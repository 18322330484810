import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Select } from 'antd';
import balanceTransactionTypes from 'constants/balanceTransactionTypes';
import messages from 'messages';
import { customFormatMessage } from 'utils/customFormatMessage';
import { StyledSelect, StyledSpan, StyledLabel } from '../styles';

const { Option } = Select;

type Props = {
  state: Record<string, any>;
  setState: any;
};

const Type = ({ state, setState }: Props) => {
  const intl = useIntl();
  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage id="reports.type.text" defaultMessage="Type" />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan
            onClick={() =>
              setState((prev: Record<string, any>) => ({
                ...prev,
                type: null,
              }))
            }
          >
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      {/* <div className="mb-1">
        <FormattedMessage id="reports.type.text" defaultMessage="Type" />
      </div> */}
      <StyledSelect
        allowClear
        placeholder={
          <FormattedMessage id="reports.select.text" defaultMessage="Select" />
        }
        mode="multiple"
        style={{ width: '100%' }}
        value={state.type ? state.type.in : []}
        onChange={(e: any[]) => {
          if (e.length) {
            setState((prev: Record<string, any>) => ({
              ...prev,
              type: { in: e },
            }));
          } else {
            setState((prev: Record<string, any>) => ({
              ...prev,
              type: null,
            }));
          }
        }}
      >
        {balanceTransactionTypes.map((option) => (
          <Option key={option.value} value={option.value}>
            {customFormatMessage(intl.formatMessage, messages[option.value])}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};

export default Type;
