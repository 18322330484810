import gql from 'graphql-tag';

export const MEMBER_TAGS = gql`
  query MemberTags(
    $first: Int
    $after: Binary
    $filter: MemberTagsFilterInput
  ) {
    memberTags(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          color
        }
      }
    }
  }
`;

export default {};
