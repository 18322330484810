import { useQuery } from '@apollo/react-hooks';
import { Col, Spin, Tag } from 'antd';
import MemberTagsSelect from 'components/MemberTagsSelect';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import removeDuplicates from 'utils/removeDuplicates';
import { StyledCol, StyledColorSquare, StyledRow } from './styles';

type Rec = Record<string, any>;
type Props = {
  users?: any[];
  onChange: (e: any) => void;
  onUserSelected?: (e: any) => void;
  tag?: Array<Rec>;
  onPreviousTagSelected?: (e: any) => void;
};

const MemberTagsPreviousNew = ({
  users,
  tag,
  onUserSelected,
  onPreviousTagSelected,
  onChange,
}: Props) => {
  const [newNext, setNewNext] = useState(tag);
  const [previous, setPrevious] = useState<any[]>([]);

  const { loading, data = {} } = useQuery(GET_MEMBERS, {
    variables: {
      filter: { id: { in: users || [] } },
    },
  }) as Rec;

  if (loading) return <Spin />;

  const { edges = [] } = data.members;
  let tags: any[] = [];
  edges
    .map(({ node }: Rec) => node.tags)
    .map((item: any) => {
      tags = [...tags, ...item];
      return false;
    });
  const usersTags = removeDuplicates(tags, 'id');

  const handleNext = (e: any) => {
    setNewNext(e);
    onChange(e);
  };

  const handlePrevious = (id: string) => {
    let newPrevious = [...previous, id];
    if (previous.includes(id)) {
      newPrevious = [...previous.filter((prevId) => prevId !== id)];
      setPrevious(newPrevious);
    } else setPrevious(newPrevious);
    if (onPreviousTagSelected) {
      onPreviousTagSelected(newPrevious);
    }

    if (onUserSelected) {
      onUserSelected(
        edges
          .map(({ node }: Rec) => node)
          .filter((b: Rec) =>
            b.tags.some((tagItem: { id: string }) =>
              newPrevious.includes(tagItem.id)
            )
          )
      );
    }
  };
  return (
    <StyledRow className="p-2">
      <StyledCol span={12} className="pr-2">
        <div className="mb-2">
          <FormattedMessage
            id="previous-tag.text"
            defaultMessage="Previous tags"
          />
        </div>
        {usersTags.map((item) => (
          <Tag.CheckableTag
            key={item.id}
            className="mb-1"
            onChange={() => handlePrevious(item.id)}
            checked={previous.includes(item.id)}
          >
            <StyledColorSquare color={item.color} /> {item.name}
          </Tag.CheckableTag>
        ))}
      </StyledCol>
      <Col span={12} className="pl-2">
        <div className="mb-2">
          <FormattedMessage id="new-tag.text" defaultMessage="New tags" />
        </div>
        <MemberTagsSelect value={newNext as any} onChange={handleNext} />
      </Col>
    </StyledRow>
  );
};

export default MemberTagsPreviousNew;
