import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import useTranslate from 'utils/useTranslate';
import { schema } from './utils';

interface PMAState {
  isShow: boolean;
  isProcessing: boolean;
  isResult: boolean;
  isBulk: boolean;
  useExcel: boolean;
  loadingQuery: boolean;
  members: string[];
  successCount: number;
  failedMembers: [];
  claimOffsetDuration: string;
  claimExpiryDuration: string;
  noClaimOffsetTime: boolean;
  noClaimExpiryTime: boolean;
  claimOffsetDurationType: string;
  claimExpiryDurationType: string;
  automaticPayoutClaiming: boolean;
}

const initialState: PMAState = {
  isShow: false,
  isProcessing: false,
  isResult: false,
  isBulk: false,
  useExcel: false,
  loadingQuery: false,
  members: [],
  successCount: 0,
  failedMembers: [],
  claimOffsetDuration: '0',
  claimExpiryDuration: '0',
  noClaimOffsetTime: false,
  noClaimExpiryTime: false,
  claimOffsetDurationType: 'days',
  claimExpiryDurationType: 'days',
  automaticPayoutClaiming: true,
};
const PMAContext = createContext<{ [key: string]: any }>({});

export const PMAProvider: React.FC = ({ children }) => {
  const [state, _state] = useState(initialState);
  const translate = useTranslate();

  const {
    control,
    setValue,
    getValues,
    reset,
    handleSubmit,
    errors,
    setError,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema(translate)),
    defaultValues: {
      amount: '1',
      actual: true,
      promoInput: {},
      remarks: '',
      playerRemarks: '',
      categoryName: '',
      password: '',
      automaticPayoutClaiming: true,
      turnoverRequirementMultiplier: 2,
      claimOffsetDuration: '0',
      claimExpiryDuration: '0',
    },
  });

  const setState = useCallback(
    (payload: { [key: string]: any }) =>
      _state((prev: PMAState) => ({ ...prev, ...payload })),
    []
  );

  const resetState = useCallback(
    (members: string[]) => {
      reset();
      _state({ ...initialState, isShow: true, members });
    },
    [reset]
  );

  const value = useMemo(
    () => ({
      state,
      setState,
      _state,
      resetState,
      form: {
        control,
        setValue,
        getValues,
        reset,
        handleSubmit,
        errors,
        setError,
      },
    }),
    [
      state,
      setState,
      resetState,
      control,
      setValue,
      getValues,
      reset,
      handleSubmit,
      errors,
      setError,
    ]
  );

  return useMemo(
    () => <PMAContext.Provider value={value}>{children}</PMAContext.Provider>,
    [children, value]
  );
};

export const usePMAContext = (): any => useContext(PMAContext);
