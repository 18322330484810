import styled from 'styled-components';

export const StyledColorSquare = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: ${(props) => props.color || '#efefef'};
  margin-right: 2px;
  vertical-align: middle;
`;

export default {};
