import { useAccount } from 'store/accountState';
import { useLazyTranslate } from 'react-google-translate';

import { useEffect } from 'react';

const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;

export const hasChinese = (str: string) =>
  str !== '' && REGEX_CHINESE.test(str);

type Result = {
  loading: boolean;
  data: string | string[];
};

const mapTranslatedText = (text: string | string[]) => {
  try {
    let result: string | Array<string> = text;
    let filterTextToBeTranslated: Array<string> = [];
    // set the value to empty string if text is in chinese form else dont do anything
    if (Array.isArray(text)) {
      filterTextToBeTranslated = text.map(str => (hasChinese(str) ? '' : str));
      result = filterTextToBeTranslated.map((val: string, index) =>
        val !== '' ? val : text[index]
      );
    }
    return result;
  } catch (error) {
    console.error('1 => Error at translatedText --> ', `${error}`);
    return '';
  }
};

const useGoogleTranslate = (
  text: string | Array<string>,
  skip?: boolean
): Result => {
  const {
    account: { locale },
  } = useAccount();
  const skipTranslate = skip || locale === 'en';

  const [translate, { loading, data, called }] = useLazyTranslate({
    language: locale,
    skip: skipTranslate,
  });

  const mappedText = mapTranslatedText(text);

  if (!called) {
    translate(mappedText);
  }

  useEffect(() => {
    translate(mappedText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return {
    loading,
    data: !skipTranslate ? data : text,
  };
};

export default useGoogleTranslate;
