import { useMutation } from '@apollo/react-hooks';
import { Modal, notification } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  CREATE_MARQUEE_MESSAGE,
  DELETE_MARQUEE_MESSAGE,
  UPDATE_MARQUEE_MESSAGE,
} from 'graphql/mutations/marqueeMessage.mutation';
import { get } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { SharedStyledTable } from 'styles/SharedStyledTable';
import { usePermissions } from 'store/accountState';
import { arrayMove } from 'utils/arrayMove';
import { rowSource, rowTarget } from 'utils/tableDragAndDrop';
import useTranslate from 'utils/useTranslate';
import { messages as localMessages } from '../../../messages';
import { BodyRow, columns } from './utils';

const { confirm } = Modal;

const draggingIndex = -1;

type Props = {
  setExistingMarquee: (data: Record<string, any>) => void;
  marqueeData: Record<string, any>;
  refetchQueries: any;
  loading?: boolean;
};

const MarqueeTable = ({
  setExistingMarquee,
  marqueeData,
  refetchQueries,
  loading,
}: Props) => {
  const [arrangeLoading, setArrangeLoading] = useState(false);
  const translate = useTranslate();
  const { role, permissions } = usePermissions();

  const {
    ALLOWED_LIST,
    ALLOWED_TOGGLE_ENABLED,
    ALLOWED_CREATE,
    ALLOWED_EDIT,
    ALLOWED_DELETE,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'TOGGLE_ENABLED', 'CREATE', 'EDIT', 'DELETE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_MARQUEE_MESSAGES}`
  );

  const columnPermissions = {
    ALLOWED_TOGGLE_ENABLED,
    ALLOWED_CREATE,
    ALLOWED_EDIT,
    ALLOWED_DELETE,
  };

  const [marquees, setMarquees] = useState([]);
  const [deleteMarqueeMessage] = useMutation(DELETE_MARQUEE_MESSAGE);
  const [updateMarqueeStatus] = useMutation(UPDATE_MARQUEE_MESSAGE);
  const [createMarqueeMessage] = useMutation(CREATE_MARQUEE_MESSAGE);

  useEffect(() => {
    const edges = get(marqueeData, 'marqueeMessages.edges', []);
    const edgeNodes = edges.map((edge: Record<string, any>) => edge.node);
    setMarquees(edgeNodes);
  }, [marqueeData]);

  const DraggableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }))(
    DragSource('row', rowSource(draggingIndex), (connect) => ({
      connectDragSource: connect.dragSource(),
    }))(BodyRow(draggingIndex))
  );

  const moveRow = async (dragIndex: number, hoverIndex: number) => {
    const selectedMarquee: Record<string, any> = marquees[dragIndex];
    const hoveredMarquee: Record<string, any> = marquees[hoverIndex];
    setMarquees((prevState) => arrayMove(prevState, dragIndex, hoverIndex));
    setArrangeLoading(true);
    await updateMarqueeStatus({
      variables: {
        id: selectedMarquee.id,
        input: {
          rank:
            selectedMarquee.rank < hoveredMarquee.rank
              ? hoveredMarquee.rank + 1
              : hoveredMarquee.rank,
        },
      },
      refetchQueries,
    });

    setArrangeLoading(false);
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const onDeleteMarqueeMessage = async (marquee: Record<string, any>) => {
    confirm({
      title: translate(messages['delete-marquee-title.text']),
      content: translate(messages.DELETE_CONFIRM_MARQUEE_MESSAGE, {
        title: marquee.title,
      }),
      okText: translate(messages.DELETE),
      okType: 'danger',
      cancelText: translate(messages['cancel.text']),
      onOk() {
        deleteMarqueeMessage({
          variables: {
            id: marquee.id,
          },
          refetchQueries,
        });
      },
    });
  };

  const onUpdateStatus = (marquee: Record<string, any>) => {
    updateMarqueeStatus({
      variables: {
        id: marquee.id,
        input: {
          enabled: !marquee.enabled,
        },
      },
      refetchQueries,
    }).then(() => {
      notification.success({
        message: translate(
          localMessages[
            marquee.enabled
              ? 'update-to-inactive.text'
              : 'update-to-active.text'
          ]
        ),
      });
    });
  };

  const onDuplicate = async (marquee: Record<string, any>) => {
    try {
      await createMarqueeMessage({
        variables: {
          input: {
            title: `copy of ${marquee.title}`,
            message: marquee.message,
            snippet: marquee.snippet,
            enabled: false,
          },
        },
        refetchQueries,
      });

      notification.success({
        message: translate(localMessages.DUPLICATE_MARQUEE_MESSAGE_SUCCESS),
      });
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  return (
    <>
      {ALLOWED_LIST && (
        <SharedStyledTable
          loading={loading || arrangeLoading}
          size="middle"
          rowKey={(x: Record<string, any>) => x.id}
          pagination={false}
          columns={columns(
            translate,
            onUpdateStatus,
            setExistingMarquee,
            onDeleteMarqueeMessage,
            onDuplicate,
            columnPermissions
          )}
          dataSource={marquees}
          components={components}
          onRow={(_: Record<string, any>, index: number) => ({
            index,
            moveRow,
          })}
        />
      )}
    </>
  );
};

export default MarqueeTable;
