import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge, Button } from 'antd';
import coercedGet from 'utils/coercedGet';
import moment from 'moment';
import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import MemberProfile from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/MemberProfile';
import { DATE_TIME_FORMAT } from 'constants/date';
import { AffiliateRequestStatusColor, getProcessingTime } from './constants';
import messages from './messages';
import { Member } from '../../../../../types/graphqlTypes';

export const columnsNext = (
  translate: any,
  handleOpenRequestModal: (data: any) => void
) => [
  {
    csvData: {
      label: translate(messages['promo-request.request-id.text']),
      key: 'serialCode',
    },
    disabled: true,
    dataIndex: 'serialCode',
    key: 'serialCode',
    title: translate(messages['promo-request.request-id.text']),
    render: (e: string, record: Record<string, any>) => (
      <Button
        type="link"
        className="pl-0"
        onClick={() => handleOpenRequestModal(record)}
      >
        {e}
      </Button>
    ),
  },
  {
    csvData: {
      label: translate(messages['username.text']),
      key: 'member.username',
      renderCell: (affiliate: Record<string, any>) =>
        coercedGet(affiliate, 'member.username', '-'),
    },
    key: 'member',
    title: translate(messages['username.text']),
    dataIndex: 'member',
    render: (member: Member) =>
      member ? (
        <MemberProfile member={member} />
      ) : (
        <FormattedMessage id="no-data.text" defaultMessage="No Data" />
      ),
  },
  {
    csvData: {
      label: translate(messages['real-name.text']),
      key: 'member.realName',
      renderCell: (affiliate: Record<string, any>) =>
        coercedGet(affiliate, 'member.realName', '-'),
    },
    key: 'member.realName',
    title: translate(messages['real-name.text']),
    dataIndex: 'member.realName',
    render: (e: string) => e || '-',
  },
  {
    csvData: {
      label: translate(messages['affiliate-programme.text']),
      key: 'programme.name',
      renderCell: (affiliate: Record<string, any>) =>
        coercedGet(affiliate, 'programme.name', '-'),
    },
    key: 'programme.name',
    title: translate(messages['affiliate-programme.text']),
    dataIndex: 'programme.name',
    render: (e: string) => e || '-',
  },
  {
    csvData: {
      label: translate(messages['status.text']),
      key: 'status',
      renderCell: (affiliate: Record<string, any>) =>
        translate(
          messages[`${coercedGet(affiliate, 'status', '').toLowerCase()}.text`]
        ),
    },
    title: translate(messages['status.text']),
    key: 'status',
    dataIndex: 'status',
    render: (e: string) => (
      <Badge
        text={translate(messages[`${e.toLowerCase()}.text`])}
        status={coercedGet(AffiliateRequestStatusColor, `${e}`, 'default')}
      />
    ),
  },
  {
    csvData: {
      label: translate(messages['vip.text']),
      key: 'vipLevel',
      renderCell: (affiliate: Record<string, any>) => {
        const memberLevels = coercedGet(
          affiliate,
          'member.memberLoyaltyLevels',
          '-'
        );
        return memberLevels.map((item: Record<string, any>) => item.name);
      },
    },
    key: 'member.memberLoyaltyLevel',
    title: translate(messages['vip.text']),
    dataIndex: 'member.memberLoyaltyLevels',
    render: (value: any) =>
      value ? <MemberLoyaltyTagList loyaltyLevels={value} /> : '-',
  },
  {
    csvData: {
      label: translate(messages['request-date.text']),
      key: 'requestDate',
      renderCell: (affiliate: Record<string, any>) =>
        moment(coercedGet(affiliate, 'dateTimeCreated', null)).format(
          DATE_TIME_FORMAT
        ) || '-',
    },
    key: 'dateTimeCreated',
    title: translate(messages['request-date.text']),
    dataIndex: 'dateTimeCreated',
    render: (e: string) => moment(e).format(DATE_TIME_FORMAT) || '-',
  },
  {
    csvData: {
      label: translate(messages['processor.text']),
      key: 'processor',
      renderCell: (affiliate: Record<string, any>) =>
        coercedGet(affiliate, 'processor.username', '-'),
    },
    key: 'processor.username',
    title: translate(messages['processor.text']),
    dataIndex: 'processor.username',
    render: (e: string) => e || '-',
  },
  {
    csvData: {
      label: translate(messages['time-processed.text']),
      key: 'id',
      renderCell: (affiliate: Record<string, any>) =>
        getProcessingTime(affiliate),
    },
    title: translate(messages['time-processed.text']),
    key: 'dateTimeProcessingStarted',
    dataIndex: 'dateTimeProcessingStarted',
    render: (_: any, record: Record<string, any>) => getProcessingTime(record),
  },
];
