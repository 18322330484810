import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useSidebar } from 'store/sidebarState';
import { dateFilterMap } from 'utils/commonDateFilters';
import useTranslate from 'utils/useTranslate';
import { DATE_TIME_FORMAT } from 'constants/date';
import messages from './messages';
import { Wrapper } from './styles';

const { RangePicker } = DatePicker;

const DateTimeFilter = ({
  handleDateRangePicker,
  filter,
  timezone,
}: {
  handleDateRangePicker: (e: any) => void;
  filter: Record<string, any>;
  timezone: string;
}) => {
  const { resetPolling } = useSidebar();

  const translate = useTranslate();

  const handleFilterChange = React.useCallback(
    (
      input: string,
      range?: {
        startDate: moment.Moment;
        endDate: moment.Moment;
      }
    ) => {
      const dateFilterValues = dateFilterMap[input](timezone, range);

      handleDateRangePicker(dateFilterValues);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timezone]
  );

  return (
    <Wrapper className="d-flex align-items-center">
      <div className="date-ranges">
        <span className="mr-4">
          <a
            className={filter.menu === 'today' ? 'active' : ''}
            onClick={() => {
              resetPolling();
              handleFilterChange('today');
            }}
            href="#/"
          >
            {translate(messages['dashboard.filter.today.text'])}
          </a>
        </span>
        <span className="mr-4">
          <a
            className={filter.menu === 'yesterday' ? 'active' : ''}
            onClick={() => handleFilterChange('yesterday')}
            href="#/"
          >
            {translate(messages['dashboard.filter.yesterday.text'])}
          </a>
        </span>
        <span className="mr-4">
          <a
            className={filter.menu === 'this-week' ? 'active' : ''}
            onClick={() => handleFilterChange('thisWeek')}
            href="#/"
          >
            {translate(messages['dashboard.filter.this-week.text'])}
          </a>
        </span>
        <span className="mr-4">
          <a
            className={filter.menu === 'last-week' ? 'active' : ''}
            onClick={() => handleFilterChange('lastWeek')}
            href="#/"
          >
            {translate(messages['dashboard.filter.last-week.text'])}
          </a>
        </span>
        <span className="mr-4">
          <a
            className={filter.menu === 'this-month' ? 'active' : ''}
            onClick={() => handleFilterChange('thisMonth')}
            href="#/"
          >
            {translate(messages['dashboard.filter.this-month.text'])}
          </a>
        </span>
        <span className="mr-4">
          <a
            className={filter.menu === 'last-month' ? 'active' : ''}
            onClick={() => handleFilterChange('lastMonth')}
            href="#/"
          >
            {translate(messages['dashboard.filter.last-month.text'])}
          </a>
        </span>
        <span className="mr-4">
          <a
            className={filter.menu === 'annual' ? 'active' : ''}
            onClick={() => handleFilterChange('annual')}
            href="#/"
          >
            {translate(messages['dashboard.filter.annual.text'])}
          </a>
        </span>
      </div>
      <RangePicker
        defaultValue={[moment(), moment()]}
        format={(date) => date.tz(timezone).format(DATE_TIME_FORMAT)}
        showTime
        onChange={(range) => {
          if (range) {
            handleFilterChange('customDateRange', {
              startDate: range[0] || moment(),
              endDate: range[1] || moment(),
            });
          }
        }}
      />
    </Wrapper>
  );
};

export default DateTimeFilter;
