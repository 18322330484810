import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useQuery } from '@apollo/react-hooks';
import { Button, Layout, List, Spin } from 'antd';
import { PAYMENT_METHOD } from 'graphql/queries/paymentMethodListing.query';
import { isEmpty } from 'lodash';
import globalMessages from 'messages';
import React from 'react';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import AccountInformation from './components/AccountInformation';
import ExchangeRateInformation from './components/ExchangeRateInformation';
import LimitSettings from './components/LimitSettings';
import OtherSettings from './components/OtherSettings';
import { StyledModal, StyledSider } from './styles';

const { Content } = Layout;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type Props = {
  toggleModal: () => void;
  toggleEdit: (data: string) => void;
  id: string;
};

const PaymentGatewayDetails = ({ toggleModal, toggleEdit, id }: Props) => {
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const { error, data = {}, loading } = useQuery(PAYMENT_METHOD, {
    variables: { id },
    fetchPolicy: 'network-only',
    context,
  });

  const { paymentMethod = {} } = data as Record<string, any>;

  const isCrypto = paymentMethod.__typename === 'CryptocurrencyPaymentMethod';
  return (
    <>
      <StyledModal
        title={paymentMethod.name}
        visible
        width={900}
        onCancel={toggleModal}
        footer={
          !loading &&
          !isEmpty(paymentMethod) && [
            <Button
              onClick={() => toggleEdit(id)}
              disabled={!paymentMethod.updateable}
              key="edit"
            >
              {translate(messages.edit)}
            </Button>,
            <Button type="primary" onClick={() => toggleModal()} key="ok">
              {translate(messages.ok)}
            </Button>,
          ]
        }
      >
        {loading && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 200 }}
          >
            <Spin />
          </div>
        )}
        {error && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 200 }}
          >
            <span className="text-danger">
              {translate(globalMessages['fatal-error.text'])}
            </span>
          </div>
        )}
        {!loading && !error && (
          <Layout style={{ height: 500 }}>
            <StyledSider theme="light" className="px-4 pt-2">
              <List>
                <List.Item className="bb-0 text-right">
                  <a href="#account-information">
                    {translate(messages.accountinfo)}
                  </a>
                </List.Item>
                {isCrypto && (
                  <List.Item className="bb-0 text-right">
                    <a href="#exchange-rate">
                      {translate(messages.exchange_rate)}
                    </a>
                  </List.Item>
                )}
                <List.Item className="bb-0 text-right">
                  <a href="#limit-settings">
                    {translate(messages.limitsettings)}
                  </a>
                </List.Item>
                <List.Item className="bb-0 text-right">
                  <a href="#other-settings">
                    {translate(messages.othersettings)}
                  </a>
                </List.Item>
              </List>
            </StyledSider>
            <Layout style={{ backgroundColor: '#fff', overflow: 'auto' }}>
              <Content className="p-4">
                <Form>
                  <AccountInformation
                    formItemLayout={formItemLayout}
                    paymentMethod={paymentMethod}
                  />
                  {isCrypto ? (
                    <ExchangeRateInformation
                      formItemLayout={formItemLayout}
                      paymentMethod={paymentMethod}
                    />
                  ) : (
                    ''
                  )}

                  <LimitSettings
                    formItemLayout={formItemLayout}
                    paymentMethod={paymentMethod}
                  />
                  <OtherSettings
                    formItemLayout={formItemLayout}
                    paymentMethod={paymentMethod}
                  />
                </Form>
              </Content>
            </Layout>
          </Layout>
        )}
      </StyledModal>
    </>
  );
};

export default PaymentGatewayDetails;
