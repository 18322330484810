export const sourceItem = [
  {
    item: 1,
    value: 'HUOBI_OTC',
    text: 'Huobi OTC',
  },
  {
    item: 2,
    value: 'COIN_MARKET_CAP',
    text: 'CoinMarketCap',
  },
  {
    item: 3,
    value: 'BLOCKCHAIN',
    text: 'BlockChain.com',
  },
  {
    item: 4,
    value: 'COINMILL',
    text: 'Coinmill',
  },
  {
    item: 4,
    value: 'COIN_GECKO',
    text: 'Coin Gecko',
  },
  {
    item: 5,
    value: 'Free_Currency_Rates',
    text: 'Free Currency Rates',
  },
  {
    item: 6,
    value: 'Currencio',
    text: 'Currencio',
  },
  {
    item: 7,
    value: 'COIN_LAYER',
    text: 'Coin Layer',
  },
];

export const frequencyTime = [
  {
    id: 1,
    text: 'm',
    value: 'minutes',
  },
  {
    id: 2,
    text: 'h',
    value: 'hours',
  },
  {
    id: 3,
    text: 'd',
    value: 'days',
  },
];
