import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

export const StyledPassword = styled.div`
  display: flex;
  .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export default {};
