import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  useCreateLoyaltyProgramContext,
  allTypes,
} from '../CreateProgramme/context';

const CreateProgramButton = ({
  toggleOverlay,
}: {
  toggleOverlay: () => void;
}) => {
  const [, dispatch] = useCreateLoyaltyProgramContext() as any;

  return (
    <Button
      onClick={() => {
        dispatch({
          type: allTypes.SET_CURRENT_PROGRAM_ID,
          payload: null,
        });
        dispatch({
          type: allTypes.RESET_PROGRAMME,
        });
        toggleOverlay();
      }}
      style={{ marginTop: 'auto' }}
      type="primary"
    >
      <span className="mr-1">
        <PlusOutlined />
      </span>
      <FormattedMessage
        id="create-programme.text"
        defaultMessage="Create Programme"
      />
    </Button>
  );
};

export default CreateProgramButton;
