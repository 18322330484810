import { useMutation, useQuery } from '@apollo/react-hooks';
import { Col, Input, Row, Spin, Tooltip, Typography } from 'antd';
import Circle from 'components/Circle';
import { DATE_FORMAT } from 'constants/date';
import ALL_PERMISSIONS from 'constants/permissions3';
import { UPDATE_WITHDRAWAL } from 'graphql/mutations/withdrawal.mutation';
import {
  WITHDRAWAL,
  WITHDRAWAL_REQUESTS,
} from 'graphql/queries/withdrawal.query';
import { startCase } from 'lodash';
import { WITHDRAWAL_MEMBER_STATUS } from 'pages/components/MemberWithdrawals/components/WithdrawalsTable/components/MemberStatus/query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import globalMessages from 'messages';
import moment from 'moment';
import React, { useState } from 'react';
import { useAccount, usePermissions } from 'store/accountState';
import coercedGet from 'utils/coercedGet';
import { getStatusColor } from 'utils/depositWithdrawalStatus';
import { isReadOnly } from 'utils/paymentsOperation';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import ApproveWithdrawal from '../ApproveWithdrawal';
import OnHoldWithdrawal from '../OnHoldWithdrawal';
import ProcessWithdrawal from '../ProcessWithdrawal';
import RejectWithdrawal from '../RejectWithdrawal';
import AccountInformation from './components/AccountInformation';
import MemberInformation from './components/MemberInformation';
import PaymentSources from './components/PaymentSources';
import SystemCompliance from './components/SystemCompliance';
import WithdrawalFee from './components/WithdrawalFee';
import { StyledModal } from './styles';

type Props = {
  toggleModal: () => void;
  id: string;
  refetchVariables?: any;
  hideActions?: boolean;
};
// NOTE: This component is also being used on BalanceServiceRecordsTable Component
const WithdrawalDetails = (props: Props) => {
  const translate = useTranslate();
  const {
    account: { account: user },
  } = useAccount();
  const { role, permissions } = usePermissions();
  const { context } = useOperatorHeader();
  const { toggleModal, id, refetchVariables, hideActions } = props;
  const [state, setState] = useState<Record<string, any>>({
    remarks: '',
    remarksError: '',
    timeout: (e: any) => e,
    paymentSourceType: 'INTERNAL',
    withdrawalMethodId: null,
    withdrawalMethodError: '',
  });

  const {
    ALLOWED_APPROVE,
    ALLOWED_REJECT,
    ALLOWED_PROCESS,
    ALLOWED_ON_HOLD,
  } = collectPermissions(
    role,
    permissions,
    ['APPROVE', 'REJECT', 'PROCESS', 'ON_HOLD'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_WITHDRAWAL_REQUESTS
  );

  const { Text } = Typography;
  const { TextArea } = Input;

  const setStateValues = (values = {}) => {
    /* 
      Only use this if you will set other states at
      the same time from a child component
    */
    setState({
      ...state,
      ...values,
    });
  };

  const setPaymentSourceType = (value: any) => {
    setState({
      ...state,
      paymentSourceType: value,
      withdrawalMethodId: null,
    });
  };

  const setWithdrawalMethodId = (value: any) => {
    setState({
      ...state,
      withdrawalMethodId: value,
    });
  };

  const setRemarksError = (error = '') => {
    setState({
      ...state,
      remarksError: error,
    });
  };

  const setWithdrawalMethodError = (error = '') => {
    setState({
      ...state,
      withdrawalMethodError: error,
    });
  };

  const refetchQueries = [
    {
      query: WITHDRAWAL,
      variables: { id },
    },
    {
      query: WITHDRAWAL_REQUESTS,
      variables: refetchVariables,
    },
    {
      query: WITHDRAWAL_MEMBER_STATUS,
      variables: { id },
    },
  ];

  const [updateWithdrawal] = useMutation(UPDATE_WITHDRAWAL, {
    variables: { id, input: { remarks: state.remarks } },
    refetchQueries,
  });

  const { loading, error, data = {} } = useQuery(WITHDRAWAL, {
    variables: { id },
    fetchPolicy: 'network-only',
    context,
    onCompleted: (result: Record<string, any>) => {
      if (result && result.withdrawal) {
        const { withdrawalMethod, remarks } = result.withdrawal;
        if (coercedGet(withdrawalMethod, 'id', false)) {
          setState({
            ...state,
            remarks,
            withdrawalMethodId: coercedGet(withdrawalMethod, 'id', null),
          });
        }
      }
    },
  });

  const withdrawal = coercedGet(data, 'withdrawal', {});
  const {
    status,
    externalStatus,
    dateTimeProcessed,
    serialCode,
    processor = {},
    manualAdjustment,
  } = withdrawal;

  const readOnly = isReadOnly({ me: user, processor, status, externalStatus });

  return (
    <>
      <StyledModal
        readOnly={readOnly}
        visible
        onOk={toggleModal}
        onCancel={toggleModal}
        width={1000}
        footer={
          loading || hideActions
            ? null
            : [
                ALLOWED_PROCESS && ALLOWED_APPROVE && (
                  <ApproveWithdrawal
                    {...props}
                    status={status}
                    refetchQueries={refetchQueries}
                    setRemarksError={setRemarksError}
                    withdrawalMethodId={state.withdrawalMethodId}
                    paymentSourceType={state.paymentSourceType}
                    setWithdrawalMethodError={setWithdrawalMethodError}
                  />
                ),
                ALLOWED_PROCESS && ALLOWED_REJECT && (
                  <RejectWithdrawal
                    {...props}
                    status={status}
                    refetchQueries={refetchQueries}
                    remarks={state.remarks}
                    setRemarksError={setRemarksError}
                  />
                ),

                ALLOWED_PROCESS && ALLOWED_ON_HOLD && (
                  <OnHoldWithdrawal
                    {...props}
                    status={status}
                    refetchQueries={refetchQueries}
                    remarks={state.remarks}
                    setRemarksError={setRemarksError}
                  />
                ),

                ALLOWED_PROCESS && (
                  <ProcessWithdrawal
                    {...props}
                    status={status}
                    refetchQueries={refetchQueries}
                    setRemarksError={setRemarksError}
                  />
                ),
              ]
        }
      >
        <div>
          {error && (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <span className="text-danger">
                {translate(messages.fatalError)}
              </span>
            </div>
          )}
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <Spin />
            </div>
          ) : (
            !error && (
              <>
                <div className="p-3 bb-1 d-flex align-items-center">
                  <h4 className="mb-0 mr-3">
                    {translate(messages.withdrawalRequestDetails)}
                  </h4>
                  <div>
                    {translate(messages.serialCode)}: {serialCode}
                  </div>
                  <span
                    className="ml-4"
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Tooltip
                      placement="top"
                      title={startCase(status.toLowerCase())}
                    >
                      <div>
                        <Circle
                          size={10}
                          color={getStatusColor(status)}
                          filled
                        />
                      </div>
                    </Tooltip>
                  </span>
                </div>
                <Row>
                  <Col span={8}>
                    <div className="p-3">
                      <MemberInformation withdrawal={withdrawal} />
                      <div className="d-flex p-2 bb-1 mt-5">
                        <div>
                          <Text type="secondary">
                            {translate(messages.processedTime)}
                          </Text>
                        </div>
                        <div className="flex-1 text-right">
                          {dateTimeProcessed ? (
                            <>
                              {moment(dateTimeProcessed).format(
                                `${DATE_FORMAT} (ddd)`
                              )}
                              <br />
                              {moment(dateTimeProcessed).format(
                                'HH:mm:ss'
                              )} - {moment(dateTimeProcessed).fromNow()}
                            </>
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                      <div className="d-flex p-2 bb-1">
                        <div>
                          <Text type="secondary">
                            {translate(messages.operator)}
                          </Text>
                        </div>
                        <div className="flex-1 text-right">
                          {processor ? processor.username : '-'}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={8} className="bx-1">
                    <div className="p-3">
                      <AccountInformation
                        withdrawal={withdrawal}
                        readOnly={readOnly}
                      />
                    </div>
                    <div className="p-3" style={{ minHeight: 350 }}>
                      <SystemCompliance withdrawal={withdrawal} />
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="p-3">
                      <div className="menu-header py-2 px-3">
                        {translate(messages.operatorRemark)} (
                        {translate(messages.optional)})
                      </div>
                      <TextArea
                        placeholder={translate(messages.remarks)}
                        disabled={!ALLOWED_PROCESS || readOnly}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        className="remarks-area mt-2"
                        value={state.remarks}
                        onChange={(e) =>
                          setState({
                            ...state,
                            remarks: e.target.value || '',
                          })
                        }
                        onKeyUp={() => {
                          clearTimeout(state.timeout);
                          setState({
                            ...state,
                            timeout: setTimeout(() => updateWithdrawal(), 500),
                          });
                        }}
                      />
                      {state.remarksError && (
                        <small className="text-danger">
                          {state.remarksError}
                        </small>
                      )}
                    </div>
                    <div className="p-3">
                      <PaymentSources
                        paymentSourceType={state.paymentSourceType}
                        setPaymentSourceType={setPaymentSourceType}
                        withdrawalMethodId={
                          manualAdjustment
                            ? translate(globalMessages.MANUAL_ADJUSTMENT)
                            : state.withdrawalMethodId
                        }
                        setWithdrawalMethodId={setWithdrawalMethodId}
                        status={status}
                        setStateValues={setStateValues}
                        readOnly={readOnly}
                      />
                      {state.withdrawalMethodError && (
                        <small className="text-danger">
                          {state.withdrawalMethodError}
                        </small>
                      )}
                    </div>
                    <div className="p-3">
                      <WithdrawalFee
                        withdrawal={withdrawal}
                        refetchVariables={refetchVariables!}
                        readOnly={readOnly}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default WithdrawalDetails;
