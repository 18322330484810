import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import { message, Button } from 'antd';
import BulkEditMemberLevelMenuForm from 'components/BulkEditMemberLevelMenuForm';
import useTranslate from 'utils/useTranslate';
import { useApolloClient } from '@apollo/react-hooks';
import { StyledModal } from './styles';
import messages from './messages';

const graphqlString = ({
  userId,
  memberLevel,
}: {
  userId: string;
  memberLevel: any;
}) => `
  ${userId}: updateMember(id: "${userId}", input: {
    memberLevel: "${memberLevel}"
  })`;

const BulkEditMemberLevelMenu = ({
  members,
  onSuccess,
}: {
  members: string[];
  onSuccess: (e: any) => void;
}) => {
  const [input, setInput] = useState<any>({
    username: members,
    memberLevel: [],
    userSelected: [],
  });
  useEffect(() => {
    setInput((state: Record<string, any>) => ({
      ...state,
      username: members,
    }));
  }, [members]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const translate = useTranslate();
  const client = useApolloClient();
  const handleSubmit = async (e: any) => {
    setLoading(true);
    try {
      await setInput(e);
      let str = '';

      e.userSelected.forEach((user: { id: string }) => {
        str += graphqlString({
          userId: user.id,
          memberLevel: e.memberLevel[0].id,
        });
      });

      await client.mutate({
        mutation: gql(`mutation { ${str} }`),
      });
      await setInput({
        ...input,
        memberLevel: e.memberLevel,
        username: e.username,
      });
      message.success(
        translate(messages['member-status.success-message.text'])
      );
      onSuccess(e.userSelected.map((x: { id: string }) => x.id));
      setLoading(false);
      handleShow();
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Button className="text-black" type="link" onClick={handleShow} block>
        <FormattedMessage
          id="edit-member-marker.text"
          defaultMessage="Edit member marker"
        />
      </Button>
      {isShow && (
        <StyledModal
          width={600}
          title={
            <FormattedMessage
              id="edit-member-marker.text"
              defaultMessage="Edit member marker"
            />
          }
          onCancel={handleShow}
          visible={isShow}
          footer={false}
        >
          <BulkEditMemberLevelMenuForm
            initialValues={input}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onClose={handleShow}
          />
        </StyledModal>
      )}
    </>
  );
};

export default BulkEditMemberLevelMenu;
