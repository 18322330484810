import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  isTypenameExternal,
  isTypenameInternal,
  isTypeBank,
  isTypeWechat,
  isTypeAlipay,
  PAYMENT_METHODS_LIST,
  isTypeHexopay,
} from 'constants/paymentTypes';
import useTranslate from 'utils/useTranslate';
import messages from 'pages/components/WithdrawalMethods/messages';
import BankDetails from './components/BankDetails';
import MethodDetails from './components/MethodDetails';

type Rec = Record<string, any>;
type Props = {
  withdrawalMethod: Rec;
  formItemLayout: Rec;
};

const RenderPaymentMethod = ({ typename }: { typename: string }) => {
  const translate = useTranslate();
  const value = PAYMENT_METHODS_LIST[typename];

  const unknownMethod =
    !isTypeBank(value) &&
    !isTypeAlipay(value) &&
    !isTypeWechat(value) &&
    !isTypeHexopay(value);
  return (
    <>
      {isTypeHexopay(value) && translate(messages.hexopay)}
      {isTypeBank(value) && translate(messages.banktransfer)}
      {isTypeAlipay(value) && translate(messages.alipay)}
      {isTypeWechat(value) && translate(messages.wechat)}
      {unknownMethod && translate(messages.unknownmethod)}
    </>
  );
};

const AccountInformation = ({ withdrawalMethod, formItemLayout }: Props) => {
  const { name, __typename, withdrawalProvider } = withdrawalMethod;
  const translate = useTranslate();
  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1"
        label={
          <strong id="account-information">
            {translate(messages.accountinfo)}
          </strong>
        }
        colon={false}
      />
      <Form.Item
        label={`${translate(messages.name)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        {name}
      </Form.Item>

      <Form.Item
        label={`${translate(messages.sources)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        {isTypenameInternal(__typename) && translate(messages.internal)}
        {isTypenameExternal(__typename) && translate(messages.thirdparty)}
        {!isTypenameInternal(__typename) &&
          !isTypenameExternal(__typename) &&
          translate(messages.unknownpaymentsource)}
      </Form.Item>

      <Form.Item
        label={`${translate(messages.paymentmethod)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <RenderPaymentMethod typename={__typename} />
      </Form.Item>
      {isTypenameExternal(__typename) && (
        <Form.Item
          label={`${translate(messages.provider)}:`}
          {...formItemLayout}
          className="mb-0"
        >
          {withdrawalProvider && withdrawalProvider.name}
        </Form.Item>
      )}
      {isTypeBank(PAYMENT_METHODS_LIST[__typename]) && (
        <BankDetails
          withdrawalMethod={withdrawalMethod}
          formItemLayout={formItemLayout}
        />
      )}
      {!isTypenameExternal(__typename) &&
        !isTypeBank(PAYMENT_METHODS_LIST[__typename]) && (
          <MethodDetails
            paymentMethod={withdrawalMethod}
            formItemLayout={formItemLayout}
          />
        )}
    </>
  );
};

export default AccountInformation;
