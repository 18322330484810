import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';
import countryList from 'country-list';
import { getBank, messages as bankMessages } from 'constants/banks';
import useTranslate from 'utils/useTranslate';

type Props = {
  paymentMethod: Record<string, any>;
  formItemLayout: Record<string, any>;
};

const BankDetails = (props: Props) => {
  const { paymentMethod, formItemLayout } = props;
  const {
    bank,
    accountNumber,
    accountName,
    branch,
    country,
    province,
    city,
  } = paymentMethod;

  const newBank = getBank(bank)!;

  const translate = useTranslate();

  return (
    <>
      <Form.Item
        label={
          <FormattedMessage id="bank-name.text" defaultMessage="Bank name" />
        }
        {...formItemLayout}
        className="mb-0"
      >
        <div className="d-flex align-items-center">
          <img
            height={20}
            width={20}
            src={newBank.logo}
            alt="logo"
            className="mr-1"
          />
          <div>
            {newBank.label !== 'OTHER' ? `[${newBank.label}] ` : '-'}
            {translate(bankMessages[newBank.label])}
          </div>
        </div>{' '}
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="account-number.text"
            defaultMessage="Account number"
          />
        }
        {...formItemLayout}
        className="mb-0"
      >
        <span>{accountNumber || '-'}</span>
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="account-name.text"
            defaultMessage="Account name"
          />
        }
        {...formItemLayout}
        className="mb-0"
      >
        <span>{accountName || '-'}</span>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="branch.text" defaultMessage="Branch" />}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{branch}</span>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="country.text" defaultMessage="Country" />}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{countryList.getName(country || '-')}</span>
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage id="province.text" defaultMessage="Province" />
        }
        {...formItemLayout}
        className="mb-0"
      >
        <span>{province || '-'}</span>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="city.text" defaultMessage="City" />}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{city || '-'}</span>
      </Form.Item>
    </>
  );
};

export default BankDetails;
