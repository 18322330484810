import { MemberInteractionLog } from 'types/graphqlTypes';

export const getInteractionModuleField = (
  record: Partial<MemberInteractionLog>,
  key: string
) => {
  if (record.memberLoyaltyLevel) {
    return record.memberLoyaltyLevel[`${key}`];
  }

  if (record.deposit) {
    return record.deposit[`${key}`];
  }

  if (record.withdrawal) {
    return record.withdrawal[`${key}`];
  }

  if (record.vendor) {
    return record.vendor[`${key}`];
  }

  return record?.remarks;
};

export const getExternalModule = (record: MemberInteractionLog) => {
  const { type } = record;

  if (type === 'MEMBER_LOGGED_IN' || type === 'MEMBER_LOGGED_OUT') {
    return record.member.username;
  }

  if (type === 'MEMBER_LOYALTY_LEVEL_UPGRADED') {
    return getInteractionModuleField(record, 'name');
  }

  if (type === 'MEMBER_LOYALTY_LEVEL_DOWNGRADED') {
    return getInteractionModuleField(record, 'name');
  }

  if (type === 'VENDOR_BALANCE_CREDITED') {
    return getInteractionModuleField(record, 'shortcode');
  }

  if (type === 'VENDOR_BALANCE_DEBITED') {
    return getInteractionModuleField(record, 'shortcode');
  }

  if (type === 'VENDOR_GAME_LAUNCHED') {
    return getInteractionModuleField(record, 'shortcode');
  }

  return getInteractionModuleField(record, 'serialCode') || '';
};
