import React, { useState } from 'react';
import FiltersProvider from 'contexts/Filters';
import { MembersOnlineScreen } from 'pages/components/MembersOnlineManagement/components/MembersOnlineScreen';

const initialFilters = {
  username: null,
};

const MembersOnlineManagement = () => {
  const [key, setKey] = useState('');
  return <MembersOnlineScreen key={key} setKey={setKey} />;
};

export default () => (
  <FiltersProvider initialValues={initialFilters}>
    <MembersOnlineManagement />
  </FiltersProvider>
);
