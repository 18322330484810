import gql from 'graphql-tag';

export const OPERATORS = gql`
  query Operators($first: Int, $after: Binary, $filter: OperatorsFilterInput) {
    operators(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          value: id
          label: username
        }
      }
    }
  }
`;

export const ADMINS = gql`
  query Admins($first: Int, $after: Binary, $filter: AdminsFilterInput) {
    admins(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          value: id
          label: username
        }
      }
    }
  }
`;

export default {};
