import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { FormikValues, useFormik } from 'formik';

import {
  CloseOutlined,
  CopyOutlined,
  ExclamationCircleFilled,
  FileOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
} from 'antd';
import * as generatePassword from 'generate-password';
import { useMutation } from '@apollo/react-hooks';
import Drawer from 'SuperAdminMain/shared/components/Drawer/Drawer';
import { DrawerContainer } from 'SuperAdminMain/shared/styles/DrawerContainer';
import './styles.css';
import DrawerFooter from 'components/DrawerFooter';
import { UseFormikErrorMessage } from 'SuperAdminMain/hooks/UseFormikErrorMessage/UseFormikErrorMessage';
import copyToClipboard from 'SuperAdminMain/utils/copyToClipboard';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';
import { ALink } from 'SuperAdminMain/shared/components/ALink/ALink';
import moment from 'moment';
import BrandSelect from 'components/BrandSelect';
import { WhiteListIpModal } from './WhitelistIpModal';
import {
  currencyOption,
  INewClientAccount,
  messagesLocal,
  StyledUploader,
  UPLOAD_FILE,
  validationSchema,
} from './utils';
import useIsNext from '../../../../hooks/useIsNext';
import { FeatureFlagsSA } from '../../../constants/featureflags';
import { Currency } from '../../../../types/currency';

const { Search } = Input;

const initialValues: FormikValues = {
  username: '',
  company: '',
  personInCharge: '',
  mobileNumber: '',
  email: '',
  qqId: '',
  wechatId: '',
  vendorGroup: '',
  password: '',
  trial: true,
  currency: Currency.Gbp,
  multiCurrencyEnabled: false,
  contractStartDateTime: '',
  contractEndDateTime: '',
  adminCode: '',
  ipWhitelist: [],
  linkedAdmins: [],
};

export const NewClientAccount = ({
  onClose,
  onNext,
  existingValues,
  vendorGroups,
}: INewClientAccount) => {
  const [files, setFiles] = useState<Array<{ id: string; name: string }>>([]);
  const [uploadProgress, setUploadProgress] = useState<boolean>(false);
  const [openWhitelist, setOpenWhitelist] = useState(false);
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const translate = useTranslate();
  const nextVendorGroup = useIsNext(FeatureFlagsSA.nextVendorGroupManagement);
  const initialFormikValues = {
    ...initialValues,
    ...existingValues,
    ...(existingValues?.vendorGroup?.id && {
      vendorGroup: existingValues?.vendorGroup?.id,
    }),
  };
  const formik = useFormik({
    initialValues: initialFormikValues,
    enableReinitialize: true,
    onSubmit: (submitValues: FormikValues) => {
      const valuesToSubmit = {
        ...submitValues,
        attachments: files.map((file) => file.id),
        ...(submitValues.contractStartDateTime && {
          contractStartDateTime: moment(
            submitValues.contractStartDateTime
          ).format(),
        }),
        ...(submitValues.contractEndDateTime && {
          contractEndDateTime: moment(
            submitValues.contractEndDateTime
          ).format(),
        }),
      };
      onNext(valuesToSubmit, !!existingValues);
    },
    validationSchema: validationSchema(translate, !!existingValues),
  });
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values: formikValues,
    handleBlur,
    errors,
  } = formik;

  useEffect(() => {
    if (coercedGet(existingValues!, 'attachments', []).length > 0) {
      setFiles(existingValues?.attachments);
    }
  }, [existingValues]);

  const generatePass = useCallback(() => {
    const generatedPassword = generatePassword.generate({
      length: 32,
      numbers: true,
    });
    setFieldValue('password', generatedPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBeforeUpload = (file: File) => {
    const sameFileName = files.find((theFile) => theFile.name === file.name);

    if (sameFileName) {
      message.error(translate(messagesLocal['file-already-exists.text']));
      return false;
    }

    setUploadProgress(true);
    uploadFile({
      variables: {
        file,
      },
    }).then((response) => {
      const uploadedFile = coercedGet(response, 'data.uploadFile', {});
      setFiles((prev) => [
        ...prev,
        {
          id: uploadedFile.id,
          name: file.name,
        },
      ]);
      setUploadProgress(false);
    });
    return false;
  };

  const values = formikValues as FormikValues;

  const disabledStartDate = (startValue: any) => {
    if (!startValue || !values.contractEndDateTime) {
      return false;
    }
    return startValue.valueOf() > values.contractEndDateTime.valueOf();
  };

  const disabledEndDate = (endValue: any) => {
    if (!endValue || !values.contractStartDateTime) {
      return false;
    }
    return endValue.valueOf() <= values.contractStartDateTime.valueOf();
  };

  return (
    <>
      <Drawer.Content>
        <DrawerContainer className="container container-small">
          <div className="d-flex">
            <StyledUploader
              multiple
              listType="picture-card"
              showUploadList={false}
              beforeUpload={onBeforeUpload}
            >
              <div>
                <PlusOutlined />
                <div className="ant-upload-text">
                  <FormattedMessage id="upload.text" defaultMessage="Upload" />
                </div>
              </div>
            </StyledUploader>
            <div className="ml-2">
              <p className="mb-1">
                <ExclamationCircleFilled
                  className="mr-2"
                  style={{ color: '#f8ad15' }}
                />
                <span className="text-black--45">
                  <FormattedMessage
                    id="MAX_FILE_SIZE_MB"
                    defaultMessage="Maximum {size}MB"
                    values={{ size: 10 }}
                  />
                </span>
              </p>
              {files.map((file) => (
                <p className="mb-1" key={file.id}>
                  <FileOutlined className="mr-1" />
                  {decodeURI(file.name.split('/').slice(-1)[0])}{' '}
                  <CloseOutlined
                    className="ml-1 fs-13 cursor-pointer"
                    onClick={() =>
                      setFiles((prev) =>
                        prev.filter((prevFile) => prevFile.id !== file.id)
                      )
                    }
                  />
                </p>
              ))}
              {uploadProgress && <LoadingOutlined />}
            </div>
          </div>

          <Row>
            <Col span={8} className="mr-2">
              <div>
                <p className="mb-1">
                  <strong>
                    {translate(messagesLocal['account-information.text'])}
                  </strong>
                </p>
                <small>
                  <FormattedMessage
                    id="client-account.text"
                    defaultMessage="Client Account"
                  />
                </small>
                <Input
                  autoComplete="new-password"
                  disabled={!!existingValues}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="username"
                  value={values.username}
                  size="large"
                  placeholder={translate(messagesLocal['please-enter.text'])}
                />

                <UseFormikErrorMessage formik={formik} name="username" />
              </div>
              <div>
                <p className="mt-3 mb-1">
                  <strong>
                    {translate(messagesLocal['client-information.text'])}
                  </strong>
                </p>
                <small>{translate(messagesLocal['category.text'])}</small>
                <Select
                  disabled={!!existingValues}
                  className="d-block w-100"
                  onChange={(val: any) => setFieldValue('trial', val)}
                  value={values.trial}
                  size="large"
                  placeholder={translate(messagesLocal['please-select.text'])}
                >
                  <Select.Option value={true as any}>
                    <FormattedMessage id="trial.text" defaultMessage="Trial" />
                  </Select.Option>
                  <Select.Option value={false as any}>
                    <FormattedMessage
                      id="production.text"
                      defaultMessage="Production"
                    />
                  </Select.Option>
                </Select>
              </div>
              <div className="mt-2">
                <small>
                  {translate(messagesLocal['person-in-charge.text'])}
                </small>
                <Input
                  onChange={handleChange}
                  value={values.personInCharge}
                  name="personInCharge"
                  size="large"
                  placeholder={translate(messagesLocal['please-enter.text'])}
                />
              </div>
              <div className="mt-2">
                <small>{translate(messagesLocal['qq-number.text'])}</small>
                <Input
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.qqId}
                  name="qqId"
                  size="large"
                  placeholder={translate(messagesLocal['please-enter.text'])}
                />
                <UseFormikErrorMessage formik={formik} name="qqId" />
              </div>
              <div className="mt-2">
                <small>{translate(messagesLocal['email.text'])}</small>
                <Input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  size="large"
                  placeholder={translate(messagesLocal['please-enter.text'])}
                />
                <UseFormikErrorMessage formik={formik} name="email" />
              </div>
              <div className="mt-2">
                <small>{translate(messagesLocal['wechat.text'])}</small>
                <Input
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.wechatId}
                  name="wechatId"
                  size="large"
                  placeholder={translate(messagesLocal['please-enter.text'])}
                />
                <UseFormikErrorMessage formik={formik} name="wechatId" />
              </div>
              <div className="mt-2">
                <small>{translate(messagesLocal['currency.text'])}</small>
                <Select
                  className="d-block"
                  value={values.currency}
                  notFoundContent={null}
                  disabled={!!existingValues}
                  placeholder="Select Currency"
                  onChange={(val) => setFieldValue('currency', val)}
                >
                  {currencyOption.map(({ value, label }, idx) => (
                    <Select.Option key={idx} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </div>

              <div className="mt-2">
                <small>{translate(messagesLocal['multi-currency.text'])}</small>
                <div className="d-block">
                  <Checkbox
                    disabled={!!existingValues}
                    checked={values.multiCurrencyEnabled}
                    onChange={(e) =>
                      setFieldValue('multiCurrencyEnabled', e.target.checked)
                    }
                  >
                    {values.multiCurrencyEnabled ? 'Enabled' : 'Disabled'}
                  </Checkbox>
                </div>
              </div>

              {nextVendorGroup && (
                <div className="mt-3">
                  <p>
                    <strong>
                      {translate(messagesLocal['vendor-management.text'])}
                    </strong>
                  </p>
                  <small>{translate(messagesLocal['vendor-group.text'])}</small>
                  <Select
                    className="d-block"
                    notFoundContent={null}
                    value={values.vendorGroup}
                    onChange={(val: any) => setFieldValue('vendorGroup', val)}
                    size="large"
                    placeholder={translate(messagesLocal['please-select.text'])}
                  >
                    {vendorGroups.map((vendorGroup) => (
                      <Select.Option
                        value={vendorGroup.id}
                        key={vendorGroup.id}
                      >
                        {vendorGroup.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
            </Col>
            {/* =====SECOND COLUMN===== */}
            <Col span={8} className="mr-2">
              <div style={{ marginTop: '26px' }}>
                <section>
                  <small>
                    <FormattedMessage
                      id="password.text"
                      defaultMessage="Password"
                    />
                  </small>
                  <div>
                    <Search
                      disabled={!!existingValues}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="password"
                      type="password"
                      value={existingValues ? '*******' : values.password}
                      suffix={
                        <Button
                          type="link"
                          className="p-0"
                          onClick={() => copyToClipboard(values.password)}
                        >
                          <CopyOutlined style={{ fontSize: '20px' }} />
                        </Button>
                      }
                      size="large"
                      placeholder={translate(
                        messagesLocal['please-enter.text']
                      )}
                      enterButton={translate(
                        messagesLocal['auto-generate.text']
                      )}
                      onSearch={generatePass}
                    />
                  </div>

                  <UseFormikErrorMessage formik={formik} name="password" />
                </section>

                <section style={{ marginTop: '46px' }}>
                  <small>
                    {translate(messagesLocal['client-(company).text'])}
                  </small>
                  <Input
                    onChange={handleChange}
                    name="company"
                    value={values.company}
                    size="large"
                    placeholder={translate(messagesLocal['please-enter.text'])}
                  />
                  <UseFormikErrorMessage formik={formik} name="company" />
                </section>

                <section className="mt-2">
                  <small>{translate(messagesLocal['mobile-phone.text'])}</small>
                  <Input
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobileNumber}
                    name="mobileNumber"
                    size="large"
                    placeholder={translate(messagesLocal['please-enter.text'])}
                  />
                  <UseFormikErrorMessage formik={formik} name="mobileNumber" />
                </section>

                <section className="mt-2">
                  <small>{translate(messagesLocal['skype.text'])}</small>
                  <Input
                    onChange={handleChange}
                    value={values.skypeId}
                    name="skypeId"
                    size="large"
                    placeholder={translate(messagesLocal['please-enter.text'])}
                  />
                </section>

                <section className="mt-2">
                  <small>{translate(messagesLocal['start-date.text'])}</small>
                  <DatePicker
                    disabledDate={disabledStartDate}
                    showTime
                    value={values.contractStartDateTime}
                    size="large"
                    placeholder={translate(
                      messagesLocal['select-date-and-time.text']
                    )}
                    onChange={(data) =>
                      setFieldValue('contractStartDateTime', data)
                    }
                    onOk={(data) =>
                      setFieldValue('contractStartDateTime', data)
                    }
                  />
                </section>

                <section className="mt-2">
                  <small>{translate(messagesLocal['expire-date.text'])}</small>
                  <DatePicker
                    disabledDate={disabledEndDate}
                    showTime
                    size="large"
                    value={values.contractEndDateTime}
                    placeholder={translate(
                      messagesLocal['select-date-and-time.text']
                    )}
                    onChange={(data) =>
                      setFieldValue('contractEndDateTime', data)
                    }
                    onOk={(data) => setFieldValue('contractEndDateTime', data)}
                  />
                </section>
              </div>
            </Col>
            <Col span={7}>
              <div style={{ marginTop: '204px' }}>
                <section>
                  <small>
                    {translate(messagesLocal['admin-code/url.text'])}
                  </small>
                  <div>
                    <Input
                      disabled={!!existingValues}
                      onChange={handleChange}
                      value={values.adminCode}
                      name="adminCode"
                      suffix={
                        <Button
                          type="link"
                          className="p-0"
                          onClick={() => copyToClipboard(values.adminCode)}
                        >
                          <CopyOutlined style={{ fontSize: '20px' }} />
                        </Button>
                      }
                      size="large"
                      placeholder={translate(
                        messagesLocal['please-enter.text']
                      )}
                    />
                  </div>
                </section>

                <section className="mt-2">
                  <small>
                    {translate(messagesLocal['whitelist-ip-address.text'])}
                  </small>

                  <ALink onClick={() => setOpenWhitelist(true)}>
                    <div
                      style={{
                        border: '1px solid #e6e6e6',
                        borderRadius: '4px',
                        minHeight: '40px',
                      }}
                      className="px-1 cursor-pointer"
                    >
                      {values.ipWhitelist.map((ipWhitelist: string) => (
                        <span className="ant-tag my-1" key={ipWhitelist}>
                          {ipWhitelist}
                        </span>
                      ))}
                    </div>
                  </ALink>
                </section>
              </div>
            </Col>
          </Row>

          <div className="mt-2">
            <p>
              <strong>{translate(messagesLocal['linked-admins.text'])}</strong>
            </p>
            <section>
              <BrandSelect onSelect={(e) => setFieldValue('linkedAdmins', e)} />
            </section>
          </div>
        </DrawerContainer>

        <WhiteListIpModal
          ipAddresses={values.ipWhitelist}
          visible={openWhitelist}
          onClose={() => setOpenWhitelist(false)}
          onSubmit={(data: string[]) => {
            setFieldValue('ipWhitelist', data);
          }}
        />
      </Drawer.Content>
      <DrawerFooter>
        <>
          <Button onClick={onClose}>
            <FormattedMessage id="back.text" defaultMessage="Back" />
          </Button>
          <Button
            disabled={!isEmpty(errors)}
            type="primary"
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="next.text" defaultMessage="Next" />
          </Button>
        </>
      </DrawerFooter>
    </>
  );
};
