import isRow from 'utils/isRow';

export const Permissions = {
  DASHBOARD: 'DASHBOARDS',
  OPERATORS: 'OPERATORS',
  MEMBERS: 'MEMBERS',
  AFFILIATES: 'AFFILIATES',
  DEPOSITS: 'DEPOSITS',
  WITHDRAWALS: 'WITHDRAWALS',
  PROMO: 'PROMOS',
  VIP: 'VIP',
  REBATES: 'REBATES',
  REPORTS: 'REPORTS',
  SYSTEM_MANAGEMENT: 'SYSTEM_MANAGEMENT',
  SYSTEM_MESSAGE: 'SYSTEM_MESSAGES',
};

export const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  OPERATOR: 'OPERATOR',
  MEMBER: 'MEMBER',
};

/*
 *   ===========     README      ===========
 *
 *   PERMISSIONS CONSTANTS
 *
 *   Hello there! Before adding or updating a permission to the following constants, it is urgent to
 *   make sure you have read and followed these guidelines to prevent regression issues:
 *
 *   [ ] I understand that adding or updating a permission constant here is seen on Query.permissions
 *   [ ] I understand that adding or updating a permission constant here means providing a support to its
 *   respective permission tree file.
 *       Example: Adding or updating a new permission constant under ALL_PROMOS **must be supported** on PromosTree.js
 *   [ ] I understand to include Rhaidz and personally inform him as one of the code reviewers. Rhaidz will only
 *   test the scope of the permission constant that was being added or updated.
 *
 */

const ALL_DASHBOARDS = {
  DASHBOARDS: 'DASHBOARDS',
  DASHBOARDS_INSTANT_FOCUS: 'DASHBOARDS:INSTANT_FOCUS',
};

const ALL_OPERATORS = {
  OPERATORS: 'OPERATORS',
  OPERATORS_ACCOUNT_MANAGEMENT: 'OPERATORS:ACCOUNT_MANAGEMENT',
  OPERATORS_ACCOUNT_MANAGEMENT_LIST: 'OPERATORS:ACCOUNT_MANAGEMENT:LIST',
  OPERATORS_ACCOUNT_MANAGEMENT_VIEW_DETAILS:
    'OPERATORS:ACCOUNT_MANAGEMENT:VIEW_DETAILS',
  OPERATORS_ACCOUNT_MANAGEMENT_CREATE: 'OPERATORS:ACCOUNT_MANAGEMENT:CREATE',
  OPERATORS_ACCOUNT_MANAGEMENT_EDIT: 'OPERATORS:ACCOUNT_MANAGEMENT:EDIT',
  OPERATORS_ACCOUNT_MANAGEMENT_DELETE: 'OPERATORS:ACCOUNT_MANAGEMENT:DELETE',
  // OPERATORS_ACCOUNT_MANAGEMENT_TOGGLE_ENABLED:
  //   'OPERATORS:ACCOUNT_MANAGEMENT:TOGGLE_ENABLED',
  OPERATORS_PERMISSION_GROUP: 'OPERATORS:PERMISSION_GROUP',
  OPERATORS_PERMISSION_GROUP_LIST: 'OPERATORS:PERMISSION_GROUP:LIST',
  OPERATORS_PERMISSION_GROUP_VIEW_DETAILS:
    'OPERATORS:PERMISSION_GROUP:VIEW_DETAILS',
  OPERATORS_PERMISSION_GROUP_CREATE: 'OPERATORS:PERMISSION_GROUP:CREATE',
  OPERATORS_PERMISSION_GROUP_EDIT: 'OPERATORS:PERMISSION_GROUP:EDIT',
  OPERATORS_PERMISSION_GROUP_DELETE: 'OPERATORS:PERMISSION_GROUP:DELETE',
  OPERATORS_PERMISSION_GROUP_DUPLICATE: 'OPERATORS:PERMISSION_GROUP:DUPLICATE',
};

const ALL_MEMBERS = {
  MEMBERS: 'MEMBERS',
  MEMBERS_MEMBER_MANAGEMENT: 'MEMBERS:MEMBER_MANAGEMENT',
  MEMBERS_MEMBER_MANAGEMENT_LIST: 'MEMBERS:MEMBER_MANAGEMENT:LIST',
  MEMBERS_MEMBER_MANAGEMENT_REPORT_GENERATE_CSV:
    'MEMBERS:MEMBER_MANAGEMENT:REPORT:GENERATE_CSV',
  MEMBERS_MEMBER_MANAGEMENT_REPORT_CSV_DOWNLOAD_BUTTON:
    'MEMBERS:MEMBER_MANAGEMENT:REPORT:CSV_DOWNLOAD_BUTTON',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_ACCOUNT_BALANCE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:ACCOUNT_BALANCE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_WALLET_BALANCE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:WALLET_BALANCE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_TO_BALANCE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:TO_BALANCE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_LAST_DEPOSIT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:LAST_DEPOSIT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_LAST_WITHDRAWAL:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:LAST_WITHDRAWAL',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_LAST_PROMO_APPLIED:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:LAST_PROMO_APPLIED',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_LAST_PLAYED_GAMES:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:LAST_PLAYED_GAMES',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MEMBER_NOTES',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES_LIST:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MEMBER_NOTES:LIST',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES_ADD:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MEMBER_NOTES:ADD',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES_EDIT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MEMBER_NOTES:EDIT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES_DELETE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MEMBER_NOTES:DELETE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_PLAYER_PROFILE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:PLAYER_PROFILE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_PLAYER_PROFILE_EDIT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:PLAYER_PROFILE:UPDATE_PROFILE_LINK',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_PLAYER_PROFILE_VIEW:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:PLAYER_PROFILE:VIEW_PROFILE_LINK',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:HEXOPAY_CREDIT_CARDS',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS_LIST:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:HEXOPAY_CREDIT_CARDS:LIST',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS_DELETE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:HEXOPAY_CREDIT_CARDS:DELETE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS_TOGGLE_CLOSED_LOOP:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:HEXOPAY_CREDIT_CARDS:TOGGLE_CLOSED_LOOP',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS_DISABLE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:HEXOPAY_CREDIT_CARDS:DISABLE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_INTERACTION_LOGS_REPORT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MEMBER_INTERACTION_LOGS_REPORT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_INTERACTION_LOGS_REPORT_LIST:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MEMBER_INTERACTION_LOGS_REPORT:LIST',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:DOCUMENT_MANAGEMENT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_LIST:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:DOCUMENT_MANAGEMENT:LIST',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_UPLOAD:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:DOCUMENT_MANAGEMENT:UPLOAD',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_APPROVE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:DOCUMENT_MANAGEMENT:APPROVE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_REJECT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:DOCUMENT_MANAGEMENT:REJECT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_DELETE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:DOCUMENT_MANAGEMENT:DELETE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_REALITY_CHECK:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:REALITY_CHECK',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_REALITY_CHECK_LIST:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:REALITY_CHECK:LIST',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_REALITY_CHECK_EDIT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:REALITY_CHECK:EDIT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DEPOSIT_LIMIT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:DEPOSIT_LIMIT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DEPOSIT_LIMIT_LIST:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:DEPOSIT_LIMIT:LIST',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DEPOSIT_LIMIT_EDIT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:DEPOSIT_LIMIT:EDIT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_INTERNAL_SELF_EXCLUSION:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:INTERNAL_SELF_EXCLUSION',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_INTERNAL_SELF_EXCLUSION_LIST:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:INTERNAL_SELF_EXCLUSION:LIST',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_INTERNAL_SELF_EXCLUSION_EDIT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:INTERNAL_SELF_EXCLUSION:EDIT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_TIMEOUT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:TIMEOUT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_TIMEOUT_LIST:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:TIMEOUT:LIST',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_TIMEOUT_EDIT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:TIMEOUT:EDIT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_AGENT_AFFILIATE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:AGENT_AFFILIATE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_INFORMATION:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MEMBER_INFORMATION',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_VIP_TIER_PROGRESS:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:VIP_TIER_PROGRESS',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_LABEL:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MEMBER_LABEL',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_USER_PROFILE:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:USER_PROFILE',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_LOGIN_DETAILS:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:LOGIN_DETAILS',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_CONTACT_DETAILS:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:CONTACT_DETAILS',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_WITHDRAWAL_BANK_ACCOUNT:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:WITHDRAWAL_BANK_ACCOUNT',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_REGISTRATION_INFORMATION:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:REGISTRATION_INFORMATION',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MESSAGES:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:MESSAGES',
  MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_ACCOUNT_RESTRICTION:
    'MEMBERS:MEMBER_MANAGEMENT:VIEW_DETAILS:ACCOUNT_RESTRICTION',
  MEMBERS_MEMBER_MANAGEMENT_CREATE: 'MEMBERS:MEMBER_MANAGEMENT:CREATE',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_ADD_BALANCE:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:ADD_BALANCE',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_ADD_BALANCE_PAYOUT:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:ADD_BALANCE:PAYOUT',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_ADD_BALANCE_DEPOSIT:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:ADD_BALANCE:DEPOSIT',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_ADD_BALANCE_EXTERNAL_PROGRAMME:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:ADD_BALANCE:EXTERNAL_PROGRAMME',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_ADD_BALANCE_DEBIT_TRANSFER:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:ADD_BALANCE:DEBIT_TRANSFER',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_ADD_BALANCE_SYSTEM_CREDIT:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:ADD_BALANCE:SYSTEM_CREDIT',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_REMOVE_BALANCE:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:REMOVE_BALANCE',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_REMOVE_BALANCE_WITHDRAWAL:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:REMOVE_BALANCE:WITHDRAWAL',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_REMOVE_BALANCE_MANUAL_WITHDRAWAL:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:REMOVE_BALANCE:MANUAL_WITHDRAWAL',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_REMOVE_BALANCE_CREDIT_TRANSFER:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:REMOVE_BALANCE:CREDIT_TRANSFER',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_REMOVE_BALANCE_SYSTEM_DEBIT:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BALANCE:REMOVE_BALANCE:SYSTEM_DEBIT',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_LABEL: 'MEMBERS:MEMBER_MANAGEMENT:EDIT_LABEL',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_STATUS:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_STATUS',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_MEMBER:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_MEMBER',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_MEMBER_MARKER:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_MEMBER_MARKER',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_VIP: 'MEMBERS:MEMBER_MANAGEMENT:EDIT_VIP',
  MEMBERS_MEMBER_MANAGEMENT_DELETE: 'MEMBERS:MEMBER_MANAGEMENT:DELETE',
  MEMBERS_MEMBER_MANAGEMENT_CHANGE_PASSWORD:
    'MEMBERS:MEMBER_MANAGEMENT:CHANGE_PASSWORD',
  MEMBERS_MEMBER_MANAGEMENT_CHANGE_WITHDRAWAL_PASSWORD:
    'MEMBERS:MEMBER_MANAGEMENT:CHANGE_WITHDRAWAL_PASSWORD',
  MEMBERS_MEMBER_MANAGEMENT_SEND_MESSAGE:
    'MEMBERS:MEMBER_MANAGEMENT:SEND_MESSAGE',
  MEMBERS_MEMBER_MANAGEMENT_EDIT_BRAND_LINKAGES:
    'MEMBERS:MEMBER_MANAGEMENT:EDIT_BRAND_LINKAGES',
  MEMBERS_MEMBER_MARKER_MANAGEMENT: 'MEMBERS:MEMBER_MARKER_MANAGEMENT',
  MEMBERS_MEMBER_MARKER_MANAGEMENT_LIST:
    'MEMBERS:MEMBER_MARKER_MANAGEMENT:LIST',
  MEMBERS_MEMBER_MARKER_MANAGEMENT_VIEW_DETAILS:
    'MEMBERS:MEMBER_MARKER_MANAGEMENT:VIEW_DETAILS',

  MEMBERS_MEMBER_MARKER_MANAGEMENT_CREATE:
    'MEMBERS:MEMBER_MARKER_MANAGEMENT:CREATE',
  MEMBERS_MEMBER_MARKER_MANAGEMENT_EDIT:
    'MEMBERS:MEMBER_MARKER_MANAGEMENT:EDIT',
  MEMBERS_MEMBER_MARKER_MANAGEMENT_DELETE:
    'MEMBERS:MEMBER_MARKER_MANAGEMENT:DELETE',
  MEMBERS_MEMBER_MARKER_MANAGEMENT_TOGGLE_ACTIVATION:
    'MEMBERS:MEMBER_MARKER_MANAGEMENT:TOGGLE_ACTIVATION',
  MEMBERS_LABEL_MANAGEMENT: 'MEMBERS:LABEL_MANAGEMENT',
  MEMBERS_LABEL_MANAGEMENT_LIST: 'MEMBERS:LABEL_MANAGEMENT:LIST',
  MEMBERS_LABEL_MANAGEMENT_VIEW_DETAILS:
    'MEMBERS:LABEL_MANAGEMENT:VIEW_DETAILS',
  MEMBERS_LABEL_MANAGEMENT_CREATE: 'MEMBERS:LABEL_MANAGEMENT:CREATE',
  MEMBERS_LABEL_MANAGEMENT_EDIT: 'MEMBERS:LABEL_MANAGEMENT:EDIT',
  MEMBERS_LABEL_MANAGEMENT_DELETE: 'MEMBERS:LABEL_MANAGEMENT:DELETE',
  MEMBERS_MEMBERS_ONLINE_ONLY: 'MEMBERS:MEMBERS_ONLINE',

  MEMBERS_MEMBER_BULK_UPDATE: 'MEMBERS:MEMBER_BULK_UPDATE',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_GAME_ROUNDS:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_UPDATE_GAME_ROUNDS',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_STATUS:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_UPDATE_STATUS',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_CREATE_NOTE:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_CREATE_NOTE',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_BALANCE:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_UPDATE_BALANCE',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_LABEL:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_UPDATE_LABEL',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_MEMBER_MARKER:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_UPDATE_MEMBER_MARKER',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_VIP:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_UPDATE_VIP',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_ACCOUNT_RESTRICTION:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_UPDATE_ACCOUNT_RESTRICTION',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_VERIFICATION_STATUS:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_UPDATE_VERIFICATION_STATUS',
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_ISSUE_BONUS:
    'MEMBERS:MEMBER_BULK_UPDATE:MEMBERS_BULK_UPDATE_ISSUE_BONUS',

  // MEMBERS_PROFILE_SETTING: 'MEMBERS:MEMBERS_PROFILE_SETTING',
  // MEMBERS_AUTOMATION: 'MEMBERS:AUTOMATION',
  // MEMBERS_AUTOMATION_LIST: 'MEMBERS:AUTOMATION:LIST',
  // MEMBERS_AUTOMATION_VIEW_DETAILS: 'MEMBERS:AUTOMATION:VIEW_DETAILS',
  // MEMBERS_AUTOMATION_CREATE: 'MEMBERS:AUTOMATION:CREATE',
  // MEMBERS_AUTOMATION_EDIT: 'MEMBERS:AUTOMATION:EDIT',
  // MEMBERS_AUTOMATION_DELETE: 'MEMBERS:AUTOMATION:DELETE',
  // MEMBERS_AUTOMATION_DUPLICATE: 'MEMBERS:AUTOMATION:DUPLICATE',

  MEMBERS_REPORTS: 'MEMBERS:REPORTS',
  MEMBERS_REPORTS_IP_ADDRESS_SUMMARY_REPORT:
    'MEMBERS:REPORTS:IP_ADDRESS_SUMMARY_REPORT',
  MEMBERS_REPORTS_MEMBER_ACCESS_HISTORY_REPORT:
    'MEMBERS:REPORTS:MEMBER_ACCESS_HISTORY_REPORT',
  MEMBERS_REPORTS_MEMBER_ACCESS_SUMMARY_REPORT:
    'MEMBERS:REPORTS:MEMBER_ACCESS_SUMMARY_REPORT',
  MEMBERS_REPORTS_MEMBER_INTERACTION_LOGS_REPORT:
    'MEMBERS:REPORTS:MEMBER_INTERACTION_LOGS_REPORT',
  MEMBERS_REPORTS_MEMBER_IP_ADDRESS_REPORT:
    'MEMBERS:REPORTS:MEMBER_IP_ADDRESS_REPORT',
  MEMBERS_REPORTS_MEMBER_SESSION_DURATION_REPORT:
    'MEMBERS:REPORTS:MEMBER_SESSION_DURATION_REPORT',

  MEMBERS_MANAGEMENT_CONFIG: 'MEMBERS:MEMBER_MANAGEMENT:CONFIG',
  MEMBERS_MANAGEMENT_CONFIG_VIEW: 'MEMBERS:MEMBER_MANAGEMENT:CONFIG:VIEW',
  MEMBERS_MANAGEMENT_CONFIG_EDIT: 'MEMBERS:MEMBER_MANAGEMENT:CONFIG:EDIT',

  // MEMBERS_REPORT: 'MEMBERS:MEMBERS:REPORT',
  // MEMBER_ACCESS_AUDIT_HISTORY: 'MEMBERS:MEMBERS:ACCESS:AUDIT:HISTORY',
  // MEMBER_IP_ADDRESS_SUMMARY: 'MEMBERS:MEMBERS:IP:ADDRESS:SUMMARY',
  // IP_ADDRESS_SUMMARY: 'MEMBERS:IP:ADDRESS:SUMMARY',
  // MEMBER_ACCESS_SUMMARY_REPORT: 'MEMBERS:MEMBER:ACCESS:SUMMARY:REPORT',
  // MEMBER_INTERACTION_LOG_REPORT: 'MEMBERS:MEMBER:INTERACTION:LOG:REPORT',
  // MEMBERS:REPORTS:MEMBER_ACCESS_HISTORY_REPORT
};
const ALL_AFFILIATES = {
  AFFILIATES: 'AFFILIATES',
  AFFILIATES_AFFILIATE_PROGRAMME: 'AFFILIATES:AFFILIATE_PROGRAMME',
  AFFILIATES_AFFILIATE_PROGRAMME_LIST: 'AFFILIATES:AFFILIATE_PROGRAMME:LIST',
  // "AFFILIATES_AFFILIATE_PROGRAMME_VIEW_DETAILS": "AFFILIATES:AFFILIATE_PROGRAMME:VIEW_DETAILS",
  AFFILIATES_AFFILIATE_PROGRAMME_CREATE:
    'AFFILIATES:AFFILIATE_PROGRAMME:CREATE',
  AFFILIATES_AFFILIATE_PROGRAMME_EDIT: 'AFFILIATES:AFFILIATE_PROGRAMME:EDIT',
  AFFILIATES_AFFILIATE_PROGRAMME_DELETE:
    'AFFILIATES:AFFILIATE_PROGRAMME:DELETE',
  AFFILIATES_AFFILIATE_PROGRAMME_TOGGLE_ACTIVATION:
    'AFFILIATES:AFFILIATE_PROGRAMME:TOGGLE_ACTIVATION',
  AFFILIATES_AFFILIATE_PROGRAMME_DUPLICATE:
    'AFFILIATES:AFFILIATE_PROGRAMME:DUPLICATE',
  AFFILIATES_AFFILIATE_CRITERIA: 'AFFILIATES:AFFILIATE_CRITERIA',
  AFFILIATES_AFFILIATE_CRITERIA_LIST: 'AFFILIATES:AFFILIATE_CRITERIA:LIST',
  // "AFFILIATES:AFFILIATE_CRITERIA:VIEW_DETAILS",
  AFFILIATES_AFFILIATE_CRITERIA_EDIT: 'AFFILIATES:AFFILIATE_CRITERIA:EDIT',
  AFFILIATES_AFFILIATE_REQUEST: 'AFFILIATES:AFFILIATE_REQUEST',
  AFFILIATES_AFFILIATE_REQUEST_LIST: 'AFFILIATES:AFFILIATE_REQUEST:LIST',
  AFFILIATES_AFFILIATE_REQUEST_VIEW_DETAILS:
    'AFFILIATES:AFFILIATE_REQUEST:VIEW_DETAILS',
  AFFILIATES_AFFILIATE_REQUEST_APPROVE: 'AFFILIATES:AFFILIATE_REQUEST:APPROVE',
  AFFILIATES_AFFILIATE_REQUEST_REJECT: 'AFFILIATES:AFFILIATE_REQUEST:REJECT',
  AFFILIATES_AFFILIATE_REQUEST_PROCESS: 'AFFILIATES:AFFILIATE_REQUEST:PROCESS',
};
const ALL_DEPOSITS = {
  DEPOSITS: 'DEPOSITS',
  DEPOSITS_INTERNAL_DEPOSITS: 'DEPOSITS:INTERNAL_DEPOSITS',
  DEPOSITS_INTERNAL_DEPOSITS_LIST: 'DEPOSITS:INTERNAL_DEPOSITS:LIST',
  DEPOSITS_INTERNAL_DEPOSITS_VIEW_DETAILS:
    'DEPOSITS:INTERNAL_DEPOSITS:VIEW_DETAILS',
  DEPOSITS_INTERNAL_DEPOSITS_APPROVE: 'DEPOSITS:INTERNAL_DEPOSITS:APPROVE',
  DEPOSITS_INTERNAL_DEPOSITS_REJECT: 'DEPOSITS:INTERNAL_DEPOSITS:REJECT',
  DEPOSITS_INTERNAL_DEPOSITS_PROCESS: 'DEPOSITS:INTERNAL_DEPOSITS:PROCESS',
  DEPOSITS_INTERNAL_DEPOSITS_UPDATE_REMARKS:
    'DEPOSITS:INTERNAL_DEPOSITS:UPDATE_REMARKS',
  DEPOSITS_EXTERNAL_DEPOSITS: 'DEPOSITS:EXTERNAL_DEPOSITS',
  DEPOSITS_EXTERNAL_DEPOSITS_LIST: 'DEPOSITS:EXTERNAL_DEPOSITS:LIST',
  DEPOSITS_EXTERNAL_DEPOSITS_VIEW_DETAILS:
    'DEPOSITS:EXTERNAL_DEPOSITS:VIEW_DETAILS',
  DEPOSITS_EXTERNAL_DEPOSITS_APPROVE: 'DEPOSITS:EXTERNAL_DEPOSITS:APPROVE',
  DEPOSITS_EXTERNAL_DEPOSITS_REJECT: 'DEPOSITS:EXTERNAL_DEPOSITS:REJECT',
  DEPOSITS_EXTERNAL_DEPOSITS_PROCESS: 'DEPOSITS:EXTERNAL_DEPOSITS:PROCESS',
  DEPOSITS_EXTERNAL_DEPOSITS_UPDATE_REMARKS:
    'DEPOSITS:EXTERNAL_DEPOSITS:UPDATE_REMARKS',
  DEPOSITS_PAYMENT_METHODS: 'DEPOSITS:PAYMENT_METHODS',
  DEPOSITS_PAYMENT_METHODS_LIST: 'DEPOSITS:PAYMENT_METHODS:LIST',
  DEPOSITS_PAYMENT_METHODS_VIEW_DETAILS:
    'DEPOSITS:PAYMENT_METHODS:VIEW_DETAILS',
  DEPOSITS_PAYMENT_METHODS_CREATE: 'DEPOSITS:PAYMENT_METHODS:CREATE',
  DEPOSITS_PAYMENT_METHODS_VIEW_PAYMENT_SETTINGS:
    'DEPOSITS:PAYMENT_METHODS:VIEW_PAYMENT_SETTINGS',
  DEPOSITS_PAYMENT_METHODS_EDIT_PAYMENT_SETTINGS:
    'DEPOSITS:PAYMENT_METHODS:EDIT_PAYMENT_SETTINGS',
  DEPOSITS_PAYMENT_METHODS_TOGGLE_ENABLED:
    'DEPOSITS:PAYMENT_METHODS:TOGGLE_ENABLED',
  DEPOSITS_PAYMENT_METHODS_EDIT: 'DEPOSITS:PAYMENT_METHODS:EDIT',
  DEPOSITS_PAYMENT_METHODS_DUPLICATE: 'DEPOSITS:PAYMENT_METHODS:DUPLICATE',
  DEPOSITS_PAYMENT_METHODS_DELETE: 'DEPOSITS:PAYMENT_METHODS:DELETE',
  DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS:
    'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS',
  DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_LIST:
    'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS:LIST',
  DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_VIEW_DETAILS:
    'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS:VIEW_DETAILS',
  DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_CREATE:
    'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS:CREATE',
  DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_EDIT:
    'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS:EDIT',
  DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_DELETE:
    'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS:DELETE',
  DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_DUPLICATE:
    'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS:DUPLICATE',
};
const ALL_WITHDRAWALS = {
  WITHDRAWALS: 'WITHDRAWALS',
  WITHDRAWALS_WITHDRAWAL_REQUESTS: 'WITHDRAWALS:WITHDRAWAL_REQUESTS',
  WITHDRAWALS_WITHDRAWAL_REQUESTS_LIST: 'WITHDRAWALS:WITHDRAWAL_REQUESTS:LIST',
  WITHDRAWALS_WITHDRAWAL_REQUESTS_VIEW_DETAILS:
    'WITHDRAWALS:WITHDRAWAL_REQUESTS:VIEW_DETAILS',
  WITHDRAWALS_WITHDRAWAL_REQUESTS_EDIT: 'WITHDRAWALS:WITHDRAWAL_REQUESTS:EDIT',
  WITHDRAWALS_WITHDRAWAL_REQUESTS_APPROVE:
    'WITHDRAWALS:WITHDRAWAL_REQUESTS:APPROVE',
  WITHDRAWALS_WITHDRAWAL_REQUESTS_REJECT:
    'WITHDRAWALS:WITHDRAWAL_REQUESTS:REJECT',
  WITHDRAWALS_WITHDRAWAL_REQUESTS_PROCESS:
    'WITHDRAWALS:WITHDRAWAL_REQUESTS:PROCESS',
  WITHDRAWALS_WITHDRAWAL_REQUESTS_ON_HOLD:
    'WITHDRAWALS:WITHDRAWAL_REQUESTS:ON_HOLD',
  WITHDRAWALS_WITHDRAWAL_METHODS: 'WITHDRAWALS:WITHDRAWAL_METHODS',
  WITHDRAWALS_WITHDRAWAL_METHODS_LIST: 'WITHDRAWALS:WITHDRAWAL_METHODS:LIST',
  WITHDRAWALS_WITHDRAWAL_METHODS_VIEW_DETAILS:
    'WITHDRAWALS:WITHDRAWAL_METHODS:VIEW_DETAILS',
  WITHDRAWALS_WITHDRAWAL_METHODS_CREATE:
    'WITHDRAWALS:WITHDRAWAL_METHODS:CREATE',
  WITHDRAWALS_WITHDRAWAL_METHODS_EDIT: 'WITHDRAWALS:WITHDRAWAL_METHODS:EDIT',
  WITHDRAWALS_WITHDRAWAL_METHODS_DELETE:
    'WITHDRAWALS:WITHDRAWAL_METHODS:DELETE',
  WITHDRAWALS_WITHDRAWAL_METHODS_DUPLICATE:
    'WITHDRAWALS:WITHDRAWAL_METHODS:DUPLICATE',
  WITHDRAWALS_WITHDRAWAL_METHODS_TOGGLE_ENABLED:
    'WITHDRAWALS:WITHDRAWAL_METHODS:TOGGLE_ENABLED',
  WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS: 'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS',
  WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_LIST:
    'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS:LIST',
  WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_VIEW_DETAILS:
    'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS:VIEW_DETAILS',
  WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_CREATE:
    'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS:CREATE',
  WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_EDIT:
    'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS:EDIT',
  WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_DUPLICATE:
    'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS:DUPLICATE',
  WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_DELETE:
    'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS:DELETE',
  WITHDRAWALS_COMPLIANCE_CHECK: 'WITHDRAWALS:COMPLIANCE_CHECK',
  WITHDRAWALS_COMPLIANCE_CHECK_VIEW: 'WITHDRAWALS:COMPLIANCE_CHECK:VIEW',
  WITHDRAWALS_COMPLIANCE_CHECK_EDIT: 'WITHDRAWALS:COMPLIANCE_CHECK:EDIT',
};

const ALL_PROMOS = {
  PROMOS: 'PROMOS',
  PROMOS_PROMO_LISTING: 'PROMOS:PROMO_LISTING',
  PROMOS_PROMO_LISTING_PROMO_LIST: 'PROMOS:PROMO_LISTING:PROMO:LIST',
  PROMOS_PROMO_LISTING_PROMO_VIEW_DETAILS:
    'PROMOS:PROMO_LISTING:PROMO:VIEW_DETAILS',
  PROMOS_PROMO_LISTING_PROMO_CREATE: 'PROMOS:PROMO_LISTING:PROMO:CREATE',
  PROMOS_PROMO_LISTING_PROMO_EDIT: 'PROMOS:PROMO_LISTING:PROMO:EDIT',
  PROMOS_PROMO_LISTING_PROMO_DUPLICATE: 'PROMOS:PROMO_LISTING:PROMO:DUPLICATE',
  PROMOS_PROMO_LISTING_PROMO_DELETE: 'PROMOS:PROMO_LISTING:PROMO:DELETE',
  PROMOS_PROMO_LISTING_PROMO_TOGGLE_PUBLISHED:
    'PROMOS:PROMO_LISTING:PROMO:TOGGLE_PUBLISHED',
  PROMOS_PROMO_LISTING_CATEGORY_LIST: 'PROMOS:PROMO_LISTING:CATEGORY:LIST',
  // PROMOS_PROMO_LISTING_CATEGORY_VIEW: 'PROMOS:PROMO_LISTING:CATEGORY:VIEW',
  PROMOS_PROMO_LISTING_CATEGORY_CREATE: 'PROMOS:PROMO_LISTING:CATEGORY:CREATE',
  PROMOS_PROMO_LISTING_CATEGORY_EDIT: 'PROMOS:PROMO_LISTING:CATEGORY:EDIT',
  PROMOS_PROMO_LISTING_CATEGORY_DELETE: 'PROMOS:PROMO_LISTING:CATEGORY:DELETE',
  PROMOS_PROMO_REQUEST: 'PROMOS:PROMO_REQUEST',
  PROMOS_PROMO_REQUEST_LIST: 'PROMOS:PROMO_REQUEST:LIST',
  PROMOS_PROMO_REQUEST_VIEW_DETAILS: 'PROMOS:PROMO_REQUEST:VIEW_DETAILS',
  PROMOS_PROMO_REQUEST_APPROVE: 'PROMOS:PROMO_REQUEST:APPROVE',
  PROMOS_PROMO_REQUEST_REJECT: 'PROMOS:PROMO_REQUEST:REJECT',
  PROMOS_PROMO_REQUEST_PROCESS: 'PROMOS:PROMO_REQUEST:PROCESS',
  PROMOS_PROMO_LABELS: 'PROMOS:PROMO_LABELS',
  PROMOS_PROMO_LABELS_LIST: 'PROMOS:PROMO_LABELS:LIST',
  PROMOS_PROMO_LABELS_CREATE: 'PROMOS:PROMO_LABELS:CREATE',
  PROMOS_PROMO_LABELS_EDIT: 'PROMOS:PROMO_LABELS:EDIT',
  PROMOS_PROMO_LABELS_DELETE: 'PROMOS:PROMO_LABELS:DELETE',
  // PROMOS_PROMO_LABELS: 'PROMOS:PROMO_LABELS',
  // PROMOS_PROMO_CONFLICT: 'PROMOS:PROMO_CONFLIC T',
};

const ALL_VIP = {
  VIP: 'VIP',
  VIP_VIP: 'VIP:VIP',
  VIP_VIP_LIST: 'VIP:VIP:LIST',
  VIP_VIP_VIEW_DETAILS: 'VIP:VIP:VIEW_DETAILS',
  VIP_VIP_CREATE: 'VIP:VIP:CREATE',
  VIP_VIP_EDIT: 'VIP:VIP:EDIT',
  VIP_VIP_DUPLICATE: 'VIP:VIP:DUPLICATE',
  VIP_VIP_DELETE: 'VIP:VIP:DELETE',
  VIP_VIP_TOGGLE_ENABLED: 'VIP:VIP:TOGGLE_ENABLED',
  VIP_VIP_SUBMIT: 'VIP:VIP:SUBMIT',
};

const ALL_REBATES = {
  REBATES: 'REBATES',
  REBATES_REBATES_REBATE_GROUP: 'REBATES:REBATES:REBATE_GROUP',
  REBATES_REBATES_REBATE_GROUP_LIST: 'REBATES:REBATES:REBATE_GROUP:LIST',
  REBATES_REBATES_REBATE_GROUP_CREATE: 'REBATES:REBATES:REBATE_GROUP:CREATE',
  REBATES_REBATES_REBATE_GROUP_EDIT: 'REBATES:REBATES:REBATE_GROUP:EDIT',
  REBATES_REBATES_REBATE_GROUP_DELETE: 'REBATES:REBATES:REBATE_GROUP:DELETE',
  // REBATES_REBATES_REBATE_GROUP_VIEW: 'REBATES:REBATES:REBATE_GROUP:VIEW',
  REBATES_REBATES_REBATE_GROUP_DUPLICATE:
    'REBATES:REBATES:REBATE_GROUP:DUPLICATE',
};
const ALL_REPORTS = {
  REPORTS: 'REPORTS',
  REPORTS_DAILY_OPERATING_REPORT: 'REPORTS:DAILY_OPERATING_REPORT',
  REPORTS_DAILY_OPERATING_REPORT_LIST: 'REPORTS:DAILY_OPERATING_REPORT:LIST',
  REPORTS_DAILY_OPERATING_REPORT_CSV_DOWNLOAD_BUTTON:
    'REPORTS:DAILY_OPERATING_REPORT:CSV_DOWNLOAD_BUTTON',
  REPORTS_MEMBER_BET_RECORDS: 'REPORTS:MEMBER_BET_RECORDS',
  REPORTS_MEMBER_BET_RECORDS_LIST: 'REPORTS:MEMBER_BET_RECORDS:LIST',
  // REPORTS_MEMBER_BET_RECORDS_BULK_UPDATE:
  //   'REPORTS:MEMBER_BET_RECORDS:BULK_UPDATE',
  REPORTS_MEMBER_BET_RECORDS_CSV_DOWNLOAD_BUTTON:
    'REPORTS:MEMBER_BET_RECORDS:CSV_DOWNLOAD_BUTTON',
  REPORTS_MEMBER_PROMO_HISTORY_RECORDS: 'REPORTS:MEMBER_PROMO_HISTORY_RECORDS',
  REPORTS_MEMBER_PROMO_HISTORY_RECORDS_LIST:
    'REPORTS:MEMBER_PROMO_HISTORY_RECORDS:LIST',
  REPORTS_MEMBER_PROMO_HISTORY_RECORDS_CSV_DOWNLOAD_BUTTON:
    'REPORTS:MEMBER_PROMO_HISTORY_RECORDS:CSV_DOWNLOAD_BUTTON',
  REPORTS_BALANCE_TRANSACTION_REPORTS: 'REPORTS:BALANCE_TRANSACTION_REPORTS',
  REPORTS_BALANCE_TRANSACTION_REPORTS_LIST:
    'REPORTS:BALANCE_TRANSACTION_REPORTS:LIST',
  REPORTS_BALANCE_TRANSACTION_REPORTS_CSV_DOWNLOAD_BUTTON:
    'REPORTS:BALANCE_TRANSACTION_REPORTS:CSV_DOWNLOAD_BUTTON',
  REPORTS_PROMO_PAYOUT_RECORDS: 'REPORTS:PROMO_PAYOUT_RECORDS',
  REPORTS_PROMO_PAYOUT_RECORDS_LIST: 'REPORTS:PROMO_PAYOUT_RECORDS:LIST',
  REPORTS_PROMO_PAYOUT_RECORDS_CSV_DOWNLOAD_BUTTON:
    'REPORTS:PROMO_PAYOUT_RECORDS:CSV_DOWNLOAD_BUTTON',
  REPORTS_REBATES_REPORTS: 'REPORTS:REBATES_REPORTS',
  REPORTS_REBATES_REPORTS_LIST: 'REPORTS:REBATES_REPORTS:LIST',
  REPORTS_REBATES_REPORTS_CSV_DOWNLOAD_BUTTON:
    'REPORTS:REBATES_REPORTS:CSV_DOWNLOAD_BUTTON',
  REPORTS_BALANCE_SERVICE_RECORD_REPORTS:
    'REPORTS:BALANCE_SERVICE_RECORD_REPORTS',
  REPORTS_BALANCE_SERVICE_RECORD_REPORTS_LIST:
    'REPORTS:BALANCE_SERVICE_RECORD_REPORTS:LIST',
  REPORTS_BALANCE_SERVICE_RECORD_REPORTS_CSV_DOWNLOAD_BUTTON:
    'REPORTS:BALANCE_SERVICE_RECORD_REPORTS:CSV_DOWNLOAD_BUTTON',
  REPORTS_MEMBER_SUMMARY_REPORT: 'REPORTS:MEMBER_SUMMARY_REPORT',
  REPORTS_MEMBER_SUMMARY_REPORT_LIST: 'REPORTS:MEMBER_SUMMARY_REPORT:LIST',
  REPORTS_MEMBER_SUMMARY_REPORT_CREATE: 'REPORTS:MEMBER_SUMMARY_REPORT:CREATE',
  REPORTS_MEMBER_SUMMARY_REPORT_CSV_DOWNLOAD_BUTTON:
    'REPORTS:MEMBER_SUMMARY_REPORT:DOWNLOAD_SPREADSHEET',
  REPORTS_EU_REPORTS: 'REPORTS:EU_REPORTS',
  REPORTS_EU_REPORTS_CSV_DOWNLOAD_BUTTON:
    'REPORTS:EU_REPORTS:CSV_DOWNLOAD_BUTTON',
  REPORTS_EU_REPORTS_DAILY_DEPOSIT_REPORT:
    'REPORTS:EU_REPORTS:DAILY_DEPOSIT_REPORT',
  REPORTS_EU_REPORTS_DAILY_PLAYER_ACTIVITIES_REPORT:
    'REPORTS:EU_REPORTS:DAILY_PLAYER_ACTIVITIES_REPORT',
  REPORTS_EU_REPORTS_DAILY_SELF_EXCLUSION_REPORT:
    'REPORTS:EU_REPORTS:DAILY_SELF_EXCLUSION_REPORT',
  REPORTS_EU_REPORTS_DAILY_TIMEOUT_REPORT:
    'REPORTS:EU_REPORTS:DAILY_TIMEOUT_REPORT',
  REPORTS_EU_REPORTS_DAILY_TRANSACTION_REPORT:
    'REPORTS:EU_REPORTS:DAILY_TRANSACTION_REPORT',
  REPORTS_EU_REPORTS_DEPOSIT_LIMIT_REPORT:
    'REPORTS:EU_REPORTS:DEPOSIT_LIMIT_REPORT',
  REPORTS_EU_REPORTS_FULL_CUSTOMER_DUMPS:
    'REPORTS:EU_REPORTS:FULL_CUSTOMER_DUMPS',
  REPORTS_EU_REPORTS_NEWLY_REGISTERED_MEMBERS_REPORT:
    'REPORTS:EU_REPORTS:NEWLY_REGISTERED_MEMBERS_REPORT',
  REPORTS_EU_REPORTS_PLAYER_STATUS_REPORT:
    'REPORTS:EU_REPORTS:PLAYER_STATUS_REPORT',
  // NOTE: Adding this permission violates the first rule imposed above
};
const ALL_SYSTEM_MANAGEMENT = {
  SYSTEM_MANAGEMENT: 'SYSTEM_MANAGEMENT',
  SYSTEM_MANAGEMENT_SITE_CONFIG: 'SYSTEM_MANAGEMENT:SITE_CONFIG',
  // SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_LIST:
  //   'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:LIST',
  // SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_VIEW_DETAILS:
  //   'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:VIEW_DETAILS',
  // SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_CREATE:
  //   'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:CREATE',
  // SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_EDIT:
  //   'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:EDIT',
  // SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_DELETE:
  //   'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:DELETE',
  // SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_DUPLICATE:
  //   'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:DUPLICATE',
  SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_VIEW_DETAILS:
    'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:VIEW_DETAILS',
  SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_LIST:
    'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:LIST',
  SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_CREATE:
    'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:CREATE',
  SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_EDIT:
    'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:EDIT',
  SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_DELETE:
    'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:DELETE',
  SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN_DUPLICATE:
    'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:DUPLICATE',

  SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS:
    'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS',
  SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_VIEW_DETAILS:
    'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS:VIEW_DETAILS',
  SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_LIST:
    'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS:LIST',
  SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_CREATE:
    'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS:CREATE',
  // SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_EDIT:
  //   'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS:EDIT',
  // SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_DUPLICATE:
  //   'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS:DUPLICATE',
  // SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_DELETE:
  //   'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS:DELETE',
  // SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_TOGGLE_ENABLED:
  //   'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS:TOGGLE_ENABLED',
  SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG: 'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG',
  SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_CATEGORY_LIST:
    'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG:CATEGORY:LIST',
  SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_CATEGORY_VIEW_DETAILS:
    'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG:CATEGORY:VIEW_DETAILS',
  SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_CATEGORY_CREATE:
    'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG:CATEGORY:CREATE',
  SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_CATEGORY_EDIT:
    'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG:CATEGORY:EDIT',
  SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_CATEGORY_DELETE:
    'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG:CATEGORY:DELETE',
  SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_CATEGORY_ADD_VENDOR:
    'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG:CATEGORY:ADD_VENDOR',
  SYSTEM_MANAGEMENT_MARQUEE_MESSAGES: 'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES',
  SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_LIST:
    'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES:LIST',
  SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_VIEW_DETAILS:
    'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES:VIEW_DETAILS',
  SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_CREATE:
    'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES:CREATE',
  SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_EDIT:
    'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES:EDIT',
  SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_DELETE:
    'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES:DELETE',
  SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_TOGGLE_ENABLED:
    'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES:TOGGLE_ENABLED',
  SYSTEM_MANAGEMENT_ROTATING_BANNER: 'SYSTEM_MANAGEMENT:ROTATING_BANNER',
  SYSTEM_MANAGEMENT_ROTATING_BANNER_LIST:
    'SYSTEM_MANAGEMENT:ROTATING_BANNER:LIST',
  SYSTEM_MANAGEMENT_ROTATING_BANNER_VIEW_DETAILS:
    'SYSTEM_MANAGEMENT:ROTATING_BANNER:VIEW_DETAILS',
  SYSTEM_MANAGEMENT_ROTATING_BANNER_CREATE:
    'SYSTEM_MANAGEMENT:ROTATING_BANNER:CREATE',
  SYSTEM_MANAGEMENT_ROTATING_BANNER_EDIT:
    'SYSTEM_MANAGEMENT:ROTATING_BANNER:EDIT',
  SYSTEM_MANAGEMENT_ROTATING_BANNER_DUPLICATE:
    'SYSTEM_MANAGEMENT:ROTATING_BANNER:DUPLICATE',
  SYSTEM_MANAGEMENT_ROTATING_BANNER_DELETE:
    'SYSTEM_MANAGEMENT:ROTATING_BANNER:DELETE',
  SYSTEM_MANAGEMENT_ROTATING_BANNER_TOGGLE_ENABLE:
    'SYSTEM_MANAGEMENT:ROTATING_BANNER:TOGGLE_ENABLE',
  SYSTEM_MANAGEMENT_ROTATING_BANNER_BANNER_SETTINGS_VIEW:
    'SYSTEM_MANAGEMENT:ROTATING_BANNER:BANNER_SETTINGS:VIEW',
  SYSTEM_MANAGEMENT_ROTATING_BANNER_BANNER_SETTINGS_EDIT:
    'SYSTEM_MANAGEMENT:ROTATING_BANNER:BANNER_SETTINGS:EDIT',
  SYSTEM_MANAGEMENT_GEO_FENCING: 'SYSTEM_MANAGEMENT:GEO_FENCING',
  SYSTEM_MANAGEMENT_GEO_FENCING_VIEW: 'SYSTEM_MANAGEMENT:GEO_FENCING:VIEW',
  SYSTEM_MANAGEMENT_GEO_FENCING_EDIT: 'SYSTEM_MANAGEMENT:GEO_FENCING:EDIT',
  SYSTEM_MANAGEMENT_SYSTEM_CONFIG: 'SYSTEM_MANAGEMENT:SYSTEM_CONFIG',
  SYSTEM_MANAGEMENT_SYSTEM_CONFIG_FORCE_VERIFICATION_VIEW:
    'SYSTEM_MANAGEMENT:SYSTEM_CONFIG:VIEW:FORCE_VERIFICATION',
  SYSTEM_MANAGEMENT_SYSTEM_CONFIG_FORCE_VERIFICATION_EDIT:
    'SYSTEM_MANAGEMENT:SYSTEM_CONFIG:EDIT:FORCE_VERIFICATION',
  SYSTEM_MANAGEMENT_COMMON_CONFIG: 'SYSTEM_MANAGEMENT:COMMON_CONFIG',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_MANAGE_CURRENCY:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:MANAGE_CURRENCY',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_MANAGE_COUNTRY:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:MANAGE_COUNTRY',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_MANAGE_COUNTRY_CODE:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:MANAGE_COUNTRY_CODE',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_MANAGE_REALITY_CHECK:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:MANAGE_REALITY_CHECK',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_EMAIL_AUDIT_TRAIL:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:EMAIL_AUDIT_TRAIL',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_CHECK_FRAUD:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:CHECK_FRAUD',

  SYSTEM_MANAGEMENT_COMMON_CONFIG_SESSION_MANAGEMENT:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:SESSION_MANAGEMENT',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_HEXOPAY_SHOP_DETAILS:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:HEXOPAY_SHOP_DETAILS',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_NETELLER_SHOP_DETAILS:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:NETELLER_SHOP_DETAILS',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_SKRILL_SHOP_DETAILS:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:SKRILL_SHOP_DETAILS',
  ...(isRow && {
    SYSTEM_MANAGEMENT_COMMON_CONFIG_WONKAPAY_SHOP_DETAILS:
      'SYSTEM_MANAGEMENT:COMMON_CONFIG:WONKAPAY_SHOP_DETAILS',
    SYSTEM_MANAGEMENT_COMMON_CONFIG_WITHDRAWAL_CANCELATION:
      'SYSTEM_MANAGEMENT:COMMON_CONFIG:WITHDRAWAL_CANCELATION',
  }),
  SYSTEM_MANAGEMENT_COMMON_CONFIG_MANAGE_EMAIL_CONTENT:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:MANAGE_EMAIL_CONTENT',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_MANAGE_SMTP_CONFIG:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:MANAGE_SMTP_CONFIG',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_MANAGE_DYNAMIC_PAGES:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:MANAGE_DYNAMIC_PAGES',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_LOQATE_INTEGRATION:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:LOQATE_INTEGRATION',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_CHECK_DEPOSIT_LIMIT:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:CHECK_DEPOSIT_LIMIT',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_SIGN_UP_CONFIG:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:SIGN_UP_CONFIG',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_GBG_MANAGEMENT:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:GBG_MANAGEMENT',
  SYSTEM_MANAGEMENT_COMMON_CONFIG_CHECK_GAMSTOP:
    'SYSTEM_MANAGEMENT:COMMON_CONFIG:CHECK_GAMSTOP',
  SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG: 'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG',
  SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_CATEGORY_LIST:
    'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG:CATEGORY:LIST',
  SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_CATEGORY_VIEW_DETAILS:
    'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG:CATEGORY:VIEW_DETAILS',
  SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_CATEGORY_CREATE:
    'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG:CATEGORY:CREATE',
  SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_CATEGORY_EDIT:
    'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG:CATEGORY:EDIT',
  SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_CATEGORY_DELETE:
    'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG:CATEGORY:DELETE',
  SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_CATEGORY_DUPLICATE:
    'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG:CATEGORY:DUPLICATE',
  SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_CATEGORY_ADD_VENDOR:
    'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG:CATEGORY:ADD_VENDOR',
};
const ALL_SYSTEM_MESSAGES = {
  SYSTEM_MESSAGES: 'SYSTEM_MESSAGES',
  SYSTEM_MESSAGES_MANUAL_MESSAGE: 'SYSTEM_MESSAGES:MANUAL_MESSAGE',
  SYSTEM_MESSAGES_MANUAL_MESSAGE_LIST: 'SYSTEM_MESSAGES:MANUAL_MESSAGE:LIST',
  SYSTEM_MESSAGES_MANUAL_MESSAGE_VIEW_DETAILS:
    'SYSTEM_MESSAGES:MANUAL_MESSAGE:VIEW_DETAILS',
  SYSTEM_MESSAGES_MANUAL_MESSAGE_CREATE:
    'SYSTEM_MESSAGES:MANUAL_MESSAGE:CREATE',

  SYSTEM_MESSAGES_AUTOMATIC_MESSAGE: 'SYSTEM_MESSAGES:AUTOMATIC_MESSAGE',
  SYSTEM_MESSAGES_AUTOMATIC_MESSAGE_LIST:
    'SYSTEM_MESSAGES:AUTOMATIC_MESSAGE:LIST',
  SYSTEM_MESSAGES_AUTOMATIC_MESSAGE_VIEW_DETAILS:
    'SYSTEM_MESSAGES:AUTOMATIC_MESSAGE:VIEW_DETAILS',
  SYSTEM_MESSAGES_AUTOMATIC_MESSAGE_CREATE:
    'SYSTEM_MESSAGES:AUTOMATIC_MESSAGE:CREATE',
  SYSTEM_MESSAGES_AUTOMATIC_MESSAGE_EDIT:
    'SYSTEM_MESSAGES:AUTOMATIC_MESSAGE:EDIT',
  SYSTEM_MESSAGES_AUTOMATIC_MESSAGE_TOGGLE_STATE:
    'SYSTEM_MESSAGES:AUTOMATIC_MESSAGE:TOGGLE_STATE',
};
const ALL_WATCHLIST = {
  WATCHLIST: 'WATCHLIST',
  WATCHLIST_WATCHLIST: 'WATCHLIST:WATCHLIST',
};

export default {
  ALL_DASHBOARDS,
  ALL_OPERATORS,
  ALL_MEMBERS,
  ALL_AFFILIATES,
  ALL_DEPOSITS,
  ALL_WITHDRAWALS,
  ALL_PROMOS,
  ALL_VIP,
  ALL_REBATES,
  ALL_REPORTS,
  ALL_SYSTEM_MANAGEMENT,
  ALL_SYSTEM_MESSAGES,
  ALL_WATCHLIST,
};
