import gql from 'graphql-tag';

export const CREATE_GAME_CATEGORY = gql`
  mutation CreateGameCategory($input: CreateGameCategoryInput!) {
    createGameCategory(input: $input)
  }
`;

export const UPDATE_VENDOR = gql`
  mutation UpdateVendor($id: ID!, $input: UpdateVendorInput!) {
    updateVendor(id: $id, input: $input)
  }
`;

export const UPDATE_GAME_CATEGORY = gql`
  mutation UpdateGameCategory($id: ID!, $input: UpdateGameCategoryInput!) {
    updateGameCategory(id: $id, input: $input)
  }
`;

export const DELETE_GAME_CATEGORY = gql`
  mutation DeleteGameCategory($id: ID!) {
    deleteGameCategory(id: $id)
  }
`;

export default {};
