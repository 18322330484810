import { Skeleton } from 'antd';
import React from 'react';
import styled from 'styled-components';

function TableCellSpinner() {
  return (
    <Wrapper>
      <Skeleton paragraph={{ rows: 1 }} title={false} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .ant-skeleton-content .ant-skeleton-paragraph {
    margin-bottom: 0;
    li {
      width: 100% !important;
    }
  }
`;

export default TableCellSpinner;
