import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormikProps } from 'formik';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Switch } from 'antd';
import UpgradeAward from './components/NewUpgradeAward';

const { Item } = Form;

const colStyles = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

type Props = {
  formik?: FormikProps<Record<string, any>>;
};

const Awards: React.FC<Props> = ({ formik = {} }) => {
  const intl = useIntl();

  const formProps = {
    formik,
    colStyles,
    intl,
  };

  const { setFieldValue, values } = formik as Record<string, any>;

  const { autoPayout } = values as Record<any, any>;

  return (
    <div style={{ paddingBottom: '50px' }}>
      <Item
        colon={false}
        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        label={
          <strong>
            <FormattedMessage
              id="award-settings.text"
              defaultMessage="Award Settings"
            />
          </strong>
        }
        {...colStyles}
      >
        &nbsp;
      </Item>
      <Item
        label={
          <FormattedMessage
            id="auto-payout.text"
            defaultMessage="Auto Payout"
          />
        }
        {...colStyles}
      >
        <Switch
          checkedChildren={
            <FormattedMessage id="on.text" defaultMessage="On" />
          }
          unCheckedChildren={
            <FormattedMessage id="off.text" defaultMessage="Off" />
          }
          defaultChecked
          checked={autoPayout}
          onChange={(bool) => {
            setFieldValue('autoPayout', bool);
          }}
        />
      </Item>
      <UpgradeAward {...formProps} />
    </div>
  );
};

export default Awards;
