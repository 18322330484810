import gql from 'graphql-tag';

export const DEPOSITS = gql`
  query Deposits($id: ID, $first: Int) {
    member(id: $id) {
      id
      deposits(first: $first) {
        totalCount
        edges {
          node {
            id
            manualAdjustment
            amount
            remarks
            firstDeposit
            status
            serialCode
            dateTimeCreated
            transactionReference
            paymentMethod {
              id
              name
              ... on OfflineBankTransferPaymentMethod {
                bank
                accountName
              }
            }
            ... on HexoPayDeposit {
              hexoPayUid
              hexoPayCreditCard {
                firstDigit
                firstSixDigits
                lastFourDigits
                expirationMonth
                expirationYear
              }
            }
            ... on CryptocurrencyDeposit {
              id
              depositorBlockchainAddress
              isCrypto
            }
            ... on SkrillDeposit {
              id
              transactionReference
            }
            ... on NetellerDeposit {
              id
              transactionReference
            }
            amountInBaseCurrency
            currency
            exchangeRate
          }
        }
      }
    }
  }
`;

export const MEMBER_WITHDRAWALS = gql`
  query Withdrawals($first: Int, $memberId: ID!) {
    member(id: $memberId) {
      id
      withdrawals(first: $first) {
        totalCount
        edges {
          cursor
          node {
            id
            amount
            status
            externalStatus
            serialCode
            dateTimeCreated
            manualAdjustment
            remarks
            firstWithdrawal
            currency
            isCrypto
            bankAccount {
              id
              accountName
              bank
              accountNumber
              city
              province
              branch
            }
            withdrawalMethod {
              id
              name
            }
            hexoPayCreditCard {
              firstDigit
              lastFourDigits
              expirationMonth
              expirationYear
            }
            transactionReference
            transactionDetails
          }
        }
      }
    }
  }
`;
