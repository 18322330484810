import { useApolloClient } from '@apollo/react-hooks';
import FiltersProvider from 'contexts/Filters';
import SelectedRowsProvider from 'contexts/SelectedRows';
import { GET_MEMBERS, GET_MEMBERS_IDS } from 'graphql/queries/member.query';
import { Page } from 'interfaces/user.interface';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import SharedStyledContent from 'styles/SharedStyledContent';
import { SharedStyledLayout } from 'styles/SharedStyledLayout';
import { useScreenTabV2 } from 'store/screenTabState';
import coercedGet from 'utils/coercedGet';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import { randomKey } from 'utils/randomKey';
import { ContentMemberManagement } from './components/Content/ContentMemberManagement';
import { HeaderMemberManagement } from './components/Header/HeaderMemberManagement';
import { MemberManagementProvider } from './MemberManagementContext';
import { formatFilters } from './util';

const pageInitState = {
  first: 10,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
};

type Props = {
  setKey: (e: string) => void;
  key: string;
};

export const NewMemberManagement = ({ setKey, key }: Props) => {
  const { thisTab, updateTab, useUpdateTab } = useScreenTabV2(
    'member-management'
  );
  const filter = coercedGet(thisTab, 'filter', {});

  const initialFilters = {};

  const [filters, setFilters] = useState(
    isEmpty(filter) ? initialFilters : { ...filter }
  );

  const [page, setPage] = useState<Page>(pageInitState);
  const client = useApolloClient();
  const handleFilters = (filterData: any) => {
    const filterToUse = !filterData ? initialFilters : filterData;
    setFilters(filterToUse);
    updateTab({
      filter: filterToUse,
    });
  };

  useUpdateTab(() => {
    setPage((prevData: any) => {
      const { first, ...restOfPageInitState } = pageInitState;
      return {
        first: prevData.first,
        ...restOfPageInitState,
      };
    });

    setFilters(coercedGet(thisTab, 'filter', filter));
  });

  const handleNext = (pageInfo: any) => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after: string = savedCursor[prevPage];
    if (!after) {
      delete page.after;
    }
    setPage({
      ...page,
      ...(after && { after }),
      currentPage: prevPage,
    });
  };

  const formattedFilters = React.useMemo(() => formatFilters(filters), [
    filters,
  ]);

  const defaultFilters = {};

  const {
    loading: memberLoading,
    error,
    data: members = {},
    refetchVariables: refetchParams,
    finalQueryFilter,
  } = usePartialFiltersQuery(
    GET_MEMBERS_IDS,
    GET_MEMBERS,
    'members.edges',
    formattedFilters,
    page,
    [
      'username',
      'realName',
      'email',
      'lastLoginIpAddress',
      'registrationIpAddress',
      'brandId',
      'platformId',
      'mobileNumber',
      'qqid',
      'wechatId',
      'postalCode',
      'tags_next',
      'affiliateId',
    ],
    'network-only'
  );

  const refetchMemberManagement = () => {
    setTimeout(
      () =>
        client.query({
          query: GET_MEMBERS,
          variables: refetchParams,
          fetchPolicy: 'network-only',
        }),
      1000
    );
  };

  return (
    <MemberManagementProvider
      refreshPage={() => setKey(randomKey())}
      refetchParams={refetchParams}
      refetchMemberManagement={refetchMemberManagement}
    >
      <SelectedRowsProvider key={key}>
        <SharedStyledLayout style={{ height: 'calc(100vh - 40px - 45px)' }}>
          <SharedStyledContent>
            <FiltersProvider
              initialValues={initialFilters}
              defaultValues={defaultFilters}
              tabId="member-management"
            >
              <HeaderMemberManagement
                initialFilters={initialFilters}
                onFilterChange={handleFilters}
                className="my-3 d-flex align-items-center px-3"
                isLoading={memberLoading}
                filters={filters}
                refetchParams={refetchParams}
              />

              <ContentMemberManagement
                error={error}
                members={members}
                memberLoading={memberLoading}
                setPage={setPage}
                filters={filters}
                page={page}
                handlePrev={handlePrev}
                handleNext={handleNext}
                onFilterChange={handleFilters}
                finalQueryFilter={finalQueryFilter}
              />
            </FiltersProvider>
          </SharedStyledContent>
        </SharedStyledLayout>
      </SelectedRowsProvider>
    </MemberManagementProvider>
  );
};
