import React from 'react';
import Layout from 'components/Layout';
import { DynamicObj } from 'interfaces/user.interface';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { Form } from '@ant-design/compatible';
import { useDatePresets } from 'hooks/useDatePresets';
import FilterItems from '../FilterItems';
import SearchSettingsForm from '../SearchSettingsForm';

type Props = {
  onFilterChange: (value: DynamicObj) => void;
  filters: Record<string, any>;
};

const Sidebar = ({ onFilterChange, filters }: Props) => {
  const handleChange = ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => {
    const newFilters = {
      ...filters,
      [name]: value,
    };

    onFilterChange(newFilters);
  };

  const { preSetValue, setPresetValue } = useDatePresets();

  return (
    <Layout.Sidebar
      quickSearch={{
        filters: {
          ...filters,
          preSetDateFilter: preSetValue,
        },
        contextKey: 'balance-transaction-records',
        onFilterChange,
        searchSettingsForm: SearchSettingsForm,
        quickSearchId: QuickSearchIds.BALANCE_TRANSACTION_RECORD,
        isNext: true,
        reportsPresetDateFilter: {
          disableQuickSearch: false,
          datePresetValue: preSetValue,
          handleDatePresetChange: setPresetValue,
        },
      }}
    >
      <Form layout="vertical">
        <FilterItems
          onRawFilterChange={handleChange}
          onFilterChange={onFilterChange}
          filters={filters}
        />
      </Form>
    </Layout.Sidebar>
  );
};

export default Sidebar;
