import React from 'react';
import { Tabs } from 'antd';
import messages from 'messages';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import { ErrorHandler } from 'components/ErrorHandler';
import { LastDeposit } from './LastDeposit';
import { LastWithdrawal } from './LastWithdrawal';

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 0 !important;
  }
  .ant-tabs-content.ant-tabs-content-top {
    background: white;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-nav > .ant-tabs-wrap {
    display: block !important;
    color: red !important;
  }

  .ant-tabs-nav-list {
    color: white;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)) !important;
  }

  .ant-tabs-tab-active {
    background: #1b48a0 !important;
    color: white !important;

    .ant-tabs-tab-btn {
      color: white !important;
    }
  }
`;

export const DepositAndWithdrawal = ({
  permissions,
}: DepositAndWithdrawalType) => {
  const { ALLOWED_LAST_DEPOSIT, ALLOWED_LAST_WITHDRAWAL } = permissions;
  const translate = useTranslate();
  const count = 3;

  return (
    <div className="card-container" style={{ marginTop: '8px' }}>
      <StyledTabs type="card" className="deposit-withdrawal-tab-member360">
        {ALLOWED_LAST_DEPOSIT && (
          <TabPane tab={translate(messages.LAST_DEPOSIT)} key="1">
            <ErrorHandler>
              <LastDeposit maxItemCount={count} />
            </ErrorHandler>
          </TabPane>
        )}
        {ALLOWED_LAST_WITHDRAWAL && (
          <TabPane tab={translate(messages.LAST_WITHDRAWAL)} key="2">
            <ErrorHandler>
              <LastWithdrawal maxItemCount={count} />
            </ErrorHandler>
          </TabPane>
        )}
      </StyledTabs>
    </div>
  );
};

type DepositAndWithdrawalType = {
  permissions: {
    ALLOWED_LAST_DEPOSIT: boolean;
    ALLOWED_LAST_WITHDRAWAL: boolean;
  };
};
