/* eslint-disable no-bitwise */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';
import Numeral from 'numeral';

const { Text } = Typography;

export const DEFAULT_ROW_COUNT = 10;

export default [
  {
    title: (
      <FormattedMessage id="reports.member.text" defaultMessage="Member" />
    ),
    render: (text: string) => <span data-testid="username">{text}</span>,
    dataIndex: 'username',
  },
  {
    title: (
      <FormattedMessage
        id="reports.number-of-bets.text"
        defaultMessage="No. of Bets"
      />
    ),
    dataIndex: 'betsCount',
    render: (text: string) => (
      <span data-testid="betsCount">
        {Numeral(text).format('0.00', (n) => ~~n)}
      </span>
    ),
  },
  {
    title: (
      <FormattedMessage id="reports.turnover.text" defaultMessage="Turnover" />
    ),
    dataIndex: 'turnover',
    render: (text: string) => (
      <span data-testid="turnover">
        {Numeral(text).format('0.00', (n) => ~~n)}
      </span>
    ),
  },
  {
    title: (
      <FormattedMessage id="reports.winloss.text" defaultMessage="Winloss" />
    ),
    dataIndex: 'winloss',
    render: (text: number) => {
      const color = text < 0 ? '#f5222d' : '#52c41a';
      return (
        <span data-testid="winloss">
          <Text style={{ color }}>
            {Numeral(text).format('0.00', (n) => ~~n)}
          </Text>
        </span>
      );
    },
  },
  {
    title: (
      <FormattedMessage
        id="reports.effective-bet.text"
        defaultMessage="Effective Bet"
      />
    ),
    dataIndex: 'effectiveBet',
    render: (text: string) => (
      <span data-testid="effectiveBet">
        {Numeral(text).format('0.00', (n) => ~~n)}
      </span>
    ),
  },
  {
    title: (
      <FormattedMessage
        id="reports.profit-ratio.text"
        defaultMessage="Profit Ratio"
      />
    ),
    dataIndex: 'profitRatio',
    render: (text: number) => {
      const color = text < 0 ? '#f5222d' : '#52c41a';
      return (
        <span data-testid="profitRatio">
          <Text style={{ color }}>
            {Numeral(text).format('0.00', (n) => ~~n)}
          </Text>
        </span>
      );
    },
  },
];
