import gql from 'graphql-tag';

export const DEPOSIT_PROVIDERS = gql`
  query depositProviders(
    $first: Int
    $after: Binary
    $filter: DepositProvidersFilterInput
  ) {
    depositProviders(filter: $filter, after: $after, first: $first) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          dateTimeCreated
          __typename
          ... on MidPayDirectDepositProvider {
            id
            apiKey
            apiKeyPreview
            midPayDirectPaymentGateway {
              id
              name
            }
            credentialValues
          }
          ... on DiorPay2DepositProvider {
            id
            name
            encryptionKey
            merchantId
            partner
          }
          ... on MidPayDepositProvider {
            apiKey
            apiKeyPreview
          }
          ... on DiorPayDepositProvider {
            key
            keyPreview
            merchantCode
          }
        }
      }
    }
  }
`;

export const DEPOSIT_PROVIDERS_CSV_DATA = gql`
  query depositProviders(
    $first: Int
    $after: Binary
    $filter: DepositProvidersFilterInput
  ) {
    depositProviders(filter: $filter, after: $after, first: $first) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          dateTimeCreated
          __typename
          ... on MidPayDirectDepositProvider {
            id
            apiKey
            apiKeyPreview
            midPayDirectPaymentGateway {
              id
              name
            }
            credentialValues
          }
          ... on DiorPay2DepositProvider {
            id
            name
            encryptionKey
            merchantId
            partner
          }
          ... on MidPayDepositProvider {
            apiKey
            apiKeyPreview
          }
          ... on DiorPayDepositProvider {
            key
            keyPreview
            merchantCode
          }
        }
      }
    }
  }
`;

export const DEPOSIT_PROVIDER = gql`
  query depositProvider($id: ID!) {
    depositProvider(id: $id) {
      id
      name
      __typename
      ... on MidPayDirectDepositProvider {
        id
        apiKey
        apiKeyPreview
        midPayDirectPaymentGateway {
          id
          name
          credentialFields
          paymentChannels
          __typename
        }
        credentialValues
        __typename
      }
      ... on DiorPay2DepositProvider {
        id
        name
        encryptionKey
        merchantId
        partner
        __typename
      }
      ... on MidPayDepositProvider {
        apiKey
        apiKeyPreview
        __typename
      }
      ... on DiorPayDepositProvider {
        key
        keyPreview
        merchantCode
        __typename
      }
    }
  }
`;

export default {};
