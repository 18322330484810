import React, { useState } from 'react';
import { Button, Input, message, Modal } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Config } from 'types/graphqlTypes';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const UPDATE_SMTP_CONFIG = gql`
  mutation UpdateSmtpConfig($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

const SMTP_CONFIG = gql`
  query SmtpConfig {
    config {
      smtpHost
      smtpService
      smtpUser
      smtpPassword
    }
  }
`;

const validationSchema = yup.object().shape({
  smtpUser: yup.string().required('This field is required'),
  smtpPassword: yup.string().required('This field is required'),
});

const ManageSmtpConfig = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { control, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      smtpHost: '',
      smtpService: '',
      smtpUser: '',
      smtpPassword: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { refetch, loading: queryLoading } = useQuery<{ config: Config }>(
    SMTP_CONFIG,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: ({ config }) => {
        reset({
          smtpHost: config.smtpHost || '',
          smtpService: config.smtpService || '',
          smtpUser: config.smtpUser || '',
          smtpPassword: config.smtpPassword || '',
        });
      },
    }
  );
  const { context } = useOperatorHeader();
  const [updateSmtpConfig, { loading: mutationLoading }] = useMutation(
    UPDATE_SMTP_CONFIG,
    {
      context,
    }
  );

  const handleOnOk = (values: Record<string, any>) => {
    updateSmtpConfig({
      variables: {
        input: values,
      },
    })
      .then(() => {
        message.success('SMTP Config updated');
      })
      .finally(() => {
        setIsModalVisible(false);
        refetch();
      });
  };

  return (
    <div>
      <h3>Manage SMTP Config</h3>
      <Button onClick={() => setIsModalVisible(true)}>Configure SMTP</Button>

      <Modal
        okButtonProps={{ loading: queryLoading || mutationLoading }}
        cancelButtonProps={{ loading: queryLoading || mutationLoading }}
        title="Configure SMTP"
        onCancel={() => setIsModalVisible(false)}
        visible={isModalVisible}
        onOk={handleSubmit(handleOnOk)}
        destroyOnClose
      >
        <div className="mb-3">
          <p className="mb-1 fw-500">SMTP Host:</p>
          <Controller as={<Input />} control={control} name="smtpHost" />
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">SMTP Service:</p>
          <Controller as={<Input />} control={control} name="smtpService" />
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">SMTP User:</p>
          <Controller as={<Input />} control={control} name="smtpUser" />
          <span className="text-11 text-danger">
            {errors?.smtpUser?.message}
          </span>
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">SMTP Password:</p>
          <Controller
            as={<Input type="password" />}
            control={control}
            name="smtpPassword"
          />
          <span className="text-11 text-danger">
            {errors?.smtpPassword?.message}
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default ManageSmtpConfig;
