import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { Icon } from '@ant-design/compatible';
import { ALink } from 'components/ALink/ALink';
import { useStarred } from 'store/accountState';
import { useMemberTab } from 'utils/handleAddTab';
import { MemberInfo } from 'store/accountState/utils';
import { StyledSection, StyledSectionBody, StyledMemberLevel } from '../styles';

const UserContainer = styled.div`
  margin-top: 20px;
  padding-bottom: 7px;
  border-bottom: 1px solid #e0e0e0;

  :hover {
    .outer-sidebar__hovericon {
      opacity: 1;
    }
  }
`;

const HoverCloseIcon = styled(Icon)`
  opacity: 0;
  cursor: pointer;
  color: #8f8f8f;
  font-size: 12px;
`;

type Props = {
  user: MemberInfo;
};

const StarMember = ({ user }: Props) => {
  const { unstarMember } = useStarred();
  const addMemberTab = useMemberTab();

  return (
    <UserContainer data-testid="members">
      <StyledSectionBody>
        <ALink
          style={{ width: '50%' }}
          onClick={() =>
            addMemberTab({
              id: user.id,
              username: user.name,
            })
          }
          className="text-truncate"
        >
          {user.name}
        </ALink>
        <div className="position-relative text-center" style={{ width: '50%' }}>
          <StyledMemberLevel
            className="text-truncate"
            color={user.memberLevelColor}
            data-testid={user.memberLevelColor}
          >
            {user.memberLevelName}
          </StyledMemberLevel>
        </div>
      </StyledSectionBody>
      <StyledSection style={{ marginTop: '3px' }}>
        <div style={{ fontSize: '12px' }}>{user.realName}</div>
        <Tooltip title="Remove" placement="left">
          <HoverCloseIcon
            className="outer-sidebar__hovericon"
            type="close-circle"
            theme="filled"
            onClick={() => unstarMember(user)}
          />
        </Tooltip>
      </StyledSection>
    </UserContainer>
  );
};

export default StarMember;
