import { Icon as LegacyIcon } from '@ant-design/compatible';
import { ExclamationCircleFilled, UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, message, Typography, Upload } from 'antd';
import { setDefaultUploadList } from 'constants/form';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import { isEmpty } from 'lodash';
import messages from 'messages';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useTranslate from 'utils/useTranslate';

const { Text } = Typography;

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;

  .warning-text {
    line-height: normal;
    display: flex;
    small {
      color: rgba(0, 0, 0, 0.65);
      font-size: 10px;
    }
  }
`;

const StyledUploader: any = styled(Upload)`
  .ant-upload-select {
    ${(props: Record<string, any>) =>
      props.previewVisible &&
      css`
        height: 128px;
        width: 128px;
        margin: 0px 8px 8px 0px;
        text-align: center;
        vertical-align: top;
        background-color: #fafafa;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        cursor: pointer;
        -webkit-transition: border-color 0.3s ease;
        transition: border-color 0.3s ease;

        .ant-upload[role='button'],
        .styled-upload-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%;
        }

        .anticon {
          font-size: 24px;
        }

        .styled-upload-button {
          flex-direction: column;
          background-repeat: no-repeat;
          background-size: contain;
        }
      `}
  }
`;

const urlRegEX = new RegExp(/^(?:\w+:)?\/\/([^\s]+\S{2}|localhost[?\d]*)\S*$/);

const SingleFileUploader = ({
  defaultFiles,
  previewVisible,
  name,
  data,
  accept,
  showUploadList,
  setFieldValue,
  imageValue,
}: {
  name: string;
  defaultFiles: Record<string, any>;
  data: Record<string, any>;
  accept?: string;
  showUploadList?: Record<string, any>;
  previewVisible?: boolean;
  setFieldValue?: (e: any, f: any) => void;
  imageValue?: string;
}) => {
  /*
   default data as list of file to show, 
   once user upload new file successfully replace it with new file
  */
  const [fileList, setFileList] = useState<any>(
    !isEmpty(defaultFiles) ? setDefaultUploadList([defaultFiles]) : []
  );

  /*
    previewFile for Image/QR code
  */

  const [previewFile, onChangePreviewFile] = useState(
    defaultFiles && urlRegEX.test(defaultFiles.uri) ? defaultFiles.uri : ''
  );
  const [uploadNewFile, { loading }] = useMutation(UPLOAD_FILE);

  /*
    show upload status and notify
  */
  const onCompleteRequest = (
    callback: Function,
    file: { name: string },
    failed: boolean
  ) => {
    callback();
    message[failed ? 'error' : 'success'](
      `${file.name} file ${failed ? 'upload failed' : 'uploaded successfully'}.`
    );
  };

  /*
    custom xhr upload directly to api admin,
    once done, notify back to caller to perform other stuff
    eg. updating field data
  */
  const onUploadFile = async ({
    onSuccess,
    onError,
    file,
  }: Record<string, any>) => {
    const { data: newFileData = {} } = (await uploadNewFile({
      variables: {
        file,
      },
    }).catch(() => onCompleteRequest(onError, file, true))) as any;
    const { uploadFile } = newFileData;

    onCompleteRequest(uploadFile ? onSuccess : onError, file, !uploadFile);
    if (uploadFile) {
      data.onCompleteUpload('image', uploadFile);
      setFileList(setDefaultUploadList([uploadFile]));
    }
    if (uploadFile && previewVisible) {
      onChangePreviewFile(uploadFile.uri);
    }
  };

  const onRemoveFile = () => {
    onChangePreviewFile('');
    setFileList('');
    const { onCompleteUpload = () => {} } = data;
    onCompleteUpload(name, '');
  };
  const translate = useTranslate();

  return (
    <Wrapper>
      <StyledUploader
        {...{
          accept,
          showUploadList,
          setFieldValue,
          imageValue,
        }}
        multiple={false}
        fileList={fileList}
        customRequest={onUploadFile}
        previewVisible={previewVisible}
        onRemove={onRemoveFile}
      >
        {!previewVisible && (
          <Button>
            <UploadOutlined />
            {translate(messages.upload)}
          </Button>
        )}
        {previewVisible && (
          <div
            className="styled-upload-button image"
            style={{ backgroundImage: `url(${previewFile || ''})` }}
            id="image"
            aria-label="image"
          >
            {!previewFile && (
              <>
                <LegacyIcon type={loading ? 'loading' : 'plus'} />
                <div className="px-2">
                  <small className="ant-upload-text">
                    {translate(
                      messages[
                        'click-or-drag-and-drop-file-to-upload-here.text'
                      ]
                    )}
                  </small>
                </div>
              </>
            )}
          </div>
        )}
      </StyledUploader>
      {previewVisible && (
        <Text type="warning" className="warning-text">
          <ExclamationCircleFilled className="mr-1" />
          <small>
            {translate(
              messages['click-or-drag-and-drop-file-to-upload-image-here.text']
            )}
          </small>
        </Text>
      )}
    </Wrapper>
  );
};

export default SingleFileUploader;
