import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { message, Modal, Spin } from 'antd';
import thirdPartyProviders, {
  thirdPartyTypeNames,
} from 'constants/thirdPartyProviders';
import { DocumentNode } from 'graphql';
import { CREATE_DEPOSIT_PROVIDER } from 'graphql/mutations/depositProvider.mutation';
import {
  DEPOSIT_PROVIDER,
  DEPOSIT_PROVIDERS,
} from 'graphql/queries/depositProvider.query';
import messages from 'pages/components/ThirdPartyDepositProvider/messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

const DuplicePayentGateway = (props: {
  id: string | null;
  toggleModal: () => void;
  refetchVariables: Record<string, any>;
}) => {
  const translate = useTranslate();
  const { toggleModal, id, refetchVariables } = props;

  const onSuccessSubmit = (data: Record<string, any>) => {
    if (!data) return;
    message.success(translate(messages.DEPOSIT_PROVIDER_DUPLICATE_SUCCESS));
  };
  const onErrorSubmit = () => {
    message.error(translate(messages.DEPOSIT_PROVIDER_DUPLICATE_ERROR));
  };

  const [createDepositProvider, { loading }] = useMutation(
    CREATE_DEPOSIT_PROVIDER,
    {
      onCompleted: onSuccessSubmit,
      onError: onErrorSubmit,
      refetchQueries: [
        {
          query: DEPOSIT_PROVIDERS,
          fetchPolicy: 'network-only',
          variables: refetchVariables,
        } as {
          query: DocumentNode;
          fetchPolicy: string;
          variables: Record<string, any>;
        },
      ],
    }
  );

  const { loading: loadingDepositProvider, data = {} } = useQuery(
    DEPOSIT_PROVIDER,
    {
      variables: { id },
      fetchPolicy: 'network-only',
      onError: onErrorSubmit,
    }
  );

  const onConfirmDuplicate = async () => {
    const { depositProvider }: Record<string, any> = data;
    const { name, __typename } = depositProvider;

    const variables = {
      input: {
        name: `copy of ${name}`,
      } as {
        name: string;
        key?: string;
        merchantCode?: string;
        apiKey: string;
        encryptionKey: string;
        merchantId: string;
        partner: string;
        midPayDirectPaymentGateway?: string;
        credentialValues: string;
      },
      type: '',
    };

    switch (__typename) {
      case thirdPartyTypeNames.diorPay:
        variables.type = thirdPartyProviders.DIORPAY;
        variables.input = {
          ...variables.input,
          key: depositProvider.key,
          merchantCode: depositProvider.merchantCode,
        };
        break;

      case thirdPartyTypeNames.midPay:
        variables.type = thirdPartyProviders.MIDPAY;
        variables.input = {
          ...variables.input,
          key: depositProvider.apiKey,
        };
        break;

      case thirdPartyTypeNames.midPayDirect:
        variables.type = thirdPartyProviders.MIDPAY_DIRECT;
        variables.input = {
          ...variables.input,
          apiKey: depositProvider.apiKey,
          midPayDirectPaymentGateway:
            depositProvider.midPayDirectPaymentGateway.id,
          credentialValues: depositProvider.credentialValues,
        };
        break;

      case thirdPartyTypeNames.diorPay2:
        variables.type = thirdPartyProviders.DIORPAY2;
        variables.input = {
          ...variables.input,
          encryptionKey: depositProvider.encryptionKey,
          merchantId: depositProvider.merchantId,
          partner: depositProvider.partner,
        };
        break;

      default:
        return;
    }

    await createDepositProvider({ variables });

    toggleModal();
  };

  return (
    <Modal
      visible
      maskClosable={false}
      closable={false}
      width={416}
      okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
      cancelText={<FormattedMessage id="cancel.text" defaultMessage="Cancel" />}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={onConfirmDuplicate}
    >
      <div className="ant-modal-confirm-body" data-testid="modal">
        {loadingDepositProvider ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spin />
          </div>
        ) : (
          <>
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
              <FormattedMessage
                id="duplicate.text"
                defaultMessage="Duplicate"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DuplicePayentGateway;
