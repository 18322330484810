import React from 'react';
import { Checkbox } from 'antd';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import { useCreateAffiliateProgrammeState } from '../../../../../../../../context';

function GGRChargeCheckBox({ name }: { name: string }) {
  const propName = name.replace(/^\w/, (c) => c.toUpperCase());

  const [{ activeVendor }] = useCreateAffiliateProgrammeState();
  const { values, handleChange } = useFormikContext();

  const { id, gameType } = activeVendor;
  const inputName = `vendorHandlers[${gameType}_${id}][no${propName}Charge]`;
  const value = get(values, inputName, false);
  const translate = useTranslate();
  return (
    <div className="mb-3">
      <Checkbox name={inputName} onChange={handleChange} checked={value}>
        {translate(messages[`agent.no-${name}-ggr-charge.text`])}
      </Checkbox>
    </div>
  );
}

export default GGRChargeCheckBox;
