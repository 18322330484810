import React from 'react';
import styled from 'styled-components';
// TODO show Sidebar if filters are working (as per nora)
// import { Sidebar } from './Sidebar/Sidebar';
import { Table } from './Table/Table';
// import { Calendar } from './Calendar/Calendar';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const VMBody: React.FC<Props> = () => {
  return (
    <Container>
      {/* <Sidebar /> */}
      <Table />
      {/* <Calendar /> */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: calc(100% - 64px);
`;
