import React from 'react';
import FiltersProvider from 'contexts/Filters';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Mission } from 'types/graphqlTypes';
import { GraphQLResponse } from 'interfaces/graphql.interface';
import { MissionsProvider } from './context';
import { MissionsScreen } from './MissionsScreen';

const initialFilter = {
  programmeName: '',
  missionType: '',
  dateRange: '',
  status: '',
};

const MISSIONS = gql`
  query Missions($first: Int, $after: Binary, $filter: MissionsFilterInput) {
    missions(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          serialCode
          name
          type
          status
        }
      }
    }
  }
`;

const Missions = () => {
  const { loading, error, data } = useQuery<{
    missions: GraphQLResponse<Mission[]>;
  }>(MISSIONS, {
    fetchPolicy: 'network-only',
    variables: {
      first: 10,
      filter: {},
    },
  });
  return (
    <MissionsProvider loading={loading} error={error} data={data?.missions!}>
      <FiltersProvider initialValues={initialFilter} tabId="missions">
        <MissionsScreen />
      </FiltersProvider>
    </MissionsProvider>
  );
};

export default Missions;
