import React, { useState, useEffect } from 'react';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { messages } from 'SuperAdminMain/components/AccountManagementNew/utils';
import { StyledLabel, StyledSpan, StyledSelect } from '../utils';

const ADMINS_COMPANY = gql`
  query ADMINS_COMPANY(
    $first: Int
    $after: Binary
    $filter: AdminsFilterInput
  ) {
    admins(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          company
        }
      }
    }
  }
`;

export const CompanyFilter: React.FC = () => {
  const { filters, setFilters } = useFilterValues();
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input, 500);
  const translate = useTranslate();

  const [loadAdmins, { data, loading, called }] = useLazyQuery(ADMINS_COMPANY, {
    variables: { first: 10, filter: { company: { contains: debouncedInput } } },
  });

  if (!called) loadAdmins();

  const admins = coercedGet(data, 'admins.edges', [])
    .map(({ node }: any) => node)
    .filter(
      (admin: any, i: number, v: any) =>
        v.findIndex((n: any) => n.company === admin.company) === i
    );

  const handleChange = (e: Array<string>) => {
    if (e.length) {
      setFilters((prev: any) => ({
        ...prev,
        company: { in: e },
      }));
    } else {
      setFilters((prev: any) => ({
        ...prev,
        company: null,
      }));
    }
  };

  useEffect(() => {
    loadAdmins();
  }, [debouncedInput, loadAdmins]);

  return (
    <div className="filter">
      <div className="label-clear">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="client-company.text"
              defaultMessage="Client (Company)"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        mode="multiple"
        value={filters.company ? filters.company.in : []}
        placeholder={translate(messages['please-enter.text'])}
        loading={loading}
        onChange={handleChange}
        onFocus={() => setInput('')}
        onBlur={() => setInput('')}
        onSearch={(text: any) => setInput(text)}
        filterOption={false}
      >
        {admins.map((admin: any) =>
          admin.company ? (
            <Select.Option
              key={admin.id}
              value={admin.company}
              label={admin.company}
            >
              {admin.company}
            </Select.Option>
          ) : null
        )}
      </StyledSelect>
    </div>
  );
};
