import gql from 'graphql-tag';

export const BALANCE_SERVICE_RECORDS_SERIAL_CODE = gql`
  query MemberBalanceUnitRecords(
    $first: Int
    $after: Binary
    $filter: MemberBalanceUnitRecordFilterInput!
  ) {
    memberBalanceUnitRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          serialCode
        }
      }
    }
  }
`;

export const BALANCE_SERVICE_RECORDS_TRANSACTION_CODE = gql`
  query MemberBalanceUnitRecords(
    $first: Int
    $after: Binary
    $filter: MemberBalanceUnitRecordFilterInput!
  ) {
    memberBalanceUnitRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          transactionSerialCode
        }
      }
    }
  }
`;

export const MEMBER_BALANCE_UNIT_RECORDS = gql`
  query MemberBalanceUnitRecords(
    $first: Int
    $after: Binary
    $filter: MemberBalanceUnitRecordFilterInput!
    $sort: SortInput
  ) {
    memberBalanceUnitRecords(
      first: $first
      after: $after
      filter: $filter
      sort: $sort
    ) {
      totalCount
      edges {
        cursor
        node {
          id
          transactionType
          remarks(language: EN)
          originalAmount
          currentAmount
          turnoverRequirementMultiplier
          turnoverRequirement
          turnoverRequirementBalance
          dateTimeCreated
          dateTimeTurnoverCompleted
          betCount
          bets
          withdrawals
          serialCode
          transactionSerialCode
          manualAdjustment {
            id
          }
          currency
          baseAmount
          exchangeRate
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const MEMBER_BALANCE_UNIT_RECORDS_CSV_DATA = gql`
  query MEMBER_BALANCE_UNIT_RECORDS_CSV_DATA(
    $first: Int
    $after: Binary
    $filter: MemberBalanceUnitRecordFilterInput!
  ) {
    memberBalanceUnitRecords(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          member {
            id
            username
          }
          id
          transactionType
          remarks(language: EN)
          originalAmount
          currentAmount
          turnoverRequirementMultiplier
          turnoverRequirement
          turnoverRequirementBalance
          dateTimeCreated
          dateTimeTurnoverCompleted
          betCount
          bets
          withdrawals
          serialCode
          transactionSerialCode
          currency
          baseAmount
          exchangeRate
        }
      }
    }
  }
`;

export const MEMBERS = gql`
  query Members($first: Int, $after: Binary, $filter: MembersFilterInput) {
    members(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          username
          value: username
          label: username
        }
      }
    }
  }
`;

export default {};
