import React from 'react';
import coercedGet from 'utils/coercedGet';
import { Tag } from 'antd';
import { get, capitalize, startCase } from 'lodash';
import MemberTag from 'components/MemberTag';
import { formatDate } from 'utils/dateUtils';
import { useDatePresets } from 'hooks/useDatePresets';
import { useFilterValues } from 'contexts/Filters';
import ProcessorTag from 'components/ProcessorTag';

type Props = {
  filters: Record<string, any>;
  loading: boolean;
  onFilterChange: Function;
};

export const FilterConditions = ({
  filters,
  loading,
  onFilterChange,
}: Props) => {
  const { preSetValue, setPresetValue } = useDatePresets();

  const { defaultValues } = useFilterValues();

  const handleRemove = ({
    event,
    dataToRemove,
    filterKey,
    operation = 'in',
  }: {
    event: React.MouseEvent<HTMLElement>;
    dataToRemove: string;
    filterKey: string;
    operation?: string;
  }) => {
    event.preventDefault();

    if (!loading) {
      const data = get(filters[filterKey], operation, []).filter(
        (item: string) => item !== dataToRemove
      );
      const newFilter = {
        [operation]: data,
      };
      onFilterChange({
        ...filters,
        [filterKey]: data.length ? newFilter : null,
      });
    }
  };

  // applicable only for string array filter values
  const FilterTag = ({
    filterKey,
    operation = 'in',
  }: {
    filterKey: string;
    operation?: string;
  }) =>
    coercedGet(filters, `${filterKey}.${operation}`, []).map((item: string) => (
      <Tag
        style={{ margin: '4px' }}
        key={item}
        closable
        onClose={(e) =>
          handleRemove({
            event: e,
            dataToRemove: item,
            filterKey,
            operation,
          })
        }
      >
        {item}
      </Tag>
    ));

  const amountRangeFilters = [
    {
      key: 'amount',
      label: 'Amount',
    },
  ];

  return (
    <>
      Filter Conditions:
      {preSetValue === 'lifetime' && (
        <Tag
          closable
          onClose={() => {
            setPresetValue(null);

            onFilterChange(defaultValues);
          }}
        >
          {capitalize(startCase(preSetValue))}
        </Tag>
      )}
      <FilterTag filterKey="serialCode" />
      <FilterTag filterKey="brandId" />
      <FilterTag filterKey="platformId" />
      <FilterTag filterKey="remarks" />
      <FilterTag filterKey="transaction" />
      <FilterTag filterKey="manualAdjustmentRemarks" />
      <FilterTag filterKey="brand" />
      <FilterTag filterKey="type" />
      {/* ======== */}
      {filters.member &&
        filters.member.in.map((item: string) => (
          <MemberTag
            key={item}
            id={item}
            fieldName="username"
            onClose={(e: React.MouseEvent<HTMLElement>) =>
              handleRemove({
                event: e,
                dataToRemove: item,
                filterKey: 'member',
              })
            }
          />
        ))}
      {filters.currency
        ? filters.currency.in.map((item: string, key: string | number) => (
            <Tag
              key={key}
              closable
              onClose={(e: any) =>
                handleRemove({
                  event: e,
                  dataToRemove: item,
                  filterKey: 'currency',
                })
              }
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.processor &&
        filters.processor.in.map((item: string) => (
          <ProcessorTag
            key={item}
            id={item}
            onClose={(e: React.MouseEvent<HTMLElement>) =>
              handleRemove({
                event: e,
                dataToRemove: item,
                filterKey: 'processor',
              })
            }
          />
        ))}
      {filters?.dateTimeCreated ? (
        <Tag
          closable
          onClose={(e) => {
            handleRemove({
              event: e,
              dataToRemove: filters?.dateTimeCreated,
              filterKey: 'dateTimeCreated',
            });

            setPresetValue(null);
          }}
        >
          Date Time Created: {formatDate(filters.dateTimeCreated?.gte)} ~{' '}
          {formatDate(filters.dateTimeCreated?.lte)}
        </Tag>
      ) : null}
      {amountRangeFilters.map((filter, index) => {
        if (filters?.[filter.key]) {
          return (
            <Tag
              key={index}
              closable
              onClose={(e) =>
                handleRemove({
                  event: e,
                  dataToRemove: filters?.[filter.key],
                  filterKey: filter.key,
                })
              }
            >
              {filter.label}: {filters[filter.key].gte} -{' '}
              {filters[filter.key].lte}
            </Tag>
          );
        }

        return null;
      })}
      {filters?.MANUAL_ADJUSTMENT ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemove({
              event: e,
              dataToRemove: filters?.MANUAL_ADJUSTMENT,
              filterKey: 'MANUAL_ADJUSTMENT',
            })
          }
        >
          {filters?.MANUAL_ADJUSTMENT === 'yes'
            ? ' Balance Adjustment Yes'
            : 'Balance Adjustment No'}
        </Tag>
      ) : null}
    </>
  );
};
