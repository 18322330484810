import React from 'react';
import styled from 'styled-components';
import { Button, message } from 'antd';
import gql from 'graphql-tag';
import { omit, partial } from 'lodash';
import compose from 'utils/compose';
import { FormattedMessage } from 'react-intl';
import {
  transformMovableDateFilter,
  impartMoveableDate,
} from 'utils/dateUtils';
import { useMutation } from '@apollo/react-hooks';
import removeNull from 'utils/removeNull';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import {
  CreateBetHistoryReportInput,
  BetHistoryReportFilterInput,
  Scalars,
} from 'types/graphqlTypes';
import { collateGameFilterFields } from './utils';
import localMessages from './messages';
import ScheduleDownload from './components/ScheduleDownload';

export const CREATE_BET_HISTORY_REPORT = gql`
  mutation CreateBetHistoryReport($input: CreateBetHistoryReportInput!) {
    createBetHistoryReport(input: $input)
  }
`;

// type Timezone = Scalars.Timezone;

type DateType = {
  gte: string;
  lte: string;
} | null;

type ModuleDateTypes = {
  dateTimeProcessed?: DateType;
  dateTimePlaced?: DateType;
  dateTimeSettled?: DateType;
};

type FilterContextType = BetHistoryReportFilterInput & {
  timezone?: Scalars;
};

const FooterActions = React.forwardRef((__props, ref: any) => {
  const { filters } = useFilterValues();
  const { currentTimezone } = useReportsTimezone() as any;
  const translate = useTranslate();

  const transformedFilters: ModuleDateTypes = transformMovableDateFilter({
    dateTimeProcessed: {
      gte: coercedGet(filters, 'dateTimeProcessed.gte', null),
      lte: coercedGet(filters, 'dateTimeProcessed.lte', null),
    },
    dateTimePlaced: {
      gte: coercedGet(filters, 'dateTimePlaced.gte', null),
      lte: coercedGet(filters, 'dateTimePlaced.lte', null),
    },
    dateTimeSettled: {
      gte: coercedGet(filters, 'dateTimeSettled.gte', null),
      lte: coercedGet(filters, 'dateTimeSettled.lte', null),
    },
  });

  const {
    dateTimeProcessed,
    dateTimePlaced,
    dateTimeSettled,
  } = transformedFilters;

  const impartDateTimePlaced = partial(
    impartMoveableDate,
    'dateTimePlaced',
    dateTimePlaced
  );

  const impartDateTimeSettled = partial(
    impartMoveableDate,
    'dateTimeSettled',
    dateTimeSettled
  );

  const impartDateTimeProcessed = partial(
    impartMoveableDate,
    'dateTimeProcessed',
    dateTimeProcessed
  );

  const formatFilters = React.useCallback(
    filts => {
      const newFilts: FilterContextType = {
        ...filts,
        timezone: filts.timezone || currentTimezone,
      };
      const procFilts: FilterContextType = removeNull(newFilts) as any;
      return {
        scheduledDateTime: null,
        timezone: procFilts.timezone,
        filter: omit(procFilts, ['timezone']),
      } as CreateBetHistoryReportInput;
    },
    [currentTimezone]
  );

  const input: CreateBetHistoryReportInput = React.useMemo(
    () =>
      compose(
        formatFilters,
        impartDateTimePlaced,
        impartDateTimeSettled,
        collateGameFilterFields,
        impartDateTimeProcessed
      )(filters),
    [
      filters,
      formatFilters,
      impartDateTimePlaced,
      impartDateTimeProcessed,
      impartDateTimeSettled,
    ]
  );

  const [createReport, { loading }] = useMutation(
    CREATE_BET_HISTORY_REPORT,
    {}
  );

  return (
    <StyledFloatingFooter className="ml-1 px-2">
      <div className="styled-center">
        <Button
          onClick={async () => {
            const dateProcessed = coercedGet(
              input,
              'filter.dateTimeProcessed',
              null
            );
            const admins = coercedGet(input, 'filter.admin', null);

            if (!dateProcessed || !admins)
              return message.error(
                translate(localMessages.CLIENT_ACCOUNT_AND_PERIOD_IS_REQUIRED)
              );

            const result = await createReport({
              variables: {
                input,
              },
            });

            if (result) {
              message.success(
                translate(localMessages.BET_HISTORY_REPORT_CREATED)
              );
            }

            return result ? ref?.current!.refetchTable() : null;
          }}
          disabled={loading}
          type="primary"
          size="small"
          className="mr-1"
        >
          <FormattedMessage
            id="reports.generate.sider.text"
            defaultMessage="Generate"
          />
        </Button>

        <ScheduleDownload input={input} ref={ref} />
      </div>
    </StyledFloatingFooter>
  );
});

export default FooterActions;

const StyledFloatingFooter = styled.div`
  position: fixed;
  left: -3px;
  bottom: 1px;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #f0f1f4;
  background-color: #fff;
  height: 6vh;
  width: 228px;
`;
