import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Radio, Select, Tooltip } from 'antd';
import { Formik } from 'formik';
import { MemberStatus } from 'types/graphqlTypes';
import { FormattedMessage } from 'react-intl';
import { StyledForm } from './styles';
import validationSchema from './validationSchema';

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (e: any) => void;
  onClose: () => void;
  isLoading: boolean;
  allowedEditBrandLinkage: boolean;
};

const reasonOptions = [
  'No time for betting',
  'Not interested in online gambling',
  'Not satisfied with the available promotions',
  'Not satisfied with the offered services',
  'Chose a different betting service',
  'Not specified',
];

const EditStatusMenuForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
  allowedEditBrandLinkage,
}: Props) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({
      handleSubmit,
      handleChange,
      values,
      errors,
      touched,
      setFieldValue,
    }) => (
      <StyledForm onSubmit={handleSubmit} role="form">
        <div className="p-3">
          <Form.Item
            label={
              <FormattedMessage id="status.text" defaultMessage="Status" />
            }
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            validateStatus={errors.status && touched.status ? 'error' : ''}
            help={touched.status ? errors.status : null}
          >
            <Radio.Group
              name="status"
              onChange={handleChange}
              value={values.status || ''}
              options={{ role: 'input' } as any}
            >
              <Radio value="ENABLED" aria-label="enable-status">
                <FormattedMessage id="ACTIVE" defaultMessage="Active" />
              </Radio>

              <Radio value="DISABLED" aria-label="disable-status">
                <FormattedMessage id="INACTIVE" defaultMessage="Inactive" />
              </Radio>

              <Radio value="CLOSED" aria-label="closed-status">
                <FormattedMessage id="CLOSED" defaultMessage="Closed" />
              </Radio>

              <Radio value="BANNED" aria-label="banned-status">
                <FormattedMessage id="BANNED" defaultMessage="Banned" />
              </Radio>

              <Radio value="DORMANT" aria-label="dormant-status">
                <FormattedMessage id="DORMANT" defaultMessage="Dormant" />
              </Radio>

              <Radio value="SELF_EXCLUDED" aria-label="self-excluded">
                <FormattedMessage
                  id="SELF_EXCLUDED"
                  defaultMessage="Self-Excluded"
                />
              </Radio>

              <Radio value="GAMSTOP_SELF_EXCLUDED" aria-label="self-excluded">
                <FormattedMessage
                  id="GAMSTOP_SELF_EXCLUDED"
                  defaultMessage="Gamstop Self-Excluded"
                />
              </Radio>

              <Radio value="COOLING_OFF" aria-label="cooling-off">
                <FormattedMessage
                  id="COOLING_OFF"
                  defaultMessage="Cooling Off"
                />
              </Radio>
              <Radio value="TIME_OUT" aria-label="time-out">
                <FormattedMessage id="TIME_OUT" defaultMessage="Time-Out" />
              </Radio>
              <Radio value="FRAUD" aria-label="fraud">
                <FormattedMessage id="FRAUD" defaultMessage="Fraud" />
              </Radio>
              <Radio value="FROZEN" aria-label="frozen">
                <FormattedMessage id="FROZEN" defaultMessage="Frozen" />
              </Radio>
              <Radio value="CLOSED_LEGACY" aria-label="closed-legacy" disabled>
                <FormattedMessage
                  id="CLOSED_LEGACY"
                  defaultMessage="Closed Legacy"
                />
              </Radio>
            </Radio.Group>
          </Form.Item>

          {values?.status === MemberStatus.Closed && (
            <div className="mt-3">
              <Form.Item
                label="Reason"
                colon={false}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                validateStatus={errors.reason && touched.reason ? 'error' : ''}
                help={touched.reason ? errors.reason : null}
              >
                <Select
                  value={values.reason}
                  onChange={(e) => setFieldValue('reason', e)}
                >
                  <Select.Option disabled value="">
                    Select a Reason
                  </Select.Option>
                  {reasonOptions.map((reason) => (
                    <Select.Option value={reason}>{reason}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}

          <div className="mt-3">
            <Form.Item
              label="Brand Linkage"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
            >
              <Tooltip
                placement="right"
                title={
                  !allowedEditBrandLinkage &&
                  'Member Management - Edit Brand Linkages permission required.'
                }
              >
                <Checkbox
                  disabled={!allowedEditBrandLinkage}
                  value={values.brandLinkagesUpdated}
                  onChange={(e) =>
                    setFieldValue('brandLinkagesUpdated', e.target.checked)
                  }
                />
              </Tooltip>
            </Form.Item>
          </div>
        </div>
        <div className="bt-1 p-3 text-right">
          <Button className="mr-2" onClick={onClose} loading={isLoading}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            role="button"
          >
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </div>
      </StyledForm>
    )}
  </Formik>
);

export default EditStatusMenuForm;
