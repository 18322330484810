import gql from 'graphql-tag';

export const JOB = gql`
  query Job($id: ID!) {
    job(id: $id) {
      id
      progress
      status
      dateTimeCreated

      ... on CreateMembersJob {
        errorReportFile {
          id
          uri
        }
        failCount
        successCount
      }

      ... on CreateManualAdjustmentsJob {
        result {
          errorReportFile {
            id
            uri
          }
          failCount
          successCount
        }
      }
    }
  }
`;
