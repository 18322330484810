import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import Circle from 'components/Circle';
import messages from 'pages/components/WithdrawalMethods/messages';
import useTranslate from 'utils/useTranslate';
import { useConfig } from '../../../../../../../hooks/useConfig';

const OtherSettings = ({
  withdrawalMethod,
  formItemLayout,
}: {
  withdrawalMethod: Record<string, any>;
  formItemLayout: Record<string, any>;
}) => {
  const {
    enabled,
    remarks,
    transactionFee,
    transactionFeeType,
  } = withdrawalMethod;
  const translate = useTranslate();
  const { currency } = useConfig();
  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1 mt-2"
        label={
          <strong id="other-settings">{translate(messages.others)}</strong>
        }
        colon={false}
      />

      <Form.Item
        label={`${translate(messages.remarks)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{remarks}</span>
      </Form.Item>

      <Form.Item
        label={`${translate(messages.transactionfee)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>
          {translate(messages[transactionFeeType.toLowerCase()])} -{' '}
          {transactionFee}{' '}
          {transactionFeeType === 'ABSOLUTE' ? `${currency?.symbol}` : '%'}
        </span>
      </Form.Item>

      <Form.Item
        label={`${translate(messages.status)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <Circle size={15} color={enabled ? 'success' : 'danger'} filled />{' '}
        {translate(enabled ? messages.enable : messages.disable)}
      </Form.Item>
    </>
  );
};

export default OtherSettings;
