import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio, Input, Select as AntdSelect, Checkbox, TimePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import Switch from 'pages/components/common/Switch';
import Select from 'pages/components/common/Select';
import FormItem from 'components/FormItem';
import RadioGroup from 'components/RadioGroup';
import { formItemHorizontalLayout } from 'constants/form';

import moment from 'moment';

import daysOfWeek from 'constants/daysOfWeek';
import daysOfMonth from 'constants/daysOfMonth';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { Root } from './styles';
import Claims from './components/Claims';

import { AffiliateProgrammeSettlementPeriod } from '../../../../utils';

const AffiliateProgrammePayoutMethod = {
  AUTOMATIC_PAYOUT: true,
  MANUAL_PAYOUT: false,
};

const { Option } = AntdSelect;

function SettlementPeriod({ formProps }: { formProps: Record<string, any> }) {
  const { setFieldValue, values, handleChange, errors } = formProps;
  const translate = useTranslate();

  return (
    <Root>
      <Form.Item
        label={
          <b>
            <FormattedMessage id="settings.text" defaultMessage="Settings" />
          </b>
        }
        {...formItemHorizontalLayout}
        colon={false}
      />
      <FormItem label={translate(messages.PAYOUT_METHOD)}>
        <RadioGroup name="automaticPayoutClaiming">
          <>
            <Radio value={AffiliateProgrammePayoutMethod.AUTOMATIC_PAYOUT}>
              <FormattedMessage
                id="automatic-payout.text"
                defaultMessage="Automatic Payout"
              />
            </Radio>
            <Radio value={AffiliateProgrammePayoutMethod.MANUAL_PAYOUT}>
              <FormattedMessage
                id="manually-payout.text"
                defaultMessage="Manually Payout"
              />
            </Radio>
          </>
        </RadioGroup>
      </FormItem>
      <Switch
        label={
          (
            <FormattedMessage
              id="minimum-payout-accumulation.text"
              defaultMessage="Minimum Payout Accumulation"
            />
          ) as any
        }
        value={values.minimumPayoutAccumulationEnabled}
        onChange={(checked) => {
          setFieldValue('minimumPayoutAccumulationEnabled', checked);
          if (!checked) {
            setFieldValue('minimumPayoutAccumulationAmount', 0);
          }
        }}
        checkedChildren={translate(messages['on.text'])}
        unCheckedChildren={translate(messages['off.text'])}
      />
      {values.minimumPayoutAccumulationEnabled && (
        <FormItem
          label={
            <FormattedMessage
              id="minimum-payout-accumulation-amount.text"
              defaultMessage="Minimum Payout Accumulation Amount"
            />
          }
          validateStatus={
            values.minimumPayoutAccumulationEnabled &&
            errors &&
            errors.minimumPayoutAccumulationAmount &&
            'error'
          }
          help={
            values.minimumPayoutAccumulationEnabled &&
            errors &&
            errors.minimumPayoutAccumulationAmount
          }
        >
          <Input
            type="number"
            style={{ width: '50%' }}
            name="minimumPayoutAccumulationAmount"
            value={values.minimumPayoutAccumulationAmount}
            onChange={handleChange}
          />
        </FormItem>
      )}
      <FormItem
        label={
          <FormattedMessage
            defaultMessage="Turnover Requirement"
            id="turnover-requirement.text"
          />
        }
        validateStatus={
          errors && errors.turnoverRequirementMultiplier && 'error'
        }
        help={errors && errors.turnoverRequirementMultiplier}
      >
        <Input
          type="number"
          style={{ width: '50%' }}
          name="turnoverRequirementMultiplier"
          value={values.turnoverRequirementMultiplier}
          onChange={handleChange}
        />
      </FormItem>
      <Select
        label={
          (<FormattedMessage defaultMessage="Period" id="period.text" />) as any
        }
        value={values.settlementPeriod}
        options={[
          {
            id: 'WEEKLY',
            text: translate(messages.WEEKLY),
          },
          {
            id: 'BIWEEKLY',
            text: translate(messages.BIWEEKLY),
          },
          {
            id: 'MONTHLY',
            text: translate(messages.MONTHLY),
          },
        ]}
        onChange={(e) => setFieldValue('settlementPeriod', e)}
      />
      <Form.Item
        label={
          <b>
            {values.period === AffiliateProgrammeSettlementPeriod.WEEKLY && (
              <>{translate(messages.WEEKLY)}</>
            )}{' '}
            {values.period === AffiliateProgrammeSettlementPeriod.MONTHLY && (
              <>{translate(messages.MONTHLY)}</>
            )}
            {values.period === AffiliateProgrammeSettlementPeriod.BIWEEKLY && (
              <>{translate(messages.BIWEEKLY)}</>
            )}
          </b>
        }
        {...formItemHorizontalLayout}
        colon={false}
      />
      {values.settlementPeriod ===
        AffiliateProgrammeSettlementPeriod.BIWEEKLY && (
        <Select
          label={
            (
              <FormattedMessage
                id="which-week.text"
                defaultMessage="Which Week"
              />
            ) as any
          }
          options={[
            {
              id: '1',
              text: 'First week',
            },
            {
              id: '2',
              text: 'Second week',
            },
          ]}
          value={values.whichWeek || 'First week'}
          onChange={(e) => setFieldValue('whichWeek', e)}
        />
      )}
      {(values.settlementPeriod === AffiliateProgrammeSettlementPeriod.WEEKLY ||
        values.settlementPeriod ===
          AffiliateProgrammeSettlementPeriod.BIWEEKLY) && (
        <Select
          label={
            (
              <FormattedMessage
                id="day-of-week.text"
                defaultMessage="Day of Week"
              />
            ) as any
          }
          options={
            daysOfWeek.map((q) => ({
              id: q.value,
              text: translate(
                messages[`Every ${q.label}`.replace(' ', '_').toUpperCase()]
              ),
            })) as any
          }
          value={values.settlementDayOfWeek}
          onChange={(e) => setFieldValue('settlementDayOfWeek', e)}
        />
      )}
      {values.settlementPeriod ===
        AffiliateProgrammeSettlementPeriod.MONTHLY && (
        <Select
          label={
            (
              <FormattedMessage
                id="date-of-month.text"
                defaultMessage="Date of Month"
              />
            ) as any
          }
          options={
            [...daysOfMonth, 29, 30, 31].map((q) => ({
              id: q,
              text: translate(messages.EVERY_DYNAMIC_DAY, {
                day: `${moment.localeData().ordinal(q)}`,
              }),
            })) as any
          }
          value={values.settlementDayOfMonth || 1}
          onChange={(e) => setFieldValue('settlementDayOfMonth', e)}
        />
      )}

      <Form.Item
        {...formItemHorizontalLayout}
        label={
          <FormattedMessage
            id="time-of-day.text"
            defaultMessage="Time of Day"
          />
        }
      >
        <TimePicker
          size="large"
          format="HH:mm"
          value={values.settlementTime}
          onChange={(value) => setFieldValue('settlementTime', value)}
          style={{ width: '50%' }}
        />
      </Form.Item>
      <Claims
        label={
          (
            <FormattedMessage
              id="claim-offset-time.text"
              defaultMessage="Claim Offset Time"
            />
          ) as any
        }
        id="Offset"
        setFieldValue={setFieldValue}
        values={values}
        onChange={handleChange}
      />
      <Claims
        label={
          (
            <FormattedMessage
              id="claim-expiry-time.text"
              defaultMessage="Claim Expiry Time"
            />
          ) as any
        }
        id="Expiry"
        setFieldValue={setFieldValue}
        values={values}
        onChange={handleChange}
      />
      <Form.Item
        className="d-none"
        label={
          <FormattedMessage
            id="award-expiry-time.text"
            defaultMessage="Award Expiry Time"
          />
        }
        {...formItemHorizontalLayout}
      >
        <Input.Group compact size="large">
          <Input
            disabled={values.noPayoutExpiryDuration}
            style={{ width: '35%' }}
            size="large"
            value={values.payoutExpiryDuration}
            onChange={(e) =>
              setFieldValue('payoutExpiryDuration', e.target.value)
            }
          />
          <AntdSelect
            disabled={values.noPayoutExpiryDuration}
            size="large"
            value={values.payoutExpirySelected || 'Day'}
            onChange={(e) => setFieldValue('payoutExpirySelected', e)}
            style={{ width: 110 }}
          >
            <Option value="d">{translate(messages.DAYS)}</Option>
            <Option value="m">{translate(messages.MONTHS)}</Option>
          </AntdSelect>
          <div style={{ width: '35%', paddingTop: 8, paddingLeft: 24 }}>
            <Checkbox
              checked={values.noPayoutExpiryDuration}
              name="noPayoutExpiryDuration"
              onChange={handleChange}
            >
              {translate(messages.NO_AWARD_EXPIRY_TIME)}
            </Checkbox>
          </div>
        </Input.Group>
      </Form.Item>
    </Root>
  );
}

export default SettlementPeriod;
