import React from 'react';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import messages from 'messages';
import { useIntl } from 'react-intl';
import { ReactComponent as RefreshIcon } from 'components/assets/refresh-table.svg';

type Props = {
  onClick: () => void;
  iconSize?: string;
};

const RefreshButton = ({ onClick, iconSize, ...rest }: Props) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <Tooltip title={formatMessage(messages.REFRESH)}>
      <Icon
        className="mr-2"
        component={RefreshIcon}
        style={{ fontSize: iconSize || '22px' }}
        onClick={onClick}
        {...rest}
      />
    </Tooltip>
  );
};

export default RefreshButton;
