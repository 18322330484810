import React from 'react';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { StyledForm } from './styles';
import FilterItems from '../FilterItems';

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (data: any) => void;
  onClose: () => void;
  isLoading: boolean;
};

const SearchSettingsForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: Props) => {
  const [initValues, setInitValues] = React.useState(initialValues);

  return (
    <StyledForm>
      <Row>
        <Col span={12}>
          <FilterItems filters={initValues} handleChange={setInitValues} />
        </Col>
      </Row>
      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>{' '}
        <Button
          onClick={() => onSubmit(initValues)}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
