import React, { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import generateId from 'utils/generateId';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { ALink } from 'components/ALink/ALink';
import { getTierRequirement } from '../../../../../../../../constants';

const DuplicateTier = ({ index }: { index: number }) => {
  const { values, setFieldValue } = useFormikContext() as any;
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const { levels } = values;

  return (
    <>
      <ALink
        onClick={() => {
          if (index === levels.length - 1) {
            handleShow();
          }
        }}
      >
        <FormattedMessage id="duplicate.text" defaultMessage="Duplicate" />
      </ALink>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          onCancel={handleShow}
          onOk={() => {
            const selectedLevel = levels.find(
              (_: any, idx: number) => idx === index
            );

            const lookup =
              values.tierRequirement.length === 2
                ? values.tierRequirementOperator
                : values.tierRequirement[0];

            const { forActiveMembers, forNetProfit } = getTierRequirement(
              lookup
            );

            const newLevels = levels.map(
              (level: Record<string, any>, idx: number) => {
                if (idx === levels.length - 1) {
                  return {
                    ...level,
                    ...(forActiveMembers && {
                      maximumActiveMembers:
                        +level.minimumActiveMembersCount + 1,
                    }),
                    ...(forNetProfit && {
                      maximumNetProfit: +level.minimumNetProfit + 1,
                    }),
                  };
                }
                return level;
              }
            );

            setFieldValue('levels', []);

            setFieldValue('levels', [
              ...newLevels,
              {
                ...selectedLevel,
                key: generateId('aap'),
                name: `Copy of ${selectedLevel.name}`,
                ...(!!newLevels.length &&
                  forActiveMembers && {
                    minimumActiveMembersCount:
                      newLevels[newLevels.length - 1].maximumActiveMembers,
                  }),
                ...(!!newLevels.length &&
                  forNetProfit && {
                    minimumNetProfit:
                      newLevels[newLevels.length - 1].maximumNetProfit,
                  }),
              },
            ]);
            handleShow();
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-duplicate.text"
                defaultMessage="Confirm Duplicate"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DuplicateTier;
