import React, { useState, useEffect } from 'react';
import { StyledTable } from 'constants/styles';
import {
  isTypeManual,
  isTypenameInternal,
  PAYMENT_METHODS_LIST,
} from 'constants/paymentTypes';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { getBank, messages as bankMessages } from 'constants/banks';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { TabId } from 'constants/route';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { showPermissionError } from 'components/Navbar/Navbar';
import coercedGet from 'utils/coercedGet';
import { columnAlign } from 'utils/tableAlignment';
import useTranslate from 'utils/useTranslate';
import { renderPaymentMethodByType } from 'pages/components/WithdrawalMethods/utils';
import WithdrawalMethodRemark from '../WithdrawalMethodRemark';
import WithdrawalMethodName from '../WithdrawalMethodName';
import WithdrawalStatus from '../WithdrawalMethodStatus';
import WithdrawalBankName from '../WithdrawalBankName';
import messages from '../../messages';
import AddEditWithdrawalMethod from '../AddEditWithdrawalMethod';
import DeleteWithdrawalMethod from '../DeleteWithdrawalMethod';
import DuplicateWithdrawalMethod from '../DuplicateWithdrawalMethod';
import UpdateWithdrawalMethodStatus from '../UpdateWithdrawalMethodStatus';
import WithdrawalMethodDetails from '../WithdrawalMethodDetails';

type Rec = Record<string, any>;
type Props = {
  dataSource: Array<Rec>;
  loading: boolean;
  refetchVariables: Rec;
};

const WithdrawalMethodsTable = ({
  dataSource,
  loading,
  refetchVariables,
}: Props) => {
  const { role, permissions } = usePermissions();

  const {
    ALLOWED_LIST,
    ALLOWED_VIEW_DETAILS,
    ALLOWED_EDIT,
    ALLOWED_DELETE,
    ALLOWED_DUPLICATE,
    ALLOWED_TOGGLE_ENABLED,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'VIEW_DETAILS', 'EDIT', 'DELETE', 'DUPLICATE', 'TOGGLE_ENABLED'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_WITHDRAWAL_METHODS
  );

  const [refetchID, setRefetchID] = useState('');
  const [state, setState] = useState<Rec>({
    currentDetailId: null,
    currentDetails: null,
    showWithdrawalMethodDetails: false,
    showWithdrawalMethodEdit: false,
    isDeleteWithdrawalMethod: false,
    isDuplicateWithdrawalMethod: false,
    isUpdateWithdrawalMethodStatus: false,
    withdrawalMethod: null,
    selectedRowKeys: [],
  });

  const {
    currentDetailId,
    showWithdrawalMethodDetails,
    showWithdrawalMethodEdit,
    currentDetails,
    isDeleteWithdrawalMethod,
    isDuplicateWithdrawalMethod,
    isUpdateWithdrawalMethodStatus,
  } = state;

  useEffect(() => {
    /**
     * This will clean up the monitored Data Loader ID in case if
     * the same ID is needed to be refetched again
     * */
    if (refetchID) {
      setRefetchID('');
    }
  }, [setRefetchID, refetchID]);

  const toggleDetails = (id?: string) => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('Withdrawal Methods - View Details');
      return;
    }
    setState({
      ...state,
      currentDetailId: id,
      showWithdrawalMethodDetails: !showWithdrawalMethodDetails,
    });
  };

  const toggleEdit = (value?: any) => {
    setState({
      ...state,
      showWithdrawalMethodEdit: !showWithdrawalMethodEdit,
      showWithdrawalMethodDetails: false,
      isUpdateWithdrawalMethodStatus: false,
      currentDetails: value,
    });
  };

  const toggleDelete = (id = null) => {
    setState({
      ...state,
      isDeleteWithdrawalMethod: !isDeleteWithdrawalMethod,
      currentDetailId: id,
    });
  };

  const toggleDuplicate = (id = null) => {
    setState({
      ...state,
      isDuplicateWithdrawalMethod: !isDuplicateWithdrawalMethod,
      currentDetailId: id,
    });
  };

  const toggleUpdateStatus = (value?: { id: string }) => {
    setState({
      ...state,
      isUpdateWithdrawalMethodStatus: !isUpdateWithdrawalMethodStatus,
      currentDetails: value,
      currentDetailId: value && value.id,
    });
  };

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = dataSource
    ? dataSource.map(({ node }) => ({ ...node, key: node.id }))
    : [];
  const translate = useTranslate();

  const columns = [
    {
      csvData: {
        label: translate(messages.title),
        key: 'name',
      },
      title: translate(messages.title),
      align: columnAlign.onCenter,
      key: 'name',
      render: (record: { id: string; name: string }) =>
        record.name ? (
          <WithdrawalMethodName
            recordID={record.id}
            toggleDetails={toggleDetails}
          />
        ) : (
          '-'
        ),
    },
    {
      csvData: {
        label: translate(messages.withdrawalsources),
        key: 'paymentSources',
        renderCell: (withdrawalMethod: Rec) => {
          if (
            isTypenameInternal(coercedGet(withdrawalMethod, '__typename', ''))
          ) {
            return translate(messages.internal);
          }
          return translate(messages.thirdparty);
        },
      },
      title: translate(messages.withdrawalsources),
      key: 'paymentSources',
      align: columnAlign.onCenter,
      dataIndex: '__typename',
      render: (method: string) => {
        if (isTypenameInternal(method)) {
          return translate(messages.internal);
        }
        return translate(messages.thirdparty);
      },
    },
    {
      csvData: {
        label: translate(messages.provider),
        key: 'provider',
        renderCell: (withdrawalMethod: Rec) =>
          coercedGet(withdrawalMethod, 'withdrawalProvider.name', '-'),
      },
      title: translate(messages.provider),
      align: columnAlign.onCenter,
      key: 'provider',
      render: (record: Rec) =>
        record.withdrawalProvider ? record.withdrawalProvider.name : '-',
    },
    {
      csvData: {
        label: translate(messages.withdrawalpaymentmethod),
        key: 'paymentMethod',
        renderCell: (withdrawalMethod: Rec) =>
          renderPaymentMethodByType(translate, withdrawalMethod.__typename),
      },
      title: translate(messages.withdrawalpaymentmethod),
      align: columnAlign.onCenter,
      dataIndex: '__typename',
      key: 'paymentMethod',
      render: (method: string) => renderPaymentMethodByType(translate, method),
    },
    {
      csvData: {
        label: `${translate(messages.bankname)} / ${translate(
          messages.nickname
        )}`,
        key: 'bankName',
        renderCell: (withdrawalMethod: Rec) => {
          const { bank, nickname, __typename } = withdrawalMethod;
          if (bank) {
            const newBank = getBank(bank) as {
              label: string;
            };
            return `${
              newBank?.label !== 'OTHER' ? `[${newBank?.label}] ` : ''
            } ${translate(bankMessages[newBank.label])}`;
          }
          if (nickname) return nickname;
          if (__typename === 'HexoPayWithdrawalMethod')
            return translate(messages.hexopay);

          return '-';
        },
      },
      title: `${translate(messages.bankname)} / ${translate(
        messages.nickname
      )}`,
      align: columnAlign.onCenter,
      width: 200,
      key: 'bank',
      render: (withdrawalMethod: Rec) => {
        const { bank, nickname, id, __typename } = withdrawalMethod;
        if (bank) {
          return (
            <WithdrawalBankName
              bankID={id}
              refetchID={refetchID}
              getBank={getBank}
              formatMessage={translate}
              bankMessages={bankMessages}
            />
          );
        }
        if (nickname) return nickname;
        if (__typename === 'HexoPayWithdrawalMethod')
          return translate(messages.hexopay);

        return '-';
      },
    },
    {
      csvData: {
        label: translate(messages.status),
        key: 'status',
        renderCell: (withdrawalMethod: Rec) =>
          coercedGet(withdrawalMethod, 'enabled', '')
            ? translate(messages.enable)
            : translate(messages.disable),
      },
      key: 'status',
      align: columnAlign.onCenter,
      width: 110,
      title: translate(messages.status),
      dataIndex: 'id',
      render: (id: string) => (
        <>
          <WithdrawalStatus
            formatMessage={translate}
            statusID={id}
            refetchID={refetchID}
          />
        </>
      ),
    },
    {
      csvData: {
        label: translate(messages.remark),
        key: 'remarks',
      },
      align: columnAlign.onCenter,
      title: translate(messages.remark),
      dataIndex: 'id',
      key: 'remarks',
      render: (id: string) => (
        <WithdrawalMethodRemark remarkId={id} refetchID={refetchID} />
      ),
    },
    {
      csvData: null,
      fixed: 'right',
      hidden: true,
      align: columnAlign.onCenter,
      width: 80,
      title: translate(messages.action),
      key: 'actions',
      render: (node: Rec) => {
        const disabledOnManual = isTypeManual(
          PAYMENT_METHODS_LIST[node.__typename]
        );
        return (
          (ALLOWED_EDIT ||
            ALLOWED_DUPLICATE ||
            ALLOWED_DELETE ||
            ALLOWED_TOGGLE_ENABLED) && (
            <Dropdown
              overlay={
                <Menu>
                  {ALLOWED_EDIT && (
                    <Menu.Item
                      disabled={disabledOnManual}
                      onClick={() => {
                        toggleEdit(node);
                      }}
                    >
                      {translate(messages.edit)}
                    </Menu.Item>
                  )}
                  {ALLOWED_DUPLICATE && (
                    <Menu.Item
                      disabled={disabledOnManual}
                      onClick={() => toggleDuplicate(node.id)}
                    >
                      {translate(messages.duplicate)}
                    </Menu.Item>
                  )}
                  {ALLOWED_DELETE && (
                    <Menu.Item
                      onClick={() => toggleDelete(node.id)}
                      disabled={node.enabled}
                    >
                      {translate(messages.delete)}
                    </Menu.Item>
                  )}
                  {ALLOWED_TOGGLE_ENABLED && (
                    <Menu.Item
                      disabled={disabledOnManual}
                      onClick={() => {
                        toggleUpdateStatus(node as { id: string });
                      }}
                    >
                      {translate(messages[node.enabled ? 'disable' : 'enable'])}
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomRight"
            >
              <div>
                <EllipsisOutlined
                  className="cursor-pointer font-weight-bold"
                  style={{ fontSize: '25px' }}
                />
              </div>
            </Dropdown>
          )
        );
      },
    },
  ];

  const { filterColumns } = useCustomColumnsV2(
    TabId.WITHDRAWAL_METHODS as any,
    columns
  );

  return (
    <>
      {showWithdrawalMethodDetails && (
        <WithdrawalMethodDetails
          id={currentDetailId}
          toggleDetails={toggleDetails}
          toggleEdit={toggleEdit}
        />
      )}
      {isDeleteWithdrawalMethod && (
        <DeleteWithdrawalMethod
          id={currentDetailId}
          toggleModal={toggleDelete}
          refetchVariables={refetchVariables}
        />
      )}
      {isDuplicateWithdrawalMethod && (
        <DuplicateWithdrawalMethod
          id={currentDetailId}
          toggleModal={toggleDuplicate}
          refetchVariables={refetchVariables}
        />
      )}
      {isUpdateWithdrawalMethodStatus && (
        <UpdateWithdrawalMethodStatus
          setRefetchID={setRefetchID}
          withdrawalMethod={currentDetails}
          toggleModal={toggleUpdateStatus}
          refetchVariables={refetchVariables}
          // toggleEdit={toggleEdit}
        />
      )}
      {showWithdrawalMethodEdit && (
        <AddEditWithdrawalMethod
          setRefetchID={setRefetchID}
          showFromEdit={showWithdrawalMethodEdit}
          editDetails={currentDetails}
          setShowEdit={toggleEdit}
          edit
        />
      )}
      {ALLOWED_LIST && (
        <StyledTable
          loading={loading}
          rowSelection={rowSelection}
          columns={filterColumns(columns)}
          size="small"
          dataSource={data}
          pagination={false}
          scroll={{ y: 640, x: 'calc(525px + 50%)' }}
          rowKey="id"
        />
      )}
    </>
  );
};

export default WithdrawalMethodsTable;
