import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import Filters from './components/Filters';
import VendorContent from './components/VendorContent';
import SiderContent from './components/SiderContent';
import { CostSettingsProvider } from './context';
import { CostSettingsSider } from './styles';

const CostVendorSettings = ({ summary }: { summary: Record<string, any> }) => (
  <CostSettingsProvider>
    <Layout>
      <Layout>
        <LayoutHeader>
          <Filters />
        </LayoutHeader>
        <Layout>
          <Layout.Content
            className="pl-3"
            style={{ marginTop: '28px', overflow: 'auto', height: 400 }}
          >
            <VendorContent summary={summary} />
          </Layout.Content>
          <CostSettingsSider width={300}>
            <SiderContent />
          </CostSettingsSider>
        </Layout>
      </Layout>
    </Layout>
  </CostSettingsProvider>
);

const LayoutHeader = styled(Layout.Header)`
  background-color: white;
  padding: 0 !important;
  line-height: 0;
  height: 52px;
  display: flex;
  align-items: center;

  &.ant-layout-header {
    padding: 0 !important;
  }
`;

export default CostVendorSettings;
