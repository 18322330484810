import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Button,
  Card,
  message as toast,
  Modal,
  Switch,
  Typography,
} from 'antd';
import gql from 'graphql-tag';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Config } from 'types/graphqlTypes';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const { Text } = Typography;

const SIGN_UP_CONFIG = gql`
  query SignUpConfig {
    config {
      interimSafeGamblingPage
      autoDetectUserCountryCode
      optInChecked
      tncChecked
    }
  }
`;

type Values = {
  interimSafeGamblingPage: boolean;
  autoDetectUserCountryCode: boolean;
  optInChecked: boolean;
  tncChecked: boolean;
};

const SignUpConfig = () => {
  const { context } = useOperatorHeader();

  const [visible, setVisible] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      interimSafeGamblingPage: false,
      autoDetectUserCountryCode: false,
      optInChecked: false,
      tncChecked: false,
    },
  });

  const [updateSignUpConfig, { loading }] = useMutation(UPDATE_CONFIG, {
    context,
  });

  const handleModalClose = () => {
    setVisible(false);
  };

  const { refetch } = useQuery<{ config: Config }>(SIGN_UP_CONFIG, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ config }) => {
      reset({
        interimSafeGamblingPage: config.interimSafeGamblingPage!,
        autoDetectUserCountryCode: config.autoDetectUserCountryCode!,
        optInChecked: config.optInChecked!,
        tncChecked: config.tncChecked!,
      });
    },
  });

  const onSubmit = async (values: Values) => {
    try {
      await updateSignUpConfig({
        variables: {
          input: {
            ...values,
          },
        },
      });

      refetch();
    } catch (err) {
      if (err) {
        toast.error('Something went wrong, please try again.');
      }
    } finally {
      toast.success('Sign Up Config updated successfully.');
      handleModalClose();
    }
  };

  const inputFieldArray = [
    {
      fieldTitle: 'Interim Safe Gambling Page',
      message:
        'Disabling this feature will redirect Member directly to Sign Up form.',
      fieldName: 'interimSafeGamblingPage',
    },
    {
      fieldTitle: 'User Country Code: Auto Detect',
      message:
        'Disabling this feature will display Country Code in lowest to highest number on Sign Up form.',
      fieldName: 'autoDetectUserCountryCode',
    },
    {
      fieldTitle: 'Opt-in: Default Check In for Member?',
      message: '',
      fieldName: 'optInChecked',
    },
    {
      fieldTitle: 'TNC & Privacy Policy: Default Check In for Member?',
      message: '',
      fieldName: 'tncChecked',
    },
  ];

  return (
    <div>
      <h3>Manage Sign Up Config</h3>
      <Button onClick={() => setVisible(true)}>Sign Up Config</Button>

      <Modal
        destroyOnClose
        centered
        title="Sign Up Config"
        visible={visible}
        onCancel={handleModalClose}
        width={700}
        bodyStyle={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2,auto)',
          height: '100%',
          rowGap: '20px',
          columnGap: '20px',
          width: '100%',
        }}
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}
        onOk={handleSubmit(onSubmit)}
      >
        {inputFieldArray.map((field) => {
          const { fieldName, fieldTitle, message } = field;

          return (
            <Card key={fieldName}>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                  <Text strong>{fieldTitle}</Text>
                  <Text
                    type="secondary"
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {message}
                  </Text>
                </div>
                <div>
                  <Controller
                    control={control}
                    name={fieldName}
                    render={({ onChange, value }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />
                    )}
                  />
                </div>
              </div>
            </Card>
          );
        })}
      </Modal>
    </div>
  );
};

export default SignUpConfig;
