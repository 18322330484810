import gql from 'graphql-tag';

export const GET_OPERATORS_IDS = gql`
  query Operators_Ids(
    $filter: OperatorsFilterInput
    $partialFilter: OperatorsFilterInput
  ) {
    operators(filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: operators(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_OPERATORS = gql`
  query Operators($first: Int, $after: Binary, $filter: OperatorsFilterInput) {
    operators(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          role
          username
          realName
          gender
          email
          mobileNumber
          wechatId
          qqId
          lastLoginDateTime
          registrationDateTime
          dateTimeCreated
          dateTimeUpdated
          emailVerified
          permissionGroups {
            id
            name
          }
          status
          notes
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_OPERATOR = gql`
  query Operator($id: ID!) {
    operator(id: $id) {
      id
      username
      realName
      gender
      email
      mobileNumber
      wechatId
      qqId
      permissionGroups {
        id
      }
      status
      notes
    }
  }
`;

export const GET_OPERATOR_USERNAMES = gql`
  query FilterOperatorUsernames(
    $first: Int
    $after: Binary
    $filter: OperatorsFilterInput
  ) {
    operators(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export default {};
