import React from 'react';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';
import { Domain } from 'types/graphqlTypes';
import { Button, Dropdown, Menu, Table } from 'antd';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import SiteStatusBadge from '../SiteStatusBadge';
import DeleteDomain from '../DeleteDomain';
import UpdateDomainStatus from '../UpdateDomainStatus';
import EditDomain from '../EditDomain';

const StyledTable = styled(Table)`
  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }
`;

type Table = {
  dataSource: Domain[];
  refetch: () => void;
};

const SiteConfigurationTable: React.FC<Table> = ({ dataSource, refetch }) => {
  const translate = useTranslate();
  const columns = [
    {
      title: translate(messages.name),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => name,
    },
    {
      title: translate(messages.descriptionremarks),
      dataIndex: 'description',
      key: 'description',
      render: (description: string) => description,
    },
    {
      title: translate(messages.status),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <SiteStatusBadge text={status} />,
    },
    {
      title: translate(messages.ssl),
      dataIndex: 'sslEnabled',
      key: 'sslEnabled',
      render: (ssl: boolean) => (ssl ? 'Enabled' : 'Disabled'),
    },
    {
      title: translate(messages.actions),
      key: 'actions',
      dataIndex: 'id',
      render: (id: string) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <UpdateDomainStatus id={id} refetch={refetch} />
              </Menu.Item>

              <Menu.Item onClick={(e) => e}>
                <EditDomain id={id} refetch={refetch} />
              </Menu.Item>

              <Menu.Item>
                <DeleteDomain id={id} refetch={refetch} />
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {};

  return (
    <StyledTable
      rowSelection={rowSelection}
      size="middle"
      columns={columns}
      dataSource={dataSource}
    />
  );
};

export default SiteConfigurationTable;
