import { defineMessages } from 'react-intl';
import coercedGet from './coercedGet';
import { DynamicObj } from '../interfaces/user.interface';

const messages = defineMessages({
  TRANSLATION_ERROR: {
    id: 'translation-error.text',
    defaultMessage: 'Translation Error',
  },
});

const noIdError = 'An `id` must be provided to format a message';

export const customFormatMessage = (
  formatMessage: any,
  message: {
    id: string;
    defaultMessage: string;
  },
  values?: DynamicObj | null,
  customFallback?: string | null
) => {
  try {
    return formatMessage(message, values);
  } catch (error) {
    const errorMessage = coercedGet(error, 'message', '');
    const idError = errorMessage.includes(noIdError);
    return idError
      ? customFallback || formatMessage(messages.TRANSLATION_ERROR)
      : '-';
  }
};
