import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import compose from 'utils/compose';
import removeNestedNulls from 'utils/removeNestedNulls';
import esGet from 'utils/esGet';
import {
  allTypes as createProgramTypes,
  useCreateLoyaltyProgramContext,
} from 'pages/components/MemberLoyaltyManagement/components/CreateProgramme/context';
import {
  processDepositOnlyCriteria,
  processUpgradePayout,
} from '../../../../utils';
import InnerForm from './InnerForm';
import { MEMBER_LOYALTY_PROGRAMME } from '../../../ProgrammeDetails/queries';

const EditProgramSettings = React.forwardRef(
  ({ currentProgramId }: { currentProgramId: any }, ref) => {
    const [, createProgrammeDispatch] = useCreateLoyaltyProgramContext() as any;

    const { loading, data = {} } = useQuery(MEMBER_LOYALTY_PROGRAMME, {
      variables: {
        id: currentProgramId,
      },
      fetchPolicy: 'network-only',
    }) as any;

    const memberLoyaltyProgramme = esGet(data?.memberLoyaltyProgramme, {});

    React.useEffect(() => {
      if (Object.keys(memberLoyaltyProgramme).length) {
        const procNode = compose(
          processUpgradePayout,
          processDepositOnlyCriteria,
          removeNestedNulls
        )(memberLoyaltyProgramme);

        createProgrammeDispatch({
          type: createProgramTypes.SET_ACTIVE_PROGRAMME,
          payload: procNode,
        });
        createProgrammeDispatch({
          type: createProgramTypes.SET_TIERS,
          payload: [...procNode.levels].reverse(),
        });
      }
    }, [createProgrammeDispatch, memberLoyaltyProgramme]);

    return (
      <InnerForm
        loading={loading}
        ref={ref}
        currentProgramId={currentProgramId}
        presetValues={{
          ...memberLoyaltyProgramme,
        }}
      />
    );
  }
);

export default EditProgramSettings;
