import React, { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Button, Input } from 'antd';
import styled from 'styled-components';
import messages from 'messages';
import { customFormatMessage } from 'utils/customFormatMessage';

const { TextArea } = Input;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding-top: 15px;

    p {
      color: rgba(0, 0, 0, 0.85);
    }

    small {
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    button {
      width: 47%;
    }
  }
`;

const StyledSubmitButton = styled(Button)<{ onHold: boolean }>`
  &,
  :active,
  :focus {
    color: ${(props) => (props.onHold ? '#800080' : '#ff0000')};
    border-color: ${(props) => (props.onHold ? '#800080' : '#ff0000')};
  }

  :hover {
    background-color: ${(props) => (props.onHold ? '#800080' : '#ff0000')};
    border-color: ${(props) => (props.onHold ? '#800080' : '#ff0000')};
    color: #fff;
  }
`;

type Props = {
  onHold?: boolean;
  id: string;
  visible: boolean;
  loading: boolean;
  setVisible: (value: boolean) => void;
  onSubmitRemarks: (values: any) => {};
  initialRemarks?: string;
};

const RemarkModal = ({
  id,
  visible,
  loading,
  setVisible,
  onSubmitRemarks,
  onHold = false,
  initialRemarks,
}: Props): ReactElement => {
  const { formatMessage } = useIntl();
  const [remarks, setRemark] = useState(initialRemarks);

  const handleSubmit = async () => {
    const values = {
      variables: {
        id,
        remarks,
      },
    };

    onSubmitRemarks(values);
  };
  const translate = (messageVal: any, options: any = null) =>
    customFormatMessage(formatMessage, messageVal, options);
  const footer = [
    <Button key={1} onClick={() => setVisible(false)} disabled={loading}>
      {translate(messages['cancel.text'])}
    </Button>,

    <StyledSubmitButton
      onHold={onHold}
      key={2}
      disabled={!remarks?.trim().length}
      onClick={handleSubmit}
      loading={loading}
    >
      {!onHold ? translate(messages.REJECT) : 'On Hold'}
    </StyledSubmitButton>,
  ];

  return (
    <StyledModal
      width={300}
      title={translate(messages.ACTION)}
      visible={visible}
      // tslint:disable-next-line: jsx-no-lambda
      onCancel={() => setVisible(false)}
      footer={footer}
    >
      {onHold ? (
        <p>{translate(messages.PLEASE_ENTER_ONHOLD_REASON)}</p>
      ) : (
        <p>{translate(messages.PLEASE_ENTER_REJECTION_REASON)}</p>
      )}
      <small>{translate(messages.OPEARATOR_REMARK)}</small>
      <TextArea
        autoSize={{ minRows: 4, maxRows: 7 }}
        placeholder={translate(messages.PLEASE_ENTER)}
        // tslint:disable-next-line: jsx-no-lambda
        onChange={({ target }) => setRemark(target.value)}
        value={remarks}
        disabled={loading}
      />
    </StyledModal>
  );
};

export default RemarkModal;
