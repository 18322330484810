import { useMutation, useQuery } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import { UPDATE_DEPOSIT_PROVIDER } from 'graphql/mutations/depositProvider.mutation';
import {
  DEPOSIT_PROVIDER,
  DEPOSIT_PROVIDERS,
} from 'graphql/queries/depositProvider.query';
import messages from 'pages/components/ThirdPartyDepositProvider/messages';
import globalMessages from 'messages';
import React from 'react';
import useTranslate from 'utils/useTranslate';
import ProviderForm from '../ProviderForm';
import { StyledModal } from './styles';

const EditProvider = (props: {
  id?: string | null;
  toggleModal: () => void;
  editable: boolean;
  refetchVariables: Record<string, any>;
}) => {
  const { toggleModal, id, editable, refetchVariables } = props;

  const refetchQueries = [
    {
      query: DEPOSIT_PROVIDER,
      variables: { id },
    },
    {
      query: DEPOSIT_PROVIDERS,
      variables: refetchVariables,
    },
  ];
  const translate = useTranslate();

  const [
    updateMidPayDepositProvider,
    updateMidPayDepositProviderResult,
  ] = useMutation(UPDATE_DEPOSIT_PROVIDER, {
    onCompleted: (data) => {
      if (!data) return;
      message.success({
        content: (
          <span data-testid="update-success">
            {translate(messages['successfully-saved-changes.text'])}
          </span>
        ),
      });
    },
    onError: () => {
      message.error({
        content: (
          <div data-testid="update-error">
            {translate(globalMessages.INTERNAL_SERVER_ERROR)}
          </div>
        ),
      });
    },
    refetchQueries,
  });

  const { loading, data = {} } = useQuery(DEPOSIT_PROVIDER, {
    variables: { id },
    onError: () =>
      message.error(translate(globalMessages.INTERNAL_SERVER_ERROR)),
  });

  const { depositProvider = {} }: Record<string, any> = data;

  return (
    <>
      <StyledModal
        visible
        width={1000}
        onOk={toggleModal}
        onCancel={toggleModal}
        footer={false}
        title={depositProvider && depositProvider.name}
      >
        <div>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <Spin />
            </div>
          ) : (
            <ProviderForm
              onClose={toggleModal}
              submitDepositProvider={updateMidPayDepositProvider}
              depositProvider={depositProvider}
              editable={editable}
              loading={updateMidPayDepositProviderResult.loading}
            />
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default EditProvider;
