import { DailyOperationReportItem } from 'types/graphqlTypes';
import { DATE_FORMAT } from 'constants/date';
import moment from 'moment-timezone';
import Numeral from 'numeral';

type ColumnProps = {
  bypassedCurrentTimezone: string;
};

export const renderNumeral = (text: string) =>
  Numeral(text)
    .format('0.00', (n) => n)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getUkColumns = ({ bypassedCurrentTimezone }: ColumnProps) => [
  {
    csvData: {
      label: 'Date',
      key: 'date',
      renderCell: (record: DailyOperationReportItem) =>
        record.date
          ? moment.tz(record.date, bypassedCurrentTimezone).format(DATE_FORMAT)
          : '-',
    },
    title: 'Date',
    dataIndex: 'date',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: true,
    align: 'center',
    render: (date: string) => moment(date).format(DATE_FORMAT),
  },
  {
    title: 'New Member',
    children: [
      {
        csvData: {
          label: 'No. of New Registrations',
          key: 'memberRegistrationsCount',
          renderCell: (row: DailyOperationReportItem) =>
            row.memberRegistrationsCount,
        },
        title: 'No. of New Registrations',
        dataIndex: 'memberRegistrationsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'No. of First Deposit',
          key: 'firstDepositsCount',
          renderCell: (row: DailyOperationReportItem) => row.firstDepositsCount,
        },
        title: 'No. of First Deposit',
        dataIndex: 'firstDepositsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'Amount of First Deposits',
          key: 'firstDepositsAmount',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.firstDepositsAmount),
        },
        title: 'Amount of First Deposits',
        dataIndex: 'firstDepositsAmount',
        align: 'center',
        render: renderNumeral,
      },
    ],
  },
  {
    title: 'All Members Financial',
    children: [
      {
        csvData: {
          label: 'Total No. of Deposits',
          key: 'depositsCount',
          renderCell: (row: DailyOperationReportItem) => row.depositsCount,
        },
        title: 'Total No. of Deposits',
        dataIndex: 'depositsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'Total Amount of Deposits',
          key: 'depositsAmount',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.depositsAmount),
        },
        title: 'Total Amount of Deposits',
        dataIndex: 'depositsAmount',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Total No. of Withdrawals',
          key: 'withdrawalsCount',
          renderCell: (row: DailyOperationReportItem) => row.withdrawalsCount,
        },
        title: 'Total No. of Withdrawals',
        dataIndex: 'withdrawalsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'Total Amount of Withdrawals',
          key: 'withdrawalsAmount',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.withdrawalsAmount),
        },
        title: 'Total Amount of Withdrawals',
        dataIndex: 'withdrawalsAmount',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Profit (Total Deposit Amount - Total Withdrawal Amount)',
          key: 'profit',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.profit),
        },
        title: 'Profit (Total Deposit Amount - Total Withdrawal Amount)',
        dataIndex: 'profit',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Promo Payouts',
          key: 'promoPayouts',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.promoPayouts),
        },
        title: 'Promo Payouts',
        dataIndex: 'promoPayouts',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Total Promo Turnover',
          key: 'totalBonusTurnover',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.totalBonusTurnover),
        },
        title: 'Total Promo Turnover',
        dataIndex: 'totalBonusTurnover',
        align: 'center',
        render: renderNumeral,
      },
    ],
  },
  {
    title: 'All Members Gaming',
    children: [
      {
        csvData: {
          label: 'Total No. of Bets',
          key: 'betsCount',
          renderCell: (row: DailyOperationReportItem) => row.betsCount,
        },
        title: 'Total No. of Bets',
        dataIndex: 'betsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'Total Amount of Bets',
          key: 'turnover',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.turnover),
        },
        title: 'Total Amount of Bets',
        dataIndex: 'turnover',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Total Amount of Effective Bets',
          key: 'effectiveBet',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.effectiveBet),
        },
        title: 'Total Amount of Effective Bets',
        dataIndex: 'effectiveBet',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Total Amount of GGR',
          key: 'ggr',
          renderCell: (row: { ggr: number }) => renderNumeral(String(row.ggr)),
        },
        title: 'Total Amount of GGR',
        dataIndex: 'ggr',
        align: 'center',
        render: (e: number) => renderNumeral(String(e)),
      },
      {
        csvData: {
          label: 'Bonus Rebates',
          key: 'bonusRebates',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.bonusRebates),
        },
        title: 'Bonus Rebates',
        dataIndex: 'bonusRebates',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'NGR',
          key: 'ngr',
          renderCell: (row: DailyOperationReportItem) => renderNumeral(row.ngr),
        },
        title: 'NGR',
        dataIndex: 'ngr',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Payout',
          key: 'payout',
          renderCell: (row: { payout: number }) =>
            renderNumeral(String(row.payout)),
        },
        title: 'Payout',
        dataIndex: 'payout',
        align: 'center',
        render: (e: number) => renderNumeral(String(e)),
      },
    ],
  },
];

export const getRowColumns = ({ bypassedCurrentTimezone }: ColumnProps) => [
  {
    csvData: {
      label: 'Date',
      key: 'date',
      renderCell: (record: DailyOperationReportItem) =>
        record.date
          ? moment.tz(record.date, bypassedCurrentTimezone).format(DATE_FORMAT)
          : '-',
    },
    title: 'Date',
    dataIndex: 'date',
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: true,
    align: 'center',
    render: (date: string) => moment(date).format(DATE_FORMAT),
  },
  {
    title: 'New Member',
    children: [
      {
        csvData: {
          label: 'No. of New Registrations',
          key: 'memberRegistrationsCount',
          renderCell: (row: DailyOperationReportItem) =>
            row.memberRegistrationsCount,
        },
        title: 'No. of New Registrations',
        dataIndex: 'memberRegistrationsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'No. of First Deposit',
          key: 'firstDepositsCount',
          renderCell: (row: DailyOperationReportItem) => row.firstDepositsCount,
        },
        title: 'No. of First Deposit',
        dataIndex: 'firstDepositsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'Amount of First Deposits',
          key: 'firstDepositsAmount',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.firstDepositsAmount),
        },
        title: 'Amount of First Deposits',
        dataIndex: 'firstDepositsAmount',
        align: 'center',
        render: renderNumeral,
      },
    ],
  },
  {
    title: 'All Member Financial',
    children: [
      {
        csvData: {
          label: 'Total No. of Deposits',
          key: 'depositsCount',
          renderCell: (row: DailyOperationReportItem) => row.depositsCount,
        },
        title: 'Total No. of Deposits',
        dataIndex: 'depositsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'Total Amount of Deposits',
          key: 'depositsBaseAmount',
          renderCell: (row: Record<string, string>) =>
            renderNumeral(row.depositsBaseAmount),
        },
        title: 'Total Amount of Deposits',
        dataIndex: 'depositsBaseAmount',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Total No. of Withdrawals',
          key: 'withdrawalsCount',
          renderCell: (row: DailyOperationReportItem) => row.withdrawalsCount,
        },
        title: 'Total No. of Withdrawals',
        dataIndex: 'withdrawalsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'Total Amount of Withdrawals',
          key: 'withdrawalsBaseAmount',
          renderCell: (row: Record<string, string>) =>
            renderNumeral(row.withdrawalsBaseAmount),
        },
        title: 'Total Amount of Withdrawals',
        dataIndex: 'withdrawalsBaseAmount',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Profit (Total Deposit Amount - Total Withdrawal Amount)',
          key: 'profit',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.profit),
        },
        title: 'Profit (Total Deposit Amount - Total Withdrawal Amount)',
        dataIndex: 'profit',
        align: 'center',
        render: renderNumeral,
      },
    ],
  },
  {
    title: 'All Members Gaming',
    children: [
      {
        csvData: {
          label: 'Total No. of Bets',
          key: 'betsCount',
          renderCell: (row: DailyOperationReportItem) => row.betsCount,
        },
        title: 'Total No. of Bets',
        dataIndex: 'betsCount',
        align: 'center',
      },
      {
        csvData: {
          label: 'Total Amount of Bets',
          key: 'baseTurnover',
          renderCell: (row: Record<string, string>) =>
            renderNumeral(row.baseTurnover),
        },
        title: 'Total Amount of Bets',
        dataIndex: 'baseTurnover',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Total Amount of Effective bets',
          key: 'baseEffectiveBet',
          renderCell: (row: Record<string, string>) =>
            renderNumeral(row.baseEffectiveBet),
        },
        title: 'Total Amount of Effective bets',
        dataIndex: 'baseEffectiveBet',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Payout',
          key: 'basePayout',
          renderCell: (row: { basePayout: number }) =>
            renderNumeral(String(row.basePayout)),
        },
        title: 'Payout',
        dataIndex: 'basePayout',
        align: 'center',
        render: (e: number) => renderNumeral(String(e)),
      },
      {
        csvData: {
          label: 'Total Amount of GGR',
          key: 'baseGgr',
          renderCell: (row: { baseGgr: number }) =>
            renderNumeral(String(row.baseGgr)),
        },
        title: 'Total Amount of GGR',
        dataIndex: 'baseGgr',
        align: 'center',
        render: (e: number) => renderNumeral(String(e)),
      },
      {
        csvData: {
          label: 'Promo (Bonus Rebate)',
          key: 'baseTotalPromo',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.baseTotalPromo),
        },
        title: 'Promo (Bonus Rebate)',
        dataIndex: 'baseTotalPromo',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'NGR (GGR - Promo)',
          key: 'baseNgr',
          renderCell: (row: { baseNgr: number }) =>
            renderNumeral(String(row.baseNgr)),
        },
        title: 'NGR (GGR - Promo)',
        dataIndex: 'baseNgr',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Promo Payouts',
          key: 'basePromoPayouts',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.basePromoPayouts),
        },
        title: 'Promo Payouts',
        dataIndex: 'basePromoPayouts',
        align: 'center',
        render: renderNumeral,
      },
      {
        csvData: {
          label: 'Total Promo Turnover',
          key: 'totalBaseBonusTurnover',
          renderCell: (row: DailyOperationReportItem) =>
            renderNumeral(row.totalBaseBonusTurnover),
        },
        title: 'Total Promo Turnover',
        dataIndex: 'totalBaseBonusTurnover',
        align: 'center',
        render: renderNumeral,
      },
    ],
  },
];
