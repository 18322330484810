import React, { useState } from 'react';
import { withErrorHandler } from 'components/ErrorHandler';
import FiltersProvider from 'SuperAdminMain/contexts/Filters';
import ReportsTimezoneProvider from 'contexts/ReportsTimezone';

import { FeatureFlagsSA as FF } from 'SuperAdminMain/constants/featureflags';
import FeaturedFlagWrap from 'components/FeaturedFlagWrap';
import Schedule from './components/Schedule';
import Sidebar from './components/Sidebar';
import { StyledLayout, StyledContent, StyledSider } from './styles';
import ReportsTable from './components/ReportsTable';
import TableHeader from './components/TableHeader';

const BalanceTransactionRecords = () => {
  const [isSidebar, setSidebar] = useState(false);
  const handleSidebar = () => setSidebar(!isSidebar);
  const tableRef = React.useRef(null);

  const initialFilters = {
    vendor: null,
    timezone: null,
    dateTimeProcessed: null,
  };

  return (
    <FiltersProvider initialValues={initialFilters}>
      <ReportsTimezoneProvider>
        <StyledLayout className="d-flex">
          <StyledContent>
            <TableHeader handleSidebar={handleSidebar} ref={tableRef} />
            <div className="d-flex">
              <Sidebar ref={tableRef} collapsed={isSidebar} />
              <StyledContent className="justify-content-space-between flex-direction-column">
                <ReportsTable ref={tableRef} />
              </StyledContent>
            </div>
          </StyledContent>

          <FeaturedFlagWrap flag={FF.curvScheduledList || ''}>
            <StyledSider
              theme="light"
              width={303}
              style={{
                overflowY: 'auto',
                height: 'auto',
              }}
            >
              <Schedule ref={tableRef} />
            </StyledSider>
          </FeaturedFlagWrap>
        </StyledLayout>
      </ReportsTimezoneProvider>
    </FiltersProvider>
  );
};

export default withErrorHandler(BalanceTransactionRecords);
