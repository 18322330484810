import React from 'react';
import moment from 'moment';
import Block from 'react-blocks';
import { FormattedMessage } from 'react-intl';

import SaveSearch from './components/SaveSearch';
import SearchSettings from './components/SearchSettings';

type Props = {
  state: Record<string, any>;
  setState: (data: Record<string, any>) => void;
};

const FilterSettings = ({ state, setState }: Props) => (
  <>
    <Block
      layout
      horizontal
      style={{ justifyContent: 'space-between' }}
      className="mb-1"
    >
      <Block>
        <div className="mb-0">
          <FormattedMessage id="search.text" defaultMessage="Search" />:
        </div>
      </Block>
      <Block>
        <SaveSearch filters={state} /> |
        <SearchSettings
          onUseFilter={(filters) => {
            const derivedFilters = {
              ...filters,
              dateRange: filters.dateRange.length
                ? [moment(filters.dateRange[0]), moment(filters.dateRange[1])]
                : [],
            };
            setState({
              ...state,
              ...derivedFilters,
            });
          }}
        />
      </Block>
    </Block>
    <Block>
      <small>
        <FormattedMessage
          id="quick-search.text"
          defaultMessage="Quick search"
        />
        :
      </small>
    </Block>
  </>
);

export default FilterSettings;
