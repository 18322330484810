import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  actions,
  initReducerState,
  reducer,
} from 'components/MoreActionsMembersFilter/utils';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import { FilterProps } from 'interfaces/filters.interface';
import { get, isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import { useLazyQueryPromise } from 'pages/components/common/hooks/useLazyQueryPromise';
import React, { useEffect, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledSelect } from './styles';

const { Option } = StyledSelect;

const MoreActionsMembersFilter = ({
  onChange,
  value,
  placeholder,
  disabled,
  validateStatus,
  help,
}: FilterProps) => {
  const [{ members, filter, isSearching, initialized }, dispatch] = useReducer(
    reducer,
    initReducerState
  );
  const [fetchMembers] = useLazyQueryPromise(GET_MEMBERS);

  const loadMembers = (opts: Record<string, any>) => {
    const overwriteMembers = get(opts, 'overwriteMembers');
    const overrideFilter = get(opts, 'overrideFilter');
    const onInitialized = get(opts, 'onInitialized', false);

    const options = {
      ...(!overrideFilter && { first: 10 }),
      filter: overrideFilter || filter,
    };

    fetchMembers(options).then(({ data }: { data: any }) => {
      const edges = get(data, 'members.edges', []);
      const memberNodes = edges.map(
        (edge: { node: Record<string, any> }) => edge.node
      );

      dispatch([
        overwriteMembers ? actions.OVERWRITE_MEMBERS : actions.SET_MEMBERS,
        memberNodes,
      ]);

      dispatch([actions.SET_SEARCHING, false]);

      if (onInitialized) {
        loadMembers({
          first: 10,
        });
      }
    });
  };

  useEffect(() => {
    if (!isEmpty(value)) {
      const idsArray = value.map((x: { key: string }) => x.key);
      loadMembers({
        onInitialized: true,
        overrideFilter: {
          id: {
            in: idsArray,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isEmpty(value) && !initialized) {
      dispatch([actions.SET_SEARCHING, true]);
      loadMembers({
        overwriteMembers: true,
      });
      return dispatch([actions.SET_INITIALIZED, true]);
    }

    if (!initialized) {
      return dispatch([actions.SET_INITIALIZED, true]);
    }

    dispatch([actions.SET_SEARCHING, true]);
    loadMembers({
      overwriteMembers: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const handleSearch = (e: string) => {
    dispatch([
      actions.SET_FILTER,
      {
        username: {
          contains: e.toUpperCase(),
        },
      },
    ]);
  };

  const handleChange = (e?: Array<any>) => {
    onChange(e?.length ? e : null);
  };

  return (
    <Form.Item
      validateStatus={validateStatus as any}
      help={help}
      wrapperCol={{ span: 24 }}
    >
      <span>
        <FormattedMessage
          id="members-involve.text"
          defaultMessage="Members involve: {totalCount} people"
          values={{ totalCount: value && value.length ? value.length : 0 }}
        />
      </span>
      <StyledSelect
        id="members-involve"
        mode="multiple"
        value={value || []}
        showSearch
        labelInValue
        placeholder={placeholder || ''}
        onChange={handleChange}
        filterOption={false}
        loading={isSearching}
        onSearch={debounce(handleSearch, 500)}
        disabled={disabled}
      >
        {members.map((node: { id: string; username: string }) => (
          <Option key={node.id} value={node.id}>
            {node.username}
          </Option>
        ))}
      </StyledSelect>
    </Form.Item>
  );
};

export default MoreActionsMembersFilter;
