import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import {
  VendorsConnectionEdge,
  Vendor as VendorType,
} from 'types/graphqlTypes';

import { VENDORS } from './queries';
import { StyledSelect, StyledSpan, StyledLabel } from '../../styles';
import { FilterStateType, SetFilterStateType } from '../../../../types';

const { Option } = Select;

type Props = {
  state: FilterStateType;
  setState: SetFilterStateType;
};

const Vendor: React.FC<Props> = ({ state, setState }) => {
  const { data } = useQuery(VENDORS, {
    // variables: { first: 10 },
  });

  const edges = get(data, 'vendors.edges', []);

  const options = edges.map((edge: VendorsConnectionEdge) => edge.node);

  const handleChange = (e: Array<string>) => {
    if (e.length) {
      setState((prev: FilterStateType) => ({
        ...prev,
        vendor: { in: e },
      }));
    } else {
      setState((prev: FilterStateType) => ({
        ...prev,
        vendor: null,
      }));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.vendor.text"
              defaultMessage="Vendor"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        data-testid="select"
        allowClear
        placeholder={
          <FormattedMessage id="reports.select.text" defaultMessage="Select" />
        }
        mode="multiple"
        style={{ width: '100%' }}
        // eslint-disable-next-line react/prop-types
        value={state.vendor ? (state.vendor.in as string[]) : []}
        optionFilterProp="label"
        onChange={handleChange as any}
      >
        {options.map((option: VendorType) => (
          <Option key={option.id} value={option.id} label={option.name}>
            {option.name}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};

export default Vendor;
