import React, { useState } from 'react';
import { Popover } from 'antd';
import { TwitterPicker } from 'react-color';
import { StyledContainer, StyledColor } from './styles';

const colorsArray = [
  '#d3d3d3',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
  '#823d4d',
  '#df40b6',
  '#b45f7a',
  '#dd902f',
  '#e099c2',
  '#96ccf5',
  '#b99fce',
  '#ff006c',
  '#000000',
];

const colorOptions = colorsArray.reduce(
  (acc, currVal) => ({
    ...acc,
    [currVal]: currVal,
  }),
  {}
);

const getKeyByValue = (obj: Record<string, any>, value: string) =>
  Object.keys(obj).find((key) => obj[key] === value);

const ColorPicker = ({
  onChange,
  value,
}: {
  onChange: (color: string) => void;
  value: string;
}) => {
  const [colorValue, setColorValue] = useState(
    getKeyByValue(colorOptions, value)
  );

  const handleChangeComplete = (color: Record<string, any>) => {
    setColorValue(color.hex);

    onChange(color.hex);
  };

  return (
    <Popover
      placement="rightTop"
      content={
        <StyledContainer>
          <TwitterPicker
            triangle="hide"
            colors={Object.keys(colorOptions)}
            onChange={handleChangeComplete}
          />
        </StyledContainer>
      }
      trigger="click"
    >
      <StyledColor className="mx-2" value={colorValue} />
    </Popover>
  );
};

export default ColorPicker;
