import React, { useEffect } from 'react';
import coercedGet from 'utils/coercedGet';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';

interface Props {
  remarkId: string;
  refetchID: string;
}

const WithdrawalMethodRemark = ({ remarkId, refetchID }: Props) => {
  const {
    remarkLoaderSet: [useRemarkLoader],
  } = useLoaders();
  const { loading, error, data = {}, refetch } = useRemarkLoader(remarkId);

  useEffect(() => {
    if (refetchID === remarkId) {
      refetch();
    }
  }, [remarkId, refetchID, refetch]);

  const translate = useTranslate();

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;

  const remark = coercedGet(data, 'remarks', null);
  const tableCell =
    remark === 'Manual Adjustment'
      ? translate(messages.MANUAL_ADJUSTMENT)
      : remark;

  return <>{tableCell}</>;
};

export default WithdrawalMethodRemark;
