import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import { FilterItem } from 'components/FilterItem/FilterItem';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import ActorSelect from 'components/ActorSelect';
import messages from 'messages';
import ModuleSelect from 'components/ModuleSelect';
import ActionSelect from 'components/ActionSelect';
import SelectFilterField from 'components/SelectFilterField';
import { useFilterValues } from 'contexts/Filters';
import BrandFilter from 'components/BrandFilter';
import {
  GET_MEMBER_BRAND_IDS,
  GET_MEMBER_PLATFORM_IDS,
  MEMBERS,
} from 'graphql/queries/memberInteractionLog.query';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import { useScreenTabV2 } from 'store/screenTabState';
import { omit } from 'lodash';

type Props = {
  filters: Record<string, any>;
  onFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
};

const FilterItems = ({ filters, onFilterChange: handleChange }: Props) => {
  const translate = useTranslate();

  const { setPersistedFilters } = useFilterValues();

  const usernameValues = filters?.member?.in || [];

  const { getTab } = useScreenTabV2();
  const { searchRef } = getTab('member-interaction-log-report');
  const { filterFieldName } = searchRef || {};

  const modifyFilterFieldName = (value: string) => {
    if (value === 'username') return 'member';
    if (value === 'action') return 'type';

    return value;
  };

  const modifiedFilters = (omit(
    filters,
    modifyFilterFieldName(filterFieldName)
  ) as unknown) as Record<string, any>;

  const filterItems = {
    dateTimeCreated: () => (
      <DateTimeRangePicker
        testId="MILR-dateTime-created-filter"
        label="date-time"
        value={
          filters.dateTimeCreated
            ? [filters.dateTimeCreated.gte, filters.dateTimeCreated.lte]
            : null
        }
        onChange={(e) =>
          handleChange({
            name: 'dateTimeCreated',
            value: e?.length ? { gte: e[0], lte: e[1] } : null,
          })
        }
      />
    ),

    member: () => (
      <IdBasedSelectFilterField
        testId="MILR-username-filter"
        label="Member"
        query={MEMBERS}
        queryConnection="members"
        filterFieldName="username"
        filters={filters}
        rawFilterValues={usernameValues}
        onChange={(e) =>
          handleChange({
            name: 'member',
            value: e?.length ? { in: e } : null,
          })
        }
        partialSupported={false}
      />
    ),

    brandId: () => (
      <SelectFilterField
        testId="MILR-brandId-filter"
        label="Brand Id"
        query={GET_MEMBER_BRAND_IDS}
        queryConnection="members"
        filterFieldName="brandId"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'brandId',
            value: e?.length ? { in: e } : null,
          })
        }
      />
    ),
    platformId: () => (
      <SelectFilterField
        testId="MILR-platformId-filter"
        label="Platform Id"
        query={GET_MEMBER_PLATFORM_IDS}
        queryConnection="members"
        filterFieldName="platformId"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'platformId',
            value: e?.length ? { in: e } : null,
          })
        }
      />
    ),
    actor: () => (
      <FilterItem
        label={translate(messages.ACTOR)}
        onClear={() =>
          handleChange({
            name: 'actor',
            value: null,
          })
        }
      >
        <ActorSelect
          value={coercedGet(filters, 'actor.in', [])}
          onChange={(val) =>
            handleChange({
              name: 'actor',
              value: val.length ? { in: val } : null,
            })
          }
          placeHolder={translate(messages.PLEASE_SELECT)}
          multiple
        />
      </FilterItem>
    ),
    module: () => (
      <FilterItem
        label={translate(messages.MODULE)}
        onClear={() =>
          handleChange({
            name: 'module',
            value: null,
          })
        }
      >
        <ModuleSelect
          value={coercedGet(filters, 'module.in', [])}
          onChange={(val) => {
            handleChange({
              name: 'module',
              value: val.length ? { in: val } : null,
            });
          }}
          placeHolder={translate(messages.PLEASE_SELECT)}
          multiple
        />
      </FilterItem>
    ),
    type: () => (
      <FilterItem
        label={translate(messages.ACTION)}
        onClear={() =>
          handleChange({
            name: 'type',
            value: null,
          })
        }
      >
        <ActionSelect
          value={coercedGet(filters, 'type.in', [])}
          onChange={(val) =>
            handleChange({
              name: 'type',
              value: val.length ? { in: val } : null,
            })
          }
          placeHolder={translate(messages.PLEASE_SELECT)}
          multiple
        />
      </FilterItem>
    ),
  };

  return (
    <>
      {Object.keys(modifiedFilters).map((filter) => filterItems[filter]())}

      <BrandFilter setState={setPersistedFilters} state={filters} />
    </>
  );
};

export default FilterItems;
