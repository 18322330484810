import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Row } from 'antd';
import { useFormik } from 'formik';
import CountFilter from 'pages/components/IPAddressSummary/components/CountFilter';
import globalMsgs from 'messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterItem } from 'components/FilterItem/FilterItem';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import { GET_IPADDRESS } from 'graphql/queries/ipAddressSummary.query';
import SelectFilterField from 'components/SelectFilterField';
import { blockAlphabets } from 'utils/blockAlphabets';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

interface ISearchSettingsForm {
  onClose: Function;
  initialValues: any;
  onSubmit: (fields: any) => void;
  isLoading: boolean;
}

const SearchSettingsForm = ({
  onClose,
  initialValues,
  onSubmit,
  isLoading,
}: ISearchSettingsForm) => {
  const translate = useTranslate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  const { handleSubmit, setFieldValue, values } = formik;

  const onChangeCount = (count: any) => {
    const currentFilter = coercedGet(values, 'membersCount', {});
    const newFilter = { ...currentFilter, [count.range]: count.value };

    setFieldValue('membersCount', removeNull(newFilter));
  };

  return (
    <StyledForm>
      <Row className="mb-3">
        <Col span={12}>
          <SelectFilterField
            label="IP Address"
            query={GET_IPADDRESS}
            queryConnection="ipAddressSummaryReport"
            filterFieldName="ipAddress"
            filters={values}
            onChange={(e) =>
              setFieldValue('ipAddress', e?.length ? { in: e } : null)
            }
            onKeyDown={blockAlphabets}
          />

          <FilterItem label={translate(globalMsgs.TOTAL_COUNT)}>
            <CountFilter
              value={{
                min: coercedGet(values, 'membersCount.gte', undefined),
                max: coercedGet(values, 'membersCount.lte', undefined),
              }}
              onChange={onChangeCount}
              minPlaceHolder={translate(globalMsgs.MIN)}
              maxPlaceHolder={translate(globalMsgs.MAX)}
            />
          </FilterItem>
        </Col>
      </Row>
      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose as any} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>{' '}
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={handleSubmit as any}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
