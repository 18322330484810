import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';
import { SelectProps } from 'interfaces/select.interface';
import { MemberLevelsConnectionEdge } from 'types/graphqlTypes';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import { MEMBER_LEVELS } from './queries';
import { StyledSelect, StyledCustomClear } from './styles';
// trigger ci
const MemberLevelsSelect = ({
  onChange,
  value,
  placeHolder,
  disabled,
  label,
  multiple,
  style,
  isFilter, // this prop affects the style of the component (checkout the styles.js file within this directory)
  labelAsValue,
  forRebates, // this prop also includes the background color for each tag
  size,
  variant,
  className,
  topRightClear,
  status,
  ...restProps
}: SelectProps) => {
  const { Option } = Select;

  const [filter, setFilter] = useState({
    name: {
      contains: '',
    },
    status: {
      in: status,
    },
  });
  const defaultLevels: any = useRef([]);
  const [opts, setOpts] = useState<any>({});

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );
  const { loading, refetch } = useQuery(MEMBER_LEVELS, {
    variables: {
      filter,
      first: 10,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const memberLevels = data.memberLevels.edges.map(
        (edge: MemberLevelsConnectionEdge) => edge.node
      );
      setOpts({
        memberLevels,
      });
    },
  });
  useEffect(() => {
    if (filter) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const hasMemberLevels = !isEmpty(get(opts, 'memberLevels', []));

  if (value) {
    const createLevelObject = (id: string) => {
      if (hasMemberLevels) {
        return opts?.memberLevels.find(
          (level: { id: string }) => level.id === id
        );
      }
      return {};
    };

    value.forEach((id: string) => {
      defaultLevels.current = [...defaultLevels.current, createLevelObject(id)];
    });
  }

  const options =
    hasMemberLevels &&
    opts.memberLevels.map(
      (
        memberLevel: {
          value: string;
          label: string;
          id: string;
          color: string;
        },
        index: number
      ) => (
        <Option
          key={memberLevel?.value}
          value={labelAsValue ? memberLevel.label : memberLevel.value}
          color={memberLevel.color}
          title={`${forRebates ? memberLevel.label : index}`}
        >
          {memberLevel.label}
        </Option>
      )
    );

  return (
    <div className={className}>
      <div className="d-flex justify-content-space-between">
        {label && (
          <div className="filter-label font-weight-bold fs-10">{label}</div>
        )}
        {topRightClear ? (
          <div>
            <StyledCustomClear onClick={() => onChange([])}>
              <FormattedMessage id="clear.text" defaultMessage="Clear" />
            </StyledCustomClear>
          </div>
        ) : (
          ''
        )}
      </div>
      <StyledSelect
        size={size}
        mode={multiple ? 'multiple' : undefined}
        value={value}
        showSearch
        placeholder={placeHolder || ''}
        onChange={(e: any[]) => {
          onChange(e);
          if (!e.length) {
            setFilter((filters) => ({
              ...filters,
              name: {
                contains: '',
              },
            }));
          }
        }}
        onBlur={() => {
          setFilter((filters) => ({
            ...filters,
            name: {
              contains: '',
            },
          }));
        }}
        onFocus={() => refetch()}
        loading={loading}
        optionFilterProp="title"
        filterOption={(inputValue: string, option: any) =>
          option.props.title.toLowerCase().includes(inputValue.toLowerCase())
        }
        disabled={disabled}
        style={style}
        isFilter={isFilter}
        variant={variant}
        tags={defaultLevels.current}
        forRebates={forRebates}
        allowClear={!topRightClear}
        {...restProps}
      >
        {!multiple && ALLOWED_LIST && (
          <Option disabled value={null as any}>
            <span className="text-gray">
              <FormattedMessage
                id="select-a-marker.text"
                defaultMessage="Select a marker"
              />
            </span>
          </Option>
        )}
        {options}
      </StyledSelect>
    </div>
  );
};

export default MemberLevelsSelect;
