import React from 'react';
import { Input, Select } from 'antd';
import styled from 'styled-components';
import { StyledSider, StyledContainer } from './styles';

const Sidebar = () => (
  <Root>
    <StyledSider
      className="br-1"
      theme="light"
      trigger={null}
      collapsible
      collapsedWidth={0}
      width={232}
    >
      <StyledContainer className="p-4">
        <Input.Search placeholder="Search" />
        <div className="my-3">
          <div className="mb-1">ID</div>
          <Input />
        </div>
        <div className="my-3">
          <div className="mb-1">Actual Name</div>
          <Input.Search allowClear />
        </div>
        <div className="my-3">
          <div className="mb-1">Member Level</div>
          <Select placeholder="Select" style={{ width: '100%' }}>
            <Select.Option value="1">Member level 1</Select.Option>
            <Select.Option value="2">Member level 2</Select.Option>
          </Select>
        </div>
        <div className="my-3">
          <div className="mb-1">Agent Loyalty Tier</div>
          <Select placeholder="Select" style={{ width: '100%' }}>
            <Select.Option value="1">Agent Loyalty Tier 1</Select.Option>
            <Select.Option value="2">Agent Loyalty Tier 2</Select.Option>
          </Select>
        </div>
        <div className="my-3">
          <div className="mb-1">Total Turnover</div>
          <Input placeholder="From - To" />
        </div>
        <div className="my-3">
          <div className="mb-1">Total Payout</div>
          <Input placeholder="From - To" />
        </div>
        <div className="my-3">
          <div className="mb-1">Status</div>
          <Select placeholder="Select" style={{ width: '100%' }}>
            <Select.Option value="1">Status 1</Select.Option>
            <Select.Option value="2">Status 2</Select.Option>
          </Select>
        </div>
        <div className="my-3">
          <div className="mb-1">Last Online</div>
          <Select placeholder="Select" style={{ width: '100%' }}>
            <Select.Option value="1">Last Online 1</Select.Option>
            <Select.Option value="2">Last Online 2</Select.Option>
          </Select>
        </div>
        <div className="my-3">
          <div className="mb-1">Apply Date</div>
          <Input placeholder="From - To" />
        </div>
        <div className="my-3">
          <div className="mb-1">Join Date</div>
          <Input placeholder="From - To" />
        </div>
        <div className="my-3">
          <div className="mb-1">Approved By</div>
          <Select placeholder="Select" style={{ width: '100%' }}>
            <Select.Option value="1">Status 1</Select.Option>
            <Select.Option value="2">Status 2</Select.Option>
          </Select>
        </div>
        <div className="my-3">
          <div className="mb-1">Approved Date</div>
          <Input placeholder="From - To" />
        </div>
        <div className="my-3">
          <div className="mb-1">Number of Approved Downlines</div>
          <Select placeholder="Select" style={{ width: '100%' }}>
            <Select.Option value="1">Status 1</Select.Option>
            <Select.Option value="2">Status 2</Select.Option>
          </Select>
        </div>
      </StyledContainer>
    </StyledSider>
  </Root>
);

const Root = styled.div`
  .ant-input {
    border: 0 !important;
    border-bottom: 1px solid #d9d9d9 !important;
    padding-left: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
  .ant-select-selection {
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
  .ant-select-selection__rendered {
    margin-left: 0;
  }
`;

export default Sidebar;
