import { useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import {
  APPROVE_WITHDRAWAL_NEXT,
  START_PROCESS_WITHDRAWAL,
} from 'graphql/mutations/withdrawal.mutation';
import {
  WITHDRAWAL,
  WITHDRAWAL_REQUESTS,
} from 'graphql/queries/withdrawal.query';
import messages from 'pages/components/MemberWithdrawals/messages';
import React from 'react';
import { ALink } from 'components/ALink/ALink';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';

type Props = {
  isPending: boolean;
  id: string;
  disabled: boolean;
  refetchVariables: any;
};

const ApproveWithdrawal = ({
  id,
  disabled,
  refetchVariables,
  isPending,
}: Props) => {
  const refetchQueries = [
    {
      query: WITHDRAWAL,
      variables: { id },
    },
    {
      query: WITHDRAWAL_REQUESTS,
      variables: refetchVariables,
    },
  ];
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [approveWithdrawalNext, { loading }] = useMutation(
    APPROVE_WITHDRAWAL_NEXT,
    {
      onCompleted: () => {
        message.success(translate(messages.withdrawalApproved));
      },
      variables: {
        id,
      },
      refetchQueries,
      context,
    }
  );

  const [startProcessWithdrawal, startProcessResult] = useMutation(
    START_PROCESS_WITHDRAWAL,
    {
      onCompleted: () => {
        approveWithdrawalNext();
      },
      variables: { id },
      refetchQueries,
    }
  );

  const onHandleApprove = () => {
    if (isPending) {
      startProcessWithdrawal();
    } else {
      approveWithdrawalNext();
    }
  };

  return (
    <ALink onClick={onHandleApprove} disabled={disabled}>
      {loading || startProcessResult.loading ? <Spin /> : null}
      {translate(messages.approve)}
    </ALink>
  );
};

export default ApproveWithdrawal;
