import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Tag } from 'antd';
import { DEPOSIT_PROVIDER } from './queries';

const DepositProviderTag = (props: {
  id: string;
  onClose: (e: any) => void;
}) => {
  const { id, onClose } = props;
  const { data = {} } = useQuery(DEPOSIT_PROVIDER, {
    variables: {
      id,
    },
  }) as Record<string, any>;

  const { depositProvider = {} } = data;

  return (
    <Tag closable onClose={(e) => onClose(e)}>
      {depositProvider.name || id}
    </Tag>
  );
};

export default DepositProviderTag;
