import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Tag, Badge } from 'antd';
import styled from 'styled-components';
import { MEMBER_TAG } from './queries';

const StyledTag = styled(Tag)`
  margin: 4px;
  border-radius: 11px !important;
`;

type Props = {
  id: string;
  onClose: (e: any) => any;
};

const MemberLabelTag: React.FC<Props> = ({ id, onClose }) => {
  const { data } = useQuery(MEMBER_TAG, {
    variables: {
      id,
    },
  });

  const { memberTag } = data || {};

  return (
    <StyledTag closable onClose={(e: any) => onClose(e)}>
      <Badge color={memberTag?.color || '#ffffff'} />
      <span>{memberTag?.name}</span>
    </StyledTag>
  );
};

export default MemberLabelTag;
