import React from 'react';
import styled from 'styled-components';
import { ErrorHandler } from 'components/ErrorHandler';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;
type Props = {
  children: React.ReactNode;
  className?: string;
  rest?: any[];
};

export const StyledInputGroup: React.FC<Props> = ({
  children,
  className,
  ...rest
}: Props) => (
  <ErrorHandler>
    <div className={`${'my-2 '}${className || ''}`} {...rest}>
      {children}
    </div>
  </ErrorHandler>
);

export default {};
