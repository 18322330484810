/* eslint-disable no-bitwise */
import React from 'react';
import Numeral from 'numeral';
import { Typography } from 'antd';
import gameTypes from 'constants/gameTypes';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';

const { Text } = Typography;

const PieChartIndex = ({
  dataSource,
  metric,
  gameTypeColumns,
}: {
  metric: string;
  gameTypeColumns: string[];
  dataSource: Record<string, any>;
}) => {
  const remappedValues: Array<any> = [];
  gameTypeColumns.forEach((gtc) => {
    const pieValue = dataSource[`${gtc.toLowerCase()}_metric`];
    remappedValues.push({
      name: gtc,
      value: pieValue >= 0 ? pieValue : pieValue * -1,
      tooltipValue: pieValue,
      metric,
    });
  });
  return (
    <ResponsiveContainer height={300} width="100%">
      <PieChart>
        <Tooltip
          formatter={(_, _1, { payload }) => {
            const {
              payload: { tooltipValue, metric: innerMetric },
            } = payload;
            const color = tooltipValue < 0 ? '#f5222d' : '#52c41a';
            return (
              <span>
                <Text style={{ color }} className="mr-1">
                  {Numeral(tooltipValue).format('0.00', (n) => ~~n)}
                </Text>
                ({innerMetric})
              </span>
            );
          }}
        />
        <Legend
          wrapperStyle={{
            paddingTop: '20px',
          }}
        />
        <Pie
          dataKey="value"
          data={remappedValues}
          innerRadius={70}
          outerRadius={100}
        >
          {gameTypeColumns.map((gtc, idx) => (
            <Cell
              key={idx}
              fill={(gameTypes.find(({ type }) => type === gtc) as any).color}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartIndex;
