import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Input, message, Switch as AntdSwitch } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { useForm, Controller } from 'react-hook-form';
import useTranslate from 'utils/useTranslate';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import DrawerActionButtons from 'components/DrawerActionButtons/DrawerActionButtons';
import DrawerFooter from 'components/DrawerFooter';
import FormItem from 'components/FormItem';
import Drawer from '../../../common/Drawer/Drawer';
import { StyledSpacer } from '../../../MobileAppConfiguration/styles';

const Switch: any = AntdSwitch;

const CREATE_DOMAIN = gql`
  mutation createDomain($input: CreateDomainInput!) {
    createDomain(input: $input)
  }
`;

const NewDomainDrawer = ({
  closeDrawer,
  drawerStatus,
  refetch,
}: {
  refetch: () => void;
  closeDrawer: () => void;
  drawerStatus: boolean;
}) => {
  const [createDomain] = useMutation(CREATE_DOMAIN);
  const [useSSL, setSSL] = useState(false);
  const translate = useTranslate();

  const {
    control,
    getValues,
    setValue,
    errors,
    handleSubmit,
    reset,
    register,
  } = useForm();

  const handleReset = () => {
    reset({
      name: '',
      description: '',
      sslEnabled: false,
      certificate: '',
      privateKey: '',
      intermediateCertificate: '',
    });
  };

  const onSubmit = async () => {
    try {
      await createDomain({
        variables: {
          input: getValues(),
        },
      });
      refetch();
      message.success(translate(messages['domain-created-successfully.text']));
      handleReset();
      closeDrawer();
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  useEffect(() => {
    register('status');
    register('sslEnabled');
    setValue('sslEnabled', false);
    setValue('status', 'INACTIVE');
  }, [register, setValue]);

  return (
    <Drawer onClose={closeDrawer} open={drawerStatus}>
      <Drawer.Header
        title={
          <FormattedMessage id="new-domain.text" defaultMessage="New Domain" />
        }
      >
        <DrawerActionButtons />
      </Drawer.Header>

      <div>
        <Drawer.Content>
          <DrawerContainer className="container">
            <Row style={{ height: '70vh' }}>
              <Col span={3} style={{ height: '100%' }}>
                <SideLabel style={{ height: '100%' }}>
                  <StyledSpacer />

                  <div
                    style={{
                      borderRight: '2px solid #3590ff',
                      padding: '10px 20px',
                    }}
                  >
                    <SideTitle>
                      <FormattedMessage
                        id="add-domain.text"
                        defaultMessage="Add Domain"
                      />
                    </SideTitle>
                  </div>
                </SideLabel>
              </Col>

              <Col span={21}>
                <Row>
                  <Col span={10}>
                    <StyledSpacer />
                    <TitleContainer>
                      <h4 style={{ marginBottom: '0', fontWeight: 600 }}>
                        <FormattedMessage
                          id="add-domain.text"
                          defaultMessage="Add Domain"
                        />
                      </h4>
                    </TitleContainer>

                    <div className="mt-3">
                      <Form layout="horizontal">
                        <FormItem
                          label={
                            <FormattedMessage
                              id="domain-url.text"
                              defaultMessage="Domain URL"
                            />
                          }
                        >
                          <Controller
                            as={Input.Password}
                            control={control}
                            name="name"
                            defaultValue=""
                            placeholder={translate(
                              messages['example-website.text']
                            )}
                            rules={{ required: true }}
                          />

                          {errors.name && (
                            <span style={{ color: 'red' }}>
                              <FormattedMessage
                                id="required.text"
                                defaultMessage="Required"
                              />
                            </span>
                          )}
                        </FormItem>

                        <FormItem
                          label={
                            <FormattedMessage
                              id="description.text"
                              defaultMessage="Description"
                            />
                          }
                        >
                          <Controller
                            as={Input.TextArea}
                            control={control}
                            name="description"
                            defaultValue=""
                            rules={{ required: true }}
                          />

                          {errors.description && (
                            <span style={{ color: 'red' }}>
                              <FormattedMessage
                                id="required.text"
                                defaultMessage="Required"
                              />
                            </span>
                          )}
                        </FormItem>

                        <FormItem
                          label={
                            <FormattedMessage
                              id="ssl-certification.text"
                              defaultMessage="SSL Certification"
                            />
                          }
                        >
                          <Switch
                            onChange={(checked: boolean) => {
                              setValue('sslEnabled', checked);
                              setSSL(checked);
                            }}
                            value={getValues('sslEnabled') as any}
                            textHelper={{
                              true: translate(messages.ON),
                              false: translate(messages.OFF),
                            }}
                          />
                        </FormItem>

                        {useSSL && (
                          <>
                            <FormItem
                              label={
                                <FormattedMessage
                                  id="crt.text"
                                  defaultMessage="Certificate"
                                />
                              }
                              extra={
                                <FormattedMessage
                                  id="accepted-file-formats.text"
                                  values={{ formats: '.crt' }}
                                  defaultMessage="Accepted file formats: {formats}"
                                />
                              }
                            >
                              <Controller
                                as={Input.TextArea}
                                control={control}
                                name="certificate"
                                defaultValue=""
                                rules={{ required: true }}
                              />
                            </FormItem>

                            <FormItem
                              label={
                                <FormattedMessage
                                  id="private-key.text"
                                  defaultMessage="Private Key"
                                />
                              }
                            >
                              <Controller
                                as={Input.TextArea}
                                control={control}
                                name="privateKey"
                                defaultValue=""
                                rules={{ required: true }}
                              />
                            </FormItem>

                            <FormItem
                              label={
                                <FormattedMessage
                                  id="intermediary-cert-optional.text"
                                  defaultMessage="Intermediary Cert (Optional)"
                                />
                              }
                            >
                              <Controller
                                as={Input.TextArea}
                                control={control}
                                name="intermediateCertificate"
                                defaultValue=""
                                rules={{ required: true }}
                              />
                            </FormItem>
                          </>
                        )}

                        <FormItem
                          label={
                            <FormattedMessage
                              id="status.text"
                              defaultMessage="Status"
                            />
                          }
                        >
                          <Switch
                            onChange={(e: boolean) =>
                              setValue('status', e ? 'ACTIVE' : 'INACTIVE')
                            }
                            value={(e: string) => e === 'ACTIVE'}
                            textHelper={{
                              true: translate(messages.ACTIVE),
                              false: translate(messages.INACTIVE),
                            }}
                          />
                        </FormItem>
                      </Form>
                    </div>
                  </Col>

                  <Col span={14} />
                </Row>
              </Col>
            </Row>
          </DrawerContainer>
        </Drawer.Content>

        <DrawerFooter>
          <>
            <Button
              onClick={() => {
                closeDrawer();
                handleReset();
              }}
            >
              <FormattedMessage id="back.text" defaultMessage="Back" />
            </Button>

            <div>
              <Button onClick={handleSubmit(onSubmit)} type="primary">
                <FormattedMessage
                  id="continue.text"
                  defaultMessage="Continue"
                />
              </Button>
            </div>
          </>
        </DrawerFooter>
      </div>
    </Drawer>
  );
};

// ===[ STYLES ]=== //
const DrawerContainer = styled.div`
  width: 90%;
`;

const SideLabel = styled.div`
  border-right: 1px solid #e0e0e0;
  text-align: right;
`;

const SideTitle = styled.h4`
  color: #3590ff;
  margin-bottom: 0;
`;

const TitleContainer = styled.div`
  padding: 10px 20px 10px 140px;
`;

export default NewDomainDrawer;
