import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { connect, getIn } from 'formik';

type Rec = Record<string, any>;

const TextField = ({
  formik,
  label,
  group,
  symbol,
  type,
  name,
  placeholder,
  value,
  maxLength,
  ...attributes
}: {
  type: string;
  name: string;
  group?: boolean;
  symbol?: string;
  formik?: Rec;
  label: string;
  placeholder?: string;
  value?: any;
  maxLength: number;
}) => {
  const error = getIn(formik?.errors, name);
  const touch = getIn(formik?.touched, name);
  return (
    <Form.Item
      label={label}
      validateStatus={error && touch ? 'error' : ''}
      help={error && touch ? error : ''}
    >
      <Input
        onChange={formik?.handleChange}
        type={type}
        name={name}
        value={value}
        addonAfter={group ? symbol : ''}
        placeholder={placeholder}
        {...attributes}
        maxLength={maxLength}
      />
    </Form.Item>
  );
};

export default connect(TextField);
