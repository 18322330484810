import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import RebatesHeader from '../../../CreateRebateGroup/components/RebateGroup/components/Header';
import RebatesRightSidebar from '../../../CreateRebateGroup/components/RebateGroup/components/RightSidebar';
import RebatesContent from '../../../CreateRebateGroup/components/RebateGroup/components/RebatesContent';
import { RebateGroupProvider } from '../../../CreateRebateGroup/components/RebateGroup/context';

type Props = {
  percentages: any;
  activeLevel: number;
  levels: Array<any>;
  setActiveLevel?: (e: number) => void;
  rebateGroupPercentages: any;
};

function GroupSettings({
  percentages,
  activeLevel,
  setActiveLevel,
  levels,
  rebateGroupPercentages,
}: Props) {
  return (
    <Root>
      <RebateGroupProvider>
        <Layout>
          <Layout>
            <Layout>
              <RebatesHeader
                readOnly
                shouldTiersShow={!!levels.length}
                levels={levels}
                activeLevel={activeLevel}
                setActiveLevel={setActiveLevel}
              />
              <LayoutContent className="p-3">
                <RebatesContent readOnly percentages={percentages} />
              </LayoutContent>
            </Layout>
            <RebatesRightSidebar
              readOnly
              percentages={rebateGroupPercentages}
            />
          </Layout>
        </Layout>
      </RebateGroupProvider>
    </Root>
  );
}

const Root = styled.div`
  .ant-layout-content {
    width: 100%;
  }
`;

const LayoutContent = styled(Layout.Content)`
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
`;

export default GroupSettings;
