export const blockAlphabets = (
  e: {
    key: string;
    preventDefault: () => void;
  },
  isInteger: boolean = false
) => {
  const reg = /^\d+$/;
  const invalidChars = ['+', '-', 'e'];
  if (
    invalidChars.includes(e.key) ||
    (!reg.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Tab' &&
      e.key !== '.')
  ) {
    e.preventDefault();
  }

  if (
    isInteger &&
    (invalidChars.includes(e.key) ||
      (!reg.test(e.key) && e.key !== 'Tab' && e.key !== 'Backspace'))
  ) {
    e.preventDefault();
  }
};
