import { useMutation } from '@apollo/react-hooks';
import { Button, message, Popconfirm } from 'antd';
import { PureQueryOptions } from 'apollo-client';
import { ON_HOLD_WITHDRAWAL } from 'graphql/mutations/withdrawal.mutation';
import React from 'react';
import { useOperatorHeader } from 'utils/useOperatorHeader';

type Props = {
  id: string;
  status: string;
  refetchQueries: (string | PureQueryOptions)[];
  remarks: string;
  setRemarksError: (data?: string) => void;
  toggleModal: () => void;
};

const OnHoldWithdrawal = (props: Props) => {
  const {
    id,
    status,
    refetchQueries,
    remarks,
    setRemarksError,
    toggleModal,
  } = props;
  const { context } = useOperatorHeader();
  const [onHoldWithdrawal, { loading }] = useMutation(ON_HOLD_WITHDRAWAL, {
    onCompleted: (data) => {
      if (data.onHoldWithdrawal) {
        message.success('Withdrawal request on hold');
      }
      setRemarksError();
      toggleModal();
    },
    onError: () => {
      setRemarksError();
    },
    variables: { id, reason: remarks },
    refetchQueries,
    context,
  });

  return (
    <>
      {(status === 'PROCESSING' || status === 'EXPIRED') && (
        <Popconfirm
          title="Are you sure?"
          onConfirm={() => {
            if (remarks) onHoldWithdrawal();
            else setRemarksError('Required for holding withdrawal request');
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button
            disabled={loading}
            loading={loading}
            style={{
              background: '#9d4dff',
              color: 'white',
            }}
          >
            On Hold
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export default OnHoldWithdrawal;
