import gql from 'graphql-tag';

export const VENDORS = gql`
  query Vendors(
    $first: Int
    $after: Binary
    $filter: VendorsFilterInput
    $language: Language
  ) {
    vendors(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name(language: $language)
          shortcode
        }
      }
    }
  }
`;

export default {};
