import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
  Button,
  DatePicker,
  Input,
  message,
  Radio,
  Switch,
  TimePicker,
} from 'antd';
import { useFormik } from 'formik';
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
} from 'graphql/mutations/manualMessage.mutation';
import { get, isEmpty } from 'lodash';
import { MultiMembersSelectForm } from 'pages/components/SystemMessage/ManualMessage/components/CreateNewMail/components/MultiMembersSelect/MultiMembersSelectForm';
import messages from 'messages';
import moment, { Moment } from 'moment';
import React, { useEffect, useReducer } from 'react';
import { UseFormikErrorMessage } from 'components/UseFormikErrorMessage/UseFormikErrorMessage';
import { SharedStyledModal } from 'styles/SharedStyledModal';
import styled from 'styled-components';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import { ICreateNewMail, InitialValues } from './interface';
import {
  actions,
  formItemLayout,
  getMultMembersId,
  initialReducerState,
  messageType,
  preLoadMultMembers,
  reducer,
  validationSchema,
} from './util';

const StyledInput = styled(Input)`
  input.ant-input {
    padding-right: 125px !important;
  }
`;

const maxTitleCount = 20;
const maxContentCount = 500;

export const CreateNewMail = ({
  visible,
  onCloseFn,
  getMessages,
  existingMessage,
}: ICreateNewMail) => {
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [
    { targetMembers, excludedMembers, isSubmitting, initialValues },
    dispatch,
  ] = useReducer(reducer, initialReducerState);
  const client = useApolloClient();
  const hasExistingMessageFn = async () => {
    if (!existingMessage) {
      return;
    }

    dispatch([
      actions.SET_INITIAL_VALUES,
      {
        ...initialValues,
        mailTitle: existingMessage.title,
        mailContent: existingMessage.content,
        scheduleSend: true,
        date: moment(existingMessage.schedule) as any,
        time: moment(existingMessage.schedule) as any,
      },
    ]);

    const hasTargetMembers = !isEmpty([
      ...existingMessage.targetMembers,
      ...existingMessage.targetMemberLevels,
      ...existingMessage.targetMemberLoyaltyLevels,
    ]);

    if (hasTargetMembers) {
      dispatch([actions.SET_QUALIFYING, messageType.TARGET_MEMBERS]);
      preLoadMultMembers({ client, dispatch, existingMessage, type: 'target' });
    }

    preLoadMultMembers({ client, dispatch, existingMessage, type: 'excluded' });
  };

  useEffect(() => {
    hasExistingMessageFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingMessage]);

  const [createMessage] = useMutation(CREATE_MESSAGE, {
    context,
  });
  const [updateMessage] = useMutation(UPDATE_MESSAGE, {
    context,
  });

  const renderByExistingMessage = (
    hasExistingMessageString: string,
    noExistingMessageString: string
  ) =>
    messages[
      existingMessage ? hasExistingMessageString : noExistingMessageString
    ];

  const submitMail = async (mailValue: InitialValues) => {
    dispatch([actions.SET_IS_SUBMITTING, true]);
    const mutationFn = existingMessage ? updateMessage : createMessage;
    const dateAndTime = moment(
      new Date(
        `${moment(mailValue.date).format('YYYY-MM-DD')} ${moment(
          mailValue.time
        ).format('HH:mm')}`
      )
    ).format();

    const schedule = {
      ...(mailValue.scheduleSend && {
        schedule: dateAndTime,
      }),
    };
    const notValidDate = mailValue.scheduleSend
      ? moment(dateAndTime).isBefore(new Date())
      : false;

    if (notValidDate) {
      message.warning(
        translate(messages.SCHEDULED_DATE_INVALID_PAST_CURRENT_DATE)
      );
      dispatch([actions.SET_IS_SUBMITTING, false]);
      return;
    }

    const createMessageInput = {
      input: {
        title: mailValue.mailTitle,
        content: mailValue.mailContent,
        ...getMultMembersId(targetMembers),
        ...getMultMembersId(excludedMembers),
        ...schedule,
      },
      ...(existingMessage && { id: existingMessage.id }),
    };
    try {
      await mutationFn({
        variables: createMessageInput,
      });
      message.success(
        translate(
          renderByExistingMessage(
            'update-mail-successful.text',
            'MESSAGE_CREATED'
          )
        )
      );
      setTimeout(() => {
        getMessages();
        onCloseFn();
        dispatch([actions.SET_IS_SUBMITTING, false]);
      }, 1000);
    } catch {
      message.warning(translate(messages['error-occurred.text']));
      dispatch([actions.SET_IS_SUBMITTING, false]);
    }
  };

  function disabledDate(current: Moment | null): any {
    const dayBefore = moment(new Date()).subtract('1', 'days');
    return current && current < moment(dayBefore).endOf('day');
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: submitMail,
    validationSchema: validationSchema(
      translate,
      maxTitleCount,
      maxContentCount
    ),
  });
  const {
    handleChange,
    setFieldValue,
    values,
    handleSubmit,
    handleBlur,
  } = formik;

  return (
    <SharedStyledModal
      width={800}
      onCancel={onCloseFn}
      visible={visible}
      title={translate(
        renderByExistingMessage('edit-mail.text', 'create-new-mail.text')
      )}
      footer={[
        <Button
          type="primary"
          onClick={handleSubmit as any}
          key={1}
          loading={isSubmitting}
        >
          {translate(renderByExistingMessage('update.text', 'submit.text'))}
        </Button>,
      ]}
    >
      <div className="p-3" id="multi-members-select">
        <Form.Item
          colon={false}
          label={translate(messages['qualifying-members.text'])}
          {...formItemLayout}
        >
          <div>
            <Radio.Group
              value={values.qualifying}
              onChange={({ target }) =>
                setFieldValue('qualifying', target.value)
              }
            >
              <Radio value={messageType.WHOLE_SITE_MEMBER}>
                {translate(messages['whole-site-members.text'])}
              </Radio>

              <Radio value="empty" disabled>
                {translate(messages['import-from.text'])}
              </Radio>

              <Radio
                value={messageType.TARGET_MEMBERS}
                data-testid={messageType.TARGET_MEMBERS}
              >
                {translate(messages['target-members.text'])}
              </Radio>
            </Radio.Group>
          </div>
        </Form.Item>

        {values.qualifying === messageType.TARGET_MEMBERS && (
          <Form.Item
            colon={false}
            label={translate(messages['target-members.text'])}
            {...formItemLayout}
          >
            <MultiMembersSelectForm
              type="target"
              values={{
                members: targetMembers.targetMembers || [],
                memberLoyaltyLevels:
                  targetMembers.targetMemberLoyaltyLevels || [],
                memberLevels: targetMembers.targetMemberLevels || [],
              }}
              setFilters={(e) => {
                dispatch([actions.SET_TARGET_MEMBERS, e]);
              }}
            />
          </Form.Item>
        )}
        <Form.Item
          colon={false}
          label={translate(messages['excluded-members.text'])}
          {...formItemLayout}
        >
          <MultiMembersSelectForm
            type="excluded"
            values={{
              members: excludedMembers.excludedMembers || [],
              memberLoyaltyLevels:
                excludedMembers.excludedMemberLoyaltyLevels || [],
              memberLevels: excludedMembers.excludedMemberLevels || [],
            }}
            setFilters={(e) => {
              dispatch([actions.SET_EXCLUDED_MEMBERS, e]);
            }}
          />
        </Form.Item>

        <Form.Item
          colon={false}
          label={translate(messages['mail-title-name.text'])}
          {...formItemLayout}
        >
          <StyledInput
            onChange={handleChange}
            name="mailTitle"
            onBlur={handleBlur}
            value={values.mailTitle}
            suffix={
              <div
                className={`fs-12 ${
                  !!get(formik, 'touched.mailTitle', null) &&
                  get(formik, 'errors.mailTitle')
                    ? 'text-danger'
                    : 'text-muted-light'
                }`}
              >
                {values.mailTitle.length}/{maxTitleCount}{' '}
                {translate(messages.CHARACTERS)}
              </div>
            }
          />
          <UseFormikErrorMessage formik={formik} name="mailTitle" />
        </Form.Item>

        <Form.Item
          colon={false}
          label={translate(messages['mail-content.text'])}
          {...formItemLayout}
        >
          <div className="position-relative">
            <Input.TextArea
              onBlur={handleBlur}
              rows={4}
              onChange={handleChange}
              name="mailContent"
              value={values.mailContent}
            />
            <small
              className="position-absolute"
              style={{
                bottom: '-11px',
                right: '18px',
              }}
            >
              <span
                style={{ background: 'rgba(255,255,255,0.80)' }}
                className={`fs-12 ${
                  !!get(formik, 'touched.mailContent', null) &&
                  get(formik, 'errors.mailContent')
                    ? 'text-danger'
                    : 'text-muted-light'
                }`}
              >
                {values.mailContent.length}/{maxContentCount}{' '}
                {translate(messages.CHARACTERS)}
              </span>
            </small>
          </div>

          <UseFormikErrorMessage formik={formik} name="mailContent" />
        </Form.Item>

        <>
          <Form.Item
            colon={false}
            label={<strong>{translate(messages['other-setting.text'])}</strong>}
            {...formItemLayout}
          />

          <Form.Item
            colon={false}
            label={translate(messages['schedule-send.text'])}
            {...formItemLayout}
          >
            <Switch
              checked={values.scheduleSend}
              onChange={(val) => setFieldValue('scheduleSend', val)}
            />
          </Form.Item>

          {values.scheduleSend && (
            <>
              <Form.Item
                colon={false}
                label={translate(messages['date.text'])}
                {...formItemLayout}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  value={values.date}
                  onChange={(date) => setFieldValue('date', date)}
                />
                <UseFormikErrorMessage formik={formik} name="date" />
              </Form.Item>

              <Form.Item
                colon={false}
                label={translate(messages['time.text'])}
                {...formItemLayout}
              >
                <TimePicker
                  value={values.time}
                  use12Hours
                  format="h:mm a"
                  onChange={(time) => setFieldValue('time', time)}
                />
                <div>
                  <UseFormikErrorMessage formik={formik} name="time" />
                </div>
              </Form.Item>
            </>
          )}
        </>
      </div>
    </SharedStyledModal>
  );
};
