/* eslint-disable react/prop-types */
import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { StyledModal } from './styles';
import SearchSettingsList from './components/SearchSettingsList';

type SearchSettingsType = {
  toggleModal: (val?: any) => void;
  showModal: boolean;
  changeFilters: (val?: any) => void;
};

const SearchSettings = ({
  toggleModal,
  showModal,
  changeFilters,
}: SearchSettingsType) => {
  const handleUseFilter = (e: any) => {
    changeFilters(e);
    toggleModal();
  };

  return (
    <>
      <a href="#/section" className="ml-1" onClick={toggleModal}>
        <SettingOutlined />
      </a>
      {showModal && (
        <StyledModal
          title={
            <FormattedMessage
              id="search-settings.text"
              defaultMessage="Search Settings"
            />
          }
          visible={showModal}
          onOk={toggleModal}
          onCancel={toggleModal}
        >
          <SearchSettingsList onUseFilter={handleUseFilter} />
        </StyledModal>
      )}
    </>
  );
};

export default SearchSettings;
