import React from 'react';
import { Form as FormCombatible } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DatePicker as AntDatePicker, Input, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';

import MemberLevelsSelect from 'components/Antd/MemberLevelsSelect';
import MemberLoyaltyLevelsSelect from 'components/Antd/MemberLoyaltyLevelsSelect';
import { DATE_FORMAT } from 'constants/date';
import { formItemHorizontalLayout } from 'constants/form';
import useTranslate from 'utils/useTranslate';
import { FormikProps } from 'interfaces/formikProps.interface';
import { RebateProps } from 'interfaces/formikValues/rebates.interface';
import messages from './messages';
import { MEMBER_LOYALTY_STATUS } from './constants';

const FormWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const Form: any = FormCombatible;
const DatePicker: any = AntDatePicker;

function RebateSettings({
  formikProps,
}: {
  formikProps: FormikProps<RebateProps>;
}) {
  const { handleChange, setFieldValue, errors, values } = formikProps;
  const disabledStartDate = (startValue: string) => {
    const endValue = values.validityDateTimeRange.end;
    const newStartValue: any = moment().startOf('day');
    if (!startValue || !endValue) {
      return newStartValue.valueOf() > startValue.valueOf();
    }
    return (
      startValue.valueOf() > endValue.endOf('day').valueOf() ||
      newStartValue.valueOf() > startValue.valueOf()
    );
  };

  const disabledEndDate = (endValue: string) => {
    const startValue = values.validityDateTimeRange.start;
    const newEndValue: any = moment().startOf('day');
    if (!endValue || !startValue) {
      return newEndValue.valueOf() > endValue.valueOf();
    }
    return endValue.valueOf() < startValue.valueOf();
  };
  const translate = useTranslate();
  return (
    <>
      <FormWrapper className="mt-5">
        <Typography.Paragraph strong className="mb-4">
          <FormattedMessage
            id="rebates.general-settings.text"
            defaultMessage="General settings"
          />
        </Typography.Paragraph>
        <Form.Item
          label={translate(messages['rebates.group-name.text'])}
          {...formItemHorizontalLayout}
          validateStatus={errors.name ? 'error' : null}
          help={errors.name}
        >
          <Input
            size="large"
            name="name"
            onChange={handleChange}
            value={values.name}
          />
        </Form.Item>
        <Form.Item
          label={translate(messages['rebates.qualifying-vip.text'])}
          {...formItemHorizontalLayout}
        >
          <MemberLoyaltyLevelsSelect
            forRebates
            stat={MEMBER_LOYALTY_STATUS.ACTIVE}
            size="large"
            className="ant-select-primary ant-select-outline"
            selectClasses="ant-select-primary ant-select-outline"
            value={values.qualifyingMemberLoyaltyLevels}
            placeHolder={translate(messages['rebates.please-select.text'])}
            multiple
            variant="outlined"
            onChange={(value: any) => {
              setFieldValue('qualifyingMemberLoyaltyLevels', value);
            }}
            disabled={false}
            label={null as any}
            style={{}}
            isFilter={false}
            topRightClear={false}
            otherFilters={{}}
          />
        </Form.Item>
        <Form.Item
          label={
            <FormattedMessage
              defaultMessage="Excluded Member Marker"
              id="EXCLUDED_MEMBER_MARKER"
            />
          }
          {...formItemHorizontalLayout}
        >
          <MemberLevelsSelect
            forRebates
            size="large"
            className="ant-select-danger ant-select-outline"
            value={values.excludedMemberLevels}
            placeHolder={translate(messages['rebates.please-select.text'])}
            multiple
            variant="outlined"
            onChange={(value: any) =>
              setFieldValue('excludedMemberLevels', value)
            }
            disabled={false}
            label={null as any}
            style={{ width: '100%' }}
            isFilter={false}
            labelAsValue={false}
            topRightClear={false}
            status={['ENABLED', 'DISABLED']}
          />
        </Form.Item>
        <Form.Item
          label={translate(messages['rebates.validity.text'])}
          {...formItemHorizontalLayout}
        >
          <Form.Item
            style={{ display: 'inline-block', width: 'calc(50% - 6px)' }}
            validateStatus={
              coercedGet(errors, 'validityDateTimeRange.start', '')
                ? 'error'
                : null
            }
            help={errors?.validityDateTimeRange?.start}
          >
            <DatePicker
              block
              placeholder={translate(messages['rebates.starting-time.text'])}
              size="large"
              format={DATE_FORMAT}
              disabledDate={disabledStartDate}
              value={values.validityDateTimeRange.start}
              onChange={(value: any) =>
                setFieldValue('validityDateTimeRange.start', value)
              }
            />
          </Form.Item>
          <span
            style={{
              display: 'inline-block',
              width: '12px',
              textAlign: 'center',
            }}
          />
          <Form.Item
            style={{ display: 'inline-block', width: 'calc(50% - 6px)' }}
          >
            <DatePicker
              block
              placeholder={translate(messages['rebates.end-time.text'])}
              size="large"
              format={DATE_FORMAT}
              disabledDate={disabledEndDate}
              value={values.validityDateTimeRange.end}
              onChange={(value: any) =>
                setFieldValue('validityDateTimeRange.end', value)
              }
            />
          </Form.Item>
        </Form.Item>
      </FormWrapper>
    </>
  );
}

export default RebateSettings;
