import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Button, message, Radio } from 'antd';
import gql from 'graphql-tag';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Member } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import { StyledModal } from '../EditStatusMenu/styles';

const MEMBER = gql`
  query Member($id: ID!) {
    member(id: $id) {
      id
      forceVerification
    }
  }
`;

type Props = {
  member: Member;
};

const EditProfileValidation = ({ member }: Props) => {
  const [profile, setProfile] = useState(true);
  const [isShow, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberInfo, setMemberInfo] = useState<Record<string, any>>({});
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const client = useApolloClient();
  const translate = useTranslate();
  const handleShow = () => setShow(!isShow);

  useEffect(() => {
    if (isShow && member) {
      client
        .query({
          query: MEMBER,
          variables: {
            id: member.id,
          },
          fetchPolicy: 'network-only',
        })
        .then(({ data }) => {
          setMemberInfo(data?.member || {});
          setProfile(!data?.member?.forceVerification);
        });
    }
  }, [client, member, isShow]);

  const onSubmit = () => {
    setLoading(true);
    updateMember({
      variables: {
        id: member.id,
        input: {
          forceVerification: profile,
        },
      },
    })
      .then(() => {
        message.success(translate(messages.MEMBER_UPDATED));
        setShow(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        type="link"
        className="text-black"
        onClick={handleShow}
        block
        role="button"
      >
        <FormattedMessage
          id="EDIT_PROFILE_VALIDATION"
          defaultMessage="Edit Profile Validation"
        />
      </Button>
      <StyledModal
        title={
          <FormattedMessage
            id="EDIT_PROFILE_VALIDATION"
            defaultMessage="Edit Profile Validation"
          />
        }
        onCancel={handleShow}
        visible={isShow}
        footer={false}
      >
        <div className="p-3">
          <Form.Item
            label={
              <FormattedMessage
                id="PROFILE_VALIDATION"
                defaultMessage="Profile Validation"
              />
            }
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Radio.Group
              name="status"
              onChange={(val) => setProfile(val.target.value)}
              value={profile}
            >
              <Radio value disabled={memberInfo?.forceVerification}>
                <FormattedMessage id="ON" defaultMessage="On" />
              </Radio>

              <Radio value={false} disabled={!memberInfo?.forceVerification}>
                <FormattedMessage id="OFF" defaultMessage="Off" />
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="bt-1 p-3 text-right">
          <Button loading={loading} className="mr-2" onClick={handleShow}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            role="button"
            onClick={onSubmit}
          >
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </div>
      </StyledModal>
    </>
  );
};

export default EditProfileValidation;
