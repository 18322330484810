import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { isEmpty } from 'lodash';
import AmountFilter from 'components/AmountFilter';
import PaymentMethodsSelect from 'components/PaymentMethodsSelect';
import DepositProvidersSelect from 'components/DepositProvidersSelect';
import BanksSelect from 'components/BanksSelect';
import globalMessages from 'messages';
import messages from '../../messages';
import PaymentMethodTypeFilter from '../Sidebar/components/PaymentMethodTypeFilter';
import StatusFilter from '../Sidebar/components/StatusFilter';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
};

export default ({ filters, onRawFilterChange: handleChange }: Props) => {
  const translate = useTranslate();

  return (
    <>
      <FilterItem
        label={translate(messages.method)}
        onClear={() =>
          handleChange({
            name: 'name',
            value: null,
          })
        }
      >
        <PaymentMethodsSelect
          value={filters.name ? filters.name.in : []}
          placeHolder={translate(messages.enter, {
            message: translate(messages.entername),
          })}
          multiple
          labelAsValue
          onChange={(e) =>
            handleChange({
              name: 'name',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>

      <PaymentMethodTypeFilter
        value={
          filters.paymentMethodType ? filters.paymentMethodType.in || [] : []
        }
        onChange={(e) =>
          handleChange({
            name: 'paymentMethodType',
            value: e.length ? { in: e } : null,
          })
        }
      />

      <FilterItem
        label={translate(messages.provider)}
        onClear={() =>
          handleChange({
            name: 'depositProvider',
            value: null,
          })
        }
      >
        <DepositProvidersSelect
          value={filters.depositProvider ? filters.depositProvider.in : []}
          placeHolder={translate(messages.enter, {
            message: `${translate(messages.provider)} ${' '} ${translate(
              messages.entername
            )}`,
          })}
          multiple
          onChange={(e) =>
            handleChange({
              name: 'depositProvider',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>

      <FilterItem
        label={translate(messages.bank)}
        onClear={() =>
          handleChange({
            name: 'bank',
            value: null,
          })
        }
      >
        <BanksSelect
          value={filters.bank ? filters.bank.in || [] : []}
          placeHolder={translate(messages.enter, {
            message: translate(messages.bankname),
          })}
          onChange={(e) =>
            handleChange({
              name: 'bank',
              value: e.length ? { in: e } : null,
            })
          }
          multiple
        />
      </FilterItem>

      <FilterItem
        label={translate(messages.currentAccumulation)}
        onClear={() =>
          handleChange({
            name: 'currentAccumulation',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: coercedGet(filters, 'currentAccumulation.gte', ''),
            max: coercedGet(filters, 'currentAccumulation.lte', ''),
          }}
          onChange={(e) => {
            const amount = { ...filters.currentAccumulation };
            if (!e.value) delete amount[e.range];
            else amount[e.range] = e.value;

            handleChange({
              name: 'currentAccumulation',
              value: isEmpty(amount) ? null : amount,
            });
          }}
        />
      </FilterItem>

      <FilterItem
        label={translate(messages.depositLimit)}
        onClear={() =>
          handleChange({
            name: 'maximumDepositAmount',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: coercedGet(filters, 'maximumDepositAmount.gte', ''),
            max: coercedGet(filters, 'maximumDepositAmount.lte', ''),
          }}
          onChange={(e) => {
            const amount = { ...filters.maximumDepositAmount };
            if (!e.value) delete amount[e.range];
            else amount[e.range] = e.value;

            handleChange({
              name: 'maximumDepositAmount',
              value: isEmpty(amount) ? null : amount,
            });
          }}
        />
      </FilterItem>

      <FilterItem label={translate(globalMessages.status)}>
        <StatusFilter
          value={filters.enabled ? filters.enabled.in || [] : []}
          onChange={(e) =>
            handleChange({
              name: 'enabled',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>
    </>
  );
};
