/* eslint-disable react/prop-types */
import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { StyledModal } from './styles';
import SearchSettingsList from './components/SearchSettingsList';
import { FilterStateType } from '../../../../types';

type Props = {
  tabContext: string;
};

const SearchSettings: React.FC<Props> = ({ tabContext }) => {
  const { setFilters } = useFilterValues() as any;

  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const handleUseFilter = (filts: FilterStateType) => {
    setFilters(filts);
    toggleModal();
  };

  return (
    <>
      <a href="#/section" className="ml-1" onClick={toggleModal}>
        <SettingOutlined />
      </a>
      {showModal && (
        <StyledModal
          title={
            <FormattedMessage
              id="search-settings.text"
              defaultMessage="Search Settings"
            />
          }
          visible={showModal}
          onOk={toggleModal}
          onCancel={toggleModal}
        >
          <SearchSettingsList
            tabContext={tabContext}
            onUseFilter={handleUseFilter}
          />
        </StyledModal>
      )}
    </>
  );
};

export default SearchSettings;
