import gql from 'graphql-tag';

export const GET_LOCATIONS = gql`
  query Places(
    $first: Int
    $after: Binary
    $filter: PlacesFilterInput!
    $language: Language
  ) {
    places(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        __typename
      }
      edges {
        cursor
        node {
          displayName(language: $language)
          id
          type
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
