import gql from 'graphql-tag';

export const ME = gql`
  {
    me {
      currency
      id
    }
  }
`;

/**
 * totalOnlineMembersCount is temporary solutions for the
 * Total Online Members Count, we'll redo this once API is updated.
 *
 * Please also check the other file which is also have this temporary solution
 * src/pages/components/TodaysInstantFocus/queries.js
 *  */
export const SIDEBAR_INFORMATION_NEXT = gql`
  query totalOnlineMembersCount {
    totalOnlineMembersCount: members(filter: { online: { eq: true } }) {
      totalCount
    }
  }
`;

export const TOTAL_VALUES = gql`
  query Query($startDateTime: DateTime!, $endDateTime: DateTime!) {
    totalBetAmount: totalEffectiveBet(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalDepositAmount: totalDepositAmount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalWithdrawalAmount: totalWithdrawalAmount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalWinlossAmount: totalWinloss(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalNetProfitAmount: totalNetProfit(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalDepositsCount: totalDepositsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalDepositorsCount: totalDepositorsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalFirstDepositorsCount: totalFirstDepositorsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalMemberRegistrationsCount: totalMemberRegistrationsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )
  }
`;

export default {};
