import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import countryList from 'country-list';
import messages from 'pages/components/WithdrawalMethods/messages';
import { getBank, messages as bankMessages } from 'constants/banks';
import useTranslate from 'utils/useTranslate';

type Rec = Record<string, any>;
type Props = {
  withdrawalMethod: Rec;
  formItemLayout: Rec;
};

const BankDetails = ({ withdrawalMethod, formItemLayout }: Props) => {
  const {
    bank,
    accountNumber,
    accNumber,
    accountName,
    branch,
    country,
    province,
    city,
  } = withdrawalMethod;
  const newBank = getBank(bank);
  const translate = useTranslate();

  return newBank ? (
    <>
      <Form.Item
        label={`${translate(messages.bankname)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <div className="d-flex align-items-center">
          <img
            height={20}
            width={20}
            src={newBank.logo}
            alt="logo"
            className="mr-1"
          />
          <div>
            {newBank.label !== 'OTHER' ? `[${newBank.label}] ` : ''}
            {translate(bankMessages[newBank.label])}
          </div>
        </div>
      </Form.Item>
      <Form.Item
        label={`${translate(messages.bankacnumber)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{accountNumber || accNumber}</span>
      </Form.Item>
      <Form.Item
        label={`${translate(messages.bankacname)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{accountName}</span>
      </Form.Item>
      <Form.Item
        label={`${translate(messages.bankacbranch)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{branch}</span>
      </Form.Item>
      <Form.Item
        label={`${translate(messages.bankaccountry)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{countryList.getName(country || '')}</span>
      </Form.Item>
      <Form.Item
        label={`${translate(messages.bankacprovince)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{province}</span>
      </Form.Item>
      <Form.Item
        label={`${translate(messages.bankaccity)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{city}</span>
      </Form.Item>
    </>
  ) : null;
};

export default BankDetails;
