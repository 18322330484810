import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import ModifiedTag from '../ModifiedTag';
import { GAME_NAME } from './queries';

const GameCategoryTag = (props: { id: string; onClose: (e: any) => void }) => {
  const { id, onClose } = props;
  const { data, loading } = useQuery(GAME_NAME, {
    variables: {
      id,
    },
  });

  if (loading) {
    return <div>.</div>;
  }
  return (
    <ModifiedTag {...props} closable onClose={(e: any) => onClose(e)}>
      {data?.game?.name}
    </ModifiedTag>
  );
};

export default GameCategoryTag;
