import React, { useEffect } from 'react';
import { Radio } from 'antd';
import WithdrawalMethodsSelect from 'components/WithdrawalMethodsSelect';
import { defaultPaymentSource } from 'pages/components/MemberWithdrawals/utils';
import useTranslate from 'utils/useTranslate';
import globalMessages from 'messages';
import messages from '../../../../messages';

type Props = {
  setPaymentSourceType: (data: string) => void;
  setWithdrawalMethodId: (data: string) => void;
  paymentSourceType: string;
  withdrawalMethodId: string;
  status: string;
  setStateValues: (data: any) => void;
  readOnly: boolean;
};

const PaymentSources = (props: Props) => {
  const {
    setPaymentSourceType,
    setWithdrawalMethodId,
    paymentSourceType = 'INTERNAL',
    withdrawalMethodId,
    status,
    setStateValues,
    readOnly,
  } = props;

  useEffect(() => {
    if (status === 'PROCESSING') {
      setStateValues(defaultPaymentSource.getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const translate = useTranslate();

  return (
    <>
      <div className="menu-header py-2 px-3">
        {translate(globalMessages.WITHDRAWAL_SOURCE)}
      </div>
      <div className="d-flex py-2 px-3">
        <Radio.Group
          onChange={(e) => {
            setPaymentSourceType(e.target.value);
          }}
          value={paymentSourceType}
          className="d-flex"
          disabled={readOnly}
        >
          <Radio value="INTERNAL">{translate(messages.internal)}</Radio>
          <Radio value="EXTERNAL">{translate(messages.thirdParty)}</Radio>
        </Radio.Group>
      </div>
      <div className="mt-2">
        <WithdrawalMethodsSelect
          showPaymentMethod
          value={withdrawalMethodId}
          placeHolder={translate(messages.withdrawalMethodplaceholder)}
          onChange={(e) => setWithdrawalMethodId(e)}
          type={paymentSourceType}
          disabled={readOnly}
          queryFilter={{
            enabled: {
              in: [true],
            },
          }}
        />
      </div>
    </>
  );
};

export default PaymentSources;
