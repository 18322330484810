import * as React from 'react';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { buildMessageType } from '../../CreateNewMail/util';

const MEMBER = gql`
  query Member($id: ID!) {
    member(id: $id) {
      id
      username
    }
  }
`;

export const MemberTags = ({ id, onClose, type }: IMemberTags) => {
  const { data, loading } = useQuery(MEMBER, {
    variables: {
      id,
    },
  });

  if (loading)
    return (
      <Tag>
        <LoadingOutlined />
      </Tag>
    );

  if (!loading) {
    const { member } = data;

    return (
      <Tag closable onClose={() => onClose(member)}>
        <strong>
          {type === buildMessageType.target && (
            <FormattedMessage
              id="target-members.text"
              defaultMessage="Target Members"
            />
          )}
          {type === buildMessageType.excluded && (
            <FormattedMessage
              id="excluded-members.text"
              defaultMessage="Excluded Members"
            />
          )}
        </strong>
        :{member.username}
      </Tag>
    );
  }

  return <></>;
};

interface IMemberTags {
  id: string;
  type?: 'excluded' | 'target' | string;
  onClose: any;
}
