import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Spin } from 'antd';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { DynamicObj } from 'interfaces/user.interface';
import { Member } from 'types/graphqlTypes';
import { TAGS } from './query';
import MemberTags from './components/MemberTags';

type Props = {
  member: DynamicObj;
  refetchParams: DynamicObj;
};

const LabelTags: React.FC<Props> = ({ member, refetchParams }): any => {
  const { loading, error, data } = useQuery(TAGS, {
    fetchPolicy: 'network-only',
    variables: {
      id: member.id,
    },
    context: { shouldBatch: true },
  });

  if (loading) return <Spin />;

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  const memberWithTag = {
    ...member,
    tags: data?.member?.tags ? [...data.member.tags] : [],
  };

  return memberWithTag.tags.length ? (
    <MemberTags
      member={memberWithTag as Member}
      refetchParams={refetchParams}
    />
  ) : (
    '-'
  );
};

export default LabelTags;
