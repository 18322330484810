import React from 'react';
import { LayoutOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useScreenTabV2 } from 'store/screenTabState';

type Props = {
  toggleSidebarVisibility?: () => void;
};

export const ToggleSidebarHead = (props: Props) => {
  const { toggleSidebarVisibility } = props;
  const { getActiveTab, updateTab } = useScreenTabV2();
  const activeTab = getActiveTab();
  const toggleSider = () => {
    updateTab({
      ...activeTab,
      updates: {
        sidebarShown: !activeTab.state.sidebarShown,
      },
    });
  };
  const useFromTabState = true;

  return (
    <>
      <Button
        data-testid="sidebarControl"
        onClick={useFromTabState ? toggleSider : toggleSidebarVisibility}
        size="small"
        className="mr-2"
      >
        {!activeTab.state.sidebarShown ? (
          <CloseSquareOutlined />
        ) : (
          <LayoutOutlined />
        )}
      </Button>
    </>
  );
};
