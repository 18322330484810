import React from 'react';

import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useConfig } from 'hooks/useConfig';

interface IProps {
  deposit: {
    depositorBlockchainAddress: string;
    transactionReference: string;
    cryptoExchangeRate: number;
    amount: number;
    currency: string;
    isCrypto: boolean;
    paymentMethod: {
      name: string;
    };
  };
  readOnly: boolean;
}
const PaymentMethodDetails = (props: IProps) => {
  const { deposit } = props;
  const { currency: configCurrency } = useConfig();

  const {
    paymentMethod,
    depositorBlockchainAddress,
    transactionReference,
    cryptoExchangeRate,
    amount,
    currency,
    isCrypto,
  } = deposit || {};

  const { name } = paymentMethod || {};

  const { Text } = Typography;

  return (
    <div data-testid="crypto-payment-details">
      <div className="menu-header p-2">
        <FormattedMessage
          id="payment-info.text"
          defaultMessage="Payment info"
        />
      </div>
      <div className="d-flex p-2 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="payment-method.text"
              defaultMessage="Payment Method"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">{name}</div>
      </div>
      <div className="d-flex pl-2 pr-2 pt-2 flex-column">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="deposit-destination-address.text"
              defaultMessage="Deposit Destination Address"
            />
          </Text>
        </div>
        <div>{depositorBlockchainAddress || '-'}</div>
      </div>
      <div className="d-flex pl-2 pr-2 pt-2 flex-column">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="transaction-reference-id.text"
              defaultMessage="Transaction Reference ID"
            />
          </Text>
        </div>
        <div>{transactionReference || '-'}</div>
      </div>
      <div className="p-2 bb-1 d-flex">
        <div className="mb-2">
          <Text type="secondary">Exchange Rate</Text>
        </div>
        <div className="flex-1 text-right">{cryptoExchangeRate || '-'}</div>
      </div>
      <div className="p-2 bb-1 d-flex">
        <div className="mb-2">
          <Text type="secondary">
            <FormattedMessage
              id="deposit-amount.text"
              defaultMessage="Deposit Amount"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {isCrypto
            ? `${currency} ${amount.toFixed(8)}`
            : `${configCurrency?.symbol} ${amount}`}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodDetails;
