import { useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import {
  APPROVE_DEPOSIT,
  START_PROCESS_DEPOSIT,
} from 'graphql/mutations/deposit.mutation';
import { DEPOSIT, DEPOSIT_REQUESTS } from 'graphql/queries/deposit.query';
import messages from 'pages/components/ExternalMemberDeposits/messages';
import React from 'react';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';

const ApproveDeposit = ({
  id,
  status,
  refetchVariables,
  disabled,
}: {
  id: string;
  status: string;
  refetchVariables: Record<string, any>;
  disabled: boolean;
}) => {
  const refetchQueries = [
    {
      query: DEPOSIT,
      variables: { id },
    },
    {
      query: DEPOSIT_REQUESTS,
      variables: refetchVariables,
    },
  ];
  const translate = useTranslate();
  const [approveDeposit, { loading }] = useMutation(APPROVE_DEPOSIT, {
    onCompleted: (data) => {
      if (data.approveDeposit) {
        message.success(translate(messages.approveDepositProcess));
      }
    },
    variables: { id },
    refetchQueries,
  });

  const [startProcessDeposit, startProcessResult] = useMutation(
    START_PROCESS_DEPOSIT,
    {
      onCompleted: (data) => {
        if (!data.startProcessDeposit) return;
        approveDeposit();
      },
      variables: { id },
    }
  );

  const onHandleApprove = () => {
    if (status === 'PROCESSING') {
      approveDeposit();
    } else {
      startProcessDeposit();
    }
  };

  return (
    <ALink
      onClick={onHandleApprove}
      disabled={(disabled || loading || startProcessResult.loading) as any}
    >
      {loading || startProcessResult.loading ? <Spin /> : null}
      {translate(messages.approve)}
    </ALink>
  );
};

export default ApproveDeposit;
