import gql from 'graphql-tag';

export const REBATE_GROUP = gql`
  query RebateGroup($id: ID!) {
    rebateGroup(id: $id) {
      id
      serialCode
      creator {
        id
        username
      }
      status
      enabled
      name
      assignedMembers {
        id
        username
      }
      qualifyingMemberLoyaltyLevels {
        id
        name
        color
      }
      excludedMemberLevels {
        id
        name
        color
      }
      validityDateTimeRange {
        start
        end
      }
      automaticPayout
      turnoverRequirementMultiplier
      claimOffsetDuration
      claimExpiryDuration
      payoutExpiryDuration
      minimumPayout
      maximumPayout
      type
      dateTimeCreated
      dateTimeUpdated
      dateTimeSubmitted
      qualifiedMembersCount
      ... on LiveRebateGroup {
        percentages {
          vendor {
            id
            name
            nameEn: name(language: EN)
            nameZh: name(language: ZH)
          }
          gameType
          percentage
        }
      }
      ... on PeriodicRebateGroup {
        payoutSettlementPeriod
        payoutSettlementTime
        payoutSettlementDayOfWeek
        payoutSettlementDayOfMonth
        payoutSettlementMonth
        levels {
          minimum
          percentages {
            vendor {
              id
              name
              nameEn: name(language: EN)
              nameZh: name(language: ZH)
            }
            gameType
            percentage
            games {
              game {
                id
              }
              percentage
            }
          }
        }
      }
    }
  }
`;

export default {};
