import * as React from 'react';
import { Button, Card, Col, Row, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { ModalWhitelistIp } from './components/ModalWhitelistIp/ModalWhitelistIp';

export const SuperAdminWhitelist = () => {
  const [editModalState, setEditModalState] = useState<boolean>(false);
  const { formatMessage } = useIntl();
  const mockIps: string[] = [
    '1.1.1.1',
    '2.2.2.2',
    '3.3.3.3',
    '4.4.4.4',
    '5.5.5.5',
  ];

  return (
    <div style={{ height: 'calc(100vh - 40px - 40px - 45px)' }}>
      <Row className="py-3">
        <Col span={6} />
        <Col span={12}>
          <div className="d-flex justify-content-space-between align-items-center">
            <div>
              <h2>
                <FormattedMessage
                  id="super-admin.text"
                  defaultMessage="Super Admin"
                />
              </h2>
              <p className="mb-0">
                <FormattedMessage
                  id="whitelist.text"
                  defaultMessage="Whitelist"
                />
              </p>
            </div>
            <Button type="primary" onClick={() => setEditModalState(true)}>
              <FormattedMessage id="edit.text" defaultMessage="Edit" />
            </Button>
          </div>

          <Card
            headStyle={{ background: '#fafafa' }}
            className="mt-3"
            title={
              <strong>
                {formatMessage({
                  id: 'targeting-ip.text',
                  defaultMessage: 'Targeting IP',
                })}
              </strong>
            }
            style={{ borderRadius: '10px' }}
          >
            {mockIps.map(ip => (
              <Tag className="mb-2">{ip}</Tag>
            ))}
          </Card>
        </Col>
        <Col span={6} />
      </Row>

      <ModalWhitelistIp
        visible={editModalState}
        onCloseFn={() => setEditModalState(false)}
        existingIpAddresses={mockIps}
      />
    </div>
  );
};
