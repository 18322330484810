import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'dashboard.total-bet.text': {
    id: 'dashboard.total-bet.text',
    defaultMessage: 'Total bet amount',
  },
  'dashboard.total-deposits.text': {
    id: 'dashboard.total-deposits.text',
    defaultMessage: 'Total deposit amount',
  },
  'dashboard.total-withdrawal.text': {
    id: 'dashboard.total-withdrawal.text',
    defaultMessage: 'Total withdrawal',
  },
  'dashboard.total-win-loss.text': {
    id: 'dashboard.total-win-loss.text',
    defaultMessage: 'Total win/loss',
  },
  'dashboard.total-net-profit.text': {
    id: 'dashboard.total-net-profit.text',
    defaultMessage: 'Total net profit',
  },
  'dashboard.day-to-day.text': {
    id: 'dashboard.day-to-day.text',
    defaultMessage: 'Day to Day',
  },
  'dashboard.week-to-week.text': {
    id: 'dashboard.week-to-week.text',
    defaultMessage: 'Week to Week',
  },
  'dashboard.month-to-month.text': {
    id: 'dashboard.month-to-month.text',
    defaultMessage: 'Month to Month',
  },
  'dashboard.year-to-year.text': {
    id: 'dashboard.year-to-year.text',
    defaultMessage: 'Year to Year',
  },
  'dashboard.period-to-period.text': {
    id: 'dashboard.period-to-period.text',
    defaultMessage: 'Period to Period',
  },
});

export default messages;
