import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { statusOptions } from './utils';
import { StyledLabel, StyledSpan, StyledSelect } from '../styles';

const { Option } = Select;

type StatusType = {
  setState: (value: any) => void;
  state: { [key: string]: any };
};

const Status = ({ state, setState }: StatusType) => {
  const handleChange = (e: any) => {
    if (e) {
      setState((prev: Record<string, any>) => ({
        ...prev,
        status: { eq: e },
      }));
    } else {
      setState((prev: Record<string, any>) => ({
        ...prev,
        status: null,
      }));
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.status.text"
              defaultMessage="Status"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        allowClear
        placeholder={
          <FormattedMessage id="reports.select.text" defaultMessage="Select" />
        }
        style={{ width: '100%' }}
        // eslint-disable-next-line react/prop-types
        value={(state.status && state.status.eq) || null}
        optionFilterProp="label"
        onChange={handleChange}
      >
        {statusOptions.map((option) => (
          <Option key={option.label} value={option.value}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};

export default Status;
