import React, { useState } from 'react';
import { message } from 'antd';

import isEmpty from 'lodash/isEmpty';
import { useMutation } from '@apollo/react-hooks';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import removeNull from 'utils/removeNull';
import { CustomFilter } from 'types/graphqlTypes';

import SearchSettingsForm from '../SearchSettingsForm';
import { EDIT_CUSTOM_FILTER } from './mutations';
import { CUSTOM_FILTERS } from '../SearchSettingsList/queries';

type CustomFilterType = CustomFilter & { isShow: boolean };

type Props = {
  searchSettings: CustomFilterType;
  onClose: (value: string) => void;
  tabContext: string;
};

type FilterStateType = { [key: string]: any };

const EditSearchSettings: React.FC<Props> = ({
  searchSettings,
  onClose,
  tabContext,
}) => {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const [updateCustomFilter] = useMutation(EDIT_CUSTOM_FILTER);
  const handleSubmit = async (fields: FilterStateType) => {
    try {
      setLoading(true);
      if (!isEmpty(removeNull(fields))) {
        await updateCustomFilter({
          variables: {
            id: searchSettings.id,
            input: {
              name: searchSettings.name,
              filter: removeNull(fields),
            },
          },
          refetchQueries: [
            {
              query: CUSTOM_FILTERS,
              variables: {
                filter: {
                  context: {
                    eq: tabContext,
                  },
                },
              },
            },
          ],
        });
        message.success(translate(messages['search-settings-updated.text']));
        setLoading(false);
      } else {
        message.error(
          translate(messages['cannot-update-filter-empty-error.text'])
        );
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <SearchSettingsForm
      initialValues={searchSettings.filter}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default EditSearchSettings;
