import React from 'react';
import { Button, Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import { toFinite } from 'lodash';
import * as Yup from 'yup';
import {
  PROMO_PAYOUT,
  MANUAL_WITHDRAWAL,
  turnoverShowTypes,
} from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/utils';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { CustomRegex } from 'utils/regex';
import { StyledForm } from './styles';
import { EditBalanceFields } from './components/EditBalanceFields';
import CloseLoopModal from './components/CloseLoopModal/CloseLoopModal';
import { ClosedLoop } from '../ChangeBalance/EditBalance';

const PopupConfirm: any = Popconfirm;

const validationSchema = (translate: any) =>
  Yup.object().shape({
    type: Yup.string().required(translate(messages.TYPE_REQUIRED)),

    amount: Yup.string()
      .required(translate(messages.AMOUNT_REQUIRED))
      .matches(
        /^[^a-zA-Z]+$/,
        translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
      )
      .test(
        'amount',
        translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 }),
        (value) => {
          const valueTransform = toFinite(value);
          return valueTransform > 0;
        }
      )
      .matches(
        CustomRegex.fourDecimals(),
        translate(messages['maximum-count-decimal-places.text'], { count: 4 })
      ),
    accountPassword: Yup.string()
      .required(translate(messages.ACCOUNT_PASSWORD_REQUIRED))
      .matches(
        CustomRegex.password(),
        translate(messages.PASSWORD_VALIDATION_FIELD)
      ),
    promo: Yup.string().when('type', {
      is: (val) => val === PROMO_PAYOUT,
      then: Yup.string()
        .nullable()
        .required(translate(messages.REQUIRED)),
    }),

    hexoPayCreditCard: Yup.string()
      .nullable()
      .when('method', {
        is: (val: string) => val === 'CARD',
        then: Yup.string().required('Please select a credit card'),
      }),

    turnover: Yup.string().when('type', {
      is: (val) => turnoverShowTypes.includes(val),
      then: Yup.string()
        .matches(
          /^[^a-zA-Z]+$/,
          translate(messages.TURNOVER_INVALID_KEYED_NUMERICS)
        )
        .test(
          'amount',
          translate(messages.TURNOVER_MIN, { count: 0 }),
          (value) => {
            const valueTransform = toFinite(value);
            return valueTransform >= 0;
          }
        )
        .matches(
          CustomRegex.twoDecimals(),
          translate(messages['maximum-count-decimal-places.text'], { count: 2 })
        )
        .required(translate(messages.TURNOVER_REQUIRED)),
    }),
    withdrawalMethod: Yup.string()
      .nullable()
      .when('type', {
        is: (val) => val === MANUAL_WITHDRAWAL,
        then: Yup.string().required(translate(messages.REQUIRED)),
      }),
    email: Yup.string()
      .nullable()
      .when('type', {
        is: (val) => val === MANUAL_WITHDRAWAL,
        then: Yup.string()
          .email('Must be a valid email address')
          .required(translate(messages.REQUIRED)),
      }),
  });

type Props = {
  initialValues: Record<string, any>;
  onSubmit: () => void;
  onClose: () => void;
  isSubmitting: boolean;
  showImportExcel: boolean;
  type: 'single' | 'bulk';
  memberNotAffiliate: boolean;
  memberId: string;
  closedLoopState: ClosedLoop;
};

const BalanceForm = ({
  initialValues,
  onSubmit,
  onClose,
  isSubmitting,
  showImportExcel,
  type,
  memberNotAffiliate,
  memberId,
  closedLoopState,
}: Props) => {
  const translate = useTranslate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: validationSchema(translate),
  });

  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    errors,
    touched,
    values,
  } = formik;

  return (
    <StyledForm onSubmit={handleSubmit} role="form">
      <EditBalanceFields
        memberId={memberId}
        values={values}
        errors={errors}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        touched={touched}
        showImportExcel={showImportExcel}
        type={type}
        memberNotAffiliate={memberNotAffiliate}
        showImportText={false}
      />
      <CloseLoopModal
        closedLoopState={closedLoopState}
        setFieldValue={setFieldValue}
        onSubmit={handleSubmit}
        loading={isSubmitting}
        values={values}
      />
      <div className="bt-1 p-2 d-flex justify-content-flex-end">
        <Button
          data-testid="edit-balance-cancel-button"
          className="mr-2"
          onClick={onClose}
          loading={isSubmitting}
        >
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>

        <div data-testid="popconfirm">
          <PopupConfirm
            aria-label="confirm-question"
            title={translate(
              messages['bulk-edit-balance-confirm-question.text']
            )}
            okButtonProps={{ 'data-testid': 'edit-balance-yes-button' }}
            cancelButtonProps={{ 'data-testid': 'edit-balance-no-button' }}
            okText={translate(messages.YES)}
            cancelText={translate(messages.NO)}
            onConfirm={handleSubmit as any}
          >
            <Button
              data-testid="edit-balance-save-button"
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              role="button"
            >
              <FormattedMessage
                id="save-changes.text"
                defaultMessage="Save Changes"
              />
            </Button>
          </PopupConfirm>
        </div>
      </div>
    </StyledForm>
  );
};

export default BalanceForm;
