import { defineMessages } from 'react-intl';
import { MessagesProps } from 'translations/messagesProps';
import paymentGateways from './paymentGateways';
import gameCategories from './gameCategories';
import promoCustomFields from './promoCustomFields';

const commonMessages: any = defineMessages({
  'app.new-update': {
    defaultMessage: 'An update is available.',
    id: 'app.new-update',
  },
  'qqid-must-be-a-number-error.text': {
    id: 'qqid-must-be-a-number-error.text',
    defaultMessage: 'QQ Id must be a number and between 5 to 14 digits',
  },
  'app.update': {
    id: 'app.update',
    defaultMessage: 'Update',
  },
  'enter-vip-level.text': {
    id: 'enter-vip-level.text',
    defaultMessage: 'Enter VIP Level',
  },
  ARE_YOU_SURE: {
    id: 'ARE_YOU_SURE',
    defaultMessage: 'Are you sure?',
  },
  VIP_PROGRAMME_TIER_NAME: {
    id: 'VIP_PROGRAMME_TIER_NAME',
    defaultMessage: 'VIP Programme & Tier Name',
  },
  AFFILIATE_PROGRAMME_NAME: {
    id: 'AFFILIATE_PROGRAMME_NAME',
    defaultMessage: 'Affiliate Programme Name',
  },
  'agent-affiliate-request.text': {
    id: 'agent-affiliate-request.text',
    defaultMessage: 'Affiliate Request',
  },
  MEMBER_IS_NOT_AN_AFFILIATE: {
    id: 'MEMBER_IS_NOT_AN_AFFILIATE',
    defaultMessage: 'Member is not an affiliate',
  },
  IMPORT_MEMBERS: {
    id: 'IMPORT_MEMBERS',
    defaultMessage: 'Import Members',
  },
  IMPORT_MEMBERS_RESULT_REPORT: {
    id: 'IMPORT_MEMBERS_RESULT_REPORT',
    defaultMessage: 'Import Members Report',
  },
  X_BLANK_USERNAMES_DETECTED: {
    id: 'X_BLANK_USERNAMES_DETECTED',
    defaultMessage: '{count} Blank usernames detected',
  },
  BLANK_USERNAME_DETECTED: {
    id: 'BLANK_USERNAME_DETECTED',
    defaultMessage: 'Blank username detected',
  },
  MEMBER_MARKER: {
    id: 'member-marker.text',
    defaultMessage: 'Member Marker',
  },
  LAST_LOGIN_DATE_TIME: {
    id: 'LAST_LOGIN_DATE_TIME',
    defaultMessage: 'Last login Date/Time',
  },
  EMAIL_REUSABILITY_COUNT_EXCEEDED: {
    id: 'EMAIL_REUSABILITY_COUNT_EXCEEDED',
    defaultMessage: "Email's reusability threshold is already exceeded.",
  },
  MOBILE_NUMBER_REUSABILITY_COUNT_EXCEEDED: {
    id: 'MOBILE_NUMBER_REUSABILITY_COUNT_EXCEEDED',
    defaultMessage: 'Mobile Number reusability threshold is already exceeded.',
  },
  'enter-member-marker.text': {
    id: 'enter-member-marker.text',
    defaultMessage: 'Enter Member Marker',
  },
  'payment-method.text': {
    id: 'payment-method.text',
    defaultMessage: 'Payment Method',
  },
  ACCOUNT_DUPLICATE: {
    id: 'ACCOUNT_DUPLICATE',
    defaultMessage:
      'An account with the same firstName, lastName, and dateOfBirth already exists',
  },
  amount: {
    id: 'amount.text',
    defaultMessage: 'Amount',
  },
  status: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  'rebates.number-of-members.text': {
    id: 'rebates.number-of-members.text',
    defaultMessage: 'No. of Members',
  },
  'permission-groups.text': {
    id: 'permission-groups.text',
    defaultMessage: 'Permission Groups',
  },
  'last-login-date.text': {
    id: 'last-login-date.text',
    defaultMessage: 'Last login date',
  },
  'registration-date.text': {
    id: 'registration-date.text',
    defaultMessage: 'Registration Date',
  },
  operator: {
    id: 'operator.text',
    defaultMessage: 'Operator',
  },
  dateTimeProcessed: {
    id: 'date-time-processed.text',
    defaultMessage: 'Date / Time Processed',
  },
  'date-time-created.text': {
    id: 'date-time-created.text',
    defaultMessage: 'Date / Time Created',
  },
  'processing-time.text': {
    id: 'processing-time.text',
    defaultMessage: 'Processing Time',
  },
  'label-remarks.text': {
    id: 'label-remarks.text',
    defaultMessage: 'Labels/Remarks',
  },
  LABELS_REMARKS: {
    id: 'LABELS_REMARKS',
    defaultMessage: 'Labels/Remarks',
  },
  CLEAR: {
    defaultMessage: 'Clear',
    id: 'CLEAR',
  },
  ENTER_REGISTRATION_IP_ADDRESS: {
    defaultMessage: 'Enter Registration IP Address',
    id: 'ENTER_REGISTRATION_IP_ADDRESS',
  },
  'number.matches.error': {
    defaultMessage: 'must be a number',
    id: 'number.matches.error',
  },
  required: {
    defaultMessage: 'Required',
    id: 'required.text',
  },
  REQUIRED: {
    id: 'REQUIRED',
    defaultMessage: 'Required',
  },
  'string.matches.regex.error': {
    defaultMessage: 'Invalid input. Format should be: {format}',
    id: 'string.matches.regex.error',
  },
  stringmatches: {
    defaultMessage: 'must match to {regex}',
    id: 'string.matches.error',
  },
  SELECT_AN_OPERATOR: {
    id: 'select-an-operator.text',
    defaultMessage: 'Select an operator',
  },
  BANK_TRANSFER: {
    id: 'bank-transfer.text',
    defaultMessage: 'Bank transfer',
  },
  CHANGE_PASSWORD: {
    id: 'CHANGE_PASSWORD',
    defaultMessage: 'Change Password',
  },
  SYSTEM_CREDIT: {
    id: 'SYSTEM_CREDIT',
    defaultMessage: 'System Credit',
  },
  SYSTEM_DEBIT: {
    id: 'SYSTEM_DEBIT',
    defaultMessage: 'System Debit',
  },
  'withdrawal-method.payment-method.unknown.text': {
    id: 'withdrawal-method.payment-method.unknown.text',
    defaultMessage: 'Payment Method Unknown',
  },
  UNKNOWN_ERROR: {
    id: 'UNKNOWN_ERROR',
    defaultMessage: 'Unknown Error Has Occured',
  },
  INSUFFICIENT_FUND: {
    id: 'INSUFFICIENT_FUND',
    defaultMessage: 'Insufficient Funds',
  },
  AMOUNT_INVALID_KEYED_NUMERICS: {
    id: 'AMOUNT_INVALID_KEYED_NUMERICS',
    defaultMessage: 'Amount keyed in must be in numerics',
  },
  TURNOVER_INVALID_KEYED_NUMERICS: {
    id: 'TURNOVER_INVALID_KEYED_NUMERICS',
    defaultMessage: 'Turnover keyed in must be in numerics',
  },
  NOT_SUFFICIENT_BALANCE: {
    id: 'NOT_SUFFICIENT_BALANCE',
    defaultMessage: 'User does not have sufficient balance to deduct',
  },
  'MANUAL_ADJUSTMENT.IMPORT_EXCEL_PROCESS_MESSAGE': {
    id: 'MANUAL_ADJUSTMENT.IMPORT_EXCEL_PROCESS_MESSAGE',
    defaultMessage:
      'Please wait while we set things up for you! Do not refresh or close\n' +
      '            the tab',
  },
  'MANUAL_ADJUSTMENT.IMPORT_EXCEL_FAILED_MESSAGE': {
    id: 'MANUAL_ADJUSTMENT.IMPORT_EXCEL_FAILED_MESSAGE',
    defaultMessage:
      'One of the fields is incorrect or invalid, Please follow the examples in order to continue. please check the .xls file again.',
  },
  PASSWORD_TO_CONFIRM: {
    id: 'PASSWORD_TO_CONFIRM',
    defaultMessage: 'Password to Confirm',
  },
  'MANUAL_ADJUSTMENT.BULK_FAILED_MESSAGE': {
    id: 'MANUAL_ADJUSTMENT.BULK_FAILED_MESSAGE',
    defaultMessage:
      'The following members have encountered an error. Please click Details for more information.',
  },
  UPLOAD_ERROR_FILE_SIZE_LIMIT: {
    id: 'UPLOAD_ERROR_FILE_SIZE_LIMIT',
    defaultMessage: 'The file size you uploaded exceeds {size}',
  },
  'member-balance.success-message.text': {
    id: 'member-balance.success-message.text',
    defaultMessage: 'Balance adjusted successfully',
  },
  ENTER_USERNAME: {
    id: 'enter-username.text',
    defaultMessage: 'Enter Username',
  },
  ENTER_NAME: {
    id: 'enter-name.text',
    defaultMessage: 'Enter Name',
  },
  TYPE: {
    id: 'type.text',
    defaultMessage: 'Type',
  },
  LABELS: {
    id: 'labels.text',
    defaultMessage: 'Labels',
  },

  CONTINUE: {
    id: 'CONTINUE',
    defaultMessage: 'Continue',
  },
  EDIT: {
    id: 'edit.text',
    defaultMessage: 'Edit',
  },
  DELETE: {
    id: 'delete.text',
    defaultMessage: 'Delete',
  },
  NO_CLAIM_EXPIRY_TIME: {
    id: 'rebates.no-claim-expiry-time.text',
    defaultMessage: 'No Claim Expiry Time',
  },
  NO_CLAIM_OFFSET_TIME: {
    id: 'rebates.no-claim-offset-time.text',
    defaultMessage: 'No Claim Offset Time',
  },
  DAYS: {
    id: 'DAYS',
    defaultMessage: 'Days',
  },
  MONTHS: {
    id: 'MONTHS',
    defaultMessage: 'Months',
  },
  USERNAME_REQUIRED: {
    id: 'username-field-is-required.text',
    defaultMessage: 'Username field is required',
  },
  USERNAME_DOES_NOT_EXIST: {
    id: 'USERNAME_DOES_NOT_EXIST',
    defaultMessage: 'Username does not exist',
  },
  'previous-tag-required.text': {
    id: 'previous-tag-required.text',
    defaultMessage: 'Previous tag is required',
  },
  MEMBERS: {
    id: 'MEMBERS',
    defaultMessage: 'Members',
  },
  'search-settings-deleted.text': {
    id: 'search-settings-deleted.text',
    defaultMessage: 'Search settings deleted',
  },
  'agent.programme-name.text': {
    id: 'agent.programme-name.text',
    defaultMessage: 'Programme Name',
  },
  'delete-this-withdrawal-account.text': {
    id: 'delete-this-withdrawal-account.text',
    defaultMessage: 'Delete this withdrawal account?',
  },
  'bulk-edit-balance-confirm-question.text': {
    id: 'bulk-edit-balance-confirm-question.text',
    defaultMessage:
      'Are you sure you want to proceed? This action is irreversible.',
  },
  YES: {
    id: 'YES',
    defaultMessage: 'Yes',
  },
  YES_OR_NO: {
    id: 'YES_OR_NO',
    defaultMessage: 'Yes / No',
  },
  DRAFT_OR_ACTIVATED: {
    id: 'DRAFT_OR_ACTIVATED',
    defaultMessage: 'Draft / Activated',
  },
  NO: {
    id: 'NO',
    defaultMessage: 'No',
  },
  TO_DO: {
    id: 'TO_DO',
    defaultMessage: 'To Do',
  },
  STATUS: {
    id: 'STATUS',
    defaultMessage: 'Status',
  },
  TIERS: {
    id: 'TIERS',
    defaultMessage: 'Tiers',
  },
  DEFAULT_PROGRAMME: {
    id: 'DEFAULT_PROGRAMME',
    defaultMessage: 'Default Programme',
  },
  NEGATIVE_CARRY: {
    id: 'NEGATIVE_CARRY',
    defaultMessage: 'Negative Carry',
  },
  OPERATOR_DELETE_SUCCESS: {
    id: 'OPERATOR_DELETE_SUCCESS',
    defaultMessage: 'The operator has been deleted.',
  },
  OPERATOR_DELETE_ERROR: {
    id: 'OPERATOR_DELETE_ERROR',
    defaultMessage: 'Failed to delete the selected operator.',
  },
  AFFILIATE_PROGRAMME_ACTIVATE_SUCCESS: {
    id: 'AFFILIATE_PROGRAMME_ACTIVATE_SUCCESS',
    defaultMessage: 'Affiliate programme activated.',
  },
  AFFILIATE_PROGRAMME_ACTIVATE_ERROR: {
    id: 'AFFILIATE_PROGRAMME_ACTIVATE_ERROR',
    defaultMessage: 'Failed to activate affiliate programme.',
  },
  AFFILIATE_PROGRAMME_DEACTIVATE_SUCCESS: {
    id: 'AFFILIATE_PROGRAMME_DEACTIVATE_SUCCESS',
    defaultMessage: 'Affiliate programme deactivated.',
  },
  AFFILIATE_PROGRAMME_DEACTIVATE_ERROR: {
    id: 'AFFILIATE_PROGRAMME_DEACTIVATE_ERROR',
    defaultMessage: 'Failed to deactivate affiliate programme.',
  },
  AFFILIATE_PROGRAMME_DELETE_SUCCESS: {
    id: 'AFFILIATE_PROGRAMME_DELETE_SUCCESS',
    defaultMessage: 'Affiliate programme deleted.',
  },
  AFFILIATE_PROGRAMME_DELETE_ERROR: {
    id: 'AFFILIATE_PROGRAMME_DELETE_ERROR',
    defaultMessage: 'Failed to delete affiliate programme.',
  },
  AFFILIATE_PROGRAMME_DUPLICATE_SUCCESS: {
    id: 'AFFILIATE_PROGRAMME_DUPLICATE_SUCCESS',
    defaultMessage: 'Affiliate programme duplicated.',
  },
  AFFILIATE_PROGRAMME_DUPLICATE_ERROR: {
    id: 'AFFILIATE_PROGRAMME_DUPLICATE_ERROR',
    defaultMessage: 'Failed to duplicate affiliate programme.',
  },
  AFFILIATE_PROGRAMME_UPDATE_SUCCESS: {
    id: 'AFFILIATE_PROGRAMME_UPDATE_SUCCESS',
    defaultMessage: 'Affiliate programme saved.',
  },
  AFFILIATE_PROGRAMME_UPDATE_ERROR: {
    id: 'AFFILIATE_PROGRAMME_UPDATE_ERROR',
    defaultMessage: 'Failed to save affiliate programme.',
  },
  AFFILIATE_PROGRAMME_FIELDS_ERROR: {
    id: 'AFFILIATE_PROGRAMME_FIELDS_ERROR',
    defaultMessage: 'Please fill in required fields.',
  },
  AGENT_AFFILIATE_PROGRAMME: {
    id: 'agent-affiliate-programme.text',
    defaultMessage: 'Agent Affiliate Programme',
  },
  COST_SETTINGS: {
    id: 'cost-settings.text',
    defaultMessage: 'Cost Settings',
  },
  SETTLEMENT_PERIOD: {
    id: 'settlement-period.text',
    defaultMessage: 'Settlement Period',
  },
  TIER_SETTINGS: {
    id: 'tier-settings.text',
    defaultMessage: 'Tier Settings',
  },
  PAYOUT_METHOD: {
    id: 'payout-method.text',
    defaultMessage: 'Payout Method',
  },
  WEEKLY: {
    id: 'WEEKLY',
    defaultMessage: 'Weekly',
  },
  AMOUNT_BASE: {
    id: 'AMOUNT_BASE',
    defaultMessage: 'Amount (Base)',
  },
  AMOUNT_MEMBER: {
    id: 'AMOUNT_MEMBER',
    defaultMessage: 'Amount (Member)',
  },
  CURRENCY: {
    id: 'CURRENCY',
    defaultMessage: 'Currency',
  },
  EXCHANGE_RATE: {
    id: 'EXCHANGE_RATE',
    defaultMessage: 'Exchange Rate',
  },
  BIWEEKLY: {
    id: 'BIWEEKLY',
    defaultMessage: 'Every 2 weeks',
  },
  MONTHLY: {
    id: 'MONTHLY',
    defaultMessage: 'Monthly',
  },
  EVERY_SUNDAY: {
    id: 'EVERY_SUNDAY',
    defaultMessage: 'Every Sunday',
  },
  EVERY_MONDAY: {
    id: 'EVERY_MONDAY',
    defaultMessage: 'Every Monday',
  },
  EVERY_TUESDAY: {
    id: 'EVERY_TUESDAY',
    defaultMessage: 'Every Tuesday',
  },
  EVERY_WEDNESDAY: {
    id: 'EVERY_WEDNESDAY',
    defaultMessage: 'Every Wednesday',
  },
  EVERY_THURSDAY: {
    id: 'EVERY_THURSDAY',
    defaultMessage: 'Every Thursday',
  },
  EVERY_FRIDAY: {
    id: 'EVERY_FRIDAY',
    defaultMessage: 'Every Friday',
  },
  EVERY_SATURDAY: {
    id: 'EVERY_SATURDAY',
    defaultMessage: 'Every Saturday',
  },
  NO_AWARD_EXPIRY_TIME: {
    id: 'NO_AWARD_EXPIRY_TIME',
    defaultMessage: 'No Award Expiry Time',
  },
  TIER_NAME: {
    id: 'tier-name.text',
    defaultMessage: 'Tier Name',
  },
  MINIMUM_ACTIVE_MEMBERS: {
    id: 'minimumm-active-members.text',
    defaultMessage: 'Minimum Active Members',
  },
  MAXIMUM_ACTIVE_MEMBERS: {
    id: 'maximum-active-members.text',
    defaultMessage: 'Maximum Active Members',
  },
  MINIMUM_NET_PROFIT: {
    id: 'minimum-net-profit.text',
    defaultMessage: 'Minimum Net Profit',
  },
  MAXIMUM_NET_PROFIT: {
    id: 'maximum-net-profit.text',
    defaultMessage: 'Maximum Net Profit',
  },
  COMMISSION_PERCENTAGE: {
    id: 'commission-percentage.text',
    defaultMessage: 'Commission Percentage',
  },
  DEPOSIT_REQUESTS_THIRD_PARTY: {
    id: 'deposit-requests-third-party.text',
    defaultMessage: 'Deposit Requests(3rd Party)',
  },
  VIP_LEVEL: {
    id: 'vip-level.text',
    defaultMessage: 'VIP Level',
  },
  MEMBER_LEVEL: {
    id: 'member-level.text',
    defaultMessage: 'Member Level',
  },
  FIRST_DEPOSIT: {
    id: 'first-deposit.text',
    defaultMessage: 'First Deposit',
  },
  DEPOSIT_REQUESTS_INTERNAL: {
    id: 'deposit-requests-internal.text',
    defaultMessage: 'Deposit Requests(Internal)',
  },
  'serial-code.text': {
    id: 'serial-code.text',
    defaultMessage: 'Serial Code',
  },
  ACCOUNT_USERNAME: {
    id: 'account-username.text',
    defaultMessage: 'Account Username',
  },
  TOTAL_NUMBER_OF_PARTICIPATING_MEMBER: {
    id: 'total-number-of-participating-member.text',
    defaultMessage: 'Total Number of Participating Member',
  },
  AMOUNT: {
    id: 'amount.text',
    defaultMessage: 'Amount',
  },
  PAYMENT_METHOD: {
    id: 'PAYMENT_METHOD',
    defaultMessage: 'Payment Method',
  },
  STATUS_UPDATE_TIME: {
    id: 'STATUS_UPDATE_TIME',
    defaultMessage: 'Status Update Time',
  },
  OPEARATOR_REMARK: {
    id: 'OPEARATOR_REMARK',
    defaultMessage: 'Operator Remark',
  },
  'pending.text': {
    id: 'pending.text',
    defaultMessage: 'Pending',
  },
  PENDING: {
    id: 'pending.text',
    defaultMessage: 'Pending',
  },
  PROCESSING: {
    id: 'processing.text',
    defaultMessage: 'Processing',
  },
  EXPIRED: {
    id: 'expired.text',
    defaultMessage: 'Expired',
  },
  ON_HOLD: {
    id: 'on-hold.text',
    defaultMessage: 'On Hold',
  },
  APPROVED: {
    id: 'approved.text',
    defaultMessage: 'Approved',
  },
  REJECTED: {
    id: 'rejected.text',
    defaultMessage: 'Rejected',
  },
  FAILED: {
    id: 'failed.text',
    defaultMessage: 'Failed',
  },
  MEMBER_HEADER: {
    id: 'member-detail.member-header.text',
    defaultMessage: 'Member Header',
  },
  ACCOUNT_BALANCE: {
    id: 'member-detail.account-balance.text',
    defaultMessage: 'Account Balance',
  },
  LAST_DEPOSIT_AND_WITHDRAWAL: {
    id: 'member-detail.last-deposit-and-withdrawal.text',
    defaultMessage: 'Last Deposit and Withdrawal',
  },
  LAST_PROMO_AND_GAME: {
    id: 'member-detail.last-promo-and-game.text',
    defaultMessage: 'Last Promo and Game',
  },
  MEMBER: {
    id: 'member.text',
    defaultMessage: 'Member',
  },
  MEMBER_NOTES: {
    id: 'member-detail.member-notes.text',
    defaultMessage: 'Member notes',
  },
  MEMBERS_TOTAL_COUNT: {
    id: 'MEMBERS_TOTAL_COUNT',
    defaultMessage: 'Members Total Count',
  },
  OPERATION_FUNCTIONS: {
    id: 'member-detail.operation-functions.text',
    defaultMessage: 'Operation Functions',
  },
  MEMBER_TAGS: {
    id: 'member-tags.text',
    defaultMessage: 'Member tags',
  },
  AGENT: {
    id: 'AGENT',
    defaultMessage: 'Agent',
  },
  MEMBER_DETAIL: {
    id: 'MEMBER_DETAIL',
    defaultMessage: 'Member Detail',
  },
  LOGIN_DETAIL: {
    id: 'LOGIN_DETAIL',
    defaultMessage: 'Login Detail',
  },
  CONTACT_METHOD: {
    id: 'CONTACT_METHOD',
    defaultMessage: 'Contact Method',
  },
  WITHDRAWAL_ACCOUNT: {
    id: 'WITHDRAWAL_ACCOUNT',
    defaultMessage: 'Withdrawal Account',
  },
  REGISTRATION_INFO: {
    id: 'REGISTRATION_INFO',
    defaultMessage: 'Registration Info',
  },
  MESSAGE: {
    id: 'MESSAGE',
    defaultMessage: 'Message',
  },
  INTERACTION_LOGS: {
    id: 'INTERACTION_LOGS',
    defaultMessage: 'Interaction Logs',
  },
  'vip-programme-name.text': {
    id: 'vip-programme-name.text',
    defaultMessage: 'VIP Programme Name',
  },
  VIP_PROGRAMME_NAME: {
    id: 'VIP_PROGRAMME_NAME',
    defaultMessage: 'VIP Programme Name',
  },
  INTERNET_BANKING_TRANSFER: {
    id: 'INTERNET_BANKING_TRANSFER',
    defaultMessage: 'Internet Banking Transfer',
  },
  ATM_CASH_TRANSFER: {
    id: 'ATM_CASH_TRANSFER',
    defaultMessage: 'ATM Cash Transfer',
  },
  ATM_BANK_TRANSFER: {
    id: 'ATM_BANK_TRANSFER',
    defaultMessage: 'ATM Bank Transfer',
  },
  BANK_COUNTER: {
    id: 'BANK_COUNTER',
    defaultMessage: 'Bank Counter',
  },
  MOBILE_BANK_TRANSFER: {
    id: 'MOBILE_BANK_TRANSFER',
    defaultMessage: 'Mobile Bank Transfer',
  },
  ALIPAY_TRANSFER: {
    id: 'ALIPAY_TRANSFER',
    defaultMessage: 'Alipay Transfer',
  },
  WECHAT_PAY_TRANSFER: {
    id: 'WECHAT_PAY_TRANSFER',
    defaultMessage: 'Wechat Transfer',
  },
  UNIONPAY_INTERNET_TRANSFER: {
    id: 'UNIONPAY_INTERNET_TRANSFER',
    defaultMessage: 'Unionpay Internet Transfer',
  },
  JDPAY_TRANSFER: {
    id: 'JDPAY_TRANSFER',
    defaultMessage: 'JDPay Transfer',
  },
  WECHAT_TO_BANK_TRANSFER: {
    id: 'WECHAT_TO_BANK_TRANSFER',
    defaultMessage: 'Wechat to Bank Transfer',
  },
  UNIONPAY_CLOUD_TRANSFER: {
    id: 'UNIONPAY_CLOUD_TRANSFER',
    defaultMessage: 'UnionPay Cloud Transfer',
  },
  QQPAY_TRANSFER: {
    id: 'QQPAY_TRANSFER',
    defaultMessage: 'QQPay Transfer',
  },
  'vip-name.text': {
    id: 'vip-name.text',
    defaultMessage: 'VIP Name',
  },
  VIP_NAME: {
    id: 'VIP_NAME',
    defaultMessage: 'VIP Name',
  },
  'name.text': {
    id: 'name.text',
    defaultMessage: 'Name',
  },
  amount_text: {
    id: 'amount_text',
    defaultMessage: 'amount',
  },
  'uv.text': {
    id: 'uv.text',
    defaultMessage: 'uv',
  },
  'member-loyalty.name-is-required.text': {
    id: 'member-loyalty.name-is-required.text',
    defaultMessage: 'Name is required',
  },
  'member-loyalty.tier-name-is-required.text': {
    id: 'member-loyalty.tier-name-is-required.text',
    defaultMessage: 'Tier Name is required',
  },
  'member-loyalty.start-date-required.text': {
    id: 'member-loyalty.start-date-required.text',
    defaultMessage: 'Start Date required',
  },
  'member-loyalty.date-range-required.text': {
    id: 'member-loyalty.date-range-required.text',
    defaultMessage: 'Date Range Required',
  },
  'member-loyalty.end-date-required.text': {
    id: 'member-loyalty.end-date-required.text',
    defaultMessage: 'End Date required',
  },
  'member-loyalty.loyalty-programme-name.text': {
    id: 'member-loyalty.loyalty-programme-name.text',
    defaultMessage: 'Loyalty Programme Name',
  },
  'member-loyalty.name-must-be-at-least-4-characters.text': {
    id: 'member-loyalty.name-must-be-at-least-4-characters.text',
    defaultMessage: 'name must be at least 4 characters',
  },
  'member-loyalty.name-must-be-max-6-characters.text': {
    id: 'member-loyalty.name-must-be-max-6-characters.text',
    defaultMessage: 'name must be max  6 characters',
  },
  'member-loyalty.name-must-be-max-12-characters.text': {
    id: 'member-loyalty.name-must-be-max-12-characters.text',
    defaultMessage: 'name must be max 12 characters',
  },
  'member-loyalty.limit-is-required.text': {
    id: 'member-loyalty.limit-is-required.text',
    defaultMessage: 'limit is required',
  },
  'member-loyalty.amount-is-required.text': {
    id: 'member-loyalty.amount-is-required.text',
    defaultMessage: 'amount is required',
  },
  'member-loyalty.turnover-is-required.text': {
    id: 'member-loyalty.turnover-is-required.text',
    defaultMessage: 'turnover is required',
  },
  'member-loyalty.time-of-day-is-required.text': {
    id: 'member-loyalty.time-of-day-is-required.text',
    defaultMessage: 'time of day is required',
  },
  'member-loyalty.day-of-week-is-required.text': {
    id: 'member-loyalty.day-of-week-is-required.text',
    defaultMessage: 'day of week is required',
  },
  'member-loyalty.day-of-month-is-required.text': {
    id: 'member-loyalty.day-of-month-is-required.text',
    defaultMessage: 'day of month is required',
  },
  'turnover-x.text': {
    id: 'turnover-x.text',
    defaultMessage: 'Turnover (x)',
  },
  'delete.text': {
    id: 'delete.text',
    defaultMessage: 'Delete',
  },
  'cancel.text': {
    id: 'cancel.text',
    defaultMessage: 'Cancel',
  },
  'draft.text': {
    id: 'draft.text',
    defaultMessage: 'draft',
  },
  DRAFT: {
    id: 'DRAFT',
    defaultMessage: 'Draft',
  },
  ACTIVE: {
    id: 'ACTIVE',
    defaultMessage: 'Active',
  },
  INACTIVE: {
    id: 'INACTIVE',
    defaultMessage: 'Inactive',
  },
  ACTIVATED: {
    id: 'ACTIVATED',
    defaultMessage: 'Activated',
  },
  DEACTIVATED: {
    id: 'DEACTIVATED',
    defaultMessage: 'Deactivated',
  },
  'active.text': {
    id: 'active.text',
    defaultMessage: 'Active',
  },
  'inactive.text': {
    id: 'inactive.text',
    defaultMessage: 'Inactive',
  },
  'required.text': {
    id: 'required.text',
    defaultMessage: 'Required',
  },
  'title.text': {
    id: 'title.text',
    defaultMessage: 'Title',
  },
  'body.text': {
    id: 'body.text',
    defaultMessage: 'Body',
  },
  'snippet.text': {
    id: 'snippet.text',
    defaultMessage: 'Snippet',
  },
  'vip-level.text': {
    id: 'vip-level.text',
    defaultMessage: 'VIP Level',
  },
  'total-turnover.text': {
    id: 'total-turnover.text',
    defaultMessage: 'Total Turnover',
  },
  'total-payout.text': {
    id: 'total-payout.text',
    defaultMessage: 'Total Payout',
  },
  'last-online.text': {
    id: 'last-online.text',
    defaultMessage: 'Last Online',
  },
  'apply-date.text': {
    id: 'apply-date.text',
    defaultMessage: 'Apply Date',
  },
  'join-date.text': {
    id: 'join-date.text',
    defaultMessage: 'Join Date',
  },
  'approved-by.text': {
    id: 'approved-by.text',
    defaultMessage: 'Approved By',
  },
  'approved-date.text': {
    id: 'approved-date.text',
    defaultMessage: 'Approved Date',
  },
  PENDING_DATE: {
    id: 'PENDING_DATE',
    defaultMessage: 'Pending Date',
  },
  PROCESSING_DATE: {
    id: 'PROCESSING_DATE',
    defaultMessage: 'Processing Date',
  },
  'rejected-date.text': {
    id: 'rejected-date.text',
    defaultMessage: 'Rejected Date',
  },
  'rejected-by.text': {
    id: 'rejected-by.text',
    defaultMessage: 'Rejected By',
  },
  'member-details-remark.text': {
    id: 'member-details-remark.text',
    defaultMessage: 'Member Details Remark',
  },
  'labels.text': {
    id: 'labels.text',
    defaultMessage: 'Labels',
  },
  'status.text': {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  'fatal-error.text': {
    id: 'fatal-error.text',
    defaultMessage:
      'An error has occured. Please contact Customer service for assistance.',
  },
  'loading.text': {
    id: 'loading.text',
    defaultMessage: 'Loading...',
  },
  'error-text': {
    id: 'error.text',
    defaultMessage: 'Error...',
  },
  'no-data.text': {
    id: 'no-data.text',
    defaultMessage: 'No data',
  },
  FATAL_ERROR: {
    id: 'FATAL_ERROR',
    defaultMessage:
      'An error has occured. Please contact Customer service for assistance.',
  },
  'save.text': {
    id: 'save.text',
    defaultMessage: 'Save',
  },
  SAVE_AND_EXIT: {
    id: 'SAVE_AND_EXIT',
    defaultMessage: 'Save & Exit',
  },
  'saved-notification.text': {
    id: 'saved-notification.text',
    defaultMessage: 'Saved',
  },
  'image-dimension-upload-error.text': {
    id: 'image-dimension-upload-error.text',
    defaultMessage: 'Image dimension is much larger than the {size}',
  },
  IMAGE_UPLOAD_ERROR_EXACT_SIZE: {
    id: 'IMAGE_UPLOAD_ERROR_EXACT_SIZE',
    defaultMessage: 'Image dimension should be exact to {size}',
  },
  'image-dimension-upload-error-minimum.text': {
    id: 'image-dimension-upload-error-minimum.text',
    defaultMessage:
      'Image dimensions should be equal or larger than the {size}',
  },
  'wrong-file-type.text': {
    id: 'wrong-file-type.text',
    defaultMessage: 'Wrong file type',
  },
  'file-size-too-large.text': {
    id: 'file-size-too-large.text',
    defaultMessage: 'File size is too large',
  },
  'bank-account.text': {
    id: 'bank_account.text',
    defaultMessage: 'Bank Account',
  },
  'payment-account-number.text': {
    id: 'payment-account-number.text',
    defaultMessage: 'Payment Account Number',
  },
  'hexopay-transaction-UID.text': {
    id: 'hexopay-transaction-UID.text',
    defaultMessage: 'Hexopay Transaction UID',
  },
  'neteller-transaction-UID.text': {
    id: 'neteller-transaction-UID.text',
    defaultMessage: 'Neteller Transaction UID',
  },

  'skrill-transaction-UID.text': {
    id: 'skrill-transaction-UID.text',
    defaultMessage: 'Skrill Transaction UID',
  },
  'username.text': {
    id: 'username.text',
    defaultMessage: 'Username',
  },
  'real-name.text': {
    id: 'real-name.text',
    defaultMessage: 'Real Name',
  },
  'gender.text': {
    id: 'gender.text',
    defaultMessage: 'Gender',
  },
  'date-of-birth.text': {
    id: 'date-of-birth.text',
    defaultMessage: 'Date of Birth',
  },
  'mobile-number.text': {
    id: 'mobile-number.text',
    defaultMessage: 'Mobile Number',
  },
  'email.text': {
    id: 'email.text',
    defaultMessage: 'Email',
  },
  'email-address-not-available.text': {
    id: 'email-address-not-available.text',
    defaultMessage: 'Email address is not available',
  },
  'qq.text': {
    id: 'qq.text',
    defaultMessage: 'QQ',
  },
  'wechat.text': {
    id: 'we-chat.text',
    defaultMessage: 'WeChat',
  },
  'withdrawal-password.text': {
    id: 'withdrawal-password.text',
    defaultMessage: 'Withdrawal Password',
  },
  'no-matching-items.found.text': {
    id: 'no-matching-items.found.text',
    defaultMessage: 'No matching items found.',
  },
  'select.text': {
    id: 'select.text',
    defaultMessage: 'Select',
  },
  'example-website.text': {
    id: 'example-website.text',
    defaultMessage: 'www.example.com',
  },
  'on.text': {
    id: 'on.text',
    defaultMessage: 'On',
  },
  'off.text': {
    id: 'off.text',
    defaultMessage: 'Off',
  },
  'slow.text': {
    id: 'slow.text',
    defaultMessage: 'Slow',
  },
  'medium.text': {
    id: 'medium.text',
    defaultMessage: 'Medium',
  },
  'fast.text': {
    id: 'fast.text',
    defaultMessage: 'Fast',
  },
  'slot.text': {
    id: 'slot.text',
    defaultMessage: 'Slot',
  },
  'casual.text': {
    id: 'casual.text',
    defaultMessage: 'Casual',
  },
  'table.text': {
    id: 'table.text',
    defaultMessage: 'Table',
  },
  'live-casino.text': {
    id: 'live-casino.text',
    defaultMessage: 'Live Casino',
  },
  'live.text': {
    id: 'live.text',
    defaultMessage: 'Live',
  },
  'poker.text': {
    id: 'poker.text',
    defaultMessage: 'Poker',
  },
  'fish.text': {
    id: 'fish.text',
    defaultMessage: 'Fish',
  },
  'sportsbook.text': {
    id: 'sportsbook.text',
    defaultMessage: 'Sportsbook',
  },
  'scratch.text': {
    id: 'scratch.text',
    defaultMessage: 'SCRATCH',
  },
  'lottery.text': {
    id: 'lottery.text',
    defaultMessage: 'Lottery',
  },
  'yes.text': {
    id: 'yes.text',
    defaultMessage: 'Yes',
  },
  'no.text': {
    id: 'no.text',
    defaultMessage: 'No',
  },
  'seamless.text': {
    id: 'seamless.text',
    defaultMessage: 'Seamless',
  },
  'transfer.text': {
    id: 'transfer.text',
    defaultMessage: 'Transfer',
  },
  'high.text': {
    id: 'high.text',
    defaultMessage: 'High',
  },
  'low.text': {
    id: 'low.text',
    defaultMessage: 'Low',
  },
  'instant.text': {
    id: 'instant.text',
    defaultMessage: 'Instant',
  },
  'daily.text': {
    id: 'daily.text',
    defaultMessage: 'Daily',
  },
  'weekly.text': {
    id: 'weekly.text',
    defaultMessage: 'Weekly',
  },
  'monthly.text': {
    id: 'monthly.text',
    defaultMessage: 'Monthly',
  },
  'annually.text': {
    id: 'annually.text',
    defaultMessage: 'Annually',
  },
  'yearly.text': {
    id: 'yearly.text',
    defaultMessage: 'Annually',
  },
  'work-in-progress.text': {
    id: 'work-in-progress.text',
    defaultMessage: 'Work in progress',
  },
  'sunday.text': {
    id: 'sunday.text',
    defaultMessage: 'Sunday',
  },
  'monday.text': {
    id: 'monday.text',
    defaultMessage: 'Monday',
  },
  'tuesday.text': {
    id: 'tuesday.text',
    defaultMessage: 'Tuesday',
  },
  'wednesday.text': {
    id: 'wednesday.text',
    defaultMessage: 'Wednesday',
  },
  'thursday.text': {
    id: 'thursday.text',
    defaultMessage: 'Thursday',
  },
  'friday.text': {
    id: 'friday.text',
    defaultMessage: 'Friday',
  },
  'saturday.text': {
    id: 'saturday.text',
    defaultMessage: 'Saturday',
  },
  '1st.text': {
    id: '1st.text',
    defaultMessage: '1st',
  },
  '2nd.text': {
    id: '2nd.text',
    defaultMessage: '2nd',
  },
  '3rd.text': {
    id: '3rd.text',
    defaultMessage: '3rd',
  },
  '4th.text': {
    id: '4th.text',
    defaultMessage: '4th',
  },
  '5th.text': {
    id: '5th.text',
    defaultMessage: '5th',
  },
  '6th.text': {
    id: '6th.text',
    defaultMessage: '6th',
  },
  '7th.text': {
    id: '7th.text',
    defaultMessage: '7th',
  },
  '8th.text': {
    id: '8th.text',
    defaultMessage: '8th',
  },
  '9th.text': {
    id: '9th.text',
    defaultMessage: '9th',
  },
  '10th.text': {
    id: '10th.text',
    defaultMessage: '10th',
  },
  '11th.text': {
    id: '11th.text',
    defaultMessage: '11th',
  },
  '12th.text': {
    id: '12th.text',
    defaultMessage: '12th',
  },
  '13th.text': {
    id: '13th.text',
    defaultMessage: '13th',
  },
  '14th.text': {
    id: '14th.text',
    defaultMessage: '14th',
  },
  '15th.text': {
    id: '15th.text',
    defaultMessage: '15th',
  },
  '16th.text': {
    id: '16th.text',
    defaultMessage: '16th',
  },
  '17th.text': {
    id: '17th.text',
    defaultMessage: '17th',
  },
  '18th.text': {
    id: '18th.text',
    defaultMessage: '18th',
  },
  '19th.text': {
    id: '19th.text',
    defaultMessage: '19th',
  },
  '20th.text': {
    id: '20th.text',
    defaultMessage: '20th',
  },
  '21st.text': {
    id: '21st.text',
    defaultMessage: '21st',
  },
  '22nd.text': {
    id: '22nd.text',
    defaultMessage: '22nd',
  },
  '23rd.text': {
    id: '23rd.text',
    defaultMessage: '23rd',
  },
  '24th.text': {
    id: '24th.text',
    defaultMessage: '24th',
  },
  '25th.text': {
    id: '25th.text',
    defaultMessage: '25th',
  },
  '26th.text': {
    id: '26th.text',
    defaultMessage: '26th',
  },
  '27th.text': {
    id: '27th.text',
    defaultMessage: '27th',
  },
  '28th.text': {
    id: '28th.text',
    defaultMessage: '28th',
  },
  '29th.text': {
    id: '29th.text',
    defaultMessage: '29th',
  },
  '30th.text': {
    id: '30th.text',
    defaultMessage: '30th',
  },
  '31st.text': {
    id: '31st.text',
    defaultMessage: '31st',
  },
  'no-data-yet.text': {
    id: 'no-data-yet.text',
    defaultMessage: 'No data yet',
  },
  'games.text': {
    id: 'games.text',
    defaultMessage: 'Games',
  },
  'game-vendors.text': {
    id: 'game-vendors.text',
    defaultMessage: 'Game Vendors',
  },
  'game-name.text': {
    id: 'game-name.text',
    defaultMessage: 'Game Name',
  },
  'visibility.text': {
    id: 'visibility.text',
    defaultMessage: 'Visibility',
  },
  'game-type.text': {
    id: 'game-type.text',
    defaultMessage: 'Game type',
  },
  'game-vendor.text': {
    id: 'game-vendor.text',
    defaultMessage: 'Game vendor',
  },
  'none.text': {
    id: 'none.text',
    defaultMessage: 'None',
  },
  'message.text': {
    id: 'message.text',
    defaultMessage: 'Message',
  },
  'recipient.text': {
    id: 'recipient.text',
    defaultMessage: 'Recipient',
  },
  'priority-alert.text': {
    id: 'priority-alert.text',
    defaultMessage: 'Priority Alert',
  },
  'priority.text': {
    id: 'priority.text',
    defaultMessage: 'Priority',
  },
  'mail-title-field-required.text': {
    id: 'mail-title-field-required.text',
    defaultMessage: 'Mail Title field is required',
  },
  'mail-content-field-required.text': {
    id: 'mail-content-field-required.text',
    defaultMessage: 'Mail Content field is required',
  },
  'users-field-required.text': {
    id: 'users-field-required.text',
    defaultMessage: 'Users field is required',
  },
  'title-field-required.text': {
    id: 'title-field-required.text',
    defaultMessage: 'Title field is required',
  },
  'body-field-required.text': {
    id: 'body-field-required.text',
    defaultMessage: 'Body field is required',
  },
  'date-field-required.text': {
    id: 'date-field-required.text',
    defaultMessage: 'Date field is required',
  },
  'time-field-required.text': {
    id: 'time-field-required.text',
    defaultMessage: 'Time field is required',
  },
  'message-field-required.text': {
    id: 'message-field-required.text',
    defaultMessage: 'Message field is required',
  },
  'recipient-field-required.text': {
    id: 'recipient-field-required.text',
    defaultMessage: 'Recipient field is required',
  },
  'account-settings.text': {
    id: 'account-settings.text',
    defaultMessage: 'Account settings',
  },
  'logout.text': {
    id: 'logout.text',
    defaultMessage: 'Logout',
  },
  'search.text': {
    id: 'search.text',
    defaultMessage: 'Search',
  },
  'search-saved.text': {
    id: 'search-saved.text',
    defaultMessage: 'Search saved',
  },
  'internal.text': {
    id: 'internal.text',
    defaultMessage: 'Internal',
  },
  'external.text': {
    id: 'external.text',
    defaultMessage: 'External',
  },
  'publish.text': {
    id: 'publish.text',
    defaultMessage: 'Publish',
  },
  'unpublish.text': {
    id: 'unpublish.text',
    defaultMessage: 'Unpublish',
  },
  'country-and-city.text': {
    id: 'country-and-city.text',
    defaultMessage: 'Country and City',
  },
  COUNTRY: {
    id: 'COUNTRY',
    defaultMessage: 'Country',
  },
  COUNTY: {
    id: 'COUNTY',
    defaultMessage: 'county',
  },
  CITY: {
    id: 'CITY',
    defaultMessage: 'city',
  },
  'ip.text': {
    id: 'ip.text',
    defaultMessage: 'IP',
  },
  'frontend.text': {
    id: 'frontend.text',
    defaultMessage: 'Frontend',
  },
  'backend.text': {
    id: 'backend.text',
    defaultMessage: 'Backend',
  },
  'operator.text': {
    id: 'operator.text',
    defaultMessage: 'Operator',
  },
  'admin.text': {
    id: 'admin.text',
    defaultMessage: 'Admin',
  },
  'edit.text': {
    id: 'edit.text',
    defaultMessage: 'Edit',
  },
  upload: {
    id: 'file.upload.title',
    defaultMessage: 'Upload',
  },
  'please-enter-a-label.text': {
    id: 'please-enter-a-label.text',
    defaultMessage: 'Please enter a label',
  },
  'please-enter.text': {
    id: 'please-enter.text',
    defaultMessage: 'Please Enter',
  },
  'status-field-required-error.text': {
    id: 'status-field-required-error.text',
    defaultMessage: 'Status field required',
  },
  'username-field-required-error.text': {
    id: 'username-field-required-error.text',
    defaultMessage: 'Username field required',
  },
  'type-field-required-error.text': {
    id: 'type-field-required-error.text',
    defaultMessage: 'Type field required',
  },
  TYPE_REQUIRED: {
    id: 'TYPE_REQUIRED',
    defaultMessage: 'Type field required',
  },
  'amount-field-required-error.text': {
    id: 'amount-field-required-error.text',
    defaultMessage: 'Amount field required',
  },
  AMOUNT_REQUIRED: {
    id: 'AMOUNT_REQUIRED',
    defaultMessage: 'Amount field required',
  },
  'turnover-field-required-error.text': {
    id: 'turnover-field-required-error.text',
    defaultMessage: 'Turnover field required',
  },
  TURNOVER_REQUIRED: {
    id: 'TURNOVER_REQUIRED',
    defaultMessage: 'Turnover field required',
  },
  'remarks-field-required-error.text': {
    id: 'remarks-field-required-error.text',
    defaultMessage: 'Remarks field required',
  },
  'realname-field-required-error.text': {
    id: 'realname-field-required-error.text',
    defaultMessage: 'Real name field required',
  },
  'name-field-required-error.text': {
    id: 'name-field-required-error.text',
    defaultMessage: 'Name field required',
  },
  'color-field-required-error.text': {
    id: 'color-field-required-error.text',
    defaultMessage: 'Color field required',
  },
  'birthdate-field-required-error.text': {
    id: 'birthdate-field-required-error.text',
    defaultMessage: 'Birth date field required',
  },
  'member-marker-field-required-error.text': {
    id: 'member-marker-field-required-error.text',
    defaultMessage: 'Member marker field required',
  },
  'mobile-number-error-field.text': {
    id: 'mobile-number-error-field.text',
    defaultMessage:
      'Mobile number must contain only numbers and between 8 - 14 digits',
  },
  'invalid-email-format.text': {
    id: 'invalid-email-format.text',
    defaultMessage: 'Invalid email format',
  },
  'wechat-id-validation-error.text': {
    id: 'wechat-id-validation-error.text',
    defaultMessage: 'WeChat Id should be between 8 to 14 characters',
  },
  'gender-field-required-error.text': {
    id: 'gender-field-required-error.text',
    defaultMessage: 'Gender field required',
  },
  'firstname-field-required-error.text': {
    id: 'firstname-field-required-error.text',
    defaultMessage: 'Firstname field is required',
  },
  'lastname-field-required-error.text': {
    id: 'lastname-field-required-error.text',
    defaultMessage: 'Lastname field is required',
  },
  'account-password-field-required-error.text': {
    id: 'account-password-field-required-error.text',
    defaultMessage: 'Account password field required',
  },
  ACCOUNT_PASSWORD_REQUIRED: {
    id: 'ACCOUNT_PASSWORD_REQUIRED',
    defaultMessage: 'Account password field required',
  },
  'minimum-number-required-error.text': {
    id: 'minimum-number-required-error.text',
    defaultMessage: 'Minimum should be {count}',
  },
  'number.max.error': {
    id: 'number.max.error',
    defaultMessage: 'must be less than or equal to {max}',
  },
  REFUND_BET: {
    id: 'REFUND_BET',
    defaultMessage: 'Refund Bet',
  },
  'click-or-drag-and-drop-file-to-upload-here.text': {
    id: 'click-or-drag-and-drop-file-to-upload-here.text',
    defaultMessage: 'Click or drag and drop files to upload here',
  },
  'click-or-drag-and-drop-file-to-upload-image-here.text': {
    id: 'click-or-drag-and-drop-file-to-upload-image-here.text',
    defaultMessage:
      'Image file size does not exceed 1024KB, it must be *.png,*.jpg',
  },
  'minimum-number-of-characters.text': {
    id: 'minimum-number-of-characters.text',
    defaultMessage: 'Minimum of {count} characters',
  },
  'maximum-number-of-characters.text': {
    id: 'maximum-number-of-characters.text',
    defaultMessage: 'Maximum of {count} characters',
  },
  'no-special-character-error.text': {
    id: 'no-special-character-error.text',
    defaultMessage: 'Must have no special characters',
  },
  'whitelist.text': {
    id: 'whitelist.text',
    defaultMessage: 'Whitelist',
  },
  'blacklist.text': {
    id: 'blacklist.text',
    defaultMessage: 'Blacklist',
  },
  'cant-save-empty-filters-error.text': {
    id: 'cant-save-empty-filters-error.text',
    defaultMessage: 'Cannot save empty filters',
  },
  'search-settings-updated.text': {
    id: 'search-settings-updated.text',
    defaultMessage: 'Search settings updated',
  },
  'cannot-update-filter-empty-error.text': {
    id: 'cannot-update-filter-empty-error.text',
    defaultMessage: 'Cannot update if filter is empty',
  },
  'must-be-valid-hex-color-error.text': {
    id: 'must-be-valid-hex-color-error.text',
    defaultMessage: 'Must be a valid hex color',
  },
  show: {
    id: 'show.text',
    defaultMessage: 'Show',
  },
  hide: {
    id: 'hide.text',
    defaultMessage: 'Hide',
  },
  'permission-alert.text': {
    id: 'permission-alert.text',
    defaultMessage:
      'Sorry, you do not have permission to access this page. Please contact an administrator for permission.',
  },
  INTERNAL_SERVER_ERROR: {
    id: 'INTERNAL_SERVER_ERROR',
    defaultMessage: 'Something went wrong',
  },
  INVALID_CREDENTIALS: {
    id: 'INVALID_CREDENTIALS',
    defaultMessage: 'Invalid username or password',
  },
  INVALID_PIN: {
    id: 'INVALID_PIN',
    defaultMessage: 'Invalid PIN',
  },
  REAL_TIME: {
    id: 'REAL_TIME',
    defaultMessage: 'Real Time',
  },
  DAILY: {
    id: 'DAILY',
    defaultMessage: 'Daily',
  },
  ANNUALLY: {
    id: 'ANNUALLY',
    defaultMessage: 'Annually',
  },
  START: {
    id: 'START',
    defaultMessage: 'Start',
  },
  END: {
    id: 'END',
    defaultMessage: 'End',
  },
  PUBLISH: {
    id: 'PUBLISH',
    defaultMessage: 'Publish',
  },
  UNPUBLISH: {
    id: 'UNPUBLISH',
    defaultMessage: 'Unpublish',
  },
  CONFIRM_DELETE: {
    id: 'CONFIRM_DELETE',
    defaultMessage: 'Confirm Delete',
  },
  CONFIRM_DUPLICATE: {
    id: 'CONFIRM_DUPLICATE',
    defaultMessage: 'Confirm Duplicate',
  },
  DO_YOU_WANT_TO_DELETE: {
    id: 'DO_YOU_WANT_TO_DELETE',
    defaultMessage: 'Do you want to delete',
  },
  DO_YOU_WANT_TO_DUPLICATE: {
    id: 'DO_YOU_WANT_TO_DUPLICATE',
    defaultMessage: 'Do you want to duplicate',
  },
  ENTER_FIELD_NAME: {
    id: 'ENTER_FIELD_NAME',
    defaultMessage: 'Enter Field Name',
  },
  EMAIL_SETUP: {
    id: 'EMAIL_SETUP',
    defaultMessage: 'Email Setup',
  },
  QQ_ID_SETUP: {
    id: 'QQ_ID_SETUP',
    defaultMessage: 'QQ ID Setup',
  },
  MOBILE_NUMBER_SETUP: {
    id: 'MOBILE_NUMBER_SETUP',
    defaultMessage: 'Mobile Number Setup',
  },
  EXISTING_MEMBER_BANK_ACCOUNT_REQUIRED: {
    id: 'EXISTING_MEMBER_BANK_ACCOUNT_REQUIRED',
    defaultMessage: 'User does not have a Bank Account',
  },
  BANK_ACCOUNT_SETUP: {
    id: 'BANK_ACCOUNT_SETUP',
    defaultMessage: 'Bank Account Setup',
  },
  WE_CHAT_ID_SETUP: {
    id: 'WE_CHAT_ID_SETUP',
    defaultMessage: 'WeChat ID Setup',
  },
  REAL_NAME_SETUP: {
    id: 'REAL_NAME_SETUP',
    defaultMessage: 'Real Name Setup',
  },
  DATE_OF_BIRTH_SETUP: {
    id: 'DATE_OF_BIRTH_SETUP',
    defaultMessage: 'Birthday Setup',
  },
  PLEASE_FILL_IN_REQUIRED_FIELDS: {
    id: 'PLEASE_FILL_IN_REQUIRED_FIELDS',
    defaultMessage: 'Please fill in required fields.',
  },
  PROMO_TITLE: {
    id: 'PROMO_TITLE',
    defaultMessage: 'Promo Title',
  },
  SUBHEADER: {
    id: 'SUBHEADER',
    defaultMessage: 'Subheader',
  },
  PROMO_CONTENT_HERE: {
    id: 'PROMO_CONTENT_HERE',
    defaultMessage: 'Promo Content Here',
  },
  TERMS_AND_CONDITIONS_HERE: {
    id: 'TERMS_AND_CONDITIONS_HERE',
    defaultMessage: 'Terms and conditions here',
  },
  EXCLUDED_MEMBER_LEVELS: {
    id: 'EXCLUDED_MEMBER_MARKERS',
    defaultMessage: 'Excluded Member Markers',
  },
  SELECT_MEMBER_LOYALTY: {
    id: 'SELECT_MEMBER_LOYALTY',
    defaultMessage: 'Select member loyalty',
  },
  SELECT_EXCLUDED_MEMBER_LEVELS: {
    id: 'SELECT_EXCLUDED_MEMBER_MARKERS',
    defaultMessage: 'Select Excluded Member Markers',
  },
  QUALIFYING_VIP: {
    id: 'QUALIFYING_VIP',
    defaultMessage: 'Qualifying VIP',
  },
  PLEASE_SELECT: {
    id: 'PLEASE_SELECT',
    defaultMessage: 'Please select',
  },
  ADD_TO_WATCHLIST: {
    id: 'ADD_TO_WATCHLIST',
    defaultMessage: 'Add to Watchlist',
  },
  USERNAME: {
    id: 'USERNAME',
    defaultMessage: 'Username',
  },
  AFFILIATE: {
    id: 'AFFILIATE',
    defaultMessage: 'Affiliate',
  },
  AFFILIATES: {
    id: 'AFFILIATES',
    defaultMessage: 'Affiliates',
  },

  TOTAL_EFFECTIVE_BET: {
    id: 'TOTAL_EFFECTIVE_BET',
    defaultMessage: 'Total Effective Bet',
  },
  LAST_ONLINE: {
    id: 'LAST_ONLINE',
    defaultMessage: 'Last Online',
  },
  LAST_LOGIN: {
    id: 'LAST_LOGIN',
    defaultMessage: 'Last Login',
  },
  ACTION: {
    id: 'ACTION',
    defaultMessage: 'Action',
  },
  ENTER_REAL_NAME: {
    id: 'ENTER_REAL_NAME',
    defaultMessage: 'Enter Real Name',
  },
  SELECT_SAVED_CUSTOM_FILTER: {
    id: 'SELECT_SAVED_CUSTOM_FILTER',
    defaultMessage: 'Select a saved custom filter',
  },
  START_DATE: {
    id: 'START_DATE',
    defaultMessage: 'Start Date',
  },
  END_DATE: {
    id: 'END_DATE',
    defaultMessage: 'End Date',
  },
  START_TIME: {
    id: 'START_TIME',
    defaultMessage: 'Start Time',
  },
  END_TIME: {
    id: 'END_TIME',
    defaultMessage: 'End Time',
  },
  ENTER_LABEL: {
    id: 'ENTER_LABEL',
    defaultMessage: 'Enter Label',
  },
  ENTER_IP_ADDRESS: {
    id: 'ENTER_IP_ADDRESS',
    defaultMessage: 'Enter IP Address',
  },
  ENTER_BANK_ACCOUNT: {
    id: 'ENTER_BANK_ACCOUNT',
    defaultMessage: 'Enter Bank Account',
  },
  ENTER_EMAIL_ADDRESS: {
    id: 'ENTER_EMAIL_ADDRESS',
    defaultMessage: 'Enter Email Address',
  },
  ENTER_PHONE_NUMBER: {
    id: 'ENTER_PHONE_NUMBER',
    defaultMessage: 'Enter Phone Number',
  },
  ENTER_WECHAT_ID: {
    id: 'ENTER_WECHAT_ID',
    defaultMessage: 'Enter WeChat ID',
  },
  ENTER_QQ_ID: {
    id: 'ENTER_QQ_ID',
    defaultMessage: 'Enter QQ ID',
  },
  ENTER_POSTAL_CODE: {
    id: 'ENTER_POSTAL_CODE',
    defaultMessage: 'Enter Postal Code',
  },
  BALANCE_ADJUSTED: {
    id: 'BALANCE_ADJUSTED',
    defaultMessage: 'Balance Adjusted',
  },
  PROMO_NAME: {
    id: 'PROMO_NAME',
    defaultMessage: 'Promo Name',
  },
  ACTUAL: {
    id: 'ACTUAL',
    defaultMessage: 'Actual',
  },
  TRANSACTION_RECORD_REMARK: {
    id: 'TRANSACTION_RECORD_REMARK',
    defaultMessage: 'Operator Transaction Record Remark',
  },
  INVALID_PASSWORD: {
    id: 'INVALID_PASSWORD',
    defaultMessage: 'Invalid Password',
  },
  PASSWORD_VALIDATION_FIELD: {
    id: 'PASSWORD_VALIDATION_FIELD',
    defaultMessage: 'Password Length must be between 6-36 characters',
  },
  NEW_PASSWORD_DOES_NOT_MATCH: {
    id: 'NEW_PASSWORD_DOES_NOT_MATCH',
    defaultMessage: 'New Password does not match',
  },
  ACCOUNT_PASSWORD: {
    id: 'ACCOUNT_PASSWORD',
    defaultMessage: 'Account Password',
  },
  MESSAGE_HAS_BEEN_SENT: {
    id: 'MESSAGE_HAS_BEEN_SENT',
    defaultMessage: 'Message has been sent',
  },
  SEND_MESSAGE: {
    id: 'SEND_MESSAGE',
    defaultMessage: 'Send Message',
  },
  CONTENT: {
    id: 'CONTENT',
    defaultMessage: 'Content',
  },
  SELECT_DATE: {
    id: 'SELECT_DATE',
    defaultMessage: 'Select Date',
  },
  SELECT_TIME: {
    id: 'SELECT_TIME',
    defaultMessage: 'Select Time',
  },
  SCHEDULE_SEND: {
    id: 'SCHEDULE_SEND',
    defaultMessage: 'Schedule Send',
  },
  MEMBER_UPDATED: {
    id: 'MEMBER_UPDATED',
    defaultMessage: 'Member updated',
  },
  ADDED_TO_WATCHLIST: {
    id: 'ADDED_TO_WATCHLIST',
    defaultMessage: 'Added to watchlist',
  },
  REMOVED_FROM_WATCHLIST: {
    id: 'REMOVED_FROM_WATCHLIST',
    defaultMessage: 'Removed from watchlist',
  },
  SIGN_UP: {
    id: 'SIGN_UP',
    defaultMessage: 'Sign up',
  },
  DEPOSIT: {
    id: 'DEPOSIT',
    defaultMessage: 'Deposit',
  },
  WIN: {
    id: 'WIN',
    defaultMessage: 'Win',
  },
  LOSS: {
    id: 'LOSS',
    defaultMessage: 'Loss',
  },
  DRAW: {
    id: 'DRAW',
    defaultMessage: 'Draw',
  },
  EFFECTIVE_BET: {
    id: 'EFFECTIVE_BET',
    defaultMessage: 'Effective Bet',
  },
  GENERAL: {
    id: 'GENERAL',
    defaultMessage: 'General',
  },
  MANUAL: {
    id: 'MANUAL',
    defaultMessage: 'Manual',
  },
  MANUAL_DEPOSIT: {
    id: 'MANUAL_DEPOSIT',
    defaultMessage: 'Manual Deposit',
  },
  INTERNAL: {
    id: 'INTERNAL',
    defaultMessage: 'Internal',
  },
  THIRD_PARTY: {
    id: 'THIRD_PARTY',
    defaultMessage: '3rd Party',
  },
  REFRESH: {
    id: 'REFRESH',
    defaultMessage: 'Refresh',
  },
  SEARCH: {
    id: 'SEARCH',
    defaultMessage: 'Search',
  },
  WORK_IN_PROGRESS: {
    id: 'WORK_IN_PROGRESS',
    defaultMessage: 'Work in Progress',
  },
  'error-occurred.text': {
    id: 'error-occurred.text',
    defaultMessage: 'An error has occurred. Please try again',
  },
  'qualifying-members.text': {
    id: 'qualifying-members.text',
    defaultMessage: 'Qualifying Members',
  },
  'whole-site-members.text': {
    id: 'whole-site-members.text',
    defaultMessage: 'Whole Site Members',
  },
  'import-from.text': {
    id: 'import-from.text',
    defaultMessage: 'Import From',
  },
  'target-members.text': {
    id: 'target-members.text',
    defaultMessage: 'Target Members',
  },
  'excluded-members.text': {
    id: 'excluded-members.text',
    defaultMessage: 'Excluded Members',
  },
  'mail-title-name.text': {
    id: 'mail-title-name.text',
    defaultMessage: 'Mail Title Name',
  },
  'mail-content.text': {
    id: 'mail-content.text',
    defaultMessage: 'Mail Content',
  },
  'other-setting.text': {
    id: 'other-setting.text',
    defaultMessage: 'Other Setting',
  },
  'schedule-send.text': {
    id: 'schedule-send.text',
    defaultMessage: 'Schedule Send',
  },
  'date.text': {
    id: 'date.text',
    defaultMessage: 'Date',
  },
  'time.text': {
    id: 'time.text',
    defaultMessage: 'Time',
  },
  'update-mail-successful.text': {
    id: 'update-mail-successful.text',
    defaultMessage: 'Successfully updated the mail',
  },
  'create-new-mail-success.text': {
    id: 'create-new-mail-success.text',
    defaultMessage: 'Mail has been created',
  },
  'edit-mail.text': {
    id: 'edit-mail.text',
    defaultMessage: 'Edit Mail',
  },
  'create-new-mail.text': {
    id: 'create-new-mail.text',
    defaultMessage: 'Create New Mail',
  },
  'update.text': {
    id: 'update.text',
    defaultMessage: 'Update',
  },
  'submit.text': {
    id: 'submit.text',
    defaultMessage: 'Submit',
  },
  'accepted-file-formats.text': {
    id: 'accepted-file-formats.text',
    defaultMessage: 'Accepted file formats: {formats}',
  },
  MEMBER_INFORMATION: {
    id: 'MEMBER_INFORMATION',
    defaultMessage: 'Member Information',
  },
  MESSAGE_CREATED: {
    id: 'MESSAGE_CREATED',
    defaultMessage: 'Message created',
  },
  VIP_TIER_PROGRESS: {
    id: 'VIP_TIER_PROGRESS',
    defaultMessage: 'VIP Tier Progress',
  },
  MEMBER_LABEL: {
    id: 'MEMBER_LABEL',
    defaultMessage: 'Member Label',
  },
  USER_PROFILE: {
    id: 'USER_PROFILE',
    defaultMessage: 'User Profile',
  },
  LOGIN_DETAILS: {
    id: 'LOGIN_DETAILS',
    defaultMessage: 'Login Details',
  },
  CONTACT_DETAILS: {
    id: 'CONTACT_DETAILS',
    defaultMessage: 'Contact Details',
  },
  WITHDRAWAL_BANK_ACCOUNT: {
    id: 'WITHDRAWAL_BANK_ACCOUNT',
    defaultMessage: 'Withdrawal Bank Account',
  },
  REGISTRATION_INFORMATION: {
    id: 'REGISTRATION_INFORMATION',
    defaultMessage: 'Registration Information',
  },
  CHARACTERS: {
    id: 'CHARACTERS',
    defaultMessage: 'Characters',
  },
  LOWERCASE_DAY: {
    id: 'LOWERCASE_DAY',
    defaultMessage: 'day',
  },
  LOWERCASE_NA: {
    id: 'LOWERCASE_NA',
    defaultMessage: 'n/a',
  },
  TRANSACTION_TYPE: {
    id: 'TRANSACTION_TYPE',
    defaultMessage: 'Transaction Type',
  },
  REMARKS: {
    id: 'REMARKS',
    defaultMessage: 'Remarks',
  },
  CREATION_DATE: {
    id: 'CREATION_DATE',
    defaultMessage: 'Creation Date',
  },
  COMPLETION_DATE: {
    id: 'COMPLETION_DATE',
    defaultMessage: 'Completion Date',
  },
  ENTER_SERIAL_CODE: {
    id: 'enter-serial-code.text',
    defaultMessage: 'Enter serial code',
  },
  BALANCE_SERVICE_CODE: {
    id: 'balance-service-code.text',
    defaultMessage: 'Balance Service code',
  },
  TRANSACTION_CODE: {
    id: 'transaction-code.text',
    defaultMessage: 'Transaction Code',
  },
  UNIQUE_TRANSACTION_ID: {
    id: 'reports.unique-transaction-id.text',
    defaultMessage: 'Unique Transaction ID',
  },
  CONFIRM: {
    id: 'common.confirm.text',
    defaultMessage: 'Confirm',
  },
  SCHEDULED_DATE_INVALID_PAST_CURRENT_DATE: {
    id: 'SCHEDULED_DATE_INVALID_PAST_CURRENT_DATE',
    defaultMessage: 'You cannot have a scheduled date past the current date',
  },
  CURRENT_PASSWORD: {
    id: 'CURRENT_PASSWORD',
    defaultMessage: 'Current Password',
  },
  NEW_PASSWORD: {
    id: 'NEW_PASSWORD',
    defaultMessage: 'New Password',
  },
  CONFIRM_NEW_PASSWORD: {
    id: 'CONFIRM_NEW_PASSWORD',
    defaultMessage: 'Confirm New Password',
  },
  UPPERCASE_NA: {
    id: 'UPPERCASE_NA',
    defaultMessage: 'NA',
  },
  ENTER_TRANSACTION_TYPE: {
    id: 'enter-transaction-type.text',
    defaultMessage: 'Enter transaction type',
  },
  ORIGINAL_AMOUNT: {
    id: 'original-amount.text',
    defaultMessage: 'Original Amount',
  },
  'minimum-original-amount.text': {
    id: 'minimum-original-amount.text',
    defaultMessage: 'Minimum original amount',
  },
  'maximum-original-amount.text': {
    id: 'maximum-original-amount.text',
    defaultMessage: 'Maximum original amount',
  },
  CURRENT_AMOUNT: {
    id: 'current-amount.text',
    defaultMessage: 'Current Amount',
  },
  'minimum-current-amount.text': {
    id: 'minimum-current-amount.text',
    defaultMessage: 'Minimum current amount',
  },
  'maximum-current-amount.text': {
    id: 'maximum-current-amount.text',
    defaultMessage: 'Maximum current amount',
  },
  TURNOVER_MULTIPLIER: {
    id: 'turnover-multiplier.text',
    defaultMessage: 'Turnover Multiplier',
  },
  ENTER_TURNOVER_MULTIPLIER: {
    id: 'enter-turnover-multiplier.text',
    defaultMessage: 'Enter turnover multiplier',
  },
  TURNOVER_REQUIREMENT: {
    id: 'turnover-requirement.text',
    defaultMessage: 'Turnover Requirement',
  },
  'minimum-turnover-requirement.text': {
    id: 'minimum-turnover-requirement.text',
    defaultMessage: 'Minimum turnover requirement',
  },
  'maximum-turnover-requirement.text': {
    id: 'maximum-turnover-requirement.text',
    defaultMessage: 'Maximum turnover requirement',
  },
  TURNOVER_BALANCE: {
    id: 'turnover-balance.text',
    defaultMessage: 'Turnover Balance',
  },
  'minimum-turnover-balance.text': {
    id: 'minimum-turnover-balance.text',
    defaultMessage: 'Minimum turnover balance',
  },
  'maximum-turnover-balance.text': {
    id: 'maximum-turnover-balance.text',
    defaultMessage: 'Maximum turnover balance',
  },
  BETS: {
    id: 'bets.text',
    defaultMessage: 'Bets',
  },
  'minimum-bets.text': {
    id: 'minimum-bets.text',
    defaultMessage: 'Minimum bets',
  },
  'maximum-bets.text': {
    id: 'maximum-bets.text',
    defaultMessage: 'Maximum bets',
  },
  WITHDRAWAL_ID: {
    id: 'withdrawal-id.text',
    defaultMessage: 'Withdrawal ID',
  },
  MANUAL_ADJUSTMENT: {
    id: 'MANUAL_ADJUSTMENT',
    defaultMessage: 'Manual Adjustment',
  },
  QUICK_FILTER: {
    id: 'QUICK_FILTER',
    defaultMessage: 'Quick Filter',
  },
  PERMISSION: {
    id: 'PERMISSION',
    defaultMessage: 'Permission',
  },
  BALANCE: {
    id: 'balance.text',
    defaultMessage: 'Balance',
  },
  PERMISSION_GROUP: {
    id: 'PERMISSION_GROUP',
    defaultMessage: 'Permission Group',
  },
  DATE_TIME_UPDATED: {
    id: 'DATE_TIME_UPDATED',
    defaultMessage: 'Date/Time updated',
  },
  EFFECTIVE_DATE_RANGE: {
    id: 'EFFECTIVE_DATE_RANGE',
    defaultMessage: 'Effective Date Range',
  },
  PERMISSION_GROUPS: {
    id: 'PERMISSION_GROUPS',
    defaultMessage: 'Permission Groups',
  },
  CLEAR_ALL: {
    id: 'CLEAR_ALL',
    defaultMessage: 'Clear all',
  },
  REJECT: {
    id: 'REJECT',
    defaultMessage: 'Reject',
  },
  ENABLED: {
    id: 'ENABLED',
    defaultMessage: 'Enabled',
  },
  DISABLED: {
    id: 'DISABLED',
    defaultMessage: 'Disabled',
  },
  PLEASE_ENTER_REJECTION_REASON: {
    id: 'PLEASE_ENTER_REJECTION_REASON',
    defaultMessage: 'Please enter reasons for Rejection.',
  },
  PLEASE_ENTER_ONHOLD_REASON: {
    id: 'PLEASE_ENTER_ONHOLD_REASON',
    defaultMessage: 'Please enter reasons for Holding.',
  },
  PLEASE_ENTER: {
    id: 'PLEASE_ENTER',
    defaultMessage: 'Please Enter',
  },
  VIP: {
    id: 'VIP',
    defaultMessage: 'VIP',
  },
  AWARD_TYPE: {
    id: 'AWARD_TYPE',
    defaultMessage: 'Award Type',
  },
  ADD: {
    id: 'common.add.text',
    defaultMessage: 'Add',
  },
  FILTER_CONDITIONS: {
    id: 'filter-conditions.text',
    defaultMessage: 'Filter conditions',
  },
  SEARCH_CRITERIA: {
    id: 'search-criteria.text',
    defaultMessage: 'Search Criteria',
  },
  ACCOUNT: {
    id: 'account.text',
    defaultMessage: 'Account',
  },
  PAYMENT_METHOD_TYPE: {
    id: 'payment-method-type.text',
    defaultMessage: 'Payment Method Type',
  },
  'select-a-payment-method.text': {
    id: 'select-a-payment-method.text',
    defaultMessage: 'Select a payment method',
  },
  OFFLINE_BANK_TRANSFER: {
    id: 'offline-bank.text',
    defaultMessage: 'Offline Bank Transfer',
  },
  UPLOAD_EXCEL: {
    id: 'UPLOAD_EXCEL',
    defaultMessage: 'Upload Excel',
  },
  'MANUAL_ADJUSTMENT.UPLOAD_TEXT_HELPER': {
    id: 'MANUAL_ADJUSTMENT.UPLOAD_TEXT_HELPER',
    defaultMessage: 'The file size should not exceed 1024kb',
  },
  FILE_MUST_BE_IN_XLS_FORMAT: {
    id: 'FILE_MUST_BE_IN_XLS_FORMAT',
    defaultMessage: 'File must be in xls format',
  },
  FORCE_PASSWORD_CHANGE: {
    id: 'FORCE_PASSWORD_CHANGE',
    defaultMessage: 'Force Password Change',
  },
  FORCE_WITHDRAWAL_PIN_CHANGE: {
    id: 'FORCE_WITHDRAWAL_PIN_CHANGE',
    defaultMessage: 'Force Withdrawal Pin Change',
  },
  IMPORT_MEMBERS_10000_ROWS_HELPER: {
    id: 'IMPORT_MEMBERS_10000_ROWS_HELPER',
    defaultMessage: 'Please limit the import to a maximum of only 10,000 rows.',
  },
  REQUEST_DATE: {
    id: 'request-date.text',
    defaultMessage: 'Request date',
  },
  REQUEST_TIME: {
    id: 'request-time.text',
    defaultMessage: 'Request time',
  },
  DEPOSIT_AMOUNT: {
    id: 'deposit-amount.text',
    defaultMessage: 'Deposit Amount',
  },
  MIN_DEPOSIT_AMOUNT: {
    id: 'minimum-deposit-amount.text',
    defaultMessage: 'Minimum deposit amount',
  },
  MAX_DEPOSIT_AMOUNT: {
    id: 'maximum-deposit-amount.text',
    defaultMessage: 'Maximum deposit amount',
  },
  DOWNLOAD_CSV: {
    id: 'DOWNLOAD_CSV',
    defaultMessage: 'Download CSV',
  },
  EXPORT_EXCEL: {
    id: 'EXPORT_EXCEL',
    defaultMessage: 'Export Excel',
  },
  FEE_DEDUCTION: {
    id: 'fee-deduction.text',
    defaultMessage: 'Fee Deduction',
  },
  COMPLIANCE_CHECK: {
    id: 'compliance-check.text',
    defaultMessage: 'Compliance Check',
  },
  PAYOUT_AMOUNT: {
    id: 'payout-amount.text',
    defaultMessage: 'Payout Amount',
  },
  ELAPSED_TIME: {
    id: 'elapsed-time.text',
    defaultMessage: 'Elapsed Time',
  },
  CREATE_PROGRAMME: {
    id: 'CREATE_PROGRAMME',
    defaultMessage: 'Create Programme',
  },
  WITHDRAWAL_FEE: {
    id: 'WITHDRAWAL_FEE',
    defaultMessage: 'Withdrawal fee',
  },
  WITHDRAWAL_CLAWBACK_FEE: {
    id: 'WITHDRAWAL_CLAWBACK_FEE',
    defaultMessage: 'Withdrawal Clawback Fee',
  },
  WITHDRAWAL: {
    id: 'WITHDRAWAL',
    defaultMessage: 'Withdrawal',
  },
  PROMO_PAYOUT: {
    id: 'PROMO_PAYOUT',
    defaultMessage: 'Promo Payout',
  },
  REBATE_PAYOUT: {
    id: 'REBATE_PAYOUT',
    defaultMessage: 'Rebate Payout',
  },
  REBATE_PROGRAMME_NAME: {
    id: 'REBATE_PROGRAMME_NAME',
    defaultMessage: 'Rebate Programme Name',
  },
  AFFILIATE_COMMISSION: {
    id: 'AFFILIATE_COMMISSION',
    defaultMessage: 'Affiliate Commission',
  },
  MEMBER_LOYALTY_PAYOUT: {
    id: 'MEMBER_LOYALTY_PAYOUT',
    defaultMessage: 'Member Loyalty Payout',
  },
  VENDOR_TRANSFER: {
    id: 'VENDOR_TRANSFER',
    defaultMessage: 'Vendor Transfer',
  },
  DEBIT_VENDOR_TRANSFER: {
    id: 'DEBIT_VENDOR_TRANSFER',
    defaultMessage: 'Debit transfer',
  },
  CREDIT_VENDOR_TRANSFER: {
    id: 'CREDIT_VENDOR_TRANSFER',
    defaultMessage: 'Credit transfer',
  },
  REFUND_CREDIT_VENDOR_TRANSFER: {
    id: 'REFUND_CREDIT_VENDOR_TRANSFER',
    defaultMessage: 'Refund credit transfer',
  },
  BET: {
    id: 'BET',
    defaultMessage: 'Bet',
  },
  BALANCE_ADJUSTMENT: {
    id: 'BALANCE_ADJUSTMENT',
    defaultMessage: 'Balance adjustment',
  },
  BALANCE_ADJUSTMENT_REMARKS: {
    id: 'reports.balance-adjustment-remarks.text',
    defaultMessage: 'Balance Adjustment Remarks',
  },

  PAYOUT: {
    id: 'PAYOUT',
    defaultMessage: 'Payout',
  },
  REBATE_COMMISSION: {
    id: 'REBATE_COMMISSION',
    defaultMessage: 'Rebate commission',
  },
  SYSTEM: {
    id: 'SYSTEM',
    defaultMessage: 'System',
  },
  ADMIN: {
    id: 'ADMIN',
    defaultMessage: 'Admin',
  },
  WITHDRAWAL_PROVIDERS: {
    id: 'withdrawal-providers.text',
    defaultMessage: 'Withdrawal Providers',
  },
  THIRD_PARTY_DEPOSIT_PROVIDERS: {
    id: 'deposit-providers-external.text',
    defaultMessage: '3rd Party Deposit Providers',
  },
  MIN: {
    id: 'MIN',
    defaultMessage: 'Min',
  },
  MAX: {
    id: 'MAX',
    defaultMessage: 'Max',
  },
  ID: {
    id: 'id.text',
    defaultMessage: 'ID',
  },
  'actions.text': {
    id: 'actions.text',
    defaultMessage: 'Actions',
  },
  INITIATOR: {
    id: 'reports.initiator.text',
    defaultMessage: 'Initiator',
  },
  ONLINE_BANK_TRANSFER: {
    id: 'online-bank-transfer.text',
    defaultMessage: 'Online Bank transfer',
  },
  ONLINE_ALIPAY: {
    id: 'online-alipay.text',
    defaultMessage: 'Online Alipay',
  },
  ONLINE_WECHAT: {
    id: 'online-wechat.text',
    defaultMessage: 'Online WeChat',
  },
  WITHDRAWAL_REQUEST_REJECTED: {
    id: 'reports.withdrawal-request-rejected',
    defaultMessage: 'Withdrawal Request Rejected',
  },
  'custom-columns.text': {
    id: 'custom-columns.text',
    defaultMessage: 'Custom Columns',
  },
  'refres-table.text': {
    id: 'refres-table.text',
    defaultMessage: 'Refresh Table',
  },
  VIP_TIER: {
    id: 'vip-tier.text',
    defaultMessage: 'VIP Tier',
  },
  'promo-request.request-id.text': {
    id: 'promo-request.request-id.text',
    defaultMessage: 'Request ID',
  },
  'promo-request.process-started.text': {
    id: 'promo-request.process-started.text',
    defaultMessage: 'Promo request process started',
  },
  REAL_NAME: {
    defaultMessage: 'Real Name',
    id: 'real-name.text',
  },
  PROMOTION_NAME: {
    defaultMessage: 'Promotion Name',
    id: 'promotion-name.text',
  },
  PROMO_TYPE: {
    defaultMessage: 'Promo Type',
    id: 'promo-type.text',
  },
  'date-time.text': {
    id: 'date-time.text',
    defaultMessage: 'Date/Time',
  },
  OPERATOR: {
    defaultMessage: 'Operator',
    id: 'operator.text',
  },
  OPERATOR_REMARK: {
    id: 'OPERATOR_REMARK',
    defaultMessage: 'Operator Remark',
  },
  IP_ADDRESS: {
    id: 'ip-address.text',
    defaultMessage: 'IP Address',
  },
  IP_ADDRESS_COUNTRY: {
    id: 'ip-address-country.text',
    defaultMessage: 'IP Address Country',
  },
  IP_ADDRESS_LOCATION: {
    id: 'IP_ADDRESS_LOCATION',
    defaultMessage: 'IP Address Location',
  },
  LAST_TIME_IP_USED: {
    id: 'LAST_TIME_IP_USED',
    defaultMessage: 'Last Date/Time IP Address was Used',
  },
  UPGRADE: {
    id: 'UPGRADE',
    defaultMessage: 'Upgrade',
  },
  ANNUAL: {
    id: 'ANNUAL',
    defaultMessage: 'Annual',
  },
  BIRTHDAY: {
    id: 'BIRTHDAY',
    defaultMessage: 'Birthday',
  },
  OTHER: {
    id: 'OTHER',
    defaultMessage: 'Other',
  },
  PROMO_REQUESTS: {
    id: 'promo-requests.text',
    defaultMessage: 'Promo Requests',
  },
  VENDOR: {
    id: 'vendor.text',
    defaultMessage: 'Vendor',
  },
  VENDORS: {
    id: 'report.vendors.text',
    defaultMessage: 'Vendors',
  },
  VENDOR_GAME_CATEGORY: {
    id: 'vendor-game-category.text',
    defaultMessage: 'Vendor Game Category',
  },
  CREATED_BY: {
    id: 'created-by.text',
    defaultMessage: 'Created By',
  },
  CREATED_DATE: {
    id: 'created-date.text',
    defaultMessage: 'Created Date',
  },
  TIMEZONE_SELECTION: {
    id: 'reports.timezone-selection.text',
    defaultMessage: 'Timezone Selection',
  },

  SELECT_TIMEZONE: {
    id: 'select-timezone.text',
    defaultMessage: 'Select Timezone',
  },

  PERIOD: {
    id: 'period.text',
    defaultMessage: 'Period',
  },
  BET_STATUS: {
    id: 'bet-status.text',
    defaultMessage: 'Bet Status',
  },
  SCHEDULE_TIME: {
    id: 'reports.schedule-time.text',
    defaultMessage: 'Schedule Time',
  },
  SETTLED: {
    id: 'bets.settled.text',
    defaultMessage: 'Settled',
  },
  UNSETTLED: {
    id: 'bets.unsettled.text',
    defaultMessage: 'Unsettled',
  },
  FILTER: {
    id: 'filter.text',
    defaultMessage: 'Filter',
  },
  FILTERS: {
    id: 'filters.text',
    defaultMessage: 'Filters',
  },
  'reports.successfully-generated-report.text': {
    id: 'reports.successfully-generated-report.text',
    defaultMessage: 'Successfully Generated Report',
  },
  SCHEDULED_REPORT_SUCCESS: {
    id: 'reports.successfully-scheduled-report.text',
    defaultMessage: 'Successfully Scheduled Report',
  },
  CLIENT_ACCOUNTS: {
    id: 'reports.client-accounts.text',
    defaultMessage: 'Client Accounts',
  },
  DELETED_REPORT_SUCCESS: {
    id: 'reports.successfully-deleted-report.text',
    defaultMessage: 'Successfully Cancelled Report',
  },
  CANCEL_SCHEDULE: {
    id: 'reports.cancel-schedule.text',
    defaultMessage: 'Cancel Schedule',
  },
  CANCEL_SCHEDULE_SUCCESS: {
    id: 'reports.cancel-schedule-success.text',
    defaultMessage: 'Cancel Schedule Success',
  },
  CANCEL_SCHEDULE_ERROR: {
    id: 'report.cancel-schedule-error.text',
    defaultMessage: 'Cancel Schedule Error',
  },
  'report.confirm=cancel-schedule-message.text': {
    id: 'report.confirm=cancel-schedule-message.text',
    defaultMessage: 'Are you sure you want to cancel schedule?',
  },
  FIELD_LABEL: {
    id: 'field_label.text',
    defaultMessage: 'Field Label',
  },
  FIELD_MANDATORY: {
    id: 'field_mandatory.text',
    defaultMessage: 'Mandatory',
  },
  FIELD_ADD_ACTION: {
    id: 'field_add_action.text',
    defaultMessage: 'Add this field',
  },
  AFFILIATE_CREATE_HEADER: {
    id: 'affiliate_create_header.text',
    defaultMessage: 'Create Agent Affiliate Programme',
  },
  'affiliate_create_relative.text': {
    id: 'affiliate_create_relative.text',
    defaultMessage: 'Relative',
  },
  RELATIVE: {
    id: 'RELATIVE',
    defaultMessage: 'Relative',
  },
  ABSOLUTE: {
    id: 'absolute.text',
    defaultMessage: 'Absolute',
  },
  'affiliate_create_absolute.text': {
    id: 'affiliate_create_absolute.text',
    defaultMessage: 'Absolute',
  },
  APPLY_TO_ALL_GAMES: {
    id: 'apply_to_all_games.text',
    defaultMessage: 'Apply to all games below',
  },
  GAME_REBATE_RATIO: {
    id: 'game_rebate_ratio.text',
    defaultMessage: 'Game Rebate Ratio',
  },
  RESULTS: {
    id: 'results.text',
    defaultMessage: 'Results',
  },
  APPLY: {
    id: 'apply.text',
    defaultMessage: 'Apply',
  },
  GOD_OF_WEALTH: {
    id: 'god-of-wealth.text',
    defaultMessage: 'God of Wealth',
  },
  'select-timezone.text': {
    id: 'select-timezone.text',
    defaultMessage: 'Select Timezone',
  },
  NO_DATA: {
    id: 'NO_DATA',
    defaultMessage: 'No Data',
  },
  GEOFENCING_EXCLUDE: {
    id: 'geofencing.exclude.text',
    defaultMessage: 'Exclude',
  },
  GEOFENCING_TARGETING: {
    id: 'geofencing.targeting.text',
    defaultMessage: 'Targeting',
  },
  'negative-figures-not-allowed.text': {
    id: 'negative-figures-not-allowed.text',
    defaultMessage: 'Negative figures are not allowed',
  },
  'maximum-count-decimal-places.text': {
    id: 'maximum-count-decimal-places.text',
    defaultMessage: 'Maximum of {count} decimal places',
  },
  'amount-keyed-in-greater-than-count.text': {
    id: 'amount-keyed-in-greater-than-count.text',
    defaultMessage: 'Amount keyed in must be equal or more than {count}',
  },
  AMOUNT_MIN_MORE_THAN: {
    id: 'AMOUNT_MIN_MORE_THAN',
    defaultMessage: 'Amount keyed in must be more than {count}',
  },
  AMOUNT_MIN: {
    id: 'AMOUNT_MIN',
    defaultMessage: 'Amount keyed in must be equal or more than {count}',
  },
  TURNOVER_MIN: {
    id: 'TURNOVER_MIN',
    defaultMessage: 'Turnover keyed in must be equal or more than {count}',
  },
  'withdrawal-account-deleted.text': {
    id: 'withdrawal-account-deleted.text',
    defaultMessage: 'Withdrawal account deleted',
  },
  PAYOUT_MANUAL_ADJUSTMENT: {
    id: 'PAYOUT_MANUAL_ADJUSTMENT',
    defaultMessage: 'Payout Manual Adjustment',
  },
  PROMO_PAYOUT_MANUAL_ADJUSTMENT: {
    id: 'PROMO_PAYOUT_MANUAL_ADJUSTMENT',
    defaultMessage: 'Promo Payout Manual Adjustment',
  },
  SYSTEM_MANUAL_ADJUSTMENT: {
    id: 'SYSTEM_MANUAL_ADJUSTMENT',
    defaultMessage: 'System Manual Adjustment',
  },
  EXTERNAL: {
    id: 'EXTERNAL',
    defaultMessage: 'External',
  },
  EXTERNAL_PROGRAMME: {
    id: 'EXTERNAL_PROGRAMME',
    defaultMessage: 'External Programme',
  },
  EXTERNAL_PROGRAMME_MANUAL_ADJUSTMENT: {
    id: 'EXTERNAL_PROGRAMME_MANUAL_ADJUSTMENT',
    defaultMessage: 'External Programme',
  },
  SYSTEM_CREDIT_MANUAL_ADJUSTMENT: {
    id: 'SYSTEM_CREDIT_MANUAL_ADJUSTMENT',
    defaultMessage: 'System Credit',
  },
  SYSTEM_DEBIT_MANUAL_ADJUSTMENT: {
    id: 'SYSTEM_DEBIT_MANUAL_ADJUSTMENT',
    defaultMessage: 'System Debit',
  },
  DEBIT_VENDOR_TRANSFER_MANUAL_ADJUSTMENT: {
    id: 'DEBIT_VENDOR_TRANSFER_MANUAL_ADJUSTMENT',
    defaultMessage: 'Debit Vendor Transfer Manual Adjustment',
  },
  CREDIT_VENDOR_TRANSFER_MANUAL_ADJUSTMENT: {
    id: 'CREDIT_VENDOR_TRANSFER_MANUAL_ADJUSTMENT',
    defaultMessage: 'Credit Vendor Transfer Adjustment',
  },
  DEPOSIT_MANUAL_ADJUSTMENT: {
    id: 'DEPOSIT_MANUAL_ADJUSTMENT',
    defaultMessage: 'Deposit Manual Adjustment',
  },
  WITHDRAWAL_MANUAL_ADJUSTMENT: {
    id: 'WITHDRAWAL_MANUAL_ADJUSTMENT',
    defaultMessage: 'Withdrawal Manual Adjustment',
  },
  PROMO_DEPOSIT_BONUS: {
    id: 'PROMO_DEPOSIT_BONUS',
    defaultMessage: 'Promo Deposit Bonus',
  },
  PROMO_FUNDS_TRANSFER: {
    id: 'PROMO_FUNDS_TRANSFER',
    defaultMessage: 'Promo Funds Transfer',
  },
  PROMO_BONUS_WON: {
    id: 'PROMO_BONUS_WON',
    defaultMessage: 'Promo Bonus Won',
  },
  PROMO_BONUS_LOST: {
    id: 'PROMO_BONUS_LOST',
    defaultMessage: 'Promo Bonus Lost',
  },
  PROMO_BONUS_CANCELED: {
    id: 'PROMO_BONUS_CANCELED',
    defaultMessage: 'Promo Bonus Canceled',
  },
  PROMO_BONUS_EXPIRED: {
    id: 'PROMO_BONUS_EXPIRED',
    defaultMessage: 'Promo Bonus Expired',
  },
  PROMO_WON: {
    id: 'PROMO_WON',
    defaultMessage: 'Promo Won',
  },
  PROMO_LOST: {
    id: 'PROMO_LOST',
    defaultMessage: 'Promo Lost',
  },
  PROMO_CANCELED: {
    id: 'PROMO_CANCELED',
    defaultMessage: 'Promo Canceled',
  },
  PROMO_EXPIRED: {
    id: 'PROMO_EXPIRED',
    defaultMessage: 'Promo Expired',
  },
  PROMO_REFUND: {
    id: 'PROMO_REFUND',
    defaultMessage: 'Promo Refund',
  },
  CRYPTOCURRENCY: {
    id: 'CRYPTOCURRENCY',
    defaultMessage: 'Crypto: USDT',
  },
  SLOT: {
    id: 'SLOT',
    defaultMessage: 'Slot',
  },
  LIVE: {
    id: 'LIVE',
    defaultMessage: 'Live',
  },
  POKER: {
    id: 'POKER',
    defaultMessage: 'Poker',
  },
  FISH: {
    id: 'FISH',
    defaultMessage: 'Fish',
  },
  SPORTSBOOK: {
    id: 'SPORTSBOOK',
    defaultMessage: 'Sportsbook',
  },
  LOTTERY: {
    id: 'LOTTERY',
    defaultMessage: 'Lottery',
  },

  // SuperAdmin
  DELETE_VENDOR_GROUP_TITLE: {
    id: 'DELETE_VENDOR_GROUP_TITLE',
    defaultMessage: 'Delete Vendor Group',
  },
  DELETE_CONFIRMATION_BODY_TEXT: {
    id: 'DELETE_CONFIRMATION_BODY_TEXT',
    defaultMessage:
      'Are you sure you want to delete? This action is irreversible.',
  },
  VENDOR_DELETED_SUCCESSFULLY: {
    id: 'VENDOR_DELETED_SUCCESSFULLY',
    defaultMessage: 'Vendor deleted successfully',
  },
  DUPLICATE_VENDOR_GROUP_TITLE: {
    id: 'DUPLICATE_VENDOR_GROUP_TITLE',
    defaultMessage: 'Duplicate Vendor Group',
  },
  DUPLICATE_VENDOR_GROUP_CONFIRMATION_BODY_TEXT: {
    id: 'DUPLICATE_VENDOR_GROUP_CONFIRMATION_BODY_TEXT',
    defaultMessage: 'Are you sure you want to duplicate this vendor group?',
  },
  VENDOR_DUPLICATED_SUCCESSFULLY: {
    id: 'VENDOR_DUPLICATED_SUCCESSFULLY',
    defaultMessage: 'Vendor duplicated successfully',
  },
  PAGE_ERROR: {
    id: 'PAGE_ERROR',
    defaultMessage: 'This page is currently unavailable.',
  },
  FUN_GAMING: {
    id: 'FUN_GAMING',
    defaultMessage: 'Fun Gaming',
  },

  IM: {
    id: 'IM',
    defaultMessage: 'IM',
  },

  NW: {
    id: 'NW',
    defaultMessage: 'NW',
  },
  IGAMING: {
    id: 'IGAMING',
    defaultMessage: 'IGAMING',
  },
  SABA_SPORTS: {
    id: 'SABA_SPORTS',
    defaultMessage: 'SABA SPORTS',
  },
  KY: {
    id: 'KY',
    defaultMessage: 'KY',
  },
  JDB_TRANSFER: {
    id: 'JDB_TRANSFER',
    defaultMessage: 'JDB Transfer',
  },
  EVO: {
    id: 'EVO',
    defaultMessage: 'EVO',
  },
  AG: {
    id: 'AG',
    defaultMessage: 'AG',
  },
  SPADEGAMING_TRANSFER: {
    id: 'SPADEGAMING_TRANSFER',
    defaultMessage: 'SPADEGAMING Transfer',
  },
  SBO: {
    id: 'SBO',
    defaultMessage: 'SBO',
  },

  BBIN: {
    id: 'BBIN',
    defaultMessage: 'BBIN',
  },
  BG: {
    id: 'BG',
    defaultMessage: 'BG',
  },
  CQ9: {
    id: 'CQ9',
    defaultMessage: 'CQ9',
  },
  HABANERO: {
    id: 'HABANERO',
    defaultMessage: 'HABANERO',
  },
  LC: {
    id: 'LC',
    defaultMessage: 'LC',
  },
  LG: {
    id: 'LG',
    defaultMessage: 'LG',
  },
  EBET: {
    id: 'EBET',
    defaultMessage: 'EBET',
  },
  LB_KENO: {
    id: 'LB_KENO',
    defaultMessage: 'LB KENO',
  },
  CMD: {
    id: 'CMD',
    defaultMessage: 'CMD',
  },
  PT: {
    id: 'PT',
    defaultMessage: 'PT',
  },
  FGHB: {
    id: 'FGHB',
    defaultMessage: 'FGHB',
  },
  SGWIN: {
    id: 'SGWIN',
    defaultMessage: 'SGWIN',
  },
  JDB: {
    id: 'JDB',
    defaultMessage: 'JDB',
  },
  SPADEGAMING: {
    id: 'SPADEGAMING',
    defaultMessage: 'SPADEGAMING',
  },
  PGSOFT: {
    id: 'PGSOFT',
    defaultMessage: 'PGSOFT',
  },
  _65G: {
    id: '_65G',
    defaultMessage: '365G',
  },
  MG_: {
    id: 'MG_',
    defaultMessage: 'MG+',
  },
  CANCELLED: {
    id: 'CANCELLED',
    defaultMessage: 'Cancelled',
  },
  WEB: {
    id: 'WEB',
    defaultMessage: 'Web',
  },
  MEMBER_MANAGEMENT_MEMBER_LOYALTY_LEVEL_UPDATE_SUCCESS: {
    id: 'MEMBER_MANAGEMENT_MEMBER_LOYALTY_LEVEL_UPDATE_SUCCESS',
    defaultMessage: 'Member loyalty level successfully updated',
  },
  EDIT_VIP: {
    id: 'EDIT_VIP',
    defaultMessage: 'Edit VIP',
  },
  QQ_NUMBER: {
    id: 'QQ_NUMBER',
    defaultMessage: 'QQ number',
  },
  DEPOSITS: {
    id: 'deposits.text',
    defaultMessage: 'Deposits',
  },
  NEW_SIGN_UP: {
    id: 'NEW_SIGN_UP',
    defaultMessage: 'New Sign-up',
  },
  DEPOSIT_PROMO: {
    id: 'DEPOSIT_PROMO',
    defaultMessage: 'Deposit Promo',
  },
  WITHDRAWAL_PROVIDER_DELETED: {
    id: 'WITHDRAWAL_PROVIDER_DELETED',
    defaultMessage: 'Withdrawal provider deleted',
  },
  REQUEST_TIMEOUT: {
    id: 'REQUEST_TIMEOUT',
    defaultMessage: 'Request Timeout',
  },
  TEASER_PROMOTION: {
    id: 'TEASER_PROMOTION',
    defaultMessage: 'Teaser Promotion',
  },
  DASHBOARDS: {
    id: 'dashboard.text',
    defaultMessage: 'Dashboard',
  },
  INSTANT_FOCUS: {
    id: 'instant-focus.text', // not found
    defaultMessage: 'Instant Focus',
  },
  OPERATORS: {
    id: 'operators.text',
    defaultMessage: 'Operators',
  },
  ACCOUNT_MANAGEMENT: {
    id: 'account-management.text',
    defaultMessage: 'Account Management',
  },
  LIST: {
    id: 'list.text', // not found
    defaultMessage: 'List',
  },
  CREATE: {
    id: 'create.text',
    defaultMessage: 'Create',
  },
  DUPLICATE: {
    id: 'duplicate.text',
    defaultMessage: 'Duplicate',
  },
  MEMBER_MANAGEMENT: {
    id: 'member-management.text',
    defaultMessage: 'Member Management',
  },
  VIEW_DETAILS: {
    id: 'view-details.text',
    defaultMessage: 'View Details',
  },
  WALLET_BALANCE: {
    id: 'member-detail.wallet-balance.text',
    defaultMessage: 'Wallet Balance',
  },
  TO_BALANCE: {
    id: 'T/O-balance.text',
    defaultMessage: 'T/O Balance',
  },
  LAST_DEPOSIT: {
    id: 'member-detail.last-deposit.text',
    defaultMessage: 'Last Deposit',
  },
  LAST_WITHDRAWAL: {
    id: 'member-detail.last-withdrawal.text',
    defaultMessage: 'Last Withdrawal',
  },
  LAST_PROMO_APPLIED: {
    id: 'member-detail.last-promo-applied.text',
    defaultMessage: 'Last Promo Applied',
  },
  LAST_PLAYED_GAMES: {
    id: 'member-detail.last-played-games.text',
    defaultMessage: 'Last Played Games',
  },
  AGENT_AFFILIATE: {
    id: 'member.agent-affiliate.text',
    defaultMessage: 'Agent Affiliate',
  },
  MESSAGES: {
    id: 'messages.text',
    defaultMessage: 'Messages',
  },
  EDIT_BALANCE: {
    id: 'edit-balance.text',
    defaultMessage: 'Edit Balance',
  },
  EDIT_LABEL: {
    id: 'edit-label.text',
    defaultMessage: 'Edit Label',
  },
  EDIT_STATUS: {
    id: 'edit-status.text',
    defaultMessage: 'Edit Status',
  },
  EDIT_MEMBER: {
    id: 'edit-member.text',
    defaultMessage: 'Edit Member',
  },
  EDIT_MEMBER_MARKER: {
    id: 'edit-member-marker.text',
    defaultMessage: 'Edit Member Marker',
  },
  CHANGE_WITHDRAWAL_PASSWORD: {
    id: 'change-withdrawal-password.text',
    defaultMessage: 'Change Withdrawal Password',
  },
  MEMBER_MARKER_MANAGEMENT: {
    id: 'member-level-management.text',
    defaultMessage: 'Member Marker Management',
  },
  TOGGLE_ACTIVATION: {
    id: 'toggle-activation.text', // not found
    defaultMessage: 'Toggle Activation',
  },
  LABEL_MANAGEMENT: {
    id: 'member-label-management.text',
    defaultMessage: 'Label Management',
  },
  MEMBERS_ONLINE: {
    id: 'members-online.text',
    defaultMessage: 'Members Online',
  },
  AUTOMATION: {
    id: 'automation.text',
    defaultMessage: 'Automation',
  },
  AFFILIATE_PROGRAMME: {
    id: 'affiliate-programme.text',
    defaultMessage: 'Affiliate Programme',
  },
  AFFILIATE_CRITERIA: {
    id: 'agent-affiliate-criteria.text',
    defaultMessage: 'Affiliate Criteria',
  },
  APPROVE: {
    id: 'approve.text',
    defaultMessage: 'Approve',
  },
  PROCESS: {
    id: 'process.text',
    defaultMessage: 'Process',
  },
  INTERNAL_DEPOSITS: {
    id: 'internal-deposits.text', // not found
    defaultMessage: 'Internal Deposits',
  },
  UPDATE_REMARKS: {
    id: 'update-remarks.text',
    defaultMessage: 'Update Remarks',
  },
  EXTERNAL_DEPOSITS: {
    id: 'external-deposits.text', // not found
    defaultMessage: 'External Deposits',
  },
  PAYMENT_METHODS: {
    id: 'payment-method.text',
    defaultMessage: 'Payment Method',
  },
  VIEW_PAYMENT_SETTINGS: {
    id: 'view-payment-settings.text',
    defaultMessage: 'View Payment Settings',
  },
  EDIT_PAYMENT_SETTINGS: {
    id: 'edit-payment-settings.text',
    defaultMessage: 'Edit Payment Settings',
  },
  TOGGLE_ENABLED: {
    id: 'toggle-enable.text', // not found
    defaultMessage: 'Toggle Enable',
  },
  WITHDRAWALS: {
    id: 'withdrawals.text',
    defaultMessage: 'Withdrawals',
  },
  WITHDRAWAL_REQUESTS: {
    id: 'withdrawal-request.text',
    defaultMessage: 'Withdrawal Request',
  },
  WITHDRAWAL_METHODS: {
    id: 'withdrawal-methods.text', // not found
    defaultMessage: 'Withdrawal Methods',
  },
  '3RD_WITHDRAWAL_PROVIDERS': {
    id: '3rd-party-withdrawal-providers.text',
    defaultMessage: '3rd party withdrawal providers',
  },
  VIEW: {
    id: 'view.text',
    defaultMessage: 'View',
  },
  PROMOS: {
    id: 'promos.text',
    defaultMessage: 'Promos',
  },
  PROMO_LISTING: {
    id: 'promo-listing.text',
    defaultMessage: 'Promo Listing',
  },
  PROMO: {
    id: 'promo.text',
    defaultMessage: 'Promo',
  },
  TOGGLE_PUBLISHED: {
    id: 'toggle-publish.text', // not found
    defaultMessage: 'Toggle Publish',
  },
  CATEGORY: {
    id: 'category.text',
    defaultMessage: 'Category',
  },
  PROMO_REQUEST: {
    id: 'promo-request.text',
    defaultMessage: 'Promo Request',
  },
  REBATES: {
    id: 'rebates.text',
    defaultMessage: 'Rebates',
  },
  REBATE_GROUP: {
    id: 'rebate-group.text', // not found
    defaultMessage: 'Rebate Group',
  },
  REPORTS: {
    id: 'reports.text',
    defaultMessage: 'Reports',
  },
  DAILY_OPERATING_REPORT: {
    id: 'daily-operating-report.text',
    defaultMessage: 'Daily Operating Report',
  },
  MEMBER_BET_RECORDS: {
    id: 'member-bet-records.text',
    defaultMessage: 'Member Bet Records',
  },
  VENDOR_GAME_REPORTS: {
    id: 'vendor-game-reports.text',
    defaultMessage: 'Vendor Game Reports',
  },
  BALANCE_TRANSACTION_REPORTS: {
    id: 'balance-transaction-records.text',
    defaultMessage: 'Balance Transaction Records',
  },
  PROMO_PAYOUT_RECORDS: {
    id: 'promo-payout-records.text',
    defaultMessage: 'Promo Payout Records',
  },
  STATISTICAL_REPORTS: {
    id: 'statistical-reports.text',
    defaultMessage: 'Statistical Reports',
  },
  ANALYSIS_REPORTS: {
    id: 'analysis-reports.text',
    defaultMessage: 'Analysis Reports',
  },
  REBATES_REPORTS: {
    id: 'rebates-reports.text',
    defaultMessage: 'Rebates Reports',
  },
  LOTTERY_REPORTS: {
    id: 'lottery-reports.text',
    defaultMessage: 'Lottery Reports',
  },
  MEMBER_GAME_REPORTS: {
    id: 'member-game-reports.text',
    defaultMessage: 'Member Game Reports',
  },
  BALANCE_SERVICE_RECORD_REPORTS: {
    id: 'balance-service-record-report.text',
    defaultMessage: 'Balance Service Record Report',
  },
  SYSTEM_MANAGEMENT: {
    id: 'system-management.text',
    defaultMessage: 'System Management',
  },
  SITE_CONFIG: {
    id: 'site-configurations.text',
    defaultMessage: 'Site Configurations',
  },
  ALERTS_AND_NOTIFICATIONS: {
    id: 'alerts-and-notifications.text',
    defaultMessage: 'Alerts and Notifications',
  },
  MOBILE_APP_CONFIG: {
    id: 'mobile-app-configuration.text',
    defaultMessage: 'Mobile App Configuration',
  },
  MARQUEE_MESSAGES: {
    id: 'marquee-messages.text',
    defaultMessage: 'Marquee Messages',
  },
  ROTATING_BANNER: {
    id: 'rotating-banner.text',
    defaultMessage: 'Rotating Banner',
  },
  TOGGLE_ENABLE: {
    id: 'toggle-enable.text', // not found
    defaultMessage: 'Toggle Enable',
  },
  BANNER_SETTINGS: {
    id: 'banner-settings.text',
    defaultMessage: 'Banner Settings',
  },
  GEO_FENCING: {
    id: 'superadmin-geofencing.text',
    defaultMessage: 'Geo-Fencing',
  },
  SYSTEM_MESSAGES: {
    id: 'system-message.text',
    defaultMessage: 'System Message',
  },
  MANUAL_MESSAGE: {
    id: 'manual-message.text',
    defaultMessage: 'Manual Message',
  },
  AUTOMATIC_MESSAGE: {
    id: 'automatic-message.text',
    defaultMessage: 'Automatic Message',
  },
  TOGGLE_STATE: {
    id: 'toggle-state.text', // not found
    defaultMessage: 'Toggle State',
  },
  DEPOSIT_PROVIDER_DELETED: {
    id: 'DEPOSIT_PROVIDER_DELETED',
    defaultMessage: 'Deposit provider deleted',
  },
  ADD_VENDOR: {
    id: 'add-vendor.text',
    defaultMessage: 'Add Vendor',
  },
  MEMBER_LEVEL_MANAGEMENT_SUCCESSFULLY_ACTIVATED: {
    id: 'MEMBER_LEVEL_MANAGEMENT_SUCCESSFULLY_ACTIVATED',
    defaultMessage: 'Member level activated',
  },
  MEMBER_LEVEL_MANAGEMENT_SUCCESSFULLY_DEACTIVATED: {
    id: 'MEMBER_LEVEL_MANAGEMENT_SUCCESSFULLY_DEACTIVATED',
    defaultMessage: 'Member level deactivated',
  },
  MEMBER_LEVEL_MANAGEMENT_DEACTIVATE_IF_ZERO_MEMBERS: {
    id: 'MEMBER_LEVEL_MANAGEMENT_DEACTIVATE_IF_ZERO_MEMBERS',
    defaultMessage: 'Can deactivate only if 0 number of members',
  },
  ACTIVATE: {
    id: 'ACTIVATE',
    defaultMessage: 'Activate',
  },
  DEACTIVATE: {
    id: 'DEACTIVATE',
    defaultMessage: 'Deactivate',
  },
  REMOVE_THIS_FIELD: {
    id: 'REMOVE_THIS_FIELD',
    defaultMessage: 'Remove this field',
  },
  REMOVE_FIELD: {
    id: 'REMOVE_FIELD',
    defaultMessage: 'Remove field',
  },
  EDIT_VENDOR: {
    id: 'edit-vendor.text',
    defaultMessage: 'Edit Vendor',
  },
  PAYMENT_SOURCE: {
    id: 'payment-source.text',
    defaultMessage: 'Payment source',
  },
  WITHDRAWAL_SOURCE: {
    id: 'withdrawal-source.text',
    defaultMessage: 'Withdrawal Sources',
  },
  COPIED_TO_CLIPBOARD: {
    id: 'COPIED_TO_CLIPBOARD',
    defaultMessage: 'Copied To clipboard!',
  },
  GRAPHQL_ERROR__ACTIVE_MEMBERLOYALTYPROGRAMME_CANNOT_BE_DELETED_: {
    id: 'ACTIVE_MEMBERLOYALTYPROGRAMME_CANNOT_BE_DELETED',
    defaultMessage: 'Active MemberLoyaltyProgramme cannot be deleted.',
  },
  GRAPHQL_ERROR__SHOULD_BE_OBJECT: {
    id: 'MEMBER_CANNOT_BE_DELETED',
    defaultMessage: 'should be object',
  },
  WITHDRAWAL_PROVIDER_IS_CURRENTLY_IN_USED: {
    id: 'WITHDRAWAL_PROVIDER_IS_CURRENTLY_IN_USED',
    defaultMessage: 'Withdrawal provider is currently in used',
  },
  GRAPHQL_ERROR__MULTIPLE_MEMBER_LOYALTY_PROGRAMMES_CANNOT_BE_ACTIVE_AT_THE_SAME_TIME: {
    id:
      'GRAPHQL_ERROR__MULTIPLE_MEMBER_LOYALTY_PROGRAMMES_CANNOT_BE_ACTIVE_AT_THE_SAME_TIME',
    defaultMessage:
      'Multiple Member Loyalty Programmes cannot be active at the same time',
  },
  GRAPHQL_ERROR__CANNOT_ACTIVATE_EXPIRED_LOYALTY_PROGRAMME: {
    id: 'GRAPHQL_ERROR__CANNOT_ACTIVATE_EXPIRED_LOYALTY_PROGRAMME',
    defaultMessage: 'Cannot activate expired Loyalty Programme',
  },
  GRAPHQL_ERROR__WITHDRAWAL_PROVIDER_NAME_COPY_OF_SAMPLE_ALREADY_IN_USE: {
    id: 'GRAPHQL_ERROR__WITHDRAWAL_PROVIDER_NAME_COPY_OF_SAMPLE_ALREADY_IN_USE',
    defaultMessage: 'Withdrawal Provider name copy of sample already in use',
  },
  GRAPHQL_ERROR__MISSING_MIDPAY_DIRECT_GATEWAY_: {
    id: 'GRAPHQL_ERROR__MISSING_MIDPAY_DIRECT_GATEWAY',
    defaultMessage: 'Missing midpay direct gateway.',
  },
  GRAPHQL_ERROR__WITHDRAWAL_PROVIDER_NAME_FAKEPAY_WITHDRAWALS_ALREADY_IN_USE: {
    id:
      'GRAPHQL_ERROR__WITHDRAWAL_PROVIDER_NAME_FAKEPAY_WITHDRAWALS_ALREADY_IN_USE',
    defaultMessage:
      'Withdrawal Provider name Fakepay Withdrawals already in use',
  },
  GRAPHQL_ERROR__WITHDRAWAL_PROVIDER_IS_CURRENTLY_IN_USED_: {
    id: 'GRAPHQL_ERROR__WITHDRAWAL_PROVIDER_IS_CURRENTLY_IN_USED_',
    defaultMessage: 'Withdrawal Provider is currently in used.',
  },
  GRAPHQL_ERROR__SHOULD_BE_STRING: {
    id: 'GRAPHQL_ERROR__SHOULD_BE_STRING',
    defaultMessage: 'Should be string',
  },
  GRAPHQL_ERROR__SHOULD_NOT_BE_LONGER_THAN_32_CHARACTERS: {
    id: 'GRAPHQL_ERROR__SHOULD_NOT_BE_LONGER_THAN_32_CHARACTERS',
    defaultMessage: 'Should not be longer than 32 characters',
  },
  MAXIMUM_WITHDRAWAL_REQEUST_EXCEED_ERROR: {
    id: 'MAXIMUM_WITHDRAWAL_REQEUST_EXCEED_ERROR',
    defaultMessage:
      'Maximum Withdrawal Request amount entered cannot exceed 999999999',
  },
  PROMO_BANNER_MOBILE: {
    id: 'PROMO_BANNER_MOBILE',
    defaultMessage: 'Promo Banner Mobile',
  },
  PROMO_BANNER_DESKTOP: {
    id: 'PROMO_BANNER_DESKTOP',
    defaultMessage: 'Promo Banner Desktop',
  },
  PROMO_IMAGE_FILE_SIZE_MOBILE: {
    id: 'PROMO_IMAGE_FILE_SIZE_MOBILE',
    defaultMessage: 'Image file must be 1242px x 690px',
  },
  PROMO_IMAGE_FILE_SIZE_DESKTOP: {
    id: 'PROMO_IMAGE_FILE_SIZE_DESKTOP',
    defaultMessage: 'Image file must be 804px x 234px',
  },
  'vendor-error.text': {
    id: 'vendor-error.text',
    defaultMessage: 'Vendor is required',
  },
  BANNER_IS_REQUIRED: {
    id: 'promo.banner-is-required.text',
    defaultMessage: 'Banner is required',
  },
  'promo.category-is-required.text': {
    defaultMessage: 'Category is required',
    id: 'promo.category-is-required.text',
  },
  'promo.name-is-required.text': {
    defaultMessage: 'Name is required',
    id: 'promo.name-is-required.text',
  },
  'promo.name-must-be-less-than-15-characters.text': {
    defaultMessage:
      'Promo name must be less than or equal to 15 characters only',
    id: 'promo.name-must-be-less-than-15-characters.text',
  },
  'promo.terms-and-conditions-is-required.text': {
    defaultMessage: 'Terms and Conditions is required',
    id: 'promo.terms-and-conditions-is-required.text',
  },
  'promo.description-is-required.text': {
    defaultMessage: 'Description is required',
    id: 'promo.description-is-required.text',
  },
  PROMO_SUBTITLE_IS_REQUIRED: {
    defaultMessage: 'Promo subtitle is required',
    id: 'promo.promo-subtitle-is-required.text',
  },
  PROMO_TITLE_IS_REQUIRED: {
    defaultMessage: 'Promo Title is required',
    id: 'promo.promo-title-is-required.text',
  },
  PROMO_STATUS_IS_REQUIRED: {
    id: 'PROMO_STATUS_IS_REQUIRED',
    defaultMessage: 'Promo Status is required',
  },
  PROMO_PAYOUT_AMOUNT: {
    id: 'PROMO_PAYOUT_AMOUNT',
    defaultMessage: 'Promo payout amount must not be less than to 0',
  },
  PROMO_MESSAGE_MAX_LENGTH: {
    id: 'PROMO_MESSAGE_MAX_LENGTH',
    defaultMessage:
      'Message Content must be less than or equal to 500 characters only',
  },
  PROMO_MESSAGE_MIN_LENGTH: {
    id: 'PROMO_MESSAGE_MIN_LENGTH',
    defaultMessage:
      'Message Title must be greater than or equal to 20 characters only',
  },
  PAYMENT_CONFIGURATION_UPDATED: {
    id: 'PAYMENT_CONFIGURATION_UPDATED',
    defaultMessage: 'Payment configuration updated!',
  },
  PAYMENT_METHOD_DUPLICATED: {
    id: 'PAYMENT_METHOD_DUPLICATED',
    defaultMessage: 'Payment method duplicated',
  },
  PAYMENT_METHOD_DELETED: {
    id: 'PAYMENT_METHOD_DELETED',
    defaultMessage: 'Payment method deleted',
    description: 'Payment method deleted',
  },
  MIDPAY: {
    id: 'MIDPAY',
    defaultMessage: 'MIDPAY',
  },
  DIORPAY: {
    id: 'DIORPAY',
    defaultMessage: 'DIORPAY',
  },
  DIORPAY2: {
    id: 'DIORPAY2',
    defaultMessage: 'DIORPAY2',
  },
  MIDPAY_DIRECT: {
    id: 'MIDPAY_DIRECT',
    defaultMessage: 'MIDPAY DIRECT',
  },
  PROMO_DRAFT_REQUIREMENTS: {
    id: 'PROMO_DRAFT_REQUIREMENTS',
    defaultMessage:
      'Name, Banner and Category are required before saving draft',
  },
  'promo.promo-has-been-saved.text': {
    id: 'promo.promo-has-been-saved.text',
    defaultMessage: 'Promo has been saved',
  },
  'promo.sign-up-bonus.text': {
    id: 'promo.sign-up-bonus.text',
    defaultMessage: 'Sign up bonus',
  },
  'promo.first-deposit-promo.text': {
    id: 'promo.first-deposit-promo.text',
    defaultMessage: 'First/Deposit Promo',
  },
  'promo.loss-rebate-promo.text': {
    id: 'promo.loss-rebate-promo.text',
    defaultMessage: 'Loss Rebate Promo',
  },
  'promo.effective-bet-promo.text': {
    id: 'promo.effective-bet-promo.text',
    defaultMessage: 'EFfective Bet Promo',
  },
  'promo.general-promo.text': {
    id: 'promo.general-promo.text',
    defaultMessage: 'General Promo',
  },
  'promo.promo-successfully-publish.text': {
    id: 'promo.promo-successfully-publish.text',
    defaultMessage: 'Promo successfully Publish',
  },
  'promo.create-new-promo.text': {
    id: 'promo.create-new-promo.text',
    defaultMessage: 'Create New Promo',
  },
  'promo.promo-details.text': {
    id: 'promo.promo-details.text',
    defaultMessage: 'Promo Details',
  },
  'promo.promo-payout-setting.text': {
    id: 'promo.promo-payout-setting.text',
    defaultMessage: 'Payout Setting',
  },
  'promo.promo-description.text': {
    id: 'promo.promo-description.text',
    defaultMessage: 'Promo Description',
  },
  'promo.promotion-fields.text': {
    id: 'promo.promotion-fields.text',
    defaultMessage: 'Promotion Fields',
  },
  'promo.custom-field.text': {
    id: 'promo.custom-field.text',
    defaultMessage: 'Custom Field',
  },
  'promo.promo-message.text': {
    id: 'promo.promo-message.text',
    defaultMessage: 'Promo Message',
  },
  'promo.category.text': {
    id: 'promo.category.text',
    defaultMessage: 'Category',
  },
  PLEASE_SELECT_CATEGORY: {
    id: 'promo.please-select-category.text',
    defaultMessage: 'Please select category',
  },
  'promo.banner.text': {
    id: 'promo.banner.text',
    defaultMessage: 'Promo Banner',
  },
  'promo.active-period.text': {
    id: 'promo.active-period.text',
    defaultMessage: 'Promo Active Period',
  },
  PROMO_SETTINGS: {
    id: 'promo.settings.text',
    defaultMessage: 'Promo Settings',
  },
  AVAILABLE_SLOTS: {
    id: 'promo.available-slots.text',
    defaultMessage: 'Available Slots',
  },
  LOAD_MORE: {
    id: 'LOAD_MORE',
    defaultMessage: 'Load More',
  },
  'search-banner-name.text': {
    id: 'search-banner-name.text',
    defaultMessage: 'Search banner name',
  },
  APPROVE_PROMO_REQUEST: {
    id: 'approve-promo-request.text',
    defaultMessage: 'Promo request process has been approved',
  },
  REJECT_PROMO_REQUEST: {
    id: 'reject-promo-request.text',
    defaultMessage: 'Promo request process has been rejected',
  },
  STOP_PROCESS_PROMO_REQUEST: {
    id: 'stop-process-promo-request.text',
    defaultMessage: 'Promo request process has been stopped',
  },
  CATEGORY_NAME: {
    id: 'CATEGORY_NAME',
    defaultMessage: 'Category Name',
  },
  'category-name-required-error.text': {
    id: 'category-name-required-error.text',
    defaultMessage: 'Category name is required',
  },
  'select-least-one-category-error.text': {
    id: 'select-least-one-category-error.text',
    defaultMessage: 'Please select at least one category',
  },
  'icon-required-error.text': {
    id: 'icon-required-error.text',
    defaultMessage: 'Icon is required',
  },
  NEW_GAME_CATEGORY_ADDED: {
    id: 'new-game-category-added.text',
    defaultMessage: 'New game category added.',
  },
  GAME_CATEGORY_UPDATED: {
    id: 'game-category-updated.text',
    defaultMessage: 'Game category updated.',
  },
  GAME_VENDOR_CATEGORY: {
    id: 'GAME_VENDOR_CATEGORY',
    defaultMessage: 'Game Vendor Category',
  },
  PROMO_DOES_NOT_EXIST: {
    id: 'PROMO_DOES_NOT_EXIST',
    defaultMessage: 'Promo does not exist!',
  },
  EVERY_DYNAMIC_DAY: {
    id: 'EVERY_DYNAMIC_DAY',
    defaultMessage: 'Every {day}',
  },
  FIRST_WITHDRAWAL: {
    id: 'first-withdrawal.text',
    defaultMessage: 'First Withdrawal',
  },
  'infinity.text': {
    id: 'infinity.text',
    defaultMessage: 'Infinity',
  },
  ACCOUNT_ALREADY_EXIST: {
    id: 'ACCOUNT_ALREADY_EXIST',
    defaultMessage: 'Account already exists.',
  },
  MEMBER_LOYALTY_SUCCESS_MESSAGE: {
    id: 'member-management.member-loyalty-level-update-success.text',
    defaultMessage: 'Member loyalty level successfully updated',
  },
  'entries.text': {
    id: 'entries.text',
    defaultMessage: 'Entries',
  },
  FILE_UPLOAD_SUCCESS: {
    id: 'file-upload-success.text',
    defaultMessage: 'File Upload Success',
  },
  AUTOMATION_UPDATED: {
    id: 'automation-updated.text',
    defaultMessage: 'Automation Updated',
  },
  NEW_AUTOMATION_CREATED: {
    id: 'new-automation-created.text',
    defaultMessage: 'New Automation Created',
  },
  MEMBER_LEVEL_DELETED: {
    id: 'member-level-deleted.text',
    defaultMessage: 'Member Level Deleted',
  },
  MEMBER_LEVEL_DUPLICATED: {
    id: 'member-level-duplicated.text',
    defaultMessage: 'Member Level Duplicated',
  },
  MEMBER_DELETED: {
    id: 'member-deleted.text',
    defaultMessage: 'Member Deleted',
  },
  'successfully-saved-changes.text': {
    id: 'successfully-saved-changes.text',
    defaultMessage: 'Successfully Saved Changes',
  },
  SITE_CONFIGURATION_UPDATED: {
    id: 'site-configuration-updated.text',
    defaultMessage: 'Site Configuration Updated',
  },
  WITHDRAWAL_METHOD_DELETED: {
    id: 'withdrawal-method-deleted.text',
    defaultMessage: 'Withdrawal Method Deleted',
  },
  WITHDRAWAL_METHOD_DUPLICATED: {
    id: 'withdrawal-method-duplicated.text',
    defaultMessage: 'Withdrawal Method Duplicated',
  },
  VENDOR_GROUP_NAME_IS_REQUIRED: {
    id: 'vendor-group-name-is-required.text',
    defaultMessage: 'Vendor group name is required',
  },
  'error-on-updating-client-account.text': {
    id: 'error-on-updating-client-account.text',
    defaultMessage: 'Error on updating client account',
  },
  'reports.generated.text': {
    id: 'reports.generated.text',
    defaultMessage: 'Generated',
  },
  'reports.generating.text': {
    id: 'reports.generating.text',
    defaultMessage: 'Generating',
  },
  ACCOUNT_EXISTS: {
    id: 'ACCOUNT_EXISTS',
    defaultMessage: 'Username already exists',
  },
  NO_STARTING_NUMBER_USERNAME: {
    id: 'NO_STARTING_NUMBER_USERNAME',
    defaultMessage: 'Username cannot start with a number',
  },
  NO_SPACES_USERNAME: {
    id: 'NO_SPACES_USERNAME',
    defaultMessage: 'Username cannot contain spaces',
  },
  NO_SPACES_PASSWORD: {
    id: 'NO_SPACES_PASSWORD',
    defaultMessage: 'Password cannot contain spaces',
  },
  NO_UPPERCASE_LETTERS: {
    id: 'NO_UPPERCASE_LETTERS',
    defaultMessage: 'Username cannot contain uppercase letters',
  },
  NO_SPECIAL_CHARACTERS_AND_LOWERCASE_ONLY_USERNAME: {
    id: 'NO_SPECIAL_CHARACTERS_AND_LOWERCASE_ONLY_USERNAME',
    defaultMessage:
      'Username cannot contain special characters and cannot contain CAPS',
  },
  USERNAME_MIN_MAX_LENGTH: {
    id: 'USERNAME_MIN_MAX_LENGTH',
    defaultMessage: 'Username length should be between 4-23 characters',
  },
  EMPTY_PASSWORD: {
    id: 'EMPTY_PASSWORD',
    defaultMessage: 'Blank Password field detected',
  },
  USERNAME_DUPLICATE: {
    id: 'USERNAME_DUPLICATE',
    defaultMessage: 'Username is duplicated',
  },
  MOBILE_H5: {
    id: 'MOBILE_H5',
    defaultMessage: 'Mobile/H5',
  },
  DESKTOP: {
    id: 'DESKTOP',
    defaultMessage: 'Desktop',
  },
  'all-vendors-mobile-image-required-error.text': {
    id: 'all-vendors-mobile-image-required-error.text',
    defaultMessage: 'All vendors needs to have a mobile image',
  },
  API_DID_NOT_RESOLVE: {
    id: 'API_DID_NOT_RESOLVE',
    defaultMessage: 'API did not resolve',
  },
  FAKEPAY: {
    id: 'FAKEPAY',
    defaultMessage: 'FakePay',
  },
  FAKEPAY3: {
    id: 'FAKEPAY3',
    defaultMessage: 'FakePay3',
  },
  WANGCAIPAY: {
    id: 'WANGCAIPAY',
    defaultMessage: 'WangCaiPay',
  },
  YINLIANDAIFUPAY: {
    id: 'YINLIANDAIFUPAY',
    defaultMessage: 'YinLianDaiFuPay',
  },
  BALANCE_UNIT_SERIAL_CODE: {
    id: 'BALANCE_UNIT_SERIAL_CODE',
    defaultMessage: 'Balance Unit Serial Code',
  },
  CONFIRM_SAVE_DEPOSIT_CONFIG: {
    id: 'confirm-save-deposit-config.text',
    defaultMessage:
      'Do you wish to save your changes to Deposit Source Config?',
  },
  DEPOSIT_SOURCE_CONFIG: {
    id: 'deposit-source-config.text',
    defaultMessage: 'Deposit Source Config',
  },
  DEPOSIT_SOURCE_CONFIG_OFFLINE: {
    id: 'deposit-source-config-offline.text',
    defaultMessage: 'Deposit Source Config (Offline)',
  },
  DEPOSIT_SOURCE_CONFIG_OFFLINE_BANK_ONLY: {
    id: 'deposit-source-config-offline-bank-only.text',
    defaultMessage: 'Deposit Source Config (Offline Bank Only)',
  },
  MANDATORY: {
    id: 'mandatory.text',
    defaultMessage: 'Mandatory',
  },
  OPTIONAL: {
    id: 'optional.text',
    defaultMessage: 'Optional',
  },
  HIDDEN: {
    id: 'hidden.text',
    defaultMessage: 'Hidden',
  },
  DEPOSIT_SOURCE_LISTING: {
    id: 'deposit-source-listing.text',
    defaultMessage: 'Deposit Source Listing',
  },
  ADD_NEW: {
    id: 'add-new.text',
    defaultMessage: 'Add New',
  },
  ADD_NEW_DEPOSIT_SOURCE: {
    id: 'add-new-deposit-source.text',
    defaultMessage: 'Add New Deposit Source',
  },
  DISPLAY_NAME: {
    id: 'display-name.text',
    defaultMessage: 'Display Name',
  },
  DISPLAY: {
    id: 'display.text',
    defaultMessage: 'Display',
  },
  BRING_TO_TOP: {
    id: 'bring-to-top.text',
    defaultMessage: 'Bring to Top',
  },
  BRING_TO_LAST: {
    id: 'bring-to-last.text',
    defaultMessage: 'Bring to Last',
  },
  ENGLISH_DISPLAY_NAME: {
    id: 'english-display-name.text',
    defaultMessage: 'English Display Name',
  },
  CHINESE_DISPLAY_NAME: {
    id: 'chinese-display-name.text',
    defaultMessage: 'Chinese Display Name',
  },
  NEED_BANK_SOURCE_INPUT: {
    id: 'need-bank-source-input.text',
    defaultMessage: 'Need Bank Source Input',
  },
  DELETE_CONFIRMATION_COLUMN: {
    id: 'DELETE_CONFIRMATION_COLUMN',
    defaultMessage: 'Do you want to delete this column?',
  },
  CANNOT_DELETE_WITH_EXISTING_PROMOS: {
    id: 'CANNOT_DELETE_WITH_EXISTING_PROMOS',
    defaultMessage: 'Cannot delete category with existing promos',
  },
  GRAPHQL_ERROR__PROMOCATEGORY_WITH_THE_SAME_NAME_ALREADY_EXISTS_: {
    id: 'GRAPHQL_ERROR__PROMOCATEGORY_WITH_THE_SAME_NAME_ALREADY_EXISTS_',
    defaultMessage: 'Promo Category with the same name already exists.',
  },
  TOTAL_LIFETIME_EFFECTIVE_BET: {
    id: 'TOTAL_LIFETIME_EFFECTIVE_BET',
    defaultMessage: 'Total Lifetime Effective Bet',
  },
  REGISTRATION_INDICATOR: {
    id: 'REGISTRATION_INDICATOR',
    defaultMessage: 'Registration Indicator',
  },
  LAST_LOGIN_DOMAIN: {
    id: 'LAST_LOGIN_DOMAIN',
    defaultMessage: 'Last Login Domain',
  },
  LAST_LOGIN_IP_ADDRESS_LOCATION: {
    id: 'LAST_LOGIN_IP_ADDRESS_LOCATION',
    defaultMessage: 'Last Login Ip Address Location',
  },
  LAST_LOGIN_IP_ADDRESS: {
    id: 'LAST_LOGIN_IP_ADDRESS',
    defaultMessage: 'Last Login Ip Address',
  },
  LAST_LOGIN_USER_AGENT: {
    id: 'LAST_LOGIN_USER_AGENT',
    defaultMessage: 'Last Login User Agent',
  },
  LAST_LOGIN_DEVICE_OS_VERSION: {
    id: 'LAST_LOGIN_DEVICE_OS_VERSION',
    defaultMessage: 'Last Login Device/OS Version',
  },
  LAST_LOGIN_DEVICE_UDID_UNIQUE_IDENTIFIER: {
    id: 'LAST_LOGIN_DEVICE_UDID_UNIQUE_IDENTIFIER',
    defaultMessage: 'Last Login Device UDID/Unique Identifier',
  },
  LAST_LOGIN_DEVICE_FINGERPRINT: {
    id: 'LAST_LOGIN_DEVICE_FINGERPRINT',
    defaultMessage: 'Last Login Device Fingerprint',
  },
  LAST_LOGIN_BROWSER: {
    id: 'LAST_LOGIN_BROWSER',
    defaultMessage: 'Last Login Browser',
  },
  LAST_LOGIN_CHANNEL: {
    id: 'LAST_LOGIN_CHANNEL',
    defaultMessage: 'Last Login Channel',
  },
  MEMBER_NAME: {
    id: 'MEMBER_NAME',
    defaultMessage: 'Member Name',
  },
  DATE_TIME_RANGE: {
    id: 'DATE_TIME_RANGE',
    defaultMessage: 'Date/Time Range',
  },
  TOTAL_COUNT: {
    id: 'TOTAL_COUNT',
    defaultMessage: 'Total Count',
  },
  SEARCH_MEMBER: {
    id: 'SEARCH_MEMBER',
    defaultMessage: 'Search Member',
  },
  COLORS: {
    id: 'COLORS',
    defaultMessage: 'Colors',
  },
  ASSOCIATED_PROMOS: {
    id: 'ASSOCIATED_PROMOS',
    defaultMessage: 'Associated Promos',
  },
  ADD_PROMO_LABEL: {
    id: 'ADD_PROMO_LABEL',
    defaultMessage: 'Add Promo Label',
  },
  EDIT_PROMO_LABEL: {
    id: 'EDIT_PROMO_LABEL',
    defaultMessage: 'Edit Promo Label',
  },
  CREATE_NEW_PROMO_LABEL: {
    id: 'CREATE_NEW_PROMO_LABEL',
    defaultMessage: 'Create New Promo Label',
  },
  PROMO_LABEL_NAME: {
    id: 'PROMO_LABEL_NAME',
    defaultMessage: 'Promo Label Name',
  },
  COLOR: {
    id: 'COLOR',
    defaultMessage: 'Color',
  },
  PROMO_LABEL_NAME_IS_REQUIRED: {
    id: 'PROMO_LABEL_NAME_IS_REQUIRED',
    defaultMessage: 'Promo Label Name is required',
  },
  PROMO_LABEL_NAME_MUST_NOT_BE_MORE_THAN_TEN_CHARACTERS: {
    id: 'PROMO_LABEL_NAME_MUST_NOT_BE_MORE_THAN_TEN_CHARACTERS',
    defaultMessage: 'Promo Label Name must not be more than 10 characters',
  },
  PROMO_LABEL_COLOR_IS_REQUIRED: {
    id: 'PROMO_LABEL_COLOR_IS_REQUIRED',
    defaultMessage: 'Promo Label Color is required',
  },
  API_NOT_YET_SUPPORTED: {
    id: 'API_NOT_YET_SUPPORTED',
    defaultMessage: 'API not yet supported',
  },

  SELECT_EXCLUDED_MEMBER_MARKERS: {
    id: 'SELECT_EXCLUDED_MEMBER_MARKERS',
    defaultMessage: 'Select Excluded Member Markers',
  },
  EXCLUDED_MEMBER_MARKERS: {
    id: 'EXCLUDED_MEMBER_MARKERS',
    defaultMessage: 'Excluded Member Markers',
  },
  SELECT_MEMBER_VIP: {
    id: 'SELECT_MEMBER_VIP',
    defaultMessage: 'Select Member VIP',
  },
  SESSION_LOGOUT: {
    id: 'SESSION_LOGOUT',
    defaultMessage: 'Session Logout',
  },
  PROMO_LABEL_SUCCESSFULLY_CREATED: {
    id: 'PROMO_LABEL_SUCCESSFULLY_CREATED',
    defaultMessage: 'Promo Label Successfully Created',
  },
  PROMO_LABEL_SUCCESSFULLY_UPDATED: {
    id: 'PROMO_LABEL_SUCCESSFULLY_UPDATED',
    defaultMessage: 'Promo Label Successfully Updated',
  },
  PROMO_LABEL_SUCCESSFULLY_DELETED: {
    id: 'PROMO_LABEL_SUCCESSFULLY_DELETED',
    defaultMessage: 'Promo Label Successfully Deleted',
  },
  DELETE_PROMO_LABEL: {
    id: 'DELETE_PROMO_LABEL',
    defaultMessage: 'Delete Promo Label',
  },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_PROMO_LABEL: {
    id: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_PROMO_LABEL',
    defaultMessage: 'Are you sure you want to delete this promo label?',
  },
  OK: {
    id: 'OK',
    defaultMessage: 'Ok',
  },
  PLEASE_ENTER_PROMO_LABEL_NAME: {
    id: 'PLEASE_ENTER_PROMO_LABEL_NAME',
    defaultMessage: ' Please Enter Promo Label Name',
  },
  PLEASE_SELECT_PROMO_LABEL_COLOR: {
    id: 'PLEASE_SELECT_PROMO_LABEL_COLOR',
    defaultMessage: ' Please Select Promo Label Color',
  },
  MODULE: {
    id: 'module.text',
    defaultMessage: 'Module',
  },
  EXTERNAL_MODULE_ID: {
    id: 'external-module-id.text',
    defaultMessage: 'External Module ID',
  },
  MEMBER_INTERACTION_LOG: {
    id: 'member-interaction-log.text',
    defaultMessage: 'Member Interaction Log',
  },
  INVALID_USER: {
    id: 'INVALID_USER',
    defaultMessage: 'Invalid user',
  },
  INTERACTION_LOG_ID: {
    id: 'interaction-log-id.text',
    defaultMessage: 'Interaction Log ID',
  },
  ACTOR: {
    id: 'actor.text',
    defaultMessage: 'Actor',
  },
  CREATED: {
    id: 'created.text',
    defaultMessage: 'Created',
  },
  SEARCH_USER: {
    id: 'SEARCH_USER',
    defaultMessage: 'Search User',
  },
  MEMBER_IP_ADDRESS_SUMMARY: {
    id: 'member-ip-address-summary.text',
    defaultMessage: 'Member IP Address Summary',
  },
  MEMBER_ACCESS_AUDIT_HISTORY: {
    id: 'member-access-audit-history.text',
    defaultMessage: 'Member Access Audit History',
  },
  IP_ADDRESS_SUMMARY: {
    id: 'ip-address-summary.text',
    defaultMessage: 'IP Address Summary',
  },
  LOGGED_IN: {
    id: 'logged-in.text',
    defaultMessage: 'Logged In',
  },
  LOGGED_OUT: {
    id: 'logged-out.text',
    defaultMessage: 'Logged Out',
  },
  PROMO_LABEL: {
    id: 'PROMO_LABEL',
    defaultMessage: 'Promo Label',
  },
  PLEASE_SELECT_PROMO_LABEL: {
    id: 'PLEASE_SELECT_PROMO_LABEL',
    defaultMessage: 'Please Select Promo Label',
  },
  MISSION_TYPE: {
    id: 'MISSION_TYPE',
    defaultMessage: 'Mission Type',
  },
  DATE_RANGE: {
    id: 'DATE_RANGE',
    defaultMessage: 'Date Range',
  },
  SERIAL_ID: {
    id: 'SERIAL_ID',
    defaultMessage: 'Serial ID',
  },
  ENROLMENT_DATE_RANGE: {
    id: 'ENROLMENT_DATE_RANGE',
    defaultMessage: 'Enrolment Date Range',
  },
  ENROLLED_MEMBERS: {
    id: 'ENROLLED_MEMBERS',
    defaultMessage: 'Enrolled Members',
  },
  NEW_MISSION_TYPE: {
    id: 'NEW_MISSION_TYPE',
    defaultMessage: 'New Mission Type',
  },
  DAILY_MISSION: {
    id: 'DAILY_MISSION',
    defaultMessage: 'Daily Mission',
  },
  DAILY_LOGIN: {
    id: 'DAILY_LOGIN',
    defaultMessage: 'Daily Login',
  },
  GENERAL_SETTINGS: {
    id: 'GENERAL_SETTINGS',
    defaultMessage: 'General Settings',
  },
  TASK_AND_REWARD_SETTINGS: {
    id: 'TASK_AND_REWARD_SETTINGS',
    defaultMessage: 'Task and Reward Settings',
  },
  MILESTONE_SETTINGS: {
    id: 'MILESTONE_SETTINGS',
    defaultMessage: 'Milestone Settings',
  },
  VIP_GROUPS: {
    id: 'VIP_GROUPS',
    defaultMessage: 'VIP Groups',
  },
  MISSION_PROGRAMME_NAME: {
    id: 'MISSION_PROGRAMME_NAME',
    defaultMessage: 'Mission Programme Name',
  },
  ACTIVATE_BY_MEMBER: {
    id: 'ACTIVATE_BY_MEMBER',
    defaultMessage: 'Activate by Member',
  },
  MISSION_SETTINGS: {
    id: 'MISSION_SETTINGS',
    defaultMessage: 'Mission Settings',
  },
  REGISTERED_IP_LIMIT_PER_CYCLE: {
    id: 'REGISTERED_IP_LIMIT_PER_CYCLE',
    defaultMessage: 'Registered IP limit per cycle',
  },
  SAME_NAME_LIMIT_PER_CYCLE: {
    id: 'SAME_NAME_LIMIT_PER_CYCLE',
    defaultMessage: 'Same Name limit per cycle',
  },
  GENERATE_REBATE: {
    id: 'GENERATE_REBATE',
    defaultMessage: 'Generate Rebate',
  },
  GENERATE_VIP_PROGRESSION: {
    id: 'GENERATE_VIP_PROGRESSION',
    defaultMessage: 'Generate VIP Progression',
  },
  NEW_PROMO_CONFLICT: {
    id: 'NEW_PROMO_CONFLICT',
    defaultMessage: 'New Promo Conflict',
  },
  PROMO_CONFLICT_NAME: {
    id: 'PROMO_CONFLICT_NAME',
    defaultMessage: 'Promo Conflict Name',
  },
  'search-member.text': {
    id: 'search-member.text',
    defaultMessage: 'Search Member',
  },
  'my-watchlist.text': {
    id: 'my-watchlist.text',
    defaultMessage: 'My Watchlist',
  },
  'date-registered.text': {
    id: 'date-registered.text',
    defaultMessage: 'Date Registered',
  },
  'last-added.text': {
    id: 'last-added.text',
    defaultMessage: 'Last Added',
  },
  'member-levels.text': {
    id: 'member-levels.text',
    defaultMessage: 'Member Levels',
  },
  NEW_PROMO_CONFLICT_GROUP_NAME: {
    id: 'NEW_PROMO_CONFLICT_GROUP_NAME',
    defaultMessage: 'New Promo Conflict Group Name',
  },
  NEXT: {
    id: 'NEXT',
    defaultMessage: 'Next',
  },
  PROMO_CONFLICT_GROUP_NAME: {
    id: 'PROMO_CONFLICT_GROUP_NAME',
    defaultMessage: 'Promo Conflict Group Name',
  },
  ENTER_PROMO_CONFLICT_GROUP_NAME: {
    id: 'ENTER_PROMO_CONFLICT_GROUP_NAME',
    defaultMessage: 'Enter Promo Conflict Group Name',
  },
  ALL: {
    id: 'ALL',
    defaultMessage: 'All',
  },
  'new-tab.text': {
    id: 'new-tab.text',
    defaultMessage: 'New Tab',
  },
  'rename-tab.text': {
    id: 'rename-tab.text',
    defaultMessage: 'Rename Tab',
  },
  AUTOMATIC: {
    id: 'automatic.text',
    defaultMessage: 'Automatic',
  },
  PROMO_LABELS: {
    id: 'PROMO_LABELS',
    defaultMessage: 'Promo Labels',
  },
  WITHDRAWAL_REQUEST_SUCCESSFUL: {
    id: 'WITHDRAWAL_REQUEST_SUCCESSFUL',
    defaultMessage: 'Withdrawal Request Successful',
  },
  CONFIRM_WITHDRAWAL_REQUEST: {
    id: 'CONFIRM_WITHDRAWAL_REQUEST',
    defaultMessage: 'Confirm Withdrawal Request',
  },
  CANCEL: {
    id: 'CANCEL',
    defaultMessage: 'Cancel',
  },
  DELETE_VIP_GROUP: {
    id: 'DELETE_VIP_GROUP',
    defaultMessage: 'Delete VIP Group',
  },
  DELETE_MILESTONE: {
    id: 'DELETE_MILESTONE',
    defaultMessage: 'Delete Milestone',
  },
  DELETE_DAY: {
    id: 'DELETE_DAY',
    defaultMessage: 'Delete Day',
  },
  DELETE_TASK: {
    id: 'DELETE_TASK',
    defaultMessage: 'Delete Task',
  },
  AUTOMATIC_APPROVAL: {
    id: 'AUTOMATIC_APPROVAL',
    defaultMessage: 'Automatic Approval',
  },
  AUTO_REGISTER: {
    id: 'AUTO_REGISTER',
    defaultMessage: 'Auto Register',
  },
  MEMBER_ACCESS_SUMMARY_REPORT: {
    id: 'MEMBER_ACCESS_SUMMARY_REPORT',
    defaultMessage: 'Member Access Summary Report',
  },
  USED_IN: {
    id: 'USED_IN',
    defaultMessage: 'Used in',
  },
  DELETE_PROMO_CONFLICT_GROUP: {
    id: 'DELETE_PROMO_CONFLICT_GROUP',
    defaultMessage: 'Delete Promo Conflict Group',
  },
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_PROMO_GROUP: {
    id: 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_PROMO_GROUP',
    defaultMessage: 'Are you sure you want to delete this promo group?',
  },
  PROMO_CONFLICT_GROUP_SUCCESSFULLY_DELETED: {
    id: 'PROMO_CONFLICT_GROUP_SUCCESSFULLY_DELETED',
    defaultMessage: 'Promo Conflict Group Successfully Deleted',
  },
  SELECT_A_PROMO_CONFLICT_GROUP: {
    id: 'SELECT_A_PROMO_CONFLICT_GROUP',
    defaultMessage: 'Select a promo conflict group',
  },
  NO_PROMO_DATA: {
    id: 'NO_PROMO_DATA',
    defaultMessage: 'No Promo Data',
  },
  PLEASE_ENTER_A_PROMO_CONFLICT_GROUP_NAME: {
    defaultMessage: 'Please enter a conflict group name',
    id: 'PLEASE_ENTER_A_PROMO_CONFLICT_GROUP_NAME',
  },
  COLOR_PINK: {
    defaultMessage: 'PINK',
    id: 'COLOR_PINK',
  },
  COLOR_PURPLE: {
    defaultMessage: 'PURPLE',
    id: 'COLOR_PURPLE',
  },
  COLOR_BLUE: {
    defaultMessage: 'BLUE',
    id: 'COLOR_BLUE',
  },
  COLOR_GREEN: {
    defaultMessage: 'GREEN',
    id: 'COLOR_GREEN',
  },
  COLOR_ORANGE: {
    defaultMessage: 'ORANGE',
    id: 'COLOR_ORANGE',
  },
  COLOR_BROWN: {
    defaultMessage: 'BROWN',
    id: 'COLOR_BROWN',
  },
  COLOR_RED: {
    defaultMessage: 'RED',
    id: 'COLOR_RED',
  },
  EDIT_PROMO_CONFLICT_GROUP: {
    id: 'EDIT_PROMO_CONFLICT_GROUP',
    defaultMessage: 'Edit Promo Conflict Group',
  },
  LAST_LOGIN_DATE_TIME_REPORT: {
    id: 'LAST_LOGIN_DATE_TIME_REPORT',
    defaultMessage: 'Last Login Date Time',
  },
  IP_ADDRESS_TOTAL_COUNT: {
    id: 'IP_ADDRESS_TOTAL_COUNT',
    defaultMessage: 'Ip Address Total Count',
  },
  'mobile_number.text': {
    id: 'mobile_number.text',
    defaultMessage: 'MOBILE NUMBER',
  },
  'we_chat_id.text': {
    id: 'we_chat_id.text',
    defaultMessage: 'WE CHAT ID',
  },
  'real_name.text': {
    id: 'real_name.text',
    defaultMessage: 'REAL NAME',
  },
  'qq_id.text': {
    id: 'qq_id.text',
    defaultMessage: 'QQ ID',
  },
  'date_of_birth.text': {
    id: 'date_of_birth.text',
    defaultMessage: 'DATE OF BIRTH',
  },
  'request-datetime.text': {
    id: 'request-datetime.text',
    defaultMessage: 'Request Date/Time',
  },
  FIELD_NAME: {
    id: 'FIELD_NAME',
    defaultMessage: 'Field Name',
  },
  MEMBER_VALIDATE: {
    id: 'MEMBER_VALIDATE',
    defaultMessage: 'Member Validate',
  },
  DO_YOU_WISH_TO_PROCEED: {
    id: 'DO_YOU_WISH_TO_PROCEED',
    defaultMessage: 'Do you wish to proceed?',
  },
  NUMBER_SHOULD_BE_BETWEEN: {
    id: 'NUMBER_SHOULD_BE_BETWEEN',
    defaultMessage: 'Number should be between {min}-{max}',
  },
  PROFILE_VALIDATION: {
    id: 'PROFILE_VALIDATION',
    defaultMessage: 'Profile Validation',
  },
  ON: {
    id: 'ON',
    defaultMessage: 'On',
  },
  OFF: {
    id: 'OFF',
    defaultMessage: 'Off',
  },
  'bank_account.text': {
    id: 'bank_account.text',
    defaultMessage: 'Bank Account',
  },
  PROMO_CONFLICT: {
    id: 'PROMO_CONFLICT',
    defaultMessage: 'Promo Conflict',
  },
  GROUP_NAME: {
    id: 'GROUP_NAME',
    defaultMessage: 'Group Name',
  },
  ENTER_PROMO_NAME: {
    id: 'ENTER_PROMO_NAME',
    defaultMessage: 'Enter Promo Name',
  },
  SYSTEM_PROMPT: {
    id: 'system-prompt.text',
    defaultMessage: 'System Prompt',
  },
  SUCCESSFULLY_DELETED_GAME_CATEGORY: {
    id: 'successfully-deleted-game-category.text',
    defaultMessage: 'Successfully Deleted Game Category',
  },
  VENDOR_IMAGE: {
    id: 'vendor-image.text',
    defaultMessage: 'Vendor Image',
  },
  PRIMARY: {
    id: 'primary.text',
    defaultMessage: 'Primary',
  },
  MALE: {
    id: 'MALE',
    defaultMessage: 'Male',
  },
  FEMALE: {
    id: 'FEMALE',
    defaultMessage: 'Female',
  },
  MOBILE_APP: {
    id: 'MOBILE_APP',
    defaultMessage: 'Mobile App',
  },
  MOBILE_WEB: {
    id: 'MOBILE_WEB',
    defaultMessage: 'Mobile Web',
  },
  DESKTOP_WEB: {
    id: 'DESKTOP_WEB',
    defaultMessage: 'Desktop Web',
  },

  MOBILE_APP_IOS: {
    id: 'MOBILE_APP_IOS',
    defaultMessage: 'Mobile App Ios',
  },
  MOBILE_APP_ANDROID: {
    id: 'MOBILE_APP_ANDROID',
    defaultMessage: 'Mobile App Android',
  },
  MOBILE: {
    id: 'MOBILE',
    defaultMessage: 'Mobile',
  },
  MOBILE_APP_IOS_HORIZONTAL: {
    id: 'MOBILE_APP_IOS_HORIZONTAL',
    defaultMessage: 'Mobile App IOS Horizontal',
  },
  MOBILE_APP_ANDROID_HORIZONTAL: {
    id: 'MOBILE_APP_ANDROID_HORIZONTAL',
    defaultMessage: 'Mobile App Android Horizontal',
  },
  MOBILE_WEB_HORIZONTAL: {
    id: 'MOBILE_WEB_HORIZONTAL',
    defaultMessage: 'Mobile Web Horizontal',
  },
  DESKTOP_WEB_HORIZONTAL: {
    id: 'DESKTOP_WEB_HORIZONTAL',
    defaultMessage: 'Desktop Web Horizontal',
  },
  CENTRAL_WALLET: {
    id: 'central-wallet.text',
    defaultMessage: 'Central Wallet',
  },
  UPGRADED: {
    id: 'upgraded.text',
    defaultMessage: 'Upgraded',
  },
  DOWNGRADED: {
    id: 'downgraded.text',
    defaultMessage: 'Downgraded',
  },
  LOYALTY_PROGRAM_ACTIVATION_FAILED: {
    id: 'member-loyalty.loyalty-programme-activation-failed',
    defaultMessage: 'Loyalty Programme Activation Failed',
  },
  MEMBER_SUMMARY_REPORT: {
    id: 'member-summary-report.text',
    defaultMessage: 'Member Summary Report',
  },
  START_END_DATE_TIME: {
    id: 'start-end-date-time.text',
    defaultMessage: 'Start - End Date / Time',
  },
  GAME_CATEGORY: {
    id: 'game-category.text',
    defaultMessage: 'Game Category',
  },
  'date-time-generated.text': {
    id: 'date-time-generated.text',
    defaultMessage: 'Date / Time Generated',
  },
  AFFILIATE_FLAG: {
    id: 'affiliate-flag.text',
    defaultMessage: 'Affiliate Flag',
  },
  AFFILIATE_UPLINE: {
    id: 'affiliate-upline.text',
    defaultMessage: 'Affiliate Upline',
  },
  ENTER_MEMBER_LABEL: {
    id: 'enter-member-label.text',
    defaultMessage: 'Enter Member Label',
  },
  REGISTRATION_START_END_DATE_TIME: {
    id: 'registration-start-end-date-time.text',
    defaultMessage: 'Registration Start - End Date / Time',
  },
  LAST_LOGIN_START_END_DATE_TIME: {
    id: 'last-login-start-end-date-time.text',
    defaultMessage: 'Last Login Start - End Date / Time',
  },
  'date-of-birth-start-end-date-time.text': {
    id: 'date-of-birth-start-end-date-time.text',
    defaultMessage: 'Date of Birth Start - End Date / Time',
  },
  FROZEN: {
    id: 'frozen.text',
    defaultMessage: 'Frozen',
  },
  KICKED: {
    id: 'kicked.text',
    defaultMessage: 'Kicked',
  },
  START_END_DATE_TIME_FILTER_REQUIRED: {
    id: 'start-end-date-time-filter-required.text',
    defaultMessage: 'Start - End Date / Time Filter is Required',
  },
  SELECT_VENDOR: {
    id: 'SELECT_VENDOR',
    defaultMessage: 'Select Vendor',
  },
  GAME_VENDOR: {
    id: 'GAME_VENDOR',
    defaultMessage: 'Game Vendor',
  },
  'affiliates.non-affiliate.text': {
    id: 'affiliates.non-affiliate.text',
    defaultMessage: 'Non-affiliate',
  },
  NON_AFFILIATE_UPLINE: {
    id: 'non-affiliate-upline.text',
    defaultMessage: 'Non-affiliate Upline',
  },
  MINIMUM_ACCOUNT_BALANCE: {
    id: 'minimum-account-balance.text',
    defaultMessage: 'Minimum Account Balance',
  },
  MAXIMUM_ACCOUNT_BALANCE: {
    id: 'maximum-account-balance.text',
    defaultMessage: 'Maximum Account Balance',
  },
  DOWNLOAD: {
    id: 'download.text',
    defaultMessage: 'Download',
  },
  AUTO_APPROVAL_TYPE: {
    id: 'AUTO_APPROVAL_TYPE',
    defaultMessage: 'Auto Approval Type',
  },
  BELONGS_IN: {
    id: 'BELONGS_IN',
    defaultMessage: 'Belongs in',
  },
  FROM: {
    id: 'FROM',
    defaultMessage: 'From',
  },
  TO: {
    id: 'TO',
    defaultMessage: 'To',
  },
  REWARD: {
    id: 'REWARD',
    defaultMessage: 'Reward',
  },
  PROMO_TAG_WITH_THE_SAME_NAME_ALREADY_EXISTS: {
    id: 'PROMO_TAG_WITH_THE_SAME_NAME_ALREADY_EXISTS',
    defaultMessage: 'PromoTag with the same name already exists',
  },
  GRAPHQL_ERROR__CAN_ONLY_ACTIVATE_FOUR_ADDITIONAL_MEMBER_LOYALTY_PROGRAMME: {
    id:
      'GRAPHQL_ERROR__CAN_ONLY_ACTIVATE_FOUR_ADDITIONAL_MEMBER_LOYALTY_PROGRAMME',
    defaultMessage:
      'Can only activate up to four additional member loyalty programs',
  },
  'name-already-exists.text': {
    id: 'name-already-exists.text',
    defaultMessage: '{name} name already exists',
  },
  'maximum-enrollee-reach.text': {
    id: 'maximum-enrollee-reach.text',
    defaultMessage: 'Maximum enrolle count reach',
  },
  LOYALTY_PROGRAMME_ACTIVATED: {
    id: 'member-loyalty.loyalty-programme-activated.text',
    defaultMessage: 'Loyalty Programme Activated',
  },
  'member-loyalty.loyalty-programme-activation-failed': {
    id: 'member-loyalty.loyalty-programme-activation-failed',
    defaultMessage: 'Loyalty Programme Activation Failed',
  },
  LOYALTY_PROGRAMME_DEACTIVATED: {
    id: 'member-loyalty.loyalty-programme-deactivated.text',
    defaultMessage: 'Loyalty Programme Deactivated',
  },
  CAN_ONLY_ACTIVATE_FOUR_ADDITONAL_MEMBER_LOYALTY_PROGRAMME: {
    id:
      'GRAPHQL_ERROR__CAN_ONLY_ACTIVATE_FOUR_ADDITIONAL_MEMBER_LOYALTY_PROGRAMME',
    defaultMessage:
      'Can only activate up to four additional member loyalty programs',
  },
  'number-of-members.text': {
    id: 'number-of-members.text',
    defaultMessage: 'Number of Members',
  },
  NEW_CATEGORY: {
    id: 'NEW_CATEGORY',
    defaultMessage: 'New Category',
  },
  DELETE_CATEGORY: {
    id: 'DELETE_CATEGORY',
    defaultMessage: 'Delete Category',
  },
  RENAME_CATEGORY: {
    id: 'RENAME_CATEGORY',
    defaultMessage: 'Rename Category',
  },
  DELETE_CONFIRMATION_CATEGORY: {
    id: 'DELETE_CONFIRMATION_CATEGORY',
    defaultMessage: 'Do you want to delete this category?',
  },
  CSV_IS_AVAILABLE_FOR_DOWNLOAD: {
    id: 'CSV_IS_AVAILABLE_FOR_DOWNLOAD',
    defaultMessage: 'CSV is available for download',
  },
  ERROR_IN_GENERATING_CSV_FILE: {
    id: 'ERROR_IN_GENERATING_CSV_FILE',
    defaultMessage: 'Error in generating CSV file',
  },
  IP_ADDRESS_REQUIRED: {
    id: 'IP_ADDRESS_REQUIRED',
    defaultMessage: 'IP Address Required',
  },
  MUST_BE_ADDRESS_FORMAT: {
    id: 'MUST_BE_ADDRESS_FORMAT',
    defaultMessage: 'Must be IP address format',
  },
  IP_ADDRESS_ALREADY_EXISTS: {
    id: 'IP_ADDRESS_ALREADY_EXISTS',
    defaultMessage: 'Ip Address already exists',
  },
  'marquee-message-setting.text': {
    id: 'marquee-message-setting.text',
    defaultMessage: 'Marquee Messages Settings',
  },
  'delete-marquee-title.text': {
    id: 'delete-marquee-title.text',
    defaultMessage: 'Delete Marquee message',
  },
  DELETE_CONFIRM_MARQUEE_MESSAGE: {
    id: 'DELETE_CONFIRM_MARQUEE_MESSAGE',
    defaultMessage: 'This will delete the {title} marquee message.',
  },
  'new-marquee-message.text': {
    id: 'new-marquee-message.text',
    defaultMessage: 'New Marquee Message',
  },
  'update-marquee-success.text': {
    id: 'update-marquee-success.text',
    defaultMessage: 'Marquee message update successfully',
  },
  'new-marquee-created.text': {
    id: 'new-marquee-created.text',
    defaultMessage: 'New marquee message created',
  },
  'snippet-helper-text.text': {
    id: 'snippet-helper-text.text',
    defaultMessage: '{ charCount } Character limit',
  },
  'snippet-field-required.text': {
    id: 'snippet-field-required.text',
    defaultMessage: 'Snippet field is required',
  },
  'snippet-characters-errors.text': {
    id: 'snippet-characters-errors.text',
    defaultMessage: '{count} characters only',
  },
  LIVE_CASINO: {
    id: 'live-casino.text',
    defaultMessage: 'Live Casino',
  },
  'member-marker.this-field-is-required.text': {
    id: 'member-marker.this-field-is-required.text',
    defaultMessage: 'This field is required',
  },
  'member-marker.please-enter.text': {
    id: 'member-marker.please-enter.text',
    defaultMessage: 'Please enter',
  },
  'member-marker.only-one-member-marker-is-allowed': {
    id: 'member-marker.only-one-member-marker-is-allowed',
    defaultMessage: 'Only one default member marker is allowed',
  },
  'member-marker-created.text': {
    id: 'member-marker-created.text',
    defaultMessage: 'Member marker created',
  },
  'member-marker-updated.text': {
    id: 'member-marker-updated.text',
    defaultMessage: 'Member marker updated',
  },
  'member-marker-management.deactivate-if-zero-members.text': {
    id: 'member-marker-management.deactivate-if-zero-members.text',
    defaultMessage: 'Can deactivate only if 0 number of members',
  },
  'delete-vendor-title.text': {
    id: 'delete-vendor-title.text',
    defaultMessage: 'Delete Vendor',
  },
  'delete-vendor-body.text': {
    id: 'delete-vendor-body.text',
    defaultMessage:
      'Please confirm to delete this vendor on category: {gameCategory}',
  },
  'please-key-in.text': {
    id: 'please-key-in.text',
    defaultMessage: 'Please key in',
  },
  'note-deleted.text': {
    id: 'note-deleted.text',
    defaultMessage: 'Note has been deleted',
  },
  'note-pinned.text': {
    id: 'note-pinned.text',
    defaultMessage: 'Note has been pinned',
  },
  'note-unpinned.text': {
    id: 'note-unpinned.text',
    defaultMessage: 'Note has been unpinned',
  },
  'pinned.text': {
    id: 'pinned.text',
    defaultMessage: 'Pinned',
  },
  'click-to-pin.text': {
    id: 'click-to-pin.text',
    defaultMessage: 'Click to Pin',
  },
  'max-words-error.text': {
    id: 'max-words-error.text',
    defaultMessage: '{count} words only',
  },
  'note-updated.text': {
    id: 'note-updated.text',
    defaultMessage: 'Note updated',
  },
  'delete-member-note-title.text': {
    id: 'delete-member-note-title.text',
    defaultMessage: 'Delete Member Note',
  },
  'delete-member-note-body.text': {
    id: 'delete-member-note-body.text',
    defaultMessage: 'Confirm to delete this note',
  },
  'note-created.text': {
    id: 'note-created.text',
    defaultMessage: 'Note created',
  },
  'rebates.edit-details.text': {
    id: 'rebates.edit-details.text',
    defaultMessage: 'Edit Details',
  },
  'rebates.group-name.text': {
    id: 'rebates.group-name.text',
    defaultMessage: 'Group name',
  },
  'rebates.qualifying-vip.text': {
    id: 'rebates.qualifying-vip.text',
    defaultMessage: 'Qualifying VIP',
  },
  'rebates.excluded-member-levels.text': {
    id: 'rebates.excluded-member-markers.text',
    defaultMessage: 'Excluded Member Markers',
  },
  'rebates.validity.text': {
    id: 'rebates.validity.text',
    defaultMessage: 'Validity',
  },
  'rebates.settlement-method.text': {
    id: 'rebates.settlement-method.text',
    defaultMessage: 'Settlement Method',
  },
  'rebates.payout-day.text': {
    id: 'rebates.payout-day.text',
    defaultMessage: 'Payout day',
  },
  'rebates.payout-date.text': {
    id: 'rebates.payout-date.text',
    defaultMessage: 'Payout date',
  },
  'rebates.payout-date-and-month.text': {
    id: 'rebates.payout-date-and-month.text',
    defaultMessage: 'Payout date and month',
  },
  'rebates.payout-time.text': {
    id: 'rebates.payout-time.text',
    defaultMessage: 'Payout time',
  },
  'rebates.payout-method.text': {
    id: 'rebates.payout-method.text',
    defaultMessage: 'Payout Method',
  },
  'rebates.turnover.text': {
    id: 'rebates.turnover.text',
    defaultMessage: 'Turnover',
  },
  'rebates.claim-offset-time.text': {
    id: 'rebates.claim-offset-time.text',
    defaultMessage: 'Claim offset time',
  },
  'rebates.claim-expiry-time.text': {
    id: 'rebates.claim-expiry-time.text',
    defaultMessage: 'Claim expiry time',
  },
  'rebates.award-expiry-time.text': {
    id: 'rebates.award-expiry-time.text',
    defaultMessage: 'Award expiry time',
  },
  'rebates.minimum-and-maximum-payout.text': {
    id: 'rebates.minimum-and-maximum-payout.text',
    defaultMessage: 'Minimum and Maximum',
  },
  'rebates.general-settings.text': {
    id: 'rebates.general-settings.text',
    defaultMessage: 'General settings',
  },
  'rebates.distribution-settings.text': {
    id: 'rebates.distribution-settings.text',
    defaultMessage: 'Distribution settings',
  },
  'rebates.group-settings.text': {
    id: 'rebates.group-settings.text',
    defaultMessage: 'Group settings',
  },
  'mobile-image-restriction-error.text': {
    id: 'mobile-image-restriction-error.text',
    defaultMessage:
      'Mobile image dimension should be equal or larger than the recommended {size}',
  },
  'sample-full-url.text': {
    id: 'sample-full-url.text',
    defaultMessage: 'Sample url: https://www.google.com',
  },
  'delete-banner-title.text': {
    id: 'delete-banner-title.text',
    defaultMessage: 'Delete Banner',
  },
  'banner-delete-confirm-message.text': {
    id: 'banner-delete-confirm-message.text',
    defaultMessage: 'Do you want to delete this banner: {name}',
  },
  'banner-name-required.text': {
    id: 'banner-name-required.text',
    defaultMessage: 'Banner name is required',
  },
  'url-required.text': {
    id: 'url-required.text',
    defaultMessage: 'URL field is required',
  },
  'promo-name-required.text': {
    id: 'promo-name-required.text',
    defaultMessage: 'Promo name field is required',
  },
  'url-validator-field.text': {
    id: 'url-validator-field.text',
    defaultMessage: 'Please enter a valid URL. Example: https://website.com',
  },
  'desktop-image-restriction-error.text': {
    id: 'desktop-image-restriction-error.text',
    defaultMessage:
      'Desktop image dimension should be equal or larger than the recommended {size}',
  },
  'horizontal-image-restriction-error.text': {
    id: 'horizontal-image-restriction-error.text',
    defaultMessage:
      'Landscape image dimension should be equal or larger than the recommended {size}',
  },
  'domain-created-successfully.text': {
    id: 'domain-created-successfully.text',
    defaultMessage: 'Domain created successfully',
  },
  'member-management.this-field-is-required.text': {
    id: 'member-management.this-field-is-required.text',
    defaultMessage: 'This field is required',
  },
  'member-management-invalid-password.text': {
    id: 'member-management-invalid-password.text',
    defaultMessage: 'Password must be 6 to 12 characters',
  },
  'member-management.minimum-length-of-6-characters': {
    id: 'member-management.minimum-length-of-6-characters',
    defaultMessage: 'minimum length of 6 numbers',
  },
  'member-management.maximum-length-of-32-characters': {
    id: 'member-management.maximum-length-of-32-characters',
    defaultMessage: 'Maximum length of 32 characters',
  },
  'member-management.must-only-be-6-characters': {
    id: 'member-management.must-only-be-6-characters',
    defaultMessage: 'Must only be 6 characters',
  },
  'invalid.text': {
    id: 'invalid.text',
    defaultMessage: 'Invalid',
  },
  'member-management-must-be-a-number.text': {
    id: 'member-management-must-be-a-number.text',
    defaultMessage: 'Must be a a number',
  },
  'member-level-no-spaces.text': {
    id: 'member-level-username-no-spaces.text',
    defaultMessage: 'Must have no spaces',
  },
  'global.is.text': {
    id: 'global.is.text',
    defaultMessage: 'is',
  },
  'global.under-construction.text': {
    id: 'global.under-construction.text',
    defaultMessage: 'Under Construction',
  },
  csv: {
    id: 'download-csv.text',
    defaultMessage: 'Download CSV',
  },
  xlsx: {
    id: 'DOWNLOAD_XLSX',
    defaultMessage: 'Download XLSX',
  },
  xls: {
    id: 'DOWNLOAD_XLS',
    defaultMessage: 'Download XLS',
  },
  'affiliate-request-error.text': {
    id: 'affiliate-request-error.text',
    defaultMessage: 'There were errors processing your request.',
  },
  'affiliate-request.approve-error.text': {
    id: 'affiliate-request.approve-error.text',
    defaultMessage:
      'Unable to approve request due to no default affiliate programme active.',
  },
  'affiliate-request.reject-error.text': {
    id: 'affiliate-request.reject-error.text',
    defaultMessage:
      'Unable to reject request due to no default affiliate programme active.',
  },
  'affiliate-request-reject-success.text': {
    id: 'affiliate-request-reject-success.text',
    defaultMessage: 'The affiliate request has been rejected.',
  },
  'affiliate-request-approve-success.text': {
    id: 'affiliate-request-approve-success.text',
    defaultMessage: 'The affiliate request has been approved succesfully.',
  },
  'affiliate-request-details.text': {
    id: 'affiliate-request-details.text',
    defaultMessage: 'Affiliate Request Details',
  },
  'affiliate-request-member-number.text': {
    id: 'affiliate-request-member-number.text',
    defaultMessage: 'Member No.',
  },
  'approve.text': {
    id: 'approve.text',
    defaultMessage: 'Approve',
  },
  'reject.text': {
    id: 'reject.text',
    defaultMessage: 'Reject',
  },
  'stop-processing.text': {
    id: 'stop-processing.text',
    defaultMessage: 'Stop Processing',
  },
  'member-info.text': {
    id: 'member-info.text',
    defaultMessage: 'Member Info',
  },
  'member-info-internal.text': {
    id: 'member-info-internal.text',
    defaultMessage: 'Member Info - Internal',
  },
  'others.text': {
    id: 'others.text',
    defaultMessage: 'Others',
  },
  'information-from-custom-form-field.text': {
    id: 'information-from-custom-form-field.text',
    defaultMessage: 'Information From Custom Form Field',
  },
  'operator-remark.text': {
    id: 'operator-remark.text',
    defaultMessage: 'Operator Remark (Optional)',
  },
  'expired.text': {
    id: 'expired.text',
    defaultMessage: 'Expired',
  },
  'rejected.text': {
    id: 'rejected.text',
    defaultMessage: 'Rejected',
  },
  'approved.text': {
    id: 'approved.text',
    defaultMessage: 'Approved',
  },
  'processing.text': {
    id: 'processing.text',
    defaultMessage: 'Processing',
  },
  EXCEL_WRONG_HEADERS_TEMPLATE: {
    id: 'EXCEL_WRONG_HEADERS_TEMPLATE',
    defaultMessage:
      'Your excel file is using a wrong header format. Please Download Template for the correct headers',
  },
  'new-member.text': {
    id: 'new-member.text',
    defaultMessage: 'New member',
  },
  ALL_MEMBERS_FINANCIALS: {
    id: 'ALL_MEMBERS_FINANCIALS',
    defaultMessage: 'All Members Financials',
  },
  ALL_MEMBERS_GAMING: {
    id: 'ALL_MEMBERS_GAMING',
    defaultMessage: 'All Members Gaming',
  },
  'not-set.text': {
    id: 'not-set.text',
    defaultMessage: 'Not Set',
  },
  'member-loyalty.loyalty-programme-deleted.text': {
    id: 'member-loyalty.loyalty-programme-deleted.text',
    defaultMessage: 'Loyalty Programme Deleted',
  },
  MAXIMUM_WITHDRAWAL_REQUEST_MUST_BE_GREATER_THAN_ZERO: {
    id: 'MAXIMUM_WITHDRAWAL_REQUEST_MUST_BE_GREATER_THAN_ZERO',
    defaultMessage:
      'Maximum Withdrawal Request must be greater than or equal to 0',
  },
  UPPERCASE_USERNAME_INVALID: {
    id: 'UPPERCASE_USERNAME_INVALID',
    defaultMessage: 'Username cannot contain CAPS',
  },
  PRIMARY_MEMBER_LOYALTY_PROGRAMME_REQUIRED: {
    id: 'PRIMARY_MEMBER_LOYALTY_PROGRAMME_REQUIRED',
    defaultMessage: 'Cannot create Member! Please set up VIP',
  },
  PRIMARY_MEMBER_LOYALTY_LEVEL_REQUIRED: {
    id: 'PRIMARY_MEMBER_LOYALTY_LEVEL_REQUIRED',
    defaultMessage: 'Primary Member Loyalty Level is required',
  },
  DEFAULT: {
    id: 'DEFAULT',
    defaultMessage: 'Default',
  },
  BANNER_WITH_THE_SAME_FILE_NAME_ALREADY_EXISTS: {
    id: 'BANNER_WITH_THE_SAME_FILE_NAME_ALREADY_EXISTS',
    defaultMessage: 'Banner with the same file name already exists',
  },
  'member-loyalty.loyalty-programme-duplicated.text': {
    id: 'member-loyalty.loyalty-programme-duplicated.text',
    defaultMessage: 'Loyalty Programme Duplicated',
  },
  AMOUNT_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO: {
    id: 'AMOUNT_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO',
    defaultMessage: 'Amount must be greater than or equal to 0',
  },
  WORD_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO: {
    id: 'WORD_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO',
    defaultMessage: '{word} must be greater than or equal to 0',
  },
  'turnover.text': {
    id: 'turnover.text',
    defaultMessage: 'Turnover',
  },
  LIMIT: {
    id: 'LIMIT',
    defaultMessage: 'Limit',
  },
  'member-loyalty.text': {
    id: 'member-loyalty.text',
    defaultMessage: 'Member Loyalty',
  },
  START_WITH_NUMERIC_USERNAME_INVALID: {
    id: 'START_WITH_NUMERIC_USERNAME_INVALID',
    defaultMessage: 'Username cannot start with a number',
  },
  PARTIAL: {
    id: 'partial.text',
    defaultMessage: 'Partial',
  },
  NO_MEMBER_NOTE_YET: {
    id: 'no-member-note-yet.text',
    defaultMessage: 'No Member note yet',
  },
  PROGRAMME_NAME: {
    id: 'PROGRAMME_NAME',
    defaultMessage: 'Programme Name',
  },
  SERIAL_CODE: {
    id: 'SERIAL_CODE',
    defaultMessage: 'Serial Code',
  },
  IP_ADDRESS_LIMIT: {
    id: 'IP_ADDRESS_LIMIT',
    defaultMessage: 'IP Address Limit',
  },
  SAME_NAME_LIMIT: {
    id: 'SAME_NAME_LIMIT',
    defaultMessage: 'Same Name Limit',
  },
  'minimum.text': {
    id: 'minimum.text',
    defaultMessage: 'Minimum',
  },
  'maximum.text': {
    id: 'maximum.text',
    defaultMessage: 'Maximum',
  },
  CANNOT_USE_OLD_WITHDRAWAL_PASSWORD: {
    id: 'CANNOT_USE_OLD_WITHDRAWAL_PASSWORD',
    defaultMessage:
      'Cannot use old withdrawal password to be your new password.',
  },
  'withdrawal-password-6-digits-error.text': {
    id: 'withdrawal-password-6-digits-error.text',
    defaultMessage: 'Withdrawal password must be 6 digit numbers',
  },
  BANNER_OF_SAME_ERROR: {
    id: 'BANNER_OF_SAME_ERROR',
    defaultMessage:
      'Banner of same exist, please choose another name for banner trying to upload',
  },
  EXPLODED_BUDGET: {
    id: 'EXPLODED_BUDGET',
    defaultMessage: 'Exploded Budget',
  },
  YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION: {
    id: 'YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION',
    defaultMessage: 'You not have permission to perform this action',
  },
  REQUEST_DATETIME: {
    id: 'REQUEST_DATETIME',
    defaultMessage: 'Request Date Time',
  },
  SITE_EXPIRED: {
    id: 'SITE_EXPIRED',
    defaultMessage:
      'This account has expired. Please contact customer service.',
  },
  'qualifying-vip-level': {
    id: 'qualifying-vip-level',
    defaultMessage: 'Qualifying VIP Level',
  },
  'number.min.error': {
    defaultMessage: 'must be greater than or equal to {min}',
    id: 'number.min.error',
  },
  VERIFICATION_STATUS: {
    id: 'VERIFICATION_STATUS',
    defaultMessage: 'Verification Status',
  },
  PROFILE_SETTINGS_INVALID_OPERATION: {
    id: 'PROFILE_SETTINGS_INVALID_OPERATION',
    defaultMessage: 'Invalid member profile settings',
  },
  COUNTRY_CODE: {
    id: 'COUNTRY_CODE',
    defaultMessage: 'Country Code',
  },
  POST_CODE: {
    id: 'POST_CODE',
    defaultMessage: 'Post Code',
  },
  VERIFIED: {
    id: 'VERIFIED',
    defaultMessage: 'Verified',
  },
  NOT_VERIFIED: {
    id: 'NOT_VERIFIED',
    defaultMessage: 'Not Verified',
  },
  FIRSTNAME: {
    id: 'FIRSTNAME',
    defaultMessage: 'First Name',
  },
  LASTNAME: {
    id: 'LASTNAME',
    defaultMessage: 'Last Name',
  },
  MEMBER_LOYALTY_PROGRAMME_EMPTY_TIERS: {
    id: 'MEMBER_LOYALTY_PROGRAMME_EMPTY_TIERS',
    defaultMessage: 'Cannot update VIP Programme with empty tiers',
  },
  EMAIL_EXISTS: {
    id: 'EMAIL_EXISTS',
    defaultMessage: 'Given email already exists',
  },
  PLATFORM_ID: {
    id: 'PLATFORM_ID',
    defaultMessage: 'Platform ID',
  },
  BRAND_ID: {
    id: 'BRAND_ID',
    defaultMessage: 'Brand ID',
  },
  PENDING_DEPOSIT_LIMIT_INCREASE_EXISTS: {
    id: 'PENDING_DEPOSIT_LIMIT_INCREASE_EXISTS',
    defaultMessage: 'Frequency HOURLY have a pending limit increase',
  },
  PROFILE_SETTINGS_INVALID_UPDATABLE: {
    id: 'PROFILE_SETTINGS_INVALID_UPDATABLE',
    defaultMessage: 'Updatable could not be on when the OTP is off',
  },
  ACCOUNT_PASSWORD_RESET_CREATED: {
    id: 'ACCOUNT_PASSWORD_RESET_CREATED',
    defaultMessage: 'Account Password Reset Created',
  },
  ACCOUNT_PASSWORD_RESET_VERIFIED: {
    id: 'ACCOUNT_PASSWORD_RESET_VERIFIED',
    defaultMessage: 'Account Password Reset Verified',
  },
  ACCOUNT_PASSWORD_RESET_EXPIRED: {
    id: 'ACCOUNT_PASSWORD_RESET_EXPIRED',
    defaultMessage: 'Account Password Reset Expired',
  },
  DOB_UNDERAGE_ERROR: {
    id: 'DOB_UNDERAGE_ERROR',
    defaultMessage:
      'You did not meet the age requirements to create an account',
  },
  DOB_INVALID_ERROR: {
    id: 'DOB_INVALID_ERROR',
    defaultMessage: 'Invalid date of birth',
  },
  MEMBER_COOLING_OFF: {
    id: 'MEMBER_COOLING_OFF',
    defaultMessage: 'Member is in cooling off period.',
  },
  EDIT_MEMBER_VERIFICATION_STATUS: {
    id: 'EDIT_MEMBER_VERIFICATION_STATUS',
    defaultMessage: 'Edit Member Verification Status',
  },
  SAVE_CHANGES: {
    id: 'SAVE_CHANGES',
    defaultMessage: 'Save Changes',
  },
  INACTIVE_OPERATOR_ACCOUNT: {
    id: 'INACTIVE_OPERATOR_ACCOUNT',
    defaultMessage: 'Operator account is inactive/disabled',
  },
  HEXOPAY: {
    id: 'HEXOPAY',
    defaultMessage: 'Hexopay',
  },
  NETELLER: {
    id: 'NETELLER',
    defaultMessage: 'Neteller',
  },

  SKRILL: {
    id: 'SKRILL',
    defaultMessage: 'Skrill',
  },
  MOBILE_NUMBER_EXISTS: {
    id: 'MOBILE_NUMBER_EXISTS',
    defaultMessage: 'Given mobile number already exists',
  },
  PASSWORD_EXPIRED: {
    id: 'PASSWORD_EXPIRED',
    defaultMessage:
      'Your password has expired. Please reset your password to login.',
  },
  CANCELED: {
    id: 'CANCELED',
    defaultMessage: 'Canceled',
  },
});

const allMessages: MessagesProps = {
  ...commonMessages,
  ...paymentGateways,
  ...gameCategories,
  ...promoCustomFields,
};

export default allMessages;
