import React from 'react';
import { Row, Col } from 'antd';

export const TransactionContent = ({
  leftNode,
  middleNode,
  rightNode,
}: ITransactionContent) => (
  <Row className="pb-2">
    <Col span={12}>
      <div className="d-flex align-items-center fs-12">{leftNode.title}</div>
      <div
        className="text-muted-light fs-12 d-flex"
        style={{ marginTop: '5px' }}
      >
        {leftNode.secondary}
      </div>
    </Col>
    <Col span={6}>
      <div className="fs-12 ">{middleNode.title}</div>
      <div className="text-muted-light fs-12 " style={{ marginTop: '5px' }}>
        {middleNode.secondary}
      </div>
    </Col>
    <Col span={6}>
      <a href="#/" className="fs-12 d-flex justify-content-end">
        {rightNode.title}
      </a>
      <div
        style={{ marginTop: '5px' }}
        className="d-flex justify-content-end fs-12"
      >
        {rightNode.secondary}
      </div>
    </Col>
  </Row>
);

interface ITransactionContent {
  leftNode: INode;
  rightNode: INode;
  middleNode: INode;
}

interface INode {
  title: any;
  secondary: any;
}
