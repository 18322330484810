import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';

// import { uniq } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import styled from 'styled-components';
// import { formatDate } from 'utils/dateUtils';
// import uuid from 'uuid/v1';

import coercedGet from 'utils/coercedGet';
import { formatDate } from 'utils/dateUtils';
import useExpandableFilterList from 'SuperAdminMain/hooks/useExpandableFilterList';
import { isObjectPropsEmpty } from 'utils/isObjectPropsEmpty/isObjectPropsEmpty';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import { useScreenTabSA } from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import useTransformedDates from 'SuperAdminMain/hooks/useTransformedDates';
import ModifiedTag from './components/ModifiedTag';
import ClientTag from './components/ClientTag/ClientTag';
import VendorTag from './components/VendorTag/VendorTag';
// import GameNameTag from './components/GameNameTag/GameNameTag';

type DateType = {
  gte: string;
  lte: string;
} | null;

type ModuleDateTypes = {
  dateTimeProcessed?: DateType;
};

const FilterConditions = ({ isLoading = false }) => {
  const { filters, setFilters, defaultValues } = useFilterValues();

  const { clearTabState } = useScreenTabSA(TabId.ACCOUNTS_CONSOLIDATED_REPORT);

  const [
    containerRef,
    { isTagsHidden, setIsTagsHidden },
    { showToggleArrow },
  ] = useExpandableFilterList() as any;

  const { dateTimeProcessed } = useTransformedDates<ModuleDateTypes>([
    'dateTimeProcessed',
  ]);

  const handleRemoveDateFilter = (dateString: string) => {
    setFilters((prev: any) => ({
      ...prev,
      [dateString]: null,
    }));
  };

  const handleRemoveTagList = (identifier: string, filterName: string) => {
    if (!isLoading) {
      const filtArray = coercedGet(filters, `${filterName}.in`, []);
      const data = filtArray.filter((item: string) => item !== identifier);

      const newFilterArray = {
        in: data,
      };
      setFilters((prev: any) => ({
        ...prev,
        [filterName]: data.length ? newFilterArray : null,
      }));
    }
  };

  const handleRemoveFilterTag = (tagName: string) => {
    if (!isLoading) {
      setFilters((prev: any) => ({
        ...prev,
        [tagName]: null,
      }));
    }
  };

  return (
    <div className="d-flex  align-items-center">
      <StyledTagWrapper
        className="mt-1 ml-3 "
        ref={containerRef}
        style={{ width: '40vw' }}
      >
        <FormattedMessage
          id="filter-conditions.text"
          defaultMessage="Filter Conditions"
        />
        :
        <span className="mr-4" />
        {filters.admin
          ? filters.admin.in.map((item: string) => (
              <ClientTag
                // key={key}
                key={item}
                onClose={() => handleRemoveTagList(item, 'admin')}
                id={item}
              />
            ))
          : null}
        {dateTimeProcessed ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveDateFilter('dateTimeProcessed')}
          >
            <FormattedMessage
              id="date-processed.text"
              defaultMessage="Date Processed"
            />
            : {formatDate(dateTimeProcessed.gte)}~
            {formatDate(dateTimeProcessed.lte)}
          </ModifiedTag>
        ) : null}
        {filters.vendor
          ? filters.vendor.in.map((item: string) => (
              <VendorTag
                key={item}
                onClose={() => handleRemoveTagList(item, 'vendor')}
                id={item}
              />
            ))
          : null}
        {filters.timezone ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveFilterTag('timezone')}
          >
            {filters.timezone}
          </ModifiedTag>
        ) : null}
        {/* {filters.game
          ? uniq(filters.game.in).map((item: any) => (
              <GameNameTag
                key={item}
                onClose={() => handleRemoveTagList(item, 'game')}
                id={item}
              />
            ))
          : null} */}
      </StyledTagWrapper>

      {showToggleArrow && !isObjectPropsEmpty(filters) && (
        <LegacyIcon
          type={isTagsHidden ? 'double-right' : 'double-left'}
          className="cursor-pointer mr-2 mt-1"
          onClick={() => {
            setIsTagsHidden((prev: any) => !prev);
          }}
        />
      )}

      <ClearAllFilterButton
        className="mt-1"
        initialFilters={defaultValues}
        currentFilters={filters}
        onFilterChange={(value: any) => {
          setFilters(value);
          clearTabState();
        }}
      />
    </div>
  );
};

const StyledTagWrapper = styled.div`
  /* display: flex;
  justify-content: flex-start; */
`;

export default FilterConditions;
