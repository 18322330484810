import styled from 'styled-components';
import { Modal } from 'antd';

type Props = {
  readOnly: boolean;
};

export const StyledModal: any = styled(Modal)`
  .ant-modal-content {
    background-color: ${({ readOnly }: Props) =>
      readOnly ? '#f2f2f2' : '#fff'};
  }
  .ant-modal-body {
    padding: 0;
  }
  .menu-header {
    background: #e7e9ed;
  }
  .remarks-area {
    background-color: ${({ readOnly }: Props) =>
      readOnly ? '#e7e9ed' : '#fff'};
  }
`;

export default {};
