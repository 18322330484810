import styled from 'styled-components';
import { Select } from 'antd';
import validateHexColor from 'utils/validateHexColor';
import { darken } from 'polished';

const colorTags = (tags: any[], forRebates?: boolean) =>
  tags.reduce(
    (acc, item, key) =>
      `${acc}
          &[title='${forRebates ? item?.label || '' : key}'] {
            background-color: ${validateHexColor(item?.color || '')} !important;
            border-color: ${darken(
              0.2,
              validateHexColor(item?.color || '')
            )} !important;
            border-radius: 11px !important;
            color: #000 !important;
          
            .anticon-close {
              color: #000 !important;
            }
          }
  `,
    ''
  );

export const StyledSelect: any = styled(Select)`
  width: 100% !important;

  .ant-select-selection li.ant-select-selection__choice {
    ${({ tags, forRebates }: { tags: any[]; forRebates?: boolean }) =>
      tags ? colorTags(tags, forRebates) : ''}
  }

  ${({ variant, isFilter }: any) =>
    isFilter &&
    variant === 'standard' &&
    `
    .ant-select-selection {
      border: 0;
      border-bottom: 1px solid #d9d9d9;
      border-radius: 0;
    }
    
    .ant-select-selection__rendered {
      margin-left: 0 !important;
    }
  `}
`;

export const StyledCustomClear = styled.span`
  font-size: 10px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export default {};
