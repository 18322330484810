import React from 'react';
import Block from 'react-blocks';

import { useFilterValues } from 'SuperAdminMain/contexts/Filters';

import { FormattedMessage } from 'react-intl';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import TimeZoneFilter from 'SuperAdminMain/shared/components/TimeZoneFilter';
import QuickSearchCustomFilters from './components/QuickSearchCustomFilters';
import SaveSearch from './components/SaveSearch';
import SearchSettings from './components/SearchSettings';
import { StyledSider, StyledContainer, StyledInputGroup } from './styles';
import FooterActions from './components/FooterActions';
import DateTimeProcessed from './components/DateTimeProcessed';
// import TimeZoneFilter from './components/TimeZoneFilter';
import ClientFilter from './components/ClientFilter';
import BetIdFilter from './components/BetIdFilter';
import VendorFilter from './components/VendorFilter';
import TimePlaced from './components/TimePlaced';
import TimeSettled from './components/TimeSettled';

import TipsMinMax from './components/TipsMinMax';
import JackpotContributionBet from './components/JackpotContributionBet';
import JackpotWin from './components/JackpotWin';
import CashoutWinLoss from './components/CashoutWinLoss';
import FreeSpin from './components/FreeSpin';
import Device from './components/Device';

import GameCategory from './components/GameCategory';
import GameSubCategory from './components/GameSubCategory';
import GameName from './components/GameName';

type Props = {
  collapsed: Boolean;
  children: React.ReactNode;
};
const Sidebar = React.forwardRef(({ collapsed }: Props, ref: any) => {
  const { filters, setFilters, resetFilter } = useFilterValues();

  return (
    <div>
      <StyledSider
        className="br-1"
        theme="light"
        trigger={null}
        collapsed={collapsed as any}
        collapsible
        collapsedWidth={0}
        width={232}
        style={{ height: 'calc(100vh - 157px)' }}
      >
        <StyledContainer className="p-3">
          <Block
            layout
            horizontal
            style={{ justifyContent: 'space-between' }}
            className="mb-1"
          >
            <Block>
              <div className="mb-0">
                <FormattedMessage id="search.text" defaultMessage="Search" />:
              </div>
            </Block>
            <Block>
              <SaveSearch tabContext={TabId.BET_HISTORY_REPORT} />
              <span className="mx-1">|</span>
              <SearchSettings tabContext={TabId.BET_HISTORY_REPORT} />
            </Block>
          </Block>
          <StyledInputGroup>
            <QuickSearchCustomFilters
              tabId={TabId.BET_HISTORY_REPORT}
              resetFilter={resetFilter}
              setFilters={setFilters}
            />
          </StyledInputGroup>

          <StyledInputGroup>
            <DateTimeProcessed state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TimeZoneFilter state={filters} setState={setFilters} />
          </StyledInputGroup>

          <hr />

          <StyledInputGroup>
            <ClientFilter state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <BetIdFilter state={filters} setState={setFilters} />
          </StyledInputGroup>
          {/* ============= */}

          <StyledInputGroup>
            <GameName state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <GameCategory state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <GameSubCategory state={filters} setState={setFilters} />
          </StyledInputGroup>

          {/* ============= */}

          <StyledInputGroup>
            <VendorFilter state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TimePlaced state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TimeSettled state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TipsMinMax state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <JackpotContributionBet state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <JackpotWin state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <CashoutWinLoss state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <FreeSpin state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <Device state={filters} setState={setFilters} />
          </StyledInputGroup>
        </StyledContainer>
      </StyledSider>
      <FooterActions ref={ref} />
    </div>
  );
});

export default Sidebar;
