import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Spin } from 'antd';
import Layout from 'components/Layout';
import ALL_PERMISSIONS from 'constants/permissions3';
import { GET_SYSTEM_NOTIFICATIONS } from 'graphql/queries/systemNotification.query';
import { get } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SearchCriteriaTag } from 'components/SearchCriteriaTag/SearchCriteriaTag';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePermissions } from 'store/accountState';
import removeNull from 'utils/removeNull';
import { tablePaginationHandler } from 'utils/tablePagination';
import { ContentTable } from './ContentTable/ContentTable';
import { NewAlertsAndNotificationsModal } from './NewAlertsAndNotificationsModal/NewAlertsAndNotificationsModal';
import { Sidebar } from './Sidebar/Sidebar';

export const AlertsAndNotificationsNext = () => {
  const pageInitialState = {
    first: 10,
    after: null,
    cursor: [null],
    currentPage: 0,
    filter: {},

    searchCriteria: {},
    onTagClose: '',
  };
  const { role, permissions } = usePermissions();
  const [page, setPage] = useState<any>(pageInitialState);
  const refetchVariables = {
    first: page.first,
    after: page.after,
    filter: removeNull(page.filter),
  };
  const [modalState, setModalState] = useState(false);

  const [getSystemNotifications, { data, loading }] = useLazyQuery(
    GET_SYSTEM_NOTIFICATIONS,
    {
      variables: refetchVariables,
      fetchPolicy: 'network-only',
    }
  );
  const { ALLOWED_LIST, ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['LIST', 'CREATE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS}`
  );
  const totalCount = get(data, 'systemNotifications.totalCount', 0);
  const pageInfo = get(data, 'systemNotifications.pageInfo', {});

  useEffect(() => {
    getSystemNotifications();
  }, [getSystemNotifications]);
  const { handlePrev, handleNext, totalPage } = tablePaginationHandler(
    page,
    setPage,
    totalCount
  );

  return (
    <Layout.Container>
      <Layout.Header
        leftNode={
          <>
            <ToggleSidebarHead />
            <FormattedMessage
              id="filter-conditions.text"
              defaultMessage="Filter conditions"
            />
            :
            <SearchCriteriaTag setPage={setPage} page={page} />
          </>
        }
        rightNode={
          ALLOWED_CREATE && (
            <Button type="primary" onClick={() => setModalState(true)}>
              <PlusOutlined className="mr-1" />
              <FormattedMessage
                id="new-alerts-and-notifications.text"
                defaultMessage="New Alerts and Notifications"
              />
            </Button>
          )
        }
      />
      <Layout.Content
        sideBar={
          <Layout.Sidebar>
            <Sidebar
              page={page}
              setPage={setPage}
              getSystemNotifications={getSystemNotifications}
              pageInitialState={pageInitialState}
            />
          </Layout.Sidebar>
        }
        footer={
          <Layout.Footer
            loading={loading}
            onNext={handleNext}
            onPrev={handlePrev}
            page={page}
            resultsCount={totalCount}
            totalPage={totalPage}
            setPage={setPage}
            pageInfo={pageInfo}
          />
        }
      >
        <Spin spinning={loading} size="large">
          {ALLOWED_LIST && (
            <ContentTable systemNotifications={data?.systemNotifications} />
          )}
        </Spin>
        {modalState && (
          <NewAlertsAndNotificationsModal
            getSystemNotifications={getSystemNotifications}
            visible={modalState}
            closeModal={() => setModalState(false)}
          />
        )}
      </Layout.Content>
    </Layout.Container>
  );
};
