import React from 'react';
import { ALink } from 'components/ALink/ALink';
import { useScreenTabV2 } from 'store/screenTabState';

type Props = {
  numOfVendors: number;
  gameCategory: Record<string, any>;
};

const VendorsScreenTab = ({ numOfVendors, gameCategory }: Props) => {
  const { addTab } = useScreenTabV2();

  const handleClick = () => {
    addTab({
      id: 'mobile-app-config-vendors',
      state: {
        gameCategory,
      },
    });
  };

  return (
    <>
      <ALink onClick={handleClick}>{numOfVendors}</ALink>
    </>
  );
};

export default VendorsScreenTab;
