import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  LoadingOutlined,
  PaperClipOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Card,
  Typography,
  Row,
  Col,
  Divider,
  Empty,
  Upload,
  message,
} from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import moment from 'moment';
import gql from 'graphql-tag';
import useTranslate from 'utils/useTranslate';
import { useAccount360 } from '../../AccountContext';

// ===[ UTILS ]=== //
const ATTACHMENTS = gql`
  query ATTACHMENTS($id: ID!) {
    admin(id: $id) {
      id
      attachments {
        id
        uri
        dateTimeCreated
        contentLength
      }
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      id
      uri
      mimetype
      encoding
    }
  }
`;

const UPDATE_ADMIN = gql`
  mutation updateAdmin($id: ID!, $input: UpdateAdminInput) {
    updateAdmin(id: $id, input: $input)
  }
`;

const messagesLocal = defineMessages({
  'file-already-exists.text': {
    id: 'file-already-exists.text',
    defaultMessage: 'File already exists',
  },
});

// ===[ MAIN ]=== //
export const UploadAttachment = () => {
  const [files, setFiles] = useState<Array<{ id: string; name: string }>>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [updateAdmin] = useMutation(UPDATE_ADMIN);
  const translate = useTranslate();
  const {
    admin: { id, attachments: adminAttachments },
  } = useAccount360();
  const [loadAttachment, { called, data, error, refetch }] = useLazyQuery(
    ATTACHMENTS,
    {
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    }
  );
  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, adminAttachments]);
  const onBeforeUpload = (file: File) => {
    const sameFileName = files.find((theFile) => theFile.name === file.name);

    if (sameFileName) {
      message.error(translate(messagesLocal['file-already-exists.text']));
      return false;
    }

    setUploading(true);

    uploadFile({
      variables: {
        file,
      },
    })
      .then((response) => {
        const uploadedFile = coercedGet(response, 'data.uploadFile', {});
        setFiles((prev) => [
          ...prev,
          {
            id: uploadedFile.id,
            name: file.name,
          },
        ]);

        return uploadedFile;
      })
      .then((res) =>
        updateAdmin({
          variables: {
            id,
            input: {
              attachments: [
                ...adminAttachments.map((item: any) => item.id),
                ...files.map((item) => item.id),
                res.id,
              ],
            },
          },
          refetchQueries: [
            {
              query: ATTACHMENTS,

              variables: {
                id,
              },
            },
          ],
        })
      )
      .finally(() => {
        setUploading(false);
      });

    return false;
  };

  if (!called) loadAttachment();
  if (error) return <>{`Error! ${error}`}</>;

  const attachments = coercedGet(data, 'admin.attachments', []).map(
    (file: any) => {
      const name = decodeURI(file.uri.split('/').slice(-1)[0]);
      // const size = file.contentLength / 1024 / 1000; // MB
      const date = moment(file.dateTimeCreated).format('MMM DD, YYYY');

      return { name, date, uri: file.uri };
    }
  );

  return (
    <Container>
      <Card
        bordered={false}
        title={
          <Typography.Text className="head-typography fw-500 text-dark">
            <FormattedMessage
              id="upload-attachment.text"
              defaultMessage="Upload Attachment"
            />
          </Typography.Text>
        }
        extra={
          <span
            className="fs-12"
            style={{ color: '#1890FF', fontSize: '14px' }}
          >
            <Upload
              accept={['.xls', '.xlsx'].join(', ')}
              showUploadList={false}
              beforeUpload={onBeforeUpload}
            >
              {uploading && <LoadingOutlined />}
              <ALink style={{ textDecoration: 'none' }}>
                <PlusOutlined />{' '}
                <FormattedMessage id="upload.text" defaultMessage="Upload" />
              </ALink>
            </Upload>
          </span>
        }
      >
        <Row>
          <Col span={17}>
            <span style={{ fontSize: '10px' }}>
              <FormattedMessage
                id="file-name.text"
                defaultMessage="File Name"
              />
            </span>
          </Col>

          {/* <Col span={7}>
            <span style={{ fontSize: '10px' }}>
              <FormattedMessage id="size.text" defaultMessage="Size" />
            </span>
          </Col> */}

          <Col span={7}>
            <span style={{ fontSize: '10px' }}>
              <FormattedMessage
                id="created-date.text"
                defaultMessage="Created Date"
              />
            </span>
          </Col>
        </Row>

        {attachments.length ? (
          attachments.map((file: any) => (
            <>
              <Row style={{ transform: 'translateY(10px)' }}>
                <Col span={17}>
                  <PaperClipOutlined
                    style={{
                      fontSize: '16px',
                      color: 'gray',
                      marginRight: '12px',
                    }}
                  />

                  <ALink href={file.uri}>{file.name}</ALink>
                </Col>

                {/* <Col span={7}>
                  {file.size}{' '}
                  <FormattedMessage
                    id="file-size-mb.text"
                    defaultMessage="MB"
                  />
                </Col> */}
                <Col span={7}>{file.date}</Col>
              </Row>

              <Divider />
            </>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Card>
    </Container>
  );
};

// ===[ STYLES ]=== //
const Container = styled.div`
  margin-top: 8px;
`;
