import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

const { Item } = Form;

export const StyledFormItem1 = styled(Item)`
  .ant-input-group-addon {
    background-color: #fff;
  }
`;

export default {};
