import gql from 'graphql-tag';

export const BET_HISTORY_REPORTS = gql`
  query BHR_MAIN_QUERY(
    $first: Int
    $after: Binary
    $filter: BetHistoryReportFilterInput
  ) {
    betHistoryReports(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          timezone
          creator {
            username
          }
          dateTimeCreated
          scheduledDateTime
          status
          report {
            id
            uri
          }

          filter {
            dateTimeProcessed {
              gte
              lte
            }
            admin {
              in
            }
            id {
              in
            }
            member {
              in
            }
            vendor {
              in
            }
            game {
              in
            }
            dateTimeSettled {
              in
            }
            dateTimePlaced {
              in
            }
            freeSpin {
              in
            }
            device {
              in
            }
          }
        }
      }
    }
  }
`;

export default {};
