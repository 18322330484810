import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-loyalty.programme-name.text': {
    id: 'member-loyalty.programme-name.text',
    defaultMessage: 'Programme name',
  },
  'member-loyalty.programme-description.text': {
    id: 'member-loyalty.programme-description.text',
    defaultMessage: 'Programme description',
  },
  'member-loyalty.on.text': {
    id: 'member-loyalty.on.text',
    defaultMessage: 'On',
  },
  'member-loyalty.off.text': {
    id: 'member-loyalty.off.text',
    defaultMessage: 'Off',
  },
});

export default messages;
