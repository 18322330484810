import {
  Switch,
  Form,
  Input,
  Typography,
  Row,
  Col,
  Select,
  Divider,
  Tooltip,
} from 'antd';
import gql from 'graphql-tag';
import React, { useState, ReactElement } from 'react';
import { GameType, VendorsConnectionEdge } from 'types/graphqlTypes';
import {
  Control,
  Controller,
  FieldError,
  useFieldArray,
} from 'react-hook-form';
import { capitalize } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { DeleteOutlined } from '@ant-design/icons';
import { ALink } from 'components/ALink/ALink';

export const ACTIVE_VENDORS = gql`
  query ActiveVendors(
    $first: Int
    $after: Binary
    $filter: VendorsFilterInput
  ) {
    vendors(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

type Props = {
  control: Control;
  clearErrors: Function;
  setValue: (name: string, value: unknown) => void;
  fieldToggles: {
    enableKillSwitch: boolean;
    enableWagerTO: number | null;
  };
  fieldError: {
    turnoverRequirementMultiplier: FieldError | undefined;
    wagerContributions?: any;
  };
};

const { Item } = Form;
const { Option } = Select;

const WagerItem = ({
  children,
  fieldKey,
  wagerContributionsErrors,
  index,
}: {
  children: ReactElement;
  wagerContributionsErrors: {
    [key: string]: {
      message: string;
    };
  }[];
  fieldKey: string;
  index: number;
}) => (
  <Item
    wrapperCol={{
      span: 24,
    }}
    validateStatus={
      wagerContributionsErrors?.length
        ? wagerContributionsErrors[index][fieldKey] && 'error'
        : undefined
    }
    help={
      wagerContributionsErrors?.length &&
      wagerContributionsErrors[index][fieldKey] &&
      wagerContributionsErrors[index][fieldKey].message
    }
  >
    {children}
  </Item>
);

const AdditionalConfig = ({
  control,
  fieldToggles,
  fieldError: { turnoverRequirementMultiplier, wagerContributions },
  clearErrors,
  setValue,
}: Props) => {
  const { enableKillSwitch, enableWagerTO: wagerTO } = fieldToggles;

  const [enableWagerTO, setEnableWagerTO] = useState<boolean>(false);

  const { fields, remove, append } = useFieldArray({
    name: 'wagerContributions',
    control,
  });

  const { loading, data } = useQuery(ACTIVE_VENDORS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        status: { eq: 'ACTIVE' },
      },
    },
  });

  const edges = coercedGet(data, 'vendors.edges', []);

  React.useEffect(() => {
    setEnableWagerTO(Boolean(wagerTO));
  }, [wagerTO]);

  return (
    <div className="d-flex justify-content-around">
      <div
        className="d-flex flex-column align-items-left"
        style={{
          width: 600,
        }}
      >
        <Item className="mb-3" label="Enable Wagering [T/O]">
          <Switch
            checked={enableWagerTO || undefined}
            onChange={(e) => {
              setEnableWagerTO(e);

              if (!e) {
                setValue('turnoverRequirementMultiplier', 0);
              }

              if (wagerContributions?.length || turnoverRequirementMultiplier) {
                clearErrors([
                  'wagerContributions',
                  'turnoverRequirementMultiplier',
                ]);
              }
            }}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </Item>

        <Item
          className="mb-4"
          validateStatus={turnoverRequirementMultiplier && 'error'}
          help={
            turnoverRequirementMultiplier &&
            turnoverRequirementMultiplier?.message
          }
        >
          <Controller
            control={control}
            name="turnoverRequirementMultiplier"
            rules={{
              ...(enableWagerTO && {
                required: {
                  value: true,
                  message: 'Turnover Requirement Multiplier is requried.',
                },
                min: {
                  value: 1,
                  message: 'Must be greater than or equal to 1.',
                },
              }),
            }}
            render={({ onChange, value }) => (
              <Input
                disabled={!enableWagerTO}
                value={value}
                onChange={(e) => onChange(+e.target.value)}
                type="number"
                min={0}
                style={{ width: 200, textAlign: 'center' }}
                addonAfter="No. of times"
              />
            )}
          />
        </Item>

        <div className="d-flex flex-column mb-4">
          <Typography.Text className="mb-2">
            Configure Wagering Contribution
          </Typography.Text>
          <Row
            style={{
              background: '#e4e4e4',
            }}
            className="mt-1"
            gutter={[16, 16]}
          >
            {['Category', 'Vendor', 'Percentage (%)'].map((text, index) => (
              <Col span={6} key={index}>
                <Typography.Title className="mb-0" level={5}>
                  {text}
                </Typography.Title>
              </Col>
            ))}
            <Col span={6}>
              <ALink
                onClick={() =>
                  append({
                    vendor: null,
                    gameType: null,
                    contribution: null,
                  })
                }
              >
                + Add more
              </ALink>
            </Col>

            <Divider className="m-0" />
          </Row>
          {fields.map((field, index) => (
            <Row key={field.id} gutter={[16, 16]}>
              <Col span={6}>
                <WagerItem
                  wagerContributionsErrors={wagerContributions}
                  fieldKey="gameType"
                  index={index}
                >
                  <Controller
                    control={control}
                    name={`wagerContributions.${index}.gameType`}
                    defaultValue={field.gameType}
                    rules={{
                      ...(enableWagerTO && {
                        required: {
                          value: true,
                          message: 'Required.',
                        },
                      }),
                    }}
                    render={({ onChange, value }) => (
                      <Select
                        disabled={!enableWagerTO}
                        allowClear
                        value={value}
                        onChange={onChange}
                        placeholder="Select"
                      >
                        {Object.keys(GameType).map((key, i) => (
                          <Option key={i} value={GameType[key]}>
                            {capitalize(GameType[key])}
                          </Option>
                        ))}
                      </Select>
                    )}
                  />
                </WagerItem>
              </Col>
              <Col span={6}>
                <WagerItem
                  wagerContributionsErrors={wagerContributions}
                  fieldKey="vendor"
                  index={index}
                >
                  <Controller
                    control={control}
                    name={`wagerContributions.${index}.vendor`}
                    defaultValue={field.vendor}
                    rules={{
                      ...(enableWagerTO && {
                        required: {
                          value: true,
                          message: 'Required.',
                        },
                      }),
                    }}
                    render={({ onChange, value }) => (
                      <Select
                        disabled={!enableWagerTO}
                        allowClear
                        value={value}
                        onChange={onChange}
                        placeholder="Select"
                        loading={loading}
                      >
                        {edges?.map((edge: VendorsConnectionEdge) => (
                          <Option key={edge.node.id} value={edge.node.id}>
                            {edge.node.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  />
                </WagerItem>
              </Col>
              <Col span={6}>
                <WagerItem
                  wagerContributionsErrors={wagerContributions}
                  fieldKey="contribution"
                  index={index}
                >
                  <Controller
                    control={control}
                    name={`wagerContributions.${index}.contribution`}
                    defaultValue={field.contribution}
                    rules={{
                      ...(enableWagerTO && {
                        min: {
                          value: 1,
                          message: 'Must be greater than or equal to 1.',
                        },
                        required: {
                          value: true,
                          message: 'Required.',
                        },
                      }),
                    }}
                    render={({ value, onChange }) => (
                      <Input
                        disabled={!enableWagerTO}
                        style={{
                          textAlign: 'center',
                        }}
                        type="number"
                        min={0}
                        max={100}
                        onKeyPress={(e) => e.preventDefault()}
                        addonAfter="%"
                        value={value}
                        onChange={(e) => onChange(+e.target.value)}
                      />
                    )}
                  />
                </WagerItem>
              </Col>
              <Col span={6}>
                <Tooltip title="Delete" placement="rightTop" className="ml-2">
                  <DeleteOutlined
                    className="text-muted"
                    style={{
                      fontSize: 20,
                      verticalAlign: 'middle',
                      paddingTop: 3,
                    }}
                    onClick={() => remove(index)}
                  />
                </Tooltip>
              </Col>
            </Row>
          ))}
        </div>

        <Item className="mb-3" label="Kill Switch (Auto Cancel Award)">
          <Controller
            control={control}
            name="autoCancel"
            render={({ onChange, value }) => (
              <Switch
                checked={value}
                onChange={onChange}
                checkedChildren="Yes"
                unCheckedChildren="No"
              />
            )}
          />
        </Item>

        <Item className="mb-3">
          <Controller
            control={control}
            name="maintainingBalance"
            render={({ onChange, value }) => (
              <Input
                disabled={!enableKillSwitch}
                value={value}
                onChange={(e) => onChange(+e.target.value)}
                type="number"
                min={0}
                style={{ width: 400, textAlign: 'center' }}
                addonBefore="If cash balance falls below"
                addonAfter="Kill the Bonus"
              />
            )}
          />
        </Item>
      </div>
    </div>
  );
};

export default AdditionalConfig;
