import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Spin, message } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import useTranslate from 'utils/useTranslate';
import { FormattedMessage } from 'react-intl';
import { yupResolver } from '@hookform/resolvers';
import messages from 'messages';
import { useMutation } from '@apollo/react-hooks';
import { get } from 'lodash';
import { changePasswordTestId } from 'components/data-testid/ChangePassword';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { messagesLocal, schema, UPDATE_PASSWORD } from './utils';

export const ChangePassword = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => any;
}) => {
  const translate = useTranslate();
  const [submitting, setSubmitting] = React.useState(false);
  const { control, handleSubmit, setError, errors } = useForm({
    resolver: yupResolver(schema(translate)),
    mode: 'all',
    reValidateMode: 'onChange',
  });
  const [updatePassword] = useMutation(UPDATE_PASSWORD);
  const { context } = useOperatorHeader();

  const onSubmit = async (data: FormValue) => {
    setSubmitting(true);
    try {
      await updatePassword({
        variables: {
          input: {
            password: data.newPassword,
          },
          password: data.password,
        },
        context,
      });
      message.success(translate(messagesLocal.SUCCESSFULLY_CHANGED_PASSWORD));
      closeModal();
    } catch (err) {
      const error = get(err.graphQLErrors[0], 'original');
      if (error.code === 'INVALID_PASSWORD') {
        setError('password', {
          type: 'manual',
          message: translate(messagesLocal.CURRENT_PASSWORD_INCORRECT),
        });
      }

      message.error(translate(messagesLocal[error.code]));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      onCancel={closeModal as any}
      title={translate(messages.CHANGE_PASSWORD)}
      visible={isOpen}
      footer={[
        <Button key={1} loading={submitting} onClick={closeModal as any}>
          <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
        </Button>,
        <Button
          data-testid={changePasswordTestId.submitButton}
          key={2}
          type="primary"
          loading={submitting}
          onClick={handleSubmit(onSubmit)}
        >
          <FormattedMessage id="SAVE_CHANGES" defaultMessage="Save Changes" />
        </Button>,
      ]}
    >
      <Spin spinning={submitting}>
        <Form layout="vertical" data-testid={changePasswordTestId.form}>
          <Form.Item
            data-testid={changePasswordTestId.passwordFormItem}
            label={
              <span className="text-black">
                {translate(messages.CURRENT_PASSWORD)}
              </span>
            }
            validateStatus={errors?.password && 'error'}
            help={errors?.password?.message}
          >
            <Controller
              data-testid={changePasswordTestId.passwordInput}
              control={control}
              name="password"
              as={Input.Password}
            />
          </Form.Item>

          <div className="mt-2">
            <Form.Item
              data-testid={changePasswordTestId.newPasswordFormItem}
              label={
                <span className="text-black">
                  {translate(messages.NEW_PASSWORD)}
                </span>
              }
              validateStatus={
                (errors?.newPassword ||
                  errors?.confirmPassword?.type === 'oneOf') &&
                'error'
              }
              help={
                (errors?.newPassword && errors?.newPassword?.message) ||
                (errors?.confirmNewPassword?.type === 'oneOf' &&
                  errors?.confirmNewPassword?.message)
              }
            >
              <Controller
                data-testid={changePasswordTestId.newPasswordInput}
                control={control}
                name="newPassword"
                as={Input.Password}
              />
            </Form.Item>
          </div>

          <div className="mt-2">
            <Form.Item
              data-testid={changePasswordTestId.confirmNewPasswordFormItem}
              label={
                <span className="text-black">
                  {translate(messages.CONFIRM_NEW_PASSWORD)}
                </span>
              }
              validateStatus={errors?.confirmNewPassword && 'error'}
              help={
                errors?.confirmNewPassword &&
                errors?.confirmNewPassword?.message
              }
            >
              <Controller
                data-testid={changePasswordTestId.confirmNewPasswordInput}
                control={control}
                name="confirmNewPassword"
                as={Input.Password}
              />
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

type FormValue = {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
};
