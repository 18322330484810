import React, { useEffect } from 'react';
import { Select } from 'antd';
import {
  StyledLabel,
  StyledSelect,
  StyledSpan,
} from 'styles/SharedStyledSelectFilter';
import { useQuery } from '@apollo/react-hooks';
import { WITHDRAWAL_METHODS } from 'components/WithdrawalMethodsSelect/queries';
import { useMethod } from 'store/methodState';

type Props = {
  filters: Record<string, any>;
  onChange: (e: string[] | null) => void;
};

const WithdrawalMethodFilter = ({ filters, onChange }: Props) => {
  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange(e);
    } else {
      onChange(null);
    }
  };

  const values = filters?.withdrawalMethod?.in || [];

  const { data, loading } = useQuery(WITHDRAWAL_METHODS, {
    variables: {
      filter: {
        enabled: { eq: true },
      },
    },
  });

  const options = data?.withdrawalMethods?.edges?.map((d: any) => ({
    label: d?.node.label,
    value: d?.node.value,
  }));
  const { setPaymentList } = useMethod();

  useEffect(() => {
    if (options) setPaymentList(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // const options = [
  //   {
  //     label: 'Manual Adjustment',
  //     value: 'MANUAL_ADJUSTMENT_WITHDRAWAL_METHOD',
  //   },
  //   {
  //     label: 'Hexopay',
  //     value: 'HEXOPAY_WITHDRAWAL_METHOD',
  //   },
  //   {
  //     label: 'Neteller',
  //     value: 'NETELLER_WITHDRAWAL_METHOD',
  //   },
  //   {
  //     label: 'Skrill',
  //     value: 'SKRILL_WITHDRAWAL_METHOD',
  //   },
  //   {
  //     label: 'Triple-A',
  //     value: 'CRYPTOCURRENCY_WITHDRAWAL_METHOD',
  //   },
  // ];

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between">
        <div>
          <StyledLabel>Payment Method Type</StyledLabel>
        </div>
        <div>
          <StyledSpan onClick={() => handleChange([])}>Clear</StyledSpan>
        </div>
      </div>
      <StyledSelect
        data-testid="withdrawal-payment-method-filter"
        mode="multiple"
        value={values}
        showSearch
        loading={loading}
        placeholder="Enter Payment Method"
        onChange={handleChange}
        filterOption={false}
      >
        {!loading &&
          options?.map((option: any) => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
      </StyledSelect>
    </div>
  );
};

export default WithdrawalMethodFilter;
