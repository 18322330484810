import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Spin, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import NextPrevButton from 'components/NextPrevButton';
import { Page, PageInfo } from 'interfaces/user.interface';
import TableFooter from '../TableFooter';

const defaultProps = {
  loading: false,
  resultsCount: 0,
};

type Props = {
  leftNode?: React.ReactNode;
  page: Page;
  onPrev: () => void;
  onNext: (pageInfo: PageInfo) => void;
  totalPage: number;
  pageInfo: PageInfo;
  loading: boolean;
  tablePagesForFooter?: number[];
  setPage?: Dispatch<SetStateAction<Page>>;
  // Use this field if you want to get the value and do your logic
  // on the parent, instead of passing setPage func
  // Alternative for setPage
  setPageViaPassingValue?: (e: any) => void;
} & typeof defaultProps;

const Footer = ({
  leftNode,
  resultsCount,
  page,
  onPrev,
  onNext,
  totalPage,
  pageInfo,
  setPage,
  setPageViaPassingValue,
  tablePagesForFooter,
  loading,
}: Props) => {
  const localTablePagesForFooter = [10, 20, 50, 100];

  const resetPagination = () => {
    if (page.currentPage + 1 >= totalPage && setPage) {
      setPage({
        ...page,
        after: undefined,
        savedCursor: [undefined] as any,
        currentPage: 0,
      });
    }
  };

  useEffect(() => {
    resetPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPage]);

  return (
    <div>
      <div
        className="d-flex justify-content-space-between align-items-center"
        style={{
          padding: '1.2rem',
          height: '2.4vh',
          background: '#f3f8fb',
        }}
      >
        {leftNode || <div />}
        <Spin spinning={loading}>
          <div className="styled-center">
            <div className="mr-3">
              <Typography.Text
                strong
                className="mr-1"
                data-testid="resultsFound"
              >
                {resultsCount}
              </Typography.Text>
              <FormattedMessage
                id="results-found.text"
                defaultMessage="Results Found"
                values={{
                  count: null,
                }}
              />
            </div>
            <NextPrevButton
              onPrev={onPrev}
              disablePrev={page.currentPage + 1 === 1}
              onNext={() => onNext(pageInfo)}
              disableNext={page.currentPage + 1 >= totalPage}
              label={`${page.currentPage + 1} / ${totalPage}`}
            />
            <TableFooter
              values={tablePagesForFooter || localTablePagesForFooter}
              setPage={setPage}
              setPageViaPassingValue={setPageViaPassingValue}
              page={page}
            />
          </div>
        </Spin>
      </div>
    </div>
  );
};

Footer.defaultProps = defaultProps;

export default Footer;
