import React from 'react';
import { Row, Col, InputNumber, Slider } from 'antd';
import { useFormikContext } from 'formik';

type Rec = Record<string, any>;

const SliderWithInputNumber = (props: {
  name: string;
  value: Rec;
  max?: number;
  min?: number;
}) => {
  const { name, min, max } = props;
  const { values, setFieldValue } = useFormikContext() as {
    values: Rec;
    setFieldValue: (e: any, f: any) => void;
  };
  const inputValue = values[name];
  function handleChange(e: any) {
    setFieldValue(name, e);
  }
  return (
    <Row>
      <Col span={12}>
        <Slider
          min={min}
          max={max}
          onChange={handleChange}
          value={typeof inputValue === 'number' ? inputValue : 0}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          min={min}
          max={max}
          style={{ marginLeft: 16 }}
          value={typeof inputValue === 'number' ? inputValue : 0}
          onChange={handleChange}
        />
      </Col>
      <Col span={2} style={{ marginLeft: '12px' }}>
        %
      </Col>
    </Row>
  );
};

export default SliderWithInputNumber;
