import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Tag } from 'antd';
import { useIntl } from 'react-intl';
import { VENDOR } from './queries';

type Props = {
  id: string;
  onClose: (e: any) => any;
};

const VendorTag: React.FC<Props> = ({ id, onClose }) => {
  const { locale } = useIntl();

  const { data } = useQuery(VENDOR, {
    variables: {
      id,
      language: locale.toUpperCase(),
    },
  });

  const { vendor } = data || {};

  return (
    <Tag closable onClose={(e: any) => onClose(e)}>
      {vendor?.shortcode} - {vendor?.name}
    </Tag>
  );
};

export default VendorTag;
