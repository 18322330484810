import gql from 'graphql-tag';

export const DELETE_OPERATOR = gql`
  mutation DeleteOperator($id: ID!) {
    deleteOperator(id: $id)
  }
`;

export const UPDATE_OPERATOR = gql`
  mutation UpdateOperator($id: ID!, $input: UpdateOperatorInput!) {
    updateOperator(id: $id, input: $input)
  }
`;

export const CREATE_OPERATOR = gql`
  mutation CreateOperator($input: CreateOperatorInput!) {
    createOperator(input: $input)
  }
`;

export default {};
