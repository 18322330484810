import React from 'react';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import useTranslate from 'utils/useTranslate';
import { useFormikContext } from 'formik';
import { getTierSettingsSchema } from '../../../../context';

const CompletionIndicator = () => {
  const { errors, values, setFieldError } = useFormikContext() as any;

  const translate = useTranslate();

  React.useEffect(() => {
    getTierSettingsSchema(translate)
      .validate(values)
      .catch((err) => {
        setFieldError(err.params.path, err.message);
      });
  }, [values, errors, translate, setFieldError]);

  const hasError = Object.entries(errors).length;

  const invalidNameVal = values.name.length < 1 || values.name.length > 12;
  return invalidNameVal || hasError ? (
    <ExclamationCircleFilled
      style={{
        color: '#faad14',
      }}
    />
  ) : (
    <CheckCircleFilled
      style={{
        color: '#52c41a',
      }}
    />
  );
};

export default CompletionIndicator;
