import React from 'react';

import DashboardTree from './DashboardTree';
import OperatorsTree from './OperatorsTree';
// import MembersTree from './MembersTree';
import MembersTreeRevamp from './MembersTreeRevamp';
import AffiliatesTree from './AffiliatesTree';
import DepositsTree from './DepositsTree';
import WithdrawalsTree from './WithdrawalsTree';
import VIPTree from './VIPTree';
import RebatesTree from './RebatesTree';
import ReportsTree from './ReportsTree';
import SystemManagementTree from './SystemManagementTree';
import SystemMessagesTree from './SystemMessagesTree';
import PromosTree from './PromosTree';

export default {
  ALL_DASHBOARDS: <DashboardTree />,
  ALL_OPERATORS: <OperatorsTree />,
  ALL_MEMBERS: <MembersTreeRevamp />,
  ALL_AFFILIATES: <AffiliatesTree />,
  ALL_DEPOSITS: <DepositsTree />,
  ALL_WITHDRAWALS: <WithdrawalsTree />,
  ALL_PROMOS: <PromosTree />,
  ALL_VIP: <VIPTree />,
  ALL_REBATES: <RebatesTree />,
  ALL_REPORTS: <ReportsTree />,
  ALL_SYSTEM_MANAGEMENT: <SystemManagementTree />,
  ALL_SYSTEM_MESSAGES: <SystemMessagesTree />,
};
