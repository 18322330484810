/**
 * Accepts `defaultValue` for nullish value. If value is empty ("") string, it returns the defaultValue.
 * @param value - Accepts any value.
 * @param defaultValue - Used as fallback value if value is nullish.
 * @returns resolved value
 */
const esGet = <T>(value: T, defaultValue: any): T => {
  const resolved = value ?? defaultValue;
  if (resolved === '') {
    return defaultValue;
  }
  return resolved;
};

export default esGet;
