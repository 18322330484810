import React, { useState } from 'react';
import { Button, Row, Col, Input, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { StyledForm } from './styles';

type Props = {
  initialValues: { [key: string]: any };
  onSubmit: (values: any) => void;
  onClose: () => void;
  isLoading: boolean;
};

const SearchSettingsForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const [values, setValues] = useState(initialValues);
  const translate = useTranslate();
  console.log(setValues);

  // const changeState = (key: string, val: any) => {
  //   setValues(prevState => ({
  //     ...prevState,
  //     [key]: val,
  //   }));
  // };

  return (
    <StyledForm>
      <Row>
        <Col span={12}>
          <FilterItem label={translate(messages.PROGRAMME_NAME)}>
            <Input />
          </FilterItem>
          <FilterItem label={translate(messages.MISSION_TYPE)}>
            <Input />
          </FilterItem>
          <FilterItem label={translate(messages.DATE_RANGE)}>
            <Input />
            <div className="mt-1">
              <Button size="small" className="mb-1">
                <FormattedMessage
                  id="last-7-days.text"
                  defaultMessage="Last 7 days"
                />
              </Button>
              <Button size="small" className="mb-1">
                <FormattedMessage
                  id="last-week.text"
                  defaultMessage="Last week"
                />
              </Button>
              <Button size="small" className="mb-1">
                <FormattedMessage
                  id="last-30-days.text"
                  defaultMessage="Last 30 days"
                />
              </Button>
            </div>
          </FilterItem>
          <FilterItem label={translate(messages.STATUS)}>
            <Checkbox.Group value={[]}>
              <Checkbox className="mt-1" value="ACTIVE">
                <FormattedMessage id="active.text" defaultMessage="Active" />
              </Checkbox>
              <br />
              <Checkbox className="mt-1" value="DISABLED">
                <FormattedMessage
                  id="inactive.text"
                  defaultMessage="Inactive"
                />
              </Checkbox>
              <br />
              <Checkbox className="mt-1" value="DRAFT">
                <FormattedMessage id="draft.text" defaultMessage="Draft" />
              </Checkbox>
            </Checkbox.Group>
          </FilterItem>
        </Col>
      </Row>
      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmit(values)}
          loading={isLoading}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
