import { defineMessages } from 'react-intl';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { Upload } from 'antd';
import * as Yup from 'yup';
import { OperatorValidation } from 'SuperAdminMain/utils/operatorValidation';
import { CustomValidation } from 'utils/customValidation';
import { Currency } from 'types/currency';

export const CREATE_ADMIN = gql`
  mutation CreateAdmin($input: CreateAdminInput!) {
    createAdmin(input: $input)
  }
`;

export const UPDATE_ADMIN = gql`
  mutation UpdateAdmin($id: ID!, $input: UpdateAdminInput) {
    updateAdmin(id: $id, input: $input)
  }
`;

export const messagesLocal = defineMessages({
  'ip-address-input-placeholder.text': {
    id: 'ip-address-input-placeholder.text',
    defaultMessage: 'Enter IP address',
  },
  'linked-admins.text': {
    id: 'linked-admins.text',
    defaultMessage: 'Linked Admins',
  },
  'file-already-exists.text': {
    id: 'file-already-exists.text',
    defaultMessage: 'File already exists',
  },
  'MANUAL_ADJUSTMENT.UPLOAD_TEXT_HELPER': {
    id: 'MANUAL_ADJUSTMENT.UPLOAD_TEXT_HELPER',
    defaultMessage: 'The file size should not exceed 1024kb',
  },
  'import-from-excel.text': {
    defaultMessage: 'Import from Excel',
    id: 'import-from-excel.text',
  },
  'account-information.text': {
    id: 'account-information.text',
    defaultMessage: 'Account Information',
  },
  'client-account.text': {
    id: 'client-account.text',
    defaultMessage: 'Client Account',
  },
  'please-enter.text': {
    id: 'please-enter.text',
    defaultMessage: 'Please Enter',
  },
  'client-information.text': {
    id: 'client-information.text',
    defaultMessage: 'Client Information',
  },
  'client-(company).text': {
    id: 'client-(company).text',
    defaultMessage: 'Client (Company)',
  },
  'person-in-charge.text': {
    id: 'person-in-charge.text',
    defaultMessage: 'Person In-charge',
  },
  'mobile-phone.text': {
    id: 'mobile-phone.text',
    defaultMessage: 'Mobile Phone',
  },
  'email.text': {
    id: 'email.text',
    defaultMessage: 'Email',
  },
  'invalid-email-format.text': {
    id: 'invalid-email-format.text',
    defaultMessage: 'Invalid email format',
  },
  'qq-number.text': {
    id: 'qq-number.text',
    defaultMessage: 'QQ Number',
  },
  'wechat.text': {
    id: 'wechat.text',
    defaultMessage: 'WeChat',
  },
  'currency.text': {
    id: 'currency.text',
    defaultMessage: 'Currency',
  },
  'multi-currency.text': {
    id: 'multi-currency.text',
    defaultMessage: 'Multi-Currency',
  },
  'skype.text': {
    id: 'skype.text',
    defaultMessage: 'Skype',
  },
  'vendor-management.text': {
    id: 'vendor-management.text',
    defaultMessage: 'Vendor Management',
  },
  'vendor-group.text': {
    id: 'vendor-group.text',
    defaultMessage: 'Vendor Group',
  },
  'select-date-and-time.text': {
    id: 'select-date-and-time.text',
    defaultMessage: 'Select Date & Time',
  },
  'please-select.text': {
    id: 'please-select.text',
    defaultMessage: 'Please Select',
  },
  'password.text': {
    id: 'password.text',
    defaultMessage: 'Password',
  },
  'auto-generate.text': {
    id: 'auto-generate.text',
    defaultMessage: 'Auto Generate',
  },
  'category.text': {
    id: 'category.text',
    defaultMessage: 'Category',
  },
  'start-date.text': {
    id: 'start-date.text',
    defaultMessage: 'Start Date',
  },
  'expire-date.text': {
    id: 'expire-date.text',
    defaultMessage: 'Expire Date',
  },
  'admin-code/url.text': {
    id: 'admin-code/url.text',
    defaultMessage: 'Admin Code/URL',
  },
  'whitelist-ip-address.text': {
    id: 'whitelist-ip-address.text',
    defaultMessage: 'Whitelist IP Address',
  },
  '2FA.text': {
    id: '2FA.text',
    defaultMessage: '2FA',
  },
});
export const StyledUploader = styled(Upload)`
  width: unset !important;
`;

export interface INewClientAccount {
  onClose: () => void;
  onNext: (values: object, hasExistingValue: boolean) => void;
  existingValues?: Record<string, any>;
  vendorGroups: Array<{ id: string; name: string }>;
}

export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      id
      uri
      mimetype
      encoding
    }
  }
`;
export const validationSchema = (formatMessage: any, existingValue: boolean) =>
  Yup.object().shape({
    username: OperatorValidation.username(formatMessage),
    ...(!existingValue && {
      password: OperatorValidation.password(formatMessage),
    }),
    email: CustomValidation.email(formatMessage),
    qqId: CustomValidation.qqId(formatMessage),
    wechatId: CustomValidation.wechatId(formatMessage),
    mobileNumber: CustomValidation.mobileNumber(formatMessage),
    company: Yup.string().required('Client (Company) is required'),
  });

export const currencyOption = Object.entries(
  Currency
).map(([label, value]) => ({ value, label }));
