export const statusTypeDepositWithdrawal = {
  APPROVED: 'success',
  PENDING: 'warning',
  PROCESSING: 'processing',
  DRAFT: 'warning',
  CANCELLED: 'error',
  REJECTED: 'error',
  FAILED: 'error',
  EXPIRED: 'default',
};

export const withdrawalExternalStatus = {
  APPROVED: 'success',
  PROCESSING: 'processing',
  FAILED: 'error',
  REJECTED: 'error',
};

export const statusTypePromos = {
  APPROVED: 'success',
  DRAFT: 'warning',
  PENDING: 'warning',
  PUBLISHED: 'success',
  PROCESSING: 'processing',
  ACTIVE: 'success',
  UNPUBLISHED: 'warning',
  EXPIRED: 'default',
};
