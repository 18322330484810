import React from 'react';
import { Button } from 'antd';
import { useScreenTabV2 } from 'store/screenTabState';
import { TabId } from 'constants/route';
import coercedGet from 'utils/coercedGet';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_MEMBER_USERNAME } from 'graphql/queries/member.query';

function RequestRedirect({ node }: { node: Record<string, any> }) {
  const type = coercedGet(node, 'type', null);
  const client = useApolloClient();
  const redirectTypes = {
    DEPOSIT: ['DEPOSIT', 'DEPOSIT_MANUAL_ADJUSTMENT'],
    WITHDRAWAL: ['WITHDRAWAL', 'WITHDRAWAL_MANUAL_ADJUSTMENT'],
  };

  const { DEPOSIT, WITHDRAWAL } = redirectTypes;

  const isDeposit = !!DEPOSIT.find((x) => x === type);
  const isWithdrawal = !!WITHDRAWAL.find((x) => x === type);

  const isRedirectable = isDeposit || isWithdrawal;

  const { addTab } = useScreenTabV2() as Record<string, any>;
  const handleRedirect = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const resp = await client.query({
      query: GET_MEMBER_USERNAME,
      variables: {
        id: node?.id,
      },
    });
    const userId = coercedGet(
      resp,
      'data.balanceTransactionRecord.member.id',
      undefined
    );
    const internalDepositsTab = {
      id: TabId.INTERNAL_DEPOSIT_REQUESTS,
      state: {
        memberIdRef: userId,
        serialCodeRef: node?.transaction,
      },
    };

    const withdrawalRequestsTab = {
      id: TabId.WITHDRAWAL_REQUESTS,
      state: {
        next: true,
        memberIdRef: userId,
        serialCodeRef: node?.transaction,
      },
    };

    if (isDeposit) return addTab(internalDepositsTab);
    if (isWithdrawal) return addTab(withdrawalRequestsTab);
    return null;
  };

  return isRedirectable && node?.transaction ? (
    <Button
      style={{ fontSize: '13px' }}
      className="px-0"
      type="link"
      onClick={handleRedirect}
    >
      {node.transaction}
    </Button>
  ) : (
    <span>-</span>
  );
}

export default RequestRedirect;
