import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio, Input } from 'antd';
import { BLOCK_CHAIN_TYPES } from 'constants/paymentTypes';
import SingleFileUploader from 'components/Uploader/SingleFileUploader';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import isRow from 'utils/isRow';
import messages from '../../../../messages';

const USDTDetails = ({
  formItemLayout,
  errors,
  handleChange,
  values,
  paymentType,
  handleBlur,
  setFieldValue,
}: any) => {
  const translate = useTranslate();

  const onCompleteUpload = (field: any, file: any) => {
    setFieldValue(field, file ? file.id : '');
  };
  const blockChainProvider = {
    OFFLINE: [
      {
        label: 'ERC20',
        value: BLOCK_CHAIN_TYPES.OFFLINE.ERC20,
      },
      {
        label: 'TRC20',
        value: BLOCK_CHAIN_TYPES.OFFLINE.TRC20,
      },
      {
        label: 'OMNI',
        value: BLOCK_CHAIN_TYPES.OFFLINE.OMNI,
      },
    ],
    ONLINE: [
      {
        label: 'ERC20',
        value: BLOCK_CHAIN_TYPES.OFFLINE.ERC20,
      },
      {
        label: 'TRC20',
        value: BLOCK_CHAIN_TYPES.OFFLINE.TRC20,
      },
      {
        label: 'OMNI',
        value: BLOCK_CHAIN_TYPES.OFFLINE.OMNI,
      },
    ],
  };
  return (
    <>
      {!isRow && (
        <>
          <Form.Item label={translate(messages.blockchain)} {...formItemLayout}>
            <Radio.Group
              onChange={handleChange}
              value={values.blockchain}
              name="blockchain"
            >
              {blockChainProvider[paymentType] &&
                blockChainProvider[paymentType].map((item: any, key: any) => (
                  <Radio value={item.value} key={key} disabled={item.disabled}>
                    <>{item.label}</>
                  </Radio>
                ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={translate(messages.destination_address)}
            {...formItemLayout}
            validateStatus={errors.blockchainAddress ? 'error' : null}
            help={errors.blockchainAddress || null}
            data-testid="blockChainTestID"
          >
            <Input
              name="blockchainAddress"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.blockchainAddress}
              placeholder="e.g: 0x971151c9f4acdf9d6cd2defd10249eedb768ce8d"
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label={translate(messages.qrcode)}
        {...formItemLayout}
        validateStatus={errors.image ? 'error' : null}
        help={errors.image || null}
      >
        <SingleFileUploader
          name="image"
          accept=".png, .jpg, .jpeg"
          defaultFiles={coercedGet(values, 'image', '')}
          data={{
            onCompleteUpload,
          }}
          previewVisible
          showUploadList={{
            showDownloadIcon: false,
          }}
        />
      </Form.Item>
    </>
  );
};

export default USDTDetails;
