import React from 'react';
import Icon from '@ant-design/icons';
import { Button, Modal } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ReactComponent as IconDailyLogin } from '../../assets/icon_dailylogin.svg';
import { ReactComponent as IconDailyMission } from '../../assets/icon_dailymission.svg';
import { useMissionsValue } from '../../context';

type Props = {
  visible: boolean;
  toggleModal: () => void;
};

const Box = styled.div`
  width: 144px;
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #cccccc;
  border-radius: 8px;
  cursor: pointer;
`;

const NewMissionModal = ({ visible, toggleModal }: Props) => {
  const translate = useTranslate();
  const { setSelectedNewMission } = useMissionsValue();

  const onSelectMission = (value: string) => {
    setSelectedNewMission(value);
    toggleModal();
  };

  return (
    <Modal
      title={translate(messages.NEW_MISSION_TYPE)}
      visible={visible}
      width={540}
      onCancel={toggleModal}
      footer={[
        <Button key={1} onClick={toggleModal}>
          <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
        </Button>,
      ]}
    >
      <p className="fw-500">
        <FormattedMessage
          id="PLEASE_CHOOSE_ONE"
          defaultMessage="Please choose one"
        />
      </p>

      <div className="mt-2 d-flex flex-wrap">
        <Box onClick={() => onSelectMission('DAILY_LOGIN')} className="mr-3">
          <Icon
            style={{ fontSize: '48px' }}
            component={IconDailyLogin}
            className="mb-2"
          />
          <FormattedMessage id="DAILY_LOGIN" defaultMessage="Daily Login" />
        </Box>
        <Box onClick={() => onSelectMission('DAILY_MISSION')}>
          <Icon
            style={{ fontSize: '48px' }}
            component={IconDailyMission}
            className="mb-2"
          />
          <FormattedMessage id="DAILY_MISSION" defaultMessage="Daily Mission" />
        </Box>
      </div>
    </Modal>
  );
};

export default NewMissionModal;
