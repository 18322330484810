export default (addresses: string[]) => {
  const filteredAddresses = addresses.filter(Boolean);
  const joinedAddresses = filteredAddresses
    .map((value, idx) => {
      if (value) {
        return `${value}${idx !== filteredAddresses?.length - 1 ? ', ' : ''}`;
      }
      return '';
    })
    .join('');
  return joinedAddresses;
};
