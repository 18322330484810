import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import SelectFilterField from 'components/SelectFilterField';
import { GET_OPERATOR_USERNAMES } from 'graphql/queries/operators.query';
import { GET_PERMISSION_GROUPS } from 'graphql/queries/permissionGroup.query';
import coercedGet from 'utils/coercedGet';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import { OperatorStatus } from 'types/graphqlTypes';
import CheckboxFilterField from 'components/CheckboxFilterField';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  onFilterChange?: (value: DynamicObj) => void;
  isCustomFilter?: boolean;
};

const FilterItems = ({
  filters,
  onRawFilterChange: handleChange,
  isCustomFilter = false,
}: Props) => (
  <>
    <SelectFilterField
      testId="operator-username-filter"
      label="Operator"
      query={GET_OPERATOR_USERNAMES}
      queryConnection="operators"
      filterFieldName="username"
      filters={filters}
      onChange={(e) =>
        handleChange({
          name: 'username',
          value: e?.length ? { in: e } : null,
        })
      }
    />

    <IdBasedSelectFilterField
      testId="operator-permission-group-filter"
      label="Permission Groups"
      query={GET_PERMISSION_GROUPS}
      queryConnection="permissionGroups"
      filterFieldName="name"
      filters={filters}
      isCustomFilter={isCustomFilter}
      rawFilterValues={coercedGet(filters, 'permissionGroups.overlaps', [])}
      partialSupported={false}
      onChange={(e) =>
        handleChange({
          name: 'permissionGroups',
          value: e?.length ? { overlaps: e } : null,
        })
      }
    />

    <DateTimeRangePicker
      testId="operator-registration-date-filter"
      label="registration-date"
      value={
        filters.registrationDateTime
          ? [filters.registrationDateTime.gte, filters.registrationDateTime.lte]
          : null
      }
      onChange={(e) =>
        handleChange({
          name: 'registrationDateTime',
          value: e?.length ? { gte: e[0], lte: e[1] } : null,
        })
      }
    />

    <DateTimeRangePicker
      testId="operator-last-login-date-filter"
      label="last-login-date"
      value={
        filters.lastLoginDateTime
          ? [filters.lastLoginDateTime.gte, filters.lastLoginDateTime.lte]
          : null
      }
      onChange={(e) =>
        handleChange({
          name: 'lastLoginDateTime',
          value: e?.length ? { gte: e[0], lte: e[1] } : null,
        })
      }
    />

    <CheckboxFilterField
      filters={filters}
      filterFieldName="status"
      filterEnum={OperatorStatus}
      label="Status"
      onChange={(e) =>
        handleChange({
          name: 'status',
          value: e.length ? { in: e } : null,
        })
      }
    />
  </>
);

export default FilterItems;
