import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { HexoPayDeposit } from 'types/graphqlTypes';

interface Props {
  deposit: HexoPayDeposit;
}

const AccountInformation = (props: Props) => {
  const { deposit } = props;

  const { Text } = Typography;

  const {
    hexoPayCreditCard,
    transactionReference,
    paymentMethod,
    account,
  } = deposit;

  const { firstSixDigits, lastFourDigits, expirationMonth, expirationYear } =
    hexoPayCreditCard || {};

  const isEmail =
    paymentMethod?.name === 'Neteller' || paymentMethod?.name === 'Skrill';

  return (
    <>
      <div className="menu-header p-2 mt-4">Payment Info</div>
      {isEmail ? (
        <div className="d-flex flex-column p-2 bb-1">
          <div>
            <Text type="secondary">Payment Account Details</Text>
          </div>
          <div className="flex-1 text-left">
            {paymentMethod?.name}: {account.initialEmail}
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex flex-column p-2 bb-1">
            <div>
              <Text type="secondary">
                <FormattedMessage
                  id="payment-account-number.text"
                  defaultMessage="Payment account number"
                />
              </Text>
            </div>
            <div className="flex-1 text-left">
              {hexoPayCreditCard
                ? `${firstSixDigits} *** *** ${lastFourDigits} ${expirationMonth}/${expirationYear}`
                : '-'}
            </div>
          </div>
          <div className="d-flex flex-column p-2 bb-1">
            <div>
              <Text type="secondary">
                <FormattedMessage
                  id="hexopay-transaction-UID.text"
                  defaultMessage="Hexopay Transaction UID"
                />
              </Text>
            </div>
            <div className="flex-1 text-left">
              {transactionReference || '-'}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AccountInformation;
