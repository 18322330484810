import { isEmpty } from 'lodash';

type Props = {
  me: any;
  processor: Record<string, any>;
  status: string;
  externalStatus?: string | null;
};

export const isReadOnly = ({
  me,
  processor,
  status,
  externalStatus = null,
}: Props): any => {
  const differentProcessor = !isEmpty(processor) && me.id !== processor.id;
  const readOnlyStatus = ['APPROVED', 'REJECTED', 'FAILED', 'EXPIRED'].includes(
    status
  );

  return differentProcessor || externalStatus || readOnlyStatus;
};
