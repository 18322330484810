import baseMoment from 'moment';
import mtz from 'moment-timezone';

// Import all supported locales here
import 'moment/locale/zh-cn';
import 'moment/locale/en-au';
// etc

const copyLocaleData = (source: typeof baseMoment, dest: typeof mtz) => {
  // Need to copy over all locale config to the copy of moment that lives in moment-timezone
  const installedLocales = dest.locales();
  // eslint-disable-next-line no-restricted-syntax
  for (const locale of source.locales()) {
    if (!installedLocales.includes(locale)) {
      const localeData = source.localeData(locale);
      // eslint-disable-next-line no-underscore-dangle
      const localeSpec: mtz.LocaleSpecification = (localeData as any)._config;
      dest.defineLocale(locale, localeSpec);
    }
  }
};

copyLocaleData(baseMoment, mtz);

// Re-export so that users don’t need to know the actual import library we use
export { mtz as momentCreator };
