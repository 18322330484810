import { defineMessages } from 'react-intl';

const localMessages = defineMessages({
  FILTERS: {
    id: 'reports.filters.text',
    defaultMessage: 'Filters',
  },
  PERIOD: {
    id: 'reports.period.text',
    defaultMessage: 'Period',
  },
  VENDOR: {
    id: 'reports.vendor.text',
    defaultMessage: 'Vendor',
  },

  BET_STATUS: {
    id: 'reports.bet-status.text',
    defaultMessage: 'Bet Status',
  },
  CLIENT_ACCOUNTS: {
    id: 'reports.client-accounts.text',
    defaultMessage: 'Client Accounts',
  },

  TIMEZONE_SELECTION: {
    id: 'reports.timezone-selection.text',
    defaultMessage: 'Timezone Selection',
  },
  MEMBER_ID: {
    id: 'reports.member-id.text',
    defaultMessage: 'Member ID',
  },
  NO_DATE_GIVEN: {
    id: 'NO_DATE_GIVEN',
    defaultMessage: 'No Date Given',
  },
});

export default localMessages;
