import React, { ReactElement } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Col,
  Input,
  Radio,
  Row,
  Select,
  message,
  Popconfirm,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import TextArea from 'antd/lib/input/TextArea';
import { StyledForm } from './styles';
// import validationSchema from './validationSchema';

type Props = {
  initialValues?: Record<string, any>;
  isLoading?: boolean;
  setDeletedTags?: Function;
};

const EditPromoForm = ({ isLoading }: Props) => {
  const method = useForm();
  const { control, handleSubmit, reset, setValue } = method;

  const onSubmit = () => {
    reset();
    message.success('Promo updated');
  };

  // for demo purposes only
  const handlePromoSelect = (e: string) => {
    if (e) {
      setValue('promo', e);

      setValue(
        'promo-details',
        `${e} Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequuntur tempora error consequatur cum quisquam! Minus dolor amet eaque eligendi nemo.`
      );
    }
  };

  return (
    <FormProvider {...method}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledForm>
          <div className="p-3">
            <Controller
              control={control}
              name="action"
              render={({ onChange, value }): ReactElement => (
                <Form.Item
                  label={
                    <FormattedMessage
                      id="action.text"
                      defaultMessage="Action"
                    />
                  }
                >
                  <Radio.Group
                    name="action"
                    onChange={onChange}
                    value={value?.target.value ?? 'subscribe'}
                  >
                    <Radio value="subscribe">
                      <FormattedMessage
                        id="subscribe.text"
                        defaultMessage="Subscribe"
                      />
                    </Radio>
                    <Radio value="unsubscribe">
                      <FormattedMessage
                        id="unsubscribe.text"
                        defaultMessage="Un-Subscribe"
                      />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              )}
            />

            <Controller
              control={control}
              name="promo"
              render={({ value }): ReactElement => (
                <Form.Item>
                  <div className="d-flex flex-direction-column">
                    <FormattedMessage
                      id="select-promo.text"
                      defaultMessage="Select Promo:"
                    />
                    <Select
                      value={value}
                      onChange={handlePromoSelect}
                      options={[
                        {
                          value: 'Promo 1',
                          label: 'Promo 1',
                        },
                        {
                          value: 'Promo 2',
                          label: 'Promo 2',
                        },
                        {
                          value: 'Promo 3',
                          label: 'Promo 3',
                        },
                      ]}
                    />
                  </div>
                </Form.Item>
              )}
            />

            <Controller
              control={control}
              name="promo-details"
              render={({ onChange, value }): ReactElement => (
                <Form.Item>
                  <FormattedMessage
                    id="promo-details.text"
                    defaultMessage="Promo Details"
                  />
                  <TextArea
                    readOnly
                    placeholder="Promo Details"
                    value={value}
                    onChange={onChange}
                    style={{ height: 150 }}
                    maxLength={500}
                  />
                </Form.Item>
              )}
            />

            <Controller
              control={control}
              name="transaction-record"
              render={({ onChange, value }): ReactElement => (
                <Form.Item>
                  <FormattedMessage
                    id="operator-transaction-record-remark.text"
                    defaultMessage="Operator Transaction Record Remark"
                  />
                  <TextArea
                    placeholder="Please Enter"
                    value={value}
                    style={{ height: 150 }}
                    onChange={onChange}
                    maxLength={500}
                  />
                </Form.Item>
              )}
            />

            <Row className="mt-2" gutter={16}>
              <Col span={12}>
                <span className="fs-13">
                  <FormattedMessage
                    id="account-password.text"
                    defaultMessage="Account Password"
                  />
                </span>

                <Controller
                  as={Input.Password}
                  control={control}
                  name="password"
                  style={{ width: '100%' }}
                />
              </Col>
            </Row>
          </div>
          <div className="bt-1 p-3 text-right">
            <Button
              className="mr-2"
              onClick={() => reset()}
              loading={isLoading}
            >
              <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
            </Button>
            <Popconfirm
              title="Are you sure you want to update promo"
              onConfirm={onSubmit}
              onCancel={() => reset()}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" htmlType="submit" loading={isLoading}>
                <FormattedMessage
                  id="save-changes.text"
                  defaultMessage="Save changes"
                />
              </Button>
            </Popconfirm>
          </div>
        </StyledForm>
      </form>
    </FormProvider>
  );
};

export default EditPromoForm;
