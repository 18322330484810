import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Radio } from 'antd';
import { useFormik } from 'formik';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import { BulkProps } from 'interfaces/bulkProps.interface';
import { compact } from 'lodash';
import MoreActionsMembersFilter from 'components/MoreActionsMembersFilter/MoreActionsMembersFilter';
import MemberTagsSelect from 'components/MemberTagsSelect';
import MemberTagsPreviousNew from 'components/MemberTagsPreviousNew';
import { StyledForm } from './styles';
import { MEMBER_TAGS } from './queries';
import { validationSchema } from './validationSchema';

const BulkEditTagMenuForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
  onSelectUser,
}: BulkProps) => {
  const [tempTags, setTempTags] = useState<any>([]);
  const { formatMessage } = useIntl();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: validationSchema(formatMessage),
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
  } = formik;
  const translate = useTranslate();

  const { data } = useQuery(MEMBER_TAGS, {
    variables: {
      filter: {
        id: {
          in: values?.username?.length
            ? values?.username?.map(({ key }: { key: string }) => key)
            : [],
        },
      },
    },
  });

  let tagIds: string[] = [];
  coercedGet(data, 'members.edges', [])
    .map((q: Record<string, any>) => q?.node?.tags || [])
    .map((q: Record<string, any>) => {
      if (Array.isArray(q) && q.length) {
        q.map((r) => tagIds.push(r?.id));
      }
      return null;
    });

  tagIds = compact([...(new Set(tagIds) as any)]);

  return (
    <StyledForm onSubmit={handleSubmit}>
      <div className="p-3">
        <div className="mb-3">
          <MoreActionsMembersFilter
            value={values.username}
            placeholder={translate(messages.ENTER_USERNAME)}
            onChange={(e) => {
              if (onSelectUser) {
                onSelectUser(e);
              }
              setFieldValue('username', e);
            }}
            validateStatus={
              errors.username && touched.username ? 'error' : undefined
            }
            help={touched.username ? errors.username : null}
          />
        </div>
        <Form.Item
          label={translate(messages.TYPE)}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          validateStatus={errors.type && touched.type ? 'error' : undefined}
          help={touched.type ? errors.type : null}
        >
          <Radio.Group
            name="type"
            onChange={(e) => {
              handleChange(e);
              setFieldValue('tags', initialValues.tags);
            }}
            value={values.type || ''}
          >
            <Radio value="ADD_REMOVE">
              <FormattedMessage
                id="add-or-remove.text"
                defaultMessage="Add/Remove"
              />
            </Radio>
            <Radio value="EDIT">
              <FormattedMessage id="edit.text" defaultMessage="Edit" />
            </Radio>
          </Radio.Group>
        </Form.Item>
        {values.type === 'ADD_REMOVE' ? (
          <Form.Item
            label={translate(messages.LABELS)}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            validateStatus={errors.tags && touched.tags ? 'error' : undefined}
            help={touched.tags ? errors.tags : null}
          >
            <MemberTagsSelect
              value={values.tags.filter(
                (q: { id: string }) =>
                  tagIds.includes(q?.id) || tempTags.includes(q?.id)
              )}
              onChange={(e) => {
                setTempTags(e.map((q: { id: string }) => q?.id));
                setFieldValue('tags', e);
              }}
            />
          </Form.Item>
        ) : (
          <Form.Item
            label={translate(messages.EDIT)}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            validateStatus={
              (errors.tags && touched.tags) ||
              (errors.userSelected && touched.userSelected)
                ? 'error'
                : undefined
            }
            help={
              touched.tags || touched.userSelected
                ? errors.tags || errors.userSelected
                : null
            }
          >
            <MemberTagsPreviousNew
              users={values?.username?.map((user: { key: string }) => user.key)}
              onPreviousTagSelected={(e) =>
                setFieldValue('previousTagSelected', e)
              }
              onUserSelected={(e) => setFieldValue('userSelected', e)}
              onChange={(e) => setFieldValue('tags', e)}
            />
          </Form.Item>
        )}
      </div>
      <div className="bt-1 p-3 text-right">
        <Button className="mr-2" onClick={onClose} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default BulkEditTagMenuForm;
