import React from 'react';
import { ProductFilter } from 'pages/components/Reports/DailyOperatingReport/components/Sidebar/ProductFilter';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { DynamicObj } from 'interfaces/user.interface';

type Props = {
  filters: Record<string, any>;
  handleFilterChange: (value: DynamicObj) => void;
};

const FilterItems = ({ filters, handleFilterChange }: Props) => (
  <>
    <DateTimeRangePicker
      testId="DOR-date-filter"
      label="date-range"
      value={filters.date ? [filters.date.gte, filters.date.lte] : null}
      onChange={(e) => {
        handleFilterChange({
          name: 'date',
          value: e?.length ? { gte: e[0], lte: e[1] } : null,
        });
      }}
    />

    <FilterItem label="Product">
      <ProductFilter filters={filters} setFilters={handleFilterChange} />
    </FilterItem>
  </>
);

export default FilterItems;
