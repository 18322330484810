import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import messages from '../messages';
import { StyledRootDiv } from './styles';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  width: 300px;
`;

const StyledParentDiv = styled.div<any>`
  width: ${(props) => `${300 * props.options.second.length}px`};
  padding-bottom: 100px;
  overflow: hidden;
`;

const DepositsTree = () => {
  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const [checked, setChecked] = useState({
    all: {
      first: false,
      DEPOSITS_INTERNAL_DEPOSITS: false,
      DEPOSITS_EXTERNAL_DEPOSITS: false,
      DEPOSITS_PAYMENT_METHODS: false,
      DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS: false,
      DEPOSITS_INTERNAL_DEPOSITS_LIST: false,
      DEPOSITS_EXTERNAL_DEPOSITS_LIST: false,
      DEPOSITS_PAYMENT_METHODS_LIST: false,
      DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_LIST: false,
    },
    indeterminate: {
      first: false,
      DEPOSITS_INTERNAL_DEPOSITS: false,
      DEPOSITS_EXTERNAL_DEPOSITS: false,
      DEPOSITS_PAYMENT_METHODS: false,
      DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS: false,
    },
    currentList: {
      DEPOSITS_INTERNAL_DEPOSITS_GRP: [],
      DEPOSITS_EXTERNAL_DEPOSITS_GRP: [],
      DEPOSITS_PAYMENT_METHODS_GRP: [],
      DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_GRP: [],
    },
  });

  const defaultValues = Object.values(ALL_PERMISSIONS.ALL_DEPOSITS);

  const levels = [
    ['first', 0],
    ['second', 1],
    ['third', 2],
  ];

  const options: Record<string, any> = levels.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr[0]}`]: Object.entries(ALL_PERMISSIONS.ALL_DEPOSITS)
        .filter((e) => (e[1].match(/:/g) || []).length === curr[1])
        .map((e) => e[0]),
    }),
    {}
  );

  const handleFirstLevelChange = (e: Record<string, any>) => {
    const list = e.target.checked ? defaultValues : [];
    setFieldValue('permissions[ALL_DEPOSITS]', list);
  };

  const handleSecondLevelChange = (e: Record<string, any>) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_DEPOSITS];
    const secondLevelValues = defaultValues.filter(
      (v) =>
        (v.match(/:/g) || []).length >= 1 &&
        v.includes(ALL_PERMISSIONS.ALL_DEPOSITS[name])
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, ...secondLevelValues];
    } else {
      list = targetPermissions.filter((p) => !secondLevelValues.includes(p));
    }

    setFieldValue(
      'permissions[ALL_DEPOSITS]',
      uniq(list.length > 1 ? ['DEPOSITS', ...list] : [])
    );
  };

  const handleThirdLevelChange = (e: Record<string, any>) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_DEPOSITS];

    const unlistedName =
      ALL_PERMISSIONS.ALL_DEPOSITS[name.replace('_LIST', '')];

    const thirdLevelValues = defaultValues.filter(
      (v) => (v.match(/:/g) || []).length >= 2 && v.includes(unlistedName)
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, unlistedName, ...thirdLevelValues];
    } else {
      list = targetPermissions.filter(
        (p) => !thirdLevelValues.includes(p) && p !== unlistedName
      );
    }

    setFieldValue(
      'permissions[ALL_DEPOSITS]',
      uniq(list.length >= 3 ? ['DEPOSITS', ...list] : [])
    );
  };

  const handleOnChange = (list: any[], name: string) => {
    const stateName = name.replace('_GRP', '');

    const secondLevelPermissionLookup = ALL_PERMISSIONS.ALL_DEPOSITS[stateName];
    const thirdLevelPermissionLookup =
      ALL_PERMISSIONS.ALL_DEPOSITS[`${stateName}_LIST`];

    const thirdLevelPermissions = Object.values(
      ALL_PERMISSIONS.ALL_DEPOSITS
    ).filter((p) => (p.match(/:/g) || []).length === 2);

    const targetPermissions = [...values.permissions.ALL_DEPOSITS];

    const templatePermissions = thirdLevelPermissions.filter(
      (p) =>
        p.includes(secondLevelPermissionLookup) &&
        p !== secondLevelPermissionLookup &&
        p !== thirdLevelPermissionLookup
    );

    setFieldValue(
      'permissions[ALL_DEPOSITS]',
      uniq([
        ...targetPermissions.filter((p) => !templatePermissions.includes(p)),
        'DEPOSITS',
        secondLevelPermissionLookup,
        thirdLevelPermissionLookup,
        ...list,
      ])
    );
  };

  const availablePermissions = coercedGet(
    values,
    'permissions.ALL_DEPOSITS',
    []
  );
  useEffect(() => {
    const permissionMatch =
      availablePermissions.length ===
      Object.values(ALL_PERMISSIONS.ALL_DEPOSITS).length;

    const thirdLevelPermissions = availablePermissions.filter(
      (p: any) => (p.match(/:/g) || []).length === 2
    );

    const secondLevelKeys = [
      ['DEPOSITS_INTERNAL_DEPOSITS', 'DEPOSITS:INTERNAL_DEPOSITS', 6],
      ['DEPOSITS_EXTERNAL_DEPOSITS', 'DEPOSITS:EXTERNAL_DEPOSITS', 6],
      ['DEPOSITS_PAYMENT_METHODS', 'DEPOSITS:PAYMENT_METHODS', 9],
      [
        'DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS',
        'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS',
        6,
      ],
    ];

    const thirdLevelChecks: Record<string, any> = secondLevelKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr[0]}`]:
          thirdLevelPermissions.filter((p: any) => p.includes(`${curr[1]}`))
            .length === curr[2],
      }),
      {}
    );

    const allChecks = {
      DEPOSITS_INTERNAL_DEPOSITS:
        permissionMatch || thirdLevelChecks.DEPOSITS_INTERNAL_DEPOSITS,
      DEPOSITS_EXTERNAL_DEPOSITS:
        permissionMatch || thirdLevelChecks.DEPOSITS_EXTERNAL_DEPOSITS,
      DEPOSITS_PAYMENT_METHODS:
        permissionMatch || thirdLevelChecks.DEPOSITS_PAYMENT_METHODS,
      DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS:
        permissionMatch ||
        thirdLevelChecks.DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS,
    };

    setChecked({
      all: {
        first: permissionMatch,
        DEPOSITS_INTERNAL_DEPOSITS: allChecks.DEPOSITS_INTERNAL_DEPOSITS,
        DEPOSITS_EXTERNAL_DEPOSITS: allChecks.DEPOSITS_EXTERNAL_DEPOSITS,
        DEPOSITS_PAYMENT_METHODS: allChecks.DEPOSITS_PAYMENT_METHODS,
        DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS:
          allChecks.DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS,

        DEPOSITS_INTERNAL_DEPOSITS_LIST:
          thirdLevelPermissions.includes('DEPOSITS:INTERNAL_DEPOSITS:LIST') ||
          allChecks.DEPOSITS_INTERNAL_DEPOSITS,
        DEPOSITS_EXTERNAL_DEPOSITS_LIST:
          thirdLevelPermissions.includes('DEPOSITS:EXTERNAL_DEPOSITS:LIST') ||
          allChecks.DEPOSITS_EXTERNAL_DEPOSITS,
        DEPOSITS_PAYMENT_METHODS_LIST:
          thirdLevelPermissions.includes('DEPOSITS:PAYMENT_METHODS:LIST') ||
          allChecks.DEPOSITS_PAYMENT_METHODS,
        DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_LIST:
          thirdLevelPermissions.includes(
            'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS:LIST'
          ) || allChecks.DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS,
      },
      indeterminate: {
        first: availablePermissions.length && !permissionMatch,

        DEPOSITS_INTERNAL_DEPOSITS:
          thirdLevelPermissions.filter((p: string) =>
            p.includes('DEPOSITS:INTERNAL_DEPOSITS')
          ).length && !thirdLevelChecks.DEPOSITS_INTERNAL_DEPOSITS,
        DEPOSITS_EXTERNAL_DEPOSITS:
          thirdLevelPermissions.filter((p: string) =>
            p.includes('DEPOSITS:EXTERNAL_DEPOSITS')
          ).length && !thirdLevelChecks.DEPOSITS_EXTERNAL_DEPOSITS,
        DEPOSITS_PAYMENT_METHODS:
          thirdLevelPermissions.filter((p: string) =>
            p.includes('DEPOSITS:PAYMENT_METHODS')
          ).length && !thirdLevelChecks.DEPOSITS_PAYMENT_METHODS,
        DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS:
          thirdLevelPermissions.filter((p: string) =>
            p.includes('DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS')
          ).length && !thirdLevelChecks.DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS,
      },
      currentList: {
        DEPOSITS_INTERNAL_DEPOSITS_GRP: thirdLevelPermissions.filter(
          (p: string) =>
            p.includes('DEPOSITS:INTERNAL_DEPOSITS') &&
            p !== 'DEPOSITS:INTERNAL_DEPOSITS' &&
            p !== 'DEPOSITS:INTERNAL_DEPOSITS:LIST'
        ),
        DEPOSITS_EXTERNAL_DEPOSITS_GRP: thirdLevelPermissions.filter(
          (p: string) =>
            p.includes('DEPOSITS:EXTERNAL_DEPOSITS') &&
            p !== 'DEPOSITS:EXTERNAL_DEPOSITS' &&
            p !== 'DEPOSITS:EXTERNAL_DEPOSITS:LIST'
        ),
        DEPOSITS_PAYMENT_METHODS_GRP: thirdLevelPermissions.filter(
          (p: string) =>
            p.includes('DEPOSITS:PAYMENT_METHODS') &&
            p !== 'DEPOSITS:PAYMENT_METHODS' &&
            p !== 'DEPOSITS:PAYMENT_METHODS:LIST'
        ),
        DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS_GRP: thirdLevelPermissions.filter(
          (p: string) =>
            p.includes('DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS') &&
            p !== 'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS' &&
            p !== 'DEPOSITS:THIRD_PARTY_DEPOSIT_PROVIDERS:LIST'
        ),
      },
    });
  }, [availablePermissions]);
  const translate = useTranslate();
  return (
    <StyledRootDiv>
      <StyledParentDiv options={options}>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={checked.all.first}
              indeterminate={checked.indeterminate.first}
              onChange={handleFirstLevelChange}
            >
              <StyledText size={16}>
                {translate(messages[ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS])}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <div className="w-100">
          <div className="mt-4 d-flex ml-3">
            {options.second.map((s: any) => (
              <StyledDiv>
                <Checkbox
                  key={s}
                  name={s}
                  checked={checked.all[s]}
                  indeterminate={checked.indeterminate[s]}
                  onChange={handleSecondLevelChange}
                >
                  <StyledText size={14}>{translate(messages[s])}</StyledText>
                </Checkbox>
                <div className="ml-4 mt-2">
                  <Checkbox
                    key={`${s}_LIST`}
                    name={`${s}_LIST`}
                    checked={checked.all[`${s}_LIST`]}
                    onChange={handleThirdLevelChange}
                  >
                    <StyledText size={14}>
                      {translate(messages.LIST)}
                    </StyledText>
                  </Checkbox>
                  <Checkbox.Group
                    className="w-100 ml-2"
                    onChange={(v) => handleOnChange(v, `${s}_GRP`)}
                    value={checked.currentList[`${s}_GRP`]}
                  >
                    <div className="ml-4">
                      {options.third
                        .filter(
                          (t: any, idx: number) =>
                            t.includes(s) &&
                            idx !== 0 &&
                            idx !== 6 &&
                            idx !== 12 &&
                            idx !== 21
                        )
                        .map((t: any) => (
                          <StyledDiv className="mt-2">
                            <Checkbox
                              key={ALL_PERMISSIONS.ALL_DEPOSITS[t]}
                              value={ALL_PERMISSIONS.ALL_DEPOSITS[t]}
                            >
                              <StyledText size={14}>
                                {translate(messages[t.replace(`${s}_`, '')])}
                              </StyledText>
                            </Checkbox>
                          </StyledDiv>
                        ))}
                    </div>
                  </Checkbox.Group>
                </div>
              </StyledDiv>
            ))}
          </div>
        </div>
      </StyledParentDiv>
    </StyledRootDiv>
  );
};

export default DepositsTree;
