import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'dashboard.total-bet.text': {
    id: 'dashboard.total-bet.text',
    defaultMessage: 'Total bet amount',
  },
  'dashboard.total-deposits.text': {
    id: 'dashboard.total-deposits.text',
    defaultMessage: 'Total deposit amount',
  },
  'dashboard.total-withdrawal.text': {
    id: 'dashboard.total-withdrawal.text',
    defaultMessage: 'Total withdrawal',
  },
  'dashboard.total-win-loss.text': {
    id: 'dashboard.total-win-loss.text',
    defaultMessage: 'Total win/loss',
  },
  'dashboard.total-net-profit.text': {
    id: 'dashboard.total-net-profit.text',
    defaultMessage: 'Total net profit',
  },
  'online-members.text': {
    id: 'online-members.text',
    defaultMessage: 'Online Members',
  },
  'dashboard.total-first-depositors.text': {
    id: 'dashboard.total-first-depositors.text',
    defaultMessage: 'Total First Depositors',
  },
  'dashboard.total-members-registration.text': {
    id: 'dashboard.total-members-registration.text',
    defaultMessage: 'Total Members Registration',
  },
});

export default messages;
