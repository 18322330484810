import { useScreenTabV2 } from 'store/screenTabState';
import { Member } from 'types/graphqlTypes';

/*
 * Add here the functions for handling tab
 * */

export const useMemberTab = () => {
  const { addTab } = useScreenTabV2();

  return (member: Partial<Member>) => {
    addTab({
      id: 'member-detail',
      memberId: member.id,
      state: {
        username: member.username,
        translateKeyId: 'member/username',
        translateOpts: {
          memberUsername: member.username,
        },
      },
    });
  };
};
