import React, { Dispatch, SetStateAction, useState } from 'react';
import { Drawer, Button, Select, Typography, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { GeoFenceConfig, GeoFenceRule, Place } from 'types/graphqlTypes';
import { capitalize } from 'lodash';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_GEOFENCING_CONFIG } from 'graphql/mutations/geofencingConfig.mutation';
import { Maybe } from 'graphql-tools';
import GeoFencingInput from './GeoFencingInput';

type Props = {
  drawerState: {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
  };
  geoFenceConfig: GeoFenceConfig | undefined;
};

export default ({ drawerState, geoFenceConfig }: Props) => {
  const [values, setValues] = useState(geoFenceConfig!);

  const handleCloseDrawer = () => {
    drawerState.setShow((prev) => !prev);
  };

  const [updateGeoFenceConfig, { loading: mutationLoading }] = useMutation(
    UPDATE_GEOFENCING_CONFIG
  );

  const getIds = (places: Maybe<Maybe<Place>[]>) =>
    places?.map((place) => place?.id);

  const handleUpdate = () => {
    const {
      whitelistTarget,
      blacklistTarget,
      blacklistExcluded,
      whitelistExcluded,
      whitelistTargetIPs,
      whitelistExcludedIPs,
      blacklistExcludedIPs,
      blacklistTargetIPs,
      activeRule,
    } = values || {};

    updateGeoFenceConfig({
      variables: {
        input: {
          type: 'FRONTEND',
          activeRule,
          whitelistExcludedIPs,
          whitelistTargetIPs,
          blacklistExcludedIPs,
          blacklistTargetIPs,
          whitelistTarget: getIds(whitelistTarget),
          whitelistExcluded: getIds(whitelistExcluded),
          blacklistTarget: getIds(blacklistTarget),
          blacklistExcluded: getIds(blacklistExcluded),
        },
      },
    })
      .then(() => {
        message.success('Geofence config updated!');
      })
      .finally(() => {
        handleCloseDrawer();
      });
  };

  return (
    <>
      <Drawer
        title="Geo-Fencing Settings"
        visible={drawerState.show}
        width="100%"
        onClose={handleCloseDrawer}
        footer={
          <div className="text-right">
            <Button onClick={handleCloseDrawer} className="mr-3">
              <FormattedMessage id="back.text" defaultMessage="Back" />
            </Button>
            <Button
              type="primary"
              onClick={handleUpdate}
              loading={mutationLoading}
            >
              <FormattedMessage id="save.text" defaultMessage="Save" />
            </Button>
          </div>
        }
      >
        <div className="d-flex flex-column">
          <Typography.Text>Active Rule</Typography.Text>
          <Select
            className="mb-3"
            size="middle"
            style={{
              width: '140px',
            }}
            value={values.activeRule}
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                activeRule: e,
              }))
            }
          >
            {Object.values(GeoFenceRule).map((rule) => (
              <Select.Option key={rule} value={rule}>
                {capitalize(rule)}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          {['whitelist', 'blacklist'].map((item, index) => (
            <GeoFencingInput
              valueState={{
                values,
                setValues,
              }}
              key={index}
              type={item}
            />
          ))}
        </div>
      </Drawer>
    </>
  );
};
