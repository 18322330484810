import React from 'react';
import { LayoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useAccountManagementValue } from '../../context';

interface Props {}

export const SideLeft: React.FC<Props> = () => {
  const { setCollapsed } = useAccountManagementValue();

  return (
    <Container>
      <Button
        onClick={() => setCollapsed((prev: boolean) => !prev)}
        size="small"
      >
        <LayoutOutlined className="layout-image" />
      </Button>

      <div className="search-criteria">
        <FormattedMessage
          id="search-criteria-text.text"
          defaultMessage="Search Criteria:"
        />
      </div>

      {/* <FilterTag name="订单号Partial: W00" /> */}

      <div className="clear-all">
        {/* <a href="#/">
          <FormattedMessage id="clear-all.text" defaultMessage="Clear all" />
        </a> */}
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  button {
    background: #f2f2f2;
    margin: 0 16px;
    border-radius: 4px;
    border: none;

    .layout-image {
      margin-top: 4px;
    }
  }

  .search-criteria {
    margin-right: 12px;
  }

  .clear-all {
    margin-left: 12px;
  }

  color: #000000d9;
`;
