import gql from 'graphql-tag';

export const GET_MEMBER_ACCESS_SUMMARY_REPORT = gql`
  query memberAccessSummaryReport(
    $first: Int
    $after: Binary
    $filter: MemberAccessSummaryReportItemsFilterInput
  ) {
    memberAccessSummaryReport(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          member {
            id
            username
            brandId
            platformId
            affiliate {
              id
              code
            }
            brandId
            platformId
            realName
          }
          ipAddressesCount
          lastLoginDateTime
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_MASR_STRING_BASED = gql`
  query MASR_IDS(
    $filter: MemberAccessSummaryReportItemsFilterInput
    $partialFilter: MemberAccessSummaryReportItemsFilterInput
  ) {
    memberAccessSummaryReport(filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
    partial: memberAccessSummaryReport(filter: $partialFilter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_MASR_ID_BASED = gql`
  query MASR_ID_BASED(
    $username: MembersFilterInput
    $realName: MembersFilterInput
  ) {
    username: members(filter: $username) {
      edges {
        node {
          id
          username
        }
      }
    }
    realName: members(filter: $realName) {
      edges {
        node {
          id
          realName
        }
      }
    }
  }
`;

export const GET_MASR_USERNAMES = gql`
  query FilterMASRUsernames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;
export const GET_MASR_REALNAMES = gql`
  query FilterMASRRealnames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          realName
        }
      }
    }
  }
`;

export default {};
