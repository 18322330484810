import * as yup from 'yup';
import { CustomRegex } from 'utils/regex';
import messages from 'messages';

const validationSchema = (translate: any) =>
  yup.object().shape({
    password: yup
      .string()
      .nullable()
      .matches(
        CustomRegex.password(),
        translate(messages.PASSWORD_VALIDATION_FIELD)
      )
      .required(translate(messages.required)),
  });

export default validationSchema;
