import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import FilterItems from './components/FilterItems';

const Sidebar = (props: any) => {
  const { filters, onFilterChange } = props;
  const handleChange = (e: Record<string, any>) => {
    const { name, value } = e;
    const newFilters = {
      ...filters,
      [name]: value,
    };
    onFilterChange(newFilters);
  };

  return (
    <Form layout="vertical">
      <FilterItems filters={filters} onRawFilterChange={handleChange} />
    </Form>
  );
};

export default Sidebar;
