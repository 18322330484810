import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, message, Modal } from 'antd';
import { DUPLICATE_LOYALTY_PROGRAM } from 'graphql/mutations/vip.mutation';
import { MEMBER_LOYALTY_PROGRAMS } from 'graphql/queries/vip.query';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

type Props = {
  programId: string;
};

const DuplicateProgramme = ({ programId }: Props) => {
  const translate = useTranslate();
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const [duplicateLoyaltyProgram, { loading }] = useMutation(
    DUPLICATE_LOYALTY_PROGRAM,
    {
      onCompleted: () => {
        message.success(
          translate(
            messages['member-loyalty.loyalty-programme-duplicated.text']
          )
        );
      },
    }
  );

  return (
    <>
      <Button type="link" className="text-black" onClick={handleShow} block>
        <FormattedMessage id="duplicate.text" defaultMessage="Duplicate" />
      </Button>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          cancelButtonProps={{ disabled: loading }}
          okButtonProps={{ loading }}
          onCancel={handleShow}
          onOk={() => {
            duplicateLoyaltyProgram({
              variables: {
                id: programId,
              },
              refetchQueries: [
                {
                  query: MEMBER_LOYALTY_PROGRAMS,
                  variables: { first: 10, filter: {} },
                },
              ],
            });

            handleShow();
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-duplicate.text"
                defaultMessage="Confirm Duplicate"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DuplicateProgramme;
