import { defineMessages, FormattedMessage } from 'react-intl';
import { Tag } from 'antd';
import { get, compact } from 'lodash';
import MemberLevelTag from 'components/MemberLevelTag';
import { formatDate } from 'utils/dateUtils';
import MemberLoyaltyLevelTag from 'components/MemberLoyaltyLevelTag';
import React from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import filterCollectionByIds from 'utils/filterCollectionByIds';
import { StyledColorSquare } from 'components/MemberTagsSelect/styles';
import gql from 'graphql-tag';
import coercedGet from 'utils/coercedGet';
import { useConfig } from 'hooks/useConfig';
import useTranslate from 'utils/useTranslate';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';

export const MEMBER_TAGS = gql`
  query memberTags(
    $first: Int
    $after: Binary
    $filter: MemberTagsFilterInput
  ) {
    memberTags(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          color
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const messages = defineMessages({
  ENABLED: {
    id: 'enabled.text',
    defaultMessage: 'Enabled',
  },
  DISABLED: {
    id: 'disabled.text',
    defaultMessage: 'Disabled',
  },
  FROZEN: {
    id: 'frozen.text',
    defaultMessage: 'Frozen',
  },
  KICKED: {
    id: 'kicked.text',
    defaultMessage: 'Kicked',
  },
  PROFILE_VALIDATION: {
    id: 'PROFILE_VALIDATION',
    defaultMessage: 'Profile Validation',
  },
  CLOSED_LEGACY: {
    id: 'CLOSED_LEGACY',
    defaultMessage: 'Closed Legacy',
  },
  DORMANT: {
    id: 'DORMANT',
    defaultMessage: 'Dormant',
  },
  CLOSED: {
    id: 'CLOSED',
    defaultMessage: 'Closed',
  },
  BANNED: {
    id: 'BANNED',
    defaultMessage: 'Banned',
  },
  TIME_OUT: {
    id: 'TIME_OUT',
    defaultMessage: 'Time-Out',
  },
  SELF_EXCLUDED: {
    id: 'SELF_EXCLUDED',
    defaultMessage: 'Self-Excluded',
  },
  GAMSTOP_SELF_EXCLUDED: {
    id: 'GAMSTOP_SELF_EXCLUDED',
    defaultMessage: 'Gamstop Self-Excluded',
  },
  COOLING_OFF: {
    id: 'COOLING_OFF',
    defaultMessage: 'Cooling Off',
  },
  COOLING_OFF_ENDED: {
    id: 'COOLING_OFF_ENDED',
    defaultMessage: 'Cooling Off Ended',
  },
  FRAUD: {
    id: 'FRAUD',
    defaultMessage: 'Fraud',
  },
});

type Props = {
  filters: Record<string, any>;
  isLoading: boolean;
  onFilterChange: (e: any) => void;
  localStateFilter: Record<string, any>;
};

export const FilterConditions = ({
  filters,
  isLoading,
  onFilterChange,
  localStateFilter,
}: Props) => {
  const { addCurrency } = useConfig();
  const handleRemove = ({
    event,
    dataToRemove,
    filterKey,
  }: {
    event: React.MouseEvent<HTMLElement>;
    dataToRemove: string;
    filterKey: string;
  }) => {
    event.preventDefault();
    if (!isLoading) {
      const data = get(filters[filterKey], 'in', []).filter(
        (item: string) => item !== dataToRemove
      );
      const newFilter = {
        in: data,
      };
      onFilterChange({
        ...filters,
        [filterKey]: data.length ? newFilter : null,
      });
    }
  };

  const handleRemoveTag = (t: string, e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      const data = get(filters.tags, 'includesAny', []).filter(
        (item: string) => item !== t
      );
      const newTag = {
        includesAny: data,
      };
      onFilterChange({
        ...filters,
        tags: data.length ? newTag : null,
      });
    }
  };

  const handleRemoveMemberLevel = (value: string, e: Record<string, any>) => {
    e.preventDefault();
    const { memberLevel } = filters;
    const data = get(memberLevel, 'in', []).filter(
      (item: string) => item !== value
    );
    const newMemberLevel = {
      in: data,
    };
    onFilterChange({
      ...filters,
      memberLevel: data.length ? newMemberLevel : null,
    });
  };

  const handleRemoveStatus = (stat: string, e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      const { status } = filters;
      const data = get(status, 'in', []).filter(
        (item: string) => item !== stat,
        e
      );
      const newStatus = {
        in: data,
      };
      onFilterChange({
        ...filters,
        status: data.length ? newStatus : null,
      });
    }
  };

  const handleRemoveDateOfBirth = (e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      onFilterChange({
        ...filters,
        dateOfBirth: null,
      });
    }
  };

  const handleRemoveLastLoginDateTime = (e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      onFilterChange({
        ...filters,
        lastLoginDateTime: null,
      });
    }
  };

  const handleRemoveRegistrationDateTime = (e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      onFilterChange({
        ...filters,
        registrationDateTime: null,
      });
    }
  };

  const handleRemoveTotalBalance = (e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      onFilterChange({
        ...filters,
        totalBalance: null,
      });
    }
  };

  const handleRemoveTotalEffectiveBet = (e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      onFilterChange({
        ...filters,
        totalEffectiveBet: null,
      });
    }
  };

  const handleRemoveRegistrationIp = (e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      onFilterChange({
        ...filters,
        registrationIpAddress: null,
      });
    }
  };

  const handleRemoveLastLoginIp = (e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      onFilterChange({
        ...filters,
        lastLoginIpAddress: null,
      });
    }
  };

  const handleRemoveMemberLoyaltyLevel = (
    value: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const { memberLoyaltyLevel } = filters;
    const data = get(memberLoyaltyLevel, 'in', []).filter(
      (item: Record<string, any>) => item.id !== value
    );
    const newMemberLoyaltyLevel = {
      in: data,
    };
    onFilterChange({
      ...filters,
      memberLoyaltyLevel: data.length ? newMemberLoyaltyLevel : null,
    });
  };

  function handleRemoveForceVerification(status: string) {
    if (!isLoading) {
      const { forceVerification } = filters;
      const data = coercedGet(forceVerification, 'in', []).filter(
        (item: string) => item !== status
      );
      const newForceVerification = {
        in: data,
      };
      onFilterChange({
        ...filters,
        forceVerification: data.length ? newForceVerification : null,
      });
    }
  }

  function handleRemoveBankNumber(name: string) {
    if (!isLoading) {
      const { bankAccountNumber } = filters;
      const data = get(bankAccountNumber, 'in', []).filter(
        (item: string) => item !== name
      );
      onFilterChange({
        ...filters,
        bankAccountNumber: data.length
          ? {
              in: data,
            }
          : null,
      });
    }
  }

  function handleRemoveMobileNumber(name: string) {
    if (!isLoading) {
      const { mobileNumber } = filters;
      const data = get(mobileNumber, 'in', []).filter(
        (item: string) => item !== name
      );
      onFilterChange({
        ...filters,
        mobileNumber: data.length
          ? {
              in: data,
            }
          : null,
      });
    }
  }

  function handleRemoveEmail(name: string) {
    if (!isLoading) {
      const { email } = filters;
      const data = get(email, 'in', []).filter((item: string) => item !== name);
      onFilterChange({
        ...filters,
        email: data.length
          ? {
              in: data,
            }
          : null,
      });
    }
  }

  function handleRemoveQQID(name: string) {
    if (!isLoading) {
      const { qqId } = filters;
      const data = get(qqId, 'in', []).filter((item: string) => item !== name);

      onFilterChange({
        ...filters,
        qqId: data.length
          ? {
              in: data,
            }
          : null,
      });
    }
  }

  function handleRemoveWeChatID(name: string) {
    if (!isLoading) {
      const { wechatId } = filters;
      const data = get(wechatId, 'in', []).filter(
        (item: string) => item !== name
      );

      onFilterChange({
        ...filters,
        wechatId: data.length
          ? {
              in: data,
            }
          : null,
      });
    }
  }
  const translate = useTranslate();

  const FilterTag = ({ filterKey }: { filterKey: string }) =>
    coercedGet(filters, `${filterKey}.in`, []).map((item: string) => (
      <Tag
        style={{ margin: '4px' }}
        key={item}
        closable
        onClose={(e) =>
          handleRemove({
            event: e,
            dataToRemove: item,
            filterKey,
          })
        }
      >
        {item}
      </Tag>
    ));

  const handleRemoveVerified = (verificationStatus: string) => {
    if (!isLoading) {
      const { verified } = filters;
      const data = coercedGet(verified, 'in', []).filter(
        (item: string) => item !== verificationStatus
      );
      const newVerificationStatus = {
        in: data,
      };
      onFilterChange({
        ...filters,
        forceVerification: data.length ? newVerificationStatus : null,
      });
    }
  };

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT_LIST
  );

  return (
    <>
      <FormattedMessage
        id="filter-conditions.text"
        defaultMessage="Filter Conditions"
      />
      :
      <FilterTag filterKey="brandId" />
      <FilterTag filterKey="affiliateId" />
      <FilterTag filterKey="platformId" />
      <FilterTag filterKey="brand" />
      <FilterTag filterKey="username" />
      <FilterTag filterKey="realName" />
      <FilterTag filterKey="postalCode" />
      <FilterTag filterKey="tags_next" />
      {coercedGet(filters, 'forceVerification.in', []).map((force: string) => (
        <Tag
          key={force}
          closable
          onClose={() => handleRemoveForceVerification(force)}
          style={{ margin: '4px' }}
        >
          {translate(messages.PROFILE_VALIDATION)}:{' '}
          {force ? (
            <FormattedMessage id="ON" defaultMessage="On" />
          ) : (
            <FormattedMessage id="OFF" defaultMessage="Off" />
          )}
        </Tag>
      ))}
      {coercedGet(filters, 'verified.in', []).map((verified: string) => (
        <Tag
          key={verified}
          closable
          onClose={() => handleRemoveVerified(verified)}
          style={{ margin: '4px' }}
        >
          {verified ? (
            <FormattedMessage id="verified" defaultMessage="Verified" />
          ) : (
            <FormattedMessage id="not-verified" defaultMessage="Not Verified" />
          )}
        </Tag>
      ))}
      {localStateFilter.lastLoginDateTime ? (
        <Tag
          closable
          onClose={handleRemoveLastLoginDateTime}
          style={{ margin: '4px' }}
        >
          <FormattedMessage id="last-login.text" defaultMessage="Last Login" />:{' '}
          {formatDate(localStateFilter.lastLoginDateTime.gte)}~
          {formatDate(localStateFilter.lastLoginDateTime.lte)}
        </Tag>
      ) : null}
      {get(filters, 'memberLoyaltyLevel.in', []).map(
        (item: Record<string, any>, key: number) => (
          <MemberLoyaltyLevelTag
            onClose={(e) => handleRemoveMemberLoyaltyLevel(item.id, e)}
            key={key}
            id={item.id}
            islight
          />
        )
      )}
      {get(filters, 'memberLevel.in', []).map((item: string, key: number) => (
        <MemberLevelTag
          onClose={(e) => handleRemoveMemberLevel(item, e)}
          key={key}
          id={item}
        />
      ))}
      {filters.tags && ALLOWED_LIST && (
        <MemberTags filter={filters.tags} handleRemoveTag={handleRemoveTag} />
      )}
      {get(filters, 'status.in', []).map((item: string, key: number) => (
        <Tag
          style={{ margin: '4px' }}
          key={key}
          closable
          onClose={(e) => handleRemoveStatus(item, e)}
        >
          {translate(messages[item])}
        </Tag>
      ))}
      {filters.totalBalance ? (
        <Tag
          closable
          onClose={handleRemoveTotalBalance}
          style={{ margin: '4px' }}
        >
          <FormattedMessage
            id="account-balance.text"
            defaultMessage="Account Balance"
          />
          : {addCurrency(filters.totalBalance.gte, 2)} ~{' '}
          {filters.totalBalance.lte
            ? addCurrency(filters.totalBalance.lte, 2)
            : '-'}
        </Tag>
      ) : null}
      {filters.totalEffectiveBet ? (
        <Tag
          closable
          onClose={handleRemoveTotalEffectiveBet}
          style={{ margin: '4px' }}
        >
          <FormattedMessage
            id="total-effective-bet.text"
            defaultMessage="Total Effective Bet"
          />
          : {addCurrency(filters.totalEffectiveBet.gte, 2)} ~{' '}
          {filters.totalEffectiveBet.lte
            ? addCurrency(filters.totalEffectiveBet.lte, 2)
            : '-'}
        </Tag>
      ) : null}
      {filters.registrationIpAddress && (
        <Tag
          closable
          onClose={handleRemoveRegistrationIp}
          style={{ margin: '4px' }}
        >
          {filters.registrationIpAddress.in}
        </Tag>
      )}
      {filters.lastLoginIpAddress && (
        <Tag
          closable
          onClose={handleRemoveLastLoginIp}
          style={{ margin: '4px' }}
        >
          {filters.lastLoginIpAddress.in}
        </Tag>
      )}
      {filters.dateOfBirth ? (
        <Tag
          closable
          onClose={handleRemoveDateOfBirth}
          style={{ margin: '4px' }}
        >
          <FormattedMessage
            id="birthdate.text"
            defaultMessage="Date of birth"
          />
          : {formatDate(filters.dateOfBirth.gte)}~
          {formatDate(filters.dateOfBirth.lte)}
        </Tag>
      ) : null}
      {localStateFilter.registrationDateTime ? (
        <Tag
          closable
          onClose={handleRemoveRegistrationDateTime}
          style={{ margin: '4px' }}
        >
          <FormattedMessage id="joined.text" defaultMessage="Joined" />:{' '}
          {localStateFilter.registrationDateTime.eq
            ? formatDate(localStateFilter.registrationDateTime.eq)
            : `${formatDate(
                localStateFilter.registrationDateTime.gte
              )}~${formatDate(localStateFilter.registrationDateTime.lte)}`}
        </Tag>
      ) : null}
      {get(filters, 'bankAccountNumber.in', []).map(
        (item: string, key: number) => (
          <Tag
            style={{ margin: '4px' }}
            key={key}
            closable
            onClose={() => handleRemoveBankNumber(item)}
          >
            {item}
          </Tag>
        )
      )}
      {get(filters, 'mobileNumber.in', []).map((item: string, key: number) => (
        <Tag
          style={{ margin: '4px' }}
          key={key}
          closable
          onClose={() => handleRemoveMobileNumber(item)}
        >
          {item}
        </Tag>
      ))}
      {get(filters, 'email.in', []).map((item: string, key: number) => (
        <Tag
          style={{ margin: '4px' }}
          key={key}
          closable
          onClose={() => handleRemoveEmail(item)}
        >
          {item}
        </Tag>
      ))}
      {get(filters, 'qqId.in', []).map((item: string, key: number) => (
        <Tag
          style={{ margin: '4px' }}
          key={key}
          closable
          onClose={() => handleRemoveQQID(item)}
        >
          {item}
        </Tag>
      ))}
      {get(filters, 'wechatId.in', []).map((item: string, key: number) => (
        <Tag
          style={{ margin: '4px' }}
          key={key}
          closable
          onClose={() => handleRemoveWeChatID(item)}
        >
          {item}
        </Tag>
      ))}
    </>
  );
};

// eslint-disable-next-line react/prop-types
const MemberTags = ({
  filter,
  handleRemoveTag,
}: {
  filter: Record<string, any>;
  handleRemoveTag: (data: string, e: any) => void;
}) => {
  const client = useApolloClient();
  const readResult = client.readQuery({
    query: MEMBER_TAGS,
    variables: {
      filter: {},
    },
  });

  const items = get(readResult, 'memberTags.edges', []);
  const memberTags = filterCollectionByIds(
    items,
    get(filter, 'includesAny', [])
  );
  return (
    <>
      {compact(memberTags).map(({ node }) => (
        <Tag
          key={node.id}
          closable
          onClose={(e) => handleRemoveTag(node.id, e)}
          style={{ margin: '4px' }}
        >
          <StyledColorSquare color={node.color} /> {node.name}
        </Tag>
      ))}
    </>
  );
};
