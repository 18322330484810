import styled from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';
import React from 'react';

const ContainerDiv = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  alignitems: center;
`;

export const ColorBox = ({
  colorBg,
  colorBorder,
  checked,
  ...restProps
}: any) => {
  const Div = styled.div<any>`
    width: 15px;
    height: 15px;
    background-color: ${({ bgColor }) => bgColor};
    border: 1px solid ${({ borderColor }) => borderColor};
    border-radius: 2px;
    cursor: pointer;
    display: flex;
  `;
  return (
    <ContainerDiv {...restProps} data-testid={`${colorBg}-marker-container`}>
      <Div
        bgColor={colorBg}
        borderColor={colorBorder}
        data-testid={`${colorBg}-marker`}
      >
        {checked && (
          <CheckOutlined
            data-testid={`checked-${colorBg}-marker`}
            style={{ fontSize: '13px', color: 'white' }}
          />
        )}
      </Div>
    </ContainerDiv>
  );
};
