import { CloseCircleFilled } from '@ant-design/icons';
import { useApolloClient } from '@apollo/react-hooks';
import { Col, Layout, Menu, Modal, Row } from 'antd';
import LanguageSelect from 'components/LanguageSelect';
import navMessages from 'constants/navMessages';
import Storage from 'constants/storage';
import { JOB } from 'graphql/queries/job.query';
import { isFlagEnabled, useNextParam } from 'hooks/useIsNext';
import logo from 'img/gobet-logo-old.svg';
import rowLogo from 'img/gobet-logo.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { head, uniq } from 'lodash';
import { checkPermission } from 'pages/components/PermissionGroup/utils';
import qs from 'qs';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { ALink } from 'components/ALink/ALink';
import { usePermissions } from 'store/accountState';
import { jobsActions, JobStatus, JobType } from 'store/jobState/jobState';
import { useScreenTabV2 } from 'store/screenTabState';
import { Flags } from 'types/featureFlag';
import { NavItem } from 'types/nav';
import useTranslate from 'utils/useTranslate';
import uuid from 'uuid';
import XLSX from 'xlsx';
import isRow from 'utils/isRow';
import ProfileDropdown from './components/ProfileDropdown';
import RootSideBar from './components/RootSideBar';
import navList from './navList';
import { GlobalStyle, StyleNav } from './styles';

const { Header } = Layout;
const { SubMenu } = Menu;
const { error } = Modal;

export const showPermissionError = (permission: string) =>
  error({
    title: 'Access Denied',
    icon: <CloseCircleFilled style={{ color: '#f7222d' }} />,
    okText: 'Close',
    content: `Sorry, you do not have permission to access this page, you need: ${permission}`,
  });

const Navbar = () => {
  const flags = useFlags();
  const translate = useTranslate();
  const { addTab: addTabNew } = useScreenTabV2();
  const { role, permissions } = usePermissions();

  const client = useApolloClient();
  const dispatch = useDispatch();
  const { jobs, activeJobs } = useSelector((state: any) => state.jobs);

  const updateJobProgress = ({ name, id }: any) => {
    const jobInterval = setInterval(async () => {
      const response = await client.query({
        query: JOB,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      });
      const { job } = response?.data;

      if (job.status === JobStatus.FAILED && job.progress >= 1) {
        clearInterval(jobInterval);
        const response2 = await fetch(
          name === JobType.BULK_MANUAL_ADJUSTMENT
            ? response?.data?.job?.result?.errorReportFile?.uri
            : response?.data?.job?.errorReportFile?.uri
        );
        const dataBlob = await response2.blob();
        const metadata = {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        };
        const file = new File([dataBlob], 'generated_error.xlsx', metadata);
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const [firstSheetName] = workbook.SheetNames;
          const worksheet = workbook.Sheets[firstSheetName];

          const rows = XLSX.utils.sheet_to_json(worksheet, {
            raw: true, // Use raw values (true) or formatted strings (false)
            header: 1, // Generate an array of arrays ("2D Array")
          });
          rows.shift();

          const formattedRows = rows.map((row: any) => ({
            username: row[0],
            amount: row[1],
            message: row[2],
          }));
          const doneJob = {
            name,
            failedReport: formattedRows,
            ...job,
          };
          dispatch(jobsActions.updateJob(doneJob));
          dispatch(jobsActions.removeActiveJob(name));
        };
        reader.readAsArrayBuffer(file);
      }

      if (job.status === JobStatus.SUCCEEDED) {
        clearInterval(jobInterval);
        dispatch(jobsActions.removeActiveJob(name));
      }
      dispatch(jobsActions.updateJob({ name, ...job }));
    }, 2000);
    dispatch(jobsActions.addActiveJob(name));
  };

  useUpdateEffect(() => {
    if (jobs.length === 0) {
      return;
    }
    const newJobs = jobs.filter((job: any) => job.status === 'PENDING');
    const jobsToRun = newJobs.filter(
      (job: any) =>
        !activeJobs.find((runningJob: any) => runningJob === job.name)
    );

    jobsToRun.forEach((job: any) => updateJobProgress(job));
  }, [jobs, activeJobs]);

  const generateUrl = (item: any) => {
    const newTabId = uuid.v1();
    const urlString = qs.stringify({
      screenTab: newTabId,
      tabParams: item,
      accessToken: localStorage.getItem(Storage.ACCESS_TOKEN),
      refreshToken: localStorage.getItem(Storage.REFRESH_TOKEN),
    });
    return `${window.location.pathname.replace(/\/$/, '')}?${urlString}`;
  };

  const handleShowBrowserTab = (item: any) => {
    window.open(generateUrl(item), '_blank');
  };

  const handleClick = (nav: any) => {
    const newTab = {
      id: nav.id,
      state: {
        ...nav,
        ...(nav.id === 'member-promo-history' && {
          first: 10,
          fetchAll: true,
        }),
      },
    };

    delete newTab.state.id;
    addTabNew(newTab);

    const keyboardEvents = window.event as KeyboardEvent;
    const { shiftKey, ctrlKey, metaKey } = keyboardEvents;

    if (shiftKey || ctrlKey || metaKey) {
      handleShowBrowserTab(nav);
    }
  };

  const isNext = useNextParam();

  // manually added new tabs to launchdarkly flags config
  // if new tabs/features is set to false, it will only show if url params has next=true
  // set value to true to show on the actual app
  // ask largs about this :D
  const newFlags = {
    ...flags,
    promoNext: false,
    createPromo: false,
    managePromo: false,
    promoPayoutReports: false,
    memberPromoHistory: isRow,
  };

  const activeNavItems = (list: any) =>
    list.filter(
      (item: NavItem) =>
        isNext || isFlagEnabled((newFlags as unknown) as Flags, item.ldKey)
    ) || [];

  return (
    <StyleNav>
      <Header>
        <Row>
          <Col xs={18} lg={18} xl={20}>
            <div className="logo">
              <ALink>
                <img
                  src={!isRow ? logo : rowLogo}
                  alt="app logo"
                  width={30}
                  {...(isRow && {
                    style: { marginBottom: '2px' },
                  })}
                />
              </ALink>
            </div>
            <Menu theme="light" mode="horizontal">
              {activeNavItems(navList).map((item: any) => {
                const isFirstLevelAllowed = checkPermission(
                  role,
                  uniq(
                    permissions.map((p) => {
                      const hasVIPMenu = permissions.includes('VIP');

                      if (!hasVIPMenu) {
                        return !p.includes('VIP') ? head(p.split(':')) : '';
                      }

                      return head(p.split(':')) || '';
                    })
                  ),
                  item.permission
                );

                /* FIRST LEVEL PERMISSION CHECKING STARTS */
                if (!isFirstLevelAllowed) {
                  return <></>;
                }
                /* FIRST LEVEL PERMISSION CHECKING ENDS */

                const list = activeNavItems(item.list || []);

                if (list.length > 0) {
                  return (
                    <SubMenu
                      // disable promo tab
                      disabled={item.id === 'promo'}
                      popupClassName="topbar-menu"
                      key={item.id}
                      title={translate(navMessages[`${item.id}.text`])}
                    >
                      {list.map((listItem: any) => {
                        const isSecondLevelAllowed = checkPermission(
                          role,
                          permissions,
                          listItem.permission
                        );

                        /* SECOND LEVEL PERMISSION CHECKING STARTS */
                        if (!isSecondLevelAllowed) {
                          return <></>;
                        }
                        /* SECOND LEVEL PERMISSION CHECKING ENDS */

                        const subList = activeNavItems(listItem.subList || []);

                        if (subList.length > 0) {
                          return (
                            <SubMenu
                              popupClassName="topbar-menu"
                              key={listItem.id}
                              title={translate(
                                navMessages[`${listItem.id}.text`]
                              )}
                            >
                              {subList.map(
                                (subListItem: Record<string, string>) => {
                                  const isThirdLevelAllowed = checkPermission(
                                    role,
                                    permissions,
                                    subListItem.permission
                                  );

                                  if (!isThirdLevelAllowed) {
                                    return <></>;
                                  }
                                  return (
                                    <Menu.Item
                                      key={subListItem.id}
                                      onClick={() => {
                                        handleClick(subListItem);
                                      }}
                                    >
                                      {translate(
                                        navMessages[`${subListItem.id}.text`]
                                      )}
                                    </Menu.Item>
                                  );
                                }
                              )}
                            </SubMenu>
                          );
                        }

                        return (
                          <Menu.Item
                            key={listItem.id}
                            onClick={() => {
                              handleClick(listItem);
                            }}
                          >
                            {translate(navMessages[`${listItem.id}.text`])}
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                }

                return (
                  <Menu.Item
                    key={item.id}
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    {translate(navMessages[`${item.id}.text`])}
                  </Menu.Item>
                );
              })}
            </Menu>
          </Col>
          <Col xs={6} lg={6} xl={4}>
            <Row align="middle" justify="end">
              <Col span={24}>
                <div className="right d-flex align-items-center">
                  {
                    // Hide searchbar due to functionality is not yet supported
                    /* <StyledSearch
                    placeholder={translate(messages[`search.text`])}
                    addonBefore={<Icon type="search" />}
                  /> */
                  }
                  <ProfileDropdown />
                  <LanguageSelect />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
      <RootSideBar />
      <GlobalStyle />
    </StyleNav>
  );
};

export default Navbar;
