import styled from 'styled-components';
import { InputNumber } from 'antd';

export const StyledInputNumber = styled(InputNumber)<any>`
  .ant-input-number-input {
    text-align: right;
    padding-right: 25px;
  }
`;

export default {};
