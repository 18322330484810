import gql from 'graphql-tag';

export const GET_CUSTOM_FILTERS = gql`
  query CustomFilters(
    $first: Int
    $after: Binary
    $filter: CustomFiltersFilterInput
  ) {
    customFilters(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          context
          filter
        }
      }
    }
  }
`;

export const GET_CUSTOM_FILTER = gql`
  query CustomFilter($id: ID) {
    customFilter(id: $id) {
      id
      name
      filter
    }
  }
`;

export default {};
