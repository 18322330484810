import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import Layout from 'components/Layout';
import ALL_PERMISSIONS from 'constants/permissions3';
import FiltersProvider from 'contexts/Filters';
import {
  GET_MEMBER_LEVELS,
  GET_MEMBER_LEVELS_IDS,
} from 'graphql/queries/memberLevel.query';
import { DynamicObj } from 'interfaces/user.interface';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePermissions } from 'store/accountState';
import coercedGet from 'utils/coercedGet';
import removeNull from 'utils/removeNull';
import { usePartialFiltersQuery } from '../../../utils/partialUtils';
import FilterConditions from './components/FilterConditions';
import MemberLevelsTable from './components/MemberLevels/components/MemberLevelsTable';
import NewMemberLevel from './components/NewMemberLevel';
import Sidebar from './components/Sidebar';
import SearchSettingsForm from './components/Sidebar/components/SearchSettingsForm';
import { MemberLevelProvider } from './context';
import { StyledTagContainer } from './styles';

const initialFilters = {
  name: null,
  status: null,
};

const pageInitState = {
  first: 10,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
};

const MemberMarkerManagement = () => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_CREATE, ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );

  const [filters, setFilters] = useState(initialFilters);
  const [page, setPage] = useState<any>(pageInitState);

  const memoizedFilter = useMemo(() => removeNull(filters), [filters]);

  const {
    loading,
    data = {} as any,
    refetch,
    refetchVariables,
  } = usePartialFiltersQuery(
    GET_MEMBER_LEVELS_IDS,
    GET_MEMBER_LEVELS,
    'memberLevels.edges',
    memoizedFilter,
    page,
    ['name']
  );

  const handleFilters = (e: any) => {
    setFilters(e);
  };

  const handleNext = (pageInfo: DynamicObj) => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage] || undefined;
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const { memberLevels = {} } = data || {};
  const { edges = [] } = memberLevels;
  const newEdges = edges.map(({ node }: any) => node);
  const totalCount = coercedGet(data, 'memberLevels.totalCount', 0);
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  return (
    <MemberLevelProvider refetchParams={refetchVariables}>
      <Layout.Container>
        <Layout.Header
          leftNode={
            <>
              <ToggleSidebarHead />
              <FormattedMessage
                id="filter-conditions.text"
                defaultMessage="Filter conditions"
              />
              :
              <StyledTagContainer>
                <FilterConditions
                  filters={filters}
                  onFilterChange={handleFilters}
                  isLoading={loading}
                  initialFilters={initialFilters}
                />
              </StyledTagContainer>
            </>
          }
          rightNode={
            <>
              <ColumnsFilterNew
                tabId="member-level-management"
                className="mr-2"
                iconOnly
              />
              {ALLOWED_CREATE && (
                <NewMemberLevel onSubmitForm={refetch as any} />
              )}
            </>
          }
        />

        <Layout.Content
          sideBar={
            <Layout.Sidebar
              quickSearch={{
                filters,
                contextKey: 'member-level-management',
                onFilterChange: handleFilters,
                isNext: true,
                quickSearchId: QuickSearchIds.MEMBER_LEVEL_MANAGEMENT,
                searchSettingsForm: SearchSettingsForm,
              }}
            >
              <Sidebar
                filters={filters}
                onFilterChange={handleFilters}
                isLoading={loading}
              />
            </Layout.Sidebar>
          }
          footer={
            <Layout.Footer
              page={page}
              onPrev={handlePrev}
              onNext={handleNext}
              totalPage={totalPage}
              loading={loading}
              pageInfo={data?.memberLevels?.pageInfo}
              resultsCount={totalCount}
              setPage={setPage}
            />
          }
        >
          {ALLOWED_LIST && (
            <MemberLevelsTable loading={loading} memberLevels={newEdges} />
          )}
        </Layout.Content>
      </Layout.Container>
    </MemberLevelProvider>
  );
};

export default () => (
  <FiltersProvider initialValues={initialFilters}>
    <MemberMarkerManagement />
  </FiltersProvider>
);
