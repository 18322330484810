import styled from 'styled-components';

export const StyledInputGroup: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-select-lg {
    margin-right: -1px;
    border-right-width: 1px;
    margin-right: 1rem;
  }
`;

export default {};
