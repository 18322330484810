import esget from 'utils/esGet';
import messages from 'pages/components/PermissionGroup/messages';

import moment from 'moment';
import { PermissionGroup } from 'types/graphqlTypes';

type Translator = (
  { id, defaultMessage }: { id: string; defaultMessage: string },
  options?: any
) => string;

const getCsvHeaders = (translate: Translator) => [
  {
    label: translate(messages.serialcode),
    key: 'serialCode',
    renderCell: (permissionGroup: PermissionGroup) =>
      esget(permissionGroup?.serialCode, ''),
  },
  {
    label: translate(messages.datetimeupdated),
    key: 'dateTimeUpdated',
    renderCell: (permissionGroup: PermissionGroup) =>
      moment(permissionGroup?.dateTimeUpdated).format(
        'MMM DD, YYYY hh:mm:ss A'
      ),
  },
  {
    label: translate(messages.name),
    key: 'name',
    renderCell: (permissionGroup: PermissionGroup) =>
      esget(permissionGroup?.name, ''),
  },
];

export default getCsvHeaders;
