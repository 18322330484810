import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';

type Props = {
  paymentMethod: Record<string, any>;
  formItemLayout: Record<string, any>;
};

const MethodDetails = (props: Props) => {
  const { paymentMethod, formItemLayout } = props;
  const { nickname, image } = paymentMethod;

  return (
    <>
      {paymentMethod.__typename !== 'CryptocurrencyPaymentMethod' ? (
        <Form.Item
          label={
            <FormattedMessage id="nickname.text" defaultMessage="Nickname" />
          }
          {...formItemLayout}
          className="mb-0"
        >
          <span>{nickname || '-'}</span>
        </Form.Item>
      ) : (
        ''
      )}

      <Form.Item
        label={<FormattedMessage id="qr-code.text" defaultMessage="QR code" />}
        {...formItemLayout}
        className="mb-0"
      >
        {image?.uri ? (
          <div
            style={{
              backgroundImage: `url(${image?.uri || ''})`,
              height: 126,
              width: 126,
              backgroundSize: 'cover',
            }}
          />
        ) : (
          '-'
        )}
      </Form.Item>
    </>
  );
};

export default MethodDetails;
