import gql from 'graphql-tag';

export const CREATE_MANUAL_DEPOSIT = gql`
  mutation CreateManualDeposit(
    $input: CreateManualDepositInput!
    $password: String!
  ) {
    createManualDeposit(input: $input, password: $password)
  }
`;
export const CREATE_MANUAL_WITHDRAWAL = gql`
  mutation CreateManualWithdrawal(
    $input: CreateManualWithdrawalInput!
    $password: String!
  ) {
    createManualWithdrawal(input: $input, password: $password)
  }
`;
export const CREATE_MANUAL_ADJUSTMENT = gql`
  mutation CreateManualAdjustment(
    $input: CreateManualAdjustmentInput!
    $password: String!
  ) {
    createManualAdjustment(input: $input, password: $password)
  }
`;
