import { Divider, Empty, Tabs } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { ErrorHandler } from 'components/ErrorHandler';
import { useNextParam } from 'hooks/useIsNext';
import messages from 'messages';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import styled from 'styled-components';
import { Member } from 'types/graphqlTypes-row';
import isRow from 'utils/isRow';
import useTranslate from 'utils/useTranslate';
import { useMemberPromo } from 'hooks/useMemberPromo';
import EditPromoForm from './EditPromoForm';

import { LastPlayedGames } from './LastPlayedGames';
import LastPromo from './LastPromo';

const { TabPane } = Tabs;
const StyledTabs = styled(Tabs)`
  .ant-tabs-tabpane.ant-tabs-tabpane-active {
    padding: 0 !important;
  }

  .ant-tabs-content.ant-tabs-content-top {
    background: white;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-nav > .ant-tabs-wrap {
    display: block !important;
    color: red !important;
  }

  .ant-tabs-nav-list {
    color: white;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)) !important;
  }

  .ant-tabs-tab-active {
    background: #1b48a0 !important;
    color: white !important;

    .ant-tabs-tab-btn {
      color: white !important;
    }
  }
`;

type MemberData = {
  member: Member;
};

export const PromoAndGames: React.FC<IPromoAndGamesProps> = ({
  permissions,
}) => {
  const { ALLOWED_LAST_PROMO_APPLIED, ALLOWED_LAST_PLAYED_GAMES } = permissions;

  const translate = useTranslate();
  const count = 3;
  const isNext = useNextParam();

  const { addTab } = useScreenTabV2();
  const { member } = useMember() as MemberData;
  const { promoCurrency } = useMemberPromo();

  const handleOpenPromoHistoryTab = () => {
    addTab({
      id: 'member-promo-history',
      state: {
        member: {
          username: member.username,
          id: member.id,
          ...(isNext && {
            currency: promoCurrency,
          }),
        },
        filter: {
          status: {
            notEqual: 'ACTIVE',
          },
        },
      },
    });
  };

  return (
    <div className="card-container" style={{ marginTop: '8px' }}>
      <StyledTabs type="card" className="promo-games-tab-member360">
        {ALLOWED_LAST_PROMO_APPLIED && (
          <TabPane tab={translate(messages.LAST_PROMO_APPLIED)} key="1">
            {isRow ? (
              <div>
                <LastPromo showActionButtons />
                <Divider className="m-0" />
                <div className="pt-2 pb-2 pl-1 pr-1 d-flex justify-content-center align-items-center">
                  <ALink
                    className="fs-12"
                    onClick={() => handleOpenPromoHistoryTab()}
                  >
                    More Details | Member Promo History
                  </ALink>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center member-profile-tab-container">
                <Empty />
              </div>
            )}
          </TabPane>
        )}
        {ALLOWED_LAST_PLAYED_GAMES && (
          <TabPane tab={translate(messages.LAST_PLAYED_GAMES)} key="2">
            <ErrorHandler>
              <LastPlayedGames maxItemCount={count} />
            </ErrorHandler>
          </TabPane>
        )}
        {isRow && isNext && (
          <TabPane tab="Edit Promo" key="3">
            <div className="d-flex justify-content-center member-profile-tab-container m-4">
              <EditPromoForm />
            </div>
          </TabPane>
        )}
      </StyledTabs>
    </div>
  );
};

interface IPromoAndGamesProps {
  permissions: {
    ALLOWED_LAST_PROMO_APPLIED: boolean;
    ALLOWED_LAST_PLAYED_GAMES: boolean;
  };
}
