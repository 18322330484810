import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import styled from 'styled-components';
import getSortedTimezones from 'utils/getSortedTimezones';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import coercedGet from 'utils/coercedGet';
import { FilterStateType, SetFilterStateType } from './types';

export const StyledSelect = styled(Select)`
  .ant-select-selection {
    border: 0;
    border: 1px solid #d9d9d9;
    border-radius: 0;
  }
  .ant-select-selection__rendered {
    // margin-left: 0 !important
    .ant-select-selection__placeholder {
      // margin-left: 0 !important;
    }
  }
`;

const { Option } = Select;

type Props = {
  state: FilterStateType;
  setState: SetFilterStateType;
};

const TimeZoneFilter: React.FC<Props> = ({ state, setState }) => {
  const { currentTimezone } = useReportsTimezone();

  const handleChange = (e: any) => {
    if (e) {
      setState((prev: FilterStateType) => ({
        ...prev,
        timezone: e,
      }));
    } else {
      setState((prev: FilterStateType) => ({
        ...prev,
        timezone: null,
      }));
    }
  };

  const renderOptions = () =>
    getSortedTimezones(true).map((zone: any) => (
      <Option value={zone.name} key={zone.id}>
        {zone.label}
      </Option>
    ));

  const [localSelectedTimezone, setLocalSelectedTimezone] = React.useState(
    currentTimezone
  );

  return (
    <>
      <div className="mb-1">
        <FormattedMessage
          id="reports.time-zone-selection.text"
          defaultMessage="Time Zone Selection"
        />
      </div>
      <StyledSelect
        allowClear
        placeholder={
          <FormattedMessage id="reports.select.text" defaultMessage="Select" />
        }
        style={{ width: '100%' }}
        value={state.timezone ? localSelectedTimezone : currentTimezone}
        optionFilterProp="label"
        onChange={(e) => {
          handleChange(e);
          setLocalSelectedTimezone(e);
        }}
        showSearch
        filterOption={(input, option) => {
          const target = coercedGet(option as {}, 'props.children', []);
          if (target.length) {
            return target.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return false;
        }}
      >
        {renderOptions()}
      </StyledSelect>
    </>
  );
};

export default TimeZoneFilter;
