import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import Circle from 'components/Circle';
import CustomTag from 'components/CustomTag';
import { useConfig } from 'hooks/useConfig';
import { get } from 'lodash';
import globalMessage from 'messages';
import React from 'react';
import { truncateProgramName } from 'utils/truncateProgramName';
import useTranslate from 'utils/useTranslate';
import messages from '../../../../messages';

type Props = {
  paymentMethod: Record<string, any>;
  formItemLayout: Record<string, any>;
};

const OtherSettings = ({ paymentMethod, formItemLayout }: Props) => {
  const {
    enabled,
    depositExpiry,
    remarks,
    turnoverRequirementMultiplier,
    excludedMemberLevel = [],
    memberLoyaltyLevel = [],
    transactionFee,
    transactionFeeType,
    __typename,
  } = paymentMethod;

  const translate = useTranslate();

  const manualAdjustmentMethod = __typename === 'ManualAdjustmentPaymentMethod';

  const { currency } = useConfig();

  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1 mt-2"
        label={
          <strong id="other-settings">
            {translate(messages.othersettings)}
          </strong>
        }
        colon={false}
      />
      <Form.Item
        label={translate(messages.membershipvipincluded)}
        {...formItemLayout}
        className="mb-0"
      >
        {memberLoyaltyLevel && memberLoyaltyLevel.length ? (
          <div className="ml-2">
            {memberLoyaltyLevel.map(
              (item: Record<string, any>, index: number) => {
                const { text: shortName } = truncateProgramName(
                  get(item, 'programme.name', '')
                );
                return (
                  <CustomTag
                    variant="memberLoyaltyLevel"
                    color={item.color}
                    key={index}
                  >
                    {shortName} - {item.name}
                  </CustomTag>
                );
              }
            )}
          </div>
        ) : (
          '-'
        )}
      </Form.Item>
      <Form.Item
        label={translate(messages.excludedmembermarker)}
        {...formItemLayout}
        className="mb-0"
      >
        {excludedMemberLevel && excludedMemberLevel.length ? (
          <div className="ml-2">
            {excludedMemberLevel.map(
              (item: Record<string, any>, index: number) => (
                <CustomTag variant="memberLevel" color={item.color} key={index}>
                  {item.name}
                </CustomTag>
              )
            )}
          </div>
        ) : (
          '-'
        )}
      </Form.Item>
      <Form.Item
        label={
          <>
            {translate(messages.validity)}
            {translate(messages.minutes)}
          </>
        }
        {...formItemLayout}
        className="mb-0"
        help={
          <span className="text-secondary">
            {translate(messages.validityhelper)}
          </span>
        }
      >
        <span>
          {manualAdjustmentMethod
            ? '-'
            : `${depositExpiry} ${translate(messages.minutes)}`}
        </span>
      </Form.Item>
      <Form.Item
        label={translate(messages.remarks)}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{remarks || '-'}</span>
      </Form.Item>
      <Form.Item
        label={translate(messages.turnover)}
        {...formItemLayout}
        className="mb-0"
      >
        <span>
          {manualAdjustmentMethod ? '-' : `${turnoverRequirementMultiplier}x`}
        </span>
      </Form.Item>
      <Form.Item
        label={translate(messages.transactionfee)}
        {...formItemLayout}
        className="mb-0"
      >
        {manualAdjustmentMethod ? (
          '-'
        ) : (
          <span>
            {translate(globalMessage[transactionFeeType])} - {transactionFee}{' '}
            {transactionFeeType === 'ABSOLUTE' ? `${currency?.symbol}` : '%'}
          </span>
        )}
      </Form.Item>
      <Form.Item
        label={translate(messages.status)}
        {...formItemLayout}
        className="mb-0"
      >
        <Circle size={15} color={enabled ? 'success' : 'danger'} filled />{' '}
        {translate(enabled ? messages.enable : messages.disable)}
      </Form.Item>
    </>
  );
};

export default OtherSettings;
