import gql from 'graphql-tag';

export const MEMBERS = gql`
  query Members($first: Int, $after: Binary, $filter: MembersFilterInput) {
    members(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          value: id
          label: username
        }
      }
    }
  }
`;

export default {};
