import React, { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { FormattedMessage } from 'react-intl';
import { allTypes, useCreateLoyaltyProgramContext } from '../../../../context';

const DeleteTier = ({ tierId }: { tierId: string }) => {
  const [, dispatch] = useCreateLoyaltyProgramContext();
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  return (
    <>
      <ALink onClick={handleShow}>
        <FormattedMessage id="delete.text" defaultMessage="Delete" />
      </ALink>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          onCancel={handleShow}
          onOk={() => {
            dispatch({
              type: allTypes.DELETE_TIER,
              payload: tierId,
            });

            handleShow();
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-delete.text"
                defaultMessage="Confirm Delete"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteTier;
