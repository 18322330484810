import * as yup from 'yup';
import messages from 'messages';
import { urlRegex } from 'utils/regex';

export default (translate: (e: any, options?: Record<string, any>) => any) => {
  yup.setLocale({
    mixed: {
      required: translate(messages.required),
    },
    string: {
      max: ({ max }) => translate(messages['number.max.error'], { max }),
    },
    number: {
      min: ({ min }) => translate(messages['number.min.error'], { min }),
      max: ({ max }) => translate(messages['number.max.error'], { max }),
      positive: ({ more }) =>
        translate(messages['number.min.error'], { min: more + 1 }),
    },
  });

  return yup.object().shape({
    domainName: yup
      .string()
      .required()
      .nullable()
      .matches(urlRegex, translate(messages['url-validator-field.text'])),
    paymentMethodSuccessRateCalculationLastTransactionsCount: yup
      .number()
      .required()
      .typeError(translate(messages['number.matches.error']))
      .min(0)
      .max(100),

    turnoverRequirementWaiverEnabled: yup.boolean().required(),
    turnoverRequirementWaiverThreshold: yup
      .number()
      .typeError(translate(messages['number.matches.error']))
      .when('turnoverRequirementWaiverEnabled', {
        is: true,
        then: yup
          .number()
          .required()
          .min(0),
        otherwise: yup.number(),
      }),
    withdrawalHandlingFee: yup
      .number()
      .min(0)
      .typeError(translate(messages['number.matches.error'])),
    withdrawalTaxType: yup.string().required(),
    withdrawalTaxValue: yup
      .number()
      .min(0)
      .required()
      .when('withdrawalTaxType', {
        is: 'ABSOLUTE',
        then: yup
          .number()
          .required()
          .typeError(translate(messages['number.matches.error'])),
        otherwise: yup
          .number()
          .required()
          .typeError(translate(messages['number.matches.error']))
          .min(0)
          .max(100),
      }),
  });
};
