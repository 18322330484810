import { useMutation } from '@apollo/react-hooks';
import { Button, message, Popconfirm } from 'antd';
import { REJECT_DEPOSIT } from 'graphql/mutations/deposit.mutation';
import { PrimitiveType } from 'intl-messageformat';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

type PropTypes = {
  id: string;
  status: string;
  remarks: string;
  setRemarksError: (
    error?: string | PrimitiveType | React.ReactElement
  ) => void;
  toggleModal: () => void;
  refetchVariables: object;
  refetchDepositRequests: (item: object) => void;
  refetchQueries: Array<object> | object;
};

const RejectDeposit = (props: PropTypes) => {
  const {
    id,
    status,
    remarks,
    setRemarksError,
    toggleModal,
    refetchVariables,
    refetchDepositRequests,
  } = props;

  const [loading, setLoading] = useState(false);
  const messages = defineMessages({
    rejectDepositProcess: {
      id: 'deposits.deposit-request-rejected.text',
      defaultMessage: 'Deposit request rejected',
    },
  });
  const translate = useTranslate();

  const [rejectDeposit] = useMutation(REJECT_DEPOSIT, {
    onCompleted: (data) => {
      if (data.rejectDeposit) {
        message.success({
          content: (
            <span data-testid="success">
              {translate(messages.rejectDepositProcess)}
            </span>
          ),
        });
      }
      setRemarksError();
      setLoading(false);
      toggleModal();
      refetchDepositRequests(refetchVariables);
    },
    variables: { id },
  });

  return (
    <>
      {status === 'PROCESSING' && (
        <Popconfirm
          title={
            <FormattedMessage
              id="are-you-sure?.text"
              defaultMessage="Are you sure?"
            />
          }
          onConfirm={() => {
            if (remarks) {
              setLoading(true);
              rejectDeposit();
            } else {
              setRemarksError(
                <FormattedMessage
                  id="deposits.required-for-rejecting-deposit-request.text"
                  defaultMessage="Required for rejecting deposit request"
                />
              );
            }
          }}
          okText={
            <div data-testid="yes">
              <FormattedMessage id="yes.text" defaultMessage="Yes" />
            </div>
          }
          cancelText={
            <div data-testid="no">
              <FormattedMessage id="no.text" defaultMessage="No" />
            </div>
          }
        >
          <Button
            data-testid="reject-deposit"
            type="primary"
            danger
            disabled={loading}
            loading={loading}
          >
            <FormattedMessage id="reject.text" defaultMessage="Reject" />
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export default RejectDeposit;
