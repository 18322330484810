import React, { useState } from 'react';
import { Button, Input, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DynamicObj } from 'interfaces/user.interface';
import { useForm, Controller } from 'react-hook-form';
import copy from 'copy-to-clipboard';
import generatePassword from 'generate-password';
import { useMutation } from '@apollo/react-hooks';
import {
  UPDATE_ADMIN,
  StyledModal,
  PasswordContainer,
  messages,
} from 'SuperAdminMain/components/AccountManagementNew/utils';
import useTranslate from 'utils/useTranslate';

type Props = {
  account: DynamicObj;
  children?: any;
};

export const ChangeLoginPassword: React.FC<Props> = ({
  account,
  children = null,
}) => {
  const [isShow, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const [updateAdmin] = useMutation(UPDATE_ADMIN);
  const {
    control,
    getValues,
    setValue,
    errors,
    handleSubmit,
    reset,
  } = useForm();

  const handleShow = () => setShow(!isShow);

  const handleReset = () => {
    reset({
      password: undefined,
      superadminPassword: undefined,
    });

    handleShow();
  };

  const handleGeneratePassword = () => {
    setValue(
      'password',
      generatePassword.generate({
        length: 10,
        numbers: true,
      })
    );
  };

  const handleCopy = (password: string) => {
    if (password) {
      copy(password);
      message.success(translate(messages['copied.text']));
    } else {
      message.error(translate(messages['nothing-to-copy.text']));
    }
  };

  const onSubmit = async (data: any) => {
    const { password } = data;
    setLoading(true);

    try {
      await updateAdmin({
        variables: {
          id: account.id,
          input: { password },
          password: getValues('superadminPassword'),
        },
      });

      message.success(translate(messages['updated-successfully.text']));

      handleReset();
      // eslint-disable-next-line no-empty
    } finally {
    }

    setLoading(false);
  };

  return (
    <>
      {!children ? (
        <Button type="link" block onClick={handleShow}>
          <FormattedMessage
            id="change-login-password.text"
            defaultMessage="Change Login Password"
          />
        </Button>
      ) : (
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => null}
          style={{ outline: 'none' }}
          onClick={handleShow}
        >
          {children}
        </span>
      )}

      <StyledModal
        title={
          <span className="fs-14">
            <FormattedMessage
              id="change-login-password.text"
              defaultMessage="Change Login Password"
            />
          </span>
        }
        visible={isShow}
        width={378}
        centered
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id="update.text" defaultMessage="Update" />
          </Button>,
        ]}
      >
        <FormattedMessage id="username.text" defaultMessage="Username" />
        <Input disabled value={account.username} />
        <br />
        <br />
        <FormattedMessage id="password.text" defaultMessage="Password" />
        <br />
        <PasswordContainer>
          <span className="password">
            <Controller
              as={Input.Password}
              control={control}
              name="password"
              placeholder={translate(messages['please-key-in.text'])}
              rules={{ required: true, minLength: 6 }}
            />
          </span>

          <span className="generate">
            <Button type="primary" onClick={handleGeneratePassword}>
              <FormattedMessage id="generate.text" defaultMessage="Generate" />
            </Button>
          </span>
        </PasswordContainer>
        {errors.password?.type === 'required' && (
          <span style={{ color: 'red' }}>
            <FormattedMessage
              id="new-password-required.text"
              defaultMessage="New password is required."
            />
          </span>
        )}
        {errors.password?.type === 'minLength' && (
          <span style={{ color: 'red' }}>
            <FormattedMessage
              id="must-be-atleast-6-char.text"
              defaultMessage="Must be at least 6 characters."
            />
          </span>
        )}

        <Button
          block
          className="mt-1"
          onClick={() => {
            handleCopy(getValues('password'));
          }}
        >
          <FormattedMessage
            id="copy-login-password.text"
            defaultMessage="Copy Login Password"
          />
        </Button>

        <br />
        <br />

        <FormattedMessage
          id="super-admin-password.text"
          defaultMessage="Super Admin Password"
        />

        <Controller
          as={Input.Password}
          control={control}
          name="superadminPassword"
          placeholder={translate(messages['please-key-in.text'])}
          rules={{ required: true, minLength: 6 }}
        />
        {errors.superadminPassword?.type === 'required' && (
          <span style={{ color: 'red' }}>
            <FormattedMessage
              id="password-is-required.text"
              defaultMessage="Password is required."
            />
          </span>
        )}
        {errors.superadminPassword?.type === 'minLength' && (
          <span style={{ color: 'red' }}>
            <FormattedMessage
              id="must-be-atleast-6-char.text"
              defaultMessage="Must be at least 6 characters."
            />
          </span>
        )}
      </StyledModal>
    </>
  );
};
