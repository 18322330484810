import React, { useState } from 'react';
import { Button, Modal, Row, Col, Input, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { DynamicObj } from 'interfaces/user.interface';
import { useForm, Controller } from 'react-hook-form';
import useTranslate from 'utils/useTranslate';
import { messages } from 'SuperAdminMain/components/AccountManagementNew/utils';
import { useAccountManagementValue } from 'SuperAdminMain/components/AccountManagementNew/context';

// ===[ UTILS ]=== //
const DELETE_ACCOUNT = gql`
  mutation deleteAdmin($id: ID!, $password: String!) {
    deleteAdmin(id: $id, password: $password)
  }
`;

// ===[ MAIN ]=== //
type Props = {
  account: DynamicObj;
};

export const DeleteAccount: React.FC<Props> = ({ account }) => {
  const [isShow, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const { refetch } = useAccountManagementValue();
  const [deleteAccount] = useMutation(DELETE_ACCOUNT);
  const { control, getValues, handleSubmit, errors, reset } = useForm();

  const handleShow = () => setShow(!isShow);

  const handleReset = () => {
    reset({ password: undefined });

    handleShow();
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      await deleteAccount({
        variables: {
          id: account.id,
          password: getValues('password'),
        },
      });

      message.success(translate(messages['updated-successfully.text']));

      refetch();
      handleReset();
      // eslint-disable-next-line no-empty
    } finally {
    }

    setLoading(false);
  };

  return (
    <Container>
      <Button type="link" block onClick={handleShow}>
        <FormattedMessage id="delete.text" defaultMessage="Delete" />
      </Button>

      <StyledModal
        title={
          <span className="fs-14">
            <FormattedMessage
              id="are-you-sure-delete.text"
              defaultMessage="Are you sure you want to delete this?"
            />
          </span>
        }
        visible={isShow}
        width={420}
        centered
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id="update.text" defaultMessage="Update" />
          </Button>,
        ]}
      >
        <Row>
          <Col span={8}>
            <StyledSpan>
              <FormattedMessage
                id="super-admin-password.text"
                defaultMessage="Super Admin Password"
              />
            </StyledSpan>
          </Col>

          <Col span={14}>
            <Controller
              as={Input.Password}
              control={control}
              name="password"
              placeholder={translate(messages['please-key-in.text'])}
              rules={{ required: true, minLength: 6 }}
            />
          </Col>
          <Col span={2} />
        </Row>

        {errors.password?.type === 'required' && (
          <span style={{ color: 'red' }}>
            <FormattedMessage
              id="password-is-required.text"
              defaultMessage="Password is required."
            />
          </span>
        )}
        {errors.password?.type === 'minLength' && (
          <span style={{ color: 'red' }}>
            <FormattedMessage
              id="must-be-atleast-6-char.text"
              defaultMessage="Must be at least 6 characters."
            />
          </span>
        )}
      </StyledModal>
    </Container>
  );
};

// ===[ STYLE ]=== //
const Container = styled.div``;

const StyledSpan = styled.div`
  font-size: 15px;
  margin-right: 16px;
  line-height: 15px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 26;
  }
`;
