import gql from 'graphql-tag';

export const MEMBER_SUMMARY_REPORT = gql`
  query MemberSummaryReport(
    $first: Int
    $after: Binary
    $language: Language
    $sort: SortInput
  ) {
    memberSummaryReports(first: $first, after: $after, sort: $sort) {
      totalCount
      edges {
        cursor
        node {
          id
          creator {
            id
            username
          }
          timezone
          filter {
            startDateTime
            endDateTime
            members {
              id
              username
            }
            gameTypes
            vendors {
              id
              name(language: $language)
            }
            isAffiliate
            isAffiliateUpline
            affiliates {
              id
              username
            }
            memberLoyaltyLevels {
              id
              name
              programme {
                id
                name
              }
            }
            memberLevels {
              id
              name
            }
            memberTags {
              id
              name
            }
            memberStatus
            registrationDateTime {
              start
              end
            }
            lastLoginDateTime {
              start
              end
            }
            dateOfBirthDateTime {
              start
              end
            }
            totalBalance {
              minimum
              maximum
            }
          }
          report {
            id
            uri
            dateTimeCreated
          }
          status
          dateTimeCreated
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const MEMBER_SUMMARY_REPORT_CSV = gql`
  query MemberSummaryReportCSV(
    $first: Int
    $after: Binary
    $language: Language
  ) {
    memberSummaryReports(first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          id
          creator {
            id
            username
          }
          timezone
          filter {
            startDateTime
            endDateTime
            members {
              id
              username
            }
            gameTypes
            vendors {
              id
              name(language: $language)
            }
            isAffiliate
            isAffiliateUpline
            affiliates {
              id
              username
            }
            memberLoyaltyLevels {
              id
              name
            }
            memberLevels {
              id
              name
            }
            memberTags {
              id
              name
            }
            memberStatus
            registrationDateTime {
              start
              end
            }
            lastLoginDateTime {
              start
              end
            }
            dateOfBirthDateTime {
              start
              end
            }
            totalBalance {
              minimum
              maximum
            }
          }
          report {
            id
            uri
            dateTimeCreated
          }
          status
          dateTimeCreated
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_MSR_USERNAMES = gql`
  query FilterMSRUsernames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export default {};
