import { ErrorHandler } from 'components/ErrorHandler';
import DataLoaderProvider from 'contexts/DataLoader';
import FiltersProvider from 'contexts/Filters';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import {
  BATCH_MEMBER_WITHDRAWAL_METHOD,
  BATCH_WITHDRAWAL_AFFILIATE,
  BATCH_WITHDRAWAL_FEE_INDICATOR,
  BATCH_WITHDRAWAL_MEMBER_MARKER,
  BATCH_WITHDRAWAL_MEMBER_NOTES,
  BATCH_WITHDRAWAL_MEMBER_TAGS,
  BATCH_WITHDRAWAL_SERIAL_CODE,
  BATCH_WITHDRAWAL_USERNAME,
  BATCH_WITHDRAWAL_VIP_TIER,
  BATCH_WITH_MEMBER_PAYMENT,
  GLOBAL_BATCH_WITHDRAWAL_STATUS,
} from 'graphql/queries/globalBatch.query';
import { useCreateLoader } from 'hooks/useLoader';
import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import uuid from 'uuid';
import { WITHDRAWAL_REQUESTS } from 'graphql/queries/withdrawal.query';
import WithdrawalsScreen from './components/WithdrawalsScreen';

const MemberWithdrawals = () => {
  const [key, setKey] = useState('');
  const { getTab } = useScreenTabV2();

  const { memberIdRef, serialCodeRef, filter = {} } = getTab(
    'withdrawal-requests'
  );

  const memberTabRef = memberIdRef ? { in: [memberIdRef] } : null;
  const serialCodeTabRef = serialCodeRef ? { in: [serialCodeRef] } : null;

  const defaultFilters = {
    serialCode: null,
    account: null,
    memberLevel: null,
    amount: null,
    status: null,
    processingTime: null,
    // processor: null,
    dateTimeCreated: null,
    memberLoyaltyLevel: null,
    handlingFeeApplied: null,
    withdrawalTaxFeeApplied: null,
    promoDeductionFeeApplied: null,
    withdrawalLimitFeeApplied: null,
    oneTimeTurnoverDetectionCheck: null,
    promoHunterCheck: null,
    contraBetCheck: null,
    ipCollisionOffenderCheck: null,
    martingaleBetOffenderCheck: null,
    withdrawalMethodType: null,
  };

  const initialFilters = {
    ...defaultFilters,
    account: memberTabRef,
    serialCode: serialCodeTabRef,
    ...filter,
  };

  const affiliateLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_AFFILIATE);
  const withdrawalSerialCodeLoaderSet = useCreateLoader(
    BATCH_WITHDRAWAL_SERIAL_CODE
  );
  const usernameLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_USERNAME);
  const markerLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_MEMBER_MARKER);
  const feeIndicatorLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_FEE_INDICATOR);
  const paymentLoaderSet = useCreateLoader(BATCH_WITH_MEMBER_PAYMENT);
  const withdrawalMethodLoaderSet = useCreateLoader(
    BATCH_MEMBER_WITHDRAWAL_METHOD
  );

  const withdrawalStatusLoaderSet = useCreateLoader(
    GLOBAL_BATCH_WITHDRAWAL_STATUS
  );
  const memberTagsLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_MEMBER_TAGS);
  const memberNotesLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_MEMBER_NOTES);
  const vipTierLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_VIP_TIER);

  return (
    <FiltersProvider
      initialValues={initialFilters}
      defaultValues={defaultFilters}
      tabId="withdrawal-requests"
    >
      <DataLoaderProvider
        loaderKeyVals={{
          affiliateLoaderSet,
          withdrawalSerialCodeLoaderSet,
          usernameLoaderSet,
          vipTierLoaderSet,
          markerLoaderSet,
          feeIndicatorLoaderSet,
          paymentLoaderSet,
          withdrawalMethodLoaderSet,
          withdrawalStatusLoaderSet,
          memberTagsLoaderSet,
          memberNotesLoaderSet,
        }}
      >
        <SpreadsheetProvider
          options={{
            query: WITHDRAWAL_REQUESTS,
            edgesPath: 'withdrawals.edges',
          }}
        >
          <ErrorHandler>
            <WithdrawalsScreen
              key={key}
              refreshPage={() => setKey(uuid.v1())}
            />
          </ErrorHandler>
        </SpreadsheetProvider>
      </DataLoaderProvider>
    </FiltersProvider>
  );
};

export default MemberWithdrawals;
