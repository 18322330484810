import { DynamicObj } from 'interfaces/user.interface';

export const FORMIK_ATTR = {
  LEVELS: 'LEVELS',
  PERCENTAGES: 'PERCENTAGES',
};

export const FORMIK_ATTR_PROP = {
  VENDOR: 'vendor',
};

export const sanitizeFormikObject = (object: DynamicObj, name: string) => {
  let newObject = {};

  Object.entries(object).forEach(([prop, value]) => {
    newObject = { ...newObject, ...(prop[name] && { prop: value }) };
    return null;
  });

  return newObject;
};

export const validateFormikAttr = (attr: string, data: Array<any>) => {
  switch (attr) {
    case FORMIK_ATTR.LEVELS:
      return data.map((level: any) => ({
        ...level,
        minimum: level.minimum !== undefined ? level.minimum : 0,
        percentages:
          level.percentages !== undefined
            ? Object.entries(level.percentages).map((e: Array<any>) => ({
                ...e[1],
                ...(e[1].multiplier !== undefined && { multiplier: undefined }),
                ...(e[1].checked !== undefined && { checked: undefined }),
                percentage:
                  level.percentages !== undefined ? e[1].percentage / 100 : 0,
                ...(e[1].games !== undefined && {
                  games: Object.entries(e[1].games).map((g: Array<any>) => ({
                    ...g[1],
                    percentage: g[1].percentage / 100,
                  })),
                }),
              }))
            : [],
        ...(level.__typename !== undefined && { __typename: undefined }),
      }));
    case FORMIK_ATTR.PERCENTAGES:
      return Object.entries(data).map((e) => ({
        ...e[1],
        percentage: e[1].percentage / 100,
        ...(e[1].games !== undefined && {
          games: Object.entries(e[1].games).map((g: Array<any>) => ({
            ...g[1],
            percentage: g[1].percentage / 100,
          })),
        }),
        ...(e[1].multiplier !== undefined && { multiplier: undefined }),
        ...(e[1].checked !== undefined && { checked: undefined }),
      }));
    default:
      return [];
  }
};
