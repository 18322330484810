export const getStatusColor = (status: string) => {
  let color;

  switch (status) {
    case 'PROCESSING':
      color = '#1890ff';
      break;
    case 'APPROVED':
      color = '#52c41a';
      break;
    case 'PENDING':
      color = '#faad14';
      break;
    case 'FAILED':
      color = '#EE4B2B';
      break;
    case 'REJECTED':
      color = '#AA4A44';
      break;
    case 'EXPIRED':
      color = 'gray';
      break;
    case 'CANCELLED':
      color = '#ff0000';
      break;
    case 'ON_HOLD':
      color = '#800080';
      break;
    default:
      color = 'gray';
      break;
  }

  return color;
};
