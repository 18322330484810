import { LoadingOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import ConfirmationModal from 'components/ConfirmationModal';
import { UPDATE_DOMAIN } from 'graphql/mutations/siteConfiguration.mutation';
import { DOMAIN } from 'graphql/queries/siteConfiguration.query';
import React, { useEffect, useState } from 'react';
import { ALink } from 'components/ALink/ALink';

type Props = {
  id: string;
  refetch: () => void;
};

const UpdateDomainStatus = ({ id, refetch }: Props) => {
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState('');

  const { data, loading } = useQuery(DOMAIN, {
    fetchPolicy: 'network-only',
    variables: { id },
  });

  useEffect(() => {
    if (data) {
      setStatus(data.domain.status);
    }
  }, [data]);

  const [updateStatus, { loading: updateLoading }] = useMutation(
    UPDATE_DOMAIN,
    {
      onCompleted: () => {
        message.success('Domain status updated.');

        refetch();
        setVisible(false);
      },
      onError: () => {
        message.error('Domain status update failed. Please try again later.');
      },
    }
  );

  const onConfirm = async (): Promise<void> => {
    await updateStatus({
      variables: {
        id,
        input: {
          status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        },
      },
    });
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 8 }} spin />;
  const statusAction = status === 'ACTIVE' ? 'Deactivate' : 'Activate';

  return (
    <>
      {loading ? (
        <Spin indicator={antIcon} />
      ) : (
        <ALink
          style={{
            textDecoration: 'none',
          }}
          onClick={() => setVisible(true)}
        >
          {statusAction}
        </ALink>
      )}

      <ConfirmationModal
        title={`Confirm ${statusAction}`}
        visible={visible}
        onOk={onConfirm}
        onCancel={() => setVisible(false)}
        okButtonProps={updateLoading}
      />
    </>
  );
};

export default UpdateDomainStatus;
