import styled from 'styled-components';

export const StyledContainer = styled.div`
  position: relative;
  margin-top: 2px;
  .twitter-picker {
    position: absolute !important;
    z-index: 1;
    left: -6px;
    top: 40px;
    div {
      > div {
        display: none !important;
      }
    }
  }
`;

export const StyledColor: any = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${(props: { value: string }) => props.value || '#efefef'};
  cursor: pointer;
`;

export default {};
