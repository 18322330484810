import React from 'react';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';

export const StatusFilter = () => {
  const { filters, setFilters } = useFilterValues();
  const value = filters.status;

  const handleChange = (e: any) => {
    let newValue = null as any;

    if (value && value.in.includes(e)) {
      const data = value.in.filter((item: any) => item !== e);
      if (data.length) {
        newValue = {
          in: value.in.filter((item: any) => item !== e),
        };
      } else {
        newValue = null;
      }
    } else if (value) {
      newValue = {
        in: [...value.in, e],
      };
    } else {
      newValue = {
        in: [e],
      };
    }

    setFilters((prev: any) => {
      if (!newValue) {
        const { status, ...restFilter } = prev;
        return restFilter;
      }

      return { ...prev, status: newValue };
    });
  };

  const valueChecked = (checkboxValue: string) =>
    !!filters?.status?.in?.find(
      (filterStatus: string) => filterStatus === checkboxValue
    );

  return (
    <>
      <div className="checkbox-circle">
        <Checkbox
          checked={valueChecked('DRAFT')}
          onChange={() => handleChange('DRAFT')}
        />
        <div className="circle-yellow" />
        <div>
          <FormattedMessage id="draft.text" defaultMessage="Draft" />
        </div>
      </div>

      <div className="checkbox-circle">
        <Checkbox
          checked={valueChecked('ACTIVE')}
          onChange={() => handleChange('ACTIVE')}
        />
        <div className="circle-green" />
        <div>
          <FormattedMessage id="active.text" defaultMessage="Active" />
        </div>
      </div>

      <div className="checkbox-circle">
        <Checkbox
          checked={valueChecked('INACTIVE')}
          onChange={() => handleChange('INACTIVE')}
        />
        <div className="circle-red" />
        <div>
          <FormattedMessage id="inactive.text" defaultMessage="Inactive" />
        </div>
      </div>
    </>
  );
};
