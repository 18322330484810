import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import storage from 'constants/storage';
import { useHistory } from 'react-router-dom';

type NewPassword = {
  verificationCode: string;
  onSuccess: () => void;
};

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Minimum of 6 characters'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .min(6, 'Minimum of 6 characters')
    .oneOf([yup.ref('password'), null], 'Passwords does not match'),
});

const CONFIRM_PASSWORD_RESET = gql`
  mutation ConfirmPasswordReset($input: PasswordResetInput!) {
    confirmPasswordReset(input: $input)
  }
`;

const NewPassword: React.FC<NewPassword> = ({
  verificationCode,
  onSuccess,
}) => {
  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const history = useHistory();

  const [confirmPasswordReset, { loading }] = useMutation(
    CONFIRM_PASSWORD_RESET
  );

  const onSubmit = (values: { password: string }) => {
    confirmPasswordReset({
      variables: {
        input: {
          verificationCode,
          newPassword: values.password,
        },
      },
    }).then(() => {
      message.success('Password reset successfully');
      onSuccess();
      history.push('/');
      localStorage.removeItem(storage.AUTHENTICATE_VIA_OTP);
    });
  };

  return (
    <div>
      <p>Please enter your new Password</p>

      <Form layout="vertical">
        <Form.Item
          label="New Password"
          validateStatus={
            (errors?.password || errors?.confirmPassword?.type === 'oneOf') &&
            'error'
          }
          help={
            (errors?.password && errors?.password?.message) ||
            (errors?.confirmPassword?.type === 'oneOf' &&
              errors?.confirmPassword?.message)
          }
        >
          <Controller
            as={Input.Password}
            name="password"
            control={control}
            placeholder="Enter new password"
          />
        </Form.Item>
        <Form.Item
          label="Confirm New Password"
          className="mt-3"
          validateStatus={errors?.confirmPassword && 'error'}
          help={errors?.confirmPassword && errors?.confirmPassword?.message}
        >
          <Controller
            as={Input.Password}
            name="confirmPassword"
            control={control}
            placeholder="Confirm new password"
          />
        </Form.Item>
      </Form>

      <Button
        loading={loading}
        className="mt-3"
        block
        type="primary"
        onClick={handleSubmit(onSubmit)}
      >
        Update Password
      </Button>
    </div>
  );
};

export default NewPassword;
