import styled from 'styled-components';

export const StyledTagContainer = styled.div`
  flex: 1;
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default {};
