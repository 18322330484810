import { isEmpty } from 'lodash';

export default (data: any) => {
  if (isEmpty(data)) {
    return undefined;
  }

  const identifier = Object.keys(data)[0];
  return data[identifier]?.pageInfo || undefined;
};
