import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select } from 'antd';
import { isTypeBank, isTypeWechat, isTypeAlipay } from 'constants/paymentTypes';
import useTranslate from 'utils/useTranslate';
import messages from 'pages/components/WithdrawalMethods/messages';
import globalMsgs from 'messages';

import { StyledSelect } from './styles';

const { Option } = Select;

type Rec = Record<string, any>;
type Props = {
  depositProviderId: string;
  disabled: boolean;
  paymentType: string;
  value: string;
  setFieldValue: (e: string, f: any) => void;
  formItemLayout: Rec;
  errors: Rec;
  presetPaymentChannels: any[];
  providerInfoData: Rec;
  loadingProviderInfo: boolean;
};

const OnlinePaymentChannel = ({
  disabled,
  paymentType,
  value,
  setFieldValue,
  formItemLayout,
  errors,
  presetPaymentChannels,
  providerInfoData,
  loadingProviderInfo,
}: Props) => {
  /* *
   * NOTE:  The paymentChannel field is being cleared when you select a new external deposit provider in the
   *        component of ExternalDepositProvider
   * */
  const translate = useTranslate();

  let options: Array<any> = [];

  if (presetPaymentChannels.length) {
    const filteredChannels = (regex: RegExp) =>
      presetPaymentChannels.filter((paymentChannel) =>
        regex.test(paymentChannel)
      );
    const generateOptions = (newPaymentChannels: any[]) =>
      newPaymentChannels.map((paymentChannel: string) => {
        const paymentProviderName = providerInfoData.name;
        const paymentChannelName = translate(
          globalMsgs[paymentChannel.toUpperCase()]
        ).toUpperCase();

        return {
          value: paymentChannel,
          label: `${paymentProviderName} - ${paymentChannelName}`,
        };
      });

    if (presetPaymentChannels.includes('default')) {
      options = generateOptions(filteredChannels(/default/));
    } else if (isTypeBank(paymentType)) {
      options = generateOptions(filteredChannels(/bank/));
    } else if (isTypeWechat(paymentType)) {
      options = generateOptions(filteredChannels(/wechat/));
    } else if (isTypeAlipay(paymentType)) {
      options = generateOptions(filteredChannels(/alipay/));
    }
  }

  return (
    <Form.Item
      {...formItemLayout}
      label={translate(messages.PAYMENT_CHANNEL)}
      validateStatus={errors.paymentChannel && 'error'}
      help={errors.paymentChannel || null}
    >
      <Input.Group>
        <Row gutter={8}>
          <Col span={24}>
            <StyledSelect
              value={value}
              loading={loadingProviderInfo}
              disabled={disabled || !paymentType}
              onChange={(e) => setFieldValue('paymentChannel', e)}
            >
              {options.map((option: { value: string; label: string }) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </StyledSelect>
          </Col>
        </Row>
      </Input.Group>
    </Form.Item>
  );
};

export default OnlinePaymentChannel;
