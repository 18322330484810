import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { Button, Divider, Row, Col } from 'antd';
import vendorGameMetrics from 'constants/vendorGameMetrics';
import gameTypeOptions from 'constants/gameTypes';
import ChartFilter from './components/ChartFilter';
import TableFilter from './components/TableFilter';
import { StyledLayout, StyledContent } from './styles';
import VendorGameReportsTable from './components/VendorGameReportsTable';
import ChartTable from './components/ChartTable';
import PieChart from './components/PieChart';
import AreaChart from './components/AreaChart';
import { VENDOR_GAME_METRICS } from './queries';

const generateAreaChartQuery = ({
  gameTypeColumns,
  metric,
  dateRange,
}: {
  gameTypeColumns: Array<any>;
  metric: string;
  dateRange: Array<any>;
}) => {
  const queryString = ['query VendorGameChart {'];
  gameTypeColumns.forEach((gt) =>
    queryString.push(
      `
      ${gt}: vendorGameChart(input: {
        metricsType: ${metric},
        gameType: ${gt},
        startDateTime: "${dateRange[0].format()}",
        endDateTime: "${dateRange[1].format()}",
      }) {
        dataPoints {
          label
          value
        }
      }
    `
    )
  );

  queryString.push('}');
  return gql(`${queryString.join('\n')}`);
};

const VendorGameReports = () => {
  const DEFAULT_DATE_START = moment().subtract(1, 'months');
  const DEFAULT_DATE_END = moment();

  const {
    data: metricData,
    loading: metricLoading,
    refetch: refetchMetric,
  } = useQuery(VENDOR_GAME_METRICS, {
    variables: {
      metricType: vendorGameMetrics[0].value,
      startDateTime: DEFAULT_DATE_START.utc(false)
        .startOf('day')
        .format(),
      endDateTime: DEFAULT_DATE_END.utc(false)
        .endOf('day')
        .format(),
    },
  });

  const [state, setState] = useState<any>({
    metric: vendorGameMetrics[0].value,
    dateRange: [DEFAULT_DATE_START, DEFAULT_DATE_END],
    gameTypes: [],
    gameTypeColumns: gameTypeOptions.map((gt) => gt.type),
    vendors: [],
  });

  const FINAL_QUERY = generateAreaChartQuery(state);

  const { data: areaChartData, loading: areaChartDataLoading } = useQuery(
    FINAL_QUERY
  );

  useEffect(() => {
    const { metric, dateRange } = state;
    const queryVariables = {
      metricType: metric,
      startDateTime: dateRange[0]
        .utc(false)
        .startOf('day')
        .format(),
      endDateTime: dateRange[1]
        .utc(false)
        .endOf('day')
        .format(),
    };
    refetchMetric(queryVariables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.metric, state.dateRange]);

  return (
    <StyledLayout>
      <StyledContent className="px-5">
        <div className="px-5 py-3">
          <Row className="mb-4" gutter={16}>
            <ChartFilter state={state} setState={setState} />
          </Row>
          <Row className="mb-4">
            <Col span={7}>
              <PieChart
                gameTypeColumns={state.gameTypeColumns}
                metric={
                  (vendorGameMetrics.find(
                    ({ value }) => value === state.metric
                  ) as { label: string }).label
                }
                dataSource={metricData || {}}
              />
            </Col>
            <Col span={17}>
              <AreaChart
                metric={
                  (vendorGameMetrics.find(
                    ({ value }) => value === state.metric
                  ) as { label: string }).label
                }
                data={areaChartDataLoading ? {} : areaChartData}
              />
            </Col>
          </Row>
          <ChartTable
            state={state}
            dataSource={metricData || {}}
            loading={metricLoading}
          />
          <Divider className="mt-5" />
          <TableFilter state={state} setState={setState} />
          <div className="text-right">
            <Button className="mb-3">
              <FormattedMessage
                id="reports.download-csv.text"
                defaultMessage="Download CSV"
              />
            </Button>
          </div>
          <VendorGameReportsTable state={state} />
        </div>
      </StyledContent>
    </StyledLayout>
  );
};

export default VendorGameReports;
