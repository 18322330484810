import React from 'react';
import { Card, Typography, Collapse, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';
import {
  StyledCardFlex,
  ModifiedCollapse,
} from 'pages/components/NewMemberDetail/components/MemberDetailContent/components/MemberInfo/styles';
import { Status } from 'SuperAdminMain/components/AccountManagementNew/AMBody/Table/Status/Status';
import { useAccount360 } from '../../AccountContext';

const { Panel } = Collapse;

export const ClientDetails = () => {
  const {
    admin: {
      status,
      company,
      trial,
      vendorGroup,
      adminCode,
      contractEndDateTime,
      contractStartDateTime,
      dateTimeCreated,
      mobileNumber,
      email,
      qqId,
      wechatId,
      skypeId,
      personInCharge,
      currency,
      multiCurrencyEnabled,
    },
  } = useAccount360();

  return (
    <Card
      bordered={false}
      title={
        <Typography.Text className="head-typography fw-500 text-dark">
          <FormattedMessage
            id="client-details.text"
            defaultMessage="Client Details"
          />
        </Typography.Text>
      }
      extra={<Status status={status} />}
    >
      <StyledCardFlex className="" style={{}}>
        <Typography.Text>
          <FormattedMessage
            id="client-company.text"
            defaultMessage="Client (Company)"
          />
        </Typography.Text>

        <Typography.Text>{company || '-'}</Typography.Text>
      </StyledCardFlex>
      <StyledCardFlex className="" style={{}}>
        <Typography.Text>
          <FormattedMessage id="category.text" defaultMessage="Category" />
        </Typography.Text>

        <Typography.Text>{trial ? 'Trial' : 'Production'}</Typography.Text>
      </StyledCardFlex>
      <StyledCardFlex className="" style={{}}>
        <Typography.Text>
          <FormattedMessage
            id="vendor-group.text"
            defaultMessage="Vendor Group"
          />
        </Typography.Text>

        <Typography.Text>{vendorGroup?.name || '-'}</Typography.Text>
      </StyledCardFlex>
      <Divider />
      <ModifiedCollapse errorTitle="Client Information">
        <Panel
          key="1"
          header={
            <StyledTitle>
              <FormattedMessage
                id="client-information.text"
                defaultMessage="Client Information"
              />
            </StyledTitle>
          }
        >
          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage
                id="admin-code-url.text"
                defaultMessage="Admin Code/URL"
              />
            </Typography.Text>

            <Typography.Text>{adminCode}</Typography.Text>
          </StyledCardFlex>

          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage
                id="expiry-date.text"
                defaultMessage="Expiry Date"
              />
            </Typography.Text>

            <Typography.Text>
              {moment(contractEndDateTime).format('YYYY/MM/DD  HH:mm:ss')}
            </Typography.Text>
          </StyledCardFlex>

          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage
                id="start-date.text"
                defaultMessage="Start Date"
              />
            </Typography.Text>

            <Typography.Text>
              {moment(contractStartDateTime).format('YYYY/MM/DD  HH:mm:ss')}
            </Typography.Text>
          </StyledCardFlex>

          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage
                id="created-date.text"
                defaultMessage="Created Date"
              />
            </Typography.Text>

            <Typography.Text>
              {moment(dateTimeCreated).format('YYYY/MM/DD  hh:mm:ss')}
            </Typography.Text>
          </StyledCardFlex>

          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage
                id="person-in-charge.text"
                defaultMessage="Person In-charge"
              />
            </Typography.Text>

            <Typography.Text>{personInCharge || '-'}</Typography.Text>
          </StyledCardFlex>

          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage
                id="mobile-phone.text"
                defaultMessage="Mobile Phone"
              />
            </Typography.Text>

            <Typography.Text>{mobileNumber || '-'}</Typography.Text>
          </StyledCardFlex>

          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage id="email.text" defaultMessage="Email" />
            </Typography.Text>

            <Typography.Text>{email || '-'}</Typography.Text>
          </StyledCardFlex>

          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage
                id="qq-number.text"
                defaultMessage="QQ Number"
              />
            </Typography.Text>

            <Typography.Text>{qqId || '-'}</Typography.Text>
          </StyledCardFlex>

          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage id="wechat.text" defaultMessage="WeChat" />
            </Typography.Text>

            <Typography.Text>{wechatId || '-'}</Typography.Text>
          </StyledCardFlex>

          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage id="skype.text" defaultMessage="Skype" />
            </Typography.Text>

            <Typography.Text>{skypeId || '-'}</Typography.Text>
          </StyledCardFlex>
          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage id="currency.text" defaultMessage="Currency" />
            </Typography.Text>

            <Typography.Text>{currency || '-'}</Typography.Text>
          </StyledCardFlex>
          <StyledCardFlex className="" style={{}}>
            <Typography.Text>
              <FormattedMessage
                id="multi-currency-enabled.text"
                defaultMessage="Multi-Currency"
              />
            </Typography.Text>

            <Typography.Text>
              {multiCurrencyEnabled ? 'Enabled' : 'Disabled'}
            </Typography.Text>
          </StyledCardFlex>
        </Panel>
      </ModifiedCollapse>
    </Card>
  );
};

const StyledTitle = styled.span`
  font-weight: 500 !important;
  font-size: 12px !important;
`;
