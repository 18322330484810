import React from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { FilterStateType, SetFilterStateType } from '../../../../types';

type Props = {
  state: FilterStateType;
  setState: SetFilterStateType;
};

const FreeSpinFilter: React.FC<Props> = ({ state, setState }) => (
  <>
    <div className="mb-1">
      <FormattedMessage
        id="reports.free-spin.text"
        defaultMessage="Free Spin"
      />
    </div>
    <div>
      <Checkbox
        checked={(state.freeSpin && state.freeSpin.eq) as boolean}
        onChange={() =>
          setState((prev: FilterStateType) => ({
            ...prev,
            freeSpin: {
              eq: true,
            },
          }))
        }
        // onChange={() => handleChange(true)}
      >
        <FormattedMessage id="yes.text" defaultMessage="Yes" />
      </Checkbox>{' '}
      <Checkbox
        checked={(state.freeSpin && !state.freeSpin.eq) as boolean}
        onChange={() =>
          setState((prev: FilterStateType) => ({
            ...prev,
            freeSpin: {
              eq: false,
            },
          }))
        }
      >
        <FormattedMessage id="no.text" defaultMessage="No" />
      </Checkbox>
    </div>
    <div>
      <Checkbox
        checked={state.freeSpin === null}
        onChange={() =>
          setState((prev: FilterStateType) => ({
            ...prev,
            freeSpin: null,
          }))
        }
      >
        <FormattedMessage id="both.text" defaultMessage="Both" />
      </Checkbox>
    </div>
  </>
);

export default FreeSpinFilter;
