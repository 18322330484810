import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';
import { Switch, Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import MultiTierForm from 'components/MultiTierForm';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import coercedGet from 'utils/coercedGet';
import GGRChargeInput from './components/GGRChargeInput';
import GGRChargeCheckBox from './components/GGRChargeCheckBox';
import { paste, setGGRTierFields, getCopiedValues } from '../../constants';
import { useCreateAffiliateProgrammeState } from '../../../../../../context';
import { CostSettingsTypes, useCostSettingsContext } from '../../context';

const StyledSwitch = styled(Switch)<any>`
  &.ant-switch-checked {
    background-color: #3bc98c;
  }
`;

const menu = (menuFunctions: any) => (
  <Menu>
    <Menu.Item onClick={() => menuFunctions.copyCharge()}>
      <FormattedMessage
        id="copy-ggr-charge.text"
        defaultMessage="Copy GGR Charge"
      />
    </Menu.Item>
    <Menu.Item onClick={() => menuFunctions.pasteCharge()}>
      <FormattedMessage
        id="paste-ggr-charge.text"
        defaultMessage="Paste GGR Charge"
      />
    </Menu.Item>
  </Menu>
);

function SiderContent() {
  const [GGRTiers, setGGRTiers] = useState<any[]>([]);

  const [{ copied: copiedValues }, dispatch] = useCostSettingsContext();

  const { values, setFieldValue } = useFormikContext() as any;
  const [{ activeVendor }] = useCreateAffiliateProgrammeState();
  const { id, gameType } = activeVendor;
  const checked = get(
    values,
    `vendorHandlers[${gameType}_${id}][ggrChargeCostSharing]`,
    false
  );

  const setTiers = (vals: any[]) => {
    setGGRTiers(vals);
    setFieldValue(`vendorHandlers[${gameType}_${id}][chargeLevels]`, []);
    return vals.map(({ minimum, value }, index) => {
      setFieldValue(
        `vendorHandlers[${gameType}_${id}][chargeLevels][${index}][minimumTotalWinloss]`,
        minimum
      );
      setFieldValue(
        `vendorHandlers[${gameType}_${id}][chargeLevels][${index}][percentage]`,
        value
      );
      return null;
    });
  };

  useEffect(() => {
    const defaultValues = coercedGet(
      values,
      `vendorHandlers[${gameType}_${id}][chargeLevels]`,
      []
    );
    const newValues = defaultValues.map((value: Record<string, any>) => ({
      minimum: value.minimumTotalWinloss,
      value: value.percentage,
      type: 'RELATIVE',
    }));
    setGGRTiers(newValues);
  }, [id, gameType, values]);

  const vendorValues = getCopiedValues({ values, gameType, id });

  const menuFunctions = {
    copyCharge: () => {
      dispatch({
        type: CostSettingsTypes.SET_COPIED,
        payload: vendorValues,
      });
    },
    pasteCharge: () => {
      paste({ gameType, id, setFieldValue }, copiedValues, setGGRTierFields);
    },
  };

  return (
    <div style={{ margin: 20 }}>
      <div className="mb-2">
        <p style={{ float: 'left', fontWeight: 'bold' }}>
          <FormattedMessage
            id="agent.ggr-charge-cost-sharing.text"
            defaultMessage="GGR Charge Cost Sharing"
          />
        </p>
        <span style={{ float: 'right' }}>
          <StyledSwitch
            unCheckedChildren={
              <FormattedMessage defaultMessage="Off" id="off.text" />
            }
            checkedChildren={
              <FormattedMessage defaultMessage="On" id="on.text" />
            }
            checked={checked}
            name={`vendorHandlers[${gameType}_${id}][ggrChargeCostSharing]`}
            onChange={(v: any) =>
              setFieldValue(
                `vendorHandlers[${gameType}_${id}][ggrChargeCostSharing]`,
                v
              )
            }
          />
        </span>
        <div className="clearfix" />
      </div>

      <GGRChargeCheckBox name="minimum" />
      <GGRChargeCheckBox name="maximum" />

      <GGRChargeInput name="minimum" />
      <GGRChargeInput name="maximum" />
      <div className="mt-2">
        <p style={{ float: 'left', fontWeight: 'bold' }}>
          <FormattedMessage
            id="agent.ggr-percent-charge-tiers.text"
            defaultMessage="GGR % Charge Tiers"
          />
        </p>
        <b style={{ float: 'right' }}>
          <Dropdown overlay={menu(menuFunctions)}>
            <EllipsisOutlined style={{ cursor: 'pointer' }} />
          </Dropdown>
        </b>
        <div className="clearfix" />
      </div>
      {/* <Table pagination={false} columns={columns} dataSource={data} /> */}
      <MultiTierForm
        values={{ GGRTiers }}
        setFieldValue={(_: any, vals) => setTiers(vals)}
        onlyRelative
        oneStepIncrement
        field="GGRTiers"
      />
    </div>
  );
}

export default SiderContent;
