import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  /* NAVIGATION TAB */
  .ant-tabs-nav-container {
    margin-bottom: -17px;
  }

  .ant-tabs-nav-wrap,
  .ant-tabs-nav-scroll {
    background: white;
  }

  .ant-tabs-tab {
    font-size: 16px;
    height: 64px;
    color: #000000a6;
    transform: translateY(8px);
  }

  .ant-tabs-tab-active {
    font-weight: normal;
  }

  /* TABLE */
  table tr:nth-child(2n) td {
    background-color: #fcfcfc;
  }

  thead > tr > th {
    height: 48px;
    padding: 0 0 0 16px;
  }

  tbody > tr > td {
    height: 72px;
    padding: 0 0 0 16px;
  }

  .ant-table-column-title {
    font-weight: normal;
  }

  .ant-tabs-nav-list {
    margin-left: 17px;
  }
`;
