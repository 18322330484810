import styled from 'styled-components';

export const ContainerStyled = styled.div`
  margin-left: 10px;
  width: 18.67px;

  .ant-tooltip-inner {
    paading: 14px 16px;
    min-width: unset !important;
    max-height: unset !important;
  }
`;

export const BubbleContainerStyled = styled.div`
  position: relative;
  height: 16px;
`;

export const BubbleStyled = styled.div`
  background-color: #ff0000;
  padding: 0 8.5px;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: #fff;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  line-height: 1.8;

  ::after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: -5px;

    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ff0000;
  }
`;

export const RemarkContainerStyled = styled.div`
  font-size: 10px;
  .content {
    margin-bottom: 10px;
  }
`;
