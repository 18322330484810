import React from 'react';
import { Popover } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { DynamicObj } from 'interfaces/user.interface';
import { FormattedMessage } from 'react-intl';
import AddMemberTag from './components/AddMemberTag';
import MemberTags from './components/AddMemberTag/MemberTags';

type Props = {
  value: DynamicObj[];
  onChange: (e: Record<string, any>[]) => void;
};

const MemberTagsSelect = ({ value = [], onChange }: Props) => {
  const handleAddTag = (e: Record<string, any>) => {
    if (!value.map((item) => item.id).includes(e.id)) {
      onChange([...value, e]);
    }
  };

  const handleRemoveTag = (e: string) => {
    onChange(value.filter((item) => item.id !== e));
  };

  return (
    <div className="d-flex">
      {value.length > 0 && <MemberTags tags={value} />}
      <Popover
        placement="bottom"
        content={
          <AddMemberTag
            handleRemoveTag={handleRemoveTag}
            onSelect={handleAddTag}
            value={value}
          />
        }
        trigger="click"
      >
        <ALink>
          <FormattedMessage id="EDIT" defaultMessage="Edit" />
        </ALink>
      </Popover>
    </div>
  );
};

export default MemberTagsSelect;
