import { useLazyQuery } from '@apollo/react-hooks';
import { Button, message, Modal, Typography } from 'antd';
import MembersSelect from 'components/Antd/MembersSelect';
import ALL_PERMISSIONS from 'constants/permissions3';
import { MEMBERS } from 'graphql/queries/balanceServiceRecord.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'pages/components/Reports/BalanceServiceRecordReport/messages';
import commonMessages from 'messages';
import React, { useState } from 'react';
import { usePermissions } from 'store/accountState';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 19.5px 17.5px;
  }

  .ant-modal-body {
    padding: 18.5px 17.5px 27.5px;
  }
`;

const StyledLabel = styled.label`
  font-size: 10px;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.65);
`;

const SearchMember = ({
  setDefaultMember,
  visible,
  toggleModal,
}: {
  setDefaultMember: (e: { id: string }) => void;
  visible: boolean;
  toggleModal: () => void;
}) => {
  const [username, setUsername] = useState<string>('');
  const translate = useTranslate();
  const [loadMembers, { loading }] = useLazyQuery(MEMBERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ members }) => {
      if (members.edges.length) {
        setDefaultMember(members.edges[0].node);
      } else {
        message.error(translate(messages.INVALID_USER));
      }
    },
    onError: () => {
      message.error(translate(commonMessages.INTERNAL_SERVER_ERROR));
    },
  });

  const handleSearch = async () => {
    const variables = {
      first: 1,
      filter: {
        username: { eq: username },
      },
    };

    loadMembers({ variables });
  };

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  return (
    <StyledModal
      width="304px"
      visible={visible}
      title={translate(messages.BALANCE_SERVICE_REPORT)}
      maskClosable={false}
      onCancel={toggleModal}
      footer={[
        <Button disabled={loading} onClick={toggleModal}>
          {translate(commonMessages['cancel.text'])}
        </Button>,
        <Button
          disabled={loading || !username}
          key="submit"
          type="primary"
          onClick={handleSearch}
        >
          {translate(commonMessages.CONFIRM)}
        </Button>,
      ]}
    >
      {ALLOWED_LIST ? (
        <>
          <StyledLabel>{translate(commonMessages.USERNAME)}</StyledLabel>
          <br />
          <MembersSelect
            className="mt-1"
            value={username}
            placeHolder={translate(commonMessages.ENTER_USERNAME)}
            onChange={(e) => setUsername(e)}
            labelAsValue
          />
        </>
      ) : (
        <div className="text-center">
          <Typography.Text type="danger">
            This Operator does not have Member Permission
          </Typography.Text>
        </div>
      )}
    </StyledModal>
  );
};

export default SearchMember;
