import { StyledComponent } from 'styled-components';
import React from 'react';
import { OptionProps } from 'antd/lib/select';
import { IMemberLevel } from 'pages/components/SystemMessage/ManualMessage/interfaces';
import { truncateProgramName } from 'utils/truncateProgramName';

export interface ILevelObject {
  id: string;
  shortName: string | null;
  fullName: string;
  color: string;
}

export interface IPrograms {
  name: string;
  levels: IMemberLevel[];
}

export interface ILevels extends IMemberLevel {
  programme: {
    name: string;
  };
}

export interface ISelected {
  activeItem: StyledComponent<
    React.ClassicComponentClass<OptionProps>,
    {
      color?: string;
    }
  >[];
  stringValue: string[];
}

export interface Props {
  value: any;
  onChange: <T extends {}>(e: T[]) => void;
  disabled: boolean;
  truncate?: boolean;
}

export const createLevelObject = (
  programmeName: string,
  level: IMemberLevel,
  truncate?: boolean
) => {
  const { id, name: levelName, color } = level;
  const fullName = `${programmeName || ''} - ${levelName || ''}`;
  let { text: shortName } = truncate
    ? truncateProgramName(programmeName)
    : { text: programmeName };
  shortName += ` - ${levelName}`;

  const levelObj: ILevelObject = {
    id,
    shortName,
    fullName,
    color,
  };

  return levelObj;
};
