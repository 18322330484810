import React from 'react';
import { Radio, Spin } from 'antd';
import { PAYMENT_TYPES, PAYMENT_SOURCES } from 'constants/paymentTypes';
import useTranslate from 'utils/useTranslate';
import { WithdrawalProviderSchemaTypes } from 'pages/components/WithdrawalProviders/constants';
import messages from 'pages/components/WithdrawalMethods/messages';

type Props = {
  handleChange: (e: any) => void;
  value: string;
  name: string;
  paymentType: string;
  disabled: boolean;
  paymentChannels: Array<any>;
  providerType: string;
  validatingChannels: boolean;
};

const InternalProviders = ({
  value,
  handleChange,
  name,
  paymentType,
  disabled,
  paymentChannels,
  providerType,
  validatingChannels,
}: Props) => {
  const translate = useTranslate();
  const providers = {
    OFFLINE: [
      {
        label: translate(messages.banktransfer),
        value: PAYMENT_TYPES.OFFLINE.BANK_WITHDRAW,
      },
      {
        label: translate(messages.wechat),
        value: PAYMENT_TYPES.OFFLINE.WECHAT_WITHDRAW,
      },
      {
        label: translate(messages.alipay),
        value: PAYMENT_TYPES.OFFLINE.ALIPAY_WITHDRAW,
      },
      {
        label: translate(messages.hexopay),
        value: PAYMENT_TYPES.OFFLINE.HEXOPAY,
      },
      {
        label: 'Astropay Wallet',
        value: PAYMENT_TYPES.OFFLINE.ASTROPAYWALLET,
      },
    ],
    ONLINE: [
      {
        label: translate(messages.banktransfer),
        value: PAYMENT_TYPES.ONLINE.BANK_WITHDRAW,
        testCondition: /bank/,
      },
      {
        label: translate(messages.wechat),
        value: PAYMENT_TYPES.ONLINE.WECHAT_WITHDRAW,
        testCondition: /wechat/,
      },
      {
        label: translate(messages.alipay),
        value: PAYMENT_TYPES.ONLINE.ALIPAY_WITHDRAW,
        testCondition: /alipay/,
      },
    ],
  };

  let providerTypes = providers[paymentType];

  if (paymentType === PAYMENT_SOURCES.ONLINE && paymentChannels.length) {
    /* *
     * Displays only the Payment Methods(providerTypes) available within the
     * selected Payment Provider(depositProvider) paymentChannels.
     * */
    const testProviderType = ({ testCondition }: { testCondition: RegExp }) =>
      paymentChannels.some((paymentChannel) =>
        testCondition.test(paymentChannel)
      );

    providerTypes = providerTypes.filter(testProviderType);
  }

  if (
    providerType ===
      WithdrawalProviderSchemaTypes.MidpayDirectWithdrawalProvider &&
    !validatingChannels &&
    !paymentChannels.length
  ) {
    return (
      <span className="text-danger">
        {translate(messages.NO_PAYMENT_METHOD_FOUND)}
      </span>
    );
  }

  return (
    <Spin spinning={validatingChannels}>
      <Radio.Group
        onChange={handleChange}
        value={value}
        name={name}
        disabled={disabled || validatingChannels}
      >
        {providerTypes.map(
          (item: { value: string; label: string }, key: number) => (
            <Radio value={item.value} key={key}>
              {item.label}
            </Radio>
          )
        )}
      </Radio.Group>
    </Spin>
  );
};

export default InternalProviders;
