import React from 'react';
import { uniq } from 'lodash';
import { useFilterValues } from 'contexts/Filters';
import { PageInfo } from 'types/graphqlTypes';
import { useFilterValues as useFilterValuesSA } from 'SuperAdminMain/contexts/Filters';

const actions = {
  NEXT: 'NEXT',
  PREV: 'PREV',
  SETSTATE: 'SETSTATE',
  RESET_PAGE: 'RESET_PAGE',
  SET_FIRST_PAGE: 'SET_FIRST_PAGE',
  SORT: 'SORT',
};

type PageStateType = {
  first: number;
  after: any | undefined;
  savedCursor: Array<string | undefined>;
  currentPage: number;
  sort?: string;
};

type actionType = {
  type: string;
  payload?: PageStateType & PageInfo;
};

type NextPayloadType = PageStateType & PageInfo;

const defaultInitState = {
  first: 10,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
  sort: 'descend',
};

export const useTablePagination = (
  initialState: PageStateType = defaultInitState
) => {
  const { filters } = useFilterValues();

  const paginationReducer = (state: PageStateType, action: actionType) => {
    const { type, payload } = action;

    const setNext = () => {
      const pageInfo = payload as NextPayloadType;
      const { currentPage } = state;
      let { savedCursor } = state;

      savedCursor = uniq(savedCursor);
      savedCursor.push(pageInfo.endCursor);

      return {
        ...state,
        after: pageInfo.endCursor,
        currentPage: currentPage + 1,
        savedCursor,
      };
    };

    const setPrev = () => {
      const { currentPage } = state;
      let { savedCursor } = state;
      savedCursor = uniq(savedCursor);
      const prevPage = currentPage - 1;
      const after = savedCursor[prevPage] || undefined;
      return { ...state, after, currentPage: prevPage };
    };

    const setState = () => ({ ...state, ...payload });

    const setFirstPage = () => ({
      ...state,
      after: undefined,
      savedCursor: [undefined],
      currentPage: 0,
    });

    const setSort = () => ({
      ...state,
      sort: payload,
    });

    switch (type) {
      case actions.NEXT:
        return setNext();
      case actions.PREV:
        return setPrev();
      case actions.SETSTATE:
        return setState();
      case actions.RESET_PAGE:
        return initialState;
      case actions.SET_FIRST_PAGE:
        return setFirstPage();
      case actions.SORT:
        return setSort();
      default:
        return null;
    }
  };

  const pageReducer: any = paginationReducer;

  const [paginationState, dispatchPagination] = React.useReducer(
    pageReducer,
    initialState
  ) as any;

  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }

    dispatchPagination({ type: actions.SET_FIRST_PAGE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return [paginationState, dispatchPagination, actions];
};

export const useTablePaginationSA = (
  initialState: PageStateType = defaultInitState
) => {
  const { filters } = useFilterValuesSA();

  const paginationReducer = (state: PageStateType, action: actionType) => {
    const { type, payload } = action;

    const setNext = () => {
      const pageInfo = payload as NextPayloadType;
      const { currentPage } = state;
      let { savedCursor } = state;

      savedCursor = uniq(savedCursor);
      savedCursor.push(pageInfo.endCursor);

      return {
        ...state,
        after: pageInfo.endCursor,
        currentPage: currentPage + 1,
        savedCursor,
      };
    };

    const setPrev = () => {
      const { currentPage } = state;
      let { savedCursor } = state;
      savedCursor = uniq(savedCursor);
      const prevPage = currentPage - 1;
      const after = savedCursor[prevPage];
      return { ...state, after, currentPage: prevPage };
    };

    const setState = () => ({ ...state, ...payload });

    switch (type) {
      case actions.NEXT:
        return setNext();
      case actions.PREV:
        return setPrev();
      case actions.SETSTATE:
        return setState();
      case actions.RESET_PAGE:
        return initialState;

      default:
        return null;
    }
  };

  const pageReducer: any = paginationReducer;

  const [paginationState, dispatchPagination] = React.useReducer(
    pageReducer,
    initialState
  ) as any;

  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }

    dispatchPagination({ type: actions.RESET_PAGE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return [paginationState, dispatchPagination, actions];
};

export const useNoRefreshPaginate = (
  initialState: PageStateType = defaultInitState
) => {
  const paginationReducer = (state: PageStateType, action: actionType) => {
    const { type, payload } = action;

    const setNext = () => {
      const pageInfo = payload as NextPayloadType;
      const { currentPage } = state;
      let { savedCursor } = state;

      savedCursor = uniq(savedCursor);
      savedCursor.push(pageInfo.endCursor);

      return {
        ...state,
        after: pageInfo.endCursor,
        currentPage: currentPage + 1,
        savedCursor,
      };
    };

    const setPrev = () => {
      const { currentPage } = state;
      let { savedCursor } = state;
      savedCursor = uniq(savedCursor);
      const prevPage = currentPage - 1;
      const after = savedCursor[prevPage];
      return { ...state, after, currentPage: prevPage };
    };

    const setState = () => ({ ...state, ...payload });

    switch (type) {
      case actions.NEXT:
        return setNext();
      case actions.PREV:
        return setPrev();
      case actions.SETSTATE:
        return setState();
      case actions.RESET_PAGE:
        return initialState;

      default:
        return null;
    }
  };

  const pageReducer: any = paginationReducer;

  const [paginationState, dispatchPagination] = React.useReducer(
    pageReducer,
    initialState
  ) as any;

  return [paginationState, dispatchPagination, actions, defaultInitState];
};

export default {};
