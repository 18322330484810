import React, { useState, useEffect } from 'react';
import messages from 'messages';
import { defineMessages } from 'react-intl';
import { useFilterValues } from 'contexts/Filters';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';
import { Tag } from 'antd';
import coercedGet from 'utils/coercedGet';
import AccountTag from 'components/AccountTag';
import MemberLoyaltyLevelTag from 'components/MemberLoyaltyLevelTag';
import MemberLevelTag from 'components/MemberLevelTag';
import { isEmpty } from 'lodash';
import ProcessorTag from 'components/ProcessorTag';
import { unassignedProcessorLabel } from 'constants/processorValues';
import useTranslate from 'utils/useTranslate';
import {
  PAYMENT_TYPES_WITHDRAWAL,
  PAYMENT_TYPES_DEPOSIT,
} from 'constants/paymentTypes';
import { DATE_FORMAT } from 'constants/date';

import { useSelector } from 'react-redux';

const { OFFLINE, ONLINE } = PAYMENT_TYPES_WITHDRAWAL;

const localMessages = defineMessages({
  withdrawalTax: {
    id: 'withdrawal-tax.text',
    defaultMessage: 'Withdrawal tax',
  },
  handlingFee: {
    id: 'handling-fee.text',
    defaultMessage: 'Handling fee',
  },
  promoDeduction: {
    id: 'promo-deduction.text',
    defaultMessage: 'Promo deduction',
  },
  withdrawalLimitFee: {
    id: 'withdrawals.withdrawal-limit-fee.text',
    defaultMessage: 'Withdrawal limit fee',
  },
  promoHunter: {
    id: 'promo-hunter.text',
    defaultMessage: 'Promo hunter',
  },
  contraBet: {
    id: 'contra-bet.text',
    defaultMessage: 'Contra bet',
  },
  oneTimeTurnoverDetection: {
    id: 'one-time-turnover-detection.text',
    defaultMessage: 'One time turnover detection',
  },
  ipCollisionOffender: {
    id: 'ip-collision-offender.text',
    defaultMessage: 'IP collision offender',
  },
  martingaleBetOffender: {
    id: 'martingale-bet-offender.text',
    defaultMessage: 'Martingale bet offender',
  },
  minWithdrawalAmount: {
    id: 'minimum-withdrawal-amount.text',
    defaultMessage: 'Minimum withdrawal amount',
  },
  maxWithdrawalAmount: {
    id: 'maximum-withdrawal-amount.text',
    defaultMessage: 'Maximum withdrawal amount',
  },
});

const FilterConditions = () => {
  const translate = useTranslate();
  const { filters, setPersistedFilters } = useFilterValues();

  const [localStateFilter, setLocalStateFilter] = useState<Record<string, any>>(
    {}
  );

  useEffect(() => {
    const transformedDateFilters = transformMovableDateFilter(filters);
    setLocalStateFilter(transformedDateFilters || {});
  }, [filters, setLocalStateFilter]);

  const handleRemoveFilterTag = (
    name: string,
    value: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const field = coercedGet(filters, name, []);
    const data = field.in.filter((item: string) => item !== value);
    const newField = {
      in: data,
    };
    setPersistedFilters({
      ...filters,
      [`${name}`]: data.length ? newField : null,
    });
  };

  const handleRemoveDate = (name: string, e: Record<string, any>) => {
    e.preventDefault();

    setPersistedFilters({
      ...filters,
      [`${name}`]: null,
    });
  };

  const handleRemoveAmountTag = (
    name: string,
    range: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const data = { ...filters[`${name}`] };
    delete data[`${range}`];
    setPersistedFilters({
      ...filters,
      [`${name}`]: isEmpty(data) ? null : data,
    });
  };

  const handleRemoveCheckboxFilter = (name: string, e: Record<string, any>) => {
    e.preventDefault();
    setPersistedFilters({
      ...filters,
      [`${name}`]: null,
    });
  };

  const handleRemovePaymentMethodType = (
    value: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const { paymentMethodType } = filters;
    const data = paymentMethodType.in.filter((item: string) => item !== value);
    const newPaymentMethodType = {
      in: data.length ? data : PAYMENT_TYPES_DEPOSIT.OFFLINE,
    };
    setPersistedFilters({
      ...filters,
      paymentMethodType: newPaymentMethodType,
    });
  };

  const handleRemoveProcessor = (value: string, e: Record<string, any>) => {
    e.preventDefault();
    const { processor } = filters;
    const inFilters = processor.in.filter((item: string) => item !== value);
    const ninFilters = processor.nin ? [...processor.nin, value] : [];

    const newProcessor = {
      ...(inFilters.length ? { in: inFilters } : {}),
      ...(ninFilters.length ? { nin: ninFilters } : {}),
    };

    setPersistedFilters({
      ...filters,
      processor: newProcessor.nin || newProcessor.in ? newProcessor : null,
    });
  };

  const handleRemoveUnassignedProcessor = (e: Record<string, any>) => {
    e.preventDefault();
    setPersistedFilters({
      ...filters,
      processor:
        filters.processor.in && filters.processor.in.length
          ? { in: filters.processor.in }
          : null,
    });
  };

  const handleRemovePaymentSource = (
    source: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    let newPaymentSources = [...filters.withdrawalMethodType.in];
    const removeFromList = (currentSources: any[], sourceBase: string) =>
      currentSources.filter((currentSource) => sourceBase !== currentSource);

    if (source === 'THIRD_PARTY') {
      newPaymentSources = ONLINE.reduce(removeFromList, newPaymentSources);
    } else if (source === 'INTERNAL') {
      newPaymentSources = OFFLINE.reduce(removeFromList, newPaymentSources);
    }

    setPersistedFilters({
      ...filters,
      withdrawalMethodType: newPaymentSources.length
        ? { in: newPaymentSources }
        : null,
    });
  };
  const { paymentList } = useSelector((state: any) => state.method);

  return (
    <>
      {filters?.withdrawalMethod
        ? filters.withdrawalMethod.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) =>
                handleRemoveFilterTag('withdrawalMethodType', item, e)
              }
            >
              {paymentList?.find((d: any) => d.value === item)?.label}
            </Tag>
          ))
        : null}
      {filters.brandId
        ? filters?.brandId?.in?.map((item: string) => (
            <Tag
              key={item}
              closable
              onClose={(e) => handleRemoveFilterTag('brandId', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.platformId
        ? filters?.platformId?.in?.map((item: string) => (
            <Tag
              key={item}
              closable
              onClose={(e) => handleRemoveFilterTag('platformId', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.serialCode
        ? filters.serialCode.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('serialCode', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.account
        ? filters.account.in.map((item: string, key: number) => (
            <AccountTag
              onClose={(e) => handleRemoveFilterTag('account', item, e)}
              key={key}
              id={item}
            />
          ))
        : null}
      {filters.memberLoyaltyLevel
        ? filters.memberLoyaltyLevel.in.map((item: string, key: number) => (
            <MemberLoyaltyLevelTag
              islight
              onClose={(e) =>
                handleRemoveFilterTag('memberLoyaltyLevel', item, e)
              }
              key={key}
              id={item}
            />
          ))
        : null}
      {filters.memberLevel
        ? filters.memberLevel.in.map((item: string, key: number) => (
            <MemberLevelTag
              onClose={(e) => handleRemoveFilterTag('memberLevel', item, e)}
              key={key}
              id={item}
            />
          ))
        : null}
      {filters.withdrawalTaxFeeApplied ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveCheckboxFilter('withdrawalTaxFeeApplied', e)
          }
        >
          {translate(localMessages.withdrawalTax)}
        </Tag>
      ) : null}
      {filters.handlingFeeApplied ? (
        <Tag
          closable
          onClose={(e) => handleRemoveCheckboxFilter('handlingFeeApplied', e)}
        >
          {translate(localMessages.handlingFee)}
        </Tag>
      ) : null}
      {filters.promoDeductionFeeApplied ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveCheckboxFilter('promoDeductionFeeApplied', e)
          }
        >
          {translate(localMessages.promoDeduction)}
        </Tag>
      ) : null}
      {filters.withdrawalLimitFeeApplied ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveCheckboxFilter('withdrawalLimitFeeApplied', e)
          }
        >
          {translate(localMessages.withdrawalLimitFee)}
        </Tag>
      ) : null}
      {filters.oneTimeTurnoverDetectionCheck ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveCheckboxFilter('oneTimeTurnoverDetectionCheck', e)
          }
        >
          {translate(localMessages.oneTimeTurnoverDetection)}
        </Tag>
      ) : null}
      {filters.promoHunterCheck ? (
        <Tag
          closable
          onClose={(e) => handleRemoveCheckboxFilter('promoHunterCheck', e)}
        >
          {translate(localMessages.promoHunter)}
        </Tag>
      ) : null}
      {filters.contraBetCheck ? (
        <Tag
          closable
          onClose={(e) => handleRemoveCheckboxFilter('contraBetCheck', e)}
        >
          {translate(localMessages.contraBet)}
        </Tag>
      ) : null}
      {filters.ipCollisionOffenderCheck ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveCheckboxFilter('ipCollisionOffenderCheck', e)
          }
        >
          {translate(localMessages.ipCollisionOffender)}
        </Tag>
      ) : null}
      {filters.martingaleBetOffenderCheck ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveCheckboxFilter('martingaleBetOffenderCheck', e)
          }
        >
          {translate(localMessages.martingaleBetOffender)}
        </Tag>
      ) : null}
      {filters.amount && filters.amount.gte ? (
        <Tag
          closable
          onClose={(e) => handleRemoveAmountTag('amount', 'gte', e)}
        >
          {translate(localMessages.minWithdrawalAmount)}: {filters.amount.gte}
        </Tag>
      ) : null}
      {filters.amount && filters.amount.lte ? (
        <Tag
          closable
          onClose={(e) => handleRemoveAmountTag('amount', 'lte', e)}
        >
          {translate(localMessages.maxWithdrawalAmount)}: {filters.amount.lte}
        </Tag>
      ) : null}
      {filters.paymentMethodType && filters.paymentMethodType.in.length === 1
        ? filters.paymentMethodType.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemovePaymentMethodType(item, e)}
            >
              {translate(messages[`${item}`])}
            </Tag>
          ))
        : null}
      {filters.status
        ? filters.status.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('status', item, e)}
            >
              {translate(messages[`${item}`])}
            </Tag>
          ))
        : null}
      {filters.currency
        ? filters.currency.in.map((item: string, key: string | number) => (
            <Tag
              key={key}
              closable
              onClose={(e: any) => handleRemoveFilterTag('currency', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.withdrawalMethodType
        ? [
            ...(filters.withdrawalMethodType.in.includes(OFFLINE[0])
              ? ['INTERNAL']
              : []),
            ...(filters.withdrawalMethodType.in.includes(ONLINE[0])
              ? ['THIRD_PARTY']
              : []),
          ].map((source) => (
            <Tag
              key={source}
              closable
              onClose={(e) => handleRemovePaymentSource(source, e)}
            >
              {translate(messages[source])}
            </Tag>
          ))
        : null}
      {localStateFilter.dateTimeCreated ? (
        <Tag closable onClose={(e) => handleRemoveDate('dateTimeCreated', e)}>
          {translate(messages.REQUEST_DATE)}:{' '}
          {formatDate(localStateFilter.dateTimeCreated.gte, DATE_FORMAT)} ~{' '}
          {formatDate(localStateFilter.dateTimeCreated.lte, DATE_FORMAT)}
        </Tag>
      ) : null}
      {filters.processor && filters.processor.in
        ? filters.processor.in.map((item: string, key: number) => (
            <ProcessorTag
              onClose={(e) => handleRemoveProcessor(item, e)}
              key={key}
              id={item}
            />
          ))
        : null}
      {filters.processor && filters.processor.nin ? (
        <Tag closable onClose={handleRemoveUnassignedProcessor}>
          {unassignedProcessorLabel}
        </Tag>
      ) : null}
    </>
  );
};

export default FilterConditions;
