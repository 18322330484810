import { defineMessages } from 'react-intl';

const messages = defineMessages({
  depositRequestThirdParty: {
    id: 'deposit-requests-third-party.text',
    defaultMessage: 'Deposit Requests(3rd Party)',
  },
  clearAll: {
    id: 'clear-all.text',
    defaultMessage: 'Clear all',
  },
  approveDepositProcess: {
    id: 'deposits.deposit-request-approved.text',
    defaultMessage: 'Deposit request approved',
  },
  rejectDepositProcess: {
    id: 'deposits.deposit-request-rejected.text',
    defaultMessage: 'Deposit request rejected',
  },
  stopDepositProcess: {
    id: 'deposits.stopped-deposit-process.text',
    defaultMessage: 'Stopped deposit process',
  },
  areYouSure: {
    id: 'are-you-sure?.text',
    defaultMessage: 'Are you sure?',
  },
  yes: {
    id: 'yes.text',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'no.text',
    defaultMessage: 'No',
  },
  approve: {
    id: 'approve.text',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'reject.text',
    defaultMessage: 'Reject',
  },
  stopProcess: {
    id: 'stop-process.text',
    defaultMessage: 'Stop Process',
  },
  remarksRequiredError: {
    id: 'deposits.required-for-rejecting-deposit-request.text',
    defaultMessage: 'Required for rejecting deposit request',
  },
  operatorRemark: {
    id: 'operator-remark.text',
    defaultMessage: 'Operator Remark',
  },
  depositRequestDetails: {
    id: 'deposit-request-details.text',
    defaultMessage: 'Deposit request details',
  },
  serialCode: {
    id: 'serial-code.text',
    defaultMessage: 'Serial code',
  },
  APPROVED: {
    id: 'approved.text',
    defaultMessage: 'Approved',
  },
  REJECTED: {
    id: 'rejected.text',
    defaultMessage: 'Rejected',
  },
  PROCESSING: {
    id: 'processing.text',
    defaultMessage: 'Processing',
  },
  PENDING: {
    id: 'pending.text',
    defaultMessage: 'Pending',
  },
  EXPIRED: {
    id: 'expired.text',
    defaultMessage: 'Expired',
  },
  CANCELLED: {
    id: 'cancelled.text',
    defaultMessage: 'Cancelled',
  },
  FAILED: {
    id: 'failed.text',
    defaultMessage: 'Failed',
  },
  memberInfo: {
    id: 'member-info.text',
    defaultMessage: 'Member info',
  },
  requester: {
    id: 'requester.text',
    defaultMessage: 'Requester',
  },
  memberLoyalty: {
    id: 'member-loyalty.text',
    defaultMessage: 'Member loyalty',
  },
  requestTime: {
    id: 'request-time.text',
    defaultMessage: 'Request time',
  },
  requestAmount: {
    id: 'request-amount.text',
    defaultMessage: 'Request amount',
  },
  memberLevel: {
    id: 'member-level.text',
    defaultMessage: 'Member level',
  },
  name: {
    id: 'name.text',
    defaultMessage: 'Name',
  },
  loginIp: {
    id: 'login-ip.text',
    defaultMessage: 'Login IP',
  },
  memberDetailsRemark: {
    id: 'member-details-remark.text',
    defaultMessage: 'Member detail remark',
  },
  labels: {
    id: 'labels.text',
    defaultMessage: 'Labels',
  },
  requestUpdate: {
    id: 'request-update.text',
    defaultMessage: 'Request Update',
  },
  ONLINE_BANK_TRANSFER: {
    id: 'online-bank-transfer.text',
    defaultMessage: 'Online Bank transfer',
  },
  ONLINE_ALIPAY: {
    id: 'online-alipay.text',
    defaultMessage: 'OnlineAlipay',
  },
  ONLINE_WECHAT: {
    id: 'online-wechat.text',
    defaultMessage: 'OnlineWeChat',
  },
  REMARKS_UPDATED: {
    id: 'remarks-updated.text',
    defaultMessage: 'Remarks updated',
  },
  VIP_LEVEL: {
    id: 'vip-level.text',
    defaultMessage: 'VIP Level',
  },
});

export default messages;
