import { useApolloClient } from '@apollo/react-hooks';
import { Select, Spin } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import { MULT_QUERIES } from 'graphql/queries/manualMessage.query';
import { flatten, get, isEmpty, uniqBy } from 'lodash';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import {
  IMember,
  IMemberLevel,
  IMemberLoyaltyLevel,
} from 'pages/components/SystemMessage/ManualMessage/interfaces';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { usePermissions } from 'store/accountState';
import {
  MemberLevelsConnectionEdge,
  MemberLoyaltyLevelsConnectionEdge,
  MemberLoyaltyProgrammesConnectionEdge,
  MembersConnectionsEdge,
} from 'types/graphqlTypes';
import { customFormatMessage } from 'utils/customFormatMessage';
import { StyledMultiSelect } from '../../../CreateNewMail/styles';
import { getMemberName } from '../../utils';
import { Props } from './interface';

const { Option, OptGroup } = Select;

const messages = defineMessages({
  'enter-a-member.text': {
    id: 'enter-a-member.text',
    defaultMessage: 'Enter a member',
  },
  'members.text': {
    id: 'members.text',
    defaultMessage: 'Members',
  },
  'member-levels.text': {
    id: 'member-levels.text',
    defaultMessage: 'Member Levels',
  },
  'member-loyalty-levels.text': {
    id: 'member-loyalty-levels.text',
    defaultMessage: 'Member Loyalty Levels',
  },
  'tiers.text': {
    id: 'tiers.text',
    defaultMessage: 'Tiers',
  },
});
type DefaultMembers = Array<IMember | IMemberLevel | IMemberLoyaltyLevel>;

export const MultiMembersSelectSidebar: React.FC<Props> = ({
  onSelectMembers,
  values,
  multiSelectType: selectType,
}) => {
  const [opts, setOpts] = useState<any>({});
  const [isFetching, setFetching] = useState<boolean>(false);
  const [input, setInput] = useState<string | null>(null);
  const debouncedInput = useDebounce(input, 500);
  const { formatMessage } = useIntl();
  const client = useApolloClient();

  const { role, permissions } = usePermissions();

  let defaultMembers: DefaultMembers = [];
  defaultMembers =
    values?.members?.in?.reduce<DefaultMembers>(
      (acc, item) => [...acc, item],
      []
    ) || [];

  defaultMembers = values?.memberLevels?.in?.reduce<DefaultMembers>(
    (acc, item) => [...acc, ...defaultMembers, item],
    []
  ) || [...defaultMembers];

  defaultMembers = values?.memberLoyaltyLevels?.in?.reduce<DefaultMembers>(
    (acc, item) => [...acc, ...defaultMembers, item],
    []
  ) || [...defaultMembers];

  const { ALLOWED_LIST: ALLOWED_MEMBER_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const { ALLOWED_LIST: ALLOWED_MARKER_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );
  const { ALLOWED_LIST: ALLOWED_VIP_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  );

  const getMultQueries = async () => {
    const resp = await client.query({
      query: MULT_QUERIES,
      variables: {
        value: debouncedInput || '',
        withMarkerListPermission: ALLOWED_MARKER_LIST,
        withMemberListPermission: ALLOWED_MEMBER_LIST,
        withVIPListPermission: ALLOWED_VIP_LIST,
      },
    });

    const memberLevels = resp.data.memberLevels?.edges.map(
      (edge: MemberLevelsConnectionEdge) => edge.node
    );
    const members = resp.data.members?.edges.map(
      (edge: MembersConnectionsEdge) => edge.node
    );
    const memberLoyaltyLevels = resp.data.memberLoyaltyLevels?.edges.map(
      (edge: MemberLoyaltyLevelsConnectionEdge) => edge.node
    );
    const memberLoyaltyProgrammes = resp.data.memberLoyaltyProgrammes?.edges.map(
      (edge: MemberLoyaltyProgrammesConnectionEdge) => edge.node
    );
    const memberLoyaltyToBeMerged = flatten(
      memberLoyaltyProgrammes?.map((memberLoyaltyProgram: any) =>
        memberLoyaltyProgram.levels.map((level: any) => ({
          id: level.id,
          name: level.name,
          color: level.color,
          programme: {
            id: memberLoyaltyProgram.id,
            name: memberLoyaltyProgram.name,
          },
          __typename: 'MemberLoyaltyLevel',
        }))
      )
    );

    const combinedMemberLoyalty = memberLoyaltyToBeMerged.length
      ? uniqBy([...memberLoyaltyLevels, ...memberLoyaltyToBeMerged], 'id')
      : [];

    setOpts({
      memberLevels,
      members,
      memberLoyaltyLevels: combinedMemberLoyalty,
    });
    setFetching(false);
  };

  useEffect(() => {
    setOpts({});
    setFetching(true);
    getMultQueries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  const membersStringified = defaultMembers?.map((item) =>
    JSON.stringify(item)
  );

  const handleSetActive = (e: any) => {
    if (e.length > defaultMembers.length) {
      const newItem = JSON.parse(e.slice(-1)[0]);
      onSelectMembers([...defaultMembers, newItem]);
    } else {
      const items = e.map((item: any) => JSON.parse(item).id);
      const currentItems = defaultMembers.filter((item: any) =>
        items.includes(item.id)
      );
      onSelectMembers(currentItems);
    }
  };

  const hasMemberLoyalty = !isEmpty(get(opts, 'memberLoyaltyLevels', []));

  const visualLoad =
    defaultMembers?.map((item: any) => (
      <Option
        key={item.id}
        title={item.id}
        value={JSON.stringify(item)}
        style={{ display: 'none' }}
      >
        {getMemberName(item)}
      </Option>
    )) || [];
  const translate = (messageVal: any, options: any = null) =>
    customFormatMessage(formatMessage, messageVal, options);
  return (
    <StyledMultiSelect
      showSearch
      onSearch={(e: any) => setInput(e)}
      filterOption={false}
      notFoundContent={isFetching ? <Spin size="small" /> : null}
      mode="multiple"
      onChange={handleSetActive}
      activeItems={defaultMembers}
      type={selectType}
      placeholder={translate(messages['enter-a-member.text'])}
      value={membersStringified}
    >
      {opts.members && opts.members.length > 0 && (
        <OptGroup label={translate(messages['members.text'])}>
          {opts.members.map((member: IMember) => (
            <Option
              key={member.id}
              title={member.id}
              value={JSON.stringify(member)}
            >
              {member.username}
            </Option>
          ))}
        </OptGroup>
      )}

      {opts.memberLevels && opts.memberLevels.length > 0 && (
        <OptGroup label={translate(messages['member-levels.text'])}>
          {opts.memberLevels.map((memberLevel: IMemberLevel) => (
            <Option
              key={memberLevel.id}
              title={memberLevel.id}
              value={JSON.stringify(memberLevel)}
            >
              {memberLevel.name}
            </Option>
          ))}
        </OptGroup>
      )}

      {hasMemberLoyalty && (
        <OptGroup label={translate(messages['tiers.text'])}>
          {opts.memberLoyaltyLevels.map(
            (memberLoyaltyLevel: IMemberLoyaltyLevel) => (
              <Option
                key={memberLoyaltyLevel.id}
                title={memberLoyaltyLevel.id}
                value={JSON.stringify(memberLoyaltyLevel)}
              >
                {memberLoyaltyLevel.programme.name} - {memberLoyaltyLevel.name}
              </Option>
            )
          )}
        </OptGroup>
      )}

      {visualLoad}
    </StyledMultiSelect>
  );
};
