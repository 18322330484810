import compact from 'lodash/compact';
import keyBy from 'lodash/keyBy';
import { Dictionary } from 'lodash';
import XLSX from 'xlsx';
import {
  TableColumnsType,
  GetColumnsToBeRendered,
  GetMaxDepth,
  FillColumnHeads,
  GetTotalChildCount,
  GetTableHeads,
  GetDataMatrix,
  GroupHeads,
  NotGroupHeads,
  GroupHead,
  NotGroupHead,
  GetMerges,
} from './types';

// Note: Recursion-extensive logic.

// Function for getting total child count on the nested tree or collection
const getTotalChildCount: GetTotalChildCount = (children: TableColumnsType) => {
  let count = 0;

  children.map((item) => {
    if (item.children) {
      count += getTotalChildCount(item.children);
    } else {
      count += 1;
    }
    return undefined;
  });

  return count;
};

// Filter out columns in antd that doesn't have csvData property. If in case of nested(with children property),
// a recursion will occur to check inner columns
const getColumnsToBeRendered: GetColumnsToBeRendered = (
  tableColumns: TableColumnsType,
  depth: number = 1
) =>
  compact(
    tableColumns.map((col) => {
      if (col.children) {
        return {
          ...col,
          depth,
          totalChildCount: getTotalChildCount(col.children),
          children: getColumnsToBeRendered(col.children, depth + 1),
        };
      }

      if (col.csvData) return { ...col, depth };

      return null;
    })
  );

// get Cells range to be merged
const getMerges: GetMerges = (
  columnHeads: string[][],
  maxDepth: number,
  groupHeads: GroupHeads,
  notGroupHeads: NotGroupHeads
) => {
  const merges: XLSX.Range[] = [];

  if (maxDepth === 1) return merges;

  const normalizedGroupHeads: Dictionary<GroupHead> = keyBy(
    groupHeads,
    'label'
  );
  const normalizedNotGroupHeads: Dictionary<NotGroupHead> = keyBy(
    notGroupHeads,
    'label'
  );

  for (let verticalIndex = 0; verticalIndex < maxDepth; verticalIndex += 1) {
    const row = columnHeads[verticalIndex];
    // s = start, e = end, c = column, r = row (see typedef for XLSX.Range)
    // { s: { c: 1, r: 0 }, e: { c: 3, r: 0 } },
    row.map((label, index) => {
      if (
        normalizedGroupHeads[label] &&
        normalizedGroupHeads[label].numOfCellMergesToLeft > 0
      ) {
        merges.push({
          s: { c: index, r: verticalIndex },
          e: {
            c: index + (normalizedGroupHeads[label].numOfCellMergesToLeft - 1),
            r: verticalIndex,
          },
        });
      }

      if (
        normalizedNotGroupHeads[label] &&
        normalizedNotGroupHeads[label].numOfCellMergesToDown > 0
      ) {
        merges.push({
          s: { c: index, r: verticalIndex },
          e: {
            c: index,
            r:
              verticalIndex +
              normalizedNotGroupHeads[label].numOfCellMergesToDown,
          },
        });
      }
      return undefined;
    });
  }
  return merges;
};

// Generate Table Heads
export const getTableHeads: GetTableHeads = (
  tableColumns: TableColumnsType
) => {
  const validColumns = getColumnsToBeRendered(tableColumns, 1);
  let maxDepth: number = 0;

  // Get the number of table head layers
  const getMaxDepth: GetMaxDepth = (cols) => {
    cols.map((item) => {
      if (maxDepth < item.depth) {
        maxDepth = item.depth;
      }

      if (item.children) {
        getMaxDepth(item.children);
      }
      return undefined;
    });
  };

  getMaxDepth(validColumns);

  const columnHeads: string[][] = Array.from({ length: maxDepth }, () => []);
  const dataPropertiesToBeRendered: Record<string, any>[] = [];
  const groupHeads: GroupHeads = [];
  const notGroupHeads: NotGroupHeads = [];

  // Fills the matrix of table heads.
  // If cell is a group head, next cells will be empty strings depending on number of cells in the tree of columns
  // If cell is a not a group head
  const fillColumnHeads: FillColumnHeads = (cols) => {
    cols.map((item) => {
      if (item.csvData) {
        columnHeads[item.depth - 1].push(item.csvData.label);
        dataPropertiesToBeRendered.push(item.csvData);
        notGroupHeads.push({
          label: item.csvData.label,
          numOfCellMergesToDown: maxDepth - item.depth,
        });

        let verticalCounter: number = item.depth;
        while (maxDepth > verticalCounter) {
          columnHeads[verticalCounter].push('');
          verticalCounter += 1;
        }
      }

      if (item.children) {
        columnHeads[item.depth - 1].push(item.title);
        groupHeads.push({
          label: item.title,
          numOfCellMergesToLeft: item.totalChildCount,
        });

        let horizontalCounter: number = 0;
        while (item.totalChildCount - 1 > horizontalCounter) {
          columnHeads[item.depth - 1].push('');
          horizontalCounter += 1;
        }
        fillColumnHeads(item.children);
      }
      return undefined;
    });
  };

  fillColumnHeads(validColumns);
  const merges = getMerges(columnHeads, maxDepth, groupHeads, notGroupHeads);

  return { columnHeads, dataPropertiesToBeRendered, merges };
};

export const getDataMatrix: GetDataMatrix = (
  dataList,
  dataPropertiesToBeRendered
) => {
  const dataMatrix = dataList.map((data) => {
    const row = dataPropertiesToBeRendered.map(
      ({ renderCell }) => renderCell(data.node)?.toString() as string
    );

    return row;
  });
  return dataMatrix;
};
