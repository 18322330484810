import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import messages from '../messages';
import { StyledRootDiv } from './styles';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  width: 300px;
`;

const StyledParentDiv = styled.div<any>`
  width: ${(props) => `${300 * props.options.second.length}px`};
  padding-bottom: 100px;
  overflow: hidden;
`;

const AffiliatesTree = () => {
  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const [checked, setChecked] = useState({
    all: {
      first: false,
      AFFILIATES_AFFILIATE_PROGRAMME: false,
      AFFILIATES_AFFILIATE_CRITERIA: false,
      AFFILIATES_AFFILIATE_REQUEST: false,
      AFFILIATES_AFFILIATE_PROGRAMME_LIST: false,
      AFFILIATES_AFFILIATE_CRITERIA_LIST: false,
      AFFILIATES_AFFILIATE_REQUEST_LIST: false,
    },
    indeterminate: {
      first: false,
      AFFILIATES_AFFILIATE_PROGRAMME: false,
      AFFILIATES_AFFILIATE_CRITERIA: false,
      AFFILIATES_AFFILIATE_REQUEST: false,
    },
    currentList: {
      AFFILIATES_AFFILIATE_PROGRAMME_GRP: [],
      AFFILIATES_AFFILIATE_CRITERIA_GRP: [],
      AFFILIATES_AFFILIATE_REQUEST_GRP: [],
    },
  });

  const defaultValues = Object.values(ALL_PERMISSIONS.ALL_AFFILIATES);

  const levels = [
    ['first', 0],
    ['second', 1],
    ['third', 2],
  ];

  const options: Record<string, any> = levels.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr[0]}`]: Object.entries(ALL_PERMISSIONS.ALL_AFFILIATES)
        .filter((e) => (e[1].match(/:/g) || []).length === curr[1])
        .map((e) => e[0]),
    }),
    {}
  );

  const handleFirstLevelChange = (e: Record<string, any>) => {
    const list = e.target.checked ? defaultValues : [];
    setFieldValue('permissions[ALL_AFFILIATES]', list);
  };

  const handleSecondLevelChange = (e: Record<string, any>) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_AFFILIATES];
    const secondLevelValues = defaultValues.filter(
      (v) =>
        (v.match(/:/g) || []).length >= 1 &&
        v.includes(ALL_PERMISSIONS.ALL_AFFILIATES[name])
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, ...secondLevelValues];
    } else {
      list = targetPermissions.filter((p) => !secondLevelValues.includes(p));
    }

    setFieldValue(
      'permissions[ALL_AFFILIATES]',
      uniq(list.length > 1 ? ['AFFILIATES', ...list] : [])
    );
  };

  const handleThirdLevelChange = (e: Record<string, any>) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_AFFILIATES];

    const unlistedName =
      ALL_PERMISSIONS.ALL_AFFILIATES[name.replace('_LIST', '')];

    const thirdLevelValues = defaultValues.filter(
      (v) => (v.match(/:/g) || []).length >= 2 && v.includes(unlistedName)
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, unlistedName, ...thirdLevelValues];
    } else {
      list = targetPermissions.filter(
        (p) => !thirdLevelValues.includes(p) && p !== unlistedName
      );
    }

    setFieldValue(
      'permissions[ALL_AFFILIATES]',
      uniq(list.length >= 3 ? ['AFFILIATES', ...list] : [])
    );
  };

  const handleOnChange = (list: any[], name: string) => {
    const stateName = name.replace('_GRP', '');

    const secondLevelPermissionLookup =
      ALL_PERMISSIONS.ALL_AFFILIATES[stateName];
    const thirdLevelPermissionLookup =
      ALL_PERMISSIONS.ALL_AFFILIATES[`${stateName}_LIST`];

    const thirdLevelPermissions = Object.values(
      ALL_PERMISSIONS.ALL_AFFILIATES
    ).filter((p) => (p.match(/:/g) || []).length === 2);

    const targetPermissions = [...values.permissions.ALL_AFFILIATES];

    const templatePermissions = thirdLevelPermissions.filter(
      (p) =>
        p.includes(secondLevelPermissionLookup) &&
        p !== secondLevelPermissionLookup &&
        p !== thirdLevelPermissionLookup
    );

    setFieldValue(
      'permissions[ALL_AFFILIATES]',
      uniq([
        ...targetPermissions.filter((p) => !templatePermissions.includes(p)),
        'AFFILIATES',
        secondLevelPermissionLookup,
        thirdLevelPermissionLookup,
        ...list,
      ])
    );
  };

  const availablePermissions = coercedGet(
    values,
    'permissions.ALL_AFFILIATES',
    []
  );

  useEffect(() => {
    const permissionMatch =
      availablePermissions.length ===
      Object.values(ALL_PERMISSIONS.ALL_AFFILIATES).length;

    const thirdLevelPermissions = availablePermissions.filter(
      (p: any) => (p.match(/:/g) || []).length === 2
    );

    const secondLevelKeys = [
      ['AFFILIATES_AFFILIATE_PROGRAMME', 'AFFILIATES:AFFILIATE_PROGRAMME', 6],
      ['AFFILIATES_AFFILIATE_CRITERIA', 'AFFILIATES:AFFILIATE_CRITERIA', 2],
      ['AFFILIATES_AFFILIATE_REQUEST', 'AFFILIATES:AFFILIATE_REQUEST', 5],
    ];

    const thirdLevelChecks: Record<string, any> = secondLevelKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr[0]}`]:
          thirdLevelPermissions.filter((p: any) => p.includes(`${curr[1]}`))
            .length === curr[2],
      }),
      {}
    );

    const allChecks = {
      AFFILIATES_AFFILIATE_PROGRAMME:
        permissionMatch || thirdLevelChecks.AFFILIATES_AFFILIATE_PROGRAMME,
      AFFILIATES_AFFILIATE_CRITERIA:
        permissionMatch || thirdLevelChecks.AFFILIATES_AFFILIATE_CRITERIA,
      AFFILIATES_AFFILIATE_REQUEST:
        permissionMatch || thirdLevelChecks.AFFILIATES_AFFILIATE_REQUEST,
    };

    setChecked({
      all: {
        first: permissionMatch,
        AFFILIATES_AFFILIATE_PROGRAMME:
          allChecks.AFFILIATES_AFFILIATE_PROGRAMME,
        AFFILIATES_AFFILIATE_CRITERIA: allChecks.AFFILIATES_AFFILIATE_CRITERIA,
        AFFILIATES_AFFILIATE_REQUEST: allChecks.AFFILIATES_AFFILIATE_REQUEST,
        AFFILIATES_AFFILIATE_PROGRAMME_LIST:
          thirdLevelPermissions.includes(
            'AFFILIATES:AFFILIATE_PROGRAMME:LIST'
          ) || allChecks.AFFILIATES_AFFILIATE_PROGRAMME,
        AFFILIATES_AFFILIATE_CRITERIA_LIST:
          thirdLevelPermissions.includes(
            'AFFILIATES:AFFILIATE_CRITERIA:LIST'
          ) || allChecks.AFFILIATES_AFFILIATE_CRITERIA,
        AFFILIATES_AFFILIATE_REQUEST_LIST:
          thirdLevelPermissions.includes('AFFILIATES:AFFILIATE_REQUEST:LIST') ||
          allChecks.AFFILIATES_AFFILIATE_REQUEST,
      },
      indeterminate: {
        first: availablePermissions.length && !permissionMatch,

        AFFILIATES_AFFILIATE_PROGRAMME:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('AFFILIATES:AFFILIATE_PROGRAMME')
          ).length && !thirdLevelChecks.AFFILIATES_AFFILIATE_PROGRAMME,

        AFFILIATES_AFFILIATE_CRITERIA:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('AFFILIATES:AFFILIATE_CRITERIA')
          ).length && !thirdLevelChecks.AFFILIATES_AFFILIATE_CRITERIA,

        AFFILIATES_AFFILIATE_REQUEST:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('AFFILIATES:AFFILIATE_REQUEST')
          ).length && !thirdLevelChecks.AFFILIATES_AFFILIATE_REQUEST,
      },
      currentList: {
        AFFILIATES_AFFILIATE_PROGRAMME_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('AFFILIATES:AFFILIATE_PROGRAMME') &&
            p !== 'AFFILIATES:AFFILIATE_PROGRAMME' &&
            p !== 'AFFILIATES:AFFILIATE_PROGRAMME:LIST'
        ),
        AFFILIATES_AFFILIATE_CRITERIA_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('AFFILIATES:AFFILIATE_CRITERIA') &&
            p !== 'AFFILIATES:AFFILIATE_CRITERIA' &&
            p !== 'AFFILIATES:AFFILIATE_CRITERIA:LIST'
        ),
        AFFILIATES_AFFILIATE_REQUEST_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('AFFILIATES:AFFILIATE_REQUEST') &&
            p !== 'AFFILIATES:AFFILIATE_REQUEST' &&
            p !== 'AFFILIATES:AFFILIATE_REQUEST:LIST'
        ),
      },
    });
  }, [availablePermissions]);
  const translate = useTranslate();

  return (
    <StyledRootDiv style={{ overflow: 'hidden' }}>
      <StyledParentDiv options={options}>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={checked.all.first}
              indeterminate={checked.indeterminate.first}
              onChange={handleFirstLevelChange}
            >
              <StyledText size={16}>
                {translate(messages[ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES])}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <div className="w-100">
          <div className="mt-4 d-flex ml-3">
            {options.second.map((s: any) => (
              <StyledDiv>
                <Checkbox
                  key={s}
                  name={s}
                  checked={checked.all[s]}
                  indeterminate={checked.indeterminate[s]}
                  onChange={handleSecondLevelChange}
                >
                  <StyledText size={14}>{translate(messages[s])}</StyledText>
                </Checkbox>
                <div className="ml-4 mt-2">
                  <Checkbox
                    key={`${s}_LIST`}
                    name={`${s}_LIST`}
                    checked={checked.all[`${s}_LIST`]}
                    onChange={handleThirdLevelChange}
                  >
                    <StyledText size={14}>
                      {translate(messages.LIST)}
                    </StyledText>
                  </Checkbox>
                  <Checkbox.Group
                    className="w-100 ml-2"
                    onChange={(v) => handleOnChange(v, `${s}_GRP`)}
                    value={checked.currentList[`${s}_GRP`]}
                  >
                    <div className="ml-4">
                      {options.third
                        .filter(
                          (t: any[], idx: number) =>
                            t.includes(s) && idx !== 0 && idx !== 6 && idx !== 8
                        )
                        .map((t: any) => (
                          <StyledDiv className="mt-2">
                            <Checkbox
                              key={ALL_PERMISSIONS.ALL_AFFILIATES[t]}
                              value={ALL_PERMISSIONS.ALL_AFFILIATES[t]}
                            >
                              <StyledText size={14}>
                                {translate(messages[t.replace(`${s}_`, '')])}
                              </StyledText>
                            </Checkbox>
                          </StyledDiv>
                        ))}
                    </div>
                  </Checkbox.Group>
                </div>
              </StyledDiv>
            ))}
          </div>
        </div>
      </StyledParentDiv>
    </StyledRootDiv>
  );
};

export default AffiliatesTree;
