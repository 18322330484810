import React from 'react';
import gql from 'graphql-tag';
import coercedGet from 'utils/coercedGet';
import { Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';

export const PROCESSOR_TYPE = gql`
  query PROCESSOR_TYPE($id: ID!) {
    balanceTransactionRecord(id: $id) {
      id
      type
      processor {
        id
        username
        role
      }
    }
  }
`;

const ProcessorType = React.memo(({ id }: { id: string }) => {
  const translate = useTranslate();
  const { data, loading, error } = useQuery(PROCESSOR_TYPE, {
    variables: { id },
    context: { shouldBatch: true },
  });

  const typePath = 'balanceTransactionRecord.type';
  const btrType = coercedGet(data, typePath, null);
  const processorRole = coercedGet(
    data,
    'balanceTransactionRecord.processor.role',
    null
  );

  const systemProcessorTypes = {
    MEMBER_LOYALTY_PAYOUT: 'MEMBER_LOYALTY_PAYOUT',
    REBATE_PAYOUT: 'REBATE_PAYOUT',
    PROMO_PAYOUT: 'PROMO_PAYOUT',
    AFFILIATE_COMMISSION: 'AFFILIATE_COMMISSION',
  };

  if (loading)
    return (
      <div className="styled-center">
        <Spin />
      </div>
    );

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  const getRole = () => {
    if (processorRole === 'MEMBER') return translate(messages.MEMBER);

    return coercedGet(data, 'balanceTransactionRecord.processor.username', '-');
  };

  return (
    <span>
      {systemProcessorTypes[btrType] ? translate(messages.SYSTEM) : getRole()}
    </span>
  );
});

export default ProcessorType;
