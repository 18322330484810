import React, { useEffect } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Table, Dropdown, Menu, Badge } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import messages from 'messages';
import statusBadge from 'utils/statusBadge';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import { showPermissionError } from 'components/Navbar/Navbar';

import { withErrorHandler } from 'components/ErrorHandler';
import { StyledTableContainer } from 'constants/styles';
import useTranslate from 'utils/useTranslate';
import { columnAlign } from 'utils/tableAlignment';
import { useAffiliateState, AffiliateTypes } from '../../context';
import DeleteAffiliateProgramme from './components/DeleteAffiliateProgramme';
import DuplicateAffiliateProgramme from './components/DuplicateAffiliateProgramme/DuplicateAffiliateProgramme';
import ActivateAffiliateProgramme from './components/ActivateAffiliateProgramme';
import DeactivateAffiliateProgramme from './components/DeactivateAffiliateProgramme';
import { AffiliateProgrammeTypes } from './utils';

const { Column, ColumnGroup } = Table;

type Props = {
  data: Array<{}>;
  error: boolean;
  loading: boolean;
  refetch: () => void;
  refetchVariables: object;
  showForm: (value: any) => void;
};

function AgentAffiliateTable(props: Props) {
  const translate = useTranslate();
  const { role, permissions } = usePermissions();

  const {
    ALLOWED_EDIT,
    ALLOWED_DELETE,
    ALLOWED_DUPLICATE,
    ALLOWED_TOGGLE_ACTIVATION,
  } = collectPermissions(
    role,
    permissions,
    ['EDIT', 'DELETE', 'DUPLICATE', 'TOGGLE_ACTIVATION'],
    ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES_AFFILIATE_PROGRAMME
  );

  const { data, error, loading, refetch, refetchVariables, showForm } = props;
  const intl = useIntl();
  const [, dispatch] = useAffiliateState() as any;

  const getColumns = React.useCallback(
    () => [
      {
        title: 'ID',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: intl.formatMessage(messages['agent.programme-name.text']),
        dataIndex: 'name',
        align: columnAlign.onCenter,
        key: 'name',
        render: (name: string, record: any) => (
          <a
            data-testid="name"
            onClick={() => {
              if (record.status !== AffiliateProgrammeTypes.DRAFT) {
                dispatch({
                  type: AffiliateTypes.SET_ACTIVE_SCREEN,
                  payload: AffiliateTypes.AGENT_AFFILIATE_DETAILS,
                });
                dispatch({
                  type: AffiliateTypes.SET_SUMMARY,
                  payload: record.id,
                });
                return;
              }
              if (!ALLOWED_EDIT) {
                showPermissionError('Affiliate Programme - Edit');
                return;
              }
              showForm(record);
            }}
            href="#section"
          >
            {name}
          </a>
        ),
      },
      {
        title: translate(messages.TO_DO),
        align: columnAlign.onCenter,
        dataIndex: 'todo',
        key: 'todo',
        render: (_: any, record: any) =>
          record.status === AffiliateProgrammeTypes.DRAFT &&
          ALLOWED_EDIT && (
            <Button
              onClick={() => {
                showForm(record);
              }}
            >
              <FormattedMessage id="continue.text" defaultMessage="Continue" />
            </Button>
          ),
      },
      {
        title: translate(messages.STATUS),
        align: columnAlign.onCenter,
        dataIndex: 'status',
        key: 'status',
        render: (key: string) => (
          <span data-testid="status">
            <Badge
              status={statusBadge(key)}
              text={translate(messages[`${key.toLowerCase()}.text`])}
            />
          </span>
        ),
      },
      {
        title: translate(messages.TIERS),
        align: columnAlign.onCenter,
        dataIndex: 'levels',
        key: 'levels',
        render: (_: any, record: any) => record.levels.length || '-',
      },
      {
        title: translate(messages.DEFAULT_PROGRAMME),
        align: columnAlign.onCenter,
        dataIndex: 'default',
        key: 'default',
        render: (e: any) => (
          <span>{translate(messages[`${e ? 'YES' : 'NO'}`])}</span>
        ),
      },
      {
        title: translate(messages.NEGATIVE_CARRY),
        align: columnAlign.onCenter,
        dataIndex: 'negativeCarryOverEnabled',
        key: 'negativeCarryOverEnabled',
        render: (e: any) => (
          <span>{translate(messages[`${e ? 'YES' : 'NO'}`])}</span>
        ),
      },
      {
        title: translate(messages.MEMBERS),
        align: columnAlign.onCenter,
        dataIndex: 'membersCount',
        key: 'membersCount',
        render: (e: any) => e || 0,
      },
      {
        title: translate(messages['actions.text']),
        align: columnAlign.onCenter,
        key: 'actions',
        render: (_: any, record: any) =>
          (ALLOWED_DELETE ||
            ALLOWED_DUPLICATE ||
            ALLOWED_EDIT ||
            ALLOWED_TOGGLE_ACTIVATION) && (
            <Dropdown
              placement="bottomRight"
              overlay={
                <Menu>
                  {ALLOWED_EDIT && (
                    <StyledMenuItem
                      disabled={
                        record.status === AffiliateProgrammeTypes.ACTIVE
                      }
                      onClick={() => {
                        showForm(record);
                      }}
                    >
                      <FormattedMessage id="edit.text" defaultMessage="Edit" />
                    </StyledMenuItem>
                  )}
                  {ALLOWED_DUPLICATE && (
                    <StyledMenuItem>
                      <DuplicateAffiliateProgramme
                        affiliate={record}
                        refetch={refetch}
                      />
                    </StyledMenuItem>
                  )}
                  {ALLOWED_DELETE && (
                    <StyledMenuItem
                      disabled={
                        record.status === AffiliateProgrammeTypes.ACTIVE
                      }
                    >
                      <DeleteAffiliateProgramme
                        disabled={
                          record.status === AffiliateProgrammeTypes.ACTIVE
                        }
                        affiliate={record}
                        refetch={refetch}
                      />
                    </StyledMenuItem>
                  )}
                  {ALLOWED_TOGGLE_ACTIVATION &&
                    ![
                      AffiliateProgrammeTypes.ACTIVE,
                      AffiliateProgrammeTypes.DRAFT,
                    ].includes(record.status) && (
                      <StyledMenuItem
                        disabled={[
                          AffiliateProgrammeTypes.ACTIVE,
                          AffiliateProgrammeTypes.DRAFT,
                        ].includes(record.status)}
                      >
                        <ActivateAffiliateProgramme affiliate={record} />
                      </StyledMenuItem>
                    )}
                  {ALLOWED_TOGGLE_ACTIVATION &&
                    ![
                      AffiliateProgrammeTypes.INACTIVE,
                      AffiliateProgrammeTypes.DRAFT,
                    ].includes(record.status) && (
                      <StyledMenuItem
                        disabled={[
                          AffiliateProgrammeTypes.INACTIVE,
                          AffiliateProgrammeTypes.DRAFT,
                        ].includes(record.status)}
                      >
                        <DeactivateAffiliateProgramme affiliate={record} />
                      </StyledMenuItem>
                    )}
                </Menu>
              }
            >
              <Button type="link" icon={<EllipsisOutlined />} />
            </Dropdown>
          ),
      },
    ],
    // eslint-disable-next-line
    [dispatch, intl, refetch, showForm]
  );

  useEffect(() => {
    dispatch({
      type: AffiliateTypes.SET_REFETCH_VARIABLES,
      payload: refetchVariables,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <StyledTableContainer
      data-testid={!loading && !error ? 'container' : 'error'}
    >
      <span data-testid={loading ? 'loading' : ''} />
      <span data-testid={error ? 'error' : ''} />

      <Table
        rowKey="key"
        size="middle"
        dataSource={data}
        pagination={false}
        loading={loading}
        data-testid={!error ? 'table' : 'error'}
      >
        {getColumns().map((column) => {
          const { subColumns = [], title, key, render, align } = column as any;
          return subColumns.length > 0 ? (
            <ColumnGroup title={title}>
              {subColumns.map(
                ({
                  title: subTitle,
                  align: subAlign,
                  dataIndex: subDataIndex,
                  render: subRender,
                }: any) => (
                  <Column
                    title={subTitle}
                    align={subAlign}
                    dataIndex={subDataIndex}
                    key={subDataIndex}
                    render={subRender}
                  />
                )
              )}
            </ColumnGroup>
          ) : (
            <Column
              title={title}
              dataIndex={key}
              key={key}
              render={render}
              align={align}
            />
          );
        })}
      </Table>
    </StyledTableContainer>
  );
}

export default withErrorHandler(AgentAffiliateTable);

const StyledMenuItem = styled(Menu.Item)`
  &.ant-dropdown-menu-item-disabled,
  &.ant-dropdown-menu-submenu-title-disabled {
    a {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;
