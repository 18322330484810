import React from 'react';
import RemarksBubble from 'components/RemarksBubble';
import coercedGet from 'utils/coercedGet';
import MemberNotes from '../MemberNotes/MemberNotes';
import MemberTagsBubble from '../MemberTagsBubble/MemberTagsBubble';

const MemberLabelRemarks = ({
  withdrawal,
}: {
  withdrawal: Record<string, any>;
}) => {
  const mainRemark = withdrawal.externalRemarks || withdrawal.remarks;
  const memberId = coercedGet(withdrawal, 'id');

  return (
    <div className="d-flex">
      <div style={{ width: 31.81 }}>
        <MemberTagsBubble memberId={memberId} />
      </div>
      <div style={{ width: 18.52 }}>
        <MemberNotes memberId={memberId} toolTipId="" />
      </div>
      {mainRemark && (
        <RemarksBubble
          remarks={mainRemark}
          date={withdrawal.dateTimeProcessed || withdrawal.dateTimeCreated}
          toolTipId=""
        />
      )}
    </div>
  );
};

export default MemberLabelRemarks;
