import { defineMessages } from 'react-intl';

const messages = defineMessages({
  complianceCheck: {
    id: 'compliance-check.text',
    defaultMessage: 'Compliance check',
  },
  cancel: {
    id: 'cancel.text',
    defaultMessage: 'Cancel',
  },
  save: {
    id: 'save.text',
    defaultMessage: 'Save',
  },
  automaticComplianceCheck: {
    id: 'automatic-compliance-checks.text',
    defaultMessage: 'Automatic compliance check',
  },
  status: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  enabled: {
    id: 'enabled.text',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'disabled.text',
    defaultMessage: 'Disabled',
  },
  bonusHunter: {
    id: 'bonus-hunter.text',
    defaultMessage: 'Bonus hunter',
  },
  enable: {
    id: 'enable.text',
    defaultMessage: 'Enable',
  },
  activationThreshold: {
    id: 'activation-threshold.text',
    defaultMessage: 'Activation threshold',
  },
  yes: {
    id: 'yes.text',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'no.text',
    defaultMessage: 'No',
  },
  ipCollisionOffender: {
    id: 'ip-collision-offender.text',
    defaultMessage: 'IP collision offender',
  },
  contraBetOffender: {
    id: 'contra-bet-offender.text',
    defaultMessage: 'Contra bet offender',
  },
  martingaleBetOffender: {
    id: 'martingale-bet-offender.text',
    defaultMessage: 'Martingale bet offender',
  },
  turnoverRequirement: {
    id: 'turnover-requirement.text',
    defaultMessage: 'Turnover requirement',
  },
  successfullySavedChanges: {
    id: 'successfully-saved-changes.text',
    defaultMessage: 'Successfully saved changes',
  },
});

export default messages;
