import esget from 'utils/esGet';
import messages from 'messages';
import moment from 'moment';
import { Member } from 'types/graphqlTypes';
import { statusTransform } from 'utils/memberStatusTransform';
import { DATE_TIME_FORMAT } from 'constants/date';
import setCurrency from '../utils/setCurrency';

type Translator = (
  { id, defaultMessage }: { id: string; defaultMessage: string },
  options?: any
) => string;

const getCsvHeaders = (translate: Translator) => [
  {
    label: 'Watchlist',
    key: 'starred',
    renderCell: (member: Member) => esget(member?.starred, ''),
  },
  {
    label: 'Username',
    key: 'username',
    renderCell: (member: Member) => esget(member?.username, '-'),
  },
  {
    label: 'Firstname',
    key: 'firstname',
    renderCell: (member: Member) => esget(member?.firstName, '-'),
  },
  {
    label: 'Lastname',
    key: 'lastname',
    renderCell: (member: Member) => esget(member?.lastName, '-'),
  },
  {
    label: 'Real Name',
    key: 'realName',
    renderCell: (member: Member) => esget(member?.realName, '-'),
  },
  {
    label: 'Affiliate',
    key: 'affiliate',
    renderCell: (member: Member) => esget(member?.affiliate?.code, '-'),
  },
  {
    label: translate(messages.VIP_TIER),
    key: 'memberLoyaltyLevels',
    renderCell: (member: Member) => {
      const memberLevels = esget(member?.memberLoyaltyLevels, '-');
      return (
        (memberLevels && memberLevels.map((level: any) => level.name)) || '-'
      );
    },
  },
  {
    label: 'Member Marker',
    key: 'memberLevel',
    renderCell: (member: Member) => esget(member?.memberLevel?.name, '-'),
  },
  {
    label: 'Labels',
    key: 'labels',
    renderCell: (member: Member) => {
      const tags = esget(member?.tags, []);
      if (tags.length === 0) {
        return '-';
      }
      return tags.map((tag) => tag!.name);
    },
  },
  {
    label: 'Verification Status',
    key: 'verified',
    renderCell: (member: Member) =>
      member?.verified ? 'Verified' : 'Not Verified',
  },
  {
    label: 'Country',
    key: 'country',
    renderCell: (member: Member) => member?.address?.country || '-',
  },
  {
    label: 'Country Code',
    key: 'countryCode',
    renderCell: (member: Member) => member?.countryCode || '-',
  },
  {
    label: 'Post Code',
    key: 'postCode',
    renderCell: (member: Member) => member?.address?.postCode || '-',
  },
  {
    label: 'Account balance',
    key: 'totalBalance',
    renderCell: (member: Member) =>
      setCurrency('', member.totalBalance, 2) || '-',
  },
  {
    label: 'Total Effective Bet',
    key: 'totalEffectiveBet',
    renderCell: (member: Member) =>
      setCurrency('', member.totalEffectiveBet, 2) || '-',
  },
  {
    label: 'Status',
    key: 'memberLevel.status',
    renderCell: (member: Member) => esget(statusTransform[member?.status], '-'),
  },
  {
    label: 'Last login',
    key: 'lastLoginDateTime',
    renderCell: (member: Member) =>
      moment(member.lastLoginDateTime).format(DATE_TIME_FORMAT) || '-',
  },
];

export default getCsvHeaders;
