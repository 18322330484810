import uuid from 'uuid';
import FiltersProvider from 'contexts/Filters';
import ReportsTimezoneProvider from 'contexts/ReportsTimezone';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import { DAILY_OPERATING_REPORT } from 'graphql/queries/dailyOperationReport.query';
import DailyOperatingReportMain from 'pages/components/Reports/DailyOperatingReport/components/DailyOperatingReportMain';
import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';

const DailyOperatingReport = () => {
  const { getTab } = useScreenTabV2();
  const { filter = {} } = getTab('daily-operating-report') || {};
  const [key, setKey] = useState('');

  const refreshPage = () => setKey(uuid.v1());

  const defaultFilters = {
    date: null,
    product: null,
  };

  const initialFilters = { ...defaultFilters, ...filter };
  return (
    <FiltersProvider
      initialValues={initialFilters}
      defaultValues={defaultFilters}
      tabId="daily-operating-report"
    >
      <SpreadsheetProvider
        options={{
          query: DAILY_OPERATING_REPORT,
          edgesPath: 'dailyOperationReport.edges',
        }}
      >
        <ReportsTimezoneProvider>
          <DailyOperatingReportMain key={key} refreshPage={refreshPage} />
        </ReportsTimezoneProvider>
      </SpreadsheetProvider>
    </FiltersProvider>
  );
};

export default DailyOperatingReport;
