import React from 'react';
import { AreaChart, Area } from 'recharts';

const dataMockup = [
  {
    name: 'Page A',
    value: 1500,
  },
  {
    name: 'Page B',
    value: 2000,
  },
  {
    name: 'Page C',
    value: 4000,
  },
  {
    name: 'Page D',
    value: 2780,
  },
  {
    name: 'Page E',
    value: 1890,
  },
  {
    name: 'Page F',
    value: 2390,
  },
  {
    name: 'Page G',
    value: 300,
  },
];

const AreaComponent = ({
  chart,
}: {
  chart: {
    dataPoints: Array<any>;
  };
}) => {
  let data = chart?.dataPoints;

  if (!data) {
    data = dataMockup;
  }

  return (
    <AreaChart
      width={320}
      height={60}
      data={data}
      margin={{
        top: 5,
        right: 0,
        left: 0,
        bottom: 5,
      }}
    >
      <Area
        type="monotone"
        dataKey="value"
        stroke="#1890FF"
        fill="#1890FF"
        fillOpacity={0.2}
      />
    </AreaChart>
  );
};

export default AreaComponent;
