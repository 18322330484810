import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Select } from 'antd';

import { StyledSelect } from '../../styles';
import { FilterStateType, SetFilterStateType } from '../../../../types';

const { Option } = Select;

type Props = {
  state: FilterStateType;
  setState: SetFilterStateType;
};
const Device: React.FC<Props> = ({ state, setState }) => {
  const options = [
    {
      label: 'WEB',
      value: 'WEB',
    },
  ];

  const handleChange = (e: Array<string>) => {
    if (e.length) {
      setState((prev: FilterStateType) => ({
        ...prev,
        device: { in: e },
      }));
    } else {
      setState((prev: FilterStateType) => ({
        ...prev,
        device: null,
      }));
    }
  };

  return (
    <>
      <div className="mb-1">
        <FormattedMessage id="reports.device.text" defaultMessage="Device" />
      </div>
      <StyledSelect
        data-testid="select"
        allowClear
        placeholder={
          <FormattedMessage id="reports.select.text" defaultMessage="Select" />
        }
        mode="multiple"
        style={{ width: '100%' }}
        // eslint-disable-next-line react/prop-types
        value={state.device ? (state.device.in as string[]) : []}
        optionFilterProp="label"
        onChange={handleChange as any}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value} label={option.label}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};

export default Device;
