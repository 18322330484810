/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { values } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Spin } from 'antd';
import { StyledTable, StyledCenter } from './styles';
import { VENDOR_SETTING } from './queries';

const SpecificVendorTable = (props: Record<string, any>) => {
  const { nodeData } = props;
  const { vendor, admin, context } = nodeData;

  const { loading, data = {} } = useQuery(VENDOR_SETTING, {
    variables: {
      vendor,
      admin,
      context,
    },
    fetchPolicy: 'network-only',
  }) as any;

  if (loading)
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  const gameTypes = ['SLOT', 'LIVE', 'POKER', 'FISH', 'SPORTSBOOK', 'LOTTERY'];

  const { vendorSettings = {} } = data;
  const { gameTypeSettings } = vendorSettings;
  const edges = values(gameTypeSettings);

  const tableData = edges
    .map((node, index) => {
      if (node === null) return node;

      if (node === 'VendorSettingsGameTypeSettings') {
        return null;
      }
      return {
        ...node,
        gameType: gameTypes[index],
      };
    })
    .filter((node) => node !== null)
    .map((node) => ({
      gameType: node.gameType,
      activationStatus: node.disabled,
      visibilityStatus: node.hidden,
      actions: node,
    }));

  const columns = [
    {
      title: 'Game Type',
      dataIndex: 'gameType',
      key: 'gameType',
      render: (text: string) => <a>{text}</a>,
    },
    {
      title: 'Activation Status',
      dataIndex: 'activationStatus',
      key: 'activationStatus',
      render: (disabled: boolean) => (
        <span>{disabled ? 'Deactivated' : 'Activated'}</span>
      ),
    },
    {
      title: 'Visibility Status',
      dataIndex: 'visibilityStatus',
      key: 'visibilityStatus',
      render: (hidden: boolean) => <span>{hidden ? 'Hidden' : 'Visible'}</span>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (node: Record<string, any>) => {
        const { disabled, hidden } = node;

        return (
          <Dropdown
            overlay={
              <Menu>
                {!hidden && (
                  <Menu.Item onClick={(e) => e}>
                    <FormattedMessage
                      id="hide-vendor.text"
                      defaultMessage="Hide Game Type"
                    />
                  </Menu.Item>
                )}
                {hidden && (
                  <Menu.Item onClick={(e) => e}>
                    <FormattedMessage
                      id="unhide-vendor.text"
                      defaultMessage="Unhide Game Type"
                    />
                  </Menu.Item>
                )}
                {disabled && (
                  <Menu.Item disabled={false} onClick={(e) => e}>
                    <FormattedMessage
                      id="enable-vendor.text"
                      defaultMessage="Enable Game Type"
                    />
                  </Menu.Item>
                )}
                {!disabled && (
                  <Menu.Item disabled={false} onClick={(e) => e}>
                    <FormattedMessage
                      id="disable-vendor.text"
                      defaultMessage="Disable Game Type"
                    />
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
          >
            <Button type="link" icon={<EllipsisOutlined />} />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <div>
        <Button>Logout</Button>
      </div>
      <StyledTable
        columns={columns}
        size="middle"
        dataSource={tableData}
        pagination={false}
      />
    </>
  );
};

export default SpecificVendorTable;
