import React from 'react';
import styled from 'styled-components';
import { Search } from 'SuperAdminMain/components/VendorManagement/VendorMaintenance/VMBody/Sidebar/Search/Search';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { StatusFilter } from './StatusFilter/StatusFilter';
import { VendorFilter } from './VendorFilter/VendorFilter';
import { ClientAccountFilter } from './ClientAccountFilter/ClientAccountFilter';
import { VendorGroupNameFilter } from './VendorGroupNameFilter/VendorGroupNameFilter';

export const Filter = () => (
  <Container>
    <Search />

    <div className="filter">
      <StyledLabel>
        <FormattedMessage
          id="quick-filter.text"
          defaultMessage="Quick Filter"
        />
      </StyledLabel>

      <StyledSelect placeholder="CQ9 Maintenance">
        <Select.Option value={1} key={1}>
          Eternal Sunshine
        </Select.Option>
      </StyledSelect>
    </div>

    <FilterItem
      label={
        <FormattedMessage
          id="vgm.vendor-group-name.text"
          defaultMessage="Vendor Group Name"
        />
      }
    >
      <VendorGroupNameFilter />
    </FilterItem>

    <div className="filter">
      <div>
        <StyledLabel>
          <FormattedMessage id="status.text" defaultMessage="Status" />
        </StyledLabel>
      </div>

      <StatusFilter />
    </div>

    <FilterItem
      label={<FormattedMessage id="vgm.vendor.text" defaultMessage="Vendor" />}
    >
      <VendorFilter />
    </FilterItem>

    <FilterItem
      label={
        <FormattedMessage
          id="vgm.client-account.text"
          defaultMessage="Client Account"
        />
      }
    >
      <ClientAccountFilter />
    </FilterItem>
  </Container>
);

const Container = styled.div`
  width: 232px;
  height: 100%;
  padding: 0 16px;
  border-right: 1px solid #e8e8e8;

  .filter {
    margin: 8px 0;

    .label-clear {
      display: flex;
      justify-content: space-between;
    }

    .checkbox-circle {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
    }

    .circle-yellow {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin: 0 6px 0 10px;
      background: rgb(255, 175, 0);
    }

    .circle-green {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin: 0 6px 0 10px;
      background: rgb(0, 200, 0);
    }

    .circle-red {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin: 0 6px 0 10px;
      background: rgb(235, 0, 0);
    }
  }
`;

const StyledLabel = styled.span`
  font-size: 10px;
  color: #000000d9;
`;

const StyledSelect = styled(Select)<any>`
  width: 100%;

  .ant-select-selection {
    padding: 4px;
    padding-top: 0;
    border-radius: 4px;
  }

  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }

  .ant-select-selection li.ant-select-selection__choice {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
`;
