import FiltersProvider from 'contexts/Filters';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import { GET_MEMBER_ACCESS_SUMMARY_REPORT } from 'graphql/queries/memberAccessSummary.query';
import React from 'react';
import MemberAccessSummaryScreen from './components/MemberAccessSummaryScreen';

const initialFilters = {
  username: null,
  realName: null,
  totalCount: null,
};

const MemberAccessSummaryReport = () => <MemberAccessSummaryScreen />;

export default () => (
  <SpreadsheetProvider
    options={{
      query: GET_MEMBER_ACCESS_SUMMARY_REPORT,
      edgesPath: 'memberAccessSummaryReport.edges',
    }}
  >
    <FiltersProvider
      initialValues={initialFilters}
      tabId="member-access-summary-report"
    >
      <MemberAccessSummaryReport />
    </FiltersProvider>
  </SpreadsheetProvider>
);
