import styled from 'styled-components';
import { get } from 'lodash';
import { memberLevelColors } from 'utils/memberLevelColors';

export const findColorByBgColor = (bgColor: string) =>
  memberLevelColors.find(
    (memberLevelColor) => memberLevelColor.bgColor === bgColor
  );

export const StyledButton = styled.button`
  max-height: 23px;
  overflow: hidden;
  border-radius: 100px;
  outline: none;
  background-color: ${(props: { color: string }) =>
    get(findColorByBgColor(props.color), 'bgColor', '#efefef')};
  border: 1px solid
    ${(props: { color: string }) =>
      get(findColorByBgColor(props.color), 'borderColor', '#888888')};
  color: black;
  padding: 0 10px;
  cursor: default;
`;

export const StyledButtonContainer = styled.div`
  position: relative;
`;

export default {};
