import React from 'react';
import styled from 'styled-components';
import { Search } from 'SuperAdminMain/components/VendorManagement/VendorMaintenance/VMBody/Sidebar/Search/Search';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { messages } from 'SuperAdminMain/components/AccountManagementNew/utils';
import useTranslate from 'utils/useTranslate';
import { AccountFilter } from './components/AccountFilter';
import { CompanyFilter } from './components/CompanyFilter';
import { AdminCodeFilter } from './components/AdminCodeFilter';
import { VendorGroupFilter } from './components/VendorGroupFilter';
import { CategoryFilter } from './components/CategoryFilter';
import { StatusFilter } from './components/StatusFilter';
import { StartDateFilter } from './components/StartDateFilter';
import { EndDateFilter } from './components/EndDateFilter';

export const Filters: React.FC = () => {
  const translate = useTranslate();

  return (
    <Container>
      <Search />

      <div className="filter">
        <StyledLabel>
          <FormattedMessage
            id="quick-filter.text"
            defaultMessage="Quick Filter"
          />
        </StyledLabel>

        <StyledSelect placeholder={translate(messages['please-enter.text'])}>
          {/* <Select.Option value={1} key={1}>
            Eternal Sunshine
          </Select.Option> */}
        </StyledSelect>
      </div>

      <div className="filter">
        <AccountFilter />
      </div>

      <div className="filter">
        <CompanyFilter />
      </div>

      <div className="filter">
        <AdminCodeFilter />
      </div>

      <div className="filter">
        <VendorGroupFilter />
      </div>

      <div className="filter">
        <CategoryFilter />
      </div>

      <div className="filter">
        <StatusFilter />
      </div>

      <div className="filter">
        <StartDateFilter />
      </div>

      <div className="filter">
        <EndDateFilter />
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 232px;
  height: 100%;
  padding: 0 16px;
  border-right: 1px solid #e8e8e8;

  .filter {
    margin: 8px 0;

    .label-clear {
      display: flex;
      justify-content: space-between;
    }
  }
`;

const StyledLabel = styled.span`
  font-size: 10px;
  color: #000000d9;
`;

const StyledSelect = styled(Select)<any>`
  width: 100%;

  .ant-select-selection {
    padding: 4px;
    padding-top: 0;
    border-radius: 4px;
  }

  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }

  .ant-select-selection li.ant-select-selection__choice {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
`;
