import gql from 'graphql-tag';

export const DEPOSIT_PROVIDERS = gql`
  query depositProviders(
    $first: Int
    $after: Binary
    $filter: DepositProvidersFilterInput
  ) {
    depositProviders(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export default {};
