import React from 'react';
import { LayoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';

// import { FormattedMessage } from 'react-intl';
// import DownloadCsv from 'components/DownloadCsvGlobal';
// import { TabId } from 'SuperAdminMain/constants/route.js';
import ColumnsFilterNew from 'SuperAdminMain/shared/components/ColumnsFilterSA';
// import TimezoneSelect from './components/TimezoneSelect';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import RefreshTable from './components/RefreshTable';
// import { ReactComponent as DownloadIcon } from 'shared/assets/download-csv.svg';
import FilterConditions from '../FilterConditions';
import HorizontalRule from './components/HorizontalRule';

import { StyledTagContainer, StyledActionsContainer } from './styles';

type Props = {
  handleSidebar: () => void;
};

// eslint-disable-next-line react/prop-types
const TableHeader = React.forwardRef(({ handleSidebar }: Props, ref: any) => (
  <div className="d-flex my-3 px-3">
    <Button onClick={handleSidebar} size="small" className="mt-1">
      <LayoutOutlined />
    </Button>

    <StyledTagContainer>
      <FilterConditions />
    </StyledTagContainer>
    {/*
    <StyledActionsContainer>
      <TimezoneSelect />
    </StyledActionsContainer>

    <StyledActionsContainer>
      <RefreshTable onClick={() => ref.current.refetchTable()} />
    </StyledActionsContainer> */}
    <StyledActionsContainer>
      <HorizontalRule className="mx-2" />
    </StyledActionsContainer>
    {/* <StyledActionsContainer>
      <Icon
        className="mr-1"
        component={DownloadIcon}
        style={{ fontSize: '22px' }}
        // onClick={handleQuery}
      />
    </StyledActionsContainer> */}

    <StyledActionsContainer>
      <RefreshTable onClick={() => ref?.current!.refetchTable()} />
    </StyledActionsContainer>
    <StyledActionsContainer>
      <ColumnsFilterNew
        className="mr-3"
        tabId={TabId.ACCOUNTS_CONSOLIDATED_REPORT}
        iconOnly
      />
    </StyledActionsContainer>
    {/* <StyledActionsContainer>
      <DownloadCsv fileName={TabId.ACCOUNTS_CONSOLIDATED_REPORT} iconOnly />
    </StyledActionsContainer> */}
  </div>
));

export default TableHeader;
