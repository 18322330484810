import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { StyledSelect } from 'styles';
import { PERMISSION_GROUPS } from './queries';

type Props = {
  onChange: (e: any) => void;
  disabled?: boolean;
  isFilter?: boolean;
  label?: string | React.ReactNode;
  labelAsValue?: boolean;
  multiple?: boolean;
  placeholder?: any;
  value: string | object[];
};

const PermissionGroupsSelect = (props: Props) => {
  const {
    onChange,
    value,
    placeholder,
    disabled,
    label,
    multiple,
    isFilter,
    labelAsValue,
  } = props;
  const { Option } = Select;

  const [filter, setFilter] = useState({
    name: {
      contains: '',
    },
  });

  const { loading, data = {}, refetch } = useQuery(PERMISSION_GROUPS, {
    fetchPolicy: 'network-only',
    variables: {
      filter,
    },
  });

  const handleSearch = (e: string) => {
    setFilter({
      name: {
        contains: e.toUpperCase(),
      },
    });
    refetch();
  };
  const handleChange = (e: any) => {
    onChange(e);
  };

  const edges = coercedGet(data, 'permissionGroups.edges', []);

  return (
    <FilterItem label={label} onClear={() => onChange([])}>
      <StyledSelect
        getPopupContainer={(trigger: any) => trigger.parentNode}
        mode={multiple ? 'multiple' : undefined}
        value={(value as any) || (multiple ? [] : null)}
        showSearch
        onFocus={() => {
          if (refetch) {
            refetch();
          }
        }}
        placeholder={placeholder || ''}
        onChange={handleChange}
        filterOption={false}
        loading={loading}
        onSearch={debounce(handleSearch, 250)}
        disabled={disabled}
        isFilter={isFilter}
      >
        {!multiple && (
          <Option disabled value="">
            <span className="text-gray">
              <FormattedMessage
                id="select-a-permission-group.text"
                defaultMessage="Select a permission group"
              />
            </span>
          </Option>
        )}
        {edges.map(({ node }: any) => (
          <Option
            key={node.value}
            value={labelAsValue ? node.label : node.value}
          >
            {node.label}
          </Option>
        ))}
      </StyledSelect>
    </FilterItem>
  );
};

export default PermissionGroupsSelect;
