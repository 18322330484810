import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button } from 'antd';
import { Formik } from 'formik';
import MemberLevelPreviousNew from 'components/MemberLevelPreviousNew';
import { BulkProps } from 'interfaces/bulkProps.interface';
import MoreActionsMembersFilter from 'components/MoreActionsMembersFilter';
import { FormattedMessage } from 'react-intl';
import { StyledForm } from './styles';
import validationSchema from './validationSchema';

const BulkEditMemberLevelMenuForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: BulkProps) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({ handleSubmit, setFieldValue, values, errors, touched }) => (
      <StyledForm onSubmit={handleSubmit}>
        <div className="p-3">
          <div className="mb-3">
            <MoreActionsMembersFilter
              value={values.username}
              placeholder={
                <FormattedMessage
                  id="enter-username.text"
                  defaultMessage="Enter Username"
                />
              }
              onChange={(e) => setFieldValue('username', e)}
              validateStatus={
                errors.username && touched.username ? 'error' : undefined
              }
              help={`${touched.username ? errors.username : ''}`}
            />
          </div>
          <Form.Item
            label={
              <FormattedMessage
                id="member-level.text"
                defaultMessage="Member level"
              />
            }
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            validateStatus={
              (errors.memberLevel && touched.memberLevel) ||
              (errors.userSelected && touched.userSelected)
                ? 'error'
                : undefined
            }
            help={
              touched.memberLevel || touched.userSelected
                ? errors.memberLevel || errors.userSelected
                : null
            }
          >
            <MemberLevelPreviousNew
              type="bulk"
              users={values.username}
              onUserSelected={(e) => setFieldValue('userSelected', e)}
              onChange={(e) => setFieldValue('memberLevel', e)}
              // memberLevel={values.memberLevel}
            />
          </Form.Item>
        </div>
        <div className="bt-1 p-3 text-right">
          <Button className="mr-2" onClick={onClose} loading={isLoading}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </div>
      </StyledForm>
    )}
  </Formik>
);

export default BulkEditMemberLevelMenuForm;
