import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledTag = styled(Tag)`
  height: 22px;
  border-radius: 11px;
`;

export const StyledColorSquare = styled.span<Record<string, any>>`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: ${(props) => props.color || '#efefef'};
  margin-right: 2px;
  vertical-align: middle;
`;

export default {};
