/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Descriptions, Empty, message, Select } from 'antd';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { ErrorHandler } from 'components/ErrorHandler';
import { cryptoCurrencies } from 'constants/currency';
import { DATE_TIME_FORMAT } from 'constants/date';
import { useMutation, useQuery } from 'graphql/client/promoClient';
import {
  CANCEL_MEMBER_PROMO,
  COMPLETE_MEMBER_PROMO,
} from 'graphql/mutations/member.mutation';
import { GET_MEMBER_PROMO_HISTORY } from 'graphql/queries/member.query';
import { useMemberPromo } from 'hooks/useMemberPromo';
import { Account } from 'interfaces/user.interface';
import { includes } from 'lodash';
import moment from 'moment';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import React, { useState } from 'react';
import { useAccount } from 'store/accountState';
import { Tab, useScreenTabV2 } from 'store/screenTabState';
import { Member as MemberAccount } from 'types/graphqlTypes';
import {
  Member,
  MemberPromo,
  MemberPromoStatus,
} from 'types/promo-graphqlTypes-row';
import { isAdmin } from 'utils/isAdmin';
import { renderNumeral } from 'utils/renderNumeral';
import { BalanceText } from '../../Balances/components/WalletBalance/WalletBalancesWrapper';

type Props = {
  showActionButtons?: boolean;
};
const LastPromo: React.FC<Props> = ({ showActionButtons = false }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const {
    account: { account: user },
  } = useAccount();
  // const isNext = useNextParam();
  const { member } = useMember() as { member: MemberAccount };
  const { getActiveTab } = useScreenTabV2();
  const tab: Tab = getActiveTab();
  const memberId = tab.memberId as string;
  const isAdminAcc = isAdmin((user as unknown) as Account);
  const [
    memberPromoHistory,
    setMemberPromoHistory,
  ] = useState<MemberPromo | null>(null);
  const {
    refetchWalletBalances,
    walletCurrency: { currencyOptions },
    promoCurrency,
    setPromoCurrency,
    activeCurrencyLoading,
    availableCurrencyLoading,
  } = useMemberPromo();
  const [hasPromoHistory, setHasPromoHistory] = useState(false);

  const { refetch: refetchPromoHistory } = useQuery<{ member: Member }>(
    GET_MEMBER_PROMO_HISTORY,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        id: memberId,
        language: member.preferredLanguage ?? 'EN',
        first: 1,

        filter: {
          currency: {
            equal: promoCurrency,
          },
        },
      },
      onCompleted: ({ member: { promoHistory } }) => {
        setMemberPromoHistory(promoHistory.edges?.[0]?.node as any);
        setHasPromoHistory(Boolean(promoHistory.edges.length > 0));
      },
      skip: !memberId,
    }
  );
  const {
    promo,
    status,
    amount,
    bonus,
    expiresAt,
    turnoverCounter,
    turnoverTarget,
    currency,
    id: promoHistoryId,
  } = memberPromoHistory || {};

  const isCrypto = includes(cryptoCurrencies, currency);

  const [completePromo, { loading }] = useMutation(COMPLETE_MEMBER_PROMO);
  const [cancelPromo, { loading: cancelMutationLoading }] = useMutation(
    CANCEL_MEMBER_PROMO
  );

  const handleToggleModal = () => setShowConfirm((prev) => !prev);
  const handleCancelToggleModal = () => setShowCancelConfirm((prev) => !prev);

  const handleBonusComplete = async () => {
    await completePromo({
      variables: {
        id: memberPromoHistory?.id,
      },
    })
      .then(() => {
        message.success('Member Bonus successfully completed!');
        refetchPromoHistory();
        refetchWalletBalances();
      })
      .catch((error) => {
        if (error) message.error(`${error.message}`);
      })
      .finally(() => handleToggleModal());
  };
  const handleBonusCancel = async () => {
    await cancelPromo({
      variables: {
        id: promoHistoryId,
      },
    })
      .then(() => {
        message.success('Member Bonus successfully cancelled!');
        refetchPromoHistory();
        refetchWalletBalances();
      })
      .catch((error) => {
        if (error) message.error(`${error.message}`);
      })
      .finally(() => handleCancelToggleModal());
  };

  const promoItems = [
    {
      key: 'title',
      label: 'Promo Title',
      children: promo?.name,
    },

    {
      key: 'amount',
      label: 'Member Deposited',
      children: amount ? renderNumeral(amount, isCrypto) : 0,
    },
    {
      key: 'bonus',
      label: 'Bonus Received',
      children: bonus ? renderNumeral(bonus, isCrypto) : 0,
    },
    {
      key: 'expiresAt',
      label: 'Expiry Date',
      children: moment(expiresAt).format(DATE_TIME_FORMAT),
    },
    {
      key: 'turnoverTarget',
      label: 'Turnover Requirement',
      children: turnoverTarget,
    },
    {
      key: 'turnoverCounter',
      label: 'Turnover Counter',
      children: turnoverCounter,
    },
    {
      key: 'status',
      label: 'Status',
      children: (
        <span
          style={{
            color: 'green',
          }}
        >
          {status}
        </span>
      ),
    },
  ];

  return (
    <>
      <ConfirmationModal
        title="Are you sure you want to complete the bonus?"
        message={"Proceed to manually complete the bonus by clicking 'OK'."}
        visible={showConfirm}
        onCancel={handleToggleModal}
        onOk={() => handleBonusComplete()}
        okButtonProps={loading}
      />
      <ConfirmationModal
        title="Are you sure you want to cancel the bonus?"
        message={"Proceed to manually cancel the bonus by clicking 'OK'."}
        visible={showCancelConfirm}
        onCancel={handleCancelToggleModal}
        onOk={() => handleBonusCancel()}
        okButtonProps={cancelMutationLoading}
      />

      <div className="flex-1">
        <div className="d-flex member-profile-tab-container m-4 flex-column">
          <Select
            className="mb-3"
            style={{ width: '163px' }}
            value={promoCurrency}
            onChange={(e) => setPromoCurrency(e)}
            loading={activeCurrencyLoading || availableCurrencyLoading}
          >
            {currencyOptions?.map((opts, index) => (
              <Select.Option key={index} value={opts.code}>
                <BalanceText currency={opts.code} />
              </Select.Option>
            ))}
          </Select>
          <>
            {hasPromoHistory ? (
              <div>
                <Descriptions bordered column={1}>
                  {promoItems.map((item) => (
                    <Descriptions.Item label={item.label} key={item.key}>
                      {item.children}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
                {isAdminAcc &&
                  showActionButtons &&
                  memberPromoHistory?.status === MemberPromoStatus.Active && (
                    <div className="align-self-start ml-5 mt-1">
                      <Button
                        type="primary"
                        onClick={handleToggleModal}
                        className="mb-2"
                      >
                        Complete
                      </Button>
                      <Button
                        type="primary"
                        onClick={handleCancelToggleModal}
                        style={{ padding: '0 24px 0 24px' }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
              </div>
            ) : (
              <ErrorHandler>
                <div className="d-flex align-items-center justify-content-center member-profile-tab-container">
                  <Empty />
                </div>
              </ErrorHandler>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default LastPromo;
