type Options = {
  isActive: boolean;
  isRealtime: boolean;
  pollTime?: number;
};

export const getPollInterval = (options: Options) => {
  const { isActive, isRealtime } = options;
  const pollTime = options.pollTime ? options.pollTime * 10000 : 10000;
  const pollInterval = isActive && isRealtime ? pollTime : 0;

  return pollInterval;
};
