import gql from 'graphql-tag';

// TODO: delete all legacy promo queries

export const MEMBER_LOYALTY_PROGRAMMES = gql`
  {
    memberLoyaltyProgrammes {
      edges {
        node {
          id
          status
          levels {
            id
            name
          }
        }
      }
    }
  }
`;

export const QUALIFYING_MEMBER_LOYALTY_LEVELS = gql`
  query memberLoyalLevels($filter: MemberLoyaltyLevelsFilterInput) {
    memberLoyaltyLevels(filter: $filter) {
      edges {
        node {
          name
          id
          color
          programme {
            id
            name
          }
        }
      }
    }
  }
`;

export const PROMO = gql`
  query promo($id: ID!) {
    promo(id: $id) {
      message {
        title
        content
      }
      enrollmentFields {
        label
      }
      payoutAmount
      id
      automaticEnrollmentApproval
      claimExpiryDuration
      claimOffsetDuration
      payoutExpiryDuration
      payoutSettlementTime
      payoutSettlementPeriod
      payoutSettlementDayOfWeek
      payoutSettlementMonth
      payoutSettlementDayOfMonth
      serialCode
      depositConditionType
      title
      # TODO PROMO | REMOVE _next upon promotion
      banner_next(platform: DESKTOP) {
        id
        uri
      }
      mobileBanner: banner_next(platform: MOBILE) {
        id
        uri
      }
      tag {
        color
        id
        name
      }
      category {
        name
        id
      }
      validityDateTimeRange {
        start
        end
      }
      publicationSchedule {
        mode
        dateTimeRange {
          start
          end
        }
      }
      description
      automaticEnrollment
      automaticPayoutClaiming
      status
      qualifyingMemberLoyaltyLevels {
        name
        id
        color
      }
      turnoverRequirementMultiplier
      excludedMemberLevels {
        name
        id
        color
      }
      payoutSettlementTime
      maximumEnrolleesCount
      creator {
        username
      }
      maximumEnrolleesCountPerIpAddress
      maximumEnrolleesCountPerName
      subtitle
      termsAndConditions
      template
      requireBankAccount
      payoutLevels {
        minimum
        type
        value
      }
      requiredMemberFields
      conditions {
        type
      }
      newMemberQualificationDuration
      vendorTargets {
        gameType
        vendor {
          id
          nameEn: name(language: EN)
          nameZh: name(language: ZH)
        }
      }
      totalPayoutBudget
      payoutBudgetExploded
      includeFreeSpin
    }
  }
`;

export const PROMO_CATEGORIES = gql`
  query {
    promoCategories {
      id
      name
      rank
      dateTimeCreated
      dateTimeUpdated
    }
  }
`;

export const PROMOS = gql`
  query promos($filter: PromosFilterInput) {
    promos(filter: $filter) {
      totalCount
      edges {
        node {
          message {
            title
            content
          }
          id
          payoutLevels {
            minimum
            type
            value
          }
          payoutAmount
          claimExpiryDuration
          claimOffsetDuration
          payoutExpiryDuration
          payoutSettlementPeriod
          payoutSettlementTime
          payoutSettlementDayOfWeek
          payoutSettlementMonth
          payoutSettlementDayOfMonth
          serialCode
          title
          newMemberQualificationDuration
          # TODO PROMO | REMOVE _next upon promotion
          banner_next(platform: DESKTOP) {
            id
            uri
          }
          desktopBanner: banner_next(platform: DESKTOP) {
            id
            uri
          }
          mobileBanner: banner_next(platform: MOBILE) {
            id
            uri
          }
          category {
            name
            id
          }
          validityDateTimeRange {
            start
            end
          }
          publicationSchedule {
            mode
            dateTimeRange {
              start
              end
            }
          }
          name
          description
          automaticEnrollment
          automaticEnrollmentApproval
          automaticPayoutClaiming
          turnoverRequirementMultiplier
          status
          tag {
            color
            id
            name
          }
          qualifyingMemberLoyaltyLevels {
            name
            id
          }
          excludedMemberLevels {
            name
            id
          }
          maximumEnrolleesCount
          creator {
            username
          }
          maximumEnrolleesCountPerIpAddress
          maximumEnrolleesCountPerName
          subtitle
          termsAndConditions
          template
          requireBankAccount
          rank
          enrollmentFields {
            required
            label
            pattern
          }
          requiredMemberFields
          vendorTargets {
            gameType
            vendor {
              id
              nameEn: name(language: EN)
              nameZh: name(language: ZH)
            }
          }
          totalPayoutBudget
          payoutBudgetExploded
          includeFreeSpin
          payoutDeductibleTypes
          canGenerateRebatePayout
          canGenerateMemberLoyaltyLevelProgress
        }
      }
    }
  }
`;

export const PROMOS_AUTOCOMPLETE = gql`
  query promos($filter: PromosFilterInput) {
    promos(filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const PROMO_PREV = gql`
  query promo($id: ID!) {
    promo(id: $id) {
      message {
        title
        content
      }
      enrollmentFields {
        label
      }
      payoutAmount
      id
      automaticEnrollmentApproval
      claimExpiryDuration
      claimOffsetDuration
      payoutExpiryDuration
      payoutSettlementTime
      payoutSettlementPeriod
      payoutSettlementDayOfWeek
      payoutSettlementMonth
      payoutSettlementDayOfMonth
      serialCode
      depositConditionType
      title
      banner {
        id
        uri
      }
      tag {
        color
        id
        name
      }
      category {
        name
        id
      }
      validityDateTimeRange {
        start
        end
      }
      publicationSchedule {
        mode
        dateTimeRange {
          start
          end
        }
      }
      description
      automaticEnrollment
      automaticPayoutClaiming
      status
      qualifyingMemberLoyaltyLevels {
        name
        id
        color
      }
      turnoverRequirementMultiplier
      excludedMemberLevels {
        name
        id
        color
      }
      payoutSettlementTime
      maximumEnrolleesCount
      creator {
        username
      }
      maximumEnrolleesCountPerIpAddress
      maximumEnrolleesCountPerName
      subtitle
      termsAndConditions
      template
      requireBankAccount
      payoutLevels {
        minimum
        type
        value
      }
      requiredMemberFields
      conditions {
        type
      }
      newMemberQualificationDuration
      teaser
      vendorTargets {
        gameType
        vendor {
          id
          nameEn: name(language: EN)
          nameZh: name(language: ZH)
        }
      }
      totalPayoutBudget
      payoutBudgetExploded
      includeFreeSpin
    }
  }
`;

export const PROMOS_PREV = gql`
  query promos($filter: PromosFilterInput) {
    promos(filter: $filter) {
      totalCount
      edges {
        node {
          message {
            title
            content
          }
          id
          payoutLevels {
            minimum
            type
            value
          }
          payoutAmount
          claimExpiryDuration
          claimOffsetDuration
          payoutExpiryDuration
          payoutSettlementPeriod
          payoutSettlementTime
          payoutSettlementDayOfWeek
          payoutSettlementMonth
          payoutSettlementDayOfMonth
          serialCode
          title
          newMemberQualificationDuration
          banner {
            id
            uri
          }
          category {
            name
            id
          }
          validityDateTimeRange {
            start
            end
          }
          publicationSchedule {
            mode
            dateTimeRange {
              start
              end
            }
          }
          name
          description
          automaticEnrollment
          automaticEnrollmentApproval
          automaticPayoutClaiming
          turnoverRequirementMultiplier
          status
          tag {
            color
            id
            name
          }
          teaser
          qualifyingMemberLoyaltyLevels {
            name
            id
          }
          excludedMemberLevels {
            name
            id
          }
          maximumEnrolleesCount
          creator {
            username
          }
          maximumEnrolleesCountPerIpAddress
          maximumEnrolleesCountPerName
          subtitle
          termsAndConditions
          template
          requireBankAccount
          rank
          enrollmentFields {
            required
            label
            pattern
          }
          requiredMemberFields
          vendorTargets {
            gameType
            vendor {
              id
              nameEn: name(language: EN)
              nameZh: name(language: ZH)
            }
          }
          totalPayoutBudget
          payoutBudgetExploded
          includeFreeSpin
          payoutDeductibleTypes
          canGenerateRebatePayout
          canGenerateMemberLoyaltyLevelProgress
        }
      }
    }
  }
`;

export const PROMO_NAMES = gql`
  query Promos($filter: PromosFilterInput) {
    promos(filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

// new Promo
export const GET_PROMOS = gql`
  query Promos($first: Int, $after: Binary, $filter: PromosFilterInput) {
    promos(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          ... on DepositPromo {
            id
            name
            type
            status
            startDateTime
            endDateTime
          }
          ... on SignUpPromo {
            id
            name
            type
            status
            startDateTime
            endDateTime
          }
        }
      }
    }
  }
`;

export const GET_PROMO_NAMES = gql`
  query PromoNames(
    $filter: PromosFilterInput
    $partialFilter: PromosFilterInput
  ) {
    promos(filter: $filter) {
      edges {
        cursor
        node {
          name
        }
      }
    }

    partial: promos(filter: $partialFilter) {
      edges {
        cursor
        node {
          name
        }
      }
    }
  }
`;

export const GET_PROMO = gql`
  query GetPromo($id: ID!) {
    promo(id: $id) {
      id
      status
      name
      type
      event
      startDateTime
      endDateTime
      platform
      includedMemberTags {
        id
      }
      excludedMemberTags {
        id
      }
      claimType
      awardPreRequisite
      turnoverRequirementMultiplier
      wagerContributions {
        rank
        vendor {
          id
        }
        gameType
        contribution
      }
      autoCancel
      maintainingBalance
      ... on DepositPromo {
        awardType
        amount
        amountPercentage
        awardCap
        minAmount
        maxAmount
        claimCountLimit
        paymentMethods {
          id
          name
        }
        maxPayoutAmount
      }
      ... on SignUpPromo {
        amount
        maxMemberCount
      }
    }
  }
`;

export const GET_PROMO_PAYOUT_NAMES = gql`
  query PromoPayoutNames(
    $filter: PromoPayoutsFilterInput
    $partialFilter: PromoPayoutsFilterInput
  ) {
    promoPayouts(filter: $filter) {
      edges {
        cursor
        node {
          name
        }
      }
    }

    partial: promoPayouts(filter: $partialFilter) {
      edges {
        cursor
        node {
          name
        }
      }
    }
  }
`;

export const GET_PROMO_PAYOUTS = gql`
  query PromoPayouts(
    $first: Int
    $after: Binary
    $filter: PromoPayoutsFilterInput
  ) {
    promoPayouts(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          type
          startDateTime
          endDateTime
          amount
          member {
            id
            platformId
            brandId
          }
          admin {
            id
            username
          }
          depositAmount
          promo {
            id
            totalAmountAwarded
          }
        }
      }
    }
  }
`;

export default {};
