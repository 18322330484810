import React from 'react';
import { Table } from 'antd';
import { StyledTable, StyledTableContainer } from 'constants/styles';

const { Column } = Table;

const TableIndex = ({
  loading,
  dataSource,
  error,
  columns,
}: {
  loading: boolean;
  dataSource: Array<Record<string, any>>;
  error: any;
  columns: Array<any>;
}) => {
  let containerTestId = 'container';
  if (loading) {
    containerTestId = 'loading';
  } else if (error) {
    containerTestId = 'error';
  } else {
    containerTestId = 'container';
  }
  return (
    <StyledTableContainer data-testid={containerTestId}>
      <StyledTable
        bordered
        size="middle"
        rowKey="id"
        dataSource={dataSource}
        loading={loading}
        pagination={false}
        scroll={{ x: 2000 }}
      >
        {columns?.map((column, idx) => (
          <Column
            title={column?.title}
            dataIndex={column?.dataIndex}
            key={column?.dataIndex || idx}
            align="center"
            render={column?.render}
          />
        ))}
      </StyledTable>
    </StyledTableContainer>
  );
};

export default TableIndex;
