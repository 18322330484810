import { ErrorHandler } from 'components/ErrorHandler';
import { TabId } from 'constants/route';
import FiltersProvider from 'contexts/Filters';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import { MEMBER_BALANCE_UNIT_RECORDS_CSV_DATA } from 'graphql/queries/balanceServiceRecord.query';
import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import uuid from 'uuid';
import BalanceServiceRecordsScreen from './components/BalanceServiceRecordsScreen';

const BalanceServiceRecordReport = () => {
  const [key, setKey] = useState('');
  const { getTab } = useScreenTabV2();
  const { memberRef, filter = {} } = getTab(
    TabId.BALANCE_SERVICE_RECORD_REPORT as any
  );

  const defaultMemberInfo = memberRef || {};
  const defaultFilters = {
    member: defaultMemberInfo.id || '',
    transactionSerialCode: null,
    serialCode: null,
    transactionType: null,
    originalAmount: null,
    currentAmount: null,
    turnoverRequirementMultiplier: null,
    turnoverRequirement: null,
    turnoverRequirementBalance: null,
    dateTimeCreated: null,
    dateTimeTurnoverCompleted: null,
    betCount: null,
    withdrawals: null,
  };

  const initialFilters = {
    ...defaultFilters,
    ...filter,
    member: defaultMemberInfo.id || '',
  };

  return (
    <SpreadsheetProvider
      options={{
        query: MEMBER_BALANCE_UNIT_RECORDS_CSV_DATA,
        edgesPath: 'memberBalanceUnitRecords.edges',
      }}
    >
      <FiltersProvider
        initialValues={initialFilters}
        defaultValues={defaultFilters}
        tabId={TabId.BALANCE_SERVICE_RECORD_REPORT as any}
      >
        <ErrorHandler>
          <BalanceServiceRecordsScreen
            key={key}
            refreshPage={() => setKey(uuid.v1())}
            filter={filter}
            defaultMemberInfo={defaultMemberInfo}
          />
        </ErrorHandler>
      </FiltersProvider>
    </SpreadsheetProvider>
  );
};

export default BalanceServiceRecordReport;
