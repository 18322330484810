import { ColumnsType } from 'antd/lib/table';
import { DATE_TIME_FORMAT } from 'constants/date';
import { Account } from 'types/graphqlTypes';
import { formatDate } from 'utils/dateUtils';

interface DataTypes {
  title: string;
  key: string;
  dataIndex?: string;
  render: Function;
}

export const getColumns = ({
  hasFeatureField,
}: {
  hasFeatureField: boolean;
}): ColumnsType<DataTypes> => [
  {
    title: 'Date/Time Created',
    key: 'dateTimeCreated',
    dataIndex: 'dateTimeCreated',
    render: (dateTimeCreated: string) => {
      const formattedDateTime = formatDate(dateTimeCreated, DATE_TIME_FORMAT);
      return formattedDateTime;
    },
  },
  {
    title: 'Action Type',
    key: 'actionType',
    dataIndex: 'actionType',
    render: (actionType: string) => {
      switch (actionType) {
        case 'OFF':
          return 'Off';
        case 'ON':
          return 'On';
        case 'MINS_15':
          return '15 mins';
        case 'MINS_30':
          return '30 mins';
        case 'MINS_45':
          return '45 mins';
        case 'MINS_60':
          return '60 mins';
        default:
          return actionType;
      }
    },
  },
  {
    ...(hasFeatureField
      ? {
          title: 'Feature',
          key: 'feature',
          dataIndex: 'feature',
          render: (feature: string) => feature,
        }
      : {}),
  },
  {
    title: 'Account (Operator)',
    key: 'account',
    render: ({ account }: { account: Account }) => account.username,
  },
];
