import { formatDate } from 'utils/dateUtils';
import messages from 'messages';
import { MemberSummaryReport } from 'types/graphqlTypes';
import { DATE_TIME_FORMAT } from 'constants/date';

const getCsvHeaders = (translate: any) => [
  {
    label: translate(messages.START_END_DATE_TIME),
    key: 'startEndDateTime',
    renderCell: (record: MemberSummaryReport) =>
      `${formatDate(
        record.filter?.startDateTime,
        DATE_TIME_FORMAT
      )} - ${formatDate(record.filter?.endDateTime, DATE_TIME_FORMAT)}`,
  },
  {
    label: translate(messages.GAME_CATEGORY),
    key: 'gameCategory',
    renderCell: (record: MemberSummaryReport) =>
      record.filter?.gameTypes
        ?.map((gameType) => ` ${translate(messages[`${gameType}`])}`)
        .toString() || '-',
  },
  {
    label: translate(messages.GAME_VENDOR),
    key: 'gameVendor',
    renderCell: (record: MemberSummaryReport) =>
      record.filter?.vendors?.map((vendor) => ` ${vendor.name}`).toString() ||
      '-',
  },
  {
    label: translate(messages.TIMEZONE_SELECTION),
    key: 'timezone',
    render: (record: MemberSummaryReport) => record.timezone || '-',
  },
  {
    label: translate(messages.CREATED_BY),
    key: 'creator',
    renderCell: (record: MemberSummaryReport) => record.creator.username || '-',
  },
  {
    label: 'Date/Time Created',
    key: 'dateTimeCreated',
    renderCell: (record: MemberSummaryReport) =>
      formatDate(record.dateTimeCreated, DATE_TIME_FORMAT) || '-',
  },
  {
    label: 'Date/Time Generated',
    key: 'dateTimeGenerated',
    renderCell: (record: MemberSummaryReport) =>
      formatDate(record.report?.dateTimeCreated, DATE_TIME_FORMAT) || '-',
  },
];

export default getCsvHeaders;
