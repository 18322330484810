import React from 'react';
import messages from 'messages';
import { FilterItem } from 'components/FilterItem/FilterItem';
import useTranslate from 'utils/useTranslate';
import TimezoneFilter from 'components/TimezoneFilter';
import GameTypesSelect from 'components/GameTypesSelect';
import { Checkbox, Row, Col } from 'antd';
import MemberLoyaltyLevelsSelect from 'components/MemberLoyaltyLevelsSelect';
import MemberTagSelect from 'components/MemberTagSelect';
import VendorSelect from 'components/VendorSelect';
import AmountFilter from 'components/AmountFilter';
import isEmpty from 'lodash/isEmpty';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import AffiliateUplineSelect from 'components/AffiliateUplineSelect';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import coercedGet from 'utils/coercedGet';
import { GET_MSR_USERNAMES } from 'graphql/queries/memberSummaryReport.query';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import { TableFilterType } from '../../../../types';

type Props = {
  filters: TableFilterType;
  handleFilterChange: (params: { name: string; value: any }) => void;
  isCustomFilter?: boolean;
};

const FilterSettings: React.FC<Props> = ({
  filters,
  handleFilterChange,
  isCustomFilter = true,
}) => {
  const translate = useTranslate();

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST: ALLOWED_VIP_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  );

  const { ALLOWED_LIST: ALLOWED_AFFILIATE_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES
  );

  const { ALLOWED_LIST: ALLOWED_MARKER_LEVEL_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const { ALLOWED_LIST: ALLOWED_MEMBER_LIST } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  return (
    <>
      <DateTimeRangePicker
        testId="MSR-date-filter"
        label="start-end-date-time"
        value={
          filters.startDateTime && filters.endDateTime
            ? [filters.startDateTime, filters.endDateTime]
            : null
        }
        showTime
        onChange={(e) => {
          handleFilterChange({
            name: 'startDateTime',
            value: e?.length ? e[0] : null,
          });
          handleFilterChange({
            name: 'endDateTime',
            value: e?.length ? e[1] : null,
          });
        }}
      />

      {ALLOWED_MEMBER_LIST && (
        <IdBasedSelectFilterField
          testId="MSR-username-filter"
          label="Members"
          query={GET_MSR_USERNAMES}
          queryConnection="members"
          filterFieldName="username"
          filters={filters}
          rawFilterValues={coercedGet(filters, 'members', [])}
          isCustomFilter={isCustomFilter}
          partialSupported={false}
          onChange={(e) =>
            handleFilterChange({
              name: 'members',
              value: e?.length ? e : null,
            })
          }
        />
      )}

      <FilterItem
        label={translate(messages.GAME_CATEGORY)}
        onClear={() =>
          handleFilterChange({
            name: 'gameTypes',
            value: null,
          })
        }
      >
        <GameTypesSelect
          value={filters.gameTypes || []}
          onChange={(value: string | Array<string>) =>
            handleFilterChange({
              name: 'gameTypes',
              value: value.length ? value : null,
            })
          }
          placeHolder={translate(messages.ENTER_USERNAME)}
          multiple
        />
      </FilterItem>

      <FilterItem
        label={translate(messages.GAME_VENDOR)}
        onClear={() =>
          handleFilterChange({
            name: 'vendors',
            value: null,
          })
        }
      >
        <VendorSelect
          value={filters.vendors || []}
          onChange={(value: string | Array<string>) =>
            handleFilterChange({
              name: 'vendors',
              value: value.length ? value : null,
            })
          }
          placeHolder={translate(messages.SELECT_VENDOR)}
          multiple
          queryFilter={
            filters.gameTypes?.length
              ? {
                  gameTypes: {
                    includesAny: filters.gameTypes,
                  },
                }
              : {}
          }
        />
      </FilterItem>

      <FilterItem
        label={translate(messages.TIMEZONE_SELECTION)}
        onClear={() =>
          handleFilterChange({
            name: 'timezone',
            value: null,
          })
        }
      >
        <TimezoneFilter
          value={filters.timezone || ''}
          onChange={(value: string | Array<string>) =>
            handleFilterChange({
              name: 'timezone',
              value: value || null,
            })
          }
          placeHolder={translate(messages.SELECT_TIMEZONE)}
        />
      </FilterItem>

      <FilterItem
        label={translate(messages.AFFILIATE_FLAG)}
        onClear={() =>
          handleFilterChange({
            name: 'isAffiliate',
            value: null,
          })
        }
      >
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={(e) =>
            handleFilterChange({
              name: 'isAffiliate',
              value: e.length ? e : null,
            })
          }
          value={filters.isAffiliate || undefined}
        >
          <Checkbox value>{translate(messages.YES)}</Checkbox>
          <Checkbox value={false}>{translate(messages.NO)}</Checkbox>
        </Checkbox.Group>
      </FilterItem>

      <FilterItem
        label={translate(messages.AFFILIATE_UPLINE)}
        onClear={() =>
          handleFilterChange({
            name: 'isAffiliateUpline',
            value: null,
          })
        }
      >
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={(e) =>
            handleFilterChange({
              name: 'isAffiliateUpline',
              value: e.length ? e : null,
            })
          }
          value={filters.isAffiliateUpline || undefined}
        >
          <Checkbox value>{translate(messages.YES)}</Checkbox>
          <Checkbox value={false}>{translate(messages.NO)}</Checkbox>
        </Checkbox.Group>
      </FilterItem>

      {ALLOWED_AFFILIATE_LIST && (
        <FilterItem
          label={translate(messages.AFFILIATE_UPLINE)}
          onClear={() =>
            handleFilterChange({
              name: 'upline',
              value: null,
            })
          }
        >
          <AffiliateUplineSelect
            value={filters?.upline?.in[0]}
            onChange={(e: string) =>
              handleFilterChange({
                name: 'upline',
                value: {
                  in: [e],
                },
              })
            }
          />
        </FilterItem>
      )}

      {ALLOWED_VIP_LIST && (
        <FilterItem
          label={translate(messages.VIP_LEVEL)}
          onClear={() =>
            handleFilterChange({
              name: 'memberLoyaltyLevels',
              value: null,
            })
          }
        >
          <MemberLoyaltyLevelsSelect
            value={filters.memberLoyaltyLevels || []}
            onChange={(value: string | Array<string>) =>
              handleFilterChange({
                name: 'memberLoyaltyLevels',
                value: value.length ? value : null,
              })
            }
            placeHolder={translate(messages['enter-vip-level.text'])}
            multiple
          />
        </FilterItem>
      )}

      {ALLOWED_MARKER_LEVEL_LIST && (
        <>
          <IdBasedSelectFilterField
            testId="MSR-member-marker-filter"
            label="Member Marker"
            query={GET_MEMBER_LEVELS}
            queryConnection="memberLevels"
            filterFieldName="name"
            filters={filters}
            isCustomFilter={isCustomFilter}
            rawFilterValues={coercedGet(filters, 'memberLevels', [])}
            partialSupported={false}
            onChange={(e) =>
              handleFilterChange({
                name: 'memberLevels',
                value: e?.length ? e : null,
              })
            }
          />

          <FilterItem
            label={translate(messages.MEMBER_LABEL)}
            onClear={() =>
              handleFilterChange({
                name: 'memberTags',
                value: null,
              })
            }
          >
            <MemberTagSelect
              value={filters.memberTags || []}
              onChange={(value: string | Array<string>) =>
                handleFilterChange({
                  name: 'memberTags',
                  value: value.length ? value : null,
                })
              }
              placeHolder={translate(messages.ENTER_MEMBER_LABEL)}
              multiple
              isCustomFilter={isCustomFilter}
            />
          </FilterItem>
        </>
      )}

      <DateTimeRangePicker
        testId="MSR-registration-date-filter"
        label="registration-start-end-date-time"
        value={
          filters.registrationDateTime
            ? [
                filters.registrationDateTime.start,
                filters.registrationDateTime.end,
              ]
            : null
        }
        showTime
        onChange={(e) => {
          handleFilterChange({
            name: 'registrationDateTime',
            value: e?.length ? { start: e[0], end: e[1] } : null,
          });
        }}
      />

      <DateTimeRangePicker
        testId="MSR-last-login-date-filter"
        label="last-login-start-end-date-time"
        value={
          filters.lastLoginDateTime
            ? [filters.lastLoginDateTime.start, filters.lastLoginDateTime.end]
            : null
        }
        showTime
        onChange={(e) => {
          handleFilterChange({
            name: 'lastLoginDateTime',
            value: e?.length ? { start: e[0], end: e[1] } : null,
          });
        }}
      />
      <DateTimeRangePicker
        testId="MSR-birth-date-filter"
        label="date-of-birth-start-end-date-time"
        value={
          filters.dateOfBirthDateTime
            ? [
                filters.dateOfBirthDateTime.start,
                filters.dateOfBirthDateTime.end,
              ]
            : null
        }
        showTime
        onChange={(e) => {
          handleFilterChange({
            name: 'dateOfBirthDateTime',
            value: e?.length ? { start: e[0], end: e[1] } : null,
          });
        }}
      />

      <FilterItem
        label={translate(messages.STATUS)}
        onClear={() =>
          handleFilterChange({
            name: 'memberStatus',
            value: null,
          })
        }
      >
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={(e) =>
            handleFilterChange({
              name: 'memberStatus',
              value: e.length ? e : null,
            })
          }
          value={filters.memberStatus || undefined}
        >
          <Row>
            <Col span={12}>
              <Checkbox value="ENABLED">{translate(messages.ENABLED)}</Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox value="DISABLED">
                {translate(messages.DISABLED)}
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </FilterItem>

      <FilterItem
        label={translate(messages.ACCOUNT_BALANCE)}
        onClear={() =>
          handleFilterChange({
            name: 'totalBalance',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: filters.totalBalance?.minimum || null,
            max: filters.totalBalance?.maximum || null,
          }}
          onChange={(e: any) => {
            const amount = { ...filters.totalBalance };
            const newRange = e.range === 'gte' ? 'minimum' : 'maximum';

            if (!e.value) delete amount[newRange];
            else amount[newRange] = e.value;

            handleFilterChange({
              name: 'totalBalance',
              value: isEmpty(amount) ? null : amount,
            });
          }}
        />
      </FilterItem>
    </>
  );
};

export default FilterSettings;
