import React, { ReactElement } from 'react';
import createContext from 'utils/createContext';

export const SortTypes = {
  GAME_TYPE: 'GAME_TYPE',
  GAME_VENDOR: 'GAME_VENDOR',
};

export const SearchTypes = {
  GAME_TYPE: 'GAME_TYPE',
  GAME_VENDOR: 'GAME_VENDOR',
  GAME_NAME: 'GAME_NAME',
};

export const SortTypeNames = {
  [SortTypes.GAME_TYPE]: 'Game type',
  [SortTypes.GAME_VENDOR]: 'Game vendor',
};

export const SearchTypeNames = {
  [SearchTypes.GAME_TYPE]: 'Game type',
  [SearchTypes.GAME_VENDOR]: 'Game vendor',
  [SearchTypes.GAME_NAME]: 'Game name',
};

export const RebateGroupTypes = {
  SET_SORT_BY: 'SET_SORT_BY',
  SET_FILTER_GAME_VENDORS: 'ALL',
  SET_FILTER_VALUES: 'SET_FILTER_VALUES',
  REMOVE_FILTER_VALUES: 'REMOVE_FILTER_VALUES',
  RESET_FILTER_VALUES: 'RESET_FILTER_VALUES',
  SET_SEARCH: 'SET_SEARCH',
  SET_GAMES: 'SET_GAMES',
  SET_FOCUSED_ITEM: 'SET_FOCUSED_ITEM',
  SET_FOCUSED_ITEMS: 'SET_FOCUSED_ITEMS',
};

export const [useCtx, ContextProvider] = createContext();

export function RebateGroupProvider({ children }: { children: ReactElement }) {
  const initialState = {
    sortBy: SortTypes.GAME_TYPE,
    search: {
      mode: SearchTypes.GAME_TYPE,
      keyword: '',
    },
    focusedItems: [],
    games: [],
    filterValues: [],
  };

  const reducer = (
    state: any,
    action: {
      type: string;
      payload: any;
    }
  ) => {
    switch (action.type) {
      case RebateGroupTypes.SET_SORT_BY:
        return {
          ...state,
          sortBy: action.payload,
        };
      case RebateGroupTypes.SET_FILTER_VALUES:
        return {
          ...state,
          filterValues: [...state.filterValues, action.payload],
        };
      case RebateGroupTypes.REMOVE_FILTER_VALUES:
        return {
          ...state,
          filterValues: state.filterValues.filter(
            (item: { value: string }) => item.value !== action.payload.value
          ),
        };
      case RebateGroupTypes.RESET_FILTER_VALUES:
        return {
          ...state,
          filterValues: [],
        };
      case RebateGroupTypes.SET_SEARCH:
        return {
          ...state,
          search: { ...state.search, ...action.payload },
        };
      case RebateGroupTypes.SET_GAMES:
        return {
          ...state,
          games: action.payload,
        };
      case RebateGroupTypes.SET_FOCUSED_ITEMS:
        return {
          ...state,
          focusedItems: action.payload,
        };
      default:
        return state;
    }
  };
  return (
    <ContextProvider value={React.useReducer(reducer, initialState)}>
      {children}
    </ContextProvider>
  );
}

export const useRebateGroupPercentagesState = () => useCtx();
