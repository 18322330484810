import { useQuery } from '@apollo/react-hooks';
import { Spin } from 'antd';
import { GET_MEMBER_INTERACTION_LOGS } from 'graphql/queries/memberInteractionLog.query';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PermissionError from 'components/PermissionError/PermissionError';
import { useScreenTabV2 } from 'store/screenTabState';
import { getPollInterval } from 'utils/pollInterval';
import { MoreDetailsTransaction } from '../MoreDetailsTransaction/MoreDetailsTransaction';
import MemberInteractionTable from './components/MemberInteractionTable';
import { StyledCard } from './styles';

type Props = {
  hasPermission: boolean;
};

const MemberInteraction = ({ hasPermission }: Props) => {
  const { addTab, isTabActive } = useScreenTabV2();
  const { member } = useMember() as any;

  const refetchVariables = {
    first: 5,
    after: undefined,
    filter: {
      member: { eq: member.id },
    },
  };

  const { loading, data } = useQuery(GET_MEMBER_INTERACTION_LOGS, {
    skip: !hasPermission,
    fetchPolicy: 'network-only',
    variables: refetchVariables,
    pollInterval: getPollInterval({
      isActive: isTabActive('member-detail'),
      isRealtime: true,
    }),
  });

  const { memberInteractionLogs = {} }: any = data || {};
  const { totalCount, edges = [] } = memberInteractionLogs;

  const openMemberInteractionLogTab = () => {
    addTab({
      id: 'member-interaction-log-report',
      state: {
        searchRef: {
          filterFieldName: 'username',
          memberId: member.id,
          username: member.username,
        },
      },
    });
  };

  return (
    <Spin spinning={loading}>
      <StyledCard>
        <div className="d-flex align-items-center card-header">
          <span className="fw-500">
            <FormattedMessage
              id="member-interaction-logs-report.text"
              defaultMessage="Member Interaction Logs Report"
            />
          </span>
        </div>
        <PermissionError
          withPermission={hasPermission}
          message="Member Interaction Log Report"
        >
          <div className="card-body">
            <MemberInteractionTable dataSource={edges} />
            <MoreDetailsTransaction
              valueCount={totalCount}
              onLoadMore={() => openMemberInteractionLogTab()}
            />
          </div>
        </PermissionError>
      </StyledCard>
    </Spin>
  );
};

export default MemberInteraction;
