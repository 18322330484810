import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-management.member-label-update-success.text': {
    id: 'member-management.member-label-update-success.text',
    defaultMessage: 'Member label successfully updated',
  },
  LOADING_TAGS: {
    id: 'LOADING_TAGS',
    defaultMessage: 'Loading Tags...',
  },
});

export default messages;
