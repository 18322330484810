/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Checkbox, Col, Row } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import { useFormikContext } from 'formik';
import { includes } from 'lodash';
import { CheckboxProps } from 'antd/lib/checkbox';
import coercedGet from 'utils/coercedGet';
import {
  CheckState,
  memberBulkUpdateManagementPermissions,
  memberEditBalanceAddList,
  memberManagement,
  memberEditBalanceManagementList,
  allList,
  memberEditBalanceRemoveList,
  memberLabelManagementPermissions,
  memberMarkerManagementPermissions,
  memberReportsManagementPermissions,
  memberViewDetailsManagementList,
  membersManagementList,
  memberLabelManagementHint,
  memberManagementHint,
  memberMarkerManagementHint,
  onChangeCheck,
  TabType,
  viewDetailsChildren,
  memberBulkUpdateManagementHint,
  membersOnlineOnlyPermission,
  memberConfigsPermission,
  memberConfigManagementHint,
  handleIsIndeterminateParent,
  memberReportsManagementHint,
} from './memberTreeUtil';
import { StyledRootDiv } from './styles';
import messages from '../messages';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div<any>`
  width: 300px;
  margin-left: ${(props) => props.marginLeft};
`;

const StyledParentDiv = styled.div<any>`
  width: 2100px;
  padding-bottom: 100px;
  overflow: hidden;
`;

const MembersTreeRevamp = () => {
  const translate = useTranslate();

  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const availablePermissions = coercedGet(
    values,
    'permissions.ALL_MEMBERS',
    []
  );

  const [checkedStatus, setCheckedStatus] = useState<CheckState>({
    indeterminate: {
      all: false,
      MEMBERS_MEMBER_MANAGEMENT: false,
      MEMBERS_MEMBER_MARKER_MANAGEMENT: false,
      MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS: false,
      MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE: false,
      MEMBERS_MEMBERS_ONLINE_ONLY: false,
      MEMBERS_MANAGEMENT_CONFIG: false,
      MEMBERS_AUTOMATION: false,
      MEMBERS_REPORTS: false,
      MEMBERS_MEMBER_BULK_UPDATE: false,
      MEMBERS_MEMBER_MANAGEMENT_LIST: false,
      MEMBERS_MEMBER_MARKER_MANAGEMENT_LIST: false,
      MEMBERS_MEMBER_MARKER_MANAGEMENT_VIEW_DETAILS: false,
      MEMBERS_LABEL_MANAGEMENT: false,
      MEMBERS_LABEL_MANAGEMENT_LIST: false,
      MEMBERS_LABEL_MANAGEMENT_VIEW_DETAILS: false,
      MEMBERS_AUTOMATION_LIST: false,
    },
    currentCheckedList: [],
  });

  const handleTranslateText = (key: string, tabType: TabType) => {
    let text = '';
    if (key.startsWith(`${tabType}_VIEW_DETAILS_`)) {
      text = translate(messages[key.replace(`${tabType}_VIEW_DETAILS_`, '')]);
    } else if (key.includes('REPORT')) {
      text = translate(messages[key]);
    } else if (
      tabType === memberBulkUpdateManagementHint ||
      tabType === 'MEMBERS_MEMBERS_ONLINE_ONLY'
    ) {
      text = translate(messages[key]);
    } else {
      text = translate(messages[key.replace(`${tabType}_`, '')]);
    }
    return text;
  };

  const handleMemberViewDetailsIndent = (
    key: string,
    permissionType: TabType
  ) => {
    if (includes(viewDetailsChildren, key)) {
      return '100px';
    }
    if (key.endsWith(`${permissionType}`)) return '0px';
    if (
      (permissionType === 'MEMBERS_LABEL_MANAGEMENT' ||
        permissionType === 'MEMBERS_MEMBER_MARKER_MANAGEMENT') &&
      (key.endsWith(`${permissionType}_CREATE`) ||
        key.endsWith(`${permissionType}_EDIT`) ||
        key.endsWith(`${permissionType}_DELETE`) ||
        key.endsWith(`${permissionType}_TOGGLE_ACTIVATION`))
    )
      return '80px';
    if (key.endsWith(`${permissionType}_VIEW_DETAILS`)) return '50px';
    if (key.startsWith(`${permissionType}_REPORT_`)) return '75px';
    if (key.startsWith(`${permissionType}_VIEW_DETAILS_`)) return '75px';
    return '25px';
  };

  const handleCheckMembers: CheckboxProps['onChange'] = (e) => {
    if (e.target.checked) {
      setCheckedStatus((prev) => ({
        ...prev,
        currentCheckedList: allList,
      }));
    } else {
      setCheckedStatus((prev) => ({
        ...prev,
        currentCheckedList: [],
      }));
    }
  };

  /**
   * this useEffect will handle setting the indeterminate status of the parent checkboxes
   * while performing a check or uncheck operation on the checkboxes
   */
  useEffect(() => {
    setCheckedStatus((prev) => ({
      ...prev,
      indeterminate: {
        all: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          listToCompare: allList,
        }),
        MEMBERS_MEMBER_MANAGEMENT: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberManagementHint,
          listToCompare: membersManagementList,
        }),
        MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: `${memberManagementHint}_VIEW_DETAILS`,
          key2: `${memberManagementHint}_REPORT`,
          listToCompare: memberViewDetailsManagementList,
        }),
        MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: `${memberManagementHint}_EDIT_BALANCE`,
          listToCompare: memberEditBalanceManagementList,
        }),
        MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_ADD_BALANCE: handleIsIndeterminateParent(
          {
            currentList: prev.currentCheckedList,
            key: `${memberManagementHint}_EDIT_BALANCE_ADD_BALANCE`,
            listToCompare: memberEditBalanceAddList,
          }
        ),
        MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_REMOVE_BALANCE: handleIsIndeterminateParent(
          {
            currentList: prev.currentCheckedList,
            key: `${memberManagementHint}_EDIT_BALANCE_REMOVE_BALANCE`,
            listToCompare: memberEditBalanceRemoveList,
          }
        ),

        MEMBERS_MEMBERS_ONLINE_ONLY: false,
        MEMBERS_MANAGEMENT_CONFIG: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberConfigManagementHint,
          listToCompare: memberConfigsPermission,
        }),
        MEMBERS_AUTOMATION: false,
        MEMBERS_REPORTS: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberReportsManagementHint,
          listToCompare: memberReportsManagementPermissions,
        }),
        MEMBERS_MEMBER_BULK_UPDATE: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberBulkUpdateManagementHint,
          listToCompare: memberBulkUpdateManagementPermissions,
        }),
        MEMBERS_MEMBER_MANAGEMENT_LIST: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberManagementHint,
          listToCompare: membersManagementList,
        }),
        MEMBERS_MEMBER_MARKER_MANAGEMENT_LIST: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberMarkerManagementHint,
          listToCompare: memberMarkerManagementPermissions,
        }),
        MEMBERS_MEMBER_MARKER_MANAGEMENT_VIEW_DETAILS: handleIsIndeterminateParent(
          {
            currentList: prev.currentCheckedList,
            key: memberMarkerManagementHint,
            listToCompare: memberMarkerManagementPermissions,
          }
        ),
        MEMBERS_MEMBER_MARKER_MANAGEMENT: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberMarkerManagementHint,
          listToCompare: memberMarkerManagementPermissions,
        }),
        MEMBERS_LABEL_MANAGEMENT_LIST: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberLabelManagementHint,
          listToCompare: memberLabelManagementPermissions,
        }),
        MEMBERS_LABEL_MANAGEMENT: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberLabelManagementHint,
          listToCompare: memberLabelManagementPermissions,
        }),
        MEMBERS_LABEL_MANAGEMENT_VIEW_DETAILS: handleIsIndeterminateParent({
          currentList: prev.currentCheckedList,
          key: memberLabelManagementHint,
          listToCompare: memberLabelManagementPermissions,
        }),
        MEMBERS_AUTOMATION_LIST: false,
      },
    }));
  }, [checkedStatus.currentCheckedList]);

  /**
   * this useEffect will handle setting the available permissions coming from the api to the checkedStatus.currentList and also
   * set the indeterminate status of the parent checkboxes
   */
  useEffect(() => {
    if (availablePermissions.length >= 1) {
      const existingPermissionToKeyValue = allList.filter((item) =>
        availablePermissions.includes(item.value)
      );
      setCheckedStatus({
        indeterminate: {
          all: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            listToCompare: allList,
          }),
          MEMBERS_MEMBER_MANAGEMENT: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberManagementHint,
            listToCompare: membersManagementList,
          }),
          MEMBERS_MEMBER_MANAGEMENT_LIST: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberManagementHint,
            listToCompare: membersManagementList,
          }),
          MEMBERS_MEMBER_MARKER_MANAGEMENT: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberMarkerManagementHint,
            listToCompare: memberMarkerManagementPermissions,
          }),
          MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: `${memberManagementHint}_VIEW_DETAILS`,
            key2: `${memberManagementHint}_REPORT`,
            listToCompare: memberViewDetailsManagementList,
          }),
          MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: `${memberManagementHint}_EDIT_BALANCE`,
            listToCompare: memberEditBalanceManagementList,
          }),
          MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_ADD_BALANCE: handleIsIndeterminateParent(
            {
              currentList: existingPermissionToKeyValue,
              key: `${memberManagementHint}_EDIT_BALANCE_ADD_BALANCE`,
              listToCompare: memberEditBalanceAddList,
            }
          ),
          MEMBERS_MEMBER_MANAGEMENT_EDIT_BALANCE_REMOVE_BALANCE: handleIsIndeterminateParent(
            {
              currentList: existingPermissionToKeyValue,
              key: `${memberManagementHint}_EDIT_BALANCE_REMOVE_BALANCE`,
              listToCompare: memberEditBalanceRemoveList,
            }
          ),
          MEMBERS_LABEL_MANAGEMENT: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberLabelManagementHint,
            listToCompare: memberLabelManagementPermissions,
          }),
          MEMBERS_LABEL_MANAGEMENT_LIST: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberLabelManagementHint,
            listToCompare: memberLabelManagementPermissions,
          }),
          MEMBERS_LABEL_MANAGEMENT_VIEW_DETAILS: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberLabelManagementHint,
            listToCompare: memberLabelManagementPermissions,
          }),
          MEMBERS_MEMBERS_ONLINE_ONLY: false,
          MEMBERS_MANAGEMENT_CONFIG: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberConfigManagementHint,
            listToCompare: memberConfigsPermission,
          }),
          MEMBERS_AUTOMATION: false,
          MEMBERS_REPORTS: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberReportsManagementHint,
            listToCompare: memberReportsManagementPermissions,
          }),
          MEMBERS_MEMBER_BULK_UPDATE: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberBulkUpdateManagementHint,
            listToCompare: memberBulkUpdateManagementPermissions,
          }),
          MEMBERS_MEMBER_MARKER_MANAGEMENT_LIST: handleIsIndeterminateParent({
            currentList: existingPermissionToKeyValue,
            key: memberMarkerManagementHint,
            listToCompare: memberMarkerManagementPermissions,
          }),
          MEMBERS_AUTOMATION_LIST: false,
        },
        currentCheckedList: existingPermissionToKeyValue,
      });
    }
  }, [availablePermissions]);

  useEffect(() => {
    setFieldValue(
      'permissions.ALL_MEMBERS',
      checkedStatus.currentCheckedList.map((item) => item.value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedStatus.currentCheckedList.length]);

  return (
    <StyledRootDiv>
      <StyledParentDiv>
        <Row>
          <Col span={24}>
            <Checkbox
              indeterminate={checkedStatus.indeterminate.all}
              onChange={handleCheckMembers}
              checked={checkedStatus.currentCheckedList?.some(
                (item) => item.key === 'MEMBERS'
              )}
              value={{ key: 'MEMBERS', value: 'MEMBERS' }}
            >
              <StyledText size={16}>
                {translate(messages[ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS])}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <div className="w-100">
          <div className="mt-4 d-flex ml-3">
            <StyledDiv className="">
              {memberManagement.map(({ key, value }) =>
                key === `${memberManagementHint}_EDIT_BALANCE` ? (
                  <StyledDiv marginLeft="25px" className="mt-2">
                    <Checkbox
                      checked={checkedStatus.currentCheckedList?.some(
                        (item) => item.key === key
                      )}
                      value={{ key, value }}
                      onChange={(e) =>
                        onChangeCheck(
                          e,
                          setCheckedStatus,
                          checkedStatus.currentCheckedList
                        )
                      }
                    >
                      <StyledText size={16}>
                        {translate(
                          messages[key.replace(`${memberManagementHint}_`, '')]
                        )}
                      </StyledText>
                    </Checkbox>
                    {memberEditBalanceAddList.map((item) => (
                      <StyledDiv
                        className={`${
                          ![
                            `${memberManagementHint}_EDIT_BALANCE_ADD_BALANCE`,
                          ].includes(item.key)
                            ? 'ml-5'
                            : 'ml-4'
                        } mt-2`}
                      >
                        <Checkbox
                          key={item.key}
                          value={{ key: item.key, value: item.value }}
                          indeterminate={checkedStatus.indeterminate[item.key]}
                          checked={checkedStatus.currentCheckedList?.some(
                            (sub) => sub.key === item.key
                          )}
                          onChange={(e) =>
                            onChangeCheck(
                              e,
                              setCheckedStatus,
                              checkedStatus.currentCheckedList
                            )
                          }
                        >
                          <StyledText size={16}>
                            {translate(
                              messages[
                                item.key.replace(
                                  `${memberManagementHint}_EDIT_BALANCE_`,
                                  ''
                                )
                              ]
                            )}
                          </StyledText>
                        </Checkbox>
                      </StyledDiv>
                    ))}
                    {memberEditBalanceRemoveList.map((item) => (
                      <StyledDiv
                        className={`${
                          ![
                            `${memberManagementHint}_EDIT_BALANCE_REMOVE_BALANCE`,
                          ].includes(item.key)
                            ? 'ml-5'
                            : 'ml-4'
                        } mt-2`}
                      >
                        <Checkbox
                          key={item.key}
                          value={{ key: item.key, value: item.value }}
                          indeterminate={checkedStatus.indeterminate[item.key]}
                          checked={checkedStatus.currentCheckedList?.some(
                            (sub) => sub.key === item.key
                          )}
                          onChange={(e) =>
                            onChangeCheck(
                              e,
                              setCheckedStatus,
                              checkedStatus.currentCheckedList
                            )
                          }
                        >
                          <StyledText size={16}>
                            {translate(
                              messages[
                                item.key.replace(
                                  `${memberManagementHint}_EDIT_BALANCE_`,
                                  ''
                                )
                              ]
                            )}
                          </StyledText>
                        </Checkbox>
                      </StyledDiv>
                    ))}
                  </StyledDiv>
                ) : (
                  <StyledDiv
                    marginLeft={handleMemberViewDetailsIndent(
                      key,
                      'MEMBERS_MEMBER_MANAGEMENT'
                    )}
                    className="mt-2"
                  >
                    <Checkbox
                      value={{ key, value }}
                      checked={checkedStatus.currentCheckedList?.some(
                        (item) => item.key === key
                      )}
                      indeterminate={checkedStatus.indeterminate[key]}
                      onChange={(e) =>
                        onChangeCheck(
                          e,
                          setCheckedStatus,
                          checkedStatus.currentCheckedList
                        )
                      }
                    >
                      <StyledText size={16}>
                        {handleTranslateText(key, memberManagementHint)}
                      </StyledText>
                    </Checkbox>
                  </StyledDiv>
                )
              )}
            </StyledDiv>
            <StyledDiv>
              {memberMarkerManagementPermissions.map(({ key, value }) => (
                <StyledDiv
                  marginLeft={handleMemberViewDetailsIndent(
                    key,
                    'MEMBERS_MEMBER_MARKER_MANAGEMENT'
                  )}
                >
                  <Checkbox
                    key={key}
                    value={{ key, value }}
                    className="mt-2"
                    indeterminate={checkedStatus.indeterminate[key]}
                    checked={checkedStatus.currentCheckedList.some(
                      (item) => item.key === key
                    )}
                    onChange={(e) =>
                      onChangeCheck(
                        e,
                        setCheckedStatus,
                        checkedStatus.currentCheckedList
                      )
                    }
                  >
                    <StyledText size={16}>
                      {handleTranslateText(key, memberMarkerManagementHint)}
                    </StyledText>
                  </Checkbox>
                </StyledDiv>
              ))}
            </StyledDiv>
            <StyledDiv>
              {memberLabelManagementPermissions.map(({ key, value }) => (
                <StyledDiv
                  marginLeft={handleMemberViewDetailsIndent(
                    key,
                    'MEMBERS_LABEL_MANAGEMENT'
                  )}
                >
                  <Checkbox
                    key={key}
                    value={{ key, value }}
                    className="mt-2"
                    checked={checkedStatus.currentCheckedList.some(
                      (item) => item.key === key
                    )}
                    indeterminate={checkedStatus.indeterminate[key]}
                    onChange={(e) =>
                      onChangeCheck(
                        e,
                        setCheckedStatus,
                        checkedStatus.currentCheckedList
                      )
                    }
                  >
                    <StyledText size={16}>
                      {handleTranslateText(key, memberLabelManagementHint)}
                    </StyledText>
                  </Checkbox>
                </StyledDiv>
              ))}
            </StyledDiv>
            <StyledDiv>
              {membersOnlineOnlyPermission.map(({ key, value }) => (
                <StyledDiv className="ml-4">
                  <Checkbox
                    key={key}
                    value={{ key, value }}
                    className="mt-2"
                    checked={checkedStatus.currentCheckedList.some(
                      (item) => item.key === key
                    )}
                    onChange={(e) =>
                      onChangeCheck(
                        e,
                        setCheckedStatus,
                        checkedStatus.currentCheckedList
                      )
                    }
                  >
                    <StyledText size={16}>
                      {handleTranslateText(key, 'MEMBERS_MEMBERS_ONLINE_ONLY')}
                    </StyledText>
                  </Checkbox>
                </StyledDiv>
              ))}
            </StyledDiv>
            <StyledDiv>
              {memberBulkUpdateManagementPermissions.map(({ key, value }) => (
                <StyledDiv
                  marginLeft={handleMemberViewDetailsIndent(
                    key,
                    'MEMBERS_MEMBER_BULK_UPDATE'
                  )}
                >
                  <Checkbox
                    key={key}
                    value={{ key, value }}
                    className="mt-2"
                    checked={checkedStatus.currentCheckedList.some(
                      (item) => item.key === key
                    )}
                    indeterminate={checkedStatus.indeterminate[key]}
                    onChange={(e) =>
                      onChangeCheck(
                        e,
                        setCheckedStatus,
                        checkedStatus.currentCheckedList
                      )
                    }
                  >
                    <StyledText size={16}>
                      {handleTranslateText(key, memberBulkUpdateManagementHint)}
                    </StyledText>
                  </Checkbox>
                </StyledDiv>
              ))}
            </StyledDiv>
            <StyledDiv>
              {memberReportsManagementPermissions.map(({ key, value }) => (
                <StyledDiv
                  marginLeft={key.endsWith('MEMBERS_REPORTS') ? '0px' : '25px'}
                >
                  <Checkbox
                    key={key}
                    value={{ key, value }}
                    className="mt-2"
                    checked={checkedStatus.currentCheckedList.some(
                      (item) => item.key === key
                    )}
                    indeterminate={checkedStatus.indeterminate[key]}
                    onChange={(e) =>
                      onChangeCheck(
                        e,
                        setCheckedStatus,
                        checkedStatus.currentCheckedList
                      )
                    }
                  >
                    <StyledText size={16}>
                      {handleTranslateText(key, memberBulkUpdateManagementHint)}
                    </StyledText>
                  </Checkbox>
                </StyledDiv>
              ))}
            </StyledDiv>
            <StyledDiv>
              {memberConfigsPermission.map(({ key, value }) => (
                <StyledDiv
                  marginLeft={
                    key.endsWith('MEMBERS_MANAGEMENT_CONFIG') ? '0px' : '25px'
                  }
                >
                  <Checkbox
                    key={key}
                    value={{ key, value }}
                    className="mt-2"
                    indeterminate={checkedStatus.indeterminate[key]}
                    checked={checkedStatus.currentCheckedList.some(
                      (item) => item.key === key
                    )}
                    onChange={(e) =>
                      onChangeCheck(
                        e,
                        setCheckedStatus,
                        checkedStatus.currentCheckedList
                      )
                    }
                  >
                    <StyledText size={16}>
                      {handleTranslateText(key, memberBulkUpdateManagementHint)}
                    </StyledText>
                  </Checkbox>
                </StyledDiv>
              ))}
            </StyledDiv>
          </div>
        </div>
      </StyledParentDiv>
    </StyledRootDiv>
  );
};

export default MembersTreeRevamp;
