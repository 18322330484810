import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const values = [10, 20, 50, 100];

type Props = {
  first: number;
  setPage: (count?: number) => void;
};

const TableFooterPages = ({ first, setPage }: Props) => (
  <Select
    onChange={setPage}
    className="ml-3"
    defaultValue={first}
    value={first}
  >
    {values.map((value) => (
      <Select.Option value={value} key={value}>
        {value} <FormattedMessage id="entries.text" defaultMessage="Entries" />
      </Select.Option>
    ))}
  </Select>
);

export default TableFooterPages;
