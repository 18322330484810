import React from 'react';
import { Checkbox } from 'antd';
import useTranslate from 'utils/useTranslate';
import { PAYMENT_TYPES_WITHDRAWAL } from 'constants/paymentTypes';
import messages from 'pages/components/MemberWithdrawals/messages';

const { OFFLINE, ONLINE } = PAYMENT_TYPES_WITHDRAWAL;

const INTERNAL = 'INTERNAL';
const EXTERNAL = 'EXTERNAL';

type Props = {
  value: string[];
  onChange: (e: string[]) => {};
};

const PaymentSourceFilter = ({ value, onChange }: Props) => {
  const translate = useTranslate();

  const handleSourceChange = (e: any) => {
    const filterList = [
      ...(e.includes(INTERNAL) ? OFFLINE : []),
      ...(e.includes(EXTERNAL) ? ONLINE : []),
    ];

    onChange(filterList);
  };

  const paymentSource = [
    ...(value.includes(OFFLINE[0]) ? [INTERNAL] : []),
    ...(value.includes(ONLINE[0]) ? [EXTERNAL] : []),
  ];

  return (
    <Checkbox.Group onChange={handleSourceChange} value={paymentSource}>
      <Checkbox className="mt-1" value={INTERNAL}>
        {translate(messages.internal)}
      </Checkbox>
      <br />
      <Checkbox className="mt-1" value={EXTERNAL}>
        {translate(messages.thirdParty)}
      </Checkbox>
    </Checkbox.Group>
  );
};

export default PaymentSourceFilter;
