import React from 'react';
import { ErrorHandler } from 'components/ErrorHandler';
import DocumentManagement from 'pages/components/NewMemberDetail/components/DocumentManagement';
import MemberDepositLimit from 'pages/components/NewMemberDetail/components/MemberDepositLimit';
import MemberRealityCheck from 'pages/components/NewMemberDetail/components/MemberRealityCheck';
import MemberTimeOut from 'pages/components/NewMemberDetail/components/MemberTimeOut';
import MemberSelfExclusion from 'pages/components/NewMemberDetail/components/MemberSelfExclusion';

import { useNewMemberDetailPermissions } from '../../constants';
import { StyledContent } from '../../styles';
import { Header } from '../Header/Header';
import { Balances } from '../Balances/Balances';
import { DepositAndWithdrawal } from '../DepositAndWithdrawal/DepositAndWithdrawal';
import { MemberNotes } from '../MemberNotes/MemberNotes';
import MemberInfo from './components/MemberInfo';
import { PromoAndGames } from '../PromoAndGames/PromoAndGames';
import { BalancesProvider } from '../Balances/context';
import MemberInteraction from '../MemberInteraction';
import AccountRestriction from '../AccountRestriction';

export const MemberDetailContent = () => {
  const {
    BALANCES,
    ALLOWED_BALANCES,
    DEPOSIT_AND_WITHDRAWALS,
    ALLOWED_DEPOSIT_AND_WITHDRAWALS,
    PROMO_AND_GAMES,
    ALLOWED_PROMO_AND_GAMES,
    ALLOWED_MEMBER_NOTES,
    MEMBER_INFO,
    ALLOWED_MEMBER_INFO,
    ALLOWED_MEMBER_INTERACTION_LOGS_REPORT,
    ALLOWED_ACCOUNT_RESTRICTION,
  } = useNewMemberDetailPermissions();

  const ALLOWED_LEFT_SECTION =
    ALLOWED_BALANCES ||
    ALLOWED_DEPOSIT_AND_WITHDRAWALS ||
    ALLOWED_PROMO_AND_GAMES ||
    ALLOWED_MEMBER_NOTES;

  return (
    <>
      <Header />
      <StyledContent
        bgColor="#f3f8fb"
        style={{ height: 'calc(100vh - 40px - 40px - 84px)', overflow: 'auto' }}
      >
        <div
          style={{ paddingTop: '16px', paddingBottom: '16px' }}
          className="px-3"
        >
          <div className="d-flex justify-content-center">
            {ALLOWED_LEFT_SECTION && (
              <div style={{ width: '616px', marginRight: '8px' }}>
                {ALLOWED_BALANCES && (
                  <BalancesProvider>
                    <Balances permissions={BALANCES} />
                  </BalancesProvider>
                )}
                {ALLOWED_DEPOSIT_AND_WITHDRAWALS && (
                  <DepositAndWithdrawal permissions={DEPOSIT_AND_WITHDRAWALS} />
                )}
                {ALLOWED_PROMO_AND_GAMES && (
                  <PromoAndGames permissions={PROMO_AND_GAMES} />
                )}
                {ALLOWED_MEMBER_NOTES && (
                  <ErrorHandler>
                    <MemberNotes />
                  </ErrorHandler>
                )}
                <MemberInteraction
                  hasPermission={ALLOWED_MEMBER_INTERACTION_LOGS_REPORT}
                />
                <DocumentManagement />
                {ALLOWED_ACCOUNT_RESTRICTION && <AccountRestriction />}

                <MemberRealityCheck />
                <MemberDepositLimit />
                <MemberSelfExclusion />
                <MemberTimeOut />
              </div>
            )}
            {ALLOWED_MEMBER_INFO && (
              <div style={{ width: '320px' }}>
                <MemberInfo permissions={MEMBER_INFO} />
              </div>
            )}
          </div>
        </div>
      </StyledContent>
    </>
  );
};
