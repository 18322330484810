import React from 'react';
import setTitleCase from 'utils/setTitleCase';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge } from 'antd';
import { upperCase, isBoolean } from 'lodash';

const color = {
  ACTIVE: 'green',
  INACTIVE: 'GRAY',
};

const displayIntlText = (text: string, locale: string) => {
  if (locale === 'en') return setTitleCase(text);

  if (locale === 'zh') {
    switch (text.toUpperCase()) {
      case 'ACTIVE':
        return <FormattedMessage id="active.text" defaultMessage="Active" />;
      case 'INACTIVE':
        return (
          <FormattedMessage id="inactive.text" defaultMessage="Inactive" />
        );
      default:
        return (
          <FormattedMessage
            id="unknown-status.text"
            defaultMessage="Unknown Status"
          />
        );
    }
  }

  return (
    <FormattedMessage
      id="unknown-status.text"
      defaultMessage="Unknown Status"
    />
  );
};

const StatusBadge = ({ text }: { text: boolean | string }) => {
  const { locale } = useIntl();

  if (isBoolean(text)) {
    return (
      <Badge
        color={color[text ? 'ACTIVE' : 'INACTIVE']}
        text={displayIntlText(text ? 'ACTIVE' : 'INACTIVE', locale)}
      />
    );
  }

  return (
    <Badge
      color={color[upperCase(text)]}
      text={displayIntlText(text, locale)}
    />
  );
};

export default StatusBadge;
