import { useMutation, useQuery } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import { UPDATE_GAME_CATEGORY } from 'graphql/mutations/vendorConfiguration.mutation';
import {
  GAME_CATEGORIES,
  GAME_CATEGORY,
} from 'graphql/queries/vendorConfiguration.query';
import messages from 'messages';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { GameCategory, Vendor } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import Drawer from '../../../common/Drawer/Drawer';
import AddEditVendorDrawer from '../AddVendorDrawer';
import UploadVendorImage from '../UploadVendorImage/UploadVendorImage';

const VENDOR_DRAWER = 'VENDOR_DRAWER';
const UPLOAD_IMAGE_DRAWER = 'UPLOAD_IMAGE_DRAWER';

type Props = {
  closeDrawer: () => void;
  drawerStatus: boolean;
  selectedGameCategory: GameCategory | null;
  formItemLayout: object;
};

export const CustomDrawer: React.FC<Props> = ({
  closeDrawer,
  drawerStatus,
  selectedGameCategory,
  formItemLayout,
}) => {
  const translate = useTranslate();
  const { locale } = useIntl();

  const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([]);
  const [activeDrawer, setActiveDrawer] = useState(VENDOR_DRAWER);

  const [updateGameCategory] = useMutation(UPDATE_GAME_CATEGORY, {
    onCompleted: () =>
      message.success(translate(messages.GAME_CATEGORY_UPDATED)),
    onError: () => message.error(translate(messages.INTERNAL_SERVER_ERROR)),
  });

  const { loading } = useQuery(GAME_CATEGORY, {
    variables: {
      language: locale.toLocaleUpperCase(),
      id: selectedGameCategory?.id,
    },
    fetchPolicy: 'network-only',
    onCompleted: (dataGameCateg) => {
      const vendorsList = coercedGet(dataGameCateg, 'gameCategory.vendors', []);

      setSelectedVendors(vendorsList);
    },
  });

  const onToVendorDrawer = () => setActiveDrawer(VENDOR_DRAWER);

  const onToUploadImage = () => setActiveDrawer(UPLOAD_IMAGE_DRAWER);

  const saveGameCategory = async (withExit = false) => {
    await updateGameCategory({
      variables: {
        id: selectedGameCategory?.id,
        input: {
          vendors: selectedVendors.map(({ id }) => id),
        },
      },
      refetchQueries: [{ query: GAME_CATEGORIES }],
    });

    if (withExit) {
      closeDrawer();
    }
  };

  return (
    <Spin spinning={loading}>
      <Drawer onClose={closeDrawer} open={drawerStatus}>
        {activeDrawer === VENDOR_DRAWER && (
          <AddEditVendorDrawer
            saveGameCategory={saveGameCategory}
            gameCategory={selectedGameCategory}
            closeDrawer={closeDrawer}
            selectedVendors={selectedVendors}
            onSetSelectedVendors={setSelectedVendors}
            onToAvailableLayout={onToUploadImage}
            formItemLayout={formItemLayout}
          />
        )}

        {activeDrawer === UPLOAD_IMAGE_DRAWER && (
          <UploadVendorImage
            saveGameCategory={saveGameCategory}
            onBackToAddVendorDrawer={onToVendorDrawer}
            selectedVendors={selectedVendors}
            gameCategory={selectedGameCategory}
            formItemLayout={formItemLayout}
          />
        )}
      </Drawer>
    </Spin>
  );
};
