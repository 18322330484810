import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import { NullableType } from 'interfaces/graphql.interface';
import globalMessages from 'messages';
import React from 'react';
import { CustomUploadDragger } from 'components/Uploader/CustomUploadDragger';
import { UseFormikErrorMessage } from 'components/UseFormikErrorMessage/UseFormikErrorMessage';
import { getBase64 } from 'utils/imageUtil';
import useTranslate from 'utils/useTranslate';
import messages from './messages';

type Props = {
  formItemLayout: any;
  existingCategory: any;
  setFieldValue: (type: string, id: NullableType<string>) => void;
  formik: any;
  type:
    | 'icon'
    | 'heroBanner'
    | 'banner'
    | 'desktopIcon'
    | 'mascotHorizontal'
    | 'backgroundHorizontal';
  fileTypes: string[];
  fileTypeText: string;
  imageRestrictionDimensions?: { width: number; height: number };
  labelTranslate: string;
  exactSize?: boolean;
  ignoreValidation?: boolean;
};

export const UploadImageForm = ({
  formItemLayout,
  existingCategory,
  setFieldValue,
  formik,
  type,
  fileTypes,
  fileTypeText,
  imageRestrictionDimensions,
  labelTranslate,
  exactSize,
  ignoreValidation,
}: Props) => {
  const translate = useTranslate();
  const [fileList, setFileList] = React.useState<NullableType<string>>('');
  const [existingImage, setExistingImage] = React.useState<any>(null);
  const imgRef = React.useRef<any>(null);

  const [uploadFile] = useMutation(UPLOAD_FILE);

  const beforeUpload = (file: File) => {
    const validFileType = fileTypes.find(
      (acceptedFileType) => acceptedFileType === file.type
    );

    if (!validFileType) {
      message.error(translate(globalMessages['wrong-file-type.text']));
      return false;
    }

    getBase64(file, (imgBase64: any) => {
      imgRef.current.src = imgBase64;
      imgRef.current.onload = () => {
        const { naturalHeight, naturalWidth } = imgRef.current;
        const { height = 0, width = 0 } = imageRestrictionDimensions || {};
        const onFileUpload = () => {
          uploadFile({
            variables: {
              file,
            },
          }).then((resp) => {
            const { data } = resp;
            setFieldValue(type, data.uploadFile.id);
            setFileList(file.name);
            setExistingImage(null);
          });
        };

        if (exactSize) {
          if (
            !ignoreValidation &&
            (naturalHeight !== height || naturalWidth !== width)
          ) {
            message.error(
              translate(globalMessages.IMAGE_UPLOAD_ERROR_EXACT_SIZE, {
                size: `${width}px x ${height}px`,
              })
            );
            return;
          }

          onFileUpload();
          return;
        }

        if (
          !ignoreValidation &&
          (naturalHeight < height || naturalWidth < width)
        ) {
          message.error(
            translate(
              globalMessages['image-dimension-upload-error-minimum.text'],
              {
                size: `${width}px x ${height}px`,
              }
            )
          );
        } else {
          onFileUpload();
        }
      };
    });

    return false;
  };

  const existingFileTranslation = () => {
    switch (type) {
      case 'icon':
        return messages.EXISTING_ICON;
      case 'banner':
        return messages.EXISTING_BANNER;
      case 'heroBanner':
        return messages.EXISTING_HERO_IMAGE;
      case 'desktopIcon':
        return messages.EXISTING_DESKTOP_ICON;
      case 'mascotHorizontal':
        return messages.EXISTING_MASCOT_IMAGE;
      case 'backgroundHorizontal':
        return messages.EXISTING_BACKGROUND_IMAGE;
      default:
        return { id: 'error.text', defaultMessage: 'Error' };
    }
  };

  React.useEffect(() => {
    if (existingCategory) {
      setExistingImage(existingCategory[`${type}`]);
      setFieldValue(type, existingCategory[`${type}`]?.id || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingCategory]);

  return (
    <Form.Item label={translate(messages[labelTranslate])} {...formItemLayout}>
      {existingImage && (
        <div>
          <a href={existingImage.uri} target="_blank" rel="noopener noreferrer">
            {translate(existingFileTranslation())}
          </a>
          <CloseOutlined
            className="ml-2 text-danger cursor-pointer"
            onClick={() => {
              setExistingImage(null);
              setFieldValue(type, '');
            }}
          />
        </div>
      )}

      <CustomUploadDragger
        fileList={fileList}
        beforeUpload={beforeUpload}
        acceptedFileTypes={fileTypes}
        fileTypesText={fileTypeText}
        imageDimensions={imageRestrictionDimensions}
        imgRef={imgRef}
        onCloseFileList={() => {
          setFileList(null);
          setFieldValue(type, '');
        }}
      />

      <div>
        <UseFormikErrorMessage formik={formik} name={type} />
      </div>
    </Form.Item>
  );
};
