import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import {
  ContainerStyled,
  BubbleContainerStyled,
  BubbleStyled,
} from 'styles/StyledRemark';
import Remark from 'components/Remark';

const Tooltip: any = AntdTooltip;

const RemarksBubble = ({
  remarks,
  date,
  toolTipId,
}: {
  remarks: string;
  date: string;
  toolTipId: string;
}) => (
  <Tooltip
    placement="bottom"
    title={<Remark remarks={remarks} date={date} />}
    {...(toolTipId && {
      getPopupContainer: () => document.getElementById(toolTipId),
    })}
  >
    <ContainerStyled data-testid="remark-bubble">
      <div id={toolTipId}>
        <BubbleContainerStyled>
          <BubbleStyled>!</BubbleStyled>
        </BubbleContainerStyled>
      </div>
    </ContainerStyled>
  </Tooltip>
);

export default RemarksBubble;
