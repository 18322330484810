import { customFormatMessage } from 'utils/customFormatMessage';
import messages from './messages';

export const allColumns = (formatMessage: any) => {
  const translate = (messageVal: any) =>
    customFormatMessage(formatMessage, messageVal);

  return [
    {
      name: 'icon',
      label: translate(messages.firstWithdrawal),
      disabled: true,
    },
    {
      name: 'serialCode',
      label: translate(messages.serialCode),
      disabled: true,
    },
    {
      name: 'affiliateIndicator',
      label: translate(messages.agentAffiliate),
    },
    {
      name: 'account',
      label: translate(messages.memberAccount),
    },
    {
      name: 'vipLevel',
      label: translate(messages.memberVipLevel),
    },
    {
      name: 'memberMarker',
      label: translate(messages.memberMarker),
    },
    {
      name: 'feeDeduction',
      label: translate(messages.feeDeduction),
    },
    {
      name: 'complianceCheck',
      label: translate(messages.complianceCheck),
    },
    {
      name: 'payoutAmount',
      label: translate(messages.withdrawalAmount),
    },
    {
      name: 'status',
      label: translate(messages.status),
    },
    {
      name: 'remarks',
      label: translate(messages.remarks),
    },
    {
      name: 'processingTime',
      label: translate(messages.ELAPSED_TIME),
    },
    {
      name: 'requestDate',
      label: translate(messages.requestDate),
    },
    {
      name: 'actions',
      label: translate(messages.actions),
    },
  ];
};

export const defaultPaymentSource = {
  setValues: (values = {}) => {
    // Note: The values should have the these keys "withdrawalMethodId" and "paymentSourceType",
    if (typeof values === 'object') {
      const storeDefaultValues = JSON.stringify(values);
      sessionStorage.setItem('lastPaymentSourceValues', storeDefaultValues);
    }
  },
  getValues: () => {
    try {
      // This sets up the default withdrawal method and payment source type based from the last approval
      let defaultValues: any = sessionStorage.getItem(
        'lastPaymentSourceValues'
      );
      defaultValues = JSON.parse(defaultValues);
      return defaultValues;
    } catch (err) {
      // Catches the error if the string is not in JSON format
      return {};
    }
  },
};
