import { LoadingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, Modal, Spin, Switch, Typography, Upload, message } from 'antd';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import React, { useState } from 'react';
import { File } from 'types/graphqlTypes';

const ManageLocalization = () => {
  const [visible, setVisible] = useState(false);
  const [enable, setEnable] = useState(false);

  const uploadedFileInit = {
    id: '',
    fileName: '',
    uri: '',
    uploadComplete: false,
  };

  const [uploadedFile, setUploadedFile] = useState(uploadedFileInit);

  const dummyRequest = ({ onSuccess }: { onSuccess: (e: string) => void }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const extractFileName = (url: string) => {
    const parts = url.split('/');
    const fileName = parts[parts.length - 1];
    return decodeURIComponent(fileName);
  };

  const [uploadFile, { loading: uploadLoading }] = useMutation<{
    uploadFile: File;
  }>(UPLOAD_FILE, {
    onCompleted: (data) => {
      setUploadedFile((prev) => ({
        ...prev,
        id: data.uploadFile.id,
        fileName: extractFileName(data.uploadFile.uri),
        uri: data.uploadFile.uri,
        uploadComplete: true,
      }));

      message.success('File Successfully Uploaded.');
    },
  });

  const handleModalClose = () => {
    setVisible((prev) => !prev);
    setUploadedFile(uploadedFileInit);
  };

  const uploadProps: any = {
    customRequest: dummyRequest,
    beforeUpload: async (file: File) => {
      // set file validation here

      try {
        await uploadFile({
          variables: {
            file,
          },
        });
      } catch (error) {
        // temp error message
        message.error('error');
      }
    },
  };

  return (
    <div>
      <h3>Manage Localization</h3>
      <Button onClick={() => setVisible((prev) => !prev)}>
        Manage Localization
      </Button>

      <Modal
        destroyOnClose
        centered
        title="Manage Localization"
        visible={visible}
        onCancel={handleModalClose}
        okButtonProps={{}}
        cancelButtonProps={{}}
        // use mutation func here on onOk
        onOk={handleModalClose}
        okText="Save"
        width="380px"
      >
        <div className="d-flex w-100 align-items-center mb-3">
          <Typography.Text className="mr-2">
            Enable Multi-Language Support
          </Typography.Text>
          <Switch
            size="small"
            onChange={() => setEnable((prev) => !prev)}
            checked={enable}
          />
        </div>

        {enable && (
          <div className="d-flex flex-column align-items-start">
            <Typography.Text>Upload File</Typography.Text>

            <Upload.Dragger
              {...uploadProps}
              showUploadList={false}
              name="file"
              multiple={false}
              style={{
                width: '332px',
                marginTop: '8px',
                borderRadius: '4px',
                borderStyle: 'solid',
                backgroundColor: '#fff',
                boxShadow: 'rgba(16, 24, 40, 0.05)',
              }}
            >
              <div className="d-flex flex-column align-items-center">
                {uploadLoading ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  />
                ) : (
                  <>
                    {uploadedFile.uploadComplete ? (
                      <div className="d-flex align-items-center justify-content-start w-100 px-3">
                        <img
                          src={`${process.env.PUBLIC_URL}/img/excel_file_icon.png`}
                          alt="excel_file_icon"
                        />
                        <Typography.Text className="ml-2">
                          {uploadedFile.fileName}
                        </Typography.Text>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            height: '40px',
                            width: '40px',
                            borderRadius: '8px',
                            borderColor: 'rgba(0, 0, 0, 0.2)',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            boxShadow: 'rgba(16, 24, 40, 0.05)',
                            padding: '16px 0',
                          }}
                          className="d-flex align-items-center justify-content-center mb-1"
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/img/upload_icon.png`}
                            alt="upload_icon"
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <Typography.Text
                            style={{
                              color: '#475467',
                              fontSize: '14px',
                            }}
                          >
                            <span
                              style={{
                                color: '#369EF8',
                                fontWeight: 700,
                              }}
                            >
                              Click to upload
                            </span>{' '}
                            or drag and drop
                          </Typography.Text>
                          <Typography.Text
                            style={{
                              color: '#475467',
                              fontSize: '14px',
                            }}
                          >
                            .XLSX or .XLS (max. 10mb)
                          </Typography.Text>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </Upload.Dragger>
            <div className="d-flex flex-column align-items-start mt-3">
              <Typography.Text>Download Sample File</Typography.Text>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  width: '332px',
                  marginTop: '8px',
                  borderRadius: '4px',
                  borderStyle: 'solid',
                  backgroundColor: '#fff',
                  borderWidth: '1px',
                  boxShadow: 'rgba(16, 24, 40, 0.05)',
                  borderColor: 'rgba(0, 0, 0, 0.2)',
                  height: '54.8px',
                }}
              >
                <div className="d-flex align-items-center justify-content-between w-100 px-3">
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/img/excel_file_icon.png`}
                      alt="excel_file_icon"
                    />
                    {/* use excel template once available */}
                    <Typography.Text className="ml-2">
                      Sample Excel File.csv
                    </Typography.Text>
                  </div>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/download_icon.png`}
                    alt="download_icon"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ManageLocalization;
