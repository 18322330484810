import React from 'react';
import { Formik } from 'formik';
import { Input, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import validationSchema from './validationSchema';
import { StyledForm } from './styles';
import messages from './messages';

const { Item } = StyledForm;
type Props = {
  initialValues: Record<string, any>;
  onSubmit: (data: Record<string, any>) => Promise<void>;
  onClose: () => void;
  isLoading: boolean;
};

const SearchForm = ({ initialValues, onSubmit, onClose, isLoading }: Props) => {
  const translate = useTranslate();
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Item
            label={<FormattedMessage id="name.text" defaultMessage="Name" />}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
            validateStatus={errors.name && touched.name ? 'error' : ''}
            help={touched.name ? errors.name : null}
            className="mt-3"
          >
            <Input
              name="name"
              onChange={handleChange}
              value={values.name || ''}
              placeholder={translate(messages['please-enter.text'])}
            />
          </Item>
          <div className="bt-1 p-4 text-right">
            <Button className="mr-2" onClick={onClose} loading={isLoading}>
              <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
            </Button>{' '}
            <Button type="primary" htmlType="submit" loading={isLoading}>
              <FormattedMessage
                id="save-changes.text"
                defaultMessage="Save changes"
              />
            </Button>
          </div>
        </StyledForm>
      )}
    </Formik>
  );
};

export default SearchForm;
