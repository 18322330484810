import React from 'react';
import { FormattedMessage } from 'react-intl';

export const permissionTypes = {
  MEMBER_READ: 'MEMBER_READ',
  MEMBER_CREATE: 'MEMBER_CREATE',
  MEMBER_UPDATE: 'MEMBER_UPDATE',
  MEMBER_KICK: 'MEMBER_KICK',
  MEMBER_FREEZE: 'MEMBER_FREEZE',
  MEMBER_DISABLE: 'MEMBER_DISABLE',
  MEMBER_ENABLE: 'MEMBER_ENABLE',
  PERMISSION_GROUP_READ: 'PERMISSION_GROUP_READ',
  PERMISSION_GROUP_CREATE: 'PERMISSION_GROUP_CREATE',
  PERMISSION_GROUP_UPDATE: 'PERMISSION_GROUP_UPDATE',
  PERMISSION_GROUP_DELETE: 'PERMISSION_GROUP_DELETE',
  AGENT_AFFILIATE_READ: 'AGENT_AFFILIATE_READ',
  AGENT_AFFILIATE_CREATE: 'AGENT_AFFILIATE_CREATE',
  AGENT_AFFILIATE_UPDATE: 'AGENT_AFFILIATE_UPDATE',
  AGENT_AFFILIATE_DELETE: 'AGENT_AFFILIATE_DELETE',
  FINANCIAL_MANAGEMENT_READ: 'FINANCIAL_MANAGEMENT_READ',
  FINANCIAL_MANAGEMENT_CREATE: 'FINANCIAL_MANAGEMENT_CREATE',
  FINANCIAL_MANAGEMENT_UPDATE: 'FINANCIAL_MANAGEMENT_UPDATE',
  FINANCIAL_MANAGEMENT_DELETE: 'FINANCIAL_MANAGEMENT_DELETE',
  SYSTEM_MANAGEMENT_READ: 'SYSTEM_MANAGEMENT_READ',
  SYSTEM_MANAGEMENT_CREATE: 'SYSTEM_MANAGEMENT_CREATE',
  SYSTEM_MANAGEMENT_UPDATE: 'SYSTEM_MANAGEMENT_UPDATE',
  SYSTEM_MANAGEMENT_DELETE: 'SYSTEM_MANAGEMENT_DELETE',
};

const {
  MEMBER_READ,
  MEMBER_CREATE,
  MEMBER_UPDATE,
  MEMBER_KICK,
  MEMBER_FREEZE,
  MEMBER_DISABLE,
  MEMBER_ENABLE,
  PERMISSION_GROUP_READ,
  PERMISSION_GROUP_CREATE,
  PERMISSION_GROUP_UPDATE,
  PERMISSION_GROUP_DELETE,
  AGENT_AFFILIATE_READ,
  AGENT_AFFILIATE_CREATE,
  AGENT_AFFILIATE_UPDATE,
  AGENT_AFFILIATE_DELETE,
  FINANCIAL_MANAGEMENT_READ,
  FINANCIAL_MANAGEMENT_CREATE,
  FINANCIAL_MANAGEMENT_UPDATE,
  FINANCIAL_MANAGEMENT_DELETE,
  SYSTEM_MANAGEMENT_READ,
  SYSTEM_MANAGEMENT_CREATE,
  SYSTEM_MANAGEMENT_UPDATE,
  SYSTEM_MANAGEMENT_DELETE,
} = permissionTypes;

const allPermissionTypes = [
  MEMBER_READ,
  MEMBER_CREATE,
  MEMBER_UPDATE,
  MEMBER_KICK,
  MEMBER_FREEZE,
  MEMBER_DISABLE,
  MEMBER_ENABLE,
  PERMISSION_GROUP_READ,
  PERMISSION_GROUP_CREATE,
  PERMISSION_GROUP_UPDATE,
  PERMISSION_GROUP_DELETE,
];

export const allMemberPermissions = [
  MEMBER_READ,
  MEMBER_CREATE,
  MEMBER_UPDATE,
  MEMBER_KICK,
  MEMBER_FREEZE,
  MEMBER_DISABLE,
  MEMBER_ENABLE,
];

export const allPermissionGroupPermissions = [
  PERMISSION_GROUP_READ,
  PERMISSION_GROUP_CREATE,
  PERMISSION_GROUP_UPDATE,
  PERMISSION_GROUP_DELETE,
];

export const allAgentAffiliatePermissions = [
  AGENT_AFFILIATE_READ,
  AGENT_AFFILIATE_CREATE,
  AGENT_AFFILIATE_UPDATE,
  AGENT_AFFILIATE_DELETE,
];

export const allFinancialManagementPermissions = [
  FINANCIAL_MANAGEMENT_READ,
  FINANCIAL_MANAGEMENT_CREATE,
  FINANCIAL_MANAGEMENT_UPDATE,
  FINANCIAL_MANAGEMENT_DELETE,
];

export const allSystemManagementPermissions = [
  SYSTEM_MANAGEMENT_READ,
  SYSTEM_MANAGEMENT_CREATE,
  SYSTEM_MANAGEMENT_UPDATE,
  SYSTEM_MANAGEMENT_DELETE,
];

export const permissionScopes = [
  {
    value: 'MEMBER',
    label: <FormattedMessage id="member.text" defaultMessage="Member" />,
    permissions: [
      {
        value: 'MEMBER_READ',
        label: (
          <FormattedMessage
            id="list-view-members.text"
            defaultMessage="List/View Members"
          />
        ),
      },
      {
        value: 'MEMBER_CREATE',
        label: (
          <FormattedMessage
            id="create-member-accounts.text"
            defaultMessage="Create Member Accounts"
          />
        ),
      },
      {
        value: 'MEMBER_UPDATE',
        label: (
          <FormattedMessage
            id="update-member-info.text"
            defaultMessage="Update Member Information"
          />
        ),
      },
      {
        value: 'MEMBER_KICK',
        label: (
          <FormattedMessage
            id="kick-member.text"
            defaultMessage="Kick Member"
          />
        ),
      },
      {
        value: 'MEMBER_FREEZE',
        label: (
          <FormattedMessage
            id="freeze-member-account.text"
            defaultMessage="Freeze Member Account"
          />
        ),
      },
      {
        value: 'MEMBER_ENABLE',
        label: (
          <FormattedMessage
            id="enable-member-account.text"
            defaultMessage="Enable Member Account"
          />
        ),
      },
      {
        value: 'MEMBER_DISABLE',
        label: (
          <FormattedMessage
            id="disable-member-account.text"
            defaultMessage="Disable Member Account"
          />
        ),
      },
    ],
    // template: [...allMemberPermissions],
  },
  {
    value: 'PERMISSION_GROUP',
    label: (
      <FormattedMessage
        id="permission-group.text"
        defaultMessage="Permission Group"
      />
    ),
    permissions: [
      {
        value: 'PERMISSION_GROUP_READ',
        label: (
          <FormattedMessage
            id="list-view-ermissions-groups.text"
            defaultMessage="List/View Permission Groups"
          />
        ),
      },
      {
        value: 'PERMISSION_GROUP_CREATE',
        label: (
          <FormattedMessage
            id="create-permission-group.text"
            defaultMessage="Create Permission Group"
          />
        ),
      },
      {
        value: 'PERMISSION_GROUP_UPDATE',
        label: (
          <FormattedMessage
            id="update-permission-group.text"
            defaultMessage="Update Permission Group"
          />
        ),
      },
      {
        value: 'PERMISSION_GROUP_DELETE',
        label: (
          <FormattedMessage
            id="delete-permission-group.text"
            defaultMessage="Delete Permission Group"
          />
        ),
      },
    ],
    // template: [...allPermissionGroupPermissions],
  },
  {
    value: 'AGENT_AFFILIATE',
    label: (
      <FormattedMessage
        id="agent-affiliate.text"
        defaultMessage="Agent affiliate"
      />
    ),
    permissions: [
      {
        value: 'AGENT_AFFILIATE_READ',
        label: (
          <FormattedMessage
            id="list-view-agent-affiliate.text"
            defaultMessage="List/View Agent Affiliate"
          />
        ),
      },
      {
        value: 'AGENT_AFFILIATE_CREATE',
        label: (
          <FormattedMessage
            id="create-agent-affiliate.text"
            defaultMessage="Create Agent Affiliate"
          />
        ),
      },
      {
        value: 'AGENT_AFFILIATE_UPDATE',
        label: (
          <FormattedMessage
            id="update-agent-affiliate.text"
            defaultMessage="Update Agent Affiliate"
          />
        ),
      },
      {
        value: 'AGENT_AFFILIATE_DELETE',
        label: (
          <FormattedMessage
            id="delete-agent-affiliate.text"
            defaultMessage="Delete Agent Affiliate"
          />
        ),
      },
    ],
    // template: [...allPermissionGroupPermissions],
  },
  // {
  //   value: 'FINANCIAL_MANAGEMENT',
  //   label: (
  //     <FormattedMessage
  //       id="financial-management.text"
  //       defaultMessage="Financial Management"
  //     />
  //   ),
  //   permissions: [
  //     {
  //       value: 'FINANCIAL_MANAGEMENT_READ',
  //       label: (
  //         <FormattedMessage
  //           id="list-view-financial-management.text"
  //           defaultMessage="List/View Financial Management"
  //         />
  //       ),
  //     },
  //     {
  //       value: 'FINANCIAL_MANAGEMENT_CREATE',
  //       label: (
  //         <FormattedMessage
  //           id="create-financial-management.text"
  //           defaultMessage="Create Financial Management"
  //         />
  //       ),
  //     },
  //     {
  //       value: 'FINANCIAL_MANAGEMENT_UPDATE',
  //       label: (
  //         <FormattedMessage
  //           id="update-financial-management.text"
  //           defaultMessage="Update Financial Management"
  //         />
  //       ),
  //     },
  //     {
  //       value: 'FINANCIAL_MANAGEMENT_DELETE',
  //       label: (
  //         <FormattedMessage
  //           id="delete-financial-management.text"
  //           defaultMessage="Delete Financial Management"
  //         />
  //       ),
  //     },
  //   ],
  //   // template: [...allPermissionGroupPermissions],
  // },
  // {
  //   value: 'SYSTEM_MANAGEMENT',
  //   label: (
  //     <FormattedMessage
  //       id="system-management.text"
  //       defaultMessage="System Management"
  //     />
  //   ),
  //   permissions: [
  //     {
  //       value: 'SYSTEM_MANAGEMENT_READ',
  //       label: (
  //         <FormattedMessage
  //           id="list-view-system-management.text"
  //           defaultMessage="List/View System Management"
  //         />
  //       ),
  //     },
  //     {
  //       value: 'SYSTEM_MANAGEMENT_CREATE',
  //       label: (
  //         <FormattedMessage
  //           id="system-management-create.text"
  //           defaultMessage="Create System Management"
  //         />
  //       ),
  //     },
  //     {
  //       value: 'SYSTEM_MANAGEMENT_UPDATE',
  //       label: (
  //         <FormattedMessage
  //           id="update-system-management.text"
  //           defaultMessage="Update System Management"
  //         />
  //       ),
  //     },
  //     {
  //       value: 'SYSTEM_MANAGEMENT_DELETE',
  //       label: (
  //         <FormattedMessage
  //           id="delete-system-management.text"
  //           defaultMessage="Delete System Management"
  //         />
  //       ),
  //     },
  //   ],
  //   // template: [...allPermissionGroupPermissions],
  // },
];

export const getPermissionDetails = (params: Array<any>) => {
  const newPermissions: Array<any> = [];
  permissionScopes.forEach((permissionScope) => {
    permissionScope.permissions.forEach((permission) => {
      if (params.includes(permission.value)) newPermissions.push(permission);
    });
  });
  return newPermissions;
};

export const getAllPermissions = () => {
  const newPermissions: Array<any> = [];
  permissionScopes.forEach((permissionScope) => {
    permissionScope.permissions.forEach((permission) => {
      newPermissions.push(permission);
    });
  });
  return newPermissions;
};

export const permissions = getAllPermissions();

export const templates = [
  {
    value: 'noTemplate',
    label: (
      <FormattedMessage id="no-template.text" defaultMessage="No Template" />
    ),
    permissions: [],
  },
  {
    value: 'generalManager',
    label: (
      <FormattedMessage
        id="general-manager.text"
        defaultMessage="General Manager"
      />
    ),
    permissions: [...allPermissionTypes],
  },
  {
    value: 'siteSupervisor',
    label: (
      <FormattedMessage
        id="site-supervisor.text"
        defaultMessage="Site Supervisor"
      />
    ),
    permissions: [...allMemberPermissions],
  },
  {
    value: 'financeSiteSupervisor',
    label: (
      <FormattedMessage
        id="finance-supervisor.text"
        defaultMessage="Assistant Site Supervisor (Finance)"
      />
    ),
    permissions: [MEMBER_READ, MEMBER_UPDATE, MEMBER_DISABLE],
  },
  {
    value: 'csSiteSupervisor',
    label: (
      <FormattedMessage
        id="customer-service-supervisor.text"
        defaultMessage="Assistant Site Supervisor (Customer Service)"
      />
    ),
    permissions: [MEMBER_READ, MEMBER_UPDATE, MEMBER_ENABLE],
  },
  {
    value: 'affiliateOfficer',
    label: (
      <FormattedMessage
        id="affiliate-officer.text"
        defaultMessage="Affiliate Relationship Officer"
      />
    ),
    permissions: [MEMBER_FREEZE, PERMISSION_GROUP_READ, MEMBER_KICK],
  },
  {
    value: 'withdrawalStaff',
    label: (
      <FormattedMessage
        id="withdrawal-staff.text"
        defaultMessage="Withdrawal Staff"
      />
    ),
    permissions: [MEMBER_CREATE, PERMISSION_GROUP_CREATE],
  },
  {
    value: 'depositStaff',
    label: (
      <FormattedMessage
        id="deposit-staff.text"
        defaultMessage="Deposit Staff"
      />
    ),
    permissions: [MEMBER_UPDATE, MEMBER_CREATE],
  },
  {
    value: 'customerServiceStaff',
    label: (
      <FormattedMessage
        id="customer-service-staff.text"
        defaultMessage="Customer Service Staff"
      />
    ),
    permissions: [MEMBER_READ, PERMISSION_GROUP_DELETE],
  },
  {
    value: 'promoStaff',
    label: (
      <FormattedMessage
        id="promos-staff.text"
        defaultMessage="Promotion Application Processing Staff"
      />
    ),
    permissions: [MEMBER_ENABLE, PERMISSION_GROUP_CREATE],
  },
];

export default {};
