import { Checkbox, Row } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React from 'react';

type Props = {
  filters: {
    [key: string]: {
      in: string[];
    };
  };
  onChange: (e: CheckboxValueType[]) => void;
  filterEnum: Record<string, string | boolean>;
  label: string;
  filterFieldName: string;
};

export default ({
  filters,
  onChange,
  label,
  filterFieldName,
  filterEnum,
}: Props) => {
  const handleChange = (e: CheckboxValueType[]) => onChange(e);

  const values = filters?.[filterFieldName]?.in || [];

  return (
    <div className="my-2">
      <div>
        <span
          style={{
            fontWeight: 'bold',
            fontSize: 10,
          }}
        >
          {label}
        </span>
      </div>

      <Checkbox.Group onChange={handleChange} value={values}>
        {Object.keys(filterEnum).map((key, index) => (
          <Row>
            <Checkbox key={index} value={filterEnum[key]}>
              {key}
            </Checkbox>
          </Row>
        ))}
      </Checkbox.Group>
    </div>
  );
};
