import React, { useEffect, useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useScreenTabV2 } from 'store/screenTabState';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { SharedStyledTable } from 'styles/SharedStyledTable';
import { UPDATE_GAME_CATEGORY } from '../MobileAppConfiguration/mutations';
import { GAME_CATEGORY } from '../MobileAppConfiguration/queries';
import { CustomDrawer as LandscapeCustomDrawer } from '../MobileLandscapeVendorConfiguration/components/CustomDrawer/CustomDrawer';
import { CustomDrawer as PortraitCustomDrawer } from '../MobilePortraitVendorConfiguration/components/CustomDrawer/CustomDrawer';
import { CustomDrawer as DesktopCustomDrawer } from '../DesktopVendorConfiguration/components/CustomDrawer/CustomDrawer';

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const MobileAppConfigurationVendors = () => {
  const { role, permissions } = usePermissions();
  const translate = useTranslate();

  const { ALLOWED_EDIT, ALLOWED_DELETE } = collectPermissions(
    role,
    permissions,
    ['EDIT', 'DELETE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG}:CATEGORY`
  );

  const [mainDrawer, setMainDrawer] = useState(false);

  const { getTab } = useScreenTabV2();
  const { gameCategory } = getTab('mobile-app-config-vendors');

  const intl = useIntl();

  const variables = {
    language: intl.locale.toLocaleUpperCase(),
    id: gameCategory ? gameCategory.id : '',
  };

  const [fetchVendors, { loading, data }] = useLazyQuery(GAME_CATEGORY);
  const [updateGameCategory, { loading: updatingCategory }] = useMutation(
    UPDATE_GAME_CATEGORY
  );

  const vendors = coercedGet(data, 'gameCategory.vendors', []);
  const platform = coercedGet(data, 'gameCategory.platform', '');

  const onEditVendors = () => {
    setMainDrawer(true);
  };

  useEffect(() => {
    if (!gameCategory) {
      return;
    }

    fetchVendors({ variables });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameCategory]);

  const onDelete = (idToBeDeleted: string) => {
    Modal.confirm({
      title: translate(messages['delete-vendor-title.text']),
      content: translate(messages['delete-vendor-body.text'], {
        gameCategory: gameCategory.name,
      }),
      okText: translate(messages.DELETE),
      okType: 'danger',
      cancelText: translate(messages.CANCEL),
      onOk: () => {
        const remainingVendorsId = vendors
          .filter((vendor: Record<string, any>) => vendor.id !== idToBeDeleted)
          .map(({ id }: { id: string }) => id);
        updateGameCategory({
          variables: {
            id: gameCategory.id,
            input: {
              vendors: remainingVendorsId,
            },
          },
          refetchQueries: [
            {
              query: GAME_CATEGORY,
              variables,
            },
          ],
        });
      },
    });
  };

  const resetDrawerStatus = () => {
    setMainDrawer(false);
  };

  const columns = [
    {
      title: <FormattedMessage id="vendors.text" defaultMessage="Vendors" />,
      dataIndex: 'name',
      key: 'name',
      render: (vendor: string, { type }: { type: string }) =>
        `${vendor} - ${translate(messages[`${type.toLowerCase()}.text`])}`,
    },
    {
      title: (
        <FormattedMessage
          id="game-list-redirect.text"
          defaultMessage="Game List Redirect"
        />
      ),
      dataIndex: 'externalLobby',
      key: 'externalLobby',
      render: (externalLobby: boolean) =>
        translate(messages[externalLobby ? 'external.text' : 'internal.text']),
    },
    {
      title: <FormattedMessage id="actions.text" defaultMessage="Actions" />,
      dataIndex: 'actions',
      key: 'actions',
      render: (__: any, allData: Record<string, any>) =>
        (ALLOWED_EDIT || ALLOWED_DELETE) && (
          <Dropdown
            overlay={
              <Menu>
                {ALLOWED_EDIT && (
                  <Menu.Item onClick={() => onEditVendors()}>
                    <FormattedMessage id="edit.text" defaultMessage="Edit" />
                  </Menu.Item>
                )}

                {ALLOWED_DELETE && (
                  <Menu.Item onClick={() => onDelete(allData.id)}>
                    <FormattedMessage
                      id="delete.text"
                      defaultMessage="Delete"
                    />
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
          >
            <EllipsisOutlined
              className="cursor-pointer font-weight-bold"
              style={{ fontSize: '25px' }}
            />
          </Dropdown>
        ),
    },
  ];

  return (
    <>
      <div className="p-3">
        <SharedStyledTable
          loading={loading || updatingCategory}
          size="middle"
          columns={columns}
          dataSource={vendors}
          rowKey={(vendor: Record<string, any>) => vendor.id}
        />
      </div>

      {mainDrawer && platform === 'MOBILE' && (
        <PortraitCustomDrawer
          drawerStatus={mainDrawer}
          closeDrawer={resetDrawerStatus}
          selectedGameCategory={gameCategory}
          formItemLayout={formItemLayout}
        />
      )}

      {mainDrawer && platform === 'HORIZONTAL' && (
        <LandscapeCustomDrawer
          drawerStatus={mainDrawer}
          closeDrawer={resetDrawerStatus}
          selectedGameCategory={gameCategory}
          formItemLayout={formItemLayout}
        />
      )}

      {mainDrawer && platform === 'DESKTOP' && (
        <DesktopCustomDrawer
          drawerStatus={mainDrawer}
          closeDrawer={resetDrawerStatus}
          selectedGameCategory={gameCategory}
          formItemLayout={formItemLayout}
        />
      )}
    </>
  );
};

export default MobileAppConfigurationVendors;
