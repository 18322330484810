const dedup = (arraySource: Record<string, any>[]) =>
  arraySource.filter(
    (item, index) =>
      index ===
      arraySource.findIndex(
        (obj) => JSON.stringify(obj) === JSON.stringify(item)
      )
  );

export default dedup;
