import React from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import esGet from 'utils/esGet';
import { AffiliateRequestsConnectionEdge } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { AFFILIATE_REQUESTS, UPLINES } from './queries';

const { Option } = Select;

const AffiliateUplineSelect = ({
  onChange,
  value,
}: {
  value?: string;
  onChange: (e: any) => void;
}) => {
  const translate = useTranslate();
  const { data, loading } = useQuery(AFFILIATE_REQUESTS, {
    variables: {
      status: {
        eq: 'APPROVED',
      },
    },
  });

  const members = esGet(data?.affiliateRequests?.edges, []).map(
    ({ node }: AffiliateRequestsConnectionEdge) => node?.member?.id
  );

  const { data: username } = useQuery(UPLINES, {
    variables: {
      filter: {
        id: {
          in: members,
        },
      },
    },
  });

  const optionValues = esGet(username?.members?.edges, []).map(
    ({ node }: { node: Record<string, any> }) => node
  );

  return (
    <>
      <Select
        value={value}
        loading={loading}
        style={{ width: '100%' }}
        onChange={onChange}
        placeholder={translate(messages.PLEASE_SELECT)}
      >
        {optionValues.map((q: { username: string; id: string }) => (
          <Option value={q.id} key={q.id}>
            {q.username}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default AffiliateUplineSelect;
