import * as Yup from 'yup';
import globalMessages from 'messages';
import messages from './components/PayoutSettings/messages';

export const CreateRebateGroupTypes = {
  LIVE: 'LIVE',
  PERIODIC: 'PERIODIC',
};

export const SettlementPeriod = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  ANNUALLY: 'YEARLY',
};

export const CreateRebateSteps = {
  REBATE_SETTINGS: 'Rebate settings',
  PAYOUT_SETTINGS: 'Payout settings',
  GROUP_SETTINGS: 'Rebate group',
};

type Translator = (
  { id, defaultMessage }: { id: string; defaultMessage: string },
  options?: any
) => string;

export const rebateSettingsSchema = (translate: Translator) => ({
  name: Yup.string().required(translate(globalMessages.REQUIRED)),
  qualifyingMemberLoyaltyLevels: Yup.array().of(Yup.string()),
  excludedMemberLevels: Yup.array().of(Yup.string()),
  validityDateTimeRange: Yup.object().shape({
    start: Yup.string()
      .nullable()
      .required(translate(globalMessages.REQUIRED)),
    end: Yup.string().nullable(),
  }),
});

export const payoutSettingsSchema = (translate: Translator) => ({
  type: Yup.string().required(translate(globalMessages.REQUIRED)),
  payoutSettlementPeriod: Yup.string(),
  payoutSettlementTime: Yup.string(),
  payoutSettlementDayOfWeek: Yup.number().nullable(),
  payoutSettlementDayOfMonth: Yup.number().nullable(),
  payoutSettlementMonth: Yup.number().nullable(),
  payoutSettlementDateAndMonth: Yup.string().nullable(),
  payoutSettlementDateTime: Yup.string().nullable(),
  automaticPayout: Yup.boolean(),
  turnoverRequirementMultiplier: Yup.number().required(
    translate(globalMessages.REQUIRED)
  ),
  claimOffsetDuration: Yup.string(),
  noClaimOffsetDuration: Yup.boolean(),
  claimExpiryDuration: Yup.string(),
  noClaimExpiryDuration: Yup.boolean(),
  payoutExpiryDuration: Yup.string(),
  noPayoutExpiryDuration: Yup.boolean(),
  minimumPayout: Yup.number()
    .nullable()
    .min(0, translate(messages['rebates.payout-error.text'], { min: 0 })),
  maximumPayout: Yup.number()
    .nullable()
    .min(1, translate(messages['rebates.payout-error.text'], { min: 1 })),
});

export const rebateGroupSettingsSchema = (translate: Translator) => ({
  percentages: Yup.array()
    .of(
      Yup.object({
        multiplier: Yup.number(),
        checked: Yup.bool(),
        gameType: Yup.string().required(translate(globalMessages.REQUIRED)),
        vendor: Yup.string().required(translate(globalMessages.REQUIRED)),
        percentage: Yup.number().required(translate(globalMessages.REQUIRED)),
        games: Yup.array().of(
          Yup.object({
            game: Yup.string().required(translate(globalMessages.REQUIRED)),
            percentage: Yup.number().required(
              translate(globalMessages.REQUIRED)
            ),
          })
        ),
      })
    )
    .nullable(),
  levels: Yup.array()
    .of(
      Yup.object({
        minimum: Yup.number(),
        percentages: Yup.array()
          .of(
            Yup.object({
              multiplier: Yup.number(),
              checked: Yup.bool(),
              gameType: Yup.string(),
              vendor: Yup.string(),
              percentage: Yup.number(),
              games: Yup.array()
                .of(
                  Yup.object({
                    game: Yup.string(),
                    percentage: Yup.number(),
                  })
                )
                .nullable(),
            })
          )
          .nullable(),
      })
    )
    .nullable(),
});

export const publishSettingsSchema = {
  status: Yup.string(),
};

export const formSchemas = (translate: Translator) => [
  rebateSettingsSchema(translate),
  payoutSettingsSchema(translate),
  rebateGroupSettingsSchema(translate),
  publishSettingsSchema,
];

export default {};
