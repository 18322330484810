import esget from 'utils/esGet';
import { WithdrawalProvider } from 'types/graphqlTypes';
import messages from 'pages/components/WithdrawalProviders/messages';
import thirdPartyProviders from 'constants/thirdPartyProviders';
import moment from 'moment';
import globalMsgs from 'messages';

const getCsvHeaders = (translate: any) => [
  {
    label: translate(messages['provider-name.text']),
    key: 'name',
    renderCell: (withdrawalProvider: WithdrawalProvider) =>
      esget(withdrawalProvider?.name, '-'),
  },
  {
    label: translate(messages['type.text']),
    key: 'type',
    renderCell: (withdrawalProvider: WithdrawalProvider) =>
      esget(withdrawalProvider?.type, '-'),
  },
  {
    label: translate(messages['api-key-preview.text']),
    key: 'apiKey',
    renderCell: (withdrawalProvider: any) =>
      withdrawalProvider.type === thirdPartyProviders.DIORPAY
        ? withdrawalProvider.key
        : withdrawalProvider.apiKey,
  },
  {
    label: translate(messages.THIRD_PARTY_GATEWAY),
    key: 'midPayDirectPaymentGateway',
    renderCell: (withdrawalProvider: any) => {
      const gatewayId = esget(
        withdrawalProvider?.midPayDirectPaymentGateway?.id,
        ''
      );
      return gatewayId ? translate(globalMsgs[gatewayId]) : '-';
    },
  },
  {
    label: translate(messages['date-created.text']),
    key: 'dateTimeCreated',
    renderCell: (withdrawalProvider: WithdrawalProvider) =>
      moment(withdrawalProvider.dateTimeCreated).format(
        'MMM DD, YYYY hh:mm:ss A'
      ),
  },
];

export default getCsvHeaders;
