import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import CountriesSelect from 'components/CountriesSelect';
import BanksSelect from 'components/BanksSelect';
import useTranslate from 'utils/useTranslate';
import messages from 'pages/components/WithdrawalMethods/messages';

type Rec = Record<string, any>;
type Props = {
  handleChange: () => void;
  setFieldValue: (e: string, f: any) => void;
  handleBlur: () => void;
  values: Rec;
  errors: Rec;
  formItemLayout: Rec;
};

const BankDetails = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  errors,
  formItemLayout,
}: Props) => {
  const translate = useTranslate();
  return (
    <>
      <Form.Item
        label={translate(messages.bankname)}
        {...formItemLayout}
        validateStatus={errors.bank && 'error'}
        help={errors.bank || null}
      >
        <BanksSelect
          placeHolder={translate(messages.selectone)}
          onChange={(e) => setFieldValue('bank', e)}
          value={values.bank}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.bankacnumber)}
        {...formItemLayout}
        validateStatus={errors.accountNumber && 'error'}
        help={errors.accountNumber || null}
      >
        <Input
          name="accountNumber"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.accountNumber}
          placeholder={translate(messages.bankacnumber)}
        />
      </Form.Item>

      <Form.Item
        label={translate(messages.bankacname)}
        {...formItemLayout}
        validateStatus={errors.accountName && 'error'}
        help={errors.accountName || null}
      >
        <Input
          name="accountName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.accountName}
          placeholder={translate(messages.bankacname)}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.bankacbranch)}
        {...formItemLayout}
        validateStatus={errors.branch && 'error'}
        help={errors.branch || null}
      >
        <Input
          name="branch"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.branch}
          placeholder={translate(messages.bankacbranch)}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.bankaccountry)}
        {...formItemLayout}
        validateStatus={errors.country && 'error'}
        help={errors.country || null}
      >
        <CountriesSelect
          value={values.country}
          placeHolder={translate(messages.countryplaceholder)}
          onChange={(e) => setFieldValue('country', e)}
          label={<></>}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.bankacprovince)}
        {...formItemLayout}
        validateStatus={errors.province && 'error'}
        help={errors.province || null}
      >
        <Input
          name="province"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.province}
          placeholder={translate(messages.bankacprovince)}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.bankaccity)}
        {...formItemLayout}
        validateStatus={errors.city && 'error'}
        help={errors.city || null}
      >
        <Input
          name="city"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city}
          placeholder={translate(messages.bankaccity)}
        />
      </Form.Item>
    </>
  );
};

export default BankDetails;
