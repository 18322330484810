import React from 'react';
import { Tooltip } from 'antd';
import { Icon } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Vendor } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import { safeParseObject } from '../../../../../utils/safeParseObject';
import { SideLabel, StyledSpacer } from '../../styles';

const VendorTitle = styled.div`
  padding: 10px 20px;
  wordwrap: break-word;

  &:hover {
    cursor: pointer;

    & i.anticon-delete {
      display: inline-block !important;
    }
  }
`;

const StyledAddTab = styled.div`
  padding: 10px 20px;

  &:hover {
    cursor: pointer;
  }
`;

const DeleteIcon = styled(Icon)`
  display: none;
  cursor: pointer;
  color: red;
`;

const activeTabStyle = {
  borderRight: '2px solid #3590ff',
  padding: '10px 20px',
};

type Props = {
  activeVendor: Vendor | {};
  selectedVendors: Vendor[];
  onDeleteVendor: (value: string) => void;
  onClickEditVendorItem: (value: Vendor) => void;
  onClickAddVendorItem: () => void;
};

export const VendorsSideLabels: React.FC<Props> = ({
  activeVendor,
  selectedVendors,
  onDeleteVendor,
  onClickEditVendorItem,
  onClickAddVendorItem,
}) => {
  const activeVendorId = coercedGet(activeVendor, 'id', '');

  return (
    <SideLabel style={{ height: '100%' }}>
      <StyledSpacer />

      {selectedVendors.length > 0 &&
        selectedVendors.map((rawVendor, idx) => {
          const vendor = safeParseObject(rawVendor);

          return (
            <VendorTitle
              key={idx}
              style={activeVendorId === vendor.id ? activeTabStyle : {}}
              onClick={() => onClickEditVendorItem(vendor)}
            >
              <h4 style={{ marginBottom: '0' }}>
                {vendor.name} - {vendor.type}
                <Tooltip placement="right" title="Remove">
                  <DeleteIcon
                    className="ml-1"
                    type="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteVendor(vendor.id);
                    }}
                  />
                </Tooltip>
              </h4>
            </VendorTitle>
          );
        })}

      <StyledAddTab
        style={!activeVendorId ? activeTabStyle : {}}
        onClick={onClickAddVendorItem}
      >
        <h4 style={{ color: '#3590ff', marginBottom: '0', cursor: 'pointer' }}>
          <FormattedMessage id="add-vendor.text" defaultMessage="Add Vendor" />
        </h4>
      </StyledAddTab>
    </SideLabel>
  );
};
