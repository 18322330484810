import React from 'react';
import useTranslate from 'utils/useTranslate';
import { Steps } from 'antd';
import messages from 'messages';
import DrawerActionButtons from 'components/DrawerActionButtons';
import Drawer from '../../../common/Drawer/Drawer';
import { useMissionsValue } from '../../context';
import GeneralSettings from '../GeneralSettings';
import TaskAndRewardSettings from '../TaskAndRewardSettings';
import MilestoneSettings from '../MilestoneSettings';
import VIPGroups from '../VIPGroups';
import ReadyToPublish from '../ReadyToPublish';
import TaskAndRewardSettingsDailyLogin from '../TaskAndRewardSettingsDailyLogin';
import { isSmall, useBreakpoint } from '../../../../../hooks/useBreakpoint';

const { Step } = Steps;

type Prop = {
  onClose: () => void;
  drawerStatus: string;
};

const RenderTaskRewardSettings = ({ type }: { type: string }) => {
  if (type === 'DAILY_LOGIN') {
    return <TaskAndRewardSettingsDailyLogin />;
  }
  return <TaskAndRewardSettings />;
};

const NewMissionsDrawer = ({ onClose, drawerStatus }: Prop) => {
  const translate = useTranslate();
  const { stepCurrent, selectedNewMission, hideMilestone } = useMissionsValue();
  const breakpoint = useBreakpoint();

  return (
    <Drawer onClose={onClose} open={!!drawerStatus}>
      <Drawer.Header title={translate(messages[drawerStatus])}>
        <DrawerActionButtons saveAndExitFn={onClose} saveFn={onClose} />
      </Drawer.Header>
      {stepCurrent < (hideMilestone ? 3 : 4) && (
        <div className="py-3 border-bottom">
          <div style={{ marginLeft: '20%', marginRight: '20%' }}>
            <Steps
              current={stepCurrent}
              direction={isSmall(breakpoint) ? 'vertical' : 'horizontal'}
            >
              <Step title={translate(messages.GENERAL_SETTINGS)} />
              <Step title={translate(messages.TASK_AND_REWARD_SETTINGS)} />
              {!hideMilestone && (
                <Step title={translate(messages.MILESTONE_SETTINGS)} />
              )}
              <Step title={translate(messages.VIP_GROUPS)} />
            </Steps>
          </div>
        </div>
      )}

      {stepCurrent === 0 && <GeneralSettings />}
      {stepCurrent === 1 && (
        <RenderTaskRewardSettings type={selectedNewMission} />
      )}
      {stepCurrent === 2 && !hideMilestone && <MilestoneSettings />}
      {stepCurrent === (hideMilestone ? 2 : 3) && <VIPGroups />}
      {stepCurrent === (hideMilestone ? 3 : 4) && <ReadyToPublish />}
    </Drawer>
  );
};

export default NewMissionsDrawer;
