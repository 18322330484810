import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'rebates.id.text': {
    id: 'rebates.id.text',
    defaultMessage: 'ID',
  },
  'rebates.rebate-group-name-level.text': {
    id: 'rebates.rebate-group-name-level.text',
    defaultMessage: 'Rebate Group Name (level)',
  },
  'rebates.to-do-list.text': {
    id: 'rebates.to-do-list.text',
    defaultMessage: 'To Do List',
  },
  'rebates.validity.text': {
    id: 'rebates.validity.text',
    defaultMessage: 'Validity',
  },
  'rebates.number-of-members.text': {
    id: 'rebates.number-of-members.text',
    defaultMessage: 'No. of Members',
  },
  'rebates.qualifying-vip.text': {
    id: 'rebates.qualifying-vip.text',
    defaultMessage: 'Qualifying VIP',
  },
  'rebates.status.text': {
    id: 'rebates.status.text',
    defaultMessage: 'Status',
  },
  'rebates.draft.text': {
    id: 'rebates.draft.text',
    defaultMessage: 'Draft',
  },
  'rebates.active.text': {
    id: 'rebates.active.text',
    defaultMessage: 'Active',
  },
  'rebates.inactive.text': {
    id: 'rebates.inactive.text',
    defaultMessage: 'Inactive',
  },
  'rebates.rebate-group-deleted.text': {
    id: 'rebates.rebate-group-deleted.text',
    defaultMessage: 'Rebate group deleted.',
  },
  'rebates.rebate-group-duplicated.text': {
    id: 'rebates.rebate-group-duplicated.text',
    defaultMessage: 'Rebate group duplicated.',
  },
  'rebates.rebate-group-activated.text': {
    id: 'rebates.rebate-group-activated.text',
    defaultMessage: 'Rebate group activated.',
  },
  'rebates.rebate-group-deactivated.text': {
    id: 'rebates.rebate-group-deactivated.text',
    defaultMessage: 'Rebate group deactivated.',
  },
});

export default messages;
