import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import createContext from 'utils/createContext';
import { useScreenTabV2 } from 'store/screenTabState';
import { useUpdateEffect } from 'react-use';
import { TabId } from 'types/nav';

const CUSTOM_FILTER = gql`
  query CustomFilters($id: ID) {
    customFilter(id: $id) {
      id
      name
      filter
    }
  }
`;
export interface FilterProps {
  filters: { [key: string]: any };
  setPersistedFilters: (value: any) => void;
  onFilterChange: (value: any) => void;
  setFilters: (value: any) => void;
  handleFilterChange: (value: any) => void;
  resetFilter: () => void;
  initialValues: object;
  resetFilterToDefault: () => void;
  defaultValues: object;
  savedFilterId: string | null;
  handleSavedFilterIdChange: (value: string) => void;
  fetchingFilter: boolean;
  tabId?: TabId;
}

const defaultProps = {
  initialValues: {},
  defaultValues: {},
  tabId: null,
};

type Props = {
  tabId?: TabId;
  initialValues: { [x: string]: any };
  defaultValues: { [x: string]: any };
  children: React.ReactNode;
};

const [useContext, ContextProvider] = createContext<FilterProps>();

const FiltersProvider = ({
  children,
  initialValues = {},
  defaultValues = {},
  tabId,
}: Props) => {
  const { updateTab } = useScreenTabV2();

  const [filters, setFilters] = useState(initialValues);
  const [savedFilterId, setSavedFilterId] = useState<string | null>(null);

  useUpdateEffect(() => {
    setFilters(initialValues);
  }, [initialValues]);

  const setPersistedFilters = (filts: any) => {
    const newFilters = typeof filts === 'function' ? filts(filters) : filts;
    if (tabId) {
      updateTab({
        id: tabId,
        updates: {
          filter: newFilters,
        },
      });
    }
    setFilters(newFilters);
  };

  const saveTabFilter = (filterToUse: any) => {
    if (tabId) {
      updateTab({
        id: tabId,
        updates: {
          filter: filterToUse,
        },
      });
    }
  };

  const { loading } = useQuery(CUSTOM_FILTER, {
    variables: {
      id: savedFilterId,
    },
    skip: !savedFilterId,
    onCompleted: (result) => {
      if (result) {
        const { customFilter } = result;
        if (customFilter) {
          const { filter } = customFilter;
          const newFilter = { ...defaultValues, ...filter };
          setFilters(newFilter);
          saveTabFilter(newFilter);
        }
      }
    },
  });

  const handleFilterChange = ({
    name,
    value,
    forgotPrev = false,
  }: {
    name: string;
    value: {
      [x: string]: any;
    };
    forgotPrev?: boolean;
  }) => {
    if (forgotPrev) {
      setFilters(() => {
        const filter = { [name]: value };
        saveTabFilter(filter);
        return filter;
      });
    }

    setFilters((prevState) => {
      const newFilter = { ...prevState, [name]: value };
      saveTabFilter(newFilter);
      return newFilter;
    });
    setSavedFilterId(null);
  };

  const handleSavedFilterIdChange = (id: string) => {
    if (!id) {
      setFilters(defaultValues);
      saveTabFilter(defaultValues);
    }
    setSavedFilterId(id);
  };

  const resetFilter = () => setFilters(initialValues);

  const resetFilterToDefault = () => {
    setFilters(defaultValues);
    setSavedFilterId(null);
  };

  return (
    <ContextProvider
      value={{
        setPersistedFilters,
        filters,
        setFilters,
        onFilterChange: (value) => setFilters(value),
        handleFilterChange,
        resetFilter,
        resetFilterToDefault,
        initialValues,
        defaultValues,
        savedFilterId,
        handleSavedFilterIdChange,
        fetchingFilter: loading,
      }}
    >
      {children}
    </ContextProvider>
  );
};

export default FiltersProvider;

export const useFilterValues = () => useContext();

FiltersProvider.defaultProps = defaultProps;
