import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Card } from 'antd';
import removeNull from 'utils/removeNull';
import { defineMessages } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import globalMessages from 'messages';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import MemberTagList from './MemberTagList';
import { CREATE_MEMBER_TAG } from './mutations';
import { MEMBER_TAGS } from './MemberTagList/queries';

const { Item } = Form;

const messages = defineMessages({
  'member-tag-created.text': {
    id: 'member-tag-created.text',
    defaultMessage: 'Member tag created',
  },
});

type Rec = Record<string, any>;

const AddMemberTag = ({
  onSelect,
  value,
}: {
  onSelect: (e: any) => void;
  value: Rec[];
}) => {
  const translate = useTranslate();
  // const [isLoading, setLoading] = useState(false);
  const [createMemberTag] = useMutation(CREATE_MEMBER_TAG);
  const [input, setInput] = useState<Rec>({
    name: null,
    description: null,
    color: '#d3d3d3',
  });

  const { context } = useOperatorHeader();
  const handleCreateMemberTag = async () => {
    try {
      // setLoading(true);

      await createMemberTag({
        variables: { input: removeNull(input) },
        refetchQueries: [
          {
            query: MEMBER_TAGS,
            variables: {
              first: 12,
              filter: {
                name: {
                  startsWith: '',
                },
              },
            },
          },
        ],
        context,
      });

      message.success(translate(messages['member-tag-created.text']));

      // setLoading(false);
      await setInput({
        name: null,
        description: null,
        color: '#d3d3d3',
      });
    } finally {
      // setLoading(false);
    }
  };

  const handleOnChange = (e: {
    target: {
      value: string;
    };
  }) => {
    setInput({
      ...input,
      name: e.target.value,
    });
  };

  const handleColor = (e: string) => {
    setInput({
      ...input,
      color: e,
    });
  };

  const handleSubmit = () => handleCreateMemberTag();

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_LABEL_MANAGEMENT
  );

  return (
    <>
      {ALLOWED_LIST ? (
        <div style={{ height: '220px', overflowY: 'auto' }}>
          <div className="p-1">
            <Item className="mb-0">
              <Input
                onChange={handleOnChange}
                value={input.name || ''}
                placeholder={translate(
                  globalMessages['please-enter-a-label.text']
                )}
              />
            </Item>
          </div>
          <MemberTagList
            hasPermission={ALLOWED_LIST}
            onSelect={onSelect}
            value={value}
            input={input}
            handleColor={handleColor}
            handleSubmit={handleSubmit}
            // isLoading={isLoading}
          />
        </div>
      ) : (
        <Card
          bordered={false}
          className="w-100 d-flex justify-content-center align-items-center bg-light"
        >
          <p className="bg-light m-0">
            Need Permission on Member Management Label (List)
          </p>
        </Card>
      )}
    </>
  );
};

export default AddMemberTag;
