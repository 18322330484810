import React, { FC } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { AdminsConnection, LinkedAdmin } from 'types/graphqlTypes';
import { Select } from 'antd';
import Maybe from 'graphql/tsutils/Maybe';
import { useAccount } from 'store/accountState';
import { ADMINS } from './queries';

type Props = {
  onSelect: (values: string[]) => void;
  brands?: Maybe<LinkedAdmin>[];
};

const BrandSelect: FC<Props> = ({ onSelect, brands }) => {
  const { account } = useAccount();
  const { account: user } = account;

  const { data: dataAdmins } = useQuery<{ admins: AdminsConnection }>(ADMINS, {
    skip: !!brands || !user,
  });
  const adminEdges = dataAdmins?.admins?.edges || [];
  const adminNodes = adminEdges.map((adminEdge) => adminEdge?.node);

  return (
    <Select
      mode="multiple"
      allowClear
      style={{ width: '100%' }}
      placeholder="Select Brand"
      onChange={(e: string[]) => onSelect(e)}
    >
      <Select.Option value="" disabled>
        Select Brands
      </Select.Option>

      {brands
        ? brands.map((brand) => (
            <Select.Option key={brand!.id} value={brand!.id}>
              {brand!.company}
            </Select.Option>
          ))
        : adminNodes?.map((adminNode) => (
            <Select.Option key={adminNode.id} value={adminNode.id}>
              {adminNode.company} ({adminNode.adminCode})
            </Select.Option>
          ))}
    </Select>
  );
};

export default BrandSelect;
