/* eslint-disable react/prop-types */
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { GET_CUSTOM_FILTERS } from 'graphql/queries/customFilter.query';
import { debounce, get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SharedStyledSelect as StyledSelect } from 'styles/SharedStyledSelect';
import { StyledLabel, StyledSpan } from '../../styles';

export const QuickSearchIds = {
  memberManagement: 'member-management',
  memberLevelManagement: 'member-level-management',
  labelManagement: 'label-management',
  membersOnline: 'members-online',
  intelligentAutomation: 'intelligent-automation',
  memberLoyaltyManagement: 'member-loyalty-management',
  permissionGroup: 'permission-group',
  operatorManagement: 'operator-management',
  rebates: 'rebates',
  dailyOperatingReport: 'daily-operation-reports',
  memberBetRecords: 'member-bet-records',
  balanceTransactionRecords: 'balance-transaction-records',
  systemMessage: 'manual-message',
};

const QuickSearchCustomFilters = ({ setFilters, resetFilter, tabId }) => {
  const { data, refetch } = useQuery(GET_CUSTOM_FILTERS, {
    variables: {
      filter: {
        context: {
          eq: tabId,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const edges = get(data, 'customFilters.edges', []);
  const customFilters = edges.map((edge) => edge.node);

  const handleChange = (val) => {
    if (!val) {
      resetFilter();
      return;
    }
    const parsedVal = JSON.parse(val);
    setFilters({ ...parsedVal });
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="quick-search.text"
              defaultMessage="Quick Search"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        onChange={handleChange}
        onSearch={debounce((text) => {
          if (text.length > 1) {
            refetch({
              first: 10,
              filter: {
                name: {
                  startsWith: text,
                },
              },
            });
          }
        }, 500)}
        showSearch
        allowClear
        filterOption={false}
        placeholder={
          <FormattedMessage
            id="select-a-saved-custom-filter.text"
            defaultMessage="Select a saved custom filter"
          />
        }
      >
        {customFilters.map((savedFilter) => (
          <Select.Option
            value={JSON.stringify(savedFilter.filter)}
            key={savedFilter.id}
          >
            {savedFilter.name}
          </Select.Option>
        ))}
      </StyledSelect>
    </>
  );
};

export default QuickSearchCustomFilters;
