import React from 'react';
import { isEmpty } from 'lodash';
import { LayoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { ALink } from 'SuperAdminMain/shared/components/ALink/ALink';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import { VendorTag } from './VendorTag/VendorTag';
import { StatusTag } from './StatusTag/StatusTag';
import { ClientTag } from './ClientTag/ClientTag';
import { VendorGroupNameTag } from './VendorGroupNameTag/VendorGroupNameTag';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const SideLeft: React.FC<Props> = () => {
  const { filters, setFilters } = useFilterValues();

  return (
    <Container>
      <Button onClick={() => null} size="small">
        <LayoutOutlined className="layout-image" />
      </Button>

      <div className="search-criteria">
        <FormattedMessage
          id="filter-conditions.text"
          defaultMessage="Filter Conditions:"
        />
      </div>

      <StatusTag />

      {coercedGet(filters, 'vendors.includesAny', []).map(
        (vendorId: string) => (
          <VendorTag key={vendorId} id={vendorId} />
        )
      )}

      {coercedGet(filters, 'admins.includesAny', []).map((adminId: string) => (
        <ClientTag id={adminId} key={adminId} />
      ))}

      {coercedGet(filters, 'name.in', []).map((name: string, idx: number) => (
        <VendorGroupNameTag key={idx} nameValue={name} />
      ))}

      {!isEmpty(filters) && (
        <div className="clear-all">
          <ALink onClick={() => setFilters({})}>
            <FormattedMessage id="clear-all.text" defaultMessage="Clear all" />
          </ALink>
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  button {
    background: #f2f2f2;
    margin: 0 16px;
    border-radius: 4px;
    border: none;

    .layout-image {
      margin-top: 4px;
    }
  }

  .search-criteria {
    margin-right: 12px;
  }

  .clear-all {
    margin-left: 12px;
  }

  color: #000000d9;
`;
