import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Spin } from 'antd';
import { WITHDRAWAL_COMPLIANCE_CONFIG } from 'graphql/queries/withdrawalCompliance.query';
import globalMessages from 'messages';
import React from 'react';
import useTranslate from 'utils/useTranslate';
import messages from '../../../../messages';

const closeIcon = <CloseOutlined style={{ color: '#E50000' }} />;
const checkIcon = <CheckOutlined style={{ color: '#27c989' }} />;

const iconStatusRender = (value: string) =>
  value === 'FAILED' ? closeIcon : checkIcon;

type Props = {
  withdrawal: Record<string, any>;
};

const SystemCompliance = (props: Props) => {
  const translate = useTranslate();
  const { withdrawal = {} } = props;
  const {
    oneTimeTurnoverDetectionCheck,
    promoHunterCheck,
    contraBetCheck,
    ipCollisionOffenderCheck,
    martingaleBetOffenderCheck,
    manualAdjustment,
  } = withdrawal;

  const { loading, data = {} } = useQuery(WITHDRAWAL_COMPLIANCE_CONFIG);
  const { withdrawalComplianceCheckConfig } = data as Record<string, any>;

  return (
    <>
      <div className="menu-header py-2 px-3" style={{ marginTop: 23 }}>
        {translate(messages.systemComplianceCheck)}
      </div>
      {loading && (
        <div className="p-4">
          <Spin />
        </div>
      )}
      {manualAdjustment && (
        <div className="d-flex py-2 px-3 bb-1">
          <div className="text-primary">
            {translate(globalMessages.MANUAL_ADJUSTMENT)}
            <br />
          </div>
          <div className="flex-1 text-right text-success">{checkIcon}</div>
        </div>
      )}
      {!manualAdjustment &&
        !loading &&
        withdrawalComplianceCheckConfig &&
        withdrawalComplianceCheckConfig.enabled && (
          <>
            {withdrawalComplianceCheckConfig.bonusHunterEnabled && (
              <div className="d-flex py-2 px-3 bb-1">
                <div className="text-primary">
                  {translate(messages.promoHunter)}
                  <br />
                  {/* <a href="#section" className="text-error">
                    <small>
                      {translate(messages.clickToViewTransactionRecord)}
                    </small>
                  </a> */}
                </div>
                <div className="flex-1 text-right">
                  {iconStatusRender(promoHunterCheck)}
                </div>
              </div>
            )}
            {withdrawalComplianceCheckConfig.contraBetOffenderEnabled && (
              <div className="d-flex py-2 px-3 bb-1">
                <div className="text-primary">
                  {translate(messages.contraBet)}
                  <br />
                  {/* <a href="#section" className="text-error">
                    <small>{translate(messages.clickToViewReport)}</small>
                  </a> */}
                </div>
                <div className="flex-1 text-right text-success">
                  {iconStatusRender(contraBetCheck)}
                </div>
              </div>
            )}
            {withdrawalComplianceCheckConfig.turnoverRequirementEnabled && (
              <div className="d-flex py-2 px-3 bb-1">
                <div className="text-primary">
                  {translate(messages.oneTimeTurnoverDetection)}
                  <br />
                  {/* <a href="#section" className="text-error">
                    <small>{translate(messages.clickToViewReport)}</small>
                  </a> */}
                </div>
                <div className="flex-1 text-right text-success">
                  {iconStatusRender(oneTimeTurnoverDetectionCheck)}
                </div>
              </div>
            )}
            {withdrawalComplianceCheckConfig.ipCollisionOffenderEnabled && (
              <div className="d-flex py-2 px-3 bb-1">
                <div className="text-primary">
                  {translate(messages.ipCollisionOffender)}
                </div>
                <div className="flex-1 text-right text-success">
                  {iconStatusRender(ipCollisionOffenderCheck)}
                </div>
              </div>
            )}
            {withdrawalComplianceCheckConfig.martingaleBetOffenderEnabled && (
              <div className="d-flex py-2 px-3 bb-1">
                <div className="text-primary">
                  {translate(messages.martingaleBetOffender)}
                </div>
                <div className="flex-1 text-right text-success">
                  {iconStatusRender(martingaleBetOffenderCheck)}
                </div>
              </div>
            )}
          </>
        )}
    </>
  );
};

export default SystemCompliance;
