import React from 'react';
import setTitleCase from 'utils/setTitleCase';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge } from 'antd';
import styled from 'styled-components';

const color: Record<string, string> = {
  ENABLED: 'green',
  DISABLED: 'red',
  FROZEN: 'blue',
};

const StyledBadge: any = styled(Badge)`
  .ant-badge-status-dot {
    width: ${(props: { width: string }) => props.width};
    height: ${(props: any) => props.height};
  }
`;

const displayIntlText = (text: string, locale: string) => {
  if (locale === 'en') return setTitleCase(text);

  if (locale === 'zh') {
    switch (text) {
      case 'ENABLED':
        return <FormattedMessage id="enabled.text" defaultMessage="Enabled" />;
      case 'DISABLED':
        return (
          <FormattedMessage id="disabled.text" defaultMessage="Disabled" />
        );
      case 'FROZEN':
        return <FormattedMessage id="frozen.text" defaultMessage="Frozen" />;

      default:
        return 'Unknown Status';
    }
  }

  return 'Unknown Status';
};

const StatusBadgeIntl = ({
  text,
  noText,
  size,
}: {
  text: string;
  noText: boolean;
  size: 'small' | 'medium';
}) => {
  const { locale } = useIntl();
  const badgeSize = () => {
    switch (size) {
      case 'medium':
        return {
          width: '10px',
          height: '10px',
        };
      case 'small':
      default:
        return {
          width: '6px',
          height: '6px',
        };
    }
  };
  return (
    <div className="styled-center">
      <StyledBadge
        color={color[text]}
        width={badgeSize().width}
        height={badgeSize().height}
      />
      {!noText && <span>{displayIntlText(text, locale)}</span>}
    </div>
  );
};

export default StatusBadgeIntl;
