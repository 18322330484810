import React, { useCallback, useEffect, useState } from 'react';
import { useAccount } from 'store/accountState';
import Storage from 'constants/storage';
import coercedGet from 'utils/coercedGet';
import gql from 'graphql-tag';
import { Redirect, useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import { getAccess } from 'utils/getAccess';
import { isAdmin, isOperator } from 'utils/isAdmin';
import { AccessDenied } from 'pages/components/AccessDenied/AccessDenied';
import PageLoader from 'components/PageLoader/PageLoader';
import ForgotPassword from 'components/ForgotPassword';
import ForceUpdatePassword from 'components/ForceUpdatePassword';
import AntLayout from './AntLayout';

const CONFIG = gql`
  query {
    s
    config {
      adminCode
    }
  }
`;

const Main = () => {
  const history = useHistory();
  const [EntryComponent, setComponent] = useState(<></>);
  const [loading, setLoading] = useState(true);
  const client = useApolloClient();

  const authenticateViaOTP = Boolean(
    localStorage.getItem(Storage.AUTHENTICATE_VIA_OTP)
  );
  const [resetPassModal, setResetPassModal] = useState<boolean>(
    authenticateViaOTP ?? false
  );

  const {
    setPortal,
    account: { account: user },
  } = useAccount() as Record<string, any>;

  const [forceChangePassModal, setForceChangePassModal] = useState<boolean>(
    user?.forceChangePassword ?? false
  );

  useEffect(() => {
    setPortal('bo');
    if (
      !localStorage.getItem(Storage.BO_ACCESS_TOKEN) ||
      !localStorage.getItem(Storage.BO_REFRESH_TOKEN)
    ) {
      localStorage.removeItem(Storage.ACCESS_TOKEN);
      history.push('/signin');
    } else {
      localStorage.setItem(
        Storage.ACCESS_TOKEN,
        localStorage.getItem(Storage.BO_ACCESS_TOKEN) || ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkAccess = useCallback(async () => {
    const { data } = await client.query({
      query: CONFIG,
    });

    const adminCode = coercedGet(data, 'config.adminCode', null);
    getAccess(adminCode)
      .then(() => setComponent(AntLayout))
      .catch((err) => {
        setComponent(<AccessDenied code={err?.message} ip={err?.ipAddress} />);
      })
      .finally(() => {
        if (loading) {
          setLoading(false);
        }
      });
  }, [client, loading]);

  useEffect(() => {
    if (!user) return;
    if (isAdmin(user) || isOperator(user)) {
      setComponent(AntLayout);
      setLoading(false);
      return;
    }

    checkAccess();
    const tenSeconds = 1000 * 10;
    const interval = setInterval(async () => {
      await checkAccess();
    }, tenSeconds);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAccess, user, user?.forceChangePassword]);

  const accessToken = localStorage[Storage.BO_ACCESS_TOKEN];

  if (!accessToken) {
    return <Redirect to="/signin" />;
  }

  if (accessToken && authenticateViaOTP) {
    return (
      <ForgotPassword
        visible={resetPassModal}
        onClose={() => setResetPassModal(false)}
        onSuccess={() => setResetPassModal(false)}
      />
    );
  }

  if (user?.forceChangePassword && user?.role === 'OPERATOR') {
    return (
      <ForceUpdatePassword
        visible={forceChangePassModal}
        onSuccess={() => setForceChangePassModal(false)}
      />
    );
  }

  if (loading) return <PageLoader />;

  return <>{React.cloneElement(EntryComponent)}</>;
};

export default Main;
