import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import ConfirmationModal from 'components/ConfirmationModal';
import { DELETE_DOMAIN } from 'graphql/mutations/siteConfiguration.mutation';
import React, { useState } from 'react';
import { ALink } from 'components/ALink/ALink';

type Props = {
  id: string;
  refetch: () => void;
};

const DeleteDomain = ({ id, refetch }: Props) => {
  const [visible, setVisible] = useState(false);
  const [deleteModal, { loading }] = useMutation(DELETE_DOMAIN, {
    onCompleted: () => {
      message.success('Domain successfully deleted.');

      refetch();
      setVisible(false);
    },
    onError: () => {
      message.error('Deleting domain failed. Please try again later.');
      setVisible(false);
    },
  });

  const handleOk = async (): Promise<void> => {
    await deleteModal({
      variables: {
        id,
      },
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <ALink
        style={{
          textDecoration: 'none',
        }}
        onClick={() => setVisible(!visible)}
      >
        Delete
      </ALink>

      <ConfirmationModal
        title="Confirm Delete"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={loading}
      />
    </>
  );
};

export default DeleteDomain;
