import gql from 'graphql-tag';

export const MULT_MEMBERS_QUERY = gql`
  query MultQuery(
    $membersId: [ID!]
    $memberLevelsId: [ID!]
    $memberLoyaltyLevelsId: [ID!]
  ) {
    members(filter: { id: { in: $membersId } }) {
      edges {
        node {
          id
          username
        }
      }
    }

    memberLevels(filter: { id: { in: $memberLevelsId } }) {
      edges {
        node {
          id
          name
          color
        }
      }
    }

    memberLoyaltyLevels(
      filter: {
        id: { in: $memberLoyaltyLevelsId }
        status: { in: [ACTIVE, DRAFT, INACTIVE] }
      }
    ) {
      edges {
        node {
          id
          name
          color
          programme {
            id
            name
          }
        }
      }
    }
  }
`;
