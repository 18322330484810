import React from 'react';
import styled from 'styled-components';
import ColumnsFilterNew from 'SuperAdminMain/shared/components/ColumnsFilterSA';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
// import { MoreActions } from './MoreActions/MoreActions';
// import { NewVendor } from './NewVendor/NewVendor';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const SideRight: React.FC<Props> = () => {
  return (
    <Container>
      <ColumnsFilterNew className="mr-3" tabId={TabId.VENDOR_MAINTENANCE} />
      {/* <MoreActions /> */}
      {/* <NewVendor /> */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  .view-column-icon {
    margin-right: 4px;
  }

  .custom-columns {
    margin-right: 9px;
  }
`;
