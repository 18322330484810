import gql from 'graphql-tag';

export const CUSTOM_FILTERS = gql`
  query CustomFilters(
    $first: Int
    $after: Binary
    $filter: CustomFiltersFilterInput
  ) {
    customFilters(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          value: id
          label: name
        }
      }
    }
  }
`;

export default {};
