import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import gql from 'graphql-tag';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import { Config, MutationUpdateConfigArgs } from 'types/graphqlTypes';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import CountriesSelect from 'components/CountriesSelect';
import coercedGet from 'utils/coercedGet';
import countries from 'constants/countries';
import moment from 'moment';

const COUNTRY = gql`
  query Country {
    config {
      defaultCountry {
        id
        name
        code
      }
    }
  }
`;
const GET_COUNTRIES = gql`
  query Countries($filter: CountryFilterInput) {
    countries(filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const UPDATE_COUNTRY = gql`
  mutation UpdateCountry($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

const ManageCountry = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [timezone, setTimezone] = useState('Europe/London');
  const [country, setCountry] = useState({
    current: '',
    selected: '',
  });
  const [loading, setLoading] = useState(false);
  const { context } = useOperatorHeader();

  const client = useApolloClient();

  useQuery<{ config: Pick<Config, 'defaultCountry'> }>(COUNTRY, {
    context,
    onCompleted: (countryData) => {
      const countryId = countryData.config.defaultCountry?.id || '';
      setCountry({
        current: countryId,
        selected: countryId,
      });
    },
  });

  const [updateCountry] = useMutation<boolean, MutationUpdateConfigArgs>(
    UPDATE_COUNTRY
  );

  const showModal = () => {
    setIsModalVisible(true);
    setCountry((prev) => ({
      ...prev,
      selected: country.current,
    }));
  };

  const handleOk = () => {
    setLoading(true);
    updateCountry({
      variables: {
        input: {
          defaultCountry: country.selected,
          timezone,
        },
      },
    })
      .then(() => {
        setIsModalVisible(false);
        message.success('Country updated');
        setCountry((prev) => ({
          ...prev,
          current: country.selected,
        }));
      })
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  React.useEffect(() => {
    const fetchCountries = async () => {
      const countriesPromise = client.query({
        query: GET_COUNTRIES,
        variables: {
          filter: {
            id: {
              eq: country.selected || null,
            },
          },
        },
      });

      const countriesRaw = await Promise.resolve(countriesPromise);

      const edges = coercedGet(countriesRaw.data, 'countries.edges', []);

      const countryName = edges[0]?.node?.name;

      const selectedTimezone =
        countryName && moment.tz.zonesForCountry(countries[countryName]);

      if (selectedTimezone) setTimezone(selectedTimezone[0]);
    };

    fetchCountries();
  }, [country, client]);

  return (
    <div>
      <h3>Manage Country</h3>
      <Button style={{ marginTop: '12px' }} onClick={showModal}>
        View/Select Default Country
      </Button>

      <Modal
        okText="Set Default"
        confirmLoading={loading}
        width="900px"
        title="View & Select Default Country"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <CountriesSelect
          value={country.selected || ''}
          onChange={(e) =>
            setCountry((prev) => ({
              ...prev,
              selected: e,
            }))
          }
          valueKey="id"
        />
      </Modal>
    </div>
  );
};

export default ManageCountry;
