import styled from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const StyledRangePicker = styled(RangePicker)`
  .ant-input {
    /* border: 0;
    border-bottom: 1px solid #d9d9d9; */
    border: 1px solid #d9d9d9;
    border-radius: 0;
    padding-left: 0;
    font-size: 12px;
  }
`;

export default {};
