import React from 'react';
import coercedGet from 'utils/coercedGet';
import { Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';

import { CLIENT_NAMES } from './query';

type Props = {
  adminIds: string[];
};

const ClientFilterNames: React.FC<Props> = ({ adminIds }) => {
  const { data, loading, error } = useQuery(CLIENT_NAMES, {
    variables: {
      filter: {
        id: {
          in: [...adminIds],
        },
      },
    },
    context: { shouldBatch: true },
  });

  if (loading)
    return (
      <div className="styled-center">
        <Spin />
      </div>
    );
  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  const edges = coercedGet(data, 'admins.edges', []);

  const getEdges = () => (
    <div>
      {edges.length
        ? edges.map((edge: any, index: number) => {
            if (index === edges.length - 1)
              return (
                <p key={coercedGet(edge, 'node.id', index)}>
                  {coercedGet(edge, 'node.username', '-')}
                </p>
              );
            return (
              <p key={coercedGet(edge, 'node.id', index)}>{`${coercedGet(
                edge,
                'node.username',
                '-'
              )}, `}</p>
            );
          })
        : '-'}
    </div>
  );

  return <span>{getEdges()}</span>;
};

export default ClientFilterNames;
