import { useQuery } from '@apollo/react-hooks';
import {
  Alert,
  Button,
  Col,
  InputNumber,
  Layout,
  Row,
  Switch,
  Table,
  Tag,
  Typography,
} from 'antd';
import { GAMES as GAMES_QUERY } from 'graphql/queries/game.query';
import { DynamicObj } from 'interfaces/user.interface';
import { get } from 'lodash';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAccount } from 'store/accountState';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import esGet from 'utils/esGet';
import useTranslate from 'utils/useTranslate';
import { CreateRebateGroupTypes } from '../../../constants';
import { SearchTypes, useRebateGroupPercentagesState } from '../context';

type GameValueProps = {
  id: string;
  nameEn: string;
  nameZh: string;
  nameZhHant: string;
  stakes: DynamicObj;
};

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  flex: 0 0 300px !important;
  max-width: 300px !important;
  min-width: 300px !important;
  width: 300px !important;
  background: #fff;
`;

const StyledTable: any = styled(Table)`
  .ant-table {
    border: 0;
    .ant-table-body {
      margin: 0 !important;
      padding-top: 4px;
      padding-bottom: 16px;
      .ant-table-thead {
        display: none;
      }
      td {
        border: 0;
      }
      tr > td:nth-child(1) {
        padding: 8px 6px 8px 0px !important;
      }
      tr > td:nth-child(2) {
        padding: 8px 3px 8px 4px !important;
      }
      tr > td:nth-child(3) {
        padding: 8px 0px !important;
      }
    }
    .ant-table-footer {
      background: transparent;
      &:before {
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background: #e8e8e8;
        content: '';
      }
    }
  }
`;

const StyledSwitch = styled(Switch)`
  &.ant-switch {
    height: 21px;
    min-width: 46px;
    &:after {
      width: 13px;
      height: 13px;
      top: 3px;
      border-radius: 13px;
      transform: translateX(20%);
    }
  }
  &.ant-switch-checked {
    background-color: #27c989;
    &:after {
      transform: translateX(-120%);
    }
  }
`;

const StyledTag = styled(Tag)`
  &.ant-tag {
    border-radius: 14px;
  }
`;

const StyledRow = styled(Row)`
  &.ant-row {
    &:before {
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #e8e8e8;
      content: '';
    }
  }
`;

const getValue = ({ input, formValue }: any) =>
  input === null ? formValue : input;

const CustomInputNumber = ({
  readOnly,
  formValue,
  onFinishChange,
  value,
  size,
  min,
  max,
  formatter,
  parser,
  ...otherProps
}: {
  readOnly: boolean;
  formValue?: number;
  onFinishChange: (e: number) => void;
  size: string;
  value?: number;
  min: number;
  max: number;
  formatter: (e: any) => string;
  parser: (e: any) => string;
}) => {
  const [input, setInput] = useState<any>(null);
  const debouncedInput = useDebounce(input, 300);

  useEffect(() => {
    if (debouncedInput !== null) {
      onFinishChange(debouncedInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  return !readOnly ? (
    <InputNumber
      {...otherProps}
      value={getValue({ input, formValue })}
      onChange={(v) => {
        setInput(v);
      }}
    />
  ) : (
    <Typography.Text strong style={{ fontSize: 18 }}>
      {formValue} %
    </Typography.Text>
  );
};

const RightSidebar = ({
  formikProps = {},
  readOnly = false,
  percentages = [],
}: {
  formikProps?: Record<string, any>;
  readOnly?: boolean;
  percentages?: Array<any>;
}) => {
  const { values, setFieldValue } = formikProps;
  const [checked, setChecked] = useState(true);
  const {
    account: { locale },
  } = useAccount();
  const translate = useTranslate();
  const [
    {
      focusedItems,
      search: { keyword, mode },
    },
  ] = useRebateGroupPercentagesState() as any;

  const currentType = coercedGet(values, 'type', '');
  const currentLevel = coercedGet(values, 'activeLevel', 0);

  const focusedItem = focusedItems.filter(
    (item: { level: number }) => item.level === currentLevel
  );
  const { gameType, vendorID } = focusedItem.length
    ? focusedItem[0]
    : { vendorID: 'None', gameType: 'SLOT' };

  const { loading, data } = useQuery(GAMES_QUERY, {
    skip: vendorID === 'None',
    variables: {
      filter: {
        vendor: {
          eq: vendorID,
        },
        type: {
          eq: gameType,
        },
        ...(mode !== SearchTypes.GAME_NAME
          ? {
              name: {
                startsWith: '',
              },
            }
          : {
              name: {
                contains: keyword,
              },
            }),
      },
    },
  });

  const getGameValue = ({
    id,
    nameEn,
    nameZh,
    nameZhHant,
    stakes,
  }: GameValueProps) => {
    let houseEdgeValue: number | string = 0;
    const rtp = coercedGet(stakes, 'rtp', 1);

    if (rtp <= 1 && rtp > 0.1) {
      houseEdgeValue = (rtp * 100).toFixed(1);
    } else if (rtp < 0.1) {
      houseEdgeValue = (100 - rtp * 100).toFixed(1);
    } else {
      houseEdgeValue = rtp;
    }

    const threshold = (100 - +houseEdgeValue).toFixed(1);

    return {
      key: id,
      nameEn,
      nameZh,
      nameZhHant,
      houseEdge: houseEdgeValue,
      threshold,
    };
  };

  const games = esGet(data?.games.edges, []);
  const results = games.map(({ node }: { node: GameValueProps }) =>
    getGameValue(node)
  );

  const parentInputName = `${
    currentType === CreateRebateGroupTypes.PERIODIC
      ? `levels[${currentLevel}][percentages]`
      : 'percentages'
  }[${gameType}_${vendorID}][percentage]`;

  const getPercentageValue = (
    row: { key: string },
    selectedGameType: string,
    selectedVendorID: string
  ) => {
    const inputName = `${
      currentType === CreateRebateGroupTypes.PERIODIC
        ? `levels[${currentLevel}][percentages]`
        : 'percentages'
    }[${selectedGameType}_${selectedVendorID}][games][${row.key}]`;

    const value = coercedGet(values, `${inputName}[percentage]`, null);

    const parentValue = coercedGet(values, parentInputName, 0);

    return value === null ? parentValue : value;
  };

  const getROPercentageValue = (
    row: { key: string },
    selectedGameType: string,
    selectedVendorID: string
  ) => {
    const readOnlyGames = coercedGet(
      percentages.find(
        ({ vendor, gameType: readOnlyGametype }) =>
          vendor.id === selectedVendorID &&
          readOnlyGametype === selectedGameType
      ),
      'games',
      []
    );

    const readOnlyValue = coercedGet(
      readOnlyGames.find(
        ({
          game,
        }: {
          game: {
            id: string;
          };
        }) => game.id === row.key
      ),
      'percentage',
      null
    );

    const readOnlyParentValue = coercedGet(
      percentages.find(
        ({ vendor, gameType: readOnlyGametype }) =>
          vendor.id === selectedVendorID &&
          readOnlyGametype === selectedGameType
      ),
      'percentage',
      0
    );

    return +Number(
      (readOnlyValue === null ? readOnlyParentValue : readOnlyValue) * 100
    ).toFixed(2);
  };

  const inputMultiplierName = `${
    currentType === CreateRebateGroupTypes.PERIODIC
      ? `levels[${currentLevel}][percentages]`
      : 'percentages'
  }[${gameType}_${vendorID}][multiplier]`;

  const inputChecked = `${
    currentType === CreateRebateGroupTypes.PERIODIC
      ? `levels[${currentLevel}][percentages]`
      : 'percentages'
  }[${gameType}_${vendorID}][checked]`;

  const handleGameInputChange = (
    row: { key: string },
    selectedGameType: string,
    selectedVendorID: string,
    value: string | number
  ) => {
    const inputName = `${
      currentType === CreateRebateGroupTypes.PERIODIC
        ? `levels[${currentLevel}][percentages]`
        : 'percentages'
    }[${selectedGameType}_${selectedVendorID}][games][${row.key}]`;

    setFieldValue(`${inputName}[percentage]`, +value);
    setFieldValue(`${inputName}[game]`, row.key);

    if (checked) {
      setFieldValue(inputChecked, false);
    }
  };

  const gameName = (name: DynamicObj, lang: string) => {
    if (lang === 'zh' && name.nameZhHant !== null) {
      return name.nameZhHant;
    }
    if (lang === 'zh' && name.nameZh !== null) {
      return name.nameZh;
    }
    return name.nameEn;
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, row: any, index: number) =>
        games.length && vendorID !== 'None' ? (
          <Typography.Text key={index} style={{ fontSize: '0.75rem' }}>
            {gameName(row, locale)}
          </Typography.Text>
        ) : (
          <></>
        ),
    },
    {
      title: 'Input',
      dataIndex: 'input',
      key: 'input',
      render: (_: any, row: any, index: number) =>
        games.length && vendorID !== 'None' ? (
          <CustomInputNumber
            readOnly={readOnly}
            key={index}
            size="small"
            min={0}
            max={100}
            formatter={(value: any) => `${value}%`}
            parser={(value: any) => value.replace('%', '')}
            onFinishChange={(value) => {
              handleGameInputChange(row, gameType, vendorID, value);
            }}
            formValue={
              !readOnly
                ? getPercentageValue(row, gameType, vendorID)
                : getROPercentageValue(row, gameType, vendorID)
            }
          />
        ) : (
          <></>
        ),
    },
    {
      title: 'houseEdge',
      dataIndex: 'houseEdge',
      key: 'houseEdge',
      render: (_: any, row: any, index: number) => {
        const currentRebate = !readOnly
          ? getPercentageValue(row, gameType, vendorID)
          : getROPercentageValue(row, gameType, vendorID);

        return games.length && vendorID !== 'None' ? (
          <StyledTag
            color={
              Number(currentRebate) < Number(row.threshold)
                ? '#27c989'
                : '#f03434'
            }
            key={index}
          >
            {row.houseEdge}%
          </StyledTag>
        ) : (
          <></>
        );
      },
    },
  ];

  const checkValue = get(values, inputChecked, true);

  useEffect(() => {
    if (checkValue !== checked) {
      setChecked(checkValue);
    }
  }, [setChecked, checked, checkValue]);

  return (
    <StyledSider
      className="pl-3"
      style={{
        paddingTop: 46,
      }}
    >
      <StyledRow className="pt-2">
        <Col span={readOnly ? 11 : 12}>
          <Typography>{translate(messages.GAME_REBATE_RATIO)}</Typography>
        </Col>
        <Col offset={6} span={6}>
          <Typography>
            {focusedItem.length && focusedItem[0].level === currentLevel
              ? results.length
              : 0}{' '}
            {translate(messages.RESULTS)}
          </Typography>
        </Col>
      </StyledRow>
      {!readOnly && (
        <Alert
          style={{
            backgroundColor: '#e8e8e8',
            border: '1px solid #e8e8e8',
          }}
          className="px-2 my-2"
          message={
            <Row>
              <Col span={10}>
                <FormattedMessage
                  id="member-detail.tab-rebate.text"
                  defaultMessage="Rebate"
                />
              </Col>
              <Col span={10}>
                <InputNumber
                  size="small"
                  name={inputMultiplierName}
                  value={coercedGet(values, inputMultiplierName, 0)}
                  onChange={(value) =>
                    setFieldValue(inputMultiplierName, value)
                  }
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value: any) => value.replace('%', '')}
                />
              </Col>
              <Col span={4}>
                <Button
                  size="small"
                  className="p-0"
                  type="link"
                  onClick={() => {
                    setChecked(false);

                    const multiplier = coercedGet(
                      values,
                      inputMultiplierName,
                      0
                    );

                    results.forEach(
                      ({
                        key,
                        threshold,
                      }: {
                        key: string;
                        threshold: number;
                      }) => {
                        const calculatedValue = (
                          (threshold / 100) *
                          (multiplier / 100) *
                          100
                        ).toFixed(2);

                        const selectedInputName = `${
                          currentType === CreateRebateGroupTypes.PERIODIC
                            ? `levels[${currentLevel}][percentages]`
                            : 'percentages'
                        }[${gameType}_${vendorID}][games][${key}]`;

                        setFieldValue(
                          `${selectedInputName}[percentage]`,
                          calculatedValue
                        );
                        setFieldValue(`${selectedInputName}[game]`, key);
                      }
                    );
                  }}
                >
                  {translate(messages.APPLY)}
                </Button>
              </Col>
            </Row>
          }
        />
      )}
      {!readOnly && (
        <Row className="mt-3 mb-2">
          <Col span={17}>
            <Typography style={{ fontSize: '0.75rem' }}>
              {translate(messages.APPLY_TO_ALL_GAMES)}
            </Typography>
          </Col>
          <Col span={7}>
            <Row>
              <Col span={16}>
                <StyledSwitch
                  checked={checked}
                  onChange={(e) => {
                    if (e) {
                      const targetinputName = `${
                        currentType === CreateRebateGroupTypes.PERIODIC
                          ? `levels[${currentLevel}][percentages]`
                          : 'percentages'
                      }[${gameType}_${vendorID}][games]`;

                      setFieldValue(targetinputName, undefined);
                    }

                    setChecked(e);
                    setFieldValue(inputChecked, e);
                  }}
                />
              </Col>
              <Col span={8}>
                <small>
                  <Typography.Text strong>
                    {checked
                      ? translate(messages['on.text'])
                      : translate(messages['off.text'])}
                  </Typography.Text>
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {!readOnly && checked && (
        <Row>
          <Col offset={16}>
            <InputNumber
              size="small"
              defaultValue={0}
              min={0}
              max={100}
              onChange={(e) => {
                if (focusedItems.length) {
                  const gamesName = `${
                    currentType === CreateRebateGroupTypes.PERIODIC
                      ? `levels[${currentLevel}][percentages]`
                      : 'percentages'
                  }[${gameType}_${vendorID}][games]`;

                  setFieldValue(gamesName, undefined);
                  if (e) {
                    setFieldValue(parentInputName, +e);
                  }
                }
              }}
              value={coercedGet(values, parentInputName, 0)}
              formatter={(value) => `${value}%`}
              parser={(value: any) => value.replace('%', '')}
            />
          </Col>
        </Row>
      )}
      {games.length ? <StyledRow className="mt-3 pt-1" /> : <></>}

      <StyledTable
        readOnly={readOnly}
        rowKey="id"
        size="small"
        columns={columns}
        dataSource={results}
        pagination={false}
        loading={loading}
        footer={() =>
          !readOnly ? (
            <Row className="mt-2">
              <Col span={6}>
                <Button
                  size="small"
                  className="p-0"
                  type="link"
                  style={{ fontSize: '0.8rem' }}
                  onClick={() => {
                    const targetName = `${
                      currentType === CreateRebateGroupTypes.PERIODIC
                        ? `levels[${currentLevel}][percentages]`
                        : 'percentages'
                    }`;

                    setFieldValue(
                      `${targetName}[${gameType}_${vendorID}]`,
                      null
                    );
                  }}
                >
                  {translate(messages.CLEAR_ALL)}
                </Button>
              </Col>
            </Row>
          ) : (
            <></>
          )
        }
      />
    </StyledSider>
  );
};

export default RightSidebar;
