import React from 'react';
import coercedGet from 'utils/coercedGet';
import { Tag } from 'antd';
import { get, startCase } from 'lodash';
import { formatDate } from 'utils/dateUtils';

type Props = {
  filters: Record<string, any>;
  loading: boolean;
  onFilterChange: Function;
};

export const FilterConditions = ({
  filters,
  loading,
  onFilterChange,
}: Props) => {
  const handleRemove = ({
    event,
    dataToRemove,
    filterKey,
  }: {
    event: React.MouseEvent<HTMLElement>;
    dataToRemove: string;
    filterKey: string;
  }) => {
    event.preventDefault();
    if (!loading) {
      const data = get(filters[filterKey], 'in', []).filter(
        (item: string) => item !== dataToRemove
      );
      const newFilter = {
        in: data,
      };
      onFilterChange({
        ...filters,
        [filterKey]: data.length ? newFilter : null,
      });
    }
  };

  const FilterTag = ({ filterKey }: { filterKey: string }) =>
    coercedGet(filters, `${filterKey}.in`, []).map((item: string) => (
      <Tag
        style={{ margin: '4px' }}
        key={item}
        closable
        onClose={(e) =>
          handleRemove({
            event: e,
            dataToRemove: item,
            filterKey,
          })
        }
      >
        {item}
      </Tag>
    ));

  return (
    <>
      Filter Conditions:
      <FilterTag filterKey="name" />
      {filters.type && (
        <Tag
          closable
          onClose={(e) => {
            e.preventDefault();
            onFilterChange({
              ...filters,
              type: null,
            });
          }}
        >
          {startCase(filters.type.eq)}
        </Tag>
      )}
      {filters.startDateTime && filters.endDateTime && (
        <Tag
          closable
          onClose={(e) => {
            e.preventDefault();
            onFilterChange({
              ...filters,
              startDateTime: null,
              endDateTime: null,
            });
          }}
        >
          Promo Start/End Date: {formatDate(filters.startDateTime?.gte)} ~{' '}
          {formatDate(filters.endDateTime?.lte)}
        </Tag>
      )}
    </>
  );
};
