import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import { Input } from 'antd';

export const StyledDeleteIcon = styled(DeleteOutlined)`
  font-size: 1rem;
  color: #ff3e3e;
`;

export const StyledInput = styled(Input)`
  height: 32px;
  width: 550px;
  margin-right: 8px;
`;
