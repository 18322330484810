import { Form, Input } from 'antd';
import React from 'react';
import { Control, FieldError, Controller } from 'react-hook-form';
import { StyledNumberInput } from 'components/Antd/StyledNumberInput/style';

type Props = {
  control: Control;
  currencySymbol: string | undefined | null;
  fieldError: {
    amount: FieldError | undefined;
    maxMemberCount: FieldError | undefined;
  };
};

const { Item } = Form;

const SignUpPromoFields = ({ control, fieldError, currencySymbol }: Props) => {
  const { amount, maxMemberCount } = fieldError || {};

  return (
    <>
      <Item
        labelCol={{
          span: 8,
        }}
        label="Enter Promo Award"
        validateStatus={amount && 'error'}
        help={amount && amount?.message}
      >
        <Controller
          control={control}
          name="amount"
          rules={{
            required: {
              value: true,
              message: 'Promo award is required',
            },
            min: {
              value: 1,
              message: 'Promo award must be greater than 0.',
            },
          }}
          render={({ onChange, value }) => (
            <Input
              value={value}
              onChange={(event) => onChange(+event.target.value)}
              type="number"
              min={0}
              style={{ width: 120 }}
              addonBefore={currencySymbol}
            />
          )}
        />
      </Item>
      <Item
        labelCol={{
          span: 8,
        }}
        label="Max Promo Available for Subscription"
        validateStatus={maxMemberCount && 'error'}
        help={maxMemberCount && maxMemberCount?.message}
      >
        <Controller
          control={control}
          name="maxMemberCount"
          rules={{
            required: {
              value: true,
              message: 'Promo max subscription count is required',
            },
          }}
          render={({ onChange, value }) => (
            <StyledNumberInput
              type="number"
              min={0}
              style={{ width: 120 }}
              value={value}
              onChange={(event) => onChange(+event.target.value)}
            />
          )}
        />
      </Item>
    </>
  );
};

export default SignUpPromoFields;
