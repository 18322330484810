export const thirdPartyTypeNames = {
  diorPay: 'DiorPayDepositProvider',
  midPay: 'MidPayDepositProvider',
  midPayDirect: 'MidPayDirectDepositProvider',
  diorPay2: 'DiorPay2DepositProvider',
};

export default {
  DIORPAY2: 'DIORPAY2',
  DIORPAY: 'DIORPAY',
  MIDPAY: 'MIDPAY',
  MIDPAY_DIRECT: 'MIDPAY_DIRECT',
  MIDPAY_DIRECT_2: 'MIDPAY DIRECT',
};
