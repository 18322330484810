import React from 'react';
import * as moment from 'moment-timezone';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import { FilterOutlined } from '@ant-design/icons';
// import messages from 'messages';
import { Tooltip } from 'antd';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import localMessages from './messages';
import { StyledContent, StyledRow, StyledCol, StyledFilter } from './styles';
import VendorNames from '../VendorNames';
import ClientNames from '../ClientNames';

type Props = {
  filter: Record<string, any>;
};

const FilterStatus: React.FC<Props> = ({ filter }) => {
  const translate = useTranslate();
  const { bypassedCurrentTimezone }: any = useReportsTimezone();
  const dateTimeProcGte = coercedGet(filter, 'dateTimeProcessed.gte', null);
  const dateTimeProcLte = coercedGet(filter, 'dateTimeProcessed.lte', null);
  const betStatusFilt = coercedGet(filter, 'status.in', []);
  const vendorFilts = coercedGet(filter, 'vendor.in', []);
  const clientFilts = coercedGet(filter, 'admin.in', []);

  const getToolTipComponent = () => (
    <StyledContent>
      <StyledRow>
        <StyledFilter>{translate(localMessages.FILTERS)}</StyledFilter>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          <span>{translate(localMessages.PERIOD)}</span>
        </StyledCol>
        <StyledCol>
          <span>
            {moment
              .tz(dateTimeProcGte, bypassedCurrentTimezone)
              .format('YYYY/MM/DD HH:mm')}{' '}
            - <br />
            {moment
              .tz(dateTimeProcLte, bypassedCurrentTimezone)
              .format('YYYY/MM/DD HH:mm')}
          </span>
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <div>
          <span>{translate(localMessages.VENDOR)}</span>
        </div>
        <div>
          {' '}
          <VendorNames vendorIds={vendorFilts} />
        </div>
      </StyledRow>
      <StyledRow>
        <div>
          <span>{translate(localMessages.BET_STATUS)}</span>
        </div>
        <div>
          <span>
            {betStatusFilt.length
              ? betStatusFilt.map((item: string, index: number) => {
                  if (index === betStatusFilt.length - 1)
                    return <span>{item}</span>;
                  return <span>{`${item}, `}</span>;
                })
              : '-'}
          </span>
        </div>
      </StyledRow>
      <StyledRow>
        <div>
          <span>{translate(localMessages.CLIENT_ACCOUNTS)}</span>
        </div>
        <div>
          <ClientNames adminIds={clientFilts} />
        </div>
      </StyledRow>
    </StyledContent>
  );

  return (
    <Tooltip placement="bottom" title={getToolTipComponent()}>
      <FilterOutlined
        className="ml-2"
        style={{ fontSize: 18, color: '#262626' }}
      />
    </Tooltip>
  );
};

export default FilterStatus;
