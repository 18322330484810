import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useAccount } from 'store/accountState';
import { Select } from 'antd';
import { useDebounce } from 'hooks/useDebounce';
import { Place, PlacesConnection } from 'types/graphqlTypes';
import { sortBy } from 'lodash';
import { GET_LOCATIONS } from './queries';

type Props = {
  value: string;
  onChange: (id: string) => void;
};

const LocationSelect: FC<Props> = ({ onChange, value }) => {
  const [searchInput, setSearchInput] = useState('');
  const [locationOptions, setLocationOptions] = useState<(Place | undefined)[]>(
    []
  );

  const debounceSearchInput = useDebounce(searchInput, 500);

  const {
    account: { locale },
  } = useAccount();

  const [getAllLocations, { loading }] = useLazyQuery<{
    places: PlacesConnection;
  }>(GET_LOCATIONS, {
    fetchPolicy: 'network-only',
    variables: {
      first: 300,
      filter: {
        displayName: { contains: debounceSearchInput },
        type: { eq: 'COUNTRY' },
      },
      language: locale.toUpperCase(),
    },
    onCompleted: (data) => {
      const locations = data.places.edges.map((edge) => edge?.node);
      const sortedLoc = sortBy(locations, ['displayName']);

      setLocationOptions(sortedLoc);
    },
  });

  useEffect(() => {
    if (!loading) {
      getAllLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchInput]);

  return (
    <>
      <Select
        showSearch
        allowClear
        onChange={onChange}
        value={value}
        onSearch={(val) => setSearchInput(val)}
        placeholder="Select a Location"
        loading={loading}
        filterOption={(input, option) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onClear={() => setSearchInput('')}
        onFocus={() => setSearchInput('')}
        onBlur={() => setSearchInput('')}
      >
        {locationOptions?.map((location) => {
          const { id, displayName } = location!;
          return (
            <Select.Option key={id} value={id}>
              {displayName}
            </Select.Option>
          );
        })}
      </Select>
    </>
  );
};

export default LocationSelect;
