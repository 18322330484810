import { Select, Spin } from 'antd';
import useTranslate from 'utils/useTranslate';
import React from 'react';
import messages from 'messages';

const defaultProps = {
  loading: false,
};

type Props = {
  values: number[];
  page: any;
  setPage?: (e: any) => void;
  setPageViaPassingValue?: (e: any) => void;
} & typeof defaultProps;

const TableFooter = ({
  values,
  setPage,
  setPageViaPassingValue,
  page,
  loading,
}: Props) => {
  const translate = useTranslate();
  return (
    <Spin spinning={loading}>
      <Select
        data-testid="table-entries"
        aria-label="entries"
        onChange={(e: any) => {
          if (setPageViaPassingValue) {
            setPageViaPassingValue({
              ...page,
              first: e,
              after: undefined,
              savedCursor: [''],
              currentPage: 0,
            });
            return;
          }

          if (setPage) {
            setPage((prev: any) => ({
              ...prev,
              first: e,
              after: undefined,
              savedCursor: [''],
              currentPage: 0,
            }));
          }
        }}
        className="ml-3"
        defaultValue={page.first}
      >
        {values.map((value: number) => (
          <Select.Option value={value} key={value}>
            {`${value || ''} ${translate(messages['entries.text'])}`}
          </Select.Option>
        ))}
      </Select>
    </Spin>
  );
};

TableFooter.defaultProps = defaultProps;

export default TableFooter;
