import { LayoutOutlined, PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Button, Layout, Spin } from 'antd';
import { withErrorHandler } from 'components/ErrorHandler';
import { VENDORS } from 'graphql/queries/vendor.query';
import { get } from 'lodash';
import { StyledCenter } from 'pages/styles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import AffiliateDetails from './components/AffiliateDetails';
import AgentAffiliateTable from './components/AgentAffiliateTable';
import CreateAffiliateProgramme from './components/CreateAffiliateProgramme';
import { CreateAffiliateProgrammeProvider } from './components/CreateAffiliateProgramme/context';
import Sidebar from './components/Sidebar';
import { AffiliateTypes, useAffiliateState } from './context';

const Affiliate = () => {
  const [isSidebar, setSidebar] = useState(false);
  const handleSidebar = () => setSidebar(!isSidebar);

  const [affiliateState, dispatch] = useAffiliateState() as any;

  const { loading } = useQuery(VENDORS, {
    onCompleted: (res) => {
      const vendors = get(res, 'vendors.edges', []);
      const defaultVendorHandlers = vendors.reduce(
        (acc: Record<string, any>, { node }: { node: Record<string, any> }) => {
          const gameTypes = get(node, 'gameTypes', []);
          let newCharges = {};
          gameTypes.map((gameType: string) => {
            newCharges = {
              ...newCharges,
              [`${gameType}_${node.id}`]: {
                vendor: node.id,
                gameType,
              },
            };
            return null;
          });
          return { ...acc, ...newCharges };
        },
        {}
      );
      dispatch({
        type: AffiliateTypes.SET_DEFAULT_VENDORS,
        payload: vendors,
      });
      dispatch({
        type: AffiliateTypes.SET_DEFAULT_VENDOR_HANDLERS,
        payload: defaultVendorHandlers,
      });
    },
  });

  if (loading) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  const showForm = (record: Record<string, any>) => {
    if (record?.id) {
      dispatch({
        type: AffiliateTypes.SET_ACTIVE_AFFILIATE_PROGRAMME,
        payload: record.id,
        meta: record,
      });
    }
    dispatch({
      type: AffiliateTypes.SHOW_CREATE_AFFILIATE_PROGRAMME,
    });
  };

  return (
    <Root>
      {affiliateState.activeScreen === AffiliateTypes.AGENT_AFFILIATE_LIST && (
        <>
          <Sidebar collapsed={isSidebar}>Sider</Sidebar>
          <Layout.Content className="p-4">
            <div className="d-flex justify-content-space-between mb-6">
              <Button onClick={handleSidebar} size="small" className="mt-1">
                <LayoutOutlined />
              </Button>
              <Button
                data-testid="create-programme"
                type="primary"
                icon={<PlusOutlined />}
                onClick={showForm}
              >
                <FormattedMessage
                  id="create-programme.text"
                  defaultMessage="Create Programme"
                />
              </Button>
            </div>
            <div>
              <AgentAffiliateTable showForm={showForm} />
            </div>
          </Layout.Content>
        </>
      )}
      {affiliateState.activeScreen ===
        AffiliateTypes.AGENT_AFFILIATE_DETAILS && (
        <AffiliateDetails showForm={showForm} />
      )}
      <CreateAffiliateProgrammeProvider>
        <CreateAffiliateProgramme />
      </CreateAffiliateProgrammeProvider>
    </Root>
  );
};

const Root = styled(Layout)`
  background-color: #fff;
  height: 100%;
`;

export default withErrorHandler(Affiliate);
