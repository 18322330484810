import React from 'react';

import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { messages } from './messages';
import { customFormatMessage } from '../../../../../../utils/customFormatMessage';

const defaultProps = { message: { title: '', content: '' } };

type Props = {} & typeof defaultProps;

export const MessageTooltip = ({ message }: Props) => {
  const { formatMessage } = useIntl();
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  const title = `${translate(messages['title.text'])}: ${message?.title ||
    '-'}`;

  const content = `${translate(messages['content.text'])}: ${message?.content ||
    '-'}`;

  return (
    <Tooltip
      placement="bottomLeft"
      title={
        <>
          <div style={{ marginBottom: '8px' }}>{title}</div>
          <div>{content}</div>
        </>
      }
      overlayStyle={{ fontSize: '10px' }}
    >
      <span>{title}</span>
      <br />
      <span>{content}</span>
    </Tooltip>
  );
};

MessageTooltip.defaultProps = defaultProps;
