/* eslint-disable no-bitwise */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from 'antd';
import Numeral from 'numeral';

export const DEFAULT_ROW_COUNT = 6;
const { Text } = Typography;

export default [
  {
    title: (
      <FormattedMessage id="reports.vendor.text" defaultMessage="Vendor" />
    ),
    dataIndex: 'vendor',
    render: (vendor: { name: string }) => vendor.name,
  },
  {
    title: (
      <FormattedMessage
        id="reports.game-type.text"
        defaultMessage="Game Type"
      />
    ),
    dataIndex: 'gameType',
  },
  {
    title: (
      <FormattedMessage id="reports.turnover.text" defaultMessage="Turnover" />
    ),
    dataIndex: 'turnover',
    render: (text: string) => Numeral(text).format('0.00', (n) => ~~n),
  },
  {
    title: (
      <FormattedMessage
        id="reports.effective-bet.text"
        defaultMessage="Effective Bet"
      />
    ),
    dataIndex: 'effectiveBet',
    render: (text: string) => Numeral(text).format('0.00', (n) => ~~n),
  },
  {
    title: (
      <FormattedMessage id="reports.winloss.text" defaultMessage="Winloss" />
    ),
    dataIndex: 'winloss',
    render: (text: number) => {
      const color = text < 0 ? '#f5222d' : '#52c41a';
      return (
        <Text style={{ color }}>
          {Numeral(text).format('0.00', (n) => ~~n)}
        </Text>
      );
    },
  },
  {
    title: (
      <FormattedMessage
        id="reports.number-of-bet.text"
        defaultMessage="Number of Bet"
      />
    ),
    dataIndex: 'betsCount',
    render: (text: string) => Numeral(text).format('0.00', (n) => ~~n),
  },
  {
    title: (
      <FormattedMessage
        id="reports.profit-ratio.text"
        defaultMessage="Profit Ratio"
      />
    ),
    dataIndex: 'profitRatio',
    render: (text: string | number) => {
      const color = text < 0 ? '#f5222d' : '#52c41a';
      return (
        <Text style={{ color }}>
          {Numeral(text).format('0.00', (n) => ~~n)}
        </Text>
      );
    },
  },
];
