import React from 'react';
import { shape, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { assignedFilterKeysByTypename } from 'pages/components/SystemMessage/ManualMessage/components/SystemMessageSidebar/utils';
import { multiSelectType } from 'pages/components/SystemMessage/ManualMessage/components/CreateNewMail/util';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { MultiMembersSelectSidebar } from '../MultiMembersSelectSidebar/MultiMembersSelectSidebar';

export const ExcludedMembersFilter = ({ values, setFilters }) => {
  const handleOnsSelectMembers = (e) => {
    setFilters(assignedFilterKeysByTypename(e, 'excluded'));
  };

  return (
    <FilterItem
      label={
        <FormattedMessage
          id="system-message.excluded-members.text"
          defaultMessage="Excluded Members"
        />
      }
    >
      <MultiMembersSelectSidebar
        multiSelectType={multiSelectType.SIDEBAR}
        values={values}
        onSelectMembers={handleOnsSelectMembers}
      />
    </FilterItem>
  );
};

ExcludedMembersFilter.propTypes = {
  values: shape({}),
  setFilters: func,
};

ExcludedMembersFilter.defaultProps = {
  values: {},
  setFilters: (e) => e,
};
