import { defineMessages } from 'react-intl';

const messages = defineMessages({
  CATEGORY_ICON: {
    id: 'CATEGORY_ICON',
    defaultMessage: 'Category Icon',
  },
  CATEGORY_BANNER_IMAGE: {
    id: 'CATEGORY_BANNER_IMAGE',
    defaultMessage: 'Category Banner Image',
  },
  CATEGORY_HERO_IMAGE: {
    id: 'CATEGORY_HERO_IMAGE',
    defaultMessage: 'Category Hero Image',
  },
  'category-icon.text': {
    id: 'category-icon.text',
    defaultMessage: 'Category Icon',
  },
  CATEGORY_DESKTOP_ICON: {
    id: 'CATEGORY_DESKTOP_ICON',
    defaultMessage: 'Category Desktop Icon',
  },
  SLOT: {
    id: 'slot.text',
    defaultMessage: 'Slot',
  },
  TABLE: {
    id: 'table.text',
    defaultMessage: 'Table',
  },
  LIVE: {
    id: 'live-casino.text',
    defaultMessage: 'Live Casino',
  },
  POKER: {
    id: 'poker.text',
    defaultMessage: 'Poker',
  },
  FISH: {
    id: 'fish.text',
    defaultMessage: 'Fish',
  },
  SPORTSBOOK: {
    id: 'sportsbook.text',
    defaultMessage: 'Sportsbook',
  },
  LOTTERY: {
    id: 'lottery.text',
    defaultMessage: 'Lottery',
  },
  SCRATCH: {
    id: 'scratch.text',
    defaultMessage: 'Scratch',
  },
  EXISTING_ICON: {
    id: 'EXISTING_ICON',
    defaultMessage: 'Existing Icon',
  },
  EXISTING_BANNER: {
    id: 'EXISTING_BANNER',
    defaultMessage: 'Existing Banner',
  },
  EXISTING_HERO_IMAGE: {
    id: 'EXISTING_HERO_IMAGE',
    defaultMessage: 'Existing Hero Image',
  },
  EXISTING_DESKTOP_ICON: {
    id: 'EXISTING_DESKTOP_ICON',
    defaultMessage: 'Existing Desktop Icon',
  },
  MASCOT_IMAGE: {
    id: 'mascot-image.text',
    defaultMessage: 'Mascot Image',
  },
  BACKGROUND_IMAGE: {
    id: 'background-image.text',
    defaultMessage: 'Background Image',
  },
  EXISTING_MASCOT_IMAGE: {
    id: 'existing-mascot-image.text',
    defaultMessage: 'Existing Mascot Image',
  },
  EXISTING_BACKGROUND_IMAGE: {
    id: 'existing-background-image.text',
    defaultMessage: 'Existing Background Image',
  },
});
export default messages;
