import React, { useState } from 'react';
import { Button, Col, Empty, Tabs as TempTab } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { generalSettingsRender } from '../../utils';
import { DesktopPreviewModal } from './DesktopPreviewModal';
import { MobilePreviewBanner } from './MobilePreviewBanner';
import { BannerProps } from './types';
import { LandscapePreviewModal } from './LandscapePreviewModal';

const Tabs = styled(TempTab)`
  .ant-tabs-tab {
    padding: 12px 16px;
  }
`;

const { TabPane } = Tabs;

export const BannerInfo = ({ selectedBanner }: BannerProps) => {
  const [desktopPreviewState, setDesktopPreviewState] = useState(false);
  const [landscapePreviewState, setLandscapePreviewState] = useState(false);

  return (
    selectedBanner && (
      <Col span={6} className="bl-1" style={{ height: '100%' }}>
        <div className="p-3">
          <h3>{selectedBanner.name}</h3>
        </div>
        <Tabs
          defaultActiveKey="1"
          onChange={(e) => {
            if (e === '2') {
              setDesktopPreviewState(true);
            }
          }}
        >
          <TabPane
            tab={
              <FormattedMessage
                id="mobile-preview.text"
                defaultMessage="Mobile Preview"
              />
            }
            key="1"
          >
            <div>
              {selectedBanner.imageMobile && (
                <MobilePreviewBanner
                  desktopPreviewState={desktopPreviewState}
                  setDesktopPreviewState={setDesktopPreviewState}
                  selectedBanner={selectedBanner}
                />
              )}
              {!selectedBanner.imageMobile && <Empty />}
            </div>
          </TabPane>
          {/* DESKTOP */}
          <TabPane
            tab={
              <FormattedMessage
                id="desktop-preview.text"
                defaultMessage="Desktop Preview"
              />
            }
            key="2"
          >
            {selectedBanner.imageDesktop && (
              <div className="d-flex justify-content-center">
                <Button onClick={() => setDesktopPreviewState(true)}>
                  <FormattedMessage
                    id="preview.text"
                    defaultMessage="Preview"
                  />
                </Button>
              </div>
            )}
            {!selectedBanner.imageDesktop && <Empty />}
          </TabPane>
          {/* DESKTOP */}
          {/* Landscape */}
          {/* <TabPane */}
          {/*  tab={ */}
          {/*    <FormattedMessage */}
          {/*      id="landscape-preview.text" */}
          {/*      defaultMessage="Landscape Preview" */}
          {/*    /> */}
          {/*  } */}
          {/*  key="3" */}
          {/* > */}
          {/*  {selectedBanner.imageHorizontal && ( */}
          {/*    <div className="d-flex justify-content-center"> */}
          {/*      <Button onClick={() => setLandscapePreviewState(true)}> */}
          {/*        <FormattedMessage */}
          {/*          id="preview.text" */}
          {/*          defaultMessage="Preview" */}
          {/*        /> */}
          {/*      </Button> */}
          {/*    </div> */}
          {/*  )} */}
          {/*  {!selectedBanner.imageHorizontal && <Empty />} */}
          {/* </TabPane> */}
          {/* Landscape */}
          <TabPane
            tab={
              <FormattedMessage
                id="general-settings.text"
                defaultMessage="General Settings"
              />
            }
            key="4"
          >
            {generalSettingsRender(selectedBanner)}
          </TabPane>
        </Tabs>

        {selectedBanner.imageDesktop && (
          <DesktopPreviewModal
            selectedBanner={selectedBanner}
            desktopPreviewState={desktopPreviewState}
            setDesktopPreviewState={setDesktopPreviewState}
          />
        )}
        {selectedBanner.imageHorizontal && (
          <LandscapePreviewModal
            selectedBanner={selectedBanner}
            landscapePreviewState={landscapePreviewState}
            setLandscapePreviewState={setLandscapePreviewState}
          />
        )}
      </Col>
    )
  );
};
