import React, { useCallback, useEffect, useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Table } from 'antd';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import gql from 'graphql-tag';
import { has, isEmpty } from 'lodash';

import { useApolloClient } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { columnAlign } from 'utils/tableAlignment';
import ResizableTitle from './components/ResizableTitle';
import EditMemberLevel from '../../../EditMemberLevel';
import DeleteMemberLevel from '../../../DeleteMemberLevel';
import columnsInit from './columns';
import ChangeStatus from './components/ChangeStatus';

const statusTypes = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
};

const MEMBERS = gql`
  query Members($filter: MembersFilterInput!) {
    members(filter: $filter) {
      edges {
        node {
          id
          memberLevel {
            id
          }
        }
      }
    }
  }
`;

type Props = {
  memberLevels: Record<string, any>[];
  loading: boolean;
};

const MemberLevelsTable = ({ memberLevels, loading }: Props) => {
  const { role, permissions } = usePermissions();
  const client = useApolloClient();
  const {
    ALLOWED_EDIT,
    ALLOWED_DELETE,
    ALLOWED_TOGGLE_ACTIVATION,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'EDIT', 'DELETE', 'TOGGLE_ACTIVATION'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );
  const [memberMarkerObj, setMemberMarkerObj] = useState({});
  const translate = useTranslate();
  const columns = [
    ...columnsInit(translate),
    {
      key: 'action',
      title: translate(messages['actions.text']),
      align: columnAlign.onCenter,
      width: 50,
      render: (node: Record<string, any>) => {
        const hasCount = memberMarkerObj[node.id];
        const disabled = hasCount || node.default;

        return (
          (ALLOWED_EDIT || ALLOWED_DELETE || ALLOWED_TOGGLE_ACTIVATION) && (
            <Dropdown
              overlay={
                <Menu>
                  {ALLOWED_EDIT && (
                    <Menu.Item key="0">
                      <EditMemberLevel memberLevel={node} />
                    </Menu.Item>
                  )}
                  {/* <Menu.Item>
                <DuplicateMemberLevel memberLevel={node} />>
              </Menu.Item> */}
                  {ALLOWED_DELETE && (
                    <Menu.Item
                      key="1"
                      disabled={node.status !== statusTypes.DISABLED}
                    >
                      <DeleteMemberLevel
                        memberLevel={node}
                        disabled={node.status !== statusTypes.DISABLED}
                      />
                    </Menu.Item>
                  )}
                  {ALLOWED_TOGGLE_ACTIVATION && (
                    <Menu.Item key="2" disabled={disabled}>
                      <ChangeStatus
                        disabled={disabled}
                        memberLevel={node}
                        count={hasCount || 0}
                      />
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomRight"
            >
              <Button type="link" icon={<EllipsisOutlined />} />
            </Dropdown>
          )
        );
      },
    },
  ];

  const fetchMembers = useCallback(
    async (memberLevelIds) => {
      const { data } = await client.query({
        query: MEMBERS,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            memberLevel: {
              in: memberLevelIds,
            },
          },
        },
      });

      const memberEdges = coercedGet(data, 'members.edges', []);
      const memberNodes = memberEdges.map(
        (edge: Record<string, any>) => edge.node
      );
      const dataMemberLevelsIds = memberNodes.map(
        (member: Record<string, any>) => member.memberLevel.id
      );

      const memberLevelsObj = dataMemberLevelsIds.reduce(
        (acc: Record<string, any>, cur: string) => {
          const newId = !has(acc, cur);
          if (newId) {
            acc[cur] = 1;
          } else {
            acc[cur] += 1;
          }

          return acc;
        },
        {}
      );
      setMemberMarkerObj(memberLevelsObj);
    },
    [client]
  );

  useEffect(() => {
    if (!isEmpty(memberLevels)) {
      const memberLevelIds = memberLevels.map(({ id }) => id);
      fetchMembers(memberLevelIds);
    }
  }, [fetchMembers, memberLevels]);

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const handleResize = (index: number) => (
    _: any,
    { size }: { size: Record<string, any> }
  ) => {
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: size.width,
    };
  };
  const newColumns = columns?.map((col, index) => ({
    ...col,
    onHeaderCell: (column: Record<string, any>) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const { filterColumns } = useCustomColumnsV2(
    'member-level-management',
    newColumns
  );

  return (
    <Table
      columns={filterColumns(newColumns)}
      size="middle"
      dataSource={memberLevels}
      pagination={false}
      scroll={{
        x: columns.map((item) => item.width).reduce((a, b) => a + b, 0),
      }}
      components={components}
      loading={loading}
    />
  );
};

export default MemberLevelsTable;
