import gql from 'graphql-tag';

export const MEMBER_LOYALTY_LEVELS = gql`
  query memberLoyaltyLevels(
    $status: MemberLoyaltyProgrammeStatusQueryOperatorInput
  ) {
    memberLoyaltyProgrammes(filter: { status: $status }) {
      edges {
        node {
          id
          name
          primary
          dateTimeCreated
          levels {
            id
            name
            color
            rank
          }
        }
      }
    }
  }
`;

export default {};
