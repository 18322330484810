import coercedGet from 'utils/coercedGet';
import { FilterStateType } from '../../types';

export const collateMemberFilterFields = (filterObj: FilterStateType) => {
  const memberUserName = coercedGet(filterObj, 'memberUserName.in', []);
  const memberRealName = coercedGet(filterObj, 'memberRealName.in', []);

  const hasGameFilt = memberUserName.length || memberRealName.length;

  return {
    ...filterObj,
    memberUserName: null,
    memberRealName: null,

    member: hasGameFilt
      ? {
          in: [...memberUserName, ...memberRealName],
        }
      : null,
  };
};
