import { useQuery } from '@apollo/react-hooks';
import Layout from 'components/Layout';
import { useFilterValues } from 'contexts/Filters';
import { DAILY_OPERATING_REPORT } from 'graphql/queries/dailyOperationReport.query';
import { Page } from 'interfaces/user.interface';
import { get } from 'lodash';
import DailyOperatingReportTable from 'pages/components/Reports/DailyOperatingReport/components/DailyOperatingReportTable';
import HeaderLeft from 'pages/components/Reports/DailyOperatingReport/components/HeaderLeft';
import HeaderRight from 'pages/components/Reports/DailyOperatingReport/components/HeaderRight';
import Sidebar from 'pages/components/Reports/DailyOperatingReport/components/Sidebar';
import React, { useEffect, useState } from 'react';
import coercedGet from 'utils/coercedGet';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { sortTransform } from 'utils/partialUtils';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const DEFAULT_ROW_COUNT = 10;

const pageInitState: Page = {
  after: undefined,
  savedCursor: [undefined] as any,
  currentPage: 0,
  first: DEFAULT_ROW_COUNT,
  sort: 'descend',
};

const DailyOperatingReport = ({ refreshPage }: { refreshPage: () => void }) => {
  // States
  const [page, setPage] = useState<Page>(pageInitState);
  const { filters } = useFilterValues();

  // Filter logic and derived variables
  const transformedFilters: any = transformMovableDateFilter({
    product: filters.product,
    ...(get(filters, 'date', null) && {
      date: {
        gte: get(filters, 'date.gte'),
        lte: get(filters, 'date.lte'),
      },
    }),
  });

  // DAILY_OPERATING_REPORT query related actions
  const queryVariables = {
    first: page.first,
    after: page.after,
    filter: transformedFilters,
    sort: {
      direction: sortTransform[page.sort],
    },
  };
  const { context } = useOperatorHeader();
  const { data, loading } = useQuery(DAILY_OPERATING_REPORT, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    context,
  });

  // Data output
  const pageInfo = coercedGet(data, 'dailyOperationReport.pageInfo', {});
  const totalCount = coercedGet(data, 'dailyOperationReport.totalCount', 0);
  const totalPage = Math.ceil(totalCount / page.first) || 1;
  const edges = coercedGet(data, 'dailyOperationReport.edges', []);
  const dataSource = edges.map((edge: { node: any }) => edge.node);

  // Pagination Actions
  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage] || undefined;
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const handleSort = (order: 'ascend' | 'descend') => {
    setPage((prev) => ({
      ...prev,
      sort: order,
    }));
  };

  // Side Effects
  useEffect(() => {
    setPage(pageInitState);
  }, [filters]);

  // JSX Rendered
  return (
    <Layout.Container data-testid={loading ? 'loading' : ''}>
      <Layout.Header
        leftNode={<HeaderLeft />}
        rightNode={
          <HeaderRight
            refetchTable={refreshPage}
            filter={queryVariables.filter}
          />
        }
      />

      <Layout.Content
        sideBar={<Sidebar />}
        footer={
          <Layout.Footer
            loading={loading}
            onNext={handleNext}
            onPrev={handlePrev}
            page={page}
            resultsCount={totalCount}
            totalPage={totalPage}
            setPage={setPage}
            pageInfo={pageInfo}
          />
        }
      >
        <DailyOperatingReportTable
          handleSort={handleSort}
          loading={loading}
          dataSource={dataSource}
        />
      </Layout.Content>
    </Layout.Container>
  );
};

export default DailyOperatingReport;
