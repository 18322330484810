import React from 'react';
import { useIntl } from 'react-intl';
import { message, Spin, Button } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { GET_PERMISSION_GROUP } from 'graphql/queries/permissionGroup.query';
import { UPDATE_PERMISSION_GROUP } from 'graphql/mutations/permissionGroup.mutation';
import messages from 'pages/components/PermissionGroup/messages';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { customFormatMessage } from 'utils/customFormatMessage';
import PermissionGroupForm from '../PermissionGroupForm';
import { StyledModal } from './styles';

const JsPermissionGroupForm: any = PermissionGroupForm; // TODO: delete after refactoring to TS file

type Props = {
  id: string | null;
  toggleModal: any;
  refetchVariables: object;
};

const EditPermissionGroup = (props: Props) => {
  const { toggleModal, id } = props;
  const { formatMessage } = useIntl();
  const editPermissionRef = React.createRef<any>();
  const refetchQueries = [
    {
      query: GET_PERMISSION_GROUP,
      variables: { id },
    },
  ];
  const { context } = useOperatorHeader();

  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);

  const { data, loading: queryLoading } = useQuery(GET_PERMISSION_GROUP, {
    variables: { id },
    fetchPolicy: 'network-only',
    context,
  });

  const [updatePermissionGroup, { loading }] = useMutation(
    UPDATE_PERMISSION_GROUP,
    {
      context,
      onCompleted: () => {
        message.success(translate(messages.UPDATED_PERMISSION_GROUP));
      },
      refetchQueries,
      awaitRefetchQueries: true,
    }
  );

  const permissionGroup = coercedGet(data, 'permissionGroup', {});

  return (
    <StyledModal
      closable={false}
      title={permissionGroup.name}
      visible
      onCancel={loading ? () => {} : toggleModal}
      width={1200}
      footer={
        <>
          <Button className="mr-2" onClick={toggleModal} disabled={loading}>
            {translate(messages.cancel)}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => {
              editPermissionRef.current.handleSubmit();
            }}
          >
            {translate(messages.save)}
          </Button>
        </>
      }
    >
      <div>
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 200 }}
          >
            <Spin />
          </div>
        ) : (
          <JsPermissionGroupForm
            ref={editPermissionRef}
            onClose={toggleModal}
            createPermissionGroup={updatePermissionGroup}
            loading={queryLoading}
            permissionGroup={permissionGroup}
          />
        )}
      </div>
    </StyledModal>
  );
};

export default EditPermissionGroup;
