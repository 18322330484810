import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import { VendorGroup } from 'types/graphqlTypes';
import { defineMessages } from 'react-intl';
import { DynamicObj } from 'interfaces/user.interface';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';

const VENDOR_GROUPS = gql`
  query VendorGroupsName($filter: VendorGroupsFilterInput) {
    vendorGroups(filter: $filter, first: 10) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const messagesLocal = defineMessages({
  SELECT_GROUP_NAME: {
    id: 'SELECT_GROUP_NAME',
    defaultMessage: 'Select group name',
  },
});

export const VendorGroupNameFilter = () => {
  const [filter, setFilter] = useState({});
  const { filters, setFilters } = useFilterValues();
  const translate = useTranslate();
  const { data, loading } = useQuery(VENDOR_GROUPS, {
    variables: {
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const onSearch = (searchValue: string) => {
    setFilter({
      name: {
        contains: searchValue,
      },
    });
  };

  const handleFilters = (selectedValue: string[]) => {
    if (!selectedValue || selectedValue.length === 0) {
      setFilters((prev: DynamicObj) => {
        const { name, ...restFilter } = prev;
        return restFilter;
      });
    } else {
      setFilters((prev: DynamicObj) => ({
        ...prev,
        name: { in: selectedValue },
      }));
    }
  };

  const vendorGroupsEdges = coercedGet(data, 'vendorGroups.edges', []);
  const vendorGroupsNode: VendorGroup[] = vendorGroupsEdges.map(
    (vendor: { node: VendorGroup }) => vendor.node
  );
  return (
    <Spin spinning={loading}>
      <Select
        placeholder={translate(messagesLocal.SELECT_GROUP_NAME)}
        onBlur={() => setFilter({})}
        onChange={handleFilters}
        value={filters?.name?.in}
        mode="multiple"
        className="w-100"
        notFoundContent={null}
        filterOption={false}
        onSearch={debounce(onSearch, 500)}
      >
        {vendorGroupsNode.map((vendor, idx) => (
          <Select.Option key={idx} value={vendor.name!}>
            {vendor.name}
          </Select.Option>
        ))}
      </Select>
    </Spin>
  );
};
