import { Select } from 'antd';
import React from 'react';
import { AffiliateRequestStatus } from 'types/graphqlTypes';

type Props = {
  filters: Record<string, any>;
  onChange: (e: string[]) => void;
};

const StatusFilter = ({ filters, onChange }: Props) => {
  const values = filters?.status?.in || [];

  return (
    <Select
      mode="multiple"
      value={values}
      onChange={onChange}
      placeholder="Select Status"
    >
      {Object.entries(AffiliateRequestStatus).map(([key, value]) => (
        <Select.Option key={key} value={value}>
          {key}
        </Select.Option>
      ))}
    </Select>
  );
};

export default StatusFilter;
