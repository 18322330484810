import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useApolloClient } from '@apollo/react-hooks';
import { message, Button } from 'antd';
import gql from 'graphql-tag';
import useTranslate from 'utils/useTranslate';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import BulkEditStatusMenuForm from 'components/BulkEditStatusMenuForm';
import { StyledModal } from './styles';
import messages from './messages';

const graphqlString = ({
  userId,
  status,
}: {
  userId: string;
  status: string;
}) => `${userId}: updateMember(id: "${userId}", input: {status: ${status}})`;

const BulkEditStatusMenu = ({
  members,
  onSuccess,
}: {
  members: string[];
  onSuccess: (e: any) => void;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const client = useApolloClient();
  const translate = useTranslate();

  const { context } = useOperatorHeader();

  const handleSubmit = async (e: Record<string, any>) => {
    const listOfIds = e.username.map((user: { key: string }) => user.key);
    setLoading(true);
    try {
      const input = { ...e };

      if (input.status) {
        delete input.username;
        let str = '';

        listOfIds.forEach((userId: string) => {
          str += graphqlString({
            userId,
            status: input.status,
          });
        });

        await client.mutate({
          mutation: gql(`mutation { ${str} }`),
          context,
        });
      }

      message.success(
        translate(messages['member-status.success-message.text'])
      );
      onSuccess(listOfIds);
      setLoading(false);
      handleShow();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button className="text-black" type="link" onClick={handleShow} block>
        <FormattedMessage id="edit-status.text" defaultMessage="Edit status" />
      </Button>
      {isShow && (
        <StyledModal
          title={
            <FormattedMessage
              id="edit-status.text"
              defaultMessage="Edit status"
            />
          }
          onCancel={handleShow}
          visible={isShow}
          footer={false}
        >
          <BulkEditStatusMenuForm
            initialValues={{
              username: members,
              status: null,
            }}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onClose={handleShow}
          />
        </StyledModal>
      )}
    </>
  );
};

export default BulkEditStatusMenu;
