import React from 'react';
// import MemberTagsBubble from 'shared/components/MemberTagsBubble';
import MemberNotes from 'pages/components/InternalMemberDeposits/components/MemberNotes';
import MemberTagsBubble from 'pages/components/InternalMemberDeposits/components/MemberTagsBubble';
import RemarksBubble from 'components/RemarksBubble';
import coercedGet from 'utils/coercedGet';

const MemberLabelRemarks = ({ deposit }: any) => {
  const memberId = coercedGet(deposit, 'id');
  return (
    <div className="d-flex justify-content-center">
      <div style={{ width: 31.81 }}>
        <MemberTagsBubble memberId={memberId} />
      </div>
      <div style={{ width: 18.52 }}>
        <MemberNotes memberId={memberId} toolTipId="" />
      </div>
      {deposit.remarks && (
        <RemarksBubble
          remarks={deposit.remarks}
          date={deposit.dateTimeProcessed || deposit.dateTimeCreated}
          toolTipId=""
        />
      )}
    </div>
  );
};

export default MemberLabelRemarks;
