import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { message, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DynamicObj } from 'interfaces/user.interface';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';
import messages from 'SuperAdminMain/messages';
import StyledModal from 'SuperAdminMain/shared/styles/StyledModal';
import { useVendorMaintenance } from '../../../context';

const VENDOR_STATUS = {
  active: 'ACTIVE',
  maintenance: 'IN_MAINTENANCE',
};

const UPDATE_ADMIN = gql`
  mutation UPDATE_ADMIN($id: ID!, $input: UpdateVendorInput!) {
    updateVendor(id: $id, input: $input)
  }
`;

interface Props {
  vendor: DynamicObj;
}

export const StatusAction: React.FC<Props> = ({ vendor }) => {
  const [updateAdmin] = useMutation(UPDATE_ADMIN);
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const translate = useTranslate();
  const { refetch } = useVendorMaintenance();

  const handleShow = () => setShow(!isShow);

  const onAction = async () => {
    let { status } = vendor;
    setLoading(true);

    status =
      status === VENDOR_STATUS.active
        ? VENDOR_STATUS.maintenance
        : VENDOR_STATUS.active;

    try {
      await updateAdmin({
        variables: {
          id: vendor.id,
          input: {
            status,
          },
        },
      });

      refetch();
      handleShow();
      message.success(translate(messages['updated-successfully.text']));
      // eslint-disable-next-line no-empty
    } finally {
    }

    setLoading(false);
  };

  return (
    <>
      <Button type="link" block onClick={handleShow}>
        {vendor.status === VENDOR_STATUS.active ? (
          <FormattedMessage
            id="maintenance.text"
            defaultMessage="Maintenance"
          />
        ) : (
          <FormattedMessage id="active.text" defaultMessage="Active" />
        )}
      </Button>

      <StyledModal
        title={
          <span className="fs-14">
            {vendor.status === VENDOR_STATUS.active ? (
              <FormattedMessage
                id="maintenance.text"
                defaultMessage="Maintenance"
              />
            ) : (
              <FormattedMessage id="active.text" defaultMessage="Active" />
            )}
          </span>
        }
        visible={isShow}
        width={640}
        centered
        onCancel={handleShow}
        footer={[
          <Button key="back" onClick={handleShow}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={onAction}
          >
            <FormattedMessage id="update.text" defaultMessage="Update" />
          </Button>,
        ]}
      >
        {vendor.status === VENDOR_STATUS.active ? (
          <FormattedMessage
            id="enter-maintenance-confirmation.text"
            defaultMessage="Are you sure you want to enter maintenance mode?"
          />
        ) : (
          <FormattedMessage
            id="exit-maintenance-confirmation.text"
            defaultMessage="Are you sure you want to exit maintenance mode?"
          />
        )}
      </StyledModal>
    </>
  );
};
