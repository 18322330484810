import React from 'react';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import { DynamicObj } from 'interfaces/user.interface';

type Props = {
  filters: Record<string, any>;
  onFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
};

const FilterItems = ({ filters, onFilterChange }: Props) => (
  <>
    <DateTimeRangePicker
      testId="rebate-report-dateTime-created-filter"
      label="request-date"
      value={
        filters.dateTimeCreated
          ? [filters.dateTimeCreated.gte, filters.dateTimeCreated.lte]
          : null
      }
      onChange={(e) =>
        onFilterChange({
          name: 'dateTimeCreated',
          value: e?.length ? { gte: e[0], lte: e[1] } : null,
        })
      }
    />
  </>
);

export default FilterItems;
