import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ADMIN_CODE_URL: {
    id: 'admin-code-url.text',
    defaultMessage: 'Admin Code/URL',
  },
  CATEGORY: {
    id: 'vgm.category.text',
    defaultMessage: 'Category',
  },
  CLIENT_ACCOUNT: {
    id: 'vgm.client-account.text',
    defaultMessage: 'Client Account',
  },
  CLIENT_COMPANY: {
    id: 'vgm.client-company.text',
    defaultMessage: 'Client (Company)',
  },
  EXPIRE_DATE: {
    id: 'expire-date.text',
    defaultMessage: 'Expire Date',
  },
  START_DATE: {
    id: 'start-date.text',
    defaultMessage: 'Start Date',
  },
  STATUS: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  VENDOR_GROUP: {
    id: 'vendor-group.text',
    defaultMessage: 'Vendor Group',
  },
});

export default messages;
