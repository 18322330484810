import { useMutation } from '@apollo/react-hooks';
import { Switch as AntSwitch } from 'antd';
import { UPDATE_AUTOMATED_MESSAGE } from 'graphql/mutations/automatedMessage.mutation';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from './styles';

export const switchType = {
  TABLE: 'TABLE',
  EDIT_MESSAGE: 'EDIT_MESSAGE',
};

const defaultProps = {
  id: '',
  toggle: false,
  type: '',
  className: '',
  refetch: () => {},
};

type Props = {} & typeof defaultProps;

export const Switch = ({
  id,
  toggle: initToggle,
  type,
  className,
  refetch,
}: Props) => {
  const [updateAutomatedMessage, { loading }] = useMutation(
    UPDATE_AUTOMATED_MESSAGE
  );

  const handleToggle = async () => {
    switch (type) {
      case switchType.TABLE:
        try {
          await updateAutomatedMessage({
            variables: {
              id,
              input: {
                enabled: !initToggle,
              },
            },
          });

          refetch();
          // eslint-disable-next-line no-empty
        } finally {
        }

        break;
      case switchType.EDIT_MESSAGE:
        break;
      default:
    }
  };

  return (
    <Container className={className}>
      <AntSwitch
        checked={initToggle}
        onClick={handleToggle}
        loading={loading}
      />

      {initToggle ? (
        <FormattedMessage id="on.text" defaultMessage="On" />
      ) : (
        <FormattedMessage id="off.text" defaultMessage="Off" />
      )}
    </Container>
  );
};

Switch.defaultProps = defaultProps;
