import gql from 'graphql-tag';

export const DEPOSIT_SOURCES_AND_CONFIG = gql`
  query DepositSources($first: Int, $after: Binary) {
    depositSources(first: $first, after: $after) {
      totalCount
      edges {
        cursor
        node {
          id
          logo {
            id
            uri
          }
          enDisplayName: displayName(language: EN)
          zhDisplayName: displayName(language: ZH)
          visible
          loadBankSource
          order
        }
      }
    }
    config {
      depositSourceFieldStateInputType
    }
  }
`;

export default {};
