import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { UPDATE_CUSTOM_FILTER } from 'graphql/mutations/customFilter.mutation';
import { GET_CUSTOM_FILTERS } from 'graphql/queries/customFilter.query';
import { DynamicObj } from 'interfaces/user.interface';
import isEmpty from 'lodash/isEmpty';
import SearchSettingsForm from 'pages/components/Reports/DailyOperatingReport/components/SearchSettings/components/SearchSettingsForm';
import messages from 'messages';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { TabId } from 'types/nav';
import { customFormatMessage } from 'utils/customFormatMessage';
import removeEmptyObjects from 'utils/removeEmptyObjects';

const EditSearchSettings = ({
  searchSettings,
  onClose,
}: EditSearchSettingsType) => {
  const [isLoading, setLoading] = useState(false);
  const [updateCustomFilter] = useMutation(UPDATE_CUSTOM_FILTER);
  const { formatMessage } = useIntl();
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  const handleSubmit = async (fields: DynamicObj) => {
    try {
      setLoading(true);
      if (!isEmpty(removeEmptyObjects(fields))) {
        await updateCustomFilter({
          variables: {
            id: searchSettings.id,
            input: {
              name: searchSettings.name,
              filter: removeEmptyObjects(fields),
            },
          },
          refetchQueries: [
            {
              query: GET_CUSTOM_FILTERS,
              variables: {
                filter: {
                  context: {
                    eq: 'daily-operating-report' as TabId,
                  },
                },
              },
            },
          ],
        });
        message.success(translate(messages['search-settings-updated.text']));
        setLoading(false);
      } else {
        message.error(
          translate(messages['cannot-update-filter-empty-error.text'])
        );
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <SearchSettingsForm
      initialValues={searchSettings.filter}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

type EditSearchSettingsType = {
  searchSettings: DynamicObj;
  onClose: () => void;
};

export default EditSearchSettings;
