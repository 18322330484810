import { useMutation, useQuery } from '@apollo/react-hooks';
import { message, Table } from 'antd';
import { withErrorHandler } from 'components/ErrorHandler';
import Layout from 'components/Layout';
import { showPermissionError } from 'components/Navbar/Navbar';
import ALL_PERMISSIONS from 'constants/permissions3';
import FiltersProvider from 'contexts/Filters';
import {
  START_PROCESS_AFFILIATE_REQUEST,
  STOP_PROCESS_AFFILIATE_REQUEST,
} from 'graphql/mutations/affiliateAgent.mutation';
import {
  AFFILIATE_REQUEST,
  AFFILIATE_REQUESTS,
} from 'graphql/queries/affiliateAgent.query';
import { Page, PageInfo } from 'interfaces/user.interface';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React, { useEffect, useState } from 'react';
import { usePermissions } from 'store/accountState';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { useScreenTabV2 } from 'store/screenTabState';
import coercedGet from 'utils/coercedGet';
import { getPollInterval } from 'utils/pollInterval';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import uuid from 'uuid';
import { columnsNext } from './columns';
import AffiliateRequestPopup from './components/AffiliateRequestPopup';
import HeaderLeft from './components/HeaderLeft';
import HeaderRight from './components/HeaderRight';
import Sidebar from './components/Sidebar';
import { AffiliateRequestStatusColor } from './constants';
import messages from './messages';

interface IModalArgs {
  id: string;
  status: string;
}

const pageInitState: Page = {
  after: undefined,
  savedCursor: [undefined] as any,
  currentPage: 0,
  first: 10,
};

type OriginalColumn = {
  key: string;
  title: string;
  customTitle?: string;
  [x: string]: any;
};

type Props = {
  refreshPage: () => void;
};

const AffiliateRequest = ({ refreshPage }: Props) => {
  const { role, permissions } = usePermissions();
  const [columns, setColumns] = useState<OriginalColumn[]>([]);
  const { isTabActive } = useScreenTabV2();
  const { ALLOWED_VIEW_DETAILS, ALLOWED_PROCESS } = collectPermissions(
    role,
    permissions,
    ['VIEW_DETAILS', 'PROCESS'],
    ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES_AFFILIATE_REQUEST
  );

  const [isRealtime, setIsRealtime] = useState(true);
  const [modalShown, setModalShown] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [sidebar, toggleSidebar] = useState(true);

  const [activeRecord, setActiveRecord] = useState('');

  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageInfo, setPageInfo] = useState<Partial<PageInfo>>({});

  const [page, setPage] = useState<Page>(pageInitState);

  const [filters, setFilters] = useState({
    serialCode: null,
    status: null,
  });

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: removeNull(filters),
  };
  const setResultInfo = (data: { pageInfo: PageInfo; totalCount: number }) => {
    setTotalCount(data.totalCount);
    setPageInfo(data.pageInfo);
  };

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const isActiveTab = isTabActive('affiliate-request');

  const affiliateRequestsQuery = useQuery(AFFILIATE_REQUESTS, {
    variables: refetchVariables,
    fetchPolicy: 'network-only',
    pollInterval: getPollInterval({
      isActive: isActiveTab,
      isRealtime,
    }),
    onCompleted: (res) => {
      setResultInfo(res.affiliateRequests);
    },
  });
  const edges = coercedGet(
    affiliateRequestsQuery.data,
    'affiliateRequests.edges',
    []
  ).map((item: any, key: number) => ({
    ...item.node,
    key: key + 1,
  }));

  const [startProcessAffiliateRequest] = useMutation(
    START_PROCESS_AFFILIATE_REQUEST
  );
  const [stopProcessAffiliateRequest] = useMutation(
    STOP_PROCESS_AFFILIATE_REQUEST
  );
  const ProcessMode = {
    START: 'START',
    STOP: 'STOP',
  };
  const processAction = {
    [ProcessMode.START]: startProcessAffiliateRequest,
    [ProcessMode.STOP]: stopProcessAffiliateRequest,
  };

  const translate = useTranslate();

  const handleToggleSidebar = () => {
    toggleSidebar((prev) => !prev);
  };
  const handleSetRealtime = () => {
    setIsRealtime((prev) => !prev);
  };
  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor!);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };
  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };
  const processAffiliate = async (mode: string, id: string) => {
    const process = processAction[mode];
    setProcessing(true);
    try {
      const res = await process({
        variables: { id },
        refetchQueries: [
          {
            query: AFFILIATE_REQUEST,
            variables: { id },
          },
          {
            query: AFFILIATE_REQUESTS,
            variables: refetchVariables,
          },
        ],
      });
      return coercedGet(
        res,
        `data.${
          mode === ProcessMode.START ? 'start' : 'stop'
        }ProcessAffiliateRequest`,
        false
      );
    } catch (e) {
      message.error(
        translate(
          messages[
            `affiliate-request-${
              mode === ProcessMode.START ? 'start' : 'stop'
            }-process-error.text`
          ]
        )
      );
      return false;
    } finally {
      setProcessing(false);
    }
  };
  const handleShowForProcessing = async (id: string) =>
    processAffiliate(ProcessMode.START, id).then((res) => {
      if (res) {
        setModalShown(true);
      }
    });
  const handleHideForProcessing = async () =>
    processAffiliate(ProcessMode.STOP, activeRecord).then((res) => {
      if (res) {
        setModalShown(false);
      }
    });
  const handleHide = () => setModalShown(false);
  const handleShow = () => setModalShown(true);
  const handleOpenRequestModal = ({ id, status }: IModalArgs) => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('Affiliate Request - View Details');
      return;
    }

    setActiveRecord(id);

    if (
      status.toLowerCase() === AffiliateRequestStatusColor.CURRENTLY_PENDING &&
      ALLOWED_PROCESS
    ) {
      handleShowForProcessing(id);
    } else {
      handleShow();
    }
  };

  const defaultFilters = {};
  const initialFilters = {};
  const { filterColumns } = useCustomColumnsV2('affiliate-request', columns);
  useEffect(() => {
    setColumns(columnsNext(translate, handleOpenRequestModal));
    // eslint-disable-next-line
  }, [translate]);

  return (
    <FiltersProvider
      initialValues={initialFilters}
      defaultValues={defaultFilters}
      tabId="affiliate-request"
    >
      <Layout.Container>
        <>
          <Layout.Header
            leftNode={<HeaderLeft toggleSidebar={handleToggleSidebar} />}
            rightNode={
              <HeaderRight
                refreshPage={refreshPage}
                isRealtime={isRealtime}
                handleSetRealtime={handleSetRealtime}
              />
            }
          />
          <Layout.Content
            sideBar={
              <Sidebar
                open={sidebar}
                filters={filters}
                setFilters={setFilters}
              />
            }
            footer={
              <Layout.Footer
                loading={false}
                onNext={handleNext}
                onPrev={handlePrev}
                page={page}
                resultsCount={totalCount}
                totalPage={totalPage}
                setPage={setPage}
                pageInfo={affiliateRequestsQuery.data?.pageInfo}
              />
            }
          >
            <Table
              dataSource={edges}
              loading={affiliateRequestsQuery?.loading}
              pagination={false}
            >
              {filterColumns(columns)?.map((column, idx) => (
                <Table.Column
                  title={column?.title}
                  dataIndex={column?.dataIndex}
                  key={column?.dataIndex || idx}
                  align="center"
                  render={column?.render}
                />
              ))}
            </Table>
            <AffiliateRequestPopup
              processing={processing}
              variables={refetchVariables}
              modalShown={modalShown}
              handleHideForProcessing={handleHideForProcessing}
              handleHide={handleHide}
              activeRecord={activeRecord}
            />
          </Layout.Content>
        </>
      </Layout.Container>
    </FiltersProvider>
  );
};

const AffiliateRequestToggable = () => {
  const [affiliateKey, setAffiliateKey] = useState('');
  const refreshPage = () => setAffiliateKey(uuid.v1());

  return <AffiliateRequest key={affiliateKey} refreshPage={refreshPage} />;
};

export default withErrorHandler(AffiliateRequestToggable);
