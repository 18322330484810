import React, { useState } from 'react';
import { StyledTable } from 'constants/styles';
import { useIntl } from 'react-intl';
import Circle from 'components/Circle';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';

import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { showPermissionError } from 'components/Navbar/Navbar';

import { getBank, messages as bankMessages } from 'constants/banks';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import coercedGet from 'utils/coercedGet';
import { columnAlign } from 'utils/tableAlignment';
import { ALink } from 'components/ALink/ALink';

import useTranslate from 'utils/useTranslate';
import tetherLogo from 'img/icons/crypto/tether-logo.svg';
import { setDecimals } from 'utils/setDecimals';
import { useConfig } from 'hooks/useConfig';
import messages from '../../messages';
import { getPaymentMethodType, getColor } from '../../utils';
import EditPaymentGateway from '../EditPaymentGateway';
import DeletePaymentGateway from '../DeletePaymentGateway';
import DuplicatePaymentGateway from '../DuplicatePaymentGateway';
import UpdatePaymentGatewayStatus from '../UpdatePaymentGatewayStatus';
import PaymentGatewayDetails from '../PaymentGatewayDetails';

const PaymentMethodsTable = ({
  dataSource,
  loading,
  refetchVariables,
}: any) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const { role, permissions } = usePermissions();
  const { addCurrency } = useConfig();
  const {
    ALLOWED_VIEW_DETAILS,
    ALLOWED_TOGGLE_ENABLED,
    ALLOWED_EDIT,
    ALLOWED_DUPLICATE,
    ALLOWED_DELETE,
  } = collectPermissions(
    role,
    permissions,
    ['VIEW_DETAILS', 'TOGGLE_ENABLED', 'EDIT', 'DUPLICATE', 'DELETE'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_PAYMENT_METHODS
  );

  const [state, setState] = useState<any>({
    isEditPaymentGateway: false,
    isDeletePaymentGateway: false,
    isDuplicatePaymentGateway: false,
    isUpdatePaymentGatewayStatus: false,
    isPaymentGatewayDetails: false,
    paymentGatewayId: null,
    paymentGatewayStatus: null,
    paymentGatewayType: null,
    paymentGatewayAccountNumber: null,
    selectedRowKeys: [],
  });

  const {
    isEditPaymentGateway,
    isDuplicatePaymentGateway,
    isDeletePaymentGateway,
    isUpdatePaymentGatewayStatus,
    isPaymentGatewayDetails,
  } = state;

  const toggleView = (id = null) => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('Payment Method Listing - View Details');
      return;
    }
    setState({
      ...state,
      isPaymentGatewayDetails: !isPaymentGatewayDetails,
      paymentGatewayId: id,
    });
  };

  const toggleEdit = (id = null) => {
    setState({
      ...state,
      isPaymentGatewayDetails: false,
      isUpdatePaymentGatewayStatus: false,
      isEditPaymentGateway: !isEditPaymentGateway,
      paymentGatewayId: id,
    });
  };

  const toggleDuplicate = (id = null) => {
    setState({
      ...state,
      isDuplicatePaymentGateway: !isDuplicatePaymentGateway,
      paymentGatewayId: id,
    });
  };

  const toggleDelete = (id = null) => {
    setState({
      ...state,
      isDeletePaymentGateway: !isDeletePaymentGateway,
      paymentGatewayId: id,
    });
  };

  const toggleUpdateStatus = (value: any = {}) => {
    const {
      id = null,
      status = null,
      type = null,
      accountNumber = null,
    } = value;

    setState({
      ...state,
      isUpdatePaymentGatewayStatus: !isUpdatePaymentGatewayStatus,
      paymentGatewayId: id,
      paymentGatewayType: type,
      paymentGatewayStatus: status,
      paymentGatewayAccountNumber: accountNumber,
    });
  };

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = dataSource
    ? dataSource.map(({ node }: any) => ({ ...node, key: node.id }))
    : [];

  const translate = useTranslate();

  const columns = [
    {
      csvData: {
        label: translate(messages.name),
        key: 'name',
      },
      title: translate(messages.name),
      align: columnAlign.onCenter,
      width: 150,
      key: 'name',
      render: (record: any) => (
        <ALink
          onClick={() => {
            toggleView(record.id);
          }}
          role="link"
        >
          {record.name === 'Manual Adjustment'
            ? translate(messages.MANUAL_ADJUSTMENT)
            : record.name}
        </ALink>
      ),
    },
    {
      csvData: {
        label: translate(messages.paymentSource),
        key: 'paymentSource',
        renderCell: (paymentMethod: any) =>
          coercedGet(paymentMethod, 'depositProvider', '')
            ? translate(messages.thirdparty)
            : translate(messages.internal),
      },
      key: 'paymentSource',
      align: columnAlign.onCenter,
      width: 100,
      title: translate(messages.paymentSource),
      render: (record: any) =>
        record.depositProvider
          ? translate(messages.thirdparty)
          : translate(messages.internal),
    },
    {
      csvData: {
        label: translate(messages.provider),
        key: 'depositProvider',
        renderCell: (paymentMethod: any) =>
          coercedGet(paymentMethod, 'depositProvider.name', '-'),
      },
      key: 'depositProvider',
      align: columnAlign.onCenter,
      width: 100,
      title: translate(messages.provider),
      render: (record: any) =>
        record.depositProvider ? record.depositProvider.name : '-',
    },
    {
      csvData: {
        label: translate(messages.paymentmethodtype),
        key: 'paymentMethodType',
        renderCell: (paymentMethod: any) =>
          getPaymentMethodType(paymentMethod, formatMessage),
      },
      key: 'paymentMethodType',
      align: columnAlign.onCenter,
      width: 200,
      title: translate(messages.paymentmethodtype),
      render: (record: any) => getPaymentMethodType(record, formatMessage),
    },
    {
      csvData: {
        label: `${translate(messages.bankname)} / ${translate(
          messages.nickname
        )}`,
        key: 'bankName',
        renderCell: (paymentMethod: any) => {
          const { bank, nickname, blockchainAddress } = paymentMethod;

          if (bank) {
            const newBank = getBank(bank);
            return `${
              newBank && newBank.label !== 'OTHER' ? `[${newBank.label}] ` : ''
            } ${newBank &&
              translate(
                bankMessages[newBank.label as keyof typeof bankMessages]
              )}`;
          }
          if (nickname) return nickname;
          if (blockchainAddress) return blockchainAddress;

          return '-';
        },
      },
      key: 'bankName',
      title: `${translate(messages.bankname)} / ${translate(
        messages.nickname
      )}`,
      align: columnAlign.onCenter,
      width: 250,
      render: (record: any) => {
        const { bank, nickname, blockchainAddress } = record;

        if (bank) {
          const newBank = getBank(bank);
          return (
            <div className="d-flex align-items-center">
              <img
                height={20}
                width={20}
                src={(newBank && `${newBank.logo}`) || ''}
                alt="logo"
                className="mr-1"
              />
              <div>
                {newBank && newBank.label !== 'OTHER'
                  ? `[${newBank && newBank.label}] `
                  : ''}
                {newBank &&
                  translate(
                    bankMessages[newBank.label as keyof typeof bankMessages]
                  )}
              </div>
            </div>
          );
        }

        if (blockchainAddress) {
          const firstFive = blockchainAddress.slice(0, 5);
          const lastFive = blockchainAddress.slice(
            blockchainAddress.length - 5
          );

          return (
            <Tooltip title={blockchainAddress}>
              <span>
                {firstFive}....{lastFive}
              </span>
            </Tooltip>
          );
        }
        if (nickname) return nickname;
        return '-';
      },
    },
    {
      csvData: {
        label: translate(messages.currentAccumulation),
        key: 'currentAccumulation',
        renderCell: (paymentMethod: any) =>
          coercedGet(paymentMethod, 'currentAccumulation', 0),
      },
      align: columnAlign.onCenter,
      width: 150,
      key: 'currentAccumulation',
      title: translate(messages.currentAccumulation),

      render: (record: any) => {
        if (record.currentAccumulation < 0) {
          return '-';
        }
        return record.__typename === 'CryptocurrencyPaymentMethod' ? (
          <>
            <img
              src={tetherLogo}
              alt="logo"
              className="mr-1"
              height={18}
              width={18}
            />
            {setDecimals(record.currentAccumulation, 2)}
          </>
        ) : (
          `${addCurrency(record.currentAccumulation, 2)}`
        );
      },
    },
    {
      csvData: {
        label: translate(messages.dailyDepositLimit),
        key: 'dailyDepositLimit',
        renderCell: (paymentMethod: any) =>
          coercedGet(paymentMethod, 'maximumDepositAmountPerDay', 0) < 0
            ? '-'
            : coercedGet(paymentMethod, 'maximumDepositAmountPerDay', 0),
      },
      align: columnAlign.onCenter,
      width: 150,
      key: 'dailyDepositLimit',
      title: translate(messages.dailyDepositLimit),

      render: (record: any) => {
        if (record.currentAccumulation < 0) {
          return '-';
        }
        return record.__typename === 'CryptocurrencyPaymentMethod' ? (
          <>
            <img
              src={tetherLogo}
              alt="logo"
              className="mr-1"
              height={18}
              width={18}
            />
            {setDecimals(record.maximumDepositAmountPerDay, 2)}
          </>
        ) : (
          `${
            record.maximumDepositAmountPerDay > 0
              ? addCurrency(record.maximumDepositAmountPerDay, 2)
              : '-'
          }`
        );
      },
    },
    {
      csvData: {
        label: translate(messages.status),
        key: 'status',
        renderCell: (paymentMethod: any) =>
          coercedGet(paymentMethod, 'enabled', '')
            ? translate(messages.enable)
            : translate(messages.disable),
      },
      align: columnAlign.onCenter,
      width: 100,
      key: 'status',
      title: translate(messages.status),
      dataIndex: 'enabled',
      render: (enabled: any) => (
        <>
          <Circle size={15} color={getColor(enabled)} filled />{' '}
          {enabled ? translate(messages.enable) : translate(messages.disable)}
        </>
      ),
    },
    {
      csvData: {
        label: translate(messages.remarks),
        key: 'remarks',
      },
      key: 'remarks',
      align: columnAlign.onCenter,
      width: 100,
      title: translate(messages.remarks),
      dataIndex: 'remarks',
    },
    {
      csvData: null,
      hidden: true,
      align: columnAlign.onCenter,
      fixed: 'right',
      width: 100,
      title: translate(messages.actions),
      key: 'actions',
      render: (record: any) =>
        (ALLOWED_EDIT ||
          ALLOWED_DUPLICATE ||
          ALLOWED_DELETE ||
          ALLOWED_TOGGLE_ENABLED) && (
          <Dropdown
            disabled={record.__typename === 'ManualAdjustmentPaymentMethod'}
            overlay={
              <Menu>
                {ALLOWED_EDIT && (
                  <Menu.Item
                    onClick={() => {
                      toggleEdit(record.id);
                    }}
                    disabled={!record.updateable}
                  >
                    {translate(messages.edit)}
                  </Menu.Item>
                )}
                {ALLOWED_DUPLICATE && (
                  <Menu.Item
                    onClick={() => {
                      toggleDuplicate(record.id);
                    }}
                  >
                    {translate(messages.duplicate)}
                  </Menu.Item>
                )}
                {ALLOWED_DELETE && (
                  <Menu.Item
                    onClick={() => {
                      toggleDelete(record.id);
                    }}
                    disabled={record.enabled || !record.updateable}
                  >
                    {translate(messages.delete)}
                  </Menu.Item>
                )}
                {ALLOWED_TOGGLE_ENABLED && (
                  <Menu.Item
                    onClick={() => {
                      toggleUpdateStatus({
                        id: record.id,
                        status: record.enabled,
                        type: record.__typename,
                        accountNumber: record.accountNumber || '',
                      });
                    }}
                    disabled={!record.updateable}
                  >
                    {record.enabled
                      ? translate(messages.DISABLE)
                      : translate(messages.ENABLE)}
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
          >
            <div>
              <EllipsisOutlined
                className="cursor-pointer font-weight-bold"
                style={{ fontSize: '25px' }}
              />
            </div>
          </Dropdown>
        ),
    },
  ];

  const { filterColumns } = useCustomColumnsV2(
    'payment-method-listing',
    columns
  );

  // const { filter } = refetchVariables;

  // const { setCsvFilters, setCsvHeader }: any = useCsvValues();

  // useEffect(() => {
  //   setCsvFilters({ filter });
  //   setCsvHeader(generateCsvHeader(columns));

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loading]);

  return (
    <>
      {isPaymentGatewayDetails && (
        <PaymentGatewayDetails
          id={state.paymentGatewayId!}
          toggleModal={toggleView}
          toggleEdit={toggleEdit as any}
        />
      )}
      {isEditPaymentGateway && (
        <EditPaymentGateway
          id={state.paymentGatewayId!}
          toggleModal={toggleEdit}
          refetchVariables={refetchVariables}
        />
      )}
      {isDuplicatePaymentGateway && (
        <DuplicatePaymentGateway
          id={state.paymentGatewayId!}
          toggleModal={toggleDuplicate}
          refetchVariables={refetchVariables}
        />
      )}
      {isDeletePaymentGateway && (
        <DeletePaymentGateway
          id={state.paymentGatewayId!}
          toggleModal={toggleDelete}
          refetchVariables={refetchVariables}
        />
      )}
      {isUpdatePaymentGatewayStatus && (
        <UpdatePaymentGatewayStatus
          id={state.paymentGatewayId!}
          status={state.paymentGatewayStatus}
          type={state.paymentGatewayType}
          accountNumber={state.paymentGatewayAccountNumber}
          toggleModal={toggleUpdateStatus}
          refetchVariables={refetchVariables}
          toggleEdit={toggleEdit as any}
        />
      )}
      <StyledTable
        loading={loading}
        rowSelection={rowSelection}
        columns={filterColumns(columns)}
        size="small"
        dataSource={data}
        pagination={false}
        scroll={{ y: 640, x: 'calc(525px + 50%)' }}
        rowKey="name"
        data-testid="payment-methods-table"
      />
    </>
  );
};

export default PaymentMethodsTable;
