import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import statusOptions from 'constants/memberStatus';
import messages from 'pages/components/Reports/messages';
import useTranslate from 'utils/useTranslate';
import { StyledSelect } from './styles';

const { Option } = Select;

type Props = {
  state: Record<string, any>;
  setState?: (data: Record<string, any>) => void;
  onChange?: (data: Record<string, any>) => void;
};

const Status = ({ state, setState, onChange }: Props) => {
  const translate = useTranslate();
  return (
    <div className="mt-2 mb-4">
      <div className="mb-1">
        <FormattedMessage id="reports.status.text" defaultMessage="Status" />
      </div>
      <StyledSelect
        allowClear
        mode="multiple"
        placeholder={
          <FormattedMessage id="reports.select.text" defaultMessage="Select" />
        }
        style={{ width: '100%' }}
        value={state.status}
        onChange={(values: any) => {
          if (onChange) {
            onChange(values);
          } else if (setState) {
            setState({
              ...state,
              status: values,
            });
          }
        }}
      >
        {Object.keys(statusOptions).map((optionKey) => (
          <Option key={optionKey} value={optionKey}>
            {translate(
              messages[`${statusOptions[optionKey].toLowerCase()}.text`]
            )}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
};

export default Status;
