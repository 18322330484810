import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, DatePicker, Button, Layout, Table, Modal } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { LayoutProps } from 'antd/lib/layout';

const { RangePicker } = DatePicker;

export const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selection {
    padding: 4px;
    padding-top: 0;
    border-radius: 2px;
  }

  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }

  .ant-select-selection li.ant-select-selection__choice {
    border-radius: 4px;
  }
` as any;

export const StyledRangePicker = styled<any>(RangePicker)`
  ${({ isFilter }: { isFilter: boolean }) =>
    isFilter &&
    `
    .ant-input {
      border-radius: 0;
      padding-left: 0;
      font-size: 12px;
    }
  `}
`;

export const StyledButton = styled(Button)`
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-right: 8px;
  margin-bottom: 8px !important;
  font-size: 12px;
  box-shadow: none;
`;

export const StyledCustomClear = styled.span`
  font-size: 10px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const StyledFormItemGroup = styled.div`
  margin-bottom: 8px;
`;

export const StyledFormItem: typeof Form.Item = styled(Form.Item)<
  FormItemProps
>`
  .ant-legacy-form-item-label {
    width: 100%;
    height: 14px;
    line-height: 14px !important;
    padding-bottom: 3px !important;
    label {
      font-size: 10px !important;
    }

    label::after {
      content: '';
    }
  }
` as any;

export const StyledQuickFilter = styled.div`
  button {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

export const StyledLayout: typeof Layout = styled(Layout)<LayoutProps>`
  height: 100%;
  .ant-layout-content {
    overflow-y: auto !important;
  }
` as any;

export const StyledContent: typeof Layout.Content = styled(Layout.Content)<
  LayoutProps
>`
  background: #fff;
` as any;

export const StyledTagContainer = styled.div<any>`
  flex: 1;
  display: flex;
  overflow: hidden;
  align-items: center;
  max-height: ${({ isExpanded }) => (isExpanded ? 'unset' : '37px')};
  flex-wrap: wrap;
  .ant-tag {
    margin: 5px;
  }
`;

export const TableFooterStyled = styled.div`
  z-index: 1;
  flex: 1;
  display: flex;
  background: #f3f8fb;
  justify-content: flex-end;
  width: 100%;
  padding: 15px 35px;
  align-items: center;

  .next-prev-container {
    margin-top: 0 !important;
  }
`;

export const StyledCenter = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTable = styled(Table)<any>`
  .ant-table-small {
    height: calc(100vh - 215px);
    border-top: none !important;
    border-radius: 0;

    .ant-table-content {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  .ant-table-small > .ant-table-content .ant-table-header {
    background-color: #fafafa !important;
  }

  .ant-table-fixed .ant-table-thead {
    background-color: #fafafa !important;
  }

  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }

  .ant-table-body {
    max-height: calc(100vh - 274px) !important;
  }

  .ant-table-fixed-right {
    right: 22px;
    height: 100%;

    transform: translateX(19px);

    box-shadow: 4px 0 12px 14px rgba(255, 255, 255, 1);
    .ant-table-body-inner {
      overflow-y: hidden;
      &::-webkit-scrollbar {
        background: transparent;
      }

      &::-moz-scrollbar {
        background: transparent;
      }

      &::-webkit-scrollbar-button {
        background: transparent;
      }

      &::-moz-scrollbar-button {
        background: transparent;
      }

      overflow-y: hidden !important;
      max-height: calc(100vh - 257px) !important;
    }
  }
`;

export const StyledTableContainer = styled.div`
  overflow: auto;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const StyledSider: typeof Layout.Sider = styled(Layout.Sider)<
  LayoutProps
>`
  .ant-layout-sider-children {
    overflow: hidden;
  }
` as any;

export const StyledContainer = styled.div`
  width: 232px;
  overflow: hidden;
  height: calc(100vh - 40px - 84px);
  :hover {
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  .no-padding {
    padding: 0;
  }
  .no-margin {
    margin: 0;
  }
`;

export const StyledHeaderContainer = styled.div`
  flex: 1;
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFloatingSidebarFooter = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #f0f1f4;
  background-color: #fff;
  height: auto;
  width: 100%;
`;

export default {};
