import React, { FC, useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Divider,
  Modal,
  Form,
  Row,
  Col,
  Input,
  message,
  Tooltip,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm, Controller } from 'react-hook-form';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Member } from 'types/graphqlTypes';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';

const PLAYER_FILE_LINK = gql`
  query MemberMessages($id: ID!) {
    member(id: $id) {
      id
      playerFileLink
    }
  }
`;

type Props = {
  memberId: string;
};

type FormValues = {
  playerFileLink: string | null;
};

const validationSchema = yup.object().shape({
  playerFileLink: yup
    .string()
    .required('Player file link is required.')
    .url('Invalid URL link format, example: https://www.example.com')
    .nullable(),
});

const PlayerFileLink: FC<Props> = ({ memberId }) => {
  const [showForm, setShowForm] = useState(false);
  const [playerFileLink, setPlayerFileLink] = useState<
    string | undefined | null
  >('');

  const { PLAYER_PROFILE_PERMISSION } = useNewMemberDetailPermissions();

  const { ALLOWED_EDIT, ALLOWED_VIEW } = PLAYER_PROFILE_PERMISSION;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      playerFileLink: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const handleShowForm = () => {
    reset();
    setShowForm((prev) => !prev);
  };

  const [getPlayerFileLink, { loading, refetch }] = useLazyQuery<{
    member: Member;
  }>(PLAYER_FILE_LINK, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: memberId,
    },
    onCompleted: ({ member }) => {
      reset({ playerFileLink: member.playerFileLink });

      setPlayerFileLink(member.playerFileLink);
    },
  });

  const [updatePlayerLink, { loading: updateLoading }] = useMutation(
    UPDATE_MEMBER
  );

  const onUpdateLink = async (values: FormValues) => {
    try {
      await updatePlayerLink({
        variables: {
          id: memberId,
          input: {
            playerFileLink: values.playerFileLink,
          },
        },
      });
      refetch();
      message.success('Player File Link successfully updated.');
    } finally {
      handleShowForm();
    }
  };

  useEffect(() => {
    if (memberId) getPlayerFileLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  return (
    <>
      <div className="d-flex justify-content-center align-items-center p-2">
        {loading ? (
          <LoadingOutlined spin />
        ) : (
          <>
            {playerFileLink ? (
              <>
                <Tooltip
                  title={
                    !ALLOWED_VIEW && 'Need View Player Profile Link Permission.'
                  }
                >
                  <Typography.Link
                    style={{
                      fontSize: 14,
                      padding: '4px 15px',
                    }}
                    href={playerFileLink}
                    target="_blank"
                    disabled={!ALLOWED_VIEW}
                  >
                    Player File
                  </Typography.Link>
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip
                  title={
                    !ALLOWED_EDIT && 'Need Edit Player Profile Link Permission.'
                  }
                >
                  <Button
                    type="link"
                    onClick={handleShowForm}
                    disabled={!ALLOWED_EDIT}
                  >
                    Edit File Link
                  </Button>
                </Tooltip>
              </>
            ) : (
              <Button type="link" onClick={handleShowForm}>
                Attach Link File
              </Button>
            )}
          </>
        )}
      </div>

      <Modal
        title="Player Profile Link"
        centered
        closable={false}
        visible={showForm}
        onCancel={handleShowForm}
        okText="Confirm"
        onOk={handleSubmit(onUpdateLink)}
        okButtonProps={{ loading: updateLoading }}
      >
        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item
                label="Player Profile Link"
                validateStatus={errors.playerFileLink && 'error'}
                help={errors.playerFileLink && errors.playerFileLink.message}
              >
                <Controller
                  control={control}
                  name="playerFileLink"
                  render={({ onChange, value }) => (
                    <Input type="url" onChange={onChange} value={value} />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PlayerFileLink;
