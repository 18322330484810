import React, { FC } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const MemberDropdown: FC<{
  showTable: boolean;
  setShowTable: Function;
}> = ({ showTable, setShowTable }) => {
  const Dropdown = ({ ...restProps }) =>
    showTable ? (
      <UpOutlined {...restProps} className="cursor-pointer" />
    ) : (
      <DownOutlined {...restProps} className="cursor-pointer" />
    );

  return <Dropdown onClick={() => setShowTable((e: boolean) => !e)} />;
};

export default MemberDropdown;
