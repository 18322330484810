import React, { FC } from 'react';
import { Select } from 'antd';

const { Option } = Select;

type Props = {
  onChange: (e: string) => void;
  value: string;
  isFilter?: boolean;
  label?: string;
};

const PromoTypeSelect: FC<Props> = ({ onChange, value, isFilter = false }) => (
  <Select
    style={{ width: isFilter ? '100%' : 200 }}
    value={value}
    onChange={onChange}
    placeholder="Select Promo Type"
  >
    <Option value="SIGN_UP">Sign Up</Option>
    <Option value="DEPOSIT">Deposit</Option>
  </Select>
);

export default PromoTypeSelect;
