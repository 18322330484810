const mergeObjectArrayValues = (objectSource: Record<string, any>) => {
  const objectKeys = Object.keys(objectSource);
  let mergedResult: Record<string, any>[] = [];
  objectKeys.forEach((key) => {
    if (key === '__typename') {
      return;
    }
    // replace value key with parent key name
    const reMappedValues = objectSource[key].map(
      ({ label, value }: { label: string; value: string }) => ({
        label,
        [key]: value,
      })
    );
    if (mergedResult.length) {
      mergedResult = mergedResult.map((item) => ({
        ...item,
        ...reMappedValues.find(
          (innerItem: Record<string, any>) => innerItem.label === item.label
        ),
      }));
    } else {
      mergedResult = reMappedValues;
    }
  });
  return mergedResult;
};

export default mergeObjectArrayValues;
