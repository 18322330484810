import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { yupResolver } from '@hookform/resolvers';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import DrawerFooter from 'components/DrawerFooter';
import Drawer from '../../../common/Drawer/Drawer';
import { useMissionsValue } from '../../context';
import { Day } from './Day';
import { ErrorMessage, validationSchema } from './utils';

const initValues = {
  cyclesCount: 1,
  days: [{ tasks: [] }],
};

const TaskAndRewardSettingsDailyLogin = () => {
  const {
    onPrevStep,
    setTaskRewardValue,
    onNextStep,
    taskRewardValue,
  } = useMissionsValue();
  const translate = useTranslate();
  const defaultValues = {
    ...initValues,
    ...taskRewardValue,
  };

  const { control, getValues, handleSubmit, errors } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(translate)),
  });
  const { fields, append, remove, insert, swap } = useFieldArray({
    control,
    name: 'days',
  });

  const onSubmit = () => {
    setTaskRewardValue(getValues());
    onNextStep();
  };

  const watchDay: any = useWatch({
    control,
    name: 'days',
  });

  return (
    <>
      <Drawer.Content
        heightExcess="220"
        className="mt-3"
        style={{ marginLeft: '5%', marginRight: '5%' }}
      >
        <Row>
          <Col span={18} className="d-flex">
            <div className="mr-2" style={{ width: '130px' }}>
              <div className="fs-11">
                <FormattedMessage
                  id="RECURRING_TIMES_SETTINGS"
                  defaultMessage="Recurring Times Settings"
                />
              </div>
              <Controller
                name="cyclesCount"
                control={control}
                as={<InputNumber style={{ width: '100%' }} size="small" />}
              />
              <ErrorMessage errors={errors} name="cyclesCount" />
            </div>
          </Col>
          <Col span={6} className="d-flex justify-content-flex-end">
            <Button type="primary" ghost onClick={() => append({ tasks: [] })}>
              <PlusOutlined />{' '}
              <FormattedMessage id="ADD_DAY" defaultMessage="Add Day" />
            </Button>
          </Col>
        </Row>

        {fields.map((item, index) => (
          <Day
            swapDay={swap}
            duplicateDay={() => insert(index + 1, watchDay[index])}
            numberOfDays={watchDay?.length}
            removeDay={remove}
            item={item}
            key={item.id}
            nestIndex={index}
            control={control}
            errors={errors}
          />
        ))}
      </Drawer.Content>
      <DrawerFooter>
        <>
          <Button onClick={() => onPrevStep()}>
            <FormattedMessage id="BACK" defaultMessage="Back" />
          </Button>
          <Button onClick={handleSubmit(onSubmit)} type="primary">
            <FormattedMessage id="CONTINUE" defaultMessage="Continue" />
          </Button>
        </>
      </DrawerFooter>
    </>
  );
};

export default TaskAndRewardSettingsDailyLogin;
