import React from 'react';
import Icon from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { ReactComponent as DownloadIcon } from 'components/assets/download-csv.svg';
import { useCsvValues } from 'contexts/Csv';
import CsvModal from './CsvModal';

const defaultProps = {
  isLink: false,
  iconOnly: false,
  fileName: moment().format(),
  permission: true,
};

type Props = typeof defaultProps;

const DownloadCsv = (props: Props) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { isLink, iconOnly } = props;
  const [isShow, setShow] = React.useState(false);
  const handleShow = (e?: React.SyntheticEvent) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const { loadQuery, handleForceClearData } = useCsvValues();
  const handleQuery = () => {
    handleForceClearData();
    loadQuery();
  };

  const renderIslink = () =>
    isLink ? (
      <Button className="text-black" type="link" onClick={handleShow} block>
        <FormattedMessage
          id="download-csv.text"
          defaultMessage="Download CSV"
        />
      </Button>
    ) : (
      <Button onClick={handleShow}>
        <FormattedMessage
          id="download-csv.text"
          defaultMessage="Download CSV"
        />
      </Button>
    );

  return (
    <>
      {iconOnly ? (
        <Tooltip title={formatMessage(messages.DOWNLOAD_CSV)}>
          <Icon
            className="mr-1"
            component={DownloadIcon}
            style={{ fontSize: '22px' }}
            onClick={handleShow}
          />
        </Tooltip>
      ) : (
        renderIslink()
      )}

      {isShow && (
        <CsvModal
          handleShow={handleShow}
          handleQuery={handleQuery}
          // isShow={isShow}
          // permission={permission}
          {...props}
        />
      )}
    </>
  );
};

DownloadCsv.defaultProps = defaultProps;

export default DownloadCsv;
