import React from 'react';
import { Button, Modal, message, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import messages from 'messages';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';
import { VendorGroup } from 'types/graphqlTypes';
import coercedGet from 'SuperAdminMain/utils/coercedGet';

const { confirm } = Modal;

const DELETE_VENDOR_GROUP = gql`
  mutation DeleteVendorGroup($id: ID!) {
    deleteVendorGroup(id: $id)
  }
`;

const ADMINS_COUNT = gql`
  query AdminsCount($filter: AdminsFilterInput) {
    admins(filter: $filter) {
      totalCount
    }
  }
`;

export const DeleteVendorGroup = ({
  vendor,
  refetchFn,
}: {
  vendor: VendorGroup;
  refetchFn: () => void;
}) => {
  const client = useApolloClient();
  const translate = useTranslate();
  const { loading, data } = useQuery(ADMINS_COUNT, {
    variables: {
      filter: {
        vendorGroup: {
          eq: vendor.id,
        },
      },
    },
  });

  const onDelete = () => {
    confirm({
      title: translate(messages.DELETE_VENDOR_GROUP_TITLE),
      content: translate(messages.DELETE_CONFIRMATION_BODY_TEXT),
      okText: translate(messages.YES),
      cancelText: translate(messages.NO),
      onOk: async () => {
        await client.mutate({
          mutation: DELETE_VENDOR_GROUP,
          variables: {
            id: vendor.id,
          },
        });
        message.success(translate(messages.VENDOR_DELETED_SUCCESSFULLY));
        refetchFn();
      },
    });
  };
  const count = coercedGet(data, 'admins.totalCount', 0);
  return (
    <Spin spinning={loading}>
      <Button
        type="link"
        block
        onClick={onDelete}
        disabled={count > 0 || vendor.status === 'ACTIVE'}
      >
        <FormattedMessage id="delete.text" defaultMessage="Delete" />
      </Button>
    </Spin>
  );
};
