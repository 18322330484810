import React, { useEffect } from 'react';
import Storage from 'constants/storage';
import { AntLayout } from 'SuperAdminMain/AntLayout/AntLayout';
import { useAccount } from 'store/accountState';
import { useHistory } from 'react-router-dom';

export default () => {
  const history = useHistory();
  const { setPortal } = useAccount();

  useEffect(() => {
    setPortal('sa');
    if (
      !localStorage.getItem(Storage.SA_ACCESS_TOKEN) ||
      !localStorage.getItem(Storage.SA_REFRESH_TOKEN)
    ) {
      localStorage.removeItem(Storage.ACCESS_TOKEN);
      localStorage.setItem(Storage.ACTIVE_PORTAL, 'sa');
      history.push('/controlcenter/signin');
    } else {
      localStorage.setItem(
        Storage.ACCESS_TOKEN,
        localStorage.getItem(Storage.SA_ACCESS_TOKEN) || ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const parsed: any = qs.parse(window.location.search.split('?')[1], {
  //   ignoreQueryPrefix: true,
  // });

  localStorage.setItem(Storage.ACTIVE_PORTAL, 'sa');

  return (
    <>
      <AntLayout />
    </>
  );
};
