import styled, { createGlobalStyle } from 'styled-components';
import { Menu, Input, Dropdown, Badge } from 'antd';
import Block from 'react-blocks';
import moreIcon from './assets/more-icon.svg';

const { Item } = Menu;

export const StyleNav = styled.div`
  position: fixed;
  z-index: 2;
  width: 100%;

  .logo {
    position: absolute;
  }

  .ant-layout-header {
    background-color: var(--nav-bg-color);
    height: auto;
    line-height: 40px;
  }

  .ant-menu {
    border-bottom: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-left: 40px;
    line-height: 40px;
  }

  .ant-menu-sub {
    background-color: var(--nav-bg-color);
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    color: rgba(255, 255, 255, 0.85) !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 40px;
    padding: 0 20px !important;
    &:hover {
      background-color: #1890ff !important;
    }
  }

  .ant-menu-submenu,
  .ant-menu-item {
    margin: 0 !important;
    border-bottom: none !important;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }
`;

export const GlobalStyle = createGlobalStyle`
  .ant-menu-item, .ant-menu-submenu-title {
    margin: 0 !important;
    border-bottom: none !important;
  }

  .ant-menu-submenu-popup {
    .ant-menu-item {
      &:not(:first-child) {
        margin-top: 4px !important;
      }
    }
    &:before {
      left: 8px !important;
    }
  }

  .ant-menu-vertical.ant-menu-sub, .ant-menu-vertical-left.ant-menu-sub, .ant-menu-vertical-right.ant-menu-sub {
    min-width: 180px;
  }

  &.ant-menu-submenu, .ant-menu-sub {
    border-radius: 0;
  }

  .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    top: 0;
  }

  .ant-menu-sub {
    /* position: relative; */
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    background-color: var(--nav-bg-color) !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 4px;
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    color: rgba(255, 255, 255, 0.65) !important;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 40px;
    padding: 0 10px;
    &:hover {
      background-color: #1890ff;
      color: rgba(255, 255, 255, 1) !important;
      .ant-menu-submenu-arrow {
        &:before {
          opacity: 1;
        }
      }
    }
    &.ant-menu-item-selected {
      background-color: transparent !important;
    }
  }
  
  .ant-menu-submenu-arrow {
    margin-top: -8px !important;
    /* height: 16px !important;
    width: 16px !important;
    right: 8px !important; */
    &:before, &:after {
      background: none !important;
    }
    &:before {
      background: url(${moreIcon}) !important;
      opacity: 0.6;
      width: 16px !important;
      height: 16px !important;
      transform: none !important;
    }
  }
`;

export const StyledMenu = styled(Menu)`
  height: 40px;
  line-height: 40px !important;
  border-right: 1px solid #e8e8e8 !important;
`;

export const StyledMenuItem = styled<any>(Item)`
  height: 40px;
  line-height: 40px;
  padding: 0 10px !important;
  top: 0px !important;
  cursor: ${(props) => (props.showIcon ? 'default !important' : 'pointer')};
`;

export const StyledLogo = styled.div`
  width: 80px;
  text-align: center;
  font-weight: bold;
  img {
    width: 32px;
  }
`;

export const StyledSearch = styled(Input)`
  display: flex;
  align-items: center;
  width: auto;
  input {
    border-radius: 0 !important;
    border: none !important;
    width: 48px !important;
    transition: width 200ms linear;
    background-color: transparent !important;
    height: 40px;
    padding: 0;
    &:focus {
      width: 100px !important;
    }
    &::placeholder {
      color: #fff;
    }
  }
  .ant-input-group-addon {
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: #fff;
    line-height: 40px;
    padding-right: 8px;
  }
`;

export const StyledNotifDropdown = styled(Dropdown)`
  display: flex;
  align-items: center;
  width: 30px;
  justify-content: center;
  margin: 0 10px;
  .ant-dropdown-link {
    line-height: '40px';
  }
`;

export const StyledFlagDropdown = styled(Dropdown)`
  display: flex;
  margin: 0 10px;
  .ant-dropdown-link {
    line-height: '40px';
    display: 'block';
  }
`;

export const StyledProfileDropdown = styled(Dropdown)`
  width: 120px;
  text-align: center;
  margin: 0 10px;
  .ant-dropdown-link {
    line-height: '40px';
    display: 'block';
  }
`;

export const NavbarWrapper = styled(Block)`
  flex: auto !important;
  overflow: hidden;
  overflow-x: auto;
`;

export const StyledBadge = styled(Badge)`
  font-size: '10px';
  padding: '0 5px';
  height: '14px';
  line-height: '14px';
`;

export const StyledButton = styled.a`
  border: 0;
  padding: 0;
  border: 0;
  line-height: 25px;
  cursor: pointer;
  outline: none;
  background: transparent;
  display: inline-block !important;
`;

export const StyledHead = styled.div`
  display: flex;
`;

export default {};
