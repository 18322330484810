import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spin } from 'antd';

const ME = gql`
  query Me {
    me {
      id
      role
    }
  }
`;

type Props =
  | {
      id: string;
      role: string;
    }
  | undefined;

export const AdminIdContext = React.createContext<Props>(undefined);

type ReactNode = {
  children:
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | boolean
    | null
    | string
    | undefined;
};

export const AdminIdProvider = ({ children }: ReactNode) => {
  const { loading, data } = useQuery(ME);
  if (loading) return <Spin />;

  const { me = {} } = data;
  const { id, role } = me;

  return (
    <AdminIdContext.Provider
      value={{
        id,
        role,
      }}
    >
      {children}
    </AdminIdContext.Provider>
  );
};

export const useAdminIdValue = () => React.useContext(AdminIdContext);

export default {};
