import * as yup from 'yup';

export default yup.object().shape({
  username: yup
    .array()
    .nullable()
    .required('required'),
  forceChangeWithdrawalPassword: yup
    .boolean()
    .nullable()
    .required('required'),
});
