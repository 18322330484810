import gql from 'graphql-tag';

export const VENDORS = gql`
  query {
    vendors(filter: { type: { eq: TRANSFER } }) {
      edges {
        node {
          id
          nameEn: name(language: EN)
          nameZh: name(language: ZH)
          shortcode
        }
      }
    }
  }
`;
