import React from 'react';
import { Steps } from 'antd';
import useTranslate from 'utils/useTranslate';
import { kebabCase } from 'lodash';
import { DynamicObj } from 'interfaces/user.interface';
import RS from '../RebateSettings';
import PS from '../PayoutSettings';
import RB from '../RebateGroup';
import messages from '../../messages';

const PayoutSettings: any = PS;
const RebateGroup: any = RB;
const RebateSettings: any = RS;

const { Step } = Steps;

export const STEPS_COMPONENTS = [
  <RebateSettings />,
  <PayoutSettings />,
  <RebateGroup />,
];

const StepsIndex = ({
  activeStep,
  steps,
}: {
  activeStep: number;
  steps: DynamicObj;
}) => {
  const translate = useTranslate();

  return (
    <Steps current={activeStep} className="mx-auto">
      {Object.keys(steps).map((_: any, idx: number) => (
        <Step
          key={idx}
          title={translate(
            messages[`common.${kebabCase(Object.values(steps)[idx])}.text`]
          )}
        />
      ))}
    </Steps>
  );
};

export default StepsIndex;
