import gql from 'graphql-tag';

export const GET_BALANCE_TRANSACTION_RECORDS = gql`
  query BalanceTransactionRecords(
    $first: Int
    $after: Binary
    $filter: BalanceTransactionRecordsFilterInput
  ) {
    balanceTransactionRecords(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          serialCode
          actual
          affiliate
          amount
          balance
          type
          transaction
          notes
          processor {
            id
            username
            role
          }
          member {
            id
            username
            platformId
          }
          remarks(language: EN)
          manualAdjustment {
            id
            type
            remarks
          }
          manualAdjustmentRemarks
          dateTimeCreated
          mainCashBalance
          casinoCashBalance
          sportsCashBalance
          casinoBonusBalance
          sportsBonusBalance
          mainCashBase
          casinoCashBase
          sportsCashBase
          casinoBonusBase
          sportsBonusBase
          exchangeRate
          currency
          amountInBaseCurrency
          balanceInBaseCurrency
          isCrypto
          cryptoAmount
          cryptoCurrency
          cryptoExchangeRate
          cryptoBalance
        }
      }
    }
  }
`;

export const GET_BALANCE_TRANSACTION_RECORD_IDS = gql`
  query BalanceTransactionIds(
    $filter: BalanceTransactionRecordsFilterInput
    $partialFilter: BalanceTransactionRecordsFilterInput
  ) {
    balanceTransactionRecords(filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: balanceTransactionRecords(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_BTR_SERIAL_CODES = gql`
  query FilterBTRSerialCode(
    $first: Int
    $after: Binary
    $filter: BalanceTransactionRecordsFilterInput
  ) {
    balanceTransactionRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          serialCode
        }
      }
    }
  }
`;

export const GET_BTR_MEMBER_BRAND_IDS = gql`
  query FilterBTRBrandIds(
    $first: Int
    $after: Binary
    $filter: BalanceTransactionRecordsFilterInput
  ) {
    balanceTransactionRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          member {
            id
            brandId
          }
        }
      }
    }
  }
`;

export const GET_BTR_MEMBER_PLATFORM_IDS = gql`
  query FilterBTRPlatformIds(
    $first: Int
    $after: Binary
    $filter: BalanceTransactionRecordsFilterInput
  ) {
    balanceTransactionRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          member {
            id
            platformId
          }
        }
      }
    }
  }
`;

export const GET_BTR_REMARKS = gql`
  query FilterBTRRemarks(
    $first: Int
    $after: Binary
    $filter: BalanceTransactionRecordsFilterInput
  ) {
    balanceTransactionRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          remarks
        }
      }
    }
  }
`;
export const GET_BTR_MANUAL_ADJUSTMENT_REMARKS = gql`
  query FilterBTRAdjustmentRemarks(
    $first: Int
    $after: Binary
    $filter: BalanceTransactionRecordsFilterInput
  ) {
    balanceTransactionRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          manualAdjustmentRemarks
        }
      }
    }
  }
`;

export const GET_BTR_TRANSACTIONS = gql`
  query FilterBTRTransactions(
    $first: Int
    $after: Binary
    $filter: BalanceTransactionRecordsFilterInput
  ) {
    balanceTransactionRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          transaction
        }
      }
    }
  }
`;

export const GET_BTR_USERNAMES = gql`
  query FilterMBRUsernames(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          username
        }
      }
    }
  }
`;

export const GET_BTR_PROCESSOR = gql`
  query FilterMBRProcessor(
    $first: Int
    $after: Binary
    $filter: OperatorsFilterInput
  ) {
    operators(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          username
        }
      }
    }
  }
`;
