import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Button, Popover } from 'antd';
import { MEMBER_MANAGEMENT } from 'constants/testIds';
import { MemberTag } from 'types/graphqlTypes';

import AddMemberTag from './components/AddMemberTag';
import { StyledColorSquare } from './styles';

type Rec = Record<string, any>;

const MemberTagsSelect = ({
  value = [],
  onChange,
  disableAddLabel,
  disableClosable,
  buttonProps,
  setDeletedTags,
}: {
  onChange?: (e: any) => void;
  value: any[];
  disableAddLabel?: boolean;
  disableClosable?: boolean;
  buttonProps?: Rec;
  setDeletedTags?: Function;
}) => {
  const handleAddTag = (e: Rec) => {
    if (!value.map((item) => item.id).includes(e.id)) {
      if (onChange) {
        onChange([...value, e]);
      }
    }
  };

  const handleRemoveTag = (e: string) => {
    if (onChange) {
      onChange(value.filter((item) => item.id !== e));

      const removedTags = value.filter((item) => item.id === e);
      setDeletedTags!((prev: MemberTag[]) => [...prev, removedTags[0]]);
    }
  };

  return (
    <div data-testid={MEMBER_MANAGEMENT.memberLabels}>
      <div data-testid={MEMBER_MANAGEMENT.selectedMemberLabels}>
        {value.map((item) => (
          <Tag
            {...(!disableClosable && { closable: true })}
            key={item.id}
            onClick={() => handleRemoveTag(item.id)}
            onClose={() => handleRemoveTag(item.id)}
            className="mb-1"
          >
            <StyledColorSquare color={item.color} />
            <span data-testid={MEMBER_MANAGEMENT.selecteMemberLabelName}>
              {item.name}
            </span>
          </Tag>
        ))}
      </div>

      {!disableAddLabel && (
        <Popover
          content={<AddMemberTag onSelect={handleAddTag} value={value} />}
          trigger="click"
        >
          <Button size="small" {...buttonProps} role="button">
            <PlusOutlined />
            <FormattedMessage id="new-label.text" defaultMessage="New Label" />
          </Button>
        </Popover>
      )}
    </div>
  );
};

export default MemberTagsSelect;
