import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers';
import { Button, Input, message, Modal } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Config } from 'types/graphqlTypes';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import * as yup from 'yup';

const NETELLER_SHOP_DETAILS = gql`
  query NetellerShopDetails {
    config {
      netellerSecretKey
      netellerShopId
    }
  }
`;

const UPDATE_NETELLER_DETAILS = gql`
  mutation UpdateNetellerShopDetails($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

const noWhitespaceAndRequired = yup
  .string()
  .nullable()
  .required('Field is required')
  .matches(/^\S+$/, 'No whitespace allowed');

const validationSchema = yup.object().shape({
  netellerShopId: noWhitespaceAndRequired,
  netellerSecretKey: noWhitespaceAndRequired,
});

const NetellerShopDetails = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { control, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      netellerShopId: '',
      netellerSecretKey: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { context } = useOperatorHeader();
  const [updateNeteller, { loading: mutationLoading }] = useMutation(
    UPDATE_NETELLER_DETAILS,
    {
      context,
    }
  );

  const { loading, refetch } = useQuery<{ config: Config }>(
    NETELLER_SHOP_DETAILS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: ({ config }) => {
        reset({
          netellerShopId: config.netellerShopId!,
          netellerSecretKey: config.netellerSecretKey!,
        });
      },
    }
  );

  const handleOnOk = (values: Record<string, any>) => {
    updateNeteller({
      variables: {
        input: {
          ...values,
        },
      },
    })
      .then(() => {
        message.success('Neteller Shop Details updated!');
      })
      .finally(() => {
        setIsModalVisible(false);
        refetch();
      });
  };

  return (
    <div>
      <h3>Manage Neteller Shop Details</h3>
      <Button onClick={() => setIsModalVisible(true)}>
        Configure Neteller Shop Details
      </Button>

      <Modal
        destroyOnClose
        title="Configure Neteller Shop Details"
        visible={isModalVisible}
        onOk={handleSubmit(handleOnOk)}
        onCancel={() => setIsModalVisible(false)}
        okButtonProps={{ loading: loading || mutationLoading }}
        cancelButtonProps={{ loading: loading || mutationLoading }}
      >
        <div className="mb-3">
          <p className="mb-1 fw-500">Neteller Shop ID:</p>
          <Controller as={Input} control={control} name="netellerShopId" />
          <span className="text-11 text-danger">
            {errors?.netellerShopId?.message}
          </span>
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">Neteller Secret Key:</p>
          <Controller as={Input} control={control} name="netellerSecretKey" />
          <span className="text-11 text-danger">
            {errors?.netellerSecretKey?.message}
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default NetellerShopDetails;
