import styled from 'styled-components';
import { Input } from 'antd';

export const StyledInput = styled(Input)`
  .ant-input {
    padding-right: 30% !important;
    background: #f0f3f7;
  }
  .ant-input-suffix {
    right: 0 !important;
  }
`;

export const StyledCenter = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {};
