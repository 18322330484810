import gql from 'graphql-tag';

export const GET_PAYMENT_SETTINGS = gql`
  query paymentSettings {
    config {
      paymentMethodConfiguration {
        enable
        active
        rank
        type
      }
      paymentMethodRotation {
        successRateWeight
        elapseMinuteWeight
      }
    }
  }
`;

export const UPDATE_PAYMENT_SETTINGS = gql`
  mutation updatePaymentSetting($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;
