/* eslint-disable no-underscore-dangle */
import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';
import copyToClipboard from 'utils/copyToClipboard';
import { ALink } from 'components/ALink/ALink';
import moment from 'moment';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { Deposit } from 'interfaces/user.interface';
import { DATE_FORMAT } from 'constants/date';

type PropTypes = {
  readOnly: boolean;
  deposit: Deposit;
};

const PaymentMethodDetails = (props: PropTypes) => {
  const { deposit, readOnly } = props;
  const {
    paymentMethod,
    serialCode,
    status,
    dateTimeCreated,
    dateTimeProcessed,
    processor,
    depositorName,
  } = deposit;

  const { Text } = Typography;

  const messages = defineMessages({
    'alipay.text': {
      id: 'alipay.text',
      defaultMessage: 'Alipay',
    },
    'wechat.text': {
      id: 'wechat.text',
      defaultMessage: 'Wechat',
    },
  });
  const translate = useTranslate();
  const paymentMethodTypes = {
    AlipayPaymentMethod: translate(messages['alipay.text']),
    WechatPaymentMethod: translate(messages['wechat.text']),
  };

  return (
    <div data-testid="payment-method-details">
      <div className="menu-header p-2 mt-4">
        <FormattedMessage
          id="payment-method-details.text"
          defaultMessage="Payment method details"
        />
      </div>
      <div className="d-flex p-2 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="payment-method.text"
              defaultMessage="Payment method"
            />
          </Text>
        </div>
        <div data-testid="payment-method" className="flex-1 text-right">
          {paymentMethodTypes[paymentMethod.__typename]}{' '}
          <ALink
            disabled={readOnly}
            onClick={() =>
              copyToClipboard(paymentMethodTypes[paymentMethod.__typename])
            }
          >
            <CopyOutlined />
          </ALink>
        </div>
      </div>
      <div className="d-flex p-2 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage id="nickname.text" defaultMessage="Nickname" />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {paymentMethod.nickname}{' '}
          <ALink
            disabled={readOnly}
            onClick={() => copyToClipboard(paymentMethod.nickname)}
          >
            <CopyOutlined />
          </ALink>
        </div>
      </div>
      <div className="d-flex p-2 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="reference-code.text"
              defaultMessage="Reference code"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {serialCode || '-'}{' '}
          {serialCode && (
            <ALink
              disabled={readOnly}
              onClick={() => copyToClipboard(serialCode)}
            >
              <CopyOutlined />
            </ALink>
          )}
        </div>
      </div>
      <div className="d-flex p-2 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="processing-time.text"
              defaultMessage="Processing time"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {status === 'EXPIRED' && '-'}
          {(status === 'APPROVED' || status === 'REJECTED') && (
            <>
              {moment(dateTimeProcessed).format(`${DATE_FORMAT} (ddd)`)}
              <br />
              {moment(dateTimeProcessed).format('HH:mm:ss')} -{' '}
              {moment(dateTimeProcessed).from(dateTimeCreated, true)}
            </>
          )}
          {(status === 'PROCESSING' || status === 'PENDING') && (
            <>
              {moment(dateTimeCreated).format(`${DATE_FORMAT} (ddd)`)}
              <br />
              {moment(dateTimeCreated).format('HH:mm:ss')} -{' '}
              {moment(dateTimeCreated).fromNow()}
            </>
          )}
        </div>
      </div>
      <div className="d-flex p-2 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage id="operator.text" defaultMessage="Operator" />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {coercedGet(processor, 'username', '-')}
        </div>
      </div>
      <div className="menu-header p-2 mt-4">
        <FormattedMessage
          id="depositor-details.text"
          defaultMessage="Depositor Details"
        />
      </div>
      <div className="p-2 bb-1 d-flex">
        <div className="mb-2">
          <Text type="secondary">
            <FormattedMessage
              id="deposit.account-id.text"
              defaultMessage="Account ID"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {depositorName || '-'}{' '}
          {depositorName && (
            <ALink
              disabled={readOnly}
              onClick={() => copyToClipboard(depositorName)}
            >
              <CopyOutlined />
            </ALink>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodDetails;
