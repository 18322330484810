import { customFormatMessage } from 'utils/customFormatMessage';
import {
  isTypeAlipay,
  isTypeBank,
  isTypeManual,
  isTypeWechat,
  isTypeHexopay,
  PAYMENT_METHODS_LIST,
} from 'constants/paymentTypes';
import messages from './messages';

export const allColumns = (formatMessage: any) => {
  const translate = (messageVal: any) =>
    customFormatMessage(formatMessage, messageVal);

  return [
    {
      name: 'withdrawalMethodName',
      label: translate(messages.title),
    },
    {
      name: 'paymentSources',
      label: translate(messages.sources),
    },
    {
      name: 'provider',
      label: translate(messages.provider),
    },
    {
      name: 'paymentMethod',
      label: translate(messages.paymentmethod),
    },
    {
      name: 'bankName',
      label: translate(messages.bankname),
    },
    {
      name: 'status',
      label: translate(messages.status),
    },
    {
      name: 'withdrawalMethodRemark',
      label: translate(messages.remark),
    },
    {
      name: 'actions',
      label: translate(messages.action),
    },
  ];
};

export const defaultPaymentSource = {
  setValues: (values = {}) => {
    // Note: The values should have the these keys "withdrawalMethodId" and "paymentSourceType",
    if (typeof values === 'object') {
      const storeDefaultValues = JSON.stringify(values);
      sessionStorage.setItem('lastPaymentSourceValues', storeDefaultValues);
    }
  },
  getValues: () => {
    try {
      // This sets up the default withdrawal method and payment source type based from the last approval
      let defaultValues = sessionStorage.getItem(
        'lastPaymentSourceValues'
      ) as any;
      defaultValues = JSON.parse(defaultValues);
      return defaultValues;
    } catch (err) {
      return {};
    }
  },
};

export const renderPaymentMethodByType = (
  translate: (e: any) => void,
  method: string
) => {
  if (method === 'CryptocurrencyWithdrawalMethod') return 'Crypto';
  if (method === 'NetellerWithdrawalMethod') return 'Neteller';
  if (method === 'WonkaPayWithdrawalMethod') return 'Wonkapay';
  if (method === 'SkrillWithdrawalMethod') return 'Skrill';
  if (method === 'AstroPayWalletWithdrawalMethod') return 'Astropay';

  if (isTypeBank(PAYMENT_METHODS_LIST[method])) {
    return translate(messages.banktransfer);
  }

  if (isTypeWechat(PAYMENT_METHODS_LIST[method])) {
    return translate(messages.wechat);
  }

  if (isTypeAlipay(PAYMENT_METHODS_LIST[method])) {
    return translate(messages.alipay);
  }

  if (isTypeManual(PAYMENT_METHODS_LIST[method])) {
    return translate(messages.MANUAL_ADJUSTMENT);
  }

  if (isTypeHexopay(PAYMENT_METHODS_LIST[method])) {
    return translate(messages.hexopay);
  }

  return translate(messages.unknownmethod);
};
