import React from 'react';
import { Col } from 'antd';
import Metrics from './components/Metrics';
import DateRangeFilter from './components/DateRangeFilter';
import GameTypeSelect from './components/GameTypeSelect';

const ChartFilter = ({
  state,
  setState,
}: {
  state: {
    metric: string[];
    dateRange: Array<any>;
    gameTypeColumns: Array<any>;
  };
  setState: (e: any) => void;
}) => (
  <>
    <Col span={6}>
      <Metrics state={state} setState={setState} />
    </Col>
    <Col span={6}>
      <DateRangeFilter state={state} setState={setState} />
    </Col>
    <Col span={12}>
      <GameTypeSelect state={state} setState={setState} />
    </Col>
  </>
);

export default ChartFilter;
