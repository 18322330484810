import React, { useState } from 'react';
import { Modal, Button, Spin } from 'antd';
import { ALink } from 'components/ALink/ALink';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';

export const DETAILS = gql`
  query transactionDetails($id: ID!) {
    deposit(id: $id) {
      id
      transactionDetails {
        data
      }
    }
  }
`;

type HexopayTransactionType = {
  id: string;
  data: string;
};

const HexopayTransaction = React.memo(
  ({ id, data }: HexopayTransactionType) => {
    const [isShow, setShow] = useState(false);
    const handleShow = () => setShow(!isShow);

    const [
      loadQuery,
      { data: detailsData, loading, error, called },
    ] = useLazyQuery(DETAILS, {
      fetchPolicy: 'network-only',
      variables: { id },
    });

    return (
      <>
        <ALink
          onClick={() => {
            if (!called) loadQuery();
            handleShow();
          }}
        >
          {data}
        </ALink>

        {isShow && (
          <Modal
            centered
            visible
            maskClosable
            width={540}
            onCancel={handleShow}
            footer={[
              <Button type="primary" key="cancel" onClick={handleShow}>
                Close
              </Button>,
            ]}
          >
            {loading && (
              <div className="styled-center">
                <Spin />
              </div>
            )}

            {error && <TableErrorCell errorMessage={error.message} />}
            {!coercedGet(
              detailsData,
              'deposit.transactionDetails.data',
              null
            ) &&
              !loading && <>&nbsp;No Transaction Details Available</>}

            {coercedGet(
              detailsData,
              'deposit.transactionDetails.data',
              null
            ) && (
              <pre>
                {JSON.stringify(
                  coercedGet(
                    detailsData,
                    'deposit.transactionDetails.data',
                    {}
                  ),
                  null,
                  1
                )}
              </pre>
            )}
          </Modal>
        )}
      </>
    );
  }
);

export default HexopayTransaction;
