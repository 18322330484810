import { Divider } from 'antd';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import React from 'react';
import { useIntl } from 'react-intl';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import RequestUpdateToggle from 'components/RequestUpdateToggle';
import { customFormatMessage } from 'utils/customFormatMessage';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import messages from '../../messages';

type Props = {
  isRealtime?: boolean;
  handleSetRealtime?: () => void;
  refreshPage: () => void;
  refetchVariables: Record<string, any>;
};

const HeaderRight: React.FC<Props> = ({
  handleSetRealtime,
  isRealtime,
  refreshPage,
  refetchVariables,
}) => {
  const { formatMessage } = useIntl();
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);

  return (
    <>
      <RequestUpdateToggle checked={isRealtime} onChange={handleSetRealtime} />
      <RefreshButton onClick={refreshPage} />
      <Divider type="vertical" className="ml-0 mr-2" />
      <ColumnsFilterNew iconOnly tabId="withdrawal-requests" />
      <SpreadsheetDownload
        permission
        iconOnly
        filter={refetchVariables.filter}
        filename={translate(messages.withdrawalRequest)}
        extension="csv"
      />
    </>
  );
};

export default HeaderRight;
