import esget from 'utils/esGet';
import messages from 'pages/components/Agent/components/AffiliateRequest/messages';

import moment from 'moment';
import { AffiliateRequest } from 'types/graphqlTypes';
import { getProcessingTime } from 'pages/components/Agent/components/AffiliateRequest/constants';
import { DATE_TIME_FORMAT } from 'constants/date';

type Translator = (
  { id, defaultMessage }: { id: string; defaultMessage: string },
  options?: any
) => string;

const getCsvHeaders = (translate: Translator) => [
  {
    label: translate(messages['promo-request.request-id.text']),
    key: 'serialCode',
    renderCell: (affiliateRequest: AffiliateRequest) =>
      esget(affiliateRequest?.serialCode, '-'),
  },
  {
    label: translate(messages['username.text']),
    key: 'username',
    renderCell: (affiliateRequest: AffiliateRequest) =>
      esget(affiliateRequest?.member?.username, '-'),
  },
  {
    label: translate(messages['real-name.text']),
    key: 'realName',
    renderCell: (affiliateRequest: AffiliateRequest) =>
      esget(affiliateRequest?.member?.realName, '-'),
  },
  {
    label: translate(messages['affiliate-programme.text']),
    key: 'programme',
    renderCell: (affiliateRequest: AffiliateRequest) =>
      esget(affiliateRequest?.programme?.name, '-'),
  },
  {
    label: translate(messages['status.text']),
    key: 'status',
    renderCell: (affiliateRequest: AffiliateRequest) =>
      translate(
        messages[`${esget(affiliateRequest?.status, '-')}.toLowerCase()}.text`]
      ),
  },
  {
    label: translate(messages['vip.text']),
    key: 'vipLevel',
    renderCell: (affiliateRequest: AffiliateRequest) => {
      const memberLevels = esget(
        affiliateRequest?.member?.memberLoyaltyLevels,
        '-'
      );
      return memberLevels?.map((item) => item.name);
    },
  },
  {
    label: translate(messages['request-date.text']),
    key: 'requestDate',
    renderCell: (affiliateRequest: AffiliateRequest) =>
      moment(esget(affiliateRequest?.dateTimeCreated, null)).format(
        DATE_TIME_FORMAT
      ) || '-',
  },
  {
    label: translate(messages['processor.text']),
    key: 'processor',
    renderCell: (affiliateRequest: AffiliateRequest) =>
      esget(affiliateRequest?.processor?.username, '-'),
  },
  {
    label: translate(messages['time-processed.text']),
    key: 'id',
    renderCell: (affiliateRequest: AffiliateRequest) =>
      getProcessingTime(affiliateRequest),
  },
];

export default getCsvHeaders;
