import { useMutation, useQuery } from '@apollo/react-hooks';
import { Col, Input, message, Row, Spin, Typography } from 'antd';
import Circle from 'components/Circle';
import PageLoader from 'components/PageLoader';
import { DATE_FORMAT } from 'constants/date';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  START_PROCESS_DEPOSIT,
  UPDATE_DEPOSIT,
} from 'graphql/mutations/deposit.mutation';
import { DEPOSIT, DEPOSIT_REQUESTS } from 'graphql/queries/deposit.query';
import { isEmpty } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import globalMessages from 'messages';
import moment from 'moment';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useAccount, usePermissions } from 'store/accountState';
import coercedGet from 'utils/coercedGet';
import { getStatusColor } from 'utils/depositWithdrawalStatus';
import { isReadOnly } from 'utils/paymentsOperation';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import ApproveDeposit from '../ApproveDeposit';
import ProcessDeposit from '../ProcessDeposit';
import RejectDeposit from '../RejectDeposit';
import BankPaymentMethodDetails from './components/BankPaymentMethodDetails';
import CryptoCurrencyInformation from './components/CrytoCurrencyPaymentInformation';
import AccountInformation from './components/HexoPayDeposit';
import MemberInformation from './components/MemberInformation';
import PaymentMethodDetails from './components/PaymentMethodDetails';
import { StyledModal } from './styles';

interface IProps {
  toggleModal: any;
  id: string | any;
  refetchVariables: {};
  refetchDepositRequests: (item: object) => void;
  disabled: boolean;
}
const DepositDetails = (props: IProps) => {
  const { locale } = useIntl();
  const {
    account: { account: user },
  } = useAccount();

  const { role, permissions } = usePermissions();
  const { context } = useOperatorHeader();
  const { Text } = Typography;

  const {
    ALLOWED_APPROVE,
    ALLOWED_REJECT,
    ALLOWED_PROCESS,
    ALLOWED_UPDATE_REMARKS,
  } = collectPermissions(
    role,
    permissions,
    ['APPROVE', 'REJECT', 'PROCESS', 'UPDATE_REMARKS'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_INTERNAL_DEPOSITS
  );

  const {
    toggleModal,
    id,
    refetchVariables,
    refetchDepositRequests,
    disabled,
  } = props;

  interface IState {
    remarks: string;
    remarksError: string;
    timeout: any;
  }
  const [state, setState] = useState<IState>({
    remarks: '',
    remarksError: '',
    timeout: null,
  });

  const { TextArea } = Input;

  const messages = defineMessages({
    'operator-remark.text': {
      id: 'operator-remark.text',
      defaultMessage: 'Operator Remark',
    },
    startProcessDeposit: {
      id: 'deposits.started-deposit-process.text',
      defaultMessage: 'Started deposit process',
    },
    MANUAL_ADJUSTMENT_DEPOSIT_REMARK: {
      id: 'MANUAL_ADJUSTMENT_DEPOSIT_REMARK',
      defaultMessage: 'Manual Adjustment - Deposit',
    },
  });

  const setRemarksError = (error: any) => {
    setState({
      ...state,
      remarksError: error,
    });
  };

  const refetchQueries = [
    {
      query: DEPOSIT,
      variables: { id },
    },
    {
      query: DEPOSIT_REQUESTS,
      variables: refetchVariables,
    },
  ];

  const [updateDeposit] = useMutation(UPDATE_DEPOSIT, {
    variables: { id, remarks: state.remarks },
    refetchQueries,
  });
  const translate = useTranslate();
  const [startProcessDeposit, startProcessResult] = useMutation(
    START_PROCESS_DEPOSIT,
    {
      onCompleted: (data) => {
        if (!data.startProcessDeposit) {
          message.success(translate(messages.startProcessDeposit));
        }
      },
      variables: { id },
      refetchQueries,
    }
  );

  const { loading, error, data } = useQuery(DEPOSIT, {
    variables: { id, language: locale.toUpperCase() },
    context,
    onCompleted: (result) => {
      if (result && result.deposit) {
        const { status: depositStatus } = result.deposit;
        setState({ ...state, remarks: result.deposit.remarks });
        if (depositStatus === 'PENDING' && ALLOWED_PROCESS) {
          startProcessDeposit();
        }
      }
    },
  });

  if (loading) {
    return <PageLoader />;
  }

  const { deposit } = data || {};
  const {
    status,
    serialCode,
    dateTimeProcessed,
    dateTimeCreated,
    manualAdjustment,
    processor = {},
    __typename,
  } = deposit || {};

  const readOnly = isReadOnly({
    me: user,
    processor,
    status,
  });
  const manualAdjustmentMethod = __typename === 'ManualAdjustmentDeposit';
  const cryptoCurrencyMethod = __typename === 'CryptocurrencyDeposit';
  const offlineBankMethod = __typename === 'OfflineBankTransferDeposit';
  const alipayWechatMethod =
    __typename === 'AlipayDeposit' || __typename === 'WechatDeposit';
  const hexoPayDeposit = __typename === 'HexoPayDeposit';
  const netellerDeposit = __typename === 'NetellerDeposit';
  const skrillDeposit = __typename === 'SkrillDeposit';

  return (
    <StyledModal
      readOnly={readOnly}
      visible
      onOk={toggleModal}
      onCancel={toggleModal}
      width={cryptoCurrencyMethod ? 1100 : 700}
      footer={
        loading ||
        startProcessResult.loading ||
        (!isEmpty(processor) && user && user.id !== processor.id)
          ? null
          : [
              <>
                {ALLOWED_PROCESS && ALLOWED_APPROVE && (
                  <ApproveDeposit
                    {...props}
                    status={status}
                    refetchQueries={refetchQueries}
                    setRemarksError={setRemarksError}
                  />
                )}
              </>,
              <>
                {ALLOWED_PROCESS && ALLOWED_REJECT && (
                  <RejectDeposit
                    {...props}
                    status={status}
                    refetchQueries={refetchQueries}
                    remarks={state.remarks}
                    setRemarksError={setRemarksError}
                    refetchVariables={refetchVariables}
                    refetchDepositRequests={refetchDepositRequests}
                  />
                )}
              </>,
              <>
                {ALLOWED_PROCESS && (
                  <ProcessDeposit
                    {...props}
                    status={status}
                    refetchQueries={refetchQueries}
                    setRemarksError={setRemarksError}
                  />
                )}
              </>,
            ]
      }
    >
      <div data-testid="modal">
        {error && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 200 }}
          >
            <span className="text-danger">
              {translate(globalMessages['fatal-error.text'])}
            </span>
          </div>
        )}
        {loading || startProcessResult.loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 200 }}
          >
            <Spin />
          </div>
        ) : (
          !error && (
            <>
              <div className="p-3 bb-1 d-flex align-items-center">
                <h4 className="mb-0 mr-3">
                  <FormattedMessage
                    id="deposit-request-details.text"
                    defaultMessage="Deposit request details"
                  />
                </h4>
                <div>
                  <FormattedMessage
                    id="serial-code.text"
                    defaultMessage="Serial code"
                  />
                  : {serialCode}
                </div>
                <span
                  className="ml-4"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Circle size={15} color={getStatusColor(status)} filled />{' '}
                  <span data-testid="status">
                    <FormattedMessage id={`${status.toLowerCase()}.text`} />
                  </span>
                </span>
              </div>
              <Row>
                <Col span={cryptoCurrencyMethod ? 8 : 12}>
                  <div className="p-3">
                    <MemberInformation deposit={deposit} readOnly={readOnly} />
                  </div>
                </Col>
                {cryptoCurrencyMethod ? (
                  <Col span={8}>
                    <div className="p-3">
                      <CryptoCurrencyInformation
                        deposit={deposit}
                        readOnly={readOnly}
                      />
                    </div>
                  </Col>
                ) : (
                  ''
                )}

                <Col span={cryptoCurrencyMethod ? 8 : 12} className="bl-1">
                  <div className="p-3">
                    <div className="menu-header p-2">
                      <FormattedMessage
                        id="operator-remark.text"
                        defaultMessage="Operator Remark"
                      />
                    </div>
                    <TextArea
                      placeholder={translate(messages['operator-remark.text'])}
                      aria-label={state.remarks}
                      disabled={!ALLOWED_UPDATE_REMARKS || readOnly || disabled}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      className="remarks-area mt-2"
                      value={
                        !manualAdjustment
                          ? state.remarks
                          : `Manual Adjustment | Operator ${deposit?.processor?.username} | Amount ${deposit?.amount}`
                      }
                      onChange={(e: any) =>
                        setState({
                          ...state,
                          remarks: e.target.value || '',
                        })
                      }
                      onKeyUp={() => {
                        clearTimeout(state.timeout);
                        setState({
                          ...state,
                          timeout: setTimeout(() => updateDeposit(), 500),
                        });
                      }}
                    />
                    {state.remarksError && (
                      <small className="text-error">{state.remarksError}</small>
                    )}
                    {(hexoPayDeposit || skrillDeposit || netellerDeposit) && (
                      <AccountInformation deposit={deposit} />
                    )}
                    {(offlineBankMethod || manualAdjustmentMethod) && (
                      <BankPaymentMethodDetails
                        deposit={deposit}
                        readOnly={readOnly}
                      />
                    )}
                    {alipayWechatMethod && (
                      <PaymentMethodDetails
                        deposit={deposit}
                        readOnly={readOnly}
                      />
                    )}
                    {cryptoCurrencyMethod && (
                      <>
                        <div className="d-flex p-2 bb-1 mt-2">
                          <div>
                            <Text type="secondary">
                              <FormattedMessage
                                id="processing-time.text"
                                defaultMessage="Processing time"
                              />
                            </Text>
                          </div>
                          <div className="flex-1 text-right">
                            {status === 'EXPIRED' && '-'}
                            {(status === 'APPROVED' ||
                              status === 'REJECTED') && (
                              <>
                                {moment(dateTimeProcessed).format(
                                  `${DATE_FORMAT} (ddd)`
                                )}
                                <br />
                                {moment(dateTimeProcessed).format(
                                  'HH:mm:ss'
                                )} -{' '}
                                {moment(dateTimeProcessed).from(
                                  dateTimeCreated,
                                  true
                                )}
                              </>
                            )}
                            {(status === 'PROCESSING' ||
                              status === 'PENDING') && (
                              <>
                                {moment(dateTimeCreated).format(
                                  `${DATE_FORMAT} (ddd)`
                                )}
                                <br />
                                {moment(dateTimeCreated).format(
                                  'HH:mm:ss'
                                )} - {moment(dateTimeCreated).fromNow()}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="d-flex p-2 bb-1">
                          <div>
                            <Text type="secondary">
                              <FormattedMessage
                                id="operator.text"
                                defaultMessage="Operator"
                              />
                            </Text>
                          </div>
                          <div className="flex-1 text-right">
                            {coercedGet(processor, 'username', '-')}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )
        )}
      </div>
    </StyledModal>
  );
};

export default DepositDetails;
