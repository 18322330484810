import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button } from 'antd';
import Vendor from './components/Vendor';
import GameType from './components/GameType';

const TableFilter = ({
  state,
  setState,
}: {
  state: {
    vendors: any[];
    gameTypes: string[];
  };
  setState: (e: Record<string, any>) => void;
}) => (
  <Row gutter={16}>
    <Col span={6}>
      <Vendor state={state} setState={setState} />
    </Col>
    <Col span={6}>
      <GameType state={state} setState={setState} />
    </Col>
    <Col span={12}>
      <Button
        style={{ marginTop: 35 }}
        onClick={() =>
          setState({
            ...state,
            gameTypes: [],
            vendors: [],
          })
        }
      >
        <FormattedMessage id="reports.clear.text" defaultMessage="Clear" />
      </Button>
    </Col>
  </Row>
);

export default TableFilter;
