import Layout from 'components/Layout';
import SavedFilterSelect from 'components/SavedFilterSelect';
import { useFilterValues } from 'contexts/Filters';
import CountFilter from 'pages/components/IPAddressSummary/components/CountFilter';
import globalMsgs from 'messages';
import React, { useState } from 'react';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { QuickSearchIds } from 'components/QuickSearchFilter/QuickSearchFilter';
import coercedGet from 'utils/coercedGet';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import { GET_IPADDRESS } from 'graphql/queries/ipAddressSummary.query';
import SelectFilterField from 'components/SelectFilterField';
import { blockAlphabets } from 'utils/blockAlphabets';
import SearchSettingsForm from '../SearchSettingsForm';

const Sidebar = () => {
  const translate = useTranslate();
  const [key, setKey] = useState(0);
  const {
    savedFilterId,
    handleSavedFilterIdChange,
    handleFilterChange,
    filters,
    onFilterChange,
  } = useFilterValues() as any;

  const handleUpdateKey = () => {
    setKey((prevState) => prevState + 1);
  };

  const onChangeCount = (count: any) => {
    const currentFilter = coercedGet(filters, 'membersCount', {});
    const newFilter = { ...currentFilter, [count.range]: count.value };

    handleFilterChange({
      name: 'membersCount',
      value: count.value ? removeNull(newFilter) : null,
    });
  };

  return (
    <Layout.Sidebar
      data-testid="sidebarIPAddressSummary"
      quickSearch={{
        filters,
        onFilterChange,
        contextKey: 'ip-address-summary',
        searchSettingsForm: SearchSettingsForm,
        isNext: true,
        quickSearchId: QuickSearchIds.IP_ADDRESS_SUMMARY,
        onFiltersUpdate: handleUpdateKey,
        hideQuickSearchFilter: true,
      }}
    >
      <>
        <FilterItem label={translate(globalMsgs.QUICK_FILTER)}>
          <SavedFilterSelect
            key={key}
            value={savedFilterId}
            placeHolder={translate(globalMsgs.SELECT_SAVED_CUSTOM_FILTER)}
            onChange={(val: string) => handleSavedFilterIdChange(val)}
            context="ip-address-summary"
          />
        </FilterItem>

        <SelectFilterField
          testId="ip-address-filter-select"
          label="IP Address"
          query={GET_IPADDRESS}
          queryConnection="ipAddressSummaryReport"
          filterFieldName="ipAddress"
          filters={filters}
          onChange={(e) =>
            handleFilterChange({
              name: 'ipAddress',
              value: e?.length ? { in: e } : null,
            })
          }
          onKeyDown={blockAlphabets}
        />

        <FilterItem
          label={translate(globalMsgs.TOTAL_COUNT)}
          onClear={() =>
            handleFilterChange({
              name: 'membersCount',
              value: null,
            })
          }
        >
          <CountFilter
            value={{
              min: coercedGet(filters, 'membersCount.gte', undefined),
              max: coercedGet(filters, 'membersCount.lte', undefined),
            }}
            onChange={onChangeCount}
            minPlaceHolder={translate(globalMsgs.MIN)}
            maxPlaceHolder={translate(globalMsgs.MAX)}
          />
        </FilterItem>
      </>
    </Layout.Sidebar>
  );
};

export default Sidebar;
