import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'dashboard.filter.today.text': {
    id: 'dashboard.filter.today.text',
    defaultMessage: 'Today',
  },
  'dashboard.filter.yesterday.text': {
    id: 'dashboard.filter.yesterday.text',
    defaultMessage: 'Yesterday',
  },
  'dashboard.filter.this-week.text': {
    id: 'dashboard.filter.this-week.text',
    defaultMessage: 'This Week',
  },
  'dashboard.filter.last-week.text': {
    id: 'dashboard.filter.last-week.text',
    defaultMessage: 'Last Week',
  },
  'dashboard.filter.this-month.text': {
    id: 'dashboard.filter.this-month.text',
    defaultMessage: 'This Month',
  },
  'dashboard.filter.last-month.text': {
    id: 'dashboard.filter.last-month.text',
    defaultMessage: 'Last Month',
  },
  'dashboard.filter.annual.text': {
    id: 'dashboard.filter.annual.text',
    defaultMessage: 'Annual',
  },
});

export default messages;
