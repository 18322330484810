import { useMutation } from '@apollo/react-hooks';
import { Button, message, Select } from 'antd';
import { DATE_TIME_FORMAT } from 'constants/date';
import { TIMEOUT_MEMBER } from 'graphql/mutations/member.mutation';
import MemberDropdown from 'pages/components/NewMemberDetail/components/MemberDropdown';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import {
  ContainerBody,
  ContainerHeader,
} from 'pages/components/NewMemberDetail/styles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PermissionError from 'components/PermissionError';
import { Member, MutationTimeOutMemberArgs } from 'types/graphqlTypes';
import { formatDate } from 'utils/dateUtils';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const timeoutOpts = [
  {
    label: '24 hours',
    value: 86_400_000,
  },
  {
    label: '1 week',
    value: 604_800_000,
  },
  {
    label: '2 weeks',
    value: 1_209_600_000,
  },
  {
    label: '3 weeks',
    value: 1_814_400_000,
  },
  {
    label: '4 weeks',
    value: 2_419_200_000,
  },
  {
    label: '5 weeks',
    value: 3_024_000_000,
  },
  {
    label: '6 weeks',
    value: 3_628_800_000,
  },
];

const MemberTimeOut = () => {
  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedTimeout, setSelectedTimeout] = useState('');
  const { member, refetch } = useMember() as {
    member: Member;
    refetch: Function;
  };
  const { context } = useOperatorHeader();
  const [timeoutMember] = useMutation<boolean, MutationTimeOutMemberArgs>(
    TIMEOUT_MEMBER
  );

  const { MEMBER_TIMEOUT_PERMISSION } = useNewMemberDetailPermissions();

  const onTimeoutMember = () => {
    setLoading(true);
    timeoutMember({
      variables: {
        id: member?.id,
        input: {
          period: selectedTimeout,
        },
      },
      context,
    })
      .then(() => {
        message.success('Successfully applied timeout');
        refetch();
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="mt-3">
      <ContainerHeader>
        <span className="fw-500">
          <FormattedMessage id="TIMEOUT" defaultMessage="Timeout" />
        </span>
        <MemberDropdown showTable={showTable} setShowTable={setShowTable} />
      </ContainerHeader>
      <ContainerBody
        className="py-3 px-3"
        style={{ display: showTable ? 'block' : 'none' }}
      >
        <PermissionError
          message="Member timeout"
          withPermission={MEMBER_TIMEOUT_PERMISSION.ALLOWED_LIST}
        >
          <>
            <div className="d-flex mb-1">
              <span className="mr-2">Timeout:</span>
              <span>{member?.timeOutDuration > 0 ? 'Yes' : 'No'}</span>
            </div>

            <div className="d-flex mb-1">
              <span className="mr-2">Start Time:</span>
              <span>
                {member?.timeOutStartDateTime
                  ? formatDate(member?.timeOutStartDateTime, DATE_TIME_FORMAT)
                  : ''}
              </span>
            </div>

            <div className="d-flex">
              <span className="mr-2">End Time:</span>
              <span>
                {member?.timeOutEndDateTime
                  ? formatDate(member?.timeOutEndDateTime, DATE_TIME_FORMAT)
                  : ''}
              </span>
            </div>

            <div className="d-flex mt-3">
              <PermissionError
                message="Member timeout Edit"
                withPermission={MEMBER_TIMEOUT_PERMISSION.ALLOWED_EDIT}
              >
                <>
                  <Select
                    className="mr-3"
                    style={{ width: '30%' }}
                    value={selectedTimeout}
                    onSelect={(e) => setSelectedTimeout(e)}
                  >
                    <Select.Option value="" disabled>
                      Select Timeout
                    </Select.Option>
                    {timeoutOpts.map((timeout) => (
                      <Select.Option value={timeout.value}>
                        {timeout.label}
                      </Select.Option>
                    ))}
                  </Select>

                  <Button
                    loading={loading}
                    disabled={loading || !selectedTimeout}
                    onClick={onTimeoutMember}
                  >
                    Apply Timeout
                  </Button>
                </>
              </PermissionError>
            </div>
          </>
        </PermissionError>
      </ContainerBody>
    </div>
  );
};

export default MemberTimeOut;
