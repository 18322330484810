import styled from 'styled-components';
import { Button } from 'antd';

export const StyledSearchContainer = styled.a`
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  align-items: center;
  :hover {
    color: rgba(0, 0, 0, 0.45);
  }
`;

export const StyledBlankContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const StyledSearchButton = styled(Button)`
  display: block;
  margin-top: 56px;
`;

export const StyledTagContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  max-height: ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded ? 'unset' : '37px'};

  .cursor-pointer {
    margin-top: 0.7rem;
  }

  .clear-all {
    margin-top: 0.5rem;
  }

  .ant-tag {
    margin: 5px;
  }
`;

export const StyledActionsContainer = styled.div`
  display: flex;
`;

export const TableFooterStyled = styled.div`
  z-index: 1;
  flex: 1;
  display: flex;
  background: #f3f8fb;
  justify-content: flex-end;
  width: 100%;
  padding: 15px 35px;
  align-items: center;

  .next-prev-container {
    margin-top: 0 !important;
  }
`;

// Temporary Overided styles for the revamps
export const StyledSidebarContainer = styled.div`
  .main-sidebar {
    .sidebar-sub-container {
      padding: 0.5rem !important;
      height: calc(100vh - 40px - 116px) !important;
    }

    .sidebar-top-section {
      font-weight: bold;
    }

    .quick-filter-container {
      margin-bottom: 0.5rem !important;
    }

    .filter-label {
      font-size: 10px;
      font-weight: bold;
    }

    .ant-select-selection,
    .ant-input {
      border-radius: 2px;
      border: solid 0.5px rgba(112, 112, 112, 0.4);
    }
  }
`;

export default {};
