import React from 'react';
import coercedGet from 'utils/coercedGet';
import { Tooltip } from 'antd';
import MemberTagsSelect from 'components/MemberTagsSelect';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { useLoaders } from 'contexts/DataLoader';

import TableCellSpinner from 'components/TableCellSpinner';
import { ContainerStyled, TagColorStyled, RemainingStyled } from './style';

interface Props {
  memberId: string;
}

const MemberTagsBubble = ({ memberId }: Props) => {
  const {
    memberTagsLoaderSet: [useMemberTagLoader],
  } = useLoaders();

  const { loading, error, data = {} } = useMemberTagLoader(memberId);

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;
  const tags = coercedGet(data, 'account.tags', []);
  if (tags.length < 1) {
    return <span>-</span>;
  }
  const cleanTags = tags.filter((tag: any) => tag);
  const remainingTags = cleanTags.length - 3;
  const disableAddLabel = true;
  const disableClosable = true;

  return (
    <Tooltip
      placement="bottom"
      title={
        <MemberTagsSelect
          value={cleanTags}
          disableAddLabel={disableAddLabel}
          disableClosable={disableClosable}
        />
      }
    >
      <ContainerStyled>
        <TagColorStyled color={cleanTags[0].color} />
        {cleanTags[1] ? <TagColorStyled color={cleanTags[1].color} /> : ''}
        {cleanTags[2] ? <TagColorStyled color={cleanTags[2].color} /> : ''}
        {remainingTags > 0 ? (
          <RemainingStyled>
            <small>+</small>
            {remainingTags}
          </RemainingStyled>
        ) : null}
      </ContainerStyled>
    </Tooltip>
  );
};

export default MemberTagsBubble;
