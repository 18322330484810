import React from 'react';
import { Input } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { useFormikContext } from 'formik';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';

const NameInput: React.FC = () => {
  const translate = useTranslate();

  const { values, handleSubmit, handleChange } = useFormikContext() as any;

  const isEmpty = values?.name?.length < 1;

  const [isEditMode, setIsEditMode] = React.useState(isEmpty);

  const toggleEdit = () => {
    setIsEditMode(!isEditMode);
    handleSubmit();
  };

  return isEditMode ? (
    <>
      <div>{translate(messages['vip.tier-name'])}</div>
      <Input
        size="small"
        style={{ width: '80%' }}
        onPressEnter={toggleEdit}
        onBlur={toggleEdit}
        name="name"
        onChange={handleChange}
        value={values.name}
      />
    </>
  ) : (
    <ALink
      style={{
        color: '#000',
      }}
      onClick={() => {
        setIsEditMode(true);
      }}
    >
      <div>{translate(messages['vip.tier-name'])}</div>
      <strong>{values.name}</strong>
    </ALink>
  );
};

export default NameInput;
