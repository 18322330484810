import React, { useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { IDateRangeValue } from 'interfaces/dateValue.interface';
import { Page } from 'interfaces/user.interface';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { OperatorFilter } from './components/OperatorFilter/OperatorFilter';
import { ISystemMessageFilter } from '../../interfaces';
import TitleFilter from './components/TitleFilter';
import { TargetMembersFilter } from './components/TargetMembersFilter/TargetMembersFilter';
import { ExcludedMembersFilter } from './components/ExcludedMembersFilter/ExcludedMembersFilter';
import { multiMembersSelectFilterUtil } from './utils';
import { ScheduleDateFilter } from './components/ScheduleDateFilter/ScheduleDateFilter';

interface ISystemMessageSidebar {
  handleFilters: (e: ISystemMessageFilter) => any;
  page?: Page<ISystemMessageFilter>;
  setFilterForMultMember: (values: object) => void;
}

const SystemMessageSidebar = ({
  handleFilters,
  page,
  setFilterForMultMember,
}: ISystemMessageSidebar) => {
  const { filter } = page!;
  const setFilters = (e: any) => {
    handleFilters({
      ...filter,
      [e.name]: e.value,
    });
  };

  useEffect(() => {
    if (!isEmpty(page!.onTagClose)) {
      setFilters({ name: page!.onTagClose, value: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page!.onTagClose]);

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST: ALLOWED_OPERATOR_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_ACCOUNT_MANAGEMENT
  );

  return (
    <>
      <TitleFilter
        values={get(page, 'filter.title.in', [])}
        setFilters={(e) => {
          setFilters({ name: 'title', value: e });
        }}
      />

      {ALLOWED_OPERATOR_LIST && (
        <OperatorFilter
          values={get(page, 'filter.creator.in', [])}
          setFilters={(e) => {
            setFilters({ name: 'creator', value: e });
          }}
        />
      )}

      {/* <MemberAccountFilter */}
      {/*  values={filter!.memberAccount} */}
      {/*  setFilters={e => setFilters({ name: 'memberAccount', value: e })} */}
      {/* /> */}

      <ScheduleDateFilter
        values={filter!.dateTimeSubmitted}
        setFilters={(e: IDateRangeValue) =>
          setFilters({ name: 'dateTimeSubmitted', value: e })
        }
      />

      <TargetMembersFilter
        values={{
          members: filter!.targetMembers || [],
          memberLoyaltyLevels: filter!.targetMemberLoyaltyLevels || [],
          memberLevels: filter!.targetMemberLevels || [],
        }}
        setFilters={(e) => {
          multiMembersSelectFilterUtil({
            values: e,
            type: 'target',
            setFilterForMultMember,
          });
        }}
      />

      <ExcludedMembersFilter
        values={{
          members: filter!.excludedMembers || [],
          memberLoyaltyLevels: filter!.excludedMemberLoyaltyLevels || [],
          memberLevels: filter!.excludedMemberLevels || [],
        }}
        setFilters={(e) =>
          multiMembersSelectFilterUtil({
            values: e,
            type: 'excluded',
            setFilterForMultMember,
          })
        }
      />
    </>
  );
};

export default SystemMessageSidebar;
