import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

interface Props {
  status: string;
}

// eslint-disable-next-line arrow-body-style
export const Status: React.FC<Props> = ({ status }) => {
  return (
    <Container>
      <Circle status={status} />
      {status === 'ACTIVE' ? (
        <FormattedMessage id="active.text" defaultMessage="Active" />
      ) : (
        <FormattedMessage id="maintenance.text" defaultMessage="Maintenance" />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.div<{ status: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
  background: ${({ status }) =>
    status === 'ACTIVE' ? 'rgb(0, 200, 0)' : 'rgb(235, 0, 0)'};
`;
