import { useMutation } from '@apollo/react-hooks';
import { Button, message, Popconfirm } from 'antd';
import { PureQueryOptions } from 'apollo-client';
import { APPROVE_DEPOSIT } from 'graphql/mutations/deposit.mutation';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

const ApproveDeposit = (props: {
  id: string;
  refetchQueries: (string | PureQueryOptions)[] | undefined;
  setRemarksError: Function;
  toggleModal: Function;
}) => {
  const translate = useTranslate();
  const { id, refetchQueries, setRemarksError, toggleModal } = props;

  const messages = defineMessages({
    approveDepositProcess: {
      id: 'deposits.deposit-request-approved.text',
      defaultMessage: 'Deposit request approved',
    },
  });

  const [approveDeposit, { loading }] = useMutation(APPROVE_DEPOSIT, {
    onCompleted: (data) => {
      if (data.approveDeposit) {
        message.success(translate(messages.approveDepositProcess));
      }
      setRemarksError();
      toggleModal();
    },
    onError: () => {
      setRemarksError();
    },
    variables: { id },
    refetchQueries,
  }) as any;

  return (
    <>
      <Popconfirm
        title={
          <FormattedMessage
            id="are-you-sure?.text"
            defaultMessage="Are you sure?"
          />
        }
        onConfirm={approveDeposit}
        onCancel={() => {}}
        okText={<FormattedMessage id="yes.text" defaultMessage="Yes" />}
        cancelText={<FormattedMessage id="no.text" defaultMessage="No" />}
      >
        <Button
          disabled={loading}
          loading={loading}
          style={{ color: '#52C41A', borderColor: '#52C41A' }}
        >
          <FormattedMessage id="approve.text" defaultMessage="Approve" />
        </Button>
      </Popconfirm>
    </>
  );
};

export default ApproveDeposit;
