import React from 'react';
import styled from 'styled-components';
import { Filters } from './Filters/Filters';
import { Table } from './Table/Table';
import { useAccountManagementValue } from '../context';

export const AMBody: React.FC = () => {
  const { collapsed } = useAccountManagementValue();
  return (
    <Container>
      {!collapsed && <Filters />}
      <Table />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: calc(100% - 64px);
`;
