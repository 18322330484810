import styled from 'styled-components';
import { Button } from 'antd';

export const StyledCenter = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCenter2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  width: 150px;
`;

export default {};
