import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'agent-affiliate.affiliate-cost-sharing-settings.text': {
    id: 'agent-affiliate.affiliate-cost-sharing-settings.text',
    defaultMessage: 'Affiliate Cost Sharing Settings',
  },
  'settlement-period.text': {
    id: 'settlement-period.text',
    defaultMessage: 'Settlement Period',
  },
  'automatic-payout.text': {
    id: 'automatic-payout.text',
    defaultMessage: 'Automatic Payout',
  },
  'manually-payout.text': {
    id: 'manually-payout.text',
    defaultMessage: 'Manually Payout',
  },
  'tier-requirement.text': {
    id: 'tier-requirement.text',
    defaultMessage: 'Tier Requirement',
  },
  'cost-vendor-settings.text': {
    id: 'cost-vendor-settings.text',
    defaultMessage: 'Cost Vendor Settings',
  },
});

export default messages;
