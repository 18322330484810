import styled from 'styled-components';
import { Modal, Layout } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .menu-header {
    background: #e7e9ed;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
  .ant-legacy-form-horizontal {
    .ant-legacy-form-item-label {
      line-height: 40px !important;
    }
  }
`;

export const StyledSider = styled(Layout.Sider)`
  border-right: 1px solid #e8e8e8;
`;
export default {};
