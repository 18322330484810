import React from 'react';
import { DATE_FORMAT } from 'constants/date';
import { StyledRangePicker } from './styles';

const DateRangeFilter = () => (
  <div className="my-5">
    <div className="mb-1">Negative Carry</div>
    <StyledRangePicker
      className="mb-2"
      placeholder={['From', 'To']}
      format={DATE_FORMAT}
    />
  </div>
);

export default DateRangeFilter;
