import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member.label-management.number.text': {
    id: 'member.label-management.number.text',
    defaultMessage: 'No.',
  },
  'member.label-management.label-name.text': {
    id: 'member.label-management.label-name.text',
    defaultMessage: 'Label name',
  },
  'member.label-management.number-of-members.text': {
    id: 'member.label-management.number-of-members.text',
    defaultMessage: 'Number of people',
  },
});

export default messages;
