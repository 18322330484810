import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin, Layout, List, Button } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { isEmpty } from 'lodash';
// import removeTypename from 'utils/removeTypename';
import messages from 'pages/components/WithdrawalMethods/messages';
import useTranslate from 'utils/useTranslate';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { WITHDRAWAL_METHOD } from './queries';
import { StyledModal, StyledSider } from './styles';
import AccountInformation from './components/AccountInformation';
import OtherSettings from './components/OtherSettings';

const { Content } = Layout;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const WithdrawalMethodDetails = ({
  toggleDetails,
  toggleEdit,
  id,
}: {
  id: string;
  toggleDetails: () => void;
  toggleEdit: (e: Record<string, any>) => void;
}) => {
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const { data = {}, loading } = useQuery(WITHDRAWAL_METHOD, {
    variables: { id },
    fetchPolicy: 'network-only',
    context,
  });

  const { withdrawalMethod = {} } = data as Record<string, any>;
  return (
    <>
      <StyledModal
        title={withdrawalMethod.name}
        visible
        width={800}
        onCancel={() => toggleDetails()}
        footer={
          !loading &&
          !isEmpty(withdrawalMethod) && [
            <Button onClick={() => toggleEdit(withdrawalMethod)} key="edit">
              {translate(messages.edit)}
            </Button>,
            <Button type="primary" onClick={() => toggleDetails()} key="ok">
              {translate(messages.ok)}
            </Button>,
          ]
        }
      >
        {loading && (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 200 }}
          >
            <Spin />
          </div>
        )}
        {!loading && (
          <Layout style={{ height: 500 }}>
            <StyledSider theme="light" className="px-4 pt-2">
              <List>
                <List.Item className="bb-0 text-right">
                  <a href="#account-information">
                    {translate(messages.accountinfo)}
                  </a>
                </List.Item>
                <List.Item className="bb-0 text-right">
                  <a href="#other-settings">{translate(messages.others)}</a>
                </List.Item>
              </List>
            </StyledSider>
            <Layout style={{ backgroundColor: '#fff' }}>
              <Content className="p-4">
                <Form colon={false}>
                  <AccountInformation
                    formItemLayout={formItemLayout}
                    withdrawalMethod={withdrawalMethod}
                  />
                  <OtherSettings
                    formItemLayout={formItemLayout}
                    withdrawalMethod={withdrawalMethod}
                  />
                </Form>
              </Content>
            </Layout>
          </Layout>
        )}
      </StyledModal>
    </>
  );
};

export default WithdrawalMethodDetails;
