import styled from 'styled-components';
import { Select } from 'antd';
import get from 'lodash/get';
import { multiSelectType } from './util';
import { memberLevelColors } from '../../../../../../utils/memberLevelColors';

export const findColorByBgColor = (bgColor: any) =>
  memberLevelColors.find(
    (memberLevelColor) => memberLevelColor.bgColor === bgColor
  );

const itemStyling = (items: any) => {
  const style = items.reduce((acc: string, cur: any) => {
    let build = acc;

    // eslint-disable-next-line no-underscore-dangle
    switch (cur.__typename) {
      case 'MemberLevel':
        build += `
          &[title='${cur.id}'] {
            background-color: ${get(
              findColorByBgColor(cur.color),
              'bgColor',
              '#efefef'
            )} !important;
  
            border-color: ${get(
              findColorByBgColor(cur.color),
              'borderColor',
              '#efefef'
            )} !important;
  
            border-radius: 100px;
            color: black;
          }
        `;
        break;
      case 'MemberLoyaltyLevel':
        build += `
          &[title='${cur.id}'] {
            background-color: ${cur.color} !important;
            border-color: ${cur.color} !important;
            border-radius: 11px !important;
            color: white !important;

            .anticon-close {
              color: #fff !important;
            }
          }
        `;
        break;
      default:
    }

    return build;
  }, '');

  return style;
};

export const StyledMultiSelect = styled(Select)<any>`
  width: 100%;

  ${({ type }) =>
    type === multiSelectType.SIDEBAR &&
    `
    .ant-select-selection {
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }
  `}

  .ant-select-selection li.ant-select-selection__choice {
    ${({ activeItems }) => (activeItems ? itemStyling(activeItems) : '')}
  }
`;
