import gql from 'graphql-tag';
import { defineMessages } from 'react-intl';

export const actions = {
  SET_NEW_ACCOUNT: 'SET_NEW_ACCOUNT',
  SET_PUBLISHING: 'SET_PUBLISHING',
  SET_NEW_ACCOUNT_REFRESH: 'SET_NEW_ACCOUNT_REFRESH',
  SET_VENDOR_GROUPS: 'SET_VENDOR_GROUPS',
  SET_VALUES_TO_SUBMIT: 'SET_VALUES_TO_SUBMIT',
  SET_BOOL_EXISTING_VALUE: 'SET_BOOL_EXISTING_VALUE',
  SET_SUBMIT_PROGRESS: 'SET_SUBMIT_PROGRESS',
};

interface State {
  newAccountState: boolean;
  publishingState: boolean;
  newAccountRefresh: boolean;
  vendorGroups: Array<{ id: string; name: string }>;
  valuesToSubmit: Record<string, any>;
  boolExistingValue: boolean;
  submitProgress: boolean;
}

export const initState: State = {
  newAccountState: true,
  publishingState: false,
  newAccountRefresh: true,
  vendorGroups: [],
  valuesToSubmit: {},
  boolExistingValue: false,
  submitProgress: false,
};

export const reducer = (state: State, [type, payload]: [string, any]): any => {
  switch (type) {
    case actions.SET_NEW_ACCOUNT:
      return {
        ...state,
        newAccountState: payload,
      };
    case actions.SET_PUBLISHING:
      return {
        ...state,
        publishingState: payload,
      };

    case actions.SET_NEW_ACCOUNT_REFRESH:
      return {
        ...state,
        newAccountRefresh: payload,
      };
    case actions.SET_VENDOR_GROUPS:
      return {
        ...state,
        vendorGroups: payload,
      };
    case actions.SET_VALUES_TO_SUBMIT:
      return {
        ...state,
        valuesToSubmit: payload,
      };
    case actions.SET_BOOL_EXISTING_VALUE:
      return {
        ...state,
        boolExistingValue: payload,
      };
    case actions.SET_SUBMIT_PROGRESS:
      return {
        ...state,
        submitProgress: payload,
      };
    default:
      return state;
  }
};

export interface INewClientDrawerContainer {
  drawerState: boolean;
  closeDrawer: () => void;
  existingValues?: Record<string, any>;
  refetchFunction: () => void;
}

export const messagesLocal = defineMessages({
  'create-new-client-account.text': {
    id: 'create-new-client-account.text',
    defaultMessage: 'Create New Client Account',
  },
  'edit-client-account.text': {
    id: 'edit-client-account.text',
    defaultMessage: 'Edit Client Account',
  },
});
export const VENDOR_GROUPS = gql`
  query VendorGroups($filter: VendorGroupsFilterInput!) {
    vendorGroups(filter: $filter) {
      edges {
        node {
          id
          name
          status
        }
      }
    }
  }
`;
