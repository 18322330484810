import React from 'react';
import { Select } from 'antd';
import getSortedTimezones from 'utils/getSortedTimezones';
import coercedGet from 'utils/coercedGet';

const { Option } = Select;

type Props = {
  onChange: (e: Array<string> | string) => void;
  value: Array<string> | string;
  placeHolder?: string;
  disabled?: boolean;
  multiple?: boolean;
};

const TimezoneFilter: React.FC<Props> = ({
  value,
  onChange,
  placeHolder,
  disabled,
  multiple,
}) => {
  const handleChange = (e: string | Array<string>) => {
    onChange(e);
  };

  const renderOptions = () =>
    getSortedTimezones(true).map((zone: any) => (
      <Option value={zone.name} key={zone.id}>
        {zone.label}
      </Option>
    ));

  return (
    <Select
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
      optionFilterProp="label"
      filterOption={(input, option) => {
        const target = coercedGet(option as {}, 'props.children', []);
        if (target.length) {
          return target.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }
        return false;
      }}
    >
      {renderOptions()}
    </Select>
  );
};

export default TimezoneFilter;
