import React from 'react';
import styled from 'styled-components';
import ColumnsFilterNew from 'SuperAdminMain/shared/components/ColumnsFilterSA';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import { NewAccount } from './NewAccount/NewAccount';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const SideRight: React.FC<Props> = () => {
  return (
    <Container>
      <ColumnsFilterNew
        className="mr-3"
        tabId={TabId.VENDOR_GROUP_MANAGEMENT}
      />
      <NewAccount />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  .view-column-icon {
    margin-right: 4px;
  }

  .custom-columns {
    margin-right: 14px;
  }
`;
