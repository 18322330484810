import gql from 'graphql-tag';

export const WITHDRAWAL_REQUESTS = gql`
  query Withdrawals(
    $first: Int
    $after: Binary
    $filter: WithdrawalsFilterInput
  ) {
    withdrawals(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          amount
          account {
            id
            platformId
            brandId
            verified
            email
            initialEmail
            username
            currency
            memberLoyaltyLevels {
              name
              description
              color
              programme {
                id
                name
              }
            }
            memberLevel {
              id
              name
              default
              color
              status
              membersCount
            }
          }
          processor {
            id
            username
          }
          withdrawalMethod {
            ... on HexoPayWithdrawalMethod {
              id
              name
            }
            ... on NetellerWithdrawalMethod {
              id
              name
            }
            ... on SkrillWithdrawalMethod {
              id
              name
            }
            ... on ManualAdjustmentWithdrawalMethod {
              id
              name
            }
            ... on CryptocurrencyWithdrawalMethod {
              id
              name
            }
          }
          hexoPayCreditCard {
            lastFourDigits
            expirationMonth
            expirationYear
            firstSixDigits
            firstDigit
            brand
          }
          processingTime
          hexoPayUid
          serialCode
          manualAdjustment
          remarks
          externalRemarks
          firstWithdrawal
          dateTimeCreated
          status
          externalStatus
          oneTimeTurnoverDetectionCheck
          promoHunterCheck
          contraBetCheck
          ipCollisionOffenderCheck
          martingaleBetOffenderCheck
          manualBankTransfer
          transactionReference
          amount
          amountInBaseCurrency
          currency
          exchangeRate
          transactionReference
          transactionDetails
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
export const WITHDRAWAL_REQUESTS_CSV_DATA = gql`
  query Withdrawals(
    $first: Int
    $after: Binary
    $filter: WithdrawalsFilterInput
  ) {
    withdrawals(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          serialCode
          status
          amount
          hexoPayUid
          hexoPayCreditCard {
            lastFourDigits
            expirationMonth
            expirationYear
            firstSixDigits
            firstDigit
            brand
          }
          bankAccount {
            id
            bank
          }
          account {
            id
            username
            platformId
            email
            brandId
            initialEmail
            tags {
              id
              name
              color
            }
            notes {
              edges {
                node {
                  id
                  note
                  dateTimeUpdated
                }
              }
            }
            memberLevel {
              id
              name
              color
            }
            memberLoyaltyLevels {
              id
              name
              color
              programme {
                id
                name
              }
            }
            affiliate {
              id
              code
            }
          }
          withdrawalMethod {
            ... on OfflineBankTransferWithdrawalMethod {
              id
              name
              transactionFee
              transactionFeeType
            }

            ... on AlipayWithdrawalMethod {
              id
              name
              transactionFee
              transactionFeeType
            }

            ... on WechatWithdrawalMethod {
              id
              name
              transactionFee
              transactionFeeType
            }
            ... on ManualAdjustmentWithdrawalMethod {
              id
            }
            ... on OnlineBankTransferWithdrawalMethod {
              id
            }
            ... on OnlineWechatWithdrawalMethod {
              id
            }
            ... on OnlineAlipayWithdrawalMethod {
              id
            }
            ... on HexoPayWithdrawalMethod {
              id
              name
            }
            ... on NetellerWithdrawalMethod {
              id
              name
            }
            ... on SkrillWithdrawalMethod {
              id
              name
            }
            ... on ManualAdjustmentWithdrawalMethod {
              id
              name
            }
          }
          remarks
          externalRemarks
          firstWithdrawal
          dateTimeCreated
          processingTime
          dateTimeProcessed
          handlingFee
          withdrawalTaxFee
          promoDeductionFee
          withdrawalLimitFee
          handlingFeeApplied
          withdrawalTaxFeeApplied
          promoDeductionFeeApplied
          withdrawalLimitFeeApplied
          oneTimeTurnoverDetectionCheck
          promoHunterCheck
          contraBetCheck
          ipCollisionOffenderCheck
          martingaleBetOffenderCheck
          transactionReference
          transactionDetails
        }
      }
    }
  }
`;

export const WITHDRAWAL = gql`
  query Withdrawal($id: ID!) {
    withdrawal(id: $id) {
      id
      serialCode
      bankAccount {
        id
        bank
        accountName
        accountNumber
        province
        city
        branch
      }
      amount
      account {
        id
        username
        realName
        mobileNumber
        initialEmail
        memberLevel {
          id
          name
          color
        }
        memberLoyaltyLevels {
          id
          name
          color
          programme {
            id
            name
          }
        }
        tags {
          id
          name
          color
        }
        notes {
          edges {
            node {
              id
              header
              note
            }
          }
        }
      }
      status
      externalStatus
      withdrawalMethod {
        id
        name
        transactionFee
        transactionFeeType
      }
      processor {
        id
        username
      }
      hexoPayUid
      hexoPayCreditCard {
        firstDigit
        firstSixDigits
        lastFourDigits
        expirationMonth
        expirationYear
      }
      manualAdjustment
      remarks
      firstWithdrawal
      dateTimeCreated
      dateTimeProcessed
      handlingFee
      withdrawalTaxFee
      promoDeductionFee
      withdrawalLimitFee
      handlingFeeApplied
      withdrawalTaxFeeApplied
      promoDeductionFeeApplied
      withdrawalLimitFeeApplied
      oneTimeTurnoverDetectionCheck
      promoHunterCheck
      contraBetCheck
      ipCollisionOffenderCheck
      martingaleBetOffenderCheck
      transactionReference
      transactionDetails
      currency
      isCrypto
    }
  }
`;

export const MEMBER_NOTES = gql`
  query MemberNotes(
    $first: Int
    $after: Binary
    $filter: MemberNotesFilterInput
  ) {
    memberNotes(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          header
          note
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

export const TOTAL_WITHDRAWALS = gql`
  query MemberWithdrawals(
    $first: Int
    $after: Binary
    $filter: WithdrawalsFilterInput
  ) {
    withdrawals(first: $first, after: $after, filter: $filter) {
      totalCount
      totalWithdrawalApproved
      totalWithdrawalRequested
      totalWithdrawalProcessed
    }
  }
`;

export const GET_WITHDRAWAL_SERIAL_CODES = gql`
  query FilterWithdrawalSerialCodes(
    $first: Int
    $after: Binary
    $filter: WithdrawalsFilterInput!
  ) {
    withdrawals(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          serialCode
        }
      }
    }
  }
`;

export const GET_WITHDRAWAL_BRAND_IDS = gql`
  query FilterWithdrawalBrandIds(
    $first: Int
    $after: Binary
    $filter: WithdrawalsFilterInput!
  ) {
    withdrawals(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          account {
            id
            brandId
          }
        }
      }
    }
  }
`;

export const GET_WITHDRAWAL_PLATFORM_IDS = gql`
  query FilterWithdrawalBrandIds(
    $first: Int
    $after: Binary
    $filter: WithdrawalsFilterInput!
  ) {
    withdrawals(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          account {
            id
            platformId
          }
        }
      }
    }
  }
`;

export const GET_WITHDRAWAL_ACCOUNTS = gql`
  query FilterWithdrawalAccounts($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export default {};
