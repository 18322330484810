import styled from 'styled-components';
import { Input } from 'antd';

export const StyledInput = styled(Input)`
  border: 0 !important;
  border-bottom: 1px solid #d9d9d9 !important;
  padding-left: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
`;

export default {};
