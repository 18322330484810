const balanceTransactionTypes = [
  { value: 'WITHDRAWAL_FEE', label: 'Withdrawal fee' },
  { value: 'WITHDRAWAL_CLAWBACK_FEE', label: 'Withdrawal Clawback Fee' },
  { value: 'WITHDRAWAL', label: 'Withdrawal' },
  {
    value: 'WITHDRAWAL_REQUEST_REJECTED',
    label: 'Withdrawal Request Rejected',
  },
  { value: 'DEPOSIT', label: 'Deposit' },
  { value: 'PROMO_PAYOUT', label: 'Promo Payout' },
  { value: 'REBATE_PAYOUT', label: 'Rebate Payout' },
  { value: 'AFFILIATE_COMMISSION', label: 'Affiliate Commission' },
  { value: 'MEMBER_LOYALTY_PAYOUT', label: 'Member Loyalty Payout' },
  { value: 'VENDOR_TRANSFER', label: 'Vendor transfer' },
  { value: 'DEBIT_VENDOR_TRANSFER', label: 'Debit transfer' },
  { value: 'CREDIT_VENDOR_TRANSFER', label: 'Credit transfer' },
  { value: 'REFUND_CREDIT_VENDOR_TRANSFER', label: 'Refund credit transfer' },
  { value: 'BET', label: 'Bet' },
  { value: 'REFUND_BET', label: 'Refund Bet' },
  { value: 'BALANCE_ADJUSTMENT', label: 'Balance adjustment' },
  { value: 'PAYOUT', label: 'Payout' },
  { value: 'REBATE_COMMISSION', label: 'Rebate commission' },
  { value: 'SYSTEM', label: 'System' },
  {
    value: 'EXTERNAL_PROGRAMME_MANUAL_ADJUSTMENT',
    label: 'External Programme',
  },
  {
    value: 'PROMO_DEPOSIT_BONUS',
    label: 'Promo Deposit Bonus',
  },
  {
    value: 'PROMO_FUNDS_TRANSFER',
    label: 'Promo Funds Transfer',
  },
  {
    value: 'PROMO_BONUS_WON',
    label: 'Promo Bonus Won',
  },
  {
    value: 'PROMO_BONUS_LOST',
    label: 'Promo Bonus Lost',
  },
  {
    value: 'PROMO_BONUS_EXPIRED',
    label: 'Promo Bonus Expired',
  },
  {
    value: 'PROMO_BONUS_CANCELED',
    label: 'Promo Bonus Canceled',
  },
  {
    value: 'PROMO_WON',
    label: 'Promo Won',
  },
  {
    value: 'PROMO_LOST',
    label: 'Promo Lost',
  },
  {
    value: 'PROMO_CANCELED',
    label: 'Promo Canceled',
  },
  {
    value: 'PROMO_EXPIRED',
    label: 'Promo Expired',
  },
  {
    value: 'PROMO_REFUND',
    label: 'Promo Refund',
  },
];

export default balanceTransactionTypes;
