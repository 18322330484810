export const truncateProgramName = (programme: string) => {
  if (!programme) {
    return { text: null, isHoverable: false };
  }

  const isHoverable = programme.length > 6;

  let text = programme;

  if (isHoverable) {
    text = text
      .split('')
      .slice(0, 6)
      .join('');

    text += '...';
  }

  return { text, isHoverable };
};
