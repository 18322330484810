import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Tag } from 'antd';
import { OPERATOR } from './queries';

const ProcessorTag = (props: { id: string; onClose: (e: any) => void }) => {
  const { id, onClose } = props;

  const operatorQuery = useQuery(OPERATOR, {
    variables: {
      id,
    },
  });

  const { operator = {} } = operatorQuery.data || {};

  return (
    <Tag closable onClose={(e) => onClose(e)}>
      {operator && operator.username}
    </Tag>
  );
};

export default ProcessorTag;
