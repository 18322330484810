import styled from 'styled-components';
import { Layout, Select, Input } from 'antd';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  .ant-layout-sider-children {
    overflow: hidden;
  }
`;
export const StyledSelect: any = styled(Select)`
  width: 100% !important;
  .ant-select-selection {
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }
`;
export const StyledInput = styled(Input)`
  .ant-input {
    border: 0 !important;
    border-bottom: 1px solid #d9d9d9 !important;
    border-radius: 0 !important;
  }
`;
