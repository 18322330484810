import React, { FC } from 'react';
import { Typography, Tooltip } from 'antd';
import { ALink } from 'components/ALink/ALink';

const { Paragraph } = Typography;

interface Props {
  text: string | number;
  isLink?: boolean;
}

const TruncateWithTooltip: FC<Props> = ({ text, isLink = false, children }) => (
  <Tooltip title={text || undefined}>
    {isLink ? (
      <Paragraph className="mb-0" ellipsis>
        <ALink href="#">{text}</ALink>
      </Paragraph>
    ) : (
      <Paragraph className="mb-0" ellipsis>
        {children}
        {text || '-'}
      </Paragraph>
    )}
  </Tooltip>
);

export default TruncateWithTooltip;
