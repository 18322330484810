import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { Locale } from 'constants/locale';
import coercedGet from 'utils/coercedGet';
import {
  VendorsConnectionEdge,
  Vendor as VendorType,
} from 'types/graphqlTypes';
import { VENDORS } from './queries';
import { StyledLabel, StyledSpan, StyledSelect } from '../styles';

const { Option } = Select;

type TipsMinMaxType = {
  setState: (value: any) => void;
  state: { [key: string]: any };
};

const Vendor = ({ state, setState }: TipsMinMaxType) => {
  const { data } = useQuery(VENDORS, {
    // variables: { first: 10 },
  });

  const intl = useIntl();

  const edges = get(data, 'vendors.edges', []);

  const options = edges.map((edge: VendorsConnectionEdge) => edge.node);

  const handleChange = (e: any) => {
    if (e.length) {
      setState((prev: Record<string, any>) => ({
        ...prev,
        vendor: { in: e },
      }));
    } else {
      setState((prev: Record<string, any>) => ({
        ...prev,
        vendor: null,
      }));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.vendor.text"
              defaultMessage="Vendor"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        data-testid="select"
        allowClear
        placeholder={
          <FormattedMessage id="reports.select.text" defaultMessage="Select" />
        }
        mode="multiple"
        style={{ width: '100%' }}
        value={state.vendor ? state.vendor.in : []}
        optionFilterProp="label"
        onChange={handleChange}
      >
        {options.map((option: VendorType) => {
          const defaultName = coercedGet(option, 'name', 'NO_VENDOR_NAME');
          const zhName = coercedGet(option, 'zhName', defaultName);
          const enName = coercedGet(option, 'enName', defaultName);
          const isEnglish = intl.locale === Locale.EN;
          return (
            <Option key={option.id} value={option.id} label={option.name}>
              {isEnglish ? enName : zhName}
            </Option>
          );
        })}
      </StyledSelect>
    </>
  );
};

export default Vendor;
