import styled from 'styled-components';

export const DrawerContainer = styled.div`
  width: 90%;
`;

export const SideLabel = styled.div`
  text-align: right;
`;

export const TitleContainer = styled.div`
  padding: 10px 20px 10px 140px;
`;

export const StyledSpacer = styled.div`
  height: 30px;
  width: auto;
`;
