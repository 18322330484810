import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography, Divider, Button, Radio, message } from 'antd';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { kebabCase } from 'lodash';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';

import getRequiredFieldsFromSchema from 'utils/getRequiredFieldsFromSchema';

import {
  SUBMIT_REBATE_GROUP,
  UPDATE_REBATE_GROUP,
} from 'pages/components/Rebates/mutation';
import { FormikProps } from 'interfaces/formikProps.interface';
import { RebateProps } from 'interfaces/formikValues/rebates.interface';
import { DynamicObj } from 'interfaces/user.interface';

import { formSchemas, CreateRebateSteps } from '../../constants';
import messages from './messages';
import {
  useRebateGroupState,
  RebateGroupStatus,
  RebateGroupTypes,
} from '../../../../context';

function RebatePublish({
  activeStep,
  setActiveStep,
  formikProps,
  isSubmitting,
  formValues,
  ...otherProps
}: {
  activeStep: number;
  setActiveStep: (e: number) => void;
  formikProps: FormikProps<RebateProps>;
  isSubmitting: boolean;
  formValues: DynamicObj;
  refetch?: () => void;
  formRef?: {
    current?: {
      resetForm?: () => void;
    };
  };
  hideDrawer?: () => void;
}) {
  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  const [rebateGroupState, rebateGroupDispatch] = useRebateGroupState() as any;
  const rebateStatus = coercedGet(
    rebateGroupState,
    'activeRebateGroupData.status',
    ''
  );
  const [submitRebateGroup, { loading }] = useMutation(SUBMIT_REBATE_GROUP);
  const [updateRebateGroup, { loading: updateLoading }] = useMutation(
    UPDATE_REBATE_GROUP
  );

  const translate = useTranslate();

  return (
    <Root>
      <Typography.Title>
        <FormattedMessage
          id="rebates.ready-to-publish-title.text"
          defaultMessage="You’re ready to publish!"
        />
      </Typography.Title>
      <Typography.Title level={4}>
        <FormattedMessage
          id="rebates.ready-to-publish-desc.text"
          defaultMessage="If you’d like to update your Rebate setting, you can easily do all that after you hit publish."
        />
      </Typography.Title>
      {Object.entries(CreateRebateSteps).map((step, idx) => {
        const requiredFields = getRequiredFieldsFromSchema(
          formSchemas(translate)[idx]
        );
        const hasErrors = requiredFields.filter((field) =>
          Object.keys(formikProps.errors).includes(field)
        ).length;
        return (
          <React.Fragment key={idx}>
            <Typography.Title level={3} className="m-0 mb-1">
              <FormattedMessage id="rebates.step.text" defaultMessage="Step" />
              <span>{idx + 1}</span>
            </Typography.Title>
            <Typography.Title level={3} className="m-0 mb-1" type="secondary">
              {translate(
                messages[`common.${kebabCase(step[1].toLowerCase())}.text`]
              )}
              <div className={`circle ${hasErrors ? 'danger' : 'success'}`}>
                <LegacyIcon
                  type={`${!hasErrors ? 'check' : 'close'}`}
                  style={{ marginLeft: 8, marginTop: 8, color: 'white' }}
                />
              </div>
            </Typography.Title>
            <Typography.Title level={3} className="m-0 mb-1">
              <Button
                type="link"
                onClick={() => setActiveStep(idx)}
                className="p-0"
                style={{ fontSize: 16, fontWeight: 'bold' }}
              >
                <FormattedMessage id="common.edit.text" defaultMessage="Edit" />
              </Button>
            </Typography.Title>
            <Divider />
          </React.Fragment>
        );
      })}
      <Typography.Title level={3} className="m-0 mb-1">
        <FormattedMessage id="rebates.status.text" defaultMessage="Status" />
      </Typography.Title>
      <Radio.Group
        className="d-block mb-4"
        onChange={(e) => formikProps.setFieldValue('status', e.target.value)}
        value={formikProps.values.status}
      >
        <Radio style={radioStyle} value={RebateGroupStatus.ACTIVE}>
          <FormattedMessage
            id="rebates.publish.text"
            defaultMessage="Publish"
          />
        </Radio>
        <Radio style={radioStyle} value={RebateGroupStatus.INACTIVE}>
          <FormattedMessage id="rebates.pause.text" defaultMessage="Pause" />
        </Radio>
        <Radio
          style={radioStyle}
          disabled={rebateStatus !== '' && rebateStatus !== 'DRAFT'}
          value={RebateGroupStatus.DRAFT}
        >
          <FormattedMessage id="rebates.draft.text" defaultMessage="Draft" />
        </Radio>
      </Radio.Group>
      <Button
        type="primary"
        size="large"
        disabled={loading || updateLoading || isSubmitting}
        onClick={async () => {
          try {
            const status = coercedGet(formikProps, 'values.status', '');
            await updateRebateGroup({
              variables: {
                id: rebateGroupState.activeRebateGroup,
                input: formValues,
              },
            });
            if (status !== RebateGroupStatus.DRAFT) {
              const res = await submitRebateGroup({
                variables: {
                  id: rebateGroupState.activeRebateGroup,
                },
              });
              if (res?.data?.submitRebateGroup) {
                const updateResponse = await updateRebateGroup({
                  variables: {
                    id: rebateGroupState.activeRebateGroup,
                    input: {
                      enabled: status === RebateGroupStatus.ACTIVE,
                    },
                  },
                });
                if (updateResponse?.data?.updateRebateGroup) {
                  /* 
                  In order for the published rebate to be active, it takes about 2-3 seconds waiting time
                
                  SCENARIO 1: I have tried applying `refetchQueries` on `updateRebateGroup` but returns an inactive rebate
                  SCENARIO 2: I have tried a single call of `refetch()` but even if the `updateRebateGroup` returns `true`, 
                  it still returns inactive rebate
                  SCENARIO 3: I have tried five (5) `refetch()` below SCENARIO 2. Still didn't work, but I had a clue. I looked on
                    network tab and saw that the rebate turns active at around 4th `await refetch()`call.

                  To minimize multiple server trips (like what was done at SCENARIO 3), I've decided to use a setTimeout instead 
                  ensuring that the returned rebate will be active (if the radio button was clicked as Publish, of course).
                */
                  setTimeout(() => {
                    if (otherProps?.refetch) {
                      otherProps.refetch();
                    }
                  }, 2500);
                }
              }
            }
          } finally {
            if (otherProps?.formRef?.current?.resetForm) {
              await otherProps.formRef.current.resetForm();
            }
            rebateGroupDispatch({
              type: RebateGroupTypes.RESET_ACTIVE_REBATE_GROUP,
            });

            if (otherProps?.hideDrawer) {
              otherProps.hideDrawer();
            }
            message.success(
              translate(messages['rebates.publish-rebate-group-success.text']),
              3
            );
          }
        }}
      >
        <FormattedMessage id="common.confirm.text" defaultMessage="Confirm" />
      </Button>
    </Root>
  );
}

const Root = styled.div`
  small {
    font-size: 10px;
  }
  .circle {
    height: 40px;
    float: right;
    width: 40px;
    border-radius: 50%;
    margin-top: -36px;
  }
  .success {
    background-color: #23c98c;
  }
  .danger {
    background-color: #ff5500;
  }
`;

export default RebatePublish;
