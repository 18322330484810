import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import NewMissionModal from '../NewMissionModal';

const RightNode = () => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <>
      <Button type="primary" onClick={() => setOpenModal((prev) => !prev)}>
        <PlusOutlined />
        <FormattedMessage id="NEW_MISSION" defaultMessage="New Mission" />
      </Button>

      <NewMissionModal
        toggleModal={() => setOpenModal((prev) => !prev)}
        visible={openModal}
      />
    </>
  );
};

export default RightNode;
