import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Button, Col, Input, message, Radio, Row, Select } from 'antd';
import { useFormik } from 'formik';
import { CREATE_SYSTEM_NOTIFICATION } from 'graphql/mutations/systemNotification.mutations';
import { GET_OPERATOR_USERNAMES } from 'graphql/queries/operators.query';
import { get } from 'lodash';
import globalMessages from 'messages';
import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { UseFormikErrorMessage } from 'components/UseFormikErrorMessage/UseFormikErrorMessage';
import { SharedStyledModal } from 'styles/SharedStyledModal';
import { OperatorsConnectionEdge } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import * as Yup from 'yup';

const { Option } = Select;

const messages = defineMessages({
  'new-notification-created.text': {
    id: 'new-notification-created.text',
    defaultMessage: 'New notification created',
  },
});

export const NewAlertsAndNotificationsModal = ({
  visible,
  closeModal,
  getSystemNotifications,
}: {
  visible: boolean;
  closeModal: () => void;
  getSystemNotifications: () => void;
}) => {
  const translate = useTranslate();

  const validationSchema = Yup.object({
    title: Yup.string().required(
      translate(globalMessages['title-field-required.text'])
    ),
    message: Yup.string().required(
      translate(globalMessages['message-field-required.text'])
    ),
    recipient: Yup.array().required(
      translate(globalMessages['recipient-field-required.text'])
    ),
  });

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [operators, setOperators] = useState([]);

  const [createSystemNotification] = useMutation(CREATE_SYSTEM_NOTIFICATION);

  const [getAllOperators] = useLazyQuery(GET_OPERATOR_USERNAMES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const operatorEdges = get(data, 'operators.edges', []);
      const operatorsData = operatorEdges.map(
        (x: OperatorsConnectionEdge) => x.node
      );
      setOperators(operatorsData);
    },
  });

  useEffect(() => {
    getAllOperators();
  }, [getAllOperators]);

  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      recipient: [],
      priority: 'HIGH',
    },
    validationSchema,
    onSubmit: async (values) => {
      await createSystemNotification({
        variables: {
          input: values,
        },
      });
      getSystemNotifications();
      message.success(translate(messages['new-notification-created.text']));
      closeModal();
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = formik;

  return (
    <SharedStyledModal
      width={800}
      title={
        <FormattedMessage
          id="new-alerts-and-notification.text"
          defaultMessage="New Alerts and Notification"
        />
      }
      visible={visible}
      onCancel={closeModal}
      footer={[
        <Button type="primary" key={1} onClick={handleSubmit as any}>
          <FormattedMessage id="send.text" defaultMessage="Send" />
        </Button>,
      ]}
    >
      <Form layout="horizontal" onSubmit={handleSubmit}>
        <div className="py-3 px-1">
          <Row>
            <Col span={12}>
              <Form.Item
                label={translate(globalMessages['title.text'])}
                {...formItemLayout}
              >
                <Input
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  name="title"
                />
                <UseFormikErrorMessage formik={formik} name="title" />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={translate(globalMessages['message.text'])}
              >
                <Input.TextArea
                  rows={5}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.message}
                  name="message"
                />
                <UseFormikErrorMessage formik={formik} name="message" />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={translate(globalMessages['recipient.text'])}
              >
                <Select
                  mode="multiple"
                  onChange={(val) => setFieldValue('recipient', val)}
                >
                  {operators.map(
                    (recipient: { id: string; username: string }) => (
                      <Option key={recipient.id} value={recipient.id}>
                        {recipient.username}
                      </Option>
                    )
                  )}
                </Select>
                <UseFormikErrorMessage formik={formik} name="recipient" />
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={translate(globalMessages['priority-alert.text'])}
              >
                <Radio.Group
                  value={values.priority}
                  onChange={({ target: { value } }) =>
                    setFieldValue('priority', value)
                  }
                >
                  <Radio.Button value="HIGH">
                    <FormattedMessage id="high.text" defaultMessage="High" />
                  </Radio.Button>
                  <Radio.Button value="MEDIUM">
                    <FormattedMessage
                      id="medium.text"
                      defaultMessage="Medium"
                    />
                  </Radio.Button>
                  <Radio.Button value="LOW">
                    <FormattedMessage id="low.text" defaultMessage="Low" />
                  </Radio.Button>
                </Radio.Group>
                <UseFormikErrorMessage formik={formik} name="priority" />
              </Form.Item>
            </Col>

            <Col span={12} />
          </Row>
        </div>
      </Form>
    </SharedStyledModal>
  );
};
