/* eslint-disable no-param-reassign */
import React from 'react';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { uniqBy } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import coercedGet from 'utils/coercedGet';
import ModifiedTag from '../ModifiedTag';

export const GAME_CATEGORIES = gql`
  query CategoriesByGameList(
    $first: Int
    $after: Binary
    $filter: GamesFilterInput
  ) {
    games(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          category
        }
      }
    }
  }
`;

type Props = {
  gameIds: Array<string> | null;
};

const GameCategoryTag: React.FC<Props> = ({ gameIds }): any => {
  const { filters, setPersistedFilters } = useFilterValues();

  const { data = {}, loading } = useQuery(GAME_CATEGORIES, {
    variables: {
      filter: {
        id: {
          in: gameIds,
        },
      },
    },
  });

  const edges = coercedGet(data, 'games.edges', []);

  const uniqCategoryGameList = uniqBy(
    edges.map(({ node }: any) => ({
      id: node.id,
      category: node.category,
    })),
    'category'
  );

  //   const currFilts = coercedGet(filters, 'game_category.in', []);

  const handleRemoveGamesByCategory = (category: string) => {
    const gameIdsByCategory = edges.reduce((acc: Array<string>, curr: any) => {
      const { node } = curr;
      // @ts-ignore
      if (node.category === category) return [...new Set([...acc, node.id])];
      return acc;
    }, []);

    const preFilteredGames = coercedGet(filters, 'game_category.in', []);
    const postFilteredGames = preFilteredGames.filter(
      (item: string) => !gameIdsByCategory.includes(item)
    );

    const newFilterArray = {
      in: postFilteredGames,
    };

    setPersistedFilters((prev: Record<string, any>) => ({
      ...prev,
      game_category: postFilteredGames.length ? newFilterArray : null,
    }));
  };

  return (
    !loading &&
    uniqCategoryGameList.map((item: any) => (
      <ModifiedTag
        key={item.id}
        closable
        onClose={() => handleRemoveGamesByCategory(item.category)}
      >
        {item.category}
      </ModifiedTag>
    ))
  );
};

export default GameCategoryTag;
