import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import { Spin } from 'antd';
import { VENDORS_NAMES } from '../../queries';

type Props = {
  vendors: Array<string>;
};

const VendorNames = (props: Props) => {
  const { vendors } = props;
  const { data: vendorData, loading } = useQuery(VENDORS_NAMES, {
    variables: {
      filter: {
        id: {
          in: vendors,
        },
      },
    },
  });
  const vendorEdges = get(vendorData, 'vendors.edges', []);
  const vendorNames = vendorEdges.map((edge: any) => edge.node.name);

  if (loading)
    return (
      <div className="styled-center">
        <Spin />
      </div>
    );

  return (
    <>
      {vendorNames.length
        ? vendorNames.map(
            (item: string, key: number) =>
              `${item}${key + 1 < vendorNames.length ? ', ' : ''}`
          )
        : '-'}
    </>
  );
};

export default VendorNames;
