import gql from 'graphql-tag';

export const MEMBER_LOYALTY_PROGRAMME = gql`
  query MemberLoyaltyProgramme($id: ID!) {
    memberLoyaltyProgramme: memberLoyaltyProgramme(id: $id) {
      id
      serialCode
      name
      description
      validityStartDate
      validityEndDate
      evaluationPeriod
      automaticMemberLoyaltyLevelUpgrade
      automaticMemberLoyaltyLevelDowngrade
      membersCount
      primary
      badgeSet
      status
      # dateTimeCreated
      # dateTimeUpdated

      levels {
        id
        name
        description
        inviteOnly
        color
        qualificationCriteria {
          type
          amount
          # level 1
          and {
            type
            amount
            gameTypes
            # level 2
            and {
              # level 3
              type
              amount
              gameTypes
            }
            or {
              type
              amount
              gameTypes
            }
          }
        }

        ## previously benefits

        maximumWithdrawalAmountPerRequest
        maximumWithdrawalRequest
        withdrawalLimitRequestReset
        withdrawalLimitFee
        withdrawalLimitFeeType
        bankAccountLimits
        eWalletLimits

        ## previously payout
        autoPayout
        upgradePayout {
          limit
          amount
          claimExpiryAmount
          claimExpiryDuration
          claimOffsetAmount
          claimOffsetDuration
          payoutExpiryAmount
          payoutExpiryDuration
          turnover
        }
        dailyPayout {
          limit
          amount
          claimExpiryAmount
          claimExpiryDuration
          claimOffsetAmount
          claimOffsetDuration
          payoutExpiryAmount
          payoutExpiryDuration
          turnover
          timeOfDay
        }
        weeklyPayout {
          limit
          amount
          claimExpiryAmount
          claimExpiryDuration
          claimOffsetAmount
          claimOffsetDuration
          payoutExpiryAmount
          payoutExpiryDuration
          turnover
          timeOfDay
          dayOfWeek
        }
        monthlyPayout {
          limit
          amount
          claimExpiryAmount
          claimExpiryDuration
          claimOffsetAmount
          claimOffsetDuration
          payoutExpiryAmount
          payoutExpiryDuration
          turnover
          timeOfDay
          dayOfMonth
        }
        annualPayout {
          limit
          amount
          claimExpiryAmount
          claimExpiryDuration
          claimOffsetAmount
          claimOffsetDuration
          payoutExpiryAmount
          payoutExpiryDuration
          turnover
          timeOfDay
        }
        birthdayPayout {
          limit
          amount
          claimExpiryAmount
          claimExpiryDuration
          claimOffsetAmount
          claimOffsetDuration
          payoutExpiryAmount
          payoutExpiryDuration
          turnover
          timeOfDay
        }
      }
    }
  }
`;

export default {};
