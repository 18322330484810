import * as yup from 'yup';

export default yup.object().shape({
  status: yup
    .string()
    .nullable()
    .required('required'),
  reason: yup.string().when('status', {
    is: (status) => status === 'CLOSED',
    then: yup.string().required('Reason is required.'),
  }),
});
