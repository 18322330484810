import React from 'react';
import { GET_USERNAMES } from 'graphql/queries/membersOnline.query';
import SelectFilterField from 'components/SelectFilterField';

type Props = {
  filters: Record<string, any>;
  handleChange: (value: any) => void;
};

const FilterItems = ({ filters, handleChange }: Props) => (
  <>
    <SelectFilterField
      testId="members-online-username-filter"
      label="Username"
      query={GET_USERNAMES}
      queryConnection="members"
      filterFieldName="username"
      filters={filters}
      onChange={(e) =>
        handleChange((prev: Record<string, string>) => ({
          ...prev,
          username: e?.length ? { in: e } : null,
        }))
      }
      additionalOptionsFilter={{
        online: {
          eq: true,
        },
      }}
    />
  </>
);

export default FilterItems;
