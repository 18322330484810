import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const StyledLayout = styled(Layout)`
  background: #fff !important;
  height: 100%;
`;

export const StyledContent = styled(Content)<IStyledContent>`
  background: ${({ bgColor }) => bgColor || '#fff'};

  .ant-tabs-nav {
    display: block !important;
    background: #f0f0f0 !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    height: 40px !important;
  }

  .ant-tabs-tab {
    color: rgba(0, 0, 0, 0.55);
    height: 40px !important;
    line-height: 40px !important;
  }

  .ant-tabs-nav-wrap {
    background: white;
    padding-top: 0 !important;
    margin-top: 2.4px !important;
  }

  .main-tab-member360 > [role='tablist'] > .ant-tabs-nav-wrap {
    background: none;
  }

  .main-tab-member360
    > [role='tablist']
    .ant-tabs-nav-list
    > .ant-tabs-tab.ant-tabs-tab-active {
    background: #1b48a0 !important;
    color: white !important;

    .d-flex.justify-content-between {
      color: white;
    }
  }

  .main-tab-member360 > [role='tablist'] .ant-tabs-nav-list > .ant-tabs-tab {
    background: none !important;
  }

  .ant-tabs.ant-tabs-top div.ant-tabs-bar div.ant-tabs-tab {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .ant-tabs-bar .ant-tabs-nav > div {
    color: white;
    display: grid !important;
    //grid-template-columns: repeat(auto-fit, minmax(50px, 1fr)) !important;
  }

  .ant-tabs-tabpane {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
`;

interface IStyledContent {
  bgColor?: string;
}

export const ContainerHeader = styled.div`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #f0f0f0;
  height: 36px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerBody = styled.div`
  background: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;
