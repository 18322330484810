import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'rebates.draft.text': {
    id: 'rebates.draft.text',
    defaultMessage: 'Draft',
  },
  'rebates.active.text': {
    id: 'rebates.active.text',
    defaultMessage: 'Active',
  },
  'rebates.inactive.text': {
    id: 'rebates.inactive.text',
    defaultMessage: 'Inactive',
  },
});

export default messages;
