import React from 'react';
import esGet from 'utils/esGet';
import { Tooltip } from 'antd';
import { useMemberManagement } from '../../../../../../MemberManagementContext';

export const formatRealName = (
  realName: string,
  widthLessThan1440?: boolean
) => {
  if (!realName) return '-';
  if (widthLessThan1440) {
    const truncateRealName = (name: string) => {
      if (name.length > 10) return `${name.slice(0, 8)}...`;

      return name;
    };

    const shortenedName = realName.split(' ');

    if (shortenedName.length === 2)
      return `${truncateRealName(shortenedName[0])}
        ${truncateRealName(shortenedName[1])}`;

    if (realName.length >= 10) return truncateRealName(realName);
  }

  return realName;
};

export const TestComponent = React.memo(
  ({
    allData,
    widthLessThan1440,
    widthMoreThan1440,
  }: {
    allData: Record<string, any>;
    widthLessThan1440: boolean;
    widthMoreThan1440: boolean;
  }) => {
    const { loader } = useMemberManagement();

    const {
      batch: { BatchRealName },
    } = loader as Record<string, any>;

    return (
      <BatchRealName
        id={esGet(allData?.id, '')}
        target="realName"
        render={(text: string) => (
          <Tooltip
            getPopupContainer={() => document.getElementById('member-loyalty')!}
            {...((widthMoreThan1440 ||
              formatRealName(text, false) === (text || '-')) && {
              visible: false,
            })}
            overlayStyle={{ fontSize: '10px' }}
            arrowPointAtCenter
            title={<span>{text}</span>}
          >
            {formatRealName(text, widthLessThan1440)}
          </Tooltip>
        )}
      />
    );
  }
);
