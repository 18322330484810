import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { NavigateButton } from './NavigateButton/NavigateButton';
import { PageEntries } from './PageEntries/PageEntries';

interface Props {
  totalCount: number;
}

// eslint-disable-next-line arrow-body-style
export const Footer: React.FC<Props> = ({ totalCount }) => {
  return (
    <Container>
      <div>
        <div>
          <Typography.Text strong className="mr-1">
            {totalCount}
          </Typography.Text>

          <FormattedMessage
            id="results-found.text"
            defaultMessage="Results Found"
            values={{
              count: null,
            }}
          />
        </div>
      </div>

      <NavigateButton />
      <PageEntries />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #f3f8fb;
  height: 48px;
  border-top: 1px solid #e8e8e8;
`;
