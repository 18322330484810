import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 16px;
    width: 360x;
  }
`;

export default {};
