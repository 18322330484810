import gql from 'graphql-tag';
import messages from 'messages';
import { defineMessages } from 'react-intl';

export interface Form {
  type: string;
  required: boolean | undefined;
  field: string;
  updatable: boolean;
  otpVerificationRequired: boolean;
  disabled?: { optional: boolean; hidden: boolean };
  translation?: string;
  __typename?: string;
}

export const PS_KEYS = {
  REAL_NAME: 'REAL_NAME',
  GENDER: 'GENDER',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
  MOBILE_NUMBER: 'MOBILE_NUMBER',
  EMAIL: 'EMAIL',
  ADDRESS: 'ADDRESS',
  QQ: 'QQ_ID',
  WECHAT: 'WECHAT_ID',
  TITLE: 'TITLE',
  PHONE_NUMBER: 'PHONE_NUMBER',
};

type Translator = (
  { id, defaultMessage }: { id: string; defaultMessage: string },
  options?: any
) => string;

export const localMessages = defineMessages({
  REAL_NAME: {
    id: 'REAL_NAME',
    defaultMessage: 'Real Name',
  },
  GENDER: {
    id: 'GENDER',
    defaultMessage: 'Gender',
  },
  DATE_OF_BIRTH: {
    id: 'DATE_OF_BIRTH',
    defaultMessage: 'Date of Birth',
  },
  TITLE: {
    id: 'TITLE',
    defaultMessage: 'Title',
  },
  PHONE_NUMBER: {
    id: 'PHONE_NUMBER',
    defaultMessage: 'Phone Number',
  },
  MOBILE_NUMBER: {
    id: 'MOBILE_NUMBER',
    defaultMessage: 'Mobile Number',
  },
  EMAIL: {
    id: 'EMAIL',
    defaultMessage: 'Email',
  },
  ADDRESS: {
    id: 'ADDRESS',
    defaultMessage: 'Address',
  },
  QQ: {
    id: 'QQ',
    defaultMessage: 'QQ',
  },
  WECHAT: {
    id: 'WECHAT',
    defaultMessage: 'WeChat',
  },
  PS_KEYS_QQ: {
    id: 'PS_KEYS_QQ',
    defaultMessage: 'QQ',
  },
  PS_KEYS_WECHAT: {
    id: 'PS_KEYS_WECHAT',
    defaultMessage: 'WeChat',
  },
  PS_MANDATORY_INFO: {
    id: 'PS_MANDATORY_INFO',
    defaultMessage:
      'User must input this profile field before withdrawal accounts or bank cards can be added.',
  },
  PS_OPTIONAL_INFO: {
    id: 'PS_OPTIONAL_INFO',
    defaultMessage:
      'Profile field will be displayed, but user input is optional.',
  },
  PS_HIDDEN_INFO: {
    id: 'PS_HIDDEN_INFO',
    defaultMessage: 'This field will not be displayed for the user.',
  },
  PS_UPDATABLE_INFO_YES: {
    id: 'PS_UPDATABLE_INFO_YES',
    defaultMessage:
      'YES = After setting this profile field, user is allowed to manually update this field without going through customer support.',
  },
  PS_UPDATABLE_INFO_NO: {
    id: 'PS_UPDATABLE_INFO_NO',
    defaultMessage:
      'NO = Updates to this profile field can only be done through customer support.',
  },
  PS_VERIFICATION_INFO_YES: {
    id: 'PS_VERIFICATION_INFO_YES',
    defaultMessage:
      'YES = To update this field, user must first perform an SMS or Email OTP verification.',
  },
  PS_VERIFICATION_INFO_NO: {
    id: 'PS_VERIFICATION_INFO_NO',
    defaultMessage:
      'NO = User can update this field anytime without the need of any verification.',
  },
  PROFILE_SETTING: {
    id: 'PROFILE_SETTING',
    defaultMessage: 'Profile Setting',
  },
  MANDATORY: {
    id: 'MANDATORY',
    defaultMessage: 'Mandatory',
  },
  OPTIONAL: {
    id: 'OPTIONAL',
    defaultMessage: 'Optional',
  },
  HIDDEN: {
    id: 'HIDDEN',
    defaultMessage: 'Hidden',
  },
  UPDATABLE: {
    id: 'UPDATABLE',
    defaultMessage: 'Updatable',
  },
  UPDATE_VERIFICATION: {
    id: 'UPDATE_VERIFICATION',
    defaultMessage: 'Update Verification',
  },
  NO: {
    id: 'NO',
    defaultMessage: 'No',
  },
  YES: {
    id: 'YES',
    defaultMessage: 'Yes',
  },
  MEMBER_PROFILE_CONFIRM: {
    id: 'MEMBER_PROFILE_CONFIRM',
    defaultMessage: 'The following updates will occur on Affiliates Criteria.',
  },
  DO_YOU_WISH_TO_PROCEED: {
    id: 'DO_YOU_WISH_TO_PROCEED',
    defaultMessage: 'Do you wish to proceed?',
  },
  CANNOT_EDIT_IF_PHONE_AND_EMAIL_HIDDEN: {
    id: 'CANNOT_EDIT_IF_PHONE_AND_EMAIL_HIDDEN',
    defaultMessage:
      'Cannot edit when Mobile Number and Email fields are HIDDEN.',
  },
});

export const getColumns = (translate: Translator) => [
  {
    key: 'PROFILE_SETTING',
    translation: translate(localMessages.PROFILE_SETTING),
  },
  {
    key: 'MANDATORY',
    translation: translate(localMessages.MANDATORY),
    tooltip: translate(localMessages.PS_MANDATORY_INFO),
  },
  {
    key: 'OPTIONAL',
    translation: translate(localMessages.OPTIONAL),
    tooltip: translate(localMessages.PS_OPTIONAL_INFO),
  },
  {
    key: 'HIDDEN',
    translation: translate(localMessages.HIDDEN),
    tooltip: translate(localMessages.PS_HIDDEN_INFO),
  },
  {
    key: 'UPDATABLE',
    translation: translate(localMessages.UPDATABLE),
    tooltip: translate(localMessages.PS_UPDATABLE_INFO_YES),
    tooltip2: translate(localMessages.PS_UPDATABLE_INFO_NO),
  },
  {
    key: 'UPDATE_VERIFICATION',
    translation: translate(localMessages.UPDATE_VERIFICATION),
    tooltip: translate(localMessages.PS_VERIFICATION_INFO_YES),
    tooltip2: translate(localMessages.PS_VERIFICATION_INFO_NO),
  },
];

export const getRows = (translate: Translator) => [
  {
    key: PS_KEYS.REAL_NAME,
    translation: translate(localMessages.REAL_NAME),
  },
  {
    key: PS_KEYS.GENDER,
    translation: translate(localMessages.GENDER),
  },
  {
    key: PS_KEYS.DATE_OF_BIRTH,
    translation: translate(localMessages.DATE_OF_BIRTH),
  },
  {
    key: PS_KEYS.MOBILE_NUMBER,
    translation: translate(localMessages.MOBILE_NUMBER),
  },
  {
    key: PS_KEYS.EMAIL,
    translation: translate(localMessages.EMAIL),
  },
  {
    key: PS_KEYS.ADDRESS,
    translation: translate(localMessages.ADDRESS),
  },
  // {
  //   key: PS_KEYS.QQ,
  //   translation: translate(localMessages.PS_KEYS_QQ),
  // },
  // {
  //   key: PS_KEYS.WECHAT,
  //   translation: translate(localMessages.PS_KEYS_WECHAT),
  // },
  {
    key: PS_KEYS.TITLE,
    translation: translate(localMessages.TITLE),
  },
  {
    key: PS_KEYS.PHONE_NUMBER,
    translation: translate(localMessages.PHONE_NUMBER),
  },
];

export const getRadio = (value: boolean | undefined) => {
  switch (value) {
    case true:
      return localMessages.MANDATORY;
    case false:
      return localMessages.OPTIONAL;
    case undefined:
      return localMessages.HIDDEN;
    default:
      return messages['error-text'];
  }
};

export const CONFIG = gql`
  {
    config {
      emailVerificationEnabled
      mobileNumberVerificationEnabled
      memberFormFields {
        ... on BuiltInFormField {
          type
          required
          field
          updatable
          otpVerificationRequired
        }
      }
    }
  }
`;

export const UPDATE_CONFIG = gql`
  mutation($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;
