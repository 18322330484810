import gql from 'graphql-tag';

export const GET_MEMBER_BET_RECORDS = gql`
  query GetMemberBetRecords(
    $first: Int
    $after: Binary
    $filter: MemberBetRecordsFilterInput
    $sort: SortInput
    $language: Language
  ) {
    memberBetRecords(
      first: $first
      after: $after
      filter: $filter
      sort: $sort
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          serialCode
          member {
            id
            username
            platformId
            brandId
          }
          ggr
          payout
          gameType
          affiliate
          dateTimePlaced
          dateTimeSettled
          dateTimeCanceled
          turnover
          balanceUnitSerialCodes
          effectiveBet
          winloss
          status
          round
          details {
            data
            url
          }
          vendor {
            id
            name(language: $language)
          }
          game {
            id
            name(language: $language)
            category
            subCategory
          }
          tips
          jackpotContributionBet
          jackpotWin
          cashoutWinloss
          freeSpin
          device
          session
          cashTurnover
          bonusTurnover

          baseTurnover
          baseEffectiveBet
          baseWinloss
          baseCashTurnover
          baseBonusTurnover
          baseGgr
          basePayout
          memberCurrency
          exchangeRate

          cryptoTurnover
          cryptoEffectiveBet
          cryptoWinloss
          cryptoCashTurnover
          cryptoBonusTurnover
          cryptoGgr
          cryptoPayout
          cryptoCurrency
          cryptoExchangeRate
        }
      }
    }
  }
`;

export const GET_MEMBER_BET_RECORD_IDS = gql`
  query MemberBetRecordIds(
    $filter: MemberBetRecordsFilterInput
    $partialFilter: MemberBetRecordsFilterInput
  ) {
    memberBetRecords(filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: memberBetRecords(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const TOTAL_MEMBER_BET_VALUES = gql`
  query MemberBetRecordsTotalValues(
    $filter: MemberBetRecordsFilterInput
    $first: Int
  ) {
    memberBetRecords(filter: $filter, first: $first) {
      totalBetAmount
      totalBetsCount
      totalEffectiveBet
      totalSettledBetsCount
      totalWinloss
    }
  }
`;

export const GET_BET_RECORD_SERIAL_CODES = gql`
  query FilterMBRSerialCode(
    $first: Int
    $after: Binary
    $filter: MemberBetRecordsFilterInput
  ) {
    memberBetRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          serialCode
        }
      }
    }
  }
`;

export const GET_BET_RECORD_BET_ID = gql`
  query FilterMBRBetId(
    $first: Int
    $after: Binary
    $filter: MemberBetRecordsFilterInput
  ) {
    memberBetRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          round
        }
      }
    }
  }
`;

export const GET_BET_RECORD_BRAND_IDS = gql`
  query FilterMBRBrandIds(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          brandId
        }
      }
    }
  }
`;

export const GET_BET_RECORD_PLATFORM_IDS = gql`
  query FilterMBRPlatformIds(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          platformId
        }
      }
    }
  }
`;

export const GET_BET_RECORD_GAME_CATEGORY = gql`
  query FilterMBRGameCategory(
    $first: Int
    $after: Binary
    $filter: MemberBetRecordsFilterInput
  ) {
    memberBetRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          game {
            id
            gameCategory: category
          }
        }
      }
    }
  }
`;

export const GET_BET_RECORD_GAME_SUB_CAT = gql`
  query FilterMBRGameSubCategory(
    $first: Int
    $after: Binary
    $filter: MemberBetRecordsFilterInput
  ) {
    memberBetRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          game {
            id
            gameSubCategory: subCategory
          }
        }
      }
    }
  }
`;

export const GET_BET_RECORD_SESSION = gql`
  query FilterMBRSession(
    $first: Int
    $after: Binary
    $filter: MemberBetRecordsFilterInput
  ) {
    memberBetRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          session
        }
      }
    }
  }
`;

export const GET_BET_RECORD_USERNAMES = gql`
  query FilterMBRUsernames(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          username
        }
      }
    }
  }
`;

export const GET_BET_RECORD_GAME_NAMES = gql`
  query FilterMBRGameNames(
    $first: Int
    $after: Binary
    $filter: GamesFilterInput
  ) {
    games(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_MBR_SUMMARY = gql`
  query MBRSummary($filter: MemberBetRecordsFilterInput) {
    memberBetRecordSummary(filter: $filter) {
      totalBetAmount
      totalEffectiveBet
      totalWinloss
      totalPayout
      totalGgr
      totalBetsCount
      totalSettledBetsCount
      totalWinnings
      totalBaseBetAmount
      totalBaseEffectiveBet
      totalBaseWinloss
    }
  }
`;
