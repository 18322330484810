import gql from 'graphql-tag';

export const VENDOR = gql`
  query VendorTag($id: ID) {
    vendor(id: $id) {
      id
      name
    }
  }
`;

export default {};
