import React, { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { StyledModal } from './styles';
import SearchSettingsList from './components/SearchSettingsList';

const SearchSettings = ({
  onUseFilter,
}: {
  onUseFilter: (data: Record<string, any>) => void;
}) => {
  const [isShow, setShow] = useState(false);
  const showModal = () => setShow(!isShow);
  const handleUseFilter = (e: Record<string, any>) => {
    onUseFilter(e);
    showModal();
  };

  return (
    <>
      <a href="#/section" className="ml-1" onClick={showModal}>
        <SettingOutlined />
      </a>
      {isShow && (
        <StyledModal
          title={
            <FormattedMessage
              id="search-settings.text"
              defaultMessage="Search Settings"
            />
          }
          visible
          onOk={showModal}
          onCancel={showModal}
          footer={null}
        >
          <SearchSettingsList onUseFilter={handleUseFilter} />
        </StyledModal>
      )}
    </>
  );
};

export default SearchSettings;
