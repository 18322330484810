import React from 'react';
import { Modal, Button, Empty, Progress } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import styled from 'styled-components';
import { useIsNextV2 } from 'hooks/useIsNext';

const StyledButton = styled(Button)`
  width: 150px;
`;

type SpreadsheetModalProps = {
  visible: boolean;
  toggleModalVisibility: () => void;
  filename: string;
  extension: string;
  permission: boolean;
  generateCsv: () => void;
  reportName?: string;
};

const SpreadsheetModal: React.FC<SpreadsheetModalProps> = ({
  visible,
  toggleModalVisibility,
  filename,
  extension,
  permission,
  generateCsv,
  reportName,
}) => {
  const {
    spreadsheetQueryLoading,
    dataList,
    generateSpreadsheet,
    dataCount,
    isError,
    resetGeneratedData,
  } = useSpreadsheetContext();
  const translate = useTranslate();
  const isNext = useIsNextV2();
  const percentageValue = (dataList.length / dataCount) * 100;

  return (
    <Modal
      visible={visible}
      maskClosable
      width={416}
      onCancel={toggleModalVisibility}
      afterClose={() => resetGeneratedData()}
      footer={
        permission && [
          <Button type="default" key="cancel" onClick={toggleModalVisibility}>
            <FormattedMessage id="reports.close.text" defaultMessage="Close" />
          </Button>,
        ]
      }
    >
      <>
        {permission ? (
          <>
            <div className="styled-center flex flex-direction-column">
              {!spreadsheetQueryLoading && dataList.length < 1 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <>
                  <Progress
                    className="my-3"
                    type="circle"
                    percent={Number(percentageValue.toFixed(2))}
                    width={80}
                    status={isError ? 'exception' : undefined}
                  />
                  <span className="mb-3">{`${dataList.length}/${dataCount}`}</span>
                </>
              )}

              {!spreadsheetQueryLoading && dataList.length > 0 && (
                <StyledButton
                  disabled={spreadsheetQueryLoading}
                  onClick={() =>
                    generateSpreadsheet({
                      filename,
                      extension,
                    })
                  }
                >
                  Download CSV
                </StyledButton>
              )}

              {isError && (
                <div className="text-danger">
                  {isNext() && reportName && (
                    <p>{`Error exporting ${reportName}`}</p>
                  )}
                  {isNext() && reportName && (
                    <p>
                      We encountered an error while trying to export the report.
                      Please try again later or contact support if the issue
                      persists.
                    </p>
                  )}
                  {(!isNext() || !reportName) && (
                    <p> An error occurred. Please try again.</p>
                  )}
                </div>
              )}

              <StyledButton
                type="primary"
                className="mt-1"
                loading={spreadsheetQueryLoading}
                onClick={generateCsv}
              >
                {isError ? 'Retry' : 'Generate New'}
              </StyledButton>
            </div>
          </>
        ) : (
          <>
            <h2>
              <FormattedMessage
                id="download-csv.text"
                defaultMessage="Download CSV"
              />
            </h2>
            {translate(
              messages.YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION
            )}
            !
          </>
        )}
      </>
    </Modal>
  );
};

export default SpreadsheetModal;
