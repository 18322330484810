import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import DownloadCsvButton from 'components/DownloadCsvButton';
import ALL_PERMISSIONS from 'constants/permissions3';
import { TabId } from 'constants/route';
import { WITHDRAWAL_METHODS_CSV_DATA } from 'graphql/queries/withdrawalMethod.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React from 'react';
import { usePermissions } from 'store/accountState';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import AddEditWithdrawalMethod from '../AddEditWithdrawalMethod';

const MemoizedAddEditWithdrawalMethod = React.memo(() => (
  <AddEditWithdrawalMethod edit={false} />
));

const HeaderRight = () => {
  const translate = useTranslate();

  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_WITHDRAWAL_METHODS
  );

  return (
    <>
      <ColumnsFilterNew tabId={TabId.WITHDRAWAL_METHODS as any} iconOnly />
      <div className="mr-2">
        <DownloadCsvButton
          iconOnly
          tabId="withdrawal-methods"
          query={WITHDRAWAL_METHODS_CSV_DATA}
          edgesPath="withdrawalMethods.edges"
          fileName={translate(messages.withdrawalMethods)}
        />
      </div>
      {ALLOWED_CREATE && <MemoizedAddEditWithdrawalMethod />}
    </>
  );
};

export default HeaderRight;
