import gql from 'graphql-tag';

export const GET_SYSTEM_NOTIFICATIONS = gql`
  query SystemNotifications(
    $filter: SystemNotificationFilterInput
    $after: Binary
    $first: Int
  ) {
    systemNotifications(filter: $filter, after: $after, first: $first) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          message
          priority
          recipient
          dateTimeCreated
          dateTimeUpdated
          title
        }
      }
    }
  }
`;
