import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import RichTextField from 'components/RichTextField';

import styled from 'styled-components';

const { Item } = Form;

const colStyles = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

function TierDescription({ formik = {} }: { formik?: Record<string, any> }) {
  const { setFieldValue, values } = formik;

  const { description } = values;

  return (
    <div>
      <Item
        colon={false}
        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        label={
          <strong>
            <FormattedMessage
              id="tier-description.text"
              defaultMessage="Tier Description"
            />
          </strong>
        }
        {...colStyles}
      >
        &nbsp;
      </Item>

      <Row>
        <StyledCol span={16} offset={6}>
          <RichTextField
            name="description"
            defaultValue={description}
            onChange={setFieldValue}
          />
        </StyledCol>
      </Row>
    </div>
  );
}

const StyledCol = styled(Col)`
  .ck-editor__editable_inline {
    height: 360px;
  }
`;

export default TierDescription;
