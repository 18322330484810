import { createBreakpoint } from 'react-use';

export const useBreakpoint = createBreakpoint({
  XL: 1280,
  L: 768,
  S: 350,
});

export const isSmall = (breakpoint: string) => breakpoint === 'S';
export const isLarge = (breakpoint: string) => breakpoint === 'L';
export const isExtraLarge = (breakpoint: string) => breakpoint === 'XL';
