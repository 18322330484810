import styled from 'styled-components';
import { get } from 'lodash';
import { Select } from 'antd';
import { findColorByBgColor } from 'pages/components/SystemMessage/ManualMessage/components/CreateNewMail/styles';

const { Option } = Select;

const colorTags = (tags?: any) =>
  tags.reduce(
    (acc: any, item: any, key: number) =>
      `${acc}
        &[title='${key}'] {
          background-color: ${get(
            findColorByBgColor(item.node.color),
            'bgColor',
            '#efefef'
          )};
          border: 1px solid
            ${get(
              findColorByBgColor(item.node.color),
              'borderColor',
              '#888888'
            )};
          border-radius: 11px !important;
          color: #000 !important;
        
          .anticon-close {
            color: #000 !important;
          }
        }
`,
    ''
  );

export const StyledOption = styled(Option)<{ color: string }>``;

export const StyledSelect = styled(Select)<{ tags?: any }>`
  width: 100% !important;

  .ant-select-selection {
    border-radius: 2px;
  }

  .ant-select-selection-selected-value {
    font-size: 12px;
    margin-top: 3px !important;
    height: 24px !important;
    line-height: 22px !important;
    padding: 0 15px;
    ${({ tags }) => (tags ? colorTags(tags) : '')}
  }

  .ant-select-selection li.ant-select-selection__choice {
    ${({ tags }) => (tags ? colorTags(tags) : '')}
  }
`;
