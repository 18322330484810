import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';
import { defineMessages } from 'react-intl';
import useTranslate from 'utils/useTranslate';

const StyledDropdown = styled<typeof Dropdown>(Dropdown)`
  display: block;
  width: 100%;
`;

type Props = {
  value: string;
  onChange: (e: string) => void;
};

const localMessages = defineMessages({
  'MISSIONS.EFFECTIVE_BET': {
    id: 'MISSIONS.EFFECTIVE_BET',
    defaultMessage: 'Make Bets',
  },
  'MISSIONS.GAME_TYPE_EFFECTIVE_BET': {
    id: 'MISSIONS.GAME_TYPE_EFFECTIVE_BET',
    defaultMessage: 'Make Bets on Category',
  },
  'MISSIONS.LOGIN': {
    id: 'MISSIONS.LOGIN',
    defaultMessage: 'Login',
  },
  'MISSIONS.OFFLINE_PAYMENT_METHOD_DEPOSIT': {
    id: 'MISSIONS.OFFLINE_PAYMENT_METHOD_DEPOSIT',
    defaultMessage: 'Deposit using offline payment method',
  },
  'MISSIONS.ONLINE_PAYMENT_METHOD_DEPOSIT': {
    id: 'MISSIONS.ONLINE_PAYMENT_METHOD_DEPOSIT',
    defaultMessage: 'Deposit using online payment method',
  },
  'MISSIONS.USDT_PAYMENT_METHOD_DEPOSIT': {
    id: 'MISSIONS.USDT_PAYMENT_METHOD_DEPOSIT',
    defaultMessage: 'Deposit using USDT payment method',
  },
  'MISSIONS.VENDOR_EFFECTIVE_BET': {
    id: 'MISSIONS.VENDOR_EFFECTIVE_BET',
    defaultMessage: 'Make Bets on Vendor',
  },
});

const taskMethods = [
  'MISSIONS.ONLINE_PAYMENT_METHOD_DEPOSIT',
  'MISSIONS.OFFLINE_PAYMENT_METHOD_DEPOSIT',
  'MISSIONS.USDT_PAYMENT_METHOD_DEPOSIT',
  'MISSIONS.EFFECTIVE_BET',
  'MISSIONS.GAME_TYPE_EFFECTIVE_BET',
  'MISSIONS.VENDOR_EFFECTIVE_BET',
  'MISSIONS.LOGIN',
];

export const TaskMethodSelect = ({ value, onChange }: Props) => {
  const serializedValue = `MISSIONS.${value}`;
  const [selected, setSelected] = useState(serializedValue);
  const translate = useTranslate();

  const deserializeValue = (task: string) => task.split('.')[1];

  const onSelectMenu = (method: string) => {
    setSelected(method);
    onChange(deserializeValue(method));
  };

  return (
    <StyledDropdown
      overlay={
        <Menu>
          {taskMethods.map((method) => (
            <Menu.Item key={method} onClick={() => onSelectMenu(method)}>
              {translate(localMessages[method])}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <span className="cursor-pointer" onClick={(e) => e.preventDefault()}>
        {translate(localMessages[selected])} <DownOutlined />
      </span>
    </StyledDropdown>
  );
};
