import gql from 'graphql-tag';

export const CREATE_MEMBER_BET_RECORD_REPORT = gql`
  mutation CreateMemberBetRecordReport(
    $input: CreateMemberBetRecordReportInput
  ) {
    createMemberBetRecordReport(input: $input)
  }
`;

export const EXPORT_MEMBERS = gql`
  mutation ExportMembers($input: ExportMembersInput) {
    exportMembers(input: $input)
  }
`;

export const CREATE_BALANCE_TRANSACTION_RECORD_REPORT = gql`
  mutation CreateBalanceTransactionRecordReport(
    $input: CreateBalanceTransactionRecordReportInput
  ) {
    createBalanceTransactionRecordReport(input: $input)
  }
`;

export const CREATE_PROMO_PAYOUTS_REPORT = gql`
  mutation CreatePromoPayoutsReport($input: CreatePromoPayoutReportInput) {
    generatePromoPayoutReport(input: $input)
  }
`;
