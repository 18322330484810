import { useSearchParam } from 'react-use';
import { isDevelopment, isLocal, isStaging } from 'utils/appEnvironment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Flag, Flags } from 'types/featureFlag';

function useIsNext(flag?: string): boolean {
  const flags = useFlags();
  const nextParam = useSearchParam('next');
  return (
    (nextParam === 'true' && (isLocal() || isDevelopment() || isStaging())) ||
    !!(flag && flags[flag])
  );
}

export const useIsNextV2 = () => {
  const flags = useFlags();
  const nextParam = useSearchParam('next');
  const isNext = (flag?: string) =>
    (nextParam === 'true' && (isLocal() || isDevelopment() || isStaging())) ||
    !!(flag && flags[flag]);
  return isNext;
};

export const useNextParam = (): boolean => {
  const nextParam = useSearchParam('next');
  return nextParam === 'true';
};

export const isFlagEnabled = (flags: Flags, flag: Flag): boolean =>
  !!(flag && flags[flag]);

export default useIsNext;
