import { useMutation } from '@apollo/react-hooks';
import { Modal } from 'antd';
import { FeatureFlags } from 'constants/featureFlags';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  DELETE_BANNER,
  DUPLICATE_BANNER,
  UPDATE_BANNER,
} from 'graphql/mutations/banner.mutation';
import { BANNERS } from 'graphql/queries/banner.query';
import useIsNext from 'hooks/useIsNext';
import { cloneDeep, get } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { SharedStyledTable } from 'styles/SharedStyledTable';
import { usePermissions } from 'store/accountState';
import { arrayMove } from 'utils/arrayMove';
import { rowSource, rowTarget } from 'utils/tableDragAndDrop';
import useTranslate from 'utils/useTranslate';
// eslint-disable-next-line import/named
import { BodyRow, columns } from './utils';

const draggingIndex = -1;

const { confirm } = Modal;

type Rec = Record<string, any>;

function RotatingBannerTable({
  bannersData,
  setSelectedBanner,
  selectedBanner,
  setForEditBanner,
  refetchVariables,
}: {
  bannersData: Rec;
  setSelectedBanner: (e: Rec) => void;
  selectedBanner: Rec;
  setForEditBanner: () => void;
  refetchVariables: Rec;
}) {
  const { role, permissions } = usePermissions();
  const translate = useTranslate();
  const {
    ALLOWED_LIST,
    ALLOWED_EDIT,
    ALLOWED_DUPLICATE,
    ALLOWED_DELETE,
    ALLOWED_TOGGLE_ENABLE,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'EDIT', 'DUPLICATE', 'DELETE', 'TOGGLE_ENABLE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_ROTATING_BANNER}`
  );

  const columnPermission = {
    ALLOWED_EDIT,
    ALLOWED_DUPLICATE,
    ALLOWED_DELETE,
    ALLOWED_TOGGLE_ENABLE,
  };

  const [banners, setBanners] = useState<any>(null);
  const [deleteBanner] = useMutation(DELETE_BANNER);
  const [updateBanner] = useMutation(UPDATE_BANNER);
  const [duplicateBanner] = useMutation(DUPLICATE_BANNER);

  const listOfBanners = get(bannersData, 'banners.edges', []).map(
    (x: { node: Rec }) => x.node
  );

  const refetchQueries = [
    {
      query: BANNERS,
      variables: refetchVariables,
    },
  ];

  useEffect(() => {
    if (bannersData) {
      setBanners(listOfBanners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannersData]);

  const DraggableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }))(
    DragSource('row', rowSource(draggingIndex), (connect) => ({
      connectDragSource: connect.dragSource(),
    }))(BodyRow(setSelectedBanner, banners, draggingIndex))
  );

  const moveRow = async (dragIndex: number, hoverIndex: number) => {
    const currentBanner = banners[dragIndex];
    const hoveredBanner = banners[hoverIndex];

    setBanners((prevBanners: any[]) =>
      arrayMove(prevBanners, dragIndex, hoverIndex)
    );

    updateBanner({
      variables: {
        id: currentBanner.id,
        input: {
          rank:
            currentBanner.rank < hoveredBanner.rank
              ? hoveredBanner.rank + 1
              : hoveredBanner.rank,
        },
      },
      refetchQueries,
    });
  };

  const onDeleteBanner = (banner: Rec) => {
    confirm({
      title: translate(messages['delete-banner-title.text']),
      content: translate(messages['banner-delete-confirm-message.text'], {
        name: banner.name,
      }),
      okText: translate(messages.DELETE),
      okType: 'danger',
      cancelText: translate(messages.CANCEL),
      onOk() {
        deleteBanner({
          variables: {
            id: banner.id,
          },
          refetchQueries,
        }).then(() => {
          if (!selectedBanner) {
            return;
          }

          if (banner.id === selectedBanner.id) {
            setSelectedBanner(null as any);
          }
        });
      },
    });
  };

  const onDuplicateBanner = (id: string) => {
    duplicateBanner({
      variables: {
        id,
      },
      refetchQueries,
    });
  };

  const updateSwitchUi = (banner: Rec, type: string) => {
    const bannerIdx = banners.findIndex(
      (currentBanner: { id: string }) => currentBanner.id === banner.id
    );
    const bannersCopy = cloneDeep(banners);

    if (type === 'desktop') {
      bannersCopy[bannerIdx].enabledDesktop = !banner.enabledDesktop;
      setBanners(bannersCopy);
    }
    if (type === 'mobile') {
      bannersCopy[bannerIdx].enabledMobile = !banner.enabledMobile;
      setBanners(bannersCopy);
    }
    if (type === 'horizontal') {
      bannersCopy[bannerIdx].enabledHorizontal = !banner.enabledHorizontal;
      setBanners(bannersCopy);
    }
  };

  const toggleDesktopOrMobile = (
    type: string,
    banner: {
      [key: string]: boolean;
    }
  ) => {
    let obj;
    if (type === 'desktop') {
      obj = {
        enabledDesktop: !banner.enabledDesktop,
      };
    }

    if (type === 'mobile') {
      obj = {
        enabledMobile: !banner.enabledMobile,
      };
    }

    if (type === 'horizontal') {
      obj = {
        enabledHorizontal: !banner.enabledHorizontal,
      };
    }

    updateSwitchUi(banner, type);
    updateBanner({
      variables: {
        id: banner.id,
        input: obj,
      },
      refetchQueries,
    });
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const isNextTrue = useIsNext(FeatureFlags.horizontalImage);

  return (
    <>
      {ALLOWED_LIST && (
        <SharedStyledTable
          size="middle"
          rowKey={(allData: any) => allData.id}
          pagination={false}
          columns={columns(
            isNextTrue,
            toggleDesktopOrMobile,
            setForEditBanner,
            onDuplicateBanner,
            onDeleteBanner,
            columnPermission
          )}
          dataSource={banners}
          components={components}
          onRow={(_: any, index: number) => ({
            index,
            moveRow,
          })}
        />
      )}
    </>
  );
}

export default RotatingBannerTable;
