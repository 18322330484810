/* eslint-disable no-underscore-dangle */
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { DATE_FORMAT } from 'constants/date';
import moment from 'moment';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import copyToClipboard from 'utils/copyToClipboard';
import { truncateProgramName } from 'utils/truncateProgramName';
import useTranslate from 'utils/useTranslate';

const PaymentMethodDetails = ({
  deposit,
  readOnly,
}: {
  deposit: Record<string, any>;
  readOnly: boolean;
}) => {
  const translate = useTranslate();
  const {
    transactionReference,
    paymentMethod = {},
    dateTimeProcessed,
    dateTimeCreated,
    processor,
    status,
    __typename,
  } = deposit;

  const { Text } = Typography;

  const messages = defineMessages({
    OnlineBankTransferDeposit: {
      id: 'online-bank-transfer.text',
      defaultMessage: 'Online Bank transfer',
    },
    OnlineAlipayDeposit: {
      id: 'online-alipay.text',
      defaultMessage: 'Online Alipay',
    },
    OnlineWechatDeposit: {
      id: 'online-wechat.text',
      defaultMessage: 'Online WeChat',
    },
  });

  const { text: truncTransactionRef, isHoverable } = truncateProgramName(
    transactionReference
  );

  return (
    <div data-testid="payment-method-details">
      <div className="menu-header p-2 mt-4">
        <FormattedMessage
          id="payment-method-details.text"
          defaultMessage="Payment method details"
        />
      </div>
      <div className="d-flex p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="payment-method-name.text"
              defaultMessage="Payment method name"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {paymentMethod.name}{' '}
          <ALink
            disabled={readOnly}
            onClick={() => copyToClipboard(paymentMethod.name)}
          >
            <CopyOutlined />
          </ALink>
        </div>
      </div>
      <div className="d-flex p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="payment-method.text"
              defaultMessage="Payment method"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {translate(
            messages[__typename] || {
              id: 'online-bank-transfer.text',
              defaultMessage: 'Online Bank transfer',
            }
          )}{' '}
          <ALink
            disabled={readOnly}
            onClick={() => copyToClipboard(translate(messages[__typename]))}
          >
            <CopyOutlined />
          </ALink>
        </div>
      </div>
      <div className="d-flex p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="processing-time.text"
              defaultMessage="Processing time"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {status === 'EXPIRED' && '-'}
          {(status === 'APPROVED' || status === 'REJECTED') && (
            <>
              {moment(dateTimeProcessed).format(`${DATE_FORMAT} (ddd)`)}
              <br />
              {moment(dateTimeProcessed).format('HH:mm:ss')} -{' '}
              {moment(dateTimeProcessed).from(dateTimeCreated, true)}
            </>
          )}
          {(status === 'PROCESSING' || status === 'PENDING') && (
            <>
              {moment(dateTimeCreated).format(`${DATE_FORMAT} (ddd)`)}
              <br />
              {moment(dateTimeCreated).format('HH:mm:ss')} -{' '}
              {moment(dateTimeCreated).fromNow()}
            </>
          )}
        </div>
      </div>
      <div className="d-flex p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage id="operator.text" defaultMessage="Operator" />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {processor ? processor.username : '-'}
        </div>
      </div>
      <div className="d-flex p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="3rd-party-transaction-id.text"
              defaultMessage="3rd party transaction ID"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {truncTransactionRef ? (
            <>
              <Tooltip
                {...(!isHoverable && { visible: false })}
                title={<span>{transactionReference}</span>}
              >
                {truncTransactionRef}
              </Tooltip>{' '}
              <ALink
                disabled={readOnly}
                onClick={() => copyToClipboard(transactionReference)}
              >
                <CopyOutlined />
              </ALink>
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      {deposit.hexoPayUid && (
        <div className="d-flex p-2">
          <Text type="secondary">Hexopay UID</Text>
          <div className="flex-1 text-right">{deposit.hexoPayUid}</div>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodDetails;
