import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { DELETE_AFFILIATE_PROGRAMME } from 'graphql/mutations/affiliateProgramme.mutation';
import { AFFILIATE_PROGRAMMES } from 'graphql/queries/affiliateProgramme.query';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

type DeleteAffiliateProgrammeType = {
  affiliate: Record<string, any>;
  refetch: () => void;
  disabled: boolean;
};

const DeleteAffiliateProgramme = ({
  affiliate,
  refetch,
  disabled,
}: DeleteAffiliateProgrammeType) => {
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: any) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };
  const translate = useTranslate();
  const [deleteAffiliateProgramme, { loading }] = useMutation(
    DELETE_AFFILIATE_PROGRAMME,
    {
      onCompleted: () => {
        message.success(
          <span data-testid="delete-success">
            {translate(messages.AFFILIATE_PROGRAMME_DELETE_SUCCESS)}
          </span>
        );
        refetch();
        handleShow();
      },
      onError: () => {
        message.error(
          <span data-testid="delete-error">
            {translate(messages.AFFILIATE_PROGRAMME_DELETE_ERROR)}
          </span>
        );
      },
    }
  );

  return (
    <>
      <a
        href="#/"
        onClick={() => {
          if (!disabled) {
            handleShow();
          }
        }}
        data-testid="delete"
      >
        <FormattedMessage id="delete.text" defaultMessage="Delete" />
      </a>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          cancelButtonProps={{ disabled: loading }}
          okButtonProps={{ loading, 'data-testid': 'confirm' } as any}
          onCancel={handleShow}
          onOk={() => {
            deleteAffiliateProgramme({
              variables: {
                id: affiliate.id,
              },
              refetchQueries: [
                {
                  query: AFFILIATE_PROGRAMMES,
                },
              ],
            });
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-delete.text"
                defaultMessage="Confirm Delete"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteAffiliateProgramme;
