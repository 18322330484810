import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import SelectFilterField from 'components/SelectFilterField';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import BrandFilter from 'components/BrandFilter';
import {
  GET_BTR_MANUAL_ADJUSTMENT_REMARKS,
  GET_BTR_MEMBER_BRAND_IDS,
  GET_BTR_MEMBER_PLATFORM_IDS,
  GET_BTR_PROCESSOR,
  GET_BTR_REMARKS,
  GET_BTR_SERIAL_CODES,
  GET_BTR_TRANSACTIONS,
  GET_BTR_USERNAMES,
} from 'graphql/queries/balanceTransactionRecordNext.query';

import DateTimeRangePicker from 'components/DateTimeRangePicker';
import { useDatePresets } from 'hooks/useDatePresets';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';

import ALL_PERMISSIONS from 'constants/permissions3';

import isRow from 'utils/isRow';
import { GET_AVAILABLE_CURRENCIES } from 'hooks/useAvailableCurrencies';
import { ManualBalanceAdjustmentFilter, MinMax, Type } from './components';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  onFilterChange: (value: DynamicObj) => void;
};

const FilterItems = ({
  filters,
  onRawFilterChange: handleChange,
  onFilterChange,
}: Props) => {
  const usernameValues = filters?.member?.in || [];
  const processorValues = filters?.processor?.in || [];

  const { disableDateFilter, setPresetValue } = useDatePresets();

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST: ALLOWED_MEMBER } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const { ALLOWED_LIST: ALLOWED_OPERATOR } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  return (
    <>
      <SelectFilterField
        testId="BTR-serialCode-filter"
        label="Serial Code"
        query={GET_BTR_SERIAL_CODES}
        queryConnection="balanceTransactionRecords"
        filterFieldName="serialCode"
        filters={filters}
        partialSupported={false}
        onChange={(e) =>
          handleChange({
            name: 'serialCode',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      {isRow && (
        <SelectFilterField
          testId="BTR-currency-filter"
          label="Currency"
          allowFilterOption
          query={GET_AVAILABLE_CURRENCIES}
          isAvailableCurrencyQuery
          queryConnection=""
          filterFieldName="currency"
          queryOperation="in"
          filters={filters}
          partialSupported={false}
          onChange={(e) =>
            handleChange({
              name: 'currency',
              value: e?.length ? { in: e } : null,
            })
          }
        />
      )}
      {ALLOWED_MEMBER && (
        <IdBasedSelectFilterField
          testId="BTR-username-filter"
          label="Member"
          query={GET_BTR_USERNAMES}
          queryConnection="members"
          filterFieldName="username"
          filters={filters}
          rawFilterValues={usernameValues}
          onChange={(e) =>
            handleChange({
              name: 'member',
              value: e?.length ? { in: e } : null,
            })
          }
          partialSupported={false}
        />
      )}
      <BrandFilter state={filters} setState={onFilterChange} />
      <SelectFilterField
        testId="BTR-brandId-filter"
        label="Brand Id"
        query={GET_BTR_MEMBER_BRAND_IDS}
        queryConnection="balanceTransactionRecords"
        fieldType="member"
        filterFieldName="brandId"
        filters={filters}
        partialSupported={false}
        onChange={(e) =>
          handleChange({
            name: 'brandId',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <SelectFilterField
        testId="BTR-platformId-filter"
        label="Platform Id"
        query={GET_BTR_MEMBER_PLATFORM_IDS}
        queryConnection="balanceTransactionRecords"
        fieldType="member"
        filterFieldName="platformId"
        filters={filters}
        partialSupported={false}
        onChange={(e) =>
          handleChange({
            name: 'platformId',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <MinMax disabled={false} setState={onFilterChange} state={filters} />
      <ManualBalanceAdjustmentFilter
        state={filters}
        setState={onFilterChange}
      />
      <Type state={filters} setState={onFilterChange} />
      <SelectFilterField
        testId="BTR-remarks-filter"
        label="Remarks"
        query={GET_BTR_REMARKS}
        queryConnection="balanceTransactionRecords"
        filterFieldName="remarks"
        filters={filters}
        partialSupported={false}
        onChange={(e) =>
          handleChange({
            name: 'remarks',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <SelectFilterField
        testId="BTR-transactionId-filter"
        label="Unique Transaction Id"
        query={GET_BTR_TRANSACTIONS}
        queryConnection="balanceTransactionRecords"
        filterFieldName="transaction"
        filters={filters}
        partialSupported={false}
        onChange={(e) =>
          handleChange({
            name: 'transaction',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      {ALLOWED_OPERATOR && (
        <IdBasedSelectFilterField
          testId="BTR-processor-filter"
          label="Initiator"
          query={GET_BTR_PROCESSOR}
          queryConnection="operators"
          filterFieldName="username"
          filters={filters}
          rawFilterValues={processorValues}
          onChange={(e) =>
            handleChange({
              name: 'processor',
              value: e?.length ? { in: e } : null,
            })
          }
          partialSupported={false}
        />
      )}

      <DateTimeRangePicker
        testId="BTR-dateTime-created-filter"
        label="reports.date-time-created"
        value={
          filters.dateTimeCreated
            ? [filters.dateTimeCreated.gte, filters.dateTimeCreated.lte]
            : null
        }
        onChange={(e) => {
          if (!e?.length) setPresetValue(null);
          handleChange({
            name: 'dateTimeCreated',
            value: e?.length ? { gte: e[0], lte: e[1] } : null,
          });
        }}
        disableDateInput={disableDateFilter}
      />

      <SelectFilterField
        testId="BTR-adjustment-remarks-filter"
        label="Adjustment Remarks"
        query={GET_BTR_MANUAL_ADJUSTMENT_REMARKS}
        queryConnection="balanceTransactionRecords"
        filterFieldName="manualAdjustmentRemarks"
        filters={filters}
        partialSupported={false}
        onChange={(e) =>
          handleChange({
            name: 'manualAdjustmentRemarks',
            value: e?.length ? { in: e } : null,
          })
        }
      />
    </>
  );
};

export default FilterItems;
