import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import tetherLogo from 'img/icons/crypto/tether-logo.svg';
import useTranslate from 'utils/useTranslate';
import { setDecimals } from 'utils/setDecimals';
import messages from '../../../../messages';
import { StyledTag } from './styles';

type Props = {
  paymentMethod: Record<string, any>;
  formItemLayout: Record<string, any>;
};

const LimitSettings = ({ paymentMethod, formItemLayout }: Props) => {
  const {
    minimumDepositAmount,
    maximumDepositAmount,
    instructionText,
    maximumDepositAmountPerDay,
    suggestedAmounts,
    __typename,
    allowCustomAmount,
  } = paymentMethod;

  const tetherLogoElement = (size: number) => (
    <img
      src={tetherLogo}
      alt="logo"
      className="mr-1"
      height={size}
      width={size}
    />
  );
  const translate = useTranslate();

  const manualAdjustmentMethod = __typename === 'ManualAdjustmentPaymentMethod';
  const isCrypto = __typename === 'CryptocurrencyPaymentMethod';
  const singleDeposit = isCrypto ? (
    <>
      {tetherLogoElement(18)}
      {setDecimals(minimumDepositAmount, 2)} - {tetherLogoElement(18)}
      {setDecimals(maximumDepositAmount, 2)}
    </>
  ) : (
    `${setDecimals(minimumDepositAmount, 2)} - ${setDecimals(
      maximumDepositAmount,
      2
    )}`
  );

  const dailyDepositAmount = isCrypto ? (
    <>
      {tetherLogoElement(18)}
      {setDecimals(maximumDepositAmountPerDay, 2)}
    </>
  ) : (
    `${setDecimals(maximumDepositAmountPerDay, 2)}`
  );

  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1 mt-2"
        label={
          <strong id="limit-settings">
            {translate(messages.limitsettings)}
          </strong>
        }
        colon={false}
      />
      {/* custom amount input */}
      <Form.Item
        label={translate(messages.CUSTOM_AMOUNT_INPUT)}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{allowCustomAmount ? 'On' : 'Off'}</span>
      </Form.Item>
      {/* custom amount input */}
      <Form.Item
        label={translate(messages.singledeposit)}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{manualAdjustmentMethod ? '-' : singleDeposit}</span>
      </Form.Item>
      <Form.Item
        label={translate(messages.dailyDepositLimit)}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{manualAdjustmentMethod ? '-' : dailyDepositAmount}</span>
      </Form.Item>
      <Form.Item
        label={translate(messages.recommendedinstructions)}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{instructionText || '-'}</span>
        {/* <div dangerouslySetInnerHTML={{ __html: instructionText }} /> */}
      </Form.Item>
      <Form.Item
        label={translate(messages.recommendedamount)}
        {...formItemLayout}
        className="mb-0"
      >
        {suggestedAmounts && suggestedAmounts.length
          ? suggestedAmounts.map((amount: number, index: number) => (
              <StyledTag visible data-id={amount} key={`${amount}-${index}`}>
                {isCrypto ? tetherLogoElement(12) : ''} {setDecimals(amount, 2)}
              </StyledTag>
            ))
          : '-'}
      </Form.Item>
    </>
  );
};

export default LimitSettings;
