import React from 'react';
import { EllipsisOutlined, MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Control, useFieldArray } from 'react-hook-form';
import { useEffectOnce } from 'react-use';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import { TaskCard } from './TaskCard';

type Props = {
  nestIndex: number;
  control: Control;
  item: Record<string, any>;
  removeDay: (index: number) => void;
  numberOfDays: number;
  duplicateDay: () => void;
  errors: Record<string, any>;
  swapDay: (from: number, to: number) => void;
};

const appendDefaultValues = {
  type: 'ONLINE_PAYMENT_METHOD_DEPOSIT',
  amount: 0,
  multiplier: true,
  rewardMultiplier: true,
  rewardType: 'MONEY',
  turnoverRequirementMultiplier: '',

  // optional fields
  vendors: [],
  gameTypes: [],
};

export const Day = ({
  nestIndex,
  control,
  item,
  removeDay,
  numberOfDays,
  duplicateDay,
  errors,
  swapDay,
}: Props) => {
  const day = nestIndex + 1;
  const { fields, append, remove } = useFieldArray({
    control,
    name: `days[${nestIndex}].tasks`,
  });

  useEffectOnce(() => {
    if (item?.tasks?.length === 0) {
      append(appendDefaultValues);
    }

    if (fields?.length !== item?.tasks?.length) {
      append(item.tasks);
    }
  });

  const [, drop] = useDrop({
    accept: 'task',
    drop: (itemDrop: DragObjectWithType & { id: number }) => {
      swapDay(itemDrop.id, nestIndex);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const [, drag, preview] = useDrag({
    item: { type: 'task', id: nestIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const attachRef = (el: HTMLElement) => {
    preview(el);
    drop(el);
  };

  return (
    <>
      <section ref={attachRef} className="mt-3 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div ref={drag}>
            <MenuOutlined
              style={{ cursor: 'grab' }}
              className="text-muted fs-18 mr-3"
            />
          </div>
          <span className="fs-17 text-black mr-3">
            <FormattedMessage id="DAY" defaultMessage="Day" /> {day}
          </span>
        </div>
        <Dropdown
          overlay={() => (
            <Menu>
              <Menu.Item
                onClick={() => {
                  remove();
                  setTimeout(() => {
                    append(appendDefaultValues);
                  });
                }}
              >
                <FormattedMessage id="CLEAR_DAY" defaultMessage="Clear Day" />
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  duplicateDay();
                }}
              >
                <FormattedMessage
                  id="DUPLICATE_DAY"
                  defaultMessage="Duplicate Day"
                />
              </Menu.Item>
              <Menu.Item
                onClick={() => removeDay(nestIndex)}
                disabled={numberOfDays === 1}
              >
                <FormattedMessage id="DELETE_DAY" defaultMessage="Delete Day" />
              </Menu.Item>
            </Menu>
          )}
        >
          <EllipsisOutlined
            className="cursor-pointer"
            style={{ fontSize: '25px' }}
          />
        </Dropdown>
      </section>

      {fields.map((itemNested, idx) => (
        <TaskCard
          errors={errors}
          key={itemNested.id}
          nestIndex={nestIndex}
          control={control}
          index={idx}
          item={itemNested}
        />
      ))}
    </>
  );
};
