/* eslint-disable react-hooks/rules-of-hooks */
import React, { PureComponent } from 'react';
import PropTypes, { string } from 'prop-types';
import { Result, message } from 'antd';
import { injectIntl } from 'react-intl';
import setErrorMessage from 'utils/setErrorMessage';
import messages from 'messages';

class ErrorHandler extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    const { onError, intl } = this.props;
    this.setState({ hasError: true });
    onError(error, info);
    message.error(
      process.env.NODE_ENV === 'development'
        ? setErrorMessage(error)
        : intl.formatMessage(messages['fatal-error.text'])
    );
  }

  render() {
    const { hasError } = this.state;
    const { children, errorElement, showFallbackUI, title, intl } = this.props;
    if (showFallbackUI && errorElement && hasError) {
      return errorElement;
    }
    if (hasError) {
      return (
        errorElement || (
          <Result
            status="warning"
            title={title}
            extra={
              <div style={{ color: 'grey' }}>
                {intl.formatMessage(messages['fatal-error.text'])}
              </div>
            }
          />
        )
      );
    }

    return children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  errorElement: PropTypes.element,
  onError: PropTypes.func,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
  showFallbackUI: PropTypes.bool,
  title: string,
};

ErrorHandler.defaultProps = {
  errorElement: null,
  onError: () => {},
  showFallbackUI: false,
  title: '',
};

export default injectIntl(ErrorHandler);
