import gql from 'graphql-tag';

export const CLIENT_NAMES = gql`
  query ClientNames($first: Int, $after: Binary, $filter: AdminsFilterInput) {
    admins(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;
