import React from 'react';
import messages from 'pages/components/MemberWithdrawals/messages';
import useTranslate from 'utils/useTranslate';
import { FilterProps } from 'interfaces/filters.interface';
import { blockAlphabets } from 'utils/blockAlphabets';
import { StyledInput } from './styles';

const ProcessingTimeFilter = (props: FilterProps) => {
  const { value, onChange } = props;

  const handleMinChange = (minute: number) => {
    onChange({
      range: 'gte',
      value: minute || null,
    });
  };

  const handleMaxChange = (minute: number) => {
    onChange({
      range: 'lte',
      value: minute || null,
    });
  };

  const handleBlur = (key: string) => {
    if (value.min && value.max) {
      if (value.min > value.max || value.max < value.min) {
        onChange({
          range: key === 'min' ? 'gte' : 'lte',
          value: '',
        });
      }
    }
  };

  const onKeyDownPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    blockAlphabets(e, true);
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  const translate = useTranslate();
  return (
    <div className="d-flex align-items-center justify-content-space-between">
      <StyledInput
        onChange={handleMinChange}
        value={value.min || null}
        placeholder={translate(messages.minute)}
        onBlur={() => handleBlur('min')}
        type="number"
        min={0}
        onKeyDown={onKeyDownPress}
      />
      -
      <StyledInput
        onChange={handleMaxChange}
        value={value.max || null}
        placeholder={translate(messages.minute)}
        onBlur={() => handleBlur('max')}
        type="number"
        min={0}
        onKeyDown={onKeyDownPress}
      />
    </div>
  );
};

export default ProcessingTimeFilter;
