import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { isBoolean } from 'lodash';
import styled from 'styled-components';
import { InputNumber } from 'antd';
import { NullableType } from 'interfaces/graphql.interface';
import { checkBothValidity, CheckIcon, checkValidity, messages } from './utils';

export const StyledInput = styled(InputNumber)`
  width: 100%;
  border-radius: 2px;

  input {
    padding-right: 25px !important;
  }

  .ant-input-number-handler-wrap {
    display: none !important;
  }
`;

type MinMaxType = {
  gte?: NullableType<number>;
  lte?: NullableType<number>;
} | null;

type FilterStateType = { [key: string]: any };

type KeyType = 'min' | 'max';

type Props = {
  state: FilterStateType;
  setState: (newState: any) => void;
  disabled?: boolean;
};

const initState: MinMaxType = {
  gte: 0,
  lte: 0,
};

const JackpotContributionBetMinMax: React.FC<Props> = ({
  setState,
  state,
  disabled,
}) => {
  const [input, setInput] = useState(initState);
  const [valid, setValid] = useState({
    gte: null,
    lte: null,
  });
  const [initialized, setInitialized] = useState(false);
  const intl = useIntl();

  const value = state.jackpotContributionBet;

  const onChange = (e: MinMaxType) => {
    const newValue = {
      ...(e && (e.gte || e.gte === 0) && { gte: e.gte }),
      ...(e && (e.lte || e.lte === 0) && { lte: e.lte }),
    };

    const jackpotContributionBet = e === null ? null : newValue;

    setState((prev: FilterStateType) => ({
      ...prev,
      jackpotContributionBet,
    }));
  };

  useEffect(() => {
    if (!initialized) {
      return;
    }
    const hasOneTrueAndNull =
      Object.values(valid).some((x) => x === true) &&
      Object.values(valid).some((x) => x === null);
    if (hasOneTrueAndNull) {
      onChange({
        gte: input.gte,
        lte: input.lte,
      });
    }

    if (valid.gte && valid.lte) {
      onChange({
        ...value,
        gte: input.gte,
        lte: input.lte,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);

  useEffect(() => {
    if (!initialized) {
      if (value) {
        checkBothValidity(value.gte, value.lte, setValid, setInitialized);
      } else {
        setInitialized(true);
      }
    }

    setInput(value || initState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onInputKeyDown = (type: KeyType) => (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      checkValidity(type, input, setValid);
    }
  };

  return (
    <>
      <div className="mb-1">
        <FormattedMessage
          id="reports.jackpot-contribution-bet.text"
          defaultMessage="Jackpot Contribution Bet"
        />
      </div>

      {/* <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.amount.text"
              defaultMessage="Amount"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => onChange(null)}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div> */}

      {/* <div className="mb-1">
        <FormattedMessage id="reports.amount.text" defaultMessage="Amount" />
      </div> */}

      <div className="d-flex">
        <div className="position-relative">
          <StyledInput
            value={input.gte as any}
            name="gte"
            onBlur={() => checkValidity('min', input, setValid)}
            placeholder={intl.formatMessage(messages['minimum.text'])}
            onChange={(e: number | string | undefined | null) => {
              if (e === '') {
                setValid((prev) => ({ ...prev, gte: null }));
                if (input.lte === 0) {
                  setValid((prev) => ({ ...prev, lte: null }));
                  onChange(null);
                }
              }

              setInput((prev: any) => ({
                ...prev,
                gte: e || 0,
              }));
            }}
            disabled={disabled}
            onKeyDown={onInputKeyDown('min') as any}
          />
          {isBoolean(valid.gte) && <CheckIcon correct={valid.gte as any} />}
        </div>
        <div className="d-flex align-items-center">&nbsp;-&nbsp;</div>
        <div className="position-relative">
          <StyledInput
            name="lte"
            onBlur={() => checkValidity('max', input, setValid)}
            value={input.lte as any}
            placeholder={intl.formatMessage(messages['maximum.text'])}
            onChange={(e) => {
              if ((e as any) === '') {
                setValid((prev) => ({ ...prev, lte: null }));
                if (input.gte === 0) {
                  setValid((prev) => ({ ...prev, gte: null }));
                  onChange(null);
                }
              }

              setInput((prev: any) => ({
                ...prev,
                lte: e || 0,
              }));
            }}
            disabled={disabled}
            onKeyDown={onInputKeyDown('max') as any}
          />

          {isBoolean(valid.lte) && <CheckIcon correct={valid.lte as any} />}
        </div>
      </div>
    </>
  );
};

export default JackpotContributionBetMinMax;
