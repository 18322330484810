import gql from 'graphql-tag';

export const DAILY_SELF_EXCLUDED_REPORT = gql`
  query DailySelfExcludedReports(
    $first: Int
    $after: Binary
    $filter: DailySelfExcludedReportFilterInput
  ) {
    dailySelfExcludedReports(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          status
          report {
            uri
          }
        }
      }
    }
  }
`;
export const DAILY_TIMEOUT_REPORTS = gql`
  query DailyTimeoutReports(
    $first: Int
    $after: Binary
    $filter: DailyTimeOutReportFilterInput
  ) {
    dailyTimeOutReports(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          status
          report {
            uri
          }
        }
      }
    }
  }
`;
export const NEWLY_REGISTERED_MEMBERS_REPORTS = gql`
  query NewlyRegisteredMembersReports(
    $first: Int
    $after: Binary
    $filter: NewlyRegisteredMembersReportFilterInput
  ) {
    newlyRegisteredMembersReports(
      first: $first
      after: $after
      filter: $filter
    ) {
      edges {
        node {
          status
          report {
            uri
          }
        }
      }
    }
  }
`;
export const DAILY_TRANSACTION_REPORTS = gql`
  query DailyTransactionReports(
    $first: Int
    $after: Binary
    $filter: DailyTransactionReportFilterInput
  ) {
    dailyTransactionReports(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          status
          report {
            uri
          }
        }
      }
    }
  }
`;
export const DAILY_DEPOSIT_REPORTS = gql`
  query DailyDepositReports(
    $first: Int
    $after: Binary
    $filter: DailyDepositReportFilterInput
  ) {
    dailyDepositReports(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          status
          report {
            uri
          }
        }
      }
    }
  }
`;

export const FULL_CUSTOMER_DUMPS_REPORTS = gql`
  query FullCustomerDumps(
    $first: Int
    $after: Binary
    $filter: FullCustomerDumpFilterInput
  ) {
    fullCustomerDumps(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          status
          report {
            uri
          }
        }
      }
    }
  }
`;

export const DEPOSIT_LIMIT_REPORTS = gql`
  query DepositLimitReports(
    $first: Int
    $after: Binary
    $filter: DepositLimitReportFilterInput
  ) {
    depositLimitReports(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          status
          report {
            uri
          }
        }
      }
    }
  }
`;

export const DAILY_PLAYER_ACTIVITIES_REPORTS = gql`
  query DailyPlayerActivities(
    $first: Int
    $after: Binary
    $filter: DailyPlayerActivityFilterInput
  ) {
    dailyPlayerActivities(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          status
          report {
            uri
          }
        }
      }
    }
  }
`;

export const PLAYER_STATUS_REPORTS = gql`
  query PlayerStatusReports(
    $first: Int
    $after: Binary
    $filter: PlayerStatusReportFilterInput
  ) {
    playerStatusReports(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          status
          report {
            uri
          }
        }
      }
    }
  }
`;
