import gql from 'graphql-tag';

export const GET_MEMBER_IP_ADDRESS_SUMMARY = gql`
  query MemberIPAddressReport(
    $first: Int
    $after: Binary
    $filter: MemberIpAddressReportItemsFilterInput
  ) {
    memberIpAddressReport(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          member {
            id
            username
            brandId
            platformId
            affiliate {
              id
              code
            }
            realName
          }
          ipAddress
          ipAddressLocation {
            city
            country
            state
          }
          lastUsedDateTime
          usageCount
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_MIAS_STRING_BASED = gql`
  query MIAS_IDS(
    $filter: MemberIpAddressReportItemsFilterInput
    $partialFilter: MemberIpAddressReportItemsFilterInput
  ) {
    memberIpAddressReport(filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: memberIpAddressReport(filter: $partialFilter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_MIAS_ID_BASED = gql`
  query MIAS_ID_BASED(
    $username: MembersFilterInput
    $realName: MembersFilterInput
  ) {
    username: members(filter: $username) {
      edges {
        node {
          id
          username
        }
      }
    }
    realName: members(filter: $realName) {
      edges {
        node {
          id
          realName
        }
      }
    }
  }
`;

export const GET_MIAR_USERNAMES = gql`
  query FilterMIARUsernames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;
export const GET_MIAR_REALNAMES = gql`
  query FilterMIARUsernames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          realName
        }
      }
    }
  }
`;

export const GET_MIAR_IP_ADDRESSES = gql`
  query FilterMIARIpAdresses(
    $first: Int
    $after: Binary
    $filter: MemberIpAddressReportItemsFilterInput
  ) {
    memberIpAddressReport(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          ipAddress
        }
      }
    }
  }
`;

export default {};
