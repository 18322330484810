import hocify from 'utils/hocify';
import { useMediaQuery } from 'react-responsive';

const withResponsive = hocify(() => ({
  widthLessThan1440: useMediaQuery({
    maxWidth: 1439,
  }),
  widthMoreThan1440: useMediaQuery({
    minWidth: 1440,
  }),
}));

export default withResponsive;
