import React from 'react';
import { Checkbox } from 'antd';
import messages from 'pages/components/MemberWithdrawals/messages';
import useTranslate from 'utils/useTranslate';

export const complianceTypes = [
  {
    id: 'promoHunter',
    complianceType: 'promoHunterCheck',
  },
  {
    id: 'ipCollisionOffender',
    complianceType: 'ipCollisionOffenderCheck',
  },
  {
    id: 'contraBet',
    complianceType: 'contraBetCheck',
  },
  {
    id: 'martingaleBetOffender',
    complianceType: 'martingaleBetOffenderCheck',
  },
  {
    id: 'oneTimeTurnoverDetection',
    complianceType: 'oneTimeTurnoverDetectionCheck',
  },
];

const WithdrawalComplianceCheckFilter = ({
  compliances,
  onChange,
}: {
  compliances: Record<string, any>;
  onChange: (e: any) => void;
}) => {
  const translate = useTranslate();
  return (
    <div>
      {complianceTypes.map((item) => (
        <Checkbox
          className="mt-1 ml-0 d-block"
          name={item.complianceType}
          checked={compliances[item.complianceType]}
          onChange={(e) => onChange(e)}
          key={item.id}
        >
          {translate(messages[item.id])}
        </Checkbox>
      ))}
    </div>
  );
};

export default WithdrawalComplianceCheckFilter;
