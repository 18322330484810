import * as yup from 'yup';
import thirdPartyProviders from 'constants/thirdPartyProviders';

export default () =>
  yup.object().shape({
    id: yup.string().nullable(),
    name: yup
      .string()
      .trim()
      .required(),
    type: yup.string().required(),
    merchantCode: yup
      .string()
      .trim()
      .when('type', {
        is: (val) => thirdPartyProviders.DIORPAY === val,
        then: yup.string().required(),
      }),
    key: yup
      .string()
      .trim()
      .when('type', {
        is: (val) =>
          thirdPartyProviders.MIDPAY_DIRECT !== val &&
          thirdPartyProviders.DIORPAY2 !== val,
        then: yup.string().required(),
      }),
    encryptionKey: yup
      .string()
      .trim()
      .when('type', {
        is: (val) => thirdPartyProviders.DIORPAY2 === val,
        then: yup.string().required(),
      }),
    merchantId: yup
      .string()
      .trim()
      .when('type', {
        is: (val) => thirdPartyProviders.DIORPAY2 === val,
        then: yup.string().required(),
      }),
    partner: yup
      .string()
      .trim()
      .when('type', {
        is: (val) => thirdPartyProviders.DIORPAY2 === val,
        then: yup.string().required(),
      }),
    midpayDirectApiKey: yup
      .string()
      .trim()
      .when('type', {
        is: (val) => thirdPartyProviders.MIDPAY_DIRECT === val,
        then: yup.string().required(),
      }),
    paymentGateway: yup
      .string()
      .trim()
      .when(['type', 'midpayDirectApiKey'], {
        is: (type, midpayDirectApiKey) =>
          thirdPartyProviders.MIDPAY_DIRECT === type && midpayDirectApiKey,
        then: yup.string().required(),
      }),
    credentials: yup.array().when(['type', 'paymentGateway'], {
      is: (type, paymentGateway) =>
        thirdPartyProviders.MIDPAY_DIRECT === type && paymentGateway,
      then: yup.array(yup.string().required()),
    }),
  });
