import { Result } from 'antd';
import PageLoader from 'components/PageLoader';
import useIsNext from 'hooks/useIsNext';
import AffiliateProgramme from 'pages/components/AffiliateProgramme';
import { AffiliateProvider } from 'pages/components/AffiliateProgramme/context';
import Agent from 'pages/components/Agent';
import BulkUpdate from 'pages/components/BulkUpdate';
import ComplianceCheck from 'pages/components/ComplianceCheck';
import CreatePromo from 'pages/components/CreatePromo';
import EditPromo from 'pages/components/CreatePromo/EditPromo';
import ManagePromo from 'pages/components/ManagePromo';
import DesktopVendorConfiguration from 'pages/components/DesktopVendorConfiguration';
import ExternalMemberDeposits from 'pages/components/ExternalMemberDeposits';
import InternalMemberDeposits from 'pages/components/InternalMemberDeposits';
import IPAddressSummary from 'pages/components/IPAddressSummary';
import MarqueeMessages from 'pages/components/MarqueeMessages';
import MemberAccessAuditHistory from 'pages/components/MemberAccessAuditHistory';
import MemberAccessSummaryReport from 'pages/components/MemberAccessSummaryReport';
import MemberInteractionLogReport from 'pages/components/MemberInteractionLogReport';
import MemberIPAddressSummary from 'pages/components/MemberIpAddressSummary';
import MemberLabels from 'pages/components/MemberLabels';
import MemberMarkerManagement from 'pages/components/MemberMarkerManagement';
import MemberSessionDurationPage from 'pages/components/MemberSessionDuration';
import MembersOnlineManagement from 'pages/components/MembersOnlineManagement';
import MemberSummaryReport from 'pages/components/MemberSummaryReport';
import MemberWithdrawals from 'pages/components/MemberWithdrawals';
import MobileAppConfigurationVendors from 'pages/components/MobileAppConfigurationVendors';
import MobileLandscapeVendorConfiguration from 'pages/components/MobileLandscapeVendorConfiguration';
import MobilePortraitVendorConfiguration from 'pages/components/MobilePortraitVendorConfiguration';
import { NewMemberDetail } from 'pages/components/NewMemberDetail/NewMemberDetail';
import OperatorManagement from 'pages/components/OperatorManagement';
import PaymentMethodListing from 'pages/components/PaymentMethodListing';
import PermissionGroup from 'pages/components/PermissionGroup';
import ProfileSetting from 'pages/components/ProfileSetting';
import Rebates from 'pages/components/Rebates';
import { RebateProvider } from 'pages/components/Rebates/context';
import BalanceServiceRecordReport from 'pages/components/Reports/BalanceServiceRecordReport';
import DailyOperatingReport from 'pages/components/Reports/DailyOperatingReport';
import MemberGameReport from 'pages/components/Reports/MemberGameReport';
import MemberPayoutRecords from 'pages/components/Reports/MemberPayoutRecords';
import RebatePayoutRecords from 'pages/components/Reports/RebatePayoutRecords';
import VendorGameReports from 'pages/components/Reports/VendorGameReports';
import SiteConfigurations from 'pages/components/SiteConfigurations';
import { AutomatedMessage } from 'pages/components/SystemMessage/AutomatedMessage/AutomatedMessage';
import { ManualMessage } from 'pages/components/SystemMessage/ManualMessage/ManualMessage';
import ThirdPartyDepositProvider from 'pages/components/ThirdPartyDepositProvider';
import TodaysInstantFocus from 'pages/components/TodaysInstantFocus';
import VendorManagement from 'pages/components/VendorManagement';
import WithdrawalMethods from 'pages/components/WithdrawalMethods';
import WithdrawalProviders from 'pages/components/WithdrawalProviders';
import PromoPayoutReports from 'pages/components/PromoPayoutReports';
import MemberBetRecords from 'pages/components/Reports/MemberBetRecords';
import BalanceTransactionRecords from 'pages/components/Reports/BalanceTransactionRecords';
import messages from 'messages';
import React from 'react';
import { useAccount } from 'store/accountState';
import { Flag } from 'types/featureFlag';
import { TabId } from 'types/nav';
import useTranslate from 'utils/useTranslate';
import GeoFencingNew from 'pages/components/GeoFencingNew';
import MemberPromoHistory from 'pages/components/MemberPromoHistory/MemberPromoHistory';
import { AlertsAndNotificationsNext } from '../../AlertsAndNotificationsNext/AlertsAndNotificationsNext';
import CommonConfigurations from '../../CommonConfigurations';
import ForceProfileValidation from '../../ForceProfileValidation';
import MemberLoyaltyManagement from '../../MemberLoyaltyManagement';
import MemberManagementConfig from '../../MemberManagementConfig';
import Missions from '../../Missions';
import { NewMemberManagementScreen } from '../../NewMemberManagement/NewMemberManagementScreen';
import EUReports from '../../Reports/EUReports';
import RotatingBanner from '../../RotatingBannerNext/RotatingBanner';

// ================================================= //
// Add here your component. Kindly follow structure. //
// ================================================= //

// Default Format
// [TabId.IDENTIFIER_HERE] : <Component />
// =====================================================
// Next Toggle Enabled Format
// [TabId.IDENTIFIER_HERE]: {
//   current: <Component/>,
//   next: <ComponentNext />
//   flag: FeatureFlag.myscreen_thing_enxt_flag
// }

type TabComponentType =
  | React.ReactNode
  | {
      flag: Flag;
      current: React.ReactNode;
      next: React.ReactNode;
    };

const components: Partial<Record<TabId, TabComponentType>> = {
  // ===[ DASHBOARD ]=== //
  'todays-instant-focus': <TodaysInstantFocus />,

  // ===[ OPERATORS ]=== //
  'account-management': <OperatorManagement />,
  'permission-group': <PermissionGroup />,

  // ===[ MEMBERS ]=== //
  'member-management': <NewMemberManagementScreen />,
  'member-level-management': <MemberMarkerManagement />,
  'member-label-management': <MemberLabels />,
  'members-online': <MembersOnlineManagement />,
  'profile-setting': <ProfileSetting />,
  'bulk-update': <BulkUpdate />,

  // ===[ MEMBERS - REPORTS SUBMENU ]=== //
  'member-access-audit-history': <MemberAccessAuditHistory />,
  'member-ip-address-summary': <MemberIPAddressSummary />,
  'ip-address-summary': <IPAddressSummary />,
  'member-access-summary-report': <MemberAccessSummaryReport />,
  'member-interaction-log-report': <MemberInteractionLogReport />,
  'force-profile-validation': <ForceProfileValidation />,
  'member-management-configuration': <MemberManagementConfig />,
  'member-session-duration': <MemberSessionDurationPage />,

  // ===[ AFFILIATES ]=== //
  'affiliate-programme': (
    <AffiliateProvider>
      <AffiliateProgramme />
    </AffiliateProvider>
  ),
  'affiliate-criteria': <Agent.AffiliateCriteria />,
  'affiliate-request': <Agent.AffiliateRequest />,

  // ===[ DEPOSITS ]=== //
  'deposit-requests-internal': <InternalMemberDeposits />,
  'deposit-requests-external': <ExternalMemberDeposits />,
  'payment-method-listing': <PaymentMethodListing />,
  'deposit-providers-external': <ThirdPartyDepositProvider />,

  // ===[ WITHDRAWALS ]=== //
  'withdrawal-requests': <MemberWithdrawals />,
  'withdrawal-methods': <WithdrawalMethods />,
  'withdrawal-providers-external': <WithdrawalProviders />,
  'compliance-check': <ComplianceCheck />,

  // ===[ PROMO ]=== //
  'create-promo': <CreatePromo />,
  'edit-promo': <EditPromo />,
  'manage-promo': <ManagePromo />,

  // ===[ VIP ]=== //
  vip: (
    <MemberLoyaltyManagement.Provider>
      <MemberLoyaltyManagement.Screens />
    </MemberLoyaltyManagement.Provider>
  ),
  // vip: (
  //   <NextMemberLoyalty.Provider>
  //     <NextMemberLoyalty.Screens />
  //   </NextMemberLoyalty.Provider>
  // ),

  // vip: {
  //   flag: 'nextVip',
  //   next: (
  //     <NextMemberLoyalty.Provider>
  //       <NextMemberLoyalty.Screens />
  //     </NextMemberLoyalty.Provider>
  //   ),
  //   current: (
  //     <MemberLoyaltyManagement.Provider>
  //       <MemberLoyaltyManagement.Screens />
  //     </MemberLoyaltyManagement.Provider>
  //   ),
  // },

  // ===[ REBATES ]=== //
  rebates: (
    <RebateProvider>
      <Rebates />
    </RebateProvider>
  ),

  // ===[ REPORTS ]=== //
  'daily-operating-report': <DailyOperatingReport />,
  'member-bet-records': <MemberBetRecords />,
  'balance-transaction-records': <BalanceTransactionRecords />,

  'vendor-game-reports': <VendorGameReports />,
  'promo-payout-reports': <PromoPayoutReports />,
  'rebates-reports': <RebatePayoutRecords />,
  'member-game-reports': <MemberGameReport />,
  'balance-service-record-report': <BalanceServiceRecordReport />,
  'member-summary-report': <MemberSummaryReport />,
  'eu-reports': <EUReports />,

  // ===[ SYSTEM MANAGEMENT ] === //
  'site-configurations': <SiteConfigurations />,
  'alerts-and-notifications': <AlertsAndNotificationsNext />,
  'mobile-app-config-vendors': <MobileAppConfigurationVendors />,
  'marquee-messages': <MarqueeMessages />,
  'rotating-banner': <RotatingBanner />,
  'vendor-management': <VendorManagement />,
  'geo-fencing': <GeoFencingNew />,
  'mobile-portrait-vendor-configuration': <MobilePortraitVendorConfiguration />,
  'mobile-landscape-vendor-configuration': (
    <MobileLandscapeVendorConfiguration />
  ),
  'desktop-vendor-configuration': <DesktopVendorConfiguration />,
  // 'responsible-gaming': <ResponsibleGaming />,
  'common-configurations': <CommonConfigurations />,

  // ===[ SYSTEM MESSAGE ]=== //
  'manual-message': <ManualMessage />,
  'automated-message': <AutomatedMessage />,

  // ===[ MISSIONS ]=== //
  missions: {
    flag: 'missions',
    next: <Missions />,
    current: null,
  },
  'member-detail': <NewMemberDetail />,
  'member-promo-history': <MemberPromoHistory />,

  // ===[ NO NAVIGATION TAB ]=== //
  'total-number-of-participating-member': <MemberPayoutRecords />,
};

type Props = {
  id: string;
};

export const TabComponent: React.FC<Props> = ({ id }) => {
  const next = useIsNext(components[id]?.flag || '');
  const translate = useTranslate();
  const {
    account: { ldInitialized },
  } = useAccount();

  if (!ldInitialized) {
    return <PageLoader />;
  }
  if (components[id]?.$$typeof) {
    return components[id];
  }
  if (next) {
    return components[id].next;
  }
  if (!components[id].current) {
    return <Result title={translate(messages.PAGE_ERROR)} />;
  }
  return components[id].current;
};
