import React, { createContext, useContext, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import XLSX from 'xlsx';
import coercedGet from 'utils/coercedGet';
import { getTableHeads, getDataMatrix } from './utils';
import {
  TableColumnsType,
  generateSpreadsheetOptions,
  SpreadsheetContextValues,
  SpreadsheetProviderProps,
} from './types';

// Contexts, Hooks and Components
export const SpreadsheetContext = createContext({
  loadSpreadsheetQuery: () => {},
  generateSpreadsheet: () => {},
  spreadsheetQueryLoading: false,
  dataList: [],
  tableColumns: [],
  setTableColumns: () => {},
  isError: false,
  dataCount: 0,
  hasNextPage: false,
  cursor: '',
  resetGeneratedData: () => {},
} as SpreadsheetContextValues);

export const useSpreadsheetContext = () => useContext(SpreadsheetContext);

const SpreadsheetProvider: React.FC<SpreadsheetProviderProps> = ({
  children,
  options,
}) => {
  const { query, edgesPath } = options;
  const [tableColumns, setTableColumns] = useState<TableColumnsType>([]);
  const [isError, setIsError] = useState(false);
  const [dataList, setDataList] = useState<Record<string, any>[]>([]);

  const initialReportItemValues = {
    hasNextPage: false,
    cursor: '',
    dataCount: 0,
  };

  const [reportItems, setReportItems] = useState(initialReportItemValues);

  const { hasNextPage, cursor, dataCount } = reportItems;

  // pages Query for Spreadsheet Download
  const [loadSpreadsheetQuery, { loading, data }] = useLazyQuery(query, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const totalCountPath = edgesPath.replace(/.edges/g, '.totalCount');
      const pageInfoPath = edgesPath.replace(/.edges/g, '.pageInfo');

      const { hasNextPage: nextPage, endCursor } = coercedGet(
        res,
        pageInfoPath,
        {}
      );
      const totalCount = coercedGet(res, totalCountPath, 0);

      setReportItems((prev) => ({
        ...prev,
        hasNextPage: nextPage,
        cursor: endCursor,
        dataCount: totalCount,
      }));

      const rawData = get(data, edgesPath, []);

      if (dataList.length !== totalCount) {
        setDataList((prev) => [...prev, ...rawData]);
      }
    },
    onError: () => {
      setIsError(true);
    },
  });

  const resetGeneratedData = () => {
    setReportItems(initialReportItemValues);

    setDataList([]);
  };

  // Actions for spreadsheet file generation
  const generateSpreadsheet = ({
    filename,
    extension,
  }: generateSpreadsheetOptions) => {
    const { columnHeads, dataPropertiesToBeRendered, merges } = getTableHeads(
      tableColumns
    );
    const dataMatrix = getDataMatrix(dataList, dataPropertiesToBeRendered);
    const cellsToBeRendered = [...columnHeads, ...dataMatrix];
    const worksheet = XLSX.utils.aoa_to_sheet(cellsToBeRendered);

    if (!worksheet['!merges']) worksheet['!merges'] = [];
    worksheet['!merges'] = [...merges];

    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, filename);
    XLSX.writeFile(newWorkbook, `${filename}.${extension}`);
  };

  return (
    <SpreadsheetContext.Provider
      value={{
        loadSpreadsheetQuery,
        spreadsheetQueryLoading: loading,
        dataList,
        generateSpreadsheet,
        tableColumns,
        setTableColumns,
        isError,
        dataCount,
        hasNextPage,
        cursor,
        resetGeneratedData,
      }}
    >
      {children}
    </SpreadsheetContext.Provider>
  );
};

export default SpreadsheetProvider;
