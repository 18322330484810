import React, { useState } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Menu, Tooltip, Input, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import styled from 'styled-components';
import OperatorProfileModal from 'components/OperatorProfileModal';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { ALink } from 'components/ALink/ALink';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import { DATE_TIME_FORMAT } from 'constants/date';

const UpdateButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
`;

type MemberNoteType = {
  node: Record<string, any>;
  togglePinned: (val: any, idx: any) => void;
  onDeleteNote: (val: any) => void;
  noteWordsLimit: number;
  onUpdateNote: (val: any, idx: any) => void;
  currentNotesCount: number;
  currentNoteIndex: number;
};
const MemberNote = ({
  node,
  togglePinned,
  onDeleteNote,
  noteWordsLimit,
  onUpdateNote,
  currentNotesCount,
  currentNoteIndex,
}: MemberNoteType) => {
  const translate = useTranslate();
  const [maxWordError, setMaxWordError] = useState(false);
  const [editState, setEditState] = useState(false);
  const [editText, setEditText] = useState(undefined);
  const [profileState, setProfileState] = useState(false);

  const { MEMBER_NOTES_PERMISSION } = useNewMemberDetailPermissions();

  const onEdit = (note: Record<string, any>) => {
    setEditText(note.note);
    setEditState(true);
  };

  const onValueChange = (value: any) => {
    setEditText(value);

    if (value) {
      const numOfWords = value?.match(/\S+/g).length;
      if (numOfWords > noteWordsLimit) {
        setMaxWordError(true);
      } else {
        setMaxWordError(false);
      }
    }
  };

  const deleteNoteConfirm = (id: string) => {
    Modal.confirm({
      title: translate(messages['delete-member-note-title.text']),
      content: translate(messages['delete-member-note-body.text']),
      okText: translate(messages.DELETE),
      okType: 'danger',
      cancelText: translate(messages.CANCEL),
      onOk() {
        onDeleteNote(id);
      },
    });
  };

  const menu = (note: Record<string, any>) => (
    <Menu>
      <Menu.Item
        disabled={!MEMBER_NOTES_PERMISSION.ALLOWED_EDIT}
        key="0"
        onClick={() => onEdit(note)}
      >
        <span>
          <FormattedMessage
            id="member-detail.edit-note.text"
            defaultMessage="Edit"
          />
        </span>
      </Menu.Item>
      {/* eslint-disable-next-line no-use-before-define */}
      <Menu.Item
        disabled={!MEMBER_NOTES_PERMISSION.ALLOWED_DELETE}
        key="1"
        onClick={() => deleteNoteConfirm(note.id)}
      >
        <span>
          <FormattedMessage
            id="member-detail.delete-note.text"
            defaultMessage="Delete"
          />
        </span>
      </Menu.Item>
    </Menu>
  );

  const updateNote = () => {
    setEditState(false);
    onUpdateNote(node, editText);
  };

  return (
    <>
      {!editState && (
        <div>
          <h4 className="mb-1">
            <span className="text-muted-light font-weight-normal fs-12">
              <Tooltip
                title={translate(
                  messages[node.pinned ? 'pinned.text' : 'click-to-pin.text']
                )}
              >
                <LegacyIcon
                  type="pushpin"
                  className="cursor-pointer"
                  onClick={() => togglePinned(node.id, node.pinned)}
                  style={{
                    color: '#1890ff',
                    padding: '0',
                    height: '16px',
                    width: '16px',
                  }}
                  theme={node.pinned ? 'filled' : 'outlined'}
                />
              </Tooltip>{' '}
              <span className="fw-600">{node.header}</span>:{' '}
              <span style={{ whiteSpace: 'pre-line' }}>{node.note}</span>
            </span>
          </h4>
          <div className="d-flex justify-content-space-between">
            <div className="d-flex fs-12">
              <ALink onClick={() => setProfileState(true)}>
                {node.creator.role === 'ADMIN'
                  ? node.creator.company
                  : node.creator.username}
              </ALink>
              <span className="mx-1"> | </span>
              {moment(node.dateTimeCreated).format(DATE_TIME_FORMAT)}
            </div>

            <Dropdown overlay={menu(node)} trigger={['click']}>
              <EllipsisOutlined />
            </Dropdown>
          </div>
        </div>
      )}

      {editState && (
        <div className="position-relative">
          <Form.Item
            validateStatus={maxWordError ? 'error' : undefined}
            help={
              maxWordError
                ? translate(messages['max-words-error.text'], {
                    count: noteWordsLimit,
                  })
                : null
            }
          >
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 6 }}
              value={editText}
              onChange={(e) => onValueChange(e.target.value)}
            />
            <UpdateButton type="link" onClick={updateNote}>
              <FormattedMessage id="update.text" defaultMessage="Update" />
            </UpdateButton>
          </Form.Item>
        </div>
      )}

      {currentNoteIndex !== currentNotesCount - 1 && (
        <Divider className="my-1" />
      )}
      {profileState && (
        <OperatorProfileModal
          userInfo={node}
          modalState={profileState}
          closeModal={() => setProfileState(false)}
        />
      )}
    </>
  );
};

export default MemberNote;
