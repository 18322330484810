import { useLoader, LoaderProps } from 'hooks/useLoader';
import React from 'react';
import {
  BATCH_REAL_NAME,
  BATCH_AFFILIATE,
  BATCH_VIP_TIER,
  BATCH_MARKER,
  BATCH_EFFECTIVE_BET,
  BATCH_STATUS,
  BATCH_TAGS,
} from './queries';

type ProviderProps = {
  children: any;
  refreshPage: () => void;
  refetchParams: Record<string, any>;
  refetchMemberManagement: any;
};

type contextProps = {
  refreshPage: () => void;
  idToBeRefreshed: string;
  setIdToBeRefreshed: (e: string) => void;
  loader: { batch: {}; reload: {} };
  refetchParams: Record<string, any>;
  refetchMemberManagement: any;
};

const contextDefault = {
  refreshPage: () => {},
  idToBeRefreshed: '',
  setIdToBeRefreshed: () => {},
  loader: { batch: {}, reload: {} },
  refetchParams: {},
  refetchMemberManagement: () => {},
};

const MemberManagementContext = React.createContext<contextProps>(
  contextDefault
);

export const MemberManagementProvider = ({
  children,
  refreshPage,
  refetchParams,
  refetchMemberManagement,
}: ProviderProps) => {
  const [idToBeRefreshed, setIdToBeRefreshed] = React.useState('');
  const [BatchRealName, reloadRealName] = useLoader(BATCH_REAL_NAME);
  const [BatchAffiliate, reloadAffiliate] = useLoader(BATCH_AFFILIATE);
  const [BatchVIPTier, reloadVIPTier, completeVIPTier] = useLoader(
    BATCH_VIP_TIER
  );
  const [BatchMarker, reloadMarker] = useLoader(BATCH_MARKER);
  const [BatchBet, reloadBet, completeEffectiveBet] = useLoader(
    BATCH_EFFECTIVE_BET
  );
  const [BatchStatus, reloadStatus] = useLoader(BATCH_STATUS);
  const [BatchTags, reloadTags, completeTags] = useLoader(BATCH_TAGS);

  const loader = {
    isComplete: {
      completeVIPTier,
      completeTags,
      completeEffectiveBet,
    },
    batch: {
      BatchRealName,
      BatchAffiliate,
      BatchVIPTier,
      BatchMarker,
      BatchBet,
      BatchStatus,
      BatchTags,
    },
    reload: {
      reloadRealName,
      reloadAffiliate,
      reloadVIPTier,
      reloadMarker,
      reloadBet,
      reloadStatus,
      reloadTags,
    },
    reloadAll: (id: string & LoaderProps) => {
      reloadRealName(id);
      reloadAffiliate(id);
      reloadVIPTier(id);
      reloadMarker(id);
      reloadBet(id);
      reloadStatus(id);
      reloadTags(id);
    },
  };

  return (
    <MemberManagementContext.Provider
      value={{
        refreshPage,
        idToBeRefreshed,
        setIdToBeRefreshed,
        loader,
        refetchParams,
        refetchMemberManagement,
      }}
    >
      {children}
    </MemberManagementContext.Provider>
  );
};

export const useMemberManagement = () =>
  React.useContext(MemberManagementContext);
