import React, { useRef, useImperativeHandle, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { Formik, useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/FormItem';
import { formItemHorizontalLayout } from 'constants/form';
import { AffiliateProgrammeLevel } from 'types/graphqlTypes';
import { Root } from './styles';
import { TIER_REQUIREMENT } from '../../../../../../../../constants';

type TierFormType = {
  index: string;
  submitEdit: boolean;
  submit: (val: any, idx: any) => void;
  isLatest: boolean;
  setSubmitEdit: any;
};

const TierForm = ({ index, submitEdit, submit, isLatest }: TierFormType) => {
  const parentForm = useFormikContext() as any;
  const formRef = useRef<any>(null);
  const parentFormValues = parentForm.values;

  const initialValues = {
    ...parentFormValues.levels.find(
      (_: AffiliateProgrammeLevel, idx: any) => idx === index
    ),
  };

  useImperativeHandle(formRef, () => ({
    ...formRef.current,
    handleSubmit: () => submit,
  }));

  useEffect(() => {
    if (submitEdit) {
      const { tierRequirement, tierRequirementOperator } = parentFormValues;
      const lookup =
        tierRequirement.length === 2
          ? tierRequirementOperator
          : tierRequirement[0];
      const {
        current: { values },
      } = formRef;
      submit(lookup, values);
    }
  }, [submitEdit, submit, parentFormValues]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ values, errors, handleChange }) => (
        <Root>
          <Form.Item
            label={
              <b>
                <FormattedMessage
                  id="parameters.text"
                  defaultMessage="Parameters"
                />
              </b>
            }
            {...formItemHorizontalLayout}
            colon={false}
          />
          <FormItem
            label={
              <FormattedMessage
                defaultMessage="Tier Name"
                id="tier-name.text"
              />
            }
            validateStatus={errors && errors.name && 'error'}
            help={errors && errors.name}
          >
            <Input
              type="text"
              name="name"
              style={{ width: '50%' }}
              value={values.name}
              onChange={handleChange}
            />
          </FormItem>
          {parentFormValues.tierRequirement.includes(
            TIER_REQUIREMENT.ACTIVE_MEMBERS
          ) && (
            <>
              <FormItem
                label={
                  <FormattedMessage
                    defaultMessage="Minimum Active Members"
                    id="minimum-active-members.text"
                  />
                }
                validateStatus={
                  errors && errors.minimumActiveMembersCount && 'error'
                }
                help={errors && errors.minimumActiveMembersCount}
              >
                <Input
                  type="number"
                  style={{ width: '50%' }}
                  name="minimumActiveMembersCount"
                  disabled={!isLatest}
                  value={values.minimumActiveMembersCount}
                  onChange={handleChange}
                />
              </FormItem>
              <FormItem
                label={
                  <FormattedMessage
                    defaultMessage="Maximum Active Members"
                    id="maximum-active-members.text"
                  />
                }
                validateStatus={
                  errors && errors.maximumActiveMembers && 'error'
                }
                help={errors && errors.maximumActiveMembers}
              >
                <Input
                  type="number"
                  style={{ width: '50%' }}
                  name="maximumActiveMembers"
                  disabled
                  value={values.maximumActiveMembers}
                  onChange={handleChange}
                />
              </FormItem>
            </>
          )}
          {parentFormValues.tierRequirement.includes(
            TIER_REQUIREMENT.NET_PROFIT
          ) && (
            <>
              <FormItem
                label={
                  <FormattedMessage
                    defaultMessage="Minimum Net Profit"
                    id="minimum-net-profit.text"
                  />
                }
                validateStatus={errors && errors.minimumNetProfit && 'error'}
                help={errors && errors.minimumNetProfit}
              >
                <Input
                  type="number"
                  name="minimumNetProfit"
                  style={{ width: '50%' }}
                  value={values.minimumNetProfit}
                  onChange={handleChange}
                  disabled={!isLatest}
                />
              </FormItem>
              <FormItem
                label={
                  <FormattedMessage
                    defaultMessage="Maximum Net Profit"
                    id="maximum-net-profit.text"
                  />
                }
                validateStatus={errors && errors.maximumNetProfit && 'error'}
                help={errors && errors.maximumNetProfit}
              >
                <Input
                  type="number"
                  style={{ width: '50%' }}
                  name="maximumNetProfit"
                  disabled
                  value={values.maximumNetProfit}
                  onChange={handleChange}
                />
              </FormItem>
            </>
          )}
          <FormItem
            label={
              <FormattedMessage
                defaultMessage="Commission Percentage"
                id="commission-percentage.text"
              />
            }
            validateStatus={errors && errors.percentage && 'error'}
            help={errors && errors.percentage}
          >
            <Input
              type="number"
              min={0}
              max={100}
              style={{ width: '50%' }}
              name="percentage"
              value={values.percentage}
              onChange={handleChange}
            />
          </FormItem>
        </Root>
      )}
    </Formik>
  );
};

export default TierForm;
