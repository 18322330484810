/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery } from '@apollo/react-hooks';

import { Select, ConfigProvider, Empty } from 'antd';
import { debounce, get, uniq } from 'lodash';
import { GamesConnectionEdge } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import { useAccount } from 'store/accountState';

import messages from 'pages/components/Reports/messages';
import { FilterStateType, SetFilterStateType } from '../../../../types';

import { GAME_NAMES } from './queries';
import { StyledSelect, StyledSpan, StyledLabel } from '../../styles';

const { Option } = Select;

type Props = {
  state: FilterStateType;
  setState: SetFilterStateType;
};

const GameName: React.FC<Props> = ({ state, setState }) => {
  const [loadSearch, { data, called, loading }] = useLazyQuery(GAME_NAMES, {
    variables: { first: 10 },
  }) as any;
  const {
    account: { locale },
  } = useAccount();

  if (!called) loadSearch();

  const edges = get(data, 'games.edges', []);

  const options = edges.map((edge: GamesConnectionEdge) => edge.node);

  const handleChange = (e: any) => {
    if (e.length) {
      setState((prev) => ({
        ...prev,
        game_name: { in: e },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        game_name: null,
      }));
    }
  };

  const debouncedRefetch = debounce((textInput) => {
    if (textInput.length === 0) {
      loadSearch({
        variables: {
          first: 10,
          filter: {},
        },
      });
    }

    if (textInput.length > 1) {
      loadSearch({
        variables: {
          first: 10,
          filter: {
            name: {
              contains: textInput,
            },
          },
        },
      });
    }
  }, 500);

  const translate = useTranslate();

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.game-name.text"
              defaultMessage="Game Name"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={translate(messages['no-data.text'])}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        <StyledSelect
          showSearch
          allowClear
          mode="multiple"
          loading={loading}
          placeholder={
            <FormattedMessage
              id="reports.search.text"
              defaultMessage="Search"
            />
          }
          style={{ width: '100%' }}
          // @ts-ignore
          value={state.game_name ? uniq(state.game_name.in) : []}
          onChange={handleChange}
          onSearch={(text) => {
            debouncedRefetch(text);
          }}
          optionFilterProp="label"
        >
          {options.map((option: any) => (
            <Option key={option.id} value={option.id} label={option.name}>
              {locale === 'zh' ? option.nameZh : option.nameEn}
            </Option>
          ))}
        </StyledSelect>
      </ConfigProvider>
    </>
  );
};

export default GameName;
