import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'rebates.publish-rebate-group-success.text': {
    id: 'rebates.publish-rebate-group-success.text',
    defaultMessage: 'Rebate group has been published successfully.',
  },
  'common.rebate-settings.text': {
    id: 'common.rebate-settings.text',
    defaultMessage: 'Rebate settings',
  },
  'common.payout-settings.text': {
    id: 'common.payout-settings.text',
    defaultMessage: 'Payout settings',
  },
  'common.rebate-group.text': {
    id: 'common.rebate-group.text',
    defaultMessage: 'Rebate group',
  },
});

export default messages;
