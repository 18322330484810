import React from 'react';
import InnerForm from './InnerForm';
import { useMemberLoyaltyProgrammeContext } from '../../../../context';

export default React.forwardRef((_props: any, ref) => {
  const [memberLoyaltyState] = useMemberLoyaltyProgrammeContext();
  const { refetchVariables } = memberLoyaltyState;

  return (
    <InnerForm
      loading={false}
      refetchVariables={refetchVariables}
      ref={ref}
      presetValues={{}}
    />
  );
});
