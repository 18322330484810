import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/react-hooks';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { MEMBER_LEVELS } from './queries';
import { StyledSelect } from './styles';

const { Option } = StyledSelect;
interface Props {
  onChange: (e: any) => any;
  value: any;
  disabled: boolean;
}

export const MemberMarkerMultiSelect: React.FC<Props> = ({
  onChange,
  value,
  disabled,
}) => {
  const [filter, setFilter] = useState({
    name: {
      contains: '',
    },
  });
  const translate = useTranslate();

  const { loading, data = { memberLevels: { edges: [] } }, refetch } = useQuery(
    MEMBER_LEVELS,
    {
      variables: {
        filter,
        first: 10,
      },
    }
  );

  const handleSearch = (e: any) => {
    setFilter({
      name: {
        contains: e.toUpperCase(),
      },
    });
  };

  const handleChange = (e: any) => {
    if (e.length) {
      onChange(e);
    } else {
      onChange(null);
    }
  };

  const { edges } = data.memberLevels;

  return (
    <StyledSelect
      mode="multiple"
      value={value || []}
      showSearch
      placeholder={translate(messages['enter-member-marker.text'])}
      onChange={handleChange}
      filterOption={false}
      loading={loading}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled}
      tags={edges}
      onBlur={() =>
        refetch({
          filter: {
            name: {
              contains: '',
            },
          },
          first: 10,
        })
      }
    >
      {edges.map((item: any) => (
        <Option key={item.node.id} value={item.node.value}>
          {item.node.label}
        </Option>
      ))}
    </StyledSelect>
  );
};
