import moment from 'moment';
import { isEmpty, has } from 'lodash';
import { DATE_TIME_FORMAT } from 'constants/date';
import removeNull from './removeNull';

export const DATE_RANGE_KEYS = {
  last7Days: 'last-7-days',
  lastWeek: 'last-week',
  last30Days: 'last-30-days',
  last90Days: 'last-90-days',
  last180Days: 'last-180-days',
};

export const DATE_KEY_PROP = {
  gte: 'gte',
  start: 'start',
};

// dateKeyProp: string, this is to check if the date key being used is on
// gte,lte format or start,end format
export const convertMovableDateToDate = (
  value: string | undefined,
  dateKeyProp?: string
) => {
  const propsToBeUsed = (from: string, to: string) => {
    if (dateKeyProp === DATE_KEY_PROP.gte) {
      return { gte: from, lte: to };
    }

    if (dateKeyProp === DATE_KEY_PROP.start) {
      return { start: from, end: to };
    }

    return { gte: from, lte: to };
  };

  switch (value) {
    case DATE_RANGE_KEYS.last7Days: {
      const from = moment()
        .subtract(7, 'd')
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      const to = moment()
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      return propsToBeUsed(from, to);
    }

    case DATE_RANGE_KEYS.lastWeek: {
      const from = moment()
        .subtract(2, 'weeks')
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      const to = moment()
        .subtract(1, 'weeks')
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      return propsToBeUsed(from, to);
    }

    case DATE_RANGE_KEYS.last30Days: {
      const from = moment()
        .subtract(30, 'd')
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      const to = moment()
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      return propsToBeUsed(from, to);
    }

    case DATE_RANGE_KEYS.last90Days: {
      const from = moment()
        .subtract(90, 'd')
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      const to = moment()
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      return propsToBeUsed(from, to);
    }

    case DATE_RANGE_KEYS.last180Days: {
      const from = moment()
        .subtract(180, 'd')
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      const to = moment()
        .milliseconds(0)
        .seconds(0)
        .toISOString();
      return propsToBeUsed(from, to);
    }

    default:
      return value;
  }

  // if (DATE_RANGE_KEYS.last7Days === value) {
  //   console.log('1');
  //   dateFrom = moment()
  //     .subtract(7, 'd')
  //     .toISOString();
  //   dateTo = moment().toISOString();
  // } else if (DATE_RANGE_KEYS.lastWeek === value) {
  //   console.log('2');
  //   dateTo = moment()
  //     .subtract(1, 'weeks')
  //     .toISOString();
  //   dateFrom = moment()
  //     .subtract(2, 'weeks')
  //     .toISOString();
  // } else if (DATE_RANGE_KEYS.last30Days === value) {
  //   console.log('3');
  //   dateFrom = moment()
  //     .subtract(30, 'd')
  //     .toISOString();
  //   dateTo = moment().toISOString();
  // }
  //
  // return {
  //   gte: dateFrom,
  //   lte: dateTo,
  // };
};

// This is going to be used inside of a useEffect, this is for converting
// movable date to a date, like last week, last 30 days, last 7 days to the
// current date. Example: src/components/LastUpdateFilter/LastUpdateFilter.js
/*
 * filter - Filter object
 * setDateValue - useState setter
 * */
export const movableFilterDateEffect = (
  filter: any,
  setDateValue: any,
  isArray: boolean = false
) => {
  if (!filter || isEmpty(filter)) {
    setDateValue(null);
    return;
  }

  // Filter is an object {gte: value, lte: value}
  if (!isArray) {
    const isADateFilter = !!filter.gte;
    if (isADateFilter) {
      const movableDates = Object.values(DATE_RANGE_KEYS);
      const isMovableDate = movableDates.find((date) => date === filter.gte);

      if (isMovableDate) {
        const transformedValue = convertMovableDateToDate(isMovableDate);
        setDateValue(transformedValue);
      } else {
        setDateValue(filter);
      }
    }
  } else {
    // Filter is an array [gte, lte]
    const movableDates = Object.values(DATE_RANGE_KEYS);
    const isMovableDate = movableDates.find((date) => date === filter[0]);
    if (isMovableDate) {
      const transformedValue = convertMovableDateToDate(
        isMovableDate
      ) as Record<string, any>;
      setDateValue([transformedValue?.gte, transformedValue?.lte]);
    } else {
      setDateValue(filter);
    }
  }
};

export const transformMovableDateFilter = (filters: Record<string, any>) => {
  const dateFilters = removeNull(filters);
  Object.keys(dateFilters).forEach((keyObj) => {
    const dateKeyProp = () => {
      if (has(dateFilters[keyObj], DATE_KEY_PROP.gte)) {
        return DATE_KEY_PROP.gte;
      }
      if (has(dateFilters[keyObj], DATE_KEY_PROP.start)) {
        return DATE_KEY_PROP.start;
      }
      return '';
    };
    const isADateFilter = !!(
      dateKeyProp() && dateFilters[keyObj][dateKeyProp()]
    );

    if (isADateFilter) {
      const dateValues = dateFilters[keyObj];
      const movableDates = Object.values(DATE_RANGE_KEYS);
      const isMovableDate = movableDates.find(
        (date) => date === dateValues[dateKeyProp()]
      );

      if (isMovableDate) {
        dateFilters[keyObj] = convertMovableDateToDate(
          isMovableDate,
          dateKeyProp()
        );
      }
    }
  });
  return dateFilters;
};

export const formatDate = (
  date: string | number,
  format = `${DATE_TIME_FORMAT} A`
) => moment(date).format(format);

export const impartMoveableDate = (
  dateKey: string,
  dateObj: any,
  filtObj: any
) => {
  if (!filtObj[dateKey]) return filtObj;
  return {
    ...filtObj,
    [dateKey]: dateObj.gte && dateObj.lte && dateObj,
  };
};
