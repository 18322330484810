import React, { useState } from 'react';
import { ApolloQueryResult } from 'apollo-client';

import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';

import OperatorsTable from './components/OperatorsTable';
import EditOperator from '../EditOperator';

const JsEditOperator: any = EditOperator; // TODO: delete after refactoring to TS file

type Props = {
  data: Array<{}>;
  loading: boolean;
  refetchVariables: object;
  refetch: () => Promise<ApolloQueryResult<Array<{}>>>;
};

const Operators = ({ loading, data, refetch, refetchVariables }: Props) => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_ACCOUNT_MANAGEMENT
  );

  const [state, setState] = useState({
    isEditOperator: false,
    operatorId: null,
  });

  const { isEditOperator } = state;
  const toggleEdit = (id = null) => {
    setState({
      ...state,
      isEditOperator: !isEditOperator,
      operatorId: id,
    });
  };

  return (
    <>
      {isEditOperator && (
        <JsEditOperator
          id={state.operatorId}
          toggleModal={toggleEdit}
          refetchVariables={refetchVariables}
        />
      )}
      {ALLOWED_LIST && (
        <OperatorsTable
          refetch={refetch}
          toggleEdit={toggleEdit}
          operators={data}
          loading={loading}
        />
      )}
    </>
  );
};

export default Operators;
