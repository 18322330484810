import React from 'react';
import styled from 'styled-components';

interface Props {
  status: string;
}

// eslint-disable-next-line arrow-body-style
export const Status: React.FC<Props> = ({ status }) => {
  return (
    <Container>
      <Circle status={status} />
      {`${status[0]}${status.toLowerCase().slice(1)}`}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.div<{ status: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
  transform: translateY(-1px);
  background: ${({ status }) =>
    // eslint-disable-next-line no-nested-ternary
    status === 'DRAFT'
      ? 'rgb(255, 175, 0)'
      : status === 'ACTIVE'
      ? 'rgb(0, 200, 0)'
      : 'rgb(235, 0, 0)'};
`;
