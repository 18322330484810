import { useQuery } from '@apollo/react-hooks';
import { GET_CUSTOM_FILTERS } from 'graphql/queries/customFilter.query';
import { createContext, useContext, useEffect, useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import { CustomFilter, CustomFiltersConnectionEdge } from 'types/graphqlTypes';
import { TabId } from 'types/nav';
import removeNull from 'utils/removeNull';

export type ContextProps = {
  filter: { [key: string]: any };
  setFilterField: (name: string, value: any) => void;
  setFilter: (value: { [key: string]: any }) => void;
  defaultFilter: { [key: string]: any };
  clearFilter: () => void;
  customFilters: {
    filters: Array<CustomFilter> | [];
    refetch: () => void;
    loading: boolean;
    error: any;
    activeFilterId: string | undefined;
    setActiveFilterId: (id: string | undefined) => void;
  };
};

export const FilterContext = createContext<ContextProps>({
  filter: {},
  setFilterField: () => {},
  setFilter: () => {},
  defaultFilter: {},
  clearFilter: () => {},
  customFilters: {
    filters: [],
    refetch: () => {},
    loading: false,
    error: undefined,
    activeFilterId: undefined,
    setActiveFilterId: () => {},
  },
});

const useFilter = () => useContext(FilterContext);

type Props = {
  initialFilter: { [key: string]: any };
  defaultFilter: { [key: string]: any };
  tabId: TabId;
  context: string;
};

export const useFilterProvider = (props: Props) => {
  const { initialFilter, defaultFilter, tabId, context } = props;
  const { updateTab } = useScreenTabV2();
  const [filterState, setFilterState] = useState(initialFilter);
  const [activeCustomFilterState, setActiveCustomFilterState] = useState<
    string | undefined
  >(undefined);

  const { loading, error, data, refetch } = useQuery(GET_CUSTOM_FILTERS, {
    variables: {
      filter: {
        context: {
          eq: context,
        },
      },
    },
  });

  useEffect(() => {
    updateTab({
      id: tabId,
      updates: {
        filter: filterState,
      },
    });
  }, [filterState, tabId, updateTab]);

  const { customFilters = {} } = data || {};
  const { edges = [] } = customFilters;
  const customFilterNodes = edges.map(
    (edge: CustomFiltersConnectionEdge) => edge.node
  );

  const getCustomFilter = (id: string) => {
    const customFilter = customFilterNodes.filter(
      (item: Partial<CustomFilter>) => item?.id === id
    );
    return customFilter.length ? customFilter[0].filter : null;
  };

  const setFilterField = (name: string, value: any) => {
    setFilterState((prev) => ({
      ...prev,
      [`${name}`]: value,
    }));

    if (activeCustomFilterState) {
      setActiveCustomFilterState(undefined);
    }
  };

  const setFilter = (value: ContextProps['filter']) => {
    setFilterState(value);
    if (activeCustomFilterState) {
      setActiveCustomFilterState(undefined);
    }
  };

  const setActiveCustomFilter = (id: string | undefined) => {
    const filter = id ? getCustomFilter(id) : defaultFilter;
    setActiveCustomFilterState(id);
    setFilterState(filter);
  };

  const clearFilter = () => {
    setFilterState(defaultFilter);
  };

  return {
    defaultFilter: removeNull(defaultFilter),
    filter: removeNull(filterState),
    setFilterField,
    setFilter,
    clearFilter,
    customFilters: {
      filters: customFilterNodes,
      refetch,
      loading,
      error,
      activeFilterId: activeCustomFilterState,
      setActiveFilterId: setActiveCustomFilter,
    },
  };
};

export default useFilter;
