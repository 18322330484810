import { FormattedMessage } from 'react-intl';
import React from 'react';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import CustomSwitch from 'components/CustomSwitch';
import { toFinite } from 'lodash';
import { CustomRegex } from '../../../utils/regex';
import messages from '../../../messages';

export const CONFIG = gql`
  query {
    config {
      memberForceVerificationSettings {
        bankAccountName
        bankAccountNumber
        bankAccountNumberLastDigitsCount
        dateOfBirth
        email
        gender
        mobileNumber
        realName
      }
      memberFormFields {
        ... on BuiltInFormField {
          type
          required
          field
          updatable
          otpVerificationRequired
        }
      }
    }
  }
`;
export const verificationDict = (
  memberFormFields: Array<Record<string, any>>
) =>
  memberFormFields
    .filter(
      (formField: Record<string, any>) =>
        formField.field === 'MOBILE_NUMBER' || formField.field === 'EMAIL'
    )
    .reduce((acc: Record<string, any>, cur: Record<string, any>) => {
      acc[cur.field] = cur.required;
      return acc;
    }, {});
export const ProfileSwitch = ({
  value,
  onChange,
  disabled,
}: {
  value: boolean;
  disabled?: boolean;
  onChange: (e: any) => void;
}) => (
  <CustomSwitch
    textStyle={{}}
    disabled={disabled}
    value={value}
    onChange={(e: boolean) => onChange(e)}
    textHelper={{
      true: <FormattedMessage id="ON" defaultMessage="On" />,
      false: <FormattedMessage id="OFF" defaultMessage="Off" />,
    }}
    activeColor="#3ec988"
  />
);

export const validationSchema = (translate: any) =>
  Yup.object().shape({
    bankAccountNumberLastDigitsCount: Yup.string()
      .matches(
        CustomRegex.onlyNumbers(),
        translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
      )
      .test(
        'bankAccountNumberLastDigitsCount',
        translate(messages.NUMBER_SHOULD_BE_BETWEEN, { min: 3, max: 12 }),
        (value) => {
          const valueTransform = toFinite(value);
          return valueTransform >= 3 && valueTransform <= 12;
        }
      ),
  });
