import React from 'react';
import { Select } from 'antd';
import { FormItem } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/utils';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

type HexopayCardsSelect = {
  errors: Record<string, any>;
  touched: Record<string, any>;
  memberId: string;
  onChange: (ccId: string) => void;
  value: string;
};

const HEXOPAY_CREDIT_CARDS = gql`
  query HexopayCreditCards(
    $first: Int
    $after: Binary
    $filter: HexoPayCreditCardsFilterInput
  ) {
    hexoPayCreditCards(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        node {
          id
          brand
          lastFourDigits
          firstDigit
          expirationMonth
          expirationYear
          disableCard
        }
      }
    }
  }
`;

const HexopayCardsSelect: React.FC<HexopayCardsSelect> = ({
  errors,
  touched,
  memberId,
  onChange,
  value,
}) => {
  const { data } = useQuery(HEXOPAY_CREDIT_CARDS, {
    skip: !memberId,
    variables: {
      first: 50,
      filter: {
        account: {
          eq: memberId,
        },
      },
    },
  });
  const rawData = data?.hexoPayCreditCards?.edges || [];
  const hexopayCreditCards = rawData?.map(
    (raw: { node: Record<string, any> }) => raw.node
  );

  return (
    <FormItem
      label="Choose Card"
      errors={errors}
      touched={touched}
      keyType="hexoPayCreditCard"
    >
      <Select value={value} onChange={onChange}>
        <Select.Option value="" disabled>
          Select a card
        </Select.Option>
        {hexopayCreditCards.map((cc: Record<string, any>) => (
          <Select.Option key={cc.id} value={cc.id} disabled={cc.disableCard}>
            <div>
              {cc.firstDigit}*** **** **** {cc.lastFourDigits}
            </div>
            <div>
              {cc.expirationMonth}/{cc.expirationYear}
            </div>
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
};

export default HexopayCardsSelect;
