import { useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import { STOP_PROCESS_DEPOSIT } from 'graphql/mutations/deposit.mutation';
import { DEPOSIT, DEPOSIT_REQUESTS } from 'graphql/queries/deposit.query';
import messages from 'pages/components/ExternalMemberDeposits/messages';
import React from 'react';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';

const StopProcessing = ({
  id,
  refetchVariables,
  disabled,
}: {
  id: string;
  refetchVariables: Record<string, any>;
  disabled: boolean;
}) => {
  const refetchQueries = [
    {
      query: DEPOSIT,
      variables: { id },
    },
    {
      query: DEPOSIT_REQUESTS,
      variables: refetchVariables,
    },
  ];
  const translate = useTranslate();
  const [stopProcessDeposit, { loading }] = useMutation(STOP_PROCESS_DEPOSIT, {
    onCompleted: (data) => {
      if (data.stopProcessDeposit) {
        message.success(translate(messages.stopDepositProcess));
      }
    },
    variables: { id: '1' },
    refetchQueries,
  });

  return (
    <ALink onClick={() => stopProcessDeposit()} disabled={loading || disabled}>
      {loading ? <Spin /> : null}
      {translate(messages.stopProcess)}
    </ALink>
  );
};

export default StopProcessing;
