import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'withdrawal-method-name.text',
    defaultMessage: 'Withdrawal Method Name',
  },
  sources: {
    id: 'withdrawal-method.payment-sources.text',
    defaultMessage: 'Payment Sources',
  },
  withdrawalsources: {
    id: 'withdrawal-method.payment-sources-new.text',
    defaultMessage: 'Withdrawal Sources',
  },
  withdrawalpaymentmethod: {
    id: 'withdrawal-method.payment-method-new.text',
    defaultMessage: 'Withdrawal Method',
  },
  provider: {
    id: 'withdrawal-method.provider.text',
    defaultMessage: 'Provider',
  },
  paymentmethod: {
    id: 'withdrawal-method.payment-method.text',
    defaultMessage: 'Payment Method',
  },
  name: {
    id: 'payment-method-name.text',
    defaultMessage: 'Payment method name',
  },
  withdrawalmethodname: {
    id: 'withdrawal-method-name.text',
    defaultMessage: 'Withdrawal Method Name',
  },
  withdrawalmethodnameplaceholder: {
    id: 'withdrawal-method-name-placeholder.text',
    defaultMessage: 'Enter withdrawal method name',
  },
  bankname: {
    id: 'withdrawal-method.bank-name.text',
    defaultMessage: 'Bank Name',
  },
  remark: {
    id: 'withdrawal-method-remark.text',
    defaultMessage: 'Withdrawal Method Remark',
  },
  action: {
    id: 'action.text',
    defaultMessage: 'Action',
  },
  excel: {
    id: 'export-excel.text',
    defaultMessage: 'Export excel',
  },
  add: {
    id: 'add-new-withdrawal-method.text',
    defaultMessage: 'Add New Withdrawal Method',
  },
  providername: {
    id: 'provider-name.text',
    defaultMessage: 'Provider name',
  },
  accountinfo: {
    id: 'account-information.text',
    defaultMessage: 'Account information',
  },
  internal: {
    id: 'internal.text',
    defaultMessage: 'Internal',
  },
  thirdparty: {
    id: '3rd-party.text',
    defaultMessage: '3rd party',
  },
  thirdpartygateway: {
    id: 'third-party-gateway.text',
    defaultMessage: '3rd Party Gateway',
  },
  cancel: {
    id: 'cancel.text',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'confirm.text',
    defaultMessage: 'Confirm',
  },
  others: {
    id: 'other-settings.text',
    defaultMessage: 'Other settings',
  },
  hexopay: {
    id: 'hexopay.text',
    defaultMessage: 'Hexopay',
  },
  remarks: {
    id: 'remarks.text',
    defaultMessage: 'Remarks',
  },
  transactionfee: {
    id: 'transaction-fee.text',
    defaultMessage: 'Provider Transaction Fee',
  },
  absolute: {
    id: 'absolute.text',
    defaultMessage: 'Absolute',
  },
  relative: {
    id: 'relative.text',
    defaultMessage: 'Relative',
  },
  enable: {
    id: 'enabled.text',
    defaultMessage: 'Enabled',
  },
  disable: {
    id: 'disabled.text',
    defaultMessage: 'Disabled',
  },
  status: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  banktransfer: {
    id: 'bank-transfer.text',
    defaultMessage: 'Bank Transfer',
  },
  wechat: {
    id: 'wechat.text',
    defaultMessage: 'WeChat',
  },
  alipay: {
    id: 'alipay.text',
    defaultMessage: 'Alipay',
  },
  nickname: {
    id: 'nickname.text',
    defaultMessage: 'Nickname',
  },
  qrcode: {
    id: 'qr-code.text',
    defaultMessage: 'QR code',
  },
  bankacname: {
    id: 'account-name.text',
    defaultMessage: 'Account name',
  },
  bankacnumber: {
    id: 'account-number.text',
    defaultMessage: 'Account number',
  },
  bankacbranch: {
    id: 'branch.text',
    defaultMessage: 'Branch',
  },
  bankaccountry: {
    id: 'country.text',
    defaultMessage: 'Country',
  },
  bankacprovince: {
    id: 'province.text',
    defaultMessage: 'Province',
  },
  bankaccity: {
    id: 'city.text',
    defaultMessage: 'City',
  },
  selectone: {
    id: 'select-one.text',
    defaultMessage: 'Select one',
  },
  countryplaceholder: {
    id: 'type-in-a-country.text',
    defaultMessage: 'Type in a country',
  },
  edit: {
    id: 'edit.text',
    defaultMessage: 'Edit',
  },
  ok: {
    id: 'ok.text',
    defaultMessage: 'OK',
  },
  search: {
    id: 'search.text',
    defaultMessage: 'Search',
  },
  quickSearch: {
    id: 'quick-search.text',
    defaultMessage: 'Quick search',
  },
  withdrawalmethod: {
    id: 'withdrawal-method.text',
    defaultMessage: 'Withdrawal method',
  },
  withdrawalmethodplaceholder: {
    id: 'select-a-withdrawal-method.text',
    defaultMessage: 'Select a withdrawal method',
  },
  nicknameplaceholder: {
    id: 'enter-nickname.text',
    defaultMessage: 'Enter nickname',
  },
  offlineBank: {
    id: 'withdrawal-methods.offline_bank_transfer_withdrawal_method.text',
    defaultMessage: 'Offline Bank',
  },
  resultsFound: {
    id: 'results-found.text',
    defaultMessage: '{count} Results found',
  },
  filterConditions: {
    id: 'filter-conditions.text',
    defaultMessage: 'Filter conditions',
  },
  selectSavedCustomFilter: {
    id: 'select-a-saved-custom-filter.text',
    defaultMessage: 'Select a saved custom filter',
  },
  enterProviderName: {
    id: 'enter-provider-name.text',
    defaultMessage: 'Enter provider name',
  },
  bank: {
    id: 'bank.text',
    defaultMessage: 'Bank',
  },
  enterBankName: {
    id: 'enter-bank-name.text',
    defaultMessage: 'Enter bank name',
  },
  paymentSource: {
    id: 'payment-source.text',
    defaultMessage: 'Payment source',
  },
  unknownpaymentsource: {
    id: 'withdrawal-method.payment-sources.unknown.text',
    defaultMessage: 'Payment Source Unknown',
  },
  unknownmethod: {
    id: 'withdrawal-method.payment-method.unknown.text',
    defaultMessage: 'Payment Method Unkown',
  },
  duplicate: {
    id: 'duplicate.text',
    defaultMessage: 'Duplicate',
  },
  delete: {
    id: 'delete.text',
    defaultMessage: 'Delete',
  },
  successfullyadded: {
    id: 'withdrawal-method-created.text',
    defaultMessage: 'Withdrawal Method created',
  },
  successfullyupdated: {
    id: 'payment-method-updated.text',
    defaultMessage: 'Successfully saved changes',
  },
  areyousure: {
    id: 'are-you-sure.text',
    defaultMessage: 'Are you sure?',
  },
  paymentmethodtype: {
    id: 'payment-method-type.text',
    defaultMessage: 'Payment method type',
  },
  clearAll: {
    id: 'clear-all.text',
    defaultMessage: 'Clear all',
  },
  pleaseEnter: {
    id: 'please-enter.text',
    defaultMessage: 'Please enter',
  },
  saveSearch: {
    id: 'save-search.text',
    defaultMessage: 'Save Search',
  },
  saveChanges: {
    id: 'save-changes.text',
    defaultMessage: 'Save changes',
  },
  filterName: {
    id: 'name.text',
    defaultMessage: 'Name',
  },
  enterName: {
    id: 'enter-name.text',
    defaultMessage: 'Enter name',
  },
  withdrawalMethods: {
    id: 'withdrawal-methods.text',
    defaultMessage: 'Withdrawal methods',
  },
  MANUAL_ADJUSTMENT: {
    id: 'MANUAL_ADJUSTMENT',
    defaultMessage: 'Manual Adjustment',
  },
  PAYMENT_CHANNEL: {
    id: 'payment-channel.text',
    defaultMessage: 'Payment Channel',
  },
  NO_PAYMENT_METHOD_FOUND: {
    id: 'NO_PAYMENT_METHOD_FOUND',
    defaultMessage:
      'No payment method found. Please check provider credentials',
  },
});

export default messages;
