import {
  CREATE_PROMO_PAYOUTS_REPORT,
  CREATE_BALANCE_TRANSACTION_RECORD_REPORT,
  CREATE_MEMBER_BET_RECORD_REPORT,
  EXPORT_MEMBERS,
} from 'graphql/mutations/csv.mutation';
import {
  BALANCE_TRANSACTION_RECORD_REPORT,
  MEMBER_BET_RECORD_REPORT,
  MEMBER_EXPORT_RECORD,
  PROMO_PAYOUTS_REPORT,
} from 'graphql/queries/csv.query';

export const reports = {
  MEMBER_BET_RECORDS: {
    mutation: CREATE_MEMBER_BET_RECORD_REPORT,
    query: MEMBER_BET_RECORD_REPORT,
  },
  MEMBER_MANAGEMENT: {
    mutation: EXPORT_MEMBERS,
    query: MEMBER_EXPORT_RECORD,
  },
  BALANCE_TRANSACTION_RECORDS: {
    mutation: CREATE_BALANCE_TRANSACTION_RECORD_REPORT,
    query: BALANCE_TRANSACTION_RECORD_REPORT,
  },
  PROMO_PAYOUT_RECORDS: {
    mutation: CREATE_PROMO_PAYOUTS_REPORT,
    query: PROMO_PAYOUTS_REPORT,
  },
};
