import { cloneDeep } from 'lodash';

/*
 * If you want to move one element of array to another index
 * This will return a new array with the elements moved.
 * Ex: const data = [1,2,3,4,5,6];
 *     const newData = arrayMove(data, 1, 5)
 *     // newData is [2,3,4,5,1,6]
 *
 * @arrayValue - Your array
 * @fromIdx - the element you want to be moved
 * @toIdx - where you want the element to be placed
 * */
export const arrayMove = <T>(
  arrayValue: T[],
  fromIdx: number,
  toIdx: number
) => {
  const arrayCopy = cloneDeep(arrayValue);
  arrayCopy.splice(toIdx, 0, arrayCopy.splice(fromIdx, 1)[0]);

  return arrayCopy;
};
