import React from 'react';
import enFlag from 'img/en.svg';
import { Avatar } from 'antd';
import { useAccount } from 'store/accountState';
import { Admin } from 'types/graphqlTypes';

function LanguageSelect() {
  const { account: user } = useAccount();
  const attachments = (user?.account as Admin)?.attachments || [];
  const profilePicture = attachments![0]?.uri || enFlag;
  return <Avatar size={28} src={profilePicture} />;
}

export default LanguageSelect;
