import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'antd';
import { useAccount } from 'store/accountState';
import { useHistory } from 'react-router-dom';
import Storage from 'constants/storage';
import { StyledContainer } from './styles';
import SignInForm from './components/SignInForm';
import AuthKeySignIn from './components/AuthKeySignIn';

// eslint-disable-next-line no-unused-vars
function Signin() {
  const { setPortal } = useAccount();
  const history = useHistory();
  useEffect(() => {
    setPortal('bo');
    if (
      !localStorage.getItem(Storage.BO_ACCESS_TOKEN) ||
      !localStorage.getItem(Storage.BO_REFRESH_TOKEN)
    ) {
      localStorage.removeItem(Storage.ACCESS_TOKEN);
    } else {
      localStorage.setItem(
        Storage.ACCESS_TOKEN,
        localStorage.getItem(Storage.BO_ACCESS_TOKEN) || ''
      );
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [authenticatorState, setAuthenticatorState] = useState<
    Record<string, any>
  >({});
  const [currentScreen, setCurrentScreen] = useState(0);

  const warningShown = sessionStorage.getItem('warningShown');

  if (warningShown || warningShown === null) {
    sessionStorage.setItem('warningShown', null as any);
  }

  const storeAuthenticatorState = ({
    otp,
    token,
  }: {
    otp: string;
    token: string;
  }) => {
    setAuthenticatorState({
      otp,
      token,
    });
  };

  const handleNextScreen = () => {
    setCurrentScreen((prevScreen) => prevScreen + 1);
  };

  const { token } = authenticatorState;

  const components = [
    <SignInForm
      handleNextScreen={handleNextScreen}
      storeAuthenticatorState={storeAuthenticatorState}
      history={history}
    />,
    <AuthKeySignIn token={token} history={history} />,
  ];

  return (
    <StyledContainer>
      <Row>
        <Col span={8} offset={8}>
          <div className="d-flex justify-content-center">
            <Card style={{ width: 350 }}>{components[currentScreen]}</Card>
          </div>
        </Col>
      </Row>
    </StyledContainer>
  );
}

export default Signin;
