import { useQuery } from '@apollo/react-hooks';
import MemberLevelButton from 'components/MemberLevelButton';
import ALL_PERMISSIONS from 'constants/permissions3';
import { MEMBER_MANAGEMENT } from 'constants/testIds';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React from 'react';
import PermissionError from 'components/PermissionError';
import { usePermissions } from 'store/accountState';
import useTranslate from 'utils/useTranslate';

type Rec = Record<string, any>;

export const MemberLevelList = ({
  onSelect,
}: {
  onSelect: (e: any) => void;
}) => {
  const translate = useTranslate();

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );

  const { loading, data = {} } = useQuery(GET_MEMBER_LEVELS, {
    variables: {
      filter: {
        status: {
          eq: 'ENABLED',
        },
      },
    },
    skip: !ALLOWED_LIST,
    fetchPolicy: 'network-only',
  });
  if (loading)
    return (
      <div data-testid={MEMBER_MANAGEMENT.memberLevelListLoading}>
        {translate(messages['loading.text'])}
      </div>
    );

  const { memberLevels = {} }: Rec = data;
  const { edges = [] }: Rec = memberLevels;

  const options = edges.map(({ node }: Rec) => node);

  return (
    <PermissionError
      withPermission={ALLOWED_LIST}
      message="Member Marker Management"
      action="List"
    >
      <div
        style={{ maxHeight: '400px', overflowY: 'auto' }}
        data-testid={MEMBER_MANAGEMENT.memberLevelList}
      >
        {options.map((item: { id: string; color: string; name: string }) => (
          <div className="mb-1" key={item.id} data-testid={item.id}>
            <MemberLevelButton
              memberLevelData={item}
              onClick={() => onSelect(item)}
            />
          </div>
        ))}
      </div>
    </PermissionError>
  );
};
