export const AFFLIATE_PROGRAMME_STATUSES = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
  {
    label: 'Draft',
    value: 'DRAFT',
  },
];
