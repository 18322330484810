// import { SearchOutlined } from '@ant-design/icons';
import Layout from 'components/Layout';
import { useFilterValues } from 'contexts/Filters';
import React from 'react';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import SearchSettingsForm from '../SearchSettingsForm';
import FilterItems from '../FilterItems/FilterItems';

const Sidebar = () => {
  const { filters, setPersistedFilters } = useFilterValues();

  return (
    <div>
      <Layout.Sidebar
        quickSearch={{
          filters,
          contextKey: 'member-access-audit-history',
          onFilterChange: setPersistedFilters,
          isNext: true,
          searchSettingsForm: SearchSettingsForm,
          quickSearchId: QuickSearchIds.MEMBER_ACCESS_AUDIT_HISTORY,
        }}
      >
        <FilterItems filters={filters} onFilterChange={setPersistedFilters} />
      </Layout.Sidebar>
    </div>
  );
};

export default Sidebar;
