import React from 'react';
import { Modal, Button } from 'antd';

import { FormattedMessage } from 'react-intl';
import { useCsvValues } from 'contexts/Csv';
import { ErrorHandler } from 'components/ErrorHandler';
import PermissionAlert from 'components/PermissionAlert';
import ModalBody from './ModalBody';

const CsvModal = ({
  permission,
  handleShow,
  handleQuery,
  ...props
}: {
  data?: any[];
  headers?: any[];
  permission: boolean;
  handleShow: () => void;
  handleQuery: () => void;
}) => {
  const { handleStopDownload } = useCsvValues();

  const handleModalClose = () => {
    handleShow();
    handleStopDownload();
  };

  return (
    <Modal
      visible
      maskClosable
      width={416}
      onCancel={handleModalClose}
      footer={
        permission && [
          <Button type="default" key="cancel" onClick={handleModalClose}>
            <FormattedMessage id="reports.close.text" defaultMessage="Close" />
          </Button>,
        ]
      }
    >
      <ErrorHandler>
        <>
          {permission ? (
            <ModalBody handleQuery={handleQuery} {...props} />
          ) : (
            <PermissionAlert />
          )}
        </>
      </ErrorHandler>
    </Modal>
  );
};

export default CsvModal;
