import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { useSelectedRows } from 'contexts/SelectedRows';
import { SendMessage } from 'components/SendMessage/SendMessage';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';

import { useMemberManagement } from 'pages/components/NewMemberManagement/MemberManagementContext';
// import EditMultiVIP from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/EditMultiVIP';
import ImportMembers from 'pages/components/NewMemberManagement/components/ImportMembers';
import BulkEditProfileValidation from 'pages/components/NewMemberManagement/components/BulkEditProfileValidation/BulkEditProfileValidation';
import { NextEditPromoManualAdjustment } from 'pages/components/NewMemberDetail/components/Header/components/EditPromoManualAdjustment/NextEditPromoManualAdjustment';
import DownloadCsvButtonNext from 'components/DownloadCsvButtonNext';
import { reports } from 'components/DownloadCsvButtonNext/reportsValue';
import BulkEditMemberLevelMenu from 'components/BulkEditMemberLevelMenu';
import BulkEditStatusMenu from 'components/BulkEditStatusMenu';
import BulkEditTagMenu from 'components/BulkEditTagMenu';
import BulkForceChangePasswordMenu from 'components/BulkForceChangePasswordMenu';
import BulkForceChangeWithdrawalPasswordMenu from 'components/BulkForceChangeWithdrawalPasswordMenu';
import { StyledMenu } from './styles';

type Rec = Record<string, any>;

const MoreActions = ({
  refetchParams,
  filters,
}: {
  refetchParams: Rec;
  filters: Rec;
}) => {
  const { role, permissions } = usePermissions();
  const {
    ALLOWED_EDIT_LABEL,
    ALLOWED_EDIT_STATUS,
    // ALLOWED_EDIT_VIP,
    ALLOWED_SEND_MESSAGE,
  } = collectPermissions(
    role,
    permissions,
    ['EDIT_BALANCE', 'EDIT_LABEL', 'EDIT_STATUS', 'EDIT_VIP', 'SEND_MESSAGE'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );
  const { refetchMemberManagement } = useMemberManagement();

  const { selectedRows } = useSelectedRows();
  const fullMembers = selectedRows.map((member: Rec) => ({
    key: member.id,
    label: member.username,
  }));

  const {
    ALLOWED_GENERATE_CSV,
    ALLOWED_CSV_DOWNLOAD_BUTTON,
  } = collectPermissions(
    role,
    permissions,
    ['GENERATE_CSV', 'CSV_DOWNLOAD_BUTTON'],
    `${ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT}:REPORT`
  );

  return (
    <Dropdown
      overlay={
        <StyledMenu>
          <StyledMenu.Item key="0" className="p-0">
            <ImportMembers refetchParams={refetchParams} />
          </StyledMenu.Item>

          <StyledMenu.Item key="1" className="p-0">
            <NextEditPromoManualAdjustment
              members={fullMembers}
              // refetchParams={refetchParams}
              bulk
              onSuccess={() => refetchMemberManagement()}
            />
          </StyledMenu.Item>

          {ALLOWED_EDIT_LABEL && (
            <StyledMenu.Item key="2" className="p-0">
              <BulkEditTagMenu
                members={fullMembers}
                onSuccess={() => refetchMemberManagement()}
              />
            </StyledMenu.Item>
          )}

          {ALLOWED_EDIT_STATUS && (
            <StyledMenu.Item key="3" className="p-0">
              <BulkEditStatusMenu
                members={fullMembers}
                onSuccess={() => refetchMemberManagement()}
              />
            </StyledMenu.Item>
          )}

          <StyledMenu.Item key="3" className="p-0">
            <BulkEditProfileValidation members={fullMembers} />
          </StyledMenu.Item>

          <StyledMenu.Item key="4" className="p-0">
            <BulkEditMemberLevelMenu
              members={fullMembers}
              onSuccess={() => refetchMemberManagement()}
            />
          </StyledMenu.Item>

          <StyledMenu.Item key="5" className="p-0">
            <BulkForceChangePasswordMenu
              members={fullMembers}
              refetchParams={refetchParams}
            />
          </StyledMenu.Item>

          <StyledMenu.Item key="6" className="p-0">
            <BulkForceChangeWithdrawalPasswordMenu
              members={fullMembers}
              refetchParams={refetchParams}
            />
          </StyledMenu.Item>

          {/** commented this block to hide VIP on the screen */}
          {/* {ALLOWED_EDIT_VIP && (
            <StyledMenu.Item key="7" className="p-0">
              <EditMultiVIP
                members={selectedRows}
                onSuccess={() => refetchMemberManagement()}
                bulk
              />
            </StyledMenu.Item>
          )} */}

          {ALLOWED_SEND_MESSAGE && (
            <StyledMenu.Item key="8" className="p-0">
              <SendMessage members={fullMembers} isBulk />
            </StyledMenu.Item>
          )}
          <StyledMenu.Item key="8" className="p-0">
            <DownloadCsvButtonNext
              isLink
              filter={filters}
              module={reports.MEMBER_MANAGEMENT}
              allowedDownloadCSV={ALLOWED_CSV_DOWNLOAD_BUTTON}
              allowedGenerateCSV={ALLOWED_GENERATE_CSV}
              permissionErrorMsg="Member Management - CSV Report Permission is required."
            />
          </StyledMenu.Item>
        </StyledMenu>
      }
    >
      <Button>
        <FormattedMessage
          id="more-actions.text"
          defaultMessage="More Actions"
        />
        <DownOutlined className="ml-1" />
      </Button>
    </Dropdown>
  );
};

export default MoreActions;
