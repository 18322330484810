import { MemberLoyaltyLevel } from 'interfaces/user.interface';
import { truncateProgramName } from 'utils/truncateProgramName';

export class MemberManagementFilterFormat {
  static memberLoyalty(
    loyalties: Partial<MemberLoyaltyLevel[]>,
    truncate?: boolean
  ) {
    return loyalties.map((loyalty) => {
      const { id, name: levelName, color, programme } = loyalty!;
      const fullName = `${programme.name || ''} - ${levelName || ''}`;
      let { text: shortName } = truncate
        ? truncateProgramName(programme.name)
        : { text: programme.name };
      shortName += ` - ${levelName}`;

      return {
        id,
        shortName,
        fullName,
        color,
      };
    });
  }
}
