import React, { useState } from 'react';
import PermissionGroupsTable from './components/PermissionGroupsTable';
import EditPermissionGroup from '../EditPermissionGroup';
import DuplicatePermissionGroup from '../DuplicatePermissionGroup';
import DeletePermissionGroup from '../DeletePermissionGroup';

type Props = {
  data: Array<{}>;
  loading: boolean;
  refetchVariables: object;
  formattedFilters: object;
  refetch: () => void;
};

const PermissionGroups = (props: Props) => {
  const { loading, data, refetchVariables, refetch } = props;
  const [state, setState] = useState<any>({
    isEditPermissionGroup: false,
    isDuplicatePermissionGroup: false,
    isDeletePermissionGroup: false,
    permissionGroupId: null,
  });

  const {
    isEditPermissionGroup,
    isDuplicatePermissionGroup,
    isDeletePermissionGroup,
  } = state;

  const toggleEdit = (id = null) => {
    setState({
      ...state,
      isEditPermissionGroup: !isEditPermissionGroup,
      permissionGroupId: id,
    });
  };

  const toggleDuplicate = (id = null) => {
    setState({
      ...state,
      isDuplicatePermissionGroup: !isDuplicatePermissionGroup,
      permissionGroupId: id,
    });
  };

  const toggleDelete = (id = null) => {
    setState({
      ...state,
      isDeletePermissionGroup: !isDeletePermissionGroup,
      permissionGroupId: id,
    });
  };

  return (
    <>
      {isEditPermissionGroup && (
        <EditPermissionGroup
          id={state.permissionGroupId}
          toggleModal={toggleEdit}
          refetchVariables={refetchVariables}
        />
      )}
      {isDuplicatePermissionGroup && (
        <DuplicatePermissionGroup
          id={state.permissionGroupId}
          toggleModal={toggleDuplicate}
          refetch={refetch}
        />
      )}
      {isDeletePermissionGroup && (
        <DeletePermissionGroup
          id={state.permissionGroupId}
          toggleModal={toggleDelete}
          refetch={refetch}
        />
      )}
      <PermissionGroupsTable
        permissionGroups={data}
        loading={loading}
        toggleEdit={toggleEdit as any}
        toggleDuplicate={toggleDuplicate as any}
        toggleDelete={toggleDelete as any}
      />
    </>
  );
};

export default PermissionGroups;
