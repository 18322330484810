import React from 'react';
import RemarksBubble from 'components/RemarksBubble';
import coercedGet from 'utils/coercedGet';
import MemberNotes from '../MemberNotes';
import MemberTagsBubble from '../MemberTagsBubble';

const MemberLabelRemarks = ({ deposit }: { deposit: Record<string, any> }) => {
  const memberId = coercedGet(deposit, 'account.id');
  return (
    <div className="d-flex">
      <div style={{ width: 31.81 }}>
        <MemberTagsBubble memberId={memberId} />
      </div>
      <div style={{ width: 18.52 }}>
        <MemberNotes memberId={memberId} toolTipId="" />
      </div>
      {deposit.remarks && (
        <RemarksBubble
          remarks={deposit.remarks}
          date={deposit.dateTimeProcessed || deposit.dateTimeCreated}
          toolTipId=""
        />
      )}
    </div>
  );
};

export default MemberLabelRemarks;
