import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { UPDATE_CUSTOM_FILTER } from 'graphql/mutations/customFilter.mutation';
import { GET_CUSTOM_FILTERS } from 'graphql/queries/customFilter.query';
import isEmpty from 'lodash/isEmpty';
import messages from 'messages';
import React, { useState } from 'react';
import { TabId } from 'types/nav';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import { SearchSettingsForm } from './SearchSettingsForm';

interface IEditSearchSettings {
  searchSettings: {
    id: string;
    name: string;
    filter: any;
  };
  onClose: (e: any) => void;
}

export const EditSearchSettings = ({
  searchSettings,
  onClose,
}: IEditSearchSettings) => {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const [updateCustomFilter] = useMutation(UPDATE_CUSTOM_FILTER);

  const handleSubmit = async (fields: any) => {
    try {
      setLoading(true);
      if (!isEmpty(removeNull(fields))) {
        await updateCustomFilter({
          variables: {
            id: searchSettings.id,
            input: {
              name: searchSettings.name,
              filter: removeNull(fields),
            },
          },
          refetchQueries: [
            {
              query: GET_CUSTOM_FILTERS,
              variables: {
                filter: {
                  context: {
                    eq: 'system-message' as TabId,
                  },
                },
              },
            },
          ],
        });
        message.success(translate(messages['search-settings-updated.text']));
        setLoading(false);
      } else {
        message.error(
          translate(messages['cannot-update-filter-empty-error.text'])
        );
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <SearchSettingsForm
      isLoading={isLoading}
      onSubmit={handleSubmit}
      initialValues={searchSettings.filter}
      onClose={onClose}
    />
  );
};
