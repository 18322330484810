import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Button, Menu } from 'antd';
import { useFormikContext } from 'formik';
import DeleteTier from '../DeleteTier';
import EditTier from '../EditTier';
import DuplicateTier from '../DuplicateTier';

const ActionsColumn = ({ index }: { index: number }) => {
  const { values } = useFormikContext() as any;

  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item>
            <EditTier index={index} />
          </Menu.Item>
          <Menu.Item disabled={index !== values.levels.length - 1}>
            <DuplicateTier index={index} />
          </Menu.Item>
          <Menu.Item>
            <DeleteTier index={index} />
          </Menu.Item>
        </Menu>
      }
    >
      <Button type="link" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default ActionsColumn;
