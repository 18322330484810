import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { FormattedMessage } from 'react-intl';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <ALink>
        <FormattedMessage id="delete.text" defaultMessage="Delete" />
      </ALink>
    </Menu.Item>
    <Menu.Item key="1">
      <ALink>
        <FormattedMessage id="edit.text" defaultMessage="Edit" />
      </ALink>
    </Menu.Item>
  </Menu>
);

const BulkActions = () => (
  <Dropdown overlay={menu} trigger={['click']} className="mr-2">
    <Button>
      <FormattedMessage id="more-actions.text" defaultMessage="More Actions" />{' '}
      <DownOutlined />
    </Button>
  </Dropdown>
);

export default BulkActions;
