export const TRIGGER_EVENT_NAMES = [
  'WELCOME_EMAIL',
  'SELF_EXCLUSION',
  'PASSWORD_CHANGE',
  'PASSWORD_RESET',
  'ACCOUNT_CLOSURE',
  'TIME_OUT_FACILITY',
  'DEPOSIT_LIMIT_UPDATES',
  'POSSIBLE_LINKAGE',
  'WITHDRAWAL_APPROVED',
  'DEPOSIT_APPROVED',
  'MEMBER_DOCUMENT_CREATED',
  'MEMBER_DOCUMENT_REJECTED',
  'MEMBER_DOCUMENT_APPROVED',
  'MEMBER_VERIFIED',
  'EMAIL_OTP',
  'TRIGGERS',
  'ACCOUNT_CLOSED_LEGACY',
  'FRAUD',
  'OTP_VERIFICATION',
  'ALERT_WEIGHTINGS_AND_COMPOUND_RULES',
  'SELF_ASSESSMENT',
  'SELF_ASSESSMENT_FEEDBACK',
  'GENERATE_TEMPORARY_PASSWORD',
  'INCREASING_CASINO_STAKES',
];

export const TRIGGER_SOURCES = [
  {
    label: 'System Generated',
    value: 'SYSTEM_GENERATED',
  },
  {
    label: 'User Requested',
    value: 'USER_REQUESTED',
  },
];

export const SENT_STATUS = [
  {
    label: 'Success',
    value: true,
  },
  {
    label: 'Failed',
    value: false,
  },
];
