import gql from 'graphql-tag';

export const CREATE_MEMBER = gql`
  mutation CreateMember($input: CreateMemberInput!) {
    createMember(input: $input)
  }
`;

export const DELETE_MEMBER = gql`
  mutation DeleteMember($id: ID!) {
    deleteMember(id: $id)
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UpdateMember($id: ID!, $input: UpdateMemberInput!) {
    updateMember(id: $id, input: $input)
  }
`;

export const CLOSE_MEMBER = gql`
  mutation CloseMember($id: ID!, $input: CloseMemberInput!) {
    closeMember(id: $id, input: $input)
  }
`;

export const SELF_EXCLUDE_MEMBER = gql`
  mutation SelfExcludeMember($id: ID!, $input: SelfExcludeMemberInput!) {
    selfExcludeMember(id: $id, input: $input)
  }
`;

export const TIMEOUT_MEMBER = gql`
  mutation TimeoutMember($id: ID!, $input: TimeOutMemberInput!) {
    timeOutMember(id: $id, input: $input)
  }
`;

export const COMPLETE_MEMBER_PROMO = gql`
  mutation CompleteMemberPromo($id: ObjectId!) {
    completeActivePromo(id: $id)
  }
`;
export const CANCEL_MEMBER_PROMO = gql`
  mutation CancelMemberPromo($id: ObjectId!) {
    cancelActivePromo(id: $id)
  }
`;

export const ENROLL_MEMBER_TO_PROMO = gql`
  mutation EnrollMemberToPromo($input: EnrollMemberToPromoInput!) {
    enrollMemberToPromo(input: $input)
  }
`;
