import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isObjectPropsEmpty } from 'utils/isObjectPropsEmpty/isObjectPropsEmpty';

type Rec = Record<string, any>;

export const ClearAllFilterButton = ({
  initialFilters,
  currentFilters,
  onFilterChange,
  className = '',
  style,
  ...rest
}: {
  style?: Rec;
  className?: string;
  initialFilters: Rec;
  currentFilters: Rec;
  onFilterChange: (e: any) => void;
}) => (
  <>
    {!isObjectPropsEmpty(currentFilters) && (
      <a
        href="#section"
        onClick={() => onFilterChange(initialFilters)}
        {...rest}
        className={className}
        style={style}
      >
        <FormattedMessage id="clear-all.text" defaultMessage="Clear all" />
      </a>
    )}
  </>
);
