import React from 'react';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import { DATE_RANGE_KEYS, movableFilterDateEffect } from 'utils/dateUtils';

const moment = require('moment-timezone');

export type DateFilterType = Partial<{
  eq: string;
  ne: string;
  gt: string;
  gte: string;
  lt: string;
  lte: string;
  in: string[];
  nin: string[];
}> | null;

export type DateStateType = Record<string, DateFilterType>;

type FuntionalStateType = (prev: DateStateType) => DateStateType;

export type SetDateFilterStateType = (
  value: DateStateType | FuntionalStateType
) => void;

export const globalSetDateFilter = (
  setState: SetDateFilterStateType,
  dateField: string,
  currentTimezone?: string
) => (dates: any) => {
  if (dates?.length) {
    const filterDates = currentTimezone
      ? {
          gte: dates[0].tz(currentTimezone).startOf('day'),
          lte: dates[1].tz(currentTimezone).endOf('day'),
        }
      : {
          gte: dates[0].startOf('day'),
          lte: dates[1].endOf('day'),
        };

    setState((prev) => ({
      ...prev,
      [dateField]: filterDates,
    }));

    return;
  }

  setState((prev) => ({
    ...prev,
    [dateField]: null,
  }));
};

export type FilterStateType = Record<string, any>;

// use-case = centralized logic for timezone specific date range filters
export const useSetDateUtils = (
  state: FilterStateType,
  setState: SetDateFilterStateType,
  dateField: string
) => {
  const { currentTimezone } = useReportsTimezone();
  const [dateValue, setDateValue] = React.useState(null) as any;

  const handleChangeDateFilter = globalSetDateFilter(
    setState,
    dateField,
    currentTimezone
  );

  React.useEffect(() => {
    const value = state[dateField];
    movableFilterDateEffect(value, setDateValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // date filter value adapts to the timezone change
  React.useEffect(() => {
    if (dateValue) {
      const filterDates = {
        gte: moment(dateValue.gte)
          .tz(currentTimezone)
          .startOf('day'),
        lte: moment(dateValue.lte)
          .tz(currentTimezone)
          .endOf('day'),
      };

      setState((prev) => ({
        ...prev,
        dateTimeCreated: {
          ...filterDates,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimezone]);

  const handleLastSevenDays = () => {
    const dates = [
      moment()
        .tz(currentTimezone)
        .subtract(7, 'd'),
      moment().tz(currentTimezone),
    ];

    setDateValue({
      gte: dates[0],
      lte: dates[1],
    });

    const filterDates = {
      gte: DATE_RANGE_KEYS.last7Days,
      lte: DATE_RANGE_KEYS.last7Days,
    };
    setState((prev) => ({
      ...prev,
      [dateField]: {
        ...filterDates,
      },
    }));
  };

  const handleLastWeek = () => {
    const dates = [
      moment()
        .tz(currentTimezone)
        .subtract(2, 'w'),
      moment()
        .tz(currentTimezone)
        .subtract(1, 'w'),
    ];

    setDateValue({
      gte: dates[0],
      lte: dates[1],
    });
    const filterDates = {
      gte: DATE_RANGE_KEYS.lastWeek,
      lte: DATE_RANGE_KEYS.lastWeek,
    };
    setState((prev) => ({
      ...prev,
      [dateField]: {
        ...filterDates,
      },
    }));
  };

  const handleLastThirtyDays = () => {
    const dates = [
      moment()
        .tz(currentTimezone)
        .subtract(30, 'd'),
      moment().tz(currentTimezone),
    ];

    setDateValue({
      gte: dates[0],
      lte: dates[1],
    });
    const filterDates = {
      gte: DATE_RANGE_KEYS.last30Days,
      lte: DATE_RANGE_KEYS.last30Days,
    };
    setState((prev) => ({
      ...prev,
      [dateField]: {
        ...filterDates,
      },
    }));
  };

  const handleLastNinetyDays = () => {
    const dates = [
      moment()
        .tz(currentTimezone)
        .subtract(90, 'd'),
      moment().tz(currentTimezone),
    ];

    setDateValue({
      gte: dates[0],
      lte: dates[1],
    });
    const filterDates = {
      gte: DATE_RANGE_KEYS.last90Days,
      lte: DATE_RANGE_KEYS.last90Days,
    };
    setState((prev) => ({
      ...prev,
      [dateField]: {
        ...filterDates,
      },
    }));
  };

  const dateInputValue = dateValue
    ? [
        moment(dateValue.gte).tz(currentTimezone),
        moment(dateValue.lte).tz(currentTimezone),
      ]
    : [];

  return {
    dateInputValue,
    handleLastSevenDays,
    handleLastWeek,
    handleLastThirtyDays,
    handleChangeDateFilter,
    handleLastNinetyDays,
  };
};
