import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Maybe } from 'graphql-tools';
import { useQuery } from 'graphql/client/promoClient';
import {
  GET_MEMBER_ACTIVE_PROMO,
  GET_MEMBER_BALANCE_NEXT,
} from 'graphql/queries/member.query';
import { Account } from 'interfaces/user.interface';
import React, { useState } from 'react';
import { useAccount } from 'store/accountState';
import { Scalars } from 'types/graphqlTypes';
import { AvailableCurrency, Member } from 'types/graphqlTypes-row';
import { MemberPromoStatus } from 'types/promo-graphqlTypes-row';
import createContext from 'utils/createContext';
import { isAdmin } from 'utils/isAdmin';
import isRow from 'utils/isRow';
import { GET_AVAILABLE_CURRENCIES } from './useAvailableCurrencies';

export const GET_MEMBER_ACTIVE_CURRENCY = gql`
  query MemberActiveCurrency($id: ID) {
    member(id: $id) {
      id
      activeCurrency
      currency
    }
  }
`;

interface ActivePromo {
  promo: {
    id: string;
    name: string;
  };
  status: MemberPromoStatus;
  amount: number;
  bonus: number;
  expiresAt: Maybe<Scalars['Date']>;
  turnoverTarget: number;
  turnoverCounter: number;
  currency: string;
  id: string;
}

export interface Wallet {
  casinoBonus: string;
  casinoCash: string;
  mainCash: string;
  sportsBonus: string;
  sportsCash: string;
}

type CurrencyType = 'CRYPTO' | 'FIAT';

export interface WalletCurrency {
  selectedCurrency: string;
  currencyOptions: AvailableCurrency[];
  currencyType: 'CRYPTO' | 'FIAT';
}

type MemberPromo = {
  memberPromo: ActivePromo | null;
  hasActivePromo: boolean;
  activePromoLoading: boolean;
  refetch: Function;
  adminOnly: boolean;
  refetchWalletBalances: Function;
  walletBalance: Wallet | null;
  walletTotalBalance: number;
  walletCurrency: WalletCurrency;
  setWalletCurrency: Function;
  setPromoCurrency: Function;
  promoCurrency: string;
  loadAvailableCurrencies: () => void;
  availableCurrencyLoading: boolean;
  walletBalancesLoading: boolean;
  activeCurrencyLoading: boolean;
  getCurrencyType: (currencyCode: string) => Maybe<CurrencyType> | undefined;
};

type Props = {
  children: React.ReactNode;
  memberId: string;
};

const [useContext, ContextProvider] = createContext<MemberPromo>();

const MemberPromoProvider = ({ children, memberId }: Props) => {
  const {
    account: { account: user },
  } = useAccount();

  // allow only Admin account to use the manual completion of promo(s)
  // remove condition once permissions are available
  const isAdminAcc = isAdmin((user as unknown) as Account);

  const [
    memberActivePromo,
    setMemberActivePromo,
  ] = useState<ActivePromo | null>(null);

  const [walletBalance, setWalletBalance] = useState<Wallet | null>(null);
  const [walletCurrency, setWalletCurrency] = useState<WalletCurrency>({
    selectedCurrency: '',
    currencyOptions: [],
    currencyType: 'FIAT',
  });
  const [promoCurrency, setPromoCurrency] = useState('');

  const [hasActivePromo, setHasActivePromo] = useState(false);

  const getCurrencyType = (currencyCode: string) => {
    const currency = walletCurrency?.currencyOptions?.find(
      (c) => c.code === currencyCode
    );
    return currency?.type;
  };

  const { loading: activePromoLoading, refetch } = useQuery(
    GET_MEMBER_ACTIVE_PROMO,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: memberId,
        language: 'EN',
      },
      skip: !isRow,
      onCompleted: ({ member: { activePromo } }) => {
        setMemberActivePromo(activePromo);
        setHasActivePromo(Boolean(activePromo));
      },
    }
  );

  // wallet balances
  const {
    refetch: refetchWalletBalances,
    loading: walletBalancesLoading,
  } = useQuery(GET_MEMBER_BALANCE_NEXT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: memberId,
      currency: walletCurrency.selectedCurrency,
    },
    skip: !isRow,
    onCompleted: ({ member: { wallets } }) => {
      setWalletBalance(wallets);
    },
  });

  // favorite currency details
  // const [loadMemberFavCurrencies] = useLazyQuery<{ member: Member }>(
  //   GET_MEMBER_FAV_CURRENCIES,
  //   {
  //     fetchPolicy: 'cache-and-network',
  //     variables: {
  //       id: memberId,
  //     },
  //     onCompleted: ({ member: { favoriteCurrencyDetails } }) => {
  //       if (favoriteCurrencyDetails) {
  //         setWalletCurrency({
  //           selectedCurrency: favoriteCurrencyDetails[0]?.code!,
  //           currencyOptions: favoriteCurrencyDetails as CurrencyMetadata[],
  //         });
  //         refetchWalletBalances();
  //       }
  //     },
  //   }
  // );

  const [
    loadMemberActiveCurrency,
    { loading: activeCurrencyLoading },
  ] = useLazyQuery<{ member: Member }>(GET_MEMBER_ACTIVE_CURRENCY, {
    fetchPolicy: 'network-only',
    variables: {
      id: memberId,
    },
    onCompleted: ({ member }) => {
      const filteredCurrencies = walletCurrency.currencyOptions.filter(
        (currency) =>
          currency.code === member.currency || currency.type !== 'FIAT'
      );

      const memberCurrency = member.activeCurrency || member.currency;

      const type = getCurrencyType(memberCurrency);

      if (member.activeCurrency) {
        setWalletCurrency({
          currencyOptions: filteredCurrencies,
          selectedCurrency: memberCurrency,
          currencyType: type as CurrencyType,
        });

        setPromoCurrency(memberCurrency);
      }
    },
  });

  const [
    loadAvailableCurrencies,
    { loading: availableCurrencyLoading },
  ] = useLazyQuery<{
    availableCurrencies: Array<AvailableCurrency>;
  }>(GET_AVAILABLE_CURRENCIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ availableCurrencies }) => {
      setWalletCurrency((prev) => ({
        ...prev,
        currencyOptions: availableCurrencies,
      }));

      refetchWalletBalances();
      loadMemberActiveCurrency();
    },
  });

  const { casinoBonus, casinoCash, mainCash, sportsBonus, sportsCash } =
    walletBalance || {};

  const walletTotalBalance = [
    casinoBonus,
    casinoCash,
    mainCash,
    sportsBonus,
    sportsCash,
  ].reduce((acc, value) => acc + (+value! || 0), 0);

  return (
    <ContextProvider
      value={{
        memberPromo: memberActivePromo,
        hasActivePromo,
        activePromoLoading,
        refetch,
        adminOnly: isAdminAcc,
        refetchWalletBalances,
        walletBalance,
        walletTotalBalance,
        setWalletCurrency,
        walletCurrency,
        loadAvailableCurrencies,
        availableCurrencyLoading,
        walletBalancesLoading,
        setPromoCurrency,
        promoCurrency,
        activeCurrencyLoading,
        getCurrencyType,
      }}
    >
      {children}
    </ContextProvider>
  );
};

export default MemberPromoProvider;
export const useMemberPromo = () => useContext();
