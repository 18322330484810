import styled from 'styled-components';
import Sortable from 'react-sortablejs';
import { Tag, Button } from 'antd';

export const StyledRecommendedAmount = styled.div<any>`
  padding: 5px 10px;
  border-radius: 4px;
  border: solid 1px
    ${(props) => (props.hasError ? 'red' : 'rgba(0, 0, 0, 0.15)')};
`;

export const StyledSortable = styled(Sortable)`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const StyledTag = styled(Tag)`
  cursor: grab;
  margin: 3px 7px 4px 0;
`;

export const StyledButtons = styled(Button)`
  cursor: grab;
  margin: 3px 7px 4px 0;
`;
