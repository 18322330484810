import React from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { LazyQueryHookOptions } from '@apollo/react-hooks/lib/types';

export function useLazyQueryPromise(
  query: DocumentNode,
  options?: LazyQueryHookOptions
): any {
  const [execute, result] = useLazyQuery(query, {
    ...options,
    fetchPolicy: 'network-only',
  });

  const resolveRef = React.useRef() as any;

  React.useEffect(() => {
    if (result.called && !result.loading && resolveRef.current) {
      resolveRef.current(result);
      resolveRef.current = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.loading, result.called]);

  const queryLazily = React.useCallback(
    (variables, context) => {
      execute({ variables, context });
      return new Promise((resolve) => {
        resolveRef.current = resolve;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [execute]
  );

  return [queryLazily, result];
}
