import React from 'react';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';
import { Table as AntTable, Dropdown, Menu, Spin } from 'antd';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import { useCustomColumnsV2 } from 'SuperAdminMain/store/SuperAdminCustomColumnState/customColumnState';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import useTranslate from 'utils/useTranslate';
import { Footer } from './Footer/Footer';
import { Status } from './Status/Status';
import { useVendorMaintenance } from '../../context';
import { StatusAction } from './Actions/StatusAction';
import ForceKick from './Actions/ForceKick';
import messages from './messages';

export const Table: React.FC = () => {
  const { data, loading, error } = useVendorMaintenance();
  const translate = useTranslate();

  const columns = [
    {
      title: translate(messages.VENDOR_NAME),
      key: 'name',
      dataIndex: 'name',
      render: (vendor: string) => vendor || '-',
      left: 400,
    },
    {
      title: (
        <>
          <span style={{ textDecoration: 'line-through' }}>
            {translate(messages.ADVANCE_BLACKOUT)}
          </span>
          &nbsp; (WIP)
        </>
      ),
      customTitle: translate(messages.ADVANCE_BLACKOUT),
      key: 'advanceBlackout',
      dataIndex: 'advanceBlackout',
      render: (advanceBlackout: string) => advanceBlackout || '-',
    },
    {
      customTitle: translate(messages.FORCE_KICK),
      title: (
        <>
          <span style={{ textDecoration: 'line-through' }}>
            {translate(messages.FORCE_KICK)}
          </span>
          &nbsp; (WIP)
        </>
      ),
      key: 'forceKick',
      dataIndex: 'forceKick',
      render: (forceKick: string) => forceKick || '-',
    },
    {
      customTitle: translate(messages.MAINTENANCE),
      title: (
        <>
          <span style={{ textDecoration: 'line-through' }}>
            {translate(messages.MAINTENANCE)}
          </span>
          &nbsp; (WIP)
        </>
      ),
      key: 'scheduledMaintenance',
      dataIndex: 'scheduledMaintenance',
      render: (scheduledMaintenance: any) =>
        scheduledMaintenance ? (
          <div className="scheduled-maintenance">
            {`${scheduledMaintenance.start}~`}
            <br />
            {scheduledMaintenance.end}
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: translate(messages.STATUS),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => <Status status={status} /> || '-',
    },
    {
      title: translate(messages['actions.text']),
      fixed: 'right',
      key: 'id',
      render: (vendor: any) => (
        <Dropdown
          overlay={
            <StyledMenu>
              <StyledMenu.Item>
                <StatusAction vendor={vendor} />
              </StyledMenu.Item>

              <StyledMenu.Item>
                <ForceKick vendor={vendor} />
              </StyledMenu.Item>
            </StyledMenu>
          }
          placement="bottomRight"
        >
          <EllipsisOutlined
            className="cursor-pointer font-weight-bold"
            style={{ fontSize: '25px' }}
          />
        </Dropdown>
      ),
    },
  ] as any;

  const { loading: loadingCC, filterColumns } = useCustomColumnsV2(
    TabId.VENDOR_MAINTENANCE,
    columns
  );

  if (loadingCC) {
    return <Spin />;
  }

  if (error) return <>Error! {error.message}</>;

  const edges = coercedGet(data, 'vendors.edges', []);
  const vendors = edges.map(({ node }: any) => node);

  return (
    <Container>
      <StyledTable
        dataSource={vendors}
        columns={filterColumns(columns)}
        pagination={false}
        loading={loading || loadingCC}
      />

      <Footer />
    </Container>
  );
};

// ===[ SYTLES ]=== //
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  /* width: calc(100% - 232px - 303px); With calender */
  width: calc(100%);

  .scheduled-maintenance {
    position: absolute;
    transform: translateY(-50%);
  }
`;

const StyledTable = styled(AntTable)`
  height: calc(100% - 48px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  .ant-table-body {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }

  -ms-overflow-style: none;

  tbody tr {
    max-height: 54px !important;
  }

  table tr:nth-child(2n) td {
    background-color: #fcfcfc;
  }
`;

const StyledMenu = styled(Menu)`
  li {
    padding: 0;
  }

  button {
    text-align: left;
    color: #000000a6;
  }
`;
