import { useQuery } from '@apollo/react-hooks';
import { Tag } from 'antd';
import { GET_PERMISSION_GROUP } from 'graphql/queries/permissionGroup.query';
import React from 'react';

const PermissionGroupTag = ({
  onClose,
  permissionGroupId,
  closable,
}: {
  onClose: (e: any) => void;
  permissionGroupId: string;
  closable: boolean;
}) => {
  const { loading, data } = useQuery<{
    permissionGroup: {
      name: string;
    };
  }>(GET_PERMISSION_GROUP, {
    variables: {
      id: permissionGroupId,
    },
  });
  if (loading) return <></>;
  return (
    <Tag closable={closable} onClose={onClose}>
      {data?.permissionGroup?.name}
    </Tag>
  );
};

export default PermissionGroupTag;
