import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Flex } from '../../../../../shared/styles/Flex';
import { CustomSwitch } from '../../../../../shared/components/CustomSwitch/CustomSwitch';

type Props = {
  vendorName: string;
  list: { name: string; checked: boolean }[];
  removeVendor: (e: string) => void;
  searchInput: string;
};

export const VendorVerticalListBox = ({
  vendorName,
  list,
  removeVendor,
  searchInput,
}: Props) => {
  const partOfSearchedInput = vendorName
    .toLowerCase()
    .includes(searchInput.toLowerCase());

  return !partOfSearchedInput ? (
    <></>
  ) : (
    <div style={{ minWidth: '144px' }}>
      <Flex justifyContent="space-around">
        <span className="fw-600 text-truncate">{vendorName}</span>
        <Popover
          placement="right"
          content={
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <p
                className="cursor-pointer mb-0"
                onClick={() => removeVendor(vendorName)}
              >
                <FormattedMessage id="delete.text" defaultMessage="Delete" />
              </p>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              {/* <p */}
              {/*  style={{}} */}
              {/*  className="cursor-pointer mb-0" */}
              {/*  onClick={() => console.log('paste pre')} */}
              {/* > */}
              {/*  <FormattedMessage */}
              {/*    id="paste-to-all-ggr-charge.text" */}
              {/*    defaultMessage="Paste to All GGR Charge" */}
              {/*  /> */}
              {/* </p> */}
            </>
          }
        >
          <EllipsisOutlined
            className="cursor-pointer font-weight-bold"
            style={{ fontSize: '25px' }}
          />
        </Popover>
      </Flex>
      <section className="mt-1" style={{ border: '1px solid #e6e6e6' }}>
        {list.map((data, idx) => (
          <div
            className="d-flex justify-content-space-between px-2"
            style={{
              height: '60px',
              background: idx % 2 !== 0 ? '#f5f5f5' : 'white',
            }}
          >
            <Flex alignItems="center">{data.name}</Flex>

            <div className="d-flex flex-direction-column justify-content-space-around align-items-end">
              <Popover
                placement="right"
                content={
                  <>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <p
                      className="cursor-pointer"
                      onClick={() => console.log('Copy GGR Charge')}
                    >
                      <FormattedMessage
                        id="copy-ggr-charge.text"
                        defaultMessage="Copy GGR Charge"
                      />
                    </p>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <p
                      className="mb-0 cursor-pointer"
                      onClick={() => console.log('Paste GGR Charge')}
                    >
                      <FormattedMessage
                        id="paste-ggr-charge.text"
                        defaultMessage="Paste GGR Charge"
                      />
                    </p>
                  </>
                }
              >
                <EllipsisOutlined
                  className="cursor-pointer font-weight-bold"
                  style={{ opacity: 0.5, fontSize: '20px' }}
                />
              </Popover>

              <CustomSwitch
                textStyle={{}}
                disabled={false}
                onChange={() => {}}
                value={data.checked}
                textHelper={null}
                activeColor="#3ec988"
                checkedChildren={
                  <FormattedMessage id="on.text" defaultMessage="On" />
                }
                unCheckedChildren={
                  <FormattedMessage id="off.text" defaultMessage="Off" />
                }
              />
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};
