import { Col, Row } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line consistent-return
export const generalSettingsRender = (selectedBanner: Record<string, any>) => {
  if (!selectedBanner) {
    return <p>No data...</p>;
  }

  if (selectedBanner.link) {
    return (
      <div className="px-3">
        <Row className="mt-3">
          <Col span={4} style={{ color: '#999' }}>
            <FormattedMessage id="redirect.text" defaultMessage="Redirect" />
          </Col>
          <Col span={19} className="pl-2">
            <FormattedMessage
              id="url-link-to-launch.text"
              defaultMessage="URL for link to launch"
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col span={4} style={{ color: '#999' }}>
            <FormattedMessage id="url-link.text" defaultMessage="URL Link" />
          </Col>
          <Col span={19} className="pl-2">
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={selectedBanner.link} target="_blank">
              {selectedBanner.link}
            </a>
          </Col>
        </Row>
      </div>
    );
  }

  if (selectedBanner.promo) {
    return (
      <div className="px-3">
        <Row className="mt-3">
          <Col span={6} style={{ color: '#999' }}>
            <FormattedMessage id="redirect.text" defaultMessage="Redirect" />
          </Col>
          <Col span={17} className="pl-2">
            <FormattedMessage
              id="promo-name.text"
              defaultMessage="Promo Name"
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <div style={{ color: '#999' }}>
            <FormattedMessage
              id="promo-content.text"
              defaultMessage="Promo Content"
            />
          </div>
          <div
            /* eslint-disable react/no-danger */
            dangerouslySetInnerHTML={{
              __html: selectedBanner.promo.description,
            }}
            className="mt-3"
          />
        </Row>

        <Row className="mt-3">
          <div style={{ color: '#999' }}>
            <FormattedMessage
              id="promo-terms-and-conditions.text"
              defaultMessage="Promo Terms and Conditions"
            />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: selectedBanner.promo.termsAndConditions,
            }}
            className="mt-3"
          />
        </Row>
      </div>
    );
  }
};
