import styled from 'styled-components';
import { Modal, Input } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 832px;
    height: 488px;
    transform: translateX(-216px);
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-header {
    height: 56px;
  }
`;

export const StyledInput = styled(Input)<{ isActive: boolean; ref: any }>`
  outline: none;
  box-shadow: ${({ isActive }) =>
    isActive ? '0 0px 3px 1px #1f89e5;' : 'none'};
  border: ${({ isActive }) =>
    isActive ? '1px solid #1f89e5 !important' : '1px solid #d9d9d9'};
  border-radius: 4px;
`;

export const StyledInputArea = styled(Input.TextArea)<{
  isActive: boolean;
  ref: any;
}>`
  outline: none;
  box-shadow: ${({ isActive }) =>
    isActive ? '0 0px 3px 1px #1f89e5;' : 'none'};
  border: ${({ isActive }) =>
    isActive ? '1px red #1f89e5' : '1px solid #d9d9d9'};
  border-radius: 4px;
`;

export const EditContainer = styled.div`
  display: flex;

  .column-1 {
    position: relative;

    .grid {
      width: 608px;
      height: 368px;
      border-bottom: 1px solid #d9d9d9;
      margin: 0;
      padding: 16px 24px 16px 16px;

      display: grid;
      grid-column-gap: 24px;

      .item-1 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .item-2 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
      }

      .item-3 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 3;
        grid-row-end: 4;
      }

      .item-4 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .item-5 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
      }

      .item-6 {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
      }
    }

    .footer-buttons {
      display: flex;
      position: absolute;
      align-items: center;
      height: 64px;
      right: 16px;

      button {
        margin-right: 8px;
      }
    }
  }

  .column-2 {
    width: 224px;
    height: 432px;
    padding: 16px;
    border-left: 1px solid #d9d9d9;

    .variable {
      display: flex;
      flex-wrap: wrap;

      .ant-tag {
        margin: 5px 4px;
        /* padding: 3px 8px; */

        font-size: 12px;
        color: #000000a6;
      }
    }
  }

  .status {
    font-size: 10px;
  }

  .language-title {
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
  }

  .apply-language {
    padding-top: 10px;
  }

  input {
    height: 40px;
  }

  textarea {
    resize: none;
  }
`;
