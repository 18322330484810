export default [
  {
    id: '1',
    name: 'Slot',
    type: 'SLOT',
    vendors: ['CQ9', 'JDB', 'SAGAMING_TRANSFER', 'SAGAMING'],
    color: '#00C49F',
  },
  // {
  //   id: '2',
  //   name: 'Casino',
  //   type: 'CASINO',
  //   vendors: ['JDB', 'SAGAMING_TRANSFER', 'KENOGAME'],
  // },
  {
    id: '3',
    name: 'Fish',
    type: 'FISH',
    vendors: ['CQ9', 'SAGAMING_TRANSFER'],
    color: '#FFBB28',
  },
  {
    id: '3',
    name: 'LIVE',
    type: 'LIVE',
    vendors: [],
    color: '#FF8042',
  },
  {
    id: '4',
    name: 'Sports Book',
    type: 'SPORTSBOOK',
    vendors: ['HC', 'SAGAMING_TRANSFER'],
    color: '#8E44AD',
  },
  {
    id: '5',
    name: 'Lottery',
    type: 'LOTTERY',
    vendors: [],
    color: '#D24D57',
  },
];

export const GameTypes = {
  SPORTSBOOK: 'SPORTSBOOK',
  LIVE: 'LIVE',
  LOTTERY: 'LOTTERY',
  SLOT: 'SLOT',
  TABLE: 'TABLE',
  POKER: 'POKER',
  FISH: 'FISH',
  SCRATCH: 'SCRATCH',
  CASUAL: 'CASUAL',
  VIRTUAL: 'VIRTUAL',
  BLACKJACK: 'BLACKJACK',
  VIDEO_SLOTS: 'VIDEO_SLOTS',
  ROULETTE: 'ROULETTE',
  VIDEO_POKER: 'VIDEO_POKER',
  LIVE_BACCARAT: 'LIVE_BACCARAT',
  LIVE_BLACKJACK: 'LIVE_BLACKJACK',
  LIVE_ROULETTE: 'LIVE_ROULETTE',
  LIVE_POKER: 'LIVE_POKER',
  LIVE_GAME_SHOWS: 'LIVE_GAME_SHOWS',
  OTHER_TABLE_GAMES: 'OTHER_TABLE_GAMES',
  LIVE_DRAGON_TIGER: 'LIVE_DRAGON_TIGER',
  BACCARAT: 'BACCARAT',
  DICE: 'DICE',
  JACKPOT_SLOTS: 'JACKPOT_SLOTS',
  OTHER_LIVE_GAMES: 'OTHER_LIVE_GAMES',
  SCRATCH_CARDS: 'SCRATCH_CARDS',
  VIRTUAL_SPORTS: 'VIRTUAL_SPORTS',
  LIVE_DICE: 'LIVE_DICE',
  BINGO: 'BINGO',
  ARCADE_GAMES: 'ARCADE_GAMES',
  DRAGON_TIGER: 'DRAGON_TIGER',
  HILO: 'HILO',
  CRASH_GAMES: 'CRASH_GAMES',
  CRASH: 'CRASH',
  LIVE_DEALER: 'LIVE_DEALER',
};

export const allGameTypes = Object.values(GameTypes);

export const GameTypeNames = {
  [GameTypes.SLOT]: 'Slot',
  [GameTypes.LIVE]: 'Live Casino',
  [GameTypes.POKER]: 'Poker',
  [GameTypes.FISH]: 'Fish',
  [GameTypes.SPORTSBOOK]: 'Sportsbook',
  [GameTypes.LOTTERY]: 'Lottery',
  [GameTypes.TABLE]: 'Table',
  [GameTypes.VIRTUAL]: 'Virtual',
  [GameTypes.BLACKJACK]: 'Blackjack',
  [GameTypes.VIDEO_SLOTS]: 'Video Slots',
  [GameTypes.ROULETTE]: 'Roulette',
  [GameTypes.VIDEO_POKER]: 'Video Poker',
  [GameTypes.LIVE_BACCARAT]: 'Live Baccarat',
  [GameTypes.LIVE_BLACKJACK]: 'Live Blackjack',
  [GameTypes.LIVE_ROULETTE]: 'Live Roulette',
  [GameTypes.LIVE_POKER]: 'Live Poker',
  [GameTypes.LIVE_GAME_SHOWS]: 'Live Game Shows',
  [GameTypes.OTHER_TABLE_GAMES]: 'Other Table Games',
  [GameTypes.LIVE_DRAGON_TIGER]: 'Live Dragon Tiger',
  [GameTypes.BACCARAT]: 'Baccarat',
  [GameTypes.DICE]: 'Dice',
  [GameTypes.JACKPOT_SLOTS]: 'Jackpot Slots',
  [GameTypes.OTHER_LIVE_GAMES]: 'Other Live Games',
  [GameTypes.SCRATCH_CARDS]: 'Scratch Card',
  [GameTypes.VIRTUAL_SPORTS]: 'Virtual Sports',
  [GameTypes.LIVE_DICE]: 'Live Dice',
  [GameTypes.BINGO]: 'Bingo',
  [GameTypes.ARCADE_GAMES]: 'Arcade Games',
  [GameTypes.DRAGON_TIGER]: 'Dragon Tiger',
  [GameTypes.HILO]: 'Hilo',
  [GameTypes.CRASH_GAMES]: 'Crash Games',
  [GameTypes.CRASH]: 'Crash',
  [GameTypes.LIVE_DEALER]: 'Live Dealer',
};

export const GameTypesTranslation = {
  [GameTypeNames.SLOT]: 'slot.text',
  [GameTypeNames.LIVE]: 'live-casino.text',
  [GameTypeNames.POKER]: 'poker.text',
  [GameTypeNames.FISH]: 'fish.text',
  [GameTypeNames.SPORTSBOOK]: 'sportsbook.text',
  [GameTypeNames.LOTTERY]: 'lottery.text',
  [GameTypeNames.TABLE]: 'table.text',
  [GameTypeNames.VIRTUAL]: 'virtual.text',
  [GameTypeNames.BLACKJACK]: 'blackjack.text',
  [GameTypeNames.VIDEO_SLOTS]: 'video-slots.text',
  [GameTypeNames.ROULETTE]: 'routlette.text',
  [GameTypeNames.VIDEO_POKER]: 'video-poker.text',
  [GameTypeNames.LIVE_BACCARAT]: 'live-baccarat.text',
  [GameTypeNames.LIVE_BLACKJACK]: 'live-blackjack.text',
  [GameTypeNames.LIVE_ROULETTE]: 'live-roulette.text',
  [GameTypeNames.LIVE_POKER]: 'live-poker.text',
  [GameTypeNames.LIVE_GAME_SHOWS]: 'live-game-shows.text',
  [GameTypeNames.OTHER_TABLE_GAMES]: 'other-table-games.text',
  [GameTypeNames.LIVE_DRAGON_TIGER]: 'live-dragon-tiger.text',
  [GameTypeNames.BACCARAT]: 'baccarat.text',
  [GameTypeNames.DICE]: 'dice.text',
  [GameTypeNames.JACKPOT_SLOTS]: 'jackpot-slots.text',
  [GameTypeNames.OTHER_LIVE_GAMES]: 'other-live-games.text',
  [GameTypeNames.SCRATCH_CARDS]: 'scratch-cards.text',
  [GameTypeNames.VIRTUAL_SPORTS]: 'virtual-sports.text',
  [GameTypeNames.LIVE_DICE]: 'live-dice.text',
  [GameTypeNames.BINGO]: 'bingo.text',
  [GameTypeNames.ARCADE_GAMES]: 'arcade-games.text',
  [GameTypeNames.DRAGON_TIGER]: 'dragon-tiger.text',
  [GameTypeNames.HILO]: 'hilo.text',
  [GameTypeNames.CRASH_GAMES]: 'crash-games.text',
  [GameTypeNames.CRASH]: 'crash.text',
  [GameTypeNames.LIVE_DEALER]: 'live-dealer.text',
};
