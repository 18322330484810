import useTranslate from 'utils/useTranslate';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { ALink } from 'components/ALink/ALink';
import { Divider } from 'antd';
import React from 'react';
import gql from 'graphql-tag';
import { MissionTask } from 'types/graphqlTypes';
import { GetAllValuesMission, useMissionsValue } from '../../context';

export const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};
const stepNumberMapping = (stepNumber: number) => {
  const mapping = {
    1: 'GENERAL_SETTINGS',
    2: 'TASK_AND_REWARD_SETTINGS',
    3: 'MILESTONE_SETTINGS',
    4: 'VIP_GROUPS',
  };
  return mapping[stepNumber];
};
export const StepCard = ({ stepNumber }: { stepNumber: number }) => {
  const translate = useTranslate();
  const { setStepCurrent } = useMissionsValue();
  return (
    <section className="mt-1" style={{ width: '30%' }}>
      <p className="mb-0 fs-16 text-black--85">
        <FormattedMessage id="STEP" defaultMessage="Step" /> {stepNumber}
      </p>
      <p className="mb-0 text-black--45 fs-16">
        {translate(messages[stepNumberMapping(stepNumber)])}
      </p>
      <ALink className="fs-16" onClick={() => setStepCurrent(stepNumber - 1)}>
        <FormattedMessage id="EDIT" defaultMessage="Edit" />
      </ALink>
      <Divider style={{ height: '2px', margin: '10px 0' }} />
    </section>
  );
};
export const CREATE_MISSION = gql`
  mutation CreateMission($input: CreateMissionInput!) {
    createMission(input: $input)
  }
`;

export const formatGeneralSettings = (
  getAllValues: () => GetAllValuesMission,
  selectedNewMission: string
) => {
  const generalSettings: any = {
    ...getAllValues().generalSettingsValue,
    type: selectedNewMission,
  };

  if (generalSettings.registeredIpSetting) {
    generalSettings.maximumEnrolleesCountPerIpAddress = null;
  }

  if (generalSettings.nameSetting) {
    generalSettings.maximumEnrolleesCountPerName = null;
  }

  generalSettings.enrollmentStartDate = generalSettings.enrolmentDateRange[0].format(
    'YYYY-MM-DD'
  );
  generalSettings.enrollmentEndDate = generalSettings.enrolmentDateRange[1].format(
    'YYYY-MM-DD'
  );
  delete generalSettings.enrolmentDateRange;
  delete generalSettings.nameSetting;
  delete generalSettings.registeredIpSetting;
  return generalSettings;
};

export const formatTaskReward = (
  getAllValues: () => GetAllValuesMission,
  selectedNewMission: string
) => {
  const taskReward: any = {
    ...getAllValues().taskRewardValue,
  };

  if (selectedNewMission === 'DAILY_MISSION') {
    taskReward.dayRewardAmount = +taskReward.dayRewardAmount;
    taskReward.dayRewardTurnoverRequirementMultiplier = +taskReward.dayRewardTurnoverRequirementMultiplier;
  }
  taskReward.days = taskReward.days.map((day: { tasks: MissionTask[] }) => ({
    tasks: day.tasks.map((task) => {
      const taskValue: MissionTask = {
        ...task,
        rewardAmount: +task.rewardAmount,
        turnoverRequirementMultiplier: +task.turnoverRequirementMultiplier,
        amount: task?.amount ? +task.amount : undefined,
      };
      if (!taskReward.dayRewardEnabled) {
        delete taskValue.rewardPoints;
      }
      return taskValue;
    }),
  }));
  return taskReward;
};

export const formatMilestone = (
  getAllValues: () => GetAllValuesMission,
  milestoneHide: boolean
) => ({
  ...getAllValues().milestonesValue,
  milestones: milestoneHide
    ? []
    : getAllValues().milestonesValue.milestones.map((milestone) => ({
        ...milestone,
        rewardAmount: +milestone.rewardAmount,
        turnoverRequirementMultiplier: +milestone.turnoverRequirementMultiplier,
      })),
});

export const formatVipGroups = (getAllValues: () => GetAllValuesMission) => {
  const vipGroups = getAllValues()?.vipGroupsValue.memberLoyaltyGroups.map(
    (vip) => ({
      ...vip,
      milestoneRewardMultiplier: +vip.milestoneRewardMultiplier,
      taskMultiplier: +vip.taskMultiplier,
      taskRewardMultiplier: +vip.taskRewardMultiplier,
      excludedMemberTags: vip.excludedMemberTags!.map(
        (tag: Record<string, any>) => tag.id
      ),
      qualifiedMemberLoyaltyLevels: vip.qualifiedMemberLoyaltyLevels.map(
        (memberLoyalty: Record<string, any>) => memberLoyalty.id
      ),
    })
  );

  return {
    memberLoyaltyGroups: vipGroups,
  };
};
