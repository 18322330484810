import React from 'react';
import styled from 'styled-components';
import NextPrevButton from 'components/NextPrevButton';
import { useAccountManagementValue } from 'SuperAdminMain/components/AccountManagementNew/context';
import getPageInfo from 'utils/getPageInfo';
import getTotalCount from 'utils/getTotalCount';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const NavigateButton: React.FC<Props> = () => {
  const { page, setPage, data, loading } = useAccountManagementValue();
  const after = getPageInfo(data)?.endCursor || undefined;
  const hasNextPage = getPageInfo(data)?.hasNextPage || false;
  const totalPage = Math.ceil(getTotalCount(data) / page.first || 0) || 1;

  return (
    <Container>
      <NextPrevButton
        loading={loading}
        onPrev={() => {
          page.savedCursor.pop();
          setPage({
            ...page,
            currentPage: page.currentPage - 1,
            after: page.savedCursor[page.savedCursor.length - 1],
          });
        }}
        disablePrev={page.currentPage + 1 === 1}
        onNext={() => {
          setPage({
            ...page,
            after,
            currentPage: page.currentPage + 1,
            savedCursor: [...page.savedCursor, after],
          });
        }}
        disableNext={!hasNextPage}
        label={`${page.currentPage + 1} / ${totalPage}`}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin: 0 16px;
`;
