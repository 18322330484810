import { EllipsisOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Dropdown, Menu, Spin, Table } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import { AUTOMATED_MESSAGES } from 'graphql/queries/automatedMessage.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePermissions } from 'store/accountState';
import { StyledCenter } from 'styles';
import { customFormatMessage } from 'utils/customFormatMessage';
import { EditMessage } from '../../components/EditMessage/EditMessage';
import { MessageTooltip } from '../../components/MessageTooltip/MessageTooltip';
import { Switch, switchType } from '../../components/Switch/Switch';
import { messages } from '../messages';

const events = [
  'VIP_UPGRADE',
  'VIP_DOWNGRADE',
  'VIP_UPGRADE_AUTOPAYOUT',
  'VIP_BIRTHDAY_AUTOPAYOUT',
  'VIP_DAILY_AUTOPAYOUT',
  'VIP_WEEKLY_AUTOPAYOUT',
  'VIP_MONTHLY_AUTOPAYOUT',
  'VIP_ANNUAL_AUTOPAYOUT',
];

const variables = {
  tierChanges: [
    'Username',
    'RealName',
    'VIPProgram',
    'PreviousTier',
    'CurrTier',
    'ActionDateTime',
  ],
  autoPayoutOn: [
    'Username',
    'RealName',
    'VIPProgram',
    'CurrTier',
    'ActionDateTime',
    'Amount',
  ],
};

export const VIP: React.FC = () => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_TOGGLE_STATE, ALLOWED_EDIT } = collectPermissions(
    role,
    permissions,
    ['TOGGLE_STATE', 'EDIT'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MESSAGES.SYSTEM_MESSAGES}:AUTOMATIC_MESSAGE`
  );

  const { formatMessage } = useIntl();
  const translate = (messageVal: any, opts = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  const statuses = {
    VIP_UPGRADE: translate(messages['upgraded.text']),
    VIP_DOWNGRADE: translate(messages['downgraded.text']),
    VIP_UPGRADE_AUTOPAYOUT: translate(messages['upgrade-award.text']),
    VIP_BIRTHDAY_AUTOPAYOUT: translate(messages['birthday-award.text']),
    VIP_DAILY_AUTOPAYOUT: translate(messages['daily-award.text']),
    VIP_WEEKLY_AUTOPAYOUT: translate(messages['weekly-award.text']),
    VIP_MONTHLY_AUTOPAYOUT: translate(messages['monthly-award.text']),
    VIP_ANNUAL_AUTOPAYOUT: translate(messages['annual-award.text']),
  };

  const queryParameters = {
    filter: {
      event: {
        in: events,
      },
    },
  };

  const { loading, data, refetch } = useQuery(AUTOMATED_MESSAGES, {
    variables: queryParameters,
  });

  if (loading) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  const {
    automatedMessages: { edges },
  } = data;

  let automatedMessages = edges.map(({ node }: any) => node);
  const result: any = [];

  events.forEach((event: string) => {
    automatedMessages = automatedMessages.filter((item: any) => {
      if (item.event === event) {
        let type = '';
        let myVar = variables.tierChanges;

        switch (event) {
          case 'VIP_UPGRADE':
          case 'VIP_DOWNGRADE':
            type = translate(messages['tier-change.text']);
            break;
          case 'VIP_UPGRADE_AUTOPAYOUT':
          case 'VIP_BIRTHDAY_AUTOPAYOUT':
          case 'VIP_DAILY_AUTOPAYOUT':
          case 'VIP_WEEKLY_AUTOPAYOUT':
          case 'VIP_MONTHLY_AUTOPAYOUT':
          case 'VIP_ANNUAL_AUTOPAYOUT':
            type = translate(messages['auto-payout-on.text']);
            myVar = variables.autoPayoutOn;
            break;
          default:
        }

        const newItem = { ...item };
        newItem.key = newItem.id;
        newItem.type = type;
        newItem.variables = myVar;
        result.push(newItem);
      }
      return !(item.event === event);
    });
  });

  const columns = [
    {
      title: (
        <FormattedMessage
          id="deposit-request.on-off.text"
          defaultMessage="On / Off"
        />
      ),
      dataIndex: 'enabled',
      width: 96,
      render: (enabled: boolean, values: any) =>
        ALLOWED_TOGGLE_STATE && (
          <Switch
            id={values.id}
            type={switchType.TABLE}
            toggle={enabled}
            refetch={refetch}
          />
        ),
    },
    {
      title: <FormattedMessage id="type.text" defaultMessage="Type" />,
      dataIndex: 'type',
      width: 136,
      render: (type: string) => type || '-',
    },
    {
      title: <FormattedMessage id="status.text" defaultMessage="Status" />,
      dataIndex: 'event',
      width: 139,
      render: (event: string) => statuses[event] || '-',
    },
    {
      title: <FormattedMessage id="english.text" defaultMessage="English" />,
      width: 561,
      render: (values: any) => (
        <MessageTooltip
          message={{ title: values.title_en, content: values.content_en }}
        />
      ),
      ellipsis: true,
    },
    {
      title: '',
      fixed: 'right',
      render: (values: any) =>
        ALLOWED_EDIT && (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item className="p-0">
                  <EditMessage
                    status={statuses[values.event]}
                    variables={values.variables}
                    messages={values}
                    refetchParams={queryParameters}
                  />
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
          >
            <EllipsisOutlined
              className="cursor-pointer font-weight-bold mr-2"
              style={{ fontSize: '25px' }}
            />
          </Dropdown>
        ),
    },
  ] as any;

  return (
    <Table
      tableLayout="fixed"
      dataSource={result}
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
    />
  );
};
