import React, { ReactText, useEffect } from 'react';
import { Select } from 'antd';

import { FeatureFlags } from 'constants/featureFlags';
import { PAYMENT_TYPES_DEPOSIT, PAYMENT_TYPES } from 'constants/paymentTypes';
import useIsNext from 'hooks/useIsNext';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { useQuery } from '@apollo/react-hooks';
import { PAYMENT_METHODS } from 'graphql/queries/paymentMethodListing.query';
import { PaymentMethodsConnection } from 'types/graphqlTypes-row';
import { useMethod } from 'store/methodState';
import isRow from 'utils/isRow';

type Props = {
  onChange: (e: Array<string> | string) => void;
  value: Array<string> | string;
  placeHolder?: string;
  disabled?: boolean;
  isInternal?: boolean;
  multiple?: boolean;
};

const { Option } = Select;

const PaymentMethodTypeSelect: React.FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
  isInternal,
}) => {
  const translate = useTranslate();
  const handleChange = (e: string | Array<string>) => {
    onChange(e);
  };
  const internalMethod = useIsNext(FeatureFlags.nextCryptoPaymentMethod)
    ? PAYMENT_TYPES_DEPOSIT.OFFLINE
    : PAYMENT_TYPES_DEPOSIT.OFFLINE.filter(
        (paymentType) => PAYMENT_TYPES.OFFLINE.CRYPTOCURRENCY !== paymentType
      );

  const paymentMethods = {
    internal: internalMethod,
    external: PAYMENT_TYPES_DEPOSIT.ONLINE,
  };

  const data: string[] = isInternal
    ? paymentMethods.internal
    : paymentMethods.external;
  const { data: dataPaymentMethods, loading } = useQuery<{
    paymentMethods: PaymentMethodsConnection;
  }>(PAYMENT_METHODS, {
    variables: {
      filter: {
        enabled: { eq: true },
      },
    },
  });
  const { setPaymentMethodType } = useMethod();
  const dataPaymentMethodType = isInternal
    ? dataPaymentMethods?.paymentMethods.edges.map((d) => ({
        label: d?.node.name,
        value: d?.node.id,
      }))
    : dataPaymentMethods?.paymentMethods.edges.map((d) => ({
        label: d?.node.name,
        value: d?.node.id,
      }));
  useEffect(() => {
    if (dataPaymentMethodType) {
      setPaymentMethodType(dataPaymentMethodType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPaymentMethods]);
  return isRow ? (
    <Select
      data-testid="payment-method-type-select"
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      disabled={disabled || false}
      allowClear
      loading={loading}
      style={{ width: '100%' }}
      filterOption={(input, option) =>
        ((option!.children as unknown) as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    >
      {!loading &&
        dataPaymentMethodType?.map((item: any, key: string | number) => (
          <Option
            key={key}
            value={item.value as ReactText}
            data-testid={`paymentMethodFilter_${item}`}
          >
            {item.label}
          </Option>
        ))}
    </Select>
  ) : (
    <Select
      data-testid="payment-method-type-select"
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
      filterOption={(input, option) =>
        ((option!.children as unknown) as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    >
      {data.map((item: string, key: string | number) => (
        <Option
          key={key}
          value={item}
          data-testid={`paymentMethodFilter_${item}`}
        >
          {translate(messages[`${item}`])}
        </Option>
      ))}
    </Select>
  );
};

export default PaymentMethodTypeSelect;
