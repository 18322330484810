import gql from 'graphql-tag';

export const CREATE_DAILY_SELF_EXCLUDED_REPORT = gql`
  mutation CreateDailySelfExcludedReport(
    $input: CreateDailySelfExcludedReportInput
  ) {
    createDailySelfExcludedReport(input: $input)
  }
`;
export const CREATE_DAILY_TIMEOUT_REPORT = gql`
  mutation CreateDailyTimeoutReport($input: CreateDailyTimeOutReportInput) {
    createDailyTimeOutReport(input: $input)
  }
`;
export const CREATE_NEWLY_REGISTERED_REPORT = gql`
  mutation CreateNewlyRegisteredMembersReport(
    $input: CreateNewlyRegisteredMembersReportInput
  ) {
    createNewlyRegisteredMembersReport(input: $input)
  }
`;

export const CREATE_DAILY_TRANSACTION_REPORT = gql`
  mutation CreateDailyTransactionReport(
    $input: CreateDailyTransactionReportInput
  ) {
    createDailyTransactionReport(input: $input)
  }
`;
export const CREATE_DAILY_DEPOSIT_REPORT = gql`
  mutation CreateDailyDepositReport($input: CreateDailyDepositReportInput) {
    createDailyDepositReport(input: $input)
  }
`;
export const CREATE_FULL_CUSTOMER_DUMP = gql`
  mutation CreateFullCustomerDump($input: CreateFullCustomerDumpInput) {
    createFullCustomerDump(input: $input)
  }
`;

export const CREATE_DEPOSIT_LIMIT_REPORT = gql`
  mutation CreateDepositLimitReport($input: CreateDepositLimitReportInput) {
    createDepositLimitReport(input: $input)
  }
`;

export const CREATE_DAILY_PLAYER_ACTIVITY_REPORT = gql`
  mutation CreateDailyPlayerActivity($input: CreateDailyPlayerActivityInput) {
    createDailyPlayerActivity(input: $input)
  }
`;

export const CREATE_PLAYER_STATUS_REPORT = gql`
  mutation CreatePlayerStatusReport($input: CreatePlayerStatusReportInput) {
    createPlayerStatusReport(input: $input)
  }
`;
