import gql from 'graphql-tag';

export const GAME_TYPES = gql`
  query GameTypes($first: Int, $after: Binary, $filter: GamesFilterInput) {
    games(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          type
        }
      }
    }
  }
`;

export default {};
