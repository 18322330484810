import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member.label-management.update-new-label-success.text': {
    id: 'member.label-management.update-new-label-success.text',
    defaultMessage: 'Label has updated successfully.',
  },
});

export default messages;
