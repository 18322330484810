import React from 'react';
import styled from 'styled-components';
import { Button, DatePicker, Select } from 'antd';
import {
  euReportsOptions,
  Reports,
  reportType,
  useReportsLazyQuery,
  useReportsMutation,
} from 'pages/components/Reports/EUReports/utils';
import moment from 'moment';
import { useInterval, useUpdateEffect } from 'react-use';
import BrandSelect from 'components/BrandSelect';
import { DATE_FORMAT } from 'constants/date';

const Container = styled.div`
  background: white;
  padding: 20px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 200px 350px 200px 200px;
  gap: 1em;
`;

const CustomReport = () => {
  const dateFormat = DATE_FORMAT;
  const [report, setReport] = React.useState<Reports | ''>('');
  const [dateTime, setDateTime] = React.useState<
    [moment.Moment, moment.Moment]
  >([
    moment(new Date(), dateFormat).startOf('day'),
    moment(new Date(), dateFormat).startOf('day'),
  ]);
  const [downloadLink, setDownloadLink] = React.useState('');
  const [isLoading, toggleIsLoading] = React.useState(false);
  const [isRunning, toggleIsRunning] = React.useState(false);
  const [selectedAdmins, setSelectedAdmins] = React.useState<string[]>([]);

  const { callMutation } = useReportsMutation(report);
  const { callQuery, data } = useReportsLazyQuery(report);

  useInterval(
    () => {
      callQuery({
        variables: {
          first: 1,
        },
      });
    },
    isRunning ? 1000 : null
  );

  useUpdateEffect(() => {
    if (data) {
      const type = reportType[report];
      const reportData = data[type]?.edges[0]?.node?.report?.uri;

      if (reportData) {
        setDownloadLink(reportData);
        toggleIsRunning(false);
        toggleIsLoading(false);
      }
    }
  }, [data]);

  const onGenerate = () => {
    setDownloadLink('');
    toggleIsLoading(true);
    const filter = {
      admins: selectedAdmins,
      startDateTime: dateTime![0],
      endDateTime: dateTime![1],
    };
    callMutation({
      variables: {
        input: {
          filter,
        },
      },
    }).then(() => {
      toggleIsRunning(true);
    });
  };

  return (
    <Container>
      <div>Custom Reports</div>

      <div className="mb-3">
        <h4>Brand</h4>
        <BrandSelect
          onSelect={(e) => {
            setSelectedAdmins(e);
          }}
        />
        <span className="fs-11 text-muted">Select at least 1</span>
      </div>
      <GridContainer>
        <div>
          <h4>Report</h4>
          <Select
            style={{ width: '100%' }}
            placeholder="Report Name"
            onChange={(e: Reports) => setReport(e)}
          >
            {euReportsOptions.map((dataReport) => (
              <Select.Option value={dataReport.value}>
                {dataReport.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div>
          <h4>Date/Time Range</h4>
          <DatePicker.RangePicker
            showTime
            value={dateTime}
            onChange={(e: any) => setDateTime(e)}
            format={`${dateFormat}, HH:mm:ss`}
            style={{ width: '350px' }}
          />
        </div>

        <div className="d-flex align-items-end">
          <Button
            className="mr-2 ml-5"
            type="default"
            onClick={onGenerate}
            disabled={!report || isLoading || selectedAdmins.length === 0}
            loading={isLoading}
          >
            {isLoading ? 'Processing...' : 'Generate'}
          </Button>
          <Button href={downloadLink} disabled={!downloadLink} type="primary">
            Download
          </Button>
        </div>
      </GridContainer>
    </Container>
  );
};

export default CustomReport;
