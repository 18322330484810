import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import {
  GET_MIAR_IP_ADDRESSES,
  GET_MIAR_REALNAMES,
  GET_MIAR_USERNAMES,
} from 'graphql/queries/memberIpAddressSummary.query';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import SelectFilterField from 'components/SelectFilterField';
import BrandFilter from 'components/BrandFilter';
import { uniq } from 'lodash';
import { MemberIpAddressReportItemsFilterInput } from 'types/graphqlTypes';
import { blockAlphabets } from 'utils/blockAlphabets';
import { StateFilter } from '../../types';

type Props = {
  filters: Partial<
    MemberIpAddressReportItemsFilterInput & {
      realnames: string[];
      usernames: string[];
    }
  >;
  isCustomFilter?: boolean;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  onFilterChange: (value: DynamicObj) => void;
  stateFilters: StateFilter;
};

const FilterItems = ({
  filters,
  onFilterChange,
  stateFilters,
  isCustomFilter = false,
}: Props) => {
  const memberFilter = filters?.member?.in;

  const memberFilters = {
    ...filters,
    ...(memberFilter?.length && {
      member: { in: uniq(memberFilter) },
    }),
  };

  const realnameValues = {
    member: {
      in: filters?.realnames,
    },
  };
  const usernameValues = {
    member: {
      in: filters?.usernames,
    },
  };

  return (
    <>
      <IdBasedSelectFilterField
        testId="MIAS-username-filter"
        label="Username"
        query={GET_MIAR_USERNAMES}
        queryConnection="members"
        filterFieldName="username"
        filters={isCustomFilter ? usernameValues : memberFilters}
        onChange={(e) => {
          onFilterChange({
            usernames: e,
          });
        }}
        localFilterState={{
          stateFilter: stateFilters as Record<string, string[]>,
          stateFilterName: 'usernames',
        }}
        isCustomFilter={isCustomFilter}
      />
      <IdBasedSelectFilterField
        testId="MIAS-realname-filter"
        label="Real Name"
        query={GET_MIAR_REALNAMES}
        queryConnection="members"
        filterFieldName="realName"
        filters={isCustomFilter ? realnameValues : memberFilters}
        onChange={(e) => {
          onFilterChange({
            realnames: e,
          });
        }}
        localFilterState={{
          stateFilter: stateFilters as Record<string, string[]>,
          stateFilterName: 'realnames',
        }}
        isCustomFilter={isCustomFilter}
      />
      <SelectFilterField
        testId="MIAS-ipAddress-filter"
        label="Ip Address"
        query={GET_MIAR_IP_ADDRESSES}
        queryConnection="memberIpAddressReport"
        filterFieldName="ipAddress"
        filters={filters}
        onChange={(e) => {
          onFilterChange({
            ipAddresses: e,
          });
        }}
        onKeyDown={blockAlphabets}
      />
      <BrandFilter
        testId="MIAS-ipAddress-filter"
        setState={onFilterChange}
        rawHandleOnChange={(e) => {
          onFilterChange({
            brand: e,
          });
        }}
        state={filters as Record<string, any>}
      />
    </>
  );
};

export default FilterItems;
