/* eslint-disable no-unused-expressions */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { Select, ConfigProvider, Empty } from 'antd';
import { debounce, get } from 'lodash';
import {
  MemberBetRecordsConnectionEdge,
  MemberBetRecord,
} from 'types/graphqlTypes';
import messages from 'pages/components/Reports/messages';

import useTranslate from 'utils/useTranslate';
import { MEMBER_BET_RECORD_ROUND } from './queries';
import { StyledSelect } from '../../styles';
import { FilterStateType, SetFilterStateType } from '../../../../types';

const { Option } = Select;

type Props = {
  state: FilterStateType;
  setState: SetFilterStateType;
};

const BetIdFilter: React.FC<Props> = ({ state, setState }) => {
  const defaultVars = { first: 10 };
  const [vars, setVars] = React.useState(defaultVars) as any;

  const resetVars = () => setVars(defaultVars);

  const [loadSearch, { data, loading, called }] = useLazyQuery(
    MEMBER_BET_RECORD_ROUND,
    {
      variables: vars,
    }
  ) as any;

  if (!called) loadSearch();

  const edges = get(data, 'memberBetRecords.edges', []);

  const options = edges.map(
    (edge: MemberBetRecordsConnectionEdge) => edge.node
  );

  const handleChange = (e: Array<string>) => {
    if (e.length) {
      setState((prev: FilterStateType) => ({
        ...prev,
        id: { in: e },
      }));
    } else {
      setState((prev: FilterStateType) => ({
        ...prev,
        id: null,
      }));
    }
  };

  // const [partial, setPartial] = React.useState('');

  const debouncedRefetch = debounce((textInput) => {
    if (textInput.length === 0) {
      loadSearch({
        variables: {
          first: 10,
          filter: {},
        },
      });
      // setPartial('');
    }

    if (textInput.length > 1) {
      loadSearch({
        variables: {
          first: 10,
          filter: {
            id: {
              startsWith: textInput,
            },
          },
        },
      });
    }
  }, 500);

  React.useEffect(() => {
    const idsArr = coercedGet(state, 'id.in', []);

    const optionsArr = options.map((item: any) => coercedGet(item, 'id', null));

    const isOptNotPresent =
      optionsArr.indexOf(idsArr[0]) === -1 && idsArr.length > 0;

    if (isOptNotPresent) {
      setVars({
        first: 10,
        filter: {
          id: {
            in: [...idsArr],
          },
        },
      });
      return;
    }
    resetVars();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // const hasPartial = checkPartial('id', state);
  const translate = useTranslate();
  return (
    <>
      <div className="mb-1">
        <FormattedMessage
          id="reports.vendor-bet-id.text"
          defaultMessage="Vendor Bet ID"
        />
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={translate(messages['no-data.text'])}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        <StyledSelect
          showSearch
          allowClear
          mode="multiple"
          // onFocus={() => setPartial('')}
          // onBlur={() => setPartial('')}
          placeholder={
            <FormattedMessage
              id="reports.search.text"
              defaultMessage="Search"
            />
          }
          loading={loading}
          style={{ width: '100%' }}
          value={state.id ? (state.id.in as string[]) : []}
          onChange={handleChange as any}
          onSearch={(text) => {
            // setPartial(text);
            debouncedRefetch(text);
          }}
        >
          {/* {!hasPartial && partial.length && (
            <Option
              key={11}
              value={`Partial: ${partial}`}
              label={`Partial: ${partial}`}
            >
              <FormattedMessage id="partial.text" defaultMessage="Partial" />:{' '}
              {partial}
            </Option>
          )} */}

          {options.map((option: MemberBetRecord) => (
            <Option key={option.id} value={option.round as any}>
              {option.round}
            </Option>
          ))}
        </StyledSelect>
      </ConfigProvider>
    </>
  );
};

export default BetIdFilter;
