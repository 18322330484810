import coercedGet from 'utils/coercedGet';

export const collateGameFilterFields = (filterObj: Record<string, any>) => {
  const GAME_CATEGORY = coercedGet(filterObj, 'game_category.in', []);
  const GAME_SUBCATEGORY = coercedGet(filterObj, 'game_subCategory.in', []);
  const GAME_NAME = coercedGet(filterObj, 'game_name.in', []);

  const hasGameFilt =
    GAME_CATEGORY.length || GAME_SUBCATEGORY.length || GAME_NAME.length;

  return {
    ...filterObj,
    game_category: null,
    game_subCategory: null,
    game_name: null,
    game: hasGameFilt
      ? {
          in: [...GAME_CATEGORY, ...GAME_SUBCATEGORY, ...GAME_NAME],
        }
      : null,
  };
};
