import React from 'react';
import { Row, Col, Button, Input } from 'antd';
import { StyledSider, StyledContainer } from './styles';
import ProgrammeName from './components/ProgrammeName';
import ToDo from './components/ToDo';
import Status from './components/Status';
import Tiers from './components/Tiers';
import DefaultProgramme from './components/DefaultProgramme';
import NegativeCarry from './components/NegativeCarry';
import Members from './components/Members';

const { Search } = Input;

const Sidebar = (props: any) => (
  <StyledSider
    className="br-1"
    theme="light"
    trigger={null}
    collapsible
    collapsedWidth={0}
    width={232}
    {...props}
  >
    <StyledContainer className="p-4">
      <Search placeholder="Search" />
      <ProgrammeName />
      <ToDo />
      <Status />
      <Tiers />
      <DefaultProgramme />
      <NegativeCarry />
      <Members />
      <Row gutter={16}>
        <Col span={12}>
          <Button block>Clear</Button>
        </Col>
        <Col span={12}>
          <Button type="primary" block>
            Search
          </Button>
        </Col>
      </Row>
    </StyledContainer>
  </StyledSider>
);

export default Sidebar;
