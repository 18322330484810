import React from 'react';
import { Tooltip } from 'antd';
import coercedGet from 'utils/coercedGet';
// import { FormattedMessage } from 'react-intl';

import { ReactComponent as AffiliateIcon } from 'components/assets/icon-agent.svg';

type Props = {
  node: Record<string, any>;
};

const AffiliateStatus: React.FC<Props> = ({ node }): any => {
  const affiliate = coercedGet(node, 'member.affiliate', null);
  const affiliateCode = coercedGet(affiliate, 'code', null);
  return affiliate ? (
    <Tooltip title={<span>{affiliateCode}</span>} className="styled-center">
      <AffiliateIcon />
    </Tooltip>
  ) : (
    '-'
  );
};

export default AffiliateStatus;
