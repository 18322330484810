import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, message, Modal } from 'antd';
import { DEACTIVATE_PROGRAM } from 'graphql/mutations/vip.mutation';
import {
  MEMBER_LOYALTY_PROGRAMMES,
  QUALIFYING_MEMBER_LOYALTY_LEVELS,
} from 'graphql/queries/promo.query';
import { MEMBER_LOYALTY_PROGRAMS } from 'graphql/queries/vip.query';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

type Props = {
  programId: string;
  refetchVariables: any;
};

const DeactivateProgram = ({ programId, refetchVariables }: Props) => {
  const translate = useTranslate();
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const [deactivateProgram, { loading }] = useMutation(DEACTIVATE_PROGRAM, {
    onCompleted: () => {
      message.success(translate(messages.LOYALTY_PROGRAMME_DEACTIVATED));
    },
  }) as any;

  return (
    <>
      <Button type="link" className="text-black" onClick={handleShow} block>
        <FormattedMessage
          id="member-loyalty.deactivate.text"
          defaultMessage="Deactivate"
        />
      </Button>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          cancelButtonProps={{ disabled: loading }}
          okButtonProps={{ loading }}
          onCancel={handleShow}
          onOk={() => {
            deactivateProgram({
              variables: {
                id: programId,
              },
              refetchQueries: [
                {
                  query: MEMBER_LOYALTY_PROGRAMS,
                  fetchPolicy: 'network-only',
                  variables: refetchVariables,
                },
                {
                  query: QUALIFYING_MEMBER_LOYALTY_LEVELS,
                  fetchPolicy: 'network-only',
                },
                {
                  query: MEMBER_LOYALTY_PROGRAMMES,
                  fetchPolicy: 'network-only',
                },
              ],
            });

            handleShow();
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-deactivate.text"
                defaultMessage="Confirm Deactivate"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeactivateProgram;
