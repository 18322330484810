import React from 'react';
import { EllipsisOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { columnAlign } from 'utils/tableAlignment';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import messages from 'messages';

export const BodyRow = (draggingIndex: number) => (
  props: Record<string, any>
) => {
  const {
    isOver,
    connectDragSource,
    connectDropTarget,
    moveRow,
    ...restProps
  } = props;
  const style = { ...restProps.style, cursor: 'move' };
  let { className } = restProps;
  if (isOver) {
    if (restProps.index > draggingIndex) {
      className += ' drop-over-downward';
    }
    if (restProps.index < draggingIndex) {
      className += ' drop-over-upward';
    }
  }
  return connectDragSource(
    connectDropTarget(<tr {...restProps} className={className} style={style} />)
  );
};

export const columns = (
  translate: any,
  onUpdateStatus: (data: Record<string, any>) => void,
  setExistingMarquee: (data: Record<string, any>) => void,
  onDeleteMarqueeMessage: (data: Record<string, any>) => void,
  onDuplicate: (data: Record<string, any>) => void,
  { ALLOWED_TOGGLE_ENABLED, ALLOWED_CREATE, ALLOWED_EDIT, ALLOWED_DELETE }: any
) => [
  {
    title: <></>,
    width: 50,
    align: columnAlign.onRight,
    render: () => <MenuOutlined />,
  },
  {
    title: translate(messages['title.text']),
    align: columnAlign.onLeft,
    dataIndex: 'title',
    key: 'title',
    render: (title: string) => title,
    width: 150,
  },
  {
    title: translate(messages['body.text']),
    align: columnAlign.onCenter,
    dataIndex: 'message',
    key: 'message',
    render: (body: string) => body,
    ellipsis: true,
  },
  {
    title: translate(messages['snippet.text']),
    align: columnAlign.onCenter,
    dataIndex: 'snippet',
    key: 'snippet',
    render: (snippet: string) => snippet,
    ellipsis: true,
  },
  {
    title: translate(messages['status.text']),
    align: columnAlign.onCenter,
    dataIndex: 'enabled',
    width: 150,
    key: 'enabled',
    render: (enabled: string) => <StatusBadge text={enabled} />,
  },
  {
    title: translate(messages.ACTION),
    align: columnAlign.onCenter,
    width: 80,
    dataIndex: 'action',
    key: 'action',
    render: (_: any, allData: Record<string, any>) =>
      (ALLOWED_TOGGLE_ENABLED || ALLOWED_EDIT || ALLOWED_DELETE) && (
        <Dropdown
          overlay={
            <Menu>
              {ALLOWED_TOGGLE_ENABLED && (
                <Menu.Item onClick={() => onUpdateStatus(allData)}>
                  {allData.enabled ? (
                    <FormattedMessage
                      id="deactivate.text"
                      defaultMessage="Deactivate"
                    />
                  ) : (
                    <FormattedMessage id="ACTIVATE" defaultMessage="Activate" />
                  )}
                </Menu.Item>
              )}

              {!allData.enabled && ALLOWED_CREATE && (
                <Menu.Item onClick={() => onDuplicate(allData)}>
                  <FormattedMessage
                    id="duplicate.text"
                    defaultMessage="Delete"
                  />
                </Menu.Item>
              )}

              {!allData.enabled && ALLOWED_EDIT && (
                <Menu.Item onClick={() => setExistingMarquee(allData)}>
                  <FormattedMessage id="edit.text" defaultMessage="Edit" />
                </Menu.Item>
              )}

              {!allData.enabled && ALLOWED_DELETE && (
                <Menu.Item onClick={() => onDeleteMarqueeMessage(allData)}>
                  <FormattedMessage id="delete.text" defaultMessage="Delete" />
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomRight"
        >
          <Button type="link" icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
  },
];
