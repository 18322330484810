import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

function Header(props: {
  onClose?: () => void;
  children?: ReactElement;
  title: string | Record<string, any>;
}) {
  const { children, onClose, title } = props;
  return (
    <Root className="drawer-header">
      <div className="modal-title">{title}</div>
      {children}
      {onClose && <Button type="link">Link</Button>}
    </Root>
  );
}

const Root = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  .modal-title {
    font-size: 16px;
    font-weight: 500;
  }
  .modal-actions {
    a {
      font-size: 16px;
      font-weight: 500;
      color: #1890ff;
    }
  }
`;

export default Header;
