export const TabIdSA = {
  ALL_CLIENT: 'superadmin-all-client',
  CLIENT_PROFILE: 'client-profile',
  WHITE_LIST: 'superadmin-whitelist',
  VENDOR_MAINTENANCE: 'superadmin-vendor-maintenance',
  VENDOR_GROUP_MANAGEMENT: 'superadmin-vendor-group-management',
  BET_HISTORY_REPORT: 'superadmin-bet-history-report',
  ACCOUNTS_CONSOLIDATED_REPORT: 'superadmin-accounts-consolidated-report',
  VENDORS_CONSOLIDATED_USAGE_REPORT:
    'superadmin-consolidated-vendor-usage-report',
  INTERNAL_DEPOSIT_REQUESTS: 'superadmin-internal-deposits',
  WITHDRAWAL_REQUESTS: 'superadmin-withdrawal-request',
  PROMO_REQUEST: 'superadmin-promo-request',
  CUSTOM_REPORT: 'superadmin-custom-report',
};

export default {};
