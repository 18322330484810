import { ErrorHandler } from 'components/ErrorHandler';
import FiltersProvider from 'contexts/Filters';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import { GET_MEMBER_INTERACTION_LOGS } from 'graphql/queries/memberInteractionLog.query';
import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import uuid from 'uuid';
import MemberInteractionLogScreen from './components/MemberInteractionLogScreen';

const MemberInteractionLogReport = () => {
  const [key, setKey] = useState('');
  const { getTab } = useScreenTabV2();
  const { searchRef, filter = {} } = getTab('member-interaction-log-report');

  const defaultSearchInfo = searchRef || {};
  const {
    memberId,
    action,
    brandId,
    platformId,
    actor,
    dateTimeCreated,
    module,
  } = defaultSearchInfo;

  const defaultFilters = {
    member: memberId ? { eq: memberId } : null,
    actor: actor ? { eq: actor } : null,
    type: action ? { eq: action } : null,
    brandId: brandId ? { eq: brandId } : null,
    platformId: platformId ? { eq: platformId } : null,
    module: module ? { eq: module } : null,
    dateTimeCreated: dateTimeCreated
      ? { gte: dateTimeCreated.gte, lte: dateTimeCreated.lte }
      : null,
  };

  const initialFilters = {
    ...defaultFilters,
    ...filter,
  };

  return (
    <SpreadsheetProvider
      options={{
        query: GET_MEMBER_INTERACTION_LOGS,
        edgesPath: 'memberInteractionLogs.edges',
      }}
    >
      <FiltersProvider
        initialValues={initialFilters}
        defaultValues={defaultFilters}
        tabId="member-interaction-log-report"
      >
        <ErrorHandler>
          <MemberInteractionLogScreen
            key={key}
            refreshPage={() => setKey(uuid.v1())}
            filter={filter}
            defaultSearchInfo={defaultSearchInfo}
          />
        </ErrorHandler>
      </FiltersProvider>
    </SpreadsheetProvider>
  );
};

export default MemberInteractionLogReport;
