import React, { useState } from 'react';
import { get, debounce } from 'lodash';
import styled from 'styled-components';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Menu,
  Layout,
  Tag,
  Row as AntdRow,
  Col,
  Typography,
  Dropdown,
  Button,
  InputNumber,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { FormikProps } from 'interfaces/formikProps.interface';
import { RebateProps } from 'interfaces/formikValues/rebates.interface';
import { useConfig } from 'hooks/useConfig';

const { Sider } = Layout;
const { Text } = Typography;
const Row: any = AntdRow;

const StyledTag = styled(Tag)`
  font-size: 24px !important;
  padding: 8px !important;
  background-color: ${(props: { active?: boolean }) =>
    props.active ? 'white' : '#ECECEC'};
`;

const StyledSider = styled(Sider)`
  flex: 0 0 260px !important;
  max-width: 260px !important;
  min-width: 260px !important;
  width: 260px !important;
  background: #fff;
  .ant-menu {
    border: none;
    .ant-menu-item {
      margin-top: 0;
      height: 60px;
      line-height: 60px;
      border-radius: 6px 0 0 6px;
      padding: 0 12px !important;
      &::after {
        border-right: 0 !important;
      }
      &.ant-menu-item-selected {
        background-color: #f0f2f5;
      }
    }
  }
`;

const StyledMenu = styled(Menu)`
  &.ant-menu {
    background-color: ${(props: { active?: boolean }) =>
      props.active ? 'rgba(0,0,0,0.05)' : '#fff'};
  }
`;

const StyledText: any = styled(Text)`
  display: ${(props: { shown?: boolean }) => (props.shown ? 'none' : 'block')};
  font-size: 16px;
  &.ant-typography {
    width: 25px;
    &:hover {
      cursor: text;
    }
  }
`;

const StyledInputNumber = styled(InputNumber)`
  width: 80px;
  position: relative;
  z-index: 4;
  &.ant-input-number .ant-input-number-handler-wrap {
    display: none !important;
  }
`;

const StyledDiv = styled.div`
  margin-right: ${(props: { shown?: boolean }) =>
    props.shown ? '0px' : '63px'};
`;

const StyledLabelText = styled(Text)`
  font-size: 13px;
`;

const textStyle = {
  lineHeight: 1.5,
  paddingTop: '5px',
};

const LeftSidebar = ({
  formikProps,
}: {
  formikProps: FormikProps<RebateProps>;
}) => {
  const levels = get(formikProps.values, 'levels', []);
  const activeLevel = get(formikProps.values, 'activeLevel', 0);
  const { currency } = useConfig();
  const [groupSettings, setGroupSettings] = useState(
    levels.length
      ? levels.map((_: any, index: number) => ({ shown: false, index }))
      : [{ shown: false, index: 0 }]
  );

  const [indexOn, setIndexOn] = useState({
    hover: 0,
  });

  const menu = (
    <Menu>
      <Menu.Item
        key="3"
        disabled={groupSettings.length === 1}
        onClick={() => {
          formikProps.setFieldValue(
            'levels',
            levels.filter((_: any, i: number) => i !== indexOn.hover)
          );
          setGroupSettings((gs: Array<any>) =>
            gs.filter((_: any, i) => i !== indexOn.hover)
          );
          if (indexOn.hover === activeLevel - 1) {
            formikProps.setFieldValue('activeLevel', activeLevel - 1);
          }
        }}
      >
        <FormattedMessage id="rebates.delete.text" defaultMessage="Delete" />
      </Menu.Item>
    </Menu>
  );

  const handleChange = (value: string, index: number) =>
    formikProps.setFieldValue(`levels[${index}][minimum]`, value);

  return (
    <StyledSider>
      <Row style={{ height: 46, paddingTop: 8 }}>
        <FormattedMessage
          id="rebates.group-settings.text"
          defaultMessage="Group Settings"
        />
      </Row>
      {groupSettings.map(({ shown }: { shown?: boolean }, index: number) => (
        <StyledMenu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          active={activeLevel === index}
          onClick={() => {
            if (index !== activeLevel) {
              formikProps.setFieldValue('activeLevel', index);
            }
          }}
        >
          <Menu.Item key="1">
            <Row>
              <Col span={4}>
                <StyledTag active={activeLevel === index}>
                  {index + 1}
                </StyledTag>
              </Col>
              <Col span={16} className="d-flex" style={textStyle}>
                <StyledDiv shown={shown}>
                  <StyledLabelText>
                    <FormattedMessage id="from.text" defaultMessage="From" />
                  </StyledLabelText>
                  <StyledText
                    shown={shown}
                    onMouseOver={() => {
                      if (index === levels.length - 1) {
                        const gs = [...groupSettings];
                        gs[index] = { shown: true };
                        setGroupSettings(gs);
                      }
                    }}
                    onFocus={() => {
                      if (index === levels.length - 1) {
                        const gs = [...groupSettings];
                        gs[index] = { shown: true };
                        setGroupSettings(gs);
                      }
                    }}
                  >
                    {currency?.symbol} {get(levels, `[${index}][minimum]`, 0)}
                  </StyledText>
                  {shown && (
                    <div
                      style={{ fontSize: '15px' }}
                      onMouseLeave={() => {
                        const gs = [...groupSettings];
                        gs[index] = { shown: false };
                        setGroupSettings(gs);
                      }}
                    >
                      {currency?.symbol}{' '}
                      <StyledInputNumber
                        size="small"
                        name={`levels[${index}][minimum]`}
                        min={index !== 0 ? levels[index - 1].minimum + 2 : 0}
                        value={get(levels, `[${index}][minimum]`, 0)}
                        onChange={debounce((value) => {
                          if (index) {
                            const minimumValue = get(
                              levels,
                              `[${index - 1}][minimum]`,
                              0
                            );
                            if (value < minimumValue) {
                              return;
                            }
                          }
                          handleChange(value, index);
                        }, 150)}
                      />
                    </div>
                  )}
                </StyledDiv>
                <div>
                  <StyledLabelText>
                    <FormattedMessage id="to.text" defaultMessage="To" />
                  </StyledLabelText>
                  <StyledText>
                    -{currency?.symbol}{' '}
                    {index !== levels.length - 1 && levels[index + 1] ? (
                      (levels[index + 1].minimum - 1).toString()
                    ) : (
                      <FormattedMessage
                        id="infinity.text"
                        defaultMessage="Infinity"
                      />
                    )}
                  </StyledText>
                </div>
              </Col>
              <Col
                span={4}
                className="d-flex flex-column"
                style={{
                  ...textStyle,
                }}
              >
                <Dropdown overlay={menu} placement="bottomRight">
                  <EllipsisOutlined
                    style={{ lineHeight: 1.5, color: 'rgba(0,0,0,0.5)' }}
                    onMouseOver={() => {
                      if (indexOn.hover !== index) {
                        setIndexOn((i) => ({ ...i, hover: index }));
                      }
                    }}
                    onFocus={() => {
                      if (indexOn.hover !== index) {
                        setIndexOn((i) => ({ ...i, hover: index }));
                      }
                    }}
                  />
                </Dropdown>
              </Col>
            </Row>
          </Menu.Item>
        </StyledMenu>
      ))}
      <Row type="flex" style={{ marginTop: 8 }}>
        <Button
          type="primary"
          ghost
          style={{ margin: 'auto' }}
          onClick={() => {
            formikProps.setFieldValue('levels', [
              ...levels,
              { minimum: levels[levels.length - 1].minimum + 2 },
            ]);

            setGroupSettings((gs: Array<any>) => [...gs, { shown: false }]);
          }}
        >
          <PlusOutlined style={{ height: 4, marginRight: 8 }} />
          <FormattedMessage
            id="rebates.create-new-tier.text"
            defaultMessage="Create New Tier"
          />
        </Button>
      </Row>
    </StyledSider>
  );
};

export default LeftSidebar;
