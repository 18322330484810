import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect = styled(Select)`
  .ant-select-selection {
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
  .ant-select-selection__rendered {
    margin-left: 0;
  }
`;

export default {};
