import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import ModifiedTag from '../ModifiedTag';
import { GAME_CATEGORY } from './queries';

type GameCategoryTagType = {
  id: string;
  onClose: (val: any) => void;
};

const GameCategoryTag = (props: GameCategoryTagType) => {
  const { id, onClose } = props;
  const { data } = useQuery(GAME_CATEGORY, {
    variables: {
      id,
    },
  });

  const { gameCategory } = data;

  return (
    <ModifiedTag {...props} closable onClose={(e: any) => onClose(e)}>
      {gameCategory.name}
    </ModifiedTag>
  );
};

export default GameCategoryTag;
