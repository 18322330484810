import React, { useState, useRef } from 'react';
import { CloseOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Dropdown, Menu, Button, Checkbox, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { kebabCase } from 'lodash';
import styled from 'styled-components';
import { useAffiliateState } from 'pages/components/AffiliateProgramme/context';
import { Locale } from 'constants/locale';
import { GameTypes } from 'constants/gameTypes';
import useTranslate from 'utils/useTranslate';
import globalMessages from 'messages';
import { FilterContainer } from './styles';
import messages from './messages';
import {
  CostSettingsTypes,
  useCostSettingsContext,
  CostSettingsFilters,
} from '../../context';

const InputGroup = Input.Group;

const StyledSelect = styled(Select)`
  width: 150px !important;
  height: 36px;
  margin-left: 1px;
  &.ant-select > .ant-select-selection--single {
    height: 36px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
  }
  &.ant-select
    > .ant-select-selection--single
    > .ant-select-selection__rendered {
    line-height: 35px;
  }
`;

const StyledInput = styled(Input)`
  height: 36px;
  width: 200px;
  .ant-input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
  }
`;

type StyledMenuType = {
  isGameVendorWidth: boolean;
};

const StyledMenu = styled(Menu)<StyledMenuType>`
  max-width: ${(props) => (props.isGameVendorWidth ? '200px' : '150px')};
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  .ant-dropdown-menu-item {
    label {
      display: block;
    }
  }
`;

function Filters() {
  const translate = useTranslate();
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const [{ sortFilter, subFilters }, dispatch] = useCostSettingsContext();
  const [{ vendors }] = useAffiliateState() as any;
  const [filterValuesShown, setFilterValuesShown] = useState(false);
  const timeOut = useRef(0);

  const setSortFilter = (value: any) => {
    dispatch({
      type: CostSettingsTypes.SET_SORT_FILTER,
      payload: value,
    });
  };

  const setSearchFilter = (value: any) => {
    dispatch({
      type: CostSettingsTypes.SET_SEARCH_FILTER,
      payload: value,
    });
  };

  const setInputSearch = (e: any) => {
    const { value } = e.target;
    setSearch(value);

    clearTimeout(timeOut.current);
    e.persist();
    timeOut.current = setTimeout(() => {
      dispatch({
        type: CostSettingsTypes.SET_SEARCH,
        payload: value,
      });
    }, 250);
  };

  const subMenuItems =
    sortFilter === CostSettingsFilters.GAME_VENDOR
      ? vendors.map((item: Record<string, any>) => ({
          label:
            intl.locale === Locale.EN
              ? item.node.nameEn
              : `${item.node.nameZh || item.node.name}`,
          value: item.node.id,
        }))
      : Object.keys(GameTypes).map((key) => ({
          label: `${translate(globalMessages[`${kebabCase(key)}.text`])}`,
          value: key,
        }));

  const menu = {
    sortFilter: (
      <Menu>
        {Object.keys(CostSettingsFilters).map(
          (value, index) =>
            value !== CostSettingsFilters.ALL && (
              <Menu.Item
                key={index}
                onClick={() => {
                  setSortFilter(value);
                }}
              >
                {translate(messages[`${kebabCase(value)}.text`])}
              </Menu.Item>
            )
        )}
      </Menu>
    ),
    subFilters: (
      <StyledMenu
        isGameVendorWidth={sortFilter === CostSettingsFilters.GAME_VENDOR}
      >
        {subMenuItems.map((item: Record<string, any>) => (
          <Menu.Item key={item.value}>
            <Checkbox
              checked={Boolean(
                subFilters.find(
                  (filterValue: Record<string, any>) =>
                    filterValue.value === item.value
                )
              )}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch({
                    type: CostSettingsTypes.SET_SUBFILTER,
                    payload: item,
                  });
                } else {
                  dispatch({
                    type: CostSettingsTypes.REMOVE_SUBFILTER,
                    payload: item,
                  });
                }
              }}
            >
              {item.label}
            </Checkbox>
          </Menu.Item>
        ))}
      </StyledMenu>
    ),
  };

  return (
    <FilterContainer>
      <div className="d-flex">
        <InputGroup compact>
          <StyledInput
            style={{ width: '240px' }}
            prefix={
              <SearchOutlined style={{ fontWeight: 'bold', fontSize: 19 }} />
            }
            value={search}
            suffix={<CloseOutlined />}
            onChange={setInputSearch}
          />
          <StyledSelect
            defaultValue={CostSettingsFilters.GAME_VENDOR}
            onChange={setSearchFilter}
          >
            {Object.keys(CostSettingsFilters).map((value, index) => {
              const messageId = kebabCase(value.toLocaleLowerCase());

              return (
                <Select.Option key={index} value={value}>
                  {translate(messages[`${messageId}.text`])}
                </Select.Option>
              );
            })}
          </StyledSelect>
        </InputGroup>
      </div>
      <div className="d-flex">
        <div className="d-flex mr-1">
          <Dropdown overlay={menu.sortFilter} trigger={['click']}>
            <Button type="link">
              <FormattedMessage id="sort-by.text" defaultMessage="Sort by" />
              <span style={{ marginRight: '4px' }}>:</span>
              {translate(
                messages[`${kebabCase(sortFilter.toLowerCase())}.text`]
              )}
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <div className="d-flex">
          <Dropdown
            onVisibleChange={(flag) => setFilterValuesShown(flag)}
            visible={filterValuesShown}
            overlay={menu.subFilters}
            trigger={['click']}
          >
            <Button type="link">
              {translate(
                messages[`${kebabCase(sortFilter.toLowerCase())}.text`]
              )}
              <span style={{ marginRight: '4px' }}>:</span>
              {Boolean(!subFilters.length) && (
                <FormattedMessage id="all.text" />
              )}
              {Boolean(subFilters.length) &&
                subFilters.reduce(
                  (acc: any, item: Record<string, any>, index: number) =>
                    index === subFilters.length - 1
                      ? `${acc}${item.label}`
                      : `${acc}${item.label}, `,
                  ''
                )}
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </FilterContainer>
  );
}

export default Filters;
