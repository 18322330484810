import React from 'react';
import { Select } from 'antd';
import { StyledSelect } from './styles';

const { Option } = Select;

const DefaultProgramme = () => (
  <div className="my-5">
    <div className="mb-1">Default Programme</div>
    <StyledSelect placeholder="Yes / No" style={{ width: '100%' }}>
      <Option value="1">Default Programme 1</Option>
      <Option value="2">Default Programme 2</Option>
    </StyledSelect>
  </div>
);

export default DefaultProgramme;
