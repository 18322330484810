import gql from 'graphql-tag';

export const START_PROCESS_WITHDRAWAL = gql`
  mutation StartProcessWithdrawal($id: ID!) {
    startProcessWithdrawal(id: $id)
  }
`;

export const STOP_PROCESS_WITHDRAWAL = gql`
  mutation StopProcessWithdrawal($id: ID!) {
    stopProcessWithdrawal(id: $id)
  }
`;

export const APPROVE_WITHDRAWAL = gql`
  mutation ApproveWithdrawal($id: ID!, $input: ApproveWithdrawalInput!) {
    approveWithdrawal(id: $id, input: $input)
  }
`;

export const APPROVE_WITHDRAWAL_NEXT = gql`
  mutation ApproveWithdrawalNext($id: ID!, $input: ApproveWithdrawalNextInput) {
    approveWithdrawalNext(id: $id, input: $input)
  }
`;

export const REJECT_WITHDRAWAL = gql`
  mutation RejectWithdrawal($id: ID!) {
    rejectWithdrawal(id: $id)
  }
`;
export const ON_HOLD_WITHDRAWAL = gql`
  mutation OnHoldWithdrawal($id: ID!, $reason: String!) {
    onHoldWithdrawal(id: $id, reason: $reason)
  }
`;

export const UPDATE_WITHDRAWAL = gql`
  mutation UpdateWithdrawal($id: ID!, $input: UpdateWithdrawalInput!) {
    updateWithdrawal(id: $id, input: $input)
  }
`;

export default {};
