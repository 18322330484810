import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Select, Col, Row, Radio } from 'antd';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import PermissionGroupsSelect from 'components/PermissionGroupsSelect';
import randomstring from 'randomstring';
import removeEmptyString from 'utils/removeEmptyString';
import removeNull from 'utils/removeNull';
import copyToClipboard from 'utils/copyToClipboard';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import validationSchema from './validationSchema';

const { Option } = Select;

type Props = {
  createOperator: (data: Record<string, any>) => Record<string, any>;
  onClose: () => void;
  loading: boolean;
  operator: Record<string, any>;
};

const OperatorForm = (props: Props) => {
  const { createOperator, onClose, loading, operator } = props;
  const translate = useTranslate();
  const InputGroup = Input.Group;
  const { TextArea } = Input;

  const initialValues = {
    ...operator,
  };

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 15 },
  };

  return (
    <>
      <Formik
        validateOnChange={false}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          const { id } = values;
          const newValues = { ...values };
          const newVariables: Record<string, any> = {};
          delete newValues.id;

          if (id) {
            delete newValues.username;

            // To avoid error in submitting empty password on update
            if (!values.password) {
              delete newValues.password;
            }
            newVariables.id = id;
          }

          newVariables.input = removeEmptyString(removeNull(newValues));

          const response = await createOperator({
            variables: newVariables,
          });
          if (response && response.data) {
            resetForm();
            onClose();
          }
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit} layout="horizontal">
            <Row className="p-6">
              <Col span={12} className="br-1">
                <h4 className="mb-4 mt-3 ml-3">
                  <FormattedMessage
                    id="fill-in-the-account-information.text"
                    defaultMessage="Fill in the account information"
                  />
                </h4>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="username.text"
                      defaultMessage="Username"
                    />
                  }
                  validateStatus={errors.username ? 'error' : ''}
                  help={errors.username || null}
                  {...formItemLayout}
                >
                  <Input
                    name="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    disabled={values.id}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="real-name.text"
                      defaultMessage="Real Name"
                    />
                  }
                  validateStatus={errors.realName ? 'error' : ''}
                  help={errors.realName || null}
                  {...formItemLayout}
                >
                  <Input
                    name="realName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.realName}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="mobile-number.text"
                      defaultMessage="Mobile number"
                    />
                  }
                  {...formItemLayout}
                  validateStatus={errors.mobileNumber ? 'error' : ''}
                  help={errors.mobileNumber || null}
                >
                  <Input
                    name="mobileNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobileNumber}
                    type="string"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <FormattedMessage id="email.text" defaultMessage="Email" />
                  }
                  validateStatus={errors.email ? 'error' : ''}
                  help={errors.email || null}
                  {...formItemLayout}
                >
                  <Input
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="gender.text"
                      defaultMessage="Gender"
                    />
                  }
                  {...formItemLayout}
                >
                  <Select
                    value={values.gender}
                    onChange={(e) => setFieldValue('gender', e)}
                  >
                    <Option value="MALE">
                      <FormattedMessage id="male.text" defaultMessage="Male" />
                    </Option>
                    <Option value="FEMALE">
                      <FormattedMessage
                        id="female.text"
                        defaultMessage="Female"
                      />
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} className="pr-3">
                <h4 className="ml-4 mt-3">
                  <FormattedMessage
                    id="login-password.text"
                    defaultMessage="Login password"
                  />
                </h4>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="password.text"
                      defaultMessage="Password"
                    />
                  }
                  validateStatus={errors.password ? 'error' : ''}
                  help={errors.password || null}
                  {...formItemLayout}
                >
                  <InputGroup compact>
                    <Input
                      style={{ width: '60%' }}
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <Button
                      style={{ width: '40%' }}
                      type="primary"
                      onClick={() =>
                        setFieldValue(
                          'password',
                          randomstring.generate({
                            length: 8,
                            charset: 'alphanumeric',
                            capitalization: 'lowercase',
                          })
                        )
                      }
                    >
                      <FormattedMessage
                        id="auto-generate.text"
                        defaultMessage="Auto generate"
                      />
                    </Button>
                  </InputGroup>
                  <Button
                    icon={<CopyOutlined />}
                    style={{ width: '100%' }}
                    type="ghost"
                    disabled={!values.password}
                    onClick={() =>
                      copyToClipboard(
                        values.password,
                        translate(messages.COPIED_TO_CLIPBOARD)
                      )
                    }
                  >
                    <FormattedMessage id="copy.text" defaultMessage="Copy" />
                  </Button>
                </Form.Item>
                <h4 className="ml-4">
                  <FormattedMessage
                    id="permissions.text"
                    defaultMessage="Permissions"
                  />
                </h4>
                <Form.Item
                  label={
                    <FormattedMessage
                      id="permission-groups.text"
                      defaultMessage="Permission groups"
                    />
                  }
                  validateStatus={errors.permissionGroups ? 'error' : ''}
                  help={
                    errors.permissionGroups ? (
                      <FormattedMessage
                        id="permission-group.select-at-least-one-permission.text"
                        defaultMessage="select at least 1 permission"
                      />
                    ) : null
                  }
                  {...formItemLayout}
                >
                  <PermissionGroupsSelect
                    value={values.permissionGroups}
                    onChange={(e) => setFieldValue('permissionGroups', e)}
                    multiple
                    placeholder={
                      <FormattedMessage
                        id="select-a-permission-group.text"
                        defaultMessage="Select a permission group"
                      />
                    }
                  />
                </Form.Item>
                <br />
                <Form.Item
                  label={
                    <FormattedMessage id="notes.text" defaultMessage="Notes" />
                  }
                  {...formItemLayout}
                >
                  <TextArea
                    name="notes"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.notes}
                    rows={4}
                  />
                </Form.Item>
                <br />
                <Form.Item
                  label={
                    <FormattedMessage
                      id="status.text"
                      defaultMessage="Status"
                    />
                  }
                  {...formItemLayout}
                >
                  <Radio.Group
                    value={values.status}
                    name="status"
                    onChange={handleChange}
                  >
                    <Radio value="ENABLED">
                      <FormattedMessage
                        id="enabled.text"
                        defaultMessage="Enabled"
                      />
                    </Radio>
                    <Radio value="DISABLED">
                      <FormattedMessage
                        id="disabled.text"
                        defaultMessage="Disabled"
                      />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <div className="bt-1 p-4 text-right">
              <Button className="mr-2" onClick={onClose} disabled={loading}>
                <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OperatorForm;
