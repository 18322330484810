import React from 'react';
import Circle from 'components/Circle';
import { Tooltip } from 'antd';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';
import WithdrawalFees from './WithdrawalFees';
import { StyledContainer } from './styles';

interface Props {
  withdrawalID: string;
  toolTipId: string;
}

interface IWithdrawal {
  handlingFeeApplied: number;
  withdrawalTaxFeeApplied: number;
  promoDeductionFeeApplied: number;
  withdrawalLimitFeeApplied: number;
  handlingFee: number;
  withdrawalTaxFee: number;
  promoDeductionFee: number;
  withdrawalLimitFee: number;
}

const MemberIndicatorFee = React.memo(
  ({ withdrawalID, toolTipId }: Props): any => {
    const {
      feeIndicatorLoaderSet: [useFeeIndicatorLoader],
    } = useLoaders();

    const { loading, error, data = {} } = useFeeIndicatorLoader(withdrawalID);

    if (error) {
      return <TableErrorCell errorMessage={error.message} />;
    }
    if (loading) return <TableCellSpinner />;
    const {
      handlingFeeApplied,
      withdrawalTaxFeeApplied,
      promoDeductionFeeApplied,
      withdrawalLimitFeeApplied,
      handlingFee,
      withdrawalTaxFee,
      promoDeductionFee,
      withdrawalLimitFee,
    }: IWithdrawal = data;

    const handling =
      handlingFeeApplied === null ? handlingFee : handlingFeeApplied;

    const tax =
      withdrawalTaxFeeApplied === null
        ? withdrawalTaxFee
        : withdrawalTaxFeeApplied;

    const promoDeduction =
      promoDeductionFeeApplied === null
        ? promoDeductionFee
        : promoDeductionFeeApplied;

    const limit =
      withdrawalLimitFeeApplied === null
        ? withdrawalLimitFee
        : withdrawalLimitFeeApplied;
    return (
      <Tooltip
        placement="bottom"
        title={
          <WithdrawalFees
            tax={tax}
            handling={handling}
            promoDeduction={promoDeduction}
            limit={limit}
          />
        }
        getPopupContainer={() => document.getElementById(toolTipId)!}
      >
        <StyledContainer>
          <div id={toolTipId} className="tool-tip-container">
            <div>
              <Circle size={10} color="primary" filled={Boolean(tax)} />
            </div>
            <div>
              <Circle size={10} color="danger" filled={Boolean(handling)} />
            </div>
            <div>
              <Circle
                size={10}
                color="success"
                filled={Boolean(promoDeduction)}
              />
            </div>
            <div>
              <Circle size={10} color="warning" filled={Boolean(limit)} />
            </div>
          </div>
        </StyledContainer>
      </Tooltip>
    );
  }
);

export default MemberIndicatorFee;
