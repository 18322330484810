import { customFormatMessage } from 'utils/customFormatMessage';
import messages from './messages';

export const getPaymentMethodType = (
  record: Record<string, any>,
  formatMessage?: any
) => {
  const translate = (messageVal: any) =>
    customFormatMessage(formatMessage, messageVal);

  const { bank, nickname, __typename } = record;

  if (bank) {
    return translate(messages.banktransfer);
  }
  if (nickname) {
    return __typename === 'WechatPaymentMethod'
      ? translate(messages.wechat)
      : translate(messages.alipay);
  }

  if (__typename === 'ManualAdjustmentPaymentMethod') {
    return translate(messages.MANUAL_ADJUSTMENT);
  }

  if (__typename === 'CryptocurrencyPaymentMethod') {
    return 'Crypto';
  }
  if (__typename === 'WonkaPayPaymentMethod') {
    return 'WonkaPay';
  }
  if (__typename === 'OnlineWechatPaymentMethod') {
    return translate(messages.wechat);
  }

  if (__typename === 'OnlineAlipayPaymentMethod') {
    return translate(messages.alipay);
  }

  if (__typename === 'OnlineBankTransferPaymentMethod') {
    return translate(messages.banktransfer);
  }

  if (__typename === 'UnionPayPaymentMethod') {
    return translate(messages.unionpay);
  }

  if (__typename === 'HexoPayPaymentMethod') {
    return translate(messages.hexopay);
  }

  if (__typename === 'NetellerPaymentMethod') {
    return translate(messages.neteller);
  }

  if (__typename === 'SkrillPaymentMethod') {
    return translate(messages.skrill);
  }

  return '-';
};

export const getColor = (status: boolean) => {
  let color = 'primary';

  switch (status) {
    case true:
      color = '#52c41a';
      break;
    case false:
      color = '#ff0000';
      break;
    default:
      color = 'ff0000';
      break;
  }

  return color;
};
