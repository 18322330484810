import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
  }
`;

export default {};
