export const MEMBER_STATUSES = [
  {
    label: 'Active',
    value: 'ENABLED',
  },
  {
    label: 'Inactive',
    value: 'DISABLED',
  },
  {
    label: 'Frozen',
    value: 'FROZEN',
  },
  {
    label: 'Kicked',
    value: 'KICKED',
  },
  {
    label: 'Self Excluded',
    value: 'SELF_EXCLUDED',
  },
  {
    label: 'Gamestop Self Excluded',
    value: 'GAMSTOP_SELF_EXCLUDED',
  },
  {
    label: 'Cooling Off',
    value: 'COOLING_OFF',
  },
  {
    label: 'Cooling Off Ended',
    value: 'COOLING_OFF_ENDED',
  },
  {
    label: 'Time-out',
    value: 'TIME_OUT',
  },
  {
    label: 'Fraud',
    value: 'FRAUD',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
  },
  {
    label: 'Banned',
    value: 'BANNED',
  },
  {
    label: 'Dormant',
    value: 'DORMANT',
  },
  {
    label: 'Closed Legacy',
    value: 'CLOSED_LEGACY',
  },
];
