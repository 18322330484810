import LeftSidebar from './LeftSidebar';
import Header from './Header';
import Container from './Container';
import Footer from './Footer';
import Content from './Content';

const Layout = () => {};

Layout.Sidebar = LeftSidebar;
Layout.Header = Header;
Layout.Container = Container;
Layout.Footer = Footer;
Layout.Content = Content;

export default Layout;

/*
 * This is the global layout should be used on pages that has Table.
 * Structure should be. If there's somethings missing, feel free to add it.
 * Just make sure that, avoid putting specific feature on this Layout component
 * Make it generic as much as possible
 * */

// <Layout.Container>
//   <>
//     <Layout.Header leftNode={<LeftComponent>} rightNode={<RightComponent>} />
//     <Layout.Content sideBar={<Layout.Sidebar sidebarStatus={false} quickSearch={{}}> Your Sidebar INputs </Layout.Sidebar>} footer={<Layout.Footer />}>
//       <YOUR_LOCAL_TABLE_COMPONENT />
//     </Layout.Content>
//   </>
// </Layout.Container>
