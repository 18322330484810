import React from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

const DrawerActionButtons = ({
  saveFn,
  saveAndExitFn,
}: {
  saveFn?: () => void;
  saveAndExitFn?: () => void;
}) => (
  <div className="drawer-actions">
    <Button type="link" className="mr-4" onClick={saveFn}>
      <FormattedMessage id="save.text" defaultMessage="Save" />
    </Button>
    <Button type="link" onClick={saveAndExitFn}>
      <FormattedMessage
        id="promo.save-and-exit.text"
        defaultMessage="Save &amp; Exit"
      />
    </Button>
  </div>
);

export default DrawerActionButtons;
