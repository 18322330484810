import React, { ReactElement } from 'react';
import styled from 'styled-components';

function Footer({
  children,
  style,
  ...rest
}: {
  children: ReactElement;
  style?: Record<string, any>;
  rest?: any;
}) {
  return (
    <Root className="drawer-footer">
      <div className="drawer-footer-container" {...rest} style={style || {}}>
        {children}
      </div>
    </Root>
  );
}

const Root = styled.div`
  position: absolute;
  bottom: 0;
  padding: 16px 24px;
  border-top: 1px solid #e8e8e8;
  width: 100%;
  background-color: #fff;
  .drawer-footer-container {
    max-width: 960px;
    margin: 0 auto;
  }
`;

export default Footer;
