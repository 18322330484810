/* eslint-disable no-bitwise */
import React from 'react';
import Numeral from 'numeral';
import vendorGameMetrics from 'constants/vendorGameMetrics';
import gameTypes from 'constants/gameTypes';
import { Table, Typography } from 'antd';

const { Column, ColumnGroup } = Table;
const { Text } = Typography;

const ChartTable = ({
  dataSource,
  loading,
  state,
}: {
  dataSource: Record<string, any>;
  loading: boolean;
  state: {
    gameTypeColumns: any[];
    metric: string;
  };
}) => {
  const { gameTypeColumns } = state;
  const derivedData: any = loading
    ? []
    : [
        {
          key: 'metric',
          metric: (vendorGameMetrics.find(
            (vgm: { value: string }) => vgm.value === state.metric
          ) as any).label,
        },
        {
          key: 'effectiveBet',
          metric: vendorGameMetrics[1].label,
        },
      ];

  [...derivedData].forEach((dd, idx) => {
    let rowTotal = 0;
    gameTypeColumns.forEach((gtc) => {
      rowTotal += dataSource[`${gtc.toLowerCase()}_${dd.key}`];
      derivedData[idx][gtc.toLowerCase()] =
        dataSource[`${gtc.toLowerCase()}_${dd.key}`];
    });
    derivedData[idx].total = rowTotal;
  });

  const columns = gameTypeColumns.map((gtc) => {
    const { name, type } = gameTypes.find((gt) => gt.type === gtc) as {
      name: string;
      type: string;
    };
    return {
      title: name,
      dataIndex: type.toLowerCase(),
      render: (text: number) => {
        const color = text < 0 ? '#f5222d' : '#52c41a';
        return (
          <Text style={{ color }}>
            {Numeral(text).format('0.00', (n) => ~~n)}
          </Text>
        );
      },
    };
  });
  columns.unshift({
    title: 'Metric',
    dataIndex: 'metric',
  } as any);
  columns.push({
    title: 'Total',
    dataIndex: 'total',
    render: (text) => {
      const color = text < 0 ? '#f5222d' : '#52c41a';
      return (
        <Text style={{ color }}>
          {Numeral(text).format('0.00', (n) => ~~n)}
        </Text>
      );
    },
  });

  return (
    <Table
      loading={loading}
      bordered
      rowKey="key"
      dataSource={derivedData}
      pagination={false}
    >
      {columns?.map((column, idx) => {
        const { subColumns, title, dataIndex, render } = column as any;
        return subColumns && subColumns.length ? (
          <ColumnGroup title={title} key={idx}>
            {subColumns.map(
              ({
                title: subTitle,
                dataIndex: subDataIndex,
                render: subRender,
              }: any) => (
                <Column
                  title={subTitle}
                  dataIndex={subDataIndex}
                  key={subDataIndex}
                  align="center"
                  render={subRender}
                />
              )
            )}
          </ColumnGroup>
        ) : (
          <Column
            title={title}
            dataIndex={dataIndex}
            key={dataIndex || idx}
            align="center"
            render={render}
          />
        );
      })}
    </Table>
  );
};

export default ChartTable;
