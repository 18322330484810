import React, { useState } from 'react';
import { showPermissionError } from 'components/Navbar/Navbar';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';

import ProvidersTable from './components/ProvidersTable';
import EditProvider from '../EditProvider';
import DeleteProvider from '../DeleteProvider';
import DuplicateProvider from '../DuplicateProvider';

type Props = {
  data: Array<{}>;
  loading: boolean;
  refetchVariables: object;
};

const Providers = (props: Props) => {
  const { data, loading, refetchVariables } = props;

  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST, ALLOWED_VIEW_DETAILS } = collectPermissions(
    role,
    permissions,
    ['LIST', 'VIEW_DETAILS'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS
  );

  const [state, setState] = useState<Record<string, any>>({
    isEditDepositProvider: false,
    isDeleteDepositProvider: false,
    isDuplicateDepositProvider: false,
    depositProviderId: null,
    editable: false,
  });

  const {
    isEditDepositProvider,
    isDuplicateDepositProvider,
    isDeleteDepositProvider,
  } = state;

  const toggleEdit = (id?: string) => {
    setState({
      ...state,
      isEditDepositProvider: !isEditDepositProvider,
      depositProviderId: id,
      editable: true,
    });
  };

  const toggleView = (id: string) => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('3rd Party Deposit Providers - View Details');
      return;
    }
    setState({
      ...state,
      isEditDepositProvider: !isEditDepositProvider,
      depositProviderId: id,
      editable: false,
    });
  };

  const toggleDuplicate = (id?: string) => {
    setState({
      ...state,
      isDuplicateDepositProvider: !isDuplicateDepositProvider,
      depositProviderId: id,
    });
  };

  const toggleDelete = (id?: string) => {
    setState({
      ...state,
      isDeleteDepositProvider: !isDeleteDepositProvider,
      depositProviderId: id,
    });
  };

  return (
    <>
      {isEditDepositProvider && (
        <EditProvider
          id={state.depositProviderId}
          toggleModal={toggleEdit}
          editable={state.editable}
          refetchVariables={refetchVariables}
        />
      )}
      {isDuplicateDepositProvider && (
        <DuplicateProvider
          id={state.depositProviderId}
          toggleModal={toggleDuplicate}
          refetchVariables={refetchVariables}
        />
      )}
      {isDeleteDepositProvider && (
        <DeleteProvider
          id={state.depositProviderId}
          toggleModal={toggleDelete}
          refetchVariables={refetchVariables}
        />
      )}
      {ALLOWED_LIST && (
        <ProvidersTable
          depositProviders={data}
          loading={loading}
          toggleEdit={toggleEdit}
          toggleView={toggleView}
          toggleDuplicate={toggleDuplicate}
          toggleDelete={toggleDelete}
        />
      )}
    </>
  );
};

export default Providers;
