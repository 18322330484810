import gql from 'graphql-tag';

export const TAGS = gql`
  query Tags($id: ID) {
    member(id: $id) {
      id
      tags {
        id
        name
        color
        description
      }
    }
  }
`;
