import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { Member } from 'types/graphqlTypes';

type Props = {
  values: string[];
  options: Member[];
  client: ApolloClient<object>;
  hasPartial: boolean;
  isCustomFilter?: boolean;
};

export const MEMBER = gql`
  query Member($id: ID!) {
    member(id: $id) {
      id
      username
      realName
    }
  }
`;

export default async ({
  values,
  options,
  client,
  hasPartial,
  isCustomFilter,
}: Props) => {
  const missingMembers = (values as string[])?.filter(
    (value) => !options.find((option) => option.id === value)
  );

  if (!hasPartial && !isCustomFilter) {
    const membersQueryPromises = missingMembers.map((memberId) =>
      client.query({
        query: MEMBER,
        variables: {
          id: memberId,
        },
      })
    );
    const membersRawData = await Promise.all(membersQueryPromises);

    const membersData = membersRawData.map(
      (memberRaw) => memberRaw.data.member
    ) as Member[];

    return { membersData, missingMembers };
  }

  return {
    membersData: [],
    missingMembers: [],
  };
};
