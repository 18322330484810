export const COMMONS = {
  nextEntries: 'nextEntries',
  prevEntries: 'prevEntries',
};

export const MEMBER_MANAGEMENT = {
  accountBalanceSum: 'account-balance-sum',
  memberDropdownMenu: 'member-dropdown-menu',
  memberEditDropdown: 'member-edit-dropdown',
  memberLabels: 'member-labels',
  memberLevelList: 'member-level-list',
  memberLevelListLoading: 'member-level-list-loading',
  memberTagListOptions: 'member-tag-list-options',
  passwordRequired: 'password-is-required',
  selectedMemberLabels: 'selected-member-labels',
  selecteMemberLabelName: 'selected-member-label-name',
  table: 'members-table',
  totalEffectiveBetSum: 'total-effective-bet-sum',
  usernameRequired: 'username-required',
  firstName: 'first-name',
  lastName: 'last-name',
  realName: 'real-name',
};

export const DEPOSIT_REQUEST_EXTERNAL = {
  table: 'deposit-request-external-table',
  toggleDetails: 'toggle-details',
  detailsModal: 'modal',
  elipsisActions: 'elipsis-actions',
};

export const IP_ADDRESS_SUMMARY = {
  table: 'container',
  ipFilterSelect: 'ip-address-summary-filter-select',
  countFilterMin: 'ip-address-summary-filter-count-min',
  countFilterMax: 'ip-address-summary-filter-count-max',
};

export default {};
