import { Button, message, Upload } from 'antd';
import React from 'react';
import { ALink } from 'components/ALink/ALink';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import { FormattedMessage } from 'react-intl';
import CustomSwitch from 'components/CustomSwitch';
import { DynamicObj } from 'interfaces/user.interface';

const BulkAddNotes = ({
  values,
  setFieldValue = null,
}: {
  values: DynamicObj;
  setFieldValue?: any;
}) => {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [processing, setProcessing] = React.useState(false);

  const uploadProps: any = {
    beforeUpload: async (file: File) => {
      const validType = file.type === 'text/plain';

      if (!validType) {
        message.error('Only upload valid text file');
        return false;
      }

      setProcessing(true);

      try {
        const { data } = await uploadFile({
          variables: {
            file,
          },
        });
        setProcessing(false);
        setFieldValue('textData', data?.uploadFile?.id);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      return false;
    },
  };

  return (
    <div>
      <div className="fs-12 text-black--85" style={{ height: '31px' }}>
        <FormattedMessage
          id="import-from-text.text"
          defaultMessage="Import from Text"
        />
      </div>
      <CustomSwitch
        className="mb-3"
        value={values.text}
        activeColor="#3ec988"
        textStyle={{}}
        disabled={false}
        textHelper={null}
        onChange={(e: boolean) => {
          if (setFieldValue) setFieldValue('text', e);
        }}
        checkedChildren={
          <FormattedMessage id="yes.text" defaultMessage="Yes" />
        }
        unCheckedChildren={
          <FormattedMessage id="no.text" defaultMessage="No" />
        }
      />
      {values.text && (
        <div
          className="d-flex mb-3
              "
        >
          <Upload
            {...uploadProps}
            maxCount={1}
            accept=".txt"
            showUploadList={false}
          >
            <Button disabled={processing} loading={processing}>
              {processing ? 'Processing...' : 'Upload Text File'}
            </Button>
          </Upload>

          <Button className="ml-2">
            <ALink
              href={`${process.env.PUBLIC_URL}/text/sample for bulk update balance.txt`}
              target="_blank"
              rel="noopener noreferrer"
              download="sample for bulk update balance.txt"
            >
              Download Template
            </ALink>
          </Button>
        </div>
      )}
    </div>
  );
};

export default BulkAddNotes;
