import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member.label-management.delete-label-success.text': {
    id: 'member.label-management.delete-label-success.text',
    defaultMessage: 'Member label deleted successfully.',
  },
});

export default messages;
