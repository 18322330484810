import gql from 'graphql-tag';

export const CREATE_MEMBER_LEVEL = gql`
  mutation CreateMemberLevel($input: CreateMemberLevelInput!) {
    createMemberLevel(input: $input)
  }
`;

export const UPDATE_MEMBER_LEVEL = gql`
  mutation UpdateMemberLevel($id: ID!, $input: UpdateMemberLevelInput!) {
    updateMemberLevel(id: $id, input: $input)
  }
`;

export const DELETE_MEMBER_LEVEL = gql`
  mutation DeleteMemberLevel($id: ID!) {
    deleteMemberLevel(id: $id)
  }
`;

export const DUPLICATE_MEMBER_LEVEL = gql`
  mutation DuplicateMemberLevel($id: ID!) {
    duplicateMemberLevel(id: $id)
  }
`;
