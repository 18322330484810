import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .menu-header {
    background: #e7e9ed;
  }
`;

export default {};
