import React from 'react';
import { useCreateLoyaltyProgramContext } from '../../context';

import NewProgramSettings from './NewProgramSettings';
import EditProgramSettings from './EditProgramSettings';

export default React.forwardRef((_props: any, ref) => {
  const [contextState] = useCreateLoyaltyProgramContext() as any;
  const { currentProgramId } = contextState;

  return (
    <>
      {currentProgramId ? (
        <EditProgramSettings currentProgramId={currentProgramId} ref={ref} />
      ) : (
        <NewProgramSettings ref={ref} />
      )}
    </>
  );
});
