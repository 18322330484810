import styled from 'styled-components';
import { Layout } from 'antd';

export const SharedStyledLayout = styled(Layout)`
  height: 100%;
  .ant-layout-content {
    overflow-y: auto !important;
  }
`;

export default {};
