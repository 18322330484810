import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Switch, Select, Input, Typography } from 'antd';
import useTranslate from 'utils/useTranslate';
import { setDecimals } from 'utils/setDecimals';
import messages from '../../../../messages';

const ExchangeRateSection = ({
  formItemLayout,
  setFieldValue,
  values,
  handleChange,
  handleBlur,
  errors,
}: any) => {
  const translate = useTranslate();
  const { Option } = Select;
  const { Text } = Typography;

  // please dont remove comments here
  const sourceItem = [
    // {
    //   item: 1,
    //   value: 'HUOBI_OTC',
    //   text: 'Huobi OTC',
    // },
    // {
    //   item: 2,
    //   value: 'COIN_MARKET_CAP',
    //   text: 'CoinMarketCap',
    // },
    // {
    //   item: 3,
    //   value: 'BLOCKCHAIN',
    //   text: 'BlockChain.com',
    // },
    // {
    //   item: 4,
    //   value: 'COINMILL',
    //   text: 'Coinmill',
    // },
    {
      item: 4,
      value: 'COIN_GECKO',
      text: 'Coin Gecko',
    },
    // {
    //   item: 5,
    //   value: 'Free_Currency_Rates',
    //   text: 'Free Currency Rates',
    // },
    // {
    //   item: 6,
    //   value: 'Currencio',
    //   text: 'Currencio',
    // },
  ];

  const frequency = [
    {
      item: 1,
      value: 'm',
      text: translate(messages.min),
    },
    {
      item: 1,
      value: 'h',
      text: translate(messages.hour),
    },
    {
      item: 1,
      value: 'd',
      text: translate(messages.day),
    },
  ];

  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1"
        label={<strong>{translate(messages.exchange_rate)}</strong>}
        colon={false}
      />
      <Form.Item
        label={translate(messages.exchange_rate_label_switch)}
        {...formItemLayout}
      >
        <Switch
          checked={values.allowUpdateExchangeRate}
          onChange={(value) => {
            if (!values.allowUpdateExchangeRate) {
              setFieldValue('exchangeRateSource', sourceItem[0].value);
              setFieldValue(
                'exchangeRateUpdateFrequencyUnit',
                frequency[2].value
              );
            } else {
              setFieldValue('exchangeRateSource', 'MANUAL');
              setFieldValue('exchangeRateUpdateFrequencyUnit', 'd');
              setFieldValue('exchangeRateUpdateFrequency', '1.0');
            }
            setFieldValue('allowUpdateExchangeRate', value);
          }}
        />
        <strong style={{ marginLeft: 10 }}>
          {values.allowUpdateExchangeRate
            ? translate(messages.on)
            : translate(messages.off)}
        </strong>
      </Form.Item>
      {values.allowUpdateExchangeRate && (
        <>
          <Form.Item
            label={translate(messages.source_label)}
            {...formItemLayout}
          >
            <Select
              defaultValue={values.exchangeRateSource || sourceItem[0].value}
              style={{ width: '100%' }}
              onChange={(value: any) =>
                setFieldValue('exchangeRateSource', value)
              }
            >
              {sourceItem.map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
          </Form.Item>
          {/* FREQUENCY SECTION */}
          <Form.Item
            label={translate(messages.frequency)}
            validateStatus={errors.exchangeRateUpdateFrequency ? 'error' : null}
            help={errors.exchangeRateUpdateFrequency}
            {...formItemLayout}
          >
            <Input.Group compact>
              <Input
                name="exchangeRateUpdateFrequency"
                placeholder="Basic usage"
                style={{ width: '50%' }}
                value={values.exchangeRateUpdateFrequency}
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={(e) => {
                  const result = Number(e.target.value);
                  const isInt = (n: number) => Number(n) === n && n % 1 === 0;
                  if (isInt(result)) {
                    const value = setDecimals(e.target.value, 1);
                    setFieldValue('exchangeRateUpdateFrequency', value);
                  }
                  handleBlur(e);
                }}
                step="0.1"
                type="number"
              />

              <Select
                defaultValue={values.exchangeRateUpdateFrequencyUnit}
                style={{ width: 280 }}
                onChange={(value: any) => {
                  setFieldValue('exchangeRateUpdateFrequencyUnit', value);
                }}
              >
                {frequency.map((item) => (
                  <Option value={item.value}>{item.text}</Option>
                ))}
              </Select>
            </Input.Group>
          </Form.Item>
        </>
      )}

      <Form.Item
        label={translate(messages.USDT_to_CNY_label)}
        {...formItemLayout}
        help={
          <span>
            <Text type="secondary">
              {translate(messages.USDT_to_CNY_secondary_label)}
            </Text>
            <br />
          </span>
        }
      >
        <Input
          disabled={!!values.allowUpdateExchangeRate}
          style={{ width: '100%' }}
          value={
            values.exchangeRate !== undefined ||
            typeof values.exchangeRate === 'string'
              ? values.exchangeRate
              : String(values.exchangeRate)
          }
          onChange={(e) => {
            handleChange(e);
          }}
          name="exchangeRate"
          onBlur={(e) => {
            const value = setDecimals(e.target.value, 4);
            setFieldValue('exchangeRate', value);
            handleBlur(e);
          }}
          step="0.0001"
          type="number"
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.spread)}
        {...formItemLayout}
        help={
          !errors.exchangeRateSpread ? (
            <span>
              <Text type="secondary">{translate(messages.spread_label)}</Text>
              <br />
            </span>
          ) : (
            errors.exchangeRateSpread
          )
        }
        validateStatus={errors.exchangeRateSpread ? 'error' : null}
      >
        <Input
          style={{ width: '100%' }}
          value={
            values.exchangeRateSpread !== undefined ||
            typeof values.exchangeRateSpread === 'string'
              ? values.exchangeRateSpread
              : String(values.exchangeRateSpread)
          }
          step="0.0001"
          onChange={(e) => {
            handleChange(e);
          }}
          name="exchangeRateSpread"
          onBlur={(e) => {
            const value = setDecimals(e.target.value, 4);
            setFieldValue('exchangeRateSpread', value);
            handleBlur(e);
          }}
          type="number"
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.confirmation_text)}
        {...formItemLayout}
        help={
          !errors.minimumConfirmationsCount ? (
            <span>
              <Text type="secondary">
                {translate(messages.confirmation_text_secondary_label)}
              </Text>
              <br />
            </span>
          ) : (
            errors.minimumConfirmationsCount
          )
        }
        validateStatus={errors.minimumConfirmationsCount ? 'error' : null}
      >
        <Input
          style={{ width: '100%' }}
          value={values.minimumConfirmationsCount}
          onChange={handleChange}
          name="minimumConfirmationsCount"
          onBlur={handleBlur}
          type="number"
        />
      </Form.Item>
    </>
  );
};

export default ExchangeRateSection;
