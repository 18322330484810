import { useMutation } from '@apollo/react-hooks';
import { Button, message, Popconfirm } from 'antd';
import { APPROVE_DEPOSIT } from 'graphql/mutations/deposit.mutation';
import { func, shape, string } from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import useTranslate from 'utils/useTranslate';

const ApproveDeposit = (props) => {
  const { id, status, refetchQueries, setRemarksError, toggleModal } = props;

  const messages = defineMessages({
    approveDepositProcess: {
      id: 'deposits.deposit-request-approved.text',
      defaultMessage: 'Deposit request approved',
    },
  });
  const translate = useTranslate();
  const [approveDeposit, { loading }] = useMutation(APPROVE_DEPOSIT, {
    onCompleted: (data) => {
      if (!data.approveDeposit) return;
      message.success({
        content: (
          <span data-testid="success">
            {translate(messages.approveDepositProcess)}
          </span>
        ),
      });
      setRemarksError();
      toggleModal();
    },
    onError: () => {
      setRemarksError();
    },
    variables: { id },
    refetchQueries,
  });

  return (
    <>
      {status === 'PROCESSING' && (
        <Popconfirm
          title={
            <FormattedMessage
              id="are-you-sure?.text"
              defaultMessage="Are you sure?"
            />
          }
          onConfirm={() => approveDeposit()}
          onCancel={() => {}}
          okButtonProps={{
            'data-testid': 'yes',
          }}
          cancelButtonProps={{
            'data-testid': 'no',
          }}
          okText={<FormattedMessage id="yes.text" defaultMessage="Yes" />}
          cancelText={<FormattedMessage id="no.text" defaultMessage="No" />}
        >
          <Button
            data-testid="approve-deposit"
            disabled={loading}
            loading={loading}
            style={{ color: '#52C41A', borderColor: '#52C41A' }}
          >
            <FormattedMessage id="approve.text" defaultMessage="Approve" />
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

ApproveDeposit.propTypes = {
  id: string,
  refetchQueries: shape({}),
  status: string,
  setRemarksError: func,
  toggleModal: func,
};

ApproveDeposit.defaultProps = {
  id: null,
  refetchQueries: {},
  status: '',
  setRemarksError: (e) => e,
  toggleModal: (e) => e,
};

export default injectIntl(ApproveDeposit);
