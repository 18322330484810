import styled from 'styled-components';

export const StyledContainer = styled.div`
  .tool-tip-container {
    display: flex;
    justify-content: space-between;
    width: 57px;
    margin: 0 auto;

    span {
      margin: 0 !important;
    }

    .ant-tooltip-inner {
      min-width: unset;
      min-height: unset;
      padding: 0;
    }
  }
`;

export const StyledContent = styled.div`
  font-family: HelveticaNeue;
  font-size: 10px;
  padding: 8px;
  min-width: 112px;
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;

  div:first-child {
    margin-right: auto;
  }

  div:last-child {
    margin-left: 10px;
  }
`;
