import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LayoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Sidebar from './components/Sidebar';
import { StyledLayout, StyledContent, StyledContainer } from './styles';
import MemberGameReportTable from './components/MemberGameReportTable';
import FilterConditions from './components/FilterConditions';

const MemberGameReport = () => {
  const [state, setState] = useState({
    sidebarState: false,
    usernames: [],
    dateRange: [],
    status: [],
  });

  const handleSidebar = () =>
    setState({
      ...state,
      sidebarState: !state.sidebarState,
    });

  return (
    <StyledLayout>
      <Sidebar
        collapsed={state.sidebarState}
        state={state}
        setState={setState}
      />
      <StyledContent className="p-4">
        <div className="d-flex mb-6">
          <Button onClick={handleSidebar} size="small" className="mt-1">
            <LayoutOutlined />
          </Button>
          <StyledContainer>
            <FilterConditions filters={state} onFilterChange={setState} />
          </StyledContainer>
          <Button>
            <FormattedMessage
              id="reports.download-csv.text"
              defaultMessage="Download CSV"
            />
          </Button>
        </div>
        <div>
          <MemberGameReportTable state={state} />
        </div>
      </StyledContent>
    </StyledLayout>
  );
};

export default MemberGameReport;
