import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'start-date.text': {
    id: 'start-date.text',
    defaultMessage: 'Start date',
  },
  'end-date.text': {
    id: 'end-date.text',
    defaultMessage: 'End date',
  },
});
