import gql from 'graphql-tag';

export const CREATE_PROMO = gql`
  mutation createPromo($input: CreatePromoInput!) {
    createPromo(input: $input)
  }
`;

export const CREATE_PROMO_CATEGORY = gql`
  mutation createPromoCategory($input: CreatePromoCategoryInput!) {
    createPromoCategory(input: $input)
  }
`;

export const SUBMIT_PROMO = gql`
  mutation submitPromo($id: ID!) {
    submitPromo(id: $id)
  }
`;

export const UPDATE_PROMO = gql`
  mutation updatePromo($id: ID!, $input: UpdatePromoInput!) {
    updatePromo(id: $id, input: $input)
  }
`;

export const UPDATE_PROMO_CATEGORY = gql`
  mutation updatePromoCategory($id: ID!, $input: UpdatePromoCategoryInput!) {
    updatePromoCategory(id: $id, input: $input)
  }
`;

export const DELETE_PROMO = gql`
  mutation deletePromo($id: ID!) {
    deletePromo(id: $id)
  }
`;

export const DELETE_PROMO_CATEGORY = gql`
  mutation deletePromoCategory($id: ID!) {
    deletePromoCategory(id: $id)
  }
`;

// promo next
export const CREATE_PROMO_NEXT = gql`
  mutation createPromo($input: CreatePromoInput!) {
    createPromo(input: $input)
  }
`;

export const UPDATE_PROMO_NEXT = gql`
  mutation updatePromo($id: ID!, $input: UpdatePromoInput!) {
    updatePromo(id: $id, input: $input)
  }
`;

export default {};
