import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'pages/components/Reports/messages';
import { FilterItem } from 'components/FilterItem/FilterItem';
import useTranslate from 'utils/useTranslate';
import { DATE_FORMAT } from 'constants/date';
import { StyledRangePicker } from './styles';

function DateRangeFilter() {
  const translate = useTranslate();
  return (
    <FilterItem
      label={
        <FormattedMessage
          id="reports.date-range.text"
          defaultMessage="Date Range"
        />
      }
    >
      <StyledRangePicker
        placeholder={[
          translate(messages['reports.from.text']),
          translate(messages['reports.to.text']),
        ]}
        format={DATE_FORMAT}
      />
    </FilterItem>
  );
}

export default DateRangeFilter;
