import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import ALL_PERMISSIONS from 'constants/permissions3';
import FiltersProvider, { useFilterValues } from 'contexts/Filters';
import {
  GET_MEMBER_TAGS,
  GET_MEMBER_TAGS_IDS,
} from 'graphql/queries/memberTag.query';
import { isEqual } from 'lodash';
import NewLabel from 'pages/components/MemberLabels/components/LabelsTable/components/NewLabel/NewLabel';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useMemo, useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePermissions } from 'store/accountState';
import { StyledTagContainer } from 'styles';
import coercedGet from 'utils/coercedGet';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import removeNull from 'utils/removeNull';
import { useTablePagination } from 'utils/usePagination';
import useTranslate from 'utils/useTranslate';
import FilterConditions from './components/FilterConditions';
import LabelsTable from './components/LabelsTable';
import SearchSettingsForm from './components/SearchSettingsForm';
import FilterItems from './components/FilterItems';

const initialFilters = {
  name: null,
};

const pageInitState = {
  first: 10,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
};

const MemberLabels = () => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST, ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['LIST', 'CREATE'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_LABEL_MANAGEMENT
  );

  const [expandedTags, setExpandedTags] = useState(false);

  const { filters, setFilters, initialValues, resetFilter } = useFilterValues();

  const [page, dispatchPagination, actions] = useTablePagination(pageInitState);

  const memoizedFilter = useMemo(() => removeNull(filters), [filters]);

  const {
    loading,
    data = {},
    refetch,
    refetchVariables,
  } = usePartialFiltersQuery(
    GET_MEMBER_TAGS_IDS,
    GET_MEMBER_TAGS,
    'memberTags.edges',
    memoizedFilter,
    page,
    ['name'],
    'no-cache'
  );

  const { pageInfo, totalCount } = coercedGet(data, 'memberTags', {});
  const edges = coercedGet(data, 'memberTags.edges', []);
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const translate = useTranslate();

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <Layout.Header
        leftNode={
          <div className="d-flex align-items-center flex-g-1">
            <ToggleSidebarHead />
            <StyledTagContainer ref={ref} isExpanded={expandedTags}>
              <span className="ml-1">
                {translate(messages.FILTER_CONDITIONS)}:{' '}
              </span>
              <FilterConditions isLoading={loading} />
            </StyledTagContainer>
            <LegacyIconToggle
              forwardedRef={ref}
              filters={filters}
              expandedTags={expandedTags}
              toggleExpandedTags={setExpandedTags}
            />
            {!isEqual(filters, initialValues) && (
              <ALink
                onClick={() => resetFilter()}
                style={{ marginTop: '0.35rem' }}
              >
                {translate(messages.CLEAR_ALL)}
              </ALink>
            )}
          </div>
        }
        rightNode={
          <>
            <ColumnsFilterNew
              iconOnly
              tabId="member-label-management"
              className="mr-2"
            />
            {ALLOWED_CREATE && <NewLabel />}
          </>
        }
      />

      <Layout.Content
        sideBar={
          <Layout.Sidebar
            quickSearch={{
              filters,
              contextKey: 'member-label-management',
              searchSettingsForm: SearchSettingsForm,
              quickSearchId: QuickSearchIds.LABEL_MANAGEMENT,
              isNext: true,
              onFilterChange: (e) =>
                setFilters((prev: Record<string, any>) => ({ ...prev, ...e })),
            }}
          >
            <FilterItems filters={filters} handleChange={setFilters} />
          </Layout.Sidebar>
        }
        footer={
          <Layout.Footer
            loading={loading}
            onNext={() =>
              dispatchPagination({
                type: actions.NEXT,
                payload: pageInfo,
              })
            }
            onPrev={() =>
              dispatchPagination({
                type: actions.PREV,
              })
            }
            page={page}
            resultsCount={totalCount}
            totalPage={totalPage}
            setPageViaPassingValue={(newState) => {
              dispatchPagination({
                type: actions.SETSTATE,
                payload: newState,
              });
            }}
            pageInfo={pageInfo}
          />
        }
      >
        {ALLOWED_LIST && (
          <LabelsTable
            refetchVariables={refetchVariables}
            labels={edges}
            loading={loading}
            refetch={refetch}
          />
        )}
      </Layout.Content>
    </Layout.Container>
  );
};

export default () => (
  <FiltersProvider initialValues={initialFilters}>
    <MemberLabels />
  </FiltersProvider>
);
