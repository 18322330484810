import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { Modal, Button, Spin } from 'antd';
import coercedGet from 'utils/coercedGet';

import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { ALink } from 'components/ALink/ALink';

export const DETAILS = gql`
  query MemberName($id: ID!) {
    memberBetRecord(id: $id) {
      details {
        data
        url
      }
    }
  }
`;

type DetailsModalType = {
  id: string;
  data: string;
};

const DetailsModal = React.memo(({ id, data }: DetailsModalType) => {
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);

  const [
    loadQuery,
    { data: detailsData, loading, error, called },
  ] = useLazyQuery(DETAILS, {
    fetchPolicy: 'network-only',
    variables: { id },
  });

  return (
    <>
      <ALink
        onClick={() => {
          if (!called) loadQuery();
          handleShow();
        }}
      >
        {data}
      </ALink>

      {isShow && (
        <Modal
          centered
          visible
          maskClosable
          width={416}
          onCancel={handleShow}
          footer={[
            <Button type="primary" key="cancel" onClick={handleShow}>
              <FormattedMessage
                id="reports.close.text"
                defaultMessage="Close"
              />
            </Button>,
          ]}
        >
          {loading && (
            <div className="styled-center">
              <FormattedMessage
                id="reports.checking-for-available-data.text"
                defaultMessage="Checking for available data"
              />{' '}
              &nbsp;
              <Spin />
            </div>
          )}
          {error && <TableErrorCell errorMessage={error.message} />}
          {!coercedGet(detailsData, 'memberBetRecord.details.data', null) &&
            !loading && (
              <FormattedMessage
                id="reports.no-vendor-data-available.text"
                defaultMessage="No Vendor Data Available"
              />
            )}
          {coercedGet(detailsData, 'memberBetRecord.details.data', null) &&
            !coercedGet(detailsData, 'memberBetRecord.details.url', null) && (
              <pre>
                {JSON.stringify(
                  coercedGet(detailsData, 'memberBetRecord.details.data', {}),
                  null,
                  2
                )}
              </pre>
            )}
          {coercedGet(detailsData, 'memberBetRecord.details.url', null) && (
            <ALink
              target="_blank"
              href={coercedGet(
                detailsData,
                'memberBetRecord.details.url',
                null
              )}
            >
              <FormattedMessage
                id="reports.more-details.text"
                defaultMessage="More Details"
              />
            </ALink>
          )}
        </Modal>
      )}
    </>
  );
});

export default DetailsModal;
