import styled from 'styled-components';
import { Tabs } from 'antd';

export const StyledCash = styled.div`
  display: inline-block;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  background: #f5f5f5;
  h2,
  div {
    color: #707070;
  }
`;
export const StyledTabs = styled(Tabs)`
  padding: 0px 16px 16px 16px !important;
  .ant-tabs-nav {
    display: flex !important;
    background: white !important;
    border-top-right-radius: 0 !important;
  }

  .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
    .ant-tabs-tab-active.ant-tabs-tab {
      background: white !important;
      color: rgb(94, 157, 241) !important;
    }
    .ant-tabs-tab {
      margin-right: 0px !important;
      line-height: unset !important;
    }
  }

  .ant-tabs-nav-wrap {
    padding-top: 0 !important;
  }

  .ant-tabs-bar {
    border-bottom: 0 !important;
  }

  .ant-tabs-extra-content {
    height: 40px;
    display: flex;
    align-items: center;
  }

  //.ant-tabs-tabpane.ant-tabs-tabpane-active {
  //
  //}

  .ant-tabs-nav-wrap
    .ant-tabs-nav-scroll
    .ant-tabs-nav.ant-tabs-nav-animated
    div
    > div.ant-tabs-tab {
    background: white !important;
  }
`;
export const StyledMainTabs = styled(Tabs)`
  background: white;
  .ant-tabs-nav-wrap
    .ant-tabs-nav-scroll
    .ant-tabs-nav.ant-tabs-nav-animated
    .ant-tabs-tab {
    padding-right: 16px !important;
  }

  .ant-tabs-nav > div > .ant-tabs-tab:nth-child(2) {
    background: #e6e6e6 !important;
  }
`;
export const StyledAmountTitle = styled.div`
  font-size: 12px;
`;
export const StyledAmount = styled.div`
  font-size: 12px;
  padding-top: 4px;
`;
export const StyledMainBalanceTabTitle = styled.div`
  padding-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const WalletBalanceTabPane = styled(Tabs.TabPane)`
  padding: 12px 15px !important;
`;
