import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import React, { ReactElement } from 'react';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

export const FormItem = ({
  children,
  label,
  errors,
  touched,
  keyType,
}: {
  children: ReactElement;
  label: string | ReactElement;
  errors: Record<string, any>;
  touched: Record<string, any>;
  keyType: string;
}) => (
  <Form.Item
    label={label}
    labelCol={{ span: 6 }}
    wrapperCol={{ span: 18 }}
    validateStatus={errors[keyType] && touched[keyType] ? 'error' : undefined}
    help={touched[keyType] ? errors[keyType] : null}
  >
    {children}
  </Form.Item>
);
