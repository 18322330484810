import { useQuery } from '@apollo/react-hooks';
import { ErrorHandler } from 'components/ErrorHandler';
import Layout from 'components/Layout';
import ALL_PERMISSIONS from 'constants/permissions3';
import { useFilterValues } from 'contexts/Filters';
import { PAYMENT_METHODS } from 'graphql/queries/paymentMethodListing.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React, { useState } from 'react';
import { usePermissions } from 'store/accountState';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import HeaderLeft from '../HeaderLeft';
import HeaderRight from '../HeaderRight';
import PaymentMethodsTable from '../PaymentMethodsTable';
import Sidebar from '../Sidebar';

const PaymentMethodsScreen = ({ refreshPage }: { refreshPage: () => void }) => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_PAYMENT_METHODS
  );

  const [page, setPage] = useState<Record<string, any>>({
    first: 10,
    after: undefined,
    savedCursor: [''],
    currentPage: 0,
  });

  const { filters } = useFilterValues();

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: transformMovableDateFilter(filters),
  };
  const { context } = useOperatorHeader();
  const { loading, data = {} } = useQuery(PAYMENT_METHODS, {
    variables: refetchVariables,
    fetchPolicy: 'cache-and-network',
    context,
  });

  const { paymentMethods = {} } = data as Record<string, any>;
  const { totalCount, pageInfo, edges = [] } = paymentMethods;
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={<HeaderLeft />}
          rightNode={
            <HeaderRight filters={filters} refreshPage={refreshPage} />
          }
        />
        <Layout.Content
          sideBar={<Sidebar />}
          footer={
            <Layout.Footer
              leftNode={<div />}
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page as any}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={pageInfo}
            />
          }
        >
          <ErrorHandler>
            {ALLOWED_LIST && (
              <PaymentMethodsTable
                dataSource={edges}
                loading={loading}
                refetchVariables={refetchVariables}
              />
            )}
          </ErrorHandler>
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

export default PaymentMethodsScreen;
