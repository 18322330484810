import React, { useState } from 'react';
import { CirclePicker as Picker } from 'react-color';
import { StyledContainer, StyledColor } from './styles';

type Rec = Record<string, any>;

const CirclePicker: any = Picker;
const colorsArray: string[] = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
];

const colorOptions = colorsArray.reduce(
  (acc, currVal) => ({
    ...acc,
    [currVal]: currVal,
  }),
  {}
);

const getKeyByValue = (obj: Rec, value: string) =>
  Object.keys(obj).find((key) => obj[key] === value);

const ColorPicker = ({
  onChange,
  value,
}: {
  onChange: (e: any) => void;
  value: string;
}) => {
  const [colorValue, setColorValue] = useState(
    getKeyByValue(colorOptions, value)
  );
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const handleChangeComplete = (color: { hex: string }) => {
    setColorValue(color.hex);

    onChange(colorOptions[color.hex]);
    handleShow();
  };

  return (
    <StyledContainer>
      <StyledColor value={colorValue} onClick={handleShow} />
      {isShow && (
        <CirclePicker
          colors={Object.keys(colorOptions)}
          triangle="top-left"
          onChange={handleChangeComplete}
        />
      )}
    </StyledContainer>
  );
};

export default ColorPicker;
