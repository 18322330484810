import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  EllipsisOutlined,
  MenuOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Table, Dropdown, Menu } from 'antd';

import generateId from 'utils/generateId';
import { arrayMove } from 'utils/arrayMove';
import { allGameTypes } from 'constants/gameTypes';

import { StyledTableContainer } from './styles';
import {
  allTypes,
  useCreateLoyaltyProgramContext,
  usePrimaryVipContext,
} from '../../context';
import ColorPicker from './components/ColorPicker';
import NameInput from './components/NameInput';
import DepositAmountInput from './components/DepositAmountInput';
import TurnoverAmountInput from './components/TurnoverAmountInput';
import UpgradeAmountInput from './components/UpgradeAmountInput';
import UpgradeTurnoverInput from './components/UpgradeTurnoverInput';
import DeleteTier from './components/DeleteTier';
import DraggableEditableRow from './components/DraggableEditableRow';
import GameCategoriesInput from './components/GameCategoriesInput';
import CompletionIndicator from './components/CompletionIndicator';

const TierSettings = ({ resetSaveOnly }: { resetSaveOnly: () => void }) => {
  const [contextState, dispatch] = useCreateLoyaltyProgramContext();

  const components = {
    body: {
      row: DraggableEditableRow,
    },
  };

  const impartResponsiveWidth = React.useCallback(
    (value) => ({ width: value } || {}),
    []
  );

  const getColumns = () => [
    {
      title: <></>,
      width: 50,
      render: () => (
        <MenuOutlined className="handle" style={{ cursor: 'pointer' }} />
      ),
    },
    {
      title: <></>,
      width: 50,
      render: () => <CompletionIndicator />,
    },
    {
      title: 'Name',
      key: 'name',
      ...impartResponsiveWidth(110),
      render: () => <NameInput />,
    },

    {
      title: 'Deposit Amount',
      key: 'depositAmount',
      ...impartResponsiveWidth(140),
      render: () => <DepositAmountInput />,
    },

    {
      title: 'Game Categories',
      key: 'gameCategories',
      ...impartResponsiveWidth(150),
      render: () => <GameCategoriesInput />,
    },

    {
      title: 'Turnover Amount',
      key: 'turnoverAmount',
      ...impartResponsiveWidth(160),
      render: () => <TurnoverAmountInput />,
    },
    {
      title: 'Upgrade Award Amount',
      key: 'upgradeAwardAmount',
      ...impartResponsiveWidth(180),
      render: () => <UpgradeAmountInput />,
    },
    {
      title: 'Upgrade Award Turnover',
      key: 'upgradeAwardTurnover',
      ...impartResponsiveWidth(195),
      render: () => <UpgradeTurnoverInput />,
    },
    {
      title: 'Color',
      key: 'color',
      render: (node: Record<string, any>) => (
        <ColorPicker
          value={node.color}
          onChange={(colorString) => {
            dispatch({
              type: allTypes.UPDATE_TIER,
              payload: {
                id: node.id,
                color: colorString,
              },
            });
          }}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (item: Record<string, any>) => (
        <>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    dispatch({
                      type: allTypes.SET_ACTIVE_SCREEN,
                      payload: allTypes.ADD_TIER_FORMS,
                    });

                    dispatch({
                      type: allTypes.SET_ACTIVE_TIER,
                      payload: item,
                    });
                  }}
                >
                  <Button type="link" className="text-black" block>
                    <FormattedMessage id="edit.text" defaultMessage="Edit" />
                  </Button>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    dispatch({
                      type: allTypes.ADD_TIER,
                      payload: {
                        ...item,
                        name: `Copy of ${item.name}`,
                        id: generateId('mll'),
                      },
                    });
                  }}
                >
                  <Button type="link" className="text-black" block>
                    <FormattedMessage
                      id="duplicate.text"
                      defaultMessage="Duplicate"
                    />
                  </Button>
                </Menu.Item>

                <Menu.Item>
                  <Button type="link" className="text-black" block>
                    <DeleteTier tierId={item.id} />
                  </Button>
                </Menu.Item>
              </Menu>
            }
          >
            <Button type="link" icon={<EllipsisOutlined />} />
          </Dropdown>
        </>
      ),
    },
  ];

  const isPrimaryVip = usePrimaryVipContext();

  const initQual = {
    type: 'BASE',
    and: [
      {
        type: 'DEPOSIT',
        amount: 0,
      },
      {
        type: 'EFFECTIVE_BET',
        amount: 0,
        gameTypes: [...allGameTypes],
        isSingleObject: true,
      },
    ],
  };
  const newValue = {
    id: generateId('mll'),
    name: '',
    description: '',
    inviteOnly: false,
    qualificationCriteria: initQual,
    color: '#000000',
    autoPayout: false,
    upgradePayout: {
      amount: 0,
      turnover: 0,
      limit: 1,
    },
    ...(isPrimaryVip
      ? {
          withdrawalLimitRequestReset: 'DAILY',
          withdrawalLimitFee: 0,
          withdrawalLimitFeeType: 'RELATIVE',
        }
      : null),
  };

  const moveRow = async (dragIndex: number, hoverIndex: number) => {
    dispatch({
      type: allTypes.SET_TIERS,
      payload: arrayMove(contextState.tiers, dragIndex, hoverIndex),
    });
  };

  return (
    <StyledTableContainer>
      <div className="button-group py-6">
        <Button
          type="primary"
          size="large"
          onClick={() => {
            resetSaveOnly();
            dispatch({
              type: allTypes.ADD_TIER,
              payload: newValue,
            });
          }}
        >
          <PlusOutlined />
          <FormattedMessage id="add-tier.text" defaultMessage="Add Tier" />
        </Button>
      </div>

      <Table
        components={components}
        showHeader={false}
        columns={getColumns()}
        size="middle"
        dataSource={contextState.tiers}
        rowKey={(x) => x.id}
        onRow={(record, index) =>
          ({
            index,
            moveRow,
            node: record,
          } as any)
        }
        pagination={false}
      />
    </StyledTableContainer>
  );
};

export default TierSettings;
