import gql from 'graphql-tag';

export const MEMBER_TAG = gql`
  query MemberTag($id: ID!) {
    memberTag(id: $id) {
      id
      name
      color
    }
  }
`;

export default {};
