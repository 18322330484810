export const TabId = {
  DASHBOARD: 'dashboard',
  TODAYS_INSTANT_FOCUS: 'todays-instant-focus',
  MEMBER_MANAGEMENT: 'member-management',
  MEMBER_DETAIL: 'member-detail',
  INTERNAL_DEPOSIT_REQUESTS: 'deposit-requests-internal',
  EXTERNAL_DEPOSIT_REQUESTS: 'deposit-requests-external',
  PAYMENT_METHOD_LISTING: 'payment-method-listing',
  THIRD_PARTY_DEPOSIT_PROVIDERS: 'deposit-providers-external',
  PROMO_LISTING: 'promo-listing',
  PROMO_REQUEST: 'promo-request',
  PROMO_REQUESTS: 'promo-requests',
  PROMO_LABELS: 'promo-labels',
  CREATE_PROMO: 'create-promo',
  MANAGE_PROMO: 'manage-promo',
  PROMO_PAYOUT_REPORTS: 'promo-payout-reports',
  WITHDRAWAL_REQUESTS: 'withdrawal-requests',
  WITHDRAWAL_METHODS: 'withdrawal-methods',
  COMPLIANCE_CHECK: 'compliance-check',
  THIRD_PARTY_WITHDRAWAL_PROVIDERS: 'withdrawal-providers-external',
  OPERATOR_ACCOUNT_MANAGEMENT: 'account-management',
  PERMISSION_GROUP: 'permission-group',
  VENDOR_MANAGEMENT: 'vendor-management',
  MEMBER_LEVEL_MANAGEMENT: 'member-level-management',
  MEMBER_LABEL_MANAGEMENT: 'member-label-management',
  DAILY_OPERATING_REPORT: 'daily-operating-report',
  MEMBER_BET_RECORDS: 'member-bet-records',
  BALANCE_TRANSACTION_RECORDS: 'balance-transaction-records',
  REPORTS: 'reports',
  VENDOR_GAME_REPORTS: 'vendor-game-reports',
  PROMO_PAYOUT_RECORDS: 'promo-payout-records',
  STATISTICAL_REPORTS: 'statistical-reports',
  ANALYSIS_REPORTS: 'analysis-reports',
  REBATES_REPORTS: 'rebates-reports',
  TOTAL_NUMBER_OF_PARTICIPATING_MEMBER: 'total-number-of-participating-member',
  TOTAL_NUMBER_OF_PARTICIPATING_MEMBER_PROMO:
    'total-number-of-participating-member-promo',
  LOTTERY_REPORTS: 'lottery-reports',
  MEMBER_GAME_REPORTS: 'member-game-reports',
  AUTOMATION: 'automation',
  AFFILIATE_PROGRAMME: 'affiliate-programme',
  AGENT_AFFILIATE: 'agent-affiliate',
  AGENT_AFFILIATE_CRITERIA: 'affiliate-criteria',
  AGENT_AFFILIATE_REQUEST: 'affiliate-request',
  AGENT_REQUEST: 'agent-request',
  AGENT_PAYOUT_REPORT: 'agent-payout-report',
  MEMBERS_ONLINE: 'members-online',
  SET_AGENCY_COMMISSION: 'set-agency-commission',
  SITE_CONFIGURATIONS: 'site-configurations',
  MOBILE_APP_CONFIGURATION: 'mobile-app-configuration',
  MARQUEE_MESSAGES: 'marquee-messages',
  ROTATING_BANNER: 'rotating-banner',
  MOBILE_APP_CONFIG_VENDORS: 'mobile-app-config-vendors',
  SPECIFIC_GAME_VENDORS: 'specific-game-vendors',
  CATEGORY_GAMES: 'category-games',
  CATEGORY_VENDOR_GAMES: 'category-vendor-games',
  PROFILE_SETTING: 'profile-setting',
  ALERTS_AND_NOTIFICATIONS: 'alerts-and-notifications',
  GEO_FENCING: 'geo-fencing',
  SYSTEM_MESSAGE: 'system-message',
  MANUAL_MESSAGE: 'manual-message',
  AUTOMATED_MESSAGE: 'automated-message',
  BALANCE_SERVICE_RECORD_REPORT: 'balance-service-record-report',
  VIP: 'vip',
  REBATES: 'rebates',
  SYSTEM_MANAGEMENT: 'system-management',
  MEMBER_ACCESS_AUDIT_HISTORY: 'member-access-audit-history',
  MEMBER_IP_ADDRESS_SUMMARY: 'member-ip-address-summary',
  IP_ADDRESS_SUMMARY: 'ip-address-summary',
  MEMBER_ACCESS_SUMMARY_REPORT: 'member-access-summary-report',
  MEMBER_INTERACTION_LOG_REPORT: 'member-interaction-log-report',
  PROMO_CONFLICT: 'promo-conflict',
  MISSIONS: 'missions',
  MOBILE_LANDSCAPE_VENDOR_CONFIGURATION:
    'mobile-landscape-vendor-configuration',
  MOBILE_PORTRAIT_VENDOR_CONFIGURATION: 'mobile-portrait-vendor-configuration',
  DESKTOP_VENDOR_CONFIGURATION: 'desktop-vendor-configuration',
  MEMBER_SUMMARY_REPORT: 'member-summary-report',
};

export default {};
