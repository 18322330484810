import React, { useState } from 'react';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Select } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { checkPartial } from 'utils/partialUtils';
import useTranslate from 'utils/useTranslate';
import { uniq } from 'lodash';
import { MembersConnectionsEdge } from 'types/graphqlTypes';
import { POSTAL_CODES } from './queries';
import { StyledLabel, StyledSpan, StyledSelect } from '../../styles';

interface Props {
  value: {
    postalCode?: Record<string, object>;
  };
  onChange: (e: Record<string, object> | null) => void;
  disabled: boolean;
}

export const PostalCodeFilter: React.FC<Props> = ({
  onChange,
  value,
  disabled,
}) => {
  const translate = useTranslate();
  const [options, setOptions] = useState<string[]>([]);
  const [input, setInput] = useState<string>('');
  const debouncedInput = useDebounce(input, 500);

  const [loadPostalCodes, { loading }] = useLazyQuery(POSTAL_CODES, {
    fetchPolicy: 'network-only',
    variables: {
      first: 10,
      filter: {
        postalCode: {
          contains: debouncedInput,
        },
      },
    },
    onCompleted: (data) => {
      const {
        members: { edges = [] },
      } = data;

      const postCodes = edges.map(
        ({ node }: MembersConnectionsEdge) => node.address?.postCode
      );

      const uniqPostCodes: string[] = uniq(postCodes);

      setOptions(uniqPostCodes);
    },
  });

  const values = value?.postalCode?.in ?? [];

  const [partial, setPartial] = React.useState('');

  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange({ in: e });
    } else {
      onChange(null);
    }

    setPartial('');
    setInput('');
  };

  const hasPartial = checkPartial('postalCode', value);

  return (
    <div className="mt-1">
      <div className="d-flex justify-content-between">
        <div>
          <StyledLabel>
            <FormattedMessage id="POST_CODE.text" defaultMessage="Post Code" />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        mode="multiple"
        value={values}
        showSearch
        allowClear
        placeholder={translate(messages.ENTER_POSTAL_CODE)}
        onChange={handleChange}
        filterOption={false}
        loading={loading}
        onSearch={(text: string) => {
          setPartial(text);
          setInput(text);
        }}
        disabled={disabled}
        onFocus={() => setInput('')}
        onBlur={() => setInput('')}
        defaultActiveFirstOption
        onDropdownVisibleChange={(open: boolean) => {
          if (open && !options.length) loadPostalCodes();
        }}
      >
        {!hasPartial && partial.length && (
          <Select.Option
            key={11}
            value={`${translate(messages.PARTIAL)}: ${partial}`}
            label={`${translate(messages.PARTIAL)}: ${partial}`}
          >
            <FormattedMessage id="partial.text" defaultMessage="Partial" />:{' '}
            {partial}
          </Select.Option>
        )}

        {options.map((postCode, index) => (
          <Select.Option key={index} value={postCode}>
            {postCode}
          </Select.Option>
        ))}
      </StyledSelect>
    </div>
  );
};
