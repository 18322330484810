import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Card, Row, Col, Typography, Menu, Dropdown, Switch } from 'antd';
import styled from 'styled-components';
import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { get } from 'lodash';
import { useFormikContext } from 'formik';
import { GameTypeNames } from 'constants/gameTypes';
import { Locale } from 'constants/locale';
import messages from 'messages';
import { customFormatMessage } from 'utils/customFormatMessage';
import { paste, setGGRTierFields, getCopiedValues } from '../../constants';
import { useAffiliateState } from '../../../../../../../../context';
import {
  useCreateAffiliateProgrammeState,
  CreateAffiliateProgrammeTypes,
} from '../../../../../../context';
import {
  useCostSettingsContext,
  CostSettingsFilters,
  CostSettingsTypes,
} from '../../context';

const StyledSwitch = styled(Switch)<any>`
  &.ant-switch-checked {
    background-color: #3bc98c;
  }
  flex: none;
`;

const StyledCard = styled(Card)<any>`
  width: 250px;

  .ant-card-body {
    min-height: 56px;
    padding-left: 12px;
    padding-right: 12px;
  }
  box-shadow: ${(props) =>
    props.focuseditem ? '0.5px 0.5px 12px 0px rgba(0, 0, 0, 0.25)' : ''};
  z-index: ${(props) => (props.focuseditem ? 15 : 1)};
`;

const menu = {
  column: (pasteCharge: any) => (
    <Menu>
      <Menu.Item onClick={() => pasteCharge()}>
        <FormattedMessage
          id="paste-ggr-charge.text"
          defaultMessage="Paste GGR Charge"
        />
      </Menu.Item>
    </Menu>
  ),
  card: (menuFunctions: any) => (
    <Menu>
      <Menu.Item onClick={() => menuFunctions.copyCharge()}>
        <FormattedMessage
          id="copy-ggr-charge.text"
          defaultMessage="Copy GGR Charge"
        />
      </Menu.Item>
      <Menu.Item onClick={() => menuFunctions.pasteCharge()}>
        <FormattedMessage
          id="paste-ggr-charge.text"
          defaultMessage="Paste GGR Charge"
        />
      </Menu.Item>
    </Menu>
  ),
};

const isActive = (
  activeVendor: Record<string, any>,
  vendor: string,
  gameType: string
) => activeVendor.id === vendor && activeVendor.gameType === gameType;

const GameTypesByVendor = ({
  gameTypes,
  vendor,
  sortIndex,
}: {
  gameTypes: any[];
  vendor: string;
  sortIndex: number;
}): any => {
  const intl = useIntl();
  const [{ activeVendor }, dispatch] = useCreateAffiliateProgrammeState();
  const [
    { copied: copiedValues },
    costSettingsDispatch,
  ] = useCostSettingsContext();
  const { values, setFieldValue } = useFormikContext() as any;

  return gameTypes.map((gameType, index) => {
    const name = `vendorHandlers[${gameType}_${vendor}][ggrChargeCostSharing]`;
    const checked = get(values, name, false);
    const activeVendorDetails = {
      id: vendor,
      gameType,
    };

    const vendorValues = getCopiedValues({ values, gameType, id: vendor });

    const menuFunctions = {
      copyCharge: () => {
        costSettingsDispatch({
          type: CostSettingsTypes.SET_COPIED,
          payload: vendorValues,
        });
      },
      pasteCharge: () => {
        paste(
          { gameType, id: vendor, setFieldValue },
          copiedValues,
          setGGRTierFields
        );
      },
    };

    if (index === 0 && sortIndex === 0 && !activeVendor.id) {
      dispatch({
        type: CreateAffiliateProgrammeTypes.SET_ACTIVE_VENDOR,
        payload: activeVendorDetails,
      });
    }
    const translate = (messageVal: any, opts = null) =>
      customFormatMessage(intl.formatMessage, messageVal, opts);
    return (
      <Row key={`${gameType}_${vendor}`}>
        <StyledCard
          focuseditem={isActive(activeVendor, vendor, gameType)}
          hoverable
          key={`${gameType}_${vendor}`}
          className={index % 2 && cx('bg-light')}
          onClick={() => {
            if (!isActive(activeVendor, vendor, gameType)) {
              dispatch({
                type: CreateAffiliateProgrammeTypes.SET_ACTIVE_VENDOR,
                payload: activeVendorDetails,
              });
            }
          }}
        >
          <Row style={{ display: 'flex', justifyItems: 'space-between' }}>
            <Col span={20} style={{ padding: 0 }}>
              <div>
                <Typography.Text style={{ fontSize: 13 }}>
                  {translate(messages[`${gameType.toLowerCase()}.text`])}
                </Typography.Text>
              </div>
            </Col>
            <Col span={4} style={{ marginTop: '-4px' }}>
              <div className="d-flex flex-column align-items-center">
                <Dropdown overlay={menu.card(menuFunctions)} className="mb-2">
                  <EllipsisOutlined style={{ cursor: 'pointer' }} />
                </Dropdown>
                <StyledSwitch
                  size="small"
                  unCheckedChildren={
                    <FormattedMessage defaultMessage="Off" id="off.text" />
                  }
                  checkedChildren={
                    <FormattedMessage defaultMessage="On" id="on.text" />
                  }
                  name={name}
                  checked={checked}
                  onChange={(v: any) => setFieldValue(name, v)}
                />
              </div>
            </Col>
          </Row>
        </StyledCard>
      </Row>
    );
  });
};

const VendorsByGameType = ({
  gameType,
  vendors,
  sortIndex,
}: {
  gameType: any;
  vendors: any[];
  sortIndex: number;
}): any => {
  const intl = useIntl();
  const [{ activeVendor }, dispatch] = useCreateAffiliateProgrammeState();
  const [
    { copied: copiedValues },
    costSettingsDispatch,
  ] = useCostSettingsContext();
  const { values, setFieldValue } = useFormikContext();

  return vendors
    .filter(({ node }) => node.gameTypes.includes(gameType))
    .map(({ node: vendor }, index) => {
      const name = `vendorHandlers[${gameType}_${vendor.id}][ggrChargeCostSharing]`;
      const checked = get(values, name, false);

      const activeVendorDetails = {
        id: vendor.id,
        gameType,
      };

      const vendorValues = getCopiedValues({ values, gameType, id: vendor.id });

      const menuFunctions = {
        copyCharge: () => {
          costSettingsDispatch({
            type: CostSettingsTypes.SET_COPIED,
            payload: vendorValues,
          });
        },
        pasteCharge: () => {
          paste(
            { gameType, id: vendor.id, setFieldValue },
            copiedValues,
            setGGRTierFields
          );
        },
      };

      if (index === 0 && sortIndex === 0 && !activeVendor.id) {
        dispatch({
          type: CreateAffiliateProgrammeTypes.SET_ACTIVE_VENDOR,
          payload: activeVendorDetails,
        });
      }

      return (
        <Row key={`${gameType}_${vendor.id}`}>
          <StyledCard
            hoverable
            focuseditem={isActive(activeVendor, vendor.id, gameType)}
            key={`${gameType}_${vendor.id}`}
            className={index % 2 && cx('bg-light')}
            onClick={() => {
              if (!isActive(activeVendor, vendor.id, gameType)) {
                dispatch({
                  type: CreateAffiliateProgrammeTypes.SET_ACTIVE_VENDOR,
                  payload: activeVendorDetails,
                });
              }
            }}
          >
            <Row style={{ display: 'flex', justifyItems: 'space-between' }}>
              <Col span={20} style={{ padding: 0 }}>
                <div>
                  <Typography.Text style={{ fontSize: 13 }}>
                    {intl.locale === Locale.EN
                      ? `${vendor.nameEn || vendor.name}`
                      : `${vendor.nameZh || vendor.name}`}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={4} style={{ marginTop: '-4px' }}>
                <div className="d-flex flex-column align-items-center">
                  <Dropdown overlay={menu.card(menuFunctions)} className="mb-2">
                    <EllipsisOutlined style={{ cursor: 'pointer' }} />
                  </Dropdown>
                  <StyledSwitch
                    size="small"
                    unCheckedChildren={
                      <FormattedMessage defaultMessage="Off" id="off.text" />
                    }
                    checkedChildren={
                      <FormattedMessage defaultMessage="On" id="on.text" />
                    }
                    name={name}
                    checked={checked}
                    onChange={(v: any) => setFieldValue(name, v)}
                  />
                </div>
              </Col>
            </Row>
          </StyledCard>
        </Row>
      );
    });
};

const SortByGameVendors = (): any => {
  const [{ vendors }] = useAffiliateState() as any;
  const { setFieldValue } = useFormikContext();
  const [
    { subFilters, search, searchFilter, copied: copiedValues },
  ] = useCostSettingsContext();
  const intl = useIntl();
  const defaultItems = subFilters.length
    ? subFilters.map((item: Record<string, any>) =>
        vendors.find(
          ({ node }: { node: Record<string, any> }) => node.id === item.value
        )
      )
    : vendors;

  const onSearch = (value: any, defaults: any[]) => {
    if (!value.length) return defaults;

    switch (searchFilter) {
      case CostSettingsFilters.GAME_VENDOR:
        return defaults.filter(({ node }) =>
          node.name.toUpperCase().includes(value.toUpperCase())
        );
      case CostSettingsFilters.GAME_CATEGORY:
        return defaults.map((vendor) => ({
          ...vendor,
          node: {
            ...vendor.node,
            gameTypes: vendor.node.gameTypes.filter((gameType: any) =>
              gameType
                .toString()
                .toUpperCase()
                .startsWith(value.toUpperCase())
            ),
          },
        }));
      default:
        return defaults;
    }
  };

  const items = onSearch(search, defaultItems);

  const pasteCharge = (gameTypes: any, id: string) => {
    gameTypes.forEach((gameType: string) => {
      paste({ gameType, id, setFieldValue }, copiedValues, setGGRTierFields);
    });
  };

  return items.map(({ node: { gameTypes, name, nameEn, nameZh, id } }, index) =>
    gameTypes.length > 0 ? (
      <div key={index} style={{ padding: 0 }}>
        <Row className="mb-2">
          <Col span={19}>
            <Typography.Text strong>
              {intl.locale === Locale.EN
                ? `${nameEn || name}`
                : `${nameZh || name}`}
            </Typography.Text>
          </Col>
          <Col span={3} style={{ marginLeft: '2px' }}>
            <Dropdown overlay={menu.column(() => pasteCharge(gameTypes, id))}>
              <EllipsisOutlined style={{ cursor: 'pointer' }} />
            </Dropdown>
          </Col>
        </Row>
        <GameTypesByVendor
          gameTypes={gameTypes}
          vendor={id}
          sortIndex={index}
        />
      </div>
    ) : (
      <></>
    )
  );
};

const SortByGameCategories = () => {
  const [{ vendors }] = useAffiliateState() as any;
  const { setFieldValue } = useFormikContext();
  const [
    { subFilters, search, searchFilter, copied: copiedValues },
  ] = useCostSettingsContext();
  const intl = useIntl();

  const defaultItems = subFilters.length
    ? subFilters.map((item: Record<string, any>) => item.value)
    : Object.keys(GameTypeNames);

  const onSearch = (value: string, defaults: any) => {
    switch (searchFilter) {
      case CostSettingsFilters.GAME_VENDOR:
        return defaults;
      case CostSettingsFilters.GAME_CATEGORY:
        return defaults.filter((filter: string) =>
          filter.toUpperCase().startsWith(value.toUpperCase())
        );
      default:
        return defaults;
    }
  };

  const onVendorSearch = (value: string, defaults: any[]) => {
    if (!value.length) return defaults;

    return defaults.filter(({ node: { name } }) =>
      name.toUpperCase().includes(value.toUpperCase())
    );
  };

  const items = onSearch(search, defaultItems);
  const vendorItems = onVendorSearch(search, vendors);

  const pasteCharge = (gameType: string, ids: string[]) => {
    ids.forEach((id) => {
      paste({ gameType, id, setFieldValue }, copiedValues, setGGRTierFields);
    });
  };
  const translate = (messageVal: any, opts = null) =>
    customFormatMessage(intl.formatMessage, messageVal, opts);
  return items.map(
    (gameType: string, index: number) =>
      Boolean(
        vendorItems.filter((vendor) => vendor.node.gameTypes.includes(gameType))
      ) && (
        <div key={index} style={{ padding: 0 }}>
          <Row className="mb-2">
            <Col span={19}>
              <Typography.Text strong>
                {translate(messages[`${gameType.toLowerCase()}.text`])}
              </Typography.Text>
            </Col>
            <Col span={3} style={{ marginLeft: '2px' }}>
              <Dropdown
                overlay={menu.column(() =>
                  pasteCharge(
                    gameType,
                    vendorItems.map(({ node: { id } }) => id)
                  )
                )}
              >
                <EllipsisOutlined style={{ cursor: 'pointer' }} />
              </Dropdown>
            </Col>
          </Row>
          <VendorsByGameType
            gameType={gameType}
            vendors={vendorItems}
            sortIndex={index}
          />
        </div>
      )
  );
};

function VendorContent() {
  const [{ sortFilter }] = useCostSettingsContext();
  return (
    <div style={{ display: 'flex' }}>
      {sortFilter === CostSettingsFilters.GAME_VENDOR && <SortByGameVendors />}
      {sortFilter === CostSettingsFilters.GAME_CATEGORY && (
        <SortByGameCategories />
      )}
    </div>
  );
}

export default VendorContent;
