import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Button } from 'antd';
import { withErrorHandler } from 'components/ErrorHandler';
import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import ALL_PERMISSIONS from 'constants/permissions3';
import { AFFILIATE_PROGRAMMES } from 'graphql/queries/affiliateProgramme.query';
import { VENDORS } from 'graphql/queries/vendor.query';
import { Page, PageInfo } from 'interfaces/user.interface';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePermissions } from 'store/accountState';
import { StyledTagContainer } from 'styles';
import { AffiliateProgrammesFilterInput } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import removeNull from 'utils/removeNull';
import { customFormatMessage } from '../../../utils/customFormatMessage';
import AffiliateDetails from './components/AffiliateDetails';
import AgentAffiliateTable from './components/AgentAffiliateTable';
import CreateAffiliateProgramme from './components/CreateAffiliateProgramme';
import { CreateAffiliateProgrammeProvider } from './components/CreateAffiliateProgramme/context';
import FilterConditions from './components/FilterConditions';
import Sidebar from './components/Sidebar';
import { AffiliateTypes, useAffiliateState } from './context';
import SearchSettingsForm from './components/SearchSettingsForm/SearchSettingsForm';

const pageInitState: Page = {
  after: undefined,
  savedCursor: [undefined] as any,
  currentPage: 0,
  first: 10,
};

const AffiliateProgramme = () => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST, ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['LIST', 'CREATE'],
    ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES_AFFILIATE_PROGRAMME
  );

  const defaultFilters: AffiliateProgrammesFilterInput = {
    name: null,
    status: null,
    default: null,
    negativeCarryOverEnabled: null,
  };

  const [filters, setFilters] = React.useState(defaultFilters);

  const handleFilters = (e: AffiliateProgrammesFilterInput) => {
    setFilters(e);
  };

  const intl = useIntl();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageInfo, setPageInfo] = useState<Partial<PageInfo>>({});

  const [affiliateState, dispatch] = useAffiliateState() as any;

  const [page, setPage] = useState<Page>(pageInitState);

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor!);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: removeNull(filters),
  };

  const setResultInfo = (data: { pageInfo: PageInfo; totalCount: number }) => {
    setTotalCount(data.totalCount);
    setPageInfo(data.pageInfo);
  };

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const showForm = (record: any = null) => {
    if (record.id) {
      dispatch({
        type: AffiliateTypes.SET_ACTIVE_AFFILIATE_PROGRAMME,
        payload: record.id,
        meta: record,
      });
    }
    dispatch({
      type: AffiliateTypes.SHOW_CREATE_AFFILIATE_PROGRAMME,
    });
  };

  useQuery(VENDORS, {
    fetchPolicy: 'network-only',
    onCompleted: (res: any) => {
      const vendors = coercedGet(res, 'vendors.edges', []);
      const defaultVendorHandlers = vendors.reduce(
        (acc: any, { node }: any) => {
          const gameTypes = coercedGet(node, 'gameTypes', []);
          let newCharges = {};
          gameTypes.forEach((gameType: any) => {
            newCharges = {
              ...newCharges,
              [`${gameType}_${node.id}`]: {
                vendor: node.id,
                gameType,
              },
            };
          });
          return { ...acc, ...newCharges };
        },
        {}
      );
      dispatch({
        type: AffiliateTypes.SET_DEFAULT_VENDORS,
        payload: vendors,
      });
      dispatch({
        type: AffiliateTypes.SET_DEFAULT_VENDOR_HANDLERS,
        payload: defaultVendorHandlers,
      });
    },
  });

  const { loading, error, data, refetch } = useQuery(AFFILIATE_PROGRAMMES, {
    variables: refetchVariables,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      setResultInfo(res.affiliateProgrammes);
    },
  });

  const edges = coercedGet(data, 'affiliateProgrammes.edges', []).map(
    (item: any, key: number) => ({
      ...item.node,
      key: key + 1,
    })
  );

  const translate = (value: any) =>
    customFormatMessage(intl.formatMessage, value);

  const ref = React.useRef<HTMLDivElement>(null);
  const [expandedTags, setExpandedTags] = useState(false);

  return affiliateState.activeScreen ===
    AffiliateTypes.AGENT_AFFILIATE_DETAILS ? (
    <AffiliateDetails showForm={showForm} />
  ) : (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={
            <div className="d-flex align-items-start flex-g-1">
              <ToggleSidebarHead />
              <StyledTagContainer ref={ref} isExpanded={expandedTags}>
                <span className="ml-1">
                  {translate(messages.FILTER_CONDITIONS)}:{' '}
                </span>{' '}
                <FilterConditions
                  onFilterChange={handleFilters}
                  filters={filters}
                />
              </StyledTagContainer>
              <LegacyIconToggle
                forwardedRef={ref}
                filters={filters}
                expandedTags={expandedTags}
                toggleExpandedTags={setExpandedTags}
              />
              <ClearAllFilterButton
                initialFilters={defaultFilters}
                currentFilters={filters}
                onFilterChange={() => handleFilters(defaultFilters)}
                style={{ marginTop: '0.35rem' }}
              />
            </div>
          }
          rightNode={
            <div className="flex align-items-center">
              <RefreshButton onClick={() => refetch(refetchVariables)} />
              {ALLOWED_CREATE && (
                <Button
                  data-testid="create-programme"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={showForm}
                >
                  {translate(messages.CREATE_PROGRAMME)}
                </Button>
              )}
            </div>
          }
        />
        <Layout.Content
          sideBar={
            <Layout.Sidebar
              quickSearch={{
                filters,
                contextKey: 'affiliate-programme',
                onFilterChange: handleFilters,
                searchSettingsForm: SearchSettingsForm,
                quickSearchId: QuickSearchIds.AFFILIATE_PROGRAMME,
                isNext: true,
              }}
            >
              <>
                <Sidebar filters={filters} onFilterChange={setFilters} />
              </>
            </Layout.Sidebar>
          }
          footer={
            <Layout.Footer
              loading={false}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={data?.pageInfo}
            />
          }
        >
          {affiliateState.activeScreen ===
            AffiliateTypes.AGENT_AFFILIATE_LIST &&
            ALLOWED_LIST && (
              <AgentAffiliateTable
                data={edges}
                error={error}
                loading={loading}
                refetchVariables={refetchVariables}
                showForm={showForm}
                refetch={refetch}
              />
            )}

          <CreateAffiliateProgrammeProvider>
            <CreateAffiliateProgramme />
          </CreateAffiliateProgrammeProvider>
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

export default withErrorHandler(AffiliateProgramme);
