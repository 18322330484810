import React, { useState, useEffect } from 'react';
import messages from 'messages';
import { useIntl } from 'react-intl';
import { useFilterValues } from 'contexts/Filters';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';
import { Tag } from 'antd';
import coercedGet from 'utils/coercedGet';
import AccountTag from 'components/AccountTag';
import { PAYMENT_TYPES_DEPOSIT } from 'constants/paymentTypes';
import MemberLoyaltyLevelTag from 'components/MemberLoyaltyLevelTag';
import MemberLevelTag from 'components/MemberLevelTag';
import { isEmpty } from 'lodash';
import ProcessorTag from 'components/ProcessorTag';
import { unassignedProcessorLabel } from 'constants/processorValues';
import { DATE_FORMAT } from 'constants/date';
import { customFormatMessage } from 'utils/customFormatMessage';

const FilterConditions = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { filters, onFilterChange } = useFilterValues() as any;

  const [localStateFilter, setLocalStateFilter] = useState({}) as any;

  useEffect(() => {
    const transformedDateFilters = transformMovableDateFilter(filters);
    setLocalStateFilter(transformedDateFilters || {});
  }, [filters, setLocalStateFilter]);

  const handleRemoveFilterTag = (name: string, value: string, e: any) => {
    e.preventDefault();
    const field = coercedGet(filters, name, []);
    const data = field.in.filter((item: string) => item !== value);
    const newField = {
      in: data,
    };
    onFilterChange({
      ...filters,
      [`${name}`]: data.length ? newField : null,
    });
  };

  const handleRemoveDate = (name: string, e: any) => {
    e.preventDefault();

    onFilterChange({
      ...filters,
      [`${name}`]: null,
    });
  };

  const handleRemoveAmountTag = (name: string, range: string, e: any) => {
    e.preventDefault();
    const data = { ...filters[`${name}`] };
    delete data[`${range}`];
    onFilterChange({
      ...filters,
      [`${name}`]: isEmpty(data) ? null : data,
    });
  };

  const handleRemovePaymentMethodType = (value: string, e: any) => {
    e.preventDefault();
    const { paymentMethodType } = filters;
    const data = paymentMethodType.in.filter((item: string) => item !== value);
    const newPaymentMethodType = {
      in: data.length ? data : PAYMENT_TYPES_DEPOSIT.ONLINE,
    };
    onFilterChange({
      ...filters,
      paymentMethodType: newPaymentMethodType,
    });
  };

  const handleRemoveProcessor = (value: string, e: any) => {
    e.preventDefault();
    const { processor } = filters;
    const inFilters = processor.in.filter((item: string) => item !== value);
    const ninFilters = processor.nin ? [...processor.nin, value] : [];

    const newProcessor = {
      ...(inFilters.length ? { in: inFilters } : {}),
      ...(ninFilters.length ? { nin: ninFilters } : {}),
    } as any;

    onFilterChange({
      ...filters,
      processor: newProcessor.nin || newProcessor.in ? newProcessor : null,
    });
  };

  const handleRemoveUnassignedProcessor = (e: any) => {
    e.preventDefault();
    onFilterChange({
      ...filters,
      processor:
        filters.processor.in && filters.processor.in.length
          ? { in: filters.processor.in }
          : null,
    });
  };
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  return (
    <>
      {filters.serialCode
        ? filters.serialCode.in.map((item: any, key: string | number) => (
            <Tag
              key={key}
              closable
              onClose={(e: any) => handleRemoveFilterTag('serialCode', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.account
        ? filters.account.in.map((item: string, key: string | number) => (
            <AccountTag
              onClose={(e: any) => handleRemoveFilterTag('account', item, e)}
              key={key}
              id={item}
            />
          ))
        : null}
      {filters.memberLoyaltyLevel
        ? filters.memberLoyaltyLevel.in.map(
            (item: string, key: string | number) => (
              <MemberLoyaltyLevelTag
                islight
                onClose={(e) =>
                  handleRemoveFilterTag('memberLoyaltyLevel', item, e)
                }
                key={key}
                id={item}
              />
            )
          )
        : null}
      {filters.memberLevel
        ? filters.memberLevel.in.map((item: string, key: string | number) => (
            <MemberLevelTag
              onClose={(e) => handleRemoveFilterTag('memberLevel', item, e)}
              key={key}
              id={item}
            />
          ))
        : null}
      {filters.amount && filters.amount.gte ? (
        <Tag
          closable
          onClose={(e: any) => handleRemoveAmountTag('amount', 'gte', e)}
        >
          {translate(messages['minimum-original-amount.text'])}:{' '}
          {filters.amount.gte}
        </Tag>
      ) : null}
      {filters.amount && filters.amount.lte ? (
        <Tag
          closable
          onClose={(e: any) => handleRemoveAmountTag('amount', 'lte', e)}
        >
          {translate(messages['maximum-original-amount.text'])}:{' '}
          {filters.amount.lte}
        </Tag>
      ) : null}
      {filters.paymentMethodType && filters.paymentMethodType.in.length === 1
        ? filters.paymentMethodType.in.map(
            (item: string, key: string | number) => (
              <Tag
                key={key}
                closable
                onClose={(e: any) => handleRemovePaymentMethodType(item, e)}
              >
                {translate(messages[`${item}`])}
              </Tag>
            )
          )
        : null}
      {filters.status
        ? filters.status.in.map((item: string, key: string | number) => (
            <Tag
              key={key}
              closable
              onClose={(e: any) => handleRemoveFilterTag('status', item, e)}
            >
              {translate(messages[`${item}`])}
            </Tag>
          ))
        : null}
      {localStateFilter.dateTimeCreated ? (
        <Tag
          closable
          onClose={(e: any) => handleRemoveDate('dateTimeCreated', e)}
        >
          {translate(messages.REQUEST_DATE)}:{' '}
          {formatDate(localStateFilter.dateTimeCreated.gte, DATE_FORMAT)} ~{' '}
          {formatDate(localStateFilter.dateTimeCreated.lte, DATE_FORMAT)}
        </Tag>
      ) : null}
      {filters.processor && filters.processor.in
        ? filters.processor.in.map((item: string, key: string | number) => (
            <ProcessorTag
              onClose={(e) => handleRemoveProcessor(item, e)}
              key={key}
              id={item}
            />
          ))
        : null}
      {filters.processor && filters.processor.nin ? (
        <Tag closable onClose={handleRemoveUnassignedProcessor}>
          {unassignedProcessorLabel}
        </Tag>
      ) : null}
    </>
  );
};

export default FilterConditions;
