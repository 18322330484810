import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const StyledLayout = styled(Layout)`
  height: calc(100vh - 40px - 40px);
`;

export const StyledContent = styled(Content)`
  background: #fff;
`;

export const StyledSider = styled(Layout.Sider)`
  height: calc(100% - 48px);
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
`;

// export const StyledTagContainer = styled.div`
//   flex: 1;
// `;

// export const StyledActionsContainer = styled.div`
//   display: flex;
//   align-items: center;
// `;

export default {};
