/* eslint-disable global-require */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { BrowserRouter as Router } from 'react-router-dom';
import { ClearCacheProvider } from 'react-clear-cache';
import ApiErrorHandler from 'contexts/ApiErrorHandler';
import Apollo from 'contexts/Apollo';
import store from 'store/store';
import Storage from 'constants/storage';

import 'scss/global.scss';
import 'swiper/swiper.scss';
import { PromoApolloProvider } from 'graphql/client/promoClient';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import App from './App';
import * as serviceWorker from './serviceWorker';

localStorage.removeItem(Storage.ACCESS_TOKEN);
localStorage.removeItem(Storage.ACTIVE_PORTAL);

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {});
}
const growthbook = new GrowthBook();
const render = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: '5e9819f706b793070087eb48',
    user: {
      anonymous: true,
    },
    options: {
      streaming: false,
    },
  });
  fetch(
    window.config?.environment === 'development'
      ? 'https://cdn.growthbook.io/api/features/staging_PLpl0znRM2bcqo2H06YmDw7m1PeXuXMVWZEHIBp5SU'
      : 'https://cdn.growthbook.io/api/features/prod_EoN32OLxM4t1hFw9tNWYFlFtBpjOaYgugkuDoz4o',
    {
      cache: 'no-cache',
    }
  )
    .then((res) => res.json())
    .then((json) => {
      growthbook.setFeatures(json.features);
    });
  ReactDOM.render(
    <ClearCacheProvider auto filename="build.json">
      <ReduxProvider store={store}>
        <GrowthBookProvider growthbook={growthbook}>
          <LDProvider>
            <ApiErrorHandler>
              <PromoApolloProvider>
                <Apollo>
                  <Router>
                    <App />
                  </Router>
                </Apollo>
              </PromoApolloProvider>
            </ApiErrorHandler>
          </LDProvider>
        </GrowthBookProvider>
      </ReduxProvider>
    </ClearCacheProvider>,
    document.getElementById('root')
  );
};

render();

serviceWorker.register();
