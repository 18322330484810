import gql from 'graphql-tag';

export const MEMBER_REBATE_PAYOUT_RECORDS = gql`
  query memberRebatePayoutRecords(
    $first: Int
    $after: Binary
    $filter: MemberRebatePayoutRecordsFilterInput
  ) {
    memberRebatePayoutRecords(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          member {
            id
            username
            memberLoyaltyLevel {
              id
              name
              programme {
                id
                name
              }
            }
          }
          rebateGroup {
            id
            name
          }
          totalAmountAccumulated
          totalAmountPayout
          totalAmountToBePayout
          totalAmountClaimed
          totalAmountExpired
          dateTimeCreated
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const MEMBER_PROMO_PAYOUT_RECORDS = gql`
  query memberPromoPayoutRecords(
    $first: Int
    $after: Binary
    $filter: MemberPromoPayoutRecordsFilterInput
  ) {
    memberPromoPayoutRecords(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          member {
            id
            username
            memberLoyaltyLevel {
              id
              name
              programme {
                id
                name
              }
            }
          }
          promo {
            name
          }
          totalAmountAccumulated
          totalAmountPayout
          totalAmountToBePayout
          totalAmountClaimed
          totalAmountExpired
          dateTimeCreated
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default {};
