import React from 'react';
import DownloadCsvButton from 'components/DownloadCsvButton';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import { Divider } from 'antd';
import useTranslate from 'utils/useTranslate';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import useFilter from 'contexts/Filter';
import {
  SCREEN_TAB_ID,
  CSV_FILE_NAME,
  CSV_QUERY,
  TABLE_QUERY_NAME,
} from '../../configs';

type Props = {
  refreshPage: () => void;
};

const HeaderRight: React.FC<Props> = ({ refreshPage }) => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_DOWNLOAD_SPREADSHEET } = collectPermissions(
    role,
    permissions,
    ['DOWNLOAD_SPREADSHEET'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS_MEMBER_SUMMARY_REPORT
  );
  const translate = useTranslate();

  return (
    <>
      <RefreshButton onClick={() => refreshPage()} />
      <Divider type="vertical" className="ml-0 mr-2" />
      <ColumnsFilterNew iconOnly tabId={SCREEN_TAB_ID} />
      <DownloadCsvButton
        useProvidedFilter={useFilter}
        iconOnly
        tabId={SCREEN_TAB_ID}
        fileName={translate(CSV_FILE_NAME)}
        edgesPath={`${TABLE_QUERY_NAME}.edges`}
        query={CSV_QUERY}
        permission={ALLOWED_DOWNLOAD_SPREADSHEET}
      />
    </>
  );
};

export default HeaderRight;
