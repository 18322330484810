import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-loyalty.qualifications-and-maintenance.text': {
    id: 'member-loyalty.qualifications-and-maintenance.text',
    defaultMessage: 'Qualifications & Maintenance',
  },
  'member-loyalty.qualifications.text': {
    id: 'member-loyalty.qualifications.text',
    defaultMessage: 'Qualification',
  },
  'member-loyalty.awards.text': {
    id: 'member-loyalty.awards.text',
    defaultMessage: 'Awards',
  },
  'member-loyalty.benefits.text': {
    id: 'member-loyalty.benefits.text',
    defaultMessage: 'Benefits',
  },
  'member-loyalty.tier-description.text': {
    id: 'member-loyalty.tier-description.text',
    defaultMessage: 'Tier Description',
  },
});

export default messages;
