import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'sidebar.status.internal.filter.pending.text': {
    id: 'sidebar.status.internal.filter.pending.text',
    defaultMessage: 'Pending',
  },
  'sidebar.status.internal.filter.processing.text': {
    id: 'sidebar.status.internal.filter.processing.text',
    defaultMessage: 'Processing',
  },
  'sidebar.status.internal.filter.approved.text': {
    id: 'sidebar.status.internal.filter.approved.text',
    defaultMessage: 'Approved',
  },
  'sidebar.status.internal.filter.rejected.text': {
    id: 'sidebar.status.internal.filter.rejected.text',
    defaultMessage: 'Rejected',
  },
  'sidebar.status.internal.filter.cancelled.text': {
    id: 'sidebar.status.internal.filter.cancelled.text',
    defaultMessage: 'Cancelled',
  },
  'sidebar.status.internal.filter.expired.text': {
    id: 'sidebar.status.internal.filter.expired.text',
    defaultMessage: 'Expired',
  },
  'sidebar.status.internal.filter.failed.text': {
    id: 'sidebar.status.internal.filter.failed.text',
    defaultMessage: 'Failed',
  },
  'sidebar.status.internal.filter.onHold.text': {
    id: 'sidebar.status.internal.filter.onHold.text',
    defaultMessage: 'On Hold',
  },
  'sidebar.status.internal.filter.canceled.text': {
    id: 'sidebar.status.internal.filter.canceled.text',
    defaultMessage: 'Canceled',
  },
  'sidebar.status.internal.filter.draft.text': {
    id: 'sidebar.status.internal.filter.draft.text',
    defaultMessage: 'Draft',
  },
});

export default messages;
