import gql from 'graphql-tag';

export const AFFILIATE_REQUESTS = gql`
  query affiliateRequests($status: AffiliateRequestStatusQueryOperatorInput) {
    affiliateRequests(filter: { status: $status }) {
      edges {
        node {
          id
          member {
            id
          }
          status
        }
      }
    }
  }
`;

export const UPLINES = gql`
  query members($filter: MembersFilterInput) {
    members(filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;
