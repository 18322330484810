/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import dedup from 'utils/arrayDedup';
import { USERNAME_OPTIONS } from 'pages/components/Reports/MemberGameReport/queries';
import { StyledSelect } from './styles';

const { Option } = Select;

type Props = {
  state: Record<string, any>;
  setState?: (data: Record<string, any>) => void;
  onChange?: (data: Record<string, any>) => void;
};

const Username = ({ state, setState, onChange }: Props) => {
  const [localState, setLocalState] = useState<any>({
    options: [],
  });

  const { data, refetch }: any = useQuery(USERNAME_OPTIONS, {
    variables: { first: 10 },
  });

  useEffect(() => {
    const {
      members: { edges },
    }: { members: { edges: Record<string, any> } } =
      data && Object.keys(data).length
        ? data
        : {
            members: {
              edges: [],
              totalCount: 0,
              pageInfo: { hasNextPage: false, endCursor: null },
            },
          };
    const options = edges.map((edge: Record<string, any>) => edge.node);
    setLocalState((prevState: any) => ({
      ...localState,
      options: dedup(prevState.options.concat(options)),
    }));
  }, []);

  return (
    <div className="mt-4 mb-2">
      <div className="mb-1">
        <FormattedMessage
          id="reports.username.text"
          defaultMessage="Username"
        />
      </div>
      <StyledSelect
        showSearch
        allowClear
        mode="multiple"
        placeholder={
          <FormattedMessage id="reports.search.text" defaultMessage="Search" />
        }
        style={{ width: '100%' }}
        value={state.usernames}
        onChange={(values) => {
          if (onChange) {
            onChange(values as any);
          } else if (setState) {
            setState({
              ...state,
              usernames: values,
            });
          }
        }}
        onSearch={debounce((text) => {
          if (text.length > 1) {
            refetch({
              first: 10,
              filter: {
                username: {
                  contains: text,
                },
              },
            });
          }
        }, 500)}
      >
        {localState.options.map((option: Record<string, any>) => (
          <Option key={option.username} value={option.username}>
            {option.username}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
};

export default Username;
