import React from 'react';
import { WarningFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import messages from 'pages/components/MemberWithdrawals/messages';
import useTranslate from 'utils/useTranslate';
import { StyledCompliances } from './styles';

const ComplianceWarning = ({
  withdrawal,
}: {
  withdrawal: Record<string, any>;
}) => {
  const translate = useTranslate();
  const {
    oneTimeTurnoverDetectionCheck,
    promoHunterCheck,
    contraBetCheck,
    ipCollisionOffenderCheck,
    martingaleBetOffenderCheck,
  } = withdrawal;

  const isFailed = (value: string) => value === 'FAILED';
  const validateCompliance = [
    oneTimeTurnoverDetectionCheck,
    promoHunterCheck,
    contraBetCheck,
    ipCollisionOffenderCheck,
    martingaleBetOffenderCheck,
  ].filter(isFailed);

  if (!validateCompliance.length) {
    return <>-</>;
  }
  const renderTitle = () => (
    <StyledCompliances>
      {isFailed(oneTimeTurnoverDetectionCheck) && (
        <div>- {translate(messages.oneTimeTurnoverDetection)}</div>
      )}
      {isFailed(promoHunterCheck) && (
        <div>- {translate(messages.promoHunter)}</div>
      )}
      {isFailed(contraBetCheck) && <div>- {translate(messages.contraBet)}</div>}
      {isFailed(ipCollisionOffenderCheck) && (
        <div>- {translate(messages.ipCollisionOffender)}</div>
      )}
      {isFailed(martingaleBetOffenderCheck) && (
        <div>- {translate(messages.martingaleBetOffender)}</div>
      )}
    </StyledCompliances>
  );

  return (
    <Tooltip placement="bottom" title={renderTitle}>
      <WarningFilled style={{ fontSize: 19, color: '#facc25' }} />
    </Tooltip>
  );
};

export default ComplianceWarning;
