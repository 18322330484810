import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Tag } from 'antd';
import gql from 'graphql-tag';

type Props = {
  id: string;
  onClose: (e: any) => void;
  prepend?: string;
};

export const ACCOUNT_USERNAME = gql`
  query Member($id: ID!) {
    member(id: $id) {
      username
    }
  }
`;

const AccountTag: React.FC<Props> = (props) => {
  const { id, onClose, prepend } = props;
  const { data } = useQuery(ACCOUNT_USERNAME, {
    variables: {
      id,
    },
  });

  const { member = {} } = data || {};

  return (
    <Tag closable onClose={(e: any) => onClose(e)} data-testid="account-tag">
      {prepend && `${prepend}: `}
      {member.username || ''}
    </Tag>
  );
};

export default AccountTag;
