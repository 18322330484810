import React, { useEffect, useRef, useState } from 'react';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { FilterConditions } from 'pages/components/NewMemberManagement/components/Header/components/LeftColumn/components/FilterConditions';
import { ResizeSensor } from 'css-element-queries';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';

type Props = {
  parentWidth: number;
  filters: Record<string, any>;
  isLoading: boolean;
  onFilterChange: (e: any) => void;
  setWidthDiff: (e: any) => void;
  expandedTags: boolean;
  setExpandedTags: (e: any) => void;
};

export const LeftColumn = ({
  parentWidth,
  filters,
  isLoading,
  onFilterChange,
  setWidthDiff,
  expandedTags,
  setExpandedTags,
}: Props) => {
  const divRef = useRef<any>(null);
  const parentTagsRef = useRef(null);
  const [lastWidthValue, setLastWidthValue] = useState<any>(null);

  const [localStateFilter, setLocalStateFilter] = useState({});

  useEffect(() => {
    const container = divRef.current!;
    const resized = new ResizeSensor(container, () => {
      const widthPercentageDiff =
        (divRef.current.offsetWidth / parentWidth) * 100;
      if (lastWidthValue !== null) {
        const isValueLowering = lastWidthValue > widthPercentageDiff;
        if (isValueLowering && widthPercentageDiff < 100) {
          setExpandedTags(false);
        }
      }
      setLastWidthValue(widthPercentageDiff);
      setWidthDiff(widthPercentageDiff);
    });

    return () => {
      resized.detach(container);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const transformedDateFilters = transformMovableDateFilter(filters);
    setLocalStateFilter(transformedDateFilters || {});
  }, [filters]);

  // Might be useful on other bugs that im going to fix
  // useEffect(() => {
  //   setTimeout(() => {
  //     const tagsHtml = parentTagsRef.current.querySelectorAll('.ant-tag');
  //     const tagsData = [];
  //     tagsHtml.forEach(x => tagsData.push(x.innerText));
  //     setTags(tagsData);
  //   });
  // }, [filters]);

  const tagContainerStyle = {
    maxHeight: expandedTags ? 'initial' : '30px',
    overflow: 'hidden',
    minWidth: '75%',
  };
  return (
    <>
      <div ref={parentTagsRef} className="d-flex">
        <ToggleSidebarHead />
        <span style={tagContainerStyle}>
          <FilterConditions
            filters={filters}
            onFilterChange={onFilterChange}
            isLoading={isLoading}
            localStateFilter={localStateFilter}
          />
        </span>
      </div>

      <div
        ref={divRef}
        className="d-flex align-items-center position-absolute"
        style={{ opacity: 0, left: '-9999999px' }}
      >
        <ToggleSidebarHead />
        <span>
          <FilterConditions
            filters={filters}
            onFilterChange={onFilterChange}
            isLoading={isLoading}
            localStateFilter={localStateFilter}
          />
        </span>
      </div>
    </>
  );
};
