import { isEmpty, kebabCase, map } from 'lodash';
import { Tag } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { globalTranslate } from 'utils/globalMessages';

export const SearchCriteriaTag = ({
  page,
  setPage,
}: ISearchCriteriaTag): any => {
  const intl = useIntl();
  return (
    page &&
    map(page.searchCriteria, (value, key) =>
      !isEmpty(value) ? (
        <Tag
          key={key}
          onClose={() => {
            setPage((prevState: any) => ({
              ...prevState,
              onTagClose: key,
            }));
          }}
          closable
        >
          <span className="text-capitalize text-bold">
            {globalTranslate(`${kebabCase(key)}.text`, intl)}:&nbsp;
          </span>
          {value}
        </Tag>
      ) : (
        <React.Fragment key={key} />
      )
    )
  );
};

interface ISearchCriteriaTag {
  page: any;
  setPage: (e: any) => void;
  overrideValueRender?: (key: string, value: string) => any;
}
