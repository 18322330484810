import React from 'react';
import { Formik } from 'formik';
import { get, isEqual, omit } from 'lodash';
import { Tabs, message } from 'antd';
import styled from 'styled-components';

import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { StyledForm } from './styles';

import messages from './messages';
import GeneralSettings from './components/GeneralSettings';
import Benefits from './components/Benefits';
import Awards from './components/Awards';
import TierDescription from './components/TierDescription';
import {
  allTypes,
  useCreateLoyaltyProgramContext,
  getBenefitsSchema,
  getPayoutsSchema,
  usePrimaryVipContext,
} from '../../context';

export default React.forwardRef((_props: any, ref) => {
  const formRef = React.useRef() as any;
  const translate = useTranslate();

  const [state, dispatch] = useCreateLoyaltyProgramContext() as any;

  const [initialTierState, setInitialTierState] = React.useState(
    coercedGet(state, 'activeTier', {})
  );

  React.useEffect(() => {
    if (!state.activeTier) {
      setInitialTierState(coercedGet(state, 'activeTier', {}));
      return;
    }

    setInitialTierState(state.activeTier);
  }, [state, state.activeTier]);

  function parseCriteriaData(data: any) {
    if (data) {
      const parsed = data.reduce((acc: any, currVal: any) => {
        if (currVal.type === 'AND' || currVal.type === 'OR') {
          if (currVal.type === 'AND' && currVal.data.length === 1) {
            const depositObject = get(currVal, 'data[0]');

            acc.type = depositObject.type;
            acc.amount = depositObject.amount;
            return acc;
          }

          if (currVal.type === 'OR' && currVal.data.length === 1) {
            const effectiveBetObject = get(currVal, 'data[0]');
            acc.type = effectiveBetObject.type;
            acc.amount = effectiveBetObject.amount;
            acc.gameTypes = effectiveBetObject.gameTypes;
            acc.isSingleObject = true;
            return acc;
          }

          // recurse
          acc.type = 'BASE';
          acc[`${currVal.type.toLowerCase()}`] = currVal.data.map((item: any) =>
            parseCriteriaData([item])
          );

          return acc;
        }

        if (currVal.type === 'DEPOSIT') {
          acc.type = currVal.type;
          acc.amount = currVal.amount;
          return acc;
        }

        if (currVal.type === 'EFFECTIVE_BET') {
          acc.type = currVal.type;
          acc.amount = currVal.amount;
          acc.gameTypes = currVal.gameTypes;
          return acc;
        }

        return acc;
      }, {});

      if (isEqual(parsed, { type: 'BASE', and: [] })) {
        return { type: 'DEPOSIT', amount: 0 };
      }
      return parsed;
    }
    return null;
  }

  const isPrimaryVip = usePrimaryVipContext();
  const isNotPrimaryVip = !isPrimaryVip;
  const [activeKey, setActiveKey] = React.useState('generalSettings');

  const initTabPanes = [
    {
      key: 'generalSettings',
      label: translate(messages['member-loyalty.qualifications.text']),
      content: <GeneralSettings />,
    },
    {
      key: 'awards',
      label: translate(messages['member-loyalty.awards.text']),
      content: <Awards />,
    },
    {
      key: 'benefits',
      label: translate(messages['member-loyalty.benefits.text']),
      content: <Benefits />,
    },
    {
      key: 'tierDescription',
      label: translate(messages['member-loyalty.tier-description.text']),
      content: <TierDescription />,
    },
  ];

  const tabPanes = isNotPrimaryVip
    ? initTabPanes.filter(
        (tabPane) =>
          !(tabPane.key === 'benefits' || tabPane.key === 'tierDescription')
      )
    : initTabPanes;

  React.useImperativeHandle(ref, () => ({
    handleTab(actionType: any) {
      const activeTabIdx = tabPanes.findIndex((item) => item.key === activeKey);

      const isLastPage = tabPanes.length - 1 === activeTabIdx;
      const isBenefitsPage =
        coercedGet(tabPanes[activeTabIdx], 'key', null) === 'benefits';
      const isPayoutsPage =
        coercedGet(tabPanes[activeTabIdx], 'key', null) === 'awards';

      const handleNext = () => {
        const formValues = formRef.current.getFormValues();

        if (isLastPage && isPayoutsPage) {
          getPayoutsSchema(translate)
            .validate(formValues)
            .then(() => {
              const payload = {
                id: state.activeTier.id,
                ...formValues,
                qualificationCriteria: parseCriteriaData(
                  formValues.qualificationCriteria
                ),
              };

              dispatch({
                type: allTypes.UPDATE_TIER,
                payload,
              });

              dispatch({
                type: allTypes.SET_ACTIVE_SCREEN,
                payload: allTypes.TIER_SETTINGS,
              });
            })
            .catch((err) => message.error(err.message));

          return null;
        }

        if (isLastPage) {
          const payload = {
            id: state.activeTier.id,
            ...formValues,
            qualificationCriteria: parseCriteriaData(
              formValues.qualificationCriteria
            ),
          };

          dispatch({
            type: allTypes.UPDATE_TIER,
            payload,
          });

          dispatch({
            type: allTypes.SET_ACTIVE_SCREEN,
            payload: allTypes.TIER_SETTINGS,
          });

          return null;
        }

        if (isBenefitsPage) {
          getBenefitsSchema(translate)
            .validate(formValues)
            .then(() => {
              const nextTab = tabPanes[activeTabIdx + 1];

              setActiveKey(nextTab.key);
              return null;
            })
            .catch((err) => {
              message.error(err.message);
            });

          return null;
        }

        if (isPayoutsPage) {
          getPayoutsSchema(translate)
            .validate(formValues)
            .then(() => {
              const nextTab = tabPanes[activeTabIdx + 1];
              setActiveKey(nextTab.key);
            })
            .catch((err) => message.error(err.message));

          return null;
        }

        const nextTab = tabPanes[activeTabIdx + 1];
        setActiveKey(nextTab.key);
        return null;
      };

      const handlePrev = () => {
        if (activeTabIdx === 0) {
          dispatch({
            type: allTypes.SET_ACTIVE_SCREEN,
            payload: allTypes.TIER_SETTINGS,
          });
          return null;
        }

        const prevTab = tabPanes[activeTabIdx - 1];
        setActiveKey(prevTab.key);
        return null;
      };

      const handleReset = () => {
        dispatch({
          type: allTypes.UPDATE_TIER,
          payload: {
            ...initialTierState,
          },
        });

        dispatch({
          type: allTypes.SET_ACTIVE_SCREEN,
          payload: allTypes.TIER_SETTINGS,
        });
      };

      switch (actionType) {
        case 'NEXT':
          return handleNext();
        case 'PREV':
          return handlePrev();
        case 'RESET':
          return handleReset();

        default:
          return null;
      }
    },
    resetActiveKey() {
      setActiveKey('generalSettings');
    },
  }));

  const reverseParseCriteriaData = (initialData: Record<string, any>) => {
    const parser = (data: any) => {
      const parsed = [data].reduce((acc, curr) => {
        if (curr.type === 'DEPOSIT') {
          return curr;
        }

        if (curr.type === 'EFFECTIVE_BET') {
          if (curr.isSingleObject) {
            return {
              type: 'OR',
              data: [omit(curr, ['isSingleObject'])],
            };
          }

          return curr;
        }

        if (curr.type === 'BASE') {
          if (curr.or) {
            acc.type = 'OR';
            acc.data = curr.or.map((item: any) => parser(item));
            return acc;
          }

          if (curr.and) {
            acc.type = 'AND';
            const intermediateAnd = curr.and.map((item: any) => {
              if (item.type === 'EFFECTIVE_BET') {
                return {
                  ...item,
                  isSingleObject: true,
                };
              }
              return item;
            });

            acc.data = intermediateAnd.map((item: any) => parser(item));
            // acc.data = curr.and.map(item => parser(item));
            return acc;
          }
        }

        return acc;
      }, {});
      return parsed;
    };

    return initialData.type === 'DEPOSIT'
      ? [
          {
            type: 'AND',
            data: [parser(initialData)],
          },
        ]
      : [parser(initialData)];
  };

  const defaultCriteria = [
    {
      type: 'AND',
      data: [],
    },
  ];

  const defaultDepositOnlyCriteria = {
    type: 'DEPOSIT',
    amount: 0,
  };

  const intitialQualification = isEqual(
    coercedGet(initialTierState, 'qualificationCriteria', {}),
    defaultDepositOnlyCriteria
  )
    ? defaultCriteria
    : reverseParseCriteriaData(
        coercedGet(
          initialTierState,
          'qualificationCriteria',
          defaultDepositOnlyCriteria
        )
      );

  const initialBenefitsFields = isPrimaryVip
    ? {
        maximumWithdrawalAmountPerRequest: 0,
        maximumWithdrawalRequest: 0,
        withdrawalLimitRequestReset: 'DAILY',
        withdrawalLimitFee: 0,
        withdrawalLimitFeeType: 'RELATIVE',
        bankAccountLimits: 0,
        eWalletLimits: 0,
      }
    : {};

  const initialValues = {
    inviteOnly: false,
    // initial payouts fields
    autoPayout: true,
    upgradePayout: null,
    dailyPayout: null,
    weeklyPayout: null,
    monthlyPayout: null,
    annualPayout: null,
    birthdayPayout: null,
    // initial benefits fields
    ...initialBenefitsFields,
    ...initialTierState,
    qualificationCriteria: intitialQualification,
  };

  return (
    <Root>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(_: any, actions) => {
          actions.setSubmitting(false);
        }}
      >
        {(formProps) => {
          const { handleSubmit, values } = formProps;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useImperativeHandle(formRef, () => ({
            getFormValues: () => values,
          }));

          return (
            <StyledForm onSubmit={handleSubmit}>
              <Tabs
                defaultActiveKey="generalSettings"
                activeKey={activeKey}
                tabPosition="left"
              >
                {tabPanes.map((item) => (
                  <Tabs.TabPane tab={item.label} key={item.key}>
                    <>
                      {React.cloneElement(item.content, {
                        formik: formProps,
                      })}
                    </>
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </StyledForm>
          );
        }}
      </Formik>
    </Root>
  );
});

const Root = styled.div`
  .ant-tabs .ant-tabs-left-content {
    border-left: 0px solid #e8e8e8;
  }
`;
