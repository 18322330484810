/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,jsx-a11y/no-static-element-interactions */
import { Icon } from '@ant-design/compatible';
import { DownOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, Dropdown, Menu, Rate } from 'antd';
import { showPermissionError } from 'components/Navbar/Navbar';
import ALL_PERMISSIONS from 'constants/permissions3';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import { GET_MEMBER_FULL_QUERY } from 'graphql/queries/member.query';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import EditBalance from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/ChangeBalance';
import EditMemberLevelMenu from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/EditMemberLevelMenu';
// import EditMultiVIP from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/EditMultiVIP';
import EditStatusMenu from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/EditStatusMenu';
import EditTagMenu from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/EditTagMenu';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import EditMemberVerificationStatus from 'components/EditMemberVerificationStatus';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import { SendMessage } from 'components/SendMessage/SendMessage';
import { EditMember } from 'components/EditMember/EditMember';
import { useAccount, usePermissions, useStarred } from 'store/accountState';
import { AddTab, useScreenTabV2 } from 'store/screenTabState';
import styled from 'styled-components';
import { useNewMemberDetailContext } from '../../context';
import { StyledContent } from '../../styles';
import { ReactComponent as BalanceServiceIcon } from './assets/balance-service.svg';
import { ReactComponent as BalanceTransactionRecordsIcon } from './assets/balance-transaction-records.svg';
import { ReactComponent as MemberBetRecordsIcon } from './assets/member-bet-records.svg';
import ChangeLoginPassword from './components/ChangeLoginPassword';
import { EditPromoManualAdjustment } from './components/EditPromoManualAdjustment/EditPromoManualAdjustment';

const StyledIcon = styled(Icon)`
  font-size: 24px;
  display: block;
`;

const StyledMenu = styled(Menu)`
  button {
    text-align: left;
  }
`;

export const Header = () => {
  const { role, permissions } = usePermissions();
  const {
    account: { locale },
  } = useAccount();
  const { refreshPage } = useNewMemberDetailContext();
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const {
    ALLOWED_EDIT_BALANCE,
    ALLOWED_EDIT_MEMBER,
    ALLOWED_CHANGE_PASSWORD,
    ALLOWED_SEND_MESSAGE,
  } = collectPermissions(
    role,
    permissions,
    [
      'EDIT_BALANCE',
      'EDIT_MEMBER',
      'CHANGE_PASSWORD',
      'CHANGE_WITHDRAWAL_PASSWORD',
      'SEND_MESSAGE',
    ],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const {
    ALLOWED_BALANCE_SERVICE_RECORD_REPORTS,
    ALLOWED_BALANCE_TRANSACTION_REPORTS,
    ALLOWED_MEMBER_BET_RECORDS,
  } = collectPermissions(
    role,
    permissions,
    [
      'BALANCE_SERVICE_RECORD_REPORTS',
      'BALANCE_TRANSACTION_REPORTS',
      'MEMBER_BET_RECORDS',
    ],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS
  );

  const [isShowEditMember, setShowEditMember] = useState(false);
  const handleShow = () => setShowEditMember((prev) => !prev);
  const { member, refetch } = useMember() as any;
  const { addTab } = useScreenTabV2();
  const { getStarredMember, toggleStar } = useStarred();

  const starredMember = getStarredMember(member.id);
  const transactionRecordsTab: AddTab = {
    id: 'balance-transaction-records',
    state: {
      memberIdRef: member.id,
    },
  };

  const balanceServiceRecordsTab: AddTab = {
    id: 'balance-service-record-report',
    state: {
      memberRef: {
        id: member.id,
        username: member.username,
      },
    },
  };

  const betRecordsTab: AddTab = {
    id: 'member-bet-records',
    state: {
      memberIdRef: member.id,
    },
  };

  return (
    <>
      <StyledContent>
        <div
          className="d-flex justify-content-center px-0"
          style={{ paddingTop: '8px', paddingBottom: '8px' }}
        >
          <div style={{ width: '944px' }}>
            <div
              className="d-flex justify-content-between align-items-center fs-12"
              style={{ marginLeft: '-8px' }}
            >
              <div className="d-flex align-items-center">
                <Rate
                  count={1}
                  className="mr-2 fs-24"
                  value={starredMember?.starred ? 1 : 0}
                  onChange={() => toggleStar(member)}
                  style={{ color: '#ffca19' }}
                />
                <h3 className="mb-0">{member.username}</h3>
              </div>

              <div className="d-flex align-items-center">
                <div className="d-flex align-items-stretch justify-content-end">
                  <div
                    className="cursor-pointer text-center header-icons-container"
                    onClick={refreshPage}
                  >
                    <div className="d-block">
                      <RefreshButton iconSize="24px" onClick={() => {}} />
                    </div>
                    <ALink
                      className="fs-8 fw-500 d-block"
                      style={{ marginTop: '2px' }}
                    >
                      <FormattedMessage id="REFRESH" defaultMessage="Refresh" />
                    </ALink>
                  </div>
                  <div
                    className="cursor-pointer text-center header-icons-container"
                    onClick={() => {
                      if (!ALLOWED_BALANCE_SERVICE_RECORD_REPORTS) {
                        showPermissionError(
                          'Balance Service Record Report - View'
                        );
                        return;
                      }
                      addTab(balanceServiceRecordsTab);
                    }}
                  >
                    <StyledIcon component={BalanceServiceIcon} />
                    <ALink className="fs-8 fw-500" style={{ marginTop: '2px' }}>
                      <FormattedMessage
                        id="balance-service.text"
                        defaultMessage="Balance Service"
                      />
                    </ALink>
                  </div>
                  <div
                    className="cursor-pointer text-center header-icons-container"
                    onClick={() => {
                      if (!ALLOWED_BALANCE_TRANSACTION_REPORTS) {
                        showPermissionError(
                          'Balance Transaction Records - View'
                        );
                        return;
                      }
                      addTab(transactionRecordsTab);
                    }}
                  >
                    <StyledIcon component={BalanceTransactionRecordsIcon} />
                    <ALink className="fs-8 fw-500" style={{ marginTop: '2px' }}>
                      <FormattedMessage
                        id="balance-transact.text"
                        defaultMessage="Balance Transact"
                      />
                      .
                    </ALink>
                  </div>
                  <div
                    className="text-center cursor-pointer header-icons-container"
                    onClick={() => {
                      if (!ALLOWED_MEMBER_BET_RECORDS) {
                        showPermissionError('Member Bet Records - View');
                        return;
                      }
                      addTab(betRecordsTab);
                    }}
                  >
                    <StyledIcon component={MemberBetRecordsIcon} />
                    <ALink className="fs-8 fw-500" style={{ marginTop: '2px' }}>
                      <FormattedMessage
                        id="bet-records.text"
                        defaultMessage="Bet Records"
                      />
                    </ALink>
                  </div>

                  {ALLOWED_CHANGE_PASSWORD && (
                    <ChangeLoginPassword member={member} />
                  )}
                </div>

                {(ALLOWED_EDIT_BALANCE ||
                  ALLOWED_EDIT_MEMBER ||
                  ALLOWED_SEND_MESSAGE) && (
                  <Dropdown
                    overlay={
                      <StyledMenu>
                        {ALLOWED_EDIT_BALANCE && (
                          <StyledMenu.Item className="p-0">
                            <EditBalance
                              refetchFunction={() => {
                                if (refreshPage) {
                                  refreshPage();
                                }
                              }}
                              member={member}
                            />
                          </StyledMenu.Item>
                        )}
                        {ALLOWED_EDIT_MEMBER && (
                          <StyledMenu.Item className="p-0" onClick={handleShow}>
                            <Button type="link" className="text-black">
                              <FormattedMessage
                                id="edit-member.text"
                                defaultMessage="Edit Member"
                              />
                            </Button>
                          </StyledMenu.Item>
                        )}
                        {ALLOWED_SEND_MESSAGE && (
                          <StyledMenu.Item className="p-0">
                            <SendMessage
                              members={[member.id]}
                              refetchFn={refreshPage}
                            />
                          </StyledMenu.Item>
                        )}

                        <StyledMenu.Item className="p-0">
                          <EditPromoManualAdjustment
                            members={[member.id]}
                            onSuccess={() => refreshPage!()}
                          />
                        </StyledMenu.Item>
                        <StyledMenu.Item className="p-0">
                          <Button
                            type="link"
                            className="text-black"
                            block
                            onClick={() => {
                              updateMember({
                                variables: {
                                  id: member.id,
                                  input: {
                                    forceVerification: true,
                                  },
                                },
                              }).then(() => {
                                refetch();
                              });
                            }}
                          >
                            <FormattedMessage
                              id="FORCE_VALIDATION"
                              defaultMessage="Force Validation"
                            />
                          </Button>
                        </StyledMenu.Item>
                        <StyledMenu.Item className="p-0">
                          <EditTagMenu member={member} refetch={refetch} />
                        </StyledMenu.Item>
                        <StyledMenu.Item className="p-0">
                          <EditStatusMenu member={member} refetch={refetch} />
                        </StyledMenu.Item>
                        <StyledMenu.Item className="p-0">
                          <EditMemberLevelMenu
                            member={member}
                            refetch={refetch}
                          />
                        </StyledMenu.Item>
                        {/* <StyledMenu.Item className="p-0">
                          <EditMultiVIP
                            members={[member]}
                            onSuccess={() => refreshPage!()}
                          />
                        </StyledMenu.Item> */}
                        <StyledMenu.Item className="p-0">
                          <EditMemberVerificationStatus />
                        </StyledMenu.Item>
                      </StyledMenu>
                    }
                    placement="bottomRight"
                  >
                    <Button style={{ marginLeft: '20px', marginRight: '8px' }}>
                      <FormattedMessage
                        id="more-actions.text"
                        defaultMessage="More Actions"
                      />
                      <DownOutlined className="ml-1" />
                    </Button>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        </div>

        {isShowEditMember && (
          <EditMember
            member={member}
            handleShow={handleShow}
            refetchQueries={[
              {
                query: GET_MEMBER_FULL_QUERY,
                variables: {
                  id: member.id,
                  language: locale.toUpperCase(),
                },
              },
            ]}
            isShow={isShowEditMember}
            refetchFn={refetch}
          />
        )}
      </StyledContent>
    </>
  );
};
