import React from 'react';
import Block from 'react-blocks';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { FormattedMessage } from 'react-intl';
// import styled from 'styled-components';
// import { Button } from 'antd';

// import SharedLeftSidebar from 'shared/components/LeftSidebar/SharedLeftSidebar';
import SavedFilterSelect from 'components/SavedFilterSelect';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import TimeZoneFilter from 'SuperAdminMain/shared/components/TimeZoneFilter';
import SaveSearch from './components/SaveSearch';
import SearchSettings from './components/SearchSettings';
import { StyledSider, StyledContainer, StyledInputGroup } from './styles';
import FooterActions from './components/FooterActions';
import DateTimeProcessed from './components/DateTimeProcessed';
import VendorFilter from './components/VendorFilter';
// import TimeZoneFilter from './components/TimeZoneFilter';

// import VendorCategoryFilter from './components/VendorCategoryFilter';
// import BetStatusFilter from './components/BetStatusFilter';

type Props = {
  collapsed: boolean;
};

type Ref = HTMLTableElement;

const Sidebar = React.forwardRef<Ref, Props>(({ collapsed }, ref) => {
  const translate = useTranslate();
  const {
    filters,
    setFilters,
    savedFilterId,
    handleSavedFilterIdChange,
  } = useFilterValues();

  const [showSaveSearch, setShowSaveSearch] = React.useState(false);
  const toggleSaveSearchModal = () => {
    setShowSaveSearch((prev) => !prev);
  };

  const [showSearchSettings, setShowSearchSettings] = React.useState(false);
  const toggleSearchSettings = () => {
    setShowSearchSettings((prev) => !prev);
  };

  return (
    <div>
      <StyledSider
        className="br-1"
        theme="light"
        trigger={null}
        collapsed={collapsed}
        collapsible
        collapsedWidth={0}
        width={232}
        style={{ height: 'calc(100vh - 157px)' }}
      >
        <StyledContainer className="p-3">
          <Block
            layout
            horizontal
            style={{ justifyContent: 'space-between' }}
            className="mb-1"
          >
            <Block>
              <div className="mb-0">
                <FormattedMessage id="search.text" defaultMessage="Search" />:
              </div>
            </Block>
            <Block>
              <SaveSearch
                showModal={showSaveSearch}
                toggleModal={toggleSaveSearchModal}
              />
              <span className="mx-1">|</span>
              <SearchSettings
                toggleModal={toggleSearchSettings}
                showModal={showSearchSettings}
                changeFilters={setFilters}
              />
            </Block>
          </Block>
          <StyledInputGroup>
            <SavedFilterSelect
              placeHolder={translate(messages.SELECT_SAVED_CUSTOM_FILTER)}
              value={savedFilterId as string}
              onChange={(val) => handleSavedFilterIdChange(val)}
              context="superadmin-vendor-usage-report"
            />
          </StyledInputGroup>

          <StyledInputGroup>
            <DateTimeProcessed state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TimeZoneFilter state={filters} setState={setFilters} />
          </StyledInputGroup>

          <StyledInputGroup>
            <VendorFilter state={filters as object} setState={setFilters} />
          </StyledInputGroup>

          {/* Hidden component for now because of unsuppoerted filter on backend */}
          {/* <StyledInputGroup>
            <VendorCategoryFilter state={filters} setState={setFilters} />
          </StyledInputGroup> */}

          {/* <StyledInputGroup>
            <BetStatusFilter state={filters} setState={setFilters} />
          </StyledInputGroup> */}
        </StyledContainer>
      </StyledSider>
      <FooterActions ref={ref} />
    </div>
  );
});

export default Sidebar;
