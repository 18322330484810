import React, { useCallback, useState } from 'react';
import FiltersProvider, {
  useFilterValues,
} from 'SuperAdminMain/contexts/Filters';
import { BatchSplitProvider } from 'SuperAdminMain/shared/components/BatchSplit/BatchSplitContext';
import { usePartialFiltersQuery } from 'SuperAdminMain/utils/partialUtils';
import { AMHeader } from './AMHeader/AMHeader';
import { AMBody } from './AMBody/AMBody';
import { AccountManagementProvider } from './context';
import { ADMINS, ADMINS_IDS, Container } from './utils';

const pageInitState = {
  first: 10,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
};

const AccountManagementNew: React.FC = () => {
  const { filters } = useFilterValues();

  const [page, setPage] = useState<any>(pageInitState);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [refetchListener, setRefetchListener] = useState(false);

  const { loading, error, data, refetch } = usePartialFiltersQuery(
    ADMINS_IDS,
    ADMINS,
    'admins.edges',
    filters,
    page,
    ['username'],
    'network-only'
  );

  const onRefetchMainQuery = useCallback(() => {
    setRefetchListener(true);
    refetch();
    setRefetchListener(false);
  }, [refetch]);

  return (
    <BatchSplitProvider refetchListener={refetchListener}>
      <AccountManagementProvider
        {...{
          data,
          loading,
          error,
          refetch: onRefetchMainQuery,
          page,
          setPage,
          collapsed,
          setCollapsed,
        }}
      >
        <Container data-testid="account-management-container">
          <AMHeader />
          <AMBody />
        </Container>
      </AccountManagementProvider>
    </BatchSplitProvider>
  );
};

export default () => (
  <FiltersProvider>
    <AccountManagementNew />
  </FiltersProvider>
);
