import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormattedMessage } from 'react-intl';

type Props = {
  paymentMethod: Record<string, any>;
  formItemLayout: Record<string, any>;
};

const LubanzhifuDetails = (props: Props) => {
  const { paymentMethod, formItemLayout } = props;
  const { depositProvider = {} } = paymentMethod;

  return (
    <>
      <Form.Item
        label={
          <FormattedMessage id="provider.text" defaultMessage="Provider" />
        }
        {...formItemLayout}
        className="mb-0"
      >
        <span>{depositProvider.name || '-'}</span>
      </Form.Item>
    </>
  );
};

export default LubanzhifuDetails;
