import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import coercedGet from 'utils/coercedGet';
import {
  CustomFiltersConnection,
  CustomFiltersConnectionEdge,
} from 'types/graphqlTypes';

const CUSTOM_FILTERS = gql`
  query CustomFilters(
    $first: Int
    $after: Binary
    $filter: CustomFiltersFilterInput
  ) {
    customFilters(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          context
          filter
        }
      }
    }
  }
`;

export default (filterContext: string) => {
  const [customFilterList, setCustomFilterList] = React.useState([]);
  const { loading, error, data } = useQuery(CUSTOM_FILTERS, {
    variables: {
      filter: {
        context: {
          eq: filterContext,
        },
      },
    },
  });

  React.useEffect(() => {
    if (data) {
      const customFilters: CustomFiltersConnection = coercedGet(
        data,
        'customFilters',
        {}
      );

      const edges: Array<CustomFiltersConnectionEdge> = coercedGet(
        customFilters,
        'edges',
        []
      );

      const newData: any = edges.map(({ node }) => ({
        ...node,
        isShow: false,
      }));
      setCustomFilterList(newData);
    }
  }, [data]);

  return [customFilterList, setCustomFilterList, { loading, error, data }];
};
