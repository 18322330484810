import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Spin, Tag, Badge } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import messages from 'messages';
import { columnAlign } from 'utils/tableAlignment';

import { StyledTable, StyledCenter } from './styles';
import localMessages from './messages';
import EditLabel from './components/EditLabel';
import DeleteLabel from './components/DeleteLabel';
import MembersCount from './components/TagMembersCount';
import useTranslate from '../../../../../utils/useTranslate';

type Props = {
  labels: any[];
  loading: boolean;
  refetch: () => void;
  refetchVariables: Record<string, any>;
};

const LabelsTable = (props: Props) => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_EDIT, ALLOWED_DELETE } = collectPermissions(
    role,
    permissions,
    ['EDIT', 'DELETE'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_LABEL_MANAGEMENT
  );

  const [localState, setLocalState] = React.useState<Record<string, any>>({
    isEditLabel: false,
    isDeleteLabel: false,
    labelId: null,
  });

  const { isEditLabel, isDeleteLabel } = localState;

  const toggleEdit = (id?: string) => {
    setLocalState({
      ...localState,
      isEditLabel: !isEditLabel,
      labelId: id || null,
    });
  };

  const toggleDelete = (id?: string) => {
    setLocalState({
      ...localState,
      isDeleteLabel: !isDeleteLabel,
      labelId: id || null,
    });
  };

  const { labels, loading, refetch, refetchVariables } = props;
  const translate = useTranslate();
  const columns = [
    {
      key: 'number',
      width: 10,
      align: columnAlign.onCenter,
      title: translate(localMessages['member.label-management.number.text']),
      render: (_: any, __: any, index: number) => <span>{index + 1}</span>,
    },
    {
      key: 'labelName',
      width: 60,
      align: columnAlign.onCenter,
      title: translate(
        localMessages['member.label-management.label-name.text']
      ),
      render: (item: Record<string, any>) => (
        <Tag>
          <Badge color={item.color || '#ffffff'} />
          <span>{item.name}</span>
        </Tag>
      ),
    },
    {
      key: 'numberOfMembers',
      width: 10,
      align: columnAlign.onCenter,
      title: translate(
        localMessages['member.label-management.number-of-members.text']
      ),
      render: (item: Record<string, any>) => (
        <span>
          <MembersCount tag={item.name} count={item?.membersCount} />
        </span>
      ),
    },
    {
      key: 'action',
      width: 10,
      align: columnAlign.onCenter,
      title: translate(messages['actions.text']),
      dataIndex: 'id',
      render: (id: string) =>
        (ALLOWED_EDIT || ALLOWED_DELETE) && (
          <Dropdown
            overlay={
              <Menu>
                {ALLOWED_EDIT && (
                  <Menu.Item
                    onClick={() => {
                      toggleEdit(id);
                    }}
                  >
                    <FormattedMessage id="edit.text" defaultMessage="Edit" />
                  </Menu.Item>
                )}
                {ALLOWED_DELETE && (
                  <Menu.Item
                    onClick={() => {
                      toggleDelete(id);
                    }}
                  >
                    <FormattedMessage
                      id="delete.text"
                      defaultMessage="Delete"
                    />
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
          >
            <Button type="link" icon={<EllipsisOutlined />} />
          </Dropdown>
        ),
    },
  ];

  const { filterColumns } = useCustomColumnsV2(
    'member-label-management',
    columns
  );

  const tableData = labels.map(({ node }) => ({ ...node, key: node.id }));

  if (loading)
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );

  return (
    <>
      <StyledTable
        columns={filterColumns(columns)}
        size="middle"
        dataSource={tableData}
        pagination={false}
        // scroll={{ x: 1800 }}
      />
      {isEditLabel && (
        <EditLabel
          id={localState.labelId!}
          toggleModal={toggleEdit}
          refetchVariables={refetchVariables}
        />
      )}
      {isDeleteLabel && (
        <DeleteLabel
          id={localState.labelId!}
          toggleModal={toggleDelete}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default LabelsTable;
