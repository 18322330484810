import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  PaperClipOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers';
import { Button, Input, Spin, Upload } from 'antd';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ALink } from 'components/ALink/ALink';
import CustomSwitch from 'components/CustomSwitch';
import styled from 'styled-components';
import { DepositSource } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import { validationSchema } from './validation';

const { Dragger } = Upload;

const StyledDragger = styled(Dragger)`
  &.ant-upload.ant-upload-drag {
    height: 100px !important;
    width: 100px !important;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

const StyledFooter = styled.div`
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
`;

type Props = {
  append: (value: Partial<DepositSource>) => void;
  edit: (value: Partial<DepositSource>) => void;
  defaultValues: {
    id: string;
    logo: { id: string; uri: string };
    displayName: {
      EN: string;
      ZH: string;
    };
    visible: boolean;
    loadBankSource: boolean;
    order: number;
  };
};

const isCustomDirty = (
  dirtyFields: { logo?: any },
  defaultValues: Props['defaultValues'],
  getValues: (name: string) => { id: string }
) => {
  if (
    Object.keys(dirtyFields).length === 1 &&
    dirtyFields.logo &&
    getValues('logo')?.id === defaultValues.logo.id
  ) {
    return false;
  }

  if (Object.keys(dirtyFields).length) {
    return true;
  }

  return false;
};

const DepositSourceForm: React.FC<Props> = ({
  append,
  edit,
  defaultValues,
}) => {
  const translate = useTranslate();
  const [uploadLogo] = useMutation(UPLOAD_FILE);
  const {
    control,
    setValue,
    formState,
    getValues,
    reset,
    handleSubmit,
    errors,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(translate)),
  });

  const { dirtyFields } = formState;
  const watchFields = watch(['displayName.EN', 'displayName.ZH']);
  const [uploadState, setUploadState] = useState<{
    uploading: boolean;
    file: File | null;
  }>({
    uploading: false,
    file: null,
  });

  useEffect(() => {
    reset(defaultValues);
    setUploadState({
      uploading: false,
      file: null,
    });
  }, [defaultValues, reset]);

  const handleRemoveUpload = () => {
    const { logo } = defaultValues;
    setValue('logo', {
      id: logo.id,
      uri: logo.uri,
    });

    setUploadState((prev) => ({
      ...prev,
      file: null,
    }));
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    accept: '.png, .jpg',
  };

  const onSubmit = (data: Partial<DepositSource>) => {
    const { id } = data;
    if (id) {
      edit(data);
    } else {
      const newData = { ...data, id: 'NEW' };
      append(newData);
    }
    reset({ ...defaultValues });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <div className="d-flex">
          <Controller
            control={control}
            name="logo"
            render={({ onChange }) => (
              <StyledDragger
                {...draggerProps}
                beforeUpload={(file: File) => {
                  setUploadState((prev) => ({
                    ...prev,
                    uploading: true,
                    file,
                  }));
                  uploadLogo({
                    variables: {
                      file,
                    },
                  }).then((resp) => {
                    const { data } = resp;
                    const { uploadFile } = data;
                    onChange({
                      id: uploadFile?.id,
                      uri: uploadFile?.uri,
                    });
                    setUploadState((prev) => ({
                      ...prev,
                      uploading: false,
                    }));
                  });

                  return false;
                }}
              >
                {uploadState.uploading ? (
                  <Spin />
                ) : (
                  <PlusOutlined style={{ fontSize: 35 }} />
                )}
                {!uploadState.uploading && getValues('logo')?.uri && (
                  <StyledImg
                    src={`${getValues('logo').uri}`}
                    alt="source icon"
                  />
                )}
              </StyledDragger>
            )}
          />
          <div className="pl-2 d-flex">
            <div className="text-warning">
              <ExclamationCircleFilled className="mt-1" />
            </div>
            <div className="pl-1">
              <small>
                {translate(messages.IMAGE_UPLOAD_ERROR_EXACT_SIZE, {
                  size: '24px x 24px',
                })}
              </small>
              <br />
              <small>
                {translate(messages['accepted-file-formats.text'], {
                  formats: '*.png, *.jpg',
                })}
              </small>
              {uploadState.file?.name && (
                <div className="mt-2 d-flex justify-content-between">
                  <ALink>
                    <PaperClipOutlined /> {uploadState.file?.name}
                  </ALink>
                  <ALink
                    className="text-danger mr-1"
                    onClick={() => handleRemoveUpload()}
                  >
                    <DeleteOutlined />
                  </ALink>
                </div>
              )}
              {errors?.logo?.id && (
                <div className="pt-2 text-danger">
                  <small>{errors?.logo?.id?.message}</small>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <small>{translate(messages.ENGLISH_DISPLAY_NAME)}</small>
              <small>{`${watchFields['displayName.EN'].length}/40 ${translate(
                messages.CHARACTERS
              )}`}</small>
            </div>
            <Controller
              control={control}
              name="displayName.EN"
              as={<Input placeholder="EN" type="text" maxLength={40} />}
            />
            {errors?.displayName?.EN && (
              <div className="text-danger">
                <small>{errors?.displayName?.EN?.message}</small>
              </div>
            )}
          </div>
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <small>{translate(messages.CHINESE_DISPLAY_NAME)}</small>
              <small>{`${watchFields['displayName.ZH']?.length}/40 ${translate(
                messages.CHARACTERS
              )}`}</small>
            </div>
            <Controller
              control={control}
              name="displayName.ZH"
              as={<Input placeholder="ZH" type="text" maxLength={40} />}
            />
            {errors?.displayName?.ZH && (
              <div className="text-danger">
                <small>{errors?.displayName?.ZH?.message}</small>
              </div>
            )}
          </div>
          <div className="mt-3">
            <small>{translate(messages.NEED_BANK_SOURCE_INPUT)}</small>
            <br />
            <Controller
              control={control}
              name="loadBankSource"
              render={({ onChange, value }) => (
                <CustomSwitch
                  textStyle={{}}
                  value={value}
                  onChange={onChange}
                  checkedChildren={translate(messages.YES)}
                  unCheckedChildren={translate(messages.NO)}
                  activeColor=""
                  disabled={false}
                  textHelper={{}}
                />
              )}
            />
          </div>
        </div>
      </div>
      <StyledFooter className="text-right pt-2 bt-1">
        <Button
          htmlType="submit"
          type="primary"
          disabled={!isCustomDirty(dirtyFields, defaultValues, getValues)}
        >
          {defaultValues.id
            ? translate(messages['update.text'])
            : translate(messages.ADD)}
        </Button>
      </StyledFooter>
      {/* Dummy Controllers */}
      <Controller control={control} name="id" as={<></>} />
      <Controller control={control} name="visible" as={<></>} />
    </Form>
  );
};

export default DepositSourceForm;
