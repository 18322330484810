import { useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import {
  REJECT_DEPOSIT,
  START_PROCESS_DEPOSIT,
  UPDATE_DEPOSIT,
} from 'graphql/mutations/deposit.mutation';
import { DEPOSIT, DEPOSIT_REQUESTS } from 'graphql/queries/deposit.query';
import messages from 'pages/components/ExternalMemberDeposits/messages';
import React, { useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import RemarkModal from 'components/RemarkModal';
import useTranslate from 'utils/useTranslate';

const RejectDeposit = ({
  id,
  remarks,
  refetchVariables,
  disabled,
}: {
  id: string;
  remarks: string;
  refetchVariables: Record<string, any>;
  disabled: boolean;
}) => {
  const refetchQueries = [
    {
      query: DEPOSIT,
      variables: { id },
    },
    {
      query: DEPOSIT_REQUESTS,
      variables: refetchVariables,
    },
  ];

  const [visible, setVisible] = useState(false);
  const translate = useTranslate();
  const [rejectDeposit, rejectDepositResult] = useMutation(REJECT_DEPOSIT, {
    onCompleted: (data) => {
      if (data.rejectDeposit) {
        message.success(translate(messages.rejectDepositProcess));
      }
    },
    variables: { id },
    refetchQueries,
  });

  const [startProcessDeposit, startProcessResult] = useMutation(
    START_PROCESS_DEPOSIT,
    {
      onCompleted: (data) => {
        if (data.startProcessDeposit) {
          message.success(translate(messages.PROCESSING));
          rejectDeposit();
        }
      },
      variables: { id },
    }
  );

  const [updateDeposit, updateDepositResult] = useMutation(UPDATE_DEPOSIT, {
    onCompleted: (data) => {
      if (data.updateDeposit) {
        message.success(translate(messages.REMARKS_UPDATED));
        setVisible(false);
        startProcessDeposit();
      }
    },
  });

  const onHandleReject = () => {
    if (remarks) {
      rejectDeposit();
    } else {
      setVisible(true);
    }
  };

  return (
    <>
      <ALink
        onClick={onHandleReject}
        disabled={
          disabled || rejectDepositResult.loading || startProcessResult.loading
        }
      >
        {rejectDepositResult.loading || startProcessResult.loading ? (
          <Spin />
        ) : null}
        {translate(messages.reject)}
      </ALink>
      {visible && (
        <RemarkModal
          id={id}
          visible={visible}
          loading={updateDepositResult.loading}
          setVisible={setVisible}
          onSubmitRemarks={updateDeposit}
        />
      )}
    </>
  );
};

export default RejectDeposit;
