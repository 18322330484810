import React from 'react';
import { usePermissions } from 'store/accountState';
import styled from 'styled-components';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import isRow from 'utils/isRow';
import ALL_PERMISSIONS from 'constants/permissions3';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import BulkBalanceAdjustment from './components/BulkBalanceAdjustment';
import BulkChangeStatus from './components/BulkChangeStatus';
import BulkAddNotes from './components/BulkAddNotes/BulkAddNotes';
import BulkUpdateMemberLabels from './components/BulkUpdateMemberLabels';
import BulkGameRounds from './components/BulkGameRounds';

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  height: calc(100vh - 112px);
  row-gap: 20px;
  padding: 24px;
  background: white;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const BulkUpdate = () => {
  const { role, permissions } = usePermissions();
  const {
    ALLOWED_MEMBERS_BULK_UPDATE_STATUS,
    ALLOWED_MEMBERS_BULK_CREATE_NOTE,
    ALLOWED_MEMBERS_BULK_UPDATE_BALANCE,
    ALLOWED_MEMBERS_BULK_UPDATE_GAME_ROUNDS,
  } = collectPermissions(
    role,
    permissions,
    [
      'MEMBERS_BULK_UPDATE_STATUS',
      'MEMBERS_BULK_CREATE_NOTE',
      'MEMBERS_BULK_UPDATE_BALANCE',
      'MEMBERS_BULK_UPDATE_GAME_ROUNDS',
    ],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_BULK_UPDATE
  );

  const gameRoundEnabled = useFeatureIsOn('bulk-update-game-rounds');

  return (
    <StyledDiv>
      <StyledColumn>
        {ALLOWED_MEMBERS_BULK_UPDATE_STATUS && <BulkChangeStatus />}
        {ALLOWED_MEMBERS_BULK_CREATE_NOTE && <BulkAddNotes />}
        {ALLOWED_MEMBERS_BULK_UPDATE_GAME_ROUNDS &&
          gameRoundEnabled &&
          !isRow && <BulkGameRounds />}
        <BulkUpdateMemberLabels />
      </StyledColumn>
      {ALLOWED_MEMBERS_BULK_UPDATE_BALANCE && <BulkBalanceAdjustment />}
    </StyledDiv>
  );
};
export default BulkUpdate;
