import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery } from '@apollo/react-hooks';
import { Select, ConfigProvider, Empty } from 'antd';
import uuid from 'utils/uuid';
import { GamesConnectionEdge } from 'types/graphqlTypes';
import messages from 'pages/components/Reports/messages';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';

import { GAME_CATEGORIES, CATEGORIES_BY_VENDOR } from './queries';
import { StyledSelect, StyledSpan, StyledLabel } from '../../styles';

const { Option } = Select;

type Props = {
  state: any;
  setState: any;
};

// FE NOTES - IF NO GAME IDS IS RETURNED THIS IS A BACKEND BUG
// - PLEASE TALK WITH RESPONSIBLE BE GUY

const GameCategory: React.FC<Props> = ({ state, setState }) => {
  const [catList, setCatList] = React.useState([]) as any;
  const translate = useTranslate();

  // for populating available options
  const [
    loadInitial,
    { data: iData, called: iCalled, loading: iLoading },
  ] = useLazyQuery(CATEGORIES_BY_VENDOR, {
    variables: {},
  });

  const iEdges = coercedGet(iData, 'vendors.edges', []);

  const uniqCatOptions = iEdges
    .reduce(
      (acc: [GamesConnectionEdge], curr: GamesConnectionEdge) => [
        // @ts-ignore
        ...new Set([...acc, ...coercedGet(curr, 'node.gameCategories', [])]),
      ],
      []
    )
    .map((item: GamesConnectionEdge) => ({
      id: uuid(),
      category: item,
    }));

  if (!iCalled) loadInitial();

  const [loadSearch, { data, loading, called }] = useLazyQuery(
    GAME_CATEGORIES,
    {
      variables: {
        filter: {
          category: {
            in: catList,
          },
        },
      },
    }
  ) as any;

  if (!called) loadSearch();

  const edges = coercedGet(data, 'games.edges', []) as [GamesConnectionEdge];
  const gameIds = edges.length
    ? edges.map((edge: GamesConnectionEdge) => coercedGet(edge, 'node.id', ''))
    : [];

  React.useEffect(() => {
    if (gameIds.length) {
      setState((prev: any) => ({
        ...prev,
        game_category: { in: gameIds },
      }));
      return;
    }

    setState((prev: any) => ({
      ...prev,
      game_category: null,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, gameIds.length]);

  const handleChange = (e: Array<string>) => {
    if (e.length) {
      setCatList(() => e);

      return;
    }

    setCatList([]);
    setState((prev: any) => ({
      ...prev,
      game_category: null,
    }));
  };

  const handleRemoveGamesByCategory = (category: string) => {
    const gameIdsByCategory = edges.reduce((acc: Array<string>, curr: any) => {
      const { node } = curr;
      // @ts-ignore
      if (node.category === category) return [...new Set([...acc, node.id])];
      return acc;
    }, []);

    const preFilteredGames = coercedGet(state, 'game_category.in', []);
    const postFilteredGames = preFilteredGames.filter(
      (item: string) => !gameIdsByCategory.includes(item)
    );

    const newFilterArray = {
      in: postFilteredGames,
    };

    setState((prev: Record<string, any>) => ({
      ...prev,
      game_category: postFilteredGames.length ? newFilterArray : null,
    }));
  };

  const getSelectValue = () => {
    const contextStateIds = coercedGet(state, 'game_category.in', []);
    const postFilteredGames = edges.filter((edge: GamesConnectionEdge) =>
      contextStateIds.includes(coercedGet(edge, 'node.id', ''))
    );

    return [
      // @ts-ignore
      ...new Set(postFilteredGames.map(({ node }) => node.category)),
    ];
  };

  const uniqCategoryList = getSelectValue();

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.vendor-category.text"
              defaultMessage="Vendor Category"
            />{' '}
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={translate(messages['no-data.text'])}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        <StyledSelect
          onDeselect={(e: any) => {
            handleRemoveGamesByCategory(e);
            setCatList((prev: any) => {
              const newCatList: Array<string> = prev.filter(
                (item: string) => !item === e
              );

              // console.log(newCatList);
              return newCatList;
            });
          }}
          showSearch
          allowClear
          mode="multiple"
          placeholder={
            <FormattedMessage
              id="reports.search.text"
              defaultMessage="Search"
            />
          }
          style={{ width: '100%' }}
          value={uniqCategoryList || []}
          defaultValue={[]}
          onChange={handleChange as any}
          // onSelect={handleChange as any}
          optionFilterProp="label"
          loading={loading || iLoading}
          disabled={loading || iLoading}
        >
          {uniqCatOptions.map((option: any) => (
            <Option
              key={option.id}
              value={option.category}
              label={option.category}
            >
              {option.category}
            </Option>
          ))}
        </StyledSelect>
      </ConfigProvider>
    </>
  );
};

export default GameCategory;
