import gql from 'graphql-tag';

export const GET_MEMBER_TAGS_IDS = gql`
  query MemberTagsIds(
    $filter: MemberTagsFilterInput
    $partialFilter: MemberTagsFilterInput
  ) {
    memberTags(filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: memberTags(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_MEMBER_TAGS = gql`
  query memberTags(
    $first: Int
    $after: Binary
    $filter: MemberTagsFilterInput
  ) {
    memberTags(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          color
          description
          membersCount
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_MEMBER_TAG = gql`
  query memberTag($id: ID!) {
    memberTag(id: $id) {
      id
      name
      color
      description
    }
  }
`;
