import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useMutation } from '@apollo/react-hooks';
import { Button, Col, Input, message, Row, Select } from 'antd';
import { GameTypes } from 'constants/gameTypes';
import { useFormik } from 'formik';
import {
  CREATE_GAME_CATEGORY,
  UPDATE_GAME_CATEGORY,
} from 'graphql/mutations/vendorConfiguration.mutation';
import { get } from 'lodash';
import messages from 'messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DrawerFooter from 'components/DrawerFooter';
import { UseFormikErrorMessage } from 'components/UseFormikErrorMessage/UseFormikErrorMessage';
import styled from 'styled-components';
import { GameCategory } from 'types/graphqlTypes';
import setErrorMessage from 'utils/setErrorMessage';
import useTranslate from 'utils/useTranslate';
import * as Yup from 'yup';
import Drawer from '../../../common/Drawer/Drawer';
import {
  DrawerContainer,
  SideLabel,
  StyledSpacer,
  TitleContainer,
} from '../../styles';
import gameTypeMessages from './messages';
import { UploadImageForm } from './UploadImageForm';

const SelectMultiplePrimary = styled(Form.Item)`
  .ant-select-selection__choice {
    background: #1890ff !important;
    color: white !important;
    border-radius: 16px !important;
    width: 88px;
    height: 24px;
    font-size: 10px;
  }

  .ant-select-selection__choice__remove {
    color: white !important;
  }

  .ant-legacy-form-item-label {
    word-break: break-word;
    white-space: normal;
  }
`;

const gametypeKeys = Object.keys(GameTypes);

type Props = {
  closeDrawer: () => void;
  drawerStatus: boolean;
  formItemLayout: object;
  existingCategory: GameCategory | null;
  refetchCategories: () => void;
};

const NewCategoryDrawer: React.FC<Props> = ({
  closeDrawer,
  drawerStatus,
  formItemLayout,
  existingCategory,
  refetchCategories,
}) => {
  const translate = useTranslate();
  const acceptedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  const initialValues = {
    platform: 'MOBILE',
    name: get(existingCategory, 'name', ''),
    gameTypes: get(existingCategory, 'gameTypes', []),
    icon: get(existingCategory, 'icon.id', ''),
  };
  const NewCategorySchema = Yup.object().shape({
    name: Yup.string().required(
      translate(messages['category-name-required-error.text'])
    ),
    gameTypes: Yup.array().required(
      translate(messages['select-least-one-category-error.text'])
    ),
  });

  const [createGameCategory] = useMutation(CREATE_GAME_CATEGORY, {
    onCompleted: () => {
      refetchCategories();
      message.success(translate(messages.NEW_GAME_CATEGORY_ADDED));
      closeDrawer();
    },
    onError: (error) => {
      if (setErrorMessage(error) === 'GameCategory already existing') {
        message.error(
          translate(messages['name-already-exists.text'], {
            name: translate(messages.GAME_CATEGORY),
          })
        );
      } else {
        message.error(translate(messages.INTERNAL_SERVER_ERROR));
      }
    },
  });

  const [updateGameCategory] = useMutation(UPDATE_GAME_CATEGORY, {
    onCompleted: () => {
      refetchCategories();
      message.success(translate(messages.GAME_CATEGORY_UPDATED));
      closeDrawer();
    },
    onError: (error) => {
      if (setErrorMessage(error) === 'GameCategory already existing') {
        message.error(
          translate(messages['name-already-exists.text'], {
            name: translate(messages.GAME_CATEGORY),
          })
        );
      } else {
        message.error(translate(messages.INTERNAL_SERVER_ERROR));
      }
    },
  });

  const onCreateGameCategory = async (values: any) => {
    const { platform, name, gameTypes, icon } = values;
    if (existingCategory) {
      await updateGameCategory({
        variables: {
          id: existingCategory.id,
          input: {
            name,
            gameTypes,
            icon: icon === '' ? null : icon,
          },
        },
      }).catch((e) => console.log(e));
    } else {
      await createGameCategory({
        variables: {
          input: {
            platform,
            name,
            gameTypes,
            icon: icon === '' ? null : icon,
            vendors: [],
          },
        },
      }).catch((e) => console.log(e));
    }
  };

  const DrawerTitle = () =>
    existingCategory ? (
      <FormattedMessage
        defaultMessage="Edit Category"
        id="edit-category.text"
      />
    ) : (
      <FormattedMessage defaultMessage="New Category" id="new-category.text" />
    );

  const SideLabelTitle = () =>
    existingCategory ? (
      <FormattedMessage
        defaultMessage="Edit Game Category"
        id="edit-game-category.text"
      />
    ) : (
      <FormattedMessage
        defaultMessage="New Game Category"
        id="new-game-category.text"
      />
    );
  const formik = useFormik({
    initialValues,
    validationSchema: NewCategorySchema,
    onSubmit: async (values) => {
      await onCreateGameCategory(values);
    },
  });

  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  } = formik;

  return (
    <Drawer onClose={closeDrawer} open={drawerStatus}>
      <Drawer.Header title={<DrawerTitle />} />
      <Drawer.Content>
        <DrawerContainer className="container">
          <Row style={{ height: '70vh' }}>
            <Col span={3} style={{ height: '100%' }}>
              <SideLabel style={{ height: '100%' }}>
                <StyledSpacer />

                <div
                  style={{
                    borderRight: '2px solid #3590ff',
                    padding: '3px 20px',
                  }}
                >
                  <h4 style={{ color: '#3590ff', marginBottom: '0' }}>
                    <SideLabelTitle />
                  </h4>
                </div>
              </SideLabel>
            </Col>
            <Col span={21} style={{ borderLeft: '1px solid #e0e0e0' }}>
              <Row>
                <Col span={10}>
                  <StyledSpacer />

                  <TitleContainer>
                    <h4 style={{ marginBottom: '0', fontWeight: 600 }}>
                      <SideLabelTitle />
                    </h4>
                  </TitleContainer>
                  <div className="mt-3">
                    <Form layout="horizontal">
                      <Form.Item
                        label={translate(messages.CATEGORY_NAME)}
                        {...formItemLayout}
                      >
                        <Input
                          size="large"
                          name="name"
                          onBlur={handleBlur}
                          value={values.name}
                          onChange={handleChange}
                          placeholder={translate(messages.ENTER_NAME)}
                        />
                        <UseFormikErrorMessage formik={formik} name="name" />
                      </Form.Item>

                      <SelectMultiplePrimary
                        label={translate(messages.GAME_VENDOR_CATEGORY)}
                        {...formItemLayout}
                      >
                        <Select
                          size="large"
                          mode="multiple"
                          value={values.gameTypes}
                          placeholder={translate(messages.PLEASE_SELECT)}
                          onChange={(e: Array<string>) =>
                            setFieldValue('gameTypes', e)
                          }
                        >
                          {gametypeKeys.map((gameType) => (
                            <Select.Option key={gameType} value={gameType}>
                              {translate(gameTypeMessages[gameType])}
                            </Select.Option>
                          ))}
                        </Select>

                        <UseFormikErrorMessage
                          formik={formik}
                          name="gameTypes"
                        />
                      </SelectMultiplePrimary>

                      <UploadImageForm
                        formItemLayout={formItemLayout}
                        existingCategory={existingCategory}
                        setFieldValue={setFieldValue}
                        formik={formik}
                        type="icon"
                        fileTypes={acceptedFileTypes}
                        fileTypeText=".jpg .png"
                        labelTranslate="category-icon.text"
                      />
                    </Form>
                  </div>
                </Col>

                <Col span={14} />
              </Row>
            </Col>
          </Row>
        </DrawerContainer>
      </Drawer.Content>
      <DrawerFooter>
        <>
          <Button onClick={closeDrawer}>
            <FormattedMessage defaultMessage="Back" id="back.text" />
          </Button>
          <Button
            type="primary"
            onClick={() => handleSubmit()}
            loading={isSubmitting}
          >
            <FormattedMessage defaultMessage="Continue" id="continue.text" />
          </Button>
        </>
      </DrawerFooter>
    </Drawer>
  );
};

export default NewCategoryDrawer;
