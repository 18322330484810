export default [
  { code: 'SAGAMING_TRANSFER', label: 'SAGaming Transfer' },
  { code: 'KENOGAME', label: 'Kenogame' },
  { code: 'HC', label: 'HC' },
  { code: 'GOLDENF', label: 'GoldenF' },
  { code: 'ALLBET', label: 'Allbet' },
  { code: 'PT', label: 'PT' },
  { code: 'SAGAMING', label: 'SAGaming' },
  { code: 'CQ9', label: 'CQ9' },
  { code: 'JDB', label: 'JDB' },
  { code: 'MGDASHUR', label: 'MGDashur' },
  { code: 'KY', label: 'KY' },
  { code: 'SPADEGAMING', label: 'Spade Gaming' },
  { code: 'BBIN', label: 'BBIN' },
  { code: 'SGWIN', label: 'SGWIN' },
  { code: 'NEWWORLD', label: 'NEWWORLD' },
  { code: 'GPK_CQ9', label: 'GPK_CQ9' },
  { code: 'GPK_JDB', label: 'GPK_JDB' },
  { code: 'GPK_SG', label: 'GPK_SG' },
  { code: 'GPK_BB', label: 'GPK_BB' },
  { code: 'GPK_AE', label: 'GPK_AE' },
  { code: 'GPK_TH', label: 'GPK_TH' },
  { code: 'GPK_AG', label: 'GPK_AG' },
  { code: 'GPK_KG', label: 'GPK_KG' },
  { code: 'GPK_NW', label: 'GPK_NW' },
  { code: 'GPK_RGTP', label: 'GPK_RGTP' },
  { code: 'GPK_AT', label: 'GPK_AT' },
  { code: 'GPK_RG_TYCOON', label: 'GPK_RG_TYCOON' },
  { code: 'GPK_MGPLUS', label: 'GPK_MGPLUS' },
  { code: 'GPK_RG_KING', label: 'GPK_RG_KING' },
  { code: 'GPK_RG_COMING', label: 'GPK_RG_COMING' },
  { code: 'GPK_RG_MILL', label: 'GPK_RG_MILL' },
  { code: 'GPK_RG_CANDY', label: 'GPK_RG_CANDY' },
  { code: 'GPK_RG_JEWELS', label: 'GPK_RG_JEWELS' },
  { code: 'V365CARDS', label: '365Cards' },
  { code: 'MGPLUS', label: 'MG+' },
  { code: 'EBET', label: 'EBET' },
  { code: 'LONGCHENG', label: 'LongCheng' },
  { code: 'SPADEGAMING_TRANSFER', label: 'Spade Gaming Transfer' },
  { code: 'JDB_TRANSFER', label: 'JDB Transfer' },
];
// not updated?

export const transferVendors = [
  {
    label: 'SAGAMING Transfer',
    value: 'SAGAMING_TRANSFER',
  },
  {
    label: 'Kenogame',
    value: 'KENOGAME',
  },
  {
    label: 'GoldenF',
    value: 'GOLDENF',
  },
  {
    label: 'Allbet',
    value: 'ALLBET',
  },
  {
    label: 'PT',
    value: 'PT',
  },
  {
    label: 'KY',
    value: 'KY',
  },
  {
    label: 'BBIN',
    value: 'BBIN',
  },
  {
    label: 'SGWIN',
    value: 'SGWIN',
  },
  {
    label: 'GPK',
    value: 'GPK',
  },
  {
    label: 'NEW_WORLD',
    value: 'NEWWORLD',
  },
  {
    label: 'MGPLUS',
    value: 'MGPLUS',
  },
  {
    label: 'V365CARDS',
    value: 'V365CARDS',
  },
  {
    label: 'SPADEGAMING_TRANSFER',
    value: 'SPADEGAMING_TRANSFER',
  },
  {
    label: 'JDB_TRANSFER',
    value: 'JDB_TRANSFER',
  },
];

export const seamlessVendors = [
  {
    label: 'SAGAMING',
    value: 'SAGAMING',
  },
  {
    label: 'CQ9',
    value: 'CQ9',
  },
  {
    label: 'JDB',
    value: 'JDB',
  },
  {
    label: 'MGDASHUR',
    value: 'MGDASHUR',
  },
  {
    label: 'SPADEGAMING',
    value: 'SPADEGAMING',
  },
  {
    label: 'EBET',
    value: 'EBET',
  },
];

export const gpkVendors = [
  {
    label: 'GPK_CQ9',
    value: 'GPK_CQ9',
  },
  {
    label: 'GPK_JDB',
    value: 'GPK_JDB',
  },
  {
    label: 'GPK_SG',
    value: 'GPK_SG',
  },
  {
    label: 'GPK_BB',
    value: 'GPK_BB',
  },
  {
    label: 'GPK_AE',
    value: 'GPK_AE',
  },
  {
    label: 'GPK_TH',
    value: 'GPK_TH',
  },
  {
    label: 'GPK_AG',
    value: 'GPK_AG',
  },
  {
    label: 'GPK_KG',
    value: 'GPK_KG',
  },
  {
    label: 'GPK_NW',
    value: 'GPK_NW',
  },
  {
    label: 'GPK_RGTP',
    value: 'GPK_RGTP',
  },
  {
    label: 'GPK_AT',
    value: 'GPK_AT',
  },
  {
    label: 'GPK_RG_TYCOON',
    value: 'GPK_RG_TYCOON',
  },
  {
    label: 'GPK_MGPLUS',
    value: 'GPK_MGPLUS',
  },
  {
    label: 'GPK_RG_KING',
    value: 'GPK_RG_KING',
  },
  {
    label: 'GPK_RG_COMING',
    value: 'GPK_RG_COMING',
  },
  {
    label: 'GPK_RG_MILL',
    value: 'GPK_RG_MILL',
  },
  {
    label: 'GPK_RG_CANDY',
    value: 'GPK_RG_CANDY',
  },
  {
    label: 'GPK_RG_JEWELS',
    value: 'GPK_RG_JEWELS',
  },
  {
    label: 'GPK_FG',
    value: 'GPK_FG',
  },
  {
    label: 'GPK_BG',
    value: 'GPK_BG',
  },
  {
    label: 'GPK_AP',
    value: 'GPK_AP',
  },
  {
    label: 'GPK_TGP',
    value: 'GPK_TGP',
  },
  {
    label: 'GPK_SW',
    value: 'GPK_SW',
  },
];
