import gql from 'graphql-tag';

export const CREATE_BANNER = gql`
  mutation CreateBanner($input: CreateBannerInput!) {
    createBanner(input: $input)
  }
`;

export const DELETE_BANNER = gql`
  mutation DeleteBanner($id: ID!) {
    deleteBanner(id: $id)
  }
`;

export const UPDATE_BANNER = gql`
  mutation UpdateBanner($id: ID!, $input: UpdateBannerInput!) {
    updateBanner(id: $id, input: $input)
  }
`;

export const DUPLICATE_BANNER = gql`
  mutation DuplicateBanner($id: ID!) {
    duplicateBanner(id: $id)
  }
`;
