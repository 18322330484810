import gql from 'graphql-tag';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';
import { Modal, Select } from 'antd';

// ===[ MUTATION ] === //
export const UPDATE_ADMIN = gql`
  mutation updateAdmin($id: ID!, $input: UpdateAdminInput!, $password: String) {
    updateAdmin(id: $id, input: $input, password: $password)
  }
`;

// ===[ TRANSLATION ] === //
export const messages = defineMessages({
  'updated-successfully.text': {
    id: 'updated-successfully.text',
    defaultMessage: 'Updated successfully',
  },
  'copied.text': {
    id: 'copied.text',
    defaultMessage: 'Copied',
  },
  'nothing-to-copy.text': {
    id: 'nothing-to-copy.text',
    defaultMessage: 'Nothing to copy',
  },
  'please-key-in.text': {
    id: 'please-key-in.text',
    defaultMessage: 'Please key in',
  },
  'please-enter.text': {
    id: 'please-enter.text',
    defaultMessage: 'Please enter',
  },
});

// ===[ STYLE ]=== //
export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 26px;
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selection {
    border: 0;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }

  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }

  .ant-select-selection__choice {
    border-radius: 4px;
  }
`;

export const StyledField = styled.div`
  font-size: 15px;
  transform: translateY(5px);
`;

export const PasswordContainer = styled.div`
  .password {
    display: inline-block;
    width: 65%;
  }

  .generate {
    button {
      display: inline-block;
      width: 35%;
    }
  }
`;

export const ADMINS_IDS = gql`
  query ADMINS_IDS(
    $first: Int
    $filter: AdminsFilterInput
    $partialFilter: AdminsFilterInput
  ) {
    admins(first: $first, filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: admins(first: $first, filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const ADMINS = gql`
  query ADMINS($first: Int, $filter: AdminsFilterInput, $after: Binary) {
    admins(first: $first, filter: $filter, after: $after) {
      edges {
        node {
          id
          username
          adminCode
          contractStartDateTime
          contractEndDateTime
          personInCharge
          mobileNumber
          email
          currency
          multiCurrencyEnabled
          qqId
          ipWhitelist
          skypeId
          wechatId
          status
          company
          trial
          attachments {
            id
            uri
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;

export const VENDOR_GROUP = gql`
  query Admin_Next($id: ID!) {
    admin(id: $id) {
      id
      vendorGroup {
        id
        name
      }
    }
  }
`;

export const Container = styled.div`
  background: #fff;
  height: calc(100vh - 80px);
`;
