import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { Button, Divider, Input, message, Radio } from 'antd';
import {
  getNewType,
  isPaymentTypeInternal,
  isTypeAstroapy,
  isTypeBank,
  isTypeHexopay,
  isTypeInternalAlipay,
  isTypeInternalWechat,
  isTypeOfflineBank,
  PAYMENT_SOURCES,
} from 'constants/paymentTypes';
import thirdPartyProviders from 'constants/thirdPartyProviders';
import { useFormik } from 'formik';
import { getWithdrawalMutation } from 'graphql/mutations/withdrawalMethod.mutation';
import { getValidationSchema } from 'pages/components/WithdrawalMethods/components/AddEditWithdrawalMethod/validationSchema';
import messages from 'pages/components/WithdrawalMethods/messages';
import globalMsgs from 'messages';
import React, { useEffect } from 'react';
import coercedGet from 'utils/coercedGet';
import diffObject from 'utils/diffObject';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import AlipayDetails from './components/AlipayDetails';
import BankDetails from './components/BankDetails';
import ExternalProviders from './components/ExternalProviders';
import InternalProviders from './components/InternalProviders';
import OnlinePaymentChannel from './components/OnlinePaymentChannel';
import OtherSettings from './components/OtherSettings';
import WechatDetails from './components/WechatDetails';
import { PAYMENT_CHANNELS, PROVIDER_INFO } from './queries';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

type Rec = Record<string, any>;
type Props = {
  onClose: () => void;
  createPaymentMethod: () => void;
  loading: boolean;
  isEditing: boolean;
  paymentMethod: Rec;
  paymentType: string;
  type: string;
};

const PaymentGatewayForm = ({
  onClose,
  paymentMethod,
  isEditing,
  paymentType,
  loading,
  type,
}: Props) => {
  const client = useApolloClient();
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const {
    handleSubmit,
    handleChange,
    values,
    initialValues,
    setFieldValue,
    handleBlur,
    errors,
    setValues,
    isValid,
  }: any = useFormik({
    initialValues: {
      ...paymentMethod,
      paymentType,
      type,
    },
    onSubmit: async (formValues, { resetForm }) => {
      const {
        type: newType,
        paymentType: newPaymentType,
        id,
        nickname,
        withdrawalProvider,
        bank,
        accountName,
        accountNumber,
        branch,
        country,
        province,
        city,
        ...newValues
      }: Rec = formValues;
      let input: Rec = newValues;

      if (isPaymentTypeInternal(newPaymentType)) {
        delete input.paymentChannel;
        delete input.withdrawalProvider;

        if (isTypeOfflineBank(newType)) {
          input = {
            ...input,
            bank,
            accountName,
            accountNumber,
            branch,
            country,
            province,
            city,
          };
        } else {
          input = {
            ...input,
            nickname,
            accountNumber,
          };
        }
      } else {
        input = {
          ...input,
          withdrawalProvider,
        };
      }

      if (isTypeHexopay(newType) || isTypeAstroapy(newType)) {
        delete input.accountNumber;
        delete input.nickname;
      }

      let variables: Rec = {
        input,
      };

      if (isEditing) {
        variables = {
          input: diffObject(input, initialValues),
          id,
        };
      } else {
        variables = {
          ...variables,
        };
      }

      client
        .mutate({
          mutation: getWithdrawalMutation(newType, isEditing),
          variables,
          refetchQueries: ['WithdrawalMethodsList'],
          context,
        })
        .then(() => {
          message.success(
            translate(
              messages[isEditing ? 'successfullyupdated' : 'successfullyadded']
            )
          );
          resetForm();
          onClose();
        });
    },
    enableReinitialize: true,
    validationSchema: getValidationSchema(translate),
  });
  const [
    getProviderInfo,
    { data: providerInfoData = {}, loading: loadingProviderInfo },
  ] = useLazyQuery(PROVIDER_INFO, {
    fetchPolicy: 'network-only',
    onError: () => message.error(translate(globalMsgs.INTERNAL_SERVER_ERROR)),
  });

  const [
    getPaymentChannels,
    { data: paymentChannelsData = {}, loading: loadingPaymentChannels },
  ] = useLazyQuery(PAYMENT_CHANNELS, {
    fetchPolicy: 'network-only',
    onError: () => message.error(translate(globalMsgs.INTERNAL_SERVER_ERROR)),
  });

  useEffect(() => {
    if (values.withdrawalProvider) {
      const providerId =
        typeof values.withdrawalProvider === 'string'
          ? values.withdrawalProvider
          : values.withdrawalProvider.id;

      getProviderInfo({ variables: { id: providerId } });
    }
  }, [getProviderInfo, values.withdrawalProvider]);

  useEffect(() => {
    const paymentChannelKey = coercedGet(
      providerInfoData,
      'withdrawalProvider.apiKey',
      ''
    );

    const withdrawalType = coercedGet(
      providerInfoData,
      'withdrawalProvider.type',
      ''
    );

    if (
      paymentChannelKey &&
      values.withdrawalProvider &&
      withdrawalType === thirdPartyProviders.MIDPAY_DIRECT
    ) {
      getPaymentChannels({
        variables: {
          input: {
            apiKey: paymentChannelKey,
            methodType: 'WITHDRAW',
            integrationType: 'MIDPAY_DIRECT',
          },
        },
      });
    }
  }, [getPaymentChannels, providerInfoData, values.withdrawalProvider]);

  const midpayDirectId = coercedGet(
    providerInfoData,
    'withdrawalProvider.midPayDirectPaymentGateway.id',
    ''
  );
  const { paymentGateways = [] }: any = paymentChannelsData;
  const { paymentChannels = [] } =
    paymentGateways.length && midpayDirectId
      ? paymentGateways.find(
          (gateway: { id: string }) => gateway.id === midpayDirectId
        )
      : {};

  return (
    <Form onSubmit={handleSubmit}>
      <div className="p-6">
        {/* Title */}
        <Form.Item
          labelCol={{ span: 8 }}
          className="mb-1"
          label={<strong>{translate(messages.accountinfo)}</strong>}
          colon={false}
        />
        {/* Name */}
        <Form.Item
          label={translate(messages.withdrawalmethodname)}
          {...formItemLayout}
          validateStatus={errors.name && 'error'}
          help={errors.name || null}
        >
          <Input
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder={translate(messages.withdrawalmethodnameplaceholder)}
          />
        </Form.Item>
        {/* Payment Method Type */}
        <Form.Item
          label={translate(messages.withdrawalsources)}
          {...formItemLayout}
        >
          <Radio.Group
            onChange={(event) => {
              const { value } = event.target;
              setValues({
                ...values,
                paymentType: value,
                withdrawalProvider: isPaymentTypeInternal(value)
                  ? ''
                  : values.withdrawalProvider,
                paymentChannel: isPaymentTypeInternal(value)
                  ? ''
                  : values.paymentChannel,
                type: getNewType(values.type),
              });
            }}
            value={values.paymentType}
            name="paymentType"
            disabled={isEditing}
          >
            <Radio value={PAYMENT_SOURCES.OFFLINE}>
              {translate(messages.internal)}
            </Radio>
            <Radio value={PAYMENT_SOURCES.ONLINE}>
              {translate(messages.thirdpartygateway)}
            </Radio>
          </Radio.Group>
        </Form.Item>
        {!isPaymentTypeInternal(values.paymentType) && (
          <ExternalProviders
            values={values}
            setFieldValue={setFieldValue}
            formItemLayout={formItemLayout}
            errors={errors}
            disabled={isEditing}
          />
        )}
        <Form.Item
          label={translate(messages.withdrawalpaymentmethod)}
          validateStatus={errors.type && 'error'}
          help={errors.type || null}
          {...formItemLayout}
        >
          <InternalProviders
            value={values.type}
            paymentType={values.paymentType}
            name="type"
            disabled={isEditing}
            paymentChannels={paymentChannels}
            validatingChannels={loadingPaymentChannels || loadingProviderInfo}
            providerType={coercedGet(
              providerInfoData,
              'withdrawalProvider.__typename',
              ''
            )}
            handleChange={(event: any) => {
              if (isTypeBank(event.target.value)) {
                setValues({
                  ...values,
                  nickname: '',
                  paymentChannel: '',
                });
              } else {
                setValues({
                  ...values,
                  bank: '',
                  accountNumber: '',
                  accountName: '',
                  branch: '',
                  country: '',
                  city: '',
                  province: '',
                  paymentChannel: '',
                });
              }
              handleChange(event);
            }}
          />
        </Form.Item>
        {!isPaymentTypeInternal(values.paymentType) && midpayDirectId && (
          <OnlinePaymentChannel
            disabled={isEditing || !values.withdrawalProvider}
            depositProviderId={values.withdrawalProvider}
            paymentType={values.type}
            value={values.paymentChannel}
            setFieldValue={setFieldValue}
            formItemLayout={formItemLayout}
            errors={errors}
            presetPaymentChannels={paymentChannels}
            loadingProviderInfo={loadingProviderInfo}
            providerInfoData={coercedGet(
              providerInfoData,
              'withdrawalProvider',
              {}
            )}
          />
        )}
        {isTypeOfflineBank(values.type) && (
          <BankDetails
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            formItemLayout={formItemLayout}
          />
        )}
        {isTypeInternalWechat(values.type) && (
          <WechatDetails
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            formItemLayout={formItemLayout}
            setFieldValue={setFieldValue}
          />
        )}
        {isTypeInternalAlipay(values.type) && (
          <AlipayDetails
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            errors={errors}
            formItemLayout={formItemLayout}
          />
        )}
        <Divider />
        <OtherSettings
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          formItemLayout={formItemLayout}
        />
      </div>
      <div className="bt-1 p-4 text-right">
        <Button className="mr-2" onClick={onClose} disabled={loading}>
          {translate(messages.cancel)}
        </Button>{' '}
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={!isValid}
        >
          {translate(messages.confirm)}
        </Button>
      </div>
    </Form>
  );
};

export default PaymentGatewayForm;
