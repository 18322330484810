import React from 'react';
import Layout from 'components/Layout';
import { FilterItem } from 'components/FilterItem/FilterItem';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { Button, Checkbox, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useFilterValues } from 'contexts/Filters';
import { QuickSearch } from 'components/Layout/LeftSidebar/LeftSidebar';
import EditSearchSettings from '../EditSearchSettings';
import { Status } from '../../utils';

const Sidebar = () => {
  const translate = useTranslate();
  const { filters, onFilterChange } = useFilterValues();
  const quickSearch: QuickSearch = {
    filters,
    onFilterChange,
    contextKey: 'missions',
    editSearchSettings: EditSearchSettings,
    onFiltersUpdate: () => {},
  };
  return (
    <Layout.Sidebar quickSearch={quickSearch}>
      <FilterItem label={translate(messages.PROGRAMME_NAME)}>
        <Input />
      </FilterItem>
      <FilterItem label={translate(messages.MISSION_TYPE)}>
        <Input />
      </FilterItem>
      <FilterItem label={translate(messages.DATE_RANGE)}>
        <Input />
        <div className="mt-1">
          <Button size="small" className="mb-1">
            <FormattedMessage
              id="last-7-days.text"
              defaultMessage="Last 7 days"
            />
          </Button>
          <Button size="small" className="mb-1">
            <FormattedMessage id="last-week.text" defaultMessage="Last week" />
          </Button>
          <Button size="small" className="mb-1">
            <FormattedMessage
              id="last-30-days.text"
              defaultMessage="Last 30 days"
            />
          </Button>
        </div>
      </FilterItem>
      <FilterItem label={translate(messages.STATUS)}>
        <Checkbox.Group value={[]}>
          <Checkbox className="mt-1" value="ACTIVE">
            <Status text="ACTIVE" />
          </Checkbox>
          <br />
          <Checkbox className="mt-1" value="DISABLED">
            <Status text="INACTIVE" />
          </Checkbox>
          <br />
          <Checkbox className="mt-1" value="DRAFT">
            <Status text="DRAFT" />
          </Checkbox>
        </Checkbox.Group>
      </FilterItem>
    </Layout.Sidebar>
  );
};

export default Sidebar;
