import { useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import { STOP_PROCESS_WITHDRAWAL } from 'graphql/mutations/withdrawal.mutation';
import messages from 'pages/components/MemberWithdrawals/messages';
import React from 'react';
import { ALink } from 'components/ALink/ALink';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';

const StopProcessing = ({
  id,
  disabled,
}: {
  id: string;
  disabled: boolean;
}) => {
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [stopProcessDeposit, { loading }] = useMutation(
    STOP_PROCESS_WITHDRAWAL,
    {
      onCompleted: () => {
        message.success(translate(messages.withdrawalStopped));
      },
      variables: { id },
      context,
    }
  );

  return (
    <ALink onClick={() => stopProcessDeposit()} disabled={disabled}>
      {loading ? <Spin /> : null}
      {translate(messages.stopProcess)}
    </ALink>
  );
};

export default StopProcessing;
