import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Filters } from 'pages/components/PermissionGroup/types';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { DATE_FORMAT } from 'constants/date';
import { permissions as permissionsData } from 'constants/permissions';

type Props = {
  filters: Filters;
  onFilterChange: any;
  initialFilters: Filters;
};

const FilterConditions = (props: Props) => {
  const { filters, onFilterChange } = props;
  const [localStateFilter, setLocalStateFilter] = useState<Partial<Filters>>(
    {}
  );

  const handleRemoveName = (value: Partial<Props>, e: any) => {
    e.preventDefault();
    const { name } = filters;
    const data = name?.in?.filter((item: any) => item !== value) || [];
    const newName = {
      in: data,
    };

    onFilterChange({
      ...filters,
      name: data.length ? newName : null,
    });
  };

  useEffect(() => {
    const transformedDateFilters = transformMovableDateFilter(filters);
    setLocalStateFilter(transformedDateFilters || {});
  }, [filters]);

  const handleRemoveSerialCode = (
    value: Partial<Props>,
    e: React.SyntheticEvent
  ) => {
    e.preventDefault();
    const { serialCode } = filters;
    const data = serialCode?.in?.filter((item) => item !== value) || [];
    const newSerialCode = {
      in: data,
    };
    onFilterChange({
      ...filters,
      serialCode: data.length ? newSerialCode : null,
    });
  };

  const handleRemovePermissions = (value: any, e: any) => {
    e.preventDefault();
    const { permissions } = filters;
    const data = permissions?.in?.filter((item) => item !== value) || [];
    const newPermissions = {
      in: data,
    };
    onFilterChange({
      ...filters,
      permissions: data.length ? newPermissions : null,
    });
  };

  const handleRemoveDateTimeUpdated = () => {
    onFilterChange({
      ...filters,
      dateTimeUpdated: null,
    });
  };

  return (
    <>
      {filters.name
        ? filters.name.in.map((item, key) => (
            <Tag
              key={key}
              closable
              onClose={(e: any) => handleRemoveName(item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.serialCode
        ? filters.serialCode.in.map((item, key) => (
            <Tag
              key={key}
              closable
              onClose={(e: any) => handleRemoveSerialCode(item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.permissions
        ? filters.permissions.in.map((item, key) => (
            <Tag
              key={key}
              closable
              onClose={(e: any) => handleRemovePermissions(item, e)}
            >
              {permissionsData.find((pItem) => pItem.value === item)?.label ||
                item}
            </Tag>
          ))
        : null}
      {localStateFilter.dateTimeUpdated ? (
        <Tag closable onClose={handleRemoveDateTimeUpdated}>
          <FormattedMessage
            id="last-update.text"
            defaultMessage="Last update"
          />
          :{moment(localStateFilter.dateTimeUpdated.gte).format(DATE_FORMAT)}~
          {moment(localStateFilter.dateTimeUpdated.lte).format(DATE_FORMAT)}
        </Tag>
      ) : null}
    </>
  );
};

export default FilterConditions;
