import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import useTranslate from 'utils/useTranslate';
import messages from 'pages/components/WithdrawalMethods/messages';

type Rec = Record<string, any>;
type Props = {
  formItemLayout: Rec;
  values: Rec;
  errors: Rec;
  handleBlur: () => void;
  handleChange: () => void;
  setFieldValue: (e: string) => void;
  disabled?: boolean;
};

const WechatDetails = (props: Props) => {
  const {
    formItemLayout,
    values,
    errors,
    handleBlur,
    handleChange,
    disabled,
  } = props;

  const translate = useTranslate();
  return (
    <>
      <Form.Item
        label={translate(messages.nickname)}
        {...formItemLayout}
        validateStatus={errors.nickname && 'error'}
        help={errors.nickname || null}
      >
        <Input
          name="nickname"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.nickname}
          placeholder={translate(messages.nickname)}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.bankacnumber)}
        {...formItemLayout}
        validateStatus={errors.accountNumber && 'error'}
        help={errors.accountNumber || null}
      >
        <Input
          name="accountNumber"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.accountNumber}
          placeholder={translate(messages.bankacnumber)}
        />
      </Form.Item>
    </>
  );
};

export default WechatDetails;
