import gql from 'graphql-tag';

export const GAMES = gql`
  query games($first: Int, $after: Binary, $filter: GamesFilterInput) {
    games(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        node {
          id
          name
          nameEn: name(language: EN)
          nameZh: name(language: ZH)
          nameZhHant: name(language: ZH_HANT)
          type
          stakes {
            rtp
            houseEdge
          }
        }
      }
    }
  }
`;
