import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import {
  PAYMENT_TYPES,
  PAYMENT_TYPES_PLAIN_LIST,
} from 'constants/paymentTypes';
import { FilterItem } from 'components/FilterItem/FilterItem';

import useTranslate from 'utils/useTranslate';
import messages from '../../../../messages';

const bank = 'BANK';
const alipay = 'ALIPAY';
const wechat = 'WECHAT';
const crypto = 'CRYPTOCURRENCY';
const unionPay = 'UNION_PAY';

const external = 'EXTERNAL';
const internal = 'INTERNAL';

type Props = {
  value: string[];
  onChange: (data: any) => void;
};

const PaymentMethodTypeFilter = ({ value, onChange }: Props) => {
  const types = [bank, alipay, wechat, crypto, unionPay];

  let filterVals: any[] = [];

  const [sources, setSource] = useState<any[]>([]);

  useEffect(() => {
    if (!value.length) {
      setSource([]);
    } else {
      const hasExternal = value.some((paymentMethod) =>
        PAYMENT_TYPES_PLAIN_LIST.ONLINE.includes(paymentMethod)
      );
      const hasInternal = value.some((paymentMethod) =>
        PAYMENT_TYPES_PLAIN_LIST.OFFLINE.includes(paymentMethod)
      );

      setSource([
        ...(hasExternal ? [external] : []),
        ...(hasInternal ? [internal] : []),
      ]);
    }
  }, [value]);

  if (
    value.includes(PAYMENT_TYPES.OFFLINE.BANK) ||
    value.includes(PAYMENT_TYPES.ONLINE.BANK)
  ) {
    filterVals = [...filterVals, bank];
  }
  if (
    value.includes(PAYMENT_TYPES.OFFLINE.ALIPAY) ||
    value.includes(PAYMENT_TYPES.ONLINE.ALIPAY)
  ) {
    filterVals = [...filterVals, alipay];
  }

  if (
    value.includes(PAYMENT_TYPES.OFFLINE.WECHAT) ||
    value.includes(PAYMENT_TYPES.ONLINE.WECHAT)
  ) {
    filterVals = [...filterVals, wechat];
  }

  if (value.includes(PAYMENT_TYPES.OFFLINE.CRYPTOCURRENCY)) {
    filterVals = [...filterVals, crypto];
  }

  if (value.includes(PAYMENT_TYPES.ONLINE.UNION_PAY)) {
    filterVals = [...filterVals, unionPay];
  }

  const handleSourceChange = (e: any[]) => {
    let values: any = [];

    if (value.length) {
      if (e.includes(internal)) {
        types.forEach((type) => {
          if (
            PAYMENT_TYPES.OFFLINE[type] &&
            (value.includes(PAYMENT_TYPES.OFFLINE[type]) ||
              value.includes(PAYMENT_TYPES.ONLINE[type]))
          ) {
            values.push(PAYMENT_TYPES.OFFLINE[type]);
          }
        });
      }

      if (e.includes(external)) {
        types.forEach((type) => {
          if (
            PAYMENT_TYPES.ONLINE[type] &&
            (value.includes(PAYMENT_TYPES.OFFLINE[type]) ||
              value.includes(PAYMENT_TYPES.ONLINE[type]))
          ) {
            values.push(PAYMENT_TYPES.ONLINE[type]);
          }
        });
      }
    } else {
      if (e.includes(internal)) {
        const newValues: any[] = [];
        types.forEach((type) => {
          if (PAYMENT_TYPES.OFFLINE[type]) {
            newValues.push(PAYMENT_TYPES.OFFLINE[type]);
          }
        });
        values = [...values, ...newValues];
      }

      if (e.includes(external)) {
        const newValues: any[] = [];
        types.forEach((type) => {
          if (PAYMENT_TYPES.ONLINE[type]) {
            newValues.push(PAYMENT_TYPES.ONLINE[type]);
          }
        });
        values = [...values, ...newValues];
      }
    }

    onChange(values);
    setSource(e);
  };

  const handleChange = (e: any[]) => {
    const values: any[] = [];

    types.forEach((type) => {
      if (e.includes(type)) {
        if (sources.includes(internal) && PAYMENT_TYPES.OFFLINE[type]) {
          values.push(PAYMENT_TYPES.OFFLINE[type]);
        }
        if (sources.includes(external) && PAYMENT_TYPES.ONLINE[type]) {
          values.push(PAYMENT_TYPES.ONLINE[type]);
        }
        if (!sources.length) {
          if (PAYMENT_TYPES.ONLINE[type]) {
            values.push(PAYMENT_TYPES.ONLINE[type]);
          }
          if (PAYMENT_TYPES.OFFLINE[type]) {
            values.push(PAYMENT_TYPES.OFFLINE[type]);
          }
        }
      }
    });

    onChange(values);
  };

  const translate = useTranslate();

  return (
    <>
      <FilterItem label={translate(messages.paymentSource)}>
        <Checkbox.Group onChange={handleSourceChange} value={sources}>
          <Checkbox className="mt-1" value={internal}>
            {translate(messages.internal)}
          </Checkbox>
          <br />
          <Checkbox className="mt-1" value={external}>
            {translate(messages.thirdparty)}
          </Checkbox>
        </Checkbox.Group>
      </FilterItem>
      <FilterItem label={translate(messages.paymentmethodtype)}>
        <Checkbox.Group onChange={handleChange} value={filterVals}>
          <Checkbox className="mt-1" value={bank}>
            {translate(messages.banktransfer)}
          </Checkbox>
          <br />
          <Checkbox className="mt-1" value={alipay}>
            {translate(messages.alipay)}
          </Checkbox>
          <br />
          <Checkbox className="mt-1" value={wechat}>
            {translate(messages.wechat)}
          </Checkbox>
          <br />
          <Checkbox className="mt-1" value={crypto}>
            {translate(messages.USDT)}
          </Checkbox>
          <br />

          <Checkbox className="mt-1" value={unionPay}>
            {translate(messages.unionpay)}
          </Checkbox>
        </Checkbox.Group>
      </FilterItem>
    </>
  );
};

export default PaymentMethodTypeFilter;
