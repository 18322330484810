import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-loyalty.on.text': {
    id: 'member-loyalty.on.text',
    defaultMessage: 'On',
  },
  'member-loyalty.name.text': {
    id: 'member-loyalty.name.text',
    defaultMessage: 'Name',
  },
  'member-loyalty.off.text': {
    id: 'member-loyalty.off.text',
    defaultMessage: 'Off',
  },
  'member-loyalty.enter-tier-description.text': {
    id: 'member-loyalty.enter-tier-description.text',
    defaultMessage: 'Enter tier description',
  },
});

export default messages;
