import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { CREATE_WITHDRAWAL_PROVIDER } from 'graphql/mutations/withdrawalProvider.mutation';
import { WITHDRAWAL_PROVIDERS } from 'graphql/queries/withdrawalProvider.query';
import messages from 'pages/components/WithdrawalProviders/messages';
import globalMsgs from 'messages';
import React, { useState } from 'react';
import { withdrawalProvidersTestId } from 'components/data-testid/WithdrawalProviders';
import useTranslate from 'utils/useTranslate';
import ProviderForm from '../ProviderForm';
import { StyledModal } from './styles';

const NewProvider = () => {
  const translate = useTranslate();
  const [isShow, setIsShow] = useState(false);
  const editable = true;
  const handleModal = () => setIsShow((prevState) => !prevState);

  const refetchQueries = [
    {
      query: WITHDRAWAL_PROVIDERS,
      variables: { first: 10, filter: {} },
    },
  ];

  const [createWithdrawalProvider, { loading }] = useMutation(
    CREATE_WITHDRAWAL_PROVIDER,
    {
      onCompleted: () => {
        message.success({
          content: (
            <span data-testid="success">
              {translate(
                messages['3rd-party-withdrawal-providers-created.text']
              )}
            </span>
          ),
        });
      },
      onError: () => message.error(translate(globalMsgs.INTERNAL_SERVER_ERROR)),
      refetchQueries,
    }
  );

  return (
    <>
      <Button
        data-testid={withdrawalProvidersTestId.createButton}
        onClick={handleModal}
        style={{ marginTop: 'auto' }}
        type="primary"
      >
        <PlusOutlined />
        {translate(messages['add-withdrawal-provider.text'])}
      </Button>
      <StyledModal
        title={translate(messages['add-withdrawal-provider.text'])}
        visible={isShow}
        width={800}
        onOk={handleModal}
        onCancel={handleModal}
        footer={false}
      >
        <div data-testid="modal">
          <ProviderForm
            editable={editable}
            onClose={handleModal}
            submitWithdrawalProvider={createWithdrawalProvider}
            loading={loading}
          />
        </div>
      </StyledModal>
    </>
  );
};

export default NewProvider;
