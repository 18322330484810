import React, { useState } from 'react';
import {
  Button,
  Col,
  Input,
  Row,
  Radio,
  DatePicker,
  Checkbox,
  InputNumber,
} from 'antd';
import messages from 'messages';
import * as Yup from 'yup';
import { FormattedMessage, defineMessages } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import moment from 'moment';
import { createBreakpoint } from 'react-use';
import { Controller, useForm } from 'react-hook-form';
import DrawerFooter from 'components/DrawerFooter';
import { yupResolver } from '@hookform/resolvers';
import Drawer from '../../../common/Drawer/Drawer';
import { useMissionsValue } from '../../context';

const labelSpan = 8;
const itemSpan = 16;
const { RangePicker } = DatePicker;

const useBreakpoint = createBreakpoint();

const FormItem = ({ children, label }: { children: any; label: string }) => (
  <Row className="d-flex align-items-center mt-3">
    <Col span={labelSpan}>
      <p className="text-right mb-0">{label}</p>
    </Col>
    <Col span={itemSpan} className="pl-3">
      {children}
    </Col>
  </Row>
);

const localMessage = defineMessages({
  'MISSIONS.NAME_MAX_LIMIT': {
    id: 'MISSIONS.NAME_MAX_LIMIT',
    defaultMessage: 'Name should be within {count} Characters',
  },
});

const initValues = {
  name: '',
  automaticEnrollment: false,
  enrolmentDateRange: [],
  requireBankAccount: false,
  maximumEnrolleesCountPerIpAddress: 0,
  maximumEnrolleesCountPerName: 0,
  automaticPayout: true,
  generateRebate: false,
  generateMemberLoyaltyProgress: false,
};

const validationSchema = (translate: any) =>
  Yup.object().shape({
    name: Yup.string()
      .required(translate(messages.REQUIRED))
      .max(
        20,
        translate(localMessage['MISSIONS.NAME_MAX_LIMIT'], { count: 20 })
      ),
    enrolmentDateRange: Yup.array().min(1, translate(messages.REQUIRED)),
  });

const GeneralSettings = () => {
  const translate = useTranslate();
  const {
    resetDrawer,
    setGeneralSettingsValue,
    onNextStep,
    generalSettingsValue,
  } = useMissionsValue();
  const [registeredIpSetting, setRegisteredIpSetting] = useState(
    generalSettingsValue?.registeredIpSetting === undefined
      ? true
      : generalSettingsValue?.registeredIpSetting
  );
  const [nameSetting, setNameSetting] = useState(
    generalSettingsValue?.nameSetting === undefined
      ? true
      : generalSettingsValue?.nameSetting
  );
  const breakpoint = useBreakpoint();
  const isLarge = breakpoint === 'laptopL';
  const isMedium = breakpoint === 'laptop';
  const isSmall = breakpoint === 'tablet';
  const defaultValues = {
    ...initValues,
    ...generalSettingsValue,
  };

  const { getValues, control, handleSubmit, errors, watch } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(translate)),
  });

  const watchName = watch('name');

  const onSubmit = () => {
    setGeneralSettingsValue({
      ...getValues(),
      registeredIpSetting,
      nameSetting,
    });
    onNextStep();
  };

  const styleValue = () => {
    if (isLarge) {
      return {
        marginRight: '27%',
        marginLeft: '27%',
      };
    }

    if (isMedium) {
      return {
        marginRight: '10%',
        marginLeft: '10%',
      };
    }

    return {
      marginRight: '0',
      marginLeft: '0',
    };
  };

  const disabledDate = (current: moment.Moment): boolean =>
    current && current < moment().endOf('day');

  return (
    <>
      <Drawer.Content className="mt-3">
        <form>
          <div style={styleValue()}>
            <Row>
              <Col span={labelSpan}>
                <p className="fs-16 fw-500 text-right">
                  <FormattedMessage
                    id="GENERAL_SETTINGS"
                    defaultMessage="General Settings"
                  />
                </p>
              </Col>
            </Row>

            <Row className="d-flex align-items-center">
              <Col span={labelSpan}>
                <p className="text-right mb-0">
                  {translate(messages.MISSION_PROGRAMME_NAME)}
                </p>
              </Col>
              <Col span={itemSpan} className="pl-3">
                <Controller
                  name="name"
                  as={
                    <Input
                      style={{
                        width: isSmall ? '60%' : '70%',
                        marginRight: '5px',
                      }}
                    />
                  }
                  control={control}
                />
                <span className="text-muted">
                  {watchName?.length}/20{' '}
                  <FormattedMessage
                    id="CHARACTERS"
                    defaultMessage="Characters"
                  />
                </span>
                {errors?.name && (
                  <div className="text-danger fs-12">{errors.name}</div>
                )}
              </Col>
            </Row>

            <FormItem label={translate(messages.ACTIVATE_BY_MEMBER)}>
              <Row>
                <Col span={14}>
                  <Controller
                    name="automaticEnrollment"
                    control={control}
                    render={({ onChange, value }) => (
                      <Radio.Group
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        className="d-flex justify-content-between"
                      >
                        <Radio value>
                          <FormattedMessage id="YES" defaultMessage="Yes" />
                        </Radio>
                        <Radio value={false}>
                          <FormattedMessage id="NO" defaultMessage="No" />
                        </Radio>
                      </Radio.Group>
                    )}
                  />
                </Col>
              </Row>
            </FormItem>

            <FormItem label={translate(messages.ENROLMENT_DATE_RANGE)}>
              <Controller
                name="enrolmentDateRange"
                control={control}
                render={({ value, onChange }) => (
                  <RangePicker
                    disabledDate={disabledDate as any}
                    onChange={(dates) => onChange(dates)}
                    value={value}
                  />
                )}
              />
              {errors?.enrolmentDateRange && (
                <div className="text-danger fs-12">
                  {errors.enrolmentDateRange}
                </div>
              )}
            </FormItem>

            <FormItem label={translate(messages.MISSION_SETTINGS)}>
              <Controller
                name="requireBankAccount"
                control={control}
                render={({ value, onChange }) => (
                  <Checkbox
                    checked={value}
                    onChange={(e) => onChange(e.target.checked || false)}
                  >
                    <FormattedMessage
                      id="BANK_CARD_BINDING"
                      defaultMessage="Bank Card Binding"
                    />
                  </Checkbox>
                )}
              />
            </FormItem>

            <FormItem label={translate(messages.REGISTERED_IP_LIMIT_PER_CYCLE)}>
              <Row className="d-flex align-items-center">
                <Col span={14}>
                  <Radio.Group
                    value={registeredIpSetting}
                    onChange={(e) => setRegisteredIpSetting(e.target.value)}
                    className="d-flex justify-content-between"
                  >
                    <Radio
                      value
                      style={{ marginRight: isSmall ? 'unset' : '80px' }}
                    >
                      <FormattedMessage
                        id="NO_LIMIT"
                        defaultMessage="No Limit"
                      />
                    </Radio>
                    <Radio value={false}>
                      <FormattedMessage id="LIMIT" defaultMessage="Limit" />
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col span={10}>
                  <Controller
                    name="maximumEnrolleesCountPerIpAddress"
                    control={control}
                    as={
                      <InputNumber
                        disabled={registeredIpSetting}
                        style={{
                          width: isSmall ? '50%' : '65%',
                          marginRight: '10px',
                          marginLeft: '10px',
                        }}
                      />
                    }
                  />

                  <FormattedMessage id="TIMES" defaultMessage="times" />
                </Col>
              </Row>
            </FormItem>

            <FormItem label={translate(messages.SAME_NAME_LIMIT_PER_CYCLE)}>
              <Row className="d-flex align-items-center">
                <Col span={14}>
                  <Radio.Group
                    value={nameSetting}
                    onChange={(e) => setNameSetting(e.target.value)}
                    className="d-flex justify-content-between"
                  >
                    <Radio
                      value
                      style={{ marginRight: isSmall ? 'unset' : '80px' }}
                    >
                      <FormattedMessage
                        id="NO_LIMIT"
                        defaultMessage="No Limit"
                      />
                    </Radio>
                    <Radio value={false}>
                      <FormattedMessage id="LIMIT" defaultMessage="Limit" />
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col span={10}>
                  <Controller
                    name="maximumEnrolleesCountPerName"
                    control={control}
                    as={
                      <InputNumber
                        disabled={nameSetting}
                        style={{
                          width: isSmall ? '50%' : '65%',
                          marginRight: '10px',
                          marginLeft: '10px',
                        }}
                      />
                    }
                  />

                  <FormattedMessage id="TIMES" defaultMessage="times" />
                </Col>
              </Row>
            </FormItem>

            <FormItem label={translate(messages.PAYOUT_METHOD)}>
              <Row>
                <Col span={14}>
                  <Controller
                    name="automaticPayout"
                    control={control}
                    render={({ value, onChange }) => (
                      <Radio.Group
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        className="d-flex justify-content-between"
                      >
                        <Radio value style={{ marginRight: '20px' }}>
                          <FormattedMessage
                            id="AUTOMATIC_PAYOUT"
                            defaultMessage="Automatic Payout"
                          />
                        </Radio>
                        <Radio value={false}>
                          <FormattedMessage
                            id="MANUAL_PAYOUT"
                            defaultMessage="Manual Payout"
                          />
                        </Radio>
                      </Radio.Group>
                    )}
                  />
                </Col>
              </Row>
            </FormItem>

            <FormItem label={translate(messages.GENERATE_REBATE)}>
              <Row>
                <Col span={14}>
                  <Controller
                    name="generateRebate"
                    control={control}
                    render={({ value, onChange }) => (
                      <Radio.Group
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        className="d-flex justify-content-between"
                      >
                        <Radio value>
                          <FormattedMessage id="YES" defaultMessage="Yes" />
                        </Radio>
                        <Radio value={false}>
                          <FormattedMessage id="NO" defaultMessage="No" />
                        </Radio>
                      </Radio.Group>
                    )}
                  />
                </Col>
              </Row>
            </FormItem>

            <FormItem label={translate(messages.GENERATE_VIP_PROGRESSION)}>
              <Row>
                <Col span={14}>
                  <Controller
                    name="generateMemberLoyaltyProgress"
                    control={control}
                    render={({ value, onChange }) => (
                      <Radio.Group
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        className="d-flex justify-content-between"
                      >
                        <Radio value>
                          <FormattedMessage id="YES" defaultMessage="Yes" />
                        </Radio>
                        <Radio value={false}>
                          <FormattedMessage id="NO" defaultMessage="No" />
                        </Radio>
                      </Radio.Group>
                    )}
                  />
                </Col>
              </Row>
            </FormItem>
          </div>
        </form>
      </Drawer.Content>
      <DrawerFooter>
        <>
          <Button onClick={() => resetDrawer()}>
            <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
          </Button>
          <Button type="primary" onClick={handleSubmit(onSubmit)}>
            <FormattedMessage id="CONTINUE" defaultMessage="Continue" />
          </Button>
        </>
      </DrawerFooter>
    </>
  );
};

export default GeneralSettings;
