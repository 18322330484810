import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'common.edit.text': {
    id: 'common.edit.text',
    defaultMessage: 'Edit',
  },
});

export default messages;
