import styled from 'styled-components';
import { Card, Row } from 'antd';

export const StyledCard = styled(Card)`
  border-radius: 8px !important;

  .ant-card-body {
    padding: 16px;
    border-radius: 8px;
  }

  h2 {
    font-size: 24px;
  }

  .label {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: left;
    background: #fff;
    color: rgba(0, 0, 0, 0.45);
  }

  .first {
    margin-top: -20px;
  }
`;

export const StyledFooter = styled(Row)`
  font-size: 12px;
`;

export default {};
