import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'tier.text': {
    id: 'tier.text',
    defaultMessage: 'Tier',
  },
  'tier-name.text': {
    id: 'tier-name.text',
    defaultMessage: 'Tier Name',
  },
  'min-active-members.text': {
    id: 'min-active-members.text',
    defaultMessage: 'Min Active Members',
  },
  'max-active-members.text': {
    id: 'max-active-members.text',
    defaultMessage: 'Max Active Members',
  },
  'min-net-profit.text': {
    id: 'min-net-profit.text',
    defaultMessage: 'Min Net Profit',
  },
  'max-net-profit.text': {
    id: 'max-net-profit.text',
    defaultMessage: 'Max Net Profit',
  },
  'commission.text': {
    id: 'commission.text',
    defaultMessage: 'Commission',
  },
});

export default messages;
