import React, { useState } from 'react';
import Layout from 'components/Layout';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { useFilterValues } from 'contexts/Filters';
import SavedFilterSelect from 'components/SavedFilterSelect';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import EditSearchSettings from '../EditSearchSettings';
import FilterItems from '../FilterItems';

const SideBar = ({ open }: { open: boolean }) => {
  const {
    savedFilterId,
    handleSavedFilterIdChange,
    handleFilterChange,
    filters,
    onFilterChange,
  } = useFilterValues();

  const [key, setKey] = useState(0);

  /**
   * Re-renders the <SavedFilterSelect/> to update the list of filters
   */
  const handleUpdateKey = () => {
    setKey((prev) => prev + 1);
  };

  const translate = useTranslate();

  return (
    <Layout.Sidebar
      sidebarStatus={open}
      quickSearch={{
        filters: { ...filters, member: null },
        onFilterChange,
        contextKey: QuickSearchIds.BALANCE_SERVICE_RECORD_REPORT as any,
        editSearchSettings: EditSearchSettings,
        onFiltersUpdate: () => handleUpdateKey(),
        hideQuickSearchFilter: true,
      }}
    >
      <>
        <FilterItem label={translate(messages.QUICK_FILTER)}>
          <SavedFilterSelect
            placeHolder={translate(messages.SELECT_SAVED_CUSTOM_FILTER)}
            value={savedFilterId}
            onChange={(val) => handleSavedFilterIdChange(val)}
            context={QuickSearchIds.BALANCE_SERVICE_RECORD_REPORT as any}
            key={key}
          />
        </FilterItem>

        <FilterItems filters={filters} onRawFilterChange={handleFilterChange} />
      </>
    </Layout.Sidebar>
  );
};

export default SideBar;
