import React, { useState } from 'react';
import { message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useMutation } from '@apollo/react-hooks';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import SearchSettingsForm from '../SearchSettingsForm';
import { EDIT_CUSTOM_FILTER } from './mutations';
import { CUSTOM_FILTERS } from '../SearchSettingsList/queries';

type Props = {
  searchSettings: Record<string, any>;
  onClose: () => void;
};

const EditSearchSettings = ({ searchSettings, onClose }: Props) => {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const [updateCustomFilter] = useMutation(EDIT_CUSTOM_FILTER);
  const handleSubmit = async (fields: Record<string, any>) => {
    const derivedFields = {
      ...fields,
      ...(fields.dateRange.length && {
        dateRange: [
          typeof fields.dateRange[0] === 'string'
            ? fields.dateRange[0]
            : fields.dateRange[0].utc().format(),
          typeof fields.dateRange[1] === 'string'
            ? fields.dateRange[1]
            : fields.dateRange[1].utc().format(),
        ],
      }),
    };
    try {
      setLoading(true);
      if (!isEmpty(removeNull(derivedFields))) {
        await updateCustomFilter({
          variables: {
            id: searchSettings.id,
            input: {
              name: searchSettings.name,
              filter: removeNull(derivedFields),
            },
          },
          refetchQueries: [
            {
              query: CUSTOM_FILTERS,
              variables: {
                filter: {
                  context: {
                    eq: 'member-game-reports',
                  },
                },
              },
            },
          ],
        });
        message.success(
          `${translate(messages['search-settings-updated.text'])}`
        );
        setLoading(false);
      } else {
        message.error(
          `${translate(messages['cant-save-empty-filters-error.text'])}`
        );
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <SearchSettingsForm
      initialValues={searchSettings.filter}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default EditSearchSettings;
