import removeNull from 'utils/removeNull';

const removeNestedNulls = (object: Record<string, any> | Array<any>): any => {
  if (typeof object !== 'object') return object;
  if (Array.isArray(object)) {
    return object.map(item => removeNestedNulls(item));
  }

  return Object.entries(removeNull(object) as any).reduce(
    (acc: any, curr: any) => {
      const [key, val] = curr;
      acc[key] = removeNestedNulls(val);

      return acc;
    },
    {}
  );
};
export default removeNestedNulls;
