import React, { useState } from 'react';
import { DollarTwoTone, EllipsisOutlined } from '@ant-design/icons';
import { Tooltip, Dropdown, Menu } from 'antd';
import { defineMessages } from 'react-intl';
import { usePermissions } from 'store/accountState';
import messages from 'messages';
import Circle from 'components/Circle';
import { getStatusColor } from 'utils/depositWithdrawalStatus';
import moment from 'moment';
import { StyledTable } from 'constants/styles';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
// import { isVipVisible } from 'utils/isVipVisible';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { showPermissionError } from 'components/Navbar/Navbar';
import { ALink } from 'components/ALink/ALink';
import { OriginalColumn } from 'store/customColumnState/types';
import { DEPOSIT_REQUEST_EXTERNAL } from 'constants/testIds';
import { Deposit, Account, DepositStatus, Member } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import { columnAlign } from 'utils/tableAlignment';
import AffiliateIndicatorBatch from 'components/AffiliateIndicatorBatch';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import { DATE_FORMAT } from 'constants/date';
import MemberLevelButton from 'components/MemberLevelButton';
// import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import MemberLabelRemarks from './components/MemberLabelRemarks';
import DepositPaymentMethod from './components/DepositPaymentMethod';
import DepositDetails from '../DepositDetails';
import MemberProfile from './components/MemberProfile';
import ApproveDeposit from './components/ApproveDeposit';
import RejectDeposit from './components/RejectDeposit';

import StopProcessing from './components/StopProcessing';

type Props = {
  dataSource: Array<object>;
  loading: boolean;
  isActiveTab: boolean;
  refetchVariables: object;
};

const getProcessingTime = (deposit: Partial<Deposit>) => {
  const { dateTimeCreated, dateTimeProcessed, status } = deposit;

  if (status === 'APPROVED' || status === 'REJECTED')
    return moment(dateTimeProcessed).from(dateTimeCreated, true);
  if (status === 'EXPIRED') return '-';

  return moment(dateTimeCreated).fromNow();
};

const messagesLocal = defineMessages({
  'first-deposit.text': {
    id: 'first-deposit.text',
    defaultMessage: 'First Deposit',
  },
  'serial-code.text': {
    id: 'serial-code.text',
    defaultMessage: 'Serial Code',
  },
  'affiliate.text': {
    id: 'affiliate.text',
    defaultMessage: 'Affiliate',
  },
  'account-username.text': {
    id: 'account-username.text',
    defaultMessage: 'Account Username',
  },
  'actions.text': {
    id: 'actions.text',
    defaultMessage: 'Actions',
  },
});

const DepositsTable: React.FC<Props> = ({
  dataSource,
  loading,
  refetchVariables,
  isActiveTab,
}) => {
  const { role, permissions } = usePermissions();
  const { currency, addCurrency } = useConfig();

  const {
    ALLOWED_LIST,
    ALLOWED_VIEW_DETAILS,
    ALLOWED_APPROVE,
    ALLOWED_REJECT,
    ALLOWED_PROCESS,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'VIEW_DETAILS', 'APPROVE', 'REJECT', 'PROCESS'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_EXTERNAL_DEPOSITS
  );

  const [state, setState] = useState<{
    selectedRowKeys: Array<any>;
    isViewDepositDetails: boolean;
    depositId: string | null | undefined;
  }>({
    selectedRowKeys: [],
    isViewDepositDetails: false,
    depositId: null,
  });

  const toggleView = (id: string | null | undefined) => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('Deposit Requests (3rd party) - View Details');
      return;
    }
    setState({
      ...state,
      isViewDepositDetails: !state.isViewDepositDetails,
      depositId: id,
    });
  };

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = dataSource
    ? dataSource.map(({ node }: any) => ({ ...node, key: node.id }))
    : [];
  const translate = useTranslate();

  const columns = [
    {
      csvData: {
        label: translate(messagesLocal['first-deposit.text']),
        key: 'firstDeposit',
        renderCell: (deposit: Partial<Deposit>) =>
          coercedGet(deposit, 'firstDeposit', '')
            ? translate(messages.YES)
            : translate(messages.NO),
      },
      disabled: true,
      key: 'firstDeposit',
      title: '',
      customTitle: translate(messagesLocal['first-deposit.text']),
      width: 50,
      render: (deposit: Partial<Deposit>) =>
        deposit.firstDeposit && deposit.status !== 'REJECTED' ? (
          <Tooltip
            arrowPointAtCenter
            title={translate(messagesLocal['first-deposit.text'])}
          >
            <DollarTwoTone twoToneColor="#FF0000" />
          </Tooltip>
        ) : (
          ''
        ),
    },
    {
      csvData: {
        label: translate(messagesLocal['serial-code.text']),
        key: 'serialCode',
      },
      disabled: true,
      align: columnAlign.onCenter,
      key: 'serialCode',
      title: translate(messages.SERIAL_CODE),
      width: 100,
      render: (_text: string, deposit: Partial<Deposit>, index: number) => (
        <ALink
          data-testid={index === 0 ? 'toggle-details' : ''}
          onClick={() => toggleView(deposit.id)}
        >
          {deposit.serialCode}
        </ALink>
      ),
    },
    {
      csvData: {
        label: translate(messagesLocal['affiliate.text']),
        key: 'affiliate',
        renderCell: (deposit: Partial<Deposit>) =>
          coercedGet(deposit, 'account.affiliate.code', '-'),
      },
      disabled: true,
      title: translate(messagesLocal['affiliate.text']),
      dataIndex: 'account',
      key: 'affiliateIndicator',
      align: columnAlign.onCenter,
      width: 100,
      render: (depositDetails: Deposit) => (
        <AffiliateIndicatorBatch data={depositDetails} />
      ),
    },
    {
      csvData: {
        label: translate(messagesLocal['account-username.text']),
        key: 'username',
        renderCell: (deposit: Partial<Deposit>) =>
          coercedGet(deposit, 'account.username', '-'),
      },
      disabled: true,
      key: 'username',
      title: translate(messages.ACCOUNT_USERNAME),
      dataIndex: 'account',
      align: columnAlign.onCenter,
      width: 100,
      render: (account: Partial<Account>) =>
        account.id ? <MemberProfile memberId={account.id} /> : '-',
    },
    {
      key: 'affiliateId',
      title: translate(messages.AFFILIATE),
      width: 100,
      align: columnAlign.onCenter,
      dataIndex: 'account',
      render: (account: Member) => <>{account?.affiliate?.code || '-'}</>,
    },
    // commented this out to hide VIP column on the Table
    // {
    //   ...(Boolean(isVipVisible) && {
    //     csvData: {
    //       label: translate(messages.VIP_TIER),
    //       key: 'vipLevel',
    //       renderCell: (deposit: Partial<Deposit>) => {
    //         const memberLevels = coercedGet(
    //           deposit,
    //           'account.memberLoyaltyLevels',
    //           '-'
    //         );
    //         return memberLevels.map((item: any) => item.name);
    //       },
    //     },
    //     width: 100,
    //     align: columnAlign.onCenter,
    //     key: 'vipLevel',
    //     title: translate(messages.VIP_TIER),
    //     dataIndex: 'account',
    //     render: (deposit: Deposit) =>
    //       deposit.account?.memberLoyaltyLevels?.length ? (
    //         <MemberLoyaltyTagList
    //           loyaltyLevels={deposit.account.memberLoyaltyLevels}
    //         />
    //       ) : (
    //         '-'
    //       ),
    //   }),
    // },
    {
      csvData: {
        label: translate(messages.MEMBER_MARKER),
        key: 'account.memberLevel.name',
        renderCell: (deposit: Partial<Deposit>) =>
          coercedGet(deposit, 'account.memberLevel.name', '-'),
      },
      title: translate(messages.MEMBER_MARKER),
      key: 'memberMarker',
      dataIndex: 'account',
      align: columnAlign.onCenter,
      width: 100,
      render: (deposit: Deposit) => (
        <MemberLevelButton memberLevelData={deposit.account?.memberLevel} />
      ),
    },
    {
      csvData: {
        label: translate(messages['payment-method.text']),
        key: 'paymentMethod.name',
        renderCell: (deposit: Partial<Deposit>) =>
          coercedGet(deposit, 'paymentMethod.name', '-'),
      },
      title: translate(messages['payment-method.text']),
      key: 'paymentMethod',
      dataIndex: 'id',
      width: 200,
      align: columnAlign.onCenter,
      render: (id: string) => <DepositPaymentMethod depositId={id} />,
    },
    {
      csvData: {
        label: `${translate(messages.amount)}(${currency?.symbol})`,
        key: 'amount',
        renderCell: (deposit: Partial<Deposit>) =>
          coercedGet(deposit, 'amount', 0),
      },
      title: `${translate(messages.amount)}(${currency?.symbol})`,
      width: 100,
      align: columnAlign.onCenter,
      key: 'amount',
      dataIndex: 'amount',
      render: (amount: number) => addCurrency(amount, 2),
    },
    {
      csvData: {
        label: translate(messages.status),
        key: 'status',
        renderCell: (deposit: Partial<Deposit>) =>
          translate(messages[`${coercedGet(deposit, 'status', '')}`]),
      },
      title: translate(messages.status),
      key: 'status',
      dataIndex: 'status',
      width: 100,
      align: columnAlign.onCenter,
      render: (status: string) => (
        <Tooltip
          placement="top"
          title={translate(messages[`${status.toLowerCase()}.text`])}
        >
          <div>
            <Circle size={10} color={getStatusColor(status)} filled />
          </div>
        </Tooltip>
      ),
    },
    {
      csvData: {
        label: translate(messages.LABELS_REMARKS),
        key: 'remarks',
        renderCell: (deposit: Partial<Deposit>) => deposit.remarks || '',
      },
      title: translate(messages.LABELS_REMARKS),
      key: 'remarks',
      width: 150,
      align: columnAlign.onCenter,
      render: (deposit: Partial<Deposit>) => (
        <MemberLabelRemarks deposit={deposit} />
      ),
    },
    {
      csvData: {
        label: translate(messages['request-datetime.text']),
        key: 'dateTimeCreated',
        renderCell: (deposit: Partial<Deposit>) =>
          `${moment(deposit.dateTimeCreated).format(DATE_FORMAT)} - ${moment(
            deposit.dateTimeCreated
          ).format('HH:mm:ss')}`,
      },
      title: translate(messages['request-datetime.text']),
      key: 'dateTimeCreated',
      dataIndex: 'dateTimeCreated',
      width: 200,
      align: columnAlign.onCenter,
      render: (dateTimeCreated: string) => (
        <span>
          {moment(dateTimeCreated).format(DATE_FORMAT)}
          <br />
          {moment(dateTimeCreated).format('HH:mm:ss')}
        </span>
      ),
    },
    {
      csvData: {
        label: translate(messages['processing-time.text']),
        key: 'processingTime',
        renderCell: (deposit: Deposit) => getProcessingTime(deposit),
      },
      title: translate(messages['processing-time.text']),
      key: 'processingTime',
      align: columnAlign.onCenter,
      width: 100,
      render: (deposit: Deposit) => getProcessingTime(deposit),
    },
    {
      csvData: null,
      title: translate(messagesLocal['actions.text']),
      key: 'actions',
      width: 100,
      align: columnAlign.onCenter,
      hidden: true,
      render: (deposit: Partial<Deposit>) => {
        const isDisabled = deposit.status !== DepositStatus.Pending;
        return (
          ALLOWED_PROCESS && (
            <Dropdown
              overlay={
                <Menu>
                  {ALLOWED_PROCESS && ALLOWED_APPROVE && (
                    <Menu.Item disabled={isDisabled}>
                      <ApproveDeposit
                        id={deposit.id as string}
                        status={deposit.status as string}
                        refetchVariables={refetchVariables}
                        disabled={isDisabled}
                      />
                    </Menu.Item>
                  )}
                  {ALLOWED_PROCESS && ALLOWED_REJECT && (
                    <Menu.Item disabled={isDisabled}>
                      <RejectDeposit
                        id={deposit.id as string}
                        remarks={deposit.remarks as string}
                        refetchVariables={refetchVariables}
                        disabled={isDisabled}
                      />
                    </Menu.Item>
                  )}
                  {ALLOWED_PROCESS && (
                    <Menu.Item disabled={isDisabled}>
                      <StopProcessing
                        id={deposit.id as string}
                        refetchVariables={refetchVariables}
                        disabled={isDisabled}
                      />
                    </Menu.Item>
                  )}
                </Menu>
              }
              placement="bottomRight"
            >
              <div data-testid={DEPOSIT_REQUEST_EXTERNAL.elipsisActions}>
                <EllipsisOutlined
                  className="cursor-pointer font-weight-bold"
                  style={{ fontSize: '25px' }}
                />
              </div>
            </Dropdown>
          )
        );
      },
    },
  ];

  const { filterColumns } = useCustomColumnsV2(
    'deposit-requests-external',
    (columns as unknown) as OriginalColumn[]
  );

  return (
    <>
      {state.isViewDepositDetails &&
        (isActiveTab === undefined || isActiveTab) && (
          <DepositDetails
            id={state?.depositId as string}
            toggleModal={toggleView}
            refetchVariables={refetchVariables}
          />
        )}
      {ALLOWED_LIST && (
        <StyledTable
          loading={loading}
          rowSelection={rowSelection}
          columns={filterColumns(columns as OriginalColumn[])}
          size="small"
          dataSource={data}
          pagination={false}
          scroll={{ y: 640, x: 'calc(525px + 50%)' }}
          rowKey="serialCode"
          data-testid={DEPOSIT_REQUEST_EXTERNAL.table}
        />
      )}
    </>
  );
};

export default DepositsTable;
