import DataLoaderProvider from 'contexts/DataLoader';
import isRow from 'utils/isRow';
import { PAYMENT_TYPES_DEPOSIT } from 'constants/paymentTypes';
import FiltersProvider from 'contexts/Filters';
import {
  BATCH_DEPOSIT_MEMBERLEVEL_VIP,
  BATCH_DEPOSIT_MEMBER_AFFILIATE,
  BATCH_DEPOSIT_MEMBER_NOTES,
  BATCH_DEPOSIT_MEMBER_TAGS,
  BATCH_DEPOSIT_MEMBER_USERNAME,
  BATCH_DEPOSIT_MEMBER_VIP,
  BATCH_DEPOSIT_PAYMENT_METHOD_INTERNAL,
  DEPOSIT_AMOUNT,
} from 'graphql/queries/globalBatch.query';
import { useCreateLoader } from 'hooks/useLoader';
import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import uuid from 'uuid';
import { DEPOSIT_REQUESTS } from 'graphql/queries/deposit.query';
import DepositsScreen from './components/DepositsScreen';

const InternalMemberDeposits = () => {
  const [key, setKey] = useState('');
  const { getTab } = useScreenTabV2();

  const { memberIdRef, serialCodeRef, filter = {} } =
    getTab('deposit-requests-internal') || {};

  const memberTabRef = memberIdRef ? { in: [memberIdRef] } : null;
  const serialCodeTabRef = serialCodeRef ? { in: [serialCodeRef] } : null;

  const defaultFilters = {
    paymentMethod: null,
    ...(isRow && {
      paymentMethod: null,
    }),
    ...(!isRow && {
      paymentMethodType: { in: PAYMENT_TYPES_DEPOSIT.OFFLINE },
    }),
    serialCode: null,
    account: null,
    amount: null,
    status: null,
    dateTimeCreated: null,
    processingTime: null,
    memberLoyaltyLevels: null,
    memberLevel: null,
    processor: null,
  };

  const refreshPage = () => setKey(uuid.v1());

  const initialFilters = {
    ...defaultFilters,
    account: memberTabRef,
    serialCode: serialCodeTabRef,
    ...filter,
  };

  const affiliateLoaderSet = useCreateLoader(BATCH_DEPOSIT_MEMBER_AFFILIATE);
  const usernameLoaderSet = useCreateLoader(BATCH_DEPOSIT_MEMBER_USERNAME);
  const vipTierLoaderSet = useCreateLoader(BATCH_DEPOSIT_MEMBER_VIP);
  const markerLoaderSet = useCreateLoader(BATCH_DEPOSIT_MEMBERLEVEL_VIP);
  const depositMethodLoaderSet = useCreateLoader(
    BATCH_DEPOSIT_PAYMENT_METHOD_INTERNAL
  );
  const depositAmountLoaderSet = useCreateLoader(DEPOSIT_AMOUNT);
  const memberTagsLoaderSet = useCreateLoader(BATCH_DEPOSIT_MEMBER_TAGS);
  const memberNotesLoaderSet = useCreateLoader(BATCH_DEPOSIT_MEMBER_NOTES);

  return (
    <FiltersProvider
      initialValues={initialFilters}
      defaultValues={defaultFilters}
      tabId="deposit-requests-internal"
    >
      <DataLoaderProvider
        loaderKeyVals={{
          affiliateLoaderSet,
          usernameLoaderSet,
          vipTierLoaderSet,
          markerLoaderSet,
          depositMethodLoaderSet,
          depositAmountLoaderSet,
          memberTagsLoaderSet,
          memberNotesLoaderSet,
        }}
      >
        <SpreadsheetProvider
          options={{
            query: DEPOSIT_REQUESTS,
            edgesPath: 'deposits.edges',
          }}
        >
          <DepositsScreen key={key} refreshPage={refreshPage} />
        </SpreadsheetProvider>
      </DataLoaderProvider>
    </FiltersProvider>
  );
};

export default InternalMemberDeposits;
