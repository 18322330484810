import { Button, Descriptions, Empty, message } from 'antd';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { ErrorHandler } from 'components/ErrorHandler';
import { cryptoCurrencies } from 'constants/currency';
import { DATE_TIME_FORMAT } from 'constants/date';
import { useMutation } from 'graphql/client/promoClient';
import {
  CANCEL_MEMBER_PROMO,
  COMPLETE_MEMBER_PROMO,
} from 'graphql/mutations/member.mutation';
import { useMemberPromo } from 'hooks/useMemberPromo';
import { includes } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { MemberPromoStatus } from 'types/promo-graphqlTypes-row';
import { renderNumeral } from 'utils/renderNumeral';

type Props = {
  showActionButtons?: boolean;
};

const ActivePromo: React.FC<Props> = ({ showActionButtons = false }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);

  const {
    memberPromo,
    refetch,
    hasActivePromo,
    refetchWalletBalances,
  } = useMemberPromo();

  const {
    promo,
    status,
    amount,
    bonus,
    expiresAt,
    turnoverCounter,
    turnoverTarget,
    currency,
    id: activePromoId,
  } = memberPromo || {};

  const isCrypto = includes(cryptoCurrencies, currency);

  const [completePromo, { loading }] = useMutation(COMPLETE_MEMBER_PROMO);
  const [cancelPromo, { loading: cancelMutationLoading }] = useMutation(
    CANCEL_MEMBER_PROMO
  );

  const handleToggleModal = () => setShowConfirm((prev) => !prev);
  const handleCancelToggleModal = () => setShowCancelConfirm((prev) => !prev);

  const handleBonusComplete = async () => {
    await completePromo({
      variables: {
        id: activePromoId,
      },
    })
      .then(() => {
        message.success('Member Bonus successfully completed!');
        refetch();
        refetchWalletBalances();
      })
      .catch((error) => {
        if (error) message.error(`${error.message}`);
      })
      .finally(() => handleToggleModal());
  };
  const handleBonusCancel = async () => {
    await cancelPromo({
      variables: {
        id: activePromoId,
      },
    })
      .then(() => {
        message.success('Member Bonus successfully cancelled!');
        refetch();
        refetchWalletBalances();
      })
      .catch((error) => {
        if (error) message.error(`${error.message}`);
      })
      .finally(() => handleCancelToggleModal());
  };

  const promoItems = [
    {
      key: 'title',
      label: 'Promo Title',
      children: promo?.name,
    },

    {
      key: 'amount',
      label: 'Member Deposited',
      children: amount ? renderNumeral(amount, isCrypto) : 0,
    },
    {
      key: 'bonus',
      label: 'Bonus Received',
      children: bonus ? renderNumeral(bonus, isCrypto) : 0,
    },
    {
      key: 'expiresAt',
      label: 'Expiry Date',
      children: moment(expiresAt).format(DATE_TIME_FORMAT),
    },
    {
      key: 'turnoverTarget',
      label: 'Turnover Requirement',
      children: turnoverTarget,
    },
    {
      key: 'turnoverCounter',
      label: 'Turnover Counter',
      children: turnoverCounter,
    },
    {
      key: 'status',
      label: 'Status',
      children: (
        <span
          style={{
            color: 'green',
          }}
        >
          {status}
        </span>
      ),
    },
  ];

  return (
    <>
      <ConfirmationModal
        title="Are you sure you want to complete the bonus?"
        message={"Proceed to manually complete the bonus by clicking 'OK'."}
        visible={showConfirm}
        onCancel={handleToggleModal}
        onOk={() => handleBonusComplete()}
        okButtonProps={loading}
      />
      <ConfirmationModal
        title="Are you sure you want to cancel the bonus?"
        message={"Proceed to manually cancel the bonus by clicking 'OK'."}
        visible={showCancelConfirm}
        onCancel={handleCancelToggleModal}
        onOk={() => handleBonusCancel()}
        okButtonProps={cancelMutationLoading}
      />

      <div className="flex-1">
        {hasActivePromo ? (
          <div className="d-flex member-profile-tab-container m-4 justify-content-start align-items-start ">
            <div>
              <Descriptions bordered column={1}>
                {promoItems.map((item) => (
                  <Descriptions.Item label={item.label} key={item.key}>
                    {item.children}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </div>
            {showActionButtons && status === MemberPromoStatus.Active && (
              <div className="align-self-start ml-5 mt-1">
                <Button
                  type="primary"
                  onClick={handleToggleModal}
                  className="mb-2"
                >
                  Complete
                </Button>
                <Button
                  type="primary"
                  onClick={handleCancelToggleModal}
                  style={{
                    padding: '0 24px 0 24px',
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        ) : (
          <ErrorHandler>
            <div className="d-flex align-items-center justify-content-center member-profile-tab-container">
              <Empty />
            </div>
          </ErrorHandler>
        )}
      </div>
    </>
  );
};

export default ActivePromo;
