import { AvailableCurrency } from 'types/graphqlTypes-row';
import { useState, Key } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

export const GET_AVAILABLE_CURRENCIES = gql`
  query AvailableCurrencies {
    availableCurrencies {
      code
      name
      symbol
      type
    }
  }
`;

export const useAvailableCurrencies = () => {
  const [availableCurrenciesState, setAvailableCurrenciesState] = useState<
    Array<AvailableCurrency>
  >([]);
  const [availableCurrencyCodes, setAvailableCurrencyCodes] = useState<
    Array<Key>
  >([]);

  const [loadAvailableCurrencies, { loading }] = useLazyQuery<{
    availableCurrencies: Array<AvailableCurrency>;
  }>(GET_AVAILABLE_CURRENCIES, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ availableCurrencies }) => {
      const availCurrencyCode = availableCurrencies.map(
        (currency) => currency.code
      );

      setAvailableCurrenciesState(availableCurrencies);
      setAvailableCurrencyCodes(availCurrencyCode);
    },
  });

  return {
    loadAvailableCurrencies,
    loading,
    availableCurrencies: availableCurrenciesState,
    availableCurrencyCodes,
    setAvailableCurrencyCodes,
  };
};
