import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Input, Button, Select, Radio } from 'antd';
import { FormikContextType, useFormikContext } from 'formik';
import { useLazyQuery } from '@apollo/react-hooks';
import messages from 'pages/components/ThirdPartyDepositProvider/messages';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import globalMessages from 'messages';
import { FeatureFlags } from 'constants/featureFlags';
import useIsNext from 'hooks/useIsNext';
import styled from 'styled-components';
import thirdPartyProviders from 'constants/thirdPartyProviders';
import { PAYMENT_GATEWAYS } from './queries';

const { DIORPAY, MIDPAY, MIDPAY_DIRECT, DIORPAY2 } = thirdPartyProviders;

const StyledButton = styled(Button)`
  text-transform: uppercase;
`;
const { Option } = Select;
const formitemlayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

const FormInputs = (props: {
  id: string;
  onClose: () => void;
  editable: boolean;
  loading: boolean;
  initCredentialFields: Array<string>;
}) => {
  const isNext = useIsNext(FeatureFlags.diorPay2);
  const { editable, onClose, id, loading, initCredentialFields } = props;
  const {
    setValues,
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    isValid,
  }: FormikContextType<Record<string, any>> = useFormikContext();
  const translate = useTranslate();
  const [credentialFields, setCredentialFields] = useState(
    initCredentialFields
  );

  const [
    getGateways,
    { loading: loadingGateways, data: dataGateways },
  ] = useLazyQuery(PAYMENT_GATEWAYS, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      const errorCode = coercedGet(error, 'graphQLErrors[0].original.code', '');
      if (errorCode === 'INVALID_PAYMENT_GATEWAY_CREDENTIALS') {
        message.error(translate(messages.INVALID_API_CREDENTIALS));
      } else {
        message.error(translate(globalMessages.INTERNAL_SERVER_ERROR));
      }
    },
  });

  const gatewayOptions = coercedGet(dataGateways, 'paymentGateways', []);
  const onGetGateways = () => {
    const paymentGatewayInput = {
      input: {
        apiKey: values?.midpayDirectApiKey,
        integrationType: values?.type,
        methodType: 'DEPOSIT',
      },
    };

    getGateways({
      variables: paymentGatewayInput,
    });
  };

  const onSelectGateway = (value: string) => {
    const gateWayInfo = gatewayOptions.find(
      (gatewayOption: { id: string }) => gatewayOption.id === value
    );
    const credValues = new Array(gateWayInfo.credentialFields.length).fill('');

    setValues({
      ...values,
      paymentGateway: value,
      credentials: credValues,
    });
    setCredentialFields(gateWayInfo.credentialFields);
  };

  const onSetType = ({ target }: { target: { value: string } }) => {
    setValues({
      name: values?.name,
      type: target.value,
    });
  };

  useEffect(() => {
    if (editable && id && initCredentialFields.length) {
      onGetGateways();
    } else if (!initCredentialFields.length) {
      // Resets the dynamic inputs after creating/editing a provider
      setCredentialFields([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable, id, initCredentialFields]);

  // console.log(credentialFields);
  return (
    <Form onSubmit={handleSubmit} layout="horizontal" labelAlign="right">
      <div className="p-3">
        <Form.Item {...formitemlayout} className={editable ? '' : 'mb-1'}>
          <div className="ant-legacy-form-item-label">
            <strong className="mr-2">
              {translate(messages['deposit-provider-details.text'])}
            </strong>
          </div>
        </Form.Item>
        <Form.Item
          {...formitemlayout}
          htmlFor="name"
          label={translate(messages['provider-name.text'])}
          validateStatus={errors.name && 'error'}
          help={errors.name || null}
          className={editable ? 'mt-1' : 'mb-0'}
        >
          <Input
            data-testid="name"
            name="name"
            id="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.name}
            placeholder={translate(messages['provider-name.text'])}
            disabled={!values?.type}
          />
        </Form.Item>

        <Form.Item
          {...formitemlayout}
          label={translate(messages['type.text'])}
          validateStatus={errors.type && 'error'}
          help={errors.type || null}
          className={editable ? 'mt-1' : 'mb-0'}
        >
          <Radio.Group
            disabled={!!id}
            name="type"
            value={values?.type}
            onChange={onSetType as any}
          >
            <Radio data-testid="midpayDirectOption" value={MIDPAY_DIRECT}>
              {translate(globalMessages.MIDPAY_DIRECT)}
            </Radio>
            {isNext ? (
              <Radio data-testid="diorpay2Option" value={DIORPAY2}>
                {translate(globalMessages.DIORPAY2)}
              </Radio>
            ) : null}
          </Radio.Group>
        </Form.Item>

        {values?.type === DIORPAY || values?.type === MIDPAY ? (
          <Form.Item
            label={`${
              editable ? translate(messages['primary-credential.text']) : ''
            }
              ${editable ? '(' : ''}${translate(
              messages[values?.type === MIDPAY ? 'api-key.text' : 'key.text']
            )}${editable ? ')' : ''}`}
            {...formitemlayout}
            validateStatus={errors.key && 'error'}
            help={errors.key || null}
            className={editable ? 'mt-1' : 'mb-0'}
          >
            {editable ? (
              <Input
                data-testid="primaryCredential"
                name="key"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values?.key}
                placeholder={translate(
                  messages[
                    values?.type === MIDPAY ? 'api-key.text' : 'key.text'
                  ]
                )}
              />
            ) : (
              <span data-testid="u-primarycred">{values?.key}</span>
            )}
          </Form.Item>
        ) : null}

        {values?.type === DIORPAY ? (
          <Form.Item
            {...formitemlayout}
            htmlFor="merchantCode"
            label={translate(messages['merchant-code.text'])}
            validateStatus={errors.merchantCode && 'error'}
            help={errors.merchantCode}
            className={editable ? 'mt-1' : 'mb-0'}
          >
            {editable ? (
              <Input
                data-testid="merchantCode"
                id="merchantCode"
                name="merchantCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.merchantCode}
                placeholder={translate(messages['merchant-code.text'])}
              />
            ) : (
              <span data-testid="u-merchantcode">{values.merchantCode}</span>
            )}
          </Form.Item>
        ) : null}

        {values?.type === MIDPAY_DIRECT ? (
          <>
            <Form.Item
              {...formitemlayout}
              htmlFor="midpayDirectApiKey"
              label={translate(messages.MIDPAY_DIRECT_CREDS)}
              validateStatus={errors.midpayDirectApiKey && 'error'}
              help={errors.midpayDirectApiKey || null}
              className={editable ? 'mt-1' : 'mb-0'}
            >
              <div className="d-flex">
                <Input
                  className="col-9 mr-1"
                  name="midpayDirectApiKey"
                  id="midpayDirectApiKey"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.midpayDirectApiKey}
                  placeholder={translate(messages['api-key.text'])}
                  disabled={loadingGateways}
                />
                <Button
                  className="col-3"
                  type="primary"
                  loading={loadingGateways}
                  onClick={onGetGateways}
                  disabled={!values.midpayDirectApiKey}
                >
                  {translate(globalMessages.CONFIRM)}
                </Button>
              </div>
            </Form.Item>
            {values?.midpayDirectApiKey && gatewayOptions.length ? (
              <>
                <Form.Item
                  {...formitemlayout}
                  htmlFor="paymentGateway"
                  label={translate(messages.THIRD_PARTY_GATEWAY)}
                  validateStatus={errors.paymentGateway && 'error'}
                  help={errors.paymentGateway || null}
                  className={editable ? 'mt-1' : 'mb-0'}
                >
                  {editable ? (
                    <Select
                      data-testid="payment-gateway-select"
                      id="paymentGateway"
                      aria-label="paymentGateway"
                      onChange={onSelectGateway}
                      value={values?.paymentGateway}
                    >
                      {gatewayOptions.map((gatewayOption: { id: string }) => (
                        <Option key={gatewayOption.id} value={gatewayOption.id}>
                          {translate(globalMessages[gatewayOption.id])}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <span data-testid="u-paymentgateway">
                      {values?.paymentGateway}
                    </span>
                  )}
                </Form.Item>

                {credentialFields.length
                  ? credentialFields.map((field, index) => (
                      <Form.Item
                        {...formitemlayout}
                        key={`${values.paymentGateway}-${index}`}
                        label={translate(messages[field])}
                        validateStatus={
                          coercedGet(errors, `credentials.[${index}]`, '') &&
                          'error'
                        }
                        help={coercedGet(
                          errors,
                          `credentials.[${index}]`,
                          null
                        )}
                        className={editable ? 'mt-1' : 'mb-0'}
                      >
                        {editable ? (
                          <Input
                            data-testid={`credentials.${index}`}
                            name={`credentials.${index}`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.credentials[index]}
                          />
                        ) : (
                          values.credentials[index]
                        )}
                      </Form.Item>
                    ))
                  : null}
              </>
            ) : null}
          </>
        ) : null}

        {values?.type === DIORPAY2 ? (
          <>
            <Form.Item
              {...formitemlayout}
              htmlFor="encryptionKey"
              label={translate(messages.ENCRYPTION_KEY)}
              validateStatus={errors.encryptionKey && 'error'}
              help={errors.encryptionKey}
              className={editable ? 'mt-1' : 'mb-0'}
            >
              <Input
                id="encryptionKey"
                name="encryptionKey"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.encryptionKey}
                placeholder={translate(messages.ENCRYPTION_KEY)}
              />
            </Form.Item>
            <Form.Item
              {...formitemlayout}
              htmlFor="merchantId"
              label={translate(messages.DIORPAY_MERCHANT_ID)}
              validateStatus={errors.merchantId && 'error'}
              help={errors.merchantId}
              className={editable ? 'mt-1' : 'mb-0'}
            >
              <Input
                id="merchantId"
                name="merchantId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.merchantId}
                placeholder={translate(messages.MERCHANT_ID)}
              />
            </Form.Item>
            <Form.Item
              {...formitemlayout}
              htmlFor="partner"
              label={translate(messages.VENDOR_MERCHANT_ID)}
              validateStatus={errors.partner && 'error'}
              help={errors.partner}
              className={editable ? 'mt-1' : 'mb-0'}
            >
              <Input
                id="partner"
                name="partner"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.partner}
                placeholder={translate(messages.VENDOR_MERCHANT_ID)}
              />
            </Form.Item>
          </>
        ) : null}
      </div>

      <div className="bt-1 p-4 text-right">
        {editable ? (
          <>
            <Button className="mr-2" onClick={onClose} disabled={loading}>
              {translate(messages['cancel.text'])}
            </Button>
            <Button
              data-testid="submit"
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={!isValid}
            >
              {translate(messages[!id ? 'add.text' : 'save.text'])}
            </Button>
          </>
        ) : (
          <>
            <StyledButton type="primary" onClick={onClose}>
              {translate(messages['ok.text'])}
            </StyledButton>
          </>
        )}
      </div>
    </Form>
  );
};

export default FormInputs;
