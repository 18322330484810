/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import Icon from '@ant-design/icons';
import { Modal, Button, Progress, Spin, Empty } from 'antd';
import { CSVLink } from 'react-csv';
import { FormattedMessage } from 'react-intl';
import { useDownloadCsv } from 'contexts/DownloadCsv';
import { DocumentNode } from 'graphql';
import { TabId } from 'types/nav';
import useCsvHeaders from 'hooks/useCsvHeaders';
import { useFilterValues } from 'contexts/Filters';
import { transformMovableDateFilter } from 'utils/dateUtils';
import PermissionAlert from 'components/PermissionAlert';

const StyledButton = styled(Button)`
  width: 150px;
`;

type Props = {
  handleShow: () => void;
  fileName: string;
  tabId: TabId;
  edgesPath: string;
  query: DocumentNode;
  permission: boolean;
  useProvidedFilter?: () => Record<string, any>;
};

const CsvModal: React.FC<Props> = ({
  handleShow,
  fileName,
  tabId,
  edgesPath,
  query,
  permission = true,
  useProvidedFilter = useFilterValues,
}) => {
  const { csvHeaders } = useCsvHeaders(tabId);
  const { filters } = useProvidedFilter();

  const {
    initialCsvState,
    getCsvState,
    newDownload,
    retryDownload,
  } = useDownloadCsv();

  const options = {
    tabId,
    edgesPath,
    query,
    filter: transformMovableDateFilter(filters),
  };

  const [mounted, setMounted] = useState(true);
  const [csvState, setCsvState] = useState(null);
  const { totalCount, records, fetchState } = csvState || initialCsvState;
  const progress = (records.length / totalCount) * 100;
  const generateComplete = progress === 100;

  const handleSetCsvState = useCallback(async () => {
    const newCsvState = await getCsvState(tabId);
    setCsvState(newCsvState);

    if (mounted) {
      setMounted(false);
    }
  }, [getCsvState, mounted, tabId]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleSetCsvState();
    }, 1000);

    return () => clearInterval(interval);
  }, [handleSetCsvState]);

  const handleModalClose = () => {
    handleShow();
  };

  return (
    <Modal
      visible
      maskClosable
      width={416}
      onCancel={handleModalClose}
      footer={null}
    >
      <>
        {permission ? (
          <Spin spinning={mounted}>
            <div className="styled-center flex-direction-column">
              {csvState && totalCount > 0 ? (
                <>
                  <Progress
                    className="my-3"
                    type="circle"
                    percent={Number(progress.toFixed(2))}
                    width={80}
                    status={fetchState.error ? 'exception' : undefined}
                  />
                  <span className="mb-3">{`${records.length} / ${totalCount}`}</span>
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              {generateComplete && (
                <StyledButton>
                  <CSVLink
                    data={records}
                    headers={csvHeaders}
                    filename={`${fileName}.csv`}
                    target="_blank"
                  >
                    <FormattedMessage
                      id="download-csv.text"
                      defaultMessage="Download CSV"
                    />
                    <Icon className="ml-2" type="download" />
                  </CSVLink>
                </StyledButton>
              )}
              {fetchState.error && (
                <>
                  <div className="text-danger">
                    <FormattedMessage
                      id="error-occurred.text"
                      defaultMessage="An error has occurred. Please try again"
                    />
                  </div>
                  <StyledButton
                    className="mt-3"
                    onClick={() => retryDownload(options)}
                  >
                    <FormattedMessage id="retry.text" defaultMessage="Retry" />
                  </StyledButton>
                </>
              )}
              {records.length === totalCount && (
                <StyledButton
                  type="primary"
                  key="new"
                  className="mt-3"
                  onClick={() => newDownload(options)}
                >
                  <FormattedMessage
                    id="generate-new.text"
                    defaultMessage="Generate New"
                  />
                </StyledButton>
              )}
            </div>
          </Spin>
        ) : (
          <PermissionAlert />
        )}
      </>
    </Modal>
  );
};

export default CsvModal;
