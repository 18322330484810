import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'common.off.text': {
    id: 'common.off.text',
    defaultMessage: 'Off',
  },
  'common.on.text': {
    id: 'common.on.tex',
    defaultMessage: 'On',
  },
  'rebates.live-rebate.text': {
    id: 'rebates.live-rebate.text',
    defaultMessage: 'Live rebate',
  },
  'rebates.settlement-method.text': {
    id: 'rebates.settlement-method.text',
    defaultMessage: 'Settlement method',
  },
  'rebates.payout-time.text': {
    id: 'rebates.payout-time.text',
    defaultMessage: 'Payout time',
  },
  'rebates.payout-method.text': {
    id: 'rebates.payout-method.text',
    defaultMessage: 'Payout method',
  },
  'rebates.turnover.text': {
    id: 'rebates.turnover.text',
    defaultMessage: 'Turnover',
  },
  'common.hour.text': {
    id: 'common.hour.text',
    defaultMessage: 'hour',
  },
  'common.day.text': {
    id: 'common.day.text',
    defaultMessage: 'day',
  },
  'common.month.text': {
    id: 'common.month.text',
    defaultMessage: 'month',
  },
  'common.year.text': {
    id: 'common.year.text',
    defaultMessage: 'year',
  },
  'rebates.settlement-method-daily-feedback.text': {
    id: 'rebates.settlement-method-daily-feedback.text',
    defaultMessage:
      'The system starts automatic settlement 1 hour before the distribution time, and generates the final rebate list',
  },
  'rebates.payout-time-feedback.text': {
    id: 'rebates.payout-time-feedback.text',
    defaultMessage:
      'Example of daily return calculation: Daily from 00:00 to 24:00',
  },
  'rebates.weekly-rebate.text': {
    id: 'rebates.weekly-rebate.text',
    defaultMessage: 'Weekly Rebate',
  },
  'rebates.payout-day.text': {
    id: 'rebates.payout-day.text',
    defaultMessage: 'Payout day',
  },
  'rebates.payout-date.text': {
    id: 'rebates.payout-date.text',
    defaultMessage: 'Payout date',
  },
  'rebates.payout-date-and-month.text': {
    id: 'rebates.payout-date-and-month.text',
    defaultMessage: 'Payout date and month',
  },
  'rebates.monthly-rebate.text': {
    id: 'rebates.monthly-rebate.text',
    defaultMessage: 'Monthly Rebate',
  },
  'rebates.claim-offset-time.text': {
    id: 'rebates.claim-offset-time.text',
    defaultMessage: 'Claim Offset Time',
  },
  'rebates.no-claim-offset-time.text': {
    id: 'rebates.no-claim-offset-time.text',
    defaultMessage: 'No Claim Offset Time',
  },
  'rebates.claim-offset-time-desc.text': {
    id: 'rebates.claim-offset-time-desc.text',
    defaultMessage:
      'offset at a certain time from the time so that the rebate can be offer a few days later',
  },
  'rebates.claim-expiry-time.text': {
    id: 'rebates.claim-expiry-time.text',
    defaultMessage: 'Claim Expiry Time',
  },
  'rebates.no-claim-expiry-time.text': {
    id: 'rebates.no-claim-expiry-time.text',
    defaultMessage: 'No Claim Expiry Time',
  },
  'rebates.award-expiry-time.text': {
    id: 'rebates.award-expiry-time.text',
    defaultMessage: 'Award Expiry Time',
  },
  'rebates.no-award-expiry-time.text': {
    id: 'rebates.no-award-expiry-time.text',
    defaultMessage: 'No Award Expiry Time',
  },
  'rebates.minimum-and-maximum.text': {
    id: 'rebates.minimum-and-maximum.text',
    defaultMessage: 'Minimum and Maximum (Optional)',
  },
  'rebates.payout-error.text': {
    id: 'number.min.error',
    defaultMessage: 'must be greater than or equal to {min}',
  },
});

export default messages;
