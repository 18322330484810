import React from 'react';
import { isEmpty } from 'lodash';
import { Tooltip as AntdTooltip } from 'antd';
import MemberTagsRenderer from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/RowRenderer/components/MemberTags';
import { Member, MemberTag } from 'types/graphqlTypes';
import { Maybe } from 'graphql-tools';
import { StyledColor } from './styles';

type Rec = Record<string, any>;

const Tooltip: any = AntdTooltip;
const MemberTags = ({
  member,
  refetchParams,
}: {
  member: Member;
  refetchParams: Rec;
}) => {
  const { tags } = member;
  const previewTags = tags
    .filter(
      (item: Maybe<MemberTag>, idx: number) => !isEmpty(item) && idx + 1 <= 3
    )
    .reduce((prev: any[], curr: any) => [...prev, curr.color], []);
  const remainingTags = tags.length - 3;

  return (
    <span
      style={{
        float: 'left',
        marginTop: previewTags.length <= 2 ? '8px' : '0',
      }}
      className="mr-2"
    >
      <Tooltip
        placement="bottom"
        title={
          <MemberTagsRenderer
            disableClosable
            disableAddLabel
            member={member}
            filter={refetchParams}
          />
        }
        getPopupContainer={() =>
          document.getElementById('member-tags-member-management')
        }
      >
        <div id="member-tags-member-management">
          <div className="d-flex">
            <StyledColor className="styled-color-tag" color={previewTags[0]} />
            <StyledColor className="styled-color-tag" color={previewTags[1]} />
          </div>

          <div className="d-flex" style={{ marginTop: '3px' }}>
            <StyledColor className="styled-color-tag" color={previewTags[2]} />
            {tags.length - 3 > 0 ? (
              <small style={{ fontSize: '10px', lineHeight: '11px' }}>
                +{remainingTags}
              </small>
            ) : null}
          </div>
        </div>
      </Tooltip>
    </span>
  );
};

export default MemberTags;
