import axios from 'axios';
import btoa from 'btoa';
import Storage from 'constants/storage';

const { config } = window;

type Headers = {
  Authorization: string;
  'admin-code'?: string;
  source: 'BO';
};

export default (username: string, password: string, adminCode = '') => {
  const token = btoa(`${username}:${password}`);
  let headers: Headers = { Authorization: `Basic ${token}`, source: 'BO' };
  if (adminCode) {
    headers = { ...headers, 'admin-code': adminCode, source: 'BO' };
  }
  return axios.get(`${config.authUrl}/test/authenticate?ttl=30d`, {
    headers,
  });
};

const getOtpData = (username: string, password: string, adminCode = '') => {
  const token = btoa(`${username}:${password}`);
  let headers: Headers = { Authorization: `Basic ${token}`, source: 'BO' };
  if (adminCode) {
    headers = { ...headers, 'admin-code': adminCode, source: 'BO' };
  }

  return axios.get(`${config.authUrl}/authenticate?ttl=30d`, {
    headers,
  });
};

const validateOtpNext = (otp: string, authorization: string) =>
  axios.post(`${config.authUrl}/otp/validate`, null, {
    headers: {
      otp,
      authorization: `Bearer ${authorization}`,
    },
  });

const otpAuthenticate = (authCode: string, secretToken: string) => {
  const auth = Buffer.from(`${authCode}:${secretToken}`, 'utf8').toString(
    'base64'
  );

  const headers = {
    Authorization: `OTP ${auth}`,
    source: 'BO',
  };

  return axios.get(`${config.authUrl}/authenticate?ttl=30d`, {
    headers,
  });
};

const deactivateOtp = (authCode: string) => {
  const accessToken = localStorage.getItem(Storage.ACCESS_TOKEN);
  const auth = Buffer.from(`${authCode}:${accessToken}`, 'utf8').toString(
    'base64'
  );
  const headers = { Authorization: `OTP ${auth}`, source: 'BO' };

  return axios.delete(`${config.authUrl}/2fa`, {
    headers,
  });
};

const activateOtp = (authCode: string) => {
  const accessToken = localStorage.getItem(Storage.ACCESS_TOKEN);
  const auth = Buffer.from(`${authCode}:${accessToken}`, 'utf8').toString(
    'base64'
  );

  const headers = { Authorization: `OTP ${auth}`, source: 'BO' };

  return fetch(`${config.authUrl}/authenticate`, {
    method: 'POST',
    headers,
  }).then((response) => response.json());
};

export {
  getOtpData,
  otpAuthenticate,
  deactivateOtp,
  activateOtp,
  validateOtpNext,
};
