import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox } from 'antd';
import { Formik } from 'formik';
import MoreActionsMembersFilter from 'components/MoreActionsMembersFilter';
import { FormattedMessage } from 'react-intl';
import { BulkProps } from 'interfaces/bulkProps.interface';
import { StyledForm } from './styles';
import validationSchema from './validationSchema';

const BulkForceChangePasswordMenuForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: BulkProps) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({ handleSubmit, setFieldValue, values, errors, touched }) => (
      <StyledForm onSubmit={handleSubmit} layout="horizontal">
        <div className="p-3">
          <div className="mb-3">
            <MoreActionsMembersFilter
              value={values.username}
              placeholder={
                <FormattedMessage
                  id="enter-username.text"
                  defaultMessage="Enter Username"
                />
              }
              onChange={(e) => setFieldValue('username', e)}
              validateStatus={
                errors.username && touched.username ? 'error' : undefined
              }
              help={touched.username ? errors.username : null}
            />
          </div>
          <Form.Item
            wrapperCol={{ span: 24 }}
            validateStatus={
              errors.forceChangePassword && touched.forceChangePassword
                ? 'error'
                : undefined
            }
            help={
              touched.forceChangePassword ? errors.forceChangePassword : null
            }
          >
            <Checkbox
              checked={values.forceChangePassword}
              onChange={() =>
                setFieldValue(
                  'forceChangePassword',
                  !values.forceChangePassword
                )
              }
            >
              <FormattedMessage
                id="confirm-sending.text"
                defaultMessage="Confirm sending"
              />
            </Checkbox>
          </Form.Item>
        </div>
        <div className="bt-1 p-3 text-right">
          <Button className="mr-2" onClick={onClose} loading={isLoading}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={!values.forceChangePassword}
          >
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </div>
      </StyledForm>
    )}
  </Formik>
);

export default BulkForceChangePasswordMenuForm;
