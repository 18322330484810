/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import CountryTag from 'pages/components/GeoFencing/components/CountryTag';
import { Place } from 'types/graphqlTypes';

export const CustomGeofencingTag = ({ header, tags }: Props) => {
  const translate = useTranslate();

  return (
    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
      <h4>{translate(messages[`${header}`])}</h4>
      {(tags ?? []).map((tag, idx) => (
        <CountryTag
          key={idx}
          country={
            // eslint-disable-next-line no-nested-ternary
            typeof tag === 'string' ? tag : tag.displayName
          }
          skipTranslate={header !== 'country-and-city.text'}
        />
      ))}
      {(tags?.length === 0 || !tags) && <p>{translate(messages.NO_DATA)}</p>}
    </div>
  );
};

interface Props {
  header: string;
  tags: Array<Place>;
}
