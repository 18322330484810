import React from 'react';
import { LayoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
// import { FilterTag } from './FilterTag/FilterTag';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const SideLeft: React.FC<Props> = () => {
  // const [expandedTags, setExpandedTags] = useState(false);

  return (
    <Container>
      <Button onClick={() => null} size="small">
        <LayoutOutlined className="layout-image" />
      </Button>

      <div className="search-criteria">
        <FormattedMessage
          id="search-criteria-text.text"
          defaultMessage="Search Criteria:"
        />
      </div>

      {/* <FilterTag name="订单号Partial: W00" /> */}

      {/* <div className="clear-all">
        <a href="#/">
          <FormattedMessage id="clear-all.text" defaultMessage="Clear all" />
        </a>
      </div> */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  button {
    background: #f2f2f2;
    margin: 0 16px;
    border-radius: 4px;
    border: none;

    .layout-image {
      margin-top: 4px;
    }
  }

  .search-criteria {
    margin-right: 12px;
  }

  .clear-all {
    margin-left: 12px;
  }

  color: #000000d9;
`;
