import React from 'react';
import { MultiMembersSelectSidebar } from 'pages/components/SystemMessage/ManualMessage/components/SystemMessageSidebar/components/MultiMembersSelectSidebar/MultiMembersSelectSidebar';
import { assignedFilterKeysByTypename } from 'pages/components/SystemMessage/ManualMessage/components/SystemMessageSidebar/utils';
import { multiSelectType } from 'pages/components/SystemMessage/ManualMessage/components/CreateNewMail/util';
import { IMultMembers } from '../../../SystemMessageSidebar/components/MultiMembersSelectSidebar/interface';

export const MultiMembersSelectForm = ({
  values,
  setFilters,
  type,
}: IMultiMembersSelectForm) => {
  const handleOnsSelectMembers = (e: any) => {
    setFilters(assignedFilterKeysByTypename(e, type));
  };

  return (
    <MultiMembersSelectSidebar
      multiSelectType={multiSelectType.FORM}
      values={values}
      onSelectMembers={handleOnsSelectMembers}
    />
  );
};

interface IMultiMembersSelectForm {
  values: IMultMembers;
  setFilters: (e: any) => void;
  type: 'target' | 'excluded';
}
