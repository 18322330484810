import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { DELETE_MEMBER_TAG } from 'graphql/mutations/memberTag.mutation';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import messages from './messages';

type Props = {
  toggleModal: () => void;
  id: string;
  refetch: () => void;
};

const DeleteLabel = (props: Props) => {
  const { toggleModal, id, refetch } = props;

  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [mutate, { loading }] = useMutation(DELETE_MEMBER_TAG, {
    context,
    onCompleted: () => {
      message.success(
        translate(messages['member.label-management.delete-label-success.text'])
      );
    },
  });

  return (
    <Modal
      visible
      maskClosable={false}
      closable={false}
      width={420}
      okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
      cancelText={<FormattedMessage id="cancel.text" defaultMessage="Cancel" />}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={async () => {
        await mutate({
          variables: {
            id,
          },
        });
        refetch();
        toggleModal();
      }}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">
          <FormattedMessage id="confirm.text" defaultMessage="Confirm" />{' '}
          <FormattedMessage id="delete.text" defaultMessage="Delete" />
        </span>
        <div className="ant-modal-confirm-content">
          <FormattedMessage
            id="are-you-sure.text"
            defaultMessage="Are you sure?"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteLabel;
