import React from 'react';
import { GET_MEMBER_TAGS } from 'graphql/queries/memberTag.query';
import SelectFilterField from 'components/SelectFilterField';

type Props = {
  filters: Record<string, any>;
  handleChange: (value: any) => void;
};

const FilterItems = ({ filters, handleChange }: Props) => (
  <>
    <SelectFilterField
      testId="label-mgmt-labelName-filter"
      label="Label Name"
      query={GET_MEMBER_TAGS}
      queryConnection="memberTags"
      filterFieldName="name"
      filters={filters}
      onChange={(e) =>
        handleChange((prev: Record<string, string>) => ({
          ...prev,
          name: e?.length ? { in: e } : null,
        }))
      }
    />
  </>
);

export default FilterItems;
