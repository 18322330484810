import gql from 'graphql-tag';

export const GET_GAME_SESSIONS_IDS = gql`
  query GameSessionsIds(
    $filter: GameSessionFilterInput
    $partialFilter: GameSessionFilterInput
  ) {
    gameSessions(filter: $filter) {
      edges {
        cursor
        node {
          id
          username
        }
      }
    }

    partial: gameSessions(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
          username
        }
      }
    }
  }
`;

export const GET_GAME_SESSIONS = gql`
  query GameSessions(
    $first: Int
    $after: Binary
    $filter: GameSessionFilterInput
  ) {
    gameSessions(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          platformId
          brandId
          username
          gameCategory
          game
          gameStartTimeStamp
          gameEndTimeStamp
          duration
          dateTimeCreated
        }
      }
    }
  }
`;

export const GET_USERNAMES = gql`
  query FilterUsernames(
    $first: Int
    $after: Binary
    $filter: GameSessionFilterInput
  ) {
    gameSessions(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export const GET_PLATFORM_IDS = gql`
  query FilterPlatformIds(
    $first: Int
    $after: Binary
    $filter: GameSessionFilterInput
  ) {
    gameSessions(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          platformId
        }
      }
    }
  }
`;

export const GET_BRAND_IDS = gql`
  query FilterBrandIds(
    $first: Int
    $after: Binary
    $filter: GameSessionFilterInput
  ) {
    gameSessions(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          brandId
        }
      }
    }
  }
`;
