import React, { Dispatch, SetStateAction } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio, Select } from 'antd';
import { Vendor } from 'types/graphqlTypes';
import { StyledSpacer, TitleContainer } from '../../styles';

type Props = {
  formItemLayout: object;
  currentVendor: string | null;
  setCurrentVendor: (e: string | null) => void;
  vendors: [Vendor];
  lobby: string | null;
  setLobby: Dispatch<SetStateAction<string | null>>;
  errorMsg: string | null | undefined;
};

export const AddVendorForm: React.FC<Props> = ({
  formItemLayout,
  currentVendor,
  setCurrentVendor,
  vendors,
  lobby,
  setLobby,
  errorMsg,
}) => (
  <>
    <StyledSpacer />
    <TitleContainer>
      <h4 style={{ marginBottom: '0', fontWeight: 600 }}>
        <FormattedMessage id="add-vendor.text" defaultMessage="Add Vendor" />
      </h4>
    </TitleContainer>

    <div className="mt-3">
      <Form layout="horizontal">
        <Form.Item
          label={<FormattedMessage id="vendor.text" defaultMessage="Vendor" />}
          validateStatus={errorMsg ? 'error' : ''}
          help={errorMsg}
          {...formItemLayout}
        >
          <Select
            value={currentVendor!}
            placeholder="Select a vendor"
            onChange={setCurrentVendor}
          >
            {vendors.map((vendor) => (
              <Select.Option value={vendor.id} key={vendor.id}>
                {vendor.name} - {vendor.type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="game-list-redirect.text"
              defaultMessage="Game List Redirect"
            />
          }
          {...formItemLayout}
        >
          <Radio.Group
            value={lobby}
            onChange={(ev) => setLobby(ev.target.value)}
          >
            <Radio value="internal">
              <FormattedMessage id="internal.text" defaultMessage="Internal" />
            </Radio>
            <Radio value="external">
              <FormattedMessage id="external.text" defaultMessage="External" />
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  </>
);
