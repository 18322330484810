import React, { FC, useCallback, ReactNode } from 'react';
import { Control, FieldError, Controller } from 'react-hook-form';
import {
  Radio,
  Space,
  Input,
  Select,
  Form,
  Typography,
  DatePicker,
} from 'antd';
import PromoTypeSelect from 'components/PromoTypeSelect';
import MemberTagSelect from 'components/MemberTagSelect';
import { DATE_TIME_FORMAT } from 'constants/date';
import moment, { Moment } from 'moment';

const { Item } = Form;
const { Option } = Select;
const { Text } = Typography;

type Props = {
  control: Control;
  setValue: (
    name: string,
    value: unknown,
    config?: Partial<{
      shouldValidate: boolean;
    }>
  ) => void;

  selectedPromoType?: string | null;
  promoTitleState: {
    promoTitleLength: number;
    setPromoTitle: Function;
  };

  fieldError: {
    type: FieldError | undefined;
    name: FieldError | undefined;
    event: FieldError | undefined;
    platform: FieldError | undefined;
    claimType: FieldError | undefined;
    awardPreRequisite: FieldError | undefined;
    validityDateTimeRange: FieldError | undefined;
  };
};

const PromoCommonFields: FC<Props> = ({
  fieldError,
  control,
  setValue,
  selectedPromoType,
  promoTitleState,
}) => {
  const {
    type,
    name,
    event,
    platform,
    claimType,
    awardPreRequisite,
    validityDateTimeRange,
  } = fieldError || {};

  const { promoTitleLength, setPromoTitle } = promoTitleState;

  const handlePromoEventOption = useCallback((): ReactNode => {
    if (selectedPromoType === 'SIGN_UP') {
      return <Option value="SIGN_UP">Sign Up</Option>;
    }

    if (selectedPromoType === 'DEPOSIT') {
      return (
        <>
          <Option value="FIRST_DEPOSIT">Deposit - 1st Deposit</Option>
          <Option value="RECURRING_DEPOSIT">Deposit Recurring Deposit</Option>
        </>
      );
    }

    return <></>;
  }, [selectedPromoType]);

  const disabledDate = (current: Moment) =>
    current && current < moment().startOf('day');

  return (
    <>
      <Item
        className="mb-3"
        label="Choose Promo Type"
        validateStatus={type && 'error'}
        help={type && type?.message}
        labelCol={{
          span: 6,
        }}
      >
        <Controller
          control={control}
          name="type"
          rules={{
            required: {
              value: true,
              message: 'Promo type is required.',
            },
          }}
          render={({ onChange, value }) => (
            <PromoTypeSelect value={value} onChange={onChange} />
          )}
        />
      </Item>

      <Item
        className="mb-3"
        label="Enter Promo Title"
        validateStatus={name && 'error'}
        help={name && name?.message}
        labelCol={{
          span: 6,
        }}
      >
        <Controller
          rules={{
            required: {
              value: true,
              message: 'Promo title is required.',
            },
            maxLength: {
              value: 100,
              message: 'More than 100 characters limit.',
            },
            pattern: {
              value: /^[aA-zZ0-9\s]+$/,
              message: 'Special characters (e.g. @,#,/,|) are not allowed.',
            },
          }}
          control={control}
          name="name"
          render={({ value }) => (
            <div className="d-flex align-items-center">
              <Input
                className="mr-1"
                value={value}
                maxLength={100}
                onChange={(e) => {
                  setValue('name', e.target.value, {
                    shouldValidate: true,
                  });
                  setPromoTitle(e.target.value.length);
                }}
                allowClear
                placeholder="Enter Promo Title"
              />
              <Text type="secondary">{promoTitleLength}/100</Text>
            </div>
          )}
        />
      </Item>

      <Item
        className="mb-3"
        label="Choose Promo Event"
        validateStatus={event && 'error'}
        help={event && event?.message}
        labelCol={{
          span: 6,
        }}
      >
        <Controller
          control={control}
          name="event"
          rules={{
            required: {
              value: true,
              message: 'Promo event is required.',
            },
          }}
          render={({ onChange, value }) => (
            <Select
              allowClear
              style={{ width: 200 }}
              value={value}
              onChange={onChange}
              placeholder="Select Promo Event"
            >
              {handlePromoEventOption()}
            </Select>
          )}
        />
      </Item>
      <Item
        className="mb-3"
        label="Promo Start/End Date"
        validateStatus={validityDateTimeRange && 'error'}
        help={validityDateTimeRange && validityDateTimeRange.message}
        labelCol={{
          span: 6,
        }}
      >
        <Space>
          <Controller
            control={control}
            name="validityDateTimeRange"
            rules={{
              required: {
                value: true,
                message: 'Start & End date time is required.',
              },
            }}
            render={({ value, onChange }) => (
              <DatePicker.RangePicker
                value={value}
                format={DATE_TIME_FORMAT}
                disabledDate={disabledDate}
                onChange={onChange}
                showTime
                placeholder={['Start Date Time', 'End Date Time']}
              />
            )}
          />
        </Space>
      </Item>

      <Item
        className="mb-3"
        label="Select Promo Platform Trigger"
        validateStatus={platform && 'error'}
        help={platform && platform?.message}
        labelCol={{
          span: 6,
        }}
      >
        <Controller
          control={control}
          name="platform"
          rules={{
            required: {
              value: true,
              message: 'Promo platform trigger is required.',
            },
          }}
          render={({ onChange, value }) => (
            <Select
              allowClear
              style={{ width: 200 }}
              value={value}
              onChange={onChange}
              placeholder="Select Promo Type"
            >
              <Option value="DESKTOP">Web</Option>
              <Option disabled value="MOBILE">
                App
              </Option>
            </Select>
          )}
        />
      </Item>

      <Item
        className="mb-3"
        label="Included Member Label(s)"
        labelCol={{
          span: 6,
        }}
      >
        <Controller
          control={control}
          name="includedMemberTags"
          render={({ onChange, value }) => (
            <MemberTagSelect
              placeHolder="Select Member Label(s)"
              multiple
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Item>

      <Item
        className="mb-3"
        label="Excluded Member Label(s)"
        labelCol={{
          span: 6,
        }}
      >
        <Controller
          control={control}
          name="excludedMemberTags"
          render={({ onChange, value }) => (
            <MemberTagSelect
              placeHolder="Select Member Label(s)"
              multiple
              value={value}
              onChange={onChange}
            />
          )}
        />
      </Item>

      <Item
        className="mb-3"
        label="Claim Type"
        validateStatus={claimType && 'error'}
        help={claimType && claimType?.message}
        labelCol={{
          span: 6,
        }}
      >
        <Controller
          control={control}
          name="claimType"
          rules={{
            required: {
              value: true,
              message: 'Claim type is required.',
            },
          }}
          render={({ onChange, value }) => (
            <Radio.Group
              onChange={(e) => onChange(e.target.value)}
              value={value}
            >
              <Space>
                <Radio value="AUTO">Auto</Radio>
                <Radio disabled value="MANUAL">
                  Manual
                </Radio>
              </Space>
            </Radio.Group>
          )}
        />
      </Item>
      <Item
        className="mb-3"
        label="Award Pre-requisite"
        validateStatus={awardPreRequisite && 'error'}
        help={awardPreRequisite && awardPreRequisite?.message}
        labelCol={{
          span: 6,
        }}
      >
        <Controller
          control={control}
          name="awardPreRequisite"
          rules={{
            required: {
              value: true,
              message: 'Promo award pre-requisite is required.',
            },
          }}
          render={({ onChange, value }) => (
            <Select
              allowClear
              style={{ width: 200 }}
              value={value}
              onChange={onChange}
              placeholder="Select Award Pre-requisite"
            >
              <Option value="VERIFIED_MEMBERS_ONLY">Verified Members</Option>
              <Option value="INCLUDING_NON_VERIFIED_MEMBERS">
                Not Verified Members
              </Option>
            </Select>
          )}
        />
      </Item>
    </>
  );
};

export default PromoCommonFields;
