import gql from 'graphql-tag';

export const GET_SITE_CONFIGURATION = gql`
  query getSiteConfiguration {
    config {
      domainName
      sslEnabled
      crtFile {
        id
        uri
        mimetype
        encoding
      }
      privateKey {
        id
        uri
        mimetype
        encoding
      }
      intermediaryCert {
        id
        uri
        mimetype
        encoding
      }
      captchaEnabledBackend
      captchaEnabledFrontend
      googleAuthEnabledBackend
      transferVendorsEnabled
      seamlessVendorsEnabled
      paymentMethodSuccessRateCalculationLastTransactionsCount
      withdrawalHandlingFee
      withdrawalHandlingFeeType
      withdrawalTaxType
      withdrawalTaxValue
      domainDescription
      turnoverRequirementWaiverEnabled
      turnoverRequirementWaiverThreshold
      timezone
      cname
    }
  }
`;

export const DOMAINS = gql`
  query Domains($first: Int, $after: Binary, $filter: DomainFilterInput) {
    domains(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          name
          status
          description
          sslEnabled
          dateTimeCreated
        }
      }
    }
  }
`;

export const DOMAIN = gql`
  query Domain($id: ID!) {
    domain(id: $id) {
      id
      name
      status
      description
      sslEnabled
      dateTimeCreated
      dateTimeUpdated
    }
  }
`;
