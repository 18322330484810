import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import {
  GET_BET_RECORD_BET_ID,
  GET_BET_RECORD_GAME_CATEGORY,
  GET_BET_RECORD_GAME_NAMES,
  GET_BET_RECORD_GAME_SUB_CAT,
  GET_BET_RECORD_SERIAL_CODES,
  GET_BET_RECORD_SESSION,
  GET_BET_RECORD_USERNAMES,
  GET_BET_RECORD_BRAND_IDS,
  GET_BET_RECORD_PLATFORM_IDS,
} from 'graphql/queries/memberBetRecords.query';
import SelectFilterField from 'components/SelectFilterField';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import BrandFilter from 'components/BrandFilter';
import { useDatePresets } from 'hooks/useDatePresets';
import { usePermissions } from 'store/accountState';
import isRow from 'utils/isRow';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import { GET_AVAILABLE_CURRENCIES } from 'hooks/useAvailableCurrencies';
import {
  BalanceUnitSerialCode,
  BetAmount,
  CashoutWinLoss,
  Device,
  EffectiveBet,
  FreeSpin,
  GameType,
  JackpotContributionBet,
  JackpotWin,
  PayoutAmountFilter,
  SportsGameTitleFilter,
  Status,
  TipsMinMax,
  Vendor,
  WinLoss,
} from './components';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  onFilterChange: (value: DynamicObj) => void;
};

const FilterItems = ({
  filters,
  onRawFilterChange: handleChange,
  onFilterChange,
}: Props) => {
  const usernameValues = filters?.member?.in || [];
  const gameNameValues = filters?.game?.in || [];
  const { disableDateFilter, setPresetValue } = useDatePresets();

  const dateFilters: Partial<{
    label: string;
    filterField: string;
    disableDateInput?: boolean;
  }[]> = [
    {
      label: 'date-time-placed',
      filterField: 'dateTimePlaced',
      disableDateInput: disableDateFilter,
    },
    {
      label: 'date-time-settled',
      filterField: 'dateTimeSettled',
    },
    {
      label: 'date-time-cancelled',
      filterField: 'dateTimeCanceled',
    },
  ];

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST: ALLOWED_MEMBER } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  return (
    <>
      <SelectFilterField
        testId="MBR-serialCode-filter"
        label="Serial Code"
        query={GET_BET_RECORD_SERIAL_CODES}
        queryConnection="memberBetRecords"
        filterFieldName="serialCode"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'serialCode',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      {isRow && (
        <SelectFilterField
          testId="MBR-currency-filter"
          label="Currency"
          allowFilterOption
          partialSupported={false}
          query={GET_AVAILABLE_CURRENCIES}
          isAvailableCurrencyQuery
          queryConnection=""
          filterFieldName="memberCurrency"
          queryOperation="in"
          filters={filters}
          multiple
          onChange={(e) =>
            handleChange({
              name: 'memberCurrency',
              value: e?.length ? { in: e } : null,
            })
          }
        />
      )}
      {ALLOWED_MEMBER && (
        <IdBasedSelectFilterField
          testId="MBR-username-filter"
          label="Member"
          query={GET_BET_RECORD_USERNAMES}
          queryConnection="members"
          filterFieldName="username"
          filters={filters}
          rawFilterValues={usernameValues}
          onChange={(e) =>
            handleChange({
              name: 'member',
              value: e?.length ? { in: e } : null,
            })
          }
          partialSupported={false}
        />
      )}
      <PayoutAmountFilter state={filters} setState={onFilterChange} />
      <SportsGameTitleFilter state={filters} setState={onFilterChange} />
      <SelectFilterField
        testId="MBR-game-category-filter"
        label="Game Category"
        query={GET_BET_RECORD_GAME_CATEGORY}
        queryConnection="memberBetRecords"
        filterFieldName="gameCategory"
        fieldType="game"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'gameCategory',
            value: e?.length ? { in: e } : null,
          })
        }
        partialSupported={false}
      />
      <SelectFilterField
        testId="MBR-game-subCategory-filter"
        label="Game Sub Category"
        query={GET_BET_RECORD_GAME_SUB_CAT}
        queryConnection="memberBetRecords"
        filterFieldName="gameSubCategory"
        fieldType="game"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'gameSubCategory',
            value: e?.length ? { in: e } : null,
          })
        }
        partialSupported={false}
      />
      <Vendor state={filters} setState={onFilterChange} />
      <SelectFilterField
        testId="MBR-vendor-betId-filter"
        label="Vendor Bet Id"
        query={GET_BET_RECORD_BET_ID}
        queryConnection="memberBetRecords"
        filterFieldName="round"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'round',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <IdBasedSelectFilterField
        testId="MBR-game-name-filter"
        label="Game Name"
        query={GET_BET_RECORD_GAME_NAMES}
        queryConnection="games"
        filterFieldName="name"
        filters={filters}
        rawFilterValues={gameNameValues}
        onChange={(e) =>
          handleChange({
            name: 'game',
            value: e?.length ? { in: e } : null,
          })
        }
        partialSupported={false}
      />
      <BrandFilter state={filters} setState={onFilterChange} />

      {ALLOWED_MEMBER && (
        <>
          <SelectFilterField
            testId="MBR-brandId-filter"
            label="Brand Id"
            query={GET_BET_RECORD_BRAND_IDS}
            queryConnection="members"
            filterFieldName="brandId"
            filters={filters}
            onChange={(e) =>
              handleChange({
                name: 'brandId',
                value: e?.length ? { in: e } : null,
              })
            }
          />
          <SelectFilterField
            testId="MBR-platformId-filter"
            label="Platform Id"
            query={GET_BET_RECORD_PLATFORM_IDS}
            queryConnection="members"
            filterFieldName="platformId"
            filters={filters}
            onChange={(e) =>
              handleChange({
                name: 'platformId',
                value: e?.length ? { in: e } : null,
              })
            }
          />
        </>
      )}
      <GameType state={filters} setState={onFilterChange} />
      {dateFilters?.map((dateFilter) => (
        <DateTimeRangePicker
          testId={`MBR-${dateFilter?.label}-filter`}
          disableDateInput={dateFilter?.disableDateInput}
          label={`reports.${dateFilter?.label}`}
          value={
            filters[dateFilter!.filterField]
              ? [
                  filters[dateFilter!.filterField].gte,
                  filters[dateFilter!.filterField].lte,
                ]
              : null
          }
          onChange={(e) => {
            if (dateFilter?.filterField === 'dateTimePlaced' && !e?.length) {
              setPresetValue(null);
            }
            handleChange({
              name: dateFilter!.filterField,
              value: e?.length ? { gte: e[0], lte: e[1] } : null,
            });
          }}
        />
      ))}

      <Status state={filters} setState={onFilterChange} />
      <BetAmount state={filters} setState={onFilterChange} />
      <EffectiveBet
        state={filters}
        setState={onFilterChange}
        disabled={false}
      />
      <WinLoss state={filters} setState={onFilterChange} />
      <BalanceUnitSerialCode state={filters} setState={onFilterChange} />
      <SelectFilterField
        testId="MBR-sessionId-filter"
        label="Session Id"
        query={GET_BET_RECORD_SESSION}
        queryConnection="memberBetRecords"
        filterFieldName="session"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'session',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <TipsMinMax state={filters} setState={onFilterChange} disabled={false} />
      <JackpotContributionBet
        state={filters}
        setState={onFilterChange}
        disabled={false}
      />
      <JackpotWin state={filters} setState={onFilterChange} disabled={false} />
      <CashoutWinLoss
        state={filters}
        setState={onFilterChange}
        disabled={false}
      />
      <FreeSpin state={filters} setState={onFilterChange} />
      <Device state={filters} setState={onFilterChange} />
    </>
  );
};

export default FilterItems;
