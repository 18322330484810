import styled from 'styled-components';
import { Layout, Select } from 'antd';

export const Divider = styled.div`
  height: 20px;
`;

export const CostSettingsContent = styled(Layout.Content)`
  border-top: 1px solid #e8e8e8;
`;

export const CostSettingsSider = styled(Layout.Sider)`
  background-color: #ffffff;
  border-left: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
`;

interface StyledSelectType {
  borderless: boolean;
}

export const StyledSelect = styled(Select)<StyledSelectType>`
  width: 148px !important;
  &.ant-select-selection {
    border: ${(props) => (props.borderless ? 'none' : '')};
  }
`;

export default {};
