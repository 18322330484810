import React from 'react';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { ReactComponent as RefreshIcon } from 'components/assets/refresh-table.svg';

type Props = {
  onClick: () => void;
};

const RefreshTable: React.FC<Props> = ({ onClick, ...rest }) => {
  const translate = useTranslate();

  return (
    <Tooltip title={translate(messages['refres-table.text'])}>
      <Icon
        className="mr-2"
        component={RefreshIcon}
        style={{ fontSize: '22px' }}
        onClick={onClick}
        {...rest}
      />
    </Tooltip>
  );
};

export default RefreshTable;
