import gql from 'graphql-tag';

export const ADMIN = gql`
  query Admin($id: ID!) {
    admin(id: $id) {
      username
    }
  }
`;

export const OPERATOR = gql`
  query Operator($id: ID!) {
    operator(id: $id) {
      id
      username
    }
  }
`;

export default {};
