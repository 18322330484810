import styled from 'styled-components';
import { Select } from 'antd';
import get from 'lodash/get';
import { findColorByBgColor } from 'pages/components/SystemMessage/ManualMessage/components/CreateNewMail/styles';

const { Option } = Select;

const colorTags = (tags: any) =>
  tags.reduce(
    (acc: any, item: any, key: number) =>
      `${acc}
          &[title='${key}'] {
            background-color: ${get(
              findColorByBgColor(item.node.color),
              'bgColor',
              '#efefef'
            )};
            border: 1px solid
              ${get(
                findColorByBgColor(item.node.color),
                'borderColor',
                '#888888'
              )};
            border-radius: 11px !important;
            color: #000 !important;
          
            .anticon-close {
              color: #000 !important;
            }
          }
  `,
    ''
  );

export const StyledOption = styled(Option)<{ color: string }>``;

export const StyledSelect = styled(Select)<{ tags: any }>`
  width: 100% !important;

  .ant-select-selection {
    border-radius: 2px;
  }

  .ant-select-selection li.ant-select-selection__choice {
    ${({ tags }) => (tags ? colorTags(tags) : '')}
  }
`;

export default {};
