import React from 'react';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/date';
import { NotesContainerStyled } from '../../styles';

const Note = ({ note }: { note: Record<string, any> }) => {
  const { node } = note;

  return (
    <NotesContainerStyled>
      <div className="content">{node.note}</div>
      <div className="d-flex justify-content-space-between">
        <div>{moment(node.dateTimeUpdated).format(DATE_TIME_FORMAT)}</div>
      </div>
    </NotesContainerStyled>
  );
};

export default Note;
