import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-loyalty.create-programme.text': {
    id: 'member-loyalty.create-programme.text',
    defaultMessage: 'Create Programme',
  },
  'member-loyalty.name-is-a-required-field.text': {
    id: 'member-loyalty.name-is-a-required-field.text',
    defaultMessage: 'Name is a required field',
  },
  'cannot-save-publish-vip-programme-with-empty-tiers': {
    id: 'cannot-save-publish-vip-programme-with-empty-tiers',
    defaultMessage: 'Cannot Save/Publish VIP Programme with empty tiers',
  },
  'member-loyalty.programme-has-no-levels-will-set-as-draft': {
    id: 'member-loyalty.programme-has-no-levels-will-set-as-draft',
    defaultMessage: 'Programme has no levels, will set the program as Draft',
  },
  'member-loyalty.loyalty-programme-publish-success.text': {
    id: 'member-loyalty.loyalty-programme-publish-success.text',
    defaultMessage: 'Loyalty programme published successfully.',
  },
  'member-loyalty.programme-details-saved.text': {
    id: 'member-loyalty.programme-details-saved.text',
    defaultMessage: 'Programme Details Saved.',
  },
  'member-loyalty.programme-draft-created.text': {
    id: 'member-loyalty.programme-draft-created.text',
    defaultMessage: 'Programme Draft Created.',
  },
  INVITE_ONLY_ERROR: {
    id: 'INVITE_ONLY_ERROR',
    defaultMessage:
      'Invite Only Tier must be above the topmost Non Invite Only Tier.',
  },
  INCOMPLETE_TIER_ERROR: {
    id: 'INCOMPLETE_TIER_ERROR',
    defaultMessage: 'You have an incomplete Tier',
  },
});

export default messages;
