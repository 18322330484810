import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import { AFFILIATE_REQUESTS_SERIAL_CODE } from 'graphql/queries/affiliateAgent.query';
import SelectFilterField from 'components/SelectFilterField';
import { FilterItem } from 'components/FilterItem/FilterItem';
import StatusFilter from '../Sidebar/components/StatusFilter';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    key,
    value,
  }: {
    key: string;
    value: DynamicObj | null;
  }) => void;
};

const FilterItems = ({ filters, onRawFilterChange: handleChange }: Props) => (
  <>
    <SelectFilterField
      testId="affiliate-request-serialCode-filter"
      label="Serial Code"
      query={AFFILIATE_REQUESTS_SERIAL_CODE}
      queryConnection="affiliateRequests"
      filterFieldName="serialCode"
      partialSupported={false}
      filters={filters}
      onChange={(e) =>
        handleChange({
          key: 'serialCode',
          value: e?.length ? { in: e } : null,
        })
      }
    />
    <FilterItem label="Status">
      <StatusFilter
        filters={filters}
        onChange={(e) =>
          handleChange({
            key: 'status',
            value: e?.length ? { in: e } : null,
          })
        }
      />
    </FilterItem>
  </>
);

export default FilterItems;
