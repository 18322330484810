import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import createContext from 'utils/createContext';
import { useScreenTabSA } from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';

const CUSTOM_FILTER = gql`
  query CustomFilters($id: ID) {
    customFilter(id: $id) {
      id
      name
      filter
    }
  }
`;
export interface FilterProps {
  filters: { [key: string]: any };
  setPersistedFilters: (value: any) => void;
  onFilterChange: (value: any) => void;
  setFilters: (value: any) => void;
  handleFilterChange: (value: any) => void;
  resetFilter: () => void;
  initialValues: object;
  resetFilterToDefault: () => void;
  defaultValues: object;
  savedFilterId: string | null;
  handleSavedFilterIdChange: (value: string) => void;
  fetchingFilter: boolean;
  tabId?: string;
}

const defaultProps = {
  initialValues: {},
  defaultValues: {},
  tabId: '',
};

type Props = {
  children: React.ReactNode;
} & typeof defaultProps;

const [useContext, ContextProvider] = createContext<FilterProps>();

const FiltersProvider = ({
  children,
  initialValues = {},
  defaultValues = {},
  tabId,
}: Props) => {
  const { updateTab } = useScreenTabSA();

  const [filters, setFilters] = useState(initialValues);
  const [savedFilterId, setSavedFilterId] = useState<string | null>(null);

  const setPersistedFilters = (filts: any) => {
    const newFilters = typeof filts === 'function' ? filts(filters) : filts;
    if (tabId) {
      updateTab({
        id: tabId,
        updates: {
          filter: newFilters,
        },
      });
    }
    setFilters(newFilters);
  };

  const saveTabFilter = (filterToUse: any) => {
    if (tabId) {
      updateTab({
        id: tabId,
        updates: {
          filter: filterToUse,
        },
      });
    }
  };

  const { loading } = useQuery(CUSTOM_FILTER, {
    variables: {
      id: savedFilterId,
    },
    skip: !savedFilterId,
    onCompleted: result => {
      if (result) {
        const { customFilter } = result;
        if (customFilter) {
          const { filter } = customFilter;
          const newFilter = { ...defaultValues, ...filter };
          setFilters(newFilter);
          saveTabFilter(newFilter);
        }
      }
    },
  });

  const handleFilterChange = ({ name, value }: any) => {
    setFilters(prevState => {
      const newFilter = { ...prevState, [name]: value };
      saveTabFilter(newFilter);
      return newFilter;
    });
    setSavedFilterId(null);
  };

  const handleSavedFilterIdChange = (id: string) => {
    if (!id) {
      setFilters(defaultValues);
      saveTabFilter(defaultValues);
    }
    setSavedFilterId(id);
  };

  const resetFilter = () => setFilters(initialValues);

  const resetFilterToDefault = () => {
    setFilters(defaultValues);
    setSavedFilterId(null);
  };

  return (
    <ContextProvider
      value={{
        filters,
        setFilters,
        setPersistedFilters,
        onFilterChange: value => setFilters(value),
        handleFilterChange,
        resetFilter,
        resetFilterToDefault,
        initialValues,
        defaultValues,
        savedFilterId,
        handleSavedFilterIdChange,
        fetchingFilter: loading,
      }}
    >
      {children}
    </ContextProvider>
  );
};

export default FiltersProvider;

export const useFilterValues = () => useContext();

FiltersProvider.defaultProps = defaultProps;
