import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { message, Modal, Spin } from 'antd';
import thirdPartyProviders from 'constants/thirdPartyProviders';
import { CREATE_WITHDRAWAL_PROVIDER } from 'graphql/mutations/withdrawalProvider.mutation';
import {
  WITHDRAWAL_PROVIDER,
  WITHDRAWAL_PROVIDERS,
} from 'graphql/queries/withdrawalProvider.query';
import messages from 'pages/components/WithdrawalProviders/messages';
import React from 'react';
import { withdrawalProvidersTestId } from 'components/data-testid/WithdrawalProviders';
import useTranslate from 'utils/useTranslate';

const DuplicePayentGateway = (props: Record<string, any>) => {
  const { toggleModal, id, refetchVariables } = props;

  const translate = useTranslate();
  const onSuccessSubmit = () =>
    message.success(
      translate(messages['3rd-party-withdrawal-provider-duplicated.text'])
    );

  const [createWithdrawalProvider, { loading }] = useMutation(
    CREATE_WITHDRAWAL_PROVIDER,
    {
      onCompleted: onSuccessSubmit,
      refetchQueries: [
        {
          query: WITHDRAWAL_PROVIDERS,
          variables: refetchVariables,
        },
      ],
    }
  );

  const { loading: loadingWithdrawalProvider, data } = useQuery(
    WITHDRAWAL_PROVIDER,
    {
      variables: { id },
      fetchPolicy: 'network-only',
    }
  );

  const onConfirmDuplicate = async () => {
    const { withdrawalProvider } = data;
    type InputType = {
      apiKey: any;
      midPayDirectPaymentGateway: any;
      credentialValues: any;
      name: string;
      type: string;
      key: string;
      merchantCode: string;
    };
    let input = {
      name: `copy of ${withdrawalProvider.name}`,
      type: withdrawalProvider.type,
    } as InputType;

    if (withdrawalProvider.type === thirdPartyProviders.DIORPAY) {
      input = {
        ...input,
        key: withdrawalProvider.key,
        merchantCode: withdrawalProvider.merchantCode || '',
      };
    } else if (withdrawalProvider.type === thirdPartyProviders.MIDPAY) {
      input = {
        ...input,
        apiKey: withdrawalProvider.apiKey,
      };
    } else if (withdrawalProvider.type === thirdPartyProviders.MIDPAY_DIRECT) {
      input = {
        ...input,
        apiKey: withdrawalProvider.apiKey,
        midPayDirectPaymentGateway:
          withdrawalProvider.midPayDirectPaymentGateway.id,
        credentialValues: withdrawalProvider.credentialValues,
      };
    }

    await createWithdrawalProvider({ variables: { input } });
    toggleModal();
  };

  return (
    <Modal
      data-testid={withdrawalProvidersTestId.duplicateModal}
      visible
      maskClosable={false}
      closable={false}
      width={416}
      okText={translate(messages['confirm.text'])}
      cancelText={translate(messages['cancel.text'])}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={onConfirmDuplicate}
    >
      <div className="ant-modal-confirm-body">
        {loadingWithdrawalProvider ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spin />
          </div>
        ) : (
          <>
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              {translate(messages['confirm.text'])}{' '}
              {translate(messages['duplicate.text'])}
            </span>
            <div className="ant-modal-confirm-content">
              {translate(messages['are-you-sure.text'])}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DuplicePayentGateway;
