import React, { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { message, Modal, Button } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';

type Props = {
  reportId: string;
  refetch: any;
};

export const DELETE_VENDOR_USAGE_REPORT = gql`
  mutation DeleteVUR($id: ID!) {
    deleteVendorUsageReport(id: $id)
  }
`;

const DeleteScheduledReport: React.FC<Props> = ({ reportId, refetch }) => {
  const [isShow, setShow] = useState(false);
  // const translate = useTranslate();
  const handleShow = (e?: any) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const [deleteReport, { loading }] = useMutation(DELETE_VENDOR_USAGE_REPORT, {
    onCompleted: () => {
      refetch();
      message.success(
        <FormattedMessage
          id="superadming.scheduled-report-deleted.text"
          defaultMessage="Scheduled Report Deleted"
        />
      );
    },
  });

  return (
    <>
      <Button
        className="text-black"
        onClick={handleShow}
        block
        style={{ width: '132px' }}
      >
        <FormattedMessage
          id="reports.cancel-schedule.text"
          defaultMessage="Cancel Schedule"
        />
      </Button>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          cancelButtonProps={{ disabled: loading }}
          okButtonProps={{ loading }}
          onCancel={handleShow}
          onOk={() => {
            deleteReport({
              variables: {
                id: reportId,
              },
            });

            handleShow();
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-delete.text"
                defaultMessage="Confirm Delete"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteScheduledReport;
