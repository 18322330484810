/* eslint-disable no-nested-ternary */
import TableCellSpinner from 'components/TableCellSpinner';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import TruncateWithTooltip from 'components/TruncateWithTooltip';
import { useLoaders } from 'contexts/DataLoader';
import messages from 'messages';
import React from 'react';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';

interface Props {
  depositId: string;
}

const DepositPaymentMethod = ({ depositId }: Props) => {
  const {
    depositMethodLoaderSet: [useDepositMethodLoader],
  } = useLoaders();
  const { loading, error, data = {} } = useDepositMethodLoader(depositId);

  const translate = useTranslate();
  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;
  const paymentMethod = coercedGet(data, 'paymentMethod', {});
  if (!paymentMethod) {
    return <span>-</span>;
  }
  const { bank, name, nickname, cryptocurrency } = paymentMethod;

  const manualAdjustMent = translate(messages.MANUAL_ADJUSTMENT);
  return (
    <TruncateWithTooltip
      text={`${
        bank || nickname || cryptocurrency
          ? `${bank || nickname || cryptocurrency} -`
          : ''
      } ${
        name === 'Manual Adjustment'
          ? manualAdjustMent
          : name === 'ASTROPAYWALLET'
          ? 'AstroPay Wallet'
          : name
      }`}
    />
  );
};

export default DepositPaymentMethod;
