import React, { useEffect, useState, useMemo } from 'react';
import uuid from 'uuid';
import { useScreenTabV2, Tab } from 'store/screenTabState';
import MemberPromoProvider from 'hooks/useMemberPromo';
import { StyledLayout } from './styles';
import { NullableType } from '../../../interfaces/graphql.interface';
import { MemberDetailContent } from './components/MemberDetailContent/MemberDetailContent';
import { NewMemberDetailProvider } from './context';
import './style.scss';
import { MemberProvider } from './memberContext';

export const NewMemberDetail = () => {
  const { getActiveTab } = useScreenTabV2();
  const [storedId, setStoredId] = useState<NullableType<string>>('');
  const [key, setKey] = useState<string>('');

  const tab: Tab = getActiveTab();
  const memberId = tab.memberId as string;

  const refreshPage = () => {
    setKey(uuid.v1());
  };

  useEffect(() => {
    setStoredId(memberId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(
    () => (
      <StyledLayout key={key}>
        <NewMemberDetailProvider refreshPage={refreshPage}>
          <MemberProvider memberId={storedId || memberId}>
            <MemberPromoProvider memberId={storedId || memberId}>
              <MemberDetailContent />
            </MemberPromoProvider>
          </MemberProvider>
        </NewMemberDetailProvider>
      </StyledLayout>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storedId, key]
  );
};
