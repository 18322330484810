import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledContainer = styled(Layout)`
  padding-top: 100px;
  padding-bottom: 100px;
  height: 100vh;
`;

export default {};
