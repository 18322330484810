import React from 'react';
import { Row, Col } from 'antd';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import ChartDetailed from './components/ChartDetailed';
import { StyledCard } from './styles';
import messages from './messages';

type Rec = Record<string, any>;
type Props = {
  langId: string;
  detail: string;
  total: number;
  graph: Rec;
  color: string;
  chart?: number;
  totalDepositsCount?: number;
  totalDepositorsCount?: number;
  withdrawalExtraInfo?: Array<{ label: string; value: number }>;
  totalBetsCount?: number;
};

const CardStat = ({
  langId,
  total,
  detail,
  graph,
  color,
  chart,
  totalDepositsCount,
  totalDepositorsCount,
  withdrawalExtraInfo,
  totalBetsCount,
}: Props) => {
  const translate = useTranslate();
  const { addAdminCurrency } = useConfig();

  return (
    <StyledCard>
      <div className="mb-2">{translate(messages[langId])}</div>
      <Row gutter={16}>
        <Col span={16}>
          <ChartDetailed graph={graph} color={color} chart={chart} />
        </Col>
        {detail === 'deposit' ? (
          <Col span={8}>
            <div className="mb-4 first">
              <div className="label">
                {translate(messages['dashboard.amount-of-deposits.text'])}
              </div>
              <h2>{addAdminCurrency(total)}</h2>
            </div>
            <div className="mb-4">
              <div className="label">
                {translate(
                  messages['dashboard.number-of-deposit-request.text']
                )}
              </div>
              <h2>{totalDepositsCount}</h2>
            </div>
            <div>
              <div className="label">
                {translate(
                  messages[
                    'dashboard.number-of-unique-accounts-doing-deposits.text'
                  ]
                )}
              </div>
              <h2>{totalDepositorsCount}</h2>
            </div>
          </Col>
        ) : (
          ''
        )}
        {detail === 'bet' ? (
          <Col span={8}>
            <div className="mb-4 first">
              <div className="label">
                {translate(messages['dashboard.total-bet-amount.text'])}
              </div>
              <h2>{addAdminCurrency(total)}</h2>
              <div className="label">Total Bets Count</div>
              <h2>{totalBetsCount}</h2>
            </div>
          </Col>
        ) : (
          ''
        )}
        {detail === 'withdrawal' ? (
          <Col span={8}>
            <div className="mb-4 first">
              <div className="label">
                {translate(messages['dashboard.amountof-withdrawals.text'])}
              </div>
              <h2>{addAdminCurrency(total)}</h2>
              {withdrawalExtraInfo?.map((info) => (
                <>
                  <div className="label">{info.label}</div>
                  <h2>{info.value}</h2>
                </>
              ))}
            </div>
          </Col>
        ) : (
          ''
        )}
        {detail === 'winloss' ? (
          <Col span={8}>
            <div className="mb-4 first">
              <div className="label">
                {translate(messages['dashboard.amount-of-win-loss.text'])}
              </div>
              <h2>{addAdminCurrency(total)}</h2>
            </div>
          </Col>
        ) : (
          ''
        )}
        {detail === 'netprofit' ? (
          <Col span={8}>
            <div className="mb-4 first">
              <div className="label">
                {translate(messages['dashboard.amount-of-net-profit.text'])}
              </div>
              <h2>{addAdminCurrency(total)}</h2>
            </div>
          </Col>
        ) : (
          ''
        )}
      </Row>
    </StyledCard>
  );
};

export default CardStat;
