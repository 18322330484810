import React from 'react';
import setTitleCase from 'utils/setTitleCase';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge } from 'antd';
import { upperCase } from 'lodash';

const color = {
  ACTIVE: 'green',
  INACTIVE: 'GRAY',
};

const displayIntlText = (text: string, locale: string) => {
  if (locale === 'en') return setTitleCase(text);

  if (locale === 'zh') {
    switch (text) {
      case 'ACTIVE':
        return <FormattedMessage id="active.text" defaultMessage="Active" />;
      case 'INACTIVE':
        return (
          <FormattedMessage id="inactive.text" defaultMessage="Inactive" />
        );
      default:
        return 'Unknown Status';
    }
  }

  return 'Unknown Status';
};

const SiteStatusBadge = ({ text }: { text: string }) => {
  const { locale } = useIntl();
  return (
    <Badge
      color={color[upperCase(text)]}
      text={displayIntlText(text, locale)}
    />
  );
};

export default SiteStatusBadge;
