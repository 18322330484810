import React from 'react';
import { useFilterValues } from 'contexts/Filters';
import { Tag } from 'antd';
import coercedGet from 'utils/coercedGet';
import WithdrawalProviderTag from 'components/WithdrawalProviderTag';
import BankTag from 'components/BankTag';
import useTranslate from 'utils/useTranslate';
import messages from '../../../messages';

type PreventDefault = {
  preventDefault: () => void;
};

const FilterConditions = () => {
  const { filters, onFilterChange } = useFilterValues();

  const handleRemoveFilterTag = (
    name: string,
    value: string,
    e: PreventDefault
  ) => {
    e.preventDefault();
    const field = coercedGet(filters, name, []);
    const data = field.in.filter((item: string) => item !== value);
    const newField = {
      in: data,
    };
    onFilterChange({
      ...filters,
      [`${name}`]: data.length ? newField : null,
    });
  };
  const translate = useTranslate();
  const methodTypes = {
    OFFLINE_BANK_TRANSFER_WITHDRAWAL_METHOD: `${translate(
      messages.internal
    )} ${translate(messages.banktransfer)}`,
    ONLINE_BANK_TRANSFER_WITHDRAWAL_METHOD: `${translate(
      messages.thirdparty
    )} ${translate(messages.banktransfer)}`,
    ALIPAY_WITHDRAWAL_METHOD: `${translate(messages.internal)} ${translate(
      messages.alipay
    )}`,
    ONLINE_ALIPAY_WITHDRAWAL_METHOD: `${translate(
      messages.thirdparty
    )} ${translate(messages.alipay)}`,
    WECHAT_WITHDRAWAL_METHOD: `${translate(messages.internal)} ${translate(
      messages.wechat
    )}`,
    ONLINE_WECHAT_WITHDRAWAL_METHOD: `${translate(
      messages.thirdparty
    )} ${translate(messages.wechat)}`,
  };

  return (
    <>
      {filters.name
        ? filters.name.in.map((name: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('name', name, e)}
            >
              {name}
            </Tag>
          ))
        : null}
      {filters.withdrawalProvider
        ? filters.withdrawalProvider.in.map((item: string, key: number) => (
            <WithdrawalProviderTag
              onClose={(e) =>
                handleRemoveFilterTag('withdrawalProvider', item, e)
              }
              key={key}
              id={item}
            />
          ))
        : null}
      {filters.nickname
        ? filters.nickname.in.map((nickname: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('nickname', nickname, e)}
            >
              {nickname}
            </Tag>
          ))
        : null}
      {filters.bank
        ? filters.bank.in.map((item: string, key: number) => (
            <BankTag
              onClose={(e) => handleRemoveFilterTag('bank', item, e)}
              key={key}
              bankCode={item}
            />
          ))
        : null}
      {filters.enabled
        ? filters.enabled.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('enabled', item, e)}
            >
              {translate(messages[item ? 'enable' : 'disable'])}
            </Tag>
          ))
        : null}
      {filters.type
        ? filters.type.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('type', item, e)}
            >
              {methodTypes[item]}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
