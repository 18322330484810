import React from 'react';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import CustomTag from 'components/CustomTag';
import MemberMarkerButton from 'components/MemberMarkerButton';
import { useConfig } from 'hooks/useConfig';
import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import { DATE_FORMAT } from 'constants/date';
import MemberProfile from './components/MemberProfile';

type Props = {
  withdrawal: Record<string, any>;
};

const MemberInformation = (props: Props) => {
  const { currency: configCurrency } = useConfig();
  const { withdrawal } = props;
  const {
    account = {},
    dateTimeCreated,
    amount,
    currency,
    isCrypto,
  } = withdrawal;
  const { tags = [], memberLoyaltyLevels, realName } = account;
  const { Text } = Typography;

  const { notes = {} } = account;
  const { edges = [] } = notes;

  return (
    <>
      <div className="menu-header p-2">
        <FormattedMessage id="member-info.text" defaultMessage="Member info" />
      </div>
      <div className="d-flex p-2 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage id="requester.text" defaultMessage="Requester" />
          </Text>
        </div>
        <div className="flex-1 text-primary text-right">
          <MemberProfile member={account} />
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage id="vip-level.text" defaultMessage="VIP level" />
          </Text>
        </div>
        <div className="ml-auto">
          {memberLoyaltyLevels?.length ? (
            <MemberLoyaltyTagList loyaltyLevels={memberLoyaltyLevels} />
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className="d-flex  bb-1 p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="request-time.text"
              defaultMessage="Request time"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {dateTimeCreated ? (
            <>
              {moment(dateTimeCreated).format(`${DATE_FORMAT} (ddd)`)}
              <br />
              {moment(dateTimeCreated).format('HH:mm:ss')} -{' '}
              {moment(dateTimeCreated).fromNow()}
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="requested-amount.text"
              defaultMessage="Requested amount"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {isCrypto
            ? `${currency} ${amount.toFixed(8)}`
            : `${configCurrency?.symbol} ${amount}`}
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="member-marker.text"
              defaultMessage="Member Marker"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          <MemberMarkerButton
            memberMarker={account?.memberLevel?.name}
            color={account?.memberLevel?.color}
          />
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage id="real-name.text" defaultMessage="Real Name" />
          </Text>
        </div>
        <div className="flex-1 text-right">{realName || '-'}</div>
      </div>
      <div className="bb-1 p-2">
        <div className="mb-2">
          <Text type="secondary">
            <FormattedMessage
              id="member-details-remark.text"
              defaultMessage="Member details remark"
            />
          </Text>
        </div>
        {edges.length ? edges[0].node.note : '-'}
      </div>
      <div className="p-2">
        <div className="mb-2">
          <Text type="secondary">
            <FormattedMessage id="label.text" defaultMessage="Label" />
          </Text>
        </div>
        <div>
          {tags.length
            ? tags.map((tag: Record<string, any>) => (
                <>
                  <CustomTag
                    className="mr-0 mt-1"
                    color={tag.color}
                    variant="memberTag"
                  >
                    {tag.name}
                  </CustomTag>{' '}
                </>
              ))
            : '-'}
        </div>
      </div>
    </>
  );
};

export default MemberInformation;
