import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { StyledLabel, StyledSpan, StyledSelect } from '../../styles';
import { MEMBER_STATUSES } from './utils';

interface Props {
  onChange: (e: Record<string, object> | null) => void;
  value: {
    status?: Record<string, object>;
  };
  disabled: boolean;
}

export const StatusFilter: FC<Props> = ({ value, onChange, disabled }) => {
  const values = value?.status?.in ?? [];

  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange({ in: e });
    } else {
      onChange(null);
    }
  };

  return (
    <div className="mt-1">
      <div className="d-flex justify-content-between">
        <div>
          <StyledLabel>
            <FormattedMessage id="status.text" defaultMessage="Status" />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        mode="multiple"
        allowClear
        value={values}
        showSearch
        placeholder="Enter Status"
        onChange={handleChange}
        optionFilterProp="children"
        filterOption={(input: string, option: Record<string, any>) =>
          option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
        defaultActiveFirstOption
      >
        {MEMBER_STATUSES.map((status) => (
          <Select.Option key={status.value} value={status.value}>
            {status.label}
          </Select.Option>
        ))}
      </StyledSelect>
    </div>
  );
};
