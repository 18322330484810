import styled from 'styled-components';
import { starredMembersSortBy } from './RenderStarMembers';

export const Container = styled.div`
  height: calc(100vh - 40px);
  width: 230px;
  overflow-y: auto;
  border: 1px solid #e8e8e8;
  background: white;
  padding: 16px;
  pointer-events: all;
  right: 30px;
`;

interface MenuMessageProps {
  readonly menuKey: String;
  readonly activeKey: String;
}

export const MenuMessage = styled.span<MenuMessageProps>`
  cursor: pointer;
  font-weight: ${({ menuKey, activeKey }) =>
    menuKey === activeKey ? 'bold' : '500'};
`;

export const menuLabels = {
  [starredMembersSortBy.dateRegistered]: 'date-registered.text',
  [starredMembersSortBy.memberLevel]: 'member-levels.text',
  [starredMembersSortBy.lastAdded]: 'last-added.text',
};
