import styled from 'styled-components';

export const StyledCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledContainer = styled.div`
  padding-top: 48px;
`;

export default {};
