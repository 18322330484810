import React, { FC } from 'react';
import { Button, Divider } from 'antd';
import moment, { Moment } from 'moment';
import { useFilterValues } from 'contexts/Filters';
import { useDatePresets } from 'hooks/useDatePresets';
import DateTimeRangePicker from 'components/DateTimeRangePicker';

type Props = {
  filterFieldKey: string;
  filterLabel: string;
};

const ReportPreSetDateFilters: FC<Props> = ({
  filterFieldKey,
  filterLabel,
}) => {
  const { datePreSets, setPresetValue } = useDatePresets();

  const { filters, setFilters } = useFilterValues();

  const startDateFormat = 'YYYY-MM-DDT00:00:00.000Z';
  const endDateFormat = 'YYYY-MM-DDT23:59:59.000Z';

  const handleChange = ({
    key,
    value,
  }: {
    key: string | null;
    value: Moment[] | string[] | null;
  }) => {
    setPresetValue(key);

    const isCustom = key === 'custom-date-range';

    const [start, end] = value || [];

    setFilters({
      ...filters,
      [filterFieldKey]: value
        ? {
            gte: isCustom ? start : moment(start).format(startDateFormat),
            lte: isCustom ? end : moment(end).format(endDateFormat),
          }
        : null,
    });
  };

  return (
    <div className="d-flex justify-content-center align-items-center h-100 w-100">
      <div>
        <div>
          <Divider>{filterLabel}</Divider>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{
            width: 500,
            flexWrap: 'wrap',
          }}
        >
          {datePreSets.map((datePreSet) => {
            const { key, label, value } = datePreSet;

            return (
              <Button
                onClick={() => handleChange({ key, value })}
                className="m-1"
                key={key}
              >
                {label}
              </Button>
            );
          })}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <DateTimeRangePicker
            value={
              filters[filterFieldKey]
                ? [filters[filterFieldKey].gte, filters[filterFieldKey].lte]
                : null
            }
            onChange={(e) =>
              handleChange({ key: 'custom-date-range', value: e })
            }
            label="custom-date-range"
            showPreSets={false}
            showLabel={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportPreSetDateFilters;
