import React, { ReactElement } from 'react';
import { Avatar } from 'antd';

const Circle = (props: {
  children?: ReactElement;
  size: any;
  filled: boolean;
  color: string;
  style?: Record<string, any>;
}) => {
  const { children, size, filled, color, style } = props;
  let newColor = '#000';
  switch (color) {
    case 'primary':
      newColor = '#1890FF';
      break;
    case 'success':
      newColor = '#14A371';
      break;
    case 'warning':
      newColor = '#FAAD14';
      break;
    case 'danger':
      newColor = '#E50000';
      break;
    case 'gray':
      newColor = '#d3d3d3';
      break;
    default:
      newColor = color;
  }
  return (
    <Avatar
      style={{
        ...style,
        border: `1px solid ${newColor}`,
        backgroundColor: filled ? newColor : 'transparent',
        verticalAlign: 'middle',
      }}
      size={size}
      className="mx-1"
      data-testid="deposits-status-col"
    >
      {children}
    </Avatar>
  );
};

export default Circle;
