import copy from 'copy-to-clipboard';
import { notification } from 'antd';

const copyToClipboard = (text: string) => {
  copy(text);
  notification.info({
    message: 'Copied to clipboard!',
    duration: 2,
  });
};

export default copyToClipboard;
