import React from 'react';
import {
  uniqBy,
  isEqual,
  partialRight,
  uniq,
  isBoolean,
  isNumber,
  isEmpty,
  forOwn,
  isArray,
} from 'lodash';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { DynamicObj } from 'interfaces/user.interface';
import removeNull from 'utils/removeNull';

import { getPartialKey } from 'constants/partialFilterKey';
import { WatchQueryFetchPolicy } from 'apollo-client';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import coercedGet from './coercedGet';

type FilterObjectType = DynamicObj;

type PageStateType = {
  first: number;
  after: undefined | string;
  savedCursor: (string | undefined)[];
  currentPage: number;
  sort?: string;
};

type PartialFilterStateType = {
  partialKeys: any[];
  savedFilts: FilterObjectType;
};

type FilterValueType = string | number | boolean;

export const checkPartial = (filterKey: string, state: FilterObjectType) => {
  if (
    coercedGet(state, filterKey) &&
    state[filterKey].in &&
    state[filterKey].in.length
  ) {
    return state[filterKey].in.some(
      (filter: string) =>
        filter &&
        !isBoolean(filter) &&
        !isNumber(filter) &&
        filter.includes(getPartialKey())
    );
  }

  return false;
};

export const getPartialString = (
  filtKey: string,
  filtObj: FilterObjectType
) => {
  if (coercedGet(filtObj, filtKey) && filtObj[filtKey].in.length) {
    return filtObj[filtKey].in
      .find((str: string) => str.includes(getPartialKey()))
      .slice(getPartialKey().length);
  }
  return null;
};

// purpose for this func is to extract and use the Partial string
//  from the filter object
const getProcessedPartialFilters = (
  filterObj: FilterObjectType,
  filtFields: string[]
) => {
  const newFilterObj = { ...filterObj };

  const isFiltField = (filtKey: string) => filtFields.includes(filtKey);

  return Object.entries(newFilterObj).reduce((acc, curr) => {
    const [key, value] = curr;

    if (checkPartial(key, newFilterObj)) {
      acc[key] = isFiltField(key)
        ? {
            contains: getPartialString(key, newFilterObj),
          }
        : null;
    }
    return {
      [key]: value,
      ...acc,
    };
  }, {});
};

// specific partial filter "function creator" provided the specific module

export const sortTransform = {
  ascend: 'ASC',
  descend: 'DESC',
};

export const getUnpaginatedTableData = (
  dataPath: string,
  tableData: object,
  customKey?: string
) => {
  const mainResults = coercedGet(tableData, dataPath, {});
  const partialResults = coercedGet(tableData, 'partial', {});

  const edges = coercedGet(mainResults, 'edges', []);
  const partialEdges = coercedGet(partialResults, 'edges', []);
  const combination = [...edges, ...partialEdges];
  const uniqueCombinedEdges = uniqBy(combination, `node.${customKey}`);

  const uniqueTableData = uniqueCombinedEdges.map((edge) => edge.node);

  return uniqueTableData.length ? uniqueTableData : [];
};

/**
 * @desc Remove partials from the filter passed
 * @author Milfren John dela Vega | nerflim
 * @todo unit testing
 * @param filter
 */

const removePartialFilter = (filter: FilterObjectType) => {
  const populatedFilters = removeNull(filter);

  return Object.keys(populatedFilters).reduce((acc, curr) => {
    const noPartials = {};

    forOwn(populatedFilters[curr], (value, key) => {
      noPartials[curr] = {
        ...noPartials[curr],
        [key]: isArray(value)
          ? value.filter(
              (fItem: FilterValueType) =>
                typeof fItem !== 'string' || !fItem.includes(getPartialKey())
            ) || []
          : value,
      };
    });

    return {
      ...acc,
      ...noPartials,
    };
  }, {});
};

/**
 * @desc Retain partials from the filter passed
 * @author Milfren John dela Vega | nerflim
 * @todo unit testing
 * @param filter
 */
const getPartialFilter = (
  filter: FilterObjectType,
  filtFields: string[],
  extraFilter: FilterObjectType
) => {
  const populatedFilters = removeNull(filter);

  const partials = Object.keys(populatedFilters).reduce((acc, curr) => {
    if (checkPartial(curr, populatedFilters) && filtFields.includes(curr)) {
      return {
        ...acc,
        [curr]: { contains: getPartialString(curr, populatedFilters) },
      };
    }

    return {
      ...acc,
    };
  }, {});

  return isEmpty(partials) ? partials : { ...partials, ...extraFilter };
};

export const usePartialFiltersQuery = (
  query1: DocumentNode,
  query2: DocumentNode,
  edgesPath: string, // eg. 'memberBetRecords.edges'
  rawFilters: FilterObjectType,
  pageState: PageStateType,
  filtFields: string[],
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  customKey?: string,
  extraPartialFilter: FilterObjectType = {}, // this will be added to the partial query without getting modified
  preAppliedFilter: boolean = true // to prevent initial load of query, only call query when filter is applied | used in MBR abd BTR
) => {
  const processedFilters = React.useMemo(
    () => removePartialFilter(rawFilters),
    [rawFilters]
  );

  const processedPartialFilters = React.useMemo(
    () => getPartialFilter(rawFilters, filtFields, extraPartialFilter),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rawFilters]
  );

  const mixedFilters = {
    filter: processedFilters,
    partialFilter: processedPartialFilters,
  };

  const queryPath = edgesPath.replace(/.edges/g, '');

  const initialState = {
    partialKeys: [],
    savedFilts: {},
  } as PartialFilterStateType;

  const [partialFilterState, setPartialFilterState] = React.useState(
    initialState
  );

  const { partialKeys, savedFilts } = partialFilterState;

  const { context } = useOperatorHeader();

  // customKey is used when you want to enable partial filtering using its own filter field and not the id
  // make sure to pass filter field key string to customKey props
  const filterKey = customKey || 'id';

  // query 1
  const [
    loadPartialQueries,
    { loading: loading1, error: error1 },
  ] = useLazyQuery(query1, {
    fetchPolicy,
    variables: mixedFilters,
    context,
    onError: () => {
      setPartialFilterState((prev) => ({
        ...prev,
        partialKeys: [],
      }));
    },

    onCompleted: (data: any) => {
      const preEdges = getUnpaginatedTableData(queryPath, data, filterKey);

      const queriedIdentifiers = preEdges.map((item) => item[filterKey]);

      if (queriedIdentifiers.length)
        return setPartialFilterState((prev) => ({
          ...prev,
          partialKeys: queriedIdentifiers,
        }));

      return setPartialFilterState((prev) => ({
        ...prev,
        partialKeys: [],
      }));
    },
  });

  React.useEffect(() => {
    if (
      !isEmpty(processedPartialFilters) &&
      !partialKeys.length &&
      preAppliedFilter
    ) {
      loadPartialQueries({
        variables: mixedFilters,
      });
      return;
    }
    if (isEmpty(processedPartialFilters) && partialKeys.length) {
      setPartialFilterState(initialState);
      return;
    }

    if (
      !isEmpty(processedPartialFilters) &&
      !isEqual(savedFilts, processedFilters) &&
      preAppliedFilter
    ) {
      loadPartialQueries({
        variables: mixedFilters,
      });
      setPartialFilterState((prev: PartialFilterStateType) => ({
        ...prev,
        savedFilts: processedFilters,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFilters]);

  const finalQueryFilter = React.useMemo(
    () =>
      partialKeys.length
        ? {
            [filterKey]: {
              in: [...partialKeys],
            },
          }
        : processedFilters,
    [partialKeys, processedFilters, filterKey]
  );

  const memPageState = React.useMemo(() => pageState, [pageState]);

  const refetchVariables = React.useMemo(
    () => ({
      first: memPageState.first,
      after: memPageState.after,
      filter: finalQueryFilter,
    }),
    [finalQueryFilter, memPageState.after, memPageState.first]
  );

  const {
    loading: loading2,
    error: error2,
    data: queryData = {},
    refetch,
  } = useQuery(query2, {
    variables: refetchVariables,
    fetchPolicy,
    context,
    skip: !preAppliedFilter,
  });

  return {
    data: queryData,
    loading: loading1 || loading2,
    error: error1 || error2,
    refetchVariables,
    refetch,
    finalQueryFilter,
  };
};

export const getMbrPartialFilters = partialRight(getProcessedPartialFilters, [
  'serialCode',
  'gameCategory',
  'gameSubCategory',
  'platformId',
  'brandId',
  'round',
]);

// to avoid API error when having "Partial:" with Id based filters e.g member: {in : ["Partial: "]}
// will remove partial S
const removePartialStrings = (
  filterObj: Record<string, any>,
  removeFields: Array<string>
) => {
  if (!removeFields || !removeFields.length) return filterObj;

  const newFiltObj = { ...filterObj };

  const isRemovableField = (filtKey: string) => removeFields.includes(filtKey);

  return Object.entries(newFiltObj).reduce((acc, curr) => {
    const [key, value] = curr;

    if (checkPartial(key, newFiltObj) && isRemovableField(key)) {
      const inValue = value.in.filter(
        (item: string) => !item.includes(getPartialKey())
      );

      // @ts-ignore
      acc[key] = inValue.length
        ? {
            in: inValue,
          }
        : null;
    }

    return {
      [key]: value,
      ...acc,
    };
  }, {});
};

const neutQuery = (filtObj: FilterObjectType) => {
  const newObj = Object.entries(filtObj).reduce((acc: any, curr: any) => {
    const key = curr[0];
    const val = curr[1];

    if (checkPartial(key, filtObj)) {
      const inValue = val.in.filter(
        (item: string) => !item.includes(getPartialKey())
      );
      acc[key] = {
        in: inValue,
      };
    }

    return {
      [key]: val,
      ...acc,
    };
  }, {});

  return newObj;
};

export const checkPartialExist = (procFilts: FilterObjectType) => {
  const filtKeys = Object.keys(procFilts);
  return filtKeys.some((filtKey) => checkPartial(filtKey, procFilts));
};

type IDQueryObjectType = {
  query: any;
  sourceFiltFields: Array<string>;
  targetStringFields: Array<string>;
  targetFilterFields: Array<string>;
};

export const createPartialUtil = (
  identifier?: string,
  processorFunc?: ((filt: FilterObjectType) => FilterObjectType) | null,
  fetchPolicy?: WatchQueryFetchPolicy
) => (
  query1: DocumentNode,
  query2: DocumentNode,
  edgesPath: string, // eg. 'memberBetRecords.edges'
  initialFilters: FilterObjectType,
  pageState: PageStateType,
  filtFields: string[],
  idQueryObject?: IDQueryObjectType,
  language?: string
) => {
  const hasProcessorFunc = processorFunc && typeof processorFunc === 'function';
  const { context } = useOperatorHeader();

  const procFilters = hasProcessorFunc
    ? processorFunc && processorFunc(initialFilters)
    : initialFilters;

  const memProcFilts = React.useMemo(() => procFilters, [
    procFilters,
  ]) as FilterObjectType;

  const memFilts = React.useMemo(() => initialFilters, [initialFilters]);

  // eslint-disable-next-line no-param-reassign
  identifier = identifier || 'id';

  // eslint-disable-next-line no-param-reassign
  fetchPolicy = fetchPolicy || 'cache-and-network';

  const isSerialCode = identifier === 'serialCode';

  // to circumvent API error for partial strings in ID based filter fields
  const removeIdPartials = partialRight(removePartialStrings, [
    ...coercedGet(idQueryObject as IDQueryObjectType, 'targetFilterFields', []),
  ]) as any;

  const processedFilters = removeNull(removeIdPartials(memProcFilts));

  const processedPartialFilters = removeNull(
    getProcessedPartialFilters(memProcFilts, filtFields)
  ) as any;

  const mixedFilters = {
    filter: neutQuery(processedFilters),
    partialFilter: processedPartialFilters,
  };

  const queryPath = edgesPath.replace(/.edges/g, '');

  const initialState = {
    partialKeys: [],
    savedFilts: {},
  } as PartialFilterStateType;

  const [partialFilterState, setPartialFilterState] = React.useState(
    initialState
  );

  const { partialKeys, savedFilts } = partialFilterState;

  // query 1
  const [
    loadPartialQueries,
    { loading: loading1, error: error1 },
  ] = useLazyQuery(query1, {
    fetchPolicy,
    variables: mixedFilters,
    onError: () => {
      setPartialFilterState((prev) => ({
        ...prev,
        partialKeys: [],
      }));
    },
    context,

    onCompleted: (data: any) => {
      const preEdges = getUnpaginatedTableData(queryPath, data, identifier);

      const queriedIdentifiers = isSerialCode
        ? preEdges.map(({ serialCode }) => serialCode)
        : preEdges.map(({ id }) => id);

      if (queriedIdentifiers.length)
        return setPartialFilterState((prev) => ({
          ...prev,
          partialKeys: queriedIdentifiers,
        }));

      return setPartialFilterState((prev) => ({
        ...prev,
        partialKeys: [],
      }));
    },
  });

  React.useEffect(() => {
    if (checkPartialExist(processedFilters) && !partialKeys.length) {
      loadPartialQueries({
        variables: mixedFilters,
      });

      return;
    }
    if (!checkPartialExist(processedFilters) && partialKeys.length) {
      setPartialFilterState(initialState);
      return;
    }

    if (
      checkPartialExist(processedFilters) &&
      !isEqual(savedFilts, processedFilters)
    ) {
      loadPartialQueries({
        variables: mixedFilters,
      });
      setPartialFilterState((prev: PartialFilterStateType) => ({
        ...prev,
        savedFilts: processedFilters,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memFilts]);

  // ID ============================

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [collatedIdKeyVals, setCollatedIdKeyVals] = React.useState({});

  const getQueryFiltVars = () => {
    const sourceFiltFields = coercedGet(
      idQueryObject as IDQueryObjectType,
      'sourceFiltFields',
      []
    );
    const targetStringFields = coercedGet(
      idQueryObject as IDQueryObjectType,
      'targetStringFields',
      []
    );

    const currentProcFilts = removeNull(
      getProcessedPartialFilters(memFilts, sourceFiltFields)
    ) as any;

    const procToTarget = sourceFiltFields.reduce((acc: any, curr: string) => {
      const sourceIndex = sourceFiltFields.indexOf(curr);
      const newKey = targetStringFields[sourceIndex];

      acc[newKey] = {};
      acc[newKey][newKey] = {
        in: [],
      };

      if (currentProcFilts[curr]) {
        acc[newKey] = {};
        acc[newKey][newKey] = currentProcFilts[curr];
      }

      return acc;
    }, {});

    return procToTarget;
  };

  const checkPartialOnSource = (filts: any) =>
    coercedGet(
      idQueryObject as IDQueryObjectType,
      'sourceFiltFields',
      []
    ).some((filtKey: string) => checkPartial(filtKey, filts));

  const hasPartialOnSourceFields = checkPartialOnSource(memFilts);

  const [loadIdQueries, { loading: loading2, error: error2 }] = useLazyQuery(
    idQueryObject?.query,
    {
      fetchPolicy: 'cache-and-network',
      variables: getQueryFiltVars(),
      onCompleted: (data: any) => {
        const targetStringFields = coercedGet(
          idQueryObject as IDQueryObjectType,
          'targetStringFields',
          []
        );

        const keyVals = targetStringFields.reduce(
          (acc: Record<string, any>, curr: string) => {
            const edges = coercedGet(data[curr], 'edges', []);
            const queriedIds = edges.map(({ node }: any) => node.id);
            acc[curr] = queriedIds;
            return acc;
          },
          {}
        );

        if (hasPartialOnSourceFields) setCollatedIdKeyVals(keyVals);
      },
    }
  );

  React.useEffect(() => {
    if (idQueryObject) {
      if (hasPartialOnSourceFields && !Object.keys(collatedIdKeyVals).length) {
        loadIdQueries();
      }

      if (!hasPartialOnSourceFields && Object.keys(collatedIdKeyVals).length) {
        setCollatedIdKeyVals({});
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memFilts]);

  // ID ============================

  const getFinalQueryFilter = (
    idKeys: Array<string>,
    collatedKeyVals: Record<string, any>,
    rawFilts: FilterObjectType
  ) => {
    //  in string based queries, non partial filters is accounted for in the first query
    const initialFinalQuery = {
      [identifier as string]: {
        in: uniq([...idKeys]),
      },
    };

    const hasStringBased = coercedGet(idKeys, 'length', null);

    const idBasedKeyValArray = Object.entries(collatedKeyVals);
    const hasIdBased = coercedGet(idBasedKeyValArray, 'length', null);

    const hasBoth = hasStringBased && hasIdBased;

    if (hasBoth) {
      return idBasedKeyValArray.reduce((acc, curr) => {
        const key = curr[0];
        const val = curr[1];

        const strFieldIndex = coercedGet(
          idQueryObject as IDQueryObjectType,
          'targetStringFields',
          []
        ).indexOf(key);

        const targetFiltKey = coercedGet(
          idQueryObject as IDQueryObjectType,
          'targetFilterFields',
          []
        )[strFieldIndex];

        if (acc[targetFiltKey]) {
          const prevSameKey = coercedGet(acc[targetFiltKey], 'in', []);

          return {
            ...acc,
            [targetFiltKey]: {
              in: uniq([...prevSameKey, ...val]),
            },
          };
        }

        return {
          ...acc,
          [targetFiltKey]: {
            in: uniq([...val]),
          },
        };
      }, initialFinalQuery);
    }

    if (hasIdBased) {
      // mix and match of previous regular Ids and Ids produced from Id based string query

      return idBasedKeyValArray.reduce((acc, curr) => {
        const key = curr[0];
        const val = curr[1];

        const strFieldIndex = coercedGet(
          idQueryObject as IDQueryObjectType,
          'targetStringFields',
          []
        ).indexOf(key);

        const targetFiltKey = coercedGet(
          idQueryObject as IDQueryObjectType,
          'targetFilterFields',
          []
        )[strFieldIndex];

        // in Id based ONLY filters regular filters is not accounted for in the query
        // thats why its set as initial value for reduce

        if (acc[targetFiltKey]) {
          const prevSameKey = coercedGet(acc[targetFiltKey], 'in', []);

          return {
            ...acc,
            [targetFiltKey]: {
              in: uniq([...prevSameKey, ...val]),
            },
          };
        }

        return {
          ...acc,
          [targetFiltKey]: {
            in: uniq([...val]),
          },
        };
      }, rawFilts);
    }

    if (hasStringBased) {
      return initialFinalQuery;
    }

    return rawFilts;
  };

  const finalQueryFilter = getFinalQueryFilter(
    partialKeys,
    collatedIdKeyVals,
    neutQuery(processedFilters)
  );

  const refetchVariables = {
    first: pageState.first,
    after: pageState.after,
    filter: finalQueryFilter,
    language,
    ...(pageState.sort && {
      sort: {
        direction: sortTransform[pageState.sort],
      },
    }),
  };

  const {
    loading: loading3,
    error: error3,
    data: queryData = {},
    refetch,
  } = useQuery(query2, {
    variables: refetchVariables,
    fetchPolicy,
    context,
  });

  const loading = loading1 || loading2 || loading3;
  const error = error1 || error2 || error3;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memFinalFilter = React.useMemo(() => finalQueryFilter, [
    memFilts,
    loading,
  ]);

  return {
    data: queryData,
    loading,
    error,
    refetchVariables,
    refetch,
    finalQueryFilter: memFinalFilter,
  };
};

export const usePartialFilterUtil = createPartialUtil('id');
