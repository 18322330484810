import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  GET_OPERATORS,
  GET_OPERATORS_IDS,
} from 'graphql/queries/operators.query';
import { Page } from 'interfaces/user.interface';
import { isEqual } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePermissions } from 'store/accountState';
import { StyledTagContainer } from 'styles';
import coercedGet from 'utils/coercedGet';
import { customFormatMessage } from 'utils/customFormatMessage';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import FilterConditions from './components/FilterConditions';
import NewOperator from './components/NewOperator';
import Operators from './components/Operators';
import Sidebar from './components/Sidebar';
import SearchSettingsForm from './components/SearchSettingsForm';

const pageInitState: Page = {
  after: undefined,
  savedCursor: [undefined] as any,
  currentPage: 0,
  first: 10,
};

const JsNewOperator: any = NewOperator;

const OperatorManagement = () => {
  const { formatMessage } = useIntl();

  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_ACCOUNT_MANAGEMENT
  );

  const [expandedTags, setExpandedTags] = useState(false);

  const [page, setPage] = useState<Page>(pageInitState);

  const defaultFilters = {
    username: null,
    registrationDateTime: null,
    lastLoginDateTime: null,
    permissionGroups: null,
    status: null,
  };
  const [filters, setFilters] = useState(defaultFilters);

  const handleFilters = (e: any) => {
    setFilters(e);
  };

  const handleNext = (pageInfo: any) => () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor!);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage] || undefined;
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const formattedFilters = useMemo(() => transformMovableDateFilter(filters), [
    filters,
  ]);

  const {
    loading,
    data = {} as any,
    refetchVariables,
    refetch,
  } = usePartialFiltersQuery(
    GET_OPERATORS_IDS,
    GET_OPERATORS,
    'operators.edges',
    formattedFilters,
    page,
    ['username']
  );

  const refetchQuery = async () => {
    await refetch(refetchVariables);
  };

  const edges = coercedGet(data, 'operators.edges', []);
  const totalCount = coercedGet(data, 'operators.totalCount', 0);
  const pageInfo = coercedGet(data, 'operators.pageInfo', {});
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={
            <>
              <div className="d-flex align-items-start flex-g-1">
                <ToggleSidebarHead />
                <StyledTagContainer ref={ref} isExpanded={expandedTags}>
                  <span className="ml-1">
                    {translate(messages.FILTER_CONDITIONS)}:{' '}
                  </span>{' '}
                  <FilterConditions
                    filters={filters}
                    onFilterChange={handleFilters}
                  />
                </StyledTagContainer>
                <LegacyIconToggle
                  forwardedRef={ref}
                  filters={filters}
                  expandedTags={expandedTags}
                  toggleExpandedTags={setExpandedTags}
                />
                {!isEqual(defaultFilters, filters) && (
                  <ALink
                    onClick={() => handleFilters(defaultFilters)}
                    style={{ marginTop: '0.35rem' }}
                  >
                    {translate(messages.CLEAR_ALL)}
                  </ALink>
                )}
              </div>
            </>
          }
          rightNode={
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <ColumnsFilterNew iconOnly tabId="account-management" />
              </div>
              {ALLOWED_CREATE && <JsNewOperator refetch={refetchQuery} />}
            </div>
          }
        />
        <Layout.Content
          sideBar={
            <Layout.Sidebar
              quickSearch={{
                filters,
                contextKey: 'account-management',
                onFilterChange: handleFilters,
                searchSettingsForm: SearchSettingsForm,
                quickSearchId: QuickSearchIds.OPERATOR_MANAGEMENT,
                isNext: true,
              }}
            >
              <Sidebar
                filters={filters}
                onFilterChange={handleFilters}
                initialFilters={defaultFilters}
              />
            </Layout.Sidebar>
          }
          footer={
            <Layout.Footer
              loading={false}
              onNext={handleNext(pageInfo) as any}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={data?.pageInfo}
            />
          }
        >
          <Operators
            data={edges}
            loading={loading}
            refetch={refetch as any}
            refetchVariables={refetchVariables}
          />
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

export default OperatorManagement;
