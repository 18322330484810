import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'actions.text': {
    id: 'actions.text',
    defaultMessage: 'Actions',
  },
  ACTIONS: {
    id: 'vgm-actions.text',
    defaultMessage: 'Actions',
  },
  ADVANCE_BLACKOUT: {
    id: 'vgm-scheduled-advance-blackout.text',
    defaultMessage: 'Advance Blackout',
  },
  FORCE_KICK: {
    id: 'vgm-scheduled-force-kick.text',
    defaultMessage: 'Force Kick',
  },
  MAINTENANCE: {
    id: 'vgm-scheduled-maintenance.text',
    defaultMessage: 'Maintenance',
  },
  STATUS: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  VENDOR_NAME: {
    id: 'vgm-vendor-name.text',
    defaultMessage: 'Vendor Name',
  },
});

export default messages;
