import gql from 'graphql-tag';

export const GAME_CATEGORIES = gql`
  query GameCategories(
    $language: Language
    $filter: GameCategoriesFilterInput
  ) {
    gameCategories(filter: $filter) {
      id
      name
      dateTimeCreated
      gameTypes
      platform
      rank
      vendors {
        id
        name(language: $language)
        type
        externalLobby
      }
      icon {
        id
        uri
      }
      mascotHorizontal {
        id
        uri
      }
      backgroundHorizontal {
        id
        uri
      }
    }
  }
`;

export const GET_VENDORS = gql`
  query Vendors($filter: VendorsFilterInput, $language: Language) {
    vendors(filter: $filter) {
      totalCount
      edges {
        node {
          id
          type
          name(language: $language)
          externalLobby
        }
      }
    }
  }
`;

export const GAME_CATEGORY = gql`
  query GameCategory($id: ID!, $language: Language) {
    gameCategory(id: $id) {
      id
      vendors {
        id
        name(language: $language)
        type
        externalLobby(gameCategory: $id)
        horizontalImage(gameCategory: $id) {
          uri
          id
        }
      }
    }
  }
`;

export default {};
