import gql from 'graphql-tag';

export const WITHDRAWAL_MEMBER = gql`
  query WITHDRAWAL($id: ID) {
    withdrawal(id: $id) {
      id
      account {
        id
        username
      }
    }
  }
`;

export default {};
