/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { Button, message, Modal } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { GET_CUSTOM_FILTERS } from 'graphql/queries/customFilter.query';
import { DELETE_CUSTOM_FILTER } from 'graphql/mutations/customFilter.mutation';

const DeleteCustomFilter = ({
  searchSettings,
  contextKey,
  onFiltersUpdate,
}: {
  searchSettings: Record<string, any>;
  contextKey: string;
  onFiltersUpdate: Function;
}) => {
  const [isLoading, setLoading] = useState(false);
  const translate = useTranslate();
  const [isShow, setShow] = useState(false);
  const [deleteCustomFilter] = useMutation(DELETE_CUSTOM_FILTER);

  const showModal = () => setShow(!isShow);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await deleteCustomFilter({
        variables: {
          id: searchSettings.id,
        },
        refetchQueries: [
          {
            query: GET_CUSTOM_FILTERS,
            variables: {
              filter: {
                context: {
                  eq: contextKey,
                },
              },
            },
          },
        ],
      });
      message.success(translate(messages['search-settings-deleted.text']));
      setLoading(false);

      const { id, name, context, filter } = searchSettings;
      onFiltersUpdate({
        id,
        name,
        context,
        filter,
      });
      showModal();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <a href="#/section" onClick={showModal}>
        <FormattedMessage id="delete.text" defaultMessage="Delete" />
      </a>
      {isShow && (
        <Modal
          centered
          style={{ maxWidth: 400 }}
          title={
            <FormattedMessage
              id="delete-settings.text"
              defaultMessage="Delete settings"
            />
          }
          visible
          onOk={showModal}
          onCancel={showModal}
          footer={null}
        >
          <div className="d-flex flex-column">
            <div>
              <FormattedMessage
                id="are-you-sure-you-want-to-delete.text"
                defaultMessage="Are you sure you want to delete?"
              />
            </div>
            <div className="d-flex justify-content-end mt-4">
              <Button className="mr-2" onClick={showModal} loading={isLoading}>
                <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
              </Button>{' '}
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                onClick={handleSubmit}
              >
                <FormattedMessage
                  id="save-changes.text"
                  defaultMessage="Save changes"
                />
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteCustomFilter;
