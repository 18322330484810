export enum withdrawalProvidersTestId {
  modal = 'add-withdrawal-provider-modal',
  providerNameFormItem = 'provider-name-formitem',
  providerTypeFormItem = 'provider-type-formitem',
  credentialsFormItem = 'primary-credential-formitem',
  merchantCodeFormItem = 'merchant-code-formitem',
  createButton = 'add-provider-button',
  apiKeyConfirmButton = 'api-key-confirm-button',
  selectPaymentGateways = 'select-payment-gateways',
  gatewayOption = 'gateway-option-',
  credentialInput = 'credentials-',
  submitButton = 'submit-button',
  midpayRadioBtn = 'midpay-radio-btn',
  diorpayRadioBtn = 'diorpay-radio-btn',
  table = 'third-party-withdrawal-providers-table',
  providerNameInput = 'provider-name-input',
  credentialsInput = 'credentials-input',
  merchantCodeInput = 'merchant-code-input',
  // ==== actions
  actionsDropdown = 'actions-dropdown',
  deleteProviderBtn = 'delete-provider-btn',
  duplicateProviderBtn = 'duplicate-provider-btn',
  // editPoviderBtn = 'edit-provider-btn',
  deleteModal = 'delete-modal',
  duplicateModal = 'duplicate-modal',
  deleteError = 'delete-error',
  duplicateError = 'duplicate-error',
  editError = 'edit-error',
  // === filters
  sideBar = 'sideBar',
  nameFilter = 'nameFilter',
}
