import gql from 'graphql-tag';

export const UPDATE_VENDOR_SETTING = gql`
  mutation updateVendorSettings(
    $vendor: VendorCode!
    $admin: ID!
    $context: VendorSettingsContext!
    $input: UpdateVendorSettingsInput!
  ) {
    updateVendorSettings(
      vendor: $vendor
      admin: $admin
      context: $context
      input: $input
    )
  }
`;

export default {};
