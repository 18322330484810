import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'agent-affiliate-programme.text': {
    id: 'agent-affiliate-programme.text',
    defaultMessage: 'Agent Affiliate Programme',
  },
  'agent-affiliate.create-affiliate-programme-saved.text': {
    id: 'agent-affiliate.create-affiliate-programme-saved.text',
    defaultMessage: 'The affiliate programme has been saved successfully.',
  },
  'agent-affiliate.create-affiliate-programme-updated.text': {
    id: 'agent-affiliate.create-affiliate-programme-updated.text',
    defaultMessage: 'The affiliate programme has been updated successfully.',
  },
  'agent-affiliate.create-affiliate-programme-published.text': {
    id: 'agent-affiliate.create-affiliate-programme-published.text',
    defaultMessage: 'The affiliate programme has been published successfully.',
  },
  'agent-affiliate.create-affiliate-programme-error.text': {
    id: 'agent-affiliate.create-affiliate-programme-error.text',
    defaultMessage: 'There were errors processing your request.',
  },
  'agent-affiliate.existing-default-affiliate-programme-error.text': {
    id: 'agent-affiliate.existing-default-affiliate-programme-error.text',
    defaultMessage: 'A default programme already exists.',
  },
  'agent-affiliate.no-tiers-affiliate-programme-error.text': {
    id: 'agent-affiliate.no-tiers-affiliate-programme-error.text',
    defaultMessage: 'Please provide at least one completed tier.',
  },
  'agent-affiliate.no-complete-tier-details-affiliate-programme-error.text': {
    id:
      'agent-affiliate.no-complete-tier-details-affiliate-programme-error.text',
    defaultMessage: 'Please complete tier details.',
  },
  'cost-settings.text': {
    id: 'cost-settings.text',
    defaultMessage: 'Cost Settings',
  },
  'settlement-period.text': {
    id: 'settlement-period.text',
    defaultMessage: 'Settlement Period',
  },
  'tier-settings.text': {
    id: 'tier-settings.text',
    defaultMessage: 'Tier Settings',
  },
});

export default messages;
