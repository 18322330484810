import FiltersProvider from 'contexts/Filters';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import { MEMBER_LOYALTY_PROGRAMS_FULL_QUERY } from 'graphql/queries/vip.query';
import React, { useState } from 'react';
import { StyledContent, StyledLayout } from 'styles';
import Content from './components/Content';
import CreateLoyaltyProgram from './components/CreateProgramme';
import {
  CreateLoyaltyProgramProvider,
  PrimaryVipProvider,
} from './components/CreateProgramme/context';
import Header from './components/Header';
import ProgrammeDetails from './components/ProgrammeDetails';
import Sidebar from './components/Sidebar';
import { allTypes, useMemberLoyaltyProgrammeContext } from './context';

const initialValues = {
  name: null,
  status: null,
};

function MemberLoyaltyManagement() {
  const [isOverlay, setIsOverlay] = useState(false);

  const handleOverlay = React.useCallback(
    () => setIsOverlay((state) => !state),
    [setIsOverlay]
  );

  const [memberLoyaltyState] = useMemberLoyaltyProgrammeContext();
  const { activeScreen } = memberLoyaltyState;

  const tableRef = React.useRef<HTMLElement | null>(null);

  return (
    <SpreadsheetProvider
      options={{
        query: MEMBER_LOYALTY_PROGRAMS_FULL_QUERY,
        edgesPath: 'memberLoyaltyProgrammes.edges',
      }}
    >
      <FiltersProvider initialValues={initialValues}>
        <CreateLoyaltyProgramProvider>
          <PrimaryVipProvider>
            {activeScreen === allTypes.PROGRAM_TABLE_LISTING && (
              <StyledLayout>
                <StyledContent>
                  <Header ref={tableRef} toggleOverlay={handleOverlay} />
                  <div className="d-flex flex-1">
                    <Sidebar />
                    <Content toggleOverlay={handleOverlay} ref={tableRef} />
                  </div>
                </StyledContent>
              </StyledLayout>
            )}
            {activeScreen === allTypes.PROGRAMME_DETAILS && (
              <ProgrammeDetails toggleOverlay={handleOverlay} />
            )}
            <CreateLoyaltyProgram
              show={isOverlay}
              toggleOverlay={handleOverlay}
            />
          </PrimaryVipProvider>
        </CreateLoyaltyProgramProvider>
      </FiltersProvider>
    </SpreadsheetProvider>
  );
}

export default MemberLoyaltyManagement;
