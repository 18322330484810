import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Button, message, Radio, Spin, Tooltip } from 'antd';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useScreenTabV2 } from 'store/screenTabState';
import styled from 'styled-components';
import {
  CONFIG as MEMBER_CONFIG,
  Form,
  getRows,
  PS_KEYS,
  UPDATE_CONFIG,
} from 'utils/profileSetting';
import useTranslate from 'utils/useTranslate';
import { CONFIG, getColumns } from './utils';

export const AffiliateCriteriaNew: React.FC = () => {
  const { updateTab, useUpdateTab } = useScreenTabV2('affiliate-criteria');
  const translate = useTranslate();
  const [forms, setForms] = useState<Form[]>([]);
  const [updateConfig, { loading: mutating }] = useMutation(UPDATE_CONFIG);

  const [loadMembers, { loading: loadingMembers }] = useLazyQuery(
    MEMBER_CONFIG,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data: { config: { memberFormFields: Form[] } }) => {
        const memberForms = data.config.memberFormFields;
        const affiliates: Form[] = [];

        forms.forEach((form: Form) => {
          if (form) {
            let newForm = { ...form }!;
            const match = memberForms.find(
              (member: Form) => member.field === form.field
            );

            if (match) {
              newForm = {
                ...newForm,
                disabled: { optional: match?.required === true, hidden: true },
              };
            }

            affiliates.push(newForm);
          }
        });

        setForms(affiliates);
      },
    }
  );

  const [loadForms, { called, loading }] = useLazyQuery(CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (data: { config: { affiliateRequestFormFields: Form[] } }) => {
      const affiliatesForms = data.config.affiliateRequestFormFields;
      const formattedForms: Form[] = [];

      getRows(translate).forEach(({ key: field, translation }) => {
        const form: Form | undefined = affiliatesForms.find(
          (item: Form) => item.field === field
        );

        const otherValues = {
          translation,
          type: 'BUILT_IN',
        };

        if (form) {
          formattedForms.push({ ...form, ...otherValues });
        } else {
          formattedForms.push({
            field,
            required: undefined,
            updatable: false,
            otpVerificationRequired: false,
            ...otherValues,
          });
        }
      });

      setForms(formattedForms);
      loadMembers();
    },
  });

  useUpdateTab(() => loadForms());

  const handleSwitch = (index: number, value: boolean | undefined) => {
    const thisForm = [...forms];
    thisForm[index].required = value;
    setForms(thisForm);
  };

  const handleSave = async () => {
    const formattedForms = forms.map((item) => ({ ...item }));
    const values: Form[] = [];

    Object.values(PS_KEYS).forEach((field: string) => {
      const form: Form = formattedForms.find(
        (item: Form) => item.field === field
      )!;

      if (form?.required !== undefined) {
        delete form.translation;
        delete form.__typename;
        delete form.disabled;

        values.push(form);
      }
    });

    try {
      await updateConfig({
        variables: {
          input: {
            affiliateRequestFormFields: values,
          },
        },
      });
      message.success(translate(messages['successfully-saved-changes.text']));
      updateTab({ id: 'profile-setting', updates: {} });
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  if (!called) loadForms();

  return (
    <Container>
      {loading || loadingMembers ? (
        <Spin className="spinner" />
      ) : (
        <>
          <div className="row">
            {getColumns(translate).map((column: any) => (
              <div className="col">
                {column.translation}{' '}
                {column?.tooltip && (
                  <Tooltip
                    className="ml-1"
                    placement="bottom"
                    title={column.tooltip}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
          <div className="d-flex flex-column justify-content-center">
            {forms.map((form: Form, index: number) => (
              <div className="row">
                <div className="col">{form.translation}</div>
                <Radio
                  className="col radio"
                  checked={form?.required}
                  onChange={() => handleSwitch(index, true)}
                />
                <Radio
                  className="col radio"
                  checked={form?.required === false}
                  onChange={() => handleSwitch(index, false)}
                  disabled={form?.disabled?.optional}
                />
                <Radio
                  className="col radio"
                  checked={form?.required === undefined}
                  onChange={() => handleSwitch(index, undefined)}
                  disabled={form?.disabled?.hidden}
                />
              </div>
            ))}
            <div
              className="d-flex justify-content-end p-3"
              style={{ marginRight: 'calc(50vw - 320px)' }}
            >
              <Button
                type="primary"
                onClick={handleSave}
                disabled={loading || mutating}
              >
                <FormattedMessage id="save.text" defaultMessage="Save" />
              </Button>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 80px);
  background: white;
  overflow: auto;

  .row {
    display: flex;
    width: 1200px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    margin: auto;
    margin-bottom: 30px;

    :first-child {
      font-weight: 600;
      padding-top: 80px;
    }

    .radio {
      margin: 0;
    }

    .check {
      width: 16px;
      margin: auto;
    }

    .ant-switch-checked {
      background-color: #52c41a;
    }
  }

  .col {
    width: 240px;

    :first-child {
      text-align: right;
    }
  }

  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
