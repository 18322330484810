import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withErrorHandler } from 'components/ErrorHandler';
import { usePermissions } from 'store/accountState';
import { includes } from 'lodash';
import { TOTAL_LINE_DATA } from '../../queries';
import LineStat from './components/LineStat';
// import NewMembersTable from './components/NewMembersTable';

import { StyledContent, StyledBlock, StyledCard, StyledArrow } from './styles';

const getStatPercentage = (current: number, previous: number) => {
  const percentValue = ((current - previous) / previous) * 100 || 0;
  if (previous === 0 || !previous) {
    return 'N/A';
  }
  return (Number.isFinite(percentValue) ? percentValue : 0).toFixed(2);
};

const LineStats = (props: {
  filter: Record<string, any>;
  pollInterval: number;
}) => {
  const { filter, pollInterval } = props;

  const { start, end, prevStartDate, prevEndDate } = filter || {};

  const { permissions } = usePermissions();
  const ALLOWED_MEMBERS_ONLINE = includes(
    permissions,
    'MEMBERS:MEMBERS_ONLINE'
  );

  const { data: totalPrevValues = {}, error } = useQuery(TOTAL_LINE_DATA, {
    variables: {
      startDateTime: start.format(),
      endDateTime: end.format(),
      startDateTimePrev: prevStartDate.format(),
      endDateTimePrev: prevEndDate.format(),
      withMemberOLPermission: ALLOWED_MEMBERS_ONLINE,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    pollInterval,
    onError: (err) => {
      throw err;
    },
  }) as any;

  let {
    totalLiveOnlineMembersCount,
    totalOnlineMembersCount,
    totalOnlineMembersCountPrev,
    totalOnlineMembersCountChart,
    totalMemberRegistrationsCount,
    totalMemberRegistrationsCountPrev,
    totalMemberRegistrationsCountChart,
    totalFirstDepositsCount,
    totalFirstDepositsCountPrev,
    totalFirstDepositsCountChart,
  } = totalPrevValues;

  if (error) {
    totalLiveOnlineMembersCount = {};
    totalOnlineMembersCount = {};
    totalOnlineMembersCountPrev = {};
    totalOnlineMembersCountChart = [];
    totalMemberRegistrationsCount = 0;
    totalMemberRegistrationsCountPrev = 0;
    totalMemberRegistrationsCountChart = [];
    totalFirstDepositsCount = 0;
    totalFirstDepositsCountPrev = 0;
    totalFirstDepositsCountChart = [];
  }

  const finalOnlineMembersCount =
    filter.menu === 'today'
      ? totalLiveOnlineMembersCount?.totalCount
      : totalOnlineMembersCount?.totalCount;

  return (
    <div>
      <StyledContent className="pt-5">
        <StyledCard>
          <StyledBlock className="mx-2">
            <LineStat
              langId="dashboard.linestats.number-of-new-member.text"
              filter={filter}
              total={totalMemberRegistrationsCount}
              percentage={getStatPercentage(
                totalMemberRegistrationsCount,
                totalMemberRegistrationsCountPrev
              )}
              chart={totalMemberRegistrationsCountChart}
            />
          </StyledBlock>
          <StyledBlock className="mx-2">
            <LineStat
              langId="dashboard.linestats.first-deposit-counts.text"
              filter={filter}
              total={totalFirstDepositsCount}
              percentage={getStatPercentage(
                totalFirstDepositsCount,
                totalFirstDepositsCountPrev
              )}
              chart={totalFirstDepositsCountChart}
            />
          </StyledBlock>
          <StyledBlock className="mx-2">
            <LineStat
              langId="dashboard.linestats.total-number-of-members-online.text"
              filter={filter}
              total={finalOnlineMembersCount || 0}
              percentage={getStatPercentage(
                finalOnlineMembersCount || 0,
                totalOnlineMembersCountPrev?.totalCount || 0
              )}
              chart={totalOnlineMembersCountChart}
            />
            <StyledArrow />
          </StyledBlock>
        </StyledCard>
      </StyledContent>
      {/* <StyledBackground>
        <StyledContent>
          <NewMembersTable filter={filter} />
        </StyledContent>
      </StyledBackground> */}
    </div>
  );
};

export default withErrorHandler(LineStats, true);
