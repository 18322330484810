import React, { useEffect } from 'react';
import {
  IpAddressSummaryReportItemsConnectionEdge,
  IpAddressSummaryReportItem,
  IpAddressLocation,
} from 'types/graphqlTypes';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';
import globalMsgs from 'messages';
import { StyledTable } from 'constants/styles';
import { columnAlign } from 'utils/tableAlignment';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { useScreenTabV2 } from 'store/screenTabState/screenTabState';
import { IP_ADDRESS_SUMMARY } from 'constants/testIds';
import IpAddressLocationTableCell from 'components/IpAddressLocationTableCell';
import { formatDate } from 'utils/dateUtils';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';

type Props = {
  dataSource: Array<IpAddressSummaryReportItemsConnectionEdge | []>;
  loading: boolean;
};

const IPAddressTable = ({ dataSource, loading }: Props) => {
  const translate = useTranslate();
  const { addTab } = useScreenTabV2();

  const { setTableColumns } = useSpreadsheetContext();

  const renderIpLocation = ({ city, state, country }: IpAddressLocation) => {
    const keys = [city, state, country];

    const cleanLocation = keys.reduce(
      (currentName, key, index) =>
        `${currentName}${key || ''}${
          keys.length !== index + 1 && key ? ', ' : ''
        }`,
      ''
    );

    return cleanLocation;
  };

  const openMemberIpSummary = (ipAddress: string) => {
    addTab({
      id: 'member-ip-address-summary',
      state: {
        ipAddress,
      },
    });
  };

  const columns = [
    {
      csvData: {
        label: translate(globalMsgs.IP_ADDRESS),
        key: 'ipAddress',
        renderCell: ({ ipAddress }: IpAddressSummaryReportItem) => ipAddress,
      },
      title: translate(globalMsgs.IP_ADDRESS),
      align: columnAlign.onCenter,
      key: 'ipAddress',
      dataIndex: 'ipAddress',
    },
    {
      csvData: {
        label: translate(globalMsgs.IP_ADDRESS_LOCATION),
        key: 'ipAddressLocation',
        renderCell: ({ ipAddressLocation }: IpAddressSummaryReportItem) =>
          renderIpLocation(ipAddressLocation),
      },
      title: translate(globalMsgs.IP_ADDRESS_LOCATION),
      align: columnAlign.onCenter,
      key: 'ipAddressLocation',
      dataIndex: 'ipAddressLocation',
      render: (ipAddressLocation: IpAddressLocation) => (
        <IpAddressLocationTableCell location={ipAddressLocation} />
      ),
    },
    {
      csvData: {
        label: translate(globalMsgs.LAST_TIME_IP_USED),
        key: 'lastUsedDateTime',
        renderCell: ({ lastUsedDateTime }: IpAddressSummaryReportItem) =>
          formatDate(lastUsedDateTime),
      },
      title: translate(globalMsgs.LAST_TIME_IP_USED),
      align: columnAlign.onCenter,
      key: 'lastUsedDateTime',
      dataIndex: 'lastUsedDateTime',
      render: (lastUsedDateTime: string) =>
        lastUsedDateTime ? formatDate(lastUsedDateTime) : '-',
    },
    {
      csvData: {
        label: translate(globalMsgs.MEMBERS_TOTAL_COUNT),
        key: 'membersCount',
        renderCell: ({ membersCount }: IpAddressSummaryReportItem) =>
          membersCount,
      },
      title: translate(globalMsgs.MEMBERS_TOTAL_COUNT),
      align: columnAlign.onCenter,
      key: 'membersCount',
      render: ({ ipAddress, membersCount }: IpAddressSummaryReportItem) => (
        <ALink onClick={() => openMemberIpSummary(ipAddress)}>
          {membersCount}
        </ALink>
      ),
    },
  ];

  const { filterColumns } = useCustomColumnsV2('ip-address-summary', columns);

  const data = dataSource.map(({ node }: any) => ({ ...node, key: node.id }));

  useEffect(() => {
    if (!loading) {
      setTableColumns(filterColumns(columns));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <StyledTable
      data-testid={IP_ADDRESS_SUMMARY.table}
      size="small"
      loading={loading}
      columns={filterColumns(columns)}
      dataSource={data}
      pagination={false}
      rowKey="id"
    />
  );
};

export default IPAddressTable;
