import removeNull from '../../../../utils/removeNull';
import coercedGet from '../../../../utils/coercedGet';
import {
  formatDate,
  transformMovableDateFilter,
} from '../../../../utils/dateUtils';
import {
  IMember,
  IMemberLevel,
  IMemberLoyaltyLevel,
  ISystemMessageFilter,
} from './interfaces';
import { Page } from '../../../../interfaces/user.interface';

const movableDateTransform = (page: Page<ISystemMessageFilter>) =>
  transformMovableDateFilter({
    schedule: {
      gte: coercedGet(page, 'filter.schedule.gte'),
      lte: coercedGet(page, 'filter.schedule.lte'),
    },
  });

export const refetchVariables = (page: Page<ISystemMessageFilter>) => {
  const transformedDate = movableDateTransform(page);
  return {
    ...(page.after ? { after: page.after } : null),
    first: page.first,
    filter: removeNull(
      {
        ...page.filter,
        schedule: removeNull(
          {
            gte:
              coercedGet(transformedDate, 'schedule.gte', null) &&
              formatDate(
                coercedGet(transformedDate, 'schedule.gte'),
                'YYYY-MM-DD'
              ),
            lte:
              coercedGet(transformedDate, 'schedule.lte', null) &&
              formatDate(
                coercedGet(transformedDate, 'schedule.lte'),
                'YYYY-MM-DD'
              ),
          },
          true
        ),
        targetMembers: page!.filter!.targetMembers
          ? {
              includesAny: page!.filter!.targetMembers.in.map(
                (member: IMember) => member.id
              ),
            }
          : null,
        targetMemberLoyaltyLevels: page!.filter!.targetMemberLoyaltyLevels
          ? {
              includesAny: page!.filter!.targetMemberLoyaltyLevels.in.map(
                (member: IMemberLoyaltyLevel) => member.id
              ),
            }
          : null,
        targetMemberLevels: page!.filter!.targetMemberLevels
          ? {
              includesAny: page!.filter!.targetMemberLevels.in.map(
                (member: IMemberLevel) => member.id
              ),
            }
          : null,
        excludedMembers: page!.filter!.excludedMembers
          ? {
              includesAny: page!.filter!.excludedMembers.in.map(
                (member: IMember) => member.id
              ),
            }
          : null,
        excludedMemberLoyaltyLevels: page!.filter!.excludedMemberLoyaltyLevels
          ? {
              includesAny: page!.filter!.excludedMemberLoyaltyLevels.in.map(
                (member: IMemberLoyaltyLevel) => member.id
              ),
            }
          : null,
        excludedMemberLevels: page!.filter!.excludedMemberLevels
          ? {
              includesAny: page!.filter!.excludedMemberLevels.in.map(
                (member: IMemberLevel) => member.id
              ),
            }
          : null,
      },
      true
    ),
  };
};
