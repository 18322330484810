import React from 'react';
import { checkPermission } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import { showPermissionError } from 'components/Navbar/Navbar';
import ALL_PERMISSIONS from 'constants/permissions3';
import { get } from 'lodash';
import { useScreenTabV2 } from 'store/screenTabState';
import { ALink } from 'components/ALink/ALink';
import { MemberManagementFilterFormat } from 'utils/filterFormats/MemberManagement/MemberManagementFilterFormat';

function MemberCount({ node }: { node: Record<string, any> }) {
  const { addTab } = useScreenTabV2();
  const count = get(node, 'membersCount', '-');

  const { role, permissions } = usePermissions();
  const isAllowed = checkPermission(
    role,
    permissions,
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_LIST
  );

  const handleClick = async () => {
    if (isAllowed) {
      const loyaltyLevelsFormatted = node.levels.map(
        (loyaltyLevel: Record<string, any>) => ({
          id: loyaltyLevel.id,
          color: loyaltyLevel.color,
          name: loyaltyLevel.name,
          programme: {
            id: node.id,
            name: node.name,
          },
        })
      );

      const filterFormatLoyaltyLevels = MemberManagementFilterFormat.memberLoyalty(
        loyaltyLevelsFormatted,
        true
      );
      addTab({
        id: 'member-management',
        state: {
          filter: {
            memberLoyaltyLevel: {
              in: filterFormatLoyaltyLevels,
            },
          },
        },
      });
    } else {
      showPermissionError('Member Management - View');
    }
  };

  return (
    <div>
      <ALink onClick={handleClick}>{count || 0}</ALink>
    </div>
  );
}

export default MemberCount;
