import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Tag } from 'antd';
import { defineMessages } from 'react-intl';
import messages from 'messages';
import removeNull from 'utils/removeNull';
import { DynamicObj } from 'interfaces/user.interface';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import MemberTagList from './MemberTagList';
import { CREATE_MEMBER_TAG } from './mutations';
import { MEMBER_TAGS } from './MemberTagList/queries';
import { StyledColorSquare } from '../../styles';

const { Item } = Form;

const localMessages = defineMessages({
  'member-tag-created.text': {
    id: 'member-tag-created.text',
    defaultMessage: 'Member tag created',
  },
});

type Props = {
  onSelect: (e: Record<string, any>) => void;
  value: DynamicObj[];
  handleRemoveTag: (e: string) => void;
};

const AddMemberTag = ({ onSelect, value, handleRemoveTag }: Props) => {
  const [createMemberTag] = useMutation(CREATE_MEMBER_TAG);
  const [input, setInput] = useState({
    name: null,
    description: null,
    color: '#d3d3d3',
  });
  const translate = useTranslate();

  const { context } = useOperatorHeader();
  const handleCreateMemberTag = async () => {
    try {
      await createMemberTag({
        variables: { input: removeNull(input) },
        refetchQueries: [
          {
            query: MEMBER_TAGS,
            variables: {
              first: 12,
              filter: {
                name: {
                  startsWith: '',
                },
              },
            },
          },
        ],
        context,
      });

      message.success(translate(localMessages['member-tag-created.text']));

      await setInput({
        name: null,
        description: null,
        color: '#d3d3d3',
      });
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  const handleOnChange = (e: Record<string, any>) => {
    setInput({
      ...input,
      name: e.target.value,
    });
  };

  const handleColor = (e: string) => {
    setInput({
      ...input,
      color: e,
    });
  };

  const handleSubmit = () => handleCreateMemberTag();

  return (
    <div style={{ height: '220px', overflowY: 'auto' }}>
      <div className="p-1">
        <Item className="mb-0">
          <Input
            onChange={handleOnChange}
            value={input.name || ''}
            placeholder={translate(messages['please-enter-a-label.text'])}
          />
        </Item>
      </div>
      <div className="my-2">
        {value.map((item: Record<string, any>) => (
          <Tag
            closable
            key={item.id}
            onClick={() => handleRemoveTag(item.id)}
            onClose={() => handleRemoveTag(item.id)}
            className="mb-1"
          >
            <StyledColorSquare color={item.color} />
            <span>{item.name}</span>
          </Tag>
        ))}
      </div>
      <MemberTagList
        onSelect={onSelect}
        value={value}
        input={input}
        handleColor={handleColor}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default AddMemberTag;
