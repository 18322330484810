import gql from 'graphql-tag';

export const UPDATE_AUTOMATED_MESSAGE = gql`
  mutation UpdateAutomatedMessage(
    $id: ID!
    $input: UpdateAutomatedMessageInput!
  ) {
    updateAutomatedMessage(id: $id, input: $input)
  }
`;
