import React, { useEffect } from 'react';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';
import useTranslate from 'utils/useTranslate';

interface Props {
  bankID: string;
  refetchID: string;
  getBank: any;
  formatMessage: any;
  bankMessages: any;
}

const WithdrawalBankName = ({
  bankID,
  refetchID,
  getBank,
  bankMessages,
}: Props) => {
  const {
    bankNameLoaderSet: [useBankNameLoader],
  } = useLoaders();

  const { loading, error, data = {}, refetch } = useBankNameLoader(bankID);
  const translate = useTranslate();

  useEffect(() => {
    if (refetchID === bankID) {
      refetch();
    }
  }, [refetchID, bankID, refetch]);

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;

  const { bank = null, nickname = null } = data;
  if (bank) {
    const newBank = getBank(bank);
    return (
      <div className="d-flex align-items-center">
        <img
          height={20}
          width={20}
          src={newBank.logo}
          alt="logo"
          className="mr-1"
        />
        <div>
          {newBank.label !== 'OTHER' ? `[${newBank.label}] ` : ''}
          {translate(bankMessages[newBank.label])}
        </div>
      </div>
    );
  }
  if (nickname) {
    return nickname;
  }

  return '-';
};

export default WithdrawalBankName;
