import React from 'react';
import { Row, Col, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import { usePMAContext } from '../context';

export const PMARemarks: React.FC = () => {
  const {
    form: { control, errors },
  } = usePMAContext();

  return (
    <Row className="mt-2" gutter={16}>
      <Col span={12}>
        {/* PLAYER REMARKS */}
        <span className="fs-13">
          <FormattedMessage
            id="player-remarks.text"
            defaultMessage="Player Remarks"
          />
        </span>

        <Controller
          as={Input.TextArea}
          control={control}
          name="playerRemarks"
          rules={{ required: true, maxLength: 32 }}
          style={{ resize: 'none' }}
          rows={4}
        />

        <span style={{ color: 'red' }}>{errors.playerRemarks?.message}</span>
      </Col>

      <Col span={12}>
        {/* BALANCE ADJUSTMENT REMARKS */}
        <span className="fs-13">
          <FormattedMessage
            id="balance-adjustment-remarks.text"
            defaultMessage="Balance Adjustment Remarks"
          />
        </span>

        <Controller
          as={Input.TextArea}
          control={control}
          name="remarks"
          rows={4}
          style={{ resize: 'none' }}
        />

        <span style={{ color: 'red' }}>{errors.remarks?.message}</span>
      </Col>
    </Row>
  );
};
