import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Radio } from 'antd';
import { Formik } from 'formik';
import MemberTagsPreviousNew from 'components/MemberTagsPreviousNew';
import MemberTagsSelect from 'components/MemberTagsSelect';
import { FormattedMessage } from 'react-intl';
import { StyledForm } from './styles';
import validationSchema from './validationSchema';

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (e: any) => void;
  onClose: () => void;
  isLoading: boolean;
  setDeletedTags: Function;
};

const EditTagMenuForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
  setDeletedTags,
}: Props) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({
      handleSubmit,
      handleChange,
      setFieldValue,
      values,
      errors,
      touched,
      resetForm,
    }) => (
      <StyledForm onSubmit={handleSubmit}>
        <div className="p-3">
          <Form.Item
            label={
              <FormattedMessage id="add-remove.text" defaultMessage="Type" />
            }
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            validateStatus={errors.type && touched.type ? 'error' : ''}
            help={touched.type ? errors.type : null}
          >
            <Radio.Group
              disabled
              name="type"
              onChange={handleChange}
              value={values.type || ''}
            >
              <Radio value="ADD_REMOVE">
                <FormattedMessage
                  id="add-or-remove.text"
                  defaultMessage="Add/Remove"
                />
              </Radio>
              <Radio value="EDIT">
                <FormattedMessage id="edit.text" defaultMessage="Edit" />
              </Radio>
            </Radio.Group>
          </Form.Item>
          {values.type === 'ADD_REMOVE' ? (
            <Form.Item
              label={
                <FormattedMessage id="labels.text" defaultMessage="Labels" />
              }
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              validateStatus={errors.tags && touched.tags ? 'error' : ''}
              help={touched.tags ? errors.tags : null}
            >
              <MemberTagsSelect
                value={values.tags}
                onChange={(e) => setFieldValue('tags', e)}
                setDeletedTags={setDeletedTags}
              />
            </Form.Item>
          ) : (
            <Form.Item
              label={<FormattedMessage id="edit.text" defaultMessage="Edit" />}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              validateStatus={errors.tags && touched.tags ? 'error' : ''}
              help={touched.tags ? errors.tags : null}
            >
              <MemberTagsPreviousNew
                onChange={(e) => setFieldValue('tags', e)}
              />
            </Form.Item>
          )}
        </div>
        <div className="bt-1 p-3 text-right">
          <Button
            className="mr-2"
            onClick={() => {
              onClose();
              resetForm();
              setDeletedTags([]);
            }}
            loading={isLoading}
          >
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </div>
      </StyledForm>
    )}
  </Formik>
);

export default EditTagMenuForm;
