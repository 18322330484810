import React from 'react';
import { Row, Col } from 'antd';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { formatDate } from 'utils/dateUtils';
import { MemberSummaryReportFilter } from 'types/graphqlTypes';
import styled from 'styled-components';
import { DATE_TIME_FORMAT } from 'constants/date';

const StyledDiv = styled.div`
  width: 100%;
  overflow-x: hide;
`;

type Props = {
  filter: MemberSummaryReportFilter & { timezone: string };
};

const FilterTooltip: React.FC<Props> = ({ filter }) => {
  const translate = useTranslate();
  const {
    startDateTime,
    endDateTime,
    members,
    gameTypes,
    vendors,
    timezone,
    isAffiliate,
    isAffiliateUpline,
    affiliates,
    memberLoyaltyLevels,
    memberLevels,
    memberTags,
    registrationDateTime,
    lastLoginDateTime,
    dateOfBirthDateTime,
    memberStatus,
    totalBalance,
  } = filter;

  return (
    <StyledDiv className="p-2">
      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.START_END_DATE_TIME)}:{' '}
        </Col>
        <Col span={12}>
          <span>
            {formatDate(startDateTime, DATE_TIME_FORMAT)} - <br />
            {formatDate(endDateTime, DATE_TIME_FORMAT)}
          </span>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.MEMBERS)}:{' '}
        </Col>
        <Col span={12}>
          {members?.map((member) => ` ${member.username}`).toString() || '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.GAME_CATEGORY)}:{' '}
        </Col>
        <Col span={12}>
          {gameTypes
            ?.map((gameType) => ` ${translate(messages[`${gameType}`])}`)
            .toString() || '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.GAME_VENDOR)}:{' '}
        </Col>
        <Col span={12}>
          {vendors?.map((vendor) => ` ${vendor.name}`).toString() || '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.TIMEZONE_SELECTION)}:{' '}
        </Col>
        <Col span={12}>{timezone}</Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.AFFILIATE_FLAG)}:{' '}
        </Col>
        <Col span={12}>
          {isAffiliate
            ?.map(
              (item) =>
                ` ${item ? translate(messages.YES) : translate(messages.NO)}`
            )
            .toString() || '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.AFFILIATE_UPLINE)}:{' '}
        </Col>
        <Col span={12}>
          {isAffiliateUpline
            ?.map(
              (item) =>
                ` ${item ? translate(messages.YES) : translate(messages.NO)}`
            )
            .toString() || '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.AFFILIATES)}:{' '}
        </Col>
        <Col span={12}>
          {affiliates
            ?.map((affiliate) => ` ${affiliate.username}`)
            .toString() || '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.VIP_LEVEL)}:{' '}
        </Col>
        <Col span={12}>
          {memberLoyaltyLevels
            ?.map(
              (memberLoyaltyLevel) =>
                ` ${memberLoyaltyLevel.programme?.name || ''}-${
                  memberLoyaltyLevel.name
                }`
            )
            .toString() || '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.MEMBER_MARKER)}:{' '}
        </Col>
        <Col span={12}>
          {memberLevels
            ?.map((memberLevel) => ` ${memberLevel.name}`)
            .toString() || '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.MEMBER_LABEL)}:{' '}
        </Col>
        <Col span={12}>
          {memberTags?.map((memberTag) => ` ${memberTag.name}`).toString() ||
            '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.REGISTRATION_START_END_DATE_TIME)}:{' '}
        </Col>
        <Col span={12}>
          {registrationDateTime ? (
            <span>
              {formatDate(registrationDateTime?.start, DATE_TIME_FORMAT)} -{' '}
              <br />
              {formatDate(registrationDateTime?.end, DATE_TIME_FORMAT)}
            </span>
          ) : (
            '-'
          )}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.LAST_LOGIN_START_END_DATE_TIME)}:{' '}
        </Col>
        <Col span={12}>
          {lastLoginDateTime ? (
            <span>
              {formatDate(lastLoginDateTime?.start, DATE_TIME_FORMAT)} - <br />
              {formatDate(lastLoginDateTime?.end, DATE_TIME_FORMAT)}
            </span>
          ) : (
            '-'
          )}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages['date-of-birth-start-end-date-time.text'])}:{' '}
        </Col>
        <Col span={12}>
          {dateOfBirthDateTime ? (
            <span>
              {formatDate(dateOfBirthDateTime?.start, DATE_TIME_FORMAT)} -{' '}
              <br />
              {formatDate(dateOfBirthDateTime?.end, DATE_TIME_FORMAT)}
            </span>
          ) : (
            '-'
          )}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.STATUS)}:{' '}
        </Col>
        <Col span={12}>
          {memberStatus
            ?.map((item) => ` ${translate(messages[`${item}`])}`)
            .toString() || '-'}
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className="pr-3" span={12}>
          {translate(messages.ACCOUNT_BALANCE)}:{' '}
        </Col>
        <Col span={12}>
          {totalBalance ? (
            <span>
              {totalBalance?.minimum} - {totalBalance?.maximum}
            </span>
          ) : (
            '-'
          )}
        </Col>
      </Row>
    </StyledDiv>
  );
};

export default FilterTooltip;
