import { useQuery } from '@apollo/react-hooks';
import { Spin } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import { WITHDRAWAL_COMPLIANCE_CONFIG } from 'graphql/queries/withdrawalCompliance.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React from 'react';
import { usePermissions } from 'store/accountState';
import removeTypename from 'utils/removeTypename';
import ComplianceCheckForm from './components/ComplianceCheckForm';
import { StyledContent, StyledLayout } from './styles';

const ComplianceCheck = () => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_VIEW } = collectPermissions(
    role,
    permissions,
    ['VIEW'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_COMPLIANCE_CHECK
  );

  const { loading, data = {} } = useQuery(WITHDRAWAL_COMPLIANCE_CONFIG, {
    fetchPolicy: 'network-only',
  });

  const { withdrawalComplianceCheckConfig } = data as any;
  return (
    <StyledLayout>
      <StyledContent className="p-4 d-flex justify-content-center">
        <div style={{ width: 850 }}>
          {loading && (
            <div data-testid="loading">
              <Spin />
            </div>
          )}
          {!loading && withdrawalComplianceCheckConfig && ALLOWED_VIEW && (
            <>
              <ComplianceCheckForm
                initialValues={{
                  ...removeTypename(withdrawalComplianceCheckConfig),
                }}
              />
            </>
          )}
        </div>
      </StyledContent>
    </StyledLayout>
  );
};

export default ComplianceCheck;
