import React, { useMemo, Dispatch, SetStateAction } from 'react';
import { DynamicObj } from '../../../interfaces/user.interface';

const AccountManagementContext = React.createContext<any>(null);

export const AccountManagementProvider = ({
  refetch,
  children,
  data,
  loading,
  error,
  page,
  setPage,
  collapsed,
  setCollapsed,
}: {
  refetch: () => void;
  data: DynamicObj;
  loading: boolean;
  error: any;
  children: any;
  page: any;
  setPage: any;
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  const value = useMemo(
    () => ({
      refetch,
      data,
      loading,
      error,
      page,
      setPage,
      collapsed,
      setCollapsed,
    }),
    [data, error, loading, refetch, page, setPage, collapsed, setCollapsed]
  );

  return (
    <AccountManagementContext.Provider value={value}>
      {children}
    </AccountManagementContext.Provider>
  );
};

export const useAccountManagementValue = () =>
  React.useContext(AccountManagementContext);
