import React from 'react';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';

const usernameAlphaNumericRegex = /^[a-z][a-z0-9]{0,23}$/i;
const smallLettersRegex = /^[^A-Z]*$/;
const alphaNumericRegex = /^[a-z0-9]+$/i;
const numbersOnlyRegex = /^[0-9]{8,14}$/i;
const alphabeticRegex = /^[a-zA-Z ]+$/i;

export default yup.object().shape({
  username: yup
    .string()
    .matches(
      usernameAlphaNumericRegex,
      // @ts-ignore
      <FormattedMessage
        id="start-with-alphabet-and-alphanumeric-error.text"
        defaultMessage="Should start with an alphabet and contain alphanumeric characters only"
      />
    )
    .matches(
      smallLettersRegex,
      // @ts-ignore
      <FormattedMessage
        id="NO_UPPERCASE_LETTERS"
        defaultMessage="Username cannot contain uppercase letters"
      />
    )
    .min(
      4,
      // @ts-ignore
      <FormattedMessage
        id="at-least-four-characters.text"
        defaultMessage="Must be at least 4 characters"
      />
    )
    .max(
      24,
      // @ts-ignore
      <FormattedMessage
        id="at-most-twenty-four-characters.text"
        defaultMessage="Must be at most 24 characters"
      />
    )
    .required(
      // @ts-ignore
      <FormattedMessage id="required.text" defaultMessage="Required" />
    ),
  realName: yup
    .string()
    .matches(
      alphabeticRegex,
      // @ts-ignore
      <FormattedMessage
        id="alphabet-only-error.text"
        defaultMessage="Alphabetic characters only, no specials or numbers allowed."
      />
    )
    .required(
      // @ts-ignore
      <FormattedMessage id="required.text" defaultMessage="Required" />
    ),
  password: yup.string().when('id', {
    is: (value) => !value,
    then: yup
      .string()
      .matches(
        alphaNumericRegex,
        // @ts-ignore
        <FormattedMessage
          id="alphanumeric-error.text"
          defaultMessage="Should only contain alphanumeric characters"
        />
      )
      .min(
        8,
        // @ts-ignore
        <FormattedMessage
          id="at-least-eight-characters.text"
          defaultMessage="Must be at least 8 characters"
        />
      )
      .max(
        24,
        // @ts-ignore
        <FormattedMessage
          id="at-most-twenty-four-characters.text"
          defaultMessage="Must be at most 24 characters"
        />
      )
      .required(
        // @ts-ignore
        <FormattedMessage id="required.text" defaultMessage="Required" />
      )
      .nullable(),
  }),
  email: yup
    .string()
    .required('Required')
    .email(
      // @ts-ignore
      <FormattedMessage
        id="email-error.text"
        defaultMessage="Must be a valid email address"
      />
    ),

  mobileNumber: yup
    .string()
    .matches(
      numbersOnlyRegex,
      // @ts-ignore
      <FormattedMessage
        id="mobile-number-error-field.text"
        defaultMessage="Mobile number must contain only numbers and between 8 - 14 digits"
      />
    )
    .nullable(),

  // givenName: yup.string().required('Required'),
  // familyName: yup.string().required('Required'),
  // permissionGroups: yup.array().min(1),
});
