import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Radio } from 'antd';
import { Member } from 'types/graphqlTypes';
import ForcePasswordChange from './components/ForcePasswordChange';
import PasswordChange from './components/PasswordChange';
import { StyledModal } from './styles';

type Props = {
  member: Member;
  refetchParams: Record<string, any>;
};

const ChangePasswordMenu = ({ member, refetchParams }: Props) => {
  const [option, setOption] = useState('FORCE_CHANGE');
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const handleChange = (e: Record<string, any>) => {
    const { value } = e.target;
    setOption(value);
  };
  return (
    <>
      <Button
        type="link"
        className="text-black"
        onClick={handleShow}
        block
        role="button"
      >
        <FormattedMessage
          id="members.change-password.text"
          defaultMessage="Change Password"
        />
      </Button>
      <StyledModal
        title={
          <FormattedMessage
            id="members.change-password.text"
            defaultMessage="Change Password"
          />
        }
        onCancel={handleShow}
        visible={isShow}
        footer={false}
      >
        <div className="p-3">
          <Radio.Group onChange={handleChange} value={option}>
            <Radio value="FORCE_CHANGE">
              <FormattedMessage
                id="force-password-change.text"
                defaultMessage="Force Password change"
              />
            </Radio>
            <Radio value="CHANGE" aria-label="change-password-radio">
              <FormattedMessage
                id="change-password.text"
                defaultMessage="Change Password"
              />
            </Radio>
          </Radio.Group>
        </div>
        {option === 'FORCE_CHANGE' && (
          <ForcePasswordChange
            member={member}
            refetchParams={refetchParams}
            onCreated={handleShow}
            onClose={handleShow}
          />
        )}
        {option === 'CHANGE' && (
          <PasswordChange
            member={member}
            refetchParams={refetchParams}
            onCreated={handleShow}
            onClose={handleShow}
          />
        )}
      </StyledModal>
    </>
  );
};

export default ChangePasswordMenu;
