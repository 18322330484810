import React from 'react';
import RequestUpdateToggle from 'components/RequestUpdateToggle';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import { Divider } from 'antd';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import removeNull from 'utils/removeNull';

type Props = {
  isRealtime?: boolean;
  handleSetRealtime?: () => void;
  refreshPage: () => void;
  filters: {
    [key: string]: any;
  };
};

const HeaderRight: React.FC<Props> = ({
  handleSetRealtime,
  isRealtime,
  refreshPage,
  filters,
}) => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_CSV_DOWNLOAD_BUTTON } = collectPermissions(
    role,
    permissions,
    ['CSV_DOWNLOAD_BUTTON'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS_REBATES_REPORTS
  );

  const translate = useTranslate();

  return (
    <>
      <RequestUpdateToggle checked={isRealtime} onChange={handleSetRealtime} />
      <RefreshButton onClick={() => refreshPage()} />
      <Divider type="vertical" className="ml-0 mr-2" />
      <ColumnsFilterNew iconOnly tabId="rebates-reports" />
      <SpreadsheetDownload
        iconOnly
        filter={removeNull(filters)}
        filename={translate(messages.REBATES_REPORTS)}
        extension="csv"
        permission={ALLOWED_CSV_DOWNLOAD_BUTTON}
      />
    </>
  );
};

export default HeaderRight;
