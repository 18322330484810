/* eslint-disable no-param-reassign */
import React from 'react';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { uniqBy } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import coercedGet from 'utils/coercedGet';
import ModifiedTag from '../ModifiedTag';

export const GAME_SUB_CATEGORIES = gql`
  query CategoriesByGameList(
    $first: Int
    $after: Binary
    $filter: GamesFilterInput
  ) {
    games(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          subCategory
        }
      }
    }
  }
`;

type Props = {
  gameIds: Array<string> | null;
};

const GameCategoryTag: React.FC<Props> = ({ gameIds }): any => {
  const { filters, setPersistedFilters } = useFilterValues();

  const { data = {}, loading } = useQuery(GAME_SUB_CATEGORIES, {
    variables: {
      filter: {
        id: {
          in: gameIds,
        },
      },
    },
  });

  const edges = coercedGet(data, 'games.edges', []);

  const uniqCategoryGameList = uniqBy(
    edges.map(({ node }: any) => ({
      id: node.id,
      subCategory: node.subCategory,
    })),
    'subCategory'
  );

  //   const currFilts = coercedGet(filters, 'game_subCategory.in', []);

  const handleRemoveGamesBySubCategory = (subCategory: string) => {
    const gameIdsBySubCategory = edges.reduce(
      (acc: Array<string>, curr: any) => {
        const { node } = curr;
        if (node.subCategory === subCategory) {
          // @ts-ignore
          return [...new Set([...acc, node.id])];
        }

        return acc;
      },
      []
    );

    const preFilteredGames = coercedGet(filters, 'game_subCategory.in', []);
    const postFilteredGames = preFilteredGames.filter(
      (item: string) => !gameIdsBySubCategory.includes(item)
    );

    const newFilterArray = {
      in: postFilteredGames,
    };

    setPersistedFilters((prev: Record<string, any>) => ({
      ...prev,
      game_subCategory: postFilteredGames.length ? newFilterArray : null,
    }));
  };

  return (
    !loading &&
    uniqCategoryGameList.map((item: any) => (
      <ModifiedTag
        key={item.id}
        closable
        onClose={() => handleRemoveGamesBySubCategory(item.subCategory)}
      >
        {item.subCategory}
      </ModifiedTag>
    ))
  );
};

export default GameCategoryTag;
