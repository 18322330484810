export const AGENT_REQUEST_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const columns = [
  {
    title: 'Account ID',
    dataIndex: 'accountId',
  },
  {
    title: 'Actual Name',
    dataIndex: 'actualName',
  },
  {
    title: 'Affiliate Tier',
    dataIndex: 'affiliateTier',
  },
  {
    title: 'Total Turnover',
    dataIndex: 'totalTurnover',
  },
  {
    title: 'Total Payout',
    dataIndex: 'toyalPayout',
  },
  {
    title: 'Pending Payout',
    dataIndex: 'pendingPayout',
  },
  {
    title: 'Affiliate Join Date',
    dataIndex: 'affiliateJoinDate',
  },
  {
    title: '# of Active Downlines',
    dataIndex: 'activeDownlines',
  },
  {
    title: 'Total Downline Turnover',
    dataIndex: 'totalDownlineTurnover',
  },
  {
    title: 'Commission Percentage',
    dataIndex: 'commissionPercentage',
  },
  {
    title: 'Expenses Incurred',
    dataIndex: 'expensesIncurred',
  },
  {
    title: 'Settlement Period',
    dataIndex: 'settlementPeriod',
  },
];

export const data = [
  {
    key: '1',
    accountId: 'CoAfCb',
    actualName: 'Last name, First name',
    affiliateTier: 'Tier',
    totalTurnover: 100,
    toyalPayout: 100,
    pendingPayout: 10,
    affiliateJoinDate: '30/09/2019',
    activeDownlines: 10,
    totalDownlineTurnover: 10,
    commissionPercentage: 0.65,
    expensesIncurred: 10,
    settlementPeriod: '1 month',
  },
  {
    key: '2',
    accountId: 'wpX4HE',
    actualName: 'Last name, First name',
    affiliateTier: 'Tier',
    totalTurnover: 50,
    toyalPayout: 50,
    pendingPayout: 20,
    affiliateJoinDate: '30/09/2019',
    activeDownlines: 15,
    totalDownlineTurnover: 15,
    commissionPercentage: 0.2,
    expensesIncurred: 10,
    settlementPeriod: '1 month',
  },
  {
    key: '3',
    accountId: 'wpX4HE',
    actualName: 'Last name, First name',
    affiliateTier: '-',
    totalTurnover: 150,
    toyalPayout: 150,
    pendingPayout: 25,
    affiliateJoinDate: '30/09/2019',
    activeDownlines: 25,
    totalDownlineTurnover: 25,
    commissionPercentage: 0.15,
    expensesIncurred: 10,
    settlementPeriod: '1 month',
  },
];

export default {};
