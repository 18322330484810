import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import ModifiedTag from '../ModifiedTag';
import { VENDOR } from './queries';

type VendorTabType = {
  id: string;
  onClose: (val: any) => void;
};

const VendorTab = (props: VendorTabType): React.ReactElement => {
  const { id, onClose } = props;
  const { data } = useQuery(VENDOR, {
    variables: {
      id,
    },
  });

  const { vendor } = data;

  return (
    <ModifiedTag {...props} closable onClose={(e) => onClose(e)}>
      {vendor.name}
    </ModifiedTag>
  );
};

export default VendorTab;
