import React from 'react';
import { partial } from 'lodash';
import { useIntl } from 'react-intl';
import { customFormatMessage } from 'utils/customFormatMessage';

const useTranslate = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  // Unknown dependency, to be fixed.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(partial(customFormatMessage, formatMessage), [
    formatMessage,
  ]);
};
export default useTranslate;
