import React, { useMemo } from 'react';
import { DynamicObj } from 'interfaces/user.interface';

const VendorMaintenanceContext = React.createContext<any>(null);

export const VendorMaintenanceProvider = ({
  refetch,
  children,
  data,
  loading,
  error,
  page,
  setPage,
}: {
  refetch: () => void;
  data: DynamicObj;
  loading: boolean;
  error: any;
  children: any;
  page: any;
  setPage: any;
}) => {
  const value = useMemo(
    () => ({ refetch, data, loading, error, page, setPage }),
    [data, error, loading, refetch, page, setPage]
  );

  return (
    <VendorMaintenanceContext.Provider value={value}>
      {children}
    </VendorMaintenanceContext.Provider>
  );
};

export const useVendorMaintenance = () =>
  React.useContext(VendorMaintenanceContext);
