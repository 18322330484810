import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import { useQuery } from '@apollo/react-hooks';
import CsvProvider, { useCsvValues } from 'contexts/Csv';
import { LayoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import coercedGet from 'utils/coercedGet';
import { TabId } from 'constants/route';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { getPollInterval } from 'utils/pollInterval';
import generateCsvHeader from 'utils/generateCsvHeader';
import { RebatePayoutRecord } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import { DEFAULT_ROW_COUNT, rebateColumns } from './columns';
import Sidebar from './components/Sidebar';
import FilterConditions from './components/FilterConditions';
import HeaderRight from './components/HeaderRight';
import { StyledContainer } from './styles';
import { MEMBER_REBATE_PAYOUT_RECORDS } from './queries';
import TableIndex from './components/MemberPayoutRecordsTable/TableIndex';

const RebateMemberPayoutRecords = ({
  id,
  isActiveTab,
}: {
  id: string;
  isActiveTab: boolean;
}) => {
  const [isRealtime, setIsRealtime] = useState(true);

  const translate = useTranslate();
  const { currency } = useConfig();
  const [columns, setColumns] = useState<any>([]);
  const [state, setState] = useState<any>({
    sidebarState: false,
    dateRange: [],
    status: [],
  });

  const { setCsvFilters, setCsvHeader } = useCsvValues();
  const { filterColumns } = useCustomColumnsV2(
    TabId.TOTAL_NUMBER_OF_PARTICIPATING_MEMBER as any,
    columns,
    {
      selectorLookup: false,
    }
  );

  useEffect(() => {
    setColumns(rebateColumns(translate, currency));
  }, [translate, currency]);

  const [page, setPage] = useState<any>({
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
    totalCount: 0,
    pageInfo: {},
  });

  const variables = {
    first: DEFAULT_ROW_COUNT,
    after: page.after,
    filter: {
      rebateGroup: {
        eq: id,
      },
      ...(state.status.length && { status: { in: state.status } }),
    },
  };
  const setResultInfo = ({
    pageInfo,
    totalCount,
  }: {
    pageInfo: Record<string, any>;
    totalCount: number;
  }) => {
    setPage({
      ...page,
      pageInfo,
      totalCount,
    });
  };

  const { loading, error, data, refetch } = useQuery(
    MEMBER_REBATE_PAYOUT_RECORDS,
    {
      variables,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      pollInterval: getPollInterval({
        isActive: isActiveTab,
        isRealtime,
      }),
      onCompleted: (result) => {
        if (result) {
          setResultInfo(result.memberRebatePayoutRecords);
        }
      },
    }
  );

  const handleSidebar = () =>
    setState({
      ...state,
      sidebarState: !state.sidebarState,
    });

  const handleSetRealtime = () => {
    setIsRealtime((prev) => !prev);
  };

  const handleNext = () => {
    const { savedCursor, currentPage, pageInfo } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const dataSource = coercedGet(
    data,
    'memberRebatePayoutRecords.edges',
    []
  ).map((edge: { node: RebatePayoutRecord }) => edge.node);
  const totalCount = coercedGet(
    data,
    'memberRebatePayoutRecords.totalCount',
    0
  );
  const totalPage = Math.ceil(page.totalCount / DEFAULT_ROW_COUNT) || 1;

  useEffect(() => {
    setCsvFilters({ filter: variables.filter });
    setCsvHeader(generateCsvHeader(columns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={
            <>
              <Button onClick={handleSidebar} size="small" className="mt-1">
                <LayoutOutlined />
              </Button>
              <StyledContainer>
                <FilterConditions filters={state} onFilterChange={setState} />
              </StyledContainer>
            </>
          }
          rightNode={
            <HeaderRight
              refreshPage={() => refetch()}
              isRealtime={isRealtime}
              handleSetRealtime={handleSetRealtime}
            />
          }
        />
        <Layout.Content
          sideBar={
            <Layout.Sidebar sidebarStatus={state.sidebarState}>
              <Sidebar state={state} setState={setState} />
            </Layout.Sidebar>
          }
          footer={
            <Layout.Footer
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={coercedGet(
                data,
                'memberRebatePayoutRecords.pageInfo',
                {}
              )}
            />
          }
        >
          <TableIndex
            loading={loading}
            dataSource={dataSource}
            error={error}
            columns={filterColumns(columns)}
          />
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

const RebateMemberPayoutRecordsTogglable = ({
  id,
  isActiveTab,
}: {
  id: string;
  isActiveTab: boolean;
}) => (
  <CsvProvider
    initValues={{
      query: MEMBER_REBATE_PAYOUT_RECORDS,
      edgesPath: 'memberRebatePayoutRecords.edges',
    }}
  >
    <RebateMemberPayoutRecords id={id} isActiveTab={isActiveTab} />
  </CsvProvider>
);

export default RebateMemberPayoutRecordsTogglable;
