import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-password-change.success-message.text': {
    id: 'member-password-change.success-message.text',
    defaultMessage: 'Password change successfully',
  },
});

export default messages;
