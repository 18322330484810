/* eslint-disable */
type Rec = Record<string, any>;

declare global {
  interface Window {
    _fs_debug: boolean;
    _fs_host: string;
    _fs_script: string;
    _fs_org: string;
    _fs_namespace: string;
  }
}

export default () => {
  window._fs_debug = false;
  window._fs_host = 'fullstory.com';
  window._fs_script = 'edge.fullstory.com/s/fs.js';
  window._fs_org = 'S637K';
  window._fs_namespace = 'FS';
  (function(
    m: Rec,
    n: Rec,
    e: string,
    t: string,
    l: string,
    o?: any,
    g?: any,
    y?: any
  ) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log(
          'FullStory namespace conflict. Please set window["_fs_namespace"].'
        );
      }
      return;
    }
    g = m[e] = function(a: any, b: any, s: any) {
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
    };
    g.q = [];
    o = n.createElement(t);
    o.async = 1;
    o.crossOrigin = 'anonymous';
    o.src = 'https://' + window._fs_script;
    y = n.getElementsByTagName(t)[0];
    y.parentNode.insertBefore(o, y);
    g.identify = function(i: any, v: any, s: any) {
      g(l, { uid: i }, s);
      if (v) g(l, v, s);
    };
    g.setUserVars = function(v: any, s: any) {
      g(l, v, s);
    };
    g.event = function(i: any, v: any, s: any) {
      g('event', { n: i, p: v }, s);
    };
    g.shutdown = function() {
      g('rec', !1);
    };
    g.restart = function() {
      g('rec', !0);
    };
    g.log = function(a: any, b: any) {
      g('log', [a, b]);
    };
    g.consent = function(a: any) {
      g('consent', !arguments.length || a);
    };
    g.identifyAccount = function(i: any, v: any) {
      o = 'account';
      v = v || {};
      v.acctId = i;
      g(o, v);
    };
    g.clearUserCookie = function() {};
    g._w = {};
    y = 'XMLHttpRequest';
    g._w[y] = m[y];
    y = 'fetch';
    g._w[y] = m[y];
    if (m[y])
      m[y] = function() {
        return g._w[y].apply(this, arguments);
      };
  })(window, document, window['_fs_namespace'], 'script', 'user');
};
