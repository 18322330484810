export enum changePasswordTestId {
  form = 'change-admin-password',
  passwordFormItem = 'password-formitem',
  passwordInput = 'password-input',
  newPasswordFormItem = 'newPassword-formitem',
  newPasswordInput = 'newPassword-input',
  confirmNewPasswordFormItem = 'confirmNewPassword-formitem',
  confirmNewPasswordInput = 'confirmNewPassword-input',
  submitButton = 'submit-button',
}
