import React from 'react';
import { StyledColorSquare, StyledTag } from './styles';

type Props = {
  value: any[];
  disableClosable: boolean;
};

const MemberTagsSelect = ({ value = [], disableClosable }: Props) => (
  <>
    {value.length > 0
      ? value.map((item) => (
          <StyledTag
            {...(!disableClosable && { closable: true })}
            key={item.id}
            className="mb-1"
          >
            <StyledColorSquare color={item.color} /> {item.name}
          </StyledTag>
        ))
      : '-'}
  </>
);

export default MemberTagsSelect;
