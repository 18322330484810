import { defineMessages } from 'react-intl';

/**
 * These translations is based from the  https://maxo.cc/d/1/api/gateways list.
 * Please coordinate with BE devs if there are any new gateways and channels will be added
 */

const messages = defineMessages({
  /* * ==/GATEWAYS/== * */
  '8APay': {
    id: '8A_Pay',
    defaultMessage: '8APay',
  },
  AliAPay: {
    id: 'ALI_A_PAY',
    defaultMessage: 'AliAPay',
  },
  CKPay: {
    id: 'CK_Pay',
    defaultMessage: 'CKPay',
  },
  FakePay: {
    id: 'FAKE_PAY',
    defaultMessage: 'FakePay',
  },
  FakePay2: {
    id: 'FAKE_PAY_2',
    defaultMessage: 'FakePay2',
  },
  FakePay3: {
    id: 'FAKE_PAY_3',
    defaultMessage: 'FakePay3',
  },
  FuDaPay: {
    id: 'FU_DA_PAY',
    defaultMessage: 'FuDaPay',
  },
  FuQiPay: {
    id: 'FU_QI_PAY',
    defaultMessage: 'FuQiPay',
  },
  FuYingPay: {
    id: 'FU_YING_PAY',
    defaultMessage: 'FuYingPay',
  },
  JYTPay: {
    id: 'JYT_PAY',
    defaultMessage: 'JYTPay',
  },
  JiShunPay: {
    id: 'JI_SHUN_PAY',
    defaultMessage: 'JiShunPay',
  },
  JuJiPay: {
    id: 'JU_JI_PAY',
    defaultMessage: 'JuJiPay',
  },
  JuZanPay: {
    id: 'JU_ZAN_PAY',
    defaultMessage: 'JuZanPay',
  },
  RuLaiPay: {
    id: 'RU_LAI_PAY',
    defaultMessage: 'RuLaiPay',
  },
  SVipPay: {
    id: 'S_VIP_PAY',
    defaultMessage: 'SVipPay',
  },
  TaiShanPay: {
    id: 'TAI_SHAN_PAY',
    defaultMessage: 'TaiShanPay',
  },
  TestPay: {
    id: 'TEST_PAY',
    defaultMessage: 'TestPay',
  },
  TongHuiBaoPay: {
    id: 'TONG_HUI_BAO_PAY',
    defaultMessage: 'TongHuiBaoPay',
  },
  WeiHuPay: {
    id: 'WEI_HU_PAY',
    defaultMessage: 'WeiHuPay',
  },
  WuDouMiPay: {
    id: 'WU_DOU_MI_PAY',
    defaultMessage: 'WuDouMiPay',
  },
  XiLaiDengPay: {
    id: 'XI_LAI_DENG_PAY',
    defaultMessage: 'XiLaiDengPay',
  },
  XiangYunPay: {
    id: 'XIANG_YUN_PAY',
    defaultMessage: 'XiangYunPay',
  },
  XinZhongYingPay: {
    id: 'XIN_ZHONG_YING_PAY',
    defaultMessage: 'XinZhongYingPay',
  },
  YinLianDaiFuPay: {
    id: 'YIN_LIAN_DAI_FU_PAY',
    defaultMessage: 'YinLianDaiFuPay',
  },
  YouJiePay: {
    id: 'YOU_JIE_PAY',
    defaultMessage: 'YouJiePay',
  },
  ZhiFengPay: {
    id: 'ZHI_FENG_PAY',
    defaultMessage: 'ZhiFengPay',
  },
  ZhiHuiTongPay: {
    id: 'ZHI_HUI_TONG_PAY',
    defaultMessage: 'ZhiHuiTongPay',
  },
  ZhongBaoPay: {
    id: 'ZHONG_BAO_PAY',
    defaultMessage: 'ZhongBaoPay',
  },
  ChengQunPay: {
    id: 'CHENG_QUN_PAY',
    defaultMessage: 'ChengQun',
  },
  DPay: {
    id: 'D_PAY',
    defaultMessage: 'DPay',
  },
  TieMaPay: {
    id: 'TIE_MA_PAY',
    defaultMessage: 'TieMa',
  },
  LiYingPay: {
    id: 'LI_YING_PAY',
    defaultMessage: 'LiYing',
  },
  XinLuLuTongPay: {
    id: 'XIN_LU_LU_TONG_PAY',
    defaultMessage: 'XinLuLuTong',
  },
  JiaYuanPay: {
    id: 'JIA_YUAN_PAY',
    defaultMessage: 'JiaYuan',
  },
  WangCaiPay: {
    id: 'WANGCAIPAY',
    defaultMessage: 'WangCaiPay',
  },

  /* * ==/PAYMENT GATEWAYS/== * */
  ALIPAY: {
    id: 'alipay.text',
    defaultMessage: 'Alipay',
  },
  UNIONPAY: {
    id: 'unionpay.text',
    defaultMessage: 'Union Pay',
  },
  WECHAT: {
    id: 'WECHAT',
    defaultMessage: 'Wechat',
  },
  BANK: {
    id: 'BANK',
    defaultMessage: 'Bank',
  },

  /* * ==/PAYMENT CHANNELS: EXTENSIONS OF PAYMENT GATEWAYS/== * */
  ALIPAY_H5: {
    id: 'ALIPAY_H5',
    defaultMessage: 'AliPay H5',
  },
  QQ: {
    id: 'QQ',
    defaultMessage: 'QQ',
  },
  QQ_H5: {
    id: 'QQ_H5',
    defaultMessage: 'QQ H5',
  },
  WECHAT_H5: {
    id: 'WECHAT_H5',
    defaultMessage: 'WeChat H5',
  },
  DEFAULT: {
    id: 'DEFAULT',
    defaultMessage: 'Default',
  },
  A: {
    id: 'A',
    defaultMessage: 'A',
  },
  B: {
    id: 'B',
    defaultMessage: 'B',
  },
  C: {
    id: 'C',
    defaultMessage: 'C',
  },
  ALIPAY_BARCODE: {
    id: 'ALIPAY_BARCODE',
    defaultMessage: 'AliPay Barcode',
  },
  ALIPAY_EXPRESS: {
    id: 'ALIPAY_EXPRESS',
    defaultMessage: 'AliPay Express',
  },
  ALIPAY_WAP: {
    id: 'ALIPAY_WAP',
    defaultMessage: 'AliPay WAP',
  },
  ALIPAY_NATIVE: {
    id: 'ALIPAY_NATIVE',
    defaultMessage: 'AliPay Native',
  },
  ALIPAY_NATIVE_QR: {
    id: 'ALIPAY_NATIVE_QR',
    defaultMessage: 'AliPay Native QR',
  },
  BAIDU_WALLET: {
    id: 'BAIDU_WALLET',
    defaultMessage: 'BaiDu Wallet',
  },
  BANK_H5: {
    id: 'BANK_H5',
    defaultMessage: 'Bank H5',
  },
  JD: {
    id: 'JD',
    defaultMessage: 'JD',
  },
  JD_WAP: {
    id: 'JD_WAP',
    defaultMessage: 'JD WAP',
  },
  QQ_WAP: {
    id: 'QQ_WAP',
    defaultMessage: 'QQ WAP',
  },
  QUICKPASS_WAP: {
    id: 'QUICKPASS_WAP',
    defaultMessage: 'Quickpass WAP',
  },
  RANDOM: {
    id: 'RANDOM',
    defaultMessage: 'Random',
  },
  SUCCESS: {
    id: 'SUCCESS',
    defaultMessage: 'Success',
  },
  TIMEOUT: {
    id: 'TIMEOUT',
    defaultMessage: 'Timeout',
  },
  UNIONPAY_EXPRESS: {
    id: 'UNIONPAY_EXPRESS',
    defaultMessage: 'UnionPay Express',
  },
  WECHAT_APP: {
    id: 'WECHAT_APP',
    defaultMessage: 'WeChat App',
  },
  WECHAT_BARCODE: {
    id: 'WECHAT_BARCODE',
    defaultMessage: 'WeChat Barcode',
  },
  WECHAT_MICROPAY: {
    id: 'WECHAT_MICROPAY',
    defaultMessage: 'WeChat Micropay',
  },
  WECHAT_PUBLIC: {
    id: 'WECHAT_PUBLIC',
    defaultMessage: 'WeChat Public',
  },
  WECHAT_QR: {
    id: 'WECHAT_QR',
    defaultMessage: 'WeChat Qr',
  },
  WECHAT_WAP: {
    id: 'WECHAT_WAP',
    defaultMessage: 'WeChat WAP',
  },
  WECHAT_MINI_PROGRAM: {
    id: 'WECHAT_MINI_PROGRAM',
    defaultMessage: 'WeChat Mini Program',
  },
  WECHAT_NATIVE_QR: {
    id: 'WECHAT_NATIVE_QR',
    defaultMessage: 'WeChat Native QR',
  },
  WECHAT_NATIVE_WAP: {
    id: 'WECHAT_NATIVE_WAP',
    defaultMessage: 'WeChat Native WAP',
  },
  JD_H5: {
    id: 'JD_H5',
    defaultMessage: 'JD H5',
  },
  ICBC: {
    id: 'ICBC',
    defaultMessage: 'ICBC',
  },
  ABC: {
    id: 'ABC',
    defaultMessage: 'ABC',
  },
  CCB: {
    id: 'CCB',
    defaultMessage: 'CCB',
  },
  SPDB: {
    id: 'SPDB',
    defaultMessage: 'SPDB',
  },
  CIB: {
    id: 'CIB',
    defaultMessage: 'CIB',
  },
  CMBC: {
    id: 'CMBC',
    defaultMessage: 'CMBC',
  },
  ECITIC: {
    id: 'ECITIC',
    defaultMessage: 'ECITIC',
  },
  CEB: {
    id: 'CEB',
    defaultMessage: 'CEB',
  },
  BOB: {
    id: 'BOB',
    defaultMessage: 'BOB',
  },
  CMB: {
    id: 'CMB',
    defaultMessage: 'CMB',
  },
  PSBC: {
    id: 'PSBC',
    defaultMessage: 'PSBC',
  },
  GDB: {
    id: 'GDB',
    defaultMessage: 'GDB',
  },
  SHB: {
    id: 'SHB',
    defaultMessage: 'SHB',
  },
  BOC: {
    id: 'BOC',
    defaultMessage: 'BOC',
  },
  HXB: {
    id: 'HXB',
    defaultMessage: 'HXB',
  },
  BOCM: {
    id: 'BOCM',
    defaultMessage: 'BOCM',
  },
  PAB: {
    id: 'PAB',
    defaultMessage: 'PAB',
  },
  SDB: {
    id: 'SDB',
    defaultMessage: 'SDB',
  },
});

export default messages;
