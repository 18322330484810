import { defineMessages } from 'react-intl';

const messages = defineMessages({
  PROMO_FIELD_USER_ID: {
    id: 'PROMO_FIELD_USER_ID',
    defaultMessage: 'User ID',
  },
  PROMO_FIELD_PROMO_NAME: {
    id: 'PROMO_FIELD_PROMO_NAME',
    defaultMessage: 'Name',
  },
  PROMO_FIELD_LOYALTY_LEVEL: {
    id: 'PROMO_FIELD_LOYALTY_LEVEL',
    defaultMessage: 'Loyalty Level',
  },
  PROMO_FIELD_GAME_LEVEL: {
    id: 'PROMO_FIELD_GAME_LEVEL',
    defaultMessage: 'Game Level',
  },
  PROMO_FIELD_GAME_TYPE: {
    id: 'PROMO_FIELD_GAME_TYPE',
    defaultMessage: 'Game Type',
  },
  PROMO_FIELD_GAME_VENDOR: {
    id: 'PROMO_FIELD_GAME_VENDOR',
    defaultMessage: 'Game Vendor',
  },
  PROMO_FIELD_GAME_NAME: {
    id: 'PROMO_FIELD_GAME_NAME',
    defaultMessage: 'Game Name',
  },
  PROMO_FIELD_TURNOVER: {
    id: 'PROMO_FIELD_TURNOVER',
    defaultMessage: 'Turnover',
  },
  PROMO_FIELD_DEPOSIT: {
    id: 'PROMO_FIELD_DEPOSIT',
    defaultMessage: 'Deposit',
  },
  PROMO_FIELD_EFFECTIVE_BET: {
    id: 'PROMO_FIELD_EFFECTIVE_BET',
    defaultMessage: 'Effective Bet',
  },
  PROMO_FIELD_BET_ID: {
    id: 'PROMO_FIELD_BET_ID',
    defaultMessage: 'Bet ID',
  },
});

export default messages;
