import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import formatGameTypeSettings from 'utils/formatGameTypeSettings';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { UPDATE_VENDOR_SETTING } from './mutations';
import { VENDOR_SETTINGS_ITEMS } from '../../../../queries';

const UpdateStatusModal = (props: {
  actionTypes: Record<string, any>;
  nodeData: Record<string, any>;
  toggleModal: () => void;
  refetchVariables: Record<string, any>;
}) => {
  const { refetchVariables, actionTypes, nodeData, toggleModal } = props;

  const {
    vendor,
    admin,
    context,
    disabled,
    hidden,
    gameTypeSettings,
    details,
    actionType,
  } = nodeData;

  const [updateVendorSettings, { loading }] = useMutation(
    UPDATE_VENDOR_SETTING,
    {
      onCompleted: () => {
        message.success(
          <FormattedMessage
            id="update-success.text"
            defaultMessage="Update Success!"
          />
        );
      },
    }
  );

  const handleDisableVendor = () => {
    const variables = {
      admin,
      context,
      vendor: vendor.code,
      input: {
        disabled: true,
        hidden,
        details,
        gameTypeSettings: formatGameTypeSettings(gameTypeSettings),
      },
    };

    updateVendorSettings({
      variables: {
        ...variables,
      },
      refetchQueries: [
        {
          query: VENDOR_SETTINGS_ITEMS,
          variables: refetchVariables,
        },
      ],
    });
    toggleModal();
  };

  const handleEnableVendor = () => {
    const variables = {
      admin,
      context,
      vendor: vendor.code,
      input: {
        disabled: false,
        hidden,
        details,
        gameTypeSettings: formatGameTypeSettings(gameTypeSettings),
      },
    };

    updateVendorSettings({
      variables: {
        ...variables,
      },
      refetchQueries: [
        {
          query: VENDOR_SETTINGS_ITEMS,
          variables: refetchVariables,
        },
      ],
    });
    toggleModal();
  };

  const handleHideVendor = () => {
    const variables = {
      admin,
      context,
      vendor: vendor.code,
      input: {
        // disabled: false,
        disabled,
        hidden: true,
        details,
        gameTypeSettings: formatGameTypeSettings(gameTypeSettings),
      },
    };

    updateVendorSettings({
      variables: {
        ...variables,
      },
      refetchQueries: [
        {
          query: VENDOR_SETTINGS_ITEMS,
          variables: refetchVariables,
        },
      ],
    });
    toggleModal();
  };

  const handleUnhideVendor = () => {
    const variables = {
      admin,
      context,
      vendor: vendor.code,
      input: {
        disabled,
        hidden: false,
        details,
        gameTypeSettings: formatGameTypeSettings(gameTypeSettings),
      },
    };

    updateVendorSettings({
      variables: {
        ...variables,
      },
      refetchQueries: [
        {
          query: VENDOR_SETTINGS_ITEMS,
          variables: refetchVariables,
        },
      ],
    });

    toggleModal();
  };

  const selectAction = (type: string) => {
    switch (type) {
      case actionTypes.ENABLE:
        return {
          handler: handleEnableVendor,
          title: (
            <FormattedMessage
              id="confirm-enable-vendor.text"
              defaultMessage="Confirm Enable Vendor"
            />
          ),
        };
      case actionTypes.DISABLE:
        return {
          handler: handleDisableVendor,
          title: (
            <FormattedMessage
              id="confirm-disable-vendor.text"
              defaultMessage="Confirm Disable Vendor"
            />
          ),
        };
      case actionTypes.HIDE:
        return {
          handler: handleHideVendor,
          title: (
            <FormattedMessage
              id="confirm-hide-vendor.text"
              defaultMessage="Confirm Hide Vendor"
            />
          ),
        };

      case actionTypes.UNHIDE:
        return {
          handler: handleUnhideVendor,
          title: (
            <FormattedMessage
              id="confirm-unhide-vendor.text"
              defaultMessage="Confirm Unhide Vendor"
            />
          ),
        };
      default:
        return null;
    }
  };

  const { handler, title } = selectAction(actionType) as any;
  return (
    <Modal
      visible
      maskClosable={false}
      closable={false}
      width={416}
      okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
      cancelText={<FormattedMessage id="cancel.text" defaultMessage="Cancel" />}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={handler}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">{title}</span>
        <div className="ant-modal-confirm-content">
          <FormattedMessage
            id="are-you-sure.text"
            defaultMessage="Are you sure?"
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateStatusModal;
