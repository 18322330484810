import React from 'react';
import { Tag, Popover } from 'antd';
import { getBank, messages as bankMessages } from 'constants/banks';
import useTranslate from 'utils/useTranslate';

const MemberLoyaltyLevelTag = (props: {
  bankCode: string;
  onClose: (e: any) => void;
}) => {
  const { bankCode, onClose } = props;
  const newBank = getBank(bankCode) as {
    label: string;
    logo: string;
  };
  const translate = useTranslate();
  return (
    <Popover content={<div>{translate(bankMessages[newBank.label])}</div>}>
      <Tag
        closable
        onClose={(e) => onClose(e)}
        style={{ margin: '4px' }}
        className="d-flex align-items-center"
      >
        <img
          height={14}
          width={14}
          src={newBank.logo}
          alt="logo"
          className="mr-1"
        />
        <div>
          {newBank.label !== 'OTHER'
            ? newBank.label
            : translate(bankMessages[newBank.label])}
        </div>
      </Tag>
    </Popover>
  );
};

export default MemberLoyaltyLevelTag;
