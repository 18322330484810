import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-loyalty.edit-name.text': {
    id: 'member-loyalty.edit-name.text',
    defaultMessage: 'Edit name',
  },
  'vip.tier-name': {
    id: 'vip.tier-name',
    defaultMessage: 'Tier Name',
  },
  'vip.deposit-amount': {
    id: 'vip.deposit-amount',
    defaultMessage: 'Deposit Amount',
  },
  'vip.turnover-amount': {
    id: 'vip.turnover-amount',
    defaultMessage: 'Turnover Amount',
  },
  'vip.upgrade-award-amount': {
    id: 'vip.upgrade-award-amount',
    defaultMessage: 'Upgrade Award Amount',
  },
  'vip.upgrade-award-turnover': {
    id: 'vip.upgrade-award-turnover',
    defaultMessage: 'Upgrade Award Turnover',
  },
});

export default messages;
