const storage = {
  ACTIVE_PORTAL: 'activePortal',
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  APP_CONFIG: 'onewallet.config',
  BO_REQUEST_UPDATE_CONFIG: 'BO_REQUEST_UPDATE_CONFIG',
  SCREEN_TAB_CONFIG: 'SCREEN_TAB_CONFIG',
  SCREEN_TAB_CONFIG_NEW: 'SCREEN_TAB_CONFIG_NEW',
  SA_SCREEN_TAB_CONFIG: 'SA_SCREEN_TAB_CONFIG',
  SA_ACCESS_TOKEN: 'SAaccessToken',
  BO_ACCESS_TOKEN: 'BOaccessToken',
  SA_REFRESH_TOKEN: 'SArefreshToken',
  BO_REFRESH_TOKEN: 'BOrefreshToken',
  APP_HAS_UPDATE: 'appHasUpdate',
  JOBS: 'JOBS',
  BO_BANNERS: 'BO_BANNERS',
  FAVORITE: 'favorite',
  AUTHENTICATE_VIA_OTP: 'authenticateViaOtp',
};

export default storage;
