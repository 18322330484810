import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import React, { useState } from 'react';
import useTranslate from 'utils/useTranslate';
import PasswordChangeForm from './components/PasswordChangeForm';
import messages from './messages';

type Props = {
  member: Record<string, any>;
  refetchParams: Record<string, any>;
  onClose: () => void;
  onCreated: () => void;
};

const WithdrawalPasswordChange = ({
  member,
  refetchParams,
  onClose,
  onCreated,
}: Props) => {
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const [isLoading, setLoading] = useState(false);
  const translate = useTranslate();
  const handleSubmit = async (e: Record<string, any>) => {
    setLoading(true);
    try {
      const input = { ...e };
      updateMember({
        variables: {
          id: member.id,
          input: {
            password: input.password,
          },
        },
        refetchQueries: [
          {
            query: GET_MEMBERS,
            variables: { ...refetchParams },
          },
        ],
      });
      message.success(
        translate(messages['member-password-change.success-message.text'])
      );
      setLoading(false);
      onCreated();
    } finally {
      setLoading(false);
    }
  };
  return (
    <PasswordChangeForm
      initialValues={{
        username: member.username,
        password: null,
      }}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};

export default WithdrawalPasswordChange;
