import { Locale } from 'store/accountState';

const supportedTimezones: Record<Locale, Array<string>> = {
  en: [
    'Africa/Algiers',
    'Atlantic/Cape_Verde',
    'Africa/Ndjamena',
    'Africa/Abidjan',
    'Africa/Cairo',
    'Africa/Accra',
    'Africa/Bissau',
    'Africa/Nairobi',
    'Africa/Monrovia',
    'Africa/Tripoli',
    'Indian/Mauritius',
    'Africa/Casablanca',
    'Africa/El_Aaiun',
    'Africa/Maputo',
    'Africa/Windhoek',
    'Africa/Lagos',
    'Indian/Reunion',
    'Africa/Sao_Tome',
    'Indian/Mahe',
    'Africa/Johannesburg',
    'Africa/Khartoum',
    'Africa/Juba',
    'Africa/Tunis',
    'Antarctica/Casey',
    'Antarctica/Davis',
    'Antarctica/Mawson',
    'Indian/Kerguelen',
    'Antarctica/DumontDUrville',
    'Antarctica/Syowa',
    'Antarctica/Troll',
    'Antarctica/Vostok',
    'Antarctica/Rothera',
    'Asia/Kabul',
    'Asia/Yerevan',
    'Asia/Baku',
    'Asia/Dhaka',
    'Asia/Thimphu',
    'Indian/Chagos',
    'Asia/Brunei',
    'Asia/Yangon',
    'Asia/Beijing',
    'Asia/Shanghai',
    'Asia/Urumqi',
    'Asia/Hong_Kong',
    'Asia/Taipei',
    'Asia/Macau',
    'Asia/Nicosia',
    'Asia/Famagusta',
    'Asia/Tbilisi',
    'Asia/Dili',
    'Asia/Kolkata',
    'Asia/Jakarta',
    'Asia/Pontianak',
    'Asia/Makassar',
    'Asia/Jayapura',
    'Asia/Tehran',
    'Asia/Baghdad',
    'Asia/Jerusalem',
    'Asia/Tokyo',
    'Asia/Amman',
    'Asia/Almaty',
    'Asia/Qyzylorda',
    'Asia/Qostanay',
    'Asia/Aqtobe',
    'Asia/Aqtau',
    'Asia/Atyrau',
    'Asia/Oral',
    'Asia/Bishkek',
    'Asia/Seoul',
    'Asia/Pyongyang',
    'Asia/Beirut',
    'Asia/Kuala_Lumpur',
    'Asia/Kuching',
    'Indian/Maldives',
    'Asia/Hovd',
    'Asia/Ulaanbaatar',
    'Asia/Choibalsan',
    'Asia/Kathmandu',
    'Asia/Karachi',
    'Asia/Gaza',
    'Asia/Hebron',
    'Asia/Manila',
    'Asia/Qatar',
    'Asia/Riyadh',
    'Asia/Singapore',
    'Asia/Colombo',
    'Asia/Damascus',
    'Asia/Dushanbe',
    'Asia/Bangkok',
    'Asia/Ashgabat',
    'Asia/Dubai',
    'Asia/Samarkand',
    'Asia/Tashkent',
    'Asia/Ho_Chi_Minh',
    'Australia/Darwin',
    'Australia/Perth',
    'Australia/Eucla',
    'Australia/Brisbane',
    'Australia/Lindeman',
    'Australia/Adelaide',
    'Australia/Hobart',
    'Australia/Currie',
    'Australia/Melbourne',
    'Australia/Sydney',
    'Australia/Broken_Hill',
    'Australia/Lord_Howe',
    'Antarctica/Macquarie',
    'Indian/Christmas',
    'Indian/Cocos',
    'Pacific/Fiji',
    'Pacific/Gambier',
    'Pacific/Marquesas',
    'Pacific/Tahiti',
    'Pacific/Guam',
    'Pacific/Tarawa',
    'Pacific/Enderbury',
    'Pacific/Kiritimati',
    'Pacific/Majuro',
    'Pacific/Kwajalein',
    'Pacific/Chuuk',
    'Pacific/Pohnpei',
    'Pacific/Kosrae',
    'Pacific/Nauru',
    'Pacific/Noumea',
    'Pacific/Auckland',
    'Pacific/Chatham',
    'Pacific/Rarotonga',
    'Pacific/Niue',
    'Pacific/Norfolk',
    'Pacific/Palau',
    'Pacific/Port_Moresby',
    'Pacific/Bougainville',
    'Pacific/Pitcairn',
    'Pacific/Pago_Pago',
    'Pacific/Apia',
    'Pacific/Guadalcanal',
    'Pacific/Fakaofo',
    'Pacific/Tongatapu',
    'Pacific/Funafuti',
    'Pacific/Wake',
    'Pacific/Efate',
    'Pacific/Wallis',
    // 'Etc/GMT',
    'Etc/UTC',
    // 'Etc/GMT-14',
    // 'Etc/GMT-13',
    // 'Etc/GMT-12',
    // 'Etc/GMT-11',
    // 'Etc/GMT-10',
    // 'Etc/GMT-9',
    // 'Etc/GMT-8',
    // 'Etc/GMT-7',
    // 'Etc/GMT-6',
    // 'Etc/GMT-5',
    // 'Etc/GMT-4',
    // 'Etc/GMT-3',
    // 'Etc/GMT-2',
    // 'Etc/GMT-1',
    // 'Etc/GMT+1',
    // 'Etc/GMT+2',
    // 'Etc/GMT+3',
    // 'Etc/GMT+4',
    // 'Etc/GMT+5',
    // 'Etc/GMT+6',
    // 'Etc/GMT+7',
    // 'Etc/GMT+8',
    // 'Etc/GMT+9',
    // 'Etc/GMT+10',
    // 'Etc/GMT+11',
    // 'Etc/GMT+12',
    'Europe/London',
    'Europe/Dublin',
    'Europe/Tirane',
    'Europe/Andorra',
    'Europe/Vienna',
    'Europe/Minsk',
    'Europe/Brussels',
    'Europe/Sofia',
    'Europe/Prague',
    'Europe/Copenhagen',
    'Atlantic/Faroe',
    'America/Danmarkshavn',
    'America/Scoresbysund',
    'America/Thule',
    'Europe/Tallinn',
    'Europe/Helsinki',
    'Europe/Paris',
    'Europe/Berlin',
    'Europe/Gibraltar',
    'Europe/Athens',
    'Europe/Budapest',
    'Atlantic/Reykjavik',
    'Europe/Rome',
    'Europe/Riga',
    'Europe/Vilnius',
    'Europe/Luxembourg',
    'Europe/Malta',
    'Europe/Chisinau',
    'Europe/Monaco',
    'Europe/Amsterdam',
    'Europe/Oslo',
    'Europe/Warsaw',
    'Europe/Lisbon',
    'Atlantic/Azores',
    'Atlantic/Madeira',
    'Europe/Bucharest',
    'Europe/Kaliningrad',
    'Europe/Moscow',
    'Europe/Simferopol',
    'Europe/Astrakhan',
    'Europe/Volgograd',
    'Europe/Saratov',
    'Europe/Kirov',
    'Europe/Samara',
    'Europe/Ulyanovsk',
    'Asia/Yekaterinburg',
    'Asia/Omsk',
    'Asia/Barnaul',
    'Asia/Novosibirsk',
    'Asia/Tomsk',
    'Asia/Novokuznetsk',
    'Asia/Krasnoyarsk',
    'Asia/Irkutsk',
    'Asia/Chita',
    'Asia/Yakutsk',
    'Asia/Vladivostok',
    'Asia/Khandyga',
    'Asia/Sakhalin',
    'Asia/Magadan',
    'Asia/Srednekolymsk',
    'Asia/Ust-Nera',
    'Asia/Kamchatka',
    'Asia/Anadyr',
    'Europe/Belgrade',
    'Europe/Madrid',
    'Africa/Ceuta',
    'Atlantic/Canary',
    'Europe/Stockholm',
    'Europe/Zurich',
    'Europe/Istanbul',
    'Europe/Kiev',
    'Europe/Uzhgorod',
    'Europe/Zaporozhye',
    'Factory',
    'America/New_York',
    'America/Chicago',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Los_Angeles',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Anchorage',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    'America/Phoenix',
    'America/Boise',
    'America/Indiana/Indianapolis',
    'America/Indiana/Marengo',
    'America/Indiana/Vincennes',
    'America/Indiana/Tell_City',
    'America/Indiana/Petersburg',
    'America/Indiana/Knox',
    'America/Indiana/Winamac',
    'America/Indiana/Vevay',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Detroit',
    'America/Menominee',
    'America/St_Johns',
    'America/Goose_Bay',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Thunder_Bay',
    'America/Nipigon',
    'America/Rainy_River',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Vancouver',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Creston',
    'America/Pangnirtung',
    'America/Iqaluit',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Whitehorse',
    'America/Dawson',
    'America/Cancun',
    'America/Merida',
    'America/Matamoros',
    'America/Monterrey',
    'America/Mexico_City',
    'America/Ojinaga',
    'America/Chihuahua',
    'America/Hermosillo',
    'America/Mazatlan',
    'America/Bahia_Banderas',
    'America/Tijuana',
    'America/Nassau',
    'America/Barbados',
    'America/Belize',
    'Atlantic/Bermuda',
    'America/Costa_Rica',
    'America/Havana',
    'America/Santo_Domingo',
    'America/El_Salvador',
    'America/Guatemala',
    'America/Port-au-Prince',
    'America/Tegucigalpa',
    'America/Jamaica',
    'America/Martinique',
    'America/Managua',
    'America/Panama',
    'America/Puerto_Rico',
    'America/Miquelon',
    'America/Grand_Turk',
    'America/Argentina/Buenos_Aires',
    'America/Argentina/Cordoba',
    'America/Argentina/Salta',
    'America/Argentina/Tucuman',
    'America/Argentina/La_Rioja',
    'America/Argentina/San_Juan',
    'America/Argentina/Jujuy',
    'America/Argentina/Catamarca',
    'America/Argentina/Mendoza',
    'America/Argentina/San_Luis',
    'America/Argentina/Rio_Gallegos',
    'America/Argentina/Ushuaia',
    'America/La_Paz',
    'America/Noronha',
    'America/Belem',
    'America/Santarem',
    'America/Fortaleza',
    'America/Recife',
    'America/Araguaina',
    'America/Maceio',
    'America/Bahia',
    'America/Sao_Paulo',
    'America/Campo_Grande',
    'America/Cuiaba',
    'America/Porto_Velho',
    'America/Boa_Vista',
    'America/Manaus',
    'America/Eirunepe',
    'America/Rio_Branco',
    'America/Santiago',
    'America/Punta_Arenas',
    'Pacific/Easter',
    'Antarctica/Palmer',
    'America/Bogota',
    'America/Curacao',
    'America/Guayaquil',
    'Pacific/Galapagos',
    'Atlantic/Stanley',
    'America/Cayenne',
    'America/Guyana',
    'America/Asuncion',
    'America/Lima',
    'Atlantic/South_Georgia',
    'America/Paramaribo',
    'America/Port_of_Spain',
    'America/Montevideo',
    'America/Caracas',
  ],
  zh: [
    '非洲/阿尔及尔',
    '大西洋/佛得角',
    '非洲/恩贾梅纳',
    '非洲/阿比让',
    '非洲/开罗',
    '非洲/阿克拉',
    '非洲/比绍',
    '非洲/内罗毕',
    '非洲/蒙罗维亚',
    '非洲/的黎波里',
    '印度/毛里求斯',
    '非洲/卡萨布兰卡',
    '非洲/艾·艾恩（El Aaiun）',
    '非洲/马普托',
    '非洲/温得和克',
    '非洲/拉各斯',
    '印度/留尼旺',
    '非洲/圣保罗',
    '印度/马赫',
    '非洲/约翰内斯堡',
    '非洲/喀土穆',
    '非洲/朱巴',
    '非洲/突尼斯',
    '南极洲/凯西',
    '南极洲/戴维斯',
    '南极洲/莫森',
    '印度/克格伦',
    '南极洲/杜蒙特',
    '南极洲/昭和',
    '南极/巨魔',
    '南极洲/沃斯托克',
    '南极洲/罗瑟拉',
    '亚洲/喀布尔',
    '亚洲/埃里温',
    '亚洲/巴库',
    '亚洲/达卡',
    '亚洲/廷布',
    '印度/查戈斯',
    '亚洲/文莱',
    '亚洲/仰光',
    '亚洲/北京', // ASIA-BEIJING
    '亚洲/上海', // Asia/Shanghai
    '亚洲/乌鲁木齐',
    '亚洲/香港',
    '亚洲/台北',
    '亚洲/澳门',
    '亚洲/尼科西亚',
    '亚洲/法马古斯塔',
    '亚洲/第比利斯',
    '亚洲/帝力',
    '亚洲/加尔各答',
    '亚洲/雅加达',
    '亚洲/蓬塔纳克',
    '亚洲/马卡萨尔',
    '亚洲/贾亚普拉',
    '亚洲/德黑兰',
    '亚洲/巴格达',
    '亚洲/耶路撒冷',
    '亚洲/东京',
    '亚洲/安曼',
    '亚洲/阿拉木图',
    '亚洲/齐齐洛达',
    '亚洲/ Qostanay',
    '亚洲/阿克托比',
    '亚洲/阿克套',
    '亚洲/阿特劳',
    '亚洲/口头',
    '亚洲/比什凯克',
    '亚洲/首尔',
    '亚洲/平壤',
    '亚洲/贝鲁特',
    '亚洲/吉隆坡',
    '亚洲/古晋',
    '印度/马尔代夫',
    '亚洲/香港',
    '亚洲/乌兰巴托',
    '亚洲/乔瓦山',
    '亚洲/加德满都',
    '亚洲/卡拉奇',
    '亚洲/加沙',
    '亚洲/希伯伦',
    '亚洲/马尼拉',
    '亚洲/卡塔尔',
    '亚洲/利雅得',
    '亚洲/新加坡',
    '亚洲/科伦坡',
    '亚洲/大马士革',
    '亚洲/杜尚别',
    '亚洲/曼谷',
    '亚洲/阿什哈巴德',
    '亚洲/迪拜',
    '亚洲/撒马尔罕',
    '亚洲/塔什干',
    '亚洲/胡志明市',
    '澳大利亚/达尔文',
    '澳大利亚/珀斯',
    '澳大利亚/欧克拉',
    '澳大利亚/布里斯班',
    '澳大利亚/林德曼',
    '澳大利亚/阿德莱德',
    '澳大利亚/霍巴特',
    '澳大利亚/科里',
    '澳大利亚/墨尔本',
    '澳大利亚/悉尼',
    '澳大利亚/断山',
    '澳大利亚/豪勋爵',
    '南极/麦格理',
    '印度/圣诞节',
    '印度/可可',
    '太平洋/斐济',
    '太平洋/甘比尔',
    '太平洋/马萨诸塞州',
    '太平洋/大溪地',
    '太平洋/关岛',
    '太平洋/塔拉瓦',
    '太平洋/恩德伯里',
    '太平洋/基里提马蒂',
    '太平洋/马朱罗',
    '太平洋/夸贾林岛',
    '太平洋/楚克',
    '太平洋/庞贝',
    '太平洋/科斯拉',
    '太平洋/瑙鲁',
    '太平洋/努美阿',
    '太平洋/奥克兰',
    '太平洋/查塔姆',
    '太平洋/拉罗东加',
    '太平洋/纽埃',
    '太平洋/诺福克',
    '太平洋/帕劳',
    '太平洋/莫尔兹比港',
    '太平洋/九重葛',
    '太平洋/皮特凯恩群岛',
    '太平洋/帕果帕果',
    '太平洋/阿皮亚',
    '太平洋/瓜达尔卡纳尔岛',
    '太平洋/法卡福',
    '太平洋/汤加塔普',
    '太平洋/富纳富提',
    '太平洋/苏醒',
    '太平洋/命运',
    '太平洋/沃利斯',
    // 'Etc/GMT',
    // 'Etc/UTC',
    // 'Etc/GMT-14',
    // 'Etc/GMT-13',
    // 'Etc/GMT-12',
    // 'Etc/GMT-11',
    // 'Etc/GMT-10',
    // 'Etc/GMT-9',
    // 'Etc/GMT-8',
    // 'Etc/GMT-7',
    // 'Etc/GMT-6',
    // 'Etc/GMT-5',
    // 'Etc/GMT-4',
    // 'Etc/GMT-3',
    // 'Etc/GMT-2',
    // 'Etc/GMT-1',
    // 'Etc/GMT+1',
    // 'Etc/GMT+2',
    // 'Etc/GMT+3',
    // 'Etc/GMT+4',
    // 'Etc/GMT+5',
    // 'Etc/GMT+6',
    // 'Etc/GMT+7',
    // 'Etc/GMT+8',
    // 'Etc/GMT+9',
    // 'Etc/GMT+10',
    // 'Etc/GMT+11',
    // 'Etc/GMT+12',
    '欧洲/伦敦',
    '欧洲/都柏林',
    '欧洲/地拉那',
    '欧洲/安道尔',
    '欧洲/维也纳',
    '欧洲/明斯克',
    '欧洲/布鲁塞尔',
    '欧洲/索非亚',
    '欧洲/布拉格',
    '欧洲/哥本哈根',
    '大西洋/法鲁',
    '美国/丹麦',
    '美国/ 斯科斯比松',
    '美国/图勒',
    '欧洲/塔林',
    '欧洲/赫尔辛基',
    '欧洲/巴黎',
    '欧洲/柏林',
    '欧洲/直布罗陀',
    '欧洲/雅典',
    '欧洲/布达佩斯',
    '大西洋/雷克雅未克',
    '欧洲/罗马',
    '欧洲/里加',
    '欧洲/维尔纽斯',
    '欧洲/卢森堡',
    '欧洲/马耳他',
    '欧洲/基希讷乌',
    '欧洲/摩纳哥',
    '欧洲/阿姆斯特丹',
    '欧洲/奥斯陆',
    '欧洲/华沙',
    '欧洲/里斯本',
    '大西洋/亚速尔群岛',
    '大西洋/马德拉岛',
    '欧洲/布加勒斯特',
    '欧洲/加里宁格勒',
    '欧洲/莫斯科',
    '欧洲/辛菲罗波尔',
    '欧洲/阿斯特拉罕',
    '欧洲/伏尔加格勒',
    '欧洲/萨拉托夫',
    '欧洲/基洛夫',
    '欧洲/萨马拉',
    '欧洲/乌里亚诺夫斯克',
    '亚洲/叶卡捷琳堡',
    '亚洲/鄂木斯克',
    '亚洲/巴尔瑙尔',
    '亚洲/新西伯利亚',
    '亚洲/托木斯克',
    '亚洲/新库兹涅茨克',
    '亚洲/克拉斯诺亚尔斯克',
    '亚洲/伊尔库茨克',
    '亚洲/赤塔',
    '亚洲/雅库茨克',
    '亚洲/符拉迪沃斯托克',
    '亚洲/ 坎迪加',
    '亚洲/萨哈林岛',
    '亚洲/马达加丹',
    '亚洲/斯雷德涅科林斯克',
    '亚洲/乌斯特-尼拉',
    '亚洲/堪察加半岛',
    '亚洲/ 阿纳迪尔',
    '欧洲/贝尔格莱德',
    '欧洲/马德里',
    '非洲/休达',
    '大西洋/金丝雀',
    '欧洲/斯德哥尔摩',
    '欧洲/苏黎世',
    '欧洲/伊斯坦布尔',
    '欧洲/基辅',
    '欧洲/乌日哥罗德',
    '欧洲/扎波罗热',
    '厂',
    '美国/纽约',
    '美国/芝加哥',
    '美国/北达科他州/中部',
    '美国/北达科他州/新萨勒姆',
    '美国/北达科他州/比拉',
    '美国/丹佛',
    '美国/洛杉矶',
    '美国/朱诺',
    '美国/锡特卡',
    '美国/梅特拉卡特拉',
    '美国/雅库塔',
    '美国/安克雷奇',
    '美国/ Nome',
    '美国/阿达克',
    '太平洋/檀香山',
    '美国/凤凰城',
    '美国/博伊西',
    '美国/印第安纳州/印第安纳波利斯',
    '美国/印第安纳州/马伦戈',
    '美国/印第安纳州/文森斯',
    '美国/印第安纳州/电视城',
    '美国/印第安纳州/彼得斯堡',
    '美国/印第安纳州/诺克斯',
    '美国/印第安纳州/威纳马克',
    '美国/印第安纳州/韦韦',
    '美国/肯塔基州/路易斯维尔',
    '美国/肯塔基州/蒙蒂塞洛',
    '美国/底特律',
    '美国/密胺',
    '美国/圣约翰斯',
    '美国/鹅湾',
    '美国/哈利法克斯',
    '美国/冰川湾',
    '美国/蒙古',
    '美国/布朗-萨布隆',
    '美国/多伦多',
    '美国/雷霆湾',
    '美国/尼皮贡',
    '美国/雨河',
    '美国/ Atikokan',
    '美国/温尼伯',
    '美国/里贾纳',
    '美国/迅捷潮流',
    '美国/埃德蒙顿',
    '美国/温哥华',
    '美国/道森克里克',
    '美国/纳尔逊堡',
    '美国/克雷斯顿',
    '美国/攀岩',
    '美国/伊卡卢伊特',
    '美国/坚决',
    '美国/兰金入口',
    '美国/剑桥湾',
    '美国/黄刀',
    '美国/ 伊努维克',
    '美国/怀特霍斯',
    '美国/道森',
    '美国/坎昆',
    '美国/梅里达',
    '美国/ 马塔莫罗斯',
    '美国/蒙特雷',
    '美国/墨西哥城',
    '美国/冲绳',
    '美国/吉娃娃',
    '美国/埃莫西约',
    '美国/马萨特兰',
    '美国/巴伊亚州班德拉斯',
    '美国/蒂华纳州',
    '美国/拿骚',
    '美国/巴巴多斯',
    '美国/伯利兹',
    '大西洋/百慕大',
    '美国/哥斯达黎加',
    '美国/哈瓦那',
    '美国/圣多明各',
    '美国/萨尔瓦多',
    '美国/危地马拉',
    '美国/太子港',
    '美国/特古西加尔巴',
    '美国/牙买加',
    '美国/马提尼克岛',
    '美国/马那瓜',
    '美国/巴拿马',
    '美国/波多黎各',
    '美国/密克隆群岛',
    '美国/大突厥',
    '美国/阿根廷/布宜诺斯艾利斯',
    '美国/阿根廷/科尔多瓦',
    '美国/阿根廷/萨尔塔',
    '美国/阿根廷/土库曼',
    '美国/阿根廷/拉里奥哈',
    '美国/阿根廷/圣胡安',
    '美国/阿根廷/胡胡伊',
    '美国/阿根廷/卡塔马卡',
    '美国/阿根廷/门多萨',
    '美国/阿根廷/圣路易斯',
    '美国/阿根廷/里约加勒戈斯',
    '美国/阿根廷/乌斯怀亚',
    '美国/拉巴斯',
    '美国/诺罗尼亚',
    '美国/贝伦',
    '美国/圣塔伦',
    '美国/福塔雷萨',
    '美国/累西腓',
    '美国/阿拉瓜纳州',
    '美国/马塞约',
    '美国/巴伊亚州',
    '美国/圣保罗',
    '美国/大坎普市',
    '美国/库亚巴',
    '美国/波尔图韦略',
    '美国/博阿维斯塔',
    '美国/马瑙斯',
    '美国/埃鲁尼佩',
    '美国/里约布兰科',
    '美国/圣地亚哥',
    '美国/蓬塔阿雷纳斯',
    '太平洋/复活节',
    '南极洲/帕尔默',
    '美国/波哥大',
    '美国/库拉索岛',
    '美国/瓜亚基尔',
    '太平洋/加拉帕戈斯群岛',
    '大西洋/史丹利',
    '美国/卡宴',
    '美国/圭亚那',
    '美国/亚松森',
    '美国/利马',
    '大西洋/南乔治亚州',
  ],
};

export default supportedTimezones;
