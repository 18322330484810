import React, { FC, useState } from 'react';
import { Select } from 'antd';
import { useDebounce } from 'hooks/useDebounce';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PROMOS } from 'graphql/queries/promo.query';
import { PromosConnectionEdge } from 'types/graphqlTypes';
import { checkPartial } from 'utils/partialUtils';

type Props = {
  value: {
    [key: string]: {
      in: string[] | null;
    };
  };
  onChange: React.Dispatch<
    React.SetStateAction<{
      [x: string]: Record<string, string[]> | null;
    }>
  >;
};

const PromoNameSelect: FC<Props> = ({ value, onChange }) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedInput = useDebounce(searchInput, 500);
  const [options, setOptions] = useState([]);
  const [partialString, setPartialString] = useState<string>('');

  const [loadPromoNames, { loading, called }] = useLazyQuery(GET_PROMOS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 10,
      filter: {
        name: {
          contains: debouncedInput,
        },
      },
    },
    onCompleted: (data) => {
      const {
        promos: { edges = [] },
      } = data;

      const promoNames = edges.map(
        ({ node }: PromosConnectionEdge) => node.name
      );

      setOptions(promoNames);
    },
  });

  if (!called) loadPromoNames();

  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange((prev) => ({
        ...prev,
        name: { in: e },
      }));
    } else {
      onChange((prev) => ({
        ...prev,
        name: null,
      }));
    }
    setPartialString('');
    setSearchInput('');
  };

  const isPartialFiltering = checkPartial('name', value);

  const values = value?.name?.in || [];

  return (
    <Select
      style={{ width: '100%' }}
      value={values}
      onChange={handleChange}
      mode="multiple"
      showSearch
      placeholder="Select Promo Name"
      loading={loading}
      onSearch={(text: string) => {
        setPartialString(text);
        setSearchInput(text);
      }}
      onFocus={() => setSearchInput('')}
      onBlur={() => setSearchInput('')}
      onDropdownVisibleChange={() => loadPromoNames()}
    >
      {!isPartialFiltering && partialString.length && (
        <Select.Option
          value={`Partial: ${partialString}`}
          label={`Partial: ${partialString}`}
        >
          Partial: {partialString}
        </Select.Option>
      )}

      {options.map((option, index) => (
        <Select.Option key={index} value={option}>
          {option}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PromoNameSelect;
