/* eslint-disable react/prop-types */
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Spin, Tooltip, Typography } from 'antd';
import MemberLevelButton from 'components/MemberLevelButton';
import { StyledMenu } from 'components/MoreActions/styles';
import NextPrevButton from 'components/NextPrevButton';
import { DATE_TIME_FORMAT } from 'constants/date';
import { FeatureFlags } from 'constants/featureFlags';
import ALL_PERMISSIONS from 'constants/permissions3';
import { MEMBER_MANAGEMENT as MEMBER_MGMT } from 'constants/testIds';
import { useSelectedRows } from 'contexts/SelectedRows';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import withResponsive from 'hoc/withResponsive';
import useIsNext from 'hooks/useIsNext';
import isRow from 'utils/isRow';
import {
  // isEmpty,
  uniqBy,
} from 'lodash';
import { EditPromoManualAdjustment } from 'pages/components/NewMemberDetail/components/Header/components/EditPromoManualAdjustment/EditPromoManualAdjustment';
import MemberTags from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/MemberTags/MemberTags';
import { TableFooterPages } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/TableFooterPages/TableFooterPages';
import { useMemberManagement } from 'pages/components/NewMemberManagement/MemberManagementContext';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import moment from 'moment';
import React, { memo, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconAgent } from 'components/assets/icon-agent.svg';
import { EditMember } from 'components/EditMember/EditMember';
// import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import { SendMessage } from 'components/SendMessage/SendMessage';
import { usePermissions } from 'store/accountState';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { Member } from 'types/graphqlTypes';
// import coercedGet from 'utils/coercedGet';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';
import esGet from 'utils/esGet';
import { statusTransform } from 'utils/memberStatusTransform';
import removeTypename from 'utils/removeTypename';
import setCurrency from 'utils/setCurrency';
import useTranslate from 'utils/useTranslate';
import AccountSum from './components/AccountSum';
import AddToWatchlist from './components/AddToWatchlist';
import EditBalance from './components/ChangeBalance';
import ChangePasswordMenu from './components/ChangePasswordMenu';
import ChangeWithdrawalPasswordMenu from './components/ChangeWithdrawalPasswordMenu';
import EditMemberLevelMenu from './components/EditMemberLevelMenu';
// import EditMultiVIP from './components/EditMultiVIP';
import EditProfileValidation from './components/EditProfileValidation/EditProfileValidation';
import EditStatusMenu from './components/EditStatusMenu';
import EditTagMenu from './components/EditTagMenu';
import MemberProfile from './components/MemberProfile';
import ResizableTitle from './components/ResizableTitle';
import { NewStyledTable } from './styles';

import { formatRealName } from './utils';

const MembersTable = (props: any) => {
  const {
    finalQueryFilter,
    members,
    refetchParams,
    loading,
    totalCount,
    widthLessThan1440,
    widthMoreThan1440,
  } = props;

  const showAffiliateId = isRow;
  const { role, permissions } = usePermissions();
  const translate = useTranslate();
  const {
    refetchParams: refetchVariables,
    refetchMemberManagement,
  } = useMemberManagement();
  const euRequirements = useIsNext(FeatureFlags.euRequirements);

  const refetchQuery = useMemo(
    () => ({
      query: GET_MEMBERS,
      variables: refetchVariables,
    }),
    [refetchVariables]
  );

  const {
    ALLOWED_EDIT_BALANCE,
    ALLOWED_EDIT_LABEL,
    ALLOWED_EDIT_STATUS,
    ALLOWED_EDIT_MEMBER,
    ALLOWED_EDIT_MEMBER_MARKER,
    ALLOWED_EDIT_VIP,
    ALLOWED_DELETE,
    ALLOWED_CHANGE_PASSWORD,
    ALLOWED_CHANGE_WITHDRAWAL_PASSWORD,
    ALLOWED_SEND_MESSAGE,
  } = collectPermissions(
    role,
    permissions,
    [
      'EDIT_BALANCE',
      'EDIT_LABEL',
      'EDIT_STATUS',
      'EDIT_MEMBER',
      'EDIT_MEMBER_MARKER',
      'EDIT_VIP',
      'DELETE',
      'CHANGE_PASSWORD',
      'CHANGE_WITHDRAWAL_PASSWORD',
      'SEND_MESSAGE',
    ],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const [selectedEditMember, setSelectedEditMember] = useState<any>(false);
  const tablePagesForFooter = [10, 20, 50, 100];
  const { page, setPage, onPrev, onNext, pageInfo, totalPage } = props;

  const columnsInit = [
    {
      key: 'starred',
      title: <div style={{ height: '20px', width: '20px' }} />,
      customTitle: translate(messages.ADD_TO_WATCHLIST),
      disabled: true,
      fixed: 'left',
      width: '50px',
      csvData: {
        label: 'Watchlist',
        key: 'starred',
      },
      render: (member: any) => <AddToWatchlist member={member} />,
    },
    {
      width: '120px',
      key: 'username',
      title: translate(messages.USERNAME),
      disabled: true,
      fixed: 'left',
      // width: '130px',
      csvData: {
        label: 'Username',
        key: 'username',
      },
      render: (member: Member) => <MemberProfile member={member} />,
    },

    isRow && {
      key: 'currency',

      minWidth: 100,
      align: 'center',
      title: 'Currency',
      render: (member: Record<string, any>) => member.currency || '-',
    },
    euRequirements && {
      minWidth: 100,
      key: 'firstName',
      title: translate(messages.FIRSTNAME),
      dataIndex: 'firstName',
      csvData: {
        label: 'Firstname',
        key: 'firstname',
      },
      render: (firstName: string) => firstName || '-',
    },
    euRequirements && {
      minWidth: 100,
      key: 'lastName',
      title: translate(messages.LASTNAME),
      dataIndex: 'lastName',
      csvData: {
        label: 'Lastname',
        key: 'lastname',
      },
      render: (lastName: string) => lastName || '-',
    },
    {
      minWidth: 120,
      key: 'realName',
      title: translate(messages['real-name.text']),
      dataIndex: 'realName',
      csvData: {
        label: 'Real Name',
        key: 'realName',
        renderCell: (member: Member) => esGet(member?.realName, '-'),
      },
      render: (realName: string) => (
        <Tooltip
          getPopupContainer={() => document.getElementById('member-loyalty')!}
          {...((widthMoreThan1440 ||
            formatRealName(realName) === (realName || '-')) && {
            visible: false,
          })}
          overlayStyle={{ fontSize: '10px' }}
          arrowPointAtCenter
          title={<span>{realName}</span>}
        >
          {formatRealName(realName, widthLessThan1440)}
        </Tooltip>
      ),
    },

    {
      minWidth: 100,
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      csvData: {
        label: 'Email',
        key: 'email',
        renderCell: (member: Member) => esGet(member?.email, '-'),
      },
      render: (email: string) => email,
    },
    {
      minWidth: 100,
      key: 'dateOfBirth',
      title: 'Date of Birth',
      dataIndex: 'dateOfBirth',
      csvData: {
        label: 'Date of Birth',
        key: 'dateOfBirth',
        renderCell: (member: Member) => esGet(member?.dateOfBirth, '-'),
      },
      render: (dob: string) => dob,
    },
    {
      minWidth: 100,
      key: 'registrationDateTime',
      title: 'Registration Date',
      dataIndex: 'registrationDateTime',
      csvData: {
        label: 'Registration Date',
        key: 'registrationDateTime',
        renderCell: (member: Member) =>
          esGet(member?.registrationDateTime, '-'),
      },
      render: (registration: string) => formatDate(registration),
    },
    {
      minWidth: 100,
      key: 'affiliate',
      title: translate(messages.AFFILIATE),
      ellipsis: true,
      className: 'text-center',
      csvData: {
        label: 'Affiliate',
        key: 'affiliate',
        renderCell: (member: Member) => esGet(member?.affiliate?.code, '-'),
      },
      render: (allData: Member) =>
        allData?.affiliate?.code ? (
          <Tooltip title={allData?.affiliate?.code}>
            <IconAgent />
          </Tooltip>
        ) : (
          '-'
        ),
    },
    // hidden VIP block for now.
    // {
    //   key: 'memberLoyalty',
    //   title: translate(messages.VIP_TIER),
    //   csvData: {
    //     label: translate(messages.VIP_TIER),
    //     key: 'memberLoyaltyLevels',
    //     renderCell: (vip: any) => {
    //       const memberLevels = coercedGet(vip, 'memberLoyaltyLevels', '-');
    //       return (
    //         (memberLevels &&
    //           memberLevels.map((level: Record<string, any>) => level.name)) ||
    //         '-'
    //       );
    //     },
    //   },
    //   render: (allData: Record<string, any>) =>
    //     isEmpty(allData?.memberLoyaltyLevels) ? (
    //       '-'
    //     ) : (
    //       <MemberLoyaltyTagList loyaltyLevels={allData?.memberLoyaltyLevels} />
    //     ),
    //   width: 120,
    // },
    {
      minWidth: 120,
      key: 'memberLevel',
      title: translate(messages.MEMBER_MARKER),
      csvData: {
        label: 'Member marker',
        key: 'memberLevel',
      },
      render: (allData: Record<string, any>) => (
        <MemberLevelButton
          memberLevelData={allData?.memberLevel}
          isHoverable={widthLessThan1440}
        />
      ),
    },
    {
      minWidth: 100,
      key: 'totalBalance',
      title: translate(messages.ACCOUNT_BALANCE),
      className: 'column-number text-right account-balance-col',
      dataIndex: 'totalBalance',
      csvData: {
        label: 'Account balance',
        key: 'totalBalance',
        renderCell: (balance: Record<string, any>) =>
          setCurrency('', balance.totalBalance, 2) || '-',
      },
      // ...(widthLessThan1440 && { width: 80 }),
      render: (totalBalance: number) => (
        <div className="text-right" data-testid="account-balance-table">
          {setCurrency('', totalBalance, 2) || '-'}
        </div>
      ),
    },
    {
      minWidth: 100,
      key: 'effectiveBet',
      title: translate(messages.TOTAL_EFFECTIVE_BET),
      className: 'column-number text-right',
      dataIndex: 'effectiveBet',
      csvData: {
        label: 'Total Effective Bet',
        key: 'effectiveBet',
        renderCell: (member: Record<string, any>) =>
          setCurrency('', member.effectiveBet, 2) || '-',
      },
      render: (effectiveBet: number) => (
        <div className="text-right" data-testid="total-effective-bet-row">
          {setCurrency('', effectiveBet, 2) || '-'}
        </div>
      ),
    },
    {
      minWidth: 100,
      key: 'status',
      title: translate(messages.status),
      className: 'text-center',
      csvData: {
        label: 'Status',
        key: 'status',
        renderCell: (status: Record<string, any>) =>
          esGet(statusTransform[status?.memberLevel?.status], '-'),
      },
      render: (allData: Record<string, any>) =>
        statusTransform[allData?.status],
    },
    {
      minWidth: 100,
      key: 'labels',
      title: translate(messages.LABELS_REMARKS),
      className: 'remarks-col',
      csvData: null,
      render: (member: Member) => (
        <MemberTags
          member={{
            ...member,
            tags: member?.tags ? [...member?.tags] : [],
          }}
          refetchParams={refetchParams}
        />
      ),
    },
    showAffiliateId && {
      minWidth: 100,
      key: 'affiliateId',
      title: 'Affiliate ID',
      dataIndex: 'affiliateId',
      csvData: null,
      render: (affiliateId: string) => affiliateId || '-',
    },
    euRequirements && {
      minWidth: 100,
      key: 'userVerificationStatus',
      title: translate(messages.VERIFICATION_STATUS),
      dataIndex: 'verified',
      csvData: {
        label: translate(messages.VERIFICATION_STATUS),
        key: 'id',
        renderCell: (status: boolean) =>
          status
            ? translate(messages.VERIFIED)
            : translate(messages.NOT_VERIFIED),
      },
      render: (status: boolean) =>
        status
          ? translate(messages.VERIFIED)
          : translate(messages.NOT_VERIFIED),
    },
    euRequirements && {
      minWidth: 100,
      key: 'country',
      title: translate(messages.COUNTRY),
      csvData: {
        label: translate(messages.COUNTRY),
        key: 'country',
        renderCell: (allData: Member) => allData?.address?.country || '-',
      },
      render: (allData: Member) => allData?.address?.country || '-',
    },
    euRequirements && {
      minWidth: 100,
      key: 'countryCode',
      title: translate(messages.COUNTRY_CODE),
      dataIndex: 'countryCode',
      csvData: {
        label: translate(messages.COUNTRY_CODE),
        key: 'countryCode',
        dataIndex: 'countryCode',
        renderCell: (countryCode: string) => countryCode || '-',
      },
      render: (countryCode: string) => countryCode || '-',
    },
    euRequirements && {
      minWidth: 100,
      key: 'postCode',
      title: translate(messages.POST_CODE),
      csvData: {
        label: translate(messages.POST_CODE),
        key: 'postCode',
        renderCell: (allData: Member) => allData?.address?.postCode || '-',
      },
      render: (allData: Member) => allData?.address?.postCode || '-',
    },
    {
      minWidth: 100,
      key: 'lastLoginDateTime',
      title: translate(messages.LAST_LOGIN),
      dataIndex: 'lastLoginDateTime',
      csvData: {
        label: 'Last login',
        key: 'lastLoginDateTime',
        renderCell: (date: Record<string, any>) =>
          moment(date.lastLoginDateTime).format(DATE_TIME_FORMAT) || '-',
      },
      // ...(widthLessThan1440 && { width: 90 }),
      render: (text: string) => moment(text).format(DATE_TIME_FORMAT) || '-',
    },
    {
      minWidth: 120,
      key: 'brandId',
      title: 'Brand ID',
      csvData: {
        label: 'Brand ID',
        key: 'brandId',
        renderCell: (allData: Member) => allData?.brandId || '-',
      },
      render: (allData: Member) => allData?.brandId || '-',
    },
    {
      minWidth: 120,
      key: 'platformId',
      title: 'Platform ID',
      csvData: {
        label: 'Platform ID',
        key: 'platformId',
        renderCell: (allData: Member) => allData?.platformId || '-',
      },
      render: (allData: Member) => allData?.platformId || '-',
    },
    {
      key: 'action',
      title: <div style={{ opacity: 0 }}>Action</div>,
      customTitle: translate(messages.ACTION),
      hidden: true,
      width: '120px',
      fixed: 'right',
      csvData: null,
      render: (item: Member) =>
        (ALLOWED_EDIT_MEMBER ||
          ALLOWED_EDIT_BALANCE ||
          ALLOWED_DELETE ||
          ALLOWED_EDIT_LABEL ||
          ALLOWED_EDIT_STATUS ||
          ALLOWED_EDIT_MEMBER_MARKER ||
          ALLOWED_CHANGE_PASSWORD ||
          ALLOWED_CHANGE_WITHDRAWAL_PASSWORD ||
          ALLOWED_EDIT_VIP ||
          ALLOWED_SEND_MESSAGE) && (
          <Dropdown
            overlay={
              <div data-testid={MEMBER_MGMT.memberDropdownMenu}>
                <StyledMenu>
                  {ALLOWED_EDIT_MEMBER && (
                    <StyledMenu.Item className="p-0">
                      <Button
                        type="link"
                        className="text-black"
                        onClick={() => setSelectedEditMember(item)}
                        block
                      >
                        <FormattedMessage
                          id="edit-member.text"
                          defaultMessage="Edit Member"
                        />
                      </Button>
                    </StyledMenu.Item>
                  )}
                  {ALLOWED_EDIT_BALANCE && (
                    <StyledMenu.Item className="p-0">
                      <EditBalance
                        member={item}
                        refetchFunction={() => refetchMemberManagement()}
                      />
                    </StyledMenu.Item>
                  )}

                  <StyledMenu.Item className="p-0">
                    <EditPromoManualAdjustment
                      members={[item.id]}
                      onSuccess={() => refetchMemberManagement()}
                    />
                  </StyledMenu.Item>
                  {ALLOWED_EDIT_LABEL && (
                    <StyledMenu.Item className="p-0">
                      <EditTagMenu
                        member={item}
                        onSuccess={() => refetchMemberManagement()}
                      />
                    </StyledMenu.Item>
                  )}
                  {ALLOWED_EDIT_STATUS && (
                    <StyledMenu.Item className="p-0">
                      <EditStatusMenu
                        member={item}
                        onSuccess={() => refetchMemberManagement()}
                      />
                    </StyledMenu.Item>
                  )}
                  <StyledMenu.Item className="p-0">
                    <EditProfileValidation member={item} />
                  </StyledMenu.Item>
                  {ALLOWED_EDIT_MEMBER_MARKER && (
                    <StyledMenu.Item className="p-0">
                      <EditMemberLevelMenu
                        member={item}
                        onSuccess={() => refetchMemberManagement()}
                      />
                    </StyledMenu.Item>
                  )}
                  {ALLOWED_CHANGE_PASSWORD && (
                    <StyledMenu.Item className="p-0">
                      <ChangePasswordMenu
                        member={item}
                        refetchParams={refetchParams}
                      />
                    </StyledMenu.Item>
                  )}
                  {ALLOWED_CHANGE_WITHDRAWAL_PASSWORD && (
                    <StyledMenu.Item className="p-0">
                      <ChangeWithdrawalPasswordMenu
                        member={item}
                        refetchParams={refetchParams}
                      />
                    </StyledMenu.Item>
                  )}
                  {/** commented this out to hide it on the screen */}
                  {/* {ALLOWED_EDIT_VIP && (
                    <StyledMenu.Item className="p-0">
                      <EditMultiVIP
                        members={[item]}
                        onSuccess={() => refetchMemberManagement()}
                      />
                    </StyledMenu.Item>
                  )} */}
                  {ALLOWED_SEND_MESSAGE && (
                    <StyledMenu.Item className="p-0">
                      <SendMessage members={[item.id]} />
                    </StyledMenu.Item>
                  )}
                </StyledMenu>
              </div>
            }
            placement="bottomRight"
          >
            <div
              className="text-center"
              data-testid={MEMBER_MGMT.memberEditDropdown}
            >
              <EllipsisOutlined
                className="cursor-pointer font-weight-bold"
                style={{ fontSize: '25px' }}
              />
            </div>
          </Dropdown>
        ),
    },
  ].filter(Boolean);

  const {
    setSelectedRows,
    selectedRowKeys,
    setSelectedRowKeys,
  } = useSelectedRows();

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 30,
    onChange: (rowKeys: Record<string, any>, rows: any[]) => {
      setSelectedRowKeys(rowKeys);
      setSelectedRows((prev: any[]) => {
        const currentRowsSelected = uniqBy([...prev, ...rows], 'id');

        return currentRowsSelected.filter((row) => rowKeys.includes(row.id));
      });
    },
  };

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const membersData = members
    ? members.map(({ node }: { node: Record<string, any> }) => ({
        ...node,
        key: node.id,
      }))
    : [];

  const newColumns: any = columnsInit.map((col) => ({
    ...col,
  }));

  const { filterColumns } = useCustomColumnsV2(
    'member-management',
    newColumns as any
  );

  const { filter } = refetchParams;

  return (
    <>
      <div style={{ height: 'calc(100vh - 205px)', overflow: 'hidden' }}>
        <NewStyledTable
          tableLayout="fixed"
          loading={loading}
          rowSelection={rowSelection}
          rowKey="id"
          columns={filterColumns(newColumns)}
          size="small"
          dataSource={membersData}
          pagination={false}
          scroll={{
            y: 'calc(100vh - 288px)',
            x: 2350,
          }}
          components={components}
          style={{ flex: 1 }}
          // widthLessThan1440={widthLessThan1440}
          id={MEMBER_MGMT.table}
          data-testid={MEMBER_MGMT.table}
        />
      </div>

      <div style={{ marginTop: 'auto' }}>
        <div
          className="d-flex justify-content-space-between align-items-center"
          style={{
            borderTop: '1px solid #e8e8e8',
            padding: '1.2rem',
            height: '2.4vh',
            background: '#f3f8fb',
          }}
        >
          <AccountSum
            page={page}
            filter={{
              ...transformMovableDateFilter(finalQueryFilter),
              ...(filter.memberLoyaltyLevel && {
                memberLoyaltyLevel: {
                  in: filter.memberLoyaltyLevel.in,
                },
              }),
            }}
          />
          <Spin spinning={loading}>
            <div className="styled-center">
              <div className="mr-3 text-truncate">
                <Typography.Text strong className="mr-1">
                  {loading ? '#' : totalCount}
                </Typography.Text>
                <FormattedMessage
                  id="results-found.text"
                  defaultMessage="Results Found"
                  values={{
                    count: null,
                  }}
                />
              </div>
              <NextPrevButton
                onPrev={onPrev}
                disablePrev={page.currentPage + 1 === 1}
                onNext={() => onNext(pageInfo)}
                disableNext={page.currentPage + 1 === totalPage}
                label={`${page.currentPage + 1} / ${totalPage}`}
              />
              <TableFooterPages
                loading={false}
                values={tablePagesForFooter}
                setPage={setPage}
                page={page}
              />
            </div>
          </Spin>
        </div>
      </div>

      {/* Modals */}

      {selectedEditMember && (
        <EditMember
          member={removeTypename(selectedEditMember)}
          refetchQueries={[refetchQuery]}
          handleShow={() => setSelectedEditMember(null)}
          isShow={selectedEditMember}
        />
      )}
    </>
  );
};

export default withResponsive(memo(MembersTable));
