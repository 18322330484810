import React, { useState } from 'react';
import { Select } from 'antd';
import { DocumentNode } from 'graphql';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDebounce } from 'hooks/useDebounce';
import { uniq } from 'lodash';
import { checkPartial } from 'utils/partialUtils';
import {
  StyledLabel,
  StyledSelect,
  StyledSpan,
} from 'styles/SharedStyledSelectFilter';
import { AvailableCurrency } from 'types/graphqlTypes-row';

const { Option } = Select;

type Props = {
  label: string;
  query: DocumentNode;
  queryConnection: string;
  filterFieldName: string;
  filters: any;
  fieldType?: string;
  onChange: (e: string[] | null) => void;
  manipulateOptionFunction?: Function;
  partialSupported?: boolean;
  additionalOptionsFilter?: { [key: string]: Record<string, unknown> };
  queryOperation?: string;
  testId?: string;
  showLabel?: boolean;
  className?: string;
  multiple?: boolean;
  isAvailableCurrencyQuery?: boolean;
  onKeyDown?: (e: { key: string; preventDefault: () => void }) => void;
  allowFilterOption?: boolean;
};

type ConnectionEdge = {
  node: {
    [key: string]: {
      [key: string]: unknown;
    };
  };
};

export default ({
  label,
  query,
  filterFieldName,
  filters: value,
  onChange,
  queryConnection,
  fieldType,
  manipulateOptionFunction,
  partialSupported = true,
  additionalOptionsFilter,
  queryOperation = 'in',
  isAvailableCurrencyQuery = false,
  testId,
  showLabel = true,
  className,
  multiple = true,
  onKeyDown,
  allowFilterOption = false,
}: Props) => {
  const [options, setOptions] = useState<string[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const [partialText, setPartialText] = useState('');
  const debouncedText = useDebounce(searchedText, 500);

  const [loadOptions, { loading }] = useLazyQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 10,
      filter: {
        [filterFieldName]: {
          contains: debouncedText,
        },
        ...additionalOptionsFilter,
      },
    },
    context: { shouldBatch: true },
    onCompleted: (data) => {
      const rawOptions = () =>
        data[`${queryConnection}`]?.edges.map(({ node }: ConnectionEdge) => {
          if (fieldType) {
            return node[fieldType][filterFieldName];
          }

          return node[filterFieldName];
        });

      const rawOptionAvailableCurrency = () =>
        data.availableCurrencies.map((d: AvailableCurrency) => d.code);

      const uniqueOptions: string[] = uniq(
        isAvailableCurrencyQuery ? rawOptionAvailableCurrency() : rawOptions()
      );

      /**
       * @desc Create a custom function and pass it as props in this component to manipulate filter options on special cases.
       * @function manipulateOptionFunction
       * @author Christopher Sotero| pogi
       */

      const finalOptions = manipulateOptionFunction
        ? manipulateOptionFunction!(uniqueOptions)
        : uniqueOptions;

      setOptions(finalOptions);
    },
  });

  const values = value?.[filterFieldName]?.[queryOperation] || [];

  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange(e);
    } else {
      onChange(null);
    }

    setPartialText('');
    setSearchedText('');
  };

  const hasPartial = checkPartial(filterFieldName, value);

  return (
    <div className={className || 'my-2'}>
      {showLabel && (
        <div className="d-flex justify-content-between">
          <div>
            <StyledLabel>{label}</StyledLabel>
          </div>
          <div>
            <StyledSpan onClick={() => handleChange([])}>Clear</StyledSpan>
          </div>
        </div>
      )}

      <StyledSelect
        data-testid={testId}
        mode={multiple ? 'multiple' : undefined}
        value={multiple ? values : value}
        showSearch
        placeholder={`Enter ${label}`}
        onChange={handleChange}
        filterOption={
          allowFilterOption
            ? (input, option) =>
                option?.children
                  ?.toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
            : false
        }
        loading={loading}
        onSearch={(text: string) => {
          setSearchedText(text);
          setPartialText(text);
        }}
        onFocus={() => setSearchedText('')}
        onDropdownVisibleChange={(open) => {
          if (open && !options.length) loadOptions();
        }}
        onBlur={() => setSearchedText('')}
        onKeyDown={onKeyDown}
      >
        {!hasPartial && partialText.length && partialSupported && (
          <Option
            key={11}
            value={`Partial: ${partialText}`}
            label={`Partial: ${partialText}`}
          >
            Partial: {partialText}
          </Option>
        )}

        {options.map((option, index) => (
          <Option
            data-testid={`${testId}-option-${option}`}
            key={index}
            value={option}
          >
            {option}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
};
