import gql from 'graphql-tag';

export const UPDATE_SITE_CONFIGURATION = gql`
  mutation updateSiteConfiguration($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

export const DELETE_DOMAIN = gql`
  mutation DeleteDomain($id: ID!) {
    deleteDomain(id: $id)
  }
`;

export const UPDATE_DOMAIN = gql`
  mutation UpdateDomain($id: ID!, $input: UpdateDomainInput!) {
    updateDomain(id: $id, input: $input)
  }
`;
