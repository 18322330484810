import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isBoolean } from 'lodash';
import styled from 'styled-components';
import { InputNumber } from 'antd';
import useTranslate from 'utils/useTranslate';
import { blockAlphabets } from 'utils/blockAlphabets';
import { checkBothValidity, CheckIcon, checkValidity, messages } from './utils';
import { StyledSpan, StyledLabel } from '../styles';

export const StyledInput = styled(InputNumber)`
  width: 100%;
  border-radius: 2px;

  input {
    padding-right: 25px !important;
  }

  .ant-input-number-handler-wrap {
    display: none !important;
  }
`;

const initState = {
  gte: 0,
  lte: 0,
};

type Props = {
  setState: any;
  state: Record<string, any>;
  disabled: boolean;
};

const MinMaxFilter = ({ setState, state, disabled }: Props) => {
  const [input, setInput] = useState<any>(initState);
  const [valid, setValid] = useState({
    gte: null,
    lte: null,
  });
  const [initialized, setInitialized] = useState(false);

  const value = state.amount;

  const onChange = (e: Record<string, any> | null) => {
    const newValue = {
      ...(e && (e.gte || e.gte === 0) && { gte: e.gte }),
      ...(e && (e.lte || e.lte === 0) && { lte: e.lte }),
    };

    const amount = e === null ? null : newValue;

    setState((prev: Record<string, any>) => ({
      ...prev,
      amount,
    }));
  };

  useEffect(() => {
    if (!initialized) {
      return;
    }
    const hasOneTrueAndNull =
      Object.values(valid).some((x) => x === true) &&
      Object.values(valid).some((x) => x === null);
    if (hasOneTrueAndNull) {
      onChange({
        gte: input.gte,
        lte: input.lte,
      });
    }

    if (valid.gte && valid.lte) {
      onChange({
        ...value,
        gte: input.gte,
        lte: input.lte,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);

  useEffect(() => {
    if (!initialized) {
      if (value) {
        checkBothValidity(
          value.gte,
          value.lte,
          setValid as any,
          setInitialized
        );
      } else {
        setInitialized(true);
      }
    }

    setInput(value || initState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onInputKeyDown = (type: any) => (event: any) => {
    blockAlphabets(event);
    if (event.key === 'Enter') {
      checkValidity(type, input, setValid);
    }
  };
  const translate = useTranslate();
  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.amount.text"
              defaultMessage="Amount"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => onChange(null)}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      {/* <div className="mb-1">
        <FormattedMessage id="reports.amount.text" defaultMessage="Amount" />
      </div> */}

      <div className="d-flex">
        <div className="position-relative">
          <StyledInput
            value={input.gte}
            name="gte"
            onFocus={(e) => e.target.select()}
            onBlur={() => checkValidity('min', input, setValid)}
            placeholder={translate(messages['minimum.text'])}
            onChange={(e) => {
              if (e === '') {
                setValid((prev) => ({ ...prev, gte: null }));
                if (input.lte === 0) {
                  setValid((prev) => ({ ...prev, lte: null }));
                  onChange(null);
                }
              }

              setInput((prev: Record<string, any>) => ({
                ...prev,
                gte: e || 0,
              }));
            }}
            disabled={disabled}
            onKeyDown={onInputKeyDown('min')}
          />
          {isBoolean(valid.gte) && <CheckIcon correct={valid.gte!} />}
        </div>
        <div className="d-flex align-items-center">&nbsp;-&nbsp;</div>
        <div className="position-relative">
          <StyledInput
            name="lte"
            onFocus={(e) => e.target.select()}
            onBlur={() => checkValidity('max', input, setValid)}
            value={input.lte}
            placeholder={translate(messages['maximum.text'])}
            onChange={(e) => {
              if (e === '') {
                setValid((prev) => ({ ...prev, lte: null }));
                if (input.gte === 0) {
                  setValid((prev) => ({ ...prev, gte: null }));
                  onChange(null);
                }
              }

              setInput((prev: Record<string, any>) => ({
                ...prev,
                lte: e || 0,
              }));
            }}
            disabled={disabled}
            onKeyDown={onInputKeyDown('max')}
          />

          {isBoolean(valid.lte) && <CheckIcon correct={valid.lte!} />}
        </div>
      </div>
    </>
  );
};

export default MinMaxFilter;
