import React, { useState, useRef, useCallback } from 'react';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { CloseOutlined } from '@ant-design/icons';
import { Modal, message, Popconfirm } from 'antd';
import { get, isEmpty, difference } from 'lodash';
import diffObject from 'utils/diffObject';
import removeTypename from 'utils/removeTypename';

import useTranslate from 'utils/useTranslate';
import globalMessages from 'messages';
import messages from 'pages/components/PaymentMethodListing/messages';
import { NoBorderButton, FlexItem, StyledItem } from './styles';
import PaymentMethods from './PaymentMethods';
import { GET_PAYMENT_SETTINGS, UPDATE_PAYMENT_SETTINGS } from './queries';
import validationSchema from './validationSchema';

type Props = {
  filters?: Record<string, any>;
  onFilterChange?: (data: Record<string, any>) => void;
  setPopOver: (show: boolean) => void;
};

const PaymentMethodsConfig = ({
  filters,
  onFilterChange,
  setPopOver,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const $formikForm = useRef<any>(null);
  const handleVisibleChange = (show: boolean) => {
    if (isDirty) {
      window.scrollTo(0, 0);
      setShowWarning(!show);
    } else {
      setPopOver(show);
      setIsModalVisible(show);
    }
  };
  const translate = useTranslate();
  const onReset = useCallback(() => {
    setIsDirty(false);
    setIsModalVisible(false);
    setShowWarning(false);
    $formikForm.current.props.onReset();
  }, [$formikForm]);

  // get
  const { data } = useQuery(GET_PAYMENT_SETTINGS);

  const config = get(data, 'config', {
    paymentMethodConfiguration: [],
    paymentMethodRotation: {
      successRateWeight: 90,
      elapseMinuteWeight: 10,
    },
  });

  // update
  const [updatePaymentSetting] = useMutation(UPDATE_PAYMENT_SETTINGS, {
    onCompleted: () => {
      message.success(translate(globalMessages.PAYMENT_CONFIGURATION_UPDATED));
      onReset();
    },
    refetchQueries: [
      {
        query: GET_PAYMENT_SETTINGS,
      },
    ],
  });

  const TitleComponent = (
    <FlexItem>
      <h4>{translate(messages.settings)}</h4>
      <NoBorderButton size="small" onClick={() => handleVisibleChange(false)}>
        <CloseOutlined />
      </NoBorderButton>
    </FlexItem>
  );
  const onMethodFilter = (event: Record<string, any>) => {
    const newFilters = {
      ...filters,
      paymentMethodType: {
        in: [event.target.getAttribute('data-type')],
      },
    };
    if (onFilterChange) {
      onFilterChange(newFilters);
    }
  };

  const initialValues = {
    paymentMethodConfiguration: [...config.paymentMethodConfiguration].sort(
      (prev, next) => prev.rank - next.rank
    ),
    successRateWeight: config.paymentMethodRotation.successRateWeight,
    elapseMinuteWeight: config.paymentMethodRotation.elapseMinuteWeight,
  };

  const onSubmit = ({
    paymentMethodConfiguration,
    successRateWeight,
    elapseMinuteWeight,
  }: any) => {
    const methods = removeTypename(
      difference(paymentMethodConfiguration, config.paymentMethodConfiguration)
    );
    const rotation = {
      successRateWeight,
      elapseMinuteWeight,
    };

    const paymentMethodRotation = diffObject(
      rotation,
      config.paymentMethodRotation
    );

    const input = {
      ...(isEmpty(methods)
        ? {}
        : {
            paymentMethodConfiguration: methods,
          }),
      ...(isEmpty(paymentMethodRotation)
        ? {}
        : {
            paymentMethodRotation,
          }),
    };
    updatePaymentSetting({ variables: { input } });
    setPopOver(false);
  };

  return (
    <>
      <StyledItem
        className="text-left"
        onClick={() => {
          setIsModalVisible(true);
          setPopOver(false);
        }}
      >
        {translate(messages.PAYMENT_METHODS_CONFIG)}
      </StyledItem>
      <Modal
        title={TitleComponent}
        visible={isModalVisible}
        footer={null}
        closable={false}
      >
        <Popconfirm
          title={translate(messages.confirmsettings)}
          onConfirm={() => $formikForm.current.props.onSubmit()}
          onCancel={onReset}
          visible={showWarning}
          placement="left"
        >
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
          >
            <PaymentMethods
              onMethodFilter={onMethodFilter}
              onTouchForm={setIsDirty}
              ref={$formikForm}
            />
          </Formik>
        </Popconfirm>
      </Modal>
    </>
  );
};

export default PaymentMethodsConfig;
