import React from 'react';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import { TabId } from 'constants/route';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import useTranslate from 'utils/useTranslate';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import { Divider } from 'antd';
import { ReactComponent as UserIcon } from 'components/assets/user.svg';
import { ReactComponent as RoundSearchIcon } from 'components/assets/round-search.svg';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import removeNull from 'utils/removeNull';
import messages from '../../messages';
import { StyledSearchContainer } from '../../styles';

const HeaderRight = ({
  toggleSearchMember,
  memberInfo,
  refreshPage,
  filters,
}: {
  toggleSearchMember: () => void;
  memberInfo: Record<string, any>;
  refreshPage: () => void;
  filters: {
    [key: string]: any;
  };
}) => {
  const translate = useTranslate();
  const { role, permissions } = usePermissions();
  const { ALLOWED_CSV_DOWNLOAD_BUTTON } = collectPermissions(
    role,
    permissions,
    ['CSV_DOWNLOAD_BUTTON'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS_BALANCE_SERVICE_RECORD_REPORTS
  );

  return (
    <>
      <StyledSearchContainer onClick={toggleSearchMember} className="mr-2">
        <UserIcon />
        {memberInfo.username}
        <RoundSearchIcon />
      </StyledSearchContainer>
      <RefreshButton onClick={refreshPage} />
      <Divider type="vertical" className="ml-0 mr-2" />
      <ColumnsFilterNew
        iconOnly
        tabId={TabId.BALANCE_SERVICE_RECORD_REPORT as any}
      />
      <SpreadsheetDownload
        iconOnly
        filter={removeNull(filters)}
        filename={translate(messages.BALANCE_SERVICE_REPORT)}
        extension="csv"
        permission={ALLOWED_CSV_DOWNLOAD_BUTTON}
        reportName="Balance Service Record Report"
      />
    </>
  );
};

export default HeaderRight;
