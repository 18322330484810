import React, { useState } from 'react';
import { Tag } from 'antd';
import { isEqual } from 'lodash';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { ALink } from 'components/ALink/ALink';
import { useFilterValues } from 'contexts/Filters';
import { StyledTagContainer } from 'styles';
import useTranslate from 'utils/useTranslate';
import globalMsgs from 'messages';
import { useScreenTabV2 } from 'store/screenTabState';

const HeaderLeft = () => {
  const translate = useTranslate();
  const {
    defaultValues,
    filters,
    handleFilterChange,
    resetFilterToDefault,
  } = useFilterValues();

  const { clearTabState } = useScreenTabV2('ip-address-summary');
  const [expandedTags, toggleExpandedTags] = useState<boolean>(false);

  const removeOneIpFilter = (removeIp: string, path: string) => {
    const currentFilter = filters.ipAddress;

    const newFilter = {
      ...currentFilter,
      [path]: currentFilter[path].filter((ip: string) => ip !== removeIp),
    };

    if (!newFilter[path].length) delete newFilter[path];

    handleFilterChange({
      name: 'ipAddress',
      value: Object.keys(newFilter).length ? newFilter : null,
    });
  };

  const removeCountFilter = (path: 'gte' | 'lte') => {
    const newFilter = { ...filters.membersCount };

    delete newFilter[path];

    handleFilterChange({
      name: 'membersCount',
      value: Object.keys(newFilter).length ? newFilter : null,
    });
  };

  const renderIpFilterTags = (ipAddresses: { in: Array<string> }) =>
    ipAddresses.in.map((ip: string) => (
      <Tag key={ip} closable onClose={() => removeOneIpFilter(ip, 'in')}>
        {translate(globalMsgs.IP_ADDRESS)}:{ip}
      </Tag>
    ));

  const renderCountFilterTag = (membersCount: { gte: number; lte: number }) => (
    <>
      {membersCount.gte ? (
        <Tag closable onClose={() => removeCountFilter('gte')}>
          {translate(globalMsgs['minimum-current-amount.text'])}:
          {membersCount.gte}
        </Tag>
      ) : null}
      {membersCount.lte ? (
        <Tag closable onClose={() => removeCountFilter('lte')}>
          {translate(globalMsgs['minimum-current-amount.text'])}:
          {membersCount.lte}
        </Tag>
      ) : null}
    </>
  );

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className="d-flex align-items-start flex-g-1">
      <ToggleSidebarHead />
      <StyledTagContainer ref={ref} isExpanded={expandedTags}>
        <div className="ml-1">
          {translate(globalMsgs.FILTER_CONDITIONS)}:
          <span className="mr-2" />
          {filters.ipAddress ? renderIpFilterTags(filters.ipAddress) : null}
          {filters.membersCount
            ? renderCountFilterTag(filters.membersCount)
            : null}
        </div>
      </StyledTagContainer>
      <LegacyIconToggle
        forwardedRef={ref}
        filters={filters}
        expandedTags={expandedTags}
        toggleExpandedTags={toggleExpandedTags}
      />
      {!isEqual(filters, defaultValues) && (
        <ALink
          onClick={() => {
            clearTabState();
            resetFilterToDefault();
          }}
          style={{ marginTop: '0.35rem' }}
        >
          {translate(globalMsgs.CLEAR_ALL)}
        </ALink>
      )}
    </div>
  );
};

export default HeaderLeft;
