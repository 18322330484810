import React, { useEffect } from 'react';
import coercedGet from 'utils/coercedGet';
import Circle from 'components/Circle';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';

interface Props {
  statusID: string;
  refetchID: string;
  formatMessage: any;
}

const WithdrawalMethodStatus = ({ statusID, refetchID }: Props) => {
  const translate = useTranslate();
  const {
    statusLoaderSet: [useStatusLoader],
  } = useLoaders();
  const { loading, error, data = {}, refetch } = useStatusLoader(statusID);

  useEffect(() => {
    if (refetchID === statusID) {
      refetch();
    }
  }, [refetchID, statusID, refetch]);

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;

  const enabled = coercedGet(data, 'enabled', null);
  return (
    <>
      <Circle size={15} color={enabled ? '#52c41a' : '#ff0000'} filled />{' '}
      {translate(messages[enabled ? 'enable' : 'disable'])}
    </>
  );
};

export default WithdrawalMethodStatus;
