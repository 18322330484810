import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { Select, ConfigProvider, Empty } from 'antd';
import { MemberBetRecordsConnectionEdge } from 'types/graphqlTypes';
import messages from 'pages/components/Reports/messages';
import useTranslate from 'utils/useTranslate';
import { uniq } from 'lodash';
import { MEMBER_BET_RECORD_PAYOUT } from './queries';
import { StyledLabel, StyledSelect, StyledSpan } from '../styles';

type Props = {
  setState: React.Dispatch<
    React.SetStateAction<{
      [x: string]: Record<string, string[]> | null;
    }>
  >;
  state: { [key: string]: Record<string, string> };
};

const PayoutAmountFilter = ({ state, setState }: Props) => {
  const translate = useTranslate();
  const [options, setOptions] = useState<number[]>([]);

  const [loadPayoutAmounts, { called, loading }] = useLazyQuery(
    MEMBER_BET_RECORD_PAYOUT,
    {
      onCompleted: (data) => {
        const {
          memberBetRecords: { edges = [] },
        } = data;

        const rawPayouts = edges.map(
          ({ node }: MemberBetRecordsConnectionEdge) => node.payout
        );

        const payoutAmounts: number[] = uniq(rawPayouts);

        setOptions(payoutAmounts);
      },
    }
  );

  if (!called) loadPayoutAmounts();

  useEffect(() => {
    loadPayoutAmounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleChange = (e: any) => {
    if (e.length) {
      setState((prev: Record<string, any>) => ({
        ...prev,
        payout: { in: e },
      }));
    } else {
      setState((prev: Record<string, any>) => ({
        ...prev,
        payout: null,
      }));
    }
  };

  const values = state?.payout?.in ?? [];

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.payout-amount.text"
              defaultMessage="Payout Amount"
            />
          </StyledLabel>
        </div>
        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={translate(messages['no-data.text'])}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        <StyledSelect
          className="w-100"
          allowClear
          mode="multiple"
          loading={loading}
          placeholder="Select payout amount"
          value={values}
          onChange={handleChange}
        >
          {options?.map((option, index) => (
            <Select.Option key={index} value={option}>
              {option}
            </Select.Option>
          ))}
        </StyledSelect>
      </ConfigProvider>
    </>
  );
};

export default PayoutAmountFilter;
