import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DynamicObj } from 'interfaces/user.interface';
import FilterItems from '../FilterItems';

type Props = {
  filters: DynamicObj;
  initializedFilters: boolean;
  setInitializedFilters: (e: boolean) => void;
  onFilterChange: (e: any) => void;
};

const Sidebar = (props: Props) => {
  const { filters, onFilterChange } = props;

  const handleChange = ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => {
    onFilterChange((prevFilter: DynamicObj) => ({
      ...prevFilter,
      [name]: value,
    }));
  };
  return (
    <Form layout="vertical">
      <FilterItems filters={filters} onRawFilterChange={handleChange} />
    </Form>
  );
};

export default Sidebar;
