import React, { useState } from 'react';
import { CloseSquareOutlined, LayoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useFilterValues } from 'contexts/Filters';
import messages from 'messages';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import { FormattedMessage } from 'react-intl';
import LegacyIconToggle from 'components/LegacyIconToggle';
import FilterConditions from './FilterConditions';

const StyledTagContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  min-height: 37px;
  max-height: ${({ isExpanded }: { isExpanded: boolean }) =>
    isExpanded ? 'unset' : '37px'};

  .ant-tag {
    margin: 5px;
  }
`;

type Props = {
  toggleSidebar: () => void;
  sidebarStatus: boolean;
};

const HeaderLeft: React.FC<Props> = ({ toggleSidebar, sidebarStatus }) => {
  const translate = useTranslate();
  const [expandedTags, toggleExpandedTags] = useState(false);
  const { defaultValues, filters, resetFilterToDefault } = useFilterValues();

  const newDefaultValues = { ...defaultValues, member: filters.member };

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className="d-flex align-items-start" style={{ width: '100%' }}>
      <Button
        onClick={() => toggleSidebar()}
        size="small"
        className="mr-3 mt-1"
      >
        {sidebarStatus ? <CloseSquareOutlined /> : <LayoutOutlined />}
      </Button>
      <StyledTagContainer
        ref={ref}
        isExpanded={expandedTags}
        className="d-flex align-items-center"
      >
        <FormattedMessage
          id="filter-conditions.text"
          defaultMessage="Filter conditions"
        />
        :<FilterConditions />
      </StyledTagContainer>
      <LegacyIconToggle
        forwardedRef={ref}
        filters={filters}
        expandedTags={expandedTags}
        toggleExpandedTags={toggleExpandedTags}
      />
      {!isEqual(filters, newDefaultValues) && (
        <a
          href="#section"
          onClick={resetFilterToDefault}
          style={{ marginTop: '0.35rem' }}
        >
          {translate(messages.CLEAR_ALL)}
        </a>
      )}
    </div>
  );
};

export default HeaderLeft;
