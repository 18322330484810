import styled from 'styled-components';
import { Switch } from 'antd';

export const StyledSwitch = styled(Switch)<any>`
  &.ant-switch-checked {
    background-color: #27c989;
  }
`;

export default {};
