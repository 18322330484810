/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, FC } from 'react';
import { StyledTable } from 'constants/styles';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import moment from 'moment';
import commonMessages from 'messages';
import useTranslate from 'utils/useTranslate';
import {
  MemberInteractionLogFilterInput,
  MemberInteractionLogConnectionEdge,
  MemberInteractionLog,
  Account,
  Scalars,
  Member,
  MemberInteractionLogActor,
} from 'types/graphqlTypes';
import { types, messages } from 'constants/memberInteractionLog';
import RemarksBubble from 'components/RemarksBubble';
import {
  getInteractionModuleField,
  getExternalModule,
} from 'utils/memberInteractionLog';
import { DATE_TIME_FORMAT } from 'constants/date';
import { capitalize } from 'lodash';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import ExternalModule from '../ExternalModule';

type Props = {
  dataSource: [MemberInteractionLogConnectionEdge];
  loading: boolean;
  refetchVariables: {
    first: number;
    after: string | undefined;
    filter: MemberInteractionLogFilterInput;
  };
};

const MemberInteractionLogTable: FC<Props> = ({ dataSource, loading }) => {
  const translate = useTranslate();
  const [state, setState] = useState({
    selectedRowKeys: [],
  });

  const { setTableColumns } = useSpreadsheetContext();

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = dataSource
    ? dataSource.map(({ node }) => ({ ...node, key: node.id }))
    : [];

  const columns = [
    {
      csvData: {
        label: translate(commonMessages.INTERACTION_LOG_ID),
        key: 'shortId',
        renderCell: (record: MemberInteractionLog) => record.shortId || '-',
      },
      key: 'shortId',
      width: 160,
      title: translate(commonMessages.INTERACTION_LOG_ID),
      dataIndex: 'shortId',
    },
    {
      csvData: {
        label: translate(commonMessages['date-time.text']),
        key: 'dateTimeCreated',
        renderCell: (record: MemberInteractionLog) =>
          moment(record.dateTimeCreated).format(`${DATE_TIME_FORMAT} A`),
      },
      key: 'dateTimeCreated',
      title: translate(commonMessages['date-time.text']),
      dataIndex: 'dateTimeCreated',
      width: 105,
      render: (dateTimeCreated: Scalars['DateTime']) =>
        moment(dateTimeCreated).format(`${DATE_TIME_FORMAT} A`),
    },
    {
      csvData: {
        label: translate(commonMessages.MEMBER),
        key: 'member',
        renderCell: (record: MemberInteractionLog) =>
          record.member?.username || '-',
      },
      key: 'member',
      width: 120,
      title: translate(commonMessages.MEMBER),
      dataIndex: 'member',
      render: (member: Account) => member.username || '-',
    },
    {
      csvData: {
        label: 'Brand ID',
        key: 'brandId',
        renderCell: (record: MemberInteractionLog) =>
          record.member?.brandId || '-',
      },
      key: 'brandId',
      width: 120,
      title: 'Brand ID',
      dataIndex: 'member',
      render: (member: Member) => member.brandId || '-',
    },
    {
      csvData: {
        label: 'Platform ID',
        key: 'platformId',
        renderCell: (record: MemberInteractionLog) =>
          record.member?.platformId || '-',
      },
      key: 'platformId',
      width: 120,
      title: 'Platform ID',
      dataIndex: 'member',
      render: (member: Member) => member.platformId || '-',
    },
    {
      csvData: {
        label: translate(commonMessages.MODULE),
        key: 'module',
        renderCell: (record: MemberInteractionLog) =>
          record.module ? translate(messages[record.module]) : '-',
      },
      key: 'module',
      width: 120,
      title: translate(commonMessages.MODULE),
      dataIndex: 'module',
      render: (module: string) => (module ? translate(messages[module]) : '-'),
    },
    {
      csvData: {
        label: translate(commonMessages.ACTION),
        key: 'action',
        renderCell: (record: MemberInteractionLog) =>
          types[`${record.type}`]
            ? translate(messages[`${types[`${record.type}`]?.action}`])
            : record.type,
      },
      key: 'action',
      width: 120,
      title: translate(commonMessages.ACTION),
      dataIndex: 'type',
      render: (type: string) =>
        types[`${type}`]
          ? translate(messages[`${types[`${type}`]?.action}`])
          : type,
    },
    {
      csvData: {
        label: translate(commonMessages.MANUAL),
        key: 'manual',
        renderCell: (record: MemberInteractionLog) =>
          record.manual
            ? translate(commonMessages.YES)
            : translate(commonMessages.NO),
      },
      key: 'manual',
      width: 80,
      title: translate(commonMessages.MANUAL),
      dataIndex: 'manual',
      render: (manual: boolean) =>
        manual ? translate(commonMessages.YES) : translate(commonMessages.NO),
    },
    {
      csvData: {
        label: translate(commonMessages.EXTERNAL_MODULE_ID),
        key: 'externalModuleId',
        renderCell: (record: MemberInteractionLog) =>
          `${getExternalModule(record) || '-'}`,
      },
      key: 'externalModuleId',
      width: 100,
      title: translate(commonMessages.EXTERNAL_MODULE_ID),
      render: (record: MemberInteractionLog) => <ExternalModule log={record} />,
    },
    {
      csvData: {
        label: translate(commonMessages.REMARKS),
        key: 'remarks',
        renderCell: (record: MemberInteractionLog) =>
          `${getInteractionModuleField(record, 'remarks') || '-'}`,
      },
      key: 'remarks',
      width: 80,
      title: translate(commonMessages.REMARKS),
      render: (record: MemberInteractionLog) => {
        const remarks = getInteractionModuleField(record, 'remarks');
        return remarks ? (
          <RemarksBubble remarks={remarks} date="" toolTipId="" />
        ) : (
          '-'
        );
      },
    },
    {
      csvData: {
        label: translate(commonMessages.ACTOR),
        key: 'actor',
        renderCell: (record: MemberInteractionLog) =>
          record.actor?.account?.username || '-',
      },
      key: 'actor',
      width: 120,
      title: translate(commonMessages.ACTOR),
      dataIndex: 'actor',
      render: (actor: MemberInteractionLogActor) =>
        capitalize(actor.type) || '-',
    },
  ];

  const { filterColumns } = useCustomColumnsV2(
    'member-interaction-log-report',
    columns
  );

  useEffect(() => {
    if (!loading) {
      setTableColumns(filterColumns(columns));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <StyledTable
        loading={loading}
        rowSelection={rowSelection}
        columns={filterColumns(columns)}
        size="small"
        dataSource={data}
        pagination={false}
        scroll={{ y: 640, x: 'calc(525px + 50%)' }}
        rowKey="id"
      />
    </>
  );
};

export default MemberInteractionLogTable;
