import React, { useState } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Divider, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { groupBy } from 'lodash';
import { CSVLink } from 'react-csv';
import { Member } from 'interfaces/user.interface';
import { ALink } from 'components/ALink/ALink';
import copyToClipboard from 'utils/copyToClipboard';
import useTranslate from 'utils/useTranslate';

export default ({
  processType,
  showSubmitProcess,
  showProcessComplete,
  successMembers,
  invalidMembers,
  totalMembers,
  moreDetails,
  setMoreDetails,
  onCloseModal,
}: AdjustmentByBulkProcessType) => {
  const [membersInvalid, setMembersInvalid] = useState<any[]>([]);
  let groupOfInvalidMembers = groupBy(invalidMembers, 'message');
  const translate = useTranslate();
  const downloadInvalidMembersAsCsv = () => {
    const translatedMessage = invalidMembers.map((member: any) => ({
      Username: member.username,
      Amount: member.amount,
      message: translate(messages[member.message], member.translateOpts),
    }));
    setMembersInvalid(translatedMessage);
  };

  if ('BLANK_USERNAME_DETECTED' in groupOfInvalidMembers) {
    const { BLANK_USERNAME_DETECTED, ...rest } = groupOfInvalidMembers;
    groupOfInvalidMembers = {
      ...rest,
      X_BLANK_USERNAMES_DETECTED: [
        // @ts-ignore
        { translateOpts: { count: BLANK_USERNAME_DETECTED.length } },
      ],
    };
  }

  return (
    <>
      {showSubmitProcess && (
        <div className="p-3 text-center">
          <Spin />
          <h2>
            {translate(messages.PROCESSING)}
            ...
          </h2>
          <p>
            {translate(
              messages['MANUAL_ADJUSTMENT.IMPORT_EXCEL_PROCESS_MESSAGE']
            )}
          </p>
        </div>
      )}

      {!showSubmitProcess && showProcessComplete && (
        <div>
          <>
            <div className="p-3">
              <div className="d-flex fs-16 fw-500">
                <span className="mr-1">
                  <CheckCircleFilled className="text-success mr-2" />
                  <FormattedMessage
                    id="success.text"
                    defaultMessage="Success"
                  />
                  :
                </span>
                {successMembers.length}/{totalMembers}
              </div>

              {invalidMembers.length > 0 && (
                <>
                  <div className="d-flex mt-3 fs-16 fw-500">
                    <span className="mr-1">
                      <CloseCircleFilled className="text-danger mr-2" />
                      <FormattedMessage
                        id="failed.text"
                        defaultMessage="Failed"
                      />
                      :
                    </span>
                    {invalidMembers.length}/{totalMembers}
                  </div>
                  <div className="mt-2">
                    <small className="fs-12">
                      {translate(
                        messages[
                          // eslint-disable-next-line no-nested-ternary
                          processType === 'excel'
                            ? 'MANUAL_ADJUSTMENT.IMPORT_EXCEL_FAILED_MESSAGE'
                            : processType === 'text'
                            ? 'MANUAL_ADJUSTMENT.IMPORT_TEXT_FAILED_MESSAGE'
                            : 'MANUAL_ADJUSTMENT.BULK_FAILED_MESSAGE'
                        ]
                      )}
                    </small>
                  </div>
                </>
              )}
            </div>
            <Divider className="m-0" />
            <div className="p-2">
              <>
                {invalidMembers.length === 0 && (
                  <div className="d-flex justify-content-flex-end">
                    <Button
                      type="primary"
                      onClick={onCloseModal}
                      style={{ width: '110px' }}
                    >
                      <FormattedMessage id="done.text" defaultMessage="Done" />
                    </Button>
                  </div>
                )}
                {invalidMembers.length > 0 && (
                  <div className="d-flex justify-content-center">
                    <Button
                      className="mr-2"
                      style={{ width: '110px' }}
                      onClick={() => setMoreDetails(!moreDetails)}
                    >
                      <span>
                        <FormattedMessage
                          id="details.text"
                          defaultMessage="Details"
                        />
                      </span>
                      <LegacyIcon type={moreDetails ? 'up' : 'down'} />
                    </Button>
                    <Button
                      type="primary"
                      style={{ width: '110px' }}
                      onClick={onCloseModal}
                    >
                      <FormattedMessage id="done.text" defaultMessage="Done" />
                    </Button>
                  </div>
                )}
              </>

              {moreDetails && (
                <div className="mt-3">
                  <div
                    className="pb-2 px-2"
                    style={{
                      background: '#f5f5f5',
                      borderRadius: '10px',
                      maxHeight: '170px',
                      overflow: 'auto',
                    }}
                  >
                    {Object.keys(groupOfInvalidMembers).map((errorKey) => (
                      <div className="mt-2" key={errorKey}>
                        <div className="fs-12 text-black--25">
                          {translate(
                            messages[errorKey],
                            (groupOfInvalidMembers[errorKey][0] as any)
                              .translateOpts
                          )}
                        </div>
                        {groupOfInvalidMembers[errorKey].map((member) => (
                          <div className="fs-12" key={member.username}>
                            {member.username}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="mt-2 d-flex justify-content-between">
                    <ALink>
                      <CSVLink
                        data={membersInvalid}
                        onClick={() => downloadInvalidMembersAsCsv()}
                      >
                        <FormattedMessage
                          id="download-csv.text"
                          defaultMessage="Download CSV"
                        />
                      </CSVLink>
                    </ALink>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                    <p
                      className="text-black--25 mb-0 cursor-pointer"
                      onClick={() => {
                        const usernames = invalidMembers.map(
                          ({ username }) => username
                        );
                        copyToClipboard(usernames.join(', '));
                      }}
                    >
                      <FormattedMessage
                        id="select-to-copy.text"
                        defaultMessage="Select to Copy"
                      />
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
};

type AdjustmentByBulkProcessType = {
  processType: 'excel' | 'text' | 'bulk';
  showSubmitProcess: boolean;
  showProcessComplete: boolean;
  successMembers: Member[];
  invalidMembers: Member[];
  totalMembers: number;
  moreDetails: boolean;
  setMoreDetails: (detail: boolean) => void;
  onCloseModal: () => void;
};
