import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Space,
  Switch,
  Typography,
  message,
} from 'antd';
import { GbgTrigger, Config, UpdateConfigInput } from 'types/graphqlTypes';
import gql from 'graphql-tag';
import { useForm, Controller } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const GBG_CONFIG = gql`
  query GBGConfig {
    config {
      enableGBG
      gbgTrigger
      gbgThresholdAmount
    }
  }
`;

const { Text } = Typography;

const ManageGBG = () => {
  const { context } = useOperatorHeader();
  const [visible, setVisible] = useState(false);
  const [isGBGEnabled, setIsGBGEnabled] = useState(true);

  const { control, reset, handleSubmit, setValue, errors, watch } = useForm({
    defaultValues: {
      enableGBG: true,
      gbgTrigger: GbgTrigger.AfterSuccessfulSignUp,
      gbgThresholdAmount: 0,
    },
  });

  const [updateGBGConfig, { loading: mutationLoading }] = useMutation(
    UPDATE_CONFIG,
    {
      context,
    }
  );

  const [loadGBGConfig, { loading }] = useLazyQuery<{ config: Config }>(
    GBG_CONFIG,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: ({ config }) => {
        reset({
          enableGBG: config.enableGBG!,
          gbgTrigger: config.gbgTrigger!,
          gbgThresholdAmount: config.gbgThresholdAmount!,
        });

        setIsGBGEnabled(config.enableGBG!);
      },
    }
  );

  const handleShow = () => {
    setVisible(true);
    loadGBGConfig();
  };

  const handleModalClose = () => {
    setVisible(false);
  };

  const onSubmit = async (values: UpdateConfigInput) => {
    try {
      await updateGBGConfig({
        variables: {
          input: {
            ...values,
            gbgThresholdAmount: +values.gbgThresholdAmount!,
          },
        },
      });
      message.success('GBG Configuration updated successfully');
    } catch (error) {
      if (error) message.error('Something went wrong, please try again.');
    } finally {
      handleModalClose();
    }
  };

  const thresholdRequired = watch('gbgTrigger') !== 'AFTER_SUCCESSFUL_SIGN_UP';

  return (
    <div>
      <h3>Manage GBG (User Verification)</h3>
      <Button onClick={handleShow}>Configure GBG Checks</Button>

      <Modal
        visible={visible}
        destroyOnClose
        title="Manage"
        closable
        onCancel={handleModalClose}
        centered
        okButtonProps={{ loading: loading || mutationLoading }}
        cancelButtonProps={{ loading: loading || mutationLoading }}
        onOk={handleSubmit(onSubmit)}
      >
        <div className="d-flex flex-column">
          <div className="d-flex w-50 justify-content-around mb-2">
            <Text strong>Enable GBG: </Text>
            <Controller
              control={control}
              name="enableGBG"
              render={({ value }) => (
                <Switch
                  onChange={(e) => {
                    setIsGBGEnabled(e);
                    setValue('enableGBG', e);
                  }}
                  checked={value}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                />
              )}
            />
          </div>
          {isGBGEnabled && (
            <>
              <Text strong className="ml-5 mb-2 font-italic">
                GBG Verification to Trigger at:
              </Text>
              <div className="d-flex flex-column  align-items-center">
                <Controller
                  control={control}
                  name="gbgTrigger"
                  render={({ value }) => (
                    <>
                      <Radio.Group
                        onChange={(e) => setValue('gbgTrigger', e.target.value)}
                        value={value}
                      >
                        <Space direction="vertical">
                          <Radio value={GbgTrigger.AfterSuccessfulSignUp}>
                            After Successful Sign Up
                          </Radio>
                          <Radio className="mr-2" value={GbgTrigger.Withdrawal}>
                            Withdrawal
                          </Radio>
                          <Radio className="mr-2" value={GbgTrigger.Deposit}>
                            Deposit
                          </Radio>
                          <Radio
                            className="mr-2"
                            value={GbgTrigger.DepositOrWithdrawal}
                          >
                            Deposit or Withdrawal
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </>
                  )}
                />
              </div>
              <Text strong className="ml-5 mb-2 font-italic mt-3">
                GBG Threshold Amount:
              </Text>
              <div className="d-flex ml-5">
                <Form.Item
                  validateStatus={errors.gbgThresholdAmount && 'error'}
                  help={
                    errors.gbgThresholdAmount &&
                    errors.gbgThresholdAmount.message
                  }
                >
                  <Controller
                    control={control}
                    name="gbgThresholdAmount"
                    rules={{
                      required: {
                        value: thresholdRequired,
                        message: 'GBG Threshold Amount is required.',
                      },
                    }}
                    render={({ value, onChange }) => (
                      <Input
                        value={value}
                        onChange={onChange}
                        placeholder="Enter an amount"
                        type="number"
                        min={0}
                        disabled={!thresholdRequired}
                      />
                    )}
                  />
                </Form.Item>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ManageGBG;
