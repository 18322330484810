import React from 'react';
import {
  CloseOutlined,
  InboxOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { Col, Upload, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isEmpty, has } from 'lodash';
import { RcFile } from 'antd/es/upload/interface';

const { Text } = Typography;

type Props = {
  fileList: string | null;
  beforeUpload: (
    file: RcFile,
    FileList: RcFile[]
  ) => boolean | Promise<void | File | Blob>;
  acceptedFileTypes: string[];
  onCloseFileList: Function;
  fileTypesText: string;
  imgRef: any;
  imageDimensions?: any;
};

export const CustomUploadDragger = ({
  fileList,
  onCloseFileList,
  beforeUpload,
  acceptedFileTypes,
  fileTypesText,
  imageDimensions,
  imgRef,
}: Props) => {
  const fileTypes = isEmpty(acceptedFileTypes)
    ? null
    : acceptedFileTypes.join();

  return (
    <>
      <Upload.Dragger
        showUploadList={false}
        beforeUpload={beforeUpload}
        accept={fileTypes as any}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          <FormattedMessage
            id="promo.drag-and-drop-files.text"
            defaultMessage="Drag and Drop to upload file"
          />
          {fileTypesText && (
            <Text type="secondary" className="d-block">
              <small>
                <FormattedMessage
                  id="accepted-file-formats.text"
                  defaultMessage="Accepted file formats: {formats}"
                  values={{
                    formats: fileTypesText,
                  }}
                />
              </small>
            </Text>
          )}
          <Text type="secondary" className="d-block">
            <small>
              <FormattedMessage
                id="1MB_FILE_SIZE_LIMIT"
                defaultMessage="File size limit 1mb"
              />
            </small>
          </Text>
          {imageDimensions && (
            <Text type="secondary">
              <small>
                {has(imageDimensions, 'width') && (
                  <FormattedMessage
                    id="size-requirements.text"
                    defaultMessage="Size requirements: {size}"
                    values={{
                      size: `${imageDimensions.width}px x ${imageDimensions.height}px`,
                    }}
                  />
                )}

                {!has(imageDimensions, 'width') && imageDimensions}
              </small>
            </Text>
          )}
        </p>
      </Upload.Dragger>
      {fileList && (
        <Col span={24}>
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <PaperClipOutlined />
            &nbsp;{fileList}{' '}
            <CloseOutlined
              className="ml-2 text-danger cursor-pointer"
              onClick={onCloseFileList as any}
            />
          </div>
        </Col>
      )}

      <div style={{ display: 'none' }}>
        <img alt="just for getting the height/width" ref={imgRef} />
      </div>
    </>
  );
};
