import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import DownloadCsvButton from 'components/DownloadCsvButton';
import ALL_PERMISSIONS from 'constants/permissions3';
import { TabId } from 'constants/route';
import { WITHDRAWAL_PROVIDERS_CSV_DATA } from 'graphql/queries/withdrawalProvider.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React from 'react';
import { usePermissions } from 'store/accountState';
import { TabId as TabIdType } from 'types/nav';
// import BulkActions from './components/BulkActions';
import useTranslate from 'utils/useTranslate';
import AddProvider from '../AddProvider';

const HeaderRight = () => {
  const translate = useTranslate();

  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_WITHDRAWAL_METHODS
  );

  return (
    <>
      <ColumnsFilterNew
        iconOnly
        tabId={TabId.THIRD_PARTY_WITHDRAWAL_PROVIDERS as TabIdType}
      />
      <div className="mr-2">
        <DownloadCsvButton
          iconOnly
          tabId="withdrawal-providers-external"
          fileName={translate(messages.WITHDRAWAL_PROVIDERS)}
          query={WITHDRAWAL_PROVIDERS_CSV_DATA}
          edgesPath="withdrawalProviders.edges"
        />
      </div>
      {ALLOWED_CREATE && <AddProvider />}
    </>
  );
};

export default HeaderRight;
