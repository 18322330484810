import React from 'react';

export const AffiliateTypes = {
  SET_ACTIVE_SCREEN: 'SET_ACTIVE_SCREEN',
  AGENT_AFFILIATE_LIST: 'AGENT_AFFILIATE_LIST',
  AGENT_AFFILIATE_DETAILS: 'AGENT_AFFILIATE_DETAILS',
  SHOW_CREATE_AFFILIATE_PROGRAMME: 'SHOW_CREATE_AFFILIATE_PROGRAMME',
  HIDE_CREATE_AFFILIATE_PROGRAMME: 'HIDE_CREATE_AFFILIATE_PROGRAMME',
  SET_DEFAULT_VENDORS: 'SET_DEFAULT_VENDORS',
  SET_DEFAULT_VENDOR_HANDLERS: 'SET_DEFAULT_VENDOR_HANDLERS',
  SET_ACTIVE_AFFILIATE_PROGRAMME: 'SET_ACTIVE_AFFILIATE_PROGRAMME',
  RESET_ACTIVE_AFFILIATE_PROGRAMME: 'RESET_ACTIVE_AFFILIATE_PROGRAMME',
  SET_SUMMARY: 'SET_SUMMARY',
  SET_REFETCH_VARIABLES: 'SET_REFETCH_VARIABLES',
};

export const AffiliateContext = React.createContext(undefined);

export function AffiliateProvider({ children }: any) {
  const initialState = {
    activeScreen: AffiliateTypes.AGENT_AFFILIATE_LIST,
    showCreateAffiliateProgramme: false,
    vendorHandlers: {},
    vendors: [],
    activeAffiliate: '',
    activeAffiliateData: {},
    recordSummary: '',
    refetchVariables: {},
  };

  const reducer = (state: Record<string, any>, action: Record<string, any>) => {
    switch (action.type) {
      case AffiliateTypes.SET_ACTIVE_SCREEN:
        return {
          ...state,
          activeScreen: action.payload,
        };
      case AffiliateTypes.SET_REFETCH_VARIABLES:
        return {
          ...state,
          refetchVariables: action.payload,
        };
      case AffiliateTypes.SET_DEFAULT_VENDOR_HANDLERS:
        return {
          ...state,
          vendorHandlers: action.payload,
        };
      case AffiliateTypes.SET_DEFAULT_VENDORS:
        return {
          ...state,
          vendors: action.payload,
        };
      case AffiliateTypes.SHOW_CREATE_AFFILIATE_PROGRAMME:
        return {
          ...state,
          showCreateAffiliateProgramme: true,
        };
      case AffiliateTypes.HIDE_CREATE_AFFILIATE_PROGRAMME:
        return {
          ...state,
          showCreateAffiliateProgramme: false,
        };
      case AffiliateTypes.SET_ACTIVE_AFFILIATE_PROGRAMME:
        return {
          ...state,
          activeAffiliate: action.payload,
          activeAffiliateData: action.meta,
        };
      case AffiliateTypes.RESET_ACTIVE_AFFILIATE_PROGRAMME:
        return {
          ...state,
          activeAffiliate: '',
          activeAffiliateData: {},
        };
      case AffiliateTypes.SET_SUMMARY:
        return {
          ...state,
          recordSummary: action.payload,
        };
      default:
        return state;
    }
  };
  return (
    <AffiliateContext.Provider
      value={React.useReducer(reducer, initialState) as any}
    >
      {children}
    </AffiliateContext.Provider>
  );
}

export const useAffiliateState = () => React.useContext(AffiliateContext);
