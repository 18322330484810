import { Select, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const TableFooterPages = ({
  values,
  setPage,
  page,
  loading,
}: ITableFooterPages) => (
  <Spin spinning={loading}>
    <Select
      data-testid="table-entries"
      onChange={(e: any) => {
        setPage((prev: any) => ({
          ...prev,
          first: e,
          after: undefined,
          savedCursor: [''],
          currentPage: 0,
        }));
      }}
      className="ml-3"
      defaultValue={page.first}
    >
      {values.map((value: number) => (
        <Select.Option value={value} key={value}>
          {value}{' '}
          <FormattedMessage id="entries.text" defaultMessage="Entries" />
        </Select.Option>
      ))}
    </Select>
  </Spin>
);

interface ITableFooterPages {
  values: number[];
  setPage: (e: any) => void;
  page: any;
  loading: boolean;
}
