import moment, { Moment, unitOfTime } from 'moment-timezone';

type DateFilterType = {
  [input: string]: (
    offset: string,
    range?: {
      startDate: moment.Moment;
      endDate: moment.Moment;
    }
  ) => {
    start: Moment | string | undefined;
    end: Moment | string | undefined;
    prevStartDate?: Moment;
    prevEndDate?: Moment;
    label: string;
    menu: string;
  };
};

const getDateOnUtcFormat = (
  unit: unitOfTime.StartOf,
  offset?: number,
  subUnit?: unitOfTime.DurationConstructor
) => {
  const startDate = moment
    .utc()
    .startOf(unit)
    .subtract(offset, subUnit)
    .format();

  const endDate = moment
    .utc()
    .endOf(unit)
    .subtract(offset, subUnit)
    .format();

  const prevStartDate = moment
    .utc()
    .startOf(unit)
    .subtract(offset, subUnit)
    .format();

  const prevEndDate = moment
    .utc()
    .endOf(unit)
    .subtract(offset, subUnit)
    .format();

  return {
    startDate,
    endDate,
    prevStartDate,
    prevEndDate,
  };
};

export const dateFilterMap: DateFilterType = {
  today: (timezone) => ({
    start: moment.tz(getDateOnUtcFormat('day').startDate, timezone),
    end: moment.tz(getDateOnUtcFormat('day').endDate, timezone),
    prevStartDate: moment.tz(
      getDateOnUtcFormat('day', 1, 'day').prevStartDate,
      timezone
    ),
    prevEndDate: moment.tz(
      getDateOnUtcFormat('day', 1, 'day').prevEndDate,
      timezone
    ),
    label: 'dashboard.day-to-day.text',
    menu: 'today',
  }),
  yesterday: (timezone) => ({
    start: moment.tz(getDateOnUtcFormat('day', 1, 'day').startDate, timezone),
    end: moment.tz(getDateOnUtcFormat('day', 1, 'day').endDate, timezone),
    prevStartDate: moment.tz(
      getDateOnUtcFormat('day', 2, 'days').prevStartDate,
      timezone
    ),
    prevEndDate: moment.tz(
      getDateOnUtcFormat('day', 2, 'days').prevEndDate,
      timezone
    ),
    label: 'dashboard.day-to-day.text',
    menu: 'yesterday',
  }),
  thisWeek: (timezone) => ({
    start: moment.tz(getDateOnUtcFormat('isoWeek').startDate, timezone),
    end: moment.tz(getDateOnUtcFormat('isoWeek').endDate, timezone),
    prevStartDate: moment.tz(
      getDateOnUtcFormat('isoWeek', 1, 'week').prevStartDate,
      timezone
    ),
    prevEndDate: moment.tz(
      getDateOnUtcFormat('isoWeek', 1, 'week').prevEndDate,
      timezone
    ),
    label: 'dashboard.week-to-week.text',
    menu: 'this-week',
  }),
  lastWeek: (timezone) => ({
    start: moment.tz(
      getDateOnUtcFormat('isoWeek', 1, 'week').startDate,
      timezone
    ),
    end: moment.tz(getDateOnUtcFormat('isoWeek', 1, 'week').endDate, timezone),
    prevStartDate: moment.tz(
      getDateOnUtcFormat('isoWeek', 2, 'weeks').prevStartDate,
      timezone
    ),
    prevEndDate: moment.tz(
      getDateOnUtcFormat('isoWeek', 2, 'weeks').prevEndDate,
      timezone
    ),
    label: 'dashboard.week-to-week.text',
    menu: 'last-week',
  }),
  thisMonth: (timezone) => ({
    start: moment.tz(getDateOnUtcFormat('month').startDate, timezone),
    end: moment.tz(getDateOnUtcFormat('month').endDate, timezone),
    prevStartDate: moment.tz(
      getDateOnUtcFormat('month', 1, 'month').prevStartDate,
      timezone
    ),
    prevEndDate: moment.tz(
      getDateOnUtcFormat('month', 1, 'month').prevEndDate,
      timezone
    ),
    label: 'dashboard.month-to-month.text',
    menu: 'this-month',
  }),
  lastMonth: (timezone) => ({
    start: moment.tz(
      getDateOnUtcFormat('month', 1, 'month').startDate,
      timezone
    ),
    end: moment.tz(getDateOnUtcFormat('month', 1, 'month').endDate, timezone),
    prevStartDate: moment.tz(
      getDateOnUtcFormat('month', 2, 'months').prevStartDate,
      timezone
    ),
    prevEndDate: moment.tz(
      getDateOnUtcFormat('month', 2, 'months').prevEndDate,
      timezone
    ),
    label: 'dashboard.month-to-month.text',
    menu: 'last-month',
  }),
  annual: (timezone) => ({
    start: moment.tz(getDateOnUtcFormat('year').startDate, timezone),
    end: moment.tz(getDateOnUtcFormat('year').endDate, timezone),
    prevStartDate: moment.tz(
      getDateOnUtcFormat('year', 1, 'year').prevStartDate,
      timezone
    ),
    prevEndDate: moment.tz(
      getDateOnUtcFormat('year', 1, 'year').prevEndDate,
      timezone
    ),
    label: 'dashboard.year-to-year.text',
    menu: 'annual',
  }),
  customDateRange: (timezone, range) => {
    const startDate = moment.utc(range?.startDate).format();
    const endDate = moment.utc(range?.endDate).format();

    return {
      start: moment.tz(startDate, timezone),
      end: moment.tz(endDate, timezone),
      prevStartDate: moment(range?.startDate)
        .subtract(1, 'day')
        .tz(timezone),
      prevEndDate: moment(range?.endDate)
        .subtract(1, 'day')
        .tz(timezone),
      label: 'dashboard.period-to-period.text',
      menu: 'date-range',
    };
  },
};
