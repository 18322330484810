import React, { useState, SetStateAction } from 'react';
import createContext from 'utils/createContext';
import moment from 'moment';

interface PresetDateProps {
  disableDateFilter: boolean;
  preSetValue: string | null;
  setPresetValue: React.Dispatch<SetStateAction<string | null>>;
  datePreSets: Array<Record<string, any>>;
}

type Props = {
  children: React.ReactNode;
};

const [useContext, ContextProvider] = createContext<PresetDateProps>();

const ReportDatePresetsProvider = ({ children }: Props) => {
  const [preSetValue, setPresetValue] = useState<string | null>(null);

  const disableDateFilter = Boolean(!preSetValue);

  const datePreSets = [
    { key: 'today', label: 'Today', value: [moment(), moment()] },
    {
      key: 'last-7-days',
      label: 'Last 7 Days',
      value: [
        moment()
          .subtract(6, 'day')
          .startOf('day'),
        moment().startOf('day'),
      ],
    },
    {
      key: 'last-week',
      label: 'Last Week',
      value: [
        moment()
          .subtract(1, 'weeks')
          .startOf('isoWeek'),
        moment()
          .subtract(1, 'weeks')
          .endOf('isoWeek'),
      ],
    },
    {
      key: 'last-30-days',
      label: 'Last 30 Days',
      value: [
        moment()
          .subtract(29, 'day')
          .startOf('day'),
        moment().startOf('day'),
      ],
    },
    {
      key: 'last-90-days',
      label: 'Last 90 Days',
      value: [
        moment()
          .subtract(89, 'day')
          .startOf('day'),
        moment().startOf('day'),
      ],
    },
    { key: 'lifetime', label: 'Lifetime', value: null },
  ];

  return (
    <ContextProvider
      value={{
        datePreSets,
        preSetValue,
        setPresetValue,
        disableDateFilter,
      }}
    >
      {children}
    </ContextProvider>
  );
};

export default ReportDatePresetsProvider;

export const useDatePresets = () => useContext();
