import * as Yup from 'yup';
import messages from 'messages';
import { turnoverShowTypes } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/utils';
import { toFinite } from 'lodash';
import { CustomRegex } from 'utils/regex';

export default (translate: (e: any, opts?: any) => string) =>
  Yup.object().shape({
    username: Yup.array()
      .nullable()
      .of(Yup.string())
      .when(['excel', 'text'], {
        is: (excel, text) => !excel && !text,
        then: Yup.array()
          .of(Yup.string())
          .required(translate(messages['username-field-required-error.text'])),
      }),
    type: Yup.string().required(
      translate(messages['type-field-required-error.text'])
    ),
    amount: Yup.string()
      .nullable()
      .when(['excel', 'text'], {
        is: (excel, text) => !excel && !text,
        then: Yup.string()
          .required(translate(messages.AMOUNT_REQUIRED))
          .matches(
            /^[^a-zA-Z]+$/,
            translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
          )
          .test(
            'amount',
            translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 }),
            (value) => {
              const valueTransform = toFinite(value);
              return valueTransform > 0;
            }
          )
          .matches(
            CustomRegex.fourDecimals(),
            translate(messages['maximum-count-decimal-places.text'], {
              count: 4,
            })
          ),
      }),
    remarks: Yup.string().nullable(),
    accountPassword: Yup.string()
      .required(
        translate(messages['account-password-field-required-error.text'])
      )
      .matches(
        CustomRegex.password(),
        translate(messages.PASSWORD_VALIDATION_FIELD)
      ),
    turnover: Yup.string().when('type', {
      is: (val) => turnoverShowTypes.includes(val),
      then: Yup.string()
        .matches(
          /^[^a-zA-Z]+$/,
          translate(messages.TURNOVER_INVALID_KEYED_NUMERICS)
        )
        .test(
          'amount',
          translate(messages.TURNOVER_MIN, { count: 0 }),
          (value) => {
            const valueTransform = toFinite(value);
            return valueTransform >= 0;
          }
        )
        .matches(
          CustomRegex.twoDecimals(),
          translate(messages['maximum-count-decimal-places.text'], { count: 2 })
        )
        .required(translate(messages.TURNOVER_REQUIRED)),
    }),
    excel: Yup.bool(),
    text: Yup.bool(),
  });
