import React, { useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useTimer } from 'use-timer';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

type VerifyCode = {
  onSuccess: (code: string) => void;
  resendVerification: () => void;
  loading: boolean;
};

const VERIFY_PASSWORD_RESET = gql`
  mutation VerifyPasswordReset($input: VerifyPasswordResetInput!) {
    verifyPasswordReset(input: $input)
  }
`;

const VerifyCode: React.FC<VerifyCode> = ({
  onSuccess,
  resendVerification,
  loading,
}) => {
  const [code, setCode] = React.useState('');

  const [timer, setTimer] = React.useState('');
  const { time, start, pause, reset } = useTimer({
    initialTime: 300,
    timerType: 'DECREMENTAL',
  });
  const [verifyPassReset, { loading: isLoading }] = useMutation(
    VERIFY_PASSWORD_RESET
  );

  useEffect(() => {
    let minutes = null;
    let seconds = null;

    minutes = parseInt(String(time / 60), 10);
    seconds = parseInt(String(time % 60), 10);

    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    setTimer(`${minutes}:${seconds}`);

    if (time <= 0) {
      pause();
    }
  }, [pause, time]);

  useEffect(() => {
    start();
  }, [start]);

  const onResendVerificationCode = () => {
    reset();
    resendVerification();
    start();
  };

  const onVerifyCode = () => {
    if (!code) {
      return;
    }

    verifyPassReset({
      variables: {
        input: {
          verificationCode: code,
        },
      },
    }).then(() => {
      onSuccess(code);
    });
  };

  return (
    <div>
      <p>Please check your email for the Verification Code</p>
      <p>Verification will expire in {timer}</p>

      <Form layout="vertical">
        <Form.Item label="Code">
          <Input
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Type the Code"
          />
        </Form.Item>
      </Form>

      <Button
        loading={isLoading}
        onClick={onVerifyCode}
        disabled={time <= 0 || !code}
        block
        type="primary"
        className="mt-3"
      >
        Continue
      </Button>
      {time <= 0 && (
        <Button
          loading={loading}
          block
          type="primary"
          className="mt-3"
          onClick={onResendVerificationCode}
        >
          Resend Verification Code
        </Button>
      )}
    </div>
  );
};

export default VerifyCode;
