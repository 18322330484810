import React from 'react';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import { Divider } from 'antd';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import { DynamicObj } from 'interfaces/user.interface';

const HeaderRight = ({
  refreshPage,
  finalQueryFilter,
}: {
  refreshPage: () => void;
  finalQueryFilter: DynamicObj;
}) => {
  const translate = useTranslate();

  return (
    <>
      <RefreshButton onClick={refreshPage} />
      <Divider type="vertical" className="ml-0 mr-2" />
      <ColumnsFilterNew iconOnly tabId="ip-address-summary" />
      <SpreadsheetDownload
        iconOnly
        filter={finalQueryFilter}
        filename={translate(messages.IP_ADDRESS_SUMMARY)}
        extension="csv"
      />
    </>
  );
};

export default HeaderRight;
