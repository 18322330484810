import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CloseOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Col, notification, Row } from 'antd';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import messages from 'messages';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomUploadDragger } from 'components/Uploader/CustomUploadDragger';
import { GameCategory, Vendor } from 'types/graphqlTypes';
import { getBase64 } from 'utils/imageUtil';
import useTranslate from 'utils/useTranslate';

type Props = {
  vendor: Vendor;
  setVendorIconId: (value: {
    iconId: string | null;
    vendorId: string | null;
  }) => void;
  formItemLayout: object;
  gameCategory: GameCategory | null;
  numOfVendors: number;
  type: string;
};

const UploadForm: React.FC<Props> = ({
  vendor,
  setVendorIconId,
  formItemLayout,
  gameCategory,
  numOfVendors,
  type,
  ...rest
}) => {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [fileListState, setFileList] = useState<string | null>(null);
  const [existingImage, setExistingImage] = useState<any>(null);
  const imgRef = useRef<any>(null);
  const translate = useTranslate();
  const acceptedFileTypes = ['image/jpg', 'image/png', 'image/jpeg'];

  const beforeUpload = (file: File) => {
    const validFileType = acceptedFileTypes.find(
      (acceptedFileType) => acceptedFileType === file.type
    );

    if (!validFileType) {
      notification.error({
        message: translate(messages['wrong-file-type.text']),
      });
      return false;
    }

    getBase64(file, (imgBase64: any) => {
      imgRef.current.src = imgBase64;
      imgRef.current.onload = () => {
        uploadFile({
          variables: {
            file,
          },
        }).then((resp) => {
          const { data } = resp;
          setVendorIconId({
            iconId: data.uploadFile.id,
            vendorId: vendor.id,
          });
          setFileList(file.name);
          setExistingImage(null);
        });
      };
    });

    return false;
  };

  useEffect(() => {
    if (vendor) {
      setExistingImage(vendor[`${type}`]);
      setVendorIconId({
        iconId: vendor[`${type}`]?.id,
        vendorId: vendor.id,
      });
    }
  }, [setVendorIconId, type, vendor]);

  return (
    <Row {...rest}>
      <Col span={10}>
        <div className="mt-3">
          <Form layout="horizontal">
            <Form.Item
              label={translate(messages.VENDOR_IMAGE)}
              {...formItemLayout}
            >
              <div>
                {existingImage && (
                  <div>
                    <a
                      href={existingImage.uri}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage
                        id="existing-image.text"
                        defaultMessage="Existing Image"
                      />
                    </a>
                    <CloseOutlined
                      className="ml-2 text-danger cursor-pointer"
                      onClick={() => {
                        setExistingImage(null);
                        setVendorIconId({
                          iconId: null,
                          vendorId: vendor.id,
                        });
                      }}
                    />
                  </div>
                )}

                <CustomUploadDragger
                  fileList={fileListState}
                  beforeUpload={beforeUpload}
                  acceptedFileTypes={acceptedFileTypes}
                  fileTypesText=".jpg .png"
                  imgRef={imgRef}
                  onCloseFileList={() => {
                    setFileList(null);
                  }}
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      </Col>
      <Col span={14} />
    </Row>
  );
};

export default UploadForm;
