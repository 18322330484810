import React, { useState } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Modal, Divider, Spin, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { groupBy } from 'lodash';
import { ALink } from 'components/ALink/ALink';
import { CSVLink } from 'react-csv';
import { usePMAContext } from '../context';

export const PMAResults: React.FC = () => {
  const {
    setState,
    state: { members, failedMembers, isProcessing, isResult, successCount },
  } = usePMAContext();

  const [invalidMembers, setInvalidMembers] = useState<any>([]);
  const [seeMore, setSeeMore] = useState(false);
  const translate = useTranslate();

  let groupOfInvalidMembers = groupBy(failedMembers, 'message');

  const downloadInvalidMembersAsCsv = () => {
    const translatedMessage = failedMembers.map((member: any) => ({
      Username: member.account,
      Amount: member.amount,
      Message: translate(messages[member.message], member.translateOpts),
    }));

    setInvalidMembers(translatedMessage);
  };

  if ('BLANK_USERNAME_DETECTED' in groupOfInvalidMembers) {
    const { BLANK_USERNAME_DETECTED, ...rest } = groupOfInvalidMembers;
    groupOfInvalidMembers = {
      ...rest,
      X_BLANK_USERNAMES_DETECTED: [
        // @ts-ignore
        { translateOpts: { count: BLANK_USERNAME_DETECTED.length } },
      ],
    };
  }

  return (
    <Modal
      title={
        <span className="fs-14">
          <FormattedMessage
            id="PROMO_MANUAL_ADJUSTMENT"
            defaultMessage="Promo Manual Adjustment"
          />
        </span>
      }
      visible={isProcessing || isResult}
      centered
      onCancel={() => setState({ isProcessing: false, isResult: false })}
      width={320}
      footer={null}
    >
      <>
        <div className="d-flex fs-16 fw-500">
          <span className="mr-1">
            <CheckCircleFilled className="text-success mr-2" />
            <FormattedMessage id="success.text" defaultMessage="Success" />:
          </span>
          {successCount}/{members.length}
        </div>

        {failedMembers?.length > 0 && (
          <div className="d-flex mt-3 fs-16 fw-500">
            <span className="mr-1">
              <CloseCircleFilled className="text-danger mr-2" />
              <FormattedMessage id="failed.text" defaultMessage="Failed" />:
            </span>
            {failedMembers.length}/{members.length}
          </div>
        )}
      </>

      <Divider />

      {isProcessing ? (
        <div className="p-3 text-center">
          <Spin />

          <h2>
            {translate(messages.PROCESSING)}
            ...
          </h2>

          <p>
            {translate(
              messages['MANUAL_ADJUSTMENT.IMPORT_EXCEL_PROCESS_MESSAGE']
            )}
          </p>
        </div>
      ) : (
        <div>
          {failedMembers.length > 0 ? (
            <>
              <div className="mt-2">
                <small className="fs-12">
                  {translate(messages['MANUAL_ADJUSTMENT.BULK_FAILED_MESSAGE'])}
                </small>
              </div>

              <div className="d-flex justify-content-center">
                <Button
                  className="mr-2"
                  style={{ width: '110px' }}
                  onClick={() => setSeeMore(!seeMore)}
                >
                  <span>
                    <FormattedMessage
                      id="details.text"
                      defaultMessage="Details"
                    />
                  </span>
                  <LegacyIcon type={seeMore ? 'up' : 'down'} />
                </Button>

                <Button
                  type="primary"
                  style={{ width: '110px' }}
                  onClick={() =>
                    setState({ isProcessing: false, isResult: false })
                  }
                >
                  <FormattedMessage id="done.text" defaultMessage="Done" />
                </Button>
              </div>

              {seeMore && (
                <div className="mt-3">
                  <div
                    className="pb-2 px-2"
                    style={{
                      background: '#f5f5f5',
                      borderRadius: '10px',
                      maxHeight: '170px',
                      overflow: 'auto',
                    }}
                  >
                    {Object.keys(groupOfInvalidMembers).map((errorKey) => (
                      <div className="mt-2" key={errorKey}>
                        <div className="fs-12 text-black--25">
                          {translate(
                            messages[errorKey],
                            (groupOfInvalidMembers[errorKey][0] as any)
                              .translateOpts
                          )}
                        </div>

                        {groupOfInvalidMembers[errorKey].map((member) => (
                          <div className="fs-12">{member.account}</div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div className="mt-2 d-flex justify-content-between">
                    <ALink>
                      <CSVLink
                        data={invalidMembers}
                        onClick={() => downloadInvalidMembersAsCsv()}
                      >
                        <FormattedMessage
                          id="download-csv.text"
                          defaultMessage="Download CSV"
                        />
                      </CSVLink>
                    </ALink>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-flex-end">
              <Button
                type="primary"
                onClick={() =>
                  setState({ isProcessing: false, isResult: false })
                }
                style={{ width: '110px', margin: 'auto' }}
              >
                <FormattedMessage id="done.text" defaultMessage="Done" />
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};
