import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from 'components/Layout';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import RightNode from './components/RightNode';
import Sidebar from './components/Sidebar';
import TableData from './components/Table';
import { useMissionsValue } from './context';
import NewMissionsDrawer from './components/NewMissionsDrawer';

export const MissionsScreen = () => {
  const { selectedNewMission, setSelectedNewMission } = useMissionsValue();

  return (
    <>
      <Layout.Container>
        <Layout.Header
          leftNode={
            <>
              <ToggleSidebarHead />
              <FormattedMessage
                id="filter-conditions.text"
                defaultMessage="Filter conditions"
              />
            </>
          }
          rightNode={<RightNode />}
        />
        <Layout.Content
          sideBar={<Sidebar />}
          footer={
            <Layout.Footer
              loading={false}
              onNext={() => {}}
              onPrev={() => {}}
              page={{
                first: 10,
                after: '1',
                savedCursor: ['abc'],
                currentPage: 1,
              }}
              resultsCount={3}
              totalPage={10}
              setPage={() => {}}
              pageInfo={{} as any}
            />
          }
        >
          <TableData />
        </Layout.Content>
      </Layout.Container>
      <NewMissionsDrawer
        onClose={() => setSelectedNewMission('')}
        drawerStatus={selectedNewMission}
      />
    </>
  );
};
