import React, { useState } from 'react';
import { Popover } from 'antd';
import messages from 'pages/components/Reports/BalanceServiceRecordReport/messages';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';

const WithdrawalCodes = ({
  withdrawalCodes,
  handleRedirect,
}: {
  withdrawalCodes: Array<string>;
  handleRedirect: (e: string) => void;
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleCodeClick = (code: string) => {
    handleRedirect(code);
    setVisible((prev) => !prev);
  };

  const content = (
    <div>
      {withdrawalCodes.map((code, index) => (
        <p key={index}>
          <ALink onClick={() => handleCodeClick(code)}>{code}</ALink>
        </p>
      ))}
    </div>
  );

  const colDisplay =
    withdrawalCodes.length > 1 ? (
      <>
        {withdrawalCodes.map((code, key) => {
          if (key > 1) return '';
          return (
            <span key={key}>
              <ALink onClick={() => handleCodeClick(code)}>{code}</ALink>
              {withdrawalCodes.length - 1 !== key && ', '}
            </span>
          );
        })}
        {withdrawalCodes.length > 2 && <span> ...</span>}
      </>
    ) : (
      <ALink onClick={() => handleCodeClick(withdrawalCodes[0])}>
        {withdrawalCodes[0]}
      </ALink>
    );
  const translate = useTranslate();
  return (
    <Popover
      placement="left"
      content={content}
      title={translate(messages.WITHDRAWAL_ID)}
      onVisibleChange={() => setVisible((prev) => !prev)}
      visible={visible}
    >
      <span style={{ cursor: 'pointer' }}>{colDisplay}</span>
    </Popover>
  );
};

export default WithdrawalCodes;
