import React from 'react';
import { Button, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as DownloadIcon } from 'components/assets/download-csv.svg';
import DownloadCsvModalNext from 'components/DownloadCsvButtonNext/DownloadCsvModalNext';
import { ReportModule } from 'types';

type DownloadCsvButtonNext = {
  filter: Record<string, any>;
  module: ReportModule;
  isLink?: boolean;
  iconOnly?: boolean;
  allowedGenerateCSV?: boolean;
  allowedDownloadCSV?: boolean;
  permissionErrorMsg?: string;
  reportName?: string;
};

const DownloadCsvButtonNext: React.FC<DownloadCsvButtonNext> = ({
  filter,
  module,
  isLink,
  iconOnly,
  allowedDownloadCSV,
  allowedGenerateCSV,
  permissionErrorMsg,
  reportName,
}) => {
  const [isShow, setShow] = React.useState(false);

  const renderIslink = () => (
    <>
      <Button
        onClick={() => setShow(true)}
        className={isLink ? 'text-black' : ''}
        type={isLink ? 'link' : 'default'}
        block
      >
        Download CSV
      </Button>
    </>
  );

  return (
    <>
      {iconOnly ? (
        <Tooltip title="Download CSV">
          <Icon
            className="mr-1"
            component={DownloadIcon}
            style={{ fontSize: '22px' }}
            onClick={() => setShow(true)}
          />
        </Tooltip>
      ) : (
        renderIslink()
      )}

      {isShow && (
        <DownloadCsvModalNext
          allowedDownloadCSV={allowedDownloadCSV}
          allowedGenerateCSV={allowedGenerateCSV}
          permissionErrorMsg={permissionErrorMsg}
          visible={isShow}
          onClose={() => setShow(false)}
          module={module}
          filter={filter}
          reportName={reportName}
        />
      )}
    </>
  );
};

export default DownloadCsvButtonNext;
