import React from 'react';
import { Tag } from 'antd';

type Props = {
  country: any;
  closable?: boolean;
  unselectCountry?: (
    country: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  skipTranslate?: boolean;
  [key: string]: any;
};

function Countrytag(props: Props) {
  const { closable, unselectCountry, country } = props;
  return (
    <Tag
      className="mt-1"
      onClose={(e: any) => {
        if (unselectCountry) {
          unselectCountry(country, e);
        }
      }}
      closable={closable}
    >
      {typeof country === 'string' ? country : country.displayName}
    </Tag>
  );
}

export default Countrytag;
