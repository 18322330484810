import { defineMessages } from 'react-intl';

const messages = defineMessages({
  VENDOR_ACCOUNTS_AND_PERIOD_IS_REQUIRED: {
    id: 'reports.vendor-accounts-and-period-filters-are-required.text',
    defaultMessage: 'Vendor Account and Period filters are required fields',
  },
  VENDOR_USAGE_REPORT_CREATED: {
    id: 'reports.vendor-usage-report-created.text',
    defaultMessage: 'Vendor Usage Report Created',
  },
});

export default messages;
