import coercedGet from 'utils/coercedGet';

const Keys = {
  GGR_CHARGE_COST_SHARING: 'ggrChargeCostSharing',
  NO_MINIMUM_CHARGE: 'noMinimumCharge',
  NO_MAXIMUM_CHARGE: 'noMaximumCharge',
  MINIMUM_CHARGE: 'minimumCharge',
  MAXIMUM_CHARGE: 'maximumCharge',
  CHARGE_LEVELS: 'chargeLevels',
};

type Props = {
  values: any[];
  gameType: string;
  id: string;
  setFieldValue: (key: string, value: any) => void;
};

export const setGGRTierFields = ({
  values,
  gameType,
  id,
  setFieldValue,
}: Props) => {
  const targetTierName = `vendorHandlers[${gameType}_${id}][chargeLevels]`;
  const chargeLevelKeys = ['minimumTotalWinloss', 'percentage'];

  setFieldValue(targetTierName, []);

  values.forEach((value, index) => {
    chargeLevelKeys.forEach((key) =>
      setFieldValue(`${targetTierName}[${index}][${key}]`, value[key])
    );
  });
};

export const paste = (
  { gameType, id, setFieldValue }: any,
  copiedValues: any[],
  cb: any
) => {
  const targetTierName = `vendorHandlers[${gameType}_${id}]`;
  const availableKeys = Object.values(Keys).filter(
    (key) => key !== Keys.CHARGE_LEVELS
  );

  availableKeys.forEach((key) => {
    setFieldValue(`${targetTierName}[${key}]`, copiedValues[key]);
  });

  const args = {
    values: coercedGet(copiedValues, 'chargeLevels', []),
    gameType,
    id,
    setFieldValue,
  };

  cb(args);
};

export const getDefaultValue = (key: string) => {
  if (key === Keys.CHARGE_LEVELS) {
    return [];
  }
  if (key === Keys.MINIMUM_CHARGE || key === Keys.MAXIMUM_CHARGE) {
    return null;
  }
  return false;
};

export const getCopiedValues = ({
  values,
  gameType,
  id,
}: {
  values: any;
  gameType: string;
  id: string;
}) => {
  const targetTierName = `vendorHandlers[${gameType}_${id}]`;

  return Object.values(Keys).reduce((acc, key) => {
    const prop = {
      [key]: coercedGet(
        values,
        `[${targetTierName}][${key}]`,
        getDefaultValue(key)
      ),
    };
    return { ...acc, ...prop };
  }, {});
};
