import React from 'react';
import { Checkbox } from 'antd';
import Circle from 'components/Circle';
import useTranslate from 'utils/useTranslate';
import messages from './messages';

export const deductions = [
  {
    id: 'withdrawalTax',
    value: 'withdrawalTaxFeeApplied',
    color: 'primary',
  },
  {
    id: 'handlingFee',
    value: 'handlingFeeApplied',
    color: 'danger',
  },
  {
    id: 'promoDeduction',
    value: 'promoDeductionFeeApplied',
    color: 'success',
  },
  {
    id: 'withdrawalLimitFee',
    value: 'withdrawalLimitFeeApplied',
    color: 'warning',
  },
];

const WithdrawalDeductionsFilter = ({
  value,
  onChange,
}: {
  value: Record<string, any>;
  onChange: (e: any) => void;
}) => {
  const translate = useTranslate();
  return (
    <div>
      {deductions.map((item) => (
        <Checkbox
          className="mt-1 ml-0 d-block"
          name={item.value}
          checked={!!value[item.value]}
          onChange={(e) => onChange(e)}
          key={item.value}
        >
          <Circle size={8} color={item.color} filled />
          {translate(messages[item.id])}
        </Checkbox>
      ))}
    </div>
  );
};

export default WithdrawalDeductionsFilter;
