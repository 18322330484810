import React from 'react';
import { message, Spin } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { GET_OPERATORS, GET_OPERATOR } from 'graphql/queries/operators.query';
import { UPDATE_OPERATOR } from 'graphql/mutations/operator.mutation';
import OperatorForm from '../OperatorForm';
import { StyledModal } from './styles';

const EditOperator = (props: Record<string, any>) => {
  const translate = useTranslate();
  const { toggleModal, id, refetchVariables } = props;
  const { context } = useOperatorHeader();

  const refetchQueries = [
    {
      query: GET_OPERATOR,
      variables: { id },
    },
    {
      query: GET_OPERATORS,
      variables: refetchVariables,
    },
  ];

  const { data = {}, loading } = useQuery(GET_OPERATOR, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const [updateOperator, updateOperatorResult] = useMutation(UPDATE_OPERATOR, {
    context,
    onCompleted: () => {
      message.success(
        `${translate(messages['successfully-saved-changes.text'])}`
      );
    },
    refetchQueries,
  });

  const { operator = {} } = data as any;

  const {
    permissionGroups = [],
    username = '',
    password = '',
    realName = '',
    gender = '',
    email = '',
    mobileNumber = '',
    wechatId = '',
    qqId = '',
    status = '',
    notes = '',
  } = operator;

  const permissionGroupsArray = permissionGroups.map(
    (permissionGroup: Record<string, any>) => permissionGroup.id
  );

  return (
    <>
      <StyledModal
        title={
          <FormattedMessage
            id="edit-operator.text"
            defaultMessage="Edit operator"
          />
        }
        visible
        width={1000}
        onOk={toggleModal}
        onCancel={toggleModal}
        footer={false}
      >
        <div>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <Spin />
            </div>
          ) : (
            <OperatorForm
              onClose={toggleModal}
              createOperator={updateOperator}
              loading={updateOperatorResult.loading}
              operator={{
                username,
                password,
                realName: realName || '',
                gender,
                email: email || '',
                mobileNumber,
                wechatId,
                qqId,
                permissionGroups: permissionGroupsArray,
                id,
                notes,
                status,
              }}
            />
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default EditOperator;
