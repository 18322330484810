import { defineMessages } from 'react-intl';

const messages = defineMessages({
  withdrawalRequest: {
    id: 'withdrawal-requests.text',
    defaultMessage: 'Withdrawal Requests',
  },
  withdrawalMethodplaceholder: {
    id: 'select-a-withdrawal-method.text',
    defaultMessage: 'Select a withdrawal method',
  },
  paymentSources: {
    id: 'payment-sources.text',
    defaultMessage: 'Payment sources',
  },
  internal: {
    id: 'internal.text',
    defaultMessage: 'Internal',
  },
  thirdParty: {
    id: '3rd-party.text',
    defaultMessage: '3rd party',
  },
  systemComplianceCheck: {
    id: 'system-compliance-check.text',
    defaultMessage: 'System compliance check',
  },
  firstWithdrawal: {
    id: 'first-withdrawal.text',
    defaultMessage: 'First Withdrawal',
  },
  agentAffiliate: {
    id: 'agent-affiliate.text',
    defaultMessage: 'Agent affiliate',
  },
  promoHunter: {
    id: 'promo-hunter.text',
    defaultMessage: 'Promo hunter',
  },
  contraBet: {
    id: 'contra-bet.text',
    defaultMessage: 'Contra bet',
  },
  oneTimeTurnoverDetection: {
    id: 'one-time-turnover-detection.text',
    defaultMessage: 'One time turnover detection',
  },
  ipCollisionOffender: {
    id: 'ip-collision-offender.text',
    defaultMessage: 'IP collision offender',
  },
  martingaleBetOffender: {
    id: 'martingale-bet-offender.text',
    defaultMessage: 'Martingale bet offender',
  },
  search: {
    id: 'search.text',
    defaultMessage: 'Search',
  },
  quickSearch: {
    id: 'quick-search.text',
    defaultMessage: 'Quick search',
  },
  selectSavedCustomFilter: {
    id: 'select-a-saved-custom-filter.text',
    defaultMessage: 'Select a saved custom filter',
  },
  serialCode: {
    id: 'serial-code.text',
    defaultMessage: 'Serial code',
  },
  enterSerialCode: {
    id: 'enter-serial-code.text',
    defaultMessage: 'Enter serial code',
  },
  memberAccount: {
    id: 'Member-account.text',
    defaultMessage: 'Member account',
  },
  enterUsername: {
    id: 'enter-username.text',
    defaultMessage: 'Enter Username',
  },
  memberVipLevel: {
    id: 'vip-level.text',
    defaultMessage: 'VIP level',
  },
  enterVipLevel: {
    id: 'enter-vip-level.text',
    defaultMessage: 'Enter VIP Level',
  },
  memberMarker: {
    id: 'member-marker.text',
    defaultMessage: 'Member Marker',
  },
  enterMemberMarker: {
    id: 'enter-member-marker.text',
    defaultMessage: 'Enter Member Marker',
  },
  requestDate: {
    id: 'request-date.text',
    defaultMessage: 'Request date',
  },
  requestTime: {
    id: 'request-time.text',
    defaultMessage: 'Request time',
  },
  operator: {
    id: 'operator.text',
    defaultMessage: 'Operator',
  },
  clickToViewTransactionRecord: {
    id: 'click-to-view-the-transaction-record.text',
    defaultMessage: 'Click to view the transaction record',
  },
  clickToViewReport: {
    id: 'click-to-view-the-report.text',
    defaultMessage: 'Click to view the report',
  },
  withdrawalRequestDetails: {
    id: 'withdrawal-request-details.text',
    defaultMessage: 'Withdrawal request details',
  },
  pending: {
    id: 'pending.text',
    defaultMessage: 'Pending',
  },
  approve: {
    id: 'approve.text',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'reject.text',
    defaultMessage: 'Reject',
  },
  reprocess: {
    id: 'reprocess.text',
    defaultMessage: 'Reprocess',
  },
  startProcess: {
    id: 'start-process.text',
    defaultMessage: 'Start process',
  },
  stopProcess: {
    id: 'stop-process.text',
    defaultMessage: 'Stop Process',
  },
  approved: {
    id: 'approved.text',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'rejected.text',
    defaultMessage: 'Rejected',
  },
  processing: {
    id: 'processing.text',
    defaultMessage: 'Processing',
  },
  failed: {
    id: 'failed.text',
    defaultMessage: 'Failed',
  },
  remarks: {
    id: 'remarks.text',
    defaultMessage: 'Remarks',
  },
  processedTime: {
    id: 'processed-time.text',
    defaultMessage: 'Processed time',
  },
  labelsRemarks: {
    id: 'label-remarks.text',
    defaultMessage: 'Labels/Remarks',
  },
  operatorRemark: {
    id: 'operator-remark.text',
    defaultMessage: 'Operator remark',
  },
  withdrawalFee: {
    id: 'withdrawal-fee.text',
    defaultMessage: 'Withdrawal fee',
  },
  feeDeduction: {
    id: 'fee-deduction.text',
    defaultMessage: 'Fee deduction',
  },
  complianceCheck: {
    id: 'compliance-check.text',
    defaultMessage: 'Compliance Check',
  },
  clearAll: {
    id: 'clear-all.text',
    defaultMessage: 'Clear all',
  },
  reset: {
    id: 'reset.text',
    defaultMessage: 'Reset',
  },
  withdrawalAmount: {
    id: 'withdrawal-amount.text',
    defaultMessage: 'Withdrawal amount',
  },
  handlingFee: {
    id: 'handling-fee.text',
    defaultMessage: 'Handling fee',
  },
  withdrawalTax: {
    id: 'withdrawals.withdraw-tax.text',
    defaultMessage: 'Withdraw tax',
  },
  withdrawalLimitFee: {
    id: 'withdrawals.withdrawal-limit-fee.text',
    defaultMessage: 'Withdrawal limit fee',
  },
  promoDeduction: {
    id: 'promo-deduction.text',
    defaultMessage: 'Promo deduction',
  },
  optional: {
    id: 'optional.text',
    defaultMessage: 'Optional',
  },
  status: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  dateTimeProcessed: {
    id: 'date-time-processed.text',
    defaultMessage: 'Date Time Processed',
  },
  processingTime: {
    id: 'elapsed-time.text',
    defaultMessage: 'Elapsed Time',
  },
  withdrawalRejected: {
    id: 'withdrawals.withdrawal-request-rejected.text',
    defaultMessage: 'Withdrawal request rejected',
  },
  withdrawalApproved: {
    id: 'withdrawals.withdrawal-request-approved.text',
    defaultMessage: 'Withdrawal request approved',
  },
  withdrawalStopped: {
    id: 'withdrawals.stopped-withdrawal-process.text',
    defaultMessage: 'Stopped withdrawal process',
  },
  requiredRejectRemarkError: {
    id: 'withdrawals.required-for-rejecting-withdrawal-request.text',
    defaultMessage: 'Required for rejecting withdrawal request',
  },
  requiredApproveRemarkError: {
    id: 'withdrawals.required-for-approving-withdrawal-request.text',
    defaultMessage: 'Required for approving withdrawal request',
  },
  minute: {
    id: 'minute.text',
    defaultMessage: 'Minute',
  },
  areYouSure: {
    id: 'are-you-sure?.text',
    defaultMessage: 'Are you sure?',
  },
  yes: {
    id: 'yes.text',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'no.text',
    defaultMessage: 'no',
  },
  fatalError: {
    id: 'fatal-error.text',
    defaultMessage:
      'An error has occured. Please contact Customer service for assistance.',
  },
  requestUpdate: {
    id: 'request-update.text',
    defaultMessage: 'Request Update',
  },
  actions: {
    id: 'actions.text',
    defaultMessage: 'Actions',
  },
  REMARKS_UPDATED: {
    id: 'remarks-updated.text',
    defaultMessage: 'Remarks updated',
  },
  VIP_TIER: {
    id: 'vip-tier.text',
    defaultMessage: 'VIP Tier',
  },
  ELAPSED_TIME: {
    id: 'elapsed-time.text',
    defaultMessage: 'Elapsed Time',
  },
});

export default messages;
