import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { SuperAdminScreenTabs } from 'SuperAdminMain/components/SuperAdminScreenTabs/SuperAdminScreenTabs';
import { Navbar } from '../components/Navbar/Navbar';

export const StyledLayout = styled(Layout)`
  height: 100%;
`;

export const AntLayout = () => (
  <StyledLayout>
    <Navbar />
    <SuperAdminScreenTabs />
  </StyledLayout>
);
