import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import copyToClipboard from 'utils/copyToClipboard';
import moment from 'moment';
import CustomTag from 'components/CustomTag';
import MemberMarkerButton from 'components/MemberMarkerButton';
import { ALink } from 'components/ALink/ALink';
import { Deposit } from 'types/graphqlTypes';
import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import { useConfig } from 'hooks/useConfig';
import { DATE_FORMAT } from 'constants/date';

import MemberProfile from './components/MemberProfile';

type PropTypes = {
  readOnly: boolean;
  deposit: Deposit & { __typename: string };
};
const MemberInformation = (props: PropTypes) => {
  const { currency } = useConfig();
  const { deposit, readOnly } = props;
  const {
    account,
    dateTimeCreated,
    amount,
    __typename: depositMethod,
  } = deposit;
  const { tags = [], memberLoyaltyLevels, realName } = account;
  const { Text } = Typography;

  const { notes } = account;
  const { edges = [] } = notes;

  return (
    <>
      <div className="menu-header p-2">
        <FormattedMessage id="member-info.text" defaultMessage="Member info" />
      </div>
      <div className="d-flex p-2 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage id="requester.text" defaultMessage="Requester" />
          </Text>
        </div>
        <div className="flex-1 text-primary text-right">
          <MemberProfile member={account} />
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage id="vip-level.text" defaultMessage="VIP Level" />
          </Text>
        </div>
        <div className="ml-auto">
          {memberLoyaltyLevels?.length ? (
            <MemberLoyaltyTagList loyaltyLevels={memberLoyaltyLevels || []} />
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className="d-flex  bb-1 p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="request-time.text"
              defaultMessage="Request time"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {dateTimeCreated ? (
            <>
              {moment(dateTimeCreated).format(`${DATE_FORMAT} (ddd)`)}
              <br />
              {moment(dateTimeCreated).format('HH:mm:ss')} -{' '}
              {moment(dateTimeCreated).fromNow()}
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      {/* Deposit Amount */}
      {depositMethod !== 'CryptocurrencyDeposit' ? (
        <div className="d-flex bb-1 p-2">
          <div>
            <Text type="secondary">
              <FormattedMessage
                id="request-amount.text"
                defaultMessage="Request amount"
              />
            </Text>
          </div>
          <div data-testid="amount" className="flex-1 text-right">
            {currency?.symbol} {amount}{' '}
            <ALink disabled={readOnly} onClick={() => copyToClipboard(amount)}>
              <CopyOutlined />
            </ALink>
          </div>
        </div>
      ) : (
        []
      )}

      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="member-marker.text"
              defaultMessage="Member marker"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          <MemberMarkerButton
            memberMarker={account.memberLevel.name}
            color={account.memberLevel.color}
          />
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">
            <FormattedMessage id="name.text" defaultMessage="Name" />
          </Text>
        </div>
        <div className="flex-1 text-right">{realName || '-'}</div>
      </div>
      <div className="bb-1 p-2">
        <div className="mb-2">
          <Text type="secondary">
            <FormattedMessage
              id="member-details-remark.text"
              defaultMessage="Member details remark"
            />
          </Text>
        </div>
        {edges.length ? edges[0]?.node?.note : '-'}
      </div>
      <div className="p-2">
        <div className="mb-2">
          <Text type="secondary">
            <FormattedMessage id="labels.text" defaultMessage="Labels" />
          </Text>
        </div>
        <div>
          {tags.length
            ? tags.map((tag) => (
                <>
                  <CustomTag
                    onClose={() => {}}
                    className="mr-0 mt-1"
                    color={tag?.color || 'black'}
                    variant="memberTag"
                  >
                    {tag?.name || '-'}
                  </CustomTag>{' '}
                </>
              ))
            : '-'}
        </div>
      </div>
    </>
  );
};

export default MemberInformation;
