import gql from 'graphql-tag';

export const WITHDRAWAL_METHOD = gql`
  query WithdrawalMethod($id: ID!) {
    withdrawalMethod(id: $id) {
      id
      name
      remarks
      enabled
      transactionFee
      transactionFeeType
      ... on OfflineBankTransferWithdrawalMethod {
        bank
        accNumber: accountNumber
        accountName
        branch
        country
        province
        city
      }
      ... on WechatWithdrawalMethod {
        accountNumber
        nickname
      }
      ... on AlipayWithdrawalMethod {
        accountNumber
        nickname
      }
      ... on OnlineBankTransferWithdrawalMethod {
        withdrawalProvider {
          id
          name
          type
        }
      }
      ... on OnlineWechatWithdrawalMethod {
        withdrawalProvider {
          id
          name
          type
        }
      }
      ... on OnlineAlipayWithdrawalMethod {
        withdrawalProvider {
          id
          name
          type
        }
      }
    }
  }
`;

export default {};
