export const statusTransform = {
  ENABLED: 'Active',
  DISABLED: 'Inactive',
  CLOSED: 'Closed',
  DORMANT: 'Dormant',
  BANNED: 'Banned',
  SELF_EXCLUDED: 'Self-Excluded',
  GAMSTOP_SELF_EXCLUDED: 'Gamstop Self-Excluded',
  TIME_OUT: 'Time-Out',
  COOLING_OFF: 'Cooling Off',
  FRAUD: 'Fraud',
  COOLING_OFF_ENDED: 'Cooling Off Ended',
  FROZEN: 'Frozen',
  CLOSED_LEGACY: 'Closed Legacy',
};
