import React, { useState } from 'react';
import { VendorGroup } from 'types/graphqlTypes';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { NewVendorDrawerContainer } from '../../../NewVendorDrawerContainer/NewVendorDrawerContainer';
import { useVendorGroupManagementValue } from '../../../../VendorGroupManagementContext';

export const EditVendorGroupInformation = ({
  vendorGroup,
}: {
  vendorGroup: VendorGroup;
}) => {
  const data = useVendorGroupManagementValue();
  const [show, setShow] = useState(false);

  return (
    <>
      <Button type="link" block onClick={() => setShow(prev => !prev)}>
        <FormattedMessage id="edit.text" defaultMessage="Edit" />
      </Button>

      <NewVendorDrawerContainer
        openDrawer={show}
        closeDrawer={() => setShow(false)}
        refetchFn={data?.refetch}
        existingValues={vendorGroup}
      />
    </>
  );
};
