import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from 'antd';
import { Vendor } from 'types/graphqlTypes';
import { isEmpty } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { StyledSpacer, TitleContainer } from '../../styles';

const StyledExtra = styled.div`
  margin: 8px 5px 0;
  color: rgba(0, 0, 0, 0.65);
`;

type Props = {
  formItemLayout: object;
  editVendorFormData: Vendor | {};
  setLobby: (value: string) => Promise<void>;
};

export const EditVendorForm: React.FC<Props> = ({
  formItemLayout,
  editVendorFormData,
  setLobby,
}) => (
  <>
    <StyledSpacer />
    <TitleContainer>
      <h4 style={{ marginBottom: '0', fontWeight: 600 }}>
        <FormattedMessage id="edit-vendor.text" defaultMessage="Add Vendor" />
      </h4>
    </TitleContainer>
    <div className="mt-3">
      {!isEmpty(editVendorFormData) && (
        <Form layout="horizontal">
          <Form.Item
            label={
              <FormattedMessage id="vendor.text" defaultMessage="Vendor" />
            }
            extra={
              <StyledExtra>{`${coercedGet(
                editVendorFormData,
                'name',
                ''
              )} - ${coercedGet(editVendorFormData, 'type', '')}`}</StyledExtra>
            }
            {...formItemLayout}
          />

          <Form.Item
            label={
              <FormattedMessage
                id="game-list-redirect.text"
                defaultMessage="Game List Redirect"
              />
            }
            {...formItemLayout}
          >
            <Radio.Group
              value={
                coercedGet(editVendorFormData, 'externalLobby', '')
                  ? 'external'
                  : 'internal'
              }
              onChange={(ev) => setLobby(ev.target.value)}
            >
              <Radio value="internal">
                <FormattedMessage
                  id="internal.text"
                  defaultMessage="Internal"
                />
              </Radio>
              <Radio value="external">
                <FormattedMessage
                  id="external.text"
                  defaultMessage="External"
                />
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </div>
  </>
);
