import styled from 'styled-components';

export const StyledTagContainer = styled.div<any>`
  flex: 1;
  display: flex;
  overflow: hidden;
  align-items: center;
  max-height: ${({ isExpanded }) => (isExpanded ? 'unset' : '37px')};
  flex-wrap: wrap;
  .ant-tag {
    margin: 5px;
  }
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default {};
