import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Button, Modal, Select, message, Typography, Tag, Divider } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import coercedGet from 'utils/coercedGet';
import {
  Config,
  CurrencyMetadata,
  CurrencyMetadataConnection,
  CurrencyMetadataConnectionEdge,
} from 'types/graphqlTypes-row';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';
import { find, orderBy } from 'lodash';
import styled from 'styled-components';
import { useAvailableCurrencies } from 'hooks/useAvailableCurrencies';
import { CURRENCIES, DEFAULT_CURRENCY } from '../ManageCurrency/utils';

const { Title } = Typography;

const StyledSelect: any = styled(Select)`
  .ant-select-selection-overflow-item {
    display: flex;
    align-items: center;
    margin: 2px 0;
  }
`;

const ManageCurrencyRow = () => {
  const [modalState, setModalState] = useState(false);
  const [selectedDefaultCurrency, setSelectedDefaultCurrency] = useState<{
    current: string;
    new: string;
  }>({ current: '', new: '' });

  const { data, loading: loadingCurrencies } = useQuery<{
    currencies: CurrencyMetadataConnection;
  }>(CURRENCIES);

  const handleToggleModal = () => {
    setModalState((prev) => !prev);
    setSelectedDefaultCurrency((prev) => ({ ...prev, new: '' }));
  };

  const {
    loadAvailableCurrencies,
    loading,
    availableCurrencyCodes,
    setAvailableCurrencyCodes,
  } = useAvailableCurrencies();

  const [loadDefaultCurrency, { loading: loadingDefaultCurr }] = useLazyQuery<{
    config: Config;
  }>(DEFAULT_CURRENCY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ config }) => {
      const defaultCurrencyData = config.defaultCurrency;

      setSelectedDefaultCurrency((prev) => ({
        ...prev,
        current: defaultCurrencyData?.id || '',
      }));
    },
  });

  const [updateAvailCurrencyConfig, { loading: loadingMutation }] = useMutation(
    UPDATE_CONFIG
  );

  const handleUpdateConfig = async () => {
    try {
      await updateAvailCurrencyConfig({
        variables: {
          input: {
            availableCurrencies: availableCurrencyCodes,
            defaultCurrency: selectedDefaultCurrency.new
              ? selectedDefaultCurrency.new
              : selectedDefaultCurrency.current,
          },
        },
      });

      message.success('Currency Configuration Updated.');
    } catch (error) {
      message.error('Something went wrong.');
    } finally {
      loadAvailableCurrencies();
      handleToggleModal();
    }
  };

  useEffect(() => {
    if (modalState) {
      loadAvailableCurrencies();
      loadDefaultCurrency();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState]);

  const currenciesData: CurrencyMetadata[] = coercedGet(
    data!,
    'currencies.edges',
    []
  ).map(({ node }: CurrencyMetadataConnectionEdge) => node);

  const filteredCurrencies = currenciesData.filter((currency) =>
    availableCurrencyCodes.includes(currency.code)
  );

  const defaultCurrencyOpts = orderBy(
    filteredCurrencies.map((curr: CurrencyMetadata) => ({
      ...curr,
      key: curr.id,
    })),
    ['name'],
    ['asc']
  );

  const transformedCurrencies = orderBy(
    currenciesData.map((curr: CurrencyMetadata) => ({
      ...curr,
      key: curr.id,
    })),
    ['name'],
    ['asc']
  );

  const filteredOptions = useCallback(
    () =>
      transformedCurrencies.filter(
        (currency) => !availableCurrencyCodes.includes(currency.code)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currenciesData]
  );

  // const defaultCurrency = coercedGet(
  //   defCurrData!,
  //   'config.defaultCurrency',
  //   {}
  // );

  const tagRender = ({
    value,
    onClose,
  }: {
    value: string;
    onClose: () => void;
  }) => {
    const onPreventMouseDown = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.preventDefault();
    };

    const { name, symbol, code } = find(currenciesData, ['code', value]) || {};
    const { code: newCode } =
      find(currenciesData, ['id', selectedDefaultCurrency.new]) || {};
    const { code: currentCode } =
      find(currenciesData, ['id', selectedDefaultCurrency.current]) || {};

    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={!(value === newCode || value === currentCode)}
        onClose={onClose}
      >
        {`${name} (${symbol}) ${code}`}
      </Tag>
    );
  };

  return (
    <div>
      <h3>Manage Currency</h3>
      <Button onClick={handleToggleModal}>Configure Currency</Button>
      <Modal
        centered
        title="Configure Available Currencies"
        visible={modalState}
        onOk={handleUpdateConfig}
        onCancel={handleToggleModal}
        destroyOnClose
        okButtonProps={{ loading: loadingMutation }}
        cancelButtonProps={{ loading: loadingMutation }}
        closable
      >
        <Title level={5}>Default Currency</Title>
        <Select
          placeholder="Select default currency"
          style={{ width: '100%' }}
          loading={loadingDefaultCurr}
          value={
            selectedDefaultCurrency.new
              ? selectedDefaultCurrency.new
              : selectedDefaultCurrency.current
          }
          onChange={(val) => {
            setSelectedDefaultCurrency((prev) => ({ ...prev, new: val }));
          }}
        >
          {defaultCurrencyOpts.map((curr) => (
            <Select.Option key={curr.id} value={curr.id}>
              {`${curr.name} (${curr.symbol}) ${curr.code}`}
            </Select.Option>
          ))}
        </Select>
        <Divider />
        <Title level={5}>Available Currencies</Title>
        <StyledSelect
          tagRender={tagRender}
          mode="multiple"
          placeholder="Select list of currencies"
          style={{ width: '100%' }}
          loading={loading || loadingCurrencies}
          value={availableCurrencyCodes}
          onChange={setAvailableCurrencyCodes}
          options={filteredOptions().map((option) => {
            const { code, name, symbol } = option;

            return {
              value: code,
              label: `${name} (${symbol}) ${code}`,
            };
          })}
        />
      </Modal>
    </div>
  );
};

export default ManageCurrencyRow;
