import React from 'react';

const HorizontalRule = (props: Record<string, any>) => (
  <span
    style={{
      borderRight: '1px solid #d9d9d9',
    }}
    {...props}
  >
    &nbsp;
  </span>
);

export default HorizontalRule;
