import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Checkbox, Button, Divider, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Form, Formik, FieldArray } from 'formik';
import Sortable from 'react-sortablejs';
import styled from 'styled-components';
import cx from 'classnames';
import { useCustomColumnsV2 } from 'SuperAdminMain/store/SuperAdminCustomColumnState/customColumnState';

type InnerFormNewProps = {
  toggleDropDown: () => void;
  tabId: string;
};

const InnerFormNew = React.forwardRef(
  ({ toggleDropDown, tabId }: InnerFormNewProps, ref) => {
    const {
      loading,
      initialColumns,
      customColumns,
      setColumns,
    } = useCustomColumnsV2(tabId);

    const [columnOrder, setColumnOrder] = React.useState(customColumns);

    return (
      <Formik
        initialValues={{ columns: customColumns }}
        onSubmit={() => {
          setColumns(columnOrder);
          toggleDropDown();
        }}
      >
        {({ handleSubmit }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useImperativeHandle(ref, () => ({
            initialColumns,
            setColumnOrder,
          }));

          return (
            <Spin spinning={loading}>
              <Form onSubmit={handleSubmit}>
                <FieldArray
                  name="columns"
                  render={() => (
                    <div
                      style={{
                        padding: '12px 16px 0px 16px',
                        overflow: 'auto',
                        maxHeight: '60vh',
                      }}
                    >
                      <Wrapper>
                        {customColumns.map(
                          (column, idx) =>
                            column.disabled && (
                              <div
                                key={idx}
                                className="d-flex align-items-center"
                                style={{
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                }}
                              >
                                <MenuOutlined
                                  style={{ cursor: 'not-allowed' }}
                                  className="mr-2"
                                />

                                <Checkbox disabled checked className="mr-2">
                                  {column.title}
                                </Checkbox>
                              </div>
                            )
                        )}

                        <Sortable
                          style={{ paddingInlineStart: '0px' }}
                          tag="ul"
                          onChange={(order: any) => {
                            const ordered = order.map((name: string) =>
                              columnOrder.find((column) => column.key === name)
                            );

                            setColumnOrder([
                              ...columnOrder.filter((x) => x.disabled),
                              ...ordered,
                            ]);
                          }}
                          options={{
                            filter: '.non-draggable',
                          }}
                        >
                          {columnOrder
                            .filter((col) => !col.disabled)
                            .map((column, index) => (
                              <li
                                className={`list ${cx({
                                  'non-draggable': column.disabled,
                                })}`}
                                style={{
                                  display: column.hidden ? 'none' : undefined,
                                }}
                                key={index}
                                value={column.key}
                                data-id={column.key}
                              >
                                <div className="checkbox-flex">
                                  <MenuOutlined
                                    style={{ cursor: 'grab' }}
                                    className="mr-2"
                                  />

                                  <Checkbox
                                    disabled={column.disabled}
                                    checked={column.visible}
                                    value={column}
                                    onChange={(e) => {
                                      const { checked } = e.target;
                                      const colIndex = columnOrder.findIndex(
                                        (checkboxCol) =>
                                          checkboxCol.key === column.key
                                      );

                                      const checkboxCol = {
                                        ...columnOrder[colIndex],
                                        visible: checked,
                                      };

                                      const newOrder = [...columnOrder];
                                      newOrder.splice(colIndex, 1, checkboxCol);

                                      setColumnOrder(newOrder);
                                    }}
                                    className="mr-2"
                                  >
                                    {column.title}
                                  </Checkbox>
                                </div>
                              </li>
                            ))}
                        </Sortable>
                      </Wrapper>
                    </div>
                  )}
                />

                <Divider className="m-0" />
                <div className="p-2 d-flex justify-content-center">
                  <Button onClick={toggleDropDown} className="mr-2">
                    <FormattedMessage
                      id="cancel.text"
                      defaultMessage="Cancel"
                    />
                  </Button>

                  <Button onClick={handleSubmit as any} type="primary">
                    <FormattedMessage id="apply.text" defaultMessage="Apply" />
                  </Button>
                </div>
              </Form>
            </Spin>
          );
        }}
      </Formik>
    );
  }
);

const Wrapper = styled.div`
  .list {
    background: white;
    padding-top: 8px;
    padding-bottom: 8px;
    list-style: none;
  }

  .checkbox-flex {
    display: flex;
    align-items: center;
  }
`;

export default InnerFormNew;
