import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  isTypenameExternal,
  PAYMENT_METHODS_LIST,
  isTypeAlipay,
  isTypeWechat,
  isTypeBank,
  isTypeUnionPay,
  isTypeUSDT,
  isTypeManual,
  isTypeHexopay,
  isTypeNeteller,
  isTypeSkrill,
} from 'constants/paymentTypes';

import BankDetails from './components/BankDetails';
import MethodDetails from './components/MethodDetails';
import LubanzhifuDetails from './components/LubanzhifuDetails';
import messages from '../../../../messages';
import useTranslate from '../../../../../../../utils/useTranslate';

type Props = {
  paymentMethod: Record<string, any>;
  formItemLayout: Record<string, any>;
};

const FormItem: any = Form.Item;

const AccountInformation = ({ paymentMethod, formItemLayout }: Props) => {
  const {
    name,
    __typename,
    depositProvider,
    blockchain,
    blockchainAddress,
  } = paymentMethod;
  const translate = useTranslate();

  const getPaymentMethod = () => {
    if (isTypeBank(PAYMENT_METHODS_LIST[__typename])) {
      return messages.banktransfer;
    }

    if (isTypeWechat(PAYMENT_METHODS_LIST[__typename])) {
      return messages.wechat;
    }

    if (isTypeAlipay(PAYMENT_METHODS_LIST[__typename])) {
      return messages.alipay;
    }

    if (isTypeUnionPay(PAYMENT_METHODS_LIST[__typename])) {
      return messages.unionpay;
    }
    if (isTypeUSDT(PAYMENT_METHODS_LIST[__typename])) {
      return messages.cryptocurrency;
    }
    if (isTypeManual(PAYMENT_METHODS_LIST[__typename])) {
      return messages.MANUAL_ADJUSTMENT;
    }

    if (isTypeHexopay(PAYMENT_METHODS_LIST[__typename])) {
      return messages.hexopay;
    }

    if (isTypeNeteller(PAYMENT_METHODS_LIST[__typename])) {
      return messages.neteller;
    }

    if (isTypeSkrill(PAYMENT_METHODS_LIST[__typename])) {
      return messages.skrill;
    }

    return '-';
  };

  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1"
        label={
          <strong id="account-information">
            {translate(messages.accountinfo)}
          </strong>
        }
        colon={false}
      />
      <FormItem
        label={translate(messages.name)}
        {...formItemLayout}
        className="mb-0"
        role="listitem"
        data-testid="pekpekpekpek"
      >
        <span role="cell">{name}</span>
      </FormItem>
      <Form.Item
        label={translate(messages.source)}
        {...formItemLayout}
        className="mb-0"
      >
        {translate(depositProvider ? messages.thirdparty : messages.internal)}
      </Form.Item>
      <Form.Item
        label={translate(messages.method)}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{translate(getPaymentMethod() as any)}</span>
      </Form.Item>
      {/* //CRYPTO */}
      {__typename === 'CryptocurrencyPaymentMethod' ? (
        <>
          <Form.Item
            label={translate(messages.blockchain)}
            {...formItemLayout}
            className="mb-0"
          >
            <span>{blockchain || ''}</span>
          </Form.Item>
          <Form.Item
            label={translate(messages.DESTINATION_ADDRESS)}
            {...formItemLayout}
            className="mb-0"
          >
            <span>{blockchainAddress || ''}</span>
          </Form.Item>
        </>
      ) : (
        ''
      )}

      {/* //CRYPTO */}
      {isTypeBank(PAYMENT_METHODS_LIST[__typename]) &&
        !isTypenameExternal(__typename) && (
          <BankDetails
            paymentMethod={paymentMethod}
            formItemLayout={formItemLayout}
          />
        )}
      {!isTypeBank(PAYMENT_METHODS_LIST[__typename]) &&
        !isTypenameExternal(__typename) && (
          <MethodDetails
            paymentMethod={paymentMethod}
            formItemLayout={formItemLayout}
          />
        )}
      {isTypenameExternal(__typename) && (
        <LubanzhifuDetails
          paymentMethod={paymentMethod}
          formItemLayout={formItemLayout}
        />
      )}
    </>
  );
};

export default AccountInformation;
