import { useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import {
  REJECT_WITHDRAWAL,
  START_PROCESS_WITHDRAWAL,
  UPDATE_WITHDRAWAL,
} from 'graphql/mutations/withdrawal.mutation';
import {
  WITHDRAWAL,
  WITHDRAWAL_REQUESTS,
} from 'graphql/queries/withdrawal.query';
import messages from 'pages/components/MemberWithdrawals/messages';
import React, { useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import RemarkModal from 'components/RemarkModal';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';

type Props = {
  isProcessing: boolean;
  id: string;
  remarks: string;
  disabled: boolean;
  refetchVariables: any;
  isOnHold: boolean;
};

const RejectWithdrawal = ({
  id,
  remarks,
  disabled,
  refetchVariables,
  isProcessing,
  isOnHold,
}: Props) => {
  const [visible, setVisible] = useState(false);

  const refetchQueries = [
    {
      query: WITHDRAWAL,
      variables: { id },
    },
    {
      query: WITHDRAWAL_REQUESTS,
      variables: refetchVariables,
    },
  ];
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [rejectWithdrawal, rejectWithdrawalResult] = useMutation(
    REJECT_WITHDRAWAL,
    {
      onCompleted: (data) => {
        if (data.rejectWithdrawal) {
          message.success(translate(messages.withdrawalRejected));
        }
      },
      variables: { id },
      refetchQueries,
      context,
    }
  );

  const [startProcessWithdrawal, startProcessResult] = useMutation(
    START_PROCESS_WITHDRAWAL,
    {
      onCompleted: (data) => {
        if (data.startProcessWithdrawal) {
          message.success(translate(messages.processing));
          rejectWithdrawal();
        }
      },
      variables: { id },
      context,
    }
  );

  const [updateWithdrawal, updateWithdrawalResult] = useMutation(
    UPDATE_WITHDRAWAL,
    {
      onCompleted: (data) => {
        if (data.updateWithdrawal) {
          message.success(translate(messages.REMARKS_UPDATED));
          if (isProcessing || isOnHold) {
            rejectWithdrawal();
          } else {
            startProcessWithdrawal();
          }
        }
        setVisible(false);
      },
      context,
    }
  );

  const onUpdateWithdrawal = ({
    variables,
  }: {
    variables: Record<string, any>;
  }) => {
    const options = {
      variables: {
        id: variables.id,
        input: {
          remarks: variables.remarks,
        },
      },
    };
    updateWithdrawal(options);
  };

  const onHandleReject = () => {
    setVisible(true);
  };

  return (
    <>
      <ALink
        onClick={onHandleReject}
        disabled={
          disabled ||
          rejectWithdrawalResult.loading ||
          startProcessResult.loading
        }
      >
        {rejectWithdrawalResult.loading || startProcessResult.loading ? (
          <Spin />
        ) : null}
        {translate(messages.reject)}
      </ALink>
      {visible && (
        <RemarkModal
          id={id}
          visible={visible}
          loading={updateWithdrawalResult.loading}
          setVisible={setVisible}
          onSubmitRemarks={onUpdateWithdrawal as any}
          initialRemarks={remarks}
        />
      )}
    </>
  );
};

export default RejectWithdrawal;
