import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { SelectProps } from 'interfaces/select.interface';
import { MEMBERS } from './queries';
import { StyledSelect, StyledCustomClear } from './styles';

const MembersSelect = ({
  onChange,
  value,
  placeHolder,
  disabled,
  label,
  multiple,
  style,
  className,
  isFilter,
  labelAsValue,
  topRightClear,
  allowClear = true,
  testId,
}: SelectProps) => {
  const { Option } = Select;

  const [filter, setFilter] = useState({
    username: {
      contains: '',
    },
  });

  const { loading, data = {}, refetch } = useQuery(MEMBERS, {
    variables: {
      filter,
      first: 10,
    },
    fetchPolicy: 'network-only',
  });

  const handleSearch = (e: string) => {
    setFilter({
      username: {
        contains: e.toUpperCase(),
      },
    });
    refetch();
  };

  const handleChange = (e: any) => {
    onChange(e);
  };

  const { members = {} }: Record<string, any> = data;
  const { edges = [] } = members;

  return (
    <div className={className}>
      <div className="d-flex justify-content-space-between">
        <div>{label}</div>
        {topRightClear ? (
          <div>
            <StyledCustomClear onClick={() => handleChange([])}>
              <FormattedMessage id="clear.text" defaultMessage="Clear" />
            </StyledCustomClear>
          </div>
        ) : (
          ''
        )}
      </div>
      <StyledSelect
        mode={multiple ? 'multiple' : undefined}
        value={value || (multiple ? [] : undefined)}
        showSearch
        placeholder={placeHolder || ''}
        onChange={handleChange}
        filterOption={false}
        loading={loading}
        onSearch={debounce(handleSearch, 150)}
        disabled={disabled}
        style={style}
        isFilter={isFilter}
        allowClear={allowClear}
        data-testid={testId || 'select'}
      >
        {edges.map(({ node }: Record<string, any>) => (
          <Option
            data-testid={`${testId}-option-${
              labelAsValue ? node.label : node.value
            }`}
            key={node.value}
            value={labelAsValue ? node.label : node.value}
          >
            {node.label}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
};

export default MembersSelect;
