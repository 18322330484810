import * as yup from 'yup';
import { CustomRegex } from 'utils/regex';
import messages from 'messages';
import gql from 'graphql-tag';
import { defineMessages } from 'react-intl';

export const messagesLocal = defineMessages({
  CURRENT_PASSWORD_INCORRECT: {
    id: 'CURRENT_PASSWORD_INCORRECT',
    defaultMessage: 'Your current password is incorrect',
  },
  SUCCESSFULLY_CHANGED_PASSWORD: {
    id: 'SUCCESSFULLY_CHANGED_PASSWORD',
    defaultMessage: 'Successfully changed password',
  },
  PASSWORD_REQUIRED: {
    id: 'PASSWORD_REQUIRED',
    defaultMessage: 'Password is required',
  },
  INVALID_PASSWORD: {
    id: 'INVALID_PASSWORD',
    defaultMessage: 'Password is invalid',
  },
  CURRENT_AND_NEW_PASSWORD_SAME_ERROR: {
    id: 'CURRENT_AND_NEW_PASSWORD_SAME_ERROR',
    defaultMessage: 'Current and New Password cannot be the same',
  },
});

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdateMeInput!, $password: String) {
    updateMe(input: $input, password: $password)
  }
`;

export const schema = (translate: any) =>
  yup.object().shape({
    password: yup
      .string()
      .required(translate(messages.REQUIRED))
      .matches(
        CustomRegex.password(),
        translate(messages.PASSWORD_VALIDATION_FIELD)
      ),
    newPassword: yup
      .string()
      .required(translate(messages.REQUIRED))
      .min(8, 'Password must be at least 8 characters')
      .max(256, 'Password length should not exceed 256 characters')
      .notOneOf(
        [yup.ref('password'), null],
        translate(messagesLocal.CURRENT_AND_NEW_PASSWORD_SAME_ERROR)
      )
      .test(
        'password',
        'Password must contain at least three of the following: lowercase characters, uppercase characters, numbers, symbols',
        (value) => {
          const lowercaseRegex = /[a-z]/;
          const uppercaseRegex = /[A-Z]/;
          const numberRegex = /\d/;
          // eslint-disable-next-line
          const symbolRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

          let count = 0;

          if (lowercaseRegex.test(value)) {
            count += 1;
          }

          if (uppercaseRegex.test(value)) {
            count += 1;
          }

          if (numberRegex.test(value)) {
            count += 1;
          }

          if (symbolRegex.test(value)) {
            count += 1;
          }

          return count >= 3;
        }
      ),
    confirmNewPassword: yup
      .string()
      .required(translate(messages.REQUIRED))
      .oneOf(
        [yup.ref('newPassword'), null],
        translate(messages.NEW_PASSWORD_DOES_NOT_MATCH)
      ),
  });
