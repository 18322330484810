import styled from 'styled-components';
import { Menu } from 'antd';

export const StyledMenu = styled(Menu)`
  button {
    text-align: left;
  }

  .ant-menu-item:hover {
    background: #f5f5f5 !important;
  }
`;

export default {};
