import Layout from 'components/Layout';
import SavedFilterSelect from 'components/SavedFilterSelect';
import { useFilterValues } from 'contexts/Filters';
import globalMessages from 'messages';
import React, { useState } from 'react';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { withdrawalProvidersTestId } from 'components/data-testid/WithdrawalProviders';
import { TabId } from 'types/nav';
import useTranslate from 'utils/useTranslate';
import SearchSettingsForm from '../SearchSettingsForm';
import FilterItems from '../FilterItems';

const SideBar = () => {
  const {
    savedFilterId,
    handleSavedFilterIdChange,
    handleFilterChange,
    filters,
    onFilterChange,
  } = useFilterValues();

  const [key, setKey] = useState(0);

  /**
   * Re-renders the <SavedFilterSelect/> to update the list of filters
   */
  const handleUpdateKey = () => {
    setKey((prev) => prev + 1);
  };
  const translate = useTranslate();
  return (
    <Layout.Sidebar
      data-testid={withdrawalProvidersTestId.sideBar}
      quickSearch={{
        filters,
        onFilterChange,
        contextKey: QuickSearchIds.WITHDRAWAL_PROVIDERS as TabId,
        onFiltersUpdate: () => handleUpdateKey(),
        hideQuickSearchFilter: true,
        quickSearchId: QuickSearchIds.WITHDRAWAL_PROVIDERS,
        isNext: true,
        searchSettingsForm: SearchSettingsForm,
      }}
    >
      <>
        <FilterItem label={translate(globalMessages.QUICK_FILTER)}>
          <SavedFilterSelect
            placeHolder={translate(globalMessages.SELECT_SAVED_CUSTOM_FILTER)}
            value={savedFilterId}
            onChange={(val) => handleSavedFilterIdChange(val)}
            context={QuickSearchIds.WITHDRAWAL_PROVIDERS as TabId}
            key={key}
          />
        </FilterItem>
        <FilterItems filters={filters} onRawFilterChange={handleFilterChange} />
      </>
    </Layout.Sidebar>
  );
};

export default SideBar;
