import React from 'react';
import { Typography } from 'antd';
import { isEmpty, get } from 'lodash';

/*
 * use this component if you're using useFormik
 * this is to replace the <ErrorMessage> of <Formik>
 *
 * @formik - useFormik reference
 * @name - formik.errors.NAME
 * */

interface IUseFormikErrorMessage {
  formik: any;
  name: string;
}

export const UseFormikErrorMessage = ({
  formik,
  name,
}: IUseFormikErrorMessage) =>
  !isEmpty(name) && !!get(formik, `touched.${name}`, null) ? (
    <Typography.Text type="danger" style={{ fontSize: '12px' }}>
      {get(formik, `errors.${name}`)}
    </Typography.Text>
  ) : (
    <></>
  );
