import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { StyledForm } from 'pages/components/Reports/DailyOperatingReport/components/SearchSettings/components/SearchSettingsForm/styles';
import { DynamicObj } from 'interfaces/user.interface';
import FilterItems from '../../../FilterItems';

const SearchSettingsForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: SearchSettingsFormType) => {
  const [initValues, setInitValues] = useState(initialValues);

  return (
    <StyledForm>
      <Row>
        <Col span={12}>
          <FilterItems
            filters={initValues}
            handleFilterChange={setInitValues}
          />
        </Col>
      </Row>

      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmit(initValues)}
          loading={isLoading}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

type SearchSettingsFormType = {
  initialValues: DynamicObj;
  onSubmit: (values: DynamicObj) => void;
  onClose: () => void;
  isLoading: boolean;
};

export default SearchSettingsForm;
