import gql from 'graphql-tag';

export const DAILY_OPERATING_REPORT = gql`
  query DailyOperatingReport(
    $first: Int
    $after: Binary
    $filter: DailyOperationReportFilterInput
    $sort: SortInput
  ) {
    dailyOperationReport(
      first: $first
      after: $after
      filter: $filter
      sort: $sort
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          date
          memberRegistrationsCount
          firstDepositsCount
          firstDepositsAmount
          depositsCount
          depositsAmount
          withdrawalsCount
          withdrawalsAmount
          profit
          betsCount
          turnover
          effectiveBet
          winloss
          profitRatio
          bonusRebates
          ngr
          ggr
          payout
          promoPayouts
          totalBonusTurnover
          totalBaseBonusTurnover
          basePromoPayouts
          baseTotalPromo
          totalPromo
          baseGgr
          baseNgr
          basePayout
          baseWinloss
          baseTurnover
          baseWinnings
          baseSportsNgr
          baseCasinoNgr
          baseVirtualNgr
          baseEffectiveBet
          depositsBaseAmount
          withdrawalsBaseAmount
        }
      }
    }
  }
`;

export default {};
