import { Select } from 'antd';
import { get, isEmpty } from 'lodash';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import removeNull from 'utils/removeNull';
import { SharedStyledSelect } from 'styles/SharedStyledSelect';

export const QuickSearchIds = {
  EXTERNAL_DEPOSIT: 'external-deposit-requests',
  INTERNAL_DEPOSIT: 'internal-deposit-requests',
  MEMBER_MANAGEMENT: 'member-management',
  MEMBER_IP_ADDRESS_SUMMARY: 'member-ip-address-summary',
  WITHDRAWAL_REQUESTS: 'withdrawal-requests',
  WITHDRAWAL_METHODS: 'withdrawal-methods',
  WITHDRAWAL_PROVIDERS: 'withdrawal-providers',
  MEMBER_LEVEL_MANAGEMENT: 'member-level-management',
  LABEL_MANAGEMENT: 'label-management',
  MEMBERS_ONLINE: 'members-online',
  INTELLIGENT_AUTOMATION: 'intelligent-automation',
  MEMBER_LOYALTY_MANAGEMENT: 'member-loyalty-management',
  PERMISSION_GROUP: 'permission-group',
  AFFILIATE_PROGRAMME: 'affiliate-programme',
  AFFILIATE_REQUEST: 'AFFILIATE_REQUEST',
  OPERATOR_MANAGEMENT: 'operator-management',
  PAYMENT_METHOD_LISTING: 'PAYMENT_METHOD_LISTING',
  THIRD_PARTY_DEPOSIT_PROVIDER: 'deposit-providers-external',
  REBATES: 'rebates',
  DAILY_OPERATING_REPORT: 'daily-operation-reports',
  MEMBER_BET_RECORD: 'member-bet-records',
  REBATE_PAYOUT_RECORDS: 'REBATE_PAYOUT_RECORDS',
  BALANCE_TRANSACTION_RECORD: 'balance-transaction-records',
  BALANCE_SERVICE_RECORD_REPORT: 'balance-service-record-report',
  SYSTEM_MESSAGE: 'manual-message',
  PROMO_ENROLLMENT_REQUESTS: 'promo-enrollment-requests',
  PROMO_REQUESTS: 'promo-requests',
  PROMO_LABELS: 'promo-labels',
  MARQUEE_MESSAGES: 'marquee-messages',
  MEMBER_ACCESS_AUDIT_HISTORY: 'member-access-audit-history',
  IP_ADDRESS_SUMMARY: 'ip-address-summary',
  MEMBER_ACCESS_SUMMARY_REPORT: 'member-access-summary-report',
  MEMBER_INTERACTION_LOG_REPORT: 'member-interaction-log-report',
  MISSIONS: 'missions',
  MEMBER_SUMMARY_REPORT: 'member-summary-report',
  // Superadmin
  SA_ALL_CLIENT: 'superadmin-all-client',
  SA_VENDOR_USAGE_REPORT: 'superadmin-vendor-usage-report',
};

type Rec = Record<string, any>;

const QuickSearchFilter = ({
  savedFilters,
  loadCustomFilters,
  searchId,
  onSelect,
  localQuickFilter,
  setLocalQuickFilter,
  filters,
  clearLocalStateFilters,
  reportsPresetDateFilter,
}: {
  savedFilters: Rec[];
  loadCustomFilters: (e?: Rec) => void;
  searchId: string;
  onSelect: (e: Rec) => void;
  localQuickFilter: string;
  setLocalQuickFilter?: Dispatch<SetStateAction<null>>;
  filters: Rec;
  clearLocalStateFilters?: () => void;
  reportsPresetDateFilter?: {
    disableQuickSearch?: boolean;
    datePresetValue?: string | null;
    handleDatePresetChange?: (value: string | null) => void;
  };
}) => {
  const initialFilter = {
    context: {
      eq: searchId,
    },
  };

  const { datePresetValue, disableQuickSearch, handleDatePresetChange } =
    reportsPresetDateFilter || {};

  const [searchInput, setSearchInput] = useState<any>('');
  const [selectedFilter, setSelectedFilter] = useState<any>('');

  const debouncedSearchInput = useDebounce(searchInput, 500);

  useEffect(() => {
    if (debouncedSearchInput) {
      const variables = {
        name: {
          contains: debouncedSearchInput,
        },
        ...initialFilter,
      };
      loadCustomFilters({
        variables,
      });
    } else {
      loadCustomFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput]);

  useEffect(() => {
    loadCustomFilters();
  }, [loadCustomFilters]);

  const onSearch = (searchValue: any) => {
    setSearchInput(searchValue);
  };

  const getMatchingFilterObj = (id: string) =>
    savedFilters.length
      ? savedFilters.filter((item) => get(item, 'id', '') === id)[0]
      : null;

  const getMatchIdByName = (name: string) =>
    savedFilters.length
      ? savedFilters.filter((item) => get(item, 'name', '') === name)[0]
      : null;

  React.useEffect(() => {
    if (isEmpty(removeNull(filters))) {
      setSelectedFilter([]);
      if (setLocalQuickFilter) {
        setLocalQuickFilter(null);
      }
    }
  }, [filters, setLocalQuickFilter]);

  const getSelectValue = () => {
    if (localQuickFilter)
      return getMatchIdByName(localQuickFilter)
        ? getMatchIdByName(localQuickFilter)?.id
        : [];
    return getMatchingFilterObj(selectedFilter)
      ? getMatchingFilterObj(selectedFilter)?.id
      : [];
  };

  return (
    <div className="mb-3 quick-filter-container">
      <div className="mb-1 fs-10 font-weight-bold">
        <FormattedMessage
          id="quick-filter.text"
          defaultMessage="Quick Filter"
        />
      </div>
      <SharedStyledSelect
        disabled={disableQuickSearch}
        value={getSelectValue()}
        onChange={(val: string) => {
          const match = getMatchingFilterObj(val);
          if (match && match.filter) {
            setSelectedFilter(match.id);
            onSelect(match.filter);
          }

          loadCustomFilters();
          if (setLocalQuickFilter) {
            setLocalQuickFilter(null);
          }
        }}
        onClear={() => {
          onSelect({});
          setSelectedFilter([]);
          if (clearLocalStateFilters) clearLocalStateFilters();
          if (datePresetValue) handleDatePresetChange!(null);
        }}
        onSearch={onSearch}
        showSearch
        allowClear
        filterOption={false}
        placeholder={
          <FormattedMessage
            id="select-a-saved-custom-filter.text"
            defaultMessage="Select a saved custom filter"
          />
        }
      >
        {savedFilters.map((savedFilter) => (
          <Select.Option value={savedFilter.id} key={savedFilter.id}>
            {savedFilter.name}
          </Select.Option>
        ))}
      </SharedStyledSelect>
    </div>
  );
};

export default QuickSearchFilter;
