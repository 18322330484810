import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Tag, Dropdown, Menu, Button } from 'antd';

export const AGENT_REQUEST_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const columns = [
  {
    title: 'Request ID',
    dataIndex: 'requestId',
  },
  {
    title: 'Account ID',
    dataIndex: 'accountId',
  },
  {
    title: 'Actual Name',
    dataIndex: 'actualName',
  },
  {
    title: 'Memberl Level',
    dataIndex: 'memberLevel',
    render: (tags: any[]) => (
      <span>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </span>
    ),
  },
  {
    title: 'Agent Loyalty Tier',
    dataIndex: 'agentLoyaltyTier',
  },
  {
    title: 'Member Loyalty Tier',
    dataIndex: 'memberLoyaltyTier',
  },
  {
    title: 'Total Turnover',
    dataIndex: 'totalTurnover',
  },
  {
    title: 'Total Payout',
    dataIndex: 'toyalPayout',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Last Online',
    dataIndex: 'lastOnline',
  },
  {
    title: 'Apply Date',
    dataIndex: 'applyDate',
  },
  {
    title: 'Join Date',
    dataIndex: 'joinDate',
  },
  {
    title: 'Approved By',
    dataIndex: 'approvedBy',
  },
  {
    title: 'Approved Date',
    dataIndex: 'approvedDate',
  },
  {
    title: '# of Active Downlines',
    dataIndex: 'activeDownlines',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    render: () => (
      <Dropdown
        placement="bottomRight"
        overlay={
          <Menu>
            <Menu.Item>Edit</Menu.Item>
            <Menu.Item>Duplicate</Menu.Item>
            <Menu.Item>Delete</Menu.Item>
          </Menu>
        }
      >
        <Button type="link" icon={<EllipsisOutlined />} />
      </Dropdown>
    ),
  },
];

export const data = [
  {
    key: '1',
    requestId: 'CoAfCb',
    accountId: 'User 1',
    actualName: 'Last name, First name',
    memberLevel: ['member level 1'],
    agentLoyaltyTier: 'AgentProgB - Peppermint Tier',
    memberLoyaltyTier: 'Tier',
    totalTurnover: 100,
    toyalPayout: 100,
    status: AGENT_REQUEST_STATUS.PENDING,
    lastOnline: '30/09/2019',
    applyDate: '30/09/2019',
    joinDate: '30/09/2019',
    approvedBy: 'Usman',
    approvedDate: '30/09/2019',
    activeDownlines: 10,
  },
  {
    key: '2',
    requestId: 'wpX4HE',
    accountId: 'User 2',
    actualName: 'Last name, First name',
    memberLevel: ['member level 2'],
    agentLoyaltyTier: 'AgentProgB - Peppermint Tier',
    memberLoyaltyTier: 'Tier',
    totalTurnover: 50,
    toyalPayout: 50,
    status: AGENT_REQUEST_STATUS.ACCEPTED,
    lastOnline: '30/09/2019',
    applyDate: '30/09/2019',
    joinDate: '30/09/2019',
    approvedBy: 'Usman',
    approvedDate: '30/09/2019',
    activeDownlines: 15,
  },
  {
    key: '3',
    requestId: 'wpX4HE',
    accountId: 'User 3',
    actualName: 'Last name, First name',
    memberLevel: ['member level 3'],
    agentLoyaltyTier: '-',
    memberLoyaltyTier: 'Tier',
    totalTurnover: 150,
    toyalPayout: 150,
    status: AGENT_REQUEST_STATUS.REJECTED,
    lastOnline: '30/09/2019',
    applyDate: '30/09/2019',
    joinDate: '30/09/2019',
    approvedBy: 'Usman',
    approvedDate: '30/09/2019',
    activeDownlines: 25,
  },
];

export default {};
