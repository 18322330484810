import React, { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, Modal, Input, Row, Col, message, Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import useTranslate from 'utils/useTranslate';
import { DynamicObj } from 'interfaces/user.interface';
import moment from 'moment';
import MoreActionsMembersFilter from 'components/MoreActionsMembersFilter';
import messages from 'messages';
import CustomSwitch from 'components/CustomSwitch';
import { UploadExcel } from 'pages/components/NewMemberManagement/components/ImportMembers/components/UploadExcel';
import { ReactComponent as CloudDownloadIcon } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/assets/cloud_download-24px.svg';
import { ALink } from 'components/ALink/ALink';
import gql from 'graphql-tag';
import { RootState } from 'store/types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { jobsActions, JobType } from 'store/jobState/jobState';
import esGet from 'utils/esGet';
import {
  CREATE_MANUAL_ADJUSTMENT,
  CREATE_PROMO,
  SUBMIT_PROMO,
  VALID_PASSWORD,
  getMS,
  localMessages,
  PROMOS_TOTAL_COUNT,
} from './utils';
import { PMAProvider, usePMAContext } from './context';
import { PMAPromosAttribution } from './components/PMAPromosAttribution';
import { PMARemarks } from './components/PMARemarks';
import { PMAPaymentMethod } from './components/PMAPaymentMethod';
import { ExcelProgress } from '../../../../../NewMemberManagement/components/ImportMembers/components/ExcelProgress';
import { ResultReport } from '../../../../../NewMemberManagement/components/ImportMembers/components/ResultReport';
import { PMAResults } from './components/PMAResults';
import { USERNAME_AMOUNT } from '../../../../../../../constants/uploadExcelValidation';

interface Props {
  members: string[];
  onSuccess: (e: any) => void;
  bulk?: boolean;
}

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: ${(props: { notOnExcel: boolean }) =>
      props?.notOnExcel ? '24px' : '0px'};
  }
`;

const CREATE_MANUAL_ADJUSTMENT_JOB = gql`
  mutation CreateCreateManualAdjustmentJob(
    $input: CreateCreateManualAdjustmentsJobInput!
    $password: String
  ) {
    createCreateManualAdjustmentsJob(input: $input, password: $password)
  }
`;

const NextEditPromoManualAdjustmentMain: React.FC<Props> = ({
  members,
  bulk,
  onSuccess,
}) => {
  const {
    state,
    setState,
    _state,
    resetState,
    form: { control, getValues, handleSubmit, errors, setError },
  } = usePMAContext();

  const translate = useTranslate();
  const client = useApolloClient();
  const [createManualAdjustment] = useMutation(CREATE_MANUAL_ADJUSTMENT);
  const [createPromo] = useMutation(CREATE_PROMO);
  const [submitPromo] = useMutation(SUBMIT_PROMO);
  const [createJob] = useMutation(CREATE_MANUAL_ADJUSTMENT_JOB);
  const dispatch = useDispatch();
  const { jobs } = useSelector(
    (selectorState: RootState) => selectorState.jobs
  );
  const [excelData, setExcelData] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [excelProgress, setExcelProgress] = useState(false);
  const [reportComplete, setReportComplete] = useState(false);

  useEffect(() => {
    setState({ isBulk: bulk });
  }, [bulk, setState]);

  useEffect(() => {
    if (state.isShow) resetState(members);
  }, [state.isShow, members, resetState]);

  useEffect(() => {
    if (state.useExcel) setState({ members: [] });
    else {
      setState({ members });
      setExcelData('');
    }
  }, [state.useExcel, members, setState]);

  const handleShow = () => {
    setState({ isShow: !state.isShow });
    if (!state.isShow) {
      setExcelData('');
      if (isSubmitting) setSubmitting(false);
    }
  };

  const addFailedMember = (failedMember: any) => {
    _state((prev: any) => ({
      ...prev,
      failedMembers: [...prev.failedMembers, failedMember],
    }));
  };

  const handleOnSubmit = async () => {
    setSubmitting(true);

    const { password } = getValues();
    let input = { ...getValues() };

    input = {
      ...input,
      claimOffsetDuration: getMS(
        input.claimOffsetDuration,
        state.claimOffsetDurationType,
        state.noClaimOffsetTime || input.automaticPayoutClaiming
      ),
      claimExpiryDuration: getMS(
        input.claimExpiryDuration,
        state.claimExpiryDurationType,
        state.noClaimExpiryTime || input.automaticPayoutClaiming
      ),
      amount: parseFloat(input.amount),
      turnoverRequirementMultiplier: parseFloat(
        input.turnoverRequirementMultiplier
      ),
    };

    try {
      const { data: promoData } = await client.query({
        query: PROMOS_TOTAL_COUNT,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            id: {
              eq: input.promo,
            },
          },
        },
      });

      const promoValid = esGet(promoData?.promos?.totalCount, 0) > 0;

      if (!promoValid) throw translate(messages.PROMO_DOES_NOT_EXIST);

      const {
        data: { validPassword },
      } = await client.query({
        query: VALID_PASSWORD,
        variables: {
          password,
        },
        fetchPolicy: 'network-only',
      });

      if (!validPassword) throw translate(messages.INVALID_PASSWORD);

      if (!input.actual) {
        // --- PROMO CREATION
        const promo = await createPromo({
          variables: {
            input: {
              template: 'EXTERNAL',
              name: input.promoInput,
              title: input.promoInput,
              category: input.category,
              validityDateTimeRange: { start: moment().format() },
            },
          },
        });

        const promoId = promo.data.createPromo;
        await submitPromo({ variables: { id: promoId } });
        input.promo = promoId;
      }

      delete input.password;
      delete input.promoInput;
      delete input.category;

      if (!bulk) {
        await createManualAdjustment({
          variables: {
            input: {
              account: members[0],
              type: 'PROMO_PAYOUT',
              ...input,
            },
            password,
          },
        });

        message.success(
          translate(localMessages.PROMO_MANUAL_ADJUSTMENT_UPDATED_SUCCESSFULLY)
        );

        onSuccess(members[0]);
        handleShow();
      } else {
        if (state.useExcel) {
          setState({ isProcessing: true });
          delete input.amount;
          const { data } = await createJob({
            variables: {
              password,
              input: {
                type: 'PROMO_PAYOUT',
                file: excelData,
                ...input,
              },
            },
          });

          setTimeout(() => {
            dispatch(
              jobsActions.addJob({
                id: data.createCreateManualAdjustmentsJob,
                name: JobType.BULK_MANUAL_ADJUSTMENT,
              })
            );
          }, 2000);
          setExcelProgress(true);
          setSubmitting(false);

          return;
        }
        handleShow();
        setState({ isProcessing: true });

        const membersToMutate = [...state.members];

        await Promise.all(
          membersToMutate.map((member: DynamicObj) => {
            const memberInput: DynamicObj = {
              account: state.useExcel ? member.id : member.key,
              type: 'PROMO_PAYOUT',
              ...input,
            };

            if (state.useExcel) memberInput.amount = member.amount;

            return createManualAdjustment({
              variables: {
                input: memberInput,
                password,
              },
            })
              .then((res) => {
                _state((prev: any) => ({
                  ...prev,
                  successCount: prev.successCount + 1,
                }));

                return res;
              })
              .catch((err) => {
                const errorMessage =
                  err.code === 'INVALID_REQUEST'
                    ? err?.original?.message
                    : err?.message;
                const e = esGet(errorMessage, err);

                addFailedMember({
                  account: member.label,
                  message: e,
                });
              });
          })
        );

        const ids = membersToMutate.map((data) =>
          state.useExcel ? data.id : data.key
        );

        onSuccess(ids);
        setState({ isProcessing: false, isResult: true });
      }
      // eslint-disable-next-line no-empty
    } finally {
    }

    setSubmitting(false);
  };

  const onChangeExcelData = (fileId: string) => {
    setExcelData(fileId);
  };

  const onExcelResetState = () => {
    setReportComplete(false);
    setExcelProgress(false);
    setState({ isProcessing: false, isResult: false });
    resetState(members);
    dispatch(jobsActions.removeJob(JobType.BULK_MANUAL_ADJUSTMENT));
    handleShow();
  };

  let isDisabled = false;

  if (bulk)
    isDisabled = state.useExcel ? !excelData.length : !state.members.length;

  const isLoading = isSubmitting || state.loadingQuery;
  const confirmCondition = isDisabled || isLoading;
  const bulkManualAdjustmentJob: any = jobs?.find(
    (x) => x.name === JobType.BULK_MANUAL_ADJUSTMENT
  );

  return (
    <>
      <Button type="link" className="text-black" onClick={handleShow} block>
        <FormattedMessage
          id="EDIT_PROMO_MANUAL_ADJUSTMENT"
          defaultMessage="Edit Promo Manual Adjustment"
        />
      </Button>

      <StyledModal
        title={
          <span className="fs-14">
            <FormattedMessage
              id="PROMO_MANUAL_ADJUSTMENT"
              defaultMessage="Promo Manual Adjustment"
            />
          </span>
        }
        visible={state.isShow}
        onCancel={onExcelResetState}
        width={!excelProgress && !reportComplete ? 600 : 300}
        footer={
          !excelProgress &&
          !reportComplete && [
            <Button key="back" onClick={() => handleShow()}>
              <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
            </Button>,
            <Popconfirm
              title={translate(
                localMessages['bulk-edit-balance-confirm-question.text']
              )}
              okText={translate(messages.YES)}
              cancelText={translate(messages.NO)}
              onConfirm={handleSubmit(handleOnSubmit)}
              disabled={confirmCondition}
            >
              <Button
                key="submit"
                type="primary"
                loading={isLoading}
                disabled={confirmCondition}
                style={{ marginLeft: '12px' }}
              >
                <FormattedMessage
                  id="save-changes.text"
                  defaultMessage="Save Changes"
                />
              </Button>
            </Popconfirm>,
          ]
        }
        style={{ top: 60 }}
        notOnExcel={!excelProgress && !reportComplete}
      >
        {!excelProgress && !reportComplete && (
          <div>
            {bulk && (
              <>
                {!state.useExcel && (
                  <div style={{ marginTop: '-18px' }}>
                    <MoreActionsMembersFilter
                      value={state.members}
                      placeholder={translate(
                        localMessages['enter-username.text']
                      )}
                      onChange={(e: any) => setState({ members: e || [] })}
                    />
                  </div>
                )}

                <div style={{ marginTop: '-12px' }}>
                  <div>
                    <span className="fs-13">
                      <FormattedMessage
                        id="import-from-excel.text"
                        defaultMessage="Import from Excel"
                      />
                    </span>
                  </div>

                  <div className="mb-2">
                    <CustomSwitch
                      textStyle={{}}
                      disabled={false}
                      value={state.useExcel}
                      onChange={(e: boolean) => setState({ useExcel: e })}
                      textHelper={null}
                      activeColor="#3ec988"
                      checkedChildren={
                        <FormattedMessage id="yes.text" defaultMessage="Yes" />
                      }
                      unCheckedChildren={
                        <FormattedMessage id="no.text" defaultMessage="No" />
                      }
                    />
                  </div>

                  {state.useExcel && (
                    <>
                      <Button>
                        <ALink
                          href={`${process.env.PUBLIC_URL}/excel/sample_template_manual_adjustment.xls`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <Icon
                            component={CloudDownloadIcon}
                            className="text-primary mr-2"
                          />
                          <FormattedMessage
                            id="download-template.text"
                            defaultMessage="Download Template"
                          />
                        </ALink>
                      </Button>

                      <div className="mt-2">
                        <UploadExcel
                          validation={USERNAME_AMOUNT}
                          onChange={onChangeExcelData}
                          onFileError={() =>
                            setError('fileId', {
                              type: 'manual',
                              message: translate(
                                messages.IMPORT_MEMBERS_10000_ROWS_HELPER
                              ),
                            })
                          }
                          excelType="import"
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <PMAPromosAttribution />
            <PMARemarks />
            <PMAPaymentMethod />

            <Row className="mt-2" gutter={16}>
              <Col span={12}>
                <span className="fs-13">
                  <FormattedMessage
                    id="password-to-confirm.text"
                    defaultMessage="Password to Confirm"
                  />
                </span>

                <Controller
                  as={Input.Password}
                  control={control}
                  name="password"
                  style={{ width: '100%' }}
                />

                <span style={{ color: 'red' }}>{errors.password?.message}</span>
              </Col>
            </Row>
          </div>
        )}

        {state.useExcel && (
          <>
            {excelProgress && !reportComplete && (
              <ExcelProgress
                percents={bulkManualAdjustmentJob?.progress * 100 || 0}
                processComplete={() => {
                  setReportComplete(true);
                  setExcelProgress(false);
                }}
              />
            )}

            {reportComplete && (
              <ResultReport
                invalidMembers={bulkManualAdjustmentJob?.failedReport || []}
                successCount={bulkManualAdjustmentJob?.result?.successCount}
                errorFileUri={
                  bulkManualAdjustmentJob?.result?.errorReportFile?.uri
                }
                totalMembersCount={
                  bulkManualAdjustmentJob?.result?.successCount +
                  bulkManualAdjustmentJob?.result?.failCount
                }
                onCloseModal={onExcelResetState}
              />
            )}
          </>
        )}
      </StyledModal>

      {!state.useExcel && <PMAResults />}
    </>
  );
};

export const NextEditPromoManualAdjustment: React.FC<Props> = (props) => (
  <PMAProvider>
    <NextEditPromoManualAdjustmentMain {...props} />
  </PMAProvider>
);
