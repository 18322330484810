import React from 'react';
import styled from 'styled-components';
import CustomSwitch from 'components/CustomSwitch';
import { EllipsisOutlined, MenuOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Table, Popconfirm } from 'antd';
import { DepositSource } from 'types/graphqlTypes';
import { useIntl } from 'react-intl';
import removeTypename from 'utils/removeTypename';
import DraggableBodyRow from 'components/DraggableBodyRow';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';

const StyledItem = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledTable = styled(Table)`
  .ant-table-thead {
    th {
      background: transparent;
      border: 0;
      padding-left: 0;
    }
  }
  .ant-table-tbody {
    td {
      padding-left: 0;
    }
  }
`;

type Props = {
  loading?: boolean;
  handleDrawerEdit: (index: number, value: Partial<DepositSource>) => void;
  removeDepositSource: (index: number, value: Partial<DepositSource>) => void;
  updateVisibility: (index: number, value: boolean) => void;
  swap: (indexA: number, indexB: number) => void;
  move: (from: number, to: number) => void;
  fields: Partial<DepositSource>[];
  prepend: (value: Partial<DepositSource>) => void;
  append: (value: Partial<DepositSource>) => void;
  remove: (index?: number) => void;
  insert: (index: number, value: Partial<DepositSource>) => void;
};

const DepositSourceList: React.FC<Props> = ({
  removeDepositSource,
  updateVisibility,
  move,
  loading,
  fields,
  handleDrawerEdit,
}) => {
  const translate = useTranslate();
  const { locale } = useIntl();

  const columns = [
    {
      title: '',
      key: 'draggable-icon',
      width: 30,
      align: 'center' as 'center',
      render: () => (
        <span className="d-flex align-items-center">
          <MenuOutlined />
        </span>
      ),
    },
    {
      title: translate(messages.DISPLAY_NAME),
      key: 'displayName',
      render: (depositSource: {
        logo: { uri: string };
        displayName: {
          EN: string;
          ZH: string;
        };
      }) => (
        <div className="d-flex">
          <img src={depositSource.logo.uri} alt="logo" height="24" width="24" />
          <span className="ml-2">
            {depositSource.displayName[locale.toLocaleUpperCase()]}
          </span>
        </div>
      ),
    },
    {
      title: translate(messages.DISPLAY),
      dataIndex: 'visible',
      key: 'visible',
      width: 80,
      render: (visible: boolean, _: any, index: number) => (
        <CustomSwitch
          textStyle={{}}
          value={visible}
          onChange={(e: any) => {
            updateVisibility(index, e);
          }}
          activeColor=""
          disabled={false}
          textHelper={null}
          checkedChildren={translate(messages.show)}
          unCheckedChildren={translate(messages.hide)}
        />
      ),
    },
    {
      title: '',
      key: 'action',
      align: 'center' as 'center',
      width: 50,
      render: (_: any, depositSource: any, index: number) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() =>
                  handleDrawerEdit(index, removeTypename(depositSource))
                }
              >
                {translate(messages.EDIT)}
              </Menu.Item>
              <Menu.Item>
                <Popconfirm
                  title={translate(messages.DELETE_CONFIRMATION_BODY_TEXT)}
                  onConfirm={() =>
                    removeDepositSource(index, removeTypename(depositSource))
                  }
                  okText={translate(messages.YES)}
                  cancelText={translate(messages.NO)}
                >
                  <StyledItem className="text-left">
                    {translate(messages.DELETE)}
                  </StyledItem>
                </Popconfirm>
              </Menu.Item>
              <Menu.Item onClick={() => move(index, 0)}>
                {translate(messages.BRING_TO_TOP)}
              </Menu.Item>
              <Menu.Item onClick={() => move(index, fields.length - 1)}>
                {translate(messages.BRING_TO_LAST)}
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          <div>
            <EllipsisOutlined
              className="cursor-pointer font-weight-bold"
              style={{ fontSize: '25px' }}
            />
          </div>
        </Dropdown>
      ),
    },
  ];

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    move(dragIndex, hoverIndex);
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  return (
    <div>
      <StyledTable
        columns={columns}
        dataSource={fields}
        rowKey={(record: any) => record.id}
        pagination={false}
        scroll={{ y: 350, x: 400 }}
        loading={loading}
        components={components}
        onRow={(_, index) =>
          ({
            index,
            moveRow,
          } as any)
        }
      />
    </div>
  );
};

export default DepositSourceList;
