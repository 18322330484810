import React from 'react';
import { useMemberTab } from 'utils/handleAddTab';
import { ALink } from 'components/ALink/ALink';

import ALL_PERMISSIONS from 'constants/permissions3';
import { checkPermission } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import { showPermissionError } from 'components/Navbar/Navbar';

import { Member } from 'types/graphqlTypes';

type PropTypes = {
  member: Partial<Member>;
};

const MemberProfile = ({ member }: PropTypes) => {
  const addMemberTab = useMemberTab();

  const { role, permissions } = usePermissions() || '';
  const isAllowed = checkPermission(
    role,
    permissions,
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS
  );

  const handleClick = () => {
    if (!isAllowed) {
      showPermissionError('Member Management - View Details');
      return;
    }
    addMemberTab(member);
  };
  return <ALink onClick={handleClick}>{member.username}</ALink>;
};

export default MemberProfile;
