import React from 'react';
import { Tag } from 'antd';
import useTranslate from 'utils/useTranslate';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import messages from './messages';

type Props = {
  filters: Record<string, any>;
  onFilterChange: (data: Record<string, any>) => void;
  isLoading: boolean;
  initialFilters: Record<string, any>;
};

const FilterConditions = ({
  filters,
  onFilterChange,
  isLoading,
  initialFilters,
}: Props) => {
  const handleRemoveUsername = (user: string, e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      const { name } = filters;
      const data = name.in.filter((item: string) => item !== user);
      const newUsername = {
        in: data,
      };
      onFilterChange({
        ...filters,
        name: data.length ? newUsername : null,
      });
    }
  };

  const handleRemoveStatus = (stat: string, e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      const { status } = filters;
      const data = status.in.filter((item: string) => item !== stat, e);
      const newStatus = {
        in: data,
      };
      onFilterChange({
        ...filters,
        status: data.length ? newStatus : null,
      });
    }
  };
  const translate = useTranslate();
  return (
    <div className="ml-3 d-flex justify-content-between">
      <div className="mt-1">
        {filters.name
          ? filters.name.in.map((item: string, key: number) => (
              <Tag
                key={key}
                closable
                onClose={(e) => handleRemoveUsername(item, e)}
              >
                {item}
              </Tag>
            ))
          : null}
        {filters.status
          ? filters.status.in.map((item: string, key: number) => (
              <Tag
                key={key}
                closable
                onClose={(e) => handleRemoveStatus(item, e)}
              >
                {translate(messages[`${item.toLowerCase()}.text`])}
              </Tag>
            ))
          : null}
      </div>
      <div className="mt-1">
        <ClearAllFilterButton
          onFilterChange={onFilterChange}
          currentFilters={filters}
          initialFilters={initialFilters}
        />
      </div>
    </div>
  );
};

export default FilterConditions;
