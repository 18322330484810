import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;

    .ant-legacy-form-item-label {
      text-align: left;

      label {
        color: rgba(0, 0, 0, 0.35);
      }
    }
  }

  .ant-legacy-form-item-label {
    height: 28px;
    z-index: 9999999 !important;
  }
`;

export default {};
