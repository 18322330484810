import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography, Input } from 'antd';
import styled from 'styled-components';
import cx from 'classnames';
import coercedGet from 'utils/coercedGet';
import { useUpdateEffect } from 'react-use';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { DynamicObj } from 'interfaces/user.interface';
import { FormikProps } from 'interfaces/formikProps.interface';
import { RebateProps } from 'interfaces/formikValues/rebates.interface';
import { CreateRebateGroupTypes } from '../../../constants';
import { useRebateGroupPercentagesState } from '../context';

const StyledCard = styled(Card)`
  width: 250px;
  .ant-card-body {
    min-height: 56px;
    padding: 12px;
  }
  border-color: ${(props: { focused?: boolean }) =>
    props.focused ? '#1890ff' : ''};
  &:hover {
    border-color: ${(props: { focused?: boolean }) =>
      props.focused ? '#1890ff' : ''};
  }
  z-index: ${(props: { focused?: boolean }) => (props.focused ? 15 : 1)};
`;

const { Text } = Typography;

const isFocused = ({
  inputName,
  focusedItems,
  currentType,
  currentLevel,
}: any) => {
  const focusedItem = focusedItems.length
    ? focusedItems.filter((item: any) => item.level === currentLevel)
    : [];

  if (!focusedItem.length) {
    return false;
  }

  const { level, vendorID, gameType } = focusedItem[0];

  const focusedItemName = `${
    currentType === CreateRebateGroupTypes.PERIODIC
      ? `levels[${level}][percentages]`
      : 'percentages'
  }[${gameType}_${vendorID}][percentage]`;

  return focusedItemName === inputName;
};

const getValue = ({ inputName, input, values }: any) => {
  const games = Object.values(coercedGet(values, `${inputName}[games]`, []));
  const formValue = coercedGet(values, `${inputName}[percentage]`, 0);
  const stateValue = input;

  if (games.length) {
    const appliedToAllGames = Object.values(games)
      .map(({ percentage }: any) => percentage)
      .every((p) => p === formValue);
    return appliedToAllGames ? formValue : '-';
  }

  return stateValue === null ? formValue : stateValue;
};

const onHandleBlur = ({
  input,
  setInput,
}: {
  input: string | null;
  setInput: any;
}) => {
  if (input === '') {
    setInput('0');
  }
};

const onHandleChange = ({ e, setInput }: any) => {
  const { value } = e.target;
  const regDecimalPlaces = /^-?\d*(\.*([0-9]{1,2})?)?%?$/;

  if (
    (!Number.isNaN(Number(value.replace('%', ''))) &&
      regDecimalPlaces.test(value)) ||
    value === ''
  ) {
    const cleansed = value.replace('-', '').replace('%', '');
    setInput(cleansed);
  }
};

const onFinishChange = ({
  setFieldValue,
  inputName,
  value,
  id,
  gameType,
}: {
  setFieldValue: (e: string, f: any) => void;
  inputName: string;
  value: any;
  gameType?: string;
  id: string;
}) => {
  setFieldValue(`${inputName}[checked]`, true);
  setFieldValue(`${inputName}[games]`, undefined);
  setFieldValue(`${inputName}[percentage]`, value);
  setFieldValue(`${inputName}[vendor]`, id);
  setFieldValue(`${inputName}[gameType]`, gameType);
};

const handleClick = ({
  focusedItems,
  currentLevel,
  node,
  gType,
  setDerivedFocusedItems,
  inputName,
  values,
  setFieldValue,
}: {
  focusedItems: Array<any>;
  currentLevel: number;
  node: any;
  setDerivedFocusedItems: (e: any) => void;
  gType: string;
  inputName: string;
  values: DynamicObj;
  setFieldValue: (e: string, f: any) => void;
}) => {
  const updatedFocusedItems = [
    ...focusedItems.filter(
      (item: { level: any }) => item.level !== currentLevel
    ),
    {
      level: currentLevel,
      vendorID: node.id,
      gameType: gType,
    },
  ];
  setDerivedFocusedItems(updatedFocusedItems);

  if (!coercedGet(values, `${inputName}[percentage]`, 0)) {
    setFieldValue(`${inputName}[percentage]`, 0);
    setFieldValue(`${inputName}[vendor]`, node.id);
    setFieldValue(`${inputName}[gameType]`, gType);
  }
};

const RebateCard = ({
  text,
  idx,
  node,
  gType,
  formikProps,
  setDerivedFocusedItems,
  otherProps,
}: {
  text: string;
  idx: number;
  node: DynamicObj;
  gType: string;
  formikProps: FormikProps<RebateProps>;
  setDerivedFocusedItems: (e: any) => void;
  otherProps?: any;
}) => {
  const { values, setFieldValue } = formikProps;
  const [{ focusedItems }] = useRebateGroupPercentagesState() as any;

  const currentType = coercedGet(values, 'type', '');
  const currentLevel = coercedGet(values, 'activeLevel', 0);

  const inputName = `${
    currentType === CreateRebateGroupTypes.PERIODIC
      ? `levels[${currentLevel}][percentages]`
      : 'percentages'
  }[${gType}_${node.id}]`;
  const focused = isFocused({
    inputName: `${inputName}[percentage]`,
    focusedItems,
    currentLevel,
    currentType,
  });

  const [input, setInput] = useState(null);
  const debouncedInput = useDebounce(input, 300);

  useEffect(() => {
    if (debouncedInput !== null) {
      onFinishChange({
        setFieldValue,
        inputName,
        value: debouncedInput,
        id: node.id,
        gameType: gType,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  useUpdateEffect(() => {
    setInput(null);
  }, [currentLevel]);

  return (
    <Col key={`${inputName}[percentage]`}>
      <StyledCard
        className={cx({ 'bg-light': (idx + 1) % 2 === 0 })}
        hoverable
        focused={focused}
        onClick={() =>
          handleClick({
            focusedItems,
            currentLevel,
            node,
            gType,
            setDerivedFocusedItems,
            inputName,
            values,
            setFieldValue,
          })
        }
      >
        <Row>
          <Col span={14}>
            <div>
              <Text style={{ fontSize: 12 }}>{text}</Text>
            </div>
          </Col>
          <Col span={10}>
            <Typography.Paragraph strong style={{ fontSize: 18 }}>
              {!otherProps.readOnly ? (
                <>
                  <Input
                    onFocus={() => {
                      if (!coercedGet(values, `${inputName}[percentage]`, 0)) {
                        setFieldValue(`${inputName}[percentage]`, 0);
                        setFieldValue(`${inputName}[vendor]`, node.id);
                        setFieldValue(`${inputName}[gameType]`, gType);
                      }
                    }}
                    onChange={(e) => onHandleChange({ e, setInput })}
                    onBlur={() => {
                      onHandleBlur({ input, setInput });
                    }}
                    value={getValue({ inputName, input, values })}
                    suffix={<span className="ml-2">%</span>}
                  />
                </>
              ) : (
                <div className="d-flex justify-content-end">
                  <span className="mr-1">
                    {coercedGet(otherProps, `${inputName}[percentage]`, 0)} %
                  </span>
                </div>
              )}
            </Typography.Paragraph>
          </Col>
        </Row>
      </StyledCard>
    </Col>
  );
};

export default RebateCard;
