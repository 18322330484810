import React from 'react';
import { Tag } from 'antd';
import cx from 'classnames';
import { ErrorHandler } from 'components/ErrorHandler';

type ModifiedTagType = {
  children: any;
  className?: string;
  isHide?: boolean;
  closable?: boolean;
  onClose?: (val: any) => void;
};

const ModifiedTag = ({
  children,
  className,
  isHide,
  ...rest
}: ModifiedTagType) => (
  <ErrorHandler>
    <Tag
      className={`mt-1 ${cx({ 'display-none': isHide })} ${className || ''}`}
      {...rest}
    >
      {children}
    </Tag>
  </ErrorHandler>
);

export default ModifiedTag;
