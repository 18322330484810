import {
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Col, Input, Row } from 'antd';
import Layout from 'components/Layout';
import ALL_PERMISSIONS from 'constants/permissions3';
import { BANNERS } from 'graphql/queries/banner.query';
import { get } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePermissions } from 'store/accountState';
import styled from 'styled-components';
import removeNull from 'utils/removeNull';
import { tablePaginationHandler } from 'utils/tablePagination';
import useTranslate from 'utils/useTranslate';
import { useDebounce } from '../common/hooks/useDebounce';
import { BannerInfo as TempBanner } from './components/BannerInfo/BannerInfo';
import BannerSettingsModal from './components/BannerSettingsModal';
import NewBannerDrawer from './components/NewBannerDrawer';
import RotatingBannerTable from './components/RotatingBannerTable';

const BannerInfo: any = TempBanner;
const SearchInput: any = styled(Input)`
  .ant-input {
    padding-left: 5px !important;
  }

  .anticon-search {
    font-size: 20px;
    color: #666 !important;
  }
`;

const pageInitialState = {
  first: 10,
  after: null,
  cursor: [null],
  currentPage: 0,
  filter: {},
};

const RotatingBanner = () => {
  const { role, permissions } = usePermissions();
  const translate = useTranslate();
  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_ROTATING_BANNER}`
  );

  const { ALLOWED_VIEW } = collectPermissions(
    role,
    permissions,
    ['VIEW'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_ROTATING_BANNER}:BANNER_SETTINGS`
  );

  const [bannerSettingsState, setBannerSettingsState] = useState<boolean>(
    false
  );
  const [newBannerState, setNewBannerState] = useState<boolean>(false);
  const [selectedBanner, setSelectedBanner] = useState<any>(null);
  const [forEditBanner, setForEditBanner] = useState<any>(null);
  const [searchBannerInput, setSearchBannerInput] = useState<any>(null);
  const [page, setPage] = useState(pageInitialState);

  const refetchVariables = {
    first: page.first,
    after: page.after,
    filter: removeNull(page.filter),
  };

  const [getBanners, { loading, errors, data }] = useLazyQuery(BANNERS, {
    variables: refetchVariables,
    fetchPolicy: 'network-only',
  }) as any;

  const debouncedSearchInput = useDebounce(searchBannerInput, 500);

  useEffect(() => {
    const searchOpts = {
      name: {
        contains: debouncedSearchInput,
      },
    };

    setPage(() => {
      const newPageState = { ...pageInitialState };

      if (debouncedSearchInput) {
        newPageState.filter = searchOpts;
      }

      return newPageState;
    });
    getBanners();
  }, [debouncedSearchInput, getBanners]);

  if (!data && errors) return <p>Error :(</p>;

  const totalBanners = get(data, 'banners.totalCount', 0);

  const onEditBanner = (banner: Record<string, any>) => {
    setForEditBanner(banner);
    setNewBannerState(true);
  };

  const onNewBanner = () => {
    setForEditBanner(null);
    setNewBannerState(true);
  };

  const totalCount = get(data, 'banners.totalCount', 0);
  const pageInfo = get(data, 'banners.pageInfo', {});

  const { totalPage, handlePrev, handleNext } = tablePaginationHandler(
    page,
    setPage,
    totalCount
  );

  return (
    <Layout.Container>
      <Layout.Header
        leftNode={
          <>
            <div>
              <h3 className="mr-3 mb-0">
                <FormattedMessage
                  id="total-banners.text"
                  defaultMessage="{bannersCount} Banners"
                  values={{
                    bannersCount: totalBanners,
                  }}
                />
              </h3>
            </div>
            <div>
              <SearchInput
                value={searchBannerInput}
                onChange={(e: {
                  target: {
                    value: string;
                  };
                }) => setSearchBannerInput(e.target.value)}
                placeholder={translate(messages['search-banner-name.text'])}
                prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              />
            </div>
          </>
        }
        rightNode={
          <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            {ALLOWED_VIEW && (
              <SettingOutlined
                onClick={() => setBannerSettingsState(true)}
                className="mr-3"
                style={{ color: '#a0a0a0' }}
              />
            )}
            {ALLOWED_CREATE && (
              <Button type="primary" onClick={onNewBanner}>
                <PlusOutlined />
                <FormattedMessage
                  id="new-banner-upload.text"
                  defaultMessage="New Banner Upload"
                />
              </Button>
            )}
          </>
        }
      />
      <Layout.Content
        sideBar={<></>}
        footer={
          <Layout.Footer
            loading={loading}
            onNext={handleNext}
            onPrev={handlePrev}
            page={page as any}
            resultsCount={totalCount}
            totalPage={totalPage}
            setPage={setPage as any}
            pageInfo={pageInfo}
          />
        }
      >
        <Row>
          <Col span={!selectedBanner ? 24 : 18}>
            <RotatingBannerTable
              selectedBanner={selectedBanner}
              bannersData={data}
              setSelectedBanner={setSelectedBanner}
              setForEditBanner={onEditBanner as any}
              refetchVariables={refetchVariables}
            />
          </Col>
          <BannerInfo selectedBanner={selectedBanner} />
        </Row>

        {bannerSettingsState && (
          <BannerSettingsModal
            setModalState={setBannerSettingsState}
            modalState={bannerSettingsState}
          />
        )}

        {newBannerState && (
          <NewBannerDrawer
            drawerState={newBannerState}
            setDrawerState={setNewBannerState}
            existingBanner={forEditBanner}
            refetchVariables={refetchVariables}
          />
        )}
      </Layout.Content>
    </Layout.Container>
  );
};

export default RotatingBanner;
