import React from 'react';
import styled from 'styled-components';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { StyledLabel } from '../utils';

export const StatusFilter: React.FC = () => {
  const { filters, setFilters } = useFilterValues();
  const value = filters.status;

  const handleChange = (e: any) => {
    let newValue = null as any;

    if (value && value.in.includes(e)) {
      const data = value.in.filter((item: any) => item !== e);
      if (data.length) {
        newValue = {
          in: value.in.filter((item: any) => item !== e),
        };
      } else {
        newValue = null;
      }
    } else if (value) {
      newValue = {
        in: [...value.in, e],
      };
    } else {
      newValue = {
        in: [e],
      };
    }

    setFilters((prev: any) => ({ ...prev, status: newValue }));
  };

  return (
    <Container>
      <div>
        <StyledLabel>
          <FormattedMessage id="status.text" defaultMessage="Status" />
        </StyledLabel>
      </div>

      <>
        <div className="checkbox-circle">
          <Checkbox onChange={() => handleChange('DRAFT')} />
          <div className="circle-yellow" />
          <div>
            <FormattedMessage id="draft.text" defaultMessage="Draft" />
          </div>
        </div>

        <div className="checkbox-circle">
          <Checkbox onChange={() => handleChange('ACTIVE')} />
          <div className="circle-green" />
          <div>
            <FormattedMessage id="active.text" defaultMessage="Active" />
          </div>
        </div>

        <div className="checkbox-circle">
          <Checkbox onChange={() => handleChange('INACTIVE')} />
          <div className="circle-red" />
          <div>
            <FormattedMessage id="inactive.text" defaultMessage="Inactive" />
          </div>
        </div>

        <div className="checkbox-circle">
          <Checkbox onChange={() => handleChange('EXPIRED')} />
          <div className="circle-gray" />
          <div>
            <FormattedMessage id="expired.text" defaultMessage="Expired" />
          </div>
        </div>
      </>
    </Container>
  );
};

const Container = styled.div`
  .checkbox-circle {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .circle-yellow {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 6px 0 10px;
    background: rgb(255, 175, 0);
  }

  .circle-green {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 6px 0 10px;
    background: rgb(0, 200, 0);
  }

  .circle-red {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 6px 0 10px;
    background: rgb(235, 0, 0);
  }

  .circle-gray {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 6px 0 10px;
    background: gray;
  }
`;
