import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import SelectFilterField from 'components/SelectFilterField';
import CheckboxFilterField from 'components/CheckboxFilterField';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import { MemberLevelStatus } from 'types/graphqlTypes';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  onFilterChange?: (value: DynamicObj) => void;
};

const FilterItems = ({ filters, onRawFilterChange: handleChange }: Props) => (
  <>
    <SelectFilterField
      testId="member-marker-mgmt-member-marker-filter"
      label="Member Marker"
      query={GET_MEMBER_LEVELS}
      queryConnection="memberLevels"
      filterFieldName="name"
      filters={filters}
      onChange={(e) =>
        handleChange({
          name: 'name',
          value: e?.length ? { in: e } : null,
        })
      }
    />
    <CheckboxFilterField
      filters={filters}
      filterFieldName="status"
      filterEnum={MemberLevelStatus}
      label="Status"
      onChange={(e) =>
        handleChange({
          name: 'status',
          value: e.length ? { in: e } : null,
        })
      }
    />
  </>
);

export default FilterItems;
