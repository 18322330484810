import React, { useEffect, useCallback, useMemo } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Badge, Button, notification } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { REBATE_GROUPS } from 'pages/components/Rebates/query';
import { UPDATE_REBATE_GROUP } from 'pages/components/Rebates/mutation';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import { showPermissionError } from 'components/Navbar/Navbar';
import coercedGet from 'utils/coercedGet';

import { FormattedMessage, useIntl } from 'react-intl';
import { STATUS_COLORS } from 'constants/colors';
import isDateInRange from 'utils/isDateInRange';
import { columnAlign } from 'utils/tableAlignment';
import { ALink } from 'components/ALink/ALink';
import { withErrorHandler } from 'components/ErrorHandler';
import { StyledTableContainer, StyledTable } from 'styles';
import { PeriodicRebateGroup, RebateGroup } from 'types/graphqlTypes';

import {
  useRebateGroupState,
  RebateGroupTypes,
  RebateGroupStatus,
} from 'pages/components/Rebates/context';
import { DATE_FORMAT } from 'constants/date';
import DuplicateRebateGroup from './DuplicateRebateGroup';
import DeleteRebateGroup from './DeleteRebateGroup';

import messages from './messages';
import { customFormatMessage } from '../../../../../utils/customFormatMessage';

const StyledBadge = styled(Badge)`
  width: ${(props) => props.status === STATUS_COLORS.INACTIVE && '64px'};
`;

type Props = {
  data: Array<{}>;
  error: boolean;
  loading: boolean;
  refetchVariables: {};
  refetch: () => void;
  showDrawer: () => void;
};

const RebatesTable = (props: Props) => {
  const { data, loading, error, refetchVariables, refetch, showDrawer } = props;

  const { role, permissions } = usePermissions();

  const {
    ALLOWED_LIST,
    ALLOWED_EDIT,
    ALLOWED_DELETE,
    ALLOWED_DUPLICATE,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'EDIT', 'DELETE', 'DUPLICATE'],
    ALL_PERMISSIONS.ALL_REBATES.REBATES_REBATES_REBATE_GROUP
  );

  const [, rebateGroupDispatch] = useRebateGroupState() as any;

  const [updateRebateGroup] = useMutation(UPDATE_REBATE_GROUP);

  const intl = useIntl();
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(intl.formatMessage, messageVal, opts);

  const { setTableColumns } = useSpreadsheetContext();

  const getColumns = useCallback(
    () => [
      {
        csvData: {
          label: 'Serial Code',
          key: 'serialCode',
          renderCell: (record: RebateGroup) => record.serialCode || '-',
        },
        key: 'serialCode',
        title: translate(messages['rebates.id.text']),
        align: columnAlign.onCenter,
        dataIndex: 'serialCode',
      },
      {
        csvData: {
          label: 'Rebate Group Name (level)',
          key: 'name',
          renderCell: (record: RebateGroup) => record.name || '-',
        },
        key: 'name',
        title: translate(messages['rebates.rebate-group-name-level.text']),
        align: columnAlign.onCenter,
        dataIndex: 'name',
        render: (text: string, record: PeriodicRebateGroup) => (
          <Button
            size="small"
            type="link"
            onClick={() => {
              rebateGroupDispatch({
                type: RebateGroupTypes.SET_ACTIVE_REBATE_GROUP,
                payload: record.id,
                meta: record,
              });
              if (record.status === RebateGroupStatus.DRAFT) {
                if (!ALLOWED_EDIT) {
                  showPermissionError('Rebates - Edit');
                  return;
                }
                showDrawer();
              } else if (
                record.status === RebateGroupStatus.ACTIVE ||
                record.status === RebateGroupStatus.INACTIVE
              ) {
                rebateGroupDispatch({
                  type: RebateGroupTypes.SET_SUMMARY_ACTIVE,
                });
              }
            }}
          >
            {text}
          </Button>
        ),
      },
      {
        title: translate(messages['rebates.to-do-list.text']),
        align: columnAlign.onCenter,
        render: (_: unknown, record: PeriodicRebateGroup) => {
          if (record.status !== RebateGroupStatus.DRAFT) {
            return null;
          }
          return (
            ALLOWED_EDIT && (
              <Button
                type="primary"
                shape="round"
                ghost
                size="small"
                onClick={() => {
                  rebateGroupDispatch({
                    type: RebateGroupTypes.SET_ACTIVE_REBATE_GROUP,
                    payload: record.id,
                    meta: record,
                  });
                  showDrawer();
                }}
              >
                <FormattedMessage
                  id="rebates.continue-editing.text"
                  defaultMessage="Continue editing"
                />
              </Button>
            )
          );
        },
      },
      {
        csvData: {
          label: 'Validity',
          key: 'validity',
          renderCell: (node: PeriodicRebateGroup) =>
            `${coercedGet(
              node,
              'validityDateTimeRange.start',
              '-'
            )} - ${coercedGet(node, 'validityDateTimeRange.end', '-')}`,
        },
        key: 'validity',
        title: translate(messages['rebates.validity.text']),
        align: columnAlign.onCenter,
        render: (_: unknown, record: PeriodicRebateGroup) => {
          const validityDateTimeRangeStart = get(
            record,
            'validityDateTimeRange.start'
          );
          const validityDateTimeRangeEnd = get(
            record,
            'validityDateTimeRange.end'
          );
          return (
            <span>
              {`${
                validityDateTimeRangeStart
                  ? moment(validityDateTimeRangeStart).format(DATE_FORMAT)
                  : ''
              } - ${
                validityDateTimeRangeEnd
                  ? moment(validityDateTimeRangeEnd).format(DATE_FORMAT)
                  : ''
              }`}
            </span>
          );
        },
      },
      {
        csvData: {
          label: 'No. of Members',
          key: 'qualifiedMembersCount',
          renderCell: (node: PeriodicRebateGroup) =>
            coercedGet(node, 'qualifiedMembersCount', '0'),
        },
        key: 'qualifiedMembersCount',
        title: translate(messages['rebates.number-of-members.text']),
        align: columnAlign.onCenter,
        dataIndex: 'qualifiedMembersCount',
        render: (text: string) => <span>{text || 0}</span>,
      },
      {
        csvData: {
          label: 'Qualifying VIP',
          key: 'qualifyingMemberLoyaltyLevels',
          renderCell: (node: PeriodicRebateGroup) =>
            coercedGet(node, 'qualifyingMemberLoyaltyLevels', '0').length,
        },
        key: 'qualifyingMemberLoyaltyLevels',
        title: translate(messages['rebates.qualifying-vip.text']),
        align: columnAlign.onCenter,
        dataIndex: 'qualifyingMemberLoyaltyLevels',
        render: (arr: []) => <span>{arr.length}</span>,
      },

      {
        csvData: {
          label: 'Status',
          key: 'status',
          renderCell: (record: RebateGroup) => record.status || '-',
        },
        key: 'status',
        title: translate(messages['rebates.status.text']),
        align: columnAlign.onCenter,
        dataIndex: 'status',
        render: (text: string) => (
          <StyledBadge
            text={translate(messages[`rebates.${text.toLowerCase()}.text`])}
            status={STATUS_COLORS[text]}
          />
        ),
      },
      {
        title: (
          <FormattedMessage
            id="rebates.actions.text"
            defaultMessage="Actions"
          />
        ),
        align: columnAlign.onCenter,
        dataIndex: 'id',
        render: (id: string, record: PeriodicRebateGroup) => {
          const startDate = get(record, 'validityDateTimeRange.start', null);
          const endDate = get(record, 'validityDateTimeRange.end', null);
          return (
            (ALLOWED_EDIT || ALLOWED_DUPLICATE || ALLOWED_DELETE) && (
              <Dropdown
                key={id}
                placement="bottomRight"
                overlay={
                  <Menu>
                    {ALLOWED_EDIT && (
                      <Menu.Item
                        disabled={record.status === RebateGroupStatus.ACTIVE}
                        onClick={() => {
                          rebateGroupDispatch({
                            type: RebateGroupTypes.SET_ACTIVE_REBATE_GROUP,
                            payload: id,
                            meta: record,
                          });
                          showDrawer();
                        }}
                      >
                        <FormattedMessage
                          id="rebates.edit.text"
                          defaultMessage="Edit"
                        />
                      </Menu.Item>
                    )}
                    {ALLOWED_DUPLICATE && (
                      <Menu.Item
                        onClick={() => {
                          rebateGroupDispatch({
                            type: RebateGroupTypes.SET_ACTIVE_REBATE_GROUP,
                            payload: id,
                          });
                          rebateGroupDispatch({
                            type: RebateGroupTypes.TOGGLE_DUPLICATE,
                            payload: id,
                          });
                        }}
                      >
                        <FormattedMessage
                          id="rebates.duplicate.text"
                          defaultMessage="Duplicate"
                        />
                      </Menu.Item>
                    )}
                    {ALLOWED_DELETE && (
                      <Menu.Item
                        disabled={record.status === RebateGroupStatus.ACTIVE}
                        onClick={() => {
                          rebateGroupDispatch({
                            type: RebateGroupTypes.SET_ACTIVE_REBATE_GROUP,
                            payload: id,
                          });
                          rebateGroupDispatch({
                            type: RebateGroupTypes.TOGGLE_DELETE,
                            payload: id,
                          });
                        }}
                      >
                        <FormattedMessage
                          id="rebates.delete.text"
                          defaultMessage="Delete"
                        />
                      </Menu.Item>
                    )}
                    {ALLOWED_EDIT && (
                      <Menu.Item
                        disabled={
                          record.status !== RebateGroupStatus.INACTIVE ||
                          !isDateInRange(
                            moment().toISOString(),
                            startDate,
                            endDate
                          )
                        }
                        onClick={async () => {
                          await updateRebateGroup({
                            variables: {
                              id,
                              input: {
                                enabled: true,
                              },
                            },
                            refetchQueries: [
                              {
                                query: REBATE_GROUPS,
                              },
                            ],
                          });
                          notification.success({
                            message: translate(
                              messages['rebates.rebate-group-activated.text']
                            ),
                          });
                        }}
                      >
                        <FormattedMessage
                          id="rebates.activate.text"
                          defaultMessage="Activate"
                        />
                      </Menu.Item>
                    )}
                    {ALLOWED_EDIT && (
                      <Menu.Item
                        disabled={record.status !== RebateGroupStatus.ACTIVE}
                        onClick={async () => {
                          await updateRebateGroup({
                            variables: {
                              id,
                              input: {
                                enabled: false,
                              },
                            },
                            refetchQueries: [
                              {
                                query: REBATE_GROUPS,
                              },
                            ],
                          });
                          notification.success({
                            message: translate(
                              messages['rebates.rebate-group-deactivated.text']
                            ),
                          });
                        }}
                      >
                        <FormattedMessage
                          id="rebates.deactivate.text"
                          defaultMessage="Deactivate"
                        />
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <ALink>
                  <EllipsisOutlined />
                </ALink>
              </Dropdown>
            )
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columnFields = useMemo(() => getColumns(), [getColumns]);

  useEffect(() => {
    setTableColumns(columnFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    rebateGroupDispatch({
      type: RebateGroupTypes.SET_REFETCH_VARIABLES,
      payload: refetchVariables,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <StyledTableContainer data-testid={!loading && !error ? 'container' : ''}>
      <span data-testid={loading ? 'loading' : ''} />
      <span data-testid={error ? 'error' : ''} />
      <DuplicateRebateGroup refetch={refetch} />
      <DeleteRebateGroup refetch={refetch} />
      {ALLOWED_LIST && (
        <StyledTable
          loading={loading}
          rowKey={(item: PeriodicRebateGroup) => item.id}
          dataSource={data}
          pagination={false}
          columns={columnFields}
        />
      )}
    </StyledTableContainer>
  );
};

export default withErrorHandler(RebatesTable, true);
