import { useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import {
  ON_HOLD_WITHDRAWAL,
  START_PROCESS_WITHDRAWAL,
  UPDATE_WITHDRAWAL,
} from 'graphql/mutations/withdrawal.mutation';
import {
  WITHDRAWAL,
  WITHDRAWAL_REQUESTS,
} from 'graphql/queries/withdrawal.query';
import React, { useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import RemarkModal from 'components/RemarkModal';

type Props = {
  id: string;
  disabled: boolean;
  isProcessing: boolean;
  refetchVariables: Record<string, string>;
};

const OnHoldWithdrawal = ({
  id,
  disabled,
  refetchVariables,
  isProcessing,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [reason, setReason] = useState('');

  const refetchQueries = [
    {
      query: WITHDRAWAL,
      variables: { id },
    },
    {
      query: WITHDRAWAL_REQUESTS,
      variables: refetchVariables,
    },
  ];
  const { context } = useOperatorHeader();
  const [onHoldWithdrawal, onHoldWithdrawalResult] = useMutation(
    ON_HOLD_WITHDRAWAL,
    {
      onCompleted: (data) => {
        if (data.onHoldWithdrawal) {
          message.success('Withdrawal request on hold');
        }
      },
      variables: { id, reason },
      refetchQueries,
      context,
    }
  );

  const [startProcessWithdrawal, startProcessResult] = useMutation(
    START_PROCESS_WITHDRAWAL,
    {
      onCompleted: (data) => {
        if (data.startProcessWithdrawal) {
          message.success('Processing');
          onHoldWithdrawal();
        }
      },
      variables: { id },
    }
  );

  const [updateWithdrawal, updateWithdrawalResult] = useMutation(
    UPDATE_WITHDRAWAL,
    {
      onCompleted: (data) => {
        if (data.updateWithdrawal) {
          message.success('Remarks updated');
          if (!isProcessing) {
            startProcessWithdrawal();
          } else {
            onHoldWithdrawal();
          }
        }
        setVisible(false);
      },
    }
  );

  const onUpdateWithdrawal = ({
    variables,
  }: {
    variables: {
      id: string;
      remarks: string;
    };
  }) => {
    const options = {
      variables: {
        id: variables.id,
        input: {
          remarks: variables.remarks,
        },
      },
    };
    updateWithdrawal(options);
    setReason(variables.remarks);
  };

  const handleOnhold = () => {
    setVisible(true);
  };

  return (
    <>
      <ALink
        onClick={handleOnhold}
        disabled={
          disabled ||
          onHoldWithdrawalResult.loading ||
          startProcessResult.loading
        }
      >
        {onHoldWithdrawalResult.loading || startProcessResult.loading ? (
          <Spin />
        ) : null}
        On hold
      </ALink>
      {visible && (
        <RemarkModal
          onHold
          id={id}
          visible={visible}
          loading={updateWithdrawalResult.loading}
          setVisible={setVisible}
          onSubmitRemarks={onUpdateWithdrawal as any}
        />
      )}
    </>
  );
};

export default OnHoldWithdrawal;
