import React from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';

type Props = {
  value: any[];
  onChange: (data: any) => void;
};

const StatusFilter = ({ value, onChange }: Props) => {
  const handleChange = (e: any) => {
    onChange(e);
  };

  return (
    <Checkbox.Group onChange={handleChange} value={value || []}>
      <Checkbox className="mt-1" value>
        <FormattedMessage id="enabled.text" defaultMessage="Enabled" />
      </Checkbox>
      <br />
      <Checkbox className="mt-1" value={false}>
        <FormattedMessage id="disabled.text" defaultMessage="Disabled" />
      </Checkbox>
    </Checkbox.Group>
  );
};

export default StatusFilter;
