import { defineMessages } from 'react-intl';

const messages = defineMessages({
  STATUS: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  NUMBER_OF_CLIENT: {
    id: 'vgm.number-of-client.text',
    defaultMessage: 'Number of Client',
  },
  NUMBER_OF_VENDOR: {
    id: 'vgm.number-of-vendor.text',
    defaultMessage: 'Number of Vendor',
  },
  VENDOR_GROUP_NAME: {
    id: 'vgm.vendor-group-name.text',
    defaultMessage: 'Vendor Group Name',
  },
});

export default messages;
