import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { get, uniqBy } from 'lodash';
import messages from 'messages/index';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import useTranslate from 'utils/useTranslate';
import { MemberLevelsConnectionEdge } from 'types/graphqlTypes';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import { Tooltip } from 'antd';
import { MEMBER_LEVELS } from './query';
import { StyledOption, StyledSelect } from './style';

type Props = {
  name: string;
  value: string | string[];
  onChange: (name: string, e: string | string[]) => void;
  disabled?: boolean;
  mode?: undefined | 'multiple' | 'tags';
};

export const MemberMarkerSelect = React.memo((props: Props) => {
  const { name, value: defaultValue, onChange, disabled, mode } = props;
  const [value, setValue] = useState(defaultValue || '');
  const [input, setInput] = useState<string>('');
  const debouncedInput = useDebounce(input, 500);
  const translate = useTranslate();

  const handleChange = useCallback(
    (val: any) => {
      if (onChange) {
        onChange(name, val);
      }
      setValue(val);

      setInput('');
    },
    [onChange, setValue, name]
  );

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );

  const [loadMemberLevels, { called, loading, data = {} }] = useLazyQuery(
    MEMBER_LEVELS,
    {
      variables: {
        filter: {
          name: {
            contains: debouncedInput,
          },
          status: {
            eq: 'ENABLED',
          },
        },
      },
      fetchPolicy: 'no-cache',
    }
  );

  if (!called && ALLOWED_LIST) loadMemberLevels();

  useEffect(() => {
    if (ALLOWED_LIST) loadMemberLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  const edges: Array<MemberLevelsConnectionEdge> = get(
    data,
    'memberLevels.edges',
    []
  );

  const options = uniqBy([...edges], 'node.id').map(
    (item: any, key: number) => (
      <StyledOption
        key={key}
        value={item.node.value}
        color={item.node.color}
        title={String(key)}
      >
        {item.node.label}
      </StyledOption>
    )
  );

  const setDefaultValue = useMemo(() => {
    if (!value) {
      const defaultMemberMarker = edges.find((edge) => edge.node.default);
      const defaultId = get(defaultMemberMarker, 'node.id', null);
      if (defaultId) {
        handleChange(defaultId);
        return defaultId;
      }
    }
    return value || '';
  }, [edges, handleChange, value]);

  return (
    <Tooltip
      placement="bottom"
      title={
        !ALLOWED_LIST && 'Need Permission on Member Marker Management (List)'
      }
    >
      <StyledSelect
        data-testid="member-marker-select"
        mode={mode || undefined}
        value={setDefaultValue}
        showSearch
        placeholder={translate(messages['enter-member-marker.text'])}
        onChange={handleChange}
        loading={loading}
        onSearch={(text: string) => {
          setInput(text);
        }}
        disabled={disabled || !ALLOWED_LIST}
        tags={[]}
        // since the colors are not showing, temporarily replace `tags={edges}` to `tags={[]}` to prevent test error for now
        // tags={edges}
      >
        <StyledOption disabled value="">
          Select member marker
        </StyledOption>
        {options}
      </StyledSelect>
    </Tooltip>
  );
});
