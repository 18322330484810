import React, { useMemo } from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import useIsNext from 'hooks/useIsNext';
import { TabIdSA } from 'SuperAdminMain/constants/route';
import AccountManagementNew from 'SuperAdminMain/components/AccountManagementNew/AccountManagementNew';
import { Account360 } from 'SuperAdminMain/components/Account360/Account360';
import { SuperAdminWhitelist } from 'SuperAdminMain/components/GeoFencing/SuperAdminWhitelist/SuperAdminWhitelist';
import VendorMaintenance from 'SuperAdminMain/components/VendorManagement/VendorMaintenance/VendorMaintenance';
import BetHistoryReport from 'SuperAdminMain/components/BetHistoryReport';
import VendorsConsolidateUsageReport from 'SuperAdminMain/components/VendorsConsolidateUsageReport';
import AccountsConsolidatedReportNext from 'SuperAdminMain/components/AccountsConsolidatedReportNext';
import { FeatureFlagsSA as FF } from 'SuperAdminMain/constants/featureflags';
import CustomReport from 'SuperAdminMain/components/CustomReport';
import VendorGroupManagement from '../../VendorManagement/VendorGroupManagement/VendorGroupManagement';

// ================================================= //
// Add here your component. Kindly follow structure. //
// ================================================= //
const useGetComponent = (id: string, accountId?: string): any =>
  useMemo(
    (): DynamicObj =>
      ({
        [TabIdSA.ALL_CLIENT]: <AccountManagementNew />,
        [TabIdSA.CLIENT_PROFILE]: <Account360 accountId={accountId || ''} />,
        [TabIdSA.WHITE_LIST]: <SuperAdminWhitelist />,
        [TabIdSA.VENDOR_MAINTENANCE]: <VendorMaintenance />,
        [TabIdSA.VENDOR_GROUP_MANAGEMENT]: {
          current: null,
          next: <VendorGroupManagement />,
          flag: FF.nextVendorGroupManagement,
        },
        [TabIdSA.BET_HISTORY_REPORT]: <BetHistoryReport />,
        [TabIdSA.ACCOUNTS_CONSOLIDATED_REPORT]: (
          <AccountsConsolidatedReportNext />
        ),
        [TabIdSA.VENDORS_CONSOLIDATED_USAGE_REPORT]: (
          <VendorsConsolidateUsageReport />
        ),
        [TabIdSA.CUSTOM_REPORT]: <CustomReport />,
      }[id]),
    [id, accountId]
  );

type Props = {
  id: string;
  accountId?: string;
};

export const TabComponent: React.FC<Props> = ({ id, accountId }) => {
  const component = useGetComponent(id, accountId);
  const next = useIsNext(component?.flag || '');
  if (component?.$$typeof) return component;
  if (next) return component.next;
  return component.current;
};
