import { get } from 'lodash';

type MultSelectTypes = 'target' | 'excluded';

const buildKey = (type: MultSelectTypes, value: string) => `${type}${value}`;

export const assignedFilterKeysByTypename = (
  values: (object & { __typename: string; id: string })[],
  type: MultSelectTypes
) =>
  values.reduce((acc, cur) => {
    let val = '';
    if (cur.__typename === 'Member') {
      val = buildKey(type, 'Members');
    } else if (cur.__typename === 'MemberLoyaltyLevel') {
      val = buildKey(type, 'MemberLoyaltyLevels');
    } else if (cur.__typename === 'MemberLevel') {
      val = buildKey(type, 'MemberLevels');
    }
    acc[val] = {
      in: [...get(acc[val], 'in', []), cur],
    };
    return acc;
  }, {});

interface IMultiMembersSelectFilterUtil {
  values: object;
  type: MultSelectTypes;
  setFilterForMultMember?: (values: object) => void;
  returnValue?: boolean;
}

export const multiMembersSelectFilterUtil = ({
  values,
  type,
  returnValue,
  setFilterForMultMember,
}: IMultiMembersSelectFilterUtil) => {
  const keys = Object.keys(values);
  const filter = keys.reduce(
    (acc, key) => {
      acc[key] = values[key];
      return acc;
    },
    {
      [buildKey(type, 'Members')]: null,
      [buildKey(type, 'MemberLoyaltyLevels')]: null,
      [buildKey(type, 'MemberLevels')]: null,
    }
  );

  if (returnValue) {
    return filter;
  }

  if (setFilterForMultMember) {
    setFilterForMultMember(filter);
  }

  return null;
};

export const getMemberName = (item: any) => {
  switch (item.__typename) {
    case 'Member':
      return item.username;
    case 'MemberLevel':
      return item.name;
    case 'MemberLoyaltyLevel':
      return `${item.programme.name} - ${item.name}`;
    default:
      return null;
  }
};
