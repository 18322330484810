import React from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { StyledLabel } from 'styles/SharedStyledSelectFilter';

type FreeSpinFilterType = {
  setState: (value: any) => void;
  state: { [key: string]: any };
};

const FreeSpinFilter = ({ state, setState }: FreeSpinFilterType) => (
  <>
    <div className="mb-1">
      <StyledLabel>
        <FormattedMessage
          id="reports.free-spin.text"
          defaultMessage="Free Spin"
        />
      </StyledLabel>
    </div>
    <div>
      <Checkbox
        checked={state.freeSpin && state.freeSpin.eq}
        onChange={() =>
          setState((prev: Record<string, any>) => ({
            ...prev,
            freeSpin: {
              eq: true,
            },
          }))
        }
        // onChange={() => handleChange(true)}
      >
        <FormattedMessage id="yes.text" defaultMessage="Yes" />
      </Checkbox>{' '}
      <Checkbox
        checked={state.freeSpin && !state.freeSpin.eq}
        onChange={() =>
          setState((prev: Record<string, any>) => ({
            ...prev,
            freeSpin: {
              eq: false,
            },
          }))
        }
      >
        <FormattedMessage id="no.text" defaultMessage="No" />
      </Checkbox>
    </div>
    <div>
      <Checkbox
        checked={state.freeSpin === null}
        onChange={() =>
          setState((prev: Record<string, any>) => ({
            ...prev,
            freeSpin: null,
          }))
        }
      >
        <FormattedMessage id="both.text" defaultMessage="Both" />
      </Checkbox>
    </div>
  </>
);

export default FreeSpinFilter;
