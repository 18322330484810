/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  useQuery,
  useMutation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import storage from 'constants/storage';
import { isBrowser } from 'utils/helpers';
import { createUploadLink } from 'apollo-upload-client';

const httpLink = createUploadLink({
  uri: isBrowser ? window.config.promoApiUrl : '',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage[storage.ACCESS_TOKEN];

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    if (graphQLErrors.length) {
      console.error({ networkError, operation });
    }
  }
});

export const promoClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

export const PromoApolloProvider: React.FC = ({ children }) => (
  <ApolloProvider client={promoClient}>{children}</ApolloProvider>
);

export { useQuery, useMutation };
