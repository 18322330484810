import React, { useCallback, useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import Icon, { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import {
  MemberInfo,
  memberToStarredMemberFormat,
} from 'store/accountState/utils';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { mapOrder } from 'utils/mapOrder';
import useTranslate from 'utils/useTranslate';
import { useStarred, usePermissions } from 'store/accountState';
import { useApolloClient } from '@apollo/react-hooks';
import {
  MemberLevel,
  MemberLevelsConnectionEdge,
  MembersConnectionsEdge,
} from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  StyledIcon,
  StyledInput,
  StyledSection,
  StyledSectionBody,
} from './styles';
import { ReactComponent as SortIcon } from './assets/sort-24px.svg';
import { MEMBER_LEVELS, STARRED_MEMBERS } from './query';
import RenderStarMembers, { starredMembersSortBy } from './StarredMembers';
import { Container, menuLabels, MenuMessage } from './utils';

type Props = {
  onCloseSidebar: () => void;
};

const RootOuterSidebar = ({ onCloseSidebar }: Props) => {
  const translate = useTranslate();
  const client = useApolloClient();
  const [searchMemberInput, setSearchMemberInput] = useState('');
  const [sortSelected, setSortSelected] = useState(
    menuLabels[starredMembersSortBy.dateRegistered]
  );
  const [searchedMembers, setSearchedMembers] = useState<Array<
    MemberInfo
  > | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [memberLevels, setMemberLevels] = useState<Array<string>>([]);
  const [sortBy, setSortBy] = useState<string>(
    starredMembersSortBy.dateRegistered
  );
  const { starred } = useStarred();

  const debouncedSearchMemberInput = useDebounce(searchMemberInput, 500);

  const orderedByMemberLevel: any = React.useCallback(
    (members: Record<string, any>[]) =>
      mapOrder(members, memberLevels, 'memberLevelName'),
    [memberLevels]
  );

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const { ALLOWED_LIST: ALLOWED_MEMBER_MARKER_LIST } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT_LIST
  );

  useEffect(() => {
    if (ALLOWED_MEMBER_MARKER_LIST) {
      client
        .query({
          query: MEMBER_LEVELS,
        })
        .then((response) => {
          const memberLevelsEdges: Array<MemberLevel> = coercedGet(
            response,
            'data.memberLevels.edges',
            []
          ).map((edge: MemberLevelsConnectionEdge) => edge.node);
          setMemberLevels(memberLevelsEdges.map((x) => x.name));
        });
    }
    return () => {
      client.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const searchMembers = React.useCallback(() => {
    if (ALLOWED_LIST) {
      client
        .query({
          query: STARRED_MEMBERS,
          variables: {
            filter: {
              starred: true,
              username: {
                contains: debouncedSearchMemberInput,
              },
            },
          },
          fetchPolicy: 'network-only',
        })
        .then((response) => {
          const membersEdges: Array<MembersConnectionsEdge & {
            node: MemberInfo;
          }> = coercedGet(response, 'data.members.edges', []);
          const nodeMembers = membersEdges
            .map((member) => member.node)
            .map((member) => memberToStarredMemberFormat(member)) as Array<
            MemberInfo
          >;

          if (sortBy === starredMembersSortBy.memberLevel) {
            setSearchedMembers(orderedByMemberLevel(nodeMembers));
          } else {
            setSearchedMembers(nodeMembers);
          }

          setIsSearching(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, debouncedSearchMemberInput]);

  useEffect(() => {
    if (debouncedSearchMemberInput) {
      searchMembers();
    } else {
      setIsSearching(false);
      setSearchedMembers(null);

      if (sortBy === starredMembersSortBy.memberLevel) {
        const orderedMembers = orderedByMemberLevel(starred);
        setSearchedMembers(orderedMembers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, debouncedSearchMemberInput]);

  const sortStarredMembers = useCallback(() => {
    const members = searchMemberInput ? searchedMembers : starred;

    switch (sortBy) {
      case starredMembersSortBy.dateRegistered: {
        const orderedByDateRegistered = orderBy(
          members,
          ['registrationDateTime'],
          ['desc']
        );
        return setSearchedMembers(orderedByDateRegistered);
      }
      case starredMembersSortBy.lastAdded: {
        const orderedByLastAdded = orderBy(
          members,
          ['watchlistRegistrationDateTime'],
          ['desc']
        );
        return setSearchedMembers(orderedByLastAdded);
      }
      case starredMembersSortBy.memberLevel: {
        const orderedMembers = orderedByMemberLevel(members);
        return setSearchedMembers(orderedMembers);
      }
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, starred]);

  useEffect(() => {
    if (!debouncedSearchMemberInput) {
      sortStarredMembers();
    } else {
      searchMembers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    if (!starred || starred.length === 0) {
      return;
    }
    sortStarredMembers();
  }, [sortStarredMembers, starred]);

  const menu = (
    <Menu
      onClick={({ key }) => {
        setSortBy(key as string);
        setSortSelected(menuLabels[key]);
      }}
    >
      <Menu.Item key={starredMembersSortBy.dateRegistered}>
        <MenuMessage
          activeKey={sortBy}
          menuKey={starredMembersSortBy.dateRegistered}
        >
          <FormattedMessage
            id="date-registered.text"
            defaultMessage="Date Registered"
          />
        </MenuMessage>
      </Menu.Item>
      <Menu.Item key={starredMembersSortBy.lastAdded}>
        <MenuMessage
          activeKey={sortBy}
          menuKey={starredMembersSortBy.lastAdded}
        >
          <FormattedMessage id="last-added.text" defaultMessage="Last Added" />
        </MenuMessage>
      </Menu.Item>
      <Menu.Item key={starredMembersSortBy.memberLevel}>
        <MenuMessage
          activeKey={sortBy}
          menuKey={starredMembersSortBy.memberLevel}
        >
          <FormattedMessage
            id="member-levels.text"
            defaultMessage="Member Levels"
          />
        </MenuMessage>
      </Menu.Item>
    </Menu>
  );

  const renderStarMembers = useCallback(() => {
    if (
      !searchMemberInput ||
      !searchedMembers ||
      searchedMembers.length === 0
    ) {
      return starred;
    }
    return searchedMembers;
  }, [searchMemberInput, searchedMembers, starred]);

  return (
    ALLOWED_LIST && (
      <Container data-testid="container">
        <StyledSection>
          <FormattedMessage id="watchlist.text" defaultMessage="Watchlist" />
          <StyledIcon type="close" onClick={onCloseSidebar} />
        </StyledSection>
        <StyledSectionBody>
          <StyledInput
            value={searchMemberInput}
            onChange={(e) => {
              setSearchMemberInput(e.target.value);
              setIsSearching(true);
            }}
            prefix={<SearchOutlined />}
            placeholder={translate(messages.SEARCH_MEMBER)}
          />
        </StyledSectionBody>

        <StyledSectionBody>
          <div>
            <Icon component={SortIcon} className="mr-1" />
            <Dropdown overlay={menu}>
              <span className="cursor-pointer">
                {translate(messages[sortSelected])} <DownOutlined />
              </span>
            </Dropdown>
          </div>
        </StyledSectionBody>
        {isSearching && (
          <div className="mt-3 d-flex justify-content-center">
            <Spin spinning={isSearching} size="large" />
          </div>
        )}
        {!isSearching && (
          <RenderStarMembers starredMembers={renderStarMembers()} />
        )}
      </Container>
    )
  );
};

export default RootOuterSidebar;
