import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'username.text': {
    id: 'username.text',
    defaultMessage: 'Username',
  },
  'affiliate.text': {
    id: 'affiliate.text',
    defaultMessage: 'Affiliate',
  },
  'real-name.text': {
    id: 'real-name.text',
    defaultMessage: 'Real Name',
  },
  'ip-address.text': {
    id: 'ip-address.text',
    defaultMessage: 'IP Address',
  },
  'ip-address-location.text': {
    id: 'ip-address-location.text',
    defaultMessage: 'IP Address Location',
  },
  'last-datetime-ip-address-used.text': {
    id: 'last-datetime-ip-address-used.text',
    defaultMessage: 'Last Date/Time IP Address Used',
  },
  'total-count-ip-address-used.text': {
    id: 'total-count-ip-address-used.text',
    defaultMessage: 'Total Count IP Address Used',
  },
});

export default messages;
