import React, { useState } from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { NewClientDrawerContainer } from 'SuperAdminMain/shared/components/NewClientAccount/NewClientDrawerContainer/NewClientDrawerContainer';
import { useAccountManagementValue } from '../../../context';

interface Props {}

export const NewAccount: React.FC<Props> = () => {
  const [newAccountDrawer, setNewAccountDrawer] = useState(false);
  const accountManagement = useAccountManagementValue();

  return (
    <Container>
      <Button type="primary" onClick={() => setNewAccountDrawer(true)}>
        <PlusOutlined />
        <FormattedMessage id="new.text" defaultMessage="New" />
      </Button>

      <NewClientDrawerContainer
        drawerState={newAccountDrawer}
        closeDrawer={() => setNewAccountDrawer(false)}
        refetchFunction={() => accountManagement.refetch()}
      />
    </Container>
  );
};

const Container = styled.div`
  margin-right: 24px;
`;
