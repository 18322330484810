import React, { useState } from 'react';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { Member } from 'types/graphqlTypes';
import { ALink } from 'components/ALink/ALink';
import copyToClipboard from 'utils/copyToClipboard';
import groupBy from 'utils/groupBy';
import { useAccount } from 'store/accountState';

type Props = {
  invalidMembers: Array<{
    username: string;
    password: string;
    message: string;
  }>;
  successCount: number;
  totalMembersCount: number;
  onCloseModal: () => void;
  errorFileUri: string;
};

export const ResultReport = ({
  invalidMembers,
  successCount,
  totalMembersCount,
  onCloseModal,
  errorFileUri,
}: Props) => {
  const {
    account: { locale },
  } = useAccount();
  const [moreDetails, setMoreDetails] = useState(false);
  const groupOfInvalidMembers = groupBy(invalidMembers, 'message');

  const translate = useTranslate();

  const BLANK_USERNAME_DETECTED =
    locale === 'en' ? 'Blank username detected' : '检测到空白用户名';

  return (
    <>
      <div className="p-3">
        <div className="d-flex fs-16 fw-500">
          <span className="mr-1">
            <CheckCircleFilled className="text-success mr-2" />
            <FormattedMessage id="success.text" defaultMessage="Success" />:
          </span>
          {successCount}/{totalMembersCount}
        </div>
        {invalidMembers.length > 0 && (
          <>
            <div className="d-flex mt-3 fs-16 fw-500">
              <span className="mr-1">
                <CloseCircleFilled className="text-danger mr-2" />
                <FormattedMessage id="failed.text" defaultMessage="Failed" />:
              </span>
              {invalidMembers.length}/{totalMembersCount}
            </div>
            <div className="mt-2">
              <small className="fs-12">
                {translate(
                  messages['MANUAL_ADJUSTMENT.IMPORT_EXCEL_FAILED_MESSAGE']
                )}
              </small>
            </div>
          </>
        )}
      </div>

      <Divider className="m-0" />

      <div className="p-2">
        <>
          {invalidMembers.length === 0 && (
            <div className="d-flex justify-content-flex-end">
              <Button
                type="primary"
                onClick={onCloseModal}
                style={{ width: '110px' }}
              >
                <FormattedMessage id="done.text" defaultMessage="Done" />
              </Button>
            </div>
          )}
          {invalidMembers.length > 0 && (
            <div className="d-flex justify-content-center">
              <Button
                className="mr-2"
                style={{ width: '110px' }}
                onClick={() => setMoreDetails(!moreDetails)}
              >
                <span>
                  <FormattedMessage
                    id="details.text"
                    defaultMessage="Details"
                  />
                </span>
                <LegacyIcon type={moreDetails ? 'up' : 'down'} />
              </Button>
              <Button
                type="primary"
                style={{ width: '110px' }}
                onClick={onCloseModal}
              >
                <FormattedMessage id="done.text" defaultMessage="Done" />
              </Button>
            </div>
          )}
        </>

        {moreDetails && (
          <div className="mt-3">
            <div
              className="pb-2 px-2"
              style={{
                background: '#f5f5f5',
                borderRadius: '10px',
                maxHeight: '170px',
                overflow: 'auto',
              }}
            >
              {Object.keys(groupOfInvalidMembers).map((errorKey) => (
                <div className="mt-2" key={errorKey}>
                  {errorKey === BLANK_USERNAME_DETECTED ? (
                    <div className="fs-12 text-black--25">
                      {groupOfInvalidMembers[errorKey].length} {errorKey}
                    </div>
                  ) : (
                    <>
                      <div className="fs-12 text-black--25">{errorKey}</div>
                      {groupOfInvalidMembers[errorKey].map((member: Member) => (
                        <div className="fs-12" key={member.username}>
                          {member.username}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <ALink href={errorFileUri}>
                <FormattedMessage
                  id="download-csv.text"
                  defaultMessage="Download CSV"
                />
              </ALink>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
              <p
                className="text-black--25 mb-0 cursor-pointer"
                onClick={() => {
                  const usernames = invalidMembers
                    .map(({ username }) => username)
                    .filter(Boolean);
                  copyToClipboard(usernames.join(', '));
                }}
              >
                <FormattedMessage
                  id="select-to-copy.text"
                  defaultMessage="Select to Copy"
                />
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
