import React from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { StyledLabel } from 'styles/SharedStyledSelectFilter';

type OperationType = Partial<{
  eq: string | boolean;
  ne: string | boolean;
  gt: string;
  gte: string;
  lt: string;
  lte: string;
  in: Array<string> | Array<boolean>;
  nin: string[];
  startsWith: string;
  contains: string;
}> | null;

type FilterType = Record<string, OperationType>;

type Props = {
  state: FilterType;
  setState: React.Dispatch<React.SetStateAction<FilterType>>;
};

const FreeSpinFilter: React.FC<Props> = ({ state, setState }) => (
  <>
    <StyledLabel className="mb-1">
      <FormattedMessage
        id="reports.registration.text"
        defaultMessage="Registration"
      />
    </StyledLabel>
    <div>
      <Checkbox
        checked={(state.registration && state.registration.eq) as boolean}
        onChange={() =>
          setState((prev: FilterType) => ({
            ...prev,
            registration: {
              eq: true,
            },
          }))
        }
      >
        <FormattedMessage id="yes.text" defaultMessage="Yes" />
      </Checkbox>{' '}
      <Checkbox
        checked={(state.registration && !state.registration.eq) as boolean}
        onChange={() =>
          setState((prev: FilterType) => ({
            ...prev,
            registration: {
              eq: false,
            },
          }))
        }
      >
        <FormattedMessage id="no.text" defaultMessage="No" />
      </Checkbox>
    </div>
    <div>
      <Checkbox
        checked={state.registration === null}
        onChange={() =>
          setState((prev: FilterType) => ({
            ...prev,
            registration: null,
          }))
        }
      >
        <FormattedMessage id="both.text" defaultMessage="Both" />
      </Checkbox>
    </div>
  </>
);

export default FreeSpinFilter;
