import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import coercedGet from 'utils/coercedGet';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import CheckboxFilterField from 'components/CheckboxFilterField';
import { RebateGroupStatus } from 'types/graphqlTypes';
import { REBATE_GROUP_NAMES } from 'pages/components/Rebates/query';
import SelectFilterField from 'components/SelectFilterField';
import VIPFilterNext from 'components/VIPFilterNext/VIPFilterNext';
import { FilterItem } from 'components/FilterItem/FilterItem';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  isCustomFilter?: boolean;
};

export default ({
  filters,
  onRawFilterChange: handleChange,
  isCustomFilter = false,
}: Props) => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST: ALLOWED_MARKER_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );

  const { ALLOWED_LIST: ALLOWED_VIP_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  );

  return (
    <>
      <SelectFilterField
        label="Group Name"
        query={REBATE_GROUP_NAMES}
        queryConnection="rebateGroups"
        filterFieldName="name"
        filters={filters}
        partialSupported={false}
        onChange={(e) =>
          handleChange({
            name: 'name',
            value: e?.length ? { in: e } : null,
          })
        }
      />

      {ALLOWED_VIP_LIST && (
        <FilterItem
          label="Qualifying VIP"
          onClear={() =>
            handleChange({
              name: 'qualifyingMemberLoyaltyLevels',
              value: null,
            })
          }
        >
          <VIPFilterNext
            values={
              filters.qualifyingMemberLoyaltyLevels
                ? filters.qualifyingMemberLoyaltyLevels.overlaps || []
                : []
            }
            onChange={(e) =>
              handleChange({
                name: 'qualifyingMemberLoyaltyLevels',
                value: e?.length ? { overlaps: e } : null,
              })
            }
          />
        </FilterItem>
      )}

      {ALLOWED_MARKER_LIST && (
        <IdBasedSelectFilterField
          label="Excluded Member Markers"
          query={GET_MEMBER_LEVELS}
          queryConnection="memberLevels"
          filterFieldName="name"
          filters={filters}
          rawFilterValues={coercedGet(
            filters,
            'excludedMemberLevels.overlaps',
            []
          )}
          isCustomFilter={isCustomFilter}
          partialSupported={false}
          onChange={(e) =>
            handleChange({
              name: 'excludedMemberLevels',
              value: e?.length ? { overlaps: e } : null,
            })
          }
        />
      )}
      <DateTimeRangePicker
        label="validity-date"
        value={
          filters.validityDateTimeRange
            ? [
                filters.validityDateTimeRange.start,
                filters.validityDateTimeRange.end,
              ]
            : null
        }
        onChange={(e) =>
          handleChange({
            name: 'validityDateTimeRange',
            value: e?.length ? { start: e[0], end: e[1] } : null,
          })
        }
      />
      <CheckboxFilterField
        filters={filters}
        filterFieldName="status"
        filterEnum={RebateGroupStatus}
        label="Status"
        onChange={(e) =>
          handleChange({
            name: 'status',
            value: e.length ? { in: e } : null,
          })
        }
      />
    </>
  );
};
