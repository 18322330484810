import React from 'react';
import gql from 'graphql-tag';
import coercedGet from 'utils/coercedGet';
import { Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';

export const CLIENT_NAMES = gql`
  query ClientNames($first: Int, $after: Binary, $filter: AdminsFilterInput) {
    admins(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

type Props = {
  adminIds: string[];
};

const ClientNames: React.FC<Props> = ({ adminIds }) => {
  const { data, loading, error } = useQuery(CLIENT_NAMES, {
    variables: {
      filter: {
        id: {
          in: [...adminIds],
        },
      },
    },
    context: { shouldBatch: true },
  });

  if (loading)
    return (
      <div className="styled-center">
        <Spin />
      </div>
    );
  if (error) {
    return (
      <span className="pl-3">
        <TableErrorCell errorMessage={error.message} />
      </span>
    );
  }

  const edges = coercedGet(data, 'admins.edges', []);

  return (
    <div>
      {edges.length ? (
        edges.map((edge: any, index: number) => {
          if (index === edges.length - 1)
            return <span>{coercedGet(edge, 'node.username', '-')}</span>;
          return <span>{`${coercedGet(edge, 'node.username', '-')}, `}</span>;
        })
      ) : (
        <span className="pl-3">-</span>
      )}
    </div>
  );
};

export default ClientNames;
