import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers';
import { Button, Input, message, Modal, Switch } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Config } from 'types/graphqlTypes';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import * as yup from 'yup';

const COINIFY_SETTINGS = gql`
  query CoinifySettings {
    config {
      coinifySecretKey
      coinifyShopId
      coinifyAccount
      coinifyBTCAddress
      coinifyTestMode
    }
  }
`;

const UPDATE_COINIFY_SHOP_DETAILS = gql`
  mutation UpdateSkrillDetails($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

const noWhitespaceAndRequired = yup
  .string()
  .nullable()
  .required('Field is required')
  .matches(/^\S+$/, 'No whitespace allowed');

const validationSchema = yup.object().shape({
  coinifyShopId: noWhitespaceAndRequired,
  coinifySecretKey: noWhitespaceAndRequired,
  coinifyAccount: noWhitespaceAndRequired,
  coinifyBTCAddress: noWhitespaceAndRequired,
});

const CoinifySettings = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { control, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      coinifyShopId: '',
      coinifySecretKey: '',
      coinifyAccount: '',
      coinifyBTCAddress: '',
      coinifyTestMode: false,
    },
    resolver: yupResolver(validationSchema),
  });

  const { context } = useOperatorHeader();
  const [updateCoinify, { loading: mutationLoading }] = useMutation(
    UPDATE_COINIFY_SHOP_DETAILS,
    {
      context,
    }
  );

  const { loading, refetch } = useQuery<{ config: Config }>(COINIFY_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: ({ config }) => {
      reset({
        coinifyShopId: config.coinifyShopId!,
        coinifySecretKey: config.coinifySecretKey!,
        coinifyAccount: config.coinifyAccount!,
        coinifyBTCAddress: config.coinifyBTCAddress!,
        coinifyTestMode: config.coinifyTestMode!,
      });
    },
  });

  const handleOnOk = (values: Record<string, string | boolean>) => {
    updateCoinify({
      variables: {
        input: {
          ...values,
        },
      },
    })
      .then(() => {
        message.success('Coinify settings updated!');
      })
      .finally(() => {
        setIsModalVisible(false);
        refetch();
      });
  };

  return (
    <div>
      <h3>Manage Coinify Shop Details</h3>
      <Button onClick={() => setIsModalVisible(true)}>Configure Coinify</Button>

      <Modal
        destroyOnClose
        title="Configure Coinify"
        visible={isModalVisible}
        onOk={handleSubmit(handleOnOk)}
        onCancel={() => setIsModalVisible(false)}
        okButtonProps={{ loading: loading || mutationLoading }}
        cancelButtonProps={{ loading: loading || mutationLoading }}
      >
        <div className="mb-3">
          <p className="mb-1 fw-500">Coinify Shop ID:</p>
          <Controller as={Input} control={control} name="coinifyShopId" />
          <span className="text-11 text-danger">
            {errors?.coinifyShopId?.message}
          </span>
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">Coinify Secret Key:</p>
          <Controller as={Input} control={control} name="coinifySecretKey" />
          <span className="text-11 text-danger">
            {errors?.coinifyShopId?.message}
          </span>
        </div>

        <div className="mb-3">
          <p className="mb-1 fw-500">Coinify Account:</p>
          <Controller as={Input} control={control} name="coinifyAccount" />
          <span className="text-11 text-danger">
            {errors?.coinifyAccount?.message}
          </span>
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">Coinify BTC Address:</p>
          <Controller as={Input} control={control} name="coinifyBTCAddress" />
          <span className="text-11 text-danger">
            {errors?.coinifyBTCAddress?.message}
          </span>
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">Test Mode:</p>
          <Controller
            control={control}
            name="coinifyTestMode"
            render={({ onChange, value }) => (
              <Switch onChange={onChange} checked={value} />
            )}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CoinifySettings;
