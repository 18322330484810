import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';

import { FormattedMessage } from 'react-intl';
import { useFilterValues } from 'contexts/Filters';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import useExpandableFilterList from 'utils/useExpandableFilterList';
import { isObjectPropsEmpty } from 'utils/isObjectPropsEmpty/isObjectPropsEmpty';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import { useScreenTabV2 } from 'store/screenTabState';
import { isPartialString } from 'constants/partialFilterKey';
import MemberTag from 'components/MemberTag';
import ModifiedTag from 'components/ModifiedTag';
import useTransformedDates from 'hooks/useTransformedDates';
import { formatDate } from 'utils/dateUtils';
import MemberLevelTag from 'components/MemberLevelTag';

type DateType = {
  gte: string;
  lte: string;
} | null;

type ModuleDateTypes = {
  dateTimeCreated?: DateType;
};

const FilterConditions = ({ isLoading = false }) => {
  const { filters, setPersistedFilters, defaultValues } = useFilterValues();

  const { clearTabState } = useScreenTabV2('member-access-audit-history');

  const [
    containerRef,
    { isTagsHidden, setIsTagsHidden },
    { showToggleArrow },
  ] = useExpandableFilterList() as any;

  const { dateTimeCreated } = useTransformedDates<ModuleDateTypes>([
    'dateTimeCreated',
  ]);

  const handleRemoveDateFilter = (dateString: string) => {
    setPersistedFilters((prev: any) => ({
      ...prev,
      [dateString]: null,
    }));
  };

  const handleRemoveTagList = (identifier: string, filterName: string) => {
    if (!isLoading) {
      const filtArray = coercedGet(filters, `${filterName}.in`, []);
      const data = filtArray.filter((item: string) => item !== identifier);

      const newFilterArray = {
        in: data,
      };
      setPersistedFilters((prev: any) => ({
        ...prev,
        [filterName]: data.length ? newFilterArray : null,
      }));
    }
  };

  const handleRemoveFilterTag = (tagName: string) => {
    if (!isLoading) {
      setPersistedFilters((prev: any) => ({
        ...prev,
        [tagName]: null,
      }));
    }
  };

  return (
    <div className="d-flex align-items-center">
      <StyledTagWrapper
        className="ml-3"
        ref={containerRef}
        style={{ width: '40vw' }}
      >
        <FormattedMessage
          id="filter-conditions.text"
          defaultMessage="Filter Conditions"
        />
        :
        <span className="mr-4" />
        {/*  TAGS HERE */}
        {filters?.brand?.in?.map((item: string) => (
          <ModifiedTag
            key={item}
            closable
            onClose={() => handleRemoveTagList(item, 'brand')}
          >
            {item}
          </ModifiedTag>
        ))}
        {filters?.memberUserName?.in?.map((item: string) =>
          isPartialString(item) ? (
            <ModifiedTag
              key={item}
              closable
              onClose={() => handleRemoveTagList(item, 'memberUserName')}
            >
              {item}
            </ModifiedTag>
          ) : (
            <MemberTag
              fieldName="username"
              key={item}
              onClose={() => handleRemoveTagList(item, 'memberUserName')}
              id={item}
            />
          )
        )}
        {filters?.memberRealName?.in?.map((item: string) =>
          isPartialString(item) ? (
            <ModifiedTag
              key={item}
              closable
              onClose={() => handleRemoveTagList(item, 'memberRealName')}
            >
              {item}
            </ModifiedTag>
          ) : (
            <MemberTag
              fieldName="realName"
              key={item}
              onClose={() => handleRemoveTagList(item, 'memberRealName')}
              id={item}
            />
          )
        )}
        {dateTimeCreated ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveDateFilter('dateTimeCreated')}
          >
            <FormattedMessage
              id="date-created.text"
              defaultMessage="Date Created"
            />
            : {formatDate(dateTimeCreated.gte)}~
            {formatDate(dateTimeCreated.lte)}
          </ModifiedTag>
        ) : null}
        {filters.memberLevel
          ? filters.memberLevel.in.map((item: string) => (
              <MemberLevelTag
                onClose={() => handleRemoveTagList(item, 'memberLevel')}
                key={item}
                id={item}
              />
            ))
          : null}
        {filters.ipAddress
          ? filters.ipAddress.in.map((item: string) => (
              <ModifiedTag
                key={item}
                closable
                onClose={() => handleRemoveTagList(item, 'ipAddress')}
              >
                <FormattedMessage
                  id="reports.ip-address.text"
                  defaultMessage="Ip Address"
                />
                : {item}
              </ModifiedTag>
            ))
          : null}
        {filters.registration ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveFilterTag('registration')}
          >
            {filters.registration ? (
              <FormattedMessage
                id="reports.registration-yes.text"
                defaultMessage="Registration: Yes"
              />
            ) : (
              <FormattedMessage
                id="reports.registration-no.text"
                defaultMessage="Registration: No"
              />
            )}
          </ModifiedTag>
        ) : null}
      </StyledTagWrapper>

      {showToggleArrow && !isObjectPropsEmpty(filters) && (
        <LegacyIcon
          type={isTagsHidden ? 'double-right' : 'double-left'}
          className="cursor-pointer mr-2 mt-1"
          onClick={() => {
            setIsTagsHidden((prev: any) => !prev);
          }}
        />
      )}

      <ClearAllFilterButton
        className="mt-1"
        initialFilters={defaultValues}
        currentFilters={filters}
        onFilterChange={(value: any) => {
          setPersistedFilters(value);
          clearTabState();
        }}
      />
    </div>
  );
};

const StyledTagWrapper = styled.div`
  /* display: flex;
  justify-content: flex-start; */
`;

export default FilterConditions;
