import React from 'react';

export const MemberLevelContext = React.createContext<any>(null);

export const MemberLevelProvider = ({ children, refetchParams = {} }: any) => (
  <MemberLevelContext.Provider value={{ refetchParams }}>
    {children}
  </MemberLevelContext.Provider>
);

export const useMemberLevel = () => React.useContext(MemberLevelContext);
