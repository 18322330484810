/*
 *  Only matches full url with http/https
 * */
// eslint-disable-next-line no-useless-escape
export const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export class CustomRegex {
  static fourDecimals() {
    return /^(?!0\d|$)\d*(\.\d{1,4})?$/;
  }

  static twoDecimals() {
    return /^(?!0\d|$)\d*(\.\d{1,2})?$/;
  }

  static password() {
    return /^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{6,36}$/;
  }

  static hasLetters() {
    return /^[^a-zA-Z]+$/;
  }

  static noSpaces() {
    return /^\S*$/;
  }

  static noSpecialCharactersAndLowercaseOnly() {
    return /^[a-z0-9]+$/;
  }

  static noStartingNumber() {
    return /^(?!^[0-9].*$).*/;
  }

  static usernameLength() {
    return /^[a-z][a-z0-9]{3,22}$/;
  }

  static noCapitalLetters() {
    return /^[^A-Z]*$/;
  }

  static onlyNumbers() {
    return /^[^a-zA-Z]+$/;
  }
}
