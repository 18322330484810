import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import ALL_PERMISSIONS from 'constants/permissions3';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import coercedGet from 'utils/coercedGet';
import {
  GET_MAHR_IP_ADDRESSES,
  GET_MAHR_REALNAMES,
  GET_MAHR_USERNAMES,
} from 'graphql/queries/memberAccessAuditHistory.query';
import BrandFilter from 'components/BrandFilter';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import RegistrationStatusFilter from 'components/RegistrationStatusFilter';
import SelectFilterField from 'components/SelectFilterField';
import { blockAlphabets } from 'utils/blockAlphabets';

type Props = {
  filters: { [x: string]: Record<string, any> | null };
  onFilterChange: (value: DynamicObj) => void;
  isCustomFilter?: boolean;
};

const FilterItems = ({
  filters,
  onFilterChange,
  isCustomFilter = false,
}: Props) => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST: ALLOWED_MARKER_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );

  const getFilterValues = (name: string) => ({
    member: filters[name],
  });

  const usernameValues = coercedGet(
    getFilterValues('memberUserName'),
    'member.in',
    []
  );

  const realnameValues = coercedGet(
    getFilterValues('memberRealName'),
    'member.in',
    []
  );

  return (
    <>
      <IdBasedSelectFilterField
        testId="MAAH-username-filter"
        label="Username"
        query={GET_MAHR_USERNAMES}
        queryConnection="members"
        filterFieldName="username"
        filters={filters}
        rawFilterValues={usernameValues}
        isCustomFilter={isCustomFilter}
        onChange={(e) => {
          const value = e?.length ? { in: e } : null;

          onFilterChange((prev: DynamicObj) => ({
            ...prev,
            memberUserName: value,
            member: value,
          }));
        }}
      />
      <IdBasedSelectFilterField
        testId="MAAH-realname-filter"
        label="Real Name"
        query={GET_MAHR_REALNAMES}
        queryConnection="members"
        filterFieldName="realName"
        filters={filters}
        rawFilterValues={realnameValues}
        isCustomFilter={isCustomFilter}
        onChange={(e) => {
          const value = e?.length ? { in: e } : null;

          onFilterChange((prev: DynamicObj) => ({
            ...prev,
            memberRealName: value,
            member: value,
          }));
        }}
      />
      {ALLOWED_MARKER_LIST && (
        <IdBasedSelectFilterField
          testId="MAAH-member-marker-filter"
          label="Member Marker"
          query={GET_MEMBER_LEVELS}
          queryConnection="memberLevels"
          filterFieldName="name"
          filters={filters}
          isCustomFilter={isCustomFilter}
          rawFilterValues={coercedGet(filters, 'memberLevel.in', [])}
          partialSupported={false}
          onChange={(e) => {
            const value = e?.length ? { in: e } : null;

            onFilterChange((prev: DynamicObj) => ({
              ...prev,
              memberLevel: value,
            }));
          }}
        />
      )}
      <BrandFilter
        testId="MAAH-brand-filter"
        setState={onFilterChange}
        state={
          filters as {
            [key: string]: Record<string, string>;
          }
        }
      />
      <DateTimeRangePicker
        testId="MAAH-last-login-dateTime-filter"
        label="reports.last-login-date-time-range"
        value={
          filters.dateTimeCreated
            ? [filters.dateTimeCreated.gte, filters.dateTimeCreated.lte]
            : null
        }
        onChange={(e) =>
          onFilterChange((prev: DynamicObj) => ({
            ...prev,
            ...(e?.length
              ? {
                  dateTimeCreated: {
                    gte: e[0],
                    lte: e[1],
                  },
                }
              : { dateTimeCreated: null }),
          }))
        }
      />
      <RegistrationStatusFilter state={filters} setState={onFilterChange} />
      <SelectFilterField
        testId="MAAH-ipAddress-filter"
        label="Ip Address"
        query={GET_MAHR_IP_ADDRESSES}
        queryConnection="memberAccessHistoryReport"
        filterFieldName="ipAddress"
        filters={filters}
        onChange={(e) =>
          onFilterChange((prev: DynamicObj) => ({
            ...prev,
            ipAddress: e?.length ? { in: e } : null,
          }))
        }
        onKeyDown={blockAlphabets}
      />
    </>
  );
};

export default FilterItems;
