import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';

const { Item } = Form;

type Props = {
  formik: Record<string, any>;
  colStyles: Record<string, any>;
};

export default function UpgradePayout({ formik, colStyles }: Props) {
  const { handleChange, values } = formik;

  const { upgradePayout } = values;

  const translate = useTranslate();
  return (
    <>
      <Item
        colon={false}
        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        label={
          <strong>
            <FormattedMessage
              id="member-loyalty.upgrade-award.text"
              defaultMessage="Upgrade Award"
            />
          </strong>
        }
        {...colStyles}
      />

      <>
        <Item
          label={<FormattedMessage id="=amount.text" defaultMessage="Amount" />}
          {...colStyles}
        >
          <Input
            name="upgradePayout.amount"
            value={upgradePayout.amount}
            onChange={handleChange}
            size="large"
            placeholder="0"
            style={{ width: '60%' }}
            type="number"
            min="0"
          />
        </Item>

        <Item label={translate(messages['turnover-x.text'])} {...colStyles}>
          <Input
            name="upgradePayout.turnover"
            value={upgradePayout.turnover}
            onChange={handleChange}
            size="large"
            placeholder={translate(messages.amount)}
            style={{ width: '60%' }}
            type="number"
            min="0"
          />
        </Item>
      </>
    </>
  );
}
