import React from 'react';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import { Divider } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { ReactComponent as RoundSearchIcon } from 'components/assets/round-search.svg';
import { ReactComponent as UserIcon } from 'components/assets/user.svg';
import { capitalize, startCase } from 'lodash';
import { formatDate } from 'utils/dateUtils';
import { DATE_FORMAT } from 'constants/date';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import { StyledSearchContainer } from '../../styles';

type Props = {
  toggleSearchMember: () => void;
  tableState: object;
  selectedFilter: string | undefined;
  refreshPage: () => void;
  filters: {
    [key: string]: any;
  };
};

const HeaderRight: React.FC<Props> = ({
  toggleSearchMember,
  tableState,
  selectedFilter,
  refreshPage,
  filters,
}) => {
  const translate = useTranslate();

  const searchedMemberDisplay = (value: string) => {
    const { gte: startDate, lte: endDate } =
      // eslint-disable-next-line dot-notation
      tableState['dateTimeCreated'] || {};

    switch (value) {
      case 'dateTimeCreated':
        return (
          <span>
            {formatDate(startDate, DATE_FORMAT)} ~{' '}
            {formatDate(endDate, DATE_FORMAT)}
          </span>
        );

      case 'module':
      case 'action':
      case 'actor':
        return capitalize(startCase(tableState[value]));

      default:
        break;
    }

    return tableState[value];
  };

  return (
    <>
      <StyledSearchContainer onClick={toggleSearchMember} className="mr-2">
        <UserIcon className="mr-2" />
        <p className="mb-0">{searchedMemberDisplay(selectedFilter!)}</p>
        <RoundSearchIcon />
      </StyledSearchContainer>
      <RefreshButton onClick={refreshPage} />
      <Divider type="vertical" className="ml-0 mr-2" />
      <ColumnsFilterNew iconOnly tabId="member-interaction-log-report" />
      <SpreadsheetDownload
        iconOnly
        filter={filters}
        filename={translate(messages.MEMBER_INTERACTION_LOG)}
        extension="csv"
      />
    </>
  );
};

export default HeaderRight;
