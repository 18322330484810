import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import SelectFilterField from 'components/SelectFilterField';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import { REAL_NAMES } from 'pages/components/NewMemberManagement/queries';
import ALL_PERMISSIONS from 'constants/permissions3';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import BrandFilter from 'components/BrandFilter';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import {
  AFFILIATE_IDS,
  BRAND_IDS,
  EMAILS,
  PLATFORM_IDS,
} from 'graphql/queries/member.query';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import coercedGet from 'utils/coercedGet';
import { isEmpty } from 'lodash';
import isRow from 'utils/isRow';
import { FormattedMessage } from 'react-intl';
import { UsernameFilter } from '../UsernameFilter/UsernameFilter';
// import { VIPFilter } from '../VIPFilter/VIPFilter';
import { LabelFilter } from '../LabelFilter/LabelFilter';
import { PostalCodeFilter } from '../PostalCodeFilter/PostalCodeFilter';
import { StatusFilter } from '../StatusFilter/StatusFilter';
import { VerificationFilter } from '../VerificationFilter/VerificationFilter';
import { MinMaxFilter } from '../MinMaxFilter/MinMaxFilter';
import { TotalEffectiveBetFilter } from '../TotalEffectiveBet/MinMaxFilter';
import { AdvancedFilter } from '../AdvancedFilter/AdvancedFilter';

type Props = {
  filters: { [x: string]: Record<string, any> };
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  isLoading: boolean;
  onFilterChange?: (value: DynamicObj) => void;
  isCustomFilter?: boolean;
};

const FilterItems = ({
  filters,
  onRawFilterChange: handleChange,
  isLoading,
  onFilterChange,
  isCustomFilter = false,
}: Props) => {
  const { role, permissions } = usePermissions();

  const showAffiliateIdFilter = isRow;

  // const { ALLOWED_LIST: ALLOWED_VIP_LIST } = collectPermissions(
  //   role,
  //   permissions,
  //   ['LIST'],
  //   ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  // );

  const { ALLOWED_LIST: ALLOWED_MARKER_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );

  return (
    <>
      <UsernameFilter
        value={filters}
        onChange={(e: any) => handleChange({ name: 'username', value: e })}
        disabled={isLoading}
      />
      <SelectFilterField
        testId="member-mgmt-realname-filter"
        label="Real Name"
        query={REAL_NAMES}
        queryConnection="members"
        filterFieldName="realName"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'realName',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <SelectFilterField
        testId="member-mgmt-email-filter"
        label="Email Address"
        query={EMAILS}
        queryConnection="members"
        filterFieldName="email"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'email',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <DateTimeRangePicker
        testId="member-mgmt-date-registered-filter"
        label="date-registered"
        value={
          filters.registrationDateTime
            ? [
                filters.registrationDateTime.gte,
                filters.registrationDateTime.lte,
              ]
            : null
        }
        onChange={(e) =>
          handleChange({
            name: 'registrationDateTime',
            value: e?.length ? { gte: e[0], lte: e[1] } : null,
          })
        }
      />

      <DateTimeRangePicker
        testId="member-mgmt-last-login-filter"
        label="last-login"
        value={
          filters.lastLoginDateTime
            ? [filters.lastLoginDateTime.gte, filters.lastLoginDateTime.lte]
            : null
        }
        onChange={(e) =>
          handleChange({
            name: 'lastLoginDateTime',
            value: e?.length ? { gte: e[0], lte: e[1] } : null,
          })
        }
      />
      {/* {ALLOWED_VIP_LIST && (
        <VIPFilter
          value={filters.memberLoyaltyLevel}
          onChange={(e) =>
            handleChange({
              name: 'memberLoyaltyLevel',
              value: e.length ? { in: e } : null,
            })
          }
          disabled={isLoading}
        />
      )} */}
      <BrandFilter
        testId="member-mgmt-brand-filter"
        setState={handleChange}
        state={filters}
        rawHandleOnChange={(e) =>
          handleChange({
            name: 'brand',
            value: e.length ? { in: e } : null,
          })
        }
      />
      <SelectFilterField
        testId="member-mgmt-brandId-filter"
        label="Brand ID"
        query={BRAND_IDS}
        queryConnection="members"
        filterFieldName="brandId"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'brandId',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <SelectFilterField
        testId="member-mgmt-platformId-filter"
        label="Platform ID"
        query={PLATFORM_IDS}
        queryConnection="members"
        filterFieldName="platformId"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'platformId',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      {showAffiliateIdFilter && (
        <SelectFilterField
          testId="member-mgmt-affiliateId-filter"
          label="Affiliate ID"
          query={AFFILIATE_IDS}
          queryConnection="members"
          filterFieldName="affiliateId"
          filters={filters}
          onChange={(e) =>
            handleChange({
              name: 'affiliateId',
              value: e?.length ? { in: e } : null,
            })
          }
        />
      )}
      {ALLOWED_MARKER_LIST && (
        <IdBasedSelectFilterField
          testId="member-mgmt-member-marker-filter"
          label="Member Marker"
          query={GET_MEMBER_LEVELS}
          queryConnection="memberLevels"
          filterFieldName="name"
          filters={filters}
          isCustomFilter={isCustomFilter}
          rawFilterValues={coercedGet(filters, 'memberLevel.in', [])}
          partialSupported={false}
          onChange={(e) =>
            handleChange({
              name: 'memberLevel',
              value: e?.length ? { in: e } : null,
            })
          }
        />
      )}
      <LabelFilter
        value={filters}
        onChange={(e) => handleChange({ name: 'tags_next', value: e })}
        disabled={isLoading}
      />
      <PostalCodeFilter
        value={filters}
        onChange={(e) => handleChange({ name: 'postalCode', value: e })}
        disabled={isLoading}
      />
      <StatusFilter
        value={filters}
        onChange={(e) => handleChange({ name: 'status', value: e })}
        disabled={isLoading}
      />
      <VerificationFilter
        value={filters}
        onChange={(e) => handleChange({ name: 'verified', value: e })}
        disabled={isLoading}
      />
      <MinMaxFilter
        label={
          <FormattedMessage
            id="account-balance.text"
            defaultMessage="Account Balance"
          />
        }
        value={filters.totalBalance}
        onChange={(e) => {
          const value = {
            ...(e.gte && { gte: e.gte }),
            ...(e.lte && { lte: e.lte }),
          };
          handleChange({
            name: 'totalBalance',
            value: isEmpty(value) ? null : value,
          });
        }}
        disabled={isLoading}
      />
      <TotalEffectiveBetFilter
        label={
          <FormattedMessage
            id="total-effective-bet.text"
            defaultMessage="Total Effective Bet"
          />
        }
        value={filters.totalEffectiveBet}
        onChange={(e) => {
          const value = {
            ...(e.gte && { gte: e.gte }),
            ...(e.lte && { lte: e.lte }),
          };
          handleChange({
            name: 'totalEffectiveBet',
            value: isEmpty(value) ? null : value,
          });
        }}
        disabled={isLoading}
      />
      <AdvancedFilter
        filters={filters}
        onFilterChange={onFilterChange}
        handleChange={handleChange}
        isLoading={isLoading}
      />
    </>
  );
};

export default FilterItems;
