import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { uniq } from 'lodash';
import { Select, ConfigProvider, Empty } from 'antd';
import {
  MemberBetRecord,
  MemberBetRecordsConnectionEdge,
} from 'types/graphqlTypes';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import messages from 'pages/components/Reports/messages';
import useTranslate from 'utils/useTranslate';
import { MEMBER_BET_RECORD_GAME_TITLES } from './queries';
import { StyledLabel, StyledSpan, StyledSelect } from '../styles';

type Props = {
  setState: (value: any) => void;
  state: { [key: string]: any };
};

const SportsGameTitleFilter = ({ state, setState }: Props) => {
  const translate = useTranslate();
  const [opts, setOpts] = useState([]);
  const [input, setInput] = useState<string>('');
  const debouncedInput = useDebounce(input, 500);

  const [loadGameTitles, { called, loading }] = useLazyQuery(
    MEMBER_BET_RECORD_GAME_TITLES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        first: 10,
        filter: {
          gameTitle: {
            contains: { EN: debouncedInput },
          },
        },
      },
      onCompleted: (data) => {
        const node = data.memberBetRecords.edges.map(
          (edge: MemberBetRecordsConnectionEdge) => edge.node
        );

        const gameTitle = node?.map((q: MemberBetRecord) => q.game.name);
        const uniqueGameTitles: any = uniq(gameTitle);
        setOpts(uniqueGameTitles);
      },
    }
  );

  if (!called) loadGameTitles();

  useEffect(() => {
    loadGameTitles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  const values = state?.gameTitle?.eq.EN ?? [];

  const handleChange = (e: any) => {
    if (e.length) {
      setState((prev: Record<string, any>) => ({
        ...prev,
        gameTitle: { eq: { EN: e[0] } },
      }));
    } else {
      setState((prev: Record<string, any>) => ({
        ...prev,
        gameTitle: null,
      }));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.game-title.text"
              defaultMessage="Game Title"
            />
          </StyledLabel>
        </div>
        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={translate(messages['no-data.text'])}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        <StyledSelect
          className="w-100"
          showSearch
          allowClear
          loading={loading}
          mode="multiple"
          placeholder={
            <FormattedMessage
              id="reports.search.text"
              defaultMessage="Search"
            />
          }
          onFocus={() => setInput('')}
          onBlur={() => setInput('')}
          value={values}
          onChange={handleChange}
          onSearch={(text: string) => setInput(text)}
        >
          {opts.map((gameTitle) => (
            <Select.Option key={gameTitle} value={gameTitle}>
              {gameTitle}
            </Select.Option>
          ))}
          <></>
        </StyledSelect>
      </ConfigProvider>
    </>
  );
};

export default SportsGameTitleFilter;
