import React, { useState } from 'react';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { MembersConnectionsEdge, MemberTag } from 'types/graphqlTypes';
import { uniqBy, flatten } from 'lodash';
import { checkPartial } from 'utils/partialUtils';
import { MEMBER_TAGS_NEXT } from './queries';
import { StyledLabel, StyledSpan, StyledSelect } from '../../styles';

const { Option } = Select;
interface Props {
  onChange: (e: Record<string, object> | null) => void;
  value: {
    tags_next?: Record<string, string[]>;
  };
  disabled: boolean;
}

export const LabelFilter: React.FC<Props> = ({ onChange, value, disabled }) => {
  const translate = useTranslate();
  const [partial, setPartial] = useState('');
  const [opts, setOpts] = useState<MemberTag[]>([]);
  const [input, setInput] = useState<string>('');
  const debouncedInput = useDebounce(input, 500);

  const [loadMemberTags, { loading }] = useLazyQuery(MEMBER_TAGS_NEXT, {
    fetchPolicy: 'no-cache',
    variables: {
      first: 10,
      filter: {
        tags_next: {
          contains: debouncedInput,
        },
      },
    },
    onCompleted: (data) => {
      const {
        members: { edges = [] },
      } = data;

      const tags = edges.map(({ node }: MembersConnectionsEdge) => node.tags);

      const flattenTagsArr = flatten(tags);
      const uniqueTags = (uniqBy(
        flattenTagsArr,
        'name'
      ) as unknown[]) as MemberTag[];

      setOpts(uniqueTags);
    },
  });

  const values = value?.tags_next?.in ?? [];

  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange({ in: e });
    } else {
      onChange(null);
    }

    setPartial('');
    setInput('');
  };

  const hasPartial = checkPartial('tags_next', value);

  return (
    <div className="mt-1">
      <div className="d-flex justify-content-between">
        <div>
          <StyledLabel>
            <FormattedMessage id="label.text" defaultMessage="Label" />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        data-testid="LabelFilter"
        mode="multiple"
        value={values}
        showSearch
        placeholder="Enter Label"
        onChange={handleChange}
        filterOption={false}
        loading={loading}
        onSearch={(text: string) => {
          setInput(text);
          setPartial(text);
        }}
        disabled={disabled}
        onFocus={() => setInput('')}
        onBlur={() => setInput('')}
        defaultActiveFirstOption
        onDropdownVisibleChange={(open: boolean) => {
          if (open && !opts.length) loadMemberTags();
        }}
      >
        {!hasPartial && partial.length && (
          <Option
            value={`${translate(messages.PARTIAL)}: ${partial}`}
            label={`${translate(messages.PARTIAL)}: ${partial}`}
          >
            <FormattedMessage id="partial.text" defaultMessage="Partial" />:{' '}
            {partial}
          </Option>
        )}

        {opts.map((tag: MemberTag) => (
          <Option key={tag.id} value={tag.name}>
            {tag.name}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
};
