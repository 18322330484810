import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import messages from '../messages';
import { StyledRootDiv } from './styles';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  width: 300px;
`;

const StyledParentDiv = styled.div<any>`
  width: ${(props) => `${300 * props.options.second.length}px`};
  padding-bottom: 100px;
  overflow: hidden;
`;

const WithdrawalsTree = () => {
  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const [checked, setChecked] = useState({
    all: {
      first: false,
      WITHDRAWALS_WITHDRAWAL_REQUESTS: false,
      WITHDRAWALS_WITHDRAWAL_METHODS: false,
      WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS: false,
      WITHDRAWALS_COMPLIANCE_CHECK: false,
      WITHDRAWALS_WITHDRAWAL_REQUESTS_LIST: false,
      WITHDRAWALS_WITHDRAWAL_METHODS_LIST: false,
      WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_LIST: false,
      WITHDRAWALS_COMPLIANCE_CHECK_LIST: false,
    },
    indeterminate: {
      first: false,
      WITHDRAWALS_WITHDRAWAL_REQUESTS: false,
      WITHDRAWALS_WITHDRAWAL_METHODS: false,
      WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS: false,
      WITHDRAWALS_COMPLIANCE_CHECK: false,
    },
    currentList: {
      WITHDRAWALS_WITHDRAWAL_REQUESTS_GRP: [],
      WITHDRAWALS_WITHDRAWAL_METHODS_GRP: [],
      WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_GRP: [],
      WITHDRAWALS_COMPLIANCE_CHECK_GRP: [],
    },
  });

  const defaultValues = Object.values(ALL_PERMISSIONS.ALL_WITHDRAWALS);

  const levels = [
    ['first', 0],
    ['second', 1],
    ['third', 2],
  ];

  const options: Record<string, any> = levels.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr[0]}`]: Object.entries(ALL_PERMISSIONS.ALL_WITHDRAWALS)
        .filter((e) => (e[1].match(/:/g) || []).length === curr[1])
        .map((e) => e[0]),
    }),
    {}
  );

  const handleFirstLevelChange = (e: any) => {
    const list = e.target.checked ? defaultValues : [];
    setFieldValue('permissions[ALL_WITHDRAWALS]', list);
  };

  const handleSecondLevelChange = (e: any) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_WITHDRAWALS];
    const secondLevelValues = defaultValues.filter(
      (v) =>
        (v.match(/:/g) || []).length >= 1 &&
        v.includes(ALL_PERMISSIONS.ALL_WITHDRAWALS[name])
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, ...secondLevelValues];
    } else {
      list = targetPermissions.filter((p) => !secondLevelValues.includes(p));
    }

    setFieldValue(
      'permissions[ALL_WITHDRAWALS]',
      uniq(list.length > 1 ? ['WITHDRAWALS', ...list] : [])
    );
  };

  const handleThirdLevelChange = (e: any) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_WITHDRAWALS];

    const unlistedName =
      ALL_PERMISSIONS.ALL_WITHDRAWALS[name.replace('_LIST', '')];

    const thirdLevelValues = defaultValues.filter(
      (v) => (v.match(/:/g) || []).length >= 2 && v.includes(unlistedName)
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, unlistedName, ...thirdLevelValues];
    } else {
      list = targetPermissions.filter(
        (p) => !thirdLevelValues.includes(p) && p !== unlistedName
      );
    }

    setFieldValue(
      'permissions[ALL_WITHDRAWALS]',
      uniq(list.length >= 3 ? ['WITHDRAWALS', ...list] : [])
    );
  };

  const handleOnChange = (list: any[], name: string) => {
    const stateName = name.replace('_GRP', '');

    const secondLevelPermissionLookup =
      ALL_PERMISSIONS.ALL_WITHDRAWALS[stateName];
    const thirdLevelPermissionLookup =
      ALL_PERMISSIONS.ALL_WITHDRAWALS[
        `${stateName}${
          stateName === 'WITHDRAWALS_COMPLIANCE_CHECK' ? '_VIEW' : '_LIST'
        }`
      ];

    const thirdLevelPermissions = Object.values(
      ALL_PERMISSIONS.ALL_WITHDRAWALS
    ).filter((p) => (p.match(/:/g) || []).length === 2);

    const targetPermissions = [...values.permissions.ALL_WITHDRAWALS];

    const templatePermissions = thirdLevelPermissions.filter(
      (p) =>
        p.includes(secondLevelPermissionLookup) &&
        p !== secondLevelPermissionLookup &&
        p !== thirdLevelPermissionLookup
    );

    setFieldValue(
      'permissions[ALL_WITHDRAWALS]',
      uniq([
        ...targetPermissions.filter((p) => !templatePermissions.includes(p)),
        'WITHDRAWALS',
        secondLevelPermissionLookup,
        thirdLevelPermissionLookup,
        ...list,
      ])
    );
  };

  const availablePermissions = coercedGet(
    values,
    'permissions.ALL_WITHDRAWALS',
    []
  );
  useEffect(() => {
    const permissionMatch =
      availablePermissions.length ===
      Object.values(ALL_PERMISSIONS.ALL_WITHDRAWALS).length;

    const thirdLevelPermissions = availablePermissions.filter(
      (p: any) => (p.match(/:/g) || []).length === 2
    );

    const secondLevelKeys = [
      ['WITHDRAWALS_WITHDRAWAL_REQUESTS', 'WITHDRAWALS:WITHDRAWAL_REQUESTS', 7],
      ['WITHDRAWALS_WITHDRAWAL_METHODS', 'WITHDRAWALS:WITHDRAWAL_METHODS', 7],
      [
        'WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS',
        'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS',
        6,
      ],
      ['WITHDRAWALS_COMPLIANCE_CHECK', 'WITHDRAWALS:COMPLIANCE_CHECK', 2],
    ];

    const thirdLevelChecks: Record<string, any> = secondLevelKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr[0]}`]:
          thirdLevelPermissions.filter((p: any) => p.includes(`${curr[1]}`))
            .length === curr[2],
      }),
      {}
    );

    const allChecks = {
      WITHDRAWALS_WITHDRAWAL_REQUESTS:
        permissionMatch || thirdLevelChecks.WITHDRAWALS_WITHDRAWAL_REQUESTS,
      WITHDRAWALS_WITHDRAWAL_METHODS:
        permissionMatch || thirdLevelChecks.WITHDRAWALS_WITHDRAWAL_METHODS,
      WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS:
        permissionMatch ||
        thirdLevelChecks.WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS,
      WITHDRAWALS_COMPLIANCE_CHECK:
        permissionMatch || thirdLevelChecks.WITHDRAWALS_COMPLIANCE_CHECK,
    };

    setChecked({
      all: {
        first: permissionMatch,
        WITHDRAWALS_WITHDRAWAL_REQUESTS:
          allChecks.WITHDRAWALS_WITHDRAWAL_REQUESTS,
        WITHDRAWALS_WITHDRAWAL_METHODS:
          allChecks.WITHDRAWALS_WITHDRAWAL_METHODS,
        WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS:
          allChecks.WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS,
        WITHDRAWALS_COMPLIANCE_CHECK: allChecks.WITHDRAWALS_COMPLIANCE_CHECK,

        WITHDRAWALS_WITHDRAWAL_REQUESTS_LIST:
          thirdLevelPermissions.includes(
            'WITHDRAWALS:WITHDRAWAL_REQUESTS:LIST'
          ) || allChecks.WITHDRAWALS_WITHDRAWAL_REQUESTS,
        WITHDRAWALS_WITHDRAWAL_METHODS_LIST:
          thirdLevelPermissions.includes(
            'WITHDRAWALS:WITHDRAWAL_METHODS:LIST'
          ) || allChecks.WITHDRAWALS_WITHDRAWAL_METHODS,
        WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_LIST:
          thirdLevelPermissions.includes(
            'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS:LIST'
          ) || allChecks.WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS,
        WITHDRAWALS_COMPLIANCE_CHECK_LIST:
          thirdLevelPermissions.includes('WITHDRAWALS:COMPLIANCE_CHECK:VIEW') ||
          allChecks.WITHDRAWALS_COMPLIANCE_CHECK,
      },
      indeterminate: {
        first: availablePermissions.length && !permissionMatch,

        WITHDRAWALS_WITHDRAWAL_REQUESTS:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('WITHDRAWALS:WITHDRAWAL_REQUESTS')
          ).length && !thirdLevelChecks.WITHDRAWALS_WITHDRAWAL_REQUESTS,

        WITHDRAWALS_WITHDRAWAL_METHODS:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('WITHDRAWALS:WITHDRAWAL_METHODS')
          ).length && !thirdLevelChecks.WITHDRAWALS_WITHDRAWAL_METHODS,
        WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS')
          ).length && !thirdLevelChecks.WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS,
        WITHDRAWALS_COMPLIANCE_CHECK:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('WITHDRAWALS:COMPLIANCE_CHECK')
          ).length && !thirdLevelChecks.WITHDRAWALS_COMPLIANCE_CHECK,
      },
      currentList: {
        WITHDRAWALS_WITHDRAWAL_REQUESTS_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('WITHDRAWALS:WITHDRAWAL_REQUESTS') &&
            p !== 'WITHDRAWALS:WITHDRAWAL_REQUESTS' &&
            p !== 'WITHDRAWALS:WITHDRAWAL_REQUESTS:LIST'
        ),
        WITHDRAWALS_WITHDRAWAL_METHODS_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('WITHDRAWALS:WITHDRAWAL_METHODS') &&
            p !== 'WITHDRAWALS:WITHDRAWAL_METHODS' &&
            p !== 'WITHDRAWALS:WITHDRAWAL_METHODS:LIST'
        ),
        WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS') &&
            p !== 'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS' &&
            p !== 'WITHDRAWALS:3RD_WITHDRAWAL_PROVIDERS:LIST'
        ),
        WITHDRAWALS_COMPLIANCE_CHECK_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('WITHDRAWALS:COMPLIANCE_CHECK') &&
            p !== 'WITHDRAWALS:COMPLIANCE_CHECK' &&
            p !== 'WITHDRAWALS:COMPLIANCE_CHECK:VIEW'
        ),
      },
    });
  }, [availablePermissions]);
  const translate = useTranslate();
  return (
    <StyledRootDiv>
      <StyledParentDiv options={options}>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={checked.all.first}
              indeterminate={checked.indeterminate.first}
              onChange={handleFirstLevelChange}
            >
              <StyledText size={16}>
                {translate(
                  messages[ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS]
                )}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <div className="w-100">
          <div className="mt-4 d-flex ml-3">
            {options.second.map((s: any) => (
              <StyledDiv>
                <Checkbox
                  key={s}
                  name={s}
                  checked={checked.all[s]}
                  indeterminate={checked.indeterminate[s]}
                  onChange={handleSecondLevelChange}
                >
                  <StyledText size={14}>{translate(messages[s])}</StyledText>
                </Checkbox>
                <div className="ml-4 mt-2">
                  <Checkbox
                    key={`${s}_LIST`}
                    name={`${s}_LIST`}
                    checked={checked.all[`${s}_LIST`]}
                    onChange={handleThirdLevelChange}
                  >
                    <StyledText size={14}>
                      {translate(messages.LIST)}
                    </StyledText>
                  </Checkbox>
                  <Checkbox.Group
                    className="w-100 ml-2"
                    onChange={(v) => handleOnChange(v, `${s}_GRP`)}
                    value={checked.currentList[`${s}_GRP`]}
                  >
                    <div className="ml-4">
                      {options.third
                        .filter(
                          (t: any, idx: number) =>
                            t.includes(s) &&
                            idx !== 0 &&
                            idx !== 7 &&
                            idx !== 14 &&
                            idx !== 20
                        )
                        .map((t: any) => (
                          <StyledDiv className="mt-2">
                            <Checkbox
                              key={ALL_PERMISSIONS.ALL_WITHDRAWALS[t]}
                              value={ALL_PERMISSIONS.ALL_WITHDRAWALS[t]}
                            >
                              <StyledText size={14}>
                                {translate(messages[t.replace(`${s}_`, '')])}
                              </StyledText>
                            </Checkbox>
                          </StyledDiv>
                        ))}
                    </div>
                  </Checkbox.Group>
                </div>
              </StyledDiv>
            ))}
          </div>
        </div>
      </StyledParentDiv>
    </StyledRootDiv>
  );
};

export default WithdrawalsTree;
