import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  visible: boolean;
  onOk: () => void;
  onCancel: () => void;
  okButtonProps?: boolean;
  title: string;
  message?: string;
};

const ConfirmationModal = ({
  visible,
  onOk,
  onCancel,
  okButtonProps = false,
  title,
  message,
}: Props) => {
  const titleId = `${title.replace(/\s+/g, '-').toLowerCase()}.text`;

  return (
    <Modal
      closable={false}
      width={416}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okButtonProps={{ loading: okButtonProps }}
      centered
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">
          <FormattedMessage id={titleId} defaultMessage={title} />
        </span>
        <div className="ant-modal-confirm-content">
          {message || (
            <FormattedMessage
              id="are-you-sure?.text"
              defaultMessage="Are you sure?"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmationModal;
