import gql from 'graphql-tag';

export const CREATE_MEMBER_NOTES = gql`
  mutation CreateMemberNote($input: CreateMemberNoteInput!) {
    createMemberNote(input: $input)
  }
`;

export const UPDATE_MEMBER_NOTE = gql`
  mutation UpdateMemberNote($id: ID!, $input: UpdateMemberNoteInput!) {
    updateMemberNote(id: $id, input: $input)
  }
`;

export const DELETE_MEMBER_NOTE = gql`
  mutation DeleteMemberNote($id: ID!) {
    deleteMemberNote(id: $id)
  }
`;

export default {};
