/* eslint-disable no-unused-expressions */
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get, uniq } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import {
  MemberBalanceUnitRecord,
  MemberBalanceUnitRecordsEdge,
} from 'types/graphqlTypes';
import { StyledSelect } from 'styles';
import { StyledLabel, StyledSpan } from '../styles';

const { Option } = Select;

type BalanceUnitSerialCode = {
  state: Record<string, Record<string, string>>;
  setState: (value: any) => void;
};

const BALANCE_UNIT_SERIAL_CODES = gql`
  query MemberBalanceUnitRecords {
    memberBetRecords(filter: {}) {
      edges {
        node {
          id
          balanceUnitSerialCodes
        }
      }
    }
  }
`;

const BalanceUnitSerialCode = ({ state, setState }: BalanceUnitSerialCode) => {
  const { data } = useQuery(BALANCE_UNIT_SERIAL_CODES);
  const edges = get(data, 'memberBalanceUnitRecords.edges', []);
  const options: MemberBalanceUnitRecord[] = edges.map(
    (edge: MemberBalanceUnitRecordsEdge) => edge.node
  );

  const handleChange = (e: string[]) => {
    if (e.length) {
      setState((prev: Record<string, string>) => ({
        ...prev,
        balanceUnitSerialCodes: { includesAny: e },
      }));
    } else {
      setState((prev: Record<string, string>) => ({
        ...prev,
        balanceUnitSerialCodes: null,
      }));
    }
  };

  const hasBalanceSerialCodes = options.filter(
    (option) => option!.serialCode!.length > 0
  );

  const uniqueSerialCodes = uniq(
    hasBalanceSerialCodes.map((x) => x?.serialCode)
  );

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.balance-unit-serial-code.text"
              defaultMessage="Balance Unit Serial Code"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan
            onClick={() =>
              setState((prev: any) => ({
                ...prev,
                balanceUnitSerialCodes: null,
              }))
            }
          >
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        data-testid="select-balanceunitserialcode"
        allowClear
        placeholder="Select"
        mode="multiple"
        style={{ width: '100%' }}
        value={
          state.balanceUnitSerialCodes
            ? state.balanceUnitSerialCodes.includesAny
            : []
        }
        optionFilterProp="label"
        onChange={handleChange}
      >
        {uniqueSerialCodes.map((serialCode) => (
          <Option
            key={serialCode!}
            value={serialCode!}
            label={serialCode}
            style={{ fontSize: '12px' }}
          >
            {serialCode}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};

export default BalanceUnitSerialCode;
