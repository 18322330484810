import { uniqBy } from 'lodash';

export const initReducerState = {
  members: [],
  isSearching: false,
  initialized: false,
  filter: {
    username: {
      startsWith: '',
    },
  },
};

export const actions = {
  SET_MEMBERS: 'SET_MEMBERS',
  SET_SEARCHING: 'SET_SEARCHING',
  SET_FILTER: 'SET_FILTER',
  OVERWRITE_MEMBERS: 'OVERWRITE_MEMBERS',
  SET_INITIALIZED: 'SET_INITIALIZED',
};

export const reducer = (state: Record<string, any>, [type, payload]: any) => {
  switch (type) {
    case actions.SET_MEMBERS:
      return {
        ...state,
        members: uniqBy([...state.members, ...payload], 'id'),
      };
    case actions.SET_SEARCHING:
      return {
        ...state,
        isSearching: payload,
      };
    case actions.SET_FILTER:
      return {
        ...state,
        filter: payload,
      };
    case actions.OVERWRITE_MEMBERS:
      return {
        ...state,
        members: payload,
      };
    case actions.SET_INITIALIZED:
      return {
        ...state,
        initialized: payload,
      };
    default:
      return state;
  }
};
