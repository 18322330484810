import gql from 'graphql-tag';

export const CREATE_CUSTOM_FILTER = gql`
  mutation CreateCustomFilter($input: CreateCustomFilterInput) {
    createCustomFilter(input: $input)
  }
`;

export const DELETE_CUSTOM_FILTER = gql`
  mutation DeleteCustomFilter($id: ID!) {
    deleteCustomFilter(id: $id)
  }
`;

export const UPDATE_CUSTOM_FILTER = gql`
  mutation UpdateCustomFilter($id: ID!, $input: UpdateCustomFilterInput) {
    updateCustomFilter(id: $id, input: $input)
  }
`;

export default {};
