import { defineMessages } from 'react-intl';
import * as Yup from 'yup';
import messages from 'messages';
import { CustomRegex } from 'utils/regex';
import { toFinite } from 'lodash';

export const localMessages = defineMessages({
  MUST_HAVE_AT_LEAST_1_VIP: {
    id: 'MUST_HAVE_AT_LEAST_1_VIP',
    defaultMessage: 'Must have at least 1 VIP selected',
  },
  'MISSIONS.GROUP_NAME_MAX_CHAR': {
    id: 'MISSIONS.GROUP_NAME_MAX_CHAR',
    defaultMessage: 'Group Name must be within 20 characters',
  },
  'MISSIONS.GROUP_NAME_DUPLICATE_ERROR': {
    id: 'MISSIONS.GROUP_NAME_DUPLICATE_ERROR',
    defaultMessage: 'Group Name cannot be the same!',
  },
});
export const validationSchema = (translate: any, hideMilestone: boolean) =>
  Yup.object().shape({
    memberLoyaltyGroups: Yup.array().of(
      Yup.object().shape({
        qualifiedMemberLoyaltyLevels: Yup.array()
          .min(1, translate(localMessages.MUST_HAVE_AT_LEAST_1_VIP))
          .required(translate(messages.REQUIRED)),
        name: Yup.string()
          .required(translate(messages.REQUIRED))
          .max(20, translate(localMessages['MISSIONS.GROUP_NAME_MAX_CHAR'])),
        milestoneRewardMultiplier: hideMilestone
          ? Yup.string()
          : Yup.string()
              .required(translate(messages.REQUIRED))
              .matches(
                CustomRegex.onlyNumbers(),
                translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
              )
              .test(
                'milestoneRewardMultiplier',
                translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 }),
                (value) => {
                  const valueTransform = toFinite(value);
                  return valueTransform > 0;
                }
              )
              .matches(
                CustomRegex.twoDecimals(),
                translate(messages['maximum-count-decimal-places.text'], {
                  count: 2,
                })
              ),
        taskMultiplier: Yup.string()
          .required(translate(messages.REQUIRED))
          .matches(
            CustomRegex.onlyNumbers(),
            translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
          )
          .test(
            'taskMultiplier',
            translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 }),
            (value) => {
              const valueTransform = toFinite(value);
              return valueTransform > 0;
            }
          )
          .matches(
            CustomRegex.twoDecimals(),
            translate(messages['maximum-count-decimal-places.text'], {
              count: 2,
            })
          ),
        taskRewardMultiplier: Yup.string()
          .required(translate(messages.REQUIRED))
          .matches(
            CustomRegex.onlyNumbers(),
            translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
          )
          .test(
            'taskRewardMultiplier',
            translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 }),
            (value) => {
              const valueTransform = toFinite(value);
              return valueTransform > 0;
            }
          )
          .matches(
            CustomRegex.twoDecimals(),
            translate(messages['maximum-count-decimal-places.text'], {
              count: 2,
            })
          ),
      })
    ),
  });
