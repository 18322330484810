import styled from 'styled-components';

export const Flex = styled.div<FlexProps>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'unset'};
  justify-content: ${({ justifyContent }) => justifyContent || 'unset'};
`;

type FlexProps = {
  alignItems?: string;
  justifyContent?: string;
};
