import styled from 'styled-components';

export const StyledContainer = styled.div`
  .twitter-picker {
    border: unset !important;
    box-shadow: none !important;
    border-radius: unset !important;
    margin-top: 10px;
    height: 94px;
    div {
      > span {
        & > div {
          height: 16px !important;
          width: 16px !important;
          margin: 0px 4px 4px 0px !important;
        }
      }

      > div {
        display: none !important;
      }
    }
  }
`;

export const StyledColor: any = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${(props: { value: string }) => props.value || '#efefef'};
  cursor: pointer;
`;

export default {};
