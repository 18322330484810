import { useMutation } from '@apollo/react-hooks';
import { message, Switch } from 'antd';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import MemberDropdown from 'pages/components/NewMemberDetail/components/MemberDropdown';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import {
  ContainerBody,
  ContainerHeader,
} from 'pages/components/NewMemberDetail/styles';
import React, { useState } from 'react';
import { Member, MutationUpdateMemberArgs } from 'types/graphqlTypes';

const AccountRestriction = () => {
  const { member, refetch } = useMember() as {
    member: Member;
    refetch: Function;
  };

  const [showContent, setShowContent] = useState(true);
  const [checked, setChecked] = useState({
    depositBlocked: member.depositBlocked,
    withdrawalBlocked: member.withdrawalBlocked,
    wagerBlocked: member.wagerBlocked,
    promotionBlocked: member.promotionBlocked,
  });

  const {
    depositBlocked,
    withdrawalBlocked,
    wagerBlocked,
    promotionBlocked,
  } = checked;

  const [updateMember] = useMutation<boolean, MutationUpdateMemberArgs>(
    UPDATE_MEMBER
  );

  const variables = {
    id: member?.id,
  };

  const onDepositBlockChange = (checkValue: boolean) => {
    setChecked({ ...checked, depositBlocked: checkValue });
    updateMember({
      variables: {
        ...variables,
        input: {
          depositBlocked: checkValue,
        },
      },
    }).then(() => {
      message.success('Block Deposits Updated');
      refetch();
    });
  };

  const onWithdrawalBlockChange = (checkValue: boolean) => {
    setChecked({ ...checked, withdrawalBlocked: checkValue });
    updateMember({
      variables: {
        ...variables,
        input: {
          withdrawalBlocked: checkValue,
        },
      },
    }).then(() => {
      message.success('Block Withdrawals Updated');
      refetch();
    });
  };

  const onWagerBlockChange = (checkValue: boolean) => {
    setChecked({ ...checked, wagerBlocked: checkValue });
    updateMember({
      variables: {
        ...variables,
        input: {
          wagerBlocked: checkValue,
        },
      },
    }).then(() => {
      message.success('Block Wager Updated');
      refetch();
    });
  };

  const onPromotionBlockChange = (checkValue: boolean) => {
    setChecked({ ...checked, promotionBlocked: checkValue });
    updateMember({
      variables: {
        ...variables,
        input: {
          promotionBlocked: checkValue,
        },
      },
    }).then(() => {
      message.success('Block Promotion Updated');
      refetch();
    });
  };

  return (
    <div className="mt-3">
      <ContainerHeader>
        <span className="fw-500">Account Restriction</span>
        <MemberDropdown showTable={showContent} setShowTable={setShowContent} />
      </ContainerHeader>
      <ContainerBody
        className="p-3"
        style={{ display: showContent ? 'block' : 'none' }}
      >
        <div className="d-flex justify-content-around">
          <div>
            <p className="mb-0">Block Deposits</p>
            <Switch
              onChange={(e) => onDepositBlockChange(e)}
              checked={depositBlocked || undefined}
              checkedChildren="On"
              unCheckedChildren="Off"
            />
          </div>
          <div>
            <p className="mb-0">Block Withdrawals</p>
            <Switch
              onChange={(e) => onWithdrawalBlockChange(e)}
              checked={withdrawalBlocked || undefined}
              checkedChildren="On"
              unCheckedChildren="Off"
            />
          </div>
          <div>
            <p className="mb-0">Block Wager</p>
            <Switch
              onChange={(e) => onWagerBlockChange(e)}
              checked={wagerBlocked || undefined}
              checkedChildren="On"
              unCheckedChildren="Off"
            />
          </div>
          <div>
            <p className="mb-0">Block Promotion</p>
            <Switch
              onChange={(e) => onPromotionBlockChange(e)}
              checked={promotionBlocked || undefined}
              checkedChildren="On"
              unCheckedChildren="Off"
            />
          </div>
        </div>
      </ContainerBody>
    </div>
  );
};

export default AccountRestriction;
