import React, { createContext, useContext } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Spin } from 'antd';
import { StyledCenter } from 'styles';

const ADMIN = gql`
  query Admin($id: ID!) {
    admin(id: $id) {
      id
      username

      # ===[ Client Details ]=== #
      status
      company
      trial
      vendorGroup {
        id
        name
      }

      # ===[ Client Informations ]=== #
      adminCode
      contractEndDateTime
      contractStartDateTime
      dateTimeCreated
      personInCharge
      mobileNumber
      email
      qqId
      wechatId
      skypeId
      currency
      multiCurrencyEnabled

      # ===[ Whitelist IP Address ] === #
      ipWhitelist

      # ===[ File Attachments ] === #
      attachments {
        id
        uri
      }
    }
  }
`;

const Account360Context = createContext<{ admin: any; refetch: () => void }>({
  admin: {},
  refetch: () => {},
});

export const Account360Provider = ({ children, accountId }: any) => {
  const { loading, error, refetch, data } = useQuery(ADMIN, {
    fetchPolicy: 'network-only',
    variables: {
      id: accountId,
    },
  });

  if (loading || !data?.admin) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  if (error) return <>Error! {error.message}</>;

  return (
    <Account360Context.Provider
      value={{
        admin: data?.admin,
        refetch,
      }}
    >
      {children}
    </Account360Context.Provider>
  );
};

export const useAccount360 = () => useContext(Account360Context);
