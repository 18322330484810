import React from 'react';
import * as Yup from 'yup';
import { omit } from 'lodash';
import messages from 'messages';
import coercedGet from 'utils/coercedGet';
import { useMemberLoyaltyProgrammeContext } from 'pages/components/MemberLoyaltyManagement/context';

export const CreateLoyaltyProgramScreens = {
  PROGRAM_SETTINGS: 'PROGRAM_SETTINGS',
  TIER_SETTINGS: 'TIER_SETTINGS',
  ADD_TIER_FORMS: 'ADD_TIER_FORMS',
};

export const ProgrammeStatus = {
  DRAFT: 'DRAFT',
};

export const allTypes = {
  SET_ACTIVE_SCREEN: 'SET_ACTIVE_SCREEN',
  RESET_PROGRAMME: 'RESET_PROGRAMME',
  SET_CURRENT_PROGRAM_ID: 'SET_CURRENT_PROGRAM_ID',
  SET_MEMBER_LOYALTY_PROGRAMME: 'SET_MEMBER_LOYALTY_PROGRAMME',
  SET_ACTIVE_PROGRAMME: 'SET_ACTIVE_PROGRAMME',
  ADD_TIER: 'ADD_TIER',
  UPDATE_TIER: 'UPDATE_TIER',
  SET_TIERS: 'SET_TIERS',
  SET_ACTIVE_TIER: 'SET_ACTIVE_TIER',
  DELETE_TIER: 'DELETE_TIER',
  ...CreateLoyaltyProgramScreens,
};

export const CreateLoyaltyProgram = React.createContext(undefined);

export const getPayoutsSchema = (translate: any) =>
  Yup.object()
    .shape({
      autoPayout: Yup.boolean().nullable(),
      upgradePayout: Yup.object()
        .shape({
          limit: Yup.number()
            .min(
              0,
              translate(messages.WORD_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO, {
                word: translate(messages.LIMIT),
              })
            )
            .required(
              translate(messages['member-loyalty.limit-is-required.text'])
            ),
          amount: Yup.number()
            .min(
              0,
              translate(messages.WORD_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO, {
                word: translate(messages.AMOUNT),
              })
            )
            .required(
              translate(messages['member-loyalty.amount-is-required.text'])
            ),
          claimExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          claimExpiryDuration: Yup.string().nullable(),
          claimOffsetAmount: Yup.number()
            .min(0)
            .nullable(),
          claimOffsetDuration: Yup.string().nullable(),
          payoutExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          payoutExpiryDuration: Yup.string().nullable(),
          turnover: Yup.number()
            .min(
              0,
              translate(messages.WORD_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO, {
                word: translate(messages['turnover.text']),
              })
            )
            .required(
              translate(messages['member-loyalty.turnover-is-required.text'])
            ),
        })
        .nullable(),
      dailyPayout: Yup.object()
        .shape({
          limit: Yup.number()
            .min(
              0,
              translate(messages.WORD_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO, {
                word: translate(messages.LIMIT),
              })
            )
            .required(
              translate(messages['member-loyalty.limit-is-required.text'])
            ),
          amount: Yup.number()
            .min(
              0,
              translate(messages.WORD_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO, {
                word: `${translate(
                  messages['member-loyalty.text']
                )} ${translate(messages.AMOUNT)}`,
              })
            )
            .required(
              translate(messages['member-loyalty.amount-is-required.text'])
            ),
          claimExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          claimExpiryDuration: Yup.string().nullable(),
          claimOffsetAmount: Yup.number()
            .min(0)
            .nullable(),
          claimOffsetDuration: Yup.string().nullable(),
          payoutExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          payoutExpiryDuration: Yup.string().nullable(),
          turnover: Yup.number()
            .min(
              0,
              translate(messages.WORD_MUST_BE_GREATER_THAN_OR_EQUAL_TO_ZERO, {
                word: `${translate(
                  messages['member-loyalty.text']
                )} ${translate(messages['turnover.text'])}`,
              })
            )
            .required(
              translate(messages['member-loyalty.turnover-is-required.text'])
            ),
          timeOfDay: Yup.string().required(
            translate(messages['member-loyalty.time-of-day-is-required.text'])
          ),
        })
        .nullable(),
      weeklyPayout: Yup.object()
        .shape({
          limit: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.limit-is-required.text'])
            ),
          amount: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.amount-is-required.text'])
            ),
          claimExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          claimExpiryDuration: Yup.string().nullable(),
          claimOffsetAmount: Yup.number()
            .min(0)
            .nullable(),
          claimOffsetDuration: Yup.string().nullable(),
          payoutExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          payoutExpiryDuration: Yup.string().nullable(),
          turnover: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.turnover-is-required.text'])
            ),
          timeOfDay: Yup.string().required(
            translate(messages['member-loyalty.time-of-day-is-required.text'])
          ),
          dayOfWeek: Yup.string().required(
            translate(messages['member-loyalty.day-of-week-is-required.text'])
          ),
        })
        .nullable(),
      monthlyPayout: Yup.object()
        .shape({
          limit: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.limit-is-required.text'])
            ),
          amount: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.amount-is-required.text'])
            ),
          claimExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          claimExpiryDuration: Yup.string().nullable(),
          claimOffsetAmount: Yup.number()
            .min(0)
            .nullable(),
          claimOffsetDuration: Yup.string().nullable(),
          payoutExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          payoutExpiryDuration: Yup.string().nullable(),
          turnover: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.turnover-is-required.text'])
            ),
          // string?
          timeOfDay: Yup.string().required(
            translate(messages['member-loyalty.time-of-day-is-required.text'])
          ),
          dayOfMonth: Yup.string().required(
            translate(messages['member-loyalty.day-of-month-is-required.text'])
          ),
        })
        .nullable(),
      annualPayout: Yup.object()
        .shape({
          limit: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.limit-is-required.text'])
            ),
          amount: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.amount-is-required.text'])
            ),
          claimExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          claimExpiryDuration: Yup.string().nullable(),
          claimOffsetAmount: Yup.number()
            .min(0)
            .nullable(),
          claimOffsetDuration: Yup.string().nullable(),
          payoutExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          payoutExpiryDuration: Yup.string().nullable(),
          turnover: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.turnover-is-required.text'])
            ),
          // string?
          timeOfDay: Yup.string().required(
            translate(messages['member-loyalty.time-of-day-is-required.text'])
          ),
        })
        .nullable(),
      birthdayPayout: Yup.object()
        .shape({
          limit: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.limit-is-required.text'])
            ),
          amount: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.amount-is-required.text'])
            ),
          claimExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          claimExpiryDuration: Yup.string().nullable(),
          claimOffsetAmount: Yup.number()
            .min(0)
            .nullable(),
          claimOffsetDuration: Yup.string().nullable(),
          payoutExpiryAmount: Yup.number()
            .min(0)
            .nullable(),
          payoutExpiryDuration: Yup.string().nullable(),
          turnover: Yup.number()
            .min(0)
            .required(
              translate(messages['member-loyalty.turnover-is-required.text'])
            ),
          // string?
          timeOfDay: Yup.string().required(
            translate(messages['member-loyalty.time-of-day-is-required.text'])
          ),
        })
        .nullable(),
    })
    .nullable();

export const getBenefitsSchema = (translate: any) =>
  Yup.object()
    .shape({
      maximumWithdrawalAmountPerRequest: Yup.number()
        .min(0)
        .nullable(),
      maximumWithdrawalRequest: Yup.number()
        .min(
          0,
          translate(
            messages.MAXIMUM_WITHDRAWAL_REQUEST_MUST_BE_GREATER_THAN_ZERO
          )
        )
        .max(
          999999999,
          translate(messages.MAXIMUM_WITHDRAWAL_REQEUST_EXCEED_ERROR)
        )
        .nullable(),
      withdrawalLimitRequestReset: Yup.string().nullable(),
      withdrawalLimitFee: Yup.number()
        .min(0)
        .nullable(),
      withdrawalLimitFeeType: Yup.string().nullable(),
      bankAccountLimits: Yup.number()
        .min(0)
        .nullable(),
      eWalletLimits: Yup.number()
        .min(0)
        .nullable(),
    })
    .nullable();

export const getNewProgrammeSchemaObject = (
  translate: any,
  isPrimary = false
) => {
  const secondaryVipObj = {
    description: Yup.string().nullable(),
    validityStartDate: Yup.string().required(
      translate(messages['member-loyalty.date-range-required.text'])
    ),
    validityEndDate: Yup.string().required(
      translate(messages['member-loyalty.date-range-required.text'])
    ),
    evaluationPeriod: Yup.string().nullable(),
    badgeSet: Yup.string().nullable(),
    automaticMemberLoyaltyLevelUpgrade: Yup.boolean(),
    automaticMemberLoyaltyLevelDowngrade: Yup.boolean(),
    name: Yup.string().required(
      translate(messages['member-loyalty.name-is-required.text'])
    ),
  };

  const primaryVipObj = {
    name: Yup.string().required(
      translate(messages['member-loyalty.name-is-required.text'])
    ),
    automaticMemberLoyaltyLevelUpgrade: Yup.boolean(),
    description: Yup.string().nullable(),
    badgeSet: Yup.string().nullable(),
  };

  return isPrimary ? primaryVipObj : secondaryVipObj;
};

export const getNewProgrammeSchema = (translate: any, isPrimaryVip: boolean) =>
  Yup.object().shape(getNewProgrammeSchemaObject(translate, isPrimaryVip));

export const getValidationSchema = (translate: any, isPrimaryVip: boolean) => {
  const tiersSchema = Yup.object().shape({
    id: Yup.string().nullable(),
    name: Yup.string()
      .required(
        translate(messages['member-loyalty.tier-name-is-required.text'])
      )
      .max(
        12,
        translate(
          messages['member-loyalty.name-must-be-max-12-characters.text']
        )
      ),
    description: Yup.string().nullable(),
    inviteOnly: Yup.boolean().nullable(),
    qualificationCriteria: Yup.object().nullable(),
    color: Yup.string().nullable(),
    isDefault: Yup.boolean().nullable(),
  });

  const validationSchema = Yup.object().shape({
    ...getNewProgrammeSchemaObject(translate, isPrimaryVip),

    levels: Yup.array()
      .of(tiersSchema)
      .nullable(),
  });

  return validationSchema;
};

export const getTierSettingsSchema = (translate: any) =>
  Yup.object().shape({
    id: Yup.string().nullable(),
    name: Yup.string()
      .required(
        translate(messages['member-loyalty.tier-name-is-required.text'])
      )
      .max(
        12,
        translate(
          messages['member-loyalty.name-must-be-max-12-characters.text']
        )
      ),
    // depositAmount: Yup.number().nullable(),
    depositAmount: Yup.number()
      .min(0)
      .max(999999999999)
      .nullable(),
    turnoverAmount: Yup.number()
      .min(0)
      .max(999999999999)
      .nullable(),
    upgradeAwardAmount: Yup.number()
      .min(0)
      .max(999999999999)
      .nullable(),
    upgradeAwardTurnover: Yup.number()
      .min(0)
      .max(999999999999)
      .nullable(),
    gameTypes: Yup.array().nullable(),
  });

export function CreateLoyaltyProgramProvider({ children }: any) {
  const initialState = {
    activeScreen: allTypes.PROGRAM_SETTINGS,
    currentProgramId: null,
    memberLoyaltyProgramme: null,
    tiers: [],
    activeProgramme: null,
    activeTier: null,
  };

  const reducer = (state: Record<string, any>, action: Record<string, any>) => {
    switch (action.type) {
      case allTypes.SET_ACTIVE_SCREEN:
        return {
          ...state,
          activeScreen: action.payload,
        };

      case allTypes.RESET_PROGRAMME:
        return {
          ...state,
          ...initialState,
        };
      case allTypes.SET_CURRENT_PROGRAM_ID:
        return {
          ...state,
          currentProgramId: action.payload,
        };
      case allTypes.SET_MEMBER_LOYALTY_PROGRAMME:
        return {
          ...state,
          memberLoyaltyProgramme: action.payload,
        };
      case allTypes.SET_ACTIVE_PROGRAMME:
        return {
          ...state,
          activeProgramme: action.payload,
        };
      case allTypes.SET_TIERS:
        return {
          ...state,
          tiers: action.payload,
        };
      case allTypes.ADD_TIER:
        return {
          ...state,
          tiers: [action.payload, ...state.tiers],
        };
      case allTypes.UPDATE_TIER:
        return {
          ...state,
          tiers: state.tiers.map((tier: Record<string, any>) =>
            tier.id === action.payload.id
              ? { ...tier, ...omit(action.payload, ['id']) }
              : tier
          ),
        };
      case allTypes.DELETE_TIER:
        return {
          ...state,
          tiers: state.tiers.filter(
            (tier: Record<string, any>) => tier.id !== action.payload
          ),
        };
      case allTypes.SET_ACTIVE_TIER:
        return {
          ...state,
          activeTier: action.payload,
        };
      default:
        return state;
    }
  };

  return (
    <CreateLoyaltyProgram.Provider
      value={
        [...React.useReducer(reducer, initialState), getValidationSchema] as any
      }
    >
      {children}
    </CreateLoyaltyProgram.Provider>
  );
}

export const useCreateLoyaltyProgramContext = () =>
  React.useContext<any>(CreateLoyaltyProgram);

const PrimaryVip = React.createContext(undefined);

export const PrimaryVipProvider = ({ children }: any) => {
  const [contextState] = useCreateLoyaltyProgramContext() as any;
  const [{ totalProgCount }] = useMemberLoyaltyProgrammeContext();

  const isPrimaryVip =
    coercedGet(contextState, 'activeProgramme.primary', null) ||
    totalProgCount === 0;

  return (
    <PrimaryVip.Provider value={isPrimaryVip}>{children}</PrimaryVip.Provider>
  );
};

export const usePrimaryVipContext = () => React.useContext(PrimaryVip);
