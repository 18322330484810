import { isObject } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const safeParseObject = (data: string | Record<string, any>) => {
  if (!data) {
    return data;
  }

  return isObject(data) ? data : JSON.parse(data);
};
