import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'antd';
import { StyledLabel } from '../../../../styles';

type Props = {
  value: any;
  onChange: (e: any) => any;
  disabled: boolean;
};

const ProfileValidationFilter = ({ value, onChange, disabled }: Props) => {
  const handleChange = (e: any) => {
    let newValue = null;
    if (value && value.in.includes(e)) {
      const data = value.in.filter((item: any) => item !== e);
      if (data.length) {
        newValue = {
          in: value.in.filter((item: any) => item !== e),
        };
      } else {
        newValue = null;
      }
    } else if (value) {
      newValue = {
        in: [...value.in, e],
      };
    } else {
      newValue = {
        in: [e],
      };
    }
    onChange(newValue);
  };

  return (
    <div className="mt-1">
      <div>
        <StyledLabel>
          <FormattedMessage
            id="PROFILE_VALIDATION"
            defaultMessage="Profile Validation"
          />
        </StyledLabel>
      </div>

      <div className="d-flex justify-content-between">
        <Checkbox
          checked={value ? value.in.includes(true) : false}
          onChange={() => handleChange(true)}
          disabled={disabled}
        >
          <FormattedMessage id="ON" defaultMessage="On" />
        </Checkbox>

        <Checkbox
          checked={value ? value.in.includes(false) : false}
          onChange={() => handleChange(false)}
          disabled={disabled}
        >
          <FormattedMessage id="OFF" defaultMessage="Off" />
        </Checkbox>
      </div>
    </div>
  );
};

export default ProfileValidationFilter;
