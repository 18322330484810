import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100% - 40px);
  width: 55px;
  border: 1px solid #e8e8e8;
  border-right: none;
  background: #fff;
  padding: 50px 5px 0 5px;
  text-align: center;
  cursor: pointer;
  pointer-events: all;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTextWatchlist = styled.p`
  writing-mode: tb-rl;
  margin-left: 10px;
  text-orientation: upright;
  margin-top: 10px;
  margin-bottom: 15px;
`;

export default {};
