import { useMutation } from '@apollo/react-hooks';
import { Col, Row, Select } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';
import { ME } from 'graphql/queries/me.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CS from 'components/CustomSwitch';
import { SharedStyledModal } from 'styles/SharedStyledModal';
import { useAccount, usePermissions } from 'store/accountState';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const { Option } = Select;
const CustomSwitch: any = CS;

const BannerSettingsModal = ({
  modalState,
  setModalState,
}: {
  modalState: boolean;
  setModalState: (e: boolean) => void;
}) => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_EDIT } = collectPermissions(
    role,
    permissions,
    ['EDIT'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_ROTATING_BANNER}:BANNER_SETTINGS`
  );

  const onCloseModal = () => setModalState(false);
  const {
    account: { config },
    setConfig,
  } = useAccount() as any;

  const delaysData = [
    {
      label: (
        <FormattedMessage
          id="seconds-number.text"
          defaultMessage="{seconds} seconds"
          values={{ seconds: 3 }}
        />
      ),
      value: 'FAST',
    },
    {
      label: (
        <FormattedMessage
          id="seconds-number.text"
          defaultMessage="{seconds} seconds"
          values={{ seconds: 5 }}
        />
      ),
      value: 'MEDIUM',
    },
    {
      label: (
        <FormattedMessage
          id="seconds-number.text"
          defaultMessage="{seconds} seconds"
          values={{ seconds: 7 }}
        />
      ),
      value: 'SLOW',
    },
  ];
  const [scrolling, setScrolling] = useState(
    config.bannerAutomaticScrollingEnabled
  );
  const { context } = useOperatorHeader();
  const [delayBanner, setDelayBanner] = useState(delaysData[0].value);
  const [updateBannerSettings, { loading: updateConfigLoading }] = useMutation(
    UPDATE_CONFIG,
    {
      refetchQueries: [
        {
          query: ME,
        },
      ],
      context,
    }
  );

  const onSubmit = () => {
    const options: {
      variables: {
        input: Record<string, any>;
      };
    } = {
      variables: {
        input: {
          bannerAutomaticScrollingEnabled: scrolling,
        },
      },
    };

    if (scrolling) {
      options.variables.input.bannerScrollingSpeed = delayBanner;
    }

    updateBannerSettings(options)
      .then(() => {
        setConfig({
          ...config,
          ...options.variables.input,
        });
        setModalState(false);
      })
      .catch();
  };

  useEffect(() => {
    const {
      bannerAutomaticScrollingEnabled = true,
      bannerScrollingSpeed = 'FAST',
    } = config;
    setScrolling(bannerAutomaticScrollingEnabled);
    setDelayBanner(bannerScrollingSpeed);
  }, [config]);

  return (
    <SharedStyledModal
      title={
        <FormattedMessage
          id="banner-settings.text"
          defaultMessage="Banner Settings"
        />
      }
      visible={modalState}
      onCancel={onCloseModal}
      okText={<FormattedMessage id="continue.text" defaultMessage="Continue" />}
      okButtonProps={{
        hidden: !ALLOWED_EDIT,
        loading: updateConfigLoading,
      }}
      cancelButtonProps={{
        hidden: !ALLOWED_EDIT,
      }}
      onOk={onSubmit}
    >
      <div className="p-3">
        <Row className="d-flex align-items-center">
          <Col span={6}>
            <span className="mr-1">
              <FormattedMessage
                id="automatic-scrolling.text"
                defaultMessage="Automatic Scrolling"
              />
            </span>
          </Col>
          <Col span={18}>
            <CustomSwitch
              value={scrolling}
              onChange={(e: boolean) => setScrolling(e)}
              textHelper={{
                true: <FormattedMessage id="on.text" defaultMessage="On" />,
                false: <FormattedMessage id="off.text" defaultMessage="Off" />,
              }}
            />
          </Col>
        </Row>

        {scrolling && (
          <Row className="d-flex align-items-center mt-3">
            <Col span={6}>
              <span className="mr-1">
                <FormattedMessage
                  id="delay-between-banners.text"
                  defaultMessage="Delay Between Banners"
                />
              </span>
            </Col>
            <Col span={18}>
              <Select
                style={{ width: 200 }}
                value={delayBanner}
                onChange={(e) => setDelayBanner(e)}
              >
                {delaysData.map((delay) => (
                  <Option value={delay.value}>{delay.label}</Option>
                ))}
              </Select>
            </Col>
          </Row>
        )}
      </div>
    </SharedStyledModal>
  );
};

export default BannerSettingsModal;
