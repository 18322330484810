import * as React from 'react';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { buildMessageType } from '../../CreateNewMail/util';

const MEMBER_LEVELS = gql`
  query MemberLevel($id: ID!) {
    memberLevel(id: $id) {
      id
      name
    }
  }
`;

export const MemberLevelsTags = ({ id, type, onClose }: IMemberLevelsTags) => {
  const { data, loading } = useQuery(MEMBER_LEVELS, {
    variables: { id },
  });

  if (loading) {
    return (
      <Tag>
        <LoadingOutlined />
      </Tag>
    );
  }

  const { memberLevel } = data;

  return (
    <Tag closable onClose={() => onClose(memberLevel)}>
      <strong>
        {type === buildMessageType.target && (
          <FormattedMessage
            id="target-members.text"
            defaultMessage="Target Members"
          />
        )}
        {type === buildMessageType.excluded && (
          <FormattedMessage
            id="excluded-members.text"
            defaultMessage="Excluded Members"
          />
        )}
      </strong>
      : {memberLevel.name}
    </Tag>
  );
};

interface IMemberLevelsTags {
  id: string;
  type: 'target' | 'excluded' | string;
  onClose: (memberLevel: any) => void;
}
