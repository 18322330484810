import React, { FC, ReactNode } from 'react';

type Props = {
  withPermission?: boolean;
  message: string;
  action?: string;
  children?: ReactNode;
};

const PermissionError: FC<Props> = ({
  message,
  action,
  withPermission,
  children,
}) => (
  <>
    {withPermission ? (
      children
    ) : (
      <div className="w-100 p-1 d-flex justify-content-center align-items-center bg-light">
        <p className="bg-light m-0 p-2">{`Need Permission in ${message} ${
          action ? `(${action})` : ''
        }`}</p>
      </div>
    )}
  </>
);

export default PermissionError;
