import gql from 'graphql-tag';

export const GET_MAHR_STRING_BASED = gql`
  query MAHR_IDS(
    $filter: MemberAccessHistoryReportItemsFilterInput
    $partialFilter: MemberAccessHistoryReportItemsFilterInput
  ) {
    memberAccessHistoryReport(filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: memberAccessHistoryReport(filter: $partialFilter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_MAHR = gql`
  query MemberAccessHistoryReport(
    $first: Int
    $after: Binary
    $filter: MemberAccessHistoryReportItemsFilterInput
    $language: Language
  ) {
    memberAccessHistoryReport(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          member {
            id
            username
            realName
            totalEffectiveBet
            brandId
            platformId
            affiliate {
              id
              code
            }
            totalBalance
            lastLoginDateTime
            memberLoyaltyLevels {
              id
              name
              color
              programme {
                id
                name
              }
            }
          }
          ipAddress
          ipAddressLocation {
            city(language: $language)
            country(language: $language)
            state(language: $language)
          }
          memberLevel {
            id
            name
            status
            default
            color
          }
          session {
            id
            domain
            userAgent
            deviceOs
            deviceId
            deviceFingerprint
            browser
            channel
            dateTimeDestroyed
            expirationDateTime
            dateTimeCreated
          }
          registration
          dateTimeCreated
        }
      }
    }
  }
`;

export const GET_MAHR_ID_BASED = gql`
  query MAHR_ID_BASED(
    $username: MembersFilterInput
    $realName: MembersFilterInput
  ) {
    username: members(filter: $username) {
      edges {
        node {
          id
          username
        }
      }
    }
    realName: members(filter: $realName) {
      edges {
        node {
          id
          realName
        }
      }
    }
  }
`;

export const GET_MAHR_USERNAMES = gql`
  query FilterMAHRUsernames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export const GET_MAHR_REALNAMES = gql`
  query FilterMAHRUsernames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          realName
        }
      }
    }
  }
`;

export const GET_MAHR_IP_ADDRESSES = gql`
  query FilterMAHRIpAdresses(
    $first: Int
    $after: Binary
    $filter: MemberAccessHistoryReportItemsFilterInput
  ) {
    memberAccessHistoryReport(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          ipAddress
        }
      }
    }
  }
`;
