import { useMutation } from '@apollo/react-hooks';
import { Button, message, Popconfirm } from 'antd';
import { REJECT_WITHDRAWAL } from 'graphql/mutations/withdrawal.mutation';
import messages from 'pages/components/MemberWithdrawals/messages';
import React from 'react';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';

type Props = {
  id: string;
  status: string;
  refetchQueries: any;
  remarks: string;
  setRemarksError: (data?: any) => void;
  toggleModal: () => void;
};

const RejectWithdrawal = (props: Props) => {
  const {
    id,
    status,
    refetchQueries,
    remarks,
    setRemarksError,
    toggleModal,
  } = props;
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [rejectWithdrawal, { loading }] = useMutation(REJECT_WITHDRAWAL, {
    onCompleted: (data) => {
      if (data.rejectWithdrawal) {
        message.success(translate(messages.withdrawalRejected));
      }
      setRemarksError();
      toggleModal();
    },
    onError: () => {
      setRemarksError();
    },
    variables: { id },
    refetchQueries,
    context,
  });

  return (
    <>
      {(status === 'PROCESSING' ||
        status === 'EXPIRED' ||
        status === 'ON_HOLD') && (
        <Popconfirm
          title={translate(messages.areYouSure)}
          onConfirm={() => {
            if (remarks) rejectWithdrawal();
            else setRemarksError(translate(messages.requiredRejectRemarkError));
          }}
          onCancel={() => {}}
          okText={translate(messages.yes)}
          cancelText={translate(messages.no)}
        >
          <Button
            disabled={loading}
            loading={loading}
            style={{
              background: '#FF4d4f',
              color: 'white',
            }}
          >
            {translate(messages.reject)}
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export default RejectWithdrawal;
