import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import messages from '../messages';
import { StyledRootDiv } from './styles';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  width: 300px;
`;

const permissionsLabelHint = (hint: string) => {
  switch (hint) {
    case 'SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG':
    case 'SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG':
      return 'CATEGORY';

    case 'SYSTEM_MANAGEMENT_SITE_CONFIG':
      return 'SUBDOMAIN';

    default:
      return 'CATEGORY';
  }
};

const SystemManagementTree = () => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const [checked, setChecked] = useState({
    all: {
      first: false,
      SYSTEM_MANAGEMENT_SITE_CONFIG: false,
      SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS: false,
      SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG: false,
      SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG: false,
      SYSTEM_MANAGEMENT_MARQUEE_MESSAGES: false,
      SYSTEM_MANAGEMENT_ROTATING_BANNER: false,
      SYSTEM_MANAGEMENT_GEO_FENCING: false,
      SYSTEM_MANAGEMENT_COMMON_CONFIG: false,
      SYSTEM_MANAGEMENT_SYSTEM_CONFIG: false,

      SYSTEM_MANAGEMENT_SITE_CONFIG_LIST: false,
      SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_LIST: false,
      SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_LIST: false,
      SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_LIST: false,
      SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_LIST: false,
      SYSTEM_MANAGEMENT_ROTATING_BANNER_LIST: false,
    },
    indeterminate: {
      first: false,
      SYSTEM_MANAGEMENT_SITE_CONFIG: false,
      SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS: false,
      SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG: false,
      SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG: false,
      SYSTEM_MANAGEMENT_MARQUEE_MESSAGES: false,
      SYSTEM_MANAGEMENT_ROTATING_BANNER: false,
      SYSTEM_MANAGEMENT_GEO_FENCING: false,
      SYSTEM_MANAGEMENT_COMMON_CONFIG: false,
      SYSTEM_MANAGEMENT_SYSTEM_CONFIG: false,
    },
    currentList: {
      SYSTEM_MANAGEMENT_SITE_CONFIG_GRP: [],
      SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_GRP: [],
      SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_GRP: [],
      SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_GRP: [],
      SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_GRP: [],
      SYSTEM_MANAGEMENT_ROTATING_BANNER_GRP: [],
      SYSTEM_MANAGEMENT_GEO_FENCING_GRP: [],
      SYSTEM_MANAGEMENT_COMMON_CONFIG_GRP: [],
      SYSTEM_MANAGEMENT_SYSTEM_CONFIG_GRP: [],
    },
  });

  const hintPermissions = [
    'MOBILE_APP_CONFIG',
    'SITE_CONFIG',
    'DESKTOP_APP_CONFIG',
  ];

  const defaultValues = Object.values(ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT);

  const levels = [
    ['first', 0],
    ['second', 1],
    ['third', 2],
  ];
  const includableKeys = [
    'SUBDOMAIN',
    'CATEGORY',
    'BANNER_SETTINGS',
    'FORCE_VERIFICATION',
  ];

  const options: Record<string, any> = levels.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr[0]}`]: Object.entries(ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT)
        .filter((e) => {
          if (curr[0] === 'third') {
            return (
              (e[1].match(/:/g) || []).length === curr[1] ||
              includableKeys.some((i) => e[1].includes(i))
            );
          }

          return (e[1].match(/:/g) || []).length === curr[1];
        })
        .map((e) => e[0]),
    }),
    {}
  );

  const handleFirstLevelChange = (e: any) => {
    const list = e.target.checked ? defaultValues : [];
    setFieldValue('permissions[ALL_SYSTEM_MANAGEMENT]', list.filter(Boolean));
  };

  const handleSecondLevelChange = (e: any) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_SYSTEM_MANAGEMENT];
    const secondLevelValues = defaultValues.filter(
      (v) =>
        (v.match(/:/g) || []).length >= 1 &&
        v.includes(ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT[name])
    );

    let list = [];

    if (e.target.checked) {
      list = [
        ...targetPermissions,
        ...secondLevelValues,
        ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT,
      ];
    } else {
      list = targetPermissions.filter((p) => !secondLevelValues.includes(p));
    }
    setFieldValue(
      'permissions[ALL_SYSTEM_MANAGEMENT]',
      uniq(list.length > 1 ? ['SYSTEM_MANAGEMENT', ...list] : []).filter(
        Boolean
      )
    );
  };

  const handleThirdLevelChange = (e: any) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_SYSTEM_MANAGEMENT];

    const unlistedName =
      ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT[name.replace('_LIST', '')];

    const thirdLevelValues = defaultValues.filter(
      (v) => (v.match(/:/g) || []).length >= 2 && v.includes(unlistedName)
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, unlistedName, ...thirdLevelValues];
    } else {
      list = targetPermissions.filter(
        (p) => !thirdLevelValues.includes(p) && p !== unlistedName
      );
    }

    setFieldValue(
      'permissions[ALL_SYSTEM_MANAGEMENT]',
      uniq(list.length >= 3 ? ['SYSTEM_MANAGEMENT', ...list] : []).filter(
        Boolean
      )
    );
  };

  const handleOnChange = (
    list: any[],
    name: string,
    isV2 = false,
    hint = ''
  ) => {
    const stateName = name.replace('_GRP', '');

    const secondLevelPermissionLookup =
      ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT[stateName];

    let thirdLevelPermissionLookup = '';

    if (isV2) {
      thirdLevelPermissionLookup =
        ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT[`${stateName}_${hint}_LIST`];
    } else {
      thirdLevelPermissionLookup =
        ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT[`${stateName}_LIST`];
    }

    const thirdLevelPermissions = Object.values(
      ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
    ).filter((p) => (p.match(/:/g) || []).length >= 2);

    const targetPermissions = [...values.permissions.ALL_SYSTEM_MANAGEMENT];

    const templatePermissions = thirdLevelPermissions.filter(
      (p) =>
        p.includes(secondLevelPermissionLookup) &&
        p !== secondLevelPermissionLookup &&
        p !== thirdLevelPermissionLookup
    );

    setFieldValue(
      'permissions[ALL_SYSTEM_MANAGEMENT]',
      uniq([
        ...targetPermissions.filter((p) => !templatePermissions.includes(p)),
        'SYSTEM_MANAGEMENT',
        secondLevelPermissionLookup,
        thirdLevelPermissionLookup,
        ...list,
      ]).filter(Boolean)
    );
  };

  const availablePermissions = coercedGet(
    values,
    'permissions.ALL_SYSTEM_MANAGEMENT',
    []
  );

  useEffect(() => {
    const permissionMatch =
      availablePermissions.length ===
      Object.values(ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT).length;

    const thirdLevelPermissions = availablePermissions.filter(
      (p: any) => (p.match(/:/g) || []).length >= 2
    );

    const secondLevelKeys = [
      [
        'SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS',
        'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS',
        3,
      ],
      [
        'SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG',
        'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG',
        6,
      ],
      [
        'SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG',
        'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG',
        7,
      ],
      [
        'SYSTEM_MANAGEMENT_MARQUEE_MESSAGES',
        'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES',
        6,
      ],
      [
        'SYSTEM_MANAGEMENT_ROTATING_BANNER',
        'SYSTEM_MANAGEMENT:ROTATING_BANNER',
        9,
      ],
      ['SYSTEM_MANAGEMENT_GEO_FENCING', 'SYSTEM_MANAGEMENT:GEO_FENCING', 2],
      [
        'SYSTEM_MANAGEMENT_COMMON_CONFIG',
        'SYSTEM_MANAGEMENT:COMMON_CONFIG',
        18,
      ],
      ['SYSTEM_MANAGEMENT_SYSTEM_CONFIG', 'SYSTEM_MANAGEMENT:SYSTEM_CONFIG', 2],
    ];

    const thirdLevelChecks: Record<string, any> = secondLevelKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr[0]}`]:
          thirdLevelPermissions.filter((p: any) => p.includes(`${curr[1]}`))
            .length === curr[2],
      }),
      {}
    );

    const allChecks = {
      SYSTEM_MANAGEMENT_SITE_CONFIG:
        permissionMatch ||
        availablePermissions.includes(
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_SITE_CONFIG
        ),
      SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS:
        permissionMatch ||
        thirdLevelChecks.SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS,
      SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG:
        permissionMatch || thirdLevelChecks.SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG,
      SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG:
        permissionMatch ||
        thirdLevelChecks.SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG,
      SYSTEM_MANAGEMENT_MARQUEE_MESSAGES:
        permissionMatch || thirdLevelChecks.SYSTEM_MANAGEMENT_MARQUEE_MESSAGES,
      SYSTEM_MANAGEMENT_ROTATING_BANNER:
        permissionMatch || thirdLevelChecks.SYSTEM_MANAGEMENT_ROTATING_BANNER,

      SYSTEM_MANAGEMENT_GEO_FENCING:
        permissionMatch || thirdLevelChecks.SYSTEM_MANAGEMENT_GEO_FENCING,
      SYSTEM_MANAGEMENT_COMMON_CONFIG:
        permissionMatch || thirdLevelChecks.SYSTEM_MANAGEMENT_COMMON_CONFIG,
      SYSTEM_MANAGEMENT_SYSTEM_CONFIG:
        permissionMatch || thirdLevelChecks.SYSTEM_MANAGEMENT_SYSTEM_CONFIG,
    };

    setChecked({
      all: {
        first: permissionMatch,
        SYSTEM_MANAGEMENT_SITE_CONFIG: allChecks.SYSTEM_MANAGEMENT_SITE_CONFIG,
        SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS:
          allChecks.SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS,
        SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG:
          allChecks.SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG,
        SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG:
          allChecks.SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG,
        SYSTEM_MANAGEMENT_MARQUEE_MESSAGES:
          allChecks.SYSTEM_MANAGEMENT_MARQUEE_MESSAGES,
        SYSTEM_MANAGEMENT_ROTATING_BANNER:
          allChecks.SYSTEM_MANAGEMENT_ROTATING_BANNER,

        SYSTEM_MANAGEMENT_GEO_FENCING: allChecks.SYSTEM_MANAGEMENT_GEO_FENCING,
        SYSTEM_MANAGEMENT_SYSTEM_CONFIG:
          allChecks.SYSTEM_MANAGEMENT_SYSTEM_CONFIG,
        SYSTEM_MANAGEMENT_COMMON_CONFIG:
          allChecks.SYSTEM_MANAGEMENT_COMMON_CONFIG,

        SYSTEM_MANAGEMENT_SITE_CONFIG_LIST:
          thirdLevelPermissions.includes(
            'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:LIST'
          ) || allChecks.SYSTEM_MANAGEMENT_SITE_CONFIG,
        SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_LIST:
          thirdLevelPermissions.includes(
            'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS:LIST'
          ) || allChecks.SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS,
        SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_LIST:
          thirdLevelPermissions.includes(
            'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG:CATEGORY:LIST'
          ) || allChecks.SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG,

        SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_LIST:
          thirdLevelPermissions.includes(
            'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG:CATEGORY:LIST'
          ) || allChecks.SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG,

        SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_LIST:
          thirdLevelPermissions.includes(
            'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES:LIST'
          ) || allChecks.SYSTEM_MANAGEMENT_MARQUEE_MESSAGES,
        SYSTEM_MANAGEMENT_ROTATING_BANNER_LIST:
          thirdLevelPermissions.includes(
            'SYSTEM_MANAGEMENT:ROTATING_BANNER:LIST'
          ) || allChecks.SYSTEM_MANAGEMENT_ROTATING_BANNER,
      },
      indeterminate: {
        first: availablePermissions.length && !permissionMatch,
        SYSTEM_MANAGEMENT_SITE_CONFIG: false,
        SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS')
          ).length &&
          !thirdLevelChecks.SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS,
        SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG')
          ).length && !thirdLevelChecks.SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG,

        SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG')
          ).length && !thirdLevelChecks.SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG,

        SYSTEM_MANAGEMENT_MARQUEE_MESSAGES:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('SYSTEM_MANAGEMENT:MARQUEE_MESSAGES')
          ).length && !thirdLevelChecks.SYSTEM_MANAGEMENT_MARQUEE_MESSAGES,
        SYSTEM_MANAGEMENT_ROTATING_BANNER:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('SYSTEM_MANAGEMENT:ROTATING_BANNER')
          ).length && !thirdLevelChecks.SYSTEM_MANAGEMENT_ROTATING_BANNER,
        SYSTEM_MANAGEMENT_GEO_FENCING:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('SYSTEM_MANAGEMENT:GEO_FENCING')
          ).length && !thirdLevelChecks.SYSTEM_MANAGEMENT_GEO_FENCING,

        SYSTEM_MANAGEMENT_SYSTEM_CONFIG:
          thirdLevelPermissions.filter((p: string) =>
            p.includes('SYSTEM_MANAGEMENT:SYSTEM_CONFIG')
          ).length && !thirdLevelChecks.SYSTEM_MANAGEMENT_SYSTEM_CONFIG,

        SYSTEM_MANAGEMENT_COMMON_CONFIG:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('SYSTEM_MANAGEMENT:COMMON_CONFIG')
          ).length && !thirdLevelChecks.SYSTEM_MANAGEMENT_COMMON_CONFIG,
      },
      currentList: {
        SYSTEM_MANAGEMENT_SITE_CONFIG_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('SYSTEM_MANAGEMENT:SITE_CONFIG') &&
            p !== 'SYSTEM_MANAGEMENT:SITE_CONFIG' &&
            p !== 'SYSTEM_MANAGEMENT:SITE_CONFIG:SUBDOMAIN:LIST'
        ),

        SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS') &&
            p !== 'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS' &&
            p !== 'SYSTEM_MANAGEMENT:ALERTS_AND_NOTIFICATIONS:LIST'
        ),
        SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG') &&
            p !== 'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG' &&
            p !== 'SYSTEM_MANAGEMENT:MOBILE_APP_CONFIG:CATEGORY:LIST'
        ),
        SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG') &&
            p !== 'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG' &&
            p !== 'SYSTEM_MANAGEMENT:DESKTOP_APP_CONFIG:CATEGORY:LIST'
        ),
        SYSTEM_MANAGEMENT_MARQUEE_MESSAGES_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('SYSTEM_MANAGEMENT:MARQUEE_MESSAGES') &&
            p !== 'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES' &&
            p !== 'SYSTEM_MANAGEMENT:MARQUEE_MESSAGES:LIST'
        ),
        SYSTEM_MANAGEMENT_ROTATING_BANNER_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('SYSTEM_MANAGEMENT:ROTATING_BANNER') &&
            p !== 'SYSTEM_MANAGEMENT:ROTATING_BANNER' &&
            p !== 'SYSTEM_MANAGEMENT:ROTATING_BANNER:LIST'
        ),

        SYSTEM_MANAGEMENT_GEO_FENCING_GRP: thirdLevelPermissions.filter(
          (p: string) => p.includes('SYSTEM_MANAGEMENT:GEO_FENCING')
        ),

        SYSTEM_MANAGEMENT_SYSTEM_CONFIG_GRP: thirdLevelPermissions.filter(
          (p: string) => p.includes('SYSTEM_MANAGEMENT:SYSTEM_CONFIG')
        ),

        SYSTEM_MANAGEMENT_COMMON_CONFIG_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('SYSTEM_MANAGEMENT:COMMON_CONFIG') &&
            p !== 'SYSTEM_MANAGEMENT:COMMON_CONFIG'
        ),
      },
    });
  }, [availablePermissions]);

  const translate = useTranslate();

  const noViewCheckPermissionHint = [
    'SYSTEM_MANAGEMENT_GEO_FENCING',
    'SYSTEM_MANAGEMENT_COMMON_CONFIG',
    'SYSTEM_MANAGEMENT_SYSTEM_CONFIG',
  ];

  useEffect(() => {
    const allSysMgmtPermissions =
      values.permissions.ALL_SYSTEM_MANAGEMENT.length;

    if (allSysMgmtPermissions && allSysMgmtPermissions <= 2) {
      setFieldValue('permissions[ALL_SYSTEM_MANAGEMENT]', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePermissions]);

  return (
    <StyledRootDiv>
      <div
        style={{ width: 300 * (options.second.length + 1), paddingBottom: 100 }}
      >
        <Row>
          <Col span={24}>
            <Checkbox
              checked={checked.all.first}
              indeterminate={checked.indeterminate.first}
              onChange={handleFirstLevelChange}
            >
              <StyledText size={16}>
                {formatMessage(
                  messages[
                    ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT
                  ]
                )}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <div className="w-100">
          <div className="mt-4 d-flex ml-3">
            {options.second.map((s: any) => (
              <StyledDiv>
                <Checkbox
                  key={s}
                  name={s}
                  checked={checked.all[s]}
                  indeterminate={checked.indeterminate[s]}
                  onChange={handleSecondLevelChange}
                >
                  <StyledText size={14}>
                    {formatMessage(messages[s])}
                  </StyledText>
                </Checkbox>
                <div className="ml-4 mt-2">
                  {hintPermissions.find((x) => s.includes(x)) ? (
                    <div className="mt-2">
                      <div className="mb-2">
                        <Checkbox
                          key={s}
                          name={s}
                          checked={checked.all[s]}
                          indeterminate={checked.indeterminate[s]}
                          onChange={handleSecondLevelChange}
                        >
                          <StyledText size={14}>
                            {translate(messages[permissionsLabelHint(s)])}
                          </StyledText>
                        </Checkbox>
                        <div className="ml-4 mt-2">
                          <Checkbox
                            key={`${s}_LIST`}
                            name={`${s}_LIST`}
                            checked={checked.all[`${s}_LIST`]}
                            onChange={handleThirdLevelChange}
                          >
                            <StyledText size={14}>
                              {translate(messages.LIST)}
                            </StyledText>
                          </Checkbox>

                          <Checkbox.Group
                            className="w-100 ml-2"
                            onChange={(v) =>
                              handleOnChange(
                                v,
                                `${s}_GRP`,
                                true,

                                permissionsLabelHint(s)
                              )
                            }
                            value={checked.currentList[`${s}_GRP`]}
                          >
                            <div className="ml-4">
                              {options.third
                                .filter(
                                  (t: any, idx: number) =>
                                    t.includes(
                                      `${s}_${permissionsLabelHint(s)}`
                                    ) && idx !== 1
                                )
                                .map(
                                  (t: any) =>
                                    !t.includes('LIST') && (
                                      <StyledDiv className="mt-2">
                                        <Checkbox
                                          key={
                                            ALL_PERMISSIONS
                                              .ALL_SYSTEM_MANAGEMENT[t]
                                          }
                                          value={
                                            ALL_PERMISSIONS
                                              .ALL_SYSTEM_MANAGEMENT[t]
                                          }
                                        >
                                          <StyledText size={14}>
                                            {translate(
                                              messages[
                                                t.replace(
                                                  `${s}_${permissionsLabelHint(
                                                    s
                                                  )}_`,
                                                  ''
                                                )
                                              ]
                                            )}
                                          </StyledText>
                                        </Checkbox>
                                      </StyledDiv>
                                    )
                                )}
                            </div>
                          </Checkbox.Group>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-2">
                      {!noViewCheckPermissionHint.some((q) =>
                        s.includes(q)
                      ) && (
                        <Checkbox
                          key={`${s}_LIST`}
                          name={`${s}_LIST`}
                          checked={
                            checked.all[`${s}_LIST`] || checked.all[`${s}_VIEW`]
                          }
                          onChange={handleThirdLevelChange}
                        >
                          <StyledText size={14}>
                            {translate(messages.LIST)}
                          </StyledText>
                        </Checkbox>
                      )}

                      <Checkbox.Group
                        className="w-100 ml-2"
                        onChange={(v) => handleOnChange(v, `${s}_GRP`)}
                        value={checked.currentList[`${s}_GRP`]}
                      >
                        <div className="ml-4">
                          {options.third
                            .filter(
                              (t: any, idx: number) =>
                                t.includes(s) &&
                                idx !== 0 &&
                                idx !== 3 &&
                                idx !== 9 &&
                                idx !== 15 &&
                                idx !== 26
                            )
                            .map(
                              (t: any) =>
                                !t.includes('LIST') && (
                                  <StyledDiv className="mt-2">
                                    <Checkbox
                                      key={
                                        ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT[t]
                                      }
                                      value={
                                        ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT[t]
                                      }
                                    >
                                      <StyledText size={14}>
                                        {translate(
                                          messages[t.replace(`${s}_`, '')]
                                        )}
                                      </StyledText>
                                    </Checkbox>
                                  </StyledDiv>
                                )
                            )}
                        </div>
                      </Checkbox.Group>
                    </div>
                  )}
                </div>
              </StyledDiv>
            ))}
          </div>
        </div>
      </div>
    </StyledRootDiv>
  );
};

export default SystemManagementTree;
