import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Input } from 'antd';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import messages from '../../messages';
import { useCreateAffiliateProgrammeState } from '../../../../../../../../context';

function GGRChargeInput({ name }: { name: string }) {
  const propName = name.replace(/^\w/, (c) => c.toUpperCase());

  const [{ activeVendor }] = useCreateAffiliateProgrammeState();
  const { values, handleChange } = useFormikContext();
  const { currency } = useConfig();

  const { id, gameType } = activeVendor;
  const inputName = `vendorHandlers[${gameType}_${id}][${name}Charge]`;
  const value = get(values, inputName, null);

  const disabled = get(
    values,
    `vendorHandlers[${gameType}_${id}][no${propName}Charge]`,
    false
  );
  const translate = useTranslate();
  return (
    <div>
      <FloatLeft>
        <p>
          {translate(messages[`${name}.text`])}
          <br />
          <FormattedMessage id="ggr-charge.text" defaultMessage="GGR Charge" />
        </p>
      </FloatLeft>
      <FloatLeft>
        <Input
          size="large"
          name={inputName}
          onChange={handleChange}
          prefix={currency?.symbol}
          type="number"
          value={value}
          disabled={disabled}
        />
      </FloatLeft>
      <div className="clearfix" />
    </div>
  );
}

const FloatLeft = styled.div`
  float: left;
  width: 50%;
`;

export default GGRChargeInput;
