import {
  MEMBER_SUMMARY_REPORT,
  MEMBER_SUMMARY_REPORT_CSV,
} from 'graphql/queries/memberSummaryReport.query';
import messages from 'messages';
import { TabId } from 'types/nav';

export const SCREEN_TAB_ID = 'member-summary-report';
export const TABLE_QUERY_NAME = 'memberSummaryReports';
export const TABLE_QUERY = MEMBER_SUMMARY_REPORT;
export const CSV_QUERY = MEMBER_SUMMARY_REPORT_CSV;
export const CSV_FILE_NAME = messages.MEMBER_SUMMARY_REPORT;
export const QUICK_SEARCH_ID: TabId = 'member-summary-report';
