import { useQuery } from '@apollo/react-hooks';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Descriptions,
  Layout,
  Row,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { DATE_FORMAT } from 'constants/date';
import ALL_PERMISSIONS from 'constants/permissions3';
import { REBATE_GROUP } from 'graphql/queries/rebateGroup.query';
import { useConfig } from 'hooks/useConfig';
import { DynamicObj } from 'interfaces/user.interface';
import messages from 'messages';
import moment from 'moment';
import ms from 'ms';
import numeral from 'numeral';
import { darken } from 'polished';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePermissions } from 'store/accountState';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { collectPermissions } from '../../../PermissionGroup/utils';
import {
  RebateGroupStatus,
  RebateGroupTypes,
  useRebateGroupState,
} from '../../context';
import { CreateRebateGroupTypes } from '../CreateRebateGroup/constants';
import GroupSettings from './components/GroupSettings';
import { StyledCenter } from './styles';

type StyledProps = {
  withBorder?: boolean;
  color: string;
};

const StyledTag = styled(Tag)`
  &.ant-tag {
    color: ${(props: StyledProps) =>
      props.withBorder ? '#000' : ''} !important;

    border-radius: 11px;
    border-color: ${(props: StyledProps) =>
      props.withBorder ? darken(0.2, props.color) : ''} !important;
  }
`;

const getPercentageValueFromGames = ({ percentage, games }: any) => {
  const defaultValue = percentage * 100;

  if (!games?.length) {
    return +Number(defaultValue).toFixed(2);
  }

  const appliedToAllGames = games
    .map(({ percentage: pctg }: any) => pctg)
    .every((p: any) => p === defaultValue);

  return appliedToAllGames ? +Number(defaultValue).toFixed(2) : '-';
};

function Summary(props: { showDrawer: () => void }) {
  const { role, permissions } = usePermissions();
  const { ALLOWED_EDIT } = collectPermissions(
    role,
    permissions,
    ['EDIT'],
    ALL_PERMISSIONS.ALL_REBATES.REBATES_REBATES_REBATE_GROUP
  );
  const [rebateGroupState, rebateGroupDispatch] = useRebateGroupState() as any;
  const translate = useTranslate();
  const { currency } = useConfig();
  // if (!rebateGroupState.activeRebateGroup) {
  //   return null;
  // }

  const { loading, data } = useQuery(REBATE_GROUP, {
    variables: {
      id: rebateGroupState.activeRebateGroup,
    },
    skip: !rebateGroupState.activeRebateGroup,
  });

  const rebateGroup = coercedGet(data, 'rebateGroup', {});

  const [activeLevel, setActiveLevel] = useState(0);

  if (loading) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  const {
    type,
    qualifyingMemberLoyaltyLevels,
    excludedMemberLevels,
    validityDateTimeRange,
    automaticPayout,
    turnoverRequirementMultiplier,
    claimOffsetDuration,
    claimExpiryDuration,
    // payoutExpiryDuration,
    minimumPayout,
    maximumPayout,
    payoutSettlementTime,
    payoutSettlementDayOfWeek,
    payoutSettlementDayOfMonth,
    payoutSettlementMonth,
    payoutSettlementPeriod,
  } = rebateGroup;

  const SETTLEMENT_METHOD_TYPES = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    ANNUALLY: 'Annually',
    YEARLY: 'Yearly',
  };

  const getPayoutDetails = (settlementMethod: string) => {
    let time;

    if (payoutSettlementTime) {
      const dateNow = new Date();
      const dateNowString = dateNow
        .toISOString()
        .split('T')
        .shift();
      time = moment(`${dateNowString} ${payoutSettlementTime}`).format('HH:mm');
    }

    switch (settlementMethod) {
      case SETTLEMENT_METHOD_TYPES.DAILY: {
        return { time };
      }
      case SETTLEMENT_METHOD_TYPES.WEEKLY: {
        const days = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ];
        return { time, day: days[payoutSettlementDayOfWeek] };
      }
      case SETTLEMENT_METHOD_TYPES.MONTHLY: {
        const monthlyDate =
          payoutSettlementDayOfMonth !== null
            ? `${payoutSettlementDayOfMonth}${['st', 'nd', 'rd'][
                ((((payoutSettlementDayOfMonth + 90) % 100) - 10) % 10) - 1
              ] || 'th'}`
            : '';
        return { time, date: monthlyDate };
      }
      case SETTLEMENT_METHOD_TYPES.YEARLY: {
        const yearlyDay =
          payoutSettlementDayOfMonth !== null
            ? `${payoutSettlementDayOfMonth}${['st', 'nd', 'rd'][
                ((((payoutSettlementDayOfMonth + 90) % 100) - 10) % 10) - 1
              ] || 'th'}`
            : '';
        const months = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        return {
          time,
          dateAndMonth: `${yearlyDay} of ${months[payoutSettlementMonth - 1]}`,
        };
      }
      default:
        return false;
    }
  };

  const settlementMethod = payoutSettlementPeriod
    ? payoutSettlementPeriod[0].toUpperCase() +
      payoutSettlementPeriod.toLowerCase().slice(1)
    : '';

  const payout: any = getPayoutDetails(settlementMethod);

  const formatMSString = (value: string) => {
    const period = value.replace(/[0-9]+/g, '');
    const monthable = period === 'd' && !(+value.replace(period, '') % 30);
    const yearable = period === 'd' && !(+value.replace(period, '') % 365);
    if (monthable) {
      const months = +value.replace(period, '') / 30;
      return `${months} month${months > 1 ? 's' : ''}`;
    }
    if (yearable) {
      const years = +value.replace(period, '') / 365;
      return `${years} year${years > 1 ? 's' : ''}`;
    }
    return ms(ms(value), { long: true });
  };

  const levels =
    rebateGroup.type === CreateRebateGroupTypes.LIVE
      ? []
      : coercedGet(rebateGroup, 'levels', []);

  const rebateGroupPercentages =
    rebateGroup.type === CreateRebateGroupTypes.LIVE
      ? rebateGroup.percentages
      : coercedGet(levels, `${activeLevel}.percentages`, []);

  const percentages = rebateGroupPercentages.reduce(
    (
      acc: any,
      {
        gameType,
        percentage,
        vendor: { id },
        games,
      }: {
        gameType: string;
        percentage: number;
        vendor: DynamicObj;
        games: Array<any>;
      }
    ) => ({
      ...acc,
      [`${gameType}_${id}`]: {
        gameType,
        percentage: getPercentageValueFromGames({ percentage, games }),
        vendor: id,
      },
    }),
    {}
  );

  return (
    <Root className="bg-white">
      <Layout.Content className="p-4">
        <Breadcrumb className="mb-3">
          <Breadcrumb.Item>
            <FormattedMessage
              id="common.rebates.text"
              defaultMessage="Rebates"
            />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a
              href="#target"
              onClick={(e) => {
                e.preventDefault();
                rebateGroupDispatch({
                  type: RebateGroupTypes.RESET_ACTIVE_REBATE_GROUP,
                });
                rebateGroupDispatch({
                  type: RebateGroupTypes.SET_SUMMARY_INACTIVE,
                });
              }}
            >
              <FormattedMessage
                id="rebates.list-of-rebates.text"
                defaultMessage="List of Rebates"
              />
            </a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{rebateGroup.name}</Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={32}>
          <Col span={24} className="gutter-row">
            <div className="gutter-box">
              <div className="d-flex justify-content-space-between">
                <Typography.Title level={2}>
                  {rebateGroup.name}
                </Typography.Title>
                {rebateGroup.status === RebateGroupStatus.INACTIVE &&
                  ALLOWED_EDIT && (
                    <Button
                      type="primary"
                      size="large"
                      onClick={() => {
                        rebateGroupDispatch({
                          type: RebateGroupTypes.SET_ACTIVE_REBATE_GROUP,
                          payload: rebateGroup.id,
                          meta: rebateGroup,
                        });
                        props.showDrawer();
                      }}
                    >
                      {translate(messages['rebates.edit-details.text'])}
                    </Button>
                  )}
              </div>
              <Collapse bordered={false} defaultActiveKey={['1', '2', '3']}>
                <Collapse.Panel
                  header={translate(messages['rebates.general-settings.text'])}
                  key="1"
                >
                  <Descriptions bordered column={1}>
                    <Descriptions.Item
                      label={translate(messages['rebates.group-name.text'])}
                    >
                      {rebateGroup.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={translate(messages['rebates.qualifying-vip.text'])}
                    >
                      {(qualifyingMemberLoyaltyLevels || []).map(
                        (
                          item: { color: string; name: string },
                          idx: number
                        ) => (
                          <StyledTag color={item.color} key={idx}>
                            {item.name}
                          </StyledTag>
                        )
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={translate(
                        messages['rebates.excluded-member-levels.text']
                      )}
                    >
                      {(excludedMemberLevels || []).map(
                        (
                          item: { color: string; name: string },
                          idx: number
                        ) => (
                          <StyledTag color={item.color} key={idx} withBorder>
                            {item.name}
                          </StyledTag>
                        )
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={translate(messages['rebates.validity.text'])}
                    >
                      <span>
                        {coercedGet(validityDateTimeRange, 'start', '')
                          ? moment(validityDateTimeRange.start).format(
                              DATE_FORMAT
                            )
                          : ''}
                      </span>
                      <span> - </span>
                      <span>
                        {coercedGet(validityDateTimeRange, 'end', '')
                          ? moment(validityDateTimeRange.end).format(
                              DATE_FORMAT
                            )
                          : ''}
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Collapse.Panel>
                <Collapse.Panel
                  header={translate(
                    messages['rebates.distribution-settings.text']
                  )}
                  key="2"
                >
                  <Descriptions bordered column={1}>
                    {type === CreateRebateGroupTypes.PERIODIC && (
                      <>
                        <Descriptions.Item
                          label={translate(
                            messages['rebates.settlement-method.text']
                          )}
                        >
                          {settlementMethod === SETTLEMENT_METHOD_TYPES.YEARLY
                            ? SETTLEMENT_METHOD_TYPES.ANNUALLY
                            : settlementMethod}
                        </Descriptions.Item>
                        {settlementMethod ===
                          SETTLEMENT_METHOD_TYPES.WEEKLY && (
                          <Descriptions.Item
                            label={translate(
                              messages['rebates.payout-day.text']
                            )}
                          >
                            {payout.day}
                          </Descriptions.Item>
                        )}
                        {settlementMethod ===
                          SETTLEMENT_METHOD_TYPES.MONTHLY && (
                          <Descriptions.Item
                            label={translate(
                              messages['rebates.payout-date.text']
                            )}
                          >
                            {payout.date}
                          </Descriptions.Item>
                        )}
                        {settlementMethod ===
                          SETTLEMENT_METHOD_TYPES.YEARLY && (
                          <Descriptions.Item
                            label={translate(
                              messages['rebates.payout-date-and-month.text']
                            )}
                          >
                            {payout.dateAndMonth}
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item
                          label={translate(
                            messages['rebates.payout-time.text']
                          )}
                        >
                          {payout.time}
                        </Descriptions.Item>
                      </>
                    )}
                    <Descriptions.Item
                      label={translate(messages['rebates.payout-method.text'])}
                    >
                      {automaticPayout ? (
                        <FormattedMessage
                          id="rebates.automatic-payout.text"
                          defaultMessage="Automatic payout"
                        />
                      ) : (
                        <FormattedMessage
                          id="manual-clain.text"
                          defaultMessage="Manual Claim"
                        />
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={translate(messages['rebates.turnover.text'])}
                    >
                      {turnoverRequirementMultiplier
                        ? `${turnoverRequirementMultiplier}x`
                        : translate(messages['none.text'])}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={translate(
                        messages['rebates.claim-offset-time.text']
                      )}
                    >
                      {claimOffsetDuration !== '0ms' && claimOffsetDuration
                        ? formatMSString(claimOffsetDuration)
                        : ''}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={translate(
                        messages['rebates.claim-expiry-time.text']
                      )}
                    >
                      {claimExpiryDuration !== '0ms' && claimExpiryDuration
                        ? formatMSString(claimExpiryDuration)
                        : ''}
                    </Descriptions.Item>
                    {/*                  
                    <Descriptions.Item
                      label={translate(
                        messages['rebates.award-expiry-time.text']
                      )}
                    >
                      {payoutExpiryDuration !== '0ms' && payoutExpiryDuration
                        ? formatMSString(payoutExpiryDuration)
                        : ''}
                    </Descriptions.Item> */}
                    <Descriptions.Item
                      label={translate(
                        messages['rebates.minimum-and-maximum-payout.text']
                      )}
                    >
                      <span>
                        {minimumPayout && <>{currency?.symbol}</>}
                        {minimumPayout
                          ? numeral(minimumPayout).format('0,0.00')
                          : ''}
                      </span>
                      <span> - </span>
                      <span>
                        {maximumPayout && <>{currency?.symbol}</>}
                        {maximumPayout
                          ? numeral(maximumPayout).format('0,0.00')
                          : ''}
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Collapse.Panel>
                <Collapse.Panel
                  header={translate(messages['rebates.group-settings.text'])}
                  key="3"
                >
                  <GroupSettings
                    levels={levels}
                    percentages={percentages}
                    activeLevel={activeLevel}
                    setActiveLevel={setActiveLevel}
                    rebateGroupPercentages={rebateGroupPercentages}
                  />
                </Collapse.Panel>
              </Collapse>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    </Root>
  );
}

const Root = styled.div`
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-header {
    padding: 12px !important;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 12px;
    .anticon {
      position: absolute;
      align-self: unset;
      top: 50%;
      right: 0;
      text-align: right !important;
      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  .ant-layout-content {
    max-width: 1024px;
    margin: 0 auto;
    font-size: 16px;
  }
  .ant-typography {
    margin-bottom: 0.5rem;
  }
  .ant-descriptions-row {
    th,
    td {
      width: 50%;
      font-weight: 500;
      font-size: 16px;
      background-color: #fff;
    }
    &:nth-child(odd) {
      th,
      td {
        background-color: #fafafa;
      }
    }
  }
`;

export default Summary;
