import React, { ReactElement } from 'react';
import Navbar from 'components/Navbar';
import { ScreenTabsNew } from 'pages/components/ScreenTabsNew/ScreenTabsNew';
import { StyledLayout } from './styles';
import './AppLayout.scss';

const AntLayout = (): ReactElement => (
  <StyledLayout>
    <Navbar />
    <ScreenTabsNew />
  </StyledLayout>
);

export default AntLayout;
