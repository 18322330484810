import React from 'react';
import { Select } from 'antd';
import { StyledSelect } from './styles';

const { Option } = Select;

const Members = () => (
  <div className="my-5">
    <div className="mb-1">Members</div>
    <StyledSelect placeholder="Select" style={{ width: '100%' }}>
      <Option value="1">Member 1</Option>
      <Option value="2">Member 2</Option>
    </StyledSelect>
  </div>
);

export default Members;
