import styled from 'styled-components';

export const StyledTableContainer = styled.div`
  /* overflow: auto;
  margin: 0 auto;
  width: 80%; */

  tr.drop-over-downward td {
    border-bottom: 2px inset #1890ff;
  }

  tr.drop-over-upward td {
    border-top: 2px inset #1890ff;
  }

  .button-group {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const StyledCenter = styled.div`
  /* height: 138px; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {};
