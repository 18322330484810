import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import { useFormik } from 'formik';
import { UPDATE_WITHDRAWAL_COMPLIANCE_CONFIG } from 'graphql/mutations/withdrawalCompliance.mutation';
import { WITHDRAWAL_COMPLIANCE_CONFIG } from 'graphql/queries/withdrawalCompliance.query';
import { isEqual } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React from 'react';
import { usePermissions } from 'store/accountState';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import AutomaticComplianceChecks from './components/AutomaticComplianceChecks';
import TurnoverRequirement from './components/TurnoverRequirement';
import validationSchema from './validationSchema';

const formItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const getThresholdValue = (input: any) => {
  const newInputValue = input ? Number(input) : null;

  if (newInputValue || newInputValue === 0)
    return Math.round(newInputValue * 100) / 100;

  return '';
};

const ComplianceCheckForm = ({
  initialValues,
}: {
  initialValues: Record<string, any>;
}) => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_EDIT } = collectPermissions(
    role,
    permissions,
    ['EDIT'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_COMPLIANCE_CHECK
  );
  const translate = useTranslate();
  const [updateComplianceConfig, updateComplianceConfigResult] = useMutation(
    UPDATE_WITHDRAWAL_COMPLIANCE_CONFIG,
    {
      onCompleted: (data) => {
        if (data) {
          message.success(translate(messages.successfullySavedChanges));
        }
      },
      refetchQueries: [
        {
          query: WITHDRAWAL_COMPLIANCE_CONFIG,
        },
      ],
    }
  );

  const formik = useFormik({
    initialValues,
    onSubmit: (formValues) => {
      const { enabled } = formValues;
      updateComplianceConfig({
        variables: { input: enabled ? { ...formValues } : { enabled: false } },
      });
    },
    enableReinitialize: true,
    validationSchema,
  });

  const { handleSubmit, values, resetForm } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-space-between mb-6 bb-1 pb-4">
        <strong>{translate(messages.complianceCheck)}</strong>
        {!isEqual(values, initialValues) && ALLOWED_EDIT && (
          <div className="d-flex">
            <Button
              className="mr-2"
              disabled={updateComplianceConfigResult.loading}
              onClick={() => resetForm()}
            >
              {translate(messages.cancel)}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={updateComplianceConfigResult.loading}
            >
              {translate(messages.save)}
            </Button>
          </div>
        )}
      </div>
      <AutomaticComplianceChecks formItemLayout={formItemLayout} {...formik} />
      {values.enabled && (
        <>
          {/* <BonusHunter
            getThresholdValue={getThresholdValue}
            formItemLayout={formItemLayout}
            {...formik}
          />
          <IpCollisionOffender
            getThresholdValue={getThresholdValue}
            formItemLayout={formItemLayout}
            {...formik}
          />
          <ContraBetOffender
            getThresholdValue={getThresholdValue}
            formItemLayout={formItemLayout}
            {...formik}
          /> */}
          <TurnoverRequirement
            getThresholdValue={getThresholdValue}
            formItemLayout={formItemLayout}
            {...formik}
          />
          {/* <MartingaleBetOffender
            getThresholdValue={getThresholdValue}
            formItemLayout={formItemLayout}
            {...formik}
          /> */}
        </>
      )}
    </Form>
  );
};

export default ComplianceCheckForm;
