import React from 'react';
import { Select } from 'antd';
import { messages } from 'constants/memberInteractionLog';
import useTranslate from 'utils/useTranslate';
import { MemberInteractionLogModule } from 'types/graphqlTypes';

const { Option } = Select;

type Props = {
  onChange: (e: Array<string> | string) => void;
  value: Array<string> | string;
  placeHolder?: string;
  disabled?: boolean;
  multiple?: boolean;
};

const ModuleSelect: React.FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
}) => {
  const translate = useTranslate();

  const handleChange = (e: string | Array<string>) => {
    onChange(e);
  };

  return (
    <Select
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
    >
      {Object.values(MemberInteractionLogModule).map((module: string) => (
        <Option key={module} value={module}>
          {translate(messages[`${module}`])}
        </Option>
      ))}
    </Select>
  );
};

export default ModuleSelect;
