import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, message, Modal } from 'antd';
import {
  ACTIVATE_PROGRAM,
  SUBMIT_MEMBER_LOYALTY_PROGRAMME,
} from 'graphql/mutations/vip.mutation';
import {
  MEMBER_LOYALTY_PROGRAMMES,
  QUALIFYING_MEMBER_LOYALTY_LEVELS,
} from 'graphql/queries/promo.query';
import { MEMBER_LOYALTY_PROGRAMS } from 'graphql/queries/vip.query';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

type Props = {
  programId: string;
  refetchVariables: any;
  isDraft: boolean;
};

const ActivateProgram = ({
  programId,
  refetchVariables,
  isDraft = false,
}: Props) => {
  const translate = useTranslate();
  const [isShow, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const [activateProgram] = useMutation(ACTIVATE_PROGRAM, {
    onCompleted: ({ activateMemberLoyaltyProgramme }) =>
      activateMemberLoyaltyProgramme
        ? message.success(translate(messages.LOYALTY_PROGRAMME_ACTIVATED))
        : message.error(
            translate(
              messages['member-loyalty.loyalty-programme-activation-failed']
            )
          ),
  }) as any;

  const onActivateProgram = async () => {
    setLoading(true);
    await activateProgram({
      variables: {
        id: programId,
      },
      refetchQueries: [
        {
          query: MEMBER_LOYALTY_PROGRAMS,
          fetchPolicy: 'network-only',
          variables: refetchVariables,
        },
        {
          query: QUALIFYING_MEMBER_LOYALTY_LEVELS,
          fetchPolicy: 'network-only',
        },
        {
          query: MEMBER_LOYALTY_PROGRAMMES,
          fetchPolicy: 'network-only',
        },
      ],
    })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        handleShow();
      });
  };
  const [submitVip] = useMutation(SUBMIT_MEMBER_LOYALTY_PROGRAMME);

  const activateFromDraft = async () => {
    setLoading(true);
    await submitVip({
      variables: {
        id: programId,
      },
    })
      .then(() => {
        setTimeout(() => {
          onActivateProgram();
        }, 1000);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button type="link" className="text-black" onClick={handleShow} block>
        <FormattedMessage
          id="member-loyalty.activate.text"
          defaultMessage="Activate"
        />
      </Button>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          cancelButtonProps={{ disabled: loading }}
          okButtonProps={{ loading }}
          onCancel={handleShow}
          onOk={async () => {
            if (isDraft) {
              await activateFromDraft();
            } else {
              await onActivateProgram();
            }
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-activate.text"
                defaultMessage="Confirm Activate"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ActivateProgram;
