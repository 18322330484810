import React, { useState } from 'react';
import NextPrev from 'components/NextPrev';
import { StyledTableContainer } from './styles';
import TableIndex from './TableIndex';
import { DEFAULT_ROW_COUNT } from './columns';

type Props = {
  state: Record<string, any>;
};

const MemberGameReportTable = ({ state }: Props) => {
  const [page, setPage] = useState({
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
    totalCount: 0,
    pageInfo: {} as Record<string, any>,
  });

  const handleNext = () => {
    const { savedCursor, currentPage, pageInfo } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const totalPage = Math.ceil(page.totalCount / DEFAULT_ROW_COUNT) || 1;

  return (
    <StyledTableContainer>
      <TableIndex
        state={state}
        page={page}
        setPage={setPage}
        setResultInfo={({
          pageInfo,
          totalCount,
        }: {
          pageInfo: Record<string, any>;
          totalCount: number;
        }) => {
          setPage({
            ...page,
            pageInfo,
            totalCount,
          });
        }}
      />
      <NextPrev
        onPrev={handlePrev}
        onNext={handleNext}
        disablePrev={page.currentPage + 1 === 1}
        disableNext={page.currentPage + 1 === totalPage}
        label={`${page.currentPage + 1} / ${totalPage}`}
      />
    </StyledTableContainer>
  );
};

export default MemberGameReportTable;
