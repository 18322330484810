import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import localMessages from 'pages/components/OperatorManagement/messages';
import useTranslate from 'utils/useTranslate';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { CREATE_OPERATOR } from 'graphql/mutations/operator.mutation';
import styled from 'styled-components';
import OperatorForm from '../OperatorForm';
import { StyledModal } from './styles';

const NewOperator = (props: Record<string, any>) => {
  const { refetch } = props;

  const [isShow, setIsShow] = useState(false);
  const { context } = useOperatorHeader();

  const handleModal = () => setIsShow((prevState) => !prevState);
  const translate = useTranslate();
  const [createOperator, { loading }] = useMutation(CREATE_OPERATOR, {
    context,
    onCompleted: () => {
      refetch();
      message.success(translate(localMessages.OPERATOR_CREATE_SUCCESS));
    },
  });

  return (
    <>
      <Button
        onClick={handleModal}
        style={{ marginTop: 'auto' }}
        type="primary"
      >
        <PlusOutlined />{' '}
        <FormattedMessage
          id="new-operator.text"
          defaultMessage="New operator"
        />
      </Button>
      <StyledModal
        title={
          <FormattedMessage
            id="new--operator.text"
            defaultMessage="New operator"
          />
        }
        visible={isShow}
        width={1000}
        onOk={handleModal}
        onCancel={handleModal}
        footer={false}
      >
        <Wrapper>
          <OperatorForm
            onClose={handleModal}
            createOperator={createOperator}
            loading={loading}
            operator={{
              username: '',
              password: '',
              realName: '',
              gender: '',
              email: '',
              mobileNumber: '',
              permissionGroups: [],
              notes: '',
              status: 'ENABLED',
            }}
          />
        </Wrapper>
      </StyledModal>
    </>
  );
};

const Wrapper = styled.div`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
  }
`;

export default NewOperator;
