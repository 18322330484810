import { defineMessages } from 'react-intl';

const messages = defineMessages({
  INVALID_USER: {
    id: 'INVALID_USER',
    defaultMessage: 'Invalid user',
  },
  BALANCE_SERVICE_REPORT: {
    id: 'BALANCE_SERVICE_REPORT',
    defaultMessage: 'Balance Service Report',
  },
  BALANCE_SERVICE_VISUAL_REPORT: {
    id: 'BALANCE_SERVICE_VISUAL_REPORT',
    defaultMessage: 'Balance Service Visual Report',
  },
  ORIGINAL_AMOUNT: {
    id: 'ORIGINAL_AMOUNT',
    defaultMessage: 'Original Amount',
  },
  CURRENT_AMOUNT: {
    id: 'CURRENT_AMOUNT',
    defaultMessage: 'Current Amount',
  },
  TURNOVER_MULTIPLIER: {
    id: 'TURNOVER_MULTIPLIER',
    defaultMessage: 'Turnover Multiplier',
  },
  TURNOVER_REQUIREMENT: {
    id: 'TURNOVER_REQUIREMENT',
    defaultMessage: 'Turnover Requirement',
  },
  TURNOVER_BALANCE: {
    id: 'TURNOVER_BALANCE',
    defaultMessage: 'Turnover Balance',
  },
  BETS: {
    id: 'BETS',
    defaultMessage: 'Bets',
  },
  WITHDRAWAL_ID: {
    id: 'WITHDRAWAL_ID',
    defaultMessage: 'Withdrawal ID',
  },
  BALANCE_SERVICE_CODE: {
    id: 'BALANCE_SERVICE_CODE',
    defaultMessage: 'Balance Service Code',
  },
  TRANSACTION_CODE: {
    id: 'TRANSACTION_CODE',
    defaultMessage: 'Transaction Code',
  },
  clearAll: {
    id: 'clear-all.text',
    defaultMessage: 'Clear all',
  },
  SEARCH_USER: {
    id: 'SEARCH_USER',
    defaultMessage: 'Search User',
  },
  MANUAL_ADJUSTMENT: {
    id: 'MANUAL_ADJUSTMENT',
    defaultMessage: 'Manual Adjustment',
  },
});

export default messages;
