import { Modal } from 'antd';
import gql from 'graphql-tag';

const { confirm } = Modal;

export const confirmModal = (
  onOk: Function,
  onCancel: Function,
  action?: string
) => {
  confirm({
    title: 'Confirm',
    content: `Do you want to ${action} this document?`,
    centered: true,
    onOk() {
      onOk();
    },
    onCancel() {
      onCancel();
    },
  });
};

export const MEMBER_DOCUMENTS = gql`
  query MemberDocuments(
    $first: Int
    $after: Binary
    $filter: MemberDocumentsFilterInput
  ) {
    memberDocuments(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          type
          document {
            id
            uri
            dateTimeCreated
            mimetype
            encoding
          }
          status
          dateTimeCreated
          dateTimeUpdated
        }
      }
    }
  }
`;

export const MEMBER_DOCUMENT = gql`
  query MemberDocument($id: ID!) {
    memberDocument(id: $id) {
      id
      type
      document {
        id
        uri
        dateTimeCreated
      }
      status
      dateTimeCreated
      dateTimeUpdated
    }
  }
`;

export const APPROVE_MEMBER_DOCUMENT = gql`
  mutation ApproveMemberDocument($id: ID!) {
    approveMemberDocument(id: $id)
  }
`;

export const REJECT_MEMBER_DOCUMENT = gql`
  mutation RejectMemberDocument($id: ID!) {
    rejectMemberDocument(id: $id)
  }
`;

export const DELETE_MEMBER_DOCUMENT = gql`
  mutation DeleteMemberDocument($id: ID!) {
    deleteMemberDocument(id: $id)
  }
`;
