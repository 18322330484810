import React, { useState, useEffect } from 'react';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import coercedGet from 'utils/coercedGet';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { messages } from 'SuperAdminMain/components/AccountManagementNew/utils';
import { checkPartial } from 'utils/partialUtils';
import globalMessages from 'messages';
import { StyledLabel, StyledSpan, StyledSelect } from '../utils';

const ADMINS_USERNAME = gql`
  query ADMINS_USERNAME(
    $first: Int
    $after: Binary
    $filter: AdminsFilterInput
  ) {
    admins(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export const AccountFilter: React.FC = () => {
  const { filters, setFilters } = useFilterValues();
  const [partial, setPartial] = React.useState('');
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input, 500);
  const translate = useTranslate();

  const [loadAdmins, { data, loading, called }] = useLazyQuery(
    ADMINS_USERNAME,
    {
      variables: {
        first: 10,
        filter: {
          username: {
            contains: debouncedInput,
          },
        },
      },
    }
  );

  if (!called) loadAdmins();

  const edges = coercedGet(data, 'admins.edges', []);
  const admins = edges.map(({ node }: any) => node);

  const handleChange = (e: Array<string>) => {
    if (e.length) {
      setFilters((prev: any) => ({
        ...prev,
        username: { in: e },
      }));
    } else {
      setFilters((prev: any) => ({
        ...prev,
        username: null,
      }));
    }

    setPartial('');
    setInput('');
  };

  const hasPartial = checkPartial('username', { username: filters.username });

  useEffect(() => {
    loadAdmins();
  }, [debouncedInput, loadAdmins]);

  return (
    <div className="filter">
      <div className="label-clear">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="vgm.client-account.text"
              defaultMessage="Client Account"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        mode="multiple"
        value={filters.username ? filters.username.in : []}
        placeholder={translate(messages['please-enter.text'])}
        loading={loading}
        onChange={handleChange}
        onFocus={() => setInput('')}
        onBlur={() => setInput('')}
        onSearch={(text: any) => {
          setPartial(text);
          setInput(text);
        }}
        filterOption={false}
      >
        {!hasPartial && partial.length && (
          <Select.Option
            key={11}
            value={`${translate(globalMessages.PARTIAL)}: ${partial}`}
            label={`${translate(globalMessages.PARTIAL)}: ${partial}`}
          >
            <FormattedMessage id="partial.text" defaultMessage="Partial" />:{' '}
            {partial}
          </Select.Option>
        )}

        {admins.map((admin: any) => (
          <Select.Option
            key={admin.id}
            value={admin.username}
            label={admin.username}
          >
            {admin.username}
          </Select.Option>
        ))}
      </StyledSelect>
    </div>
  );
};
