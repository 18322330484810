import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Table } from 'antd';
import columns, { DEFAULT_ROW_COUNT } from './columns';
import { MEMBER_GAME_REPORT } from '../../queries';

const { Column, ColumnGroup } = Table;

const TableIndex = (props: Record<string, any>) => {
  const { state, setResultInfo, page, setPage } = props;
  const { dateRange, status, usernames } = state;
  const variables = {
    first: DEFAULT_ROW_COUNT,
    after: page.after,
    filter: {
      ...(status.length && { status: { in: status } }),
      ...(usernames.length && { username: { in: usernames } }),
    },
  };

  useEffect(() => {
    setPage({
      ...page,
      after: undefined,
      savedCursor: [undefined],
      currentPage: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, status, usernames]);
  const { loading, error, data = {} } = useQuery(MEMBER_GAME_REPORT, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: (result: Record<string, any>) => setResultInfo(result.members),
  });
  const { members = {} } = data;
  const { edges = [] } = members;
  const dataSource = edges.map((edge: Record<string, any>) => edge.node);
  let containerTestId = 'container';
  if (loading) {
    containerTestId = 'loading';
  } else if (error) {
    containerTestId = 'error';
  } else {
    containerTestId = 'container';
  }
  return (
    <div data-testid={containerTestId}>
      <Table
        bordered
        size="middle"
        rowKey="id"
        dataSource={dataSource}
        loading={loading}
        pagination={false}
      >
        {columns?.map((column: Record<string, any>, idx) => {
          const { subColumns = [], title, dataIndex, render } = column;
          return subColumns.length ? (
            <ColumnGroup title={title} key={idx}>
              {subColumns.map(
                ({
                  title: subTitle,
                  dataIndex: subDataIndex,
                  render: subRender,
                }: {
                  title: string;
                  dataIndex: number;
                  render: any;
                }) => (
                  <Column
                    title={subTitle}
                    dataIndex={subDataIndex}
                    key={subDataIndex}
                    align="center"
                    render={subRender}
                  />
                )
              )}
            </ColumnGroup>
          ) : (
            <Column
              title={title}
              dataIndex={dataIndex}
              key={dataIndex || idx}
              align="center"
              render={render}
            />
          );
        })}
      </Table>
    </div>
  );
};

export default TableIndex;
