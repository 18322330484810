import { useQuery } from '@apollo/react-hooks';
import { CONFIG } from 'graphql/queries/config.query';
import numeral from 'numeral';
import { Config, CurrencyMetadata } from 'types/graphqlTypes';
import isRow from 'utils/isRow';
import esGet from '../utils/esGet';

interface Props {
  skipConfig?: boolean;
}

export const useConfig = ({ skipConfig = false }: Partial<Props> = {}) => {
  const { data, refetch, loading } = useQuery<{
    config: Config;
  }>(CONFIG, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: skipConfig,
  });

  const {
    enableLoqateIntegration,
    enableWithdrawalCancelation,
    defaultCurrency,
    memberFormFields,
    checkDepositLimit,
    baseCurrencySymbol,
    defaultCountry,
    timezone,
    xtpAppToken,
    websiteLink,
    baseCurrency,
  } = data?.config || {};

  const currency = esGet(defaultCurrency, {
    symbol: loading ? '' : '£',
  }) as CurrencyMetadata;

  const renderNumeral = (
    value: string | number,
    isCrypto: boolean = false,
    isExchangeRate: boolean = false
  ) => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!Boolean(value)) return isCrypto ? '0.00000000' : '0.00';

    const [integerPart, decimalPart] = value.toString().split('.');

    let decimalPlaces: number = 2;
    if (isExchangeRate) {
      decimalPlaces = decimalPart ? 4 : 2;
    } else {
      decimalPlaces = isCrypto ? 8 : 2;
    }

    // eslint-disable-next-line no-nested-ternary
    const formattedDecimalPart = decimalPart
      ? decimalPart.length >= decimalPlaces
        ? `.${decimalPart.slice(0, decimalPlaces)}`
        : `.${decimalPart}${'0'.repeat(decimalPlaces - decimalPart.length)}`
      : `.${'0'.repeat(decimalPlaces)}`;

    const formattedNumber = `${integerPart.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ','
    )}${formattedDecimalPart}`;

    return formattedNumber;
  };

  // use case UKGC/ROW amounts with currency (admin)
  // for data display of amounts in UKGC: use the Config.defaultCurrency for currency display
  // for data display of amounts in ROW: use the Config.baseCurrencySymbol for currency display
  // use addAdminCurrency
  const addAdminCurrency = (value: string | number) =>
    `${isRow ? baseCurrencySymbol : currency.symbol} ${renderNumeral(value)}`;

  // TODO: delete old addCurrency helper func and refactor all its occurrences: replace with addAdminCurrency
  const addCurrency = (
    value: any,
    decimalPlace = 0,
    roundingFunction: any = undefined,
    currencySymbol: any = undefined
  ) => {
    let newDigits = '';
    for (let j = 0; j < decimalPlace; j += 1) {
      newDigits += '0';
    }
    return `${currencySymbol ?? currency.symbol} ${numeral(value).format(
      `0,0.${newDigits}`,
      roundingFunction
    )}`;
  };

  return {
    websiteLink,
    currency,
    addCurrency,
    baseCurrencySymbol,
    refetch,
    renderNumeral,
    loqateIntegration: enableLoqateIntegration,
    enableWithdrawalCancelation,
    memberFormFields,
    loadingConfig: loading,
    checkDepositLimit,
    defaultCountry,
    timezone,
    xtpAppToken,
    baseCurrency,
    addAdminCurrency,
  };
};
