import React from 'react';
import { Button, Popconfirm as AntdPopconfirm } from 'antd';
import { useFormik } from 'formik';
import messages from 'messages';
import { defineMessages, FormattedMessage } from 'react-intl';
import { EditBalanceFields } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/EditBalanceFields';
import useTranslate from 'utils/useTranslate';
import { BulkProps } from 'interfaces/bulkProps.interface';
import MoreActionsMembersFilter from 'components/MoreActionsMembersFilter';
import { StyledForm } from './styles';
import validationSchema from './validationSchema';

const Popconfirm: any = AntdPopconfirm;
const messagesLocal = defineMessages({
  'enter-username.text': {
    id: 'enter-username.text',
    defaultMessage: 'Enter Username',
  },
  'bulk-edit-balance-confirm-question.text': {
    id: 'bulk-edit-balance-confirm-question.text',
    defaultMessage:
      'Are you sure you want to proceed? This action is irreversible.',
  },
});

const BulkAdjustBalanceMenuForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: BulkProps) => {
  const translate = useTranslate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validationSchema: validationSchema(translate),
  });

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    handleChange,
  } = formik;
  return (
    <StyledForm>
      {!values.excel && !values.text && (
        <div className="px-3 pt-3">
          <MoreActionsMembersFilter
            value={values.username}
            placeholder={translate(messagesLocal['enter-username.text'])}
            onChange={(e) => setFieldValue('username', e || [])}
            validateStatus={
              errors.username && touched.username ? 'error' : undefined
            }
            help={`${touched.username ? errors.username : 'No Users'}`}
          />
        </div>
      )}
      <EditBalanceFields
        showImportExcel
        showImportText
        values={values}
        errors={errors}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        touched={touched}
        type="bulk"
        memberNotAffiliate={false}
        isSingleManualWithdrawal={false}
      />
      <div className="bt-1 p-3 text-right">
        <Button className="mr-2" onClick={onClose} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>
        <Popconfirm
          title={translate(
            messagesLocal['bulk-edit-balance-confirm-question.text']
          )}
          okText={translate(messages.YES)}
          cancelText={translate(messages.NO)}
          onConfirm={handleSubmit}
        >
          <Button type="primary" htmlType="submit" loading={isLoading}>
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </Popconfirm>
      </div>
    </StyledForm>
  );
};

export default BulkAdjustBalanceMenuForm;
