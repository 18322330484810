import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, Form } from 'antd';
import { GeoFenceConfig } from 'types/graphqlTypes';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

type Props = {
  onAddIp: (
    value: {
      [x: string]: string;
    },
    field: string
  ) => void;
  fields: {
    values: GeoFenceConfig;
    type: string;
    item: string;
  };
};

export default ({ onAddIp, fields: { item, type, values } }: Props) => {
  const ValidationSchema = yup.object().shape({
    [`${type}${item}IPs`]: yup
      .string()
      .nullable()
      .required('Field is required')
      .matches(
        /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/,
        'Must be IP Address format.'
      )
      .notOneOf(values[`${type}${item}IPs`], 'IP Address already exists.'),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    clearErrors,
    reset,
  } = useForm({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(ValidationSchema),
  });

  return (
    <>
      <Form style={{ width: '83%' }}>
        <Form.Item
          validateStatus={errors[`${type}${item}IPs`] && 'error'}
          help={
            errors[`${type}${item}IPs`] && errors[`${type}${item}IPs`]?.message
          }
        >
          <Controller
            control={control}
            name={`${type}${item}IPs`}
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={(event) => {
                  clearErrors(`${type}${item}IPs`);

                  onChange(event.target.value);
                }}
              />
            )}
          />
        </Form.Item>
      </Form>
      <Button
        type="primary"
        style={{
          width: '15%',
        }}
        onClick={handleSubmit((value) => {
          onAddIp(value, `${type}${item}IPs`);
          reset();
        })}
      >
        Add
      </Button>
    </>
  );
};
