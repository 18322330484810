import React, { useState } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Button, Row, Col } from 'antd';
import { formItemHorizontalLayout } from 'constants/form';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { useConfig } from '../../hooks/useConfig';

type Rec = Record<string, any>;

const InputGroup = Input.Group;
const { Option } = Select;

function MultiTierForm({
  values,
  setFieldValue,
  field,
  withFormItem,
  onlyRelative,
  oneStepIncrement,
  isLossRebate,
}: {
  setFieldValue: (e: string, jac: any, shouldValidate?: boolean) => void;
  values: Rec;
  field: string;
  withFormItem?: boolean;
  onlyRelative?: boolean;
  oneStepIncrement?: boolean;
  isLossRebate?: boolean;
}) {
  const translate = useTranslate();
  const { currency } = useConfig();
  const PayoutType = {
    RELATIVE: 'RELATIVE',
    ABSOLUTE: 'ABSOLUTE',
  };
  let levels = values[field];
  if (!levels) {
    levels = [];
  }

  const fromPropsMinimum = levels[levels.length - 1]
    ? +levels[levels.length - 1].minimum + 1
    : 0;
  const [payoutValue, setPayoutValue] = useState<any>(0);
  const [payoutMinimum, setPayoutMinimum] = useState<any>(fromPropsMinimum);
  const [payoutType, setPayoutType] = useState<any>(PayoutType.RELATIVE);
  const [errorStatus, setErrorStatus] = useState<any>('success');
  const [inputError, setInputError] = useState<any>(null);

  function onAdd() {
    const lesser = +fromPropsMinimum > +payoutMinimum;
    if (lesser) {
      setErrorStatus('error');
      setInputError(`Minimum value must be greater than ${fromPropsMinimum}`);
    }

    if (!lesser) {
      const tempPayout = [...levels].concat({
        minimum: +payoutMinimum,
        value:
          payoutType === PayoutType.ABSOLUTE ? payoutValue : payoutValue / 100,
        type: payoutType,
      });

      setFieldValue(field, tempPayout, false);
      setErrorStatus(null);
      setInputError(null);
      setPayoutValue(0);
      setPayoutMinimum(+payoutMinimum + (oneStepIncrement ? 1 : 2));
    }
  }

  function onRemove(i: number) {
    const temp = [...levels];
    temp.splice(i, 1);
    setFieldValue(field, temp, false);
  }

  function renderPayoutInfo() {
    return levels
      .map((q: any, i: number) => ({
        ...q,
        maximum: levels[i + 1]
          ? levels[i + 1].minimum - (oneStepIncrement ? 0 : 1)
          : translate(messages['infinity.text']),
      }))
      .map((q: Rec, i: number) => {
        const absolute = q.type === PayoutType.ABSOLUTE;

        return (
          <InputGroup key={i} compact style={{ marginBottom: 5 }}>
            <Input
              suffix={`${(q.value * (absolute ? 1 : 100)).toFixed(2)}${
                absolute ? `${currency?.symbol}` : '%'
              }`}
              size="large"
              readOnly
              value={`${q.minimum} - ${q.maximum}`}
              style={{ width: '80%' }}
            />
            <Button
              style={{
                borderRadius: '50%',
                margin: '4px 10px',
                backgroundColor: 'gray',
                color: 'white',
              }}
              onClick={() => onRemove(i)}
              shape="circle"
              icon={<CloseOutlined />}
            />
          </InputGroup>
        );
      });
  }

  const compactInputGroup = () => (
    <>
      {levels.length ? (
        <>
          {isLossRebate && (
            <Row>
              <Col span={12}>
                {translate(messages.FROM)} - {translate(messages.TO)}
              </Col>
              <Col span={7} style={{ textAlign: 'right' }}>
                {translate(messages.REWARD)} %
              </Col>
            </Row>
          )}
          {renderPayoutInfo()}
        </>
      ) : (
        ''
      )}
      <>
        <InputGroup compact>
          <Input
            size="large"
            placeholder="Minimum"
            style={{ width: '32%' }}
            type="number"
            value={payoutMinimum}
            onChange={(e) => setPayoutMinimum(e.target.value)}
          />
          <Select
            value={payoutType}
            style={{ width: 100 }}
            size="large"
            onChange={(e) => setPayoutType(e)}
          >
            <Option value={PayoutType.RELATIVE}>
              {translate(messages['affiliate_create_relative.text'])}
            </Option>
            {!onlyRelative && (
              <Option value={PayoutType.ABSOLUTE}>
                {translate(messages['affiliate_create_absolute.text'])}
              </Option>
            )}
          </Select>
          <Input
            type="number"
            value={payoutValue <= 0 ? 0 : payoutValue || 0}
            size="large"
            placeholder="Value"
            style={{ width: '32%' }}
            suffix={`${
              payoutType === PayoutType.RELATIVE ? '%' : `${currency?.symbol}`
            }`}
            onChange={(e) => {
              if (+e.target.value <= 0) {
                setPayoutValue(0);
              } else {
                setPayoutValue(e.target.value);
              }
            }}
          />
        </InputGroup>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAdd}
          className="mt-2"
        >
          <FormattedMessage id="add.text" defaultMessage="Add" />
        </Button>
      </>
    </>
  );

  return (
    <>
      {withFormItem ? (
        <Form.Item
          label={translate(messages.PAYOUT)}
          {...formItemHorizontalLayout}
          validateStatus={errorStatus}
          help={inputError}
        >
          {compactInputGroup()}
        </Form.Item>
      ) : (
        compactInputGroup()
      )}
    </>
  );
}

export default MultiTierForm;
