import { Form, Radio, Space, Input, Tooltip, Select } from 'antd';
import React from 'react';
import { Control, FieldError, Controller } from 'react-hook-form';
import { useQuery } from '@apollo/react-hooks';
import {
  PaymentMethod,
  PaymentMethodsConnectionEdge,
} from 'types/graphqlTypes';
import { GET_PAYMENT_METHODS } from 'graphql/queries/paymentMethodListing.query';
import coercedGet from 'utils/coercedGet';

type Props = {
  control: Control;
  currencySymbol: string | undefined | null;
  selectedAwardType: string | null;

  fieldError: {
    awardType: FieldError | undefined;
    amount: FieldError | undefined;
    amountPercentage: FieldError | undefined;
    awardCap: FieldError | undefined;
    minAmount: FieldError | undefined;
    maxAmount: FieldError | undefined;
    claimCountLimit: FieldError | undefined;
    paymentMethods: FieldError | any;
    maxPayoutAmount: FieldError | undefined;
  };
};

const { Item } = Form;
const { Option } = Select;

const DepositPromoFields = ({
  control,
  fieldError,
  selectedAwardType,
  currencySymbol,
}: Props) => {
  const {
    awardType,
    amount,
    awardCap,
    claimCountLimit,
    paymentMethods,
    amountPercentage,
  } = fieldError || {};

  const { data, loading } = useQuery(GET_PAYMENT_METHODS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        enabled: { eq: true },
      },
    },
  });

  const paymentMethodOpts = coercedGet(data, 'paymentMethods.edges', []).map(
    (edge: PaymentMethodsConnectionEdge) => edge.node
  );

  return (
    <>
      <Item
        className="mb-4"
        labelCol={{
          span: 8,
        }}
        label="Promo Award Type"
        validateStatus={awardType && 'error'}
        help={awardType && awardType?.message}
      >
        <Controller
          control={control}
          name="awardType"
          rules={{
            required: {
              value: true,
              message: 'Promo award type is required',
            },
          }}
          render={({ onChange, value }) => (
            <Radio.Group
              onChange={(e) => onChange(e.target.value)}
              value={value}
            >
              <Space>
                <Radio value="FLAT">Flat</Radio>
                <Radio value="PERCENTAGE">%</Radio>
              </Space>
            </Radio.Group>
          )}
        />
      </Item>
      {selectedAwardType === 'FLAT' && (
        <Item
          className="mb-4"
          labelCol={{
            span: 8,
          }}
          label="Promo Award Flat Amount"
          validateStatus={amount && 'error'}
          help={amount && amount?.message}
        >
          <Controller
            control={control}
            name="amount"
            rules={{
              required: {
                value: true,
                message: 'Promo award flat amount is required',
              },
              min: {
                value: 1,
                message: 'Promo award flat amount must be greater than 0.',
              },
            }}
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={(event) => onChange(+event.target.value)}
                type="number"
                min={0}
                style={{ width: 120, textAlign: 'center' }}
                addonBefore={currencySymbol}
              />
            )}
          />
        </Item>
      )}

      {selectedAwardType === 'PERCENTAGE' && (
        <>
          <Item
            className="mb-4"
            labelCol={{
              span: 8,
            }}
            label="Enter Promo Award Config"
            validateStatus={amountPercentage && 'error'}
            help={amountPercentage && amountPercentage?.message}
          >
            <Controller
              control={control}
              name="amountPercentage"
              rules={{
                required: {
                  value: true,
                  message: 'Promo award config is required',
                },
                min: {
                  value: 1,
                  message: 'Promo award must be greater than 0.',
                },
              }}
              render={({ onChange, value }) => (
                <Input
                  value={value}
                  onChange={(event) => onChange(+event.target.value)}
                  type="number"
                  min={0}
                  max={100}
                  onKeyPress={(e) => e.preventDefault()}
                  style={{ width: 120, textAlign: 'center' }}
                  addonAfter="%"
                />
              )}
            />
          </Item>
          <Item
            className="mb-4"
            labelCol={{
              span: 8,
            }}
            label="Max Award Value (CAP)"
            validateStatus={awardCap && 'error'}
            help={awardCap && awardCap?.message}
          >
            <Controller
              control={control}
              name="awardCap"
              rules={{
                required: {
                  value: true,
                  message: 'Maximum award value (CAP) is required',
                },
                min: {
                  value: 1,
                  message: 'Maximum award value (CAP) must be greater than 0.',
                },
              }}
              render={({ onChange, value }) => (
                <Input
                  value={value}
                  onChange={(event) => onChange(+event.target.value)}
                  type="number"
                  min={0}
                  style={{ width: 120, textAlign: 'center' }}
                  addonBefore={currencySymbol}
                />
              )}
            />
          </Item>
        </>
      )}

      <Item
        className="mb-4"
        labelCol={{
          span: 8,
        }}
        label="Deposit <min> - <max> Value"
      >
        <Input.Group compact>
          <Input
            className="site-input-split"
            style={{
              width: 32,
              borderRight: 0,
              pointerEvents: 'none',
            }}
            value={currencySymbol as string}
          />
          <Controller
            control={control}
            name="minAmount"
            rules={{}}
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={(event) => onChange(+event.target.value)}
                min={0}
                type="number"
                style={{ width: 120, textAlign: 'center' }}
              />
            )}
          />
          <Input
            className="site-input-split"
            style={{
              width: 30,
              pointerEvents: 'none',
            }}
            placeholder="-"
            disabled
          />
          <Controller
            control={control}
            name="maxAmount"
            rules={{}}
            render={({ onChange, value }) => (
              <Input
                value={value}
                onChange={(event) => onChange(+event.target.value)}
                min={0}
                type="number"
                style={{ width: 120, textAlign: 'center' }}
              />
            )}
          />
        </Input.Group>
      </Item>
      <Item
        className="mb-4"
        labelCol={{
          span: 8,
        }}
        label="Same Member can avail Promo"
        validateStatus={claimCountLimit && 'error'}
        help={claimCountLimit && claimCountLimit?.message}
      >
        <Controller
          control={control}
          name="claimCountLimit"
          rules={{
            required: {
              value: true,
              message: 'Same Member can avail Promo count is required',
            },
            min: {
              value: 0,
              message:
                'Same Member can avail Promo count must be greater than or equal 0.',
            },
          }}
          render={({ onChange, value }) => (
            <Tooltip
              placement="right"
              title="If set to 0, promo availabilty to a member as unlimited."
            >
              <Input
                value={value}
                onChange={(event) => onChange(+event.target.value)}
                type="number"
                min={0}
                style={{ width: 200, textAlign: 'center' }}
                addonAfter="No. of times"
              />
            </Tooltip>
          )}
        />
      </Item>
      <Item
        className="mb-4"
        labelCol={{
          span: 8,
        }}
        label="Deposit Source"
        validateStatus={paymentMethods && 'error'}
        help={paymentMethods && paymentMethods.message}
      >
        <Controller
          control={control}
          name="paymentMethods"
          rules={{
            required: {
              value: true,
              message: 'Deposit source is required.',
            },
          }}
          render={({ onChange, value }) => (
            <Select
              allowClear
              style={{ width: 200 }}
              value={value}
              onChange={onChange}
              placeholder="Select Deposit Source"
              loading={loading}
              mode="multiple"
            >
              {paymentMethodOpts.map((option: PaymentMethod) => (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </Item>
      <Item
        className="mb-4"
        labelCol={{
          span: 8,
        }}
        label="Max Payout Budget"
      >
        <Controller
          control={control}
          name="maxPayoutAmount"
          rules={{}}
          render={({ onChange, value }) => (
            <Input
              value={value}
              onChange={(event) => onChange(+event.target.value)}
              type="number"
              min={0}
              style={{ width: 200, textAlign: 'center' }}
              addonBefore={currencySymbol}
            />
          )}
        />
      </Item>
    </>
  );
};

export default DepositPromoFields;
