import gql from 'graphql-tag';

export const CREATE_MEMBER_TAG = gql`
  mutation createMemberTag($input: CreateMemberTagInput!) {
    createMemberTag(input: $input)
  }
`;

export const UPDATE_MEMBER_TAG = gql`
  mutation updateMemberTag($id: ID!, $input: UpdateMemberTagInput!) {
    updateMemberTag(id: $id, input: $input)
  }
`;

export const DELETE_MEMBER_TAG = gql`
  mutation deleteMemberTag($id: ID!) {
    deleteMemberTag(id: $id)
  }
`;

export const UPDATE_OPERATOR = gql`
  mutation UpdateOperator($id: ID!, $input: UpdateOperatorInput!) {
    updateOperator(id: $id, input: $input)
  }
`;
