import { defineMessages } from 'react-intl';

const messages = defineMessages({
  CLIENT_ACCOUNT_AND_PERIOD_IS_REQUIRED: {
    id: 'reports.client-account-and-period-filters-are-required.text',
    defaultMessage: 'Client Account and Period filters are required fields',
  },
  ACCOUNT_USAGE_REPORT_CREATED: {
    id: 'reports.account-usage-report-created.text',
    defaultMessage: 'Account Usage Report Created',
  },
});

export default messages;
