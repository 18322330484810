import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'enabled.text': {
    id: 'enabled.text',
    defaultMessage: 'Enabled',
  },
  'disabled.text': {
    id: 'disabled.text',
    defaultMessage: 'Disabled',
  },
  'frozen.text': {
    id: 'frozen.text',
    defaultMessage: 'Frozen',
  },
});

export default messages;
