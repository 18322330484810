import React, { useState, useRef, ReactElement } from 'react';
import cx from 'classnames';
import {
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Dropdown,
  Button,
  Menu,
  Checkbox,
  Row as AntdRow,
  Col,
  Input,
  Select,
  Tag,
  Typography,
} from 'antd';
import { Icon } from '@ant-design/compatible';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { kebabCase } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { globalTranslate } from 'utils/globalMessages';
import { GameTypes } from 'constants/gameTypes';
import useTranslate from 'utils/useTranslate';
import { Locale } from 'constants/locale';
import { ALink } from 'components/ALink/ALink';
import { useConfig } from 'hooks/useConfig';
import { DynamicObj } from 'interfaces/user.interface';
import { useRebateGroupState } from '../../../../../context';
import {
  useRebateGroupPercentagesState,
  RebateGroupTypes,
  SortTypeNames,
  SearchTypeNames,
  SortTypes,
} from '../context';
import messages from '../messages';

const Row: any = AntdRow;

const StyledInput = styled(Input)`
  height: 36px;
  width: 200px;
  .ant-input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  &.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
  }
`;

const StyledSelect = styled(Select)`
  &.ant-select > .ant-select-selection--single {
    height: 36px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
  }
  &.ant-select
    > .ant-select-selection--single
    > .ant-select-selection__rendered {
    line-height: 35px;
  }
`;

const StyledIcon = styled(Icon)`
  color: 'rgba(0,0,0,.45)';
  &:hover {
    color: 'rgba(46, 49, 49, 1)';
  }
`;

const StyledRow: any = styled(Row)`
  background-color: ${(props: { active?: boolean }) =>
    props.active ? 'rgba(0,0,0,0.05)' : 'white'};
  width: 120px;
  border: 1px solid transparent;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer !important;
`;

const StyledTag = styled(Tag)`
  font-size: 24px !important;
  padding: 8px !important;
  background-color: ${(props: { active?: boolean }) =>
    props.active ? 'white' : '#ECECEC'};
  cursor: pointer !important;
`;

const CustomDivider = styled.div`
  height: 80%;
  margin-top: auto;
  margin-bottom: auto;
  border-right: 0.25px solid gray;
  opacity: 0.25;
`;

const Header = ({
  readOnly,
  shouldTiersShow,
  levels = [],
  activeLevel = 0,
  setActiveLevel,
}: {
  shouldTiersShow?: boolean;
  activeLevel?: number;
  levels?: Array<any>;
  readOnly?: boolean;
  setActiveLevel?: (e: number) => void;
}) => {
  const [{ vendors }] = useRebateGroupState() as any;
  const [state, dispatch] = useRebateGroupPercentagesState() as any;
  const { currency } = useConfig();
  const timeOut = useRef(0);
  const [search, setSearch] = useState('');
  const [filterValuesShown, setFilterValuesShown] = React.useState(false);

  const intl = useIntl();
  const subMenuItems =
    state.sortBy === SortTypes.GAME_TYPE
      ? Object.keys(GameTypes).map((key) => ({
          label: `${globalTranslate(`${kebabCase(key)}.text`, intl)}`,
          value: key,
        }))
      : vendors.map((item: DynamicObj) => ({
          label:
            intl.locale === Locale.EN
              ? item.node.nameEn
              : `${item.node.nameZh || item.node.name}`,
          value: item.node.id,
        }));

  const handleChange = (e: any) => {
    const { value } = e.target;
    setSearch(value);

    clearTimeout(timeOut.current);
    e.persist();
    timeOut.current = setTimeout(() => {
      dispatch({
        type: RebateGroupTypes.SET_SEARCH,
        payload: { keyword: value },
      });
    }, 250);
  };
  const translate = useTranslate();
  return (
    <>
      <Row
        style={{ background: 'white', height: 46 }}
        type="flex"
        justify="space-between"
      >
        <div>
          <div className="d-flex">
            <StyledInput
              placeholder={translate(messages['search.text'])}
              prefix={
                <SearchOutlined style={{ color: 'rgba(46, 49, 49, 1)' }} />
              }
              value={search}
              onChange={handleChange}
              suffix={
                search && (
                  <StyledIcon
                    type="close"
                    onClick={() => {
                      setSearch('');
                      dispatch({
                        type: RebateGroupTypes.SET_SEARCH,
                        payload: { keyword: '' },
                      });
                    }}
                  />
                )
              }
            />
            <StyledSelect
              defaultValue="GAME_TYPE"
              style={{ width: 140, height: 36 }}
              onChange={(mode) => {
                dispatch({
                  type: RebateGroupTypes.SET_SEARCH,
                  payload: { mode },
                });
              }}
            >
              {Object.entries(SearchTypeNames).map((value, index) => {
                const messageId = kebabCase(value[1].toLocaleLowerCase());

                return (
                  <Select.Option key={index} value={value[0]}>
                    {translate(messages[`rebates.${messageId}.text`])}
                  </Select.Option>
                );
              })}
            </StyledSelect>
          </div>
        </div>
        <div>
          <Row type="flex" justify="end" className="my-2">
            <div>
              <Dropdown
                overlay={
                  <Menu>
                    {Object.entries(SortTypeNames).map((value) => {
                      const messageId = kebabCase(value[1].toLocaleLowerCase());
                      return (
                        <Menu.Item key={value[0]}>
                          <ALink
                            onClick={(e: { preventDefault: () => void }) => {
                              e.preventDefault();
                              if (state.sortBy === value[0]) {
                                return;
                              }
                              dispatch({
                                type: RebateGroupTypes.SET_SORT_BY,
                                payload: value[0],
                              });
                              dispatch({
                                type: RebateGroupTypes.RESET_FILTER_VALUES,
                              });
                            }}
                          >
                            {translate(messages[`rebates.${messageId}.text`])}
                          </ALink>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
                trigger={['click']}
                placement="bottomLeft"
              >
                <ALink className="ant-dropdown-link">
                  <FormattedMessage
                    id="common.sort-by.text"
                    defaultMessage="Sort by"
                  />
                  :{' '}
                  {`${globalTranslate(
                    `${kebabCase(state.sortBy.toLowerCase())}.text`,
                    intl
                  )}`}{' '}
                  <DownOutlined />
                </ALink>
              </Dropdown>
            </div>
            <div
              className={cx({
                'ml-2': readOnly,
                'ml-3': !readOnly,
              })}
            >
              <Dropdown
                onVisibleChange={(flag) => setFilterValuesShown(flag)}
                visible={filterValuesShown}
                overlay={
                  <StyledMenu>
                    {subMenuItems.map(
                      (item: { value: string; label: string }) => (
                        <Menu.Item key={item.value}>
                          <Checkbox
                            checked={Boolean(
                              state.filterValues.find(
                                (filterValue: { value: string }) =>
                                  filterValue.value === item.value
                              )
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                dispatch({
                                  type: RebateGroupTypes.SET_FILTER_VALUES,
                                  payload: item,
                                });
                              } else {
                                dispatch({
                                  type: RebateGroupTypes.REMOVE_FILTER_VALUES,
                                  payload: item,
                                });
                              }
                            }}
                          >
                            {item.label}
                          </Checkbox>
                        </Menu.Item>
                      )
                    )}
                  </StyledMenu>
                }
                trigger={['click']}
                placement="bottomLeft"
              >
                <ALink className="ant-dropdown-link">
                  <span className="mr-1">
                    {state.sortBy === SortTypes.GAME_TYPE ? (
                      <FormattedMessage
                        id="rebates.game-type-select.text"
                        defaultMessage="Game Type:"
                      />
                    ) : (
                      <FormattedMessage
                        id="rebates.game-vendor-select.text"
                        defaultMessage="Game Vendor:"
                      />
                    )}
                  </span>
                  <span>
                    {state.filterValues.length > 0 ? (
                      <FilterValuesWrapper>
                        {!readOnly
                          ? state.filterValues.map(
                              (item: {
                                label: string | ReactElement;
                                value: string;
                              }) => (
                                <span key={item.value}>
                                  {state.sortBy === SortTypes.GAME_TYPE ? (
                                    <>
                                      {translate(
                                        messages[
                                          `common.${item.value.toLowerCase()}.text`
                                        ]
                                      )}
                                    </>
                                  ) : (
                                    <>{item.label}</>
                                  )}
                                </span>
                              )
                            )
                          : '...'}
                      </FilterValuesWrapper>
                    ) : (
                      <FormattedMessage
                        id="rebates.all.text"
                        defaultMessage="All"
                      />
                    )}
                  </span>
                  <DownOutlined className="ml-1" />
                </ALink>
              </Dropdown>
            </div>
          </Row>
        </div>
      </Row>
      {readOnly && shouldTiersShow && (
        <Row
          style={{
            background: 'white',
            height: 56,
            paddingLeft: '0.6rem',
          }}
          type="flex"
          className="relative"
        >
          {activeLevel ||
            (0 > 4 && (
              <Button
                shape="circle"
                className="absolute"
                style={{
                  left: 0,
                  bottom: 0,
                  zIndex: 3,
                  marginBottom: 12,
                }}
              >
                <LeftOutlined />
              </Button>
            ))}
          <Row type="flex">
            {levels.map((level, index) => (
              <>
                <StyledRow
                  onClick={() => {
                    if (activeLevel !== index) {
                      if (setActiveLevel) {
                        setActiveLevel(index);
                      }
                    }
                  }}
                  active={activeLevel === index}
                  key={level}
                  type="flex"
                  justify="center"
                  align="middle"
                >
                  <Col span={8}>
                    <StyledTag active={activeLevel === index}>
                      {index + 1}
                    </StyledTag>
                  </Col>
                  <Col span={12}>
                    <Typography.Text style={{ fontSize: 16 }}>
                      {currency?.symbol} {coercedGet(level, 'minimum', 0)} -{' '}
                      {currency?.symbol}
                      {index !== levels.length - 1 && levels[index + 1] ? (
                        (levels[index + 1].minimum - 1).toString()
                      ) : (
                        <FormattedMessage
                          id="infinity.text"
                          defaultMessage="Infinity"
                        />
                      )}
                    </Typography.Text>
                  </Col>
                </StyledRow>
                {index !== 4 && activeLevel !== index && <CustomDivider />}
              </>
            ))}
          </Row>
          {levels.length > 5 && (
            <Button
              shape="circle"
              className="absolute"
              style={{
                right: 0,
                bottom: 0,
                zIndex: 3,
                marginBottom: 12,
              }}
            >
              <RightOutlined />
            </Button>
          )}
        </Row>
      )}
    </>
  );
};

const FilterValuesWrapper = styled.span`
  > span {
    &:not(:last-child):after {
      content: ', ';
    }
  }
`;

const StyledMenu = styled(Menu)`
  max-width: 250px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
  .ant-dropdown-menu-item {
    label {
      display: block;
    }
  }
`;

export default Header;
