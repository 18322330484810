import { useMutation, useQuery } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import { UPDATE_WITHDRAWAL_PROVIDER } from 'graphql/mutations/withdrawalProvider.mutation';
import {
  WITHDRAWAL_PROVIDER,
  WITHDRAWAL_PROVIDERS,
} from 'graphql/queries/withdrawalProvider.query';
import messages from 'pages/components/WithdrawalProviders/messages';
import React from 'react';
import useTranslate from 'utils/useTranslate';
import ProviderForm from '../ProviderForm';
import { StyledModal } from './styles';

const EditProvider = (props: Record<string, any>) => {
  const { toggleModal, id, editable, refetchVariables } = props;

  const refetchQueries = [
    {
      query: WITHDRAWAL_PROVIDER,
      variables: { id },
    },
    {
      query: WITHDRAWAL_PROVIDERS,
      variables: refetchVariables,
    },
  ];
  const translate = useTranslate();

  const [
    updateMidWithdrawalProvider,
    updateMidWithdrawalProviderResult,
  ] = useMutation(UPDATE_WITHDRAWAL_PROVIDER, {
    onCompleted: () => {
      message.success({
        content: (
          <div data-testid="success">
            {translate(messages['successfully-saved-changes.text'])}
          </div>
        ),
      });
    },
    refetchQueries,
  });

  const { loading, data } = useQuery(WITHDRAWAL_PROVIDER, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <StyledModal
        visible
        width={1000}
        onOk={toggleModal}
        onCancel={toggleModal}
        footer={false}
        title={data?.withdrawalProvider && data?.withdrawalProvider.name}
      >
        <div>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <Spin />
            </div>
          ) : (
            <ProviderForm
              onClose={toggleModal}
              submitWithdrawalProvider={updateMidWithdrawalProvider}
              withdrawalProvider={data?.withdrawalProvider}
              editable={editable}
              loading={updateMidWithdrawalProviderResult.loading}
            />
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default EditProvider;
