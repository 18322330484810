import React from 'react';
import { get } from 'lodash';
import { Typography, Collapse, Divider, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import {
  allTypes,
  useCreateLoyaltyProgramContext,
} from '../../../CreateProgramme/context';

type Props = {
  tier: any;
  handleEditProgram: () => void;
};

function TierDetails({ tier, handleEditProgram }: Props) {
  const { qualificationCriteria } = tier;

  const [, dispatch] = useCreateLoyaltyProgramContext() as any;

  const getDepositAmount = () => {
    if (
      qualificationCriteria.type === 'BASE' &&
      get(qualificationCriteria, 'and.length') > 0
    ) {
      return get(qualificationCriteria, 'and[0].amount');
    }

    if (qualificationCriteria.type === 'DEPOSIT') {
      return qualificationCriteria.amount;
    }

    return '-';
  };

  const withTurnover =
    qualificationCriteria.type === 'BASE' &&
    qualificationCriteria.and.length > 1;

  const {
    upgradePayout,
    dailyPayout,
    weeklyPayout,
    monthlyPayout,
    annualPayout,
    birthdayPayout,
  } = tier;

  return (
    <Collapse defaultActiveKey={['1']}>
      <Collapse.Panel header={tier.name} key="1" className="pb-0">
        <Typography.Paragraph strong>
          {' '}
          <FormattedMessage
            id="member-loyalty-general-caps.text"
            defaultMessage="GENERAL"
          />
        </Typography.Paragraph>
        <Typography.Paragraph strong>
          {' '}
          <FormattedMessage
            id="member-loyalty-invitation-only.text"
            defaultMessage="Invitation Only"
          />
        </Typography.Paragraph>
        <Typography.Paragraph>
          {tier.inviteOnly ? (
            <FormattedMessage id="yes.text" defaultMessage="Yes" />
          ) : (
            <FormattedMessage id="no.text" defaultMessage="No" />
          )}
        </Typography.Paragraph>

        <Typography.Paragraph strong>
          <FormattedMessage
            id="member-loyalty-qualification-setting-caps.text"
            defaultMessage="QUALIFICATIONS SETTINGS"
          />
        </Typography.Paragraph>

        {/* <Typography.Paragraph strong>
          <FormattedMessage
            id="member-loyalty-conditions.text"
            defaultMessage="Conditions"
          />
        </Typography.Paragraph>
        <Typography.Paragraph>
          {withTurnover > 1 ? (
            <FormattedMessage
              id="member-loyalty-deposit-and-or-turnover.text"
              defaultMessage="Deposit and/ or Turnover"
            />
          ) : (
            <FormattedMessage
              id="member-loyalty-deposit-only.text"
              defaultMessage="Deposit Only"
            />
          )}
        </Typography.Paragraph> */}

        <Typography.Paragraph strong>
          <FormattedMessage
            id="member-loyalty-deposit-amount.text"
            defaultMessage="Deposit Amount"
          />
        </Typography.Paragraph>
        <Typography.Paragraph>
          {qualificationCriteria && getDepositAmount()}
        </Typography.Paragraph>

        <Typography.Paragraph strong>
          <FormattedMessage
            id="member-loyalty-turnover-amount.text"
            defaultMessage="Turnover Amount"
          />
        </Typography.Paragraph>
        <Typography.Paragraph>
          {withTurnover ? qualificationCriteria.and[1].amount : '-'}
        </Typography.Paragraph>

        {/* <Typography.Paragraph strong>
          <FormattedMessage
            id="member-loyalty-maintenance.text"
            defaultMessage="Maintenance"
          />
        </Typography.Paragraph> */}
        {/* <Typography.Paragraph>
          {tier.sameAsQualificationCriteria ? (
            <FormattedMessage
              id="member-loyalty-same-as-qualification.text"
              defaultMessage="Same As Qualification"
            />
          ) : (
            <FormattedMessage
              id="member-loyalty-not-same-as-qualification.text"
              defaultMessage="Not Same As Qualification"
            />
          )}
        </Typography.Paragraph> */}

        <Divider className="my-3" />
        {/* upgrade */}

        {upgradePayout && (
          <>
            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-upgrade-awards-caps.text"
                defaultMessage="UPGRADE AWARDS"
              />
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-limit-per-member.text"
                defaultMessage="Award Limit Per Member"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {upgradePayout ? upgradePayout.limit : '-'}
            </Typography.Paragraph>

            {/* <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-expiry-hours.text"
                defaultMessage="Award Expiry (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {upgradePayout ? upgradePayout.payoutExpiryAmount : '-'}
            </Typography.Paragraph> */}

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-amount.text"
                defaultMessage="Amount"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {upgradePayout ? upgradePayout.amount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-turnover.text"
                defaultMessage="Turnover"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {upgradePayout && upgradePayout.turnover
                ? upgradePayout.turnover
                : '-'}
            </Typography.Paragraph>

            {/* <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-duration.text"
                defaultMessage="Claim Duration "
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {upgradePayout && upgradePayout.claimExpiryDuration
                ? upgradePayout.claimExpiryDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-offset-hours.text"
                defaultMessage="Claim Offset (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {upgradePayout && upgradePayout.claimOffsetDuration
                ? upgradePayout.claimOffsetDuration
                : '-'}
            </Typography.Paragraph> */}
            <Divider className="my-3" />
          </>
        )}

        {/* daily */}

        {dailyPayout && (
          <>
            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-daily-awards-caps.text"
                defaultMessage="DAILY AWARDS"
              />
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-limit-per-member.text"
                defaultMessage="Award Limit Per Member"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {dailyPayout ? dailyPayout.limit : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-expiry-hours.text"
                defaultMessage="Award Expiry (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {dailyPayout ? dailyPayout.payoutExpiryAmount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-amount.text"
                defaultMessage="Amount"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {dailyPayout ? dailyPayout.amount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-turnover.text"
                defaultMessage="Turnover"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {dailyPayout && dailyPayout.turnover ? dailyPayout.turnover : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-duration.text"
                defaultMessage="Claim Duration "
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {dailyPayout && dailyPayout.claimExpiryDuration
                ? dailyPayout.claimExpiryDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-offset-hours.text"
                defaultMessage="Claim Offset (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {dailyPayout && dailyPayout.claimOffsetDuration
                ? dailyPayout.claimOffsetDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-time-of-day.text"
                defaultMessage="Time of Day"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {dailyPayout
                ? moment(dailyPayout.timeOfDay, 'HH:mm').format('hh:mm A')
                : '-'}
            </Typography.Paragraph>
            <Divider className="my-3" />
          </>
        )}

        {/* weekly */}

        {weeklyPayout && (
          <>
            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-weekly-awards-caps.text"
                defaultMessage="WEEKLY AWARDS"
              />
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-limit-per-member.text"
                defaultMessage="Award Limit Per Member"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {weeklyPayout ? weeklyPayout.limit : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-expiry-hours.text"
                defaultMessage="Award Expiry (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {weeklyPayout ? weeklyPayout.payoutExpiryAmount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-amount.text"
                defaultMessage="Amount"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {weeklyPayout ? weeklyPayout.amount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-turnover.text"
                defaultMessage="Turnover"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {weeklyPayout && weeklyPayout.turnover
                ? weeklyPayout.turnover
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-duration.text"
                defaultMessage="Claim Duration "
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {weeklyPayout && weeklyPayout.claimExpiryDuration
                ? weeklyPayout.claimExpiryDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-offset-hours.text"
                defaultMessage="Claim Offset (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {weeklyPayout && weeklyPayout.claimOffsetDuration
                ? weeklyPayout.claimOffsetDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-time-of-day.text"
                defaultMessage="Time of Day"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {weeklyPayout
                ? moment(weeklyPayout.timeOfDay, 'HH:mm').format('hh:mm A')
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-day-of-week.text"
                defaultMessage="Day of Week"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {weeklyPayout && weeklyPayout.dayOfWeek
                ? weeklyPayout.dayOfWeek
                : '-'}
            </Typography.Paragraph>
            <Divider className="my-3" />
          </>
        )}

        {/* monthly */}

        {monthlyPayout && (
          <>
            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-monthly-awards-caps.text"
                defaultMessage="MONTHLY AWARDS"
              />
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-limit-per-member.text"
                defaultMessage="Award Limit Per Member"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {monthlyPayout ? monthlyPayout.limit : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-expiry-hours.text"
                defaultMessage="Award Expiry (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {monthlyPayout ? monthlyPayout.payoutExpiryAmount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-amount.text"
                defaultMessage="Amount"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {monthlyPayout ? monthlyPayout.amount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-turnover.text"
                defaultMessage="Turnover"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {monthlyPayout && monthlyPayout.turnover
                ? monthlyPayout.turnover
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-duration.text"
                defaultMessage="Claim Duration "
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {monthlyPayout && monthlyPayout.claimExpiryDuration
                ? monthlyPayout.claimExpiryDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-offset-hours.text"
                defaultMessage="Claim Offset (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {monthlyPayout && monthlyPayout.claimOffsetDuration
                ? monthlyPayout.claimOffsetDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-time-of-day.text"
                defaultMessage="Time of Day"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {monthlyPayout
                ? moment(monthlyPayout.timeOfDay, 'HH:mm').format('hh:mm A')
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-day-of-month.text"
                defaultMessage="Day of Month"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {monthlyPayout && monthlyPayout.dayOfMonth
                ? monthlyPayout.dayOfMonth
                : '-'}
            </Typography.Paragraph>
            <Divider className="my-3" />
          </>
        )}

        {/* annual */}

        {annualPayout && (
          <>
            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-annual-awards-caps.text"
                defaultMessage="ANNUAL AWARDS"
              />
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-limit-per-member.text"
                defaultMessage="Award Limit Per Member"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {annualPayout ? annualPayout.limit : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-expiry-hours.text"
                defaultMessage="Award Expiry (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {annualPayout ? annualPayout.payoutExpiryAmount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-amount.text"
                defaultMessage="Amount"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {annualPayout ? annualPayout.amount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-turnover.text"
                defaultMessage="Turnover"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {annualPayout && annualPayout.turnover
                ? annualPayout.turnover
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-duration.text"
                defaultMessage="Claim Duration "
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {annualPayout && annualPayout.claimExpiryDuration
                ? annualPayout.claimExpiryDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-offset-hours.text"
                defaultMessage="Claim Offset (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {annualPayout && annualPayout.claimOffsetDuration
                ? annualPayout.claimOffsetDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-time-of-day.text"
                defaultMessage="Time of Day"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {annualPayout
                ? moment(annualPayout.timeOfDay, 'HH:mm').format('hh:mm A')
                : '-'}
            </Typography.Paragraph>
            <Divider className="my-3" />
          </>
        )}

        {/* birthday */}

        {birthdayPayout && (
          <>
            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-birthday-awards-caps.text"
                defaultMessage="BIRTHDAY AWARDS"
              />
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-limit-per-member.text"
                defaultMessage="Award Limit Per Member"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {birthdayPayout ? birthdayPayout.limit : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-award-expiry-hours.text"
                defaultMessage="Award Expiry (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {birthdayPayout ? birthdayPayout.payoutExpiryAmount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-amount.text"
                defaultMessage="Amount"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {birthdayPayout ? birthdayPayout.amount : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-turnover.text"
                defaultMessage="Turnover"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {birthdayPayout && birthdayPayout.turnover
                ? birthdayPayout.turnover
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-duration.text"
                defaultMessage="Claim Duration "
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {birthdayPayout && birthdayPayout.claimExpiryDuration
                ? birthdayPayout.claimExpiryDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-claim-offset-hours.text"
                defaultMessage="Claim Offset (Hours)"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {birthdayPayout && birthdayPayout.claimOffsetDuration
                ? birthdayPayout.claimOffsetDuration
                : '-'}
            </Typography.Paragraph>

            <Typography.Paragraph strong>
              <FormattedMessage
                id="member-loyalty-time-of-day.text"
                defaultMessage="Time of Day"
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              {birthdayPayout
                ? moment(birthdayPayout.timeOfDay, 'HH:mm').format('hh:mm A')
                : '-'}
            </Typography.Paragraph>
            <Divider className="mb-0 mt-3" />
          </>
        )}

        {/* ============================= */}

        <Typography.Paragraph className="text-center m-0">
          <Button
            type="link"
            className="p-0"
            style={{ lineHeight: 1 }}
            onClick={() => {
              handleEditProgram();

              dispatch({
                type: allTypes.SET_ACTIVE_SCREEN,
                payload: allTypes.TIER_SETTINGS,
              });

              dispatch({
                type: allTypes.SET_ACTIVE_TIER,
                payload: tier,
              });

              dispatch({
                type: allTypes.SET_ACTIVE_SCREEN,
                payload: allTypes.ADD_TIER_FORMS,
              });
            }}
          >
            <FormattedMessage
              id="view-edit-tier.text"
              defaultMessage="View / Edit Tier"
            />
          </Button>
        </Typography.Paragraph>
      </Collapse.Panel>
    </Collapse>
  );
}

export default TierDetails;
