import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';

const AdjustmentStatus = () => (
  <Tooltip
    title={
      <span>
        <FormattedMessage
          id="reports.manual-balance-adjustment.text"
          defaultMessage="Manual Balance Adjustment"
        />
      </span>
    }
    className="styled-center"
  >
    <CheckCircleOutlined
      style={{ fontSize: '20px' }}
      data-testid="adjustment-status"
    />
  </Tooltip>
);

export default AdjustmentStatus;
