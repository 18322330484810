import React from 'react';
import coercedGet from 'utils/coercedGet';
import { Tag } from 'antd';
import { get, uniq, capitalize, startCase } from 'lodash';
import MemberTag from 'components/MemberTag';
import VendorTag from 'components/VendorTag';
import { formatDate } from 'utils/dateUtils';
import { useDatePresets } from 'hooks/useDatePresets';
import { useFilterValues } from 'contexts/Filters';
import GameNameTag from './GameNameTag/GameNameTag';

type Props = {
  filters: Record<string, any>;
  loading: boolean;
  onFilterChange: Function;
};

export const FilterConditions = ({
  filters,
  loading,
  onFilterChange,
}: Props) => {
  const { preSetValue, setPresetValue } = useDatePresets();

  const { defaultValues } = useFilterValues();

  const handleRemove = ({
    event,
    dataToRemove,
    filterKey,
    operation = 'in',
  }: {
    event: React.MouseEvent<HTMLElement>;
    dataToRemove: string;
    filterKey: string;
    operation?: string;
  }) => {
    event.preventDefault();

    if (!loading) {
      const data = get(filters[filterKey], operation, []).filter(
        (item: string) => item !== dataToRemove
      );
      const newFilter = {
        [operation]: data,
      };
      onFilterChange({
        ...filters,
        [filterKey]: data.length ? newFilter : null,
      });
    }
  };

  // applicable only for string array filter values
  const FilterTag = ({
    filterKey,
    operation = 'in',
  }: {
    filterKey: string;
    operation?: string;
  }) =>
    coercedGet(filters, `${filterKey}.${operation}`, []).map((item: string) => (
      <Tag
        style={{ margin: '4px' }}
        key={item}
        closable
        onClose={(e) =>
          handleRemove({
            event: e,
            dataToRemove: item,
            filterKey,
            operation,
          })
        }
      >
        {item}
      </Tag>
    ));

  const handleRemoveStatus = () =>
    onFilterChange({
      ...filters,
      status: null,
    });

  const amountRangeFilters = [
    {
      key: 'effectiveBet',
      label: 'Effective Bet',
    },
    {
      key: 'winloss',
      label: 'Win / Loss',
    },
    {
      key: 'tips',
      label: 'Tips',
    },
    {
      key: 'jackpotContributionBet',
      label: 'Jackpot Contribution Bet',
    },
    {
      key: 'jackpotWin',
      label: 'Jackpot Win',
    },
    {
      key: 'cashoutWinloss',
      label: 'Cashout Win / Loss',
    },
    {
      key: 'turnover',
      label: 'Bet Amount',
    },
  ];

  return (
    <>
      Filter Conditions:
      {preSetValue === 'lifetime' && (
        <Tag
          closable
          onClose={() => {
            setPresetValue(null);

            onFilterChange(defaultValues);
          }}
        >
          {capitalize(startCase(preSetValue))}
        </Tag>
      )}
      <FilterTag filterKey="serialCode" />
      <FilterTag filterKey="payout" />
      <FilterTag filterKey="gameCategory" />
      <FilterTag filterKey="memberCurrency" />
      <FilterTag filterKey="gameSubCategory" />
      <FilterTag filterKey="gameType" />
      <FilterTag filterKey="brand" />
      <FilterTag filterKey="brandId" />
      <FilterTag filterKey="platformId" />
      <FilterTag filterKey="balanceUnitSerialCodes" operation="includesAny" />
      <FilterTag filterKey="round" />
      <FilterTag filterKey="device" />
      {/* ======== */}
      {filters.member &&
        filters.member.in.map((item: string) => (
          <MemberTag
            key={item}
            id={item}
            fieldName="username"
            onClose={(e: React.MouseEvent<HTMLElement>) =>
              handleRemove({
                event: e,
                dataToRemove: item,
                filterKey: 'member',
              })
            }
          />
        ))}
      {filters.vendor
        ? filters.vendor.in.map((item: string) => (
            <VendorTag
              key={item}
              onClose={(e) =>
                handleRemove({
                  event: e,
                  dataToRemove: item,
                  filterKey: 'vendor',
                })
              }
              id={item}
            />
          ))
        : null}
      {filters.game
        ? uniq(filters.game.in).map((item: any, key: any) => (
            <GameNameTag
              key={key}
              onClose={(e) =>
                handleRemove({
                  event: e,
                  dataToRemove: item,
                  filterKey: 'game',
                })
              }
              id={item}
            />
          ))
        : null}
      {filters?.dateTimePlaced ? (
        <Tag
          closable
          onClose={(e) => {
            handleRemove({
              event: e,
              dataToRemove: filters?.dateTimePlaced,
              filterKey: 'dateTimePlaced',
            });

            setPresetValue(null);
          }}
        >
          Date Time Placed: {formatDate(filters.dateTimePlaced?.gte)} ~{' '}
          {formatDate(filters.dateTimePlaced?.lte)}
        </Tag>
      ) : null}
      {filters?.dateTimeSettled ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemove({
              event: e,
              dataToRemove: filters?.dateTimeSettled,
              filterKey: 'dateTimeSettled',
            })
          }
        >
          Date Time Settled: {formatDate(filters.dateTimeSettled?.gte)} ~{' '}
          {formatDate(filters.dateTimeSettled?.lte)}
        </Tag>
      ) : null}
      {filters?.dateTimeCanceled ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemove({
              event: e,
              dataToRemove: filters?.dateTimeCanceled,
              filterKey: 'dateTimeCanceled',
            })
          }
        >
          Date Time Canceled: {formatDate(filters.dateTimeCanceled?.gte)} ~{' '}
          {formatDate(filters.dateTimeCanceled?.lte)}
        </Tag>
      ) : null}
      {amountRangeFilters.map((filter, index) => {
        if (filters?.[filter.key]) {
          return (
            <Tag
              key={index}
              closable
              onClose={(e) =>
                handleRemove({
                  event: e,
                  dataToRemove: filters?.[filter.key],
                  filterKey: filter.key,
                })
              }
            >
              {filter.label}: {filters[filter.key].gte} -{' '}
              {filters[filter.key].lte}
            </Tag>
          );
        }

        return null;
      })}
      {filters.status ? (
        <>
          <Tag closable onClose={() => handleRemoveStatus()}>
            {capitalize(filters.status.eq)}
          </Tag>
        </>
      ) : null}
      {filters.freeSpin ? (
        <>
          <Tag closable onClose={() => handleRemoveStatus()}>
            {filters.freeSpin.eq ? 'Free Spin Yes' : 'Free Spin No'}
          </Tag>
        </>
      ) : null}
    </>
  );
};
