// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { StyledSelect } from 'styles';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { MessagesConnectionEdge } from 'types/graphqlTypes';
import { Select } from 'antd';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { MESSAGES } from './queries';

const { Option } = Select;
interface ITitleFilter {
  values: string[];
  setFilters: (e: { in: string[] } | null) => any;
}

const TitleFilter = ({ values, setFilters }: ITitleFilter) => {
  const [options, setOptions] = useState([]);

  const [input, setInput] = useState<string>('');
  const debouncedInput = useDebounce(input, 500);

  const [loadMessagesTitle, { called, loading }] = useLazyQuery(MESSAGES, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        title: {
          contains: debouncedInput,
        },
      },
    },
    onCompleted: (data) => {
      const {
        messages: { edges = [] },
      } = data;

      const messageTitles = edges.map(
        ({ node }: MessagesConnectionEdge) => node.title
      );

      setOptions(messageTitles);
    },
  });

  const handleChange = (e: string[]) => {
    if (e.length) {
      setFilters({ in: e });
    } else {
      setFilters(null);
    }

    setInput('');
  };

  if (!called) loadMessagesTitle();

  useEffect(() => {
    loadMessagesTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  return (
    <FilterItem label="Title" onClear={() => handleChange([])}>
      <StyledSelect
        mode="multiple"
        showSearch
        placeholder="Enter Title"
        value={values}
        onChange={handleChange}
        filterOption={false}
        allowClear
        loading={loading}
        onSearch={(text: string) => {
          setInput(text);
        }}
        onFocus={() => setInput('')}
        onBlur={() => setInput('')}
      >
        {options.map((title, index) => (
          <Option key={index} value={title}>
            {title}
          </Option>
        ))}
      </StyledSelect>
    </FilterItem>
  );
};

export default TitleFilter;
