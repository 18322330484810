/* eslint-disable no-underscore-dangle */
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { PAYMENT_METHODS_LIST } from 'constants/paymentTypes';
import { getWithdrawalMutation } from 'graphql/mutations/withdrawalMethod.mutation';
import { WITHDRAWAL_METHODS } from 'graphql/queries/withdrawalMethod.query';
import React from 'react';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';

type Rec = Record<string, any>;
type Props = {
  setRefetchID: (ad: string) => void;
  toggleModal: () => void;
  refetchVariables: Rec;
  withdrawalMethod: Rec;
};

const UpdateWithdrawalMethodStatus = ({
  setRefetchID,
  toggleModal,
  refetchVariables,
  withdrawalMethod,
}: Props) => {
  const { __typename, id } = withdrawalMethod;

  const translate = useTranslate();
  const { context } = useOperatorHeader();

  const [updateMethod, { loading }] = useMutation(
    getWithdrawalMutation(PAYMENT_METHODS_LIST[__typename], true),
    {
      refetchQueries: [
        {
          query: WITHDRAWAL_METHODS,
          variables: refetchVariables,
        },
      ],
      context,
      onCompleted: () => {
        setRefetchID(id);
        message.success(translate(messages.successfullyupdated));
        toggleModal();
      },
    }
  );

  const onConfirm = () =>
    updateMethod({
      variables: {
        id,
        input: {
          enabled: !withdrawalMethod.enabled,
        },
      },
    });

  return (
    <Modal
      visible
      maskClosable={false}
      closable={false}
      width={416}
      okText={translate(messages.confirm)}
      cancelText={translate(messages.cancel)}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={onConfirm}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">
          {translate(messages.confirm)}
          {/* {translate(messages[enabled ? 'disable': 'enable'])} */}
        </span>
        <div className="ant-modal-confirm-content">
          {translate(messages.areyousure)}
        </div>
      </div>
    </Modal>
  );
};

export default UpdateWithdrawalMethodStatus;
