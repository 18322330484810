/* eslint-disable no-restricted-syntax */
const removeEmptyArray = (obj: Record<string, any>) => {
  const emptyObj = { ...obj };
  for (const key in obj) {
    if (emptyObj[key] && Array(emptyObj[key]) && emptyObj[key].length === 0) {
      emptyObj[key] = null;
    }
  }

  return emptyObj;
};

export default removeEmptyArray;
