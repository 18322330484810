import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useMutation } from '@apollo/react-hooks';
import { Button, Input, message, Tag } from 'antd';
import cx from 'classnames';
import { useFormik } from 'formik';
import { UPDATE_AUTOMATED_MESSAGE } from 'graphql/mutations/automatedMessage.mutation';
import { AUTOMATED_MESSAGES } from 'graphql/queries/automatedMessage.query';
import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { customFormatMessage } from 'utils/customFormatMessage';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { messages as translations } from '../../messages';
import {
  EditContainer,
  StyledInput,
  StyledInputArea,
  StyledModal,
} from './styles';

type Props = {
  status: string;
  variables: string[];
  messages: any;
  refetchParams: any;
};

export const EditMessage: React.FC<Props> = ({
  status,
  variables,
  messages,
  refetchParams,
}) => {
  const [isShow, setShow] = useState(false);
  const [activeField, setActiveField] = useState('');
  const [isHoverField, setHoverField] = useState(false);
  const titleEnRef = useRef();
  const contentEnRef = useRef();
  const titleZhRef = useRef();
  const contentZhRef = useRef();
  const { formatMessage } = useIntl();
  const { context } = useOperatorHeader();

  const [updateAutomatedMessage, { loading }] = useMutation(
    UPDATE_AUTOMATED_MESSAGE,
    {
      context,
    }
  );

  const titleLimit = 50;
  const contentLimit = 300;

  const refs = {
    title_en: titleEnRef,
    content_en: contentEnRef,
    title_zh: titleZhRef,
    content_zh: contentZhRef,
  };

  refs[activeField]?.current?.focus();

  const handleShow = () => setShow(!isShow);
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  const formik = useFormik({
    initialValues: {
      title_en: messages.title_en,
      content_en: messages.content_en,
      title_zh: messages.title_zh,
      content_zh: messages.content_zh,
    },
    onSubmit: async (vals: any) => {
      try {
        await updateAutomatedMessage({
          variables: {
            id: messages.id,
            input: vals,
          },
          refetchQueries: [
            {
              query: AUTOMATED_MESSAGES,
              variables: refetchParams,
            },
          ],
        });

        message.success(translate(translations['edit-message-success.text']));

        handleShow();
        // eslint-disable-next-line no-empty
      } finally {
      }
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    resetForm,
  } = formik;

  return (
    <>
      <Button type="link" className="text-black" block onClick={handleShow}>
        <FormattedMessage id="edit.text" defaultMessage="Edit" />
      </Button>

      <StyledModal
        title={
          <span className="fs-16">
            <FormattedMessage
              id="edit-message.text"
              defaultMessage="Edit Message"
            />
          </span>
        }
        visible={isShow}
        width={400}
        onCancel={() => {
          resetForm();
          handleShow();
        }}
        style={{ padding: 0 }}
        footer={null}
      >
        <Form onSubmit={handleSubmit}>
          <EditContainer onClick={() => !isHoverField && setActiveField('')}>
            {/* EDIT COLUMNN 1 - Message */}
            <div className="column-1">
              <div className="grid">
                {/* GRID COLUMN 1 */}
                <div className="item-1">
                  <div className="status">
                    <FormattedMessage
                      id="status.text"
                      defaultMessage="Status"
                    />
                  </div>

                  <Input placeholder={status} disabled />
                </div>

                <div className="item-2">
                  <div className="language-title">
                    <FormattedMessage
                      id="english.text"
                      defaultMessage="English"
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <span className="status">
                      <FormattedMessage
                        id="title.text"
                        defaultMessage="Title"
                      />
                    </span>

                    <span
                      className={`status ${cx({
                        'text-black': values.title_en.length <= titleLimit,
                        'text-danger': values.title_en.length > titleLimit,
                      })}`}
                    >
                      {`${values.title_en.length}/${titleLimit} `}
                      <FormattedMessage
                        id="characters.text"
                        defaultMessage="Characters"
                      />
                    </span>
                  </div>

                  <StyledInput
                    isActive={activeField === 'title_en'}
                    id="title_en"
                    name="title_en"
                    type="text"
                    onChange={handleChange}
                    value={values.title_en}
                    onFocus={() => setActiveField('title_en')}
                    onMouseEnter={() => setHoverField(true)}
                    onMouseLeave={() => setHoverField(false)}
                    ref={titleEnRef}
                  />

                  <br />
                  <br />

                  <div className="d-flex justify-content-between">
                    <span className="status">
                      <FormattedMessage
                        id="content.text"
                        defaultMessage="Content"
                      />
                    </span>

                    <span
                      className={`status ${cx({
                        'text-black': values.content_en.length <= contentLimit,
                        'text-danger': values.content_en.length > contentLimit,
                      })}`}
                    >
                      {`${values.content_en.length}/${contentLimit} `}

                      <FormattedMessage
                        id="characters.text"
                        defaultMessage="Characters"
                      />
                    </span>
                  </div>

                  <StyledInputArea
                    isActive={activeField === 'content_en'}
                    rows={4}
                    id="content_en"
                    name="content_en"
                    onChange={handleChange}
                    value={values.content_en}
                    onFocus={() => setActiveField('content_en')}
                    ref={contentEnRef}
                    onMouseEnter={() => setHoverField(true)}
                    onMouseLeave={() => setHoverField(false)}
                  />
                </div>

                <div className="item-3">
                  {/* <div className="status apply-language">
                    <FormattedMessage
                      id="apply-english-content-to-other-languages.text"
                      defaultMessage="Apply English content to other languages"
                    />
                  </div>

                  <Switch className="mt-2" toggle /> */}
                </div>

                <div className="item-6" />
              </div>

              <div className="footer-buttons">
                <Button
                  onClick={() => {
                    resetForm();
                    handleShow();
                  }}
                >
                  <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={
                    values.title_en.length > titleLimit ||
                    values.title_zh.length > titleLimit ||
                    values.content_en.length > contentLimit ||
                    values.content_zh.length > contentLimit
                  }
                >
                  <FormattedMessage
                    id="Confirm.text"
                    defaultMessage="Confirm"
                  />
                </Button>
              </div>
            </div>

            {/* EDIT COLUMN 2 - Variables */}
            <div className="column-2">
              <div className="status">Variable</div>

              <div className="variable mt-1">
                {variables.map((variable, index) => (
                  <Tag
                    key={index}
                    onClick={() => {
                      if (activeField) {
                        setFieldValue(
                          activeField,
                          `${values[activeField]}$${variable}`
                        );

                        setTimeout(() => {
                          refs[activeField].current.blur();
                          refs[activeField].current.focus();
                        });
                      }
                    }}
                    onMouseEnter={() => setHoverField(true)}
                    onMouseLeave={() => setHoverField(false)}
                  >
                    ${variable}
                  </Tag>
                ))}
              </div>
            </div>
          </EditContainer>
        </Form>
      </StyledModal>
    </>
  );
};
