import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

type MethodProps = {
  selectedMethod: string;
};
type PaymentMethodProps = {
  paymentList?: Array<{ label: string; value: string }>;
};
type PaymentMethodTypeProps = {
  paymentMethodType?: Array<{ label: string; value: string }>;
};
type MethodState = {
  method: MethodProps;
};
type PaymentMethodState = {
  paymentListMethod: PaymentMethodProps;
};
type PaymentMethodTypeState = {
  paymentMethodTypeMethod: PaymentMethodTypeProps;
};
export const methodSlice = createSlice({
  name: 'method',
  initialState: {
    selectedMethod: 'CARD',
  },
  reducers: {
    setSelectedMethod: (state, { payload }: { payload: string }) => ({
      ...state,
      selectedMethod: payload,
    }),
    setPaymentList: (
      state,
      { payload }: { payload: Array<{ label: string; value: string }> }
    ) => ({
      ...state,
      paymentList: payload,
    }),
    setPaymentMethodType: (
      state,
      {
        payload,
      }: { payload: Array<{ label?: string; value?: string }> | undefined }
    ) => ({
      ...state,
      paymentMethodType: payload,
    }),
  },
});

export const useMethod = () => {
  const { actions } = methodSlice;
  const dispatch = useDispatch();
  const method = useSelector((state: MethodState) => state.method);
  const paymentListMethod = useSelector(
    (state: PaymentMethodState) => state.paymentListMethod
  );
  const paymentMethodTypeMethod = useSelector(
    (state: PaymentMethodTypeState) => state.paymentMethodTypeMethod
  );
  return {
    setSelectedMethod: useCallback(
      (payload: string) => dispatch(actions.setSelectedMethod(payload)),
      [actions, dispatch]
    ),
    setPaymentList: useCallback(
      (payload: Array<{ label: string; value: string }>) =>
        dispatch(actions.setPaymentList(payload)),
      [actions, dispatch]
    ),
    setPaymentMethodType: useCallback(
      (payload: Array<{ label?: string; value?: string }> | undefined) =>
        dispatch(actions.setPaymentMethodType(payload)),
      [actions, dispatch]
    ),
    method,
    paymentListMethod,
    paymentMethodTypeMethod,
  };
};

export const methodReducer = methodSlice.reducer;
