import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import messages from '../messages';
import { StyledRootDiv } from './styles';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  width: 300px;
`;

const StyledParentDiv = styled.div<any>`
  width: ${(props) => `${300 * props.options.second.length}px`};
  padding-bottom: 100px;
`;

const PromosTree = () => {
  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const [checked, setChecked] = useState<Record<string, any>>({
    all: {
      first: false,
      PROMOS_PROMO_LISTING: false,
      PROMOS_PROMO_REQUEST: false,
      PROMOS_PROMO_LABELS: false,
      PROMOS_PROMO_LISTING_PROMO_LIST: false,
      PROMOS_PROMO_LISTING_CATEGORY_LIST: false,
      PROMOS_PROMO_REQUEST_LIST: false,
      PROMOS_PROMO_LABELS_LIST: false,
    },
    indeterminate: {
      first: false,
      PROMOS_PROMO_LISTING: false,
      PROMOS_PROMO_LISTING_PROMO_LIST: false,
      PROMOS_PROMO_LISTING_CATEGORY_LIST: false,
      PROMOS_PROMO_REQUEST: false,
      PROMOS_PROMO_LABELS: false,
      PROMOS_PROMO_LABELS_LIST: false,
    },
    currentList: {
      PROMOS_PROMO_LISTING_PROMO_LIST_GRP: [],
      PROMOS_PROMO_LISTING_CATEGORY_LIST_GRP: [],
      PROMOS_PROMO_REQUEST_GRP: [],
      PROMOS_PROMO_LABELS_GRP: [],
    },
  });

  const defaultValues = Object.values(ALL_PERMISSIONS.ALL_PROMOS);

  const levels = [
    ['first', 0],
    ['second', 1],
    ['third', 2],
  ];

  const options: Record<string, any> = levels.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr[0]}`]: Object.entries(ALL_PERMISSIONS.ALL_PROMOS)
        .filter(
          (e) =>
            (e[1].match(/:/g) || []).length ===
            (curr[0] === 'third' && e[1].includes('PROMO_LISTING')
              ? 3
              : curr[1])
        )
        .map((e) => e[0]),
    }),
    {}
  );

  const handleFirstLevelChange = (e: Record<string, any>) => {
    const list = e.target.checked ? defaultValues : [];
    setFieldValue('permissions[ALL_PROMOS]', list);
  };

  const handleSecondLevelChange = (e: Record<string, any>) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_PROMOS];
    const secondLevelValues = defaultValues.filter(
      (v) =>
        (v.match(/:/g) || []).length >= 1 &&
        v.includes(ALL_PERMISSIONS.ALL_PROMOS[name])
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, ...secondLevelValues];
    } else {
      list = targetPermissions.filter((p) => !secondLevelValues.includes(p));
    }

    setFieldValue(
      'permissions[ALL_PROMOS]',
      uniq(list.length > 1 ? ['PROMOS', ...list] : [])
    );
  };

  const handleMidSecondLevelChange = (e: Record<string, any>) => {
    const { name } = e.target;
    const lookup = `PROMOS:PROMO_LISTING:${
      name.replace('_LIST', '').includes('CATEGORY') ? 'CATEGORY' : 'PROMO'
    }`;

    const targetPermissions = [...values.permissions.ALL_PROMOS];
    const secondLevelValues = defaultValues.filter((v) => v.includes(lookup));

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, ...secondLevelValues];
    } else {
      list = targetPermissions.filter((p) => !secondLevelValues.includes(p));
    }

    setFieldValue(
      'permissions[ALL_PROMOS]',
      uniq(list.length > 2 ? ['PROMOS', ...list] : [])
    );
  };

  const handleThirdLevelChange = (e: Record<string, any>) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_PROMOS];

    const unlistedName = ALL_PERMISSIONS.ALL_PROMOS[name.replace('_LIST', '')];

    const thirdLevelValues = defaultValues.filter(
      (v) => (v.match(/:/g) || []).length >= 2 && v.includes(unlistedName)
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, unlistedName, ...thirdLevelValues];
    } else {
      list = targetPermissions.filter(
        (p) => !thirdLevelValues.includes(p) && p !== unlistedName
      );
    }

    setFieldValue(
      'permissions[ALL_PROMOS]',
      uniq(list.length >= 3 ? ['PROMOS', ...list] : [])
    );
  };

  const getPromoLookup = (contains: any, name: string) => {
    if (!contains) return contains;
    return name.includes('CATEGORY')
      ? ALL_PERMISSIONS.ALL_PROMOS.PROMOS_PROMO_LISTING_CATEGORY_LIST
      : ALL_PERMISSIONS.ALL_PROMOS.PROMOS_PROMO_LISTING_PROMO_LIST;
  };

  const handleOnChange = (list: any[], name: string) => {
    const stateName = name.replace('_GRP', '');
    const hasPromoListing = stateName.includes('PROMO_LISTING');

    const secondLevelPermissionLookup = hasPromoListing
      ? ALL_PERMISSIONS.ALL_PROMOS.PROMOS_PROMO_LISTING
      : ALL_PERMISSIONS.ALL_PROMOS[stateName];

    const thirdLevelPermissionLookup =
      getPromoLookup(hasPromoListing, stateName) ||
      ALL_PERMISSIONS.ALL_PROMOS[`${stateName}_LIST`];

    const hasCategory =
      thirdLevelPermissionLookup ===
      ALL_PERMISSIONS.ALL_PROMOS.PROMOS_PROMO_LISTING_CATEGORY_LIST;

    const thirdLevelPermissions = Object.values(
      ALL_PERMISSIONS.ALL_PROMOS
    ).filter(
      (p) => p.includes('PROMO_LISTING:') || (p.match(/:/g) || []).length === 2
    );

    const targetPermissions = [...values.permissions.ALL_PROMOS];

    let templatePermissions: any = [];

    if (hasPromoListing) {
      templatePermissions = thirdLevelPermissions.filter(
        (p) =>
          p.includes(secondLevelPermissionLookup) &&
          p !== secondLevelPermissionLookup &&
          p !== thirdLevelPermissionLookup &&
          (hasCategory ? p.includes('CATEGORY') : !p.includes('CATEGORY'))
      );
    } else {
      templatePermissions = thirdLevelPermissions.filter(
        (p) =>
          p.includes(secondLevelPermissionLookup) &&
          p !== secondLevelPermissionLookup &&
          p !== thirdLevelPermissionLookup
      );
    }

    setFieldValue(
      'permissions[ALL_PROMOS]',
      uniq([
        ...targetPermissions.filter((p) => !templatePermissions.includes(p)),
        'PROMOS',
        secondLevelPermissionLookup,
        thirdLevelPermissionLookup,
        ...list,
      ])
    );
  };

  const availablePermissions = coercedGet(values, 'permissions.ALL_PROMOS', []);

  useEffect(() => {
    const permissionMatch =
      availablePermissions.length ===
      Object.values(ALL_PERMISSIONS.ALL_PROMOS).length;

    const thirdLevelPermissions = availablePermissions.filter(
      (p: any) =>
        p.includes('PROMO_LISTING:') || (p.match(/:/g) || []).length === 2
    );

    const secondLevelKeys = [
      ['PROMOS_PROMO_LISTING', 'PROMOS:PROMO_LISTING', 11],
      ['PROMOS_PROMO_REQUEST', 'PROMOS:PROMO_REQUEST', 5],
      ['PROMOS_PROMO_LABELS', 'PROMOS:PROMO_LABELS', 4],
    ];

    const thirdLevelChecks: Record<string, any> = secondLevelKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr[0]}`]:
          thirdLevelPermissions.filter((p: any) => p.includes(`${curr[1]}`))
            .length === curr[2],
      }),
      {}
    );

    const allChecks = {
      PROMOS_PROMO_LISTING:
        permissionMatch || thirdLevelChecks.PROMOS_PROMO_LISTING,
      PROMOS_PROMO_REQUEST:
        permissionMatch || thirdLevelChecks.PROMOS_PROMO_REQUEST,
      PROMOS_PROMO_LABELS:
        permissionMatch || thirdLevelChecks.PROMOS_PROMO_LABELS,
      PROMOS_PROMO_LISTING_PROMO_LIST:
        permissionMatch ||
        thirdLevelPermissions.some((p: any) =>
          p.includes('PROMOS:PROMO_LISTING:PROMO')
        ),
      PROMOS_PROMO_LISTING_CATEGORY_LIST:
        permissionMatch ||
        thirdLevelPermissions.some((p: any) =>
          p.includes('PROMOS:PROMO_LISTING:CATEGORY')
        ),
      PROMOS_PROMO_LABELS_LIST:
        permissionMatch ||
        thirdLevelPermissions.some((p: any) =>
          p.includes('PROMOS:PROMO_LABELS:LIST')
        ),
    };

    setChecked({
      all: {
        first: permissionMatch,
        PROMOS_PROMO_LISTING: allChecks.PROMOS_PROMO_LISTING,
        PROMOS_PROMO_LISTING_PROMO_LIST:
          allChecks.PROMOS_PROMO_LISTING_PROMO_LIST,
        PROMOS_PROMO_LISTING_CATEGORY_LIST:
          allChecks.PROMOS_PROMO_LISTING_CATEGORY_LIST,
        PROMOS_PROMO_REQUEST: allChecks.PROMOS_PROMO_REQUEST,
        PROMOS_PROMO_LABELS: allChecks.PROMOS_PROMO_LABELS,
        PROMOS_PROMO_REQUEST_LIST:
          thirdLevelPermissions.includes('PROMOS:PROMO_REQUEST:LIST') ||
          allChecks.PROMOS_PROMO_REQUEST,
        PROMOS_PROMO_LABELS_LIST: allChecks.PROMOS_PROMO_LABELS_LIST,
      },
      indeterminate: {
        first: availablePermissions.length && !permissionMatch,
        PROMOS_PROMO_LISTING:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('PROMOS:PROMO_LISTING')
          ).length && !thirdLevelChecks.PROMOS_PROMO_LISTING,
        PROMOS_PROMO_REQUEST:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('PROMOS:PROMO_REQUEST')
          ).length && !thirdLevelChecks.PROMOS_PROMO_REQUEST,

        PROMOS_PROMO_LABELS:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('PROMOS:PROMO_LABELS')
          ).length && !thirdLevelChecks.PROMOS_PROMO_LABELS,
      },
      currentList: {
        PROMOS_PROMO_LISTING_PROMO_LIST_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('PROMOS:PROMO_LISTING:PROMO') &&
            p !== 'PROMOS:PROMO_LISTING' &&
            p !== 'PROMOS:PROMO_LISTING:PROMO'
        ),
        PROMOS_PROMO_LISTING_CATEGORY_LIST_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('PROMOS:PROMO_LISTING:CATEGORY') &&
            p !== 'PROMOS:PROMO_LISTING' &&
            p !== 'PROMOS:PROMO_LISTING:CATEGORY'
        ),
        PROMOS_PROMO_REQUEST_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('PROMOS:PROMO_REQUEST') &&
            p !== 'PROMOS:PROMO_REQUEST' &&
            p !== 'PROMOS:PROMO_REQUEST:LIST'
        ),
        PROMOS_PROMO_LABELS_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('PROMOS:PROMO_LABELS') &&
            p !== 'PROMOS:PROMO_LABELS' &&
            p !== 'PROMOS:PROMO_LABELS:LIST'
        ),
      },
    });
  }, [availablePermissions]);

  const promoListingItems = ['PROMOS', 'CATEGORY'];
  const translate = useTranslate();
  return (
    <StyledRootDiv>
      <StyledParentDiv options={options}>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={checked.all.first}
              indeterminate={checked.indeterminate.first}
              onChange={handleFirstLevelChange}
            >
              <StyledText size={16}>
                {translate(messages[ALL_PERMISSIONS.ALL_PROMOS.PROMOS])}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <div className="w-100">
          <div className="mt-4 d-flex ml-3">
            {options.second.map((s: any) => (
              <StyledDiv>
                <Checkbox
                  key={s}
                  name={s}
                  checked={checked.all[s]}
                  indeterminate={checked.indeterminate[s]}
                  onChange={handleSecondLevelChange}
                >
                  <StyledText size={14}>{translate(messages[s])}</StyledText>
                </Checkbox>
                {s === 'PROMOS_PROMO_LISTING' ? (
                  <div className="ml-4 mt-2">
                    {promoListingItems.map((item, index) => {
                      const componentName = `${s}_${item.replace(
                        'S',
                        ''
                      )}_LIST`;
                      return (
                        <div className={`${index === 0 && 'mb-2'}`}>
                          <Checkbox
                            key={componentName}
                            name={componentName}
                            checked={checked.all[componentName]}
                            indeterminate={checked.indeterminate[componentName]}
                            onChange={handleMidSecondLevelChange}
                          >
                            <StyledText size={14}>
                              {translate(messages[item])}
                            </StyledText>
                          </Checkbox>
                          <div className="ml-4 mt-2">
                            <Checkbox
                              key={componentName}
                              name={componentName}
                              checked={checked.all[componentName]}
                              onChange={handleMidSecondLevelChange}
                            >
                              <StyledText size={14}>
                                {translate(messages.LIST)}
                              </StyledText>
                            </Checkbox>
                            <Checkbox.Group
                              className="w-100 ml-2"
                              onChange={(v) =>
                                handleOnChange(v, `${componentName}_GRP`)
                              }
                              value={
                                checked.currentList[`${componentName}_GRP`]
                              }
                            >
                              <div className="ml-4">
                                {options.third
                                  .filter(
                                    (t: any, idx: number) =>
                                      t.includes(
                                        `${s}_${item.replace('S', '')}_`
                                      ) && idx !== (item === 'PROMOS' ? 0 : 7)
                                  )
                                  .map((t: any) => (
                                    <StyledDiv className="mt-2">
                                      <Checkbox
                                        key={ALL_PERMISSIONS.ALL_PROMOS[t]}
                                        value={ALL_PERMISSIONS.ALL_PROMOS[t]}
                                      >
                                        <StyledText size={14}>
                                          {translate(
                                            messages[
                                              t.replace(
                                                `${s}_${item.replace(
                                                  'S',
                                                  ''
                                                )}_`,
                                                ''
                                              )
                                            ]
                                          )}
                                        </StyledText>
                                      </Checkbox>
                                    </StyledDiv>
                                  ))}
                              </div>
                            </Checkbox.Group>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="ml-4 mt-2">
                    <Checkbox
                      key={`${s}_LIST`}
                      name={`${s}_LIST`}
                      checked={checked.all[`${s}_LIST`]}
                      onChange={handleThirdLevelChange}
                    >
                      <StyledText size={14}>
                        {translate(messages.LIST)}
                      </StyledText>
                    </Checkbox>
                    <Checkbox.Group
                      className="w-100 ml-2"
                      onChange={(v) => handleOnChange(v, `${s}_GRP`)}
                      value={checked.currentList[`${s}_GRP`]}
                    >
                      <div className="ml-4">
                        {options.third
                          .filter(
                            (t: string, idx: number) =>
                              t.includes(s) && idx !== 11 && idx !== 16
                          )
                          .map((t: string) => (
                            <StyledDiv className="mt-2">
                              <Checkbox
                                key={ALL_PERMISSIONS.ALL_PROMOS[t]}
                                value={ALL_PERMISSIONS.ALL_PROMOS[t]}
                              >
                                <StyledText size={14}>
                                  {translate(messages[t.replace(`${s}_`, '')])}
                                </StyledText>
                              </Checkbox>
                            </StyledDiv>
                          ))}
                      </div>
                    </Checkbox.Group>
                  </div>
                )}
              </StyledDiv>
            ))}
          </div>
        </div>
      </StyledParentDiv>
    </StyledRootDiv>
  );
};

export default PromosTree;
