import React, { useCallback, useMemo } from 'react';
import { GraphQLResponse } from '../../../interfaces/graphql.interface';
import {
  Mission,
  MissionMemberLoyaltyGroup,
  MissionMilestone,
} from '../../../types/graphqlTypes';

export type GetAllValuesMission = {
  generalSettingsValue: Record<string, any>;
  taskRewardValue: Record<string, any>;
  milestonesValue: {
    milestonesEnabled: boolean;
    milestones: MissionMilestone[];
  };
  vipGroupsValue: {
    memberLoyaltyGroups: MissionMemberLoyaltyGroup[];
  };
};

type ContextType = {
  selectedNewMission: string;
  setSelectedNewMission: (value: string) => void;
  stepCurrent: number;
  setStepCurrent: (value: number) => void;
  onNextStep: (step?: number) => void;
  onPrevStep: (step?: number) => void;
  resetDrawer: () => void;
  getAllValues: () => GetAllValuesMission;
  generalSettingsValue: Record<string, any>;
  setGeneralSettingsValue: (data: Record<string, any>) => void;
  taskRewardValue: Record<string, any>;
  setTaskRewardValue: (data: Record<string, any>) => void;
  milestonesValue: {
    milestonesEnabled: boolean;
    milestones: Record<string, any>[];
  };
  setMilestonesValue: (data: Record<string, any>) => void;
  vipGroupsValue: {
    memberLoyaltyGroups: Record<string, any>[];
  };
  setVipGroupsValue: (data: {
    memberLoyaltyGroups: Record<string, any>[];
  }) => void;
  hideMilestone: boolean;
  setHideMilestone: (e: boolean) => void;
  loading: boolean;
  error: any;
  data: GraphQLResponse<Mission[]>;
};

const MissionsContext = React.createContext<ContextType>({
  selectedNewMission: '',
  setSelectedNewMission: () => {},
  stepCurrent: 0,
  setStepCurrent: () => {},
  onNextStep: () => {},
  onPrevStep: () => {},
  resetDrawer: () => {},
  generalSettingsValue: {},
  setGeneralSettingsValue: () => {},
  taskRewardValue: {},
  setTaskRewardValue: () => {},
  milestonesValue: {
    milestonesEnabled: true,
    milestones: [],
  },
  setMilestonesValue: () => {},
  vipGroupsValue: {
    memberLoyaltyGroups: [],
  },
  setVipGroupsValue: () => {},
  getAllValues: () => ({
    generalSettingsValue: {},
    taskRewardValue: {},
    milestonesValue: {
      milestonesEnabled: true,
      milestones: [],
    },
    vipGroupsValue: {
      memberLoyaltyGroups: [],
    },
  }),
  hideMilestone: false,
  setHideMilestone: () => {},
  loading: false,
  error: null,
  data: {
    totalCount: 0,
    pageInfo: {
      endCursor: '',
      hasNextPage: false,
    },
    edges: [],
  },
});

type Props = {
  children: any;
  loading: boolean;
  error: any;
  data: GraphQLResponse<Mission[]>;
};

export const MissionsProvider = ({ children, loading, error, data }: Props) => {
  const [selectedNewMission, setSelectedNewMission] = React.useState('');
  const [stepCurrent, setStepCurrent] = React.useState(0);
  const [generalSettingsValue, setGeneralSettingsValue] = React.useState({});
  const [taskRewardValue, setTaskRewardValue] = React.useState({});
  const [milestonesValue, setMilestonesValue] = React.useState({
    milestonesEnabled: true,
    milestones: [],
  });
  const [vipGroupsValue, setVipGroupsValue] = React.useState({
    memberLoyaltyGroups: [],
  });
  const [hideMilestone, setHideMilestone] = React.useState<boolean>(false);

  const onNextStep = (step?: number) => {
    setStepCurrent((prev) => step || prev + 1);
  };

  const onPrevStep = (step?: number) => {
    setStepCurrent((prev) => step || prev - 1);
  };

  const resetDrawer = () => {
    setStepCurrent(0);
    setSelectedNewMission('');
    setGeneralSettingsValue({});
    setTaskRewardValue({});
    setMilestonesValue({
      milestonesEnabled: true,
      milestones: [],
    });
    setVipGroupsValue({ memberLoyaltyGroups: [] });
    setHideMilestone(false);
  };

  const getAllValues = useCallback(
    () => ({
      generalSettingsValue,
      taskRewardValue,
      milestonesValue,
      vipGroupsValue,
    }),
    [generalSettingsValue, milestonesValue, taskRewardValue, vipGroupsValue]
  );
  const value = useMemo(
    () => ({
      selectedNewMission,
      setSelectedNewMission,
      stepCurrent,
      setStepCurrent,
      onNextStep,
      onPrevStep,
      resetDrawer,
      generalSettingsValue,
      setGeneralSettingsValue,
      taskRewardValue,
      setTaskRewardValue,
      milestonesValue,
      setMilestonesValue,
      vipGroupsValue,
      setVipGroupsValue,
      getAllValues,
      hideMilestone,
      setHideMilestone,
      loading,
      error,
      data,
    }),
    [
      selectedNewMission,
      stepCurrent,
      generalSettingsValue,
      taskRewardValue,
      milestonesValue,
      vipGroupsValue,
      getAllValues,
      hideMilestone,
      loading,
      error,
      data,
    ]
  );

  return (
    <MissionsContext.Provider value={value as any}>
      {children}
    </MissionsContext.Provider>
  );
};

export const useMissionsValue = () => React.useContext(MissionsContext);
