import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag, Popover } from 'antd';
import styled from 'styled-components';
import validateHexColor from 'utils/validateHexColor';
import { darken } from 'polished';
import { get } from 'lodash';
import { truncateProgramName } from 'utils/truncateProgramName';
import { MEMBER_LOYALTY_LEVEL } from './queries';

const StyledTag: any = styled(Tag)`
  margin: 4px;
  background: ${({ color }: { color: string }) => validateHexColor(color)};
  border-color: ${({ color, islight }: { color: string; islight: boolean }) =>
    islight ? validateHexColor(color) : darken(0.2, validateHexColor(color))};
  border-radius: 11px !important;
  color: ${({ islight }: { islight: boolean }) =>
    islight ? '#fff' : 'rgba(0, 0, 0, 0.65)'} !important;

  .anticon-close {
    color: ${({ islight }) =>
      islight ? '#fff' : 'rgba(0, 0, 0, 0.65)'} !important;
  }
`;

const MemberLoyaltyLevelTag = (props: {
  id: string;
  onClose: (e: any) => void;
  islight: boolean;
}) => {
  const { id, onClose, islight } = props;
  const { data = {}, loading } = useQuery(MEMBER_LOYALTY_LEVEL, {
    variables: {
      id,
    },
  });

  if (loading) {
    return (
      <Tag>
        <LoadingOutlined />
      </Tag>
    );
  }

  const { memberLoyaltyLevel = {} }: Record<string, any> = data;
  const programName = get(memberLoyaltyLevel, 'programme.name');
  const loyaltyName = get(memberLoyaltyLevel, 'name', id);
  const { text, isHoverable } = truncateProgramName(programName);

  return (
    <>
      <Popover
        {...(!isHoverable && { visible: false })}
        content={
          <div>
            {programName} - {loyaltyName}
          </div>
        }
      >
        <StyledTag
          color={memberLoyaltyLevel && memberLoyaltyLevel.color}
          closable
          islight={islight.toString()}
          onClose={(e: any) => onClose(e)}
          style={{ margin: '4px' }}
        >
          {text} - {loyaltyName}
        </StyledTag>
      </Popover>
    </>
  );
};

export default MemberLoyaltyLevelTag;
