import React, { useEffect, useCallback, useState } from 'react';
import db from 'utils/idb';
import Storage from 'constants/storage';
import { notification } from 'antd';
import Worker from 'worker';
import useTranslate from 'utils/useTranslate';
import navMessages from 'constants/navMessages';
import messages from 'messages';
import { TabId } from 'types/nav';
import { DocumentNode } from 'graphql';
import useCsvHeaders from 'hooks/useCsvHeaders';

const { config } = window;
const ENTRIES_PER_BATCH = 2;
const TABLE_NAME = 'downloadCsv';

const getAllCsv = async () => {
  const result = await db.table(TABLE_NAME).toArray();

  return result;
};

const getCsvData = async (id: string) => {
  const result = await db
    .table(TABLE_NAME)
    .where('id')
    .equals(id)
    .toArray();

  return result.length ? result[0] : null;
};

const setCsvData = async (data: any) => {
  const result = await db.table(TABLE_NAME).put(data, data.id);

  return result;
};

// const removeCsvData = async (id: string) => {
//   await db.table(TABLE_NAME).delete(id);
// };

type Props = {
  getCsvState: (tabId: TabId) => object;
  newDownload: (options: Options) => void;
  retryDownload: (options: Options) => void;
  initialCsvState: object;
};

type Options = {
  tabId: TabId;
  edgesPath: string;
  query: DocumentNode;
  filter: {
    [x: string]: any;
  };
};

type DownloadCsvProviderProps = {
  children: React.ReactNode;
};

export const CsvContext = React.createContext<Props>({
  getCsvState: () => ({}),
  newDownload: () => {},
  retryDownload: () => {},
  initialCsvState: {},
});

const DownloadCsvProvider: React.FC<DownloadCsvProviderProps> = ({
  children,
}) => {
  const [csvWorker] = useState(() => new Worker());
  const translate = useTranslate();
  const { getCsvHeaders } = useCsvHeaders();
  const initialCsvState = {
    id: '',
    query: '',
    queryName: '',
    edgesPath: '',
    operationName: '',
    records: [],
    totalCount: 0,
    fetchState: {
      loading: false,
      error: false,
    },
    variables: {
      first: ENTRIES_PER_BATCH,
      after: undefined,
      filter: {},
    },
  };

  const runDownloadCsvWorker = useCallback(
    async (tabId: TabId) => {
      const params = {
        id: tabId,
        token: localStorage.getItem(Storage.BO_ACCESS_TOKEN) || '',
        API: config.apiUrl,
      };

      const result = await csvWorker.download(params);

      if (result === 'SUCCESS') {
        notification.success({
          message: `${translate(navMessages[`${tabId}.text`])} CSV`,
          description: translate(messages.CSV_IS_AVAILABLE_FOR_DOWNLOAD),
          duration: 3,
        });
      } else {
        notification.error({
          message: `${translate(navMessages[`${tabId}.text`])} CSV`,
          description: translate(messages.ERROR_IN_GENERATING_CSV_FILE),
          duration: 3,
        });
      }
    },
    [csvWorker, translate]
  );

  const resumeAllDownload = useCallback(async () => {
    const allCsv = await getAllCsv();
    if (allCsv?.length) {
      allCsv.forEach((result: any) => {
        const { fetchState, id } = result;
        if (fetchState.loading) {
          runDownloadCsvWorker(id);
        }
      });
    }
  }, [runDownloadCsvWorker]);

  const newDownload = async (options: Options) => {
    const { query, edgesPath, tabId, filter }: any = options;
    if (options) {
      await setCsvData({
        ...initialCsvState,
        id: options.tabId,
        variables: {
          ...initialCsvState.variables,
          filter,
        },
        edgesPath,
        query: query?.loc?.source?.body || '',
        queryName: edgesPath.split('.')[0],
        operationName: query?.definitions[0]?.name?.value,
      });
    }
    return runDownloadCsvWorker(tabId);
  };

  const retryDownload = async (options: Options) => {
    const { tabId } = options;
    const result = await getCsvData(options.tabId);

    if (result) {
      const { fetchState } = result;
      await setCsvData({
        ...result,
        fetchState: {
          ...fetchState,
          loading: true,
          error: false,
        },
      });
      runDownloadCsvWorker(tabId);
    } else {
      newDownload(options);
    }
  };

  const getCsvState = async (tabId: TabId) => {
    const result = await getCsvData(tabId);
    const csvHeaders = getCsvHeaders(tabId);

    if (!result) {
      return null;
    }

    const { records } = result;
    const pipeLine = csvHeaders.filter(
      (item: any) => item.renderCell && typeof item.renderCell === 'function'
    );

    if (pipeLine.length > 0) {
      const getProcessedTableData = (): any =>
        records.map((node: any) => {
          if (pipeLine && pipeLine.length > 0) {
            const processedNode = pipeLine.reduce(
              (acc: any, curr: any) => ({
                ...acc,
                [curr && curr.key]: curr && curr.renderCell(acc),
              }),
              node
            );
            return processedNode;
          }

          return node;
        });

      result.records = getProcessedTableData();
    }

    return result;
  };

  useEffect(() => {
    resumeAllDownload();

    return () => {
      csvWorker.terminate();
    };
  }, [csvWorker, resumeAllDownload]);

  return (
    <CsvContext.Provider
      value={{
        getCsvState,
        newDownload,
        retryDownload,
        initialCsvState,
      }}
    >
      {children}
    </CsvContext.Provider>
  );
};

export default DownloadCsvProvider;

export const useDownloadCsv = () => React.useContext<any | null>(CsvContext);
