import * as React from 'react';
import { isEmpty } from 'lodash';
import { Divider, Popover, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { truncateProgramName } from 'utils/truncateProgramName';
import { findColorByBgColor } from '../../../CreateNewMail/styles';
import {
  ISystemMemberLevels,
  ISystemMemberLoyaltyLevels,
} from '../../../../interfaces';

export const memberLevelsFnTag = (memberLevel: ISystemMemberLevels) => {
  const color = findColorByBgColor(memberLevel.color) || {
    borderColor: '#fafafa',
    bgColor: 'gray',
  };

  return (
    <Tag
      className="mb-1"
      color={color!.bgColor}
      style={{ borderColor: color!.borderColor, borderRadius: '20px' }}
    >
      {memberLevel.name}
    </Tag>
  );
};

export const memberLoyaltyLevelsFnTag = (
  loyalty: ISystemMemberLoyaltyLevels
) => {
  const { text, isHoverable } = truncateProgramName(loyalty.programme.name);

  return (
    <Popover
      {...(!isHoverable && { visible: false })}
      content={
        <div>
          {loyalty.programme.name} - {loyalty.name}
        </div>
      }
    >
      <Tag
        className="mb-1"
        color={loyalty.color}
        style={{ borderColor: loyalty.color, borderRadius: '20px' }}
      >
        {text} - {loyalty.name}
      </Tag>
    </Popover>
  );
};

export const MessageRowRenderer = (data: any) => (
  <div>
    <div className="p-3">
      <span className="mr-2">Qualifying Members:</span>
      {data.targetMembers.map((member: any) => (
        <Tag>{member.username}</Tag>
      ))}
      {data.targetMemberLevels.length > 0 &&
        data.targetMemberLevels.map(memberLevelsFnTag)}

      {data.targetMemberLoyaltyLevels.map(memberLoyaltyLevelsFnTag)}

      {isEmpty(data.targetMemberLevels) &&
        isEmpty(data.targetMemberLoyaltyLevels) &&
        isEmpty(data.targetMembers) && (
          <strong>
            <FormattedMessage
              id="whole-site-members.text"
              defaultMessage="Whole Site Members"
            />
          </strong>
        )}
    </div>
    <Divider dashed className="my-1" />
    <div className="p-3">
      <span className="mr-2">Excluded Members:</span>
      {data.excludedMembers.map((member: any) => (
        <Tag>{member.username}</Tag>
      ))}
      {data.excludedMemberLevels.map(memberLevelsFnTag)}

      {data.excludedMemberLoyaltyLevels.map(memberLoyaltyLevelsFnTag)}

      {isEmpty(data.excludedMemberLevels) &&
        isEmpty(data.excludedMemberLoyaltyLevels) &&
        isEmpty(data.excludedMembers) && (
          <strong>
            <FormattedMessage id="none.text" defaultMessage="None" />
          </strong>
        )}
    </div>
  </div>
);
