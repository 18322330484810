import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Formik } from 'formik';
import thirdPartyProviders, {
  thirdPartyTypeNames,
} from 'constants/thirdPartyProviders';
import coercedGet from 'utils/coercedGet';
import FormInputs from './FormInputs';
import validationSchema from './validationSchema';

type Rec = Record<string, any>;

const { DIORPAY, MIDPAY, MIDPAY_DIRECT, DIORPAY2 } = thirdPartyProviders;
const { diorPay, midPay, midPayDirect, diorPay2 } = thirdPartyTypeNames;

const ProviderForm = (props: {
  submitDepositProvider: (e: Rec) => void;
  onClose: () => void;
  depositProvider: any;
  editable?: boolean;
  loading: boolean;
}) => {
  const {
    submitDepositProvider,
    onClose,
    depositProvider,
    editable,
    loading,
  } = props;

  const getInitialValues = (depositProviderInfo: Rec) => {
    const { name, __typename } = depositProviderInfo;
    let initialValues: Rec = { name };

    if (__typename === diorPay) {
      initialValues = {
        ...initialValues,
        type: DIORPAY,
        key: depositProviderInfo[editable ? 'key' : 'keyPreview'],
        merchantCode: depositProviderInfo.merchantCode,
      };
    } else if (__typename === midPay) {
      initialValues = {
        ...initialValues,
        type: MIDPAY,
        key: depositProviderInfo[editable ? 'apiKey' : 'apiKeyPreview'],
      };
    } else if (__typename === midPayDirect) {
      initialValues = {
        ...initialValues,
        type: MIDPAY_DIRECT,
        midpayDirectApiKey:
          depositProviderInfo[editable ? 'apiKey' : 'apiKeyPreview'],
        paymentGateway:
          depositProviderInfo.midPayDirectPaymentGateway[
            editable ? 'id' : 'name'
          ],
        credentials: depositProviderInfo.credentialValues,
      };
    } else if (__typename === diorPay2) {
      initialValues = {
        ...initialValues,
        type: DIORPAY2,
        encryptionKey: depositProviderInfo.encryptionKey,
        merchantId: depositProviderInfo.merchantId,
        partner: depositProviderInfo.partner,
      };
    }

    return initialValues;
  };

  const onFinalSubmit = async (
    values: Rec,
    { resetForm }: { resetForm: () => void }
  ) => {
    const variables: Rec = {
      input: {
        name: values.name,
      },
      type: values.type,
    };

    if (values.type === DIORPAY) {
      variables.input = {
        ...variables.input,
        key: values.key,
        merchantCode: values.merchantCode,
        // temporary value to fix the test
        midPayDirectPaymentGateway: '12',
        credentialValues: ['test'],
      };
    } else if (values.type === MIDPAY) {
      variables.input = {
        ...variables.input,
        apiKey: values.key,
        // temporary value to fix the test
        midPayDirectPaymentGateway: '12',
        credentialValues: ['test'],
      };
    } else if (values.type === MIDPAY_DIRECT) {
      variables.input = {
        ...variables.input,
        apiKey: values.midpayDirectApiKey,
        midPayDirectPaymentGateway: values.paymentGateway,
        credentialValues: values.credentials,
      };
    } else if (values.type === DIORPAY2) {
      variables.input = {
        ...variables.input,
        encryptionKey: values.encryptionKey,
        merchantId: values.merchantId,
        partner: values.partner,
      };
    }

    if (depositProvider?.id) {
      variables.id = depositProvider.id;
      delete variables.type;
    }

    const response = (await submitDepositProvider({
      variables,
    })) as any;

    if (response && response.data) {
      resetForm();
      onClose();
    }
  };

  const initCredentialFields = coercedGet(
    depositProvider as Rec,
    'midPayDirectPaymentGateway.credentialFields',
    []
  );

  return (
    <Formik
      initialValues={
        depositProvider && getInitialValues(depositProvider as Rec)
      }
      validationSchema={validationSchema()}
      onSubmit={onFinalSubmit}
    >
      <FormInputs
        id={depositProvider?.id}
        editable={editable as boolean}
        onClose={onClose}
        loading={loading}
        initCredentialFields={initCredentialFields}
      />
    </Formik>
  );
};

export default ProviderForm;
