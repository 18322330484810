import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { invert, isEmpty } from 'lodash';
import useTranslate from 'utils/useTranslate';
import { GameTypeNames, GameTypesTranslation } from 'constants/gameTypes';
import globalMessages from 'messages';
import { StyledTable, StyledCenter } from './styles';
import UpdateStatusModal from './components/UpdateStatusModal';
import SpeocificVendorModal from './components/SpecificVendorModal';
import GameVendorsScreenTab from '../../screentab/GameVendorsScreenTab';
import GamesScreenTab from '../../screentab/GamesScreenTab';
import { ACTIVE_GAMES, ALL_GAMES, VENDORS } from '../../queries';
import { messages } from './messages';

const VendorManagementTable = ({ ...props }) => {
  const translate = useTranslate();
  const client = useApolloClient();
  const { refetchVariables, loading } = props;
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [currentNodeData, setCurrentNodeData] = useState<any>(null);

  const [games, setGames] = useState<Record<string, any>>({});
  const [showActivationModal, setshowActivationModal] = useState(false);
  const { data: vendorQueryResult } = useQuery(VENDORS);
  useEffect(() => {
    Promise.all([
      client.query({ query: ALL_GAMES }),
      client.query({ query: ACTIVE_GAMES }),
    ]).then(([{ data: allGames }, { data: activeGames }]) => {
      setGames({ allGames, activeGames });
    });
  }, [client]);

  const actionTypes = {
    DISABLE: 'DISABLE',
    ENABLE: 'ENABLE',
    HIDE: 'HIDE',
    UNHIDE: 'UNHIDE',
  };

  const toggleVendorModal = (node: any) => (e: {
    preventDefault: () => void;
  }) => {
    e.preventDefault();
    if (node !== null) {
      setCurrentNodeData({
        ...node,
      });
    }
    setShowVendorModal((shown) => !shown);
  };

  const toggleActivationModal = (node?: any, actionType?: string) => () => {
    if (node !== null) {
      setCurrentNodeData({
        ...node,
        actionType,
      });
    }
    setshowActivationModal((shown) => !shown);
  };

  const columns = [
    {
      title: translate(messages['game-vendor-categories.text']),
      dataIndex: 'gameVendorCategories',
      key: 'gameVendorCategories',
      render: (vendor: string) => vendor,
    },
    {
      title: translate(messages['game-vendors.text']),
      dataIndex: 'gameVendors',
      key: 'gameVendors',
      render: (gameVendors: Array<any>, allData: Record<string, any>) => (
        <GameVendorsScreenTab
          vendors={gameVendors}
          gameCategory={allData.untranslatedKey}
        />
      ),
    },
    {
      title: translate(messages['active-game-vendors.text']),
      dataIndex: 'activeGameVendors',
      key: 'activeGameVendors',
      render: (activeGameVendors: any[], allData: Record<string, any>) => (
        <GameVendorsScreenTab
          vendors={activeGameVendors}
          activeVendors
          gameCategory={allData.untranslatedKey}
          // totalGames={get(allData, 'gameVendors.length', 0)}//
          // isActiveMode
        />
      ),
    },
    {
      title: translate(messages['active-games.text']),
      dataIndex: 'activeGames',
      key: 'activeGames',
      render: (activeGames: number) => activeGames,
    },
    {
      title: translate(messages['games.text']),
      dataIndex: 'games',
      key: 'games',
      render: (currentGames: number, allData: Record<string, any>) => (
        <GamesScreenTab
          games={currentGames}
          gameCategory={allData.untranslatedKey}
        />
      ),
    },
    {
      title: translate(messages['status.text']),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => status,
    },
    {
      title: translate(messages['visibility.text']),
      dataIndex: 'visibility',
      key: 'visibility',
      render: (visibility: boolean) => visibility,
    },
    {
      title: translate(messages['action.text']),
      dataIndex: 'action',
      key: 'action',
      render: () => {},
    },
  ];

  let dataSource = Object.values(GameTypeNames).map((gameType) => ({
    gameVendorCategories: gameType,
  }));

  if (vendorQueryResult) {
    const {
      vendors: { edges },
    } = vendorQueryResult;
    const listOfVendors = edges.map((edge: Record<string, any>) => edge.node);
    const gameTypesMapper = invert(GameTypeNames);

    dataSource = dataSource.map((data) => {
      const gameTypeKey = gameTypesMapper[data.gameVendorCategories];
      const totalGames = listOfVendors.filter((vendor: Record<string, any>) =>
        vendor.gameTypes.includes(gameTypeKey)
      );

      const enabledGames = listOfVendors.filter(
        (vendor: Record<string, any>) =>
          vendor.gameTypes.includes(gameTypeKey) && vendor.enabled
      );

      return {
        ...data,
        gameVendors: totalGames,
        activeGameVendors: enabledGames,
      };
    });
  }

  if (!isEmpty(games)) {
    const gameTypesMapper = invert(GameTypeNames);
    dataSource = dataSource.map((data) => {
      const key = gameTypesMapper[data.gameVendorCategories];
      const gameProperty = `${key.toLowerCase()}Games`;
      const totalAllGames = games.allGames[gameProperty].totalCount;
      const totalActiveGames = games.activeGames[gameProperty].totalCount;

      return {
        ...data,
        games: totalAllGames,
        activeGames: totalActiveGames,
      };
    });
  }

  if (loading)
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );

  dataSource = dataSource.map((gameType) => ({
    ...gameType,
    gameVendorCategories: translate(
      globalMessages[GameTypesTranslation[gameType.gameVendorCategories]]
    ),
    untranslatedKey: gameType.gameVendorCategories,
  }));

  return (
    <>
      {showActivationModal && (
        <UpdateStatusModal
          nodeData={currentNodeData}
          actionTypes={actionTypes}
          toggleModal={toggleActivationModal(null)}
          refetchVariables={refetchVariables}
        />
      )}
      {showVendorModal && (
        <SpeocificVendorModal
          nodeData={currentNodeData}
          toggleModal={toggleVendorModal(null)}
        />
      )}
      <StyledTable
        columns={columns}
        size="middle"
        dataSource={dataSource}
        pagination={false}
        rowKey={(x: any) => x.gameVendorCategories}
      />
    </>
  );
};

export default VendorManagementTable;
