import React from 'react';
import { get } from 'lodash';
import { Divider, Empty, Dropdown, Menu, Spin } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import { formatDate } from 'utils/dateUtils';
import { useQuery } from '@apollo/react-hooks';
import { useScreenTabV2 } from 'store/screenTabState';
import { ALink } from 'components/ALink/ALink';
import { useConfig } from 'hooks/useConfig';
import { DATE_TIME_FORMAT } from 'constants/date';
import { showPermissionError } from 'components/Navbar/Navbar';
import { useNewMemberDetailPermissions } from '../../../constants';
import { TransactionContent } from '../../TransactionContent/TransactionContent';
import { MoreDetailsTransaction } from '../../MoreDetailsTransaction/MoreDetailsTransaction';
import { LAST_PLAYED_GAMES } from './queries';

export const LastPlayedGames = ({ maxItemCount }: { maxItemCount: number }) => {
  const { member } = useMember() as any;
  const { addTab } = useScreenTabV2();
  const { ALLOWED_MEMBER_BET_RECORDS } = useNewMemberDetailPermissions();
  const { addCurrency } = useConfig();
  const { loading, error, data } = useQuery(LAST_PLAYED_GAMES, {
    context: { shouldBatch: true },
    fetchPolicy: 'network-only',
    variables: {
      id: member.id,
    },
  });

  if (loading) {
    return <Spin spinning />;
  }

  if (error) {
    throw new Error('Error');
  }

  const playedGamesEdges = get(data, 'member.playedGames.edges', []);
  const playedGames: IPlayedGames[] = playedGamesEdges.map(
    ({ node }: { node: any }) => node
  );
  const totalGamesCount = get(data, 'member.playedGames.totalCount', 0);
  const notTheLastItem = (index: number) => index !== maxItemCount - 1;

  const openMemberBetRecordsTab = (serialCode?: string) => {
    if (!ALLOWED_MEMBER_BET_RECORDS) {
      showPermissionError('Member Bet Records - View');
      return;
    }
    addTab({
      id: 'member-bet-records',
      state: {
        memberIdRef: member.id,
        serialCodeRef: serialCode,
      },
    });
  };

  return totalGamesCount === 0 ? (
    <div className="d-flex align-items-center justify-content-center member-profile-tab-container">
      <Empty />
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-space-between member-profile-tab-container">
      <div style={{ padding: '16px 16px 0 16px' }}>
        {playedGames.map((playedGame, index) => (
          <>
            <TransactionContent
              leftNode={{
                title: <strong>{playedGame?.game?.name}</strong>,
                secondary: (
                  <>
                    <div className="mr-2">
                      {formatDate(
                        playedGame?.lastMemberBetRecord?.dateTimePlaced,
                        DATE_TIME_FORMAT
                      )}
                    </div>
                    <div>
                      {moment(
                        playedGame?.lastMemberBetRecord?.dateTimePlaced
                      ).fromNow()}
                    </div>
                  </>
                ),
              }}
              middleNode={{
                title: addCurrency(
                  playedGame?.lastMemberBetRecord?.winloss,
                  2,
                  Math.floor
                ),
                secondary: (
                  <>
                    {playedGame?.lastMemberBetRecord?.vendor?.name} -{' '}
                    {playedGame?.game?.type}
                  </>
                ),
              }}
              rightNode={{
                title: (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="1"
                          // onClick={() =>
                          //   handleShowBrowserTab(
                          //     TabProps.memberBetRecords(member.id),
                          //     setScreenTab
                          //   )
                          // }
                        >
                          <FormattedMessage
                            id="open-in-new-browser-tab.text"
                            defaultMessage="Open in new browser tab"
                          />
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={['contextMenu']}
                  >
                    <ALink
                      onClick={() =>
                        openMemberBetRecordsTab(
                          playedGame?.lastMemberBetRecord?.serialCode
                        )
                      }
                    >
                      {playedGame?.lastMemberBetRecord?.serialCode}
                    </ALink>
                  </Dropdown>
                ),
                secondary: <></>,
              }}
            />
            {notTheLastItem(index) && <Divider className="m-0 mb-2" />}
          </>
        ))}
      </div>
      <MoreDetailsTransaction
        valueCount={totalGamesCount}
        onLoadMore={() => openMemberBetRecordsTab()}
      />
    </div>
  );
};

interface IPlayedGames {
  id: string;
  game: IGame;
  lastMemberBetRecord: ILastMemberBetRecord;
}

interface ILastMemberBetRecord {
  id: string;
  serialCode: string;
  gameType: string;
  winloss: number;
  dateTimePlaced: string;
  game: IGame;
  vendor: {
    id: string;
    type: string;
    name: string;
    shortcode: string;
  };
}

interface IGame {
  id: string;
  type: string;
  name: string;
}
