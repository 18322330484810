/* eslint-disable react/prop-types */
import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { Input, message } from 'antd';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import * as yup from 'yup';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import messages from 'messages';
import removeNull from 'utils/removeNull';
import { ALink } from 'components/ALink/ALink';
import localMessages from './messages';
import { SAVE_SEARCH } from './mutations';
import { CUSTOM_FILTERS } from '../SearchSettings/components/SearchSettingsList/queries';
import { StyledForm, StyledModal } from './styles';

const { Item } = StyledForm;

type Props = {
  tabContext: string;
};

const SaveSearch: React.FC<Props> = ({ tabContext }) => {
  const translate = useTranslate();

  const [showModal, setShowModal] = React.useState(false);
  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const [saveSearch, { loading }] = useMutation(SAVE_SEARCH);
  const { filters } = useFilterValues();

  const onSubmit = async (input: any) => {
    try {
      if (!isEmpty(removeNull(filters))) {
        await saveSearch({
          variables: {
            input: {
              name: input.name,
              context: tabContext,
              filter: removeNull(filters),
            },
          },
          refetchQueries: [
            {
              query: CUSTOM_FILTERS,
              variables: {
                filter: {
                  context: {
                    eq: tabContext,
                  },
                },
              },
            },
          ],
        });
        message.success(translate(messages['search-saved.text']));

        toggleModal();
      } else {
        message.error(
          translate(messages['cant-save-empty-filters-error.text'])
        );
      }
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ name: null }}
      onSubmit={onSubmit}
      validationSchema={yup.object().shape({
        name: yup.string().nullable(),
      })}
    >
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <>
          <ALink onClick={toggleModal}>
            <FormattedMessage
              id="save-search.text"
              defaultMessage="Save Search"
            />
          </ALink>
          {showModal && (
            <StyledModal
              title="Save Search"
              visible={showModal}
              okText={
                <FormattedMessage
                  id="save-changes.text"
                  defaultMessage="Save changes"
                />
              }
              cancelText={
                <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
              }
              onOk={handleSubmit as any}
              okButtonProps={{ type: 'primary' }}
              onCancel={toggleModal}
              confirmLoading={loading}
            >
              <StyledForm>
                <Item
                  label={
                    <FormattedMessage id="name.text" defaultMessage="Name" />
                  }
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 14 }}
                  validateStatus={
                    (errors.name && touched.name ? 'error' : null) as any
                  }
                  help={touched.name ? errors.name : null}
                  className="mt-3"
                >
                  <Input
                    name="name"
                    onChange={handleChange}
                    value={values.name || ''}
                    placeholder={translate(localMessages['please-enter.text'])}
                  />
                </Item>
              </StyledForm>
            </StyledModal>
          )}
        </>
      )}
    </Formik>
  );
};

export default SaveSearch;
