import React from 'react';
import { uniqBy, isEqual } from 'lodash';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import { DynamicObj } from 'interfaces/user.interface';
import removeNull from 'utils/removeNull';

import { getPartialKey } from 'constants/partialFilterKey';
import { WatchQueryFetchPolicy } from 'apollo-client';
import { useAccount } from 'store/accountState';
import coercedGet from './coercedGet';
import { useTablePaginationSA } from '../hooks/usePagination';

type FilterObjectType = DynamicObj;

type PageStateType = {
  first: number;
  after: undefined | string;
  savedCursor: (string | undefined)[];
  currentPage: number;
};

type PartialFilterStateType = {
  partialKeys: any[];
  savedFilts: FilterObjectType;
};

export const checkPartial = (filterKey: string, state: FilterObjectType) => {
  if (
    coercedGet(state, filterKey) &&
    state[filterKey].in &&
    state[filterKey].in.length
  ) {
    return state[filterKey].in.some(
      (filter: string) =>
        Array.isArray(filter) && filter.includes(getPartialKey())
    );
  }

  return false;
};

const getPartialString = (filtKey: string, filtObj: FilterObjectType) => {
  if (coercedGet(filtObj, filtKey) && filtObj[filtKey].in.length) {
    return filtObj[filtKey].in
      .find((str: string) => str.includes(getPartialKey()))
      .slice(9);
  }
  return null;
};

const getProcessedPartialFilters = (
  filterObj: FilterObjectType,
  filtFields: string[]
) => {
  const newFilterObj = { ...filterObj };

  const isFiltField = (filtKey: string) => filtFields.includes(filtKey);

  return Object.entries(newFilterObj).reduce((acc, curr) => {
    const [key, value] = curr;

    if (checkPartial(key, newFilterObj)) {
      acc[key] = isFiltField(key)
        ? {
            contains: getPartialString(key, newFilterObj),
          }
        : null;
    }
    return {
      [key]: value,
      ...acc,
    };
  }, {});
};

// specific partial filter "function creator" provided the specific module

export const getUnpaginatedTableData = (
  dataPath: string,
  tableData: object
) => {
  const mainResults = coercedGet(tableData, dataPath, {});
  const partialResults = coercedGet(tableData, 'partial', {});

  const edges = coercedGet(mainResults, 'edges', []);
  const partialEdges = coercedGet(partialResults, 'edges', []);
  const combination = [...edges, ...partialEdges];
  const uniqueCombinedEdges = uniqBy(combination, 'node.id');

  const uniqueTableData = uniqueCombinedEdges.map((edge) => edge.node);

  return uniqueTableData.length ? uniqueTableData : [];
};

export const usePartialFiltersQuery = (
  query1: DocumentNode,
  query2: DocumentNode,
  edgesPath: string, // eg. 'memberBetRecords.edges'
  rawFilters: FilterObjectType,
  pageState: PageStateType,
  filtFields: string[],
  fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network'
) => {
  const { account } = useAccount();
  const { account: user } = account;

  const processedFilters = removeNull(rawFilters);
  const processedPartialFilters = removeNull(
    getProcessedPartialFilters(rawFilters, filtFields)
  ) as any;

  const mixedFilters = {
    filter: processedFilters,
    partialFilter: processedPartialFilters,
  };

  const queryPath = edgesPath.replace(/.edges/g, '');

  const initialState = {
    partialKeys: [],
    savedFilts: {},
  } as PartialFilterStateType;

  const [partialFilterState, setPartialFilterState] = React.useState(
    initialState
  );

  const { partialKeys, savedFilts } = partialFilterState;

  // query 1
  const [
    loadPartialQueries,
    { loading: loading1, error: error1 },
  ] = useLazyQuery(query1, {
    fetchPolicy,
    variables: mixedFilters,
    onError: () => {
      setPartialFilterState((prev) => ({
        ...prev,
        partialKeys: [],
      }));
    },

    onCompleted: (data: any) => {
      const preEdges = getUnpaginatedTableData(queryPath, data);
      const queriedIdentifiers = preEdges.map(({ id }) => id);

      if (queriedIdentifiers.length)
        return setPartialFilterState((prev) => ({
          ...prev,
          partialKeys: queriedIdentifiers,
        }));

      return setPartialFilterState((prev) => ({
        ...prev,
        partialKeys: [],
      }));
    },
  });

  const checkPartialExist = (procFilts: FilterObjectType) => {
    const filtKeys = Object.keys(procFilts);
    return filtKeys.some((filtKey) => checkPartial(filtKey, procFilts));
  };

  React.useEffect(() => {
    if (checkPartialExist(processedFilters) && !partialKeys.length) {
      loadPartialQueries({
        variables: mixedFilters,
      });

      return;
    }
    if (!checkPartialExist(processedFilters) && partialKeys.length) {
      setPartialFilterState(initialState);
      return;
    }

    if (
      checkPartialExist(processedFilters) &&
      !isEqual(savedFilts, processedFilters)
    ) {
      loadPartialQueries({
        variables: mixedFilters,
      });
      setPartialFilterState((prev: PartialFilterStateType) => ({
        ...prev,
        savedFilts: processedFilters,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFilters]);

  const finalQueryFilter = partialKeys.length
    ? {
        id: {
          in: [...partialKeys],
        },
      }
    : processedFilters;

  const refetchVariables = {
    first: pageState.first,
    after: pageState.after,
    filter: finalQueryFilter,
  };

  const {
    loading: loading2,
    error: error2,
    data: queryData = {},
    refetch,
  } = useQuery(query2, {
    variables: refetchVariables,
    fetchPolicy,
    skip: !user,
  });

  return {
    data: queryData,
    loading: loading1 || loading2,
    error: error1 || error2,
    refetchVariables,
    refetch,
  };
};

export const usePartialFiltersQueryWithPagination = (
  query1: DocumentNode,
  query2: DocumentNode,
  edgesPath: string, // eg. 'memberBetRecords.edges'
  rawFilters: FilterObjectType,
  filtFields: string[]
) => {
  const processedFilters = removeNull(rawFilters);
  const processedPartialFilters = removeNull(
    getProcessedPartialFilters(rawFilters, filtFields)
  ) as any;

  const mixedFilters = {
    filter: processedFilters,
    partialFilter: processedPartialFilters,
  };

  const queryPath = edgesPath.replace(/.edges/g, '');

  const initialState = {
    partialKeys: [],
    savedFilts: {},
  } as PartialFilterStateType;

  const [partialFilterState, setPartialFilterState] = React.useState(
    initialState
  );

  const { partialKeys, savedFilts } = partialFilterState;

  // query 1
  const [
    loadPartialQueries,
    { loading: loading1, error: error1 },
  ] = useLazyQuery(query1, {
    fetchPolicy: 'cache-and-network',
    variables: mixedFilters,
    onError: () => {
      setPartialFilterState((prev) => ({
        ...prev,
        partialKeys: [],
      }));
    },

    onCompleted: (data: any) => {
      const preEdges = getUnpaginatedTableData(queryPath, data);

      const queriedIdentifiers = preEdges.map(({ id }) => id);

      if (queriedIdentifiers.length)
        return setPartialFilterState((prev) => ({
          ...prev,
          partialKeys: queriedIdentifiers,
        }));

      return setPartialFilterState((prev) => ({
        ...prev,
        partialKeys: [],
      }));
    },
  });

  const checkPartialExist = (procFilts: FilterObjectType) => {
    const filtKeys = Object.keys(procFilts);
    return filtKeys.some((filtKey) => checkPartial(filtKey, procFilts));
  };

  React.useEffect(() => {
    if (checkPartialExist(processedFilters) && !partialKeys.length) {
      loadPartialQueries({
        variables: mixedFilters,
      });

      return;
    }
    if (!checkPartialExist(processedFilters) && partialKeys.length) {
      setPartialFilterState(initialState);
      return;
    }

    if (
      checkPartialExist(processedFilters) &&
      !isEqual(savedFilts, processedFilters)
    ) {
      loadPartialQueries({
        variables: mixedFilters,
      });
      setPartialFilterState((prev: PartialFilterStateType) => ({
        ...prev,
        savedFilts: processedFilters,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawFilters]);

  const finalQueryFilter = partialKeys.length
    ? {
        id: {
          in: [...partialKeys],
        },
      }
    : processedFilters;

  const pageInitState = {
    first: 10,
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
  };
  const [page, dispatchPagination, actions] = useTablePaginationSA(
    pageInitState
  );
  const paginationState = [page, dispatchPagination, actions];

  const refetchVariables = {
    first: page.first,
    after: page.after,
    filter: finalQueryFilter,
  };

  const {
    loading: loading2,
    error: error2,
    data: queryData = {},
    refetch,
  } = useQuery(query2, {
    variables: refetchVariables,
  });

  // tableData
  const rawData = coercedGet(queryData, queryPath, {});
  const { totalCount, pageInfo } = rawData;
  const totalPage = Math.ceil(totalCount / page.first) || 1;
  const edges = coercedGet(queryData, edgesPath, []);
  const tableData = edges.map((edge: any) => edge.node) || [];

  return {
    paginationState,
    queryData: {
      refetch,
      data: queryData,
      loading: loading1 || loading2,
      error: error1 || error2,
      refetchVariables,
    },
    pageData: {
      tableData,
      totalPage,
      pageInfo,
      totalCount,
    },
  };
};
