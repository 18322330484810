import React from 'react';
import coercedGet from 'utils/coercedGet';
import TruncateToolTip from 'components/TruncateToolTip';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';

interface Props {
  depositId: string;
}

const DepositPaymentMethod = ({ depositId }: Props) => {
  const {
    depositMethodLoaderSet: [useDepositMethodLoader],
  } = useLoaders();

  const { loading, error, data = {} } = useDepositMethodLoader(depositId);

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;
  const paymentMethod = coercedGet(data, 'paymentMethod', {});

  if (!paymentMethod) {
    return <span>-</span>;
  }

  const { name = '', depositProvider = {} } = paymentMethod;
  return (
    <TruncateToolTip
      maxRows={2}
      content={`${depositProvider.name} - ${name}`}
    />
  );
};

export default DepositPaymentMethod;
