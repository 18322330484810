import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { movableFilterDateEffect } from 'utils/dateUtils';
import { FormattedMessage } from 'react-intl';
import { StyledRangePicker } from 'components/DateRegisterFilter/styles';
import isEmpty from 'lodash/isEmpty';
import { Button } from 'antd';
import useTranslate from 'utils/useTranslate';
import { NullableType } from 'interfaces/graphql.interface';
import { IDateRangeValue } from 'interfaces/dateValue.interface';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { messages } from './messages';

export const ScheduleDateFilter = ({
  values,
  setFilters,
}: IScheduleDateFilter) => {
  const [dateValue, setDateValue] = useState<NullableType<IDateRangeValue>>(
    null
  );

  useEffect(() => {
    movableFilterDateEffect(values, setDateValue);
  }, [values]);

  const handleOnChange = (e: any) => {
    const firstDate = `${moment(e[0]).format('YYYY-MM-DD')}T00:00:00.000Z`;
    const lastDate = `${moment(e[1]).format('YYYY-MM-DD')}T12:59:59.999Z`;

    const isFirstValid = moment(firstDate).isValid();
    const isSecondValid = moment(lastDate).isValid();
    if (e.length) {
      setFilters({
        gte: isFirstValid ? firstDate : e[0],
        lte: isSecondValid ? lastDate : e[1],
      });
      setDateValue({
        gte: e[0],
        lte: e[1],
      });
    } else {
      setFilters(null);
      setDateValue(null);
    }
  };

  const handleSevenDays = () => {
    const dateFrom = moment()
      .subtract(7, 'd')
      .startOf('day')
      .toISOString();
    const dateTo = moment().toISOString();

    setDateValue({ gte: dateFrom, lte: dateTo });
    setFilters({
      gte: dateFrom,
      lte: dateTo,
    });
  };

  const handleLastWeek = () => {
    const dateTo = moment()
      .subtract(1, 'weeks')
      .toISOString();
    const dateFrom = moment()
      .subtract(2, 'weeks')
      .startOf('isoWeek')
      .toISOString();

    setDateValue({ gte: dateFrom, lte: dateTo });
    setFilters({
      gte: dateFrom,
      lte: dateTo,
    });
  };

  const handleLastThirtyDays = () => {
    const dateTo = moment().toISOString();
    const dateFrom = moment()
      .subtract(30, 'd')
      .toISOString();

    setDateValue({ gte: dateFrom, lte: dateTo });
    setFilters({
      gte: dateFrom,
      lte: dateTo,
    });
  };
  const translate = useTranslate();
  return (
    <FilterItem
      label={
        <FormattedMessage
          id="system-message.sending-date.text"
          defaultMessage="Sending Date"
        />
      }
    >
      <StyledRangePicker
        className="mb-2"
        value={
          !isEmpty(dateValue)
            ? [moment(dateValue!.gte), moment(dateValue!.lte)]
            : ([] as any)
        }
        onChange={handleOnChange}
        placeholder={[
          translate(messages['start-date.text']),
          translate(messages['end-date.text']),
        ]}
      />

      <div>
        <Button size="small" className="mb-1" onClick={handleSevenDays}>
          <FormattedMessage
            id="last-7-days.text"
            defaultMessage="Last 7 days"
          />
        </Button>

        <Button size="small" className="mb-1" onClick={handleLastWeek}>
          <FormattedMessage id="last-week.text" defaultMessage="Last week" />
        </Button>

        <Button size="small" className="mb-1" onClick={handleLastThirtyDays}>
          <FormattedMessage
            id="last-30-days.text"
            defaultMessage="Last 30 days"
          />
        </Button>
      </div>
    </FilterItem>
  );
};

interface IScheduleDateFilter {
  values: object;
  setFilters: (e: any) => void;
}
