import React, { useState } from 'react';
import { message } from 'antd';
import Numeral from 'numeral';
import { get } from 'lodash';
import * as idb from 'idb-keyval';
import { useLazyQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { useNoRefreshPaginate } from 'utils/usePagination';

const setData = (key: string, val: any) => idb.set(key, val);

const getData = (key: string) => idb.get(key);

const removeData = (key: string) => idb.del(key);

type Props =
  | {
      tableData: any;
      setCsvFilters: any;
      setCsvHeader: any;
      csvHeader: any;
      csvFilters: any;
      loading: any;
      loadQuery: any;
      edgesPath: string;
      progPercent: number;
      ratioString: string;
      handleStopDownload: () => void;
      handleForceClearData: () => void;
      isNotStarted: boolean;
      emptyServerData: boolean;
      stop: boolean;
      error: boolean;
    }
  | undefined;

export const CsvContext = React.createContext<Props>(undefined);
const pageInitState = {
  first: 1,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
};

// ** README  -  Requirements for plugging imlementation to modules  ***

// (1) the pageInfo { endCursor , hasNextPage} needs to be included in the query
//  this is used for batched download logic
// (2) please make sure all paths specified in "key" property of csvData is included in the graphql query

// eslint-disable-next-line no-unused-vars
const CsvProvider = ({ children, initValues }: any) => {
  const { query, edgesPath } = initValues;
  const [csvHeader, setCsvHeader] = useState([]);
  const [csvFilters, setCsvFilters] = useState({ filter: {} });
  const [tableData, setTableData] = useState([]);
  // ============

  const [progPercent, setProgPercent] = useState(0);
  const [ratioString, setRatioString] = useState('N/A');
  const [isLoading, setIsLoading] = useState(false);
  const [initialTableData, setInitialTableData] = useState([]);
  const [stop, setStop] = useState(false);
  //  ============

  const [isNotStarted, setIsNotStarted] = useState(true);
  const [emptyServerData, setEmptyServerData] = useState(false);
  const [isError, setIsError] = useState(false);

  const [page, dispatch, actions] = useNoRefreshPaginate(pageInitState);

  const refetchVariables = {
    first: page.first,
    after: page.after,
    ...csvFilters,
  };

  const handleStopDownload = () => {
    if (progPercent < 99 && !emptyServerData) {
      removeData(edgesPath).then(() => {
        dispatch({
          type: actions.RESET_PAGE,
        });
        setStop(true);
        setProgPercent(0);
        setRatioString('N/A');
        setInitialTableData([]);
        setTableData([]);
        setIsNotStarted(true);
        setIsError(false);
      });
    }

    if (emptyServerData) {
      setEmptyServerData(false);
      dispatch({
        type: actions.RESET_PAGE,
      });
      setIsError(false);
    }
  };

  const handleForceClearData = () => {
    removeData(edgesPath).then(() => {
      dispatch({
        type: actions.RESET_PAGE,
      });

      setProgPercent(0);
      setRatioString('N/A');
      setInitialTableData([]);
      setTableData([]);
      setIsNotStarted(true);
      setEmptyServerData(false);
    });
  };
  React.useEffect(() => {
    getData(edgesPath)
      .then((result: any) => {
        const collatedData = result || [];

        setInitialTableData(collatedData);
      })
      .catch((err) => message.error(err));

    removeData(edgesPath);
  }, [edgesPath]);

  const [loadQuery] = useLazyQuery(query, {
    fetchPolicy: 'network-only',
    variables: refetchVariables,
    onCompleted: (res) => {
      getData(edgesPath)
        .then((result: any) => {
          setIsLoading(true);
          const collatedData = result || [];

          setIsNotStarted(!collatedData.length && !tableData.length);
          const storeCount = collatedData.length;

          const totalCountPath = edgesPath.replace(/.edges/g, '.totalCount');

          const totalCount = coercedGet(res, totalCountPath, 0);

          const responsePath = coercedGet(
            res,
            `${edgesPath.replace(/.edges/g, '')}`,
            null
          );

          const checkServerDataEmpty = () => {
            const actualEdges = get(res, edgesPath);
            const actualTotalCount = get(res, totalCountPath);

            if (actualEdges.length === 0 && actualTotalCount === 0) return true;
            return false;
          };

          const isEmptyServerData = checkServerDataEmpty();

          if (isEmptyServerData) {
            setIsLoading(false);
            setEmptyServerData(() => isEmptyServerData);

            return;
          }

          if (!responsePath) {
            // eslint-disable-next-line no-console
            console.warn(
              'For Devs eyes only - Please check graphql query string and csvProvider parameters.'
            );
            throw new Error('Please Check Query and CSV arguments');
          }

          const isComplete = storeCount >= totalCount;

          const pVal = Numeral((storeCount / totalCount) * 100).format(
            '0,0'
          ) as any;

          setRatioString(
            `${Numeral(storeCount).format('0,0')} / ${Numeral(
              totalCount
            ).format('0,0')}`
          );

          setProgPercent(pVal);

          if (stop && !isComplete) {
            setStop(false);
            setIsLoading(false);
            return;
          }

          if (isComplete) {
            setIsLoading(false);
            setInitialTableData(collatedData);
            dispatch({
              type: actions.RESET_PAGE,
            });

            return;
          }

          const edges = coercedGet(res, edgesPath, []);

          const retrievedData = edges.map(({ node }: any) =>
            Object.entries(node).reduce((acc, curr) => {
              const key = curr[0];
              const val = curr[1];

              if (!val) {
                acc[key] = '';
                return acc;
              }
              acc[key] = val;
              return acc;
            }, {})
          );

          setData(edgesPath, [...collatedData, ...retrievedData]).then(() => {
            const pageInfoPath = edgesPath.replace(/.edges/g, '.pageInfo');
            const pageInfo = coercedGet(res, pageInfoPath, '');

            if (totalCount > 2000 && totalCount < 10000 && page.first < 50) {
              dispatch({
                type: actions.SETSTATE,
                payload: {
                  first: 50,
                },
              });
            }

            if (totalCount > 10000 && page.first < 80) {
              dispatch({
                type: actions.SETSTATE,
                payload: {
                  first: 80,
                },
              });
            }

            dispatch({
              type: actions.NEXT,
              payload: pageInfo,
            });
          });
        })
        .catch((err) => message.error(err));
    },

    onError: () => {
      setIsError(true);
    },
  });

  React.useEffect(() => {
    if (initialTableData.length) {
      const pipeLine = csvHeader?.filter(
        (item: any) => item.renderCell && typeof item.renderCell === 'function'
      );

      const getProcessedTableData = (): any =>
        initialTableData &&
        initialTableData.map((node: any) => {
          if (pipeLine && pipeLine.length > 0) {
            const processedNode = pipeLine.reduce(
              (acc: any, curr: any) => ({
                ...acc,
                [curr && curr.key]: curr && curr.renderCell(acc),
              }),
              node
            );

            return processedNode;
          }

          return node;
        });

      setTableData(getProcessedTableData());
    }
  }, [isLoading, initialTableData, csvHeader]);

  return (
    <CsvContext.Provider
      value={{
        tableData,
        setCsvFilters,
        setCsvHeader,
        csvHeader,
        csvFilters,
        loading: isLoading,
        loadQuery,
        edgesPath,
        progPercent,
        ratioString,
        handleStopDownload,
        handleForceClearData,
        isNotStarted,
        error: isError,
        emptyServerData,
        stop,
      }}
    >
      {children}
    </CsvContext.Provider>
  );
};

export default CsvProvider;

export const useCsvValues = () => React.useContext<any | null>(CsvContext);
