import { useMutation } from '@apollo/react-hooks';
import MemberTagsSelect from 'components/MemberTagsSelect';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import React, { useState } from 'react';
import { Member } from 'types/graphqlTypes';

type Props = {
  member: Member;
  filter: Record<string, any>;
  disableAddLabel?: boolean;
  disableClosable?: boolean;
};

const MemberTagsRenderer = ({
  member,
  filter,
  disableAddLabel,
  disableClosable,
}: Props) => {
  const [tags, setTags] = useState(member.tags);

  const [updateMember] = useMutation(UPDATE_MEMBER);

  const handleChange = async (e: any[]) => {
    try {
      await updateMember({
        variables: {
          id: member.id,
          input: {
            tags: e.map((item) => item.id),
          },
        },
        refetchQueries: [{ query: GET_MEMBERS, variables: filter }],
      });
      setTags(e);
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  return (
    <MemberTagsSelect
      value={tags.filter((item) => item !== null)}
      onChange={handleChange}
      disableAddLabel={disableAddLabel}
      disableClosable={disableClosable}
    />
  );
};

export default MemberTagsRenderer;
