import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import vendorGameMetrics from 'constants/vendorGameMetrics';

const { Option } = Select;

const GameType = ({
  state,
  setState,
}: {
  state: {
    metric: Array<string>;
  };
  setState: (e: any) => void;
}) => (
  <div className="my-3">
    <div className="mb-1">
      <FormattedMessage id="reports.metric.text" defaultMessage="Metric" />
    </div>
    <Select
      placeholder={
        <FormattedMessage id="reports.select.text" defaultMessage="Select" />
      }
      style={{ width: '100%' }}
      value={state.metric}
      onChange={(value) =>
        setState(
          (prevState: { reset: boolean }) =>
            ({
              ...state,
              metric: value,
              ...((!value && { reset: !prevState.reset }) as any),
            } as any)
        )
      }
    >
      {vendorGameMetrics.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  </div>
);

export default GameType;
