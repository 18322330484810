import { formatDate } from 'utils/dateUtils';

export const columns = [
  {
    title: 'Platform ID',
    key: 'platformId',
    dataIndex: 'platformId',
    render: (e: string) => e,
  },
  {
    title: 'Brand ID',
    key: 'brandId',
    dataIndex: 'brandId',
    render: (e: string) => e,
  },
  {
    title: 'Username',
    key: 'username',
    dataIndex: 'username',
    render: (e: string) => e,
  },
  {
    title: 'Game Category',
    key: 'gameCategory',
    dataIndex: 'gameCategory',
    render: (e: string) => e,
  },
  {
    title: 'Game Name',
    key: 'gameName',
    dataIndex: 'game',
    render: (e: string) => e || '-',
  },
  {
    title: 'Launch Stamp',
    key: 'gameStartTimeStamp',
    dataIndex: 'gameStartTimeStamp',
    render: (e: string) => (e ? formatDate(e, 'HH:mm:ss') : '-'),
  },
  {
    title: 'End Stamp',
    key: 'gameEndTimeStamp',
    dataIndex: 'gameEndTimeStamp',
    render: (e: string) => (e ? formatDate(e, 'HH:mm:ss') : '-'),
  },
  {
    title: 'Session Duration',
    key: 'duration',
    dataIndex: 'duration',
    render: (e: string) => e || '-',
  },
];
