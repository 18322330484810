import Layout from 'components/Layout';
import { useFilterValues } from 'contexts/Filters';
import React from 'react';
import BrandFilter from 'components/BrandFilter';
import {
  GET_USERNAMES,
  GET_BRAND_IDS,
  GET_PLATFORM_IDS,
} from 'graphql/queries/memberSessionDuration.query';
import SelectFilterField from 'components/SelectFilterField';

interface Props {
  extraQuery?: { [key: string]: Record<string, string> };
}

const Sidebar = ({ extraQuery }: Props) => {
  const { filters, setPersistedFilters } = useFilterValues();

  return (
    <Layout.Sidebar>
      <SelectFilterField
        testId="MSD-username-filter"
        label="Username"
        query={GET_USERNAMES}
        queryConnection="gameSessions"
        filterFieldName="username"
        filters={filters}
        onChange={(e) =>
          setPersistedFilters((prev: Record<string, string>) => ({
            ...prev,
            username: e?.length ? { in: e } : null,
          }))
        }
        additionalOptionsFilter={extraQuery}
      />

      <BrandFilter
        testId="MSD-brand-filter"
        setState={setPersistedFilters}
        state={filters}
      />

      <SelectFilterField
        testId="MSD-brandId-filter"
        label="Brand Id"
        query={GET_BRAND_IDS}
        queryConnection="gameSessions"
        filterFieldName="brandId"
        filters={filters}
        onChange={(e) =>
          setPersistedFilters((prev: Record<string, string>) => ({
            ...prev,
            brandId: e?.length ? { in: e } : null,
          }))
        }
      />

      <SelectFilterField
        testId="MSD-platformId-filter"
        label="Platform Id"
        query={GET_PLATFORM_IDS}
        queryConnection="gameSessions"
        filterFieldName="platformId"
        filters={filters}
        onChange={(e) =>
          setPersistedFilters((prev: Record<string, string>) => ({
            ...prev,
            platformId: e?.length ? { in: e } : null,
          }))
        }
        additionalOptionsFilter={extraQuery}
      />
    </Layout.Sidebar>
  );
};

export default Sidebar;
