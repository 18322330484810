import styled from 'styled-components';
import { InputNumber } from 'antd';

export const StyledInput = styled(InputNumber)`
  width: 45% !important;
  border-bottom: 1px solid #d9d9d9 !important;
  border-radius: 0 !important;
`;

export default {};
