import gql from 'graphql-tag';

export const CREATE_PERMISSION_GROUP = gql`
  mutation CreatePermissionGroup($input: CreatePermissionGroupInput!) {
    createPermissionGroup(input: $input)
  }
`;

export const DELETE_PERMISSION_GROUP = gql`
  mutation DeletePermissionGroup($id: ID!) {
    deletePermissionGroup(id: $id)
  }
`;

export const UPDATE_PERMISSION_GROUP = gql`
  mutation UpdatePermissionGroup(
    $id: ID!
    $input: UpdatePermissionGroupInput!
  ) {
    updatePermissionGroup(id: $id, input: $input)
  }
`;

export const DUPLICATE_PERMISSION_GROUP = gql`
  mutation DuplicatePermissionGroup($id: ID!) {
    duplicatePermissionGroup(id: $id)
  }
`;

export default {};
