import React, { useState, useEffect } from 'react';
import coercedGet from 'utils/coercedGet';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import messages from '../messages';
import { StyledRootDiv } from './styles';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  width: 300px;
`;

const DashboardTree = () => {
  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const slicedSubOptions = Object.entries(ALL_PERMISSIONS.ALL_DASHBOARDS).slice(
    1
  );

  const options = slicedSubOptions.map((e) => e[1]);

  const [checked, setChecked] = useState({
    all: false,
    indeterminate: false,
    currentList: [],
  });

  const handleAllChange = (e: Record<string, any>) => {
    const permissionsList = e.target.checked
      ? Object.values(ALL_PERMISSIONS.ALL_DASHBOARDS)
      : [];

    setFieldValue('permissions[ALL_DASHBOARDS]', permissionsList);
  };

  const handleOnChange = (checkedList: any[]) => {
    let permissionsList = [...checkedList];

    if (checkedList.length === options.length) {
      permissionsList = Object.values(ALL_PERMISSIONS.ALL_DASHBOARDS);
    }

    setFieldValue('permissions[ALL_DASHBOARDS]', permissionsList);
  };

  const availablePermissions = coercedGet(
    values,
    'permissions.ALL_DASHBOARDS',
    []
  );

  useEffect(() => {
    const permissionMatch =
      availablePermissions.length ===
      Object.values(ALL_PERMISSIONS.ALL_DASHBOARDS).length;

    const inputPermissions = permissionMatch
      ? availablePermissions.slice(1)
      : availablePermissions;

    setChecked({
      all: permissionMatch,
      indeterminate: availablePermissions.length && !permissionMatch,
      currentList: inputPermissions,
    });
  }, [availablePermissions]);
  const translate = useTranslate();
  return (
    <StyledRootDiv>
      <div style={{ width: 300 * slicedSubOptions.length, paddingBottom: 100 }}>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={checked.all}
              indeterminate={checked.indeterminate}
              onChange={handleAllChange}
            >
              <StyledText size={16}>
                {translate(messages[ALL_PERMISSIONS.ALL_DASHBOARDS.DASHBOARDS])}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <Checkbox.Group
          className="w-100"
          onChange={handleOnChange}
          value={checked.currentList}
        >
          <div className="mt-4 d-flex ml-3">
            {slicedSubOptions.map((e) => (
              <StyledDiv>
                <Checkbox value={ALL_PERMISSIONS.ALL_DASHBOARDS[e[0]]}>
                  <StyledText size={14}>{translate(messages[e[0]])}</StyledText>
                </Checkbox>
              </StyledDiv>
            ))}
          </div>
        </Checkbox.Group>
      </div>
    </StyledRootDiv>
  );
};

export default DashboardTree;
