import React, { useState, FC, useMemo, useEffect, useCallback } from 'react';
import ReportsTimezoneProvider, {
  useReportsTimezone,
} from 'contexts/ReportsTimezone';
import Layout from 'components/Layout';
import FiltersProvider, { useFilterValues } from 'contexts/Filters';
import removeNull from 'utils/removeNull';
import { useTablePagination } from 'hooks/usePaginate';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import coercedGet from 'utils/coercedGet';

import {
  GET_BALANCE_TRANSACTION_RECORDS,
  GET_BALANCE_TRANSACTION_RECORD_IDS,
} from 'graphql/queries/balanceTransactionRecordNext.query';
import {
  Admin,
  BalanceTransactionRecord,
  BalanceTransactionRecordsConnectionEdge,
} from 'types/graphqlTypes';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { useConfig } from 'hooks/useConfig';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { StyledTagContainer } from 'styles';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { ALink } from 'components/ALink/ALink';
import { useAccount, usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import TimezoneSelect from 'components/TimezoneSelect';
import { reports } from 'components/DownloadCsvButtonNext/reportsValue';
import DownloadCsvButtonNext from 'components/DownloadCsvButtonNext';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import { randomKey } from 'utils/randomKey';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import ReportPreSetDateFilters from 'components/ReportPreSetDateFilters';
import ReportDatePresetsProvider, {
  useDatePresets,
} from 'hooks/useDatePresets';
import compose from 'utils/compose';
import { useScreenTabV2 } from 'store/screenTabState';
import { isObjectPropsEmpty } from 'utils/isObjectPropsEmpty/isObjectPropsEmpty';
import { isEmpty } from 'lodash';
import { OriginalColumn } from 'store/customColumnState/types';
import {
  getColumns,
  convertManualAdjustmentToTypes,
  typeFilterAdjustment,
} from './utils';
import Sidebar from './components/Sidebar';
import { FilterConditions } from './components/FilterConditions';

type Props = {
  refreshPage: () => void;
};

type FilterColumns = {
  filterColumns: (value: ColumnsType<any>) => ColumnsType<any>;
  columnFields: ColumnsType<BalanceTransactionRecord>;
};

const BalanceTransactionRecordsContent: FC<Props> = ({ refreshPage }) => {
  const [expandedTags, setExpandedTags] = useState(false);

  const { role, permissions } = usePermissions();
  const { ALLOWED_CSV_DOWNLOAD_BUTTON } = collectPermissions(
    role,
    permissions,
    ['VIEW', 'CSV_DOWNLOAD_BUTTON'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS_BALANCE_TRANSACTION_REPORTS
  );

  const { bypassedCurrentTimezone } = useReportsTimezone();
  const { renderNumeral } = useConfig();

  const {
    account: { account: adminAccount },
  } = useAccount() as { account: { account: Admin } };

  const showBaseAmounts = adminAccount?.multiCurrencyEnabled;

  const columns = useCallback(
    () =>
      getColumns({
        renderNumeral,
        reportTimezone: bypassedCurrentTimezone,
        showBaseAmounts,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bypassedCurrentTimezone]
  );

  const { filters, setFilters, defaultValues } = useFilterValues();

  const { preSetValue: preSetDateFilter, setPresetValue } = useDatePresets();

  const handleFilterChange = (e: object) => {
    setFilters(e);
  };

  const { page, setPage, handleNext, handlePrev } = useTablePagination({
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
    first: 10,
  });

  const processManualAdjustmentFilter = compose(
    convertManualAdjustmentToTypes,
    typeFilterAdjustment
  );

  const memoizedFilter = React.useMemo(
    () =>
      removeNull({
        ...filters,
        ...processManualAdjustmentFilter(filters),
        MANUAL_ADJUSTMENT: null,
        preSetDateFilter: null,
      }),

    [filters, processManualAdjustmentFilter]
  );

  const { loading, data } = usePartialFiltersQuery(
    GET_BALANCE_TRANSACTION_RECORD_IDS,
    GET_BALANCE_TRANSACTION_RECORDS,
    'balanceTransactionRecords.edges',
    memoizedFilter,
    page,
    [
      'serialCode',
      'brandId',
      'platformId',
      'remarks',
      'manualAdjustmentRemarks',
      'transaction',
    ],
    'cache-and-network',
    '',
    {},
    Boolean(preSetDateFilter)
  );

  const balanceTransactionRecordsEdges = coercedGet(
    data!,
    'balanceTransactionRecords.edges',
    []
  );
  const balanceTransactionRecords: BalanceTransactionRecord[] = balanceTransactionRecordsEdges.map(
    (balanceTransactionRecord: BalanceTransactionRecordsConnectionEdge) =>
      balanceTransactionRecord.node
  );

  const totalCount = coercedGet(
    data!,
    'balanceTransactionRecords.totalCount',
    0
  );
  const pageInfo = coercedGet(data!, 'balanceTransactionRecords.pageInfo', 0);

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const columnFields = useMemo(
    () => columns().filter((field) => !isEmpty(field)),
    [columns]
  );

  const { filterColumns } = (useCustomColumnsV2(
    'balance-transaction-records',
    (columnFields as unknown) as OriginalColumn[]
  ) as unknown) as FilterColumns;

  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (filters.preSetDateFilter) setPresetValue(filters.preSetDateFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={
            <div className="d-flex align-items-center flex-g-1">
              <ToggleSidebarHead />
              <StyledTagContainer ref={ref} isExpanded={expandedTags}>
                <FilterConditions
                  filters={filters}
                  onFilterChange={handleFilterChange}
                  loading={loading}
                />
              </StyledTagContainer>
              <LegacyIconToggle
                forwardedRef={ref}
                filters={filters}
                expandedTags={expandedTags}
                toggleExpandedTags={setExpandedTags}
              />
              {!isObjectPropsEmpty(filters) && (
                <ALink
                  onClick={() => {
                    handleFilterChange(defaultValues);
                    setPresetValue(null);
                  }}
                  style={{ marginTop: '0.35rem' }}
                >
                  Clear All
                </ALink>
              )}
            </div>
          }
          rightNode={
            <div className="d-flex align-items-center">
              <TimezoneSelect />
              <RefreshButton onClick={refreshPage} />
              <ColumnsFilterNew tabId="balance-transaction-records" iconOnly />
              {ALLOWED_CSV_DOWNLOAD_BUTTON && (
                <DownloadCsvButtonNext
                  filter={memoizedFilter}
                  iconOnly
                  module={reports.BALANCE_TRANSACTION_RECORDS}
                  allowedDownloadCSV={ALLOWED_CSV_DOWNLOAD_BUTTON}
                  allowedGenerateCSV={ALLOWED_CSV_DOWNLOAD_BUTTON}
                  reportName="Balance Transaction Records"
                />
              )}
            </div>
          }
        />
        <Layout.Content
          sideBar={
            <Sidebar filters={filters} onFilterChange={handleFilterChange} />
          }
          footer={
            <Layout.Footer
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={pageInfo}
            />
          }
        >
          {preSetDateFilter ? (
            <Table
              loading={loading}
              dataSource={balanceTransactionRecords}
              pagination={false}
              columns={filterColumns(columnFields)}
              size="middle"
              scroll={{
                x: 1500,
                y: 'calc(100vh - 296px)',
              }}
            />
          ) : (
            <ReportPreSetDateFilters
              filterFieldKey="dateTimeCreated"
              filterLabel="Date/Time Created"
            />
          )}
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

const BalanceTransactionRecords = () => {
  const [key, setKey] = useState('');

  const refreshPage = () => setKey(randomKey());

  const { getTab } = useScreenTabV2('balance-transaction-records');
  const { memberIdRef, serialCodeRef } = getTab('balance-transaction-records');

  const memberTabRef = memberIdRef ? { in: [memberIdRef] } : null;
  const balanceTransactionTabRef = serialCodeRef
    ? { in: [serialCodeRef] }
    : null;

  const initialDateFilter = memberIdRef || serialCodeRef ? 'lifetime' : null;

  return (
    <ReportsTimezoneProvider>
      <FiltersProvider
        tabId="balance-transaction-records"
        initialValues={{
          serialCode: balanceTransactionTabRef,
          brandId: null,
          platformId: null,
          remarks: null,
          transaction: null,
          manualAdjustmentRemarks: null,
          brand: null,
          type: null,
          member: memberTabRef,
          processor: null,
          dateTimeCreated: null,
          amount: null,
          MANUAL_ADJUSTMENT: null,
          preSetDateFilter: initialDateFilter,
        }}
      >
        <ReportDatePresetsProvider>
          <BalanceTransactionRecordsContent
            key={key}
            refreshPage={refreshPage}
          />
        </ReportDatePresetsProvider>
      </FiltersProvider>
    </ReportsTimezoneProvider>
  );
};

export default BalanceTransactionRecords;
