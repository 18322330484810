/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { string, bool } from 'prop-types';
import Icon from '@ant-design/icons';
import { Popover, Button, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import styled from 'styled-components';
import InnerFormNew from './InnerFormNew';
import { ReactComponent as ViewColumnIcon } from './assets/view_column.svg';

const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-title {
    padding: 12px;
  }
`;

type ColumnsFilterProps = {
  className: string;
  tabId: string;
  iconOnly: boolean;
};

function ColumnsFilterNew({ className, tabId, iconOnly }: ColumnsFilterProps) {
  const intl = useIntl();
  const formikRef = React.useRef() as any;

  const { formatMessage } = intl;

  const [isDropDown, setIsDropDown] = React.useState(false);
  const uniqueId = `columns-filter-button-${+new Date()}`;

  const handleVisibleChange = (e: any) => {
    setIsDropDown(e);
  };

  const toggleDropDown = () => setIsDropDown((prev) => !prev);

  return (
    <StyledPopover
      title={
        <div className="d-flex justify-content-space-between align-items-center">
          <FormattedMessage
            id="custom-columns.text"
            defaultMessage="Custom Columns"
          />
          <Button
            size="small"
            onClick={(e) => {
              e.preventDefault();
              formikRef?.current?.setColumnOrder(
                formikRef?.current?.initialColumns
              );
            }}
          >
            <FormattedMessage id="reset.text" defaultMessage="Reset" />
          </Button>
        </div>
      }
      style={{ background: 'white' }}
      placement="bottomLeft"
      getPopupContainer={() => document.getElementById(uniqueId) as any}
      visible={isDropDown}
      trigger="click"
      onVisibleChange={handleVisibleChange}
      content={
        <InnerFormNew
          toggleDropDown={toggleDropDown}
          tabId={tabId}
          ref={formikRef}
        />
      }
      className={className}
    >
      <div className="cursor-pointer d-flex align-items-center" id={uniqueId}>
        {iconOnly ? (
          <Tooltip title={formatMessage(messages['custom-columns.text'])}>
            <Icon
              className="mr-1"
              component={ViewColumnIcon}
              style={{ fontSize: '22px' }}
            />
          </Tooltip>
        ) : (
          <Icon
            className="mr-1"
            component={ViewColumnIcon}
            style={{ fontSize: '22px' }}
          />
        )}
        {!iconOnly ? (
          <FormattedMessage
            id="custom-columns.text"
            defaultMessage="Custom Columns"
          />
        ) : (
          ''
        )}{' '}
      </div>
    </StyledPopover>
  );
}

ColumnsFilterNew.propTypes = {
  className: string,
  tabId: string.isRequired,
  iconOnly: bool,
};

ColumnsFilterNew.defaultProps = {
  className: '',
  iconOnly: false,
};

export default ColumnsFilterNew;
