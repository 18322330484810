import React from 'react';
import { Tag } from 'antd';
import { defineMessages } from 'react-intl';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';

const messagesLocal = defineMessages({
  INACTIVE: {
    id: 'INACTIVE',
    defaultMessage: 'Inactive',
  },
  ACTIVE: {
    id: 'ACTIVE',
    defaultMessage: 'Active',
  },
  DRAFT: {
    id: 'DRAFT',
    defaultMessage: 'Draft',
  },
});

export const StatusTag = () => {
  const { filters, setFilters } = useFilterValues();
  const translate = useTranslate();

  const removeStatus = (status: string) => {
    setFilters((filter: any) => {
      const newStatusFilters = filter?.status?.in.filter(
        (filterStatus: string) => filterStatus !== status
      );

      if (newStatusFilters.length === 0) {
        const { _status, ...restFilter } = filter;
        return restFilter;
      }

      return {
        ...filter,
        status: {
          in: newStatusFilters,
        },
      };
    });
  };

  const statusFilters = filters?.status?.in;

  if (!statusFilters) {
    return <></>;
  }

  return statusFilters.map((status: string) => (
    <Tag closable key={status} onClose={() => removeStatus(status)}>
      {translate(messagesLocal[status])}
    </Tag>
  ));
};
