import styled from 'styled-components';
import { Select, Button, DatePicker, Input } from 'antd';

const { RangePicker } = DatePicker;

export const ScrollableFilter = styled.div`
  margin-top: 1rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
`;

export const StyledSpan = styled.span`
  font-size: 10px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const StyledLabel = styled.span`
  font-size: 10px;
  font-weight: bold;
`;

export const StyledSelect = styled(Select)<any>`
  width: 100%;

  .ant-select-selection {
    padding: 4px;
    padding-top: 0;
    border-radius: 2px;
  }

  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }

  .ant-select-selection li.ant-select-selection__choice {
    border-radius: 4px;
  }
`;

export const StyledSelectDropdown = styled(Select)<any>`
  width: 100%;

  .ant-select-selection {
    padding: 4px;
    padding-top: 0;
    border-radius: 2px;
  }

  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }

  .ant-select-selection li.ant-select-selection__choice {
    border-radius: 4px;
  }
`;

interface Prop {
  value: any;
  disabled?: any;
}

export const StyledRangePicker = styled(RangePicker)<Prop>`
  .ant-calendar-picker-input {
    border-radius: 2px;
  }
`;

export const StyledButton = styled(Button)<any>`
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-right: 8px;
`;

export const StyledInput = styled(Input)`
  border-radius: 2px;
`;
