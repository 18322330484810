import React from 'react';
import { MemberInteractionLog } from 'types/graphqlTypes';
import { getInteractionModuleField } from 'utils/memberInteractionLog';
import { useScreenTabV2 } from 'store/screenTabState';
import { ALink } from 'components/ALink/ALink';
import { isInternalDepositByTypename } from 'utils/depositWithdrawalTypename';
import coercedGet from 'utils/coercedGet';

type Props = {
  log: MemberInteractionLog;
};

const ExternalModule: React.FC<Props> = ({ log }) => {
  const { addTab } = useScreenTabV2();

  const handleOpenInternalDepositTab = (serialCodeRef: string) => {
    addTab({
      id: 'deposit-requests-internal',
      state: {
        serialCodeRef,
      },
    });
  };

  const handleOpenExternalDepositTab = (serialCodeRef: string) => {
    addTab({
      id: 'deposit-requests-external',
      state: {
        serialCodeRef,
      },
    });
  };

  const handleOpenWithdrawalTab = (serialCodeRef: string) => {
    addTab({
      id: 'withdrawal-requests',
      state: {
        serialCodeRef,
      },
    });
  };

  const handleOpenBetRecordTab = (memberIdRef: string) => {
    addTab({
      id: 'member-bet-records',
      state: {
        memberIdRef,
      },
    });
  };

  const handleOpenBalanceTransactionRecordTab = (memberIdRef: string) => {
    addTab({
      id: 'balance-transaction-records',
      state: {
        memberIdRef,
      },
    });
  };

  const handleOpenVipTab = (programmeNameRef: string | undefined | null) => {
    addTab({
      id: 'vip',
      state: {
        programmeNameRef,
      },
    });
  };

  const handleOpenMemberAccessAuditTab = (memberIdRef: string) => {
    addTab({
      id: 'member-access-audit-history',
      state: {
        memberId: memberIdRef,
      },
    });
  };

  const handleDepositWithdrawalCodeClick = () => {
    if (log.withdrawal) {
      handleOpenWithdrawalTab(log.withdrawal.serialCode);
    } else if (log.deposit) {
      const type = coercedGet(log.deposit, '__typename', '');
      if (isInternalDepositByTypename(type)) {
        handleOpenInternalDepositTab(log.deposit.serialCode);
      } else {
        handleOpenExternalDepositTab(log.deposit.serialCode);
      }
    }
  };

  const renderExternalModule = () => {
    const { type } = log;
    const usernameModule = [
      'MEMBER_LOGGED_IN',
      'MEMBER_LOGGED_OUT',
      'ACCOUNT_PASSWORD_RESET_CREATED',
      'ACCOUNT_PASSWORD_RESET_VERIFIED',
      'ACCOUNT_PASSWORD_RESET_EXPIRED',
    ];
    const matchedUsernameModule = !!usernameModule.find((x) => x === type);

    if (matchedUsernameModule) {
      const externalModuleId = log.member.username;
      return externalModuleId ? (
        <ALink onClick={() => handleOpenMemberAccessAuditTab(log.member.id)}>
          {externalModuleId}
        </ALink>
      ) : (
        '-'
      );
    }

    if (type === 'MEMBER_LOYALTY_LEVEL_UPGRADED') {
      const externalModuleId = getInteractionModuleField(log, 'name');
      return externalModuleId ? (
        <ALink
          onClick={() =>
            handleOpenVipTab(log.memberLoyaltyLevel?.programme?.name)
          }
        >
          {externalModuleId}
        </ALink>
      ) : (
        '-'
      );
    }

    if (type === 'MEMBER_LOYALTY_LEVEL_DOWNGRADED') {
      const externalModuleId = getInteractionModuleField(log, 'name');
      return externalModuleId ? (
        <ALink
          onClick={() =>
            handleOpenVipTab(log.memberLoyaltyLevel?.programme?.name)
          }
        >
          {externalModuleId}
        </ALink>
      ) : (
        '-'
      );
    }

    if (type === 'VENDOR_BALANCE_CREDITED') {
      const externalModuleId = getInteractionModuleField(log, 'shortcode');
      return externalModuleId ? (
        <ALink
          onClick={() => handleOpenBalanceTransactionRecordTab(log.member.id)}
        >
          {externalModuleId}
        </ALink>
      ) : (
        '-'
      );
    }

    if (type === 'VENDOR_BALANCE_DEBITED') {
      const externalModuleId = getInteractionModuleField(log, 'shortcode');
      return externalModuleId ? (
        <ALink
          onClick={() => handleOpenBalanceTransactionRecordTab(log.member.id)}
        >
          {externalModuleId}
        </ALink>
      ) : (
        '-'
      );
    }

    if (type === 'VENDOR_GAME_LAUNCHED') {
      const externalModuleId = getInteractionModuleField(log, 'shortcode');
      return externalModuleId ? (
        <ALink onClick={() => handleOpenBetRecordTab(log.member.id)}>
          {externalModuleId}
        </ALink>
      ) : (
        '-'
      );
    }

    const externalModuleId = getInteractionModuleField(log, 'serialCode');
    return externalModuleId ? (
      <ALink onClick={() => handleDepositWithdrawalCodeClick()}>
        {getInteractionModuleField(log, 'serialCode')}
      </ALink>
    ) : (
      '-'
    );
  };

  return <>{renderExternalModule()}</>;
};

export default ExternalModule;
