import React from 'react';
import { Badge } from 'antd';
import coercedGet from 'utils/coercedGet';
import styled from 'styled-components';

import useTranslate from 'utils/useTranslate';
import { AffiliateRequest } from 'types/graphqlTypes';
import { Status } from 'types';
import messages from 'messages';
import {
  toCapitalizedCase,
  AffiliateRequestStatusColor,
} from '../../../constants';

type Props = {
  loading: boolean;
  error: boolean;
  summary: AffiliateRequest;
};

const ModalTitle = (props: Props) => {
  const { loading, error, summary } = props;
  const { status } = summary;
  const statusColor = (AffiliateRequestStatusColor[status] ||
    'default') as Status;
  const translate = useTranslate();
  return (
    <div className="d-flex">
      <div className="mr-5">
        {translate(messages['affiliate-request-details.text'])}
      </div>
      {!loading && !error && (
        <StyledDiv className="d-flex">
          <div className="mr-3">
            {translate(messages['affiliate-request-member-number.text'])}:{' '}
            {coercedGet(summary, 'member.username', '')}
          </div>
          <div className="mr-3">
            <Badge
              text={`${toCapitalizedCase(translate(messages[status]))}`}
              status={statusColor}
            />
          </div>
        </StyledDiv>
      )}
    </div>
  );
};

const StyledDiv = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`;

export default ModalTitle;
