import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import useTranslate from 'utils/useTranslate';
import { StyledSwitch } from '../../styles';
import messages from '../../../../messages';

type Props = {
  formItemLayout: Record<string, any>;
  setFieldValue: (key: string, data: any) => void;
  values: Record<string, any>;
  errors: Record<string, any>;
  getThresholdValue: (data: string) => void;
};
const TurnoverRequirement = (props: Props) => {
  const {
    formItemLayout,
    setFieldValue,
    values,
    errors,
    getThresholdValue,
  } = props;
  const translate = useTranslate();
  return (
    <>
      <Form.Item className="mb-0 text-right" wrapperCol={{ span: 5 }}>
        <strong className="mr-2">
          {translate(messages.turnoverRequirement)}
        </strong>
      </Form.Item>
      <Form.Item
        label={translate(messages.enable)}
        {...formItemLayout}
        className="mb-1 ml-2"
      >
        <StyledSwitch
          name="turnoverRequirementEnabled"
          checkedChildren={translate(messages.yes)}
          unCheckedChildren={translate(messages.no)}
          checked={values.turnoverRequirementEnabled}
          onChange={(e: any) => setFieldValue('turnoverRequirementEnabled', e)}
        />
      </Form.Item>
      <Form.Item
        className="mb-5 ml-2"
        label={translate(messages.activationThreshold)}
        validateStatus={
          errors.turnoverRequirementActivationThreshold && 'error'
        }
        help={errors.turnoverRequirementActivationThreshold || null}
        {...formItemLayout}
      >
        <Input
          name="turnoverRequirementActivationThreshold"
          type="number"
          placeholder="0"
          suffix="X"
          style={{ width: 350 }}
          onFocus={(e) => e.target.select()}
          value={values.turnoverRequirementActivationThreshold}
          onChange={(e) =>
            setFieldValue(
              'turnoverRequirementActivationThreshold',
              getThresholdValue(e.target.value)
            )
          }
        />
      </Form.Item>
    </>
  );
};

export default TurnoverRequirement;
