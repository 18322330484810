import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import messages from '../messages';
import { StyledRootDiv } from './styles';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  width: 300px;
`;

const VIPTree = () => {
  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const defaultCheckedValue = {
    all: {
      first: false,
      VIP_VIP: false,
      VIP_VIP_LIST: false,
    },
    indeterminate: {
      first: false,
      VIP_VIP: false,
    },
    currentList: {
      VIP_VIP_GRP: [],
    },
  };

  const [checked, setChecked] = useState(defaultCheckedValue);

  const defaultValues = Object.values(ALL_PERMISSIONS.ALL_VIP);

  const levels = [
    ['first', 0],
    ['second', 1],
    ['third', 2],
  ];

  const options: Record<string, any> = levels.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr[0]}`]: Object.entries(ALL_PERMISSIONS.ALL_VIP)
        .filter((e) => (e[1].match(/:/g) || []).length === curr[1])
        .map((e) => e[0]),
    }),
    {}
  );

  const handleFirstLevelChange = (e: any) => {
    const list = e.target.checked ? defaultValues : [];
    setFieldValue('permissions[ALL_VIP]', list);
  };

  const handleThirdLevelChange = (e: any) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_VIP];

    const unlistedName = ALL_PERMISSIONS.ALL_VIP[name.replace('_LIST', '')];

    const thirdLevelValues = defaultValues.filter(
      (v) => (v.match(/:/g) || []).length >= 2 && v.includes(unlistedName)
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, unlistedName, ...thirdLevelValues];
    } else {
      list = targetPermissions.filter(
        (p) => !thirdLevelValues.includes(p) && p !== unlistedName
      );
    }

    setFieldValue(
      'permissions[ALL_VIP]',
      uniq(list.length >= 3 ? ['VIP', ...list] : [])
    );
  };

  const handleOnChange = (list: any[], name: string) => {
    const stateName = name.replace('_GRP', '');

    const secondLevelPermissionLookup = ALL_PERMISSIONS.ALL_VIP[stateName];
    const thirdLevelPermissionLookup =
      ALL_PERMISSIONS.ALL_VIP[`${stateName}_LIST`];

    const thirdLevelPermissions = Object.values(ALL_PERMISSIONS.ALL_VIP).filter(
      (p) => (p.match(/:/g) || []).length === 2
    );

    const targetPermissions = [...values.permissions.ALL_VIP];

    const templatePermissions = thirdLevelPermissions.filter(
      (p) =>
        p.includes(secondLevelPermissionLookup) &&
        p !== secondLevelPermissionLookup &&
        p !== thirdLevelPermissionLookup
    );

    setFieldValue(
      'permissions[ALL_VIP]',
      uniq([
        ...targetPermissions.filter((p) => !templatePermissions.includes(p)),
        'VIP',
        secondLevelPermissionLookup,
        thirdLevelPermissionLookup,
        ...list,
      ])
    );
  };

  const availablePermissions = coercedGet(values, 'permissions.ALL_VIP', []);
  useEffect(() => {
    const permissionMatch =
      availablePermissions.length ===
      Object.values(ALL_PERMISSIONS.ALL_VIP).length;

    const thirdLevelPermissions = availablePermissions.filter(
      (p: any) => (p.match(/:/g) || []).length === 2
    );

    const secondLevelKeys = [['VIP_VIP', 'VIP:VIP', 7]];

    const thirdLevelChecks: Record<string, any> = secondLevelKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr[0]}`]:
          thirdLevelPermissions.filter((p: any) => p.includes(`${curr[1]}`))
            .length === curr[2],
      }),
      {}
    );

    const allChecks = {
      VIP_VIP: permissionMatch || thirdLevelChecks.VIP_VIP,
    };

    setChecked({
      all: {
        first: permissionMatch,
        VIP_VIP: allChecks.VIP_VIP,
        VIP_VIP_LIST:
          thirdLevelPermissions.includes('VIP:VIP:LIST') || allChecks.VIP_VIP,
      },
      indeterminate: {
        first: availablePermissions.length && !permissionMatch,
        VIP_VIP:
          thirdLevelPermissions.filter((p: any) => p.includes('VIP:VIP'))
            .length && !thirdLevelChecks.VIP_VIP,
      },
      currentList: {
        VIP_VIP_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('VIP:VIP') && p !== 'VIP:VIP' && p !== 'VIP:VIP:LIST'
        ),
      },
    });

    if (values.permissions.ALL_VIP.length <= 2) setChecked(defaultCheckedValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePermissions]);

  const translate = useTranslate();

  return (
    <StyledRootDiv>
      <div style={{ width: 300 * options.second.length, paddingBottom: 100 }}>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={checked.all.first}
              indeterminate={checked.indeterminate.first}
              onChange={handleFirstLevelChange}
            >
              <StyledText size={16}>
                {translate(messages[ALL_PERMISSIONS.ALL_VIP.VIP])}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <div className="w-100">
          <div className="mt-4 d-flex ml-3">
            {options.second.map((s: any) => (
              <StyledDiv>
                <div className="mt-2">
                  <Checkbox
                    key={`${s}_LIST`}
                    name={`${s}_LIST`}
                    checked={checked.all[`${s}_LIST`]}
                    onChange={handleThirdLevelChange}
                  >
                    <StyledText size={14}>
                      {translate(messages.LIST)}
                    </StyledText>
                  </Checkbox>
                  <Checkbox.Group
                    className="w-100 ml-2"
                    onChange={(v) => handleOnChange(v, `${s}_GRP`)}
                    value={checked.currentList[`${s}_GRP`]}
                  >
                    <div className="ml-4">
                      {options.third
                        .filter(
                          (t: any, idx: number) => t.includes(s) && idx !== 0
                        )
                        .map((t: any) => (
                          <StyledDiv className="mt-2">
                            <Checkbox
                              key={ALL_PERMISSIONS.ALL_VIP[t]}
                              value={ALL_PERMISSIONS.ALL_VIP[t]}
                            >
                              <StyledText size={14}>
                                {translate(messages[t.replace(`${s}_`, '')])}
                              </StyledText>
                            </Checkbox>
                          </StyledDiv>
                        ))}
                    </div>
                  </Checkbox.Group>
                </div>
              </StyledDiv>
            ))}
          </div>
        </div>
      </div>
    </StyledRootDiv>
  );
};

export default VIPTree;
