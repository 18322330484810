import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import SelectFilterField from 'components/SelectFilterField';
import { PROGRAMME_NAMES } from 'graphql/queries/affiliateProgramme.query';
import Status from '../Status';
import DefaultProgramme from '../DefaultProgramme';
import NegativeCarry from '../NegativeCarry';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    key,
    value,
  }: {
    key: string;
    value: DynamicObj | null;
  }) => void;
};

const FilterItems = ({ filters, onRawFilterChange: handleChange }: Props) => (
  <>
    <SelectFilterField
      testId="affiliate-program-name-filter"
      label="Programme Name"
      query={PROGRAMME_NAMES}
      queryConnection="affiliateProgrammes"
      filterFieldName="name"
      partialSupported={false}
      filters={filters}
      onChange={(e) =>
        handleChange({
          key: 'name',
          value: e?.length ? { in: e } : null,
        })
      }
    />
    <Status
      value={filters.status}
      onChange={(e) =>
        handleChange({
          key: 'status',
          value: e.length ? { in: e } : null,
        })
      }
    />
    <DefaultProgramme
      value={filters.default}
      onChange={(e) =>
        handleChange({
          key: 'default',
          value: Array.isArray(e) ? null : { in: e },
        })
      }
    />
    <NegativeCarry
      value={filters.negativeCarryOverEnabled}
      onChange={(e) => {
        handleChange({
          key: 'negativeCarryOverEnabled',
          value: Array.isArray(e) ? null : { in: e },
        });
      }}
    />
  </>
);

export default FilterItems;
