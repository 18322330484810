import gql from 'graphql-tag';

export const DELETE_PAYMENT_METHOD = gql`
  mutation DeletePaymentMethod($id: ID!) {
    deletePaymentMethod(id: $id)
  }
`;

export const DUPLICATE_PAYMENT_METHOD = gql`
  mutation duplicate($id: ID!, $type: ResourceType!) {
    duplicate(id: $id, type: $type)
  }
`;

export const UPDATE_BANK_TRANSFER_PAYMENT_METHOD = gql`
  mutation UpdateBankTransferPaymentMethod(
    $id: ID!
    $input: UpdateBankTransferPaymentMethodInput!
  ) {
    updateBankTransferPaymentMethod(id: $id, input: $input)
  }
`;

export const UPDATE_ALIPAY_PAYMENT_METHOD = gql`
  mutation UpdateAlipayPaymentMethod(
    $id: ID!
    $input: UpdateAlipayPaymentMethodInput!
  ) {
    updateAlipayPaymentMethod(id: $id, input: $input)
  }
`;

export const UPDATE_WECHAT_PAYMENT_METHOD = gql`
  mutation UpdateWechatPaymentMethod(
    $id: ID!
    $input: UpdateWechatPaymentMethodInput!
  ) {
    updateWechatPaymentMethod(id: $id, input: $input)
  }
`;

export const UPDATE_LUBANZHIFU_PAYMENT_METHOD = gql`
  mutation UpdateLubanzhifuPaymentMethod(
    $id: ID!
    $input: UpdateLubanzhifuPaymentMethodInput!
  ) {
    updateLubanzhifuPaymentMethod(id: $id, input: $input)
  }
`;

export const UPDATE_PAYMENT_METHOD = gql`
  mutation updatePaymentMethod($id: ID!, $input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(id: $id, input: $input)
  }
`;
