import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterStateType } from '../../types';
import { StyledForm } from './styles';
import FilterItems from '../FilterItems/FilterItems';

type Props = {
  initialValues: FilterStateType;
  onSubmit: (value: FilterStateType) => void;
  onClose: (value: string) => void;
  isLoading: Boolean;
};

const SearchSettingsForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const [initValues, setInitValues] = useState(initialValues);

  return (
    <StyledForm>
      <Row>
        <Col span={12}>
          <FilterItems
            filters={initValues}
            onFilterChange={setInitValues}
            isCustomFilter
          />
        </Col>
      </Row>
      <div className="bt-1 py-4 text-right">
        <Button
          className="mr-2"
          onClick={onClose as any}
          loading={isLoading as any}
        >
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmit(initValues)}
          loading={isLoading as any}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
