import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Button, Divider, Empty, message, Spin } from 'antd';
import {
  CREATE_MEMBER_NOTES,
  DELETE_MEMBER_NOTE,
  UPDATE_MEMBER_NOTE,
} from 'graphql/mutations/memberNote.mutation';
import { GET_MEMBER_NOTES } from 'graphql/queries/memberNote.query';
import { cloneDeep, get } from 'lodash';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import removeNull from 'utils/removeNull';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import MemberNote from './MemberNote';
import { StyledInput } from './styles';

const noteWordsLimit = 150;
export const MemberNotes = () => {
  const client = useApolloClient();

  const { MEMBER_NOTES_PERMISSION } = useNewMemberDetailPermissions();

  const [wordsCountLeft, setWordsCountLeft] = useState(noteWordsLimit);
  const [isSubmit, setSubmit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);
  const [maxWordsError, setMaxWordsError] = useState(false);
  const [loadingMoreNotes, setLoadingMoreNotes] = useState(false);
  const { member } = useMember() as any;
  const [listOfNotes, setListOfNotes] = useState() as any;
  const [input, setInput] = useState({
    member: member.id,
    header: 'remarks',
    note: null,
    pinned: false,
  });

  const [createMemberNotes] = useMutation(CREATE_MEMBER_NOTES);
  const [updateNote] = useMutation(UPDATE_MEMBER_NOTE);
  const [deleteNote] = useMutation(DELETE_MEMBER_NOTE);
  const memberNotes = get(listOfNotes, 'edges', []);
  const translate = useTranslate();

  const handleNextPage = async () => {
    setLoadingMoreNotes(true);
    const {
      data: {
        member: { notes },
      },
    } = await client.query({
      fetchPolicy: 'network-only',
      query: GET_MEMBER_NOTES,
      variables: {
        id: member.id,
        first: 999,
        after: listOfNotes?.edges[listOfNotes?.edges.length - 1].cursor,
      },
    });
    setListOfNotes((currentNotes: Record<string, any>) => ({
      ...cloneDeep(currentNotes),
      edges: [...currentNotes.edges, ...notes.edges],
      pageInfo: notes.pageInfo,
    }));
    setLoadingMoreNotes(false);
  };

  const loadInitialNotes = () => {
    setIsLoadingNotes(true);
    return new Promise(() => {
      client
        .query({
          fetchPolicy: 'network-only',
          query: GET_MEMBER_NOTES,
          context: { shouldBatch: true },
          variables: {
            id: member.id,
            first: 2,
          },
        })
        .then((resp) => {
          const notes = get(resp, 'data.member.notes', []);
          setListOfNotes(notes);
        })
        .finally(() => setIsLoadingNotes(false));
    });
  };

  const { context } = useOperatorHeader();
  const onDeleteNote = (id: string) => {
    deleteNote({
      variables: {
        id,
      },
      context,
    }).then(() => {
      const filteredNotes = {
        ...cloneDeep(listOfNotes),
        edges: listOfNotes.edges.filter(
          (note: Record<string, any>) => note.node.id !== id
        ),
      };
      setListOfNotes(filteredNotes);
      message.success(translate(messages['note-deleted.text']));
    });
  };

  useEffect(() => {
    loadInitialNotes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddNote = async () => {
    setSubmit(true);
    if (input.note && !maxWordsError) {
      try {
        setLoading(true);
        await createMemberNotes({
          variables: {
            input: removeNull(input),
          },
          context,
        });
        setInput((prevData) => ({
          ...prevData,
          note: null,
        }));
        setSubmit(false);
        loadInitialNotes().then(() => {
          message.success(translate(messages['note-created.text']));
          setLoading(false);
        });
      } finally {
        setLoading(false);
        setWordsCountLeft(noteWordsLimit);
      }
    } else {
      setSubmit(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });

    if (value) {
      const numOfWords = value.match(/\S+/g).length;
      setWordsCountLeft(noteWordsLimit - (numOfWords || 1));

      if (numOfWords > noteWordsLimit) {
        setMaxWordsError(true);
        return;
      }
      setMaxWordsError(false);
      return;
    }
    setWordsCountLeft(noteWordsLimit);
  };

  const togglePinned = (id: string, pinnedState: any) => {
    const noteToUpdateIdx = listOfNotes.edges.findIndex(
      (note: Record<string, any>) => note.node.id === id
    );
    const notesCopy = cloneDeep(listOfNotes);
    notesCopy.edges[noteToUpdateIdx].node.pinned = !pinnedState;
    setListOfNotes(notesCopy);

    updateNote({
      variables: {
        id,
        input: {
          pinned: !pinnedState,
        },
      },
      context,
    }).then(() => {
      loadInitialNotes();
      message.success(
        !pinnedState
          ? translate(messages['note-pinned.text'])
          : translate(messages['note-unpinned.text'])
      );
    });
  };

  function onUpdateNote(note: Record<string, any>, updatedText: string) {
    const noteToUpdateIdx = listOfNotes.edges.findIndex(
      (nodeNote: Record<string, any>) => nodeNote.node.id === note.id
    );
    const notesCopy = cloneDeep(listOfNotes);
    notesCopy.edges[noteToUpdateIdx].node.note = updatedText;
    setListOfNotes(notesCopy);

    updateNote({
      variables: {
        id: note.id,
        input: {
          note: updatedText,
        },
      },
      context,
    }).then(() => {
      message.success(translate(messages['note-updated.text']));
    });
  }

  return (
    <Spin spinning={isLoadingNotes}>
      <div style={{ marginTop: '8px' }}>
        <div
          className="d-flex align-items-center"
          style={{
            borderTopRightRadius: '10px',
            borderTopLeftRadius: '10px',
            background: '#f0f0f0',
            height: '36px',
            paddingLeft: '16px',
          }}
        >
          <span className="fw-500">
            <FormattedMessage
              id="member-notes.text"
              defaultMessage="Member Notes"
            />
          </span>
        </div>
        <div
          style={{
            background: '#fff',
            borderBottomRightRadius: '4px',
            borderBottomLeftRadius: '4px',
          }}
        >
          <div style={{ padding: '16px 16px 0 16px' }}>
            {MEMBER_NOTES_PERMISSION.ALLOWED_ADD && (
              <Form.Item
                className="mb-0"
                validateStatus={
                  (isSubmit && !input.note) || maxWordsError
                    ? 'error'
                    : undefined
                }
                help={
                  (isSubmit && !input.note
                    ? translate(messages['required.text'])
                    : null) || maxWordsError
                    ? translate(messages['max-words-error.text'], {
                        count: noteWordsLimit,
                      })
                    : null
                }
              >
                <StyledInput
                  value={input.note || ''}
                  name="note"
                  onChange={handleChange}
                  placeholder={translate(messages['please-key-in.text'])}
                  suffix={
                    <>
                      <span style={{ color: '#c3c3c3' }}>
                        <FormattedMessage
                          id="number-of-words.text"
                          defaultMessage="{count} words"
                          values={{ count: wordsCountLeft }}
                        />
                      </span>

                      <Button
                        className="fs-12"
                        type="link"
                        onClick={handleAddNote}
                        disabled={isLoading}
                      >
                        <FormattedMessage id="add.text" defaultMessage="Add" />
                      </Button>
                    </>
                  }
                />
              </Form.Item>
            )}
            <Divider className="mt-1 mb-0" />
          </div>
          {memberNotes.length > 0 && MEMBER_NOTES_PERMISSION.ALLOWED_LIST ? (
            <>
              <div style={{ padding: '8px 16px 0 16px' }}>
                {memberNotes.map(
                  ({ node }: { node: Record<string, any> }, index: number) => (
                    <MemberNote
                      currentNoteIndex={index}
                      currentNotesCount={memberNotes.length}
                      node={node}
                      noteWordsLimit={noteWordsLimit}
                      onDeleteNote={onDeleteNote}
                      togglePinned={togglePinned}
                      onUpdateNote={onUpdateNote}
                      key={node.id}
                    />
                  )
                )}
              </div>
              <div className="text-center">
                {listOfNotes.pageInfo.hasNextPage && !loadingMoreNotes ? (
                  <>
                    <Divider className="mb-0 mt-1" />
                    <div style={{ paddingTop: '7px', paddingBottom: '8px' }}>
                      <a href="#/" onClick={handleNextPage} className="fs-12">
                        <FormattedMessage
                          id="member-details.load-more.text"
                          defaultMessage="Load more"
                        />
                      </a>
                    </div>
                  </>
                ) : (
                  <div style={{ paddingBottom: '16px' }} />
                )}

                {loadingMoreNotes && (
                  <span className="fs-12">
                    <FormattedMessage
                      id="loading.text"
                      defaultMessage="Loading..."
                    />
                  </span>
                )}
              </div>
            </>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </Spin>
  );
};
