import { omit } from 'lodash';
import removeNull from 'utils/removeNull';

export default (gameTypeSettings = {}) =>
  removeNull(
    omit(gameTypeSettings, [
      '__typename',
      'SLOT.__typename',
      'LIVE.__typename',
      'POKER.__typename',
      'FISH.__typename',
      'SPORTSBOOK.__typename',
      'LOTTERY.__typename',
    ])
  );
