import * as React from 'react';
import { useFormik } from 'formik';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import styled from 'styled-components';
import TitleFilter from '../TitleFilter';
import { OperatorFilter } from '../OperatorFilter/OperatorFilter';
import { MemberAccountFilter } from '../MemberAccountFilter/MemberAccountFilter';
import { TargetMembersFilter } from '../TargetMembersFilter/TargetMembersFilter';
import { ExcludedMembersFilter } from '../ExcludedMembersFilter/ExcludedMembersFilter';
import { multiMembersSelectFilterUtil } from '../../utils';
import { IDateRangeValue } from '../../../../../../../../interfaces/dateValue.interface';
import { ScheduleDateFilter } from '../ScheduleDateFilter/ScheduleDateFilter';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

interface ISearchSettingsForm {
  onClose: Function;
  initialValues: any;
  onSubmit: (fields: any) => void;
  isLoading: boolean;
}

export const SearchSettingsForm = ({
  onClose,
  initialValues,
  onSubmit,
  isLoading,
}: ISearchSettingsForm) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  const { handleSubmit, setFieldValue, values } = formik;

  return (
    <StyledForm>
      <Row className="mb-3">
        <Col span={12}>
          <TitleFilter
            values={get(values, 'title.in', [])}
            setFilters={(e) => setFieldValue('title', e)}
          />

          <OperatorFilter
            values={get(values, 'creator.in', [])}
            setFilters={(e) => {
              setFieldValue('creator', e);
            }}
          />

          <MemberAccountFilter
            values={values.memberAccount}
            setFilters={(e) => setFieldValue('memberAccount', e)}
          />

          <ScheduleDateFilter
            values={values.dateTimeSubmitted}
            setFilters={(e: IDateRangeValue) =>
              setFieldValue('dateTimeSubmitted', e)
            }
          />

          <TargetMembersFilter
            values={{
              members: values.targetMembers || [],
              memberLoyaltyLevels: values.targetMemberLoyaltyLevels || [],
              memberLevels: values.targetMemberLevels || [],
            }}
            setFilters={(filter) => {
              const response: object | null = multiMembersSelectFilterUtil({
                values: filter,
                type: 'target',
                returnValue: true,
              });
              const keys = Object.keys(response!);
              keys.forEach((key: string) => setFieldValue(key, response![key]));
            }}
          />

          <ExcludedMembersFilter
            values={{
              members: values.excludedMembers || [],
              memberLoyaltyLevels: values.excludedMemberLoyaltyLevels || [],
              memberLevels: values.excludedMemberLevels || [],
            }}
            setFilters={(filter) => {
              const response: object | null = multiMembersSelectFilterUtil({
                values: filter,
                type: 'excluded',
                returnValue: true,
              });
              const keys = Object.keys(response!);
              keys.forEach((key: string) => setFieldValue(key, response![key]));
            }}
          />
        </Col>
      </Row>

      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose as any} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>{' '}
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={handleSubmit as any}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};
