import { get, cloneDeep } from 'lodash';

/**
 * Sort array of objects based on another array
 * https://gist.github.com/ecarter/1423674
 *
 * arrayOfObjs = array of objects
 * order = array of string/number
 * key = the key to be iterated at.. supports nested keys example: "a.b.c"
 */

export function mapOrder(
  arrayOfObjs: Record<string, any>[],
  order: Array<string | number>,
  key: string
) {
  const clonedData = cloneDeep(arrayOfObjs);
  clonedData.sort((a, b) => {
    const keyOfA = get(a, key);
    const keyOfB = get(b, key);

    if (order.indexOf(keyOfA) > order.indexOf(keyOfB)) {
      return 1;
    }
    return -1;
  });

  return clonedData;
}

/**
 * Example:
 */

// var item_array, item_order, ordered_array;
//
// item_array = [
//   { id: 2, label: 'Two' },
//   { id: 3, label: 'Three' },
//   { id: 5, label: 'Five' },
//   { id: 6, label: 'Three' },
//   { id: 4, label: 'Four' },
//   { id: 1, label: 'One' },
// ];
//
// item_order = ['Five', 'Two', 'Three', 'Four', 'One'];
//
// ordered_array = mapOrder(item_array, item_order, 'label');

/*
* Result:
* 0: {id: 5, label: "Five"}
1: {id: 2, label: "Two"}
2: {id: 6, label: "Three"}
3: {id: 3, label: "Three"}
4: {id: 4, label: "Four"}
5: {id: 1, label: "One"}
* */
