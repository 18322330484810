import FiltersProvider from 'contexts/Filters';
import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import DepositProvidersScreen from './components/DepositProvidersScreen';

const ThirdPartyDepositProvider = () => {
  const { getTab } = useScreenTabV2();
  const { filter = {} } = getTab('deposit-providers-external') || {};
  const defaultFilters = { name: null, depositProviderType: null };
  const initialFilters = {
    ...defaultFilters,
    ...filter,
  };
  return (
    <FiltersProvider
      initialValues={initialFilters}
      defaultValues={defaultFilters}
      tabId="deposit-providers-external"
    >
      <DepositProvidersScreen />
    </FiltersProvider>
  );
};
export default ThirdPartyDepositProvider;
