import React from 'react';
import { CaretUpOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import LineChart from './components/LineChart';
import messages from './messages';
import { StyledCard } from './styles';

type Rec = Record<string, any>;
type Props = {
  langId: string;
  filter: Rec;
  total: number;
  percentage: number | string;
  chart: any;
};
const CardStat = ({ filter, langId, total, percentage, chart }: Props) => {
  let percentageLangID = '';
  switch (filter.menu) {
    case 'this-day':
      percentageLangID = 'dashboard.linestats.day-to-day.text';
      break;
    case 'yesterday':
      percentageLangID = 'dashboard.linestats.day-to-day.text';
      break;
    case 'this-week':
      percentageLangID = 'dashboard.linestats.week-to-week.text';
      break;
    case 'last-week':
      percentageLangID = 'dashboard.linestats.week-to-week.text';
      break;
    case 'this-month':
      percentageLangID = 'dashboard.linestats.month-to-month.text';
      break;
    case 'last-month':
      percentageLangID = 'dashboard.linestats.month-to-month.text';
      break;
    case 'annual':
      percentageLangID = 'dashboard.linestats.year-to-year.text';
      break;
    case 'date-range':
      percentageLangID = 'dashboard.linestats.period-to-period.text';
      break;
    default:
      percentageLangID = 'dashboard.linestats.month-to-month.text';
  }
  const translate = useTranslate();

  return (
    <Wrapper>
      <StyledCard bordered={false}>
        <div className="mb-2">
          <div className="float-left">{translate(messages[langId])}</div>
          <div className="clearfix" />
        </div>
        <div className="float-left">
          <h3>{total}</h3>
        </div>
        <div className="float-right">
          {/* <span>12.1</span> &nbsp;
        <Icon type="caret-down" /> */}
          {translate(messages[percentageLangID])}
          <CaretUpOutlined style={{ color: '#52C41A' }} /> {percentage} %
          {/* {percentage.toFixed(2)}% */}
        </div>

        <div className="chart">
          <LineChart chart={chart} />
        </div>
      </StyledCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-select-selection,
  .ant-select-selection--single {
    border: none;
  }
`;

export default CardStat;
