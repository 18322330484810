import React from 'react';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import messages from '../messages';

const StyledSidebarTitle = styled.h6`
  font-size: 8px;
`;

const StyledSidebarDesc = styled.p`
  font-size: 11px;
`;

const StyledContainer = styled.div`
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
`;

const SidebarItem = ({
  value,
  langId,
  ...restProps
}: {
  value: string | number;
  langId: string;
}) => {
  const translate = useTranslate();
  return (
    <StyledContainer data-testid="root-sidebar-item" {...restProps}>
      <StyledSidebarTitle>{translate(messages[langId])}</StyledSidebarTitle>
      <StyledSidebarDesc>{value}</StyledSidebarDesc>
    </StyledContainer>
  );
};

export default SidebarItem;
