import { useQuery } from '@apollo/react-hooks';
import Layout from 'components/Layout';
import ALL_PERMISSIONS from 'constants/permissions3';
import { useFilterValues } from 'contexts/Filters';
import { DEPOSIT_REQUESTS } from 'graphql/queries/deposit.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React, { useCallback, useState } from 'react';
import { usePermissions } from 'store/accountState';
import { useScreenTabV2 } from 'store/screenTabState';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { getPollInterval } from 'utils/pollInterval';
import DepositsTable from '../DepositsTable';
import HeaderLeft from '../HeaderLeft';
import HeaderRight from '../HeaderRight';
import Sidebar from '../Sidebar';

type DepositType = {
  refreshPage: () => void;
};

const DepositsScreen = ({ refreshPage }: DepositType) => {
  const { isTabActive } = useScreenTabV2();
  const isActiveTab = isTabActive('deposit-requests-external');

  const [isRealtime, setIsRealtime] = useState(true);

  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_EXTERNAL_DEPOSITS
  );

  const [page, setPage] = useState({
    first: 10,
    after: undefined,
    savedCursor: [''],
    currentPage: 0,
  }) as any;

  const { filters } = useFilterValues() as any;

  const cleanFilter = useCallback((oldFilter) => {
    // The cleanFilter function will remove the condition of `in` key from the `processor` filters if there's a `nin` key
    const newFilter: any = { ...transformMovableDateFilter(oldFilter) };

    if (newFilter.processor && newFilter.processor.nin) {
      newFilter.processor = {
        nin: newFilter.processor.nin,
      };
    }

    return newFilter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetRealtime = () => {
    setIsRealtime((prev) => !prev);
  };

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: cleanFilter(filters),
  };
  const { loading, data = {} } = useQuery(DEPOSIT_REQUESTS, {
    variables: refetchVariables,
    // fetchPolicy: 'network-only',
    pollInterval: getPollInterval({
      isActive: isActiveTab,
      isRealtime,
    }),
  });

  const { deposits = {} }: any = data;
  const { totalCount, pageInfo, edges = [] } = deposits;

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={<HeaderLeft />}
          rightNode={
            <HeaderRight
              refreshPage={refreshPage}
              isRealtime={isRealtime}
              handleSetRealtime={handleSetRealtime}
            />
          }
        />
        <Layout.Content
          sideBar={<Sidebar />}
          footer={
            <Layout.Footer
              leftNode={<div />}
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={pageInfo}
            />
          }
        >
          {ALLOWED_LIST && (
            <DepositsTable
              dataSource={edges}
              loading={loading}
              isActiveTab={isActiveTab}
              refetchVariables={refetchVariables}
            />
          )}
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

export default DepositsScreen;
