import React from 'react';
import { useIntl } from 'react-intl';
import RequestUpdateToggle from 'components/RequestUpdateToggle';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import { Divider } from 'antd';
import { customFormatMessage } from 'utils/customFormatMessage';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import messages from 'messages';

type Props = {
  withRealtime?: boolean;
  isRealtime?: boolean;
  handleSetRealtime?: () => void;
  refreshPage: () => void;
  filter: Record<string, any> | undefined;
};

const HeaderRight: React.FC<Props> = ({
  handleSetRealtime,
  isRealtime,
  withRealtime = true,
  refreshPage,
  filter,
}) => {
  const { formatMessage } = useIntl();
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);

  return (
    <>
      {withRealtime && (
        <RequestUpdateToggle
          checked={isRealtime}
          onChange={handleSetRealtime}
        />
      )}
      <RefreshButton onClick={() => refreshPage()} />
      <Divider type="vertical" className="mr-2 ml-0" />
      <ColumnsFilterNew iconOnly tabId="member-ip-address-summary" />
      <div>
        <SpreadsheetDownload
          iconOnly
          filter={filter}
          filename={translate(messages.MEMBER_IP_ADDRESS_SUMMARY)}
          extension="csv"
        />
      </div>
    </>
  );
};

export default HeaderRight;
