import React from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';

const PermissionAlert = () => {
  const translate = useTranslate();

  return (
    <>
      <h2>
        <FormattedMessage
          id="download-csv.text"
          defaultMessage="Download CSV"
        />
      </h2>
      {translate(messages.YOU_DO_NOT_HAVE_PERMISSION_TO_PERFORM_THIS_ACTION)}!
    </>
  );
};

export default PermissionAlert;
