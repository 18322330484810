import gql from 'graphql-tag';

export const WITHDRAWAL_PROVIDERS = gql`
  query withdrawalProviders(
    $first: Int
    $after: Binary
    $filter: WithdrawalProviderFilterInput
  ) {
    withdrawalProviders(filter: $filter, after: $after, first: $first) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          type
          dateTimeCreated
          ... on MidpayDirectWithdrawalProvider {
            id
            apiKey
            apiKeyPreview
            midPayDirectPaymentGateway {
              id
              name
            }
            credentialValues
          }
          ... on MidpayWithdrawalProvider {
            apiKey
            apiKeyPreview
          }
          ... on DiorpayWithdrawalProvider {
            key
            keyPreview
            merchantCode
          }
        }
      }
    }
  }
`;

export const WITHDRAWAL_PROVIDERS_CSV_DATA = gql`
  query withdrawalProviders(
    $first: Int
    $after: Binary
    $filter: WithdrawalProviderFilterInput
  ) {
    withdrawalProviders(filter: $filter, after: $after, first: $first) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          type
          dateTimeCreated
          ... on MidpayDirectWithdrawalProvider {
            id
            apiKey
            apiKeyPreview
            midPayDirectPaymentGateway {
              id
              name
              credentialFields
            }
            credentialValues
          }
          ... on MidpayWithdrawalProvider {
            apiKey
            apiKeyPreview
          }
          ... on DiorpayWithdrawalProvider {
            key
            keyPreview
            merchantCode
          }
        }
      }
    }
  }
`;

export const WITHDRAWAL_PROVIDER = gql`
  query withdrawalProvider($id: ID!) {
    withdrawalProvider(id: $id) {
      id
      name
      type
      ... on MidpayDirectWithdrawalProvider {
        id
        apiKey
        apiKeyPreview
        midPayDirectPaymentGateway {
          id
          name
          credentialFields
        }
        credentialValues
      }
      ... on MidpayWithdrawalProvider {
        apiKey
        apiKeyPreview
      }
      ... on DiorpayWithdrawalProvider {
        key
        keyPreview
        merchantCode
      }
      dateTimeCreated
      dateTimeUpdated
    }
  }
`;

export default {};
