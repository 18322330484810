import React, { ReactElement } from 'react';
import Drawer from 'pages/components/common/Drawer/Drawer';

const DrawerFooter = ({ children }: { children: ReactElement }) => (
  <Drawer.Footer>
    <div className="footer-actions d-flex justify-content-space-between w-100">
      {children}
    </div>
  </Drawer.Footer>
);

export default DrawerFooter;
