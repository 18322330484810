import React, { useState, useEffect } from 'react';
import {
  EmailAuditTrail,
  EmailAuditTrailConnectionEdge,
} from 'types/graphqlTypes';
import { formatDate } from 'utils/dateUtils';
import esGet from 'utils/esGet';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Modal, Spin, Table } from 'antd';
import { startCase } from 'lodash';
import { DATE_TIME_FORMAT } from 'constants/date';
import FiltersProvider, { useFilterValues } from 'contexts/Filters';
import { useAccount } from 'store/accountState';
import removeNull from 'utils/removeNull';
import EmailAuditTrailFilter from './EmailAuditTrailFilter';
import { EMAIL_TRANSACTIONS } from './queries';
import TriggerEventName from './TriggerEventName';

const EmailAuditTrailRecordContent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { account: me } = useAccount();

  const translate = useTranslate();
  const { filters, setFilters, defaultValues } = useFilterValues();

  const handleModalState = () => {
    setIsModalVisible((prev) => !prev);

    setFilters(defaultValues);
  };

  const [loadEmailTransactions, { data, loading }] = useLazyQuery(
    EMAIL_TRANSACTIONS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        filter: {
          admin: { eq: me.account?.id },
          ...removeNull(filters),
        },
      },
    }
  );

  const tableData = esGet(data?.emailAuditTrail?.edges, []).map(
    ({ node }: EmailAuditTrailConnectionEdge) => node
  );

  const columnFields = [
    {
      title: 'Date/Time Created',
      key: 'dateTimeCreated',
      render: (node: EmailAuditTrail) => {
        const dateTime = esGet(node?.dateTimeCreated, '-');
        const formattedDateTime = formatDate(dateTime, DATE_TIME_FORMAT);
        return <p>{formattedDateTime}</p>;
      },
    },
    {
      title: 'Trigger Event Name',
      key: 'triggerEventName',
      render: (node: EmailAuditTrail) => <TriggerEventName record={node} />,
    },
    {
      title: 'Sent To',
      key: 'sentTo',
      render: (node: EmailAuditTrail) => {
        const email = esGet(
          node?.sentTo,
          translate(messages['email-address-not-available.text'])
        );
        return <p>{email}</p>;
      },
    },
    {
      title: 'Trigger Source',
      key: 'source',
      render: (node: EmailAuditTrail) => {
        const triggerSource = esGet(node?.source, '-');
        return startCase(triggerSource.toLowerCase());
      },
    },
    {
      title: 'Sent Status',
      key: 'sent',
      render: (node: EmailAuditTrail) => {
        const sentStatus = esGet(node?.sent, '-');
        return (
          <p style={{ color: `${sentStatus ? 'green' : 'red'}` }}>
            {sentStatus ? 'Success' : 'Failed'}
          </p>
        );
      },
    },
  ];

  useEffect(() => {
    if (isModalVisible) loadEmailTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  return (
    <div>
      <h3>View Email Audit Trail</h3>
      <Button onClick={handleModalState}>View Logs - Email Transaction</Button>

      <Modal
        width="80%"
        title="View Logs - Email Transaction"
        visible={isModalVisible}
        onCancel={handleModalState}
        footer={null}
      >
        <EmailAuditTrailFilter
          filters={filters}
          setFilters={setFilters}
          defaultValues={defaultValues}
        />
        <Spin spinning={loading}>
          <Table
            style={{ height: '400px', overflow: 'auto' }}
            columns={columnFields}
            dataSource={tableData}
          />
        </Spin>
      </Modal>
    </div>
  );
};

const EmailAuditTrailRecord = () => (
  <FiltersProvider
    initialValues={{
      admin: null,
      startDateTime: null,
      endDateTime: null,
      triggerEventName: null,
      sentTo: null,
      source: null,
      blocked: null,
    }}
  >
    <EmailAuditTrailRecordContent />
  </FiltersProvider>
);

export default EmailAuditTrailRecord;
