import styled from 'styled-components';
import { Dropdown } from 'antd';

export const StyledProfileDropdown = styled(Dropdown)`
  text-align: center;
  margin: 0 8px;
  color: #fff !important;
  height: 40px;
  .ant-dropdown-link {
    line-height: '40px';
    display: 'block';
  }
`;

export default {};
