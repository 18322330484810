import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { NewClientDrawerContainer } from 'SuperAdminMain/shared/components/NewClientAccount/NewClientDrawerContainer/NewClientDrawerContainer';
import { Admin } from 'types/graphqlTypes';
import { useQuery } from '@apollo/react-hooks';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import { VENDOR_GROUP } from 'SuperAdminMain/components/AccountManagementNew/utils';
import { useAccountManagementValue } from '../../../../context';

type Props = {
  account: Admin;
};

export const EditClientInformation: React.FC<Props> = ({ account }) => {
  const { data } = useQuery(VENDOR_GROUP, {
    variables: {
      id: account.id,
    },
  });

  const vendors = coercedGet(data, 'admin.vendorGroup', null);

  const [isShow, setShow] = useState(false);
  const [userAccount, setUserAccount] = useState({});
  const handleShow = () => setShow(!isShow);
  const accountManagement = useAccountManagementValue();
  const formatAttachmentsUri = useCallback(() => {
    if (coercedGet(account, 'attachments', []).length > 0) {
      const splittedUris = account?.attachments?.map((attachment) => ({
        id: attachment.id,
        name: attachment.uri.split('/'),
      }));
      return splittedUris!.map((uri) => ({
        ...uri,
        name: uri.name[uri.name.length - 1],
      }));
    }
    return undefined;
  }, [account]);

  useEffect(() => {
    const obj: any = {};

    if (vendors) {
      obj.vendorGroup = vendors;
    }

    setUserAccount({
      ...account,
      ...(account.contractStartDateTime && {
        contractStartDateTime: moment(account.contractStartDateTime),
      }),
      ...(account.contractEndDateTime && {
        contractEndDateTime: moment(account.contractEndDateTime),
      }),
      ...obj,
      attachments: formatAttachmentsUri(),
    });
  }, [account, formatAttachmentsUri, vendors]);

  return (
    <>
      <Button type="link" block onClick={handleShow}>
        <FormattedMessage id="edit.text" defaultMessage="Edit" />
      </Button>

      <NewClientDrawerContainer
        existingValues={userAccount}
        drawerState={isShow}
        closeDrawer={() => setShow(false)}
        refetchFunction={() => accountManagement.refetch()}
      />
    </>
  );
};
