import React, { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { Tag, Spin } from 'antd';

export const MEMBER = gql`
  query Member($id: ID) {
    member(id: $id) {
      username
      realName
    }
  }
`;

type Props = {
  id: string;
  onClose: Function;
  fieldName: string;
};

// & this component is used for filter condition(s) tags that uses id as filter

const MemberTag: FC<Props> = ({ id, onClose, fieldName }) => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

  const { data, loading } = useQuery(MEMBER, {
    variables: {
      id,
    },
  });

  return (
    <Tag closable onClose={(e) => onClose(e)}>
      {loading ? (
        <Spin indicator={loadingIcon} />
      ) : (
        coercedGet(data, `member.${fieldName}`, null)
      )}
    </Tag>
  );
};

export default MemberTag;
