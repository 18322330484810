import React from 'react';
import RequestUpdateToggle from 'components/RequestUpdateToggle';
import DownloadCsvGlobal from 'components/DownloadCsvGlobal';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import { Divider } from 'antd';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';

type Props = {
  isRealtime?: boolean;
  handleSetRealtime?: () => void;
  refreshPage: () => void;
};

const HeaderRight: React.FC<Props> = ({
  handleSetRealtime,
  isRealtime,
  refreshPage,
}) => {
  const { role, permissions } = usePermissions();
  const {
    ALLOWED_CSV_DOWNLOAD_BUTTON: AllowedRebateReports,
  } = collectPermissions(
    role,
    permissions,
    ['CSV_DOWNLOAD_BUTTON'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS_REBATES_REPORTS
  );

  const {
    ALLOWED_CSV_DOWNLOAD_BUTTON: AllowedPromoPayoutReports,
  } = collectPermissions(
    role,
    permissions,
    ['CSV_DOWNLOAD_BUTTON'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS_PROMO_PAYOUT_RECORDS
  );
  return (
    <div className="d-flex align-items-center">
      <RequestUpdateToggle checked={isRealtime} onChange={handleSetRealtime} />
      <RefreshButton onClick={() => refreshPage()} />
      <Divider type="vertical" />
      <div className="mr-1">
        <ColumnsFilterNew
          iconOnly
          tabId="total-number-of-participating-member"
        />
      </div>
      <div className="mr-1">
        <DownloadCsvGlobal
          permission={AllowedRebateReports || AllowedPromoPayoutReports}
          iconOnly
          fileName={useTranslate()(
            messages.TOTAL_NUMBER_OF_PARTICIPATING_MEMBER
          )}
        />
      </div>
    </div>
  );
};

export default HeaderRight;
