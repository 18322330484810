import { Form } from '@ant-design/compatible';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DynamicObj } from 'interfaces/user.interface';
import FilterItems from '../FilterItems';

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (data: Record<string, any>) => void;
  onClose?: () => void;
  isLoading: boolean;
};

const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

const SearchSettingsForm = ({
  onClose,
  isLoading,
  onSubmit,
  initialValues,
}: Props) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <StyledForm>
      <Row>
        <Col span={12}>
          <FilterItems
            filters={values}
            onFilterChange={setValues}
            onRawFilterChange={handleChange}
          />
        </Col>
      </Row>
      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmit(values)}
          loading={isLoading}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
