import gql from 'graphql-tag';

export const MEMBER_GAME_REPORT = gql`
  query MemberGameReport(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
    $startDateTime: DateTime
    $endDateTime: DateTime
  ) {
    members(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          username
          betsCount(startDateTime: $startDateTime, endDateTime: $endDateTime)
          turnover(startDateTime: $startDateTime, endDateTime: $endDateTime)
          winloss(startDateTime: $startDateTime, endDateTime: $endDateTime)
          # profitRatio(startDateTime: $startDateTime, endDateTime: $endDateTime)
          effectiveBet(startDateTime: $startDateTime, endDateTime: $endDateTime)
        }
      }
    }
  }
`;

export const USERNAME_OPTIONS = gql`
  query MemberGameReport(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          username
        }
      }
    }
  }
`;

export default {};
