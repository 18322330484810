import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
// import { formatDate } from 'utils/dateUtils';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';
import useExpandableFilterList from 'SuperAdminMain/hooks/useExpandableFilterList';
import { isObjectPropsEmpty } from 'utils/isObjectPropsEmpty/isObjectPropsEmpty';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import { useScreenTabSA } from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';
import { TabIdSA } from 'SuperAdminMain/constants/route';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import ModifiedTag from './components/ModifiedTag';
import VendorTag from './components/VendorTag';
import GameCategoryTag from './components/GameCategoryTag';

const FilterConditions = () => {
  const translate = useTranslate();
  const { filters, setFilters, defaultValues } = useFilterValues();
  const { clearTabState } = useScreenTabSA(TabIdSA.BET_HISTORY_REPORT);
  const [localDateFilters, setLocalDateFilters] = React.useState<any>({});
  const { vendor, status, game, timezone } = filters;

  const [
    containerRef,
    { isTagsHidden, setIsTagsHidden },
    { showToggleArrow },
  ] = useExpandableFilterList() as any;

  React.useEffect(() => {
    const transformedFilters = transformMovableDateFilter({
      dateTimeProcessed: {
        gte: get(filters, 'dateTimeProcessed.gte'),
        lte: get(filters, 'dateTimeProcessed.lte'),
      },
    });

    setLocalDateFilters(transformedFilters);
  }, [filters]);

  let { dateTimeProcessed } = localDateFilters;

  dateTimeProcessed =
    dateTimeProcessed && dateTimeProcessed.gte && dateTimeProcessed.lte
      ? dateTimeProcessed
      : null;

  const handleRemoveDateFilter = (dateString: string) => {
    setFilters((prev: Record<string, any>) => ({
      ...prev,
      [dateString]: null,
    }));
  };
  const handleRemoveTimezoneFilter = (e: any) => {
    e.preventDefault();
    setFilters((prev: Record<string, any>) => ({
      ...prev,
      timezone: null,
    }));
  };

  const handleRemoveFilterTag = (
    name: string,
    value: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const field = coercedGet(filters, name, []);
    const data = field.in.filter((item: string) => item !== value);
    const newField = {
      in: data,
    };

    setFilters((prev: Record<string, any>) => ({
      ...prev,
      [`${name}`]: data.length ? newField : null,
    }));
  };

  return (
    <div className="d-flex  align-items-center">
      <StyledTagWrapper
        className="mt-1 ml-3 "
        ref={containerRef}
        style={{ width: '40vw' }}
      >
        <FormattedMessage
          id="filter-conditions.text"
          defaultMessage="Filter Conditions"
        />
        :
        <span className="mr-2" />
        {dateTimeProcessed ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveDateFilter('dateTimeProcessed')}
          >
            <FormattedMessage
              id="reports.period.text"
              defaultMessage="Period"
            />
            : {formatDate(dateTimeProcessed.gte)}~
            {formatDate(dateTimeProcessed.lte)}
          </ModifiedTag>
        ) : null}
        {vendor &&
          vendor.in.map((item: string, key: number) => (
            <VendorTag
              id={item}
              onClose={(e) => handleRemoveFilterTag('vendor', item, e)}
              key={key}
            />
          ))}
        {status
          ? status.in.map((item: string, key: number) => (
              <ModifiedTag
                key={key}
                closable
                onClose={(e: any) => handleRemoveFilterTag('status', item, e)}
              >
                {translate(messages[`${item}`])}
              </ModifiedTag>
            ))
          : null}
        {game
          ? game.in.map((item: string, key: number) => (
              <GameCategoryTag
                key={key}
                onClose={(e) => handleRemoveFilterTag('game', item, e)}
                id={item}
              />
            ))
          : null}
        {timezone ? (
          <ModifiedTag
            closable
            onClose={(e: any) => handleRemoveTimezoneFilter(e)}
          >
            {timezone}
          </ModifiedTag>
        ) : null}
      </StyledTagWrapper>

      {showToggleArrow && !isObjectPropsEmpty(filters) && (
        <LegacyIcon
          type={isTagsHidden ? 'double-right' : 'double-left'}
          className="cursor-pointer mr-2 mt-1"
          onClick={() => {
            setIsTagsHidden((prev: Record<string, any>) => !prev);
          }}
        />
      )}
      <ClearAllFilterButton
        className="mt-1"
        initialFilters={defaultValues}
        currentFilters={filters}
        onFilterChange={(value) => {
          setFilters(value);
          clearTabState();
        }}
      />
    </div>
  );
};

const StyledTagWrapper = styled.div`
  /* display: flex;
  justify-content: flex-start; */
`;

export default FilterConditions;
