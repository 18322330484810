export const FeatureFlags = {
  nextGameCategoryDesktopIcon: 'nextGameCategoryDesktopIcon',
  nextMarqueeMessages: 'nextMarqueeMessages',
  nextAffiliates: 'nextAffiliates',
  nextPromoPayoutRecords: 'nextPromoPayoutRecords',
  nextMemberPayoutRecords: 'nextMemberPayoutRecords',
  nextAffiliateCriteria: 'nextAffiliateCriteria',
  nextAffiliateRequest: 'nextAffiliateRequest',
  nextPermissions: 'nextPermissions',
  nextBalanceServiceRecords: 'nextBalanceServiceRecords',
  nextRebatesReports: 'nextRebatesReports',
  nextMemberBetRecords: 'nextMemberBetRecords',
  nextThirdPartyDepositProviders: 'nextThirdPartyDepositProviders',
  nextPaymentMethodListing: 'nextPaymentMethodListing',
  nextInternalDepositRequests: 'nextInternalDepositRequests',
  nextExternalDepositRequests: 'nextExternalDepositRequests',
  nextAutomatedMessage: 'nextAutomatedMessage',
  nextWithdrawalMethods: 'nextWithdrawalMethods',
  nextWithdrawalProviders: 'nextWithdrawalProviders',
  nextMemberMarker: 'nextMemberMarker',
  nextMemberIPAddressReport: 'nextMemberIpAddressReport',
  nextMemberLabels: 'nextMemberLabels',
  nextAffiliateProgramme: 'nextAffiliateProgramme',
  nextWithdrawalRequests: 'nextWithdrawalRequests',
  nextRebates: 'nextRebates',
  nextVipManagement: 'nextVipManagement',
  sentry: 'sentry',
  logrocket: 'logrocket',
  fullstory: 'fullstory',
  nextRotatingBanner: 'nextRotatingBanner',
  nextAlertsNotification: 'nextAlertsNotification',
  nextCryptoPaymentMethod: 'nextCryptoPaymentMethod',
  midpay: 'midpay',
  nextBtrRemarks: 'nextBtrRemarks',
  nextPromoBannerUpload: 'nextPromoBannerUpload',
  withdrawalHandlingFeeType: 'withdrawalHandlingFeeType',
  depositSourceConfig: 'depositSourceConfig',
  diorPay2: 'diorPay2',
  memberInteractionLog: 'memberInteractionLog',
  vendorTargeting: 'promoVendorTargeting',
  missions: 'missions',
  horizontalImage: 'horizontalImage',
  nextPromoAutoApprove: 'nextPromoAutoApprove',
  nextPromoDetailsUpdate: 'nextPromoDetailsUpdate',
  nextPromoRequestPopupUpdates: 'nextPromoRequestPopupUpdates',
  lossRebatePromo: 'lossRebatePromo',
  nextPromoConflict: 'nextPromoConflict',
  nextMultiVipColumn: 'nextMultiVipColumn',
  nextPromoVip: 'nextPromoVip',
  nextPromoConflictSelect: 'nextPromoConflictSelect',
  nextTotalBudget: 'nextTotalBudget',
  nextMemberManagement: 'nextMemberManagement',
  euRequirements: 'euRequirements',
};

export default {};
