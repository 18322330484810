import gql from 'graphql-tag';

export const BULK_UPDATE_MEMBER_STATUS = gql`
  mutation BulkUpdateMemberStatus($file: ID!, $brandLinkage: Boolean!) {
    bulkUpdateMemberStatus(file: $file, brandLinkage: $brandLinkage)
  }
`;

export const BULK_UPDATE_MEMBER_TAGS = gql`
  mutation BulkUpdateMemberTags(
    $file: ID!
    $action: BulkUpdateMemberTagsAction!
  ) {
    bulkUpdateMemberTags(file: $file, action: $action)
  }
`;
export const BULK_UPDATE_GAME_ROUNDS = gql`
  mutation BulkUpdateGameRounds($file: ID!) {
    bulkUpdateGameRounds(file: $file)
  }
`;
export const MEMBER_BULK_STATUS_UPDATE_RECORD = gql`
  query MemberBulkStatusUpdateRecord {
    memberBulkStatusUpdateRecord {
      initiator {
        ... on Admin {
          username
        }
      }
      dateTimeDone
      dateTimeCreated
      result
      totalRows
      processedRows
      status
    }
  }
`;

export const MEMBER_BULK_TAGS_UPDATE_RECORD = gql`
  query MemberBulkTagsUpdateRecord {
    memberBulkTagsUpdateRecord {
      initiator {
        ... on Admin {
          username
        }
      }
      result {
        uri
      }
      dateTimeDone
      dateTimeCreated
      totalRows
      processedRows
      status
      error {
        message
        code
        labels
      }
    }
  }
`;

export const MEMBER_BULK_GAME_ROUNDS_RECORD = gql`
  query BulkUpdateGameRoundRecord {
    bulkUpdateGameRoundRecord {
      initiator {
        ... on Admin {
          username
        }
      }
      dateTimeDone
      dateTimeCreated
      totalRows
      processedRows
      status
    }
  }
`;

export const BULK_CREATE_MEMBER_NOTE = gql`
  mutation BulkCreateMemberNote($file: ID!) {
    bulkCreateMemberNote(file: $file)
  }
`;

export const MEMBER_BULK_NOTE_CREATE_RECORD = gql`
  query MemberBulkNoteCreateRecord {
    memberBulkNoteCreateRecord {
      initiator {
        ... on Admin {
          username
        }
      }
      dateTimeDone
      dateTimeCreated
      result
      totalRows
      processedRows
      status
    }
  }
`;
