import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import thirdPartyProviders from 'constants/thirdPartyProviders';
import { FeatureFlags } from 'constants/featureFlags';
import useIsNext from 'hooks/useIsNext';
import { FilterProps } from 'interfaces/filters.interface';

const Checkbox: any = AntdCheckbox;
const ThirdPartyProvidersTypeFilter = ({ values, onChange }: FilterProps) => {
  const translate = useTranslate();
  const isNext = useIsNext(FeatureFlags.diorPay2);

  const options = [
    {
      value: thirdPartyProviders.DIORPAY,
      name: translate(messages.DIORPAY),
    },
    {
      value: thirdPartyProviders.MIDPAY,
      name: translate(messages.MIDPAY),
    },
    {
      value: thirdPartyProviders.MIDPAY_DIRECT,
      name: translate(messages.MIDPAY_DIRECT),
    },

    ...(isNext
      ? [
          {
            value: thirdPartyProviders.DIORPAY2,
            name: translate(messages.DIORPAY2),
          },
        ]
      : []),
  ];

  const handleChange = ({
    target,
  }: {
    target: {
      name: string;
    };
  }) => {
    const { name } = target;
    let newValues = [...values];

    if (values.includes(name)) {
      newValues = values.filter((value: string) => value !== name);
    } else {
      newValues = [...newValues, name];
    }

    onChange(newValues);
  };

  return (
    <div>
      {options.map((option) => (
        <div className="mb-2" key={option.value}>
          <Checkbox
            data-testid={`depositProviderType_${option.value}`}
            name={option.value}
            checked={values.length ? values.includes(option.value) : false}
            onChange={handleChange}
          >
            {option.name}
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default ThirdPartyProvidersTypeFilter;
