import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, DatePicker, Switch, Spin } from 'antd';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { omit, pick } from 'lodash';
import getFieldsFromSchema from 'utils/getFieldsFromSchema';
import { getNewProgrammeSchemaObject } from 'pages/components/MemberLoyaltyManagement/components/CreateProgramme/context';
import useTranslate from 'utils/useTranslate';
import { StyledForm } from './styles';
import messages from './messages';
import {
  allTypes,
  useCreateLoyaltyProgramContext,
  usePrimaryVipContext,
} from '../../context';

const { Item } = Form;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const CustomItem: any = Item;
const CustomSelect: any = Select;
const CustomSwitch: any = Switch;

const colStyles = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const InnerForm = React.forwardRef(({ presetValues, loading }: any, ref) => {
  const translate = useTranslate();

  const [contextState, dispatch] = useCreateLoyaltyProgramContext() as any;
  const isPrimaryVip = usePrimaryVipContext();
  const isNotPrimaryVip = !isPrimaryVip;

  const includedFields = getFieldsFromSchema(
    getNewProgrammeSchemaObject(translate, isPrimaryVip)
  );

  const initialValues = pick(
    {
      name: '',
      description: '',
      validityStartDate: '',
      validityEndDate: '',
      automaticMemberLoyaltyLevelUpgrade: false,
      status: 'DRAFT',
      badgeSet: 'PRIMARY',
      ...contextState.activeProgramme,
      ...omit(presetValues, ['id', '__typename']),
    },
    includedFields
  );

  const {
    handleChange,
    setFieldValue,
    errors,
    touched,
    values,
    handleSubmit,
    setValues,
    validateForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape(
      getNewProgrammeSchemaObject(translate, isPrimaryVip)
    ),
    onSubmit: (formValues) => {
      dispatch({
        type: allTypes.SET_ACTIVE_PROGRAMME,
        payload: {
          ...contextState.activeProgramme,
          ...formValues,
        },
      });
      dispatch({
        type: allTypes.SET_ACTIVE_SCREEN,
        payload: allTypes.TIER_SETTINGS,
      });
    },
  });

  React.useImperativeHandle(
    ref,
    () => ({
      isLoading: loading,
      getFormValues: () => values,
      handleSubmit,
      validateForm,
    }),
    [handleSubmit, loading, validateForm, values]
  );

  if (loading)
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: 200 }}
      >
        <Spin />
      </div>
    );

  return (
    <StyledForm>
      <Item
        colon={false}
        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        label={
          <strong>
            <FormattedMessage
              id="general-settings.text"
              defaultMessage="General Settings"
            />
          </strong>
        }
        {...colStyles}
      >
        &nbsp;
      </Item>

      <Item
        label={translate(messages['member-loyalty.programme-name.text'])}
        {...colStyles}
        validateStatus={errors.name && touched.name ? 'error' : ''}
        help={errors.name && touched.name ? errors.name : null}
      >
        <Input
          className="mr-3"
          size="large"
          name="name"
          value={values.name}
          onChange={handleChange}
          placeholder={translate(
            messages['member-loyalty.programme-name.text']
          )}
          style={{ width: '75%' }}
        />
      </Item>

      <Item
        label={translate(messages['member-loyalty.programme-description.text'])}
        {...colStyles}
        validateStatus={
          errors.description && touched.description ? 'error' : ''
        }
        help={
          errors.description && touched.description ? errors.description : null
        }
      >
        <TextArea
          className="mr-3"
          size="large"
          name="description"
          value={values.description}
          onChange={handleChange}
          placeholder={translate(
            messages['member-loyalty.programme-description.text']
          )}
          autoSize={{ minRows: 3, maxRows: 5 }}
          style={{ width: '75%', fontSize: 16 }}
        />
      </Item>

      {isNotPrimaryVip && (
        <CustomItem
          hidden={values.primary}
          label={
            <FormattedMessage
              id="effective-date-range.text"
              defaultMessage="Effective Date Range"
            />
          }
          {...colStyles}
          validateStatus={
            errors.validityStartDate && touched.validityStartDate ? 'error' : ''
          }
          help={
            errors.validityStartDate && touched.validityStartDate
              ? errors.validityStartDate
              : null
          }
        >
          <RangePicker
            size="large"
            style={{ width: '75%' }}
            format="YYYY-MM-DD"
            value={
              [
                ...(values.validityStartDate && values.validityEndDate
                  ? [
                      moment(values.validityStartDate),
                      moment(values.validityEndDate),
                    ]
                  : []),
              ] as any
            }
            onChange={(_, [validityStartDate, validityEndDate]) => {
              setValues({
                ...values,
                validityStartDate,
                validityEndDate,
              });
            }}
          />
        </CustomItem>
      )}

      <Item
        label={
          <FormattedMessage id="badge-set.text" defaultMessage="Badge Set" />
        }
        {...colStyles}
        validateStatus={errors.badgeSet && touched.badgeSet ? 'error' : ''}
        help={errors.badgeSet && touched.badgeSet ? errors.badgeSet : null}
      >
        <CustomSelect
          style={{ width: '75%' }}
          size="large"
          placeholder={
            <FormattedMessage
              id="member-loyalty.please-select.text"
              defaultMessage="Please select"
            />
          }
          value={values.badgeSet}
          name="badgeSet"
          onChange={(badgeSet: any) => {
            setFieldValue('badgeSet', badgeSet);
          }}
        >
          <Option key="1" value="PRIMARY">
            <FormattedMessage id="primary.text" defaultMessage="Primary" />
          </Option>
          <Option key="2" value="DARK_BLUE">
            <FormattedMessage id="dark-blue.text" defaultMessage="Dark Blue" />
          </Option>
          <Option key="3" value="LIGHT_BLUE">
            <FormattedMessage
              id="light-blue.text"
              defaultMessage="Light Blue"
            />
          </Option>
        </CustomSelect>
      </Item>

      <Item
        label={
          <FormattedMessage
            id="automatic-upgrade.text"
            defaultMessage="Automatic Upgrade"
          />
        }
        {...colStyles}
        validateStatus={
          errors.automaticMemberLoyaltyLevelUpgrade &&
          touched.automaticMemberLoyaltyLevelUpgrade
            ? 'error'
            : ''
        }
        help={
          errors.automaticMemberLoyaltyLevelUpgrade &&
          touched.automaticMemberLoyaltyLevelUpgrade
            ? errors.automaticMemberLoyaltyLevelUpgrade
            : null
        }
      >
        <CustomSwitch
          name="automaticMemberLoyaltyLevelUpgrade"
          checkedChildren={translate(messages['member-loyalty.on.text'])}
          unCheckedChildren={translate(messages['member-loyalty.off.text'])}
          defaultChecked
          checked={values.automaticMemberLoyaltyLevelUpgrade}
          onChange={(checked: any) =>
            setFieldValue('automaticMemberLoyaltyLevelUpgrade', checked)
          }
          className="mr-4"
        />
      </Item>
    </StyledForm>
  );
});

export default InnerForm;
