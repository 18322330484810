import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { BALANCE_FORM_EXTRA_VALUES } from 'constants/balanceFormInitialValues';
import gql from 'graphql-tag';
import { CREATE_MANUAL_ADJUSTMENT } from 'graphql/mutations/manualAdjustment.mutation';
import {
  PROMO_PAYOUT,
  turnoverShowTypes,
} from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/utils';
import messages from 'messages';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMethod } from 'store/methodState';
import coercedGet from 'utils/coercedGet';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import { get, includes } from 'lodash';
import BalanceForm from '../BalanceForm';
import { actualShowTypes } from '../BalanceForm/components/utils';
import { StyledModal } from './styles';

const MEMBER_AFFILIATE = gql`
  query MemberAffiliate($id: ID!) {
    member(id: $id) {
      id
      affiliate {
        id
        code
      }
    }
  }
`;

const PROMOS = gql`
  query Promos($filter: PromosFilterInput!) {
    promos(filter: $filter) {
      totalCount
    }
  }
`;

export interface ClosedLoop {
  showClosedLoopModal: boolean;
  closedLoopBlocked: boolean;
  setClosedLoopState: React.Dispatch<
    React.SetStateAction<{
      showModal: boolean;
      closeLoopBlocked: boolean;
    }>
  >;
}

type Props = {
  member: Record<string, any>;
  buttonType?: string;
  refetchFunction: (e: any) => void;
  showImportExcel?: boolean;
};

// eslint-disable-next-line no-unused-vars
const EditBalance = ({
  member,
  buttonType = 'link',
  refetchFunction,
  showImportExcel = false,
}: Props) => {
  const [isShow, setShow] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleShow = () => setShow(!isShow);
  const [memberNotAffiliate, setMemberNotAffiliate] = useState(true);
  const [closedLoopState, setClosedLoopState] = useState({
    showModal: false,
    closeLoopBlocked: false,
  });
  const client = useApolloClient();

  const translate = useTranslate();
  const { context } = useOperatorHeader();

  const [createManualAdjustment] = useMutation(CREATE_MANUAL_ADJUSTMENT, {
    onCompleted: (data) => {
      if (data)
        setClosedLoopState({
          closeLoopBlocked: false,
          showModal: false,
        });
    },
  });

  const fetchValidationForType = useCallback(async () => {
    const { data } = await client.query({
      query: MEMBER_AFFILIATE,
      variables: { id: member.id },
    });
    const notAffiliate = !coercedGet(data, 'member.affiliate.code');
    setMemberNotAffiliate(notAffiliate);
  }, [client, member.id]);

  const { setSelectedMethod } = useMethod();

  const method = useSelector(
    (state: { method: { selectedMethod: string } }) => state?.method
  );

  useEffect(() => {
    fetchValidationForType();
  }, [fetchValidationForType]);

  const handleSubmit = async ({
    amount,
    actual,
    type,
    remarks,
    accountPassword,
    turnover,
    promo,
    hexoPayCreditCard,
    disableClosedLoop,
    withdrawalMethod,
    email,
  }: any) => {
    const currentMethod = method.selectedMethod;
    setIsSubmitting(true);

    if (type === PROMO_PAYOUT) {
      const { data: promoData } = await client.query({
        query: PROMOS,
        fetchPolicy: 'network-only',
        variables: {
          filter: {
            id: {
              eq: promo,
            },
          },
        },
      });

      const promoValid = coercedGet(promoData, 'promos.totalCount', 0) > 0;

      if (!promoValid) {
        setIsSubmitting(false);
        return message.warning(translate(messages.PROMO_DOES_NOT_EXIST));
      }
    }

    const optionalPromo = () => {
      if (type === PROMO_PAYOUT) {
        return { promo };
      }

      return {};
    };

    const dataToSend = {
      input: {
        account: member.id,
        amount: +amount,
        ...(actualShowTypes.includes(type) && { actual }),
        type,
        remarks,
        ...(turnoverShowTypes.includes(type) && {
          turnoverRequirementMultiplier: +turnover,
        }),
        ...optionalPromo(),
        ...(type === 'WITHDRAWAL' && currentMethod === 'CARD'
          ? { hexoPayCreditCard }
          : {}),
        ...(closedLoopState.closeLoopBlocked && { disableClosedLoop }),
        ...(type === 'MANUAL_WITHDRAWAL' && {
          withdrawalMethod,
          email,
        }),
      },
      password: accountPassword,
    };

    try {
      await createManualAdjustment({
        variables: dataToSend,
        context,
      });

      if (refetchFunction) {
        refetchFunction(member.id);
      }

      message.success(translate(messages.BALANCE_ADJUSTED));
      return handleShow();
    } catch (err) {
      const error = get(err.graphQLErrors[0], 'original');
      const errorCode = error?.code;

      if (
        errorCode === 'WITHDRAWAL_CLOSED_LOOP_ERROR' ||
        includes(error.message, 'WITHDRAWAL_CLOSED_LOOP_ERROR')
      ) {
        setClosedLoopState({
          closeLoopBlocked: true,
          showModal: true,
        });
      }
    } finally {
      // set method state to default CARD
      setSelectedMethod('CARD');
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        type={buttonType as any}
        className={`${buttonType === 'primary' ? 'text-white' : 'text-black'}`}
        onClick={handleShow}
        block
      >
        <FormattedMessage
          id="edit-balance.text"
          defaultMessage="Edit Balance"
        />
      </Button>
      {isShow && (
        <StyledModal
          title={
            <span className="fs-14">
              <FormattedMessage
                id="edit-balance.text"
                defaultMessage="Edit Balance"
              />
            </span>
          }
          visible
          footer={false}
          onCancel={handleShow}
          width={400}
        >
          <BalanceForm
            initialValues={{
              action: 'ADD',
              type: '',
              amount: '',
              actual: false,
              remarks: '',
              accountPassword: '',
              disableClosedLoop: true,
              ...BALANCE_FORM_EXTRA_VALUES,
            }}
            type="single"
            memberNotAffiliate={memberNotAffiliate}
            showImportExcel={showImportExcel}
            isSubmitting={isSubmitting}
            onClose={handleShow}
            onSubmit={handleSubmit as any}
            memberId={member.id}
            closedLoopState={{
              showClosedLoopModal: closedLoopState.showModal,
              closedLoopBlocked: closedLoopState.closeLoopBlocked,
              setClosedLoopState,
            }}
          />
        </StyledModal>
      )}
    </>
  );
};

export default EditBalance;
