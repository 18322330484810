import React, { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useLazyQuery } from '@apollo/react-hooks';
import { Select, message } from 'antd';
import coercedGet from 'utils/coercedGet';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { VendorsConnectionEdge } from 'types/graphqlTypes';
import { useIntl } from 'react-intl';
import { VENDORS } from './queries';

const { Option } = Select;

type Props = {
  isCustomFilter?: boolean;
  onChange: (e: Array<string> | string) => void;
  placeHolder?: string;
  value: Array<string> | string | undefined;
  disabled?: boolean;
  multiple?: boolean;
  queryFilter?: { [key: string]: any };
};

const VendorSelect: React.FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
  queryFilter,
  isCustomFilter,
}) => {
  const { locale } = useIntl();
  const [filter, setFilter] = useState({
    shortcode: { contains: '' },
  });
  const translate = useTranslate();

  const newFilter = { ...filter, ...queryFilter };
  const variables = {
    filter: newFilter,
    first: 10,
    language: locale.toUpperCase(),
  };

  const [loadOptions, { loading, data }] = useLazyQuery(VENDORS, {
    variables,
    context: {
      component: true,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      message.error({
        content: <span>{translate(messages.INTERNAL_SERVER_ERROR)}</span>,
      });
    },
  });

  const handleSearch = (e: string) => {
    setFilter({
      shortcode: {
        contains: e,
      },
    });
  };
  const handleChange = (e: string | Array<string>) => {
    onChange(e);
  };

  const edges = coercedGet(data, 'vendors.edges', []);

  useEffect(() => {
    if (isCustomFilter) loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      data-testid="vendor-select"
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      loading={loading}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
      onDropdownVisibleChange={(open) => {
        if (open && !edges.length) loadOptions();
      }}
    >
      {// eslint-disable-next-line no-shadow
      edges.map((edge: VendorsConnectionEdge) => (
        <Option key={edge.node.id} value={edge.node.id}>
          {edge.node?.shortcode} - {edge.node?.name}
        </Option>
      ))}
    </Select>
  );
};

export default VendorSelect;
