import Icon from '@ant-design/icons';
import { useApolloClient } from '@apollo/react-hooks';
import { Badge, Divider, Dropdown, Empty, Menu, Spin, Tooltip } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { showPermissionError } from 'components/Navbar/Navbar';
import { DATE_TIME_FORMAT } from 'constants/date';
import { capitalize, truncate } from 'lodash';
import messages from 'messages';
import moment from 'moment';
import ExternalDepositDetails from 'pages/components/ExternalMemberDeposits/components/DepositDetails';
import InternalDepositDetails from 'pages/components/InternalMemberDeposits/components/DepositDetails';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useScreenTabV2 } from 'store/screenTabState';
import styled from 'styled-components';
import { Status } from 'types';
import { Deposit, HexoPayDeposit } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import { formatDate } from 'utils/dateUtils';
import useMemberCurrency from 'utils/useMemberCurrency';
import useTranslate from 'utils/useTranslate';
import { DynamicObj, Member } from '../../../../../interfaces/user.interface';
import { isInternalDepositByPaymentMethodTypename } from '../../../../../utils/depositWithdrawalTypename';
import { useNewMemberDetailPermissions } from '../../constants';
import { statusTypeDepositWithdrawal } from '../../utils';
import { ReactComponent as FirstDepositIcon } from '../assets/first.svg';
import { ReactComponent as RemarksIcon } from '../assets/remark.svg';
import { TransactionContent } from '../TransactionContent/TransactionContent';
import { DEPOSITS } from './queries';

const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    width: 8px !important;
    height: 8px !important;
  }
`;

export const MANUAL_DEPOSIT = 'ManualDeposit';

export const LastDeposit = ({ maxItemCount }: { maxItemCount: number }) => {
  const [deposits, setDeposits] = useState<any[]>([]);
  const [totalDeposits, setTotalDeposits] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedDepositId, setSelectedDepositId] = useState<string>('');
  const [depositType, setDepositType] = useState<'INTERNAL' | 'EXTERNAL' | ''>(
    ''
  );
  const { member } = useMember()! as { member: Member };
  const { addTab } = useScreenTabV2();
  const client = useApolloClient();
  const { addCurrency } = useMemberCurrency();
  const translate = useTranslate();
  const {
    ALLOWED_INTERNAL_DEPOSITS,
    ALLOWED_EXTERNAL_DEPOSITS,
  } = useNewMemberDetailPermissions();

  const loadManualDeposits = async () => {
    setIsLoading(true);
    const responseDeposit = await client.query<{ deposit: Deposit }>({
      query: DEPOSITS,
      variables: {
        id: member.id,
        first: maxItemCount,
      },
      fetchPolicy: 'network-only',
    });

    const depositsData = coercedGet(
      responseDeposit,
      'data.member.deposits',
      {}
    );
    const { totalCount, edges } = depositsData;
    const depositNodes = edges.map(
      (edge: { node: HexoPayDeposit }) => edge.node
    );

    setTotalDeposits(totalCount);
    setDeposits(depositNodes);
    setIsLoading(false);
  };

  useEffect(() => {
    loadManualDeposits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notTheLastItem = (index: number) => index !== maxItemCount - 1;

  const openDepositInternalTab = (filter?: DynamicObj) => {
    if (!ALLOWED_INTERNAL_DEPOSITS) {
      showPermissionError('Deposit Requests (Internal) - View');
      return;
    }
    addTab({
      id: 'deposit-requests-internal',
      state: {
        memberIdRef: member.id,
        ...filter,
      },
    });
  };

  const openDepositExternalTab = (filter?: DynamicObj) => {
    if (!ALLOWED_EXTERNAL_DEPOSITS) {
      showPermissionError('Deposit Requests (3rd party) - View');
      return;
    }
    addTab({
      id: 'deposit-requests-external',
      state: {
        memberIdRef: member.id,
        ...filter,
      },
    });
  };

  const linkToDepositPage = (deposit: HexoPayDeposit) => {
    const isManual = deposit.manualAdjustment;
    const isInternalDeposit = isInternalDepositByPaymentMethodTypename(
      coercedGet(deposit, 'paymentMethod.__typename')
    );

    setSelectedDepositId(deposit.id);

    if (isInternalDeposit || isManual) {
      if (!ALLOWED_INTERNAL_DEPOSITS) {
        showPermissionError('Deposit Requests (Internal) - View');
        return;
      }
      setDepositType('INTERNAL');
      setSelectedDepositId(deposit.id);
    } else {
      if (!ALLOWED_EXTERNAL_DEPOSITS) {
        showPermissionError('Deposit Requests (3rd party) - View');
        return;
      }
      setDepositType('EXTERNAL');
    }
  };

  const renderDepositType = (deposit: HexoPayDeposit) => {
    const isManual = deposit.manualAdjustment;
    const isInternalDeposit =
      isManual ||
      isInternalDepositByPaymentMethodTypename(
        coercedGet(deposit, 'paymentMethod.__typename')
      );

    if (isManual) {
      return translate(messages.MANUAL);
    }

    if (isInternalDeposit) {
      return translate(messages.INTERNAL);
    }

    return translate(messages.THIRD_PARTY);
  };

  const toggleModal = () => {
    setSelectedDepositId('');
    setDepositType('');
  };

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center member-profile-tab-container">
      <Spin>{translate(messages['loading.text'])}</Spin>
    </div>
  ) : (
    <>
      {totalDeposits === 0 && (
        <div className="d-flex justify-content-center align-items-center member-profile-tab-container">
          <Empty />
        </div>
      )}
      {totalDeposits > 0 && (
        <div className="d-flex flex-column justify-content-space-between member-profile-tab-container">
          {depositType === 'INTERNAL' && (
            <InternalDepositDetails
              disabled
              toggleModal={toggleModal}
              id={selectedDepositId}
              refetchVariables={{}}
              refetchDepositRequests={() => {}}
            />
          )}
          {depositType === 'EXTERNAL' && (
            <ExternalDepositDetails
              id={selectedDepositId}
              toggleModal={toggleModal}
              refetchVariables={{}}
            />
          )}
          <div style={{ padding: '16px 16px 0 16px' }}>
            {deposits.map((deposit, index) => {
              const { hexoPayCreditCard, depositorBlockchainAddress } =
                deposit || {};
              const {
                firstDigit,
                firstSixDigits,
                lastFourDigits,
                expirationYear,
                expirationMonth,
              } = hexoPayCreditCard || {};

              return (
                <React.Fragment key={deposit.id}>
                  <TransactionContent
                    leftNode={{
                      title: (
                        <>
                          <Tooltip
                            title={capitalize(deposit.status)}
                            placement="left"
                          >
                            <StyledBadge
                              status={
                                statusTypeDepositWithdrawal[
                                  deposit.status
                                ] as Status
                              }
                              className="mr-1"
                            />
                          </Tooltip>
                          <strong>
                            {deposit.manualAdjustment ? (
                              <>
                                <FormattedMessage
                                  id="member-detail.payment-method.text"
                                  defaultMessage="Payment Method"
                                />{' '}
                                -{' '}
                                <FormattedMessage
                                  id="member-detail.manual.text"
                                  defaultMessage="Manual"
                                />
                              </>
                            ) : (
                              <>
                                {coercedGet(
                                  deposit,
                                  'paymentMethod.bank',
                                  null
                                ) &&
                                  `${coercedGet(
                                    deposit,
                                    'paymentMethod.bank',
                                    null
                                  )} -  `}
                                {coercedGet(
                                  deposit,
                                  'paymentMethod.name',
                                  null
                                ) === 'ASTROPAYWALLET'
                                  ? 'AstroPay Wallet'
                                  : coercedGet(
                                      deposit,
                                      'paymentMethod.name',
                                      null
                                    )}
                              </>
                            )}
                          </strong>
                        </>
                      ),
                      secondary: (
                        <div className="d-flex flex-column">
                          <div className="d-flex">
                            <div className="mr-2">
                              {formatDate(
                                deposit.dateTimeCreated,
                                DATE_TIME_FORMAT
                              )}
                            </div>
                            <div>
                              {moment(deposit.dateTimeCreated).fromNow()}
                            </div>
                          </div>

                          {deposit.__typename === 'CryptocurrencyDeposit' ? (
                            <div>{depositorBlockchainAddress || '-'}</div>
                          ) : (
                            <>
                              <div>
                                {hexoPayCreditCard
                                  ? `${firstSixDigits ||
                                      firstDigit} *** *** ${lastFourDigits}`
                                  : '-'}
                              </div>
                              <div>
                                {hexoPayCreditCard
                                  ? `${expirationMonth} / ${expirationYear}`
                                  : '-'}
                              </div>
                            </>
                          )}
                        </div>
                      ),
                    }}
                    middleNode={{
                      title: deposit.isCrypto
                        ? `${deposit.currency} ${deposit.amount.toFixed(8)}`
                        : addCurrency(member.currency, deposit.amount),
                      secondary: renderDepositType(deposit),
                    }}
                    rightNode={{
                      title: (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key="1">
                                <FormattedMessage
                                  id="open-in-new-browser-tab.text"
                                  defaultMessage="Open in new browser tab"
                                />
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['contextMenu']}
                        >
                          <Tooltip title={deposit.transactionReference}>
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <span onClick={() => linkToDepositPage(deposit)}>
                              {truncate(deposit.transactionReference, {
                                length: 24,
                              })}
                            </span>
                          </Tooltip>
                        </Dropdown>
                      ),
                      secondary: (
                        <>
                          {deposit.firstDeposit && (
                            <Tooltip title={translate(messages.FIRST_DEPOSIT)}>
                              <Icon
                                component={FirstDepositIcon}
                                className="text-danger fs-16 mr-2"
                              />
                            </Tooltip>
                          )}
                          {deposit.remarks && (
                            <Tooltip title={deposit.remarks}>
                              <Icon
                                component={RemarksIcon}
                                className="text-danger fs-16"
                              />
                            </Tooltip>
                          )}
                        </>
                      ),
                    }}
                  />
                  {notTheLastItem(index) && <Divider className="m-0 mb-2" />}
                </React.Fragment>
              );
            })}
          </div>
          {totalDeposits > 0 && (
            <div>
              <Divider className="m-0" />
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ paddingTop: '7px', paddingBottom: '8px' }}
              >
                <span className="mr-1 fs-12">
                  <FormattedMessage
                    id="more-details.text"
                    defaultMessage="More Details"
                  />
                  :
                </span>
                <ALink
                  className="fs-12"
                  onClick={() => openDepositInternalTab()}
                >
                  <FormattedMessage
                    id="deposit-request-(internal).text"
                    defaultMessage="Deposit Request (Internal)"
                  />
                </ALink>
                <span className="mx-1 text-muted-light fs-12">|</span>
                <ALink
                  className="fs-12"
                  onClick={() => openDepositExternalTab()}
                >
                  <FormattedMessage
                    id="deposit-request-(3rd-party).text"
                    defaultMessage="Deposit Request (3rd Party)"
                  />
                </ALink>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
