import { TabId } from 'constants/route';
import DataLoaderProvider from 'contexts/DataLoader';
import FiltersProvider from 'contexts/Filters';
import {
  BATCH_WITHDRAWAL_BANK_NAME,
  BATCH_WITHDRAWAL_METHOD_REMARK,
  BATCH_WITHDRAWAL_METHOD_STATUS,
} from 'graphql/queries/globalBatch.query';
import { useCreateLoader } from 'hooks/useLoader';
import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import WithdrawalMethodsScreen from './components/WithdrawalMethodsScreen';

const WithdrawalMethods = () => {
  const { getTab } = useScreenTabV2();
  const { filter = {} } = getTab(TabId.WITHDRAWAL_METHODS as any);

  const defaultFilters = {
    name: null,
    enabled: null,
    nickname: null,
    type: null,
    withdrawalProvider: null,
    bank: null,
  };

  const initialFilters = {
    ...defaultFilters,
    ...filter,
  };

  const bankNameLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_BANK_NAME);
  const statusLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_METHOD_STATUS);
  const remarkLoaderSet = useCreateLoader(BATCH_WITHDRAWAL_METHOD_REMARK);

  return (
    <FiltersProvider
      initialValues={initialFilters}
      defaultValues={defaultFilters}
      tabId={TabId.WITHDRAWAL_METHODS as any}
    >
      <DataLoaderProvider
        loaderKeyVals={{
          bankNameLoaderSet,
          statusLoaderSet,
          remarkLoaderSet,
        }}
      >
        <WithdrawalMethodsScreen />
      </DataLoaderProvider>
    </FiltersProvider>
  );
};

export default WithdrawalMethods;
