import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { UPDATE_MEMBER_LEVEL } from 'graphql/mutations/memberLevel.mutation';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import { useMemberLevel } from 'pages/components/MemberMarkerManagement/context';
import messages from 'messages';
import React from 'react';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';

type Props = {
  memberLevel: Record<string, any>;
  count: number;
  disabled: boolean;
};

function ChangeStatus({ memberLevel, count, disabled }: Props) {
  const translate = useTranslate();
  const [changeStatus] = useMutation(UPDATE_MEMBER_LEVEL);
  const { refetchParams } = useMemberLevel()!;
  let status = translate(messages.ACTIVATE);

  let active: any = null;

  if (memberLevel.status === 'ENABLED') {
    status = translate(messages.DEACTIVATE);

    active = count === 0;
  }

  const handleClick = async () => {
    if (disabled) {
      return;
    }

    switch (active) {
      case null:
        try {
          await changeStatus({
            variables: {
              id: memberLevel.id,
              input: {
                status: 'ENABLED',
              },
            },
            refetchQueries: [
              {
                query: GET_MEMBER_LEVELS,
                variables: refetchParams,
              },
            ],
          });

          message.success(
            translate(messages.MEMBER_LEVEL_MANAGEMENT_SUCCESSFULLY_ACTIVATED)
          );
          // eslint-disable-next-line no-empty
        } finally {
        }
        break;
      case true:
        try {
          await changeStatus({
            variables: {
              id: memberLevel.id,
              input: {
                status: 'DISABLED',
              },
            },
            refetchQueries: [
              {
                query: GET_MEMBER_LEVELS,
                variables: refetchParams,
              },
            ],
          });

          message.success(
            translate(messages.MEMBER_LEVEL_MANAGEMENT_SUCCESSFULLY_DEACTIVATED)
          );
          // eslint-disable-next-line no-empty
        } finally {
        }
        break;
      case false:
        message.warn(
          translate(messages.MEMBER_LEVEL_MANAGEMENT_DEACTIVATE_IF_ZERO_MEMBERS)
        );
        break;
      default:
    }
  };

  return <ALink onClick={handleClick}>{status}</ALink>;
}

export default ChangeStatus;
