import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  APPROVE_AFFILIATE_REQUEST,
  REJECT_AFFILIATE_REQUEST,
  UPDATE_AFFILIATE_REQUEST,
} from 'graphql/mutations/affiliateAgent.mutation';
import {
  AFFILIATE_REQUEST,
  AFFILIATE_REQUESTS,
} from 'graphql/queries/affiliateAgent.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useState } from 'react';
import { usePermissions } from 'store/accountState';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { defaultActions } from '../constants';

const StyledButton = styled(Button)`
  &.ant-btn {
    border-color: #52c41a;
    color: #52c41a;
  }
`;

type Props = {
  handleHide: () => void;
  loading: boolean;
  processing: boolean;
  error: any;
  variables?: Record<string, any>;
  requestID: string;
  remarks: string;
  handleHideForProcessing: () => void;
};

const ModalFooter = ({
  handleHide,
  loading,
  processing,
  error,
  variables,
  requestID,
  remarks,
  handleHideForProcessing,
}: Props) => {
  const translate = useTranslate();
  const { role, permissions } = usePermissions();

  const {
    ALLOWED_APPROVE,
    ALLOWED_REJECT,
    ALLOWED_PROCESS,
  } = collectPermissions(
    role,
    permissions,
    ['APPROVE', 'REJECT', 'PROCESS'],
    ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES_AFFILIATE_REQUEST
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mode, setMode] = useState('');

  const [approveAffiliateRequest] = useMutation(APPROVE_AFFILIATE_REQUEST, {
    onError: () =>
      message.error(
        translate(messages['affiliate-request.approve-error.text'])
      ),
  });
  const [rejectAffiliateRequest] = useMutation(REJECT_AFFILIATE_REQUEST, {
    onError: () =>
      message.error(translate(messages['affiliate-request.reject-error.text'])),
  });
  const [updateAffiliateRequest] = useMutation(UPDATE_AFFILIATE_REQUEST);
  const formActions = {
    APPROVE: approveAffiliateRequest,
    REJECT: rejectAffiliateRequest,
  };

  const decideRequestAction = async (currentMode: string) => {
    const formAction = formActions[currentMode];

    try {
      setIsSubmitting(true);
      if (remarks.length) {
        updateAffiliateRequest({
          variables: {
            id: requestID,
            input: {
              remarks,
            },
          },
          refetchQueries: [
            {
              query: AFFILIATE_REQUEST,
              variables: { id: requestID },
            },
          ],
        });
      }

      const res = await formAction({
        variables: { id: requestID },
        refetchQueries: [
          {
            query: AFFILIATE_REQUEST,
            variables: { id: requestID },
          },
          {
            query: AFFILIATE_REQUESTS,
            variables,
          },
        ],
        awaitRefetchQueries: true,
      });

      const response = coercedGet(
        res,
        `data.${
          currentMode === defaultActions.APPROVE ? 'approve' : 'reject'
        }AffiliateRequest`,
        false
      );

      if (response) {
        message.success(
          translate(
            messages[
              currentMode === defaultActions.APPROVE
                ? 'affiliate-request-approve-success.text'
                : 'affiliate-request-reject-success.text'
            ]
          )
        );

        handleHide();
      }
    } finally {
      setIsSubmitting(false);
      setMode('');
    }
  };

  return (
    <div>
      {!loading && ALLOWED_PROCESS && (
        <>
          {ALLOWED_PROCESS && ALLOWED_APPROVE && (
            <StyledButton
              disabled={isSubmitting || processing || error}
              loading={isSubmitting && mode === defaultActions.APPROVE}
              ghost
              className="mr-2 px-4 py-1"
              onClick={() => {
                setMode(defaultActions.APPROVE);
                decideRequestAction(defaultActions.APPROVE);
              }}
            >
              {translate(messages['approve.text'])}
            </StyledButton>
          )}
          {ALLOWED_PROCESS && ALLOWED_REJECT && (
            <Button
              disabled={isSubmitting || processing || error}
              loading={isSubmitting && mode === defaultActions.REJECT}
              ghost
              className="mr-2 px-4 py-1"
              onClick={() => {
                setMode(defaultActions.REJECT);
                decideRequestAction(defaultActions.REJECT);
              }}
            >
              {translate(messages['reject.text'])}
            </Button>
          )}
          {ALLOWED_PROCESS && (
            <Button
              className="px-3 py-1"
              onClick={handleHideForProcessing}
              disabled={isSubmitting || processing || error}
              loading={processing}
            >
              {translate(messages['stop-processing.text'])}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ModalFooter;
