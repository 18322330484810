import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { rowSource, rowTarget } from 'utils/tableDragAndDrop';

const BodyRow = (draggingIndex: number) => (props: any) => {
  const {
    isOver,
    connectDragSource,
    connectDropTarget,
    moveRow,
    ...restProps
  } = props;
  const style = { ...restProps.style, cursor: 'move' };
  let { className } = restProps;
  if (isOver) {
    if (restProps.index > draggingIndex) {
      className += ' drop-over-downward';
    }
    if (restProps.index < draggingIndex) {
      className += ' drop-over-upward';
    }
  }
  return connectDragSource(
    connectDropTarget(<tr {...restProps} className={className} style={style} />)
  );
};

const draggingIndex = -1;

const DraggableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(
  DragSource('row', rowSource(draggingIndex), (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow(draggingIndex))
);

export default DraggableBodyRow;
