import React from 'react';
import { Formik } from 'formik';
import { DragSource, DropTarget } from 'react-dnd';
import { rowSource, rowTarget } from 'utils/tableDragAndDrop';
import coercedGet from 'utils/coercedGet';
import compose from 'utils/compose';
import { set, partialRight } from 'lodash';
import { allTypes, useCreateLoyaltyProgramContext } from '../../../../context';
import { BodyRow } from '../../utils';

type Props = {
  node: any;
};

// eslint-disable-next-line prefer-const
let draggingIndex = -1;

const DraggableEditableRow: React.FC<Props> = (props: any) => {
  const DraggableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }))(
    DragSource('row', rowSource(draggingIndex), (connect) => ({
      connectDragSource: connect.dragSource(),
    }))(BodyRow(draggingIndex))
  );

  const [, dispatch] = useCreateLoyaltyProgramContext();

  const { node } = props;

  const initDepositAmount = coercedGet(
    node,
    'qualificationCriteria.and[0].amount',
    0
  );
  const initTurnoverAmount = coercedGet(
    node,
    'qualificationCriteria.and[1].amount',
    0
  );

  const initUpgradeAmount = coercedGet(node, 'upgradePayout.amount', 0);
  const initUpgradeTurnover = coercedGet(node, 'upgradePayout.turnover', 0);

  const initGameTypes = coercedGet(
    node,
    'qualificationCriteria.and[1].gameTypes',
    []
  );

  return (
    <Formik
      initialValues={{
        id: node?.id,
        name: node?.name,
        depositAmount: initDepositAmount,
        turnoverAmount: initTurnoverAmount,
        upgradeAwardAmount: initUpgradeAmount,
        upgradeAwardTurnover: initUpgradeTurnover,
        gameTypes: initGameTypes,
      }}
      onSubmit={(values) => {
        // console.log('onSubmit', values);

        const setDepositAmount = partialRight(
          set,
          'and[0].amount',
          values.depositAmount
        );

        const setTurnoverAmount = partialRight(
          set,
          'and[1].amount',
          values.turnoverAmount
        );

        const setGameTypes = partialRight(
          set,
          'and[1].gameTypes',
          values.gameTypes
        );

        const setUpgradeAmount = partialRight(
          set,
          'amount',
          values.upgradeAwardAmount
        );

        const setUpgradeTurnover = partialRight(
          set,
          'turnover',
          values.upgradeAwardTurnover
        );

        const qualificationCriteria = compose(
          setGameTypes,
          setTurnoverAmount,
          setDepositAmount
        )(node.qualificationCriteria);

        const upgradePayout = compose(
          setUpgradeAmount,
          setUpgradeTurnover
        )(node.upgradePayout);

        dispatch({
          type: allTypes.UPDATE_TIER,
          payload: {
            id: values.id,
            name: values.name,
            qualificationCriteria,
            upgradePayout,
          },
        });
      }}
    >
      <DraggableBodyRow {...props} />
    </Formik>
  );
};

export default DraggableEditableRow;
