import { CopyOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Typography } from 'antd';
import { WITHDRAWAL_REQUESTS } from 'graphql/queries/withdrawal.query';
import { useConfig } from 'hooks/useConfig';
import { round, subtract, sum, values } from 'lodash';
import React, { useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import copyToClipboard from 'utils/copyToClipboard';
import preventNegativeOnKeyDown from 'utils/preventNegativeOnKeyDown';
import useTranslate from 'utils/useTranslate';
import messages from '../../../../messages';
import EditSaveButton from './components/EditSaveButton';
import { UPDATE_WITHDRAWAL } from './mutations';
import { StyledInputNumber } from './styles';

type Props = {
  withdrawal: Record<string, any>;
  refetchVariables: any;
  readOnly: boolean;
};

const PaymentSources = (props: Props) => {
  const { withdrawal, refetchVariables, readOnly } = props;
  const {
    amount,
    handlingFee,
    withdrawalTaxFee,
    promoDeductionFee,
    withdrawalLimitFee,
    handlingFeeApplied,
    withdrawalTaxFeeApplied,
    promoDeductionFeeApplied,
    withdrawalLimitFeeApplied,
    id,
    currency,
    isCrypto,
  } = withdrawal;

  const { Text } = Typography;
  const { currency: configCurrency } = useConfig();

  const [fees, setFees] = useState({
    handling: handlingFeeApplied === null ? handlingFee : handlingFeeApplied,
    tax:
      withdrawalTaxFeeApplied === null
        ? withdrawalTaxFee
        : withdrawalTaxFeeApplied,
    promoDeduction:
      promoDeductionFeeApplied === null
        ? promoDeductionFee
        : promoDeductionFeeApplied,
    limit:
      withdrawalLimitFeeApplied === null
        ? withdrawalLimitFee
        : withdrawalLimitFeeApplied,
  });

  const [activeFeeInput, setActiveFeeInput] = useState(null);
  const [totalPayout, setTotalPayout] = useState(
    subtract(
      amount,
      sum([
        handlingFeeApplied,
        withdrawalTaxFeeApplied,
        promoDeductionFeeApplied,
        withdrawalLimitFeeApplied,
      ])
    ) || 0
  );

  const { handling, tax, promoDeduction, limit } = fees;

  const [updateWithdrawal] = useMutation(UPDATE_WITHDRAWAL, {
    refetchQueries: [
      {
        query: WITHDRAWAL_REQUESTS,
        variables: { ...refetchVariables },
      },
    ],
  });

  const handleUpdateFee = (e: Record<string, any>) => {
    updateWithdrawal({
      variables: {
        id,
        input: {
          [`${e.target.name}`]: Number(e.target.value || 0),
        },
      },
    });
    const newValue = values({
      ...fees,
      [`${activeFeeInput}`]: Number(e.target.value),
    });
    setTotalPayout(subtract(amount, sum(newValue)));
    setActiveFeeInput(null);
  };

  const handleResetFees = () => {
    updateWithdrawal({
      variables: {
        id,
        input: {
          handlingFeeApplied: handlingFee,
          withdrawalTaxFeeApplied: withdrawalTaxFee,
          promoDeductionFeeApplied: promoDeductionFee,
          withdrawalLimitFeeApplied: withdrawalLimitFee,
        },
      },
    });
    setActiveFeeInput(null);
    setFees({
      handling: handlingFee,
      tax: withdrawalTaxFee,
      promoDeduction: promoDeductionFee,
      limit: withdrawalLimitFee,
    });
    setTotalPayout(
      subtract(
        amount,
        sum([
          handlingFee,
          withdrawalTaxFee,
          promoDeductionFee,
          withdrawalLimitFee,
        ])
      )
    );
  };

  const handleClearFees = () => {
    updateWithdrawal({
      variables: {
        id,
        input: {
          handlingFeeApplied: 0,
          withdrawalTaxFeeApplied: 0,
          promoDeductionFeeApplied: 0,
          withdrawalLimitFeeApplied: 0,
        },
      },
    });
    setActiveFeeInput(null);
    setFees({
      handling: 0,
      tax: 0,
      promoDeduction: 0,
      limit: 0,
    });
    setTotalPayout(amount);
  };

  const totalPayoutDisplayed = totalPayout < 0 ? 0 : round(totalPayout, 2);
  const translate = useTranslate();
  return (
    <>
      <div className="menu-header py-2 px-3 mt-4">
        <div className="d-flex">
          <div>{translate(messages.withdrawalFee)}</div>
          <div className="flex-1 text-right">
            <ALink
              className="mr-3"
              onClick={() => handleClearFees()}
              disabled={readOnly}
            >
              {translate(messages.clearAll)}
            </ALink>
            <ALink onClick={() => handleResetFees()} disabled={readOnly}>
              {translate(messages.reset)}
            </ALink>
          </div>
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">{translate(messages.withdrawalAmount)}</Text>
        </div>
        <div className="flex-1 text-right">
          {isCrypto
            ? `${currency} ${amount.toFixed(8)}`
            : `${configCurrency?.symbol} ${amount}`}
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">{translate(messages.handlingFee)}</Text>
        </div>
        <div className="flex-1 text-right">
          {activeFeeInput === 'handling' ? (
            <StyledInputNumber
              size="small"
              name="handlingFeeApplied"
              type="number"
              min={0}
              autoFocus
              value={handling || 0}
              onKeyDown={(e: Record<string, any>) =>
                preventNegativeOnKeyDown(e)
              }
              onChange={(e: number) => setFees({ ...fees, handling: e || 0 })}
              onFocus={(e: Record<string, any>) => e.target.select()}
              onBlur={(e: Record<string, any>) => handleUpdateFee(e)}
              onPressEnter={(e: Record<string, any>) => handleUpdateFee(e)}
            />
          ) : (
            `${handling > 0 ? '-' : ''} ${configCurrency?.symbol} ${handling}`
          )}{' '}
          <EditSaveButton
            activeFeeInput={activeFeeInput!}
            fieldInput="handling"
            setActiveFeeInput={setActiveFeeInput as any}
            disabled={readOnly}
          />
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">{translate(messages.withdrawalTax)}</Text>
        </div>
        <div className="flex-1 text-right">
          <>
            {activeFeeInput === 'tax' ? (
              <StyledInputNumber
                size="small"
                name="withdrawalTaxFeeApplied"
                type="number"
                min={0}
                autoFocus
                value={tax || 0}
                onKeyDown={(e: Record<string, any>) =>
                  preventNegativeOnKeyDown(e)
                }
                onChange={(e: number) => setFees({ ...fees, tax: e || 0 })}
                onFocus={(e: Record<string, any>) => e.target.select()}
                onBlur={(e: Record<string, any>) => handleUpdateFee(e)}
                onPressEnter={(e: Record<string, any>) => handleUpdateFee(e)}
              />
            ) : (
              `${tax > 0 ? '-' : ''} ${configCurrency?.symbol} ${tax}`
            )}{' '}
            <EditSaveButton
              activeFeeInput={activeFeeInput!}
              fieldInput="tax"
              setActiveFeeInput={setActiveFeeInput as any}
              disabled={readOnly}
            />
          </>
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">{translate(messages.withdrawalLimitFee)}</Text>
        </div>
        <div className="flex-1 text-right">
          <>
            {activeFeeInput === 'limit' ? (
              <StyledInputNumber
                size="small"
                name="withdrawalLimitFeeApplied"
                type="number"
                min={0}
                autoFocus
                value={limit || 0}
                onKeyDown={(e: Record<string, any>) =>
                  preventNegativeOnKeyDown(e)
                }
                onChange={(e: number) => setFees({ ...fees, limit: e || 0 })}
                onFocus={(e: Record<string, any>) => e.target.select()}
                onBlur={(e: Record<string, any>) => handleUpdateFee(e)}
                onPressEnter={(e: Record<string, any>) => handleUpdateFee(e)}
              />
            ) : (
              `${limit > 0 ? '-' : ''} ${configCurrency?.symbol} ${limit}`
            )}{' '}
            <EditSaveButton
              activeFeeInput={activeFeeInput!}
              fieldInput="limit"
              setActiveFeeInput={setActiveFeeInput as any}
              disabled={readOnly}
            />
          </>
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">{translate(messages.promoDeduction)}</Text>
        </div>
        <div className="flex-1 text-right">
          <>
            {activeFeeInput === 'promoDeduction' ? (
              <StyledInputNumber
                size="small"
                name="promoDeductionFeeApplied"
                type="number"
                min={0}
                autoFocus
                value={promoDeduction || 0}
                onKeyDown={(e: Record<string, any>) =>
                  preventNegativeOnKeyDown(e)
                }
                onChange={(e: number) =>
                  setFees({ ...fees, promoDeduction: e || 0 })
                }
                onFocus={(e: Record<string, any>) => e.target.select()}
                onBlur={(e: Record<string, any>) => handleUpdateFee(e)}
                onPressEnter={(e: Record<string, any>) => handleUpdateFee(e)}
              />
            ) : (
              `${promoDeduction > 0 ? '-' : ''} ${
                configCurrency?.symbol
              } ${promoDeduction}`
            )}{' '}
            <EditSaveButton
              activeFeeInput={activeFeeInput!}
              fieldInput="promoDeduction"
              setActiveFeeInput={setActiveFeeInput as any}
              disabled={readOnly}
            />
          </>
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">{translate(messages.withdrawalAmount)}</Text>
        </div>
        <div className="flex-1 text-right">
          {configCurrency?.symbol} {totalPayoutDisplayed}{' '}
          <ALink
            disabled={readOnly}
            onClick={() => copyToClipboard(totalPayoutDisplayed)}
          >
            <CopyOutlined />
          </ALink>
        </div>
      </div>
    </>
  );
};

export default PaymentSources;
