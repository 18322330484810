import gql from 'graphql-tag';

export const MEMBER_LEVEL = gql`
  query MemberLevel($id: ID!) {
    memberLevel(id: $id) {
      name
      id
      color
    }
  }
`;

export default {};
