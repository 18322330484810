import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Switch as AntdSwitch } from 'antd';
import { formItemHorizontalLayout } from 'constants/form';

const Temp: any = AntdSwitch;
const Switch = ({
  checkedChildren,
  unCheckedChildren,
  label,
  value,
  onChange,
}: {
  checkedChildren: string;
  unCheckedChildren: string;
  value: string;
  label: string;
  onChange: (e: any) => void;
}) => (
  <Form.Item {...formItemHorizontalLayout} label={label}>
    <Temp
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      value={value}
      onChange={onChange}
    />
  </Form.Item>
);

export default Switch;
