import React from 'react';
import { Card, Typography, Tag, Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useAccount360 } from '../../AccountContext';

export const WhitelistIPAddress = () => {
  const {
    admin: { ipWhitelist },
  } = useAccount360();

  return (
    <Card
      bordered={false}
      title={
        <Typography.Text className="head-typography fw-500 text-dark">
          <FormattedMessage
            id="whitelist-ip-address.text"
            defaultMessage="Whitelist IP Address"
          />
        </Typography.Text>
      }
    >
      <div>
        {ipWhitelist.length ? (
          ipWhitelist.map((ip: string, index: number) => (
            <Tag key={index} className="mt-2">
              {ip}
            </Tag>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </Card>
  );
};
