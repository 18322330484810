export const enRelativeTime = {
  s: '< 1 minute',
  m: '1 minute',
  h: '1 hour',
  d: '1 day',
  M: '1 month',
  y: '1 year',
};

export const zhRelativeTime = {
  s: '< 1 分钟',
  m: '1 分钟',
  h: '1 小时',
  d: '1 天',
  M: '1 个月',
  y: '1 年',
};

export default {};
