import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import useTranslate from 'utils/useTranslate';
import { Member, MembersConnectionsEdge } from 'types/graphqlTypes';
import messages from 'messages';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
// eslint-disable-next-line import/no-cycle
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { STARRED_MEMBERS, STAR_MEMBER, UPDATE_LANGUAGE } from './query';

type UpdateLanguageProps = {
  updateLangauge: (lang: string) => void;
};

export type MemberInfo = Member & {
  name: string;
  memberLevelName: string;
  memberLevelColor: string;
};

export const useLanguage = (): UpdateLanguageProps => {
  const [updateLanguage] = useMutation(UPDATE_LANGUAGE);
  const updateLangauge = async (lang: string) => {
    await updateLanguage({
      variables: {
        input: {
          language: lang.toUpperCase(),
        },
      },
    });
  };

  return {
    updateLangauge,
  };
};

export const memberToStarredMemberFormat = (
  member: Partial<MemberInfo>
): Partial<MemberInfo> => ({
  id: member.id,
  name: member.username,
  starred: member.starred,
  realName: member.realName,
  memberLevelName: member.memberLevel?.name,
  memberLevelColor: member.memberLevel?.color,
  watchlistRegistrationDateTime: member.watchlistRegistrationDateTime,
  registrationDateTime: member.registrationDateTime,
});

type StarredMembers = {
  getStarredMembers: () => void;
  called: boolean;
};

export const useStarredMembers = (
  dispatchSetStarredMembers: (data: Array<Partial<MemberInfo>>) => void
): StarredMembers => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const [execute, { called }] = useLazyQuery(STARRED_MEMBERS, {
    onCompleted: (data: {
      members: {
        edges: Array<MembersConnectionsEdge>;
      };
    }) => {
      if (data.members && ALLOWED_LIST) {
        const members: Array<Partial<MemberInfo>> = (
          data?.members.edges || []
        ).map<Partial<MemberInfo>>(({ node }: { node: any }) =>
          memberToStarredMemberFormat(node)
        );
        dispatchSetStarredMembers(members);
      }
    },
  });

  const getStarredMembers = () => {
    execute({
      variables: {
        filter: {
          starred: true,
        },
      },
    });
  };

  return {
    getStarredMembers,
    called,
  };
};

export const useStarMember = (
  starred: Array<MemberInfo>,
  dispatchSetStarredMembers: (data: any) => void,
  dispatchSetStarringMemberId: (id: string | null) => void
) => {
  const translate = useTranslate();
  const [mutate] = useMutation(STAR_MEMBER);
  const memberExist = (id: string) => starred.find((item) => item.id === id);

  const getStarredMember = (id: string): MemberInfo | undefined =>
    starred.find((item) => item.id === id);

  const setStarMember = async (member: MemberInfo, status: boolean) => {
    if (!member) return;
    const { id } = member;
    dispatchSetStarringMemberId(id);

    const starredMember = getStarredMember(id);

    if (memberExist(id)) {
      const newStarred: Array<MemberInfo> = starred.filter(
        (item) => item.id !== starredMember?.id
      );
      dispatchSetStarredMembers(newStarred);
    } else {
      dispatchSetStarredMembers([
        ...starred,
        memberToStarredMemberFormat({
          ...member,
          starred: true,
        }),
      ]);
    }

    try {
      await mutate({
        variables: {
          id,
          input: {
            starred: status,
          },
        },
      });

      message.success(
        translate(
          messages[status ? 'ADDED_TO_WATCHLIST' : 'REMOVED_FROM_WATCHLIST']
        )
      );
    } catch (err) {
      dispatchSetStarredMembers(starred);
      message.error(translate(messages.INTERNAL_SERVER_ERROR));
    } finally {
      dispatchSetStarringMemberId(null);
    }
  };

  const starMember = async (member: MemberInfo) => {
    setStarMember(member, true);
  };

  const unstarMember = async (member: MemberInfo) => {
    setStarMember(member, false);
  };

  const toggleStar = async (member: MemberInfo) => {
    setStarMember(member, !member.starred);
  };

  return {
    getStarredMember,
    starMember,
    unstarMember,
    toggleStar,
  };
};
