import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import React from 'react';
import { partial } from 'lodash';
import coercedGet from '../utils/coercedGet';

const messages = defineMessages({
  TRANSLATION_ERROR: {
    id: 'translation-error.text',
    defaultMessage: 'Translation Error',
  },
});

const customFormatMessage = (
  formatMessage: any,
  message: MessageDescriptor,
  values?: Record<string, any> | null
) => {
  const noIdError = 'An `id` must be provided to format a message';
  try {
    return formatMessage(message, values);
  } catch (error) {
    const errorMessage = coercedGet(error, 'message', '');
    const idError = errorMessage.includes(noIdError);
    return idError ? formatMessage(messages.TRANSLATION_ERROR) : '-';
  }
};

const useTranslate = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  // Unknown dependency, to be fixed.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return React.useCallback(partial(customFormatMessage, formatMessage), [
    formatMessage,
  ]);
};
export default useTranslate;
