import gql from 'graphql-tag';

export const WITHDRAWAL_METHODS = gql`
  query WithdrawalMethods(
    $first: Int
    $after: Binary
    $filter: WithdrawalMethodFilterInput
  ) {
    withdrawalMethods(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          value: id

          ... on OfflineBankTransferWithdrawalMethod {
            bank
          }

          ... on AlipayWithdrawalMethod {
            nickname
          }

          ... on WechatWithdrawalMethod {
            nickname
          }
        }
      }
    }
  }
`;

export default {};
