import gql from 'graphql-tag';

export const WITHDRAWAL_PROVIDERS = gql`
  query WithdrawalProviders(
    $first: Int
    $after: Binary
    $filter: WithdrawalProviderFilterInput
  ) {
    withdrawalProviders(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
