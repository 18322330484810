import gql from 'graphql-tag';

export const GAME_NAMES = gql`
  query GameNames($first: Int, $after: Binary, $filter: GamesFilterInput) {
    games(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          nameEn: name(language: EN)
          nameZh: name(language: ZH_HANT)
        }
      }
    }
  }
`;

export default {};
