import { PAYMENT_TYPES_DEPOSIT } from 'constants/paymentTypes';
import { TabId } from 'constants/route';
import DataLoaderProvider from 'contexts/DataLoader';
import FiltersProvider from 'contexts/Filters';
import {
  BATCH_DEPOSIT_PAYMENT_METHOD_EXTERNAL,
  BATCH_MEMBER_AFFILIATE,
  BATCH_MEMBER_MARKER,
  BATCH_MEMBER_NOTES,
  BATCH_MEMBER_TAGS,
  BATCH_USERNAME,
  BATCH_VIP_TIER,
} from 'graphql/queries/globalBatch.query';
import { useCreateLoader } from 'hooks/useLoader';
import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import uuid from 'uuid';
import DepositsScreen from './components/DepositsScreen';

const ExternalMemberDeposits = () => {
  const [key, setKey] = useState('');
  const { getTab } = useScreenTabV2();

  const { memberIdRef, serialCodeRef, filter = {} } =
    getTab(TabId.EXTERNAL_DEPOSIT_REQUESTS as any) || {};

  const memberTabRef = memberIdRef ? { in: [memberIdRef] } : null;
  const serialCodeTabRef = serialCodeRef ? { in: [serialCodeRef] } : null;

  const defaultFilters = {
    paymentMethodType: { in: PAYMENT_TYPES_DEPOSIT.ONLINE },
    serialCode: null,
    account: null,
    amount: null,
    status: null,
    dateTimeCreated: null,
    processingTime: null,
    memberLoyaltyLevel: null,
    memberLevel: null,
    processor: null,
  };

  const initialFilters = {
    ...defaultFilters,
    account: memberTabRef,
    serialCode: serialCodeTabRef,
    ...filter,
  };

  const affiliateLoaderSet = useCreateLoader(BATCH_MEMBER_AFFILIATE);
  const usernameLoaderSet = useCreateLoader(BATCH_USERNAME);
  const vipTierLoaderSet = useCreateLoader(BATCH_VIP_TIER);
  const markerLoaderSet = useCreateLoader(BATCH_MEMBER_MARKER);
  const depositMethodLoaderSet = useCreateLoader(
    BATCH_DEPOSIT_PAYMENT_METHOD_EXTERNAL
  );
  const memberTagsLoaderSet = useCreateLoader(BATCH_MEMBER_TAGS);
  const memberNotesLoaderSet = useCreateLoader(BATCH_MEMBER_NOTES);

  return (
    <FiltersProvider
      initialValues={initialFilters}
      defaultValues={defaultFilters}
    >
      <DataLoaderProvider
        loaderKeyVals={{
          affiliateLoaderSet,
          usernameLoaderSet,
          vipTierLoaderSet,
          markerLoaderSet,
          depositMethodLoaderSet,
          memberTagsLoaderSet,
          memberNotesLoaderSet,
        }}
      >
        <DepositsScreen key={key} refreshPage={() => setKey(uuid.v1())} />
      </DataLoaderProvider>
    </FiltersProvider>
  );
};
export default ExternalMemberDeposits;
