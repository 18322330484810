import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'game-vendor.text': {
    id: 'game-vendor.text',
    defaultMessage: 'Game vendor',
  },
  'game-category.text': {
    id: 'game-category.text',
    defaultMessage: 'Game category',
  },
  'all.text': {
    id: 'all.text',
    defaultMessage: 'All',
  },
  'from.text': {
    id: 'from.text',
    defaultMessage: 'From',
  },
  'to.text': {
    id: 'to.text',
    defaultMessage: 'To',
  },
  'charge.text': {
    id: 'charge.text',
    defaultMessage: 'Charge',
  },
});

export default messages;
