import ALL_PERMISSIONS from 'constants/permissions3';
import { Nav } from 'types/nav';

/*
 * put "next: true" if you want the specific feature
 * to be only available when the user uses the params "?next=true"
 * this is for feature toggling
 * */

const navList: Array<Nav> = [
  {
    id: 'todays-instant-focus',
    ldKey: 'dashboard',
    permission: ALL_PERMISSIONS.ALL_DASHBOARDS.DASHBOARDS,
    list: [],
    // list: [
    //   {
    //     id: TabId.TODAYS_INSTANT_FOCUS,
    //     ldKey: TabId.TODAYS_INSTANT_FOCUS,
    //     permission: ALL_PERMISSIONS.ALL_DASHBOARDS.DASHBOARDS_INSTANT_FOCUS,
    //   },
    // ],
  },
  {
    id: 'operators',
    ldKey: 'operators',
    permission: ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS,
    list: [
      {
        id: 'account-management',
        ldKey: 'accountManagement',
        permission: ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_ACCOUNT_MANAGEMENT,
      },
      {
        id: 'permission-group',
        ldKey: 'permissionGroup',
        permission: ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_PERMISSION_GROUP,
      },
    ],
  },
  {
    id: 'members',
    ldKey: 'members',
    permission: ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS,
    list: [
      {
        id: 'member-management',
        ldKey: 'memberManagement',
        permission: ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT,
      },
      {
        id: 'member-level-management',
        ldKey: 'memberLevelManagement',
        permission:
          ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT,
      },
      {
        id: 'member-label-management',
        ldKey: 'memberLabelManagement',
        permission: ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_LABEL_MANAGEMENT,
      },
      {
        id: 'members-online',
        ldKey: 'membersOnline',
        permission: ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBERS_ONLINE_ONLY,
      },
      {
        id: 'profile-setting',
        ldKey: 'profileSetting',
        // permission: ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_PROFILE_SETTING,
      },
      {
        id: 'member-reports',
        ldKey: 'memberReports',
        permission: ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_REPORTS,
        subList: [
          {
            id: 'member-access-audit-history',
            ldKey: 'memberAccessAuditHistory',
            permission:
              ALL_PERMISSIONS.ALL_MEMBERS
                .MEMBERS_REPORTS_MEMBER_ACCESS_HISTORY_REPORT,
          },
          {
            id: 'member-ip-address-summary',
            ldKey: 'memberIpAddressSummary',
            permission:
              ALL_PERMISSIONS.ALL_MEMBERS
                .MEMBERS_REPORTS_MEMBER_IP_ADDRESS_REPORT,
          },
          {
            id: 'ip-address-summary',
            ldKey: 'ipAddressSummary',
            permission:
              ALL_PERMISSIONS.ALL_MEMBERS
                .MEMBERS_REPORTS_IP_ADDRESS_SUMMARY_REPORT,
          },
          {
            id: 'member-access-summary-report',
            ldKey: 'memberAccessSummaryReport',
            permission:
              ALL_PERMISSIONS.ALL_MEMBERS
                .MEMBERS_REPORTS_MEMBER_ACCESS_SUMMARY_REPORT,
          },
          {
            id: 'member-interaction-log-report',
            ldKey: 'memberInteractionLog',
            permission:
              ALL_PERMISSIONS.ALL_MEMBERS
                .MEMBERS_REPORTS_MEMBER_INTERACTION_LOGS_REPORT,
          },
          {
            id: 'member-session-duration',
            ldKey: 'memberInteractionLog',
            permission:
              ALL_PERMISSIONS.ALL_MEMBERS
                .MEMBERS_REPORTS_MEMBER_SESSION_DURATION_REPORT,
          },
        ],
      },
      {
        id: 'member-management-configuration',
        ldKey: 'euRequirements',
        permission: ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MANAGEMENT_CONFIG,
      },
      {
        id: 'bulk-update',
        ldKey: 'euRequirements',
        permission: ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_BULK_UPDATE,
      },
    ],
  },
  {
    id: 'affiliates',
    ldKey: 'affiliates',
    permission: ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES,
    list: [
      {
        id: 'affiliate-programme',
        ldKey: 'affiliateProgramme',
        permission:
          ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES_AFFILIATE_PROGRAMME,
      },
      {
        id: 'affiliate-criteria',
        ldKey: 'affiliateCriteria',
        permission:
          ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES_AFFILIATE_CRITERIA,
      },
      {
        id: 'affiliate-request',
        ldKey: 'affiliateRequest',
        permission: ALL_PERMISSIONS.ALL_AFFILIATES.AFFILIATES_AFFILIATE_REQUEST,
      },
    ],
  },
  {
    id: 'deposits',
    ldKey: 'deposits',
    permission: ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS,
    list: [
      {
        id: 'deposit-requests-internal',
        ldKey: 'depositRequestsInternal',
        permission: ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_INTERNAL_DEPOSITS,
      },
      {
        id: 'deposit-requests-external',
        ldKey: 'depositRequestsExternal',
        permission: ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_EXTERNAL_DEPOSITS,
      },
      {
        id: 'payment-method-listing',
        ldKey: 'paymentMethodListing',
        permission: ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_PAYMENT_METHODS,
      },
      {
        id: 'deposit-providers-external',
        ldKey: 'depositProvidersExternal',
        permission:
          ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS,
      },
    ],
  },
  {
    id: 'withdrawals',
    ldKey: 'withdrawals',
    permission: ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS,
    list: [
      {
        id: 'withdrawal-requests',
        ldKey: 'withdrawalRequests',
        permission:
          ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_WITHDRAWAL_REQUESTS,
      },
      {
        id: 'withdrawal-methods',
        ldKey: 'withdrawalMethods',
        permission:
          ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_WITHDRAWAL_METHODS,
      },
      {
        id: 'withdrawal-providers-external',
        ldKey: 'withdrawalProvidersExternal',
        permission:
          ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS,
      },
      {
        id: 'compliance-check',
        ldKey: 'complianceCheck',
        permission:
          ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_COMPLIANCE_CHECK,
      },
    ],
  },
  {
    id: 'promo',
    ldKey: 'promo',
    permission: ALL_PERMISSIONS.ALL_PROMOS.PROMOS,
    list: [
      {
        id: 'promo-listing',
        ldKey: 'promoListing',
        permission: ALL_PERMISSIONS.ALL_PROMOS.PROMOS_PROMO_LISTING,
      },
      {
        id: 'promo-requests',
        ldKey: 'promoRequests',
        permission: ALL_PERMISSIONS.ALL_PROMOS.PROMOS_PROMO_REQUEST,
      },
      {
        id: 'promo-labels',
        ldKey: 'promoLabels',
        permission: ALL_PERMISSIONS.ALL_PROMOS.PROMOS_PROMO_LABELS,
      },
      // TODO uncomment below if re-implemented
      // {
      //   id: 'promo-conflict',
      //   ldKey: 'promoConflict',
      //   // permission: ALL_PERMISSIONS.ALL_PROMOS.PROMOS_PROMO_CONFLICT
      // },
    ],
  },
  // todo permissions are to be added when API is available
  {
    id: 'promo-next',
    ldKey: 'promoNext',
    permission: '',
    list: [
      {
        id: 'create-promo',
        ldKey: 'createPromo',
        permission: '',
      },
      {
        id: 'manage-promo',
        ldKey: 'managePromo',
        permission: '',
      },
    ],
  },
  // made this VIP menu hidden as of the moment
  // {
  //   id: 'vip',
  //   ldKey: 'vip',
  //   permission: ALL_PERMISSIONS.ALL_VIP.VIP,
  //   list: [
  //     //   {
  //     //     id: 'vip',
  //     //     permission: 'member_loyalty_view',
  //     //   },
  //   ],
  // },
  {
    id: 'rebates',
    ldKey: 'rebates',
    permission: ALL_PERMISSIONS.ALL_REBATES.REBATES,
    list: [
      //   {
      //     id: 'rebates',
      //     permission: 'rebates_view',
      //   },
    ],
  },
  {
    id: 'reports',
    ldKey: 'reports',
    permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS,
    list: [
      {
        id: 'daily-operating-report',
        ldKey: 'dailyOperatingReport',
        permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_DAILY_OPERATING_REPORT,
      },
      {
        id: 'member-bet-records',
        ldKey: 'memberBetRecords',
        permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_MEMBER_BET_RECORDS,
      },
      {
        id: 'promo-payout-reports',
        ldKey: 'promoPayoutReports',
        permission: '',
      },
      // {
      //   id: TabId.VENDOR_GAME_REPORTS,
      //   permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_VENDOR_GAME_REPORTS,
      // },
      {
        id: 'balance-transaction-records',
        ldKey: 'balanceTransactionRecords',
        permission:
          ALL_PERMISSIONS.ALL_REPORTS.REPORTS_BALANCE_TRANSACTION_REPORTS,
      },
      // {
      //   id: 'promo-payout-records',
      //   ldKey: 'promoPayoutRecords',
      //   permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_PROMO_PAYOUT_RECORDS,
      // },
      // {
      //   id: TabId.STATISTICAL_REPORTS,
      //   permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_STATISTICAL_REPORTS,
      // },
      // {
      //   id: TabId.ANALYSIS_REPORTS,
      //   permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_ANALYSIS_REPORTS,
      // },
      {
        id: 'rebates-reports',
        ldKey: 'rebatesReports',
        permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_REBATES_REPORTS,
      },
      // {
      //   id: TabId.LOTTERY_REPORTS,
      //   permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_LOTTERY_REPORTS,
      // },
      // {
      //   id: TabId.MEMBER_GAME_REPORTS,
      //   permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_MEMBER_GAME_REPORTS,
      // },
      {
        id: 'balance-service-record-report',
        ldKey: 'balanceServiceRecordReport',
        permission:
          ALL_PERMISSIONS.ALL_REPORTS.REPORTS_BALANCE_SERVICE_RECORD_REPORTS,
      },
      {
        id: 'member-summary-report',
        ldKey: 'memberSummaryReport',
        permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_MEMBER_SUMMARY_REPORT,
        // disabling this; violates the first rule imposed on constants/permissions3
      },
      {
        id: 'member-promo-history',
        ldKey: 'memberPromoHistory',
        permission:
          ALL_PERMISSIONS.ALL_REPORTS.REPORTS_MEMBER_PROMO_HISTORY_RECORDS,
      },
      {
        id: 'eu-reports',
        ldKey: 'euRequirements',
        permission: ALL_PERMISSIONS.ALL_REPORTS.REPORTS_EU_REPORTS,
      },
    ],
  },
  {
    id: 'system-management',
    ldKey: 'systemManagement',
    permission: ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT,
    list: [
      {
        id: 'site-configurations',
        ldKey: 'siteConfigurations',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_SITE_CONFIG,
      },
      {
        id: 'alerts-and-notifications',
        ldKey: 'alertsAndNotifications',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
            .SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS,
      },
      {
        id: 'marquee-messages',
        ldKey: 'marqueeMessages',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
            .SYSTEM_MANAGEMENT_MARQUEE_MESSAGES,
      },
      {
        id: 'rotating-banner',
        ldKey: 'rotatingBanner',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
            .SYSTEM_MANAGEMENT_ROTATING_BANNER,
      },
      // {
      //   id: TabId.VENDOR_MANAGEMENT
      //   permission:
      //     ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
      //       .SYSTEM_MANAGEMENT_VENDOR_MANAGEMENT,
      //   multiple: true,
      // },
      {
        id: 'geo-fencing',
        ldKey: 'geoFencing',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_GEO_FENCING,
      },
      {
        id: 'force-profile-validation',
        ldKey: 'forceProfileValidation',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
            .SYSTEM_MANAGEMENT_SYSTEM_CONFIG_FORCE_VERIFICATION_VIEW,
      },
      {
        id: 'mobile-portrait-vendor-configuration',
        ldKey: 'mobilePortraitVendorConfiguration',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
            .SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG,
      },
      // {
      //   id: 'mobile-landscape-vendor-configuration',
      //   ldKey: 'mobileLandscapeVendorConfiguration',
      //   permission:
      //     ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
      //       .SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG,
      // },
      {
        id: 'desktop-vendor-configuration',
        ldKey: 'desktopVendorConfiguration',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
            .SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG,
      },
      // {
      //   id: 'responsible-gaming',
      //   ldKey: 'euRequirements',
      //   permission:
      //     ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT
      //       .SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG,
      // },
      {
        id: 'common-configurations',
        ldKey: 'euRequirements',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_COMMON_CONFIG,
      },
    ],
  },
  {
    id: 'system-message',
    ldKey: 'systemMessage',
    permission: ALL_PERMISSIONS.ALL_SYSTEM_MESSAGES.SYSTEM_MESSAGES,
    list: [
      {
        id: 'manual-message',
        ldKey: 'manualMessage',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MESSAGES.SYSTEM_MESSAGES_MANUAL_MESSAGE,
      },
      {
        id: 'automated-message',
        ldKey: 'automatedMessage',
        permission:
          ALL_PERMISSIONS.ALL_SYSTEM_MESSAGES.SYSTEM_MESSAGES_AUTOMATIC_MESSAGE,
      },
    ],
  },
  {
    id: 'missions',
    ldKey: 'missions',
    permission: '',
    list: [],
  },
];

export default navList;
