import styled from 'styled-components';
import { Card } from 'antd';
import Block from 'react-blocks';

export const StyledContent = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;

export const StyledCardStatContainer = styled.div`
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
`;

export const StyledBackground = styled.div`
  background: #f0f2f5;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
`;

export const StyledArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #f0f2f5;
  position: absolute;
  left: calc(50% - 13px);
`;

export const StyledBlock = styled(Block)`
  flex-grow: 1 !important;
  position: relative;
  a {
    text-decoration: none;
    cursor: pointer;
    display: block;
  }
`;

export const StyledCard = styled(Card)`
  box-shadow: 0px 1px 1px 0.5px rgba(0, 0, 0, 0.2);

  & > .ant-card-body {
    display: flex;
    margin: 10px 0;
    padding-top: 0;
  }
`;
export default {};
