import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'promo-request.request-id.text': {
    id: 'promo-request.request-id.text',
    defaultMessage: 'Request ID',
  },
  'username.text': {
    id: 'username.text',
    defaultMessage: 'Username',
  },
  'real-name.text': {
    id: 'real-name.text',
    defaultMessage: 'Real Name',
  },
  'affiliate-programme.text': {
    id: 'affiliate-programme.text',
    defaultMessage: 'Affiliate Programme',
  },
  'vip.text': {
    id: 'vip.text',
    defaultMessage: 'VIP',
  },
  'status.text': {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  'request-date.text': {
    id: 'request-date.text',
    defaultMessage: 'Request Date',
  },
  'processor.text': {
    id: 'processor.text',
    defaultMessage: 'Processor',
  },
  'time-processed.text': {
    id: 'time-processed.text',
    defaultMessage: 'Time Processed',
  },
  'affiliate-request-start-process-error.text': {
    id: 'affiliate-request-start-process-error.text',
    defaultMessage:
      'You can only start processing when an affiliate request is in PENDING status.',
  },
  'affiliate-request-stop-process-error.text': {
    id: 'affiliate-request-stop-process-error.text',
    defaultMessage:
      'You can only stop processing when an affiliate request is in PROCESSING status.',
  },
  'affiliate-request-unauthorized-error.text': {
    id: 'affiliate-request-unauthorized-error.text',
    defaultMessage: 'You are not authorized to process this request.',
  },
  'pending.text': {
    id: 'pending.text',
    defaultMessage: 'Pending',
  },
  'processing.text': {
    id: 'processing.text',
    defaultMessage: 'Processing',
  },
  'approved.text': {
    id: 'approved.text',
    defaultMessage: 'Approved',
  },
  'rejected.text': {
    id: 'rejected.text',
    defaultMessage: 'Rejected',
  },
});

export default messages;
