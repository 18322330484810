import React, { useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import PaymentMethodsConfig from './components/PaymentMethodsConfig';
import DepositSourceConfig from './components/DepositSourceConfig';

const Settings = () => {
  const [dropdown, setDropDown] = useState(false);
  const [popOver, setPopOver] = useState(false);

  const handleSetPopOver = (val: boolean) => {
    setPopOver(val);
    if (!val) {
      setDropDown(false);
    }
  };

  const MenuOverlay = (
    <Menu>
      <Menu.Item className="p-0">
        <PaymentMethodsConfig setPopOver={handleSetPopOver} />
      </Menu.Item>
      <Menu.Item className="p-0">
        <DepositSourceConfig setPopOver={handleSetPopOver} />
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="d-flex align-items-center">
      <Dropdown
        overlay={MenuOverlay}
        placement="bottomRight"
        visible={dropdown}
        onVisibleChange={() => {
          if (!popOver) {
            setDropDown((prev) => !prev);
          }
        }}
      >
        <div className="p-1 cursor-pointer">
          <SettingOutlined />
        </div>
      </Dropdown>
    </div>
  );
};

export default Settings;
