import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { pick, omit, pickBy, identity, get, isEmpty } from 'lodash';
import moment from 'moment';
import { DynamicObj } from 'interfaces/user.interface';
import useTranslate from 'utils/useTranslate';
import mergeArrayValues from 'utils/mergeArrayValues';
import coercedGet from 'utils/coercedGet';
import Drawer from 'pages/components/common/Drawer/Drawer';
import Steps from './components/Steps';
import InnerForm from './InnerForm';
import {
  CreateRebateSteps,
  SettlementPeriod,
  CreateRebateGroupTypes,
  rebateSettingsSchema,
  payoutSettingsSchema,
  rebateGroupSettingsSchema,
  publishSettingsSchema,
} from './constants';

import messages from './messages';
import {
  useRebateGroupState,
  RebateGroupTypes,
  RebateGroupStatus,
} from '../../context';

const StepsWrapper = styled.div`
  max-width: 920px;
  margin: 0 auto;
`;

const CreateRebateGroup = React.memo(
  (props: {
    drawerShown: boolean;
    refetch: (e?: any) => void;
    hideDrawer: () => void;
  }) => {
    const translate = useTranslate();
    const { drawerShown, hideDrawer, refetch } = props;
    const [activeStep, setActiveStep] = React.useState(0);
    const [isSaveAndExit, setIsSaveAndExit] = React.useState(false);

    React.useEffect(() => {
      if (!drawerShown) {
        setActiveStep(0);
      }
    }, [drawerShown]);

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const formRef: any = React.useRef<HTMLInputElement>();

    const [
      rebateGroupState,
      rebateGroupDispatch,
    ] = useRebateGroupState() as any;
    const { activeRebateGroupData } = rebateGroupState;

    const rebateGroup: any = pickBy(activeRebateGroupData, identity);
    const handleHide = () => {
      if (formRef.current) {
        formRef.current.resetForm();
      }
      if (!rebateGroupState.summaryIsActive) {
        rebateGroupDispatch({
          type: RebateGroupTypes.RESET_ACTIVE_REBATE_GROUP,
        });
      }
      hideDrawer();
    };

    const isMonth = (duration: string) => {
      const period = duration.replace(/[0-9]+/g, '');
      return period === 'd' && !(+duration.replace(period, '') % 30);
    };

    const isYear = (duration: string) => {
      const period = duration.replace(/[0-9]+/g, '');
      return period === 'd' && !(+duration.replace(period, '') % 365);
    };

    const getDurationPeriods = () => {
      const durationKeys = [
        'claimOffsetDuration',
        'claimExpiryDuration',
        'payoutExpiryDuration',
      ];
      return durationKeys.reduce((acc, durationKey) => {
        const periodKey = `${durationKey}Period`;
        const noDurationKey = `no${durationKey.charAt(0).toUpperCase() +
          durationKey.slice(1)}`;

        const duration = coercedGet(rebateGroup, durationKey, '0ms');
        const monthable = isMonth(duration);
        const yearable = isYear(duration);

        let durationValue = 0;
        let periodValue = '';
        const noDurationValue = duration === '0ms';

        if (noDurationValue) {
          periodValue = '-';
        } else if (monthable) {
          durationValue = +duration.replace(/[a-z]+/g, '') / 30;
          periodValue = 'm';
        } else if (yearable) {
          durationValue = +duration.replace(/[a-z]+/g, '') / 365;
          periodValue = 'y';
        } else {
          durationValue = +duration.replace(/[a-z]+/g, '');
          periodValue = duration.replace(/[0-9]+/g, '');
        }

        return {
          ...acc,
          [durationKey]: durationValue,
          [periodKey]: periodValue,
          [noDurationKey]: noDurationValue,
        };
      }, {});
    };

    const durationPeriods: DynamicObj = getDurationPeriods();

    const percentages = rebateGroupState.vendors.reduce(
      (acc: DynamicObj, { node }: { node: DynamicObj }) => {
        const gameTypes = get(node, 'gameTypes', []);
        let newPercentages = {};
        gameTypes.map((gameType: string) => {
          newPercentages = {
            ...newPercentages,
            [`${gameType}_${node.id}`]: {
              vendor: node.id,
              gameType,
              percentage: 0,
            },
          };
          return null;
        });
        return { ...acc, ...newPercentages };
      },
      {}
    );

    const levels = rebateGroup.levels
      ? rebateGroup.levels.map((level: any) => {
          let newPercentages = {};
          const newLevel = {
            ...level,
            percentages: level.percentages.reduce(
              (
                acc: any,
                {
                  vendor: { id },
                  gameType,
                  percentage,
                  games,
                }: {
                  vendor: {
                    id: string;
                  };
                  gameType: string;
                  percentage: string | number;
                  games: Array<any>;
                }
              ) => {
                let newGames = {};
                newPercentages = {
                  ...newPercentages,
                  [`${gameType}_${id}`]: {
                    vendor: id,
                    percentage: +(+percentage * 100).toFixed(2),
                    gameType,
                    games:
                      games !== undefined
                        ? games.reduce((accu, g) => {
                            newGames = {
                              ...newGames,
                              [`${g.game.id}`]: {
                                percentage: +(g.percentage * 100).toFixed(2),
                                game: g.game.id,
                              },
                            };
                            return { ...accu, ...newGames };
                          }, newGames)
                        : undefined,
                  },
                };
                return { ...acc, ...newPercentages };
              },
              newPercentages
            ),
            ...(level.__typename !== undefined && { __typename: undefined }),
          };
          return newLevel;
        })
      : [];

    const [initialValues, setInitialValues] = React.useState({});
    React.useEffect(
      () => {
        if (rebateGroupState.vendors) {
          setInitialValues({
            ...initialValues,
            percentages,
          });
        }
        if (
          rebateGroupState.activeRebateGroup &&
          rebateGroupState.preventFillFor !== 'create'
        ) {
          const validityDateTimeRangeStart = coercedGet(
            rebateGroup,
            'validityDateTimeRange.start',
            ''
          );
          const validityDateTimeRangeEnd = coercedGet(
            rebateGroup,
            'validityDateTimeRange.end',
            ''
          );
          let values = {
            ...initialValues,
            ...omit(
              pick(
                rebateGroup,
                mergeArrayValues([
                  Object.keys(rebateSettingsSchema(translate)),
                  Object.keys(payoutSettingsSchema(translate)),
                  Object.keys(rebateGroupSettingsSchema(translate)),
                  Object.keys(publishSettingsSchema),
                ])
              ),
              [
                'payoutSettlementTime',
                'payoutSettlementDayOfWeek',
                'payoutSettlementDayOfMonth',
              ]
            ),
            validityDateTimeRange: {
              start: validityDateTimeRangeStart
                ? moment(validityDateTimeRangeStart)
                : null,
              end: validityDateTimeRangeEnd
                ? moment(validityDateTimeRangeEnd)
                : null,
            },
            payoutSettlementPeriod: coercedGet(
              rebateGroup,
              'payoutSettlementPeriod',
              ''
            ),
            claimOffsetDuration: durationPeriods.claimOffsetDuration,
            claimOffsetDurationPeriod:
              durationPeriods.claimOffsetDurationPeriod,
            noClaimOffsetDuration: durationPeriods.noClaimOffsetDuration,
            claimExpiryDuration: durationPeriods.claimExpiryDuration,
            claimExpiryDurationPeriod:
              durationPeriods.claimExpiryDurationPeriod,
            noClaimExpiryDuration: durationPeriods.noClaimExpiryDuration,
            payoutExpiryDuration: durationPeriods.payoutExpiryDuration,
            payoutExpiryDurationPeriod:
              durationPeriods.payoutExpiryDurationPeriod,
            noPayoutExpiryDuration: durationPeriods.noPayoutExpiryDuration,
            percentages: {
              ...rebateGroupState.defaultVendorRebate,
              ...(
                (rebateGroup.type === CreateRebateGroupTypes.LIVE
                  ? rebateGroup.percentages
                  : get(rebateGroup, 'levels.0.percentages', [])) || []
              ).reduce(
                (
                  acc: any,
                  {
                    gameType,
                    percentage,
                    vendor: { id },
                  }: {
                    gameType: string;
                    percentage: number | string;
                    vendor: {
                      id: string;
                    };
                  }
                ) => ({
                  ...acc,
                  [`${gameType}_${id}`]: {
                    gameType,
                    percentage: +(+percentage * 100).toFixed(2),
                    vendor: id,
                  },
                }),
                {}
              ),
            },
            levels,
            excludedMemberLevels: get(
              rebateGroup,
              'excludedMemberLevels',
              []
            ).map(({ id }: { id: string }) => id),
            qualifyingMemberLoyaltyLevels: get(
              rebateGroup,
              'qualifyingMemberLoyaltyLevels',
              []
            )
              .filter((item: any) => !isEmpty(item))
              .map(({ id }: { id: string }) => id),
          };

          if (rebateGroup.type === CreateRebateGroupTypes.PERIODIC) {
            let payoutSettlementValue = null;
            const dateNow = new Date();
            const dateNowString = dateNow
              .toISOString()
              .split('T')
              .shift();
            const timeNowString = '16:00:00Z';
            switch (rebateGroup.payoutSettlementPeriod) {
              case SettlementPeriod.DAILY: {
                break;
              }
              case SettlementPeriod.WEEKLY:
                payoutSettlementValue = {
                  payoutSettlementDayOfWeek:
                    rebateGroup.payoutSettlementDayOfWeek,
                };
                break;
              case SettlementPeriod.MONTHLY:
                payoutSettlementValue = {
                  payoutSettlementDayOfMonth:
                    rebateGroup.payoutSettlementDayOfMonth - 1,
                };
                break;
              case SettlementPeriod.ANNUALLY: {
                const settlementDateTime = moment({
                  month: rebateGroup.payoutSettlementMonth - 1 || 0,
                  day: rebateGroup.payoutSettlementDayOfMonth || 1,
                });
                const dateTime = settlementDateTime.isValid()
                  ? settlementDateTime
                  : dateNow;
                payoutSettlementValue = {
                  payoutSettlementDateAndMonth: dateTime,
                };
                break;
              }
              default:
                payoutSettlementValue = {};
            }
            payoutSettlementValue = {
              ...payoutSettlementValue,
              payoutSettlementTime: moment(
                `${dateNowString} ${rebateGroup.payoutSettlementTime ||
                  timeNowString}`
              ),
            };
            values = {
              ...values,
              payoutSettlementPeriod: rebateGroup.payoutSettlementPeriod,
              ...payoutSettlementValue,
            };
          }
          setInitialValues(values);
        } else {
          setInitialValues({
            percentages,
          });
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        rebateGroupState.activeRebateGroup,
        rebateGroupState.defaultVendorRebate,
        rebateGroupState.vendors,
      ]
    );

    const onLastPage =
      activeStep + 1 === Object.keys(CreateRebateSteps).length &&
      coercedGet(rebateGroup, 'status', '') === RebateGroupStatus.INACTIVE;

    return (
      <Drawer open={drawerShown} onClose={handleHide}>
        <Drawer.Header
          title={translate(messages['rebates.add-rebate-group.text'])}
        >
          <div className="drawer-actions">
            <Button type="link" onClick={handleHide} style={{ height: 'auto' }}>
              <FormattedMessage
                id="rebates.cancel.text"
                defaultMessage="Cancel"
              />
            </Button>
            <Button
              type="link"
              onClick={async () => {
                if (formRef) {
                  await formRef.current.submitForm();
                }
              }}
              style={{ height: 'auto' }}
            >
              <FormattedMessage id="rebates.save.text" defaultMessage="Save" />
            </Button>
            <Button
              type="link"
              onClick={async () => {
                if (formRef.current) {
                  await formRef.current
                    .submitForm()
                    .then(() => {
                      setIsSaveAndExit(true);
                    })
                    .finally(() => {
                      handleHide();
                    });
                }
              }}
              style={{ height: 'auto' }}
            >
              <FormattedMessage
                id="rebates.save-and-exit.text"
                defaultMessage="Save and Exit"
              />
            </Button>
          </div>
        </Drawer.Header>
        <Drawer.Content heightExcess={140}>
          {activeStep + 1 <= Object.keys(CreateRebateSteps).length && (
            <StepsWrapper>
              <Steps activeStep={activeStep} steps={CreateRebateSteps} />
            </StepsWrapper>
          )}
          <InnerForm
            ref={formRef}
            defaultValues={initialValues as any}
            activeStep={activeStep}
            hideDrawer={hideDrawer}
            setActiveStep={setActiveStep}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            isSaveAndExit={isSaveAndExit}
            setIsSaveAndExit={setIsSaveAndExit}
            refetch={refetch}
          />
        </Drawer.Content>
        {activeStep + 1 <= Object.keys(CreateRebateSteps).length && (
          <Drawer.Footer>
            <div className="d-flex justify-content-flex-end">
              {activeStep > 0 && (
                <Button
                  size="large"
                  className="mx-2"
                  onClick={() => setActiveStep((step) => step - 1)}
                >
                  <FormattedMessage
                    id="common.previous.text"
                    defaultMessage="Previous"
                  />
                </Button>
              )}
              <Button
                size="large"
                type="primary"
                disabled={isSubmitting}
                onClick={async () => {
                  if (!formRef) {
                    return;
                  }
                  const form = formRef.current;
                  const errors = await form.validateForm();
                  if (Object.keys(errors).length < 1) {
                    if (activeStep + 1 === 3) {
                      await form.submitForm();
                    }
                    setActiveStep((step) => step + 1);
                  }
                }}
              >
                {translate(
                  messages[
                    `common.${onLastPage ? 'save-changes' : 'next'}.text`
                  ]
                )}
              </Button>
            </div>
          </Drawer.Footer>
        )}
      </Drawer>
    );
  }
);

export default CreateRebateGroup;
