import React from 'react';
import Icon from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import bgImage from './assets/bg.svg';
import lockImage from './assets/lock illustration.png';
import { ReactComponent as ExclamationIcon } from './assets/Icon_Exclamation-circle.svg';

type Props = {
  ip: string;
  code: string;
};

export const AccessDenied = ({ ip, code }: Props) => (
  <div
    style={{
      height: '100vh',
      width: 'auto',
      backgroundImage: `url(${bgImage})`,
      paddingLeft: '15%',
      paddingRight: '15%',
    }}
    className="d-flex justify-content-center align-items-center"
  >
    <div>
      <img src={lockImage} alt="123" />
    </div>
    <div style={{ width: '50%' }}>
      <h2 style={{ fontSize: '24px' }}>
        <Icon
          component={ExclamationIcon}
          className="mr-3 text-black--65"
          style={{ fontSize: '34px' }}
        />
        www.xxx.com -{' '}
        <FormattedMessage
          id="access-denied.text"
          defaultMessage="Access Denied"
        />
      </h2>

      <h2
        className="fw-600 text-black--85"
        style={{ fontSize: '66px', marginBottom: '14px' }}
      >
        <FormattedMessage id="oops!.text" defaultMessage="Oops!" />
      </h2>

      <h2
        className="text-black--65"
        style={{
          fontSize: '30px',
          width: '60%',
        }}
      >
        <FormattedMessage
          id="access-denied.restricted-message.text"
          defaultMessage="Your current location is restricted from accessing our domain name."
        />
      </h2>

      <h2 className="text-black--65" style={{ fontSize: '24px' }}>
        <FormattedMessage id="error-code.text" defaultMessage="Error Code" />:{' '}
        {code}
      </h2>

      <h2 className="text-black--65" style={{ fontSize: '16px' }}>
        <FormattedMessage
          id="access-denied.request-blocked.text"
          defaultMessage="This request was blocked by the security rules"
        />
      </h2>
      <h2 className="text-black--65 my-3" style={{ fontSize: '16px' }}>
        {new Date().toUTCString()}
      </h2>

      <h2 className="text-black--65" style={{ fontSize: '16px' }}>
        <FormattedMessage
          id="access-denied.your-ip.text"
          defaultMessage="Your IP"
        />{' '}
        {ip}
      </h2>
    </div>
  </div>
);
