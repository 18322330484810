import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Button, message, Spin } from 'antd';
import gql from 'graphql-tag';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { updateMemberCacheTags } from 'utils/updateMemberTagsCache';
import { Member, MemberTag } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { includes, compact } from 'lodash';
import useTranslate from 'utils/useTranslate';
import EditTagMenuForm from './components/EditTagMenuForm';
import messages from './messages';
import { StyledModal } from './styles';

const MEMBER_TAGS = gql`
  query MemberTags($id: ID!) {
    member(id: $id) {
      id
      tags {
        id
        name
        color
        description
      }
    }
  }
`;

type Props = {
  member: Member;
  onSuccess?: (id: string) => void;
  refetch?: Function;
};

const EditTagMenu = ({ member, onSuccess, refetch }: Props) => {
  const [updateMemberTags] = useMutation(UPDATE_MEMBER);
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const [tags, setTags] = useState([]);
  const [deletedTags, setDeletedTags] = useState<MemberTag[]>([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const { context } = useOperatorHeader();

  const handleShow = () => setShow(!isShow);
  const translate = useTranslate();
  const handleSubmit = async (e: Record<string, any>) => {
    setLoading(true);
    try {
      const input = { ...e };
      delete input.type;
      const inputTags = input.tags.map((item: MemberTag) => item.id);
      const initialTags = tags.map((tag: MemberTag) => tag.id);
      const rawDeletedTags = deletedTags.map((tag) => tag.id);

      const removedTags = rawDeletedTags.map((deletedTag) =>
        includes(inputTags, deletedTag) && includes(initialTags, deletedTag)
          ? null
          : deletedTag
      );

      updateMemberTags({
        variables: {
          id: member.id,
          input: {
            tagsV2: {
              add: inputTags,
              remove: compact(removedTags),
            },
          },
        },
        update: updateMemberCacheTags(inputTags, member.id),
        context,
      }).then(() => {
        if (refetch) refetch();
      });
      message.success(
        translate(
          messages['member-management.member-label-update-success.text']
        )
      );
      handleShow();
      if (onSuccess) onSuccess(member.id);
      setLoading(false);
    } finally {
      setDeletedTags([]);
      setLoading(false);
    }
  };
  const client = useApolloClient();

  useEffect(() => {
    if (isShow) {
      setLoadingTags(true);
      client
        .query({
          query: MEMBER_TAGS,
          variables: {
            id: member.id,
          },
          fetchPolicy: 'network-only',
        })
        .then((resp) => {
          const tagsData = coercedGet(resp, 'data.member.tags', []);
          setTags(tagsData);
        })
        .finally(() => setLoadingTags(false));
    }
  }, [client, isShow, member.id]);

  return (
    <>
      <Button type="link" className="text-black" onClick={handleShow} block>
        <FormattedMessage id="edit-labels.text" defaultMessage="Edit Labels" />
      </Button>
      <StyledModal
        title={
          <FormattedMessage
            id="edit-labels.text"
            defaultMessage="Edit Labels"
          />
        }
        onCancel={handleShow}
        visible={isShow}
        footer={false}
        closable={false}
      >
        <Spin spinning={loadingTags} tip={translate(messages.LOADING_TAGS)}>
          <EditTagMenuForm
            initialValues={{
              id: member.id,
              type: 'ADD_REMOVE',
              tags,
            }}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onClose={handleShow}
            setDeletedTags={setDeletedTags}
          />
        </Spin>
      </StyledModal>
    </>
  );
};

export default EditTagMenu;
