/* eslint-disable no-bitwise */
import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import {
  Game,
  Member,
  MemberBetRecord,
  MemberBetRecordStatus,
  Vendor,
} from 'types/graphqlTypes';
import { Typography } from 'antd';
import { capitalize } from 'lodash';
import moment from 'moment';
import TruncateWithTooltip from 'components/TruncateWithTooltip';
import { DATE_TIME_FORMAT } from 'constants/date';
import DetailsModal from './components/DetailsModal';

const { Text } = Typography;

const reportFormatDate = (date: string, timezone: string) =>
  date ? moment.tz(date, timezone).format(`${DATE_TIME_FORMAT} A`) : '-';

const deviceEnum = {
  DESKTOP_WEB: 'Web',
  MOBILE_WEB: 'Mobile',
};

export const getColumns = ({
  renderNumeral,
  reportTimezone,
  isRow,
  showBaseAmounts,
}: {
  renderNumeral: (
    value: string | number,
    isCrypto?: boolean,
    isExchangeRate?: boolean
  ) => string;
  reportTimezone: string;
  isRow?: boolean;
  showBaseAmounts?: boolean | null;
}): ColumnsType<MemberBetRecord> => [
  {
    key: 'serialCode',
    title: 'Serial Code',
    dataIndex: 'serialCode',
    width: 100,
    align: 'center',
  },
  {
    key: 'balanceUnitSerialCodes',
    title: 'Balance Unit Serial Codes',
    dataIndex: 'balanceUnitSerialCodes',
    width: 100,
    align: 'center',
    render: (code: string[]) => (code?.length > 0 ? code.join(',') : '-'),
  },
  {
    key: 'affiliate',
    title: 'Affiliate',
    dataIndex: 'affiliate',
    width: 100,
    align: 'center',
    render: (affiliate: string[]) => affiliate || '-',
  },
  {
    key: 'member',
    title: 'Member',
    dataIndex: 'member',
    width: 100,
    align: 'center',
    render: (member: Member) => <TruncateWithTooltip text={member.username!} />,
  },
  {
    key: 'platformId',
    title: 'Platform Id',
    dataIndex: 'member',
    width: 100,
    align: 'center',
    render: (member: Member) => (
      <TruncateWithTooltip text={member.platformId!} />
    ),
  },
  {
    key: 'vendorBetId',
    title: 'Vendor Bet Id',
    dataIndex: 'round',
    width: 100,
    align: 'center',
    render: (round: string, record: { id: string }) => (
      <DetailsModal data={round} id={record.id} />
    ),
  },
  {
    key: 'effectiveBet',
    title: isRow ? 'Effective Bet Amount (Member)' : 'Effective Bet Amount',
    dataIndex: 'effectiveBet',
    width: 100,
    align: 'center',
    render: (effectiveBet: string) => renderNumeral(effectiveBet),
  },
  {
    key: 'turnover',
    title: isRow ? 'Bet Amount (Member)' : 'Bet Amount',
    dataIndex: 'turnover',
    width: 100,
    align: 'center',
    render: (turnover: string) => renderNumeral(turnover),
  },
  {
    key: 'winloss',
    title: isRow ? 'Win / Loss (Member)' : 'Win / Loss',
    width: 100,
    align: 'center',
    render: (betRecord: MemberBetRecord) => {
      const { winloss, status } = betRecord || {};

      if (status === MemberBetRecordStatus.Unsettled) return '-';

      const color = winloss < 0 ? '#f5222d' : '#52c41a';

      return (
        (winloss && <Text style={{ color }}>{renderNumeral(winloss)}</Text>) ||
        '0.00'
      );
    },
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    width: 100,
    align: 'center',
    render: (status: string) => capitalize(status),
  },
  {
    key: 'winloss-status',
    title: 'Win/loss Status',
    dataIndex: 'winloss',
    width: 100,
    align: 'center',
    render: (winloss: number, betRecord: MemberBetRecord) => {
      const { payout, freeSpin, status } = betRecord || {};

      if (!winloss && !payout && !freeSpin) {
        return 'Refunded';
      }

      if (!winloss && payout > 0) {
        return 'Draw';
      }

      if ((!winloss && !payout && freeSpin) || (winloss < 0 && !payout)) {
        return 'Loss';
      }

      if (winloss !== 0 && payout > 0) {
        return 'Win';
      }

      return status === 'UNSETTLED' && 'Pending';
    },
  },
  {
    ...(!isRow && {
      key: 'cashTurnover',
      title: 'Turnover',
      dataIndex: 'turnover',
      width: 100,
      align: 'center',
      render: (turnover: string) => renderNumeral(turnover),
    }),
  },
  {
    ...(isRow && {
      key: 'cashTurnover',
      title: isRow ? 'Cash Turnover (Member)' : 'Cash Turnover',
      dataIndex: 'cashTurnover',
      width: 100,
      align: 'center',
      render: (cashTurnover: number) =>
        !cashTurnover ? '-' : cashTurnover.toFixed(2),
    }),
  },
  {
    ...(isRow && {
      key: 'bonusTurnover',
      title: isRow ? 'Bonus Turnover (Member)' : 'Bonus Turnover',
      dataIndex: 'bonusTurnover',
      width: 100,
      align: 'center',
      render: (bonusTurnover: number) => (!bonusTurnover ? '-' : bonusTurnover),
    }),
  },
  {
    key: 'ggr',
    title: isRow ? 'GGR (Member)' : 'GGR',
    dataIndex: 'ggr',
    width: 100,
    align: 'center',
    render: (ggr: string) => renderNumeral(ggr),
  },
  {
    key: 'payout',
    title: isRow ? 'Payout (Member)' : 'Payout',
    dataIndex: 'payout',
    width: 100,
    align: 'center',
    render: (payout: string) => renderNumeral(payout),
  },
  {
    key: 'vendor',
    title: 'Game Vendor',
    dataIndex: 'vendor',
    width: 100,
    align: 'center',
    render: (vendor: Vendor) => <TruncateWithTooltip text={vendor.name!} />,
  },
  {
    key: 'gameTitle',
    title: 'Game Title',
    dataIndex: 'game',
    width: 100,
    align: 'center',
    render: (game: Game) => {
      const { name } = game;

      if (name === 'BetVision') return 'Sportsbook';

      return <TruncateWithTooltip text={name!} />;
    },
  },
  {
    key: 'gameCategory',
    title: 'Game Category',
    dataIndex: 'game',
    width: 150,
    align: 'center',
    render: (game: Game) => game.category,
  },
  {
    key: 'gameSubCategory',
    title: 'Game Sub Category',
    dataIndex: 'game',
    width: 100,
    align: 'center',
    render: (game: Game) => <TruncateWithTooltip text={game.subCategory!} />,
  },
  {
    key: 'dateTimePlaced',
    title: 'Date/Time Placed',
    dataIndex: 'dateTimePlaced',
    width: 100,
    align: 'center',
    render: (dateTimePlaced: string) =>
      reportFormatDate(dateTimePlaced, reportTimezone),
  },
  {
    key: 'dateTimeSettled',
    title: 'Date/Time Settled',
    dataIndex: 'dateTimeSettled',
    width: 100,
    align: 'center',
    render: (dateTimeSettled: string) =>
      reportFormatDate(dateTimeSettled, reportTimezone),
  },
  {
    key: 'dateTimeCancelled',
    title: 'Date/Time Cancelled',
    dataIndex: 'dateTimeCancelled',
    width: 100,
    align: 'center',
    render: (dateTimeCancelled: string) =>
      reportFormatDate(dateTimeCancelled, reportTimezone),
  },
  {
    key: 'session',
    title: 'Session Id',
    dataIndex: 'session',
    width: 100,
    align: 'center',
    render: (session: string) => <TruncateWithTooltip text={session} />,
  },
  {
    key: 'tips',
    title: 'Tips',
    dataIndex: 'tips',
    width: 100,
    align: 'center',
    render: (tips: string) => <TruncateWithTooltip text={tips} />,
  },
  {
    key: 'jackpotContributionBet',
    title: 'Jackpot Contribution Bet',
    dataIndex: 'jackpotContributionBet',
    width: 100,
    align: 'center',
    render: (jackpotContributionBet: string) => (
      <TruncateWithTooltip text={jackpotContributionBet} />
    ),
  },
  {
    key: 'jackpotWin',
    title: 'Jackpot Win',
    dataIndex: 'jackpotWin',
    width: 100,
    align: 'center',
    render: (jackpotWin: string) => <TruncateWithTooltip text={jackpotWin} />,
  },
  {
    key: 'cashoutWinloss',
    title: 'Cashout Win/Loss',
    dataIndex: 'cashoutWinloss',
    width: 100,
    align: 'center',
    render: (cashoutWinloss: string) => (
      <TruncateWithTooltip text={cashoutWinloss} />
    ),
  },
  {
    key: 'freeSpin',
    title: 'Free Spin',
    dataIndex: 'freeSpin',
    width: 100,
    align: 'center',
    render: (freeSpin: boolean) => (freeSpin ? 'Yes' : 'No'),
  },
  {
    key: 'device',
    title: 'Device',
    dataIndex: 'device',
    width: 100,
    align: 'center',
    render: (device: string) => (device ? deviceEnum[device] : '-'),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'baseEffectiveBet',
        title: 'Effective Bet Amount (Base)',
        dataIndex: 'baseEffectiveBet',
        width: 100,
        align: 'center',
        render: (baseEffectiveBet: string) => renderNumeral(baseEffectiveBet),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'baseWinloss',
        title: 'Win / Loss (Base)',
        width: 100,
        align: 'center',
        render: (betRecord: MemberBetRecord) => {
          const { baseWinloss, status } = betRecord || {};

          if (status === MemberBetRecordStatus.Unsettled) return '-';

          const color = baseWinloss < 0 ? '#f5222d' : '#52c41a';

          return (
            (baseWinloss && (
              <Text style={{ color }}>{renderNumeral(baseWinloss)}</Text>
            )) ||
            '0.00'
          );
        },
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'baseCashTurnover',
        title: 'Cash Turnover (Base)',
        dataIndex: 'baseCashTurnover',
        width: 100,
        align: 'center',
        render: (baseCashTurnover: number) =>
          !baseCashTurnover ? '-' : baseCashTurnover.toFixed(2),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'baseBonusTurnover',
        title: 'Bonus Turnover (Base)',
        dataIndex: 'baseBonusTurnover',
        width: 100,
        align: 'center',
        render: (baseBonusTurnover: number) =>
          !baseBonusTurnover ? '-' : baseBonusTurnover,
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'baseGgr',
        title: 'GGR (Base)',
        dataIndex: 'baseGgr',
        width: 100,
        align: 'center',
        render: (baseGgr: string) => renderNumeral(baseGgr),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'basePayout',
        title: 'Payout (Base)',
        dataIndex: 'basePayout',
        width: 100,
        align: 'center',
        render: (basePayout: string) => renderNumeral(basePayout),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'baseTurnover',
        title: 'Bet Amount (Base)',
        dataIndex: 'baseTurnover',
        width: 100,
        align: 'center',
        render: (baseTurnover: string) => renderNumeral(baseTurnover),
      }),
  },
  {
    ...(isRow &&
      showBaseAmounts && {
        key: 'exchangeRate',
        title: 'Exchange Rate',
        dataIndex: 'exchangeRate',
        width: 100,
        align: 'center',
        render: (exchangeRate: string) =>
          renderNumeral(exchangeRate, false, true),
      }),
  },
  {
    ...(isRow && {
      key: 'memberCurrency',
      title: 'Member Currency',
      dataIndex: 'memberCurrency',
      width: 100,
      align: 'center',
      render: (memberCurrency: string) =>
        !memberCurrency ? '-' : memberCurrency,
    }),
  },
  {
    ...(isRow && {
      key: 'cryptoTurnover',
      title: 'Crypto Turnover',
      dataIndex: 'cryptoTurnover',
      width: 100,
      align: 'center',
      render: (cryptoTurnover: number) => cryptoTurnover || '-',
    }),
  },
  {
    ...(isRow && {
      key: 'cryptoEffectiveBet',
      title: 'Crypto Effective Bet',
      dataIndex: 'cryptoEffectiveBet',
      width: 100,
      align: 'center',
      render: (cryptoEffectiveBet: number) => cryptoEffectiveBet || '-',
    }),
  },
  {
    ...(isRow && {
      key: 'cryptoWinloss',
      title: 'Crypto Winloss',
      dataIndex: 'cryptoWinloss',
      width: 100,
      align: 'center',
      render: (cryptoWinloss: number) => cryptoWinloss || '-',
    }),
  },
  {
    ...(isRow && {
      key: 'cryptoCashTurnover',
      title: 'Crypto Cash Turnover',
      dataIndex: 'cryptoCashTurnover',
      width: 100,
      align: 'center',
      render: (cryptoCashTurnover: number) => cryptoCashTurnover || '-',
    }),
  },
  {
    ...(isRow && {
      key: 'cryptoBonusTurnover',
      title: 'Crypto Bonus Turnover',
      dataIndex: 'cryptoBonusTurnover',
      width: 100,
      align: 'center',
      render: (cryptoBonusTurnover: number) => cryptoBonusTurnover || '-',
    }),
  },
  {
    ...(isRow && {
      key: 'cryptoGgr',
      title: 'Crypto GGR',
      dataIndex: 'cryptoGgr',
      width: 100,
      align: 'center',
      render: (cryptoGgr: number) => cryptoGgr || '-',
    }),
  },
  {
    ...(isRow && {
      key: 'cryptoPayout',
      title: 'Crypto Payout',
      dataIndex: 'cryptoPayout',
      width: 100,
      align: 'center',
      render: (cryptoPayout: number) => cryptoPayout || '-',
    }),
  },
  {
    ...(isRow && {
      key: 'cryptoCurrency',
      title: 'Crypto Currency',
      dataIndex: 'cryptoCurrency',
      width: 100,
      align: 'center',
      render: (cryptoCurrency: string) => cryptoCurrency || '-',
    }),
  },
  {
    ...(isRow && {
      key: 'cryptoExchangeRate',
      title: 'Crypto Exchange Rate',
      dataIndex: 'cryptoExchangeRate',
      width: 100,
      align: 'center',
      render: (cryptoExchangeRate: number) => cryptoExchangeRate || '-',
    }),
  },
];
