import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { Col, Divider, Empty, Row, Spin } from 'antd';
import { get } from 'lodash';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import { useApolloClient } from '@apollo/react-hooks';
import { formatDate } from 'utils/dateUtils';
import { useConfig } from 'hooks/useConfig';
import { DATE_TIME_FORMAT } from 'constants/date';
import { CashBalanceCards } from '../../../../utils';
import { MEMBER_REBATE_PAYOUTS } from './queries';

export const RebatesBalance = ({ member }: { member: any }) => {
  const [rebatePayouts, setRebatePayouts] = useState<IRebatePayout[]>([]);
  const [endCursor, setEndCursor] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [onLoading, setOnLoading] = useState(false);
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0,
  });
  const { addCurrency } = useConfig();

  const client = useApolloClient();
  const loadMoreRebatePayouts = useCallback(async () => {
    const { data } = await client.query({
      query: MEMBER_REBATE_PAYOUTS,
      fetchPolicy: 'network-only',
      context: { shouldBatch: true },
      variables: {
        id: member.id,
        after: endCursor,
      },
    });
    const response = get(data, 'member.rebatePayouts', {});
    setEndCursor(response.pageInfo.endCursor);
    setRebatePayouts((prev) => [
      ...prev,
      ...response.edges.map((x: any) => x.node),
    ]);
  }, [client, endCursor, member.id]);

  const loadInitialRebatePayouts = useCallback(async () => {
    const { data } = await client.query({
      query: MEMBER_REBATE_PAYOUTS,
      variables: {
        id: member.id,
        after: null,
      },
    });
    const response = get(data, 'member.rebatePayouts', {});
    setEndCursor(response.pageInfo.endCursor || '');
    setTotalCount(response.totalCount || 0);
    setRebatePayouts((prev) => [
      ...prev,
      ...response.edges.map((x: any) => x.node),
    ]);
  }, [client, member.id]);

  useEffect(() => {
    loadInitialRebatePayouts();
  }, [loadInitialRebatePayouts]);

  useEffect(() => {
    if (inView) {
      setOnLoading(true);
      loadMoreRebatePayouts();
    }
  }, [inView, loadMoreRebatePayouts]);

  return rebatePayouts.length > 0 ? (
    <div className="d-flex">
      <CashBalanceCards className="mr-3">
        <h4 className="text-muted fs-12">
          <FormattedMessage
            id="rebates-payouts.text"
            defaultMessage="Rebates Payouts"
          />
        </h4>
        <h4 className="fs-14" style={{ marginBottom: '4px' }}>
          <FormattedMessage id="total.text" defaultMessage="Total" />{' '}
          {addCurrency(member.totalRebatePayout, 2, Math.floor)}
        </h4>
        <h3 className="fs-14 mb-0">
          {totalCount}{' '}
          <FormattedMessage
            id="member-detail.times.text"
            defaultMessage="times"
          />
        </h3>
      </CashBalanceCards>
      <div style={{ maxHeight: '117px', overflowY: 'auto', flex: 1 }}>
        <Row style={{ fontSize: '10px' }} className="mb-1">
          <Col span={13}>
            <span className="text-muted-light">
              <FormattedMessage id="name.text" defaultMessage="Name" />
            </span>
          </Col>

          <Col span={4}>
            <span className="text-muted-light">
              <FormattedMessage id="amount.text" defaultMessage="Amount" />
            </span>
          </Col>
          <Col span={7}>
            <span className="text-muted-light">
              <FormattedMessage
                id="date-credited.text"
                defaultMessage="Date Credited"
              />
            </span>
          </Col>
        </Row>

        {rebatePayouts.map((rebatePayout) => (
          <Fragment key={rebatePayout.id}>
            <Row style={{ fontSize: '10px' }}>
              <Col span={13}>
                <strong>{get(rebatePayout, 'rebateGroup.name')}</strong>
              </Col>

              <Col span={4}>{rebatePayout.amount.toFixed(4)}</Col>
              <Col span={7}>
                {formatDate(rebatePayout.dateTimeClaimed, DATE_TIME_FORMAT)}
              </Col>
            </Row>
            <Divider className="my-1" />
          </Fragment>
        ))}
        {totalCount > rebatePayouts.length && (
          <div className="text-center p-1" ref={ref}>
            <Spin spinning={onLoading}>
              <FormattedMessage id="loading.text" defaultMessage="Loading..." />
            </Spin>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Empty />
  );
};

interface IRebatePayout {
  id: string;
  amount: number;
  dateTimeClaimed: string;
  rebateGroup: {
    id: string;
    name: string;
  };
}
