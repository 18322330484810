import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { Popover } from 'antd';
import Circle from 'components/Circle';
import { DATE_TIME_FORMAT } from 'constants/date';
import { customFormatMessage } from 'utils/customFormatMessage';
import { messages } from './messages';

const columns = (formatMessage: any) => {
  const translate = (messageVal: any) =>
    customFormatMessage(formatMessage, messageVal);

  return [
    {
      key: 'permissionGroups',
      title: translate(messages['permission-groups.text']),
      dataIndex: 'permissionGroups',
      render: (permissionGroups: any[]) => {
        if (!permissionGroups.length) return '-';

        const content = (
          <div>
            {permissionGroups.map((newPermissionGroup, index) => (
              <p key={index}>{newPermissionGroup.name}</p>
            ))}
          </div>
        );

        const colDisplay =
          permissionGroups.length > 1 ? (
            <>
              {permissionGroups.map((permissionGroup, key) => {
                if (key > 1) return '';
                return (
                  <span>
                    {permissionGroup.name}
                    {permissionGroups.length - 1 !== key && ', '}
                  </span>
                );
              })}
              {permissionGroups.length > 2 && <span> ...</span>}
            </>
          ) : (
            permissionGroups[0].name
          );

        return (
          <Popover
            placement="left"
            content={content}
            title={translate(messages['permission-groups.text'])}
          >
            <span style={{ cursor: 'pointer' }}>{colDisplay}</span>
          </Popover>
        );
      },
    },
    {
      key: 'lastLoginDateTime',
      title: translate(messages['last-login-date.text']),
      dataIndex: 'lastLoginDateTime',
      render: (lastLoginDateTime: string) =>
        moment(lastLoginDateTime).format(`${DATE_TIME_FORMAT} A`),
    },
    {
      key: 'registrationDateTime',
      title: translate(messages['registration-date.text']),
      dataIndex: 'registrationDateTime',
      render: (registrationDateTime: string) =>
        moment(registrationDateTime).format(`${DATE_TIME_FORMAT} A`),
    },
    {
      key: 'status',
      title: translate(messages['status.text']),
      dataIndex: 'status',
      render: (status: string) => {
        if (!status) return '-';

        return status === 'ENABLED' ? (
          <>
            <Circle size={15} color="success" filled />{' '}
            <FormattedMessage id="enabled.text" defaultMessage="Enabled" />
          </>
        ) : (
          <>
            <Circle size={15} color="danger" filled />{' '}
            <FormattedMessage id="disabled.text" defaultMessage="Disabled" />
          </>
        );
      },
    },
  ];
};

export default columns;
