import * as Yup from 'yup';

export const validationSchema = () =>
  Yup.object().shape({
    isEmailReusable: Yup.boolean(),
    emailReusabilityCount: Yup.number()
      .when('isEmailReusable', {
        is: true,
        then: Yup.number().min(
          1,
          'Reusability count must be greater or equal to 1'
        ),
      })
      .nullable()
      .required('Field cannot be empty, please add a valid number'),
    isMobileNumberReusable: Yup.boolean(),
    mobileNumberReusabilityCount: Yup.number()
      .when('isMobileNumberReusable', {
        is: true,
        then: Yup.number().min(
          1,
          'Reusability count must be greater or equal to 1'
        ),
      })
      .nullable()
      .required('Field cannot be empty, please add a valid number'),
  });
