// tslint-disable no-use-before-define
import React, { useState } from 'react';
import {
  Modal,
  Row,
  Col,
  Descriptions,
  DatePicker,
  message,
  Button,
} from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import * as moment from 'moment-timezone';
import coercedGet from 'utils/coercedGet';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import VendorNames from 'SuperAdminMain/components/VendorsConsolidateUsageReport/components/VendorNames';
import { FeatureFlagsSA as FF } from 'SuperAdminMain/constants/featureflags';
import useIsNext from 'hooks/useIsNext';
import getTimezoneLabel from 'utils/getTimezoneLabel';
import useTransformedDates from 'SuperAdminMain/hooks/useTransformedDates';
import { ModuleDateTypes } from 'SuperAdminMain/components/VendorsConsolidateUsageReport/types';

const localMessages = defineMessages({
  VENDOR_ACCOUNTS_AND_PERIOD_IS_REQUIRED: {
    id: 'reports.vendor-accounts-and-period-filters-are-required.text',
    defaultMessage: 'Vendor Account and Period filters are required fields',
  },
});

export const CREATE_VENDOR_USAGE_REPORT = gql`
  mutation CreateVendorUsageReport($input: CreateVendorUsageReportInput!) {
    createVendorUsageReport(input: $input)
  }
`;

type Props = {
  input: any;
};

const ScheduleDownload = React.forwardRef(({ input }: Props, ref: any) => {
  const {
    bypassedCurrentTimezone,
    currentTimezone,
  }: any = useReportsTimezone();
  const translate = useTranslate();
  const [isShow, setIsShow] = useState(false);
  const toggleModal = () => {
    setIsShow((prev) => !prev);
  };

  const [createReport, { loading }] = useMutation(CREATE_VENDOR_USAGE_REPORT, {
    onCompleted: () => {
      message.success(
        translate(messages['reports.successfully-generated-report.text'])
      );
      setIsShow(false);
      ref.current.refetchSchedules();
    },
  });

  const handleCreateReport = async (schedule: any) => {
    const period = coercedGet(input, 'filter.dateTimeProcessed', null);
    const vendors = coercedGet(input, 'filter.vendor', null);

    if (!period || !vendors) {
      message.error(
        translate(localMessages.VENDOR_ACCOUNTS_AND_PERIOD_IS_REQUIRED)
      );
      return;
    }

    const newInput = schedule
      ? {
          ...input,
          scheduledDateTime: schedule.tz(currentTimezone),
        }
      : input;

    await createReport({
      variables: {
        input: newInput,
      },
    });
  };

  const { filters } = useFilterValues();

  const [schedule, setSchedule] = useState(null) as any;

  const { dateTimeProcessed } = useTransformedDates<ModuleDateTypes>([
    'dateTimeProcessed',
  ]) as any;

  const dateTimeProcGte = coercedGet(dateTimeProcessed, 'gte', null);
  const dateTimeProcLte = coercedGet(dateTimeProcessed, 'lte', null);

  const timeZoneLabel = getTimezoneLabel(
    coercedGet(filters, 'timezone', bypassedCurrentTimezone)
  );

  const vendors = coercedGet(filters, 'vendor.in', []);
  return (
    <>
      <Button
        disabled={!useIsNext(FF.curvScheduledList || '')}
        onClick={() => toggleModal()}
        size="small"
      >
        <FormattedMessage
          id="reports.schedule-download.text"
          defaultMessage="Schedule Download"
        />
      </Button>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          width={800}
          title={
            <FormattedMessage
              id="schedule-download.text"
              defaultMessage="Schedule Download"
            />
          }
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          onCancel={toggleModal}
          onOk={() => handleCreateReport(schedule)}
          okButtonProps={{
            disabled: !schedule || loading,
            loading,
          }}
        >
          <StyledDiv className="p-3">
            <h4>
              <FormattedMessage id="filter.text" defaultMessage="Filter" />
            </h4>
            <Row>
              <Col className="gutter-row" span={12}>
                <StyledDescriptions
                  bordered
                  size="small"
                  colon={false}
                  column={1}
                >
                  <Descriptions.Item label={translate(messages.PERIOD)}>
                    {dateTimeProcGte && dateTimeProcLte
                      ? `${moment
                          .tz(dateTimeProcGte, bypassedCurrentTimezone)
                          .format('YYYY/MM/DD HH:mm')} - ${moment
                          .tz(dateTimeProcLte, bypassedCurrentTimezone)
                          .format('YYYY/MM/DD HH:mm')}`
                      : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={translate(messages.TIMEZONE_SELECTION)}
                  >
                    {timeZoneLabel}
                  </Descriptions.Item>
                  <Descriptions.Item label={translate(messages.VENDORS)}>
                    <VendorNames
                      // vendors={vendor ? vendor.in : []}
                      vendors={vendors}
                    />
                  </Descriptions.Item>
                  {/* <Descriptions.Item
                    label={translate(messages.CLIENT_ACCOUNTS)}
                  >
                    <ClientFilterNames adminIds={adminIds} />
                  </Descriptions.Item> */}
                </StyledDescriptions>
              </Col>
            </Row>
          </StyledDiv>
          <Row className="mt-4">
            <Col className="gutter-row" span={10}>
              <span style={{ fontSize: 12 }}>
                {translate(messages.SCHEDULE_TIME)}
              </span>
              <DatePicker
                className="mt-1"
                showTime
                value={schedule}
                onChange={(e) => setSchedule(e)}
                format="YYYY/MM/DD HH:mm:ss"
              />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  );
});

export const StyledDiv = styled.div`
  background: #f5f5f5;
`;

export const StyledDescriptions = styled(Descriptions)`
  .ant-descriptions-view,
  .ant-descriptions-row {
    border: none;
    th {
      font-size: 12px;
      color: #0000008a !important;
      /* color: #0000008a; */
    }
    td {
      font-size: 12px;
      color: #000;
    }
  }
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    border: none;
    background: transparent;
    padding: 8px 0px !important;
  }

  .ant-descriptions-item-label {
    color: #0000008a !important;
  }
`;

export default ScheduleDownload;
