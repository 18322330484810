import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'title.text': {
    id: 'title.text',
    defaultMessage: 'Title',
  },
  'content.text': {
    id: 'content.text',
    defaultMessage: 'Content',
  },
});
