import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { withErrorHandler } from 'components/ErrorHandler';
import { MemberLoyaltyProgramme, MemberLoyaltyLevel } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import { messages as vipMessages } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/EditMultiVIP/utils';
import messages from 'messages';
import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import { Select, Tooltip } from 'antd';
import '@ant-design/compatible/assets/index.css';
import { truncateProgramName } from 'utils/truncateProgramName';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import { MEMBER_LOYALTY_LEVELS } from './queries';
import { StyledNewVIPSelect } from './styles';

interface Props {
  memberLevel: string[];
  onChange: <T extends {}>(e: T[]) => void;
  allProgramme?: boolean;
}

type Selected = {
  programme: MemberLoyaltyProgramme;
  selectedTier?: MemberLoyaltyLevel | string;
};

const MultiVIPSelect: React.FC<Props> = ({
  onChange,
  memberLevel,
  allProgramme = false,
}) => {
  const translate = useTranslate();
  const [opts, setOpts] = useState<Selected[]>([]);
  const [vipIds, setVipIds] = useState<string[]>();

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  );

  const [loadVIP, { loading }] = useLazyQuery<any>(MEMBER_LOYALTY_LEVELS, {
    variables: {
      ...(!allProgramme && {
        status: {
          eq: 'ACTIVE',
        },
      }),
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { memberLoyaltyProgrammes } = data;

      const vips: MemberLoyaltyProgramme[] =
        memberLoyaltyProgrammes.edges?.map(
          ({ node }: { node: MemberLoyaltyProgramme }) => node
        ) ?? [];

      vips
        .sort(
          ({ dateTimeCreated: timeA }, { dateTimeCreated: timeB }) =>
            new Date(timeA).getTime() - new Date(timeB).getTime()
        )
        .sort((a, b) => {
          const r = a.primary === b.primary ? 0 : null;
          return r ?? a.primary ? -1 : 1;
        });

      const newOpts: Selected[] = vips.map(
        (vip: MemberLoyaltyProgramme, index: number): Selected => {
          let selectedTier;

          if (index === 0 && vipIds?.[0] === undefined) {
            const { levels } = vip;
            const [primary] = levels as MemberLoyaltyLevel[];

            selectedTier = primary;
          } else {
            selectedTier = vip.levels?.find((tier: MemberLoyaltyLevel) =>
              memberLevel?.find((item) => item === tier.id)
            );
          }
          return {
            programme: vip,
            selectedTier,
          };
        }
      );

      if (vipIds?.[0] === undefined) {
        const ids = newOpts
          .map(
            (item: Selected) => (item.selectedTier as MemberLoyaltyLevel)?.id
          )
          .filter((item) => item);

        onChange(ids);
      }

      setOpts(newOpts);
    },
  });

  useEffect(() => {
    if (memberLevel) {
      setVipIds(memberLevel);
    }
  }, [memberLevel]);

  useEffect(() => {
    if (ALLOWED_LIST) loadVIP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadVIP, vipIds]);

  return (
    <>
      {opts?.length === 0 && (
        <Tooltip placement="bottom" title="Need Permission on VIP (List)">
          <StyledLabel label={translate(vipMessages.PRIMARY_VIP)}>
            <Select
              disabled={!ALLOWED_LIST}
              placeholder={translate(messages['please-enter.text'])}
            >
              <Select.Option disabled value="">
                No VIP
              </Select.Option>
            </Select>
          </StyledLabel>
        </Tooltip>
      )}

      {opts.map(({ programme, selectedTier }: Selected, index: number) => (
        <StyledLabel
          key={index}
          label={`${
            index === 0
              ? translate(vipMessages.PRIMARY_VIP)
              : `${translate(vipMessages.ADDITIONAL_VIP)} ${index}`
          } - ${programme.name}`}
        >
          <Container>
            <StyledNewVIPSelect
              style={{ width: '100%' }}
              placeholder={translate(messages['please-enter.text'])}
              tier={selectedTier}
              loading={loading}
              value={
                typeof selectedTier === 'object'
                  ? JSON.stringify(selectedTier)
                  : translate(vipMessages.NO_VIP)
              }
              onChange={(e: unknown) => {
                const newOpts = [...opts];
                newOpts[index].selectedTier = e ? JSON.parse(e as string) : e;
                setOpts(newOpts);

                const ids = newOpts
                  .map(
                    (item: Selected) =>
                      (item.selectedTier as MemberLoyaltyLevel)?.id
                  )
                  .filter((item) => item);

                onChange(ids);
              }}
            >
              {index !== 0 && selectedTier && (
                <StyledNewVIPSelect.Option value={undefined as any}>
                  {translate(vipMessages.REMOVE_VIP)}
                </StyledNewVIPSelect.Option>
              )}

              {programme.levels?.map((tier: MemberLoyaltyLevel, i: number) => (
                <StyledNewVIPSelect.Option
                  key={i + tier.name}
                  value={JSON.stringify(tier)}
                >
                  {`${truncateProgramName(programme?.name as string).text} - ${
                    tier.name
                  }`}
                </StyledNewVIPSelect.Option>
              ))}
            </StyledNewVIPSelect>
          </Container>
        </StyledLabel>
      ))}
    </>
  );
};

const StyledLabel = styled(Form.Item)<{ label: string }>``;
const Container = styled.div``;

export default withErrorHandler(MultiVIPSelect);
