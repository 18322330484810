import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import gameTypes from 'constants/gameTypes';

const { Option } = Select;

const GameType = ({
  state,
  setState,
}: {
  state: {
    gameTypes: Array<string>;
  };
  setState: (e: any) => void;
}) => (
  <div className="mt-2 mb-4">
    <div className="mb-1">
      <FormattedMessage
        id="reports.game-type.text"
        defaultMessage="Game Type"
      />
    </div>
    <Select
      placeholder={
        <FormattedMessage id="reports.select.text" defaultMessage="Select" />
      }
      mode="multiple"
      style={{ width: '100%' }}
      value={state.gameTypes}
      onChange={(values) =>
        setState((prevState: { reset: boolean }) => ({
          ...state,
          gameTypes: values,
          ...(!values.length && { reset: !prevState.reset }),
        }))
      }
    >
      {gameTypes.map((option) => (
        <Option key={option.id} value={option.type}>
          {option.name}
        </Option>
      ))}
    </Select>
  </div>
);

export default GameType;
