import gql from 'graphql-tag';

export const WITHDRAWAL_METHOD_NAME = gql`
  query WithdrawalMethod($id: ID!) {
    withdrawalMethod(id: $id) {
      id
      name
    }
  }
`;
