import React, { useCallback, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isEmpty, flatten } from 'lodash';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import useTranslate from 'utils/useTranslate';
import DrawerFooter from 'components/DrawerFooter';
import { useEffectOnce } from 'react-use';
import coercedGet from 'utils/coercedGet';
import { findDuplicates } from 'utils/findDuplicates';
import { getAllIndexes } from 'utils/getAllIndexes';
import Drawer from '../../../common/Drawer/Drawer';
import { useMissionsValue } from '../../context';
import { VIPGroupCard } from './VIPGroupCard';
import { localMessages, validationSchema } from './utils';

const appendDefaultValue = {
  name: '',
  qualifiedMemberLoyaltyLevels: [],
  milestoneRewardMultiplier: '1',
  taskMultiplier: '1',
  taskRewardMultiplier: '1',
  excludedMemberLevels: [],
  excludedMemberTags: [],
};

const VIPGroups = () => {
  const {
    onPrevStep,
    onNextStep,
    setVipGroupsValue,
    vipGroupsValue,
  } = useMissionsValue();
  const translate = useTranslate();
  const { hideMilestone, getAllValues } = useMissionsValue();
  const [names, setNames] = useState<string[]>([]);
  const milestoneHide =
    !coercedGet(getAllValues(), 'milestonesValue.milestonesEnabled', false) ||
    hideMilestone;
  const {
    control,
    handleSubmit,
    getValues,
    errors,
    setError,
    formState,
  } = useForm<{ memberLoyaltyGroups: Record<string, any>[] }>({
    defaultValues: {
      memberLoyaltyGroups: [...vipGroupsValue.memberLoyaltyGroups],
    },
    mode: 'onBlur',
    resolver: yupResolver(validationSchema(translate, milestoneHide)),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'memberLoyaltyGroups',
  });

  useEffectOnce(() => {
    if (fields.length === 0) {
      append({
        ...appendDefaultValue,
        milestoneRewardMultiplier: '1',
        taskMultiplier: '1',
        taskRewardMultiplier: '1',
      });
    }
  });

  const validateGroupName = useCallback(() => {
    if (!isEmpty(names.filter(Boolean))) {
      const duplicates = findDuplicates(names, true);
      const indexWithDups: number[] = flatten(
        duplicates.map((dup: number) => getAllIndexes(names, dup))
      );

      if (indexWithDups.length > 0) {
        setTimeout(() => {
          indexWithDups.forEach((idx) =>
            setError(`memberLoyaltyGroups[${idx}].name`, {
              type: 'manual',
              message: translate(
                localMessages['MISSIONS.GROUP_NAME_DUPLICATE_ERROR']
              ),
            })
          );
        });
      }
      return indexWithDups.length > 0;
    }
    return false;
  }, [names, setError, translate]);

  useEffect(() => {
    validateGroupName();
  }, [validateGroupName, formState.submitCount]);

  const onSubmit = () => {
    const hasErrors = validateGroupName();
    if (hasErrors) {
      return;
    }
    setVipGroupsValue(getValues());
    onNextStep();
  };

  const onPrevious = () => {
    onPrevStep();
  };

  const watchedGroups = useWatch({
    control,
    name: 'memberLoyaltyGroups',
  });
  return (
    <>
      <Drawer.Content padding="24px 0">
        <div
          id="missions-vip-group-container"
          className="d-flex justify-content-flex-end"
          style={{ marginLeft: '10%', marginRight: '10%' }}
        >
          <Button
            type="primary"
            ghost
            onClick={() => append(appendDefaultValue)}
          >
            <PlusOutlined />{' '}
            <FormattedMessage id="ADD_GROUP" defaultMessage="Add Group" />
          </Button>
        </div>

        <div className="mt-3">
          {fields.map((item, idx) => (
            <VIPGroupCard
              disableDelete={
                (watchedGroups as Record<string, any>[])?.length === 1
              }
              names={names}
              setNames={setNames}
              milestoneHide={milestoneHide}
              errors={errors}
              control={control}
              item={item}
              key={item.id}
              index={idx}
              remove={remove}
            />
          ))}
        </div>
      </Drawer.Content>
      <DrawerFooter>
        <>
          <Button onClick={() => onPrevious()}>
            <FormattedMessage id="BACK" defaultMessage="Back" />
          </Button>
          <Button onClick={handleSubmit(onSubmit)} type="primary">
            <FormattedMessage id="CONTINUE" defaultMessage="Continue" />
          </Button>
        </>
      </DrawerFooter>
    </>
  );
};

export default VIPGroups;
