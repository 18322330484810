import React from 'react';
import { Tag } from 'antd';
import { Place } from 'types/graphqlTypes';

type Props = {
  country: Place;
  action: string;
  handleClose: (
    id: string,
    action: string,
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
};

const Countrytag = ({ country, handleClose, action }: Props) => (
  <Tag
    className="mt-1"
    onClose={(e) => {
      handleClose(country.id, action, e);
    }}
    closable
  >
    {typeof country === 'string' ? country : country.displayName}
  </Tag>
);

export default Countrytag;
