import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'rebates.create-rebate-group.text': {
    id: 'rebates.create-rebate-group.tex',
    defaultMessage: 'Add new rebate group',
  },
  'rebates.group-name.text': {
    id: 'rebates.group-name.tex',
    defaultMessage: 'Group name',
  },
  'rebates.qualifying-vip.text': {
    id: 'rebates.qualifying-vip.tex',
    defaultMessage: 'Qualifying VIP',
  },
  'rebates.excluded-member-level.text': {
    id: 'rebates.excluded-member-marker.text',
    defaultMessage: 'Excluded Member Marker',
  },
  'rebates.validity.text': {
    id: 'rebates.validity.tex',
    defaultMessage: 'Validity',
  },
  'rebates.please-select.text': {
    id: 'rebates.please-select.tex',
    defaultMessage: 'Please select',
  },
  'rebates.starting-time.text': {
    id: 'rebates.starting-time.text',
    defaultMessage: 'Starting time',
  },
  'rebates.end-time.text': {
    id: 'rebates.end-time.text',
    defaultMessage: 'End time',
  },
});

export default messages;
