import { PAYMENT_TYPES } from 'constants/paymentTypes';
import gql from 'graphql-tag';

export const DELETE_WITHDRAWAL_METHOD = gql`
  mutation DeleteWithdrawalMethod($id: ID!) {
    deleteWithdrawalMethod(id: $id)
  }
`;

export const DUPLICATE_WITHDRAWAL_METHOD = gql`
  mutation duplicate($id: ID!, $type: ResourceType!) {
    duplicate(id: $id, type: $type)
  }
`;

export const TOGGLE_BANK_WITHDRAWAL_METHOD = gql`
  mutation UpdateOfflineBankTransferWithdrawalMethod(
    $id: ID!
    $input: UpdateOfflineBankTransferWithdrawalMethodInput
  ) {
    updateOfflineBankTransferWithdrawalMethod(id: $id, input: $input)
  }
`;

export const TOGGLE_ALIPAY_WITHDRAWAL_METHOD = gql`
  mutation UpdateAlipayWithdrawalMethod(
    $id: ID!
    $input: UpdateAlipayWithdrawalMethodInput
  ) {
    updateAlipayWithdrawalMethod(id: $id, input: $input)
  }
`;

export const TOGGLE_WECHAT_WITHDRAWAL_METHOD = gql`
  mutation UpdateWechatWithdrawalMethod(
    $id: ID!
    $input: UpdateWechatWithdrawalMethodInput
  ) {
    updateWechatWithdrawalMethod(id: $id, input: $input)
  }
`;

export const CREATE_OFFLINE_BANK_WITHDRAWAL_METHOD = gql`
  mutation createOfflineBankTransferWithdrawalMethod(
    $input: CreateOfflineBankTransferWithdrawalMethodInput
  ) {
    createOfflineBankTransferWithdrawalMethod(input: $input)
  }
`;

export const CREATE_ONLINE_BANK_WITHDRAWAL_METHOD = gql`
  mutation createOnlineBankTransferWithdrawalMethod(
    $input: CreateOnlineWithdrawalMethodInput
  ) {
    createOnlineBankTransferWithdrawalMethod(input: $input)
  }
`;

export const CREATE_OFFLINE_ALIPAY_WITHDRAWAL_METHOD = gql`
  mutation createAlipayWithdrawalMethod(
    $input: CreateAlipayWithdrawalMethodInput
  ) {
    createAlipayWithdrawalMethod(input: $input)
  }
`;

export const CREATE_ONLINE_ALIPAY_WITHDRAWAL_METHOD = gql`
  mutation createOnlineAlipayWithdrawalMethod(
    $input: CreateOnlineWithdrawalMethodInput
  ) {
    createOnlineAlipayWithdrawalMethod(input: $input)
  }
`;

export const CREATE_OFFLINE_WECHAT_WITHDRAWAL_METHOD = gql`
  mutation createWechatWithdrawalMethod(
    $input: CreateWechatWithdrawalMethodInput
  ) {
    createWechatWithdrawalMethod(input: $input)
  }
`;

export const CREATE_ONLINE_WECHAT_WITHDRAWAL_METHOD = gql`
  mutation createOnlineWechatWithdrawalMethod(
    $input: CreateOnlineWithdrawalMethodInput
  ) {
    createOnlineWechatWithdrawalMethod(input: $input)
  }
`;

export const UPDATE_OFFLINE_BANK_WITHDRAWAL_METHOD = gql`
  mutation updateOfflineBankTransferWithdrawalMethod(
    $id: ID!
    $input: UpdateOfflineBankTransferWithdrawalMethodInput!
  ) {
    updateOfflineBankTransferWithdrawalMethod(id: $id, input: $input)
  }
`;

export const UPDATE_ONLINE_BANK_WITHDRAWAL_METHOD = gql`
  mutation updateOnlineBankTransferWithdrawalMethod(
    $id: ID!
    $input: UpdateOnlineWithdrawalMethodInput!
  ) {
    updateOnlineBankTransferWithdrawalMethod(id: $id, input: $input)
  }
`;

export const UPDATE_OFFLINE_ALIPAY_WITHDRAWAL_METHOD = gql`
  mutation updateAlipayWithdrawalMethod(
    $id: ID!
    $input: UpdateAlipayWithdrawalMethodInput!
  ) {
    updateAlipayWithdrawalMethod(id: $id, input: $input)
  }
`;

export const UPDATE_ONLINE_ALIPAY_WITHDRAWAL_METHOD = gql`
  mutation updateOnlineAlipayWithdrawalMethod(
    $id: ID!
    $input: UpdateOnlineWithdrawalMethodInput!
  ) {
    updateOnlineAlipayWithdrawalMethod(id: $id, input: $input)
  }
`;

export const UPDATE_OFFLINE_WECHAT_WITHDRAWAL_METHOD = gql`
  mutation updateWechatWithdrawalMethod(
    $id: ID!
    $input: UpdateWechatWithdrawalMethodInput!
  ) {
    updateWechatWithdrawalMethod(id: $id, input: $input)
  }
`;

export const UPDATE_ONLINE_WECHAT_WITHDRAWAL_METHOD = gql`
  mutation updateOnlineWechatWithdrawalMethod(
    $id: ID!
    $input: UpdateOnlineWithdrawalMethodInput!
  ) {
    updateOnlineWechatWithdrawalMethod(id: $id, input: $input)
  }
`;

export const CREATE_HEXOPAY_WITHDRAWAL_METHOD = gql`
  mutation createHexoPayWithdrawalMethod(
    $input: CreateHexoPayWithdrawalMethodInput
  ) {
    createHexoPayWithdrawalMethod(input: $input)
  }
`;

export const UPDATE_HEXOPAY_WITHDRAWAL_METHOD = gql`
  mutation updateHexoPayWithdrawalMethod(
    $id: ID!
    $input: UpdateHexoPayWithdrawalMethodInput
  ) {
    updateHexoPayWithdrawalMethod(id: $id, input: $input)
  }
`;

export const CREATE_ASTROPAYWALLET_WITHDRAWAL_METHOD = gql`
  mutation createAstroPayWalletWithdrawalMethod(
    $input: CreateAstroPayWalletWithdrawalMethodInput
  ) {
    createAstroPayWalletWithdrawalMethod(input: $input)
  }
`;

export const UPDATE_ASTROPAYWALLET_WITHDRAWAL_METHOD = gql`
  mutation updateAstroPayWalletWithdrawalMethod(
    $id: ID!
    $input: UpdateAstroPayWalletWithdrawalMethodInput
  ) {
    updateAstroPayWalletWithdrawalMethod(id: $id, input: $input)
  }
`;

export const getWithdrawalMutation = (
  type: string,
  update: boolean = false
) => {
  switch (type) {
    case PAYMENT_TYPES.OFFLINE.BANK_WITHDRAW:
      return update
        ? UPDATE_OFFLINE_BANK_WITHDRAWAL_METHOD
        : CREATE_OFFLINE_BANK_WITHDRAWAL_METHOD;
    case PAYMENT_TYPES.ONLINE.BANK_WITHDRAW:
      return update
        ? UPDATE_ONLINE_BANK_WITHDRAWAL_METHOD
        : CREATE_ONLINE_BANK_WITHDRAWAL_METHOD;
    case PAYMENT_TYPES.OFFLINE.WECHAT_WITHDRAW:
      return update
        ? UPDATE_OFFLINE_WECHAT_WITHDRAWAL_METHOD
        : CREATE_OFFLINE_WECHAT_WITHDRAWAL_METHOD;
    case PAYMENT_TYPES.ONLINE.WECHAT_WITHDRAW:
      return update
        ? UPDATE_ONLINE_WECHAT_WITHDRAWAL_METHOD
        : CREATE_ONLINE_WECHAT_WITHDRAWAL_METHOD;
    case PAYMENT_TYPES.OFFLINE.ALIPAY_WITHDRAW:
      return update
        ? UPDATE_OFFLINE_ALIPAY_WITHDRAWAL_METHOD
        : CREATE_OFFLINE_ALIPAY_WITHDRAWAL_METHOD;
    case PAYMENT_TYPES.ONLINE.ALIPAY_WITHDRAW:
      return update
        ? UPDATE_ONLINE_ALIPAY_WITHDRAWAL_METHOD
        : CREATE_ONLINE_ALIPAY_WITHDRAWAL_METHOD;
    case PAYMENT_TYPES.OFFLINE.HEXOPAY:
      return update
        ? UPDATE_HEXOPAY_WITHDRAWAL_METHOD
        : CREATE_HEXOPAY_WITHDRAWAL_METHOD;
    case PAYMENT_TYPES.OFFLINE.ASTROPAYWALLET:
      return update
        ? UPDATE_ASTROPAYWALLET_WITHDRAWAL_METHOD
        : CREATE_ASTROPAYWALLET_WITHDRAWAL_METHOD;
    default:
      return CREATE_OFFLINE_BANK_WITHDRAWAL_METHOD;
  }
};

export default {};
