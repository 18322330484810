import { isEmpty, isNil } from 'lodash';

export const isObjectPropsEmpty = (objValue: Record<string, any>) => {
  if (isEmpty(objValue) || isNil(objValue)) {
    return true;
  }

  const values = Object.values(objValue);
  return values.every((value) => {
    if (value === 0) {
      return false;
    }

    return !value;
  });
};
