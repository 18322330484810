import React, { memo, useMemo } from 'react';
import styled from 'styled-components';

type Props = {
  sidebarStatus: boolean;
  children: React.ReactNode;
  width: number;
  noBorder?: boolean;
};

const StyledSider = memo(
  ({ sidebarStatus, width, noBorder, children, ...props }: Props) => {
    const isShown = useMemo(() => sidebarStatus, [sidebarStatus]);

    return (
      <Root>
        <div
          style={{ width: isShown ? width : 0 }}
          className={`container-fluid magic-container ${isShown && 'closed'}`}
          {...props}
        >
          <div className="sidebar-left-nav ">
            <div className="sidebar-shrink">{children}</div>
          </div>
        </div>
      </Root>
    );
  }
);

const duration = 0.5;
const Root: any = styled.div`
  .sidebar-shrink {
    height: calc(100vh - 160px);
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .sidebar-left-nav {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px);
    transition: all ${duration}s ease 0s;
    -webkit-transition: all ${duration}s ease 0s;
  }
  .closed .sidebar-left-nav {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    transition: all ${duration}s easeOut 0s;
    -webkit-transition: all ${duration}s easeOut 0s;
  }
`;

export default StyledSider;
