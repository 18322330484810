import { Tabs } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from './styles';
import { AffiliatesRequest } from './tabs/AffiliatesRequest/AffiliatesRequest';
import { DepositRequest } from './tabs/DepositRequest/DepositRequest';
import { Promotions } from './tabs/Promotions/Promotions';
import { Rebates } from './tabs/Rebates/Rebates';
import { VIP } from './tabs/VIP/VIP';
import { WithdrawalRequest } from './tabs/WithdrawalRequest/WithdrawalRequest';

const { TabPane } = Tabs;

export const AutomatedMessage = () => (
  <Container>
    <Tabs defaultActiveKey="1">
      <TabPane
        tab={
          <FormattedMessage
            id="deposit-request.text"
            defaultMessage="Deposit Request"
          />
        }
        key="1"
        forceRender
      >
        <DepositRequest />
      </TabPane>

      <TabPane
        tab={
          <FormattedMessage
            id="withdrawal-request.text"
            defaultMessage="Withdrawal Request"
          />
        }
        key="2"
        forceRender
      >
        <WithdrawalRequest />
      </TabPane>

      <TabPane
        tab={
          <FormattedMessage id="promotions.text" defaultMessage="Promotions" />
        }
        key="3"
        forceRender
      >
        <Promotions />
      </TabPane>

      <TabPane
        tab={<FormattedMessage id="vip.text" defaultMessage="VIP" />}
        key="4"
        forceRender
      >
        <VIP />
      </TabPane>

      <TabPane
        tab={<FormattedMessage id="rebates.text" defaultMessage="Rebates" />}
        key="5"
        forceRender
      >
        <Rebates />
      </TabPane>

      <TabPane
        tab={
          <FormattedMessage
            id="affiliates-request.text"
            defaultMessage="Affiliates Request"
          />
        }
        key="6"
        forceRender
      >
        <AffiliatesRequest />
      </TabPane>
    </Tabs>
  </Container>
);
