import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import { ALink } from 'components/ALink/ALink';

type Props = {
  tag: string;
  count: number;
};

function TagMembersCount({ tag, count }: Props) {
  const { addTab } = useScreenTabV2();
  const handleClick = () => {
    addTab({
      id: 'member-management',
      state: {
        filter: {
          tags_next: {
            in: [tag],
          },
        },
      },
    });
  };

  return (
    <div>
      <ALink onClick={handleClick}>{count || 0}</ALink>
    </div>
  );
}

export default TagMembersCount;
