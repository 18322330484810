import React from 'react';
import { isTypenameExternal } from 'constants/paymentTypes';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import messages from 'pages/components/MemberWithdrawals/messages';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';

type Props = {
  id: string;
  manualAdjustment: boolean;
};

const PaymentSource = ({ id, manualAdjustment }: Props) => {
  const translate = useTranslate();
  const {
    withdrawalMethodLoaderSet: [useWithdrawalMethodLoader],
  } = useLoaders();
  const { data, loading, error } = useWithdrawalMethodLoader(id);

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;

  const withdrawalMethodId = coercedGet(data, 'withdrawalMethod.id', '');
  const hasNoData = !withdrawalMethodId! && !manualAdjustment;

  if (hasNoData) return '-';

  const typename = coercedGet(data, 'withdrawalMethod.__typename', '');
  const display = isTypenameExternal(typename) ? 'thirdParty' : 'internal';

  return translate(messages[display]);
};

export default PaymentSource;
