import React from 'react';
import removeNull from 'utils/removeNull';
import removeEmptyArrays from 'utils/removeEmptyArrays';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';

import coercedGet from 'utils/coercedGet';
import MembersTable from './components/MembersTable';

type Props = {
  filters: Record<string, any>;
  finalQueryFilter: Record<string, any>;
  page: Record<string, any>;
  onPrev: Function;
  onNext: Function;
  setPage: Function;
  members: Record<string, any>;
  memberLoading: boolean;
  error: any;
};

const CustomMembersTable: any = MembersTable;

export const Members = React.memo((props: Props) => {
  const {
    filters,
    page,
    setPage,
    members,
    memberLoading,
    finalQueryFilter,
  } = props;

  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const membersData = coercedGet(members, 'members', {});
  const { totalCount, edges = [], pageInfo } = membersData;
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  delete filters.quickFilter;

  return (
    ALLOWED_LIST && (
      <CustomMembersTable
        {...props}
        totalCount={totalCount}
        setPage={setPage}
        pageInfo={pageInfo}
        totalPage={totalPage}
        members={edges}
        loading={memberLoading}
        finalQueryFilter={finalQueryFilter}
        refetchParams={{
          first: page.first,
          after: page.after || undefined,
          filter: removeNull(
            removeEmptyArrays({
              ...filters,
              ...(filters.memberLoyaltyLevel && {
                memberLoyaltyLevel: {
                  in: filters.memberLoyaltyLevel.in.map(
                    (item: Record<string, any>) => item.id
                  ),
                },
              }),
            })
          ),
        }}
      />
    )
  );
});
