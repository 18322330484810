import styled, { createGlobalStyle } from 'styled-components';
import { Layout, Menu, Input } from 'antd';
import moreIcon from './assets/more-icon.svg';

const Block = require('react-blocks');

const { Header } = Layout;
const { Item } = Menu;

export const StyleNav = styled.div`
  position: fixed;
  z-index: 2;
  width: 100%;

  .ant-tabs-bar {
    width: 100% !important;
  }

  .logo {
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding: 4px 3.5px;
    justify-content: center;
    a {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }

  .ant-layout-header {
    background-color: var(--nav-bg-color);
    height: auto;
    line-height: 40px;
  }

  .ant-menu {
    border-bottom: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-left: 40px;
    line-height: 40px;
  }

  .ant-menu-sub {
    background-color: var(--nav-bg-color);
  }

  .ant-menu-submenu-title,
  .ant-menu-item {
    color: rgba(255, 255, 255, 0.85) !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 40px;
    padding: 0 20px !important;
    &:hover {
      background-color: #1890ff !important;
    }
  }

  .ant-menu-submenu,
  .ant-menu-item {
    border-bottom: none !important;
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledHeader = styled(Header)`
  height: 40px !important;
  line-height: 40px !important;
  background: #fff !important;
  padding: 0 !important;
  border-bottom: 1px solid #e8e8e8 !important;
`;

// export const StyledSubHeader = styled(StyledHeader)`
//   padding-left: 80px !important;
// `;

export const StyledMenu = styled(Menu)`
  height: 40px;
  line-height: 40px !important;
  border-right: 1px solid #e8e8e8 !important;
`;

export const StyledMenuItem = styled(Item)`
  height: 40px;
  line-height: 40px;
  padding: 0 10px !important;
  top: 0px !important;
  cursor: ${(props: any) =>
    props.showIcon ? 'default !important' : 'pointer'};
`;

export const StyledLogo = styled.div`
  width: 80px;
  text-align: center;
  font-weight: bold;
  img {
    width: 32px;
  }
`;

export const StyledSearch = styled(Input)`
  input {
    border: none !important;
  }
  margin-left: 10px !important;
  width: auto !important;
`;

export const NavbarWrapper = styled(Block)`
  flex: auto !important;
  overflow: hidden;
  overflow-x: auto;
`;

export const StyledButton = styled.a`
  border: 0;
  padding: 0;
  border: 0;
  line-height: 25px;
  cursor: pointer;
  outline: none;
  background: transparent;
  display: inline-block !important;
`;

export const GlobalStyle = createGlobalStyle`



  .ant-menu-item, .ant-menu-submenu-title {
    margin: 0 !important;
    border-bottom: none !important;
  }
  .ant-menu-submenu-popup {
    .ant-menu-item {
      &:not(:first-child) {
        margin-top: 4px !important;
      }
    }
    &:before {
      left: 8px !important;
    }
  }
  .ant-menu-vertical.ant-menu-sub, .ant-menu-vertical-left.ant-menu-sub, .ant-menu-vertical-right.ant-menu-sub {
    min-width: 180px;
  }
  &.ant-menu-submenu, .ant-menu-sub {
    border-radius: 0;
  }
  .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    top: 0;
  }
  .ant-menu-sub {
    position: relative;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    background-color: var(--nav-bg-color) !important;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16) !important;
    border-radius: 4px;
  }
  .ant-menu-submenu-title,
  .ant-menu-item {
    color: rgba(255, 255, 255, 0.65) !important;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 40px;
    padding: 0 10px;
    &:hover {
      background-color: #1890ff;
      color: rgba(255, 255, 255, 1) !important;
      .ant-menu-submenu-arrow {
        &:before {
          opacity: 1;
        }
      }
    }
    &.ant-menu-item-selected {
      background-color: transparent !important;
    }
  }
  .ant-menu-submenu-arrow {
    margin-top: -8px !important;
    height: 16px !important;
    width: 16px !important;
    right: 8px !important;
    &:before, &:after {
      background: none !important;
    }
    &:before {
      background: url(${moreIcon}) !important;
      opacity: 0.6;
      width: 16px !important;
      height: 16px !important;
      transform: none !important;
    }
  }
`;

export default {};
