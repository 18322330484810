import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { CustomRegex } from 'utils/regex';
import messages from 'messages';
import gql from 'graphql-tag';
import { Modal } from 'antd';

export const TextLabel = ({ children }: { children: any }) => (
  <div className="fs-12 text-black--85" style={{ height: '25px' }}>
    {children}
  </div>
);

export const LabelContainerDiv = styled.div`
  border: 1px solid #d9d9d9;
  padding: 8px 11px;
  border-radius: 4px;
`;

export type FormDefaultValues = {
  passwordChange: boolean;
  withdrawalPinChange: boolean;
  status: boolean;
  memberMarker: string;
  password: string;
  memberLoyaltyLevels: [];
  tags: [];
  fileId: string;
};

export const validationSchema = (translate: any) =>
  yup.object().shape({
    password: yup
      .string()
      .nullable()
      .required(translate(messages.REQUIRED))
      .matches(
        CustomRegex.password(),
        translate(messages.PASSWORD_VALIDATION_FIELD)
      ),
    fileId: yup
      .string()
      .nullable()
      .required(translate(messages.REQUIRED)),
  });

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const StyledLabel = styled.div`
  transform: translateY(-7px);

  .ant-legacy-form-item {
    display: block;
    margin-bottom: 0 !important;

    label {
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
    }
  }

  .ant-legacy-form-item-label {
    height: 28px;
    z-index: 9999999 !important;
  }

  .ant-select-selection--single {
    border-radius: 2px;
  }
`;

export const MEMBERS_DELETE = gql`
  query {
    members(filter: { username: { startsWith: "username" } }) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation DeleteMember($id: ID!) {
    deleteMember(id: $id)
  }
`;
