import React, { ReactElement } from 'react';
import styled from 'styled-components';

function Content({
  children,
  ...restProps
}: {
  children: ReactElement;
  restProps: any;
}) {
  return (
    <Root {...restProps}>
      <div className="drawer-content">{children}</div>
    </Root>
  );
}

const Root: any = styled.div`
  padding: ${(props: { padding: string }) => props.padding};
  position: relative;
  overflow: visible;
  overflow-y: auto;
  height: calc(100% - ${(props: any) => props.heightExcess}px);
  .ant-legacy-form-item {
    margin-bottom: 8px;
  }
  .drawer-content {
    height: ${(props: any) => (props.fullScreen ? '100%' : 'initial')};
  }
`;

export default Content;
