import * as yup from 'yup';
import messages from 'messages';
// import { MessageFormat } from 'types/react-intl';

export const validationSchema = (translate: any) =>
  yup.object().shape({
    logo: yup.object().shape({
      id: yup.string().required(translate(messages.REQUIRED)),
    }),
    displayName: yup.object().shape({
      EN: yup.string().required(translate(messages.REQUIRED)),
      ZH: yup.string().required(translate(messages.REQUIRED)),
    }),
  });
