import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import SelectFilterField from 'components/SelectFilterField';
import { LOYALTY_PROGRAMME_NAMES } from 'graphql/queries/vip.query';
import CheckboxFilterField from 'components/CheckboxFilterField';
import { MemberLoyaltyProgrammeStatus } from 'types/graphqlTypes';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
};

export default ({ filters, onRawFilterChange: handleChange }: Props) => (
  <>
    <SelectFilterField
      testId="vip-name-filter"
      partialSupported={false}
      label="VIP Programme Name"
      query={LOYALTY_PROGRAMME_NAMES}
      queryConnection="memberLoyaltyProgrammes"
      filterFieldName="name"
      filters={filters}
      onChange={(e) =>
        handleChange({
          name: 'name',
          value: e?.length ? { in: e } : null,
        })
      }
    />

    <CheckboxFilterField
      filters={filters}
      filterFieldName="status"
      filterEnum={MemberLoyaltyProgrammeStatus}
      label="Status"
      onChange={(e) =>
        handleChange({
          name: 'status',
          value: e.length ? { in: e } : null,
        })
      }
    />
  </>
);
