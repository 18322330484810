import gql from 'graphql-tag';

export const VENDOR = gql`
  query VendorTag($id: ID, $language: Language) {
    vendor(id: $id) {
      id
      name(language: $language)
      shortcode
    }
  }
`;

export default {};
