import React from 'react';
import { Spin } from 'antd';
import { IpAddressLocation } from 'types/graphqlTypes';
import esGet from 'utils/esGet';
import { StyledCenter } from 'styles';
import { useIsNextV2 } from 'hooks/useIsNext';
import TruncateToolTip from 'components/TruncateToolTip';
import useGoogleTranslate from 'hooks/useGoogleTranslate';

type Props = {
  location: IpAddressLocation;
};

function IpAddressLocationTableCell(props: Props) {
  const isNext = useIsNextV2();
  const { location } = props;
  const { city, country, state } = esGet(location, {});
  const locationList = [city ?? '', state ?? '', country ?? ''];
  const { data, loading } = useGoogleTranslate(locationList);
  const locationResult = (Array.isArray(data)
    ? (data as Array<string>)
    : []) ?? [data];
  const locationData = isNext('locationTranslation')
    ? (data as Array<string>)
    : locationResult;
  const locationStr = locationData.filter(Boolean).join(', ');
  if (loading) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }
  return locationStr ? (
    <TruncateToolTip maxRows={2} content={locationStr} />
  ) : (
    <span>-</span>
  );
}

export default IpAddressLocationTableCell;
