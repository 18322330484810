import React from 'react';
import styled from 'styled-components';
// import { useClearCacheCtx } from 'react-clear-cache';

const Content = ({
  children,
  sideBar,
  footer,
  contentStyle,
  height,
}: TableSidebarContainerType) => (
  // const { isLatestVersion } = useClearCacheCtx();

  <StyledContainer height={height} hasUpdate={false} className="d-flex">
    {sideBar}
    <div
      style={{
        background: '#fff',
        overflow: 'auto',
      }}
      className="d-flex flex-column justify-content-between w-100"
    >
      <div
        style={{
          height: 'calc(100vh - 205px)',
          overflow: 'auto',
          ...contentStyle,
        }}
      >
        {children}
      </div>
      {footer}
    </div>
  </StyledContainer>
);

const StyledContainer = styled.div<{ hasUpdate: boolean; height?: string }>`
  height: ${(props) => {
    if (props.height) {
      return props.height;
    }

    return props.hasUpdate
      ? 'calc(100vh - 80px - 77px - 37px)'
      : 'calc(100vh - 80px - 77px)';
  }};
`;

type TableSidebarContainerType = {
  children: React.ReactNode;
  sideBar: React.ReactNode;
  footer: React.ReactNode;
  contentStyle?: any;
  height?: string;
};

export default Content;
