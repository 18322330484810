import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';
import { findColorByBgColor } from 'pages/components/SystemMessage/ManualMessage/components/CreateNewMail/styles';
import { MEMBER_LEVEL } from './queries';

const StyledTag = styled(Tag)`
  margin: 4px;
  background-color: ${({ color }) =>
    get(findColorByBgColor(color), 'bgColor', '#efefef')} !important;
  border: 1px solid
    ${({ color }) => get(findColorByBgColor(color), 'borderColor', '#888888')} !important;
  border-radius: 11px !important;
  color: #000 !important;

  .anticon-close {
    color: #000 !important;
  }
`;

const MemberLevelTag = (props: { id: string; onClose: (e: any) => void }) => {
  const { id, onClose } = props;
  const { data = {}, loading } = useQuery(MEMBER_LEVEL, {
    variables: {
      id,
    },
  });

  if (loading) {
    return (
      <Tag style={{ margin: '4px' }}>
        <LoadingOutlined />
      </Tag>
    );
  }

  const { memberLevel = {} }: Record<string, any> = data;

  return (
    !loading && (
      <StyledTag color={memberLevel.color} closable onClose={(e) => onClose(e)}>
        {memberLevel.name}
      </StyledTag>
    )
  );
};

export default MemberLevelTag;
