import React from 'react';
import { Tag } from 'antd';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { DynamicObj } from 'interfaces/user.interface';

export const VendorGroupNameTag = ({ nameValue }: { nameValue: string }) => {
  const { filters, setFilters } = useFilterValues();

  const removeFilter = (idToBeRemoved: string, e: any) => {
    e.preventDefault();
    const newFilter = filters?.name?.in?.filter(
      (name: string) => name !== idToBeRemoved
    );

    if (newFilter.length === 0) {
      setFilters((prev: DynamicObj) => {
        const { name, ...prevFilters } = prev;
        return {
          ...prevFilters,
        };
      });
    } else {
      setFilters((prev: DynamicObj) => ({
        ...prev,
        name: { in: newFilter },
      }));
    }
  };

  return (
    <Tag closable onClose={(e: any) => removeFilter(nameValue, e)}>
      {nameValue}
    </Tag>
  );
};
