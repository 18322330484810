import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import coercedGet from 'utils/coercedGet';
import {
  GET_WITHDRAWAL_ACCOUNTS,
  GET_WITHDRAWAL_BRAND_IDS,
  GET_WITHDRAWAL_PLATFORM_IDS,
  GET_WITHDRAWAL_SERIAL_CODES,
} from 'graphql/queries/withdrawal.query';
import SelectFilterField from 'components/SelectFilterField';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import { FilterItem } from 'components/FilterItem/FilterItem';
import useTranslate from 'utils/useTranslate';
import MemberLoyaltyLevelsSelect from 'components/MemberLoyaltyLevelsSelect';
import globalMessages from 'messages';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import WithdrawalDeductionsFilter from 'components/WithdrawalDeductionsFilter';
import WithdrawalComplianceCheckFilter from 'components/WithdrawalComplianceCheckFilter';
import AmountFilter from 'components/AmountFilter';
import PaymentStatusFilter from 'components/PaymentStatusFilter';
import PaymentSourceFilter from 'components/PaymentSourceFilter';
import { isEmpty } from 'lodash';
import ProcessingTimeFilter from 'components/ProcessingTimeFilter';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import WithdrawalMethodFilter from 'components/WithdrawalMethodFilter';
import { isVipVisible } from 'utils/isVipVisible';
import { GET_AVAILABLE_CURRENCIES } from 'hooks/useAvailableCurrencies';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  isCustomFilter?: boolean;
};
export default ({
  filters,
  onRawFilterChange: handleChange,
  isCustomFilter = false,
}: Props) => {
  const translate = useTranslate();

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'LIST'],
    [
      ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT,
      ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_ACCOUNT_MANAGEMENT,
    ]
  );

  const { ALLOWED_LIST: ALLOWED_MARKER_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );

  const {
    handlingFeeApplied,
    withdrawalTaxFeeApplied,
    promoDeductionFeeApplied,
    withdrawalLimitFeeApplied,
    oneTimeTurnoverDetectionCheck,
    promoHunterCheck,
    contraBetCheck,
    ipCollisionOffenderCheck,
    martingaleBetOffenderCheck,
  } = filters;

  return (
    <>
      <SelectFilterField
        testId="withdrawals-serialCode-filter"
        partialSupported={false}
        label="Serial Code"
        query={GET_WITHDRAWAL_SERIAL_CODES}
        queryConnection="withdrawals"
        filterFieldName="serialCode"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'serialCode',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <SelectFilterField
        testId="withdrawals-currency-filter"
        label="Currency"
        isAvailableCurrencyQuery
        allowFilterOption
        partialSupported={false}
        query={GET_AVAILABLE_CURRENCIES}
        queryConnection=""
        filterFieldName="currency"
        queryOperation="in"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'currency',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <IdBasedSelectFilterField
        testId="withdrawals-username-filter"
        label="Account"
        placeholder="Username"
        query={GET_WITHDRAWAL_ACCOUNTS}
        queryConnection="members"
        filterFieldName="username"
        filters={filters}
        rawFilterValues={coercedGet(filters, 'account.in', [])}
        isCustomFilter={isCustomFilter}
        onChange={(e) =>
          handleChange({
            name: 'account',
            value: e?.length ? { in: e } : null,
          })
        }
        partialSupported={false}
      />
      {ALLOWED_LIST && isVipVisible && (
        <FilterItem
          label={translate(globalMessages.VIP_LEVEL)}
          onClear={() =>
            handleChange({
              name: 'memberLoyaltyLevel',
              value: null,
            })
          }
        >
          <MemberLoyaltyLevelsSelect
            value={coercedGet(filters, 'memberLoyaltyLevel.in', [])}
            onChange={(val) =>
              handleChange({
                name: 'memberLoyaltyLevel',
                value: val.length ? { in: val } : null,
              })
            }
            placeHolder={translate(globalMessages['enter-vip-level.text'])}
            multiple
          />
        </FilterItem>
      )}

      {ALLOWED_MARKER_LIST && (
        <IdBasedSelectFilterField
          testId="withdrawals-member-marker-filter"
          label="Member Marker"
          query={GET_MEMBER_LEVELS}
          queryConnection="memberLevels"
          filterFieldName="name"
          filters={filters}
          isCustomFilter={isCustomFilter}
          rawFilterValues={coercedGet(filters, 'memberLevel.in', [])}
          partialSupported={false}
          onChange={(e) =>
            handleChange({
              name: 'memberLevel',
              value: e?.length ? { in: e } : null,
            })
          }
        />
      )}

      <WithdrawalMethodFilter
        filters={filters}
        onChange={(e) => {
          handleChange({
            name: 'withdrawalMethod',
            value: e?.length ? { in: e } : null,
          });
        }}
      />

      <FilterItem label={translate(globalMessages.FEE_DEDUCTION)}>
        <WithdrawalDeductionsFilter
          value={{
            handlingFeeApplied,
            withdrawalTaxFeeApplied,
            promoDeductionFeeApplied,
            withdrawalLimitFeeApplied,
          }}
          onChange={(e: Record<string, any>) =>
            handleChange({
              name: e.target.name,
              value: e.target.checked ? { gt: 0 } : null,
            })
          }
        />
      </FilterItem>

      <FilterItem label={translate(globalMessages.COMPLIANCE_CHECK)}>
        <WithdrawalComplianceCheckFilter
          compliances={{
            oneTimeTurnoverDetectionCheck,
            promoHunterCheck,
            contraBetCheck,
            ipCollisionOffenderCheck,
            martingaleBetOffenderCheck,
          }}
          onChange={(e) =>
            handleChange({
              name: e.target.name,
              value: e.target.checked ? { eq: 'FAILED' } : null,
            })
          }
        />
      </FilterItem>

      <FilterItem
        label={translate(globalMessages.PAYOUT_AMOUNT)}
        onClear={() =>
          handleChange({
            name: 'amount',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: coercedGet(filters, 'amount.gte', ''),
            max: coercedGet(filters, 'amount.lte', ''),
          }}
          onChange={(e) => {
            const amount = { ...filters.amount };
            if (!e.value) delete amount[e.range];
            else amount[e.range] = e.value;

            handleChange({
              name: 'amount',
              value: isEmpty(amount) ? null : amount,
            });
          }}
        />
      </FilterItem>

      <FilterItem
        label={translate(globalMessages.PAYMENT_SOURCE)}
        onClear={() =>
          handleChange({
            name: 'withdrawalMethodType',
            value: null,
          })
        }
      >
        <PaymentSourceFilter
          value={coercedGet(filters, 'withdrawalMethodType.in', [])}
          onChange={(e) =>
            handleChange({
              name: 'withdrawalMethodType',
              value: e.length ? { in: e } : null,
            }) as any
          }
        />
      </FilterItem>

      <FilterItem
        label={translate(globalMessages.STATUS)}
        onClear={() =>
          handleChange({
            name: 'status',
            value: null,
          })
        }
      >
        <PaymentStatusFilter
          excludedStatus={['EXPIRED']}
          value={coercedGet(filters, 'status.in', [])}
          onChange={(e) =>
            handleChange({
              name: 'status',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>

      <FilterItem
        label="Processing Time"
        onClear={() =>
          handleChange({
            name: 'processingTime',
            value: null,
          })
        }
      >
        <ProcessingTimeFilter
          value={{
            min: coercedGet(filters, 'processingTime.gte', ''),
            max: coercedGet(filters, 'processingTime.lte', ''),
          }}
          onChange={(e) => {
            const processingTime = { ...filters.processingTime };
            if (!e.value) delete processingTime[e.range];
            else processingTime[e.range] = e.value;

            handleChange({
              name: 'processingTime',
              value: isEmpty(processingTime) ? null : processingTime,
            });
          }}
        />
      </FilterItem>

      <DateTimeRangePicker
        testId="withdrawals-dateTime-created-filter"
        label="request-date"
        value={
          filters.dateTimeCreated
            ? [filters.dateTimeCreated.gte, filters.dateTimeCreated.lte]
            : null
        }
        onChange={(e) =>
          handleChange({
            name: 'dateTimeCreated',
            value: e?.length ? { gte: e[0], lte: e[1] } : null,
          })
        }
      />

      <SelectFilterField
        testId="withdrawals-brandId-filter"
        partialSupported={false}
        label="Brand ID"
        query={GET_WITHDRAWAL_BRAND_IDS}
        queryConnection="withdrawals"
        fieldType="account"
        filterFieldName="brandId"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'brandId',
            value: e?.length ? { in: e } : null,
          })
        }
      />

      <SelectFilterField
        testId="withdrawals-platformId-filter"
        partialSupported={false}
        label="Platform ID"
        query={GET_WITHDRAWAL_PLATFORM_IDS}
        queryConnection="withdrawals"
        fieldType="account"
        filterFieldName="platformId"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'platformId',
            value: e?.length ? { in: e } : null,
          })
        }
      />
    </>
  );
};
