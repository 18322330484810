import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Button, message, Modal, Radio, Spin, Switch, Tooltip } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { CONFIG as AFFILIATES_CONFIG } from 'pages/components/Agent/components/Affiliate/AffiliateCriteriaNew/utils';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useScreenTabV2 } from 'store/screenTabState';
import styled from 'styled-components';
import {
  CONFIG,
  Form,
  getColumns,
  getRadio,
  getRows,
  localMessages,
  PS_KEYS,
  UPDATE_CONFIG,
} from 'utils/profileSetting';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import arrow from './assets/arrow.svg';

const MUST_VERIFY = ['EMAIL', 'MOBILE_NUMBER'];

interface Changes {
  field: string;
  from: boolean | undefined;
  to: boolean | undefined;
}

const ProfileSetting: React.FC = () => {
  const translate = useTranslate();
  const { updateTab, useUpdateTab } = useScreenTabV2('profile-setting');
  const [forms, setForms] = useState<Form[]>([]);
  const [original, setOriginal] = useState<Form[]>([]);
  const [changes, setChanges] = useState<Changes[]>([]);
  const [confirm, setConfirm] = useState(false);
  const [affiliates, setAffiliates] = useState<Form[]>([]);
  const [cannotVerify, setCannotVerify] = useState(true);
  const [masterEnabled, setMasterEnabled] = useState({
    email: false,
    mobile: false,
  });
  const { context } = useOperatorHeader();
  const [updateConfig, { loading: mutating }] = useMutation(UPDATE_CONFIG, {
    context,
  });
  const disableVerification = (
    thisForms: Form[],
    disableAll: boolean = false
  ) => {
    const email = thisForms.find((form) => form.field === PS_KEYS.EMAIL);
    const mobileNumber = thisForms.find(
      (form) => form.field === PS_KEYS.MOBILE_NUMBER
    );

    if (email && mobileNumber) {
      const isDisabled =
        email.required === undefined && mobileNumber.required === undefined;

      setCannotVerify(isDisabled);

      if (disableAll && isDisabled) {
        const newForms: Form[] = [...thisForms].map((form: Form) => ({
          ...form,
          otpVerificationRequired: false,
        }));

        setForms(newForms);
      } else {
        setForms(thisForms);
      }
    }
  };

  const [loadAffiliates] = useLazyQuery(AFFILIATES_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (data: { config: { affiliateRequestFormFields: Form[] } }) => {
      setAffiliates(data.config.affiliateRequestFormFields);
    },
  });

  const [loadForms, { called, loading }] = useLazyQuery(CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (data: {
      config: {
        memberFormFields: Form[];
        emailVerificationEnabled: boolean;
        mobileNumberVerificationEnabled: boolean;
      };
    }) => {
      const memberForms = data.config.memberFormFields;
      const {
        emailVerificationEnabled,
        mobileNumberVerificationEnabled,
      } = data.config;
      setMasterEnabled({
        email: emailVerificationEnabled,
        mobile: mobileNumberVerificationEnabled,
      });
      const formatForms: Form[] = [];
      const originalForms: Form[] = [];
      getRows(translate).forEach(({ key, translation }) => {
        const form: Form | undefined = memberForms.find(
          (item: Form) => item.field === key
        );

        let newForm: Form | any = {
          translation,
          type: 'BUILT_IN',
        };

        if (form) {
          newForm = { ...form, ...newForm };
        } else {
          newForm = {
            field: key,
            required: undefined,
            updatable: false,
            otpVerificationRequired: false,
            ...newForm,
          };
        }

        formatForms.push(newForm);
        originalForms.push({ ...newForm });
      });

      disableVerification(formatForms);
      setOriginal(originalForms);
      loadAffiliates();
    },
  });

  useUpdateTab(() => loadForms());

  if (!called) loadForms();

  const handleRadio = (e: RadioChangeEvent, index: number, field: string) => {
    const newForms: Form[] = [...forms];
    newForms[index].required = e.target.value;

    if (e.target.value === undefined) {
      newForms[index].updatable = false;
      newForms[index].otpVerificationRequired = false;
    }

    if (MUST_VERIFY.includes(field)) {
      disableVerification(newForms, true);
    } else {
      setForms(newForms);
    }
  };

  const handleSwitch = (
    e: boolean,
    index: number,
    field: string,
    name: string
  ) => {
    const newForms: Form[] = [...forms];
    newForms[index][name] = e;

    if (e && MUST_VERIFY.includes(field)) {
      const mustForm = newForms.find((form) => form.field === field);
      if (mustForm) mustForm.otpVerificationRequired = true;
    }

    setForms(newForms);
  };
  const handleConfirm = () => {
    const newChanges: Changes[] = [];

    forms.forEach((form: Form) => {
      const match = original.find((item: Form) => item.field === form.field)!;
      const isChanged =
        form.required !== match.required && form.required !== undefined;

      if (isChanged) {
        const affiliate = affiliates.find(
          (item: Form) => item.field === form.field
        )!;

        if (form.required !== affiliate?.required) {
          const change = { field: match.field } as Changes;

          if (change.field === PS_KEYS.QQ || change.field === PS_KEYS.WECHAT) {
            change.field = `PS_KEYS_${change.field}`.replace('_ID', '');
          }

          switch (form.required) {
            case true:
              change.from = affiliate?.required;
              change.to = form.required;
              break;
            case false:
              if (affiliate?.required === undefined) {
                change.from = undefined;
                change.to = false;
              }
              break;
            default:
          }

          if (Object.keys(change).length > 1) newChanges.push(change);
        }
      }
    });

    setChanges(newChanges);
  };

  const handleSave = async () => {
    const formattedForms = forms.map((item) => ({ ...item }));
    const values: any = [];

    Object.values(PS_KEYS).forEach((field: string) => {
      const form: Form | undefined = formattedForms.find(
        (item: Form) => item.field === field
      );

      if (form?.required !== undefined) {
        delete form.translation;
        delete form.__typename;

        values.push(form);
      }
    });

    try {
      await updateConfig({
        variables: {
          input: {
            memberFormFields: values,
          },
        },
      });

      message.success(translate(messages['successfully-saved-changes.text']));
      /* Note: the value of updates key is empty.
       *  This is just to trigger the useUpdateTab hook in Affiliates Criteria.
       *  This is to reflect the changes live if that tab is existing. */
      updateTab({ id: 'affiliate-criteria', updates: {} });
      setConfirm(false);
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  return (
    <Container>
      <div className="d-flex justify-content-flex-end p-3">
        <Button
          type="primary"
          onClick={() => {
            setConfirm(true);
            handleConfirm();
          }}
        >
          <FormattedMessage id="save.text" defaultMessage="Save" />
        </Button>
      </div>

      <div className="row">
        {getColumns(translate).map((column, key) => (
          <div className="col" key={key}>
            {column.translation}{' '}
            {column?.tooltip && (
              <Tooltip
                className="ml-1"
                placement="bottom"
                title={
                  !column?.tooltip2 ? (
                    column.tooltip
                  ) : (
                    <>
                      <div>
                        <span>{column.tooltip}</span>
                      </div>
                      <br />
                      <span>{column.tooltip2}</span>
                    </>
                  )
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </div>
        ))}
      </div>

      {!loading ? (
        forms.map((form: Form, index: number) => (
          <Radio.Group
            key={form.translation}
            className="row"
            onChange={(e) => handleRadio(e, index, form.field)}
            value={form.required}
          >
            <div className="col">
              {translate(
                localMessages[
                  form.translation!.replace(/ /g, '_').toUpperCase()
                ]
              )}
            </div>
            <Radio className="col radio" value />
            <Radio className="col radio" value={false} />
            <Radio className="col radio" value={undefined} />
            <div className="col">
              <Switch
                checked={form.updatable}
                onChange={(e) =>
                  handleSwitch(e, index, form.field, 'updatable')
                }
                disabled={form.required === undefined}
              />{' '}
              <span>
                {translate(localMessages[form.updatable ? 'YES' : 'NO'])}
              </span>
            </div>

            <div className="col">
              <Tooltip
                placement="topRight"
                title={
                  cannotVerify
                    ? translate(
                        localMessages.CANNOT_EDIT_IF_PHONE_AND_EMAIL_HIDDEN
                      )
                    : ''
                }
              >
                <Switch
                  checked={form.otpVerificationRequired}
                  onChange={(e) =>
                    handleSwitch(
                      e,
                      index,
                      form.field,
                      'otpVerificationRequired'
                    )
                  }
                  disabled={
                    form.required === undefined ||
                    !form.updatable ||
                    cannotVerify ||
                    MUST_VERIFY.includes(form.field) ||
                    (!masterEnabled.mobile && !masterEnabled.email)
                  }
                />
              </Tooltip>{' '}
              <span>
                {translate(
                  localMessages[form.otpVerificationRequired ? 'YES' : 'NO']
                )}
              </span>
            </div>
          </Radio.Group>
        ))
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Spin />
        </div>
      )}

      <Modal
        visible={confirm}
        onOk={handleSave}
        onCancel={() => {
          setConfirm(false);
        }}
        bodyStyle={{
          padding: '32px 32px 24px 32px',
        }}
        footer={null}
        closable={false}
      >
        <div className="modal-content d-flex flex-row">
          <div style={{ width: 38 }}>
            <InfoCircleOutlined
              style={{ color: 'rgb(251,193,78)', fontSize: '22px' }}
            />
          </div>

          <div style={{ position: 'relative', width: '100%' }}>
            <div
              className="modal-title"
              style={{
                fontSize: 16,
                color: 'rgba(0,0,0,0.85',
              }}
            >
              {!!changes.length && (
                <div>{translate(localMessages.MEMBER_PROFILE_CONFIRM)}</div>
              )}
              <div>{translate(localMessages.DO_YOU_WISH_TO_PROCEED)}</div>
            </div>

            <div
              className="modal-section my-2"
              style={{ pointerEvents: 'none' }}
            >
              {changes.map((change: Changes) => (
                <div className="d-flex my-2" key={change.field}>
                  <div style={{ width: 110 }}>
                    {translate(localMessages[change.field])}
                  </div>

                  <div className="right-side d-flex">
                    <div className="from d-flex" style={{ width: 100 }}>
                      <Radio />
                      {translate(getRadio(change.from))}
                    </div>

                    <div className="arrow">
                      <img src={arrow} alt="arrow" />
                    </div>

                    <div className="to">
                      <Radio className="ml-2 mr-1" checked />
                      {translate(getRadio(change.to))}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="buttons d-flex justify-content-end">
              <Button disabled={mutating} onClick={() => setConfirm(false)}>
                {translate(messages['cancel.text'])}
              </Button>

              <Button
                type="primary"
                disabled={mutating}
                onClick={handleSave}
                style={{ marginLeft: 8 }}
              >
                {translate(messages['save.text'])}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  height: calc(100vh - 80px);
  background: white;
  overflow: auto;

  .row {
    display: flex;
    width: 1200px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    margin: auto;
    margin-bottom: 30px;

    :first-child {
      font-weight: 600;
      padding-top: 80px;
    }

    .radio {
      margin: 0;
    }

    .check {
      width: 16px;
      margin: auto;
    }

    .ant-switch-checked {
      background-color: #52c41a;
    }
  }

  .col {
    width: 240px;

    :first-child {
      text-align: right;
    }
  }

  .save {
    position: absolute;
    width: 88px;
    right: 16px;
    top: 11px;
  }

  span {
    margin-left: 8px;
  }
`;

export default ProfileSetting;
