import React from 'react';

export const NewMemberDetailContext = React.createContext<
  Partial<ContextProps>
>({});

export const NewMemberDetailProvider = ({
  children,
  refreshPage,
}: {
  children: any;
  refreshPage: () => void;
}) => (
  <NewMemberDetailContext.Provider
    value={{
      refreshPage,
    }}
  >
    {children}
  </NewMemberDetailContext.Provider>
);

type ContextProps = {
  refreshPage: () => void;
};

export const useNewMemberDetailContext = () =>
  React.useContext(NewMemberDetailContext);
