import React from 'react';
import { Radio, Tooltip, Spin } from 'antd';
import { PAYMENT_TYPES, PAYMENT_SOURCES } from 'constants/paymentTypes';
import useTranslate from 'utils/useTranslate';
import { thirdPartyTypeNames } from 'constants/thirdPartyProviders';
import isRow from 'utils/isRow';
import messages from '../../../../messages';

interface InternalProvidersTypes {
  handleChange: any;
  value: string;
  name: string;
  paymentType: string;
  paymentChannels: string[];
  providerType: string;
  validatingChannels: boolean;
  disabled: boolean;
}

type blockChainType = {
  label: string;
  value: string;
  disabled: boolean;
};

type providerTypes = {
  label: string;
  value: string;
};

const InternalProviders = ({
  value,
  handleChange,
  name,
  paymentType,
  paymentChannels,
  providerType,
  validatingChannels,
  disabled,
}: InternalProvidersTypes) => {
  const translate = useTranslate();

  const providers = {
    OFFLINE: [
      {
        label: translate(messages.banktransfer),
        value: PAYMENT_TYPES.OFFLINE.BANK,
      },
      {
        label: translate(messages.wechat),
        value: PAYMENT_TYPES.OFFLINE.WECHAT,
      },
      {
        label: translate(messages.alipay),
        value: PAYMENT_TYPES.OFFLINE.ALIPAY,
      },
      {
        label: translate(messages.hexopay),
        value: PAYMENT_TYPES.OFFLINE.HEXOPAY,
      },
      {
        label: translate(messages.neteller),
        value: PAYMENT_TYPES.OFFLINE.NETELLER,
      },
      {
        label: translate(messages.skrill),
        value: PAYMENT_TYPES.OFFLINE.SKRILL,
      },
      {
        label: 'Astropay Wallet',
        value: PAYMENT_TYPES.OFFLINE.ASTROPAYWALLET,
      },
      {
        label: 'PayByAstroPay',
        value: PAYMENT_TYPES.OFFLINE.PAYBYASTROPAY,
      },
    ],
    ONLINE: [
      {
        label: translate(messages.banktransfer),
        value: PAYMENT_TYPES.ONLINE.BANK,
        testCondition: /bank/i,
      },
      {
        label: translate(messages.wechat),
        value: PAYMENT_TYPES.ONLINE.WECHAT,
        testCondition: /wechat/i,
      },
      {
        label: translate(messages.alipay),
        value: PAYMENT_TYPES.ONLINE.ALIPAY,
        testCondition: /alipay/i,
      },
      {
        label: translate(messages.unionpay),
        value: PAYMENT_TYPES.ONLINE.UNION_PAY,
        testCondition: /unionpay/i,
      },
    ],
  };

  const blockChainProvider = {
    OFFLINE: [
      {
        label: translate(
          isRow ? messages.CRYPTOCURRENCY_text : messages.USDT_text
        ),
        value: PAYMENT_TYPES.OFFLINE.CRYPTOCURRENCY,
      },
      // {
      //   label: translate(messages.Bitcoin_text),
      //   value: PAYMENT_TYPES.OFFLINE.BITCOIN,
      //   disabled: true,
      // },
    ],
    ONLINE: [
      {
        label: isRow ? 'Cryptocurrency' : 'USDT',
        value: PAYMENT_TYPES.OFFLINE.CRYPTOCURRENCY,
      },
      // {
      //   label: 'Bitcoin',
      //   value: PAYMENT_TYPES.OFFLINE.BITCOIN,
      //   disabled: true,
      // },
    ],
  };

  const blockChainWithTypes = blockChainProvider[paymentType];
  let providerTypes = providers[paymentType];

  if (
    paymentType === PAYMENT_SOURCES.ONLINE &&
    providerType === thirdPartyTypeNames.midPayDirect &&
    paymentChannels.length
  ) {
    /* *
     * Displays only the Payment Methods(providerTypes) available within the
     * selected Payment Provider(depositProvider) paymentChannels.
     * */
    const testProviderType = ({
      testCondition,
    }: {
      label: string;
      value: string;
      testCondition: RegExp;
    }) =>
      paymentChannels.some((paymentChannel) =>
        testCondition.test(paymentChannel)
      );

    providerTypes = providerTypes.filter(testProviderType);
  }

  if (
    providerType === thirdPartyTypeNames.midPayDirect &&
    !validatingChannels &&
    !paymentChannels.length
  ) {
    return (
      <span className="text-danger">
        {translate(messages.NO_PAYMENT_METHOD_FOUND)}
      </span>
    );
  }

  return (
    <Spin spinning={validatingChannels}>
      <p style={{ marginBottom: '0px', lineHeight: '12px', marginTop: '15px' }}>
        {translate(messages.traditional)}
      </p>
      <Radio.Group
        onChange={handleChange}
        value={value}
        name={name}
        disabled={disabled || validatingChannels}
      >
        {providerTypes &&
          providerTypes.map(
            (item: providerTypes, key: number) =>
              item && (
                <Radio
                  value={item.value}
                  key={key}
                  data-testid="providerTestID"
                >
                  {item.label}
                </Radio>
              )
          )}
      </Radio.Group>
      {/* 
          Temporarily hides the crypto options if paymentType is "ONLINE"
          due to while API doesn't support for this type yet
        */}
      <p style={{ marginBottom: '1px', lineHeight: '12px' }}>
        {translate(messages.cryptocurrency)}
      </p>
      <Radio.Group
        onChange={handleChange}
        value={value}
        name={name}
        disabled={disabled || validatingChannels}
      >
        {paymentType === PAYMENT_SOURCES.OFFLINE ? (
          <>
            {blockChainWithTypes &&
              blockChainWithTypes.map(
                (item: blockChainType, key: number) =>
                  item && (
                    <Radio
                      value={item.value}
                      key={key}
                      disabled={item.disabled}
                    >
                      {item.label === 'Bitcoin' ? (
                        <Tooltip title="Coming Soon">
                          <span>{item.label}</span>
                        </Tooltip>
                      ) : (
                        item.label
                      )}
                    </Radio>
                  )
              )}
          </>
        ) : null}
      </Radio.Group>
    </Spin>
  );
};

export default InternalProviders;
