import styled from 'styled-components';

export const StyledContent = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;

export const StyledCardStatContainer = styled.div`
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
`;

export default {};
