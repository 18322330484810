import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Spin, Table } from 'antd';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import esGet from 'utils/esGet';
import { Country, CountryConnectionEdge } from 'types/graphqlTypes';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { useConfig } from 'hooks/useConfig';
import { getColumnSearchProps } from '../utils';

const columns = [
  {
    title: 'Country',
    dataIndex: 'name',
    key: 'name',
    ...getColumnSearchProps('name'),
    sorter: (a: Country, b: Country) => a.name.localeCompare(b.name),
  },
  {
    title: 'Country Code',
    dataIndex: 'code',
    key: 'code',
  },
];

export const DEFAULT_COUNTRY_CODE = gql`
  query Config {
    config {
      defaultCountryCode {
        id
        code
        name
      }
    }
  }
`;

export const UPDATE_COUNTRY_CODE = gql`
  mutation UpdateConfig($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

const COUNTRIES = gql`
  query Countries($first: Int) {
    countries(first: $first) {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;

const ManageCountryCode = () => {
  const { data } = useQuery(COUNTRIES, {
    variables: {
      first: 300,
    },
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState<React.Key[]>([
    '',
  ]);

  const [getDefaultCountryCode] = useLazyQuery(DEFAULT_COUNTRY_CODE, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (dataResp: Record<string, any>) => {
      setLoading(false);
      const defaultCountryCode: Country = esGet(
        dataResp?.config?.defaultCountryCode,
        {}
      );
      setSelectedCountryCode([defaultCountryCode?.id!]);
    },
  });

  const { refetch } = useConfig();
  const { context } = useOperatorHeader();
  const [updateCountryCode] = useMutation(UPDATE_COUNTRY_CODE, {
    context,
  });

  const onUpdateCountryCode = async () => {
    setLoading(true);
    await updateCountryCode({
      variables: {
        input: {
          defaultCountryCode: selectedCountryCode[0],
        },
      },
    }).finally(() => {
      message.success('Country code updated');
      setLoading(false);
      if (refetch) {
        refetch();
      }
    });
  };

  useEffect(() => {
    if (isModalVisible) {
      setLoading(true);
      getDefaultCountryCode();
    }
  }, [getDefaultCountryCode, isModalVisible]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await onUpdateCountryCode();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedCountryCode(selectedRowKeys);
    },
    selectedRowKeys: selectedCountryCode,
  };

  const countryCodes: CountryConnectionEdge[] = esGet(
    data?.countries.edges,
    []
  );
  const transformedCountryCodes = countryCodes.map((code) => ({
    ...code.node,
    key: code.node.id,
  }));

  return (
    <div>
      <h3>Manage Country Code</h3>
      <Button style={{ marginTop: '12px' }} onClick={showModal}>
        View/Select Default Country Code
      </Button>

      <Modal
        width="600px"
        bodyStyle={{ height: '700px' }}
        title="View & Select your Default Country Code"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {loading ? (
          <Spin />
        ) : (
          <Table
            rowSelection={{
              type: 'radio',
              ...rowSelection,
            }}
            dataSource={transformedCountryCodes}
            columns={columns}
            scroll={{ y: 550 }}
          />
        )}
      </Modal>
    </div>
  );
};

export default ManageCountryCode;
