import React, { useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import { ExclamationCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { DragSource, DropTarget } from 'react-dnd';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import useTranslate from 'utils/useTranslate';
import { rowSource, rowTarget } from 'utils/tableDragAndDrop';
import CustomSwitch from 'components/CustomSwitch';
import messages from 'pages/components/PaymentMethodListing/messages';
import commonMessages from 'messages';

import {
  ActionItem,
  FlexFormItem,
  TopPercentHelper,
  BottomPercentHelper,
  StyledTable,
} from './styles';

const draggingIndex = -1;

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 4, offset: 10 },
};

const OpacityText = styled.span<any>`
  color: ${({ enable }) =>
    enable ? 'rgba(0, 0, 0, 0.65)' : 'rgba(0, 0, 0, 0.15)'};
`;

const getPaymentMethod = (text: string) => {
  switch (text) {
    case 'OFFLINE_BANK_TRANSFER':
      return messages.offlinebank;
    case 'ONLINE_BANK_TRANSFER':
      return messages.onlinebank;
    case 'WECHAT':
      return messages.wechat;
    case 'ALIPAY':
      return messages.alipay;
    case 'ONLINE_ALIPAY':
      return messages.onlinealipay;
    case 'ONLINE_WECHAT':
      return messages.onlinewechat;
    case 'UNIONPAY':
      return messages.unionpay;
    case 'CRYPTOCURRENCY':
      return messages.cryptocurrency;
    case 'WONKAPAY':
      return messages.wonkapay;
    case 'SKRILL':
      return messages.skrill;
    case 'NETELLER':
      return messages.neteller;
    default:
      return messages.offlinebank;
  }
};

const CustomInput: any = Input;

const BodyRow = (index: number) => (props: Record<string, any>) => {
  const {
    isOver,
    connectDragSource,
    connectDropTarget,
    moveRow,
    ...restProps
  } = props;
  const style = { ...restProps.style, cursor: 'move' };
  let { className } = restProps;
  if (isOver) {
    if (restProps.index > index) {
      className += ' drop-over-downward';
    }
    if (restProps.index < index) {
      className += ' drop-over-upward';
    }
  }
  return connectDragSource(
    connectDropTarget(<tr {...restProps} className={className} style={style} />)
  );
};

const PaymentMethods = forwardRef(
  ({ onMethodFilter, onTouchForm }: any, ref) => {
    const { role, permissions } = usePermissions();
    const { ALLOWED_EDIT_PAYMENT_SETTINGS } = collectPermissions(
      role,
      permissions,
      ['EDIT_PAYMENT_SETTINGS'],
      ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_PAYMENT_METHODS
    );

    const {
      handleSubmit,
      handleReset,
      handleBlur,
      values,
      setValues,
      errors,
      dirty,
    } = useFormikContext<any>();

    const paymentWithCrypto = values.paymentMethodConfiguration;

    const [enable, setEnable] = React.useState(false);
    // remove this line to enable crypto as default
    const dataSource = paymentWithCrypto.map((p: Record<string, any>) =>
      p.type === 'CRYPTOCURRENCY' ? { ...p, enable } : p
    );

    useEffect(() => {
      onTouchForm(dirty);
    }, [dirty, onTouchForm]);
    const translate = useTranslate();

    const columns = [
      {
        title: translate(messages.sourcemethods),
        dataIndex: 'type',
        key: 'type',
        render: (text: string, record: Record<string, any>) => (
          <span className="d-flex align-items-center">
            <MenuOutlined className="mr-1" />
            <OpacityText enable={record.enable}>
              {translate(getPaymentMethod(text))}
            </OpacityText>
          </span>
        ),
      },
      {
        title: translate(messages.active),
        dataIndex: 'active',
        key: 'active',
        align: 'right',
        render: (active: string, record: Record<string, any>) => (
          <Button type="link" onClick={onMethodFilter} data-type={record.type}>
            {active}
          </Button>
        ),
      },
      {
        title: translate(messages.appdisplay),
        dataIndex: 'enable',
        key: 'enable',
        render: (checked: boolean, _: any, index: number) => (
          <CustomSwitch
            name="enable"
            value={checked}
            checkedChildren={translate(commonMessages.show)}
            unCheckedChildren={translate(commonMessages.hide)}
            onChange={(value: boolean) => {
              if (
                dataSource[index].type === 'CRYPTOCURRENCY' &&
                dataSource[index].enable === false
              ) {
                setEnable(true);
              } else if (
                dataSource[index].type === 'CRYPTOCURRENCY' &&
                dataSource[index].enable === true
              ) {
                setEnable(false);
              }
              const newNode = {
                ...dataSource[index],
                enable: value,
              };
              const newData = Object.assign([], [...dataSource], {
                [index]: newNode,
              }).sort(
                (prev: Record<string, any>, next: Record<string, any>) =>
                  prev.rank - next.rank
              );

              setValues({
                ...values,
                paymentMethodConfiguration: newData,
              });
            }}
          />
        ),
      },
    ];

    const DraggableBodyRow = DropTarget(
      'row',
      rowTarget,
      (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
      })
    )(
      DragSource('row', rowSource(draggingIndex), (connect) => ({
        connectDragSource: connect.dragSource(),
      }))(BodyRow(draggingIndex))
    );

    const moveRow = async (dragIndex: number, hoverIndex: number) => {
      const dragNode = {
        ...dataSource[dragIndex],
        rank: dataSource[hoverIndex].rank,
      };
      const dropNode = {
        ...dataSource[hoverIndex],
        rank: dataSource[dragIndex].rank,
      };
      const newData = Object.assign([], [...dataSource], {
        [hoverIndex]: dropNode,
        [dragIndex]: dragNode,
      }).sort(
        (prev: Record<string, any>, next: Record<string, any>) =>
          prev.rank - next.rank
      );
      setValues({
        ...values,
        paymentMethodConfiguration: newData,
      });
    };

    const components = {
      body: {
        row: DraggableBodyRow,
      },
    };

    const onChangeField = (event: Record<string, any>) => {
      const newValue = parseInt(event.target.value, 10) || 0;
      setValues({
        ...values,
        [event.target.name]: newValue,
        [event.target.getAttribute('reffield')]: 100 - newValue,
      });
    };

    return (
      <Form
        layout="horizontal"
        onSubmit={handleSubmit}
        ref={ref as any}
        onReset={handleReset}
      >
        {/* <DndProvider backend={HTML5Backend}> */}

        <StyledTable
          columns={columns}
          dataSource={dataSource}
          components={components}
          pagination={false}
          rowKey={(record: Record<string, any>) => record.rank}
          // loading={loading}
          onRow={(_: any, index: number) => ({
            index,
            moveRow,
          })}
          scroll={{ y: 350, x: 400 }}
        />

        {/* </DndProvider> */}
        <h4 className="mt-1">{translate(messages.rotation)}</h4>
        <FlexFormItem
          {...formItemLayout}
          label={
            <>
              <span>{translate(messages.successrateweight)}</span>
              <Tooltip
                title={translate(messages.successrateweighttip)}
                arrowPointAtCenter
              >
                <ExclamationCircleOutlined className="ml-2" />
              </Tooltip>
            </>
          }
          validateStatus={errors.successRateWeight ? 'error' : ''}
          help={
            <>
              <TopPercentHelper type="enter" />
              <div>100 %</div>
              <BottomPercentHelper type="enter" />
            </>
          }
          colon={false}
        >
          <CustomInput
            name="successRateWeight"
            reffield="elapseMinuteWeight"
            value={values.successRateWeight}
            size="large"
            placeholder="90"
            suffix="%"
            type="text"
            onChange={onChangeField}
            onBlur={handleBlur}
          />
        </FlexFormItem>
        <FlexFormItem
          {...formItemLayout}
          label={
            <>
              <span>{translate(messages.elapseminweight)}</span>
              <Tooltip
                title={translate(messages.elapseminweighttip)}
                arrowPointAtCenter
              >
                <ExclamationCircleOutlined className="ml-2" />
              </Tooltip>
            </>
          }
          validateStatus={errors.elapseMinuteWeight ? 'error' : ''}
          colon={false}
          className="mt-3"
        >
          <CustomInput
            name="elapseMinuteWeight"
            reffield="successRateWeight"
            value={values.elapseMinuteWeight}
            size="large"
            placeholder="10"
            suffix="%"
            onChange={onChangeField}
            onBlur={handleBlur}
          />
        </FlexFormItem>
        <ActionItem className="pt-2">
          {ALLOWED_EDIT_PAYMENT_SETTINGS && (
            <Button type="primary" onClick={handleSubmit as any}>
              {translate(messages.save)}
            </Button>
          )}
        </ActionItem>
      </Form>
    );
  }
);

export default PaymentMethods;
