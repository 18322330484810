/*
 * This is to check the deposit if they're internal or external
 * by checking the paymentMethod.__typename
 * */
export const isInternalDepositByPaymentMethodTypename = (
  depositTypename: string
) =>
  [
    'OfflineBankTransferPaymentMethod',
    'WechatPaymentMethod',
    'AlipayPaymentMethod',
    'ManualAdjustmentDeposit',
    'ManualDeposit',
    'CryptocurrencyDeposit',
    'CryptocurrencyPaymentMethod',
  ].includes(depositTypename);

export const isExternalDepositByPaymentMethodTypename = (
  depositTypename: string
) =>
  [
    'OnlineBankTransferPaymentMethod',
    'OnlineAlipayPaymentMethod',
    'OnlineWechatPaymentMethod',
  ].includes(depositTypename);

export const isInternalDepositByTypename = (depositTypename: string) =>
  [
    'ManualAdjustmentDeposit',
    'ManualDeposit',
    'OfflineBankTransferDeposit',
    'AlipayDeposit',
    'WechatDeposit',
    'CryptocurrencyDeposit',
  ].includes(depositTypename);
