import { Button, Form, message, Radio, Upload } from 'antd';
import React from 'react';
import { ALink } from 'components/ALink/ALink';
import { useInterval } from 'react-use';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import { FormattedMessage } from 'react-intl';
import {
  BULK_CREATE_MEMBER_NOTE,
  MEMBER_BULK_NOTE_CREATE_RECORD,
} from '../../utils';

const BulkAddNotes = () => {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [bulkCreateMemberNote] = useMutation(BULK_CREATE_MEMBER_NOTE);

  const client = useApolloClient();

  const [isIntervalRunning, setIsIntervalRunning] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [downloadLink, setDownloadLink] = React.useState('');
  const [fileType, setFileType] = React.useState('text');

  const onCallBulkRecord = async () => {
    const { data } = await client.query({
      query: MEMBER_BULK_NOTE_CREATE_RECORD,
      fetchPolicy: 'network-only',
    });

    const status = data?.memberBulkNoteCreateRecord?.status;

    if (status === 'FAILED') {
      setIsIntervalRunning(false);
      setProcessing(false);
      message.error('Failed to Add Bulk Notes');
      return;
    }

    if (status === 'DONE') {
      setIsIntervalRunning(false);
      setProcessing(false);
      setDownloadLink(data?.memberBulkNoteCreateRecord?.result);
      message.success('Successfully Added Bulk Notes');
    }
  };

  useInterval(
    () => {
      onCallBulkRecord();
    },
    isIntervalRunning ? 1500 : null
  );

  const filePath =
    fileType === 'text'
      ? '/text/sample for bulk add notes.txt'
      : '/excel/sample_template_bulk_add_notes.csv';

  const fileName = `sample for bulk add notes.${
    fileType === 'text' ? 'txt' : 'csv'
  }`;

  const acceptedFileType = fileType === 'text' ? ['.txt'] : ['.csv'];

  const fileTypeButtonLabel = fileType === 'text' ? 'Text' : 'CSV';

  const uploadProps: any = {
    beforeUpload: async (file: File) => {
      setDownloadLink('');

      const validType =
        fileType === 'text'
          ? file.type === 'text/plain'
          : file.type === 'text/csv';

      if (!validType) {
        message.error('Only upload valid text file');
        return false;
      }

      setProcessing(true);

      try {
        const { data } = await uploadFile({
          variables: {
            file,
          },
        });

        await bulkCreateMemberNote({
          variables: {
            file: data?.uploadFile?.id,
          },
        });

        setIsIntervalRunning(true);
        onCallBulkRecord();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      return false;
    },
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <h3 className="mb-0 pr-4">Bulk Add Notes &nbsp;</h3>
        <ALink
          href={`${process.env.PUBLIC_URL}${filePath}`}
          target="_blank"
          rel="noopener noreferrer"
          download={fileName}
        >
          Sample Format
        </ALink>
      </div>
      <div className="d-flex mb-3">
        <Upload
          {...uploadProps}
          maxCount={1}
          accept={acceptedFileType.join(', ')}
          showUploadList={false}
        >
          <Button className="mr-3" disabled={processing} loading={processing}>
            {processing
              ? 'Processing...'
              : `Upload ${fileTypeButtonLabel} File`}
          </Button>
        </Upload>

        <Button disabled={!downloadLink} type="primary" href={downloadLink}>
          Download
        </Button>
      </div>

      <Form.Item label="File Type">
        <Radio.Group
          defaultValue={fileType}
          onChange={(e) => setFileType(e.target.value)}
        >
          <Radio value="text" className="ml-2">
            <FormattedMessage id="TEXT" defaultMessage="Text" />
          </Radio>

          <Radio value="csv" className="ml-3">
            <FormattedMessage id="CSV" defaultMessage="CSV" />
          </Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default BulkAddNotes;
