import React from 'react';
import { Button } from 'antd';
import { useScreenTabV2 } from 'store/screenTabState';
import { TabId } from 'constants/route';

function TotalNumberOfParticipatingMemberColumn(props: {
  data: Record<string, any>;
}) {
  const { addTab } = useScreenTabV2();
  const { data } = props;
  const { id, participatingMembers } = data;
  return (
    <Button
      type="link"
      className="p-0"
      data-testid={TabId.TOTAL_NUMBER_OF_PARTICIPATING_MEMBER}
      onClick={() =>
        addTab({
          id: TabId.TOTAL_NUMBER_OF_PARTICIPATING_MEMBER as any,
          state: {
            data: {
              rebateGroupId: id,
            },
          },
        })
      }
    >
      {participatingMembers?.totalCount}
    </Button>
  );
}

export default TotalNumberOfParticipatingMemberColumn;
