import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import messages from 'pages/components/WithdrawalMethods/messages';
import useTranslate from 'utils/useTranslate';

type Rec = Record<string, any>;
const { Item }: any = Form;
const AlipayDetails = (props: {
  formItemLayout: Rec;
  values: Rec;
  errors: Rec;
  handleBlur: () => void;
  handleChange: () => void;
  disabled?: boolean;
}) => {
  const {
    formItemLayout,
    values,
    errors,
    handleBlur,
    handleChange,
    disabled,
  } = props;

  const translate = useTranslate();

  return (
    <>
      <Item
        label={translate(messages.nickname)}
        {...formItemLayout}
        validateStatus={errors.nickname ? 'error' : null}
        help={errors.nickname || null}
      >
        <Input
          name="nickname"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.nickname}
          placeholder={translate(messages.nickname)}
          disabled={disabled}
        />
      </Item>
      <Item
        label={translate(messages.bankacnumber)}
        {...formItemLayout}
        validateStatus={errors.accountNumber ? 'error' : null}
        help={errors.accountNumber || null}
      >
        <Input
          name="accountNumber"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.accountNumber}
          placeholder={translate(messages.bankacnumber)}
        />
      </Item>
    </>
  );
};

export default AlipayDetails;
