import React from 'react';
import { Menu, Layout, Row, Col } from 'antd';
import qs from 'qs';
import navMessages from 'constants/navMessages';
import { navlist as navList } from 'SuperAdminMain/components/Navbar/utils';
import LanguageSelect from 'components/LanguageSelect';
import * as uuid from 'uuid';
// import { screentabType } from 'pages/components/ScreenTab/utils';
import Storage from 'constants/storage';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';
import {
  useScreenTabSA,
  setActiveTab,
} from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';
import logo from 'img/gobet-logo.svg';
import { StyleNav, GlobalStyle } from './styles';
import { ProfileDropdown } from './components/ProfileDropdown/ProfileDropdown';
import useIsNext from '../../../hooks/useIsNext';
import { FeatureFlagsSA } from '../../constants/featureflags';

export const Navbar = () => {
  const translate = useTranslate();
  const { addTab: addTabNew } = useScreenTabSA();
  const next = useIsNext;
  const generateUrl = (item: any) => {
    const newTabId = uuid.v1();
    const urlString = qs.stringify({
      screenTab: newTabId,
      tabParams: item,
      accessToken: localStorage.getItem(Storage.ACCESS_TOKEN),
      refreshToken: localStorage.getItem(Storage.REFRESH_TOKEN),
    });
    return `${window.location.pathname.replace(/\/$/, '')}?${urlString}`;
  };

  const handleShowBrowserTab = (item: any) => {
    window.open(generateUrl(item), '_blank');
  };

  const handleClick = (nav: any) => {
    const newTab = {
      id: nav.id,
      state: {
        ...nav,
      },
    };

    delete newTab.state.id;
    addTabNew(newTab);

    // @ts-ignore
    if (window.event.shiftKey || window.event.ctrlKey || window.event.metaKey) {
      handleShowBrowserTab(nav);
      // } else if (!nav.multiple) {
      //   addTab(nav, screentabType.SUPER_ADMIN);
      //   setActiveTab(nav);
      // } else {
      //   setActiveTab(nav, screentabType.SUPER_ADMIN);
    }
  };

  const nextNavItems = {
    // 'superadmin-accounts-consolidated-report': next(FeatureFlagsSA.screenCura),
    // 'superadmin-consolidated-vendor-usage-report': next(
    //   FeatureFlagsSA.screenCurv
    // ),
    'superadmin-vendor-group-management': next(
      FeatureFlagsSA.nextVendorGroupManagement
    ),
  };

  const filterSubItemByNext = (list: any[]) =>
    list.filter(
      (item) => nextNavItems[item.id] === undefined || nextNavItems[item.id]
    );

  const filterMainNavIfNoList = navList.filter((navListData) => {
    const lists = filterSubItemByNext(navListData.list);
    return lists.length > 0;
  });

  return (
    <StyleNav>
      <Layout.Header>
        <Row>
          <Col xs={18} lg={18} xl={20}>
            <div className="logo">
              <ALink
                onClick={(e: any) => {
                  e.preventDefault();
                  setActiveTab('toda');
                }}
              >
                <img src={logo} alt="app logo" width={38} />
              </ALink>
            </div>

            <Menu theme="light" mode="horizontal">
              {filterMainNavIfNoList.map((item: any) => {
                if (item.list.length > 0) {
                  return (
                    <Menu.SubMenu
                      popupClassName="topbar-menu"
                      key={item.id}
                      title={translate(navMessages[`${item.id}.text`])}
                    >
                      {filterSubItemByNext(item.list).map((subItem: any) => (
                        <Menu.Item
                          key={subItem.id}
                          onClick={() => handleClick(subItem)}
                        >
                          {translate(navMessages[`${subItem.id}.text`])}
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>
                  );
                }

                return (
                  <Menu.Item key={item.id} onClick={() => handleClick(item)}>
                    {translate(navMessages[`${item.id}.text`])}
                  </Menu.Item>
                );
              })}
            </Menu>
          </Col>

          <Col xs={6} lg={6} xl={4}>
            <Row align="middle" justify="end">
              <Col span={24}>
                <div className="right">
                  <ProfileDropdown />
                  <LanguageSelect />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout.Header>
      <GlobalStyle />
    </StyleNav>
  );
};
