import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'categories.text': {
    id: 'categories.text',
    defaultMessage: 'Categories',
  },
  'vendors.text': {
    id: 'vendors.text',
    defaultMessage: 'Vendors',
  },
  'actions.text': {
    id: 'actions.text',
    defaultMessage: 'Actions',
  },
  'error-no-vendors.text': {
    id: 'error-no-vendors.text',
    defaultMessage: 'Please add vendors before you continue',
  },
  'delete-category-title.text': {
    id: 'delete-category-title.text',
    defaultMessage: 'Delete Category',
  },
  'delete-category-content.text': {
    id: 'delete-category-content.text',
    defaultMessage: 'This will delete the {category} category.',
  },
  'add-vendor.text': {
    id: 'add-vendor.text',
    defaultMessage: 'Add Vendor',
  },
  'edit-vendor.text': {
    id: 'edit-vendor.text',
    defaultMessage: 'Edit Vendor',
  },
  'vendor-error.text': {
    id: 'vendor-error.text',
    defaultMessage: 'Vendor is required',
  },
});
