import { Divider } from 'antd';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import DownloadCsvButton from 'components/DownloadCsvButton';
import { DEPOSIT_REQUESTS_CSV_DATA } from 'graphql/queries/deposit.query';
import React from 'react';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import RequestUpdateToggle from 'components/RequestUpdateToggle';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';

type Props = {
  isRealtime?: boolean;
  handleSetRealtime?: () => void;
  refreshPage: () => void;
};

const HeaderRight: React.FC<Props> = ({
  handleSetRealtime,
  isRealtime,
  refreshPage,
}) => {
  const translate = useTranslate();

  return (
    <>
      <RequestUpdateToggle checked={isRealtime} onChange={handleSetRealtime} />
      <RefreshButton onClick={refreshPage} />
      <Divider type="vertical" className="ml-0 mr-2" />
      <ColumnsFilterNew iconOnly tabId="deposit-requests-external" />
      <DownloadCsvButton
        iconOnly
        tabId="deposit-requests-external"
        fileName={translate(messages.depositRequestThirdParty)}
        edgesPath="deposits.edges"
        query={DEPOSIT_REQUESTS_CSV_DATA}
      />
    </>
  );
};

export default HeaderRight;
