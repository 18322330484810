import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { StyledLabel, StyledSpan } from '../../styles';

interface Props {
  onChange: (e: Record<string, object> | null) => void;
  value: {
    verified?: Record<string, string[]>;
  };
  disabled: boolean;
}

const verificationStatuses = [
  {
    value: true,
    label: 'Verified',
  },
  {
    value: false,
    label: 'Not Verified',
  },
];

export const VerificationFilter: FC<Props> = ({
  value,
  onChange,
  disabled,
}) => {
  const values = value?.verified?.in ?? [];

  const handleChange = (e: CheckboxValueType[]) => {
    if (e.length) {
      onChange({ in: e });
    } else {
      onChange(null);
    }
  };

  return (
    <div className="mt-1">
      <div className="d-flex justify-content-between">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="verification-status.text"
              defaultMessage="Verification Status"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>
      <Checkbox.Group
        options={verificationStatuses}
        onChange={handleChange}
        disabled={disabled}
        value={values}
      />
    </div>
  );
};
