import React, { useState } from 'react';
import Layout from 'components/Layout';
import { useScreenTabV2 } from 'store/screenTabState';
import { getPollInterval } from 'utils/pollInterval';
import { useQuery } from '@apollo/react-hooks';
import { ErrorHandler } from 'components/ErrorHandler';
import coercedGet from 'utils/coercedGet';
import { useTablePagination } from 'hooks/usePaginate';
import ReportsTimezoneProvider from 'contexts/ReportsTimezone';
import { useIntl } from 'react-intl';
import { FilterContext, useFilterProvider } from 'contexts/Filter';
import { sortTransform } from 'utils/partialUtils';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import uuid from 'uuid';
import {
  TABLE_QUERY,
  SCREEN_TAB_ID,
  TABLE_QUERY_NAME,
  QUICK_SEARCH_ID,
} from './configs';
import Sidebar from './components/Sidebar';
import HeaderLeft from './components/HeaderLeft';
import HeaderRight from './components/HeaderRight';
import ScreenTable from './components/ScreenTable';

type Props = {
  refreshPage: () => void;
};

const Screen = ({ refreshPage }: Props) => {
  const { locale } = useIntl();
  const { isTabActive } = useScreenTabV2();
  const { page, setPage, handleNext, handlePrev, sort } = useTablePagination({
    after: undefined,
    savedCursor: [undefined] as any,
    currentPage: 0,
    first: 10,
    sort: 'descend',
  });

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    language: locale.toUpperCase(),
    sort: {
      direction: sortTransform[page.sort],
    },
  };
  const { context, setOnPoll } = useOperatorHeader();
  const { loading, data = {} } = useQuery(TABLE_QUERY, {
    fetchPolicy: 'network-only',
    variables: refetchVariables,
    pollInterval: getPollInterval({
      isActive: isTabActive(SCREEN_TAB_ID),
      isRealtime: true,
    }),
    context,
    onCompleted: () => {
      setOnPoll(true);
    },
  });

  const queryData = coercedGet(data, TABLE_QUERY_NAME, {});
  const { totalCount, pageInfo, edges = [] } = queryData;
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  return (
    <Layout.Container>
      <Layout.Header
        leftNode={<HeaderLeft />}
        rightNode={<HeaderRight refreshPage={refreshPage} />}
      />
      <Layout.Content
        sideBar={<Sidebar refetch={refreshPage} />}
        footer={
          <Layout.Footer
            leftNode={<div />}
            loading={loading}
            onNext={() => handleNext(pageInfo)}
            onPrev={handlePrev}
            page={page}
            resultsCount={totalCount}
            totalPage={totalPage}
            setPage={setPage}
            pageInfo={pageInfo}
          />
        }
      >
        <ErrorHandler>
          <ScreenTable
            sort={sort}
            dataSource={edges}
            loading={loading}
            refetchVariables={refetchVariables}
          />
        </ErrorHandler>
      </Layout.Content>
    </Layout.Container>
  );
};

const ScreenProvider = () => {
  const { getTab } = useScreenTabV2();
  const { filter = {} } = getTab(SCREEN_TAB_ID);

  const [key, setKey] = useState('');
  const refreshPage = () => setKey(uuid.v1());

  const defaultFilter = {
    startDateTime: null,
    endDateTime: null,
    members: null,
    gameTypes: null,
    vendors: null,
    isAffiliate: null,
    isAffiliateUpline: null,
    affiliates: null,
    memberLoyaltyLevels: null,
    memberLevels: null,
    memberTags: null,
    memberStatus: null,
    registrationDateTime: null,
    lastLoginDateTime: null,
    dateOfBirthDateTime: null,
    totalBalance: null,
    timezone: null,
  };

  const initialFilter = {
    ...defaultFilter,
    ...filter,
  };

  return (
    <FilterContext.Provider
      value={useFilterProvider({
        tabId: SCREEN_TAB_ID,
        context: QUICK_SEARCH_ID,
        initialFilter,
        defaultFilter,
      })}
    >
      <ReportsTimezoneProvider>
        <Screen key={key} refreshPage={refreshPage} />
      </ReportsTimezoneProvider>
    </FilterContext.Provider>
  );
};

export default ScreenProvider;
