import React from 'react';
import { Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { DynamicObj } from 'interfaces/user.interface';
import { AffiliateProgramme } from 'types/graphqlTypes';
import { AFFILIATE_PROGRAMMES } from './queries';

type Props = {
  value: string;
  onChange: (e: string) => void;
};

export const AffiliateProgrammesSelect = ({ value, onChange }: Props) => {
  const { data = {}, loading } = useQuery(AFFILIATE_PROGRAMMES, {
    variables: {
      filter: {
        status: {
          eq: 'ACTIVE',
        },
      },
    },
  });

  const affiliateEdges = coercedGet(data, 'affiliateProgrammes.edges', []);
  const affiliateProgrammes = affiliateEdges.map(
    (affiliate: { node: DynamicObj }) => affiliate.node
  );
  return (
    <Select value={value} onChange={onChange} loading={loading}>
      {affiliateProgrammes.map((affiliate: AffiliateProgramme) => (
        <Select.Option data-testid="select-option" value={affiliate.id}>
          {affiliate.name}
        </Select.Option>
      ))}
    </Select>
  );
};
