import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { withErrorHandler } from 'components/ErrorHandler';

import FiltersProvider from 'SuperAdminMain/contexts/Filters';
import ReportsTimezoneProvider from 'contexts/ReportsTimezone';
import Schedule from './components/Schedule';
import Sidebar from './components/Sidebar';
import { StyledLayout, StyledContent, StyledSider } from './styles';
import ReportsTable from './components/ReportsTable';
import TableHeader from './components/TableHeader';

const BalanceTransactionRecords = () => {
  const [isSidebar, setSidebar] = useState(false);

  const handleSidebar = () => setSidebar(!isSidebar);

  const tableRef = React.useRef(null);

  return (
    <FiltersProvider
      initialValues={{
        timezone: null,
        dateTimeProcessed: null,
        dateTimePlaced: null,
        dateTimeSettled: null,
        admin: null,
        id: null,
        vendor: null,
        game: null,
        game_category: null,
        game_subCategory: null,
        game_name: null,
        tips: null,
        jackpotContributionBet: null,
        jackpotWin: null,
        cashoutWinloss: null,
        freeSpin: null,
      }}
    >
      <ReportsTimezoneProvider>
        <StyledLayout className="d-flex">
          <StyledContent>
            <TableHeader ref={tableRef} handleSidebar={handleSidebar} />
            <div className="d-flex">
              <Sidebar ref={tableRef} collapsed={isSidebar}>
                <FormattedMessage
                  id="member.loyalty.sider.text"
                  defaultMessage="Sider"
                />
              </Sidebar>
              <StyledContent className="justify-content-space-between flex-direction-column">
                <ReportsTable ref={tableRef} />
              </StyledContent>
            </div>
          </StyledContent>
          <StyledSider
            theme="light"
            width={303}
            style={{
              overflowY: 'auto',
              height: 'auto',
            }}
          >
            <Schedule ref={tableRef} />
          </StyledSider>
        </StyledLayout>
      </ReportsTimezoneProvider>
    </FiltersProvider>
  );
};

export default withErrorHandler(BalanceTransactionRecords);
