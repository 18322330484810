import React from 'react';
import FiltersProvider, { useFilterValues } from 'contexts/Filters';
import Layout from 'components/Layout';
import { Table, Button } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { isEqual, startCase } from 'lodash';
import { Promo, PromosConnectionEdge } from 'types/graphqlTypes';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { formatDate } from 'utils/dateUtils';
import ToggleSwitch from 'components/ToggleSwitch';
import { useTablePagination } from 'hooks/usePaginate';
import { GET_PROMOS, GET_PROMO_NAMES } from 'graphql/queries/promo.query';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import removeNull from 'utils/removeNull';
import coercedGet from 'utils/coercedGet';
import { StyledTagContainer } from 'styles';
import { usePromo } from 'store/promoState/promoState';
import { useScreenTabV2, AddTab } from 'store/screenTabState';
import { FilterConditions } from './FilterConditions';
import Sidebar from './Sidebar';

const ManagePromoContent = () => {
  const { setActivePromo } = usePromo();
  const { addTab } = useScreenTabV2();

  const openNewTab = (e: AddTab, id?: string) => {
    if (id) {
      setActivePromo(id);
    }
    addTab(e);
  };

  const [expandedTags, setExpandedTags] = React.useState(false);
  const { filters, initialValues, setFilters } = useFilterValues();
  const { page, setPage, handleNext, handlePrev } = useTablePagination({
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
    first: 10,
  });

  const memoizedFilter = React.useMemo(
    () => ({
      ...removeNull(filters),
    }),
    [filters]
  );

  const { loading, data, refetch } = usePartialFiltersQuery(
    GET_PROMO_NAMES,
    GET_PROMOS,
    'promos.edges',
    memoizedFilter,
    page,
    ['name'],
    'cache-and-network',
    'name'
  );

  const promoEdges = coercedGet(data, 'promos.edges', []);
  const promos = promoEdges.map(
    (promoEdge: { node: PromosConnectionEdge }) => promoEdge.node
  );

  const totalCount = coercedGet(data, 'promos.totalCount', 0);
  const pageInfo = coercedGet(data, 'promos.pageInfo', 0);

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleFilters = (e: object) => {
    setFilters(e);
  };

  const columns = [
    {
      key: 'type',
      title: 'Promo Type',
      dataIndex: 'type',
      align: 'center',
      render: (promoType: string) => startCase(promoType),
    },
    {
      key: 'name',
      title: 'Promo Name',
      dataIndex: 'name',
      align: 'center',
    },
    {
      key: 'startDateTime',
      title: 'Start Date Time',
      align: 'center',
      render: (_: unknown, record: Promo) => (
        <span>{formatDate(record.startDateTime)}</span>
      ),
    },
    {
      key: 'endDateTime',
      title: 'End Date Time',
      align: 'center',
      render: (_: unknown, record: Promo) => (
        <span>{formatDate(record.endDateTime)}</span>
      ),
    },
    {
      key: 'active',
      title: 'Activate/Deactivate',
      align: 'center',
      render: (record: Promo) => {
        const { id, status } = record || {};

        return (
          <ToggleSwitch
            id={id}
            enumValue={status}
            confirmationMessage={`${
              status === 'ACTIVE' ? 'Deactivate' : 'Activate'
            } Promo.`}
            hasCheckedChildren={false}
            refetch={() => refetch()}
          />
        );
      },
    },

    {
      key: 'action',
      title: 'Edit',
      align: 'center',
      render: (record: Promo) => (
        <Button
          disabled={record.status === 'ACTIVE'}
          type="link"
          onClick={() => {
            openNewTab(
              {
                id: 'edit-promo',
                state: {
                  promoId: record.id,
                },
              },
              record.id
            );
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={
            <div className="d-flex align-items-center flex-g-1">
              <ToggleSidebarHead />
              <StyledTagContainer ref={ref} isExpanded={expandedTags}>
                <FilterConditions
                  filters={filters}
                  onFilterChange={handleFilters}
                  loading={loading}
                />
              </StyledTagContainer>
              <LegacyIconToggle
                forwardedRef={ref}
                filters={filters}
                expandedTags={expandedTags}
                toggleExpandedTags={setExpandedTags}
              />
              {!isEqual(initialValues, filters) && (
                <ALink
                  onClick={() => handleFilters(initialValues)}
                  style={{ marginTop: '0.35rem' }}
                >
                  Clear All
                </ALink>
              )}
            </div>
          }
          rightNode={<></>}
        />
        <Layout.Content
          sideBar={<Sidebar />}
          footer={
            <Layout.Footer
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={pageInfo}
            />
          }
        >
          <Table
            style={{
              marginTop: 35,
            }}
            columns={columns as any}
            dataSource={promos}
            pagination={false}
            loading={loading}
          />
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

const ManagePromo = () => (
  <FiltersProvider
    initialValues={{
      type: null,
      name: null,
      startDateTime: null,
      endDateTime: null,
    }}
  >
    <ManagePromoContent />
  </FiltersProvider>
);

export default ManagePromo;
