import React, { ReactElement } from 'react';
import Handler from './ErrorHandler';

const ErrorHandler: any = Handler;
const withErrorHandler = (
  ChildComponent: any,
  showFallbackUI?: boolean,
  errorElement?: ReactElement,
  onError?: (e: any, f?: any) => void
) => (props: Record<string, any>) => (
  <ErrorHandler
    errorElement={errorElement}
    onError={onError}
    showFallbackUI={showFallbackUI}
  >
    <ChildComponent {...props} />
  </ErrorHandler>
);

export default withErrorHandler;
