import errorMessages from 'constants/errorMessages';

export default (err: Record<string, any>) => {
  let errMessage = err.toString();
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    const error = err.graphQLErrors[0];
    if (error.message.length > 0) {
      errMessage = error.message;
    } else if (error.code) {
      errMessage = errorMessages[error.code];
    } else {
      errMessage = 'Something went wrong!';
    }
  }

  const { response } = err;
  const code = response ? response.data.code : null;

  switch (code) {
    case 'INVALID_CREDENTIALS':
      return err.response.data.description;
    case 'INVALID_PIN':
      return err.response.data.description;
    default:
      return errMessage;
  }
};
