import React from 'react';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';
import { Table as AntTable, Dropdown, Menu, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import { useScreenTabSA } from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';
import moment from 'moment';
import coercedGet from 'utils/coercedGet';
import { BatchSplit } from 'SuperAdminMain/shared/components/BatchSplit/BatchSplit';
import { Admin } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import { useCustomColumnsV2 } from 'SuperAdminMain/store/SuperAdminCustomColumnState/customColumnState';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import { Status } from './Status/Status';
import { Footer } from './Footer/Footer';
import { ChangeLoginPassword } from './Actions/ChangeLoginPassword/ChangeLoginPassword';
import { UpgradeToProduction } from './Actions/UpgradeToProduction/UpgradeToProduction';
import { ExtendTrialDuration } from './Actions/ExtendTrialDuration/ExtendTrialDuration';
import { EditStatus } from './Actions/EditStatus/EditStatus';
import { AddOperatorAccount } from './Actions/AddOperatorAccount/AddOperatorAccount';
import { DeleteAccount } from './Actions/DeleteAccount/DeleteAccount';
import { EditClientInformation } from './Actions/EditClientInformation/EditClientInformation';
import { useAccountManagementValue } from '../../context';
import messages from './messages';
import { VENDOR_GROUP } from '../../utils';

// ===[ MAIN ]=== //
export const Table: React.FC = () => {
  const { addTab } = useScreenTabSA();
  const translate = useTranslate();
  const { data, loading, error, collapsed } = useAccountManagementValue();

  const edges = coercedGet(data, 'admins.edges', []);
  const admins = edges.map(({ node }: any) => node);

  const columns = [
    {
      key: 'username',
      title: translate(messages.CLIENT_ACCOUNT),
      render: (account: any) =>
        (
          <>
            <div className="d-flex align-items-center">
              {account.status === 'DRAFT' && (
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '11px',
                    borderRadius: '20px',
                    padding: '2px 9px',
                    border: '1px solid #3590ff',
                    color: '#2f80ed',
                    cursor: 'pointer',
                  }}
                  className="mr-2"
                >
                  <FormattedMessage id="todo.text" defaultMessage="TODO" />
                </div>
              )}

              <ALink
                onClick={() => {
                  addTab({
                    id: 'client-profile',
                    memberId: account.id,
                    state: {
                      username: account.username,
                      translateKeyId: 'account/username',
                      translateOpts: {
                        memberUsername: account.username,
                      },
                    },
                  });
                }}
              >
                {account.username}
              </ALink>
            </div>
          </>
        ) || '-',
    },
    {
      key: 'company',
      title: translate(messages.CLIENT_COMPANY),
      dataIndex: 'company',
      render: (company: string) => company || '-',
    },
    {
      key: 'adminCode',
      title: translate(messages.ADMIN_CODE_URL),
      dataIndex: 'adminCode',
      render: (vendor: string) => vendor || '-',
    },
    {
      render: (id: any) =>
        id ? (
          <BatchSplit
            customVariables={{ id }}
            withEdges={false}
            gqlTag={VENDOR_GROUP}
            render="vendorGroup.name"
          />
        ) : (
          '-'
        ),
      key: 'id',
      title: translate(messages.VENDOR_GROUP),
      dataIndex: 'id',
    },
    {
      key: 'trial',
      title: translate(messages.CATEGORY),
      dataIndex: 'trial',
      render: (category: boolean) =>
        category ? (
          <FormattedMessage id="trial.text" defaultMessage="Trial" />
        ) : (
          <FormattedMessage id="production.text" defaultMessage="Production" />
        ),
    },
    {
      key: 'status',
      title: translate(messages.STATUS),
      dataIndex: 'status',
      render: (status: string) => <Status status={status || ''} />,
    },
    {
      title: translate(messages.START_DATE),
      key: 'contractStartDateTime',
      dataIndex: 'contractStartDateTime',
      render: (contractStartDateTime: any) =>
        contractStartDateTime ? (
          <div className="date">
            {moment(contractStartDateTime).format('YYYY/MM/DD')}
            <br />
            {`(一) ${moment(contractStartDateTime).format('HH:mm:ss')}`}
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: translate(messages.EXPIRE_DATE),
      dataIndex: 'contractEndDateTime',
      key: 'contractEndDateTime',
      render: (contractEndDateTime: any) =>
        contractEndDateTime ? (
          <div className="date">
            {`${moment(contractEndDateTime).format('YYYY/MM/DD')}`}
            <br />
            {`(一) ${moment(contractEndDateTime).format('HH:mm:ss')}`}
          </div>
        ) : (
          '-'
        ),
    },
    {
      title: '',
      key: 'actions',
      fixed: 'right',
      hidden: true,
      render: (account: Admin) => (
        <Dropdown
          overlay={
            <StyledMenu>
              <StyledMenu.Item>
                <EditClientInformation account={account} />
              </StyledMenu.Item>

              <StyledMenu.Item>
                <ChangeLoginPassword account={account} />
              </StyledMenu.Item>

              <StyledMenu.Item>
                <ExtendTrialDuration account={account} />
              </StyledMenu.Item>

              <StyledMenu.Item>
                <UpgradeToProduction account={account} />
              </StyledMenu.Item>

              <StyledMenu.Item>
                <EditStatus account={account} />
              </StyledMenu.Item>

              <StyledMenu.Item>
                <AddOperatorAccount account={account} />
              </StyledMenu.Item>

              <StyledMenu.Item>
                <DeleteAccount account={account} />
              </StyledMenu.Item>
            </StyledMenu>
          }
          placement="bottomRight"
        >
          <EllipsisOutlined
            className="cursor-pointer font-weight-bold"
            style={{ fontSize: '25px' }}
          />
        </Dropdown>
      ),
    },
  ] as any;

  const { filterColumns, loading: loadingCC } = useCustomColumnsV2(
    TabId.ALL_CLIENT,
    columns
  );

  if (error)
    return <span data-testid="account-mgmt-error">Error! {error.message}</span>;

  if (loadingCC) {
    return <Spin data-testid="account-mgmt-table-loading" />;
  }

  return (
    <Container collapsed={collapsed}>
      <StyledTable
        data-testid="account-mgmt-table"
        dataSource={admins}
        columns={filterColumns(columns)}
        pagination={false}
        loading={loading}
        rowKey="id"
      />
      <Footer />
    </Container>
  );
};

// ===[ STYLES ]=== //
const Container: any = styled.div`
  height: auto;
  width: calc(100% - ${({ collapsed }: any) => (collapsed ? 0 : 232)}px);

  .date {
    position: absolute;
    transform: translateY(-50%);
  }
`;

const StyledTable = styled(AntTable)`
  height: calc(100% - 48px);
  overflow-y: scroll;

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  tbody tr {
    max-height: 54px !important;
  }

  table tr:nth-child(2n) td {
    background-color: #fcfcfc;
  }

  button {
    text-align: left;
  }
`;

const StyledMenu = styled(Menu)`
  li {
    padding: 0;
  }

  button {
    text-align: left;
    color: #000000a6;
  }
`;
