import React from 'react';
import { Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { StyledLabel } from '../styles';

type Props = {
  state: Record<string, any>;
  setState: any;
};
const ManualBalanceAdjustmentFilter = ({ state, setState }: Props) => {
  const NextCheckboxYes = () => (
    <Checkbox
      checked={state?.MANUAL_ADJUSTMENT === 'yes'}
      onChange={() =>
        setState((prev: Record<string, any>) => ({
          ...prev,
          MANUAL_ADJUSTMENT: 'yes',
        }))
      }
    >
      <FormattedMessage id="yes.text" defaultMessage="Yes" />
    </Checkbox>
  );
  const NextCheckboxNo = () => (
    <Checkbox
      checked={state?.MANUAL_ADJUSTMENT === 'no'}
      onChange={() =>
        setState((prev: Record<string, any>) => ({
          ...prev,
          MANUAL_ADJUSTMENT: 'no',
        }))
      }
    >
      <FormattedMessage id="no.text" defaultMessage="No" />
    </Checkbox>
  );

  const NextCheckboxBoth = () => (
    <Checkbox
      checked={!state.MANUAL_ADJUSTMENT}
      onChange={() =>
        setState((prev: Record<string, any>) => ({
          ...prev,
          MANUAL_ADJUSTMENT: undefined,
        }))
      }
    >
      <FormattedMessage id="both.text" defaultMessage="Both" />
    </Checkbox>
  );

  return (
    <>
      <div className="mb-1">
        <StyledLabel>
          <FormattedMessage
            id="manual-balance-adjustment.text"
            defaultMessage="Manual Balance Adjustment"
          />
        </StyledLabel>
      </div>
      <div>
        <NextCheckboxYes />
        <NextCheckboxNo />
      </div>
      <div>
        <NextCheckboxBoth />
      </div>
    </>
  );
};

export default ManualBalanceAdjustmentFilter;
