import { DynamicObj } from 'interfaces/user.interface';

export const BALANCE_FORM_EXTRA_VALUES: DynamicObj = {
  promo: '',
  turnover: '',
  vip: { programme: {} },
  awardType: '',
  rebateGroup: '',
  affiliateProgramme: '',
  hexoPayCreditCard: '',
  withdrawalMethod: '',
  email: '',
};
