import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import useTranslate from 'utils/useTranslate';
import { setDecimals } from 'utils/setDecimals';
import messages from '../../../../messages';
import { frequencyTime, sourceItem } from './constants';

const ExchangeRateInformation = ({ paymentMethod, formItemLayout }: any) => {
  const {
    exchangeRateSpread,
    minimumConfirmationsCount,
    exchangeRate,
    exchangeRateSource,
    exchangeRateUpdateFrequency,
  } = paymentMethod;
  const translate = useTranslate();

  const isExchangeRateON = exchangeRateSource !== 'MANUAL';

  // filter array with payment method property
  const filterByValue = (array: any, string: string) =>
    array.filter((o: any) =>
      Object.keys(o).some(
        (k) =>
          typeof o[k] === 'string' &&
          o[k].toLowerCase().includes(string && string.toLowerCase())
      )
    );

  const frequencyTimeValue = filterByValue(
    frequencyTime,
    exchangeRateUpdateFrequency && exchangeRateUpdateFrequency.slice(-1)
  );

  const frequency =
    exchangeRateUpdateFrequency &&
    `${exchangeRateUpdateFrequency.substring(
      0,
      exchangeRateUpdateFrequency.length - 1
    )} ${frequencyTimeValue[0] && frequencyTimeValue[0].value}`;

  // coin gecko is currently the only supported exchange rate source
  const exchangeRateSourceText = filterByValue(sourceItem, exchangeRateSource);

  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1 mt-2"
        label={
          <strong id="exchange-rate">
            {translate(messages.exchange_rate)}
          </strong>
        }
        colon={false}
      />
      <Form.Item
        label={translate(messages.AUTOMATICALLY_UPDATE_EXCHANGE_RATE)}
        {...formItemLayout}
        className="two-rows-label"
      >
        <span>{isExchangeRateON ? 'On' : 'Off'}</span>
      </Form.Item>
      {isExchangeRateON ? (
        <>
          <Form.Item
            label={translate(messages.SOURCE_TEXT)}
            {...formItemLayout}
            className="mb-0"
          >
            <span>{exchangeRateSourceText[0].text || '-'}</span>
          </Form.Item>
          <Form.Item
            label={translate(messages.FREQUENCY_TEXT)}
            {...formItemLayout}
            className="mb-0"
          >
            <span>{frequency || '-'}</span>
          </Form.Item>
        </>
      ) : (
        ''
      )}

      <Form.Item
        label={translate(messages.EXCHANGE_RATE_USDT)}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{setDecimals(exchangeRate, 4) || ''}</span>
      </Form.Item>
      <Form.Item
        label={translate(messages.SPREAD_TEXT)}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{setDecimals(exchangeRateSpread, 4) || '-'}</span>
      </Form.Item>
      <Form.Item
        label={translate(messages.MINIMUM_NUMBER_OF_CONFIRMATIONS)}
        {...formItemLayout}
        className="two-rows-label"
      >
        {minimumConfirmationsCount || ''}
      </Form.Item>
    </>
  );
};

export default ExchangeRateInformation;
