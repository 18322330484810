import gql from 'graphql-tag';

export const MESSAGES = gql`
  query Messages($filter: MessagesFilterInput, $after: Binary, $first: Int) {
    messages(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          totalReads
          totalRecipients
          schedule
          content
          submitted
          dateTimeUpdated
          dateTimeSubmitted
          creator {
            id
            role
            username
          }
          targetMembers {
            id
            username
          }
          targetMemberLevels {
            id
            name
            color
          }
          targetMemberLoyaltyLevels {
            id
            name
            color
            programme {
              id
              name
            }
          }
          excludedMembers {
            id
            username
          }
          excludedMemberLevels {
            id
            name
            color
          }
          excludedMemberLoyaltyLevels {
            id
            name
            color
            programme {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const MULT_QUERIES = gql`
  query(
    $value: String!
    $withMarkerListPermission: Boolean!
    $withMemberListPermission: Boolean!
    $withVIPListPermission: Boolean!
  ) {
    memberLevels(filter: { name: { startsWith: $value } }, first: 15)
      @include(if: $withMarkerListPermission) {
      edges {
        node {
          status
          color
          default
          id
          name
        }
      }
    }

    members(filter: { username: { startsWith: $value } }, first: 15)
      @include(if: $withMemberListPermission) {
      edges {
        node {
          id
          username
        }
      }
    }

    memberLoyaltyLevels(filter: { name: { startsWith: $value } }, first: 15)
      @include(if: $withVIPListPermission) {
      edges {
        node {
          id
          name
          color
          programme {
            id
            name
          }
        }
      }
    }

    memberLoyaltyProgrammes(
      filter: { name: { startsWith: $value } }
      first: 15
    ) @include(if: $withVIPListPermission) {
      edges {
        node {
          id
          name
          levels {
            id
            name
            color
          }
        }
      }
    }
  }
`;
