import React from 'react';
import styled from 'styled-components';
import { WhitelistIPAddress } from './WhitelistIPAddress/WhitelistIPAddress';
import { UploadAttachment } from './UploadAttachment/UploadAttachment';
import { ClientDetails } from './ClientDetails/ClientDetails';

// eslint-disable-next-line arrow-body-style
export const AccountBody: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <div style={{ width: '616px', marginRight: '8px' }}>
          <WhitelistIPAddress />
          <UploadAttachment />
        </div>

        <div style={{ width: '320px' }}>
          <div style={{ height: '656px' }}>
            <ClientDetails />
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 944px;
  margin: 16px auto;
`;

const Wrapper = styled.div`
  .ant-card {
    /*  for general layout */
    transform: translateY(2px);

    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .ant-typography {
      font-size: 12px;
    }

    .head-typography {
      font-size: 14px !important;
    }

    .ant-card-extra {
      font-size: 14px;
    }

    .ant-card-head-wrapper {
      height: 100%;
    }

    .ant-card-head {
      background: #f0f0f0;
      min-height: 0px;
      height: 37.5px;
      padding: 0px 16px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom: 0 !important;
    }

    .ant-card-body {
      padding: 10px 16px 37px 16px !important;
      border: 1px #f0f0f0 solid;
      border-top: none;
    }

    .ant-slider-with-marks {
      margin-bottom: 0px;
    }
    .ant-divider-horizontal {
      margin: 15px 0px 0px 0;
    }

    /*  for collapse */
    .ant-collapse {
      background: transparent;
      .ant-collapse-item {
        border-bottom: 1px solid #e8e8e8;
      }

      .ant-collapse-content-box {
        padding: 0px 1px 4px 0px;
      }

      .arrow-icon {
        background: #f0f2f5;
        width: 18px;
        height: 18px;
        border-radius: 10px;
        right: 0px;
        top: 17px;

        .anticon-right {
          padding-top: 2px;
          padding-right: 2px;
        }
      }

      .ant-collapse-header {
        padding: 6px 0px 0px 0px;
        margin-bottom: 8px;
        .ant-collapse-content-box {
          padding: 0px;
        }
      }
    }
  }
`;
