import React from 'react';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import { StyledContent, StyledRow } from './styles';
import messages from './messages';

interface Props {
  tax: number;
  handling: number;
  promoDeduction: number;
  limit: number;
}
const WithdrawalFees = ({ tax, handling, promoDeduction, limit }: Props) => {
  const translate = useTranslate();
  const { currency } = useConfig();
  return (
    <StyledContent>
      <StyledRow>
        <div>{translate(messages.withdrawalText)}</div>
        <div>
          {currency?.symbol}
          {tax}
        </div>
      </StyledRow>
      <StyledRow>
        <div>{translate(messages.handlingFee)}</div>
        <div>
          {currency?.symbol}
          {handling}
        </div>
      </StyledRow>
      <StyledRow>
        <div>{translate(messages.promoDeduction)}</div>
        <div>
          {currency?.symbol}
          {promoDeduction}
        </div>
      </StyledRow>
      <StyledRow>
        <div>{translate(messages.withdrawalLimitFee)}</div>
        <div>
          {currency?.symbol}
          {limit}
        </div>
      </StyledRow>
    </StyledContent>
  );
};

export default WithdrawalFees;
