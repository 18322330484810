import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import copyToClipboard from 'utils/copyToClipboard';
import moment from 'moment';
import { getBank, messages } from 'constants/banks';
import { ALink } from 'components/ALink/ALink';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import {
  Deposit,
  ManualAdjustmentDeposit,
  ManualDeposit,
  OfflineBankTransferDeposit,
  AlipayDeposit,
  WechatDeposit,
  OnlineAlipayDeposit,
  OnlineWechatDeposit,
  OnlineBankTransferDeposit,
  CryptocurrencyDeposit,
  BankAccount,
  Bank,
} from 'types/graphqlTypes';
import { DATE_FORMAT } from 'constants/date';

interface PaymentMethodType {
  bank: Bank;
  accountName: String;
}
interface IProps {
  deposit: CryptocurrencyDeposit &
    OnlineBankTransferDeposit &
    Deposit &
    ManualDeposit &
    OfflineBankTransferDeposit &
    AlipayDeposit &
    WechatDeposit &
    OnlineAlipayDeposit &
    OnlineWechatDeposit &
    ManualAdjustmentDeposit &
    BankAccount & {
      paymentMethod: PaymentMethodType;
    };
  readOnly: boolean;
}
const BankPaymentMethodDetails = (props: IProps) => {
  const { deposit, readOnly } = props;
  const {
    paymentMethod,
    transactionReference,
    serialCode,
    dateTimeProcessed,
    dateTimeCreated,
    processor,
    depositSource,
    depositorBank,
    depositorName,
    status,
    sourceBank,
    manualAdjustment,
  } = deposit;

  const lang = useIntl().locale;

  // const { bank, accountName, name } = paymentMethod;
  const bank = paymentMethod?.bank;
  const accountName = paymentMethod?.accountName;
  const { Text } = Typography;

  const depositorBankInfo = getBank(sourceBank);
  const bankInfo = getBank(bank);

  const translate = useTranslate();

  return (
    <>
      <div data-testid="bank-details">
        <div
          className="menu-header p-2 mt-4"
          data-testid="bankPaymentMethodDetails"
        >
          <FormattedMessage
            id="bank-deposit-details.text"
            defaultMessage="Bank deposit details"
          />
        </div>
        <div className="d-flex p-2 bb-1">
          <div>
            <Text type="secondary">
              <FormattedMessage
                id="bank-name.text"
                defaultMessage="Bank name"
              />
            </Text>
          </div>
          {bankInfo ? (
            <div className="flex-1 text-right">
              <span>
                {lang === 'en' ? bank : translate(messages[bankInfo.label])}
              </span>
              <ALink disabled={readOnly} onClick={() => copyToClipboard(bank)}>
                <CopyOutlined />
              </ALink>
            </div>
          ) : (
            <div className="flex-1 text-right">
              {manualAdjustment ? 'Manual Adjustment' : '-'}
            </div>
          )}
        </div>
        <div className="d-flex p-2 bb-1">
          <div>
            <Text type="secondary">
              <FormattedMessage
                id="account-name.text"
                defaultMessage="Account name"
              />
            </Text>
          </div>
          <div className="flex-1 text-right">
            {accountName || manualAdjustment ? 'Manual Adjustment' : '-'}{' '}
            {accountName && (
              <ALink
                disabled={readOnly}
                onClick={() => copyToClipboard(accountName)}
                data-testid="copyTestID"
              >
                <CopyOutlined />
              </ALink>
            )}
          </div>
        </div>
        <div className="d-flex p-2 bb-1">
          <div>
            <Text type="secondary">
              <FormattedMessage
                id="referral-code.text"
                defaultMessage="Referral code"
              />
            </Text>
          </div>
          <div className="flex-1 text-right">
            {serialCode || '-'}{' '}
            {serialCode && (
              <ALink
                disabled={readOnly}
                onClick={() => copyToClipboard(serialCode)}
              >
                <CopyOutlined />
              </ALink>
            )}
          </div>
        </div>
        <div className="d-flex p-2 bb-1">
          <div>
            <Text type="secondary">
              <FormattedMessage
                id="processing-time.text"
                defaultMessage="Processing time"
              />
            </Text>
          </div>
          <div className="flex-1 text-right">
            {status === 'EXPIRED' && '-'}
            {(status === 'APPROVED' || status === 'REJECTED') && (
              <>
                {moment(dateTimeProcessed).format(`${DATE_FORMAT} (ddd)`)}
                <br />
                {moment(dateTimeProcessed).format('HH:mm:ss')} -{' '}
                {moment(dateTimeProcessed).from(dateTimeCreated, true)}
              </>
            )}
            {(status === 'PROCESSING' || status === 'PENDING') && (
              <>
                {moment(dateTimeCreated).format(`${DATE_FORMAT} (ddd)`)}
                <br />
                {moment(dateTimeCreated).format('HH:mm:ss')} -{' '}
                {moment(dateTimeCreated).fromNow()}
              </>
            )}
          </div>
        </div>
        <div className="d-flex p-2 bb-1">
          <div>
            <Text type="secondary">
              <FormattedMessage id="operator.text" defaultMessage="Operator" />
            </Text>
          </div>
          <div className="flex-1 text-right">
            {processor?.username}
            {/* {coercedGet(processor, 'username', '-')} */}
          </div>
        </div>
      </div>
      <div data-testid="depositor-bank-details">
        <div className="menu-header p-2 mt-4">
          <FormattedMessage
            id="depositor-bank-details.text"
            defaultMessage="Depositor bank details"
          />
        </div>
        <div className="d-flex p-2 bb-1">
          <div>
            <Text type="secondary">
              <FormattedMessage
                id="depositor-name.text"
                defaultMessage="Depositor name"
              />
            </Text>
          </div>
          <div className="flex-1 text-right">
            {depositorName || '-'}{' '}
            {depositorName && (
              <ALink
                disabled={readOnly}
                onClick={() => copyToClipboard(depositorName)}
              >
                <CopyOutlined />
              </ALink>
            )}
          </div>
        </div>
        <div className="d-flex p-2 bb-1">
          <div>
            <Text type="secondary">
              <FormattedMessage
                id="deposit-source.text"
                defaultMessage="Deposit Source"
              />
            </Text>
          </div>
          <div className="flex-1 text-right">
            {depositSource ? (
              <>
                <img
                  height={15}
                  width={15}
                  src={coercedGet(depositSource, 'logo.uri', '')}
                  alt="logo"
                  className="mr-1"
                />
                <span>{coercedGet(depositSource, 'displayName', '')} </span>
                <ALink
                  disabled={readOnly}
                  onClick={() =>
                    copyToClipboard(
                      coercedGet(depositSource, 'displayName', '')
                    )
                  }
                >
                  <CopyOutlined />
                </ALink>
              </>
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className="d-flex p-2 bb-1">
          <div>
            <Text type="secondary">
              <FormattedMessage
                id="bank-name.text"
                defaultMessage="Bank name"
              />
            </Text>
          </div>
          {depositorBankInfo ? (
            <div className="flex-1 text-right">
              <img
                height={15}
                width={15}
                src={String(depositorBankInfo.logo)}
                alt="logo"
                className="mr-1"
              />
              {depositorBankInfo.label !== 'OTHER'
                ? `[${depositorBankInfo.label}] `
                : ''}
              {translate(messages[depositorBankInfo.label])}{' '}
              <ALink
                disabled={readOnly}
                onClick={() => copyToClipboard(depositorBank)}
              >
                <CopyOutlined />
              </ALink>
            </div>
          ) : (
            <div className="flex-1 text-right">-</div>
          )}
        </div>
        {transactionReference && transactionReference.length >= 15 ? (
          <>
            <div className="d-flex pl-2 pr-2 pt-2">
              <div>
                <Text type="secondary">
                  <FormattedMessage
                    id="transaction-reference.text"
                    defaultMessage="Transaction reference"
                  />
                </Text>
              </div>
              <div className="flex-1 text-right">
                {transactionReference ? (
                  <ALink
                    disabled={readOnly}
                    onClick={() => copyToClipboard(transactionReference)}
                  >
                    <CopyOutlined />
                  </ALink>
                ) : (
                  '-'
                )}
              </div>
            </div>

            <div className="flex-1 bb-1 pl-2 pb-2">
              {transactionReference || ''}
            </div>
          </>
        ) : (
          <div className="d-flex bb-1 p-2">
            <div>
              <Text type="secondary">
                <FormattedMessage
                  id="transaction-reference.text"
                  defaultMessage="Transaction reference"
                />
              </Text>
            </div>
            <div className="flex-1 text-right">
              {transactionReference || '-'}{' '}
              {transactionReference && (
                <ALink
                  disabled={readOnly}
                  onClick={() => copyToClipboard(transactionReference)}
                >
                  <CopyOutlined />
                </ALink>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BankPaymentMethodDetails;
