import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from '../../../../../../../../components/ALink/ALink';

type Props = {
  activeFeeInput: string;
  fieldInput: string;
  setActiveFeeInput: (data: string) => void;
  disabled: boolean;
};

const EditSaveButton = ({
  activeFeeInput,
  fieldInput,
  setActiveFeeInput,
  disabled,
}: Props) => (
  <>
    <ALink
      disabled={disabled}
      onClick={() => setActiveFeeInput(fieldInput)}
      style={{
        display: activeFeeInput === fieldInput ? 'none' : 'inline',
      }}
    >
      <FormattedMessage id="edit.text" defaultMessage="Edit" />
    </ALink>
    <ALink
      disabled={disabled}
      style={{
        display: activeFeeInput === fieldInput ? 'inline' : 'none',
      }}
    >
      <FormattedMessage id="save.text" defaultMessage="Save" />
    </ALink>
  </>
);

export default EditSaveButton;
