import { useQuery } from '@apollo/react-hooks';
import { ErrorHandler } from 'components/ErrorHandler';
import Layout from 'components/Layout';
import { useFilterValues } from 'contexts/Filters';
import { GET_MEMBER_INTERACTION_LOGS } from 'graphql/queries/memberInteractionLog.query';
import { Page } from 'interfaces/user.interface';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SearchUserIcon } from 'components/assets/search-user.svg';
import SharedStyledContent from 'styles/SharedStyledContent';
import { SharedStyledLayout } from 'styles/SharedStyledLayout';
import { useScreenTabV2 } from 'store/screenTabState';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { getPollInterval } from 'utils/pollInterval';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import removeNull from 'utils/removeNull';
import { isEmpty } from 'lodash';
import { Moment } from 'moment';
import { StyledBlankContainer, StyledSearchButton } from '../../styles';
import HeaderLeft from '../HeaderLeft';
import HeaderRight from '../HeaderRight';
import MemberInteractionLogTable from '../MemberInteractionLogTable';
import SearchMember from '../SearchMember';
import SideBar from '../Sidebar';

type SearchInfo = {
  filterFieldName: string;
  memberId: string | null;
  username: string | null;
  action: string | null;
  actor: string | null;
  module: string | null;
  dateTimeCreated: {
    gte: Moment | string;
    lte: Moment | string;
  } | null;
};

type Props = {
  defaultSearchInfo: Partial<SearchInfo>;
  filter: object;
  refreshPage: () => void;
};

const MemberInteractionLogScreen: React.FC<Props> = ({
  defaultSearchInfo,
  filter,
  refreshPage,
}) => {
  const translate = useTranslate();
  const { isTabActive, updateTab } = useScreenTabV2();
  const [tableState, setTableState] = useState(defaultSearchInfo);
  const [showSearch, setShowSearch] = useState(false);

  const [selectedFilterField, setSelectedFilterField] = useState(
    defaultSearchInfo.filterFieldName
  );

  const toggleSearch = () => setShowSearch((prev) => !prev);

  useEffect(() => {
    updateTab({
      id: 'member-interaction-log-report',
      updates: {
        searchRef: {
          filterFieldName: selectedFilterField,
          ...tableState,
        },
        filter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState]);

  const [page, setPage] = useState<Page>({
    first: 10,
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
  });

  const [sidebar, toggleSidebar] = useState(true);

  const handleToggleSidebar = () => {
    toggleSidebar((prev) => !prev);
  };

  const { filters } = useFilterValues();

  const isEmptyFilters = isEmpty(removeNull(filters));

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: transformMovableDateFilter(filters),
  };
  const { context, setOnPoll } = useOperatorHeader();
  const { loading, data } = useQuery(GET_MEMBER_INTERACTION_LOGS, {
    fetchPolicy: 'network-only',
    variables: refetchVariables,
    pollInterval: getPollInterval({
      isActive: isTabActive('member-interaction-log-report') && !isEmptyFilters,
      isRealtime: true,
    }),
    skip: isEmptyFilters,
    context,
    onCompleted: () => {
      if (!isEmptyFilters) {
        setOnPoll(true);
      }
    },
  });

  const { memberInteractionLogs = {} }: any = data || {};
  const { totalCount, pageInfo, edges = [] } = memberInteractionLogs;
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  return (
    <>
      <SearchMember
        setSearchInfo={setTableState}
        searchInfo={tableState}
        setShowSearch={setShowSearch}
        selectedFilterFieldState={{
          state: selectedFilterField,
          setState: setSelectedFilterField,
        }}
        visible={showSearch}
      />
      {!isEmptyFilters ? (
        <Layout.Container>
          <>
            <Layout.Header
              leftNode={
                <HeaderLeft
                  sidebarStatus={sidebar}
                  toggleSidebar={handleToggleSidebar}
                />
              }
              rightNode={
                <HeaderRight
                  refreshPage={refreshPage}
                  tableState={tableState}
                  selectedFilter={selectedFilterField}
                  toggleSearchMember={toggleSearch}
                  filters={removeNull(filters)}
                />
              }
            />
            <Layout.Content
              key="69"
              sideBar={<SideBar open={sidebar} />}
              footer={
                <Layout.Footer
                  leftNode={<div />}
                  loading={loading}
                  onNext={handleNext}
                  onPrev={handlePrev}
                  page={page}
                  resultsCount={totalCount}
                  totalPage={totalPage}
                  setPage={setPage}
                  pageInfo={pageInfo}
                />
              }
            >
              <ErrorHandler>
                <MemberInteractionLogTable
                  dataSource={edges}
                  loading={loading}
                  refetchVariables={refetchVariables}
                />
              </ErrorHandler>
            </Layout.Content>
          </>
        </Layout.Container>
      ) : (
        <SharedStyledLayout style={{ height: 'calc(100vh - 40px - 45px)' }}>
          <SharedStyledContent style={{ height: 'fit-content' }}>
            <StyledBlankContainer>
              <SearchUserIcon />
              <StyledSearchButton onClick={toggleSearch} type="primary">
                {translate(messages.SEARCH_USER)}
              </StyledSearchButton>
            </StyledBlankContainer>
          </SharedStyledContent>
        </SharedStyledLayout>
      )}
    </>
  );
};

export default MemberInteractionLogScreen;
