import React from 'react';
import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

type StyledInputGroupType = {
  children?: React.ReactElement;
  className?: string;
  rest?: Record<string, any>;
};

export const StyledInputGroup = ({
  children,
  className,
  ...rest
}: StyledInputGroupType) => (
  <div className={`${'my-2 '}${className || ''}`} {...rest}>
    {children}
  </div>
);

export default {};
