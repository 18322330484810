import styled from 'styled-components';
import { Modal, Button } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

export const StyledButton = styled(Button)`
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  padding: 0;
  line-height: 22px;
`;

export default {};
