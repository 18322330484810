import React, { useEffect, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';

type Props = {
  expandedTags?: boolean;
  toggleExpandedTags?: Function;
  filters: object;
  forwardedRef: any;
};

const LegacyToggleIcon = ({
  expandedTags,
  toggleExpandedTags,
  filters,
  forwardedRef,
}: Props) => {
  const [legacyIconShow, setLegacyIconShow] = useState(false);

  useEffect(() => {
    const height = forwardedRef.current?.offsetHeight;
    const scrollHeight = forwardedRef.current?.scrollHeight;

    if (scrollHeight! > height!) {
      setLegacyIconShow(true);
    } else {
      setLegacyIconShow(false);
      toggleExpandedTags!(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <>
      {legacyIconShow && (
        <LegacyIcon
          type={expandedTags ? 'double-left' : 'double-right'}
          onClick={() => toggleExpandedTags!((prev: boolean) => !prev)}
          className="mr-2 mt-2"
        />
      )}
    </>
  );
};

export default LegacyToggleIcon;
