/* eslint-disable no-shadow */

import { CheckCircleOutlined } from '@ant-design/icons';
import { Spin, Table, Tooltip, Typography } from 'antd';
import IpAddressLocationTableCell from 'components/IpAddressLocationTableCell';
import NextPrevButton from 'components/NextPrevButton';
import { DATE_TIME_FORMAT } from 'constants/date';
import { useFilterValues } from 'contexts/Filters';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import {
  GET_MAHR,
  GET_MAHR_ID_BASED,
  GET_MAHR_STRING_BASED,
} from 'graphql/queries/memberAccessAuditHistory.query';
import { GraphqlData } from 'interfaces/user.interface';
import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import { isEmpty, partial } from 'lodash';
import messages from 'messages';
import * as moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import TruncateToolTip from 'components/TruncateToolTip';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import {
  MemberAccessHistoryReportItem,
  MemberAccessHistoryReportItemsConnectionEdge,
  MemberLoyaltyLevel,
} from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import compose from 'utils/compose';
import {
  impartMoveableDate,
  transformMovableDateFilter,
} from 'utils/dateUtils';
import { OriginalColumn } from 'store/customColumnState/types';
import { isVipVisible } from 'utils/isVipVisible';
import esGet from 'utils/esGet';
import { createPartialUtil } from 'utils/partialUtils';
import removeNull from 'utils/removeNull';
import { columnAlign } from 'utils/tableAlignment';
import { useTablePagination } from 'utils/usePagination';
import useTranslate from 'utils/useTranslate';
import MemberLevelButton from 'components/MemberLevelButton';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import AffiliateStatus from './components/AffiliateStatus';
import LabelTags from './components/LabelTags';
import MemberProfile from './components/MemberProfile';
import ResizableTitle from './components/ResizableTitle';
import TableFooterPages from './components/TableFooterPages';
import { StyledCenter, StyledTableContainer } from './styles';
import { collateMemberFilterFields } from './utils';
import TableHeader from '../TableHeader';
import { StyledContent } from '../../styles';
import Sidebar from '../Sidebar';

const RecordsTable = React.forwardRef((__props, ref) => {
  const { setTableColumns } = useSpreadsheetContext();

  const { bypassedCurrentTimezone } = useReportsTimezone() as any;
  const translate = useTranslate();

  const { filters } = useFilterValues();

  const transformedFilters = transformMovableDateFilter({
    dateTimeCreated: {
      gte: coercedGet(filters, 'dateTimeCreated.gte', null),
      lte: coercedGet(filters, 'dateTimeCreated.lte', null),
    },
  }) as any;

  const { dateTimeCreated } = transformedFilters;

  const impartDateTimeCreated = partial(
    impartMoveableDate,
    'dateTimeCreated',
    dateTimeCreated
  );

  const [
    page,
    dispatchPagination,
    actions,
    defaultInitState,
  ] = useTablePagination({
    first: 10,
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
  });

  const idQueries = {
    query: GET_MAHR_ID_BASED,
    sourceFiltFields: ['memberUserName', 'memberRealName'],
    targetStringFields: ['username', 'realName'],
    targetFilterFields: ['member', 'member'],
  };

  const filtProcessorFunc = compose(
    removeNull,
    collateMemberFilterFields,
    impartDateTimeCreated
  );

  const usePartialFilterUtil = createPartialUtil('id', filtProcessorFunc);
  const { locale } = useIntl();
  const {
    loading,
    data,
    refetchVariables,
    refetch,
    finalQueryFilter,
  } = usePartialFilterUtil(
    GET_MAHR_STRING_BASED,
    GET_MAHR,
    'memberAccessHistoryReport.edges',
    filters,
    page,
    ['ipAddress'],
    idQueries,
    locale.toUpperCase()
  );

  React.useImperativeHandle(ref, () => ({
    refetchTable: refetch,
  }));

  const impartResponsiveWidth = React.useCallback(
    (value) => ({ width: value } || {}),
    []
  );

  const columnFields = [
    {
      csvData: {
        label: translate(messages.USERNAME),
        key: 'username',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'member.username', '-'),
      },
      title: translate(messages.USERNAME),
      align: columnAlign.onCenter,
      key: 'username',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) => (
        <MemberProfile member={node.member} />
      ),
    },
    {
      csvData: {
        label: translate(messages.REAL_NAME),
        key: 'realName',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'member.realName', '-'),
      },

      title: translate(messages.REAL_NAME),
      align: columnAlign.onCenter,
      key: 'realName',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'member.realName', '-'),
    },
    {
      csvData: {
        label: translate(messages.AFFILIATE),
        key: 'affiliate',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'member.affiliate.code', '-'),
      },

      title: translate(messages.AFFILIATE),
      align: columnAlign.onCenter,
      key: 'affiliate',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) => (
        <AffiliateStatus node={node} />
      ),
    },
    {
      csvData: {
        label: translate(messages.ACCOUNT_BALANCE),
        key: 'accountBalance',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'member.totalBalance', '-'),
      },
      title: translate(messages.ACCOUNT_BALANCE),
      align: columnAlign.onCenter,
      key: 'accountBalance',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'member.totalBalance', '-'),
    },
    {
      csvData: {
        label: translate(messages.BRAND_ID),
        key: 'brandId',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'member.brandId', '-'),
      },
      title: translate(messages.BRAND_ID),
      align: columnAlign.onCenter,
      key: 'brandId',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'member.brandId', '-'),
    },
    {
      csvData: {
        label: 'Platform ID',
        key: 'platformId',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'member.platformId', '-'),
      },
      title: 'Platform ID',
      align: columnAlign.onCenter,
      key: 'platformId',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'member.platformId', '-'),
    },
    {
      csvData: {
        label: translate(messages.TOTAL_LIFETIME_EFFECTIVE_BET),
        key: 'effectiveBet',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'member.totalEffectiveBet', '-'),
      },

      title: translate(messages.TOTAL_LIFETIME_EFFECTIVE_BET),
      align: columnAlign.onCenter,
      key: 'effectiveBet',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'member.totalEffectiveBet', '-'),
    },
    {
      csvData: {
        label: translate(messages.LABELS_REMARKS),
        key: 'labelsRemark',
        renderCell: () => '-',
      },

      title: translate(messages.LABELS_REMARKS),
      align: columnAlign.onCenter,
      key: 'labelsRemark',
      ...impartResponsiveWidth(140),
      // eslint-disable-next-line arrow-body-style
      render: (node: MemberAccessHistoryReportItem) => {
        // console.log(node);
        return (
          <LabelTags
            member={coercedGet(node, 'member', {})}
            refetchParams={refetchVariables}
          />
        );
      },
    },
    {
      csvData: {
        label: translate(messages.MEMBER_MARKER),
        key: 'memberMarker',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'member.memberLevel.name', '-'),
      },

      title: translate(messages.MEMBER_MARKER),
      align: columnAlign.onCenter,
      key: 'memberMarker',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) => (
        <MemberLevelButton memberLevelData={node.memberLevel!} />
      ),
    },
    {
      ...(Boolean(isVipVisible) && {
        csvData: {
          label: translate(messages.VIP_LEVEL),
          key: 'vipLevels',
          renderCell: (node: {
            member: { memberLoyaltyLevels: MemberLoyaltyLevel[] };
          }) => {
            const loyaltyLevels = esGet<MemberLoyaltyLevel[]>(
              node.member.memberLoyaltyLevels,
              []
            );

            return loyaltyLevels.length
              ? loyaltyLevels.map(
                  ({ programme, name }) => `${programme?.name} - ${name}`
                )
              : '-';
          },
        },
        title: translate(messages.VIP_LEVEL),
        align: columnAlign.onCenter,
        key: 'vipLevels',
        ...impartResponsiveWidth(140),
        render: (node: MemberAccessHistoryReportItem) =>
          node.member.memberLoyaltyLevels?.length ? (
            <MemberLoyaltyTagList
              loyaltyLevels={node.member.memberLoyaltyLevels}
            />
          ) : (
            '-'
          ),
      }),
    },
    {
      csvData: {
        label: translate(messages.REGISTRATION_INDICATOR),
        key: 'registrationIndicator',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'registration', null)
            ? translate(messages.YES)
            : translate(messages.NO),
      },

      title: translate(messages.REGISTRATION_INDICATOR),
      align: columnAlign.onCenter,
      key: 'registrationIndicator',
      dataIndex: 'registration',
      ...impartResponsiveWidth(140),
      render: (reg: MemberAccessHistoryReportItem) =>
        reg ? (
          <Tooltip
            title={
              <span>
                <FormattedMessage
                  id="reports.member-registration.text"
                  defaultMessage="Member Registration"
                />
              </span>
            }
            className="styled-center"
          >
            <CheckCircleOutlined style={{ fontSize: '20px', color: 'green' }} />
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      csvData: {
        label: translate(messages.LAST_LOGIN_DATE_TIME),
        key: 'lastLoginDateTime',
        renderCell: (node: MemberAccessHistoryReportItem) => {
          const lastLoginDateTime = coercedGet(node, 'dateTimeCreated', null);

          return lastLoginDateTime
            ? moment
                .tz(lastLoginDateTime, bypassedCurrentTimezone)
                .format(`${DATE_TIME_FORMAT} A`)
            : '-';
        },
      },

      title: translate(messages.LAST_LOGIN_DATE_TIME),
      align: columnAlign.onCenter,
      key: 'lastLoginDateTime',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) => {
        const lastLoginDateTime = coercedGet(node, 'dateTimeCreated', null);
        return lastLoginDateTime
          ? moment
              .tz(lastLoginDateTime, bypassedCurrentTimezone)
              .format(`${DATE_TIME_FORMAT} A`)
          : '-';
      },
    },
    {
      csvData: {
        label: translate(messages.LAST_LOGIN_DOMAIN),
        key: 'domain',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'session.domain', '-'),
      },

      title: translate(messages.LAST_LOGIN_DOMAIN),
      align: columnAlign.onCenter,
      key: 'domain',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'session.domain', '-'),
    },

    {
      csvData: {
        label: translate(messages.LAST_LOGIN_IP_ADDRESS),
        key: 'ipAddress',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'ipAddress', null),
      },

      title: translate(messages.LAST_LOGIN_IP_ADDRESS),
      align: columnAlign.onCenter,
      key: 'ipAddress',

      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'ipAddress', null) || '-',
    },

    // ==========================
    {
      csvData: {
        label: translate(messages.LAST_LOGIN_IP_ADDRESS_LOCATION),
        key: 'ipAddressLocation',
        renderCell: (node: MemberAccessHistoryReportItem) => {
          const { city, country, state } = esGet(node.ipAddressLocation, '');
          const location = [city, state, country];
          const locationStr = location.filter(Boolean).join(', ');
          return locationStr ?? '-';
        },
      },

      title: translate(messages.LAST_LOGIN_IP_ADDRESS_LOCATION),
      align: columnAlign.onCenter,
      key: 'ipAddressLocation',

      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) => (
        <IpAddressLocationTableCell location={node.ipAddressLocation} />
      ),
    },
    {
      csvData: {
        label: translate(messages.LAST_LOGIN_USER_AGENT),
        key: 'userAgent',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'session.userAgent', '-'),
      },

      title: translate(messages.LAST_LOGIN_USER_AGENT),
      align: columnAlign.onCenter,
      key: 'userAgent',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) => {
        const userAgent = coercedGet(node, 'session.userAgent', null);
        return userAgent ? (
          <TruncateToolTip maxRows={2} content={userAgent} />
        ) : (
          '-'
        );
      },
    },
    {
      csvData: {
        label: translate(messages.LAST_LOGIN_DEVICE_OS_VERSION),
        key: 'deviceOs',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'session.deviceOs', '-'),
      },

      title: translate(messages.LAST_LOGIN_DEVICE_OS_VERSION),
      align: columnAlign.onCenter,
      key: 'deviceOs',

      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'session.deviceOs', '-'),
    },
    {
      csvData: {
        label: translate(messages.LAST_LOGIN_DEVICE_UDID_UNIQUE_IDENTIFIER),
        key: 'deviceId',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'session.deviceId', '-'),
      },

      title: translate(messages.LAST_LOGIN_DEVICE_UDID_UNIQUE_IDENTIFIER),
      align: columnAlign.onCenter,
      key: 'deviceId',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'session.deviceId', '-'),
    },
    {
      csvData: {
        label: translate(messages.LAST_LOGIN_DEVICE_FINGERPRINT),
        key: 'deviceFingerprint',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'session.deviceFingerprint', '-'),
      },

      title: translate(messages.LAST_LOGIN_DEVICE_FINGERPRINT),
      align: columnAlign.onCenter,
      key: 'deviceFingerprint',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'session.deviceFingerprint', '-'),
    },
    {
      csvData: {
        label: translate(messages.LAST_LOGIN_BROWSER),
        key: 'browser',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'session.browser', '-'),
      },

      title: translate(messages.LAST_LOGIN_BROWSER),
      align: columnAlign.onCenter,
      key: 'browser',

      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) =>
        coercedGet(node, 'session.browser', '-'),
    },
    {
      csvData: {
        label: translate(messages.LAST_LOGIN_CHANNEL),
        key: 'channel',
        renderCell: (node: MemberAccessHistoryReportItem) =>
          coercedGet(node, 'session.channel', '-'),
      },

      title: translate(messages.LAST_LOGIN_CHANNEL),
      align: columnAlign.onCenter,
      key: 'channel',
      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) => {
        const channel = coercedGet(node, 'session.channel', '');
        return channel ? translate(messages[channel], null, channel) : '-';
      },
    },
    {
      csvData: {
        label: translate(messages.SESSION_LOGOUT),
        key: 'dateTimeDestroyed',
        renderCell: (node: MemberAccessHistoryReportItem) => {
          const dateTimeDestroyed = coercedGet(
            node,
            'session.dateTimeDestroyed',
            null
          );

          const expirationDateTime = coercedGet(
            node,
            'session.expirationDateTime',
            null
          );

          const renderSessionLogout = () => {
            if (dateTimeDestroyed)
              return moment
                .tz(dateTimeDestroyed, bypassedCurrentTimezone)
                .format(`${DATE_TIME_FORMAT} A`);

            if (expirationDateTime) {
              const currentDateTime = moment.tz();
              if (currentDateTime.isAfter(expirationDateTime))
                return moment
                  .tz(expirationDateTime, bypassedCurrentTimezone)
                  .format(`${DATE_TIME_FORMAT} A`);

              return '-';
            }

            return '-';
          };

          return renderSessionLogout();
        },
      },

      title: translate(messages.SESSION_LOGOUT),
      align: columnAlign.onCenter,
      key: 'dateTimeDestroyed',

      ...impartResponsiveWidth(140),
      render: (node: MemberAccessHistoryReportItem) => {
        const dateTimeDestroyed = coercedGet(
          node,
          'session.dateTimeDestroyed',
          null
        );

        const expirationDateTime = coercedGet(
          node,
          'session.expirationDateTime',
          null
        );

        const renderSessionLogout = () => {
          if (dateTimeDestroyed)
            return moment
              .tz(dateTimeDestroyed, bypassedCurrentTimezone)
              .format(`${DATE_TIME_FORMAT} A`);

          if (expirationDateTime) {
            const currentDateTime = moment.tz();
            if (currentDateTime.isAfter(expirationDateTime))
              return moment
                .tz(expirationDateTime, bypassedCurrentTimezone)
                .format(`${DATE_TIME_FORMAT} A`);

            return '-';
          }

          return '-';
        };

        return renderSessionLogout();
      },
    },
  ];

  const { totalCount, pageInfo } = coercedGet(
    data,
    'memberAccessHistoryReport',
    {}
  ) as GraphqlData<MemberAccessHistoryReportItem>;
  const totalPage = Math.ceil(totalCount / page.first) || 1;
  const edges = coercedGet(data, 'memberAccessHistoryReport.edges', []);

  const tableData =
    edges.map(
      (edge: MemberAccessHistoryReportItemsConnectionEdge) => edge.node
    ) || [];

  const {
    loading: loadingCC,
    filterColumns,
    customColumns,
  } = useCustomColumnsV2(
    'member-access-audit-history',
    (columnFields as unknown) as OriginalColumn[]
  );

  React.useEffect(() => {
    if (!loadingCC) {
      setTableColumns(filterColumns(columnFields as OriginalColumn[]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCC, filters, finalQueryFilter]);

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  if (isEmpty(customColumns)) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  const isLoading = loading;

  return (
    <>
      <StyledContent>
        <TableHeader
          forwardRef={ref as any}
          finalQueryFilter={finalQueryFilter}
        />
        <div className="d-flex">
          <Sidebar />
          <StyledContent
            className="justify-content-space-between flex-direction-column"
            style={{
              overflowX: 'auto',
            }}
          >
            <StyledTableContainer
              className="table-container"
              style={{ height: 'calc(100vh - 205px)', overflow: 'hidden' }}
            >
              <Table
                columns={filterColumns(columnFields as OriginalColumn[])}
                loading={isLoading}
                size="middle"
                dataSource={tableData}
                pagination={false}
                components={components}
                scroll={{
                  y: 'calc(10px + 50%)',
                  x: 'calc(545px + 50%)',
                }}
              />
            </StyledTableContainer>
            <div>
              <div
                className="d-flex justify-content-space-between align-items-center"
                style={{
                  padding: '1.2rem',
                  height: '2.4vh',
                  background: '#f3f8fb',
                }}
              >
                <div> </div>

                <div className="styled-center">
                  <div className="mr-3">
                    <Typography.Text strong className="mr-1">
                      {totalCount}
                    </Typography.Text>
                    <FormattedMessage
                      id="results-found.text"
                      defaultMessage="Results Found"
                      values={{
                        count: null,
                      }}
                    />
                  </div>

                  <NextPrevButton
                    loading={isLoading}
                    onPrev={() =>
                      dispatchPagination({
                        type: actions.PREV,
                      })
                    }
                    disablePrev={page.currentPage + 1 === 1}
                    onNext={() =>
                      dispatchPagination({
                        type: actions.NEXT,
                        payload: pageInfo,
                      })
                    }
                    disableNext={page.currentPage + 1 === totalPage}
                    label={`${page.currentPage + 1} / ${totalPage}`}
                  />

                  <TableFooterPages
                    first={page.first}
                    setPage={(rowCount) => {
                      dispatchPagination({
                        type: actions.SETSTATE,
                        payload: {
                          ...defaultInitState,
                          first: rowCount,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </StyledContent>
        </div>
      </StyledContent>
    </>
  );
});

export default RecordsTable;
