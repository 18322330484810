import { useLazyQuery } from '@apollo/react-hooks';
import { Spin, Tooltip, Typography } from 'antd';
import { MEMBER_MANAGEMENT } from 'constants/testIds';
import { GET_MEMBERS_BALANCE } from 'graphql/queries/member.query';
import { useConfig } from 'hooks/useConfig';
import useWindowWidth from 'pages/components/common/hooks/useWindowWidth';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import esGet from 'utils/esGet';

function AccountSum(props: IAccountSum) {
  const { filter, page } = props;
  const IsSmallScreenSize = useWindowWidth() <= 1070;
  const { currency, addCurrency } = useConfig();

  const [loadMemberBalance, { data, loading, error }] = useLazyQuery(
    GET_MEMBERS_BALANCE,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    loadMemberBalance({
      variables: {
        filter,
        first: page.first,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filter]);

  if (loading)
    return (
      <div className="styled-center">
        <Spin />
      </div>
    );

  if (error) {
    return (
      <div>
        <FormattedMessage
          id="error-fetching-balance.text"
          defaultMessage="Error fetching balance"
        />
      </div>
    );
  }

  const totalMemberBalance = {
    accountBalance: esGet(data?.members?.totalAccountBalance, 0),
    totalEffectiveBet: esGet(data?.members?.totalEffectiveBet, 0),
    totalDepositApproved: esGet(data?.members?.totalDepositApproved, 0),
    totalDepositRequested: esGet(data?.members?.totalDepositRequested, 0),
  };

  const totalBalance = {
    accountBalance: totalMemberBalance.accountBalance,
    totalEffectiveBet: totalMemberBalance.totalEffectiveBet,
    totalDepositApproved: totalMemberBalance.totalDepositApproved,
    totalDepositRequested: totalMemberBalance.totalDepositRequested,
  };

  const text = { style: { fontSize: '' } };

  if (IsSmallScreenSize) {
    text.style.fontSize = '0.8em';
  }

  return (
    <div className="styled-center" style={{ maxWidth: '60%' }}>
      <Tooltip title="Account Balance Sum">
        <div className="mr-3 text-truncate">
          <Typography.Text {...text}>
            <FormattedMessage
              id="members.account-balance-sum.text"
              defaultMessage="Account Balance Sum"
            />{' '}
            ({currency?.symbol})
          </Typography.Text>
          <Typography.Text
            className=" d-block"
            strong
            data-testid={MEMBER_MANAGEMENT.accountBalanceSum}
          >
            {addCurrency(totalBalance?.accountBalance, 2)}
          </Typography.Text>
        </div>
      </Tooltip>

      <Tooltip title="Total Effective Bet Sum">
        <div className="mr-3 text-truncate">
          <Typography.Text {...text}>
            <FormattedMessage
              id="members.total-effective-bet-sum.text"
              defaultMessage="Total Effective Bet Sum"
            />{' '}
            ({currency?.symbol})
          </Typography.Text>
          <Typography.Text
            className="d-block"
            strong
            data-testid={MEMBER_MANAGEMENT.totalEffectiveBetSum}
          >
            {addCurrency(totalBalance?.totalEffectiveBet, 2)}
          </Typography.Text>
        </div>
      </Tooltip>

      <Tooltip title="Total Deposit Approved">
        <div className="mr-3 text-truncate">
          <Typography.Text {...text}>
            <FormattedMessage
              id="total-deposit-approved.text"
              defaultMessage="Total Deposit Approved"
            />{' '}
            ({currency?.symbol})
          </Typography.Text>
          <Typography.Text className=" d-block" strong>
            {addCurrency(totalBalance?.totalDepositApproved, 2)}
          </Typography.Text>
        </div>
      </Tooltip>

      <Tooltip title="Total Deposit Requested">
        <div className="mr-3 text-truncate">
          <Typography.Text {...text}>
            <FormattedMessage
              id="total-deposit-requested.text"
              defaultMessage="Total Deposit Requested"
            />{' '}
            ({currency?.symbol})
          </Typography.Text>
          <Typography.Text className=" d-block" strong>
            {addCurrency(totalBalance?.totalDepositRequested, 2)}
          </Typography.Text>
        </div>
      </Tooltip>
    </div>
  );
}

interface IAccountSum {
  filter: {};
  page: { first: number };
}

export default AccountSum;
