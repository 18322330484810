import React, { useEffect, useState } from 'react';
import { Button, Divider, Typography } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { GeoFenceConfig } from 'types/graphqlTypes';
import { GEOFENCING_CONFIG } from 'graphql/queries/geofencingConfig.query';
import { capitalize } from 'lodash';
import GeoFencingTable from './GeoFencingTable/GeoFencingTable';
import SetupGeoFencing from './SetupGeoFencing/SetupGeoFencing';
import GeoLocation from '../GeoFencing/components/GeoLocation/GeoLocation';

const GeoFencingNew = () => {
  const [showEdit, setShowEdit] = useState<boolean>(false);

  const [loadGeoConfig, { data, loading }] = useLazyQuery<{
    geoFenceConfig: GeoFenceConfig;
  }>(GEOFENCING_CONFIG, {
    fetchPolicy: 'cache-and-network',
    variables: {
      language: 'EN',
      type: 'FRONTEND',
    },
  });

  const { geoFenceConfig } = data || {};

  useEffect(() => {
    loadGeoConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEdit]);

  return (
    <div
      className="p-3 d-flex flex-column"
      style={{
        height: '100%',
      }}
    >
      <div className="d-flex flex-column justify-content-start mb-3">
        <div>
          <Divider>
            <Typography.Title
              style={{
                margin: 0,
              }}
              level={3}
            >
              Geo-Fencing
            </Typography.Title>
          </Divider>
        </div>

        <div className="d-flex justify-content-between">
          <Typography.Title level={5}>
            Active Rule: {capitalize(geoFenceConfig?.activeRule)}
          </Typography.Title>
          <Button
            className="mb-2"
            type="primary"
            onClick={() => setShowEdit((prev) => !prev)}
            disabled={loading}
          >
            Edit
          </Button>
        </div>

        <GeoFencingTable geoFenceConfig={geoFenceConfig} loading={loading} />
      </div>

      <div>
        <div>
          <Divider className="mb-3">
            <Typography.Title
              style={{
                margin: 0,
              }}
              level={3}
            >
              Geo-Location
            </Typography.Title>
          </Divider>
        </div>
        <GeoLocation />
      </div>

      {showEdit && (
        <SetupGeoFencing
          drawerState={{
            show: showEdit,
            setShow: setShowEdit,
          }}
          geoFenceConfig={geoFenceConfig}
        />
      )}
    </div>
  );
};

export default GeoFencingNew;
