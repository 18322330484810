import { Select } from 'antd';
import gql from 'graphql-tag';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';
import { MemberLoyaltyLevel } from 'types/graphqlTypes';

// --- Styles
export const itemStyling = (value: any) =>
  value.reduce(
    (acc: string, item: any) =>
      item
        ? `${acc}
          &[title='${item.name}'] {
            position: relative;
            background-color: ${item.color};
            border-color: ${item.color};
            border-radius: 11px;
            color: #fff;
            transform: translateY(-2px);

            .anticon-close {
              color: #fff;;
            }
          }
  `
        : acc,
    ''
  );

type Props = {
  activeItems: Array<MemberLoyaltyLevel | undefined>;
};

export const StyledSelect = styled(Select)<Props>`
  width: 100%;

  .ant-select-selection--single {
    height: 37px;
  }

  .ant-select-selection-selected-value {
    transform: translateY(2px);
  }

  .ant-select-selection li.ant-select-selection__choice {
    ${({ activeItems }) => (activeItems ? itemStyling(activeItems) : '')}
  }
`;

export const StyledNewVIPSelect = styled(Select)<{
  tier?: MemberLoyaltyLevel & string;
}>`
  .ant-select-selection--single {
    height: 37px;
  }

  .ant-select-selection__placeholder {
    transform: translateY(2px);
  }

  .ant-select-selection-selected-value {
    color: ${({ tier }) => (tier?.color ? 'white' : 'black')};
    background-color: ${({ tier }) => tier?.color ?? 'none'};
    border-color: ${({ tier }) => tier?.color ?? 'none'};
    transform: ${({ tier }) =>
      `translateY(${typeof tier === 'object' ? 5 : 8}px)`};

    padding: ${({ tier }) =>
      `0 ${typeof tier === 'object' ? '10px' : '0'} !important`};

    line-height: ${({ tier }) =>
      `${typeof tier === 'object' ? '24px' : 'normal'} !important`};

    border-radius: 11px;

    ${({ tier }) =>
      typeof tier === 'object' &&
      `
       height: 25px;
       font-size: 12px;
       margin: 0
    `}
  }
`;

// --- Query
export const MEMBER_VIPS = gql`
  query member($ids: [ID!], $withVIPPermission: Boolean!) {
    members(filter: { id: { in: $ids } }) {
      edges {
        node {
          id
          username
          memberLoyaltyLevels {
            id
            name
            color
            rank
          }
        }
      }
    }

    memberLoyaltyProgrammes(filter: { status: { eq: ACTIVE } })
      @include(if: $withVIPPermission) {
      edges {
        node {
          id
          name
          primary
          dateTimeCreated
          levels {
            id
            name
            color
          }
        }
      }
    }
  }
`;

export const EXCEL_MEMBERS = gql`
  query members($filter: MembersFilterInput) {
    members(filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export const ALL_PROGRAMMES = gql`
  {
    memberLoyaltyProgrammes {
      edges {
        node {
          id
          name
          primary
        }
      }
    }
  }
`;

// --- Mutation
export const UPDATE_VIPS = gql`
  mutation updateMember($id: ID!, $input: UpdateMemberInput!) {
    updateMember(id: $id, input: $input)
  }
`;

// --- Translations
export const messages = defineMessages({
  EDIT_VIP: {
    id: 'EDIT_VIP',
    defaultMessage: 'Edit VIP',
  },
  CURRENT_VIP: {
    id: 'CURRENT_VIP',
    defaultMessage: 'Current VIP',
  },
  NEW_VIP: {
    id: 'NEW_VIP',
    defaultMessage: 'New VIP',
  },
  PRIMARY_VIP: {
    id: 'PRIMARY_VIP',
    defaultMessage: 'Primary VIP',
  },
  ADDITIONAL_VIP: {
    id: 'ADDITIONAL_VIP',
    defaultMessage: 'Additional VIP',
  },
  NO_VIP: {
    id: 'NO_VIP',
    defaultMessage: 'No VIP',
  },
  REMOVE_VIP: {
    id: 'REMOVE_VIP',
    defaultMessage: 'Remove VIP',
  },
  NO_CHANGES: {
    id: 'NO_CHANGES',
    defaultMessage: 'No Changes',
  },
});
