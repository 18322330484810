import React, { useState, useEffect } from 'react';
import { Card, Row, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Locale } from 'constants/locale';
import { globalTranslate } from 'utils/globalMessages';
import { GameTypeNames } from 'constants/gameTypes';
import { useRebateGroupState } from 'pages/components/Rebates/context';
import { DynamicObj } from 'interfaces/user.interface';
import { FormikProps } from 'interfaces/formikProps.interface';
import { RebateProps } from 'interfaces/formikValues/rebates.interface';
import {
  useRebateGroupPercentagesState,
  RebateGroupTypes,
  SortTypes,
  SearchTypes,
} from '../context';

import RebateCard from './RebateCard';

type StyledProps = {
  focuseditem?: boolean;
};

const { Text } = Typography;
const StyledCard = styled(Card)`
  width: 250px;
  .ant-card-body {
    min-height: 56px;
    padding: 12px;
  }
  border-color: ${(props: StyledProps) => (props.focuseditem ? '#1890ff' : '')};
  &:hover {
    border-color: ${(props: StyledProps) =>
      props.focuseditem ? '#1890ff' : ''};
  }
  z-index: ${(props: StyledProps) => (props.focuseditem ? 15 : 1)};
`;

const searchQuery = (
  sortBy: string,
  keyword: string,
  mode: string,
  items: Array<any>,
  filters: Array<string> = [],
  intl: any = null
) => {
  switch (mode) {
    case SearchTypes.GAME_TYPE: {
      if (sortBy === SortTypes.GAME_TYPE) {
        return filters.filter((filter) =>
          (intl?.locale === Locale.EN
            ? filter
            : globalTranslate(`${filter.toLowerCase()}.text`, intl)
          )
            .toString()
            .toLowerCase()
            .startsWith(keyword.toLowerCase())
        );
      }
      const vendors = [...items];
      return vendors.map((vendor) => ({
        ...vendor,
        node: {
          ...vendor.node,
          gameTypes: vendor.node.gameTypes.filter((gameType: string) =>
            (intl.locale === Locale.EN
              ? gameType
              : globalTranslate(`${gameType.toLowerCase()}.text`, intl)
            )
              .toString()
              .toLowerCase()
              .startsWith(keyword.toLowerCase())
          ),
        },
      }));
    }
    case SearchTypes.GAME_VENDOR: {
      if (sortBy === SortTypes.GAME_TYPE) {
        const vendor: DynamicObj = { ...items };
        return vendor?.node[
          `${intl.locale === Locale.EN ? 'nameEn' : 'nameZh'}`
        ]
          .toString()
          .toLowerCase()
          .startsWith(keyword.toLowerCase());
      }
      const vendors = [...items];
      return vendors.filter((vendor) =>
        vendor.node[`${intl.locale === Locale.EN ? 'nameEn' : 'nameZh'}`]
          .toString()
          .toLowerCase()
          .startsWith(keyword.toLowerCase())
      );
    }
    default:
      return items;
  }
};

const VendorsByGameType = ({
  gType,
  vendors,
  formikProps,
  ...otherProps
}: {
  gType: string;
  vendors: Array<any>;
  formikProps: FormikProps<RebateProps>;
}) => {
  const [derivedFocusedItems, setDerivedFocusedItems] = useState([]);
  const [, dispatch] = useRebateGroupPercentagesState() as any;
  const intl = useIntl();

  useEffect(() => {
    dispatch({
      type: RebateGroupTypes.SET_FOCUSED_ITEMS,
      payload: derivedFocusedItems,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedFocusedItems]);

  if (vendors.length === 0) {
    return (
      <StyledCard className="mt-2 border">
        <FormattedMessage
          id="rebates.no-vendors-found"
          defaultMessage="No vendors found"
        />
      </StyledCard>
    );
  }

  return (
    <Row className="mt-2">
      {vendors.map(({ node }, idx) => (
        <RebateCard
          text={intl.locale === Locale.EN ? `${node.nameEn}` : `${node.nameZh}`}
          idx={idx}
          node={node}
          gType={gType}
          setDerivedFocusedItems={setDerivedFocusedItems}
          otherProps={otherProps}
          formikProps={formikProps}
        />
      ))}
    </Row>
  );
};

const RebatesByGameType = ({
  vendors,
  formikProps,
  ...otherProps
}: {
  vendors: Array<any>;
  formikProps: FormikProps<RebateProps>;
}) => {
  const [state] = useRebateGroupPercentagesState() as any;
  const {
    sortBy,
    search: { keyword, mode },
    filterValues,
  } = state;

  const intl = useIntl();

  const getColumns = () => {
    const filters = filterValues.length
      ? filterValues.map((item: { value: string }) => item.value)
      : Object.keys(GameTypeNames);

    if (keyword.length && mode === SearchTypes.GAME_TYPE) {
      return searchQuery(sortBy, keyword, mode, vendors, filters, intl);
    }
    return filters;
  };

  const columns = getColumns();

  return (
    <>
      {columns?.map((value: string) => {
        const vendorsByGameType = vendors.filter((vendor) => {
          if (keyword.length && mode === SearchTypes.GAME_VENDOR) {
            return (
              vendor.node.gameTypes.includes(value) &&
              vendor.node[`${intl.locale === Locale.EN ? 'nameEn' : 'nameZh'}`]
                .toLowerCase()
                .startsWith(keyword.toLowerCase())
            );
          }
          return vendor.node.gameTypes.includes(value);
        });
        return (
          !!vendorsByGameType.length && (
            <div key={value}>
              <Text strong>
                {globalTranslate(`${value.toLowerCase()}.text`, intl)}
              </Text>
              <VendorsByGameType
                gType={value}
                vendors={vendorsByGameType}
                formikProps={formikProps}
                {...otherProps}
              />
            </div>
          )
        );
      })}
    </>
  );
};

const GameTypesByVendor = ({
  vendor,
  gameTypes,
  formikProps,
  ...otherProps
}: {
  vendor: any;
  gameTypes: Array<string>;
  formikProps: FormikProps<RebateProps>;
}) => {
  const [derivedFocusedItems, setDerivedFocusedItems] = useState([]);
  const [, dispatch] = useRebateGroupPercentagesState() as any;
  const intl = useIntl();

  useEffect(() => {
    dispatch({
      type: RebateGroupTypes.SET_FOCUSED_ITEMS,
      payload: derivedFocusedItems,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [derivedFocusedItems]);

  return (
    <Row className="mt-2">
      {gameTypes.map((gameType, idx) => (
        <RebateCard
          text={globalTranslate(`${gameType.toLowerCase()}.text`, intl)}
          idx={idx}
          node={vendor}
          gType={gameType}
          setDerivedFocusedItems={setDerivedFocusedItems}
          otherProps={otherProps}
          formikProps={formikProps}
        />
      ))}
    </Row>
  );
};

const RebatesByGameVendor = ({
  vendors,
  formikProps,
  ...otherProps
}: {
  vendors: Array<any>;
  formikProps: FormikProps<RebateProps>;
}) => {
  const intl = useIntl();
  const [state] = useRebateGroupPercentagesState() as any;
  const { keyword, mode } = state.search;
  const { sortBy, filterValues } = state;

  const getColumns = () => {
    const newVendors =
      filterValues.length > 0
        ? filterValues.map((item: { value: string }) => {
            const vendor = vendors.find(({ node }) => node.id === item.value);
            return vendor;
          })
        : vendors;

    return keyword.length
      ? searchQuery(sortBy, keyword, mode, newVendors, filterValues, intl)
      : newVendors;
  };

  const columns = getColumns();
  return (
    <>
      {columns?.map(({ node }: { node: DynamicObj }) => {
        const gameTypesByVendor = node.gameTypes;
        return (
          !!gameTypesByVendor.length && (
            <div key={node.id}>
              <Text strong>
                {intl.locale === Locale.EN
                  ? `${node.nameEn}`
                  : `${node.nameZh}`}
              </Text>
              <GameTypesByVendor
                vendor={node}
                gameTypes={gameTypesByVendor}
                formikProps={formikProps}
                {...otherProps}
              />
            </div>
          )
        );
      })}
    </>
  );
};

function RebatesContent({
  formikProps = {},
  readOnly,
  percentages,
  ...otherProps
}: {
  percentages?: any;
  readOnly?: boolean;
  formikProps?: Record<string, any>;
}) {
  const [{ vendors }] = useRebateGroupState() as any;
  const [state] = useRebateGroupPercentagesState() as any;

  return (
    <div className="d-flex pb-4">
      {state.sortBy === SortTypes.GAME_TYPE && (
        <RebatesByGameType
          vendors={vendors}
          formikProps={formikProps as any}
          {...otherProps}
        />
      )}
      {state.sortBy === SortTypes.GAME_VENDOR && (
        <RebatesByGameVendor
          formikProps={formikProps as any}
          vendors={vendors}
          {...otherProps}
        />
      )}
    </div>
  );
}

export default RebatesContent;
