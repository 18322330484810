import React from 'react';
import { Switch } from 'antd';

const CustomSwitch = ({
  onChange,
  value,
  textHelper,
  textStyle,
  activeColor,
  disabled,
  ...props
}: any) => (
  <>
    <Switch
      {...props}
      disabled={disabled}
      onChange={onChange}
      checked={value}
      style={{
        background: value ? activeColor || '#3ec988' : '#bfbfbf',
      }}
    />
    {textHelper && !disabled && (
      <small className="ml-2 text-bold" style={textStyle}>
        {value ? textHelper.true : textHelper.false}
      </small>
    )}
  </>
);

export default CustomSwitch;
