import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const NextPrev = ({
  onNext,
  disableNext,
  onPrev,
  disablePrev,
  label,
}: {
  onNext: (e: any) => void;
  onPrev: (e: any) => void;
  disableNext: boolean;
  disablePrev: boolean;
  label: string;
}) => (
  <div className="d-flex justify-content-center mt-3 next-prev-container">
    <Button
      disabled={disablePrev}
      type="default"
      icon={<LeftOutlined />}
      onClick={onPrev}
    />
    <div className="py-1 px-2">{label}</div>
    <Button
      disabled={disableNext}
      type="default"
      icon={<RightOutlined />}
      onClick={onNext}
    />
  </div>
);

export default NextPrev;
