export const WithdrawalProviderTypes = {
  MIDPAY: 'MIDPAY',
  DIORPAY: 'DIORPAY',
  MIDPAY_DIRECT: 'MIDPAY_DIRECT',
};

export const WithdrawalProviderSchemaTypes = {
  MidpayDirectWithdrawalProvider: 'MidpayDirectWithdrawalProvider',
  MidpayWithdrawalProvider: 'MidpayWithdrawalProvider',
  DiorpayWithdrawalProvider: 'DiorpayWithdrawalProvider',
};

export const WithdrawalProviderTypeSchemaType = {
  [WithdrawalProviderTypes.MIDPAY]: 'MidpayWithdrawalProvider',
  [WithdrawalProviderTypes.MIDPAY_DIRECT]: 'MidpayDirectWithdrawalProvider',
  [WithdrawalProviderTypes.DIORPAY]: 'DiorpayWithdrawalProvider',
};

export const PaymentGatewayTypes = {
  PaymentGateway: 'PaymentGateway',
  MidPayDirectPaymentGateway: 'MidPayDirectPaymentGateway',
  DiorPay2PaymentGateway: 'DiorPay2PaymentGateway',
};

export type PaymentGatewayType =
  | 'PaymentGateway'
  | 'MidPayDirectPaymentGateway'
  | 'DiorPay2PaymentGateway';
