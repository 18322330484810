import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { withErrorHandler } from 'components/ErrorHandler';

import FiltersProvider from 'SuperAdminMain/contexts/Filters';
import ReportsTimezoneProvider from 'contexts/ReportsTimezone';
// import { Schedule } from './components/Schedule/Schedule';
import { FeatureFlagsSA as FF } from 'SuperAdminMain/constants/featureflags';
import FeaturedFlagWrap from 'components/FeaturedFlagWrap';
import Sidebar from './components/Sidebar';
import { StyledLayout, StyledContent, StyledSider } from './styles';
import ReportsTable from './components/ReportsTable';
import TableHeader from './components/TableHeader';
import Schedule from './components/Schedule/Schedule';
// import ScheduledReports from './components/ScheduledReports';

const AccountsConsolidatedReport = () => {
  const [isSidebar, setSidebar] = useState(false);

  const handleSidebar = () => setSidebar(!isSidebar);

  const tableRef = React.useRef(null);

  return (
    <FiltersProvider
      initialValues={{
        dateTimeProcessed: null,
        timezone: null,
        admin: null,
        vendor: null,
      }}
    >
      <ReportsTimezoneProvider>
        <StyledLayout className="d-flex">
          <StyledContent>
            <TableHeader ref={tableRef} handleSidebar={handleSidebar} />
            <div className="d-flex">
              <Sidebar ref={tableRef} collapsed={isSidebar}>
                <FormattedMessage
                  id="member.loyalty.sider.text"
                  defaultMessage="Sider"
                />
              </Sidebar>
              <StyledContent className="justify-content-space-between flex-direction-column">
                <ReportsTable ref={tableRef} />
              </StyledContent>
            </div>
          </StyledContent>
          <FeaturedFlagWrap flag={FF.curaScheduledList || ''}>
            <StyledSider
              theme="light"
              width={303}
              style={{
                overflowY: 'auto',
                height: 'auto',
              }}
            >
              <Schedule ref={tableRef} />
            </StyledSider>
          </FeaturedFlagWrap>
        </StyledLayout>
      </ReportsTimezoneProvider>
    </FiltersProvider>
  );
};

export default withErrorHandler(AccountsConsolidatedReport);
