import React from 'react';
import { StyledInput } from './styles';

const Tiers = () => (
  <div className="my-5">
    <div className="mb-1">Tiers</div>
    <StyledInput placeholder="Amount" />
  </div>
);

export default Tiers;
