import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'update-to-active.text': {
    id: 'update-to-active.text',
    defaultMessage: 'Successfully changed status to Active',
  },
  'update-to-inactive.text': {
    id: 'update-to-inactive.text',
    defaultMessage: 'Successfully changed status to Inactive',
  },
  'delete.text': {
    id: 'delete.text',
    defaultMessage: 'Delete',
  },
  'cancel.text': {
    id: 'cancel.text',
    defaultMessage: 'Cancel',
  },
  'update-marquee-success.text': {
    id: 'update-marquee-success.text',
    defaultMessage: 'Marquee message update successfully',
  },
  'delete-confirm-message.text': {
    id: 'delete-confirm-message.text',
    defaultMessage: 'This will delete the {title} marquee message.',
  },
  'title.text': {
    id: 'title.text',
    defaultMessage: 'Title',
  },
  'body.text': {
    id: 'body.text',
    defaultMessage: 'Body',
  },
  'snippet.text': {
    id: 'snippet.text',
    defaultMessage: 'Snippet',
  },
  'status.text': {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  'action.text': {
    id: 'action.text',
    defaultMessage: 'Action',
  },
  'new-marquee-message.text': {
    id: 'new-marquee-message.text',
    defaultMessage: 'New Marquee Message',
  },
  'edit-marquee-message.text': {
    id: 'edit-marquee-message.text',
    defaultMessage: 'Editing Marquee Message',
  },
  'message.text': {
    id: 'message.text',
    defaultMessage: 'Message',
  },
  'active.text': {
    id: 'active.text',
    defaultMessage: 'Active',
  },
  'inactive.text': {
    id: 'inactive.text',
    defaultMessage: 'Inactive',
  },
  'new-marquee-created.text': {
    id: 'new-marquee-created.text',
    defaultMessage: 'New marquee message created',
  },
  DUPLICATE_MARQUEE_MESSAGE_SUCCESS: {
    id: 'DUPLICATE_MARQUEE_MESSAGE_SUCCESS',
    defaultMessage: 'Message Duplication Successful',
  },
});
