import gql from 'graphql-tag';

export const REALITY_CHECK_AUDIT_TRAIL = gql`
  query RealityCheckAuditTrail($first: Int, $after: Binary, $sort: SortInput) {
    realityCheckAuditTrail(first: $first, after: $after, sort: $sort) {
      edges {
        node {
          dateTimeCreated
          account {
            id
            username
          }
          actionType
        }
        cursor
      }
    }
  }
`;

export const LOQATE_AUDIT_TRAIL = gql`
  query LoqateAuditTrail($first: Int, $after: Binary, $sort: SortInput) {
    loqateAuditTrail(first: $first, after: $after, sort: $sort) {
      edges {
        node {
          dateTimeCreated
          account {
            id
            username
          }
          actionType
        }
        cursor
      }
    }
  }
`;

export const REGISTRATION_AUDIT_TRAIL = gql`
  query RegistrationAuditTrail($first: Int, $after: Binary) {
    registrationAuditTrail(first: $first, after: $after) {
      edges {
        node {
          dateTimeCreated
          account {
            id
            username
          }
          actionType
          feature
        }
        cursor
      }
    }
  }
`;

export const GBG_AUDIT_TRAIL = gql`
  query GBGAuditTrail($first: Int, $after: Binary) {
    gbgAuditTrail(first: $first, after: $after) {
      edges {
        node {
          dateTimeCreated
          account {
            id
            username
          }
          actionType
        }
        cursor
      }
    }
  }
`;

export const GAMSTOP_AUDIT_TRAIL = gql`
  query GamstopAuditTrail($first: Int, $after: Binary) {
    gamstopAuditTrail(first: $first, after: $after) {
      edges {
        node {
          dateTimeCreated
          account {
            id
            username
          }
          actionType
        }
        cursor
      }
    }
  }
`;
