import React from 'react';
import { EllipsisOutlined, MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { columnAlign } from 'utils/tableAlignment';
import { GameCategory, Vendor } from 'types/graphqlTypes';
import { messages } from './messages';
import VendorsScreenTab from '../MobileAppConfigurationVendors/VendorsScreenTab';

export const columns = (
  translate: any,
  onAddVendor: any,
  onEditGameCategory: any,
  onDeleteConfirmModal: any,
  permissions: any
) => {
  const {
    ALLOWED_VIEW_DETAILS,
    ALLOWED_ADD_VENDOR,
    ALLOWED_EDIT,
    ALLOWED_DELETE,
  } = permissions;

  return [
    {
      title: <></>,
      width: 50,
      align: columnAlign.onRight,
      key: 'dragIcon',
      render: () => (
        <MenuOutlined className="handle" style={{ cursor: 'pointer' }} />
      ),
    },
    {
      title: translate(messages['categories.text']),
      align: columnAlign.onLeft,
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (category: GameCategory) => category,
    },
    {
      title: translate(messages['vendors.text']),
      dataIndex: 'vendors',
      key: 'vendors',
      align: columnAlign.onCenter,
      width: 100,
      render: (vendorsList: [Vendor], allData: GameCategory) =>
        vendorsList &&
        ALLOWED_VIEW_DETAILS && (
          <VendorsScreenTab
            numOfVendors={vendorsList.length}
            gameCategory={allData}
          />
        ),
    },
    {
      title: translate(messages['actions.text']),
      dataIndex: 'actions',
      align: columnAlign.onCenter,
      key: 'actions',
      width: 100,
      render: (_: any, gameCategoryObj: GameCategory) =>
        (ALLOWED_ADD_VENDOR || ALLOWED_EDIT || ALLOWED_DELETE) && (
          <Dropdown
            overlay={
              <Menu>
                {ALLOWED_ADD_VENDOR && (
                  <Menu.Item onClick={() => onAddVendor(gameCategoryObj)}>
                    <FormattedMessage
                      id="add-vendor.text"
                      defaultMessage="Add Vendor"
                    />
                  </Menu.Item>
                )}

                {ALLOWED_EDIT && (
                  <Menu.Item
                    onClick={() => onEditGameCategory(gameCategoryObj)}
                  >
                    <FormattedMessage id="edit.text" defaultMessage="Edit" />
                  </Menu.Item>
                )}

                {ALLOWED_DELETE && (
                  <Menu.Item
                    onClick={() => onDeleteConfirmModal(gameCategoryObj)}
                  >
                    <FormattedMessage
                      id="delete.text"
                      defaultMessage="Delete"
                    />
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
          >
            <EllipsisOutlined
              data-testid="actions"
              className="cursor-pointer font-weight-bold"
              style={{ fontSize: '25px' }}
            />
          </Dropdown>
        ),
    },
  ];
};

export const BodyRow = (draggingIndex: number) => (props: any) => {
  const {
    isOver,
    connectDragSource,
    connectDropTarget,
    moveRow,
    ...restProps
  } = props;
  const style = { ...restProps.style, cursor: 'move' };
  let { className } = restProps;
  if (isOver) {
    if (restProps.index > draggingIndex) {
      className += ' drop-over-downward';
    }
    if (restProps.index < draggingIndex) {
      className += ' drop-over-upward';
    }
  }
  return connectDragSource(
    connectDropTarget(<tr {...restProps} className={className} style={style} />)
  );
};
