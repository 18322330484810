import React, { ReactElement } from 'react';
import { Button } from 'antd';
import { SharedStyledModal } from 'styles/SharedStyledModal';

const MessagePromptModal = ({
  title,
  visible,
  okText,
  children,
  onCloseFn,
}: {
  title: string;
  visible: boolean;
  okText: string;
  children: ReactElement;
  onCloseFn: () => void;
}) => (
  <SharedStyledModal
    title={title}
    visible={visible}
    onCancel={onCloseFn}
    footer={[
      <Button type="primary" onClick={onCloseFn}>
        {okText || 'Ok'}
      </Button>,
    ]}
  >
    <div className="p-3">{children}</div>
  </SharedStyledModal>
);

export default MessagePromptModal;
