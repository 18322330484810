import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import { Formik } from 'formik';
import randomstring from 'randomstring';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { StyledForm, StyledPassword } from './styles';
import validationSchema from './validationSchema';

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (e: any) => void;
  onClose: () => void;
  isLoading: boolean;
};

const WithdrawalPasswordChangeForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: Props) => {
  const translate = useTranslate();
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema(translate)}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        touched,
      }) => {
        const handleGenerate = () => {
          setFieldValue('password', randomstring.generate({ length: 6 }));
        };
        return (
          <StyledForm onSubmit={handleSubmit}>
            <div className="p-3">
              <div>
                <FormattedMessage
                  id="username.text"
                  defaultMessage="Username"
                />
              </div>
              <div className="mb-2">
                <Input disabled defaultValue={values.username} />
              </div>
              <div>
                <FormattedMessage
                  id="password.text"
                  defaultMessage="Password"
                />
              </div>
              <Form.Item
                wrapperCol={{ span: 24 }}
                validateStatus={
                  errors.password && touched.password ? 'error' : ''
                }
                help={touched.password ? errors.password : null}
              >
                <StyledPassword>
                  <Input.Password
                    aria-label="password-input"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <Button type="primary" onClick={handleGenerate} role="button">
                    <FormattedMessage
                      id="generate.text"
                      defaultMessage="Generate"
                    />
                  </Button>
                </StyledPassword>
              </Form.Item>
            </div>
            <div className="bt-1 p-3 text-right">
              <Button className="mr-2" onClick={onClose} loading={isLoading}>
                <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                <FormattedMessage
                  id="save-changes.text"
                  defaultMessage="Save changes"
                />
              </Button>
            </div>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default WithdrawalPasswordChangeForm;
