import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import useTranslate from 'utils/useTranslate';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import SelectFilterField from 'components/SelectFilterField';
import {
  BALANCE_SERVICE_RECORDS_SERIAL_CODE,
  BALANCE_SERVICE_RECORDS_TRANSACTION_CODE,
} from 'graphql/queries/balanceServiceRecord.query';
import messages from 'messages';
import { FilterItem } from 'components/FilterItem/FilterItem';
import TransactionTypeSelect from 'components/TransactionTypeSelect';
import coercedGet from 'utils/coercedGet';
import AmountFilter from 'components/AmountFilter';
import { isEmpty } from 'lodash';
import MultiplierSelectInput from 'components/MultiplierSelectInput';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import { GET_WITHDRAWAL_SERIAL_CODES } from 'graphql/queries/withdrawal.query';
import isRow from 'utils/isRow';
import { GET_AVAILABLE_CURRENCIES } from 'hooks/useAvailableCurrencies';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
};

const FilterItems = ({
  filters,
  onRawFilterChange: handleFilterChange,
}: Props) => {
  const translate = useTranslate();

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS
  );
  return (
    <>
      <SelectFilterField
        testId="BSRR-serialCode-filter"
        label="Balance Service Code"
        query={BALANCE_SERVICE_RECORDS_SERIAL_CODE}
        queryConnection="memberBalanceUnitRecords"
        filterFieldName="serialCode"
        filters={filters}
        partialSupported={false}
        additionalOptionsFilter={{
          member: filters.member,
        }}
        onChange={(e) =>
          handleFilterChange({
            name: 'serialCode',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <SelectFilterField
        testId="BSRR-transactionCode-filter"
        label="Transaction Code"
        query={BALANCE_SERVICE_RECORDS_TRANSACTION_CODE}
        queryConnection="memberBalanceUnitRecords"
        filterFieldName="transactionSerialCode"
        filters={filters}
        partialSupported={false}
        additionalOptionsFilter={{
          member: filters.member,
        }}
        onChange={(e) =>
          handleFilterChange({
            name: 'transactionSerialCode',
            value: e?.length ? { in: e } : null,
          })
        }
      />

      {isRow && (
        <SelectFilterField
          testId="BSR-currency-filter"
          label="Currency"
          allowFilterOption
          query={GET_AVAILABLE_CURRENCIES}
          isAvailableCurrencyQuery
          queryConnection=""
          filterFieldName="currency"
          queryOperation="in"
          filters={filters}
          partialSupported={false}
          onChange={(e) =>
            handleFilterChange({
              name: 'currency',
              value: e?.length ? { in: e } : null,
            })
          }
        />
      )}

      <FilterItem
        label={translate(messages.TRANSACTION_TYPE)}
        onClear={() =>
          handleFilterChange({
            name: 'transactionType',
            value: null,
          })
        }
      >
        <TransactionTypeSelect
          value={coercedGet(filters, 'transactionType.in', [])}
          onChange={(val) =>
            handleFilterChange({
              name: 'transactionType',
              value: val.length ? { in: val } : null,
            })
          }
          placeHolder={translate(messages.ENTER_TRANSACTION_TYPE)}
          multiple
        />
      </FilterItem>

      <FilterItem
        label={`${translate(messages.ORIGINAL_AMOUNT)}`}
        onClear={() =>
          handleFilterChange({
            name: 'originalAmount',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: coercedGet(filters, 'originalAmount.gte', ''),
            max: coercedGet(filters, 'originalAmount.lte', ''),
          }}
          onChange={(e) => {
            const amount = { ...filters.originalAmount };
            if (!e.value) delete amount[e.range];
            else amount[e.range] = e.value;

            handleFilterChange({
              name: 'originalAmount',
              value: isEmpty(amount) ? null : amount,
            });
          }}
        />
      </FilterItem>
      <FilterItem
        label={`${translate(messages.CURRENT_AMOUNT)}`}
        onClear={() =>
          handleFilterChange({
            name: 'currentAmount',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: coercedGet(filters, 'currentAmount.gte', ''),
            max: coercedGet(filters, 'currentAmount.lte', ''),
          }}
          onChange={(e) => {
            const amount = { ...filters.currentAmount };
            if (!e.value) delete amount[e.range];
            else amount[e.range] = e.value;

            handleFilterChange({
              name: 'currentAmount',
              value: isEmpty(amount) ? null : amount,
            });
          }}
        />
      </FilterItem>
      <FilterItem
        label={translate(messages.TURNOVER_MULTIPLIER)}
        onClear={() =>
          handleFilterChange({
            name: 'turnoverRequirementMultiplier',
            value: null,
          })
        }
      >
        <MultiplierSelectInput
          value={coercedGet(filters, 'turnoverRequirementMultiplier.in', [])}
          onChange={(e) =>
            handleFilterChange({
              name: 'turnoverRequirementMultiplier',
              value: e.length ? { in: e } : null,
            })
          }
          placeHolder={translate(messages.ENTER_TURNOVER_MULTIPLIER)}
          multiple
          // topRightClear
        />
      </FilterItem>
      <FilterItem
        label={`${translate(messages.TURNOVER_REQUIREMENT)}`}
        onClear={() =>
          handleFilterChange({
            name: 'turnoverRequirement',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: coercedGet(filters, 'turnoverRequirement.gte', ''),
            max: coercedGet(filters, 'turnoverRequirement.lte', ''),
          }}
          onChange={(e) => {
            const amount = { ...filters.turnoverRequirement };
            if (!e.value) delete amount[e.range];
            else amount[e.range] = e.value;

            handleFilterChange({
              name: 'turnoverRequirement',
              value: isEmpty(amount) ? null : amount,
            });
          }}
        />
      </FilterItem>
      <FilterItem
        label={`${translate(messages.TURNOVER_BALANCE)}`}
        onClear={() =>
          handleFilterChange({
            name: 'turnoverRequirementBalance',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: coercedGet(filters, 'turnoverRequirementBalance.gte', ''),
            max: coercedGet(filters, 'turnoverRequirementBalance.lte', ''),
          }}
          onChange={(e) => {
            const amount = { ...filters.turnoverRequirementBalance };
            if (!e.value) delete amount[e.range];
            else amount[e.range] = e.value;

            handleFilterChange({
              name: 'turnoverRequirementBalance',
              value: isEmpty(amount) ? null : amount,
            });
          }}
        />
      </FilterItem>

      <DateTimeRangePicker
        testId="BSRR-dateTime-created-filter"
        label="creation-date"
        value={
          filters.dateTimeCreated
            ? [filters.dateTimeCreated.gte, filters.dateTimeCreated.lte]
            : null
        }
        onChange={(e) =>
          handleFilterChange({
            name: 'dateTimeCreated',
            value: e?.length ? { gte: e[0], lte: e[1] } : null,
          })
        }
      />

      <DateTimeRangePicker
        testId="BSRR-completion-date-filter"
        label="completion-date"
        value={
          filters.dateTimeTurnoverCompleted
            ? [
                filters.dateTimeTurnoverCompleted.gte,
                filters.dateTimeTurnoverCompleted.lte,
              ]
            : null
        }
        onChange={(e) =>
          handleFilterChange({
            name: 'dateTimeTurnoverCompleted',
            value: e?.length ? { gte: e[0], lte: e[1] } : null,
          })
        }
      />

      <FilterItem
        label={translate(messages.BETS)}
        onClear={() =>
          handleFilterChange({
            name: 'betCount',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: coercedGet(filters, 'betCount.gte', ''),
            max: coercedGet(filters, 'betCount.lte', ''),
          }}
          minPlaceHolder="0"
          maxPlaceHolder="0"
          onChange={(e) => {
            const count = { ...filters.betCount };
            if (!e.value) delete count[e.range];
            else count[e.range] = e.value;

            handleFilterChange({
              name: 'betCount',
              value: isEmpty(count) ? null : count,
            });
          }}
        />
      </FilterItem>

      {ALLOWED_LIST && (
        <SelectFilterField
          testId="BSRR-withdrawal-serialCode-filter"
          partialSupported={false}
          label="Serial Code"
          query={GET_WITHDRAWAL_SERIAL_CODES}
          queryConnection="withdrawals"
          filterFieldName="serialCode"
          filters={filters}
          queryOperation="includesAny"
          onChange={(e) =>
            handleFilterChange({
              name: 'withdrawals',
              value: e?.length ? { includesAny: e } : null,
            })
          }
        />
      )}
    </>
  );
};

export default FilterItems;
