import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import SelectFilterField from 'components/SelectFilterField';
import {
  GET_DEPOSIT_ACCOUNTS,
  GET_DEPOSIT_BRAND_IDS,
  GET_DEPOSIT_PLATFORM_IDS,
  GET_DEPOSIT_SERIAL_CODES,
} from 'graphql/queries/deposit.query';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import coercedGet from 'utils/coercedGet';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { FilterItem } from 'components/FilterItem/FilterItem';
import MemberLoyaltyLevelsSelect from 'components/MemberLoyaltyLevelsSelect';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';

import PaymentMethodTypeSelect from 'components/PaymentMethodTypeSelect';
import { isEmpty } from 'lodash';
import AmountFilter from 'components/AmountFilter';
import PaymentStatusFilter from 'components/PaymentStatusFilter';
import { PAYMENT_TYPES_DEPOSIT } from 'constants/paymentTypes';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import ProcessorsSelect from 'components/ProcessorsSelect';
import { unassignedProcessorValue } from 'constants/processorValues';
import { isVipVisible } from 'utils/isVipVisible';
import { GET_AVAILABLE_CURRENCIES } from 'hooks/useAvailableCurrencies';
import isRow from 'utils/isRow';

type Props = {
  filters: Record<string, any>;
  defaultValues?: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  isCustomFilter?: boolean;
};

export default ({
  filters,
  onRawFilterChange: handleChange,
  isCustomFilter = false,
  defaultValues,
}: Props) => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST: ALLOWED_VIP_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  );

  const { ALLOWED_LIST: ALLOWED_MARKER_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT
  );

  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'LIST'],
    [
      ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT,
      ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_ACCOUNT_MANAGEMENT,
    ]
  );

  const setProcessorValues = () => {
    const { processor } = filters;

    if (processor) {
      // This includes a null value if the `processor.nin` has a value since unassigned operators are included
      return [
        ...(new Set([
          ...(processor.in ? processor.in : []),
          ...(processor.nin ? [unassignedProcessorValue] : []),
        ]) as any),
      ];
    }

    return [];
  };

  const onChangeProcessor = (
    includedProcessors: Array<any>,
    excludedProcessors: Array<any>
  ) => {
    let filterValues = {} as any;

    if (excludedProcessors.length) {
      filterValues = {
        nin: excludedProcessors,
        ...(includedProcessors.length ? { in: includedProcessors } : {}),
      };
    } else {
      filterValues = includedProcessors.length
        ? { in: includedProcessors }
        : null;
    }

    handleChange({
      name: 'processor',
      value: filterValues,
    });
  };

  const translate = useTranslate();

  return (
    <>
      <SelectFilterField
        testId="deposits-serialCode-filter"
        partialSupported={false}
        label="Serial Code"
        query={GET_DEPOSIT_SERIAL_CODES}
        queryConnection="deposits"
        filterFieldName="serialCode"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'serialCode',
            value: e?.length ? { in: e } : null,
          })
        }
      />
      <IdBasedSelectFilterField
        testId="deposits-username-filter"
        label="Account"
        placeholder="Username"
        query={GET_DEPOSIT_ACCOUNTS}
        queryConnection="members"
        filterFieldName="username"
        filters={filters}
        rawFilterValues={coercedGet(filters, 'account.in', [])}
        isCustomFilter={isCustomFilter}
        onChange={(e) =>
          handleChange({
            name: 'account',
            value: e?.length ? { in: e } : null,
          })
        }
        partialSupported={false}
      />
      {ALLOWED_VIP_LIST && isVipVisible && (
        <FilterItem
          label={translate(messages.VIP_LEVEL)}
          onClear={() =>
            handleChange({
              name: 'memberLoyaltyLevels',
              value: null,
            })
          }
        >
          <MemberLoyaltyLevelsSelect
            value={coercedGet(filters, 'memberLoyaltyLevels.in', [])}
            onChange={(val: string | Array<string>) =>
              handleChange({
                name: 'memberLoyaltyLevels',
                value: val.length ? { in: val } : null,
              })
            }
            placeHolder={translate(messages['enter-vip-level.text'])}
            multiple
          />
        </FilterItem>
      )}
      {ALLOWED_MARKER_LIST && (
        <IdBasedSelectFilterField
          testId="deposits-member-marker-filter"
          label="Member Marker"
          query={GET_MEMBER_LEVELS}
          queryConnection="memberLevels"
          filterFieldName="name"
          filters={filters}
          isCustomFilter={isCustomFilter}
          rawFilterValues={coercedGet(filters, 'memberLevel.in', [])}
          partialSupported={false}
          onChange={(e) =>
            handleChange({
              name: 'memberLevel',
              value: e?.length ? { in: e } : null,
            })
          }
        />
      )}
      <FilterItem
        label={translate(messages.PAYMENT_METHOD_TYPE)}
        onClear={() =>
          handleChange({
            name: 'paymentMethod',
            value: defaultValues?.paymentMethod,
          })
        }
      >
        {isRow && (
          <PaymentMethodTypeSelect
            value={filters.paymentMethod?.in ?? null}
            onChange={(val: string | Array<string>) => {
              handleChange({
                name: 'paymentMethod',
                value: {
                  in: val && val.length ? val : [],
                },
              });
            }}
            multiple
            placeHolder={translate(messages['select-a-payment-method.text'])}
            isInternal
          />
        )}
        {!isRow && (
          <PaymentMethodTypeSelect
            value={
              filters.paymentMethodType &&
              filters.paymentMethodType.in.length === 1
                ? filters.paymentMethodType.in[0]
                : null
            }
            onChange={(val: string | Array<string>) =>
              handleChange({
                name: 'paymentMethodType',
                value: {
                  in: val && val.length ? [val] : PAYMENT_TYPES_DEPOSIT.OFFLINE,
                },
              })
            }
            placeHolder={translate(messages['select-a-payment-method.text'])}
            isInternal
          />
        )}
      </FilterItem>

      {isRow && (
        <SelectFilterField
          testId="deposit-currency-filter"
          partialSupported={false}
          label="Currency"
          isAvailableCurrencyQuery
          query={GET_AVAILABLE_CURRENCIES}
          queryConnection="deposits"
          filterFieldName="currency"
          filters={filters}
          allowFilterOption
          onChange={(e) =>
            handleChange({
              name: 'currency',
              value: e?.length ? { in: e } : null,
            })
          }
        />
      )}

      <FilterItem
        label={translate(messages.DEPOSIT_AMOUNT)}
        onClear={() =>
          handleChange({
            name: 'amount',
            value: null,
          })
        }
      >
        <AmountFilter
          value={{
            min: coercedGet(filters, 'amount.gte', undefined),
            max: coercedGet(filters, 'amount.lte', undefined),
          }}
          onChange={(e: any) => {
            const amount = { ...filters.amount };
            if (!e.value) delete amount[e.range];
            else amount[e.range] = e.value;

            handleChange({
              name: 'amount',
              value: isEmpty(amount) ? null : amount,
            });
          }}
        />
      </FilterItem>
      <FilterItem
        label={translate(messages.STATUS)}
        onClear={() =>
          handleChange({
            name: 'status',
            value: null,
          })
        }
      >
        <PaymentStatusFilter
          excludedStatus={['ON_HOLD']}
          value={coercedGet(filters, 'status.in', [])}
          onChange={(e) =>
            handleChange({
              name: 'status',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>
      <DateTimeRangePicker
        testId="deposits-dateTime-created-filter"
        label="request-date"
        value={
          filters.dateTimeCreated
            ? [filters.dateTimeCreated.gte, filters.dateTimeCreated.lte]
            : null
        }
        onChange={(e) => {
          handleChange({
            name: 'dateTimeCreated',
            value: e?.length ? { gte: e[0], lte: e[1] } : null,
          });
        }}
      />
      {ALLOWED_LIST && (
        <FilterItem
          label={translate(messages.operator)}
          onClear={() =>
            handleChange({
              name: 'processor',
              value: null,
            })
          }
        >
          <ProcessorsSelect
            style={{ width: '100%' }}
            topRightClear
            value={setProcessorValues()}
            placeHolder={translate(messages.ENTER_USERNAME)}
            multiple
            allowUnassigned
            onChange={onChangeProcessor}
          />
        </FilterItem>
      )}
      <SelectFilterField
        testId="deposits-brandId-filter"
        partialSupported={false}
        label="Brand ID"
        query={GET_DEPOSIT_BRAND_IDS}
        queryConnection="deposits"
        fieldType="account"
        filterFieldName="brandId"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'brandId',
            value: e?.length ? { in: e } : null,
          })
        }
      />

      <SelectFilterField
        testId="deposits-platformId-filter"
        partialSupported={false}
        label="Platform ID"
        query={GET_DEPOSIT_PLATFORM_IDS}
        queryConnection="deposits"
        fieldType="account"
        filterFieldName="platformId"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'platformId',
            value: e?.length ? { in: e } : null,
          })
        }
      />
    </>
  );
};
