import { useState, useCallback } from 'react';
import { TabId } from 'types/nav';
import getCsvFunctions from 'csv-headers';
import useTranslate from 'utils/useTranslate';

const useCsvHeaders = (tabId?: TabId) => {
  const translate = useTranslate();

  const getCsvHeaders = useCallback(
    (id: TabId) => {
      const newCsvHeaders = getCsvFunctions[`${id}`](translate);
      return newCsvHeaders;
    },
    [translate]
  );

  const [csvHeaders] = useState(() => {
    if (tabId) {
      const newCsvHeaders = getCsvHeaders(tabId);
      return newCsvHeaders;
    }

    return [];
  });

  return { csvHeaders, getCsvHeaders };
};

export default useCsvHeaders;
