import React from 'react';
import { Select } from 'antd';
import { StyledSelect } from './styles';

const { Option } = Select;

const Status = () => (
  <div className="my-5">
    <div className="mb-1">Status</div>
    <StyledSelect placeholder="Draft / Activated" style={{ width: '100%' }}>
      <Option value="1">Status 1</Option>
      <Option value="2">Status 2</Option>
    </StyledSelect>
  </div>
);

export default Status;
