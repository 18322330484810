/* eslint-disable no-shadow */
import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/react-hooks';
import {
  unassignedProcessorValue,
  unassignedProcessorLabel,
} from 'constants/processorValues';
import { Select } from 'antd';

import messages from 'messages';

import useTranslate from 'utils/useTranslate';
import { SelectProps } from 'interfaces/select.interface';
import { OPERATORS } from './queries';
/* *
 * NOTE#1: "allowUnassigned" is for `nin` condition
 *
 * NOTE#2: If the prop "allowUnassigned" is true please add a logic on filter that has a `nin` condition
 * Also if the `unassigned-operator` value is selected add a logic from the parent component that the `unassigned-operator` value will be
 * included in the value prop.
 * */
const ProcessorsSelect = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
  style,
  labelAsValue,
  allowUnassigned,
  topRightClear,
}: SelectProps) => {
  const { Option } = Select;
  const translate = useTranslate();
  const [filter, setFilter] = useState({
    username: {
      contains: '',
    },
  }) as any;

  const operatorsQuery = useQuery(OPERATORS, {
    variables: {
      filter,
      first: 10,
    },
    fetchPolicy: 'network-only',
  });

  const handleSearch = (e: string) => {
    setFilter({
      username: {
        contains: e.toUpperCase(),
      },
    });
    operatorsQuery.refetch();
  };

  const { operators = {} } = operatorsQuery.data || {};

  const operatorEdges = operators.edges || [];

  const handleChange = (e: any) => {
    const includedProcessors = !allowUnassigned
      ? e
      : e.filter(
          (processorId: string) => processorId !== unassignedProcessorValue
        );

    let excludedProcessors =
      allowUnassigned && e.includes(unassignedProcessorValue)
        ? [...operatorEdges.map(({ node }: Record<string, any>) => node.value)]
        : [];

    if (excludedProcessors.length) {
      excludedProcessors = includedProcessors.reduce(
        (willBeExcludedIds: any[], includedProcessor: any) => {
          const newExcludedIds = willBeExcludedIds.filter(
            (excludedProcessor) => excludedProcessor !== includedProcessor
          );

          return newExcludedIds;
        },
        excludedProcessors
      );
    }

    onChange(includedProcessors, excludedProcessors);
  };

  return (
    <Select
      className="w-100"
      mode={multiple ? 'multiple' : undefined}
      value={value || (multiple ? [] : undefined)}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      loading={operatorsQuery.loading}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled}
      style={style}
      allowClear={!topRightClear}
    >
      {allowUnassigned && operatorEdges.length && (
        <Option key="unassigned" value={unassignedProcessorValue}>
          {unassignedProcessorLabel}
        </Option>
      )}
      {!multiple && (
        <Option disabled value={null as any}>
          <span className="text-gray">
            {translate(messages.SELECT_AN_OPERATOR)}
          </span>
        </Option>
      )}
      {operatorEdges.map(({ node }: Record<string, any>) => (
        <Option key={node.value} value={labelAsValue ? node.label : node.value}>
          {node.label}
        </Option>
      ))}
    </Select>
  );
};

export default ProcessorsSelect;
