import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import useTranslate from 'utils/useTranslate';
import { defineMessages } from 'react-intl';
import { FilterItem } from 'components/FilterItem/FilterItem';
import DepositProvidersSelect from 'components/DepositProvidersSelect';
import ThirdPartyProvidersTypeFilter from 'components/ThirdPartyProvidersTypeFilter';
import messages from 'messages';

const messagesLocal = defineMessages({
  'deposit-provider.text': {
    id: 'deposit-provider.text',
    defaultMessage: 'Deposit provider',
  },
  'enter-name.text': {
    id: 'enter-name.text',
    defaultMessage: 'Enter name',
  },
});

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
};

export default ({ filters, onRawFilterChange: handleChange }: Props) => {
  const translate = useTranslate();

  return (
    <>
      <FilterItem
        label={translate(messagesLocal['deposit-provider.text'])}
        onClear={() => handleChange({ name: 'name', value: null })}
      >
        <DepositProvidersSelect
          value={filters.name ? filters.name.in || [] : []}
          placeHolder={translate(messagesLocal['enter-name.text'])}
          multiple
          labelAsValue
          onChange={(e) =>
            handleChange({
              name: 'name',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>

      <FilterItem
        label={translate(messages.TYPE)}
        onClear={() =>
          handleChange({ name: 'depositProviderType', value: null })
        }
      >
        <ThirdPartyProvidersTypeFilter
          values={
            filters.depositProviderType
              ? filters.depositProviderType.in || []
              : []
          }
          onChange={(e) =>
            handleChange({
              name: 'depositProviderType',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>
    </>
  );
};
