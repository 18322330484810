import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import {
  Radio,
  Row,
  Col,
  Select,
  Input,
  Button,
  Modal,
  message,
  Tooltip,
} from 'antd';
import { DynamicObj } from 'interfaces/user.interface';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  localMessages,
  CREATE_CATEGORY,
  PROMOS,
  PROMO_CATEGORIES,
} from '../utils';
import { usePMAContext } from '../context';

export const PMAPromosAttribution: React.FC = () => {
  const {
    setState,
    state: { isShow, useExcel },
    form: { control, setValue, getValues, errors },
  } = usePMAContext();

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    `${ALL_PERMISSIONS.ALL_PROMOS.PROMOS_PROMO_LISTING}:PROMO`
  );

  const [isActual, setActual] = useState(true);
  const [isCategory, setCategory] = useState(false);
  const [promoList, setPromoList] = useState<Array<{ id: string }>>([]);
  const [categoryList, setCategoryList] = useState<Array<{ id: string }>>([]);

  const [loadPromos, { error, loading: loadingPromos }] = useLazyQuery(PROMOS, {
    fetchPolicy: 'network-only',
    onCompleted: (data: DynamicObj) => {
      const promos = coercedGet(data, 'promos.edges', []).map(
        ({ node }: { node: DynamicObj }) => node
      );

      setState({ loadingQuery: false });
      setPromoList(promos);
      if (promos.length) setValue('promo', promos[0].id);
    },
  });

  const [loadCategories, { loading: loadingCategories }] = useLazyQuery(
    PROMO_CATEGORIES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data: DynamicObj) => {
        const categories = coercedGet(data, 'promoCategories', [{ id: '' }]);

        setState({ loadingQuery: false });
        setCategoryList(categories);
        if (categories.length) setValue('category', categories[0].id);
      },
    }
  );

  const [createCategory] = useMutation(CREATE_CATEGORY);
  const translate = useTranslate();

  useEffect(() => {
    if (isShow && ALLOWED_LIST) {
      setState({ loadingQuery: true });
      setActual(true);
      loadPromos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, loadPromos, setState]);

  const handleCreateCategory = async () => {
    try {
      await createCategory({
        variables: {
          input: { name: getValues('categoryName') },
        },
      });

      loadCategories();
      setCategory(false);
      message.success(translate(localMessages.NEW_PROMO_CATEGORY_CREATED));
      // eslint-disable-next-line no-empty
    } finally {
    }
  };

  if (error) return <span>Error! {error.message}</span>;

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          {/* AMOUNT */}
          <div style={{ display: useExcel ? 'none' : 'block' }}>
            <span className="fs-13">
              <FormattedMessage id="amount.text" defaultMessage="Amount" />
            </span>

            <Controller
              as={Input}
              control={control}
              name="amount"
              style={{ width: '100%' }}
            />

            <span style={{ color: 'red' }}>{errors.amount?.message}</span>
          </div>

          <div className="mt-2">
            <span className="fs-13">
              <FormattedMessage
                id="attribution-to-promos.text"
                defaultMessage="Attribution to Promos"
              />
            </span>
          </div>

          <Controller
            control={control}
            name="actual"
            render={({ value, onChange }) => (
              <Radio.Group
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  setActual(e.target.value);
                  if (!e.target.value) {
                    setState({ loadingQuery: true });
                    loadCategories();
                  } else setValue('promoInput', null);
                }}
              >
                <Radio
                  value
                  style={{
                    width: '115px',
                  }}
                >
                  <FormattedMessage id="yes.text" defaultMessage="Yes" />
                </Radio>

                <Radio value={false}>
                  <FormattedMessage id="no.text" defaultMessage="No" />
                </Radio>
              </Radio.Group>
            )}
          />
        </Col>
      </Row>

      <div className="mt-2">
        <span className="fs-13">
          <FormattedMessage id="promo-name.text" defaultMessage="Promo Name" />
        </span>
      </div>

      <div style={{ position: 'relative', height: isActual ? 30 : 75 }}>
        <Row gutter={16}>
          <Col
            span={12}
            style={{
              opacity: isActual ? 1 : 0,
              pointerEvents: isActual ? 'auto' : 'none',
            }}
          >
            <Controller
              control={control}
              name="promo"
              defaultValue={promoList[0]?.id || null}
              render={({ value, onChange }) => (
                <Tooltip
                  placement="right"
                  title={
                    !ALLOWED_LIST && 'Need Permission on Promo Listing (List)'
                  }
                >
                  <Select
                    style={{ width: '100%' }}
                    loading={loadingPromos}
                    onChange={(e: any) => onChange(e.toString())}
                    value={value}
                    defaultValue={value}
                    defaultActiveFirstOption
                    disabled={!ALLOWED_LIST}
                  >
                    {promoList.map((promo: DynamicObj) => (
                      <Select.Option key={promo.id} value={promo.id}>
                        {promo.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Tooltip>
              )}
            />
          </Col>
        </Row>

        <Row
          gutter={16}
          style={{
            opacity: isActual ? 0 : 1,
            position: 'absolute',
            left: 0,
            top: 0,
            pointerEvents: isActual ? 'none' : 'auto',
            width: '560px',
          }}
        >
          <Col span={12}>
            <Controller
              rules={{ required: true, maxLength: 32 }}
              control={control}
              name="promoInput"
              style={{ resize: 'none' }}
              render={({ value, onChange }) => (
                <Input value={value} onChange={(e) => onChange(e)} />
              )}
            />

            <span style={{ color: 'red' }}>{errors.promoInput?.message}</span>
          </Col>

          <Col span={12} style={{ transform: 'translateY(-21px)' }}>
            <span className="fs-13">
              <FormattedMessage id="category.text" defaultMessage="Category" />
            </span>

            <Controller
              control={control}
              name="category"
              defaultValue={categoryList[0]?.id || null}
              render={({ value, onChange }) => (
                <Select
                  style={{ width: '100%' }}
                  loading={loadingCategories}
                  onChange={(e: any) => onChange(e.toString())}
                  value={value}
                >
                  {categoryList.map(
                    (category: any) =>
                      category?.id && (
                        <Select.Option key={category.id} value={category.id}>
                          {category.name}
                        </Select.Option>
                      )
                  )}
                </Select>
              )}
            />

            <Button
              className="mt-1"
              style={{ width: '100%' }}
              onClick={() => setCategory(true)}
            >
              <FormattedMessage
                id="CREATE_CATEGORY"
                defaultMessage="Create Category"
              />
            </Button>

            {/* CREATE CATEGORY */}
            {isCategory && (
              <Modal
                title={
                  <FormattedMessage
                    id="CATEGORY_NAME"
                    defaultMessage="Category Name"
                  />
                }
                visible={isCategory}
                onCancel={() => setCategory(false)}
                onOk={handleCreateCategory}
                centered
              >
                <Controller
                  as={Input}
                  control={control}
                  name="categoryName"
                  style={{ resize: 'none' }}
                />
              </Modal>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};
