import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/react-hooks';
import { Select, message } from 'antd';
import coercedGet from 'utils/coercedGet';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { DepositProvidersConnectionEdge } from 'types/graphqlTypes';
import { DEPOSIT_PROVIDERS } from './queries';

const { Option } = Select;

type Props = {
  onChange: (e: Array<string> | string) => void;
  placeHolder?: string;
  value: Array<string> | string | undefined;
  disabled?: boolean;
  multiple?: boolean;
  labelAsValue?: boolean;
  queryFilter?: { [key: string]: any };
};

const DepositProvidersSelect: React.FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
  labelAsValue,
  queryFilter,
}) => {
  const [filter, setFilter] = useState({
    name: { contains: '' },
  });
  const translate = useTranslate();

  const newFilter = { ...filter, ...queryFilter };
  const variables = {
    filter: newFilter,
    first: 10,
  };

  const { loading, data = {}, refetch } = useQuery(DEPOSIT_PROVIDERS, {
    variables,
    context: {
      component: true,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      message.error({
        content: (
          <span data-testid="errorDepositProvidersSelect">
            {translate(messages.INTERNAL_SERVER_ERROR)}
          </span>
        ),
      });
    },
  });

  const handleSearch = (e: string) => {
    setFilter({
      name: {
        contains: e.toUpperCase(),
      },
    });
    refetch();
  };
  const handleChange = (e: string | Array<string>) => {
    onChange(e);
  };

  const edges = coercedGet(data, 'depositProviders.edges', []);

  return (
    <Select
      data-testid="nameFilter"
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      loading={loading}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
    >
      {// eslint-disable-next-line no-shadow
      edges.map((edge: DepositProvidersConnectionEdge) => (
        <Option
          data-testid={`nameFilter_${edge.node?.id}`}
          key={edge.node?.id}
          value={labelAsValue ? edge.node?.name : edge.node?.id}
        >
          {edge.node?.name}
        </Option>
      ))}
    </Select>
  );
};

export default DepositProvidersSelect;
