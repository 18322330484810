import { defineMessages } from 'react-intl';

const localMessages = defineMessages({
  ADD_NEW_REBATE_GROUP: {
    id: 'ADD_NEW_REBATE_GROUP',
    defaultMessage: 'Add new rebate group',
  },
});

export default localMessages;
