import gql from 'graphql-tag';

export const CREATE_DEPOSIT_PROVIDER = gql`
  mutation createDepositProvider(
    $type: DepositProviderType!
    $input: CreateDepositProviderInput!
  ) {
    createDepositProvider(type: $type, input: $input)
  }
`;

export const DELETE_DEPOSIT_PROVIDER = gql`
  mutation deleteDepositProvider($id: ID!) {
    deleteDepositProvider(id: $id)
  }
`;

export const UPDATE_DEPOSIT_PROVIDER = gql`
  mutation updateDepositProvider(
    $id: ID!
    $input: UpdateDepositProviderInput!
  ) {
    updateDepositProvider(id: $id, input: $input)
  }
`;

export default {};
