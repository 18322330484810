import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { ALink } from 'components/ALink/ALink';
import { useInView } from 'react-intersection-observer';
import { Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import { useDebounce } from 'react-use';
import { MEMBER_MANAGEMENT } from 'constants/testIds';
import { MEMBER_TAGS } from './queries';
import { StyledColorSquare } from './styles';
import ColorPicker from '../ColorPicker/ColorPicker';

type Props = {
  onSelect: (e: Record<string, any>) => void;
  value: Record<string, any>[];
  input: Record<string, any>;
  handleColor: (e: string) => void;
  handleSubmit: () => void;
};

const MemberTagList = ({
  onSelect,
  value,
  input,
  handleColor,
  handleSubmit,
}: Props) => {
  const [tags, setTags] = useState<Record<string, any>[]>([]);
  const [endCursor, setEndCursor] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const client = useApolloClient();

  const tagsLoader = useCallback(
    async (variables) => {
      setLoading(true);
      const { data } = await client.query({
        query: MEMBER_TAGS,
        fetchPolicy: 'network-only',
        variables,
      });

      const edges = coercedGet(data, 'memberTags.edges', []);
      const nodes = edges.map((edge: Record<string, any>) => edge.node);
      const endCursorData = coercedGet(
        data,
        'memberTags.pageInfo.endCursor',
        ''
      );
      const totalCountData = coercedGet(data, 'memberTags.totalCount', 0);
      setLoading(false);
      return { nodes, endCursorData, totalCountData };
    },
    [client]
  );

  const loadMoreTags = useCallback(
    async (variables) => {
      const { nodes, endCursorData, totalCountData } = await tagsLoader(
        variables
      );
      setTags((prevTags) => [...prevTags, ...nodes]);
      setEndCursor(endCursorData);
      setTotalCount(totalCountData);
    },
    [tagsLoader]
  );

  const loadInitialTags = useCallback(
    async (variables) => {
      const { nodes, endCursorData, totalCountData } = await tagsLoader(
        variables
      );
      setTags(nodes);
      setEndCursor(endCursorData);
      setTotalCount(totalCountData);
    },
    [tagsLoader]
  );

  const [, cancel] = useDebounce(
    () => {
      loadInitialTags({
        first: 12,
        filter: {
          name: {
            contains: input.name || '',
          },
        },
      });
    },
    400,
    [input.name]
  );

  useEffect(() => {
    cancel();
    loadInitialTags({ first: 12 });
  }, [cancel, loadInitialTags]);

  useEffect(() => {
    if (inView) {
      loadMoreTags({
        first: 12,
        after: endCursor,
        ...(input.name && {
          filter: {
            name: {
              contains: input.name,
            },
          },
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const options = tags.filter(
    (item) => !value.map((val) => (val ? val.id : null)).includes(item.id)
  );

  return (
    <Spin spinning={loading}>
      {options.length ? (
        <div data-testid={MEMBER_MANAGEMENT.memberTagListOptions}>
          {options.map((item) => (
            <div className="p-1" key={item.id}>
              <ALink onClick={() => onSelect(item)} role="link">
                <StyledColorSquare color={item.color} className="mr-1" />
                <span>{item.name}</span>
              </ALink>
            </div>
          ))}
          {totalCount > tags.length && (
            <div className="text-center p-1" ref={ref}>
              <Spin spinning={loading}>
                <FormattedMessage
                  id="loading.text"
                  defaultMessage="Loading..."
                />
              </Spin>
            </div>
          )}
        </div>
      ) : null}

      {input.name && input.name.length > 0 && (
        <ColorPicker
          name={input.name}
          value={input.color}
          onChange={handleColor}
          onCreate={handleSubmit}
        />
      )}
    </Spin>
  );
};

export default MemberTagList;
