/* eslint-disable no-bitwise */
import React, { useEffect } from 'react';
import { Table, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import { TablePaginationConfig } from 'antd/es/table';
import { Key } from 'antd/lib/table/interface';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import isRow from 'utils/isRow';
import { getRowColumns, getUkColumns } from './utils';

type DailyOperatingReportTableType = {
  dataSource: { [key: string]: any }[];
  loading: boolean;
  handleSort: (order: 'ascend' | 'descend') => void;
};

const { Text } = Typography;

// Table Component
const DailyOperatingReportTable: React.FC<DailyOperatingReportTableType> = ({
  dataSource,
  loading,
  handleSort,
}) => {
  const { setTableColumns } = useSpreadsheetContext();

  const { bypassedCurrentTimezone } = useReportsTimezone();

  const columns: Record<string, any>[] = isRow
    ? getRowColumns({ bypassedCurrentTimezone })
    : getUkColumns({ bypassedCurrentTimezone });

  // Side Effects
  useEffect(() => {
    setTableColumns(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Jsx rendered
  return (
    <div data-testid="daily-operating-table">
      <Table
        columns={columns}
        bordered
        size="small"
        dataSource={dataSource}
        loading={loading}
        onChange={(
          _pagination: TablePaginationConfig,
          _filters: Record<string, (Key | boolean)[] | null>,
          sorter: Record<string, any>
        ) => {
          handleSort(sorter.order);
        }}
        footer={() => (
          <Text strong>
            <FormattedMessage
              id="reports.daily-operation-report-footer-message.text"
              defaultMessage="All Members - Deposit and Withdrawals: No Manual Deposit"
            />
          </Text>
        )}
        pagination={false}
        scroll={{ x: 'calc(525px + 50%)' }}
      />
    </div>
  );
};

export default DailyOperatingReportTable;
