import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import navMessages from 'constants/navMessages';
import {
  StyledCardContainer,
  StyledTabPane,
} from 'pages/components/ScreenTab/styles';
import useEventListener from '@use-it/event-listener';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  Tab,
  ScreenTabState,
  setActiveTab,
  removeTab,
} from 'store/screenTabState';
import cx from 'classnames';
// import { useClearCacheCtx } from 'react-clear-cache';
import { ErrorHandler } from 'components/ErrorHandler';

import { usePermissions } from 'store/accountState';
import { ROLES } from 'constants/permissions3';

import { TabComponent } from './components/TabComponent';
import DraggableTabs from '../ScreenTab/DraggableTabs';
import { customFormatMessage } from '../../../utils/customFormatMessage';

export const ScreenTabsNew: React.FC = () => {
  // const { isLatestVersion } = useClearCacheCtx();
  const [tabsSameSize, setTabsSameSize] = useState(false);
  const { role, permissions } = usePermissions();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const isDesktop = useMediaQuery({
    query: '(min-width: 1025px) and (max-width: 1200px)',
  });
  const isBigScreen = useMediaQuery({
    query: '(min-width: 1201px) and (max-width: 1440px)',
  });
  const isExtraBigScreen = useMediaQuery({
    query: '(min-width: 1441px) and (max-width: 1905px)',
  });

  const { tabs, activeKey } = useSelector(
    ({ screenTab }: { screenTab: ScreenTabState }) => screenTab
  );

  const isAutoSize =
    (isTablet && tabs.length > 4) ||
    (isDesktop && tabs.length > 5) ||
    (isBigScreen && tabs.length > 8) ||
    (isExtraBigScreen && tabs.length > 10);

  useEventListener('resize', () => {
    if (isAutoSize) {
      setTabsSameSize(true);
    } else {
      setTabsSameSize(false);
    }
  });

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      window.dispatchEvent(new Event('resize'));
    }

    return () => {
      mounted = false;
    };
  }, [isAutoSize, tabs]);

  /* 
      This is a 1st level permission validation whether to allow 
      some tabs to be still be shown by default and keep on being shown.
      This does not affect the admin user only the operators.
  */

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (role === ROLES.OPERATOR) {
        tabs.forEach(({ state, key }) => {
          if (!permissions.find((p) => p === state.permission)) {
            dispatch(removeTab(key.toString()));
          }
        });
      }
    }

    return () => {
      mounted = false;
    };

    /*  removing tabs as a dependency, as it prevents other "non-permissions-dependent" 
    /*  tabs to be added to screenTabs  
     */

    // eslint-disable-next-line
  }, [role, permissions, dispatch]);

  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);

  const getTabName = (tab: Tab) =>
    tab.memberId && !tab.state.translateOpts
      ? tab.state.username
      : translate(
          navMessages[`${tab.state.translateKeyId || tab.id}.text`],
          tab.state.translateOpts
        );

  const handleChangeTab = (key: string) => {
    dispatch(setActiveTab(key));
  };

  const handleRemoveTab = (
    key: string | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    dispatch(removeTab(key.toString()));
  };

  return tabs.length ? (
    <StyledCardContainer
      className="screen-tab"
      tabsSameSize={tabsSameSize}
      updateAvailable={false}
      globalSidebar
    >
      <DraggableTabs
        type={tabs.length > 1 ? 'editable-card' : 'card'}
        activeKey={activeKey}
        onChange={handleChangeTab}
        onEdit={handleRemoveTab}
        hideAdd
      >
        {tabs.map((tab: Tab) => (
          <StyledTabPane
            tab={
              <div className={cx('d-inline-block', { 'd-flex': tabsSameSize })}>
                <div className={cx({ truncate: tabsSameSize })}>
                  {getTabName(tab)}
                </div>
              </div>
            }
            key={tab.key}
            forceRender={tab.id !== 'member-detail'}
          >
            <ErrorHandler>
              <TabComponent id={tab.id} />
            </ErrorHandler>
          </StyledTabPane>
        ))}
      </DraggableTabs>
    </StyledCardContainer>
  ) : null;
};
