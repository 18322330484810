import { useApolloClient } from '@apollo/react-hooks';
import { message } from 'antd';
import axios from 'axios';
import Storage from 'constants/storage';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAccount } from 'store/accountState';
import { resetTabs } from 'store/screenTabState';

interface Props {
  infoMessage: string;
}

export const useLogout = () => {
  const { account, resetAccount } = useAccount();
  const client = useApolloClient();
  const dispatch = useDispatch();
  const history = useHistory();

  const { account: user } = account;

  const { config } = window;

  const accessToken = localStorage.getItem(Storage.ACCESS_TOKEN);

  const logout = async ({ infoMessage }: Props) => {
    try {
      // call /session:destroy only on operator accounts temporarily
      if (user?.role === 'OPERATOR') {
        await axios.post(`${config.authUrl}/session:destroy`, null, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
      }
    } finally {
      localStorage.removeItem(Storage.ACCESS_TOKEN);
      localStorage.removeItem(Storage.BO_ACCESS_TOKEN);
      localStorage.removeItem(Storage.REFRESH_TOKEN);
      localStorage.removeItem(Storage.APP_CONFIG);
      sessionStorage.removeItem(Storage.SCREEN_TAB_CONFIG);
      sessionStorage.removeItem(`${Storage.SCREEN_TAB_CONFIG}_NEW`);
      localStorage.removeItem(Storage.FAVORITE);
      localStorage.removeItem(Storage.AUTHENTICATE_VIA_OTP);
      client.cache.reset();
      resetAccount();
      dispatch(resetTabs());
      history.push('/signin');

      message.info(infoMessage);
    }
  };

  return {
    logout,
  };
};
