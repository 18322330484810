import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Modal, notification } from 'antd';
import { DUPLICATE_REBATE_GROUP } from 'graphql/mutations/rebateGroup.mutation';
import {
  RebateGroupTypes,
  useRebateGroupState,
} from 'pages/components/Rebates/context';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import messages from './messages';

const DuplicateRebateGroup = (props: { refetch: () => void }) => {
  const { refetch } = props;

  const [rebateGroupState, rebateGroupDispatch] = useRebateGroupState() as any;
  const toggleDuplicate = () => {
    rebateGroupDispatch({
      type: RebateGroupTypes.TOGGLE_DUPLICATE,
    });
  };
  const { duplicateShown } = rebateGroupState;
  const translate = useTranslate();
  const [duplicateRebateGroup, { loading }] = useMutation(
    DUPLICATE_REBATE_GROUP,
    {
      onCompleted: () => {
        notification.success({
          message: translate(messages['rebates.rebate-group-duplicated.text']),
        });
        refetch();
        toggleDuplicate();
      },
    }
  );

  return (
    <Modal
      visible={duplicateShown}
      maskClosable={false}
      closable={false}
      width={416}
      okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
      cancelText={<FormattedMessage id="cancel.text" defaultMessage="Cancel" />}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleDuplicate}
      onOk={async () => {
        await duplicateRebateGroup({
          variables: {
            id: rebateGroupState.activeRebateGroup,
          },
        });
        rebateGroupDispatch({
          type: RebateGroupTypes.RESET_ACTIVE_REBATE_GROUP,
        });
      }}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">
          <FormattedMessage id="confirm.text" defaultMessage="Confirm" />{' '}
          <FormattedMessage id="duplicate.text" defaultMessage="Duplicate" />
        </span>
        <div className="ant-modal-confirm-content">
          <FormattedMessage
            id="are-you-sure.text"
            defaultMessage="Are you sure?"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DuplicateRebateGroup;
