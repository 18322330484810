import React from 'react';
import { ALink } from 'components/ALink/ALink';
import messages from 'messages';
import coercedGet from 'utils/coercedGet';
import { Member, MemberAccessSummaryReportItem } from 'types/graphqlTypes';
import moment from 'moment';
import { columnAlign } from 'utils/tableAlignment';
import MemberProfile from './MemberProfile';
import AffiliateStatus from './AffiliateStatus';

type Translator = (messageArgs: {
  id: string;
  defaultMessage: string;
}) => string;

type HandleClick = ({ id, username }: { id: string; username: string }) => void;
const getColumns = (translate: Translator, handleClick: HandleClick) => [
  {
    csvData: {
      label: translate(messages['username.text']),
      key: 'username',
      renderCell: ({ member }: MemberAccessSummaryReportItem) =>
        coercedGet(member, 'username', ''),
    },
    key: 'member',
    title: translate(messages['username.text']),
    align: columnAlign.onCenter,
    width: 150,
    render: (member: any) => <MemberProfile member={member.member} />,
  },
  {
    csvData: {
      label: translate(messages['real-name.text']),
      key: 'realName',
      renderCell: ({ member }: MemberAccessSummaryReportItem) =>
        coercedGet(member, 'realName', ''),
    },
    key: 'realName',
    title: translate(messages['real-name.text']),
    dataIndex: 'member',
    align: columnAlign.onCenter,
    width: 250,
    render: (member: any) => member.realName,
  },
  {
    csvData: {
      label: 'Brand ID',
      key: 'brandId',
      renderCell: ({ member }: MemberAccessSummaryReportItem) =>
        coercedGet(member, 'brandId', ''),
    },
    key: 'brandId',
    title: 'Brand ID',
    dataIndex: 'member',
    align: columnAlign.onCenter,
    width: 250,
    render: (member: Member) => member.brandId,
  },
  {
    csvData: {
      label: 'Platform ID',
      key: 'platformId',
      renderCell: ({ member }: MemberAccessSummaryReportItem) =>
        coercedGet(member, 'platformId', ''),
    },
    key: 'platformId',
    title: 'Platform ID',
    dataIndex: 'member',
    align: columnAlign.onCenter,
    width: 250,
    render: (member: Member) => member.platformId,
  },
  {
    csvData: {
      label: translate(messages.AFFILIATE),
      key: 'affiliate',
      renderCell: ({ member }: MemberAccessSummaryReportItem) =>
        coercedGet(member, 'affiliate.code', ''),
    },
    key: 'affiliate',
    title: translate(messages.AFFILIATE),
    dataIndex: 'member',
    align: columnAlign.onCenter,
    width: 150,
    render: (node: any) => <AffiliateStatus node={node} />,
  },
  {
    csvData: {
      label: 'Last Login Date Time',
      key: 'lastLoginDateTime',
      renderCell: ({ lastLoginDateTime }: MemberAccessSummaryReportItem) =>
        moment(lastLoginDateTime).format('MMM DD, YYYY hh:mm:ss A'),
    },
    key: 'lastLoginDateTime',
    title: translate(messages.LAST_LOGIN_DATE_TIME_REPORT),
    dataIndex: 'lastLoginDateTime',
    align: columnAlign.onCenter,
    width: 200,
    render: (lastLoginDateTime: string) =>
      lastLoginDateTime
        ? moment(lastLoginDateTime).format('MMM DD, YYYY hh:mm:ss A')
        : '-',
  },
  {
    csvData: {
      label: 'Ip Address Total Count',
      key: 'ipAddressesCount',
      renderCell: ({ ipAddressesCount }: MemberAccessSummaryReportItem) =>
        ipAddressesCount,
    },
    key: 'IPAddressTotalCount',
    title: translate(messages.IP_ADDRESS_TOTAL_COUNT),
    align: columnAlign.onCenter,
    width: 100,
    render: ({
      ipAddressesCount,
      member: { id, username },
    }: MemberAccessSummaryReportItem) => (
      <ALink onClick={() => handleClick({ id, username })}>
        {ipAddressesCount}
      </ALink>
    ),
  },
];

export default getColumns;
