import styled from 'styled-components';
import { Table } from 'antd';

export const NewStyledTable = styled(Table)`
  .ant-table-body {
    overflow: auto;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-small {
    border-top: none !important;
    border-bottom: none;
    .ant-table-content {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  .ant-table-fixed .ant-table-thead {
    background: #fafafa !important;
  }

  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }

  .ant-table-tbody {
    .column-number {
      text-align: right !important;
    }
  }

  .ant-table-scroll {
    max-height: calc(100vh - 199px);
    overflow: auto;

    .ant-table-body {
      overflow: auto !important;
    }
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td {
    min-height: 59px !important;
  }

  tbody tr {
    min-height: 59px !important;
  }

  tbody thead {
    height: 59px !important;
  }

  .ant-table-body {
    max-height: calc(100vh - 284px) !important;

    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .ant-table-scroll {
    overflow-y: hidden;

    .ant-table-header thead tr {
      height: 59px !important;
    }
  }

  .ant-table-fixed-left {
    height: 98%;

    .ant-table-body-inner {
      &::-webkit-scrollbar {
        background: transparent;
      }

      &::-moz-scrollbar {
        background: transparent;
      }

      &::-webkit-scrollbar-button {
        background: transparent;
      }

      &::-moz-scrollbar-button {
        background: transparent;
      }

      max-height: calc(
        100vh -
          ${({ widthLessThan1440 }: any) =>
            widthLessThan1440 ? '284px' : '269px'}
      ) !important;
    }
  }

  .ant-table-fixed-right {
    height: 100%;
    transform: translateX(19px) !important;
    box-shadow: none;

    ::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 10px;
      height: 59px;
      box-shadow: -5px 0 10px 5px #fafafa;
      transition: 0.65s;
      opacity: ${({ widthLessThan1440 }: any) => (widthLessThan1440 ? 1 : 0)};
    }

    .ant-table-body-inner {
      overflow-y: hidden;
      &::-webkit-scrollbar {
        background: transparent;
      }

      &::-moz-scrollbar {
        background: transparent;
      }

      &::-webkit-scrollbar-button {
        background: transparent;
      }

      &::-moz-scrollbar-button {
        background: transparent;
      }

      overflow-y: hidden !important;
      max-height: calc(
        100vh -
          ${({ widthLessThan1440 }: any) =>
            widthLessThan1440 ? '284px' : '269px'}
      ) !important;
    }
  }

  .ant-table-fixed-right {
    overflow: visible !important;
  }

  .ant-table-fixed-right .ant-table-tbody tr:nth-child(1) {
    position: relative;
    background: white;

    ::after {
      content: '';
      position: absolute;
      left: 0;
      width: 10px;
      height: 100%;
      box-shadow: -5px 0 10px 5px #fff;
      transition: 0.65s;
      opacity: ${({ widthLessThan1440 }: any) => (widthLessThan1440 ? 1 : 0)};
    }
  }

  .ant-table.ant-table-small.ant-table-fixed-header.ant-table-layout-fixed.ant-table-scroll-position-left {
    height: calc(100vh - 205px) !important;
  }

  .ant-table-header {
    height: 59px !important;
  }
`;

export const Wrapper = styled.div`
  .ant-table-wrapper {
    width: 90px;
  }
`;

export const StyledColor = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background-color: ${(props) => props.color || '#efefef'};
  margin-right: 2px;
`;

export default {};
