import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { DownOutlined } from '@ant-design/icons';
import { Spin, Dropdown, Menu, Checkbox, Badge } from 'antd';
import { FormattedMessage } from 'react-intl';

export const VENDORS = gql`
  query {
    vendors {
      edges {
        node {
          id
          name(language: EN)
          shortcode
        }
      }
    }
  }
`;

type Props = {
  value: string[];
  onChange: (e: any) => void;
};

export const VendorsSelect = ({ value, onChange }: Props) => {
  const { data, loading } = useQuery(VENDORS);
  const [visible, setVisible] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState<string[]>(value || []);

  if (loading) {
    return <Spin />;
  }

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  const handleMenuClick = (e: Record<string, any>) => {
    if (e.key === '3') {
      setVisible(false);
    }
  };

  const onSelectVendor = (vendor: Record<string, any>) => {
    const idExists = selectedVendors.find((id) => id === vendor.id);
    if (idExists) {
      const removed = selectedVendors.filter((y) => y !== idExists);
      setSelectedVendors(removed);
      onChange(removed);
    } else {
      const added = selectedVendors.concat([vendor.id]);
      setSelectedVendors(added);
      onChange(added);
    }
  };

  const checkedVendor = (vendorId: string) =>
    !!selectedVendors.find((x) => x === vendorId);

  const vendorsEdges = data?.vendors?.edges;
  const vendors = vendorsEdges.map((edge: Record<string, any>) => edge.node);

  return (
    <>
      <p className="fw-600 fs-13">
        <FormattedMessage id="VENDOR" defaultMessage="Vendor" />
      </p>
      <Dropdown
        onVisibleChange={handleVisibleChange}
        visible={visible}
        overlay={
          <Menu
            onClick={handleMenuClick}
            style={{ height: '300px', overflow: 'auto' }}
          >
            {vendors.map((vendor: Record<string, any>) => (
              <Menu.Item key={vendor.id} onClick={() => onSelectVendor(vendor)}>
                <Checkbox checked={checkedVendor(vendor.id)} /> {vendor.nameEn}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div className="d-flex align-items-center cursor-pointer">
          <Badge
            showZero
            className="mr-3"
            count={selectedVendors.length}
            style={{ background: '#1890ff' }}
          />
          <DownOutlined />
        </div>
      </Dropdown>
    </>
  );
};
