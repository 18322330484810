import gql from 'graphql-tag';

export const PAYMENT_METHODS = gql`
  query PaymentMethods(
    $first: Int
    $after: Binary
    $filter: PaymentMethodsFilterInput
  ) {
    paymentMethods(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          remarks
          enabled
          currentAccumulation
          minimumDepositAmount
          maximumDepositAmount
          instructionText
          turnoverRequirementMultiplier
          maximumDepositAmountPerDay
          depositExpiry
          suggestedAmounts
          updateable
          ... on OfflineBankTransferPaymentMethod {
            bank
            accountNumber
          }

          ... on AlipayPaymentMethod {
            nickname
            image {
              id
              uri
            }
          }

          ... on WechatPaymentMethod {
            nickname
            image {
              id
              uri
            }
          }
          ... on OnlineBankTransferPaymentMethod {
            depositProvider {
              id
              name
            }
          }

          ... on OnlineAlipayPaymentMethod {
            depositProvider {
              id
              name
            }
          }

          ... on OnlineWechatPaymentMethod {
            depositProvider {
              id
              name
            }
          }
          ... on CryptocurrencyPaymentMethod {
            cryptocurrency
            blockchain
            blockchainAddress
            image {
              id
              uri
            }
          }
          ... on UnionPayPaymentMethod {
            depositProvider {
              id
              name
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const PAYMENT_METHODS_CSV_DATA = gql`
  query PaymentMethods_CSV(
    $first: Int
    $after: Binary
    $filter: PaymentMethodsFilterInput
  ) {
    paymentMethods(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          remarks
          enabled
          currentAccumulation
          minimumDepositAmount
          maximumDepositAmount
          instructionText
          turnoverRequirementMultiplier
          maximumDepositAmountPerDay
          depositExpiry
          suggestedAmounts
          updateable
          ... on OfflineBankTransferPaymentMethod {
            bank
            accountNumber
          }

          ... on AlipayPaymentMethod {
            nickname
            image {
              id
              uri
            }
          }

          ... on WechatPaymentMethod {
            nickname
            image {
              id
              uri
            }
          }
          ... on OnlineBankTransferPaymentMethod {
            depositProvider {
              id
              name
            }
          }

          ... on OnlineAlipayPaymentMethod {
            depositProvider {
              id
              name
            }
          }

          ... on OnlineWechatPaymentMethod {
            depositProvider {
              id
              name
            }
          }
          ... on CryptocurrencyPaymentMethod {
            cryptocurrency
            blockchain
            blockchainAddress
            image {
              id
              uri
            }
          }
          ... on UnionPayPaymentMethod {
            depositProvider {
              id
              name
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const PAYMENT_METHOD = gql`
  query PaymentMethod($id: ID!) {
    paymentMethod(id: $id) {
      id
      name
      remarks
      enabled
      memberLoyaltyLevel {
        id
        name
        color
        programme {
          id
          name
        }
      }
      excludedMemberLevel {
        id
        name
        color
      }
      allowCustomAmount
      minimumDepositAmount
      maximumDepositAmount
      instructionText
      turnoverRequirementMultiplier
      maximumDepositAmountPerDay
      depositExpiry
      suggestedAmounts
      transactionFee
      transactionFeeType
      updateable
      ... on OfflineBankTransferPaymentMethod {
        bank
        accountNumber
        accountName
        branch
        country
        province
        city
      }

      ... on AlipayPaymentMethod {
        nickname
        image {
          id
          uri
        }
      }

      ... on WechatPaymentMethod {
        nickname
        image {
          id
          uri
        }
      }

      ... on OnlineBankTransferPaymentMethod {
        depositProvider {
          id
          name
        }
      }

      ... on OnlineAlipayPaymentMethod {
        paymentChannel
        depositProvider {
          id
          name
        }
      }

      ... on OnlineWechatPaymentMethod {
        paymentChannel
        depositProvider {
          id
          name
        }
      }
      ... on CryptocurrencyPaymentMethod {
        cryptocurrency
        blockchain
        blockchainAddress
        exchangeRateSpread
        exchangeRateSource
        exchangeRate
        exchangeRateUpdateFrequency
        image {
          id
          uri
        }
        minimumConfirmationsCount
      }
      ... on UnionPayPaymentMethod {
        paymentChannel
        depositProvider {
          id
          name
        }
      }
    }
  }
`;

export const GET_PAYMENT_METHODS = gql`
  query EnabledPaymentMethods(
    $first: Int
    $after: Binary
    $filter: PaymentMethodsFilterInput
  ) {
    paymentMethods(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export default {};
