export const getAllIndexes = (
  arr: string[] | number[],
  val: string | number
) => {
  const indexes = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === val) {
      indexes.push(i);
    }
  }

  return indexes;
};
