import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import useTranslate from 'utils/useTranslate';
import { StyledSwitch } from '../../styles';
import messages from '../../../../messages';

type Props = {
  formItemLayout: Record<string, any>;
  setFieldValue: (key: string, data: any) => void;
  values: Record<string, any>;
};

const ComplianceCheckForm = (props: Props) => {
  const { formItemLayout, setFieldValue, values } = props;
  const translate = useTranslate();
  return (
    <>
      <Form.Item className="mb-0 text-right" wrapperCol={{ span: 6 }}>
        <strong>{translate(messages.automaticComplianceCheck)}</strong>
      </Form.Item>
      <Form.Item
        className="mb-5"
        label={translate(messages.status)}
        {...formItemLayout}
      >
        <StyledSwitch
          name="enabled"
          onChange={(e: any) => setFieldValue('enabled', e)}
          className="ml-2"
          checkedChildren={translate(messages.enabled)}
          unCheckedChildren={translate(messages.disabled)}
          checked={values.enabled}
        />
      </Form.Item>
    </>
  );
};

export default ComplianceCheckForm;
