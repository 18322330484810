export const updateMemberCacheTags = (tagsId: string[], memberId: string) => (
  cache: Record<string, any>
) => {
  const {
    data: { data: innerData },
  } = cache;
  const memberCacheId = `Member:${memberId}`;
  const cacheDataTag = tagsId.map((tagId) => innerData[`MemberTag:${tagId}`]);
  const memberCacheData = innerData[memberCacheId];
  if (!memberCacheData) {
    return;
  }

  cache.writeData({
    id: memberCacheId,
    data: {
      tags: cacheDataTag,
    },
  });
};
