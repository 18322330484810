import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DynamicObj } from 'interfaces/user.interface';
import FilterItems from './components/FilterItems/FilterItems';

type Props = {
  filters: DynamicObj;
  onFilterChange: (e: any) => void;
};

function Sidebar(props: Props) {
  const { filters, onFilterChange } = props;

  const handleChange = (e: { key: string; value: DynamicObj | null }) => {
    const { key, value } = e;
    onFilterChange((prevFilter: DynamicObj) => ({
      ...prevFilter,
      [key]: value,
    }));
  };

  return (
    <Form layout="vertical">
      <FilterItems onRawFilterChange={handleChange} filters={filters} />
    </Form>
  );
}

export default Sidebar;
