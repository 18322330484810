import gql from 'graphql-tag';

export const PERMISSION_GROUPS = gql`
  query PermissionGroups(
    $first: Int
    $after: Binary
    $filter: PermissionGroupsFilterInput
  ) {
    permissionGroups(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          value: id
          label: name
        }
      }
    }
  }
`;

export const GET_PERMISSION_GROUPS = gql`
  query FilterPermissionGroups(
    $first: Int
    $after: Binary
    $filter: PermissionGroupsFilterInput
  ) {
    permissionGroups(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export default {};
