import React from 'react';
import messages from 'messages';
import { Table } from 'antd';
import useTranslate from 'utils/useTranslate';
import { Status } from '../../utils';
import { useMissionsValue } from '../../context';

const TableData = () => {
  const translate = useTranslate();
  const { data, loading } = useMissionsValue();
  const columns = [
    {
      title: translate(messages.SERIAL_ID),
      dataIndex: 'serialCode',
      key: 'serialCode',
      render: (e: string) => e,
    },
    {
      title: translate(messages.PROGRAMME_NAME),
      dataIndex: 'name',
      key: 'name',
      render: (e: string) => e,
    },
    {
      title: translate(messages.MISSION_TYPE),
      dataIndex: 'type',
      key: 'type',
      render: (e: string) => e,
    },
    {
      title: translate(messages.STATUS),
      dataIndex: 'status',
      key: 'status',
      render: (e: string) => <Status text={e || ''} />,
    },
    {
      title: translate(messages.ENROLMENT_DATE_RANGE),
      dataIndex: 'enrolmentDateRange',
      key: 'enrolmentDateRange',
      render: (e: string) => e,
    },
    {
      title: translate(messages.ENROLLED_MEMBERS),
      dataIndex: 'enrolledMembers',
      key: 'enrolledMembers',
      render: (e: string) => e,
    },
  ];

  const missionsNode = data?.edges.map((x) => x.node);

  return (
    <>
      <Table
        columns={columns}
        pagination={false}
        dataSource={missionsNode}
        loading={loading}
      />
    </>
  );
};

export default TableData;
