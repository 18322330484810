import React from 'react';
import { isEmpty } from 'lodash';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import MemberTagsRenderer from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/RowRenderer/components/MemberTags';
import { Member } from 'types/graphqlTypes';
import { StyledColor } from './styles';

type Props = {
  member: Member;
  refetchParams: Record<string, any>;
};

const MemberTags = ({ member, refetchParams }: Props) => {
  const { tags } = member;
  const previewTags = tags
    .filter((item, idx) => !isEmpty(item) && idx + 1 <= 3)
    .reduce((prev: any, curr: any) => [...prev, curr.color], []);
  const remainingTags = tags.length - 3;

  return (
    <span
      style={{
        float: 'left',
        marginTop: previewTags.length <= 2 ? '8px' : undefined,
      }}
      className={classnames({ 'd-none': tags.length < 1 })}
    >
      <Tooltip
        placement="bottom"
        title={
          <MemberTagsRenderer
            disableClosable
            disableAddLabel
            member={member}
            filter={refetchParams}
          />
        }
        getPopupContainer={() =>
          document.getElementById('member-tags-member-management')!
        }
      >
        <div id="member-tags-member-management">
          <div className="d-flex">
            <StyledColor color={previewTags[0]} />
            <StyledColor color={previewTags[1]} />
          </div>

          <div className="d-flex" style={{ marginTop: '3px' }}>
            <StyledColor color={previewTags[2]} />
            {tags.length - 3 > 0 ? (
              <small style={{ fontSize: '10px', lineHeight: '11px' }}>
                +{remainingTags}
              </small>
            ) : null}
          </div>
        </div>
      </Tooltip>
    </span>
  );
};

export default MemberTags;
