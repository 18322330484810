import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery } from '@apollo/react-hooks';

import { Select } from 'antd';
import { debounce } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { AdminsConnectionEdge, Admin } from 'types/graphqlTypes';

import { CLIENT_NAMES } from './queries';
import { StyledSelect, StyledSpan, StyledLabel } from '../../styles';
import { FilterStateType, SetFilterStateType } from '../../../../types';

const { Option } = Select;

type Props = {
  state: FilterStateType;
  setState: SetFilterStateType;
};

const SerialCodeFilter: React.FC<Props> = ({ state, setState }) => {
  const defaultVars = { first: 10 };
  const [vars, setVars] = React.useState(defaultVars) as any;

  const resetVars = () => setVars(defaultVars);

  const [loadSearch, { data, loading, called }] = useLazyQuery(CLIENT_NAMES, {
    variables: vars,
  }) as any;

  if (!called) loadSearch();

  const edges = coercedGet(data, 'admins.edges', []);
  const options = edges.map((edge: AdminsConnectionEdge) => edge.node);

  const handleChange = (e: Array<string>) => {
    if (e.length) {
      setState((prev: FilterStateType) => ({
        ...prev,
        admin: { in: e },
      }));
    } else {
      setState((prev: FilterStateType) => ({
        ...prev,
        admin: null,
      }));
    }
  };

  const debouncedRefetch = debounce((textInput) => {
    if (textInput.length === 0) {
      loadSearch({
        variables: {
          first: 10,
          filter: {},
        },
      });
    }

    if (textInput.length > 1) {
      loadSearch({
        variables: {
          first: 10,
          filter: {
            username: {
              startsWith: textInput,
            },
          },
        },
      });
    }
  }, 500);

  React.useEffect(() => {
    const idsArr = coercedGet(state, 'admin.in', []);

    const optionsArr = options.map((item: any) => coercedGet(item, 'id', null));

    const isOptNotPresent =
      optionsArr.indexOf(idsArr[0]) === -1 && idsArr.length > 0;

    if (isOptNotPresent) {
      setVars({
        first: 10,
        filter: {
          id: {
            in: [...idsArr],
          },
        },
      });
      return;
    }
    resetVars();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.client-account.text"
              defaultMessage="Client Account"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        showSearch
        allowClear
        mode="multiple"
        // onFocus={() => setPartial('')}
        // onBlur={() => setPartial('')}
        placeholder={
          <FormattedMessage id="reports.search.text" defaultMessage="Search" />
        }
        loading={loading}
        style={{ width: '100%' }}
        value={state.admin ? (state.admin.in as string[]) : []}
        onChange={handleChange as any}
        onSearch={(text) => {
          // eslint-disable-next-line  @typescript-eslint/no-unused-expressions
          debouncedRefetch(text) as any;
        }}
        optionFilterProp="label"
      >
        {options.map((option: Admin) => (
          <Option key={option.id} value={option.id} label={option.username}>
            {option.username}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};

export default SerialCodeFilter;
