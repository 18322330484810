import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'game-vendor-categories.text': {
    id: 'game-vendor-categories.text',
    defaultMessage: 'Game Vendor Categories',
  },
  'game-vendors.text': {
    id: 'game-vendors.text',
    defaultMessage: 'Game Vendors',
  },
  'active-game-vendors.text': {
    id: 'active-game-vendors.text',
    defaultMessage: 'Active Game Vendors',
  },
  'active-games.text': {
    id: 'active-games.text',
    defaultMessage: 'Active Games',
  },
  'status.text': {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  'visibility.text': {
    id: 'visibility.text',
    defaultMessage: 'Visibility',
  },
  'action.text': {
    id: 'action.text',
    defaultMessage: 'Action',
  },
});
