import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { Col, Spin } from 'antd';
import removeDuplicates from 'utils/removeDuplicates';
import MemberLevelDropdownSelect from 'components/MemberLevelDropdownSelect';
import { StyledRow, StyledCol } from './styles';
import { MEMBER_LEVEL } from './queries';
import MemberLevelButton from '../MemberLevelButton';

type Rec = Record<string, any>;

const MemberLevelPreviousNew = ({
  users,
  onUserSelected,
  onChange,
  type,
}: {
  users: any[];
  onUserSelected: (e: any) => void;
  onChange: (e: any) => void;
  type?: string;
}) => {
  const [previous, setPrevious] = useState<any[]>([]);
  const [newNext, setNewNext] = useState([]);

  const userIds = useMemo(() => {
    if (type === 'bulk') {
      return users?.map((user: Rec) => user.key);
    }
    return users;
  }, [type, users]);

  const { loading, data = {} } = useQuery(MEMBER_LEVEL, {
    variables: {
      filter: { id: { in: userIds || [] } },
    },
  }) as Rec;

  useEffect(() => {
    setPrevious([]);
  }, [userIds]);

  if (loading) return <Spin />;

  const { edges = [] }: Rec = data.members;
  const memberLevels = edges.map(({ node }: { node: Rec }) => node.memberLevel);
  const filteredMemberLevels = removeDuplicates(memberLevels, 'id');

  const handleNext = (e: any) => {
    setNewNext(e);
    onChange(e);
  };

  const handlePrevious = (
    id: string,
    e: {
      preventDefault: () => void;
    }
  ) => {
    e.preventDefault();
    let newPrevious = [...previous, id];
    if (previous.includes(id)) {
      newPrevious = [...previous.filter((prevId) => prevId !== id)];
      setPrevious(newPrevious);
    } else setPrevious(newPrevious);
    onUserSelected(
      edges
        .map(({ node }: Rec) => node)
        .filter((b: Rec) => newPrevious.includes(b.memberLevel.id))
    );
  };

  return (
    <StyledRow className="p-2">
      <StyledCol span={12} className="pr-2">
        <div className="mb-2">
          <FormattedMessage
            id="previous-marker.text"
            defaultMessage="Previous marker"
          />
        </div>
        <div>
          {filteredMemberLevels.map((item) => (
            <div className="mr-1 mb-1 float-left" key={item.id}>
              <MemberLevelButton
                memberLevelData={item}
                key={item.id}
                onClick={(e) => handlePrevious(item.id, e)}
                isHighlight={previous.includes(item.id)}
              />
            </div>
          ))}
          <div className="clearfix" />
        </div>
      </StyledCol>
      <Col span={12} className="pl-2">
        <div className="mb-2">
          <FormattedMessage id="new-marker.text" defaultMessage="New marker" />
        </div>
        <MemberLevelDropdownSelect value={newNext} onChange={handleNext} />
      </Col>
    </StyledRow>
  );
};

export default MemberLevelPreviousNew;
