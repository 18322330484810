import React from 'react';
import Layout from 'components/Layout';
import { StateFilter } from 'pages/components/MemberAccessSummaryReport/types';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { MemberAccessSummaryReportItemsFilterInput } from 'types/graphqlTypes';
import SearchSettingsForm from '../SearchSettingsForm';
import FilterItems from '../FilterItems/FilterItems';

type Props = {
  filters: MemberAccessSummaryReportItemsFilterInput;
  stateFilters: StateFilter;
  onFilterChange: (filterData: StateFilter) => void;
  isLoading: boolean;
  resetStateFilter: () => void;
};

const Sidebar = ({
  filters,
  onFilterChange,
  stateFilters,
  isLoading,
  resetStateFilter,
}: Props) => (
  <Layout.Sidebar
    quickSearch={{
      filters: stateFilters,
      contextKey: 'member-access-summary-report',
      onFilterChange,
      quickSearchId: QuickSearchIds.MEMBER_ACCESS_SUMMARY_REPORT,
      isNext: true,
      searchSettingsForm: SearchSettingsForm,
      clearLocalStateFilters: resetStateFilter,
    }}
  >
    <FilterItems
      filters={filters}
      onFilterChange={onFilterChange}
      isLoading={isLoading}
      stateFilters={stateFilters}
    />
  </Layout.Sidebar>
);

export default Sidebar;
