import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useVendorGroupManagementValue } from 'SuperAdminMain/components/VendorManagement/VendorGroupManagement/VendorGroupManagementContext';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const PageEntries: React.FC<Props> = () => {
  const { page, setPage } = useVendorGroupManagementValue();
  const entries = [10, 20, 50, 100];

  return (
    <Container>
      <Select
        defaultValue="10 Entries"
        onChange={(e: any) =>
          setPage({
            ...page,
            first: e,
            after: undefined,
            currentPage: 0,
          })
        }
        value={page.first}
      >
        {entries.map((q, i) => (
          <Select.Option key={i} value={q}>
            {q} <FormattedMessage id="entries.text" defaultMessage="Entries" />
          </Select.Option>
        ))}
      </Select>
    </Container>
  );
};

const Container = styled.div`
  margin-right: 24px;
`;
