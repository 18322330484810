import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'minimum.text': {
    id: 'minimum.text',
    defaultMessage: 'Minimum',
  },
  'maximum.text': {
    id: 'maximum.text',
    defaultMessage: 'Maximum',
  },
});

export const checkValidity = (
  type: string,
  input: { gte: number; lte: number },
  setValid: (value: any) => void
) => {
  const setValidity = (data: any) =>
    setValid((prev: any) => ({ ...prev, ...data }));
  if (type === 'min') {
    if (!input.lte || !input.gte || input.gte <= input.lte) {
      setValidity({ gte: true });
    } else {
      setValidity({ gte: false });
    }
  }

  if (type === 'max') {
    if (!input.gte || !input.lte || input.lte >= input.gte) {
      setValidity({ lte: true });
    } else {
      setValidity({ lte: false });
    }
  }

  if (input.gte === 0 && input.lte === 0) {
    setValid({ gte: null, lte: null });
  }
};

export const checkBothValidity = (
  min: number,
  max: number,
  setValid: (value: any) => void,
  setInitialized: (value: boolean) => void
) => {
  if (min > 0 && !max) {
    setValid({ lte: null, gte: true });
  } else if (max > 0 && !min) {
    setValid({ lte: true, gte: null });
  } else {
    setValid((prev: object) => ({
      ...prev,
      lte: true,
      gte: min < max,
    }));
  }
  setInitialized(true);
};
