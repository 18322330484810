import gql from 'graphql-tag';

export const ACTIVATE_PROGRAM = gql`
  mutation activateMemberLoyaltyProgramme($id: ID!) {
    activateMemberLoyaltyProgramme(id: $id)
  }
`;

export const DEACTIVATE_PROGRAM = gql`
  mutation deactivateMemberLoyaltyProgramme($id: ID!) {
    deactivateMemberLoyaltyProgramme(id: $id)
  }
`;

export const CREATE_MEMBER_LOYALTY_PROGRAMME = gql`
  mutation CreateMemberLoyaltyProgramme(
    $input: CreateMemberLoyaltyProgrammeInput
  ) {
    createMemberLoyaltyProgramme: createMemberLoyaltyProgramme(input: $input)
  }
`;

export const UPDATE_MEMBER_LOYALTY_PROGRAMME = gql`
  mutation UpdateMemberLoyaltyProgramme(
    $id: ID!
    $input: UpdateMemberLoyaltyProgrammeInput!
  ) {
    updateMemberLoyaltyProgramme: updateMemberLoyaltyProgramme(
      id: $id
      input: $input
    )
  }
`;

export const SUBMIT_MEMBER_LOYALTY_PROGRAMME = gql`
  mutation($id: ID!) {
    submitMemberLoyaltyProgramme(id: $id)
  }
`;

export const DELETE_MEMBER_LOYALTY_PROGRAM = gql`
  mutation DeleteMemberLoyaltyProgramme($id: ID!) {
    deleteMemberLoyaltyProgramme(id: $id)
  }
`;

export const DUPLICATE_LOYALTY_PROGRAM = gql`
  mutation DuplicateMemberLoyaltyProgramme($id: ID!) {
    duplicateMemberLoyaltyProgramme(id: $id)
  }
`;
