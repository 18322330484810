import { defineMessages } from 'react-intl';

const messages = defineMessages({
  name: {
    id: 'name.text',
    defaultMessage: 'Name',
  },
  edittitle: {
    id: 'site-configuration.edit.title',
    defaultMessage: 'Edit Site Configuration',
  },
  generalInfo: {
    id: 'site-configuration.generalinfo',
    defaultMessage: 'General Information',
  },
  subdomains: {
    id: 'site-configuration.subdomains',
    defaultMessage: 'List of Sub-domains',
  },
  domainname: {
    id: 'site-configuration.domain.name',
    defaultMessage: 'Domain name',
  },
  cname: {
    id: 'site-configuration.cname',
    defaultMessage: 'CNAME',
  },
  description: {
    id: 'site-configuration.description',
    defaultMessage: 'Description',
  },
  upload: {
    id: 'file.upload.title',
    defaultMessage: 'Upload',
  },
  crtfile: {
    id: 'site-configuration.crtfile',
    defaultMessage: '.CRT File',
  },
  crtfilehelp: {
    id: 'site-configuration.crtfile.help',
    defaultMessage: 'Accepted file formats：.crt',
  },
  privatekey: {
    id: 'site-configuration.privatekey',
    defaultMessage: 'Private Key',
  },
  intermediarycrtfile: {
    id: 'site-configuration.intermediarycrtfile',
    defaultMessage: 'Intermediary Cert',
  },
  captcha: {
    id: 'captcha.title',
    defaultMessage: 'Captcha',
  },
  frontend: {
    id: 'captcha.frontend',
    defaultMessage: 'Front-end',
  },
  backend: {
    id: 'captcha.backend',
    defaultMessage: 'Back-end',
  },
  googleauth: {
    id: 'googleauth.title',
    defaultMessage: 'Google Authenticator',
  },
  walletconfig: {
    id: 'site-configuration.walletconfig.title',
    defaultMessage: 'Wallet Configuration',
  },
  transfer: {
    id: 'site-configuration.walletconfig.transfer',
    defaultMessage: 'Transfer',
  },
  seamless: {
    id: 'site-configuration.walletconfig.seamless',
    defaultMessage: 'Seamless',
  },
  paymentmethodratecalc: {
    id: 'site-configuration.paymentmethod.rate',
    defaultMessage: 'Payment Method Success Rate Calculation',
  },
  paymentmethodtransactions: {
    id: 'site-configuration.paymentmethod.transactions.title',
    defaultMessage: 'Last “X” transactions',
  },
  withdrawalcharges: {
    id: 'site-configuration.withdrawal.charges',
    defaultMessage: 'Withdrawal Charges',
  },
  turnoverwaiver: {
    id: 'site-configuration.withdrawl.turnoverwaiver',
    defaultMessage: 'Turnover requirement Waiver',
  },
  turnoverwaiverhelp: {
    id: 'site-configuration.withdrawl.turnoverwaiver.help',
    defaultMessage: 'Total Account Balance less than',
  },
  withdrawalfee: {
    id: 'site-configuration.withdrawl.handlingfee',
    defaultMessage: 'Withdrawal Handling Fee',
  },
  withdrawaltax: {
    id: 'site-configuration.withdrawl.tax',
    defaultMessage: 'Withdrawal Tax',
  },
  withdrawaltaxhelp: {
    id: 'site-configuration.withdrawl.tax.help',
    defaultMessage:
      'Tax impose on the remaining amount that has yet to be turnover',
  },
  absolute: {
    id: 'absolute',
    defaultMessage: 'Absolute',
  },
  relative: {
    id: 'relative',
    defaultMessage: 'Relative',
  },
  save: {
    id: 'buttons.save',
    defaultMessage: 'Save',
  },
  urlsslcertificateconfiguration: {
    id: 'url-ssl-certificate-configuration.text',
    defaultMessage: 'URL / SSL Certificate Configuration',
  },
  sslcertification: {
    id: 'ssl-certification.text',
    defaultMessage: 'SSL Certification',
  },
  timezone: {
    id: 'timezone.text',
    defaultMessage: 'Time Zone',
  },
  descriptionremarks: {
    id: 'description-remarks.text',
    defaultMessage: 'Description / Remarks',
  },
  status: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  ssl: {
    id: 'ssl.text',
    defaultMessage: 'SSL',
  },
  actions: {
    id: 'actions.text',
    defaultMessage: 'Actions',
  },
  disable: {
    id: 'disable.text',
    defaultMessage: 'Disable',
  },
  HANDLING_FEE_CHARGING: {
    id: 'HANDLING_FEE_CHARGING',
    defaultMessage: 'Handling Fee Charging',
  },
  UNMET: {
    id: 'ONLY_UNMET_TURNOVER',
    defaultMessage: 'Only when there is unmet turnover',
  },
  ALWAYS: {
    id: 'ALL_THE_TIME',
    defaultMessage: 'All the time',
  },
});

export default messages;
