import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select as Temp } from 'antd';
import { formItemHorizontalLayout } from 'constants/form';

const { Option } = Temp;

function Select({
  options,
  label,
  value,
  onChange,
}: {
  options: Array<{
    id: any;
    text: any;
  }>;
  label: string;
  value: string;
  onChange: (e: any) => void;
}) {
  return (
    <Form.Item {...formItemHorizontalLayout} label={label}>
      <Temp
        value={value}
        style={{ width: '50%' }}
        onChange={(e) => onChange(e)}
        size="large"
      >
        {options.map((q, i) => (
          <Option value={q.id} key={i}>
            {q.text}
          </Option>
        ))}
      </Temp>
    </Form.Item>
  );
}

export default Select;
