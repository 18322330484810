import React from 'react';
import { Tag } from 'antd';
import { useFilterValues } from 'contexts/Filters';

type Props = {
  isLoading?: boolean;
};

const FilterConditions = ({ isLoading }: Props) => {
  const { filters, setFilters } = useFilterValues();
  const handleRemoveMember = (
    memberUsername: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    if (!isLoading) {
      const { username } = filters;
      const data = username.in.filter(
        (item: string) => item !== memberUsername
      );

      const newMember = {
        in: data,
      };
      setFilters({
        ...filters,
        username: data.length ? newMember : null,
      });
    }
  };

  return (
    <>
      {filters.username
        ? filters.username.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveMember(item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
