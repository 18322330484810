import { LoadingOutlined } from '@ant-design/icons';
import { message, Select, Spin, Typography } from 'antd';
import { useMemberPromo, WalletCurrency } from 'hooks/useMemberPromo';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Member } from 'types/graphqlTypes';
import { CashBalanceCards } from '../../utils';

type Props = {
  member: Member;
};

type BalanceProps = {
  label: string;
  balance: number;
};

const WalletBalanceWrapper: React.FC<Props> = () => {
  const {
    walletCurrency,
    walletTotalBalance,
    walletBalance,
    setWalletCurrency,
    loadAvailableCurrencies,
    walletBalancesLoading,
    getCurrencyType,
  } = useMemberPromo();

  const { currencyOptions, selectedCurrency, currencyType } = walletCurrency;

  const { casinoBonus, casinoCash, mainCash, sportsBonus, sportsCash } =
    walletBalance || {};

  const balances = useMemo(() => {
    const bal: BalanceProps[] = [
      {
        label: 'Cash: Main',
        balance: +mainCash!,
      },
      {
        label: 'Cash: Sports',
        balance: +sportsCash!,
      },
      {
        label: 'Bonus: Sports',
        balance: +sportsBonus!,
      },
      {
        label: 'Cash: Casino',
        balance: +casinoCash!,
      },

      {
        label: 'Bonus: Casino',
        balance: +casinoBonus!,
      },
      {
        label: 'Total',
        balance: +walletTotalBalance,
      },
    ];

    return bal;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletBalance]);

  // const isNext = useSearchParam('next') === 'true';

  const handleChangeWalletCurrency = (e: string) => {
    const type = getCurrencyType(e);

    setWalletCurrency((prev: WalletCurrency) => ({
      ...prev,
      selectedCurrency: e,
      currencyType: type,
    }));

    message.success('Wallet selection changed successfully');
  };

  React.useEffect(
    () => loadAvailableCurrencies(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Select
        value={selectedCurrency}
        onChange={handleChangeWalletCurrency}
        style={{
          width: '162px',
          height: '32px',
          marginBottom: '15px',
        }}
      >
        {currencyOptions.map((opts, index) => (
          <Select.Option key={index} value={opts.code}>
            <BalanceText currency={opts.code} />
          </Select.Option>
        ))}
      </Select>
      {walletBalancesLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: '200px',
          }}
        >
          <Spin size="large" indicator={<LoadingOutlined spin />} />
        </div>
      ) : (
        <div className="d-flex justify-content-flex-start flex-wrap">
          {balances.map((item, index) => (
            <CashBalanceCards key={index} className="mr-1">
              <h4 className="text-muted fs-12">{item.label}</h4>
              <h4 className="fs-14" style={{ marginBottom: '4px' }}>
                <FormattedMessage id="total.text" defaultMessage="Total" />
                <BalanceText
                  label={item.balance}
                  currency={selectedCurrency}
                  type={currencyType}
                />
              </h4>
            </CashBalanceCards>
          ))}
        </div>
      )}
    </>
  );
};

export const BalanceText = ({
  currency,
  label,
  type,
}: {
  currency: string;
  label?: number;
  type?: 'FIAT' | 'CRYPTO';
}) => {
  const loadImage = (code: string) => {
    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`./assets/currencyIcons/${code.toLowerCase()}.png`);
    } catch (e) {
      //
    }

    return null;
  };

  return (
    <div className="d-flex align-items-center h-100 w-100">
      <img
        src={loadImage(currency)}
        alt={`${currency}_icon`}
        height="20px"
        width="20px"
      />
      <Typography.Text
        style={{
          fontSize: '14px',
          fontWeight: 400,
          marginLeft: '4px',
        }}
      >
        {label?.toFixed(type === 'CRYPTO' ? 8 : 2) ?? currency}
      </Typography.Text>
    </div>
  );
};

export default WalletBalanceWrapper;
