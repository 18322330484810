import React from 'react';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { formatDate } from 'utils/dateUtils';

type PreventDefault = {
  preventDefault: () => void;
};

const FilterConditions = ({
  filters,
  onFilterChange,
}: {
  filters: Record<string, any>;
  onFilterChange: (e: Record<string, any>) => void;
}) => {
  const handleRemoveFilter = (
    name: string,
    value: string,
    e: PreventDefault
  ) => {
    e.preventDefault();
    onFilterChange({
      ...filters,
      [name]: filters[name].filter((item: string) => item !== value),
    });
  };
  const handleRemoveDateFilter = (e: PreventDefault) => {
    e.preventDefault();
    onFilterChange({
      ...filters,
      dateTimeCreated: null,
    });
  };

  return (
    <>
      {filters.usernames
        ? filters.usernames.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilter('usernames', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.dateTimeCreated ? (
        <Tag closable onClose={handleRemoveDateFilter}>
          <FormattedMessage
            id="registration-date.text"
            defaultMessage="Registration date"
          />
          : {formatDate(filters.dateTimeCreated.gte)}~
          {formatDate(filters.dateTimeCreated.lte)}
        </Tag>
      ) : null}

      {filters.status
        ? filters.status.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilter('status', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
