import React from 'react';
import * as moment from 'moment-timezone';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import VendorNames from 'SuperAdminMain/components/VendorsConsolidateUsageReport/components/VendorNames';
// import GameCategoryNames from 'SuperAdminMain/components/VendorsConsolidateUsageReport/components/GameCategoryNames';
import { StyledContent, StyledRow, StyledCol, StyledFilter } from './styles';

type Props = {
  report: any;
};

const FilterStatus: React.FC<Props> = ({ report }) => {
  const translate = useTranslate();
  const { bypassedCurrentTimezone }: any = useReportsTimezone();
  const { filter = {} } = report || {};
  const {
    dateTimeProcessed,
    //  status,
    vendor,
  } = filter;

  return (
    <StyledContent>
      <StyledRow>
        <StyledFilter>{translate(messages.FILTER)}</StyledFilter>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          <p>{translate(messages.PERIOD)}</p>
        </StyledCol>
        <StyledCol>
          {dateTimeProcessed ? (
            <span>
              <p>
                {moment
                  .tz(dateTimeProcessed.gte, bypassedCurrentTimezone)
                  .format('YYYY/MM/DD HH:mm')}{' '}
                -
              </p>

              <p>
                {moment
                  .tz(dateTimeProcessed?.lte, bypassedCurrentTimezone)
                  .format('YYYY/MM/DD HH:mm')}{' '}
              </p>
            </span>
          ) : (
            <p>-</p>
          )}
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <div>
          <span>{translate(messages.VENDOR)}</span>
        </div>
        <div>
          <span>
            <VendorNames vendors={vendor ? vendor.in : []} />
          </span>
        </div>
      </StyledRow>
      {/* <StyledRow>
          <div>
            <p>{translate(messages.VENDOR_GAME_CATEGORY)}</p>
          </div>
          <div>
            <p>
              <GameCategoryNames games={game ? game.in : []} />
            </p>
          </div>
        </StyledRow> */}
      {/* <StyledRow>
        <div>
          <span>{translate(messages.BET_STATUS)}</span>
        </div>
        <div>
          <span>
            {status
              ? status.in.map(
                  (item: string, key: number) =>
                    `${translate(messages[`${item}`])}${
                      key + 1 < status.in.length ? ', ' : ''
                    }`
                )
              : '-'}
          </span>
        </div>
      </StyledRow> */}
    </StyledContent>
  );
};

export default FilterStatus;
