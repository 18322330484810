import styled from 'styled-components';
import { Row, Col } from 'antd';

export const StyledRow = styled(Row)`
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  line-height: normal !important;
  display: flex !important;
  flex-wrap: wrap;
`;

export const StyledCol = styled(Col)`
  border-right: 1px solid rgba(0, 0, 0, 0.15);
`;

export const StyledColorSquare = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: ${(props) => props.color || '#efefef'};
  margin-right: 2px;
  vertical-align: middle;
`;

export default {};
