import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import messages from 'pages/components/Reports/messages';
import {
  useSetDateUtils,
  SetDateFilterStateType,
  DateStateType,
} from 'utils/globalSetDateUtils';
import useTranslate from 'utils/useTranslate';
import { FilterStateType, SetFilterStateType } from '../../../../types';
import { StyledRangePicker } from '../../styles';

type Props = {
  state: FilterStateType;
  setState: SetFilterStateType;
};

type DateTypes = {
  state: DateStateType;
  setState: SetDateFilterStateType;
};

const DateRangeFilter: React.FC<Props> = (props) => {
  const { state, setState } = props as DateTypes;
  const translate = useTranslate();

  const {
    dateInputValue,
    handleLastSevenDays,
    handleLastWeek,
    handleLastThirtyDays,
    handleChangeDateFilter,
  } = useSetDateUtils(state, setState, 'dateTimePlaced');

  return (
    <>
      <div className="mb-1">
        <FormattedMessage
          id="reports.date-time-placed.text"
          defaultMessage="Date / Time Placed"
        />
      </div>

      <StyledRangePicker
        className="mb-2"
        placeholder={[
          translate(messages['reports.from.text']),
          translate(messages['reports.to.text']),
        ]}
        format="YYYY/MM/DD"
        value={dateInputValue as any}
        onChange={handleChangeDateFilter}
      />

      <div>
        <Button size="small" className="mb-1" onClick={handleLastSevenDays}>
          <FormattedMessage
            id="last-7-days.text"
            defaultMessage="Last 7 days"
          />
        </Button>
        <Button size="small" className="mb-1" onClick={handleLastWeek}>
          <FormattedMessage id="last-week.text" defaultMessage="Last week" />
        </Button>
        <Button size="small" className="mb-1" onClick={handleLastThirtyDays}>
          <FormattedMessage
            id="last-30-days.text"
            defaultMessage="Last 30 days"
          />
        </Button>
      </div>
    </>
  );
};

export default DateRangeFilter;
