import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'agent.game-vendor-platform-fees.text': {
    id: 'agent.game-vendor-platform-fees.text',
    defaultMessage: 'Game Vendor Platform Fees',
  },
  'agent.deposit-transaction-cost.text': {
    id: 'agent.deposit-transaction-cost.text',
    defaultMessage: 'Deposit Transaction Cost',
  },
  'agent.3rd-party-payment-gateway-deposit-transaction-cost.text': {
    id: 'agent.3rd-party-payment-gateway-deposit-transaction-cost.text',
    defaultMessage: '3rd Party Payment Gateway Deposit Transaction Cost',
  },
  'agent.withdrawal-transaction-cost.text': {
    id: 'agent.withdrawal-transaction-cost.text',
    defaultMessage: 'Withdrawal Transaction Cost',
  },
  'agent.3rd-party-payment-gateway-withdrawal-transaction-cost.text': {
    id: 'agent.3rd-party-payment-gateway-withdrawal-transaction-cost.text',
    defaultMessage: '3rd Party Payment Gateway Withdrawal Transaction Cost',
  },
  'agent.promotion-cost.text': {
    id: 'agent.promotion-cost.text',
    defaultMessage: 'Promotion Cost',
  },
  'agent.rebate-cost.text': {
    id: 'agent.rebate-cost.text',
    defaultMessage: 'Rebate Cost',
  },
  'agent.interest-account-cost.text': {
    id: 'agent.interest-account-cost.text',
    defaultMessage: 'Interest Account Cost',
  },
  'active-members.text': {
    id: 'active-members.text',
    defaultMessage: 'Active Members',
  },
  'net-profit.text': {
    id: 'net-profit.text',
    defaultMessage: 'Net Profit',
  },
  'and.text': {
    id: 'and.text',
    defaultMessage: 'And',
  },
  'or.text': {
    id: 'or.text',
    defaultMessage: 'Or',
  },
});

export default messages;
