import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Vendor } from 'types/graphqlTypes';
import { VendorData } from './component/VendorData/VendorData';
import { actions, useBalancesContext } from '../../context';

const StyledSelect = styled(Select)<any>`
  .ant-select-selection__rendered {
    padding-right: 40px;
    padding-left: 25px;
  }
`;

// EU-2962
const DISABLED_TEMP = true;

export const WalletBalance = ({ member }: IWalletBalance) => {
  const {
    onSearchWalletStatus,
    searchedVendorsSelected,
    vendors,
    dispatch,
  } = useBalancesContext();

  return (
    <>
      {!DISABLED_TEMP && onSearchWalletStatus && (
        <div className="position-relative">
          <StyledSelect
            mode="multiple"
            style={{ width: '100%' }}
            className="mb-2"
            value={searchedVendorsSelected}
            onDeselect={(valId: string) => {
              const filteredVendors = searchedVendorsSelected.filter(
                (vendorName: string) => vendorName !== valId
              );
              dispatch([actions.SET_SEARCH_VENDORS_SELECTED, filteredVendors]);
            }}
            onSelect={(valId: string) => {
              dispatch([
                actions.SET_SEARCH_VENDORS_SELECTED,
                [...searchedVendorsSelected, valId],
              ]);
            }}
          >
            {vendors.map((vendor: Vendor) => (
              <Select.Option value={vendor.name!} key={vendor.id}>
                {vendor.name}
              </Select.Option>
            ))}
          </StyledSelect>
          {searchedVendorsSelected.length > 0 && (
            /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
            <div
              onClick={() =>
                dispatch([actions.SET_SEARCH_VENDORS_SELECTED, []])
              }
              className="position-absolute cursor-pointer"
              style={{ top: '8px', right: '20px' }}
            >
              <FormattedMessage id="clear.text" defaultMessage="Clear" />
            </div>
          )}
          <SearchOutlined
            className="position-absolute"
            style={{
              top: '9px',
              left: '10px',
            }}
          />
        </div>
      )}

      {DISABLED_TEMP ? (
        <></>
      ) : (
        <div
          className="d-flex flex-wrap"
          style={{ maxHeight: '192px', overflowY: 'auto' }}
        >
          {searchedVendorsSelected.length === 0 &&
            vendors.map((vendor: Vendor) => (
              <VendorData
                key={vendor.id}
                vendor={vendor}
                memberId={member.id}
              />
            ))}
          {searchedVendorsSelected.length > 0 &&
            searchedVendorsSelected.map((vendorName: string) => {
              const foundVendor = vendors.find(
                (x: Vendor) => x.name === vendorName
              );
              return (
                <VendorData
                  key={foundVendor.id}
                  vendor={foundVendor!}
                  onSearchMode
                  memberId={member.id}
                />
              );
            })}
        </div>
      )}
    </>
  );
};

interface IWalletBalance {
  member: any;
}
