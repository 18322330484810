import React from 'react';
import { shape, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { assignedFilterKeysByTypename } from 'pages/components/SystemMessage/ManualMessage/components/SystemMessageSidebar/utils';
import { multiSelectType } from 'pages/components/SystemMessage/ManualMessage/components/CreateNewMail/util';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { MultiMembersSelectSidebar } from '../MultiMembersSelectSidebar/MultiMembersSelectSidebar';

// eslint-disable-next-line no-unused-vars
export const TargetMembersFilter = ({ values, setFilters }) => {
  const handleOnsSelectMembers = (e) => {
    setFilters(assignedFilterKeysByTypename(e, 'target'));
  };

  return (
    <FilterItem
      label={
        <FormattedMessage
          id="system-message.target-members.text"
          defaultMessage="Target Members"
        />
      }
    >
      <MultiMembersSelectSidebar
        multiSelectType={multiSelectType.SIDEBAR}
        values={values}
        onSelectMembers={handleOnsSelectMembers}
      />
    </FilterItem>
  );
};

TargetMembersFilter.propTypes = {
  values: shape({}),
  setFilters: func,
};

TargetMembersFilter.defaultProps = {
  values: {},
  setFilters: (e) => e,
};
