import React from 'react';
import { Typography, Dropdown, Menu, Spin } from 'antd';
import gql from 'graphql-tag';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';

import { FormattedMessage } from 'react-intl';
import { useScreenTabV2 } from 'store/screenTabState';
import { ALink } from 'components/ALink/ALink';
import { CityStateLocation } from 'components/CityStateLocation/CityStateLocation';
import { useQuery } from '@apollo/react-hooks';
import { useAccount } from 'store/accountState';

const MEMBER_LAST_LOGIN = gql`
  query MemberLastLogin($id: ID!, $language: Language!) {
    member(id: $id) {
      id
      lastLoginIpAddress
      lastLoginIpAddressLocation {
        city(language: $language)
        country(language: $language)
        state(language: $language)
      }
    }
  }
`;

const LastLoginIP = () => {
  const { addTab } = useScreenTabV2();
  const { member } = useMember() as Record<string, any>;
  const {
    account: { locale },
  } = useAccount();

  const { loading, data = {} } = useQuery<Record<string, any>>(
    MEMBER_LAST_LOGIN,
    {
      context: { shouldBatch: true },
      fetchPolicy: 'network-only',
      variables: {
        id: member.id,
        language: locale.toUpperCase(),
      },
    }
  );

  if (loading) {
    return <Spin />;
  }

  const { lastLoginIpAddress, lastLoginIpAddressLocation } = data?.member;

  // const handleShowBrowserTab = properties => {
  //   const newTabId = uuid.v1();
  //   const url = `${window.location.pathname}?screenTab=${newTabId}`;
  //   const newScreenTab = setScreenTab(newTabId);
  //   newScreenTab.addTab(properties);
  //   window.open(url, '_blank');
  // };

  const handleClick = () => {
    addTab({
      id: 'member-management',
      state: {
        filter: { lastLoginIpAddress: { eq: data?.member.lastLoginIpAddress } },
      },
    });
  };

  return (
    <>
      <Typography.Text>
        <FormattedMessage
          id="last-login-ip.text"
          defaultMessage="Last Login IP"
        />
      </Typography.Text>
      <span className="text-right">
        {lastLoginIpAddress ? (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  // onClick={() => handleShowBrowserTab(memberManagementTab)}
                >
                  <FormattedMessage
                    id="open-in-new-browser-tab.text"
                    defaultMessage="Open in new browser tab"
                  />
                </Menu.Item>
              </Menu>
            }
            trigger={['contextMenu']}
          >
            <ALink
              className="fs-12"
              style={{ textAlign: 'end' }}
              onClick={() => handleClick()}
            >
              {lastLoginIpAddress || '-'}{' '}
              <CityStateLocation
                cityStateLocation={lastLoginIpAddressLocation}
              />
            </ALink>
          </Dropdown>
        ) : (
          '-'
        )}
      </span>
    </>
  );
};

export default LastLoginIP;
