import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { DocumentNode } from 'graphql';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { useDebounce } from 'hooks/useDebounce';
import { uniqBy } from 'lodash';
import { checkPartial } from 'utils/partialUtils';
import fetchMissingMembers from 'utils/fetchMissingMembers';
import { Member } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import {
  StyledLabel,
  StyledSelect,
  StyledSpan,
} from 'styles/SharedStyledSelectFilter';

const { Option } = Select;

type Props = {
  label: string;
  query: DocumentNode;
  queryConnection: string;
  filterFieldName: string;
  filters: Record<string, unknown>;
  rawFilterValues?: string[] | null;
  fieldType?: string;
  onChange: (e: string[] | null) => void;
  partialSupported?: boolean;
  additionalOptionsFilter?: { [key: string]: Record<string, unknown> };
  localFilterState?: {
    stateFilter?: Record<string, string[]>;
    stateFilterName: string;
  };
  testId?: string;
  isCustomFilter?: boolean;
  placeholder?: string;
};

type ConnectionEdge = {
  node: {
    [key: string]: {
      [key: string]: unknown;
    };
  };
};

export default ({
  label,
  query,
  filterFieldName,
  filters,
  onChange,
  queryConnection,
  fieldType,
  partialSupported = true,
  additionalOptionsFilter,
  localFilterState,
  rawFilterValues = null,
  testId,
  isCustomFilter,
  placeholder,
}: Props) => {
  const [options, setOptions] = useState<Member[]>([]);
  const [searchedText, setSearchedText] = useState('');
  const [partialText, setPartialText] = useState('');
  const debouncedText = useDebounce(searchedText, 500);

  const { stateFilter, stateFilterName } = localFilterState || {};

  const client = useApolloClient();

  const [loadOptions, { loading }] = useLazyQuery(query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 10,
      filter: {
        [filterFieldName]: {
          contains: debouncedText,
        },
        ...additionalOptionsFilter,
      },
    },
    onCompleted: (data) => {
      const {
        [queryConnection]: { edges = [] },
      } = data;

      const rawOptions = edges?.map(({ node }: ConnectionEdge) => {
        if (fieldType) {
          return node[fieldType];
        }

        return node;
      });

      const uniqueOptions = (uniqBy(rawOptions, 'id') as unknown) as Member[];

      setOptions(uniqueOptions);
    },
  });

  const values =
    rawFilterValues ||
    coercedGet(filters, 'member.in', []).filter((memberId: string) =>
      stateFilter![stateFilterName!]?.includes(memberId)
    );

  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange(e);
    } else {
      onChange(null);
    }

    setPartialText('');
    setSearchedText('');
  };

  const hasPartial = checkPartial('member', filters);

  useEffect(() => {
    fetchMissingMembers({
      values,
      options,
      client,
      hasPartial,
    }).then((res) => {
      if (res.missingMembers.length) {
        setOptions((prev) => [...prev, ...res.membersData]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  React.useEffect(() => {
    if (isCustomFilter) loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between">
        <div>
          <StyledLabel>{label}</StyledLabel>
        </div>
        <div>
          <StyledSpan onClick={() => handleChange([])}>Clear</StyledSpan>
        </div>
      </div>

      <StyledSelect
        data-testid={testId}
        mode="multiple"
        value={values}
        showSearch
        placeholder={`Enter ${placeholder || label}`}
        onChange={handleChange}
        filterOption={false}
        loading={loading}
        onSearch={(text: string) => {
          setSearchedText(text);
          setPartialText(text);
        }}
        onFocus={() => setSearchedText('')}
        onDropdownVisibleChange={(open) => {
          if (open && !options.length) loadOptions();
        }}
        onBlur={() => setSearchedText('')}
      >
        {!hasPartial && partialText.length && partialSupported && (
          <Option
            key={69}
            value={`Partial: ${partialText}`}
            label={`Partial: ${partialText}`}
          >
            Partial: {partialText}
          </Option>
        )}

        {options.map((option, index) => (
          <Option key={index} value={option?.id}>
            {option?.[filterFieldName]}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
};
