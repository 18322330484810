import React, { useState } from 'react';
import { StyledTagContainer } from 'styles';
import messages from 'messages';

import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import FilterConditions from 'pages/components/Reports/DailyOperatingReport/components/FilterConditions';
import { useFilterValues } from 'contexts/Filters';
import useTranslate from 'utils/useTranslate';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { useScreenTabV2 } from 'store/screenTabState';

type Props = {};

const HeaderLeft: React.FC<Props> = () => {
  const {
    filters,
    setFilters,
    initialValues,
    resetFilterToDefault,
  } = useFilterValues();
  const [expandedTags, setExpandedTags] = useState(false);
  const translate = useTranslate();
  const { clearTabState } = useScreenTabV2('daily-operating-report');

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="d-flex align-items-start flex-g-1">
        <ToggleSidebarHead />
        <StyledTagContainer ref={ref} isExpanded={expandedTags}>
          <span className="ml-1">
            {translate(messages.FILTER_CONDITIONS)}:{' '}
          </span>{' '}
          <FilterConditions filters={filters} onFilterChange={setFilters} />
        </StyledTagContainer>
        <LegacyIconToggle
          forwardedRef={ref}
          filters={filters}
          expandedTags={expandedTags}
          toggleExpandedTags={setExpandedTags}
        />
        <ClearAllFilterButton
          currentFilters={filters}
          onFilterChange={(value: any) => {
            setFilters(value);
            clearTabState();
            resetFilterToDefault();
          }}
          initialFilters={initialValues}
          style={{ marginTop: '0.35rem' }}
        />
      </div>
    </>
  );
};

export default HeaderLeft;
