import useTranslate from 'utils/useTranslate';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { messagesLocal } from './utils';

export const WhiteListIpModal = ({
  ipAddresses,
  visible,
  onClose,
  onSubmit,
}: any) => {
  const translate = useTranslate();
  const [ip, setIp] = useState<string>('');
  const [ipList, setOfIpList] = useState(ipAddresses);
  const [errorMsg, setErrorMsg] = useState(false);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    setOfIpList(ipAddresses);
  }, [ipAddresses]);

  return (
    <Modal
      title="Whitelist IP Address"
      visible={visible}
      closable
      onCancel={onClose}
      onOk={() => {
        onSubmit(ipList);
        onClose();
      }}
      okText="Submit"
      cancelText="Cancel"
    >
      <div
        className="d-flex flex-column justify-content-space-between p-2"
        style={{
          borderRadius: '0 0 10px 10px',
          height: '175px',
          width: '100%',
          border: '1px solid lightgray',
        }}
      >
        <div>
          <small className="d-block">
            <FormattedMessage id="ip.text" defaultMessage="IP" />
          </small>

          {ipList.map((ipAdd: string) => (
            <Tag
              key={ipAdd}
              className="mt-1"
              closable
              onClose={(e: any) => {
                e.preventDefault();
                setOfIpList((prevIp: string[]) =>
                  prevIp.filter(theIp => theIp !== ipAdd)
                );
              }}
            >
              {ipAdd}
            </Tag>
          ))}
        </div>

        <div>
          <div className="d-flex justify-content-space-between">
            <Input
              name="ip"
              style={{ width: '83%' }}
              onChange={e => setIp(e.target.value)}
              value={ip}
              ref={inputRef}
              placeholder={translate(
                messagesLocal['ip-address-input-placeholder.text']
              )}
            />
            <Button
              type="primary"
              style={{ width: '15%' }}
              onClick={() => {
                if (!ip) {
                  return;
                }
                const ipRegex = /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/;
                const validIp = ipRegex.test(ip);
                if (!validIp) {
                  setErrorMsg(true);
                  return;
                }

                setErrorMsg(false);
                setOfIpList((prev: string[]) => [...prev, ip]);
                setIp('');
                inputRef.current.focus();
              }}
            >
              <FormattedMessage id="add.text" defaultMessage="Add" />
            </Button>
          </div>
          {errorMsg && (
            <p className="text-danger">
              <FormattedMessage
                id="ip-address-validation.text"
                defaultMessage="Must be IP address format"
              />
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};
