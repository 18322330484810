// tslint-disable
import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Radio, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import messages from 'messages';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import useTranslate from 'utils/useTranslate';
import { Member } from 'types/graphqlTypes';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';

const StyledModal = styled<typeof Modal>(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

const UPDATE_MEMBER = gql`
  mutation UpdateMember($id: ID!, $input: UpdateMemberInput!) {
    updateMember(id: $id, input: $input)
  }
`;

const EditMemberVerificationStatus = () => {
  const [isShow, setIsShow] = useState(false);
  const [verified, setVerified] = useState(false);

  const [updateMember] = useMutation(UPDATE_MEMBER);
  const translate = useTranslate();
  const { member, refetch } = useMember() as {
    member: Member;
    refetch: () => void;
  };

  useEffect(() => {
    if (member) {
      setVerified(member?.verified);
    }
  }, [member]);

  const onUpdateMember = () => {
    updateMember({
      variables: {
        id: member?.id,
        input: {
          verified,
        },
      },
    }).then(() => {
      refetch();
      setIsShow(false);
      message.success('Verification status successfully updated.');
    });
  };

  return (
    <>
      <Button
        className="text-black"
        type="link"
        onClick={() => setIsShow(true)}
        block
      >
        <FormattedMessage
          id="EDIT_MEMBER_VERIFICATION_STATUS"
          defaultMessage="Edit Member Verification Status"
        />
      </Button>

      <StyledModal
        visible={isShow}
        footer={null}
        onCancel={() => setIsShow(false)}
        title="Edit Member Verification Status"
      >
        <div className="p-3">
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <Form.Item label={translate(messages.STATUS)}>
              <Radio.Group
                value={verified}
                onChange={(e) => setVerified(e.target.value)}
              >
                <Radio value={false}>
                  <FormattedMessage
                    id="NOT_VERIFIED_DEFAULT"
                    defaultMessage="Not Verified (Default)"
                  />
                </Radio>
                <Radio value>
                  <FormattedMessage id="VERIFIED" defaultMessage="Verified" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>

          <div className="d-flex justify-content-flex-end mt-3">
            <Button className="mr-2" onClick={() => setIsShow(false)}>
              {translate(messages.CANCEL)}
            </Button>
            <Button type="primary" onClick={onUpdateMember}>
              {translate(messages.SAVE_CHANGES)}
            </Button>
          </div>
        </div>
      </StyledModal>
    </>
  );
};

export default EditMemberVerificationStatus;
