/* eslint-disable no-lonely-if */
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import ALL_PERMISSIONS from 'constants/permissions3';
import { flatten, isArray, uniq } from 'lodash';

export type PermissionItem = {
  key: string;
  value: string;
};

export type Permissions = {
  key: string;
  value: PermissionItem[];
};

export interface CheckState {
  indeterminate: {
    [key: string]: boolean;
  };
  currentCheckedList: PermissionItem[];
}

export type TabType =
  | 'MEMBERS_MEMBER_MANAGEMENT'
  | 'MEMBERS_MEMBER_MARKER_MANAGEMENT'
  | 'MEMBERS_LABEL_MANAGEMENT'
  | 'MEMBERS_MEMBER_BULK_UPDATE_MANAGEMENT'
  | 'MEMBERS_MEMBER_REPORTS_MANAGEMENT'
  | 'MEMBERS_MEMBER_BULK_UPDATE'
  | 'MEMBERS_MEMBERS_ONLINE_ONLY'
  | 'MEMBERS_REPORTS';

export const memberManagementHint = 'MEMBERS_MEMBER_MANAGEMENT';
export const memberMarkerManagementHint = 'MEMBERS_MEMBER_MARKER_MANAGEMENT';
export const memberLabelManagementHint = 'MEMBERS_LABEL_MANAGEMENT';
export const memberBulkUpdateManagementHint = 'MEMBERS_MEMBER_BULK_UPDATE';
export const memberReportsManagementHint = 'MEMBERS_REPORTS';
export const memberConfigManagementHint = 'MEMBERS_MANAGEMENT_CONFIG';

export const keysToRemoveMemberManagement = [
  'MEMBERS_MEMBER_MANAGEMENT',
  'MEMBERS_MEMBER_MANAGEMENT_LIST',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS',
];
export const memberMarkerTopParent = [
  'MEMBERS_MEMBER_MARKER_MANAGEMENT',
  'MEMBERS_MEMBER_MARKER_MANAGEMENT_LIST',
  'MEMBERS_MEMBER_MARKER_MANAGEMENT_VIEW_DETAILS',
];

export const memberLabelTopParent = [
  'MEMBERS_LABEL_MANAGEMENT',
  'MEMBERS_LABEL_MANAGEMENT_LIST',
  'MEMBERS_LABEL_MANAGEMENT_VIEW_DETAILS',
];

export const viewDetailsChildren = [
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES_LIST',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES_ADD',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES_EDIT',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES_DELETE',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_PLAYER_PROFILE_EDIT',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_PLAYER_PROFILE_VIEW',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS_LIST',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS_DELETE',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS_TOGGLE_CLOSED_LOOP',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS_DISABLE',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_INTERACTION_LOGS_REPORT_LIST',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_LIST',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_UPLOAD',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_APPROVE',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_REJECT',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT_DELETE',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_REALITY_CHECK_LIST',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_REALITY_CHECK_EDIT',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DEPOSIT_LIMIT_LIST',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DEPOSIT_LIMIT_EDIT',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_INTERNAL_SELF_EXCLUSION_LIST',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_INTERNAL_SELF_EXCLUSION_EDIT',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_TIMEOUT_LIST',
  'MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_TIMEOUT_EDIT',
];

export const structuredValues: Permissions[] = [];
export const standaloneValues: PermissionItem[] = [];

/// this snippet will handle organizing the permissions into structuredValues and standaloneValues
/// structuredValues means that the permission has a subcatergory while the standaloneValues are the permissions that has no subcategory
Object.entries(ALL_PERMISSIONS.ALL_MEMBERS).forEach(([key, value]) => {
  const parts = key.split('_');
  const mainCategory = parts.slice(0, 5).join('_');
  const subCategory = parts.slice(5).join('_');

  if (subCategory) {
    const parentIndex = structuredValues.findIndex(
      (item) => item.key === mainCategory
    );
    if (parentIndex === -1) {
      structuredValues.push({
        key: mainCategory,
        value: [{ key, value }],
      });
    } else {
      structuredValues[parentIndex].value.push({ key, value });
    }
  } else {
    standaloneValues.push({ key, value });
  }
});

export const memberManagementStandAloneList = standaloneValues.filter((item) =>
  item.key.includes(memberManagementHint)
);

export const memberMarkerStandAloneList = standaloneValues.filter((item) =>
  item.key.includes(memberMarkerManagementHint)
);

export const memberBulkUpdateManagementPermissions = [
  ...standaloneValues
    .filter((item) => item.key.includes('MEMBERS_MEMBER_BULK_UPDATE'))
    .map((item) => item),
  ...flatten(
    structuredValues
      .filter((item) => item.key.includes('MEMBERS_MEMBER_BULK_UPDATE_MEMBERS'))
      .map((item) => item.value.map((v) => v))
  ),
];

export const memberReportsManagementPermissions = [
  ...standaloneValues.filter((item) => item.key.includes('MEMBERS_REPORTS')),
  ...structuredValues
    .filter((item) => item.key.includes('MEMBERS_REPORTS'))
    .map((item) => item.value[0]),
];

export const memberLabelManagementPermissions = [
  ...standaloneValues.filter((item) => item.key.includes('LABEL_MANAGEMENT')),
];

export const memberMarkerManagementPermissions = [
  ...memberMarkerStandAloneList.filter((item) =>
    item.key.endsWith(`${memberMarkerManagementHint}`)
  ),
  ...memberMarkerStandAloneList.filter((item) =>
    item.key.endsWith(`${memberMarkerManagementHint}_LIST`)
  ),
  ...structuredValues
    .filter((item) => item.key.endsWith(`${memberMarkerManagementHint}_VIEW`))
    .map((item) => item.value[0]),
  ...memberMarkerStandAloneList.filter(
    (item) => !memberMarkerTopParent.includes(item.key)
  ),
  ...structuredValues
    .filter((item) => item.key.endsWith(`${memberMarkerManagementHint}_TOGGLE`))
    .map((item) => item.value[0]),
];

export const memberEditBalancePermission = flatten([
  ...structuredValues
    .filter((item) => item.key.includes(`${memberManagementHint}_EDIT_BALANCE`))
    .map((item) => item.value.map((value) => value)),
]);

export const membersOnlineOnlyPermission = [
  ...standaloneValues.filter((item) =>
    item.key.includes('MEMBERS_MEMBERS_ONLINE_ONLY')
  ),
];

export const memberConfigsPermission = [
  ...standaloneValues.filter((item) =>
    item.key.includes(memberConfigManagementHint)
  ),
];

export const memberManagement = [
  ...standaloneValues.filter(
    (item) =>
      item.key === `${memberManagementHint}_VIEW_DETAILS` ||
      item.key === `${memberManagementHint}` ||
      item.key === `${memberManagementHint}_LIST`
  ),
  ...structuredValues
    .filter((item) => item.key.includes(`${memberManagementHint}_REPORT`))
    .map((item) => item.value[0]),
  ...flatten(
    structuredValues
      .filter((item) => item.key.includes('VIEW_DETAILS'))
      .map((item) =>
        isArray(item.value) ? item.value.map((v) => v) : item.value
      )
  ),
  ...memberManagementStandAloneList.filter(
    (item) => !keysToRemoveMemberManagement.includes(item.key)
  ),
  ...flatten(
    structuredValues
      .filter((item) => item.key.includes('EDIT_BRAND'))
      .map((item) =>
        isArray(item.value) ? item.value.map((v) => v) : item.value
      )
  ),
];

export const allList = [
  ...standaloneValues.filter(
    (item) =>
      item.key.endsWith('MEMBERS') ||
      item.key.endsWith('MEMBERS_MEMBERS_ONLINE_ONLY')
  ),
  ...memberManagement,
  ...memberEditBalancePermission,
  ...memberMarkerManagementPermissions,
  ...memberLabelManagementPermissions,
  ...memberReportsManagementPermissions,
  ...memberBulkUpdateManagementPermissions,
  ...memberConfigsPermission,
];
export const membersManagementList = uniq([
  ...standaloneValues.filter(
    (item) =>
      item.key.endsWith('MEMBERS') ||
      item.key.includes('MEMBERS_MEMBER_MANAGEMENT_LIST')
  ),
  ...memberManagement,
  ...memberEditBalancePermission,
]);
export const memberEditBalanceAddList = [
  ...memberEditBalancePermission.filter((item) =>
    item.key.includes(`${memberManagementHint}_EDIT_BALANCE_ADD_BALANCE`)
  ),
];
export const memberEditBalanceRemoveList = [
  ...memberEditBalancePermission.filter((item) =>
    item.key.startsWith(`${memberManagementHint}_EDIT_BALANCE_REMOVE`)
  ),
];
export const memberViewDetailsManagementList = [
  ...standaloneValues.filter(
    (item) =>
      item.key.endsWith('MEMBERS') ||
      item.key.endsWith(`${memberManagementHint}`) ||
      item.key.endsWith(`${memberManagementHint}_LIST`)
  ),
  ...memberManagement.filter(
    (item) => item.key.includes('VIEW_DETAILS') || item.key.includes('REPORT')
  ),
];

export const memberEditBalanceManagementList = [
  ...memberEditBalancePermission,
  ...standaloneValues.filter((item) =>
    item.key.includes(`${memberManagementHint}_EDIT_BALANCE`)
  ),
];

const parentMemberManagementLookup = [
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_LIST,
];

const parentMarkerManagementLookup = [
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT_LIST,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MARKER_MANAGEMENT_VIEW_DETAILS,
];
const parentLabelManagementLookup = [
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_LABEL_MANAGEMENT,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_LABEL_MANAGEMENT_LIST,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_LABEL_MANAGEMENT_VIEW_DETAILS,
];
const parentBulkUpdateManagementLookup = [
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_BULK_UPDATE,
];
const parentReportsManagementLookup = [
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_REPORTS,
];
const parentMembersConfigLookup = [
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS,
  ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MANAGEMENT_CONFIG,
];

const topLevelPermissionsListToBeChecked = [
  'MEMBERS',
  `${memberManagementHint}`,
  `${memberManagementHint}_LIST`,
  `${memberManagementHint}_VIEW_DETAILS`,
  `${memberManagementHint}_EDIT_BALANCE`,
  `${memberMarkerManagementHint}`,
  `${memberMarkerManagementHint}_LIST`,
  `${memberMarkerManagementHint}_VIEW_DETAILS`,
  `${memberLabelManagementHint}`,
  `${memberLabelManagementHint}_LIST`,
  `${memberLabelManagementHint}_VIEW_DETAILS`,
  `${memberBulkUpdateManagementHint}`,
  `${memberReportsManagementHint}`,
  `${memberConfigManagementHint}`,
  'MEMBERS_MEMBERS_ONLINE_ONLY',
];

const hints = [
  memberManagementHint,
  memberMarkerManagementHint,
  memberLabelManagementHint,
  memberBulkUpdateManagementHint,
  memberReportsManagementHint,
  memberConfigManagementHint,
  'MEMBERS_MEMBERS_ONLINE_ONLY',
];

const parentCheckboxKeyLookUp = [
  'MEMBERS',
  `${memberManagementHint}`,
  `${memberManagementHint}_LIST`,
  `${memberManagementHint}_EDIT_BALANCE`,
  `${memberManagementHint}_EDIT_BALANCE_ADD_BALANCE`,
  `${memberManagementHint}_EDIT_BALANCE_REMOVE_BALANCE`,
  `${memberManagementHint}_VIEW_DETAILS`,
  `${memberManagementHint}_VIEW_DETAILS_MEMBER_NOTES`,
  `${memberManagementHint}_VIEW_DETAILS_DOCUMENT_MANAGEMENT`,
  `${memberManagementHint}_VIEW_DETAILS_REALITY_CHECK`,
  `${memberManagementHint}_VIEW_DETAILS_INTERNAL_SELF_EXCLUSION`,
  `${memberManagementHint}_VIEW_DETAILS_MEMBER_INTERACTION_LOGS_REPORT`,
  `${memberManagementHint}_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS`,
  `${memberManagementHint}_VIEW_DETAILS_PLAYER_PROFILE`,
  `${memberManagementHint}_VIEW_DETAILS_MEMBER_INTERACTION_LOGS_REPORT`,
  `${memberManagementHint}_VIEW_DETAILS_DEPOSIT_LIMIT`,
  `${memberManagementHint}_VIEW_DETAILS_TIMEOUT`,
  `${memberMarkerManagementHint}`,
  `${memberMarkerManagementHint}_LIST`,
  `${memberMarkerManagementHint}_VIEW_DETAILS`,
  `${memberLabelManagementHint}`,
  `${memberLabelManagementHint}_LIST`,
  `${memberLabelManagementHint}_VIEW_DETAILS`,
  `${memberBulkUpdateManagementHint}`,
  `${memberReportsManagementHint}`,
  `${memberConfigManagementHint}`,
];

/**
 * @description this function will handle the check of the parent if the child is checked
 * @param permission its the array of permission list
 * @param key it's a key which is used to filter the permission list
 * @returns an array of permission list
 */
const handleCheckParentIfChildIsChecked = (
  permission: PermissionItem[],
  key: string
) => {
  if (key.startsWith(`${memberManagementHint}_EDIT_BALANCE_ADD_BALANCE_`)) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_EDIT_BALANCE_ADD_BALANCE`
          ) ||
          item.key.endsWith(`${memberManagementHint}_EDIT_BALANCE`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberManagementHint}_EDIT_BALANCE_REMOVE_BALANCE_`)) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_EDIT_BALANCE_REMOVE_BALANCE`
          ) ||
          item.key.endsWith(`${memberManagementHint}_EDIT_BALANCE`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (
    key.startsWith(`${memberManagementHint}_VIEW_DETAILS_DOCUMENT_MANAGEMENT_`)
  ) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_VIEW_DETAILS_DOCUMENT_MANAGEMENT`
          ) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberManagementHint}_VIEW_DETAILS_PLAYER_PROFILE_`)) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_VIEW_DETAILS_PLAYER_PROFILE`
          ) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberManagementHint}_REPORT_`)) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberManagementHint}_VIEW_DETAILS_MEMBER_NOTES_`)) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_VIEW_DETAILS_MEMBER_NOTES`
          ) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (
    key.startsWith(
      `${memberManagementHint}_VIEW_DETAILS_INTERNAL_SELF_EXCLUSION_`
    )
  ) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_VIEW_DETAILS_INTERNAL_SELF_EXCLUSION`
          ) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberManagementHint}_VIEW_DETAILS_MEMBER_NOTES_`)) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_VIEW_DETAILS_MEMBER_NOTES`
          ) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (
    key.startsWith(`${memberManagementHint}_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS_`)
  ) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS`
          ) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberManagementHint}_VIEW_DETAILS_DEPOSIT_LIMIT_`)) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_VIEW_DETAILS_DEPOSIT_LIMIT`
          ) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberManagementHint}_VIEW_DETAILS_REALITY_CHECK_`)) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_VIEW_DETAILS_REALITY_CHECK`
          ) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (
    key.startsWith(
      `${memberManagementHint}_VIEW_DETAILS_MEMBER_INTERACTION_LOGS_REPORT_`
    )
  ) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(
            `${memberManagementHint}_VIEW_DETAILS_MEMBER_INTERACTION_LOGS_REPORT`
          ) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberManagementHint}_VIEW_DETAILS_TIMEOUT_`)) {
    return [
      ...permission.filter(
        (item) =>
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS_TIMEOUT`) ||
          item.key.endsWith(`${memberManagementHint}_VIEW_DETAILS`) ||
          parentMemberManagementLookup.includes(item.value)
      ),
    ];
  }
  if (
    !memberMarkerTopParent.includes(key) &&
    key.startsWith(`${memberMarkerManagementHint}`)
  ) {
    return [
      ...permission.filter((item) =>
        parentMarkerManagementLookup.includes(item.value)
      ),
    ];
  }
  if (
    !memberLabelTopParent.includes(key) &&
    key.startsWith(memberLabelManagementHint)
  ) {
    return [
      ...permission.filter((item) =>
        parentLabelManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberBulkUpdateManagementHint}_`)) {
    return [
      ...permission.filter((item) =>
        parentBulkUpdateManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith(`${memberReportsManagementHint}_`)) {
    return [
      ...permission.filter((item) =>
        parentReportsManagementLookup.includes(item.value)
      ),
    ];
  }
  if (key.startsWith('MEMBERS_MANAGEMENT_CONFIG_')) {
    return [
      ...permission.filter((item) =>
        parentMembersConfigLookup.includes(item.value)
      ),
    ];
  }
  if (key.endsWith('MEMBERS_MEMBERS_ONLINE_ONLY')) {
    return [...permission.filter((item) => item.key.endsWith('MEMBERS'))];
  }
  return [
    ...permission.filter((item) =>
      parentMemberManagementLookup.includes(item.value)
    ),
  ];
};

/**
 * @description this function will check if the key contains the hint
 * @returns the hint
 */
const containsKey = (key: string, hintList: string[]) =>
  hintList.find((hint) => key.includes(hint));

/**
 * @description this function will get the immediate parent key by splitting the key using `_` and then joining the parts
 * @param key it's the key that will be used to get the parent key
 * @returns the parent key
 */
const getImmediateParent = (key: string) => {
  const parts = key.split('_');
  if (parts.length > 1) {
    parts.pop(); // Remove the last part
    return parts.join('_');
  }
  return null;
};

/**
 * @description this function will check if the parent has checked children
 * @param parentKey it's the parent key
 * @param checkedList it's the list of checked permissions
 * @returns a boolean
 */
const hasCheckedChildren = (key: string, checkedList: PermissionItem[]) =>
  checkedList.some((item) => item.key.startsWith(key) && item.key !== key);

/**
 * @description this function will handle checking the parent and the child, it's both used for checking the parent and the child permission.
 * In this function the parent's permission key is being checked if it's the same as the key coming from the checkbox value and
 * then if it's equal it will set the children that belongs to the parent permission. Otherwise it will check individual permission and set the checked status if it
 * has a parent permission.
 * @setCheckedStatus it's a function that will set the checked status
 */
export const onChangeCheck = (
  e: CheckboxChangeEvent,
  setCheckedStatus: React.Dispatch<React.SetStateAction<CheckState>>,
  currentList?: PermissionItem[]
) => {
  let subParent = e.target.value.key;
  while (subParent) {
    if (parentCheckboxKeyLookUp.includes(subParent)) {
      break;
    }
    subParent = getImmediateParent(subParent);
  }

  let parentKey = getImmediateParent(subParent);
  while (parentKey) {
    if (parentCheckboxKeyLookUp.includes(parentKey)) {
      break;
    }
    parentKey = getImmediateParent(parentKey);
  }
  const updateList = (list: PermissionItem[]) => {
    setCheckedStatus((prev) => ({
      ...prev,
      currentCheckedList: uniq(list),
    }));
  };

  if (parentCheckboxKeyLookUp.includes(e.target.value.key)) {
    if (e.target.checked) {
      if (e.target.value.key === `${memberManagementHint}_VIEW_DETAILS`) {
        updateList([
          ...(currentList as PermissionItem[]),
          ...allList.filter(
            (item) =>
              topLevelPermissionsListToBeChecked
                .filter(
                  (l) =>
                    l.includes(`${containsKey(e.target.value.key, hints)}`) &&
                    !l.includes('EDIT_BALANCE')
                )
                .includes(item.key) ||
              item.key.endsWith('MEMBERS') ||
              item.key.includes(
                `${containsKey(e.target.value.key, hints)}_VIEW_DETAILS`
              ) ||
              item.key.includes(
                `${containsKey(e.target.value.key, hints)}_REPORT`
              )
          ),
        ]);
      } else if (
        e.target.value.key.endsWith('_VIEW_DETAILS') ||
        e.target.value.key.endsWith('_LIST')
      ) {
        updateList([
          ...(currentList as PermissionItem[]),
          ...allList.filter(
            (item) =>
              parentCheckboxKeyLookUp.includes(item.value) ||
              item.key.includes(
                containsKey(e.target.value.key, hints) as string
              )
          ),
        ]);
      } else {
        updateList([
          ...(currentList as PermissionItem[]),
          ...allList.filter(
            (item) =>
              topLevelPermissionsListToBeChecked
                .filter((l) =>
                  l.includes(`${containsKey(e.target.value.key, hints)}`)
                )
                .includes(item.key) ||
              item.key.startsWith(e.target.value.key) ||
              item.key.endsWith('MEMBERS')
          ),
        ]);
      }
    } else {
      if (e.target.value.key === `${memberManagementHint}_VIEW_DETAILS`) {
        updateList(
          currentList?.filter(
            (item) =>
              !item.key.includes(`${memberManagementHint}_VIEW_DETAILS`) &&
              !item.key.includes(`${memberManagementHint}_REPORT`)
          ) as PermissionItem[]
        );
      } else {
        updateList(
          currentList?.filter(
            (item) => !item.key.startsWith(e.target.value.key)
          ) as PermissionItem[]
        );
      }
      setCheckedStatus((prev) => {
        if (!hasCheckedChildren(`${parentKey}`, prev.currentCheckedList)) {
          return {
            ...prev,
            currentCheckedList: prev.currentCheckedList.filter(
              (item) => item.key !== parentKey
            ),
          };
        }
        return prev;
      });
    }
  } else {
    if (e.target.checked) {
      // individual check for checkbox
      setCheckedStatus((prev) => ({
        ...prev,
        currentCheckedList: [
          ...(prev.currentCheckedList as PermissionItem[]),
          ...handleCheckParentIfChildIsChecked(allList, e.target.value.key),
          e.target.value,
        ],
      }));
    } else {
      const newList = currentList?.filter(
        (item) => item.key !== e.target.value.key
      );
      // this will check if the sub-parent or the parent has no child checked
      if (!hasCheckedChildren(subParent, newList as PermissionItem[])) {
        updateList(
          newList?.filter((item) => item.key !== subParent) as PermissionItem[]
        );
      }
      setCheckedStatus((prev) => {
        if (!hasCheckedChildren(`${parentKey}`, prev.currentCheckedList)) {
          return {
            ...prev,
            currentCheckedList: prev.currentCheckedList.filter(
              (item) => item.key !== parentKey
            ),
          };
        }
        return {
          ...prev,
          currentCheckedList: prev.currentCheckedList.filter(
            (item) => !item.key.includes(e.target.value.key)
          ),
        };
      });
    }
  }
};

interface HandleIsIndeterminateParent {
  currentList: PermissionItem[];
  key?: string;
  key2?: string;
  listToCompare: PermissionItem[];
}

export const handleIsIndeterminateParent = ({
  currentList,
  listToCompare,
  key,
  key2,
}: HandleIsIndeterminateParent) => {
  if (key) {
    return (
      currentList.some(
        (item) => item.key.startsWith(key) || (key2 && item.key.includes(key2))
      ) &&
      currentList.filter(
        (val) => val.key.startsWith(key) || (key2 && val.key.includes(key2))
      ).length !==
        listToCompare.filter(
          (val) => val.key.includes(key) || (key2 && val.key.includes(key2))
        ).length
    );
  }
  return (
    currentList.some((item) =>
      listToCompare.some((val) => val.key.includes(item.key))
    ) && currentList.length !== listToCompare.length
  );
};
