import { defineMessages } from 'react-intl';

const localMessages = defineMessages({
  CLIENT_ACCOUNT: {
    id: 'reports.client-account.text',
    defaultMessage: 'Client Account',
  },
  TIMEZONE_SELECTION: {
    id: 'reports.timezone-selection.text',
    defaultMessage: 'Timezone Selection',
  },
  CREATED_DATE: {
    id: 'reports.created-date.text',
    defaultMessage: 'Created Date',
  },
  CREATED_BY: {
    id: 'reports.created-by.text',
    defaultMessage: 'Created By',
  },
  FILTERS: {
    id: 'reports.filters.text',
    defaultMessage: 'Filters',
  },
  DOWNLOAD: {
    id: 'reports.download.text',
    defaultMessage: 'Download',
  },
  NO_STATUS: {
    id: 'reports.no-status.text',
    defaultMessage: 'No Status',
  },
  SCHEDULED: {
    id: 'reports.scheduled.text',
    defaultMessage: 'Scheduled',
  },
  TABLE_REFRESHED: {
    id: 'reports.table-refreshed.text',
    defaultMessage: 'Table Refreshed',
  },
  REFRESH_ERROR: {
    id: 'reports.refresh-error.text',
    defaultMessage: 'Refresh Error',
  },
});

export default localMessages;
