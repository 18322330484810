import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select } from 'antd';
import {
  isTypeBank,
  isTypeWechat,
  isTypeAlipay,
  isTypeUnionPay,
} from 'constants/paymentTypes';
import { thirdPartyTypeNames } from 'constants/thirdPartyProviders';

import useTranslate from 'utils/useTranslate';
import globalMsgs from 'messages';
import messages from 'pages/components/PaymentMethodListing/messages';

import { SupportedBanksContainer, BankTag, StyledSelect } from './styles';
import { DEPOSIT_PROVIDER } from './queries';

const { Option } = Select;

const { midPayDirect, diorPay2 } = thirdPartyTypeNames;

type Props = {
  depositProviderId: string;
  paymentType: string;
  value: string;
  setFieldValue: (key: string, value: any) => void;
  formItemLayout: Record<string, any>;
  errors: any;
  presetPaymentChannels: any[];
  providerInfoData: Record<string, any>;
  loadingProviderInfo: boolean;
};

const OnlinePaymentChannel = ({
  depositProviderId,
  paymentType,
  value,
  setFieldValue,
  formItemLayout,
  errors,
  presetPaymentChannels,
  providerInfoData,
  loadingProviderInfo,
}: Props) => {
  /* *
   * NOTE:  The paymentChannel field is being cleared when you select a new external deposit provider in the
   *        component of ExternalDepositProvider
   * */

  const translate = useTranslate();
  const { data = {}, loading } = useQuery(DEPOSIT_PROVIDER, {
    variables: {
      id: depositProviderId,
    },
    skip: !depositProviderId,
  });

  const { depositProvider = {} } = data as Record<string, any>;
  let options = [];

  if (
    typeof depositProvider.paymentChannels === 'object' &&
    depositProvider.paymentChannels
  ) {
    /* *
     * TODO:
     * REMOVE THIS IF-BLOCK STATEMENT ONCE DIORPAY HAS BEEN DEPRECATED
     * ALSO, Include the fetching of DEPOSIT_PROVIDER from this component
     * */
    const optionGenerator = (newOptions: any[]) =>
      newOptions.map((option) => ({
        value: option,
        label: `${depositProvider.name} - ${option}`,
      }));

    if (isTypeBank(paymentType)) {
      options = depositProvider.paymentChannels.bank;
    } else if (isTypeWechat(paymentType)) {
      options = optionGenerator(depositProvider.paymentChannels.wechat);
    } else if (isTypeAlipay(paymentType)) {
      options = optionGenerator(depositProvider.paymentChannels.alipay);
    } else if (isTypeUnionPay(paymentType)) {
      options = optionGenerator(depositProvider.paymentChannels.unionpay);
    }
  } else if (presetPaymentChannels.length) {
    const generateOptions = (newPaymentChannels: any[]) =>
      newPaymentChannels.map((paymentChannel) => {
        const paymentProviderName = providerInfoData.name;
        const paymentChannelName = translate(
          globalMsgs[paymentChannel.toUpperCase()]
        ).toUpperCase();

        return {
          value: paymentChannel,
          label: `${paymentProviderName} - ${paymentChannelName}`,
        };
      });

    switch (depositProvider.__typename) {
      case midPayDirect: {
        const getMidPayDirectChannels = (regex: RegExp) =>
          presetPaymentChannels.filter((paymentChannel) =>
            regex.test(paymentChannel)
          );

        if (isTypeBank(paymentType)) {
          options = generateOptions(getMidPayDirectChannels(/bank/i));
        } else if (isTypeWechat(paymentType)) {
          options = generateOptions(getMidPayDirectChannels(/wechat/i));
        } else if (isTypeAlipay(paymentType)) {
          options = generateOptions(getMidPayDirectChannels(/alipay/i));
        } else if (isTypeUnionPay(paymentType)) {
          options = generateOptions(getMidPayDirectChannels(/unionpay/i));
        }
        break;
      }

      case diorPay2: {
        const getDiorPay2Channels = (regex: RegExp) =>
          presetPaymentChannels.reduce((newChannels, channel) => {
            if (regex.test(channel.paymentType)) {
              return [...newChannels, channel.id];
            }

            return newChannels;
          }, []);

        if (isTypeBank(paymentType)) {
          options = generateOptions(getDiorPay2Channels(/bank/i));
        } else if (isTypeWechat(paymentType)) {
          options = generateOptions(getDiorPay2Channels(/wechat/i));
        } else if (isTypeAlipay(paymentType)) {
          options = generateOptions(getDiorPay2Channels(/alipay/i));
        } else if (isTypeUnionPay(paymentType)) {
          options = generateOptions(getDiorPay2Channels(/unionpay/i));
        }
        break;
      }

      default:
        options = [];
        break;
    }
  }

  return (
    <Form.Item
      {...formItemLayout}
      label={translate(messages.channel)}
      validateStatus={errors.paymentChannel ? 'error' : ''}
      help={errors.paymentChannel || null}
    >
      <Input.Group>
        <Row gutter={8}>
          <Col span={24}>
            {isTypeBank(paymentType) && !presetPaymentChannels.length ? (
              <SupportedBanksContainer>
                {options.map((supportedBank: string) => (
                  <BankTag key={supportedBank}>{supportedBank}</BankTag>
                ))}
              </SupportedBanksContainer>
            ) : (
              <StyledSelect
                value={value}
                loading={loading || loadingProviderInfo}
                disabled={!depositProviderId || !paymentType}
                onChange={(e) => setFieldValue('paymentChannel', e)}
              >
                {options.map((option: Record<string, any>) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </StyledSelect>
            )}
          </Col>
        </Row>
      </Input.Group>
    </Form.Item>
  );
};

export default OnlinePaymentChannel;
