import gql from 'graphql-tag';

export const MEMBER_REBATE_PAYOUTS = gql`
  query Member($id: ID!, $after: Binary) {
    member(id: $id) {
      id
      rebatePayouts(first: 10, after: $after) {
        edges {
          cursor
          node {
            id
            amount
            dateTimeClaimed
            rebateGroup {
              id
              name
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
        totalCount
      }
    }
  }
`;
