// Decimal formatter for point precision and making it adaptable for any decimal length you will pass on the first parameter whether a string or integer
// and it will round down numbers up to the specified decimals you will pass on the second parameter, please check test suite for reference

// this function will be useful for fields that has mandatory decimal places like in cryptocurrency
export const setDecimals = (num: any, decimals: number) => {
  const t = 10 ** decimals;
  return (
    Math.floor(
      num * t +
        (decimals > 0 ? 1 : 0) * (Math.sign(num) * (10 / 100 ** decimals))
    ) / t
  ).toFixed(decimals);
};

export const truncateDecimals = (value: number, decimals: number) => {
  const numbers = (typeof value === 'string' ? value : value.toString()).split(
    '.'
  );

  const wholeNumber = numbers[0] || '';
  let decNumbers = numbers[1] || '';

  if (decNumbers.length > decimals)
    return `${wholeNumber}.${decNumbers.slice(0, decimals)}`;

  while (decNumbers.length < decimals) decNumbers += '0';
  return `${wholeNumber}.${decNumbers}`;
};
