import React from 'react';

type Props =
  | {
      loaderKeyVals: Record<string, any>;
    }
  | undefined;

export const DataLoaderContext = React.createContext<Props>(undefined);
const DataLoaderProvider = ({ children, loaderKeyVals, isNext }: any) => (
  <DataLoaderContext.Provider value={{ ...loaderKeyVals, isNext }}>
    {children}
  </DataLoaderContext.Provider>
);

export const useLoaders = () => React.useContext<any | null>(DataLoaderContext);

export default DataLoaderProvider;
