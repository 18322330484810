import gql from 'graphql-tag';

export const GAME_CATEGORY = gql`
  query GameCategory($id: ID!) {
    gameCategory(id: $id) {
      id
      name
    }
  }
`;

export default {};
