import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'antd';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import { columnAlign } from 'utils/tableAlignment';
import messages from '../../../../messages';

type Props = {
  permissionStringArray: any[];
};

const PermissionValue = ({ permissionStringArray }: Props) => {
  const limit = permissionStringArray.length - 1;
  return permissionStringArray.reduce(
    (acc, curr, index) => `${acc}${curr}${index === limit ? '' : ':'}`,
    ''
  );
};

const DisplayPermission = ({
  permissions,
  isContent,
}: {
  permissions: any;
  isContent: any;
}) => {
  const display = permissions.map((newPermission: any, key: number) => {
    const permissionStringArray = newPermission.split(':');

    if (isContent) {
      return (
        <p key={key}>
          <PermissionValue permissionStringArray={permissionStringArray} />
        </p>
      );
    }

    if (key > 1) return '';

    return (
      <span key={key}>
        <PermissionValue permissionStringArray={permissionStringArray} />
        {permissions.length - 1 !== key && ', '}
      </span>
    );
  });

  if (isContent) return display;

  return permissions.length > 1 ? (
    <div>
      {display}
      {permissions.length > 2 && <span> ...</span>}
    </div>
  ) : (
    permissions[0]
  );
};

const Columns = () => {
  const translate = useTranslate();
  return [
    {
      csvData: {
        label: translate(messages.permissions),
        key: 'permissions',
        renderCell: (permissionGroup: any) =>
          coercedGet(permissionGroup, 'permissions', ''),
      },
      key: 'permissions',
      align: columnAlign.onCenter,
      width: 100,
      title: translate(messages.permissions),
      dataIndex: 'permissions',
      render: (permissions: any) => (
        <Popover
          overlayInnerStyle={{
            height: '400px',
            overflow: 'auto',
          }}
          placement="bottom"
          content={<DisplayPermission permissions={permissions} isContent />}
          title={
            <FormattedMessage
              id="permissions.text"
              defaultMessage="Permissions"
            />
          }
        >
          <span style={{ cursor: 'pointer' }}>
            <DisplayPermission permissions={permissions} isContent={false} />
          </span>
        </Popover>
      ),
    },
  ];
};

export default Columns;
