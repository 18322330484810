import gql from 'graphql-tag';

export const GET_MEMBER_LEVELS_IDS = gql`
  query MemberLevelsIds(
    $filter: MemberLevelFilterInput
    $partialFilter: MemberLevelFilterInput
  ) {
    memberLevels(filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: memberLevels(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_MEMBER_LEVELS = gql`
  query MemberLevels(
    $first: Int
    $after: Binary
    $filter: MemberLevelFilterInput
  ) {
    memberLevels(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          status
          color
          name
          default
          membersCount
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
