import { useLazyQuery } from '@apollo/react-hooks';
import { useDebounce } from 'hooks/useDebounce';
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { StyledSelect } from 'styles/SharedStyledSelectFilter';
import { Select, Tag } from 'antd';
import coercedGet from 'utils/coercedGet';
import {
  MemberLoyaltyLevelsConnectionEdge,
  MemberLoyaltyProgrammesConnectionEdge,
} from 'types/graphqlTypes';
import { concat, flatten, truncate, unionBy } from 'lodash';

const MEMBER_LOYALTY_LEVELS = gql`
  query memberLoyaltyLevels($first: Int, $input: String) {
    memberLoyaltyLevels(
      first: $first
      filter: { name: { contains: $input }, status: { in: [ACTIVE] } }
    ) {
      edges {
        node {
          id
          name
          color
          programme {
            id
            name
          }
        }
      }
    }

    memberLoyaltyProgrammes(
      first: $first
      filter: { name: { contains: $input }, status: { in: [ACTIVE] } }
    ) {
      edges {
        node {
          id
          name
          levels {
            id
            name
            color
          }
        }
      }
    }
  }
`;

type Option = {
  id: string;
  label: string;
  color: string;
}[];

type Props = {
  values: string[];
  onChange: (e: string[] | null) => void;
};

const VIPFilterNext = ({ values, onChange }: Props) => {
  const [searchedText, setSearchedText] = useState('');
  const debouncedText = useDebounce(searchedText, 500);
  const [options, setOptions] = useState<Option>([]);

  const [loadVips, { loading }] = useLazyQuery(MEMBER_LOYALTY_LEVELS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 10,
      input: debouncedText,
    },
    onCompleted: (data) => {
      const memberLoyaltyLevelsEdge: MemberLoyaltyLevelsConnectionEdge[] = coercedGet(
        data,
        'memberLoyaltyLevels.edges',
        []
      );
      const memberLoyaltyProgrammesEdge: MemberLoyaltyProgrammesConnectionEdge[] = coercedGet(
        data,
        'memberLoyaltyProgrammes.edges',
        []
      );

      const loyaltyLevelsRaw = memberLoyaltyLevelsEdge.map(({ node }) => {
        const programmeName = truncate(node.programme?.name!, { length: 10 });
        const label = `${programmeName} - ${node.name}`;

        return {
          id: node.id,
          label,
          color: node.color,
        };
      });

      const loyaltyProgrammeRaw = (flatten(
        memberLoyaltyProgrammesEdge.map(({ node }) =>
          node.levels?.map((level) => {
            const programmeName = truncate(node?.name!, {
              length: 10,
            });
            const label = `${programmeName} - ${level.name}`;

            return {
              id: level.id,
              label,
              color: level.color,
            };
          })
        )
      ) as unknown) as Option;

      const optionsRaw = concat(loyaltyLevelsRaw, loyaltyProgrammeRaw);
      const uniqueOptions = unionBy(optionsRaw, 'id');

      setOptions(uniqueOptions);
    },
  });

  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange(e);
    } else {
      onChange(null);
    }

    setSearchedText('');
  };

  const handleTagRender = (props: { value: any; onClose: () => void }) => {
    const { value: id, onClose } = props;

    const loyaltyTag = options?.find((option) => option?.id === id);

    if (!loyaltyTag) return <></>;

    return (
      <Tag
        id={loyaltyTag.id}
        closable
        onClose={onClose}
        color={loyaltyTag.color}
        style={{
          margin: 3,
          borderRadius: 10,
        }}
      >
        {loyaltyTag.label}
      </Tag>
    );
  };

  return (
    <StyledSelect
      mode="multiple"
      value={values}
      showSearch
      placeholder="Enter Loyalty Levels"
      onChange={handleChange}
      onSearch={(text: string) => setSearchedText(text)}
      loading={loading}
      onBlur={() => setSearchedText('')}
      tagRender={handleTagRender}
      onDropdownVisibleChange={(open) => {
        if (open && !options?.length) loadVips();
      }}
      filterOption={false}
    >
      {options?.map((option) => (
        <Select.Option key={option.id} value={option.id!}>
          {option.label}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};

export default VIPFilterNext;
