import React, { useState } from 'react';
import {
  CloseSquareOutlined,
  LayoutOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import { StyledContent } from 'styles';
import { Domain } from 'types/graphqlTypes';
import SiteConfigurationTable from '../SiteConfigurationTable';
import NewDomainDrawer from '../NewDomainDrawer';
import { usePermissions } from '../../../../../store/accountState';
import { collectPermissions } from '../../../PermissionGroup/utils';
import ALL_PERMISSIONS from '../../../../../constants/permissions3';

const SiteConfigurationContent = ({
  handleSidebar,
  sidebarStatus,
  dataSource,
  refetch,
}: {
  refetch: () => void;
  handleSidebar: () => void;
  sidebarStatus: boolean;
  dataSource: Domain[];
}) => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_SITE_CONFIG}:SUBDOMAIN`
  );

  const [newSubdomainState, setNewSubDomainState] = useState(false);

  return (
    <StyledContent className="p-3">
      <div className="d-flex justify-content-space-between mb-6 align-items-center">
        <div>
          <Button onClick={handleSidebar} size="small" className="mr-3">
            {!sidebarStatus ? <CloseSquareOutlined /> : <LayoutOutlined />}
          </Button>
          <FormattedMessage
            id="filter-conditions.text"
            defaultMessage="Filter conditions"
          />
          :
        </div>

        {ALLOWED_CREATE && (
          <Button type="primary" onClick={() => setNewSubDomainState(true)}>
            <PlusOutlined className="mr-2" />
            <FormattedMessage
              id="new-domain.text"
              defaultMessage="New Domain"
            />
          </Button>
        )}
      </div>

      <SiteConfigurationTable dataSource={dataSource} refetch={refetch} />

      {ALLOWED_CREATE && (
        <NewDomainDrawer
          refetch={refetch}
          closeDrawer={() => setNewSubDomainState(false)}
          drawerStatus={newSubdomainState}
        />
      )}
    </StyledContent>
  );
};

export default SiteConfigurationContent;
