import { useMutation, useQuery } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import { getPaymentType, PAYMENT_METHODS_LIST } from 'constants/paymentTypes';
import { UPDATE_PAYMENT_METHOD } from 'graphql/mutations/paymentMethodListing.mutation';
import {
  PAYMENT_METHOD,
  PAYMENT_METHODS,
} from 'graphql/queries/paymentMethodListing.query';
import globalMessages from 'messages';
import React from 'react';
import removeTypename from 'utils/removeTypename';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import PaymentGatewayForm from '../PaymentGatewayForm';
import { StyledModal } from './styles';

type Props = {
  toggleModal: () => void;
  id: string;
  refetchVariables: any;
};

const EditPaymentGateway = (props: Props) => {
  const translate = useTranslate();
  const { toggleModal, id, refetchVariables } = props;
  const {
    // error,
    data = {},
    loading,
  } = useQuery(PAYMENT_METHOD, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const { context } = useOperatorHeader();
  const [updatePaymentMethod, { loading: updateLoading }] = useMutation(
    UPDATE_PAYMENT_METHOD,
    {
      onCompleted: () =>
        message.success(translate(messages.successfullyupdated)),
      onError: () =>
        message.error(translate(globalMessages.INTERNAL_SERVER_ERROR)),
      refetchQueries: [
        {
          query: PAYMENT_METHODS,
          variables: { id },
        },
        {
          query: PAYMENT_METHODS,
          variables: refetchVariables,
        },
      ],
      context,
    }
  );

  const { paymentMethod = {} } = data as Record<string, any>;
  const newPaymentMethod = removeTypename(paymentMethod);

  return (
    <>
      <StyledModal
        title={paymentMethod.name}
        visible
        width={900}
        onOk={toggleModal}
        onCancel={toggleModal}
        footer={false}
        maskClosable={false}
      >
        <div>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <Spin />
            </div>
          ) : (
            <PaymentGatewayForm
              operation="edit"
              onClose={toggleModal}
              createPaymentMethod={updatePaymentMethod}
              loading={updateLoading}
              paymentMethod={{
                ...newPaymentMethod,
                excludedMemberLevel: newPaymentMethod.excludedMemberLevel.length
                  ? newPaymentMethod.excludedMemberLevel.map(
                      (item: Record<string, any>) => item.id
                    )
                  : [],
                memberLoyaltyLevel: newPaymentMethod.memberLoyaltyLevel.length
                  ? newPaymentMethod.memberLoyaltyLevel.map(
                      (item: Record<string, any>) => item.id
                    )
                  : [],
                bank: newPaymentMethod.bank || '',
                accountNumber: newPaymentMethod.accountNumber || '',
                accountName: newPaymentMethod.accountName || '',
                branch: newPaymentMethod.branch || '',
                country: newPaymentMethod.country || '',
                province: newPaymentMethod.province || '',
                city: newPaymentMethod.city || '',
                nickname: newPaymentMethod.nickname || '',
                image: newPaymentMethod.image || '',
                imageUrl: newPaymentMethod.image || '',
                depositProvider:
                  (newPaymentMethod.depositProvider &&
                    newPaymentMethod.depositProvider.id) ||
                  '',
                suggestedAmounts: newPaymentMethod.suggestedAmounts || [],
                transactionFee: newPaymentMethod.transactionFee || 0,
                transactionFeeType:
                  newPaymentMethod.transactionFeeType || 'ABSOLUTE',
                allowUpdateExchangeRate:
                  newPaymentMethod.exchangeRateSource !== 'MANUAL',
                exchangeRateSource: newPaymentMethod.exchangeRateSource || '',
                exchangeRateUpdateFrequencyUnit:
                  newPaymentMethod.exchangeRateUpdateFrequency &&
                  newPaymentMethod.exchangeRateUpdateFrequency.substr(
                    newPaymentMethod.exchangeRateUpdateFrequency.length - 1
                  ),
                exchangeRateUpdateFrequency:
                  newPaymentMethod.exchangeRateUpdateFrequency &&
                  newPaymentMethod.exchangeRateUpdateFrequency.replace(
                    /[^0-9/.]+/g,
                    ''
                  ),
              }}
              /* eslint no-underscore-dangle: 0 */
              type={
                PAYMENT_METHODS_LIST[
                  paymentMethod.__typename as keyof typeof PAYMENT_METHODS_LIST
                ]
              }
              paymentType={getPaymentType(paymentMethod.__typename)}
            />
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default EditPaymentGateway;
