import React, { useState } from 'react';
import { Button, Drawer, Input, message, Spin, DatePicker } from 'antd';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import {
  DynamicPage,
  DynamicPageConnection,
  DynamicPagePages,
  DynamicPageType,
  MutationCreateDynamicPageArgs,
} from 'types/graphqlTypes';
import { capitalize, difference, range, startCase } from 'lodash';
import { NullableType } from 'interfaces/graphql.interface';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import moment from 'moment';
import { useDeepCompareEffect } from 'react-use';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import {
  CREATE_DYNAMIC_PAGES,
  DYNAMIC_PAGE,
  DYNAMIC_PAGES,
  isAscending,
  validationSchema,
} from './utils';
import { PageEdit } from './components/PageEdit';

const ManageDynamicPages = () => {
  const [visible, setVisible] = useState(false);
  const [pageSelected, setSelectedPage] = useState<
    NullableType<Partial<DynamicPage>>
  >(null);
  const [isQueryLoading, setIsQueryLoading] = useState(false);

  const client = useApolloClient();
  const { control, reset, handleSubmit, watch, errors, getValues } = useForm<{
    pages: DynamicPagePages[];
    version: string;
    customDateTimeCreated: string;
  }>({
    defaultValues: {
      pages: [],
      version: '1',
      customDateTimeCreated: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { context } = useOperatorHeader();
  const [createDynamicPages, { loading: mutationLoading }] = useMutation<
    boolean,
    MutationCreateDynamicPageArgs
  >(CREATE_DYNAMIC_PAGES, {
    context,
  });
  const { fields, append, move, remove } = useFieldArray<DynamicPagePages>({
    control,
    name: 'pages',
  });

  const watchedPages = watch('pages');

  const loadDynamicPage = async (id?: string, type?: DynamicPageType) => {
    if (!id) {
      reset({ pages: [], version: '1', customDateTimeCreated: '' });
      setSelectedPage({
        pages: [],
        version: '1',
        customDateTimeCreated: '',
        type: type!,
      });
      return;
    }

    setIsQueryLoading(true);
    const pageData = await client.query({
      query: DYNAMIC_PAGE,
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    });
    const dynamicPage: DynamicPage = pageData?.data?.dynamicPage || {};
    const pagesValue = dynamicPage.pages.map((page) => ({
      title: page.title,
      content: page.content,
      pageNumber: page.pageNumber,
    }));
    reset({
      pages: pagesValue,
      version: dynamicPage?.version || '1',
      customDateTimeCreated: dynamicPage?.customDateTimeCreated,
    });
    setSelectedPage(dynamicPage);
    setIsQueryLoading(false);
  };

  const { data, loading } = useQuery<{
    faq: DynamicPageConnection;
    privacyPolicy: DynamicPageConnection;
    tnc: DynamicPageConnection;
  }>(DYNAMIC_PAGES, {
    skip: !visible,
    fetchPolicy: 'cache-and-network',
  });

  const resetState = () => {
    setSelectedPage(null);
    setVisible(false);
    reset({ pages: [], version: '1', customDateTimeCreated: '' });
  };

  const onSubmit = (values: {
    pages: DynamicPagePages[];
    version: string;
    customDateTimeCreated: string;
  }) => {
    const newPages = values.pages.map((value, idx: number) => ({
      ...value,
      pageNumber: idx + 1,
    }));
    createDynamicPages({
      variables: {
        input: {
          ...(values?.customDateTimeCreated && {
            customDateTimeCreated: values.customDateTimeCreated,
          }),
          version: values.version,
          pages: newPages,
          type: pageSelected!.type!,
        },
      },
    })
      .then(() => {
        message.success('Pages updated!');
      })
      .finally(() => {
        resetState();
      });
  };

  const updateFieldsChronologically = () => {
    const newFields = watchedPages.map((field, idx) => ({
      content: field.content,
      title: field.title,
      pageNumber: idx + 1,
    }));
    reset({
      pages: newFields,
      version: getValues().version,
      customDateTimeCreated: getValues().customDateTimeCreated,
    });
  };

  useDeepCompareEffect(() => {
    if (!watchedPages || watchedPages.length === 0) {
      return;
    }

    const pagesNum: number[] = watchedPages.map((field) => field.pageNumber!);

    if (isAscending(pagesNum)) {
      const arrayOfNum = range(pagesNum.length).map((x) => x + 1);
      const hasDiff = difference(pagesNum, arrayOfNum);

      if (hasDiff.length > 0) {
        updateFieldsChronologically();
      }
    } else {
      updateFieldsChronologically();
    }
  }, [fields]);

  return (
    <div>
      <h3>Manage Dynamic Pages</h3>
      <Button onClick={() => setVisible(true)}>Configure Dynamic Pages</Button>

      <Drawer
        onClose={resetState}
        title={
          pageSelected ? (
            <div className="d-flex align-items-center">
              <div className="mr-3">
                Editing: {startCase(capitalize(pageSelected.type))},{' '}
                {fields.length} pages
              </div>
              <Button
                onClick={() =>
                  append({
                    title: '',
                    content: '',
                    pageNumber: fields.length + 1,
                  })
                }
              >
                Add Page
              </Button>
            </div>
          ) : (
            'Manage Dynamic Pages'
          )
        }
        width="100%"
        visible={visible}
        footer={
          <div className="text-right">
            <Button className="mr-3" onClick={resetState}>
              {pageSelected ? 'Cancel' : 'Close'}
            </Button>
            {pageSelected && (
              <Button
                type="primary"
                loading={mutationLoading}
                disabled={mutationLoading}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            )}
          </div>
        }
      >
        {!isQueryLoading && !pageSelected && (
          <>
            <h3>Select a Page to Manage</h3>
            {loading ? (
              <Spin />
            ) : (
              <>
                <Button
                  className="mr-2"
                  onClick={() =>
                    loadDynamicPage(
                      data?.tnc?.edges[0]?.node?.id,
                      DynamicPageType.TermsAndConditions
                    )
                  }
                >
                  Terms and Conditions
                </Button>
                <Button
                  className="mr-2"
                  onClick={() =>
                    loadDynamicPage(
                      data?.privacyPolicy?.edges[0]?.node?.id,
                      DynamicPageType.PrivacyPolicy
                    )
                  }
                >
                  Privacy Policy
                </Button>
                <Button
                  className="mr-2"
                  onClick={() =>
                    loadDynamicPage(
                      data?.faq?.edges[0]?.node?.id,
                      DynamicPageType.FrequentlyAskedQuestions
                    )
                  }
                >
                  FAQ
                </Button>
              </>
            )}
          </>
        )}

        {isQueryLoading && (
          <div className="d-flex justify-content-center h-100 align-items-center">
            <Spin />
          </div>
        )}

        {!isQueryLoading && pageSelected && (
          <div className="mt-3">
            <div className="mb-3">
              <p className="font-weight-bold mb-0">Version:</p>
              <Controller
                control={control}
                as={<Input style={{ width: '10%' }} />}
                name="version"
              />
              {errors?.version && (
                <small className="text-danger d-block">
                  {errors.version.message}
                </small>
              )}
            </div>
            <div className="mb-3">
              <p className="font-weight-bold mb-0">Date/Time Created:</p>
              <Controller
                name="customDateTimeCreated"
                control={control}
                render={({ value, onChange }) => (
                  <DatePicker
                    showTime
                    onChange={onChange}
                    value={moment(value || new Date())}
                  />
                )}
              />
            </div>
            {fields?.map((page, idx) => (
              <PageEdit
                errors={errors?.pages ? errors?.pages[idx] : {}}
                allPages={fields}
                page={page}
                move={move}
                remove={remove}
                idx={idx}
                control={control}
              />
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default ManageDynamicPages;
