import gql from 'graphql-tag';

export const VENDOR_SETTINGS_ITEMS = gql`
  query VendorSettingsItems(
    $admin: ID!
    $context: VendorSettingsContext!
    $first: Int
    $after: Binary
    $filter: VendorSettingsItemsFilterInput
  ) {
    vendorSettingsItems(
      admin: $admin
      context: $context
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      edges {
        cursor
        node {
          vendor
          admin
          context
          gameTypeSettings {
            SLOT {
              disabled
              hidden
            }
            LIVE {
              disabled
              hidden
            }
            POKER {
              disabled
              hidden
            }
            FISH {
              disabled
              hidden
            }
            SPORTSBOOK {
              disabled
              hidden
            }
            LOTTERY {
              disabled
              hidden
            }
          }
          disabled
          hidden
          details
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const ALL_GAMES = gql`
  query AllGames {
    slotGames: games(filter: { type: { eq: SLOT } }) {
      totalCount
    }

    liveGames: games(filter: { type: { eq: LIVE } }) {
      totalCount
    }

    pokerGames: games(filter: { type: { eq: POKER } }) {
      totalCount
    }

    fishGames: games(filter: { type: { eq: FISH } }) {
      totalCount
    }

    sportsbookGames: games(filter: { type: { eq: SPORTSBOOK } }) {
      totalCount
    }

    lotteryGames: games(filter: { type: { eq: LOTTERY } }) {
      totalCount
    }
  }
`;

export const ACTIVE_GAMES = gql`
  query ActiveGames {
    slotGames: games(filter: { type: { eq: SLOT }, enabled: { eq: true } }) {
      totalCount
    }

    liveGames: games(filter: { type: { eq: LIVE }, enabled: { eq: true } }) {
      totalCount
    }

    pokerGames: games(filter: { type: { eq: POKER }, enabled: { eq: true } }) {
      totalCount
    }

    fishGames: games(filter: { type: { eq: FISH }, enabled: { eq: true } }) {
      totalCount
    }

    sportsbookGames: games(
      filter: { type: { eq: SPORTSBOOK }, enabled: { eq: true } }
    ) {
      totalCount
    }

    lotteryGames: games(
      filter: { type: { eq: LOTTERY }, enabled: { eq: true } }
    ) {
      totalCount
    }
  }
`;

export const VENDORS = gql`
  query Vendors($filter: VendorsFilterInput, $after: Binary, $first: Int) {
    vendors(filter: $filter, after: $after, first: $first) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          enabled
          gameTypes
          name
          visible
        }
      }
    }
  }
`;

export const GAMES = gql`
  query Games($filter: GamesFilterInput, $first: Int, $after: Binary) {
    games(filter: $filter, first: $first, after: $after) {
      totalCount
    }
  }
`;

export default {};
