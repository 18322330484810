import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Empty, Row, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { startCase } from 'lodash';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { useScreenTabV2 } from 'store/screenTabState';
import { usePermissions } from 'store/accountState';
import { formatDate } from 'utils/dateUtils';
import { ALink } from 'components/ALink/ALink';
import { DynamicObj } from 'interfaces/user.interface';
import messages from 'messages';
import useMemberCurrency from 'utils/useMemberCurrency';
import useTranslate from 'utils/useTranslate';
import { DATE_TIME_FORMAT } from 'constants/date';
import { showPermissionError } from 'components/Navbar/Navbar';

export const TurnoverBalance = ({
  member,
  balanceUnitsData,
}: {
  member: any;
  balanceUnitsData: IBalanceUnit[];
}) => {
  const { addTab } = useScreenTabV2();
  const translate = useTranslate();
  const { addCurrency } = useMemberCurrency();
  const { role, permissions } = usePermissions();
  const { ALLOWED_BALANCE_SERVICE_RECORD_REPORTS } = collectPermissions(
    role,
    permissions,
    ['BALANCE_SERVICE_RECORD_REPORTS'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS
  );

  const openBsvTab = (filter?: DynamicObj) => {
    if (!ALLOWED_BALANCE_SERVICE_RECORD_REPORTS) {
      showPermissionError('Balance Service Record Report - View');
      return;
    }
    addTab({
      id: 'balance-service-record-report',
      state: {
        memberRef: {
          id: member.id,
          username: member.username,
        },
        ...filter,
      },
    });
  };
  return balanceUnitsData.length > 0 ? (
    <div>
      <div style={{ padding: '16px 16px 0 16px' }}>
        <div style={{ height: '162px', overflowY: 'auto', flex: 1 }}>
          <Row style={{ fontSize: '10px' }} className="mb-1">
            <Col span={3}>
              <span className="text-muted-light">
                <FormattedMessage id="type.text" defaultMessage="Type" />
              </span>
            </Col>

            <Col span={6}>
              <span className="text-muted-light">
                <FormattedMessage id="date.text" defaultMessage="Date" />
              </span>
            </Col>
            <Col span={4}>
              <span className="text-muted-light">
                <FormattedMessage id="value.text" defaultMessage="value" />
              </span>
            </Col>
            <Col span={3}>
              <span className="text-muted-light">
                <FormattedMessage
                  id="multiplier.text"
                  defaultMessage="Multiplier"
                />
              </span>
            </Col>
            <Col span={4}>
              <span className="text-muted-light">
                <FormattedMessage
                  id="requirement.text"
                  defaultMessage="Requirement"
                />
              </span>
            </Col>
            <Col span={4}>
              <span className="text-muted-light">
                <FormattedMessage
                  id="T/O-balance.text"
                  defaultMessage="T/O Balance"
                />
              </span>
            </Col>
          </Row>

          {balanceUnitsData
            .filter(
              (balanceUnit) => balanceUnit.turnoverRequirementBalance !== 0
            )
            .map((balanceUnit, idx) => (
              <React.Fragment key={balanceUnit.id}>
                <Row>
                  <Col span={3} className="text-truncate pr-1">
                    <Tooltip
                      title={startCase(balanceUnit.source.toLowerCase())}
                    >
                      <ALink
                        className="fs-12"
                        onClick={() =>
                          openBsvTab({
                            filter: {
                              transactionType: {
                                in: [balanceUnit.source.toUpperCase()],
                              },
                            },
                          })
                        }
                      >
                        {startCase(balanceUnit.source.toLowerCase())}
                      </ALink>
                    </Tooltip>
                  </Col>
                  <Col span={6}>
                    <span className="fs-12">
                      {formatDate(balanceUnit.timestamp, DATE_TIME_FORMAT)}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span className="fs-12">
                      {addCurrency(member.currency, balanceUnit.amount)}
                    </span>
                  </Col>
                  <Col span={3}>
                    <span className="fs-12">
                      {balanceUnit.turnoverRequirementMultiplier}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span className="fs-12">
                      {addCurrency(
                        member.currency,
                        balanceUnit.turnoverRequirement
                      )}
                    </span>
                  </Col>
                  <Col span={4}>
                    <span className="fs-12">
                      {addCurrency(
                        member.currency,
                        balanceUnit.turnoverRequirementBalance
                      )}
                    </span>
                  </Col>

                  {balanceUnit.manualAdjustment && (
                    <Tooltip
                      arrowPointAtCenter
                      title={translate(messages.MANUAL_ADJUSTMENT)}
                    >
                      <CheckCircleOutlined
                        className="position-absolute"
                        style={{ right: '13px', top: '3px', fontSize: '15px' }}
                      />
                    </Tooltip>
                  )}
                </Row>
                {idx !== balanceUnitsData.length - 1 ? (
                  <Divider style={{ marginTop: '7px', marginBottom: '7px' }} />
                ) : (
                  <div style={{ marginTop: '7px', marginBottom: '7px' }} />
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
      <Divider className="m-0" />
      <div className="d-flex justify-content-center p-1">
        <ALink onClick={() => openBsvTab()}>
          <FormattedMessage
            id="more-details.text"
            defaultMessage="More Details"
          />
        </ALink>
      </div>
    </div>
  ) : (
    <Empty />
  );
};

interface IBalanceUnit {
  id: string;
  amount: number;
  expireAt: number;
  originalAmount: number;
  source: string;
  turnoverRequirementBalance: number;
  scope: {
    game: string;
    gameType: string;
    vendor: string;
    channel: string;
  };
  timestamp: number;
  turnoverRequirement: number;
  turnoverRequirementMultiplier: number;
  manualAdjustment: boolean;
}
