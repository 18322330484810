import gql from 'graphql-tag';

export const MESSAGES = gql`
  query Messages($first: Int, $after: Binary, $filter: MessagesFilterInput) {
    messages(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;
