import React from 'react';
import { Select } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';

export const AWARD_TYPES = [
  'UPGRADE',
  'DAILY',
  'WEEKLY',
  'MONTHLY',
  'ANNUAL',
  'BIRTHDAY',
  'OTHER',
];

type Props = {
  value: string;
  onChange: (e: string) => void;
};

export const VipAwardTypeSelect = ({ value, onChange }: Props) => {
  const translate = useTranslate();

  return (
    <Select value={value} onChange={onChange}>
      {AWARD_TYPES.map((type) => (
        <Select.Option value={type}>{translate(messages[type])}</Select.Option>
      ))}
    </Select>
  );
};
