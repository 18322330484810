import React from 'react';
import { Button, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';

export const MoreDetailsTransaction = ({
  valueCount,
  onLoadMore,
}: IMoreDetailsTransaction) =>
  valueCount > 0 ? (
    <div>
      <Divider className="m-0" />
      <div className="text-center">
        <Button type="link" onClick={onLoadMore} className="fs-12">
          <FormattedMessage
            id="more-details.text"
            defaultMessage="More Details"
          />
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );

interface IMoreDetailsTransaction {
  valueCount: number;
  onLoadMore: (e: any) => void;
}
