import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Table, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledTableContainer } from '../../styles';
import { getTierRequirement } from '../../../../../../../../constants';
import { getColumns } from '../../columns';

type Props = {
  formProps: Record<string, any>;
  defaultLevelValue: any;
  tierRequirementLookup: any;
};

const TierTable = ({
  formProps,
  defaultLevelValue,
  tierRequirementLookup,
}: Props) => {
  const { values, setFieldValue } = formProps;
  const intl = useIntl();

  return (
    <StyledTableContainer>
      <div className="button-group py-6">
        <Button
          type="primary"
          size="large"
          onClick={() => {
            const { levels } = values;
            setFieldValue('levels', []);
            const { forActiveMembers, forNetProfit } = getTierRequirement(
              tierRequirementLookup
            );

            const newLevels = levels.map(
              (level: Record<string, any>, index: number) => {
                if (index === levels.length - 1) {
                  return {
                    ...level,
                    ...(forActiveMembers && {
                      maximumActiveMembers:
                        +level.minimumActiveMembersCount + 1,
                    }),
                    ...(forNetProfit && {
                      maximumNetProfit: +level.minimumNetProfit + 1,
                    }),
                  };
                }
                return level;
              }
            );

            setFieldValue('levels', [
              ...newLevels,
              {
                ...defaultLevelValue,
                ...(!!newLevels.length &&
                  forActiveMembers && {
                    minimumActiveMembersCount:
                      newLevels[newLevels.length - 1].maximumActiveMembers,
                  }),
                ...(!!newLevels.length &&
                  forNetProfit && {
                    minimumNetProfit:
                      newLevels[newLevels.length - 1].maximumNetProfit,
                  }),
              },
            ]);
          }}
        >
          <PlusOutlined />
          <FormattedMessage id="add-tier.text" defaultMessage="Add Tier" />
        </Button>
      </div>
      <Table
        showHeader={false}
        columns={getColumns(values, intl)}
        size="middle"
        dataSource={values.levels}
        pagination={false}
        rowKey={(r) => r.key}
      />
    </StyledTableContainer>
  );
};

export default TierTable;
