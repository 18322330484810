import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import React, { useState } from 'react';
import { Member } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import ForceWithdrawalPasswordChangeForm from './components/ForceWithdrawalPasswordChangeForm';
import messages from './messages';

type Props = {
  member: Member;
  refetchParams: Record<string, any>;
  onCreated: () => void;
  onClose: () => void;
};

const ForceWithdrawalPasswordChange = ({
  member,
  refetchParams,
  onCreated,
  onClose,
}: Props) => {
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const [isLoading, setLoading] = useState(false);
  const translate = useTranslate();
  const handleSubmit = async (e: Record<string, any>) => {
    setLoading(true);
    try {
      const input = { ...e };
      updateMember({
        variables: {
          id: member.id,
          input: {
            forceChangeWithdrawalPassword: input.forceChangeWithdrawalPassword,
          },
        },
        refetchQueries: [
          {
            query: GET_MEMBERS,
            variables: { ...refetchParams },
          },
        ],
      });
      message.success({
        content: (
          <span data-testid="success">
            {translate(messages['member-password-change.success-message.text'])}
          </span>
        ),
      });
      setLoading(false);
      onCreated();
    } finally {
      setLoading(false);
    }
  };
  return (
    <ForceWithdrawalPasswordChangeForm
      initialValues={{
        forceChangeWithdrawalPassword: false,
      }}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  );
};

export default ForceWithdrawalPasswordChange;
