import React from 'react';
import { Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import { Icon } from '@ant-design/compatible';
import { Button, Spin } from 'antd';
import useWindowWidth from 'pages/components/common/hooks/useWindowWidth';
import { useApolloClient } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';

interface PrefetchQuery {
  query: DocumentNode;
  variables: Record<string, any>;
}

type Props = {
  onNext: () => void;
  onPrev: () => void;
  disableNext: boolean;
  disablePrev: boolean;
  label: string;
  prefetchQuery?: PrefetchQuery;
  loading?: boolean;
  scroll?: any;
};

const NextPrev: React.FC<Props> = ({
  onNext,
  disableNext,
  onPrev,
  disablePrev,
  label,
  prefetchQuery,
  loading,
}) => {
  const width = useWindowWidth();
  const client = useApolloClient();

  const [prevEndCursor, setPrevEndCursor] = React.useState('');

  const { after } = prefetchQuery?.variables ?? {};

  const handlePrev = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onPrev();
  };
  const handleNext = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onNext();
  };

  const btnSize = width <= 1024 ? 'small' : 'middle';

  const onFocus = React.useCallback(() => {
    if (typeof prefetchQuery === 'object' && prevEndCursor !== after) {
      client
        .query({
          ...prefetchQuery,
        })
        .then(() => setPrevEndCursor(after));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  return (
    <Spin spinning={loading}>
      <div className="styled-center" style={{ fontSize: '12px' }}>
        <Button size={btnSize} disabled={disablePrev}>
          <Link
            data-testid="prevEntries"
            onMouseOverCapture={onFocus}
            to="#section"
            onClick={handlePrev}
          >
            <Icon type="left" />
          </Link>
        </Button>
        <div>
          <div className="py-1 px-2">{label}</div>
        </div>
        <Button disabled={disableNext} size={btnSize}>
          <Link
            data-testid="nextEntries"
            onMouseOverCapture={onFocus}
            to="#section"
            onClick={handleNext}
          >
            <RightOutlined />
          </Link>
        </Button>
      </div>
    </Spin>
  );
};

NextPrev.defaultProps = {
  loading: false,
};

export default NextPrev;
