import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'updated-successfully.text': {
    id: 'updated-successfully.text',
    defaultMessage: 'Updated successfully',
  },
});

export default messages;
