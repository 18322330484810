import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { StyledSelect } from 'styles';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { AFFLIATE_PROGRAMME_STATUSES } from './utils';

const { Option } = Select;

type StatusType = {
  onChange: (e: string[] | string) => void;
  value: { in: string };
};

const Status = (props: StatusType) => {
  const { onChange, value } = props;
  const translate = useTranslate();

  return (
    <FilterItem label={translate(messages.STATUS)} onClear={() => onChange([])}>
      <StyledSelect
        allowClear
        mode="multiple"
        placeholder={
          <FormattedMessage
            id="affliates.enter-status.text"
            defaultMessage="Enter Status"
          />
        }
        value={value ? value.in : []}
        onChange={onChange}
        showSearch
      >
        {AFFLIATE_PROGRAMME_STATUSES.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
    </FilterItem>
  );
};

export default Status;
