import gql from 'graphql-tag';

export const DEPOSIT_PROVIDER = gql`
  query DepositProvider($id: ID!) {
    depositProvider(id: $id) {
      name
      __typename
      ... on DiorPayDepositProvider {
        paymentChannels {
          bank
          alipay
          wechat
          unionpay
        }
      }
    }
  }
`;

export default {};
