import Layout from 'components/Layout';
import { useFilterValues } from 'contexts/Filters';
import {
  GET_IP_SUMMARY_REPORTS,
  GET_IP_SUMMARY_REPORT_IDS,
} from 'graphql/queries/ipAddressSummary.query';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { IpAddressSummaryReportItemsConnection } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import { createPartialUtil } from 'utils/partialUtils';
import removeNull from 'utils/removeNull';
import HeaderLeft from './components/HeaderLeft';
import HeaderRight from './components/HeaderRight';
import IPAddressTable from './components/IPAddressTable';
import SideBar from './components/SideBar';

const IPAddressSumarryScreen = ({
  refreshPage,
}: {
  refreshPage: () => void;
}) => {
  const { filters } = useFilterValues() as any;
  const [page, setPage] = useState({
    first: 10,
    after: undefined,
    savedCursor: [''],
    currentPage: 0,
  }) as any;

  const usePartialFilterUtil = createPartialUtil('id', removeNull);
  const { locale } = useIntl();

  const idQueries = {
    query: '',
    sourceFiltFields: [],
    targetStringFields: [],
    targetFilterFields: [],
  };

  const { data, loading, finalQueryFilter } = usePartialFilterUtil(
    GET_IP_SUMMARY_REPORT_IDS,
    GET_IP_SUMMARY_REPORTS,
    'ipAddressSummaryReport.edges',
    filters,
    page,
    ['ipAddress'],
    idQueries,
    locale.toUpperCase()
  );

  const ipAddressSummaryReport = coercedGet(data, 'ipAddressSummaryReport', {});
  const {
    totalCount = 0,
    pageInfo,
  } = ipAddressSummaryReport as IpAddressSummaryReportItemsConnection;

  const dataSource = coercedGet(ipAddressSummaryReport, 'edges', []);
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleNext = () => {
    const { savedCursor, currentPage } = page;

    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage] || undefined;
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  return (
    <Layout.Container>
      <Layout.Header
        leftNode={<HeaderLeft />}
        rightNode={
          <HeaderRight
            refreshPage={refreshPage}
            finalQueryFilter={finalQueryFilter}
          />
        }
      />
      <Layout.Content
        sideBar={<SideBar />}
        footer={
          <Layout.Footer
            leftNode={<div />}
            loading={loading}
            onNext={handleNext}
            onPrev={handlePrev}
            page={page}
            resultsCount={totalCount}
            totalPage={totalPage}
            setPage={setPage}
            pageInfo={{
              endCursor: '',
              hasNextPage: false,
            }}
          />
        }
      >
        <IPAddressTable dataSource={dataSource} loading={loading} />
      </Layout.Content>
    </Layout.Container>
  );
};

export default IPAddressSumarryScreen;
