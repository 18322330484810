import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { truncateProgramName } from 'utils/truncateProgramName';
import { IMemberLevel } from 'pages/components/SystemMessage/ManualMessage/interfaces';
import messages from 'messages';
import { withErrorHandler } from 'components/ErrorHandler';
import { customFormatMessage } from 'utils/customFormatMessage';
import { MEMBER_LOYALTY_LEVELS } from './queries';
import { StyledOption, StyledSelect } from './styles';

interface ILevelObject {
  id: string;
  shortName: string | null;
  fullName: string;
  color: string;
  programmeId: string;
  programmeName: string;
  loyaltyName: string;
  rank: number;
}

interface IPrograms {
  name: string;
  levels: IMemberLevel[];
}

interface ILevels extends IMemberLevel {
  programme: {
    name: string;
  };
}

interface ISelected {
  activeItem: any[];
  stringValue: string[];
}

interface Props {
  memberLevel: any;
  onChange: <T extends {}>(e: T[]) => void;
  disabled: boolean;
  mode?: undefined | 'multiple' | 'tags';
  allProgramme?: boolean;
}

const createLevelObject = (
  programme: any,
  level: IMemberLevel
): ILevelObject => {
  const { id, name, color, rank } = level;
  const fullName = `${programme.name || ''} - ${name || ''}`;
  let { text: shortName } = truncateProgramName(programme.name);
  shortName += ` - ${name}`;

  return {
    id,
    shortName,
    fullName,
    color,
    programmeId: programme.id,
    programmeName: programme.name,
    loyaltyName: name,
    rank,
  };
};

const VIPSelect: React.FC<Props> = ({
  onChange,
  memberLevel,
  disabled,
  mode,
  allProgramme = false,
}) => {
  const value: any = [createLevelObject(memberLevel.programme, memberLevel)];
  const [opts, setOpts] = useState<ILevelObject[]>();
  const [input, setInput] = useState<string>('');
  const [selected, setSelected] = useState<ISelected>({
    activeItem: [],
    stringValue: [],
  });

  const debouncedInput = useDebounce(input, 500);
  const { formatMessage } = useIntl();

  const [loadVIP, { called, loading, data = {} }] = useLazyQuery<any>(
    MEMBER_LOYALTY_LEVELS,
    {
      variables: {
        input: debouncedInput,
        ...(!allProgramme && {
          status: {
            eq: 'ACTIVE',
          },
        }),
      },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        const levelEdges = get(data, 'memberLoyaltyLevels.edges', []) || [];
        const programEdges =
          get(data, 'memberLoyaltyProgrammes.edges', []) || [];

        const programmes: ILevelObject[] = (programEdges || [])
          .map(({ node }: { node: IPrograms }) => {
            const { levels } = node;
            return levels.map(
              (level: IMemberLevel): ILevelObject =>
                createLevelObject(node, level)
            );
          })
          .reduce((acc: any, cur: any) => [...acc, ...cur], []);

        const levels: ILevelObject[] = (levelEdges || []).map(
          ({ node }: { node: ILevels }): ILevelObject =>
            createLevelObject(node.programme, node)
        );

        const combination: ILevelObject[] = [...programmes, ...levels].filter(
          (levelA, index, self) =>
            index === self.findIndex((levelB) => levelA.id === levelB.id)
        );

        setOpts(combination);
      },
    }
  );

  const handleSetActive = (e: any) => {
    onChange(JSON.parse(e));
  };

  if (!called) loadVIP();

  useEffect(() => {
    loadVIP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  useEffect(() => {
    if (!value[0].id) {
      setSelected({
        activeItem: [],
        stringValue: [],
      });
    } else {
      setSelected({
        activeItem:
          value.map((item: ILevelObject, key: number) => (
            <StyledOption
              key={key}
              title={item.id}
              value={JSON.stringify(item)}
              style={{ display: 'none' }}
            >
              {item.shortName}
            </StyledOption>
          )) || [],
        stringValue:
          value.map((item: ILevelObject) => JSON.stringify(item)) || [],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberLevel]);

  // eslint-disable-next-line consistent-return
  const setDefaultValue = (): any => {
    if (opts && opts.length > 0 && selected.stringValue.length === 0) {
      const defaultVIP = opts.find((opt) => opt.rank === 0);
      if (!defaultVIP) {
        return selected.stringValue;
      }

      setSelected({
        activeItem:
          [defaultVIP!].map((item: ILevelObject, key: number) => (
            <StyledOption
              key={key}
              title={item.id}
              value={JSON.stringify(item)}
              style={{ display: 'none' }}
            >
              {item.shortName}
            </StyledOption>
          )) || [],
        stringValue:
          [defaultVIP!].map((item: ILevelObject) => JSON.stringify(item)) || [],
      });
      onChange(defaultVIP as any);
    }
    return selected.stringValue;
  };
  const translate = (messageVal: any, options = null) =>
    customFormatMessage(formatMessage, messageVal, options);
  return (
    <>
      <StyledSelect
        data-testid="vip-select"
        mode={mode || undefined}
        onSearch={(e) => setInput(e)}
        onChange={handleSetActive}
        loading={loading}
        disabled={disabled}
        placeholder={translate(messages['enter-vip-level.text'])}
        filterOption={false}
        onBlur={() => setInput('')}
        showSearch
        value={setDefaultValue()}
        activeItems={value || []}
      >
        {opts?.map((item, key) => (
          <StyledOption
            key={key}
            value={JSON.stringify(item)}
            color={item.color}
            title={item.id}
          >
            {item.shortName}
          </StyledOption>
        ))}

        {selected.activeItem}
      </StyledSelect>
    </>
  );
};

export default withErrorHandler(VIPSelect);
