/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataLoader from 'dataloader';
import { useApolloClient } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import get from 'utils/coercedGet';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import TableCellSpinner from 'components/TableCellSpinner';

export interface LoaderProps {
  id: string;
  target: string;
  render: Function;
}

const Render: React.FC<LoaderProps & {
  dataloader: DataLoader<string, any, string>;
  setIsComplete: (val: boolean) => void;
  keys: {};
}> = React.memo(({ setIsComplete, dataloader, id, target, render, keys }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [data, setData] = useState({});
  const refetch = keys?.[id];

  useEffect(() => {
    (async () => {
      setIsComplete(false);
      setIsLoading(true);
      keys[id] = keys?.[id] || 0;

      try {
        const values = await dataloader.load(id);
        setData(values);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
        setIsComplete(true);
      }
    })();
  }, [setIsComplete, dataloader, id, keys, refetch]);

  if (isLoading) return <TableCellSpinner />;
  if (error) return <TableErrorCell errorMessage={error} />;

  return render(get(data, target, null)); // this is the node data
});

export const useLoader = (query: string) => {
  const client = useApolloClient();
  const [, setReload] = useState({});
  const [isComplete, setIsComplete] = useState(false);
  const keysRef = useRef<{ [x: string]: number }>({});
  const dataloader = useMemo(
    () =>
      new DataLoader(
        async (keys: readonly string[]) => {
          dataloader.clearAll();
          let customQuery: string | DocumentNode = '';

          keys.forEach((key: string) => {
            const queryReplace = query.replace(/\$id/, `"${key}"`);
            customQuery += `${key}:${queryReplace}`;
          });
          const result = client
            .query({
              query: gql(`{${customQuery}}`),
              fetchPolicy: 'network-only',
            })
            .then((res: any) =>
              keys.map((key: string) => get(res, `data[${key}]`, ''))
            )
            .catch((error) => {
              throw new Error(`Encountered an error: ${error}.`);
            });

          return result;
        },
        {
          batchScheduleFn: (callback) => setTimeout(callback, 100),
          maxBatchSize: 5,
          cache: false,
        }
      ),
    [client, query]
  );

  const refresh = (id: string | string[]) => {
    (Array.isArray(id) ? id : [id]).forEach((item) => {
      keysRef.current[item] += 1;
    });

    setReload({});
  };

  return [
    useCallback(
      ({ id, target, render }: LoaderProps) => (
        <Render
          dataloader={dataloader}
          id={id}
          target={target}
          render={render}
          keys={keysRef.current}
          setIsComplete={setIsComplete}
        />
      ),
      [dataloader, setIsComplete]
    ),
    refresh,
    () => isComplete,
  ];
};

type FetchPolicyType =
  | 'cache-first'
  | 'network-only'
  | 'cache-only'
  | 'no-cache'
  | 'standby'
  | 'cache-and-network'
  | undefined;

export const useCreateLoader = (
  query: string,
  fetchPolicy: FetchPolicyType = 'network-only'
) => {
  const client = useApolloClient();
  const [, setReload] = useState({});
  const keysRef = useRef<{ [x: string]: number }>({});
  const dataloader = useMemo(
    () =>
      new DataLoader(
        async (keys: readonly string[]) => {
          dataloader.clearAll();
          let customQuery: string | DocumentNode = '';

          keys.forEach((key: string) => {
            const queryReplace = query.replace(/\$id/, `"${key}"`);
            customQuery += `${key}:${queryReplace}`;
          });

          const foo = client
            .watchQuery({
              query: gql(`{${customQuery}}`),
              fetchPolicy,
            })
            .result()
            .then((res: any) =>
              keys.map((key: string) => get(res, `data[${key}]`, ''))
            )
            .catch((error) => {
              throw new Error(`Encountered an error: ${error}.`);
            });
          return foo;
        },
        {
          batchScheduleFn: (callback) => setTimeout(callback, 100),
          cache: false,
          maxBatchSize: 5,
        }
      ),
    [client, fetchPolicy, query]
  );

  const refresh = (id: string | string[]) => {
    (Array.isArray(id) ? id : [id]).forEach((item) => {
      keysRef.current[item] += 1;
    });

    setReload({});
  };
  const [isComplete, setIsComplete] = useState(false);
  const keys = keysRef.current;

  const useDataLoader = (id: string) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [watchValue, setWatchValue] = useState(false);

    const refetch = React.useCallback(
      () => setWatchValue((prev: boolean) => !prev),
      []
    );

    useEffect(() => {
      const loadData = async () => {
        try {
          setIsComplete(false);
          setLoading(true);
          keys[id] = keys?.[id] || 0;
          const loadedData = await dataloader.load(id);
          setData(loadedData);
          setLoading(false);
          setIsComplete(true);
        } catch (err) {
          setError(err);
        }
      };
      loadData();
    }, [watchValue, id]);

    const isLoading = loading || data === null;

    return { data, error, loading: isLoading, refetch };
  };

  return [useDataLoader, refresh, isComplete];
};
