import { useQuery } from '@apollo/react-hooks';
import {
  Badge,
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Row,
  Spin,
  Typography,
} from 'antd';
import { withErrorHandler } from 'components/ErrorHandler';
import daysOfWeek from 'constants/daysOfWeek';
import { AFFILIATE_PROGRAMME } from 'graphql/queries/affiliateAgent.query';
import { useConfig } from 'hooks/useConfig';
import messages from 'messages';
import moment from 'moment';
import ms from 'ms';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import statusBadge from 'utils/statusBadge';
import useTranslate from 'utils/useTranslate';
import { getTierRequirement } from '../../constants';
import { AffiliateTypes, useAffiliateState } from '../../context';
import CostVendorSettings from './components/CostVendorSettings';
import { SettlementPeriod } from './constants';
import localMessages from './messages';

const StyledCenter = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDiv = styled.div`
  padding-bottom: 78px;
`;

const StyledTitle = styled.div`
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
`;

const SettlementPeriodDetails = ({
  summary,
}: {
  summary: Record<string, any>;
}) => {
  switch (summary.settlementPeriod) {
    case SettlementPeriod.WEEKLY:
      return (
        <Row>
          <Col span={14}>
            <Typography.Paragraph>
              <FormattedMessage
                id="day-of-week.text"
                defaultMessage="Day of Week"
              />
            </Typography.Paragraph>
          </Col>
          <Col span={10}>
            <Typography.Text strong>
              {`Every ${
                daysOfWeek.find(
                  (day) => day.value === summary.settlementDayOfWeek
                )!.label
              }`}
            </Typography.Text>
          </Col>
        </Row>
      );
    case SettlementPeriod.BIWEEKLY:
      return (
        <>
          <Row>
            <Col span={14}>
              <Typography.Paragraph>
                <FormattedMessage
                  id="which-week.text"
                  defaultMessage="Which Week"
                />
              </Typography.Paragraph>
            </Col>
            <Col span={10}>
              <Typography.Text strong>
                {summary.whichWeek || ' '}
              </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={14}>
              <Typography.Paragraph>
                <FormattedMessage
                  id="day-of-week.text"
                  defaultMessage="Day of Week"
                />
              </Typography.Paragraph>
            </Col>
            <Col span={10}>
              <Typography.Text strong>
                {`Every ${
                  daysOfWeek.find(
                    (day) => day.value === summary.settlementDayOfWeek
                  )!.label
                }`}
              </Typography.Text>
            </Col>
          </Row>
        </>
      );
    case SettlementPeriod.MONTHLY:
      return (
        <Row>
          <Col span={14}>
            <Typography.Paragraph>
              <FormattedMessage
                id="date-of-month.text"
                defaultMessage="Date of Month"
              />
            </Typography.Paragraph>
          </Col>
          <Col span={10}>
            <Typography.Text strong>
              {`Every ${moment()
                .localeData()
                .ordinal(summary.settlementDayOfMonth)}`}
            </Typography.Text>
          </Col>
        </Row>
      );
    default:
      return <></>;
  }
};

function AffiliateDetails({
  showForm,
}: {
  showForm: (data: Record<string, any>) => void;
}) {
  const translate = useTranslate();
  const [{ recordSummary }, dispatch] = useAffiliateState() as any;
  const { currency } = useConfig();
  const { loading, data } = useQuery(AFFILIATE_PROGRAMME, {
    variables: {
      id: recordSummary,
    },
    skip: !recordSummary,
  });

  const summary = coercedGet(data, 'affiliateProgramme', {});

  const dateNow = new Date();
  const dateNowString = dateNow
    .toISOString()
    .split('T')
    .shift();
  const time = moment(`${dateNowString} ${summary.settlementTime}`).format(
    'HH:mm'
  );

  const { levelsRequirement } = summary;

  const { forActiveMembers, forNetProfit } = getTierRequirement(
    levelsRequirement
  );

  if (loading) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  return (
    <Root className="bg-white">
      <Layout.Content className="p-4">
        <Breadcrumb className="mb-3">
          <Breadcrumb.Item>
            <FormattedMessage
              id="agent-affiliate.text"
              defaultMessage="Agent Affiliate"
            />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <ALink
              onClick={() =>
                dispatch({
                  type: AffiliateTypes.SET_ACTIVE_SCREEN,
                  payload: AffiliateTypes.AGENT_AFFILIATE_LIST,
                })
              }
            >
              <FormattedMessage
                id="programmes-listing.text"
                defaultMessage="Programmes Listing"
              />
            </ALink>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={32}>
          <Col span={12} className="gutter-row">
            <div className="gutter-box">
              <Typography.Title level={2}>{summary.name}</Typography.Title>
              <Row className="mt-3">
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Typography.Text>
                        <FormattedMessage
                          id="default-affiliate-programme.text"
                          defaultMessage="Default Affiliate Programme"
                        />
                      </Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Typography.Text className="ml-3" strong>
                        <span>
                          {translate(
                            messages[
                              `${summary.default ? 'on.text' : 'off.text'}`
                            ]
                          )}
                        </span>
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Typography.Text className="my-auto">
                        <FormattedMessage
                          id="negative-lost-carry.text"
                          defaultMessage="Negative Lost Carry"
                        />
                      </Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Typography.Text className="ml-3" strong>
                        <span>
                          {translate(
                            messages[
                              `${
                                summary.negativeCarryOverEnabled
                                  ? 'on.text'
                                  : 'off.text'
                              }`
                            ]
                          )}
                        </span>
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-2 mb-4">
                <Col span={12}>
                  <Row>
                    <Col span={14}>
                      <Typography.Text>
                        <FormattedMessage
                          id="category.text"
                          defaultMessage="Category"
                        />
                      </Typography.Text>
                    </Col>
                    <Col span={10}>
                      <Typography.Text strong>
                        <FormattedMessage
                          id="trial.text"
                          defaultMessage="Trial"
                        />
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Typography.Text>
                        <FormattedMessage
                          id="status.text"
                          defaultMessage="Status"
                        />
                      </Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Typography.Text className="ml-3">
                        <Badge
                          status={statusBadge(summary.status)}
                          text={translate(
                            messages[`${summary.status.toLowerCase()}.text`]
                          )}
                        />
                      </Typography.Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Card
                title={translate(
                  localMessages[
                    'agent-affiliate.affiliate-cost-sharing-settings.text'
                  ]
                )}
              >
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="deposit-transaction-cost.text"
                        defaultMessage="Deposit Transaction Cost"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {`${summary.depositTransactionCost * 100}%`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="3rd-party-payment-gateway-deposit-transaction-cost.text"
                        defaultMessage="3rd Party Payment Gateway
                          Deposit Transaction Cost"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {`${summary.thirdPartyDepositTransactionCost * 100}%`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="withdrawal-transaction-cost.text"
                        defaultMessage="Withdrawal Transaction Cost"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {`${summary.withdrawalTransactionCost * 100}%`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="3rd-party-payment-gateway-withdrawal-transaction-cost.text"
                        defaultMessage="3rd Party Payment Gateway
                          Withdrawal Transaction Cost"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {`${summary.thirdPartyWithdrawalTransactionCost * 100}%`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="promotion-cost.text"
                        defaultMessage="Promotion Cost"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {`${summary.promoCost * 100}%`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="rebate-cost.text"
                        defaultMessage="Rebate Cost"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {`${summary.rebateCost * 100}%`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="interest-account-cost.text"
                        defaultMessage="Interest Account Cost"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {`${summary.interestAccountCost * 100}%`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col span={24}>
                    <Typography.Paragraph strong>
                      <FormattedMessage
                        id="requirement-for-active-member.text"
                        defaultMessage="Requirement for Active Member"
                      />
                    </Typography.Paragraph>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="effective-bet.text"
                        defaultMessage="Effective Bet"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      &#0165;{` ${summary.minimumEffectiveBetRequirement}`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="deposit.text"
                        defaultMessage="Deposit"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      &#0165;{` ${summary.minimumDepositRequirement}`}
                    </Typography.Text>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
          <Col span={12} className="gutter-row mt-4">
            <div className="gutter-box mt-4">
              <StyledDiv>
                <Button
                  type="primary"
                  disabled={summary.status === 'ACTIVE'}
                  onClick={() => showForm(summary)}
                >
                  <FormattedMessage
                    id="edit-details.text"
                    defaultMessage="Edit Details"
                  />
                </Button>
              </StyledDiv>
              <StyledTitle className="mb-4">
                <FormattedMessage
                  id="programme-cost-settings.text"
                  defaultMessage="Programme Cost Settings"
                />
              </StyledTitle>

              <Row className="mb-2">
                <Col span={14}>
                  <Typography.Paragraph>
                    <FormattedMessage
                      id="members.text"
                      defaultMessage="Members"
                    />
                  </Typography.Paragraph>
                </Col>
                <Col span={10}>
                  <Typography.Paragraph className="text-right">
                    {coercedGet(summary, 'membersCount', 0)}
                  </Typography.Paragraph>
                </Col>
              </Row>
              <Typography.Paragraph className="mb-1">
                <FormattedMessage
                  id="programme-description.text"
                  defaultMessage="Programme Description"
                />
              </Typography.Paragraph>
              <Typography.Paragraph strong>
                {summary.description}
              </Typography.Paragraph>
              <Divider className="mt-4" />
            </div>
          </Col>
        </Row>
        <Row gutter={32} className="mt-4">
          <Col span={12} className="gutter-row">
            <div className="gutter-box">
              <Card title={translate(localMessages['settlement-period.text'])}>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="payout-method.text"
                        defaultMessage="Payout Method"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {translate(
                        localMessages[
                          summary.automaticPayoutClaiming
                            ? 'automatic-payout.text'
                            : 'manually-payout.text'
                        ]
                      )}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="minimum-payout-accumulation.text"
                        defaultMessage="Minimum Payout Accumulation"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {translate(
                        messages[
                          summary.minimumPayoutAccumulationEnabled
                            ? 'on.text'
                            : 'off.text'
                        ]
                      )}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="turnover-requirement.text"
                        defaultMessage="Turnover Requirement"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {`${summary.turnoverRequirementMultiplier}x`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <Typography.Paragraph>
                      <FormattedMessage
                        id="period.text"
                        defaultMessage="Period"
                      />
                    </Typography.Paragraph>
                  </Col>
                  <Col span={10}>
                    <Typography.Text strong>
                      {translate(
                        messages[
                          `${summary.settlementPeriod.toLowerCase()}.text`
                        ]
                      )}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col span={24}>
                    <Typography.Paragraph strong>
                      {translate(
                        messages[
                          `${summary.settlementPeriod.toLowerCase()}.text`
                        ]
                      )}
                    </Typography.Paragraph>
                  </Col>
                  <SettlementPeriodDetails summary={summary} />
                  <Row>
                    <Col span={14}>
                      <Typography.Paragraph>
                        <FormattedMessage
                          id="time-of-day.text"
                          defaultMessage="Time of Day"
                        />
                      </Typography.Paragraph>
                    </Col>
                    <Col span={10}>
                      <Typography.Text strong>{time}</Typography.Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <Typography.Paragraph>
                        <FormattedMessage
                          id="claim-offset-time.text"
                          defaultMessage="Claim Offset Time"
                        />
                      </Typography.Paragraph>
                    </Col>
                    <Col span={10}>
                      <Typography.Text strong>
                        {summary.payoutClaimOffsetDuration !== '0ms'
                          ? ms(ms(summary.payoutClaimOffsetDuration) as any, {
                              long: true,
                            })
                          : ''}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={14}>
                      <Typography.Paragraph>
                        <FormattedMessage
                          id="claim-expiry-time.text"
                          defaultMessage="Claim Expiry Time"
                        />
                      </Typography.Paragraph>
                    </Col>
                    <Col span={10}>
                      <Typography.Text strong>
                        {summary.payoutClaimExpiryDuration !== '0ms'
                          ? ms(ms(summary.payoutClaimExpiryDuration) as any, {
                              long: true,
                            })
                          : ''}
                      </Typography.Text>
                    </Col>
                  </Row>
                  <Row className="d-none">
                    <Col span={14}>
                      <Typography.Paragraph>
                        <FormattedMessage
                          id="award-expiry-time.text"
                          defaultMessage="Award Expiry Time"
                        />
                      </Typography.Paragraph>
                    </Col>
                    <Col span={10}>
                      <Typography.Text strong>
                        {summary.payoutExpiryDuration !== '0ms'
                          ? ms(ms(summary.payoutExpiryDuration) as any, {
                              long: true,
                            })
                          : ''}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
        <Row gutter={32} className="mt-4">
          <Col span={24} className="gutter-row">
            <div className="gutter-box">
              <Card
                title={translate(localMessages['cost-vendor-settings.text'])}
              >
                <CostVendorSettings summary={summary} />
              </Card>
            </div>
          </Col>
        </Row>
        <Row gutter={32} className="mt-4">
          <Col span={24} className="gutter-row">
            <div className="gutter-box">
              <Card title={translate(localMessages['tier-requirement.text'])}>
                <>
                  {coercedGet(summary, 'levels', []).map(
                    (level: Record<string, any>, index: number) => (
                      <Row
                        gutter={16}
                        className={index % 2 === 0 ? 'mt-2' : ''}
                      >
                        <Col span={1} className="gutter-row text-center mr-2">
                          <Typography.Paragraph strong>
                            <FormattedMessage
                              id="tier.text"
                              defaultMessage="Tier"
                            />
                          </Typography.Paragraph>
                          <Typography.Paragraph strong>
                            {index + 1}
                          </Typography.Paragraph>
                        </Col>
                        <Col span={4} className="gutter-row">
                          <Typography.Paragraph strong>
                            <FormattedMessage
                              id="tier-name.text"
                              defaultMessage="Tier Name"
                            />
                          </Typography.Paragraph>
                          <Typography.Paragraph>
                            {level.name}
                          </Typography.Paragraph>
                        </Col>
                        {forActiveMembers && (
                          <>
                            <Col span={4} className="gutter-row">
                              <Typography.Paragraph strong>
                                <FormattedMessage
                                  id="min-active-members.text"
                                  defaultMessage="Min Active Members"
                                />
                              </Typography.Paragraph>
                              <Typography.Paragraph>
                                {level.minimumActiveMembersCount}
                              </Typography.Paragraph>
                            </Col>
                            <Col span={4} className="gutter-row">
                              <Typography.Paragraph strong>
                                <FormattedMessage
                                  id="max-active-members.text"
                                  defaultMessage="Max Active Members"
                                />
                              </Typography.Paragraph>
                              <Typography.Paragraph>
                                {index === summary.levels.length - 1
                                  ? '-'
                                  : coercedGet(
                                      summary,
                                      `levels[${index +
                                        1}].minimumActiveMembersCount`,
                                      0
                                    )}
                              </Typography.Paragraph>
                            </Col>
                          </>
                        )}
                        {forNetProfit && (
                          <>
                            <Col span={3} className="gutter-row">
                              <Typography.Paragraph strong>
                                <FormattedMessage
                                  id="min-net-profit.text"
                                  defaultMessage="Min Net Profit"
                                />
                              </Typography.Paragraph>
                              <Typography.Paragraph>
                                {currency?.symbol} {level.minimumNetProfit}
                              </Typography.Paragraph>
                            </Col>
                            <Col span={3} className="gutter-row">
                              <Typography.Paragraph strong>
                                <FormattedMessage
                                  id="max-net-profit.text"
                                  defaultMessage="Max Net Profit"
                                />
                              </Typography.Paragraph>
                              <Typography.Paragraph>
                                {currency?.symbol}{' '}
                                {index === summary.levels.length - 1
                                  ? '-'
                                  : coercedGet(
                                      summary,
                                      `levels[${index + 1}].minimumNetProfit`,
                                      0
                                    )}
                              </Typography.Paragraph>
                            </Col>
                          </>
                        )}
                        <Col span={3} className="gutter-row">
                          <Typography.Paragraph strong>
                            <FormattedMessage
                              id="commission.text"
                              defaultMessage="Commission"
                            />
                          </Typography.Paragraph>
                          <Typography.Paragraph>
                            {`${level.percentage * 100}%`}
                          </Typography.Paragraph>
                        </Col>
                      </Row>
                    )
                  )}
                </>
              </Card>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    </Root>
  );
}

const Root = styled.div`
  .ant-layout-content {
    max-width: 1024px;
    margin: 0 auto;
    font-size: 16px;
  }
  .ant-typography {
    margin-bottom: 0.5rem;
  }
`;

export default withErrorHandler(AffiliateDetails);
