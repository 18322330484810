import { PoweroffOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ChangePassword } from 'components/Navbar/components/ProfileDropdown/ChangePassword/ChangePassword';
import messages from 'messages';
import React, { useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import { useAccount } from 'store/accountState';
import { useLogout } from 'utils/useLogout';
import useTranslate from 'utils/useTranslate';
import { StyledProfileDropdown } from './styles';

const ProfileDropdown = () => {
  const { logout } = useLogout();

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [key, setKey] = useState(Math.random());
  const { account } = useAccount();

  const translate = useTranslate();

  return (
    <>
      <StyledProfileDropdown
        overlayClassName="topbar-menu"
        overlay={
          <Menu>
            <Menu.Item onClick={() => setOpenChangePassword(true)}>
              <SettingOutlined className="mr-2" style={{ fontSize: 14 }} />
              <span>{translate(messages.CHANGE_PASSWORD)}</span>
            </Menu.Item>

            <Menu.Item
              onClick={() =>
                logout({ infoMessage: 'Successfully logged out.' })
              }
            >
              <PoweroffOutlined className="mr-2" style={{ fontSize: 14 }} />
              <span>{translate(messages['logout.text'])}</span>
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
      >
        <ALink className="ant-dropdown-link" data-target="profile-username">
          {account?.account?.username}
        </ALink>
      </StyledProfileDropdown>

      <ChangePassword
        key={key}
        isOpen={openChangePassword}
        closeModal={() => {
          setOpenChangePassword(false);
          setTimeout(() => {
            setKey(Math.random());
          }, 1000);
        }}
      />
    </>
  );
};

export default ProfileDropdown;
