import { defineMessages } from 'react-intl';

const messages = defineMessages({
  CATEGORY_ICON: {
    id: 'CATEGORY_ICON',
    defaultMessage: 'Category Icon',
  },
  CATEGORY_BANNER_IMAGE: {
    id: 'CATEGORY_BANNER_IMAGE',
    defaultMessage: 'Category Banner Image',
  },
  CATEGORY_HERO_IMAGE: {
    id: 'CATEGORY_HERO_IMAGE',
    defaultMessage: 'Category Hero Image',
  },
  'category-icon.text': {
    id: 'category-icon.text',
    defaultMessage: 'Category Icon',
  },
  CATEGORY_DESKTOP_ICON: {
    id: 'CATEGORY_DESKTOP_ICON',
    defaultMessage: 'Category Desktop Icon',
  },
  SLOT: {
    id: 'slot.text',
    defaultMessage: 'Slot',
  },
  CASUAL: {
    id: 'casual.text',
    defaultMessage: 'Casual',
  },
  TABLE: {
    id: 'table.text',
    defaultMessage: 'Table',
  },
  LIVE: {
    id: 'live-casino.text',
    defaultMessage: 'Live Casino',
  },
  POKER: {
    id: 'poker.text',
    defaultMessage: 'Poker',
  },
  LIVE_DEALER: {
    id: 'live-dealer.text',
    defaultMessage: 'Live Dealer',
  },
  FISH: {
    id: 'fish.text',
    defaultMessage: 'Fish',
  },
  SPORTSBOOK: {
    id: 'sportsbook.text',
    defaultMessage: 'Sportsbook',
  },
  LOTTERY: {
    id: 'lottery.text',
    defaultMessage: 'Lottery',
  },
  SCRATCH: {
    id: 'scratch.text',
    defaultMessage: 'Scratch',
  },
  VIRTUAL: {
    id: 'virtual.text',
    defaultMessage: 'Virtual',
  },
  BLACKJACK: {
    id: 'blackjack.text',
    defaultMessage: 'Black Jack',
  },
  VIDEO_SLOTS: {
    id: 'video-slots.text',
    defaultMessage: 'Video Slots',
  },
  ROULETTE: {
    id: 'roulette.text',
    defaultMessage: 'Roulette',
  },
  VIDEO_POKER: {
    id: 'video-poker.text',
    defaultMessage: 'Video Poker',
  },
  LIVE_BACCARAT: {
    id: 'live-baccarat.text',
    defaultMessage: 'Live Baccarat',
  },
  LIVE_BLACKJACK: {
    id: 'live-blackjack.text',
    defaultMessage: 'Live Blackjack',
  },
  LIVE_ROULETTE: {
    id: 'live-roulette.text',
    defaultMessage: 'Live Roulette',
  },
  LIVE_POKER: {
    id: 'live-poker.text',
    defaultMessage: 'Live Poker',
  },
  LIVE_GAME_SHOWS: {
    id: 'live-game-shows.text',
    defaultMessage: 'Live Game Shows',
  },
  OTHER_TABLE_GAMES: {
    id: 'other-table-games.text',
    defaultMessage: 'Other Table Games',
  },
  LIVE_DRAGON_TIGER: {
    id: 'live-dragon-tiger.text',
    defaultMessage: 'Live Dragon Tiger',
  },
  BACCARAT: {
    id: 'baccarat.text',
    defaultMessage: 'Baccarat',
  },
  DICE: {
    id: 'dice.text',
    defaultMessage: 'Dice',
  },
  JACKPOT_SLOTS: {
    id: 'jackpot-slots.text',
    defaultMessage: 'Jackpot Slots',
  },
  OTHER_LIVE_GAMES: {
    id: 'other-live-games.text',
    defaultMessage: 'Other Live Games',
  },
  SCRATCH_CARDS: {
    id: 'scratch-cards.text',
    defaultMessage: 'Scratch Cards',
  },
  VIRTUAL_SPORTS: {
    id: 'virtual-sports.text',
    defaultMessage: 'Virtual Sports',
  },
  LIVE_DICE: {
    id: 'live-dice.text',
    defaultMessage: 'Live Dice',
  },
  BINGO: {
    id: 'bingo.text',
    defaultMessage: 'Bingo',
  },
  ARCADE_GAMES: {
    id: 'arcade-games.text',
    defaultMessage: 'Arcade Games',
  },
  DRAGON_TIGER: {
    id: 'dragon-tiger.text',
    defaultMessage: 'Dragon Tiger',
  },
  HILO: {
    id: 'hilo.text',
    defaultMessage: 'Hilo',
  },
  CRASH_GAMES: {
    id: 'crash-games.text',
    defaultMessage: 'Crash Games',
  },
  CRASH: {
    id: 'crash.text',
    defaultMessage: 'Crash',
  },
  EXISTING_ICON: {
    id: 'EXISTING_ICON',
    defaultMessage: 'Existing Icon',
  },
  EXISTING_BANNER: {
    id: 'EXISTING_BANNER',
    defaultMessage: 'Existing Banner',
  },
  EXISTING_HERO_IMAGE: {
    id: 'EXISTING_HERO_IMAGE',
    defaultMessage: 'Existing Hero Image',
  },
  EXISTING_DESKTOP_ICON: {
    id: 'EXISTING_DESKTOP_ICON',
    defaultMessage: 'Existing Desktop Icon',
  },
  MASCOT_IMAGE: {
    id: 'mascot-image.text',
    defaultMessage: 'Mascot Image',
  },
  BACKGROUND_IMAGE: {
    id: 'background-image.text',
    defaultMessage: 'Background Image',
  },
  EXISTING_MASCOT_IMAGE: {
    id: 'existing-mascot-image.text',
    defaultMessage: 'Existing Mascot Image',
  },
  EXISTING_BACKGROUND_IMAGE: {
    id: 'existing-background-image.text',
    defaultMessage: 'Existing Background Image',
  },
  HERO_IMAGE: {
    id: 'hero-image.text',
    defaultMessage: 'Hero Image',
  },
  BANNER_IMAGE: {
    id: 'banner-image.text',
    defaultMessage: 'Banner Image',
  },
  EXISTING_BANNER_IMAGE: {
    id: 'existing-banner-image.text',
    defaultMessage: 'Existing Banner Image',
  },
});
export default messages;
