import React, { useState } from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';
import { ALink } from 'components/ALink/ALink';
import { isNotEmpty } from '../ConditionalInput/ConditionalInput';
import TierForm from '../TierForm';

const EditTier = ({ index }: { index: number }) => {
  const [isShow, setShow] = useState(false);
  const [submitEdit, setSubmitEdit] = useState(false);
  const { setFieldValue, values } = useFormikContext() as any;

  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const handleOk = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setSubmitEdit(true);
  };

  const submit = (lookup: any, newValues: any) => {
    setSubmitEdit(false);
    setFieldValue(
      'levels',
      values.levels.map((level: any, idx: number) => {
        if (idx === index) {
          return {
            ...newValues,
            complete: isNotEmpty(newValues, lookup),
            completedAt: isNotEmpty(newValues, lookup) ? lookup : '',
          };
        }
        return level;
      })
    );
    setShow(!isShow);
  };

  return (
    <>
      <ALink onClick={handleShow}>
        <FormattedMessage id="edit.text" defaultMessage="Edit" />
      </ALink>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          width={800}
          title={
            <FormattedMessage
              id="Parameters.text"
              defaultMessage="Parameters"
            />
          }
          okText={
            <FormattedMessage id="continue.text" defaultMessage="Continue" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          onCancel={handleShow}
          onOk={handleOk}
        >
          <TierForm
            isLatest={index === values.levels.length - 1}
            index={index}
            submitEdit={submitEdit}
            submit={submit}
          />
        </Modal>
      )}
    </>
  );
};

export default EditTier;
