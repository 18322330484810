import { useMutation } from '@apollo/react-hooks';
import { Button, message, Steps } from 'antd';
import { Formik } from 'formik';
import {
  CREATE_AFFILIATE_PROGRAMME,
  SUBMIT_AFFILIATE_PROGRAMME,
  UPDATE_AFFILIATE_PROGRAMME,
} from 'graphql/mutations/affiliateAgent.mutation';
import { AFFILIATE_PROGRAMMES } from 'graphql/queries/affiliateAgent.query';
import { omit } from 'lodash';
import messages from 'messages';
import moment from 'moment';
import ms from 'ms';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import * as Yup from 'yup';
import Drawer from '../../../../../common/Drawer/Drawer';
import { getTierRequirement } from '../../constants';
import { AffiliateTypes, useAffiliateState } from '../../context';
import InnerForm from './components/InnerForm/InnerForm';
import { formSchemas } from './constants';
import {
  CreateAffiliateProgrammeScreens,
  CreateAffiliateProgrammeTypes,
  useCreateAffiliateProgrammeState,
} from './context';
import localMessages from './messages';
import {
  AffiliateProgrammeSettlementPeriod,
  getNextScreen,
  getPrevScreen,
  transformAffiliateData,
} from './utils';

const CustomFormik: any = Formik;

function CreateAffiliateProgramme() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [
    createAffiliateProgrammeState,
    createAffiliateProgrammeDispatch,
  ] = useCreateAffiliateProgrammeState() as any;

  const SubmissionModes = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    SUBMIT: 'SUBMIT',
  };

  const FormEvents = {
    EXITS: true,
    STAYS: false,
    HIDE_ALERTS: false,
  };

  const ErrorTypes = {
    DEFAULT: 'DEFAULT',
    NO_TIERS: 'NO_TIERS',
    INCOMPLETE_TIERS: 'INCOMPLETE_TIERS',
  };
  const translate = useTranslate();
  const success = (mode: string) => {
    switch (mode) {
      case SubmissionModes.CREATE: {
        message.success(
          translate(
            localMessages[
              'agent-affiliate.create-affiliate-programme-saved.text'
            ]
          )
        );
        break;
      }
      case SubmissionModes.UPDATE: {
        message.success(
          translate(
            localMessages[
              'agent-affiliate.create-affiliate-programme-updated.text'
            ]
          )
        );
        break;
      }
      case SubmissionModes.SUBMIT: {
        message.success(
          translate(
            localMessages[
              'agent-affiliate.create-affiliate-programme-published.text'
            ]
          )
        );
        break;
      }
      default:
        break;
    }
  };

  const error = (errorType: string) => {
    switch (errorType) {
      case ErrorTypes.DEFAULT: {
        message.error(
          translate(
            localMessages[
              'agent-affiliate.existing-default-affiliate-programme-error.text'
            ]
          )
        );
        break;
      }
      case ErrorTypes.NO_TIERS: {
        message.error(
          translate(
            localMessages[
              'agent-affiliate.no-tiers-affiliate-programme-error.text'
            ]
          )
        );
        break;
      }
      case ErrorTypes.INCOMPLETE_TIERS: {
        message.error(
          translate(
            localMessages[
              'agent-affiliate.no-complete-tier-details-affiliate-programme-error.text'
            ]
          )
        );
        break;
      }
      default:
        message.error(
          translate(
            localMessages[
              'agent-affiliate.create-affiliate-programme-error.text'
            ]
          )
        );
        break;
    }
  };

  const steps = {
    [CreateAffiliateProgrammeScreens.AGENT_AFFILIATE_PROGRAMME_SCREEN]: translate(
      messages.AGENT_AFFILIATE_PROGRAMME
    ),
    [CreateAffiliateProgrammeScreens.COST_SETTINGS_SCREEN]: translate(
      messages.COST_SETTINGS
    ),
    [CreateAffiliateProgrammeScreens.SETTLEMENT_PERIOD_SCREEN]: translate(
      messages.SETTLEMENT_PERIOD
    ),
    [CreateAffiliateProgrammeScreens.TIER_SETTINGS_SCREEN]: translate(
      messages.TIER_SETTINGS
    ),
  };

  const formRef = React.useRef(null) as any;
  const [affiliateState, affiliateDispatch] = useAffiliateState() as any;

  const [createAffiliateMutation] = useMutation(CREATE_AFFILIATE_PROGRAMME);
  const [updateAffiliateMutation] = useMutation(UPDATE_AFFILIATE_PROGRAMME);
  const [submitAffiliateMutation] = useMutation(SUBMIT_AFFILIATE_PROGRAMME);

  const formAction = {
    [SubmissionModes.CREATE]: createAffiliateMutation,
    [SubmissionModes.UPDATE]: updateAffiliateMutation,
    [SubmissionModes.SUBMIT]: submitAffiliateMutation,
  };

  const submit = async (mode = '', enableExit = false, enableAlert = true) => {
    setIsLoading(true);
    if (mode === SubmissionModes.SUBMIT || enableExit) {
      setIsSubmitting(true);
    }

    const formik = formRef.current as any;
    const { values } = formik;

    const levelsRequirement =
      values.tierRequirement.length === 2
        ? values.tierRequirementOperator
        : values.tierRequirement[0];

    const { forActiveMembers, forNetProfit } = getTierRequirement(
      levelsRequirement
    );

    const levels = values.levels
      .map((level: Record<string, any>) => {
        if (level.complete) {
          return omit(
            {
              ...level,
              minimumActiveMembersCount: forActiveMembers
                ? +level.minimumActiveMembersCount
                : 0,
              maximumActiveMembers: forActiveMembers
                ? +level.maximumActiveMembers
                : 0,
              minimumNetProfit: forNetProfit ? +level.minimumNetProfit : 0,
              maximumNetProfit: forNetProfit ? +level.maximumNetProfit : 0,
              percentage: level.percentage
                ? +(level.percentage / 100).toFixed(2)
                : 0,
            },
            [
              'key',
              'complete',
              'completedAt',
              'number',
              'maximumActiveMembers',
              'maximumNetProfit',
              '__typename',
            ]
          );
        }
        return undefined;
      })
      .filter((item: any) => item !== undefined);

    const payoutClaimOffsetDuration =
      ms(
        `${
          values.noPayoutClaimOffsetDuration
            ? 0
            : values.payoutClaimOffsetDuration
        }${values.payoutClaimOffsetSelected}`
      ) || 0;

    const payoutClaimExpiryDuration =
      ms(
        `${
          values.noPayoutClaimExpiryDuration
            ? 0
            : values.payoutClaimExpiryDuration
        }${values.payoutClaimExpirySelected}`
      ) || 0;

    const payoutExpiryDuration =
      ms(
        `${values.noPayoutExpiryDuration ? 0 : values.payoutExpiryDuration}${
          values.payoutExpirySelected
        }`
      ) || 0;

    const settlementTime = values.settlementTime
      ?.toISOString()
      ?.split('T')
      ?.pop();

    const vendorPlatformFees = Object.entries(values.vendorHandlers)
      .map((e: any) => {
        if (e[1].ggrChargeCostSharing) {
          const minimumCharge =
            !e[1].minimumCharge || e[1].noMinimumCharge
              ? 0
              : +e[1].minimumCharge;
          const maximumCharge =
            !e[1].maximumCharge || e[1].noMaximumCharge
              ? 0
              : +e[1].maximumCharge;
          const chargeLevels = e[1].chargeLevels ? e[1].chargeLevels : [];
          return omit({ ...e[1], minimumCharge, maximumCharge, chargeLevels }, [
            'ggrChargeCostSharing',
            'noMinimumCharge',
            'noMaximumCharge',
          ]);
        }
        return undefined;
      })
      .filter((item) => item !== undefined && item.vendor);

    const costs = {
      depositTransactionCost: values.depositTransactionCost
        ? +(values.depositTransactionCost / 100).toFixed(2)
        : 0,
      thirdPartyDepositTransactionCost: values.thirdPartyDepositTransactionCost
        ? +(values.thirdPartyDepositTransactionCost / 100).toFixed(2)
        : 0,
      withdrawalTransactionCost: values.withdrawalTransactionCost
        ? +(values.withdrawalTransactionCost / 100).toFixed(2)
        : 0,
      thirdPartyWithdrawalTransactionCost: values.thirdPartyWithdrawalTransactionCost
        ? +(values.thirdPartyWithdrawalTransactionCost / 100).toFixed(2)
        : 0,
      promoCost: values.promoCost ? +(values.promoCost / 100).toFixed(2) : 0,
      rebateCost: values.rebateCost ? +(values.rebateCost / 100).toFixed(2) : 0,
      interestAccountCost: values.interestAccountCost
        ? +(values.interestAccountCost / 100).toFixed(2)
        : 0,
    };

    const newValues = {
      levelsRequirement,
      payoutClaimOffsetDuration: `${payoutClaimOffsetDuration}`,
      payoutClaimExpiryDuration: `${payoutClaimExpiryDuration}`,
      payoutExpiryDuration: `${payoutExpiryDuration}`,
      settlementTime,
      levels,
      vendorPlatformFees,
      settlementDayOfMonth: values.settlementDayOfMonth
        ? values.settlementDayOfMonth
        : 1,
      description: values.description !== null ? values.description : '',
      minimumDepositRequirement:
        values.minimumDepositRequirement !== null
          ? +values.minimumDepositRequirement
          : 0,
      minimumEffectiveBetRequirement:
        values.minimumEffectiveBetRequirement !== null
          ? +values.minimumEffectiveBetRequirement
          : 0,
      turnoverRequirementMultiplier:
        values.turnoverRequirementMultiplier !== null
          ? +values.turnoverRequirementMultiplier
          : 0,
      ...costs,
    };

    const omittedFields = [
      'tierRequirement',
      'tierRequirementOperator',
      'noPayoutClaimOffsetDuration',
      'noPayoutClaimExpiryDuration',
      'noPayoutExpiryDuration',
      'payoutClaimOffsetSelected',
      'payoutClaimExpirySelected',
      'payoutExpirySelected',
      'vendorHandlers',
      'whichWeek',
      'membersCount',
      'positiveCarryOverEnabled',
      'id',
      'status',
    ];

    let input = { ...values, ...newValues };

    input = omit(input, omittedFields);

    // VALIDATION CHECKING FOR TIERS

    let types: any = [];

    const noLevels = formRef.current.values.levels.length === 0;

    const incompletedLevels =
      !noLevels &&
      (
        coercedGet(formRef.current.values, 'levels', []).filter(
          (l: Record<string, any>) => l.complete
        ) || []
      ).length !== formRef.current.values.levels.length;

    if (noLevels && mode === SubmissionModes.SUBMIT) {
      types = [...types, ErrorTypes.NO_TIERS];
    }

    if (incompletedLevels && mode === SubmissionModes.SUBMIT) {
      types = [...types, ErrorTypes.INCOMPLETE_TIERS];
    }
    if (types.length) {
      types.map((t: any) => error(t));
      setIsLoading(false);
      setIsSubmitting(false);
      return false;
    }

    // VALIDATION CHECKING FOR TIERS END

    try {
      const formAffiliateMutation = formAction[mode];

      if (mode === SubmissionModes.SUBMIT) {
        await updateAffiliateMutation({
          variables: { id: values.id, input },
        });
      }

      const res = await formAffiliateMutation({
        variables: {
          ...(mode !== SubmissionModes.SUBMIT && { input }),
          ...(mode !== SubmissionModes.CREATE && { id: values.id }),
        },
        refetchQueries: [
          {
            query: AFFILIATE_PROGRAMMES,
            variables: affiliateState.refetchVariables,
          },
        ],
      });

      if (mode === SubmissionModes.CREATE) {
        formik.setFieldValue(
          'id',
          coercedGet(res, 'data.createAffiliateProgramme', '')
        );
      }

      if (enableAlert) {
        success(mode);
      }

      if (enableExit) {
        formRef.current.resetForm();
        affiliateDispatch({
          type: AffiliateTypes.RESET_ACTIVE_AFFILIATE_PROGRAMME,
        });
        createAffiliateProgrammeDispatch({
          type: CreateAffiliateProgrammeTypes.SET_ACTIVE_SCREEN,
          payload:
            CreateAffiliateProgrammeTypes.AGENT_AFFILIATE_PROGRAMME_SCREEN,
        });
        affiliateDispatch({
          type: AffiliateTypes.HIDE_CREATE_AFFILIATE_PROGRAMME,
        });
      }
      return res;
    } catch (e) {
      const isDefault = coercedGet(formRef?.current?.values, 'default', false);

      if (isDefault) {
        error(ErrorTypes.DEFAULT);
      }

      return e;
    } finally {
      setIsLoading(false);
      if (mode === SubmissionModes.SUBMIT || enableExit) {
        setIsSubmitting(false);
      }
    }
  };

  React.useImperativeHandle(formRef, () => ({
    ...formRef.current,
    handleSubmit: () => submit,
  }));

  React.useEffect(() => {
    document.body.classList.remove('create-affiliate-programme-drawer-shown');
    if (affiliateState.showCreateAffiliateProgramme) {
      document.body.classList.add('create-affiliate-programme-drawer-shown');
    }
  }, [affiliateState.showCreateAffiliateProgramme]);

  const createAffiliateProgrammeValues = Object.values(
    CreateAffiliateProgrammeScreens
  );

  const activeScreenIndex =
    createAffiliateProgrammeValues.findIndex(
      (screen) => screen === createAffiliateProgrammeState.activeScreen
    ) || 0;

  const validationSchema = Yup.object().shape({
    ...formSchemas[activeScreenIndex],
  });

  const RenderLeftButtons = () => {
    const screen = getPrevScreen(createAffiliateProgrammeState.activeScreen);

    switch (createAffiliateProgrammeState.activeScreen) {
      case CreateAffiliateProgrammeScreens.AGENT_AFFILIATE_PROGRAMME_SCREEN:
        return (
          <Button
            onClick={() => {
              formRef.current.resetForm();
              affiliateDispatch({
                type: AffiliateTypes.RESET_ACTIVE_AFFILIATE_PROGRAMME,
              });
              affiliateDispatch({
                type: AffiliateTypes.HIDE_CREATE_AFFILIATE_PROGRAMME,
              });
            }}
          >
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
        );
      case CreateAffiliateProgrammeScreens.SETTLEMENT_PERIOD_SCREEN:
      case CreateAffiliateProgrammeScreens.COST_SETTINGS_SCREEN:
      case CreateAffiliateProgrammeScreens.TIER_SETTINGS_SCREEN:
        return (
          <Button
            onClick={() => {
              createAffiliateProgrammeDispatch({
                type: AffiliateTypes.SET_ACTIVE_SCREEN,
                payload: screen,
              });
            }}
          >
            <FormattedMessage id="back.text" defaultMessage="Back" />
          </Button>
        );
      default:
        return <></>;
    }
  };

  const costSettingsOpen =
    createAffiliateProgrammeState.activeScreen === 'COST_SETTINGS_SCREEN';

  const RenderRightButtons = () => {
    const screen = getNextScreen(createAffiliateProgrammeState.activeScreen);

    const continueButton = (
      <Button
        className="mx-2"
        type="primary"
        loading={isLoading}
        onClick={async () => {
          if (!formRef) {
            return;
          }
          const form = formRef.current;
          const errors = await form.validateForm();
          if (Object.keys(errors).length < 1) {
            if (!form.values.id) {
              submit(
                SubmissionModes.CREATE,
                FormEvents.STAYS,
                FormEvents.HIDE_ALERTS
              ).then((e) => {
                if (e.data) {
                  createAffiliateProgrammeDispatch({
                    type: CreateAffiliateProgrammeTypes.SET_ACTIVE_SCREEN,
                    payload: screen,
                  });
                }
              });
            } else {
              submit(
                SubmissionModes.UPDATE,
                FormEvents.STAYS,
                FormEvents.HIDE_ALERTS
              ).then((e) => {
                if (e.data) {
                  createAffiliateProgrammeDispatch({
                    type: CreateAffiliateProgrammeTypes.SET_ACTIVE_SCREEN,
                    payload: screen,
                  });
                }
              });
            }
          }
        }}
      >
        <FormattedMessage id="continue.text" defaultMessage="Continue" />
      </Button>
    );

    const publishButton = (
      <Button
        loading={isLoading}
        disabled={isSubmitting}
        className="mx-2"
        type="primary"
        onClick={async () => {
          const form = formRef.current;
          const errors = await form.validateForm();
          if (Object.keys(errors).length < 1) {
            if (form.values.id) {
              submit(SubmissionModes.SUBMIT, FormEvents.EXITS);
            }
          }
        }}
      >
        <FormattedMessage id="publish.text" defaultMessage="Publish" />
      </Button>
    );

    switch (createAffiliateProgrammeState.activeScreen) {
      case CreateAffiliateProgrammeScreens.AGENT_AFFILIATE_PROGRAMME_SCREEN:
      case CreateAffiliateProgrammeScreens.SETTLEMENT_PERIOD_SCREEN:
      case CreateAffiliateProgrammeScreens.COST_SETTINGS_SCREEN:
        return continueButton;
      case CreateAffiliateProgrammeScreens.TIER_SETTINGS_SCREEN:
        return publishButton;
      default:
        return <></>;
    }
  };

  const defaultValues = {
    vendorHandlers: { ...affiliateState.vendorHandlers },
    ...transformAffiliateData({
      ...affiliateState.activeAffiliateData,
    }),
  };

  const initialValues = {
    id: '',
    name: '',
    description: null,
    default: false,
    automaticPayoutClaiming: false,
    negativeCarryOverEnabled: false,
    positiveCarryOverEnabled: false,
    vendorPlatformFees: [],
    depositTransactionCost: 100,
    thirdPartyDepositTransactionCost: 100,
    withdrawalTransactionCost: 100,
    thirdPartyWithdrawalTransactionCost: 100,
    promoCost: 100,
    rebateCost: 100,
    interestAccountCost: 100,
    minimumEffectiveBetRequirement: null,
    minimumDepositRequirement: null,
    minimumPayoutAccumulationEnabled: false,
    minimumPayoutAccumulationAmount: 0,
    turnoverRequirementMultiplier: null,
    settlementPeriod: AffiliateProgrammeSettlementPeriod.WEEKLY,
    settlementTime: moment().startOf('day'),
    settlementDayOfWeek: 0,
    settlementDayOfMonth: 0,
    payoutClaimOffsetDuration: null,
    payoutClaimExpiryDuration: null,
    payoutExpiryDuration: null,
    payoutClaimOffsetSelected: 'd',
    payoutClaimExpirySelected: 'd',
    payoutExpirySelected: 'd',
    noPayoutClaimOffsetDuration: false,
    noPayoutClaimExpiryDuration: false,
    noPayoutExpiryDuration: false,
    levels: [],
    levelsRequirement: null,
    tierRequirement: [],
    tierRequirementOperator: 'BOTH',
    whichWeek: 0,
    ...defaultValues,
  };

  return (
    <Drawer
      backdrop={false}
      open={affiliateState.showCreateAffiliateProgramme}
      onClose={() =>
        affiliateDispatch({
          type: AffiliateTypes.HIDE_CREATE_AFFILIATE_PROGRAMME,
        })
      }
    >
      <Drawer.Header title="Create Agent Affiliate Programme">
        <div className="drawer-actions">
          <ALink
            disabled={isSubmitting}
            className="mr-4 px-0 py-0"
            onClick={async () => {
              const form = formRef.current;
              const errors = await form.validateForm();
              if (Object.keys(errors).length < 1) {
                if (!form.values.id) {
                  submit(SubmissionModes.CREATE, FormEvents.STAYS);
                } else {
                  submit(SubmissionModes.UPDATE, FormEvents.STAYS);
                }
              }
            }}
          >
            Save
          </ALink>
          <ALink
            disabled={isSubmitting}
            onClick={async () => {
              const form = formRef.current;
              const errors = await form.validateForm();
              if (Object.keys(errors).length < 1) {
                if (!form.values.id) {
                  submit(SubmissionModes.CREATE, FormEvents.EXITS);
                } else {
                  submit(SubmissionModes.UPDATE, FormEvents.EXITS);
                }
              }
            }}
          >
            Save &amp; Exit
          </ALink>
        </div>
      </Drawer.Header>
      <Drawer.Content
        padding={costSettingsOpen ? '24px 0px 0px 0px' : null}
        height={costSettingsOpen ? 'calc(100vh - 122px)' : null}
      >
        <div className="agent-main">
          <div
            className="container"
            style={{
              maxWidth: `${costSettingsOpen ? '100vw' : '1112px'}`,
              padding: `${costSettingsOpen ? 0 : 12}`,
            }}
          >
            <Steps
              current={activeScreenIndex}
              className={`${!costSettingsOpen ? 'mb-5' : ''}`}
              style={{ maxWidth: 1112, margin: 'auto' }}
            >
              {Object.entries(steps).map((item) => (
                <Steps.Step key={item[0]} title={item[1]} />
              ))}
            </Steps>
            <CustomFormik
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {() => <InnerForm />}
            </CustomFormik>
          </div>
        </div>
      </Drawer.Content>
      <Drawer.Footer>
        <div className="d-flex ">
          <RenderLeftButtons />
          <span className="mr-auto" />
          <RenderRightButtons />
        </div>
      </Drawer.Footer>
    </Drawer>
  );
}

export default CreateAffiliateProgramme;
