import gql from 'graphql-tag';

export const TOTAL_AMOUNT_DETAILS = gql`
  query TotalAmountDetails($startDateTime: DateTime!, $endDateTime: DateTime!) {
    betAmountDetails: totalEffectiveBetChart(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    ) {
      dataPoints {
        pv: value
      }
    }

    totalBetsCount: totalBetsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    depositAmountDetails: totalDepositAmountChart(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    ) {
      dataPoints {
        pv: value
      }
    }

    withdrawalAmountDetails: totalWithdrawalAmountChart(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    ) {
      dataPoints {
        pv: value
      }
    }

    winLossAmountDetails: totalWinlossChart(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    ) {
      dataPoints {
        pv: value
      }
    }

    netProfitAmountDetails: totalNetProfitChart(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    ) {
      dataPoints {
        pv: value
      }
    }
  }
`;

export const TOTALS__WITH_PREVIOUS_VALUE = gql`
  query TotalAmountsDetailsWithPrev(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $startDateTimePrev: DateTime!
    $endDateTimePrev: DateTime!
  ) {
    totalBetAmount: totalEffectiveBet(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalBetAmountPrev: totalEffectiveBet(
      input: {
        startDateTime: $startDateTimePrev
        endDateTime: $endDateTimePrev
      }
    )

    totalDepositAmount: totalDepositAmount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalDepositAmountPrev: totalDepositAmount(
      input: {
        startDateTime: $startDateTimePrev
        endDateTime: $endDateTimePrev
      }
    )

    totalWithdrawalsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalWithdrawersCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalWithdrawalAmount: totalWithdrawalAmount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalWithdrawalAmountPrev: totalWithdrawalAmount(
      input: {
        startDateTime: $startDateTimePrev
        endDateTime: $endDateTimePrev
      }
    )

    totalWinlossAmount: totalWinloss(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalWinlossAmountPrev: totalWinloss(
      input: {
        startDateTime: $startDateTimePrev
        endDateTime: $endDateTimePrev
      }
    )

    totalNetProfitAmount: totalNetProfit(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalNetProfitAmountPrev: totalNetProfit(
      input: {
        startDateTime: $startDateTimePrev
        endDateTime: $endDateTimePrev
      }
    )

    totalDepositsCount: totalDepositsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalDepositorsCount: totalDepositorsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )
  }
`;

/**
 * totalLiveOnlineMembersCount and totalOnlineMembersCount are temporary solutions for the
 * Total Online Members Count, we'll redo this once API is updated.
 *
 * Please also check the other file which is also have this temporary solution
 * src/components/Navbar/components/RootSideBar/queries.js
 *  */
export const TOTAL_LINE_DATA = gql`
  query Query(
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $startDateTimePrev: DateTime!
    $endDateTimePrev: DateTime!
    $withMemberOLPermission: Boolean!
  ) {
    totalLiveOnlineMembersCount: members(filter: { online: { eq: true } }) {
      totalCount
    }

    totalOnlineMembersCount: members(
      filter: { lastOnlineDateTime: { gte: $startDateTime, lte: $endDateTime } }
    ) {
      totalCount
    }

    totalOnlineMembersCountPrev: members(
      filter: {
        lastOnlineDateTime: { gte: $startDateTimePrev, lte: $endDateTimePrev }
      }
    ) {
      totalCount
    }

    totalOnlineMembersCountChart: totalOnlineMembersCountChart(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    ) @include(if: $withMemberOLPermission) {
      interval
      dataPoints {
        value
      }
    }

    totalMemberRegistrationsCount: totalMemberRegistrationsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalMemberRegistrationsCountPrev: totalMemberRegistrationsCount(
      input: {
        startDateTime: $startDateTimePrev
        endDateTime: $endDateTimePrev
      }
    )

    totalMemberRegistrationsCountChart: totalMemberRegistrationsCountChart(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    ) {
      interval
      dataPoints {
        value
      }
    }

    totalFirstDepositsCount: totalFirstDepositorsCount(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    )

    totalFirstDepositsCountPrev: totalFirstDepositorsCount(
      input: {
        startDateTime: $startDateTimePrev
        endDateTime: $endDateTimePrev
      }
    )

    totalFirstDepositsCountChart: totalFirstDepositorsCountChart(
      input: { startDateTime: $startDateTime, endDateTime: $endDateTime }
    ) {
      interval
      dataPoints {
        value
      }
    }
  }
`;

export default {};
