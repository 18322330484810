import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { FilterItem } from 'components/FilterItem/FilterItem';
import useTranslate from 'utils/useTranslate';
import { PAYMENT_TYPES } from '../../../../constants';
import messages from '../../../../messages';

const bank = 'BANK';
const alipay = 'ALIPAY';
const wechat = 'WECHAT';

const external = 'EXTERNAL';
const internal = 'INTERNAL';

type Props = {
  value: string[];
  onChange: (e: any) => void;
};

const internalValues = [
  PAYMENT_TYPES.OFFLINE.BANK,
  PAYMENT_TYPES.OFFLINE.ALIPAY,
  PAYMENT_TYPES.OFFLINE.WECHAT,
];

const externalValues = [
  PAYMENT_TYPES.ONLINE.BANK,
  PAYMENT_TYPES.ONLINE.ALIPAY,
  PAYMENT_TYPES.ONLINE.WECHAT,
];

const PaymentMethodTypeFilter = ({ value, onChange }: Props) => {
  let filterVals: string[] = [];

  const [sources, setSource] = useState<Array<any>>([]);
  useEffect(() => {
    if (!value.length) {
      setSource([]);
    }
  }, [value]);

  useEffectOnce(() => {
    const isInternal = internalValues.every((internalVal) =>
      value.includes(internalVal)
    );
    const isExternal = externalValues.every((externalVal) =>
      value.includes(externalVal)
    );

    if (isInternal) {
      setSource((source) => [...source, internal]);
    }

    if (isExternal) {
      setSource((source) => [...source, external]);
    }
  });

  if (
    value.includes(PAYMENT_TYPES.OFFLINE.BANK) ||
    value.includes(PAYMENT_TYPES.ONLINE.BANK)
  ) {
    filterVals = [...filterVals, bank];
  }
  if (
    value.includes(PAYMENT_TYPES.OFFLINE.ALIPAY) ||
    value.includes(PAYMENT_TYPES.ONLINE.ALIPAY)
  ) {
    filterVals = [...filterVals, alipay];
  }

  if (
    value.includes(PAYMENT_TYPES.OFFLINE.WECHAT) ||
    value.includes(PAYMENT_TYPES.ONLINE.WECHAT)
  ) {
    filterVals = [...filterVals, wechat];
  }

  const handleSourceChange = (e: Array<any>) => {
    let values: any[] = [];
    if (value.length) {
      const types = [bank, alipay, wechat];
      if (e.includes(internal)) {
        types.forEach((type) => {
          if (
            value.includes(PAYMENT_TYPES.OFFLINE[type]) ||
            value.includes(PAYMENT_TYPES.ONLINE[type])
          )
            values.push(PAYMENT_TYPES.OFFLINE[type]);
        });
      }
      if (e.includes(external)) {
        types.forEach((type) => {
          if (
            value.includes(PAYMENT_TYPES.OFFLINE[type]) ||
            value.includes(PAYMENT_TYPES.ONLINE[type])
          )
            values.push(PAYMENT_TYPES.ONLINE[type]);
        });
      }
    } else {
      if (e.includes(internal)) {
        values = [
          ...values,
          ...[
            PAYMENT_TYPES.OFFLINE.BANK,
            PAYMENT_TYPES.OFFLINE.ALIPAY,
            PAYMENT_TYPES.OFFLINE.WECHAT,
          ],
        ];
      }
      if (e.includes(external)) {
        values = [
          ...values,
          ...[
            PAYMENT_TYPES.ONLINE.BANK,
            PAYMENT_TYPES.ONLINE.ALIPAY,
            PAYMENT_TYPES.ONLINE.WECHAT,
          ],
        ];
      }
    }
    onChange(values);
    setSource(e);
  };

  const handleChange = (e: any[]) => {
    const types = [bank, alipay, wechat];
    let values: any[] = [];

    types.forEach((type) => {
      if (e.includes(type)) {
        if (sources.includes(internal)) {
          values.push(PAYMENT_TYPES.OFFLINE[type]);
        }
        if (sources.includes(external)) {
          values.push(PAYMENT_TYPES.ONLINE[type]);
        }
        if (!sources.length) {
          values = [
            ...values,
            ...[PAYMENT_TYPES.OFFLINE[type], PAYMENT_TYPES.ONLINE[type]],
          ];
        }
      }
    });

    onChange(values);
  };

  useUpdateEffect(() => {
    const isInternal = internalValues.every((internalVal) =>
      value.includes(internalVal)
    );
    const isExternal = externalValues.every((externalVal) =>
      value.includes(externalVal)
    );

    const bothExists = isInternal && isExternal;

    if (bothExists) {
      setSource([internal, external]);
    } else if (isInternal) {
      setSource([internal]);
    } else if (isExternal) {
      setSource([external]);
    } else {
      setSource([]);
    }
  }, [value]);

  const translate = useTranslate();
  return (
    <>
      <FilterItem label={translate(messages.paymentSource)}>
        <Checkbox.Group onChange={handleSourceChange} value={sources}>
          <Checkbox className="mt-1" value={internal}>
            {translate(messages.internal)}
          </Checkbox>
          <br />
          <Checkbox className="mt-1" value={external}>
            {translate(messages.thirdparty)}
          </Checkbox>
        </Checkbox.Group>
      </FilterItem>
      <FilterItem label={translate(messages.paymentmethodtype)}>
        <Checkbox.Group onChange={handleChange} value={filterVals}>
          <Checkbox className="mt-1" value={bank}>
            {translate(messages.banktransfer)}
          </Checkbox>
          <br />
          <Checkbox className="mt-1" value={alipay}>
            {translate(messages.alipay)}
          </Checkbox>
          <br />
          <Checkbox className="mt-1" value={wechat}>
            {translate(messages.wechat)}
          </Checkbox>
        </Checkbox.Group>
      </FilterItem>
    </>
  );
};

export default PaymentMethodTypeFilter;
