import gql from 'graphql-tag';

export const PAYMENT_GATEWAYS = gql`
  query PaymentGateways($input: PaymentGatewaysInput!) {
    paymentGateways(input: $input) {
      ... on PaymentGateway {
        id
        name
      }
      ... on MidPayDirectPaymentGateway {
        id
        name
        credentialFields
        paymentChannels
      }
      ... on DiorPay2PaymentGateway {
        id
        name
        gateway
        paymentChannels
      }
      __typename
    }
  }
`;
