import gql from 'graphql-tag';

export const WITHDRAWAL_METHODS = gql`
  query WithdrawalMethods(
    $first: Int
    $after: Binary
    $filter: WithdrawalMethodFilterInput
  ) {
    withdrawalMethods(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          value: id
          label: name
          __typename

          ... on OnlineBankTransferWithdrawalMethod {
            withdrawalProvider {
              id
              name
              __typename
            }
          }

          ... on OnlineWechatWithdrawalMethod {
            withdrawalProvider {
              id
              name
              __typename
            }
          }

          ... on OnlineAlipayWithdrawalMethod {
            withdrawalProvider {
              id
              name
              __typename
            }
          }
        }
      }
    }
  }
`;

export default {};
