import React from 'react';
import coercedGet from 'utils/coercedGet';
import { useFilterValues } from 'contexts/Filters';
import { transformMovableDateFilter } from 'utils/dateUtils';

const useTransformedDates = <T extends Record<string, any> | {}>(
  dateArr: Array<string> = []
): T => {
  const initialDate = {} as T;
  const [localDateFilters, setLocalDateFilters] = React.useState(initialDate);
  const { filters } = useFilterValues();

  React.useEffect(() => {
    const dateObj = dateArr.reduce((acc: any, curr: string) => {
      const currGte: string | null = coercedGet(filters, `${curr}.gte`, null);
      const currLte: string | null = coercedGet(filters, `${curr}.lte`, null);

      const hasDate = currGte && currLte;

      acc[curr] = hasDate
        ? {
            gte: currGte,
            lte: currLte,
          }
        : null;

      return acc;
    }, {});

    const transformedFilters = transformMovableDateFilter(dateObj);

    const procDates = dateArr.reduce((acc: any, curr: string) => {
      acc[curr] =
        transformedFilters[curr] &&
        transformedFilters[curr]?.gte &&
        transformedFilters[curr]?.lte
          ? transformedFilters[curr]
          : null;

      return acc;
    }, {});

    setLocalDateFilters(procDates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return localDateFilters;
};

export default useTransformedDates;
