import React from 'react';
import { Tooltip as AntdToolTip } from 'antd';
import coercedGet from 'utils/coercedGet';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import useTranslate from 'utils/useTranslate';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';
import messages from 'messages';
import Note from './components/Note';
import { ContainerStyled, BubbleContainerStyled, BubbleStyled } from './styles';

const Tooltip: any = AntdToolTip;
const MemberNotes = ({
  memberId,
  toolTipId,
}: {
  memberId: string;
  toolTipId: string;
}) => {
  const translate = useTranslate();

  const {
    memberNotesLoaderSet: [useMemberNotesLoader],
  } = useLoaders();
  const { loading, error, data = {} } = useMemberNotesLoader(memberId);

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;

  const notes = coercedGet(data, 'notes.edges', []);

  const notesLength = notes.length;
  const toolTipTitle = notesLength ? (
    <Note note={notes[0]} />
  ) : (
    translate(messages.NO_MEMBER_NOTE_YET)
  );

  return (
    <Tooltip
      placement="bottom"
      title={toolTipTitle}
      {...(toolTipId && {
        getPopupContainer: () => document.getElementById(toolTipId),
      })}
    >
      <ContainerStyled>
        <BubbleContainerStyled>
          <BubbleStyled notesLength={notesLength}>{notesLength}</BubbleStyled>
        </BubbleContainerStyled>
        <div id={toolTipId} />
      </ContainerStyled>
    </Tooltip>
  );
};

export default MemberNotes;
