import React, { useCallback } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Radio, Typography } from 'antd';
import MemberLevelsSelect from 'components/Antd/MemberLevelsSelect';
import MemberLoyaltyLevelsSelect from 'components/Antd/MemberLoyaltyLevelsSelect';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import isRow from 'utils/isRow';
import messages from '../../../../messages';

const loyaltyLevelFilter = { status: { in: ['ACTIVE', 'INACTIVE'] } };

const CustomMemberLoyaltyLevelsSelect: any = MemberLoyaltyLevelsSelect;
const CustomMemberLevelsSelect: any = MemberLevelsSelect;

type Props = {
  values: Record<string, any>;
  handleChange: any;
  handleBlur: any;
  errors: any;
  formItemLayout: Record<string, any>;
  setFieldValue: (key: string, value: any) => void;
  disabled?: boolean;
};

const OtherSettings = ({
  values,
  handleChange,
  handleBlur,
  errors,
  formItemLayout,
  setFieldValue,
  disabled,
}: Props) => {
  const { Text } = Typography;
  const translate = useTranslate();
  const { currency } = useConfig();
  const validityMinutes = (
    <span>
      <Text type="secondary">{translate(messages.validityhelper)}</Text>
      <br />
      {errors.depositExpiry || null}
    </span>
  );

  const onChangeMemberLoyaltyLevel = useCallback(
    (e) => setFieldValue('memberLoyaltyLevel', e),
    [setFieldValue]
  );

  const onChangeExcludedMember = useCallback(
    (e) => setFieldValue('excludedMemberLevel', e),
    [setFieldValue]
  );

  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1"
        label={<strong>{translate(messages.othersettings)}</strong>}
        colon={false}
      />
      {!isRow && (
        <>
          <Form.Item
            label={translate(messages.memberVipIncluded)}
            {...formItemLayout}
            validateStatus={errors.memberLoyaltyLevel ? 'error' : ''}
            help={errors.memberLoyaltyLevel || null}
          >
            <CustomMemberLoyaltyLevelsSelect
              onChange={onChangeMemberLoyaltyLevel}
              value={values.memberLoyaltyLevel}
              placeHolder={translate(messages.entermemberloyaltylevel)}
              multiple
              disabled={disabled}
              otherFilters={loyaltyLevelFilter}
            />
          </Form.Item>
          <Form.Item
            label={translate(messages.excludedmembermarker)}
            {...formItemLayout}
            validateStatus={errors.excludedMemberLevel ? 'error' : ''}
            help={errors.excludedMemberLevel || null}
          >
            <CustomMemberLevelsSelect
              forRebates
              onChange={onChangeExcludedMember}
              value={values.excludedMemberLevel}
              placeHolder={translate(messages.selectalevel)}
              multiple
              disabled={disabled}
              status={['ENABLED']}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        label={`${translate(messages.validity)} ${translate(messages.minutes)}`}
        {...formItemLayout}
        validateStatus={errors.depositExpiry ? 'error' : ''}
        help={validityMinutes}
        htmlFor="depositExpiry"
      >
        <Input
          type="number"
          name="depositExpiry"
          id="depositExpiry"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.depositExpiry}
          placeholder={translate(messages.validity)}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.remarks)}
        {...formItemLayout}
        validateStatus={errors.remarks ? 'error' : ''}
        help={errors.remarks || null}
        className="mt-3"
      >
        <Input
          name="remarks"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.remarks}
          placeholder={translate(messages.remarks)}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.turnover)}
        {...formItemLayout}
        validateStatus={errors.turnoverRequirementMultiplier ? 'error' : ''}
        help={errors.turnoverRequirementMultiplier || null}
        className="mt-3"
        htmlFor="turnoverRequirementMultiplier"
      >
        <Input
          name="turnoverRequirementMultiplier"
          id="turnoverRequirementMultiplier"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.turnoverRequirementMultiplier}
          placeholder={translate(messages.turnover)}
          suffix="X"
          style={{ width: 200 }}
          type="number"
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.transactionfee)}
        {...formItemLayout}
        validateStatus={errors.transactionFee ? 'error' : ''}
        help={errors.transactionFee || null}
      >
        <Radio.Group
          name="transactionFeeType"
          onChange={handleChange}
          value={values.transactionFeeType}
        >
          <Radio value="ABSOLUTE">{translate(messages.absolute)}</Radio>
          <Radio value="RELATIVE">{translate(messages.relative)}</Radio>
        </Radio.Group>
        <br />
        <Input
          name="transactionFee"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.transactionFee}
          placeholder="0"
          addonAfter={
            values.transactionFeeType === 'ABSOLUTE' ? currency?.symbol : '%'
          }
          type="number"
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.status)}
        {...formItemLayout}
        validateStatus={errors.enabled ? 'error' : ''}
        help={errors.enabled || null}
        className="mb-0"
      >
        <Radio.Group
          name="enabled"
          onChange={handleChange}
          value={values.enabled}
          disabled={disabled}
        >
          <Radio value>{translate(messages.enable)}</Radio>
          <Radio value={false}>{translate(messages.disable)}</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default OtherSettings;
