import styled from 'styled-components';

export const FilterContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default {};
