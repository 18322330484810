import React, { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { FormattedMessage } from 'react-intl';
import esGet from 'utils/esGet';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import {
  allTypes,
  useCreateLoyaltyProgramContext,
  CreateLoyaltyProgramScreens,
} from '../../context';

type Props = {
  handleOverlay: () => void;
  ref: any;
};

const CancelConfirm: React.FC<Props> = React.forwardRef((props, ref: any) => {
  const { handleOverlay } = props;
  const translate = useTranslate();

  const addTierFormsRef: React.MutableRefObject<{
    handleTab: (type: string) => void;
    resetActiveKey: () => void;
  }> = ref;

  const [contextState, dispatch] = useCreateLoyaltyProgramContext() as any;

  const { activeScreen, currentProgramId } = contextState;

  const handleResetAddTierForms = () =>
    addTierFormsRef.current.handleTab('RESET');

  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow((prev) => !prev);

  const handleShowProgramList = () => {
    if (currentProgramId || esGet(addTierFormsRef?.current, null)) {
      const resetActiveKey = esGet(
        addTierFormsRef?.current?.resetActiveKey,
        () => null
      );

      resetActiveKey();
      handleOverlay();
      handleShow();
      return;
    }

    dispatch({
      type: allTypes.SET_CURRENT_PROGRAM_ID,
      payload: null,
    });

    dispatch({
      type: allTypes.SET_ACTIVE_TIER,
      payload: null,
    });

    handleOverlay();
    handleShow();
  };

  const handleCancelConfirm = () => {
    if (activeScreen === CreateLoyaltyProgramScreens.ADD_TIER_FORMS) {
      handleResetAddTierForms();
      return;
    }
    handleShow();
  };

  return (
    <>
      <ALink className="mr-4" onClick={handleCancelConfirm}>
        {translate(messages.CANCEL)}
      </ALink>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          onCancel={handleShow}
          onOk={() => {
            handleShowProgramList();
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
              &nbsp;
              <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
});

export default CancelConfirm;
