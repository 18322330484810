import React from 'react';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/date';
import { RemarkContainerStyled } from 'styles/StyledRemark';

const Remark = ({ remarks, date }: { remarks: string; date: string }) => (
  <RemarkContainerStyled>
    <div className="content">{remarks}</div>
    <div className="d-flex justify-content-space-between">
      {date && <div>{moment(date).format(DATE_TIME_FORMAT)}</div>}
    </div>
  </RemarkContainerStyled>
);

export default Remark;
