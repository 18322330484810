import Layout from 'components/Layout';
import React from 'react';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { MemberIpAddressReportItemsFilterInput } from 'types/graphqlTypes';
import { StateFilter } from '../../types';
import SearchSettingsForm from '../SearchSettingsForm';
import FilterItems from '../FilterItems';

type Props = {
  filter: MemberIpAddressReportItemsFilterInput;
  stateFilters: StateFilter;
  onFilterChange: (filterData: StateFilter) => void;
  isLoading: boolean;
  resetStateFilter: () => void;
};

const Sidebar: React.FC<Props> = ({
  filter,
  onFilterChange,
  stateFilters,
  resetStateFilter,
}) => (
  <Layout.Sidebar
    quickSearch={{
      filters: stateFilters,
      contextKey: 'member-ip-address-summary',
      onFilterChange,
      quickSearchId: QuickSearchIds.MEMBER_IP_ADDRESS_SUMMARY,
      isNext: true,
      searchSettingsForm: SearchSettingsForm,
      clearLocalStateFilters: resetStateFilter,
    }}
  >
    <FilterItems
      filters={filter}
      stateFilters={stateFilters}
      onFilterChange={onFilterChange}
      onRawFilterChange={() => {}}
    />
  </Layout.Sidebar>
);

export default Sidebar;
