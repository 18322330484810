import styled from 'styled-components';

export const StyledRequestUpdate: any = styled.div`
  display: flex;
  align-items: center;
  max-height: 32px;

  .ant-switch {
    margin-right: 5px;
    width: 28px;
  }

  .ant-switch-checked {
    background-color: #27c989;
  }

  span {
    font-size: 14px;
    width: ${({ locale }: { locale: string }) =>
      locale === 'en' ? 103 : 50}px;
    line-height: 12px;
  }
  @-moz-document url-prefix() {
    span {
      width: ${({ locale }: { locale: string }) =>
        locale === 'en' ? 115 : 50}px;
    }
  }
`;
