import React, { useEffect, useRef, useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Col, Row } from 'antd';
import { get } from 'lodash';
import MoreActions from 'components/MoreActions';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';

import RefreshButton from 'components/RefreshButton/RefreshButton';
import { NewMember } from 'components/NewMember/NewMember';
import { LeftColumn } from './components/LeftColumn/LeftColumn';
import { useMemberManagement } from '../../MemberManagementContext';

export const HeaderMemberManagement = ({
  refetchParams,
  filters,
  isLoading,
  onFilterChange,
  initialFilters,
  ...props
}: IHeaderMemberManagement) => {
  const { role, permissions } = usePermissions();
  const { refreshPage } = useMemberManagement();
  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const [parentWidth, setParentWidth] = useState(0);
  const [widthDiff, setWidthDiff] = useState(0);
  const [expandedTags, setExpandedTags] = useState(false);
  const parentRef = useRef(null);

  useEffect(() => {
    setParentWidth(get(parentRef, 'current.offsetWidth', 0));
  }, []);

  return (
    <Row {...props}>
      <Col span={10}>
        <div className="d-flex" ref={parentRef}>
          {parentWidth > 0 && (
            <LeftColumn
              expandedTags={expandedTags}
              setExpandedTags={setExpandedTags}
              setWidthDiff={setWidthDiff}
              onFilterChange={onFilterChange}
              isLoading={isLoading}
              filters={filters}
              parentWidth={parentWidth}
            />
          )}
        </div>
      </Col>
      <Col span={4}>
        <div className="d-flex align-items-center">
          {widthDiff > 100 && (
            <LegacyIcon
              type={expandedTags ? 'double-left' : 'double-right'}
              className="cursor-pointer mr-2"
              onClick={() => setExpandedTags((prev) => !prev)}
            />
          )}
          <ClearAllFilterButton
            initialFilters={initialFilters}
            currentFilters={filters}
            onFilterChange={onFilterChange}
          />
        </div>
      </Col>

      <Col span={10}>
        <div className="d-flex align-items-center justify-content-flex-end">
          <RefreshButton onClick={refreshPage} />
          <ColumnsFilterNew iconOnly tabId="member-management" />
          <div className="mr-2">
            <MoreActions refetchParams={refetchParams} filters={filters} />
          </div>

          {ALLOWED_CREATE && <NewMember refetchParams={refetchParams} />}
        </div>
      </Col>
    </Row>
  );
};

export interface IHeaderMemberManagement {
  refetchParams: object;
  filters: any;
  isLoading: boolean;
  onFilterChange: (e: any) => void;
  initialFilters: object;
  [key: string]: any;
}
