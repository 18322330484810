import transform from 'lodash/transform';
import { isEqualWith, isEqual, isObject, isArray } from 'lodash';

const customizer = (baseValue: any[], value: any[]) => {
  if (isArray(baseValue) && isArray(value)) {
    return isEqual(baseValue.sort(), value.sort());
  }
  return undefined;
};
/* eslint-disable */
/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function diffObject(object: Record<string, any>, base: Record<string, any>) {
  function changes(object: Record<string, any>, base: Record<string, any>) {
    return transform(object, function(
      result: Record<string, any>,
      value: any,
      key: any
    ) {
      if (!isEqualWith(value, base[key], customizer)) {
        result[key] =
          isObject(value) && isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export default diffObject;
