import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { CREATE_MEMBER_TAG } from 'graphql/mutations/memberTag.mutation';
import { GET_MEMBER_TAGS } from 'graphql/queries/memberTag.query';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import LabelForm from '../LabelForm';
import messages from './messages';
import { StyledModal } from './styles';

const NewOperator = () => {
  const [isShow, setIsShow] = useState(false);

  const handleModal = () => setIsShow((prevState) => !prevState);

  const refetchQueries = [
    {
      query: GET_MEMBER_TAGS,
      variables: { first: 10, filter: {} },
    },
  ];
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [mutate] = useMutation(CREATE_MEMBER_TAG, {
    onCompleted: () => {
      message.success(
        translate(
          messages['member.label-management.create-new-label-success.text']
        )
      );
    },
    refetchQueries,
    context,
  });

  return (
    <>
      <Button
        onClick={handleModal}
        style={{ marginTop: 'auto' }}
        type="primary"
      >
        <PlusOutlined />
        <FormattedMessage
          id="member.label-management.create-new-label.text"
          defaultMessage="Create new label"
        />
      </Button>
      {isShow && (
        <StyledModal
          title={
            <FormattedMessage
              id="member.label-management.create-new-label.text"
              defaultMessage="Create new label"
            />
          }
          visible={isShow}
          width={540}
          onOk={handleModal}
          onCancel={handleModal}
          footer={false}
        >
          <div>
            <LabelForm onClose={handleModal} mutate={mutate} defaults={{}} />
          </div>
        </StyledModal>
      )}
    </>
  );
};

export default NewOperator;
