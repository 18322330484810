import { get } from 'lodash';
import { memberLevelColors } from 'utils/memberLevelColors';

export const findColorByBgColor = (
  bgColor: string,
  key = 'bgColor',
  defaultValue = '#888888'
) =>
  get(
    memberLevelColors.find(
      (memberLevelColor) => memberLevelColor.bgColor === bgColor
    ),
    key,
    defaultValue
  );
