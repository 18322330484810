import React from 'react';
import { LayoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import { FormattedMessage } from 'react-intl';
// import DownloadCsv from 'components/DownloadCsvGlobal';
// import { TabId } from 'SuperAdminMain/constants/route.js';
// import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
// import TimezoneSelect from './components/TimezoneSelect';
// import RefreshTable from './components/RefreshTable';
import ColumnsFilterNew from 'SuperAdminMain/shared/components/ColumnsFilterSA';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import FilterConditions from '../FilterConditions';
import HorizontalRule from './components/HorizontalRule';
import { StyledTagContainer, StyledActionsContainer } from './styles';
import RefreshTable from './components/RefreshTable';

type Props = {
  handleSidebar: () => void;
};

const TableHeader = React.forwardRef(({ handleSidebar }: Props, ref: any) => (
  <div className="d-flex my-3 px-3">
    <Button onClick={handleSidebar} size="small" className="mt-1">
      <LayoutOutlined />
    </Button>

    <StyledTagContainer>
      <FilterConditions />
    </StyledTagContainer>

    <StyledActionsContainer>
      <HorizontalRule className="mx-2" />
    </StyledActionsContainer>

    <StyledActionsContainer>
      <RefreshTable onClick={() => ref.current.refetchTable()} />
    </StyledActionsContainer>

    <StyledActionsContainer>
      <ColumnsFilterNew
        className="mr-3"
        tabId={TabId.VENDORS_CONSOLIDATED_USAGE_REPORT}
        iconOnly
      />
    </StyledActionsContainer>
  </div>
));
export default TableHeader;
