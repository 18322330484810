import React from 'react';
import messages from 'messages';
import { blockAlphabets } from 'utils/blockAlphabets';
import useTranslate from 'utils/useTranslate';
import { StyledInput } from './styles';

type Props = {
  onChange: (e: { range: string; value: string | null | number }) => void;
  value: { min: number | undefined; max: number | undefined };
  minPlaceHolder?: string;
  maxPlaceHolder?: string;
};

const AmountFilter: React.FC<Props> = ({
  value,
  onChange,
  minPlaceHolder,
  maxPlaceHolder,
}) => {
  const translate = useTranslate();

  const handleMinChange = (newValue: number | undefined | null) => {
    onChange({
      range: 'gte',
      value: newValue || null,
    });
  };

  const handleMaxChange = (newValue: number | undefined | null) => {
    onChange({
      range: 'lte',
      value: newValue || null,
    });
  };

  const handleBlur = (key: string) => {
    if (value.min && value.max) {
      if (value.min > value.max || value.max < value.min) {
        onChange({
          range: key === 'min' ? 'lte' : 'gte',
          value: '',
        });
      }
    }
  };

  const onKeyDownPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    blockAlphabets(e);
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-space-between">
      <StyledInput
        onChange={(newValue: number | undefined | string | null) =>
          handleMinChange(newValue as number)
        }
        value={value.min}
        placeholder={minPlaceHolder || translate(messages.MIN)}
        onBlur={() => handleBlur('min')}
        type="number"
        min={0}
        aria-label="min-input"
        onKeyDown={onKeyDownPress}
      />
      -
      <StyledInput
        onChange={(newValue: number | undefined | string | null) =>
          handleMaxChange(newValue as number)
        }
        value={value.max}
        placeholder={maxPlaceHolder || translate(messages.MAX)}
        onBlur={() => handleBlur('max')}
        type="number"
        min={0}
        aria-label="max-input"
        onKeyDown={onKeyDownPress}
      />
    </div>
  );
};

export default AmountFilter;
