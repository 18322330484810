import gql from 'graphql-tag';

// Commented out the lat and long because its still need to be fixed on the backend
export const PLACES = gql`
  query Places(
    $first: Int
    $after: Binary
    $filter: PlacesFilterInput!
    $language: Language
  ) {
    places(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        __typename
      }
      edges {
        cursor
        node {
          displayName(language: $language)
          id
          type
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GEOFENCING_CONFIG = gql`
  query GeoFencingConfig($type: GeoFenceConfigType!, $language: Language) {
    geoFenceConfig(type: $type) {
      activeRule
      type
      whitelistExcluded {
        displayName(language: $language)
        id
        lat
        long
        type
      }
      whitelistTarget {
        displayName(language: $language)
        id
        lat
        long
        type
      }
      whitelistExcludedIPs
      whitelistTargetIPs
      blacklistExcluded {
        displayName(language: $language)
        id
        lat
        long
        type
      }
      blacklistTarget {
        displayName(language: $language)
        id
        lat
        long
        type
      }
      blacklistExcludedIPs
      blacklistTargetIPs
    }
  }
`;
