import React, { ReactElement } from 'react';
import { Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { SharedStyledModal } from 'styles/SharedStyledModal';

const StyledColumn: any = styled(Col)`
  display: flex !important;
  justify-content: flex-end !important;
  padding-right: 25px !important;
  ${({ header }: { header: boolean }) =>
    !header &&
    `
    color: #888;  
  `}
  ${({ header }: { header: boolean }) =>
    header &&
    `
    font-weight: 600;  
  `}
`;

const ProfileRow = styled(Row)`
  margin-bottom: 1rem;
`;

const LabelColumn = ({
  children,
  header,
  ...props
}: {
  header?: boolean;
  children: ReactElement;
}) => (
  <StyledColumn span={10} {...props}>
    {children}
  </StyledColumn>
);

const OperatorProfileModal = ({
  userInfo,
  closeModal,
  modalState,
}: {
  userInfo: Record<string, any>;
  closeModal: () => void;
  modalState: boolean;
}) => {
  const {
    username,
    mobileNumber,
    email,
    gender,
    realName,
    role,
    company,
  } = userInfo.creator;
  const { header } = userInfo;

  const isMember = role === 'MEMBER';
  const isAdmin = role === 'ADMIN';
  const isTrigger = header === 'Trigger';

  const creatorUsernameId = () => {
    if (role === 'MEMBER') return 'member-username.text';
    return 'operators-username.text';
  };

  const creatorNameId = () => {
    if (role === 'MEMBER') return 'member-name.text';
    return 'operators-name.text';
  };

  return (
    <SharedStyledModal
      onCancel={closeModal}
      title={
        <FormattedMessage
          id="operators-information.text"
          defaultMessage="Operator’s Information"
        />
      }
      visible={modalState}
      footer={[
        <Button type="primary" key={1} onClick={closeModal}>
          <FormattedMessage id="ok.text" defaultMessage="Ok" />
        </Button>,
      ]}
    >
      <div className="p-3">
        <ProfileRow>
          <LabelColumn>
            <FormattedMessage
              id={isTrigger ? 'brand-name.text' : creatorUsernameId()}
              defaultMessage={"Operator's Username"}
            />
          </LabelColumn>
          <Col span={14}>{isTrigger ? company : username}</Col>
        </ProfileRow>

        <ProfileRow>
          <LabelColumn>
            <FormattedMessage
              id={creatorNameId()}
              defaultMessage={"Operator's Name"}
            />
          </LabelColumn>
          <Col span={14}>{realName || '-'}</Col>
        </ProfileRow>

        <ProfileRow>
          <LabelColumn>
            <FormattedMessage
              id="phone-number.text"
              defaultMessage="Phone Number"
            />
          </LabelColumn>
          <Col span={14}>{isMember ? mobileNumber : '-'}</Col>
        </ProfileRow>

        <ProfileRow>
          <LabelColumn>
            <FormattedMessage id="email.text" defaultMessage="Email" />
          </LabelColumn>
          <Col span={14}>{isMember || isAdmin ? email : '-'}</Col>
        </ProfileRow>

        <ProfileRow>
          <LabelColumn>
            <FormattedMessage id="gender.text" defaultMessage="Gender" />
          </LabelColumn>
          <Col span={14}>{isMember ? gender : '-'}</Col>
        </ProfileRow>
      </div>
    </SharedStyledModal>
  );
};

export default OperatorProfileModal;
