import React from 'react';
import { checkPermission } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import coercedGet from 'utils/coercedGet';
import { showPermissionError } from 'components/Navbar/Navbar';
import { useMemberTab } from 'utils/handleAddTab';
import { ALink } from 'components/ALink/ALink';
import { Deposit } from 'types/graphqlTypes';

type Props = {
  data: Deposit;
};

const MemberProfile: React.FC<Props> = ({ data }) => {
  const addMemberTab = useMemberTab();

  const { role, permissions } = usePermissions();
  const isAllowed = checkPermission(
    role,
    permissions,
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS
  );

  const { account } = data || {};

  const username = coercedGet(data, 'account.username', '-');

  const handleClick = () => {
    if (isAllowed) {
      addMemberTab(account);
    } else {
      showPermissionError('Member Management - View Details');
    }
  };

  return <ALink onClick={handleClick}>{username}</ALink>;
};

export default MemberProfile;
