import React from 'react';
import coercedGet from 'utils/coercedGet';
import { Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import TruncateToolTip from 'components/TruncateToolTip';
import { CLIENT_NAMES } from './query';

type Props = {
  adminIds: string[];
};

const ClientNames: React.FC<Props> = ({ adminIds }) => {
  const { data, loading, error } = useQuery(CLIENT_NAMES, {
    variables: {
      filter: {
        id: {
          in: [...adminIds],
        },
      },
    },
    context: { shouldBatch: true },
  });

  if (loading)
    return (
      <div className="styled-center">
        <Spin />
      </div>
    );
  if (error) {
    return (
      <div className="pl-3">
        <TableErrorCell errorMessage={error.message} />
      </div>
    );
  }

  const edges = coercedGet(data, 'admins.edges', []);

  const getEdges = () => (
    <div>
      {edges.map((edge: any, index: number) => {
        if (index === edges.length - 1)
          return <span>{coercedGet(edge, 'node.username', '-')}</span>;
        return <span>{`${coercedGet(edge, 'node.username', '-')}, `}</span>;
      })}
    </div>
  );

  return edges.length ? (
    <TruncateToolTip maxRows={2} content={getEdges()} />
  ) : (
    <span className="pl-3">-</span>
  );
};

export default ClientNames;
