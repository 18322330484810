import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Radio } from 'antd';
import { Member } from 'types/graphqlTypes';
import ForceWithdrawalPasswordChange from './components/ForceWithdrawalPasswordChange';
import WithdrawalPasswordChange from './components/WithdrawalPasswordChange';
import { StyledModal } from './styles';

type Props = {
  member: Member;
  refetchParams: Record<string, any>;
};

const ChangePasswordMenu = ({ member, refetchParams }: Props) => {
  const [option, setOption] = useState('FORCE_CHANGE');
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const handleChange = (e: Record<string, any>) => {
    const { value } = e.target;
    setOption(value);
  };
  return (
    <>
      <Button type="link" className="text-black" onClick={handleShow} block>
        <FormattedMessage
          id="members.change-withdrawal-password.text"
          defaultMessage="Change Withdrawal Password"
        />
      </Button>
      <StyledModal
        title={
          <FormattedMessage
            id="members.change-withdrawal-password.text"
            defaultMessage="Change Withdrawal Password"
          />
        }
        onCancel={handleShow}
        visible={isShow}
        footer={false}
      >
        <div className="p-3">
          <Radio.Group onChange={handleChange} value={option}>
            <Radio value="FORCE_CHANGE">
              <FormattedMessage
                id="force-withdrawal-password-change.text"
                defaultMessage="Force Withdrawal Password Change"
              />
            </Radio>
            <Radio value="CHANGE">
              <FormattedMessage
                id="change-withdrawal-password.text"
                defaultMessage="Change Withdrawal Password"
              />
            </Radio>
          </Radio.Group>
        </div>
        {option === 'FORCE_CHANGE' && (
          <ForceWithdrawalPasswordChange
            member={member}
            refetchParams={refetchParams}
            onCreated={handleShow}
            onClose={handleShow}
          />
        )}
        {option === 'CHANGE' && (
          <WithdrawalPasswordChange
            member={member}
            refetchParams={refetchParams}
            onCreated={handleShow}
            onClose={handleShow}
          />
        )}
      </StyledModal>
    </>
  );
};

export default ChangePasswordMenu;
