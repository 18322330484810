import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import generateId from 'utils/generateId';
import { get } from 'lodash';
import {
  PAYMENT_TYPES,
  getPaymentType,
  PAYMENT_METHODS_LIST,
} from 'constants/paymentTypes';
import PaymentGatewayForm from './components/PaymentGatewayForm';
import messages from '../../messages';

type Rec = Record<string, any>;

const AddEditWithdrawalMethod = ({
  setRefetchID,
  showFromEdit,
  editDetails = {},
  setShowEdit,
  edit,
}: {
  setRefetchID?: (jac: string) => void;
  showFromEdit?: boolean;
  editDetails?: Rec;
  setShowEdit?: () => void;
  edit: boolean;
}) => {
  const [showModal, toggleModal] = useState(showFromEdit);
  useEffect(() => {
    toggleModal(showFromEdit);
  }, [showFromEdit]);
  const translate = useTranslate();

  const handleModal = () => {
    if (edit) {
      if (setShowEdit && setRefetchID) {
        setShowEdit();
        setRefetchID(editDetails.id);
      }
    } else {
      toggleModal((prevState) => !prevState);
    }
  };

  return (
    <>
      <Button
        onClick={handleModal}
        type="primary"
        style={{
          display: edit ? 'none' : '',
        }}
      >
        <PlusOutlined /> {translate(messages.add)}
      </Button>

      <StyledModal
        title={edit ? get(editDetails, 'name', '') : translate(messages.add)}
        visible={showModal}
        width={700}
        onOk={handleModal}
        onCancel={handleModal}
        footer={false}
        maskClosable={false}
      >
        <PaymentGatewayForm
          isEditing={edit}
          onClose={handleModal}
          createPaymentMethod={() => {}}
          loading={false}
          paymentMethod={{
            id: generateId('wdm'),
            name: '',
            remarks: '',
            enabled: false,
            transactionFee: 0,
            transactionFeeType: 'ABSOLUTE',
            withdrawalProvider: '',
            bank: '',
            // accountNumber: '',
            accountName: '',
            branch: '',
            country: '',
            province: '',
            city: '',
            nickname: '',
            accountNumber:
              editDetails.accountNumber || editDetails.accNumber || '',
            paymentChannel: '',
            ...editDetails,
          }}
          paymentType={getPaymentType(editDetails.__typename)}
          type={
            editDetails.__typename
              ? PAYMENT_METHODS_LIST[editDetails.__typename]
              : PAYMENT_TYPES.OFFLINE.BANK_WITHDRAW
          }
        />
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .menu-header {
    background: #e7e9ed;
  }
  .ant-legacy-form-horizontal {
    .ant-legacy-form-item-label {
      line-height: 40px !important;
    }
  }
`;

export default AddEditWithdrawalMethod;
