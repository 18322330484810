export const ApiErrorCode = {
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INVALID_CREDENTIALS: 'INVALID_CREDENTIALS',
  INVALID_PIN: 'INVALID_PIN',
};

export const getAPIErrorCode = (err: Record<string, any>) => {
  if (!err) return null;
  const { response } = err;
  return response ? response.data.code : null;
};

export default {};
