import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Radio, Divider } from 'antd';
import { useFormik } from 'formik';
import { useLazyQuery } from '@apollo/react-hooks';

import {
  isTypeBank,
  isTypeOfflineBank,
  isTypeAlipay,
  isTypeWechat,
  isTypeUSDT,
  getNewType,
  isPaymentTypeInternal,
  PAYMENT_SOURCES,
} from 'constants/paymentTypes';
import thirdPartyProviders, {
  thirdPartyTypeNames,
} from 'constants/thirdPartyProviders';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import isRow from 'utils/isRow';
import { getValidationSchema } from './validationSchema';
import InternalProviders from './components/InternalProviders';
import ExternalProviders from './components/ExternalProviders';
import BankDetails from './components/BankDetails';
import OtherSettings from './components/OtherSettings';
import LimitSettings from './components/LimitSettings';
import AlipayDetails from './components/AlipayDetails';
import WechatDetails from './components/WechatDetails';
import UsdtDetails from './components/USDTDetails';
import OnlinePaymentChannel from './components/OnlinePaymentChannel';
import ExchangeRateSection from './components/ExchangeRateSection';
import { PROVIDER_INFO, PAYMENT_CHANNELS } from './queries';

import messages from '../../messages';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

type Props = {
  createPaymentMethod: any;
  onClose: () => void;
  loading: boolean;
  paymentMethod: Record<string, any>;
  paymentType: string;
  type: string;
  operation: string;
  isShow?: boolean;
};

const CustomLimitSettings: any = LimitSettings;

const PaymentGatewayForm = ({
  createPaymentMethod,
  onClose,
  loading,
  paymentMethod,
  paymentType,
  type,
  operation,
  isShow,
}: Props) => {
  const translate = useTranslate();
  const [channelOptions, setChannelOptions] = useState([]);

  const formik = useFormik({
    initialValues: {
      ...paymentMethod,
      paymentType,
      type,
    },
    onSubmit: async (formValues: Record<string, any>, { resetForm }) => {
      const {
        id,
        type: newType,
        updateable,
        imageUrl,
        paymentType: newPaymentType,
        ...restValues
      } = formValues;

      const imageCheck = typeof formValues.image;

      const input: Record<string, any> = {
        ...restValues,
        transactionFee: Number(formValues.transactionFee),
        minimumDepositAmount: Number(formValues.minimumDepositAmount),
        maximumDepositAmount: Number(formValues.maximumDepositAmount),
        maximumDepositAmountPerDay: Number(
          formValues.maximumDepositAmountPerDay
        ),
        depositExpiry: Number(formValues.depositExpiry),
        turnoverRequirementMultiplier: Number(
          formValues.turnoverRequirementMultiplier
        ),
        ...(!isRow && {
          exchangeRate: Number(formValues.exchangeRate),
          exchangeRateSpread: Number(formValues.exchangeRateSpread),
          exchangeRateUpdateFrequency:
            formValues.exchangeRateUpdateFrequency +
            formValues.exchangeRateUpdateFrequencyUnit,
        }),
        image: imageCheck === 'object' ? formValues.image.id : formValues.image,
      };

      if (!isTypeOfflineBank(newType)) {
        delete input.bank;
        delete input.accountName;
        delete input.accountNumber;
        delete input.branch;
        delete input.city;
        delete input.country;
        delete input.province;
        delete input.allowUpdateExchangeRate;
        delete input.exchangeRateUpdateFrequencyUnit;
      }

      if (!isTypeUSDT(newType)) {
        delete input.blockchain;
        delete input.blockchainAddress;
        delete input.exchangeRateSource;
        delete input.exchangeRate;
        delete input.exchangeRateSpread;
        delete input.minimumConfirmationsCount;
        delete input.allowUpdateExchangeRate;
        delete input.exchangeRateUpdateFrequency;
        delete input.exchangeRateUpdateFrequencyUnit;
      }
      if (!input.image) delete input.image;

      let variables: any = {
        input,
      };

      if (operation === 'edit') {
        const uniqueFields = ['name', 'accountNumber'];
        if (typeof input.image === 'object' && !input.image.id)
          delete input.image;
        /**
         * Remove from the input variable the 'must be unique fields' that does not
         * have any changes to avoid error upon updating the payment method
         */
        uniqueFields.forEach(
          (field) =>
            input[field] === formik.initialValues[field] && delete input[field]
        );

        variables = {
          input,
          id,
        };
      } else {
        variables = {
          type: newType,
          input,
        };
      }
      try {
        const response = await createPaymentMethod({
          variables,
        });
        if (response && response.data) {
          resetForm();
          onClose();
        }
        // eslint-disable-next-line no-empty
      } finally {
      }
    },
    // enableReinitialize: true,
    validationSchema: getValidationSchema(translate),
  });

  const {
    handleSubmit,
    setFieldValue,
    values,
    errors,
    handleChange,
    handleBlur,
    setValues,
  } = formik;

  const [
    getProviderInfo,
    { data: providerInfoData = {}, loading: loadingProviderInfo },
  ] = useLazyQuery(PROVIDER_INFO, {
    fetchPolicy: 'network-only',
  });

  const [
    getPaymentChannels,
    { data: paymentChannelsData = {}, loading: loadingPaymentChannels },
  ] = useLazyQuery<any>(PAYMENT_CHANNELS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!isShow) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  useEffect(() => {
    // === [Step 1 for Preping Payment Channels Options] ===
    // === note: This is only being used if values.paymentType is ONLINE  ===
    if (values.depositProvider) {
      getProviderInfo({ variables: { id: values.depositProvider } });
    }
  }, [getProviderInfo, operation, values.depositProvider]);

  useEffect(() => {
    // === [Step 2 for Preping Payment Channels Options] ===
    const { MIDPAY_DIRECT, DIORPAY2 } = thirdPartyProviders;
    const { midPayDirect, diorPay2 } = thirdPartyTypeNames;

    const depositType = coercedGet(
      providerInfoData,
      'depositProvider.__typename',
      ''
    );

    if (
      values.depositProvider &&
      (depositType === midPayDirect || depositType === diorPay2)
    ) {
      let input: Record<string, any> = { methodType: 'DEPOSIT' };

      switch (depositType) {
        case midPayDirect:
          input = {
            ...input,
            apiKey: coercedGet(providerInfoData, 'depositProvider.apiKey', ''),
            integrationType: MIDPAY_DIRECT,
          };
          break;

        case diorPay2:
          input = {
            ...input,
            integrationType: DIORPAY2,
          };
          break;

        default:
          throw new Error("Couldn't determine depositType");
      }

      getPaymentChannels({
        variables: {
          input,
        },
      });
    }
  }, [getPaymentChannels, providerInfoData, values.depositProvider]);

  useEffect(() => {
    // === [Step 3(FINAL STEP) for Preping Payment Channels Options] ===
    const paymentGateways = paymentChannelsData.paymentGateways || [];
    const depositType = coercedGet(
      providerInfoData,
      'depositProvider.__typename',
      ''
    );

    if (paymentGateways.length && depositType.length) {
      const { midPayDirect, diorPay2 } = thirdPartyTypeNames;
      let newChannelOptions = [];

      switch (depositType) {
        case midPayDirect: {
          const midpayDirectId = coercedGet(
            providerInfoData,
            'depositProvider.midPayDirectPaymentGateway.id',
            ''
          );
          const paymentGateway = paymentGateways.find(
            (gateway: Record<string, any>) => gateway.id === midpayDirectId
          );
          newChannelOptions = paymentGateway.paymentChannels;
          break;
        }

        case diorPay2: {
          const compileChannels = (
            formattedChannels: any[],
            gateway: Record<string, any>
          ) => {
            const newFormatChannels = gateway.paymentChannels.map(
              (paymentChannel: string) => ({
                paymentType: gateway.name,
                id: paymentChannel,
              })
            );

            return [...formattedChannels, ...newFormatChannels];
          };

          newChannelOptions = paymentGateways.reduce(compileChannels, []);
          break;
        }

        default:
          throw new Error("Couldn't determine depositType");
      }
      setChannelOptions(newChannelOptions);
    }
  }, [
    providerInfoData,
    paymentChannelsData.paymentGateways,
    setChannelOptions,
  ]);

  return (
    <Form onSubmit={handleSubmit} data-testid="payment-gateway-form">
      <div className="p-6">
        <Form.Item
          labelCol={{ span: 8 }}
          className="mb-1"
          label={<strong>{translate(messages.accountinfo)}</strong>}
          colon={false}
        />

        <Form.Item
          htmlFor="name"
          label={translate(messages.name)}
          validateStatus={errors.name ? 'error' : ''}
          help={errors.name || null}
          data-testid="accoutNameError"
          {...formItemLayout}
        >
          <Input
            id="name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            placeholder={translate(messages.name)}
          />
        </Form.Item>

        <Form.Item label={translate(messages.source)} {...formItemLayout}>
          <Radio.Group
            onChange={(event) => {
              setValues({
                ...values,
                depositProvider: isPaymentTypeInternal(event.target.value)
                  ? ''
                  : values.depositProvider,
                type: getNewType(values.type),
              });
              handleChange(event);
            }}
            value={values.paymentType}
            name="paymentType"
            disabled={operation === 'edit'}
          >
            <Radio value="OFFLINE">{translate(messages.internal)}</Radio>
            <Radio value="ONLINE">{translate(messages.gateway)}</Radio>
          </Radio.Group>
        </Form.Item>
        {!isPaymentTypeInternal(values.paymentType) && (
          <ExternalProviders
            values={values}
            setValues={setValues}
            formItemLayout={formItemLayout}
            errors={errors}
            getProviderInfo={getProviderInfo}
          />
        )}
        <Form.Item
          label={translate(messages.method)}
          validateStatus={errors.type ? 'error' : ''}
          help={errors.type || null}
          {...formItemLayout}
        >
          <InternalProviders
            value={values.type}
            paymentType={values.paymentType}
            name="type"
            paymentChannels={channelOptions}
            providerType={coercedGet(
              providerInfoData,
              'depositProvider.__typename',
              ''
            )}
            validatingChannels={loadingPaymentChannels || loadingProviderInfo}
            disabled={
              (operation === 'edit' &&
                values.paymentType === PAYMENT_SOURCES.OFFLINE) ||
              (values.paymentType === PAYMENT_SOURCES.ONLINE &&
                !values.depositProvider)
            }
            handleChange={(event: Record<string, any>) => {
              if (isTypeBank(event.target.value)) {
                setValues({
                  ...values,
                  image: '',
                  nickname: '',
                });
              } else {
                setValues({
                  ...values,
                  bank: '',
                  accountNumber: '',
                  accountName: '',
                  branch: '',
                  country: '',
                  city: '',
                  province: '',
                  paymentChannel: '',
                });
              }
              handleChange(event);
            }}
          />
        </Form.Item>
        {isTypeBank(values.type) &&
          isPaymentTypeInternal(values.paymentType) && (
            <BankDetails
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              formItemLayout={formItemLayout}
            />
          )}
        {isTypeWechat(values.type) &&
          isPaymentTypeInternal(values.paymentType) && (
            <WechatDetails
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              formItemLayout={formItemLayout}
              setFieldValue={setFieldValue}
            />
          )}
        {isTypeAlipay(values.type) &&
          isPaymentTypeInternal(values.paymentType) && (
            <AlipayDetails
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              errors={errors}
              formItemLayout={formItemLayout}
              setFieldValue={setFieldValue}
            />
          )}
        {isTypeUSDT(values.type) && isPaymentTypeInternal(values.paymentType) && (
          <>
            <UsdtDetails
              paymentType={values.paymentType}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              values={values}
              errors={errors}
              formItemLayout={formItemLayout}
            />
            {!isRow && (
              <ExchangeRateSection
                setFieldValue={setFieldValue}
                values={values}
                handleChange={handleChange}
                formItemLayout={formItemLayout}
                handleBlur={handleBlur}
                errors={errors}
              />
            )}
          </>
        )}
        {!isPaymentTypeInternal(values.paymentType) && (
          <OnlinePaymentChannel
            depositProviderId={values.depositProvider}
            paymentType={values.type}
            value={values.paymentChannel}
            setFieldValue={setFieldValue}
            formItemLayout={formItemLayout}
            errors={errors}
            presetPaymentChannels={channelOptions}
            loadingProviderInfo={loadingProviderInfo}
            providerInfoData={coercedGet(
              providerInfoData,
              'depositProvider',
              {}
            )}
          />
        )}
        <Divider />
        <CustomLimitSettings
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          formItemLayout={formItemLayout}
          setFieldValue={setFieldValue}
          disabled={!values.allowCustomAmount}
        />
        <Divider />
        <OtherSettings
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          formItemLayout={formItemLayout}
        />
      </div>
      <div className="bt-1 p-4 text-right">
        <Button className="mr-2" onClick={onClose} disabled={loading}>
          {translate(messages.cancel)}
        </Button>{' '}
        <Button type="primary" htmlType="submit" loading={loading}>
          {translate(messages.confirm)}
        </Button>
      </div>
    </Form>
  );
};

export default PaymentGatewayForm;
