import React, { useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { get, flatten, uniqBy, isEmpty } from 'lodash';
import { truncateProgramName } from 'utils/truncateProgramName';
import { Select, Tag } from 'antd';
import { MEMBER_LOYALTY_LEVELS } from './queries';

const { Option } = Select;

type Props = {
  onChange: (e: Array<string> | string) => void;
  value: Array<string> | string;
  placeHolder?: string;
  disabled?: boolean;
  multiple?: boolean;
  labelAsValue?: boolean;
  forRebates?: boolean;
};

type Edge = {
  node: { value: string; label: string };
};

type Level = {
  id: string;
  name: string;
  color: string;
  programme: {
    id: string;
    name: string;
  };
};

type MemberLoyaltyProgram = {
  id: string;
  name: string;
  levels: Array<Level>;
};

const MemberLoyaltyLevelsSelect: React.FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
  labelAsValue,
  forRebates,
}) => {
  const [filter, setFilter] = useState({
    name: {
      contains: '',
    },
    status: {
      in: ['ACTIVE', 'INACTIVE'],
    },
  });
  const defaultMembers = useRef([]) as any;
  const [opts, setOpts] = useState({}) as any;

  const { loading, refetch } = useQuery(MEMBER_LOYALTY_LEVELS, {
    variables: {
      filter,
      first: 10,
      filterProgrammes: filter,
      filterLevels: filter,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const memberLoyaltyLevels = coercedGet(
        data.memberLoyaltyLevels,
        'edges',
        []
      ).map((edge: Edge) => edge.node);

      const memberLoyaltyProgrammes = coercedGet(
        data.memberLoyaltyProgrammes,
        'edges',
        []
      ).map((edge: Edge) => edge.node);

      const memberLoyaltyToBeMerged = flatten(
        memberLoyaltyProgrammes.map(
          (memberLoyaltyProgram: MemberLoyaltyProgram) =>
            memberLoyaltyProgram.levels.map((level: Level) => ({
              id: level.id,
              name: level.name,
              color: level.color,
              programme: {
                id: memberLoyaltyProgram.id,
                name: memberLoyaltyProgram.name,
              },
            }))
        )
      );

      const combinedMemberLoyalty = uniqBy(
        [...memberLoyaltyLevels, ...memberLoyaltyToBeMerged],
        'id'
      ).map((level) => {
        const programmeName = truncateProgramName(level.programme.name).text;
        const shortName = `${programmeName} - ${level.name}`;
        const fullName = `${level.programme.name || ''} - ${level.name || ''}`;

        return { ...level, fullName, shortName };
      });

      setOpts({
        memberLoyaltyLevels: combinedMemberLoyalty,
      });
    },
  });

  const tagRender = (props: any) => {
    const { value: id, onClose } = props;
    const item = opts.memberLoyaltyLevels?.find((opt: Level) => opt.id === id);

    if (!item) return <></>;

    return (
      <Tag
        closable
        onClose={onClose}
        color={item.color}
        style={{ margin: 3, borderRadius: 11 }}
      >
        {item.shortName}
      </Tag>
    );
  };

  const handleSearch = (e: string) => {
    setFilter({
      name: {
        contains: e,
      },
      status: {
        in: ['ACTIVE', 'INACTIVE'],
      },
    });
  };

  const handleChange = (e: any) => {
    onChange(e);
  };

  useEffect(() => {
    if (filter) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const hasMemberLoyalty = !isEmpty(get(opts, 'memberLoyaltyLevels', []));

  if (value && value.length) {
    const createLevelObject = (id: string) => {
      if (hasMemberLoyalty) {
        return opts.memberLoyaltyLevels.find((level: Level) => level.id === id);
      }
      return {};
    };

    if (Array.isArray(value)) {
      value.forEach((id: string) => {
        defaultMembers.current = [
          ...defaultMembers.current,
          createLevelObject(id),
        ];
      });
    }
  }

  const options: React.ReactNode =
    hasMemberLoyalty &&
    opts.memberLoyaltyLevels.map((memberLoyaltyLevel: any) => (
      <Option
        data-testid={`vipFilter_${memberLoyaltyLevel.id}`}
        key={memberLoyaltyLevel.id}
        title={forRebates ? memberLoyaltyLevel.fullName : memberLoyaltyLevel.id}
        value={
          labelAsValue ? memberLoyaltyLevel.fullName : memberLoyaltyLevel.id
        }
      >
        {memberLoyaltyLevel.shortName}
      </Option>
    ));

  return (
    <Select
      data-testid="vipLevelFilter"
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      loading={loading}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled || false}
      allowClear
      tagRender={tagRender}
      style={{ width: '100%' }}
    >
      {options}
    </Select>
  );
};

export default MemberLoyaltyLevelsSelect;
