import React from 'react';
import { useMemberTab } from 'utils/handleAddTab';
import { ALink } from 'components/ALink/ALink';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import { showPermissionError } from 'components/Navbar/Navbar';

const MemberProfile = ({ member }: { member: Record<string, any> }) => {
  const addMemberTab = useMemberTab();

  const { role, permissions } = usePermissions();

  const { ALLOWED_VIEW_DETAILS } = collectPermissions(
    role,
    permissions,
    ['VIEW_DETAILS'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const handleClick = () => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('Member Management - View Details');
      return;
    }

    addMemberTab(member);
  };

  return <ALink onClick={handleClick}>{member.username}</ALink>;
};

export default MemberProfile;
