import React from 'react';
import { useIntl } from 'react-intl';
import RequestUpdateToggle from 'components/RequestUpdateToggle';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import { Divider } from 'antd';
import { customFormatMessage } from 'utils/customFormatMessage';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import messages from 'messages';
import { MemberAccessSummaryReportItemsFilterInput } from 'types/graphqlTypes';
import SpreadsheetDownload from 'components/SpreadsheetDownload';

type Props = {
  withRealtime?: boolean;
  isRealtime?: boolean;
  handleSetRealtime?: () => void;
  refreshPage: () => void;
  filters: MemberAccessSummaryReportItemsFilterInput;
};

const HeaderRight: React.FC<Props> = ({
  handleSetRealtime,
  isRealtime,
  withRealtime = true,
  refreshPage,
  filters,
}) => {
  const { formatMessage } = useIntl();
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  return (
    <>
      {withRealtime && (
        <RequestUpdateToggle
          checked={isRealtime}
          onChange={handleSetRealtime}
        />
      )}
      <RefreshButton onClick={() => refreshPage()} />
      <Divider type="vertical" className="ml-0 mr-2" />
      <ColumnsFilterNew iconOnly tabId="member-access-summary-report" />
      <SpreadsheetDownload
        iconOnly
        filter={filters}
        filename={translate(messages.MEMBER_ACCESS_SUMMARY_REPORT)}
        extension="csv"
      />
    </>
  );
};

export default HeaderRight;
