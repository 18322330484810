import { useMutation, useQuery } from '@apollo/react-hooks';
import { Col, Input, Row, Spin } from 'antd';
import Circle from 'components/Circle';
import ALL_PERMISSIONS from 'constants/permissions3';
import { DEPOSIT_REQUEST_EXTERNAL } from 'constants/testIds';
import { UPDATE_DEPOSIT } from 'graphql/mutations/deposit.mutation';
import { DEPOSIT, DEPOSIT_REQUESTS } from 'graphql/queries/deposit.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import globalMessages from 'messages';
import React, { useState } from 'react';
import { usePermissions } from 'store/accountState';
import { getStatusColor } from 'utils/depositWithdrawalStatus';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import ApproveDeposit from '../ApproveDeposit';
import RejectDeposit from '../RejectDeposit';
import MemberInformation from './components/MemberInformation';
import PaymentMethodDetails from './components/PaymentMethodDetails';
import { StyledModal } from './styles';

const updatableRequestStatus = ['PENDING', 'PROCESSING'];

const isReadOnly = (status: string) =>
  ['APPROVED', 'REJECTED', 'EXPIRED'].includes(status);

const { TextArea } = Input;

const DepositDetails = (props: {
  id: string;
  toggleModal: Function;
  refetchVariables: Record<string, any>;
}) => {
  const { role, permissions } = usePermissions();
  const translate = useTranslate();

  const {
    ALLOWED_PROCESS,
    ALLOWED_APPROVE,
    ALLOWED_REJECT,
    ALLOWED_UPDATE_REMARKS,
  } = collectPermissions(
    role,
    permissions,
    ['APPROVE', 'PROCESS', 'REJECT', 'UPDATE_REMARKS'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_EXTERNAL_DEPOSITS
  );

  const { toggleModal, id, refetchVariables } = props;
  const [state, setState] = useState<Record<string, any>>({
    remarks: '',
    remarksError: '',
    timeout: (e: any) => e,
  });

  const setRemarksError = (error = '') => {
    setState({
      ...state,
      remarksError: error,
    });
  };

  const refetchQueries = [
    {
      query: DEPOSIT,
      variables: { id },
    },
    {
      query: DEPOSIT_REQUESTS,
      variables: refetchVariables,
    },
  ];

  const [updateDeposit] = useMutation(UPDATE_DEPOSIT, {
    variables: { id, remarks: state.remarks },
    refetchQueries,
  });

  const { loading, error, data = {} } = useQuery(DEPOSIT, {
    variables: { id },
    fetchPolicy: 'network-only',
    onCompleted: (result: Record<string, any>) => {
      if (result && result?.deposit) {
        setState({ ...state, remarks: result.deposit.remarks });
      }
    },
  });

  const { deposit = {} } = data;
  const { status, serialCode } = deposit;
  const readOnly = isReadOnly(status);

  return (
    <>
      <StyledModal
        visible
        readOnly={readOnly}
        onOk={toggleModal}
        onCancel={toggleModal}
        width={700}
        footer={
          updatableRequestStatus.includes(status)
            ? [
                ALLOWED_PROCESS && ALLOWED_APPROVE && (
                  <ApproveDeposit
                    key="approveDeposit"
                    {...props}
                    refetchQueries={refetchQueries}
                    setRemarksError={setRemarksError}
                  />
                ),
                ALLOWED_PROCESS && ALLOWED_REJECT && (
                  <RejectDeposit
                    key="rejectDeposit"
                    {...props}
                    refetchQueries={refetchQueries}
                    remarks={state.remarks}
                    setRemarksError={setRemarksError}
                  />
                ),
              ]
            : null
        }
      >
        <div data-testid={DEPOSIT_REQUEST_EXTERNAL.detailsModal}>
          {error && (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <span className="text-danger">
                {translate(globalMessages['fatal-error.text'])}
              </span>
            </div>
          )}
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <Spin />
            </div>
          ) : (
            !error && (
              <>
                <div className="p-3 bb-1 d-flex align-items-center">
                  <h4 className="mb-0 mr-3">
                    {translate(messages.depositRequestDetails)}
                  </h4>
                  <div>
                    {translate(messages.serialCode)}: {serialCode}
                  </div>
                  <span
                    className="ml-4"
                    style={{ display: 'flex', alignItems: 'center' }}
                    data-testid="status"
                  >
                    <Circle size={15} color={getStatusColor(status)} filled />{' '}
                    {translate(messages[`${status}`])}
                  </span>
                </div>
                <Row>
                  <Col span={12} className="br-1">
                    <div className="p-3">
                      <MemberInformation
                        deposit={deposit}
                        readOnly={readOnly}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="p-3">
                      <div className="menu-header p-2">
                        {translate(messages.operatorRemark)}
                      </div>
                      <TextArea
                        placeholder={translate(messages.operatorRemark)}
                        disabled={!ALLOWED_UPDATE_REMARKS || readOnly}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        className="remarks-area mt-2"
                        value={state.remarks}
                        onChange={(e) =>
                          setState({
                            ...state,
                            remarks: e.target.value || '',
                          })
                        }
                        onKeyUp={() => {
                          clearTimeout(state?.timeout);
                          setState({
                            ...state,
                            timeout: setTimeout(() => updateDeposit(), 500),
                          });
                        }}
                      />
                      {state.remarksError && (
                        <small className="text-error">
                          {state.remarksError}
                        </small>
                      )}
                      <PaymentMethodDetails
                        deposit={deposit}
                        readOnly={readOnly}
                      />
                    </div>
                  </Col>
                </Row>
              </>
            )
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default DepositDetails;
