import { Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

export const StyledOption = styled(Option)<{ color?: string }>``;

const itemStyling = (value: any) =>
  value.reduce(
    (acc: string, item: any) => `${acc}
          &[title='${item.id}'] {
            position: relative;
            background-color: ${item.color};
            border: 1px solid ${item.color};
            border-radius: 11px;
            color: #fff;

            .anticon-close {
              color: #fff;;
            }
          }
  `,
    ''
  );

export const StyledSelect = styled(Select)<{ activeItems: any }>`
  width: 100%;

  .ant-select-selection {
    border-radius: 0;
  }
  .ant-select-selection__rendered {
    margin-left: 11px;
  }

  .ant-select-selection-selected-value {
    font-size: 12px;
    margin-top: 3px !important;
    height: 24px !important;
    line-height: 22px !important;
    padding: 0 15px;
    ${({ activeItems }: any) => (activeItems ? itemStyling(activeItems) : '')}
  }

  .ant-select-selection li.ant-select-selection__choice {
    ${({ activeItems }: any) => (activeItems ? itemStyling(activeItems) : '')}
  }
`;
