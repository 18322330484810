import styled from 'styled-components';

export const StyledTagContainer = styled.div<any>`
  flex: 1;
  display: flex;
  overflow: hidden;
  max-height: ${({ isExpanded }) => (isExpanded ? 'unset' : '37px')};

  .cursor-pointer {
    margin-top: 0.7rem;
  }

  .clear-all {
    margin-top: 0.5rem;
  }

  .ant-tag {
    margin: 5px;
  }
`;

export default {};
