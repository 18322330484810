import { Table } from 'antd';
import { CustomGeofencingTag } from 'pages/components/GeoFencing/components/CustomGeofencingTag/CustomGeofencingTag';
import React from 'react';
import { GeoFenceConfig, Place } from 'types/graphqlTypes';

type Props = {
  geoFenceConfig: GeoFenceConfig | undefined;
  loading: boolean;
};

export default ({ geoFenceConfig, loading }: Props) => {
  const {
    blacklistExcluded,
    blacklistTarget,
    blacklistTargetIPs,
    blacklistExcludedIPs,
    whitelistExcluded,
    whitelistExcludedIPs,
    whitelistTarget,
    whitelistTargetIPs,
  } = geoFenceConfig || {};

  const geoFenceData = [
    {
      header: 'country-and-city.text',
      blacklistTarget,
      blacklistExcluded,
      whitelistTarget,
      whitelistExcluded,
    },
    {
      header: 'ip.text',
      blacklistTarget: blacklistTargetIPs,
      blacklistExcluded: blacklistExcludedIPs,
      whitelistTarget: whitelistTargetIPs,
      whitelistExcluded: whitelistExcludedIPs,
    },
  ];

  const columns = [
    {
      title: 'WHITELIST',
      children: [
        {
          key: 'whitelistTarget',
          title: 'Target',
          align: 'center',
          dataIndex: 'whitelistTarget',
          width: '25%',
          render: (record: Place[], allData: Record<string, any>) => (
            <CustomGeofencingTag tags={record} header={allData.header} />
          ),
        },
        {
          key: 'whitelistExcluded',
          title: 'Excluded',
          align: 'center',
          dataIndex: 'whitelistExcluded',
          width: '25%',
          render: (record: Place[], allData: Record<string, any>) => (
            <CustomGeofencingTag tags={record} header={allData.header} />
          ),
        },
      ],
    },
    {
      title: 'BLACKLIST',
      children: [
        {
          key: 'blacklistTarget',
          title: 'Target',
          align: 'center',
          dataIndex: 'blacklistTarget',
          width: '25%',
          render: (record: Place[], allData: Record<string, any>) => (
            <CustomGeofencingTag tags={record} header={allData.header} />
          ),
        },
        {
          key: 'blacklistExcluded',
          title: 'Excluded',
          align: 'center',
          dataIndex: 'blacklistExcluded',
          width: '25%',
          render: (record: Place[], allData: Record<string, any>) => (
            <CustomGeofencingTag tags={record} header={allData.header} />
          ),
        },
      ],
    },
  ];

  return (
    <>
      <Table
        bordered
        dataSource={geoFenceData}
        loading={loading}
        columns={columns}
        pagination={false}
      />
    </>
  );
};
