import React, { useState, useEffect } from 'react';
import { useFilterValues } from 'contexts/Filters';
import { Tag } from 'antd';
import coercedGet from 'utils/coercedGet';
import messages from 'messages';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';
import { isEmpty } from 'lodash';

import useTranslate from 'utils/useTranslate';
import { DATE_FORMAT } from 'constants/date';

type PreventDefault = {
  preventDefault: () => void;
};

const FilterConditions = () => {
  const { filters, onFilterChange } = useFilterValues();

  const [localStateFilter, setLocalStateFilter] = useState<Record<string, any>>(
    {}
  );

  useEffect(() => {
    const transformedDateFilters = transformMovableDateFilter(filters);
    setLocalStateFilter(transformedDateFilters || {});
  }, [filters]);

  const handleRemoveFilterTag = (
    name: string,
    value: string,
    e: PreventDefault
  ) => {
    e.preventDefault();
    const field = coercedGet(filters, name, []);
    const data = field.in.filter((item: string) => item !== value);
    const newField = {
      in: data,
    };
    onFilterChange({
      ...filters,
      [`${name}`]: data.length ? newField : null,
    });
  };

  const handleRemoveWithdrawalFilterTag = (
    name: string,
    value: string,
    e: PreventDefault
  ) => {
    e.preventDefault();
    const field = coercedGet(filters, name, []);
    const data = field.includesAny.filter((item: string) => item !== value);
    const newField = {
      includesAny: data,
    };
    onFilterChange({
      ...filters,
      [`${name}`]: data.length ? newField : null,
    });
  };

  const handleRemoveAmountTag = (
    name: string,
    range: string,
    e: PreventDefault
  ) => {
    e.preventDefault();
    const data = { ...filters[`${name}`] };
    delete data[`${range}`];
    onFilterChange({
      ...filters,
      [`${name}`]: isEmpty(data) ? null : data,
    });
  };

  const handleRemoveDate = (name: string, e: PreventDefault) => {
    e.preventDefault();

    onFilterChange({
      ...filters,
      [`${name}`]: null,
    });
  };
  const translate = useTranslate();

  return (
    <>
      {filters.serialCode
        ? filters.serialCode.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('serialCode', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.transactionSerialCode
        ? filters.transactionSerialCode.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) =>
                handleRemoveFilterTag('transactionSerialCode', item, e)
              }
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.transactionType
        ? filters.transactionType.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('transactionType', item, e)}
            >
              {translate(messages[item])}
            </Tag>
          ))
        : null}
      {filters.originalAmount && filters.originalAmount.gte ? (
        <Tag
          closable
          onClose={(e) => handleRemoveAmountTag('originalAmount', 'gte', e)}
        >
          {translate(messages['minimum-original-amount.text'])}:{' '}
          {filters.originalAmount.gte}
        </Tag>
      ) : null}
      {filters.originalAmount && filters.originalAmount.lte ? (
        <Tag
          closable
          onClose={(e) => handleRemoveAmountTag('originalAmount', 'lte', e)}
        >
          {translate(messages['maximum-original-amount.text'])}:{' '}
          {filters.originalAmount.lte}
        </Tag>
      ) : null}
      {filters.currentAmount && filters.currentAmount.gte ? (
        <Tag
          closable
          onClose={(e) => handleRemoveAmountTag('currentAmount', 'gte', e)}
        >
          {translate(messages['minimum-current-amount.text'])}:{' '}
          {filters.currentAmount.gte}
        </Tag>
      ) : null}
      {filters.currentAmount && filters.currentAmount.lte ? (
        <Tag
          closable
          onClose={(e) => handleRemoveAmountTag('currentAmount', 'lte', e)}
        >
          {translate(messages['maximum-current-amount.text'])}:{' '}
          {filters.currentAmount.lte}
        </Tag>
      ) : null}
      {filters.turnoverRequirementMultiplier
        ? filters.turnoverRequirementMultiplier.in.map(
            (item: string, key: number) => (
              <Tag
                key={key}
                closable
                onClose={(e) =>
                  handleRemoveFilterTag(
                    'turnoverRequirementMultiplier',
                    item,
                    e
                  )
                }
              >
                {`x${item}`}
              </Tag>
            )
          )
        : null}
      {filters.turnoverRequirement && filters.turnoverRequirement.gte ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveAmountTag('turnoverRequirement', 'gte', e)
          }
        >
          {translate(messages['minimum-turnover-requirement.text'])}:{' '}
          {filters.turnoverRequirement.gte}
        </Tag>
      ) : null}
      {filters.turnoverRequirement && filters.turnoverRequirement.lte ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveAmountTag('turnoverRequirement', 'lte', e)
          }
        >
          {translate(messages['maximum-turnover-requirement.text'])}:{' '}
          {filters.turnoverRequirement.lte}
        </Tag>
      ) : null}
      {filters.turnoverRequirementBalance &&
      filters.turnoverRequirementBalance.gte ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveAmountTag('turnoverRequirementBalance', 'gte', e)
          }
        >
          {translate(messages['minimum-turnover-balance.text'])}:{' '}
          {filters.turnoverRequirementBalance.gte}
        </Tag>
      ) : null}
      {filters.turnoverRequirementBalance &&
      filters.turnoverRequirementBalance.lte ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveAmountTag('turnoverRequirementBalance', 'lte', e)
          }
        >
          {translate(messages['maximum-turnover-balance.text'])}:{' '}
          {filters.turnoverRequirementBalance.lte}
        </Tag>
      ) : null}
      {localStateFilter.dateTimeCreated ? (
        <Tag closable onClose={(e) => handleRemoveDate('dateTimeCreated', e)}>
          {translate(messages.CREATION_DATE)}:{' '}
          {formatDate(localStateFilter.dateTimeCreated.gte, DATE_FORMAT)}~
          {formatDate(localStateFilter.dateTimeCreated.lte, DATE_FORMAT)}
        </Tag>
      ) : null}
      {localStateFilter.dateTimeTurnoverCompleted ? (
        <Tag
          closable
          onClose={(e) => handleRemoveDate('dateTimeTurnoverCompleted', e)}
        >
          {translate(messages.COMPLETION_DATE)}:{' '}
          {formatDate(
            localStateFilter.dateTimeTurnoverCompleted.gte,
            DATE_FORMAT
          )}
          ~
          {formatDate(
            localStateFilter.dateTimeTurnoverCompleted.lte,
            DATE_FORMAT
          )}
        </Tag>
      ) : null}
      {filters.betCount && filters.betCount.gte ? (
        <Tag
          closable
          onClose={(e) => handleRemoveAmountTag('betCount', 'gte', e)}
        >
          {translate(messages['minimum-bets.text'])}: {filters.betCount.gte}
        </Tag>
      ) : null}
      {filters.betCount && filters.betCount.lte ? (
        <Tag
          closable
          onClose={(e) => handleRemoveAmountTag('betCount', 'lte', e)}
        >
          {translate(messages['maximum-bets.text'])}: {filters.betCount.lte}
        </Tag>
      ) : null}
      {filters.withdrawals
        ? filters.withdrawals.includesAny.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) =>
                handleRemoveWithdrawalFilterTag('withdrawals', item, e)
              }
            >
              {item}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
