import React from 'react';
import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }
  .ant-table-tbody {
    .column-number {
      text-align: right !important;
    }
  }
  .react-resizable {
    position: relative;
    background-clip: padding-box;
    border-right: 1px solid #e8e8e8;
  }
  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
  }
`;

const StyledColorDiv = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 4px;
  background-color: ${(props) => props.color || '#efefef'};
  margin-right: 2px;
`;

// eslint-disable-next-line react/prop-types
export const StyledColor = ({ color }: { color: string }) =>
  color ? <StyledColorDiv color={color} /> : <></>;

export default {};
