import React, { useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { FormattedMessage } from 'react-intl';
import { useFormikContext } from 'formik';

import { getTierRequirement } from '../../../../../../../../constants';

const DeleteTier = ({ index }: { index: number }) => {
  const { values, setFieldValue } = useFormikContext() as any;
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const { levels } = values;

  return (
    <>
      <ALink onClick={handleShow}>
        <FormattedMessage id="delete.text" defaultMessage="Delete" />
      </ALink>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          onCancel={handleShow}
          onOk={() => {
            const lookup =
              values.tierRequirement.length === 2
                ? values.tierRequirementOperator
                : values.tierRequirement[0];

            const { forActiveMembers, forNetProfit } = getTierRequirement(
              lookup
            );

            let providedLevels = [];

            const newFilteredLevels = levels.filter(
              (_: string, idx: number) => idx !== index
            );

            providedLevels = newFilteredLevels;
            if (newFilteredLevels.length) {
              const newMappedLevels = newFilteredLevels.map(
                (l: Record<string, any>, indx: number) => {
                  if (indx !== newFilteredLevels.length - 1) {
                    return {
                      ...l,
                      ...(forActiveMembers && {
                        maximumActiveMembers:
                          newFilteredLevels[indx + 1].minimumActiveMembersCount,
                      }),
                      ...(forNetProfit && {
                        maximumNetProfit:
                          newFilteredLevels[indx + 1].minimumNetProfit,
                      }),
                    };
                  }
                  return l;
                }
              );
              providedLevels = newMappedLevels;
            }

            setFieldValue('levels', providedLevels);
            handleShow();
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-delete.text"
                defaultMessage="Confirm Delete"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteTier;
