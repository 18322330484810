import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'rebates.game-type.text': {
    id: 'rebates.game-type.text',
    defaultMessage: 'Game type',
  },
  'rebates.game-vendor.text': {
    id: 'rebates.game-vendor.text',
    defaultMessage: 'Game vendor',
  },
  'rebates.game-name.text': {
    id: 'rebates.game-name.text',
    defaultMessage: 'Game name',
  },
  'common.slot.text': {
    id: 'common.slot.text',
    defaultMessage: 'Slot',
  },
  'common.live.text': {
    id: 'common.live.text',
    defaultMessage: 'Live',
  },
  'common.poker.text': {
    id: 'common.poker.text',
    defaultMessage: 'Poker',
  },
  'common.fish.text': {
    id: 'common.fish.text',
    defaultMessage: 'Fish',
  },
  'common.sportsbook.text': {
    id: 'common.sportsbook.text',
    defaultMessage: 'Sportbook',
  },
  'common.lottery.text': {
    id: 'common.lottery.text',
    defaultMessage: 'Lottery',
  },
  'search.text': {
    id: 'search.text',
    defaultMessage: 'Search',
  },
});

export default messages;
