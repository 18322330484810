import React from 'react';
import { checkPermission } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { showPermissionError } from 'components/Navbar/Navbar';
import { useMemberTab } from 'utils/handleAddTab';
import { ALink } from 'components/ALink/ALink';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import coercedGet from 'utils/coercedGet';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';
import { useApolloClient } from '@apollo/react-hooks';
import esGet from 'utils/esGet';
import { WITHDRAWAL_MEMBER } from './query';

interface Props {
  withdrawalId: string;
}

const MemberProfile = ({ withdrawalId }: Props) => {
  const addMemberTab = useMemberTab();
  const client = useApolloClient();

  const onLoadMember = async () => {
    const response = await client.query({
      query: WITHDRAWAL_MEMBER,
      fetchPolicy: 'network-only',
      variables: {
        id: withdrawalId,
      },
    });
    const dataMember = esGet(response?.data?.withdrawal?.account, {});

    addMemberTab(dataMember);
  };
  const { role, permissions } = usePermissions();

  const isAllowed = checkPermission(
    role,
    permissions,
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS
  );

  const { usernameLoaderSet } = useLoaders();

  const [useUsernameLoader] = usernameLoaderSet;

  const { loading, error, data = {} } = useUsernameLoader(withdrawalId);

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;

  const username = coercedGet(data, 'account.username', '');

  const handleClick = () => {
    if (isAllowed) {
      onLoadMember();
    } else {
      showPermissionError('Member Management - View Details');
    }
  };

  return <ALink onClick={handleClick}>{username}</ALink>;
};

export default MemberProfile;
