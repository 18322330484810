import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button } from 'antd';
import { Formik } from 'formik';
import MemberLevelPreviousNew from 'components/MemberLevelPreviousNew';
import { FormattedMessage } from 'react-intl';
import { StyledForm } from './styles';
import validationSchema from './validationSchema';

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (e: any) => void;
  onClose: () => void;
  isLoading: boolean;
};

const EditMemberLevelMenuForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: Props) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({ handleSubmit, setFieldValue, values, errors, touched }) => (
      <StyledForm onSubmit={handleSubmit}>
        <div className="p-3">
          <Form.Item
            label={
              <FormattedMessage
                id="member-marker.text"
                defaultMessage="Member marker"
              />
            }
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            validateStatus={
              errors.memberLevel && touched.memberLevel ? 'error' : ''
            }
            help={touched.memberLevel ? errors.memberLevel : null}
          >
            <MemberLevelPreviousNew
              users={values.users}
              onUserSelected={(e) => setFieldValue('userSelected', e)}
              onChange={(e) => setFieldValue('memberLevel', e)}
            />
          </Form.Item>
        </div>

        <div className="bt-1 p-3 text-right">
          <Button className="mr-2" onClick={onClose} loading={isLoading}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            role="button"
          >
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </div>
      </StyledForm>
    )}
  </Formik>
);

export default EditMemberLevelMenuForm;
