import { flowRight } from 'lodash';

export const logResult = (label: string) => (e: any) => {
  if (label) {
    // eslint-disable-next-line no-console
    console.log({ [label]: e });
    return e;
  }
  // eslint-disable-next-line no-console
  console.log(e);
  return e;
};

export default (...rest: any[]) => flowRight([...rest]);
