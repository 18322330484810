import gql from 'graphql-tag';

export const STARRED_MEMBERS = gql`
  query StarredMembers($filter: MembersFilterInput) {
    members(filter: $filter) {
      edges {
        node {
          id
          username
          starred
          realName
          registrationDateTime
          watchlistRegistrationDateTime
          memberLevel {
            id
            name
            color
          }
        }
      }
    }
  }
`;

export const MEMBER_LEVELS = gql`
  query MemberLevels {
    memberLevels {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default {};
