import gql from 'graphql-tag';

export const LAST_PLAYED_GAMES = gql`
  query LastPlayedGames($id: ID!) {
    member(id: $id) {
      id
      playedGames(first: 3) {
        totalCount
        edges {
          node {
            id
            game {
              id
              type
              name
            }
            lastMemberBetRecord {
              id
              serialCode
              gameType
              winloss
              dateTimePlaced
              game {
                id
                type
                name
              }
              vendor {
                id
                type
                name
                shortcode
              }
            }
          }
        }
      }
    }
  }
`;
