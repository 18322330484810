import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import Filters from './components/Filters';
import SiderContent from './components/SiderContent';
import { CostSettingsSider, Divider } from './styles';
import VendorContent from './components/VendorContent';
import { CostSettingsProvider } from './context';

const LayoutContent = styled(Layout.Content)`
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  margin-top: -226px;
`;

const LayoutHeader = styled(Layout.Header)`
  background-color: white;
  padding: 0;
  line-height: 0;
  border-top: 1px solid #e8e8e8;
  height: 56px;
  display: flex;
  align-items: center;
`;

function CostSettings() {
  return (
    <>
      <CostSettingsProvider>
        <Divider />
        <Layout style={{ height: 'calc(100vh - 198px)' }}>
          <Layout>
            <LayoutHeader className="px-3">
              <Filters />
            </LayoutHeader>
            <LayoutContent className="pl-3" style={{ marginTop: '28px' }}>
              <VendorContent />
            </LayoutContent>
          </Layout>
          <CostSettingsSider width={400} breakpoint="md">
            <SiderContent />
          </CostSettingsSider>
        </Layout>
      </CostSettingsProvider>
    </>
  );
}

export default CostSettings;
