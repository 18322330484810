import React from 'react';
import styled from 'styled-components';
import { Filter } from './Filter/Filter';
import { Table } from './Table/Table';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const VGMBody: React.FC<Props> = () => {
  return (
    <Container>
      <Filter />
      <Table />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: calc(100% - 64px);
`;
