import React from 'react';
import { Select } from 'antd';
import { StyledSelect } from './styles';

const { Option } = Select;

const ToDo = () => (
  <div className="my-5">
    <div className="mb-1">To Do</div>
    <StyledSelect placeholder="Continue /" style={{ width: '100%' }}>
      <Option value="1">To Do 1</Option>
      <Option value="2">To Do 2</Option>
    </StyledSelect>
  </div>
);

export default ToDo;
