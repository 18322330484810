import * as yup from 'yup';
import messages from 'messages';

type Translate = (e: any) => any;

export class CustomValidation {
  static qqId(translate: Translate) {
    return yup
      .string()
      .matches(
        /^[0-9]{5,14}$/,
        translate(messages['qqid-must-be-a-number-error.text'])
      )
      .nullable();
  }

  static wechatId(translate: Translate) {
    return yup
      .string()
      .nullable()
      .matches(
        /^[A-Za-z0-9_\\-]{8,14}$/,
        translate(messages['wechat-id-validation-error.text'])
      );
  }

  static mobileNumber(translate: Translate) {
    return yup
      .string()
      .matches(
        /^[0-9]{8,14}$/,
        translate(messages['mobile-number-error-field.text'])
      )
      .nullable();
  }

  static email(translate: Translate) {
    return yup
      .string()
      .required('Email is required.')
      .email(translate(messages['invalid-email-format.text']));
  }

  static withdrawalPassword(translate: Translate) {
    return yup
      .string()
      .matches(
        /^[0-9]{6}$/,
        translate(messages['withdrawal-password-6-digits-error.text'])
      );
  }
}
