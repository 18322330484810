import { defineMessages } from 'react-intl';

const messages = defineMessages({
  OPERATOR_CREATE_SUCCESS: {
    id: 'OPERATOR_CREATE_SUCCESS',
    defaultMessage: 'New operator created.',
  },
});

export default messages;
