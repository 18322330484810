import React, { useState, useEffect } from 'react';
import { useScreenTabV2, Tab } from 'store/screenTabState';
import { GET_PROMO } from 'graphql/queries/promo.query';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  DepositPromo,
  PromoWagerContribution,
  SignUpPromo,
  UpdatePromoInput,
} from 'types/graphqlTypes';
import { LoadingOutlined } from '@ant-design/icons';
import { useConfig } from 'hooks/useConfig';
import { useForm } from 'react-hook-form';
import { UPDATE_PROMO_NEXT } from 'graphql/mutations/promo.mutation';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import ConfirmationModal from 'components/ConfirmationModal';
import removeNull from 'utils/removeNull';
import moment, { Moment } from 'moment';
import { CUSTOM_DATE_TIME_FORMAT } from 'constants/date';
import { map } from 'lodash';
import { message, Typography, Button, Divider, Form, Row, Col } from 'antd';
import DepositPromoFields from './DepositPromoFields';
import SignUpPromoFields from './SignUpPromoFields';
import AdditionalConfig from './AdditionalConfig';
import PromoCommonFields from './PromoCommonFields';

const { Title } = Typography;

type FormValues = SignUpPromo &
  DepositPromo & {
    validityDateTimeRange: Array<Moment | string> | null;
  };

const EditPromo = () => {
  const { getActiveTab, isTabActive } = useScreenTabV2();
  const tab: Tab = getActiveTab();

  const { context } = useOperatorHeader();

  const {
    currency: { symbol: currencySymbol },
  } = useConfig();

  const [promoTitleLength, setPromoTitleLength] = useState(0);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<FormValues>();

  const wagerContributionsDef = [
    {
      rank: 1,
      vendor: null,
      gameType: null,
      contribution: null,
    },
  ];

  const { loading, refetch } = useQuery(GET_PROMO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: tab.state.promoId,
    },
    skip: !isTabActive('edit-promo'),
    onCompleted: (data) => {
      const { promo } = data || {};

      const initalWageCont = promo?.wagerContributions?.map(
        (item: PromoWagerContribution) => ({
          rank: item.rank,
          vendor: item.vendor.id,
          gameType: item.gameType,
          contribution: item.contribution,
        })
      );

      if (promo) {
        reset({
          ...promo,
          includedMemberTags: map(promo.includedMemberTags, 'id'),
          excludedMemberTags: map(promo.excludedMemberTags, 'id'),
          paymentMethods: map(promo.paymentMethods, 'id'),
          validityDateTimeRange: [
            moment(promo.startDateTime),
            moment(promo.endDateTime),
          ],
          wagerContributions: [
            ...(promo.wagerContributions?.length
              ? initalWageCont
              : wagerContributionsDef),
          ],
        });
      }
    },
  });

  const {
    type: selectedPromoType,
    awardType: selectedPromoAwardType,
    autoCancel: enableKillSwitch,
    turnoverRequirementMultiplier: enableWagerTO,
  } = watch([
    'type',
    'awardType',
    'autoCancel',
    'turnoverRequirementMultiplier',
  ]);

  const isSignupPromo = selectedPromoType === 'SIGN_UP';
  const isDepositPromo = selectedPromoType === 'DEPOSIT';

  const [updatePromo, { loading: mutationLoading }] = useMutation<
    UpdatePromoInput
  >(UPDATE_PROMO_NEXT, {
    context,
  });

  const onUpdatePromo = async (values: FormValues) => {
    const newValues = removeNull(values);

    delete newValues.validityDateTimeRange;

    const wagerContributionRank = values?.wagerContributions?.map(
      (item, index) => ({
        ...item,
        rank: index + 1,
      })
    );

    try {
      await updatePromo({
        variables: {
          id: tab.state.promoId,
          input: {
            ...newValues,
            startDateTime: moment(values.validityDateTimeRange![0]).format(
              CUSTOM_DATE_TIME_FORMAT
            ),
            endDateTime: moment(values.validityDateTimeRange![1]).format(
              CUSTOM_DATE_TIME_FORMAT
            ),
            status: 'INACTIVE',
            turnoverRequirementMultiplier: enableWagerTO
              ? values.turnoverRequirementMultiplier
              : 0,
            wagerContributions:
              enableWagerTO && values?.wagerContributions[0]?.contribution
                ? wagerContributionRank
                : [],
          },
        },
      });
      message.success('Promo successfully updated!');
      refetch({ id: tab.state.promoId });
    } catch (err) {
      if (err) message.error('Something went wrong, Please try again.');
    } finally {
      setShowSubmitModal(false);
    }
  };

  const {
    type,
    name,
    event,
    platform,
    claimType,
    awardPreRequisite,
    amount,
    amountPercentage,
    maxMemberCount,
    awardType,
    awardCap,
    minAmount,
    maxAmount,
    claimCountLimit,
    paymentMethods,
    maxPayoutAmount,
    turnoverRequirementMultiplier,
    wagerContributions,
    validityDateTimeRange,
  } = errors;

  useEffect(() => {
    if (errors) setShowSubmitModal(false);
  }, [errors]);

  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="bg-white h-100 p-4">
      {loading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: '100vh' }}
        >
          {loadingIcon}
        </div>
      ) : (
        <>
          <ConfirmationModal
            title="Submit and Edit Promo?"
            message="Are you sure?"
            visible={showSubmitModal}
            onCancel={() => setShowSubmitModal(false)}
            onOk={handleSubmit(onUpdatePromo)}
            okButtonProps={mutationLoading}
          />
          <ConfirmationModal
            title="Cancel Edit Promo?"
            message="Are you sure?"
            visible={showCancelModal}
            onCancel={() => setShowCancelModal(false)}
            onOk={() => {
              reset();
              setPromoTitleLength(0);
              setShowCancelModal(false);
              message.success('Promo Editing Cancelled.');
            }}
          />
          <div className="d-flex justify-content-between">
            <div>
              <Title level={3} type="secondary">
                Edit Promo
              </Title>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => setShowSubmitModal(true)}
                form="edit-promo"
                className="mr-2"
              >
                Update Promo
              </Button>
              <Button onClick={() => setShowCancelModal(true)}>Cancel</Button>
            </div>
          </div>
          <Divider />
          <Form
            wrapperCol={{
              span: 14,
            }}
            labelCol={{
              span: 8,
            }}
            layout="horizontal"
            id="edit-promo"
          >
            <Row>
              <Col span={12}>
                <div className="d-flex flex-column">
                  <PromoCommonFields
                    control={control}
                    selectedPromoType={selectedPromoType}
                    setValue={setValue}
                    promoTitleState={{
                      promoTitleLength,
                      setPromoTitle: setPromoTitleLength,
                    }}
                    fieldError={{
                      type,
                      name,
                      event,
                      platform,
                      claimType,
                      awardPreRequisite,
                      validityDateTimeRange,
                    }}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div className="d-flex flex-column">
                  {isSignupPromo && (
                    <SignUpPromoFields
                      control={control}
                      fieldError={{
                        amount,
                        maxMemberCount,
                      }}
                      currencySymbol={currencySymbol}
                    />
                  )}
                  {isDepositPromo && (
                    <DepositPromoFields
                      selectedAwardType={selectedPromoAwardType}
                      control={control}
                      fieldError={{
                        awardType,
                        amount,
                        amountPercentage,
                        awardCap,
                        minAmount,
                        maxAmount,
                        claimCountLimit,
                        paymentMethods,
                        maxPayoutAmount,
                      }}
                      currencySymbol={currencySymbol}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Divider>
              <Title level={3} type="secondary">
                Additional Configuration
              </Title>
            </Divider>
            <AdditionalConfig
              clearErrors={clearErrors}
              control={control}
              setValue={setValue}
              fieldError={{
                turnoverRequirementMultiplier,
                wagerContributions,
              }}
              fieldToggles={{
                enableKillSwitch,
                enableWagerTO,
              }}
            />
          </Form>
        </>
      )}
    </div>
  );
};

export default EditPromo;
