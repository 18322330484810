import React from 'react';
import styled from 'styled-components';

function Content({ children, ...restProps }: { children?: any }) {
  return (
    <Root {...restProps}>
      <div className="drawer-content">{children}</div>
    </Root>
  );
}

const Root = styled.div<any>`
  padding: ${(props) => props.padding};
  position: relative;
  /* overflow: hidden; */
  overflow: visible;
  overflow-y: auto;
  height: calc(100% - ${(props) => props.heightExcess}px);
  .ant-legacy-form-item {
    margin-bottom: 8px;
  }
`;

Content.defaultProps = {
  padding: '24px',
  heightExcess: '130',
};

export default Content;
