import gql from 'graphql-tag';

export const AUTOMATED_MESSAGES = gql`
  query AutomatedMessages($filter: AutomatedMessageFilterInput) {
    automatedMessages(filter: $filter) {
      edges {
        node {
          id
          event
          title_en
          title_zh
          content_en
          content_zh
          enabled
        }
      }
    }
  }
`;
