import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { Admin, Operator, SuperAdmin, Config } from 'types/graphqlTypes';
import storage from 'constants/storage';
import {
  MemberInfo,
  useLanguage,
  useStarMember,
  useStarredMembers,
} from './utils';

export type Account = Admin | Operator | SuperAdmin | null;

export interface AppConfig {
  previousUser: Partial<Account>;
  previousConfig: Partial<Config>;
  previousSessionId: string | null;
}

export type Locale = 'en' | 'zh';

type Portal = 'sa' | 'bo';

export type AccountState = {
  portal: string;
  account: Account;
  appConfig: AppConfig;
  config: Partial<Config> | null;
  locale: string;
  ldInitialized: boolean;
  starred: Array<MemberInfo>;
  starringMemberId: string | null;
};

const initialState: AccountState = {
  portal: 'bo',
  account: null,
  appConfig: {
    previousUser: {},
    previousConfig: {},
    previousSessionId: '',
  },
  config: null,
  locale: 'en',
  ldInitialized: false,
  starred: [],
  starringMemberId: null,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setPortal: (state: AccountState, { payload }: { payload: Portal }) => ({
      ...state,
      portal: payload,
    }),
    setAccount: (
      state: AccountState,
      {
        payload,
      }: {
        payload: Account;
      }
    ) => ({
      ...state,
      account: payload,
    }),
    resetAccount: (state: AccountState) => ({
      ...state,
      account: null,
    }),
    setConfig: (
      state: AccountState,
      { payload }: { payload: Partial<Config> }
    ) => ({
      ...state,
      config: payload,
    }),
    setAppConfig: (
      state: AccountState,
      { payload }: { payload: AppConfig }
    ) => ({
      ...state,
      appConfig: payload,
    }),
    setLocale: (state: AccountState, { payload }: { payload: string }) => ({
      ...state,
      locale: payload.toLowerCase(),
    }),
    setLDInitialized: (
      state: AccountState,
      { payload }: { payload: boolean }
    ) => ({
      ...state,
      ldInitialized: payload,
    }),
    setStarredMembers: (
      state: AccountState,
      { payload }: { payload: Array<MemberInfo> }
    ) => ({
      ...state,
      starred: payload || [],
    }),
    setStarringMemberId: (
      state: AccountState,
      { payload }: { payload: string | null }
    ) => ({
      ...state,
      starringMemberId: payload,
    }),
  },
});

export const accountReducer = accountSlice.reducer;

export const useAccount = () => {
  const { actions } = accountSlice;
  const dispatch = useDispatch();
  const account = useSelector(
    (state: { account: AccountState }) => state.account
  );

  const { updateLangauge } = useLanguage();

  const dispatchSetStarredMembers = (data: any) => {
    dispatch(actions.setStarredMembers(data));
  };

  const { getStarredMembers } = useStarredMembers(dispatchSetStarredMembers);

  const dispatchSetPortal = (portal: Portal) => {
    localStorage.setItem(storage.ACTIVE_PORTAL, portal);
    dispatch(actions.setPortal(portal));
  };

  const dispatchSetAccount = (acc: Account) =>
    dispatch(actions.setAccount(acc));

  const dispatchSetConfig = (conf: Partial<Config>) =>
    dispatch(actions.setConfig(conf));

  const dispatchSetAppConfig = (conf: AppConfig) => {
    dispatch(actions.setAppConfig(conf));
  };

  const dispatchSetLocale = async (lang: string) => {
    localStorage.setItem('locale', lang);
    dispatch(actions.setLocale(lang));
  };

  const dispatchUpdateLanguage = async (lang: string) => {
    dispatchSetLocale(lang);
    updateLangauge(lang);
    localStorage.setItem('locale', lang);
  };

  const dispatchResetAccount = () => {
    dispatch(actions.resetAccount());
    localStorage.removeItem('locale');
  };

  return {
    getStarredMembers,
    setPortal: dispatchSetPortal,
    setAccount: dispatchSetAccount,
    resetAccount: dispatchResetAccount,
    setConfig: dispatchSetConfig,
    setAppConfig: dispatchSetAppConfig,
    setLocale: dispatchSetLocale,
    updateAccountLanguage: dispatchUpdateLanguage,
    setLDInitialized: () => dispatch(actions.setLDInitialized(true)),
    account,
  };
};

export const usePermissions = (): {
  permissions: string[];
  role: string | null;
} => {
  const account = useSelector(
    (state: { account: AccountState }) => state.account
  );
  const user = account.account as Operator;

  if (!user)
    return {
      permissions: [],
      role: null,
    };

  return {
    permissions: user.permissions || [],
    role: user.role,
  };
};

type Starred = {
  starringMemberId: string | null;
  starred: MemberInfo[];
  getStarredMember: (id: string) => MemberInfo | undefined;
  starMember: (member: MemberInfo) => void;
  unstarMember: (member: MemberInfo) => void;
  toggleStar: (member: MemberInfo) => void;
};

export const useStarred = (): Starred => {
  const { actions } = accountSlice;
  const dispatch = useDispatch();
  const account = useSelector(
    (state: { account: AccountState }) => state.account
  );
  const { starred, starringMemberId } = account;

  const dispatchSetStarredMembers = (data: any) => {
    dispatch(actions.setStarredMembers(data));
  };

  const dispatchSetStarringMemberId = (id: string | null) => {
    dispatch(actions.setStarringMemberId(id));
  };

  const {
    getStarredMember,
    starMember,
    unstarMember,
    toggleStar,
  } = useStarMember(
    starred,
    dispatchSetStarredMembers,
    dispatchSetStarringMemberId
  );

  return {
    starringMemberId,
    starred,
    getStarredMember,
    starMember,
    unstarMember,
    toggleStar,
  };
};
