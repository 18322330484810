import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import coercedGet from 'utils/coercedGet';
import messages from 'messages';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';
import useTranslate from 'utils/useTranslate';
import AccountTag from 'components/AccountTag';
import VendorTag from 'components/VendorTag';
import MemberLoyaltyLevelTag from 'components/MemberLoyaltyLevelTag';
import MemberLevelTag from 'components/MemberLevelTag';
import MemberLabelTag from 'components/MemberLabelTag';
import { DATE_TIME_FORMAT } from 'constants/date';
import { isEmpty } from 'lodash';
import { TableFilterType } from '../../../types';

type Props = {
  filter: TableFilterType;
  setFilterField: (name: string, value: any) => void;
};

const FilterConditions: React.FC<Props> = ({ filter, setFilterField }) => {
  const translate = useTranslate();
  const {
    startDateTime = null,
    endDateTime = null,
    registrationDateTime = null,
    lastLoginDateTime = null,
    dateOfBirthDateTime = null,
    members,
    gameTypes,
    vendors,
    timezone,
    isAffiliate,
    isAffiliateUpline,
    affiliates,
    memberLoyaltyLevels,
    memberLevels,
    memberTags,
    memberStatus,
    totalBalance,
  } = filter;

  const [dateFields, setDateFields] = useState<any>({});

  useEffect(() => {
    const newDates = {
      startDateTime,
      endDateTime,
      registrationDateTime,
      lastLoginDateTime,
      dateOfBirthDateTime,
    };

    if (newDates.startDateTime && newDates.endDateTime) {
      const { startEndDate }: any = transformMovableDateFilter({
        startEndDate: {
          gte: newDates.startDateTime,
          lte: newDates.endDateTime,
        },
      });
      newDates.startDateTime = startEndDate.gte;
      newDates.endDateTime = startEndDate.lte;
    }

    setDateFields(transformMovableDateFilter(newDates));
  }, [
    startDateTime,
    endDateTime,
    registrationDateTime,
    lastLoginDateTime,
    dateOfBirthDateTime,
  ]);

  const handleRemoveArrayFilterTag = (
    name: string,
    value: string | boolean,
    e: any
  ) => {
    e.preventDefault();

    const field = coercedGet(filter, name, []);
    const newField = field.filter((item: string) => item !== value);

    setFilterField(name, newField.length ? newField : null);
  };

  const handleRemoveFilterTag = (name: string, e: any) => {
    e.preventDefault();

    setFilterField(name, null);
  };

  const handleRemoveAmountTag = (name: string, range: string, e: any) => {
    e.preventDefault();
    const newAmount = { ...filter[`${name}`] };
    delete newAmount[`${range}`];

    setFilterField(name, isEmpty(newAmount) ? null : newAmount);
  };

  return (
    <>
      {dateFields.startDateTime && dateFields.endDateTime ? (
        <Tag
          closable
          onClose={(e: any) => {
            handleRemoveFilterTag('startDateTime', e);
            handleRemoveFilterTag('endDateTime', e);
          }}
        >
          {translate(messages.START_END_DATE_TIME)}:{' '}
          {formatDate(dateFields.startDateTime, DATE_TIME_FORMAT)} ~{' '}
          {formatDate(dateFields.endDateTime, DATE_TIME_FORMAT)}
        </Tag>
      ) : null}

      {members?.length
        ? members.map((member: string) => (
            <AccountTag
              onClose={(e: any) =>
                handleRemoveArrayFilterTag('members', member, e)
              }
              key={member}
              id={member}
            />
          ))
        : null}

      {gameTypes?.length
        ? gameTypes.map((gameType: string) => (
            <Tag
              closable
              key={gameType}
              onClose={(e: any) =>
                handleRemoveArrayFilterTag('gameTypes', gameType, e)
              }
            >
              {translate(messages[`${gameType}`])}
            </Tag>
          ))
        : null}

      {vendors?.length
        ? vendors.map((vendor: string) => (
            <VendorTag
              onClose={(e: any) =>
                handleRemoveArrayFilterTag('vendors', vendor, e)
              }
              key={vendor}
              id={vendor}
            />
          ))
        : null}

      {timezone ? (
        <Tag
          closable
          key={timezone}
          onClose={(e: any) => handleRemoveFilterTag('timezone', e)}
        >
          {timezone}
        </Tag>
      ) : null}

      {isAffiliate?.length
        ? isAffiliate.map((item: boolean, key: number) => (
            <Tag
              closable
              key={key}
              onClose={(e: any) =>
                handleRemoveArrayFilterTag('isAffiliate', item, e)
              }
            >
              {item
                ? translate(messages.AFFILIATE)
                : translate(messages['affiliates.non-affiliate.text'])}
            </Tag>
          ))
        : null}

      {isAffiliateUpline?.length
        ? isAffiliateUpline.map((item: boolean, key: number) => (
            <Tag
              closable
              key={key}
              onClose={(e: any) =>
                handleRemoveArrayFilterTag('isAffiliateUpline', item, e)
              }
            >
              {item
                ? translate(messages.AFFILIATE_UPLINE)
                : translate(messages.NON_AFFILIATE_UPLINE)}
            </Tag>
          ))
        : null}

      {affiliates?.length
        ? affiliates.map((affiliate: string) => (
            <AccountTag
              onClose={(e: any) =>
                handleRemoveArrayFilterTag('affiliates', affiliate, e)
              }
              key={affiliate}
              id={affiliate}
              prepend={translate(messages.AFFILIATE_UPLINE)}
            />
          ))
        : null}

      {memberLoyaltyLevels?.length
        ? memberLoyaltyLevels.map((memberLoyaltyLevel: string) => (
            <MemberLoyaltyLevelTag
              islight
              onClose={(e) =>
                handleRemoveArrayFilterTag(
                  'memberLoyaltyLevels',
                  memberLoyaltyLevel,
                  e
                )
              }
              id={memberLoyaltyLevel}
            />
          ))
        : null}

      {memberLevels?.length
        ? memberLevels.map((memberLevel: string) => (
            <MemberLevelTag
              onClose={(e) =>
                handleRemoveArrayFilterTag('memberLevels', memberLevel, e)
              }
              key={memberLevel}
              id={memberLevel}
            />
          ))
        : null}

      {memberTags?.length
        ? memberTags.map((memberTag: string) => (
            <MemberLabelTag
              onClose={(e) =>
                handleRemoveArrayFilterTag('memberTags', memberTag, e)
              }
              key={memberTag}
              id={memberTag}
            />
          ))
        : null}

      {dateFields.registrationDateTime ? (
        <Tag
          closable
          onClose={(e: any) => {
            handleRemoveFilterTag('registrationDateTime', e);
          }}
        >
          {translate(messages.REGISTRATION_START_END_DATE_TIME)}:{' '}
          {formatDate(dateFields.registrationDateTime?.start, DATE_TIME_FORMAT)}{' '}
          ~ {formatDate(dateFields.registrationDateTime?.end, DATE_TIME_FORMAT)}
        </Tag>
      ) : null}

      {dateFields.lastLoginDateTime ? (
        <Tag
          closable
          onClose={(e: any) => {
            handleRemoveFilterTag('lastLoginDateTime', e);
          }}
        >
          {translate(messages.LAST_LOGIN_START_END_DATE_TIME)}:{' '}
          {formatDate(dateFields.lastLoginDateTime?.start, DATE_TIME_FORMAT)} ~{' '}
          {formatDate(dateFields.lastLoginDateTime?.end, DATE_TIME_FORMAT)}
        </Tag>
      ) : null}

      {dateFields.dateOfBirthDateTime ? (
        <Tag
          closable
          onClose={(e: any) => {
            handleRemoveFilterTag('dateOfBirthDateTime', e);
          }}
        >
          {translate(messages['date-of-birth-start-end-date-time.text'])}:{' '}
          {formatDate(dateFields.dateOfBirthDateTime?.start, DATE_TIME_FORMAT)}{' '}
          ~ {formatDate(dateFields.dateOfBirthDateTime?.end, DATE_TIME_FORMAT)}
        </Tag>
      ) : null}

      {memberStatus?.length
        ? memberStatus.map((item: string) => (
            <Tag
              closable
              key={item}
              onClose={(e: any) =>
                handleRemoveArrayFilterTag('memberStatus', item, e)
              }
            >
              {translate(messages[`${item}`])}
            </Tag>
          ))
        : null}

      {totalBalance?.minimum ? (
        <Tag
          closable
          onClose={(e: any) =>
            handleRemoveAmountTag('totalBalance', 'minimum', e)
          }
        >
          {translate(messages.MINIMUM_ACCOUNT_BALANCE)}: {totalBalance?.minimum}
        </Tag>
      ) : null}
      {totalBalance?.maximum ? (
        <Tag
          closable
          onClose={(e: any) =>
            handleRemoveAmountTag('totalBalance', 'maximum', e)
          }
        >
          {translate(messages.MAXIMUM_ACCOUNT_BALANCE)}: {totalBalance?.maximum}
        </Tag>
      ) : null}
    </>
  );
};

export default FilterConditions;
