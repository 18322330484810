import React from 'react';
import gql from 'graphql-tag';
import CustomSwitch from 'components/CustomSwitch';
import { FormattedMessage } from 'react-intl';

export const CONFIG = gql`
  query {
    config {
      isEmailReusable
      isMobileNumberReusable
      emailReusabilityCount
      mobileNumberReusabilityCount
    }
  }
`;

export const ProfileSwitch = ({
  value,
  onChange,
  disabled,
}: {
  value: boolean;
  disabled?: boolean;
  onChange: (e: any) => void;
}) => (
  <CustomSwitch
    textStyle={{}}
    disabled={disabled}
    value={value}
    onChange={(e: boolean) => onChange(e)}
    textHelper={{
      true: <FormattedMessage id="ON" defaultMessage="On" />,
      false: <FormattedMessage id="OFF" defaultMessage="Off" />,
    }}
    activeColor="#3ec988"
  />
);
