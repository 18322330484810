import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { VGMHeader } from './components/VGMHeader/VGMHeader';
import { VGMBody } from './components/VGMBody/VGMBody';
import { VendorGroupManagementProvider } from './VendorGroupManagementContext';
import { usePartialFiltersQuery } from '../../../../utils/partialUtils';
import FiltersProvider, { useFilterValues } from '../../../contexts/Filters';
import { useScreenTabSA } from '../../../store/SuperAdminScreenTabState/screenTabState';
import { TabIdSA } from '../../../constants/route';

interface Props {}

export const VENDOR_GROUPS_IDS = gql`
  query VendorGroupsIds(
    $filter: VendorGroupsFilterInput
    $partialFilter: VendorGroupsFilterInput
  ) {
    vendorGroups(filter: $filter) {
      edges {
        node {
          id
        }
      }
    }

    partial: vendorGroups(filter: $partialFilter) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const VENDOR_GROUPS = gql`
  query VendorGroups(
    $first: Int
    $after: Binary
    $filter: VendorGroupsFilterInput
  ) {
    vendorGroups(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          status
          vendors {
            id
            name
          }
          dateTimeCreated
          GGRCharges {
            vendor {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const pageInitState = {
  first: 10,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
};

const VendorGroupManagement: React.FC<Props> = () => {
  const { filters } = useFilterValues();
  const [page, setPage] = useState<any>(pageInitState);
  const { isTabActive } = useScreenTabSA(TabIdSA.VENDOR_GROUP_MANAGEMENT);
  const isActive = isTabActive(TabIdSA.VENDOR_GROUP_MANAGEMENT);
  const { data, loading, error, refetch } = usePartialFiltersQuery(
    VENDOR_GROUPS_IDS,
    VENDOR_GROUPS,
    'vendorGroups.edges',
    filters,
    page,
    ['name'],
    'network-only'
  );

  useEffect(() => {
    if (isActive) {
      refetch();
    }
  }, [isActive, refetch]);

  return (
    <VendorGroupManagementProvider
      page={page}
      setPage={setPage}
      data={data}
      loading={loading}
      error={error}
      refetch={refetch}
    >
      <Container data-testid="vendor-group-management-content">
        <VGMHeader />
        <VGMBody />
      </Container>
    </VendorGroupManagementProvider>
  );
};
export default () => (
  <FiltersProvider>
    <VendorGroupManagement />
  </FiltersProvider>
);

const Container = styled.div`
  background: #fff;
  height: calc(100vh - 80px);
`;
