import gql from 'graphql-tag';

export const GET_MEMBERS_ONLINE_IDS = gql`
  query MembersOnlineIds(
    $filter: MembersFilterInput
    $partialFilter: MembersFilterInput
  ) {
    members(filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: members(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_MEMBERS_ONLINE = gql`
  query MembersOnline(
    $first: Int
    $after: Binary
    $filter: MembersFilterInput
  ) {
    members(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          username
          currency
          language
          timezone
          realName
          winloss
          lastLoginDateTime
          dateTimeCreated
          dateTimeUpdated
          status
          totalBalance
          memberLevel {
            id
            name
            color
          }
          tags {
            id
            name
            color
            description
          }
          session {
            id
            dateTimeCreated
            turnover
            ggr
            ipAddress
            channel
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_USERNAMES = gql`
  query Usernames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;
