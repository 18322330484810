import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Button, message, Switch, Upload } from 'antd';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import {
  BULK_UPDATE_MEMBER_STATUS,
  MEMBER_BULK_STATUS_UPDATE_RECORD,
} from 'pages/components/BulkUpdate/utils';
import React from 'react';
import { useInterval } from 'react-use';
import { ALink } from 'components/ALink/ALink';

const BulkChangeStatus = () => {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [bulkUpdateMemberStatus] = useMutation(BULK_UPDATE_MEMBER_STATUS);

  const client = useApolloClient();

  const [isIntervalRunning, setIsIntervalRunning] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  const [downloadLink, setDownloadLink] = React.useState('');
  const [brandLinkage, setBrandLinkage] = React.useState(false);

  const onCallBulkRecord = async () => {
    const { data } = await client.query({
      query: MEMBER_BULK_STATUS_UPDATE_RECORD,
      fetchPolicy: 'network-only',
    });
    const status = data?.memberBulkStatusUpdateRecord?.status;

    if (status === 'FAILED') {
      setIsIntervalRunning(false);
      setProcessing(false);
      message.error('Bulk update error');
      return;
    }

    if (status === 'DONE') {
      setIsIntervalRunning(false);
      setProcessing(false);
      setDownloadLink(data?.memberBulkStatusUpdateRecord?.result);
      message.success('Bulk update status successfully');
    }
  };

  useInterval(
    () => {
      onCallBulkRecord();
    },
    isIntervalRunning ? 1500 : null
  );

  const uploadProps: any = {
    beforeUpload: (file: File) => {
      setDownloadLink('');
      const validType = file.type === 'text/plain';

      if (!validType) {
        message.error('Only upload valid text file');
        return false;
      }

      setProcessing(true);
      uploadFile({
        variables: {
          file,
        },
      }).then(async ({ data }) => {
        await bulkUpdateMemberStatus({
          variables: {
            file: data?.uploadFile?.id,
            brandLinkage,
          },
        });
        setIsIntervalRunning(true);
        onCallBulkRecord();
      });

      return false;
    },
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <h3 className="mb-0">Bulk Change Status &nbsp;</h3>
        <ALink
          href={`${process.env.PUBLIC_URL}/text/sample for bulk update.txt`}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Sample Format
        </ALink>
      </div>
      <div className="d-flex">
        <Upload
          {...uploadProps}
          maxCount={1}
          accept={['.txt'].join(', ')}
          showUploadList={false}
        >
          <Button className="mr-3" disabled={processing} loading={processing}>
            {processing ? 'Processing...' : 'Upload Text File'}
          </Button>
        </Upload>

        <Button disabled={!downloadLink} type="primary" href={downloadLink}>
          Download
        </Button>
      </div>

      <div className="mt-3">
        <Switch checked={brandLinkage} onChange={(e) => setBrandLinkage(e)} />{' '}
        Brand Linkage
        <div>
          <small>Switch this on/off before uploading the text file</small>
        </div>
      </div>
    </div>
  );
};

export default BulkChangeStatus;
