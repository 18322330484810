import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import {
  GET_MASR_ID_BASED,
  GET_MASR_STRING_BASED,
  GET_MEMBER_ACCESS_SUMMARY_REPORT,
} from 'graphql/queries/memberAccessSummary.query';
import { Page, PageInfo } from 'interfaces/user.interface';
import { StyledTagContainer } from 'pages/components/MemberAccessSummaryReport/styles';
import messages from 'messages';
import React, { useState } from 'react';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { useScreenTabV2 } from 'store/screenTabState';
import {
  MemberAccessSummaryReportItem,
  MemberAccessSummaryReportItemsFilterInput,
  Scalars,
} from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import compose from 'utils/compose';
import { createPartialUtil } from 'utils/partialUtils';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import {
  MemberFromTab,
  QueryResult,
  StateFilter,
  UnifiedFilters,
} from '../types';
import { collateMemberFilterFields } from '../utils';
import FilterConditions from './FilterCondition';
import HeaderRight from './HeaderRight';
import MemberAccessSummaryTable from './MemberAccessSummaryTable';
import getColumns from './MemberAccessSummaryTable/columns';
import Sidebar from './Sidebar';

const MemberAccessSummaryScreen = () => {
  type OriginalColumn = {
    key: string;
    title: string;
    customTitle?: string;
    [x: string]: any;
  };
  const initialFilters: StateFilter = {
    usernames: null,
    realnames: null,
    ipAddressesCount: null,
    brand: null,
  };

  const emptyMember: MemberFromTab = {
    id: null,
    username: null,
  };
  const isPropsEmpty = (obj: MemberFromTab) =>
    !Object.values(obj).every((prop) => !!prop);

  const isPropsActive = (obj: UnifiedFilters) =>
    Object.values(obj).some((prop) => !!prop);

  const [unifiedFilters, setUnifiedFilters] = useState<UnifiedFilters>({});

  const idQueries = {
    query: GET_MASR_ID_BASED,
    sourceFiltFields: ['memberUserName', 'memberRealName'],
    targetStringFields: ['username', 'realName'],
    targetFilterFields: ['member', 'member'],
  };

  const { setTableColumns } = useSpreadsheetContext();

  const filtProcessorFunc = compose(removeNull, collateMemberFilterFields);

  const usePartialFilterUtil = createPartialUtil('id', filtProcessorFunc);

  const pageInitState: Page = {
    after: undefined,
    savedCursor: [undefined] as any,
    currentPage: 0,
    first: 10,
  };

  const [page, setPage] = useState(pageInitState);

  const {
    loading,
    data,
    refetch,
    error,
    finalQueryFilter,
  } = usePartialFilterUtil(
    GET_MASR_STRING_BASED,
    GET_MEMBER_ACCESS_SUMMARY_REPORT,
    'memberAccessSummaryReport.edges',
    unifiedFilters,
    page,
    ['ipAddressesCount'],
    idQueries
  );
  const translate = useTranslate();

  const dataSource: MemberAccessSummaryReportItem[] = coercedGet(
    data as QueryResult,
    'memberAccessSummaryReport.edges',
    []
  ).map((edge: any) => edge!.node);

  const totalCount: number = coercedGet(
    data as QueryResult,
    'memberAccessSummaryReport.totalCount',
    0
  );
  const pageInfo: PageInfo = coercedGet(
    data as QueryResult,
    'memberAccessSummaryReport.pageInfo',
    0
  );
  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage] || undefined;
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };
  const [expandedTags, setExpandedTags] = useState(false);

  const { thisTab, updateTab, useUpdateTab, clearTabState } = useScreenTabV2(
    'member-access-summary-report'
  );

  const memberFromTab: MemberFromTab = coercedGet(
    thisTab,
    'member',
    emptyMember
  );

  const filter = coercedGet(thisTab, 'filter', {});
  const [filters, setFilters] = useState<
    MemberAccessSummaryReportItemsFilterInput
  >(filter);

  useUpdateTab(() => {
    setPage((prevData: Page) => {
      const { first, ...restOfPageInitState } = pageInitState;
      return {
        first: prevData.first,
        ...restOfPageInitState,
      };
    });
    setFilters(coercedGet(thisTab, 'filter', filter));
  });

  const [columns, setColumns] = useState<OriginalColumn[]>([]);
  const { filterColumns } = useCustomColumnsV2(
    'member-access-summary-report',
    columns
  );

  const pageInfoFoot: PageInfo = coercedGet(
    data as any,
    'memberAccessSummaryReport.pageInfo',
    {}
  );

  React.useEffect(() => {
    if (!loading) setTableColumns(filterColumns(columns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, filters, finalQueryFilter]);

  const { addTab } = useScreenTabV2();

  const handleClick = ({
    id,
    username,
  }: {
    id: string;
    username: string;
  }): void => {
    addTab({
      id: 'member-ip-address-summary',
      state: {
        member: {
          id,
          username,
        },
      },
    });
  };

  React.useEffect(() => {
    setColumns(getColumns(translate, handleClick));
    // eslint-disable-next-line
  }, [translate]);
  const totalPage: number = Math.ceil(totalCount / page.first) || 1;
  const [stateFilters, setStateFilters] = React.useState<StateFilter>(
    initialFilters
  );

  React.useEffect(() => {
    if (!isPropsEmpty(memberFromTab)) {
      const id = coercedGet(memberFromTab, 'id', '');
      setStateFilters((state) => ({ ...state, usernames: [id] }));
      updateTab({ filter: { member: { in: [id] } } });
    }
  }, [memberFromTab, updateTab]);

  React.useEffect(() => {
    setUnifiedFilters({
      member: null,
      memberUserName: stateFilters?.usernames?.length
        ? { in: stateFilters.usernames }
        : null,
      memberRealName: stateFilters?.realnames?.length
        ? { in: stateFilters.realnames }
        : null,
      ipAddressesCount: stateFilters?.ipAddressesCount
        ? {
            gte: stateFilters.ipAddressesCount.gte,
            lte: stateFilters.ipAddressesCount.lte,
          }
        : null,
      brand: stateFilters?.brand?.length ? { in: stateFilters.brand } : null,
    });
  }, [stateFilters]);

  const handleStateFilters = (filterData: StateFilter): void => {
    setStateFilters((state: any) => ({ ...state, ...filterData }));

    const memberIds: Scalars['ID'][] = [
      ...coercedGet({ ...stateFilters, ...filterData }, 'usernames', []),
      ...coercedGet({ ...stateFilters, ...filterData }, 'realnames', []),
    ];

    const ipAddressesCount = coercedGet(
      { ...stateFilters, ...filterData },
      'ipAddressesCount',
      ''
    );

    const brandValues = [
      ...coercedGet({ ...stateFilters, ...filterData }, 'brand', []),
    ];

    updateTab({
      filter: {
        ...(memberIds.length && {
          member: {
            in: memberIds,
          },
        }),
        ...(ipAddressesCount && {
          ipAddressesCount: {
            gte: ipAddressesCount.gte,
            lte: ipAddressesCount.lte,
          },
        }),
        ...(brandValues.length && {
          brand: {
            in: brandValues,
          },
        }),
      },
    });
  };

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <Layout.Header
        leftNode={
          <div className="d-flex align-items-center flex-g-1">
            <ToggleSidebarHead />
            <StyledTagContainer ref={ref} isExpanded={expandedTags}>
              {translate(messages.FILTER_CONDITIONS)}:
              <FilterConditions
                stateFilters={stateFilters}
                onFilterChange={handleStateFilters}
                isLoading={loading}
              />
            </StyledTagContainer>
            <LegacyIconToggle
              forwardedRef={ref}
              filters={filters}
              expandedTags={expandedTags}
              toggleExpandedTags={setExpandedTags}
            />
            {isPropsActive(unifiedFilters) && (
              <ClearAllFilterButton
                currentFilters={filters}
                onFilterChange={() => {
                  setStateFilters(initialFilters);
                  clearTabState();
                }}
                initialFilters={initialFilters}
              />
            )}
          </div>
        }
        rightNode={
          <HeaderRight
            refreshPage={refetch}
            withRealtime={false}
            filters={filters}
          />
        }
      />

      <Layout.Content
        sideBar={
          <Sidebar
            filters={filters}
            onFilterChange={handleStateFilters}
            isLoading={false}
            stateFilters={stateFilters}
            resetStateFilter={clearTabState}
          />
        }
        footer={
          <Layout.Footer
            onNext={handleNext}
            onPrev={handlePrev}
            page={page}
            resultsCount={totalCount}
            totalPage={totalPage}
            setPage={setPage}
            pageInfo={pageInfoFoot}
            loading={loading}
            // pageInfo={data && data.pageInfo}
          />
        }
      >
        <MemberAccessSummaryTable
          members={dataSource}
          loading={loading}
          columns={filterColumns(columns)}
          error={error}
        />
      </Layout.Content>
    </Layout.Container>
  );
};

export default MemberAccessSummaryScreen;
