import { Locale } from 'constants/locale';
import messages from 'messages';

export const translateDefaultMarker = ({
  text,
  withEllipsis,
  translate,
  intl,
}: {
  text: string;
  withEllipsis?: boolean;
  translate: any;
  intl: any;
}) => {
  if (intl.locale === Locale.ZH) {
    if (text === 'Default' || text === 'Defa') {
      return translate(messages.DEFAULT);
    }
  }

  return `${text}${withEllipsis ? '...' : ''}`;
};
