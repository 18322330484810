import {
  CloseOutlined,
  CloudUploadOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, Col, message, Progress, Row, Upload } from 'antd';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import messages from 'messages';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import XLSX from 'xlsx';

const StyledCol = styled(Col)`
  div.d-flex {
    span {
      width: 100% !important;
    }
  }
`;
const StyledDragger = styled(Upload.Dragger)`
  .ant-upload-drag-container {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .ant-upload.ant-upload-btn {
    padding: 0px !important;
  }
`;
const StyledUploadButton = styled(Button)`
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`;
const oneMbInBytes = 1024000;
const acceptedFileTypes = ['.xls', '.xlsx', '.csv'];
const StyledProgress = styled(Progress)`
  .ant-progress-bg {
    height: 3px !important;
  }
`;

const dummyRequest = ({ onSuccess }: { onSuccess: (e: string) => void }) => {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
};

export const UploadExcel = ({
  onChange,
  onFileError,
  excelType = 'password',
  clear = 0,
  validation,
}: {
  onChange: any;
  onFileError: any;
  excelType?: string | null;
  clear?: number;
  validation?: string[];
}) => {
  const [fileList, setFileList] = useState<File[]>([]);
  const [percent, setPercent] = useState(0);
  const [fileError, setFileError] = useState(false);
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const translate = useTranslate();

  const fileRead = useCallback(
    (file: File, interval: any) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e!.target!.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const [firstSheetName] = workbook.SheetNames;
        const worksheet = workbook.Sheets[firstSheetName];

        const rows = XLSX.utils.sheet_to_json(worksheet, {
          raw: true, // Use raw values (true) or formatted strings (false)
          header: 1, // Generate an array of arrays ("2D Array")
        });

        if (validation) {
          const formatHeaders = (rows[0] as string[]).join('').toLowerCase();
          const validationHeaders = validation.join('').toLowerCase();
          if (formatHeaders !== validationHeaders) {
            setFileList([]);
            clearInterval(interval);
            message.error(translate(messages.EXCEL_WRONG_HEADERS_TEMPLATE));
            return;
          }
        }

        rows.shift();

        if (rows.length >= 10000) {
          clearInterval(interval);
          setPercent(100);
          setFileError(true);
          onFileError(true);
          return;
        }
        setPercent(100);
        clearInterval(interval);

        const items = rows.map((row: any) => ({
          username: row[0],
          ...(excelType && { [excelType]: row[1] }),
        }));

        if (excelType === 'import') {
          uploadFile({
            variables: {
              file,
            },
          }).then((resp) => {
            setPercent(100);
            clearInterval(interval);
            onChange(resp?.data?.uploadFile?.id);
          });

          return;
        }

        if (!excelType) {
          const excelMembers: string[] = items
            .map(({ username }) => username)
            .filter((username: string) => username);

          const getDuplicates = excelMembers.filter(
            (username, index, arr) =>
              username !== undefined && arr.indexOf(username) !== index
          );

          if (getDuplicates.length) {
            message.error(translate(messages.USERNAME_DUPLICATE));
            setFileList([]);
          } else {
            onChange(excelMembers);
          }

          return;
        }

        onChange(items);

        // Will comment out this for now, because the process is on the FE side
        // return;
        // uploadFile({
        //   variables: {
        //     file,
        //   },
        // }).then(resp => {
        //   setPercent(100);
        //   clearInterval(interval);
        //   onChange(resp?.data?.uploadFile?.id);
        // });
      };

      reader.readAsArrayBuffer(file);
    },
    [excelType, onChange, onFileError, translate, uploadFile, validation]
  );

  useEffect(() => {
    setFileList([]);
  }, [setFileList, clear]);

  const propsUpload: any = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    onRemove: () => {
      setFileList([]);
    },
    // This is to negate the use of the built-in post request by this library
    customRequest: dummyRequest,
    beforeUpload: async (file: File) => {
      setFileList([]);
      if (file.size > oneMbInBytes) {
        message.warning(
          translate(messages.UPLOAD_ERROR_FILE_SIZE_LIMIT, {
            size: '1024kb',
          })
        );
        return false;
      }
      setFileError(false);
      setPercent(0);
      setFileList([file]);
      const interval = setInterval(() => {
        if (percent < 90) {
          setPercent((prev) => prev + 5);
        }
      }, 300);
      fileRead(file, interval);
      return false;
    },
    fileList,
  };

  return (
    <>
      <Row gutter={16}>
        <StyledCol span={12}>
          <StyledDragger {...propsUpload} accept={acceptedFileTypes.join(', ')}>
            <p className="fs-12" style={{ margin: '0 auto' }}>
              <FormattedMessage
                id="CLICK_OR_DRAG_FILE_UPLOAD"
                defaultMessage="Click or Drag the file here to upload"
              />
            </p>
            <StyledUploadButton type="primary" icon={<CloudUploadOutlined />} />
          </StyledDragger>
        </StyledCol>
        <Col span={12}>
          {fileList[0] && (
            <div>
              <PaperClipOutlined
                style={{ color: fileError ? 'red' : 'unset' }}
              />
              <span className={fileError ? 'text-danger' : ''}>
                &nbsp;{fileList[0].name}{' '}
              </span>
              <CloseOutlined
                className="ml-2 cursor-pointer"
                onClick={() => {
                  setFileList([]);
                  onChange(null);
                }}
              />
              <StyledProgress
                format={() => null}
                percent={percent}
                size="small"
                {...(fileError && { status: 'exception' })}
              />
            </div>
          )}
        </Col>
      </Row>

      <div>
        <small className="text-black--45">
          {translate(messages['MANUAL_ADJUSTMENT.UPLOAD_TEXT_HELPER'])}
        </small>
      </div>
    </>
  );
};
