import React, { useRef, useEffect, useState } from 'react';
import { BarChart, Bar } from 'recharts';

const Chart = ({
  chart,
  customBarColor,
  graph,
}: {
  chart?: number;
  customBarColor?: string;
  graph: Record<string, any>;
}) => {
  let dataPoints = graph?.dataPoints || [];
  const [width, setWidth] = useState(0);
  const ref = useRef() as any;

  useEffect(() => {
    if (ref?.current?.clientWidth) setWidth(ref?.current?.clientWidth);
  }, [setWidth, ref]);

  // Prepare data for Positive and Negative Chart table
  if (dataPoints && chart === 1) {
    const reformattedArray = dataPoints.map((obj: { pv: number }) => {
      const rObj: {
        uv?: number;
        pv?: number;
      } = {};
      // negative values must map to "uv"
      if (Math.sign(obj.pv) === -1) {
        rObj.uv = obj.pv;
      } else {
        rObj.pv = obj.pv;
      }
      return rObj;
    });
    dataPoints = reformattedArray;
  }

  return (
    <div ref={ref}>
      {chart === 1 ? (
        <BarChart width={width} height={55} data={dataPoints}>
          <Bar dataKey="pv" fill="#52C41A" />
          <Bar dataKey="uv" fill="#FF0062" />
        </BarChart>
      ) : (
        <BarChart width={width} height={55} data={dataPoints}>
          <Bar dataKey="pv" fill={customBarColor} />
        </BarChart>
      )}
    </div>
  );
};

export default Chart;
