import gql from 'graphql-tag';

export const DUPLICATE_AFFILIATE_PROGRAMME = gql`
  mutation duplicateAffiliateProgramme($id: ID!) {
    duplicateAffiliateProgramme(id: $id)
  }
`;

export const CREATE_AFFILIATE_PROGRAMME = gql`
  mutation createAffiliateProgramme($input: CreateAffiliateProgrammeInput) {
    createAffiliateProgramme(input: $input)
  }
`;

export const DELETE_AFFILIATE_PROGRAMME = gql`
  mutation deleteAffiliateProgramme($id: ID!) {
    deleteAffiliateProgramme(id: $id)
  }
`;
export const UPDATE_AFFILIATE_PROGRAMME = gql`
  mutation updateAffiliateProgramme(
    $id: ID!
    $input: UpdateAffiliateProgrammeInput
  ) {
    updateAffiliateProgramme(id: $id, input: $input)
  }
`;

export const SUBMIT_AFFILIATE_PROGRAMME = gql`
  mutation submitAffiliateProgramme($id: ID!) {
    submitAffiliateProgramme(id: $id)
  }
`;

export const UPDATE_AFFILIATE_REQUEST = gql`
  mutation updateAffiliateRequest(
    $id: ID!
    $input: UpdateAffiliateRequestInput!
  ) {
    updateAffiliateRequest(id: $id, input: $input)
  }
`;

export const UPDATE_AFFILIATE_CRITERIA = gql`
  mutation updateConfig($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

export const START_PROCESS_AFFILIATE_REQUEST = gql`
  mutation startProcessAffiliateRequest($id: ID!) {
    startProcessAffiliateRequest(id: $id)
  }
`;

export const STOP_PROCESS_AFFILIATE_REQUEST = gql`
  mutation stopProcessAffiliateRequest($id: ID!) {
    stopProcessAffiliateRequest(id: $id)
  }
`;

export const APPROVE_AFFILIATE_REQUEST = gql`
  mutation approveAffiliateRequest($id: ID!) {
    approveAffiliateRequest(id: $id)
  }
`;

export const REJECT_AFFILIATE_REQUEST = gql`
  mutation rejectAffiliateRequest($id: ID!) {
    rejectAffiliateRequest(id: $id)
  }
`;
