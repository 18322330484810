import styled from 'styled-components';
import { Input } from 'antd';

export const StyledNumberInput = styled(Input)`
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default {};
