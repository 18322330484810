import React from 'react';
import { FormattedMessage } from 'react-intl';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Icon } from '@ant-design/compatible';
import { Col, Popover, Tooltip } from 'antd';
import { FireFox } from 'utils/browserSpecific';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import Chart from './components/Chart';
import { StyledCard, StyledFooter } from './styles';
import messages from './messages';

type Rec = Record<string, any>;
type Props = {
  langId: string;
  chart?: number;
  customBarColor?: string;
  totalAmount: number;
  percentage: number;
  percentageLangId: string;
  label: string;
  graph: Rec;
};

const CardStat = ({
  langId,
  chart,
  customBarColor,
  totalAmount,
  percentage,
  percentageLangId,
  graph,
}: Props) => {
  const translate = useTranslate();
  const { addAdminCurrency } = useConfig();

  const content = (
    <div>
      <FormattedMessage
        id="tooltip-info.text"
        defaultMessage="This should show information about this graph."
      />
    </div>
  );

  const percentageIncrease = percentage > 0;

  return (
    <StyledCard>
      <div className={FireFox ? 'mb-3' : 'mb-2'}>
        <div className="float-left">{translate(messages[langId])}</div>
        <div className="float-right">
          <Popover placement="leftTop" content={content} trigger="click">
            <InfoCircleOutlined />
          </Popover>
        </div>
        <div className="clearfix" />
      </div>
      <Tooltip arrowPointAtCenter title={addAdminCurrency(totalAmount)}>
        <h3 className="text-truncate">{addAdminCurrency(totalAmount)}</h3>
      </Tooltip>
      <div className="bb-1">
        <Chart chart={chart} customBarColor={customBarColor} graph={graph} />
      </div>
      <StyledFooter className="pt-3">
        <Col>
          {translate(messages[percentageLangId])}
          {!!percentage && (
            <Icon
              type={percentageIncrease ? 'caret-up' : 'caret-down'}
              style={{ color: percentageIncrease ? '#52C41A' : '#F5222D' }}
            />
          )}{' '}
          {percentage}%
        </Col>
      </StyledFooter>
    </StyledCard>
  );
};

export default CardStat;
