import styled from 'styled-components';
import { Layout } from 'antd';
import Drawer from '../../../common/Drawer/Drawer';

const { Content, Header, Sider } = Layout;

export const Wrapper = styled.div`
  font-size: 16px;
  .offcanvas-header-full {
    .h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 22px;
      padding-left: 22px;
      width: 100%;
      height: 100%;
    }
    .ant-btn {
      font-size: 16px;
    }
  }

  .offcanvas-footer-full {
    .buttons {
      margin-left: 1000px;
    }
  }

  .footer-items {
    height: 100%;
    padding: 0 360px;
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const StyledHeader = styled(Header)`
  background: #fff !important;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  .ant-steps {
    max-width: 800px;
  }
`;

export const StyledSidebar = styled(Sider)`
  background: #fff !important;
  margin: 100px 0px 95px 100px;

  .sidebar-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;

    .pages {
      color: rgba(0, 0, 0, 0.65);

      .page {
        display: flex;
        flex-direction: row-reverse;
      }
      .selected-page {
        color: #1890ff;
      }
    }

    .steps {
      .vr {
        width: 2px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.15);
        margin-left: 16px;
      }
      .selected-step {
        background-color: #1890ff !important;
      }
    }
  }
`;

export const StyledContent = styled(Content)`
  background: #fff !important;

  .form-layout {
    max-width: 700px;
    margin: 0 auto;
  }

  .with-sider-layout {
    max-width: 1000px;
    margin: 100px 0px 95px 100px;
  }
`;

export const StyledLayout = styled(Layout)`
  height: 100%;
  padding: 0 !important;
  background: #fff !important;
`;

export const StyledDrawerFooter = styled(Drawer.Footer)`
  .container {
    max-width: 1124px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
  }
`;

export default {};
