import React, { useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  Input,
  TimePicker,
  message,
  Switch,
  Modal,
} from 'antd';
import { useFormik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import messages from 'messages';
import moment from 'moment';
import MoreActionsMembersFilter from 'components/MoreActionsMembersFilter';
import { get } from 'lodash';
import styled from 'styled-components';
import { ValidationSchema } from 'utils/formikValidations';
import gql from 'graphql-tag';
import useTranslate from 'utils/useTranslate';
import { FormItem, StyledForm } from './utils';

const maxTitleCount = 20;
const maxContentCount = 500;

const CREATE_MESSAGE = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input)
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

const StyledInput = styled(Input)`
  input.ant-input {
    padding-right: 125px !important;
  }
`;

type Rec = Record<string, any>;

export const SendMessage = ({
  members,
  CustomButton,
  isBulk,
  refetchFn,
}: {
  members: string[];
  CustomButton?: Function;
  isBulk?: boolean;
  refetchFn?: Function;
}) => {
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const [createMessage] = useMutation(CREATE_MESSAGE);
  const translate = useTranslate();
  const handleShow = () => {
    setShow(!isShow);
  };

  const onSubmit = ({
    userIds: ids,
    title,
    body,
    date,
    time,
    scheduleSend,
  }: Rec) => {
    const listOfIds = ids.map((data: { key: string }) => data.key);
    setLoading(true);

    const schedule = {
      ...(scheduleSend && {
        schedule: moment(new Date(`${date} ${time}`)).format(),
      }),
    };

    const createMessageInput = {
      input: {
        title,
        content: body,
        targetMembers: isBulk ? listOfIds : members,
        ...schedule,
      },
    };

    createMessage({
      variables: createMessageInput,
    })
      .then(() => {
        message.success(translate(messages.MESSAGE_CREATED));
        if (refetchFn) {
          refetchFn();
        }
        handleShow();
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
        handleShow();
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userIds: members || [],
      title: '',
      body: '',
      scheduleSend: false,
      date: null,
      time: null,
    },
    onSubmit,
    validationSchema: ValidationSchema.sendMessage(
      translate,
      maxTitleCount,
      maxContentCount,
      isBulk || false
    ),
  });

  useEffect(() => {
    if (isShow) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  const {
    handleSubmit,
    values,
    setFieldValue,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = formik;

  return (
    <>
      {CustomButton ? (
        <CustomButton onClick={handleShow} />
      ) : (
        <Button className="text-black" type="link" onClick={handleShow} block>
          <div>{translate(messages.SEND_MESSAGE)}</div>
        </Button>
      )}

      {isShow && (
        <StyledModal
          title={translate(messages.SEND_MESSAGE)}
          onCancel={handleShow}
          visible={isShow}
          footer={false}
        >
          <StyledForm>
            <div className="p-3">
              {isBulk && (
                <div className="mb-3">
                  <MoreActionsMembersFilter
                    value={values.userIds}
                    placeholder={translate(messages.ENTER_USERNAME)}
                    onChange={(e) => setFieldValue('userIds', e)}
                    validateStatus={
                      errors.userIds && touched.userIds ? 'error' : undefined
                    }
                    help={touched.userIds ? errors.userIds : null}
                  />
                </div>
              )}

              <FormItem
                label={translate(messages['title.text'])}
                touched={touched}
                errors={errors}
                keyType="title"
              >
                <StyledInput
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  suffix={
                    <div
                      className={`fs-12 ${
                        !!get(formik, 'touched.title', null) &&
                        get(formik, 'errors.title')
                          ? 'text-danger'
                          : 'text-muted-light'
                      }`}
                    >
                      {values.title.length}/{maxTitleCount}{' '}
                      {translate(messages.CHARACTERS)}
                    </div>
                  }
                />
              </FormItem>

              <FormItem
                label={translate(messages.CONTENT)}
                touched={touched}
                errors={errors}
                keyType="body"
              >
                <div className="position-relative">
                  <Input.TextArea
                    onBlur={handleBlur}
                    rows={4}
                    name="body"
                    value={values.body}
                    onChange={handleChange}
                  />
                  <small
                    className="position-absolute"
                    style={{
                      bottom: '-6px',
                      right: '19px',
                    }}
                  >
                    <span
                      style={{ background: 'rgba(255,255,255,0.80)' }}
                      className={`fs-12 ${
                        !!get(formik, 'touched.body', null) &&
                        get(formik, 'errors.body')
                          ? 'text-danger'
                          : 'text-muted-light'
                      }`}
                    >
                      {values.body.length}/{maxContentCount}{' '}
                      {translate(messages.CHARACTERS)}
                    </span>
                  </small>
                </div>
              </FormItem>

              <FormItem
                label={translate(messages.SCHEDULE_SEND)}
                touched={touched}
                errors={errors}
                keyType="scheduleSend"
              >
                <Switch
                  checked={values.scheduleSend}
                  onChange={(val) => setFieldValue('scheduleSend', val)}
                />
              </FormItem>
              {values.scheduleSend && (
                <div>
                  <FormItem
                    label={translate(messages.SELECT_DATE)}
                    touched={touched}
                    errors={errors}
                    keyType="date"
                  >
                    <DatePicker
                      onChange={(_, dateString) =>
                        setFieldValue('date', dateString)
                      }
                    />
                  </FormItem>

                  <FormItem
                    label={translate(messages.SELECT_TIME)}
                    touched={touched}
                    errors={errors}
                    keyType="time"
                  >
                    <TimePicker
                      format="HH:mm"
                      onChange={(_, timeString) =>
                        setFieldValue('time', timeString)
                      }
                    />
                  </FormItem>
                </div>
              )}
            </div>

            <div className="bt-1 p-3 text-right">
              <Button className="mr-2" onClick={handleShow} loading={isLoading}>
                {translate(messages['cancel.text'])}
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit as any}
                loading={isLoading}
              >
                {translate(messages.SEND_MESSAGE)}
              </Button>
            </div>
          </StyledForm>
        </StyledModal>
      )}
    </>
  );
};
