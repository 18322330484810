export const truncateMemberMarker = (memberMarkerName: string) => {
  if (!memberMarkerName) {
    return { text: null, isHoverable: false };
  }

  const isHoverable = memberMarkerName.length > 6;

  let text = memberMarkerName;

  if (isHoverable) {
    text = text
      .split('')
      .slice(0, 6)
      .join('');

    text += '...';
  }

  return { text, isHoverable };
};
