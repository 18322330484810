import React from 'react';
import { Button, Form, Input } from 'antd';

type PasswordRequest = {
  onPasswordReset: (email: string, username: string, adminCode: string) => void;
  loading: boolean;
};

const PasswordRequest: React.FC<PasswordRequest> = ({
  onPasswordReset,
  loading,
}) => {
  const [email, setEmail] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [adminCode, setAdminCode] = React.useState('');

  const onSubmit = async () => {
    if (!email && !username && !adminCode) {
      return;
    }

    onPasswordReset(email, username, adminCode);
  };

  return (
    <div>
      <p>
        Please enter your details below and a new password will be sent to the
        email address you provided
      </p>

      <Form layout="vertical">
        <Form.Item label="Admin Code">
          <Input
            value={adminCode}
            type="text"
            onChange={(e) => setAdminCode(e.target.value)}
            placeholder="Admin Code"
          />
        </Form.Item>
        <Form.Item label="Email Address">
          <Input
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
          />
        </Form.Item>

        <Form.Item label="Username">
          <Input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
        </Form.Item>
      </Form>

      <Button
        block
        className="mt-3"
        type="primary"
        onClick={onSubmit}
        loading={loading}
      >
        Continue
      </Button>
    </div>
  );
};

export default PasswordRequest;
