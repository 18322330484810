import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, message, Layout } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import Drawer from 'pages/components/common/Drawer/Drawer';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import { Wrapper } from 'SuperAdminMain/components/BetHistoryReport/components/Sidebar/components/SearchSettings/components/SearchSettingsList/styles';
import { CREATE_PERMISSION_GROUP } from 'graphql/mutations/permissionGroup.mutation';
import PermissionGroupForm from '../PermissionGroupForm';
import messages from '../../messages';

type Props = {
  refetch: () => void;
};

const NewPermissionGroup = (props: Props) => {
  const { refetch } = props;
  const newPermissionRef = React.useRef() as any;
  const { context } = useOperatorHeader();

  const [isShow, setIsShow] = useState(false);

  const toggleOverlay = () => {
    setIsShow((prevState) => !prevState);
    if (newPermissionRef.current) {
      newPermissionRef.current.resetForm();
    }
  };

  const translate = useTranslate();
  const [createPermissionGroup, { loading }] = useMutation(
    CREATE_PERMISSION_GROUP,
    {
      context,
      onCompleted: (data) => {
        if (data.createPermissionGroup) {
          message.success(translate(messages.CREATED_PERMISSION_GROUP));
        }
        refetch();
      },
    }
  );

  return (
    <>
      <Wrapper>
        <Button onClick={toggleOverlay} type="primary">
          <PlusOutlined /> {translate(messages.NEW_PERMISSION_GROUP)}
        </Button>
        <Drawer backdrop={false} open={isShow} onClose={toggleOverlay}>
          <Drawer.Header title={translate(messages.addnewtitle)} />
          <Drawer.Content>
            <Layout hasSider style={{ backgroundColor: '#fff' }}>
              <Layout.Content>
                <PermissionGroupForm
                  permissionGroup={{}}
                  ref={newPermissionRef}
                  onClose={toggleOverlay}
                  createPermissionGroup={createPermissionGroup}
                  loading={loading}
                />
              </Layout.Content>
            </Layout>
          </Drawer.Content>
          <Drawer.Footer>
            <>
              <Button
                className="mr-2"
                onClick={toggleOverlay}
                disabled={loading}
              >
                {translate(messages.cancel)}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={() => {
                  newPermissionRef.current.handleSubmit();
                }}
              >
                {translate(messages.confirm)}
              </Button>
            </>
          </Drawer.Footer>
        </Drawer>
      </Wrapper>
    </>
  );
};

export default NewPermissionGroup;
