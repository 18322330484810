import styled from 'styled-components';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

export const StyledCardContainer = styled.div<{
  tabsSameSize: boolean;
  globalSidebar?: boolean;
  updateAvailable?: boolean;
}>`
  padding-top: 80px;
  ${(props) => props.globalSidebar && 'padding-right: 55px'};
  flex-grow: 1;
  z-index: 1;

  .ant-tabs-nav-wrap {
    padding-top: 4px;
    background-color: #f3f8fb;
  }

  .ant-tabs-nav {
    display: ${(props) => (props.tabsSameSize ? 'block' : 'inline-block')};
  }

  .ant-tabs-nav > div {
    display: ${(props) => (props.tabsSameSize ? 'grid' : 'inherit')};
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  }

  .ant-tabs-nav-container-scrolling {
    padding: 0px !important;
  }

  .ant-tabs-tab-arrow-show {
    display: none;
  }

  .ant-tabs-card {
    height: 100%;
    > .ant-tabs-content {
      margin-top: -16px;
      > .ant-tabs-tabpane {
        background: #fff;
        padding: 16px;
      }
    }

    > .ant-tabs-nav:not(.ant-tabs-tab-active) {
      color: rgba(0, 0, 0, 0.65);
    }

    > .ant-tabs-nav {
      padding: 0;
      border-color: #fff;

      .ant-tabs-tab {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        border-radius: 8px 8px 0 0 !important;
        border-color: transparent !important;
        //background: transparent !important;
        margin-right: 0;
        border: none !important;
        padding: 0 ${(props) => (props.tabsSameSize ? '12px' : '16px')} !important;
        padding-right: ${(props) =>
          props.tabsSameSize ? '12px' : '28px'} !important;
        position: relative;

        .anticon {
          font-size: 10px;
          margin-left: 12px;
          position: absolute;
          top: 0;
          right: ${(props) => (props.tabsSameSize ? '5px' : '8px')};
          display: flex;
          height: 100%;
          align-items: center;
        }

        .truncate {
          margin-right: 5px;
        }
      }
    }
  }

  &.screen-tab {
    > .ant-tabs {
      > .ant-tabs-nav {
        position: fixed;
        display: flex;
        top: ${(props) => (props.updateAvailable ? '80px' : '40px')};
        ${(props) =>
          props.globalSidebar ? 'width: calc(100% - 55px)' : 'width: 100%'};
        z-index: 100;

        > .ant-tabs-nav-wrap {
          .ant-tabs-tab-active {
            border: none !important;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
            background: #fff !important;

            .anticon {
              color: var(--link-text-color);
            }
          }
        }
      }

      > .ant-tabs-content {
        margin-top: 0;
        z-index: 10;
      }
    }
  }
`;

export const StyledTabPane = styled(TabPane)`
  padding: 0 !important;
`;

export default {};
