import gql from 'graphql-tag';

export const ME = gql`
  query Me {
    me {
      id
      username
      realName
      gender
      email
      mobileNumber
      wechatId
      qqId
      role
      timezone
      email
      language
      forceChangePassword
      ... on Operator {
        company
        permissions
        multiCurrencyEnabled
        linkedAdmins {
          id
          company
        }
      }
      ... on Admin {
        adminCode
        company
        multiCurrencyEnabled
        linkedAdmins {
          id
          company
        }
        attachments {
          id
          uri
        }
      }
      __typename
    }
    config {
      multipleSession
      checkFraud
      bannerAutomaticScrollingEnabled
      bannerScrollingSpeed
      emailVerificationEnabled
      enableWithdrawalCancelation
      marqueeMessageSpeed
      mobileNumberVerificationEnabled
      timezone
      enableLoqateIntegration
      checkGamstop
      checkDepositLimit
      __typename
    }
  }
`;

export default {};
