import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { SelectProps } from 'interfaces/select.interface';
import { PAYMENT_METHODS } from './queries';

const { Option } = Select;

const PaymentMethodsSelect = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
  labelAsValue,
}: SelectProps) => {
  const [filter, setFilter] = useState({
    name: {
      contains: '',
    },
  });

  const { loading, data = {}, refetch } = useQuery(PAYMENT_METHODS, {
    variables: {
      filter,
      first: 10,
    },
    fetchPolicy: 'network-only',
  });

  const handleSearch = (e: string) => {
    setFilter({
      name: {
        contains: e,
      },
    });
    refetch();
  };

  const handleChange = (e: any) => {
    onChange(e);
  };

  const { paymentMethods = {} }: Record<string, any> = data;
  const { edges = [] } = paymentMethods;

  return (
    <Select
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      loading={loading}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
    >
      {// eslint-disable-next-line no-shadow
      edges.map(({ node }: Record<string, any>, key: number) => (
        <Option key={key} value={labelAsValue ? node.label : node.value}>
          {node.label}
        </Option>
      ))}
    </Select>
  );
};

export default PaymentMethodsSelect;
