import { EllipsisOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Dropdown, Menu, Spin, Table } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import { AUTOMATED_MESSAGES } from 'graphql/queries/automatedMessage.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePermissions } from 'store/accountState';
import { StyledCenter } from 'styles';
import { customFormatMessage } from 'utils/customFormatMessage';
import { EditMessage } from '../../components/EditMessage/EditMessage';
import { MessageTooltip } from '../../components/MessageTooltip/MessageTooltip';
import { Switch, switchType } from '../../components/Switch/Switch';
import { messages } from '../messages';

const events = [
  'PROMO_RECEIVED',
  'PROMO_PROCESSING',
  'PROMO_APPROVED',
  'PROMO_REJECTED',
  'PROMO_AUTOPAYOUT',
  'PROMO_MANUAL_CLAIM',
];

const variables = {
  manualApproval: [
    'Username',
    'RealName',
    'PromoName',
    'RequestDateTime',
    'Amount',
    'CurrBalance',
    // 'Remarks',
  ],
  automaticPayout: [
    'Username',
    'RealName',
    'PromoName',
    'Amount',
    'CurrBalance',
    // 'Remarks',
  ],
};

export const Promotions: React.FC = () => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_TOGGLE_STATE, ALLOWED_EDIT } = collectPermissions(
    role,
    permissions,
    ['TOGGLE_STATE', 'EDIT'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MESSAGES.SYSTEM_MESSAGES}:AUTOMATIC_MESSAGE`
  );

  const { formatMessage } = useIntl();
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  const statuses = {
    PROMO_RECEIVED: translate(messages['received.text']),
    PROMO_PROCESSING: translate(messages['processing.text']),
    PROMO_APPROVED: translate(messages['approved.text']),
    PROMO_REJECTED: translate(messages['rejected.text']),
    PROMO_AUTOPAYOUT: translate(messages['disbursed.text']),
    PROMO_MANUAL_CLAIM: translate(messages['claimable.text']),
  };

  const queryParameters = {
    filter: {
      event: {
        in: events,
      },
    },
  };

  const { loading, data, refetch } = useQuery(AUTOMATED_MESSAGES, {
    variables: queryParameters,
  });

  if (loading) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  const {
    automatedMessages: { edges },
  } = data;

  let automatedMessages = edges.map(({ node }: any) => node);
  const result: any = [];

  events.forEach((event: string) => {
    automatedMessages = automatedMessages.filter((item: any) => {
      if (item.event === event) {
        let type = '';
        let myVars = variables.manualApproval;

        switch (event) {
          case 'PROMO_RECEIVED':
          case 'PROMO_PROCESSING':
          case 'PROMO_APPROVED':
          case 'PROMO_REJECTED':
            type = translate(messages['manual-approval.text']);
            break;
          case 'PROMO_AUTOPAYOUT':
            type = translate(messages['automatic-payout.text']);
            myVars = variables.automaticPayout;
            break;
          default:
        }

        const newItem = { ...item };
        newItem.key = newItem.id;
        newItem.type = type;
        newItem.variables = myVars;
        result.push(newItem);
      }
      return !(item.event === event);
    });
  });

  const columns = [
    {
      title: (
        <FormattedMessage
          id="deposit-request.on-off.text"
          defaultMessage="On / Off"
        />
      ),
      dataIndex: 'enabled',
      width: 96,
      render: (enabled: boolean, values: any) =>
        ALLOWED_TOGGLE_STATE && (
          <Switch
            id={values.id}
            type={switchType.TABLE}
            toggle={enabled}
            refetch={refetch}
          />
        ),
    },
    {
      title: <FormattedMessage id="type.text" defaultMessage="Type" />,
      dataIndex: 'type',
      width: 136,
      render: (type: string) => type || '-',
    },
    {
      title: <FormattedMessage id="status.text" defaultMessage="Status" />,
      dataIndex: 'event',
      width: 96,
      render: (event: string) => statuses[event] || '-',
    },
    {
      title: <FormattedMessage id="english.text" defaultMessage="English" />,
      width: 561,
      render: (values: any) => (
        <MessageTooltip
          message={{ title: values.title_en, content: values.content_en }}
        />
      ),
      ellipsis: true,
    },
    {
      title: '',
      fixed: 'right',
      render: (values: any) =>
        ALLOWED_EDIT && (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item className="p-0">
                  <EditMessage
                    status={statuses[values.event]}
                    variables={values.variables}
                    messages={values}
                    refetchParams={queryParameters}
                  />
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
          >
            <EllipsisOutlined
              className="cursor-pointer font-weight-bold mr-2"
              style={{ fontSize: '25px' }}
            />
          </Dropdown>
        ),
    },
  ] as any;

  return (
    <Table
      tableLayout="fixed"
      dataSource={result}
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
    />
  );
};
