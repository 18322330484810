import { message, Switch } from 'antd';
import ConfirmationModal from 'components/ConfirmationModal';
import React, { useState, FC } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { UPDATE_PROMO_NEXT } from 'graphql/mutations/promo.mutation';
import { MutationUpdatePromoArgs, PromoStatus } from 'types/graphqlTypes';

type Props = {
  enumValue?: string;
  label?: string;
  confirmationMessage: string;
  id?: string;
  hasCheckedChildren: boolean;
  refetch: Function;
};

const ToggleSwitch: FC<Props> = ({
  enumValue,
  label,
  confirmationMessage,
  hasCheckedChildren = true,
  id,
  refetch,
}) => {
  const mappedEnumValues = {
    ACTIVE: true,
    INACTIVE: false,
  };

  const [checked, setChecked] = useState(mappedEnumValues[enumValue!]);
  const [visible, setVisible] = useState(false);

  const { context } = useOperatorHeader();

  const [updatePromoStatus, { loading }] = useMutation<
    PromoStatus,
    MutationUpdatePromoArgs
  >(UPDATE_PROMO_NEXT, {
    context,
  });

  const onChange = (checkValue: boolean) => {
    setChecked(checkValue);
    setVisible(true);
  };

  const onConfirm = async (): Promise<void> => {
    await updatePromoStatus({
      variables: {
        id: id as string,
        input: {
          status: checked ? PromoStatus.Active : PromoStatus.Inactive,
        },
      },
    }).then(() => {
      message.success('Promo status updated.');
      refetch();
      setVisible(false);
    });
  };

  const onCancel = () => {
    setVisible(false);
    setChecked(!checked);
  };

  React.useEffect(() => {
    setChecked(mappedEnumValues[enumValue!]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enumValue]);

  return (
    <div>
      {label && <h3>{label}</h3>}
      <Switch
        onChange={onChange}
        checked={checked || undefined}
        {...(hasCheckedChildren && {
          checkedChildren: 'On',
          unCheckedChildren: 'Off',
        })}
      />
      <ConfirmationModal
        title="Are You Sure?"
        message={confirmationMessage}
        visible={visible}
        onOk={onConfirm}
        onCancel={onCancel}
        okButtonProps={loading}
      />
    </div>
  );
};

export default ToggleSwitch;
