import gql from 'graphql-tag';

export const PAYMENT_METHODS = gql`
  query PaymentMethods(
    $first: Int
    $after: Binary
    $filter: PaymentMethodsFilterInput
  ) {
    paymentMethods(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          value: id
          label: name
        }
      }
    }
  }
`;

export default {};
