import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import messages from 'pages/components/Reports/messages';

import {
  useSetDateUtils,
  SetDateFilterStateType,
  DateStateType,
} from 'utils/globalSetDateUtils';
import useTranslate from 'utils/useTranslate';
import { FilterStateType, SetFilterStateType } from '../../../../types';

import { StyledRangePicker } from './styles';
import { StyledSpan, StyledLabel } from '../../styles';

type Props = {
  state: FilterStateType;
  setState: SetFilterStateType;
};

type DateTypes = {
  state: DateStateType;
  setState: SetDateFilterStateType;
};

const DateRangeFilter: React.FC<Props> = (props) => {
  const { state, setState } = props as DateTypes;

  const translate = useTranslate();
  const {
    dateInputValue,
    handleLastSevenDays,
    handleLastWeek,
    handleLastThirtyDays,
    handleChangeDateFilter,
  } = useSetDateUtils(state, setState, 'dateTimeProcessed');

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.period.text"
              defaultMessage="Period"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan
            onClick={() =>
              setState((prev) => ({
                ...prev,
                dateTimeProcessed: null,
              }))
            }
          >
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledRangePicker
        className="mb-2"
        placeholder={[
          translate(messages['reports.from.text']),
          translate(messages['reports.to.text']),
        ]}
        format="YYYY/MM/DD"
        onChange={handleChangeDateFilter}
        value={dateInputValue as any}
      />

      <div>
        <Button size="small" className="mb-1" onClick={handleLastSevenDays}>
          <FormattedMessage
            id="last-7-days.text"
            defaultMessage="Last 7 days"
          />
        </Button>
        <Button size="small" className="mb-1" onClick={handleLastWeek}>
          <FormattedMessage id="last-week.text" defaultMessage="Last week" />
        </Button>
        <Button size="small" className="mb-1" onClick={handleLastThirtyDays}>
          <FormattedMessage
            id="last-30-days.text"
            defaultMessage="Last 30 days"
          />
        </Button>
      </div>
    </>
  );
};

export default DateRangeFilter;
