import React, { useState } from 'react';
import { message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useMutation } from '@apollo/react-hooks';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import removeNull from 'utils/removeNull';
import { QuickSearchIds } from 'components/QuickSearchFilter/QuickSearchFilter';
import SearchSettingsForm from '../SearchSettingsForm';
import { EDIT_CUSTOM_FILTER } from './mutations';
import { CUSTOM_FILTERS } from '../SearchSettingsList/queries';

type EditSearchSettingsTypes = {
  searchSettings: Record<string, any>;
  onClose: () => void;
};

const EditSearchSettings = ({
  searchSettings,
  onClose,
}: EditSearchSettingsTypes) => {
  const [isLoading, setLoading] = useState(false);
  const translate = useTranslate();
  const [updateCustomFilter] = useMutation(EDIT_CUSTOM_FILTER);
  const handleSubmit = async (fields: any) => {
    try {
      setLoading(true);
      if (!isEmpty(removeNull(fields))) {
        await updateCustomFilter({
          variables: {
            id: searchSettings.id,
            input: {
              name: searchSettings.name,
              filter: removeNull(fields),
            },
          },
          refetchQueries: [
            {
              query: CUSTOM_FILTERS,
              variables: {
                filter: {
                  context: {
                    eq: QuickSearchIds.SA_VENDOR_USAGE_REPORT,
                  },
                },
              },
            },
          ],
        });
        message.success(translate(messages['search-settings-updated.text']));
        setLoading(false);
      } else {
        message.error(
          translate(messages['cannot-update-filter-empty-error.text'])
        );
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <SearchSettingsForm
      initialValues={searchSettings.filter}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      onClose={onClose}
    />
  );
};

export default EditSearchSettings;
