import React from 'react';
import { Rate } from 'antd';
import { useStarred } from 'store/accountState';
import { MemberInfo } from 'store/accountState/utils';

type OwnProps = {
  member: MemberInfo;
};

function AddToWatchlist(props: OwnProps) {
  const { member } = props;
  const { getStarredMember, starMember, unstarMember } = useStarred();

  const starredMember = getStarredMember(member.id);

  const toggleStar = () =>
    !starredMember?.starred ? starMember(member) : unstarMember(member);

  return (
    <span data-testid="watchlist">
      <Rate
        count={1}
        value={starredMember?.starred ? 1 : 0}
        onChange={toggleStar}
      />
    </span>
  );
}

export default AddToWatchlist;
