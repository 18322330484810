import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import Layout from 'components/Layout';
import { DynamicObj } from 'interfaces/user.interface';
import FilterItems from '../FilterItems/FilterItems';

type Props = {
  open: boolean;
  filters: {
    serialCode: string[] | null;
    status: string[] | null;
  };
  setFilters: React.Dispatch<
    React.SetStateAction<{
      serialCode: null;
      status: null;
    }>
  >;
};

const Sidebar = ({ open, filters, setFilters }: Props) => {
  const handleChange = ({
    key,
    value,
  }: {
    key: string;
    value: DynamicObj | null;
  }) =>
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));

  return (
    <Layout.Sidebar
      sidebarStatus={open}
      quickSearch={{
        filters: {},
        contextKey: 'affiliate-request',
        editSearchSettings: <></>,
        onFilterChange: () => {},
      }}
    >
      <Form layout="vertical">
        <FilterItems filters={filters} onRawFilterChange={handleChange} />
      </Form>
    </Layout.Sidebar>
  );
};

export default Sidebar;
