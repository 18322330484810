import styled from 'styled-components';
import { Layout } from 'antd';

export const FilterContainer = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const CostSettingsSider = styled(Layout.Sider)`
  background-color: #ffffff;
  border-left: 1px solid #e8e8e8;
  border-top: 1px solid #e8e8e8;
`;

export default {};
