import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
  }
`;

export const StyledInput = styled(Input)`
  input.ant-input {
    padding-right: 125px !important;
  }
`;

export default {};
