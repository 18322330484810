import gql from 'graphql-tag';

export const WITHDRAWAL_MEMBER_STATUS = gql`
  query MEMBER($id: ID) {
    withdrawal(id: $id) {
      id
      status
      externalStatus
    }
  }
`;

export default {};
