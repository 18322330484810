import styled from 'styled-components';
import { MemberStatus } from 'types/graphqlTypes';

const colorByStatus = (status: MemberStatus) => {
  if (status === MemberStatus.Enabled) {
    return {
      bgColor: '#32cd32',
      borderColor: '#00cc00',
    };
  }

  return {
    bgColor: '#f57069',
    borderColor: '#8d0600',
  };
};

export const MemberStatusBadge = styled.button<{ status: MemberStatus }>`
  max-height: 23px;
  overflow: hidden;
  border-radius: 100px;
  outline: none;
  background-color: ${(props: any) => colorByStatus(props?.status)?.bgColor};
  border: 1px solid ${(props: any) => colorByStatus(props?.status)?.borderColor};
  color: white;
  padding: 0 10px;
  cursor: default;
`;
