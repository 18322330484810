import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'dashboard.first-deposit.text': {
    id: 'dashboard.first-deposit.text',
    defaultMessage: 'First Deposit',
  },
  'dashboard.total-bets-count.text': {
    id: 'dashboard.total-bets-count.text',
    defaultMessage: 'Total Bets Count',
  },
  'dashboard.total-bet-amount.text': {
    id: 'dashboard.total-bet-amount.text',
    defaultMessage: 'Total bet amount',
  },
  'dashboard.total-withdrawal-amount.text': {
    id: 'dashboard.total-withdrawal-amount.text',
    defaultMessage: 'Total withdrawal amount',
  },
  'dashboard.total-deposit-amount.text': {
    id: 'dashboard.total-deposit-amount.text',
    defaultMessage: 'Total deposit amount',
  },
  'dashboard.amount-of-deposits.text': {
    id: 'dashboard.amount-of-deposits.text',
    defaultMessage: 'Amount of Deposits',
  },
  'dashboard.total-winloss-amount.text': {
    id: 'dashboard.total-winloss-amount.text',
    defaultMessage: 'Total win/loss amount',
  },
  'dashboard.amount-of-win-loss.text': {
    id: 'dashboard.amount-of-win-loss.text',
    defaultMessage: 'Amount of Win/Loss',
  },
  'dashboard.total-net-profit-amount.text': {
    id: 'dashboard.total-net-profit-amount.text',
    defaultMessage: 'Total net profit amount',
  },
  'dashboard.amount-of-net-profit.text': {
    id: 'dashboard.amount-of-net-profit.text',
    defaultMessage: 'Amount of Net Profit',
  },
  'dashboard.number-of-deposit-request.text': {
    id: 'dashboard.number-of-deposit-request.text',
    defaultMessage: 'Number of Deposit Request',
  },
  'dashboard.number-of-unique-accounts-doing-deposits.text': {
    id: 'dashboard.number-of-unique-accounts-doing-deposits.text',
    defaultMessage: 'Number of Unique Accounts Doing Deposits',
  },
  'dashboard.amountof-withdrawals.text': {
    id: 'dashboard.amountof-withdrawals.text',
    defaultMessage: 'Amount of Withdrawals',
  },
  'dashboard.number-of-withdrawals-request.text': {
    id: 'dashboard.number-of-withdrawals-request.text',
    defaultMessage: 'Number of Withdrawals Request',
  },
  'dashboard.number-of-unique-accounts doing-withdrawals.text': {
    id: 'dashboard.number-of-unique-accounts doing-withdrawals.text',
    defaultMessage: 'Number of Unique Accounts Doing Withdrawals',
  },
});

export default messages;
