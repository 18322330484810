import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import SelectFilterField from 'components/SelectFilterField';
import { WITHDRAWAL_PROVIDERS } from 'graphql/queries/withdrawalProvider.query';
import useTranslate from 'utils/useTranslate';
import { FilterItem } from 'components/FilterItem/FilterItem';
import ThirdPartyProvidersTypeFilter from 'components/ThirdPartyProvidersTypeFilter';
import messages from '../../messages';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
};

export default ({ filters, onRawFilterChange: handleChange }: Props) => {
  const translate = useTranslate();

  return (
    <>
      <SelectFilterField
        testId="withdrawal-providers-name-filter"
        partialSupported={false}
        label="Provider Name"
        query={WITHDRAWAL_PROVIDERS}
        queryConnection="withdrawalProviders"
        filterFieldName="name"
        filters={filters}
        onChange={(e) =>
          handleChange({
            name: 'name',
            value: e?.length ? { in: e } : null,
          })
        }
      />

      <FilterItem label={translate(messages['type.text'])}>
        <ThirdPartyProvidersTypeFilter
          values={filters.type ? filters.type.in || [] : []}
          onChange={(e) =>
            handleChange({
              name: 'type',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>
    </>
  );
};
