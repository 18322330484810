import gql from 'graphql-tag';

export const MEMBER_BET_RECORD_GAME_TITLES = gql`
  query MemberBetRecordsGameTitles(
    $first: Int
    $after: Binary
    $filter: MemberBetRecordsFilterInput
  ) {
    memberBetRecords(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          game {
            id
            name
            zhName: name(language: ZH)
            enName: name(language: EN)
          }
        }
      }
    }
  }
`;

export default {};
