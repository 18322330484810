import React from 'react';

export const memberLoyaltyScreens = {
  PROGRAMME_DETAILS: 'PROGRAMME_DETAILS',
  PROGRAM_TABLE_LISTING: 'PROGRAM_TABLE_LISTING',
};

export const allTypes = {
  SET_ACTIVE_SCREEN: 'SET_ACTIVE_SCREEN',
  SET_REFETCH_VARIABLES: 'SET_REFETCH_VARIABLES',
  SET_TOTAL_PROG_COUNT: 'SET_TOTAL_PROG_COUNT',
  ...memberLoyaltyScreens,
};

export const MemberLoyalty = React.createContext(null);

type Props = {
  children: React.ReactNode;
};

export function MemberLoyaltyProvider({ children }: Props) {
  const initialState = {
    activeScreen: allTypes.PROGRAM_TABLE_LISTING,
    currentProgramId: null,
    refetchVariables: null,
    totalProgCount: 0,
  };

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case allTypes.SET_ACTIVE_SCREEN:
        return {
          ...state,
          activeScreen: action.payload,
        };
      case allTypes.SET_REFETCH_VARIABLES:
        return {
          ...state,
          refetchVariables: action.payload,
        };
      case allTypes.SET_TOTAL_PROG_COUNT:
        return {
          ...state,
          totalProgCount: action.payload,
        };
      default:
        return state;
    }
  };

  return (
    <MemberLoyalty.Provider
      value={React.useReducer(reducer, initialState) as any}
    >
      {children}
    </MemberLoyalty.Provider>
  );
}

export const useMemberLoyaltyProgrammeContext = () =>
  React.useContext(MemberLoyalty) as any;
