import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import coercedGet from 'utils/coercedGet';

import { setDecimals } from 'utils/setDecimals';
import { CurrencyMetadata, RebateGroup } from 'types/graphqlTypes';
import { formatDate } from 'utils/dateUtils';
import { DATE_FORMAT } from 'constants/date';
import PayoutSettlementPeriodColumn from './components/PayoutSettlementPeriodColumn';
import TotalNumberOfParticipatingMemberColumn from './components/TotalNumberOfParticipatingMemberColumn';
import AutoPayoutColumn from './components/AutoPayoutColumn';
import messages from './messages';

export const getColumns = (
  translate: (messageArgs: any) => string,
  currency: CurrencyMetadata
) => [
  {
    csvData: {
      label: 'Date/Time Created',
      key: 'dateTimeCreated',
      renderCell: (rebate: RebateGroup) =>
        coercedGet(rebate, 'dateTimeCreated', '-'),
    },
    defaultSortOrder: 'descend',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: true,
    title: 'Date/Time Created',
    render: (value: string) => (
      <span data-testid="dateTimeCreated">
        {formatDate(value, DATE_FORMAT)}
      </span>
    ),
    key: 'dateTimeCreated',
    dataIndex: 'dateTimeCreated',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['rebate-programme-name.text']),
      key: 'name',
      renderCell: (rebate: any) => coercedGet(rebate, 'name', '-'),
    },
    title: translate(messages['rebate-programme-name.text']),
    render: (value: string) => <span data-testid="name">{value}</span>,
    key: 'name',
    dataIndex: 'name',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['payout-time-set.text']),
      key: 'payoutSettlementTime',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'payoutSettlementTime', '-');
        return temp === '-'
          ? temp
          : moment(
              `${new Date()
                .toISOString()
                .split('T')
                .shift()} ${temp}`
            ).format('HH:mm');
      },
    },
    title: translate(messages['payout-time-set.text']),
    render: (value: string) => (
      <span data-testid="payoutSettlementTime">
        {value
          ? moment(
              `${new Date()
                .toISOString()
                .split('T')
                .shift()} ${value}`
            ).format('HH:mm')
          : '-'}
      </span>
    ),
    key: 'payoutSettlementTime',
    dataIndex: 'payoutSettlementTime',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['settlement-method.text']),
      key: 'type',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'type', '-');
        return temp === '-' ? temp : translate(messages[temp]);
      },
    },
    title: translate(messages['settlement-method.text']),
    render: (value: string) => (
      <span data-testid="type">
        <PayoutSettlementPeriodColumn value={value} />
      </span>
    ),
    key: 'type',
    dataIndex: 'type',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['qualifying-vip-level.text']),
      key: 'qualifyingMemberLoyaltyLevels',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'qualifyingMemberLoyaltyLevels', []);
        return temp.length;
      },
    },
    title: translate(messages['qualifying-vip-level.text']),
    render: (value: any) => (value ? value.length : 0),
    key: 'qualifyingMemberLoyaltyLevels',
    dataIndex: 'qualifyingMemberLoyaltyLevels',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['rebate-programme-period.text']),
      key: 'validityDateTimeRange',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'validityDateTimeRange', {});
        if (!coercedGet(temp, 'start', '') && !coercedGet(temp, 'end', '')) {
          return '-';
        }
        return `${
          coercedGet(temp, 'start', '')
            ? moment(temp.start).format(DATE_FORMAT)
            : ''
        } - ${
          coercedGet(temp, 'end', '')
            ? moment(temp.end).format(DATE_FORMAT)
            : translate(messages['not-set.text'])
        }`;
      },
    },
    title: translate(messages['rebate-programme-period.text']),
    render: (data: any) => (
      <span date-testid="validityDateTimeRange">
        {!coercedGet(data, 'start', '') && !coercedGet(data, 'end', '') ? (
          <div>-</div>
        ) : (
          <div>
            <span data-testid="rebate-programme-period">
              <span>
                {coercedGet(data, 'start', '')
                  ? moment(data.start).format(DATE_FORMAT)
                  : ''}
              </span>
              <span> - </span>
              <span>
                {coercedGet(data, 'end', '')
                  ? moment(data.end).format(DATE_FORMAT)
                  : `[${translate(messages['not-set.text'])}]`}
              </span>
            </span>
          </div>
        )}
      </span>
    ),
    key: 'validityDateTimeRange',
    dataIndex: 'validityDateTimeRange',
    width: 200,
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['total-number-of-participating-member.text']),
      key: 'participatingMembers',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'participatingMembers.totalCount', 0);
        return temp;
      },
    },
    title: translate(messages['total-number-of-participating-member.text']),
    render: (record: any) => (
      <span
        style={{ width: '100px' }}
        date-testid="totalParticipatingMembersCount"
      >
        <TotalNumberOfParticipatingMemberColumn data={record} />
      </span>
    ),
    key: 'participatingMembers',
    width: '10%',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['total-number-of-qualifying-member.text']),
      key: 'qualifiedMembersCount',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'qualifiedMembersCount', 0);
        return temp;
      },
    },
    title: translate(messages['total-number-of-qualifying-member.text']),
    render: (text: string) => (
      <span data-testid="qualifiedMembersCount">{text}</span>
    ),
    key: 'qualifiedMembersCount',
    dataIndex: 'qualifiedMembersCount',
    width: 200,
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['auto-payout.text']),
      key: 'automaticPayout',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'automaticPayout', false);
        return `${translate(messages[`${temp ? 'YES' : 'NO'}`])}`;
      },
    },
    title: translate(messages['auto-payout.text']),
    render: (value: boolean) => (
      <span data-testid="automaticPayout">
        <AutoPayoutColumn value={value ? 'YES' : 'NO'} />
      </span>
    ),
    key: 'automaticPayout',
    dataIndex: 'automaticPayout',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['total-amount-payout.text']),
      key: 'totalPayout',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'totalPayout', 0);
        return `${currency?.symbol} ${setDecimals(temp, 3)}`;
      },
    },
    title: translate(messages['total-amount-payout.text']),
    render: (value: string) => (
      <span data-testid="totalPayout">
        {currency?.symbol} {setDecimals(value, 3)}
      </span>
    ),
    key: 'totalPayout',
    dataIndex: 'totalPayout',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['total-amount-to-be-payout.text']),
      key: 'totalAmountToBePayout',
      renderCell: () => translate(messages['not-available.text']),
    },
    title: translate(messages['total-amount-to-be-payout.text']),
    render: () => (
      <FormattedMessage
        id="not-available.text"
        defaultMessage="Not Available"
      />
    ),
    key: 'totalAmountToBePayout',
    dataIndex: 'totalAmountToBePayout',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['total-number-of-user-claimed.text']),
      key: 'payoutClaimantsCount',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'payoutClaimantsCount', 0);
        return `${currency?.symbol} ${setDecimals(temp, 3)}`;
      },
    },
    title: translate(messages['total-number-of-user-claimed.text']),
    render: (value: string) => (
      <span data-testid="payoutClaimantsCount">
        {currency?.symbol} {setDecimals(value, 3)}
      </span>
    ),
    key: 'payoutClaimantsCount',
    dataIndex: 'payoutClaimantsCount',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['total-amount-claimed.text']),
      key: 'totalClaimedPayout',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'totalClaimedPayout', 0);
        return `${currency?.symbol} ${setDecimals(temp, 3)}`;
      },
    },
    title: translate(messages['total-amount-claimed.text']),
    render: (text: string) => (
      <span data-testid="totalClaimedPayout">
        {currency?.symbol} {setDecimals(text, 3)}
      </span>
    ),
    key: 'totalClaimedPayout',
    dataIndex: 'totalClaimedPayout',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['amount-expired.text']),
      key: 'totalExpiredPayout',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'totalExpiredPayout', 0);
        return `${currency?.symbol}  ${setDecimals(temp, 3)}`;
      },
    },
    title: translate(messages['amount-expired.text']),
    render: (text: string) => (
      <span data-testid="totalExpiredPayout">
        {currency?.symbol} {setDecimals(text, 3)}
      </span>
    ),
    key: 'totalExpiredPayout',
    dataIndex: 'totalExpiredPayout',
    align: 'center',
  },
  {
    csvData: {
      label: translate(messages['clawback.text']),
      key: 'totalClawback',
      renderCell: (rebate: any) => {
        const temp = coercedGet(rebate, 'totalClawback', 0);
        return `${currency?.symbol}  ${setDecimals(temp, 3)}`;
      },
    },
    title: translate(messages['clawback.text']),
    render: (value: string) => (
      <span data-testid="totalClawback">
        {currency?.symbol} {setDecimals(value, 3)}
      </span>
    ),
    key: 'totalClawback',
    dataIndex: 'totalClawback',
    align: 'center',
  },
];

export default {};
