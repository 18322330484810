import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Member } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import EditMemberLevelMenuForm from './components/EditMemberLevelMenuForm';
import messages from './messages';
import { StyledModal } from './styles';

type Props = {
  member: Member;
  onSuccess?: (data: string) => void;
  refetch?: Function;
};

const EditMemberLevelMenu = ({ member, onSuccess, refetch }: Props) => {
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const translate = useTranslate();

  const handleSubmit = async (e: Record<string, any>) => {
    setLoading(true);
    try {
      const input = { ...e };
      delete input.type;
      updateMember({
        variables: {
          id: member.id,
          input: {
            memberLevel: input.memberLevel[0].id,
          },
        },
      }).then(() => {
        if (refetch) refetch();
      });

      message.success(
        translate(
          messages['member-management.member-level-update-success.text']
        )
      );
      setLoading(false);
      if (onSuccess) onSuccess(member.id);
      handleShow();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        type="link"
        className="text-black"
        onClick={handleShow}
        block
        role="button"
      >
        <FormattedMessage
          id="edit-member-marker.text"
          defaultMessage="Edit member marker"
        />
      </Button>
      <StyledModal
        title={
          <FormattedMessage
            id="edit-member-marker.text"
            defaultMessage="Edit member marker"
          />
        }
        onCancel={handleShow}
        visible={isShow}
        footer={false}
      >
        <EditMemberLevelMenuForm
          initialValues={{
            id: member.id,
            users: [member.id],
            memberLevel: member.memberLevel,
            userSelected: [member],
          }}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onClose={handleShow}
        />
      </StyledModal>
    </>
  );
};

export default EditMemberLevelMenu;
