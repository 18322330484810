import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { message } from 'antd';
import { defineMessages } from 'react-intl';
import { useForm } from 'react-hook-form';
import Drawer from 'SuperAdminMain/shared/components/Drawer/Drawer';
import DrawerActionButtons from 'SuperAdminMain/shared/components/DrawerActionButtons';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';
import { VendorGroup } from 'types/graphqlTypes';
import { PublishVendorDrawer } from '../PublishVendorDrawer/PublishVendorDrawer';
import { NewVendorGroupDrawer } from '../NewVendorGroup/NewVendorGroupDrawer';

const messagesLocal = defineMessages({
  SAVED_VENDOR_GROUP_DRAFT: {
    id: 'SAVED_VENDOR_GROUP_DRAFT',
    defaultMessage: 'Vendor group has been saved as draft',
  },
  CREATED_VENDOR_GROUP: {
    id: 'CREATED_VENDOR_GROUP',
    defaultMessage: 'Vendor group has been created',
  },
  VENDOR_GROUP_NAME_ERROR: {
    id: 'VENDOR_GROUP_NAME_ERROR',
    defaultMessage: 'Vendor group name is required',
  },
});

const CREATE_VENDOR_GROUP = gql`
  mutation CreateVendorGroup($input: CreateVendorGroupInput!) {
    createVendorGroup(input: $input)
  }
`;

const UPDATE_VENDOR_GROUP = gql`
  mutation UpdateVendorGroup($id: ID!, $input: UpdateVendorGroupInput!) {
    updateVendorGroup(id: $id, input: $input)
  }
`;

const SUBMIT_VENDOR_GROUP = gql`
  mutation SubmitVendorGroup($id: ID!) {
    submitVendorGroup(id: $id)
  }
`;

export const NewVendorDrawerContainer = ({
  openDrawer,
  closeDrawer,
  refetchFn,
  existingValues,
}: Props) => {
  const [vendorInfoDrawer, setVendorInfoDrawer] = useState<boolean>(true);
  const [publishVendorDrawer, setPublishVendorDrawer] = useState<boolean>(
    false
  );
  const [vendorInfoRefresh, setVendorInfoRefresh] = useState(true);
  const [vendors, setVendors] = useState<any[]>([]);
  const [vendorGroupId, setVendorGroupId] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const client = useApolloClient();
  const translate = useTranslate();
  const { getValues, control, handleSubmit, setValue } = useForm();

  const createVendorGroupFn = useCallback(async () => {
    const { vendorGroupName } = getValues();
    if (!vendorGroupName) {
      message.error(translate(messagesLocal.VENDOR_GROUP_NAME_ERROR), 1);
      return Promise.reject();
    }
    setSubmitting(true);
    return client
      .mutate({
        mutation: CREATE_VENDOR_GROUP,
        variables: {
          input: {
            name: vendorGroupName,
            GGRCharges: vendors.map((vendor) => ({
              vendor: vendor.key,
            })),
          },
        },
      })
      .then((resp) => {
        const { createVendorGroup } = resp?.data;
        setVendorGroupId(createVendorGroup);
        return Promise.resolve(createVendorGroup);
      })
      .catch(() => Promise.reject())
      .finally(() => setSubmitting(false));
  }, [client, getValues, translate, vendors]);

  useEffect(() => {
    if (existingValues) {
      setValue('vendorGroupName', existingValues.name);
      setVendorGroupId(existingValues.id);
      const transformVendors = existingValues.vendors.map((vendor) => ({
        key: vendor.id,
        label: vendor.name,
      }));
      setVendors(transformVendors);
    }
  }, [existingValues, setValue]);

  const closeDrawerAndRefreshState = () => {
    closeDrawer();
    refetchFn();
    setVendorInfoRefresh(false);
    setTimeout(() => {
      setVendorInfoRefresh(true);
    });
  };

  const updateVendor = async (vendorId?: string, close = false) => {
    const { vendorGroupName } = getValues();
    const variables = {
      id: vendorId || vendorGroupId,
      input: {
        ...(vendorGroupName && { name: vendorGroupName }),
        GGRCharges: vendors.map((vendor) => ({
          vendor: vendor.key,
        })),
      },
    };

    setSubmitting(true);
    await client.mutate({
      mutation: UPDATE_VENDOR_GROUP,
      variables,
    });
    setSubmitting(false);

    if (close) {
      closeDrawerAndRefreshState();
    }
  };

  const submitVendorGroup = async () => {
    let vendorId = vendorGroupId;
    if (!vendorGroupId) {
      vendorId = (await createVendorGroupFn()) as any;
    } else {
      await updateVendor();
    }
    setSubmitting(true);
    client
      .mutate({
        mutation: SUBMIT_VENDOR_GROUP,
        variables: {
          id: vendorId,
        },
      })
      .then(() => {
        message.success(translate(messagesLocal.CREATED_VENDOR_GROUP));
        refetchFn();
        setSubmitting(false);
        setVendorInfoDrawer(true);
        setPublishVendorDrawer(false);
        closeDrawerAndRefreshState();
      });
  };

  return (
    <Drawer onClose={() => closeDrawerAndRefreshState()} open={openDrawer}>
      <Drawer.Header title="Create New Vendor Group">
        <DrawerActionButtons
          saveAndExitFn={async () => {
            if (!vendorGroupId) {
              await createVendorGroupFn().then((vendorId: any) => {
                updateVendor(vendorId, true);
                message.success(
                  translate(messagesLocal.SAVED_VENDOR_GROUP_DRAFT)
                );
              });
            } else {
              updateVendor('', true);
              message.success(
                translate(messagesLocal.SAVED_VENDOR_GROUP_DRAFT)
              );
            }
          }}
          saveFn={async () => {
            if (!vendorGroupId) {
              await createVendorGroupFn().then((vendorId: any) => {
                updateVendor(vendorId);
                message.success(
                  translate(messagesLocal.SAVED_VENDOR_GROUP_DRAFT)
                );
              });
            } else {
              updateVendor('');
              message.success(
                translate(messagesLocal.SAVED_VENDOR_GROUP_DRAFT)
              );
            }
          }}
        />
      </Drawer.Header>
      {vendorInfoRefresh && (
        <div
          className={!vendorInfoDrawer ? 'position-absolute' : ''}
          style={{ top: !vendorInfoDrawer ? '-9999px' : 'unset' }}
        >
          <NewVendorGroupDrawer
            submitting={submitting}
            control={control}
            vendors={vendors}
            setVendors={setVendors}
            handleSubmit={handleSubmit}
            onNext={() => {
              const { vendorGroupName } = getValues();
              if (!vendorGroupName) {
                message.error(translate(messagesLocal.VENDOR_GROUP_NAME_ERROR));
              } else {
                setVendorInfoDrawer(false);
                setPublishVendorDrawer(true);
              }
            }}
          />
        </div>
      )}

      {publishVendorDrawer && (
        <PublishVendorDrawer
          values={{
            hasName: !!getValues()?.vendorGroupName,
            hasVendors: vendors.length > 0,
          }}
          submitting={submitting}
          onComplete={() => submitVendorGroup()}
          onPrevious={() => {
            setVendorInfoDrawer(true);
            setPublishVendorDrawer(false);
          }}
          existingStatus={null}
        />
      )}
    </Drawer>
  );
};

type Props = {
  openDrawer: boolean;
  closeDrawer: () => void;
  refetchFn: () => void;
  existingValues?: VendorGroup;
};
