/* eslint-disable no-bitwise */
import React from 'react';
import Icon from '@ant-design/icons';
import { Typography, Table, Spin, Tooltip } from 'antd';

import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import * as moment from 'moment-timezone';

import NextPrevButton from 'components/NextPrevButton';

import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import coercedGet from 'utils/coercedGet';

import { useReportsTimezone } from 'contexts/ReportsTimezone';
import { useCustomColumnsV2 } from 'SuperAdminMain/store/SuperAdminCustomColumnState/customColumnState';
import { useNoRefreshPaginate } from 'utils/usePagination';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';
import { ReactComponent as DownloadIcon } from 'components/assets/cloud_download.svg';
import { GraphqlData } from 'interfaces/user.interface';
import {
  BetHistoryReport as BetHistoryReportType,
  BetHistoryReportsConnectionEdge,
} from 'types/graphqlTypes';
import { useScreenTabSA } from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';
import { getPollInterval } from 'utils/pollInterval';
import messages from 'messages';
import { StyledTableContainer, StyledCenter } from './styles';
import { BET_HISTORY_REPORTS } from './queries';
import TableFooterPages from './components/TableFooterPages';
import ResizableTitle from './components/ResizableTitle';
import localMessages from './messages';
import ClientNames from './components/ClientNames';
import FilterStatus from './components/FilterStatus';

type ColumnDataType = {
  key: string;
  title: string;
  dataIndex?: string;
  render: (value: any) => any;
};

const ReportsTable = React.forwardRef((__props, ref) => {
  const { isTabActive } = useScreenTabSA();
  const isActiveTab = isTabActive(TabId.BET_HISTORY_REPORT);
  const { bypassedCurrentTimezone } = useReportsTimezone() as any;

  const translate = useTranslate();

  const pageInitState = {
    first: 10,
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
  };

  const [page, dispatchPagination, actions] = useNoRefreshPaginate(
    pageInitState
  );

  const refetchVariables = {
    first: page.first,
    after: page.after,
    filter: {},
  };

  const { loading, data = {}, refetch } = useQuery(BET_HISTORY_REPORTS, {
    variables: refetchVariables,
    pollInterval: getPollInterval({
      isActive: isActiveTab,
      isRealtime: true,
    }),
  });

  React.useImperativeHandle(ref, () => ({
    refetchTable: refetch,
  }));

  const impartResponsiveWidth = React.useCallback(
    (value) => ({ width: value } || {}),
    []
  );

  const columnFields: ColumnDataType[] = React.useMemo(
    () => [
      {
        key: 'clientAccount',
        title: translate(localMessages.CLIENT_ACCOUNT),

        render: (node: any) => (
          <ClientNames adminIds={(node && node.filter.admin.in) || []} />
        ),
      },
      {
        key: 'timezone',
        title: translate(localMessages.TIMEZONE_SELECTION),

        dataIndex: 'timezone',
        render: (text: string | null) => text || '-',
      },
      {
        key: 'createdDate',
        title: translate(localMessages.CREATED_DATE),
        dataIndex: 'dateTimeCreated',
        render: (text: string | null) =>
          text
            ? moment
                .tz(text, bypassedCurrentTimezone)
                .format('MMM DD, YYYY hh:mm:ss A')
            : '-',
      },
      {
        key: 'createdBy',
        title: translate(localMessages.CREATED_BY),
        dataIndex: 'creator',
        render: (text: any) => coercedGet(text, 'username', null) || '-',
      },
      {
        key: 'filters',
        title: translate(localMessages.FILTERS),
        ...impartResponsiveWidth(120),
        render: (node: any) => <FilterStatus node={node} />,
      },
      {
        key: 'download',
        title: translate(localMessages.DOWNLOAD),
        ...impartResponsiveWidth(120),
        render: (node: any) => {
          const disabled = node.status === 'GENERATING' || !node.report;
          const tooltipTitle =
            node.status === 'GENERATED'
              ? translate(messages['reports.generated.text'])
              : translate(messages['reports.generating.text']);

          return (
            <Tooltip placement="top" title={tooltipTitle}>
              <ALink
                className={`styled-center ${disabled ? 'cursor-disabled' : ''}`}
                href={coercedGet(node, 'report.uri', null)}
              >
                <Icon
                  className={`mr-3 ${
                    disabled ? 'action-icon-disabled' : 'action-icon'
                  }`}
                  component={DownloadIcon}
                  style={{ fontSize: '22px' }}
                  // onClick={() => refetch()}
                />
              </ALink>
            </Tooltip>
          );
        },
      },
    ],
    [bypassedCurrentTimezone, impartResponsiveWidth, translate]
  );

  const { totalCount, pageInfo } = coercedGet(
    data,
    'betHistoryReports',
    {}
  ) as GraphqlData<BetHistoryReportType>;
  const totalPage = Math.ceil(totalCount / page.first) || 1;
  const edges = coercedGet(data, 'betHistoryReports.edges', []);

  const tableData =
    edges.map((edge: BetHistoryReportsConnectionEdge) => edge.node) || [];

  const {
    loading: loadingCC,
    filterColumns,
    customColumns,
  } = useCustomColumnsV2(TabId.BET_HISTORY_REPORT, columnFields);

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  if (isEmpty(customColumns)) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  const isLoading = loading || loadingCC;

  return (
    <>
      <StyledTableContainer
        className="table-container"
        style={{ height: 'calc(100vh - 205px)', overflow: 'hidden' }}
      >
        <Table
          columns={filterColumns(columnFields)}
          // columns={columnFields}
          loading={isLoading}
          size="middle"
          dataSource={tableData}
          pagination={false}
          components={components}
          scroll={{
            y: 640,
            x: 'calc(545px + 50%)',
          }}
        />
      </StyledTableContainer>
      <div>
        <div
          className="d-flex justify-content-space-between align-items-center"
          style={{
            padding: '1.2rem',
            height: '2.4vh',
            background: '#f3f8fb',
          }}
        >
          <div> </div>

          <div className="styled-center">
            <div className="mr-3">
              <Typography.Text strong className="mr-1">
                {totalCount}
              </Typography.Text>
              <FormattedMessage
                id="results-found.text"
                defaultMessage="Results Found"
                values={{
                  count: null,
                }}
              />
            </div>

            <NextPrevButton
              loading={isLoading}
              onPrev={() =>
                dispatchPagination({
                  type: actions.PREV,
                })
              }
              disablePrev={page.currentPage + 1 === 1}
              onNext={() =>
                dispatchPagination({
                  type: actions.NEXT,
                  payload: pageInfo,
                })
              }
              disableNext={page.currentPage + 1 === totalPage}
              label={`${page.currentPage + 1} / ${totalPage}`}
            />

            <TableFooterPages
              first={page.first}
              setPage={(rowCount) => {
                dispatchPagination({
                  type: actions.SETSTATE,
                  payload: {
                    ...pageInitState,
                    first: rowCount,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default ReportsTable;
