import React, { useEffect, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { Wrapper } from './styles';
import { CUSTOM_FILTERS } from './queries';
import EditSearchSettings from '../EditSearchSettings/EditSearchSettings';
import DeleteSettings from '../DeleteSettings';

const SearchSettingsList = ({
  onUseFilter,
}: {
  onUseFilter: (data: Record<string, any>) => void;
}) => {
  const [customFilterList, setCustomFilterList] = useState<any>([]);
  const { loading, data } = useQuery(CUSTOM_FILTERS, {
    variables: {
      filter: {
        context: {
          eq: 'member-game-reports',
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      const { customFilters = {} } = data;
      const { edges = [] } = customFilters;
      const newData = edges.map(({ node }: { node: Record<string, any> }) => ({
        ...node,
        isShow: false,
      }));
      setCustomFilterList(newData);
    }
  }, [data]);

  if (loading)
    return (
      <div className="p-6">
        <FormattedMessage id="loading.text" defaultMessage="Loading..." />
      </div>
    );

  const handleChildren = (id: string) => {
    const newCustomFilterList = customFilterList.map(
      (item: Record<string, any>) => {
        if (item.id === id) {
          return {
            ...item,
            isShow: !item.isShow,
          };
        }
        return {
          ...item,
          isShow: false,
        };
      }
    );
    setCustomFilterList(newCustomFilterList);
  };

  const handleUseSearchSettings = (e: Record<string, any>) => {
    onUseFilter(e.filter);
  };

  return (
    <Wrapper>
      {customFilterList.length ? (
        customFilterList.map((item: Record<string, any>) => (
          <div key={item.id} className="bb-1 pt-5">
            <div className="row-item pb-5">
              <span>
                <a
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    handleUseSearchSettings(item);
                  }}
                >
                  {item.name}
                </a>
              </span>
              <span className="options">
                <a
                  href="#/"
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleChildren(item.id);
                  }}
                >
                  <FormattedMessage id="edit.text" defaultMessage="Modify" />{' '}
                  <DownOutlined />
                </a>
                <DeleteSettings searchSettings={item} />
              </span>
            </div>
            {item.isShow && (
              <EditSearchSettings
                searchSettings={item}
                onClose={() => handleChildren(item.id)}
              />
            )}
          </div>
        ))
      ) : (
        <div className="py-5">
          <FormattedMessage
            id="reports.search-result-no-data.text"
            defaultMessage="No data yet"
          />
        </div>
      )}
    </Wrapper>
  );
};

export default SearchSettingsList;
