import gql from 'graphql-tag';

export const PROMOS = gql`
  query Promos($filter: PromosFilterInput!, $hasPermission: Boolean!) {
    promos(filter: $filter) @include(if: $hasPermission) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
export const REBATES = gql`
  query RebateGroups(
    $filter: RebateGroupsFilterInput!
    $hasPermission: Boolean!
  ) {
    rebateGroups(filter: $filter) @include(if: $hasPermission) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
export const AFFILIATE_PROGRAMMES = gql`
  query AffiliateProgrammes(
    $filter: AffiliateProgrammesFilterInput
    $hasPermission: Boolean!
  ) {
    affiliateProgrammes(filter: $filter) @include(if: $hasPermission) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
