import gql from 'graphql-tag';

export const MEMBER_BET_RECORD_REPORT = gql`
  query MemberBetRecordReport {
    memberBetRecordReport {
      status
      report {
        uri
      }
      processedRows
      totalRows
    }
  }
`;

export const MEMBER_EXPORT_RECORD = gql`
  query MemberExportRecord {
    memberExportRecord {
      status
      report {
        uri
      }
      processedRows
      totalRows
    }
  }
`;

export const BALANCE_TRANSACTION_RECORD_REPORT = gql`
  query BalanceTransactionRecordReport {
    balanceTransactionRecordReport {
      status
      report {
        uri
      }
      processedRows
      totalRows
    }
  }
`;

export const PROMO_PAYOUTS_REPORT = gql`
  query PromoPayoutsReport {
    promoPayoutReport {
      status
      report {
        id
        uri
      }
      processedRows
      totalRows
    }
  }
`;
