import React, { useState } from 'react';
import moment from 'moment';
import thirdPartyProviders, {
  thirdPartyTypeNames,
} from 'constants/thirdPartyProviders';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { TabId } from 'constants/route';

import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { columnAlign } from 'utils/tableAlignment';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';

import useTranslate from 'utils/useTranslate';
import globalMsgs from 'messages';
import messages from 'pages/components/ThirdPartyDepositProvider/messages';

import coercedGet from 'SuperAdminMain/utils/coercedGet';
import { ALink } from 'components/ALink/ALink';
import { StyledTable } from './styles';

type Rec = Record<string, any>;

const ProvidersTable = ({
  depositProviders,
  toggleEdit,
  toggleDelete,
  toggleDuplicate,
  toggleView,
  loading,
}: {
  depositProviders: Rec[];
  toggleEdit: (e: string) => void;
  toggleDelete: (e: string) => void;
  toggleDuplicate: (e: string) => void;
  toggleView: (e: string) => void;
  loading: boolean;
}) => {
  const translate = useTranslate();

  const { role, permissions } = usePermissions();

  const {
    ALLOWED_EDIT,
    ALLOWED_DELETE,
    ALLOWED_DUPLICATE,
  } = collectPermissions(
    role,
    permissions,
    ['EDIT', 'DELETE', 'DUPLICATE'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS
  );

  const [state, setState] = useState<Rec>({
    selectedRowKeys: [],
  });

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = depositProviders
    ? depositProviders.map(({ node }) => ({ ...node, key: node.id }))
    : [];

  const renderTypeColumn = (typeName: string) => {
    switch (typeName) {
      case thirdPartyTypeNames.diorPay:
        return translate(globalMsgs[thirdPartyProviders.DIORPAY]);

      case thirdPartyTypeNames.midPay:
        return translate(globalMsgs[thirdPartyProviders.MIDPAY]);

      case thirdPartyTypeNames.midPayDirect:
        return translate(globalMsgs[thirdPartyProviders.MIDPAY_DIRECT]);

      case thirdPartyTypeNames.diorPay2:
        return translate(globalMsgs[thirdPartyProviders.DIORPAY2]);

      default:
        return '-';
    }
  };

  const renderKeysPreview = (provider: {
    __typename: string;
    keyPreview?: string;
    apiKeyPreview?: string;
    encryptionKey?: string;
  }) => {
    switch (provider.__typename) {
      case thirdPartyTypeNames.diorPay:
      case thirdPartyProviders.DIORPAY:
        return provider.keyPreview;

      case thirdPartyTypeNames.midPay:
      case thirdPartyTypeNames.midPayDirect:
      case thirdPartyProviders.MIDPAY:
      case thirdPartyProviders.MIDPAY_DIRECT:
        return provider.apiKeyPreview;

      case thirdPartyProviders.DIORPAY2:
      case thirdPartyTypeNames.diorPay2: {
        const { encryptionKey } = provider;
        const reg = /^([\s\S]{2})([\s\S]{1,})([\s\S]{3})$/g;
        const replace = (_: any, p1 = '', p2 = '', p3 = '') =>
          `${p1}${'*'.repeat(p2.length)}${p3}`;
        const keyPreview = encryptionKey?.replace(reg, replace);

        return keyPreview;
      }

      default:
        return '-';
    }
  };

  const csvRenderKey = (provider: {
    __typename: string;
    key?: string;
    apiKey?: string;
    encryptionKey?: string;
  }) => {
    switch (provider.__typename) {
      case thirdPartyTypeNames.diorPay:
      case thirdPartyProviders.DIORPAY:
        return provider.key;

      case thirdPartyTypeNames.midPay:
      case thirdPartyTypeNames.midPayDirect:
      case thirdPartyProviders.MIDPAY:
      case thirdPartyProviders.MIDPAY_DIRECT:
      case thirdPartyProviders.MIDPAY_DIRECT_2:
        return provider.apiKey;

      case thirdPartyProviders.DIORPAY2:
      case thirdPartyTypeNames.diorPay2:
        return provider.encryptionKey;

      default:
        return '-';
    }
  };
  const columns = [
    {
      csvData: {
        label: translate(messages['provider-name.text']),
        key: 'name',
      },
      title: translate(messages['provider-name.text']),
      key: 'name',
      align: columnAlign.onCenter,
      render: (provider: { id: string; name: string }) => (
        <a
          href="#/section"
          data-testid={`provider-${provider.id}`}
          onClick={() => {
            toggleView(provider.id);
          }}
        >
          {provider.name}
        </a>
      ),
    },
    {
      csvData: {
        label: translate(messages['type.text']),
        key: '__typename',
        renderCell: (paymentGateway: Rec) => {
          const type = coercedGet(paymentGateway, '__typename', '');
          return type ? renderTypeColumn(type) : '-';
        },
      },
      title: translate(messages['type.text']),
      dataIndex: '__typename',
      key: '__typename',
      align: columnAlign.onCenter,
      render: renderTypeColumn,
    },
    {
      csvData: {
        label: translate(messages.KEY_PREVIEW),
        key: 'apiKeyPreview',
        renderCell: (key: any) => csvRenderKey(key),
      },
      title: translate(messages.KEY_PREVIEW),
      key: 'apiKeyPreview',
      align: columnAlign.onCenter,
      render: renderKeysPreview,
    },
    {
      csvData: {
        label: translate(messages.THIRD_PARTY_GATEWAY),
        key: 'midPayDirectPaymentGateway',
        renderCell: (paymentGateway: Rec) => {
          const gatewayId = coercedGet(
            paymentGateway,
            'midPayDirectPaymentGateway.id',
            ''
          );
          return gatewayId ? translate(globalMsgs[gatewayId]) : '-';
        },
      },
      title: translate(messages.THIRD_PARTY_GATEWAY),
      key: 'midPayDirectPaymentGateway',
      dataIndex: 'midPayDirectPaymentGateway',
      align: columnAlign.onCenter,
      render: (paymentGateway: Rec) => {
        const gatewayId = coercedGet(paymentGateway, 'id', '');
        return gatewayId ? translate(globalMsgs[gatewayId]) : '-';
      },
    },
    {
      csvData: {
        label: translate(messages['date-created.text']),
        key: 'dateTimeCreated',
        renderCell: (node: Rec) =>
          moment(node.dateTimeCreated).format('MMM DD, YYYY hh:mm:ss A'),
      },
      title: translate(messages['date-created.text']),
      dataIndex: 'dateTimeCreated',
      key: 'dateTimeCreated',
      align: columnAlign.onCenter,
      render: (text: string) => moment(text).format('MMM DD, YYYY hh:mm:ss A'),
    },
    {
      csvData: null,
      title: translate(messages['actions.text']),
      key: 'actions',
      dataIndex: 'id',
      hidden: true,
      width: 80,
      align: columnAlign.onCenter,
      render: (id: string) => (
        <Dropdown
          trigger={['hover', 'click']}
          overlay={
            <Menu>
              {ALLOWED_EDIT && (
                <Menu.Item
                  onClick={() => {
                    toggleEdit(`${id}`);
                  }}
                >
                  {translate(messages['edit.text'])}
                </Menu.Item>
              )}
              {ALLOWED_DUPLICATE && (
                <Menu.Item
                  onClick={() => {
                    toggleDuplicate(id);
                  }}
                >
                  {translate(messages['duplicate.text'])}
                </Menu.Item>
              )}
              {ALLOWED_DELETE && (
                <Menu.Item
                  disabled={false}
                  onClick={() => {
                    toggleDelete(id);
                  }}
                >
                  {translate(messages['delete.text'])}
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomRight"
        >
          <ALink href="#" aria-label="Actions">
            <EllipsisOutlined
              className="cursor-pointer font-weight-bold"
              style={{ fontSize: '25px' }}
            />
          </ALink>
        </Dropdown>
      ),
    },
  ];
  const { filterColumns } = useCustomColumnsV2(
    TabId.THIRD_PARTY_DEPOSIT_PROVIDERS as any,
    columns
  );

  return (
    <StyledTable
      aria-label="Deposit Providers"
      data-testid="container"
      loading={loading}
      columns={filterColumns(columns)}
      size="middle"
      dataSource={data}
      pagination={false}
      scroll={{ x: 1200 }}
      rowSelection={rowSelection}
    />
  );
};

export default ProvidersTable;
