import * as Yup from 'yup';

export const agentAffiliateProgramSchema = {
  name: Yup.string().required('Required'),
  tierRequirement: Yup.array().required('Required'),
  minimumEffectiveBetRequirement: Yup.number()
    .nullable()
    .min(0, 'Invalid amount'),
  minimumDepositRequirement: Yup.number()
    .nullable()
    .min(0, 'Invalid amount'),
};

export const costSettingsSchema = {
  vendorHandlers: Yup.array()
    .of(
      Yup.object({
        gameType: Yup.string().required('Required'),
        vendor: Yup.string().required('Required'),
        minimumCharge: Yup.number(),
        noMinimumCharge: Yup.bool(),
        maximumCharge: Yup.number(),
        noMaximumCharge: Yup.bool(),
        ggrChargeCostSharing: Yup.bool().required(),
        chargeLevels: Yup.array()
          .of(
            Yup.object({
              minimumTotalWinloss: Yup.number(),
              percentage: Yup.number(),
            })
          )
          .nullable(),
      })
    )
    .nullable(),
};

export const settlementPeriodSchema = {
  minimumPayoutAccumulationAmount: Yup.number()
    .required('Required')
    .min(0, 'Invalid amount'),
  turnoverRequirementMultiplier: Yup.number()
    .nullable()
    .min(0, 'Invalid amount'),
};
export const tierSettingsSchema = {};

export const formSchemas = [
  agentAffiliateProgramSchema,
  costSettingsSchema,
  settlementPeriodSchema,
  tierSettingsSchema,
];
