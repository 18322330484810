import React, { ReactNode } from 'react';
import styled from 'styled-components';
import useIsNext from 'hooks/useIsNext';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';

const ComingSoonWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  flag: string;
  name?: string;
  placeholder?: boolean | string | ReactNode;
};

const FeaturedFlagWrap: React.FC<Props> = (props) => {
  const translate = useTranslate();
  const { flag, name, children, placeholder } = props;
  const next = useIsNext(flag || '');

  if (next) {
    return <>{children}</>;
  }

  if (placeholder) {
    return (
      <ComingSoonWrap>
        <span>
          {name ? `${name} ${messages['global.is.text']} ` : ''}
          {translate(messages['global.under-construction.text'])}...
        </span>
      </ComingSoonWrap>
    );
  }

  return <></>;
};

export default FeaturedFlagWrap;
