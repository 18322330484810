import React from 'react';
import { Modal, Select, Button } from 'antd';

// import * as moment from 'moment-timezone';
// import supportedTimezones from 'constants/supportedTimezones';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import messages from 'messages';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
// import getSortedTimezones from 'utils/getSortedTimezones';
import TimeZoneSelector from 'utils/TimeZoneSelector';
import { ReactComponent as DiffTimezone } from './assets/diff_timezone.svg';
import { ReactComponent as SameTimezone } from './assets/same_timezone.svg';

const { Option } = Select;

const TimezoneSelect = () => {
  const translate = useTranslate();
  const [isShow, setIsShow] = React.useState(false);
  const toggleShow = () => setIsShow((prev) => !prev);
  const {
    currentTimezone,
    setCurrentTimezone,
    globalDefaultTimezone,
  } = useReportsTimezone();

  const isSameTimezone = globalDefaultTimezone === currentTimezone;

  const [localSelectedTimezone, setLocalSelectedTimezone] = React.useState(
    currentTimezone
  );

  const renderOptions = () =>
    TimeZoneSelector(false).props.children.map((zone: any) => (
      <Option value={zone.name} key={zone.id}>
        {zone.labelEn}
      </Option>
    ));

  React.useEffect(() => {
    setLocalSelectedTimezone(currentTimezone);
  }, [currentTimezone]);

  return (
    <Wrapper>
      <Button className="styled-center" onClick={toggleShow}>
        {isSameTimezone ? (
          <SameTimezone className="mr-1" />
        ) : (
          <DiffTimezone className="mr-1" />
        )}{' '}
        {translate(messages['select-timezone.text'])}
      </Button>
      <Modal
        className="styled-center"
        width={300}
        visible={isShow}
        title={translate(messages['select-timezone.text'])}
        onOk={() => {
          setCurrentTimezone(localSelectedTimezone);
          toggleShow();
        }}
        onCancel={() => {
          setLocalSelectedTimezone(currentTimezone);
          toggleShow();
        }}
      >
        <Select
          style={{
            width: '330px',
          }}
          defaultValue={currentTimezone}
          onChange={setLocalSelectedTimezone}
          showSearch
          filterOption={(input, option) => {
            const target = coercedGet(option as {}, 'props.children', []);
            if (target.length) {
              return target.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }
            return false;
          }}
        >
          {renderOptions()}
        </Select>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-btn {
    border: none;
  }
`;

export default TimezoneSelect;
