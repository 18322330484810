import React from 'react';
import Icon from '@ant-design/icons';
import { DynamicObj } from 'interfaces/user.interface';
import { Tooltip } from 'antd';
import { StyledButton } from './styles';

const MemberLevelButton = ({
  onClick,
  isHighlight,
  isHoverable,
  maxChar = 4,
  memberLevelData,
}: {
  onClick?: (e: any) => void;
  isHighlight?: boolean;
  isHoverable?: boolean;
  maxChar?: number;
  memberLevelData: DynamicObj;
}) => {
  const { color, name } = memberLevelData || {};

  return (
    <div id="member-loyalty">
      <div className="position-relative">
        <Tooltip
          getPopupContainer={() =>
            document.getElementById('member-loyalty') || document.body
          }
          overlayStyle={{ fontSize: '10px' }}
          arrowPointAtCenter
          title={<span>{name}</span>}
        >
          <StyledButton
            role="button"
            color={color!}
            onClick={onClick}
            className="text-truncate"
            style={{ maxWidth: '100%' }}
          >
            {isHoverable && name.length > maxChar
              ? `${name.slice(0, maxChar)}...`
              : name}
          </StyledButton>
        </Tooltip>

        {isHighlight && (
          <Icon
            type="check-circle"
            style={{
              color: 'limegreen',
              right: '-6px',
              top: '-5px',
            }}
            className="position-absolute"
          />
        )}
      </div>
    </div>
  );
};

export default MemberLevelButton;
