import React from 'react';
import coercedGet from 'utils/coercedGet';
import { CheckCircleFilled } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import useTranslate from 'utils/useTranslate';
import { useIntl } from 'react-intl';
import { translateDefaultMarker } from 'utils/translateDefaultMarker';
import { StyledButton } from './styles';
import { MEMBER_LEVEL } from './queries';

const MemberLevelButton = ({
  memberId,
  onClick,
  isHighlight,
  isHoverable,
  maxChar = 4,
  isMemberDetail,
  memberLevelInfo,
}: {
  memberId: string;
  onClick: () => void;
  isHighlight: boolean;
  isHoverable: boolean;
  maxChar: number;
  isMemberDetail?: boolean;
  memberLevelInfo?: {
    name: string;
    color: string;
    loading: boolean;
  };
}) => {
  const intl = useIntl();
  const translate = useTranslate();
  const { loading, error, data } = useQuery(MEMBER_LEVEL, {
    context: { shouldBatch: true },
    fetchPolicy: 'network-only',
    variables: {
      id: memberId,
    },
    skip: isMemberDetail,
  });

  if (loading || memberLevelInfo?.loading) {
    return <Spin spinning />;
  }

  if (error) {
    throw new Error('Error');
  }

  const memberLevel = coercedGet(data, 'member.memberLevel', memberLevelInfo);
  const color = coercedGet(
    data,
    'member.memberLevel.color',
    memberLevelInfo?.color
  );
  const name = coercedGet(
    data,
    'member.memberLevel.name',
    memberLevelInfo?.name
  );
  const translatedMarker = translateDefaultMarker({
    text: name,
    intl,
    translate,
  });
  return memberLevel ? (
    <div id="member-loyalty">
      <div className="position-relative">
        <Tooltip
          getPopupContainer={() =>
            document.getElementById('member-loyalty') || document.body
          }
          overlayStyle={{ fontSize: '10px' }}
          arrowPointAtCenter
          title={<span>{translatedMarker}</span>}
        >
          <StyledButton
            color={color}
            onClick={onClick}
            className="text-truncate"
            style={{ maxWidth: '100%' }}
          >
            {isHoverable && name?.length > maxChar
              ? `${translateDefaultMarker({
                  text: name.slice(0, maxChar),
                  withEllipsis: true,
                  intl,
                  translate,
                })}`
              : translatedMarker}
          </StyledButton>
        </Tooltip>

        {isHighlight && (
          <CheckCircleFilled
            style={{
              color: 'limegreen',
              right: '-6px',
              top: '-5px',
            }}
            className="position-absolute"
          />
        )}
      </div>
    </div>
  ) : (
    '-'
  );
};

export default MemberLevelButton;
