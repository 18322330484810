import React from 'react';
import { StyledLayout, StyledContent } from 'styles';

type Props = {
  children: React.ReactNode;
};

const Container = ({ children, ...restProps }: Props) => (
  <StyledLayout {...restProps}>
    <StyledContent>{children}</StyledContent>
  </StyledLayout>
);

export default Container;
