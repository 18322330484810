export const renderNumeral = (
  value: string | number,
  isCrypto: boolean = false,
  isExchangeRate: boolean = false
) => {
  // eslint-disable-next-line no-extra-boolean-cast
  if (!Boolean(value)) return isCrypto ? '0.00000000' : '0.00';

  const [integerPart, decimalPart] = value.toString().split('.');

  let decimalPlaces: number = 2;
  if (isExchangeRate) {
    decimalPlaces = decimalPart ? 4 : 2;
  } else {
    decimalPlaces = isCrypto ? 8 : 2;
  }

  // eslint-disable-next-line no-nested-ternary
  const formattedDecimalPart = decimalPart
    ? decimalPart.length >= decimalPlaces
      ? `.${decimalPart.slice(0, decimalPlaces)}`
      : `.${decimalPart}${'0'.repeat(decimalPlaces - decimalPart.length)}`
    : `.${'0'.repeat(decimalPlaces)}`;

  const formattedNumber = `${integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  )}${formattedDecimalPart}`;

  return formattedNumber;
};
