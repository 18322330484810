import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-loyalty.draft.text': {
    id: 'member-loyalty.draft.text',
    defaultMessage: 'Draft',
  },
  'member-loyalty.inactive.text': {
    id: 'member-loyalty.inactive.text',
    defaultMessage: 'Inactive',
  },
  'member-loyalty.active.text': {
    id: 'member-loyalty.active.text',
    defaultMessage: 'Active',
  },
});

export default messages;
