import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'reports.select-date-time.text': {
    id: 'reports.select-date-time.text',
    defaultMessage: 'Select Date Time',
  },
  'reports.from.text': {
    id: 'reports.from.text',
    defaultMessage: 'From',
  },
  'reports.to.text': {
    id: 'reports.to.text',
    defaultMessage: 'To',
  },
  'reports.input-search.text': {
    id: 'reports.input-search.text',
    defaultMessage: 'Search',
  },
  'reports.withdrawal-fee.text': {
    id: 'withdrawal-fee.text',
    defaultMessage: 'Withdrawal Fee',
  },
  'reports.withdrawal-clawback-fee.text': {
    id: 'withdrawal-clawback-fee.text',
    defaultMessage: 'Withdrawal ClawBack Fee',
  },
  'reports.withdrawal.text': {
    id: 'withdrawal.text',
    defaultMessage: 'Withdrawal',
  },
  'reports.deposit.text': {
    id: 'deposit.text',
    defaultMessage: 'Deposit',
  },
  'reports.promo-payout.text': {
    id: 'promo-payout.text',
    defaultMessage: 'Promo Payout',
  },
  'reports.vendor-transfer.text': {
    id: 'vendor-transfer.text',
    defaultMessage: 'Vendor Transfer',
  },
  'reports.debit-vendor-transfer.text': {
    id: 'debit-vendor-transfer.text',
    defaultMessage: 'Debit Vendor Transfer',
  },
  'reports.credit-vendor-transfer.text': {
    id: 'credit-vendor-transfer.text',
    defaultMessage: 'Credit Vendor Transfer',
  },
  'reports.refund-credit-vendor-transfer.text': {
    id: 'refund-credit-vendor-transfer.text',
    defaultMessage: 'Refund Credit Vendor Transfer',
  },
  'reports.bet.text': {
    id: 'bet.text',
    defaultMessage: 'Bet',
  },
  'reports.balance-adjustment.text': {
    id: 'balance-adjustment.text',
    defaultMessage: 'Balance Adjustment',
  },
  'reports.payout.text': {
    id: 'payout.text',
    defaultMessage: 'Payout',
  },
  'reports.member-affiliate-commission.text': {
    id: 'member-affiliate-commission.text',
    defaultMessage: 'Member Affiliate Commision',
  },
  'reports.rebate-commission.text': {
    id: 'rebate-commission.text',
    defaultMessage: 'Rebate Commission',
  },
  'reports.rebate-payout.text': {
    id: 'rebate-payout.text',
    defaultMessage: 'Rebate Payout',
  },
  'reports.agent-affiliate-commission.text': {
    id: 'agent-affiliate-commission.text',
    defaultMessage: 'Agent Affiliate Commission',
  },
  'reports.affiliate-commission.text': {
    id: 'affiliate-commission.text',
    defaultMessage: 'Affiliate Commission',
  },
  'reports.system.text': {
    id: 'system.text',
    defaultMessage: 'System',
  },
  'reports.member-loyalty-payout.text': {
    id: 'member-loyalty-payout.text',
    defaultMessage: 'Member Loyalty Payout',
  },
  'enabled.text': {
    id: 'enabled.text',
    defaultMessage: 'Enabled',
  },
  'disabled.text': {
    id: 'disabled.text',
    defaultMessage: 'Disabled',
  },
  'frozen.text': {
    id: 'frozen.text',
    defaultMessage: 'Frozen',
  },
  'kicked.text': {
    id: 'kicked.text',
    defaultMessage: 'Kicked',
  },
  'no-data.text': {
    id: 'no-data.text',
    defaultMessage: 'No data',
  },
  'reports.select-member-first.text': {
    id: 'reports.select-member-first.text',
    defaultMessage: 'Select a "Member" first',
  },
});

export default messages;
