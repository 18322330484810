import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';

const FilterConditions = () => {
  const menu = (
    <Menu>
      {/* <Menu.Item key={1}>Action 1</Menu.Item> */}
      {/* <Menu.Item key={2}>Action 2</Menu.Item> */}
      {/* <Menu.Item key={3}>Action 3</Menu.Item> */}
    </Menu>
  );

  return (
    <div className="ml-3">
      <div className="mt-1 d-flex justify-content-flex-end">
        <Dropdown overlay={menu}>
          <Button>
            <FormattedMessage
              id="more-actions.text"
              defaultMessage="More Actions"
            />
            <DownOutlined style={{ fontSize: '10px' }} />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default FilterConditions;
