import React, { useState } from 'react';
import gql from 'graphql-tag';
import { DownOutlined } from '@ant-design/icons';
import { Badge, Checkbox, Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { GameTypes } from 'constants/gameTypes';
import useTranslate from 'utils/useTranslate';
import gameTypeMessages from '../../MobileAppConfiguration/components/NewCategoryDrawer/messages';

export const GET_GAME_CATEGORIES = gql`
  query GameCategories {
    gameCategories {
      name
      id
    }
  }
`;

type Props = {
  value: string[];
  onChange: (e: Record<string, any>) => void;
};

export const CategorySelect = ({ value, onChange }: Props) => {
  const [selected, setSelected] = useState<string[]>(value || []);
  const [visible, setVisible] = useState(false);
  const translate = useTranslate();
  const gametypeKeys = Object.keys(GameTypes);
  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };
  const handleMenuClick = (e: Record<string, any>) => {
    if (e.key === '3') {
      setVisible(false);
    }
  };
  const checkedCategory = (gameTypeLocal: string) =>
    !!selected.find((x) => x === gameTypeLocal);

  const onSelectCategory = (game: string) => {
    const categoryExists = selected.find((sel) => sel === game);
    if (categoryExists) {
      const removed = selected.filter((sel) => sel === game);
      setSelected(removed);
      onChange(removed);
    } else {
      const added = selected.concat([game]);
      setSelected(added);
      onChange(added);
    }
  };

  return (
    <>
      <p className="fw-600 fs-13">
        <FormattedMessage id="CATEGORY" defaultMessage="Category" />
      </p>
      <Dropdown
        onVisibleChange={handleVisibleChange}
        visible={visible}
        overlay={
          <Menu
            onClick={handleMenuClick}
            style={{ height: '200px', overflow: 'auto' }}
          >
            {gametypeKeys.map((gameType) => (
              <Menu.Item
                key={gameType}
                onClick={() => onSelectCategory(gameType)}
              >
                <Checkbox checked={checkedCategory(gameType)} />{' '}
                {translate(gameTypeMessages[gameType])}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <div className="d-flex align-items-center cursor-pointer">
          <Badge
            showZero
            className="mr-3"
            count={selected.length}
            style={{ background: '#1890ff' }}
          />
          <DownOutlined />
        </div>
      </Dropdown>
    </>
  );
};
