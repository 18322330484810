import gql from 'graphql-tag';

export const MESSAGE_CREATORS = gql`
  query Messages($first: Int, $after: Binary, $filter: MessagesFilterInput) {
    messages(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          creator {
            id
            role
            username
          }
        }
      }
    }
  }
`;
