import { useApolloClient } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import gql from 'graphql-tag';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import { flatten, isEmpty, uniqBy } from 'lodash';
import { useLazyQueryPromise } from 'pages/components/common/hooks/useLazyQueryPromise';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { updateMemberCacheTags } from 'utils/updateMemberTagsCache';
import useTranslate from 'utils/useTranslate';
import BulkEditTagMenuForm from '../BulkEditTagMenuForm';
import messages from './messages';
import { StyledModal } from './styles';

const graphqlString = ({ userId, tags }: { userId: string; tags: any[] }) => `
  ${userId}: updateMember(id: "${userId}", input: {
    tags: [${tags.map((item: any) => `"${item}"`).join(',')}]
  })`;

const BulkEditTagMenu = ({
  members,
  onSuccess,
}: {
  members: Array<any>;
  onSuccess: (e: any) => void;
}) => {
  const [input, setInput] = useState<any>({
    tags: [],
    type: 'ADD_REMOVE',
    userSelected: [],
    previousTagSelected: [],
  });

  const [fetchMemberTags] = useLazyQueryPromise(GET_MEMBERS);

  const getMembersTags = async () => {
    const formatMembers = members.map((member) => member.key);
    const { data = {} } = await fetchMemberTags({
      filter: { id: { in: formatMembers } },
    });
    const { edges } = data?.members || {};
    const memberTags = edges?.map(({ node }: Record<string, any>) => node.tags);
    const newTags = flatten(memberTags);
    setInput((prevInput: Record<string, any>) => ({
      ...prevInput,
      tags: uniqBy(newTags, 'id'),
    }));
  };

  const fetchTagsOnUserSelect = async (users: Array<any>) => {
    if (input.userSelected.length >= 1) {
      const { data = {} } = await fetchMemberTags({
        filter: {
          id: {
            in: Array.isArray(users) ? users.map((user) => user.key) : [],
          },
        },
      });

      if (isEmpty(data)) {
        return;
      }

      const { edges } = data.members;
      const memberTags = uniqBy(
        flatten(
          edges.map(
            ({
              node,
            }: {
              node: {
                tags: any;
              };
            }) => node.tags
          )
        ),
        'id'
      );
      setInput((prevInput: Record<string, any>) => ({
        ...prevInput,
        tags: uniqBy([...prevInput.tags, ...memberTags], 'id'),
      }));
    }
  };

  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const handleOpenTag = () => {
    getMembersTags();
    handleShow();
  };
  const translate = useTranslate();
  const client = useApolloClient();

  const handleSubmit = async (e: any) => {
    setLoading(true);
    const listOfUserIds = e.username.map((user: { key: string }) => user.key);
    try {
      await setInput(e);
      let str = '';
      const userIds: any[] = [];
      let tags: any[] = [];
      if (e.type === 'ADD_REMOVE') {
        listOfUserIds.forEach((user: string) => {
          userIds.push(user);
          str += graphqlString({
            userId: user,
            tags: e.tags.map((item: { id: string }) => item.id),
          });
        });
        tags.push([...e.tags.map((item: { id: string }) => item.id)]);
      } else {
        e.userSelected.forEach((user: any) => {
          const unSelectedTags = user.tags.filter(
            (oldTag: { id: string }) =>
              !e.previousTagSelected.includes(oldTag.id)
          );
          userIds.push(user.id);
          str += graphqlString({
            userId: user.id,
            tags: [
              ...unSelectedTags.map((item: { id: string }) => item.id),
              ...e.tags.map((item: { id: string }) => item.id),
            ],
          });
          tags = [
            ...unSelectedTags.map((item: { id: string }) => item.id),
            ...e.tags.map((item: { id: string }) => item.id),
          ];
        });
      }

      await client.mutate({
        mutation: gql(`mutation { ${str} }`),
        update: (cache) => {
          const flattenTags = flatten(tags);
          userIds.forEach((userId) => {
            updateMemberCacheTags(flattenTags, userId)(cache);
          });
        },
      });
      message.success(
        translate(messages['member-management.member-tag-update-success.text'])
      );
      onSuccess(userIds);
      setLoading(false);
      handleShow();
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Button className="text-black" type="link" onClick={handleOpenTag} block>
        <FormattedMessage id="edit-labels.text" defaultMessage="Edit labels" />
      </Button>
      {isShow && (
        <StyledModal
          width={600}
          title={
            <FormattedMessage
              id="edit-labels.text"
              defaultMessage="Edit labels"
            />
          }
          onCancel={handleShow}
          visible={isShow}
          footer={false}
        >
          <BulkEditTagMenuForm
            onSelectUser={fetchTagsOnUserSelect}
            initialValues={{ ...input, username: members }}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onClose={handleShow}
          />
        </StyledModal>
      )}
    </>
  );
};

export default BulkEditTagMenu;
