import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { customFormatMessage } from 'utils/customFormatMessage';
import { TIER_REQUIREMENT } from 'pages/components/AffiliateProgramme/constants';
import ConditionalInput from './components/ConditionalInput';
import { StyledCenter } from './styles';
import ActionColumns from './components/ActionColumns';
import messages from './messages';

export const getColumns = (values: Record<string, any>, intl: any) => {
  const translate = (messageVal: any, opts = null) =>
    customFormatMessage(intl.formatMessage, messageVal, opts);

  return [
    {
      title: <></>,
      width: 50,
      render: (_: unknown, record: Record<string, any>) =>
        record.complete ? (
          <CheckCircleFilled style={{ color: '#53c639', fontSize: '24px' }} />
        ) : (
          <ExclamationCircleFilled
            style={{
              color: '#faad14',
              fontSize: '24px',
            }}
          />
        ),
    },
    {
      title: translate(messages['tier.text']),
      key: 'number',
      dataIndex: 'number',
      render: (_: unknown, _1: unknown, index: number) => (
        <StyledCenter>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div>
              <FormattedMessage id="tier.text" defaultMessage="Tier" />
            </div>
            <strong>{index + 1}</strong>
          </div>
        </StyledCenter>
      ),
    },
    {
      title: translate(messages['tier-name.text']),
      key: 'name',
      dataIndex: 'name',
      render: (_: unknown, record: Record<string, any>, index: any) => (
        <StyledCenter style={{ marginRight: '24px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div>
              <FormattedMessage
                id="tier-name.text"
                defaultMessage="Tier Name"
              />
            </div>
            <ConditionalInput node={record} name="name" index={index} />
          </div>
        </StyledCenter>
      ),
    },
    {
      title: translate(messages['min-active-members.text']),
      key: 'minimumActiveMembersCount',
      dataIndex: 'minimumActiveMembersCount',
      render: (_: unknown, record: Record<string, any>, index: any) =>
        values.tierRequirement.includes(TIER_REQUIREMENT.ACTIVE_MEMBERS) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              <FormattedMessage
                id="min-active-members.text"
                defaultMessage="Min Active Members"
              />
            </div>
            <ConditionalInput
              node={record}
              name="minimumActiveMembersCount"
              index={index}
              disableEdit={index !== values.levels.length - 1}
            />
          </div>
        ) : (
          <></>
        ),
    },
    {
      title: translate(messages['max-active-members.text']),
      key: 'maximumActiveMembers',
      dataIndex: 'maximumActiveMembers',
      render: (_: unknown, record: Record<string, any>, index: any) =>
        values.tierRequirement.includes(TIER_REQUIREMENT.ACTIVE_MEMBERS) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div>
              <FormattedMessage
                id="max-active-members.text"
                defaultMessage="Max Active Members"
              />
            </div>
            <ConditionalInput
              node={record}
              name="maximumActiveMembers"
              disableEdit
              index={index}
            />
          </div>
        ) : (
          <></>
        ),
    },
    {
      title: translate(messages['min-net-profit.text']),
      key: 'minimumNetProfit',
      dataIndex: 'minimumNetProfit',
      render: (_: unknown, record: Record<string, any>, index: any) =>
        values.tierRequirement.includes(TIER_REQUIREMENT.NET_PROFIT) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              <FormattedMessage
                id="min-net-profit.text"
                defaultMessage="Min Net Profit"
              />
            </div>
            <ConditionalInput
              node={record}
              name="minimumNetProfit"
              index={index}
              disableEdit={index !== values.levels.length - 1}
            />
          </div>
        ) : (
          <></>
        ),
    },
    {
      title: translate(messages['max-net-profit.text']),
      key: 'maximumNetProfit',
      dataIndex: 'maximumNetProfit',
      render: (_: unknown, record: Record<string, any>, index: any) =>
        values.tierRequirement.includes(TIER_REQUIREMENT.NET_PROFIT) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div>
              <FormattedMessage
                id="max-net-profit.text"
                defaultMessage="Max Net Profit"
              />
            </div>
            <ConditionalInput
              node={record}
              name="maximumNetProfit"
              disableEdit
              index={index}
            />
          </div>
        ) : (
          <></>
        ),
    },
    {
      title: translate(messages['commission.text']),
      key: 'percentage',
      dataIndex: 'percentage',
      render: (_: unknown, record: Record<string, any>, index: number) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div>
            <FormattedMessage
              id="commission.text"
              defaultMessage="Commission"
            />
          </div>
          <ConditionalInput node={record} name="percentage" index={index} />
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: unknown, _1: Record<string, any>, index: number) => (
        <ActionColumns index={index} />
      ),
    },
  ];
};
