import { defineMessages } from 'react-intl';

const messages = defineMessages({
  withdrawalText: {
    id: 'withdrawal-tax.text',
    defaultMessage: 'Withdrawal Tax',
  },
  handlingFee: {
    id: 'handling-fee.text',
    defaultMessage: 'Handling Fee',
  },
  promoDeduction: {
    id: 'promo-deduction.text',
    defaultMessage: 'Promo Deduction',
  },
  withdrawalLimitFee: {
    id: 'withdrawals.withdrawal-limit-fee.text',
    defaultMessage: 'Withdrawal Limit Fee',
  },
});

export default messages;
