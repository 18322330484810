import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { UPDATE_AFFILIATE_PROGRAMME } from 'graphql/mutations/affiliateAgent.mutation';
import { AFFILIATE_PROGRAMMES } from 'graphql/queries/affiliateAgent.query';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import noOp from 'utils/noOp';
import useTranslate from 'utils/useTranslate';
import { AffiliateProgrammeTypes } from '../../utils';

const CustomModal: any = Modal;

const DeactivateAffiliateProgramme = ({
  affiliate,
}: {
  affiliate: Record<string, any>;
}) => {
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const translate = useTranslate();
  const [updateAffiliateProgramme, { loading }] = useMutation(
    UPDATE_AFFILIATE_PROGRAMME,
    {
      onCompleted: () => {
        message.success(
          <span data-testid="success">
            {translate(messages.AFFILIATE_PROGRAMME_DEACTIVATE_SUCCESS)}
          </span>
        );
        handleShow();
      },
      onError: () =>
        message.error(
          <span data-testid="error">
            {translate(messages.AFFILIATE_PROGRAMME_DEACTIVATE_ERROR)}
          </span>
        ),
    }
  );

  return (
    <>
      <a
        href="#/"
        onClick={
          affiliate.status === AffiliateProgrammeTypes.ACTIVE
            ? handleShow
            : noOp
        }
        data-testid="deactivate"
      >
        <FormattedMessage id="deactivate.text" defaultMessage="Deactivate" />
      </a>
      {isShow && (
        <CustomModal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          cancelButtonProps={{ disabled: loading }}
          okButtonProps={{ loading, 'data-testid': 'confirm' }}
          onCancel={handleShow}
          onOk={() => {
            updateAffiliateProgramme({
              variables: {
                id: affiliate.id,
                input: {
                  enabled: false,
                },
              },
              refetchQueries: [
                {
                  query: AFFILIATE_PROGRAMMES,
                },
              ],
            });
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-deactivate.text"
                defaultMessage="Confirm Deactivate"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default DeactivateAffiliateProgramme;
