import React, { FC } from 'react';
import { Select, Radio, Row, Col, Tag } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import { EmailAuditTrailConnectionEdge } from 'types/graphqlTypes';
import { isObjectPropsEmpty } from 'utils/isObjectPropsEmpty/isObjectPropsEmpty';
import { startCase, uniq } from 'lodash';
import { useDebounce } from 'hooks/useDebounce';
import { TRIGGER_EVENT_NAMES, TRIGGER_SOURCES, SENT_STATUS } from './utils';
import { EMAIL_ADDRESSES } from './queries';

const { Option } = Select;

type Props = {
  setFilters: Function;
  filters: Record<string, any>;
  defaultValues: object;
};

type Rec = Record<string, string>;

const EmailAuditTrailFilter: FC<Props> = ({
  filters,
  setFilters,
  defaultValues,
}) => {
  const handleFilterChange = ({
    name,
    value,
  }: {
    name: string;
    value: string | boolean | null;
  }) => {
    setFilters((prev: Rec) => ({
      ...prev,
      // eslint-disable-next-line no-nested-ternary
      [name]: name === 'sent' ? { eq: value } : value ? { eq: value } : null,
    }));
  };

  const [searchedEmailText, setSearchedEmailText] = React.useState('');
  const debouncedText = useDebounce(searchedEmailText, 500);

  const createTriggerSourceOpt = TRIGGER_SOURCES.map((source: Rec) => {
    const { value, label } = source;
    return (
      <Option key={value} value={value}>
        {label}
      </Option>
    );
  });

  const { data, loading: loadingEmails } = useQuery(EMAIL_ADDRESSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 10,
      filter: {
        sentTo: {
          contains: debouncedText,
        },
      },
    },
  });
  const edges = coercedGet(data, 'emailAuditTrail.edges', []);
  const emailAddresses =
    edges.map((edge: EmailAuditTrailConnectionEdge) => edge.node.sentTo) || [];

  const uniqueEmailAdd: string[] = uniq(emailAddresses);

  const createSentToOpt = uniqueEmailAdd.map(
    (emailAddress: string, index: number) => (
      <Option key={index} value={emailAddress}>
        {emailAddress}
      </Option>
    )
  );

  return (
    <Row gutter={[50, 20]} align="middle">
      <Col>
        <h4>Date/Time Range</h4>
        <DateTimeRangePicker
          label="Date/Time Range"
          showLabel={false}
          value={
            filters.startDateTime || filters.endDateTime
              ? [filters?.startDateTime?.gte, filters?.endDateTime?.lte]
              : null
          }
          onChange={(e) => {
            setFilters((prev: Rec) => ({
              ...prev,
              ...(e?.length
                ? {
                    startDateTime: {
                      gte: e[0],
                    },
                    endDateTime: {
                      lte: e[1],
                    },
                  }
                : null),
            }));
          }}
        />
      </Col>

      <Col>
        <h4>Trigger Event Name</h4>
        <Select
          allowClear
          onChange={(e: string) =>
            handleFilterChange({
              name: 'triggerEventName',
              value: e,
            })
          }
          value={filters?.triggerEventName?.eq}
          placeholder="Trigger Event Name"
          style={{ width: 150 }}
        >
          {TRIGGER_EVENT_NAMES.map((eventName) => (
            <Option key={eventName} value={eventName}>
              {startCase(eventName.toLowerCase())}
            </Option>
          ))}
        </Select>
      </Col>
      <Col>
        <h4>Sent To</h4>
        <Select
          allowClear
          onChange={(e: string) =>
            handleFilterChange({
              name: 'sentTo',
              value: e,
            })
          }
          value={filters?.sentTo?.eq}
          loading={loadingEmails}
          showSearch
          onSearch={(text: string) => setSearchedEmailText(text)}
          style={{ width: 200 }}
          placeholder="Sample@mail.com"
          optionFilterProp="children"
          onClear={() => setSearchedEmailText('')}
          onBlur={() => setSearchedEmailText('')}
          onFocus={() => setSearchedEmailText('')}
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {createSentToOpt}
        </Select>
      </Col>
      <Col>
        <h4>Trigger Source</h4>
        <Select
          value={filters?.source?.eq}
          allowClear
          onChange={(e: string) =>
            handleFilterChange({
              name: 'source',
              value: e,
            })
          }
          placeholder="Trigger Source"
          style={{ width: 150 }}
        >
          {createTriggerSourceOpt}
        </Select>
      </Col>
      <Col>
        <h4>Sent Status</h4>
        <Radio.Group
          options={SENT_STATUS}
          onChange={(e) =>
            handleFilterChange({
              name: 'sent',
              value: e.target.value,
            })
          }
          optionType="button"
          value={filters?.sent?.eq}
        />
      </Col>

      <Col>
        {!isObjectPropsEmpty(filters) && (
          <Tag
            style={{
              cursor: 'pointer',
            }}
            closable
            onClick={() => setFilters(defaultValues)}
            color="#108ee9"
          >
            Clear All
          </Tag>
        )}
      </Col>
    </Row>
  );
};

export default EmailAuditTrailFilter;
