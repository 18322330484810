import React from 'react';
import Icon, { FilterOutlined } from '@ant-design/icons';
import { Typography, Table, Spin, Tooltip } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment-timezone';
import NextPrevButton from 'components/NextPrevButton';
import coercedGet from 'utils/coercedGet';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import { useNoRefreshPaginate } from 'utils/usePagination';
import { ALink } from 'components/ALink/ALink';
import { ReactComponent as DownloadIcon } from 'components/assets/cloud_download.svg';
// import { useMockQuery } from 'apollo/mock';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { useCustomColumnsV2 } from 'SuperAdminMain/store/SuperAdminCustomColumnState/customColumnState';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import { isEmpty } from 'lodash';
import { useScreenTabSA } from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';
import { getPollInterval } from 'utils/pollInterval';
import { StyledTableContainer, StyledCenter } from './styles';
import { VENDOR_USAGE_REPORTS } from './queries';
import TableFooterPages from './components/TableFooterPages';
import ResizableTitle from './components/ResizableTitle';
import FilterStatus from '../../../../shared/components/FilterStatus';
import VendorNames from '../VendorNames';
// import GameCategoryNames from '../GameCategoryNames';

const ReportsTable = React.forwardRef((__props, ref) => {
  const { isTabActive } = useScreenTabSA();
  const isActiveTab = isTabActive(TabId.VENDORS_CONSOLIDATED_USAGE_REPORT);
  const { bypassedCurrentTimezone } = useReportsTimezone();
  const translate = useTranslate();

  const [
    page,
    dispatchPagination,
    actions,
    defaultInitState,
  ] = useNoRefreshPaginate({
    first: 10,
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
  });

  const refetchVariables = {
    first: page.first,
    after: page.after,
    filter: {
      status: {
        in: ['GENERATED', 'GENERATING'],
      },
    },
  };

  const { loading, data = {}, refetch } = useQuery(VENDOR_USAGE_REPORTS, {
    variables: refetchVariables,
    pollInterval: getPollInterval({
      isActive: isActiveTab,
      isRealtime: true,
    }),
  });

  React.useImperativeHandle(ref, () => ({
    refetchTable: refetch,
  }));

  const impartResponsiveWidth = React.useCallback(
    (value) => ({ width: value } || {}),
    []
  );

  const columnFields = React.useMemo(
    () => [
      {
        key: 'vendor',
        title: translate(messages.VENDOR),
        dataIndex: 'filter',
        render: (filter: Record<string, any>) => (
          <VendorNames vendors={filter.vendor ? filter.vendor.in : []} />
        ),
      },
      // {
      //   key: 'vendorGameCategory',
      //   title: translate(messages.VENDOR_GAME_CATEGORY),
      //   dataIndex: 'filter',
      //   render: filter => (
      //     <GameCategoryNames games={filter.game ? filter.game.in : []} />
      //   ),
      // },
      {
        key: 'timezone',
        title: translate(messages.TIMEZONE_SELECTION),
        dataIndex: 'timezone',
        render: (text: string) => text || '-',
      },
      {
        key: 'createdBy',
        title: translate(messages.CREATED_BY),
        width: 100,
        dataIndex: 'creator',
        render: (text: Record<string, any>) =>
          coercedGet(text, 'username', null) || '-',
      },
      {
        key: 'createdDate',
        title: translate(messages.CREATED_DATE),
        dataIndex: 'dateTimeCreated',
        render: (text: string) =>
          text
            ? moment
                .tz(text, bypassedCurrentTimezone)
                .format('YYYY/MM/DD HH:mm')
            : '-',
      },
      {
        key: 'filters',
        title: '',
        hidden: true,
        ...impartResponsiveWidth(120),
        render: (report: Record<string, any>) => (
          <Tooltip placement="bottom" title={<FilterStatus report={report} />}>
            <FilterOutlined className="ml-2" />
          </Tooltip>
        ),
      },
      {
        key: 'download',
        title: '',
        hidden: true,
        ...impartResponsiveWidth(120),
        render: (node: Record<string, any>) => {
          const disabled = node.status === 'GENERATING' || !node.report;
          const tooltipTitle =
            node.status === 'GENERATED'
              ? translate(messages['reports.generated.text'])
              : translate(messages['reports.generating.text']);

          return (
            <Tooltip placement="top" title={tooltipTitle}>
              <ALink
                className={`styled-center ${disabled ? 'cursor-disabled' : ''}`}
                href={coercedGet(node, 'report.uri', null)}
              >
                <Icon
                  className={`mr-3 ${
                    disabled ? 'action-icon-disabled' : 'action-icon'
                  }`}
                  component={DownloadIcon}
                  style={{ fontSize: '22px' }}
                />
              </ALink>
            </Tooltip>
          );
        },
      },
    ],
    [bypassedCurrentTimezone, impartResponsiveWidth, translate]
  );

  const { totalCount, pageInfo } = coercedGet(data, 'vendorUsageReports', {});
  const totalPage = Math.ceil(totalCount / page.first) || 1;
  const edges = coercedGet(data, 'vendorUsageReports.edges', []);
  const tableData = edges.map((edge: any) => edge.node) || [];

  const {
    loading: loadingCC,
    filterColumns,
    customColumns,
  } = useCustomColumnsV2(TabId.VENDORS_CONSOLIDATED_USAGE_REPORT, columnFields);

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  if (isEmpty(customColumns)) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  const isLoading = loading || loadingCC;

  return (
    <>
      <StyledTableContainer
        className="table-container"
        style={{ height: 'calc(100vh - 205px)', overflow: 'hidden' }}
      >
        <Table
          size="middle"
          columns={filterColumns(columnFields)}
          loading={isLoading}
          dataSource={tableData}
          pagination={false}
          components={components}
          scroll={{
            y: 640,
            x: 'calc(545px + 50%)',
          }}
        />
      </StyledTableContainer>
      <div>
        <div
          className="d-flex justify-content-space-between align-items-center"
          style={{
            padding: '1.2rem',
            height: '2.4vh',
            background: '#f3f8fb',
          }}
        >
          <div> </div>
          <div className="styled-center">
            <div className="mr-3">
              <Typography.Text strong className="mr-1">
                {totalCount}
              </Typography.Text>
              <FormattedMessage
                id="results-found.text"
                defaultMessage="Results Found"
                values={{
                  count: null,
                }}
              />
            </div>

            <NextPrevButton
              loading={isLoading}
              onPrev={() =>
                dispatchPagination({
                  type: actions.PREV,
                })
              }
              disablePrev={page.currentPage + 1 === 1}
              onNext={() =>
                dispatchPagination({
                  type: actions.NEXT,
                  payload: pageInfo,
                })
              }
              disableNext={page.currentPage + 1 === totalPage}
              label={`${page.currentPage + 1} / ${totalPage}`}
            />

            <TableFooterPages
              first={page.first}
              setPage={(rowCount) => {
                dispatchPagination({
                  type: actions.SETSTATE,
                  payload: {
                    ...defaultInitState,
                    first: rowCount,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default ReportsTable;
