import { Divider } from 'antd';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import DownloadCsvButton from 'components/DownloadCsvButton';
import { AFFILIATE_REQUESTS_CSV_DATA } from 'graphql/queries/affiliateAgent.query';
import messages from 'messages';
import React from 'react';
import { useIntl } from 'react-intl';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import RequestUpdateToggle from 'components/RequestUpdateToggle';
import { customFormatMessage } from 'utils/customFormatMessage';
import BulkActions from './components/BulkActions';

type Props = {
  isRealtime?: boolean;
  handleSetRealtime?: () => void;
  refreshPage: () => void;
};

const HeaderRight: React.FC<Props> = ({
  handleSetRealtime,
  isRealtime,
  refreshPage,
}) => {
  const { formatMessage } = useIntl();
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  return (
    <div className="d-flex align-items-center">
      <RequestUpdateToggle checked={isRealtime} onChange={handleSetRealtime} />
      <RefreshButton onClick={() => refreshPage()} />
      <Divider type="vertical" />
      <div className="mr-1">
        <ColumnsFilterNew iconOnly tabId="affiliate-request" />
      </div>
      <div className="mr-1">
        <DownloadCsvButton
          tabId="affiliate-request"
          edgesPath="affiliateRequests.edges"
          query={AFFILIATE_REQUESTS_CSV_DATA}
          iconOnly
          fileName={translate(messages['agent-affiliate-request.text'])}
        />
      </div>
      <BulkActions />
    </div>
  );
};

export default HeaderRight;
