import { useQuery } from '@apollo/react-hooks';
import { Spin } from 'antd';
import { GET_MEMBER } from 'graphql/queries/member.query';
import React from 'react';
import styled from 'styled-components';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { NullableType } from '../../../interfaces/graphql.interface';

export const MemberContext = React.createContext<NullableType<object>>(null);

type Props = {
  children: any;
  memberId: string;
};

export const MemberProvider = ({ children, memberId }: Props) => {
  const { context } = useOperatorHeader();

  const { loading, error, data, refetch } = useQuery(GET_MEMBER, {
    variables: {
      id: memberId,
    },
    fetchPolicy: 'network-only',
    context,
  });

  if (loading)
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );

  return (
    <MemberContext.Provider
      value={{
        loading,
        error,
        data,
        member: data.member,
        refetch,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export const useMember = () => React.useContext(MemberContext);

const StyledCenter = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
