import gql from 'graphql-tag';

export const GET_PERMISSIONS_IDS = gql`
  query PermissionsIds(
    $filter: PermissionGroupsFilterInput
    $partialFilter: PermissionGroupsFilterInput
  ) {
    permissionGroups(filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: permissionGroups(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_PERMISSION_GROUPS = gql`
  query PermissionGroups(
    $first: Int
    $after: Binary
    $filter: PermissionGroupsFilterInput
  ) {
    permissionGroups(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          serialCode
          name
          permissions
          dateTimeCreated
          dateTimeUpdated
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_PERMISSION_GROUP = gql`
  query PermissionGroup($id: ID!) {
    permissionGroup(id: $id) {
      id
      serialCode
      name
      permissions
      dateTimeCreated
      dateTimeUpdated
    }
  }
`;

export const GET_PERMISSION_GROUP_NAMES = gql`
  query FilterPermissionGroupNames(
    $first: Int
    $after: Binary
    $filter: PermissionGroupsFilterInput
  ) {
    permissionGroups(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_PERMISSION_GROUP_SERIALCODE = gql`
  query FilterPermissionGroupSerialCodes(
    $first: Int
    $after: Binary
    $filter: PermissionGroupsFilterInput
  ) {
    permissionGroups(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          serialCode
        }
      }
    }
  }
`;

export const GET_PERMISSION_GROUP_PERMISSIONS = gql`
  query FilterPermissionGroupPermissions(
    $first: Int
    $after: Binary
    $filter: PermissionGroupsFilterInput
  ) {
    permissionGroups(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          permissions
        }
      }
    }
  }
`;

export default {};
