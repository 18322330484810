import gql from 'graphql-tag';

export const BATCH_REAL_NAME = `member(id: $id) {
  id
  realName
}`;

export const BATCH_AFFILIATE = `member(id: $id) {
  id
  affiliate {
    id
    code
  }
}`;

export const BATCH_VIP_TIER = `member(id: $id) {
  id
  memberLoyaltyLevels {
    id
    name
    color
    programme {
      id
      name
    }
  }
}`;

export const BATCH_MARKER = `member(id: $id) {
  id
  memberLevel {
    id
    name
    color
  }
}`;

export const BATCH_EFFECTIVE_BET = `member(id: $id) {
  id
  totalEffectiveBet
}`;

export const BATCH_STATUS = `member(id: $id) {
  id
  status
}`;

export const BATCH_TAGS = `member(id: $id) {
  id
  tags {
    id
    name
    color
    description
  }
}`;

export const REAL_NAMES = gql`
  query RealNames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          realName
        }
      }
    }
  }
`;
