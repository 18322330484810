import { EllipsisOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Badge, Button, Dropdown, Menu, Spin, Table } from 'antd';
import { withErrorHandler } from 'components/ErrorHandler';
import NextPrev from 'components/NextPrev';
import { AFFILIATE_PROGRAMMES } from 'graphql/queries/affiliateAgent.query';
import { StyledCenter } from 'pages/styles';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import statusBadge from 'utils/statusBadge';
import useTranslate from 'utils/useTranslate';
import { AffiliateTypes, useAffiliateState } from '../../context';
import ActivateAffiliateProgramme from './components/ActivateAffiliateProgramme';
import DeactivateAffiliateProgramme from './components/DeactivateAffiliateProgramme';
import DeleteAffiliateProgramme from './components/DeleteAffiliateProgramme';
import DuplicateAffiliateProgramme from './components/DuplicateAffiliateProgramme/DuplicateAffiliateProgramme';
import { StyledTableContainer } from './styles';
import { AffiliateProgrammeTypes } from './utils';

const { Column, ColumnGroup } = Table;

function AgentAffiliateTable({ showForm }: { showForm: (data: any) => void }) {
  const translate = useTranslate();
  const [, dispatch] = useAffiliateState() as any;

  const [page, setPage] = useState({
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
    totalCount: 0,
    pageInfo: {},
  });

  const DEFAULT_ROW_COUNT = 10;

  const variables = {
    first: DEFAULT_ROW_COUNT,
    after: page.after,
  };

  const { loading, data, refetch } = useQuery(AFFILIATE_PROGRAMMES, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pageInfo = coercedGet(res, 'affiliateProgrammes.pageInfo', {});
      const totalCount = coercedGet(res, 'affiliateProgrammes.totalCount', 0);
      setPage({
        ...page,
        pageInfo,
        totalCount,
      });
    },
  });

  const collection = coercedGet(data, 'affiliateProgrammes.edges', []).map(
    (q: Record<string, any>, i: number) => ({
      ...q.node,
      key: i + 1,
    })
  );

  const totalPage = Math.ceil(page.totalCount / DEFAULT_ROW_COUNT) || 1;

  const handleNext = () => {
    const pageInfo = coercedGet(data, 'affiliateProgrammes.pageInfo', {});
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const getColumns = React.useCallback(
    () => [
      {
        title: 'ID',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: translate(messages.PROGRAMME_NAME),
        dataIndex: 'name',
        key: 'name',
        render: (name: string, record: Record<string, any>) => (
          <a
            data-testid="name"
            onClick={() => {
              if (record.status !== AffiliateProgrammeTypes.DRAFT) {
                dispatch({
                  type: AffiliateTypes.SET_ACTIVE_SCREEN,
                  payload: AffiliateTypes.AGENT_AFFILIATE_DETAILS,
                });
                dispatch({
                  type: AffiliateTypes.SET_SUMMARY,
                  payload: record.id,
                });
                return;
              }
              showForm(record);
            }}
            href="#section"
          >
            {name}
          </a>
        ),
      },
      {
        title: translate(messages.TO_DO),
        dataIndex: 'todo',
        key: 'todo',
        render: (_: any, record: Record<string, any>) =>
          record.status === AffiliateProgrammeTypes.DRAFT && (
            <Button
              onClick={() => {
                showForm(record);
              }}
            >
              <FormattedMessage id="continue.text" defaultMessage="Continue" />
            </Button>
          ),
      },
      {
        title: translate(messages.STATUS),
        dataIndex: 'status',
        key: 'status',
        render: (key: any) => (
          <span data-testid="status">
            <Badge
              status={statusBadge(key)}
              text={translate(messages[`${key.toLowerCase()}.text`])}
            />
          </span>
        ),
      },
      {
        title: translate(messages.TIERS),
        dataIndex: 'levels',
        key: 'levels',
        render: (_: any, record: Record<string, any>) =>
          record.levels.length || '-',
      },
      {
        title: translate(messages.DEFAULT_PROGRAMME),
        dataIndex: 'default',
        key: 'default',
        render: (e: any) => (
          <span>{translate(messages[`${e ? 'YES' : 'NO'}`])}</span>
        ),
      },
      {
        title: translate(messages.NEGATIVE_CARRY),
        dataIndex: 'negativeCarryOverEnabled',
        key: 'negativeCarryOverEnabled',
        render: (e: any) => (
          <span>{translate(messages[`${e ? 'YES' : 'NO'}`])}</span>
        ),
      },
      {
        title: translate(messages.MEMBERS),
        dataIndex: 'membersCount',
        key: 'membersCount',
        render: (e: any) => e || 0,
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_: any, record: Record<string, any>) => (
          <Dropdown
            placement="bottomRight"
            overlay={
              <Menu>
                <StyledMenuItem
                  disabled={record.status === AffiliateProgrammeTypes.ACTIVE}
                  onClick={() => {
                    showForm(record);
                  }}
                >
                  <FormattedMessage id="edit.text" defaultMessage="Edit" />
                </StyledMenuItem>
                <StyledMenuItem>
                  <DuplicateAffiliateProgramme
                    affiliate={record}
                    refetch={refetch}
                  />
                </StyledMenuItem>
                <StyledMenuItem
                  disabled={record.status === AffiliateProgrammeTypes.ACTIVE}
                >
                  <DeleteAffiliateProgramme
                    disabled={record.status === AffiliateProgrammeTypes.ACTIVE}
                    affiliate={record}
                    refetch={refetch}
                  />
                </StyledMenuItem>
                <StyledMenuItem
                  disabled={[
                    AffiliateProgrammeTypes.ACTIVE,
                    AffiliateProgrammeTypes.DRAFT,
                  ].includes(record.status)}
                >
                  <ActivateAffiliateProgramme affiliate={record} />
                </StyledMenuItem>
                <StyledMenuItem
                  disabled={[
                    AffiliateProgrammeTypes.INACTIVE,
                    AffiliateProgrammeTypes.DRAFT,
                  ].includes(record.status)}
                >
                  <DeactivateAffiliateProgramme affiliate={record} />
                </StyledMenuItem>
              </Menu>
            }
          >
            <Button type="link" icon={<EllipsisOutlined />} />
          </Dropdown>
        ),
      },
    ],
    [translate, showForm, dispatch, refetch]
  );

  useEffect(() => {
    dispatch({
      type: AffiliateTypes.SET_REFETCH_VARIABLES,
      payload: variables,
    });
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <StyledCenter data-testid="loading">
        <Spin />
      </StyledCenter>
    );
  }

  return (
    <StyledTableContainer data-testid="container">
      <Table
        rowKey="key"
        size="middle"
        dataSource={collection}
        pagination={false}
        loading={loading}
        data-testid="table"
      >
        {getColumns().map((column: any) => {
          const { subColumns = [], title, key, render } = column;
          return subColumns.length > 0 ? (
            <ColumnGroup title={title}>
              {subColumns.map(
                ({
                  title: subTitle,
                  dataIndex: subDataIndex,
                  render: subRender,
                }: {
                  title: any;
                  dataIndex: number;
                  render: any;
                }) => (
                  <Column
                    title={subTitle}
                    dataIndex={subDataIndex}
                    key={subDataIndex}
                    render={subRender}
                  />
                )
              )}
            </ColumnGroup>
          ) : (
            <Column title={title} dataIndex={key} key={key} render={render} />
          );
        })}
      </Table>
      <NextPrev
        onPrev={handlePrev}
        onNext={handleNext}
        disablePrev={page.currentPage + 1 === 1}
        disableNext={page.currentPage + 1 === totalPage}
        label={`${page.currentPage + 1} / ${totalPage}`}
      />
    </StyledTableContainer>
  );
}

export default withErrorHandler(AgentAffiliateTable);

const StyledMenuItem = styled(Menu.Item)`
  &.ant-dropdown-menu-item-disabled,
  &.ant-dropdown-menu-submenu-title-disabled {
    a {
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
    }
  }
`;
