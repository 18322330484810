import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, message, Spin } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import gql from 'graphql-tag';
import { CLOSE_MEMBER, UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import { checkPermission } from 'pages/components/PermissionGroup/utils';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePermissions } from 'store/accountState';
import {
  Member,
  MemberStatus,
  MutationCloseMemberArgs,
} from 'types/graphqlTypes';
import removeNull from 'utils/removeNull';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import EditStatusMenuForm from './components/EditStatusMenuForm';
import messages from './messages';
import { StyledModal } from './styles';

export const STATUS = gql`
  query Status($id: ID) {
    member(id: $id) {
      id
      username
      status
    }
  }
`;

type Props = {
  member: Member;
  refetch?: Function;
  onSuccess?: (data: string) => void;
};

const EditStatusMenu = ({ member, refetch, onSuccess }: Props) => {
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const [closeMember] = useMutation<boolean, MutationCloseMemberArgs>(
    CLOSE_MEMBER
  );
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);

  const translate = useTranslate();
  const { context } = useOperatorHeader();

  const { role, permissions } = usePermissions() || '';
  const isAllowed = checkPermission(
    role,
    permissions,
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_EDIT_BRAND_LINKAGES
  );

  const { loading, data } = useQuery(STATUS, {
    variables: {
      id: member.id,
    },
  });

  if (loading) {
    return <Spin />;
  }

  const handleSubmit = async (e: Record<string, any>) => {
    setLoading(true);
    try {
      const input = { ...e };
      delete input.username;
      if (input.status === MemberStatus.Closed) {
        closeMember({
          variables: {
            id: member.id,
            input: {
              reason: input.reason,
              ...(isAllowed && {
                brandLinkagesUpdated: input.brandLinkagesUpdated,
              }),
            },
          },
          context,
        });
      } else {
        delete input.reason;

        if (!isAllowed) {
          delete input.brandLinkagesUpdated;
        }

        updateMember({
          variables: {
            id: member.id,
            input: {
              ...removeNull(input),
            },
          },
          context,
        }).then(() => {
          if (refetch) refetch();
        });
      }

      if (onSuccess) onSuccess(member.id);

      message.success(
        translate(messages['member-status.success-message.text'])
      );
    } finally {
      setShow(false);
      setLoading(false);
    }
  };
  return (
    <>
      <Button
        type="link"
        className="text-black"
        onClick={handleShow}
        block
        role="button"
      >
        <FormattedMessage id="edit-status.text" defaultMessage="Edit status" />
      </Button>
      <StyledModal
        title={
          <FormattedMessage
            id="edit-status.text"
            defaultMessage="Edit status"
          />
        }
        onCancel={handleShow}
        visible={isShow}
        footer={false}
      >
        <EditStatusMenuForm
          initialValues={{
            status: data?.member?.status,
            reason: '',
            brandLinkagesUpdated: false,
          }}
          isLoading={isLoading}
          onSubmit={handleSubmit}
          onClose={handleShow}
          allowedEditBrandLinkage={isAllowed}
        />
      </StyledModal>
    </>
  );
};

export default EditStatusMenu;
