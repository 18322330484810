import React, { useState } from 'react';
import { Modal, Select, Spin } from 'antd';
import gql from 'graphql-tag';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import useTranslate from '../../../../../hooks/useTranslate';
import { Flex } from '../../../../../shared/styles/Flex';
import coercedGet from '../../../../../utils/coercedGet';

const messages = defineMessages({
  'add-vendor.text': {
    id: 'add-vendor.text',
    defaultMessage: 'Add Vendor',
  },
  'save.text': {
    id: 'save.text',
    defaultMessage: 'Save',
  },
});

const VENDORS = gql`
  query {
    vendors {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const AddVendorModal = ({
  openModal,
  closeModal,
  onVendorSelect,
  existingVendors,
}: Props) => {
  const [selectedVendor, setSelectedVendor] = useState<SelectValue>({
    key: '',
    label: '',
  });
  const translate = useTranslate();
  const { data, loading } = useQuery(VENDORS);
  const vendorsEdges = coercedGet(data, 'vendors.edges', []);
  const vendors = vendorsEdges.map((edge: { node: any }) => edge.node);
  const onSaveVendor = () => {
    onVendorSelect(selectedVendor);
    setSelectedVendor({ key: '', label: '' });
  };

  return (
    <Modal
      visible={openModal}
      onCancel={closeModal}
      onOk={onSaveVendor}
      okText={translate(messages['save.text'])}
      closable
      title={translate(messages['add-vendor.text'])}
    >
      <Spin spinning={loading}>
        <Flex alignItems="center">
          <span className="mr-2">
            <FormattedMessage
              id="vendor-name.text"
              defaultMessage="Vendor Name"
            />
          </span>
          <Select
            value={selectedVendor as any}
            labelInValue
            style={{ width: '50%' }}
            onChange={(e: any) => setSelectedVendor(e)}
          >
            {vendors
              .filter(
                (vendor: any) =>
                  !existingVendors.find(
                    (existingVendor) => existingVendor.key === vendor.id
                  )
              )
              .map((vendor: { id: string; name: string }) => (
                <Select.Option value={vendor.id}>{vendor.name}</Select.Option>
              ))}
          </Select>
        </Flex>
      </Spin>
    </Modal>
  );
};

type Props = {
  openModal: boolean;
  closeModal: () => void;
  onVendorSelect: (data: SelectValue) => void;
  existingVendors: SelectValue[];
};

type SelectValue = {
  key: string;
  label: string;
};
