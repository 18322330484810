import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import FiltersProvider, {
  useFilterValues,
} from 'SuperAdminMain/contexts/Filters';
import { VMHeader } from './VMHeader/VMHeader';
import { VMBody } from './VMBody/VMBody';
import { VendorMaintenanceProvider } from './context';

const pageInitState = {
  first: 10,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
};

const VENDORS = gql`
  query VENDORS($first: Int, $filter: VendorsFilterInput, $after: Binary) {
    vendors(first: $first, filter: $filter, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
      edges {
        node {
          id
          name
          type
          status
        }
      }
    }
  }
`;

const VendorMaintenance: React.FC = () => {
  const { filters } = useFilterValues();
  const [page, setPage] = useState<any>(pageInitState);
  const { loading, error, data, refetch } = useQuery(VENDORS, {
    variables: { first: page.first, after: page.after, filter: { ...filters } },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <VendorMaintenanceProvider
      {...{
        data,
        loading,
        error,
        refetch,
        page,
        setPage,
      }}
    >
      <Container>
        <VMHeader />
        <VMBody />
      </Container>
    </VendorMaintenanceProvider>
  );
};

export default () => (
  <FiltersProvider>
    <VendorMaintenance />
  </FiltersProvider>
);

const Container = styled.div`
  background: #fff;
  height: calc(100vh - 40px - 40px);
`;
