import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Spin } from 'antd';
import { string, func } from 'prop-types';
import coercedGet from 'utils/coercedGet';
import ModifiedTag from '../ModifiedTag';

export const CLIENT = gql`
  query Client($id: ID!) {
    admin(id: $id) {
      id
      username
    }
  }
`;

const ClientTag = (props) => {
  const { id, onClose } = props;
  const { data = {}, loading } = useQuery(CLIENT, {
    variables: {
      id,
    },
  });

  return (
    <ModifiedTag {...props} closable onClose={(e) => onClose(e)}>
      {loading ? <Spin /> : coercedGet(data, 'admin.username', null)}
    </ModifiedTag>
  );
};

ClientTag.propTypes = {
  id: string,
  onClose: func,
};

ClientTag.defaultProps = {
  id: null,
  onClose: (e) => e,
};

export default ClientTag;
