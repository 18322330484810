import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import useCustomFilters from 'utils/useCustomFilters';
import { FormattedMessage } from 'react-intl';

import { CustomFilter } from 'types/graphqlTypes';
import { Wrapper } from './styles';
import EditSearchSettings from '../EditSearchSettings';
import DeleteSettings from '../DeleteSettings';

type FilterStateType = { [key: string]: any };

type Props = {
  onUseFilter: (value: FilterStateType) => void;
  tabContext: string;
};

type CustomFilterType = CustomFilter & { isShow: boolean };

const SearchSettingsList: React.FC<Props> = ({ onUseFilter, tabContext }) => {
  const [customFilterList, setCustomFilterList, { loading }] = useCustomFilters(
    tabContext
  ) as any;

  if (loading)
    return (
      <div className="p-6">
        <FormattedMessage id="loading.text" defaultMessage="Loading" />
      </div>
    );

  const handleChildren = (id: string) => {
    const newCustomFilterList = customFilterList.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          isShow: !item.isShow,
        };
      }

      return {
        ...item,
        isShow: false,
      };
    });
    setCustomFilterList(newCustomFilterList);
  };

  const handleUseSearchSettings = (e: any) => {
    onUseFilter(e.filter);
  };

  return (
    <Wrapper>
      {customFilterList.length ? (
        customFilterList.map((item: CustomFilterType) => (
          <div key={item.id} className="bb-1 pt-5">
            <div className="row-item pb-5">
              <span>
                <a
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    handleUseSearchSettings(item);
                  }}
                >
                  {item.name}
                </a>
              </span>
              <span className="options">
                <a
                  href="#/"
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleChildren(item.id);
                  }}
                >
                  <FormattedMessage id="edit.text" defaultMessage="Modify" />{' '}
                  <DownOutlined />
                </a>
                <DeleteSettings tabContext={tabContext} searchSettings={item} />
              </span>
            </div>
            {item.isShow && (
              <EditSearchSettings
                searchSettings={item}
                onClose={() => handleChildren(item.id)}
                tabContext={tabContext}
              />
            )}
          </div>
        ))
      ) : (
        <div className="py-3">
          <FormattedMessage
            id="no-data-yet.text"
            defaultMessage="no data yet"
          />
        </div>
      )}
    </Wrapper>
  );
};

export default SearchSettingsList;
