import React, { FC } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormItemProps } from 'antd/lib/form';

const FormItem: FC<FormItemProps> = ({ children, ...restProps }) => (
  <Form.Item
    {...{
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    }}
    {...restProps}
  >
    <>{children}</>
  </Form.Item>
);

export default FormItem;
