import gql from 'graphql-tag';

export const DEPOSIT_PROVIDER = gql`
  query DepositProvider($id: ID!) {
    depositProvider(id: $id) {
      name
    }
  }
`;

export default {};
