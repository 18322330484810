import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';

import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { showPermissionError } from 'components/Navbar/Navbar';

import { StyledTable, StyledCenter } from 'styles';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';
import columns from './columns';
import DeleteOperator from '../../../DeleteOperator';

type Props = {
  operators: Record<string, any>[];
  toggleEdit: any;
  refetch: () => void;
  loading: boolean;
};

const OperatorsTable = ({ operators, toggleEdit, refetch, loading }: Props) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { role, permissions } = usePermissions();

  const { ALLOWED_LIST, ALLOWED_EDIT, ALLOWED_DELETE } = collectPermissions(
    role,
    permissions,
    ['LIST', 'EDIT', 'DELETE'],
    ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_ACCOUNT_MANAGEMENT
  );

  const data = operators
    ? operators.map(({ node }) => ({ ...node, key: node.id }))
    : [];
  const translate = useTranslate();
  const newColumns = [
    {
      key: 'account',
      title: translate(messages.ACCOUNT),
      render: (operator: Record<string, any>) => (
        <ALink
          onClick={() => {
            if (!ALLOWED_EDIT) {
              showPermissionError('Account Management - Edit');
              return;
            }
            toggleEdit(operator.id);
          }}
        >
          {operator.username}
        </ALink>
      ),
    },
    ...columns(formatMessage),
    {
      key: 'action',
      title: translate(messages['actions.text']),
      dataIndex: 'id',
      render: (id: string) =>
        (ALLOWED_EDIT || ALLOWED_DELETE) && (
          <Dropdown
            overlay={
              <Menu>
                {ALLOWED_EDIT && (
                  <Menu.Item
                    onClick={() => {
                      toggleEdit(id);
                    }}
                  >
                    <FormattedMessage id="edit.text" defaultMessage="Edit" />
                  </Menu.Item>
                )}
                {ALLOWED_DELETE && (
                  <Menu.Item>
                    <DeleteOperator id={id} refetch={refetch} />
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
          >
            <Button type="link" icon={<EllipsisOutlined />} />
          </Dropdown>
        ),
    },
  ];

  const { filterColumns } = useCustomColumnsV2(
    'account-management',
    newColumns
  );

  if (loading) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  return (
    ALLOWED_LIST && (
      <StyledTable
        columns={filterColumns(newColumns)}
        size="middle"
        dataSource={data}
        pagination={false}
        scroll={{ x: 1200 }}
      />
    )
  );
};

export default OperatorsTable;
