const access = {
  SUPER_ADMIN: 'SUPER_ADMIN',
};

interface INavlistContainer {
  id: string;
  access: string[];
  list: INavlist[];
}

export interface INavlist {
  id: string;
  permission: string;
  multiple: boolean;
}

export const navlist: INavlistContainer[] = [
  {
    id: 'dashboard',
    access: [access.SUPER_ADMIN],
    list: [],
  },
  {
    id: 'account-management',
    access: [access.SUPER_ADMIN],
    list: [
      {
        id: 'superadmin-all-client',
        permission: 'all_client_view',
        multiple: true,
      },
    ],
  },
  {
    id: 'vendor-management',
    access: [access.SUPER_ADMIN],
    list: [
      {
        id: 'superadmin-vendor-maintenance',
        permission: 'vendor-maintenance',
        multiple: true,
      },
      {
        id: 'superadmin-vendor-group-management',
        permission: 'vendor-group-management',
        multiple: true,
      },
    ],
  },
  {
    id: 'report',
    access: [access.SUPER_ADMIN],
    list: [
      {
        id: 'superadmin-bet-history-report',
        permission: 'superadmin-reports',
        multiple: true,
      },
      {
        id: 'superadmin-accounts-consolidated-report',
        permission: 'superadmin-reports',
        multiple: true,
      },
      {
        id: 'superadmin-consolidated-vendor-usage-report',
        permission: 'superadmin-reports',
        multiple: true,
      },
      {
        id: 'superadmin-custom-report',
        permission: 'superadmin-reports',
        multiple: true,
      },
    ],
  },
  {
    id: 'geo-fencing',
    access: [access.SUPER_ADMIN],
    list: [
      {
        id: 'superadmin-whitelist',
        permission: 'superadmin_whitelist',
        multiple: true,
      },
    ],
  },
];
