import { useAccount } from 'store/accountState';
import uuid from 'uuid';
import React from 'react';

export const useOperatorHeader = () => {
  const [onPoll, setOnPoll] = React.useState(false);
  const {
    account: { account: user },
  } = useAccount();

  const isOperatorAccount = user?.role === 'OPERATOR';

  const context = {
    ...(isOperatorAccount && !onPoll
      ? { headers: { 'X-Operator-Request-Id': uuid.v4() } }
      : null),
  };

  return {
    context,
    setOnPoll,
  };
};
