import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import useCustomFilters from 'utils/useCustomFilters';
import { FormattedMessage } from 'react-intl';
import { QuickSearchIds } from 'components/QuickSearchFilter/QuickSearchFilter';
import { Wrapper } from './styles';
import EditSearchSettings from '../EditSearchSettings';
import DeleteSettings from '../DeleteSettings';

type SearchSettingsFilterType = {
  onUseFilter: (val: any) => void;
};

const SearchSettingsList = ({ onUseFilter }: SearchSettingsFilterType) => {
  const [customFilterList, setCustomFilterList, { loading }] = useCustomFilters(
    QuickSearchIds.SA_VENDOR_USAGE_REPORT
  ) as any;

  if (loading)
    return (
      <div className="p-6">
        <FormattedMessage id="loading.text" defaultMessage="Loading" />
      </div>
    );

  const handleChildren = (id: string) => {
    const newCustomFilterList = customFilterList.map(
      (item: Record<string, any>) => {
        if (item.id === id) {
          return {
            ...item,
            isShow: !item.isShow,
          };
        }

        return {
          ...item,
          isShow: false,
        };
      }
    );
    setCustomFilterList(newCustomFilterList);
  };

  const handleUseSearchSettings = (e: Record<string, any>) => {
    onUseFilter(e.filter);
  };

  return (
    <Wrapper>
      {customFilterList.length ? (
        customFilterList.map((item: Record<string, any>) => (
          <div key={item.id} className="bb-1 pt-3">
            <div className="row-item pb-3">
              <span>
                <a
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    handleUseSearchSettings(item);
                  }}
                >
                  {item.name}
                </a>
              </span>
              <span className="options">
                <a
                  href="#/"
                  className="mr-2"
                  onClick={(e) => {
                    e.preventDefault();
                    handleChildren(item.id);
                  }}
                >
                  <FormattedMessage id="edit.text" defaultMessage="Modify" />{' '}
                  <DownOutlined />
                </a>
                <DeleteSettings searchSettings={item} />
              </span>
            </div>
            {item.isShow && (
              <EditSearchSettings
                searchSettings={item}
                onClose={() => handleChildren(item.id)}
              />
            )}
          </div>
        ))
      ) : (
        <div className="py-3">
          <FormattedMessage
            id="no-data-yet.text"
            defaultMessage="no data yet"
          />
        </div>
      )}
    </Wrapper>
  );
};

export default SearchSettingsList;
