import Layout from 'components/Layout';
import React from 'react';
import { QuickSearchIds } from 'components/QuickSearchFilter/QuickSearchFilter';
import { SearchSettingsForm } from './components/SearchSettingsForm/SearchSettingsForm';
import FilterItems from './components/FilterItems/index';

interface Props {
  filters: any;
  onFilterChange: (e: any) => any;
  isLoading?: boolean;
}

export const Sidebar: React.FC<Props> = ({
  filters,
  onFilterChange,
  isLoading = false,
}) => {
  const handleChange = (e: any) => {
    const { name, value } = e;

    onFilterChange({
      ...filters,
      [name]: value,
    });
  };

  return (
    <Layout.Sidebar
      data-testid="sidebar"
      quickSearch={{
        filters,
        contextKey: 'member-management',
        onFilterChange,
        searchSettingsForm: SearchSettingsForm,
        isNext: true,
        quickSearchId: QuickSearchIds.MEMBER_MANAGEMENT,
      }}
    >
      <FilterItems
        onRawFilterChange={handleChange}
        onFilterChange={onFilterChange}
        isLoading={isLoading}
        filters={filters}
      />
    </Layout.Sidebar>
  );
};
