/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus,jsx-a11y/no-static-element-interactions */
import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { Icon, Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Button, Radio, Checkbox } from 'antd';
import removeNull from 'utils/removeNull';
import generatePassword from 'generate-password';
import { shape } from 'prop-types';
import { ReactComponent as ChangeLoginPasswordIcon } from 'pages/components/NewMemberDetail/components/Header/assets/change-login-password.svg';
import styled from 'styled-components';
import { ALink } from 'components/ALink/ALink';
import { CHANGE_PASSWORD } from './mutations';
import { StyledModal, Container } from './styles';

const StyledIcon = styled(Icon)`
  font-size: 24px;
`;
const { Item } = Form;

const ChangeLoginPassword = ({ member }: { member: Record<string, any> }) => {
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);

  const [updateMember] = useMutation(CHANGE_PASSWORD);

  const [input, setInput] = useState<Record<string, any>>({
    username: member.username,
    password: null,
    forceChangePassword: null,
  });

  const [value, setValue] = useState(1);
  const textAreaRef = useRef<any>(null);

  const handleModal = () => {
    setShow(!isShow);
  };

  const isEmpty = (obj: Record<string, any>) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  const handleChangePassword = async (e: Record<string, any>) => {
    await setInput(e);

    const obj = {
      password: removeNull(e).password || null,
      forceChangePassword: removeNull(e).forceChangePassword || null,
    };

    try {
      setLoading(true);

      await updateMember({
        variables: {
          id: member.id,
          input: removeNull(obj),
        },
      });

      setLoading(false);

      if (!isEmpty(removeNull(obj)))
        message.success(
          <FormattedMessage
            id="member-details.updated-successfully.text"
            defaultMessage="Updated successfully"
          />
        );

      await setInput({
        ...input,
        password: null,
        forceChangePassword: null,
      });

      handleModal();
    } finally {
      setLoading(false);
    }
  };

  const handleCheckBox = (e: boolean) =>
    setInput({ ...input, forceChangePassword: e });

  const handleOnChange = (
    { target: { value: radio } }: any,
    setFieldValue: (key: string, data: any) => void
  ) => {
    setValue(radio);

    if (radio === 2 && input.forceChangePassword) {
      handleCheckBox(false);
    } else {
      setInput({ ...input, password: null });
      setFieldValue('password', '');
    }
  };

  const handleGeneratePassword = () => {
    setInput({
      ...input,
      password: generatePassword.generate({
        length: 10,
        numbers: true,
      }),
    });
  };

  const handleCopy = () => {
    if (textAreaRef.current.value) {
      textAreaRef.current.select();
      document.execCommand('copy');
      message.success(
        <FormattedMessage
          id="member-details.Copy success.text"
          defaultMessage="Copy success"
        />
      );
    } else {
      message.error(
        <FormattedMessage
          id="member-details.nothing-to-copy.text"
          defaultMessage="Nothing to copy"
        />
      );
    }
  };

  return (
    <>
      <div
        className="text-center cursor-pointer header-icons-container"
        onClick={handleModal}
      >
        <StyledIcon
          style={{ display: 'block' }}
          component={ChangeLoginPasswordIcon}
        />
        <ALink className="fs-8 fw-500" style={{ marginTop: '2px' }}>
          <FormattedMessage
            id="edit-login-password.text"
            defaultMessage="Edit Login Password"
          />
        </ALink>
      </div>
      {isShow && (
        <StyledModal visible width={425} onCancel={handleModal} footer={false}>
          <Formik
            initialValues={input}
            onSubmit={handleChangePassword}
            enableReinitialize
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .nullable()
                .min(
                  6,
                  (
                    <FormattedMessage
                      id="member-details.minimum-password-characters.text"
                      defaultMessage="Minimum of 6 characters"
                    />
                  ) as any
                )
                .max(
                  12,
                  (
                    <FormattedMessage
                      id="member-details.maximum-password-characters.text"
                      defaultMessage="Maximum of 12 characters"
                    />
                  ) as any
                ),
            })}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="p-3">
                  <div>
                    <FormattedMessage
                      id="member-details.change-password.text"
                      defaultMessage="Change Password"
                    />
                  </div>
                  <br />
                  <Radio.Group
                    onChange={(e) => handleOnChange(e, setFieldValue)}
                    value={value}
                  >
                    <Radio value={1}>
                      <FormattedMessage
                        id="member-details.force-login-password-change.text"
                        defaultMessage="Force Login Password Change"
                      />
                    </Radio>

                    <Radio value={2}>
                      <FormattedMessage
                        id="member-details.change-password.text"
                        defaultMessage="Change Password"
                      />
                    </Radio>
                  </Radio.Group>
                  <br />
                  <br />
                  {value === 1 ? (
                    <>
                      <Checkbox
                        onChange={(e) => handleCheckBox(e.target.checked)}
                        checked={input.forceChangePassword}
                      >
                        <FormattedMessage
                          id="member-details.confirm-sending-force-change.text"
                          defaultMessage="Confirm sending force password change message"
                        />
                      </Checkbox>
                    </>
                  ) : (
                    <>
                      <div>
                        <FormattedMessage
                          id="username.text"
                          defaultMessage="Username"
                        />
                      </div>
                      <Item className="mb-2">
                        <Input
                          name="username"
                          readOnly
                          value={member.username || ''}
                          style={{ background: '#dedede' }}
                        />
                      </Item>
                      <div>
                        <FormattedMessage
                          id="password.text"
                          defaultMessage="Password"
                        />
                      </div>
                      <Item
                        validateStatus={
                          errors.password && touched.password ? 'error' : ''
                        }
                        help={touched.password ? errors.password : null}
                        className="mb-0"
                      >
                        <Container>
                          <Input.Password
                            name="password"
                            onChange={handleChange}
                            value={values.password || ''}
                            placeholder="Please enter password"
                          />
                        </Container>

                        <Button type="primary" onClick={handleGeneratePassword}>
                          <FormattedMessage
                            id="member-details.generate-password.text"
                            defaultMessage="Generate Password"
                          />
                        </Button>

                        <div>
                          <Button onClick={handleCopy} block>
                            <FormattedMessage
                              id="member-details.copy.text"
                              defaultMessage="Copy"
                            />
                          </Button>
                        </div>

                        <textarea
                          ref={textAreaRef}
                          value={values.password || ''}
                          style={{
                            position: 'absolute',
                            opacity: '0',
                            pointerEvents: 'none',
                          }}
                          readOnly
                        />
                      </Item>
                    </>
                  )}
                </div>

                <div className="bt-1 p-4 text-right">
                  <Button className="mr-2" onClick={handleModal}>
                    <FormattedMessage
                      id="cancel.text"
                      defaultMessage="Cancel"
                    />
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      isLoading ||
                      (!input.forceChangePassword && !values.password)
                    }
                  >
                    <FormattedMessage
                      id="save-changes.text"
                      defaultMessage="Save changes"
                    />
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </StyledModal>
      )}
    </>
  );
};

ChangeLoginPassword.propTypes = {
  member: shape({}),
};

ChangeLoginPassword.defaultProps = {
  member: {},
};

export default ChangeLoginPassword;
