import React from 'react';
import styled from 'styled-components';
import { useSidebar } from 'store/sidebarState';
import ReportsTimezoneProvider from 'contexts/ReportsTimezone';
import RootInnerSidebar from './RootInnerSidebar';
import RootOuterSidebar from './RootOuterSidebar';

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;

  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  pointer-events: none;
  z-index: 42;

  transition: 0.3s ease;
  transform: ${(props: { sidebarStatus: boolean }) =>
    !props.sidebarStatus && 'translateX(230px)'};
`;

const RootSideBar = React.memo(() => {
  const { sidebarKey } = useSidebar();
  const [sidebarStatus, setSidebarStatus] = React.useState(false);
  const innerProps = { sidebarStatus, setSidebarStatus };

  return (
    <Container sidebarStatus={sidebarStatus}>
      <ReportsTimezoneProvider>
        <RootInnerSidebar key={sidebarKey} {...innerProps} />
        <RootOuterSidebar onCloseSidebar={() => setSidebarStatus(false)} />
      </ReportsTimezoneProvider>
    </Container>
  );
});

export default RootSideBar;
