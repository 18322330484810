import { cryptoCurrencies } from 'constants/currency';
import { DATE_TIME_FORMAT } from 'constants/date';
import { DynamicObj } from 'interfaces/user.interface';
import { capitalize, includes } from 'lodash';
import moment from 'moment';
import { MemberPromo } from 'types/promo-graphqlTypes-row';

const formatDate = (date: string, timezone: string) =>
  date ? moment.tz(date, timezone).format(`${DATE_TIME_FORMAT} A`) : '-';

export const getColumns = ({
  renderNumeral,
  reportTimezone,
}: {
  renderNumeral: Function;
  reportTimezone: string;
}) => [
  {
    key: 'member',
    title: 'Member',
    dataIndex: 'member',
    width: 100,
    align: 'center',
    render: (member: DynamicObj) => member?.username || '-',
  },
  {
    key: 'promoTitle',
    title: 'Promo Title',
    dataIndex: 'promo',
    width: 100,
    align: 'center',
    render: (promo: DynamicObj) => promo.name || '-',
  },
  {
    key: 'amount',
    title: 'Member Deposited',
    width: 100,
    align: 'center',
    render: (record: MemberPromo) => {
      const isCrypto = includes(cryptoCurrencies, record.currency);

      return renderNumeral(record.amount, isCrypto);
    },
  },
  {
    key: 'bonus',
    title: 'Bonus Received',
    width: 100,
    align: 'center',
    render: (record: MemberPromo) => {
      const isCrypto = includes(cryptoCurrencies, record.currency);

      return renderNumeral(record.bonus, isCrypto);
    },
  },
  {
    key: 'turnoverTarget',
    title: 'Turnover Requirement',
    dataIndex: 'turnoverTarget',
    width: 100,
    align: 'center',
    render: (turnoverTarget: string) => renderNumeral(turnoverTarget),
  },
  {
    key: 'turnoverCounter',
    title: 'Turnover Counter',
    dataIndex: 'turnoverCounter',
    width: 100,
    align: 'center',
    render: (turnoverCounter: string) => renderNumeral(turnoverCounter),
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    width: 100,
    align: 'center',
    render: (status: string) => capitalize(status),
  },
  {
    key: 'bonusWon',
    title: 'Bonus Won',
    dataIndex: 'bonusWon',
    width: 100,
    align: 'center',
    render: (bonusWon: string) => renderNumeral(bonusWon),
  },
  {
    key: 'currency',
    title: 'Currency',
    dataIndex: 'currency',
    width: 100,
    align: 'center',
    render: (currency: string) => currency ?? '-',
  },
  {
    key: 'dateTimeCreated',
    title: 'Date Enrolled',
    dataIndex: 'dateTimeCreated',
    width: 100,
    align: 'center',
    render: (dateTimePlaced: string) =>
      formatDate(dateTimePlaced, reportTimezone),
  },
  {
    key: 'dateTimeResolved',
    title: 'Date Ended',
    dataIndex: 'dateTimeResolved',
    width: 100,
    align: 'center',
    render: (dateTimeResolved: string) =>
      formatDate(dateTimeResolved, reportTimezone),
  },
];
