import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { func, shape } from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import get from 'lodash/get';
import { SharedStyledSelect } from 'styles/SharedStyledSelect';
import debounce from 'lodash/debounce';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { customFormatMessage } from 'utils/customFormatMessage';
import { MEMBER_ACCOUNT } from './queries';

const messages = defineMessages({
  'system-message.enter-a-member.text': {
    id: 'system-message.enter-a-member.text',
    defaultMessage: 'Enter a member',
  },
});

export const MemberAccountFilter = ({ values, setFilters }) => {
  const [filter, setFilter] = useState({
    username: {
      contains: '',
    },
  });

  const intl = useIntl();

  const { loading, data = {}, refetch } = useQuery(MEMBER_ACCOUNT, {
    variables: {
      first: 10,
      filter,
    },
  });
  const handleSearch = (e) => {
    setFilter({
      username: {
        contains: e,
      },
    });
    refetch();
  };

  const handleChange = (e) => {
    if (e.length) {
      setFilters({
        in: e,
      });
    } else {
      setFilters(null);
    }
  };
  const translate = (messageVal, opts = null) =>
    customFormatMessage(intl.formatMessage, messageVal, opts);
  const members = get(data, 'members.edges', []).map(({ node }) => node);
  return (
    <>
      <FilterItem
        label={
          <span>
            <FormattedMessage
              id="system-message.member-account.text"
              defaultMessage="Member Account"
            />
          </span>
        }
      >
        <SharedStyledSelect
          mode="multiple"
          placeholder={translate(
            messages['system-message.enter-a-member.text']
          )}
          onSearch={debounce(handleSearch, 500)}
          loading={loading}
          onChange={handleChange}
          value={values ? values.in : []}
        >
          {members.map((member, index) => (
            <Select.Option key={index} value={member.username}>
              {member.username}
            </Select.Option>
          ))}
        </SharedStyledSelect>
      </FilterItem>
    </>
  );
};

MemberAccountFilter.propTypes = {
  values: shape({}),
  setFilters: func,
};

MemberAccountFilter.defaultProps = {
  values: {},
  setFilters: (e) => e,
};
