import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

import TimeZoneFilter from 'SuperAdminMain/shared/components/TimeZoneFilter';
import ClientFilter from '../../../ClientFilter';
import DateTimeProcessed from '../../../DateTimeProcessed';
import BetIdFilter from '../../../BetIdFilter';
import VendorFilter from '../../../VendorFilter';
import TimePlaced from '../../../TimePlaced';
import TimeSettled from '../../../TimeSettled';
import TipsMinMax from '../../../TipsMinMax';
import JackpotContributionBet from '../../../JackpotContributionBet';
import JackpotWin from '../../../JackpotWin';
import CashoutWinLoss from '../../../CashoutWinLoss';
import FreeSpin from '../../../FreeSpin';
import Device from '../../../Device';

import GameName from '../../../GameName';
import GameCategory from '../../../GameCategory';
import GameSubCategory from '../../../GameSubCategory';

import { FilterStateType } from '../../../../../../types';
import { StyledForm, StyledInputGroup } from './styles';

type Props = {
  initialValues: FilterStateType;
  onSubmit: (value: FilterStateType) => void;
  onClose: (value: string) => void;
  isLoading: Boolean;
};

const SearchSettingsForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const [initValues, setInitValues] = useState(initialValues);

  return (
    <StyledForm>
      <Row>
        <Col span={12}>
          <StyledInputGroup>
            <DateTimeProcessed state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <ClientFilter state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TimeZoneFilter state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <BetIdFilter state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <GameName state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <GameCategory state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <GameSubCategory state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <VendorFilter state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TimePlaced state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TimeSettled state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TipsMinMax state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <JackpotContributionBet
              state={initValues}
              setState={setInitValues}
            />
          </StyledInputGroup>

          <StyledInputGroup>
            <JackpotWin state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <CashoutWinLoss state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <FreeSpin state={initValues} setState={setInitValues} />
          </StyledInputGroup>

          <StyledInputGroup>
            <Device state={initValues} setState={setInitValues} />
          </StyledInputGroup>
        </Col>
      </Row>
      <div className="bt-1 py-4 text-right">
        <Button
          className="mr-2"
          onClick={onClose as any}
          loading={isLoading as any}
        >
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmit(initValues)}
          loading={isLoading as any}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
