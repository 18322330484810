import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox } from 'antd';
import { Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { StyledForm } from './styles';
import validationSchema from './validationSchema';

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (e: any) => void;
  onClose: () => void;
  isLoading: boolean;
};

const ForceWithdrawalPasswordChangeForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: Props) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({ handleSubmit, setFieldValue, values, errors, touched }) => (
      <StyledForm onSubmit={handleSubmit}>
        <div className="p-3">
          <Form.Item
            wrapperCol={{ span: 24 }}
            validateStatus={
              errors.forceChangeWithdrawalPassword &&
              touched.forceChangeWithdrawalPassword
                ? 'error'
                : ''
            }
            help={
              touched.forceChangeWithdrawalPassword
                ? errors.forceChangeWithdrawalPassword
                : null
            }
          >
            <Checkbox
              checked={values.forceChangeWithdrawalPassword}
              onChange={() =>
                setFieldValue(
                  'forceChangeWithdrawalPassword',
                  !values.forceChangeWithdrawalPassword
                )
              }
            >
              <FormattedMessage
                id="confirm-sending.text"
                defaultMessage="Confirm sending"
              />
            </Checkbox>
          </Form.Item>
        </div>
        <div className="bt-1 p-3 text-right">
          <Button className="mr-2" onClick={onClose} loading={isLoading}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={!values.forceChangeWithdrawalPassword}
          >
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </div>
      </StyledForm>
    )}
  </Formik>
);

export default ForceWithdrawalPasswordChangeForm;
