import { useMutation, useQuery } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import { UPDATE_MEMBER_TAG } from 'graphql/mutations/memberTag.mutation';
import {
  GET_MEMBER_TAG,
  GET_MEMBER_TAGS,
} from 'graphql/queries/memberTag.query';
import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import LabelForm from '../LabelForm';
import messages from './messages';
import { StyledModal } from './styles';

type Props = {
  toggleModal: () => void;
  id: string;
  refetchVariables: Record<string, any>;
};

const EditLabel = (props: Props) => {
  const { toggleModal, id, refetchVariables } = props;

  const formRef = React.useRef(null);

  const refetchQueries = [
    {
      query: GET_MEMBER_TAGS,
      variables: refetchVariables,
    },
  ];

  const { data, loading } = useQuery(GET_MEMBER_TAG, {
    variables: { id },
  });
  const translate = useTranslate();
  const [mutate] = useMutation(UPDATE_MEMBER_TAG, {
    onCompleted: () => {
      message.success(
        translate(
          messages['member.label-management.update-new-label-success.text']
        )
      );
    },
    refetchQueries,
  });

  const memberTag = get(data, 'memberTag');

  return (
    <>
      <StyledModal
        title={
          <FormattedMessage
            id="member.label-management.edit-label.text"
            defaultMessage="Edit label"
          />
        }
        visible
        width={540}
        onOk={toggleModal}
        onCancel={toggleModal}
        footer={false}
      >
        <div>
          {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: 200 }}
            >
              <Spin />
            </div>
          ) : (
            <LabelForm
              ref={formRef}
              onClose={toggleModal as any}
              mutate={mutate}
              defaults={memberTag}
            />
          )}
        </div>
      </StyledModal>
    </>
  );
};

export default EditLabel;
