function validateHexColor(color: string) {
  // const res = color.match(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/g) || '#ffffff';
  const res =
    color.match(/#([a-f]|[A-F]|[0-9]){3}(([a-f]|[A-F]|[0-9]){3})?\b/) ||
    '#ffffff';

  return res[0] !== '#' ? res[0] : '#ffffff';
}

export default validateHexColor;
