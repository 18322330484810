import React from 'react';
import { GameType } from 'types/graphqlTypes';
import { Select } from 'antd';
import { capitalize } from 'lodash';

type ProductFilterProps = {
  filters: Record<string, any>;
  setFilters: (val: Record<string, any>) => void;
};

export const ProductFilter: React.FC<ProductFilterProps> = ({
  filters,
  setFilters,
}) => (
  <Select
    data-testid="product-filter"
    mode="multiple"
    style={{ width: '100%' }}
    value={filters?.product?.in || []}
    onChange={(e) => {
      if (e.length === 0 || !e) {
        setFilters({
          name: 'product',
          value: null,
        });
        return;
      }
      setFilters({
        name: 'product',
        value: {
          in: e,
        },
      });
    }}
  >
    {Object.values(GameType).map((value, index) => (
      <Select.Option key={index} value={value}>
        {capitalize(value)}
      </Select.Option>
    ))}
  </Select>
);
