import React from 'react';
import { LayoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

type Props = {
  toggleSidebar: () => void;
};

const HeaderLeft: React.FC<Props> = ({ toggleSidebar }) => (
  <div className="d-flex align-items-center" style={{ width: '100%' }}>
    <Button onClick={() => toggleSidebar()} size="small" className="mr-3 mt-1">
      <LayoutOutlined />
    </Button>
    <FormattedMessage
      id="filter-conditions.text"
      defaultMessage="Filter conditions"
    />
    :
  </div>
);

export default HeaderLeft;
