import gql from 'graphql-tag';

export const SUB_CATEGORIES_BY_VENDOR = gql`
  query CategoriesByVendor(
    $first: Int
    $after: Binary
    $filter: VendorsFilterInput
  ) {
    vendors(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          name
          gameSubCategories
        }
      }
    }
  }
`;

export const GAME_SUB_CATEGORIES = gql`
  query GameByCategories(
    $first: Int
    $after: Binary
    $filter: GamesFilterInput
  ) {
    games(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          subCategory
        }
      }
    }
  }
`;

export default {};
