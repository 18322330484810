import MemberTags from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/MemberTags';
import React from 'react';
import { Spin, Tooltip } from 'antd';
import moment from 'moment';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import { formatDate } from 'utils/dateUtils';
import BatchSplit from 'components/BatchSplit';
import { AFFILIATE_CODE } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/queries';
import MemberLevelButton from 'components/MemberLevelButton';
import { ReactComponent as IconAgent } from 'components/assets/icon-agent.svg';
import { columnAlign } from 'utils/tableAlignment';
import device from 'constants/device';
import { Session, Member } from 'types/graphqlTypes';
import { DATE_TIME_FORMAT } from 'constants/date';
import { setDecimals, truncateDecimals } from 'utils/setDecimals';
import isRow from 'utils/isRow';
import MembersProfile from './components/MemberProfile';
import { messages as localMessages } from './messages';
import { StyledTable, StyledCenter } from './styles';

type Props = {
  members: Record<string, any>[];
  loading: boolean;
  page: Record<string, number>;
};

const MembersOnlineTable = (props: Props) => {
  const { addCurrency } = useConfig();

  const { members, loading, page } = props;

  const translate = useTranslate();
  const columns = [
    {
      key: 'number',
      width: 10,
      align: columnAlign.onCenter,
      title: translate(localMessages['members-online.number.text']),
      dataIndex: 'number',
    },
    {
      key: 'username',
      width: 150,
      align: columnAlign.onCenter,
      title: translate(messages['username.text']),
      render: (member: Record<string, any>) => (
        <MembersProfile member={member} />
      ),
    },
    {
      key: 'realName',
      width: 200,
      align: columnAlign.onCenter,
      title: translate(messages['real-name.text']),
      dataIndex: 'realName',
      render: (realName: string) => realName || '-',
    },
    {
      ...(isRow && {
        key: 'currency',
        width: 100,
        align: columnAlign.onCenter,
        title: 'Currency',
        render: (member: Record<string, any>) => member.currency || '-',
      }),
    },

    {
      key: 'currentSessionTime',
      width: 250,
      align: columnAlign.onCenter,
      title: 'Current Session Time',
      render: (data: Record<string, any>) =>
        `${formatDate(
          data?.session?.dateTimeCreated,
          'DD/MM/YYYY HH:mm'
        )} (${moment(data?.session?.dateTimeCreated).fromNow(true)})` || '-',
    },
    {
      key: 'turnover',
      width: 100,
      align: columnAlign.onCenter,
      title: 'Turnover',
      render: (data: Record<string, Session>) =>
        data?.session?.turnover ? setDecimals(data?.session?.turnover, 2) : '-',
    },
    {
      key: 'ggr',
      width: 100,
      align: columnAlign.onCenter,
      title: 'GGR',
      render: (data: Record<string, Session>) =>
        data?.session?.ggr ? truncateDecimals(data?.session?.ggr, 2) : '-',
    },
    {
      key: 'ipAddress',
      width: 100,
      align: columnAlign.onCenter,
      title: 'IP Address',
      render: (data: Record<string, Session>) =>
        data?.session?.ipAddress ?? '-',
    },
    {
      key: 'channel',
      width: 100,
      align: columnAlign.onCenter,
      title: 'Channel',
      render: (data: Record<string, Session>) =>
        data.session?.channel ? device[data.session.channel] : '-',
    },
    {
      key: 'affiliate',
      width: 100,
      align: columnAlign.onCenter,
      title: translate(messages.AFFILIATE),
      render: ({ id }: { id: string }) => (
        <BatchSplit
          gqlTag={AFFILIATE_CODE}
          customVariables={{
            id,
          }}
          withEdges={false}
          render="affiliate.code"
          manipulate={(code: string) =>
            code !== '-' ? (
              <Tooltip title={code}>
                <IconAgent />
              </Tooltip>
            ) : (
              code
            )
          }
        />
      ),
    },
    {
      key: 'lastLoginDateTime',
      width: 200,
      align: columnAlign.onCenter,
      title: translate(messages.LAST_LOGIN),
      dataIndex: 'lastLoginDateTime',
      render: (text: string) =>
        moment(text).format(`${DATE_TIME_FORMAT} A`) || '-',
    },
    {
      key: 'accountBalance',
      width: 150,
      align: columnAlign.onCenter,
      title: translate(localMessages['members-online.account-balance.text']),
      dataIndex: 'accountBalance',
      render: (balance: number) => addCurrency(balance, 2),
    },
    {
      key: 'lifetimeGGR',
      width: 100,
      align: columnAlign.onCenter,
      title: 'Lifetime GGR',
      dataIndex: 'winloss',
      render: (winloss: number) => setDecimals(winloss, 2),
    },
    {
      key: 'memberLevel',
      align: columnAlign.onCenter,
      title: 'Member Marker',
      render: (data: Record<string, Member>) => (
        <MemberLevelButton memberLevelData={data?.memberLevel} />
      ),
    },
    {
      key: 'labelName',
      width: 60,
      align: columnAlign.onCenter,
      title: 'Member label',
      render: (member: Member) => (
        <MemberTags
          member={{
            ...member,
            tags: member?.tags ? [...member?.tags] : [],
          }}
          refetchParams={{}}
        />
      ),
    },
  ];

  const newColumns: any = columns.map((col) => ({
    ...col,
  }));

  const { filterColumns } = useCustomColumnsV2(
    'members-online',
    newColumns as any
  );

  const tableData = members.map(({ node }, index) => ({
    number: index + 1 + +page.currentPage * +page.first,
    id: node.id,
    lastLoginDateTime: node.lastLoginDateTime,
    accountBalance: node.totalBalance,
    currency: node.currency,
    realName: node.realName,
    username: node.username,
    winloss: node.winloss,
    session: node.session,
    tags: node.tags,
    memberLevel: node.memberLevel,
  }));

  if (loading) {
    return (
      <StyledCenter>
        <Spin />
      </StyledCenter>
    );
  }

  return (
    <>
      <StyledTable
        columns={filterColumns(newColumns)}
        size="middle"
        dataSource={tableData}
        pagination={false}
        // scroll={{ x: 1800 }}
      />
    </>
  );
};

export default MembersOnlineTable;
