import React, { useEffect } from 'react';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { yupResolver } from '@hookform/resolvers';
import DrawerFooter from 'components/DrawerFooter';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import Drawer from '../../../common/Drawer/Drawer';
import { useMissionsValue } from '../../context';
import { Day } from './Day';
import { RadioSwitch } from '../../utils';
import { ErrorMessage, localMessages, validationSchema } from './utils';

const initValues = {
  cyclesCount: 1,
  dayRewardEnabled: true,
  dayRewardPointsRequirement: 1,
  dayRewardType: 'MONEY',
  dayRewardAmount: '',
  dayRewardMultiplier: true,
  dayRewardTurnoverRequirementMultiplier: '',
  days: [{ tasks: [] }],
};

const TaskAndRewardSettings = () => {
  const {
    onPrevStep,
    setTaskRewardValue,
    onNextStep,
    taskRewardValue,
    setHideMilestone,
  } = useMissionsValue();
  const translate = useTranslate();
  const defaultValues = {
    ...initValues,
    ...taskRewardValue,
  };

  const { control, getValues, handleSubmit, errors, watch } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(translate)),
  });
  const { fields, append, remove, insert, swap } = useFieldArray({
    control,
    name: 'days',
  });

  const onSubmit = () => {
    setTaskRewardValue(getValues());
    onNextStep();
  };

  const watchPointsSetting = watch('dayRewardEnabled');
  const watchDay: any = useWatch({
    control,
    name: 'days',
  });

  useEffect(() => {
    setHideMilestone(!watchPointsSetting);
  }, [setHideMilestone, watchPointsSetting]);

  return (
    <>
      <Drawer.Content
        heightExcess="220"
        className="mt-3"
        style={{ marginLeft: '5%', marginRight: '5%' }}
      >
        <Row>
          <Col span={18} className="d-flex" style={{ overflowX: 'auto' }}>
            <div className="mr-2 flex-shrink-0" style={{ width: '130px' }}>
              <div className="fs-11">
                <FormattedMessage
                  id="RECURRING_TIMES_SETTINGS"
                  defaultMessage="Recurring Times Settings"
                />
              </div>
              <Controller
                name="cyclesCount"
                control={control}
                as={<InputNumber style={{ width: '100%' }} size="small" />}
              />
              <ErrorMessage errors={errors} name="cyclesCount" />
            </div>
            <Divider
              type="vertical"
              className="flex-shrink-0"
              style={{ height: '50px' }}
            />

            <div className="flex-shrink-0" style={{ width: '75px' }}>
              <div className="fs-11">
                <FormattedMessage
                  id="POINTS_SETTING"
                  defaultMessage="Points Setting"
                />
              </div>
              <div className="d-flex align-items-center">
                <Controller
                  name="dayRewardEnabled"
                  control={control}
                  render={({ value, onChange }) => (
                    <RadioSwitch onChange={onChange} value={value} />
                  )}
                />
              </div>
            </div>

            <div className="ml-2 flex-shrink-0" style={{ width: '130px' }}>
              <div className="fs-11">
                <FormattedMessage id="POINTS" defaultMessage="Points" />
                <Tooltip
                  title={
                    <span className="fs-11">
                      {translate(localMessages.MISSION_POINTS_INFO)}
                    </span>
                  }
                >
                  <InfoCircleOutlined className="ml-1 text-muted fs-13" />
                </Tooltip>
              </div>
              <Controller
                name="dayRewardPointsRequirement"
                control={control}
                as={<InputNumber style={{ width: '100%' }} size="small" />}
              />
              <ErrorMessage errors={errors} name="dayRewardPointsRequirement" />
            </div>

            <div className="ml-2 flex-shrink-0" style={{ width: '130px' }}>
              <div className="fs-11">
                <FormattedMessage
                  id="REWARD_TYPE"
                  defaultMessage="Reward Type"
                />
              </div>
              <Controller
                name="dayRewardType"
                control={control}
                as={
                  <Select size="small" style={{ width: '100%' }}>
                    <Select.Option value="MONEY">
                      <FormattedMessage id="MONEY" defaultMessage="Money" />
                    </Select.Option>
                  </Select>
                }
              />
            </div>

            <div className="ml-2 flex-shrink-0" style={{ width: '130px' }}>
              <div className="fs-11">
                <FormattedMessage
                  id="REWARD_AMOUNT"
                  defaultMessage="Reward Amount"
                />
              </div>
              <Controller
                name="dayRewardAmount"
                control={control}
                as={<Input style={{ width: '100%' }} size="small" />}
              />
              <ErrorMessage errors={errors} name="dayRewardAmount" />
            </div>

            <div className="ml-2 flex-shrink-0" style={{ width: '70px' }}>
              <div className="fs-11">
                <FormattedMessage id="MULTIPLIER" defaultMessage="Multiplier" />
              </div>
              <div className="d-flex align-items-center">
                <Controller
                  name="dayRewardMultiplier"
                  control={control}
                  render={({ value, onChange }) => (
                    <RadioSwitch onChange={onChange} value={value} />
                  )}
                />
              </div>
            </div>

            <div className="ml-2 flex-shrink-0" style={{ width: '100px' }}>
              <div className="fs-11">
                <FormattedMessage id="TURNOVER" defaultMessage="Turnover" /> (X)
              </div>
              <Controller
                name="dayRewardTurnoverRequirementMultiplier"
                control={control}
                as={<Input style={{ width: '100%' }} size="small" />}
              />
              <ErrorMessage
                errors={errors}
                name="dayRewardTurnoverRequirementMultiplier"
              />
            </div>
          </Col>
          <Col span={6} className="d-flex justify-content-flex-end">
            <Button type="primary" ghost onClick={() => append({ tasks: [] })}>
              <PlusOutlined />{' '}
              <FormattedMessage id="ADD_DAY" defaultMessage="Add Day" />
            </Button>
          </Col>
        </Row>

        {fields.map((item, index) => (
          <Day
            swapDay={swap}
            duplicateDay={() => insert(index + 1, watchDay[index])}
            numberOfDays={watchDay?.length}
            removeDay={remove}
            watchPointsSetting={watchPointsSetting as any}
            errors={errors}
            item={item}
            key={item?.id}
            nestIndex={index}
            control={control}
          />
        ))}
      </Drawer.Content>
      <DrawerFooter>
        <>
          <Button onClick={() => onPrevStep()}>
            <FormattedMessage id="BACK" defaultMessage="Back" />
          </Button>
          <Button onClick={handleSubmit(onSubmit)} type="primary">
            <FormattedMessage id="CONTINUE" defaultMessage="Continue" />
          </Button>
        </>
      </DrawerFooter>
    </>
  );
};

export default TaskAndRewardSettings;
