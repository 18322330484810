import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import Icon from '@ant-design/icons';
import { Badge, Divider, Dropdown, Empty, Menu, Spin, Tooltip } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import styled from 'styled-components';
import moment from 'moment';
import { useScreenTabV2 } from 'store/screenTabState';
import { ALink } from 'components/ALink/ALink';

import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import { isTypenameInternal } from 'constants/paymentTypes';
import { formatDate } from 'utils/dateUtils';
import coercedGet from 'utils/coercedGet';
import useMemberCurrency from 'utils/useMemberCurrency';
import WithdrawalDetails from 'pages/components/MemberWithdrawals/components/WithdrawalDetails';
import { DynamicObj, Member } from 'interfaces/user.interface';
import { Withdrawal } from 'types/graphqlTypes';
import { DATE_TIME_FORMAT } from 'constants/date';
import { showPermissionError } from 'components/Navbar/Navbar';
import { startCase } from 'lodash';
import { getColor } from 'components/PaymentStatusFilter/PaymentStatusFilter';
import { useNewMemberDetailPermissions } from '../../constants';
import { TransactionContent } from '../TransactionContent/TransactionContent';

import { ReactComponent as FirstDepositIcon } from '../assets/first.svg';
import { ReactComponent as RemarksIcon } from '../assets/remark.svg';
import { MoreDetailsTransaction } from '../MoreDetailsTransaction/MoreDetailsTransaction';
import { MEMBER_WITHDRAWALS } from './queries';

const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    width: 8px !important;
    height: 8px !important;
  }
`;

export const LastWithdrawal = ({ maxItemCount }: { maxItemCount: number }) => {
  const translate = useTranslate();
  const { member } = useMember()! as { member: Member };
  const { addTab } = useScreenTabV2();
  const [selectedWithdrawalId, setSelectedWithdrawalId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [withdrawals, setWithdrawals] = useState<Withdrawal[]>([]);
  const [totalWithdrawals, setTotalWithdrawals] = useState<number>(0);

  const client = useApolloClient();
  const { addCurrency } = useMemberCurrency();
  const { ALLOWED_WITHDRAWAL_REQUESTS } = useNewMemberDetailPermissions();

  const loadWithdrawals = async () => {
    setIsLoading(true);
    const responseWithdrawal = await client.query({
      query: MEMBER_WITHDRAWALS,
      fetchPolicy: 'network-only',
      variables: {
        first: maxItemCount,
        memberId: member.id,
      },
    });

    const withdrawalsData = coercedGet(
      responseWithdrawal,
      'data.member.withdrawals',
      {}
    );
    const { totalCount, edges } = withdrawalsData;
    const withdrawalNodes: Withdrawal[] = edges.map(
      (edge: { node: Withdrawal }) => edge.node
    );
    setTotalWithdrawals(totalCount);
    setWithdrawals(withdrawalNodes);
    setIsLoading(false);
  };

  useEffect(() => {
    loadWithdrawals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notTheLastItem = (index: number) => index !== maxItemCount - 1;

  const handleAccountNumberPrivacy = (withdrawal: Withdrawal) => {
    const accountNumber = coercedGet(withdrawal, 'bankAccount.accountNumber');
    if (accountNumber) {
      return `******${accountNumber.slice(-5)}`;
    }

    return '-';
  };

  const openWithdrawalRequestTab = (filter?: DynamicObj) => {
    if (!ALLOWED_WITHDRAWAL_REQUESTS) {
      showPermissionError('Withdrawal Requests - View');
      return;
    }
    addTab({
      id: 'withdrawal-requests',
      state: {
        memberIdRef: member.id,
        ...filter,
      },
    });
  };

  const linkToWithdrawalPage = (withdrawal: Withdrawal) => {
    setSelectedWithdrawalId(withdrawal.id);
  };

  const renderPaymentSource = (withdrawal: Withdrawal) => {
    if (
      withdrawal.manualAdjustment ||
      withdrawal.status !== 'APPROVED' ||
      !withdrawal.withdrawalMethod
    ) {
      return '';
    }

    const paymentSource = isTypenameInternal(withdrawal.__typename!)
      ? translate(messages.INTERNAL)
      : translate(messages.THIRD_PARTY);

    return (
      <>
        {withdrawal?.withdrawalMethod?.name} - {paymentSource}
      </>
    );
  };

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center p-3 member-profile-tab-container">
      <Spin>{translate(messages['loading.text'])}</Spin>
    </div>
  ) : (
    <>
      {totalWithdrawals === 0 && (
        <div className="member-profile-tab-container d-flex justify-content-center align-items-center">
          <Empty />
        </div>
      )}

      {totalWithdrawals > 0 && (
        <div className="d-flex flex-column justify-content-space-between member-profile-tab-container">
          {selectedWithdrawalId && (
            <WithdrawalDetails
              toggleModal={() => setSelectedWithdrawalId('')}
              id={selectedWithdrawalId}
            />
          )}

          <div style={{ padding: '16px 16px 0 16px' }}>
            {withdrawals.map((withdrawal, index) => {
              const {
                hexoPayCreditCard,
                transactionDetails,
                withdrawalMethod,
              } = withdrawal || {};
              const {
                firstDigit,
                lastFourDigits,
                expirationMonth,
                expirationYear,
              } = hexoPayCreditCard || {};
              const branch = withdrawal?.bankAccount?.branch;
              const city = withdrawal?.bankAccount?.city;
              const province = withdrawal?.bankAccount?.province;
              return (
                <>
                  <TransactionContent
                    leftNode={{
                      title: (
                        <>
                          <Tooltip
                            title={startCase(
                              withdrawal.externalStatus?.toLowerCase() ||
                                withdrawal.status?.toLowerCase()
                            )}
                            placement="left"
                          >
                            <StyledBadge
                              color={getColor(
                                withdrawal.externalStatus || withdrawal.status
                              )}
                              className="mr-1"
                            />
                          </Tooltip>
                          <div>
                            <strong>
                              {!withdrawal.manualAdjustment ? (
                                <>
                                  {withdrawal.bankAccount ? (
                                    <>
                                      {coercedGet(
                                        withdrawal,
                                        'bankAccount.bank'
                                      )}{' '}
                                      - {handleAccountNumberPrivacy(withdrawal)}{' '}
                                      &nbsp;
                                      {coercedGet(
                                        withdrawal,
                                        'bankAccount.accountName'
                                      )}
                                    </>
                                  ) : (
                                    <>{withdrawal.withdrawalMethod?.name}</>
                                  )}
                                </>
                              ) : (
                                <>
                                  <FormattedMessage
                                    id="withdrawal-method.text"
                                    defaultMessage="Withdrawal Method"
                                  />{' '}
                                  -{' '}
                                  <FormattedMessage
                                    id="manual-adjustment.text"
                                    defaultMessage="Manual Adjustment"
                                  />
                                </>
                              )}
                            </strong>
                            <small className="d-block text-muted">
                              {(branch || city || province) && (
                                <>
                                  <span>{branch || '-'}, </span>
                                  <span>{city || '-'}, </span>
                                  <span>{province || '-'}</span>
                                </>
                              )}
                            </small>
                          </div>
                        </>
                      ),
                      secondary: (
                        <div className="d-flex flex-column">
                          <div className="d-flex">
                            <div className="mr-2">
                              {formatDate(
                                withdrawal.dateTimeCreated,
                                DATE_TIME_FORMAT
                              )}
                            </div>
                            <div>
                              {moment(withdrawal.dateTimeCreated).fromNow()}
                            </div>
                          </div>
                          <div>
                            {withdrawalMethod?.name === 'Triple-A' ? (
                              <>{transactionDetails || '-'}</>
                            ) : (
                              <>
                                {hexoPayCreditCard
                                  ? `${firstDigit}-${lastFourDigits} ${expirationMonth}/${expirationYear}`
                                  : '-'}
                              </>
                            )}
                          </div>
                        </div>
                      ),
                    }}
                    middleNode={{
                      title: withdrawal.isCrypto
                        ? `${withdrawal.currency} ${withdrawal.amount.toFixed(
                            8
                          )}`
                        : addCurrency(member.currency, withdrawal.amount),
                      secondary: renderPaymentSource(withdrawal),
                    }}
                    rightNode={{
                      title: (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                key="1"
                                // onClick={() =>
                                //   handleShowBrowserTab(
                                //     TabProps.withdrawalRequest(member.id),
                                //     setScreenTab
                                //   )
                                // }
                              >
                                <FormattedMessage
                                  id="open-in-new-browser-tab.text"
                                  defaultMessage="Open in new browser tab"
                                />
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['contextMenu']}
                        >
                          <ALink
                            onClick={() => linkToWithdrawalPage(withdrawal)}
                          >
                            {withdrawal.serialCode}
                          </ALink>
                        </Dropdown>
                      ),
                      secondary: (
                        <div className="d-flex flex-column align-items-end">
                          {withdrawal.firstWithdrawal && (
                            <Tooltip
                              title={translate(messages.FIRST_WITHDRAWAL)}
                            >
                              <Icon
                                component={FirstDepositIcon}
                                className="text-danger fs-16 mr-2"
                              />
                            </Tooltip>
                          )}
                          {withdrawal.remarks && (
                            <Tooltip title={withdrawal.remarks}>
                              <Icon
                                component={RemarksIcon}
                                className="text-danger fs-16"
                              />
                            </Tooltip>
                          )}
                          {withdrawal.transactionReference && (
                            <div>{withdrawal.transactionReference}</div>
                          )}
                        </div>
                      ),
                    }}
                  />
                  {notTheLastItem(index) && <Divider className="m-0 mb-2" />}
                </>
              );
            })}
          </div>
          <MoreDetailsTransaction
            valueCount={totalWithdrawals}
            onLoadMore={() => openWithdrawalRequestTab()}
          />
        </div>
      )}
    </>
  );
};
