import React from 'react';
import { Tag } from 'antd';
import coercedGet from 'utils/coercedGet';
import { isPartialString } from 'constants/partialFilterKey';
import MemberTag from 'components/MemberTag';
import { StateFilter } from '../../types';

type Props = {
  stateFilters: StateFilter;
  onFilterChange: (filterArgs: any) => void;
  isLoading: boolean;
};

type TRemoveArgs = {
  key: string;
  item: any;
  e: any;
};

const FilterConditions = ({
  onFilterChange,
  isLoading,
  stateFilters,
}: Props) => {
  const handleRemove = ({ key, item, e }: TRemoveArgs) => {
    e.preventDefault();
    if (!isLoading) {
      const data =
        key !== 'ipAddress'
          ? coercedGet(stateFilters, `${key}`, []).filter(
              (i: any) => i !== item
            )
          : item;
      onFilterChange({ [key]: data });
    }
  };

  const usernames = coercedGet(stateFilters, 'usernames', []);
  const realnames = coercedGet(stateFilters, 'realnames', []);
  const ipAddresses = coercedGet(stateFilters, 'ipAddresses', []);
  const brands = coercedGet(stateFilters, 'brand', []);

  return (
    <>
      {usernames.map((item: string) =>
        isPartialString(item) ? (
          <Tag
            className="mr-2"
            key={item}
            closable
            onClose={(e: any) => handleRemove({ key: 'usernames', item, e })}
          >
            {item}
          </Tag>
        ) : (
          <MemberTag
            fieldName="username"
            key={item}
            onClose={(e: any) => handleRemove({ key: 'usernames', item, e })}
            id={item}
          />
        )
      )}
      {realnames.map((item: any) =>
        isPartialString(item) ? (
          <Tag
            style={{ margin: '4px' }}
            key={item}
            closable
            onClose={(e: any) => handleRemove({ key: 'realnames', item, e })}
          >
            {item}
          </Tag>
        ) : (
          <MemberTag
            fieldName="realName"
            key={item}
            onClose={(e: any) => handleRemove({ key: 'realnames', item, e })}
            id={item}
          />
        )
      )}
      {ipAddresses.map((item: any) =>
        isPartialString(item) ? (
          <Tag
            style={{ margin: '4px' }}
            key={item}
            closable
            onClose={(e) => handleRemove({ key: 'ipAddresses', item, e })}
          >
            {item}
          </Tag>
        ) : (
          <Tag
            key={item}
            closable
            onClose={(e) => handleRemove({ key: 'ipAddresses', item, e })}
            id={item}
          >
            {item}
          </Tag>
        )
      )}
      {brands.map((item: string) => (
        <Tag
          key={item}
          closable
          onClose={(e: any) => handleRemove({ key: 'brand', item, e })}
          id={item}
        >
          {item}
        </Tag>
      ))}
    </>
  );
};

export default FilterConditions;
