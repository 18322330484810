// tslint-disable no-use-before-define
import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import FilterHeader from 'components/FilterHeader';
import Layout from 'components/Layout';
import useFilter from 'contexts/Filter';
import { CREATE_MEMBER_SUMMARY_REPORT } from 'graphql/mutations/memberSummaryReport.mutation';
import messages from 'messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledFloatingSidebarFooter } from 'styles';
import { transformMovableDateFilter } from 'utils/dateUtils';
import useTranslate from 'utils/useTranslate';
import { QUICK_SEARCH_ID } from '../../configs';
import { TableFilterType } from '../../types';
import FilterSettings from './components/FilterSettings';

type Props = {
  refetch: () => void;
};

const Sidebar: React.FC<Props> = ({ refetch }) => {
  const translate = useTranslate();
  const { filter, setFilterField, customFilters } = useFilter();

  const handleFilterChange = ({
    name,
    value,
  }: {
    name: string;
    value: any;
  }) => {
    setFilterField(name, value);
  };

  const [createMemberSummaryReport, { loading }] = useMutation(
    CREATE_MEMBER_SUMMARY_REPORT,
    {
      onCompleted: () => {
        refetch();
        message.success(
          translate(messages['reports.successfully-generated-report.text'])
        );
      },
      onError: () => {
        message.error(translate(messages['error-occurred.text']));
      },
    }
  );

  const generateReport = () => {
    const generateReportFilter = { ...filter };
    const timezone = filter.timezone || null;

    if (filter.startDateTime && filter.endDateTime) {
      const { startEndDate }: any = transformMovableDateFilter({
        startEndDate: {
          gte: filter.startDateTime,
          lte: filter.endDateTime,
        },
      });

      generateReportFilter.startDateTime = startEndDate.gte;
      generateReportFilter.endDateTime = startEndDate.lte;
    } else {
      message.error(translate(messages.START_END_DATE_TIME_FILTER_REQUIRED));
      return;
    }

    if (timezone) {
      delete generateReportFilter.timezone;
    }

    createMemberSummaryReport({
      variables: {
        input: {
          filter: transformMovableDateFilter(generateReportFilter),
          ...(timezone && { timezone }),
        },
      },
    });
  };

  return (
    <Layout.Sidebar>
      <FilterHeader
        context={QUICK_SEARCH_ID}
        filter={filter}
        filterSettings={FilterSettings}
        customFilters={customFilters}
      />
      <div className="pb-5">
        <FilterSettings
          filters={filter as TableFilterType}
          handleFilterChange={handleFilterChange}
          isCustomFilter={false}
        />
      </div>
      <StyledFloatingSidebarFooter className="p-2">
        <Button
          type="primary"
          block
          onClick={() => {
            generateReport();
          }}
          loading={loading}
        >
          <FormattedMessage
            id="reports.generate.sider.text"
            defaultMessage="Generate"
          />
        </Button>
      </StyledFloatingSidebarFooter>
    </Layout.Sidebar>
  );
};

export default Sidebar;
