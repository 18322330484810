/* eslint-disable consistent-return */
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useMutation } from '@apollo/react-hooks';
import { Button, Col, Input, message, Row } from 'antd';
import classNames from 'classnames';
import { UPDATE_VENDOR } from 'graphql/mutations/vendorConfiguration.mutation';
import { merge } from 'lodash';
import Drawer from 'pages/components/common/Drawer/Drawer';
import messages from 'messages';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DrawerActionButtons from 'components/DrawerActionButtons/DrawerActionButtons';
import DrawerFooter from 'components/DrawerFooter';
import { GameCategory, Vendor } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import {
  DrawerContainer,
  SideLabel,
  StyledSpacer,
  TitleContainer,
} from '../../styles';
import './style.scss';
import UploadForm from './UploadForm';

type Props = {
  formItemLayout: object;
  onBackToAddVendorDrawer: () => void;
  gameCategory: GameCategory | null;
  selectedVendors: Vendor[];
  saveGameCategory: (value: boolean) => void;
};

const UploadVendorImage: React.FC<Props> = ({
  formItemLayout,
  onBackToAddVendorDrawer,
  gameCategory,
  selectedVendors,
  saveGameCategory,
}) => {
  const translate = useTranslate();
  const [selectedVendorForm, setSelectedVendorForm] = useState(
    selectedVendors[0]
  );
  const [vendorLandscapeImages, setVendorLandscapeImages] = useState({});
  const [updateVendor] = useMutation(UPDATE_VENDOR);

  const onUploadImages = async () =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (res) => {
      const uploadImagePromises = selectedVendors.map(async (vendor) => {
        await updateVendor({
          variables: {
            id: vendor.id,
            input: {
              desktopImage: {
                [gameCategory?.id || '']: vendorLandscapeImages[vendor.id],
              },
            },
          },
        });
      });

      try {
        await Promise.all(uploadImagePromises);
        res(null);
      } catch (_) {
        message.error(translate(messages.INTERNAL_SERVER_ERROR));
      }
    });

  const setVendorMobileIconId = useCallback(
    ({
      iconId,
      vendorId,
    }: {
      iconId: string | null;
      vendorId: string | null;
    }) => {
      const updatedVendorImages = merge(vendorLandscapeImages, {
        [vendorId || '']: iconId,
      });
      setVendorLandscapeImages(updatedVendorImages);
    },
    [vendorLandscapeImages]
  );

  const onSave = async (withExit = false) => {
    try {
      const allHaveImages = Object.values(vendorLandscapeImages).every(Boolean);
      if (!allHaveImages) {
        message.error('All vendors should have an image');
        return;
      }
      await onUploadImages();
      saveGameCategory(withExit);
      message.success(translate(messages['saved-notification.text']));
    } catch (error) {
      if (typeof error === 'string') {
        message.error(translate(messages[`${error}`]));
      } else {
        message.error(translate(messages.INTERNAL_SERVER_ERROR));
      }
    }
  };

  return (
    <>
      <Drawer.Header
        title={
          <FormattedMessage
            id="upload-vendor-image.text"
            defaultMessage="Upload Vendor Image"
          />
        }
      >
        <DrawerActionButtons
          saveAndExitFn={() => onSave(true)}
          saveFn={() => onSave(false)}
        />
      </Drawer.Header>

      <Drawer.Content>
        <DrawerContainer className="container">
          <Row style={{ height: '70vh' }}>
            <Col span={3} style={{ height: '100%' }}>
              <SideLabel style={{ height: '100%' }}>
                <StyledSpacer />

                {selectedVendors.map((vendor) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div
                    style={{
                      padding: '10px 20px',
                      cursor: 'pointer',
                      wordWrap: 'break-word',
                    }}
                    key={vendor.id}
                    className={classNames({
                      'upload-vendor-image__active-sidetext-container':
                        vendor.id === selectedVendorForm.id,
                    })}
                    onClick={() => setSelectedVendorForm(vendor)}
                  >
                    <h4
                      style={{ marginBottom: '0' }}
                      className={classNames({
                        'upload-vendor-image__active-sidetext':
                          vendor.id === selectedVendorForm.id,
                      })}
                    >
                      <FormattedMessage
                        id="add-vendor-image-label"
                        defaultMessage="{vendor} Image"
                        values={{
                          vendor: `${vendor.name} - ${vendor.type}`,
                        }}
                      />
                    </h4>
                  </div>
                ))}
              </SideLabel>
            </Col>

            <Col span={21}>
              {selectedVendorForm &&
                selectedVendors.map((vendor) => (
                  <div
                    style={{
                      display:
                        selectedVendorForm.id === vendor.id ? 'block' : 'none',
                    }}
                    key={vendor.id}
                  >
                    <Row>
                      <Col span={10}>
                        <StyledSpacer />

                        <TitleContainer>
                          <h4 style={{ marginBottom: '0', fontWeight: 600 }}>
                            <FormattedMessage
                              id="upload-vendor-image.text"
                              defaultMessage="Upload Vendor Image"
                            />
                          </h4>
                        </TitleContainer>
                        <Form layout="horizontal">
                          <Form.Item label="Vendor" {...formItemLayout}>
                            <Input
                              size="large"
                              disabled
                              value={`${vendor.name} - ${vendor.type}`}
                            />
                          </Form.Item>
                        </Form>
                      </Col>
                    </Row>

                    <UploadForm
                      type="desktopImage"
                      numOfVendors={selectedVendors.length}
                      vendor={vendor}
                      key={vendor.id}
                      gameCategory={gameCategory}
                      formItemLayout={formItemLayout}
                      setVendorIconId={setVendorMobileIconId}
                    />
                  </div>
                ))}
            </Col>
          </Row>
        </DrawerContainer>
      </Drawer.Content>

      <DrawerFooter>
        <>
          <Button onClick={onBackToAddVendorDrawer}>
            <FormattedMessage id="back.text" defaultMessage="Back" />
          </Button>
          <Button type="primary" onClick={() => onSave(true)}>
            <FormattedMessage id="continue.text" defaultMessage="Continue" />
          </Button>
        </>
      </DrawerFooter>
    </>
  );
};

export default UploadVendorImage;
