import { useLazyQuery } from '@apollo/react-hooks';
import { Button, message, Modal, Select } from 'antd';
import MembersSelect from 'components/Antd/MembersSelect';
import {
  GET_MEMBER_BRAND_IDS,
  GET_MEMBER_PLATFORM_IDS,
  MEMBERS,
} from 'graphql/queries/memberInteractionLog.query';
import messages from 'messages';
import React, { useState } from 'react';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import { capitalize, startCase, isEmpty, toLower, replace } from 'lodash';
import { useFilterValues } from 'contexts/Filters';
import { types } from 'constants/memberInteractionLog';
import {
  MemberInteractionLogType,
  MemberInteractionLogActorType,
  MemberInteractionLogModule,
} from 'types/graphqlTypes';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import { Moment } from 'moment';
import SelectFilterField from 'components/SelectFilterField';

const interactionLogTypes = Object.keys(types);

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 19.5px 17.5px;
  }

  .ant-modal-body {
    padding: 18.5px 17.5px 27.5px;
  }
`;

const StyledLabel = styled.label`
  font-size: 10px;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.65);
`;

type SearchInfo = {
  memberId: string | null;
  username: string | null;
  action: string | null;
  brandId: string | null;
  platformId: string | null;
  actor: string | null;
  module: string | null;
  dateTimeCreated: {
    gte: Moment | string;
    lte: Moment | string;
  } | null;
};

type Props = {
  setSearchInfo: React.Dispatch<React.SetStateAction<Partial<SearchInfo>>>;
  searchInfo: Partial<SearchInfo>;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFilterFieldState: {
    state: string | undefined;
    setState: React.Dispatch<React.SetStateAction<string | undefined>>;
  };
  visible: boolean;
};

const filterTypes = [
  'username',
  'action',
  'actor',
  'brandId',
  'platformId',
  'module',
  'dateTimeCreated',
];

const SearchMember: React.FC<Props> = ({
  setSearchInfo,
  searchInfo,
  setShowSearch,
  visible,
  selectedFilterFieldState,
}) => {
  const { handleFilterChange } = useFilterValues();

  const {
    state: filterType,
    setState: setFilterType,
  } = selectedFilterFieldState;

  const translate = useTranslate();

  const [input, setInput] = useState(searchInfo);
  const [selectValue, setSelectValue] = useState(filterType);

  const [loadMembers, { loading }] = useLazyQuery(MEMBERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ members }) => {
      if (members.edges.length) {
        const res = members.edges[0].node;

        handleFilterChange({
          name: 'member',
          value: { eq: res.id },
          forgotPrev: true,
        });

        setSearchInfo({
          memberId: res.id,
          username: res.username,
        });

        setShowSearch((prev) => !prev);
      } else {
        message.error(translate(messages.INVALID_USER));
      }
    },
    onError: () => {
      message.error(translate(messages.INTERNAL_SERVER_ERROR));
    },
  });

  const handleSearch = async () => {
    setFilterType(selectValue);

    const variables = {
      first: 1,
      filter: {
        username: { eq: input?.username },
      },
    };

    if (selectValue === 'username') {
      loadMembers({ variables });

      return;
    }

    const isTypeField = selectValue === 'action';

    const dateFilter = {
      gte: input?.dateTimeCreated?.gte,
      lte: input?.dateTimeCreated?.lte,
    };

    handleFilterChange({
      name: isTypeField ? 'type' : selectValue,
      value: {
        ...(selectValue === 'dateTimeCreated'
          ? dateFilter
          : {
              eq: input[selectValue!],
            }),
      },
      forgotPrev: true,
    });

    setSearchInfo({
      [selectValue!]: input[selectValue!],
    });

    setShowSearch((prev) => !prev);
  };

  const actionValues = Object.values(MemberInteractionLogType);
  const actorValues = Object.values(MemberInteractionLogActorType);
  const moduleValues = Object.values(MemberInteractionLogModule);

  const formatTestId = (text: string) => replace(toLower(text), /_/g, '-');

  const getFilterComponent = (value: string) => {
    switch (value) {
      case 'username':
        return (
          <>
            <StyledLabel>{translate(messages.USERNAME)}</StyledLabel>
            <MembersSelect
              testId="interaction-log-username-filter"
              className="mt-1"
              value={input.username}
              placeHolder={translate(messages.ENTER_USERNAME)}
              onChange={(e) =>
                setInput({
                  username: e,
                })
              }
              labelAsValue
              allowClear={false}
            />
          </>
        );

      case 'action':
        return (
          <>
            <StyledLabel>Action</StyledLabel>
            <Select
              className="mt-1"
              value={(input.action as unknown) as string}
              onChange={(val) => {
                const newValue = interactionLogTypes.filter((type) =>
                  val.includes(types[`${type}`].action)
                );

                setInput({
                  action: newValue[0],
                });
              }}
              placeholder="Please Enter Action"
              showSearch
            >
              {actionValues.map((action: string, key: number) => (
                <Select.Option
                  data-testid={`interaction-log-action-filter-option-${formatTestId(
                    action
                  )}`}
                  key={key}
                  value={action}
                >
                  {startCase(capitalize(action))}
                </Select.Option>
              ))}
            </Select>
          </>
        );

      case 'actor':
        return (
          <>
            <StyledLabel>Actor</StyledLabel>
            <Select
              className="mt-1"
              value={(input.actor as unknown) as string}
              onChange={(e) =>
                setInput({
                  actor: e,
                })
              }
              placeholder="Please Enter Actor"
              showSearch
            >
              {actorValues.map((actor: string, key: number) => (
                <Select.Option
                  key={key}
                  value={actor}
                  data-testid={`interaction-log-actor-filter-option-${formatTestId(
                    actor
                  )}`}
                >
                  {startCase(capitalize(actor))}
                </Select.Option>
              ))}
            </Select>
          </>
        );

      case 'module':
        return (
          <>
            <StyledLabel>Module</StyledLabel>
            <Select
              className="mt-1"
              value={(input.module as unknown) as string}
              onChange={(e) =>
                setInput({
                  module: e,
                })
              }
              placeholder="Please Enter Module"
              showSearch
            >
              {moduleValues.map((module: string, key: number) => (
                <Select.Option
                  key={key}
                  value={module}
                  data-testid={`interaction-log-module-filter-option-${formatTestId(
                    module
                  )}`}
                >
                  {startCase(capitalize(module))}
                </Select.Option>
              ))}
            </Select>
          </>
        );

      case 'brandId':
        return (
          <>
            <StyledLabel>Brand ID</StyledLabel>
            <SelectFilterField
              testId="interaction-log-brandId-filter"
              className="mt-1"
              label="Brand Id"
              showLabel={false}
              query={GET_MEMBER_BRAND_IDS}
              queryOperation="eq"
              queryConnection="members"
              filterFieldName="brandId"
              partialSupported={false}
              filters={input.brandId}
              multiple={false}
              onChange={(e) => setInput({ brandId: (e as unknown) as string })}
            />
          </>
        );

      case 'platformId':
        return (
          <>
            <StyledLabel>Platform ID</StyledLabel>
            <SelectFilterField
              testId="interaction-log-platformId-filter"
              className="mt-1"
              label="Platform Id"
              showLabel={false}
              query={GET_MEMBER_PLATFORM_IDS}
              queryOperation="eq"
              queryConnection="members"
              filterFieldName="platformId"
              partialSupported={false}
              filters={input.platformId}
              multiple={false}
              onChange={(e) =>
                setInput({ platformId: (e as unknown) as string })
              }
            />
          </>
        );

      case 'dateTimeCreated':
        return (
          <>
            <StyledLabel>Date Created</StyledLabel>
            <DateTimeRangePicker
              showLabel={false}
              label="date-created"
              value={
                input.dateTimeCreated
                  ? [input.dateTimeCreated.gte, input.dateTimeCreated.lte]
                  : null
              }
              onChange={(e) => {
                setInput({
                  ...(e?.length && {
                    dateTimeCreated: {
                      gte: e[0],
                      lte: e[1],
                    },
                  }),
                });
              }}
            />
          </>
        );

      default:
        break;
    }

    return <></>;
  };

  const handleCloseSearchModal = () => {
    setShowSearch(false);
    setSelectValue(filterType);
    setInput(searchInfo);
  };

  const disableSubmit = Boolean(!selectValue) || isEmpty(input);

  return (
    <StyledModal
      width="304px"
      visible={visible}
      title={translate(messages.MEMBER_INTERACTION_LOG)}
      destroyOnClose
      onCancel={handleCloseSearchModal}
      footer={[
        <Button
          key="cancel"
          disabled={loading}
          onClick={handleCloseSearchModal}
        >
          {translate(messages['cancel.text'])}
        </Button>,
        <Button
          disabled={disableSubmit}
          key="submit"
          type="primary"
          onClick={handleSearch}
        >
          {translate(messages.CONFIRM)}
        </Button>,
      ]}
    >
      <div className="w-100 d-flex justify-content-center align-items-start flex-column">
        <div className="d-flex flex-column w-100 mb-2 justify-content-center">
          <StyledLabel className="mb-1">Filter Type</StyledLabel>
          <Select
            value={selectValue}
            onChange={(e) => setSelectValue(e)}
            filterOption={false}
            placeholder="Please Select Filter Type"
          >
            {filterTypes.map((type, index) => (
              <Select.Option key={index} value={type}>
                {capitalize(startCase(type))}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="d-flex flex-column w-100">
          {getFilterComponent(selectValue!)}
        </div>
      </div>
    </StyledModal>
  );
};

export default SearchMember;
