import React from 'react';
import styled from 'styled-components';
import { Layout, Select } from 'antd';
import { ErrorHandler } from 'components/ErrorHandler';

const { Sider } = Layout;

export const StyledInputGroup = ({ children, className, ...rest }: any) => (
  <ErrorHandler>
    <div className={`${'my-2 '}${className || ''}`} {...rest}>
      {children}
    </div>
  </ErrorHandler>
);

export const StyledSider = styled(Sider)`
  .ant-layout-sider-children {
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  }
`;

export const StyledContainer = styled.div`
  width: 232px;
  .no-padding {
    padding: 0;
  }
  .no-margin {
    margin: 0;
  }
`;

export const StyledSelect = styled(Select)<any>`
  .ant-select-selection {
    border: 0;
    /* border-bottom: 1px solid #d9d9d9; */
    border: 1px solid #d9d9d9;
    border-radius: 0;
  }
  .ant-select-selection__rendered {
    margin-left: 0 !important;
    .ant-select-selection__placeholder {
      margin-left: 0 !important;
    }
  }
`;

export const StyledSpan = styled.span`
  font-size: 10px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const StyledLabel = styled.span`
  font-size: 10px;
  font-weight: bold;
`;

export default {};
