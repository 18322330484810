import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { ALink } from 'components/ALink/ALink';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import TableCellSpinner from 'components/TableCellSpinner';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { WITHDRAWAL_METHOD_NAME } from './query';

interface Props {
  recordID: string;
  toggleDetails: (id?: string) => void;
}

const WithdrawalMethodRemark = ({ recordID, toggleDetails }: Props) => {
  const { loading, error, data = {} } = useQuery(WITHDRAWAL_METHOD_NAME, {
    variables: {
      id: recordID,
    },
    context: { shouldBatch: true },
  });

  const translate = useTranslate();

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;

  const recordName = coercedGet(data, 'withdrawalMethod.name', null);

  const tableCell =
    recordName === 'Manual Adjustment'
      ? translate(messages.MANUAL_ADJUSTMENT)
      : recordName;

  return (
    <>
      <ALink onClick={() => toggleDetails(recordID)}>{tableCell}</ALink>
    </>
  );
};

export default WithdrawalMethodRemark;
