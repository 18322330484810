import { Tag, Typography } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { GeoFenceConfig, Place } from 'types/graphqlTypes';
import { capitalize } from 'lodash';
import CountryCitySelect from './CountryCitySelect';
import CountryTag from './CountryTag';
import IpAddressForm from './IpAddressForm';

const StyledContainer = styled.div`
  width: 90%;
  height: 50%;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 8px;
  margin-bottom: 24px;
`;

type Props = {
  type: string;
  valueState: {
    values: GeoFenceConfig;
    setValues: Dispatch<SetStateAction<GeoFenceConfig>>;
  };
};

export default ({ type, valueState }: Props) => {
  const { values, setValues } = valueState;

  const handleRemove = (
    id: string,
    action: string,
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    ip?: boolean
  ) => {
    e.preventDefault();

    setValues((prev) => ({
      ...prev,
      [`${type}${action}`]: prev[`${type}${action}`].filter((item: any) =>
        ip ? item !== id : item.id !== id
      ),
    }));
  };

  const onAddIp = (value: { [x: string]: string }, field: string) => {
    setValues((prev) => ({
      ...prev,
      [field]: [...prev[field], value[field]],
    }));
  };

  return (
    <div className="d-flex flex-column justify-content-start w-100 h-100">
      <>
        <Typography.Title
          level={4}
          style={{
            margin: 0,
          }}
        >
          {capitalize(type)}
        </Typography.Title>

        {['Target', 'Excluded'].map((item) => (
          <>
            <Typography.Title level={5}>{item}</Typography.Title>
            <StyledContainer>
              <div className="d-flex flex-column justify-content-between h-50 mb-3">
                <div>
                  <small className="d-block">Country and City</small>
                  {values[`${type}${item}`]?.map((value: Place) => (
                    <CountryTag
                      key={value.id}
                      country={value}
                      action={item}
                      handleClose={handleRemove}
                    />
                  ))}
                </div>

                <CountryCitySelect
                  onSelect={{
                    setValues,
                    action: item,
                    type,
                  }}
                />
              </div>

              <div
                className="d-flex flex-column justify-content-between"
                style={{
                  height: '40%',
                }}
              >
                <div>
                  <small className="d-block">IP Address</small>
                  {values[`${type}${item}IPs`]?.map(
                    (value: string, index: React.Key) => (
                      <Tag
                        key={index}
                        closable
                        onClose={(e) =>
                          handleRemove(value, `${item}IPs`, e, true)
                        }
                      >
                        {value}
                      </Tag>
                    )
                  )}
                </div>

                <div className="d-flex justify-content-between">
                  <IpAddressForm
                    onAddIp={onAddIp}
                    fields={{
                      values: valueState.values,
                      type,
                      item,
                    }}
                  />
                </div>
              </div>
            </StyledContainer>
          </>
        ))}
      </>
    </div>
  );
};
