import gql from 'graphql-tag';

export const PROVIDER_INFO = gql`
  query depositProvider($id: ID!) {
    depositProvider(id: $id) {
      id
      name
      __typename
      ... on MidPayDirectDepositProvider {
        id
        apiKey
        midPayDirectPaymentGateway {
          id
        }
      }
      ... on DiorPay2DepositProvider {
        id
        name
      }
      # TEMPORARILY removed since paymentChannels are available on MidPayDirectDepositProvider only
      # ... on MidPayDepositProvider {
      #   apiKey
      #   apiKeyPreview
      # }
      # ... on DiorPayDepositProvider {
      #   key
      #   keyPreview
      #   merchantCode
      # }
    }
  }
`;

// Payment channels are also known as Payment gateways
export const PAYMENT_CHANNELS = gql`
  query PaymentGateways($input: PaymentGatewaysInput!) {
    paymentGateways(input: $input) {
      ... on PaymentGateway {
        id
        name
      }
      ... on MidPayDirectPaymentGateway {
        id
        name
        credentialFields
        paymentChannels
      }
      ... on DiorPay2PaymentGateway {
        id
        name
        gateway
        paymentChannels
      }
      __typename
    }
  }
`;

export default {};
