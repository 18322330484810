import gql from 'graphql-tag';

export const AFFILIATE_CODE = gql`
  query MemberAffiliate($id: ID!) {
    member(id: $id) {
      id
      affiliate {
        code
        id
      }
    }
  }
`;

export const MEMBER_ACCOUNT_BALANCE = gql`
  query MemberBalance($id: ID!) {
    member(id: $id) {
      id
      totalBalance
    }
  }
`;
