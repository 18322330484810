import { Divider, Empty } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { ErrorHandler } from 'components/ErrorHandler';
import { useMemberPromo } from 'hooks/useMemberPromo';
import ActivePromo from 'pages/components/NewMemberDetail/components/PromoAndGames/ActivePromo/ActivePromo';
import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import { Member } from 'types/graphqlTypes';
import isRow from 'utils/isRow';
import EnrollPromo from './EnrollPromo/EnrollPromo';

type Props = {
  member: Member;
};

const PromoBalanceNext: React.FC<Props> = ({ member }) => {
  const { addTab } = useScreenTabV2();
  const { hasActivePromo } = useMemberPromo();

  const handleOpenPromoHistoryTab = () => {
    addTab({
      id: 'member-promo-history',
      state: {
        member: {
          username: member.username,
          id: member.id,
        },
        filter: {
          status: {
            notEqual: 'ACTIVE',
          },
        },
      },
    });
  };

  return (
    <>
      {isRow ? (
        <>
          <div className="d-flex justify-content-between">
            <ActivePromo showActionButtons />
            {!hasActivePromo && <EnrollPromo />}
          </div>
          <Divider className="m-0" />
          <div className="pt-2 pl-1 pr-1 d-flex justify-content-center align-items-center">
            <ALink
              className="fs-12"
              onClick={() => handleOpenPromoHistoryTab()}
            >
              More Details | Member Promo History
            </ALink>
          </div>
        </>
      ) : (
        <ErrorHandler>
          <div className="d-flex align-items-center justify-content-center member-profile-tab-container">
            <Empty />
          </div>
        </ErrorHandler>
      )}
    </>
  );
};

export default PromoBalanceNext;
