/* eslint-disable */
import React, { useState } from 'react';
import { StyledTable } from 'constants/styles';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import commonMessages from 'messages';
import Icon, { FilterOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import useTranslate from 'utils/useTranslate';
import { Account, MemberSummaryReportFilter } from 'types/graphqlTypes';
import { formatDate } from 'utils/dateUtils';
import { ALink } from 'components/ALink/ALink';
import { ReactComponent as DownloadIcon } from 'components/assets/cloud_download.svg';
import coercedGet from 'utils/coercedGet';
import { columnAlign } from 'utils/tableAlignment';
import cx from 'classnames';
import styled from 'styled-components';
import { TableDataSourceType, TableRecordType } from '../../types';
import { SCREEN_TAB_ID } from '../../configs';
import FilterTooltip from './FilterTooltip';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { Key, TablePaginationConfig } from 'antd/lib/table/interface';
import { DATE_TIME_FORMAT } from 'constants/date';

const StyledCell = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: default;
`;

type Props = {
  dataSource: [TableDataSourceType];
  loading: boolean;
  refetchVariables: {
    first: number;
    after: string | undefined;
  };
  sort: (order: 'ascend' | 'descend') => void;
};

const ScreenTable: React.FC<Props> = ({ dataSource, loading, sort }) => {
  const translate = useTranslate();
  const [state, setState] = useState({
    selectedRowKeys: [],
  });

  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['LIST', 'CSV_DOWNLOAD_BUTTON', 'CREATE'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS_MEMBER_SUMMARY_REPORT
  );

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = dataSource
    ? dataSource.map(({ node }) => ({ ...node, key: node.id }))
    : [];

  const columns = [
    {
      csvData: {
        label: translate(commonMessages.START_END_DATE_TIME),
        key: 'startEndDateTime',
        renderCell: (record: TableRecordType) =>
          `${formatDate(
            record.filter?.startDateTime,
            DATE_TIME_FORMAT
          )} - ${formatDate(record.filter?.endDateTime, DATE_TIME_FORMAT)}`,
      },
      key: 'startEndDateTime',
      align: columnAlign.onCenter,
      title: translate(commonMessages.START_END_DATE_TIME),
      dataIndex: 'filter',
      render: (filter: MemberSummaryReportFilter) => (
        <span>
          {formatDate(filter?.startDateTime, DATE_TIME_FORMAT)} - <br />
          {formatDate(filter?.endDateTime, DATE_TIME_FORMAT)}
        </span>
      ),
    },
    {
      csvData: {
        label: translate(commonMessages.GAME_CATEGORY),
        key: 'gameCategory',
        renderCell: (record: TableRecordType) =>
          record.filter?.gameTypes
            ?.map((gameType) => ` ${translate(commonMessages[`${gameType}`])}`)
            .toString() || '-',
      },
      key: 'gameCategory',
      align: columnAlign.onCenter,
      title: translate(commonMessages.GAME_CATEGORY),
      render: (record: TableRecordType) =>
        record.filter?.gameTypes?.length ? (
          <Tooltip
            placement="bottom"
            title={
              <span>
                {record.filter?.gameTypes
                  ?.map(
                    (gameType) => ` ${translate(commonMessages[`${gameType}`])}`
                  )
                  .toString() || '-'}
              </span>
            }
          >
            <StyledCell>
              {record.filter?.gameTypes
                ?.map(
                  (gameType) => ` ${translate(commonMessages[`${gameType}`])}`
                )
                .toString()}
            </StyledCell>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      csvData: {
        label: translate(commonMessages.GAME_VENDOR),
        key: 'gameVendor',
        renderCell: (record: TableRecordType) =>
          record.filter?.vendors
            ?.map((vendor) => ` ${vendor.name}`)
            .toString() || '-',
      },
      key: 'gameVendor',
      align: columnAlign.onCenter,
      title: translate(commonMessages.GAME_VENDOR),
      render: (record: TableRecordType) =>
        record.filter?.vendors?.length ? (
          <Tooltip
            placement="bottom"
            title={
              <span>
                {record.filter?.vendors
                  ?.map((vendor) => ` ${vendor.name}`)
                  .toString() || '-'}
              </span>
            }
          >
            <StyledCell>
              {record.filter?.vendors
                ?.map((vendor) => ` ${vendor.name}`)
                .toString()}
            </StyledCell>
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      csvData: {
        label: translate(commonMessages.TIMEZONE_SELECTION),
        key: 'timezone',
        render: (record: TableRecordType) => record.timezone || '-',
      },
      key: 'timezone',
      align: columnAlign.onCenter,
      title: translate(commonMessages.TIMEZONE_SELECTION),
      dataIndex: 'timezone',
      render: (timezone: TableRecordType['timezone']) => timezone || '-',
    },
    {
      csvData: {
        label: translate(commonMessages.CREATED_BY),
        key: 'creator',
        renderCell: (record: TableRecordType) => record.creator.username || '-',
      },
      key: 'creator',
      align: columnAlign.onCenter,
      title: translate(commonMessages.CREATED_BY),
      dataIndex: 'creator',
      render: (creator: Account) => creator.username || '-',
    },
    {
      csvData: {
        label: 'Date/Time Created',
        key: 'dateTimeCreated',
        renderCell: (record: TableRecordType) =>
          formatDate(record.dateTimeCreated, DATE_TIME_FORMAT) || '-',
      },
      key: 'dateTimeCreated',
      align: columnAlign.onCenter,
      title: 'Date/Time Created',
      dataIndex: 'dateTimeCreated',
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: true,
      render: (dateTimeCreated: TableRecordType['dateTimeCreated']) =>
        formatDate(dateTimeCreated, DATE_TIME_FORMAT) || '-',
    },
    {
      csvData: {
        label: 'Date/Time Generated',
        key: 'dateTimeGenerated',
        renderCell: (record: TableRecordType) =>
          record.status === 'GENERATED'
            ? formatDate(record.report?.dateTimeCreated, DATE_TIME_FORMAT)
            : '-',
      },
      key: 'dateTimeGenerated',
      align: columnAlign.onCenter,
      title: 'Date/Time Generated',
      render: (record: TableRecordType) =>
        record.status === 'GENERATED'
          ? formatDate(record.report?.dateTimeCreated, DATE_TIME_FORMAT)
          : '-',
    },
    {
      csvData: null,
      key: 'filter',
      title: '',
      customTitle: translate(commonMessages.FILTER),
      align: columnAlign.onCenter,
      width: 50,
      render: (record: TableRecordType) => (
        <Tooltip
          placement="left"
          overlayStyle={{
            width: 400,
            maxWidth: 'unset',
            maxHeight: 550,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
          title={
            <FilterTooltip
              filter={{ ...record.filter, timezone: record.timezone }}
            />
          }
        >
          <FilterOutlined className="ml-2" />
        </Tooltip>
      ),
    },
    {
      csvData: null,
      key: 'download',
      title: '',
      customTitle: translate(commonMessages.DOWNLOAD),
      align: columnAlign.onCenter,
      width: 50,
      render: (record: TableRecordType) => {
        const disabled =
          record.status === 'GENERATING' || !record.report || !ALLOWED_CREATE;
        const tooltipTitle =
          record.status === 'GENERATED'
            ? translate(commonMessages['reports.generated.text'])
            : translate(commonMessages['reports.generating.text']);
        return (
          <Tooltip placement="top" title={tooltipTitle}>
            <ALink
              className={`styled-center ${disabled ? 'cursor-disabled' : ''}`}
              href={disabled ? '#' : coercedGet(record, 'report.uri', null)}
            >
              <Icon
                className={`mr-3 ${cx({
                  'action-icon-disabled': disabled,
                  'action-icon': !disabled,
                })}`}
                component={DownloadIcon}
                style={{ fontSize: '22px' }}
              />
            </ALink>
          </Tooltip>
        );
      },
    },
  ];

  const { filterColumns } = useCustomColumnsV2(SCREEN_TAB_ID, columns);

  return (
    <StyledTable
      loading={loading}
      rowSelection={rowSelection}
      columns={filterColumns(columns)}
      size="small"
      dataSource={data}
      pagination={false}
      scroll={{ x: 'calc(525px + 50%)' }}
      rowKey="id"
      onChange={(
        _pagination: TablePaginationConfig,
        _filters: Record<string, (Key | boolean)[] | null>,
        sorter: Record<string, any>
      ) => {
        const { order } = sorter;
        sort(order);
      }}
    />
  );
};

export default ScreenTable;
