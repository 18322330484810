import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UPSERT_PREFERENCE } from 'graphql/mutations/upsertPreference.mutation';
import { PREFERENCE } from 'graphql/queries/preference.query';
import { sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import coercedGet from 'utils/coercedGet';
import { TabId } from '../../types/nav';
import { useAccount } from '../accountState';
import { RootState } from '../types';
import {
  ColumnsContainer,
  CustomColumn,
  CustomColumnHookOptions,
  CustomColumnsState,
  OriginalColumn,
} from './types';

const customColumnsSlice = createSlice({
  name: 'custom-column',
  initialState: [] as CustomColumnsState,
  reducers: {
    manage: (state, { payload }: PayloadAction<ColumnsContainer>) => {
      const columnsContainerIndex = state.findIndex(
        (columnsContainer: ColumnsContainer) =>
          columnsContainer.tabId === payload.tabId
      );

      if (columnsContainerIndex === -1) state.push(payload);
      else state.splice(columnsContainerIndex, 1, payload);
    },
  },
});

const selectCustomColumn = createSelector(
  (customColumns: CustomColumnsState, tabId: string) =>
    customColumns.find(
      (columnsContainer: ColumnsContainer) => columnsContainer.tabId === tabId
    ),
  (column) => column || []
);

export const customColumnsReducer = customColumnsSlice.reducer;

const transformInitialColumn = (column: OriginalColumn, hideKey?: string) => {
  const customTitle =
    typeof column.customTitle === 'object' ? '' : column.customTitle;
  const title = typeof column.title === 'object' ? '' : column.title;

  return {
    key: column.key,
    title: customTitle || title,
    titleTranslationProps:
      typeof column.customTitle === 'object' ||
      (typeof column.title === 'object' &&
        coercedGet(column, 'title.props', {})),
    visible: hideKey !== column.key,
    ...(column?.disabled && { disabled: true }),
    ...(column?.hidden && { hidden: true }),
    ...(hideKey === column.key && { hidden: true }),
  };
};

export const useCustomColumnsV2 = (
  tabId: TabId,
  initialColumn: OriginalColumn[] = [],
  options: CustomColumnHookOptions = {
    selectorLookup: true,
  },
  hideKey?: string
) => {
  const key = `custom-columns-v2:${tabId}`;
  const dispatch = useDispatch();
  const selector = useSelector((state: RootState) => state.customColumns);
  const {
    account: { locale },
  } = useAccount();
  const { actions } = customColumnsSlice;
  const [upsertPreference] = useMutation(UPSERT_PREFERENCE);
  const [loadCustomColumn, { called, error, loading }] = useLazyQuery(
    PREFERENCE,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        key,
      },
      onCompleted: (data: { preference: ColumnsContainer }) => {
        if (!initialColumn.length) return;
        let { preference } = data;
        const initial: CustomColumn[] = initialColumn.map((column) =>
          transformInitialColumn(column, hideKey ?? '')
        );

        const original = { tabId, initial, custom: initial };

        if (!preference) {
          preference = original;
        } else {
          const server = JSON.stringify(
            Object.values(preference.initial || {})
          );
          const local = JSON.stringify(Object.values(initial || {}));

          if (server !== local) {
            preference = original;
            if (selector.length > 0 && options.selectorLookup) {
              const foundCustomColumn = selector.find(
                (select) => select.tabId === preference.tabId
              );
              if (!foundCustomColumn) {
                upsertPreference({
                  variables: {
                    key,
                    value: preference,
                  },
                });
                dispatch(actions.manage(preference));
                return;
              }
              const orderOfColumns = sortBy(preference.custom, (o) =>
                foundCustomColumn!.custom.findIndex((a) => a.key === o.key)
              );
              preference = {
                ...preference,
                initial: foundCustomColumn!.custom,
                custom: orderOfColumns.map((prefCust) => ({
                  ...prefCust,
                  visible: foundCustomColumn!.custom!.find(
                    (foundCust) => foundCust.key === prefCust.key
                  )!.visible,
                })),
              };
            }
            upsertPreference({
              variables: {
                key,
                value: preference,
              },
            });
          }
        }

        dispatch(actions.manage(preference));
      },
    }
  );

  const columns = useSelector(
    ({ customColumns: columnsState }: { customColumns: CustomColumnsState }) =>
      selectCustomColumn(columnsState, tabId)
  ) as ColumnsContainer;

  const setColumns = useCallback(
    (customColumns: CustomColumn[]) => {
      const newCustomColumn: ColumnsContainer = {
        ...columns,
        custom: customColumns,
      };

      dispatch(actions.manage(newCustomColumn));
      upsertPreference({
        variables: {
          key,
          value: newCustomColumn,
        },
      });
    },
    [columns, dispatch, actions, upsertPreference, key]
  );

  const resetColumns = useCallback(() => {
    setColumns(columns.initial);
  }, [setColumns, columns.initial]);

  const filterColumns = useCallback(
    (originalColumns: OriginalColumn[]) => {
      if (error || loading) {
        return originalColumns;
      }

      return columns?.custom
        ?.filter((column: CustomColumn) => column.visible)
        .map((customColumn: CustomColumn) =>
          originalColumns.find(
            (originalColumn: OriginalColumn) =>
              originalColumn.key === customColumn.key
          )
        ) as OriginalColumn[];
    },
    [columns, error, loading]
  );

  if (!called) loadCustomColumn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useMemo(() => loadCustomColumn(), [hideKey]);
  useUpdateEffect(() => {
    if (initialColumn.length === 0) {
      return;
    }
    const initial: CustomColumn[] = initialColumn.map((column) =>
      transformInitialColumn(column, hideKey ?? '')
    );
    const initialColumnDict = initial.reduce((acc, cur) => {
      acc[cur.key] = cur.title;
      return acc;
    }, {});
    const foundCustomColumn = selector.find((select) => select.tabId === tabId);
    if (!foundCustomColumn) {
      return;
    }
    const translatedColumns = {
      ...foundCustomColumn,
      initial,
      custom: foundCustomColumn.custom.map((custCol) => ({
        ...custCol,
        title: initialColumnDict[custCol.key],
      })),
    };

    upsertPreference({
      variables: {
        key,
        value: translatedColumns,
      },
    });
    dispatch(actions.manage(translatedColumns));
  }, [locale]);

  return useMemo(
    () => ({
      loading,
      initialColumns: columns.initial,
      customColumns: columns.custom,
      setColumns,
      resetColumns,
      filterColumns,
      preferenceQueryError: error,
    }),
    [columns, setColumns, resetColumns, filterColumns, loading, error]
  );
};
