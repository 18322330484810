import gql from 'graphql-tag';

export const VENDORS = gql`
  query Vendors($filter: VendorsFilterInput, $after: Binary, $first: Int) {
    vendors(filter: $filter, after: $after, first: $first) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          value: id
          label: name
          #   enabled
          #   gameTypes
          #   visible
        }
      }
    }
  }
`;
