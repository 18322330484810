import { defineMessages } from 'react-intl';

const messages = defineMessages({
  CARD_GAME: {
    id: 'CARD_GAME',
    defaultMessage: 'Card Game',
  },
  GAME_VENDOR: {
    id: 'game-vendor.text',
    defaultMessage: 'Game vendor',
  },
  GAME_TYPE: {
    id: 'GAME_TYPE',
    defaultMessage: 'Game Type',
  },
  _: {
    id: '_',
    defaultMessage: '-',
  },
  AG_TRANSFER______: {
    id: 'AG_TRANSFER______',
    defaultMessage: 'AG_TRANSFER',
  },
  AG___CASUAL: {
    id: 'AG___CASUAL',
    defaultMessage: 'AG - Casual',
  },
  AG___MULTI_PLAYER: {
    id: 'AG___MULTI_PLAYER',
    defaultMessage: 'AG - Multi Player',
  },
  AG___SLOT: {
    id: 'AG___SLOT',
    defaultMessage: 'AG - Slot',
  },
  AG___SLOT_GAME: {
    id: 'AG___SLOT_GAME',
    defaultMessage: 'AG - Slot Game',
  },
  AG___SINGLE_PLAYER: {
    id: 'AG___SINGLE_PLAYER',
    defaultMessage: 'AG - Single Player',
  },
  AG___TABLE_GAME: {
    id: 'AG___TABLE_GAME',
    defaultMessage: 'AG - Table Game',
  },
  AG___VIDEO_POKER: {
    id: 'AG___VIDEO_POKER',
    defaultMessage: 'AG - Video Poker',
  },
  AG_TRANSFER___SLOT: {
    id: 'AG_TRANSFER___SLOT',
    defaultMessage: 'AG_TRANSFER - Slot',
  },
  AG_TRANSFER___SLOT_GAME: {
    id: 'AG_TRANSFER___SLOT_GAME',
    defaultMessage: 'AG_TRANSFER - Slot Game',
  },
  AG_TRANSFER___SLOT_GAMES: {
    id: 'AG_TRANSFER___SLOT_GAMES',
    defaultMessage: 'AG_TRANSFER - Slot Games',
  },
  AG_TRANSFER___POKER: {
    id: 'AG_TRANSFER___POKER',
    defaultMessage: 'AG_TRANSFER - Poker',
  },
  AG_TRANSFER___POKER_GAME: {
    id: 'AG_TRANSFER___POKER_GAME',
    defaultMessage: 'AG_TRANSFER - Poker Game',
  },
  AG_TRANSFER___POKER_GAMES: {
    id: 'AG_TRANSFER___POKER_GAMES',
    defaultMessage: 'AG_TRANSFER - Poker Games',
  },
  AG_TRANSFER___LIVE: {
    id: 'AG_TRANSFER___LIVE',
    defaultMessage: 'AG_TRANSFER - Live',
  },
  AG_TRANSFER___LIVE_GAME: {
    id: 'AG_TRANSFER___LIVE_GAME',
    defaultMessage: 'AG_TRANSFER - Live Game',
  },
  AG_TRANSFER___LIVE_GAMES: {
    id: 'AG_TRANSFER___LIVE_GAMES',
    defaultMessage: 'AG_TRANSFER - Live Games',
  },
  AG_TRANSFER___FISH: {
    id: 'AG_TRANSFER___FISH',
    defaultMessage: 'AG_TRANSFER - Fish',
  },
  AG_TRANSFER___FISH_GAME: {
    id: 'AG_TRANSFER___FISH_GAME',
    defaultMessage: 'AG_TRANSFER - Fish Game',
  },
  AG_TRANSFER___FISH_GAMES: {
    id: 'AG_TRANSFER___FISH_GAMES',
    defaultMessage: 'AG_TRANSFER - Fish Games',
  },
  BBIN___BB_CASINO: {
    id: 'BBIN___BB_CASINO',
    defaultMessage: 'BBIN - BB Casino',
  },
  BBIN___BB_FISH_HUNTER: {
    id: 'BBIN___BB_FISH_HUNTER',
    defaultMessage: 'BBIN - BB Fish Hunter',
  },
  BBIN___BB_FISHING_MASTER: {
    id: 'BBIN___BB_FISHING_MASTER',
    defaultMessage: 'BBIN - BB Fishing Master',
  },
  BBIN___BB_LIVE_DEALER: {
    id: 'BBIN___BB_LIVE_DEALER',
    defaultMessage: 'BBIN - BB Live Dealer',
  },
  BBIN___BB_LOTTERY: {
    id: 'BBIN___BB_LOTTERY',
    defaultMessage: 'BBIN - BB Lottery',
  },
  BBIN___BB_SPORTS: {
    id: 'BBIN___BB_SPORTS',
    defaultMessage: 'BBIN - BB Sports',
  },
  BBIN___BB_TH_BATTLE: {
    id: 'BBIN___BB_TH_BATTLE',
    defaultMessage: 'BBIN - BB TH Battle',
  },
  BBIN___NEW_BB_SPORTS: {
    id: 'BBIN___NEW_BB_SPORTS',
    defaultMessage: 'BBIN - New BB Sports',
  },
  BBIN___XBB_LIVE: {
    id: 'BBIN___XBB_LIVE',
    defaultMessage: 'BBIN - XBB Live',
  },
  BB_CASINO: {
    id: 'BB_CASINO',
    defaultMessage: 'BB Casino',
  },
  BB_FISH_HUNTER: {
    id: 'BB_FISH_HUNTER',
    defaultMessage: 'BB Fish Hunter',
  },
  BB_FISHING_MASTER: {
    id: 'BB_FISHING_MASTER',
    defaultMessage: 'BB Fishing Master',
  },
  BB_LIVE_DEALER: {
    id: 'BB_LIVE_DEALER',
    defaultMessage: 'BB Live Dealer',
  },
  BB_LOTTERY: {
    id: 'BB_LOTTERY',
    defaultMessage: 'BB Lottery',
  },
  BB_SPORTS: {
    id: 'BB_SPORTS',
    defaultMessage: 'BB Sports',
  },
  BB_TH_BATTLE: {
    id: 'BB_TH_BATTLE',
    defaultMessage: 'BB TH Battle',
  },
  NEW_BB_SPORTS: {
    id: 'NEW_BB_SPORTS',
    defaultMessage: 'New BB Sports',
  },
  XBB_LIVE: {
    id: 'XBB_LIVE',
    defaultMessage: 'XBB Live',
  },
  BG______: {
    id: 'BG______',
    defaultMessage: 'BG',
  },
  BG_TRANSFER: {
    id: 'BG_TRANSFER',
    defaultMessage: 'BG_TRANSFER',
  },
  BG_TRANSFER___FISHING: {
    id: 'BG_TRANSFER___FISHING',
    defaultMessage: 'BG_TRANSFER - Fishing',
  },
  BG_TRANSFER___LIVE_GAME: {
    id: 'BG_TRANSFER___LIVE_GAME',
    defaultMessage: 'BG_TRANSFER - Live Game',
  },
  BG_TRANSFER___VIDEO_GAME: {
    id: 'BG_TRANSFER___VIDEO_GAME',
    defaultMessage: 'BG_TRANSFER - Video Game',
  },
  BG___FISHING: {
    id: 'BG___FISHING',
    defaultMessage: 'BG - Fishing',
  },
  BG___LIVE_GAME: {
    id: 'BG___LIVE_GAME',
    defaultMessage: 'BG - Live Game',
  },
  BG___VIDEO_GAME: {
    id: 'BG___VIDEO_GAME',
    defaultMessage: 'BG - Video Game',
  },
  BOOONGO: {
    id: 'BOOONGO',
    defaultMessage: 'BOOONGO',
  },
  BOOONGO______: {
    id: 'BOOONGO______',
    defaultMessage: 'BOOONGO',
  },
  BOOONGO_TRANSFER: {
    id: 'BOOONGO_TRANSFER',
    defaultMessage: 'BOOONGO_TRANSFER',
  },
  BOOONGO_TRANSFER______: {
    id: 'BOOONGO_TRANSFER______',
    defaultMessage: 'BOOONGO_TRANSFER',
  },
  BOOONGO_TRANSFER___合作伙伴游戏: {
    id: 'BOOONGO_TRANSFER___合作伙伴游戏',
    defaultMessage: 'BOOONGO_TRANSFER - 合作伙伴游戏',
  },
  BOOONGO_TRANSFER___老虎机: {
    id: 'BOOONGO_TRANSFER___老虎机',
    defaultMessage: 'BOOONGO_TRANSFER - 老虎机',
  },
  BOOONGO_TRANSFER___BOOOGO: {
    id: 'BOOONGO_TRANSFER___BOOOGO',
    defaultMessage: 'BOOONGO_TRANSFER - Booogo',
  },
  BOOONGO_TRANSFER___PLAYSON: {
    id: 'BOOONGO_TRANSFER___PLAYSON',
    defaultMessage: 'BOOONGO_TRANSFER - Playson',
  },
  BOOONGO_TRANSFER___POKER_GAME: {
    id: 'BOOONGO_TRANSFER___POKER_GAME',
    defaultMessage: 'BOOONGO_TRANSFER - Poker Games',
  },
  BOOONGO_TRANSFER___SLOT: {
    id: 'BOOONGO_TRANSFER___SLOT',
    defaultMessage: 'BOOONGO_TRANSFER - Slot',
  },
  BOOONGO_TRANSFER___SLOT_GAME: {
    id: 'BOOONGO_TRANSFER___SLOT_GAME',
    defaultMessage: 'BOOONGO_TRANSFER - Slot Games',
  },
  BOOONGO_TRANSFER___TABLE_GAME: {
    id: 'BOOONGO_TRANSFER___TABLE_GAME',
    defaultMessage: 'BOOONGO_TRANSFER - Table Game',
  },
  CQ9___: {
    id: 'CQ9___',
    defaultMessage: 'CQ9',
  },
  CQ9: {
    id: 'CQ9',
    defaultMessage: 'CQ9',
  },
  CQ9___ARCADE: {
    id: 'CQ9___ARCADE',
    defaultMessage: 'CQ9 - Arcade',
  },
  CQ9___FISH: {
    id: 'CQ9___FISH',
    defaultMessage: 'CQ9 - Fish',
  },
  CQ9___SLOT: {
    id: 'CQ9___SLOT',
    defaultMessage: 'CQ9 - Slot',
  },
  CQ9___TABLE: {
    id: 'CQ9___TABLE',
    defaultMessage: 'CQ9 - Table',
  },
  CQ9_TRANSFER______: {
    id: 'CQ9_TRANSFER______',
    defaultMessage: 'CQ9_TRANSFER',
  },
  CQ9_TRANSFER___ARCADE: {
    id: 'CQ9_TRANSFER___ARCADE',
    defaultMessage: 'CQ9_TRANSFER - Arcade',
  },
  CQ9_TRANSFER___FISH: {
    id: 'CQ9_TRANSFER___FISH',
    defaultMessage: 'CQ9_TRANSFER - Fish',
  },
  CQ9_TRANSFER___SLOT: {
    id: 'CQ9_TRANSFER___SLOT',
    defaultMessage: 'CQ9_TRANSFER - Slot',
  },
  CQ9_TRANSFER___TABLE: {
    id: 'CQ9_TRANSFER___TABLE',
    defaultMessage: 'CQ9_TRANSFER - Table',
  },
  EBET_TRANSFER___BACCARAT: {
    id: 'EBET_TRANSFER___BACCARAT',
    defaultMessage: 'EBET_TRANSFER - Baccarat',
  },
  EBET_TRANSFER___DRAGONTIGER: {
    id: 'EBET_TRANSFER___DRAGONTIGER',
    defaultMessage: 'EBET_TRANSFER - Dragontiger',
  },
  EBET_TRANSFER___SICBO: {
    id: 'EBET_TRANSFER___SICBO',
    defaultMessage: 'EBET_TRANSFER - Sicbo',
  },
  EBET_TRANSFER___SLOT_GAME: {
    id: 'EBET_TRANSFER___SLOT_GAME',
    defaultMessage: 'EBET_TRANSFER - Slot Game',
  },
  EBET___BACCARAT: {
    id: 'EBET___BACCARAT',
    defaultMessage: 'EBET - Baccarat',
  },
  EBET___DRAGONTIGER: {
    id: 'EBET___DRAGONTIGER',
    defaultMessage: 'EBET - Dragontiger',
  },
  EBET___SICBO: {
    id: 'EBET___SICBO',
    defaultMessage: 'EBET - Sicbo',
  },
  EBET___SLOT_GAME: {
    id: 'EBET___SLOT_GAME',
    defaultMessage: 'EBET - Slot Game',
  },
  FG_TRANSFER_______: {
    id: 'FG_TRANSFER_______',
    defaultMessage: 'FG_TRANSFER',
  },
  FG_TRANSFER___HUNTER: {
    id: 'FG_TRANSFER___HUNTER',
    defaultMessage: 'FG_TRANSFER - Hunter',
  },
  FG_TRANSFER___ARCADE: {
    id: 'FG_TRANSFER___ARCADE',
    defaultMessage: 'FG_TRANSFER - Arcade',
  },
  FG_TRANSFER___CHESS: {
    id: 'FG_TRANSFER___CHESS',
    defaultMessage: 'FG_TRANSFER - Chess',
  },
  FG_TRANSFER______: {
    id: 'FG_TRANSFER______',
    defaultMessage: 'FG_TRANSFER',
  },
  FG_TRANSFER___SLOT: {
    id: 'FG_TRANSFER___SLOT',
    defaultMessage: 'FG TRANSFER - Slot',
  },
  HABANERO_TRANSFER___POKER_GAME: {
    id: 'HABANERO_TRANSFER___POKER_GAME',
    defaultMessage: 'HABANERO_TRANSFER - Poker Game',
  },
  HABANERO_TRANSFER___SLOT_GAME: {
    id: 'HABANERO_TRANSFER___SLOT_GAME',
    defaultMessage: 'HABANERO_TRANSFER - Slot Game',
  },
  HABANERO_TRANSFER___TABLE_GAME: {
    id: 'HABANERO_TRANSFER___TABLE_GAME',
    defaultMessage: 'HABANERO_TRANSFER - Table Game',
  },
  HABANERO_TRANSFER___VIDEO_POKER: {
    id: 'HABANERO_TRANSFER___VIDEO_POKER',
    defaultMessage: 'HABANERO_TRANSFER - Video Poker',
  },
  JDB_TRANSFER___ARCADE_GAME: {
    id: 'JDB_TRANSFER___ARCADE_GAME',
    defaultMessage: 'JDB_TRANSFER - Arcade Game',
  },
  JDB_TRANSFER___CARD_GAME: {
    id: 'JDB_TRANSFER___CARD_GAME',
    defaultMessage: 'JDB_TRANSFER - Card Game',
  },
  JDB_TRANSFER___FISH_GAME: {
    id: 'JDB_TRANSFER___FISH_GAME',
    defaultMessage: 'JDB_TRANSFER - Fish Game',
  },
  JDB_TRANSFER___LOTTERY: {
    id: 'JDB_TRANSFER___LOTTERY',
    defaultMessage: 'JDB_TRANSFER - Lottery',
  },
  JDB_TRANSFER___SLOT_GAMES: {
    id: 'JDB_TRANSFER___SLOT_GAMES',
    defaultMessage: 'JDB_TRANSFER - Slot Games ',
  },
  JDB___ARCADE_GAME: {
    id: 'JDB___ARCADE_GAME',
    defaultMessage: 'JDB - Arcade Game',
  },
  JDB___CARD_GAME: {
    id: 'JDB___CARD_GAME',
    defaultMessage: 'JDB - Card Game',
  },
  JDB___FISH_GAME: {
    id: 'JDB___FISH_GAME',
    defaultMessage: 'JDB - Fish Game',
  },
  JDB___LOTTERY: {
    id: 'JDB___LOTTERY',
    defaultMessage: 'JDB - Lottery',
  },
  JDB___SLOT_GAMES: {
    id: 'JDB___SLOT_GAMES',
    defaultMessage: 'JDB - Slot Games ',
  },
  KAGAMING: {
    id: 'KAGAMING',
    defaultMessage: 'KA Gaming',
  },
  KAGAMING______: {
    id: 'KAGAMING______',
    defaultMessage: 'KA Gaming',
  },
  KAGAMING___FISH_SHOOTING: {
    id: 'KAGAMING___FISH_SHOOTING',
    defaultMessage: 'KAGAMING - Fish/Shooting',
  },
  KAGAMING___OTHER: {
    id: 'KAGAMING___OTHER',
    defaultMessage: 'KAGAMING - Other',
  },
  KAGAMING___POKER: {
    id: 'KAGAMING___POKER',
    defaultMessage: 'KAGAMING - Poker',
  },
  KAGAMING___TABLE_GAME: {
    id: 'KAGAMING___TABLE_GAME',
    defaultMessage: 'KAGAMING - Table Game',
  },
  KAGAMING___VIDEO_SLOT: {
    id: 'KAGAMING___VIDEO_SLOT',
    defaultMessage: 'KAGAMING - Video Slot',
  },
  KAGAMING_TRANSFER: {
    id: 'KAGAMING_TRANSFER',
    defaultMessage: 'KA Gaming Transfer',
  },
  KAGAMING_TRANSFER___FISH_GAME: {
    id: 'KAGAMING_TRANSFER___FISH_GAME',
    defaultMessage: 'KAGAMING_TRANSFER - Fish Games',
  },
  KAGAMING_TRANSFER___OTHER: {
    id: 'KAGAMING_TRANSFER___OTHER',
    defaultMessage: 'KAGAMING_TRANSFER - Other',
  },
  KAGAMING_TRANSFER___POKER: {
    id: 'KAGAMING_TRANSFER___POKER',
    defaultMessage: 'KAGAMING_TRANSFER - Poker',
  },
  KAGAMING_TRANSFER___POKER_GAME: {
    id: 'KAGAMING_TRANSFER___POKER_GAME',
    defaultMessage: 'KAGAMING_TRANSFER - Poker Games',
  },
  KAGAMING_TRANSFER___SLOT: {
    id: 'KAGAMING_TRANSFER___SLOT',
    defaultMessage: 'KAGAMING_TRANSFER - Slot',
  },
  KAGAMING_TRANSFER___SLOT_GAME: {
    id: 'KAGAMING_TRANSFER___SLOT_GAME',
    defaultMessage: 'KAGAMING_TRANSFER - Slot Games',
  },
  KAGAMING_TRANSFER___TABLE_GAME: {
    id: 'KAGAMING_TRANSFER___TABLE_GAME',
    defaultMessage: 'KAGAMING_TRANSFER - Table Game',
  },
  KAGAMING_TRANSFER___VIDEO_SLOTS: {
    id: 'KAGAMING_TRANSFER___VIDEO_SLOTS',
    defaultMessage: 'KAGAMING_TRANSFER - Video Slots',
  },
  LEG: {
    id: 'LEG',
    defaultMessage: 'LEG',
  },
  LEG___: {
    id: 'LEG___',
    defaultMessage: 'LEG',
  },
  LEG___CHESS_AND_CARDS: {
    id: 'LEG___CHESS_AND_CARDS',
    defaultMessage: 'LEG - Chess and Cards',
  },
  LEG___ELECTRONICS: {
    id: 'LEG___ELECTRONICS',
    defaultMessage: 'LEG - Electronics',
  },
  LEG___FISHING_TYPE: {
    id: 'LEG___FISHING_TYPE',
    defaultMessage: 'LEG - Fishing Type',
  },
  LEGPOKER______: {
    id: 'LEGPOKER______',
    defaultMessage: 'LEGPOKER - 棋牌类',
  },
  LEGPOKER_TRANSFER: {
    id: 'LEGPOKER_TRANSFER',
    defaultMessage: 'LEG Poker Transfer',
  },
  LEGPOKER_TRANSFER___SLOT_GAME: {
    id: 'LEGPOKER_TRANSFER___SLOT_GAME',
    defaultMessage: 'LEGPOKER_TRANSFER - Slot',
  },
  MEGAWIN___CARD_GAME: {
    id: 'MEGAWIN___CARD_GAME',
    defaultMessage: 'MEGAWIN - Card Game',
  },
  MEGAWIN___ELECTRONIC_GAMES: {
    id: 'MEGAWIN___ELECTRONIC_GAMES',
    defaultMessage: 'MEGAWIN - Electronic Games',
  },
  MEGAWIN___FISHING_GAME: {
    id: 'MEGAWIN___FISHING_GAME',
    defaultMessage: 'MEGAWIN - Fishing Game',
  },
  MGPLUS___BIRDS_HUNTING: {
    id: 'MGPLUS___BIRDS_HUNTING',
    defaultMessage: 'MGPLUS - Birds Hunting',
  },
  MGPLUS___CASUAL: {
    id: 'MGPLUS___CASUAL',
    defaultMessage: 'MGPLUS - Casual',
  },
  MGPLUS___FISH_HUNTING: {
    id: 'MGPLUS___FISH_HUNTING',
    defaultMessage: 'MGPLUS - Fish Hunting',
  },
  MGPLUS___INSTANT_SPORTS: {
    id: 'MGPLUS___INSTANT_SPORTS',
    defaultMessage: 'MGPLUS - Instant Sports',
  },
  MGPLUS___INTERACTIVE_GAMES: {
    id: 'MGPLUS___INTERACTIVE_GAMES',
    defaultMessage: 'MGPLUS - Interactive Games',
  },
  MGPLUS___SCHEDULE_SPORTS: {
    id: 'MGPLUS___SCHEDULE_SPORTS',
    defaultMessage: 'MGPLUS - Schedule Sports',
  },
  MGPLUS___SLOTS: {
    id: 'MGPLUS___SLOTS',
    defaultMessage: 'MGPLUS - Slots',
  },
  MGPLUS___SLOTS_PROGRESSIVE: {
    id: 'MGPLUS___SLOTS_PROGRESSIVE',
    defaultMessage: 'MGPLUS - Slots Progressive',
  },
  MGPLUS___STRATEGY: {
    id: 'MGPLUS___STRATEGY',
    defaultMessage: 'MGPLUS - Strategy',
  },
  MGPLUS___TABLE_GAMES: {
    id: 'MGPLUS___TABLE_GAMES',
    defaultMessage: 'MGPLUS - Table Games',
  },
  MGPLUS___VIDEO_POKER: {
    id: 'MGPLUS___VIDEO_POKER',
    defaultMessage: 'MGPLUS - Video Poker',
  },
  MTGAMING_____: {
    id: 'MTGAMING_____',
    defaultMessage: 'MTGaming',
  },
  MTGAMING______: {
    id: 'MTGAMING______',
    defaultMessage: 'MTGaming',
  },
  MTGAMING___捕鱼: {
    id: 'MTGAMING___捕鱼',
    defaultMessage: 'MTGaming - 捕鱼',
  },
  MTGAMING___棋牌类: {
    id: 'MTGAMING___棋牌类',
    defaultMessage: 'MTGaming - 棋牌类',
  },
  MTGAMING___百人场: {
    id: 'MTGAMING___百人场',
    defaultMessage: 'MTGaming - 百人场',
  },
  MTGAMING___街机: {
    id: 'MTGAMING___街机',
    defaultMessage: 'MTGaming - 街机',
  },
  NETENT_TRANSFER___3_REEL_SLOT: {
    id: 'NETENT_TRANSFER___3_REEL_SLOT',
    defaultMessage: 'NETENT_TRANSFER - 3 Reel Slot',
  },
  NETENT_TRANSFER___5_REEL_SLOT: {
    id: 'NETENT_TRANSFER___5_REEL_SLOT',
    defaultMessage: 'NETENT_TRANSFER - 5 Reel Slot',
  },
  NETENT_TRANSFER___6_REEL_SLOT: {
    id: 'NETENT_TRANSFER___6_REEL_SLOT',
    defaultMessage: 'NETENT_TRANSFER - 6 Reel Slot',
  },
  NETENT_TRANSFER___AVALANCHE: {
    id: 'NETENT_TRANSFER___AVALANCHE',
    defaultMessage: 'NETENT_TRANSFER - Avalanche',
  },
  NETENT_TRANSFER___GRID_SLOT: {
    id: 'NETENT_TRANSFER___GRID_SLOT',
    defaultMessage: 'NETENT_TRANSFER - Grid Slot',
  },
  NETENT_TRANSFER___TABLE_GAME: {
    id: 'NETENT_TRANSFER___TABLE_GAME',
    defaultMessage: 'NETENT_TRANSFER - Table Game',
  },
  PGSOFT_TRANSFER: {
    id: 'PGSOFT_TRANSFER',
    defaultMessage: 'PG Soft Transfer',
  },
  PGSOFT_TRANSFER___CARD_GAME: {
    id: 'PGSOFT_TRANSFER___CARD_GAME',
    defaultMessage: 'PGSOFT_TRANSFER - Card Game',
  },
  PGSOFT_TRANSFER___SLOT_GAME: {
    id: 'PGSOFT_TRANSFER___SLOT_GAME',
    defaultMessage: 'PG SOFT TRANSFER - Slot',
  },
  PGSOFT: {
    id: 'PGSOFT',
    defaultMessage: 'PG Soft',
  },
  PGSOFT___CARD_GAME: {
    id: 'PGSOFT___CARD_GAME',
    defaultMessage: 'PGSOFT - Card Game',
  },
  PGSOFT___SLOT_GAME: {
    id: 'PGSOFT___SLOT_GAME',
    defaultMessage: 'PGSOFT - Slot',
  },
  PP___: {
    id: 'PP___',
    defaultMessage: 'Pragmatic Play',
  },
  PP: {
    id: 'PP',
    defaultMessage: 'Pragmatic Play',
  },
  PP___CLASSIC_GAMES: {
    id: 'PP___CLASSIC_GAMES',
    defaultMessage: 'PP - Classic Games',
  },
  PP_TRANSFER: {
    id: 'PP_TRANSFER',
    defaultMessage: 'PP Transfer',
  },
  PP_TRANSFER___: {
    id: 'PP_TRANSFER___',
    defaultMessage: 'PP Transfer',
  },
  PP_TRANSFER___CLASSIC_GAMES: {
    id: 'PP_TRANSFER___CLASSIC_GAMES',
    defaultMessage: 'PP_TRANSFER - Classic Games',
  },
  PP___OTHERS_GAMES: {
    id: 'PP___OTHERS_GAMES',
    defaultMessage: 'PP - Others Games',
  },
  PP_TRANSFER___OTHERS_GAMES: {
    id: 'PP_TRANSFER___OTHERS_GAMES',
    defaultMessage: 'PP_TRANSFER - Others Games',
  },
  PP_TRANSFER___OTHERS_GAME: {
    id: 'PP_TRANSFER___OTHERS_GAME',
    defaultMessage: 'PP_TRANSFER - Others Game',
  },
  PP___TABLE_GAMES: {
    id: 'PP___TABLE_GAMES',
    defaultMessage: 'PP - Table Games',
  },
  PP_TRANSFER___TABLE_GAMES: {
    id: 'PP_TRANSFER___TABLE_GAMES',
    defaultMessage: 'PP_TRANSFER - Table Games',
  },
  PP___VIDEO_SLOTS: {
    id: 'PP___VIDEO_SLOTS',
    defaultMessage: 'PP - Video Slots',
  },
  PP_TRANSFER___VIDEO_SLOTS: {
    id: 'PP_TRANSFER___VIDEO_SLOTS',
    defaultMessage: 'PP_TRANSFER - Video Slots',
  },
  PT___ARCADE: {
    id: 'PT___ARCADE',
    defaultMessage: 'PT - Arcade',
  },
  PT___ARCADE_GAME: {
    id: 'PT___ARCADE_GAME',
    defaultMessage: 'PT - Arcade Game',
  },
  PT___FIX_ODDS: {
    id: 'PT___FIX_ODDS',
    defaultMessage: 'PT - Fix Odds',
  },
  PT___LIVE_GAMES: {
    id: 'PT___LIVE_GAMES',
    defaultMessage: 'PT - Live Games',
  },
  PT___SCRATCH_CARDS: {
    id: 'PT___SCRATCH_CARDS',
    defaultMessage: 'PT - Scratch Cards',
  },
  PT___SLOT_MACHINES: {
    id: 'PT___SLOT_MACHINES',
    defaultMessage: 'PT - Slot Machines',
  },
  PT___VIDEO_SLOTS: {
    id: 'PT___VIDEO_SLOTS',
    defaultMessage: 'PT - Video Slots',
  },
  SGWIN___0: {
    id: 'SGWIN___0',
    defaultMessage: 'SGWIN - None',
  },
  SGWIN______: {
    id: 'SGWIN______',
    defaultMessage: 'SGWIN',
  },
  SGWIN_TRANSFER: {
    id: 'SGWIN_TRANSFER',
    defaultMessage: 'SGWIN_TRANSFER',
  },
  SGWIN___NONE: {
    id: 'SGWIN___NONE',
    defaultMessage: 'SGWIN - None',
  },
  SGWIN_TRANSFER___NONE: {
    id: 'SGWIN_TRANSFER___NONE',
    defaultMessage: 'SGWIN_TRANSFER - None',
  },
  SPADEGAMING: {
    id: 'SPADEGAMING',
    defaultMessage: 'Spadegaming',
  },
  SPADEGAMING___: {
    id: 'SPADEGAMING___',
    defaultMessage: 'Spadegaming',
  },
  SPADEGAMING___ARCADE: {
    id: 'SPADEGAMING___ARCADE',
    defaultMessage: 'SPADEGAMING - Arcade',
  },
  SPADEGAMING_TRANSFER___ARCADE: {
    id: 'SPADEGAMING_TRANSFER___ARCADE',
    defaultMessage: 'SPADEGAMING_TRANSFER - Arcade',
  },
  SPADEGAMING___BOARD_GAME: {
    id: 'SPADEGAMING___BOARD_GAME',
    defaultMessage: 'SPADEGAMING - Board Game',
  },
  SPADEGAMING_TRANSFER___BOARD_GAME: {
    id: 'SPADEGAMING_TRANSFER___BOARD_GAME',
    defaultMessage: 'SPADEGAMING_TRANSFER - Board Game',
  },
  SPADEGAMING___FISHING: {
    id: 'SPADEGAMING___FISHING',
    defaultMessage: 'SPADEGAMING - Fishing',
  },
  SPADEGAMING_TRANSFER___FISHING: {
    id: 'SPADEGAMING_TRANSFER___FISHING',
    defaultMessage: 'SPADEGAMING_TRANSFER - Fishing',
  },
  SPADEGAMING___SLOT: {
    id: 'SPADEGAMING___SLOT',
    defaultMessage: 'SPADEGAMING - Slot',
  },
  SPADEGAMING_TRANSFER___SLOT: {
    id: 'SPADEGAMING_TRANSFER___SLOT',
    defaultMessage: 'SPADEGAMING_TRANSFER - Slot',
  },
  SPADEGAMING___POKER: {
    id: 'SPADEGAMING___POKER',
    defaultMessage: 'SPADEGAMING - Poker',
  },
  SPADEGAMING_TRANSFER___POKER: {
    id: 'SPADEGAMING_TRANSFER___POKER',
    defaultMessage: 'SPADEGAMING_TRANSFER - Poker',
  },
  TABLE: {
    id: 'TABLE',
    defaultMessage: 'Table',
  },
  WORLDMATCH______: {
    id: 'WORLDMATCH______',
    defaultMessage: 'World Match',
  },
  WORLDMATCH___SLOT_MACHINE: {
    id: 'WORLDMATCH___SLOT_MACHINE',
    defaultMessage: 'World Match - Slot Machine',
  },
  WORLDMATCH___VIDEO_POKER: {
    id: 'WORLDMATCH___VIDEO_POKER',
    defaultMessage: 'World Match - Video Poker',
  },
  WORLDMATCH___ROULETTE: {
    id: 'WORLDMATCH___ROULETTE',
    defaultMessage: 'World Match - Roulette',
  },
});

export default messages;
