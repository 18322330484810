import styled from 'styled-components';

export const StyledCard = styled.div`
  margin-top: 8px;

  .card-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background: #f0f0f0;
    height: 36px;
    padding-left: 16px;
  }

  .card-body {
    background: #fff;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .ant-table {
    border: 0;
  }

  .ant-table-content {
    padding: 0 8px;
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .ant-table-header-column {
    font-weight: normal !important;
    color: rgba(0, 0, 0, 0.45) !important;
  }

  .ant-table-body {
    margin: 0 !important;
  }
`;

export default {};
