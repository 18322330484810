/* eslint-disable global-require */
import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)<{ readOnly: any }>`
  .ant-modal-content {
    background-color: ${({ readOnly }) => (readOnly ? '#f2f2f2' : '#fff')};
  }
  .ant-modal-body {
    padding: 0;
  }
  .menu-header {
    background: #e7e9ed;
  }
  .remarks-area {
    background-color: ${({ readOnly }) => (readOnly ? '#e7e9ed' : '#fff')};
  }
`;

export default {};
