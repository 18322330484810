import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import FilterItems from '../FilterItems';

type Props = {
  filters: any;
  onFilterChange: (value: any) => void;
  initialFilters: Object;
};

const Sidebar = (props: Props) => {
  const { filters, onFilterChange } = props;
  const handleChange = (e: any) => {
    const { name, value } = e;
    const newFilters = {
      ...filters,
      [name]: value,
    };
    onFilterChange(newFilters);
  };

  return (
    <Form layout="vertical">
      <FilterItems filters={filters} onRawFilterChange={handleChange} />
    </Form>
  );
};

export default Sidebar;
