import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Button, Typography, Switch } from 'antd';
import useTranslate from 'utils/useTranslate';
import Sortable from 'react-sortablejs';
import cryptoImage from 'img/icons/crypto/tether-logo.svg';
import { useConfig } from 'hooks/useConfig';
import {
  StyledRecommendedAmount,
  // StyledSortable,
  StyledTag,
  StyledButtons,
} from './styles';
import messages from '../../../../messages';

const { Text } = Typography;
const { TextArea } = Input;

interface ProvidersTypes {
  handleChange: () => void;
  handleBlur: () => void;
  values: {
    minimumDepositAmount: number;
    maximumDepositAmount: number;
    allowCustomAmount: boolean;
    suggestedAmounts: Array<number>;
    type: string;
    maximumDepositAmountPerDay: number;
    instructionText: string;
  };
  errors: {
    minimumDepositAmount: string;
    maximumDepositAmount: string;
    suggestedAmounts: string;
    maximumDepositAmountPerDay: string;
    instructionText: string;
  };
  formItemLayout: {};
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled: boolean;
}

const LimitSettings = ({
  values,
  formItemLayout,
  errors,
  handleChange,
  handleBlur,
  disabled,
  setFieldValue,
}: ProvidersTypes) => {
  const [suggestedAmountInput, setSuggestedAmountInput] = useState<number>(0);
  const { currency } = useConfig();
  const InputGroup = Input.Group;

  const despoitRangeIsValid =
    !errors.minimumDepositAmount &&
    !errors.maximumDepositAmount &&
    suggestedAmountInput >= values.minimumDepositAmount &&
    suggestedAmountInput <= values.maximumDepositAmount;

  const suggestedAmountIsPositive = suggestedAmountInput >= 0;
  // Limits the suggested amount input up to two decimal places
  const validateSuggestedAmount = /^[0-9]+(\.[0-9]{1,2})?$/.exec(
    String(suggestedAmountInput)
  );

  const suggestedAmountHasError =
    !despoitRangeIsValid ||
    !suggestedAmountIsPositive ||
    !validateSuggestedAmount;

  /* * 
    NOTE: The usage of the data-id in the StyledSortable component is to identify the array will be sorted for 
          the suggestedAmounts field
   * */
  const translate = useTranslate();
  const suggestedAmountsButton = [
    {
      key: 1,
      text: 100,
      value: 100,
    },
    {
      key: 2,
      text: 200,
      value: 200,
    },
    {
      key: 3,
      text: 300,
      value: 300,
    },
    {
      key: 4,
      text: 400,
      value: 400,
    },
    {
      key: 5,
      text: 500,
      value: 500,
    },
    {
      key: 6,
      text: 600,
      value: 600,
    },
    {
      key: 7,
      text: 700,
      value: 700,
    },
    {
      key: 8,
      text: 800,
      value: 800,
    },
    {
      key: 9,
      text: 900,
      value: 900,
    },
    {
      key: 10,
      text: 1000,
      value: 1000,
    },
  ];

  const isCrypto = values.type === 'CRYPTOCURRENCY';
  const tetherLogo = (size: number) => (
    <img
      height={size}
      width={size}
      src={cryptoImage}
      alt="logo"
      className="mr-1"
    />
  );
  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1"
        label={<strong>{translate(messages.limitsettings)}</strong>}
        colon={false}
      />
      <Form.Item
        label={translate(messages.customAmountInput)}
        {...formItemLayout}
      >
        <Switch
          checked={values.allowCustomAmount}
          onChange={(value) => setFieldValue('allowCustomAmount', value)}
        />
        <strong style={{ marginLeft: 10 }}>
          {values.allowCustomAmount
            ? translate(messages.on)
            : translate(messages.off)}
        </strong>
      </Form.Item>
      <Form.Item
        label={translate(messages.singledeposit)}
        {...formItemLayout}
        validateStatus={
          errors.minimumDepositAmount || errors.maximumDepositAmount
            ? 'error'
            : undefined
        }
        help={
          errors.minimumDepositAmount || errors.maximumDepositAmount || null
        }
        htmlFor="minimumDepositAmount"
      >
        <InputGroup>
          <Row>
            <Col span={11}>
              <Input
                addonBefore={isCrypto ? tetherLogo(18) : ''}
                name="minimumDepositAmount"
                id="minimumDepositAmount"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.minimumDepositAmount}
                placeholder={translate(messages.min)}
                type="number"
                disabled={disabled}
              />
            </Col>
            <Col className="text-center" span={2}>
              ~
            </Col>
            <Col span={11}>
              <Input
                addonBefore={isCrypto ? tetherLogo(18) : ''}
                name="maximumDepositAmount"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.maximumDepositAmount}
                placeholder={translate(messages.max)}
                type="number"
                disabled={disabled}
                data-testid="maximumDepositAmount"
              />
            </Col>
          </Row>
        </InputGroup>
      </Form.Item>
      <Form.Item
        label={translate(messages.dailyDepositLimit)}
        {...formItemLayout}
        validateStatus={errors.maximumDepositAmountPerDay ? 'error' : undefined}
        help={errors.maximumDepositAmountPerDay || null}
        htmlFor="maximumDepositAmountPerDay"
      >
        <Input
          addonBefore={isCrypto ? tetherLogo(18) : ''}
          name="maximumDepositAmountPerDay"
          id="maximumDepositAmountPerDay"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.maximumDepositAmountPerDay}
          placeholder={translate(messages.dailyDepositLimit)}
          disabled={disabled}
          type="number"
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.recommendedinstructions)}
        validateStatus={errors.instructionText ? 'error' : undefined}
        help={errors.instructionText || null}
        {...formItemLayout}
      >
        {/* TEXTAREA */}
        {isCrypto ? (
          <TextArea
            value={values.instructionText}
            placeholder={translate(messages.recommendedinstructionsplaceholder)}
            name="instructionText"
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        ) : (
          <Input
            placeholder={translate(messages.recommendedinstructionsplaceholder)}
            value={values.instructionText}
            name="instructionText"
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        )}
      </Form.Item>
      <Form.Item
        label={translate(messages.recommendedamount)}
        {...formItemLayout}
      >
        <StyledRecommendedAmount>
          <Sortable
            onChange={(newSortedList: any) =>
              setFieldValue(
                'suggestedAmounts',
                newSortedList.map((suggestedAmount: string) =>
                  parseFloat(suggestedAmount)
                )
              )
            }
          >
            {values.suggestedAmounts &&
              values.suggestedAmounts.map((amount, index) => (
                <StyledTag
                  closable
                  visible
                  data-id={amount}
                  key={`${amount}-${index}`}
                  onClose={() => {
                    const newSuggestedAmounts = [...values.suggestedAmounts];
                    newSuggestedAmounts.splice(index, 1);
                    setFieldValue('suggestedAmounts', newSuggestedAmounts);
                  }}
                >
                  {isCrypto ? tetherLogo(12) : currency?.symbol} {amount}
                </StyledTag>
              ))}
          </Sortable>
          {values.suggestedAmounts && values.suggestedAmounts.length < 15 ? (
            <>
              <InputGroup>
                <Row gutter={8}>
                  <Col span={16}>
                    <Input
                      addonBefore={isCrypto ? tetherLogo(18) : currency?.symbol}
                      type="number"
                      placeholder={translate(messages.enteramount)}
                      value={
                        suggestedAmountInput === 0 ? '' : suggestedAmountInput
                      }
                      disabled={!values.maximumDepositAmount}
                      onChange={(e) => {
                        setSuggestedAmountInput(Number(e.target.value));
                      }}
                    />
                  </Col>
                  <Col span={8}>
                    <Button
                      type="primary"
                      ghost
                      style={{ width: '100%' }}
                      onClick={() => {
                        const { suggestedAmounts } = values;
                        setFieldValue('suggestedAmounts', [
                          ...suggestedAmounts,
                          suggestedAmountInput,
                        ]);
                        setSuggestedAmountInput(0);
                      }}
                      disabled={suggestedAmountHasError || disabled}
                    >
                      {translate(messages.addbutton)}
                    </Button>
                  </Col>
                </Row>
              </InputGroup>
              {isCrypto && (
                <InputGroup>
                  {suggestedAmountsButton.map((item) => (
                    <StyledButtons
                      key={item.value}
                      type="primary"
                      ghost
                      value={item.value}
                      style={{ width: '18.3%' }}
                      disabled={!values.maximumDepositAmount}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        const { value } = e.target as HTMLButtonElement;
                        setSuggestedAmountInput(Number(value));
                      }}
                    >
                      {tetherLogo(18)}
                      {item.text}
                    </StyledButtons>
                  ))}
                </InputGroup>
              )}
            </>
          ) : null}
          {!suggestedAmountIsPositive && (
            <div>
              <Text type="danger">
                {translate(messages.suggestedAmountIsPositiveError)}
              </Text>
            </div>
          )}
          {suggestedAmountIsPositive && !despoitRangeIsValid && (
            <div>
              <Text type="danger">
                {translate(messages.suggestedAmountInputError)}
              </Text>
            </div>
          )}
          {suggestedAmountIsPositive && !validateSuggestedAmount && (
            <div>
              <Text type="danger">
                {translate(messages.maxDecimalPlacesError, { max: 2 })}
              </Text>
            </div>
          )}
          {!suggestedAmountIsPositive && (
            <div>
              <Text type="danger">
                {' '}
                {translate(messages.NEGATIVE_NUMBERS_ARE_NOT_ALLOWED)}
              </Text>
            </div>
          )}
        </StyledRecommendedAmount>
        {errors.suggestedAmounts && (
          <div>
            <Text type="danger">{errors.suggestedAmounts}</Text>
          </div>
        )}
        <div>
          <Text type="secondary">
            {translate(messages.limitrecommendedamount)}
          </Text>
        </div>
      </Form.Item>
    </>
  );
};

export default React.memo(LimitSettings);
