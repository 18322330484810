import React, { useState } from 'react';
import { TwitterPicker as Picker } from 'react-color';
import { StyledContainer } from './styles';

type Rec = Record<string, any>;

const TwitterPicker: any = Picker;
const colorsArray: string[] = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
  '#823d4d',
  '#df40b6',
  '#b45f7a',
  '#dd902f',
  '#e099c2',
  '#96ccf5',
  '#b99fce',
  '#ff006c',
];

const colorOptions = colorsArray.reduce(
  (acc, currVal) => ({
    ...acc,
    [currVal]: currVal,
  }),
  {}
);

const getKeyByValue = (obj: Rec, value: string) =>
  Object.keys(obj).find((key) => obj[key] === value);

const Twitter = ({
  onChange,
  value,
}: {
  onChange: (e: any) => void;
  value: string;
}) => {
  const [colorValue, setColorValue] = useState(
    getKeyByValue(colorOptions, value)
  );

  const handleChangeComplete = (color: { hex: string }) => {
    setColorValue(color.hex);

    onChange(colorOptions[color.hex]);
  };

  return (
    <StyledContainer color={colorValue}>
      <TwitterPicker
        color={colorValue}
        triangle="hide"
        colors={colorOptions}
        onChange={handleChangeComplete}
      />
    </StyledContainer>
  );
};

export default Twitter;
