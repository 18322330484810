import { Spin } from 'antd';
import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { withErrorHandler } from 'components/ErrorHandler';
import {
  TOTAL_AMOUNT_DETAILS,
  TOTALS__WITH_PREVIOUS_VALUE,
} from '../../queries';
import CardStat from './components/CardStat';
import CardStatDetailed from './components/CardStatDetailed';

import {
  StyledContent,
  StyledCardStatContainer,
  StyledBackground,
  StyledArrow,
  StyledBlock,
} from './styles';

type Rec = Record<string, any>;

const getStatPercentage = (current: number, previous: number) => {
  const percentValue = ((current - previous) / previous) * 100 || 0;
  return Number.isFinite(percentValue) ? percentValue.toFixed(2) : 0;
};

const CardStats = (props: { filter: Rec; pollInterval: number }) => {
  const { filter, pollInterval } = props;

  const [currentFocus, setCurrentFocus] = useState(null);
  const handleFocus = (e: any) => setCurrentFocus(e);

  const { start, end, prevStartDate, prevEndDate } = filter || {};

  const { data: totalAmountDetails = {} }: Rec = useQuery(
    TOTAL_AMOUNT_DETAILS,
    {
      variables: {
        startDateTime: start.format(),
        endDateTime: end.format(),
      },
    }
  );

  const {
    data: totalAmountsWithPrevValues = {},
    error: errorTotalAmounts,
    loading,
  } = useQuery(TOTALS__WITH_PREVIOUS_VALUE, {
    variables: {
      startDateTime: start.format(),
      endDateTime: end.format(),
      startDateTimePrev: prevStartDate.format(),
      endDateTimePrev: prevEndDate.format(),
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval,
  }) as any;

  const {
    totalBetsCount,
    betAmountDetails,
    depositAmountDetails,
    withdrawalAmountDetails,
    winLossAmountDetails,
    netProfitAmountDetails,
  } = totalAmountDetails;

  let {
    totalBetAmount,
    totalBetAmountPrev,
    totalDepositAmount,
    totalDepositAmountPrev,
    totalWithdrawalAmount,
    totalWithdrawalAmountPrev,
    totalWinlossAmount,
    totalWinlossAmountPrev,
    totalNetProfitAmount,
    totalNetProfitAmountPrev,
    totalDepositsCount,
    totalDepositorsCount,
    totalWithdrawersCount,
    totalWithdrawalsCount,
  } = totalAmountsWithPrevValues;

  if (errorTotalAmounts) {
    totalBetAmount = 0;
    totalBetAmountPrev = 0;
    totalDepositAmount = 0;
    totalDepositAmountPrev = 0;
    totalWithdrawalAmount = 0;
    totalWithdrawalAmountPrev = 0;
    totalWinlossAmount = 0;
    totalWinlossAmountPrev = 0;
    totalNetProfitAmount = 0;
    totalNetProfitAmountPrev = 0;
    totalDepositsCount = 0;
    totalDepositorsCount = 0;
    totalWithdrawersCount = 0;
    totalWithdrawalsCount = 0;
  }
  return (
    <>
      {!loading && (
        <div>
          <StyledContent className="py-3">
            <StyledCardStatContainer>
              <StyledBlock className="mx-2">
                <a href="#/" onClick={() => handleFocus('total-bet')}>
                  <CardStat
                    label="Total bet amount"
                    langId="dashboard.total-bet.text"
                    customBarColor="#8543E0"
                    totalAmount={totalBetAmount || 0}
                    percentage={Number(
                      getStatPercentage(totalBetAmount, totalBetAmountPrev)
                    )}
                    percentageLangId={filter.label}
                    graph={betAmountDetails}
                  />
                  {currentFocus === 'total-bet' && <StyledArrow />}
                </a>
              </StyledBlock>
              <StyledBlock className="mx-2">
                <a href="#/" onClick={() => handleFocus('total-deposit')}>
                  <CardStat
                    label="Total deposit amount"
                    langId="dashboard.total-deposits.text"
                    customBarColor="#52C41A"
                    totalAmount={totalDepositAmount || 0}
                    percentage={Number(
                      getStatPercentage(
                        totalDepositAmount,
                        totalDepositAmountPrev
                      )
                    )}
                    percentageLangId={filter.label}
                    graph={depositAmountDetails}
                  />
                  {currentFocus === 'total-deposit' && <StyledArrow />}
                </a>
              </StyledBlock>
              <StyledBlock className="mx-2">
                <a href="#/" onClick={() => handleFocus('total-withdrawal')}>
                  <CardStat
                    label="Total withdrawal amount"
                    langId="dashboard.total-withdrawal.text"
                    customBarColor="#FF0062"
                    totalAmount={totalWithdrawalAmount || 0}
                    percentage={Number(
                      getStatPercentage(
                        totalWithdrawalAmount,
                        totalWithdrawalAmountPrev
                      )
                    )}
                    percentageLangId={filter.label}
                    graph={withdrawalAmountDetails}
                  />
                  {currentFocus === 'total-withdrawal' && <StyledArrow />}
                </a>
              </StyledBlock>
              <StyledBlock className="mx-2">
                <a href="#/" onClick={() => handleFocus('total-win-loss')}>
                  <CardStat
                    label="Total win loss"
                    langId="dashboard.total-win-loss.text"
                    chart={1}
                    totalAmount={totalWinlossAmount || 0}
                    percentage={Number(
                      getStatPercentage(
                        totalWinlossAmount,
                        totalWinlossAmountPrev
                      )
                    )}
                    percentageLangId={filter.label}
                    graph={winLossAmountDetails}
                  />
                  {currentFocus === 'total-win-loss' && <StyledArrow />}
                </a>
              </StyledBlock>
              <StyledBlock className="mx-2">
                <a href="#/" onClick={() => handleFocus('total-net-profit')}>
                  <CardStat
                    label="Total net profit"
                    langId="dashboard.total-net-profit.text"
                    chart={1}
                    totalAmount={totalNetProfitAmount || 0}
                    percentage={Number(
                      getStatPercentage(
                        totalNetProfitAmount,
                        totalNetProfitAmountPrev
                      )
                    )}
                    percentageLangId={filter.label}
                    graph={netProfitAmountDetails}
                  />
                  {currentFocus === 'total-net-profit' && <StyledArrow />}
                </a>
              </StyledBlock>
            </StyledCardStatContainer>
          </StyledContent>
          {currentFocus === 'total-bet' && (
            <StyledBackground>
              <StyledContent>
                <CardStatDetailed
                  langId="dashboard.total-bet-amount.text"
                  detail="bet"
                  total={totalBetAmount}
                  totalBetsCount={totalBetsCount}
                  graph={betAmountDetails}
                  color="#8543e0"
                />
              </StyledContent>
            </StyledBackground>
          )}
          {currentFocus === 'total-deposit' && (
            <StyledBackground>
              <StyledContent>
                <CardStatDetailed
                  langId="dashboard.total-deposit-amount.text"
                  detail="deposit"
                  total={totalDepositAmount}
                  totalDepositsCount={totalDepositsCount}
                  totalDepositorsCount={totalDepositorsCount}
                  graph={depositAmountDetails}
                  color="#52C41A"
                />
              </StyledContent>
            </StyledBackground>
          )}
          {currentFocus === 'total-withdrawal' && (
            <StyledBackground>
              <StyledContent>
                <CardStatDetailed
                  langId="dashboard.total-withdrawal-amount.text"
                  detail="withdrawal"
                  total={totalWithdrawalAmount}
                  graph={withdrawalAmountDetails}
                  color="#ff0062"
                  withdrawalExtraInfo={[
                    {
                      label: 'Number of withdrawals',
                      value: totalWithdrawalsCount,
                    },
                    {
                      label: 'Unique customer withdrawals',
                      value: totalWithdrawersCount,
                    },
                  ]}
                />
              </StyledContent>
            </StyledBackground>
          )}
          {currentFocus === 'total-win-loss' && (
            <StyledBackground>
              <StyledContent>
                <CardStatDetailed
                  langId="dashboard.total-winloss-amount.text"
                  detail="winloss"
                  total={totalWinlossAmount}
                  graph={winLossAmountDetails}
                  color="#ff0062"
                  chart={1}
                />
              </StyledContent>
            </StyledBackground>
          )}
          {currentFocus === 'total-net-profit' && (
            <StyledBackground>
              <StyledContent>
                <CardStatDetailed
                  langId="dashboard.total-net-profit-amount.text"
                  detail="netprofit"
                  total={totalNetProfitAmount}
                  graph={netProfitAmountDetails}
                  color="#ff0062"
                  chart={1}
                />
              </StyledContent>
            </StyledBackground>
          )}
        </div>
      )}

      {loading && (
        <div className="text-center mt-5">
          <Spin />
        </div>
      )}
    </>
  );
};

export default withErrorHandler(CardStats);
