import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';

import { useCreateLoyaltyProgramContext } from '../../../../context';
import QualificationCriteria from './components/QualificationCriteria';
import { QualificationCriteriaProvider } from './components/QualificationCriteria/context';

import messages from './messages';

const { Item } = Form;

const formItemHorizontalLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

export default function GeneralSettings({
  formik = {},
}: {
  formik?: Record<string, any>;
}) {
  const translate = useTranslate();
  const [createProgramState] = useCreateLoyaltyProgramContext() as any;

  const activeTier = coercedGet(createProgramState, 'activeTier', {});
  const { qualificationCriteria = {} } = activeTier;
  const { handleChange, values } = formik;
  const isPresetCriteriaPresent = !isEqual(qualificationCriteria, {
    type: 'DEPOSIT',
    amount: 0,
  });

  return (
    <div>
      <Item
        colon={false}
        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        label={
          <strong>
            <FormattedMessage
              id="general-settings.text"
              defaultMessage="General Settings"
            />
          </strong>
        }
        {...formItemHorizontalLayout}
      >
        &nbsp;
      </Item>

      <Item
        label={
          <FormattedMessage
            id="=member-loyalty.name.text"
            defaultMessage="Name"
          />
        }
        {...formItemHorizontalLayout}
      >
        <Input
          size="large"
          name="name"
          value={values.name}
          onChange={handleChange}
          placeholder={translate(messages['member-loyalty.name.text'])}
        />
      </Item>

      <QualificationCriteriaProvider>
        <QualificationCriteria
          formik={formik}
          isPresetCriteriaPresent={isPresetCriteriaPresent}
        />
      </QualificationCriteriaProvider>
    </div>
  );
}
