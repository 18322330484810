import { useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import {
  APPROVE_DEPOSIT,
  START_PROCESS_DEPOSIT,
} from 'graphql/mutations/deposit.mutation';
import { DEPOSIT, DEPOSIT_REQUESTS } from 'graphql/queries/deposit.query';
import messages from 'pages/components/ExternalMemberDeposits/messages';
import React from 'react';
import { useIntl } from 'react-intl';
import { customFormatMessage } from 'utils/customFormatMessage';

type Props = {
  id: string;
  status: string;
  refetchVariables: object;
  disabled: boolean;
};
const ApproveDeposit = ({ id, status, refetchVariables }: Props) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  const translate = (value: any) => customFormatMessage(formatMessage, value);

  const refetchQueries = [
    {
      query: DEPOSIT,
      variables: { id },
    },
    {
      query: DEPOSIT_REQUESTS,
      variables: refetchVariables,
    },
  ];

  const [approveDeposit, { loading }] = useMutation(APPROVE_DEPOSIT, {
    onCompleted: (data) => {
      if (data.approveDeposit) {
        message.success(translate(messages.approveDepositProcess));
      }
    },
    variables: { id },
    refetchQueries,
  });

  const [startProcessDeposit, startProcessResult] = useMutation(
    START_PROCESS_DEPOSIT,
    {
      onCompleted: (data) => {
        if (data.startProcessDeposit) {
          approveDeposit();
        }
      },
      variables: { id },
    }
  );

  const onHandleApprove = () => {
    if (status === 'PROCESSING') {
      approveDeposit();
    } else {
      startProcessDeposit();
    }
  };

  const disableState = loading || startProcessResult.loading;

  return (
    <a href="#/" onClick={onHandleApprove}>
      {disableState ? <Spin /> : null}
      {translate(messages.approve)}
    </a>
  );
};

export default ApproveDeposit;
