import React from 'react';
import { Empty } from 'antd';
import { isEmpty } from 'lodash';
import StarMember from './StarMember';

export const starredMembersSortBy = {
  dateRegistered: 'dateRegistered',
  memberLevel: 'memberLevel',
  lastAdded: 'lastAdded',
};

export const RenderStarMembers = ({
  starredMembers,
}: {
  starredMembers: Array<Record<string, any>>;
}) => {
  const empty = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return !isEmpty(starredMembers)
    ? starredMembers.map((user: any, index: number) => (
        <StarMember key={index} user={user} />
      ))
    : empty;
};
