import { DynamicObj } from 'interfaces/user.interface';
import React, { ReactElement } from 'react';
import createContext from 'utils/createContext';

export const RebateGroupTypes = {
  SET_ACTIVE_REBATE_GROUP: 'SET_ACTIVE_REBATE_GROUP',
  RESET_ACTIVE_REBATE_GROUP: 'RESET_ACTIVE_REBATE_GROUP',
  RESET_FILL_FOR: 'RESET_FILL_FOR',
  SET_REFETCH_VARIABLES: 'SET_REFETCH_VARIABLES',
  SET_VENDORS: 'SET_VENDORS',
  SET_DEFAULT_VENDOR_REBATES: 'SET_DEFAULT_VENDOR_REBATES',
  SET_SUMMARY_ACTIVE: 'SET_SUMMARY_ACTIVE',
  SET_SUMMARY_INACTIVE: 'SET_SUMMARY_INACTIVE',
  SET_INITIALIZED_FILTERS: 'SET_INITIALIZED_FILTERS',
  TOGGLE_DUPLICATE: 'TOGGLE_DUPLICATE',
  TOGGLE_DELETE: 'TOGGLE_DELETE',
};

export const RebateGroupStatus = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const [useCtx, ContextProvider] = createContext();

export function RebateProvider({ children }: { children: ReactElement }) {
  const initialState = {
    activeRebateGroup: '',
    activeRebateGroupData: null,
    refetchVariables: {},
    preventFillFor: '',
    vendors: [],
    defaultVendorRebate: {},
    summaryIsActive: false,
    initializedFilters: true,
    deleteShown: false,
    duplicateShown: false,
  };

  const reducer = (
    state: any,
    action: {
      type: string;
      payload: any;
      meta: DynamicObj;
      preventFillFor: any;
    }
  ) => {
    switch (action.type) {
      case RebateGroupTypes.SET_ACTIVE_REBATE_GROUP:
        return {
          ...state,
          activeRebateGroup: action.payload,
          activeRebateGroupData: action.meta,
          preventFillFor: action.preventFillFor,
        };
      case RebateGroupTypes.RESET_FILL_FOR:
        return {
          ...state,
          preventFillFor: '',
        };
      case RebateGroupTypes.RESET_ACTIVE_REBATE_GROUP:
        return {
          ...state,
          activeRebateGroup: null,
          activeRebateGroupData: null,
          preventFillFor: '',
        };
      case RebateGroupTypes.SET_REFETCH_VARIABLES:
        return {
          ...state,
          refetchVariables: action.payload,
        };
      case RebateGroupTypes.SET_VENDORS:
        return {
          ...state,
          vendors: action.payload,
        };
      case RebateGroupTypes.SET_DEFAULT_VENDOR_REBATES:
        return {
          ...state,
          defaultVendorRebate: action.payload,
        };
      case RebateGroupTypes.SET_SUMMARY_ACTIVE:
        return {
          ...state,
          summaryIsActive: true,
        };
      case RebateGroupTypes.SET_SUMMARY_INACTIVE:
        return {
          ...state,
          summaryIsActive: false,
        };
      case RebateGroupTypes.SET_INITIALIZED_FILTERS:
        return {
          ...state,
          initializedFilters: action.payload,
        };
      case RebateGroupTypes.TOGGLE_DUPLICATE:
        return {
          ...state,
          duplicateShown: !state.duplicateShown,
        };
      case RebateGroupTypes.TOGGLE_DELETE:
        return {
          ...state,
          deleteShown: !state.deleteShown,
        };
      default:
        return state;
    }
  };

  return (
    <ContextProvider value={React.useReducer(reducer, initialState)}>
      {children}
    </ContextProvider>
  );
}

export const useRebateGroupState = () => useCtx();
