import React, { ReactElement } from 'react';
import { Radio } from 'antd';
import { useFormikContext } from 'formik';
import { RadioChangeEvent } from 'antd/lib/radio';

const RadioGroup = (props: { children: ReactElement; name: string }) => {
  const { children, name } = props;
  const { setFieldValue, values } = useFormikContext() as any;
  function handleChange(e: RadioChangeEvent) {
    setFieldValue(name, e.target.value);
  }
  return (
    <Radio.Group onChange={handleChange} value={values[name]}>
      {children}
    </Radio.Group>
  );
};

export default RadioGroup;
