import React from 'react';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import styled from 'styled-components';
import { InputNumber } from 'antd';
import { IP_ADDRESS_SUMMARY } from 'constants/testIds';
import { blockAlphabets } from 'utils/blockAlphabets';

const defaultProps = {
  onChange: (e: any) => e,
  minPlaceHolder: '',
  maxPlaceHolder: '',
};

type Props = {
  value: { min: number | undefined; max: number | undefined };
} & typeof defaultProps;

const CountFilter = ({
  value,
  onChange,
  minPlaceHolder,
  maxPlaceHolder,
}: Props) => {
  const translate = useTranslate();

  const handleMinChange = (newValue: number | undefined | string | null) => {
    onChange({
      range: 'gte',
      value: newValue || null,
    });
  };

  const handleMaxChange = (newValue: number | undefined | string | null) => {
    onChange({
      range: 'lte',
      value: newValue || null,
    });
  };

  const handleBlur = (key: 'min' | 'max'): null => {
    if (!value.min || !value.max) return null;

    if (value.min > value.max || value.max < value.min) {
      onChange({
        range: key === 'min' ? 'lte' : 'gte',
        value: '',
      });
    }
    return null;
  };

  const onKeyDownPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // const reg = /^\d+$/;
    blockAlphabets(e, true);
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-space-between">
      <StyledInput
        aria-label={IP_ADDRESS_SUMMARY.countFilterMin}
        onChange={handleMinChange}
        value={value.min}
        type="number"
        min={0}
        placeholder={minPlaceHolder || translate(messages.MIN)}
        onBlur={() => handleBlur('min')}
        onKeyDown={onKeyDownPress}
      />
      -
      <StyledInput
        aria-label={IP_ADDRESS_SUMMARY.countFilterMax}
        onChange={handleMaxChange}
        value={value.max}
        type="number"
        min={0}
        placeholder={maxPlaceHolder || translate(messages.MAX)}
        onBlur={() => handleBlur('max')}
        onKeyDown={onKeyDownPress}
      />
    </div>
  );
};

export default CountFilter;

const StyledInput = styled(InputNumber)`
  width: 45% !important;
  border-bottom: 1px solid #d9d9d9 !important;
`;
