import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button } from 'antd';
import Layout from 'components/Layout';
import ALL_PERMISSIONS from 'constants/permissions3';
import { MESSAGES } from 'graphql/queries/manualMessage.query';
import { Page } from 'interfaces/user.interface';
import isEmpty from 'lodash/isEmpty';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePermissions } from 'store/accountState';
import { useScreenTabV2 } from 'store/screenTabState';
import coercedGet from 'utils/coercedGet';
import removeNull from 'utils/removeNull';
import { tablePaginationHandler } from 'utils/tablePagination';
import { FilterConditions } from './components/Content/components/FilterConditions/FilterConditions';
import { CreateNewMail } from './components/CreateNewMail/CreateNewMail';
import SystemMessageSidebar from './components/SystemMessageSidebar';
import { EditSearchSettings } from './components/SystemMessageSidebar/components/EditSearchSettings/EditSearchSettings';
import { SystemMessageTable } from './components/SystemMessageTable/SystemMessageTable';
import { IMessagesData, ISystemMessageFilter } from './interfaces';
import { refetchVariables } from './utils';

const pageInitialState: Page<ISystemMessageFilter> = {
  first: 10,
  after: undefined,
  cursor: [null],
  savedCursor: [],
  currentPage: 0,
  filter: {},

  onTagClose: '',
};

export const ManualMessage = () => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MESSAGES.SYSTEM_MESSAGES}:MANUAL_MESSAGE`
  );

  const [modalState, setModalState] = useState<boolean>(false);

  const { getTab, useUpdateTab } = useScreenTabV2('manual-message');

  const thisTab = getTab('manual-message');
  const filter = coercedGet(thisTab, 'memberFilter', {});

  const [page, setPage] = useState<Page<ISystemMessageFilter>>({
    ...pageInitialState,
    filter: isEmpty(filter) ? {} : filter,
  });

  useUpdateTab(() => {
    setPage((prev) => ({
      ...prev,
      filter,
    }));
  });

  const handleFilters = useCallback((filterData: ISystemMessageFilter) => {
    // eslint-disable-next-line no-param-reassign
    delete (filterData as any).quickFilter;
    setPage((prev) => ({
      ...prev,
      filter: removeNull({
        ...prev.filter,
        ...filterData,
      }),
    }));
  }, []);

  const setFilterForMultMember = useCallback((values: object) => {
    setPage((prev) => ({
      ...prev,
      filter: removeNull({
        ...prev.filter,
        ...values,
      }),
    }));
  }, []);

  const [getMessages, { loading, error, data }] = useLazyQuery<IMessagesData>(
    MESSAGES,
    {
      variables: refetchVariables(page),
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  if (error) {
    return (
      <p>
        <FormattedMessage id="error.text" defaultMessage="Error" />
      </p>
    );
  }

  const totalCount = coercedGet(data!, 'messages.totalCount', 0);
  const pageInfo = coercedGet(data!, 'messages.pageInfo', {});

  const { handleNext, handlePrev, totalPage } = tablePaginationHandler(
    page,
    setPage,
    totalCount
  );

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={<FilterConditions page={page} setPage={setPage} />}
          rightNode={
            ALLOWED_CREATE && (
              <Button type="primary" onClick={() => setModalState(true)}>
                <PlusOutlined className="mr-1" />
                <FormattedMessage
                  id="create-new-mail.text"
                  defaultMessage="Create New Mail"
                />
              </Button>
            )
          }
        />
        <Layout.Content
          sideBar={
            <Layout.Sidebar
              quickSearch={{
                filters: page.filter!,
                contextKey: 'system-message',
                editSearchSettings: EditSearchSettings,
                onFilterChange: handleFilters,
              }}
            >
              <SystemMessageSidebar
                setFilterForMultMember={setFilterForMultMember}
                page={page}
                handleFilters={handleFilters}
              />
            </Layout.Sidebar>
          }
          footer={
            <Layout.Footer
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page as any}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage as any}
              pageInfo={pageInfo}
            />
          }
        >
          <SystemMessageTable
            loading={loading}
            getMessages={getMessages}
            data={data!}
          />
        </Layout.Content>
        {modalState && (
          <CreateNewMail
            getMessages={getMessages}
            visible={modalState}
            onCloseFn={() => setModalState(false)}
          />
        )}
      </>
    </Layout.Container>
  );
};
