import React from 'react';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import cx from 'classnames';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import useEventListener from '../hooks/useEventListener';

function Drawer(props: {
  backdrop?: boolean;
  open?: boolean;
  children?: any;
  onClose?: () => void;
}) {
  const ownRef = React.useRef(null);
  const { children, open, backdrop, onClose } = props;

  const [status, setStatus] = React.useState<string>('LEAVED');
  React.useEffect(() => {
    if (status === 'ENTERING') {
      setTimeout(() => setStatus('ENTERED'), 50);
    }
    if (status === 'ENTERED') {
      document.body.style.overflow = 'hidden';
      document.body.style.width = '100%';
    }
    if (status === 'LEAVING') {
      document.body.style.overflow = 'visible';
      setTimeout(() => setStatus('LEAVED'), 300);
    }
    if (status === 'LEAVED') {
      document.body.style.overflow = 'visible';
    }
  }, [status]);
  React.useEffect(() => {
    if (!open) {
      setStatus('LEAVING');
    } else {
      setStatus('ENTERING');
    }
  }, [open]);

  const handleKeyPress = (e: { keyCode: number }) => {
    if (!backdrop) return;
    if (e.keyCode === 27) {
      if (onClose) {
        onClose();
      }
    }
  };
  useEventListener(
    'click',
    handleKeyPress,
    (backdrop ? ownRef.current : null) as any
  );

  return (
    <Portal>
      <Root className={cx(status.toLocaleLowerCase(), 'drawer-wrapper')}>
        {children}
      </Root>
    </Portal>
  );
}

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: translateY(1000%);
  opacity: 1;
  background-color: #fff;
  &.entering {
    display: block;
  }
  &.entered {
    transform: translateY(0%);
  }
  &.leaving {
    transform: translateY(100%);
  }
  &.leaved {
    display: none;
  }
`;

const StyledDrawerContent: any = styled(Content)`
  padding: ${(props: any) => props.padding || '24px'};
  height: ${(props: any) => props.height || null};
`;

Drawer.Header = Header;
Drawer.Content = StyledDrawerContent;
Drawer.Footer = Footer;

export default Drawer;
