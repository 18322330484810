import React from 'react';
import { message, Modal } from 'antd';
import PasswordRequest from 'components/ForgotPassword/components/PasswordRequest';
import VerifyCode from 'components/ForgotPassword/components/VerifyCode';
import NewPassword from 'components/ForgotPassword/components/NewPassword';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_PASSWORD_RESET } from 'components/ForgotPassword/mutations';

type ForgotPassword = {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const ForgotPassword: React.FC<ForgotPassword> = ({
  visible,
  onClose,
  onSuccess,
}) => {
  const [step, setStep] = React.useState(1);
  const [code, setCode] = React.useState('');
  const [userInfo, setUserInfo] = React.useState({ email: '', username: '' });

  const [createPasswordRequest, { loading }] = useMutation(
    CREATE_PASSWORD_RESET
  );

  const onNextStep = () => setStep((prev) => prev + 1);

  const passwordResetRequest = (
    email?: string,
    username?: string,
    adminCode?: string
  ) => {
    if (email && username && adminCode) {
      setUserInfo({ email, username });
    }

    createPasswordRequest({
      variables: {
        input: {
          email: email ?? userInfo.email,
          username: username ?? userInfo.username,
          adminCode,
        },
      },
    })
      .then(() => {
        if (email && username && adminCode) {
          onNextStep();
        }
      })
      .catch(() => {
        message.error('Error bro');
      });
  };

  return (
    <Modal
      destroyOnClose
      title="Reset Password"
      visible={visible}
      footer={null}
      onCancel={() => {
        setStep(1);
        onClose();
      }}
    >
      {step === 1 && (
        <PasswordRequest
          loading={loading}
          onPasswordReset={passwordResetRequest}
        />
      )}
      {step === 2 && (
        <VerifyCode
          loading={loading}
          resendVerification={passwordResetRequest}
          onSuccess={(generatedCode) => {
            setCode(generatedCode);
            onNextStep();
          }}
        />
      )}
      {step === 3 && (
        <NewPassword
          verificationCode={code}
          onSuccess={() => {
            onClose();
            setStep(1);
            onSuccess();
          }}
        />
      )}
    </Modal>
  );
};

export default ForgotPassword;
