import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-status.success-message.text': {
    id: 'member-status.success-message.text',
    defaultMessage: 'Member status updated successfully',
  },
});

export default messages;
