import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { Spin } from 'antd';
import ModifiedTag from '../ModifiedTag';
import { VENDOR } from './queries';

type Props = {
  id: string;
  onClose: (value: any) => any;
};
const VendorTab: React.FC<Props> = props => {
  const { id, onClose } = props;
  const { data = {}, loading } = useQuery(VENDOR, {
    variables: {
      id,
    },
  });

  return (
    <ModifiedTag {...props} closable onClose={onClose as any}>
      {loading ? <Spin /> : coercedGet(data, 'vendor.name', null)}
    </ModifiedTag>
  );
};

export default VendorTab;
