import React, { useState } from 'react';
import { StyledTable } from 'constants/styles';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Spin } from 'antd';
import thirdPartyProviders from 'constants/thirdPartyProviders';
import moment from 'moment';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { showPermissionError } from 'components/Navbar/Navbar';
import { columnAlign } from 'utils/tableAlignment';

import coercedGet from 'utils/coercedGet';
import messages from 'pages/components/WithdrawalProviders/messages';
import globalMsgs from 'messages';
import useTranslate from 'utils/useTranslate';
import { withdrawalProvidersTestId } from 'components/data-testid/WithdrawalProviders';
import { ALink } from 'components/ALink/ALink';
import { WithdrawalProvider } from 'types/graphqlTypes';
import EditProvider from '../EditProvider';
import DeleteProvider from '../DeleteProvider';
import DuplicateProvider from '../DuplicateProvider';

type WithdrawalProviderType = {
  dataSource: Record<string, any>;
  loading: boolean;
  refetchVariables: Record<string, any>;
};

const WithdrawalProvidersTable = ({
  dataSource,
  loading,
  refetchVariables,
}: WithdrawalProviderType) => {
  const translate = useTranslate();
  const { role, permissions } = usePermissions();

  const {
    ALLOWED_LIST,
    ALLOWED_VIEW_DETAILS,
    ALLOWED_EDIT,
    ALLOWED_DELETE,
    ALLOWED_DUPLICATE,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'VIEW_DETAILS', 'EDIT', 'DELETE', 'DUPLICATE'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS
  );

  const [state, setState] = useState({
    isEditWithdrawalProvider: false,
    isDeleteWithdrawalProvider: false,
    isDuplicateWithdrawalProvider: false,
    withdrawalProviderId: null,
    editable: false,
    selectedRowKeys: [],
  });

  const {
    isEditWithdrawalProvider,
    isDuplicateWithdrawalProvider,
    isDeleteWithdrawalProvider,
  } = state;

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = dataSource
    ? dataSource.map(({ node }: Record<string, any>) => ({
        ...node,
        key: node.id,
      }))
    : [];

  const toggleEdit = (id = null) => {
    setState({
      ...state,
      isEditWithdrawalProvider: !isEditWithdrawalProvider,
      withdrawalProviderId: id,
      editable: true,
    });
  };

  const toggleView = (id: string) => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('3rd party withdrawal providers - View Details');
      return;
    }
    setState({
      ...state,
      isEditWithdrawalProvider: !isEditWithdrawalProvider,
      withdrawalProviderId: id as any,
      editable: false,
    });
  };

  const toggleDuplicate = (id = null) => {
    setState({
      ...state,
      isDuplicateWithdrawalProvider: !isDuplicateWithdrawalProvider,
      withdrawalProviderId: id,
    });
  };

  const toggleDelete = (id = null) => {
    setState({
      ...state,
      isDeleteWithdrawalProvider: !isDeleteWithdrawalProvider,
      withdrawalProviderId: id,
    });
  };

  const columns = [
    {
      csvData: {
        label: translate(messages['provider-name.text']),
        key: 'name',
      },
      title: translate(messages['provider-name.text']),
      align: columnAlign.onCenter,
      key: 'name',
      render: (provider: WithdrawalProvider) => (
        <a
          href="#/section"
          data-testid={`provider-${provider.id}`}
          onClick={() => {
            toggleView(provider.id);
          }}
        >
          {provider.name}
        </a>
      ),
    },
    {
      csvData: {
        label: translate(messages['type.text']),
        key: 'type',
        renderCell: (withdrawalProvider: WithdrawalProvider) =>
          coercedGet(withdrawalProvider, 'type', ''),
      },
      title: translate(messages['type.text']),
      align: columnAlign.onCenter,
      dataIndex: 'type',
      key: 'type',
    },
    {
      csvData: {
        label: translate(messages['api-key-preview.text']),
        key: 'apiKey',
        renderCell: (withdrawalProvider: Record<string, any>) =>
          withdrawalProvider.type === thirdPartyProviders.DIORPAY
            ? withdrawalProvider.key
            : withdrawalProvider.apiKey,
      },
      title: translate(messages['api-key-preview.text']),
      align: columnAlign.onCenter,
      key: 'apiKeyPreview',
      render: (provider: Record<string, any>) =>
        provider.type === thirdPartyProviders.DIORPAY
          ? provider.keyPreview
          : provider.apiKeyPreview,
    },
    {
      csvData: {
        label: translate(messages.THIRD_PARTY_GATEWAY),
        key: 'midPayDirectPaymentGateway',
        renderCell: (paymentGateway: WithdrawalProvider) => {
          const gatewayId = coercedGet(
            paymentGateway,
            'midPayDirectPaymentGateway.id',
            ''
          );
          return gatewayId ? translate(globalMsgs[gatewayId]) : '-';
        },
      },
      title: translate(messages.THIRD_PARTY_GATEWAY),
      align: columnAlign.onCenter,
      key: 'midPayDirectPaymentGateway',
      dataIndex: 'midPayDirectPaymentGateway',
      render: (paymentGateway: WithdrawalProvider) => {
        const gatewayId = coercedGet(paymentGateway, 'id', '');
        return gatewayId ? translate(globalMsgs[gatewayId]) : '-';
      },
    },
    {
      csvData: {
        label: translate(messages['date-created.text']),
        key: 'dateTimeCreated',
        renderCell: (withdrawalProvider: WithdrawalProvider) =>
          moment(withdrawalProvider.dateTimeCreated).format(
            'MMM DD, YYYY hh:mm:ss A'
          ),
      },
      title: translate(messages['date-created.text']),
      align: columnAlign.onCenter,
      dataIndex: 'dateTimeCreated',
      key: 'dateTimeCreated',
      render: (text: string) => moment(text).format('MMM DD, YYYY hh:mm:ss A'),
    },
    {
      csvData: null,
      fixed: 'right',
      width: 80,
      hidden: true,
      key: 'actions',
      title: translate(messages['actions.text']),
      align: columnAlign.onCenter,
      dataIndex: 'id',
      render: (id: any) =>
        (ALLOWED_EDIT || ALLOWED_DUPLICATE || ALLOWED_DELETE) && (
          <Dropdown
            trigger={['hover', 'click']}
            overlay={
              <Menu>
                {ALLOWED_EDIT && (
                  <Menu.Item
                    data-testid={`edit-button-${id}`}
                    onClick={() => {
                      toggleEdit(id);
                    }}
                  >
                    {translate(messages['edit.text'])}
                  </Menu.Item>
                )}
                {ALLOWED_DUPLICATE && (
                  <Menu.Item
                    data-testid={`duplicate-button-${id}`}
                    onClick={() => {
                      toggleDuplicate(id);
                    }}
                  >
                    {translate(messages['duplicate.text'])}
                  </Menu.Item>
                )}
                {ALLOWED_DELETE && (
                  <Menu.Item
                    data-testid={`delete-button-${id}`}
                    disabled={false}
                    onClick={() => {
                      toggleDelete(id);
                    }}
                  >
                    {translate(messages['delete.text'])}
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
          >
            <ALink
              href="#"
              aria-label="Actions"
              data-testid={`actions-dropdown-${id}`}
            >
              <EllipsisOutlined
                className="cursor-pointer font-weight-bold"
                style={{ fontSize: '25px' }}
              />
            </ALink>
          </Dropdown>
        ),
    },
  ];

  const { filterColumns } = useCustomColumnsV2(
    'withdrawal-providers-external',
    columns
  );

  if (loading)
    return (
      <div className="styled-center" data-testid="loading-spinner">
        <Spin />
      </div>
    );

  return (
    <>
      {isEditWithdrawalProvider && (
        <EditProvider
          id={state.withdrawalProviderId}
          toggleModal={toggleEdit}
          editable={state.editable}
          refetchVariables={refetchVariables}
        />
      )}
      {isDuplicateWithdrawalProvider && (
        <DuplicateProvider
          id={state.withdrawalProviderId}
          toggleModal={toggleDuplicate}
          refetchVariables={refetchVariables}
        />
      )}
      {isDeleteWithdrawalProvider && (
        <DeleteProvider
          id={state.withdrawalProviderId}
          toggleModal={toggleDelete}
          refetchVariables={refetchVariables}
        />
      )}
      {ALLOWED_LIST && (
        <StyledTable
          data-testid={withdrawalProvidersTestId.table}
          loading={loading}
          rowSelection={rowSelection}
          columns={filterColumns(columns)}
          size="small"
          dataSource={data}
          pagination={false}
          scroll={{ y: 640, x: 'calc(525px + 50%)' }}
          rowKey="serialCode"
        />
      )}
    </>
  );
};

export default WithdrawalProvidersTable;
