import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledLabel = styled.span`
  font-size: 10px;
  font-weight: bold;
`;

const StyledClearButton = styled.span<any>`
  font-size: 10px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const Section = styled.section`
  margin-top: 5px;
`;

export const FilterItem = ({
  onClear,
  label,
  children,
}: {
  onClear?: (value: string[]) => void;
  label: string | ReactNode;
  children: ReactNode;
}) => {
  const Label = () => <StyledLabel>{label}</StyledLabel>;
  return (
    <Section>
      <div>
        {onClear && (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ minHeight: '21px' }}
          >
            <Label />
            <StyledClearButton onClick={onClear}>
              <FormattedMessage id="clear.text" defaultMessage="Clear" />
            </StyledClearButton>
          </div>
        )}
        {!onClear && <Label />}
      </div>
      <div>{children}</div>
    </Section>
  );
};
