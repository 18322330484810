import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';

const memberDetailsPermissions = [
  'ACCOUNT_BALANCE',
  'WALLET_BALANCE',
  'TO_BALANCE',
  'LAST_DEPOSIT',
  'LAST_WITHDRAWAL',
  'LAST_PROMO_APPLIED',
  'LAST_PLAYED_GAMES',
  'MEMBER_NOTES',
  'AGENT_AFFILIATE',
  'MEMBER_INFORMATION',
  'VIP_TIER_PROGRESS',
  'MEMBER_LABEL',
  'USER_PROFILE',
  'LOGIN_DETAILS',
  'CONTACT_DETAILS',
  'WITHDRAWAL_BANK_ACCOUNT',
  'REGISTRATION_INFORMATION',
  'MESSAGES',
  'ACCOUNT_RESTRICTION',
  'PLAYER_PROFILE',
];

export const useNewMemberDetailPermissions = () => {
  const { role, permissions } = usePermissions();

  const {
    ALLOWED_ACCOUNT_BALANCE,
    ALLOWED_WALLET_BALANCE,
    ALLOWED_TO_BALANCE,
    ALLOWED_LAST_DEPOSIT,
    ALLOWED_LAST_WITHDRAWAL,
    ALLOWED_LAST_PROMO_APPLIED,
    ALLOWED_LAST_PLAYED_GAMES,
    ALLOWED_MEMBER_NOTES,
    ALLOWED_PLAYER_PROFILE,
    ALLOWED_ACCOUNT_RESTRICTION,
    ...memberInfoPermissions
  } = collectPermissions(
    role,
    permissions,
    memberDetailsPermissions,
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS
  );

  const hexopayCardsPermission = collectPermissions(
    role,
    permissions,
    ['LIST', 'DELETE', 'TOGGLE_CLOSED_LOOP', 'DISABLE'],
    ALL_PERMISSIONS.ALL_MEMBERS
      .MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_HEXOPAY_CREDIT_CARDS
  );

  const memberNotesPermission = collectPermissions(
    role,
    permissions,
    ['LIST', 'ADD', 'EDIT', 'DELETE'],
    ALL_PERMISSIONS.ALL_MEMBERS
      .MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_MEMBER_NOTES
  );

  const playerProfilePermission = collectPermissions(
    role,
    permissions,
    ['UPDATE_PROFILE_LINK', 'VIEW_PROFILE_LINK'],
    ALL_PERMISSIONS.ALL_MEMBERS
      .MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_PLAYER_PROFILE
  );

  const documentManagementPermission = collectPermissions(
    role,
    permissions,
    ['LIST', 'UPLOAD', 'APPROVE', 'REJECT', 'DELETE'],
    ALL_PERMISSIONS.ALL_MEMBERS
      .MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DOCUMENT_MANAGEMENT
  );

  const realityCheckPermission = collectPermissions(
    role,
    permissions,
    ['LIST', 'EDIT'],
    ALL_PERMISSIONS.ALL_MEMBERS
      .MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_REALITY_CHECK
  );

  const depositLimitPermission = collectPermissions(
    role,
    permissions,
    ['LIST', 'EDIT'],
    ALL_PERMISSIONS.ALL_MEMBERS
      .MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_DEPOSIT_LIMIT
  );

  const selfExclusionPermission = collectPermissions(
    role,
    permissions,
    ['LIST', 'EDIT'],
    ALL_PERMISSIONS.ALL_MEMBERS
      .MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_INTERNAL_SELF_EXCLUSION
  );

  const timeoutPermission = collectPermissions(
    role,
    permissions,
    ['LIST', 'EDIT'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS_TIMEOUT
  );

  const { ALLOWED_MEMBER_BET_RECORDS } = collectPermissions(
    role,
    permissions,
    ['MEMBER_BET_RECORDS'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS
  );

  const { ALLOWED_MEMBER_INTERACTION_LOGS_REPORT } = collectPermissions(
    role,
    permissions,
    ['MEMBER_INTERACTION_LOGS_REPORT'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_REPORTS
  );

  const {
    ALLOWED_INTERNAL_DEPOSITS,
    ALLOWED_EXTERNAL_DEPOSITS,
  } = collectPermissions(
    role,
    permissions,
    ['INTERNAL_DEPOSITS', 'EXTERNAL_DEPOSITS'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS
  );

  const { ALLOWED_WITHDRAWAL_REQUESTS } = collectPermissions(
    role,
    permissions,
    ['WITHDRAWAL_REQUESTS'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS
  );

  const { ALLOWED_PROMO_REQUEST } = collectPermissions(
    role,
    permissions,
    ['PROMO_REQUEST'],
    ALL_PERMISSIONS.ALL_PROMOS.PROMOS
  );

  const BALANCES = {
    ALLOWED_ACCOUNT_BALANCE,
    ALLOWED_WALLET_BALANCE,
    ALLOWED_TO_BALANCE,
  };

  const DEPOSIT_AND_WITHDRAWALS = {
    ALLOWED_LAST_DEPOSIT,
    ALLOWED_LAST_WITHDRAWAL,
  };

  const PROMO_AND_GAMES = {
    ALLOWED_LAST_PROMO_APPLIED,
    ALLOWED_LAST_PLAYED_GAMES,
  };

  return {
    BALANCES,
    ALLOWED_BALANCES: Object.values(BALANCES).some(Boolean),
    DEPOSIT_AND_WITHDRAWALS,
    ALLOWED_DEPOSIT_AND_WITHDRAWALS: Object.values(
      DEPOSIT_AND_WITHDRAWALS
    ).some(Boolean),
    PROMO_AND_GAMES,
    ALLOWED_PROMO_AND_GAMES: Object.values(PROMO_AND_GAMES).some(Boolean),
    ALLOWED_MEMBER_NOTES,
    ALLOWED_PLAYER_PROFILE,
    MEMBER_INFO: memberInfoPermissions,
    ALLOWED_MEMBER_INFO: Object.values(memberInfoPermissions).some(Boolean),
    ALLOWED_MEMBER_BET_RECORDS,
    ALLOWED_INTERNAL_DEPOSITS,
    ALLOWED_EXTERNAL_DEPOSITS,
    ALLOWED_WITHDRAWAL_REQUESTS,
    ALLOWED_PROMO_REQUEST,
    ALLOWED_MEMBER_INTERACTION_LOGS_REPORT,
    ALLOWED_ACCOUNT_RESTRICTION,
    HEXOPAY_CARDS_PERMISSION: {
      ALLOWED_LIST: hexopayCardsPermission.ALLOWED_LIST,
      ALLOWED_DELETE: hexopayCardsPermission.ALLOWED_DELETE,
      ALLOWED_TOGGLE_CLOSED_LOOP:
        hexopayCardsPermission.ALLOWED_TOGGLE_CLOSED_LOOP,
      ALLOWED_DISABLE: hexopayCardsPermission.ALLOWED_DISABLE,
    },
    MEMBER_NOTES_PERMISSION: {
      ALLOWED_ADD: memberNotesPermission.ALLOWED_ADD,
      ALLOWED_DELETE: memberNotesPermission.ALLOWED_DELETE,
      ALLOWED_EDIT: memberNotesPermission.ALLOWED_EDIT,
      ALLOWED_LIST: memberNotesPermission.ALLOWED_LIST,
    },
    PLAYER_PROFILE_PERMISSION: {
      ALLOWED_EDIT: playerProfilePermission.ALLOWED_UPDATE_PROFILE_LINK,
      ALLOWED_VIEW: playerProfilePermission.ALLOWED_VIEW_PROFILE_LINK,
    },
    MEMBER_DOCUMENT_MANAGEMENT_PERMISSION: {
      ALLOWED_UPLOAD: documentManagementPermission.ALLOWED_UPLOAD,
      ALLOWED_LIST: documentManagementPermission.ALLOWED_LIST,
      ALLOWED_APPROVE: documentManagementPermission.ALLOWED_APPROVE,
      ALLOWED_REJECT: documentManagementPermission.ALLOWED_REJECT,
      ALLOWED_DELETE: documentManagementPermission.ALLOWED_DELETE,
    },
    MEMBER_REALITY_CHECK_PERMISSION: {
      ALLOWED_LIST: realityCheckPermission.ALLOWED_LIST,
      ALLOWED_EDIT: realityCheckPermission.ALLOWED_EDIT,
    },
    MEMBER_DEPOSIT_LIMIT_PERMISSION: {
      ALLOWED_LIST: depositLimitPermission.ALLOWED_LIST,
      ALLOWED_EDIT: depositLimitPermission.ALLOWED_EDIT,
    },
    MEMBER_SELF_EXCLUSION_PERMISSION: {
      ALLOWED_LIST: selfExclusionPermission.ALLOWED_LIST,
      ALLOWED_EDIT: selfExclusionPermission.ALLOWED_EDIT,
    },
    MEMBER_TIMEOUT_PERMISSION: {
      ALLOWED_LIST: timeoutPermission.ALLOWED_LIST,
      ALLOWED_EDIT: timeoutPermission.ALLOWED_EDIT,
    },
  };
};
