import messages from 'messages';
import { Deposit } from 'types/graphqlTypes';
import { defineMessages } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/date';

const messagesLocal = defineMessages({
  'first-deposit.text': {
    id: 'first-deposit.text',
    defaultMessage: 'First Deposit',
  },
  'serial-code.text': {
    id: 'serial-code.text',
    defaultMessage: 'Serial Code',
  },
  'affiliate.text': {
    id: 'affiliate.text',
    defaultMessage: 'Affiliate',
  },
  'account-username.text': {
    id: 'account-username.text',
    defaultMessage: 'Account Username',
  },
  'actions.text': {
    id: 'actions.text',
    defaultMessage: 'Actions',
  },
});

const getProcessingTime = (deposit: any) => {
  const { dateTimeCreated, dateTimeProcessed, status } = deposit;

  if (status === 'APPROVED' || status === 'REJECTED') {
    if (!dateTimeProcessed) {
      return moment(dateTimeCreated).fromNow();
    }
    return moment(dateTimeProcessed).from(dateTimeCreated, true);
  }
  if (status === 'EXPIRED') return '-';

  return moment(dateTimeCreated).fromNow();
};

const getCsvHeaders = (translate: any) => [
  {
    label: translate(messagesLocal['first-deposit.text']),
    key: 'firstDeposit',
    renderCell: (deposit: Partial<Deposit>) =>
      coercedGet(deposit, 'firstDeposit', '')
        ? translate(messages.YES)
        : translate(messages.NO),
  },
  {
    label: translate(messagesLocal['serial-code.text']),
    key: 'serialCode',
    renderCell: (deposit: Partial<Deposit>) =>
      coercedGet(deposit, 'serialCode', '-'),
  },
  {
    label: translate(messagesLocal['affiliate.text']),
    key: 'affiliate',
    renderCell: (deposit: Partial<Deposit>) =>
      coercedGet(deposit, 'account.affiliate.code', '-'),
  },
  {
    label: translate(messagesLocal['account-username.text']),
    key: 'username',
    renderCell: (deposit: Partial<Deposit>) =>
      coercedGet(deposit, 'account.username', '-'),
  },
  {
    label: translate(messages.VIP_TIER),
    key: 'vipLevel',
    renderCell: (deposit: Partial<Deposit>) => {
      const memberLoyaltyLevels = coercedGet(
        deposit,
        'account.memberLoyaltyLevels',
        '-'
      );

      return memberLoyaltyLevels?.map((item: any) => item.name);
    },
  },
  {
    label: translate(messages.MEMBER_MARKER),
    key: 'account.memberLevel.name',
    renderCell: (deposit: Partial<Deposit>) =>
      coercedGet(deposit, 'account.memberLevel.name', '-'),
  },
  {
    label: translate(messages.PAYMENT_METHOD),
    key: 'paymentMethod.name',
    renderCell: (deposit: Partial<Deposit>) =>
      coercedGet(deposit, 'paymentMethod.name', '-'),
  },
  {
    label: `${translate(messages.amount)}`,
    key: 'amount',
    renderCell: (deposit: Partial<Deposit>) => coercedGet(deposit, 'amount', 0),
  },
  {
    label: translate(messages.status),
    key: 'status',
    renderCell: (deposit: Partial<Deposit>) =>
      translate(messages[`${coercedGet(deposit, 'status', '')}`]),
  },
  {
    label: translate(messages.LABELS_REMARKS),
    key: 'remarks',
    renderCell: (deposit: Partial<Deposit>) => deposit.remarks || '',
  },
  {
    label: translate(messages.REQUEST_DATETIME),
    key: 'dateTimeCreated',
    renderCell: (deposit: Partial<Deposit>) =>
      `${moment(deposit.dateTimeCreated).format(DATE_FORMAT)}\n${moment(
        deposit.dateTimeCreated
      ).format('HH:mm:ss')}`,
  },
  {
    label: translate(messages['processing-time.text']),
    key: 'processingTime',
    renderCell: (deposit: Deposit) => getProcessingTime(deposit),
  },
];

export default getCsvHeaders;
