export const BATCH_MEMBER_AFFILIATE = `member(id: $id) {
    id
    affiliate {
      id
      code
    }
  }`;

export const BATCH_USERNAME = `member(id: $id) {
    id
    username
  }`;

export const BATCH_VIP_TIER = `member(id: $id) {
  id
  memberLoyaltyLevels {
    id
    name
    color
    programme {
      id
      name
    }
  }
}`;

export const BATCH_MEMBER_MARKER = `member(id: $id) {
    id
    memberLevel {
      id
      name
      color
    }
  }`;

export const BATCH_DEPOSIT_PAYMENT_METHOD_EXTERNAL = `deposit(id: $id) {
    id
    paymentMethod {
      id
      name
      remarks

      ... on OnlineBankTransferPaymentMethod {
        depositProvider {
          id
          name
        }
      }

      ... on OnlineAlipayPaymentMethod {
        depositProvider {
          id
          name
        }
      }
      ... on OnlineWechatPaymentMethod {
        depositProvider {
          id
          name
        }
      }

      ... on UnionPayPaymentMethod {
        depositProvider {
          id
          name
        }
      }
    }
  }`;

export const BATCH_DEPOSIT_PAYMENT_METHOD_INTERNAL = `deposit(id: $id) {
    id
    paymentMethod {
      id
      name
      remarks
      excludedMemberLevel {
        id
        name
        color
        __typename
      }
      ... on OfflineBankTransferPaymentMethod {
        id
        bank
        accountNumber
        accountName
        branch
        country
        province
        city
        __typename
      }
      ... on AlipayPaymentMethod {
        id
        nickname
        __typename
      }
      ... on WechatPaymentMethod {
        id
        nickname
        __typename
      }
      ... on CryptocurrencyPaymentMethod {
        id
        name
        cryptocurrency
      }
      __typename
    }
  }`;

export const BATCH_MEMBER_TAGS = `member(id: $id) {
    id
    tags {
      id
      name
      color
      description
    }
  }`;

export const BATCH_MEMBER_NOTES = `member(id: $id) {
    id
    notes {
      edges {
        node {
          id
          note
          dateTimeUpdated
        }
      }
    }
  }`;

export const DEPOSIT_AMOUNT = `deposit(id: $id) {
  id
  amount
  paymentMethod {
    id
    __typename
    ... on CryptocurrencyPaymentMethod {
      name
      cryptocurrency
      exchangeRate
      exchangeRateSpread
    }
  }
}`;

export const BATCH_WITHDRAWAL_SERIAL_CODE = `withdrawal(id: $id) {
  id
  serialCode
}`;

export const BATCH_WITHDRAWAL_MEMBER_USERNAME = `withdrawal(id: $id) {
  id
  account{
    username
  }
}`;

export const BATCH_DEPOSIT_MEMBER_USERNAME = `deposit(id: $id) {
  id
  account{
    id
    username
  }
}`;

export const BATCH_DEPOSIT_MEMBER_AFFILIATE = `deposit(id: $id) {
  id
  account{
    id
    affiliate{
      id
      code
    }
  }
}`;

export const BATCH_DEPOSIT_MEMBER_VIP = `deposit(id: $id) {
  id
  account{
    id
    memberLoyaltyLevels{
      id
      name
      color
      programme {
        id
        name
      }
   }
  }
}`;

export const BATCH_DEPOSIT_MEMBERLEVEL_VIP = `deposit(id: $id) {
  id
  account{
    id
    memberLevel {
      id
      name
      color
    }
  }
}`;

export const BATCH_DEPOSIT_MEMBER_NOTES = `deposit(id: $id) {
  id
  account{
    id
    notes {
      edges {
        node {
          id
          note
          dateTimeUpdated
        }
      }
    }
  }
}`;

export const BATCH_DEPOSIT_MEMBER_TAGS = `deposit(id: $id) {
  id
  account{
    id
    tags {
      id
      name
      color
      description
    }
  }
}`;

export const BATCH_WITHDRAWAL_FEE_INDICATOR = `withdrawal(id: $id) {
  id
  handlingFeeApplied
  withdrawalTaxFeeApplied
  promoDeductionFeeApplied
  withdrawalLimitFeeApplied
  handlingFee
  withdrawalTaxFee
  promoDeductionFee
  withdrawalLimitFee
}`;

export const BATCH_WITH_MEMBER_PAYMENT = `withdrawal(id: $id) {
  id
  amount
}`;

export const BATCH_MEMBER_WITHDRAWAL_METHOD = `withdrawal(id: $id) {
  id
  status
  externalStatus
  withdrawalMethod {
    id
    ... on OfflineBankTransferWithdrawalMethod {
      id
    }
    ... on AlipayWithdrawalMethod {
      id
    }
    ... on WechatWithdrawalMethod {
      id
    }
    ... on OfflineBankTransferWithdrawalMethod {
      id
    }
    ... on OnlineBankTransferWithdrawalMethod {
      id
    }
    ... on OnlineWechatWithdrawalMethod {
      id
    }
    ... on OnlineAlipayWithdrawalMethod {
      id
    }
  }
}`;

export const GLOBAL_BATCH_WITHDRAWAL_STATUS = `withdrawal(id: $id) {
  id
  status
  externalStatus
}`;

export const BATCH_WITHDRAWAL_BANK_NAME = `withdrawalMethod(id: $id) {
  id
  ... on OfflineBankTransferWithdrawalMethod {
    bank
  }
}`;

export const BATCH_WITHDRAWAL_METHOD_STATUS = `withdrawalMethod(id: $id) {
  id
  enabled
}`;

export const BATCH_WITHDRAWAL_METHOD_REMARK = `withdrawalMethod(id: $id) {
  id
  remarks
}`;

export const MBR_BATCH_USERNAME = `memberBetRecord(id: $id) {
  id
  member {
    id
    username
  }
}
`;

export const MBR_BATCH_ROUND = `memberBetRecord(id: $id) {
  id
  round
}`;

export const MBR_BATCH_VENDOR_NAME = `memberBetRecord(id: $id) {
  id
  vendor {
    id
    name
    zhName: name(language: ZH)
    enName: name(language: EN)
    __typename
  }
}`;

export const MBR_BATCH_GAME_FIELD = `memberBetRecord(id: $id) {
  id
  game {
    id
    name
    zhName: name(language: ZH)
    enName: name(language: EN)
    category
    subCategory
    __typename
  }
}`;

export const MBR_BATCH_JACKPOT_FIELDS = `memberBetRecord(id: $id) {
  id
  tips
  jackpotContributionBet
  jackpotWin
  cashoutWinloss
  freeSpin
  device
  session
  __typename
}`;

export const BATCH_WITHDRAWAL_AFFILIATE = `withdrawal(id: $id) {
  id
  account{
    id
    affiliate{
      id
      code
    }
  }
}`;

export const BATCH_WITHDRAWAL_USERNAME = `withdrawal(id: $id) {
  id
  account{
    id
    username
  }
}`;

export const BATCH_WITHDRAWAL_VIP_TIER = `withdrawal(id: $id) {
  id
  account{
    id
    memberLoyaltyLevels {
      id
      name
      color
      programme {
        id
        name
      }
    }
  }
}`;

export const BATCH_WITHDRAWAL_MEMBER_MARKER = `withdrawal(id: $id) {
  id
  account{
    id
    memberLevel {
      id
      name
      color
    }
  }
}`;

export const BATCH_WITHDRAWAL_MEMBER_NOTES = `withdrawal(id: $id) {
  id
  account{
    id
    notes {
      edges {
        node {
          id
          note
          dateTimeUpdated
        }
      }
    }
  }
}`;

export const BATCH_WITHDRAWAL_MEMBER_TAGS = `withdrawal(id: $id) {
  id
  account{
    id
    tags {
      id
      name
      color
      description
    }
  }
}`;

export default {};
