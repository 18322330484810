import React from 'react';

import { useConfig } from 'hooks/useConfig';
import { currencies, cryptoCurrencies } from 'constants/currency';

interface Props {
  amount: string | number;
  currency?: string;
}

const MemberPaymentAmount = ({ amount, currency }: Props) => {
  const { renderNumeral, currency: configCurrency } = useConfig();

  const isCrypto = cryptoCurrencies.includes(currency!);

  return (
    <>{`${
      currency ? currencies[currency] : configCurrency.symbol
    } ${renderNumeral(amount, isCrypto)}`}</>
  );
};

export default MemberPaymentAmount;
