import Affiliate from './components/Affiliate';
import AffiliateRequest from './components/AffiliateRequest';
import PayoutReport from './PayoutReport/PayoutReport';
import Request from './Request/Request';
import { AffiliateProvider } from './components/Affiliate/context';
import AffiliateCriteriaNew from './components/Affiliate/AffiliateCriteriaNew';

const Agent: Record<string, any> = {};

Agent.Affiliate = Affiliate;
Agent.AffiliateCriteria = AffiliateCriteriaNew;
Agent.AffiliateRequest = AffiliateRequest;
Agent.AffiliateProvider = AffiliateProvider;
Agent.PayoutReport = PayoutReport;
Agent.Request = Request;

export default Agent;
