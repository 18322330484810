import gql from 'graphql-tag';

export const EMAIL_TRANSACTIONS = gql`
  query emailTransactions(
    $first: Int
    $after: Binary
    $filter: EmailAuditTrailFilterInput
  ) {
    emailAuditTrail(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        node {
          dateTimeCreated
          triggerEventName
          triggerName
          sentTo
          source
          sent
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const EMAIL_ADDRESSES = gql`
  query getAllEmailAdd(
    $first: Int
    $after: Binary
    $filter: EmailAuditTrailFilterInput
  ) {
    emailAuditTrail(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          sentTo
        }
      }
    }
  }
`;
