import { useApolloClient } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import BulkForceChangeWithdrawalPasswordMenuForm from 'components/BulkForceChangeWithdrawalPasswordMenuForm';
import gql from 'graphql-tag';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import messages from './messages';
import { StyledModal } from './styles';

const graphqlString = ({
  userId,
  forceChangeWithdrawalPassword,
}: {
  userId: string;
  forceChangeWithdrawalPassword: boolean;
}) => `
  ${userId}: updateMember(id: "${userId}", input: {
    forceChangeWithdrawalPassword: ${forceChangeWithdrawalPassword}
  })`;

const BulkForceChangeWithdrawalPasswordMenu = ({
  members,
  refetchParams,
}: {
  members: string[];
  refetchParams: Record<string, any>;
}) => {
  const client = useApolloClient();
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const handleSubmit = async (e: any) => {
    const listOfIds = e.username.map((user: { key: string }) => user.key);
    setLoading(true);
    try {
      let str = '';
      listOfIds.map((userId: string) => {
        str += graphqlString({
          userId,
          forceChangeWithdrawalPassword: e.forceChangeWithdrawalPassword,
        });
        return false;
      });
      await client.mutate({
        mutation: gql(`mutation { ${str} }`),
        refetchQueries: [
          {
            query: GET_MEMBERS,
            variables: { ...refetchParams },
          },
        ],
      });
      message.success(
        translate(messages['member-password-change.success-message.text'])
      );
      setLoading(false);
      handleShow();
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Button className="text-black" type="link" onClick={handleShow} block>
        <FormattedMessage
          id="members.force-withdrawal-password.text"
          defaultMessage="Force Withdrawal Password Change"
        />
      </Button>
      {isShow && (
        <StyledModal
          title={
            <FormattedMessage
              id="members.force-withdrawal-password.text"
              defaultMessage="Force Withdrawal Password Change"
            />
          }
          onCancel={handleShow}
          visible={isShow}
          footer={false}
        >
          <BulkForceChangeWithdrawalPasswordMenuForm
            initialValues={{
              username: members,
              forceChangeWithdrawalPassword: false,
            }}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onClose={handleShow}
          />
        </StyledModal>
      )}
    </>
  );
};

export default BulkForceChangeWithdrawalPasswordMenu;
