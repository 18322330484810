import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, Select } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';
import { debounce } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { useInView } from 'react-intersection-observer';
import useTranslate from 'utils/useTranslate';
import styled from 'styled-components';
import messages from 'pages/components/WithdrawalMethods/messages';
import { WITHDRAWAL_PROVIDERS } from './queries';

const StyledIcon = styled(Icon)`
  display: block;
`;

const { Option } = Select;
const InputGroup = Input.Group;

type Rec = Record<string, any>;
type Props = {
  values: Rec;
  formItemLayout: Rec;
  setFieldValue: (e: string, f: any) => void;
  errors: Rec;
  disabled: boolean;
};

const ExternalProviders = ({
  values,
  setFieldValue,
  formItemLayout,
  errors,
  disabled,
}: Props) => {
  const translate = useTranslate();
  let value = '';

  if (
    values.withdrawalProvider &&
    typeof values.withdrawalProvider === 'string'
  ) {
    value = values.withdrawalProvider;
  }
  if (
    values.withdrawalProvider &&
    typeof values.withdrawalProvider !== 'string'
  ) {
    value = values.withdrawalProvider.id;
  }

  const [ref, inView] = useInView();
  const [options, setOptions] = useState<Array<any>>([]);
  const [pageInfo, setPageInfo] = useState({
    endCursor: null,
    hasNextPage: false,
  });
  const [filter, setFilter] = useState({
    name: {
      contains: '',
    },
  });

  const [getProviders, { loading }] = useLazyQuery(WITHDRAWAL_PROVIDERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ withdrawalProviders = {} }) => {
      const newPageInfo = coercedGet(withdrawalProviders, 'pageInfo', {});

      if (newPageInfo.endCursor !== pageInfo.endCursor) {
        const newOptions = coercedGet(withdrawalProviders, 'edges', []);

        setPageInfo(newPageInfo);
        setOptions([...options, ...newOptions]);
      }
    },
  });

  const onGetProviders = useCallback(() => {
    const after = pageInfo.endCursor ? pageInfo.endCursor : null;

    getProviders({
      variables: {
        filter,
        after,
        first: 10,
      },
    });
  }, [getProviders, pageInfo.endCursor, filter]);

  /** Initial component mount */
  useEffect(() => {
    if (!options.length && !filter.name.contains) {
      onGetProviders();
    }
  }, [options, filter.name.contains, onGetProviders]);

  /** Runs on search by typing on the inputbox */
  useEffect(() => {
    if (!options.length && filter.name.contains) {
      onGetProviders();
    }
  }, [options, filter.name.contains, onGetProviders]);

  /** Loads additional data for infinite scroll behaviour */
  useEffect(() => {
    if (inView && !loading) {
      onGetProviders();
    }
  }, [inView, loading, onGetProviders]);

  const handleSearch = (e: string) => {
    setOptions([]);
    setFilter({
      name: {
        contains: e.toUpperCase(),
      },
    });
  };

  return (
    <Form.Item
      label={translate(messages.provider)}
      {...formItemLayout}
      validateStatus={errors.withdrawalProvider && 'error'}
      help={errors.withdrawalProvider || null}
    >
      <InputGroup>
        <Row gutter={8}>
          <Col span={24}>
            <Select
              disabled={disabled}
              value={value}
              defaultValue={value}
              style={{ width: '100%' }}
              onChange={(newValue) => {
                setFieldValue('type', null);
                setFieldValue('withdrawalProvider', newValue);
              }}
              loading={loading}
              onSearch={debounce(handleSearch, 250)}
              showSearch
            >
              <Option value={null as any} disabled>
                <span className="text-gray">
                  {translate(messages.selectone)}
                </span>
              </Option>
              {options.map((provider, index) => (
                <Option value={provider.node.id} key={index}>
                  {provider.node.name}
                </Option>
              ))}
              {pageInfo.hasNextPage ? (
                <Option disabled value="loading-ico">
                  <div ref={ref}>
                    <StyledIcon type="loading" />
                  </div>
                </Option>
              ) : null}
            </Select>
          </Col>
        </Row>
      </InputGroup>
    </Form.Item>
  );
};

export default ExternalProviders;
