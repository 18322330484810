import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Radio } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { BulkProps } from 'interfaces/bulkProps.interface';
import MoreActionsMembersFilter from 'components/MoreActionsMembersFilter';
import { StyledForm } from './styles';

const BulkEditStatusMenuForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: BulkProps) => {
  const translate = useTranslate();
  const validationSchema = Yup.object().shape({
    username: Yup.array()
      .nullable()
      .required(translate(messages['username-field-required-error.text'])),
    status: Yup.string()
      .nullable()
      .required(translate(messages['status-field-required-error.text'])),
  });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors,
        touched,
      }) => (
        <StyledForm onSubmit={handleSubmit}>
          <div className="p-3">
            <div className="mb-3">
              <MoreActionsMembersFilter
                value={values.username}
                placeholder={
                  <FormattedMessage
                    id="enter-username.text"
                    defaultMessage="Enter Username"
                  />
                }
                onChange={(e) => setFieldValue('username', e)}
                validateStatus={
                  errors.username && touched.username ? 'error' : undefined
                }
                help={touched.username ? errors.username : null}
              />
            </div>
            <Form.Item
              className="d-flex justify-content-end align-items-center"
              label={
                <FormattedMessage id="status.text" defaultMessage="Status" />
              }
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 14 }}
              validateStatus={
                errors.status && touched.status ? 'error' : undefined
              }
              help={touched.status ? errors.status : null}
            >
              <Radio.Group
                name="status"
                onChange={handleChange}
                value={values.status || ''}
              >
                <Radio value="ENABLED">
                  <FormattedMessage
                    id="enabled.text"
                    defaultMessage="Enabled"
                  />
                </Radio>
                <Radio value="DISABLED">
                  <FormattedMessage
                    id="disabled.text"
                    defaultMessage="Disabled"
                  />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="bt-1 p-3 text-right">
            <Button className="mr-2" onClick={onClose} loading={isLoading}>
              <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
            </Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              <FormattedMessage
                id="save-changes.text"
                defaultMessage="Save changes"
              />
            </Button>
          </div>
        </StyledForm>
      )}
    </Formik>
  );
};

export default BulkEditStatusMenuForm;
