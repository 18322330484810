import React, { useState } from 'react';
import Layout from 'components/Layout';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { useFilterValues } from 'contexts/Filters';
import SavedFilterSelect from 'components/SavedFilterSelect';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { QuickSearchIds } from 'components/QuickSearchFilter/QuickSearchFilter';
import SearchSettingsForm from '../SearchSettingsForm';
import FilterItems from '../FilterItems';

type Props = {
  open: boolean;
};

const SideBar: React.FC<Props> = ({ open }) => {
  const translate = useTranslate();
  const [key, setKey] = useState(0);
  const {
    savedFilterId,
    handleSavedFilterIdChange,
    handleFilterChange,
    filters,
    onFilterChange,
  } = useFilterValues();

  /**
   * Re-renders the <SavedFilterSelect/> to update the list of filters
   */
  const handleUpdateKey = () => {
    setKey((prev) => prev + 1);
  };

  return (
    <Layout.Sidebar
      sidebarStatus={open}
      quickSearch={{
        filters: { ...filters, member: null },
        contextKey: 'member-interaction-log-report',
        onFilterChange,
        searchSettingsForm: SearchSettingsForm,
        quickSearchId: QuickSearchIds.MEMBER_INTERACTION_LOG_REPORT,
        onFiltersUpdate: () => handleUpdateKey(),
        hideQuickSearchFilter: true,
        isNext: true,
      }}
    >
      <>
        <FilterItem label={translate(messages.QUICK_FILTER)}>
          <SavedFilterSelect
            placeHolder={translate(messages.SELECT_SAVED_CUSTOM_FILTER)}
            value={savedFilterId}
            onChange={(val) => handleSavedFilterIdChange(val)}
            context="member-interaction-log-report"
            key={key}
          />
        </FilterItem>

        <FilterItems filters={filters} onFilterChange={handleFilterChange} />
      </>
    </Layout.Sidebar>
  );
};

export default SideBar;
