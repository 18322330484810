import { SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Select } from 'antd';
import { ContextProps } from 'contexts/Filter';
import { CREATE_CUSTOM_FILTER } from 'graphql/mutations/customFilter.mutation';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { StyledModal } from 'styles';
import { CustomFilter } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import SavedFilterList from './components/SavedFilterList';
import SaveSearchForm from './components/SaveSearchForm';

const { Option } = Select;

type Props = {
  filterSettings: React.ElementType;
  filter: { [key: string]: any };
  context: string;
  customFilters: ContextProps['customFilters'];
};

const FilterHeader: React.FC<Props> = ({
  filterSettings,
  filter,
  context,
  customFilters,
}) => {
  const translate = useTranslate();
  const {
    filters,
    loading,
    refetch,
    activeFilterId,
    setActiveFilterId,
  } = customFilters;

  const [showSave, setShowSave] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const handleShowSave = () => setShowSave(!showSave);
  const handleShowEdit = () => setShowEdit(!showEdit);

  const [saveSearch, { loading: saving }] = useMutation(CREATE_CUSTOM_FILTER, {
    onCompleted: () => {
      refetch();
      message.success(translate(messages['search-saved.text']));
      handleShowSave();
    },
  });

  const handleSelect = (id: string) => {
    setActiveFilterId(id);
    handleShowEdit();
  };

  return (
    <>
      <div className="d-flex justify-content-space-between mb-3">
        <span className="fs-14">
          <SearchOutlined className="mr-2" />
          <FormattedMessage id="search.text" defaultMessage="Search" />
        </span>
        <div>
          <ALink onClick={handleShowSave}>
            <FormattedMessage
              id="save-search.text"
              defaultMessage="Save Search"
            />
          </ALink>
          {showSave && (
            <StyledModal
              style={{ maxWidth: 400 }}
              title={
                <FormattedMessage
                  id="save-search.text"
                  defaultMessage="Save Search"
                />
              }
              onCancel={handleShowSave}
              footer={false}
              visible
            >
              <div className="px-4 py-3">
                <SaveSearchForm
                  saveSearch={saveSearch}
                  context={context}
                  filter={filter}
                  loading={saving}
                />
              </div>
            </StyledModal>
          )}

          <span style={{ color: 'rgba(0, 0, 0, 0.35)' }}> |</span>

          <ALink className="ml-1" onClick={handleShowEdit}>
            <SettingOutlined />
          </ALink>
          {showEdit && (
            <StyledModal
              title={
                <FormattedMessage
                  id="search-settings.text"
                  defaultMessage="Search Settings"
                />
              }
              onCancel={handleShowEdit}
              footer={false}
              visible
            >
              <div className="px-3">
                <SavedFilterList
                  handleSelect={handleSelect}
                  customFilters={customFilters}
                  FilterSettings={filterSettings}
                />
              </div>
            </StyledModal>
          )}
        </div>
      </div>
      <FilterItem label={translate(messages.QUICK_FILTER)}>
        <Select
          showSearch
          value={activeFilterId}
          placeholder={translate(messages.SELECT_SAVED_CUSTOM_FILTER)}
          onChange={(value: string | undefined) => {
            setActiveFilterId(value);
          }}
          loading={loading}
          allowClear
          style={{ width: '100%' }}
          filterOption={(input: string, option: any) => {
            const target = coercedGet(option, 'props.children', '');
            return target
              ? target.toLowerCase().indexOf(input.toLowerCase()) >= 0
              : false;
          }}
        >
          {(filters as any[]).map((customFilter: CustomFilter) => (
            <Option key={customFilter?.id} value={customFilter?.id}>
              {customFilter?.name}
            </Option>
          ))}
        </Select>
      </FilterItem>
    </>
  );
};

export default FilterHeader;
