import FiltersProvider from 'contexts/Filters';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import { GET_IP_SUMMARY_REPORTS } from 'graphql/queries/ipAddressSummary.query';
import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import uuid from 'uuid';
import IPAddressSummaryScreen from './IPAddressSummaryScreen';

const IPAddressSummary = () => {
  const [key, setKey] = useState('');
  const { getTab } = useScreenTabV2();
  const { filter = {} } = getTab('ip-address-summary');

  const refreshPage = () => setKey(uuid.v1());

  const defaultFilters = {
    ipAddress: null,
    membersCount: null,
  };
  const initialFilters = { ...defaultFilters, ...filter };

  return (
    <SpreadsheetProvider
      options={{
        query: GET_IP_SUMMARY_REPORTS,
        edgesPath: 'ipAddressSummaryReport.edges',
      }}
    >
      <FiltersProvider
        initialValues={initialFilters}
        defaultValues={defaultFilters}
        tabId="ip-address-summary"
      >
        <IPAddressSummaryScreen key={key} refreshPage={refreshPage} />
      </FiltersProvider>
    </SpreadsheetProvider>
  );
};

export default IPAddressSummary;
