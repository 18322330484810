import styled from 'styled-components';
import { Layout } from 'antd';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  background: 'white' .ant-layout-sider-children {
    overflow: hidden;
  }
`;

export default {};
