import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { isEmpty } from 'lodash';
import { AffiliateProgrammesFilterInput } from 'types/graphqlTypes';

type filterConditionsProps = {
  filters: AffiliateProgrammesFilterInput;
  onFilterChange: (e: AffiliateProgrammesFilterInput) => void;
};

const FilterConditions = ({
  filters,
  onFilterChange,
}: filterConditionsProps) => {
  const [localStateFilter, setLocalStateFilter] = useState<
    AffiliateProgrammesFilterInput
  >({});

  useEffect(() => {
    setLocalStateFilter(filters || {});
  }, [filters, setLocalStateFilter]);

  const handleRemoveProgrammeName = (
    value: string,
    e: { preventDefault: () => void }
  ) => {
    e.preventDefault();
    const { name } = filters;
    const data = name?.in?.filter((item: string) => item !== value);
    const newProgrammeName = {
      in: data,
    };
    onFilterChange({
      ...filters,
      name: data?.length ? newProgrammeName : null,
    });
  };

  const handleRemoveStatus = (
    value: string,
    e: { preventDefault: () => void }
  ) => {
    e.preventDefault();
    const { status } = filters;
    const data = status?.in?.filter((item: string) => item !== value);
    const newStatus = {
      in: data,
    };
    onFilterChange({
      ...filters,
      status: data?.length ? newStatus : null,
    });
  };

  const handleRemoveDefault = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onFilterChange({
      ...filters,
      default: null,
    });
  };

  const handleRemoveNegativeCarry = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onFilterChange({
      ...filters,
      negativeCarryOverEnabled: null,
    });
  };

  return (
    <>
      {localStateFilter?.name?.in?.map((item: string, key: number) => (
        <Tag
          key={key}
          closable
          onClose={(e: { preventDefault: () => void }) =>
            handleRemoveProgrammeName(item, e)
          }
        >
          {item}
        </Tag>
      ))}

      {localStateFilter?.status?.in?.map((item: string, key: number) => (
        <Tag
          key={key}
          closable
          onClose={(e: { preventDefault: () => void }) =>
            handleRemoveStatus(item, e)
          }
        >
          {item}
        </Tag>
      ))}

      {!isEmpty(localStateFilter.default) && (
        <Tag
          closable
          onClose={(e: { preventDefault: () => void }) =>
            handleRemoveDefault(e)
          }
        >
          Default Program: {localStateFilter?.default?.in ? 'Yes' : 'No'}
        </Tag>
      )}

      {!isEmpty(localStateFilter.negativeCarryOverEnabled) && (
        <Tag
          closable
          onClose={(e: { preventDefault: () => void }) =>
            handleRemoveNegativeCarry(e)
          }
        >
          Negative Carry:{' '}
          {localStateFilter?.negativeCarryOverEnabled?.in ? 'Yes' : 'No'}
        </Tag>
      )}
    </>
  );
};

export default FilterConditions;
