import React, { useState } from 'react';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { Select } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { checkPartial } from 'utils/partialUtils';
import useTranslate from 'utils/useTranslate';
import { MembersConnectionsEdge } from 'types/graphqlTypes';
import { intersection, uniq } from 'lodash';
import esGet from 'utils/esGet';
import { USERNAMES } from './queries';
import { StyledLabel, StyledSpan, StyledSelect } from '../../styles';

interface Props {
  onChange: (e: Record<string, object> | null) => void;
  value: {
    username?: Record<string, string[]>;
  };
  disabled: boolean;
}

export const UsernameFilter: React.FC<Props> = ({
  onChange,
  value,
  disabled,
}) => {
  const translate = useTranslate();
  const [opts, setOpts] = useState<string[]>([]);
  const [input, setInput] = useState<string>('');
  const debouncedInput = useDebounce(input, 500);
  const [isPartial, setIsPartial] = useState<boolean>(true);

  const { data: memberData } = useQuery(USERNAMES);
  const nodes = esGet(memberData?.members?.edges, []);
  const initialUsernames = nodes.map(
    ({ node }: MembersConnectionsEdge) => node.username
  );

  const [loadUsernames, { loading }] = useLazyQuery(USERNAMES, {
    fetchPolicy: 'no-cache',
    variables: {
      first: 10,
      filter: {
        username: {
          contains: debouncedInput,
        },
      },
    },
    onCompleted: (data) => {
      const {
        members: { edges = [] },
      } = data;

      const usernames = edges.map(
        ({ node }: MembersConnectionsEdge) => node.username
      );

      const uniqueUsernames: string[] = uniq(usernames);

      setOpts(uniqueUsernames);
    },
  });

  const values = value?.username?.in ?? [];

  const [partial, setPartial] = useState('');

  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange({ in: e });
    } else {
      onChange(null);
    }

    setPartial('');
    setInput('');
  };

  const hasPartial = checkPartial('username', value);

  const multiSearch = (searchText: string) => {
    setIsPartial(false);

    const transformSearchStr = searchText.replace(/\s+/g, '');
    const searchArray = transformSearchStr.split(',').filter((e) => e);

    const matchedUsernames = intersection(initialUsernames, searchArray);

    handleChange(matchedUsernames);
  };

  return (
    <div className="mt-1">
      <div className="d-flex justify-content-between">
        <div>
          <StyledLabel>
            <FormattedMessage id="username.text" defaultMessage="Username" />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        data-testid="member-mgmt-username-filter"
        mode="multiple"
        value={values}
        showSearch
        placeholder={translate(messages.ENTER_USERNAME)}
        onChange={handleChange}
        filterOption={false}
        loading={loading}
        onSearch={(text: string) => {
          if (text.includes(',')) {
            multiSearch(text);
          } else {
            setIsPartial(true);
            setInput(text);
            setPartial(text);
          }
        }}
        disabled={disabled}
        onFocus={() => setInput('')}
        onBlur={() => setInput('')}
        onDropdownVisibleChange={(open: boolean) => {
          if (open && !opts.length) loadUsernames();
        }}
        defaultActiveFirstOption
      >
        {!hasPartial && partial.length && isPartial && (
          <Select.Option
            key={11}
            value={`${translate(messages.PARTIAL)}: ${partial}`}
            label={`${translate(messages.PARTIAL)}: ${partial}`}
          >
            <FormattedMessage id="partial.text" defaultMessage="Partial" />:{' '}
            {partial}
          </Select.Option>
        )}

        {opts.map((username, index) => (
          <Select.Option key={index} value={username}>
            {username}
          </Select.Option>
        ))}
      </StyledSelect>
    </div>
  );
};
