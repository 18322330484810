/* eslint-disable linebreak-style */
import gql from 'graphql-tag';

export const POSTAL_CODES = gql`
  query PostalCodes($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          address {
            postCode
          }
        }
      }
    }
  }
`;
