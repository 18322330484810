import React from 'react';
import { FormattedMessage } from 'react-intl';
import gameTypes from 'constants/gameTypes';
import { Checkbox } from 'antd';

const { Group }: any = Checkbox;

const GameTypeSelect = ({
  state,
  setState,
}: {
  state: {
    gameTypeColumns: Array<any>;
  };
  setState: (e: Record<string, any>) => void;
}) => {
  const gameTypeOptions = gameTypes.map(
    (gt: { type: string; name: string }) => ({
      label: gt.name,
      value: gt.type,
      disabled:
        state.gameTypeColumns.length === 1 &&
        state.gameTypeColumns.includes(gt.type),
    })
  );

  return (
    <div className="my-3">
      <div className="mb-2">
        <FormattedMessage
          id="reports.game-type.text"
          defaultMessage="Game Type"
        />
      </div>
      <Group
        options={gameTypeOptions}
        value={state.gameTypeColumns}
        onChange={(values: any) =>
          setState({
            ...state,
            gameTypeColumns: values,
          })
        }
      />
    </div>
  );
};

export default GameTypeSelect;
