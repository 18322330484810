import moment from 'moment';
import removeNull from 'utils/removeNull';
import { initQualificationCriteria } from 'pages/components/MemberLoyaltyManagement/components/CreateProgramme/components/TierSettings/constants';
import coercedGet from 'utils/coercedGet';
import { allGameTypes } from 'constants/gameTypes';
import { MemberLoyaltyProgramme, MemberLoyaltyLevel } from 'types/graphqlTypes';

// export const impartSameAsQualification = (program: any) => ({
//   ...program,
//   levels: program.levels.map((level: any) => ({
//     ...level,
//     sameAsQualificationCriteria: isEqual(
//       level.qualificationCriteria,
//       level.maintenanceCriteria
//     ),
//   })),
// });

export function getEffectiveDateRange(startTime: any, endTime: any) {
  return `${moment(startTime).format('YYYY-MM-DD')} - ${moment(endTime).format(
    'YYYY-MM-DD'
  )}`;
}

export const refetchVariables = (
  page: any,
  filters: any,
  filterToTransform: any
) => ({
  first: page.first,
  after: page.after,
  filter: removeNull(
    {
      ...filters,
      validityStartDate: removeNull(
        {
          gte:
            filterToTransform.date.gte &&
            moment(filterToTransform.date.gte).format('YYYY-MM-DD'),
        },
        true
      ),
      validityEndDate: removeNull(
        {
          lte:
            filterToTransform.date.lte &&
            moment(filterToTransform.date.lte).format('YYYY-MM-DD'),
        },
        true
      ),
    },
    true
  ),
});

export const processDepositOnlyCriteria = (rawNode: MemberLoyaltyProgramme) => {
  const newLevels = [...rawNode?.levels!].reduce(
    (acc: any, curr: MemberLoyaltyLevel) => {
      const qualCriteria = coercedGet(
        curr,
        'qualificationCriteria',
        initQualificationCriteria
      );

      if (qualCriteria.type === 'DEPOSIT') {
        const newQual = {
          type: 'BASE',
          and: [
            {
              type: 'DEPOSIT',
              amount: coercedGet(qualCriteria, 'amount', 0),
            },
            {
              type: 'EFFECTIVE_BET',
              amount: 0,
              gameTypes: [...allGameTypes],
            },
          ],
        };
        const newTier = { ...curr, qualificationCriteria: newQual };

        return [...acc, newTier];
      }

      return [...acc, curr];
    },
    []
  );

  return { ...rawNode, levels: newLevels };
};

export const processUpgradePayout = (rawNode: MemberLoyaltyProgramme) => {
  const newLevels = [...rawNode?.levels!].reduce(
    (acc: Array<MemberLoyaltyLevel>, curr) => {
      const newUpgradePayout = coercedGet(curr, 'upgradePayout', {
        amount: 0,
        turnover: 0,
        limit: 1,
      });

      const newTier = { ...curr, upgradePayout: newUpgradePayout };

      return [...acc, newTier];
    },
    []
  );

  return { ...rawNode, levels: newLevels };
};
