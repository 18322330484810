import gql from 'graphql-tag';

export const MEMBER_TAGS = gql`
  query members($filter: MembersFilterInput) {
    members(filter: $filter) {
      edges {
        node {
          id
          username
          realName
          tags {
            id
            name
          }
        }
      }
    }
  }
`;
