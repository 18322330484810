import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import isRow from 'utils/isRow';
import messages from '../messages';
import { StyledRootDiv } from './styles';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  width: 300px;
`;

const StyledParentDiv = styled.div<any>`
  width: ${(props) => `${300 * props.options.second.length}px`};
  padding-bottom: 100px;
  overflow: hidden;
`;

const reportsArray = [
  'DAILY_OPERATING_REPORT',
  'MEMBER_BET_RECORDS',
  'BALANCE_TRANSACTION_REPORTS',
  'PROMO_PAYOUT_RECORDS',
  'REBATES_REPORTS',
  'LOTTERY_REPORTS',
  'MEMBER_GAME_REPORTS',
  'BALANCE_SERVICE_RECORD_REPORTS',
  'MEMBER_PROMO_HISTORY_RECORDS',
  'MEMBER_SUMMARY_REPORT',
  'EU_REPORTS',
];

const ReportsTree = () => {
  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const [checked, setChecked] = useState({
    all: {
      first: false,
      ...(() => {
        const temp = {};
        reportsArray.forEach((j) => {
          temp[`REPORTS_${j}`] = false;
          temp[`REPORTS_${j}_LIST`] = false;
        });
        return temp;
      })(),
    },
    indeterminate: {
      first: false,
      ...(() => {
        const temp = {};
        reportsArray.forEach((a) => {
          temp[`REPORTS_${a}`] = false;
        });
        return temp;
      })(),
    },
    currentList: {
      ...(() => {
        const temp = {};
        reportsArray.forEach((c) => {
          temp[`REPORTS_${c}`] = [];
        });
        return temp;
      })(),
    },
  });

  const defaultValues = Object.values(ALL_PERMISSIONS.ALL_REPORTS);

  const levels = [
    ['first', 0],
    ['second', 1],
    ['third', 2],
  ];

  const options: Record<string, any> = levels.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr[0]}`]: Object.entries(ALL_PERMISSIONS.ALL_REPORTS)
        .filter((e) => (e[1].match(/:/g) || []).length === curr[1])
        .map((e) => e[0]),
    }),
    {}
  );

  const handleFirstLevelChange = (e: any) => {
    const list = e.target.checked ? defaultValues : [];
    setFieldValue('permissions[ALL_REPORTS]', list);
  };

  const handleSecondLevelChange = (e: any) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_REPORTS];
    const secondLevelValues = defaultValues.filter(
      (v) =>
        (v.match(/:/g) || []).length >= 1 &&
        v.includes(ALL_PERMISSIONS.ALL_REPORTS[name])
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, ...secondLevelValues];
    } else {
      list = targetPermissions.filter((p) => !secondLevelValues.includes(p));
    }

    setFieldValue(
      'permissions[ALL_REPORTS]',
      uniq(list.length > 1 ? ['REPORTS', ...list] : [])
    );
  };

  const handleThirdLevelChange = (e: any) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_REPORTS];

    const unlistedName = ALL_PERMISSIONS.ALL_REPORTS[name.replace('_LIST', '')];

    const thirdLevelValues = defaultValues.filter(
      (v) => (v.match(/:/g) || []).length >= 2 && v.includes(unlistedName)
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, unlistedName, thirdLevelValues[0]];
    } else {
      list = targetPermissions.filter(
        (p) => !thirdLevelValues.includes(p) && p !== unlistedName
      );
    }

    setFieldValue(
      'permissions[ALL_REPORTS]',
      uniq(list.length >= 2 ? ['REPORTS', ...list] : []).filter(Boolean)
    );
  };

  const handleOnChange = (list: any[], name: string) => {
    const stateName = name.replace('_GRP', '');

    const secondLevelPermissionLookup = ALL_PERMISSIONS.ALL_REPORTS[stateName];
    const thirdLevelPermissionLookup =
      ALL_PERMISSIONS.ALL_REPORTS[`${stateName}_LIST`];

    const thirdLevelPermissions = Object.values(
      ALL_PERMISSIONS.ALL_REPORTS
    ).filter((p) => (p.match(/:/g) || []).length === 2);

    const targetPermissions = [...values.permissions.ALL_REPORTS];

    const templatePermissions = thirdLevelPermissions.filter(
      (p) =>
        p.includes(secondLevelPermissionLookup) &&
        p !== secondLevelPermissionLookup &&
        p !== thirdLevelPermissionLookup
    );

    setFieldValue(
      'permissions[ALL_REPORTS]',
      uniq([
        ...targetPermissions.filter((p) => !templatePermissions.includes(p)),
        'REPORTS',
        secondLevelPermissionLookup,
        thirdLevelPermissionLookup,
        ...list,
      ]).filter(Boolean)
    );
  };

  const availablePermissions = coercedGet(
    values,
    'permissions.ALL_REPORTS',
    []
  );
  useEffect(() => {
    const permissionMatch =
      availablePermissions.length ===
      Object.values(ALL_PERMISSIONS.ALL_REPORTS).length;

    const thirdLevelPermissions = availablePermissions.filter(
      (p: any) => (p.match(/:/g) || []).length === 2
    );

    const secondLevelKeys = (() => [
      ...reportsArray.map((q) => [`REPORTS_${q}`, `REPORTS:${q}`, 2]),
    ])();

    const thirdLevelChecks = secondLevelKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr[0]}`]:
          thirdLevelPermissions.filter((p: any) => p.includes(`${curr[1]}`))
            .length === curr[2],
      }),
      {}
    );

    const allChecks = (() => {
      const obj = {};
      reportsArray.map((q) => {
        obj[`REPORTS_${q}`] =
          permissionMatch || thirdLevelChecks[`REPORTS_${q}`];
        return null;
      });
      return obj;
    })();

    setChecked({
      all: {
        first: permissionMatch,
        ...(() => {
          const temp = {};

          reportsArray.forEach((j) => {
            temp[`REPORTS_${j}`] = allChecks[`REPORTS_${j}`];
            temp[`REPORTS_${j}_LIST`] =
              thirdLevelPermissions.includes(`REPORTS:${j}:LIST`) ||
              allChecks[`REPORTS_${j}`];
          });
          return temp;
        })(),
      },
      indeterminate: {
        first: availablePermissions.length && !permissionMatch,
        ...(() => {
          const temp = {};
          reportsArray.forEach((a) => {
            temp[`REPORTS_${a}`] =
              thirdLevelPermissions.filter((p: any) =>
                p.includes(`REPORTS:${a}`)
              ).length && !thirdLevelChecks[`REPORTS_${a}`];
          });
          return temp;
        })(),
      },
      currentList: {
        ...(() => {
          const temp = {};
          reportsArray.forEach((k) => {
            temp[`REPORTS_${k}_GRP`] = thirdLevelPermissions.filter(
              (p: any) =>
                p.includes(`REPORTS:${k}`) &&
                p !== `REPORTS:${k}` &&
                p !== `REPORTS:${k}:LIST`
            );
          });
          return temp;
        })(),
      },
    });
  }, [availablePermissions]);

  const translate = useTranslate();

  return (
    <StyledRootDiv style={{ overflow: 'auto' }}>
      <StyledParentDiv options={options}>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={checked.all.first}
              indeterminate={checked.indeterminate.first}
              onChange={handleFirstLevelChange}
            >
              <StyledText size={16}>
                {translate(messages[ALL_PERMISSIONS.ALL_REPORTS.REPORTS])}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <div className="w-100">
          <div className="mt-4 d-flex ml-3">
            {options.second.map((s: any) => {
              // hide this permission if not row
              if (s === 'REPORTS_MEMBER_PROMO_HISTORY_RECORDS' && !isRow) {
                return null;
              }
              return (
                <StyledDiv>
                  <Checkbox
                    key={s}
                    name={s}
                    checked={checked.all[s]}
                    indeterminate={checked.indeterminate[s]}
                    onChange={handleSecondLevelChange}
                  >
                    <StyledText size={14}>{translate(messages[s])}</StyledText>
                  </Checkbox>
                  <div className="ml-4 mt-2">
                    {s.includes('EU_REPORTS') || (
                      <Checkbox
                        key={`${s}_LIST`}
                        name={`${s}_LIST`}
                        checked={checked.all[`${s}_LIST`]}
                        onChange={handleThirdLevelChange}
                      >
                        <StyledText size={14}>
                          {translate(messages.LIST)}
                        </StyledText>
                      </Checkbox>
                    )}
                    <Checkbox.Group
                      className="w-100 ml-2"
                      onChange={(v) => handleOnChange(v, `${s}_GRP`)}
                      value={checked.currentList[`${s}_GRP`]}
                    >
                      <div className="ml-4">
                        {options.third
                          .filter(
                            (t: any) => t.includes(s) && !t.includes('_LIST')
                          )
                          .map((t: any) => (
                            <StyledDiv className="mt-2">
                              <Checkbox
                                key={ALL_PERMISSIONS.ALL_REPORTS[t]}
                                value={ALL_PERMISSIONS.ALL_REPORTS[t]}
                              >
                                <StyledText size={14}>
                                  {translate(messages[t.replace(`${s}_`, '')])}
                                </StyledText>
                              </Checkbox>
                            </StyledDiv>
                          ))}
                      </div>
                    </Checkbox.Group>
                  </div>
                </StyledDiv>
              );
            })}
          </div>
        </div>
      </StyledParentDiv>
    </StyledRootDiv>
  );
};

export default ReportsTree;
