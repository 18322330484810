import React from 'react';
import { globalTranslate } from 'utils/globalMessages';
import { useIntl } from 'react-intl';

const AutoPayoutColumn = ({ value }: { value: string }) => {
  const intl = useIntl();
  return (
    <span data-testid="payout-method.text">
      {value ? <>{globalTranslate(value, intl)}</> : <>-</>}
    </span>
  );
};

export default AutoPayoutColumn;
