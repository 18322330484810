import styled from 'styled-components';

export const StyledTableContainer = styled.div`
  overflow: auto;

  .ant-table {
    /* line-height: 1.14; */
    font-size: 13px;
  }
  .ant-table-fixed .ant-table-thead {
    background: #fafafa !important;
  }
  .ant-table-header {
    background-color: #fafafa !important;
  }

  .ant-table-body {
    max-height: calc(100vh - 273px) !important;
  }

  .ant-table-row tr td {
    height: 52px !important;
  }

  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }

  tbody {
    height: 509px !important;
    max-height: 509px !important;

    tr {
      height: 52px !important;
      max-height: 52px !important;

      td {
        height: 52px !important;
        max-height: 52px !important;
      }
    }
  }

  .ant-table-small
    > .ant-table-content
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td {
    padding: 1px 3px !important;
    transform: translateX(16px);
    /* width: 108px; */
  }

  .action-icon {
    .b {
      fill: #1890ff;
    }
  }

  .action-icon-disabled {
    .b {
      fill: rgba(0, 0, 0, 0.38);
      cursor: not-allowed;
    }
  }
`;

export const StyledCenter = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {};
