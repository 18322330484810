import { useIntl } from 'react-intl';
import messages from 'messages';

/*
 *  Only put here the generic translations, not specific to only one feature
 * */

export const globalTranslate = (
  field: string,
  intl: Record<string, any>,
  opts?: Record<string, any>
) => intl.formatMessage(messages[field], opts);

export const useGlobalTranslate = (
  field: string,
  opts?: Record<string, any>
) => {
  const intl = useIntl();
  return intl.formatMessage(messages[field], opts);
};
