import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import copyToClipboard from 'utils/copyToClipboard';
import moment from 'moment';
import CustomTag from 'components/CustomTag';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';
import MemberMarkerButton from 'components/MemberMarkerButton';
import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import { DATE_FORMAT } from 'constants/date';

import MemberProfile from './components/MemberProfile';
import messages from '../../../../messages';

const MemberInformation = (props: {
  readOnly: boolean;
  deposit: Record<string, any>;
}) => {
  const { deposit, readOnly } = props;
  const { account = {}, dateTimeCreated, amount } = deposit;
  const {
    tags = [],
    memberLoyaltyLevels,
    realName,
    lastLoginIpAddress,
  } = account;
  const { Text } = Typography;

  const { notes = {} } = account;
  const { edges = [] } = notes;
  const translate = useTranslate();

  return (
    <>
      <div className="menu-header p-2">{translate(messages.memberInfo)}</div>
      <div className="d-flex p-2 bb-1">
        <div>
          <Text type="secondary">{translate(messages.requester)}</Text>
        </div>
        <div className="flex-1 text-primary text-right">
          <MemberProfile member={account} />
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">{translate(messages.VIP_LEVEL)}</Text>
        </div>
        <div className="ml-auto">
          {memberLoyaltyLevels ? (
            <MemberLoyaltyTagList loyaltyLevels={memberLoyaltyLevels || []} />
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className="d-flex  bb-1 p-2">
        <div>
          <Text type="secondary">{translate(messages.requestTime)}</Text>
        </div>
        <div className="flex-1 text-right">
          {dateTimeCreated ? (
            <>
              {moment(dateTimeCreated).format(`${DATE_FORMAT} (ddd)`)}
              <br />
              {moment(dateTimeCreated).format('HH:mm:ss')} -{' '}
              {moment(dateTimeCreated).fromNow()}
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">{translate(messages.requestAmount)}</Text>
        </div>
        <div data-testid="amount" className="flex-1 text-right">
          {amount}{' '}
          <ALink disabled={readOnly} onClick={() => copyToClipboard(amount)}>
            <CopyOutlined />
          </ALink>
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">{translate(messages.memberLevel)}</Text>
        </div>
        <div className="flex-1 text-right">
          <MemberMarkerButton
            memberMarker={account.memberLevel.name}
            color={account.memberLevel.color}
          />
        </div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">{translate(messages.name)}</Text>
        </div>
        <div className="flex-1 text-right">{realName || '-'}</div>
      </div>
      <div className="d-flex bb-1 p-2">
        <div>
          <Text type="secondary">{translate(messages.loginIp)}</Text>
        </div>
        <div className="flex-1 text-right">{lastLoginIpAddress || '-'}</div>
      </div>
      <div className="bb-1 p-2">
        <div className="mb-2">
          <Text type="secondary">
            {translate(messages.memberDetailsRemark)}
          </Text>
        </div>
        {edges.length ? edges[0].node.note : '-'}
      </div>
      <div className="p-2">
        <div className="mb-2">
          <Text type="secondary">{translate(messages.labels)}</Text>
        </div>
        <div>
          {tags.length
            ? tags.map((tag: { name: string; color: string }) => (
                <CustomTag
                  key={tag.name}
                  className="mr-0 mt-1"
                  color={tag.color}
                  variant="memberTag"
                >
                  {tag.name}
                </CustomTag>
              ))
            : '-'}
        </div>
      </div>
    </>
  );
};

export default MemberInformation;
