import * as React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DynamicObj } from 'interfaces/user.interface';
import FilterItems from '../FilterItems';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

interface ISearchSettingsForm {
  onClose: Function;
  initialValues: any;
  onSubmit: (fields: any) => void;
  isLoading: boolean;
}

export const SearchSettingsForm = ({
  onClose,
  initialValues,
  onSubmit,
  isLoading,
}: ISearchSettingsForm) => {
  const [values, setValues] = React.useState(initialValues);

  const handleChange = ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => {
    setValues((prev: DynamicObj) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <StyledForm>
      <Row className="mb-3">
        <Col span={12}>
          <FilterItems
            onRawFilterChange={handleChange}
            filters={values}
            isLoading={isLoading}
            isCustomFilter
          />
        </Col>
      </Row>

      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose as any} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>{' '}
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={() => onSubmit(values)}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};
