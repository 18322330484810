import React, { useState } from 'react';

export const SelectedRowsContext = React.createContext<any>(null);

type Props = {
  children: React.ReactNode;
  initialValues?: Record<string, any>[];
};

// eslint-disable-next-line react/prop-types
const SelectedRowsProvider = ({ children, initialValues = [] }: Props) => {
  const [selectedRows, setSelectedRows] = useState(initialValues);
  const [selectedRowKeys, setSelectedRowKeys] = useState(initialValues);
  return (
    <SelectedRowsContext.Provider
      value={{
        selectedRows,
        setSelectedRows,
        selectedRowKeys,
        setSelectedRowKeys,
      }}
    >
      {children}
    </SelectedRowsContext.Provider>
  );
};

export default SelectedRowsProvider;

export const useSelectedRows = () => React.useContext(SelectedRowsContext);
