import { useApolloClient } from '@apollo/react-hooks';
import { useState, useCallback } from 'react';
import { Member } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import gql from 'graphql-tag';

export const GET_MEMBER_INFO = gql`
  query MemberInfo($id: ID!) {
    member(id: $id) {
      id
      lastLoginDateTime
      mobileNumber
      qqId
      email
      currency
      wechatId
      dateOfBirth
      registrationDateTime
      totalReadMessagesCount
      firstName
      countryCode
      title
      phoneNumber
      preferredLanguage
      affiliateId
      acceptedPrivacyPolicy {
        id
        type
        version
      }
      acceptedTermsAndConditions {
        id
        type
        version
      }
      tags {
        id
        name
        color
      }
      memberLevel {
        id
        name
        color
      }
      memberLoyaltyLevels {
        id
        name
        color
        programme {
          id
          name
          serialCode
        }
      }
      status
      forceVerification
      forceVerificationURL
      lastName
      gender
      realName
      address {
        building
        country
        locality
        postCode
        premise
        province
        stateDistrict
        street
        address
      }
      brandId
      platformId
      messages {
        totalCount
      }
      linkedPlatforms {
        company
        brandId
      }
      netellerDisabled
      skrillDisabled
      netellerClosedLoopDisabled
      skrillClosedLoopDisabled
      initialEmail
      btagsNext {
        btag
        btag2
        btag3
        btag4
        btag5
      }
    }
  }
`;

export default (member: string) => {
  const [totalMessages, setTotalMessages] = useState(0);
  const [localMemberData, setLocalMemberData] = useState<
    Member | Record<string, any>
  >({});
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  const fetchMemberData = useCallback(() => {
    setLoading(true);

    client
      .query({
        query: GET_MEMBER_INFO,
        fetchPolicy: 'network-only',
        context: { shouldBatch: true },
        variables: {
          id: member,
        },
      })
      .then((response) => {
        const dataMember = coercedGet(response, 'data.member', {});
        const messagesTotalCount = coercedGet(
          dataMember,
          'messages.totalCount',
          0
        );

        setTotalMessages(messagesTotalCount);
        setLocalMemberData(dataMember);

        setLoading(false);
      });
  }, [client, member]);

  return { totalMessages, localMemberData, fetchMemberData, loading };
};
