import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Col, Row } from 'antd';
import { UPDATE_VENDOR } from 'graphql/mutations/vendorConfiguration.mutation';
import { GET_VENDORS } from 'graphql/queries/vendorConfiguration.query';
import { isEmpty, keyBy } from 'lodash';
import Drawer from 'pages/components/common/Drawer/Drawer';
import globalMessages from 'messages';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DrawerActionButtons from 'components/DrawerActionButtons/DrawerActionButtons';
import DrawerFooter from 'components/DrawerFooter';
import {
  GameCategory,
  Vendor,
  VendorsConnectionEdge,
} from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { DrawerContainer } from '../../styles';
import { AddVendorForm } from './AddVendorForm';
import { EditVendorForm } from './EditVendorForm';
import { VendorsSideLabels } from './VendorsSideLabels';

type Props = {
  closeDrawer: () => void;
  formItemLayout: object;
  selectedVendors: Vendor[];
  onSetSelectedVendors: Dispatch<SetStateAction<Vendor[]>>;
  onToUploadImage: () => void;
  gameCategory: GameCategory | null;
  saveGameCategory: (value: boolean) => void;
};

const AddEditVendorDrawer: React.FC<Props> = ({
  gameCategory,
  closeDrawer,
  formItemLayout,
  onToUploadImage,
  selectedVendors,
  onSetSelectedVendors,
  saveGameCategory,
}) => {
  const { locale } = useIntl();
  const translate = useTranslate();
  const { loading, error, data } = useQuery(GET_VENDORS, {
    variables: {
      language: locale.toLocaleUpperCase(),
      filter: {
        gameTypes: {
          includesAny: gameCategory?.gameTypes,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const [currentVendor, setCurrentVendor] = useState<string | null>(null);
  const [editVendorForm, setEditVendorForm] = useState<Vendor | {}>({});
  const [lobby, setLobby] = useState<string | null>('internal');
  const [errorMsg, setErrorMsg] = useState(null);
  const editVendorFormId = coercedGet(editVendorForm, 'id', '');

  const [updateVendor] = useMutation(UPDATE_VENDOR);

  if (loading) return <p>{translate(globalMessages['loading.text'])}</p>;
  if (error) return <p>{translate(globalMessages['error-text'])}</p>;
  if (!data) return <p>{translate(globalMessages['no-data.text'])}</p>;

  let vendors = data?.vendors.edges.map(
    (edge: VendorsConnectionEdge) => edge.node
  );
  if (!isEmpty(selectedVendors)) {
    const parsedVendorsObj = keyBy(selectedVendors, 'id');
    vendors = vendors.filter((vendor: Vendor) => !parsedVendorsObj[vendor.id]);
  }

  const isAddVendor = isEmpty(editVendorForm);

  const addVendor = async () => {
    if (currentVendor) {
      const isExternalLobby = lobby === 'external';
      const newVendor = vendors.find(
        (vendor: Vendor) => currentVendor === vendor.id
      );

      const vendorToAdd = {
        ...newVendor,
        externalLobby: isExternalLobby,
      };

      await updateVendor({
        variables: {
          id: vendorToAdd.id,
          input: {
            externalLobby: {
              [gameCategory?.id || '']: isExternalLobby,
            },
          },
        },
      });

      onSetSelectedVendors([...selectedVendors, vendorToAdd as Vendor]);
      setCurrentVendor(null);
    } else {
      setErrorMsg(translate(globalMessages['vendor-error.text']));
    }
  };

  const onContinue = async () => {
    setErrorMsg(null);
    onToUploadImage();
  };

  const onDeleteVendor = (vendorId: string) => {
    const filteredVendors = selectedVendors.filter(
      (vendor) => vendor.id !== vendorId
    );
    onSetSelectedVendors(filteredVendors);

    if (vendorId === editVendorFormId || !filteredVendors.length) {
      setEditVendorForm({});
    }
  };

  const onEditVendor = (vendorData: Vendor) => {
    setEditVendorForm(vendorData);
  };

  /**
   * This function is for the edit form and will update the selectedVendors and the editVendorForm state
   * */
  const onToggleVendorLobby = async (value: string) => {
    const isExternalLobby = value === 'external';

    await updateVendor({
      variables: {
        id: editVendorFormId,
        input: {
          externalLobby: {
            [gameCategory?.id || '']: isExternalLobby,
          },
        },
      },
    });

    const updatedVendor = {
      ...editVendorForm,
      externalLobby: isExternalLobby,
    } as Vendor;
    const updatedVendorId = coercedGet(updatedVendor, 'id', '');

    const updatedVendorList = selectedVendors.map((vendorData: Vendor) =>
      vendorData.id === updatedVendorId ? updatedVendor : vendorData
    );

    setEditVendorForm(updatedVendor);
    onSetSelectedVendors(updatedVendorList);
  };

  const onClickAddVendorItemTab = () => {
    setEditVendorForm({});
  };

  const setCurrentVendorFunc = (value: string | null) => {
    setCurrentVendor(value);
    setErrorMsg(null);
  };

  return (
    <>
      <Drawer.Header
        title={translate(
          isAddVendor ? globalMessages.ADD_VENDOR : globalMessages.EDIT_VENDOR
        )}
      >
        <DrawerActionButtons
          saveAndExitFn={() => saveGameCategory(true)}
          saveFn={() => saveGameCategory(false)}
        />
      </Drawer.Header>
      <Drawer.Content>
        <DrawerContainer className="container">
          <Row style={{ height: '70vh' }}>
            <Col span={3} style={{ height: '100%' }}>
              <VendorsSideLabels
                activeVendor={editVendorForm}
                onDeleteVendor={onDeleteVendor}
                selectedVendors={selectedVendors}
                onClickEditVendorItem={onEditVendor}
                onClickAddVendorItem={onClickAddVendorItemTab}
              />
            </Col>

            <Col span={21}>
              <Row>
                <Col span={10}>
                  {isAddVendor && (
                    <AddVendorForm
                      vendors={vendors}
                      formItemLayout={formItemLayout}
                      currentVendor={currentVendor}
                      lobby={lobby}
                      setCurrentVendor={setCurrentVendorFunc}
                      setLobby={setLobby}
                      errorMsg={errorMsg}
                    />
                  )}

                  {!isAddVendor && (
                    <EditVendorForm
                      editVendorFormData={editVendorForm}
                      formItemLayout={formItemLayout}
                      setLobby={onToggleVendorLobby}
                    />
                  )}
                </Col>
                <Col span={14} />
              </Row>
            </Col>
          </Row>
        </DrawerContainer>
      </Drawer.Content>
      <DrawerFooter>
        <>
          <Button onClick={closeDrawer}>
            <FormattedMessage id="back.text" defaultMessage="Back" />
          </Button>

          <div>
            {isAddVendor && (
              <Button className="mr-2" onClick={addVendor}>
                <FormattedMessage
                  id="add-vendor.text"
                  defaultMessage="Add Vendor"
                />
              </Button>
            )}

            {!isEmpty(selectedVendors) && (
              <Button type="primary" onClick={onContinue}>
                <FormattedMessage
                  id="continue.text"
                  defaultMessage="Continue"
                />
              </Button>
            )}
          </div>
        </>
      </DrawerFooter>
    </>
  );
};

export default AddEditVendorDrawer;
