import gql from 'graphql-tag';

export const MEMBER_TAGS = gql`
  query MemberTags($id: ID!) {
    member(id: $id) {
      id
      tags {
        id
        name
        color
        description
      }
    }
  }
`;
