import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import MemberLoyaltyLevelTag from 'components/MemberLoyaltyLevelTag';
import MemberLevelTag from 'components/MemberLevelTag';
import useTranslate from 'utils/useTranslate';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';
import messages from './messages';

const FilterConditions = ({ filters, onFilterChange }: any) => {
  const [localStateFilter, setLocalStateFilter] = useState({}) as any;

  useEffect(() => {
    const transformedDateFilters = transformMovableDateFilter(filters);
    setLocalStateFilter(transformedDateFilters || {});
  }, [filters, setLocalStateFilter]);

  const translate = useTranslate();

  const handleRemoveValidityDateTimeRange = () => {
    onFilterChange({
      ...filters,
      validityDateTimeRange: null,
    });
  };

  const handleRemoveStatus = (
    value: string,
    e: { preventDefault: () => void }
  ) => {
    e.preventDefault();
    const { status } = filters;
    const data = status.in.filter((item: string) => item !== value);
    const newStatus = {
      in: data,
    };
    onFilterChange({
      ...filters,
      status: data.length ? newStatus : null,
    });
  };

  const handleRemoveMemberLevel = (
    value: string,
    e: { preventDefault: () => void }
  ) => {
    e.preventDefault();
    const { excludedMemberLevels } = filters;
    const data = excludedMemberLevels.overlaps.filter(
      (item: string) => item !== value
    );
    const newMemberLevels = {
      overlaps: data,
    };
    onFilterChange({
      ...filters,
      excludedMemberLevels: data.length ? newMemberLevels : null,
    });
  };

  const handleRemoveMemberLoyaltyLevel = (
    value: string,
    e: { preventDefault: () => void }
  ) => {
    e.preventDefault();
    const { qualifyingMemberLoyaltyLevels } = filters;
    const data = qualifyingMemberLoyaltyLevels.overlaps.filter(
      (item: any) => item !== value
    );
    const newMemberLoyaltyLevels = {
      overlaps: data,
    };
    onFilterChange({
      ...filters,
      qualifyingMemberLoyaltyLevels: data.length
        ? newMemberLoyaltyLevels
        : null,
    });
  };

  return (
    <>
      {localStateFilter.name
        ? localStateFilter.name.in.map((item: string, index: number) => (
            <Tag
              key={index}
              closable
              onClose={(e: { preventDefault: () => void }) => {
                e.preventDefault();

                onFilterChange({
                  ...filters,
                  name: null,
                });
              }}
            >
              {item}
            </Tag>
          ))
        : null}
      {localStateFilter.validityDateTimeRange ? (
        <Tag closable onClose={handleRemoveValidityDateTimeRange}>
          <FormattedMessage
            id="rebates.validity-date.text"
            defaultMessage="Validity date"
          />
          : {formatDate(localStateFilter.validityDateTimeRange.start)} ~{' '}
          {formatDate(localStateFilter.validityDateTimeRange.end)}
        </Tag>
      ) : null}
      {localStateFilter.status && !isEmpty(localStateFilter.status)
        ? localStateFilter.status.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveStatus(item, e)}
            >
              {translate(messages[`rebates.${item.toLowerCase()}.text`])}
            </Tag>
          ))
        : null}
      {localStateFilter.excludedMemberLevels &&
      !isEmpty(localStateFilter.excludedMemberLevels)
        ? localStateFilter.excludedMemberLevels.overlaps.map(
            (item: string, key: number) => (
              <MemberLevelTag
                onClose={(e) => handleRemoveMemberLevel(item, e)}
                key={key}
                id={item}
              />
            )
          )
        : null}
      {localStateFilter.qualifyingMemberLoyaltyLevels &&
      !isEmpty(localStateFilter.qualifyingMemberLoyaltyLevels)
        ? localStateFilter.qualifyingMemberLoyaltyLevels.overlaps.map(
            (item: string, key: number) => (
              <MemberLoyaltyLevelTag
                islight
                onClose={(e) => handleRemoveMemberLoyaltyLevel(item, e)}
                key={key}
                id={item}
              />
            )
          )
        : null}
    </>
  );
};

export default FilterConditions;
