import styled from 'styled-components';

export const StyledWarningIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 19px solid red;
  border-radius: 10px;

  ::before {
    content: '!';
    align: center;
  }
`;

export const StyledCompliances = styled.div`
  font-size: 10px;
`;
