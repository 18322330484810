import React from 'react';
import { Layout } from 'antd';
import { get } from 'lodash';
import styled from 'styled-components';
import { FormikProps } from 'interfaces/formikProps.interface';
import { RebateProps } from 'interfaces/formikValues/rebates.interface';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import Header from './components/Header';
import RebatesContent from './components/RebatesContent';
import { CreateRebateGroupTypes } from '../../constants';
import { RebateGroupProvider } from './context';

function RebateGroup({
  formikProps,
}: {
  formikProps: FormikProps<RebateProps>;
}) {
  const rebateType = get(formikProps.values, 'type', '');

  return (
    <RebateGroupProvider>
      <Layout className="my-4">
        <Layout>
          {rebateType !== CreateRebateGroupTypes.LIVE && (
            <LeftSidebar formikProps={formikProps} />
          )}
          <Layout>
            <Header />
            <LayoutContent className="p-3">
              <RebatesContent formikProps={formikProps} />
            </LayoutContent>
          </Layout>
          <RightSidebar formikProps={formikProps} />
        </Layout>
      </Layout>
    </RebateGroupProvider>
  );
}

const LayoutContent = styled(Layout.Content)`
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
`;

export default RebateGroup;
