import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Table } from 'antd';
import columns, { DEFAULT_ROW_COUNT } from './columns';
import { VENDOR_GAME_REPORTS } from '../../queries';

const { Column, ColumnGroup } = Table;

const TableIndex = ({
  state,
  setResultInfo,
  page,
  setPage,
}: {
  setPage: (e: Record<string, any>) => void;
  page: {
    after: string;
  };
  state: {
    dateRange: any[];
    gameTypes: any[];
    vendors: any[];
  };
  setResultInfo: (e: Record<string, any>) => void;
}) => {
  const { gameTypes, vendors, dateRange } = state;
  const vendor = vendors.length ? { in: vendors } : null;
  const gameType = gameTypes.length ? { in: gameTypes } : null;
  const variables = {
    first: DEFAULT_ROW_COUNT,
    after: page.after,
    startDateTime: dateRange[0]
      .utc(false)
      .startOf('day')
      .format(),
    endDateTime: dateRange[1]
      .utc(false)
      .endOf('day')
      .format(),
    filter: {
      ...(vendor && { vendor }),
      ...(gameType && { gameType }),
    },
  };

  useEffect(() => {
    setPage((prev: Record<string, any>) => ({
      ...prev,
      after: undefined,
      savedCursor: [undefined],
      currentPage: 0,
    }));
  }, [setPage]);

  const { data, loading } = useQuery(VENDOR_GAME_REPORTS, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted: (result) => setResultInfo(result.vendorGameReport),
  });

  const {
    vendorGameReport: { edges },
  } =
    data && Object.keys(data).length
      ? data
      : {
          vendorGameReport: {
            edges: [],
            totalCount: 0,
            pageInfo: { hasNextPage: false, endCursor: null },
          } as Record<string, any>,
        };
  const dataSource = edges.map(
    (edge: { node: Record<string, any> }) => edge.node
  );

  return (
    <Table
      bordered
      rowKey="id"
      dataSource={dataSource}
      loading={loading}
      pagination={false}
    >
      {columns?.map((column, idx) => {
        const { subColumns, title, dataIndex, render } = column as any;
        return subColumns && subColumns.length ? (
          <ColumnGroup title={title} key={idx}>
            {subColumns.map(
              ({
                title: subTitle,
                dataIndex: subDataIndex,
                render: subRender,
              }: any) => (
                <Column
                  title={subTitle}
                  dataIndex={subDataIndex}
                  key={subDataIndex}
                  align="center"
                  render={subRender}
                />
              )
            )}
          </ColumnGroup>
        ) : (
          <Column
            title={title}
            dataIndex={dataIndex}
            key={dataIndex || idx}
            align="center"
            render={render}
          />
        );
      })}
    </Table>
  );
};

export default TableIndex;
