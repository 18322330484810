import styled from 'styled-components';
import { Select } from 'antd';

export const SharedStyledSelect: any = styled(Select)`
  width: 100% !important;
  .ant-select-selection {
    border-radius: 0;
  }
  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }
  .ant-select-selection__placeholder {
    padding: 0 5px;
  }
`;

export const StyledLabel = styled.span`
  font-size: 10px;
  font-weight: bold;
`;

export default {};
