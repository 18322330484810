import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { DELETE_WITHDRAWAL_PROVIDER } from 'graphql/mutations/withdrawalProvider.mutation';
import { WITHDRAWAL_PROVIDERS } from 'graphql/queries/withdrawalProvider.query';
import messages from 'messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withdrawalProvidersTestId } from 'components/data-testid/WithdrawalProviders';
import useTranslate from 'utils/useTranslate';

const DeleteProvider = (props: Record<string, any>) => {
  const { toggleModal, id, refetchVariables } = props;
  const translate = useTranslate();

  const [deleteWithdrawalProvider, { loading }] = useMutation(
    DELETE_WITHDRAWAL_PROVIDER,
    {
      onCompleted: () => {
        message.success(translate(messages.WITHDRAWAL_PROVIDER_DELETED));

        toggleModal();
      },
    }
  );

  return (
    <Modal
      data-testid={withdrawalProvidersTestId.deleteModal}
      visible
      maskClosable={false}
      closable={false}
      width={416}
      okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
      cancelText={<FormattedMessage id="cancel.text" defaultMessage="Cancel" />}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={() => {
        deleteWithdrawalProvider({
          variables: {
            id,
          },
          refetchQueries: [
            {
              query: WITHDRAWAL_PROVIDERS,
              variables: refetchVariables,
            },
          ],
        });
      }}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">
          <FormattedMessage id="confirm.text" defaultMessage="Confirm" />{' '}
          <FormattedMessage id="delete.text" defaultMessage="Delete" />
        </span>
        <div className="ant-modal-confirm-content">
          <FormattedMessage
            id="are-you-sure.text"
            defaultMessage="Are you sure?"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteProvider;
