import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Spin } from 'antd';
import coercedGet from 'utils/coercedGet';

import ModifiedTag from '../ModifiedTag';
import { GAME_NAME } from './queries';

type Props = {
  id: string;
  onClose: () => void;
  key?: string | number;
};

const GameCategoryTag = (props: Props) => {
  const { id, onClose } = props;

  const { data = {}, loading } = useQuery(GAME_NAME, {
    variables: {
      id,
    },
  });

  return (
    <ModifiedTag {...props} closable onClose={onClose}>
      {loading ? <Spin /> : coercedGet(data, 'game.name', null)}
    </ModifiedTag>
  );
};

export default GameCategoryTag;
