import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { uniq } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import styled from 'styled-components';

import coercedGet from 'utils/coercedGet';
import { formatDate } from 'utils/dateUtils';
import useExpandableFilterList from 'SuperAdminMain/hooks/useExpandableFilterList';
import { isObjectPropsEmpty } from 'utils/isObjectPropsEmpty/isObjectPropsEmpty';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import { useScreenTabSA } from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';
import { TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import useTransformedDates from 'SuperAdminMain/hooks/useTransformedDates';
import ModifiedTag from './components/ModifiedTag';
import ClientTag from './components/ClientTag/ClientTag';

import GameCategoryTagList from './components/GameCategoryTagList/GameCategoryTagList';
import GameSubCategoryTagList from './components/GameSubCategoryTagList/GameSubCategoryTagList';
import GameNameTag from './components/GameNameTag/GameNameTag';

type DateType = {
  gte: string;
  lte: string;
} | null;

type ModuleDateTypes = {
  dateTimeProcessed?: DateType;
  dateTimePlaced?: DateType;
  dateTimeSettled?: DateType;
};

const FilterConditions = ({ isLoading = false }) => {
  const { filters, setFilters, defaultValues } = useFilterValues();

  const { clearTabState } = useScreenTabSA(TabId.BET_HISTORY_REPORT);

  const [
    containerRef,
    { isTagsHidden, setIsTagsHidden },
    { showToggleArrow },
  ] = useExpandableFilterList() as any;

  const {
    dateTimePlaced,
    dateTimeSettled,
    dateTimeProcessed,
  } = useTransformedDates<ModuleDateTypes>([
    'dateTimePlaced',
    'dateTimeSettled',
    'dateTimeProcessed',
  ]);

  const handleRemoveDateFilter = (dateString: string) => {
    setFilters((prev: any) => ({
      ...prev,
      [dateString]: null,
    }));
  };

  const handleRemoveTagList = (identifier: string, filterName: string) => {
    if (!isLoading) {
      const filtArray = coercedGet(filters, `${filterName}.in`, []);
      const data = filtArray.filter((item: string) => item !== identifier);

      const newFilterArray = {
        in: data,
      };
      setFilters((prev: any) => ({
        ...prev,
        [filterName]: data.length ? newFilterArray : null,
      }));
    }
  };

  const handleRemoveFilterTag = (tagName: string) => {
    if (!isLoading) {
      setFilters((prev: any) => ({
        ...prev,
        [tagName]: null,
      }));
    }
  };

  return (
    <div className="d-flex  align-items-center">
      <StyledTagWrapper
        className="mt-1 ml-3 "
        ref={containerRef}
        style={{ width: '40vw' }}
      >
        <FormattedMessage
          id="filter-conditions.text"
          defaultMessage="Filter Conditions"
        />
        :
        <span className="mr-4" />
        {filters.admin
          ? filters.admin.in.map((item: string) => (
              <ClientTag
                // key={key}
                key={item}
                onClose={() => handleRemoveTagList(item, 'admin')}
                id={item}
              />
            ))
          : null}
        {filters.id
          ? filters.id.in.map((item: any) => (
              <ModifiedTag
                key={item}
                closable
                onClose={() => handleRemoveTagList(item, 'id')}
              >
                {item}
              </ModifiedTag>
            ))
          : null}
        {dateTimeProcessed ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveDateFilter('dateTimeProcessed')}
          >
            <FormattedMessage
              id="date-processed.text"
              defaultMessage="Date Processed"
            />
            : {formatDate(dateTimeProcessed.gte)}~
            {formatDate(dateTimeProcessed.lte)}
          </ModifiedTag>
        ) : null}
        {dateTimeSettled ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveDateFilter('dateTimeSettled')}
          >
            <FormattedMessage
              id="date-settled.text"
              defaultMessage="Date Settled"
            />
            : {formatDate(dateTimeSettled.gte)}~
            {formatDate(dateTimeSettled.lte)}
          </ModifiedTag>
        ) : null}
        {dateTimePlaced ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveDateFilter('dateTimePlaced')}
          >
            <FormattedMessage
              id="date-placed.text"
              defaultMessage="Date Placed"
            />
            : {formatDate(dateTimePlaced.gte)}~{formatDate(dateTimePlaced.lte)}
          </ModifiedTag>
        ) : null}
        {/* GAME CAT & SUBCAT FILTER FIELDS - different implementation due to API limitations */}
        {filters.game_category ? (
          <GameCategoryTagList gameIds={filters.game_category.in} />
        ) : null}
        {filters.game_subCategory ? (
          <GameSubCategoryTagList gameIds={filters.game_subCategory.in} />
        ) : null}
        {filters.game_name
          ? uniq(filters.game_name.in).map((item: any) => (
              <GameNameTag
                key={item}
                onClose={() => handleRemoveTagList(item, 'game_name')}
                id={item}
              />
            ))
          : null}
        {filters.tips ? (
          <ModifiedTag closable onClose={() => handleRemoveFilterTag('tips')}>
            <FormattedMessage id="reports.tips.text" defaultMessage="Tips" />:{' '}
            {filters.tips.gte} - {filters.tips.lte}
          </ModifiedTag>
        ) : null}
        {filters.jackpotContributionBet ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveFilterTag('jackpotContributionBet')}
          >
            <FormattedMessage
              id="reports.jackpot-contribution-bet.text"
              defaultMessage="Jackpot Contribution Bet"
            />
            : {filters.jackpotContributionBet.gte} -{' '}
            {filters.jackpotContributionBet.lte}
          </ModifiedTag>
        ) : null}
        {filters.jackpotWin ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveFilterTag('jackpotWin')}
          >
            <FormattedMessage
              id="reports.jackpot-win.text"
              defaultMessage="Jackpot Win"
            />
            : {filters.jackpotWin.gte} - {filters.jackpotWin.lte}
          </ModifiedTag>
        ) : null}
        {filters.cashoutWinloss ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveFilterTag('cashoutWinloss')}
          >
            <FormattedMessage
              id="reports.cash-out-win-loss.text"
              defaultMessage="Cashout Win / Loss"
            />
            : {filters.cashoutWinloss.gte} - {filters.cashoutWinloss.lte}
          </ModifiedTag>
        ) : null}
        {filters.freeSpin ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveFilterTag('freeSpin')}
          >
            {filters.freeSpin.eq ? (
              <FormattedMessage
                id="reports.free-spin-yes.text"
                defaultMessage="Free Spin Yes"
              />
            ) : (
              <FormattedMessage
                id="reports.free-spin-no.text"
                defaultMessage="Free Spin No"
              />
            )}
          </ModifiedTag>
        ) : null}
        {filters.device
          ? filters.device.in.map((item: any) => (
              <ModifiedTag
                key={item}
                closable
                onClose={() => handleRemoveTagList(item, 'device')}
              >
                {item}
              </ModifiedTag>
            ))
          : null}
        {filters.timezone ? (
          <ModifiedTag
            closable
            onClose={() => handleRemoveFilterTag('timezone')}
          >
            {filters.timezone}
          </ModifiedTag>
        ) : null}
      </StyledTagWrapper>

      {showToggleArrow && !isObjectPropsEmpty(filters) && (
        <LegacyIcon
          type={isTagsHidden ? 'double-right' : 'double-left'}
          className="cursor-pointer mr-2 mt-1"
          onClick={() => {
            setIsTagsHidden((prev: any) => !prev);
          }}
        />
      )}

      <ClearAllFilterButton
        className="mt-1"
        initialFilters={defaultValues}
        currentFilters={filters}
        onFilterChange={(value: any) => {
          setFilters(value);
          clearTabState();
        }}
      />
    </div>
  );
};

const StyledTagWrapper = styled.div`
  /* display: flex;
  justify-content: flex-start; */
`;

export default FilterConditions;
