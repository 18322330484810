import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import messages from 'pages/components/WithdrawalMethods/messages';
import useTranslate from 'utils/useTranslate';

const MethodDetails = ({
  paymentMethod,
  formItemLayout,
}: {
  paymentMethod: Record<string, any>;
  formItemLayout: Record<string, any>;
}) => {
  const { nickname, accountNumber, accNumber } = paymentMethod;
  const translate = useTranslate();
  return (
    <>
      <Form.Item
        label={`${translate(messages.nickname)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{nickname}</span>
      </Form.Item>
      <Form.Item
        label={`${translate(messages.bankacnumber)}:`}
        {...formItemLayout}
        className="mb-0"
      >
        <span>{accountNumber || accNumber}</span>
      </Form.Item>
    </>
  );
};

export default MethodDetails;
