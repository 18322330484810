import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'dashboard.linestats.number-of-new-member.text': {
    id: 'dashboard.linestats.number-of-new-member.text',
    defaultMessage: 'Number of new member',
  },
  'dashboard.linestats.first-deposit-counts.text': {
    id: 'dashboard.linestats.first-deposit-counts.text',
    defaultMessage: 'First Deposits count',
  },
  'dashboard.linestats.total-number-of-members-online.text': {
    id: 'dashboard.linestats.total-number-of-members-online.text',
    defaultMessage: 'Total number of members online',
  },
  'dashboard.linestats.day-to-day.text': {
    id: 'dashboard.linestats.day-to-day.text',
    defaultMessage: 'Day to Day',
  },
  'dashboard.linestats.week-to-week.text': {
    id: 'dashboard.linestats.week-to-week.text',
    defaultMessage: 'Week to Week',
  },
  'dashboard.linestats.month-to-month.text': {
    id: 'dashboard.linestats.month-to-month.text',
    defaultMessage: 'Month to Month',
  },
  'dashboard.linestats.year-to-year.text': {
    id: 'dashboard.linestats.year-to-year.text',
    defaultMessage: 'Year to Year',
  },
  'dashboard.linestats.period-to-period.text': {
    id: 'dashboard.linestats.period-to-period.text',
    defaultMessage: 'Period to Period',
  },
});

export default messages;
