import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Radio } from 'antd';
import useTranslate from 'utils/useTranslate';
import messages from 'pages/components/WithdrawalMethods/messages';
import { useConfig } from 'hooks/useConfig';

type Rec = Record<string, any>;
type Props = {
  handleChange: () => void;
  handleBlur: () => void;
  values: Rec;
  errors: Rec;
  formItemLayout: Rec;
};

const OtherSettings = (props: Props) => {
  const { values, handleChange, handleBlur, errors, formItemLayout } = props;
  const { currency } = useConfig();
  const translate = useTranslate();
  return (
    <>
      <Form.Item
        labelCol={{ span: 8 }}
        className="mb-1"
        label={<strong>{translate(messages.others)}</strong>}
        colon={false}
      />

      <Form.Item
        label={translate(messages.remarks)}
        {...formItemLayout}
        validateStatus={errors.remarks && 'error'}
        help={errors.remarks || null}
      >
        <Input
          name="remarks"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.remarks}
          placeholder={translate(messages.remarks)}
        />
      </Form.Item>

      <Form.Item
        label={translate(messages.transactionfee)}
        {...formItemLayout}
        validateStatus={errors.transactionFee && 'error'}
        help={errors.transactionFee || null}
      >
        <Radio.Group
          name="transactionFeeType"
          onChange={handleChange}
          value={values.transactionFeeType}
        >
          <Radio value="ABSOLUTE">{translate(messages.absolute)}</Radio>
          <Radio value="RELATIVE">{translate(messages.relative)}</Radio>
        </Radio.Group>
        <br />
        <Input
          type="number"
          name="transactionFee"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.transactionFee}
          placeholder="0"
          suffix={
            values.transactionFeeType === 'ABSOLUTE'
              ? `${currency?.symbol}`
              : '%'
          }
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.status)}
        {...formItemLayout}
        validateStatus={errors.enabled && 'error'}
        help={errors.enabled || null}
      >
        <Radio.Group
          name="enabled"
          onChange={handleChange}
          value={values.enabled}
        >
          <Radio value>{translate(messages.enable)}</Radio>
          <Radio value={false}>{translate(messages.disable)}</Radio>
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default OtherSettings;
