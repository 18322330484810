import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, message, Typography } from 'antd';
import { FormattedMessage, useIntl, defineMessages } from 'react-intl';
import * as Yup from 'yup';
import TextField from 'components/TextField';
import { getOtpData, validateOtpNext } from 'utils/getAuthTokens';
import { ApiErrorCode, getAPIErrorCode } from 'utils/api';
import Storage from 'constants/storage';
import useTranslate from 'utils/useTranslate';
import { globalTranslate } from 'utils/globalMessages';
import ValidateOTP from 'components/ValidateOTP';
import ForgotPassword from 'components/ForgotPassword';

import axios from 'axios';

const { Paragraph } = Typography;
const { config } = window;

const messages = defineMessages({
  USERNAME_REQUIRED: {
    id: 'USERNAME_REQUIRED',
    defaultMessage: 'Please enter your Username',
  },
  PASSWORD_REQUIRED: {
    id: 'PASSWORD_REQUIRED',
    defaultMessage: 'Please enter your Password',
  },
  'admin-code.text': {
    id: 'admin-code.text',
    defaultMessage: 'Admin Code',
  },
  'username.text': {
    id: 'username.text',
    defaultMessage: 'Username',
  },
  'password.text': {
    id: 'password.text',
    defaultMessage: 'Password',
  },
  LOGIN_AS_OPERATOR: {
    id: 'LOGIN_AS_OPERATOR',
    defaultMessage: 'Sign in as Operator',
  },
  LOGIN_AS_ADMIN: {
    id: 'LOGIN_AS_ADMIN',
    defaultMessage: 'Sign in as Admin',
  },
});

const ME = `
  query Me {
    me {
      id
      role
    }
  }
`;

function SignInForm(props: Record<string, any>) {
  const intl = useIntl();
  const [isLoginAsOperator, setIsLoginAsOperator] = useState(false);
  const [showResetPasswordBtn, setShowResetPasswordBtn] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);

  const toggleLoginAsOperator = () => setIsLoginAsOperator((state) => !state);

  const [loginRes, setLoginRes] = useState({
    access: '',
    refresh: '',
  });

  const [otpNew, setOtpNew] = useState('');

  const [otpModalOpen, setOtpModalOpen] = useState(false);

  const initialValues = {
    adminCode: '',
    username: '',
    password: '',
  };

  const { storeAuthenticatorState, handleNextScreen, history } = props;
  const translate = useTranslate();

  const handleValidateOTP = async () => {
    try {
      const response = await validateOtpNext(otpNew, loginRes.access);

      if (response.status === 200) {
        localStorage.setItem(Storage.BO_ACCESS_TOKEN, loginRes.access);
        localStorage.setItem(Storage.BO_REFRESH_TOKEN, loginRes.refresh);
        localStorage.setItem(Storage.ACCESS_TOKEN, loginRes.access);

        history.push('/');

        message.success('Successfully validated OTP.');
      }

      setOtpModalOpen(false);
    } catch (error) {
      if (error) {
        message.error('Invalid OTP.');
      }
      setOtpModalOpen(false);
    }
  };

  return (
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        const { adminCode, username, password } = values;

        try {
          const response = await getOtpData(username, password, adminCode);

          if (response.data.authenticateViaOTP) {
            localStorage.setItem(
              Storage.AUTHENTICATE_VIA_OTP,
              response.data.authenticateViaOTP
            );
          }

          const {
            data: { otp },
          } = response;

          if (otp) {
            const {
              data: { token },
            } = response;

            storeAuthenticatorState({
              otp,
              token,
            });
            handleNextScreen();
            return;
          }

          const {
            data: { access, refresh },
          } = response;

          setLoginRes({
            access,
            refresh,
          });

          // disable otp in prod and staging
          // if (isDevelopment()) {
          //   if (isLoginAsOperator) {
          //     setOtpModalOpen(true);
          //   }
          //   // disable otp in admin level
          //   if (!isLoginAsOperator) {
          //     localStorage.setItem(Storage.BO_ACCESS_TOKEN, access);
          //     localStorage.setItem(Storage.BO_REFRESH_TOKEN, refresh);
          //     localStorage.setItem(Storage.ACCESS_TOKEN, access);

          //     history.push('/');
          //   }
          // }

          const getUserRole = () =>
            axios.post(
              `${config.apiUrl}?me`,
              {
                query: ME,
              },
              {
                headers: {
                  authorization: `Bearer ${access}`,
                },
              }
            );

          const userRole = (await getUserRole()).data.data.me.role;

          if (userRole === 'ADMIN' && isLoginAsOperator) {
            message.error('Invalid username or password');
            return;
          }

          localStorage.setItem(Storage.BO_ACCESS_TOKEN, access);
          localStorage.setItem(Storage.BO_REFRESH_TOKEN, refresh);
          localStorage.setItem(Storage.ACCESS_TOKEN, access);
          history.push('/');

          return;
        } catch (err) {
          const code = getAPIErrorCode(err);

          if (code === 'PASSWORD_EXPIRED') {
            setShowResetPasswordBtn(true);
          }

          message.error(
            globalTranslate(code || ApiErrorCode.INTERNAL_SERVER_ERROR, intl)
          );
        }
        setSubmitting(false);
      }}
      initialValues={initialValues}
      validationSchema={Yup.object({
        adminCode: Yup.string().min(4),
        username: Yup.string()
          .min(3)
          .required(translate(messages.USERNAME_REQUIRED)),
        password: Yup.string().required(translate(messages.PASSWORD_REQUIRED)),
      })}
      render={(formikProps) => {
        const { handleSubmit, values, isSubmitting } = formikProps;
        const { adminCode, username, password } = values;

        return (
          <>
            <Form>
              <Paragraph style={{ textAlign: 'center' }}>
                <FormattedMessage
                  id="welcome-back.text"
                  defaultMessage="Welcome back"
                />
              </Paragraph>
              {isLoginAsOperator && (
                <TextField
                  data-target="admin-code"
                  type="text"
                  name="adminCode"
                  label={translate(messages['admin-code.text'])}
                  placeholder={translate(messages['admin-code.text'])}
                  value={adminCode}
                  maxLength={32}
                />
              )}
              <TextField
                data-target="username"
                type="text"
                name="username"
                label={translate(messages['username.text'])}
                placeholder={translate(messages['username.text'])}
                value={username}
                maxLength={32}
              />
              <TextField
                data-target="password"
                type="password"
                name="password"
                label={translate(messages['password.text'])}
                placeholder={translate(messages['password.text'])}
                value={password}
                maxLength={32}
              />
              <>
                <div className="mb-3" />

                <Button type="link" block onClick={toggleLoginAsOperator}>
                  {translate(
                    isLoginAsOperator
                      ? messages.LOGIN_AS_ADMIN
                      : messages.LOGIN_AS_OPERATOR
                  )}
                </Button>
              </>
              <div className="mb-3" />
              <div className="mb-2">
                {showResetPasswordBtn && (
                  <Button
                    className="mb-2"
                    block
                    onClick={() => setResetPassModal(true)}
                  >
                    Reset Password
                  </Button>
                )}
                <Button
                  data-target="signin-button"
                  type="primary"
                  block
                  htmlType="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  onClick={handleSubmit as any}
                >
                  <FormattedMessage id="signin.text" defaultMessage="Signin" />
                </Button>

                <Button
                  type="link"
                  block
                  onClick={() => setResetPassModal(true)}
                >
                  Forgot Password
                </Button>
              </div>

              {/* <OperatorForgotPassword
                visible={operatorForgotPassModal}
                onClose={() => setOperatorForgotPassModal(false)}
                getAdminCode={(code) => setFieldValue('adminCode', code)}
              /> */}

              <ForgotPassword
                visible={resetPassModal}
                onClose={() => setResetPassModal(false)}
                onSuccess={() => {
                  setResetPassModal(false);
                }}
              />
            </Form>
            <ValidateOTP
              visible={otpModalOpen}
              onValidateOTP={handleValidateOTP}
              setNewOtp={setOtpNew}
              otpNew={otpNew}
            />
          </>
        );
      }}
    />
  );
}

export default SignInForm;
