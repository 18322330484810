import gql from 'graphql-tag';
import { defineMessages } from 'react-intl';
import * as yup from 'yup';
import messages from 'messages';
import styled from 'styled-components';
import { CustomRegex } from 'utils/regex';
import { toFinite } from 'lodash';
import { Input, Select } from 'antd';

// ===[ HELPER FN ]=== //
export const getMS = (
  integer: number,
  type: string,
  isDisabled?: boolean
): string | null => {
  if (isDisabled) return null;
  return type === 'months' ? `${integer * 30} days` : `${integer} ${type}`;
};

// ===[ MUTATION ]=== //
export const CREATE_MANUAL_ADJUSTMENT = gql`
  mutation createManualAdjustment(
    $input: CreateManualAdjustmentInput!
    $password: String!
  ) {
    createManualAdjustment(input: $input, password: $password)
  }
`;

export const CREATE_PROMO = gql`
  mutation createPromo($input: CreatePromoInput!) {
    createPromo(input: $input)
  }
`;

export const SUBMIT_PROMO = gql`
  mutation submitPromo($id: ID!) {
    submitPromo(id: $id)
  }
`;

export const CREATE_CATEGORY = gql`
  mutation createPromoCategory($input: CreatePromoCategoryInput!) {
    createPromoCategory(input: $input)
  }
`;

// ===[ QUERY ]=== //
export const VALID_PASSWORD = gql`
  query validPassword($password: String!) {
    validPassword(password: $password)
  }
`;

export const PROMOS = gql`
  query promos {
    promos(filter: { status: { nin: [DRAFT, PENDING] } }) {
      edges {
        node {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
  }
`;

export const PROMOS_TOTAL_COUNT = gql`
  query Promos($filter: PromosFilterInput!) {
    promos(filter: $filter) {
      totalCount
    }
  }
`;

export const PROMO_CATEGORIES = gql`
  query promoCategories {
    promoCategories {
      id
      name
    }
  }
`;

export const MEMBERS = gql`
  query members($usernames: [String!]) {
    members(filter: { username: { in: $usernames } }) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

// ===[ TRANSLATION ]=== //
export const localMessages = defineMessages({
  PROMO_MANUAL_ADJUSTMENT_UPDATED_SUCCESSFULLY: {
    id: 'PROMO_MANUAL_ADJUSTMENT_UPDATED_SUCCESSFULLY',
    defaultMessage: 'Promo Manual Adjustment updated successfully.',
  },
  NEW_PROMO_CATEGORY_CREATED: {
    id: 'NEW_PROMO_CATEGORY_CREATED',
    defaultMessage: 'New promo category created',
  },
  AMOUNT_KEYED_IN_MUST_BE_IN_NUMERICS: {
    id: 'AMOUNT_KEYED_IN_MUST_BE_IN_NUMERICS',
    defaultMessage: 'Amount keyed in must be in numerics',
  },
  MUST_BE_EQUAL_OR_GREATER_THAN_0: {
    id: 'MUST_BE_EQUAL_OR_GREATER_THAN_0',
    defaultMessage: 'Amount keyed in must be equal or more than 0',
  },
  MAX_CHARACTERS_OF_32: {
    id: 'MAX_CHARACTERS_OF_32',
    defaultMessage: 'Max characters of 32.',
  },
  'enter-username.text': {
    id: 'enter-username.text',
    defaultMessage: 'Enter Username',
  },
  'bulk-edit-balance-confirm-question.text': {
    id: 'bulk-edit-balance-confirm-question.text',
    defaultMessage:
      'Are you sure you want to proceed? This action is irreversible.',
  },
});

// ===[ VALIDATION ]=== //
export const schema = (translate: any) =>
  yup.object().shape({
    amount: yup
      .string()
      .required(translate(messages.REQUIRED))
      .matches(
        CustomRegex.hasLetters(),
        translate(localMessages.AMOUNT_KEYED_IN_MUST_BE_IN_NUMERICS)
      )
      .test(
        'amount',
        translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 }),
        (value) => toFinite(value) > 0
      )
      .matches(
        CustomRegex.fourDecimals(),
        translate(messages['maximum-count-decimal-places.text'], {
          count: 4,
        })
      ),
    turnoverRequirementMultiplier: yup
      .string()
      .required(translate(messages.REQUIRED))
      .matches(
        CustomRegex.hasLetters(),
        translate(localMessages.AMOUNT_KEYED_IN_MUST_BE_IN_NUMERICS)
      )
      .test(
        'turnoverRequirementMultiplier',
        translate(localMessages.MUST_BE_EQUAL_OR_GREATER_THAN_0, { count: 0 }),
        (value) => toFinite(value) >= 0
      )
      .matches(
        CustomRegex.twoDecimals(),
        translate(messages['maximum-count-decimal-places.text'], {
          count: 2,
        })
      ),
    claimOffsetDuration: yup
      .string()
      .default(null)
      .nullable()
      .matches(
        CustomRegex.hasLetters(),
        translate(localMessages.AMOUNT_KEYED_IN_MUST_BE_IN_NUMERICS)
      )
      .test(
        'claimOffsetDuration',
        translate(localMessages.MUST_BE_EQUAL_OR_GREATER_THAN_0, { count: 0 }),
        (value: number) => toFinite(value) >= 0 || value === null
      ),
    claimExpiryDuration: yup
      .string()
      .default(null)
      .nullable()
      .matches(
        CustomRegex.hasLetters(),
        translate(localMessages.AMOUNT_KEYED_IN_MUST_BE_IN_NUMERICS)
      )
      .test(
        'claimExpiryDuration',
        translate(localMessages.MUST_BE_EQUAL_OR_GREATER_THAN_0, { count: 0 }),
        (value: number) => toFinite(value) >= 0 || value === null
      ),
    playerRemarks: yup
      .string()
      .required(translate(messages.REQUIRED))
      .max(32, translate(localMessages.MAX_CHARACTERS_OF_32)),
    remarks: yup.string().required(translate(messages.REQUIRED)),
    promoInput: yup
      .string()
      .nullable()
      // .required(translate(messages.REQUIRED))
      .max(32, translate(localMessages.MAX_CHARACTERS_OF_32)),
    password: yup
      .string()
      .required(translate(messages.REQUIRED))
      .matches(
        CustomRegex.password(),
        translate(messages.PASSWORD_VALIDATION_FIELD)
      ),
  });

// ===[ STYLES ]=== //
export const StyledNumberInput = styled(Input)`
  border-right: none;
  border-radius: 4px 0px 0px 4px;
`;

export const StyledDateSelect = styled(Select)`
  .ant-select-selection--single {
    border-radius: 0px 4px 4px 0px;
  }
`;
