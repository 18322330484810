import gql from 'graphql-tag';

export const REBATE_GROUPS = gql`
  query rebateGroups(
    $first: Int
    $after: Binary
    $filter: RebateGroupsFilterInput
  ) {
    rebateGroups(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          serialCode
          creator {
            id
            username
          }
          status
          enabled
          name
          qualifyingMemberLoyaltyLevels {
            id
            name
          }
          excludedMemberLevels {
            id
            name
          }
          validityDateTimeRange {
            start
            end
          }
          automaticPayout
          turnoverRequirementMultiplier
          claimOffsetDuration
          claimExpiryDuration
          payoutExpiryDuration
          minimumPayout
          maximumPayout
          type
          dateTimeCreated
          dateTimeUpdated
          dateTimeSubmitted
          qualifiedMembersCount
          ... on LiveRebateGroup {
            percentages {
              vendor {
                id
                name
                nameEn: name(language: EN)
                nameZh: name(language: ZH)
              }
              gameType
              percentage
            }
          }
          ... on PeriodicRebateGroup {
            payoutSettlementPeriod
            payoutSettlementTime
            payoutSettlementDayOfWeek
            payoutSettlementDayOfMonth
            payoutSettlementMonth
            levels {
              minimum
              percentages {
                vendor {
                  id
                  name
                  nameEn: name(language: EN)
                  nameZh: name(language: ZH)
                }
                gameType
                percentage
                games {
                  game {
                    id
                  }
                  percentage
                }
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
export const REBATE_GROUP = gql`
  query rebateGroup($id: ID!) {
    rebateGroup(id: $id) {
      id
      serialCode
      creator {
        id
        username
      }
      status
      enabled
      name
      qualifyingMemberLoyaltyLevels {
        id
        name
        color
      }
      excludedMemberLevels {
        id
        name
        color
      }
      validityDateTimeRange {
        start
        end
      }
      automaticPayout
      turnoverRequirementMultiplier
      claimOffsetDuration
      claimExpiryDuration
      payoutExpiryDuration
      minimumPayout
      maximumPayout
      type
      dateTimeCreated
      dateTimeUpdated
      dateTimeSubmitted
      qualifiedMembersCount
      ... on LiveRebateGroup {
        percentages {
          vendor {
            id
            name
            nameEn: name(language: EN)
            nameZh: name(language: ZH)
          }
          gameType
          percentage
        }
      }
      ... on PeriodicRebateGroup {
        payoutSettlementPeriod
        payoutSettlementTime
        payoutSettlementDayOfWeek
        payoutSettlementDayOfMonth
        payoutSettlementMonth
        levels {
          minimum
          percentages {
            vendor {
              id
              name
              nameEn: name(language: EN)
              nameZh: name(language: ZH)
            }
            gameType
            percentage
            games {
              game {
                id
              }
              percentage
            }
          }
        }
      }
    }
  }
`;

export const REBATE_GROUP_NAMES = gql`
  query FilterRebateGroupNames(
    $first: Int
    $after: Binary
    $filter: RebateGroupsFilterInput
  ) {
    rebateGroups(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export default {};
