import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { ErrorHandler } from 'components/ErrorHandler';
import styled from 'styled-components';

export const StyledCardFlex = ({
  children,
  style,
  className,
  ...rest
}: any) => (
  <div
    style={{ marginBottom: '8px', ...style }}
    className={`${'d-flex justify-content-between align-items-center '}${className ||
      ''}`}
    {...rest}
  >
    {children}
  </div>
);

const collapseProps = {
  expandIconPosition: 'right',
  bordered: false,
  expandIcon: ({ isActive }: { isActive: boolean }) => (
    <span className="arrow-icon styled-center p-2" style={{ top: 'auto' }}>
      <RightOutlined
        style={{
          paddingTop: '2px',
          paddingLeft: '2px',
        }}
        rotate={isActive ? 90 : 0}
      />
    </span>
  ),
};

export const ModifiedCollapse = ({ children, errorTitle, ...rest }: any) => (
  <ErrorHandler title={errorTitle || 'Error'}>
    <Collapse defaultActiveKey={['1']} {...collapseProps} {...rest}>
      {children}
    </Collapse>
  </ErrorHandler>
);
export const Wrapper = styled.div`
  .ant-card {
    /*  for general layout */
    transform: translateY(2px);

    /* .my-1 {
      margin-top: 0.2rem !important;
      margin-bottom: 0.2rem !important;
    } */

    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .ant-typography {
      font-size: 12px;
    }

    .head-typography {
      font-size: 14px !important;
    }

    /* .ant-card-head-title {
      font-size: 14px !important;
    } */

    .ant-card-extra {
      font-size: 14px;
    }

    .ant-card-head-wrapper {
      height: 100%;
    }

    .ant-card-head {
      background: #f0f0f0;
      min-height: 0px;
      height: 37.5px;
      padding: 0px 16px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom: 0 !important;
    }

    .ant-card-body {
      padding: 10px 16px 37px 16px !important;
    }

    .ant-slider-with-marks {
      margin-bottom: 0px;
    }

    .ant-divider-horizontal {
      margin: 15px 0px 0px 0;
    }

    /*  for collapse */

    .ant-collapse {
      background: transparent;

      .ant-collapse-item {
        border-bottom: 1px solid #e8e8e8;
      }

      .ant-collapse-content-box {
        padding: 0px 1px 4px 0px;
      }

      .arrow-icon {
        background: #f0f2f5;
        width: 18px;
        height: 18px;
        border-radius: 10px;
        right: 0px;
        top: 17px;

        .anticon-right {
          padding-top: 2px;
          padding-right: 2px;
        }
      }

      .ant-collapse-header {
        /* padding: 10px 0px; */
        padding: 6px 0px 0px 0px;
        margin-bottom: 8px;

        .ant-collapse-content-box {
          padding: 0px;
        }
      }

      /* .ant-switch {
        height: 20px;
        width: 44px;
      } */
    }
  }
`;
