import React from 'react';
import { Select } from 'antd';
import { StyledSelect } from 'styles';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { FilterItem } from 'components/FilterItem/FilterItem';

const { Option }: any = Select;

type DefaultProgrammeType = {
  onChange: (e: boolean | string[]) => void;
  value: { in: boolean };
};

function DefaultProgramme(props: DefaultProgrammeType) {
  const { onChange, value } = props;

  const translate = useTranslate();

  return (
    <FilterItem
      label={translate(messages.DEFAULT_PROGRAMME)}
      onClear={() => onChange([])}
    >
      <StyledSelect
        placeholder={translate(messages.YES_OR_NO)}
        value={value ? value.in : null}
        onChange={onChange}
      >
        <Option value>Yes</Option>
        <Option value={false}>No</Option>
      </StyledSelect>
    </FilterItem>
  );
}

export default DefaultProgramme;
