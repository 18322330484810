import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const StyledLayout = styled(Layout)`
  height: 100%;
`;

export const StyledContent = styled(Content)`
  background: #fff;
  /* .ant-table-header-column {
    text-align: center;
    display: block !important;
  } */
`;

export const StyledContainer = styled.div`
  flex-grow: 1;
`;

export default {};
