import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { PAYMENT_METHODS } from 'graphql/queries/paymentMethodListing.query';
import globalMessages from 'messages';
import React from 'react';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import { UPDATE_PAYMENT_METHOD } from './mutations';

type Props = {
  toggleModal: () => void;
  toggleEdit: (data: string) => void;
  id: string;
  status: string;
  type: string;
  accountNumber: string;
  refetchVariables: Record<string, any>;
};

const UpdatePaymentGatewayStatus = (props: Props) => {
  const translate = useTranslate();
  const {
    toggleModal,
    toggleEdit,
    id,
    status,
    type,
    accountNumber,
    refetchVariables,
  } = props;

  const refetchQueries = [
    {
      query: PAYMENT_METHODS,
      variables: refetchVariables,
      fetchPolicy: 'network-only',
    },
  ];
  const { context } = useOperatorHeader();
  const [updatePaymentMethod, { loading }] = useMutation(
    UPDATE_PAYMENT_METHOD,
    {
      onCompleted: () => {
        message.success(translate(messages.successfullyupdated));
        toggleModal();
      },
      refetchQueries,
      context,
    }
  );

  return (
    <Modal
      visible
      maskClosable={false}
      closable={false}
      width={416}
      okText={translate(messages.confirm)}
      cancelText={translate(messages.cancel)}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={() => {
        if (type === 'OfflineBankTransferPaymentMethod' && !accountNumber) {
          message.error(
            translate(globalMessages.PLEASE_FILL_IN_REQUIRED_FIELDS)
          );
          toggleEdit(id);
          return;
        }
        updatePaymentMethod({
          variables: {
            id,
            input: {
              enabled: !status,
            },
          },
        });
      }}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title text-capitalize">
          {status
            ? translate(messages.CONFIRM_DISABLE)
            : translate(messages.CONFIRM_ENABLE)}
        </span>
        <div className="ant-modal-confirm-content">
          {translate(messages.areYouSure)}
        </div>
      </div>
    </Modal>
  );
};

export default UpdatePaymentGatewayStatus;
