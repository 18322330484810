import * as yup from 'yup';
import {
  isTypeBank,
  isPaymentTypeInternal,
  isTypeHexopay,
  isTypeAstroapy,
} from 'constants/paymentTypes';
import messages from 'messages';

export const getValidationSchema = (
  formatMessage: (e: any, options?: any) => any
) => {
  yup.setLocale({
    mixed: {
      required: formatMessage(messages.required),
    },
    string: {
      max: ({ max }) => formatMessage(messages['number.max.error'], { max }),
    },
    number: {
      min: ({ min }) => formatMessage(messages['number.min.error'], { min }),
      max: ({ max }) => formatMessage(messages['number.max.error'], { max }),
    },
  });
  return yup.object().shape({
    name: yup
      .string()
      .max(24)
      .required(),
    type: yup
      .string()
      .nullable()
      .when(['paymentType', 'withdrawalProvider'], {
        is: (paymentType, withdrawalProvider) => {
          const isInternal = isPaymentTypeInternal(paymentType);
          return isInternal || (!isInternal && withdrawalProvider);
        },
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    paymentChannel: yup
      .string()
      .nullable()
      .when(['paymentType', 'withdrawalProvider', 'type'], {
        is: (paymentType, depositProvider, type) =>
          !isPaymentTypeInternal(paymentType) && depositProvider && type,
        then: yup
          .string()
          .nullable()
          .required(),
      }),
    bank: yup
      .string()
      .nullable()
      .when(['type', 'paymentType'], {
        is: (type, paymentType) =>
          isTypeBank(type) && isPaymentTypeInternal(paymentType),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    accountNumber: yup
      .string()
      .nullable()
      .when(['paymentType', 'type'], {
        is: (paymentType, type) =>
          isPaymentTypeInternal(paymentType) &&
          !isTypeHexopay(type) &&
          !isTypeAstroapy(type),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    accountName: yup
      .string()
      .nullable()
      .when(['type', 'paymentType'], {
        is: (type, paymentType) =>
          isTypeBank(type) && isPaymentTypeInternal(paymentType),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    nickname: yup
      .string()
      .nullable()
      .when(['type', 'paymentType'], {
        is: (type, paymentType) =>
          !isTypeBank(type) &&
          isPaymentTypeInternal(paymentType) &&
          !isTypeHexopay(type) &&
          !isTypeAstroapy(type),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    withdrawalProvider: yup
      .string()
      .nullable()
      .when('paymentType', {
        is: (value) => !isPaymentTypeInternal(value),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    transactionFee: yup
      .number()
      .min(0)
      .when('transactionFeeType', {
        is: 'RELATIVE',
        then: yup.number().max(100),
      }),
  });
};
