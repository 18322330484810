import React from 'react';
import Icon from '@ant-design/icons';
import { Popover, Button, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import styled from 'styled-components';
import { TabId } from 'types/nav';
import { CustomColumn } from 'types/customColumn';
import useTranslate from 'utils/useTranslate';
import { ReactComponent as ViewColumnIcon } from 'components/assets/view_column.svg';
import InnerFormNew from './InnerFormNew';

const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-title {
    padding: 12px;
  }
`;

type Props = {
  className?: string;
  tabId: TabId;
  iconOnly?: boolean;
  checkAll?: boolean;
};

function ColumnsFilterNew(props: Props) {
  const translate = useTranslate();
  const { className, tabId, iconOnly, checkAll } = props;
  const formikRef = React.useRef<any>(null);

  const [isDropDown, setIsDropDown] = React.useState(false);
  const uniqueId = `columns-filter-button-${tabId}`;

  const handleVisibleChange = (e: boolean) => {
    setIsDropDown(e);
  };

  const toggleDropDown = () => setIsDropDown((prev) => !prev);

  return (
    <StyledPopover
      title={
        <div className="d-flex justify-content-space-between align-items-center">
          <FormattedMessage
            id="custom-columns.text"
            defaultMessage="Custom Columns"
          />
          <div>
            {checkAll && (
              <Button
                size="small"
                className="mr-1"
                onClick={(e) => {
                  e.preventDefault();
                  formikRef.current.setColumnOrder(
                    formikRef.current.columnOrder.map(
                      (column: CustomColumn) => ({
                        ...column,
                        visible: true,
                      })
                    )
                  );
                }}
              >
                <FormattedMessage
                  id="check-all.text"
                  defaultMessage="Check All"
                />
              </Button>
            )}
            <Button
              size="small"
              onClick={(e) => {
                e.preventDefault();
                formikRef.current.setColumnOrder(
                  formikRef.current.initialColumns.filter(
                    (cols: CustomColumn) =>
                      'title' in cols && typeof cols.title !== 'undefined'
                  )
                );
              }}
            >
              <FormattedMessage id="reset.text" defaultMessage="Reset" />
            </Button>
          </div>
        </div>
      }
      style={{ background: 'white' }}
      placement="bottomLeft"
      getPopupContainer={() => document.getElementById(uniqueId) as any}
      visible={isDropDown}
      trigger="click"
      onVisibleChange={handleVisibleChange}
      content={
        <InnerFormNew
          toggleDropDown={toggleDropDown}
          tabId={tabId}
          ref={formikRef}
        />
      }
      className={className}
    >
      <div className="cursor-pointer d-flex align-items-center" id={uniqueId}>
        {iconOnly ? (
          <Tooltip title={translate(messages['custom-columns.text'])}>
            <Icon
              className="mr-2"
              component={ViewColumnIcon}
              style={{ fontSize: '22px' }}
            />
          </Tooltip>
        ) : (
          <Icon
            className="mr-2"
            component={ViewColumnIcon}
            style={{ fontSize: '22px' }}
          />
        )}
        {!iconOnly ? (
          <FormattedMessage
            id="custom-columns.text"
            defaultMessage="Custom Columns"
          />
        ) : (
          ''
        )}{' '}
      </div>
    </StyledPopover>
  );
}

export default ColumnsFilterNew;
