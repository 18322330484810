import * as yup from 'yup';
import thirdPartyProviders from 'constants/thirdPartyProviders';
import messages from 'messages';

export const validationSchema = (translate: any) =>
  yup.object().shape({
    id: yup.string().nullable(),
    name: yup
      .string()
      .trim()
      .required(translate(messages.REQUIRED)),
    type: yup.string().required(translate(messages.REQUIRED)),
    key: yup
      .string()
      .trim()
      .when('type', {
        is: (val) => thirdPartyProviders.MIDPAY_DIRECT !== val,
        then: yup.string().required(),
      }),
    merchantCode: yup
      .string()
      .trim()
      .when('type', {
        is: (val) => thirdPartyProviders.DIORPAY === val,
        then: yup.string().required(),
      }),
    midpayDirectApiKey: yup
      .string()
      .trim()
      .when('type', {
        is: (val) => thirdPartyProviders.MIDPAY_DIRECT === val,
        then: yup.string().required(),
      }),
    paymentGateway: yup
      .string()
      .trim()
      .when(['type', 'midpayDirectApiKey'], {
        is: (type, midpayDirectApiKey) =>
          thirdPartyProviders.MIDPAY_DIRECT === type && midpayDirectApiKey,
        then: yup.string().required(translate(messages.REQUIRED)),
      }),
    credentials: yup.array().when(['type', 'paymentGateway'], {
      is: (type, paymentGateway) =>
        thirdPartyProviders.MIDPAY_DIRECT === type && paymentGateway,
      then: yup.array(yup.string().required(translate(messages.REQUIRED))),
    }),
  });
