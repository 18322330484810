import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { CREATE_CUSTOM_FILTER } from 'graphql/mutations/customFilter.mutation';
import { GET_CUSTOM_FILTERS } from 'graphql/queries/customFilter.query';
import isEmpty from 'lodash/isEmpty';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import { StyledModal } from 'styles';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import SearchForm from './components/SearchForm';

type Props = {
  filters: any;
  contextKey: any;
  onFiltersUpdate?: (filter: object) => void;
  refetchCustomFilters?: any;
};

const SharedSavedSearch = (props: Props) => {
  const translate = useTranslate();
  const { filters, contextKey, onFiltersUpdate, refetchCustomFilters } = props;
  const [isShow, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [saveSearch] = useMutation(CREATE_CUSTOM_FILTER);
  const handleShowModal = () => setShow(!isShow);
  const handleSubmit = async (input: any) => {
    try {
      setLoading(true);
      if (!isEmpty(removeNull(filters))) {
        const values = {
          name: input.name,
          context: contextKey,
          filter: removeNull({ ...filters }),
        };
        await saveSearch({
          variables: {
            input: values,
          },
          refetchQueries: [
            {
              query: GET_CUSTOM_FILTERS,
              variables: {
                filter: {
                  context: {
                    eq: contextKey,
                  },
                },
              },
            },
          ],
        });
        setLoading(false);
        message.success(translate(messages['search-saved.text']));

        if (onFiltersUpdate) {
          onFiltersUpdate(values);
        }

        if (refetchCustomFilters) {
          refetchCustomFilters();
        }

        handleShowModal();
      } else {
        setLoading(false);
        message.error(
          translate(messages['cant-save-empty-filters-error.text'])
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ALink onClick={handleShowModal}>
        <FormattedMessage id="save-search.text" defaultMessage="Save Search" />
      </ALink>
      {isShow && (
        <StyledModal
          style={{ maxWidth: 400 }}
          title={
            <FormattedMessage
              id="save-search.text"
              defaultMessage="Save Search"
            />
          }
          visible
          footer={false}
          onCancel={handleShowModal}
        >
          <SearchForm
            initialValues={{ name: null }}
            onSubmit={handleSubmit}
            onClose={handleShowModal}
            isLoading={isLoading}
          />
        </StyledModal>
      )}
    </>
  );
};

SharedSavedSearch.defaultProps = {
  filters: {},
  contextKey: '',
};

export default SharedSavedSearch;
