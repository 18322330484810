import React, { useState } from 'react';
import { StyledTagContainer } from 'styles';
import messages from 'messages';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import useTranslate from 'utils/useTranslate';
import { isEqual } from 'lodash';
import { ALink } from 'components/ALink/ALink';
import LegacyIconToggle from 'components/LegacyIconToggle';
import useFilter from 'contexts/Filter';
import FilterConditions from './FilterConditions';
import { TableFilterType } from '../../types';

const HeaderLeft = () => {
  const translate = useTranslate();
  const { filter, setFilterField, defaultFilter, clearFilter } = useFilter();
  const [expandedTags, toggleExpandedTags] = useState<boolean>(false);

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className="d-flex align-items-start flex-g-1">
      <ToggleSidebarHead />
      <StyledTagContainer ref={ref} isExpanded={expandedTags}>
        <span className="ml-1">{translate(messages.FILTER_CONDITIONS)}: </span>
        <FilterConditions
          filter={filter as TableFilterType}
          setFilterField={setFilterField}
        />
      </StyledTagContainer>
      <LegacyIconToggle
        forwardedRef={ref}
        filters={filter}
        expandedTags={expandedTags}
        toggleExpandedTags={toggleExpandedTags}
      />
      {!isEqual(filter, defaultFilter) && (
        <ALink onClick={() => clearFilter()} style={{ marginTop: '0.35rem' }}>
          {translate(messages.CLEAR_ALL)}
        </ALink>
      )}
    </div>
  );
};

export default HeaderLeft;
