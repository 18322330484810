import gql from 'graphql-tag';

export const BANNERS = gql`
  query Banners($filter: BannersFilterInput, $first: Int, $after: Binary) {
    banners(filter: $filter, after: $after, first: $first) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          promo {
            id
            description
            termsAndConditions
            name
          }
          name
          link
          rank
          enabledDesktop
          enabledMobile
          enabledHorizontal
          imageDesktop {
            uri
            id
          }
          imageHorizontal {
            uri
            id
          }
          imageMobile {
            id
            uri
          }
        }
      }
    }
  }
`;

export const BANNER_LISTS = gql`
  query Banners {
    banners {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
