// eslint-disable-next-line max-classes-per-file
import React from 'react';
import { Tabs } from 'antd';
import { DragSource, DropTarget } from 'react-dnd';

// Drag & Drop node
class TabNode extends React.Component<any, {}> {
  render() {
    const { connectDragSource, connectDropTarget, children } = this.props;
    return connectDragSource(connectDropTarget(children));
  }
}

const cardTarget = {
  drop(props: Record<string, any>, monitor: Record<string, any>) {
    const dragKey = monitor.getItem().index;
    const hoverKey = props.index;

    if (dragKey === hoverKey) {
      return;
    }

    props.moveTabNode(dragKey, hoverKey);
    // eslint-disable-next-line no-param-reassign
    monitor.getItem().index = hoverKey;
  },
};

const cardSource = {
  beginDrag(props: Record<string, any>) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const WrapTabNode = DropTarget('DND_NODE', cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource('DND_NODE', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(TabNode)
);

class DraggableTabs extends React.Component<any, { order: any[] }> {
  constructor(props: Record<string, any>) {
    super(props);

    this.state = {
      order: [],
    };
  }

  moveTabNode = (dragKey: number, hoverKey: number) => {
    let { order: newOrder } = this.state;
    newOrder = newOrder.slice();
    const { children } = this.props;

    React.Children.forEach(children, (c) => {
      if (newOrder.indexOf((c as any).key) === -1) {
        newOrder.push((c as any).key);
      }
    });

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);

    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);

    this.setState({
      order: newOrder,
    });
  };

  renderTabBar = (props: Record<string, any>, DefaultTabBar: any) => (
    <DefaultTabBar {...props}>
      {(newNode: Record<string, any>) => (
        <WrapTabNode
          key={newNode.key}
          index={newNode.key}
          moveTabNode={this.moveTabNode}
        >
          {newNode}
        </WrapTabNode>
      )}
    </DefaultTabBar>
  );

  render() {
    const { order } = this.state;
    const { children } = this.props;

    const tabs: Record<string, any>[] = [];
    React.Children.forEach(children, (c) => {
      tabs.push(c as any);
    });

    const orderTabs = tabs.slice().sort((a, b) => {
      const orderA = order.indexOf(a.key);
      const orderB = order.indexOf(b.key);

      if (orderA !== -1 && orderB !== -1) {
        return orderA - orderB;
      }
      if (orderA !== -1) {
        return -1;
      }
      if (orderB !== -1) {
        return 1;
      }

      const ia = tabs.indexOf(a);
      const ib = tabs.indexOf(b);

      return ia - ib;
    });

    return (
      <Tabs renderTabBar={this.renderTabBar} {...this.props}>
        {orderTabs}
      </Tabs>
    );
  }
}

export default DraggableTabs;
