import React from 'react';
import { PoweroffOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useApolloClient } from '@apollo/react-hooks';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import Storage from 'constants/storage';
import { useAccount } from 'store/accountState';
import { useHistory } from 'react-router-dom';
import { StyledProfileDropdown } from './styles';

export const ProfileDropdown = () => {
  const translate = useTranslate();
  const history = useHistory();
  const client = useApolloClient();
  const {
    resetAccount,
    account: { account: user },
  } = useAccount();

  const handleSignOut = () => {
    localStorage.removeItem(Storage.ACCESS_TOKEN);
    localStorage.removeItem(Storage.SA_ACCESS_TOKEN);
    localStorage.removeItem(Storage.SA_REFRESH_TOKEN);
    localStorage.removeItem(Storage.ACTIVE_PORTAL);
    localStorage.removeItem(Storage.APP_CONFIG);
    client.cache.reset();
    resetAccount();
    history.push('/controlcenter/signin');
  };

  return (
    <StyledProfileDropdown
      overlayClassName="topbar-menu"
      overlay={
        <Menu>
          <Menu.Item onClick={handleSignOut}>
            <PoweroffOutlined className="mr-2" style={{ fontSize: 14 }} />
            <span>{translate(messages['logout.text'])}</span>
          </Menu.Item>
        </Menu>
      }
      placement="bottomRight"
    >
      <a className="ant-dropdown-link" href="#/" data-target="profile-username">
        {user?.username}
      </a>
    </StyledProfileDropdown>
  );
};

export default ProfileDropdown;
