import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { useFormik } from 'formik';
import {
  CREATE_REBATE_GROUP,
  UPDATE_REBATE_GROUP,
} from 'graphql/mutations/rebateGroup.mutation';
import { DynamicObj } from 'interfaces/user.interface';
import { identity, omit, pick, pickBy } from 'lodash';
import { REBATE_GROUPS } from 'pages/components/Rebates/query';
import moment from 'moment';
import ms from 'ms';
import React, { useState } from 'react';
import coercedGet from 'utils/coercedGet';
import useDate from 'utils/useDate';
import useTranslate from 'utils/useTranslate';
import * as Yup from 'yup';
import { RebateGroupTypes, useRebateGroupState } from '../../context';
import RebatePublish from './components/RebatePublish';
import { STEPS_COMPONENTS } from './components/Steps';
import {
  CreateRebateGroupTypes,
  CreateRebateSteps,
  formSchemas,
  payoutSettingsSchema,
  rebateGroupSettingsSchema,
  rebateSettingsSchema,
  SettlementPeriod,
} from './constants';
import messages from './messages';
import { FORMIK_ATTR, validateFormikAttr } from './utilities';

type Props = {
  activeStep: number;
  defaultValues: DynamicObj;
  isSubmitting: boolean;
  setIsSubmitting: (e: boolean) => void;
  hideDrawer: () => void;
  setActiveStep: (e: number) => void;
  isSaveAndExit: boolean;
  setIsSaveAndExit: (e: boolean) => void;
  refetch: (e?: any) => void;
};

const InnerForm = React.forwardRef((props: Props, ref) => {
  const { daysOfMonth } = useDate();
  const {
    activeStep,
    defaultValues,
    isSubmitting,
    setIsSubmitting,
    hideDrawer,
    setActiveStep,
    isSaveAndExit,
    setIsSaveAndExit,
    refetch,
  } = props;
  const translate = useTranslate();

  const createFormValuesGroup = [
    ...Object.keys(rebateSettingsSchema(translate)),
    ...Object.keys(payoutSettingsSchema(translate)),
    ...Object.keys(rebateGroupSettingsSchema(translate)),
  ];

  const createRebateSchema = Yup.object().shape({
    ...rebateSettingsSchema(translate),
    ...payoutSettingsSchema(translate),
    ...rebateGroupSettingsSchema(translate),
  });

  const formSchema = Yup.object().shape({
    ...formSchemas(translate)[activeStep],
  } as any);

  const [createRebateGroup] = useMutation(CREATE_REBATE_GROUP);
  const [updateRebateGroup] = useMutation(UPDATE_REBATE_GROUP);

  const [rebateGroupState, rebateGroupDispatch] = useRebateGroupState() as any;

  const initialValues = {
    name: '',
    qualifyingMemberLoyaltyLevels: [],
    excludedMemberLevels: [],
    validityDateTimeRange: {
      start: moment().startOf('day'),
      end: null,
    },
    type: CreateRebateGroupTypes.PERIODIC,
    payoutSettlementPeriod: SettlementPeriod.DAILY,
    payoutSettlementDayOfWeek: moment.weekdays().indexOf('Sunday'),
    payoutSettlementDayOfMonth: daysOfMonth.indexOf('1st'),
    payoutSettlementDateAndMonth: moment().startOf('year'),
    payoutSettlementTime: moment().startOf('day'),
    automaticPayout: false,
    turnoverRequirementMultiplier: 0,
    claimOffsetDuration: '',
    claimOffsetDurationPeriod: 'd',
    claimExpiryDuration: '',
    claimExpiryDurationPeriod: 'd',
    payoutExpiryDuration: '',
    payoutExpiryDurationPeriod: 'd',
    minimumPayout: null,
    maximumPayout: null,
    percentages: [],
    levels: [{ minimum: 0 }],
    status: '',
    ...defaultValues,
  };

  const [formValues, setFormValues] = useState({});

  const isEdit = rebateGroupState.activeRebateGroup;

  const formAction = !isEdit ? createRebateGroup : updateRebateGroup;

  const getReinitialization = () => {
    if (activeStep === 2) {
      return isSaveAndExit;
    }
    return true;
  };

  const getDurations = (values: DynamicObj) => {
    const keys = [
      'claimOffsetDurationPeriod',
      'claimExpiryDurationPeriod',
      'payoutExpiryDurationPeriod',
    ];
    return keys.reduce((acc, period) => {
      const duration = period.replace('Period', '');
      let value = ms(
        `0${values[period] === '-' ? 'ms' : values[period]}`
      )?.toString();
      if (!values[`no${duration}`]) {
        if (values[period] === 'm') {
          value = ms(`${ms('30 days') * values[duration]}`)?.toString();
        } else {
          value = ms(`${values[duration]}${values[period]}`)?.toString();
        }
      }
      return { ...acc, [duration]: value };
    }, {});
  };

  const formikProps = useFormik({
    enableReinitialize: getReinitialization(),
    initialValues,
    validationSchema:
      !isEdit && activeStep === 2 ? createRebateSchema : formSchema,
    onSubmit: async (values, actions) => {
      const tempValues: any = { ...values };

      setIsSubmitting(true);
      const validityDateTimeRange = {
        start: values.validityDateTimeRange.start
          ? values.validityDateTimeRange.start.startOf('day').toISOString()
          : '',
        end: tempValues?.validityDateTimeRange?.end?.endOf
          ? tempValues?.validityDateTimeRange?.end?.endOf('day').toISOString()
          : '',
      };

      const durations = getDurations(values);

      const percentages = validateFormikAttr(FORMIK_ATTR.PERCENTAGES, {
        ...values.percentages,
      });

      const levels = validateFormikAttr(FORMIK_ATTR.LEVELS, [
        ...values.levels,
      ]).map((e) => {
        const level = {
          minimum: e.minimum,
          percentages: e.percentages.filter(
            (p: Object) => Object.keys(pickBy(p, identity)).length !== 0
          ),
        };

        return level;
      });

      let input: any = {
        ...pick(values, [
          'name',
          'type',
          'qualifyingMemberLoyaltyLevels',
          'excludedMemberLevels',
          'minimumPayout',
          'maximumPayout',
          'turnoverRequirementMultiplier',
          'automaticPayout',
        ]),
        percentages,
        levels,
        minimumPayout:
          values.minimumPayout !== '' ? values.minimumPayout : null,
        maximumPayout:
          values.maximumPayout !== 0 && values.minimumPayout !== ''
            ? values.maximumPayout
            : null,
        ...durations,
        validityDateTimeRange: pickBy(validityDateTimeRange, identity),
      };

      if (values.type === CreateRebateGroupTypes.PERIODIC) {
        let payoutSettlementValue = null;
        let toBeOmittedFields = ['percentages'];
        switch (values.payoutSettlementPeriod) {
          case SettlementPeriod.DAILY:
            toBeOmittedFields = [
              ...toBeOmittedFields,
              'payoutSettlementDayOfWeek',
              'payoutSettlementDayOfMonth',
              'payoutSettlementDateAndMonth',
            ];
            break;
          case SettlementPeriod.WEEKLY:
            toBeOmittedFields = [
              ...toBeOmittedFields,
              'payoutSettlementDayOfMonth',
              'payoutSettlementDateAndMonth',
            ];
            payoutSettlementValue = {
              payoutSettlementDayOfWeek: values.payoutSettlementDayOfWeek,
            };
            break;
          case SettlementPeriod.MONTHLY:
            toBeOmittedFields = [
              ...toBeOmittedFields,
              'payoutSettlementDayOfWeek',
              'payoutSettlementDateAndMonth',
            ];
            payoutSettlementValue = {
              payoutSettlementDayOfMonth: values.payoutSettlementDayOfMonth + 1,
            };
            break;
          case SettlementPeriod.ANNUALLY:
            toBeOmittedFields = [
              ...toBeOmittedFields,
              'payoutSettlementDayOfWeek',
              'payoutSettlementDayOfMonth',
              'payoutSettlementDateAndMonth',
            ];
            payoutSettlementValue = {
              payoutSettlementMonth:
                values.payoutSettlementDateAndMonth.month() + 1,
              payoutSettlementDayOfMonth: values.payoutSettlementDateAndMonth.date(),
            };
            break;

          default:
            payoutSettlementValue = {};
        }

        if (moment.isMoment(values.payoutSettlementTime)) {
          payoutSettlementValue = {
            ...payoutSettlementValue,
            payoutSettlementTime: values.payoutSettlementTime
              .toISOString()
              .split('T')
              .pop(),
          };
        }

        input = {
          ...omit(input, toBeOmittedFields),
          payoutSettlementPeriod: tempValues.payoutSettlementPeriod,
          ...payoutSettlementValue,
        };
      } else {
        input = {
          ...omit(input, ['levels']),
        };
      }

      try {
        const formValueFields = createFormValuesGroup;

        const res = await formAction({
          variables: {
            ...(!isEdit ? {} : { id: rebateGroupState.activeRebateGroup }),
            input: pick(input, formValueFields),
          },
          refetchQueries: [
            {
              query: REBATE_GROUPS,
              variables: rebateGroupState.refetchVariables,
            },
          ],
        });

        setFormValues(pick(input, formValueFields));

        if (!isEdit) {
          rebateGroupDispatch({
            type: RebateGroupTypes.SET_ACTIVE_REBATE_GROUP,
            payload: coercedGet(res, 'data.createRebateGroup'),
            preventFillFor: 'create',
          });
        } else if (isEdit && rebateGroupState.preventFillFor) {
          rebateGroupDispatch({
            type: RebateGroupTypes.RESET_FILL_FOR,
          });
        }
      } finally {
        if (isSaveAndExit) {
          setIsSaveAndExit(false);
        }
        message.success(
          !isEdit
            ? translate(messages['rebates.create-rebate-group-success.text'])
            : translate(messages['rebates.update-rebate-group-success.text']),
          5
        );
        refetch();
        actions.setSubmitting(false);
        setIsSubmitting(false);
      }
    },
  });

  React.useImperativeHandle(
    ref,
    () => ({
      ...formikProps,
    }),
    [formikProps]
  );

  return (
    <div>
      <form onSubmit={formikProps.handleSubmit}>
        {activeStep + 1 <= Object.keys(CreateRebateSteps).length ? (
          <>
            {React.cloneElement(STEPS_COMPONENTS[activeStep], {
              formikProps,
            })}
          </>
        ) : (
          <div
            className="container"
            style={{
              maxWidth: 580,
            }}
          >
            <RebatePublish
              hideDrawer={hideDrawer}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              formikProps={formikProps as any}
              formRef={ref as any}
              isSubmitting={isSubmitting}
              formValues={formValues}
              refetch={refetch}
            />
          </div>
        )}
      </form>
    </div>
  );
});

export default InnerForm;
