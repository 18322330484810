import gql from 'graphql-tag';

export const GET_GAME_CATEGORIES = gql`
  query GameCategories($language: Language) {
    gameCategories {
      name
      id
      dateTimeCreated
      gameTypes
      vendors {
        id
        name(language: $language)
        type
        externalLobby
      }
      icon {
        id
        uri
      }
      banner {
        id
        uri
      }
      heroBanner {
        id
        uri
      }
      rank
    }
  }
`;

export const GET_GAME_CATEGORIES_NEXT = gql`
  query GameCategories($language: Language) {
    gameCategories {
      name
      id
      dateTimeCreated
      gameTypes
      vendors {
        id
        name(language: $language)
        type
        externalLobby
      }
      icon {
        id
        uri
      }
      desktopIcon {
        id
        uri
      }
      banner {
        id
        uri
      }
      heroBanner {
        id
        uri
      }
      rank
    }
  }
`;

export const GET_VENDORS = gql`
  query Vendors($filter: VendorsFilterInput, $language: Language) {
    vendors(filter: $filter) {
      totalCount
      edges {
        node {
          id
          type
          name(language: $language)
          externalLobby
        }
      }
    }
  }
`;

export const GAME_CATEGORY = gql`
  query GameCategory($id: ID!, $language: Language) {
    gameCategory(id: $id) {
      id
      platform
      vendors {
        id
        name(language: $language)
        type
        externalLobby(gameCategory: $id)
        image(gameCategory: $id) {
          uri
          id
        }
        desktopImage(gameCategory: $id) {
          uri
          id
        }
      }
    }
  }
`;

export default {};
