import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Row, Col, Tag, Input } from 'antd';
import styled from 'styled-components';
import { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SharedStyledModal } from 'styles/SharedStyledModal';
import { UseFormikErrorMessage } from 'SuperAdminMain/hooks/UseFormikErrorMessage/UseFormikErrorMessage';
import { OperatorValidation } from 'SuperAdminMain/utils/operatorValidation';

const FormLabel = styled.div`
  text-align: right;
  margin-right: 20px;
`;

export const ModalWhitelistIp = ({
  visible,
  onCloseFn,
  existingIpAddresses,
}: IModalWhitelistIp) => {
  const [ipAddress, setIpAddress] = useState<string[]>(
    existingIpAddresses || []
  );
  const { formatMessage } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  function removeIp(ip: string) {
    setIpAddress((prev) => prev.filter((prevIp) => prevIp !== ip));
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ip: '',
    },
    validationSchema: Yup.object().shape({
      ip: OperatorValidation.ipAddress(formatMessage, {
        ipAddresses: ipAddress,
      }),
    }),
    onSubmit: (value: { ip: string }) => {
      setIpAddress((prev) => [...prev, value.ip]);
      formik.resetForm();
      inputRef!.current!.focus();
    },
  });

  const { handleChange, handleSubmit, values } = formik;

  const updateIpAddresses = () => {
    console.log('ipAddress', ipAddress);
  };

  return (
    <SharedStyledModal
      centered
      width={700}
      onCancel={onCloseFn}
      visible={visible}
      title={formatMessage({
        id: 'super-admin-whitelist-ip.text',
        defaultMessage: 'Super Admin Whitelist IP',
      })}
      footer={[
        <Button onClick={onCloseFn}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>,
        <Button type="primary" onClick={updateIpAddresses}>
          <FormattedMessage id="update.text" defaultMessage="Update" />
        </Button>,
      ]}
    >
      <div className="p-3">
        <Row className="pb-5">
          <Col span={4}>
            <FormLabel>
              <FormattedMessage
                id="targeting-ip.text"
                defaultMessage="Targeting IP"
              />
            </FormLabel>
          </Col>
          <Col span={20}>
            <div
              className="d-flex flex-column justify-content-space-between p-2"
              style={{
                borderRadius: '10px 10px 0 0',
                minHeight: '175px',
                width: '100%',
                border: '1px solid lightgray',
              }}
            >
              <div>
                {ipAddress.map((ip) => (
                  <Tag
                    key={ip}
                    className="mt-1"
                    closable
                    onClose={() => removeIp(ip)}
                  >
                    {ip}
                  </Tag>
                ))}
              </div>

              <div>
                <div className="d-flex justify-content-space-between">
                  <Input
                    name="ip"
                    style={{ width: '83%' }}
                    onChange={handleChange}
                    value={values.ip}
                    ref={inputRef as any}
                    placeholder={formatMessage({
                      id: 'ip-address-input-placeholder.text',
                      defaultMessage: 'Enter IP address',
                    })}
                  />
                  <Button
                    type="primary"
                    style={{ width: '15%' }}
                    onClick={() => handleSubmit()}
                  >
                    <FormattedMessage id="add.text" defaultMessage="Add" />
                  </Button>
                </div>
              </div>
            </div>
            <UseFormikErrorMessage formik={formik} name="ip" />
          </Col>
        </Row>
      </div>
    </SharedStyledModal>
  );
};

interface IModalWhitelistIp {
  visible: boolean;
  onCloseFn: () => void;
  existingIpAddresses: string[];
}
