import React from 'react';
import styled from 'styled-components';
import { Collapse, Spin, Empty } from 'antd';
import { FormattedMessage } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import { useQuery } from '@apollo/react-hooks';
import * as moment from 'moment-timezone';
import { useAccount } from 'store/accountState';
import { useReportsTimezone } from 'contexts/ReportsTimezone';

import {
  BetHistoryReport,
  BetHistoryReportsConnectionEdge,
} from 'types/graphqlTypes';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';

import { BET_HISTORY_REPORTS } from '../ReportsTable/queries';
import VendorNames from '../ReportsTable/components/VendorNames';
import ClientFilterNames from './components/ClientFilterNames';
import SingleClientName from './components/SingleClientName';
import DeleteScheduledReport from './components/DeleteScheduledReport';

interface Props {
  ref: any;
}

const EmptyComponent = () => (
  <div className="styled-center" style={{ height: '84vh' }}>
    <Empty />
  </div>
);

export const Schedule: React.FC<Props> = React.forwardRef(
  (_props, ref: any) => {
    const { account } = useAccount() as any;

    const currentUsername = coercedGet(account, 'account.username', '');

    const { filters } = useFilterValues();

    const {
      currentTimezone,
      bypassedCurrentTimezone,
    }: any = useReportsTimezone();
    const { loading, data = {}, refetch } = useQuery(BET_HISTORY_REPORTS, {
      variables: {
        filter: {
          status: {
            eq: 'SCHEDULED',
          },
        },
      },
    });

    React.useEffect(() => {
      if (coercedGet(ref, 'current', null)) {
        // eslint-disable-next-line no-param-reassign
        ref.current = {
          ...coercedGet(ref, 'current', {}),
          refetchSchedules: refetch,
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const edges = coercedGet(data, 'betHistoryReports.edges', []);
    const nodes = edges.map(
      ({ node }: BetHistoryReportsConnectionEdge) => node
    );
    if (loading)
      return (
        <div className="styled-center" style={{ height: '100%' }}>
          <Spin />
        </div>
      );

    return (
      <Container>
        <CollapseHead>
          <div className="head-title">
            <FormattedMessage
              id="member.loyalty.schedule.text"
              defaultMessage="Schedule"
            />
          </div>
        </CollapseHead>

        <StyledCollapse expandIconPosition="right">
          {nodes.length ? (
            nodes.map((node: BetHistoryReport, index: number) => {
              const filter = coercedGet(node, 'filter', {});
              // const creatorUsername = coercedGet(node, 'creator.username', '');
              const dateTimeCreated = coercedGet(node, 'dateTimeCreated', null);

              const dateTimeProcGte = coercedGet(
                filter,
                'dateTimeProcessed.gte',
                null
              );
              const dateTimeProcLte = coercedGet(
                filter,
                'dateTimeProcessed.lte',
                null
              );

              const clientFilts = coercedGet(filter, 'admin.in', []);
              const vendorFilts = coercedGet(filter, 'vendor.in', []);

              return (
                <StyledPanel
                  header={
                    <>
                      <div className="main">
                        {currentUsername} -{' '}
                        {clientFilts.length ? (
                          <SingleClientName adminIds={clientFilts} />
                        ) : (
                          ''
                        )}
                      </div>
                      <div className="sub">
                        {moment
                          .tz(dateTimeCreated, bypassedCurrentTimezone)
                          .format('MMM DD, YYYY hh:mm:ss A') || (
                          <FormattedMessage
                            id="reports.no-date-give.text"
                            defaultMessage="No Date Given"
                          />
                        )}
                      </div>
                    </>
                  }
                  key={coercedGet(node, 'id', index)}
                  disabled={false}
                >
                  <div className="calendar-category">
                    <div>
                      <FormattedMessage
                        id="period.text"
                        defaultMessage="Period"
                      />
                    </div>

                    {!dateTimeProcGte && !dateTimeProcLte ? (
                      <p>
                        {' '}
                        <FormattedMessage
                          id="reports.no-date-give.text"
                          defaultMessage="No Date Given"
                        />
                      </p>
                    ) : (
                      <div>
                        <p>
                          {moment
                            .tz(dateTimeProcGte, currentTimezone)
                            .format('YYYY/MM/DD HH:mm')}{' '}
                          ~
                        </p>{' '}
                        <p>
                          {moment
                            .tz(dateTimeProcLte, currentTimezone)
                            .format('YYYY/MM/DD HH:mm')}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="calendar-category">
                    <div>
                      <FormattedMessage
                        id="client-account.text"
                        defaultMessage="Client Account"
                      />
                    </div>

                    <div>
                      <p>
                        <ClientFilterNames adminIds={clientFilts} />
                      </p>
                    </div>
                  </div>

                  {/* <div className="calendar-category">
                <div>
                  <FormattedMessage
                    id="client-category.text"
                    defaultMessage="Client Category"
                  />
                </div>

                <div>
                  <p>{clientCategory}</p>
                </div>
              </div> */}

                  <div className="calendar-category">
                    <div>
                      <FormattedMessage
                        id="time-zone-selection.text"
                        defaultMessage="Time Zone Selection"
                      />
                    </div>

                    <div>
                      <p>{coercedGet(node, 'timezone', '-')}</p>
                    </div>
                  </div>

                  <div className="calendar-category">
                    <div>
                      <FormattedMessage
                        id="member-id.text"
                        defaultMessage="Member ID"
                      />
                    </div>

                    <div>
                      <p>-</p>
                    </div>
                  </div>

                  <div className="calendar-category">
                    <div>
                      <FormattedMessage
                        id="vendor.text"
                        defaultMessage="Vendor"
                      />
                    </div>

                    <div>
                      <p>
                        <VendorNames vendorIds={vendorFilts} />
                      </p>
                    </div>
                  </div>

                  <div className="calendar-category">
                    <div>
                      <FormattedMessage
                        id="vendor-game-category.text"
                        defaultMessage="Vendor Game Category"
                      />
                    </div>

                    <div>
                      <p>-</p>
                    </div>
                  </div>

                  <div className="calendar-category">
                    <div>
                      <FormattedMessage
                        id="vendor-game-sub-category.text"
                        defaultMessage="Vendor Game Sub Category"
                      />
                    </div>

                    <div>
                      <p>-</p>
                    </div>
                  </div>

                  <div className="calendar-category mt-3">
                    {/* <Button style={{ width: '132px' }}>
                  <FormattedMessage
                    id="reports.cancel-schedule.text"
                    defaultMessage="Cancel Schedule"
                  />
                </Button> */}
                    <DeleteScheduledReport
                      reportId={coercedGet(node, 'id', {})}
                      refetch={refetch}
                    />
                  </div>
                </StyledPanel>
              );
            })
          ) : (
            <EmptyComponent />
          )}
        </StyledCollapse>
      </Container>
    );
  }
);

export default Schedule;

const Container = styled.div`
  width: 303px;
  border-left: 1px #e8e8e8 solid;

  .main {
    font-size: 14px;
    font-weight: 500;
    color: #000000d9;
  }

  .sub {
    font-size: 10px;
    color: #00000073;
  }

  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  .example {
    -ms-overflow-style: none;
  }

  .calendar-category {
    font-size: 12px;
    color: #000000a6;

    line-height: 6px;

    margin: 8px 0;

    display: flex;
    justify-content: space-between;

    p {
      color: #000;
    }
  }
`;

const CollapseHead = styled.div`
  display: flex;
  justify-content: space-between;

  width: 304px;
  height: 54px;

  padding: 8px 16px;

  .head-title {
    font-size: 14px;
    font-weight: 500;
    color: #000000d9;

    transform: translateY(10px);
  }
`;

const StyledCollapse = styled(Collapse)<any>`
  .ant-collapse-item {
    /* height: 56px; */
    width: 303px;
  }

  .ant-collapse-icon-position-left {
    border: none;
  }
`;

const StyledPanel = styled(Collapse.Panel)<{ disabled: boolean }>`
  background: ${({ disabled }) => (!disabled ? '#fff' : '#F2F2F2')};

  /* .main {
    color: ${({ disabled }) => (!disabled ? '#000' : '#00000040')};
  } */

  /* .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    border: 1px red solid;
  } */

  .ant-collapse-content {
    border-top: none;
  }

  .ant-collapse-content-box {
    padding-top: 0;
  }
`;
