import { useMutation } from '@apollo/react-hooks';
import { Button, Image, message, Modal } from 'antd';
import { capitalize, startCase } from 'lodash';
import {
  APPROVE_MEMBER_DOCUMENT,
  confirmModal,
  DELETE_MEMBER_DOCUMENT,
  REJECT_MEMBER_DOCUMENT,
} from 'pages/components/NewMemberDetail/components/DocumentManagement/utils';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import React, { FC, useState } from 'react';
import {
  MemberDocument,
  MemberDocumentStatus,
  MutationApproveMemberDocumentArgs,
  MutationRejectMemberDocumentArgs,
} from 'types/graphqlTypes';

const { Document, Page } = require('react-pdf');

type Props = {
  visible: boolean;
  onClose: () => void;
  memberDocument: MemberDocument;
};

const RenderStatus = ({ status }: { status: MemberDocumentStatus }) => {
  if (status === MemberDocumentStatus.Pending || !status) {
    return <></>;
  }

  if (status === MemberDocumentStatus.Approved) {
    return <p className="text-success fw-500">Approved</p>;
  }

  return <p className="text-danger fw-500">Rejected</p>;
};

export const ViewDocumentModal: FC<Props> = ({
  visible,
  onClose,
  memberDocument,
}) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [approveMemberDocument] = useMutation<
    boolean,
    MutationApproveMemberDocumentArgs
  >(APPROVE_MEMBER_DOCUMENT);

  const [rejectMemberDocument] = useMutation<
    boolean,
    MutationRejectMemberDocumentArgs
  >(REJECT_MEMBER_DOCUMENT);

  const [deleteMemberDocument] = useMutation<
    boolean,
    MutationRejectMemberDocumentArgs
  >(DELETE_MEMBER_DOCUMENT);

  const {
    MEMBER_DOCUMENT_MANAGEMENT_PERMISSION,
  } = useNewMemberDetailPermissions();

  const onConfirm = () => {
    confirmModal(
      async () => {
        await approveMemberDocument({
          variables: {
            id: memberDocument?.id,
          },
        });
        message.success('Document approved');
        onClose();
      },
      () => {},
      'approve'
    );
  };

  const onDelete = () => {
    confirmModal(
      async () => {
        await deleteMemberDocument({
          variables: {
            id: memberDocument?.id,
          },
        });
        message.success('Document deleted');
        onClose();
      },
      () => {},
      'delete'
    );
  };

  const onReject = () => {
    confirmModal(
      async () => {
        await rejectMemberDocument({
          variables: {
            id: memberDocument?.id,
          },
        });
        message.success('Document rejected');
        onClose();
      },
      () => {},
      'reject'
    );
  };

  const name = capitalize(startCase(memberDocument?.type));

  const isPDF = memberDocument?.document?.mimetype === 'application/pdf';

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <Modal
      width={700}
      title="Document Management"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <p>
        File name: <span className="fw-500">{name}</span>
      </p>
      <div>
        <a href={memberDocument?.document?.uri}>Download</a>
      </div>

      <RenderStatus status={memberDocument?.status} />

      <div className="mt-3" style={{ maxHeight: '600px', overflow: 'auto' }}>
        {!isPDF && <Image src={memberDocument?.document?.uri} />}
        {isPDF && (
          <>
            <Document
              file={memberDocument?.document?.uri}
              onLoadSuccess={({ numPages }: any) => setNumberOfPages(numPages)}
              onLoadError={(e: Error) => console.log('onLoadError', e)}
              onSourceError={(e: Error) => console.log('onSourceError', e)}
            >
              <Page pageNumber={pageNumber} />
            </Document>
            <p>
              Page {pageNumber || (numberOfPages ? 1 : '--')} of{' '}
              {numberOfPages || '--'}
            </p>
          </>
        )}
      </div>

      {memberDocument?.status === MemberDocumentStatus.Pending && (
        <div
          className={`mt-3 d-flex ${
            isPDF ? 'justify-content-space-between' : 'justify-content-flex-end'
          }`}
        >
          {isPDF && (
            <div className="d-flex">
              <Button
                className="mr-2"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Previous
              </Button>
              <Button disabled={pageNumber >= numberOfPages} onClick={nextPage}>
                Next
              </Button>
            </div>
          )}
          <div className="d-flex">
            <Button
              disabled={!MEMBER_DOCUMENT_MANAGEMENT_PERMISSION.ALLOWED_APPROVE}
              type="primary"
              className="mr-2"
              onClick={onConfirm}
            >
              Approve
            </Button>
            <Button
              disabled={!MEMBER_DOCUMENT_MANAGEMENT_PERMISSION.ALLOWED_REJECT}
              type="default"
              className="mr-2"
              onClick={onReject}
            >
              Reject
            </Button>
            <Button
              disabled={!MEMBER_DOCUMENT_MANAGEMENT_PERMISSION.ALLOWED_DELETE}
              className="default"
              onClick={onDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
