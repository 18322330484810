import gql from 'graphql-tag';

export const ADMINS = gql`
  query Admins {
    admins {
      edges {
        node {
          id
          company
          adminCode
        }
      }
    }
  }
`;
