import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { DUPLICATE_PAYMENT_METHOD } from 'graphql/mutations/paymentMethodListing.mutation';
import { PAYMENT_METHODS } from 'graphql/queries/paymentMethodListing.query';
import messages from 'messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

type Props = {
  toggleModal: () => void;
  id: string;
  refetchVariables: any;
};

const DuplicatePaymentGateway = (props: Props) => {
  const { toggleModal, id, refetchVariables } = props;
  const translate = useTranslate();

  const [duplicate, { loading }] = useMutation(DUPLICATE_PAYMENT_METHOD, {
    onCompleted: () => {
      message.success(translate(messages.PAYMENT_METHOD_DUPLICATED));
      toggleModal();
    },
  });

  return (
    <Modal
      visible
      maskClosable={false}
      closable={false}
      width={416}
      okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
      cancelText={<FormattedMessage id="cancel.text" defaultMessage="Cancel" />}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={() => {
        duplicate({
          variables: {
            id,
            type: 'PAYMENT_METHOD',
          },
          refetchQueries: [
            {
              query: PAYMENT_METHODS,
              variables: refetchVariables,
            },
          ],
        });
      }}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">
          <FormattedMessage
            id="confirm-duplicate.text"
            defaultMessage="Confirm Duplicate"
          />
        </span>
        <div className="ant-modal-confirm-content">
          <FormattedMessage
            id="are-you-sure?.text"
            defaultMessage="Are you sure?"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DuplicatePaymentGateway;
