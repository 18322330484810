export const memberInfoFields = {
  'member.username': 'Username',
  'member.realName': 'Real Name',
  'member.gender.isNotProper': 'Gender',
  'member.dateOfBirth': 'Date of Birth',
  'member.email': 'Email',
  'member.mobileNumber': 'Mobile Number',
  'member.wechatId': 'WeChat',
  'member.qqId': 'QQ',
  'member.bankAccounts.edges': 'Bank Account',
  'member.status.isNotProper': 'Status',
};

export const memberInfoInternalFields = {
  'member.remarks': 'Member Details Remark',
  'member.tags': 'Labels',
};

export const othersFields = {
  'member.memberLoyaltyLevel': 'VIP Level',
  'member.totalTurnover': 'Total Turnover',
  'member.totalPromoPayout': 'Total Payout',
  status: 'Status',
  'member.lastOnlineDateTime': 'Last Online',
  dateTimeCreated: 'Apply Date',
  'member.registrationDateTime': 'Join Date',
  'processor.username': 'Approved By',
  dateTimeUpdated: 'Approved Date',
};

export const defaultActions = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
};
