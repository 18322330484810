import gql from 'graphql-tag';

export const VENDORS_NAMES = gql`
  query VendorNames($filter: VendorsFilterInput, $after: Binary, $first: Int) {
    vendors(filter: $filter, after: $after, first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GAME_CATEGORY_NAMES = gql`
  query GameCategoryNames {
    gameCategories {
      id
      name
    }
  }
`;

export default {};
