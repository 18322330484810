import React from 'react';
import { Row, Col, Radio, Input, Select, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Controller } from 'react-hook-form';
import { usePMAContext } from '../context';
import { StyledNumberInput, StyledDateSelect } from '../utils';

export const PMAPaymentMethod: React.FC = () => {
  const {
    setState,
    state: {
      automaticPayoutClaiming,
      noClaimOffsetTime,
      noClaimExpiryTime,
      claimOffsetDuration,
      claimExpiryDuration,
      claimOffsetDurationType,
      claimExpiryDurationType,
    },
    form: { control, errors },
  } = usePMAContext();

  return (
    <>
      <Row gutter={16}>
        <Col span={12} className="mt-2">
          {/* PAYMENT METHOD */}
          <span className="fs-13">
            <FormattedMessage
              id="payment-method.text"
              defaultMessage="Payment Method"
            />
          </span>

          <Controller
            control={control}
            name="automaticPayoutClaiming"
            render={({ value, onChange }) => (
              <Radio.Group
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  setState({ automaticPayoutClaiming: e.target.value });
                }}
              >
                <Radio
                  value
                  style={{
                    width: '150px',
                  }}
                >
                  <FormattedMessage
                    id="automatic-payout.text"
                    defaultMessage="Automatic Payout"
                  />
                </Radio>

                <Radio value={false}>
                  <FormattedMessage
                    id="self-claim.text"
                    defaultMessage="Self-Claim"
                  />
                </Radio>
              </Radio.Group>
            )}
          />

          {/* TURNOVER REQUIREMENT */}
          <div className="mt-2">
            <span className="fs-13">
              <FormattedMessage
                id="turnover-requirement.text"
                defaultMessage="Turnover Requirement"
              />
            </span>

            <Controller
              as={Input}
              control={control}
              name="turnoverRequirementMultiplier"
              style={{ width: '100%' }}
            />

            <span style={{ color: 'red' }}>
              {errors.turnoverRequirementMultiplier?.message}
            </span>
          </div>
        </Col>
      </Row>

      {!automaticPayoutClaiming && (
        <>
          <Row className="mt-2" gutter={16}>
            <Col span={12}>
              {/* CLAIM OFFSET */}
              <span className="fs-13">
                <FormattedMessage
                  id="claim-offset.text"
                  defaultMessage="Claim Offset"
                />
              </span>

              <div>
                <Controller
                  control={control}
                  name="claimOffsetDuration"
                  render={({ onChange }) => (
                    <StyledNumberInput
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        setState({
                          claimOffsetDuration: e.target.value,
                        });
                      }}
                      style={{ width: '70%' }}
                      value={claimOffsetDuration}
                      disabled={noClaimOffsetTime}
                    />
                  )}
                />

                <StyledDateSelect
                  onChange={(e: any) =>
                    setState({ claimOffsetDurationType: e.toString() })
                  }
                  value={claimOffsetDurationType}
                  style={{ width: '30%' }}
                  disabled={noClaimOffsetTime}
                >
                  <Select.Option key="Days" value="days">
                    <FormattedMessage id="days.text" defaultMessage="Days" />
                  </Select.Option>

                  <Select.Option key="Weeks" value="weeks">
                    <FormattedMessage id="weeks.text" defaultMessage="Weeks" />
                  </Select.Option>

                  <Select.Option key="Months" value="months">
                    <FormattedMessage
                      id="months.text"
                      defaultMessage="Months"
                    />
                  </Select.Option>
                </StyledDateSelect>
              </div>

              <span style={{ color: 'red' }}>
                {errors.claimOffsetDuration?.message}
              </span>
            </Col>

            <Col span={12}>
              {/* NO CLAIM OFFSET TIME */}
              <br />
              <div style={{ transform: 'translateY(4px)' }}>
                <Checkbox
                  defaultChecked={noClaimOffsetTime}
                  value={noClaimOffsetTime}
                  onChange={(e) =>
                    setState({ noClaimOffsetTime: e.target.checked })
                  }
                />
                &nbsp;
                <span className="fs-13">
                  <FormattedMessage
                    id="no-claim-offset-time.text"
                    defaultMessage="No Claim Offset Time"
                  />
                </span>
              </div>
            </Col>
          </Row>

          <Row className="mt-2" gutter={16}>
            <Col span={12}>
              {/* CLAIM EXPIRY */}
              <span className="fs-13">
                <FormattedMessage
                  id="claim-expiry.text"
                  defaultMessage="Claim Expiry"
                />
              </span>

              <div>
                <Controller
                  control={control}
                  name="claimExpiryDuration"
                  render={({ onChange }) => (
                    <StyledNumberInput
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        setState({
                          claimExpiryDuration: e.target.value,
                        });
                      }}
                      style={{ width: '70%' }}
                      value={claimExpiryDuration}
                      disabled={noClaimExpiryTime}
                    />
                  )}
                />

                <StyledDateSelect
                  onChange={(e: any) => setState(e.toString())}
                  value={claimExpiryDurationType}
                  style={{ width: '30%' }}
                  disabled={noClaimExpiryTime}
                >
                  <Select.Option key="Days" value="days">
                    <FormattedMessage id="days.text" defaultMessage="Days" />
                  </Select.Option>

                  <Select.Option key="Weeks" value="weeks">
                    <FormattedMessage id="weeks.text" defaultMessage="Weeks" />
                  </Select.Option>

                  <Select.Option key="Months" value="months">
                    <FormattedMessage
                      id="months.text"
                      defaultMessage="Months"
                    />
                  </Select.Option>
                </StyledDateSelect>
              </div>

              <span style={{ color: 'red' }}>
                {errors.claimExpiryDuration?.message}
              </span>
            </Col>

            <Col span={12}>
              {/* NO CLAIM EXPIRY TIME */}
              <br />
              <div style={{ transform: 'translateY(4px)' }}>
                <Checkbox
                  defaultChecked={noClaimExpiryTime}
                  value={noClaimExpiryTime}
                  onChange={(e) =>
                    setState({ noClaimExpiryTime: e.target.checked })
                  }
                />
                &nbsp;
                <span className="fs-13">
                  <FormattedMessage
                    id="no-claim-expiry-time.text"
                    defaultMessage="No Claim Expiry Time"
                  />
                </span>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
