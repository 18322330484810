import React from 'react';
import { useFilterValues } from 'contexts/Filters';
import { Tag } from 'antd';
import coercedGet from 'utils/coercedGet';
import { PAYMENT_TYPES } from 'constants/paymentTypes';
import DepositProviderTag from 'components/DepositProviderTag';
import BankTag from 'components/BankTag';
import { isEmpty } from 'lodash';
import useTranslate from 'utils/useTranslate';
import depositsMsgs from '../../../messages';

const FilterConditions = () => {
  const { filters, onFilterChange } = useFilterValues();

  const handleRemoveFilterTag = (
    name: string,
    value: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const field = coercedGet(filters, name, []);
    const data = field.in.filter((item: string) => item !== value);
    const newField = {
      in: data,
    };
    onFilterChange({
      ...filters,
      [`${name}`]: data.length ? newField : null,
    });
  };

  const handleRemoveAmountTag = (
    name: string,
    range: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const data = { ...filters[`${name}`] };
    delete data[`${range}`];
    onFilterChange({
      ...filters,
      [`${name}`]: isEmpty(data) ? null : data,
    });
  };

  const handleRemovePaymentMethodTag = (
    value: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const field = coercedGet(filters, 'paymentMethodType', []);
    const data = field.in.filter((item: string) => item !== value);
    const newField = {
      in: data,
      nin: [PAYMENT_TYPES.OFFLINE.MANUAL],
    };
    onFilterChange({
      ...filters,
      paymentMethodType: data.length ? newField : null,
    });
  };

  const translate = useTranslate();

  const methodTypes = {
    [PAYMENT_TYPES.OFFLINE.BANK]: `${translate(
      depositsMsgs.internal
    )} ${translate(depositsMsgs.banktransfer)}`,
    [PAYMENT_TYPES.ONLINE.BANK]: `${translate(
      depositsMsgs.thirdparty
    )} ${translate(depositsMsgs.banktransfer)}`,
    [PAYMENT_TYPES.OFFLINE.ALIPAY]: `${translate(
      depositsMsgs.internal
    )} ${translate(depositsMsgs.alipay)}`,
    [PAYMENT_TYPES.ONLINE.ALIPAY]: `${translate(
      depositsMsgs.thirdparty
    )} ${translate(depositsMsgs.alipay)}`,
    [PAYMENT_TYPES.OFFLINE.WECHAT]: `${translate(
      depositsMsgs.internal
    )} ${translate(depositsMsgs.wechat)}`,
    [PAYMENT_TYPES.ONLINE.WECHAT]: `${translate(
      depositsMsgs.thirdparty
    )} ${translate(depositsMsgs.wechat)}`,
    [PAYMENT_TYPES.OFFLINE.CRYPTOCURRENCY]: `${translate(
      depositsMsgs.cryptocurrency
    )}`,
  };

  return (
    <>
      {filters.name
        ? filters.name.in.map((name: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('name', name, e)}
            >
              {name}
            </Tag>
          ))
        : null}
      {filters.paymentMethodType && filters.paymentMethodType.in
        ? filters.paymentMethodType.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemovePaymentMethodTag(item, e)}
            >
              {methodTypes[item]}
            </Tag>
          ))
        : null}
      {filters.depositProvider
        ? filters.depositProvider.in.map((item: string, key: number) => (
            <DepositProviderTag
              onClose={(e) => handleRemoveFilterTag('depositProvider', item, e)}
              key={key}
              id={item}
            />
          ))
        : null}
      {filters.bank
        ? filters.bank.in.map((item: string, key: number) => (
            <BankTag
              onClose={(e) => handleRemoveFilterTag('bank', item, e)}
              key={key}
              bankCode={item}
            />
          ))
        : null}
      {filters.nickname
        ? filters.nickname.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('nickname', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.currentAccumulation && filters.currentAccumulation.gte ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveAmountTag('currentAccumulation', 'gte', e)
          }
        >
          {translate(depositsMsgs.minCurrentAccumulation)}:{' '}
          {filters.currentAccumulation.gte}
        </Tag>
      ) : null}
      {filters.currentAccumulation && filters.currentAccumulation.lte ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveAmountTag('currentAccumulation', 'lte', e)
          }
        >
          {translate(depositsMsgs.maxCurrentAccumulation)}:{' '}
          {filters.currentAccumulation.lte}
        </Tag>
      ) : null}
      {filters.maximumDepositAmount && filters.maximumDepositAmount.gte ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveAmountTag('maximumDepositAmount', 'gte', e)
          }
        >
          {translate(depositsMsgs.minDepositAmount)}:{' '}
          {filters.maximumDepositAmount.gte}
        </Tag>
      ) : null}
      {filters.maximumDepositAmount && filters.maximumDepositAmount.lte ? (
        <Tag
          closable
          onClose={(e) =>
            handleRemoveAmountTag('maximumDepositAmount', 'lte', e)
          }
        >
          {translate(depositsMsgs.maxDepositAmount)}:{' '}
          {filters.maximumDepositAmount.lte}
        </Tag>
      ) : null}
      {filters.enabled
        ? filters.enabled.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('enabled', item, e)}
            >
              {translate(depositsMsgs[item ? 'enable' : 'disable'])}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
