import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers';
import { Button, message, Popconfirm } from 'antd';
import { CREATE_JOB } from 'graphql/mutations/createJob.mutation';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import { VALID_PASSWORD } from 'graphql/queries/validPassword.query';
import messages from 'messages';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { jobsActions, JobType } from 'store/jobState/jobState';
import useTranslate from 'utils/useTranslate';
import { RootState } from '../../../../../store/types';
import { ExcelProgress } from './components/ExcelProgress';
import { ImportMembersForm } from './components/ImportMembersForm';
import { ResultReport } from './components/ResultReport';
import { FormDefaultValues, StyledModal, validationSchema } from './utils';

type Props = {
  refetchParams: any;
};

const defaultValues = {
  passwordChange: true,
  withdrawalPinChange: false,
  status: true,
  memberMarker: '',
  memberLoyaltyLevels: [],
  password: '',
  tags: [],
  fileId: '',
};

const ImportMembers = ({ refetchParams }: Props) => {
  const [visible, setVisible] = useState(false);
  const [excelProgress, setExcelProgress] = useState(false);
  const [reportComplete, setReportComplete] = useState(false);
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { jobs } = useSelector((state: RootState) => state.jobs);

  const [createJob] = useMutation(CREATE_JOB);

  const client = useApolloClient();

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    control,
    errors,
    reset,
    setError,
  } = useForm<FormDefaultValues>({
    defaultValues,
    mode: 'onBlur',
    resolver: yupResolver(validationSchema(translate)),
  });

  useEffect(() => {
    register('passwordChange');
    register('withdrawalPinChange');
    register('status');
    register('memberMarker');
    register('memberLoyaltyLevels');
    register('tags');
    register('fileId');
  }, [register]);

  const validateOperatorPassword = async () =>
    new Promise((resolve, reject) => {
      client
        .query({
          query: VALID_PASSWORD,
          variables: {
            password: getValues()?.password,
          },
        })
        .then(({ data }) => {
          if (!data?.validPassword) {
            reject();
          } else {
            resolve(null);
          }
        });
    });

  const processUsers = async () => {
    const { data } = await createJob({
      variables: {
        password: getValues()?.password,
        input: {
          type: JobType.CREATE_MEMBERS,
          file: getValues()?.fileId,
          forcePasswordChange: getValues()?.passwordChange,
          enabled: getValues()?.status,
          memberLevel: getValues()?.memberMarker,
          memberTags: getValues()?.tags.map((tag: any) => tag.id),
          memberLoyaltyLevels: getValues()?.memberLoyaltyLevels,
        },
      },
    });

    dispatch(
      jobsActions.addJob({
        id: data.createJob,
        name: JobType.CREATE_MEMBERS,
      })
    );
  };

  const onSubmit = async () => {
    validateOperatorPassword()
      .then(() => {
        processUsers();
        setExcelProgress(true);
      })
      .catch(() => {
        setError('password', {
          type: 'manual',
          message: translate(messages.INVALID_PASSWORD),
        });
        message.error(translate(messages.INVALID_PASSWORD));
      });
  };

  const fileOrPasswordEmpty = () =>
    !getValues()?.fileId || !getValues()?.password;

  const resetState = useCallback(() => {
    dispatch(jobsActions.removeJob(JobType.CREATE_MEMBERS));
    reset();
    setVisible(false);
    setExcelProgress(false);
    setReportComplete(false);
  }, [dispatch, reset]);

  const onChangeExcelData = (fileId: string) => {
    if (!fileId) {
      return;
    }
    setValue('fileId', fileId, { shouldValidate: true });
  };

  const modalWidth = useCallback(() => {
    if (!excelProgress && !reportComplete) {
      return 600;
    }

    if (excelProgress && !reportComplete) {
      return 272;
    }

    if (reportComplete) {
      return 350;
    }
    return 600;
  }, [excelProgress, reportComplete]);

  const modalTitle = useCallback(() => {
    if (!excelProgress && !reportComplete) {
      return translate(messages.IMPORT_MEMBERS);
    }

    if (excelProgress && !reportComplete) {
      return null;
    }

    if (reportComplete) {
      return translate(messages.IMPORT_MEMBERS_RESULT_REPORT);
    }
    return translate(messages.IMPORT_MEMBERS);
  }, [excelProgress, reportComplete, translate]);

  const refetchMemberManagement = () => {
    setTimeout(() => {
      client.query({
        query: GET_MEMBERS,
        variables: refetchParams,
        fetchPolicy: 'network-only',
      });
    }, 1000);
  };

  const importMembersJob = jobs?.find((x) => x.name === JobType.CREATE_MEMBERS);

  return (
    <>
      <Button
        type="link"
        className="text-black"
        onClick={() => setVisible(true)}
      >
        <FormattedMessage
          id="import-members.text"
          defaultMessage="Import Members"
        />
      </Button>
      {visible && (
        <StyledModal
          closable={!excelProgress}
          maskClosable={false}
          width={modalWidth()}
          title={modalTitle()}
          visible={visible}
          onCancel={() => resetState()}
          footer={
            !excelProgress &&
            !reportComplete && [
              <Button key="back" onClick={() => setVisible(false)}>
                <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
              </Button>,
              fileOrPasswordEmpty() ? (
                <Button
                  key="submit"
                  type="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  <FormattedMessage
                    id="confirm.text"
                    defaultMessage="Confirm"
                  />
                </Button>
              ) : (
                <Popconfirm
                  key={1}
                  title={
                    <>
                      <div>
                        <FormattedMessage
                          id="ARE_YOU_SURE_YOU_WANT_TO_PROCEED_ONLY"
                          defaultMessage="Are you sure you want to proceed?"
                        />
                      </div>
                      <div>
                        <FormattedMessage
                          id="THIS_ACTION_IS_IRREVERSIBLE"
                          defaultMessage="This action is irreversible"
                        />
                      </div>
                    </>
                  }
                  okText={translate(messages.YES)}
                  cancelText={translate(messages.NO)}
                  onConfirm={handleSubmit(onSubmit)}
                >
                  <Button key="submit" type="primary">
                    <FormattedMessage
                      id="confirm.text"
                      defaultMessage="Confirm"
                    />
                  </Button>
                </Popconfirm>
              ),
            ]
          }
        >
          {!excelProgress && !reportComplete && (
            <ImportMembersForm
              errors={errors}
              getValues={getValues}
              setValue={setValue}
              control={control}
              onChangeExcelData={onChangeExcelData}
              setError={setError}
            />
          )}

          {excelProgress && !reportComplete && (
            <ExcelProgress
              percents={importMembersJob?.progress! * 100 || 0}
              processComplete={() => {
                setReportComplete(true);
                refetchMemberManagement();
                setExcelProgress(false);
              }}
            />
          )}

          {reportComplete && (
            <ResultReport
              invalidMembers={importMembersJob?.failedReport! || []}
              successCount={importMembersJob?.successCount!}
              errorFileUri={importMembersJob?.errorReportFile?.uri!}
              totalMembersCount={
                importMembersJob?.successCount! + importMembersJob?.failCount!
              }
              onCloseModal={resetState}
            />
          )}
        </StyledModal>
      )}
    </>
  );
};

export default ImportMembers;
