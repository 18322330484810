import React from 'react';
import { Select as AntdSelect } from 'antd';
import banks, { messages } from 'constants/banks';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { SelectProps } from 'interfaces/select.interface';

const Select: any = AntdSelect;
const BanksSelect = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
  style,
}: SelectProps) => {
  const handleChange = (e: any) => {
    onChange(e);
  };
  const translate = useTranslate();
  return (
    <Select
      className="w-100"
      data-testid="bank-select"
      mode={multiple ? 'multiple' : undefined}
      value={value || (multiple ? [] : undefined)}
      showSearch
      placeholder={placeHolder}
      onChange={handleChange}
      disabled={disabled}
      style={style}
      allowClear
      id="bankSelect"
      aria-label="bankSelect"
      filterOption={(input: string, option: Record<string, any>) => {
        const target = coercedGet(option, 'props.children.props.children', []);
        if (target.length) {
          return (
            target[1].props.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }
        return false;
      }}
      getPopupContainer={(trigger: any) => trigger.parentElement}
    >
      {banks.map((item, key) => (
        <Select.Option role="option" key={key} value={item.value}>
          <div className="d-flex align-items-center">
            <img
              height={18}
              width={18}
              src={item.logo}
              alt="logo"
              className="mr-1"
            />
            <div>
              {`${item.label !== 'OTHER' ? `[${item.label}]` : ''} ${translate(
                messages[item.label]
              )}`}
            </div>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default BanksSelect;
