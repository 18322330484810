import gql from 'graphql-tag';

export const WITHDRAWAL_COMPLIANCE_CONFIG = gql`
  query WithdrawalComplianceCheckConfig {
    withdrawalComplianceCheckConfig {
      enabled
      turnoverRequirementEnabled
      turnoverRequirementActivationThreshold
      ipCollisionOffenderEnabled
      contraBetOffenderEnabled
      contraBetOffenderActivationThreshold
      martingaleBetOffenderEnabled
      martingaleBetOffenderActivationThreshold
      bonusHunterEnabled
      bonusHunterActivationThreshold
    }
  }
`;

export default {};
