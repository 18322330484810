import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import PermissionGroupTag from 'components/PermissionGroupTag';
import { FormattedMessage } from 'react-intl';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';

const FilterConditions = ({
  filters,
  onFilterChange,
}: {
  filters: Record<string, any>;
  onFilterChange: (data: Record<string, any>) => void;
}) => {
  const [localStateFilter, setLocalStateFilter] = useState<Record<string, any>>(
    {}
  );

  const translate = useTranslate();
  useEffect(() => {
    const transformedDateFilters = transformMovableDateFilter(filters);
    setLocalStateFilter(transformedDateFilters || {});
  }, [filters]);

  const handleRemoveUsername = (value: string, e: Record<string, any>) => {
    e.preventDefault();
    const { username } = filters;
    const data = username.in.filter((item: string) => item !== value);
    const newUsername = {
      in: data,
    };
    onFilterChange({
      ...filters,
      username: data.length ? newUsername : null,
    });
  };

  const handleRemovePermissionGroup = (
    value: string,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const { permissionGroups } = filters;
    const data = permissionGroups.overlaps.filter(
      (item: string) => item !== value
    );
    const newPermissionGroups = {
      overlaps: data,
    };
    onFilterChange({
      ...filters,
      permissionGroups: data.length ? newPermissionGroups : null,
    });
  };

  const handleRemoveRegistrationDateTime = () => {
    onFilterChange({
      ...filters,
      registrationDateTime: null,
    });
  };

  const handleRemoveLastLoginDateTime = () => {
    onFilterChange({
      ...filters,
      lastLoginDateTime: null,
    });
  };

  const handleRemoveStatus = (value: string, e: Record<string, any>) => {
    e.preventDefault();
    const { status } = filters;
    const data = status.in.filter((item: string) => item !== value);
    const newStatus = {
      in: data,
    };
    onFilterChange({
      ...filters,
      status: data.length ? newStatus : null,
    });
  };

  return (
    <>
      {filters.username
        ? filters.username.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveUsername(item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.permissionGroups
        ? filters.permissionGroups.overlaps.map((item: string, key: number) => (
            <PermissionGroupTag
              key={key}
              closable
              onClose={(e) => handleRemovePermissionGroup(item, e)}
              permissionGroupId={item}
            />
          ))
        : null}
      {localStateFilter.registrationDateTime ? (
        <Tag closable onClose={handleRemoveRegistrationDateTime}>
          <FormattedMessage
            id="registration-date.text"
            defaultMessage="Registration date"
          />
          : {formatDate(localStateFilter.registrationDateTime.gte)}~
          {formatDate(localStateFilter.registrationDateTime.lte)}
        </Tag>
      ) : null}
      {localStateFilter.lastLoginDateTime ? (
        <Tag closable onClose={handleRemoveLastLoginDateTime}>
          <FormattedMessage
            id="last-login-date.text"
            defaultMessage="Last login date"
          />
          : {formatDate(localStateFilter.lastLoginDateTime.gte)}~
          {formatDate(localStateFilter.lastLoginDateTime.lte)}
        </Tag>
      ) : null}
      {filters.status
        ? filters.status.in.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveStatus(item, e)}
            >
              {translate(messages[item])}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
