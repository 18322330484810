import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'antd';
import { get, sortedUniq } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { MESSAGE_CREATORS } from '../../SystemMessageSidebar/components/OperatorFilter/queries';

interface ICreatorTag {
  ids: string[];
  onClose: (e: any) => void;
}
interface ICreator {
  id: string;
  role: string;
  username: string;
}

export const CreatorTag = ({ ids, onClose }: ICreatorTag) => {
  const { data, loading } = useQuery(MESSAGE_CREATORS, {
    variables: {
      filter: {
        creator: {
          in: ids,
        },
      },
    },
  });

  const messageCreators = get(data, 'messages.edges', []).map(
    (x: any) => x.node.creator
  ) as ICreator[];

  return (
    <>
      {!loading &&
        sortedUniq(messageCreators).map((creator) => (
          <Tag closable key={creator.id} onClose={() => onClose(creator)}>
            <strong>
              <FormattedMessage id="operator.text" defaultMessage="Operator" />
            </strong>
            : {creator.username}
          </Tag>
        ))}
    </>
  );
};
