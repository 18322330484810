import gql from 'graphql-tag';

export const MEMBER_LOYALTY_LEVEL = gql`
  query MemberLoyaltyLevel($id: ID!) {
    memberLoyaltyLevel(id: $id) {
      id
      name
      color
      programme {
        id
        name
      }
    }
  }
`;

export default {};
