import gql from 'graphql-tag';

export const VENDOR_GAME_REPORTS = gql`
  query VendorGameReport(
    $first: Int
    $after: Binary
    $filter: VendorGameReportFilterInput
    $startDateTime: DateTime!
    $endDateTime: DateTime!
  ) {
    vendorGameReport(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          gameType
          vendor {
            name(language: EN)
          }
          betsCount(startDateTime: $startDateTime, endDateTime: $endDateTime)
          turnover(startDateTime: $startDateTime, endDateTime: $endDateTime)
          effectiveBet(startDateTime: $startDateTime, endDateTime: $endDateTime)
          winloss(startDateTime: $startDateTime, endDateTime: $endDateTime)
          profitRatio(startDateTime: $startDateTime, endDateTime: $endDateTime)
        }
      }
    }
  }
`;

export const VENDOR_GAME_METRICS = gql`
  query VendorGameMetrics(
    $metricType: VendorGameMetricsType!
    $startDateTime: DateTime!
    $endDateTime: DateTime!
  ) {
    slot_metric: vendorGameMetrics(
      input: {
        type: $metricType
        gameType: SLOT
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )
    fish_metric: vendorGameMetrics(
      input: {
        type: $metricType
        gameType: FISH
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )
    live_metric: vendorGameMetrics(
      input: {
        type: $metricType
        gameType: LIVE
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )
    sportsbook_metric: vendorGameMetrics(
      input: {
        type: $metricType
        gameType: SPORTSBOOK
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )
    lottery_metric: vendorGameMetrics(
      input: {
        type: $metricType
        gameType: LOTTERY
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )

    slot_effectiveBet: vendorGameMetrics(
      input: {
        type: TOTAL_EFFECTIVE_BET
        gameType: SLOT
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )
    fish_effectiveBet: vendorGameMetrics(
      input: {
        type: TOTAL_EFFECTIVE_BET
        gameType: FISH
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )
    live_effectiveBet: vendorGameMetrics(
      input: {
        type: TOTAL_EFFECTIVE_BET
        gameType: LIVE
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )
    sportsbook_effectiveBet: vendorGameMetrics(
      input: {
        type: TOTAL_EFFECTIVE_BET
        gameType: SPORTSBOOK
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )
    lottery_effectiveBet: vendorGameMetrics(
      input: {
        type: TOTAL_EFFECTIVE_BET
        gameType: LOTTERY
        startDateTime: $startDateTime
        endDateTime: $endDateTime
      }
    )
  }
`;

export default {};
