import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';

import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import {
  StyledLabel,
  StyledSelect,
  StyledSpan,
} from 'styles/SharedStyledSelectFilter';

const { Option } = Select;

type DeviceProps = {
  state: any;
  setState: (value: any) => void;
};
const Device = ({ state, setState }: DeviceProps) => {
  const translate = useTranslate();
  const options = [
    {
      label: translate(messages.WEB),
      value: 'DESKTOP_WEB',
    },
  ];

  const handleChange = (e: any) => {
    if (e.length) {
      setState((prev: any) => ({
        ...prev,
        device: { in: e },
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        device: null,
      }));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.device.text"
              defaultMessage="Device"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        data-testid="select"
        allowClear
        placeholder={
          <FormattedMessage id="reports.select.text" defaultMessage="Select" />
        }
        mode="multiple"
        style={{ width: '100%' }}
        // eslint-disable-next-line react/prop-types
        value={state.device ? state.device.in : []}
        optionFilterProp="label"
        onChange={handleChange}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value} label={option.label}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};

export default Device;
