import React from 'react';
import { StyledInput } from './styles';

const ProgrammeName = () => (
  <div className="my-5">
    <div className="mb-1">Programme Name</div>
    <StyledInput allowClear placeholder="Search" />
  </div>
);

export default ProgrammeName;
