import React, { useMemo } from 'react';

type Props = {
  children: any;
  data: any;
  loading: boolean;
  error: any;
  refetch: () => void;
  page: any;
  setPage: (e: any) => void;
};

const VendorGroupManagementContext = React.createContext<any>({});

export const VendorGroupManagementProvider = ({
  children,
  data,
  loading,
  error,
  refetch,
  page,
  setPage,
}: Props) => {
  const value = useMemo(
    () => ({ data, loading, error, refetch, page, setPage }),
    [data, error, loading, refetch, page, setPage]
  );

  return (
    <VendorGroupManagementContext.Provider value={value}>
      {children}
    </VendorGroupManagementContext.Provider>
  );
};

export const useVendorGroupManagementValue = () =>
  React.useContext(VendorGroupManagementContext);
