import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Switch, Checkbox, Radio } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import FormItem from 'components/FormItem';
import SliderWithInputNumber from 'components/SliderWithInputNumber';
import useTranslate from 'utils/useTranslate';
import { costSettings } from './constants';
import { TIER_REQUIREMENT } from '../../../../../../constants';
import messages from './messages';

function AgentAffiliateProgramme({
  formProps,
}: {
  formProps: Record<string, any>;
}) {
  const formItemHorizontalLayout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const { setFieldValue, values, handleChange, errors } = formProps;
  const translate = useTranslate();

  return (
    <Root>
      <Form.Item
        label={
          <b>
            <FormattedMessage
              id="programme-settings.text"
              defaultMessage="Programme Settings"
            />
          </b>
        }
        {...formItemHorizontalLayout}
        colon={false}
      />
      <Form.Item
        validateStatus={errors && errors.name && 'error'}
        help={errors && errors.name}
        label={
          <FormattedMessage
            id="programme-name.text"
            defaultMessage="Programme Name"
          />
        }
        {...formItemHorizontalLayout}
      >
        <Input
          size="large"
          name="name"
          onChange={handleChange}
          value={values.name}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="programme-description.text"
            defaultMessage="Programme Description"
          />
        }
        {...formItemHorizontalLayout}
      >
        <Input.TextArea
          autoSize={{ minRows: 4, maxRows: 5 }}
          name="description"
          onChange={handleChange}
          value={values.description}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="default-affiliate-programme.text"
            defaultMessage="Default Affiliate Programme"
          />
        }
        {...formItemHorizontalLayout}
      >
        <Switch
          onChange={(e) => setFieldValue('default', e)}
          checked={values.default}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="negative-lose-carry.text"
            defaultMessage="Negative Lose Carry"
          />
        }
        {...formItemHorizontalLayout}
      >
        <Switch
          onChange={(e) => setFieldValue('negativeCarryOverEnabled', e)}
          checked={values.negativeCarryOverEnabled}
        />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage
            id="tier-requirement.text"
            defaultMessage="Tier Requirement"
          />
        }
        validateStatus={errors && errors.tierRequirement && 'error'}
        help={errors && errors.tierRequirement}
        {...formItemHorizontalLayout}
      >
        <>
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={(e) => setFieldValue('tierRequirement', e)}
            value={values.tierRequirement}
          >
            <div>
              <Checkbox value={TIER_REQUIREMENT.ACTIVE_MEMBERS}>
                {translate(messages['active-members.text'])}
              </Checkbox>
            </div>
            <div>
              <Checkbox value={TIER_REQUIREMENT.NET_PROFIT}>
                {translate(messages['net-profit.text'])}
              </Checkbox>
            </div>
          </Checkbox.Group>
          {values.tierRequirement.length === 2 && (
            <Radio.Group
              buttonStyle="solid"
              name="tierRequirementOperator"
              value={values.tierRequirementOperator}
              onChange={handleChange}
            >
              <Radio.Button value={TIER_REQUIREMENT.BOTH}>
                {translate(messages['and.text'])}
              </Radio.Button>
              <Radio.Button value={TIER_REQUIREMENT.EITHER}>
                {translate(messages['or.text'])}
              </Radio.Button>
            </Radio.Group>
          )}
        </>
      </Form.Item>

      <Form.Item
        label={
          <b>
            <FormattedMessage
              id="affiliate-cost-sharing-settings.text"
              defaultMessage="Affiliate Cost Sharing Settings"
            />
          </b>
        }
        {...formItemHorizontalLayout}
        colon={false}
      />
      {costSettings.map((q, i) => (
        <FormItem
          key={i}
          label={translate(messages[`${q.id}`])}
          {...formItemHorizontalLayout}
        >
          <SliderWithInputNumber
            name={q.key}
            min={0}
            max={100}
            value={values[q.key]}
          />
        </FormItem>
      ))}
      <Form.Item
        label={
          <b>
            <FormattedMessage
              id="requirement-for-active-member.text"
              defaultMessage="Requirement for Active Member"
            />
          </b>
        }
        {...formItemHorizontalLayout}
        colon={false}
      />
      <Form.Item
        validateStatus={
          errors && errors.minimumEffectiveBetRequirement && 'error'
        }
        help={
          errors &&
          errors.minimumEffectiveBetRequirement &&
          errors.minimumEffectiveBetRequirement
        }
        label={
          <FormattedMessage
            id="effective-bet.text"
            defaultMessage="Effective Bet"
          />
        }
        {...formItemHorizontalLayout}
      >
        <Input
          size="large"
          type="number"
          min={0}
          name="minimumEffectiveBetRequirement"
          value={values.minimumEffectiveBetRequirement}
          onChange={handleChange}
        />
      </Form.Item>
      <Form.Item
        validateStatus={errors && errors.minimumDepositRequirement && 'error'}
        help={
          errors &&
          errors.minimumDepositRequirement &&
          errors.minimumDepositRequirement
        }
        label={<FormattedMessage id="deposit.text" defaultMessage="Deposit" />}
        {...formItemHorizontalLayout}
      >
        <Input
          size="large"
          type="number"
          min={0}
          name="minimumDepositRequirement"
          value={values.minimumDepositRequirement}
          onChange={handleChange}
        />
      </Form.Item>
    </Root>
  );
}

const Root = styled.div`
  .footer-actions {
    display: flex;
  }
`;

export default AgentAffiliateProgramme;
