import React from 'react';
import Icon from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import { ReactComponent as DownloadIcon } from 'components/assets/download-csv.svg';
import { TabId } from 'types/nav';
import { DocumentNode } from 'graphql';
import CsvModal from './CsvModal';

type Props = {
  isLink?: boolean;
  iconOnly?: boolean;
  fileName: string;
  tabId: TabId;
  edgesPath: string;
  query: DocumentNode;
  permission?: boolean;
  useProvidedFilter?: () => Record<string, any>;
};

const DownloadCsvButton: React.FC<Props> = ({
  isLink,
  iconOnly,
  fileName,
  tabId,
  edgesPath,
  query,
  permission = true,
  useProvidedFilter,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [isShow, setShow] = React.useState(false);

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const renderIslink = () =>
    isLink ? (
      <Button className="text-black" type="link" onClick={handleShow} block>
        <FormattedMessage
          id="download-csv.text"
          defaultMessage="Download CSV"
        />
      </Button>
    ) : (
      <Button onClick={handleShow}>
        <FormattedMessage
          id="download-csv.text"
          defaultMessage="Download CSV"
        />
      </Button>
    );

  return (
    <>
      {iconOnly ? (
        <Tooltip title={formatMessage(messages.DOWNLOAD_CSV)}>
          <Icon
            className="mr-1"
            component={DownloadIcon}
            style={{ fontSize: '22px' }}
            onClick={handleShow}
          />
        </Tooltip>
      ) : (
        renderIslink()
      )}

      {isShow && (
        <CsvModal
          useProvidedFilter={useProvidedFilter}
          fileName={fileName}
          handleShow={handleShow}
          tabId={tabId}
          edgesPath={edgesPath}
          query={query}
          permission={permission}
        />
      )}
    </>
  );
};

export default DownloadCsvButton;
