import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import FilterSettings from './components/FilterSettings';
import { StyledSider, StyledContainer } from './styles';
import Username from './components/Username';
import DateRangeFilter from './components/DateRangeFilter';
import Status from './components/Status';

const Sidebar = ({
  state,
  setState,
  collapsed,
}: {
  state: Record<string, any>;
  setState: any;
  collapsed: boolean;
}) => (
  <StyledSider
    className="br-1"
    theme="light"
    trigger={null}
    collapsible
    collapsedWidth={0}
    width={232}
    collapsed={collapsed}
  >
    <StyledContainer className="p-3">
      <FilterSettings state={state} setState={setState} />
      <Username state={state} setState={setState} />
      <DateRangeFilter state={state} setState={setState} />
      <Status state={state} setState={setState} />
      <Button
        block
        onClick={() =>
          setState({
            ...state,
            usernames: [],
            status: [],
            dateRange: [],
          })
        }
      >
        <FormattedMessage id="reports.clear.text" defaultMessage="Clear" />
      </Button>
    </StyledContainer>
  </StyledSider>
);

export default Sidebar;
