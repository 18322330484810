import moment from 'moment';

export const AffiliateRequestStatusColor = {
  PENDING: 'warning',
  CURRENTLY_PENDING: 'pending',
  PROCESSING: 'processing',
  APPROVED: 'success',
  REJECTED: 'error',
};

export const AffiliateRequestActions = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const toCapitalizedCase = (word: string) =>
  word.charAt(0) + word.substring(1).toLowerCase();

export const getProcessingTime = (record: Record<string, any>) => {
  const { status, dateTimeProcessingStarted, dateTimeUpdated } = record;

  if (
    status.toUpperCase() === AffiliateRequestActions.APPROVED ||
    status.toUpperCase() === AffiliateRequestActions.REJECTED ||
    status.toUpperCase() ===
      AffiliateRequestStatusColor.PROCESSING.toUpperCase()
  ) {
    return moment(dateTimeProcessingStarted).from(dateTimeUpdated, true);
  }
  return '-';
};
