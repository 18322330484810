import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DynamicObj } from 'interfaces/user.interface';
import { StyledForm } from './styles';
import FilterItems from '../FilterItems';

type Props = {
  initialValues: { [key: string]: any };
  onSubmit: (values: any) => void;
  onClose: () => void;
  isLoading: boolean;
};

const SearchSettingsForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const [values, setValues] = useState(initialValues);

  const handleChange = ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <StyledForm>
      <Row>
        <Col span={12}>
          <FilterItems
            filters={values}
            onRawFilterChange={handleChange}
            isCustomFilter
            defaultValues={{}}
          />
        </Col>
      </Row>
      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmit(values)}
          loading={isLoading}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
