import { useQuery } from '@apollo/react-hooks';
import { Alert, Col, Modal, Row, Spin } from 'antd';
import { withErrorHandler } from 'components/ErrorHandler';
import { AFFILIATE_REQUEST } from 'graphql/queries/affiliateAgent.query';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { useAccount } from 'store/accountState';
import styled from 'styled-components';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import ModalComponents from './components';

const { ModalTitle, ModalContent, ModalFooter } = ModalComponents;

const StyledCenter = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  modalShown: boolean;
  handleHide: () => void;
  handleHideForProcessing: () => void;
  activeRecord: string;
  processing: boolean;
};

const AffiliateRequestPopup = (props: Props) => {
  const {
    modalShown,
    handleHide,
    handleHideForProcessing,
    activeRecord,
    processing,
  } = props;
  const translate = useTranslate();
  const {
    account: { account: user },
  } = useAccount() as Record<string, any>;

  const { loading, error, data } = useQuery(AFFILIATE_REQUEST, {
    variables: {
      id: activeRecord,
    },
    fetchPolicy: 'network-only',
    skip: !activeRecord,
  });

  const summary = coercedGet(data, 'affiliateRequest', {});

  const isProcessing = summary.status === 'PROCESSING';
  const sameProcessor = user.username === summary.processor?.username;

  const [remarks, setRemarks] = useState('');

  useEffect(() => {
    const newRemarks = coercedGet(summary, 'remarks', '');
    setRemarks(newRemarks);
  }, [summary, setRemarks]);

  return (
    <Modal
      maskClosable
      style={{ top: 20 }}
      title={
        <ModalTitle error={error as any} loading={loading} summary={summary} />
      }
      width="calc(100vw - 100px)"
      visible={modalShown}
      onCancel={handleHide}
      footer={
        isProcessing && sameProcessor ? (
          <ModalFooter
            remarks={remarks}
            processing={processing}
            handleHideForProcessing={handleHideForProcessing}
            handleHide={handleHide}
            loading={loading}
            error={error}
            requestID={summary.id}
          />
        ) : (
          <Row>
            <Col span={24} />
          </Row>
        )
      }
    >
      {!loading && error && (
        <Alert
          type="error"
          message={translate(messages.INTERNAL_SERVER_ERROR)}
          banner
        />
      )}
      {loading ? (
        <StyledCenter>
          <Spin />
        </StyledCenter>
      ) : (
        !error && (
          <ModalContent
            summary={summary}
            remarks={remarks}
            setRemarks={setRemarks}
          />
        )
      )}
    </Modal>
  );
};

export default withErrorHandler(AffiliateRequestPopup);
