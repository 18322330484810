import styled from 'styled-components';

export const Container = styled.div`
  .ant-switch {
    margin-right: 8px;
  }

  .ant-switch-checked {
    background-color: #52c41a;
  }
`;
