import { useQuery } from '@apollo/react-hooks';
import { Spin, Typography } from 'antd';
import { TOTAL_WITHDRAWALS } from 'graphql/queries/withdrawal.query';
import { useConfig } from 'hooks/useConfig';
import useWindowWidth from 'pages/components/common/hooks/useWindowWidth';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import esGet from 'utils/esGet';

function AccountSum() {
  const IsSmallScreenSize = useWindowWidth() <= 1070;
  const { addAdminCurrency } = useConfig();
  const { data, loading, error } = useQuery(TOTAL_WITHDRAWALS, {
    variables: {
      first: 1,
    },
  });

  if (loading)
    return (
      <div className="styled-center">
        <Spin />
      </div>
    );

  if (error) {
    return (
      <div>
        <FormattedMessage
          id="error-fetching-balance.text"
          defaultMessage="Error fetching balance"
        />
      </div>
    );
  }

  const totalWithdrawals = {
    totalWithdrawalApproved: esGet(
      data?.withdrawals?.totalWithdrawalApproved,
      0
    ),
    totalWithdrawalRequested: esGet(
      data?.withdrawals?.totalWithdrawalRequested,
      0
    ),
    totalWithdrawalProcessed: esGet(
      data?.withdrawals?.totalWithdrawalProcessed,
      0
    ),
  };

  return (
    <div className="styled-center">
      <div className="mr-3">
        <Typography.Text
          className="d-block"
          style={IsSmallScreenSize ? { fontSize: '0.8em' } : {}}
        >
          <FormattedMessage
            id="total-withdrawal-requested.text"
            defaultMessage="Total Withdrawal Requested"
          />
        </Typography.Text>
        <Typography.Text className="d-block" strong>
          {addAdminCurrency(totalWithdrawals?.totalWithdrawalRequested)}
        </Typography.Text>
      </div>

      <div className="mr-3">
        <Typography.Text
          className="d-block"
          style={IsSmallScreenSize ? { fontSize: '0.8em' } : {}}
        >
          <FormattedMessage
            id="total-withdrawal-processed.text"
            defaultMessage="Total Withdrawal Processed"
          />{' '}
        </Typography.Text>
        <Typography.Text className=" d-block" strong>
          {addAdminCurrency(totalWithdrawals?.totalWithdrawalProcessed)}
        </Typography.Text>
      </div>

      <div>
        <Typography.Text
          className="d-block"
          style={IsSmallScreenSize ? { fontSize: '0.8em' } : {}}
        >
          <FormattedMessage
            id="total-withdrawal-approved.text"
            defaultMessage="Total Withdrawal Approved"
          />{' '}
        </Typography.Text>
        <Typography.Text className=" d-block" strong>
          {addAdminCurrency(totalWithdrawals?.totalWithdrawalApproved)}
        </Typography.Text>
      </div>
    </div>
  );
}

export default AccountSum;
