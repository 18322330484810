import React from 'react';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';

export const PriorityAlertTag = ({ priority }: { priority: string }) => {
  const translate = useTranslate();

  // eslint-disable-next-line consistent-return
  const priorityColor = (high: string, medium: string, low: string) => {
    switch (priority.toLowerCase()) {
      case 'high':
        return high;
      case 'medium':
        return medium;
      case 'low':
        return low;
      default:
        return 'gray';
    }
  };

  const borderColor = () => priorityColor('#ffa39e', '#91d5ff', '#d9d9d9');
  const bgColor = () => priorityColor('#fff1f0', '#e6f7ff', '#f5f5f5');

  return (
    <span
      style={{
        padding: '5px',
        border: `1px solid ${borderColor()}`,
        background: bgColor(),
        borderRadius: '4px',
      }}
    >
      {translate(messages[`${priority.toLowerCase()}.text`])}
    </span>
  );
};
