import React, { useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Input, InputNumber, Menu, Select } from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Control, Controller } from 'react-hook-form';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import {
  isExtraLarge,
  isLarge,
  isSmall,
  useBreakpoint,
} from 'hooks/useBreakpoint';
import { useEffectOnce } from 'react-use';
import { useConfig } from 'hooks/useConfig';
import { PromptWarningModal, RadioSwitch } from '../../utils';

type Props = {
  index: number;
  control: Control<Record<string, any>>;
  item: Record<string, any>;
  errors: Record<string, any>;
  remove: (idx: number) => void;
  settings: boolean;
  duplicate: () => void;
  setAmounts: (e: any) => void;
};

const ActionButton = ({
  style = {},
  duplicate,
  setShowModal,
}: {
  style?: Record<string, any>;
  duplicate: () => void;
  setShowModal: (val: boolean) => void;
}) => (
  <div className="flex-shrink-0 pr-3 text-right" style={style}>
    <p className="fw-600 fs-13" style={{ opacity: 0 }}>
      action
    </p>
    <Dropdown
      overlay={() => (
        <Menu>
          <Menu.Item onClick={duplicate}>
            <FormattedMessage
              id="DUPLICATE_MILESTONE"
              defaultMessage="Duplicate Milestone"
            />
          </Menu.Item>
          <Menu.Item onClick={() => setShowModal(true)}>
            <FormattedMessage
              id="DELETE_MILESTONE"
              defaultMessage="Delete Milestone"
            />
          </Menu.Item>
        </Menu>
      )}
    >
      <EllipsisOutlined
        className="cursor-pointer"
        style={{ fontSize: '25px' }}
      />
    </Dropdown>
  </div>
);

const ErrorMessage = ({
  errors,
  name,
  index,
}: {
  errors: Record<string, any>;
  name: string;
  index: number;
}) =>
  errors?.milestones?.[index]?.[name]?.message ? (
    <small className="text-danger">
      {errors?.milestones?.[index]?.[name]?.message}
    </small>
  ) : (
    <></>
  );

const localMessage = defineMessages({
  MILESTONE_TASK_NUMBER: {
    id: 'MILESTONE_TASK_NUMBER',
    defaultMessage: 'Milestone {count} Task',
  },
});

export const MilestoneCard = ({
  index,
  control,
  item,
  errors,
  remove,
  settings,
  duplicate,
  setAmounts,
}: Props) => {
  const taskNumber = index + 1;
  const [showModal, setShowModal] = useState(false);
  const name = (val: string) => `milestones[${index}].${val}`;
  const translate = useTranslate();
  const breakpoint = useBreakpoint();
  const { currency } = useConfig();

  useEffectOnce(() => {
    setAmounts((prev: Record<number, number>) => ({
      ...prev,
      [index]: item.requirement || 0,
    }));
  });

  return (
    <div
      className="border px-3 d-flex justify-content-between"
      style={{
        overflowX: 'auto',
        borderRadius: '4px',
        minHeight: '88px',
        opacity: settings ? 1 : 0.3,
        pointerEvents: settings ? 'all' : 'none',
        paddingTop: '25px',
      }}
    >
      <div className="d-flex">
        <div className="mr-5 flex-shrink-0">
          <p className="fw-600 fs-13">
            {translate(localMessage.MILESTONE_TASK_NUMBER, {
              count: taskNumber,
            })}
          </p>
          <p className="mb-0">
            <FormattedMessage
              id="COMPLETE_DAILY_TASKS"
              defaultMessage="Complete Daily Tasks"
            />
          </p>
        </div>
        <div className="flex-shrink-0" style={{ width: '100px' }}>
          <p className="fw-600 fs-13">
            <FormattedMessage id="TIMES_DAYS" defaultMessage="Times/Days" />
          </p>
          <Controller
            name={name('requirement')}
            defaultValue={item.requirement}
            control={control}
            render={({ value, onChange }) => (
              <InputNumber
                style={{ width: '100%' }}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  setAmounts((prev: Record<number, any>) => ({
                    ...prev,
                    [index]: e,
                  }));
                }}
              />
            )}
          />
          <ErrorMessage errors={errors} name="requirement" index={index} />
        </div>

        <Divider
          style={{ height: '35px', width: '1px', minWidth: '1px' }}
          className="mx-3 flex-shrink-0"
        />

        <div className="mr-3 flex-shrink-0">
          <p className="fw-600 fs-13">
            <FormattedMessage id="REWARD_TYPE" defaultMessage="Reward Type" />
          </p>
          <Controller
            name={name('rewardType')}
            defaultValue={item.rewardType}
            control={control}
            as={
              <Select style={{ width: '100%' }}>
                <Select.Option value="MONEY">
                  <FormattedMessage id="MONEY" defaultMessage="Money" />
                </Select.Option>
              </Select>
            }
          />
        </div>

        <div className="mr-3 flex-shrink-0" style={{ width: '150px' }}>
          <p className="fw-600 fs-13" style={{ marginBottom: '10px' }}>
            <FormattedMessage
              id="REWARD_AMOUNT"
              defaultMessage="Reward Amount"
            />
            {currency?.symbol}
          </p>
          <Controller
            name={name('rewardAmount')}
            defaultValue={item.rewardAmount}
            control={control}
            as={<Input style={{ width: '100%' }} />}
          />
          <ErrorMessage errors={errors} name="rewardAmount" index={index} />
        </div>

        <div className="flex-shrink-0">
          <p className="fw-600 fs-13">
            <FormattedMessage id="MULTIPLIER" defaultMessage="Multiplier" />
          </p>
          <div className="d-flex">
            <Controller
              name={name('rewardMultiplier')}
              control={control}
              defaultValue={item.rewardMultiplier}
              render={({ value, onChange }) => (
                <RadioSwitch onChange={onChange} value={value} />
              )}
            />
          </div>
        </div>

        <Divider
          style={{ height: '35px', width: '1px', minWidth: '1px' }}
          className="mx-3 flex-shrink-0"
        />

        <div className="mr-3 flex-shrink-0" style={{ width: '150px' }}>
          <p className="fw-600 fs-13" style={{ marginBottom: '10px' }}>
            <FormattedMessage id="TURNOVER" defaultMessage="Turnover" />
            (x)
          </p>
          <Controller
            name={name('turnoverRequirementMultiplier')}
            defaultValue={item.turnoverRequirementMultiplier}
            control={control}
            as={<Input style={{ width: '100%' }} />}
          />
          <ErrorMessage
            errors={errors}
            name="turnoverRequirementMultiplier"
            index={index}
          />
        </div>

        {isSmall(breakpoint) || isLarge(breakpoint) ? (
          <ActionButton
            duplicate={duplicate}
            setShowModal={setShowModal}
            style={{ width: '200px' }}
          />
        ) : (
          <></>
        )}
      </div>

      {isExtraLarge(breakpoint) && (
        <ActionButton duplicate={duplicate} setShowModal={setShowModal} />
      )}

      <PromptWarningModal
        onOk={() => remove(index)}
        visible={showModal}
        onCloseFn={() => setShowModal(false)}
        title={translate(messages.DELETE_MILESTONE)}
      />
    </div>
  );
};
