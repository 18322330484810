import React, { useState, useEffect } from 'react';
import { Button, Row, DatePicker, Col, message, TimePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DynamicObj } from 'interfaces/user.interface';
import { useMutation } from '@apollo/react-hooks';
import {
  UPDATE_ADMIN,
  StyledModal,
  StyledSelect,
  StyledField,
  messages,
} from 'SuperAdminMain/components/AccountManagementNew/utils';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import useTranslate from 'utils/useTranslate';
import { useAccountManagementValue } from 'SuperAdminMain/components/AccountManagementNew/context';

type Props = {
  account: DynamicObj;
};

export const UpgradeToProduction: React.FC<Props> = ({ account }) => {
  const [isShow, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const { refetch } = useAccountManagementValue();
  const [updateAdmin] = useMutation(UPDATE_ADMIN);
  const {
    register,
    getValues,
    setValue,
    errors,
    handleSubmit,
    reset,
  } = useForm();

  const handleShow = () => setShow(!isShow);

  const handleReset = () => {
    reset({
      date: undefined,
      time: undefined,
      select: [account.username],
    });

    handleShow();
  };

  const onSubmit = async (data: any) => {
    const { date, time } = data;
    const dateX = moment(date).format('YYYY-MM-DD');
    const timeX = moment(time).format('HH:mm:ss');
    const contractEndDateTime = moment(`${dateX} ${timeX}`).format();

    setLoading(true);

    try {
      await updateAdmin({
        variables: {
          id: account.id,
          input: { contractEndDateTime, trial: false },
        },
      });

      message.success(translate(messages['updated-successfully.text']));

      refetch();
      handleReset();
      // eslint-disable-next-line no-empty
    } finally {
    }

    setLoading(false);
  };

  useEffect(() => {
    register(
      { name: 'select' },
      { validate: { positive: (val) => val.length > 0 } }
    );
    setValue('select', [account.username]);
    register('date');
    register('time');
  }, [account.username, register, setValue]);

  return (
    <>
      <Button type="link" block onClick={handleShow} disabled={!account.trial}>
        <FormattedMessage
          id="upgrade-to-production.text"
          defaultMessage="Upgrade To Production"
        />
      </Button>

      <StyledModal
        title={
          <span className="fs-14">
            <FormattedMessage
              id="upgrade-to-production.text"
              defaultMessage="Upgrade To Production"
            />
          </span>
        }
        visible={isShow}
        width={640}
        centered
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id="update.text" defaultMessage="Update" />
          </Button>,
        ]}
      >
        <FormattedMessage
          id="members-involved.text"
          defaultMessage="Members Involved"
        />
        : 1 <FormattedMessage id="people.text" defaultMessage="People" />
        <StyledSelect
          mode="multiple"
          value={getValues('select')}
          onChange={(e) => setValue('select', e)}
        >
          <StyledSelect.Option key={account.username} value={account.username}>
            {account.username}
          </StyledSelect.Option>
        </StyledSelect>
        {errors.select && (
          <span style={{ color: 'red' }}>
            <FormattedMessage
              id="must-select-1.text"
              defaultMessage="Must select at least 1."
            />
          </span>
        )}
        <br />
        <br />
        <Row gutter={16}>
          <Col span={1} />
          <Col span={6}>
            <StyledField style={{ marginBottom: '100px' }}>
              <FormattedMessage
                id="new-expire-date.text"
                defaultMessage="New Expire Date"
              />
            </StyledField>
          </Col>

          <Col span={8}>
            <DatePicker
              value={getValues('date')}
              onChange={(e) => setValue('date', e)}
            />
          </Col>

          <Col span={8}>
            <TimePicker
              defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
              value={getValues('time')}
              onChange={(e) => setValue('time', e)}
            />
          </Col>
          <Col span={1} />
        </Row>
      </StyledModal>
    </>
  );
};
