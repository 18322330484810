import gql from 'graphql-tag';

export const UPDATE_WITHDRAWAL_COMPLIANCE_CONFIG = gql`
  mutation UpdateWithdrawalComplianceCheckConfig(
    $input: UpdateComplianceCheckConfigInput!
  ) {
    updateWithdrawalComplianceCheckConfig(input: $input)
  }
`;

export default {};
