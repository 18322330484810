import { Environment } from 'types/environment';

export function isLocal(): boolean {
  return window.config.environment === 'local';
}

export function isDevelopment(): boolean {
  return window.config.environment === 'development';
}

export function isTest() {
  return window.config.environment === 'test';
}

export function isStaging() {
  return window.config.environment === 'staging';
}

export function isDemo() {
  return window.config.environment === 'demo';
}

export function isProduction() {
  return window.config.environment === 'production';
}

export function getEnvironment(): Environment {
  return window.config.environment;
}
