import React from 'react';
import { Row, Col, Table } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import { customFormatMessage } from 'utils/customFormatMessage';
import { useConfig } from 'hooks/useConfig';
import { useCostSettingsContext } from '../context';
import messages from '../messages';

const columns = (intl: any) => {
  const translate = (messageVal: any, opts = null) =>
    customFormatMessage(intl.formatMessage, messageVal, opts);
  return [
    {
      title: translate(messages['from.text']),
      dataIndex: 'from',
      key: 'from',
      className: 'text-left',
    },
    {
      title: translate(messages['to.text']),
      dataIndex: 'to',
      key: 'to',
      className: 'text-left',
    },
    {
      title: translate(messages['charge.text']),
      dataIndex: 'charge',
      key: 'charge',
      className: 'text-right',
    },
  ];
};

function SiderContent() {
  const intl = useIntl();
  const [{ activeVendor }] = useCostSettingsContext() as any;
  const { currency } = useConfig();
  const chargeLevels = coercedGet(activeVendor, 'chargeLevels', []);
  return (
    <div style={{ margin: 20 }}>
      <div>
        <p style={{ float: 'left', fontWeight: 'bold' }}>
          <FormattedMessage
            id="agent.ggr-charge.text"
            defaultMessage="GGR Charge"
          />
        </p>
        <div className="clearfix" />
      </div>
      <Row>
        <Col span={20}>
          <FormattedMessage
            id="minimum-ggr-charge.text"
            defaultMessage="Minimum GGR Charge"
          />
        </Col>
        <Col span={4}>
          {currency?.symbol} {activeVendor.minimumCharge}
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          <FormattedMessage
            id="maximum-ggr-charge.text"
            defaultMessage="Maximum GGR Charge"
          />
        </Col>
        <Col span={4}>
          {currency?.symbol} {activeVendor.maximumCharge}
        </Col>
      </Row>
      <div className="mt-4">
        <p style={{ float: 'left', fontWeight: 'bold' }}>
          <FormattedMessage
            id="agent.ggr-percent-charge-tiers.text"
            defaultMessage="GGR % Charge Tiers"
          />
        </p>
        <div className="clearfix" />
      </div>
      <Table
        pagination={false}
        columns={columns(intl)}
        dataSource={chargeLevels.map(
          (level: Record<string, any>, index: number) => ({
            from: `${level.minimumTotalWinloss}`,
            to:
              index === chargeLevels.length - 1
                ? 'Infinity'
                : `${+chargeLevels[index + 1].minimumTotalWinloss - 1}`,
            charge: `${level.percentage * 100}%`,
          })
        )}
      />
    </div>
  );
}

export default SiderContent;
