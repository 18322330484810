import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Radio, Input, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DynamicObj } from 'interfaces/user.interface';
import { useMutation } from '@apollo/react-hooks';
import {
  UPDATE_ADMIN,
  messages,
  StyledModal,
  StyledSelect,
} from 'SuperAdminMain/components/AccountManagementNew/utils';
import { useForm, Controller } from 'react-hook-form';
import useTranslate from 'utils/useTranslate';
import styled from 'styled-components';
import { useAccountManagementValue } from 'SuperAdminMain/components/AccountManagementNew/context';

type Props = {
  account: DynamicObj;
};

export const EditStatus: React.FC<Props> = ({ account }) => {
  const [isShow, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateAdmin] = useMutation(UPDATE_ADMIN);
  const { refetch } = useAccountManagementValue();
  const translate = useTranslate();
  const {
    register,
    getValues,
    setValue,
    errors,
    handleSubmit,
    control,
    reset,
    watch,
  } = useForm();

  const handleShow = () => setShow(!isShow);

  const handleReset = () => {
    reset({
      password: undefined,
      status: account.status,
      select: [account.username],
    });

    handleShow();
  };

  const onSubmit = async (data: any) => {
    const { status } = data;
    setLoading(true);

    try {
      await updateAdmin({
        variables: {
          id: account.id,
          input: { status },
          password: getValues('password'),
        },
      });

      message.success(translate(messages['updated-successfully.text']));
      handleReset();
      refetch();
      // eslint-disable-next-line no-empty
    } finally {
    }

    setLoading(false);
  };

  useEffect(() => {
    register(
      { name: 'select' },
      { validate: { positive: (val) => val.length > 0 } }
    );
    register('status');
    setValue('status', account.status);
    setValue('select', [account.username]);
  }, [account.status, account.username, register, setValue]);

  const statusValue = watch('status');
  return (
    <>
      <Button type="link" block onClick={handleShow}>
        <FormattedMessage id="edit-status.text" defaultMessage="Edit Status" />
      </Button>

      <StyledModal
        title={
          <span className="fs-14">
            <FormattedMessage
              id="edit-status.text"
              defaultMessage="Edit Status"
            />
          </span>
        }
        visible={isShow}
        width={640}
        centered
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id="update.text" defaultMessage="Update" />
          </Button>,
        ]}
      >
        <FormattedMessage
          id="members-involved.text"
          defaultMessage="Members Involved"
        />
        : 1 <FormattedMessage id="people.text" defaultMessage="People" />
        <StyledSelect
          mode="multiple"
          value={getValues('select')}
          onChange={(e) => setValue('select', e)}
        >
          <StyledSelect.Option key={account.username} value={account.username}>
            {account.username}
          </StyledSelect.Option>
        </StyledSelect>
        {errors.select && (
          <span style={{ color: 'red' }}>
            <FormattedMessage
              id="must-select-1.text"
              defaultMessage="Must select at least 1."
            />
          </span>
        )}
        <br />
        <br />
        <Row>
          <Col span={4} />
          <Col span={4}>
            <StyledSpan>
              <FormattedMessage id="status.text" defaultMessage="Status" />
            </StyledSpan>
          </Col>

          <Col span={16}>
            <Radio.Group
              value={statusValue}
              onChange={(e) => setValue('status', e.target.value)}
            >
              <Radio value="ACTIVE">
                <FormattedMessage id="active.text" defaultMessage="Active" />
              </Radio>

              <Radio value="INACTIVE">
                <FormattedMessage
                  id="deactive.text"
                  defaultMessage="Deactivate"
                />
              </Radio>

              <Radio value="DRAFT">
                <FormattedMessage id="draft.text" defaultMessage="Draft" />
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        <br />
        <Row style={{ marginBottom: '60px' }}>
          <Col span={8}>
            <StyledSpan style={{ transform: 'translateY(5px)' }}>
              <FormattedMessage
                id="super-admin-password.text"
                defaultMessage="Super Admin Password"
              />
            </StyledSpan>
          </Col>

          <Col span={12}>
            <Controller
              as={Input.Password}
              control={control}
              name="password"
              placeholder={translate(messages['please-key-in.text'])}
              rules={{ required: true, minLength: 6 }}
            />
            {errors.password?.type === 'required' && (
              <span style={{ color: 'red' }}>
                <FormattedMessage
                  id="password-is-required.text"
                  defaultMessage="Password is required."
                />
              </span>
            )}
            {errors.password?.type === 'minLength' && (
              <span style={{ color: 'red' }}>
                <FormattedMessage
                  id="must-be-atleast-6-char.text"
                  defaultMessage="Must be at least 6 characters."
                />
              </span>
            )}
          </Col>
          <Col span={4} />
        </Row>
      </StyledModal>
    </>
  );
};

// ===[ STYLE ] === //
const StyledSpan = styled.div`
  font-size: 15px;
  text-align: right;
  margin-right: 24px;
`;
