import styled from 'styled-components';
import { Select } from 'antd';

export const StyledLabel = styled.span`
  font-size: 10px;
  color: #000000d9;
`;

export const StyledSpan = styled.span`
  font-size: 10px;
  color: #000000d9;
  opacity: 0.4;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const StyledSelect = styled(Select)<any>`
  width: 100%;

  .ant-select-selection {
    padding: 4px;
    padding-top: 0;
    border-radius: 4px;
  }

  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }

  .ant-select-selection li.ant-select-selection__choice {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
`;
