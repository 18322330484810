import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Col, Row, Divider, Radio, Spin } from 'antd';
import { useState } from 'react';
import Drawer from 'SuperAdminMain/shared/components/Drawer/Drawer';
import { DrawerContainer } from 'SuperAdminMain/shared/styles/DrawerContainer';
import { ALink } from 'SuperAdminMain/shared/components/ALink/ALink';

interface IPublishAccount {
  onComplete: (status: string) => void;
  existingStatus?: string;
  onPrevious: () => void;
  submitting: boolean;
}
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

export const PublishAccount = ({
  onComplete,
  existingStatus,
  submitting,
  onPrevious,
}: IPublishAccount) => {
  const [status, setStatus] = useState(existingStatus || 'ACTIVE');

  return (
    <>
      <Drawer.Content height="calc(100% - 57px)">
        <DrawerContainer
          className="container container-small"
          style={{ width: '600px' }}
        >
          <Spin spinning={submitting} tip="Submitting data...">
            <div className="mt-4">
              <h1 style={{ fontSize: '40px' }}>
                <FormattedMessage
                  id="publish-account.header.text"
                  defaultMessage="You're ready to publish!"
                />
              </h1>
            </div>
            <h2 style={{ fontSize: '25px' }}>
              <FormattedMessage
                id="publish-account.body.text"
                defaultMessage="If you'd like to update your Client Account Setting, you can easily do all that after you hit publish."
              />
            </h2>

            <div className="mt-5">
              <Row>
                <Col span={13}>
                  <h4 className="text-bold">
                    <FormattedMessage
                      id="step-1.text"
                      defaultMessage="Step 1"
                    />
                  </h4>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <h4 className="text-muted mb-0">
                      <FormattedMessage
                        id="account-information.text"
                        defaultMessage="Account Information"
                      />
                    </h4>
                    <CheckCircleFilled
                      style={{ color: 'mediumseagreen', fontSize: '30px' }}
                    />
                  </div>
                  <h4 className="text-bold">
                    <ALink onClick={() => onPrevious()}>
                      <FormattedMessage id="edit.text" defaultMessage="Edit" />
                    </ALink>
                  </h4>
                  <Divider className="m-0" />

                  <h4 className="text-bold mt-3">
                    <FormattedMessage
                      id="step-2.text"
                      defaultMessage="Step 2"
                    />
                  </h4>
                  <div className="d-flex align-items-center justify-content-space-between">
                    <h4 className="text-muted mb-0">
                      <FormattedMessage
                        id="vendor-management.text"
                        defaultMessage="Vendor Management"
                      />
                    </h4>
                    <CheckCircleFilled
                      style={{ color: 'mediumseagreen', fontSize: '30px' }}
                    />
                  </div>
                  <h4 className="text-bold">
                    <ALink onClick={() => onPrevious()}>
                      <FormattedMessage id="edit.text" defaultMessage="Edit" />
                    </ALink>
                  </h4>
                  <Divider className="m-0" />

                  <h4 className="text-bold mt-5">
                    <FormattedMessage
                      id="status.text"
                      defaultMessage="Status"
                    />
                  </h4>
                  <Radio.Group
                    onChange={(value) => setStatus(value.target.value)}
                    value={status}
                    disabled={!!existingStatus}
                  >
                    <Radio style={radioStyle} value="ACTIVE">
                      <FormattedMessage
                        id="active.text"
                        defaultMessage="Active"
                      />
                    </Radio>
                    <Radio style={radioStyle} value="INACTIVE">
                      <FormattedMessage
                        id="inactive.text"
                        defaultMessage="Inactive"
                      />
                    </Radio>
                    <Radio style={radioStyle} value="DRAFT">
                      <FormattedMessage
                        id="draft.text"
                        defaultMessage="Draft"
                      />
                    </Radio>
                  </Radio.Group>

                  <div>
                    <Button
                      loading={submitting}
                      className="mt-5 px-4"
                      type="primary"
                      onClick={() => onComplete(status)}
                    >
                      <FormattedMessage
                        id="confirm.text"
                        defaultMessage="Confirm"
                      />
                    </Button>
                  </div>
                </Col>
                <Col span={11} />
              </Row>
            </div>
          </Spin>
        </DrawerContainer>
      </Drawer.Content>
    </>
  );
};
