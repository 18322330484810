import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Rate, Badge, message } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { withErrorHandler } from 'components/ErrorHandler';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import { useConfig } from 'hooks/useConfig';
import { useStarred, usePermissions } from 'store/accountState';
import { dateFilterMap } from 'utils/commonDateFilters';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import SidebarItem from '../SidebarItem/SidebarItem';
import { SIDEBAR_INFORMATION_NEXT, TOTAL_VALUES } from '../queries';
import { Container, StyledTextWatchlist } from './styles';

const RootInnerSidebar = ({
  sidebarStatus,
  setSidebarStatus,
}: {
  sidebarStatus: boolean;
  setSidebarStatus: (e: boolean) => void;
}) => {
  const { addCurrency } = useConfig();
  const translate = useTranslate();

  const { currentTimezone } = useReportsTimezone();

  const dailyFilter = dateFilterMap.today(currentTimezone);

  const { data: totalData } = useQuery(TOTAL_VALUES, {
    variables: {
      startDateTime: dailyFilter.start,
      endDateTime: dailyFilter.end,
    },
    pollInterval: 10000,
    fetchPolicy: 'network-only',
    onError: () =>
      message.error(
        <span data-testid="error">
          {translate(messages.INTERNAL_SERVER_ERROR)}
        </span>
      ),
  });
  const { starred } = useStarred();

  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'LIST'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const { data: sidebarData } = useQuery(SIDEBAR_INFORMATION_NEXT, {
    pollInterval: 10000,
    fetchPolicy: 'network-only',
    skip: !ALLOWED_LIST,
  });

  return (
    <Container
      onClick={() => setSidebarStatus(!sidebarStatus)}
      data-testid="container"
    >
      {!ALLOWED_LIST ? (
        <small>{translate(messages['loading.text'])}</small>
      ) : (
        <>
          <section>
            <SidebarItem
              langId="dashboard.total-bet.text"
              value={addCurrency(totalData?.totalBetAmount || 0)}
            />
            <SidebarItem
              langId="dashboard.total-win-loss.text"
              value={addCurrency(totalData?.totalWinlossAmount || 0)}
            />
            <SidebarItem
              langId="dashboard.total-net-profit.text"
              value={addCurrency(totalData?.totalNetProfitAmount || 0)}
            />
            <SidebarItem
              langId="online-members.text"
              value={sidebarData?.totalOnlineMembersCount?.totalCount || 0}
            />
            <SidebarItem
              langId="dashboard.total-deposits.text"
              value={addCurrency(totalData?.totalDepositAmount || 0)}
            />
            <SidebarItem
              langId="dashboard.total-withdrawal.text"
              value={addCurrency(totalData?.totalWithdrawalAmount || 0)}
            />
            <SidebarItem
              langId="dashboard.total-first-depositors.text"
              value={totalData?.totalFirstDepositorsCount || 0}
            />
            <SidebarItem
              langId="dashboard.total-members-registration.text"
              value={totalData?.totalMemberRegistrationsCount || 0}
            />
          </section>

          <section style={{ marginTop: '40px' }}>
            <Rate count={1} value={1} disabled />
            <StyledTextWatchlist>
              <FormattedMessage
                id="watchlist.text"
                defaultMessage="Watchlist"
              />
            </StyledTextWatchlist>
            <Badge count={starred.length} />
          </section>
        </>
      )}
    </Container>
  );
};

const FallBackUI = () => (
  <Container>
    <div className="d-flex align-items-center h-75">
      <div className="w-100">
        <ExclamationCircleOutlined className="text-warning fs-17" />
        <p className="fs-11">
          <FormattedMessage
            id="fatal-error.text"
            defaultMessage="An error has occured. Please contact Customer service for assistance."
          />
        </p>
      </div>
    </div>
  </Container>
);

export default withErrorHandler(RootInnerSidebar, true, <FallBackUI />);
