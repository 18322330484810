import styled from 'styled-components';
import { Layout } from 'antd';

const { Sider } = Layout;

export const StyledSider = styled(Sider)`
  .ant-layout-sider-children {
    overflow: hidden;
  }
`;

export const StyledContainer = styled.div`
  width: 232px;
  .no-padding {
    padding: 0;
  }
  .no-margin {
    margin: 0;
  }
`;

export default {};
