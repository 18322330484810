import styled from 'styled-components';
import { Select } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { memberLevelColors } from 'utils/memberLevelColors';

export const CustomSelect = ({
  colorSelected,
  children,
  ...restProps
}: any) => {
  const StyledSelect = styled(Select)<any>`
    .ant-select-selection-item {
      border-color: ${({ borderColor }) => borderColor};
      background-color: ${({ bgColor }) => bgColor};
      padding-right: 10px;
      border-radius: 11px;
    }

    .ant-select-selection-item-remove {
      display: none;
    }

    .ant-select-search--inline {
      display: none;
    }

    .ant-select-selection--multiple {
      cursor: pointer;
    }
  `;
  const colorBg = get(colorSelected, 'bgColor', memberLevelColors[0].bgColor);
  const colorBorder = get(
    colorSelected,
    'borderColor',
    memberLevelColors[0].borderColor
  );

  return (
    <StyledSelect borderColor={colorBorder} bgColor={colorBg} {...restProps}>
      {children}
    </StyledSelect>
  );
};
