import styled from 'styled-components';
import { Modal } from 'antd';

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;
export const Container = styled.div`
  display: inline-block;
  width: 58.5%;
`;

export default {};
