import React from 'react';
import gql from 'graphql-tag';
import { Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import coercedGet from 'utils/coercedGet';
import { RebateGroup } from 'interfaces/user.interface';

export const REBATES = gql`
  query RebateGroups($filter: RebateGroupsFilterInput!) {
    rebateGroups(filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type Props = {
  value: string;
  onChange: (e: string) => void;
};

export const RebateGroupsSelect = ({ value, onChange }: Props) => {
  const { data = {}, loading } = useQuery(REBATES, {
    variables: {
      filter: {
        status: {
          eq: 'ACTIVE',
        },
      },
    },
  });

  const rebateGroupsEdges = coercedGet(data, 'rebateGroups.edges', []);
  const rebateGroups = rebateGroupsEdges.map(
    (edge: { node: RebateGroup }) => edge.node
  );

  return (
    <Select loading={loading} value={value} onChange={onChange}>
      {rebateGroups.map((rebate: RebateGroup) => (
        <Select.Option key={rebate.id} value={rebate.id}>
          {rebate.name}
        </Select.Option>
      ))}
    </Select>
  );
};
