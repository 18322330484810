import React from 'react';
import { Tag } from 'antd';
import { capitalize, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';

type Props = {
  filters: { [key: string]: any };
  onFilterChange: (e: any) => void;
};

const FilterConditions: React.FC<Props> = ({ filters, onFilterChange }) => {
  const handleRemoveDateFilter = () => {
    onFilterChange({
      ...filters,
      date: null,
    });
  };

  const hasDateFilter = get(filters, 'date', null);

  const transformDatesFromFilters = () => {
    if (!hasDateFilter) return null;

    const { date } = transformMovableDateFilter({
      date: {
        gte: get(filters, 'date.gte'),
        lte: get(filters, 'date.lte'),
      },
    }) as { date: { gte: string; lte: string } };

    return date;
  };

  const transformedDate = transformDatesFromFilters();

  const onRemoveProduct = (product: string) => {
    const filteredProduct = filters.product.in.filter(
      (prod: string) => prod !== product
    );

    if (filteredProduct.length === 0 || !filteredProduct) {
      onFilterChange({
        ...filters,
        product: null,
      });
      return;
    }

    onFilterChange({
      ...filters,
      product: {
        in: filteredProduct,
      },
    });
  };

  return (
    <>
      {transformedDate ? (
        <Tag closable onClose={handleRemoveDateFilter}>
          <FormattedMessage id="date-range.text" defaultMessage="Date range" />:{' '}
          {formatDate(transformedDate.gte)}~{formatDate(transformedDate.lte)}
        </Tag>
      ) : null}

      {filters?.product ? (
        filters?.product?.in.map((product: string) => (
          <Tag
            closable
            onClose={(e) => {
              e.preventDefault();
              onRemoveProduct(product);
            }}
          >
            {capitalize(product)}
          </Tag>
        ))
      ) : (
        <></>
      )}
    </>
  );
};

export default FilterConditions;
