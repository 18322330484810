import React from 'react';
import { Tag } from 'antd';
import {
  MemberLevelStyle,
  MemberLoyaltyLevelStyle,
  MemberTagStyle,
} from './styles';

type Props = {
  color?: string;
  children: React.ReactNode | string;
  variant?: string;
  closable?: boolean;
  className?: string;
  visible?: boolean;
  onClose?: (e: any) => any;
};

const CustomTag: React.FC<Props> = ({
  color,
  children,
  onClose,
  variant,
  visible,
  closable,
  className,
}) => {
  const variantList = {
    memberLevel: MemberLevelStyle,
    memberLoyaltyLevel: MemberLoyaltyLevelStyle,
    memberTag: MemberTagStyle,
  };

  const StyledTag = variant ? variantList[variant] : Tag;

  return (
    <StyledTag
      color={color || '#fff'}
      closable={closable}
      onClose={(e: any) => onClose && onClose(e)}
      variant={variant}
      className={className || ''}
      visible={visible}
    >
      {variant === 'memberTag' ? (
        <div className="d-flex align-items-center">
          <div
            style={{
              width: 12,
              height: 12,
              borderRadius: 4,
              backgroundColor: color,
              marginRight: 6,
            }}
          />
          {children}
        </div>
      ) : (
        children
      )}
    </StyledTag>
  );
};

export default CustomTag;
