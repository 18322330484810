import gql from 'graphql-tag';

export const AFFILIATE_PROGRAMMES = gql`
  query affiliateProgrammes(
    $first: Int
    $after: Binary
    $filter: AffiliateProgrammesFilterInput
  ) {
    affiliateProgrammes(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          status
          membersCount
          name
          description
          default
          automaticPayoutClaiming
          negativeCarryOverEnabled
          vendorPlatformFees {
            vendor {
              id
              name
              nameEn: name(language: EN)
              nameZh: name(language: ZH)
              gameTypes
            }
            minimumCharge
            maximumCharge
            chargeLevels {
              minimumTotalWinloss
              percentage
            }
            gameType
          }
          minimumEffectiveBetRequirement
          minimumDepositRequirement
          minimumPayoutAccumulationEnabled
          minimumPayoutAccumulationAmount
          turnoverRequirementMultiplier
          settlementPeriod
          settlementTime
          settlementDayOfWeek
          settlementDayOfMonth
          payoutClaimOffsetDuration
          payoutClaimExpiryDuration
          payoutExpiryDuration
          levels {
            name
            minimumActiveMembersCount
            minimumNetProfit
            percentage
          }
          levelsRequirement
          depositTransactionCost
          thirdPartyDepositTransactionCost
          withdrawalTransactionCost
          thirdPartyWithdrawalTransactionCost
          promoCost
          rebateCost
          interestAccountCost
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const AFFILIATE_PROGRAMME = gql`
  query affiliateProgramme($id: ID!) {
    affiliateProgramme(id: $id) {
      id
      status
      membersCount
      name
      description
      default
      automaticPayoutClaiming
      negativeCarryOverEnabled
      vendorPlatformFees {
        vendor {
          id
          name
          nameEn: name(language: EN)
          nameZh: name(language: ZH)
          gameTypes
        }
        minimumCharge
        maximumCharge
        chargeLevels {
          minimumTotalWinloss
          percentage
        }
        gameType
      }
      minimumEffectiveBetRequirement
      minimumDepositRequirement
      minimumPayoutAccumulationEnabled
      minimumPayoutAccumulationAmount
      turnoverRequirementMultiplier
      settlementPeriod
      settlementTime
      settlementDayOfWeek
      settlementDayOfMonth
      payoutClaimOffsetDuration
      payoutClaimExpiryDuration
      payoutExpiryDuration
      levels {
        name
        minimumActiveMembersCount
        minimumNetProfit
        percentage
      }
      levelsRequirement
      depositTransactionCost
      thirdPartyDepositTransactionCost
      withdrawalTransactionCost
      thirdPartyWithdrawalTransactionCost
      promoCost
      rebateCost
      interestAccountCost
    }
  }
`;

export const AFFILIATE_REQUESTS = gql`
  query affiliateRequests(
    $first: Int
    $after: Binary
    $filter: AffiliateRequestsFilterInput
  ) {
    affiliateRequests(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        node {
          id
          serialCode
          programme {
            id
            name
          }
          member {
            id
            username
            realName
            memberLoyaltyLevels {
              id
              programme {
                id
                name
              }
              name
              color
            }
          }
          status
          dateTimeCreated
          dateTimeUpdated
          processor {
            id
            username
          }
          dateTimeProcessingStarted
          dateTimeProcessingEnded
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const AFFILIATE_REQUESTS_CSV_DATA = gql`
  query affiliateRequests(
    $first: Int
    $after: Binary
    $filter: AffiliateRequestsFilterInput
  ) {
    affiliateRequests(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          serialCode
          programme {
            id
            name
          }
          member {
            id
            username
            realName
            memberLoyaltyLevels {
              id
              programme {
                id
                name
              }
              name
              color
            }
          }
          status
          dateTimeCreated
          dateTimeUpdated
          processor {
            id
            username
          }
          dateTimeProcessingStarted
          dateTimeProcessingEnded
        }
      }
    }
  }
`;

export const AFFILIATE_REQUEST = gql`
  query affiliateRequest($id: ID!) {
    affiliateRequest(id: $id) {
      id
      serialCode
      processor {
        id
        username
      }
      programme {
        id
        name
      }
      formFields {
        ... on CustomFormField {
          type
          required
          label
          pattern
        }
        ... on BuiltInFormField {
          type
          required
          field
        }
      }
      formFieldValues
      member {
        id
        username
        realName
        gender
        dateOfBirth
        email
        mobileNumber
        wechatId
        qqId
        bankAccounts {
          edges {
            node {
              id
              accountNumber
            }
          }
        }
        status
        memberLevel {
          id
          name
          color
        }
        memberLoyaltyLevels {
          id
          programme {
            id
            name
          }
          name
          color
        }
        tags {
          id
          name
          color
          description
        }
        totalTurnover
        totalPromoPayout
        lastOnlineDateTime
        registrationDateTime
      }
      status
      dateTimeCreated
      dateTimeUpdated
      remarks
    }
  }
`;

export const AFFILIATE_CRITERIA = gql`
  {
    config {
      affiliateRequestFormFields {
        ... on BuiltInFormField {
          type
          required
          field
        }
        ... on CustomFormField {
          type
          required
          label
          pattern
        }
      }
    }
  }
`;

export const AFFILIATE_REQUESTS_SERIAL_CODE = gql`
  query FilterAffiliateRequestsSerial(
    $first: Int
    $after: Binary
    $filter: AffiliateRequestsFilterInput
  ) {
    affiliateRequests(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        node {
          id
          serialCode
        }
      }
    }
  }
`;

export default {};
