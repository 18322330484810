import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { TabId } from 'types/nav';
import { CUSTOM_FILTERS } from './queries';

const { Option } = Select;

type Props = {
  onChange: (e: string) => void;
  value: string | null | undefined;
  placeHolder?: string;
  disabled?: boolean;
  context: TabId;
};

const SavedFilterSelect: React.FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled,
  context,
}) => {
  const [filter, setFilter] = useState({
    name: {
      contains: '',
    },
    context: {
      eq: context,
    },
  });

  const { loading, data = {}, refetch } = useQuery(CUSTOM_FILTERS, {
    variables: {
      filter,
      first: 10,
    },
    fetchPolicy: 'network-only',
  });

  const handleSearch = (e: string) => {
    setFilter({
      ...filter,
      name: {
        contains: e,
      },
    });
    refetch();
  };

  const handleChange = (e: string) => {
    onChange(e);
  };

  const handleRefetch = () => {
    refetch();
  };

  const { customFilters = {} }: any = data;
  const { edges = [] } = customFilters;

  return (
    <Select
      value={value || undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      loading={loading}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
      onFocus={() => handleRefetch()}
    >
      {edges.map(({ node }: any) => (
        <Option key={node.value} value={node.value}>
          {node.label}
        </Option>
      ))}
    </Select>
  );
};

export default SavedFilterSelect;
