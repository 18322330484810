import React from 'react';
import Icon from '@ant-design/icons';
import { Button, Col, Input, Radio, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { MemberMarkerSelect } from 'components/MemberMarkerSelect/MemberMarkerSelect';
import MultiVIPSelect from 'components/MultiVIPSelect';
import { Controller } from 'react-hook-form';
import useTranslate from 'utils/useTranslate';
import { USERNAME_PASSWORD } from 'constants/uploadExcelValidation';
import MemberTagsSelect from 'components/MemberTagsSelect';
import { LabelContainerDiv, TextLabel, StyledLabel } from '../utils';
import { ReactComponent as CloudDownloadIcon } from '../../Content/components/Members/components/MembersTable/components/BalanceForm/components/assets/cloud_download-24px.svg';
import { UploadExcel } from './UploadExcel';

type Props = {
  errors: any;
  getValues: any;
  setValue: any;
  control: any;
  onChangeExcelData: any;
  setError: any;
};

export const ImportMembersForm = ({
  errors,
  getValues,
  setValue,
  control,
  onChangeExcelData,
  setError,
}: Props) => {
  const translate = useTranslate();

  return (
    <div className="p-3">
      <Button>
        <a
          href={`${process.env.PUBLIC_URL}/excel/sample_template_import_members.xls`}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <Icon component={CloudDownloadIcon} className="text-primary mr-2" />
          <FormattedMessage
            id="download-template.text"
            defaultMessage="Download Template"
          />
        </a>
      </Button>

      <div className="mt-3">
        <TextLabel>{translate(messages.UPLOAD_EXCEL)}</TextLabel>
        <div>
          <UploadExcel
            validation={USERNAME_PASSWORD}
            onChange={onChangeExcelData}
            onFileError={() =>
              setError('fileId', {
                type: 'manual',
                message: translate(messages.IMPORT_MEMBERS_10000_ROWS_HELPER),
              })
            }
            excelType="import"
          />
          {errors?.fileId?.message && (
            <div>
              <small className="text-danger">{errors?.fileId?.message}</small>
            </div>
          )}
        </div>
      </div>

      <div className="mt-3">
        <Row gutter={16}>
          <Col span={12}>
            <TextLabel>{translate(messages.MEMBER_MARKER)}</TextLabel>
            <MemberMarkerSelect
              name="memberMarker"
              value={getValues()?.memberMarker}
              onChange={setValue}
            />

            <StyledLabel>
              <MultiVIPSelect
                memberLevel={getValues()?.memberLoyaltyLevels}
                onChange={(val: any) => setValue('memberLoyaltyLevels', val)}
              />
            </StyledLabel>
          </Col>

          <Col span={12}>
            <TextLabel>{translate(messages.STATUS)}</TextLabel>
            <Row>
              <Col span={10}>
                <Radio
                  checked={getValues()?.status}
                  onChange={() => setValue('status', true)}
                >
                  {translate(messages.ENABLED)}
                </Radio>
              </Col>
              <Col span={10}>
                <Radio
                  checked={!getValues()?.status}
                  onChange={() => setValue('status', false)}
                >
                  {translate(messages.DISABLED)}
                </Radio>
              </Col>
            </Row>

            <div className="mt-4">
              <TextLabel>
                {translate(messages.FORCE_WITHDRAWAL_PIN_CHANGE)}
              </TextLabel>
              <Row>
                <Col span={10}>
                  <Radio
                    disabled
                    checked={getValues()?.withdrawalPinChange}
                    onChange={() => setValue('withdrawalPinChange', true)}
                  >
                    {translate(messages.YES)}
                  </Radio>
                </Col>
                <Col span={10}>
                  <Radio
                    disabled
                    checked={!getValues()?.withdrawalPinChange}
                    onChange={() => setValue('withdrawalPinChange', false)}
                  >
                    {translate(messages.NO)}
                  </Radio>
                </Col>
              </Row>
            </div>

            <div className="mt-4">
              <TextLabel>{translate(messages.FORCE_PASSWORD_CHANGE)}</TextLabel>
              <Row>
                <Col span={10}>
                  <Radio
                    checked={getValues()?.passwordChange}
                    onChange={() => setValue('passwordChange', true)}
                  >
                    {translate(messages.YES)}
                  </Radio>
                </Col>
                <Col span={10}>
                  <Radio
                    checked={!getValues()?.passwordChange}
                    onChange={() => setValue('passwordChange', false)}
                  >
                    {translate(messages.NO)}
                  </Radio>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        {/* <Row className="mt-3"> */}
        {/* {nextProfileValidation && ( */}
        {/*  <Col span={12}> */}
        {/*    <TextLabel>{translate(messages.PROFILE_VALIDATION)}</TextLabel> */}
        {/*    <Row> */}
        {/*      <Col span={10}> */}
        {/*        <Radio */}
        {/*          checked={getValues()?.profileValidation} */}
        {/*          onChange={() => setValue('profileValidation', true)} */}
        {/*        > */}
        {/*          {translate(messages.ON)} */}
        {/*        </Radio> */}
        {/*      </Col> */}
        {/*      <Col span={10}> */}
        {/*        <Radio */}
        {/*          checked={!getValues()?.profileValidation} */}
        {/*          onChange={() => setValue('profileValidation', false)} */}
        {/*        > */}
        {/*          {translate(messages.OFF)} */}
        {/*        </Radio> */}
        {/*      </Col> */}
        {/*    </Row> */}
        {/*  </Col> */}
        {/* )} */}
        {/* </Row> */}
      </div>

      <div className="mt-2">
        <Row gutter={16}>
          <Col span={12}>
            <TextLabel>{translate(messages.LABELS)}</TextLabel>
            <LabelContainerDiv>
              <MemberTagsSelect
                buttonProps={{ ghost: true, type: 'primary' }}
                value={getValues()?.tags}
                onChange={(e: any) => setValue('tags', e)}
              />
            </LabelContainerDiv>
          </Col>
          <Col span={12}>
            <TextLabel>{translate(messages.PASSWORD_TO_CONFIRM)}</TextLabel>
            <Controller
              name="password"
              control={control}
              as={<Input.Password />}
            />
            {errors?.password?.message && (
              <small className="text-danger">{errors?.password?.message}</small>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
