import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'please-enter.text': {
    id: 'please-enter.text',
    defaultMessage: 'Please enter',
  },
  'confirm.text': {
    id: 'confirm.text',
    defaultMessage: 'Confirm',
  },
  'are-you-sure.text': {
    id: 'are-you-sure.text',
    defaultMessage: 'Are you sure?',
  },

  '3rd-party-withdrawal-provider-duplicated.text': {
    id: '3rd-party-withdrawal-provider-duplicated.text',
    defaultMessage: 'Successfully duplicated',
  },
  'successfully-saved-changes.text': {
    id: 'successfully-saved-changes.text',
    defaultMessage: 'Successfully saved changes',
  },

  '3rd-party-withdrawal-providers-created.text': {
    id: '3rd-party-withdrawal-providers-created.text',
    defaultMessage: 'Withdrawal provider created',
  },
  'add-withdrawal-provider.text': {
    id: 'add-withdrawal-provider.text',
    defaultMessage: 'Add withdrawal provider',
  },

  'merchant-code.text': {
    id: 'merchant-code.text',
    defaultMessage: 'Merchant Code',
  },
  'deposit-provider-details.text': {
    id: 'deposit-provider-details.text',
    defaultMessage: 'Deposit provider details',
  },
  'primary-credential.text': {
    id: 'primary-credential.text',
    defaultMessage: 'Primary Credential',
  },
  'key.text': {
    id: 'key.text',
    defaultMessage: 'Key',
  },
  'api-key.text': {
    id: 'api-key.text',
    defaultMessage: 'API key',
  },
  'required.text': {
    id: 'required.text',
    defaultMessage: 'Required',
  },
  'add.text': {
    id: 'add.text',
    defaultMessage: 'Add',
  },
  'save.text': {
    id: 'save.text',
    defaultMessage: 'Save',
  },
  'ok.text': {
    id: 'ok.text',
    defaultMessage: 'OK',
  },
  'cancel.text': {
    id: 'cancel.text',
    defaultMessage: 'Cancel',
  },

  'provider-name.text': {
    id: 'provider-name.text',
    defaultMessage: 'Provider name',
  },
  'type.text': {
    id: 'type.text',
    defaultMessage: 'Type',
  },
  'api-key-preview.text': {
    id: 'api-key-preview.text',
    defaultMessage: 'API key preview',
  },
  'date-created.text': {
    id: 'date-created.text',
    defaultMessage: 'Date created',
  },
  'actions.text': {
    id: 'actions.text',
    defaultMessage: 'Actions',
  },
  'edit.text': {
    id: 'edit.text',
    defaultMessage: 'Edit',
  },
  'duplicate.text': {
    id: 'duplicate.text',
    defaultMessage: 'Duplicate',
  },
  'delete.text': {
    id: 'delete.text',
    defaultMessage: 'Delete',
  },
  KEY_PREVIEW: {
    id: 'KEY_PREVIEW',
    defaultMessage: 'Key Preview',
  },

  /** Midpay Direct */
  SELECT_ONE: {
    id: 'select-one.text',
    defaultMessage: 'Select one',
  },
  MIDPAY_DIRECT_CREDS: {
    id: 'MIDPAY_DIRECT_CREDS',
    defaultMessage: 'Midpay Direct Credentials(API Key)',
  },
  MIDPAY_DIRECT_CREDS_REQUIRED: {
    id: 'MIDPAY_DIRECT_CREDS_REQUIRED',
    defaultMessage: 'Midpay Direct Credentials(API Key) required',
  },
  THIRD_PARTY_GATEWAY: {
    id: 'THIRD_PARTY_GATEWAY',
    defaultMessage: '3rd party payment gateway',
  },
  THIRD_PARTY_GATEWAY_REQUIRED: {
    id: 'THIRD_PARTY_GATEWAY_REQUIRED',
    defaultMessage: '3rd party payment gateway required',
  },
  VERIFY_API_KEY_FIRST: {
    id: 'VERIFY_API_KEY_FIRST',
    defaultMessage: 'Verify API key first',
  },
  DIORPAY_MERCHANT_ID: {
    id: 'DIORPAY_MERCHANT_ID',
    defaultMessage: 'DiorPay Merchant ID',
  },
  MERCHANT_ID: {
    id: 'MERCHANT_ID',
    defaultMessage: 'Merchant ID',
  },
  SAFE_CODE: {
    id: 'SAFE_CODE',
    defaultMessage: 'Safe code',
  },
  PRIVATE_KEY: {
    id: 'PRIVATE_KEY',
    defaultMessage: 'Private key',
  },
  PUBLIC_KEY: {
    id: 'PUBLIC_KEY',
    defaultMessage: 'Public key',
  },
  MERCHANT_APP_ID: {
    id: 'MERCHANT_APP_ID',
    defaultMessage: 'Merchant app ID ',
  },
  MERCHANT_NAME: {
    id: 'MERCHANT_NAME',
    defaultMessage: 'Merchant name',
  },
  DEPOSIT_PROVIDER_DUPLICATE_SUCCESS: {
    id: 'DEPOSIT_PROVIDER_DUPLICATE_SUCCESS',
    defaultMessage: 'Deposit provider duplicated.',
  },
  DEPOSIT_PROVIDER_DUPLICATE_ERROR: {
    id: 'DEPOSIT_PROVIDER_DUPLICATE_ERROR',
    defaultMessage: 'Failed to duplicate deposited provider.',
  },
  INVALID_API_CREDENTIALS: {
    id: 'INVALID_API_CREDENTIALS',
    defaultMessage: 'Authentication failed. Please check API-KEY credentials.',
  },

  /** Dior pay 2 */
  VENDOR_MERCHANT_ID: {
    id: 'VENDOR_MERCHANT_ID',
    defaultMessage: 'Vendor Merchant ID',
  },
  ENCRYPTION_KEY: {
    id: 'ENCRYPTION_KEY',
    defaultMessage: 'Encryption Key',
  },
});

export default messages;
