import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import { ALink } from 'components/ALink/ALink';

const GameVendorsScreenTab = ({
  vendors,
  gameCategory,
  activeVendors,
}: {
  vendors: any[];
  gameCategory: string;
  activeVendors?: boolean;
}) => {
  const { addTab } = useScreenTabV2();
  const handleClick = () => {
    addTab({
      id: 'specific-game-vendors',
      state: {
        vendors,
        activeVendors,
        resolvedGameCategory: gameCategory,
        translateOpts: { category: gameCategory },
      },
    });
  };

  return <ALink onClick={handleClick}>{vendors.length}</ALink>;
};

export default GameVendorsScreenTab;
