import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { UPDATE_CUSTOM_FILTER } from 'graphql/mutations/customFilter.mutation';
import { GET_CUSTOM_FILTERS } from 'graphql/queries/customFilter.query';
import messages from 'messages';
import React, { FC, useState } from 'react';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';

type Props = {
  searchSettings: {
    context: string;
    id: string;
    isShow: boolean;
    name: string;
    filter: Record<string, any>;
  };
  SearchSettingsFormNode: any;
  quickSearchId?: string;
  refetch: () => void;
  onClose: () => void;
};

const EditSearchSettings: FC<Props> = ({
  searchSettings,
  quickSearchId,
  SearchSettingsFormNode,
  refetch,
  onClose,
}) => {
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [updateCustomFilter] = useMutation(UPDATE_CUSTOM_FILTER);
  const handleSubmit = async (fields: Record<string, any>) => {
    try {
      setLoading(true);
      await updateCustomFilter({
        variables: {
          id: searchSettings.id,
          input: {
            name: searchSettings.name,
            filter: removeNull(fields),
          },
        },
        refetchQueries: [
          {
            query: GET_CUSTOM_FILTERS,
            variables: {
              filter: {
                context: {
                  eq: quickSearchId,
                },
              },
            },
          },
        ],
      });
      message.success(translate(messages['search-settings-updated.text']));
      setLoading(false);
    } finally {
      refetch();
      setLoading(false);
    }
  };

  return (
    <SearchSettingsFormNode
      initialValues={searchSettings.filter}
      onSubmit={handleSubmit}
      isLoading={loading}
      onClose={onClose}
    />
  );
};

export default EditSearchSettings;
