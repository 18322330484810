import React from 'react';
import { union, head } from 'lodash';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message } from 'antd';

import { Formik } from 'formik';
import coercedGet from 'utils/coercedGet';

import { Permissions } from 'constants/permissions3';

import useTranslate from 'utils/useTranslate';
import globalMessage from 'messages';
import validationSchema from './validationSchema';
import PermissionsComponent from './components/Permissions';
import messages from '../../messages';

const PermissionGroupForm = React.forwardRef(
  (props: Record<string, any>, ref) => {
    const { createPermissionGroup, onClose, permissionGroup } = props;

    const getPermissions = (group: Record<string, any>) =>
      Object.values(Permissions).reduce(
        (acc, curr) => ({
          ...acc,
          [`ALL_${curr}`]: coercedGet(group, 'permissions', []).filter(
            (item: string) => head(item.split(':') || '') === curr
          ),
        }),
        {}
      );

    const existingPermissionGroup = {
      permissions: getPermissions(permissionGroup) || {},
      name: coercedGet(permissionGroup, 'name', ''),
      ...(permissionGroup.id && { id: permissionGroup.id }),
    };

    const initialValues: Record<string, any> = {
      ...existingPermissionGroup,
    };

    const formItemHorizontalLayout = {
      labelCol: {
        span: 10,
      },
      wrapperCol: {
        span: 14,
      },
    };
    const translate = useTranslate();
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            const { name, permissions, id } = values;

            const permissionValues: any = Object.values(permissions).reduce(
              (acc: any, curr: any) => union(acc, curr),
              []
            );

            if (!permissionValues.length) {
              message.error({
                content: translate(messages.selectonepermission),
              });
              return;
            }

            const response = await createPermissionGroup({
              variables: {
                id: id || null,
                input: {
                  name,
                  permissions: permissionValues,
                },
              },
            });
            if (response && response.data) {
              resetForm();
              onClose();
            }
          }}
        >
          {({
            handleSubmit,
            values,
            errors,
            handleChange,
            handleBlur,
            resetForm,
          }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            React.useImperativeHandle(ref, () => ({
              getFormValues: () => values,
              handleSubmit,
              resetForm,
            }));

            return (
              <Form
                onSubmit={handleSubmit}
                layout="horizontal"
                labelAlign="left"
              >
                <div className="p-4">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Form.Item
                      className="mb-0 mt-2"
                      label={translate(messages.permissiongroupname)}
                      {...formItemHorizontalLayout}
                      colon={false}
                      validateStatus={errors.name ? 'error' : 'success'}
                      help={
                        errors.name ? translate(globalMessage.REQUIRED) : null
                      }
                    >
                      <Input
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        style={{ width: 300 }}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    validateStatus={
                      errors.selectedPermissions
                        ? translate(globalMessage.REQUIRED)
                        : null
                    }
                    help={
                      errors.selectedPermissions
                        ? translate(messages.selectonepermission)
                        : null
                    }
                  >
                    <PermissionsComponent />
                  </Form.Item>
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
);

export default PermissionGroupForm;
