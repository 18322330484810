import gql from 'graphql-tag';

export const MEMBER_LEVEL = gql`
  query MemberLevel($first: Int, $after: Binary, $filter: MembersFilterInput) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          memberLevel {
            id
            name
            default
            color
            status
          }
        }
      }
    }
  }
`;
