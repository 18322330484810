import * as yup from 'yup';
import { defineMessages } from 'react-intl';
import {
  isTypeBank,
  isPaymentTypeInternal,
  isTypeUSDT,
  isTypeHexopay,
  isTypeSkrill,
  isTypeNeteller,
  isTypeAstroapy,
  isTypePayByAstroPay,
} from 'constants/paymentTypes';
import messages from 'messages';

const messagesLocal = defineMessages({
  suggestedAmountsError: {
    id: 'suggestedAmountsError',
    defaultMessage: 'Amount should be in the range of Single Deposit values',
  },
});

export const getValidationSchema = (translate: any) => {
  yup.setLocale({
    mixed: {
      required: translate(messages.required),
    },
    string: {
      max: ({ max }) => translate(messages['number.max.error'], { max }),
    },
    number: {
      min: ({ min }) => translate(messages['number.min.error'], { min }),
      max: ({ max }) => translate(messages['number.max.error'], { max }),
      positive: ({ more }) =>
        translate(messages['number.min.error'], { min: more + 1 }),
    },
  });

  return yup.object().shape({
    name: yup
      .string()
      .max(24)
      .required(),
    // excludedMemberLevel: yup.array().min(1, 'Select at least 1'),
    // memberLoyaltyLevel: yup.array().min(1, 'Select at least 1'),
    type: yup
      .string()
      .nullable()
      .when(['paymentType', 'depositProvider'], {
        is: (paymentType, depositProvider) => {
          const isInternal = isPaymentTypeInternal(paymentType);
          return isInternal || (!isInternal && depositProvider);
        },
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    paymentChannel: yup
      .string()
      .nullable()
      .when(['paymentType', 'depositProvider', 'type'], {
        is: (paymentType, depositProvider, type) =>
          !isPaymentTypeInternal(paymentType) &&
          depositProvider &&
          !isTypeBank(type),
        then: yup
          .string()
          .nullable()
          .required(),
      }),

    allowCustomAmount: yup.boolean(),

    minimumDepositAmount: yup
      .number()
      .min(0)
      .when('allowCustomAmount', {
        is: true,
        then: yup.number().required(),
      }),

    exchangeRateSpread: yup
      .number()
      .min(0)
      .when(['type', 'paymentType', 'id'], {
        is: (type, paymentType) =>
          isTypeUSDT(type) && isPaymentTypeInternal(paymentType),
        then: yup
          .number()
          // .required()
          .nullable(),
      }),

    exchangeRateUpdateFrequency: yup
      .number()
      .min(1)
      .when(['type', 'paymentType', 'id'], {
        is: (type, paymentType) =>
          isTypeUSDT(type) && isPaymentTypeInternal(paymentType),
        then: yup
          .number()
          // .required()
          .nullable()
          .test(
            'exchangeRateUpdateFrequencyUnit',
            '',
            function validateFrequency(exchangeRateUpdateFrequency): any {
              const exchangeRateUnit = this.parent
                .exchangeRateUpdateFrequencyUnit;
              let errorMessage: any = '';
              if (
                exchangeRateUnit === 'm' &&
                exchangeRateUpdateFrequency < 15
              ) {
                errorMessage = this.createError({
                  message:
                    'Update Frequency Time should be at least 15 minutes',
                });
              }
              return errorMessage;
            }
          ),
      }),

    exchangeRateUpdateFrequencyUnit: yup.string().nullable(),

    minimumConfirmationsCount: yup
      .number()
      .min(1)
      .when(['type', 'paymentType', 'id'], {
        is: (type, paymentType) =>
          isTypeUSDT(type) && isPaymentTypeInternal(paymentType),
        then: yup
          .number()
          // .required()
          .nullable(),
      }),
    maximumDepositAmount: yup
      .number()
      .min(yup.ref('minimumDepositAmount'))
      .when('allowCustomAmount', {
        is: true,
        then: yup.number().required(),
      }),

    maximumDepositAmountPerDay: yup
      .number()
      .min(yup.ref('maximumDepositAmount') || 0)
      .when('allowCustomAmount', {
        is: true,
        then: yup.number().required(),
      }),

    suggestedAmounts: yup
      .array()
      .test('isSuggestedAmountsValid', '', function validateInput(
        suggestedAmounts
      ) {
        /* *
         * This function will check if the `suggestedAmounts` are greater than equal to `maximumDepositAmount`
         *
         * Note: We used the `this` context, the test function must be a function expression (function test(value) {}),
         * not an arrow function. Since arrow functions have lexical context.
         * (reference:https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema)
         * */

        const { minimumDepositAmount, maximumDepositAmount } = this.parent;
        let isValid: any = true;

        if (maximumDepositAmount && suggestedAmounts.length) {
          const isSuggestedAmountValid = suggestedAmounts.some(
            (suggestedAmount: any) =>
              suggestedAmount >= minimumDepositAmount &&
              suggestedAmount <= maximumDepositAmount
          );

          isValid = isSuggestedAmountValid
            ? true
            : this.createError({
                message: translate(messagesLocal.suggestedAmountsError),
              });
        }

        return isValid;
      }),
    depositExpiry: yup
      .number()
      .positive()
      .required(),
    turnoverRequirementMultiplier: yup
      .number()
      .min(0)
      .required(),
    bank: yup
      .string()
      .nullable()
      .when(['type', 'paymentType'], {
        is: (type, paymentType) =>
          isTypeBank(type) && isPaymentTypeInternal(paymentType),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    accountNumber: yup
      .string()
      .nullable()
      .when(['type', 'paymentType'], {
        is: (type, paymentType) =>
          isTypeBank(type) && isPaymentTypeInternal(paymentType),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    accountName: yup
      .string()
      .nullable()
      .when(['type', 'paymentType'], {
        is: (type, paymentType) =>
          isTypeBank(type) && isPaymentTypeInternal(paymentType),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    nickname: yup
      .string()
      .nullable()
      .when(['type', 'paymentType'], {
        is: (type, paymentType) =>
          !isTypeBank(type) &&
          !isTypeUSDT(type) &&
          !isTypeHexopay(type) &&
          !isTypeNeteller(type) &&
          !isTypeSkrill(type) &&
          !isTypeAstroapy(type) &&
          !isTypePayByAstroPay(type) &&
          isPaymentTypeInternal(paymentType),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    blockchainAddress: yup
      .string()
      .nullable()
      .when(['type', 'paymentType', 'id'], {
        is: (type, paymentType) =>
          isTypeUSDT(type) && isPaymentTypeInternal(paymentType),
        then: yup
          .string()
          // .required()
          .nullable(),
      }),

    image: yup
      .string()
      .nullable()
      .when(['type', 'paymentType', 'id'], {
        is: (type, paymentType) =>
          !isTypeBank(type) &&
          isPaymentTypeInternal(paymentType) &&
          !isTypeHexopay(type) &&
          !isTypeNeteller(type) &&
          !isTypeAstroapy(type) &&
          !isTypePayByAstroPay(type) &&
          !isTypeSkrill(type),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    depositProvider: yup
      .string()
      .nullable()
      .when('paymentType', {
        is: (value) => !isPaymentTypeInternal(value),
        then: yup
          .string()
          .required()
          .nullable(),
      }),
    transactionFee: yup
      .number()
      .min(0)
      .when('transactionFeeType', {
        is: 'RELATIVE',
        then: yup.number().max(100),
      }),
  });
};
