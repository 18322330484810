import React, { useCallback, useState } from 'react';
import { Select, Spin } from 'antd';
import gql from 'graphql-tag';
import { useDebounce, useUpdateEffect } from 'react-use';
import { useLazyQueryPromise } from 'hooks/useLazyQueryPromise';
import esGet from 'utils/esGet';
import { Address, LoqateAddress } from 'types/graphqlTypes';

const LOQATE_ADDRESS = gql`
  query LoqateAddress($filter: LoqateAddressesFilterInput!) {
    loqateAddresses(filter: $filter) {
      id
      addressText
      description
      type
    }
  }
`;

const ADDRESS = gql`
  query Address($id: ID!) {
    address(id: $id) {
      building
      premise
      street
      locality
      stateDistrict
      province
      postCode
      country
    }
  }
`;

type Props = {
  country?: string;
  onSelectAddress: (address: Address, description: string) => void;
  value?: Record<string, any>;
};

type FoundAddress = {
  value: string;
  label: string;
  description: string;
  type: string;
};

const PostalCodeSelect = ({ onSelectAddress, value, country }: Props) => {
  const [fetchLoqateAddress, { loading }] = useLazyQueryPromise(
    LOQATE_ADDRESS,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  const [fetchAddress] = useLazyQueryPromise(ADDRESS);

  const [searchText, setSearchText] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [foundAddresses, setFoundAddresses] = useState<FoundAddress[]>([]);
  const [fetching, setFetching] = useState(false);
  const [postCode, setPostCode] = useState(value);
  const [calledInitially, setCalledInitially] = useState(false);
  const [open, setOpen] = useState(false);

  useUpdateEffect(() => {
    if (calledInitially) {
      return;
    }

    setPostCode(value);
  }, [value, country]);

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(searchText);
    },
    1000,
    [searchText]
  );

  const onFetchPostcodes = useCallback(
    (id?: string) => {
      setCalledInitially(true);
      fetchLoqateAddress({
        filter: {
          countries: country || 'United Kingdom',
          searchText: debouncedValue,
          ...(id && { container: id }),
        },
      })
        .then(({ data }: { data: { loqateAddresses: LoqateAddress[] } }) => {
          const loqateAddresses: LoqateAddress[] = esGet(
            data?.loqateAddresses,
            []
          );
          const mappedValueForSelect = loqateAddresses.map((loqateAdd) => ({
            label: `${loqateAdd.addressText}, ${loqateAdd.description ?? ''}`,
            value: loqateAdd.id,
            description: loqateAdd.description!,
            type: loqateAdd?.type,
          }));
          setFoundAddresses(mappedValueForSelect);
        })
        .finally(() => {
          setFetching(false);
        });
    },
    [debouncedValue, fetchLoqateAddress, country]
  );

  useUpdateEffect(() => {
    if (!debouncedValue) {
      return;
    }
    onFetchPostcodes();
  }, [debouncedValue]);

  const onGetAddress = (id: string, addressData: Record<string, any>) => {
    fetchAddress({
      id,
    }).then(({ data }: any) => {
      const address: Address = esGet(data?.address, {});

      delete address.__typename;
      const keys = Object.keys(address);
      keys.forEach((key) => {
        if (!address[key]) {
          delete address[key];
        }
      });

      onSelectAddress(address, addressData.description);
      setPostCode({ label: address.postCode, value: id });
    });
  };

  const addressSelect = (id: string) => {
    const address = foundAddresses.find((addr) => addr.value === id)!;
    if (address.type !== 'Address') {
      setFetching(true);
      setFoundAddresses([]);
      onFetchPostcodes(address?.value);
      return;
    }

    setOpen(false);
    setCalledInitially(false);
    onGetAddress(id, address!);
  };

  return (
    <Select
      data-testid="postal-code-select"
      placeholder="Select Postal Code"
      labelInValue
      value={(postCode as any) || undefined}
      showSearch
      open={open}
      loading={loading}
      onSearch={(searchVal) => {
        setOpen(true);
        cancel();
        setFetching(true);
        setFoundAddresses([]);
        setSearchText(searchVal);
      }}
      onChange={(data: { label: string; value: string }) => {
        addressSelect(data.value);
      }}
      filterOption={false}
      notFoundContent={fetching ? <Spin size="small" /> : null}
    >
      {foundAddresses.map((add) => (
        <Select.Option key={add.value} value={add.value}>
          {add.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PostalCodeSelect;
