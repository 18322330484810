import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { columnAlign } from 'utils/tableAlignment';
import moment from 'moment';
import { SharedStyledTable } from 'styles/SharedStyledTable';
import { DATE_TIME_FORMAT } from 'constants/date';
import { SystemNotification } from 'types/graphqlTypes';
import { PriorityAlertTag } from '../PriorityAlertTag';

export const ContentTable = ({
  systemNotifications,
}: {
  systemNotifications: Record<string, any>;
}) => {
  const [notifications, setNotifications] = useState([]);
  const columns = [
    {
      title: <FormattedMessage id="title.text" defaultMessage="Title" />,
      align: columnAlign.onCenter,
      dataIndex: 'title',
      key: 'title',
      render: (title: string) => title,
    },
    {
      title: <FormattedMessage id="message.text" defaultMessage="Message" />,
      align: columnAlign.onCenter,
      dataIndex: 'message',
      key: 'message',
      render: (message: string) => message,
    },
    {
      title: (
        <FormattedMessage
          id="priority-alert.text"
          defaultMessage="Priority Alert"
        />
      ),
      align: columnAlign.onCenter,
      dataIndex: 'priority',
      key: 'priority',
      render: (priorityAlert: string) => (
        <PriorityAlertTag priority={priorityAlert} />
      ),
    },
    {
      title: (
        <FormattedMessage id="date-time.text" defaultMessage="Date / Time" />
      ),
      align: columnAlign.onCenter,
      dataIndex: 'dateTimeCreated',
      key: 'dateTimeCreated',
      render: (dateTime: string) => moment(dateTime).format(DATE_TIME_FORMAT),
    },
  ];

  useEffect(() => {
    const notificationsData = get(systemNotifications, 'edges', []).map(
      (x: { node: SystemNotification }) => x.node
    );
    setNotifications(notificationsData);
  }, [systemNotifications]);

  return (
    <>
      <SharedStyledTable
        size="middle"
        pagination={false}
        columns={columns}
        dataSource={notifications}
        rowKey={(dataMock: { id: string }) => dataMock.id}
      />
    </>
  );
};
