import React from 'react';
import { ALink } from 'components/ALink/ALink';
import { useMemberTab } from 'utils/handleAddTab';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import { showPermissionError } from 'components/Navbar/Navbar';
import { Member } from 'types/graphqlTypes';

type Props = {
  member: Member;
  cb?: () => void;
};

const MemberProfile = ({ member, cb }: Props) => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_VIEW_DETAILS } = collectPermissions(
    role,
    permissions,
    ['VIEW_DETAILS'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT
  );

  const addMemberTab = useMemberTab();

  const click = () => {
    if (!ALLOWED_VIEW_DETAILS) {
      showPermissionError('Member Management - View Details');
      return;
    }
    addMemberTab(member);
    if (cb) cb();
  };

  return <ALink onClick={click}>{member.username}</ALink>;
};

export default MemberProfile;
