import gql from 'graphql-tag';

export const USERNAMES = gql`
  query Usernames($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;
