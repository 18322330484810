import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { Select as AntdSelect } from 'antd';
import { SelectProps } from 'interfaces/select.interface';

const Select: any = AntdSelect;
const MultiplierSelectInput = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
}: SelectProps) => {
  const { Option } = Select;
  const [options, setOptions] = useState<any>([]);
  const handleSearch = (e: any) => {
    const isValid = /^[0-9]*(?:\.[0-9]*)?$/.test(e);
    if (e && isValid) {
      let lastDigit = e.slice(-1);

      if (lastDigit === '.') {
        lastDigit = 0;
        setOptions(
          [
            ...Array.from({
              length: 10 - lastDigit,
            }),
          ].map((_: any, i: number) =>
            parseFloat(`${e}${lastDigit + Number(i)}`)
          )
        );
      } else {
        lastDigit = Number(lastDigit);
        setOptions(
          [
            ...Array.from({
              length: 10 - lastDigit,
            }),
          ].map((_: any, i: number) =>
            parseFloat(`${e.replace(/.$/, `${lastDigit + Number(i)}`)}`)
          )
        );
      }
    } else {
      setOptions([]);
    }
  };

  const handleChange = (e: any) => {
    onChange(e);
  };

  return (
    <Select
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
    >
      {// eslint-disable-next-line no-shadow
      options.map((option: any, key: number) => (
        <Option value={option} key={key}>
          {option}
        </Option>
      ))}
    </Select>
  );
};

export default MultiplierSelectInput;
