import { createSlice } from '@reduxjs/toolkit';
import storage from '../../constants/storage';

export enum JobType {
  CREATE_MEMBERS = 'CREATE_MEMBERS',
  BULK_MANUAL_ADJUSTMENT = 'BULK_MANUAL_ADJUSTMENT',
}

type JobStatusType = 'PENDING' | 'IN_PROGRESS' | 'SUCCEEDED' | 'FAILED';

type Job = {
  name: string | undefined;
  id: string | undefined;
  progress: number;
  status: JobStatusType;
  dateTimeCreated: Date;
  failCount?: number;
  successCount?: number;
  errorReportFile?: {
    uri?: string;
  };
  failedReport?: any[];
};

export enum JobStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export type JobState = {
  jobs: Array<Job>;
  activeJobs: string[];
};

const initialState: JobState = {
  jobs: [],
  activeJobs: [],
};

const getJobsStorage = () => localStorage.getItem(storage.JOBS);
const setJobsStorage = (jobs: any) =>
  localStorage.setItem(storage.JOBS, JSON.stringify(jobs));

const addJob = (state: JobState, { payload }: { payload: Partial<Job> }) => {
  const { name, id } = payload;
  const job = {
    name,
    id,
    progress: 0,
    status: JobStatus.PENDING,
    dateTimeCreated: new Date(),
  };
  const jobsStorage = getJobsStorage();
  if (!jobsStorage) {
    setJobsStorage([job]);
  } else {
    const parsedJobs: Job[] = JSON.parse(jobsStorage);
    const currentCreateMembersJob = parsedJobs.find(
      (currentJob) => currentJob.name === name
    );

    if (!currentCreateMembersJob) {
      parsedJobs.push(job);
      setJobsStorage(parsedJobs);
    }
  }

  return {
    ...state,
    jobs: [...state.jobs, job],
  };
};

const addActiveJob = (state: JobState, { payload }: { payload: string }) => ({
  ...state,
  activeJobs: [...state.activeJobs, payload],
});

const removeActiveJob = (
  state: JobState,
  { payload }: { payload: string }
) => ({
  ...state,
  activeJobs: state.activeJobs.filter((activeJob) => activeJob !== payload),
});

const removeJob = (state: JobState, { payload }: { payload: string }) => {
  const jobsStorage = getJobsStorage();
  if (jobsStorage) {
    const parsedJobsStorage: Job[] = JSON.parse(jobsStorage);
    const filteredJobs = parsedJobsStorage.filter(
      (job) => job.name !== payload
    );
    setJobsStorage(filteredJobs);
  }

  return {
    ...state,
    jobs: state.jobs.filter((job) => job.name !== payload),
  };
};

const updateJob = (state: JobState, { payload }: { payload: Partial<Job> }) => {
  const { name } = payload;

  const jobIndex = state.jobs.findIndex((x) => x.name === name);
  const copyJobs = [...state.jobs];
  copyJobs[jobIndex] = payload as Job;
  const jobsStorage = getJobsStorage();

  if (payload.status === 'SUCCEEDED' || payload.status === 'FAILED') {
    if (jobsStorage) {
      const parsedJobsStorage: Job[] = JSON.parse(jobsStorage);
      const filteredJobs = parsedJobsStorage.filter((job) => job.name !== name);
      setJobsStorage(filteredJobs);
    }
  } else {
    setJobsStorage(copyJobs);
  }
  return {
    ...state,
    jobs: copyJobs,
  };
};

export const jobSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    addJob,
    addActiveJob,
    removeActiveJob,
    removeJob,
    updateJob,
  },
});

export const jobsActions = jobSlice.actions;
export const jobsReducer = jobSlice.reducer;
