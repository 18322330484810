import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Row, Col, Divider, Button, Spin } from 'antd';
import { Controller } from 'react-hook-form';
import Drawer from '../../../../../shared/components/Drawer/Drawer';
import { Flex } from '../../../../../shared/styles/Flex';
import { VendorVerticalListBox } from '../VendorVerticalListBox/VendorVerticalListBox';
import { AddVendorModal } from '../AddVendorModal/AddVendorModal';

export const NewVendorGroupDrawer = ({
  onNext,
  control,
  handleSubmit,
  vendors,
  setVendors,
  submitting,
}: Props) => {
  const [addVendorModal, setAddVendorModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const onSubmitData = () => {
    onNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitData)}>
      <Spin spinning={submitting}>
        <section>
          <Row className="mt-3">
            <Col span={10}>
              <div className="d-flex align-items-center">
                <span className="text-right mr-2 fs-14">
                  <FormattedMessage
                    id="VENDOR_GROUP_NAME"
                    defaultMessage="Vendor Group Name"
                  />
                </span>
                <Controller
                  name="vendorGroupName"
                  as={Input}
                  control={control}
                />
              </div>
            </Col>
            <Col span={14} />
          </Row>
          <Divider className="mb-0" />
          <Row>
            <Col span={24} className="mt-2">
              <Flex
                className="mb-2"
                alignItems="center"
                justifyContent="space-between"
              >
                <p style={{ marginLeft: '11px' }} className="fs-14 mb-0">
                  <FormattedMessage
                    id="vendors.text"
                    defaultMessage="Vendors"
                  />
                </p>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  className="mr-2"
                >
                  <Input
                    className="mr-3"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    prefix={<SearchOutlined />}
                    suffix={
                      <CloseOutlined
                        onClick={() => setSearchInput('')}
                        className="cursor-pointer"
                        style={{ opacity: '0.5' }}
                      />
                    }
                  />
                  <Button
                    type="primary"
                    onClick={() => setAddVendorModal(true)}
                  >
                    <PlusOutlined />
                    <FormattedMessage id="new.text" defaultMessage="New" />
                  </Button>
                </Flex>
              </Flex>

              <div
                className="pt-3 px-3"
                style={{
                  overflow: 'auto',
                  background: '#F0F2F5',
                  height: 'calc(100vh - 268px)',
                }}
              >
                <Flex>
                  {vendors.map((vendor) => (
                    <VendorVerticalListBox
                      searchInput={searchInput}
                      vendorName={vendor.label}
                      list={[]}
                      removeVendor={(vendorToBeRemoved: string) =>
                        setVendors((prev: any) =>
                          prev.filter((x: any) => x.label !== vendorToBeRemoved)
                        )
                      }
                    />
                  ))}
                </Flex>
              </div>
            </Col>
          </Row>
        </section>
      </Spin>
      <Drawer.Footer>
        <Row>
          <Col span={20}>
            <Flex justifyContent="flex-end" style={{ paddingRight: '10px' }}>
              <Button type="primary" htmlType="submit">
                <FormattedMessage id="next.text" defaultMessage="Next" />
              </Button>
            </Flex>
          </Col>
          <Col span={4} />
        </Row>
      </Drawer.Footer>

      <AddVendorModal
        existingVendors={vendors}
        openModal={addVendorModal}
        closeModal={() => setAddVendorModal(false)}
        onVendorSelect={(vendor) =>
          setVendors((prev: any) => [...prev, vendor])
        }
      />
    </form>
  );
};

type Props = {
  onNext: () => void;
  control: any;
  handleSubmit: any;
  vendors: any[];
  setVendors: (e: any) => any;
  submitting: boolean;
};
