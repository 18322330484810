import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { SharedStyledSelect } from 'styles/SharedStyledSelect';
import { StyledSider } from '../../styles';

const { Option } = Select;

const LeftSidebar = ({ sideBarStatus }: { sideBarStatus: boolean }) => {
  const translate = useTranslate();

  return (
    <StyledSider
      style={{ background: 'white' }}
      className="br-1"
      theme="light"
      collapsed={sideBarStatus}
      trigger={null}
      collapsible
      collapsedWidth={0}
      width={232}
    >
      <div className="p-3">
        <span style={{ fontSize: '16px' }}>
          <SearchOutlined className="mr-2" />
          <FormattedMessage id="search.text" defaultMessage="Search" />
        </span>

        <div className="mt-3">
          <div className="mb-1 fs-10 font-weight-bold">
            <FormattedMessage
              id="domain-name.text"
              defaultMessage="Domain Name"
            />
          </div>
          <SharedStyledSelect
            showSearch
            showArrow={false}
            placeholder={translate(messages['select.text'])}
          >
            <Option value="sample">{translate(messages['select.text'])}</Option>
          </SharedStyledSelect>
        </div>

        <div className="mt-3">
          <div className="mb-1 fs-10 font-weight-bold">
            <FormattedMessage
              id="ssl-certification.text"
              defaultMessage="SSL Certification"
            />
          </div>
          <SharedStyledSelect
            showSearch
            placeholder={translate(messages['select.text'])}
          >
            <Option value="sample">{translate(messages['select.text'])}</Option>
          </SharedStyledSelect>
        </div>

        <div className="mt-3">
          <div className="mb-1 fs-10 font-weight-bold">
            <FormattedMessage id="status.text" defaultMessage="Status" />
          </div>
          <SharedStyledSelect
            showSearch
            placeholder={translate(messages['select.text'])}
          >
            <Option value="sample">{translate(messages['select.text'])}</Option>
          </SharedStyledSelect>
        </div>
      </div>
    </StyledSider>
  );
};

export default LeftSidebar;
