import React from 'react';
import { isEmpty } from 'lodash';
import { Tag, Tooltip } from 'antd';
import { DynamicObj } from 'interfaces/user.interface';
import { StyledColor } from './styles';
import { StyledColorSquare } from '../../../styles';

type Props = {
  tags: DynamicObj[];
};

const MemberTags = ({ tags }: Props) => {
  const previewTags = tags
    .filter((item, idx) => !isEmpty(item) && idx + 1 <= 3)
    .reduce((prev: string[], curr) => [...prev, curr.color], []);
  const remainingTags = tags.length - 3;

  return (
    <span className="mr-2">
      <Tooltip
        placement="bottom"
        title={tags.map((tag) => (
          <Tag closable key={tag.id} className="mb-1">
            <StyledColorSquare color={tag.color} />
            <span>{tag.name}</span>
          </Tag>
        ))}
        getPopupContainer={() =>
          document.getElementById('missions-vip-group-container')!
        }
      >
        <div className="d-flex">
          <StyledColor color={previewTags[0]} />
          <StyledColor color={previewTags[1]} />
        </div>

        <div className="d-flex" style={{ marginTop: '3px' }}>
          <StyledColor color={previewTags[2]} />
          {tags.length - 3 > 0 ? (
            <small style={{ fontSize: '10px', lineHeight: '11px' }}>
              +{remainingTags}
            </small>
          ) : null}
        </div>
      </Tooltip>
    </span>
  );
};

export default MemberTags;
