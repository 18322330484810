import React, { useState } from 'react';
import { LayoutOutlined } from '@ant-design/icons';
import { Button, Table, Typography } from 'antd';
import styled from 'styled-components';
import Sidebar from './components/Sidebar/Sidebar';
import { StyledLayout, StyledContent, StyledContainer } from './styles';

import { data, columns } from './context';

const PayoutReport = () => {
  const [isSidebar, setSidebar] = useState(false);
  const handleSidebar = () => setSidebar(!isSidebar);
  return (
    <Root>
      <StyledLayout>
        <Sidebar />
        <StyledContent className="p-4">
          <div className="d-flex mb-6">
            <StyledContainer>
              <Button onClick={handleSidebar} size="small" className="mt-1">
                <LayoutOutlined />
              </Button>
              <Typography.Paragraph className="m-0 ml-3">
                {data.length} Results Found
              </Typography.Paragraph>
              <Typography.Paragraph className="m-0 ml-4">
                Search criteria:
              </Typography.Paragraph>
            </StyledContainer>
          </div>
          <div>
            <div className="agent-request-table">
              <Table
                rowKey="key"
                size="middle"
                dataSource={data}
                pagination={false}
              >
                {columns?.map((column: Record<string, any>) => {
                  const { subColumns, title, dataIndex, render } = column;
                  return subColumns && subColumns.length ? (
                    <Table.ColumnGroup title={title}>
                      {subColumns.map(
                        ({
                          title: subTitle,
                          dataIndex: subDataIndex,
                          render: subRender,
                        }: Record<string, any>) => (
                          <Table.Column
                            title={subTitle}
                            dataIndex={subDataIndex}
                            key={subDataIndex}
                            align="center"
                            render={subRender}
                          />
                        )
                      )}
                    </Table.ColumnGroup>
                  ) : (
                    <Table.Column
                      title={title}
                      dataIndex={dataIndex}
                      key={dataIndex}
                      align="center"
                      render={render}
                    />
                  );
                })}
              </Table>
            </div>
          </div>
        </StyledContent>
      </StyledLayout>
    </Root>
  );
};

const Root = styled.div`
  .agent-request-table {
    overflow: auto;
  }
`;

export default PayoutReport;
