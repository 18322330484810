import * as moment from 'moment-timezone';

export default (zoneName: string) => {
  const isBeijing = zoneName === 'Asia/Beijing';
  const offsetArg = isBeijing ? 'Asia/Shanghai' : zoneName;

  return `(GMT${moment
    .tz(offsetArg)
    .locale('zh-cn')
    .format('Z')}) ${zoneName}`;
};
