import React from 'react';
import { Dropdown, Menu, Checkbox, Avatar } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import { allGameTypes } from 'constants/gameTypes';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';

const GameCategoriesInput = () => {
  const { values, handleSubmit, setFieldValue } = useFormikContext() as any;
  const translate = useTranslate();
  const [isVisible, setIsVisible] = React.useState(false);

  const handleVisibleChange = (flag: boolean) => {
    if (flag === false) {
      handleSubmit();
    }
    setIsVisible(flag);
  };

  return (
    <>
      <Dropdown
        trigger={['hover', 'click']}
        placement="bottomCenter"
        visible={isVisible}
        onVisibleChange={handleVisibleChange}
        overlay={
          <Menu>
            {allGameTypes.map((gameType: string, index: number) => {
              const itemKey = `${index}_${gameType}`;
              return (
                <Menu.Item key={itemKey}>
                  <Checkbox
                    key={itemKey}
                    className="mr-3"
                    checked={values.gameTypes.includes(gameType)}
                    onChange={() => {
                      setIsVisible(true);
                      const currentGameTypes = [...values.gameTypes];
                      const hasSelectedGameType = currentGameTypes.includes(
                        gameType
                      );

                      if (hasSelectedGameType) {
                        setFieldValue(
                          'gameTypes',
                          currentGameTypes.filter((item) => item !== gameType)
                        );
                        return;
                      }
                      setFieldValue('gameTypes', [
                        ...currentGameTypes,
                        gameType,
                      ]);
                    }}
                  />
                  <span>{translate(messages[gameType.toUpperCase()])}</span>
                </Menu.Item>
              );
            })}
          </Menu>
        }
      >
        <ALink
          style={{
            color: '#000',
          }}
          onClick={() => setIsVisible(true)}
        >
          <div>
            <FormattedMessage
              id="game-categories.text"
              defaultMessage="Game Categories"
            />
          </div>
          <strong>
            {values.gameTypes.length === allGameTypes.length ? (
              <FormattedMessage id="ALL" defaultMessage="All" />
            ) : (
              <Avatar
                style={{
                  background: '#1890ff',
                  verticalAlign: 'middle',
                }}
              >
                {values.gameTypes.length}
              </Avatar>
            )}
          </strong>
        </ALink>
      </Dropdown>
    </>
  );
};

export default GameCategoriesInput;
