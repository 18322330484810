export const PAYMENT_TYPES = {
  OFFLINE: {
    BANK: 'OFFLINE_BANK_TRANSFER_WITHDRAWAL_METHOD',
    ALIPAY: 'ALIPAY_WITHDRAWAL_METHOD',
    WECHAT: 'WECHAT_WITHDRAWAL_METHOD',
  },
  ONLINE: {
    BANK: 'ONLINE_BANK_TRANSFER_WITHDRAWAL_METHOD',
    ALIPAY: 'ONLINE_ALIPAY_WITHDRAWAL_METHOD',
    WECHAT: 'ONLINE_WECHAT_WITHDRAWAL_METHOD',
  },
};

export default {};
