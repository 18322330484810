import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as BalanceRefreshIcon } from 'components/assets/balance-refresh.svg';
import { useConfig } from 'hooks/useConfig';
import { Vendor } from 'types/graphqlTypes';
import { ReactComponent as ErrorRefreshIcon } from './assets/baseline-refresh-24px.svg';
import './style.scss';
import { useLazyQueryPromise } from '../../../../../../../common/hooks/useLazyQueryPromise';
import { actions, useBalancesContext } from '../../../../context';

const MEMBER_VENDOR_BALANCE = gql`
  query MemberVendorBalance($id: ID!, $vendorId: ID!) {
    member(id: $id) {
      id
      vendorBalance(vendor: $vendorId)
    }
  }
`;

export const VendorData = ({
  vendor,
  memberId,
  onSearchMode = false,
}: {
  vendor: Vendor;
  memberId: string;
  onSearchMode?: boolean;
}) => {
  const [balance, setBalance] = useState(0);
  const { dispatch } = useBalancesContext();
  const [getVendorBalance, { error, loading }] = useLazyQueryPromise(
    MEMBER_VENDOR_BALANCE
  );
  const { addCurrency } = useConfig();
  const getBalance = async () => {
    const { data } = await (getVendorBalance as any)({
      id: memberId,
      vendorId: vendor.id,
    });
    if (data) {
      const dataBalance = get(data, 'member.vendorBalance', 0);
      setBalance(dataBalance);

      if (!onSearchMode) {
        dispatch([actions.SET_BALANCES, { [vendor.id]: dataBalance }]);
      }
    }
  };

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadImage = (shortcode: string) => {
    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`./assets/vendor_icons/${shortcode}.png`);
    } catch (e) {
      // console.log(`Vendor Icon Missing: ${shortcode}`);
    }

    return null;
  };
  return (
    <div
      className={`d-flex align-items-center b-1 position-relative ${!error &&
        !loading &&
        'vendor-wallet-balance-card'}`}
      style={{
        borderRadius: '4px',
        height: '40px',
        width: '134px',
        marginRight: '3px',
        marginTop: '4px',
      }}
    >
      <div style={{ padding: '4px 8px' }}>
        <img
          height="36"
          width="48"
          src={loadImage(vendor.shortcode)}
          alt={vendor.shortcode}
        />
      </div>
      <div
        style={{ paddingLeft: '4px', paddingRight: '4px' }}
        className="text-truncate"
      >
        <div className="fs-10 text-muted-light text-truncate">
          {vendor.name}
        </div>
        <div className="fs-14">
          {loading && !error && <LoadingOutlined />}
          {error && !loading && (
            <div className="d-flex">
              <span className="text-danger">
                <FormattedMessage id="error.text" defaultMessage="Error" />
              </span>
              <Icon
                className="fs-21"
                component={ErrorRefreshIcon}
                onClick={() => getBalance()}
              />
            </div>
          )}
          {!loading && !error && addCurrency(balance, 2, Math.floor)}
        </div>
      </div>

      <div
        className="d-flex align-items-center justify-content-center position-absolute vendor-wallet-balance-card__refresh-balance"
        style={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          background: 'rgba(24, 144, 255,.67)',
        }}
      >
        <Icon
          component={BalanceRefreshIcon}
          onClick={() => getBalance()}
          className="mr-1 cursor-pointer"
          style={{ fontSize: '25px' }}
        />
        <span className="text-white fs-13">
          <FormattedMessage
            id="balance-refresh.text"
            defaultMessage="Balance Refresh"
          />
        </span>
      </div>
    </div>
  );
};
