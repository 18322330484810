import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DownOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { SharedStyledModal } from 'styles/SharedStyledModal';
import { GrayBlocksProps, ImageGrayBlocksProps } from './types';

const SmallGrayBlock = styled.div`
  background: #e6e6e6;
  height: 10px;
  width: 35px;
  border-radius: 15px;
`;

const ImageGrayBlocks = styled.div<ImageGrayBlocksProps>`
  position: absolute;
  height: 25px;
  width: 45px;
  background: #e6e6e6;
  left: ${({ left }) => left}%;
  transform: translateX(-50%);
  bottom: 10px;
  background: #e6e6e6;
`;

const GrayBlock = styled.div<GrayBlocksProps>`
  background: ${({ bg }) => (bg === 'dark' ? '#d0d0d0' : '#e6e6e6')};
  height: ${({ height }) => height || '15px'};
  border-radius: 15px;
  width: ${({ width }) => width};
`;

const SmallBlockGroup = () => (
  <div className="mr-3 d-flex">
    <SmallGrayBlock className="mr-1" />
    <DownOutlined style={{ fontSize: '10px', color: '#d0d0d0' }} />
  </div>
);

type BannerProps = {
  landscapePreviewState: boolean;
  setLandscapePreviewState: (item: boolean) => void;
  selectedBanner: {
    imageDesktop: {
      uri: string;
    };
    imageMobile: {
      uri: string;
    };
    imageHorizontal: {
      uri: string;
    };
    name: string;
  };
};

export const LandscapePreviewModal = ({
  landscapePreviewState,
  setLandscapePreviewState,
  selectedBanner,
}: BannerProps) => (
  <SharedStyledModal
    title={
      <FormattedMessage
        id="desktop-preview.text"
        defaultMessage="Desktop Preview"
      />
    }
    visible={landscapePreviewState}
    onCancel={() => setLandscapePreviewState(false)}
    width={1000}
    footer={[
      <Button
        type="primary"
        onClick={() => setLandscapePreviewState(false)}
        key={1}
      >
        <FormattedMessage id="continue.text" defaultMessage="Continue" />
      </Button>,
    ]}
  >
    <div
      style={{
        height: '25px',
        width: '100%',
        background: '#e6e6e6',
      }}
      className="d-flex align-items-center px-5"
    >
      <div className="d-flex w-100">
        <GrayBlock width="35%" bg="dark" className="mr-2" />
        <GrayBlock width="20%" bg="dark" />
      </div>

      <div className="d-flex w-100 justify-content-flex-end">
        <GrayBlock width="35%" bg="dark" className="mr-2" />
        <GrayBlock width="35%" bg="dark" className="mr-2" />
        <GrayBlock width="20%" bg="dark" />
      </div>
    </div>

    <div className="mt-3 d-flex justify-content-center align-items-center">
      <SmallBlockGroup />
      <SmallBlockGroup />
      <SmallBlockGroup />
      <SmallBlockGroup />
      <SmallBlockGroup />

      <div
        className="mr-3"
        style={{
          borderRadius: '20px',
          color: '#1890ff',
          background: '#e6e6e6',
          fontSize: '20px',
          padding: '7px 20px',
        }}
      >
        LOGO
      </div>

      <SmallBlockGroup />
      <SmallBlockGroup />
      <SmallBlockGroup />
      <SmallBlockGroup />
      <SmallBlockGroup />
    </div>

    <div className="d-flex justify-content-space-between mt-1 align-items-center">
      <div
        style={{
          borderRadius: '0 10px 10px 0',
          height: '225px',
          width: '75px',
          background: '#e6e6e6',
        }}
      />
      <div className="px-3 position-relative">
        <img
          src={selectedBanner.imageHorizontal.uri}
          alt={selectedBanner.name}
          style={{
            width: '800px',
            borderRadius: '10px',
          }}
          height={250}
        />
        <ImageGrayBlocks left={38} />
        <ImageGrayBlocks left={44} />
        <ImageGrayBlocks left={50} />
        <ImageGrayBlocks left={56} />
      </div>

      <div
        style={{
          borderRadius: '10px 0 0 10px',
          height: '225px',
          width: '75px',
          background: '#e6e6e6',
        }}
      />
    </div>

    <div className="my-2 d-flex justify-content-center align-items-center">
      <GrayBlock width="2%" className="mr-2" />
      <GrayBlock width="4%" className="mr-5" />
      <GrayBlock width="45%" />
    </div>
  </SharedStyledModal>
);
