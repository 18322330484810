import React from 'react';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import messages from 'messages';
import SavedFilterSelect from 'components/SavedFilterSelect';
import { useFilterValues } from 'contexts/Filters';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import useTranslate from 'utils/useTranslate';
import FilterItems from '../FilterItems';

const Sidebar = (props: { savedFilterKey: number }) => {
  const { savedFilterKey } = props;
  const translate = useTranslate();

  const {
    savedFilterId,
    handleSavedFilterIdChange,
    handleFilterChange,
    filters,
  } = useFilterValues();

  return (
    <Form layout="vertical">
      <FilterItem label={translate(messages.QUICK_FILTER)}>
        <SavedFilterSelect
          placeHolder={translate(messages.SELECT_SAVED_CUSTOM_FILTER)}
          value={savedFilterId}
          onChange={(val) => handleSavedFilterIdChange(val)}
          context={QuickSearchIds.THIRD_PARTY_DEPOSIT_PROVIDER as any}
          key={savedFilterKey}
        />
      </FilterItem>
      <FilterItems filters={filters} onRawFilterChange={handleFilterChange} />
    </Form>
  );
};

export default Sidebar;
