/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { CustomFilter } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { StyledModal } from './styles';
import { DELETE_CUSTOM_FILTER } from './mutations';
import { CUSTOM_FILTERS } from '../SearchSettingsList/queries';

type CustomFilterType = CustomFilter & { isShow: boolean };

type Props = {
  searchSettings: CustomFilterType;
  tabContext: string;
};

const DeleteSettings: React.FC<Props> = ({ searchSettings, tabContext }) => {
  const translate = useTranslate();
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);

  const [deleteCustomFilter] = useMutation(DELETE_CUSTOM_FILTER);

  const showModal = () => setShow(!isShow);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      await deleteCustomFilter({
        variables: {
          id: searchSettings.id,
        },
        refetchQueries: [
          {
            query: CUSTOM_FILTERS,
            variables: {
              filter: {
                context: {
                  eq: tabContext,
                },
              },
            },
          },
        ],
      });
      message.success(translate(messages['search-settings-deleted.text']));
      setLoading(false);
      showModal();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <a href="#/section" onClick={showModal}>
        <FormattedMessage id="delete.text" defaultMessage="Delete" />
      </a>
      {isShow && (
        <StyledModal
          title={
            <FormattedMessage
              id="delete-settings.text"
              defaultMessage="Delete settings"
            />
          }
          visible
          onOk={showModal}
          onCancel={showModal}
          footer={null}
        >
          <div className="p-6">
            <FormattedMessage
              id="are-you-sure-you-want-to-delete.text"
              defaultMessage="Are you sure you want to delete?"
            />
          </div>
          <div className="bt-1 py-4 px-6 text-right">
            <Button className="mr-2" onClick={showModal} loading={isLoading}>
              <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
            </Button>{' '}
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              onClick={handleSubmit}
            >
              <FormattedMessage
                id="save-changes.text"
                defaultMessage="Save changes"
              />
            </Button>
          </div>
        </StyledModal>
      )}
    </>
  );
};

export default DeleteSettings;
