import React from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, Menu, Spin, Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { ALink } from 'components/ALink/ALink';
import { withErrorHandler } from 'components/ErrorHandler';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { STATUS_COLORS } from 'constants/colors';
import { useFilterValues } from 'contexts/Filters';
import {
  allTypes as memberLoyaltyTypes,
  useMemberLoyaltyProgrammeContext,
} from 'pages/components/MemberLoyaltyManagement/context';
import {
  allTypes as createProgramTypes,
  ProgrammeStatus,
  useCreateLoyaltyProgramContext,
} from 'pages/components/MemberLoyaltyManagement/components/CreateProgramme/context';
import { StyledTable, StyledCenter } from 'styles';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import { showPermissionError } from 'components/Navbar/Navbar';

import { ApolloError } from 'apollo-client';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import moment from 'moment';
import { MemberLoyaltyProgramme } from 'types/graphqlTypes';
import { columnAlign } from 'utils/tableAlignment';
import { DATE_FORMAT } from 'constants/date';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import localMessages from './messages';
import DeleteLoyaltyProgram from '../../../DeleteProgramme';
import DuplicateLoyaltyProgram from '../../../DuplicateProgramme';
import DeactivateProgram from './components/DeactivateProgram';
import ActivateProgram from './components/ActivateProgram';
import MemberCount from './components/MemberCount';
import { getEffectiveDateRange } from './utils';

const JsDeactivateProgram: any = DeactivateProgram;
const JsActivateProgram: any = ActivateProgram;

type Props = {
  data: any;
  error: ApolloError | undefined;
  loading: boolean;
  toggleView: () => void;
  refetchVariables: Record<string, any>;
};

const VIPTable = (props: Props) => {
  const { role, permissions } = usePermissions();
  const {
    ALLOWED_LIST,
    ALLOWED_VIEW_DETAILS,
    ALLOWED_EDIT,
    ALLOWED_DUPLICATE,
    ALLOWED_DELETE,
    ALLOWED_TOGGLE_ENABLED,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'VIEW_DETAILS', 'EDIT', 'DUPLICATE', 'DELETE', 'TOGGLE_ENABLED'],
    ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  );

  const { setTableColumns } = useSpreadsheetContext();

  const { data, error, loading, toggleView, refetchVariables } = props;
  const { filters } = useFilterValues() as any;
  const intl = useIntl();
  const [, createProgrammeDispatch] = useCreateLoyaltyProgramContext() as any;
  const [, memberLoyaltyDispatch] = useMemberLoyaltyProgrammeContext();

  const statusType = {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
  };

  const handleToggleDrawer = React.useCallback(
    (node) => () => {
      createProgrammeDispatch({
        type: createProgramTypes.SET_CURRENT_PROGRAM_ID,
        payload: node.id,
      });

      toggleView();
      createProgrammeDispatch({
        type: createProgramTypes.SET_ACTIVE_SCREEN,
        payload: createProgramTypes.PROGRAM_SETTINGS,
      });
    },
    [createProgrammeDispatch, toggleView]
  );

  const translate = useTranslate();

  const impartResponsiveWidth = React.useCallback(
    (value) => ({ width: value } || {}),
    []
  );

  const getColumns = React.useCallback(
    () => [
      {
        csvData: {
          label: translate(messages.SERIAL_CODE),
          key: 'serialCode',
          renderCell: (node: MemberLoyaltyProgramme) =>
            coercedGet(node, 'serialCode', '-'),
        },
        key: 'serialCode',
        title: translate(messages.ID),
        align: columnAlign.onCenter,
        dataIndex: 'serialCode',
      },
      {
        csvData: {
          label: translate(messages.VIP_PROGRAMME_NAME),
          key: 'name',
          renderCell: (node: MemberLoyaltyProgramme) =>
            coercedGet(node, 'name', '-'),
        },
        key: 'programName',
        ...impartResponsiveWidth(200),
        title: translate(messages.VIP_PROGRAMME_NAME),
        align: columnAlign.onCenter,
        render: (node: any) => (
          <div
            className={cx({
              'd-flex': node.status === statusType.DRAFT && ALLOWED_EDIT,
            })}
          >
            <ALink
              className="mr-2"
              onClick={(e: any) => {
                e.preventDefault();
                if (node.status === ProgrammeStatus.DRAFT) {
                  if (!ALLOWED_VIEW_DETAILS) {
                    showPermissionError('VIP - View Details');
                    return;
                  }
                  handleToggleDrawer(node)();
                } else {
                  if (!ALLOWED_EDIT) {
                    showPermissionError('VIP - Edit');
                    return;
                  }
                  createProgrammeDispatch({
                    type: createProgramTypes.SET_CURRENT_PROGRAM_ID,
                    payload: node.id,
                  });

                  memberLoyaltyDispatch({
                    type: memberLoyaltyTypes.SET_ACTIVE_SCREEN,
                    payload: memberLoyaltyTypes.PROGRAMME_DETAILS,
                  });
                }
              }}
            >
              {node.name}
            </ALink>
            {node.status === statusType.DRAFT && ALLOWED_EDIT && (
              <Button
                type="primary"
                shape="round"
                ghost
                size="small"
                onClick={handleToggleDrawer(node)}
              >
                <FormattedMessage
                  id="member-loyalty.continue.text"
                  defaultMessage="Continue"
                />
              </Button>
            )}
          </div>
        ),
      },
      {
        csvData: {
          label: translate(messages.PRIMARY),
          key: 'primary',
          renderCell: (node: any) =>
            node.primary ? translate(messages.PRIMARY) : '-',
        },
        key: 'primary',
        ...impartResponsiveWidth(140),
        title: translate(messages.PRIMARY),
        align: columnAlign.onCenter,
        render: (node: any) =>
          node.primary ? <Tag>{translate(messages.PRIMARY)}</Tag> : '',
      },
      {
        csvData: {
          label: translate(messages.MEMBERS),
          key: 'membersCount',
          renderCell: (node: MemberLoyaltyProgramme) =>
            node && coercedGet(node, 'membersCount', '-'),
        },
        key: 'members',
        ...impartResponsiveWidth(150),
        title: translate(messages.MEMBERS),
        align: columnAlign.onCenter,
        render: (node: any) => <MemberCount node={node} />,
      },
      {
        csvData: {
          label: translate(messages.EFFECTIVE_DATE_RANGE),
          key: 'effectiveDateRange',
          renderCell: (node: MemberLoyaltyProgramme) =>
            `${coercedGet(node, 'validityStartDate', '-')} - ${coercedGet(
              node,
              'validityEndDate',
              '-'
            )}`,
        },
        key: 'effectiveDateRange',
        ...impartResponsiveWidth(200),
        title: translate(messages.EFFECTIVE_DATE_RANGE),
        align: columnAlign.onCenter,
        render: (node: any) => {
          const primary = node.primary
            ? '-'
            : coercedGet(node, 'effectiveDateRange', '-');
          const startDate = primary?.substring(0, 10);
          const endDate = primary?.substring(13, 23);
          return (
            <p className="mb-0">
              {!node.primary && moment(startDate).format(DATE_FORMAT)} -{' '}
              {!node.primary && moment(endDate).format(DATE_FORMAT)}
            </p>
          );
        },
      },
      {
        csvData: {
          label: translate(messages.STATUS),
          key: 'status',
          renderCell: (node: MemberLoyaltyProgramme) =>
            coercedGet(node, 'status', '-'),
        },
        key: 'status',
        title: translate(messages.status),
        align: columnAlign.onCenter,
        dataIndex: 'status',

        render: (text: string) => (
          <Badge
            text={translate(
              localMessages[`member-loyalty.${text.toLowerCase()}.text`]
            )}
            status={STATUS_COLORS[text.toUpperCase()]}
          />
        ),
      },
      {
        csvData: {
          label: translate(messages.TIERS),
          key: 'levels',
          renderCell: (node: MemberLoyaltyProgramme) =>
            coercedGet(node, 'levels.length', '-'),
        },
        key: 'tiers',
        title: translate(messages.TIERS),
        dataIndex: 'levels',
        align: columnAlign.onCenter,
        render: (levels: any) => levels.length,
      },
      {
        csvData: null,
        key: 'actions',
        align: columnAlign.onCenter,
        title: translate(messages['actions.text']),

        render: (node: any) => {
          const renderDropDown = () => {
            const detShouldRender = (addedCond: any) =>
              Boolean(addedCond) && !coercedGet(node, 'primary', null);
            return (
              <Dropdown
                overlay={
                  <Menu className="p-2">
                    {ALLOWED_EDIT && (
                      <Menu.Item
                        className="py-1"
                        onClick={handleToggleDrawer(node)}
                      >
                        <Button type="link" className="text-black" block>
                          <FormattedMessage
                            id="edit.text"
                            defaultMessage="Edit"
                          />
                        </Button>
                      </Menu.Item>
                    )}
                    {detShouldRender(ALLOWED_DUPLICATE) && (
                      <Menu.Item className="py-1">
                        <DuplicateLoyaltyProgram programId={node.id} />
                      </Menu.Item>
                    )}
                    {detShouldRender(ALLOWED_DELETE) && (
                      <Menu.Item
                        className="py-1"
                        style={{
                          borderBottom:
                            node.status === statusType.INACTIVE ||
                            node.status === statusType.ACTIVE
                              ? '1px solid rgba(0, 0, 0, 0.35)'
                              : 'none',
                        }}
                      >
                        <DeleteLoyaltyProgram
                          programId={node.id}
                          refetchVariables={refetchVariables}
                        />
                      </Menu.Item>
                    )}{' '}
                    {detShouldRender(
                      node.status === statusType.INACTIVE ||
                        node.status === statusType.ACTIVE
                    ) &&
                      ALLOWED_TOGGLE_ENABLED && (
                        <div className="py-1 styled-center">
                          <FormattedMessage
                            id="member-loyalty.change-status.text"
                            defaultMessage="Change Status"
                          />
                        </div>
                      )}
                    {detShouldRender(node.status === statusType.ACTIVE) &&
                      ALLOWED_TOGGLE_ENABLED && (
                        <Menu.Item className="py-1">
                          <JsDeactivateProgram programId={node.id} />
                        </Menu.Item>
                      )}
                    {detShouldRender(node.status === statusType.INACTIVE) &&
                      ALLOWED_TOGGLE_ENABLED && (
                        <Menu.Item className="py-1">
                          <JsActivateProgram programId={node.id} />
                        </Menu.Item>
                      )}
                    {node.status === statusType.DRAFT && (
                      <>
                        {ALLOWED_TOGGLE_ENABLED && (
                          <>
                            <div
                              className="py-1 styled-center"
                              style={{
                                borderTop: '1px solid rgba(0, 0, 0, 0.35)',
                              }}
                            >
                              <FormattedMessage
                                id="member-loyalty.change-status.text"
                                defaultMessage="Change Status"
                              />
                            </div>
                            <Menu.Item className="py-1">
                              <JsActivateProgram isDraft programId={node.id} />
                            </Menu.Item>
                            <Menu.Item className="py-1">
                              <JsDeactivateProgram programId={node.id} />
                            </Menu.Item>
                          </>
                        )}
                      </>
                    )}
                  </Menu>
                }
              >
                <Button type="link" icon={<EllipsisOutlined />} />
              </Dropdown>
            );
          };

          return (
            (ALLOWED_EDIT ||
              ALLOWED_DUPLICATE ||
              ALLOWED_DELETE ||
              ALLOWED_TOGGLE_ENABLED) &&
            renderDropDown()
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createProgrammeDispatch,
      handleToggleDrawer,
      intl,
      memberLoyaltyDispatch,
      refetchVariables,
    ]
  );

  const columnFields = React.useMemo(() => getColumns(), [getColumns]);

  const {
    loading: loadingCC,
    filterColumns,
    customColumns,
  } = useCustomColumnsV2('vip', columnFields);

  React.useEffect(() => {
    if (!loading) {
      setTableColumns(filterColumns(columnFields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCC, filters, loading]);

  if (isEmpty(customColumns)) {
    return (
      <StyledCenter data-testid="loading">
        <Spin />
      </StyledCenter>
    );
  }

  const tableData = data.map(({ node }: any) => ({
    ...node,
    key: node.id,
    effectiveDateRange: getEffectiveDateRange(
      node.validityStartDate,
      node.validityEndDate
    ),
  }));

  return (
    <>
      {ALLOWED_LIST && (
        <StyledTable
          loading={loading || loadingCC}
          data-testid={!error ? 'table' : 'error'}
          columns={filterColumns(columnFields)}
          dataSource={tableData}
          pagination={false}
        />
      )}
    </>
  );
};

export default withErrorHandler(VIPTable) as React.ComponentType<Props>;
