import React, { useEffect } from 'react';
import generateId from 'utils/generateId';
import { TIER_REQUIREMENT } from '../../../../../../constants';
import { isNotEmpty } from './components/ConditionalInput/ConditionalInput';
import TierTable from './components/TierTable';

const TierSettings = ({ formProps }: { formProps: Record<string, any> }) => {
  const { values, setFieldValue } = formProps;

  const tierRequirementLookup =
    values.tierRequirement.length === 2
      ? values.tierRequirementOperator
      : values.tierRequirement[0];

  useEffect(() => {
    const newLevels = values.levels.map((level: Record<string, any>) => {
      if (
        (level.completedAt === TIER_REQUIREMENT.ACTIVE_MEMBERS ||
          level.completedAt === TIER_REQUIREMENT.NET_PROFIT) &&
        level.completedAt !== tierRequirementLookup
      ) {
        if (
          level.completedAt === TIER_REQUIREMENT.ACTIVE_MEMBERS &&
          (tierRequirementLookup === TIER_REQUIREMENT.BOTH ||
            tierRequirementLookup === TIER_REQUIREMENT.EITHER)
        ) {
          return {
            ...level,
            complete: false,
            minimumNetProfit: '',
            maximumNetProfit: '',
          };
        }
        if (
          level.completedAt === TIER_REQUIREMENT.NET_PROFIT &&
          (tierRequirementLookup === TIER_REQUIREMENT.BOTH ||
            tierRequirementLookup === TIER_REQUIREMENT.EITHER)
        ) {
          return {
            ...level,
            complete: false,
            minimumActiveMembersCount: '',
            maximumActiveMembers: '',
          };
        }
        return {
          ...level,
          complete: false,
          minimumNetProfit: '',
          maximumNetProfit: '',
          minimumActiveMembersCount: '',
          maximumActiveMembers: '',
        };
      }
      if (
        (level.completedAt === TIER_REQUIREMENT.BOTH ||
          level.completedAt === TIER_REQUIREMENT.EITHER) &&
        level.completedAt !== tierRequirementLookup
      ) {
        if (
          tierRequirementLookup === TIER_REQUIREMENT.ACTIVE_MEMBERS &&
          (level.completedAt === TIER_REQUIREMENT.BOTH ||
            level.completedAt === TIER_REQUIREMENT.EITHER)
        ) {
          return {
            ...level,
            minimumNetProfit: '',
            maximumNetProfit: '',
            complete: isNotEmpty(level, tierRequirementLookup),
          };
        }
        if (
          tierRequirementLookup === TIER_REQUIREMENT.NET_PROFIT &&
          (level.completedAt === TIER_REQUIREMENT.BOTH ||
            level.completedAt === TIER_REQUIREMENT.EITHER)
        ) {
          return {
            ...level,
            minimumActiveMembersCount: '',
            maximumActiveMembers: '',
            complete: isNotEmpty(level, tierRequirementLookup),
          };
        }
      }
      return {
        ...level,
        complete: isNotEmpty(level, tierRequirementLookup),
      };
    });
    setFieldValue('levels', newLevels);
    // eslint-disable-next-line
  }, [setFieldValue, tierRequirementLookup]);

  const defaultLevelValue = {
    key: generateId('aap'),
    complete: false,
    completedAt: '',
    name: '',
    minimumActiveMembersCount: 1,
    maximumActiveMembers: '',
    minimumNetProfit: 1,
    maximumNetProfit: '',
    percentage: '',
  };

  return (
    <TierTable
      formProps={formProps}
      defaultLevelValue={defaultLevelValue}
      tierRequirementLookup={tierRequirementLookup}
    />
  );
};

export default TierSettings;
