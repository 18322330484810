import { useQuery } from '@apollo/react-hooks';
import { ErrorHandler } from 'components/ErrorHandler';
import Layout from 'components/Layout';
import { TabId } from 'constants/route';
import { useFilterValues } from 'contexts/Filters';
import { MEMBER_BALANCE_UNIT_RECORDS } from 'graphql/queries/balanceServiceRecord.query';
import React, { useEffect, useState } from 'react';
import { ReactComponent as SearchUserIcon } from 'components/assets/search-user.svg';
import SharedStyledContent from 'styles/SharedStyledContent';
import { SharedStyledLayout } from 'styles/SharedStyledLayout';
import { useScreenTabV2 } from 'store/screenTabState';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { sortTransform } from 'utils/partialUtils';
import { getPollInterval } from 'utils/pollInterval';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import messages from '../../messages';
import { StyledBlankContainer, StyledSearchButton } from '../../styles';
import BalanceServiceRecordsTable from '../BalanceServiceRecordsTable';
import HeaderLeft from '../HeaderLeft';
import HeaderRight from '../HeaderRight';
import SearchMember from '../SearchMember';
import SideBar from '../Sidebar';

const BalanceServiceRecordsScreen = ({
  defaultMemberInfo,
  filter,
  refreshPage,
}: {
  defaultMemberInfo?: Record<string, any>;
  filter?: Record<string, any>;
  refreshPage: () => void;
}) => {
  const { isTabActive, updateTab } = useScreenTabV2();
  const [memberState, setMemberState] = useState<any>(defaultMemberInfo);
  const [showSearchMember, setShowSearchMember] = useState(false);
  const toggleSearchMember = () => setShowSearchMember((prev) => !prev);

  useEffect(() => {
    updateTab({
      id: TabId.BALANCE_SERVICE_RECORD_REPORT as any,
      updates: {
        memberRef: memberState,
        filter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberState]);

  useEffect(() => {
    updateTab({
      id: TabId.BALANCE_SERVICE_RECORD_REPORT as any,
      updates: {
        memberRef: defaultMemberInfo,
        filter,
      },
    });
    setMemberState(defaultMemberInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultMemberInfo]);

  const [page, setPage] = useState<any>({
    first: 10,
    after: undefined,
    savedCursor: [''],
    currentPage: 0,
    sort: 'descend',
  });

  const [sidebar, toggleSidebar] = useState<boolean>(true);

  const handleToggleSidebar = () => {
    toggleSidebar((prev) => !prev);
  };

  const { filters, handleFilterChange } = useFilterValues();
  const { member } = filters;
  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: transformMovableDateFilter(filters),
    sort: {
      direction: sortTransform[page.sort],
    },
  };
  const { context, setOnPoll } = useOperatorHeader();
  const { loading, data = {} } = useQuery(MEMBER_BALANCE_UNIT_RECORDS, {
    fetchPolicy: 'network-only',
    variables: refetchVariables,
    pollInterval: getPollInterval({
      isActive:
        isTabActive(TabId.BALANCE_SERVICE_RECORD_REPORT as any) && !!member,
      isRealtime: true,
    }),
    skip: !member,
    context,
    onCompleted: () => {
      setOnPoll(true);
    },
  });

  const { memberBalanceUnitRecords = {} }: any = data;
  const { totalCount, pageInfo, edges = [] } = memberBalanceUnitRecords;
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const handleSort = (order: 'ascend' | 'descend') => {
    setPage((prev: Record<string, any>) => ({
      ...prev,
      sort: order,
    }));
  };

  const { resetGeneratedData } = useSpreadsheetContext();

  const setDefaultMember = (memberInfo: { id: string }) => {
    const { id } = memberInfo;

    handleFilterChange({
      name: 'member',
      value: id,
    });

    setShowSearchMember(false);
    setMemberState(memberInfo);
    resetGeneratedData();
  };
  const translate = useTranslate();
  return (
    <>
      <SearchMember
        setDefaultMember={setDefaultMember}
        visible={showSearchMember}
        toggleModal={toggleSearchMember}
      />
      {memberState.id ? (
        <Layout.Container>
          <>
            <Layout.Header
              leftNode={
                <HeaderLeft
                  sidebarStatus={sidebar}
                  toggleSidebar={handleToggleSidebar}
                />
              }
              rightNode={
                <HeaderRight
                  refreshPage={refreshPage}
                  memberInfo={memberState}
                  toggleSearchMember={toggleSearchMember}
                  filters={filters}
                />
              }
            />
            <Layout.Content
              key={memberState.id || 0}
              sideBar={<SideBar open={sidebar} />}
              footer={
                <Layout.Footer
                  leftNode={<div />}
                  loading={loading}
                  onNext={handleNext}
                  onPrev={handlePrev}
                  page={page}
                  resultsCount={totalCount}
                  totalPage={totalPage}
                  setPage={setPage}
                  pageInfo={pageInfo}
                />
              }
            >
              <ErrorHandler>
                <BalanceServiceRecordsTable
                  handleSort={handleSort}
                  dataSource={edges}
                  loading={loading}
                  refetchVariables={refetchVariables}
                />
              </ErrorHandler>
            </Layout.Content>
          </>
        </Layout.Container>
      ) : (
        <SharedStyledLayout style={{ height: 'calc(100vh - 40px - 45px)' }}>
          <SharedStyledContent style={{ height: 'fit-content' }}>
            <StyledBlankContainer>
              <SearchUserIcon />
              <StyledSearchButton onClick={toggleSearchMember} type="primary">
                {translate(messages.SEARCH_USER)}
              </StyledSearchButton>
            </StyledBlankContainer>
          </SharedStyledContent>
        </SharedStyledLayout>
      )}
    </>
  );
};

export default BalanceServiceRecordsScreen;
