import { defineMessages } from 'react-intl';

const messages = defineMessages({
  depositRequestsInternal: {
    id: 'deposit-requests-internal.text',
    defaultMessage: 'Deposit Requests(Internal)',
  },
  vipLevel: {
    id: 'vip-level.text',
    defaultMessage: 'VIP level',
  },
  clearAll: {
    id: 'clear-all.text',
    defaultMessage: 'Clear all',
  },
  enterVipLevel: {
    id: 'enter-vip-level.text',
    defaultMessage: 'Enter VIP Level',
  },
  memberMarker: {
    id: 'member-marker.text',
    defaultMessage: 'Member Marker',
  },
  enterMemberMarker: {
    id: 'enter-member-marker.text',
    defaultMessage: 'Enter Member Marker',
  },
  bankDeposit: {
    id: 'bank-deposit.text',
    defaultMessage: 'Bank Deposit',
  },
  amount: {
    id: 'amount.text',
    defaultMessage: 'Amount',
  },
  status: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  operator: {
    id: 'operator.text',
    defaultMessage: 'Operator',
  },
  dateTimeProcessed: {
    id: 'date-time-processed.text',
    defaultMessage: 'Date Time Processed',
  },
  processingTime: {
    id: 'processing-time.text',
    defaultMessage: 'Processing Time',
  },
  operatorRemark: {
    id: 'operator-remark.text',
    defaultMessage: 'Operator Remark',
  },
  requestUpdate: {
    id: 'request-update.text',
    defaultMessage: 'Request Update',
  },
  OFFLINE_BANK_TRANSFER: {
    id: 'bank-transfer.text',
    defaultMessage: 'Bank transfer',
  },
  ALIPAY: {
    id: 'alipay.text',
    defaultMessage: 'Alipay',
  },
  WECHAT: {
    id: 'wechat.text',
    defaultMessage: 'WeChat',
  },
  agentAffiliate: {
    id: 'agent-affiliate.text',
    defaultMessage: 'Agent affiliate',
  },
});

export default messages;
