import { DownOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import messages from 'messages';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import SharedEditSearchSettings from 'components/EditSearchSettings';
import styled from 'styled-components';
import { CustomFiltersConnectionEdge } from 'types/graphqlTypes';
import { GET_CUSTOM_FILTERS } from 'graphql/queries/customFilter.query';
import useTranslate from 'utils/useTranslate';
import DeleteCustomFilter from 'components/DeleteCustomFilter';

const Container = styled.div`
  .row-item {
    display: flex;
    justify-content: space-between;
  }
`;

type Rec = Record<string, any>;
type Props = {
  onUseFilter: Function;
  contextKey: string;
  EditSearchSettingsNode: any;
  setLocalQuickFilter: Dispatch<SetStateAction<string>>;
  onFiltersUpdate: Function;
  isNext?: boolean;
  quickSearchId?: string;
  SearchSettingsFormNode: ReactNode;
};

type CustomFilter = {
  id: string;
  name: string;
  context: string;
  isShow: boolean;
  filter: Record<string, any>;
};

const CustomFilterList = ({
  onUseFilter,
  contextKey,
  EditSearchSettingsNode,
  setLocalQuickFilter,
  onFiltersUpdate,
  isNext,
  quickSearchId,
  SearchSettingsFormNode,
}: Props) => {
  const translate = useTranslate();
  const [customFilterList, setCustomFilterList] = useState<CustomFilter[]>([]);
  const { loading, data, refetch } = useQuery(GET_CUSTOM_FILTERS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        context: {
          eq: contextKey,
        },
      },
    },
  });

  useEffect(() => {
    if (data) {
      const { customFilters = {} } = data;
      const { edges = [] } = customFilters;
      const newData = edges.map(
        ({ node: nodeData }: CustomFiltersConnectionEdge) => ({
          ...nodeData,
          isShow: false,
        })
      );
      setCustomFilterList(newData);
    }
  }, [data]);

  if (loading)
    return (
      <div className="p-6">
        <FormattedMessage id="loading.text" defaultMessage="Loading..." />
      </div>
    );

  const handleChildren = (id: string) => {
    const newCustomFilterList = customFilterList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isShow: !item.isShow,
        };
      }
      return {
        ...item,
        isShow: false,
      };
    });

    setCustomFilterList(newCustomFilterList);
  };

  const handleUseSearchSettings = (e: Rec) => {
    onUseFilter(e.filter);
  };

  return (
    <Container>
      {customFilterList.length ? (
        customFilterList.map((item) => (
          <div key={item.id} className="bb-1 pt-3">
            <div className="pb-3 d-flex justify-content-space-between">
              <span>
                <ALink
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    handleUseSearchSettings(item);
                    setLocalQuickFilter(item.name);
                  }}
                >
                  {item.name}
                </ALink>
              </span>

              <span className="d-flex">
                <ALink
                  href="#/"
                  className="mr-2"
                  onClick={(e: { preventDefault: () => void }) => {
                    e.preventDefault();
                    handleChildren(item.id);
                  }}
                >
                  <FormattedMessage id="modify.text" defaultMessage="Modify" />
                  <DownOutlined
                    style={{ fontSize: '10px', paddingLeft: '4px' }}
                  />
                </ALink>

                <DeleteCustomFilter
                  onFiltersUpdate={onFiltersUpdate}
                  searchSettings={item}
                  contextKey={contextKey}
                />
              </span>
            </div>
            {!isNext && item.isShow ? (
              <EditSearchSettingsNode
                refetch={refetch}
                searchSettings={item}
                onClose={() => handleChildren(item.id)}
              />
            ) : null}

            {isNext && item.isShow ? (
              <SharedEditSearchSettings
                refetch={refetch}
                searchSettings={item}
                SearchSettingsFormNode={SearchSettingsFormNode}
                quickSearchId={quickSearchId}
                onClose={() => handleChildren(item.id)}
              />
            ) : null}
          </div>
        ))
      ) : (
        <div className="py-3">{translate(messages.NO_DATA)}</div>
      )}
    </Container>
  );
};

export default CustomFilterList;
