export const PAYMENT_TYPES = {
  OFFLINE: {
    BANK: 'OFFLINE_BANK_TRANSFER',
    ALIPAY: 'ALIPAY',
    WECHAT: 'WECHAT',
    HEXOPAY: 'HEXOPAY',
    NETELLER: 'NETELLER',
    SKRILL: 'SKRILL',
    ASTROPAYWALLET: 'ASTROPAYWALLET',
    CRYPTOCURRENCY: 'CRYPTOCURRENCY',
    BITCOIN: 'BITCOIN',
    MANUAL: 'MANUAL_ADJUSTMENT',
    BANK_WITHDRAW: 'OFFLINE_BANK_TRANSFER_WITHDRAWAL_METHOD',
    ALIPAY_WITHDRAW: 'ALIPAY_WITHDRAWAL_METHOD',
    WECHAT_WITHDRAW: 'WECHAT_WITHDRAWAL_METHOD',
    MANUAL_WITHDRAW: 'MANUAL_ADJUSTMENT_WITHDRAWAL_METHOD',
    HEXOPAY_WITHDRAW: 'HEXOPAY_WITHDRAWAL_METHOD',
    NETELLER_WITHDRAW: 'NETELLER_WITHDRAWAL_METHOD',
    SKRILL_WITHDRAW: 'SKRILL_WITHDRAWAL_METHOD',
    PAYBYASTROPAY: 'PAYBYASTROPAY',
  },
  ONLINE: {
    BANK: 'ONLINE_BANK_TRANSFER',
    ALIPAY: 'ONLINE_ALIPAY',
    WECHAT: 'ONLINE_WECHAT',
    BANK_WITHDRAW: 'ONLINE_BANK_TRANSFER_WITHDRAWAL_METHOD',
    ALIPAY_WITHDRAW: 'ONLINE_ALIPAY_WITHDRAWAL_METHOD',
    WECHAT_WITHDRAW: 'ONLINE_WECHAT_WITHDRAWAL_METHOD',
    UNION_PAY: 'UNIONPAY',
  },
};

export const PAYMENT_TYPES_WITHDRAWAL = {
  OFFLINE: [
    PAYMENT_TYPES.OFFLINE.ALIPAY_WITHDRAW,
    PAYMENT_TYPES.OFFLINE.BANK_WITHDRAW,
    PAYMENT_TYPES.OFFLINE.WECHAT_WITHDRAW,
    PAYMENT_TYPES.OFFLINE.MANUAL_WITHDRAW,
    PAYMENT_TYPES.OFFLINE.HEXOPAY_WITHDRAW,
    PAYMENT_TYPES.OFFLINE.NETELLER_WITHDRAW,
    PAYMENT_TYPES.OFFLINE.SKRILL_WITHDRAW,
  ],
  ONLINE: [
    PAYMENT_TYPES.ONLINE.BANK_WITHDRAW,
    PAYMENT_TYPES.ONLINE.ALIPAY_WITHDRAW,
    PAYMENT_TYPES.ONLINE.WECHAT_WITHDRAW,
  ],
};

export const PAYMENT_TYPES_DEPOSIT = {
  OFFLINE: [
    PAYMENT_TYPES.OFFLINE.ALIPAY,
    PAYMENT_TYPES.OFFLINE.BANK,
    PAYMENT_TYPES.OFFLINE.WECHAT,
    PAYMENT_TYPES.OFFLINE.MANUAL,
    PAYMENT_TYPES.OFFLINE.CRYPTOCURRENCY,
    PAYMENT_TYPES.OFFLINE.HEXOPAY,
    PAYMENT_TYPES.OFFLINE.NETELLER,
    PAYMENT_TYPES.OFFLINE.SKRILL,
  ],
  ONLINE: [
    PAYMENT_TYPES.ONLINE.BANK,
    PAYMENT_TYPES.ONLINE.ALIPAY,
    PAYMENT_TYPES.ONLINE.WECHAT,
    PAYMENT_TYPES.ONLINE.UNION_PAY,
  ],
};

export const BLOCK_CHAIN_TYPES = {
  OFFLINE: {
    ERC20: 'ERC20',
    TRC20: 'TRC20',
    OMNI: 'OMNI',
  },
  ONLINE: {
    ERC20: 'ERC20',
    TRC20: 'TRC20',
    OMNI: 'OMNI',
  },
};

export const PAYMENT_TYPES_PLAIN_LIST = {
  OFFLINE: Object.keys(PAYMENT_TYPES.OFFLINE).map(
    (offlineKey) =>
      PAYMENT_TYPES.OFFLINE[offlineKey as keyof typeof PAYMENT_TYPES.OFFLINE]
  ),
  ONLINE: Object.keys(PAYMENT_TYPES.ONLINE).map(
    (onlineKey) =>
      PAYMENT_TYPES.ONLINE[onlineKey as keyof typeof PAYMENT_TYPES.ONLINE]
  ),
};

export const PAYMENT_METHODS_LIST = {
  OfflineBankTransferPaymentMethod: PAYMENT_TYPES.OFFLINE.BANK,
  WechatPaymentMethod: PAYMENT_TYPES.OFFLINE.WECHAT,
  AlipayPaymentMethod: PAYMENT_TYPES.OFFLINE.ALIPAY,
  OnlineWechatPaymentMethod: PAYMENT_TYPES.ONLINE.WECHAT,
  OnlineAlipayPaymentMethod: PAYMENT_TYPES.ONLINE.ALIPAY,
  OnlineBankTransferPaymentMethod: PAYMENT_TYPES.ONLINE.BANK,
  UnionPayPaymentMethod: PAYMENT_TYPES.ONLINE.UNION_PAY,
  CryptocurrencyPaymentMethod: PAYMENT_TYPES.OFFLINE.CRYPTOCURRENCY,
  ManualAdjustmentPaymentMethod: PAYMENT_TYPES.OFFLINE.MANUAL,
  HexoPayPaymentMethod: PAYMENT_TYPES.OFFLINE.HEXOPAY,
  NetellerPaymentMethod: PAYMENT_TYPES.OFFLINE.NETELLER,
  SkrillPaymentMethod: PAYMENT_TYPES.OFFLINE.SKRILL,
  AstroPayWalletPaymentMethod: PAYMENT_TYPES.OFFLINE.ASTROPAYWALLET,

  AstroPayWalletWithdrawalMethod: PAYMENT_TYPES.OFFLINE.ASTROPAYWALLET,
  PayByAstroPayPaymentMethod: PAYMENT_TYPES.OFFLINE.PAYBYASTROPAY,
  // withdrawal methods
  OfflineBankTransferWithdrawalMethod: PAYMENT_TYPES.OFFLINE.BANK_WITHDRAW,
  OnlineBankTransferWithdrawalMethod: PAYMENT_TYPES.ONLINE.BANK_WITHDRAW,
  WechatWithdrawalMethod: PAYMENT_TYPES.OFFLINE.WECHAT_WITHDRAW,
  OnlineWechatWithdrawalMethod: PAYMENT_TYPES.ONLINE.WECHAT_WITHDRAW,
  AlipayWithdrawalMethod: PAYMENT_TYPES.OFFLINE.ALIPAY_WITHDRAW,
  OnlineAlipayWithdrawalMethod: PAYMENT_TYPES.ONLINE.ALIPAY_WITHDRAW,
  ManualAdjustmentWithdrawalMethod: PAYMENT_TYPES.OFFLINE.MANUAL_WITHDRAW,
  HexoPayWithdrawalMethod: PAYMENT_TYPES.OFFLINE.HEXOPAY,
  NetellerWithdrawalMethod: PAYMENT_TYPES.OFFLINE.NETELLER,
  SkrillWithdrawalMethod: PAYMENT_TYPES.OFFLINE.SKRILL,
};

const ONLINE = 'ONLINE';
const OFFLINE = 'OFFLINE';

export const PAYMENT_SOURCES = {
  ONLINE,
  OFFLINE,
};

const OFFLINE_BANK_TYPES = [
  PAYMENT_TYPES.OFFLINE.BANK,
  PAYMENT_TYPES.OFFLINE.BANK_WITHDRAW,
];

const ONLINE_BANK_TYPES = [
  PAYMENT_TYPES.ONLINE.BANK,
  PAYMENT_TYPES.ONLINE.BANK_WITHDRAW,
];

const BANK_TYPES = [...OFFLINE_BANK_TYPES, ...ONLINE_BANK_TYPES];

const OFFLINE_ALIPAY_TYPES = [
  PAYMENT_TYPES.OFFLINE.ALIPAY,
  PAYMENT_TYPES.OFFLINE.ALIPAY_WITHDRAW,
];

const ONLINE_ALIPAY_TYPES = [
  PAYMENT_TYPES.ONLINE.ALIPAY,
  PAYMENT_TYPES.ONLINE.ALIPAY_WITHDRAW,
];

const ALIPAY_TYPES = [...OFFLINE_ALIPAY_TYPES, ...ONLINE_ALIPAY_TYPES];

const OFFLINE_WECHAT_TYPES = [
  PAYMENT_TYPES.OFFLINE.WECHAT,
  PAYMENT_TYPES.OFFLINE.WECHAT_WITHDRAW,
];

const ONLINE_WECHAT_TYPES = [
  PAYMENT_TYPES.ONLINE.WECHAT,
  PAYMENT_TYPES.ONLINE.WECHAT_WITHDRAW,
];

const WECHAT_TYPES = [...OFFLINE_WECHAT_TYPES, ...ONLINE_WECHAT_TYPES];

const UNION_PAY_TYPES = [PAYMENT_TYPES.ONLINE.UNION_PAY];

const USDT_PAY_TYPES = [PAYMENT_TYPES.OFFLINE.CRYPTOCURRENCY];

const ERC20_TYPES = [BLOCK_CHAIN_TYPES.OFFLINE.ERC20];

const MANUAL_TYPES = [
  PAYMENT_TYPES.OFFLINE.MANUAL,
  PAYMENT_TYPES.OFFLINE.MANUAL_WITHDRAW,
];

export const isTypeOfflineBank = (type: string) =>
  OFFLINE_BANK_TYPES.includes(type);
export const isTypeBank = (type: string) => BANK_TYPES.includes(type);
export const isTypeWechat = (type: string) => WECHAT_TYPES.includes(type);
export const isTypeInternalWechat = (type: string) =>
  OFFLINE_WECHAT_TYPES.includes(type);
export const isTypeAlipay = (type: string) => ALIPAY_TYPES.includes(type);
export const isTypeInternalAlipay = (type: string) =>
  OFFLINE_ALIPAY_TYPES.includes(type);
export const isTypeUnionPay = (type: string) => UNION_PAY_TYPES.includes(type);
export const isTypeUSDT = (type: string) => USDT_PAY_TYPES.includes(type);
export const isTypeERC20 = (type: string) => ERC20_TYPES.includes(type);
export const isTypeHexopay = (type: string) => type === 'HEXOPAY';
export const isTypeNeteller = (type: string) => type === 'NETELLER';
export const isTypeSkrill = (type: string) => type === 'SKRILL';
export const isTypeAstroapy = (type: string) => type === 'ASTROPAYWALLET';
export const isTypePayByAstroPay = (type: string) => type === 'PAYBYASTROPAY';
export const isTypeManual = (type: string) => MANUAL_TYPES.includes(type);

export const getNewType = (type: string) => {
  switch (type) {
    case PAYMENT_TYPES.OFFLINE.BANK:
      return PAYMENT_TYPES.ONLINE.BANK;
    case PAYMENT_TYPES.ONLINE.BANK:
      return PAYMENT_TYPES.OFFLINE.BANK;
    case PAYMENT_TYPES.OFFLINE.ALIPAY:
      return PAYMENT_TYPES.ONLINE.ALIPAY;
    case PAYMENT_TYPES.ONLINE.ALIPAY:
      return PAYMENT_TYPES.OFFLINE.ALIPAY;
    case PAYMENT_TYPES.OFFLINE.WECHAT:
      return PAYMENT_TYPES.ONLINE.WECHAT;
    case PAYMENT_TYPES.ONLINE.WECHAT:
      return PAYMENT_TYPES.OFFLINE.WECHAT;
    case PAYMENT_TYPES.OFFLINE.BANK_WITHDRAW:
      return PAYMENT_TYPES.ONLINE.BANK_WITHDRAW;
    case PAYMENT_TYPES.ONLINE.BANK_WITHDRAW:
      return PAYMENT_TYPES.OFFLINE.BANK_WITHDRAW;
    case PAYMENT_TYPES.OFFLINE.WECHAT_WITHDRAW:
      return PAYMENT_TYPES.ONLINE.WECHAT_WITHDRAW;
    case PAYMENT_TYPES.ONLINE.WECHAT_WITHDRAW:
      return PAYMENT_TYPES.OFFLINE.WECHAT_WITHDRAW;
    case PAYMENT_TYPES.OFFLINE.ALIPAY_WITHDRAW:
      return PAYMENT_TYPES.ONLINE.ALIPAY_WITHDRAW;
    case PAYMENT_TYPES.ONLINE.ALIPAY_WITHDRAW:
      return PAYMENT_TYPES.OFFLINE.ALIPAY_WITHDRAW;
    default:
      return type;
  }
};

export const getPaymentType = (typename: string) =>
  [
    ...ONLINE_BANK_TYPES,
    ...ONLINE_WECHAT_TYPES,
    ...ONLINE_ALIPAY_TYPES,
    ...UNION_PAY_TYPES,
  ].indexOf(
    PAYMENT_METHODS_LIST[typename as keyof typeof PAYMENT_METHODS_LIST]
  ) >= 0
    ? 'ONLINE'
    : 'OFFLINE';

export const isPaymentTypeInternal = (paymentType: string) =>
  paymentType === OFFLINE;
export const isHexopay = (paymentType: string) =>
  paymentType === PAYMENT_TYPES.OFFLINE.HEXOPAY;
export const isSkrill = (paymentType: string) =>
  paymentType === PAYMENT_TYPES.OFFLINE.SKRILL;
export const isNeteller = (paymentType: string) =>
  paymentType === PAYMENT_TYPES.OFFLINE.NETELLER;
export const isTypenameInternal = (typename: string) =>
  !/online|unionpay/i.test(typename);
export const isTypenameExternal = (typename: string) =>
  /online|unionpay/i.test(typename);

export default {};
