/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { SettingOutlined } from '@ant-design/icons';
import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import { Modal } from 'antd';
import CustomFilterList from 'components/CustomFilterList';

type Rec = Record<string, any>;
type Props = {
  onUseFilter: Function;
  contextKey: string;
  EditSearchSettingsNode: any;
  setLocalQuickFilter: Dispatch<SetStateAction<string>>;
  onFiltersUpdate: () => void;
  isNext?: boolean;
  quickSearchId?: string;
  SearchSettingsFormNode: ReactNode;
};

const CustomFilterSettings = ({
  onUseFilter,
  contextKey,
  EditSearchSettingsNode,
  setLocalQuickFilter,
  onFiltersUpdate,
  isNext,
  quickSearchId,
  SearchSettingsFormNode,
}: Props) => {
  const [isShow, setShow] = useState<boolean>(false);
  const showModal = () => setShow(!isShow);
  const handleUseFilter = (e: Rec) => {
    onUseFilter(e);
    showModal();
  };

  return (
    <>
      <ALink className="ml-1" onClick={showModal}>
        <SettingOutlined />
      </ALink>

      {isShow && (
        <Modal
          title={
            <FormattedMessage
              id="search-settings.text"
              defaultMessage="Search Settings"
            />
          }
          visible
          onOk={showModal}
          onCancel={showModal}
          footer={null}
        >
          <CustomFilterList
            setLocalQuickFilter={setLocalQuickFilter}
            onUseFilter={handleUseFilter}
            contextKey={contextKey}
            EditSearchSettingsNode={EditSearchSettingsNode}
            SearchSettingsFormNode={SearchSettingsFormNode}
            onFiltersUpdate={onFiltersUpdate}
            isNext={isNext}
            quickSearchId={quickSearchId}
          />
        </Modal>
      )}
    </>
  );
};

export default CustomFilterSettings;
