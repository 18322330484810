import { useApolloClient } from '@apollo/react-hooks';
import { message, Table } from 'antd';
import { ReactComponent as UserIcon } from 'components/assets/user.svg';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import Layout from 'components/Layout';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import TimezoneSelect from 'components/TimezoneSelect/TimezoneSelect';
import FiltersProvider from 'contexts/Filters';
import ReportsTimezoneProvider, {
  useReportsTimezone,
} from 'contexts/ReportsTimezone';
import { promoClient } from 'graphql/client/promoClient';
import {
  GET_MEMBERS_PROMO_HISTORY,
  GET_MEMBER_PREFERRED_LANGUAGE,
  GET_MEMBER_PROMO_HISTORY,
} from 'graphql/queries/member.query';
import { useTablePagination } from 'hooks/usePaginate';
import { PageInfo } from 'interfaces/user.interface';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { OriginalColumn } from 'store/customColumnState/types';
import { Tab, useScreenTabV2 } from 'store/screenTabState';
import { Member, QueryMemberArgs } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import { randomKey } from 'utils/randomKey';
import { renderNumeral } from 'utils/renderNumeral';
import { getColumns } from './utils';

type Props = {
  refreshPage: () => void;
};

const MemberPromoHistoryContent: FC<Props> = ({ refreshPage }) => {
  const [promoHistory, setPromoHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingPreferredLang, setFetchingPreferredLang] = useState(false);
  const [preferredLanguage, setPreferredLanguage] = useState<string | null>(
    'EN'
  );
  const [pageData, setPageData] = useState({
    totalCount: 0,
    totalPage: 0,
    pageInfo: {
      hasNextPage: false,
      endCursor: '',
    },
  });
  const client = useApolloClient();
  const { bypassedCurrentTimezone } = useReportsTimezone();
  // const isNext = useNextParam();

  const { getActiveTab } = useScreenTabV2();
  const tab: Tab = getActiveTab();

  const { page, setPage, handleNext, handlePrev } = useTablePagination({
    after: undefined,
    savedCursor: [],
    currentPage: 0,
    first: 10,
  });

  const {
    state: { member, filter, first, fetchAll },
    isActive,
  } = tab || {};

  const { id } = member || {};

  const columns = useCallback(
    () =>
      getColumns({
        renderNumeral,
        reportTimezone: bypassedCurrentTimezone,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bypassedCurrentTimezone]
  );

  // const [getPreferredLanguage, { data }] = useLazyQuery<
  //   { member: Member },
  //   QueryMemberArgs
  // >(GET_MEMBER_PREFERRED_LANGUAGE, {
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'network-only',
  // });

  const getPreferredLanguage = useCallback(async () => {
    try {
      if (id && isActive) {
        setFetchingPreferredLang(true);
        const preferredLang = await client.query<
          { member: Member },
          QueryMemberArgs
        >({
          query: GET_MEMBER_PREFERRED_LANGUAGE,
          variables: {
            id,
          },
          fetchPolicy: 'network-only',
        });
        if (preferredLang.data.member.preferredLanguage) {
          setPreferredLanguage(preferredLang.data.member.preferredLanguage);
        } else {
          setPreferredLanguage('EN');
        }
        setFetchingPreferredLang(false);
      }
    } catch (error) {
      message.error('Error fetching Member Preferred Language');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, isActive, id]);

  const fetchMemberPromoHistory = useCallback(
    async (cursor?: string) => {
      try {
        if ((id && isActive) || fetchAll) {
          setLoading(true);
          const promoHistoryQueryPromise = promoClient.query({
            query: fetchAll
              ? GET_MEMBERS_PROMO_HISTORY
              : GET_MEMBER_PROMO_HISTORY,
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            variables: {
              ...(id &&
                !fetchAll && {
                  id,
                  language: preferredLanguage,
                }),
              ...(!id &&
                fetchAll && {
                  language: 'EN',
                }),
              after: cursor,
              filter: {
                ...filter,
                ...(!fetchAll &&
                  member.currency && {
                    currency: {
                      equal: member?.currency,
                    },
                  }),
              },
              first,
            },
          });

          const promoHistoryRawData = await Promise.resolve(
            promoHistoryQueryPromise
          );

          const promoHistoryEdges = coercedGet(
            promoHistoryRawData.data,
            fetchAll ? 'memberPromoHistory.edges' : 'member.promoHistory.edges',
            {}
          );

          const totalCount = coercedGet(
            promoHistoryRawData.data,
            fetchAll
              ? 'memberPromoHistory.totalCount'
              : 'member.promoHistory.totalCount',
            0
          );
          const pageInfo = coercedGet(
            promoHistoryRawData.data,
            fetchAll
              ? 'memberPromoHistory.pageInfo'
              : 'member.promoHistory.pageInfo',
            0
          );

          const totalPage = Math.ceil(totalCount / page.first) || 1;

          setPageData((prev) => ({
            ...prev,
            totalCount,
            totalPage,
            pageInfo,
          }));

          const promoHistoryData = promoHistoryEdges?.map(({ node }: any) => ({
            ...node,
            key: node.id,
          }));

          setPromoHistory(promoHistoryData);
        }
      } catch (error) {
        message.error('Error fetching Promo History');
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchAll, filter, first, isActive, id, page.first, preferredLanguage]
  );
  const handleNextPage = (pageInfo: any) => {
    handleNext(pageInfo);
    fetchMemberPromoHistory(pageData.pageInfo.endCursor);
  };
  const handlePrevPage = () => {
    handlePrev();
    fetchMemberPromoHistory(page.savedCursor[page.currentPage - 1]);
  };

  useEffect(() => {
    getPreferredLanguage();
  }, [getPreferredLanguage]);

  useEffect(() => {
    fetchMemberPromoHistory();
  }, [fetchMemberPromoHistory]);

  const columnFields = useMemo(() => columns(), [columns]);

  const { filterColumns } = useCustomColumnsV2(
    'member-promo-history',
    (columnFields as unknown) as OriginalColumn[],
    {},
    !fetchAll ? 'member' : undefined
  );

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={<></>}
          rightNode={
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center mr-4">
                <UserIcon className="mr-2" />{' '}
                <p className="mb-0">{member?.username}</p>
              </div>
              <TimezoneSelect />
              <ColumnsFilterNew tabId="member-promo-history" iconOnly />
              <RefreshButton onClick={refreshPage} />
            </div>
          }
        />
        <Layout.Content
          sideBar={<></>}
          footer={
            <Layout.Footer
              loading={loading || fetchingPreferredLang}
              onNext={handleNextPage}
              onPrev={handlePrevPage}
              page={page}
              resultsCount={pageData.totalCount}
              totalPage={pageData.totalPage}
              setPage={setPage}
              pageInfo={pageData.pageInfo as PageInfo}
            />
          }
        >
          <Table
            loading={loading || fetchingPreferredLang}
            dataSource={promoHistory}
            columns={filterColumns(columnFields)}
            pagination={false}
            size="middle"
          />
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

const MemberPromoHistory = () => {
  const [key, setKey] = useState('');

  const refreshPage = () => setKey(randomKey());

  return (
    <ReportsTimezoneProvider>
      <FiltersProvider initialValues={{}}>
        <MemberPromoHistoryContent key={key} refreshPage={refreshPage} />
      </FiltersProvider>
    </ReportsTimezoneProvider>
  );
};

export default MemberPromoHistory;
