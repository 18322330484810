import React from 'react';
import { Input } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { useFormikContext } from 'formik';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';

const DepositAmountInput = () => {
  const translate = useTranslate();
  const [isEditMode, setIsEditMode] = React.useState(false);

  const inputRef = React.useRef<any>(null);

  const { values, handleSubmit, handleChange } = useFormikContext() as any;

  React.useEffect(() => {
    if (isEditMode) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      inputRef && inputRef.current && inputRef?.current!.focus();
    }
  }, [isEditMode]);

  const toggleEdit = () => {
    setIsEditMode(!isEditMode);
    handleSubmit();
  };

  return isEditMode ? (
    <>
      <div>{translate(messages['vip.deposit-amount'])}</div>
      <Input
        style={{ width: '80%' }}
        size="small"
        type="number"
        ref={inputRef}
        onPressEnter={toggleEdit}
        onBlur={toggleEdit}
        name="depositAmount"
        onChange={handleChange}
        value={values.depositAmount}
      />
    </>
  ) : (
    <ALink
      style={{
        color: '#000',
      }}
      onClick={() => {
        setIsEditMode(true);
      }}
    >
      <div>{translate(messages['vip.deposit-amount'])}</div>
      <strong>{values.depositAmount}</strong>
    </ALink>
  );
};

export default DepositAmountInput;
