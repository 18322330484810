import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, message } from 'antd';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { useForm, Controller } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import removeNull from 'utils/removeNull';
import { FormattedMessage } from 'react-intl';

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

type Props = {
  saveSearch: (params: any) => void;
  filter: { [key: string]: any };
  context: string;
  loading: boolean;
};

const SaveSearchForm: React.FC<Props> = ({
  saveSearch,
  filter,
  context,
  loading,
}) => {
  const { control, handleSubmit, errors, reset } = useForm();
  const translate = useTranslate();

  const onSubmit = async (data: { name: string }) => {
    if (isEmpty(filter)) {
      message.error(
        translate(messages['cannot-update-filter-empty-error.text'])
      );
    } else {
      await saveSearch({
        variables: {
          input: {
            name: data.name,
            context,
            filter: removeNull(filter),
          },
        },
      });
      reset();
    }
  };

  return (
    <Form
      {...formItemLayout}
      layout="horizontal"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Form.Item
        labelAlign="left"
        label={translate(messages['name.text'])}
        validateStatus={errors.name && 'error'}
        help={errors.name && translate(messages.REQUIRED)}
      >
        <Controller
          rules={{ required: true }}
          control={control}
          defaultValue=""
          name="name"
          as={<Input />}
        />
      </Form.Item>

      <div className="text-right mt-2">
        <Button type="primary" htmlType="submit" loading={loading}>
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </Form>
  );
};

export default SaveSearchForm;
