import gql from 'graphql-tag';

export const CLIENT_NAME = gql`
  query ClientName($id: ID!) {
    admin(id: $id) {
      id
      username
    }
  }
`;
