import React from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  PaymentMethodType,
  PaymentMethodsConnection,
  QueryAvailablePaymentMethodsArgs,
} from 'types/graphqlTypes';
import {
  Empty,
  Popconfirm,
  Tooltip,
  Typography,
  Checkbox,
  message,
} from 'antd';
import { StyledCardFlex } from 'pages/components/NewMemberDetail/components/MemberDetailContent/components/MemberInfo/styles';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import styled from 'styled-components';
import { IssuesCloseOutlined } from '@ant-design/icons';

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
`;

const AVAILABLE_PAYMENT_METHODS = gql`
  query AvailablePaymentMethods($filter: AvailablePaymentMethodsFilterInput!) {
    availablePaymentMethods(filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const TOGGLE_PAYMENT_METHOD_CLOSED_LOOP = gql`
  mutation togglePaymentMethodClosedLoop(
    $input: TogglePaymentMethodClosedLoopInput!
  ) {
    togglePaymentMethodClosedLoop(input: $input)
  }
`;

const AvailablePaymentMethods: React.FC<{
  memberId: string;
  skrillDisabled: boolean;
  netellerDisabled: boolean;
  skrillClosedLoopDisabled: boolean;
  netellerClosedLoopDisabled: boolean;
  memberEmail?: string;
  onTogglePaymentMethodState: (
    paymentMethodState:
      | { skrillDisabled: boolean }
      | { netellerDisabled: boolean }
  ) => void;
  fetchMemberData: () => void;
}> = ({
  memberId,
  skrillDisabled,
  memberEmail,
  netellerDisabled,
  skrillClosedLoopDisabled,
  netellerClosedLoopDisabled,
  onTogglePaymentMethodState,
  fetchMemberData,
}) => {
  const { context } = useOperatorHeader();

  const { data } = useQuery<
    {
      availablePaymentMethods: PaymentMethodsConnection;
    },
    QueryAvailablePaymentMethodsArgs
  >(AVAILABLE_PAYMENT_METHODS, {
    skip: !memberId,
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        account: memberId,
        paymentMethodType: {
          in: ['SKRILL' as PaymentMethodType, 'NETELLER' as PaymentMethodType],
        },
      },
    },
    context,
  });

  const rawData = data?.availablePaymentMethods?.edges || [];
  const availablePaymentMethods = rawData.map((x) => x!.node);

  const [togglePaymentMethodClosedLoop] = useMutation(
    TOGGLE_PAYMENT_METHOD_CLOSED_LOOP,
    {
      context,
    }
  );

  const handleToggleClosedLoop = async ({
    account,
    paymentMethod,
    disableClosedLoop,
  }: {
    account: string;
    paymentMethod: string;
    disableClosedLoop: boolean;
  }) => {
    try {
      await togglePaymentMethodClosedLoop({
        variables: {
          input: {
            account,
            paymentMethod,
            disableClosedLoop,
          },
        },
      });
      message.success('Payment method state updated.');
      fetchMemberData();
    } catch (error) {
      if (error) message.error('Something went wrong, Please try again.');
    }
  };

  return (
    <>
      {availablePaymentMethods.length > 0 ? (
        availablePaymentMethods?.map((paymentMethod) => (
          <StyledCardFlex>
            <StyledCardFlex>
              <Typography.Text>
                {paymentMethod.name}: {memberEmail}
              </Typography.Text>
            </StyledCardFlex>

            <div
              className="d-flex justify-content-end mb-0 align-items-center"
              style={{
                width: '60px',
              }}
            >
              <Popconfirm
                className="mr-1"
                okText="Confirm"
                cancelText="Cancel"
                title={`Are you sure you want to ${
                  (paymentMethod.name === 'Skrill'
                  ? !skrillDisabled
                  : !netellerDisabled)
                    ? 'disable'
                    : 'enable'
                } the payment method?`}
                onConfirm={() =>
                  onTogglePaymentMethodState(
                    paymentMethod.name === 'Skrill'
                      ? { skrillDisabled: !skrillDisabled }
                      : { netellerDisabled: !netellerDisabled }
                  )
                }
              >
                <Tooltip
                  title={`Payment method is ${
                    (paymentMethod.name === 'Skrill'
                    ? !skrillDisabled
                    : !netellerDisabled)
                      ? 'enabled'
                      : 'disabled'
                  }.`}
                >
                  <StyledCheckbox
                    checked={
                      paymentMethod.name === 'Skrill'
                        ? !skrillDisabled
                        : !netellerDisabled
                    }
                  />
                </Tooltip>
              </Popconfirm>

              <Popconfirm
                okText="Confirm"
                cancelText="Cancel"
                title={`Are you sure you want to ${
                  (paymentMethod.name === 'Skrill'
                  ? Boolean(skrillClosedLoopDisabled)
                  : Boolean(netellerClosedLoopDisabled))
                    ? 'enable'
                    : 'disable'
                } closed loop?`}
                onConfirm={() =>
                  handleToggleClosedLoop({
                    account: memberId,
                    paymentMethod: paymentMethod.id,
                    disableClosedLoop:
                      paymentMethod.name === 'Skrill'
                        ? !skrillDisabled
                        : !netellerDisabled,
                  })
                }
              >
                <Tooltip
                  title={`Closed loop feature is ${
                    (paymentMethod.name === 'Skrill'
                    ? Boolean(skrillClosedLoopDisabled)
                    : Boolean(netellerClosedLoopDisabled))
                      ? 'disabled'
                      : 'enabled'
                  }.`}
                >
                  <IssuesCloseOutlined
                    className={`text-${
                      (paymentMethod.name === 'Skrill'
                      ? Boolean(skrillClosedLoopDisabled)
                      : Boolean(netellerClosedLoopDisabled))
                        ? 'danger'
                        : 'success'
                    } cursor-pointer`}
                  />
                </Tooltip>
              </Popconfirm>
            </div>
          </StyledCardFlex>
        ))
      ) : (
        <Empty />
      )}
    </>
  );
};

export default AvailablePaymentMethods;
