import styled from 'styled-components';
import { Select, Tag } from 'antd';

export const SupportedBanksContainer = styled.div`
  width: 100%;
  padding: 10px 4px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.15);
`;

export const BankTag = styled(Tag)`
  margin-bottom: 8px;
`;

export const StyledSelect = styled(Select)`
  width: 100% !important;
`;
