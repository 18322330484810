import styled from 'styled-components';
import { Table } from 'antd';

export const SharedStyledTable: any = styled(Table)`
  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }
`;

export const SharedRevampedTable = styled(Table)`
  .ant-table-small {
    height: calc(100vh - 215px);
    border-top: none !important;
    border-radius: 0;

    .ant-table-content {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  .ant-table-small > .ant-table-content .ant-table-header {
    background-color: #fafafa !important;
  }

  .ant-table-fixed .ant-table-thead {
    background-color: #fafafa !important;
  }

  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }

  .ant-table-body {
    max-height: calc(100vh - 274px) !important;
  }

  .ant-table-fixed-right {
    right: 22px;
    height: 100%;

    transform: translateX(19px);

    box-shadow: 4px 0 12px 14px rgba(255, 255, 255, 1);
    .ant-table-body-inner {
      overflow-y: hidden;
      &::-webkit-scrollbar {
        background: transparent;
      }

      &::-moz-scrollbar {
        background: transparent;
      }

      &::-webkit-scrollbar-button {
        background: transparent;
      }

      &::-moz-scrollbar-button {
        background: transparent;
      }

      overflow-y: hidden !important;
      max-height: calc(100vh - 257px) !important;
    }
  }
`;

export default {};
