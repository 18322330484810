import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import { MemberAccessSummaryReportItemsFilterInput } from 'types/graphqlTypes';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import {
  GET_MASR_REALNAMES,
  GET_MASR_USERNAMES,
} from 'graphql/queries/memberAccessSummary.query';
import useTranslate from 'utils/useTranslate';
import IpAddressTotalCountFilter from 'components/IpAddressTotalCountFilter';
import BrandFilter from 'components/BrandFilter';
import messages from 'messages';
import { StateFilter } from '../../types';

type Props = {
  filters: Partial<MemberAccessSummaryReportItemsFilterInput>;
  isCustomFilter?: boolean;
  onFilterChange: (value: DynamicObj) => void;
  stateFilters: StateFilter;
  isLoading: boolean;
};

const FilterItems = ({
  filters,
  onFilterChange,
  stateFilters,
  isCustomFilter = false,
  isLoading,
}: Props) => {
  const translate = useTranslate();

  const brands = stateFilters?.brand?.map((e: string) => e);

  const realnameValues = {
    member: {
      in: stateFilters?.realnames,
    },
  };
  const usernameValues = {
    member: {
      in: stateFilters?.usernames,
    },
  };

  const brandValues = {
    brand: {
      in: brands,
    },
  };

  return (
    <>
      <IdBasedSelectFilterField
        testId="MASR-username-filter"
        label="Username"
        query={GET_MASR_USERNAMES}
        queryConnection="members"
        filterFieldName="username"
        filters={isCustomFilter ? usernameValues : filters}
        onChange={(e) => {
          onFilterChange({
            usernames: e,
          });
        }}
        localFilterState={{
          stateFilter: stateFilters as Record<string, string[]>,
          stateFilterName: 'usernames',
        }}
        isCustomFilter={isCustomFilter}
      />
      <IdBasedSelectFilterField
        testId="MASR-realname-filter"
        label="Real Name"
        query={GET_MASR_REALNAMES}
        queryConnection="members"
        filterFieldName="realName"
        filters={isCustomFilter ? realnameValues : filters}
        onChange={(e) => {
          onFilterChange({
            realnames: e,
          });
        }}
        localFilterState={{
          stateFilter: stateFilters as Record<string, string[]>,
          stateFilterName: 'realnames',
        }}
        isCustomFilter
      />
      <IpAddressTotalCountFilter
        label={translate(messages.TOTAL_COUNT)}
        value={filters.ipAddressesCount!}
        onChange={onFilterChange}
        disabled={isLoading}
      />
      <BrandFilter
        testId="MASR-brand-filter"
        setState={onFilterChange}
        rawHandleOnChange={(e) => {
          onFilterChange({
            brand: e,
          });
        }}
        state={brandValues as Record<string, any>}
      />
    </>
  );
};

export default FilterItems;
