import React, { useState } from 'react';
import { EllipsisOutlined, MenuOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Input, InputNumber, Menu, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Control, Controller, useWatch } from 'react-hook-form';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import messages from 'messages';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import { TaskMethodSelect } from '../TaskMethodSelect';
import {
  PromptWarningModal,
  RadioSwitch,
  RenderExtraByMethod,
} from '../../utils';

type Props = {
  nestIndex: number;
  control: Control;
  index: number;
  item: Record<string, any>;
  remove: (idx: number) => void;
  disableDelete: boolean;
  errors: Record<string, any>;
  watchPointsSetting: object;
  insert: (index: number, value: object | object[]) => void;
  swap: (from: number, to: number) => void;
};

const ErrorMessage = ({
  errors,
  name,
  index,
  nestIndex,
}: {
  errors: Record<string, any>;
  name: string;
  index: number;
  nestIndex: number;
}) => {
  if (errors) {
    const error = coercedGet(
      errors,
      `days[${nestIndex}].tasks[${index}].[${name}].message`,
      null
    );
    return error ? <small className="text-danger">{error}</small> : <></>;
  }

  return <></>;
};

export const TaskCard = ({
  nestIndex,
  control,
  index,
  item,
  remove,
  disableDelete,
  errors,
  watchPointsSetting,
  insert,
  swap,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const taskWatch: Record<string, any>[] = useWatch({
    control,
    name: 'days',
    defaultValue: [{ tasks: [] }],
  });

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'task',
    canDrop: (
      itemDrop: DragObjectWithType & { id: { parent: number; child: number } }
    ) => itemDrop.id.parent === nestIndex,
    drop: (
      itemDrop: DragObjectWithType & { id: { child: number; parent: number } }
    ) => {
      swap(itemDrop.id.child, index);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });
  const [, drag, preview] = useDrag({
    item: { type: 'task', id: { child: index, parent: nestIndex } },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const attachRef = (el: HTMLElement) => {
    preview(el);
    drop(el);
  };
  const translate = useTranslate();
  const { currency } = useConfig();
  const name = (val: string) => `days[${nestIndex}].tasks[${index}].${val}`;
  const watching = taskWatch[nestIndex]?.tasks[index];

  return (
    <section ref={attachRef} className="position-relative">
      {isOver && canDrop && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'lightgray',
          }}
        />
      )}
      <div
        className="mt-2 border px-3 d-flex"
        style={{
          overflowX: 'auto',
          borderRadius: '4px',
          minHeight: '88px',
          paddingTop: '16px',
          paddingBottom: '16px',
        }}
      >
        <div style={{ width: '250px' }} className="flex-shrink-0">
          <div className="d-flex align-items-center">
            <div ref={drag}>
              <MenuOutlined
                className="text-muted fs-18 mr-3"
                style={{ cursor: 'grab' }}
              />
            </div>
            <div>
              <p className="fw-600 fs-13">
                <FormattedMessage id="TASK" defaultMessage="Task" /> {index + 1}
              </p>
              <Controller
                name={name('type')}
                control={control}
                defaultValue={item.type}
                render={({ value, onChange }) => (
                  <TaskMethodSelect value={value} onChange={onChange} />
                )}
              />
            </div>
          </div>
        </div>

        <div style={{ width: '70px' }} className="mr-3 flex-shrink-0">
          <RenderExtraByMethod
            item={item}
            method={watching?.type}
            control={control}
            name={name}
          />
        </div>

        <div style={{ width: '70px' }} className="mr-3 flex-shrink-0">
          {watching?.type === 'EFFECTIVE_BET' && (
            <div>
              <p className="fw-600 fs-13" style={{ marginBottom: '10px' }}>
                <FormattedMessage id="AMOUNT" defaultMessage="Amount" /> (
                {currency?.symbol})
              </p>
              <Controller
                name={name('amount')}
                defaultValue={item.amount}
                control={control}
                as={<Input style={{ width: '100%' }} />}
              />
              <ErrorMessage
                errors={errors}
                name="amount"
                index={index}
                nestIndex={nestIndex}
              />
            </div>
          )}
        </div>

        <div style={{ width: '70px' }} className="flex-shrink-0">
          <div style={{ height: '100%' }}>
            <p className="fw-600 fs-13">
              <FormattedMessage id="MULTIPLIER" defaultMessage="Multiplier" />
            </p>
            <div className="d-flex" style={{ marginTop: '16px' }}>
              <Controller
                name={name('multiplier')}
                control={control}
                defaultValue={item.multiplier}
                render={({ value, onChange }) => (
                  <RadioSwitch onChange={onChange} value={value} />
                )}
              />
            </div>
          </div>
        </div>

        <Divider
          type="vertical"
          style={{ height: '50px' }}
          className="mr-3 flex-shrink-0"
        />

        <div style={{ width: '100px' }} className="flex-shrink-0">
          <div>
            <p className="fw-600 fs-13">
              <FormattedMessage
                id="REWARD_POINT"
                defaultMessage="Reward Point"
              />
            </p>
            <Controller
              name={name('rewardPoints')}
              defaultValue={item.rewardPoints}
              control={control}
              as={
                <InputNumber
                  disabled={!watchPointsSetting}
                  style={{ width: '100%' }}
                />
              }
            />
            <ErrorMessage
              errors={errors}
              name="rewardPoints"
              index={index}
              nestIndex={nestIndex}
            />
          </div>
        </div>

        <Divider
          type="vertical"
          style={{ height: '50px' }}
          className="mx-3 flex-shrink-0"
        />

        <div className="pr-3 flex-shrink-0" style={{ width: '150px' }}>
          <div>
            <p className="fw-600 fs-13">
              <FormattedMessage id="REWARD_TYPE" defaultMessage="Reward Type" />
            </p>
            <Controller
              name={name('rewardType')}
              defaultValue={item.rewardType}
              control={control}
              as={
                <Select style={{ width: '100%' }}>
                  <Select.Option value="MONEY">
                    <FormattedMessage id="MONEY" defaultMessage="Money" />
                  </Select.Option>
                </Select>
              }
            />
          </div>
        </div>

        <div style={{ width: '150px' }} className="mr-3 flex-shrink-0">
          <div>
            <p className="fw-600 fs-13">
              <FormattedMessage
                id="REWARD_AMOUNT"
                defaultMessage="Reward Amount"
              />{' '}
              ({currency?.symbol})
            </p>
            <Controller
              name={name('rewardAmount')}
              defaultValue={item.rewardAmount}
              control={control}
              as={<Input style={{ width: '100%' }} />}
            />
            <ErrorMessage
              errors={errors}
              name="rewardAmount"
              index={index}
              nestIndex={nestIndex}
            />
          </div>
        </div>

        <div style={{ width: '70px' }} className="flex-shrink-0">
          <div style={{ height: '100%' }}>
            <p className="fw-600 fs-13">
              <FormattedMessage id="MULTIPLIER" defaultMessage="Multiplier" />
            </p>
            <div className="d-flex" style={{ marginTop: '16px' }}>
              <Controller
                name={name('rewardMultiplier')}
                control={control}
                defaultValue={item.rewardMultiplier}
                render={({ value, onChange }) => (
                  <RadioSwitch onChange={onChange} value={value} />
                )}
              />
            </div>
          </div>
        </div>

        <Divider
          type="vertical"
          style={{ height: '50px' }}
          className="mx-3 flex-shrink-0"
        />

        <div style={{ width: '150px' }} className="mr-3 flex-shrink-0">
          <div>
            <p className="fw-600 fs-13">
              <FormattedMessage id="TURNOVER" defaultMessage="Turnover" /> (x)
            </p>
            <Controller
              name={name('turnoverRequirementMultiplier')}
              defaultValue={item.turnoverRequirementMultiplier}
              control={control}
              as={<Input style={{ width: '100%' }} />}
            />

            <ErrorMessage
              errors={errors}
              name="turnoverRequirementMultiplier"
              index={index}
              nestIndex={nestIndex}
            />
          </div>
        </div>

        <div style={{ width: '22%' }} className="flex-shrink-0 pr-3">
          <div className="text-right">
            <p className="fw-600 fs-13" style={{ opacity: 0 }}>
              action
            </p>
            <Dropdown
              overlay={() => (
                <Menu>
                  <Menu.Item onClick={() => insert(index, watching)}>
                    <FormattedMessage
                      id="DUPLICATE_TASK"
                      defaultMessage="Duplicate Task"
                    />
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => setShowModal(true)}
                    disabled={disableDelete}
                  >
                    <FormattedMessage
                      id="DELETE_TASK"
                      defaultMessage="Delete Task"
                    />
                  </Menu.Item>
                </Menu>
              )}
            >
              <EllipsisOutlined
                className="cursor-pointer"
                style={{ fontSize: '25px' }}
              />
            </Dropdown>
          </div>
        </div>
      </div>

      <PromptWarningModal
        onOk={() => remove(index)}
        visible={showModal}
        onCloseFn={() => setShowModal(false)}
        title={translate(messages.DELETE_TASK)}
      />
    </section>
  );
};
