import React from 'react';
import { Modal, Button, Input, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

type Props = {
  visible: boolean;
  onValidateOTP: () => void;
  setNewOtp: (e: string) => void;
  otpNew: string;
};

const ValidateOTP = ({ visible, onValidateOTP, setNewOtp, otpNew }: Props) => (
  <Modal
    closable={false}
    centered
    title="Validate OTP"
    visible={visible}
    footer={null}
    width={400}
  >
    <div>
      <div className="d-flex align-items-center mb-3">
        <QuestionCircleOutlined
          className="mr-1"
          style={{ color: '#faad14', fontSize: '24px' }}
        />
        <Typography.Text>Please enter OTP from your Email.</Typography.Text>
      </div>
      <div className="d-flex flex-column justify-content-around align-items-center">
        <Input
          className="mb-2"
          maxLength={6}
          style={{
            width: '200px',
            height: '48px',
            fontSize: '24px',
            fontWeight: 'bold',
            textAlign: 'center',
            letterSpacing: '4px',
          }}
          value={otpNew}
          onChange={(e) => setNewOtp(e.target.value)}
        />
        <Button onClick={onValidateOTP} type="primary">
          Validate
        </Button>
      </div>
    </div>
  </Modal>
);

export default ValidateOTP;
