import React from 'react';
import { Col, Row } from 'antd';

const Header = ({
  leftNode,
  rightNode,
}: {
  leftNode: React.ReactNode;
  rightNode: React.ReactNode;
}) => (
  <Row className="my-3 d-flex align-items-center px-3">
    <Col md={12}>
      <div className="d-flex align-items-center">{leftNode}</div>
    </Col>
    <Col span={12}>
      <div className="d-flex align-items-center justify-content-flex-end">
        {rightNode}
      </div>
    </Col>
  </Row>
);

export default Header;
