import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import messages from 'messages';
import { StatusFilter } from 'types';
import { StyledTagContainer } from 'styles';
import { useFilterValues } from 'contexts/Filters';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { Filters } from 'pages/components/MemberLoyaltyManagement/types';
import { DATE_FORMAT } from 'constants/date';
import { customFormatMessage } from 'utils/customFormatMessage';

// type Props = {
//   filters: Filters;
//   onFilterChange: (value: any) => void;
//   initialFilters: Object;
// };

const FilterConditions = () => {
  // const {
  //   onFilterChange,

  //   initialFilters,
  // } = props;
  const [expandedTags, toggleExpandedTags] = useState(false);

  const {
    filters,
    setPersistedFilters,
    initialValues,
  } = useFilterValues() as any;
  const intl = useIntl();
  const [localStateFilter, setLocalStateFilter] = useState<Partial<Filters>>(
    {}
  );

  useEffect(() => {
    const transformedDateFilters = transformMovableDateFilter(filters);
    setLocalStateFilter(transformedDateFilters || {});
  }, [filters]);

  const handleRemoveProgramName = (program: any, e: any) => {
    e.preventDefault();
    // const { name } = filters;
    const name = get(filters, 'name', null);

    const data = name?.in?.filter((item: any) => item !== program) || [];
    const newName = {
      in: data,
    };
    setPersistedFilters({
      ...filters,
      name: data.length ? newName : null,
    });
  };

  const handleRemoveStatus = (value: any, e: any) => {
    e.preventDefault();
    const { status } = filters;
    const data =
      status?.in?.filter((item: StatusFilter) => item !== value, e) || [];
    const newStatus = {
      in: data,
    };
    setPersistedFilters({
      ...filters,
      status: data.length ? newStatus : null,
    });
  };

  const handleRemoveValidityDate = (e: any) => {
    e.preventDefault();
    setPersistedFilters({
      ...filters,
      validityStartDate: null,
      validityEndDate: null,
    });
  };
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(intl.formatMessage, messageVal, opts);

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className="d-flex align-items-start flex-g-1 w-50">
      <StyledTagContainer ref={ref} isExpanded={expandedTags}>
        <span className="ml-1">{translate(messages.FILTER_CONDITIONS)}: </span>
        {(filters?.name?.in || []).map((item: any, key: any) => (
          <Tag
            key={key}
            closable
            onClose={(e: any) => handleRemoveProgramName(item, e)}
          >
            {item}
          </Tag>
        ))}
        {(filters?.status?.in || []).map((item: any, key: any) => (
          <Tag
            key={key}
            closable
            onClose={(e: any) => handleRemoveStatus(item, e)}
          >
            {translate(messages[item])}
          </Tag>
        ))}
        {localStateFilter.validityDate ? (
          <Tag closable onClose={handleRemoveValidityDate}>
            <span>{translate(messages.EFFECTIVE_DATE_RANGE)}</span>:
            {moment(localStateFilter.validityDate.gte).format(DATE_FORMAT)}~
            {moment(localStateFilter.validityDate.lte).format(DATE_FORMAT)}
          </Tag>
        ) : null}
      </StyledTagContainer>
      <LegacyIconToggle
        forwardedRef={ref}
        filters={filters}
        expandedTags={expandedTags}
        toggleExpandedTags={toggleExpandedTags}
      />
      <ClearAllFilterButton
        initialFilters={initialValues}
        currentFilters={filters}
        onFilterChange={setPersistedFilters}
        style={{ marginTop: '0.35rem' }}
        className="mr-4"
      />
    </div>
  );
};

export default FilterConditions;
