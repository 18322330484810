import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { useLazyQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { WITHDRAWAL_METHODS } from './queries';

const { Option } = Select;

type Props = {
  value: string | Array<any>;
  placeHolder: string;
  onChange: (e: any) => void;
  disabled?: boolean;
  multiple: boolean;
  labelAsValue?: boolean;
  type?: string;
  queryFilter?: Record<string, any>;
};

const NicknameFilter = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
  labelAsValue,
  type,
  queryFilter,
}: Props) => {
  const [edges, setEdges] = useState([]);

  const [filter, setFilter] = useState({
    nickname: {
      contains: '',
    },
  });

  const newFilter = { ...filter, ...queryFilter } as Record<string, any>;
  if (type === 'INTERNAL') {
    newFilter.type = {
      in: [
        'OFFLINE_BANK_TRANSFER_WITHDRAWAL_METHOD',
        'ALIPAY_WITHDRAWAL_METHOD',
        'WECHAT_WITHDRAWAL_METHOD',
      ],
    };
  } else if (type === 'EXTERNAL') {
    newFilter.type = {
      nin: [
        'OFFLINE_BANK_TRANSFER_WITHDRAWAL_METHOD',
        'ALIPAY_WITHDRAWAL_METHOD',
        'WECHAT_WITHDRAWAL_METHOD',
      ],
    };
  }

  const [loadOptions, { loading }] = useLazyQuery(WITHDRAWAL_METHODS, {
    variables: {
      filter: newFilter,
      first: 10,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const {
        withdrawalMethods: { edges: withdrawalMethodEdges },
      } = data;

      setEdges(withdrawalMethodEdges);
    },
  });

  const handleSearch = (e: string) => {
    setFilter({
      nickname: {
        contains: e,
      },
    });
  };

  const handleChange = (e: any) => {
    onChange(e);
  };

  return (
    <Select
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      loading={loading}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
      onDropdownVisibleChange={(open) => {
        if (open && !edges.length) loadOptions();
      }}
    >
      {// eslint-disable-next-line no-shadow
      edges.map(
        ({
          node,
        }: {
          node: {
            value: string;
            nickname: string;
            bank: string;
          };
        }) => (
          <Option
            key={node.value}
            value={labelAsValue ? node.nickname || node.bank : node.value}
          >
            {node.nickname || node.bank}
          </Option>
        )
      )}
    </Select>
  );
};

export default NicknameFilter;
