import { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Table,
  Tooltip,
} from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import { useFormik } from 'formik';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';
import { isEmpty } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { usePermissions } from 'store/accountState';
import useTranslate from 'utils/useTranslate';
import { CONFIG } from './utils';
import { validationSchema } from './validationSchema';

const MemberManagementConfig = () => {
  const translate = useTranslate();
  const [modalVisible, setModalVisible] = useState(false);

  const [updateConfig] = useMutation(UPDATE_CONFIG);
  const { data, loading } = useQuery(CONFIG, {
    fetchPolicy: 'network-only',
  });

  const defaultReusabilityCount = 0;

  const defaultValue = {
    isEmailReusable: data?.config?.isEmailReusable,
    emailReusabilityCount:
      data?.config?.emailReusabilityCount !== null
        ? data?.config?.emailReusabilityCount
        : defaultReusabilityCount,
    isMobileNumberReusable: data?.config?.isMobileNumberReusable,
    mobileNumberReusabilityCount:
      data?.config?.mobileNumberReusabilityCount !== null
        ? data?.config?.mobileNumberReusabilityCount
        : defaultReusabilityCount,
  };

  const formik = useFormik({
    onSubmit: (e) => {
      const {
        isEmailReusable,
        isMobileNumberReusable,
        emailReusabilityCount,
        mobileNumberReusabilityCount,
      } = e;
      updateConfig({
        variables: {
          input: {
            isEmailReusable,
            isMobileNumberReusable,
            mobileNumberReusabilityCount,
            emailReusabilityCount,
          },
        },
      }).then(() => {
        message.success(translate(messages['successfully-saved-changes.text']));
        setModalVisible(false);
      });
    },
    validationSchema: validationSchema(),
    initialValues: data?.config
      ? defaultValue
      : {
          emailReusabilityCount: defaultReusabilityCount,
          isEmailReusable: false,
          isMobileNumberReusable: false,
          mobileNumberReusabilityCount: defaultReusabilityCount,
        },
    enableReinitialize: true,
  });

  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
  } = formik;

  const { role, permissions } = usePermissions();

  const { ALLOWED_EDIT } = collectPermissions(
    role,
    permissions,
    ['EDIT'],
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MANAGEMENT_CONFIG
  );

  const EmailColumns = [
    {
      title: 'Unique Email Adress',
      dataIndex: 'name',
      key: 'name',
      width: 400,
    },
    {
      title: 'YES',
      dataIndex: 'isEmailReusableYes',
      key: 'isEmailReusableYes',
      width: 150,
      render: () => (
        <Checkbox
          checked={values.isEmailReusable}
          onChange={(e: any) => {
            const value = e?.target?.checked;
            return setFieldValue('isEmailReusable', value);
          }}
        />
      ),
    },
    {
      title: 'NO',
      dataIndex: 'isEmailReusableNo',
      key: 'isEmailReusableNo',
      render: () => (
        <Checkbox
          checked={!values.isEmailReusable}
          onChange={(e: any) => {
            const value = e?.target?.checked;
            return setFieldValue('isEmailReusable', !value);
          }}
        />
      ),
    },
  ];

  const MobileColumns = [
    {
      title: 'Unique Mobile Number',
      dataIndex: 'name',
      key: 'name',
      width: 400,
    },
    {
      title: 'YES',
      dataIndex: 'isMobileNumberReusableYes',
      key: 'isMobileNumberReusableYes',
      width: 150,
      render: () => (
        <Checkbox
          checked={values.isMobileNumberReusable}
          onChange={(e: any) => {
            const value = e?.target?.checked;
            return setFieldValue('isMobileNumberReusable', value);
          }}
        />
      ),
    },
    {
      title: 'NO',
      dataIndex: 'isMobileNumberReusableNo',
      key: 'isMobileNumberReusableNo',
      render: () => (
        <Checkbox
          checked={!values.isMobileNumberReusable}
          onChange={(e: any) => {
            const value = e?.target?.checked;
            return setFieldValue('isMobileNumberReusable', !value);
          }}
        />
      ),
    },
  ];

  const emailReusabilityCount = [
    {
      title: translate(messages.FIELD_NAME),
      dataIndex: 'name',
      key: 'name',
      width: 400,
    },
    {
      title: 'YES',
      dataIndex: 'emailReusabilityCount',
      key: 'emailReusabilityCount',
      width: 100,
      validate: values.emailReusabilityCount,
      render: () => (
        <>
          <Input
            disabled={!values.isEmailReusable || !ALLOWED_EDIT}
            type="number"
            style={{ width: '100px' }}
            value={
              values.isEmailReusable
                ? values.emailReusabilityCount
                : defaultReusabilityCount
            }
            name="emailReusabilityCount"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div style={{ fontSize: '12px', color: 'red', width: '350px' }}>
            {errors && errors.emailReusabilityCount}
          </div>
        </>
      ),
    },
    {
      title: 'NO',
      dataIndex: 'render',
      key: 'render',
    },
  ];

  const mobileReusabilityCount = [
    {
      title: translate(messages.FIELD_NAME),
      dataIndex: 'name',
      key: 'name',
      width: 400,
    },
    {
      title: 'YES',
      dataIndex: 'mobileNumberReusabilityCount',
      key: 'mobileNumberReusabilityCount',
      width: 100,
      validate: values.mobileNumberReusabilityCount,
      render: () => (
        <>
          <Input
            disabled={!values.isMobileNumberReusable || !ALLOWED_EDIT}
            type="number"
            value={
              values.isMobileNumberReusable
                ? values.mobileNumberReusabilityCount
                : defaultReusabilityCount
            }
            name="mobileNumberReusabilityCount"
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ width: '100px' }}
          />
          <div style={{ fontSize: '12px', color: 'red', width: '350px' }}>
            {errors && errors.mobileNumberReusabilityCount}
          </div>
        </>
      ),
    },
    {
      title: 'NO',
      dataIndex: 'render',
      key: 'render',
    },
  ];

  const mock = [
    {
      name: 'Same Email Address can be used in multiple accounts',
      height: 100,
      render: null,
      key: 'emailAddress',
    },
  ];

  const MobileMock = [
    {
      name: 'Same Mobile Number can be used in multiple account registration?',
      // validate: values.email,
      render: null,
      key: 'emailAddress',
    },
  ];

  const EmailVerify = [
    {
      name: 'How many accounts can use "one email address"',
      height: 100,
      render: null,
      key: 'verifyEmail',
    },
  ];

  const MobileVerify = [
    {
      name: 'How many accounts can use "one mobile number"',
      // validate: values.email,
      render: null,
      key: 'verifyEmail',
    },
  ];

  return (
    <Form style={{ height: 'calc(100vh - 112px)' }}>
      <div className="py-4 px-3" style={{ background: 'white' }}>
        <div className="d-flex justify-content-between">
          <p className="fs-14 fw-600">
            <FormattedMessage
              id="MEMBER_MANAGEMENT_CONFIG"
              defaultMessage="Member Management Config"
            />
          </p>
          <Tooltip
            placement="left"
            title={
              !ALLOWED_EDIT &&
              'Need Permission in Member Management Config (Edit)'
            }
          >
            <Button
              disabled={!isEmpty(formik.errors) || !ALLOWED_EDIT}
              onClick={() => setModalVisible(true)}
            >
              <FormattedMessage id="save.text" defaultMessage="Save" />
            </Button>
          </Tooltip>
        </div>

        <div className="mt-3">
          <Table columns={EmailColumns} dataSource={mock} pagination={false} />
        </div>
        <Table
          loading={loading}
          columns={emailReusabilityCount}
          dataSource={EmailVerify}
          pagination={false}
          showHeader={false}
        />

        <div className="mt-3">
          <Table
            columns={MobileColumns}
            dataSource={MobileMock}
            pagination={false}
          />
        </div>
        <Table
          loading={loading}
          columns={mobileReusabilityCount}
          dataSource={MobileVerify}
          pagination={false}
          showHeader={false}
        />
      </div>

      <Modal
        visible={modalVisible}
        onOk={() => handleSubmit()}
        onCancel={() => setModalVisible(false)}
        bodyStyle={{
          padding: '32px 32px 24px 32px',
        }}
        footer={null}
        closable={false}
      >
        <div className="modal-content d-flex flex-row">
          <div style={{ width: 38 }}>
            <InfoCircleOutlined
              style={{ color: 'rgb(251,193,78)', fontSize: '22px' }}
            />
          </div>

          <div style={{ position: 'relative', width: '100%' }}>
            <div
              className="modal-title"
              style={{
                fontSize: 16,
                color: 'rgba(0,0,0,0.85',
              }}
            >
              <div>{translate(messages.DO_YOU_WISH_TO_PROCEED)}</div>
            </div>

            <div className="buttons d-flex justify-content-end">
              <Button onClick={() => setModalVisible(false)}>
                {translate(messages['cancel.text'])}
              </Button>

              <Button
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginLeft: 8 }}
              >
                {translate(messages['save.text'])}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default MemberManagementConfig;
