import React from 'react';
import { useFormikContext } from 'formik';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import styled from 'styled-components';
import AgentAffiliateProgramme from './components/AgentAffiliateProgramme';
import CostSettings from './components/CostSettings';
import TierSettings from './components/TierSettings';
import SettlementPeriod from './components/SettlementPeriod';

import {
  useCreateAffiliateProgrammeState,
  CreateAffiliateProgrammeTypes,
} from '../../context';

function InnerForm() {
  const formProps = useFormikContext();
  const [
    createAffiliateProgrammeState,
  ] = useCreateAffiliateProgrammeState() as any;
  return (
    <Root>
      <Form onSubmit={formProps.handleSubmit}>
        <div className="form-main">
          {createAffiliateProgrammeState.activeScreen ===
            CreateAffiliateProgrammeTypes.AGENT_AFFILIATE_PROGRAMME_SCREEN && (
            <AgentAffiliateProgramme formProps={formProps} />
          )}
          {createAffiliateProgrammeState.activeScreen ===
            CreateAffiliateProgrammeTypes.COST_SETTINGS_SCREEN && (
            <CostSettings />
          )}
          {createAffiliateProgrammeState.activeScreen ===
            CreateAffiliateProgrammeTypes.TIER_SETTINGS_SCREEN && (
            <TierSettings formProps={formProps} />
          )}
          {createAffiliateProgrammeState.activeScreen ===
            CreateAffiliateProgrammeTypes.SETTLEMENT_PERIOD_SCREEN && (
            <SettlementPeriod formProps={formProps} />
          )}
        </div>
      </Form>
    </Root>
  );
}

const Root = styled.div`
  .ant-col {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export default InnerForm;
