import { useApolloClient } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import BulkForceChangePasswordMenuForm from 'components/BulkForceChangePasswordMenuForm';
import gql from 'graphql-tag';
import { GET_MEMBERS } from 'graphql/queries/member.query';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import messages from './messages';
import { StyledModal } from './styles';

const graphqlString = ({
  userId,
  forceChangePassword,
}: {
  userId: string;
  forceChangePassword: boolean;
}) => `
  ${userId}: updateMember(id: "${userId}", input: {
    forceChangePassword: ${forceChangePassword}
  })`;

const BulkForceChangePasswordMenu = ({
  members,
  refetchParams,
}: {
  members: string[];
  refetchParams: Record<string, any>;
}) => {
  const translate = useTranslate();
  const client = useApolloClient();
  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const handleSubmit = async (e: Record<string, any>) => {
    const listOfIds = e.username.map((user: { key: string }) => user.key);
    setLoading(true);
    try {
      const strings = listOfIds.reduce((acc: string | number, cur: string) => {
        // eslint-disable-next-line no-param-reassign
        acc += graphqlString({
          userId: cur,
          forceChangePassword: e.forceChangePassword,
        });
        return acc;
      }, '');
      await client.mutate({
        mutation: gql(`mutation { ${strings} }`),
        refetchQueries: [
          {
            query: GET_MEMBERS,
            variables: { ...refetchParams },
          },
        ],
      });
      message.success(
        translate(messages['member-password-change.success-message.text'])
      );
      setLoading(false);
      handleShow();
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Button className="text-black" type="link" onClick={handleShow} block>
        <FormattedMessage
          id="members.force-login-password.text"
          defaultMessage="Force Login Password Change"
        />
      </Button>
      {isShow && (
        <StyledModal
          title={
            <FormattedMessage
              id="members.force-login-password.text"
              defaultMessage="Force Login Password Change"
            />
          }
          onCancel={handleShow}
          visible={isShow}
          footer={false}
        >
          <BulkForceChangePasswordMenuForm
            initialValues={{
              username: members,
              forceChangePassword: false,
            }}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            onClose={handleShow}
          />
        </StyledModal>
      )}
    </>
  );
};

export default BulkForceChangePasswordMenu;
