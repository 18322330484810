// eslint-disable-next-line react/prop-types
import { Form } from '@ant-design/compatible';

import '@ant-design/compatible/assets/index.css';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Select, Tooltip } from 'antd';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';
import isRow from 'utils/isRow';
import ALL_PERMISSIONS from 'constants/permissions3';

const { Item } = Form;

const messages = defineMessages({
  WITHDRAWAL: {
    id: 'WITHDRAWAL',
    defaultMessage: 'Withdrawal',
  },
  MANUAL_WITHDRAWAL: {
    id: 'MANUAL_WITHDRAWAL',
    defaultMessage: 'Manual Withdrawal',
  },
  CREDIT_VENDOR_TRANSFER: {
    id: 'CREDIT_VENDOR_TRANSFER',
    defaultMessage: 'Credit Vendor Transfer',
  },
  SYSTEM_CREDIT: {
    id: 'SYSTEM_CREDIT',
    defaultMessage: 'System Credit',
  },

  DORMANT_ADJUSTMENT: {
    id: 'DORMANT_ADJUSTMENT',
    defaultMessage: 'Dormant Adjustment',
  },

  REMOVING_SE_BALANCE: {
    id: 'REMOVING_SE_BALANCE',
    defaultMessage: 'Removing SE Balance',
  },

  SYSTEM_DEBIT: {
    id: 'SYSTEM_DEBIT',
    defaultMessage: 'System Debit',
  },
  SYSTEM: {
    id: 'SYSTEM',
    defaultMessage: 'System',
  },
  PAYOUT: {
    id: 'PAYOUT',
    defaultMessage: 'Payout',
  },
  DEPOSIT: {
    id: 'DEPOSIT',
    defaultMessage: 'Deposit',
  },
  PROMO_PAYOUT: {
    id: 'PROMO_PAYOUT',
    defaultMessage: 'Promo Payout',
  },
  MEMBER_LOYALTY_PAYOUT: {
    id: 'MEMBER_LOYALTY_PAYOUT',
    defaultMessage: 'Member Loyalty Payout',
  },
  AFFILIATE_COMMISSION: {
    id: 'AFFILIATE_COMMISSION',
    defaultMessage: 'Affiliate Commission',
  },
  REBATE_PAYOUT: {
    id: 'REBATE_PAYOUT',
    defaultMessage: 'Rebate Payout',
  },
  DEBIT_VENDOR_TRANSFER: {
    id: 'DEBIT_VENDOR_TRANSFER',
    defaultMessage: 'Debit Vendor Transfer',
  },
  EXTERNAL_PROGRAMME: {
    id: 'EXTERNAL_PROGRAMME',
    defaultMessage: 'External Programme',
  },
});

export const newBalanceTypes = {
  REMOVE: [
    {
      label: 'WITHDRAWAL',
      value: 'WITHDRAWAL',
    },
    {
      label: 'CREDIT_VENDOR_TRANSFER',
      value: 'CREDIT_VENDOR_TRANSFER',
    },
    {
      label: 'SYSTEM_DEBIT',
      value: 'SYSTEM_DEBIT',
    },

    {
      label: 'DORMANT_ADJUSTMENT',
      value: 'DORMANT_ADJUSTMENT',
    },

    {
      label: 'REMOVING_SE_BALANCE',
      value: 'REMOVING_SE_BALANCE',
    },
  ],
  ADD: [
    { label: 'PAYOUT', value: 'PAYOUT' },
    { label: 'DEPOSIT', value: 'DEPOSIT' },
    { label: 'EXTERNAL_PROGRAMME', value: 'EXTERNAL_PROGRAMME' },
    { label: 'DEBIT_VENDOR_TRANSFER', value: 'DEBIT_VENDOR_TRANSFER' },
    {
      label: 'SYSTEM_CREDIT',
      value: 'SYSTEM_CREDIT',
    },
  ],
};

export const PROMO_PAYOUT = 'PROMO_PAYOUT';
export const MANUAL_WITHDRAWAL = 'MANUAL_WITHDRAWAL';
export const MEMBER_LOYALTY_PAYOUT = 'MEMBER_LOYALTY_PAYOUT';
export const REBATE_PAYOUT = 'REBATE_PAYOUT';
export const AFFILIATE_COMMISION = 'AFFILIATE_COMMISSION';

export const turnoverShowTypes = [
  PROMO_PAYOUT,
  'DEPOSIT',
  'REBATE_COMMISSION',
  'MEMBER_LOYALTY_PAYOUT',
  'EXTERNAL_PROGRAMME',
  'SYSTEM_CREDIT',
];

export const actualShowTypes = ['DEPOSIT', 'WITHDRAWAL'];
export const StyledItem = styled(Item)`
  padding-bottom: 0 !important;
`;

// eslint-disable-next-line react/prop-types
export const FormItem = ({
  children,
  label,
  errors,
  touched,
  keyType,
}: {
  children: any;
  label: string;
  errors: Record<string, any>;
  touched: Record<string, any>;
  keyType: any;
}) => (
  <StyledItem
    style={{ paddingBottom: '0 !important' }}
    colon={false}
    label={<span className="fs-12">{label}</span>}
    validateStatus={errors[keyType] && touched[keyType] ? 'error' : ''}
    help={touched[keyType] ? errors[keyType] : null}
  >
    {children}
  </StyledItem>
);
export const renderOptsBasedOnAction = (
  actionValue: string,
  validationType: Record<string, any>,
  translate: any,
  role?: string | null,
  permissions?: Array<string>,
  isSingleManualWithdrawal?: boolean
) => {
  const {
    ALLOWED_WITHDRAWAL,
    ALLOWED_MANUAL_WITHDRAWAL,
    ALLOWED_CREDIT_TRANSFER,
    ALLOWED_SYSTEM_DEBIT,
  } = collectPermissions(
    role,
    permissions,
    ['WITHDRAWAL', 'MANUAL_WITHDRAWAL', 'CREDIT_TRANSFER', 'SYSTEM_DEBIT'],
    `${ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT}:EDIT_BALANCE:REMOVE_BALANCE`
  );

  const {
    ALLOWED_PAYOUT,
    ALLOWED_DEPOSIT,
    ALLOWED_EXTERNAL_PROGRAMME,
    ALLOWED_DEBIT_TRANSFER,
    ALLOWED_SYSTEM_CREDIT,
  } = collectPermissions(
    role,
    permissions,
    [
      'PAYOUT',
      'DEPOSIT',
      'EXTERNAL_PROGRAMME',
      'DEBIT_TRANSFER',
      'SYSTEM_CREDIT',
    ],
    `${ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT}:EDIT_BALANCE:ADD_BALANCE`
  );

  const balanceTypes = {
    REMOVE: [
      {
        label: 'WITHDRAWAL',
        value: 'WITHDRAWAL',
        allowed: isSingleManualWithdrawal && ALLOWED_WITHDRAWAL,
      },
      ...(!isRow && isSingleManualWithdrawal
        ? [
            {
              label: 'MANUAL_WITHDRAWAL',
              value: 'MANUAL_WITHDRAWAL',
              allowed: ALLOWED_MANUAL_WITHDRAWAL,
            },
          ]
        : []),
      {
        label: 'CREDIT_VENDOR_TRANSFER',
        value: 'CREDIT_VENDOR_TRANSFER',
        allowed: isSingleManualWithdrawal && ALLOWED_CREDIT_TRANSFER,
      },
      {
        label: 'SYSTEM_DEBIT',
        value: 'SYSTEM_DEBIT',
        allowed: ALLOWED_SYSTEM_DEBIT,
      },
    ],
    ADD: [
      { label: 'PAYOUT', value: 'PAYOUT', allowed: ALLOWED_PAYOUT },
      {
        label: 'DEPOSIT',
        value: 'DEPOSIT',
        allowed: isSingleManualWithdrawal && ALLOWED_DEPOSIT,
      },
      {
        label: 'EXTERNAL_PROGRAMME',
        value: 'EXTERNAL_PROGRAMME',
        allowed: isSingleManualWithdrawal && ALLOWED_EXTERNAL_PROGRAMME,
      },
      {
        label: 'DEBIT_VENDOR_TRANSFER',
        value: 'DEBIT_VENDOR_TRANSFER',
        allowed: isSingleManualWithdrawal && ALLOWED_DEBIT_TRANSFER,
      },
      {
        label: 'SYSTEM_CREDIT',
        value: 'SYSTEM_CREDIT',
        allowed: ALLOWED_SYSTEM_CREDIT,
      },
    ],
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isNewRemoveBalanceListEnabled = useFeatureIsOn(
    'remove-balance-dropdown-list'
  );

  const options = isNewRemoveBalanceListEnabled
    ? newBalanceTypes[actionValue]
    : balanceTypes[actionValue];

  const disableByValidationType = (value: string) => {
    if (!validationType) {
      return false;
    }
    const disableRebates = value === REBATE_PAYOUT && validationType.noRebate;
    const disableAffiliate =
      value === AFFILIATE_COMMISION && validationType.notAffiliate;

    return disableAffiliate || disableRebates;
  };

  return options.map((option: Record<string, any>, idx: number) => (
    <Select.Option
      aria-label="balance-type-options"
      key={idx}
      value={option.value}
      disabled={disableByValidationType(option.value) || !option.allowed}
    >
      <Tooltip
        placement="right"
        title={
          disableByValidationType(option.value) &&
          'Need Permission on Promo Listing (List)'
        }
      >
        {translate(messages[option.label])}
      </Tooltip>
    </Select.Option>
  ));
};
