import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input } from 'antd';
import { Formik } from 'formik';
import randomstring from 'randomstring';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import useTranslate from 'utils/useTranslate';
import { StyledForm, StyledPassword } from './styles';

const messages = defineMessages({
  'withdrawal-password-6-digits-error.text': {
    id: 'withdrawal-password-6-digits-error.text',
    defaultMessage: 'Withdrawal password must be 6 digit numbers',
  },
  'withdrawal-password-required-error.text': {
    id: 'withdrawal-password-required-error.text',
    defaultMessage: 'Withdrawal password is required',
  },
});

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (e: any) => void;
  onClose: () => void;
  isLoading: boolean;
};

const WithdrawalPasswordChangeForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: Props) => {
  const translate = useTranslate();

  const validationSchema = yup.object().shape({
    withdrawalPassword: yup
      .string()
      .matches(
        /^[0-9]{6}$/,
        translate(messages['withdrawal-password-6-digits-error.text'])
      )
      .required(translate(messages['withdrawal-password-required-error.text'])),
  });
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        touched,
      }) => {
        const handleGenerate = () => {
          setFieldValue(
            'withdrawalPassword',
            randomstring.generate({ length: 6, charset: 'numeric' })
          );
        };
        return (
          <StyledForm onSubmit={handleSubmit}>
            <div className="p-3">
              <div>
                <FormattedMessage
                  id="username.text"
                  defaultMessage="Username"
                />
              </div>
              <div className="mb-2">
                <Input
                  disabled
                  defaultValue={values.username}
                  aria-label="username"
                  name="username"
                />
              </div>
              <div>
                <FormattedMessage
                  id="password.text"
                  defaultMessage="Password"
                />
              </div>
              <Form.Item
                wrapperCol={{ span: 24 }}
                validateStatus={
                  errors.withdrawalPassword && touched.withdrawalPassword
                    ? 'error'
                    : ''
                }
                help={
                  touched.withdrawalPassword ? errors.withdrawalPassword : null
                }
              >
                <StyledPassword>
                  <Input.Password
                    data-testid="withdrawalPassword"
                    name="withdrawalPassword"
                    value={values.withdrawalPassword}
                    onChange={handleChange}
                  />
                  <Button type="primary" onClick={handleGenerate}>
                    <FormattedMessage
                      id="generate.text"
                      defaultMessage="Generate"
                    />
                  </Button>
                </StyledPassword>
              </Form.Item>
            </div>
            <div className="bt-1 p-3 text-right">
              <Button className="mr-2" onClick={onClose} loading={isLoading}>
                <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                <FormattedMessage
                  id="save-changes.text"
                  defaultMessage="Save changes"
                />
              </Button>
            </div>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default WithdrawalPasswordChangeForm;
