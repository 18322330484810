import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import { getPollInterval } from 'utils/pollInterval';
import { usePermissions } from 'store/accountState';
import { checkPermission } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import { dateFilterMap } from 'utils/commonDateFilters';
import ReportsTimezoneProvider, {
  useReportsTimezone,
} from 'contexts/ReportsTimezone';
import TimezoneSelect from 'components/TimezoneSelect';
import CardStats from './components/CardStats';
import DateTimeFilter from './components/DateTimeFilter';
import LineStats from './components/LineStats';
import { StyledContent } from './styles';

const TodaysInstantFocusContent = () => {
  const { isTabActive } = useScreenTabV2();
  const { role, permissions } = usePermissions();
  const isAllowed = checkPermission(
    role,
    permissions,
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_LIST
  );

  const { currentTimezone } = useReportsTimezone();

  const defaultFilter = dateFilterMap.thisMonth(currentTimezone);

  const [filter, setFilters] = useState(defaultFilter);

  const pollInterval = getPollInterval({
    isRealtime: filter.menu === 'today',
    isActive: isTabActive('todays-instant-focus'),
  });

  return (
    <div className="bg-white" style={{ minHeight: 'calc(100vh - 80px)' }}>
      <StyledContent className="pt-3 styled-center">
        <DateTimeFilter
          handleDateRangePicker={setFilters}
          filter={filter}
          timezone={currentTimezone}
        />
        <div className="ml-4">
          <TimezoneSelect />
        </div>
      </StyledContent>
      <CardStats filter={filter} pollInterval={pollInterval} />
      {isAllowed && <LineStats filter={filter} pollInterval={pollInterval} />}
      <StyledContent className="pt-3" />
    </div>
  );
};

const TodaysInstantFocus = () => (
  <ReportsTimezoneProvider>
    <TodaysInstantFocusContent />
  </ReportsTimezoneProvider>
);

export default TodaysInstantFocus;
