import gql from 'graphql-tag';

export const GET_MEMBER_NOTES = gql`
  query MemberNotes(
    $id: ID!
    $first: Int
    $after: Binary
    $filter: MemberNotesFilterInput
  ) {
    member(id: $id) {
      id
      notes(first: $first, after: $after, filter: $filter) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            header
            note
            pinned
            creator {
              id
              username
              role
              email
              company
              qqId
              wechatId
              mobileNumber
              realName
              gender
            }
            dateTimeCreated
            dateTimeUpdated
          }
        }
      }
    }
  }
`;

export default {};
