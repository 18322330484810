/* eslint-disable import/named */
import gql from 'graphql-tag';
import { defineMessages } from 'react-intl';
import { localMessages as messages } from 'utils/profileSetting';

export const localMessages = defineMessages({
  PS_MANDATORY_INFO_AFFILIATES: {
    id: 'PS_MANDATORY_INFO_AFFILIATES',
    defaultMessage:
      'User must input this profile field before he can sign up as an affiliate.',
  },
  PS_OPTIONAL_INFO_AFFILIATES: {
    id: 'PS_OPTIONAL_INFO_AFFILIATES',
    defaultMessage:
      'Profile field will be displayed, but user input is optional before signing up as affiliate.',
  },
  PS_HIDDEN_INFO_AFFILIATES: {
    id: 'PS_HIDDEN_INFO_AFFILIATES',
    defaultMessage:
      'This field will not be displayed for the user when signing up as affiliate.',
  },
});

export const getColumns = (translate: any) => [
  {
    key: 'PROFILE_SETTING',
    translation: translate(messages.PROFILE_SETTING),
  },
  {
    key: 'MANDATORY',
    translation: translate(messages.MANDATORY),
    tooltip: translate(localMessages.PS_MANDATORY_INFO_AFFILIATES),
  },
  {
    key: 'OPTIONAL',
    translation: translate(messages.OPTIONAL),
    tooltip: translate(localMessages.PS_OPTIONAL_INFO_AFFILIATES),
  },
  {
    key: 'HIDDEN',
    translation: translate(messages.HIDDEN),
    tooltip: translate(localMessages.PS_HIDDEN_INFO_AFFILIATES),
  },
];

export const CONFIG = gql`
  {
    config {
      affiliateRequestFormFields {
        ... on BuiltInFormField {
          type
          required
          field
          updatable
          otpVerificationRequired
        }
      }
    }
  }
`;
