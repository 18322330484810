import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, message, Modal, Radio } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import esGet from 'utils/esGet';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const UPDATE_REALITY_CHECK = gql`
  mutation UpdateConfig($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

const REALITY_CHECK = gql`
  query {
    config {
      realityCheckTime
    }
  }
`;

const RealityCheck = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selected, setSelected] = useState(900_000);

  useQuery(REALITY_CHECK, {
    onCompleted: (resp) => {
      const realityCheck = esGet(resp?.config?.realityCheckTime, 900_000);
      setSelected(realityCheck);
    },
  });
  const { context } = useOperatorHeader();
  const [updateRealityCheck, { loading }] = useMutation(UPDATE_REALITY_CHECK, {
    context,
  });
  const onUpdateRealityCheck = () => {
    updateRealityCheck({
      variables: {
        input: {
          realityCheckTime: selected,
        },
      },
    })
      .then(() => {
        message.success('Reality check time updated');
      })
      .finally(() => {
        setIsModalVisible(false);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    onUpdateRealityCheck();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div>
      <h3>Manage Reality Check</h3>
      <Button onClick={showModal}>Configure Reality Check Time</Button>

      <Modal
        title="Change Reality Check Time"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ loading }}
        cancelButtonProps={{ loading }}
      >
        <Radio.Group
          onChange={(e) => setSelected(e.target.value)}
          value={selected}
        >
          <Radio value={0}>Off</Radio>
          <Radio value={900_000}>15 mins</Radio>
          <Radio value={1_800_000}>30 mins</Radio>
          <Radio value={2_700_000}>45 mins</Radio>
          <Radio value={3_600_000}>60 mins</Radio>
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default RealityCheck;
