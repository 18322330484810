import gql from 'graphql-tag';

export const GET_IP_SUMMARY_REPORT_IDS = gql`
  query IP_ADDRESS_SUMARRY_REPORT_IDS(
    $filter: IpAddressSummaryReportItemsFilterInput
    $partialFilter: IpAddressSummaryReportItemsFilterInput
  ) {
    ipAddressSummaryReport(filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: ipAddressSummaryReport(filter: $partialFilter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const GET_IP_SUMMARY_REPORTS = gql`
  query IP_ADDRESS_SUMARRY_REPORT(
    $first: Int
    $after: Binary
    $filter: IpAddressSummaryReportItemsFilterInput
    $language: Language
  ) {
    ipAddressSummaryReport(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          ipAddress
          ipAddressLocation {
            city(language: $language)
            country(language: $language)
            state(language: $language)
          }
          lastUsedDateTime
          membersCount
        }
      }
    }
  }
`;

export const GET_IPADDRESS = gql`
  query FilterIASIpAddress(
    $after: Binary
    $filter: IpAddressSummaryReportItemsFilterInput
  ) {
    ipAddressSummaryReport(first: 10, after: $after, filter: $filter) {
      edges {
        node {
          id
          ipAddress
        }
      }
    }
  }
`;
