/* Disable these rules to allow ImmerJS. */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import Storage from 'constants/storage';
import { v4 as uuidv4 } from 'uuid';
import { TabIdSA } from 'SuperAdminMain/constants/route';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useCallback, useState } from 'react';
import { AddTab, TabId } from './type';

export type Tab = {
  key: string; // uuid generated
  id: TabId; // TabId
  update: boolean;
  isActive: boolean;
  memberId?: string | null;
  state: {
    [x: string]: any;
  };
};

export type ScreenTabState = {
  tabs: Tab[];
  activeKey: string;
};

type UpdateTab =
  | { [x: string]: any }
  | { id: string; updates: { [x: string]: any } };

const SCREEN_TABS: string = `${Storage.SA_SCREEN_TAB_CONFIG}_NEW`;

// Grab session storage tabs if existing.
const storageState: ScreenTabState = JSON.parse(
  sessionStorage.getItem(SCREEN_TABS) || '{}'
);

// Use default initial state if nothing on storage.
const initialKey = uuidv4();

type State = {
  tabs: Array<Tab>;
  activeKey: TabId;
};

const initialState: State = {
  tabs: [
    {
      key: initialKey,
      id: 'superadmin-all-client',
      isActive: true,
      update: false,
      state: {},
    },
  ],
  activeKey: initialKey,
};

// Sets the state, actions, and reducers.
const screenTabSlice = createSlice({
  name: SCREEN_TABS,
  initialState: Object.keys(storageState).length ? storageState : initialState,
  // Note: Reducer can directly "mutate" the state because of ImmerJS.
  reducers: {
    addTab: (state, { payload }: PayloadAction<AddTab>) => {
      const { id, memberId = null, state: tabState } = payload;
      const tabIndex = state.tabs.findIndex((tab: Tab) => tab.id === id);
      let found = false;

      // If exist, set to that tab and update properies.
      if (tabIndex !== -1) {
        const prevTabIndex = state.tabs.findIndex(
          (tab: Tab) => tab.key === state.activeKey
        );

        if (prevTabIndex !== -1 && state.tabs[prevTabIndex].id !== id)
          state.tabs[prevTabIndex].isActive = false;

        if (id !== TabIdSA.CLIENT_PROFILE) {
          state.tabs[tabIndex] = {
            ...state.tabs[tabIndex],
            state: {
              ...tabState,
            },
            update: true,
            isActive: true,
          };

          found = true;
          state.activeKey = state.tabs[tabIndex].key;
        } else {
          const memberKey = state.tabs.findIndex(
            (tab: Tab) => tab.memberId === memberId
          );

          if (memberKey !== -1) {
            found = true;
            state.tabs[memberKey].isActive = true;
            state.activeKey = state.tabs[memberKey].key;
          }
        }
      }

      // Create new tab if not found.
      if (!found) {
        const key = uuidv4();
        const newTab: Tab = {
          key,
          id,
          update: false,
          isActive: true,
          state: {
            ...tabState,
          },
        };

        const prevTab = state.tabs.find(
          (tab: Tab) => tab.key === state.activeKey
        );

        if (prevTab) prevTab.isActive = false;
        if (memberId) newTab.memberId = memberId;
        state.tabs.push(newTab);
        state.activeKey = key;
      }

      sessionStorage.setItem(SCREEN_TABS, JSON.stringify(state));
    },
    removeTab: (state, { payload: key }: PayloadAction<string>) => {
      const tabIndex = state.tabs.findIndex((tab: Tab) => tab.key === key);

      if (tabIndex !== -1) {
        state.tabs.splice(tabIndex, 1);

        if (state.activeKey === key) {
          if (state.tabs[tabIndex]) {
            // Retain tab position if has tab on right.
            state.activeKey = state.tabs[tabIndex].key;
            state.tabs[tabIndex].isActive = true;
          } else if (state.tabs[tabIndex - 1]) {
            // Go to the left existing tab if right most tab closed.
            state.activeKey = state.tabs[tabIndex - 1].key;
            state.tabs[tabIndex - 1].isActive = true;
          } else {
            // All tabs closed, no more active tab.
            state.activeKey = '';
          }
        }
      }

      sessionStorage.setItem(SCREEN_TABS, JSON.stringify(state));
    },
    updateTab: (state, { payload }: PayloadAction<UpdateTab>) => {
      const { ...rest } = payload;
      const tabIndex = state.tabs.findIndex(
        (tab: Tab) => tab.id === payload.id
      );

      if (tabIndex !== -1) {
        state.tabs.splice(tabIndex, 1, {
          ...state.tabs[tabIndex],
          state: {
            ...state.tabs[tabIndex].state,
            ...payload.updates,
            ...rest,
          },
          update: true,
        });

        sessionStorage.setItem(SCREEN_TABS, JSON.stringify(state));
      }
    },
    useUpdateTab: (state, { payload: id }: PayloadAction<string>) => {
      const tabIndex = state.tabs.findIndex((tab: Tab) => tab.id === id);
      state.tabs.splice(tabIndex, 1, {
        ...state.tabs[tabIndex],
        update: false,
      });
    },
    setActiveTab: (state, { payload: key }: PayloadAction<string>) => {
      const nextTabIndex = state.tabs.findIndex((tab: Tab) => tab.key === key);
      const prevTabIndex = state.tabs.findIndex(
        (tab: Tab) => tab.key === state.activeKey
      );

      state.tabs[nextTabIndex].isActive = true;
      state.tabs[prevTabIndex].isActive = false;
      state.activeKey = key;
      sessionStorage.setItem(SCREEN_TABS, JSON.stringify(state));
    },
    clearTabState: (
      state,
      { payload: tabId }: PayloadAction<string | null>
    ) => {
      const tabIndex: number = state.tabs.findIndex(
        (item: Tab) => item.id === tabId
      );

      if (tabIndex !== -1) {
        state.tabs[tabIndex].state = {};
        state.tabs[tabIndex].update = true;
        sessionStorage.setItem(SCREEN_TABS, JSON.stringify(state));
      }
    },
    resetTabs: () => initialState,
  },
});

// I'm not including this in the useScreenTabV2 for separation of concerns.
// These actions are only for <ScreenTabNew /> and <ProfileDropDown />.
export const { resetTabs, removeTab, setActiveTab } = screenTabSlice.actions;

const selectActiveTab = createSelector(
  ({ superAdminScreenTab }: { superAdminScreenTab: ScreenTabState }) =>
    superAdminScreenTab.tabs.filter(
      (tab: Tab) => tab.key === superAdminScreenTab.activeKey
    )[0],
  (tab) => tab
);

// const selectMember = createSelector(
//   (superAdminScreenTab: ScreenTabState, memberId: string) =>
//     superAdminScreenTab.tabs
//       .filter((tab: Tab) => tab.id === TabId.MEMBER_DETAIL)
//       .find((memberTab: Tab) => memberTab.memberId === memberId),
//   member => member
// );

const selectTab = createSelector(
  (superAdminScreenTab: ScreenTabState, id: string | null) =>
    superAdminScreenTab.tabs.find((tab: Tab) => tab.id === id),
  (tab) => tab || ({} as Tab)
);

const selectTabState = createSelector(selectTab, ({ state }) => state);
const selectThisTab = createSelector(selectTab, (tab) =>
  tab ? tab.state : {}
);
const selectTabUpdate = createSelector(
  selectTab,
  (tab) => tab?.update || false
);

// This is only for the Redux store to import. Do not import this.
export const screenTabReducer = screenTabSlice.reducer;

/**
 * The custom screen tab hook to abstract Redux.
 * 90% of use cases, you would only need these functions:
 *    1. addTab   2. thisTab/getTab   3. updateTab   4. useUpdateTab
 */

export const useScreenTabSA = (tabId: string | null = null) => {
  const { actions } = screenTabSlice;
  const dispatch = useDispatch();
  const [, setRefresh] = useState(0);

  // This is literally the only use of useCallback; for proper referencing.
  // Memoize functions to prevent re-rendering in useMemo dependency.
  const addTab = useCallback(
    ({ id, memberId, state }: AddTab) => {
      dispatch(actions.addTab({ id, memberId, state }));
    },
    [actions, dispatch]
  );

  const updateTab = useCallback(
    ({ id = null, updates = {}, ...rest }: UpdateTab) => {
      if (tabId && !id) {
        dispatch(actions.updateTab({ id: tabId, ...rest }));
      } else {
        dispatch(actions.updateTab({ id, updates }));
      }
    },
    [actions, dispatch, tabId]
  );

  const clearTabState = useCallback(
    (id: string | null = null) => {
      dispatch(actions.clearTabState(id || tabId));
    },
    [actions, dispatch, tabId]
  );

  const update = useSelector(
    ({ superAdminScreenTab }: { superAdminScreenTab: ScreenTabState }) =>
      selectTabUpdate(superAdminScreenTab, tabId)
  );

  const thisTab = useSelector(
    ({ superAdminScreenTab }: { superAdminScreenTab: ScreenTabState }) =>
      selectThisTab(superAdminScreenTab, tabId)
  );

  const useUpdateTab = useCallback(
    (fn: () => any) => {
      if (update && tabId) {
        fn();
        dispatch(actions.useUpdateTab(tabId));
      }
    },
    [actions, dispatch, tabId, update]
  );

  // The follow 3 functions are memoized already by the selector.
  const useGetActiveTab = () => useSelector(selectActiveTab);

  const useGetTab = (id: string) =>
    useSelector(
      ({ superAdminScreenTab }: { superAdminScreenTab: ScreenTabState }) =>
        selectTabState(superAdminScreenTab, id)
    );

  // const useGetMember = (memberId: string) =>
  //   useSelector(({ superAdminScreenTab }: { superAdminScreenTab: ScreenTabState }) =>
  //     selectMember(superAdminScreenTab, memberId)
  //   );

  const useIsTabActive = (id: string): boolean =>
    useSelector(
      ({ superAdminScreenTab }: { superAdminScreenTab: ScreenTabState }) =>
        selectTab(superAdminScreenTab, id).isActive
    );

  const refreshTab = () => setRefresh(Math.random);

  return useMemo(
    () => ({
      thisTab,
      addTab,
      updateTab,
      refreshTab,
      clearTabState,
      useUpdateTab,
      getActiveTab: useGetActiveTab,
      isTabActive: useIsTabActive,
      getTab: useGetTab,
      // getMember: useGetMember,
    }),
    [thisTab, addTab, updateTab, clearTabState, useUpdateTab]
  );
};
