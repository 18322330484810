import React from 'react';
import { Button, Modal, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import messages from 'messages';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';

const { confirm } = Modal;

const DUPLICATE_VENDOR_GROUP = gql`
  mutation DuplicateVendorGroup($id: ID!) {
    duplicateVendorGroup(id: $id)
  }
`;

export const DuplicateVendorGroup = ({
  id,
  refetchFn,
}: {
  id: string;
  refetchFn: () => void;
}) => {
  const client = useApolloClient();
  const translate = useTranslate();

  const onDelete = () => {
    confirm({
      title: translate(messages.DUPLICATE_VENDOR_GROUP_TITLE),
      content: translate(
        messages.DUPLICATE_VENDOR_GROUP_CONFIRMATION_BODY_TEXT
      ),
      okText: translate(messages.YES),
      cancelText: translate(messages.NO),
      onOk: async () => {
        try {
          await client.mutate({
            mutation: DUPLICATE_VENDOR_GROUP,
            variables: {
              id,
            },
          });
          message.success(translate(messages.VENDOR_DUPLICATED_SUCCESSFULLY));
          refetchFn();
        } catch (e) {
          message.error(translate(messages['error-occurred.text']));
        }
      },
    });
  };

  return (
    <>
      <Button type="link" block onClick={onDelete}>
        <FormattedMessage id="duplicate.text" defaultMessage="Duplicate" />
      </Button>
    </>
  );
};
