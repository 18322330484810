import React, { Dispatch, SetStateAction } from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { DATE_FORMAT } from 'constants/date';

type Props = {
  filters: Record<string, any>;
  onFilterChange: Dispatch<SetStateAction<any>>;
};

const FilterConditions = ({ filters, onFilterChange }: Props) => {
  const handleRemoveFilter = (name: string, value: string, e: any) => {
    e.preventDefault();
    onFilterChange({
      ...filters,
      [name]: filters[name].filter((item: string) => item !== value),
    });
  };
  const handleRemoveDateFilter = (name: string) => {
    onFilterChange({
      ...filters,
      [name]: [],
    });
  };
  return (
    <div className="ml-3">
      <div className="mt-1">
        <FormattedMessage
          id="filter-conditions.text"
          defaultMessage="Filter Conditions"
        />
        <span className="mr-4" />
        {filters.usernames
          ? filters.usernames.map((item: string, key: number) => (
              <Tag
                key={key}
                closable
                onClose={(e) => handleRemoveFilter('usernames', item, e)}
              >
                {item}
              </Tag>
            ))
          : null}
        {filters.dateRange.length ? (
          <Tag closable onClose={() => handleRemoveDateFilter('dateRange')}>
            <FormattedMessage
              id="date-placed.text"
              defaultMessage="Date/Time Placed"
            />
            : {moment(filters.dateRange[0]).format(DATE_FORMAT)}~
            {moment(filters.dateRange[1]).format(DATE_FORMAT)}
          </Tag>
        ) : null}
        {filters.status
          ? filters.status.map((item: string, key: number) => (
              <Tag
                key={key}
                closable
                onClose={(e) => handleRemoveFilter('status', item, e)}
              >
                {item}
              </Tag>
            ))
          : null}
      </div>
    </div>
  );
};

export default FilterConditions;
