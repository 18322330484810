import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Tooltip } from 'antd';
import { DATE_TIME_FORMAT } from 'constants/date';
import { capitalize, startCase } from 'lodash';
import {
  APPROVE_MEMBER_DOCUMENT,
  confirmModal,
  DELETE_MEMBER_DOCUMENT,
  REJECT_MEMBER_DOCUMENT,
} from 'pages/components/NewMemberDetail/components/DocumentManagement/utils';
import { ViewDocumentModal } from 'pages/components/NewMemberDetail/components/DocumentManagement/ViewDocumentModal';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import React, { FC, useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import {
  MemberDocument,
  MemberDocumentStatus,
  MutationApproveMemberDocumentArgs,
  MutationRejectMemberDocumentArgs,
} from 'types/graphqlTypes';
import { formatDate } from 'utils/dateUtils';
import { useOperatorHeader } from 'utils/useOperatorHeader';

type Props = {
  memberDocument: MemberDocument;
  refetch: Function;
};

export const Document: FC<Props> = ({ memberDocument, refetch }) => {
  const [isViewDocument, setIsViewDocument] = useState(false);
  const { context } = useOperatorHeader();
  const [approveMemberDocument] = useMutation<
    boolean,
    MutationApproveMemberDocumentArgs
  >(APPROVE_MEMBER_DOCUMENT);

  const [rejectMemberDocument] = useMutation<
    boolean,
    MutationRejectMemberDocumentArgs
  >(REJECT_MEMBER_DOCUMENT);

  const [deleteMemberDocument] = useMutation<
    boolean,
    MutationRejectMemberDocumentArgs
  >(DELETE_MEMBER_DOCUMENT);

  const onConfirm = () => {
    confirmModal(
      async () => {
        await approveMemberDocument({
          variables: {
            id: memberDocument?.id,
          },
          context,
        });
        message.success('Document approved');
        refetch();
      },
      () => {},
      'approve'
    );
  };

  const onReject = () => {
    confirmModal(
      async () => {
        await rejectMemberDocument({
          variables: {
            id: memberDocument?.id,
          },
          context,
        });
        message.success('Document rejected');
        refetch();
      },
      () => {},
      'reject'
    );
  };

  const onDelete = () => {
    confirmModal(
      async () => {
        await deleteMemberDocument({
          variables: {
            id: memberDocument?.id,
          },
          context,
        });
        message.success('Document deleted');
        refetch();
      },
      () => {},
      'delete'
    );
  };

  const date = formatDate(memberDocument?.dateTimeCreated, DATE_TIME_FORMAT);
  const name = capitalize(startCase(memberDocument?.type));
  const isPending = memberDocument?.status === MemberDocumentStatus.Pending;

  const fontSize = {
    fontSize: '20px',
  };

  const {
    MEMBER_DOCUMENT_MANAGEMENT_PERMISSION,
  } = useNewMemberDetailPermissions();

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <Tooltip title="View Document">
          <ALink className="text-black" onClick={() => setIsViewDocument(true)}>
            <div className="d-flex align-items-center cursor-pointer">
              <FileTextOutlined className="mr-3" style={{ fontSize: '20px' }} />
              <div>
                <p className="fw-500 mb-0">{name}</p>
                <p className="mb-0 text-muted fs-12">Shared on {date}</p>
              </div>
            </div>
          </ALink>
        </Tooltip>
        <div className="d-flex">
          <div className="d-flex">
            {isPending && (
              <>
                <Tooltip title="Approve">
                  <CheckCircleOutlined
                    onClick={onConfirm}
                    className="text-success mr-3 cursor-pointer"
                    style={fontSize}
                    disabled={
                      !MEMBER_DOCUMENT_MANAGEMENT_PERMISSION.ALLOWED_APPROVE
                    }
                  />
                </Tooltip>

                <Tooltip title="Reject">
                  <CloseCircleOutlined
                    onClick={onReject}
                    className="text-danger cursor-pointer"
                    style={fontSize}
                    disabled={
                      !MEMBER_DOCUMENT_MANAGEMENT_PERMISSION.ALLOWED_REJECT
                    }
                  />
                </Tooltip>
              </>
            )}
            {!isPending &&
              (memberDocument?.status === MemberDocumentStatus.Approved ? (
                <span className="text-success">Approved</span>
              ) : (
                <span className="text-danger">Rejected</span>
              ))}
          </div>
          <Tooltip title="Delete" placement="rightTop" className="ml-2">
            <DeleteOutlined
              className="text-muted"
              onClick={onDelete}
              style={fontSize}
              disabled={!MEMBER_DOCUMENT_MANAGEMENT_PERMISSION.ALLOWED_DELETE}
            />
          </Tooltip>
        </div>
      </div>

      <ViewDocumentModal
        visible={isViewDocument}
        onClose={() => {
          setIsViewDocument(false);
          refetch();
        }}
        memberDocument={memberDocument}
      />
    </>
  );
};
