import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import coercedGet from 'utils/coercedGet';
import { useFormikContext } from 'formik';
import styled from 'styled-components';
import { Checkbox, Row, Col } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import useTranslate from 'utils/useTranslate';
import messages from '../messages';
import { StyledRootDiv } from './styles';

const StyledText = styled.span<any>`
  font-size: ${(props) => `${props.size}px`};
  font-weight: 500;
`;

const StyledDiv = styled.div`
  width: 300px;
`;

const RebatesTree = () => {
  const { values, setFieldValue } = useFormikContext() as Record<string, any>;

  const [checked, setChecked] = useState({
    all: {
      first: false,
      REBATES_REBATES_REBATE_GROUP: false,
      REBATES_REBATES_REBATE_GROUP_LIST: false,
    },
    indeterminate: {
      first: false,
      REBATES_REBATES_REBATE_GROUP: false,
    },
    currentList: {
      REBATES_REBATES_REBATE_GROUP_GRP: [],
    },
  });

  const defaultValues = Object.values(ALL_PERMISSIONS.ALL_REBATES);

  const levels = [
    ['first', 0],
    ['second', 2],
    ['third', 3],
  ];

  const options: Record<string, any> = levels.reduce(
    (acc, curr) => ({
      ...acc,
      [`${curr[0]}`]: Object.entries(ALL_PERMISSIONS.ALL_REBATES)
        .filter((e) => (e[1].match(/:/g) || []).length === curr[1])
        .map((e) => e[0]),
    }),
    {}
  );

  const handleFirstLevelChange = (e: any) => {
    const list = e.target.checked ? defaultValues : [];
    setFieldValue('permissions[ALL_REBATES]', list);
  };

  const handleThirdLevelChange = (e: any) => {
    const { name } = e.target;
    const targetPermissions = [...values.permissions.ALL_REBATES];

    const unlistedName = ALL_PERMISSIONS.ALL_REBATES[name.replace('_LIST', '')];

    const thirdLevelValues = defaultValues.filter(
      (v) => (v.match(/:/g) || []).length >= 3 && v.includes(unlistedName)
    );

    let list = [];

    if (e.target.checked) {
      list = [...targetPermissions, unlistedName, ...thirdLevelValues];
    } else {
      list = targetPermissions.filter(
        (p) => !thirdLevelValues.includes(p) && p !== unlistedName
      );
    }

    setFieldValue(
      'permissions[ALL_REBATES]',
      uniq(list.length >= 3 ? ['REBATES', ...list] : [])
    );
  };

  const handleOnChange = (list: any[], name: string) => {
    const stateName = name.replace('_GRP', '');

    const secondLevelPermissionLookup = ALL_PERMISSIONS.ALL_REBATES[stateName];
    const thirdLevelPermissionLookup =
      ALL_PERMISSIONS.ALL_REBATES[`${stateName}_LIST`];

    const thirdLevelPermissions = Object.values(
      ALL_PERMISSIONS.ALL_REBATES
    ).filter((p) => (p.match(/:/g) || []).length === 3);

    const targetPermissions = [...values.permissions.ALL_REBATES];

    const templatePermissions = thirdLevelPermissions.filter(
      (p) =>
        p.includes(secondLevelPermissionLookup) &&
        p !== secondLevelPermissionLookup &&
        p !== thirdLevelPermissionLookup
    );

    setFieldValue(
      'permissions[ALL_REBATES]',
      uniq([
        ...targetPermissions.filter((p) => !templatePermissions.includes(p)),
        'REBATES',
        secondLevelPermissionLookup,
        thirdLevelPermissionLookup,
        ...list,
      ])
    );
  };

  const availablePermissions = coercedGet(
    values,
    'permissions.ALL_REBATES',
    []
  );
  useEffect(() => {
    const permissionMatch =
      availablePermissions.length ===
      Object.values(ALL_PERMISSIONS.ALL_REBATES).length;

    const thirdLevelPermissions = availablePermissions.filter(
      (p: any) => (p.match(/:/g) || []).length === 3
    );

    const secondLevelKeys = [
      ['REBATES_REBATES_REBATE_GROUP', 'REBATES:REBATES:REBATE_GROUP', 6],
    ];

    const thirdLevelChecks: Record<string, any> = secondLevelKeys.reduce(
      (acc, curr) => ({
        ...acc,
        [`${curr[0]}`]:
          thirdLevelPermissions.filter((p: any) => p.includes(`${curr[1]}`))
            .length === curr[2],
      }),
      {}
    );

    const allChecks = {
      REBATES_REBATES_REBATE_GROUP:
        permissionMatch || thirdLevelChecks.REBATES_REBATES_REBATE_GROUP,
    };

    setChecked({
      all: {
        first: permissionMatch,
        REBATES_REBATES_REBATE_GROUP: allChecks.REBATES_REBATES_REBATE_GROUP,

        REBATES_REBATES_REBATE_GROUP_LIST:
          thirdLevelPermissions.includes('REBATES:REBATES:REBATE_GROUP:LIST') ||
          allChecks.REBATES_REBATES_REBATE_GROUP,
      },
      indeterminate: {
        first: availablePermissions.length && !permissionMatch,
        REBATES_REBATES_REBATE_GROUP:
          thirdLevelPermissions.filter((p: any) =>
            p.includes('REBATES:REBATES:REBATE_GROUP')
          ).length && !thirdLevelChecks.REBATES_REBATES_REBATE_GROUP,
      },
      currentList: {
        REBATES_REBATES_REBATE_GROUP_GRP: thirdLevelPermissions.filter(
          (p: any) =>
            p.includes('REBATES:REBATES:REBATE_GROUP') &&
            p !== 'REBATES:REBATES:REBATE_GROUP' &&
            p !== 'REBATES:REBATES:REBATE_GROUP:LIST'
        ),
      },
    });
  }, [availablePermissions]);
  const translate = useTranslate();
  return (
    <StyledRootDiv>
      <div style={{ width: 300 * options.second.length, paddingBottom: 100 }}>
        <Row>
          <Col span={24}>
            <Checkbox
              checked={checked.all.first}
              indeterminate={checked.indeterminate.first}
              onChange={handleFirstLevelChange}
            >
              <StyledText size={16}>
                {translate(messages[ALL_PERMISSIONS.ALL_REBATES.REBATES])}
              </StyledText>
            </Checkbox>
          </Col>
        </Row>
        <div className="w-100">
          <div className="mt-4 d-flex ml-3">
            {options.second.map((s: any) => (
              <StyledDiv>
                <Checkbox
                  key={`${s}_LIST`}
                  name={`${s}_LIST`}
                  checked={checked.all[`${s}_LIST`]}
                  onChange={handleThirdLevelChange}
                >
                  <StyledText size={14}>{translate(messages.LIST)}</StyledText>
                </Checkbox>
                <Checkbox.Group
                  className="w-100 ml-2"
                  onChange={(v) => handleOnChange(v, `${s}_GRP`)}
                  value={checked.currentList[`${s}_GRP`]}
                >
                  <div className="ml-4">
                    {options.third
                      .filter(
                        (t: any, idx: number) => t.includes(s) && idx !== 0
                      )
                      .map((t: any) => (
                        <StyledDiv className="mt-2">
                          <Checkbox
                            key={ALL_PERMISSIONS.ALL_REBATES[t]}
                            value={ALL_PERMISSIONS.ALL_REBATES[t]}
                          >
                            <StyledText size={14}>
                              {translate(messages[t.replace(`${s}_`, '')])}
                            </StyledText>
                          </Checkbox>
                        </StyledDiv>
                      ))}
                  </div>
                </Checkbox.Group>
              </StyledDiv>
            ))}
          </div>
        </div>
      </div>
    </StyledRootDiv>
  );
};

export default RebatesTree;
