import { useState, useEffect } from 'react';
import useFilter from 'contexts/Filter';
import { Page, PageInfo } from 'interfaces/user.interface';

export const useTablePagination = (initialState: Page) => {
  const [page, setPage] = useState(initialState);
  const { filter } = useFilter();

  const setToFirstPage = () => {
    setPage({
      ...page,
      after: undefined,
      savedCursor: [undefined] as any,
      currentPage: 0,
    });
  };

  const handleNext = (pageInfo: PageInfo) => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after: after || null,
      currentPage: prevPage,
    });
  };

  const sort = (order: 'ascend' | 'descend') => {
    setPage((prev) => ({
      ...prev,
      sort: order,
    }));
  };

  useEffect(() => {
    if (page.currentPage !== 0) {
      setToFirstPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return { page, setPage, setToFirstPage, handleNext, handlePrev, sort };
};
