import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import {
  Layout,
  Breadcrumb,
  Typography,
  Col,
  Row,
  Divider,
  Button,
  Spin,
} from 'antd';
import { ALink } from 'components/ALink/ALink';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import removeNestedNulls from 'utils/removeNestedNulls';
import compose from 'utils/compose';

import { StyledCenter } from 'pages/styles';

import {
  useMemberLoyaltyProgrammeContext,
  allTypes as memberLoyaltyTypes,
} from '../../context';
import {
  allTypes as createProgramTypes,
  useCreateLoyaltyProgramContext,
} from '../CreateProgramme/context';
import { MEMBER_LOYALTY_PROGRAMME } from './queries';
import TierDetails from './components/TierDetails';
import { processDepositOnlyCriteria, processUpgradePayout } from '../../utils';

function ProgrammeDetails({ toggleOverlay }: { toggleOverlay: () => void }) {
  const { role, permissions } = usePermissions();
  const { ALLOWED_EDIT } = collectPermissions(
    role,
    permissions,
    ['EDIT'],
    ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  );

  const [
    createProgramState,
    createProgrammeDispatch,
  ] = useCreateLoyaltyProgramContext() as any;

  const { currentProgramId } = createProgramState;

  const [, memberLoyaltyDispatch] = useMemberLoyaltyProgrammeContext();

  const { loading, data = {} } = useQuery(MEMBER_LOYALTY_PROGRAMME, {
    variables: {
      id: currentProgramId,
    },
  });

  const { memberLoyaltyProgramme = {} } = data as Record<string, any>;
  const processedProgramme = loading
    ? {}
    : compose(
        processUpgradePayout,
        processDepositOnlyCriteria,
        removeNestedNulls
      )(memberLoyaltyProgramme);

  const {
    membersCount,
    name,
    description,
    id,
    isDefault,
    validityStartDate,
    validityEndDate,
    automaticMemberLoyaltyLevelUpgrade,
    automaticMemberLoyaltyLevelDowngrade,
    evaluationPeriod,
  } = processedProgramme;

  const levels = loading ? [] : [...processedProgramme.levels].reverse();

  React.useEffect(() => {
    createProgrammeDispatch({
      type: createProgramTypes.SET_ACTIVE_PROGRAMME,
      payload: processedProgramme,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleEditProgram = () => {
    createProgrammeDispatch({
      type: createProgramTypes.SET_CURRENT_PROGRAM_ID,
      payload: id,
    });

    createProgrammeDispatch({
      type: createProgramTypes.SET_TIERS,
      payload: levels,
    });

    toggleOverlay();

    createProgrammeDispatch({
      type: createProgramTypes.SET_ACTIVE_SCREEN,
      payload: createProgramTypes.PROGRAM_SETTINGS,
    });
  };

  return loading ? (
    <StyledCenter>
      <Spin />
    </StyledCenter>
  ) : (
    <Root className="bg-white">
      <Layout.Content className="p-4">
        <Breadcrumb className="mb-3">
          <Breadcrumb.Item>
            <FormattedMessage
              id="member-loyalty-members.text"
              defaultMessage="Members"
            />
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <ALink
              onClick={() => {
                createProgrammeDispatch({
                  type: createProgramTypes.SET_CURRENT_PROGRAM_ID,
                  payload: null,
                });
                memberLoyaltyDispatch({
                  type: memberLoyaltyTypes.SET_ACTIVE_SCREEN,
                  payload: memberLoyaltyTypes.PROGRAM_TABLE_LISTING,
                });
              }}
            >
              <FormattedMessage
                id="member-loyalty-programmes-listing.text"
                defaultMessage="Programmes Listing"
              />
            </ALink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <ALink onClick={(e: any) => e}>{name}</ALink>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={32}>
          <Col span={12} className="gutter-row">
            <div className="gutter-box">
              <Typography.Title level={2}>{name}</Typography.Title>
              <Row>
                <Col span={12}>
                  <Typography.Text strong>
                    {' '}
                    <FormattedMessage id="tiers.text" defaultMessage="Tiers" />
                  </Typography.Text>
                  <Typography.Text
                    className="ml-3"
                    style={{ color: '#1890ff' }}
                  >
                    {levels ? levels.length : 0}
                  </Typography.Text>
                </Col>

                <Col span={12}>
                  <Typography.Text strong>
                    <FormattedMessage
                      id="member-loyalty.defaut-program.text"
                      defaultMessage="Default Programme"
                    />
                  </Typography.Text>
                  <Typography.Text className="ml-3">
                    {isDefault ? (
                      <FormattedMessage id="yes.text" defaultMessage="Yes" />
                    ) : (
                      <FormattedMessage id="no.text" defaultMessage="No" />
                    )}
                  </Typography.Text>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col span={12}>
                  <Typography.Text strong>
                    <FormattedMessage
                      id="members.text"
                      defaultMessage="Members"
                    />
                  </Typography.Text>
                  <Typography.Text
                    className="ml-3"
                    style={{ color: '#1890ff' }}
                  >
                    {membersCount}
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text strong>
                    <FormattedMessage
                      id="member-loyalty.effective-date-range.text"
                      defaultMessage="Effective Date Range"
                    />
                  </Typography.Text>
                  <br />
                  <Typography.Text className="ml-3">
                    <span>
                      {moment(validityStartDate).format('YYYY-DD-MM')}
                    </span>
                    <span className="px-1">-</span>
                    <span>{moment(validityEndDate).format('YYYY-DD-MM')}</span>
                  </Typography.Text>
                </Col>
              </Row>
              <Typography.Paragraph strong>
                <FormattedMessage
                  id="description.text"
                  defaultMessage="Description"
                />
              </Typography.Paragraph>
              <Typography.Paragraph>{description}</Typography.Paragraph>
              {/* collapse */}
              {levels &&
                levels.length > 0 &&
                levels.map((level) => (
                  <TierDetails
                    handleEditProgram={handleEditProgram}
                    tier={level}
                  />
                ))}
            </div>
          </Col>
          <Col span={12} className="gutter-row">
            <div className="gutter-box">
              <Typography.Title level={4}>
                <FormattedMessage
                  id="member-loyalty-programmes-settings.text"
                  defaultMessage="Programme Settings"
                />
              </Typography.Title>

              <Row className="mb-2">
                <Col span={12}>
                  <Typography.Paragraph strong type="secondary">
                    <FormattedMessage
                      id="member-loyalty-period.text"
                      defaultMessage="Period"
                    />
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  <Typography.Paragraph strong className="text-right">
                    {evaluationPeriod}
                  </Typography.Paragraph>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col span={12}>
                  <Typography.Paragraph strong type="secondary">
                    <FormattedMessage
                      id="member-loyalty-automatic-upgrade.text"
                      defaultMessage="Automatic Upgrade"
                    />
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  <Typography.Paragraph className="text-right">
                    {automaticMemberLoyaltyLevelUpgrade ? (
                      <FormattedMessage
                        id="member-loyalty.yes.text"
                        defaultMessage="Yes"
                      />
                    ) : (
                      <FormattedMessage
                        id="member-loyalty.no.text"
                        defaultMessage="No"
                      />
                    )}
                  </Typography.Paragraph>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={12}>
                  <Typography.Paragraph strong type="secondary">
                    <FormattedMessage
                      id="member-loyalty.automatic-dowgrade.text"
                      defaultMessage="Automatic Downgrade"
                    />
                  </Typography.Paragraph>
                </Col>
                <Col span={12}>
                  <Typography.Paragraph className="text-right">
                    {automaticMemberLoyaltyLevelDowngrade ? (
                      <FormattedMessage
                        id="member-loyalty.yes.text"
                        defaultMessage="Yes"
                      />
                    ) : (
                      <FormattedMessage
                        id="member-loyalty.no.text"
                        defaultMessage="No"
                      />
                    )}
                  </Typography.Paragraph>
                </Col>
              </Row>
              <Divider />
              {ALLOWED_EDIT && (
                <Button type="primary" onClick={handleEditProgram}>
                  <FormattedMessage
                    id="edit-programme.text"
                    defaultMessage="Edit Programme"
                  />
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Layout.Content>
    </Root>
  );
}

const Root = styled.div`
  .ant-layout-content {
    max-width: 1024px;
    margin: 0 auto;
    font-size: 16px;
  }
  .ant-typography {
    margin-bottom: 0.5rem;
  }
`;

export default ProgrammeDetails;
