import React from 'react';
import { MemberLoyaltyLevel } from 'types/graphqlTypes';
import { TooltipProps } from 'antd/lib/tooltip';
import coercedGet from 'utils/coercedGet';
import { Tooltip } from 'antd';
import StyledMemberLoyalty from 'styles/StyledMemberLoyalty';

type MemberLoyaltyTagListProps = {
  loyaltyLevels: Array<Partial<MemberLoyaltyLevel>>;
  toolTipProps?: Partial<TooltipProps>;
};

const MemberLoyaltyTagList = ({
  loyaltyLevels,
  toolTipProps = {},
}: MemberLoyaltyTagListProps) => {
  const renderFullNames = (tier: Partial<MemberLoyaltyLevel>, indx: number) => {
    const vipName = coercedGet(tier, 'name', '');
    const programmeName = coercedGet(tier, 'programme.name', '');
    return (
      <div key={indx}>
        {programmeName} - {vipName}
      </div>
    );
  };

  const renderTiers = (tier: Partial<MemberLoyaltyLevel>, indx: number) => {
    const color = coercedGet(tier, 'color', '#6d6d6d');
    const vipName = coercedGet(tier, 'name', '');

    return (
      <StyledMemberLoyalty
        key={indx}
        color={color}
        data-testid="loyalty-button"
      >
        <button className="loyalty-button" type="button">
          {vipName}
        </button>
      </StyledMemberLoyalty>
    );
  };

  return (
    <Tooltip
      placement="right"
      {...toolTipProps}
      arrowPointAtCenter
      title={loyaltyLevels.map(renderFullNames)}
    >
      {loyaltyLevels.map(renderTiers)}
    </Tooltip>
  );
};

export default MemberLoyaltyTagList;
