// tslint-disable no-use-before-define
import React from 'react';
import { Empty, Table } from 'antd';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import RemarksBubble from 'components/RemarksBubble';
import moment from 'moment';
import {
  types,
  messages as interactionLogMessages,
} from 'constants/memberInteractionLog';
import {
  MemberInteractionLogConnectionEdge,
  MemberInteractionLog,
  Scalars,
} from 'types/graphqlTypes';
import { getInteractionModuleField } from 'utils/memberInteractionLog';
import ExternalModule from 'pages/components/MemberInteractionLogReport/components/ExternalModule';
import { DATE_TIME_FORMAT } from 'constants/date';

type Props = {
  dataSource: [MemberInteractionLogConnectionEdge];
};

const MemberInteractionTable: React.FC<Props> = ({ dataSource }) => {
  const translate = useTranslate();

  const columns = [
    {
      key: 'dateTimeCreated',
      title: translate(messages['date-time.text']),
      dataIndex: 'dateTimeCreated',
      render: (dateTimeCreated: Scalars['DateTime']) =>
        moment(dateTimeCreated).format(DATE_TIME_FORMAT),
    },
    {
      key: 'module',
      title: translate(messages.MODULE),
      width: 120,
      dataIndex: 'type',
      render: (type: string) =>
        types[`${type}`]
          ? translate(interactionLogMessages[`${types[`${type}`]?.module}`])
          : type,
    },
    {
      key: 'action',
      title: translate(messages.ACTION),
      width: 120,
      dataIndex: 'type',
      render: (type: string) =>
        types[`${type}`]
          ? translate(interactionLogMessages[`${types[`${type}`]?.action}`])
          : type,
    },
    {
      title: translate(messages.EXTERNAL_MODULE_ID),
      key: 'externalModuleId',
      width: 100,
      render: (record: MemberInteractionLog) => <ExternalModule log={record} />,
    },
    {
      title: translate(messages.OPERATOR_REMARK),
      key: 'operatorRemarks',
      width: 100,
      render: (record: Partial<MemberInteractionLog>) => {
        const remarks = getInteractionModuleField(record, 'remarks');
        return remarks ? (
          <RemarksBubble remarks={remarks} date="" toolTipId="" />
        ) : (
          '-'
        );
      },
    },
  ];

  if (!dataSource.length) {
    return <Empty className="py-4" />;
  }

  const data = dataSource
    ? dataSource.map(({ node }) => ({ ...node, key: node.id }))
    : [];

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="id"
    />
  );
};

export default MemberInteractionTable;
