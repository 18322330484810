import { useAvailableCurrencies } from 'hooks/useAvailableCurrencies';
import { useConfig } from 'hooks/useConfig';
import { merge } from 'lodash';
import numeral from 'numeral';
import React from 'react';
import isRow from 'utils/isRow';

const UK_CURRENCIES = {
  GBP: '£',
  USD: '$',
  EUR: '€',
};

export default () => {
  const {
    addCurrency: configCurrency,
    baseCurrency: baseCurrencyCode,
  } = useConfig();

  const {
    availableCurrencies,
    loadAvailableCurrencies,
    loading,
  } = useAvailableCurrencies();

  React.useEffect(() => {
    loadAvailableCurrencies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currencyMap = merge(
    availableCurrencies.reduce((acc, currency) => {
      acc[currency.code] = currency.symbol;
      return acc;
    }, {}),
    UK_CURRENCIES
  );

  const addCurrency = (currency: string, value: string | number) => {
    if (!loading) {
      if (isRow) {
        return `${currencyMap[currency]} ${numeral(value).format(
          '0.00',
          (n) => n
        )}`;
      }
    }
    return configCurrency(value, 2, Math.floor);
  };

  const convertToBaseCurrency = (value: number, exchangeRate: number) => {
    const convertedValue = exchangeRate * value;

    return addCurrency(baseCurrencyCode!, convertedValue);
  };

  return { addCurrency, convertToBaseCurrency, baseCurrencyCode };
};
