import gql from 'graphql-tag';

export const MARQUEE_MESSAGES = gql`
  query MarqueeMessages(
    $filter: MarqueeMessagesFilterInput
    $first: Int
    $after: Binary
  ) {
    marqueeMessages(filter: $filter, first: $first, after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          title
          message
          snippet
          enabled
          rank
        }
      }
    }
  }
`;
