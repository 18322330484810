import styled from 'styled-components';

export default styled.div`
  .loyalty-button {
    font-size: 12px;
    border-radius: 100px;
    outline: none;
    background-color: ${(props) => props.color};
    border: none;
    color: white;
    padding: 0 10px;
    cursor: default;
    margin: 2.5px 0;
  }
`;
