import { CloseSquareOutlined, LayoutOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/react-hooks';
import { Button } from 'antd';
import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { showPermissionError } from 'components/Navbar/Navbar';
import ALL_PERMISSIONS from 'constants/permissions3';
import { REBATE_GROUPS_RECORDS } from 'graphql/queries/rebatesReport.query';
import { useConfig } from 'hooks/useConfig';
import { Page, PageInfo } from 'interfaces/user.interface';
import { isEqual } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import { usePermissions } from 'store/accountState';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { useScreenTabV2 } from 'store/screenTabState';
import { StyledTagContainer } from 'styles';
import coercedGet from 'utils/coercedGet';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { sortTransform } from 'utils/partialUtils';
import { getPollInterval } from 'utils/pollInterval';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import SpreadsheetProvider, {
  useSpreadsheetContext,
} from 'contexts/Spreadsheet';
import FilterConditions from './components/FilterConditions';
import HeaderRight from './components/HeaderRight';
import RebatePayoutRecordsTable from './components/RebatePayoutRecordsTable';
import Sidebar from './components/Sidebar';
import EditSearchSettings from './components/Sidebar/components/FilterSettings/components/SearchSettings/components/EditSearchSettings';
import { getColumns } from './constants';

const pageInitState: Page = {
  after: undefined,
  savedCursor: [undefined] as any,
  currentPage: 0,
  first: 10,
};

type OriginalColumn = {
  key: string;
  title: string;
  customTitle?: string;
  [x: string]: any;
};

const RebatePayoutRecords = () => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_LIST: ALLOWED_REBATES_LIST } = collectPermissions(
    role,
    permissions,
    ['LIST'],
    ALL_PERMISSIONS.ALL_REBATES.REBATES_REBATES_REBATE_GROUP
  );

  const [expandedTags, setExpandedTags] = useState(false);
  const [pageInfo, setPageInfo] = useState<Partial<PageInfo>>({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [sidebarShown, setSidebarShown] = useState(true);
  const showSidebar = () => setSidebarShown((prevState) => !prevState);
  const [page, setPage] = useState<Page>(pageInitState);
  const { isTabActive } = useScreenTabV2();
  const [columns, setColumns] = useState<OriginalColumn[]>([]);
  const [isRealtime, setIsRealtime] = useState(true);
  const { currency } = useConfig();

  const translate = useTranslate();

  const { setTableColumns } = useSpreadsheetContext();

  const defaultFilters = {
    dateTimeCreated: null,
    // status: null,
  };
  const [filters, setFilters] = useState(defaultFilters);
  const handleFilters = (e: any) => {
    setFilters(e);
  };

  useEffect(() => {
    setPage((prev: any) => ({
      ...pageInitState,
      first: prev.first,
    }));
  }, [setPage]);

  const handleSetRealtime = () => {
    setIsRealtime((prev) => !prev);
  };

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor!);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const handleSort = (order: 'ascend' | 'descend') => {
    setPage((prev) => ({
      ...prev,
      sort: order,
    }));
  };

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: transformMovableDateFilter(filters),
    sort: {
      direction: sortTransform[page.sort],
    },
  };

  const setResultInfo = (data: { pageInfo: PageInfo; totalCount: number }) => {
    setTotalCount(data?.totalCount);
    setPageInfo(data?.pageInfo);
  };

  const isActiveTab = isTabActive('rebates-reports');
  const { context, setOnPoll } = useOperatorHeader();
  const { loading, error, data, refetch } = useQuery(REBATE_GROUPS_RECORDS, {
    variables: refetchVariables,
    fetchPolicy: 'network-only',
    skip: !ALLOWED_REBATES_LIST,
    notifyOnNetworkStatusChange: true,
    pollInterval: getPollInterval({
      isActive: isActiveTab,
      isRealtime,
    }),
    context,
    onCompleted: (result) => {
      setOnPoll(true);
      setResultInfo(result?.rebateGroups);
    },
  });
  const edges = coercedGet(data, 'rebateGroups.edges', []);
  const records = edges.map((edge: any) => edge.node);

  const { filterColumns } = useCustomColumnsV2('rebates-reports', columns);

  useEffect(() => {
    setColumns(getColumns(translate, currency));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading) {
      setTableColumns(filterColumns(columns));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  useEffect(() => {
    if (!ALLOWED_REBATES_LIST) {
      showPermissionError('Rebates - View');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      {ALLOWED_REBATES_LIST && (
        <>
          <Layout.Header
            leftNode={
              <div className="d-flex align-items-start flex-g-1">
                <Button onClick={showSidebar} size="small">
                  {sidebarShown ? <CloseSquareOutlined /> : <LayoutOutlined />}
                </Button>
                <StyledTagContainer
                  ref={ref}
                  isExpanded={expandedTags}
                  className="ml-3"
                >
                  <span className="ml-1">
                    {translate(messages.FILTER_CONDITIONS)}:{' '}
                  </span>{' '}
                  <FilterConditions
                    filters={refetchVariables.filter}
                    onFilterChange={handleFilters}
                  />
                </StyledTagContainer>
                <LegacyIconToggle
                  forwardedRef={ref}
                  filters={filters}
                  expandedTags={expandedTags}
                  toggleExpandedTags={setExpandedTags}
                />
                {!isEqual(defaultFilters, filters) && (
                  <ALink
                    onClick={() => handleFilters(defaultFilters)}
                    style={{ marginTop: '0.35rem' }}
                  >
                    {translate(messages.CLEAR_ALL)}
                  </ALink>
                )}
              </div>
            }
            rightNode={
              <HeaderRight
                refreshPage={() => refetch()}
                isRealtime={isRealtime}
                handleSetRealtime={handleSetRealtime}
                filters={filters}
              />
            }
          />
          <Layout.Content
            sideBar={
              <Layout.Sidebar
                sidebarStatus={sidebarShown}
                quickSearch={{
                  filters: refetchVariables.filter,
                  contextKey: 'rebates-reports',
                  onFilterChange: handleFilters,
                  editSearchSettings: EditSearchSettings,
                }}
              >
                <Sidebar
                  filters={filters}
                  onFilterChange={handleFilters}
                  initialFilters={defaultFilters}
                />
              </Layout.Sidebar>
            }
            footer={
              <Layout.Footer
                loading={false}
                onNext={handleNext}
                onPrev={handlePrev}
                page={page}
                resultsCount={totalCount}
                totalPage={totalPage}
                setPage={setPage}
                pageInfo={data && data.pageInfo}
              />
            }
          >
            <RebatePayoutRecordsTable
              handleSort={handleSort}
              loading={loading}
              error={error}
              data={records}
              columns={filterColumns(columns)}
            />
          </Layout.Content>
        </>
      )}
    </Layout.Container>
  );
};

const RebatePayoutRecordsTogglable = () => (
  <SpreadsheetProvider
    options={{
      query: REBATE_GROUPS_RECORDS,
      edgesPath: 'rebateGroups.edges',
    }}
  >
    <RebatePayoutRecords />
  </SpreadsheetProvider>
);

export default RebatePayoutRecordsTogglable;
