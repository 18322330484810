import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { isBoolean } from 'lodash';
import styled from 'styled-components';
import { InputNumber } from 'antd';
import { blockAlphabets } from 'utils/blockAlphabets';
import { checkBothValidity, CheckIcon, checkValidity, messages } from './utils';
import {
  IMinMaxNumberFilter,
  IMinMaxNumberValid,
  IMinMaxNumberValue,
} from './interface';
import { StyledLabel } from '../../styles';
import { customFormatMessage } from '../../../../../../../../../utils/customFormatMessage';

export const StyledInput = styled(InputNumber)`
  width: 100%;
  border-radius: 2px;

  input {
    padding-right: 25px !important;
  }

  .ant-input-number-handler-wrap {
    display: none !important;
  }
`;

const initState: IMinMaxNumberValue = {
  gte: 0,
  lte: 0,
};

export const TotalEffectiveBetFilter = ({
  value,
  onChange,
  disabled,
  label,
}: IMinMaxNumberFilter) => {
  const [input, setInput] = useState<IMinMaxNumberValue>(initState);
  const [valid, setValid] = useState<IMinMaxNumberValid>({
    gte: null,
    lte: null,
  });
  const [initialized, setInitialized] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (!initialized) {
      return;
    }
    const hasOneTrueAndNull =
      Object.values(valid).some((x) => x === true) &&
      Object.values(valid).some((x) => x === null);
    if (hasOneTrueAndNull) {
      onChange({
        gte: input.gte,
        lte: input.lte,
      });
    }

    if (valid.gte && valid.lte) {
      onChange({
        ...value,
        gte: input.gte,
        lte: input.lte,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);

  useEffect(() => {
    if (!initialized) {
      if (value) {
        checkBothValidity(value.gte!, value.lte!, setValid, setInitialized);
      } else {
        setInitialized(true);
      }
    }

    setInput(value || initState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onInputKeyDown = (type: 'min' | 'max') => (event: KeyboardEvent) => {
    blockAlphabets(event);
    if (event.key === 'Enter') {
      checkValidity(type, input, setValid);
    }
  };
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(intl.formatMessage, messageVal, opts);
  return (
    <div className="mt-1">
      <div>
        <StyledLabel>{label}</StyledLabel>
      </div>

      <div className="d-flex">
        <div className="position-relative">
          <StyledInput
            aria-label="total-bet-min-input"
            value={input.gte!}
            name="gte"
            onBlur={() => checkValidity('min', input, setValid)}
            placeholder={translate(messages['minimum.text'])}
            onFocus={(e) => e.target.select()}
            onChange={(e: string | number | undefined | null) => {
              if ((e as string) === '') {
                setValid((prev) => ({ ...prev, gte: null }));
                if (input.lte === 0) {
                  setValid((prev) => ({ ...prev, lte: null }));
                  onChange(null);
                }
              }

              setInput((prev: any) => ({
                ...prev,
                gte: (e as number) || 0,
              }));
            }}
            disabled={disabled}
            onKeyDown={onInputKeyDown('min') as any}
          />
          {isBoolean(valid.gte) && <CheckIcon correct={valid.gte} />}
        </div>
        <div className="d-flex align-items-center">&nbsp;-&nbsp;</div>
        <div className="position-relative">
          <StyledInput
            aria-label="total-bet-max-input"
            name="lte"
            onBlur={() => checkValidity('max', input, setValid)}
            value={input.lte!}
            placeholder={translate(messages['maximum.text'])}
            onFocus={(e) => e.target.select()}
            onChange={(e: string | number | undefined | null) => {
              if ((e as string) === '') {
                setValid((prev) => ({ ...prev, lte: null }));
                if (input.gte === 0) {
                  setValid((prev) => ({ ...prev, gte: null }));
                  onChange(null);
                }
              }

              setInput((prev: any) => ({
                ...prev,
                lte: (e as number) || 0,
              }));
            }}
            disabled={disabled}
            onKeyDown={onInputKeyDown('max') as any}
          />

          {isBoolean(valid.lte) && <CheckIcon correct={valid.lte} />}
        </div>
      </div>
    </div>
  );
};
