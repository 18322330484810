import React from 'react';
import { Switch } from 'antd';
import { any, bool, func, object, oneOfType, shape, string } from 'prop-types';

export const CustomSwitch = ({
  onChange,
  value,
  textHelper,
  activeColor,
  disabled,
  ...props
}) => (
  <>
    <Switch
      size="small"
      {...props}
      disabled={disabled}
      onChange={onChange}
      checked={value}
      style={{
        background: value ? activeColor || '#3ec988' : '#bfbfbf',
      }}
    />
    {textHelper && !disabled && (
      <small className="ml-2 text-bold">
        {value ? textHelper.true : textHelper.false}
      </small>
    )}
  </>
);

CustomSwitch.propTypes = {
  onChange: func,
  // eslint-disable-next-line react/forbid-prop-types
  value: any,
  textHelper: shape({
    true: oneOfType([string, object]),
    false: oneOfType([string, object]),
  }),
  activeColor: string,
  disabled: bool,
};

CustomSwitch.defaultProps = {
  onChange: (e) => e,
  value: null,
  textHelper: {
    true: null,
    false: null,
  },
  activeColor: null,
  disabled: false,
};
