import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const StyledLayout = styled(Layout)`
  height: 100%;
`;

export const StyledContent = styled(Content)`
  background: #fff;
`;

export default {};
