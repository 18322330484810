import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Form, Input, message, Modal, Switch } from 'antd';
import { UPDATE_DOMAIN } from 'graphql/mutations/siteConfiguration.mutation';
import { DOMAIN } from 'graphql/queries/siteConfiguration.query';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ALink } from 'components/ALink/ALink';

const { TextArea } = Input;

type Props = {
  id: string;
  refetch: () => void;
};

const EditDomain = ({ id, refetch }: Props) => {
  const [visible, setVisible] = useState(false);
  const [sslState, setSslState] = useState(false);
  const [statusState, setStatusState] = useState(false);

  const { data: initialData } = useQuery(DOMAIN, {
    fetchPolicy: 'network-only',
    variables: { id },
    onCompleted: (data) => {
      setSslState(data.domain.sslEnabled);
    },
  });

  const [updateDomain, { loading: updateLoading }] = useMutation(
    UPDATE_DOMAIN,
    {
      onCompleted: () => {
        message.success('Domain updated successfully.');

        refetch();
        setVisible(false);
      },
      onError: () => {
        message.error('Domain update failed. Please try again later.');
      },
    }
  );

  const {
    name,
    status,
    description,
    sslEnabled,
    certificate,
    privateKey,
    intermediateCertificate,
  } = initialData?.domain || {};

  const { control, handleSubmit, setValue, register, getValues } = useForm();

  const onClose = () => {
    setVisible(false);
  };

  const onSubmit = async (): Promise<void> => {
    await updateDomain({
      variables: {
        id,
        input: getValues(),
      },
    });
  };

  const sslConfigs = [
    { name: 'certificate', label: 'Certificate', defaultValue: certificate },
    { name: 'privateKey', label: 'Private Key', defaultValue: privateKey },
    {
      name: 'intermediateCertificate',
      label: 'Intermediate Certificate (Optional)',
      defaultValue: intermediateCertificate,
    },
  ];

  useEffect(() => {
    register('status');
    register('sslEnabled');
    setValue('sslEnabled', sslEnabled);
    setValue('status', status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register, setValue, initialData]);

  return (
    <>
      <ALink
        style={{
          textDecoration: 'none',
        }}
        onClick={() => setVisible(true)}
      >
        Edit
      </ALink>
      <Modal
        title="Edit Domain"
        closable={false}
        width={600}
        visible={visible}
        footer={[
          <Button loading={updateLoading} onClick={onClose}>
            Cancel
          </Button>,
          <Button
            loading={updateLoading}
            type="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save Changes
          </Button>,
        ]}
      >
        <Form
          layout="horizontal"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item label="Domain URL">
            <Controller
              control={control}
              name="name"
              as={<Input />}
              defaultValue={name}
            />
          </Form.Item>
          <Form.Item label="Description">
            <Controller
              control={control}
              name="description"
              as={<TextArea rows={3} />}
              defaultValue={description}
            />
          </Form.Item>
          <Form.Item label="SSL Certification">
            <Switch
              onChange={(checked: boolean) => {
                setValue('sslEnabled', checked);
                setSslState(checked);
              }}
              checked={getValues('sslEnabled')}
            />
          </Form.Item>
          {sslState && (
            <>
              {sslConfigs.map((config) => (
                <Form.Item label={config.label}>
                  <Controller
                    control={control}
                    name={config.name}
                    as={<TextArea rows={3} />}
                    defaultValue={config.defaultValue || ''}
                  />
                </Form.Item>
              ))}
            </>
          )}
          <Form.Item label="Status">
            <Switch
              onChange={(e: boolean) => {
                setValue('status', e ? 'ACTIVE' : 'INACTIVE');
                setStatusState(e);
              }}
              checked={statusState}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditDomain;
