import esget from 'utils/esGet';
import { WithdrawalMethod } from 'types/graphqlTypes';
import messages from 'pages/components/WithdrawalMethods/messages';
import { isTypenameInternal } from 'constants/paymentTypes';
import { renderPaymentMethodByType } from 'pages/components/WithdrawalMethods/utils';
import { getBank, messages as bankMessages } from 'constants/banks';

const getCsvHeaders = (translate: any) => [
  {
    label: translate(messages.title),
    key: 'name',
    renderCell: (withdrawalMethod: WithdrawalMethod) =>
      esget(withdrawalMethod?.name, '-'),
  },
  {
    label: translate(messages.sources),
    key: 'paymentSources',
    renderCell: (withdrawalMethod: any) => {
      if (isTypenameInternal(esget(withdrawalMethod?.__typename, '-'))) {
        return translate(messages.internal);
      }
      return translate(messages.thirdparty);
    },
  },
  {
    label: translate(messages.provider),
    key: 'provider',
    renderCell: (withdrawalMethod: any) =>
      esget(withdrawalMethod?.withdrawalProvider?.name, '-'),
  },
  {
    label: translate(messages.paymentmethod),
    key: 'paymentMethod',
    renderCell: (withdrawalMethod: any) =>
      renderPaymentMethodByType(translate, withdrawalMethod.__typename),
  },
  {
    label: `${translate(messages.bankname)} / ${translate(messages.nickname)}`,
    key: 'bankName',
    renderCell: (withdrawalMethod: any) => {
      const { bank, nickname } = withdrawalMethod;
      if (bank) {
        const newBank = getBank(bank);
        const bankLabel: any = newBank?.label;
        return `${bankLabel !== 'OTHER' ? `[${bankLabel}] ` : ''} ${translate(
          bankMessages[bankLabel]
        )}`;
      }
      if (nickname) return nickname;
      return '-';
    },
  },
  {
    label: translate(messages.status),
    key: 'status',
    renderCell: (withdrawalMethod: any) =>
      esget(withdrawalMethod?.enabled, false)
        ? translate(messages.enable)
        : translate(messages.disable),
  },
  {
    label: translate(messages.remark),
    key: 'remarks',
    renderCell: (withdrawalMethod: any) =>
      esget(withdrawalMethod?.remarks, '-'),
  },
];

export default getCsvHeaders;
