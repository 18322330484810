import moment from 'moment';
import removeNull from 'utils/removeNull';

export function getEffectiveDateRange(startTime: any, endTime: any) {
  return `${moment(startTime).format('YYYY-MM-DD')} - ${moment(endTime).format(
    'YYYY-MM-DD'
  )}`;
}

export const refetchVariables = (
  page: any,
  filters: any,
  filterToTransform: any
) => ({
  first: page.first,
  after: page.after,
  filter: removeNull(
    {
      ...filters,
      validityStartDate: removeNull(
        {
          gte:
            filterToTransform.date.gte &&
            moment(filterToTransform.date.gte).format('YYYY-MM-DD'),
        },
        true
      ),
      validityEndDate: removeNull(
        {
          lte:
            filterToTransform.date.lte &&
            moment(filterToTransform.date.lte).format('YYYY-MM-DD'),
        },
        true
      ),
    },
    true
  ),
});
