import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { movableFilterDateEffect } from 'utils/dateUtils';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DatePicker, Button } from 'antd';
import { isEmpty } from 'lodash';

export const EndDateFilter: React.FC = () => {
  const { filters, setFilters } = useFilterValues();
  const [dateValue, setDateValue] = useState<any>(null);

  const value = filters.contractEndDateTime;

  useEffect(() => {
    movableFilterDateEffect(value, setDateValue);
  }, [value]);

  const handleChange = (e: any) => {
    if (e.length) {
      setFilters((prev: any) => ({
        ...prev,
        contractEndDateTime: {
          gte: e[0].toISOString(),
          lte: e[1].toISOString(),
        },
      }));
    } else {
      setFilters((prev: any) => ({
        ...prev,
        contractEndDateTime: null,
      }));
    }
  };

  const handleSevenDays = () => {
    const dateFrom = moment()
      .subtract(7, 'd')
      .startOf('day')
      .toISOString();
    const dateTo = moment().toISOString();

    setDateValue({ gte: dateFrom, lte: dateTo });
    setFilters((prev: any) => ({
      ...prev,
      contractEndDateTime: { gte: dateFrom, lte: dateTo },
    }));
  };

  const handleLastWeek = () => {
    const dateTo = moment()
      .subtract(1, 'weeks')
      .toISOString();
    const dateFrom = moment()
      .subtract(2, 'weeks')
      .startOf('isoWeek')
      .toISOString();

    setDateValue({ gte: dateFrom, lte: dateTo });
    setFilters((prev: any) => ({
      ...prev,
      contractEndDateTime: { gte: dateFrom, lte: dateTo },
    }));
  };

  const handleLastThirtyDays = () => {
    const dateTo = moment().toISOString();
    const dateFrom = moment()
      .subtract(30, 'd')
      .toISOString();

    setDateValue({ gte: dateFrom, lte: dateTo });
    setFilters((prev: any) => ({
      ...prev,
      contractEndDateTime: { gte: dateFrom, lte: dateTo },
    }));
  };

  return (
    <>
      <div>
        <StyledLabel>
          <FormattedMessage
            id="expire-date.text"
            defaultMessage="Expire Date"
          />
        </StyledLabel>
      </div>

      <StyledDatePicker
        value={
          !isEmpty(dateValue)
            ? [moment(dateValue.gte), moment(dateValue.lte)]
            : ([] as any)
        }
        onChange={handleChange}
      />

      <div className="mt-1">
        <StyledButton size="small" className="mb-1" onClick={handleSevenDays}>
          <FormattedMessage
            id="last-7-days.text"
            defaultMessage="Last 7 days"
          />
        </StyledButton>

        <StyledButton size="small" className="mb-1" onClick={handleLastWeek}>
          <FormattedMessage id="last-week.text" defaultMessage="Last week" />
        </StyledButton>

        <StyledButton
          size="small"
          className="mb-1"
          onClick={handleLastThirtyDays}
        >
          <FormattedMessage
            id="last-30-days.text"
            defaultMessage="Last 30 days"
          />
        </StyledButton>
      </div>
    </>
  );
};

const StyledLabel = styled.span`
  font-size: 10px;
  color: #000000d9;
`;

const StyledButton = styled(Button)<any>`
  background: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-right: 8px;
`;

const StyledDatePicker = styled(DatePicker.RangePicker)`
  .ant-calendar-range-picker-separator {
    position: absolute;
    right: 16px;
    top: 4px;
  }

  .ant-calendar-picker-icon {
    display: none;
  }
`;
