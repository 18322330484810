import React from 'react';
import { Tag } from 'antd';
import { useFilterValues } from 'contexts/Filters';

type Props = {
  isLoading: boolean;
};

const FilterConditions = ({ isLoading }: Props) => {
  const { filters, setFilters } = useFilterValues();
  const handleRemoveName = (memberName: string, e: Record<string, any>) => {
    e.preventDefault();
    if (!isLoading) {
      const { name } = filters;
      const data = name.in.filter((item: string) => item !== memberName);

      const newName = {
        in: data,
      };
      setFilters({
        ...filters,
        name: data.length ? newName : null,
      });
    }
  };

  return (
    <>
      {filters.name
        ? filters?.name?.in?.map((item: string, key: number) => (
            <Tag key={key} closable onClose={(e) => handleRemoveName(item, e)}>
              {item}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
