import styled from 'styled-components';

export const ContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 33px;

  div:nth-child(odd) {
    margin-right: 2px;
  }
`;

export const TagColorStyled = styled.div`
  height: 10px;
  width: 10px;
  background-color: ${({ color }) => color};
  border-radius: 2px;
  margin-bottom: 2px;
`;

export const RemainingStyled = styled.small`
  font-size: 10px;
  line-height: 1;
  font-weight: 700;
`;
