import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'rebates.add-rebate-group.text': {
    id: 'rebates.add-rebate-group.text',
    defaultMessage: 'Add rebate group',
  },
  'rebates.create-rebate-group-success.text': {
    id: 'rebates.create-rebate-group-success.text',
    defaultMessage: 'Rebate group has been saved successfully.',
  },
  'rebates.update-rebate-group-success.text': {
    id: 'rebates.update-rebate-group-success.text',
    defaultMessage: 'Rebate group has been updated successfully.',
  },
  'common.rebate-settings.text': {
    id: 'common.rebate-settings.text',
    defaultMessage: 'Rebate settings',
  },
  'common.payout-settings.text': {
    id: 'common.payout-settings.text',
    defaultMessage: 'Payout settings',
  },
  'common.rebate-group.text': {
    id: 'common.rebate-group.text',
    defaultMessage: 'Rebate group',
  },
  'common.next.text': {
    id: 'common.next.text',
    defaultMessage: 'Next',
  },
  'common.save-changes.text': {
    id: 'common.save-changes.text',
    defaultMessage: 'Save Changes',
  },
});

export default messages;
