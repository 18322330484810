import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UPSERT_PREFERENCE } from 'graphql/mutations/upsertPreference.mutation';
import { PREFERENCE } from 'graphql/queries/preference.query';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type OriginalColumn = {
  key: string;
  title: string;
  customTitle?: string;
  [x: string]: any;
};

type CustomColumn = {
  key: string;
  title: string;
  visible: boolean;
  disabled?: boolean;
  hidden?: boolean;
};

type ColumnsContainer = {
  tabId: string;
  initial: CustomColumn[];
  custom: CustomColumn[];
};

export type CustomColumnsState = ColumnsContainer[];

const customColumnsSlice = createSlice({
  name: 'custom-column',
  initialState: [] as CustomColumnsState,
  reducers: {
    manage: (state, { payload }: PayloadAction<ColumnsContainer>) => {
      const columnsContainerIndex = state.findIndex(
        (columnsContainer: ColumnsContainer) =>
          columnsContainer.tabId === payload.tabId
      );

      if (columnsContainerIndex === -1) state.push(payload);
      else state.splice(columnsContainerIndex, 1, payload);
    },
  },
});

const selectCustomColumn = createSelector(
  (customColumns: CustomColumnsState, tabId: string) =>
    customColumns.find(
      (columnsContainer: ColumnsContainer) => columnsContainer.tabId === tabId
    ),
  (column) => column || []
);

export const customColumnsReducer = customColumnsSlice.reducer;

export const useCustomColumnsV2 = (
  tabId: string,
  initialColumn: OriginalColumn[] = []
) => {
  const key = `custom-columns-v2:${tabId}`;
  const dispatch = useDispatch();
  const { actions } = customColumnsSlice;

  const [upsertPreference] = useMutation(UPSERT_PREFERENCE);

  const [loadCustomColumn, { called }] = useLazyQuery(PREFERENCE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      key,
    },
    onCompleted: (data: { preference: ColumnsContainer }) => {
      if (!initialColumn.length) return;
      let { preference } = data;
      const initial: CustomColumn[] = initialColumn.map(
        (column: OriginalColumn) => ({
          key: column.key,
          title: column.customTitle || column.title,
          visible: true,
          ...(column?.disabled && { disabled: true }),
          ...(column?.hidden && { hidden: true }),
        })
      );
      const original = { tabId, initial, custom: initial };

      if (!preference) {
        preference = original;
      } else {
        const server = JSON.stringify(Object.values(preference.initial));
        const local = JSON.stringify(Object.values(initial));

        if (server !== local) {
          preference = original;
          upsertPreference({
            variables: {
              key,
              value: original,
            },
          });
        }
      }

      dispatch(actions.manage(preference));
    },
  });

  const columns = useSelector(
    ({
      superAdminCustomColumns: columnsState,
    }: {
      superAdminCustomColumns: CustomColumnsState;
    }) => selectCustomColumn(columnsState, tabId)
  ) as ColumnsContainer;

  const setColumns = useCallback(
    (superAdminCustomColumns: CustomColumn[]) => {
      const newCustomColumn: ColumnsContainer = {
        ...columns,
        custom: superAdminCustomColumns,
      };

      dispatch(actions.manage(newCustomColumn));
      upsertPreference({
        variables: {
          key,
          value: newCustomColumn,
        },
      });
    },
    [columns, dispatch, actions, upsertPreference, key]
  );

  const resetColumns = useCallback(() => {
    setColumns(columns.initial);
  }, [setColumns, columns.initial]);

  const filterColumns = useCallback(
    (originalColumns: OriginalColumn[]) =>
      columns?.custom
        ?.filter((column: CustomColumn) => column.visible)
        .map((customColumn: CustomColumn) =>
          originalColumns.find(
            (originalColumn: OriginalColumn) =>
              originalColumn.key === customColumn.key
          )
        ) as OriginalColumn[],
    [columns]
  );

  if (!called) loadCustomColumn();

  return useMemo(
    () => ({
      loading: !columns.custom?.length,
      initialColumns: columns.initial,
      customColumns: columns.custom,
      setColumns,
      resetColumns,
      filterColumns,
    }),
    [columns, setColumns, resetColumns, filterColumns]
  );
};
