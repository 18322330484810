import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Modal, Table } from 'antd';
import { DocumentNode } from 'graphql';
import React, { FC, useState } from 'react';
import { getColumns } from './columns';

interface DataType {
  dateTimeCreated: string;
  actionType: string;
  account: {
    username: string;
  };
  feature: string;
}

type Props = {
  title: string;
  subTitle: string;
  query: DocumentNode;
  auditTrailRecord: string;
};

const AuditTrailRecords: FC<Props> = ({
  title,
  subTitle,
  query,
  auditTrailRecord,
}) => {
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [loadData, { loading }] = useLazyQuery(query, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const rawData = data[auditTrailRecord]?.edges.map(
        ({ node }: { node: DataType }) => node
      );

      setTableData(rawData);
    },
  });

  const handleViewReport = () => {
    setVisible(true);
    loadData();
  };

  return (
    <div>
      <h3>View {title}</h3>
      <Button onClick={handleViewReport}>View Logs - {subTitle}</Button>

      <Modal
        title={`View Logs - ${subTitle}`}
        onCancel={() => setVisible(false)}
        visible={visible}
        centered
        footer={null}
        width={800}
        destroyOnClose
      >
        <Table
          style={{ height: '400px', overflow: 'auto' }}
          columns={getColumns({
            hasFeatureField: auditTrailRecord === 'registrationAuditTrail',
          })}
          dataSource={tableData}
          loading={loading}
        />
      </Modal>
    </div>
  );
};

export default AuditTrailRecords;
