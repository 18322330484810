import { Modal, Typography, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';
import { ClosedLoop } from '../../../ChangeBalance/EditBalance';

type Props = {
  setFieldValue: (key: string, data: any) => void;
  onSubmit: () => void;
  loading: boolean;
  values: Record<string, any>;
  closedLoopState: ClosedLoop;
};

export default ({
  values,
  setFieldValue,
  onSubmit,
  loading,
  closedLoopState,
}: Props) => {
  const onChange = (e: RadioChangeEvent) => {
    if (e.target.value) setFieldValue('disableClosedLoop', e.target.value);
  };

  const handleClose = () => {
    closedLoopState.setClosedLoopState({
      closeLoopBlocked: false,
      showModal: false,
    });
  };

  return (
    <Modal
      visible={closedLoopState.showClosedLoopModal}
      centered
      destroyOnClose
      closable={false}
      okText="Submit"
      onCancel={handleClose}
      onOk={onSubmit}
      width="250px"
      okButtonProps={{ loading }}
      cancelButtonProps={{ loading }}
    >
      <div className="d-flex flex-column justify-content-center align-items-center w-100">
        <Typography.Title level={4}>Closed Loop Block</Typography.Title>
        <div className="d-flex justify-content-around w-100 mt-3">
          <Radio.Group onChange={onChange} value={values.disableClosedLoop}>
            <Radio value>Approve</Radio>
            <Radio onClick={handleClose}>Deny</Radio>
          </Radio.Group>
        </div>
      </div>
    </Modal>
  );
};
