import { useMutation } from '@apollo/react-hooks';
import { message, Spin } from 'antd';
import {
  REJECT_DEPOSIT,
  START_PROCESS_DEPOSIT,
  UPDATE_DEPOSIT,
} from 'graphql/mutations/deposit.mutation';
import { DEPOSIT, DEPOSIT_REQUESTS } from 'graphql/queries/deposit.query';
import messages from 'pages/components/ExternalMemberDeposits/messages';
import React, { useState } from 'react';
import RemarkModal from 'components/RemarkModal';
import useTranslate from 'utils/useTranslate';

type Props = {
  id: string;
  remarks?: string | null;
  refetchVariables: object;
  disabled: boolean;
  isProcessing: boolean;
};
const RejectDeposit = ({
  id,
  remarks,
  refetchVariables,
  isProcessing,
}: Props) => {
  const refetchQueries = [
    {
      query: DEPOSIT,
      variables: { id },
    },
    {
      query: DEPOSIT_REQUESTS,
      variables: refetchVariables,
    },
  ];

  const [visible, setVisible] = useState(false);
  const translate = useTranslate();
  const [rejectDeposit, rejectDepositResult] = useMutation(REJECT_DEPOSIT, {
    onCompleted: (data) => {
      if (data.rejectDeposit) {
        message.success(translate(messages.rejectDepositProcess));
      }
    },
    variables: { id },
    refetchQueries,
  });

  const [startProcessDeposit, startProcessResult] = useMutation(
    START_PROCESS_DEPOSIT,
    {
      onCompleted: (data) => {
        if (data.startProcessDeposit) {
          message.success(translate(messages.PROCESSING));
          rejectDeposit();
        }
      },
      variables: { id },
    }
  );

  const [updateDeposit, updateDepositResult] = useMutation(UPDATE_DEPOSIT, {
    onCompleted: (data) => {
      if (data.updateDeposit) {
        message.success(translate(messages.REMARKS_UPDATED));
        if (!isProcessing) {
          startProcessDeposit();
        } else {
          rejectDeposit();
        }
      }
      setVisible(false);
    },
  });

  const onHandleReject = () => {
    if (remarks) {
      rejectDeposit();
    } else {
      setVisible(true);
    }
  };

  const disableState =
    startProcessResult.loading || rejectDepositResult.loading;

  return (
    <>
      <a href="#/" onClick={onHandleReject}>
        {disableState ? <Spin /> : null}
        {translate(messages.reject)}
      </a>
      {visible && (
        <RemarkModal
          id={id}
          visible={visible}
          loading={updateDepositResult.loading}
          setVisible={setVisible}
          onSubmitRemarks={updateDeposit}
        />
      )}
    </>
  );
};

export default RejectDeposit;
