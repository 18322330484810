import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'agent.no-minimum-ggr-charge.text': {
    id: 'agent.no-minimum-ggr-charge.text',
    defaultMessage: 'No minimum GGR Charge',
  },
  'agent.no-maximum-ggr-charge.text': {
    id: 'agent.no-maximum-ggr-charge.text',
    defaultMessage: 'No maximum GGR Charge',
  },
  'minimum.text': {
    id: 'minimum.text',
    defaultMessage: 'Minimum',
  },
  'maximum.text': {
    id: 'maximum.text',
    defaultMessage: 'Maximum',
  },
});

export default messages;
