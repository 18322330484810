import { useLazyQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import { PLACES } from 'graphql/queries/geofencingConfig.query';
import { useDebounce } from 'hooks/useDebounce';
import { capitalize, toUpper } from 'lodash';
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import {
  GeoFenceConfig,
  Place,
  PlacesConnectionEdge,
} from 'types/graphqlTypes';

type Props = {
  onSelect: {
    setValues: Dispatch<SetStateAction<GeoFenceConfig>>;
    action: string;
    type: string;
  };
};

const StyledSelect = styled(Select)`
  .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix) {
    display: none !important;
  }
`;

export default ({ onSelect }: Props) => {
  const { action, setValues, type } = onSelect;

  const [searchedText, setSearchedText] = useState('');
  const debouncedText = useDebounce(searchedText, 500);
  const [places, setPlaces] = useState<Place[]>([]);

  const [loadPlaces, { loading }] = useLazyQuery(PLACES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 10,
      language: 'EN',
      filter: {
        displayName: {
          contains: debouncedText,
        },
      },
    },
    onCompleted: (data) => {
      const placesData = data.places.edges.map(
        ({ node }: PlacesConnectionEdge) => ({
          id: node.id,
          displayName: node.displayName,
          type: node.type,
        })
      );

      setPlaces(placesData);
    },
  });

  useEffect(() => {
    loadPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  return (
    <StyledSelect
      style={{
        width: '100%',
      }}
      mode="multiple"
      onSearch={(value: string) => setSearchedText(value)}
      onSelect={(value: any) => {
        const label = value.label;
        const labelStr = label.split(' - ');

        setValues((prev) => ({
          ...prev,
          [`${type}${action}`]: [
            ...prev[`${type}${action}`],
            {
              id: value.value,
              displayName: labelStr[0],
              type: toUpper(labelStr[1]),
            },
          ],
        }));
      }}
      labelInValue
      value={undefined}
      showSearch
      loading={loading}
      filterOption={false}
      onFocus={() => setSearchedText('')}
      onBlur={() => setSearchedText('')}
      defaultActiveFirstOption
    >
      {places.map((place) => (
        <Select.Option key={place.id} value={place.id}>
          {`${place.displayName} - ${capitalize(place.type)}`}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};
