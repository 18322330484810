import gql from 'graphql-tag';

export const WITHDRAWAL_METHODS = gql`
  query WithdrawalMethodsList(
    $first: Int
    $after: Binary
    $filter: WithdrawalMethodFilterInput
  ) {
    withdrawalMethods(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          remarks
          enabled
          transactionFee
          transactionFeeType
          ... on OfflineBankTransferWithdrawalMethod {
            bank
            accNumber: accountNumber
            accountName
            branch
            country
            province
            city
          }
          ... on WechatWithdrawalMethod {
            accountNumber
            nickname
          }
          ... on AlipayWithdrawalMethod {
            accountNumber
            nickname
          }
          ... on OnlineBankTransferWithdrawalMethod {
            paymentChannel
            withdrawalProvider {
              id
              name
              type
            }
          }
          ... on OnlineWechatWithdrawalMethod {
            paymentChannel
            withdrawalProvider {
              id
              name
              type
            }
          }
          ... on OnlineAlipayWithdrawalMethod {
            paymentChannel
            withdrawalProvider {
              id
              name
              type
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const WITHDRAWAL_METHODS_CSV_DATA = gql`
  query WithdrawalMethodsList(
    $first: Int
    $after: Binary
    $filter: WithdrawalMethodFilterInput
  ) {
    withdrawalMethods(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          __typename
          id
          name
          remarks
          enabled
          transactionFee
          transactionFeeType
          ... on OfflineBankTransferWithdrawalMethod {
            bank
            accNumber: accountNumber
            accountName
          }
          ... on WechatWithdrawalMethod {
            accountNumber
            nickname
          }
          ... on AlipayWithdrawalMethod {
            accountNumber
            nickname
          }
          ... on OnlineBankTransferWithdrawalMethod {
            withdrawalProvider {
              id
              name
              type
            }
          }
          ... on OnlineWechatWithdrawalMethod {
            withdrawalProvider {
              id
              name
              type
            }
          }
          ... on OnlineAlipayWithdrawalMethod {
            withdrawalProvider {
              id
              name
              type
            }
          }
        }
      }
    }
  }
`;

export const WITHDRAWAL_PROVIDERS = gql`
  query WithdrawalProviders(
    $first: Int
    $after: Binary
    $filter: WithdrawalProviderFilterInput
  ) {
    withdrawalProviders(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export default {};
