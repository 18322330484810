import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Modal, notification } from 'antd';
import { DELETE_REBATE_GROUP } from 'graphql/mutations/rebateGroup.mutation';
import {
  RebateGroupTypes,
  useRebateGroupState,
} from 'pages/components/Rebates/context';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import messages from './messages';

const DeleteRebateGroup = (props: { refetch: () => void }) => {
  const { refetch } = props;

  const [rebateGroupState, rebateGroupDispatch] = useRebateGroupState() as any;
  const { deleteShown } = rebateGroupState;
  const toggleDelete = () => {
    rebateGroupDispatch({
      type: RebateGroupTypes.TOGGLE_DELETE,
    });
  };
  const translate = useTranslate();
  const [deleteRebateGroup, { loading }] = useMutation(DELETE_REBATE_GROUP, {
    onCompleted: () => {
      notification.success({
        message: translate(messages['rebates.rebate-group-deleted.text']),
      });
      refetch();
      toggleDelete();
    },
  });

  return (
    <Modal
      visible={deleteShown}
      maskClosable={false}
      closable={false}
      width={416}
      okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
      cancelText={<FormattedMessage id="cancel.text" defaultMessage="Cancel" />}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleDelete}
      onOk={async () => {
        await deleteRebateGroup({
          variables: {
            id: rebateGroupState.activeRebateGroup,
          },
        });
        rebateGroupDispatch({
          type: RebateGroupTypes.RESET_ACTIVE_REBATE_GROUP,
        });
      }}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">
          <FormattedMessage id="confirm.text" defaultMessage="Confirm" />{' '}
          <FormattedMessage id="delete.text" defaultMessage="Delete" />
        </span>
        <div className="ant-modal-confirm-content">
          <FormattedMessage
            id="are-you-sure.text"
            defaultMessage="Are you sure?"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteRebateGroup;
