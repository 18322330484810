import React from 'react';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { VENDORS } from './queries';
import { StyledSelect } from '../../styles';

const { Option } = Select;

const Vendor = ({ state, setState }) => {
  const { data } = useQuery(VENDORS, {
    // variables: { first: 10 },
  });

  const edges = get(data, 'vendors.edges', []);

  const options = edges.map((edge) => edge.node);

  const handleChange = (e) => {
    if (e.length) {
      setState((prev) => ({
        ...prev,
        vendor: { in: e },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        vendor: null,
      }));
    }
  };

  return (
    <>
      <div className="mb-1">
        <FormattedMessage id="reports.vendor.text" defaultMessage="Vendor" />
      </div>
      <StyledSelect
        data-testid="select"
        allowClear
        placeholder={
          <FormattedMessage id="reports.select.text" defaultMessage="Select" />
        }
        mode="multiple"
        style={{ width: '100%' }}
        // eslint-disable-next-line react/prop-types
        value={state.vendor ? state.vendor.in : []}
        optionFilterProp="label"
        onChange={handleChange}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value} label={option.label}>
            {option.label}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};

Vendor.propTypes = {
  state: PropTypes.shape({
    member: PropTypes.array,
  }).isRequired,
  setState: PropTypes.func.isRequired,
};

export default Vendor;
