import React from 'react';
import { Select } from 'antd';
import transactionTypes from 'constants/transactionTypes';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { SelectProps } from 'interfaces/select.interface';

const TransactionTypeSelect = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
}: SelectProps) => {
  const { Option } = Select;
  const translate = useTranslate();
  const handleChange = (e: any) => {
    onChange(e);
  };

  return (
    <Select
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
    >
      {// eslint-disable-next-line no-shadow
      transactionTypes.map((transactionType, key) => (
        <Option key={key} value={transactionType}>
          {translate(messages[transactionType])}
        </Option>
      ))}
    </Select>
  );
};

export default TransactionTypeSelect;
