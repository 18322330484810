import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from 'antd';
import { DATE_FORMAT } from 'constants/date';

const { RangePicker }: any = DatePicker;

const DateRangeFilter = ({
  state,
  setState,
}: {
  state: {
    dateRange: Array<any>;
  };
  setState: (e: any) => void;
}) => (
  <div className="my-3">
    <div className="mb-1">
      <FormattedMessage
        id="reports.date-range.text"
        defaultMessage="Date Range"
      />
    </div>
    <RangePicker
      allowClear={false}
      format={DATE_FORMAT}
      value={state.dateRange}
      onChange={(dates: Array<any>) => {
        setState(
          (prevState: { reset: boolean }) =>
            ({
              ...state,
              dateRange: dates,
              ...((!dates?.length && { reset: !prevState.reset }) as any),
            } as any)
        );
      }}
    />
  </div>
);

export default DateRangeFilter;
