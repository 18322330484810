import gql from 'graphql-tag';

export const MEMBER_LOYALTY_LEVELS = gql`
  query memberLoyaltyLevels($first: Int, $input: String) {
    memberLoyaltyLevels(
      first: $first
      filter: { name: { contains: $input }, status: { in: [ACTIVE, INACTIVE] } }
    ) {
      edges {
        node {
          id
          name
          color
          programme {
            id
            name
          }
        }
      }
    }

    memberLoyaltyProgrammes(
      first: $first
      filter: { name: { contains: $input }, status: { in: [ACTIVE, INACTIVE] } }
    ) {
      edges {
        node {
          id
          name
          levels {
            id
            name
            color
          }
        }
      }
    }
  }
`;

export default {};
