import { defineMessages } from 'react-intl';

const messages = defineMessages({
  feeDeduction: {
    id: 'fee-deduction.text',
    defaultMessage: 'Fee deduction',
  },
  withdrawalTax: {
    id: 'withdrawal-tax.text',
    defaultMessage: 'Withdrawal tax',
  },
  handlingFee: {
    id: 'handling-fee.text',
    defaultMessage: 'Handling fee',
  },
  promoDeduction: {
    id: 'promo-deduction.text',
    defaultMessage: 'Promo deduction',
  },
  withdrawalLimitFee: {
    id: 'withdrawals.withdrawal-limit-fee.text',
    defaultMessage: 'Withdrawal limit fee',
  },
});

export default messages;
