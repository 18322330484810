import React from 'react';
import { Table } from 'antd';
import { Member, Admin, Promo, PromoPayout } from 'types/graphqlTypes';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/date';

export const getColumns = ({
  type,
  reportTimezone,
}: {
  type: string;
  reportTimezone: string;
}): ColumnsType<PromoPayout> => [
  {
    key: 'type',
    title: 'Promo Type',
    dataIndex: 'type',
    align: 'center',
  },
  {
    key: 'name',
    title: 'Promo Name',
    dataIndex: 'name',
    align: 'center',
  },
  {
    key: 'startDateTime',
    title: 'Start Date',
    dataIndex: 'startDateTime',
    align: 'center',
    render: (startDateTime: string) => (
      <span>
        {startDateTime &&
          moment
            .tz(startDateTime, reportTimezone)
            .format(`${DATE_TIME_FORMAT} A`)}
      </span>
    ),
  },
  {
    key: 'endDateTime',
    title: 'End Date',
    dataIndex: 'endDateTime',
    align: 'center',
    render: (endDateTime: string) => (
      <span>
        {endDateTime &&
          moment
            .tz(endDateTime, reportTimezone)
            .format(`${DATE_TIME_FORMAT} A`)}
      </span>
    ),
  },
  {
    key: 'membersAwardedBonus',
    title: 'Members Awarded Bonus (Total)',
    dataIndex: 'amount',
    align: 'center',
  },
  {
    key: 'platformId',
    title: 'Platform Id',
    dataIndex: 'member',
    align: 'center',
    render: (member: Member) => member.platformId,
  },
  {
    key: 'brandId',
    title: 'Brand Id',
    dataIndex: 'member',
    align: 'center',
    render: (member: Member) => member.brandId,
  },
  {
    key: 'brand',
    title: 'Brand',
    dataIndex: 'admin',
    align: 'center',
    render: (brand: Admin) => brand.username,
  },
  {
    ...(type === 'DEPOSIT'
      ? {
          key: 'depositAmount',
          title: 'Total Deposits by Member',
          dataIndex: 'depositAmount',
          align: 'center',
        }
      : {}),
  },

  {
    key: 'totalBonusAwarded',
    title: 'Total Bonus Awarded',
    dataIndex: 'promo',
    align: 'center',
    render: (promo: Promo) => promo.totalAmountAwarded,
  },
];

export const getPayoutTabPanes = ({
  dataSource,
  loading,
  reportTimezone,
}: {
  dataSource: PromoPayout[];
  loading: boolean;
  reportTimezone: string;
}) => [
  {
    key: 'SIGN_UP',
    title: 'Sign Up',
    content: (
      <Table
        columns={getColumns({ type: 'SIGN_UP', reportTimezone })}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
      />
    ),
    pagination: false,
  },
  {
    key: 'DEPOSIT',
    title: 'Deposit',
    content: (
      <Table
        columns={getColumns({ type: 'DEPOSIT', reportTimezone })}
        dataSource={dataSource}
        loading={loading}
        pagination={false}
      />
    ),
  },
];
