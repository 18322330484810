import React, { useEffect, useState } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { Modal, Spin } from 'antd';
import { useIntl } from 'react-intl';

import globalMessages from 'messages';
import useTranslate from 'utils/useTranslate';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';

import {
  GameCategory,
  MutationUpdateGameCategoryArgs,
} from 'types/graphqlTypes';
import { SharedStyledTable } from 'styles/SharedStyledTable';
import { messages } from './messages';
import { arrayMove } from '../../../utils/arrayMove';
import { BodyRow, columns } from './utils';
import { rowSource, rowTarget } from '../../../utils/tableDragAndDrop';

type Props = {
  gameCategories: [GameCategory];
  fetchingCategories: boolean;
  refetchCategories: (params: { variables: { language: string } }) => void;
  updateGameCategory: (params: {
    variables: MutationUpdateGameCategoryArgs;
  }) => void;
  onAddVendor: (params: GameCategory) => void;
  onEditGameCategory: (params: GameCategory) => void;
  onDeleteGameCategory: (params: GameCategory) => void;
};

// eslint-disable-next-line
let draggingIndex = -1;

const { confirm } = Modal;

const MobileAppConfigTable: React.FC<Props> = ({
  gameCategories,
  fetchingCategories,
  refetchCategories,
  updateGameCategory,
  onAddVendor,
  onEditGameCategory,
  onDeleteGameCategory,
}) => {
  const translate = useTranslate();
  const { locale } = useIntl();

  const { role, permissions } = usePermissions();

  const {
    ALLOWED_LIST,
    ALLOWED_VIEW_DETAILS,
    ALLOWED_ADD_VENDOR,
    ALLOWED_EDIT,
    ALLOWED_DELETE,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'VIEW_DETAILS', 'ADD_VENDOR', 'EDIT', 'DELETE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG}:CATEGORY`
  );

  const columnPermissions = {
    ALLOWED_ADD_VENDOR,
    ALLOWED_VIEW_DETAILS,
    ALLOWED_EDIT,
    ALLOWED_DELETE,
  };

  const [localGameCategories, setLocalGameCategories] = useState<
    GameCategory[]
  >([]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  useEffect(() => {
    setLocalGameCategories(gameCategories);
  }, [gameCategories]);

  const DraggableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }))(
    DragSource('row', rowSource(draggingIndex), (connect) => ({
      connectDragSource: connect.dragSource(),
    }))(BodyRow(draggingIndex))
  );

  const moveRow = async (dragIndex: number, hoverIndex: number) => {
    const selectedCategory = localGameCategories[dragIndex];
    const hoveredCategory = localGameCategories[hoverIndex];

    setLoadingCategories(true);
    setLocalGameCategories((prevState) =>
      arrayMove(prevState, dragIndex, hoverIndex)
    );

    await updateGameCategory({
      variables: {
        id: selectedCategory.id,
        input: {
          rank:
            selectedCategory.rank < hoveredCategory.rank
              ? hoveredCategory.rank + 1
              : hoveredCategory.rank,
        },
      },
    });

    refetchCategories({
      variables: {
        language: locale.toLocaleUpperCase(),
      },
    });

    setLoadingCategories(false);
  };

  const onDeleteConfirmModal = (gameCategory: GameCategory) => {
    confirm({
      title: translate(messages['delete-category-title.text']),
      content: translate(messages['delete-category-content.text'], {
        category: gameCategory.name,
      }),
      okText: translate(globalMessages['delete.text']),
      okType: 'danger',
      cancelText: translate(globalMessages['cancel.text']),
      onOk() {
        onDeleteGameCategory(gameCategory);
      },
    });
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  return (
    ALLOWED_LIST && (
      <Spin spinning={loadingCategories || fetchingCategories}>
        <SharedStyledTable
          pagination={false}
          style={{ marginTop: '20px' }}
          size="middle"
          columns={
            columns(
              translate,
              onAddVendor,
              onEditGameCategory,
              onDeleteConfirmModal,
              columnPermissions
            ) as any
          }
          dataSource={localGameCategories}
          components={components}
          rowKey={(data: any) => data.id}
          onRow={(_: any, index: number) =>
            ({
              index,
              moveRow,
            } as any)
          }
        />
      </Spin>
    )
  );
};

export default MobileAppConfigTable;
