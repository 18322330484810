import React from 'react';
import { useFilterValues } from 'contexts/Filters';
import coercedGet from 'utils/coercedGet';
import { Tag } from 'antd';

const FilterConditions = () => {
  const { filters, onFilterChange } = useFilterValues();

  const handleRemoveFilterTag = (
    name: string,
    value: Record<string, any>,
    e: Record<string, any>
  ) => {
    e.preventDefault();
    const field = coercedGet(filters, name, []);
    const data = field.in.filter((item: Record<string, any>) => item !== value);
    const newField = {
      in: data,
    };
    onFilterChange({
      ...filters,
      [`${name}`]: data.length ? newField : null,
    });
  };

  return (
    <>
      {filters.name
        ? filters.name.in.map((item: Record<string, any>, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('name', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {filters.type
        ? filters.type.in.map((item: Record<string, any>, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('type', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
