import React from 'react';
import supportedTimezones from 'constants/supportedTimezones';
import uuid from 'uuid';
import { momentCreator } from './MomentCreator';

const TimeZoneSelector = (isShortVersion: any) => {
  const dataTimeZone = (timeZone: any) =>
    timeZone
      .map((zoneName: any) => {
        // when tzName is Asia/Beijing we use Asia/Shanghai offset at the same time retaining the Asia/Beijing Value to be sent to the server
        // this is necessary because moment doesnt support Asia/Beijing
        const isBeijing = zoneName === 'Asia/Beijing';
        const offsetArg = isBeijing ? 'Asia/Shanghai' : zoneName;

        const getParsedEstOffset = () => {
          const timeStr = momentCreator
            .tz(offsetArg)
            .locale('zh-cn')
            .format('Z')
            .slice(0, 3);
          const timeInt = parseInt(timeStr, 10) - 4;

          const converToString = (myInt: number) =>
            Number(Math.abs(myInt)).toString();
          if (timeInt === 0) return '+00:00';
          if (timeInt > 0 && timeInt < 10)
            return `+0${converToString(timeInt)}:00`;
          if (timeInt >= 10) return `+${converToString(timeInt)}:00`;
          if (timeInt < 0 && Math.abs(timeInt) < 10)
            return `-0${converToString(timeInt)}:00`;
          if (timeInt < 0 && Math.abs(timeInt) >= 10)
            return `-${converToString(timeInt)}:00`;

          return `${timeInt}:00`;
        };

        const labelEn = isShortVersion
          ? `(GMT${momentCreator
              .tz(offsetArg)
              .locale('zh-cn')
              .format('Z')}) ${zoneName}`
          : `(GMT${momentCreator
              .tz(offsetArg)
              .locale('zh-cn')
              .format('Z')}) (EST${getParsedEstOffset()}) ${zoneName}`;

        return {
          labelEn,
          name: zoneName,
          id: uuid(),
        };
      })
      .sort((a: any, b: any) => {
        const re = /^\(GMT([+-]\d{1,2}):(\d{1,2})\).*$/;

        const aOffset = parseFloat(a.labelEn.replace(re, '$1.$2'));
        const bOffset = parseFloat(b.labelEn.replace(re, '$1.$2'));
        if (aOffset < bOffset) {
          return -1;
        }
        if (aOffset > bOffset) {
          return 1;
        }
        return 0;
      });

  return <>{dataTimeZone(supportedTimezones.en)}</>;
};

export default TimeZoneSelector;
