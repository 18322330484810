import { Dispatch } from 'react';

export const tablePaginationHandler = (
  page: Record<string, any>,
  setPage: Dispatch<any>,
  totalCount: number
) => {
  const handlePrev = () => {
    const { currentPage, cursor } = page;
    const prevPage = currentPage - 1;
    const after = cursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const handleNext = (infoPage: Record<string, any>) => {
    const { cursor, currentPage } = page;
    cursor.push(infoPage.endCursor);
    setPage({
      ...page,
      after: infoPage.endCursor,
      currentPage: currentPage + 1,
      cursor,
    });
  };

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  return { handleNext, handlePrev, totalPage };
};
