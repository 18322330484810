import gql from 'graphql-tag';

export const MEMBER_LOYALTY_LEVELS = gql`
  query memberLoyaltyLevels(
    $first: Int
    $after: Binary
    $filterProgrammes: MemberLoyaltyProgrammesFilterInput
    $filterLevels: MemberLoyaltyLevelsFilterInput
  ) {
    memberLoyaltyLevels(first: $first, after: $after, filter: $filterLevels) {
      edges {
        cursor
        node {
          id
          value: id
          label: name
          color
          name
          programme {
            id
            name
          }
        }
      }
    }

    memberLoyaltyProgrammes(
      first: $first
      after: $after
      filter: $filterProgrammes
    ) {
      edges {
        node {
          id
          name
          status
          levels {
            id
            name
            color
          }
        }
      }
    }
  }
`;

export default {};
