import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';

import { StyledModal } from './styles';
import MemberLevelForm from '../MemberLevelForm';

type Props = {
  memberLevel: Record<string, any>;
};

const EditMember = ({ memberLevel }: Props) => {
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const { color, name, status } = memberLevel;
  const translate = useTranslate();
  return (
    <>
      <ALink onClick={handleShow}>{translate(messages.EDIT)}</ALink>
      {isShow && (
        <StyledModal
          title={
            <FormattedMessage
              id="edit-member-marker.text"
              defaultMessage="Edit member marker"
            />
          }
          width={600}
          visible
          footer={false}
          onCancel={handleShow}
        >
          <MemberLevelForm
            actionType="EDIT"
            toggleModal={handleShow}
            memberLevel={memberLevel}
            initialValues={{
              color,
              name,
              status,
              default: memberLevel.default,
            }}
          />
        </StyledModal>
      )}
    </>
  );
};

export default EditMember;
