import gql from 'graphql-tag';

export const WITHDRAWAL_PROVIDER = gql`
  query WithdrawalProvider($id: ID!) {
    withdrawalProvider(id: $id) {
      name
    }
  }
`;

export default {};
