import { transformMovableDateFilter } from '../../../utils/dateUtils';

export const formatFilters = (filters: any) => {
  // eslint-disable-next-line no-param-reassign
  delete filters.quickFilter;

  return {
    ...transformMovableDateFilter(filters),
    ...(filters.memberLoyaltyLevel && {
      memberLoyaltyLevel: {
        in: filters.memberLoyaltyLevel.in.map(
          (item: { id: string }) => item.id
        ),
      },
    }),
  };
};
