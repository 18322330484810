import gql from 'graphql-tag';

export const STARRED_MEMBERS = gql`
  query StarredMembers($filter: MembersFilterInput) {
    members(filter: $filter) {
      edges {
        node {
          id
          username
          starred
          realName
          registrationDateTime
          watchlistRegistrationDateTime
          memberLevel {
            id
            name
            color
          }
        }
      }
    }
  }
`;

export const STARRED_MEMBER = gql`
  query StarredMember($id: ID!) {
    member(id: $id) {
      id
      username
      starred
      realName
      registrationDateTime
      watchlistRegistrationDateTime
      memberLevel {
        id
        name
        color
      }
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation UpdateLanguage($input: UpdateMeInput!) {
    updateMe(input: $input)
  }
`;

export const STAR_MEMBER = gql`
  mutation updateMember($id: ID!, $input: UpdateMemberInput!) {
    updateMember(id: $id, input: $input)
  }
`;
