import React, { useState } from 'react';
import { useFilterValues } from 'contexts/Filters';
import messages from 'messages';
import { StyledTagContainer } from 'styles';
import { isEqual } from 'lodash';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import useTranslate from 'utils/useTranslate';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { ALink } from 'components/ALink/ALink';
import FilterConditions from './FilterConditions';

type Props = {};

const HeaderLeft: React.FC<Props> = () => {
  const translate = useTranslate();
  const [expandedTags, toggleExpandedTags] = useState(false);
  const { defaultValues, filters, resetFilterToDefault } = useFilterValues();

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className="d-flex align-items-start flex-g-1">
      <ToggleSidebarHead />
      <StyledTagContainer ref={ref} isExpanded={expandedTags}>
        <span className="ml-1">{translate(messages.FILTER_CONDITIONS)}: </span>{' '}
        <FilterConditions />
      </StyledTagContainer>
      <LegacyIconToggle
        forwardedRef={ref}
        filters={filters}
        expandedTags={expandedTags}
        toggleExpandedTags={toggleExpandedTags}
      />
      {!isEqual(filters, defaultValues) && (
        <ALink
          onClick={() => resetFilterToDefault()}
          style={{ marginTop: '0.35rem' }}
        >
          {translate(messages.CLEAR_ALL)}
        </ALink>
      )}
    </div>
  );
};

export default HeaderLeft;
