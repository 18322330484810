import React, { ReactElement, useRef, useEffect, useState } from 'react';
import { Typography, Tooltip } from 'antd';
import styled from 'styled-components';
import { ALink } from '../ALink/ALink';

const { Paragraph } = Typography;

const StyledContainer = styled.div`
  .ant-typography {
    margin-bottom: 0;
  }
`;

// Height limit is based on px unit.
interface IProps {
  maxRows: number;
  content: any;
  maxHeight: number;
  isLink?: boolean;
}

const TruncateToolTip = ({
  maxRows,
  content,
  maxHeight,
  isLink = false,
}: IProps): ReactElement => {
  const [showToolTip, setShowToolTip] = useState(false);
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = refContainer;

    if (current && current.clientHeight > maxHeight) {
      setShowToolTip(true);
    }

    if (maxRows === 1) setShowToolTip(true);
    // eslint-disable-next-line
  }, [refContainer]);

  const ellipsis = {
    rows: maxRows,
    suffix: '...',
    expandable: false,
  };

  return (
    <StyledContainer ref={refContainer}>
      <Tooltip
        {...(!showToolTip && { visible: false })}
        title={<span>{content}</span>}
      >
        {isLink ? (
          <Paragraph ellipsis={ellipsis}>
            <ALink href="#">{content}</ALink>
          </Paragraph>
        ) : (
          <Paragraph ellipsis={ellipsis}>{content}</Paragraph>
        )}
      </Tooltip>
    </StyledContainer>
  );
};

TruncateToolTip.defaultProps = {
  maxRows: 1,
  content: '',
  maxHeight: 42,
};

export default TruncateToolTip;
