import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'date-time.text': {
    id: 'date-time.text',
    defaultMessage: 'Date/Time',
  },
  'member-id.text': {
    id: 'member-id.text',
    defaultMessage: 'Member ID',
  },
  'no-data.text': {
    id: 'no-data.text',
    defaultMessage: 'No Data',
  },
  'member-affiliate.text': {
    id: 'member-affiliate.text',
    defaultMessage: 'Member Affiliate',
  },
  'not-applicable.text': {
    id: 'not-applicable.text',
    defaultMessage: 'N/A',
  },
  'not-available.text': {
    id: 'not-available.text',
    defaultMessage: 'Not Available',
  },
  'vip-level.text': {
    id: 'vip-level.text',
    defaultMessage: 'VIP Level',
  },
  'promo-name.text': {
    id: 'promo-name.text',
    defaultMessage: 'Promo Name',
  },
  'promo-tier.text': {
    id: 'promo-tier.text',
    defaultMessage: 'Promo Tier',
  },
  'amount-accumulated.text': {
    id: 'amount-accumulated.text',
    defaultMessage: 'Amount Accumulated',
  },
  'amount-payout.text': {
    id: 'amount-payout.text',
    defaultMessage: 'Amount Payout',
  },
  'total-amount-to-be-payout.text': {
    id: 'total-amount-to-be-payout.text',
    defaultMessage: 'Total Amount to be Payout',
  },
  'total-amount-claimed.text': {
    id: 'total-amount-claimed.text',
    defaultMessage: 'Total Amount Claimed',
  },
  'amount-expired.text': {
    id: 'amount-expired.text',
    defaultMessage: 'Amount Expired',
  },
  'clawback.text': {
    id: 'clawback.text',
    defaultMessage: 'Clawback',
  },
  'rebate-programme-name.text': {
    id: 'rebate-programme-name.text',
    defaultMessage: 'Rebate Programme Name',
  },
  'rebate-programme-tier.text': {
    id: 'rebate-programme-tier.text',
    defaultMessage: 'Rebate Programme Tier',
  },
});

export default messages;
