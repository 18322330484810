import { DownOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, Col, message, Popconfirm, Row, Spin } from 'antd';
import { ContextProps } from 'contexts/Filter';
import {
  DELETE_CUSTOM_FILTER,
  UPDATE_CUSTOM_FILTER,
} from 'graphql/mutations/customFilter.mutation';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import styled from 'styled-components';
import { CustomFilter } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';

const Wrapper = styled.div`
  .row-item {
    display: flex;
    justify-content: space-between;
  }
`;

type Props = {
  FilterSettings: React.ElementType;
  handleSelect: (id: string) => void;
  customFilters: ContextProps['customFilters'];
};

const SavedFilterList: React.FC<Props> = ({
  FilterSettings,
  customFilters,
  handleSelect,
}) => {
  const translate = useTranslate();
  const { loading, refetch, filters } = customFilters;

  const [active, setActive] = useState<number | null>(null);
  const [formValue, setFormValue] = useState({});

  const [deleteCustomFilter, { loading: deleteLoading }] = useMutation(
    DELETE_CUSTOM_FILTER,
    {
      onCompleted: () => {
        refetch();
        message.success(
          `${translate(messages['search-settings-deleted.text'])}`
        );
        setActive(null);
      },
    }
  );

  const [updateCustomFilter, { loading: updateLoading }] = useMutation(
    UPDATE_CUSTOM_FILTER,
    {
      onCompleted: () => {
        refetch();
        message.success(
          `${translate(messages['search-settings-updated.text'])}`
        );
        setActive(null);
      },
    }
  );

  const handleFilterChange = (params: { name: string; value: any }) => {
    setFormValue({ ...formValue, [`${params.name}`]: params.value });
  };

  if (loading)
    return (
      <div className="p-6 text-center">
        <FormattedMessage id="loading.text" defaultMessage="Loading" />
        ...
      </div>
    );

  return (
    <Wrapper>
      <Spin spinning={deleteLoading || updateLoading}>
        {filters.length ? (
          (filters as any[]).map(
            (customFilter: CustomFilter, index: number) => (
              <div key={customFilter?.id} className="bb-1 py-3">
                <div className="d-flex justify-content-space-between">
                  <span>
                    <ALink
                      onClick={() => {
                        handleSelect(customFilter?.id);
                      }}
                    >
                      {customFilter?.name}
                    </ALink>
                  </span>
                  <span className="d-flex">
                    <ALink
                      className="mr-3"
                      onClick={(e: any) => {
                        e.preventDefault();
                        setFormValue(customFilter?.filter);
                        setActive(active === index ? null : index);
                      }}
                    >
                      <FormattedMessage
                        id="modify.text"
                        defaultMessage="Modify"
                      />{' '}
                      <DownOutlined />
                    </ALink>
                    <Popconfirm
                      title={translate(messages.DELETE_CONFIRMATION_BODY_TEXT)}
                      onConfirm={() => {
                        deleteCustomFilter({
                          variables: { id: customFilter?.id },
                        });
                      }}
                      okText={translate(messages.OK)}
                      cancelText={translate(messages['cancel.text'])}
                    >
                      <ALink>
                        <FormattedMessage
                          id="delete.text"
                          defaultMessage="Delete"
                        />
                      </ALink>
                    </Popconfirm>
                  </span>
                </div>
                {active === index && (
                  <>
                    <Row>
                      <Col span={12}>
                        <FilterSettings
                          filters={formValue}
                          handleFilterChange={handleFilterChange}
                        />
                      </Col>
                    </Row>
                    <div className="mt-2 text-right">
                      <Button
                        type="default"
                        className="mr-2"
                        onClick={() => {
                          setActive(null);
                        }}
                      >
                        {translate(messages['cancel.text'])}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          updateCustomFilter({
                            variables: {
                              id: customFilter?.id,
                              input: {
                                filter: formValue,
                              },
                            },
                          });
                        }}
                      >
                        {translate(messages['save.text'])}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          )
        ) : (
          <div className="py-3 text-center">
            {translate(messages['no-data-yet.text'])}
          </div>
        )}
      </Spin>
    </Wrapper>
  );
};

export default SavedFilterList;
