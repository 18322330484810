import React from 'react';
import styled from 'styled-components';
import { SideLeft } from './SideLeft/SideLeft';
import { SideRight } from './SideRight/SideRight';

// eslint-disable-next-line arrow-body-style
export const AMHeader: React.FC = () => {
  return (
    <Container>
      <SideLeft />
      <SideRight />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  font-size: 14px;
`;
