import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'member-management.member-tag-update-success.text': {
    id: 'member-management.member-tag-update-success.text',
    defaultMessage: 'Member tags successfully updated',
  },
});

export default messages;
