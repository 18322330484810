import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Popover, Tag } from 'antd';
import { truncateProgramName } from 'utils/truncateProgramName';
import { FormattedMessage } from 'react-intl';
import { buildMessageType } from '../../CreateNewMail/util';

const MEMBER_LOYALTY_LEVELS = gql`
  query MemberLoyaltyLevel($id: ID!) {
    memberLoyaltyLevel(id: $id) {
      id
      name
      programme {
        id
        name
      }
    }
  }
`;

export const MemberLoyaltyLevelsTags = ({
  id,
  type,
  onClose,
}: IMemberLoyaltyLevelsTags) => {
  const { data, loading } = useQuery(MEMBER_LOYALTY_LEVELS, {
    variables: {
      id,
    },
  });

  if (loading) {
    return (
      <Tag>
        <LoadingOutlined />
      </Tag>
    );
  }

  const { memberLoyaltyLevel } = data;

  if (!loading) {
    const { text, isHoverable } = truncateProgramName(
      memberLoyaltyLevel.programme.name
    );
    return (
      <Popover
        {...(!isHoverable && { visible: false })}
        content={
          <div>
            {memberLoyaltyLevel.programme.name} - {memberLoyaltyLevel.name}
          </div>
        }
      >
        <Tag closable onClose={() => onClose(memberLoyaltyLevel)}>
          <strong>
            {type === buildMessageType.target && (
              <FormattedMessage
                id="target-members.text"
                defaultMessage="Target Members"
              />
            )}
            {type === buildMessageType.excluded && (
              <FormattedMessage
                id="excluded-members.text"
                defaultMessage="Excluded Members"
              />
            )}
          </strong>
          :{text} - {memberLoyaltyLevel.name}
        </Tag>
      </Popover>
    );
  }

  return <></>;
};

interface IMemberLoyaltyLevelsTags {
  id: string;
  type: 'target' | 'excluded' | string;
  onClose: (e: any) => void;
}
