import { isArray, isEmpty, isObject } from 'lodash';

// not this guys [], {}, false, '', null, undefined, 0
const isTruthyValues = (value: any) => {
  if (isObject(value) || isArray(value)) {
    return !isEmpty(value);
  }

  return !!value;
};

export default (state: any = {}, removeEmptyValue = false): any => {
  const cleanState = {};
  Object.keys(state).forEach((key) => {
    const value = state[key];
    if (removeEmptyValue) {
      if (isTruthyValues(value)) {
        cleanState[key] = value;
      }
    } else if (value !== null) {
      cleanState[key] = value;
    }
  });
  return cleanState as any;
};
