import { useMutation } from '@apollo/react-hooks';
import { Button, Col, message, Row, Select } from 'antd';
import { DATE_FORMAT } from 'constants/date';
import { SELF_EXCLUDE_MEMBER } from 'graphql/mutations/member.mutation';
import MemberDropdown from 'pages/components/NewMemberDetail/components/MemberDropdown';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import {
  ContainerBody,
  ContainerHeader,
} from 'pages/components/NewMemberDetail/styles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PermissionError from 'components/PermissionError';
import {
  Member,
  MemberStatus,
  MutationSelfExcludeMemberArgs,
} from 'types/graphqlTypes';
import { formatDate } from 'utils/dateUtils';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import isRow from 'utils/isRow';

const options = [
  {
    label: 'Select Period',
    value: 0,
    disabled: true,
  },
  {
    label: '6 months',
    value: 15778476000,
  },
  {
    label: '1 year',
    value: 31556952000,
  },
  ...(isRow
    ? []
    : [
        {
          label: '2 years',
          value: 63113904000,
        },
      ]),
  {
    label: '5 years',
    value: 157784760000,
  },
];

const MemberSelfExclusion = () => {
  const [showTable, setShowTable] = useState(true);

  const [selectedPeriod, setSelectedPeriod] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const { MEMBER_SELF_EXCLUSION_PERMISSION } = useNewMemberDetailPermissions();

  const { member, refetch } = useMember() as {
    member: Member;
    refetch: Function;
  };
  const { context } = useOperatorHeader();

  const [selfExcludeMember] = useMutation<
    boolean,
    MutationSelfExcludeMemberArgs
  >(SELF_EXCLUDE_MEMBER);

  const onSelfExcludeMember = () => {
    setLoading(true);
    selfExcludeMember({
      variables: {
        id: member?.id,
        input: {
          period: selectedPeriod,
        },
      },
      context,
    })
      .then(() => {
        message.success('Member self-excluded successfully');
        refetch();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isSelfExcluded =
    member?.status === MemberStatus.SelfExcluded ||
    member?.status === MemberStatus.GamstopSelfExcluded;

  return (
    <div className="mt-3">
      <ContainerHeader>
        <span className="fw-500">
          <FormattedMessage
            id="INTERNAL_SELF_EXCLUSION"
            defaultMessage="Internal Self-Exclusion"
          />
        </span>
        <MemberDropdown showTable={showTable} setShowTable={setShowTable} />
      </ContainerHeader>
      <ContainerBody
        className="p-3"
        style={{ display: showTable ? 'block' : 'none' }}
      >
        <PermissionError
          message="Member self exclusion"
          withPermission={MEMBER_SELF_EXCLUSION_PERMISSION.ALLOWED_LIST}
        >
          <>
            <div className="d-flex">
              <span className="mr-2">Self-Exclusion:</span>
              <span>{isSelfExcluded ? 'Yes' : 'No'}</span>
            </div>
            <div className="d-flex">
              <span className="mr-2">Start Date:</span>
              <span>
                {member?.selfExclusionStartDateTime
                  ? formatDate(member?.selfExclusionStartDateTime, DATE_FORMAT)
                  : ''}
              </span>
            </div>
            <div className="d-flex">
              <span className="mr-2">End Date:</span>
              <span>
                {member?.selfExclusionEndDateTime
                  ? formatDate(member?.selfExclusionEndDateTime, DATE_FORMAT)
                  : ''}
              </span>
            </div>
            <div className="d-flex">
              <span className="mr-2">Set By:</span>
              <span>{member?.selfExclusionSetBy}</span>
            </div>

            <div className="mt-3">
              <PermissionError
                message="Member self-exclusion Edit"
                withPermission={MEMBER_SELF_EXCLUSION_PERMISSION.ALLOWED_EDIT}
              >
                <Row gutter={8}>
                  <Col span={7}>
                    <Select
                      value={selectedPeriod}
                      onSelect={(e) => setSelectedPeriod(e)}
                      style={{ width: '100%' }}
                    >
                      {options?.map((option) => (
                        <Select.Option value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>

                  <Col span={6}>
                    <Button
                      onClick={onSelfExcludeMember}
                      disabled={loading || !selectedPeriod}
                      loading={loading}
                    >
                      Save Exclusion
                    </Button>
                  </Col>
                </Row>
              </PermissionError>
            </div>
          </>
        </PermissionError>
      </ContainerBody>
    </div>
  );
};

export default MemberSelfExclusion;
