import { Typography } from 'antd';
import { ErrorHandler } from 'components/ErrorHandler';
import Layout from 'components/Layout';
import NextPrev from 'components/NextPrev';
import { useFilterValues } from 'contexts/Filters';
import {
  MEMBER_LOYALTY_PROGRAMS,
  MEMBER_LOYALTY_PROGRAMS_IDS,
} from 'graphql/queries/vip.query';
import {
  allTypes as memberLoyaltyTypes,
  useMemberLoyaltyProgrammeContext,
} from 'pages/components/MemberLoyaltyManagement/context';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { TableFooterStyled } from 'styles';
import coercedGet from 'utils/coercedGet';
import { customFormatMessage } from 'utils/customFormatMessage';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import { useTablePagination } from 'utils/usePagination';
import TableFooterPages from './components/TableFooterPages';
import VIPTable from './components/VIPTable';

type Props = {
  toggleOverlay: () => void;
};

const messagesLocal = defineMessages({
  'results-found.text': {
    id: 'results-found.text',
    defaultMessage: '{count} Results found',
  },
});

const Content = React.forwardRef((props: Props, ref: any) => {
  const { toggleOverlay } = props;
  const { formatMessage } = useIntl();
  const { filters } = useFilterValues() as any;
  const [, memberLoyaltyDispatch] = useMemberLoyaltyProgrammeContext();

  const pageInitState = {
    first: 10,
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
    sort: 'descend',
  };

  const [page, dispatchPagination, actions] = useTablePagination(pageInitState);

  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);

  const {
    loading,
    error,
    data = {} as any,
    refetchVariables,
    refetch,
  } = usePartialFiltersQuery(
    MEMBER_LOYALTY_PROGRAMS_IDS,
    MEMBER_LOYALTY_PROGRAMS,
    'memberLoyaltyProgrammes.edges',
    filters,
    page,
    ['name']
  );

  React.useImperativeHandle(ref, () => ({
    refetchTable: refetch,
  }));

  const { totalCount, pageInfo } = coercedGet(
    data,
    'memberLoyaltyProgrammes',
    {}
  );

  const edges = coercedGet(data, 'memberLoyaltyProgrammes.edges', []);
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  React.useEffect(() => {
    memberLoyaltyDispatch({
      type: memberLoyaltyTypes.SET_REFETCH_VARIABLES,
      payload: refetchVariables,
    });
    memberLoyaltyDispatch({
      type: memberLoyaltyTypes.SET_TOTAL_PROG_COUNT,
      payload: totalCount,
    });
  }, [
    page,
    filters,
    loading,
    memberLoyaltyDispatch,
    totalCount,
    refetchVariables,
  ]);

  return (
    <>
      <Layout.Container data-testid={error ? 'error' : 'table'}>
        <Layout.Content
          sideBar={<></>}
          footer={
            <TableFooterStyled>
              <Typography.Text strong className="mr-1">
                {`${totalCount}`}
              </Typography.Text>
              {`${translate(messagesLocal['results-found.text'])}`}
              <NextPrev
                onPrev={() =>
                  dispatchPagination({
                    type: actions.PREV,
                  })
                }
                disablePrev={page.currentPage + 1 === 1}
                onNext={() =>
                  dispatchPagination({
                    type: actions.NEXT,
                    payload: pageInfo,
                  })
                }
                disableNext={page.currentPage + 1 === totalPage}
                label={`${page.currentPage + 1} / ${totalPage}`}
              />
              <TableFooterPages
                first={page.first}
                setPage={(rowCount?: number) => {
                  dispatchPagination({
                    type: actions.SETSTATE,
                    payload: {
                      ...pageInitState,
                      first: rowCount,
                    },
                  });
                }}
              />
            </TableFooterStyled>
          }
        >
          <ErrorHandler>
            <VIPTable
              error={error}
              loading={loading}
              data={edges}
              toggleView={toggleOverlay}
              refetchVariables={refetchVariables}
            />
          </ErrorHandler>
        </Layout.Content>
      </Layout.Container>
    </>
  );
});

export default Content;
