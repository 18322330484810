import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Radio } from 'antd';
import DrawerFooter from 'components/DrawerFooter';
import coercedGet from 'utils/coercedGet';
import { useMutation } from '@apollo/react-hooks';
import Drawer from '../../../common/Drawer/Drawer';
import { useMissionsValue } from '../../context';
import {
  CREATE_MISSION,
  formatGeneralSettings,
  formatMilestone,
  formatTaskReward,
  formatVipGroups,
  radioStyle,
  StepCard,
} from './utils';

const ReadyToPublish = () => {
  const {
    onPrevStep,
    getAllValues,
    hideMilestone,
    selectedNewMission,
    resetDrawer,
  } = useMissionsValue();

  const [createMission] = useMutation(CREATE_MISSION);

  const milestoneHide =
    !coercedGet(getAllValues(), 'milestonesValue.milestonesEnabled', false) ||
    hideMilestone;

  const onCreateMission = () => {
    const allValue = {
      ...formatGeneralSettings(getAllValues, selectedNewMission),
      ...formatTaskReward(getAllValues, selectedNewMission),
      ...formatMilestone(getAllValues, milestoneHide),
      ...formatVipGroups(getAllValues),
    };
    createMission({
      variables: {
        input: allValue,
      },
    })
      .then(() => {
        resetDrawer();
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Drawer.Content style={{ margin: '0 25%' }}>
        <h1 className="mb-0 text-black--65" style={{ fontSize: '40px' }}>
          <FormattedMessage
            id="YOURE_READY_TO_PUBLISH"
            defaultMessage="You're ready to publish!"
          />
        </h1>
        <h2 className="fs-16 text-black--65" style={{ width: '40%' }}>
          <FormattedMessage
            id="MISSION_READY_TO_PUBLISH_INFO"
            defaultMessage="If you’d like to update your Mission setting, you can update by clicking edit button."
          />
        </h2>

        <section className="mt-4">
          <StepCard stepNumber={1} />
          <StepCard stepNumber={2} />
          {getAllValues()?.taskRewardValue?.dayRewardEnabled ? (
            <StepCard stepNumber={3} />
          ) : (
            <></>
          )}
          <StepCard stepNumber={hideMilestone ? 3 : 4} />
        </section>

        <section className="mt-3">
          <p className="fs-15 mb-0">
            <FormattedMessage id="STATUS" defaultMessage="Status" />
          </p>
          <Radio.Group value="ACTIVE">
            <Radio style={radioStyle} value="ACTIVE">
              <FormattedMessage id="ACTIVE" defaultMessage="Active" />
            </Radio>
            <Radio style={radioStyle} value="INACTIVE">
              <FormattedMessage id="INACTIVE" defaultMessage="Inactive" />
            </Radio>
            <Radio style={radioStyle} value="DRAFT">
              <FormattedMessage id="DRAFT" defaultMessage="Draft" />
            </Radio>
          </Radio.Group>
        </section>
      </Drawer.Content>
      <DrawerFooter>
        <>
          <Button onClick={() => onPrevStep()}>
            <FormattedMessage id="BACK" defaultMessage="Back" />
          </Button>
          <Button onClick={onCreateMission} type="primary">
            <FormattedMessage id="CONTINUE" defaultMessage="Continue" />
          </Button>
        </>
      </DrawerFooter>
    </>
  );
};

export default ReadyToPublish;
