import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { FilterItem } from 'components/FilterItem/FilterItem';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';

export const StyledInput = styled(Input)`
  .ant-input {
    border: 0 !important;
    border-radius: 0 !important;
  }
`;

export const SearchFilterInput = ({
  type,
  inputValueRef,
  onChange,
}: {
  type: string;
  inputValueRef: any;
  onChange: (type: string, value: string) => void;
}) => {
  const translate = useTranslate();

  return (
    <FilterItem label={translate(messages[`${type}.text`])}>
      <StyledInput
        value={inputValueRef}
        onChange={({ target: { value } }) => onChange(type, value)}
        placeholder={translate(messages.SEARCH)}
        allowClear
      />
    </FilterItem>
  );
};
