import gql from 'graphql-tag';

export const GET_MEMBER_INTERACTION_LOGS = gql`
  query MemberInteractionLogs(
    $first: Int
    $after: Binary
    $filter: MemberInteractionLogFilterInput!
  ) {
    memberInteractionLogs(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          shortId
          dateTimeCreated
          member {
            id
            username
            brandId
            platformId
            __typename
          }
          type
          module
          manual
          actor {
            type
            account {
              id
              username
              __typename
            }
            channel
          }
          deposit {
            id
            serialCode
            remarks
            __typename
          }
          withdrawal {
            id
            serialCode
            remarks
            __typename
          }
          memberLoyaltyLevel {
            id
            name
            programme {
              id
              name
            }
            __typename
          }
          vendor {
            id
            name
            shortcode
            __typename
          }
          remarks
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const MEMBERS = gql`
  query Members($first: Int, $after: Binary, $filter: MembersFilterInput) {
    members(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          username
          value: username
          label: username
        }
      }
    }
  }
`;

export const GET_MEMBER_BRAND_IDS = gql`
  query Members($first: Int, $after: Binary, $filter: MembersFilterInput) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          brandId
        }
      }
    }
  }
`;

export const GET_MEMBER_PLATFORM_IDS = gql`
  query Members($first: Int, $after: Binary, $filter: MembersFilterInput) {
    members(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          platformId
        }
      }
    }
  }
`;

export default {};
