import React from 'react';
import moment from 'moment';
import { Badge, Table, Row, Col, Input } from 'antd';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import styled from 'styled-components';
import MemberTagsSelect from 'components/MemberTagsSelect';
import MemberLoyaltyTagList from 'components/MemberLoyaltyTagList/MemberLoyaltyTagList';
import messages from 'messages';
import { useConfig } from 'hooks/useConfig';
import { DATE_TIME_FORMAT } from 'constants/date';
import { AffiliateRequest, AffiliateRequestStatus } from 'types/graphqlTypes';
import {
  AffiliateRequestStatusColor,
  AffiliateRequestActions,
  toCapitalizedCase,
} from '../../../constants';
import {
  memberInfoFields,
  memberInfoInternalFields,
  othersFields,
} from '../constants';

const StyledTable = styled(Table)`
  .ant-table-thead {
    padding: 10px 16px !important;
  }
`;

type Props = {
  summary: AffiliateRequest;
  remarks: string;
  setRemarks: (val: string) => void;
};

const ModalContent = (props: Props) => {
  const { summary, remarks, setRemarks } = props;
  const { status = '' } = summary;
  const translate = useTranslate();
  const { currency } = useConfig();

  const labelItem = (e: any) => `${e[1].replace(/ /g, '-').toLowerCase()}.text`;

  const memberInfoDataSource = Object.entries(memberInfoFields).map((e) => {
    if (e[0].includes('edges')) {
      const edges = coercedGet(summary, `${e[0]}`, []);
      if (edges.length) {
        const accountNumbers = edges.map(({ node }: any) => node.accountNumber);
        return {
          item: {
            label: translate(messages[labelItem(e)]),
            value: accountNumbers.join(', '),
          },
        };
      }

      return {
        item: {
          label: translate(messages[labelItem(e)]),
          value: '',
        },
      };
    }
    if (e[0].includes('isNotProper')) {
      const id = coercedGet(summary, `${e[0]}`.replace('.isNotProper', ''), '');
      return {
        item: {
          label: translate(messages[labelItem(e)]),
          value: toCapitalizedCase(translate(messages[id])),
        },
      };
    }

    return {
      item: {
        label: translate(messages[labelItem(e)]),
        value: coercedGet(summary, `${e[0]}`, ''),
      },
    };
  });
  const memberInfoInternalDataSource = Object.entries(
    memberInfoInternalFields
  ).map((e) => ({
    item: {
      label: translate(messages[labelItem(e)]),
      value: e[0].includes('tags') ? (
        <MemberTagsSelect
          disableClosable
          disableAddLabel
          value={coercedGet(summary, `${e[0]}`, [])}
        />
      ) : (
        coercedGet(summary, `${e[0]}`, '')
      ),
    },
  }));
  const othersDataSource = Object.entries(othersFields).map((e) => {
    if (e[0].includes('memberLoyaltyLevel')) {
      const loyaltyLevels = coercedGet(
        summary,
        'member.memberLoyaltyLevels',
        []
      );

      return {
        item: {
          label: translate(messages[labelItem(e)]),
          value: loyaltyLevels.length ? (
            <MemberLoyaltyTagList loyaltyLevels={loyaltyLevels} />
          ) : null,
        },
      };
    }
    if (e[0].includes('totalTurnover') || e[0].includes('totalPromoPayout')) {
      return {
        item: {
          label: translate(messages[labelItem(e)]),
          value: `${currency?.symbol} ${+coercedGet(summary, `${e[0]}`, '')}`,
        },
      };
    }
    if (e[0].includes('status')) {
      return {
        item: {
          label: translate(messages[labelItem(e)]),
          value: (
            <Badge
              text={`${toCapitalizedCase(translate(messages[status]))}`}
              status={coercedGet(
                AffiliateRequestStatusColor,
                `${status}`,
                'default'
              )}
            />
          ),
        },
      };
    }
    if (e[0].includes('date') || e[0].includes('Date')) {
      const dateValue = moment(coercedGet(summary, `${e[0]}`, '')).format(
        DATE_TIME_FORMAT
      );
      if (e[0] === 'dateTimeUpdated') {
        if (status === AffiliateRequestStatus.Approved) {
          return {
            item: {
              label: translate(messages['approved-date.text']),
              value: dateValue,
            },
          };
        }
        if (status === AffiliateRequestStatus.Rejected) {
          return {
            item: {
              label: translate(messages['rejected-date.text']),
              value: dateValue,
            },
          };
        }
        if (status === AffiliateRequestStatus.Processing) {
          return {
            item: {
              label: translate(messages.PROCESSING_DATE),
              value: dateValue,
            },
          };
        }
        if (status === AffiliateRequestStatus.Pending) {
          return {
            item: {
              label: translate(messages['pending.text']),
              value: dateValue,
            },
          };
        }

        return {
          item: {
            label: e[1],
            value: '',
          },
        };
      }

      return {
        item: {
          label: translate(messages[labelItem(e)]),
          value: dateValue !== 'Invalid date' && dateValue,
        },
      };
    }
    if (e[0].includes('processor.username')) {
      if (status === AffiliateRequestActions.APPROVED) {
        return {
          item: {
            label: translate(messages[labelItem(e)]),
            value: coercedGet(summary, `${e[0]}`, ''),
          },
        };
      }
      if (status === AffiliateRequestActions.REJECTED) {
        return {
          item: {
            label: translate(messages['rejected-by.text']),
            value: coercedGet(summary, `${e[0]}`, ''),
          },
        };
      }
      return {
        item: {
          label: translate(messages[labelItem(e)]),
          value: '',
        },
      };
    }

    return {
      item: {
        label: e[1],
        value: coercedGet(summary, `${e[0]}`, ''),
      },
    };
  });

  const customFields = coercedGet(summary, 'formFields', []).filter(
    (field: any) => field.type === 'CUSTOM'
  );

  const formFieldValues = coercedGet(summary, 'formFieldValues', []);

  const newFormFieldValues = formFieldValues.slice(8, formFieldValues.length);

  const customFormDataSource = customFields.map(
    ({ label }: any, index: number) => ({
      item: {
        label,
        value: newFormFieldValues[index] || '',
      },
    })
  );

  return (
    <Row gutter={10}>
      <Col span={8} className="gutter-row">
        <StyledTable
          dataSource={memberInfoDataSource}
          pagination={false}
          columns={[
            {
              title: translate(messages['member-info.text']),
              className: 'py-2',
              dataIndex: 'item',
              render: (_: any, record: any) => (
                <Row>
                  <Col span={12}>{record.item.label}</Col>
                  <Col span={12}>{record.item.value}</Col>
                </Row>
              ),
            },
          ]}
        />
      </Col>
      <Col span={8} className="gutter-row">
        <StyledTable
          dataSource={memberInfoInternalDataSource}
          pagination={false}
          columns={[
            {
              title: translate(messages['member-info-internal.text']),
              className: 'py-2',
              dataIndex: 'item',
              render: (_: any, record: any) => (
                <>
                  <Row>{record.item.label}</Row>
                  <Row className="mt-1">{record.item.value}</Row>
                </>
              ),
            },
          ]}
        />
        <StyledTable
          pagination={false}
          dataSource={othersDataSource}
          className="mt-2"
          columns={[
            {
              title: translate(messages['others.text']),
              className: 'py-2',
              dataIndex: 'item',
              render: (_: any, record: any) => (
                <Row>
                  <Col span={12}>{record.item.label}</Col>
                  <Col span={12}>{record.item.value}</Col>
                </Row>
              ),
            },
          ]}
        />
      </Col>
      <Col span={8} className="gutter-row">
        <StyledTable
          className="mb-2"
          pagination={false}
          dataSource={customFormDataSource}
          columns={[
            {
              title: translate(
                messages['information-from-custom-form-field.text']
              ),
              className: 'py-2',
              render: (_: any, record: any) => (
                <>
                  <Row>{record.item.label}</Row>
                  <Row className="mt-1">{record.item.value}</Row>
                </>
              ),
            },
          ]}
        />
        <StyledTable
          pagination={false}
          dataSource={['dummy' as {}]}
          columns={[
            {
              title: translate(messages['operator-remark.text']),
              className: 'py-2',
              render: () =>
                !Object.keys(AffiliateRequestActions).includes(status) ? (
                  <Input.TextArea
                    rows={4}
                    value={remarks}
                    onChange={({ target: { value } }) => setRemarks(value)}
                  />
                ) : (
                  <div>{remarks}</div>
                ),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

ModalContent.defaultProps = {
  summary: {},
  remarks: '',
};

export default ModalContent;
