import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { AccountHeader } from './AccountHeader/AccountHeader';
import { AccountBody } from './AccountBody/AccountBody';
import { Account360Provider } from './AccountContext';

type Props = {
  accountId: string;
};

// eslint-disable-next-line arrow-body-style
export const Account360: React.FC<Props> = ({ accountId }) => {
  return (
    <Account360Provider accountId={accountId}>
      <Container>
        <AccountHeader />
        <AccountBody />
      </Container>
    </Account360Provider>
  );
};

const Container = styled(Layout)`
  background: #f3f8fb;
  height: calc(100vh - 80px);
`;
