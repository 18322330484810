import numeral from 'numeral';
import currencyFormatter from 'currency-formatter';

export default (
  currency = '',
  number: number,
  decimalPlace = 0,
  roundingFunction: any = undefined
) => {
  let newSymbol = '';
  if (currency) {
    newSymbol = currencyFormatter.findCurrency(currency).symbol;
  }
  let newDigits = '';
  for (let j = 0; j < decimalPlace; j += 1) {
    newDigits += '0';
  }
  return `${newSymbol} ${numeral(number).format(
    `0,0.${newDigits}`,
    roundingFunction
  )}`;
};
