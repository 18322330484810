import styled from 'styled-components';
import { Input } from 'antd';
import { Icon } from '@ant-design/compatible';
import { get } from 'lodash';
import { findColorByBgColor } from 'utils/findColorByBgColor';

export const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledInput = styled(Input)`
  .anticon-search {
    color: #989898;
    font-size: 18px;
  }

  .ant-input {
    background: #f5f5f5;
    border-color: #f5f5f5;
    padding-left: 40px !important;
  }
`;

export const StyledSectionBody = styled(StyledSection as any)`
  margin-top: 10px;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  font-size: 18px;
  color: #8f8f8f;
`;

export const StyledMemberLevel = styled.div`
  border-radius: 100px;
  padding: 0 10px;
  background-color: ${(props: { color: string }) =>
    get(findColorByBgColor(props.color as string), 'bgColor', '#efefef')};
  border: 1px solid
    ${(props: { color: string }) =>
      get(findColorByBgColor(props.color), 'borderColor', '#888888')};
`;
