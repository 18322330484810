import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
  [key: string]: any;
};

const StyledAnchor = styled.a`
  :hover {
    text-decoration: underline;
  }
`;

export const ALink: React.FC<Props> = ({ children, ...rest }) => (
  <StyledAnchor {...rest}>{children}</StyledAnchor>
);
