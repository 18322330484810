import axios from 'axios';

const { config } = window;

export const getAccess = async (adminCode: string) =>
  new Promise((res, rej) => {
    axios
      .get(config.authUrl, {
        headers: {
          'Geofence-Type': 'backend',
          'Admin-Code': adminCode,
        },
      })
      .then(() => res(null))
      .catch((err) => rej(err.response.data));
  });
