import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import { Row, Col, Button } from 'antd';
import styled from 'styled-components';
import FilterItems from '../FilterItems/FilterItems';

type Props = {
  initialValues: { [key: string]: any };
  onSubmit: (values: any) => void;
  onClose: () => void;
  isLoading: boolean;
};

const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

const SearchSettingsForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}) => {
  const [stateFilters, setStateFilters] = useState(initialValues);

  return (
    <StyledForm>
      <Row>
        <Col span={12}>
          <FilterItems
            filters={stateFilters}
            onFilterChange={setStateFilters}
            stateFilters={stateFilters}
            isCustomFilter
            isLoading={isLoading}
          />
        </Col>
      </Row>

      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose} loading={isLoading}>
          Cancel
        </Button>{' '}
        <Button
          onClick={() => onSubmit(stateFilters)}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Save Changes
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
