import React from 'react';
import { checkPermission } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { showPermissionError } from 'components/Navbar/Navbar';
import { useMemberTab } from 'utils/handleAddTab';
import { ALink } from 'components/ALink/ALink';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import coercedGet from 'utils/coercedGet';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';

type Props = {
  memberId: string;
};

const MemberProfile: React.FC<Props> = ({ memberId }) => {
  const addMemberTab = useMemberTab();

  const { role, permissions } = usePermissions();
  const isAllowed = checkPermission(
    role,
    permissions,
    ALL_PERMISSIONS.ALL_MEMBERS.MEMBERS_MEMBER_MANAGEMENT_VIEW_DETAILS
  );

  const { usernameLoaderSet } = useLoaders();

  const [useUsernameLoader] = usernameLoaderSet;

  const { loading, error, data = {} } = useUsernameLoader(memberId);

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;

  const username = coercedGet(data, 'username', '');

  const handleClick = () => {
    if (isAllowed) {
      addMemberTab(data);
    } else {
      showPermissionError('Member Management - View Details');
    }
  };

  return <ALink onClick={handleClick}> {username}</ALink>;
};

export default MemberProfile;
