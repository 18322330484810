import Layout from 'components/Layout';
import SavedFilterSelect from 'components/SavedFilterSelect';
import { useFilterValues } from 'contexts/Filters';
import messages from 'messages';
import React, { useState } from 'react';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import useTranslate from 'utils/useTranslate';
import SearchSettingsForm from '../SearchSettingsForm';
import FilterItems from '../FilterItems';

type Props = {};

const Sidebar: React.FC<Props> = () => {
  const {
    savedFilterId,
    handleSavedFilterIdChange,
    handleFilterChange,
    defaultValues,
    filters,
    onFilterChange,
  } = useFilterValues() as any;

  const [key, setKey] = useState(0);

  /**
   * Re-renders the <SavedFilterSelect/> to update the list of filters
   */
  const handleUpdateKey = () => {
    setKey((prev) => prev + 1);
  };

  const translate = useTranslate();

  return (
    <Layout.Sidebar
      quickSearch={{
        filters,
        onFilterChange,
        contextKey: 'deposit-requests-external',
        onFiltersUpdate: () => handleUpdateKey(),
        hideQuickSearchFilter: true,
        isNext: true,
        quickSearchId: QuickSearchIds.EXTERNAL_DEPOSIT,
        searchSettingsForm: SearchSettingsForm,
      }}
    >
      <>
        <FilterItem label={translate(messages.QUICK_FILTER)}>
          <SavedFilterSelect
            placeHolder={translate(messages.SELECT_SAVED_CUSTOM_FILTER)}
            value={savedFilterId}
            onChange={(val: string) => handleSavedFilterIdChange(val)}
            context="deposit-requests-external"
            key={key}
          />
        </FilterItem>

        <FilterItems
          filters={filters}
          onRawFilterChange={handleFilterChange}
          defaultValues={defaultValues}
        />
      </>
    </Layout.Sidebar>
  );
};

export default Sidebar;
