import { useQuery } from '@apollo/react-hooks';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import DownloadCsvButton from 'components/DownloadCsvButton';
import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import ALL_PERMISSIONS from 'constants/permissions3';
import { useFilterValues } from 'contexts/Filters';
import {
  DEPOSIT_PROVIDERS,
  DEPOSIT_PROVIDERS_CSV_DATA,
} from 'graphql/queries/depositProvider.query';
import { Page, PageInfo } from 'interfaces/user.interface';
import { isEqual } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useReducer, useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePermissions } from 'store/accountState';
import { StyledTagContainer } from 'styles';
import coercedGet from 'utils/coercedGet';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import FilterConditions from '../FilterConditions';
import NewDepositProvider from '../NewProvider';
import Providers from '../Providers';
import SearchSettingsForm from '../SearchSettingsForm';
import Sidebar from '../Sidebar';

enum ActionTypes {
  SET_SIDEBAR,
  SET_TOTAL_COUNT,
  SET_EXPANDED,
}

type Action = [ActionTypes, any?];

const initialState = {
  sidebarShown: false,
  expandedTags: false,
  totalCount: 0,
};

type State = typeof initialState;

function ownReducer(state: State, [type, payload]: Action) {
  switch (type) {
    case ActionTypes.SET_SIDEBAR:
      return {
        ...state,
        sidebarShown: payload,
      };

    case ActionTypes.SET_EXPANDED:
      return {
        ...state,
        expandedTags: payload,
      };

    case ActionTypes.SET_TOTAL_COUNT:
      return {
        ...state,
        totalCount: payload,
      };

    default:
      return state;
  }
}

const DepositProvidersScreen = () => {
  const [{ totalCount }, dispatch] = useReducer(ownReducer, initialState);
  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS
  );
  const {
    defaultValues,
    filters,
    onFilterChange,
    resetFilterToDefault,
  } = useFilterValues();

  const [page, setPage] = useState<Page>({
    first: 10,
    after: undefined,
    savedCursor: [''],
    currentPage: 0,
  });

  const [pageInfo, setPageInfo] = useState<Partial<PageInfo>>({});

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: removeNull(filters),
  };

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor!);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const setResultInfo = (data: { pageInfo: PageInfo; totalCount: number }) => {
    dispatch([ActionTypes.SET_TOTAL_COUNT, data.totalCount]);
    setPageInfo(data.pageInfo);
  };

  const translate = useTranslate();

  const { loading, data } = useQuery(DEPOSIT_PROVIDERS, {
    variables: refetchVariables,
    onCompleted: (result: any) => {
      setResultInfo(result.depositProviders);
    },
  });
  const totalPage = Math.ceil(totalCount / page.first) || 1;
  const edges = coercedGet(data, 'depositProviders.edges', []);

  const [key, setKey] = useState(0);

  /**
   * Re-renders the <SavedFilterSelect/> to update the list of filters
   */
  const handleUpdateKey = () => {
    setKey((prev) => prev + 1);
  };

  const [expandedTags, setExpandedTags] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={
            <div className="d-flex align-items-start flex-g-1">
              <ToggleSidebarHead />
              <StyledTagContainer ref={ref} isExpanded={expandedTags}>
                <span className="ml-1">
                  {translate(messages.FILTER_CONDITIONS)}:{' '}
                </span>{' '}
                <FilterConditions
                  filters={filters}
                  onFilterChange={onFilterChange}
                />
              </StyledTagContainer>
              <LegacyIconToggle
                forwardedRef={ref}
                filters={filters}
                expandedTags={expandedTags}
                toggleExpandedTags={setExpandedTags}
              />
              {!isEqual(filters, defaultValues) && (
                <ALink
                  onClick={() => resetFilterToDefault()}
                  style={{ marginTop: '0.35rem' }}
                >
                  {translate(messages.CLEAR_ALL)}
                </ALink>
              )}
            </div>
          }
          rightNode={
            <>
              <ColumnsFilterNew tabId="deposit-providers-external" iconOnly />
              <div className="mr-2">
                <DownloadCsvButton
                  iconOnly
                  tabId="deposit-providers-external"
                  fileName={translate(messages.THIRD_PARTY_DEPOSIT_PROVIDERS)}
                  query={DEPOSIT_PROVIDERS_CSV_DATA}
                  edgesPath="depositProviders.edges"
                />
              </div>
              {ALLOWED_CREATE && <NewDepositProvider />}
            </>
          }
        />
        <Layout.Content
          sideBar={
            <Layout.Sidebar
              data-testid="sidebar"
              quickSearch={{
                filters,
                contextKey: 'deposit-providers-external',
                onFilterChange,
                hideQuickSearchFilter: true,
                onFiltersUpdate: () => handleUpdateKey(),
                isNext: true,
                quickSearchId: QuickSearchIds.THIRD_PARTY_DEPOSIT_PROVIDER,
                searchSettingsForm: SearchSettingsForm,
              }}
            >
              <Sidebar savedFilterKey={key} />
            </Layout.Sidebar>
          }
          footer={
            <Layout.Footer
              loading={false}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={data?.pageInfo}
            />
          }
        >
          <Providers
            data={edges}
            loading={loading}
            refetchVariables={refetchVariables}
          />
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

export default DepositProvidersScreen;
