import { v4 as uuidV4 } from 'uuid';

const generateId = (prefix: string) => {
  const id = uuidV4();
  if (!prefix || prefix.length === 0) return id;

  return `${prefix}_${id.split('-').join('')}`;
};

export default generateId;
