import { configureStore } from '@reduxjs/toolkit';
import { screenTabReducer as superAdminScreenTabReducer } from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';
import { customColumnsReducer as SuperAdmincustomColumnsReducer } from 'SuperAdminMain/store/SuperAdminCustomColumnState/customColumnState';
import { screenTabReducer } from './screenTabState/screenTabState';
import { customColumnsReducer } from './customColumnState/customColumnState';
import { settingsReducer } from './settingState';
import { accountReducer } from './accountState';
import { sidebarReducer } from './sidebarState';
import { jobsReducer } from './jobState/jobState';
import { promoReducer } from './promoState/promoState';
import { methodReducer } from './methodState';

export default configureStore({
  reducer: {
    screenTab: screenTabReducer,
    customColumns: customColumnsReducer,
    settings: settingsReducer,
    account: accountReducer,
    sidebar: sidebarReducer,
    jobs: jobsReducer,
    superAdminScreenTab: superAdminScreenTabReducer,
    superAdminCustomColumns: SuperAdmincustomColumnsReducer,
    promos: promoReducer,
    method: methodReducer,
  },
});
