import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import TimeZoneFilter from 'SuperAdminMain/shared/components/TimeZoneFilter';
import DateTimeProcessed from '../../../DateTimeProcessed';
import VendorFilter from '../../../VendorFilter';
// import BetStatusFilter from '../../../BetStatusFilter';
import { StyledForm, StyledInputGroup } from './styles';

type SearchSettingsFormType = {
  initialValues: Record<string, any>;
  onSubmit: (val: any) => void;
  onClose: () => void;
  isLoading: boolean;
};

const SearchSettingsForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: SearchSettingsFormType) => {
  const [state, setState] = useState(initialValues);

  return (
    <StyledForm>
      <Row>
        <Col span={12}>
          <StyledInputGroup>
            <DateTimeProcessed state={state} setState={setState} />
          </StyledInputGroup>

          <StyledInputGroup>
            <TimeZoneFilter state={state} setState={setState} />
          </StyledInputGroup>

          <StyledInputGroup>
            <VendorFilter state={state} setState={setState} />
          </StyledInputGroup>

          {/* Hidden component for now because of unsuppoerted filter on backend */}
          {/* <StyledInputGroup>
            <VendorCategoryFilter state={filters} setState={setFilters} />
          </StyledInputGroup> */}

          {/* <StyledInputGroup>
            <BetStatusFilter state={state} setState={setState} />
          </StyledInputGroup> */}
        </Col>
      </Row>
      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmit(state)}
          loading={isLoading}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
