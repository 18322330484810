import gql from 'graphql-tag';

export const CONFIG = gql`
  query Config {
    config {
      websiteLink
      enableLoqateIntegration
      enableWithdrawalCancelation
      checkDepositLimit
      xtpAppToken
      baseCurrency
      baseCurrencySymbol
      defaultCountry {
        id
        name
      }
      timezone
      defaultCurrency {
        id
        code
        name
        digitalCode
        symbol
      }
      memberFormFields {
        ... on BuiltInFormField {
          type
          required
          field
        }
      }
    }
  }
`;
