import React from 'react';
import { Divider, Input, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Control, Controller, useWatch } from 'react-hook-form';
import coercedGet from 'utils/coercedGet';
import { useConfig } from 'hooks/useConfig';
import { TaskMethodSelect } from '../TaskMethodSelect';
import { RadioSwitch, RenderExtraByMethod } from '../../utils';

type Props = {
  nestIndex: number;
  control: Control;
  index: number;
  item: Record<string, any>;
  errors: Record<string, any>;
};

const ErrorMessage = ({
  errors,
  name,
  index,
  nestIndex,
}: {
  errors: Record<string, any>;
  name: string;
  index: number;
  nestIndex: number;
}) => {
  if (errors) {
    const error = coercedGet(
      errors,
      `days[${nestIndex}].tasks[${index}].[${name}].message`,
      null
    );
    return error ? <small className="text-danger">{error}</small> : <></>;
  }

  return <></>;
};

export const TaskCard = ({
  nestIndex,
  control,
  index,
  item,
  errors,
}: Props) => {
  const taskWatch: Record<string, any>[] = useWatch({
    control,
    name: 'days',
    defaultValue: [{ tasks: [] }],
  });
  const { currency } = useConfig();
  const name = (val: string) => `days[${nestIndex}].tasks[${index}].${val}`;
  const watching = taskWatch[nestIndex]?.tasks[index];

  return (
    <section>
      <div
        className="mt-2 border px-3 d-flex align-items-center"
        style={{ borderRadius: '4px', height: '88px', overflowX: 'auto' }}
      >
        <div style={{ width: '250px' }} className="flex-shrink-0">
          <div className="d-flex align-items-center">
            <div>
              <p className="fw-600 fs-13">
                <FormattedMessage id="TASK" defaultMessage="Task" /> {index + 1}
              </p>
              <Controller
                name={name('type')}
                control={control}
                defaultValue={item.type}
                render={({ value, onChange }) => (
                  <TaskMethodSelect value={value} onChange={onChange} />
                )}
              />
            </div>
          </div>
        </div>

        <div style={{ width: '70px' }} className="mr-3 flex-shrink-0">
          <RenderExtraByMethod
            item={item}
            method={watching?.type}
            control={control}
            name={name}
          />
        </div>

        <div style={{ width: '70px' }} className="mr-3 flex-shrink-0">
          <div>
            <p className="fw-600 fs-13" style={{ marginBottom: '10px' }}>
              <FormattedMessage id="AMOUNT" defaultMessage="Amount" />{' '}
              {currency?.symbol}
            </p>
            <Controller
              name={name('amount')}
              defaultValue={item.amount}
              control={control}
              as={<Input style={{ width: '100%' }} />}
            />
            <ErrorMessage
              errors={errors}
              name="amount"
              index={index}
              nestIndex={nestIndex}
            />
          </div>
        </div>

        <div style={{ width: '70px' }} className="flex-shrink-0">
          <div>
            <p className="fw-600 fs-13">
              <FormattedMessage id="MULTIPLIER" defaultMessage="Multiplier" />
            </p>
            <div className="d-flex">
              <Controller
                name={name('multiplier')}
                control={control}
                defaultValue={item.multiplier}
                render={({ value, onChange }) => (
                  <RadioSwitch onChange={onChange} value={value} />
                )}
              />
            </div>
          </div>
        </div>

        <Divider
          type="vertical"
          style={{ height: '50px' }}
          className="mr-3 flex-shrink-0"
        />

        <div className="pr-3 flex-shrink-0" style={{ width: '150px' }}>
          <div>
            <p className="fw-600 fs-13">
              <FormattedMessage id="REWARD_TYPE" defaultMessage="Reward Type" />
            </p>
            <Controller
              name={name('rewardType')}
              defaultValue={item.rewardType}
              control={control}
              as={
                <Select style={{ width: '100%' }}>
                  <Select.Option value="MONEY">
                    <FormattedMessage id="MONEY" defaultMessage="Money" />
                  </Select.Option>
                </Select>
              }
            />
          </div>
        </div>

        <div style={{ width: '150px' }} className="mr-3 flex-shrink-0">
          <div>
            <p className="fw-600 fs-13">
              <FormattedMessage
                id="REWARD_AMOUNT"
                defaultMessage="Reward Amount"
              />{' '}
              {currency?.symbol}
            </p>
            <Controller
              name={name('rewardAmount')}
              defaultValue={item.rewardAmount}
              control={control}
              as={<Input style={{ width: '100%' }} />}
            />
            <ErrorMessage
              errors={errors}
              name="rewardAmount"
              index={index}
              nestIndex={nestIndex}
            />
          </div>
        </div>

        <div style={{ width: '70px' }} className="flex-shrink-0">
          <div>
            <p className="fw-600 fs-13">
              <FormattedMessage id="MULTIPLIER" defaultMessage="Multiplier" />
            </p>
            <div className="d-flex">
              <Controller
                name={name('rewardMultiplier')}
                control={control}
                defaultValue={item.rewardMultiplier}
                render={({ value, onChange }) => (
                  <RadioSwitch onChange={onChange} value={value} />
                )}
              />
            </div>
          </div>
        </div>

        <Divider
          type="vertical"
          style={{ height: '50px' }}
          className="mx-3 flex-shrink-0"
        />

        <div style={{ width: '150px' }} className="mr-3 flex-shrink-0 pr-3">
          <div>
            <p className="fw-600 fs-13">
              <FormattedMessage id="TURNOVER" defaultMessage="Turnover" /> (x)
            </p>
            <Controller
              name={name('turnoverRequirementMultiplier')}
              defaultValue={item.turnoverRequirementMultiplier}
              control={control}
              as={<Input style={{ width: '100%' }} />}
            />
            <ErrorMessage
              errors={errors}
              name="turnoverRequirementMultiplier"
              index={index}
              nestIndex={nestIndex}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
