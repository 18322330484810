import React from 'react';
import { Button, Input, message, Popconfirm, Popover } from 'antd';
import { range } from 'lodash';
import {
  Control,
  Controller,
  ArrayField,
  DeepMap,
  FieldError,
} from 'react-hook-form';
import RichTextField from 'components/RichTextField/RichTextField';
import { DynamicPagePages } from 'types/graphqlTypes';
import { ALink } from 'components/ALink/ALink';

type PageEditProps = {
  page: Partial<ArrayField<DynamicPagePages, 'id'>>;
  move: (fromIdx: number, toIdx: number) => void;
  idx: number;
  control: Control<{ pages: DynamicPagePages[] }>;
  allPages: Partial<ArrayField<DynamicPagePages, 'id'>>[];
  remove: (idx: number) => void;
  errors: DeepMap<DynamicPagePages, FieldError> | undefined;
};

export const PageEdit: React.FC<PageEditProps> = ({
  page,
  move,
  idx,
  control,
  allPages,
  remove,
  errors,
}) => {
  const pageByIndex = idx + 1;
  const totalPagesLength = allPages.length;
  const pagesOptions = range(totalPagesLength)
    .map((pageNum) => pageNum + 1)
    .filter((pageNum) => pageNum !== pageByIndex);

  return (
    <div className="mb-3 p-3 border" key={page.pageNumber}>
      <div className="d-flex align-items-center">
        <p className="font-weight-bold mb-0">Page {page.pageNumber}</p>
        <Popover
          title="Move Page"
          content={
            <div>
              {pagesOptions.map((locPage) => (
                <Button
                  className="mr-1"
                  key={locPage}
                  onClick={() => {
                    message.info(`Moved to page ${locPage}`);
                    move(idx, locPage - 1);
                  }}
                >
                  Page {locPage}
                </Button>
              ))}
            </div>
          }
        >
          <ALink className="fs-12 ml-3">Move</ALink>
        </Popover>
        <Popconfirm title="Remove page" onConfirm={() => remove(idx)}>
          <p className="mb-0 text-danger cursor-pointer fs-12 ml-1">Remove</p>
        </Popconfirm>
      </div>
      <div className="mt-1">
        <p>Title:</p>
        <Controller
          control={control}
          as={Input}
          defaultValue={page.title}
          name={`pages[${idx}].title`}
        />
        {errors?.title && (
          <small className="text-danger">{errors.title.message}</small>
        )}
      </div>
      <div className="mt-3">
        <p>Content:</p>
        <Controller
          name={`pages[${idx}].content`}
          control={control}
          render={({ onChange, value }) => (
            <RichTextField
              name={`pages[${idx}].content`}
              defaultValue={value}
              onChange={(_key, val) => onChange(val)}
            />
          )}
        />
        {errors?.content && (
          <small className="text-danger">{errors.content.message}</small>
        )}
      </div>
    </div>
  );
};
