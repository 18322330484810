import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useMutation } from '@apollo/react-hooks';
import { Button, Input, Modal, notification } from 'antd';
import { useFormik } from 'formik';
import {
  CREATE_MARQUEE_MESSAGE,
  UPDATE_MARQUEE_MESSAGE,
} from 'graphql/mutations/marqueeMessage.mutation';
import globalMessages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CustomSwitch from 'components/CustomSwitch';
import { UseFormikErrorMessage } from 'components/UseFormikErrorMessage/UseFormikErrorMessage';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import * as Yup from 'yup';
import { messages } from '../../../messages';

const { TextArea } = Input;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

type Props = {
  modalState: boolean;
  setModalState: (e: boolean) => void;
  existingMarquee: Record<string, any>;
  refetchQueries: any;
};

const NewMarqueeMessageModal = ({
  modalState,
  setModalState,
  existingMarquee,
  refetchQueries,
}: Props) => {
  const onCloseModal = () => setModalState(false);
  const [enabled, setEnabled] = useState(false);
  const [createMarqueeMessage] = useMutation(CREATE_MARQUEE_MESSAGE);
  const [updateMarqueeMessage] = useMutation(UPDATE_MARQUEE_MESSAGE);
  const [initialValues, setInitialValues] = useState({
    title: '',
    message: '',
    snippet: '',
  });
  const translate = useTranslate();

  const validationSchema = Yup.object({
    title: Yup.string().required(
      translate(globalMessages['title-field-required.text'])
    ),
    message: Yup.string().required(
      translate(globalMessages['message-field-required.text'])
    ),
    snippet: Yup.string()
      .required(translate(globalMessages['snippet-field-required.text']))
      .max(
        320,
        translate(globalMessages['snippet-characters-errors.text'], {
          count: 320,
        })
      ),
  });
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onToggleSwitch = (a: boolean) => {
    setEnabled(a);
  };

  const onSubmit = async (vals: Record<string, any>, { resetForm }: any) => {
    try {
      if (existingMarquee) {
        await updateMarqueeMessage({
          variables: {
            id: existingMarquee.id,
            input: {
              title: vals.title,
              message: vals.message,
              snippet: vals.snippet,
              enabled,
            },
          },
          refetchQueries,
        });
        notification.success({
          message: translate(messages['update-marquee-success.text']),
        });
      } else {
        await createMarqueeMessage({
          variables: {
            input: {
              title: vals.title,
              message: vals.message,
              snippet: vals.snippet,
              enabled,
            },
          },
          refetchQueries,
        });
        notification.success({
          message: translate(messages['new-marquee-created.text']),
        });
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      onCloseModal();
      resetForm();
    }
  };

  useEffect(() => {
    if (existingMarquee) {
      const { title, message, snippet } = existingMarquee;
      setInitialValues({
        title,
        message,
        snippet,
      });
      setEnabled(existingMarquee.enabled);
    }
  }, [existingMarquee]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema,
  });

  const { handleSubmit, handleChange, values, handleBlur } = formik;
  const { title, message, snippet } = values;

  return (
    <StyledModal
      title={
        existingMarquee
          ? translate(messages['edit-marquee-message.text'])
          : translate(messages['new-marquee-message.text'])
      }
      visible={modalState}
      footer={false}
      onCancel={onCloseModal}
      width={680}
    >
      <Form className="w-100" layout="horizontal" onSubmit={handleSubmit}>
        <div
          className="p-1 d-flex flex-column"
          style={{
            width: '550px',
          }}
        >
          <Form.Item
            label={translate(messages['title.text'])}
            {...formItemLayout}
          >
            <Input
              onBlur={handleBlur}
              onChange={handleChange}
              value={title}
              name="title"
            />
            <UseFormikErrorMessage formik={formik} name="title" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={translate(messages['message.text'])}
          >
            <TextArea
              rows={5}
              onBlur={handleBlur}
              onChange={handleChange}
              value={message}
              name="message"
            />
            <UseFormikErrorMessage formik={formik} name="message" />
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={translate(messages['snippet.text'])}
            extra={translate(globalMessages['snippet-helper-text.text'], {
              charCount: 320 - snippet.length <= 0 ? 0 : 320 - snippet.length,
            })}
          >
            <Input
              onBlur={handleBlur}
              onChange={handleChange}
              value={snippet}
              name="snippet"
            />
            <UseFormikErrorMessage formik={formik} name="snippet" />
          </Form.Item>

          <Form.Item
            label={translate(messages['status.text'])}
            {...formItemLayout}
          >
            <CustomSwitch
              value={enabled}
              onChange={(e: any) => onToggleSwitch(e)}
              textHelper={{
                true: translate(globalMessages.ACTIVE),
                false: translate(globalMessages.INACTIVE),
              }}
            />
          </Form.Item>
        </div>

        <div className="bt-1 text-right" style={{ padding: '10px 16px' }}>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="save.text" defaultMessage="Save" />
          </Button>
        </div>
      </Form>
    </StyledModal>
  );
};

export default NewMarqueeMessageModal;
