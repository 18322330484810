import { SearchOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_CUSTOM_FILTERS } from 'graphql/queries/customFilter.query';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import QuickSearchFilter from 'components/QuickSearchFilter';
import { useScreenTabV2 } from 'store/screenTabState';
import { TabId } from 'types/nav';
import { FireFox } from 'utils/browserSpecific';
import coercedGet from 'utils/coercedGet';
import StyledSider from 'components/StyledSider';
import CustomilterSettings from 'components/CustomFilterSettings';
import SharedSavedSearch from 'components/SharedSavedSearch';

/*
 * Now this LeftSidebar component there are some component still uses this the old way
 * where they provide their own search header, its recommended to get rid of it
 * and use the `quickSearch` props on this one so we only have 1 style and 1 way
 * to implement things. If the existing features that uses this LeftSidebar has been
 * converted to use the `quickSearch` props, then it's alright to remove the `withSearchIcon`
 * and remove the conditional operator of `quickSearch` on the template
 * */

export type QuickSearch = {
  contextKey: TabId;
  filters: { [key: string]: any };
  onFilterChange: (filter: object) => void;
  onFiltersUpdate?: (filter: any) => void;
  searchSettingsForm?: React.ReactNode;
  editSearchSettings?: React.ReactNode;
  hideQuickSearchFilter?: boolean | null;
  isNext?: boolean;
  quickSearchId?: string;
  clearLocalStateFilters?: () => void;
  reportsPresetDateFilter?: {
    disableQuickSearch?: boolean;
    datePresetValue?: string | null;
    handleDatePresetChange?: (value: string | null) => void;
  };
};

type Props = {
  children: React.ReactNode;
  withSearchIcon?: boolean | null;
  width?: number;
  quickSearch?: QuickSearch;
  [key: string]: any;
};

const LeftSidebar = ({
  children,
  withSearchIcon,
  width = FireFox ? 270 : 232,
  quickSearch,
  ...props
}: Props) => {
  const [localQuickFilter, setLocalQuickFilter] = React.useState('');

  const { reportsPresetDateFilter } = quickSearch || {};

  const { getActiveTab } = useScreenTabV2();
  const activeTab = getActiveTab();
  const { sidebarShown } = activeTab.state;

  const variables = {
    filter: {
      context: {
        eq: quickSearch?.contextKey!,
      },
    },
  };

  const [
    loadCustomFilters,
    { data, refetch: refetchCustomFilters },
  ] = useLazyQuery(GET_CUSTOM_FILTERS, {
    fetchPolicy: 'network-only',
    variables,
  });

  const customFilters = coercedGet(data, 'customFilters.edges', []);
  const savedFilters =
    customFilters.map((customFilter: any) => customFilter.node) || [];

  return (
    <StyledSider width={width} sidebarStatus={!sidebarShown} {...props}>
      <div className="p-3">
        {!quickSearch && (
          <>
            {withSearchIcon && (
              <span className="fs-16">
                <SearchOutlined className="mr-2" />
                <FormattedMessage id="search.text" defaultMessage="Search" />
              </span>
            )}
          </>
        )}
        {quickSearch && (
          <>
            <div className="d-flex justify-content-space-between mb-3">
              <span className="fs-14">
                <SearchOutlined className="mr-2" />
                <FormattedMessage id="search.text" defaultMessage="Search" />
              </span>

              <div>
                <SharedSavedSearch
                  refetchCustomFilters={refetchCustomFilters}
                  contextKey={quickSearch?.contextKey}
                  filters={quickSearch?.filters}
                  onFiltersUpdate={quickSearch.onFiltersUpdate}
                />
                <span style={{ color: 'rgba(0, 0, 0, 0.35)' }}> |</span>

                <CustomilterSettings
                  onFiltersUpdate={quickSearch.onFiltersUpdate as any}
                  EditSearchSettingsNode={quickSearch?.editSearchSettings!}
                  SearchSettingsFormNode={quickSearch?.searchSettingsForm!}
                  onUseFilter={quickSearch?.onFilterChange!}
                  contextKey={quickSearch?.contextKey!}
                  setLocalQuickFilter={setLocalQuickFilter}
                  isNext={quickSearch.isNext}
                  quickSearchId={quickSearch.quickSearchId}
                />
              </div>
            </div>

            {!quickSearch.hideQuickSearchFilter && (
              <div>
                <QuickSearchFilter
                  savedFilters={savedFilters}
                  loadCustomFilters={loadCustomFilters as any}
                  filters={quickSearch?.filters as any}
                  searchId={quickSearch?.contextKey!}
                  onSelect={quickSearch?.onFilterChange!}
                  localQuickFilter={localQuickFilter}
                  setLocalQuickFilter={setLocalQuickFilter as any}
                  clearLocalStateFilters={quickSearch.clearLocalStateFilters}
                  reportsPresetDateFilter={{
                    datePresetValue: reportsPresetDateFilter?.datePresetValue,
                    disableQuickSearch:
                      reportsPresetDateFilter?.disableQuickSearch,
                    handleDatePresetChange:
                      reportsPresetDateFilter?.handleDatePresetChange,
                  }}
                />
              </div>
            )}
          </>
        )}

        <div>{children}</div>
      </div>
    </StyledSider>
  );
};

export default LeftSidebar;
