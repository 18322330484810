/* eslint-disable global-require */
import { Bank } from 'interfaces/user.interface';
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ICBC: {
    id: 'member-detail.bank-icbc.text',
    defaultMessage: 'Industrial and Commercial Bank of China',
  },
  ABC: {
    id: 'member-detail.bank-abc.text',
    defaultMessage: 'Agricultural Bank of China',
  },
  BOC: {
    id: 'member-detail.bank-boc.text',
    defaultMessage: 'Bank of China',
  },
  CCB: {
    id: 'member-detail.bank-ccb.text',
    defaultMessage: 'China Construction Bank',
  },
  CMB: {
    id: 'member-detail.bank-cmb.text',
    defaultMessage: 'China Merchants Bank',
  },
  BOCOM: {
    id: 'member-detail.bank-bocom.text',
    defaultMessage: 'Bank of Communications Limited',
  },
  PAB: {
    id: 'member-detail.bank-pab.text',
    defaultMessage: 'Ping An Bank',
  },
  CITIC: {
    id: 'member-detail.bank-citic.text',
    defaultMessage: 'China CITIC Bank',
  },
  CIB: {
    id: 'member-detail.bank-cib.text',
    defaultMessage: 'China Industrial Bank',
  },
  CEB: {
    id: 'member-detail.bank-ceb.text',
    defaultMessage: 'China Everbright Bank',
  },
  CMBC: {
    id: 'member-detail.bank-cmbc.text',
    defaultMessage: 'China Minsheng Bank',
  },
  PSBC: {
    id: 'member-detail.bank-psbc.text',
    defaultMessage: 'Postal Savings Bank of China',
  },
  OTHER: {
    id: 'member-detail.bank-other.text',
    defaultMessage: 'Other Bank',
  },
});

const banks = [
  {
    value: 'ICBC',
    label: 'ICBC',
    logo: require('img/icons/banks/icon_bank_icbc.png'),
  },
  {
    value: 'ABC',
    label: 'ABC',
    logo: require('img/icons/banks/icon_bank_abc.png'),
  },
  {
    value: 'BOC',
    label: 'BOC',
    logo: require('img/icons/banks/icon_bank_bochina.png'),
  },
  {
    value: 'CCB',
    label: 'CCB',
    logo: require('img/icons/banks/icon_bank_ccb.png'),
  },
  {
    value: 'CMB',
    label: 'CMB',
    logo: require('img/icons/banks/icon_bank_cmb.png'),
  },
  {
    value: 'BOCOM',
    label: 'BOCOM',
    logo: require('img/icons/banks/icon_bank_bocomm.png'),
  },
  {
    value: 'PAB',
    label: 'PAB',
    logo: require('img/icons/banks/icon_bank_pab.png'),
  },
  {
    value: 'CITIC',
    label: 'CITIC',
    logo: require('img/icons/banks/icon_bank_citic.png'),
  },
  {
    value: 'CIB',
    label: 'CIB',
    logo: require('img/icons/banks/icon_bank_cib.png'),
  },
  {
    value: 'CEB',
    label: 'CEB',
    logo: require('img/icons/banks/icon_bank_ceb.png'),
  },
  {
    value: 'CMBC',
    label: 'CMBC',
    logo: require('img/icons/banks/icon_bank_cmbc.png'),
  },
  {
    value: 'PSBC',
    label: 'PSBC',
    logo: require('img/icons/banks/icon_bank_psbc.png'),
  },
  {
    value: 'OTHER',
    label: 'OTHER',
    logo: require('img/icons/banks/icon_bank_etc.png'),
  },
];

export const getBank = (bankCode: string | Bank | null | undefined) => {
  const filteredBanks = banks.filter((bank) => bank.value === bankCode);
  if (filteredBanks.length) return filteredBanks[0];

  return null;
};

export default banks;
