import React, { useEffect } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Icon from '@ant-design/icons';
import { Button } from 'antd';
import CustomSwitch from 'components/CustomSwitch/index';
import { DynamicObj } from 'interfaces/user.interface';
import { UploadExcel } from 'pages/components/NewMemberManagement/components/ImportMembers/components/UploadExcel';
import { ReactComponent as CloudDownloadIcon } from './cloud_download-24px.svg';

const ImportExcel = ({
  validation,
  values,
  setFieldValue = null,
  PMAValues,
}: {
  validation: string[];
  values: DynamicObj;
  setFieldValue?: any;
  PMAValues?: any;
}) => {
  const { state, setState, clearExcel, setClearExcel } = PMAValues || {};

  const isExcel = values.excel || state?.useExcel;

  useEffect(() => {
    if (clearExcel) {
      setClearExcel(false);
    }
  }, [setClearExcel, clearExcel]);

  return (
    <>
      <div
        className={cx('mb-3', {
          'd-flex': isExcel,
          'align-items-end': isExcel,
        })}
      >
        <div className="mr-3">
          <div className="fs-12 text-black--85" style={{ height: '31px' }}>
            <FormattedMessage
              id="import-from-csv-excel.text"
              defaultMessage="Import from CSV/Excel"
            />
          </div>
          <CustomSwitch
            textStyle={{}}
            disabled={false}
            value={isExcel}
            onChange={(e: boolean) => {
              if (setFieldValue) setFieldValue('excel', e);
              if (setState) setState({ useExcel: e });
            }}
            textHelper={null}
            activeColor="#3ec988"
            checkedChildren={
              <FormattedMessage id="yes.text" defaultMessage="Yes" />
            }
            unCheckedChildren={
              <FormattedMessage id="no.text" defaultMessage="No" />
            }
          />
        </div>
        {isExcel && (
          <Button>
            <a
              href={`${process.env.PUBLIC_URL}/excel/sample_template_bulk_update_balance.csv`}
              target="_blank"
              rel="noopener noreferrer"
              download="sample_template_bulk_update_balance.csv"
            >
              <Icon
                component={CloudDownloadIcon}
                className="text-primary mr-2"
              />
              <FormattedMessage
                id="download-template.text"
                defaultMessage="Download Template"
              />
            </a>
          </Button>
        )}
      </div>

      {isExcel && (
        <UploadExcel
          validation={validation}
          excelType="import"
          onChange={(e: string) => setFieldValue('excelData', e)}
          onFileError={() => {}}
        />
      )}
    </>
  );
};

export default ImportExcel;
