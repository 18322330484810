import React from 'react';
import { FieldArray, getIn } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Card, Checkbox, Row, Col, Select } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { StyledFormItem } from '../../styles';
import {
  allTypes,
  useQualificationCriteriaContext,
  fixedScenarioTypes,
} from './context';

const { Item } = Form;

const { Option } = Select;

const formItemHorizontalLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const colStyles = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

type Props = {
  formik: Record<string, any>;
  isPresetCriteriaPresent: boolean;
};

export default function QualificationCriteria({
  formik,
  isPresetCriteriaPresent,
}: Props) {
  const translate = useTranslate();
  const checkboxOptions = [
    { label: translate(messages.SLOT), value: 'SLOT' },
    { label: translate(messages.FISH), value: 'FISH' },
    { label: translate(messages.LIVE_CASINO), value: 'LIVE' },
    { label: translate(messages.SPORTSBOOK), value: 'SPORTSBOOK' },
    { label: translate(messages.LOTTERY), value: 'LOTTERY' },
    { label: translate(messages.POKER), value: 'POKER' },
  ];

  const { handleChange, setFieldValue, values } = formik;

  const [contextState, dispatch] = useQualificationCriteriaContext();

  const { activeScenario } = contextState;

  const scenarioIncluded = (scenario: string) =>
    activeScenario.indexOf(scenario) !== -1;

  const toggleScenario = (scenario: string) =>
    scenarioIncluded(scenario)
      ? dispatch({
          type: allTypes.REMOVE_ALL_SEQUENCED_SCENARIOS,
          payload: scenario,
        })
      : dispatch({
          type: allTypes.ADD_SCENARIO,
          payload: scenario,
        });

  const scenariosArray = Object.values(fixedScenarioTypes);
  const getScenarioIndex = (scenario: string) =>
    scenariosArray.indexOf(scenario);
  const fieldArrayRoot = values.qualificationCriteria[0];

  const getAmountFieldValue = (scenario: string, dataAccess: string) => {
    const isDataLoaded = Boolean(
      fieldArrayRoot.data[getScenarioIndex(scenario)]
    );

    return isDataLoaded ? getIn(values, dataAccess) : 0;
  };
  // ** DISABLE TOGGLING TEMPORARILY
  // const handleFieldToggle = (scenario, pushValue, arrayHelpers) => {
  //   if (!scenarioIncluded(scenario)) {
  //     arrayHelpers.push(pushValue);
  //   } else {
  //     setFieldValue(
  //       'qualificationCriteria[0].data',
  //       fieldArrayRoot.data.slice(0, getScenarioIndex(scenario))
  //     );
  //   }
  //   toggleScenario(scenario);
  // };

  const initialEffectiveBetData = {
    type: 'EFFECTIVE_BET',
    amount: 0,
    gameTypes: [],
  };

  const disableDeposit = () => {
    setFieldValue(
      'qualificationCriteria[0].data',
      fieldArrayRoot.data.slice(0, getScenarioIndex(allTypes.D_ONLY))
    );
    toggleScenario(allTypes.D_ONLY);
  };

  React.useEffect(() => {
    if (values.inviteOnly && scenarioIncluded(allTypes.D_ONLY)) {
      disableDeposit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.inviteOnly]);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (isPresetCriteriaPresent) {
      const rootLength = fieldArrayRoot.data.length;

      if (rootLength === 1)
        return dispatch({
          type: allTypes.ADD_SCENARIO,
          payload: allTypes.D_ONLY,
        });

      if (rootLength === 2)
        return dispatch({
          type: allTypes.ADD_ALL_SCENARIOS,
          payload: [allTypes.D_ONLY, allTypes.D_AND_T],
        });

      if (rootLength >= 3)
        return dispatch({
          type: allTypes.ADD_ALL_SCENARIOS,
        });

      return dispatch({
        type: allTypes.ADD_SCENARIO,
        payload: allTypes.D_ONLY,
      });
    }
  }, [isPresetCriteriaPresent, dispatch, fieldArrayRoot.data.length]);

  return (
    <FieldArray
      name="qualificationCriteria[0].data"
      render={() => {
        // arrayHelpers
        const arrayData0 = getIn(values, 'qualificationCriteria[0].data').slice(
          2
        );

        return (
          <>
            <Item
              colon={false}
              style={{ color: 'rgba(0, 0, 0, 0.85)' }}
              label={
                <strong>
                  <FormattedMessage
                    id="qualification-settings.text"
                    defaultMessage="Qualification Settings"
                  />
                </strong>
              }
              {...formItemHorizontalLayout}
            >
              &nbsp;
            </Item>
            {/* D_ONLY **************** */}
            <Card
              title={
                <Form.Item {...colStyles}>
                  {/* <Checkbox
                    checked={scenarioIncluded(allTypes.D_ONLY)}
                    onChange={() => {
                      handleFieldToggle(
                        allTypes.D_ONLY,
                        { type: 'DEPOSIT', amount: 0 },
                        arrayHelpers
                      );
                    }}
                    DISABLES
                    ENTIRE
                    FORM
                    disabled={values.inviteOnly}

                    checked
                    onChange={() => null}
                    disabled
                  >
                    <FormattedMessage
                      id="member-loyalty.deposit.text"
                      defaultMessage="Deposit"
                    />
                  </Checkbox> */}
                  <span>
                    {' '}
                    <FormattedMessage
                      id="member-loyalty.deposit.text"
                      defaultMessage="Deposit"
                    />
                  </span>

                  <Row gutter={8}>
                    <Col span={12}>
                      <Input
                        type="number"
                        name="qualificationCriteria[0].data[0].amount"
                        value={getAmountFieldValue(
                          allTypes.D_ONLY,
                          'qualificationCriteria[0].data[0].amount'
                        )}
                        disabled={!scenarioIncluded(allTypes.D_ONLY)}
                        onChange={handleChange}
                        size="large"
                      />
                    </Col>
                    <Col span={12}>
                      {/* <Button
                        className="mr-1"
                        disabled={!scenarioIncluded(allTypes.D_ONLY)}
                        type={
                          !scenarioIncluded(allTypes.D_AND_T)
                            ? 'default'
                            : 'primary'
                        }
                        // onClick={toggleScenario(allTypes.D_AND_T)}
                        onClick={() => {
                          handleFieldToggle(
                            allTypes.D_AND_T,
                            {
                              type: 'OR',
                              data: [initialEffectiveBetData],
                            },
                            arrayHelpers
                          );
                        }}
                      >
                        <FormattedMessage
                          id="member-loyalty.and.text"
                          defaultMessage="And"
                        />
                      </Button> */}
                      {/* <Button>
                <FormattedMessage
                  id="member-loyalty.or.text"
                  defaultMessage="Or"
                />
              </Button> */}
                    </Col>
                  </Row>
                </Form.Item>
              }
            >
              {/* D_AND_T*******  */}
              <StyledFormItem
                colon={false}
                label={
                  <Checkbox
                    // disabled={!scenarioIncluded(allTypes.D_ONLY)}
                    // onChange={toggleScenario(allTypes.D_AND_T)}
                    // checked={scenarioIncluded(allTypes.D_AND_T)}
                    checked
                    onChange={() => null}
                    disabled
                    // onChange={() => {
                    //   handleFieldToggle(
                    //     allTypes.D_AND_T,
                    //     {
                    //       type: 'OR',
                    //       data: [initialEffectiveBetData],
                    //     },
                    //     arrayHelpers
                    //   );
                    // }}
                  />
                }
                {...colStyles}
              >
                <Select
                  // ***
                  // disabled={!scenarioIncluded(allTypes.D_AND_T)}
                  style={{ width: '80%' }}
                  size="large"
                  placeholder={translate(messages.PLEASE_SELECT)}
                  defaultValue="turnover"
                >
                  <Option key="1" value="turnover">
                    <FormattedMessage
                      id="member-loyalty-turnover.text"
                      defaultMessage="Turnover"
                    />
                  </Option>
                </Select>
              </StyledFormItem>

              <Card>
                {/* enclosing card */}
                <Card>
                  <FieldArray
                    name={`qualificationCriteria[0].data[${getScenarioIndex(
                      allTypes.D_AND_T
                    )}].data`}
                    render={() => {
                      // arrayHelpers1
                      const arrayData1 = getIn(
                        values,
                        `qualificationCriteria[0].data[${getScenarioIndex(
                          allTypes.D_AND_T
                        )}].data`
                      ) || [
                        {
                          type: 'OR',
                          data: [initialEffectiveBetData],
                        },
                      ];

                      return (
                        <>
                          {arrayData1.map((__item: any, index: number) => (
                            <StyledFormItem {...colStyles}>
                              <div className="mb-4">
                                <div>
                                  <Checkbox.Group
                                    disabled={
                                      !scenarioIncluded(allTypes.D_AND_T)
                                    }
                                    options={checkboxOptions}
                                    defaultValue={[]}
                                    value={getAmountFieldValue(
                                      allTypes.D_AND_T,
                                      `qualificationCriteria[0].data[${getScenarioIndex(
                                        allTypes.D_AND_T
                                      )}].data[${index}].gameTypes`
                                    )}
                                    onChange={(checkedValues) => {
                                      setFieldValue(
                                        `qualificationCriteria[0].data[${getScenarioIndex(
                                          allTypes.D_AND_T
                                        )}].data[${index}].gameTypes`,
                                        checkedValues
                                      );
                                    }}
                                  />
                                </div>

                                <Input
                                  type="number"
                                  name={`qualificationCriteria[0].data[${getScenarioIndex(
                                    allTypes.D_AND_T
                                  )}].data[${index}].amount`}
                                  value={getAmountFieldValue(
                                    allTypes.D_AND_T,
                                    `qualificationCriteria[0].data[${getScenarioIndex(
                                      allTypes.D_AND_T
                                    )}].data[${index}].amount`
                                  )}
                                  disabled={!scenarioIncluded(allTypes.D_AND_T)}
                                  onChange={handleChange}
                                  size="large"
                                  style={{ width: '60%' }}
                                />
                                {/* adds one checkbox group under multipliable checkbox card */}

                                {/* *****====== DISABLE NESTING FEATURE TEMPORARILY ======****  */}
                                {/* <span className="ml-4">
                                  <Button
                                    size="large"
                                    disabled={
                                      !scenarioIncluded(allTypes.D_AND_T)
                                    }
                                    type="default"
                                    onClick={() => {
                                      // *****====== DISABLE NESTING FEATURE TEMPORARILY ======****
                                      // arrayHelpers1.push(
                                      //   initialEffectiveBetData
                                      // );
                                    }}
                                  >
                                    <FormattedMessage
                                      id="or.text"
                                      defaultMessage="Or"
                                    />
                                  </Button>
                                </span> */}
                              </div>
                            </StyledFormItem>
                          ))}
                        </>
                      );
                    }}
                  />
                </Card>
                {/* adds more multipliable checkbox cards */}
                {/*  *****====== DISABLE NESTING FEATURE TEMPORARILY ======****` */}
                {/* <Button
                  size="large"
                  className="my-2"
                  disabled={!scenarioIncluded(allTypes.D_AND_T)}
                  type={
                    !scenarioIncluded(allTypes.D_AND_T_AND_T_AND_T)
                      ? 'default'
                      : 'primary'
                  }
                  onClick={() => {
                    // *****====== DISABLE NESTING FEATURE TEMPORARILY ======****`;
                    // arrayHelpers.push({
                    //   type: 'OR',
                    //   data: [initialEffectiveBetData],
                    // });
                    // // toggles D_AND_T_AND_T
                    // if (!scenarioIncluded(allTypes.D_AND_T_AND_T)) {
                    //   toggleScenario(allTypes.D_AND_T_AND_T);
                    // }
                  }}
                >
                  <FormattedMessage id="and.text" defaultMessage="And" />
                </Button> */}
                {/*  additonal multipliable checkbox cards - conditionally rendered */}
                <>
                  {arrayData0.length > 0 &&
                    arrayData0.map((___item: any, i: number) => (
                      <>
                        <Card>
                          <FieldArray
                            name={`qualificationCriteria[0].data[${i +
                              2}].data`}
                            render={() => {
                              // arrayHelpers1
                              const arrayData1 = getIn(
                                values,
                                `qualificationCriteria[0].data[${i + 2}].data`
                              ) || [
                                {
                                  type: 'OR',
                                  data: [initialEffectiveBetData],
                                },
                              ];

                              return (
                                <>
                                  {arrayData1.map(
                                    (__item: any, index: number) => (
                                      <StyledFormItem {...colStyles}>
                                        <div className="mb-4">
                                          <div>
                                            <Checkbox.Group
                                              disabled={
                                                !scenarioIncluded(
                                                  allTypes.D_AND_T
                                                )
                                              }
                                              options={checkboxOptions}
                                              defaultValue={[]}
                                              value={getAmountFieldValue(
                                                allTypes.D_AND_T,
                                                `qualificationCriteria[0].data[${i +
                                                  2}].data[${index}].gameTypes`
                                              )}
                                              onChange={(checkedValues) => {
                                                setFieldValue(
                                                  `qualificationCriteria[0].data[${i +
                                                    2}].data[${index}].gameTypes`,
                                                  checkedValues
                                                );
                                              }}
                                            />
                                          </div>

                                          <Input
                                            type="number"
                                            name={`qualificationCriteria[0].data[${i +
                                              2}].data[${index}].amount`}
                                            value={getAmountFieldValue(
                                              allTypes.D_AND_T,
                                              `qualificationCriteria[0].data[${i +
                                                2}].data[${index}].amount`
                                            )}
                                            disabled={
                                              !scenarioIncluded(
                                                allTypes.D_AND_T
                                              )
                                            }
                                            onChange={handleChange}
                                            size="large"
                                            style={{ width: '60%' }}
                                          />
                                          {/* *****====== DISABLE NESTING FEATURE TEMPORARILY ======**** */}
                                          {/* <span className="ml-4">
                                          <Button
                                            size="large"
                                            disabled={
                                              !scenarioIncluded(
                                                allTypes.D_AND_T
                                              )
                                            }
                                            type="default"
                                            onClick={() => {
                                              // *****====== DISABLE NESTING FEATURE TEMPORARILY ======****
                                              // arrayHelpers1.push(
                                              //   initialEffectiveBetData
                                              // );
                                            }}
                                          >
                                            <FormattedMessage
                                              id="or.text"
                                              defaultMessage="Or"
                                            />
                                          </Button>
                                        </span> */}
                                        </div>
                                      </StyledFormItem>
                                    )
                                  )}
                                </>
                              );
                            }}
                          />
                        </Card>
                        {/* *****====== DISABLE NESTING FEATURE TEMPORARILY ======**** */}
                        {/* <Button
                          size="large"
                          className="my-2"
                          disabled={!scenarioIncluded(allTypes.D_AND_T)}
                          type={
                            !scenarioIncluded(allTypes.D_AND_T_AND_T_AND_T)
                              ? 'default'
                              : 'primary'
                          }
                          onClick={() => {
                            // *****====== DISABLE NESTING FEATURE TEMPORARILY ======****
                            // arrayHelpers.push({
                            //   type: 'OR',
                            //   data: [initialEffectiveBetData],
                            // });
                            // if (!scenarioIncluded(allTypes.D_AND_T_AND_T)) {
                            //   toggleScenario(allTypes.D_AND_T_AND_T);
                            // }
                          }}
                        >
                          <FormattedMessage
                            id="and.text"
                            defaultMessage="And"
                          />
                        </Button> */}
                      </>
                    ))}
                </>
              </Card>
            </Card>
          </>
        );
      }}
    />
  );
}
