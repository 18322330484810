import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col } from 'antd';
import DepositProvidersSelect from 'components/DepositProvidersSelect';
import useTranslate from 'utils/useTranslate';
import messages from '../../../../messages';

const InputGroup = Input.Group;

type Props = {
  values: Record<string, any>;
  setValues: (data: any) => void;
  formItemLayout: Record<string, any>;
  errors: any;
  disabled?: boolean;
  getProviderInfo: (data: Record<string, any>) => void;
};

const ExternalProviders = ({
  values,
  setValues,
  formItemLayout,
  errors,
  disabled,
  getProviderInfo,
}: Props) => {
  const translate = useTranslate();

  const setDepositProvider = (e: string) => {
    setValues({
      ...values,
      depositProvider: e,
      paymentChannel: '',
      type: null,
    });
    if (e) {
      getProviderInfo({ variables: { id: e } });
    }
  };

  return (
    <Form.Item
      label={translate(messages.provider)}
      {...formItemLayout}
      validateStatus={errors.depositProvider ? 'error' : ''}
      help={errors.depositProvider || null}
    >
      <InputGroup>
        <Row gutter={8}>
          <Col span={24}>
            <DepositProvidersSelect
              value={values.depositProvider}
              placeHolder={translate(messages.SELECT_PROVIDER)}
              onChange={setDepositProvider as any}
              disabled={disabled}
            />
          </Col>
        </Row>
      </InputGroup>
    </Form.Item>
  );
};

export default ExternalProviders;
