import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import DownloadCsvButton from 'components/DownloadCsvButton';
import ALL_PERMISSIONS from 'constants/permissions3';
import { PAYMENT_METHODS_CSV_DATA } from 'graphql/queries/paymentMethodListing.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React from 'react';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import { usePermissions } from 'store/accountState';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import NewPaymentGateway from '../NewPaymentGateway';
import Settings from '../Settings';

const HeaderRight = ({
  filters,
  refreshPage,
}: {
  filters: Record<string, any>;
  refreshPage: () => void;
}) => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_CREATE, ALLOWED_VIEW_PAYMENT_SETTINGS } = collectPermissions(
    role,
    permissions,
    ['CREATE', 'VIEW_PAYMENT_SETTINGS'],
    ALL_PERMISSIONS.ALL_DEPOSITS.DEPOSITS_PAYMENT_METHODS
  );
  const translate = useTranslate();
  return (
    <>
      <div className="mr-2">
        {ALLOWED_VIEW_PAYMENT_SETTINGS && <Settings />}
      </div>
      <RefreshButton onClick={refreshPage} />
      <ColumnsFilterNew iconOnly tabId="payment-method-listing" />
      <div className="mr-2">
        <DownloadCsvButton
          iconOnly
          tabId="payment-method-listing"
          fileName={translate(messages.paymentMethods)}
          query={PAYMENT_METHODS_CSV_DATA}
          edgesPath="paymentMethods.edges"
        />
      </div>
      {ALLOWED_CREATE && <NewPaymentGateway filters={filters} />}
    </>
  );
};

export default HeaderRight;
