import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Button, message, Upload } from 'antd';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import {
  MEMBER_BULK_GAME_ROUNDS_RECORD,
  BULK_UPDATE_GAME_ROUNDS,
} from 'pages/components/BulkUpdate/utils';
import React from 'react';
import { useInterval } from 'react-use';
import { ALink } from 'components/ALink/ALink';

const BulkUpdateGameRounds = () => {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [bulkUpdateGameRounds] = useMutation(BULK_UPDATE_GAME_ROUNDS);

  const client = useApolloClient();

  const [isIntervalRunning, setIsIntervalRunning] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const onCallBulkRecord = async () => {
    const { data } = await client.query({
      query: MEMBER_BULK_GAME_ROUNDS_RECORD,
      fetchPolicy: 'network-only',
    });
    const status = data?.bulkUpdateGameRoundRecord?.status;

    if (status === 'FAILED') {
      setIsIntervalRunning(false);
      setProcessing(false);
      message.error('Unable to upload text file');
      return;
    }

    if (status === 'DONE') {
      setIsIntervalRunning(false);
      setProcessing(false);
      message.success('Successfully updated bulk game round ');
    }
  };

  useInterval(
    () => {
      onCallBulkRecord();
    },
    isIntervalRunning ? 1500 : null
  );

  const uploadProps: any = {
    beforeUpload: (file: File) => {
      const validType =
        // file.type === 'text/csv' ||
        // file.type === 'text/xslx' ||
        file.type === 'text/plain';
      // file.type ===
      //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      if (!validType) {
        message.error('Only upload valid text file');
        return false;
      }

      setProcessing(true);
      uploadFile({
        variables: {
          file,
        },
      }).then(async ({ data }) => {
        await bulkUpdateGameRounds({
          variables: {
            file: data?.uploadFile?.id,
          },
        });
        setIsIntervalRunning(true);
        onCallBulkRecord();
      });

      return false;
    },
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <h3 className="mb-0">Bulk Update Game Rounds &nbsp;</h3>
        <ALink
          href={`${process.env.PUBLIC_URL}/text/sample_for_bulk_update_game_rounds.txt`}
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          Sample Format
        </ALink>
      </div>

      <div className="d-flex">
        <Upload
          {...uploadProps}
          maxCount={1}
          accept={['.text', '.txt']}
          showUploadList={false}
        >
          <Button
            className="mr-3"
            // eslint-disable-next-line
            disabled={processing}
            loading={processing}
          >
            {processing ? 'Processing...' : 'Upload Text File'}
          </Button>
        </Upload>
      </div>
    </div>
  );
};

export default BulkUpdateGameRounds;
