import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { PAYMENT_METHODS } from 'graphql/queries/paymentMethodListing.query';
import React, { useState } from 'react';
import generateId from 'utils/generateId';
// import globalMessages from 'messages';
import removeNull from 'utils/removeNull';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import messages from '../../messages';
import PaymentGatewayForm from '../PaymentGatewayForm';
import { CREATE_PAYMENT_METHOD } from './mutations';
import { StyledModal } from './styles';

const NewPaymentGateway = ({ filters }: { filters: Record<string, any> }) => {
  const translate = useTranslate();
  const [isShow, setIsShow] = useState(false);
  const handleModal = () => setIsShow((prevState) => !prevState);

  const refetchVariables = {
    first: 10,
    filter: removeNull(filters),
  };

  const { context } = useOperatorHeader();
  const [createPaymentMethod, { loading }] = useMutation(
    CREATE_PAYMENT_METHOD,
    {
      onCompleted: () => message.success(translate(messages.successfullyadded)),
      onError: (err) => {
        const error = `${err.message}`.split(':').reverse()[0];
        message.error(<span data-testid="error">{error}</span>);
      },
      refetchQueries: [
        {
          query: PAYMENT_METHODS,
          variables: refetchVariables,
        },
      ],
      context,
    }
  );

  return (
    <>
      <Button onClick={handleModal} type="primary">
        <PlusOutlined /> {translate(messages.new)}
      </Button>
      <StyledModal
        title={translate(messages.add)}
        visible={isShow}
        width={900}
        onOk={handleModal}
        onCancel={handleModal}
        footer={false}
        maskClosable={false}
      >
        <div>
          <PaymentGatewayForm
            operation="create"
            onClose={handleModal}
            createPaymentMethod={createPaymentMethod}
            loading={loading}
            paymentMethod={{
              id: generateId('pmm'),
              name: '',
              remarks: '',
              enabled: false,
              excludedMemberLevel: [],
              memberLoyaltyLevel: [],
              minimumDepositAmount: '',
              maximumDepositAmount: '',
              instructionText: '',
              maximumDepositAmountPerDay: '',
              depositExpiry: '',
              turnoverRequirementMultiplier: '',
              bank: '',
              accountNumber: '',
              accountName: '',
              branch: '',
              country: '',
              province: '',
              city: '',
              nickname: '',
              image: '',
              imageUrl: '',
              depositProvider: '',
              suggestedAmounts: [],
              transactionFee: 0,
              transactionFeeType: 'ABSOLUTE',
              allowCustomAmount: true,
              exchangeRateSource: 'MANUAL',
              exchangeRate: '7.1000',
              exchangeRateSpread: '0.0500',
              minimumConfirmationsCount: 1,
              exchangeRateUpdateFrequency: '1.0',
              exchangeRateUpdateFrequencyUnit: '',
              blockchain: 'ERC20',
              blockchainAddress: '',
              cryptocurrency: 'USDT',
            }}
            paymentType="OFFLINE"
            type="OFFLINE_BANK_TRANSFER"
            isShow={isShow}
          />
        </div>
      </StyledModal>
    </>
  );
};

export default NewPaymentGateway;
