import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { Button, message, Modal } from 'antd';
import AdjustmentByBulkProcess from 'components/AdjustmentByBulkProcess';
import { BALANCE_FORM_EXTRA_VALUES } from 'constants/balanceFormInitialValues';
import gql from 'graphql-tag';
import { CREATE_MANUAL_ADJUSTMENT } from 'graphql/mutations/manualAdjustment.mutation';
import styled from 'styled-components';
import {
  AFFILIATE_COMMISION,
  PROMO_PAYOUT,
  turnoverShowTypes,
  actualShowTypes,
} from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/utils';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import BulkAdjustBalanceMenuForm from 'components/BulkAdjustBalanceMenuForm';
import { ExcelProgress } from 'pages/components/NewMemberManagement/components/ImportMembers/components/ExcelProgress';
import { ResultReport } from 'pages/components/NewMemberManagement/components/ImportMembers/components/ResultReport';
import { jobsActions, JobType } from 'store/jobState/jobState';

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
`;

type Rec = Record<string, any>;

const MEMBERS_WITH_AFFILIATE = gql`
  query Members($usernames: [String!]) {
    members(filter: { username: { in: $usernames } }) {
      edges {
        node {
          id
          username
          affiliate {
            code
            id
          }
        }
      }
    }
  }
`;

const PROMOS = gql`
  query Promos($filter: PromosFilterInput!) {
    promos(filter: $filter) {
      totalCount
    }
  }
`;

const CREATE_MANUAL_ADJUSTMENT_JOB = gql`
  mutation CreateCreateManualAdjustmentJob(
    $input: CreateCreateManualAdjustmentsJobInput!
    $password: String
  ) {
    createCreateManualAdjustmentsJob(input: $input, password: $password)
  }
`;

const BulkBalanceAdjustment = () => {
  const [createManualAdjustment] = useMutation(CREATE_MANUAL_ADJUSTMENT);

  const [isLoading, setLoading] = useState(false);
  const [isShow, setShow] = useState(false);
  const [showSubmitProcess, setShowSubmitProcess] = useState(false);
  const [showProcessComplete, setShowProcessComplete] = useState(false);
  const [invalidMembers, setInvalidMembers] = useState<any[]>([]);
  const [successMembers, setSuccessMembers] = useState<any[]>([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [moreDetails, setMoreDetails] = useState(false);
  const [processType, setProcessType] = useState('');
  const [excelProgress, setExcelProgress] = useState(false);
  const [reportComplete, setReportComplete] = useState(false);
  const dispatch = useDispatch();
  const { jobs } = useSelector((state: any) => state?.jobs);

  const [createJob] = useMutation(CREATE_MANUAL_ADJUSTMENT_JOB);
  const client = useApolloClient();
  const translate = useTranslate();

  const addInvalidMembers = (
    username: string,
    amount: number,
    messageText: string,
    translateOpts: any = null
  ) => {
    setInvalidMembers((prev: any[]) => [
      ...prev,
      {
        username,
        amount,
        message: messageText,
        ...(translateOpts && { translateOpts }),
      },
    ]);
  };

  const resetState = () => {
    setShowSubmitProcess(false);
    setShowProcessComplete(false);
    setInvalidMembers([]);
    setSuccessMembers([]);
    setTotalMembers(0);
    setMoreDetails(false);
    setReportComplete(false);
    setShow(!isShow);
    dispatch(jobsActions.removeJob(JobType.BULK_MANUAL_ADJUSTMENT));
  };

  const findUsersAffiliate = async (users: Array<Rec>) => {
    const usernames = users.map((user) => user.username);
    const response = await client.query({
      query: MEMBERS_WITH_AFFILIATE,
      variables: {
        usernames,
      },
    });
    const respMembers = response.data.members.edges.map(
      (edge: Rec) => edge.node
    );
    const membersIdObj = respMembers.reduce((acc: Rec, member: Rec) => {
      acc[member.id] = !!member.affiliate?.code;
      return acc;
    }, {});
    const copyOfUsers = [...users];

    const notAffiliatedMembers = copyOfUsers.filter(
      (user) => !membersIdObj[user.id]
    );

    notAffiliatedMembers.forEach((member) => {
      addInvalidMembers(
        member.username,
        member.amount,
        'MEMBER_IS_NOT_AN_AFFILIATE'
      );
    });

    return membersIdObj;
  };

  const handleShow = () => {
    resetState();
    setShow(!isShow);
  };

  const processManualAdjustment = async (objData: Rec) => {
    const { actual, type, remarks, accountPassword, turnover, promo } = objData;
    const optionalTurnover = turnoverShowTypes.includes(type) && {
      turnoverRequirementMultiplier: +turnover,
    };

    const fileData = objData.excelData ?? objData.textData;
    const optionalPromo = () => {
      if (type === PROMO_PAYOUT) {
        return { promo };
      }

      return {};
    };
    const { data } = await createJob({
      variables: {
        password: accountPassword,
        input: {
          type,
          file: fileData,
          remarks,
          ...(actualShowTypes.includes(type) && { actual }),
          ...optionalTurnover,
          ...optionalPromo(),
        },
      },
    });

    setTimeout(() => {
      dispatch(
        jobsActions.addJob({
          id: data.createCreateManualAdjustmentsJob,
          name: JobType.BULK_MANUAL_ADJUSTMENT,
        })
      );
    }, 2000);
  };

  const handleSubmit = async ({
    amount,
    actual,
    type,
    remarks,
    accountPassword,
    username,
    turnover,
    promo,
    excel,
    excelData,
    text,
    textData,
  }: Rec) => {
    // eslint-disable-next-line no-unreachable
    setLoading(true);
    try {
      if (type === PROMO_PAYOUT) {
        const { data: promoData } = await client.query({
          query: PROMOS,
          fetchPolicy: 'network-only',
          variables: {
            filter: {
              id: {
                eq: promo,
              },
            },
          },
        });

        const promoValid = coercedGet(promoData, 'promos.totalCount', 0) > 0;

        if (!promoValid) {
          setLoading(false);
          message.warning(translate(messages.PROMO_DOES_NOT_EXIST));
          return;
        }
      }

      const optionalTurnover = turnoverShowTypes.includes(type) && {
        turnoverRequirementMultiplier: +turnover,
      };

      const optionalPromo = () => {
        if (type === PROMO_PAYOUT) {
          return { promo };
        }

        return {};
      };

      const mutationsPromise = (users: Rec[]) =>
        users.map((validUser: Rec) => {
          const dataToSend = {
            input: {
              account: validUser.id,
              amount: +validUser.amount,
              actual,
              type,
              remarks,
              ...optionalTurnover,
              ...optionalPromo(),
            },
            password: accountPassword,
          };

          return createManualAdjustment({
            variables: dataToSend,
          })
            .then((result) => {
              setSuccessMembers((prev) => [...prev, validUser]);
              return result;
            })
            .catch((err) => {
              const noWithdrawalBank =
                coercedGet(err, 'graphQLErrors[0].original.code') ===
                'EXISTING_MEMBER_BANK_ACCOUNT_REQUIRED';
              const invalidPassword =
                coercedGet(err, 'graphQLErrors[0].original.code') ===
                'INVALID_PASSWORD';

              if (invalidPassword) {
                throw new Error('');
              }

              if (noWithdrawalBank) {
                setInvalidMembers((prev) => [
                  ...prev,
                  {
                    ...validUser,
                    message: 'EXISTING_MEMBER_BANK_ACCOUNT_REQUIRED',
                  },
                ]);
                return;
              }

              const insufficientFundError =
                coercedGet(
                  err,
                  'graphQLErrors[0].original.code',
                  'UNKNOWN_ERROR'
                ) === 'INSUFFICIENT_FUND';
              setInvalidMembers((prev) => [
                ...prev,
                {
                  ...validUser,
                  message: insufficientFundError
                    ? 'NOT_SUFFICIENT_BALANCE'
                    : 'UNKNOWN_ERROR',
                },
              ]);
            });
        });

      if (excel) {
        if (excelData) {
          setProcessType('excel');
          await processManualAdjustment({
            actual,
            type,
            remarks,
            accountPassword,
            turnover,
            promo,
            excelData,
          });
          setLoading(false);
          setExcelProgress(true);
        } else {
          message.error('No File Uploaded!');
          setLoading(false);
          setShowSubmitProcess(false);
        }
        return;
      }

      if (text) {
        if (textData) {
          setProcessType('text');
          await processManualAdjustment({
            actual,
            type,
            remarks,
            accountPassword,
            turnover,
            promo,
            textData,
          });
          setLoading(false);
          setExcelProgress(true);
        } else {
          message.error('No File Uploaded!');
          setLoading(false);
          setShowSubmitProcess(false);
        }
        return;
      }

      setProcessType('bulk');
      setShowSubmitProcess(true);
      setTotalMembers(username.length);

      let users = username.map((user: { key: string; label: string }) => ({
        id: user.key,
        username: user.label,
        amount,
      }));

      if (type === AFFILIATE_COMMISION) {
        const membersIdObj = await findUsersAffiliate(users);
        users = users.filter((user: { id: string }) => !!membersIdObj[user.id]);
      }

      const userMutationsPromises = mutationsPromise(users);
      try {
        const successArray = (await Promise.all(userMutationsPromises)).filter(
          Boolean
        );

        setShowSubmitProcess(false);
        setShowProcessComplete(true);

        if (successArray.length > 0) {
          message.success(
            translate(messages['member-balance.success-message.text'])
          );
        }
      } catch {
        message.error(translate(messages.INVALID_PASSWORD));
        setInvalidMembers([]);
        setShowSubmitProcess(false);
      }
    } catch {
      setLoading(false);
    }
    setLoading(false);
  };
  const bulkManualAdjustmentJob = jobs?.find(
    (x: { name: string }) => x.name === JobType.BULK_MANUAL_ADJUSTMENT
  );

  const hideMainModal =
    showSubmitProcess || showProcessComplete || excelProgress || reportComplete;

  return (
    <>
      <Button
        style={{
          width: '200px',
        }}
        onClick={handleShow}
      >
        Bulk Balance Adjustment
      </Button>
      {isShow && (
        <StyledModal
          title={
            <FormattedMessage
              id="edit-balance.text"
              defaultMessage="Edit balance"
            />
          }
          maskClosable={!showSubmitProcess}
          closable={!showSubmitProcess}
          width={hideMainModal ? 350 : 500}
          onCancel={handleShow}
          visible={isShow}
          footer={false}
        >
          <div
            className={hideMainModal ? 'position-absolute' : ''}
            style={{
              top: hideMainModal ? '-99999px' : 'unset',
            }}
          >
            <BulkAdjustBalanceMenuForm
              initialValues={{
                username: [],
                action: 'ADD',
                type: '',
                amount: '',
                actual: false,
                remarks: '',
                accountPassword: '',
                excel: false,
                excelData: '',
                text: false,
                textData: '',
                ...BALANCE_FORM_EXTRA_VALUES,
              }}
              isLoading={isLoading}
              onSubmit={handleSubmit}
              onClose={handleShow}
            />
          </div>

          {(processType === 'excel' || processType === 'text') && (
            <>
              {excelProgress && !reportComplete && (
                <ExcelProgress
                  percents={bulkManualAdjustmentJob?.progress * 100 || 0}
                  processComplete={() => {
                    setReportComplete(true);
                    setExcelProgress(false);
                  }}
                />
              )}

              {reportComplete && (
                <ResultReport
                  invalidMembers={bulkManualAdjustmentJob?.failedReport || []}
                  successCount={bulkManualAdjustmentJob?.result?.successCount}
                  errorFileUri={
                    bulkManualAdjustmentJob?.result?.errorReportFile?.uri
                  }
                  totalMembersCount={
                    bulkManualAdjustmentJob?.result?.successCount +
                    bulkManualAdjustmentJob?.result?.failCount
                  }
                  onCloseModal={resetState}
                />
              )}
            </>
          )}
          <AdjustmentByBulkProcess
            processType={processType as 'excel' | 'text' | 'bulk'}
            invalidMembers={invalidMembers}
            moreDetails={moreDetails}
            setMoreDetails={setMoreDetails}
            showProcessComplete={showProcessComplete}
            showSubmitProcess={showSubmitProcess}
            successMembers={successMembers}
            totalMembers={totalMembers}
            onCloseModal={handleShow}
          />
        </StyledModal>
      )}
    </>
  );
};

export default BulkBalanceAdjustment;
