import styled from 'styled-components';

export const StyledCustomClear = styled.span`
  font-size: 10px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export default {};
