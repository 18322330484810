import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import {
  START_PROCESS_DEPOSIT,
  STOP_PROCESS_DEPOSIT,
} from 'graphql/mutations/deposit.mutation';
import { PrimitiveType } from 'intl-messageformat';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

type PropTypes = {
  id: string;
  status: string;
  refetchQueries: Array<object> | object;
  setRemarksError: (
    error?: string | PrimitiveType | React.ReactElement
  ) => void;
  toggleModal: () => void;

  disabled: boolean;
};

const ProcessDeposit = (props: PropTypes) => {
  const {
    id,
    status,

    setRemarksError,
    toggleModal,
    disabled,
  } = props;

  const messages = defineMessages({
    'remarks.text': {
      id: 'remarks.text',
      defaultMessage: 'Remarks',
    },
    startDepositProcess: {
      id: 'deposits.started-deposit-process.text',
      defaultMessage: 'Started deposit process',
    },
    stopDepositProcess: {
      id: 'deposits.stopped-deposit-process.text',
      defaultMessage: 'Stopped deposit process',
    },
  });
  const translate = useTranslate();

  const [startProcessDeposit, startProcessResult] = useMutation(
    START_PROCESS_DEPOSIT,
    {
      onCompleted: (data) => {
        if (!data.startProcessDeposit) return;
        message.success({
          content: (
            <span data-testid="start-success">
              {translate(messages.startDepositProcess)}
            </span>
          ),
        });
        setRemarksError();
      },
      onError: () => {
        setRemarksError();
      },
      variables: { id },
    }
  );

  const [stopProcessDeposit, stopProcessResult] = useMutation(
    STOP_PROCESS_DEPOSIT,
    {
      onCompleted: (data) => {
        if (!data.stopProcessDeposit) return;
        message.success({
          content: (
            <span data-testid="stop-success">
              {translate(messages.stopDepositProcess)}
            </span>
          ),
        });
        setRemarksError();
        toggleModal();
      },
      onError: () => {
        setRemarksError();
      },
      variables: { id },
    }
  );
  return (
    <>
      {status === 'PENDING' && (
        <Button
          data-testid="start-process"
          disabled={startProcessResult.loading || disabled}
          loading={startProcessResult.loading}
          onClick={() => startProcessDeposit()}
        >
          {status === 'PENDING' ? (
            <FormattedMessage
              id="start-process.text"
              defaultMessage="Start process"
            />
          ) : (
            <FormattedMessage id="reprocess.text" defaultMessage="Reprocess" />
          )}
        </Button>
      )}
      {status === 'PROCESSING' && (
        <Button
          data-testid="stop-process"
          disabled={stopProcessResult.loading}
          loading={stopProcessResult.loading}
          onClick={() => stopProcessDeposit()}
        >
          <FormattedMessage
            id="stop-process.text"
            defaultMessage="Stop process"
          />
        </Button>
      )}
    </>
  );
};

export default ProcessDeposit;
