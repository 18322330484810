import React from 'react';
import moment from 'moment';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { DATE_FORMAT } from 'constants/date';
import { StyledRangePicker, StyledLabel } from '../../../../styles';

interface Props {
  onChange: (e: any) => any;
  value: any;
  disabled: boolean;
}

export const BirthDateFilter: React.FC<Props> = ({
  value,
  onChange,
  disabled,
}) => {
  const handleChange = (e: any) => {
    if (e?.length) {
      onChange({
        gte: e[0].format('YYYY-MM-DD'),
        lte: e[1].format('YYYY-MM-DD'),
      });
    } else {
      onChange(null);
    }
  };

  const translate = useTranslate();
  return (
    <div className="mt-1">
      <div>
        <StyledLabel>
          <FormattedMessage
            id="birthdate.text"
            defaultMessage="Date of birth"
          />
        </StyledLabel>
      </div>
      <StyledRangePicker
        data-testid="birthdate-filter"
        value={
          value
            ? [
                value.gte ? moment(value.gte) : null,
                value.lte ? moment(value.lte) : null,
              ]
            : null
        }
        format={DATE_FORMAT}
        onChange={handleChange}
        disabled={disabled}
        placeholder={[
          translate(messages.START_DATE),
          translate(messages.END_DATE),
        ]}
      />
    </div>
  );
};
