import * as React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { SelectProps } from 'antd/lib/select';

export const StyledSpan = styled.span`
  font-size: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);

  :hover {
    text-decoration: underline;
    opacity: 0.7;
  }
`;

export const StyledLabel = styled.span`
  font-size: 10px;
  font-weight: bold;
`;

export const StyledSelect = styled((props: SelectProps<string[]>) => (
  <Select {...props} />
))`
  width: 100%;

  .ant-select-selection {
    padding: 4px;
    padding-top: 0;
    border-radius: 2px;
  }

  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }

  .ant-select-selection li.ant-select-selection__choice {
    border-radius: 4px;
  }
`;
