import styled from 'styled-components';
import { Icon, Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Table } from 'antd';

export const StyledItem = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px 12px;
`;

export const NoBorderButton = styled(Button)`
  border: none;
  box-shadow: none;
  padding: 0;
`;

export const FlexItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
`;

export const ActionItem = styled.div`
  border-top: 1px solid #e8e8e8;
  text-align: right;
`;

export const FlexFormItem = styled(Form.Item)`
  .ant-legacy-form-item-label {
    label {
      display: flex;
      align-items: center;
    }
  }
  ant-input-number,
  .ant-input-number-lg {
    width: 100%;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input,
  .ant-input:not(:last-child) {
    padding-right: 20px;
  }
  .ant-legacy-form-explain {
    position: absolute;
    margin: 0;
    top: 20px;
    left: -50px;
  }
`;

export const TopPercentHelper = styled(Icon)`
  svg {
    transform: rotate(180deg);
    font-size: 20px;
  }
`;

export const BottomPercentHelper = styled(Icon)`
  svg {
    transform: rotateY(180deg);
    font-size: 20px;
  }
`;

export const StyledTable = styled(Table)<any>`
  .ant-table-thead {
    th {
      background: transparent;
      border: 0;
      padding-left: 0;
    }
  }
  .ant-table-tbody {
    td {
      padding-left: 0;
    }
  }
`;
