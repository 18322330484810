import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect: any = styled(Select)`
  width: 100% !important;

  ${({ isFilter }: { isFilter: boolean }) =>
    isFilter &&
    `
    .ant-select-selection {
      border-radius: 0;
    }
    .ant-select-selection__rendered {
      margin-left: 0 !important;
    }
  `}
`;

export const StyledCustomClear = styled.span`
  font-size: 10px;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export default {};
