import React from 'react';
import { useFilterValues } from 'contexts/Filters';
import { Tag } from 'antd';
import coercedGet from 'utils/coercedGet';
import messages from 'messages';
import { messages as memberInteractionLogMessages } from 'constants/memberInteractionLog';
import { formatDate } from 'utils/dateUtils';
import useTranslate from 'utils/useTranslate';
import { capitalize, startCase, omit } from 'lodash';
import { DATE_FORMAT } from 'constants/date';
import MemberTag from 'components/MemberTag';
import { useScreenTabV2 } from 'store/screenTabState';

const FilterConditions = () => {
  const { filters, onFilterChange } = useFilterValues();

  const { getTab } = useScreenTabV2();
  const { searchRef } = getTab('member-interaction-log-report');

  const { filterFieldName } = searchRef || {};

  const handleRemoveFilterTag = (name: string, value: string, e: any) => {
    e.preventDefault();
    const field = coercedGet(filters, name, []);
    const data = field.in.filter((item: string) => item !== value);
    const newField = {
      in: data,
    };
    onFilterChange({
      ...filters,
      [`${name}`]: data.length ? newField : null,
    });
  };

  const handleRemoveDate = (name: string, e: any) => {
    e.preventDefault();

    onFilterChange({
      ...filters,
      [`${name}`]: null,
    });
  };

  const translate = useTranslate();

  const filterKey = filterFieldName === 'username' ? 'member' : filterFieldName;

  const modifiedFilters = (omit(filters, filterKey) as unknown) as Record<
    string,
    any
  >;

  return (
    <>
      {modifiedFilters.actor
        ? modifiedFilters.actor.in?.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('actor', item, e)}
            >
              {translate(memberInteractionLogMessages[`${item}`])}
            </Tag>
          ))
        : null}
      {modifiedFilters.type
        ? modifiedFilters.type.in?.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('type', item, e)}
            >
              {startCase(capitalize(item))}
            </Tag>
          ))
        : null}
      {modifiedFilters.brandId
        ? modifiedFilters.brandId.in?.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('brandId', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {modifiedFilters.platformId
        ? modifiedFilters.platformId.in?.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('platformId', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
      {modifiedFilters.module
        ? modifiedFilters.module.in?.map((item: string, key: number) => (
            <Tag
              key={key}
              closable
              onClose={(e) => handleRemoveFilterTag('module', item, e)}
            >
              {startCase(capitalize(item))}
            </Tag>
          ))
        : null}
      {modifiedFilters.member
        ? modifiedFilters.member.in?.map((item: string, key: number) => (
            <MemberTag
              fieldName="username"
              key={key}
              onClose={(e: React.MouseEvent<HTMLElement>) =>
                handleRemoveFilterTag('member', item, e)
              }
              id={item}
            />
          ))
        : null}
      {modifiedFilters?.dateTimeCreated ? (
        <Tag closable onClose={(e) => handleRemoveDate('dateTimeCreated', e)}>
          {translate(messages.CREATION_DATE)}:{' '}
          {formatDate(modifiedFilters?.dateTimeCreated?.gte, DATE_FORMAT)}~
          {formatDate(modifiedFilters?.dateTimeCreated?.lte, DATE_FORMAT)}
        </Tag>
      ) : null}
      {modifiedFilters?.brand
        ? modifiedFilters?.brand?.in?.map((item: string) => (
            <Tag
              key={item}
              closable
              onClose={(e: any) => handleRemoveFilterTag('type', item, e)}
            >
              {item}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
