import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, notification } from 'antd';
import styled from 'styled-components';
import messages from 'messages';
import TextField from 'components/TextField';
import { otpAuthenticate } from 'utils/getAuthTokens';
import useTranslate from 'utils/useTranslate';
import { FormattedMessage } from 'react-intl';
import Storage from 'constants/storage';

function QrCodeSignin(props: Record<string, any>) {
  const initialValues = {
    authCode: '',
  };

  const { token, history } = props;
  const translate = useTranslate();

  return (
    <Wrapper>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const { authCode } = values;

          try {
            const response = await otpAuthenticate(authCode, token);
            const {
              data: { refresh, access },
            } = response;

            if ((response as Record<string, any>)?.code === 'INVALID_PIN') {
              notification.error({
                message: translate(messages.INVALID_PIN),
              });
              setSubmitting(false);
              return;
            }

            localStorage.setItem(Storage.ACCESS_TOKEN, access);
            localStorage.setItem(Storage.REFRESH_TOKEN, refresh);
            history.push('/');
            // eslint-disable-next-line no-empty
          } finally {
          }
          setSubmitting(false);
        }}
        initialValues={initialValues}
        validationSchema={Yup.object({
          authCode: Yup.string()
            .min(6)
            .required('Authentication Code is required'),
        })}
        render={(formikProps) => {
          const { handleSubmit, values, isSubmitting } = formikProps;
          const { authCode } = values;

          return (
            <Form>
              <>
                <div className="qr-code">
                  <p className="message">
                    <FormattedMessage
                      id="enter-qr-code.text"
                      defaultMessage="Enter the 6 digit key below and click Signin."
                    />
                  </p>
                </div>
                <TextField
                  type="text"
                  name="authCode"
                  label="Two Factor Authentication Code"
                  placeholder="Authentication Code"
                  value={authCode}
                  maxLength={32}
                />
                <Button
                  block
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={handleSubmit as any}
                  type="primary"
                  htmlType="submit"
                  size="large"
                >
                  <FormattedMessage id="signin.text" defaultMessage="Signin" />
                </Button>
              </>
            </Form>
          );
        }}
      />
    </Wrapper>
  );
}

export default QrCodeSignin;

const Wrapper = styled.div`
  .qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;

    .message {
      font-size: 12px;
    }

    .qr {
      width: 65%;
    }
  }

  .secret-key {
    .btn {
      transform: translateY(-12px);
    }

    .btn:focus {
      box-shadow: none;
    }
  }

  .font-12 {
    font-size: 12px;
  }
`;
