import * as yup from 'yup';
import messages from 'messages';

const validationSchema = (translate: any) => {
  const HEX_COLOR_PATTERN = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return yup.object().shape({
    name: yup
      .string()
      .required(translate(messages['name-field-required-error.text']))
      .max(
        24,
        translate(messages['maximum-number-of-characters.text'], { count: 24 })
      ),
    color: yup
      .string()
      .required(translate(messages['color-field-required-error.text']))
      .matches(
        HEX_COLOR_PATTERN,
        translate(messages['must-be-valid-hex-color-error.text'])
      )
      .nullable(),
    description: yup.string().nullable(),
  });
};

export default validationSchema;
