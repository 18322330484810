import { defineMessages } from 'react-intl';

const messages = defineMessages({
  MANUAL_ADJUSTMENT: {
    id: 'MANUAL_ADJUSTMENT',
    defaultMessage: 'Manual Adjustment',
  },
  new: {
    id: 'new-payment-gateway.text',
    defaultMessage: 'New payment gateway',
  },
  add: {
    id: 'add-payment-gateway.text',
    defaultMessage: 'Add payment gateway',
  },
  successfullyadded: {
    id: 'payment-method-created.text',
    defaultMessage: 'Payment method created',
  },
  name: {
    id: 'payment-method-name.text',
    defaultMessage: 'Payment method name',
  },
  accountinfo: {
    id: 'account-information.text',
    defaultMessage: 'Account information',
  },
  source: {
    id: 'payment-source.text',
    defaultMessage: 'Payment source',
  },
  internal: {
    id: 'internal.text',
    defaultMessage: 'Internal',
  },
  thirdparty: {
    id: '3rd-party.text',
    defaultMessage: '3rd party',
  },
  gateway: {
    id: 'third-party-gateway.text',
    defaultMessage: '3rd Party Gateway',
  },
  method: {
    id: 'payment-method.text',
    defaultMessage: 'Payment Method',
  },
  channel: {
    id: 'payment-channel.text',
    defaultMessage: 'Payment Channel',
  },
  cancel: {
    id: 'cancel.text',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'confirm.text',
    defaultMessage: 'Confirm',
  },
  banktransfer: {
    id: 'bank-transfer.text',
    defaultMessage: 'Bank transfer',
  },
  wechat: {
    id: 'wechat.text',
    defaultMessage: 'Wechat',
  },
  unionpay: {
    id: 'unionpay.text',
    defaultMessage: 'Union Pay',
  },
  alipay: {
    id: 'alipay.text',
    defaultMessage: 'Alipay',
  },
  hexopay: {
    id: 'hexopay.text',
    defaultMessage: 'Hexopay',
  },

  neteller: {
    id: 'neteller.text',
    defaultMessage: 'Neteller',
  },

  skrill: {
    id: 'skrill.text',
    defaultMessage: 'Skrill',
  },
  wonkapay: {
    id: 'wonkapay.text',
    defaultMessage: 'Wonkapay',
  },
  successfullyupdated: {
    id: 'payment-method-updated.text',
    defaultMessage: 'Successfully saved changes',
  },
  settings: {
    id: 'payment-settings.text',
    defaultMessage: 'Payment Settings',
  },
  exportexcel: {
    id: 'export-excel.text',
    defaultMessage: 'Export excel',
  },
  sourcemethods: {
    id: 'payment-settings.sources.text',
    defaultMessage: 'Sources / Method',
  },
  active: {
    id: 'active.text',
    defaultMessage: 'Active',
  },
  onoff: {
    id: 'on-off.text',
    defaultMessage: 'On/Off',
  },
  appdisplay: {
    id: 'app-display.text',
    defaultMessage: 'App Display',
  },
  rotation: {
    id: 'payment-settings.rotation.text',
    defaultMessage: 'Payment Gateway Rotation Settings',
  },
  successrateweight: {
    id: 'payment-settings.successrate.text',
    defaultMessage: 'Succ Rate Weight',
  },
  successrateweighttip: {
    id: 'payment-settings.successrate-tip.text',
    defaultMessage:
      'This is the relative importance assigned to a a gateway’s rate of success. A high value here will prioritize gateways which have previously seen a high rate of successful deposits.',
  },
  elapseminweight: {
    id: 'payment-settings.elapsemin.text',
    defaultMessage: 'Elapse Min Weight',
  },
  elapseminweighttip: {
    id: 'payment-settings.elapsemin-tip.text',
    defaultMessage:
      'This is the relative importance assigned to a a gateway’s elapsed time since its last usage. A high value here will prioritize gateways which have not been utlilized over the longest period.',
  },
  save: {
    id: 'save.text',
    defaultMessage: 'Save',
  },
  nickname: {
    id: 'nickname.text',
    defaultMessage: 'Nickname',
  },
  qrcode: {
    id: 'qr-code.text',
    defaultMessage: 'QR code',
  },
  provider: {
    id: 'provider.text',
    defaultMessage: 'Provider',
  },
  bankacname: {
    id: 'account-name.text',
    defaultMessage: 'Account name',
  },
  bankacnumber: {
    id: 'account-number.text',
    defaultMessage: 'Account number',
  },
  bankacbranch: {
    id: 'branch.text',
    defaultMessage: 'Branch',
  },
  bankaccountry: {
    id: 'country.text',
    defaultMessage: 'Country',
  },
  bankacprovince: {
    id: 'province.text',
    defaultMessage: 'Province',
  },
  bankaccity: {
    id: 'city.text',
    defaultMessage: 'City',
  },
  selectone: {
    id: 'select-one.text',
    defaultMessage: 'Select one',
  },
  countryplaceholder: {
    id: 'type-in-a-country.text',
    defaultMessage: 'Type in a country',
  },
  bankname: {
    id: 'withdrawal-method.bank-name.text',
    defaultMessage: 'Bank Name',
  },
  limitsettings: {
    id: 'limit-settings.text',
    defaultMessage: 'Limit settings',
  },
  maximumdailydeposit: {
    id: 'maximum-daily-deposit.text',
    defaultMessage: 'Maximum daily deposit',
  },
  day: {
    id: 'day.text',
    defaultMessage: 'Day',
  },
  hour: {
    id: 'hour.text',
    defaultMessage: 'Hour',
  },
  min: {
    id: 'min.text',
    defaultMessage: 'Min',
  },
  max: {
    id: 'max.text',
    defaultMessage: 'max',
  },
  recommendedinstructions: {
    id: 'recommended-instructions.text',
    defaultMessage: 'Recommended instructions',
  },
  recommendedinstructionsplaceholder: {
    id: 'recommended-instructions.placeholder',
    defaultMessage: 'Input instruction message for user',
  },
  singledeposit: {
    id: 'single-deposit.text',
    defaultMessage: 'Single deposit',
  },
  enteramount: {
    id: 'enter-amount.text',
    defaultMessage: 'Enter Amount',
  },
  recommendedamount: {
    id: 'recommended-amount.text',
    defaultMessage: 'Recommended amount',
  },
  addbutton: {
    id: 'add.text',
    defaultMessage: 'Add',
  },
  limitrecommendedamount: {
    id: 'limit-15-groups-of-recommended-amount.text',
    defaultMessage: 'Limit 15 groups of recommended amount',
  },
  remove: {
    id: 'remove.text',
    defaultMessage: 'Remove',
  },
  maxdepositlimit: {
    id: 'max-deposit-limit.text',
    defaultMessage: 'Max deposit limit',
  },
  dailyDepositLimit: {
    id: 'daily-deposit-limit.text',
    defaultMessage: 'Daily deposit limit',
  },
  depositLimit: {
    id: 'deposit-limit.text',
    defaultMessage: 'Daily limit',
  },
  othersettings: {
    id: 'other-settings.text',
    defaultMessage: 'Other settings',
  },
  membershipvipincluded: {
    id: 'membership-vip-included.text',
    defaultMessage: 'Membership VIP included',
  },
  membershiployaltyexcluded: {
    id: 'membership-loyalty-excluded.text',
    defaultMessage: 'Membership loyalty excluded',
  },
  validity: {
    id: 'validity.text',
    defaultMessage: 'Validity',
  },
  minutes: {
    id: 'minutes.text',
    defaultMessage: 'Minutes',
  },
  remarks: {
    id: 'remarks.text',
    defaultMessage: 'Remarks',
  },
  turnover: {
    id: 'turnover.text',
    defaultMessage: 'Turnover',
  },
  validityhelper: {
    id: 'validity-help.text',
    defaultMessage:
      "The effective time of the company's deposit order. After the effective time expires, the system will automatically change the [unconfirmed] order to [cancelled].",
  },
  transactionfee: {
    id: 'transaction-fee.text',
    defaultMessage: 'Provider Transaction Fee',
  },
  status: {
    id: 'status.text',
    defaultMessage: 'Status',
  },
  enable: {
    id: 'enabled.text',
    defaultMessage: 'Enabled',
  },
  disable: {
    id: 'disabled.text',
    defaultMessage: 'Disabled',
  },
  edit: {
    id: 'edit.text',
    defaultMessage: 'Edit',
  },
  ok: {
    id: 'ok.text',
    defaultMessage: 'OK',
  },
  actions: {
    id: 'action.text',
    defaultMessage: 'Action',
  },
  duplicate: {
    id: 'duplicate.text',
    defaultMessage: 'Duplicate',
  },
  delete: {
    id: 'delete.text',
    defaultMessage: 'Delete',
  },
  areYouSure: {
    id: 'are-you-sure?.text',
    defaultMessage: 'Are you sure?',
  },
  effectiveminutes: {
    id: 'effective-minutes.text',
    defaultMessage: 'Effective minutes',
  },
  memberloyaltylevels: {
    id: 'select-member-loyalty-levels.text',
    defaultMessage: 'Select member loyalty levels',
  },
  memberlevelsexclude: {
    id: 'membership-level-excluded.text',
    defaultMessage: 'Membership level excluded',
  },
  excludedmembermarker: {
    id: 'excluded-member-marker.text',
    defaultMessage: 'Excluded Member Marker',
  },
  selectmemberlevelsexclude: {
    id: 'select-member-levels-to-exclude.text',
    defaultMessage: 'Select member levels to exclude',
  },
  selectalevel: {
    id: 'select-a-level.text',
    defaultMessage: 'Select a level',
  },
  entermemberloyaltylevel: {
    id: 'enter-member-loyalty-level.text',
    defaultMessage: 'Enter member loyalty level',
  },
  absolute: {
    id: 'absolute.text',
    defaultMessage: 'Absolute',
  },
  relative: {
    id: 'relative.text',
    defaultMessage: 'Relative',
  },
  search: {
    id: 'search.text',
    defaultMessage: 'Search',
  },
  quicksearch: {
    id: 'quick-search.text',
    defaultMessage: 'Quick search',
  },
  quicksearchplaceholder: {
    id: 'select-a-saved-custom-filter.text',
    defaultMessage: 'Select a saved custom filter',
  },
  enter: {
    id: 'enter-dynamic.text',
    defaultMessage: 'Enter {message}',
  },
  entername: {
    id: 'name.text',
    defaultMessage: 'Name',
  },
  bank: {
    id: 'bank.text',
    defaultMessage: 'Bank',
  },
  on: {
    id: 'on.text',
    defaultMessage: 'On',
  },
  off: {
    id: 'off.text',
    defaultMessage: 'Off',
  },
  offlinebank: {
    id: 'offline-bank.text',
    defaultMessage: 'OfflineBankTransfer',
  },
  onlinebank: {
    id: 'online-bank.text',
    defaultMessage: 'OnlineBankTransfer',
  },
  onlinealipay: {
    id: 'online-alipay.text',
    defaultMessage: 'OnlineAlipay',
  },
  onlinewechat: {
    id: 'online-wechat.text',
    defaultMessage: 'OnlineWechat',
  },
  maxDecimalPlacesError: {
    id: 'max-decimal-places.error',
    defaultMessage: 'maximum of {max} decimal places',
  },
  numberMinError: {
    id: 'number.min.error',
    defaultMessage: 'must be greater than or equal to {min}',
  },
  suggestedAmountInputError: {
    id: 'suggestedAmountsError',
    defaultMessage: 'Amount should be in the range of Single Deposit values',
  },
  suggestedAmountIsPositiveError: {
    id: 'negative-figures-not-allowed.text',
    defaultMessage: 'Negative figures are not allowed',
  },
  paymentSource: {
    id: 'payment-source.text',
    defaultMessage: 'Payment source',
  },
  paymentmethodtype: {
    id: 'payment-method-type.text',
    defaultMessage: 'Payment method type',
  },
  confirmsettings: {
    id: 'payment-settings-confirmation.text',
    defaultMessage: 'Do you wish to save your changes to Payment Setting?',
  },
  paymentMethods: {
    id: 'payment-methods.text',
    defaultMessage: 'Payment methods',
  },
  currentAccumulation: {
    id: 'current-accumulation.text',
    defaultMessage: 'Current Accumulation',
  },
  maxCurrentAccumulation: {
    id: 'maximum-current-accumulation.text',
    defaultMessage: 'Maximum current accumulation',
  },
  minCurrentAccumulation: {
    id: 'minimum-current-accumulation.text',
    defaultMessage: 'Minimum current accumulation',
  },
  maxDepositAmount: {
    id: 'maximum-deposit-amount.text',
    defaultMessage: 'Maximum deposit amount',
  },
  minDepositAmount: {
    id: 'minimum-deposit-amount.text',
    defaultMessage: 'Minimum deposit amount',
  },
  memberVipIncluded: {
    id: 'member-vip-included.text',
    defaultMessage: 'Member VIP included',
  },
  customAmountInput: {
    id: 'custom-amount-input.text',
    defaultMessage: 'Custom Amount Input',
  },
  traditional: {
    id: 'traditional.text',
    defaultMessage: 'Traditional',
  },
  cryptocurrency: {
    id: 'cryptocurrency.text',
    defaultMessage: 'Cryptocurrency',
  },
  USDT: {
    id: 'USDT.text',
    defaultMessage: 'USDT',
  },
  blockchain: {
    id: 'blockchain.text',
    defaultMessage: 'Blockchain',
  },
  destination_address: {
    id: 'destination_address.text',
    defaultMessage: 'Destination Address',
  },
  exchange_rate: {
    id: 'exchange_rate.text',
    defaultMessage: 'Exchange Rate',
  },
  exchange_rate_label_switch: {
    id: 'exchange_rate_label_switch.text',
    defaultMessage: 'Automatically Update Exchange Rate',
  },
  frequency: {
    id: 'frequency.text',
    defaultMessage: 'Frequency',
  },
  USDT_to_CNY_label: {
    id: 'USDT_to_CNY_label.text',
    defaultMessage: 'USDT to CNY Exchange Rate',
  },
  USDT_to_CNY_secondary_label: {
    id: 'USDT_to_CNY_secondary_label.text',
    defaultMessage: 'Numeric 4 decimal places, Mandatory',
  },
  USDT_text: {
    id: 'USDT_text.text',
    defaultMessage: 'USDT',
  },
  Bitcoin_text: {
    id: 'Bitcoin_text.text',
    defaultMessage: 'Bitcoin',
  },
  CRYPTOCURRENCY_text: {
    id: 'CRYPTOCURRENCY_text.text',
    defaultMessage: 'Cryptocurrency',
  },
  spread: {
    id: 'spread.text',
    defaultMessage: 'Spread',
  },
  spread_label: {
    id: 'spread_label.text',
    defaultMessage:
      'Numeric 4 decimal places, Mandatory, Default 0.0500, cannot be negative',
  },
  confirmation_text: {
    id: 'confirmation_text.text',
    defaultMessage: 'Minimum number of confirmations',
  },
  confirmation_text_secondary_label: {
    id: 'confirmation_text_secondary_label.text',
    defaultMessage: 'Cannot be zero or negative',
  },
  source_label: {
    id: 'source_label.text',
    defaultMessage: 'Source',
  },
  CONFIRM_ENABLE: {
    id: 'CONFIRM_ENABLE',
    defaultMessage: 'Confirm enable',
  },
  CONFIRM_DISABLE: {
    id: 'CONFIRM_DISABLE',
    defaultMessage: 'Confirm disable',
  },
  ENABLE: {
    id: 'enable.text',
    defaultMessage: 'Enable',
  },
  DISABLE: {
    id: 'disable.text',
    defaultMessage: 'Disable',
  },
  NO_PAYMENT_METHOD_FOUND: {
    id: 'NO_PAYMENT_METHOD_FOUND',
    defaultMessage:
      'No payment method found. Please check provider credentials',
  },
  PAYMENT_METHODS_CONFIG: {
    id: 'payment-methods-config.text',
    defaultMessage: 'Payment Methods Config',
  },
  AUTOMATICALLY_UPDATE_EXCHANGE_RATE: {
    id: 'AUTOMATICALLY_UPDATE_EXCHANGE_RATE',
    defaultMessage: 'Automatically Update Exchange Rate',
  },
  EXCHANGE_RATE_USDT: {
    id: 'EXCHANGE_RATE_USDT',
    defaultMessage: 'Exchange Rate (USDT → £)',
  },
  SPREAD_TEXT: {
    id: 'SPREAD_TEXT',
    defaultMessage: 'Spread',
  },
  MINIMUM_NUMBER_OF_CONFIRMATIONS: {
    id: 'MINIMUM_NUMBER_OF_CONFIRMATIONS',
    defaultMessage: 'Minimum number of Confirmations',
  },
  SOURCE_TEXT: {
    id: 'SOURCE_TEXT',
    defaultMessage: 'Source',
  },
  FREQUENCY_TEXT: {
    id: 'FREQUENCY_TEXT',
    defaultMessage: 'Frequency',
  },
  DESTINATION_ADDRESS: {
    id: 'DESTINATION_ADDRESS',
    defaultMessage: 'Destination Address',
  },
  CUSTOM_AMOUNT_INPUT: {
    id: 'CUSTOM_AMOUNT_INPUT',
    defaultMessage: 'Custom Amount Input',
  },
  SELECT_PROVIDER: {
    id: 'select-a-deposit-provider.text',
    defaultMessage: 'Select a deposit provider',
  },
  NEGATIVE_NUMBERS_ARE_NOT_ALLOWED: {
    id: 'NEGATIVE_NUMBERS_ARE_NOT_ALLOWED',
    defaultMessage: 'Negative numbers are not allowed',
  },
});

export default messages;
