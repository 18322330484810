import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { DocumentNode } from 'graphql';
import { DELETE_DEPOSIT_PROVIDER } from 'graphql/mutations/depositProvider.mutation';
import { DEPOSIT_PROVIDERS } from 'graphql/queries/depositProvider.query';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslation from 'utils/useTranslate';

const DeleteProvider = (props: {
  toggleModal: () => void;
  id: string | null;
  refetchVariables: Record<string, any>;
}) => {
  const { toggleModal, id, refetchVariables } = props;
  const [isShow, setShow] = useState(false);

  const translate = useTranslation();
  const handleShow = (e?: { preventDefault: () => void }): void => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const [deleteDepositProvider, { loading }] = useMutation(
    DELETE_DEPOSIT_PROVIDER,
    {
      onCompleted: () => {
        message.success(
          <span data-testid="delete-success">
            {translate(messages.DEPOSIT_PROVIDER_DELETED)}
          </span>
        );

        toggleModal();
        handleShow();
      },
    }
  );

  return (
    <>
      <Modal
        visible
        maskClosable={false}
        closable={false}
        width={416}
        okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
        cancelText={
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        }
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={
          { loading, 'data-testid': 'confirm' } as {
            loading: boolean;
            'data-testid': string;
          }
        }
        onCancel={toggleModal}
        onOk={() => {
          deleteDepositProvider({
            variables: {
              id,
            },
            refetchQueries: [
              {
                query: DEPOSIT_PROVIDERS,
                fetchPolicy: 'network-only',
                variables: refetchVariables,
              } as {
                query: DocumentNode;
                fetchPolicy: string;
                variables: Record<string, any>;
              },
            ],
          });
        }}
      >
        <div className="ant-modal-confirm-body">
          <QuestionCircleOutlined style={{ color: '#faad14' }} />
          <span className="ant-modal-confirm-title">
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />{' '}
            <FormattedMessage id="delete.text" defaultMessage="Delete" />
          </span>
          <div className="ant-modal-confirm-content">
            <FormattedMessage
              id="are-you-sure.text"
              defaultMessage="Are you sure?"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteProvider;
