import React, { useState } from 'react';
import { EllipsisOutlined, MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Control, useFieldArray } from 'react-hook-form';
import { ALink } from 'components/ALink/ALink';
import { useEffectOnce } from 'react-use';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import { TaskCard } from './TaskCard';
import { PromptWarningModal } from '../../utils';

type Props = {
  nestIndex: number;
  control: Control;
  item: Record<string, any>;
  errors: Record<string, any>;
  watchPointsSetting: any;
  removeDay: (index: number) => void;
  numberOfDays: number;
  duplicateDay: () => void;
  swapDay: (from: number, to: number) => void;
};

const appendDefaultValues = {
  type: 'ONLINE_PAYMENT_METHOD_DEPOSIT',
  amount: '',
  multiplier: true,
  rewardMultiplier: true,
  rewardPoints: undefined,
  rewardAmount: '',
  rewardType: 'MONEY',
  turnoverRequirementMultiplier: '',

  // optional fields
  vendors: [],
  gameTypes: [],
};

export const Day = ({
  nestIndex,
  control,
  item,
  errors,
  watchPointsSetting,
  removeDay,
  numberOfDays,
  duplicateDay,
  swapDay,
}: Props) => {
  const day = nestIndex + 1;
  const [showModal, setShowModal] = useState(false);
  const { fields, append, remove, insert, swap } = useFieldArray({
    control,
    name: `days[${nestIndex}].tasks`,
  });
  const translate = useTranslate();

  const [, drop] = useDrop({
    accept: 'task',
    drop: (itemDrop: DragObjectWithType & { id: number }) => {
      swapDay(itemDrop.id, nestIndex);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const [, drag, preview] = useDrag({
    item: { type: 'task', id: nestIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const attachRef = (el: HTMLElement) => {
    preview(el);
    drop(el);
  };

  useEffectOnce(() => {
    if (item?.tasks?.length === 0) {
      append(appendDefaultValues);
      return;
    }

    if (fields?.length !== item?.tasks?.length) {
      append(item.tasks);
    }
  });

  return (
    <section>
      <section ref={attachRef} className="mt-3 d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <div ref={drag}>
            <MenuOutlined
              style={{ cursor: 'grab' }}
              className="text-muted fs-18 mr-3"
            />
          </div>
          <span className="fs-17 text-black mr-3">
            <FormattedMessage id="DAY" defaultMessage="Day" /> {day}
          </span>
          <ALink className="fs-16" onClick={() => append(appendDefaultValues)}>
            + <FormattedMessage id="TASK" defaultMessage="Task" />
          </ALink>
        </div>
        <Dropdown
          overlay={() => (
            <Menu>
              <Menu.Item
                onClick={() => {
                  remove();
                  setTimeout(() => {
                    append(appendDefaultValues);
                  });
                }}
              >
                <FormattedMessage id="CLEAR_DAY" defaultMessage="Clear Day" />
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  duplicateDay();
                }}
              >
                <FormattedMessage
                  id="DUPLICATE_DAY"
                  defaultMessage="Duplicate Day"
                />
              </Menu.Item>
              <Menu.Item
                onClick={() => setShowModal(true)}
                disabled={numberOfDays === 1}
              >
                <FormattedMessage id="DELETE_DAY" defaultMessage="Delete Day" />
              </Menu.Item>
            </Menu>
          )}
        >
          <EllipsisOutlined
            className="cursor-pointer"
            style={{ fontSize: '25px' }}
          />
        </Dropdown>
      </section>

      {fields.map((itemNested, idx) => (
        <TaskCard
          swap={swap}
          insert={insert}
          watchPointsSetting={watchPointsSetting}
          errors={errors}
          key={itemNested?.id}
          remove={remove}
          nestIndex={nestIndex}
          control={control}
          index={idx}
          item={itemNested}
          disableDelete={fields?.length === 1}
        />
      ))}

      <PromptWarningModal
        onOk={() => removeDay(nestIndex)}
        visible={showModal}
        onCloseFn={() => setShowModal(false)}
        title={translate(messages.DELETE_DAY)}
      />
    </section>
  );
};
