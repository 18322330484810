import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { useLazyQuery } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import {
  isTypenameInternal,
  PAYMENT_METHODS_LIST,
  PAYMENT_TYPES,
  isTypeBank,
  isTypeWechat,
  isTypeAlipay,
  isTypeHexopay,
} from 'constants/paymentTypes';
import messages from 'messages';
import { Select as AntdSelect } from 'antd';
import { WithdrawalMethodType } from 'types/graphqlTypes';
import { WITHDRAWAL_METHODS } from './queries';

const Select: any = AntdSelect;
const { Option } = Select;

type Props = {
  value: string[] | string | Array<Record<string, any>>;
  placeHolder: string;
  multiple?: boolean;
  labelAsValue?: boolean;
  onChange: (e: any) => void;
  disabled?: boolean;
  type?: string;
  queryFilter?: Record<string, any>;
  showPaymentMethod?: boolean;
};

const WithdrawalMethodsSelect = ({
  showPaymentMethod,
  value,
  placeHolder,
  onChange,
  type,
  disabled,
  queryFilter,
  multiple,
  labelAsValue,
}: Props) => {
  const [edges, setEdges] = useState([]);

  const { formatMessage } = useIntl();

  const internalWithdrawalMethods = [
    PAYMENT_TYPES.OFFLINE.BANK_WITHDRAW,
    PAYMENT_TYPES.OFFLINE.ALIPAY_WITHDRAW,
    PAYMENT_TYPES.OFFLINE.WECHAT_WITHDRAW,
    WithdrawalMethodType.HexopayWithdrawalMethod,
  ];

  const [filter, setFilter] = useState({
    name: {
      contains: '',
    },
  });

  const newFilter: Record<string, any> = { ...filter, ...queryFilter };

  if (type === 'INTERNAL') {
    newFilter.type = {
      in: internalWithdrawalMethods,
    };
  } else if (type === 'EXTERNAL') {
    newFilter.type = {
      nin: [
        ...internalWithdrawalMethods,
        PAYMENT_TYPES.OFFLINE.MANUAL_WITHDRAW,
      ],
    };
  }

  const [loadOptions, { loading }] = useLazyQuery(WITHDRAWAL_METHODS, {
    variables: {
      filter: newFilter,
      first: 10,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const {
        withdrawalMethods: { edges: withdrawalMethodEdges },
      } = data;

      setEdges(withdrawalMethodEdges);
    },
  });

  const handleSearch = (e: string) => {
    setFilter({
      name: {
        contains: e,
      },
    });
  };

  const handleChange = (e: any) => {
    onChange(e);
  };

  const getMethodType = (method: string) => {
    if (isTypeBank(PAYMENT_METHODS_LIST[method])) {
      return formatMessage(messages.BANK_TRANSFER);
    }

    if (isTypeWechat(PAYMENT_METHODS_LIST[method])) {
      return formatMessage(messages.WECHAT);
    }

    if (isTypeAlipay(PAYMENT_METHODS_LIST[method])) {
      return formatMessage(messages.ALIPAY);
    }

    if (isTypeHexopay(PAYMENT_METHODS_LIST[method])) {
      return formatMessage(messages.HEXOPAY);
    }

    return formatMessage(
      messages['withdrawal-method.payment-method.unknown.text']
    );
  };

  return (
    <Select
      data-testid="withdrawal-methods-select"
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      loading={loading}
      onSearch={debounce(handleSearch, 250)}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
      onDropdownVisibleChange={(open: boolean) => {
        if (open && !edges.length) loadOptions();
      }}
    >
      {edges.map(({ node }: Record<string, any>) => (
        <Option key={node.value} value={labelAsValue ? node.label : node.value}>
          {isTypenameInternal(node.__typename) ? (
            <>
              <span
                className={!showPaymentMethod ? 'width-method-type-option' : ''}
              >
                {getMethodType(node.__typename)} -{' '}
              </span>
              {node.label}
            </>
          ) : (
            <>
              <span
                className={!showPaymentMethod ? 'width-method-type-option' : ''}
              >
                {node.withdrawalProvider.name} -{' '}
              </span>
              {node.label}
            </>
          )}
        </Option>
      ))}
    </Select>
  );
};

export default WithdrawalMethodsSelect;
