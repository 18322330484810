import gql from 'graphql-tag';

export const GET_GEOLOCATION_CONFIG = gql`
  query {
    config {
      geolocationSettings {
        location {
          id
          displayName(language: EN)
        }
        redirectUrl
      }
    }
  }
`;
