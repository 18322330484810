import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import SelectFilterField from 'components/SelectFilterField';
import { getParentPermission } from 'pages/components/PermissionGroup/utils';
import {
  GET_PERMISSION_GROUP_NAMES,
  GET_PERMISSION_GROUP_PERMISSIONS,
  GET_PERMISSION_GROUP_SERIALCODE,
} from 'graphql/queries/permissionGroup.query';
import DateTimeRangePicker from 'components/DateTimeRangePicker';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  onFilterChange?: (value: DynamicObj) => void;
};

const FilterItems = ({ filters, onRawFilterChange: handleChange }: Props) => (
  <>
    <SelectFilterField
      testId="permission-group-name-filter"
      label="Permission Group Name"
      query={GET_PERMISSION_GROUP_NAMES}
      queryConnection="permissionGroups"
      filterFieldName="name"
      filters={filters}
      onChange={(e) =>
        handleChange({
          name: 'name',
          value: e?.length ? { in: e } : null,
        })
      }
    />

    <SelectFilterField
      testId="permission-group-serial-code-filter"
      label="Serial Code"
      query={GET_PERMISSION_GROUP_SERIALCODE}
      queryConnection="permissionGroups"
      filterFieldName="serialCode"
      filters={filters}
      onChange={(e) =>
        handleChange({
          name: 'serialCode',
          value: e?.length ? { in: e } : null,
        })
      }
    />

    <SelectFilterField
      testId="permission-group-permissions-filter"
      label="Permissions"
      query={GET_PERMISSION_GROUP_PERMISSIONS}
      queryConnection="permissionGroups"
      filterFieldName="permissions"
      filters={filters}
      onChange={(e) =>
        handleChange({
          name: 'permissions',
          value: e?.length ? { in: e } : null,
        })
      }
      allowFilterOption
      manipulateOptionFunction={getParentPermission}
      partialSupported={false}
    />

    <DateTimeRangePicker
      testId="permission-group-date-time-updated-filter"
      label="date-time-updated"
      value={
        filters.dateTimeUpdated
          ? [filters.dateTimeUpdated.gte, filters.dateTimeUpdated.lte]
          : null
      }
      onChange={(e) =>
        handleChange({
          name: 'dateTimeUpdated',
          value: e?.length ? { gte: e[0], lte: e[1] } : null,
        })
      }
    />
  </>
);

export default FilterItems;
