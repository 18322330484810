import { defineMessages } from 'react-intl';

const messages = defineMessages({
  serialcode: {
    id: 'serial-code.text',
    defaultMessage: 'Serial code',
  },
  datetimeupdated: {
    id: 'date-time-updated.text',
    defaultMessage: 'Date/Time updated',
  },
  name: {
    id: 'name.text',
    defaultMessage: 'Name',
  },
  permission: {
    id: 'permission.text',
    defaultMessage: 'Permission',
  },
  permissions: {
    id: 'permissions.text',
    defaultMessage: 'Permissions',
  },
  action: {
    id: 'action.text',
    defaultMessage: 'Action',
  },
  excel: {
    id: 'export-excel.text',
    defaultMessage: 'Export excel',
  },
  NEW_PERMISSION_GROUP: {
    id: 'NEW_PERMISSION_GROUP',
    defaultMessage: 'New permission group',
  },
  addnewtitle: {
    id: 'permission-groups.add-new-permission-group.text',
    defaultMessage: 'Add New Permission Group',
  },
  cancel: {
    id: 'cancel.text',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'confirm.text',
    defaultMessage: 'Confirm',
  },
  edit: {
    id: 'edit.text',
    defaultMessage: 'Edit',
  },
  save: {
    id: 'save.text',
    defaultMessage: 'Save',
  },
  duplicate: {
    id: 'duplicate.text',
    defaultMessage: 'Duplicate',
  },
  delete: {
    id: 'delete.text',
    defaultMessage: 'Delete',
  },
  permissiongroupname: {
    id: 'permission-group-name.text',
    defaultMessage: 'Permission Group Name',
  },
  selectonepermission: {
    id: 'permission-group.select-at-least-one-permission.text',
    defaultMessage: 'Please select at least 1 permission.',
  },
  CREATED_PERMISSION_GROUP: {
    id: 'permission-group.created-successfully.text',
    defaultMessage: 'The permission group has been created successfully.',
  },
  UPDATED_PERMISSION_GROUP: {
    id: 'permission-group.updated-successfully.text',
    defaultMessage: 'The permission group has been updated successfully.',
  },
  PERMISSION_GROUP_DUPLICATE_SUCCESS: {
    id: 'PERMISSION_GROUP_DUPLICATE_SUCCESS',
    defaultMessage: 'Permission group duplicated.',
  },
  PERMISSION_GROUP_DUPLICATE_ERROR: {
    id: 'PERMISSION_GROUP_DUPLICATE_ERROR',
    defaultMessage: 'Failed to duplicate permission group.',
  },
  PERMISSION_GROUP_DELETE_SUCCESS: {
    id: 'PERMISSION_GROUP_DELETE_SUCCESS',
    defaultMessage: 'Permission group deleted.',
  },
  PERMISSION_GROUP_DELETE_ERROR: {
    id: 'PERMISSION_GROUP_DELETE_ERROR',
    defaultMessage: 'Failed to delete permission group.',
  },
  PERMISSION_GROUP_LOAD_ERROR: {
    id: 'PERMISSION_GROUP_LOAD_ERROR',
    defaultMessage: 'Failed to load permission group.',
  },
});

export const tabsMsgs = defineMessages({
  ALL_DEPOSIT: {
    id: 'permission-group.deposit.text',
    defaultMessage: 'Deposit',
  },
  ALL_WITHDRAWAL: {
    id: 'permission-group.withdrawal.text',
    defaultMessage: 'Withdrawal',
  },
  ALL_MEMBER: {
    id: 'permission-group.member.text',
    defaultMessage: 'Member',
  },
  ALL_OPERATORS: {
    id: 'permission-group.operator.text',
    defaultMessage: 'Operator',
  },
  ALL_PROMOS: {
    id: 'permission-group.promo.text',
    defaultMessage: 'Promo',
  },
  ALL_REPORTS: {
    id: 'permission-group.report.text',
    defaultMessage: 'Report',
  },
});

export const tabsMsgsNext = defineMessages({
  ALL_DASHBOARDS: {
    id: 'permission-group.dashboard.text',
    defaultMessage: 'Dashboard',
  },
  ALL_OPERATORS: {
    id: 'permission-group.operators.text',
    defaultMessage: 'Operators',
  },
  ALL_MEMBERS: {
    id: 'permission-group.members.text',
    defaultMessage: 'Members',
  },
  ALL_AFFILIATES: {
    id: 'permission-group.affiliates.text',
    defaultMessage: 'Affiliates',
  },
  ALL_DEPOSITS: {
    id: 'permission-group.deposits.text',
    defaultMessage: 'Deposits',
  },
  ALL_WITHDRAWALS: {
    id: 'permission-group.withdrawals.text',
    defaultMessage: 'Withdrawals',
  },
  ALL_PROMOS: {
    id: 'permission-group.promo.text',
    defaultMessage: 'Promo',
  },
  ALL_VIP: {
    id: 'permission-group.vip.text',
    defaultMessage: 'VIP',
  },
  ALL_REBATES: {
    id: 'permission-group.rebates.text',
    defaultMessage: 'Rebates',
  },
  ALL_REPORTS: {
    id: 'permission-group.reports.text',
    defaultMessage: 'Reports',
  },
  ALL_SYSTEM_MANAGEMENT: {
    id: 'permission-group.system-management.text',
    defaultMessage: 'System Management',
  },
  ALL_SYSTEM_MESSAGES: {
    id: 'permission-group.system-messages.text',
    defaultMessage: 'System Messages',
  },
});

export default messages;
