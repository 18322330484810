// tslint-disable no-use-before-define
import React, { useCallback, useEffect, useReducer } from 'react';
import { message } from 'antd';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import Drawer from 'SuperAdminMain/shared/components/Drawer/Drawer';
import removeNull from 'SuperAdminMain/utils/removeNull';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import { VendorGroup } from 'types/graphqlTypes';
import messages from 'messages';
import { GraphQLError } from 'graphql';
import { NewClientAccount } from '../NewClientAccount';
import { PublishAccount } from '../PublishAccount';
import {
  actions,
  INewClientDrawerContainer,
  initState,
  messagesLocal,
  reducer,
  VENDOR_GROUPS,
} from './utils';
import { CREATE_ADMIN, UPDATE_ADMIN } from '../utils';
import useIsNext from '../../../../../hooks/useIsNext';
import { FeatureFlagsSA } from '../../../../constants/featureflags';

export const NewClientDrawerContainer = ({
  drawerState,
  closeDrawer,
  existingValues,
  refetchFunction,
}: INewClientDrawerContainer) => {
  const [
    {
      newAccountState,
      publishingState,
      newAccountRefresh,
      vendorGroups,
      valuesToSubmit,
      boolExistingValue,
      submitProgress,
    },
    dispatch,
  ] = useReducer(reducer, initState);
  const [createAdminNext] = useMutation(CREATE_ADMIN);
  const [updateAdminNext] = useMutation(UPDATE_ADMIN);
  const nextVendorGroup = useIsNext(FeatureFlagsSA.nextVendorGroupManagement);

  const translate = useTranslate();
  const client = useApolloClient();

  const refreshClientForm = () => {
    dispatch([actions.SET_NEW_ACCOUNT_REFRESH, false]);
    setTimeout(() => {
      dispatch([actions.SET_NEW_ACCOUNT_REFRESH, true]);
    });
  };

  const onCatch = (err: { graphQLErrors: GraphQLError[] }) => {
    const error = err.graphQLErrors[0];
    message.error(
      error.message ??
        translate(messages['error-on-updating-client-account.text'])
    );
    dispatch([actions.SET_SUBMIT_PROGRESS, false]);
  };

  const onCompleteFns = () => {
    refetchFunction();
    dispatch([actions.SET_PUBLISHING, false]);
    dispatch([actions.SET_SUBMIT_PROGRESS, false]);
    closeDrawer();
    dispatch([actions.SET_NEW_ACCOUNT, true]);
    refreshClientForm();
  };
  const onCompleteClientAccount = async (statusData: string) => {
    dispatch([actions.SET_SUBMIT_PROGRESS, true]);
    if (boolExistingValue) {
      const {
        id,
        username,
        __typename,
        adminCode,
        password,
        status,
        currency,
        multiCurrencyEnabled,
        ...inputToSubmit
      } = valuesToSubmit;

      const cleanInput = Object.keys(inputToSubmit).reduce((acc, cur) => {
        acc[cur] = inputToSubmit[cur] || undefined;
        return acc;
      }, {});

      await updateAdminNext({
        variables: {
          input: cleanInput,
          id,
        },
      })
        .then(onCompleteFns)
        .catch(onCatch);
    } else {
      const values = { ...valuesToSubmit, status: statusData };
      await createAdminNext({
        variables: {
          input: removeNull(values, true),
        },
      })
        .then(onCompleteFns)
        .catch(onCatch);
    }
  };

  const fetchVendorGroups = useCallback(async () => {
    if (!nextVendorGroup) {
      return;
    }
    const resp = await client.query({
      query: VENDOR_GROUPS,
      variables: {
        filter: {
          status: { eq: 'ACTIVE' },
        },
      },
      fetchPolicy: 'network-only',
    });
    const vendorGroupsEdges = coercedGet(resp, 'data.vendorGroups.edges', []);
    const vendorGroupsNode = vendorGroupsEdges
      .map((vendor: { node: VendorGroup }) => vendor.node)
      // Currently Filter on VENDOR_GROUPS isn't working
      // Once it's working, delete this filter function and remove status field
      .filter((x: VendorGroup) => x.status === 'ACTIVE');
    dispatch([actions.SET_VENDOR_GROUPS, vendorGroupsNode]);
  }, [client, nextVendorGroup]);

  useEffect(() => {
    if (drawerState) {
      fetchVendorGroups();
    }
  }, [client, drawerState, fetchVendorGroups]);

  return (
    <Drawer open={drawerState} onClose={closeDrawer}>
      <Drawer.Header
        title={translate(
          messagesLocal[
            existingValues
              ? 'edit-client-account.text'
              : 'create-new-client-account.text'
          ]
        )}
      >
        <></>
      </Drawer.Header>
      <span
        className={!newAccountState ? 'position-absolute' : ''}
        style={{ top: !newAccountState ? '-99999px' : 'unset' }}
      >
        {newAccountRefresh && (
          <NewClientAccount
            vendorGroups={vendorGroups}
            existingValues={existingValues}
            onClose={() => {
              closeDrawer();
              refreshClientForm();
            }}
            onNext={(values: object, hasExistingValue) => {
              dispatch([actions.SET_VALUES_TO_SUBMIT, values]);
              dispatch([actions.SET_BOOL_EXISTING_VALUE, hasExistingValue]);
              dispatch([actions.SET_NEW_ACCOUNT, false]);
              dispatch([actions.SET_PUBLISHING, true]);
            }}
          />
        )}
      </span>
      {publishingState && (
        <PublishAccount
          submitting={submitProgress}
          existingStatus={valuesToSubmit.status}
          onPrevious={() => {
            dispatch([actions.SET_PUBLISHING, false]);
            dispatch([actions.SET_NEW_ACCOUNT, true]);
          }}
          onComplete={async (statusData) => {
            await onCompleteClientAccount(statusData);
          }}
        />
      )}
    </Drawer>
  );
};
