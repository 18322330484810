import { Select } from 'antd';
import React from 'react';
import useTranslate from 'utils/useTranslate';
import getSortedTimezones from 'utils/getSortedTimezones';
import messages from 'pages/components/SiteConfigurations/messages';
import { Locale, useAccount } from 'store/accountState';

const { Option } = Select;

type Props = {
  formik: any;
};

function TimezoneSelect(props: Props) {
  const { formik } = props;
  const { values, setFieldValue } = formik;
  const translate = useTranslate();
  const {
    account: { locale },
  } = useAccount();
  return (
    <Select
      defaultValue={values.timezone}
      showSearch
      placeholder={translate(messages.timezone)}
      onChange={(timezone: any) => setFieldValue('timezone', timezone)}
      value={values.timezone}
    >
      {getSortedTimezones(false, locale as Locale).map((zone) => (
        <Option value={zone.name} key={zone.id}>
          {zone.label}
        </Option>
      ))}
    </Select>
  );
}

export default TimezoneSelect;
