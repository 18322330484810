/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import SpecificVendorTable from './components/SpecificVendorTable';

function SpecificVendorModal(props: Record<string, any>) {
  const { toggleModal, nodeData } = props;

  return (
    <Modal
      visible
      maskClosable={false}
      closable={false}
      width={416}
      title="Vendor Name Here"
      okText={
        <FormattedMessage id="common.submit.text" defaultMessage="Submit" />
      }
      cancelText={
        <FormattedMessage id="common.cancel.text" defaultMessage="Cancel" />
      }
      // cancelButtonProps={{ disabled: loading }}
      // okButtonProps={{ loading }}
      onCancel={toggleModal}
      // onOk={handler}
    >
      <div className="ant-modal-confirm-body">
        <div className="ant-modal-body">
          <SpecificVendorTable nodeData={nodeData} />
        </div>
      </div>
    </Modal>
  );
}

export default SpecificVendorModal;
