import React from 'react';
import { Modal, Button, Input, Form, message } from 'antd';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

const UPDATE_OPERATOR_PASSWORD = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input)
  }
`;

type Props = {
  visible: boolean;
  onSuccess: () => void;
};

const validationSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required('Current password is required')
    .min(6, 'Minimum of 6 characters'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .max(256, 'Password length should not exceed 256 characters')
    .test(
      'password',
      'Password must contain at least three of the following: lowercase characters, uppercase characters, numbers, symbols',
      (value) => {
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /\d/;
        // eslint-disable-next-line
        const symbolRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        let count = 0;

        if (lowercaseRegex.test(value)) {
          count += 1;
        }

        if (uppercaseRegex.test(value)) {
          count += 1;
        }

        if (numberRegex.test(value)) {
          count += 1;
        }

        if (symbolRegex.test(value)) {
          count += 1;
        }

        return count >= 3;
      }
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords does not match'),
});

const ForceUpdatePassword = ({ visible, onSuccess }: Props) => {
  const [updateOperatorPassword, { loading }] = useMutation(
    UPDATE_OPERATOR_PASSWORD
  );

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    mode: 'all',
  });

  const history = useHistory();

  const onSubmit = (values: Record<string, any>) => {
    const updateOperatorPasswordInput = {
      input: {
        oldPassword: values.currentPassword,
        newPassword: values.password,
      },
    };

    updateOperatorPassword({
      variables: updateOperatorPasswordInput,
    })
      .then(() => {
        message.success('Password Updated');
        onSuccess();
        setTimeout(() => history.go(0), 500);
      })
      .catch(() => {});
  };

  return (
    <Modal
      closable={false}
      centered
      title="Change Password"
      visible={visible}
      footer={null}
      width={500}
    >
      <>
        <div>
          <Form layout="vertical">
            <Form.Item
              label="Current Password"
              validateStatus={errors?.currentPassword && 'error'}
              help={errors?.currentPassword?.message}
            >
              <Controller
                as={Input.Password}
                name="currentPassword"
                control={control}
                placeholder="Enter new password"
              />
            </Form.Item>

            <Form.Item
              label="New Password"
              validateStatus={
                (errors?.password ||
                  errors?.confirmPassword?.type === 'oneOf') &&
                'error'
              }
              help={
                (errors?.password && errors?.password?.message) ||
                (errors?.confirmPassword?.type === 'oneOf' &&
                  errors?.confirmPassword?.message)
              }
            >
              <Controller
                as={Input.Password}
                name="password"
                control={control}
                placeholder="Enter new password"
              />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              className="mt-3"
              validateStatus={errors?.confirmPassword && 'error'}
              help={errors?.confirmPassword && errors?.confirmPassword?.message}
            >
              <Controller
                as={Input.Password}
                name="confirmPassword"
                control={control}
                placeholder="Confirm new password"
              />
            </Form.Item>
          </Form>

          <Button
            className="mt-3"
            block
            type="primary"
            onClick={handleSubmit(onSubmit)}
            loading={loading}
          >
            Update Password
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default ForceUpdatePassword;
