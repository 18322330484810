import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Typography, Button, Tooltip, Spin, message } from 'antd';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { StyledDeleteIcon, StyledInput } from './styles';
import { GET_GEOLOCATION_CONFIG } from './queries';
import { UPDATE_GEOLOCATION_SETTINGS } from './mutation';
import LocationSelect from './components/LocationSelect';

const { Title, Text } = Typography;

type FormValues = {
  geolocationSettings: {
    location: {
      id: string | null;
      displayName: string | null;
    };
    redirectUrl: string | null;
  }[];
};

declare module 'yup' {
  // tslint:disable-next-line
  interface ArraySchema<T> {
    unique(mapper: (a: T) => T, message?: string): ArraySchema<T>;
  }
}

// prettier-ignore
yup.addMethod(yup.array, 'unique', function (
  mapper = (a: Record<string, any>) => a
) {
  return this.test({
    name: 'unique',
    message: 'Country already selected',
    test: (list) => list.length === new Set(list.map(mapper)).size,
  });
});

const geolocSchema = yup.object({
  geolocationSettings: yup
    .array()
    .of(
      yup.object().shape({
        location: yup.object().shape({
          id: yup
            .string()
            .required('Country is Required.')
            .nullable(),
        }),
        redirectUrl: yup
          .string()
          .required('Redirect URL is required.')
          .url('Invalid URL format, example: https://www.example.com')
          .nullable(),
      })
    )
    .unique((a: Record<string, any>) => a.location.id),
});

const GeoLocation = () => {
  const [formLength, setFormLength] = useState(0);
  const [actionBtn, setActionBtn] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);

  const defaultValue = {
    geolocationSettings: [
      {
        location: {
          id: null,
          displayName: null,
        },
        redirectUrl: null,
      },
    ],
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(geolocSchema),
    mode: 'all',
    defaultValues: defaultValue,
    shouldUnregister: false,
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'geolocationSettings',
  });

  const [loadGeoConfig, { loading: geolocationLoading }] = useLazyQuery(
    GET_GEOLOCATION_CONFIG,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const { config } = data || {};
        const geolocConfigLength = config?.geolocationSettings?.length;
        if (geolocConfigLength > 0) {
          reset(config);
        }

        setFormLength(geolocConfigLength);
      },
    }
  );

  const [updateGeolocSettings, { loading: mutationLoading }] = useMutation(
    UPDATE_GEOLOCATION_SETTINGS
  );

  const handleCancel = () => {
    reset();
  };

  const onSubmit = (values: FormValues) => {
    const geolocValues = values?.geolocationSettings?.map((value) => ({
      location: value.location.id,
      redirectUrl: value.redirectUrl,
    }));

    setSubmitLoader(true);

    updateGeolocSettings({
      variables: {
        input: {
          geolocationSettings: geolocValues || [],
        },
      },
    }).then(() => {
      message.success('Geolocation Settings Updated');
      loadGeoConfig();

      setSubmitLoader(false);
      if (geolocValues.length === 0) {
        reset(defaultValue);

        setTimeout(() => {
          handleCancel();
        }, 1000);
      }
    });
  };

  useEffect(() => {
    loadGeoConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formLength !== fields.length) {
      setActionBtn(false);
    } else {
      setActionBtn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <>
      <div className="w-50">
        <Title level={5}>Geo Location</Title>
        {!geolocationLoading || !mutationLoading ? (
          <>
            <div className="d-flex justify-content-end">
              <Button
                className="mb-3"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() =>
                  append({
                    location: {
                      id: null,
                      displayName: null,
                    },
                    redirectUrl: null,
                  })
                }
              >
                Add More
              </Button>
            </div>
            <form id="geoloc-form" onSubmit={handleSubmit(onSubmit)}>
              {fields.map((field, index) => (
                <div
                  key={field.id}
                  className="d-flex align-items-start justify-content-between mb-2"
                >
                  <div key={field.id} className="w-25 mr-2">
                    <Controller
                      control={control}
                      name={`geolocationSettings[${index}].location.id`}
                      defaultValue={field.location.id}
                      render={({ onChange, value }) => (
                        <LocationSelect onChange={onChange} value={value} />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name={`geolocationSettings[${index}].location.id`}
                      render={({ message: errMessage }) => (
                        <Text type="danger">{errMessage}</Text>
                      )}
                    />
                  </div>
                  <div key={field.id} className="w-75 d-flex flex-column">
                    <div className="d-flex align-items-center justify-content-between">
                      <Controller
                        className="w-100"
                        as={StyledInput}
                        name={`geolocationSettings[${index}].redirectUrl`}
                        control={control}
                        placeholder="Redirect URL"
                        defaultValue={field.redirectUrl}
                      />
                      <Tooltip title="Delete">
                        <StyledDeleteIcon onClick={() => remove(index)} />
                      </Tooltip>
                    </div>
                    <ErrorMessage
                      errors={errors}
                      name={`geolocationSettings[${index}].redirectUrl`}
                      render={({ message: errMessage }) => (
                        <Text type="danger">{errMessage}</Text>
                      )}
                    />
                  </div>
                </div>
              ))}
            </form>
            <div className="d-flex justify-content-end mt-3">
              <Button
                type="default"
                onClick={handleCancel}
                className="mr-1"
                disabled={fields.length !== 0 && actionBtn}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                form="geoloc-form"
                loading={submitLoader}
                disabled={!isValid}
              >
                Submit
              </Button>
            </div>
          </>
        ) : (
          <Spin size="small" />
        )}
      </div>
    </>
  );
};

export default GeoLocation;
