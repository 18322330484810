import React, { useState, useEffect } from 'react';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import coercedGet from 'utils/coercedGet';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { messages } from 'SuperAdminMain/components/AccountManagementNew/utils';
import { StyledLabel, StyledSpan, StyledSelect } from '../utils';

const ADMINS_VENDOR = gql`
  query ADMINS_VENDOR($first: Int, $after: Binary, $filter: AdminsFilterInput) {
    admins(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          vendorGroup {
            id
            name
          }
        }
      }
    }
  }
`;

export const VendorGroupFilter: React.FC = () => {
  const { filters, setFilters } = useFilterValues();
  const [input, setInput] = useState('');
  const debouncedInput = useDebounce(input, 500);
  const translate = useTranslate();

  const [loadAdmins, { data, loading, called }] = useLazyQuery(ADMINS_VENDOR, {
    variables: {
      first: 10,
      // filter: {
      //   adminCode: {
      //     contains: debouncedInput,
      //   },
      // },
    },
  });

  if (!called) loadAdmins();

  const edges = coercedGet(data, 'admins.edges', []);
  const admins = edges.filter(({ node }: any) => node?.name);

  const handleChange = (e: Array<string>) => {
    if (e.length) {
      setFilters((prev: any) => ({
        ...prev,
        vendorGroup: { in: e },
      }));
    } else {
      setFilters((prev: any) => ({
        ...prev,
        vendorGroup: null,
      }));
    }
  };

  useEffect(() => {
    loadAdmins();
  }, [debouncedInput, loadAdmins]);

  return (
    <div className="filter">
      <div className="label-clear">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="vendor-group.text"
              defaultMessage="Vendor Group"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        mode="multiple"
        value={filters.vendorGroup ? filters.vendorGroup.in : []}
        placeholder={translate(messages['please-enter.text'])}
        loading={loading}
        onChange={handleChange}
        onFocus={() => setInput('')}
        onBlur={() => setInput('')}
        onSearch={(text: any) => setInput(text)}
        filterOption={false}
      >
        {admins.map((admin: any) => (
          <Select.Option
            key={admin.id}
            value={admin.vendorGroup?.name}
            label={admin.vendorGroup?.name}
          >
            {admin.vendorGroup?.name}
          </Select.Option>
        ))}
      </StyledSelect>
    </div>
  );
};
