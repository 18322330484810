import React, { memo, useState, useCallback } from 'react';
import { flatten, uniqBy, isEmpty } from 'lodash';
import esGet from 'utils/esGet';
import { Select as AntdSelect, Tag } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { truncateProgramName } from 'utils/truncateProgramName';
import coercedGet from 'utils/coercedGet';
import { SelectProps } from 'interfaces/select.interface';
import {
  MemberLoyaltyLevelsConnectionEdge,
  MemberLoyaltyProgrammesConnectionEdge,
} from 'types/graphqlTypes';
import { MEMBER_LOYALTY_LEVELS } from './queries';
import { StyledCustomClear } from './styles';

const Select: any = AntdSelect;
const { Option } = Select;

const MemberLoyaltyLevelsSelect = ({
  onChange,
  value,
  placeHolder,
  disabled,
  label,
  multiple,
  style,
  className,
  isFilter,
  forRebates,
  topRightClear,
  selectClasses,
  stat,
  otherFilters = {},
  ...restProps
}: SelectProps) => {
  const [filter, setFilter] = useState({
    name: {
      contains: '',
    },
    ...(stat?.length && {
      status: {
        eq: stat,
      },
    }),
    ...(Object.keys(otherFilters).length && otherFilters),
  });
  const [opts, setOpts] = useState<any>({});

  const { loading } = useQuery(MEMBER_LOYALTY_LEVELS, {
    variables: {
      first: 10,
      filterProgrammes: filter,
      filterLevels: filter,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const memberLoyaltyLevels = coercedGet(
        data.memberLoyaltyLevels,
        'edges',
        []
      ).map((edge: MemberLoyaltyLevelsConnectionEdge) => edge.node);
      const memberLoyaltyProgrammes = coercedGet(
        data.memberLoyaltyProgrammes,
        'edges',
        []
      ).map((edge: MemberLoyaltyProgrammesConnectionEdge) => edge.node);
      const memberLoyaltyToBeMerged = flatten(
        memberLoyaltyProgrammes.map(
          (memberLoyaltyProgram: Record<string, any>) =>
            memberLoyaltyProgram?.levels.map(
              (level: { id: string; name: string; color: string }) => ({
                id: level.id,
                name: level.name,
                color: level.color,
                programme: {
                  id: memberLoyaltyProgram.id,
                  name: memberLoyaltyProgram.name,
                },
              })
            )
        )
      );
      const combinedMemberLoyalty = memberLoyaltyToBeMerged.length
        ? uniqBy(
            [...memberLoyaltyToBeMerged, ...memberLoyaltyLevels],
            'id'
          ).map((level) => {
            const programmeName = truncateProgramName(level.programme.name)
              .text;
            const shortName = `${programmeName} - ${level.name}`;

            const fullName = `${level.programme.name || ''} - ${level.name ||
              ''}`;

            return { ...level, fullName, shortName };
          })
        : [];

      setOpts({
        memberLoyaltyLevels: combinedMemberLoyalty.reverse(),
      });
    },
  });

  const hasMemberLoyalty = !isEmpty(esGet(opts?.memberLoyaltyLevels, []));

  const options =
    hasMemberLoyalty &&
    opts.memberLoyaltyLevels.map(
      (memberLoyaltyLevel: {
        id: string;
        fullName: string;
        shortName: string;
      }) => (
        <Option
          key={memberLoyaltyLevel.id}
          title={
            forRebates ? memberLoyaltyLevel.fullName : memberLoyaltyLevel.id
          }
          value={memberLoyaltyLevel.id}
        >
          {memberLoyaltyLevel.shortName}
        </Option>
      )
    );

  const onChangeInput = useCallback(
    (e) => {
      onChange(e);
      if (!e.length) {
        setFilter((filters) => ({
          ...filters,
          name: {
            contains: '',
          },
        }));
      }
    },
    [onChange, setFilter]
  );

  const onBlur = useCallback(() => {
    setFilter((filters) => ({
      ...filters,
      name: {
        contains: '',
      },
    }));
  }, [setFilter]);

  const filterOption = (inputValue: string, option: any) =>
    option.props.title.toLowerCase().includes(inputValue.toLowerCase());

  const tagRender = (props: { value: string; onClose: () => void }) => {
    // eslint-disable-next-line react/prop-types
    const { value: id, onClose } = props;
    const item = opts.memberLoyaltyLevels?.find(
      (opt: { id: string }) => opt.id === id
    );

    if (!item) return <></>;

    return (
      <Tag
        closable
        onClose={onClose}
        color={item.color}
        style={{ margin: 3, borderRadius: 11 }}
      >
        {item.shortName}
      </Tag>
    );
  };

  return (
    <div className={className}>
      <div className="d-flex justify-content-space-between">
        {label && <div className="font-weight-bold fs-10">{label}</div>}
        {topRightClear && (
          <div>
            <StyledCustomClear onClick={() => onChange([])}>
              <FormattedMessage id="clear.text" defaultMessage="Clear" />
            </StyledCustomClear>
          </div>
        )}
      </div>
      <Select
        className={selectClasses}
        mode={multiple ? 'multiple' : undefined}
        showSearch
        placeholder={placeHolder || ''}
        onChange={onChangeInput}
        onBlur={onBlur}
        optionFilterProp="title"
        filterOption={filterOption}
        loading={loading}
        disabled={disabled}
        style={style}
        isFilter={isFilter}
        allowClear={!topRightClear}
        value={value || undefined}
        tagRender={tagRender}
        {...restProps}
      >
        {!multiple && (
          <Select.Option disabled value={null}>
            <span className="text-gray">
              <FormattedMessage
                id="select-a-qualifying-member-loyalty-level.text"
                defaultMessage="Select a qualifying member loyalty level"
              />
            </span>
          </Select.Option>
        )}
        {options}
      </Select>
    </div>
  );
};

export default memo(MemberLoyaltyLevelsSelect);
