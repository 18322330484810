import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';

import { Button, Popover } from 'antd';
import MemberLevelButton from 'components/MemberLevelButton';
import { MemberLevelList } from './components/MemberLevelList/MemberLevelList';

const MemberLevelDropdownSelect = ({
  value = [],
  onChange,
}: {
  onChange: (e: Record<string, any>) => void;
  value: Record<string, any>[];
}) => {
  const [isShow, setShow] = useState(false);
  const handleAddMemberLevel = (e: Record<string, any>) => {
    onChange([...value, e]);
  };

  const handleRemoveMemberLevel = (e: string) => {
    onChange(value.filter((item) => item.id !== e));
  };

  const handleShow = () => setShow(!isShow);

  const handleVisibleChange = () => {
    handleShow();
  };
  return (
    <>
      <div>
        {value.map((item, index) => (
          <div className="mr-1 mb-1 float-left" key={index}>
            <MemberLevelButton
              memberLevelData={item}
              onClick={() => handleRemoveMemberLevel(item.id)}
            />
          </div>
        ))}
        <div className="clearfix" />
      </div>
      <Popover
        placement="rightTop"
        visible={isShow && !value.length}
        content={<MemberLevelList onSelect={handleAddMemberLevel} />}
        onVisibleChange={handleVisibleChange}
        trigger="click"
      >
        <Button
          size="small"
          disabled={Boolean(value.length)}
          onClick={handleShow}
          role="button"
        >
          <PlusOutlined />
          <FormattedMessage
            id="new-member-marker.text"
            defaultMessage="New marker"
          />
        </Button>
      </Popover>
    </>
  );
};

export default MemberLevelDropdownSelect;
