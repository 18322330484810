import React from 'react';
import { Card, Row, Col, Typography, Switch } from 'antd';
import styled from 'styled-components';
import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { GameTypeNames } from 'constants/gameTypes';
import { Locale } from 'constants/locale';
import messages from 'messages';
import coercedGet from 'utils/coercedGet';
import { customFormatMessage } from 'utils/customFormatMessage';
import { useAffiliateState } from '../../../../../context';
import {
  useCostSettingsContext,
  CostSettingsFilters,
  CostSettingsTypes,
} from '../context';

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #3bc98c;
  }
  flex: none;
`;

const StyledCard = styled(Card)<any>`
  width: 250px;

  .ant-card-body {
    min-height: 56px;
    padding-left: 12px;
    padding-right: 12px;
  }
  box-shadow: ${(props) =>
    props.focuseditem ? '0.5px 0.5px 12px 0px rgba(0, 0, 0, 0.25)' : ''};
  z-index: ${(props) => (props.focuseditem ? 15 : 1)};
`;

const isActive = (
  activeVendor: Record<string, any>,
  vendor: Record<string, any>,
  gameType: string
) => activeVendor.id === vendor && activeVendor.gameType === gameType;

const GameTypesByVendor = ({
  gameTypes,
  vendor,
  sortIndex,
  summary,
}: {
  gameTypes: any;
  vendor: Record<string, any>;
  sortIndex: number;
  summary: Record<string, any>;
}) => {
  const intl = useIntl();
  const [{ activeVendor }, dispatch] = useCostSettingsContext();

  return gameTypes.map((gameType: string, index: number) => {
    const vendorPlatformFees = coercedGet(summary, 'vendorPlatformFees', []);
    const selectedVendorGameType =
      vendorPlatformFees.find(
        (node: Record<string, any>) =>
          node.gameType === gameType && node.vendor.id === vendor
      ) || {};

    const checked =
      !!(
        coercedGet(selectedVendorGameType, 'minimumCharge', 0) ||
        coercedGet(selectedVendorGameType, 'maximumCharge', 0) ||
        coercedGet(selectedVendorGameType, 'chargeLevels', []).length
      ) || false;

    const activeVendorDetails = {
      id: vendor,
      gameType,
      minimumCharge: coercedGet(selectedVendorGameType, 'minimumCharge', 0),
      maximumCharge: coercedGet(selectedVendorGameType, 'maximumCharge', 0),
      chargeLevels: coercedGet(selectedVendorGameType, 'chargeLevels', []),
    };

    if (index === 0 && sortIndex === 0 && !activeVendor.id) {
      dispatch({
        type: CostSettingsTypes.SET_ACTIVE_VENDOR,
        payload: activeVendorDetails,
      });
    }
    const translate = (messageVal: any, opts = null) =>
      customFormatMessage(intl.formatMessage, messageVal, opts);
    return (
      <Row key={`${gameType}_${vendor}`}>
        <StyledCard
          focuseditem={isActive(activeVendor, vendor, gameType)}
          hoverable
          key={`${gameType}_${vendor}`}
          className={index % 2 && cx('bg-light')}
          onClick={() => {
            if (!isActive(activeVendor, vendor, gameType)) {
              dispatch({
                type: CostSettingsTypes.SET_ACTIVE_VENDOR,
                payload: activeVendorDetails,
              });
            }
          }}
        >
          <Row style={{ display: 'flex', justifyItems: 'space-between' }}>
            <Col span={20} style={{ padding: 0 }}>
              <div>
                <Typography.Text style={{ fontSize: 13 }}>
                  {translate(messages[`${gameType.toLowerCase()}.text`])}
                </Typography.Text>
              </div>
            </Col>
            <Col span={4} style={{ marginTop: '-4px' }}>
              <StyledSwitch
                className="my-auto"
                size="small"
                unCheckedChildren={
                  <FormattedMessage defaultMessage="Off" id="off.text" />
                }
                checkedChildren={
                  <FormattedMessage defaultMessage="On" id="on.text" />
                }
                checked={checked}
              />
            </Col>
          </Row>
        </StyledCard>
      </Row>
    );
  });
};

const VendorsByGameType = ({
  gameType,
  vendors,
  sortIndex,
  summary,
}: {
  gameType: any;
  vendors: Record<string, any>[];
  sortIndex: number;
  summary: Record<string, any>;
}): any => {
  const intl = useIntl();
  const [{ activeVendor }, dispatch] = useCostSettingsContext();

  return vendors
    .filter(({ node }) => node.gameTypes.includes(gameType))
    .map(({ node: vendor }, index) => {
      const vendorPlatformFees = coercedGet(summary, 'vendorPlatformFees', []);
      const selectedVendorGameType =
        vendorPlatformFees.find(
          (node: Record<string, any>) =>
            node.gameType === gameType && node.vendor.id === vendor.id
        ) || {};

      const checked =
        !!(
          coercedGet(selectedVendorGameType, 'minimumCharge', 0) ||
          coercedGet(selectedVendorGameType, 'maximumCharge', 0) ||
          coercedGet(selectedVendorGameType, 'chargeLevels', []).length
        ) || false;

      const activeVendorDetails = {
        id: vendor.id,
        gameType,
        minimumCharge: coercedGet(selectedVendorGameType, 'minimumCharge', 0),
        maximumCharge: coercedGet(selectedVendorGameType, 'maximumCharge', 0),
        chargeLevels: coercedGet(selectedVendorGameType, 'chargeLevels', []),
      };

      if (index === 0 && sortIndex === 0 && !activeVendor.id) {
        dispatch({
          type: CostSettingsTypes.SET_ACTIVE_VENDOR,
          payload: activeVendorDetails,
        });
      }

      return (
        <Row key={`${gameType}_${vendor.id}`}>
          <StyledCard
            hoverable
            focuseditem={isActive(activeVendor, vendor.id, gameType)}
            key={`${gameType}_${vendor.id}`}
            className={index % 2 && cx('bg-light')}
            onClick={() => {
              if (!isActive(activeVendor, vendor.id, gameType)) {
                dispatch({
                  type: CostSettingsTypes.SET_ACTIVE_VENDOR,
                  payload: activeVendorDetails,
                });
              }
            }}
          >
            <Row style={{ display: 'flex', justifyItems: 'space-between' }}>
              <Col span={20} style={{ padding: 0 }}>
                <div>
                  <Typography.Text style={{ fontSize: 13 }}>
                    {intl.locale === Locale.EN
                      ? `${vendor.nameEn || vendor.name}`
                      : `${vendor.nameZh || vendor.name}`}
                  </Typography.Text>
                </div>
              </Col>
              <Col span={4} style={{ marginTop: '-4px' }}>
                <StyledSwitch
                  size="small"
                  unCheckedChildren={
                    <FormattedMessage defaultMessage="Off" id="off.text" />
                  }
                  checkedChildren={
                    <FormattedMessage defaultMessage="On" id="on.text" />
                  }
                  checked={checked}
                />
              </Col>
            </Row>
          </StyledCard>
        </Row>
      );
    });
};

const SortByGameVendors = ({
  summary,
}: {
  summary: Record<string, any>;
}): any => {
  const [{ vendors }] = useAffiliateState() as any;
  const [{ subFilters, search, searchFilter }] = useCostSettingsContext();
  const intl = useIntl();
  const defaultItems = subFilters.length
    ? subFilters.map((item: Record<string, any>) =>
        vendors.find(
          ({ node }: { node: Record<string, any> }) => node.id === item.value
        )
      )
    : vendors;

  const onSearch = (value: string, defaults: any[]) => {
    if (!value.length) return defaults;

    switch (searchFilter) {
      case CostSettingsFilters.GAME_VENDOR:
        return defaults.filter(({ node }) =>
          node.name.toUpperCase().includes(value.toUpperCase())
        );
      case CostSettingsFilters.GAME_CATEGORY:
        return defaults.map((vendor) => ({
          ...vendor,
          node: {
            ...vendor.node,
            gameTypes: vendor.node.gameTypes.filter((gameType: any) =>
              gameType
                .toString()
                .toUpperCase()
                .startsWith(value.toUpperCase())
            ),
          },
        }));
      default:
        return defaults;
    }
  };

  const items = onSearch(search, defaultItems);

  return items.map(
    ({ node: { gameTypes, name, nameEn, nameZh, id } }, index) =>
      !!gameTypes.length && (
        <div key={index} style={{ padding: 0 }}>
          <Row className="mb-2">
            <Col span={19}>
              <Typography.Text strong>
                {intl.locale === Locale.EN
                  ? `${nameEn || name}`
                  : `${nameZh || name}`}
              </Typography.Text>
            </Col>
          </Row>
          <GameTypesByVendor
            gameTypes={gameTypes}
            vendor={id}
            sortIndex={index}
            summary={summary}
          />
        </div>
      )
  );
};

const SortByGameCategories = ({
  summary,
}: {
  summary: Record<string, any>;
}): any => {
  const [{ vendors }] = useAffiliateState() as any;
  const [{ subFilters, search, searchFilter }] = useCostSettingsContext();
  const intl = useIntl();

  const defaultItems = subFilters.length
    ? subFilters.map((item: Record<string, any>) => item.value)
    : Object.keys(GameTypeNames);

  const onSearch = (value: string, defaults: any[]) => {
    switch (searchFilter) {
      case CostSettingsFilters.GAME_VENDOR:
        return defaults;
      case CostSettingsFilters.GAME_CATEGORY:
        return defaults.filter((filter) =>
          filter.toUpperCase().startsWith(value.toUpperCase())
        );
      default:
        return defaults;
    }
  };

  const onVendorSearch = (value: string, defaults: any[]) => {
    if (!value.length) return defaults;

    return defaults.filter(({ node: { name } }) =>
      name.toUpperCase().includes(value.toUpperCase())
    );
  };

  const items = onSearch(search, defaultItems);
  const vendorItems = onVendorSearch(search, vendors);
  const translate = (messageVal: any, opts = null) =>
    customFormatMessage(intl.formatMessage, messageVal, opts);
  return items.map(
    (gameType, index) =>
      !!vendorItems.filter((vendor) =>
        vendor.node.gameTypes.includes(gameType)
      ) && (
        <div key={index} style={{ padding: 0 }}>
          <Row className="mb-2">
            <Col span={19}>
              <Typography.Text strong>
                {translate(messages[`${gameType.toLowerCase()}.text`])}
              </Typography.Text>
            </Col>
          </Row>
          <VendorsByGameType
            gameType={gameType}
            vendors={vendorItems}
            sortIndex={index}
            summary={summary}
          />
        </div>
      )
  );
};

function VendorContent({ summary }: { summary: Record<string, any> }) {
  const [{ sortFilter }] = useCostSettingsContext();
  return (
    <div style={{ display: 'flex' }}>
      {sortFilter === CostSettingsFilters.GAME_VENDOR && (
        <SortByGameVendors summary={summary} />
      )}
      {sortFilter === CostSettingsFilters.GAME_CATEGORY && (
        <SortByGameCategories summary={summary} />
      )}
    </div>
  );
}

export default VendorContent;
