import * as Yup from 'yup';
import { get, isEmpty } from 'lodash';
import { defineMessages } from 'react-intl';
import messages from 'messages';
import { CustomRegex } from '../../utils/regex';

const messagesLocal = defineMessages({
  'username-is-required.text': {
    id: 'username-is-required.text',
    defaultMessage: 'Username is requried',
  },
  'superadmin-username-error.text': {
    id: 'superadmin-username-error.text',
    defaultMessage:
      'Username must start in alphabet and must be between 4-23 characters',
  },
  'password-is-required.text': {
    id: 'password-is-required.text',
    defaultMessage: 'Password is required',
  },
  'superadmin-password-error.text': {
    id: 'superadmin-password-error.text',
    defaultMessage: 'Password must be between 6-32 characters',
  },
  'ip-address-validation.text': {
    id: 'ip-address-validation.text',
    defaultMessage: 'Must be IP address format',
  },
  'ip-address-already-exists-validation.text': {
    id: 'ip-address-already-exists-validation.text',
    defaultMessage: 'Ip Address already exists',
  },
});

export class OperatorValidation {
  static ipRegex = /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/;

  static username = (formatMessage: any) =>
    Yup.string()
      .required(formatMessage(messagesLocal['username-is-required.text']))
      .matches(
        CustomRegex.noStartingNumber(),
        formatMessage(messages.NO_STARTING_NUMBER_USERNAME)
      )
      .matches(
        CustomRegex.noCapitalLetters(),
        formatMessage(messages.NO_UPPERCASE_LETTERS)
      )
      .matches(
        CustomRegex.usernameLength(),
        formatMessage(messages.USERNAME_MIN_MAX_LENGTH)
      );

  static password = (formatMessage: any) =>
    Yup.string()
      .required(formatMessage(messagesLocal['password-is-required.text']))
      .matches(
        CustomRegex.password(),
        formatMessage(messages.PASSWORD_VALIDATION_FIELD)
      );

  static ipAddress = (
    formatMessage: any,
    preventDuplicateIp?: {
      ipAddresses: string[];
    }
  ) => {
    const ipAddresses = get(preventDuplicateIp, 'ipAddresses', []);

    if (!isEmpty(ipAddresses)) {
      return Yup.string()
        .matches(
          OperatorValidation.ipRegex,
          formatMessage(messagesLocal['ip-address-validation.text'])
        )
        .notOneOf(
          ipAddresses,
          formatMessage(
            messagesLocal['ip-address-already-exists-validation.text']
          )
        );
    }

    return Yup.string().matches(
      OperatorValidation.ipRegex,
      formatMessage({
        id: 'ip-address-validation.text',
        defaultMessage: 'Must be IP address format',
      })
    );
  };
}
