import styled from 'styled-components';
import { darken } from 'polished';
import validateHexColor from 'utils/validateHexColor';
import { Tag } from 'antd';

export const MemberLevelStyle = styled(Tag)`
  background-color: ${(props) => validateHexColor(props.color!)} !important;
  border-color: ${(props) =>
    darken(0.2, validateHexColor(props.color!))} !important;
  border-radius: 11px !important;
  color: #000 !important;

  .anticon-close {
    color: #000 !important;
  }
`;

export const MemberTagStyle = styled(Tag)`
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: #222222 !important;
  padding-right: ${(props) => props.closable && '20px !important'};
  position: relative !important;

  .anticon-close {
    color: #222222 !important;
    position: absolute !important;
    right: 4px !important;
    top: 4px !important;
  }
`;

export const MemberLoyaltyLevelStyle = styled(Tag)`
  background-color: ${(props) => validateHexColor(props.color!)} !important;
  border-color: ${(props) => validateHexColor(props.color!)} !important;
  border-radius: 11px !important;
  color: #fff !important;

  .anticon-close {
    color: #fff !important;
  }
`;

export default {};
