const colorBuilder = (
  borderColor: string,
  bgColor: string,
  boxColor: string
): Record<string, string> => ({
  borderColor,
  bgColor,
  boxColor,
});

export const memberLevelColors = [
  colorBuilder('#c4c4c4', '#f7f7f7', '#e5e5e5'),
  colorBuilder('#c4c4c4', '#e8e8e8', '#c4c4c4'),
  colorBuilder('#808080', '#cccccc', '#808080'),
  colorBuilder('#222222', '#b5b5b5', '#000000'),

  colorBuilder('#f9baab', '#fff6f4', '#f9baab'),
  colorBuilder('#e07861', '#ffddd6', '#e07861'),
  colorBuilder('#ce222c', '#ffa2a7', '#ce222c'),
  colorBuilder('#8d0600', '#f57069', '#8d0600'),

  colorBuilder('#f2c993', '#fff3e3', '#f9d3a2'),
  colorBuilder('#ecb772', '#ffdfb5', '#ecb772'),
  colorBuilder('#dd912f', '#f5bb70', '#dd902f'),
  colorBuilder('#a05400', '#ef9d43', '#a05300'),

  colorBuilder('#f4e143', '#fffed8', '#fef86a'),
  colorBuilder('#fae745', '#fff59d', '#f5e465'),
  colorBuilder('#d0c24b', '#f4e66c', '#d0c04b'),
  colorBuilder('#b29600', '#e0c21e', '#b29501'),

  colorBuilder('#bae188', '#f1ffdd', '#d7f3b0'),
  colorBuilder('#bae188', '#d2ff96', '#badf88'),
  colorBuilder('#8fcc40', '#a6e257', '#8fca40'),
  colorBuilder('#16a471', '#40d8a1', '#14a371'),

  colorBuilder('#97cbf5', '#f0f8ff', '#c8e5fb'),
  colorBuilder('#97cbf5', '#cce8ff', '#96ccf5'),
  colorBuilder('#72aaec', '#9fcbff', '#70abec'),
  colorBuilder('#228dff', '#7bbaff', '#1890ff'),

  colorBuilder('#b99ece', '#f7efff', '#e0ceef'),
  colorBuilder('#b99ece', '#ecd5ff', '#b99fce'),
  colorBuilder('#a978c6', '#da9bff', '#a97ac6'),
  colorBuilder('#863ae0', '#b880fa', '#8543e0'),

  colorBuilder('#fcb8df', '#ffeff8', '#f6d0e6'),
  colorBuilder('#e098c2', '#ffd5ed', '#e099c2'),
  colorBuilder('#c2699d', '#ff9dd5', '#c26a9d'),
  colorBuilder('#ff006c', '#ff72ae', '#ff006c'),

  colorBuilder('#00cc00', '#32cd32', '#00cc00'),
];
