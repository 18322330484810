import React from 'react';
import RefreshTable from 'pages/components/Reports/DailyOperatingReport/components/RefreshTable';
import TimezoneSelect from 'components/TimezoneSelect';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';

type Props = {
  refetchTable: () => void;
  filter: Record<string, any> | undefined;
};

const HeaderRight: React.FC<Props> = ({ refetchTable, filter }) => {
  const { role, permissions } = usePermissions();
  const translate = useTranslate();
  const { ALLOWED_CSV_DOWNLOAD_BUTTON } = collectPermissions(
    role,
    permissions,
    ['LIST', 'CSV_DOWNLOAD_BUTTON'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS_DAILY_OPERATING_REPORT
  );

  return (
    <>
      <TimezoneSelect />
      <RefreshTable
        onClick={() => {
          refetchTable();
        }}
      />
      <SpreadsheetDownload
        permission={ALLOWED_CSV_DOWNLOAD_BUTTON}
        iconOnly
        filter={filter}
        filename={translate(messages.DAILY_OPERATING_REPORT)}
        extension="csv"
      />
    </>
  );
};

export default HeaderRight;
