import styled from 'styled-components';

export const StyledTableContainer = styled.div`
  tr.drop-over-downward td {
    border-bottom: 2px inset #1890ff;
  }

  tr.drop-over-upward td {
    border-top: 2px inset #1890ff;
  }

  .button-group {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const StyledCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {};
