import { DownOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/react-hooks';
import {
  Button,
  Col,
  Divider,
  message as messageNotif,
  Modal,
  Row,
  Spin,
  Tag,
} from 'antd';
import { DATE_FORMAT } from 'constants/date';
import { DELETE_MESSAGE } from 'graphql/mutations/manualMessage.mutation';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { SharedStyledModal } from 'styles/SharedStyledModal';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { customFormatMessage } from '../../../../../../utils/customFormatMessage';
import { ISystemMembers, ISystemMessageNode } from '../../interfaces';
import { CreateNewMail } from '../CreateNewMail/CreateNewMail';
import { buildMessageType } from '../CreateNewMail/util';
import {
  memberLevelsFnTag,
  memberLoyaltyLevelsFnTag,
} from '../SystemMessageTable/components/MessageRowRenderer/MessageRowRenderer';

const { confirm } = Modal;

const messagesLocal = defineMessages({
  'system-message-deleted.text': {
    id: 'system-message-deleted.text',
    defaultMessage: 'Message has been deleted.',
  },
  'delete-system-message.title.text': {
    id: 'delete-system-message.title.text',
    defaultMessage: 'Delete system message',
  },
  'delete-system-message.content.text': {
    id: 'delete-system-message.content.text',
    defaultMessage: 'Do you want to delete this system message?',
  },
  'confirm.text': {
    id: 'confirm.text',
    defaultMessage: 'Confirm',
  },
  'cancel.text': {
    id: 'cancel.text',
    defaultMessage: 'Cancel',
  },
});

const MultTags = ({
  data,
  type,
}: {
  data: ISystemMessageNode;
  type: string;
}) => {
  const members = data[`${type}Members`];
  const memberLevels = data[`${type}MemberLevels`];
  const memberLoyaltyLevels = data[`${type}MemberLoyaltyLevels`];
  const wholeSiteMembers =
    isEmpty(members) && isEmpty(memberLevels) && isEmpty(memberLoyaltyLevels);

  return (
    <div>
      {members.map((member: ISystemMembers) => (
        <Tag className="mb-1">{member.username}</Tag>
      ))}
      {memberLevels.map(memberLevelsFnTag)}

      {memberLoyaltyLevels.map(memberLoyaltyLevelsFnTag)}

      {wholeSiteMembers &&
        (type === 'target' ? (
          <FormattedMessage
            id="whole-site-members.text"
            defaultMessage="Whole Site Members"
          />
        ) : (
          <FormattedMessage id="none.text" defaultMessage="None" />
        ))}
    </div>
  );
};

export const ViewMessage = ({
  message,
  onCancelFn,
  getMessages,
}: IViewMessage) => {
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [selectedMessageForEdit, setSelectedMessage] = useState<any>(null);
  const { formatMessage } = useIntl();
  const client = useApolloClient();
  const { context } = useOperatorHeader();
  if (!message) {
    return <></>;
  }
  const translate = (messageVal: any, opts = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  const onDeleteMessage = () => {
    setIsDeleting(true);
    client
      .mutate({
        mutation: DELETE_MESSAGE,
        variables: {
          id: message.id,
        },
        context,
      })
      .then(() => {
        messageNotif.success(
          translate(messagesLocal['system-message-deleted.text'])
        );
        getMessages();
        onCancelFn();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  function showDeleteConfirm() {
    confirm({
      title: translate(messagesLocal['delete-system-message.title.text']),
      content: translate(messagesLocal['delete-system-message.content.text']),
      okText: translate(messagesLocal['confirm.text']),
      okType: 'primary',
      cancelText: translate(messagesLocal['cancel.text']),
      onOk() {
        onDeleteMessage();
      },
    });
  }

  return (
    <>
      <SharedStyledModal
        visible={!!message}
        title={message.title}
        onCancel={onCancelFn}
        width={800}
        footer={null}
      >
        <Spin spinning={isDeleting}>
          <Row>
            <Col span={16}>
              <div className="p-3" style={{ whiteSpace: 'pre-wrap' }}>
                {message.content}
              </div>
            </Col>
            <Col span={8} style={{ borderLeft: '1px solid lightgray' }}>
              <div className="p-2">
                <div className="d-flex justify-content-space-between align-items-center">
                  <strong>
                    <FormattedMessage
                      id="qualifying-members.text"
                      defaultMessage="Qualifying Members"
                    />
                  </strong>
                  <DownOutlined />
                </div>

                <div className="pt-2">
                  <MultTags data={message} type={buildMessageType.target} />
                </div>
              </div>
              <Divider className="my-0" />
              <div className="p-2">
                <div className="d-flex justify-content-space-between align-items-center">
                  <strong>
                    <FormattedMessage
                      id="excluded-members.text"
                      defaultMessage="Excluded Members"
                    />
                  </strong>
                  <DownOutlined />
                </div>
                <div className="pt-2">
                  <MultTags data={message} type={buildMessageType.excluded} />
                </div>
              </div>

              <Divider className="my-0" />
              <div className="d-flex justify-content-space-between p-2 align-items-center">
                <strong>
                  <FormattedMessage
                    id="total-members.text"
                    defaultMessage="Total Members"
                  />
                </strong>
                {message.totalRecipients}
              </div>
              <Divider className="my-0" />
              <div className="d-flex justify-content-space-between p-2 align-items-center">
                <strong>
                  <FormattedMessage
                    id="read-members.text"
                    defaultMessage="Read Members"
                  />
                </strong>
                {message.totalReads}
              </div>

              <div className="d-flex justify-content-space-between p-2 align-items-center">
                <strong>
                  <FormattedMessage
                    id="created-by.text"
                    defaultMessage="Created by"
                  />
                </strong>
                {message.creator.username}
              </div>

              <div className="p-2 d-flex justify-content-between">
                <strong>
                  <FormattedMessage
                    id="sending-date.text"
                    defaultMessage="Sending Date"
                  />
                </strong>
                <div className="text-right">
                  {moment(message.dateTimeSubmitted).format(
                    `${DATE_FORMAT} (dd) hh:mm:ss`
                  )}
                </div>
              </div>
              <Divider className="my-0" />
              <div className="p-2 d-flex justify-content-between">
                <strong>
                  <FormattedMessage
                    id="last-edited-by.text"
                    defaultMessage="Last Edited by"
                  />
                </strong>
                <div>{message.creator.username}</div>
              </div>

              <div className="p-2 d-flex justify-content-between">
                <strong>
                  <FormattedMessage
                    id="last-edited.text"
                    defaultMessage="Last Edited"
                  />
                </strong>
                <div>
                  {moment(message.dateTimeUpdated).format(
                    `${DATE_FORMAT} (dd) hh:mm:ss`
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Divider className="my-0" />
          <div className="p-2 d-flex justify-content-space-between">
            <div className="d-flex">
              {!message.submitted && (
                <>
                  <Button className="mr-2" onClick={showDeleteConfirm}>
                    <FormattedMessage
                      id="delete.text"
                      defaultMessage="Delete"
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectedMessage(message);
                      // onCancelFn();
                    }}
                  >
                    <FormattedMessage id="edit.text" defaultMessage="Edit" />
                  </Button>
                </>
              )}
            </div>
            <div className="d-flex">
              <Button type="primary" onClick={onCancelFn}>
                <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
              </Button>
            </div>
          </div>
        </Spin>
      </SharedStyledModal>

      <CreateNewMail
        visible={!!selectedMessageForEdit}
        onCloseFn={() => {
          setSelectedMessage(false);
          onCancelFn();
        }}
        getMessages={getMessages}
        existingMessage={selectedMessageForEdit}
      />
    </>
  );
};

interface IViewMessage {
  message: ISystemMessageNode;
  onCancelFn: () => void;
  getMessages: any;
}
