import React from 'react';

export const BodyRow = (draggingIndex: number) => (
  props: Record<string, any>
) => {
  const {
    isOver,
    connectDragSource,
    connectDropTarget,
    moveRow,
    ...restProps
  } = props;

  const style = { ...restProps.style, cursor: 'move' };
  let { className } = restProps;
  if (isOver) {
    if (restProps.index > draggingIndex) {
      className += ' drop-over-downward';
    }
    if (restProps.index < draggingIndex) {
      className += ' drop-over-upward';
    }
  }
  return connectDragSource(
    connectDropTarget(
      <tr
        key={props['data-row-key']}
        {...restProps}
        className={className}
        style={style}
      />
    )
  );
};
