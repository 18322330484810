import React from 'react';
import { ReactComponent as IconAgent } from 'components/assets/icon-agent.svg';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { Deposit, Withdrawal } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';

const StyledContainer = styled.div`
  width: 17px;
  height: 17px;
  svg {
    width: 17px;
    height: 17px;
  }
`;

type Props = {
  data: Deposit | Withdrawal;
};

const AffiliateIndicator = React.memo(
  (data: Props): JSX.Element => {
    const affiliate = coercedGet(data.data, 'account.affiliate', null);

    if (!affiliate) {
      return <span>-</span>;
    }

    const { code } = affiliate;

    return (
      <StyledContainer>
        <Tooltip arrowPointAtCenter title={code}>
          <IconAgent data-testid="affiliate-indicator" />
        </Tooltip>
      </StyledContainer>
    );
  }
);

export default AffiliateIndicator;
