import gql from 'graphql-tag';

export const MEMBER_ACCOUNT = gql`
  query MemberAccount($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;
