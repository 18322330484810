import React, { useState } from 'react';
import { Button, Input, message, Modal, Popover } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { ChromePicker } from 'react-color';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Config } from 'types/graphqlTypes';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const EMAIL_CONTENT = gql`
  query EmailContent {
    config {
      websiteLink
      logoLink
      themeColor
      themeSubColor
    }
  }
`;

const UPDATE_EMAIL_CONTENT = gql`
  mutation UpdateEmailContent($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

const validationSchema = yup.object().shape({
  websiteLink: yup
    .string()
    .nullable()
    .url('Must be a valid URL format e.g. http://website.com'),
  logoLink: yup
    .string()
    .nullable()
    .url('Must be a valid URL format e.g. http://website.com'),
});

const ManageEmailContent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { control, setValue, watch, reset, handleSubmit, errors } = useForm({
    defaultValues: {
      websiteLink: '',
      logoLink: '',
      themeColor: '#FFFFFF',
      themeSubColor: '#FFFFFF',
    },
    resolver: yupResolver(validationSchema),
  });
  const { context } = useOperatorHeader();
  const [updateEmailContent, { loading: mutationLoading }] = useMutation(
    UPDATE_EMAIL_CONTENT,
    {
      context,
    }
  );
  const { refetch, loading: queryLoading } = useQuery<{ config: Config }>(
    EMAIL_CONTENT,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: ({ config }) => {
        reset({
          websiteLink: config.websiteLink || '',
          logoLink: config.logoLink || '',
          themeColor: config.themeColor || '#FFFFFF',
          themeSubColor: config.themeSubColor || '#FFFFFF',
        });
      },
    }
  );

  const themeColorWatched = watch('themeColor');
  const themeSubColorWatched = watch('themeSubColor');

  const handleOnOk = (values: Record<string, any>) => {
    updateEmailContent({
      variables: {
        input: {
          ...values,
        },
      },
    })
      .then(() => {
        message.success('Email content updated!');
      })
      .finally(() => {
        setIsModalVisible(false);
        refetch();
      });
  };

  return (
    <div>
      <h3>Manage Email Content</h3>
      <Button onClick={() => setIsModalVisible(true)}>
        Configure Email Content
      </Button>

      <Modal
        destroyOnClose
        title="Configure Email Content"
        visible={isModalVisible}
        onOk={handleSubmit(handleOnOk)}
        onCancel={() => setIsModalVisible(false)}
        okButtonProps={{ loading: queryLoading || mutationLoading }}
        cancelButtonProps={{ loading: queryLoading || mutationLoading }}
      >
        <div className="mb-3">
          <p className="mb-1 fw-500">Website Link:</p>
          <Controller
            as={<Input placeholder="http://www.website.com" />}
            control={control}
            name="websiteLink"
          />
          <span className="text-11 text-danger">
            {errors?.websiteLink?.message}
          </span>
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">Logo Link:</p>
          <Controller
            as={<Input placeholder="http://www.website.com" />}
            control={control}
            name="logoLink"
          />
          <span className="text-11 text-danger">
            {errors?.logoLink?.message}
          </span>
        </div>
        <div className="mb-3">
          <div className="d-flex align-items-center">
            <p className="mb-1 fw-500">Theme Color</p>
            <div
              className="ml-1 mb-1 border"
              style={{
                height: '10px',
                width: '10px',
                background: themeColorWatched,
              }}
            />
          </div>

          <div className="d-flex align-items-center">
            <Controller
              as={<Input readOnly style={{ width: '30%' }} />}
              control={control}
              name="themeColor"
            />
            <Popover
              trigger="click"
              content={
                <ChromePicker
                  color={themeColorWatched}
                  onChange={(e) => setValue('themeColor', e.hex)}
                  onChangeComplete={(e) => setValue('themeColor', e.hex)}
                />
              }
            >
              <Button className="ml-2">Select Color</Button>
            </Popover>
          </div>
        </div>
        <div className="mb-3">
          <div className="d-flex align-items-center">
            <p className="mb-1 fw-500">Theme Sub-Color</p>
            <div
              className="ml-1 mb-1 border"
              style={{
                height: '10px',
                width: '10px',
                background: themeSubColorWatched,
              }}
            />
          </div>

          <div className="d-flex">
            <Controller
              as={<Input readOnly style={{ width: '30%' }} />}
              control={control}
              name="themeSubColor"
            />
            <Popover
              trigger="click"
              content={
                <ChromePicker
                  color={themeSubColorWatched}
                  onChange={(e) => setValue('themeSubColor', e.hex)}
                  onChangeComplete={(e) => setValue('themeSubColor', e.hex)}
                />
              }
            >
              <Button className="ml-2">Select Color</Button>
            </Popover>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ManageEmailContent;
