import { useQuery } from '@apollo/react-hooks';
import { Button, Col, Modal, Row as AntdRow } from 'antd';
import { getFileNameFromURL } from 'constants/form';
import ALL_PERMISSIONS from 'constants/permissions3';
import supportedTimezones from 'constants/supportedTimezones';
import { GET_SITE_CONFIGURATION } from 'graphql/queries/siteConfiguration.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import globalMessages from 'messages';
import React, { useState } from 'react';
import { useAccount, usePermissions } from 'store/accountState';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import EditSiteConfiguration from '../../EditSiteConfiguration';
import messages from '../../messages';
import { StyledSider } from '../../styles';
import InfoValues from './InfoValues';

const Row: any = AntdRow;

const StyledTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const RightSidebar = (props: any) => {
  const { role, permissions } = usePermissions();
  const translate = useTranslate();
  const {
    account: { locale },
  } = useAccount();
  const { ALLOWED_EDIT } = collectPermissions(
    role,
    permissions,
    ['EDIT'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_SITE_CONFIG}:SUBDOMAIN`
  );

  const { data = {} } = useQuery(GET_SITE_CONFIGURATION);
  const { config = {} } = data as {
    config?: Record<string, any>;
  };
  const [show, toggleModal] = useState(false);
  const onToggleModal = () => toggleModal(!show);

  const timezoneIdx = supportedTimezones.en.indexOf(config.timezone);
  const activeTimezone = supportedTimezones[locale];
  const timezone = activeTimezone[timezoneIdx];

  return (
    <StyledSider
      className="bl-1"
      theme="light"
      trigger={null}
      collapsible
      collapsedWidth={0}
      width={350}
      {...props}
    >
      <div className="p-3 bb-1">
        <Row justify="space-between" type="flex">
          <Col span={6}>
            <span style={{ fontSize: '16px' }}>{config.domainName}</span>
          </Col>
          <Col span={6}>
            {ALLOWED_EDIT && (
              <Button onClick={onToggleModal}>
                {translate(globalMessages['edit.text'])}
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <div className="p-3">
        <StyledTitle>{translate(messages.generalInfo)}</StyledTitle>
        <InfoValues label={translate(messages.cname)} value={config.cname} />

        <StyledTitle>
          {translate(messages.urlsslcertificateconfiguration)}
        </StyledTitle>
        <InfoValues
          label={translate(messages.sslcertification)}
          value={translate(
            globalMessages[config.sslEnabled ? 'on.text' : 'off.text']
          )}
        />
        <InfoValues
          label={translate(messages.crtfile)}
          value={config.crtFile ? getFileNameFromURL(config.crtFile.uri) : ''}
        />
        <InfoValues
          label={translate(messages.privatekey)}
          value={
            config.privateKey ? getFileNameFromURL(config.privateKey.uri) : ''
          }
        />
        <InfoValues
          label={translate(messages.intermediarycrtfile)}
          value={
            config.intermediaryCert
              ? getFileNameFromURL(config.intermediaryCert.uri)
              : ''
          }
        />

        <InfoValues
          label={translate(messages.seamless)}
          value={translate(
            globalMessages[
              config.seamlessVendorsEnabled ? 'on.text' : 'off.text'
            ]
          )}
        />

        <StyledTitle>{translate(messages.paymentmethodratecalc)}</StyledTitle>
        <InfoValues
          label={translate(messages.paymentmethodtransactions)}
          value={
            config.paymentMethodSuccessRateCalculationLastTransactionsCount
          }
        />

        <StyledTitle>{translate(messages.withdrawalcharges)}</StyledTitle>
        <InfoValues
          label={translate(messages.withdrawalfee)}
          value={config.withdrawalHandlingFee}
        />
        <InfoValues
          label={translate(messages.HANDLING_FEE_CHARGING)}
          value={translate(
            config.withdrawalHandlingFeeType
              ? messages[config.withdrawalHandlingFeeType]
              : globalMessages['loading.text']
          )}
        />
        <InfoValues
          label={translate(messages.withdrawaltax)}
          value={translate(
            config.withdrawalTaxType
              ? globalMessages[config.withdrawalTaxType]
              : globalMessages['loading.text']
          )}
        />

        <StyledTitle>{translate(messages.timezone)}</StyledTitle>
        <InfoValues label={translate(messages.timezone)} value={timezone} />
      </div>
      <Modal
        visible={show}
        title={translate(messages.edittitle)}
        destroyOnClose
        width={700}
        footer={null}
        onCancel={onToggleModal}
      >
        <EditSiteConfiguration onSuccess={onToggleModal} config={config} />
      </Modal>
    </StyledSider>
  );
};

export default RightSidebar;
