import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers';
import { Button, Input, message, Modal } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Config } from 'types/graphqlTypes';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import * as yup from 'yup';

const SKRILL_SHOP_DETAILS = gql`
  query SkrillShopDetails {
    config {
      skrillSecretKey
      skrillShopId
      skrillAccount
    }
  }
`;

const UPDATE_SKRILL_SHOP_DETAILS = gql`
  mutation UpdateSkrillDetails($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

const noWhitespaceAndRequired = yup
  .string()
  .nullable()
  .required('Field is required')
  .matches(/^\S+$/, 'No whitespace allowed');

const validationSchema = yup.object().shape({
  skrillShopId: noWhitespaceAndRequired,
  skrillSecretKey: noWhitespaceAndRequired,
  skrillAccount: noWhitespaceAndRequired,
});

const SkrillShopDetails = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { control, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      skrillShopId: '',
      skrillSecretKey: '',
      skrillAccount: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { context } = useOperatorHeader();
  const [updateSkrill, { loading: mutationLoading }] = useMutation(
    UPDATE_SKRILL_SHOP_DETAILS,
    {
      context,
    }
  );

  const { loading, refetch } = useQuery<{ config: Config }>(
    SKRILL_SHOP_DETAILS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: ({ config }) => {
        reset({
          skrillShopId: config.skrillShopId!,
          skrillSecretKey: config.skrillSecretKey!,
          skrillAccount: config.skrillAccount!,
        });
      },
    }
  );

  const handleOnOk = (values: Record<string, any>) => {
    updateSkrill({
      variables: {
        input: {
          ...values,
        },
      },
    })
      .then(() => {
        message.success('Skrill shop details updated!');
      })
      .finally(() => {
        setIsModalVisible(false);
        refetch();
      });
  };

  return (
    <div>
      <h3>Manage Skrill Shop Details</h3>
      <Button onClick={() => setIsModalVisible(true)}>
        Configure Skrill Shop Details
      </Button>

      <Modal
        destroyOnClose
        title="Configure Skrill Shop Details"
        visible={isModalVisible}
        onOk={handleSubmit(handleOnOk)}
        onCancel={() => setIsModalVisible(false)}
        okButtonProps={{ loading: loading || mutationLoading }}
        cancelButtonProps={{ loading: loading || mutationLoading }}
      >
        <div className="mb-3">
          <p className="mb-1 fw-500">Skrill Shop ID:</p>
          <Controller as={Input} control={control} name="skrillShopId" />
          <span className="text-11 text-danger">
            {errors?.skrillShopId?.message}
          </span>
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">Skrill Secret Key:</p>
          <Controller as={Input} control={control} name="skrillSecretKey" />
          <span className="text-11 text-danger">
            {errors?.skrillSecretKey?.message}
          </span>
        </div>

        <div className="mb-3">
          <p className="mb-1 fw-500">Skrill Account:</p>
          <Controller as={Input} control={control} name="skrillAccount" />
          <span className="text-11 text-danger">
            {errors?.skrillAccount?.message}
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default SkrillShopDetails;
