import gql from 'graphql-tag';
import { CurrencyMetadata } from 'types/graphqlTypes';
import { getColumnSearchProps } from '../utils';

export const columns = [
  {
    title: 'Sr. No',
    dataIndex: 'digitalCode',
    key: 'digitalCode',
  },
  {
    title: 'Currency Title',
    dataIndex: 'name',
    key: 'name',
    ...getColumnSearchProps('name'),
    sorter: (a: CurrencyMetadata, b: CurrencyMetadata) =>
      a.name.localeCompare(b.name),
  },
  {
    title: 'Currency Symbol',
    dataIndex: 'symbol',
    key: 'symbol',
  },
  {
    title: 'Currency Alpha 3 Code',
    dataIndex: 'code',
    key: 'code',
    sorter: (a: CurrencyMetadata, b: CurrencyMetadata) =>
      a.code.localeCompare(b.code),
  },
];
export const CURRENCIES = gql`
  query Currencies {
    currencies(first: 1000) {
      edges {
        node {
          code
          digitalCode
          id
          name
          symbol
        }
      }
    }
  }
`;
export const DEFAULT_CURRENCY = gql`
  query Config {
    config {
      defaultCurrency {
        id
        code
        name
        digitalCode
        symbol
      }
    }
  }
`;
export const UPDATE_CURRENCY = gql`
  mutation UpdateConfig($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;
