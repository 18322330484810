import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import messages from 'pages/components/Reports/messages';
import useTranslate from 'utils/useTranslate';
import { DATE_FORMAT } from 'constants/date';
import { StyledRangePicker } from './styles';

type Props = {
  state: Record<string, any>;
  setState?: (data: Record<string, any>) => void;
  onChange?: (data: Record<string, any>) => void;
};

const DateRangeFilter = ({ state, setState, onChange }: Props) => {
  const lastSevenDays = () => {
    const dates = [moment().subtract(7, 'd'), moment()];
    if (onChange) {
      onChange(dates);
    } else if (setState) {
      setState({
        ...state,
        dateRange: dates,
      });
    }
  };
  const lastWeek = () => {
    const dates = [moment().subtract(2, 'w'), moment().subtract(1, 'w')];
    if (onChange) {
      onChange(dates);
    } else if (setState) {
      setState({
        ...state,
        dateRange: dates,
      });
    }
  };
  const lastThirtyDays = () => {
    const dates = [moment().subtract(30, 'd'), moment()];
    if (onChange) {
      onChange(dates);
    } else if (setState) {
      setState({
        ...state,
        dateRange: dates,
      });
    }
  };
  const translate = useTranslate();

  return (
    <div className="my-3">
      <div className="mb-1">
        <FormattedMessage id="reports.period.text" defaultMessage="Period" />
      </div>
      <StyledRangePicker
        placeholder={[
          translate(messages['reports.from.text']),
          translate(messages['reports.to.text']),
        ]}
        format={DATE_FORMAT}
        value={state.dateRange}
        onChange={(dates: any) => {
          if (onChange) {
            onChange(dates);
          } else if (setState) {
            setState({
              ...state,
              dateRange: dates,
            });
          }
        }}
      />
      <div className="mt-2">
        <Button size="small" className="mb-1" onClick={lastSevenDays}>
          <FormattedMessage
            id="last-7-days.text"
            defaultMessage="Last 7 days"
          />
        </Button>
        <Button size="small" className="mx-1 mb-1" onClick={lastWeek}>
          <FormattedMessage id="last-week.text" defaultMessage="Last week" />
        </Button>
        <Button size="small" onClick={lastThirtyDays}>
          <FormattedMessage
            id="last-30-days.text"
            defaultMessage="Last 30 days"
          />
        </Button>
      </div>
    </div>
  );
};

export default DateRangeFilter;
