import React from 'react';
import StatusBadgeIntl from 'components/StatusBadgeIntl';
import { FormattedMessage } from 'react-intl';
import MemberLevelButton from 'components/MemberLevelButton';
import messages from 'messages';
import { columnAlign } from 'utils/tableAlignment';
import MembersCount from './components/MemberCount';

const RenderButton = ({ color, name }: { color: string; name: string }) => {
  const memberLevel = {
    color,
    name,
  };
  return <MemberLevelButton memberLevelData={memberLevel} />;
};

const StatusBadge: any = StatusBadgeIntl;

const columns = (translate: any) => [
  {
    key: 'name',
    title: translate(messages['name.text']),
    render: RenderButton,
    width: 100,
    align: columnAlign.onCenter,
  },
  {
    key: 'numberOfMembers',
    title: translate(messages['rebates.number-of-members.text']),
    dataIndex: 'id',
    render: (id: any, allData: Record<string, any>) => (
      <span>
        <MembersCount memberLevel={id} count={allData.membersCount} />
      </span>
    ),
    width: 100,
    align: columnAlign.onCenter,
  },
  {
    key: 'default',
    title: translate(messages.DEFAULT),
    dataIndex: 'default',
    render: (isDefault: boolean) =>
      isDefault ? (
        <strong>
          <FormattedMessage id="default.text" defaultMessage="Default" />
        </strong>
      ) : (
        ''
      ),
    width: 100,
    align: columnAlign.onCenter,
  },
  {
    key: 'status',
    title: translate(messages.status),
    dataIndex: 'status',
    width: 100,
    align: columnAlign.onCenter,
    render: (text: string) => <StatusBadge text={text} /> || '-',
  },
];

export default columns;
