import React from 'react';
import { Tooltip } from 'antd';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { useLoaders } from 'contexts/DataLoader';
import TableCellSpinner from 'components/TableCellSpinner';
import messages from 'messages';
import Note from './components/Note';
import { ContainerStyled, BubbleContainerStyled, BubbleStyled } from './styles';

type Props = {
  memberId: string;
  toolTipId: string;
};

const CustomTooltip: any = Tooltip;

const MemberNotes = ({
  memberId,
  toolTipId = 'member-notes-deposit-request',
}: Props) => {
  const translate = useTranslate();

  const {
    memberNotesLoaderSet: [useMemberNotesLoader],
  } = useLoaders();
  const { loading, error, data = {} } = useMemberNotesLoader(memberId);

  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  if (loading) return <TableCellSpinner />;

  const notes = coercedGet(data, 'account.notes.edges', []);

  const notesLength = notes.length;
  const toolTipTitle = notesLength ? (
    <Note note={notes[0]} />
  ) : (
    translate(messages.NO_MEMBER_NOTE_YET)
  );

  return (
    <CustomTooltip
      placement="bottom"
      title={toolTipTitle}
      {...(toolTipId && {
        getPopupContainer: () => document.getElementById(toolTipId),
      })}
    >
      <ContainerStyled>
        <BubbleContainerStyled>
          <BubbleStyled notesLength={notesLength}>{notesLength}</BubbleStyled>
        </BubbleContainerStyled>
        <div id={toolTipId} />
      </ContainerStyled>
    </CustomTooltip>
  );
};

export default MemberNotes;
