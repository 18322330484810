import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export type SettingState = {
  siderCollapsed: boolean;
};

export const settingSlice = createSlice({
  name: 'settings',
  initialState: {
    siderCollapsed: true,
  },
  reducers: {
    toggleSider: (state) => {
      Object.assign(state, {
        siderCollapsed: !state.siderCollapsed,
      });
    },
  },
});

export const settingsReducer = settingSlice.reducer;

export const useSetting = () => {
  const { actions } = settingSlice;
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: { settings: SettingState }) => state.settings
  );
  return {
    toggleSider: useCallback(() => dispatch(actions.toggleSider()), [
      actions,
      dispatch,
    ]),
    settings,
  };
};
