export const currency = '£';

export const cryptoCurrencies = [
  'USDT',
  'ETH',
  'DMT',
  'BELT',
  'NFT',
  'BTO',
  'CND',
  'BCD',
  'CLAM',
  'BTC',
  'COLX',
  'FFT',
];

export const currencies = {
  ZWL: '$',
  ZMW: 'ZK',
  ZAR: 'R',
  XAF: '₣',
  XCD: '$',
  YER: '﷼',
  XPF: '₣',
  WST: 'T',
  VND: '₫',
  VEF: 'Bs F',
  VUV: 'Vt',
  UZS: '',
  UGX: 'Sh',
  USD: '$',
  UYU: '$',
  UAH: '₴',
  TZS: 'Sh',
  TRY: '₤',
  TND: 'د.ت',
  TTD: '$',
  TOP: 'T$',
  TWD: '$',
  TMT: 'm',
  TJS: 'ЅМ',
  THB: '฿',
  SRD: '$',
  SZL: 'L',
  STN: 'Db',
  SYP: 'ل.س',
  SLL: 'Le',
  SEK: 'kr',
  SHP: '£',
  SGD: '$',
  SOS: 'Sh',
  SBD: '$',
  SCR: '₨',
  SAR: 'ر.س',
  SDG: '£',
  RWF: '₣',
  RUB: 'р.',
  RSD: 'din',
  RON: 'L',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'ر.ق',
  PHP: '₱',
  PEN: 'S/.',
  PGK: 'K',
  PKR: '₨',
  PAB: 'B/.',
  OMR: 'ر.ع.',
  NZD: '$',
  NPR: '₨',
  NIO: 'C$',
  NOK: 'kr',
  NAD: '$',
  NGN: '₦',
  MZN: 'MTn',
  MWK: 'MK',
  MVR: 'ރ.',
  MXN: '$',
  MRU: 'UM',
  MYR: 'RM',
  MUR: '₨',
  MOP: 'P',
  MGA: '',
  MMK: 'K',
  MKD: 'ден',
  MNT: '₮',
  MDL: 'L',
  MAD: 'د.م.',
  LSL: 'L',
  LRD: '$',
  LYD: 'ل.د',
  LBP: 'ل.ل',
  KPW: '₩',
  KRW: '₩',
  KYD: '$',
  KWD: 'د.ك',
  KZT: '〒',
  LKR: 'Rs',
  LAK: '₭',
  KES: 'Sh',
  KGS: '',
  JPY: '¥',
  KHR: '៛',
  JMD: '$',
  JOD: 'د.ا',
  IRR: '﷼',
  ISK: 'Kr',
  HUF: 'Ft',
  IDR: 'Rp',
  INR: '₹',
  ILS: '₪',
  IQD: 'ع.د',
  HNL: 'L',
  HRK: 'Kn',
  HTG: 'G',
  HKD: '$',
  GTQ: 'Q',
  GMD: 'D',
  GYD: '$',
  GNF: '₣',
  GEL: 'ლ',
  GIP: '£',
  GBP: '£',
  GHS: '₵',
  FJD: '$',
  EUR: '€',
  ETB: '',
  FKP: '£',
  ERN: 'Nfk',
  EGP: '£',
  DOP: '$',
  DZD: 'د.ج',
  CVE: '$',
  CZK: 'Kč',
  DJF: '₣',
  DKK: 'kr',
  CNY: '¥',
  CRC: '₡',
  CUP: '$',
  COP: '$',
  CHF: '₣',
  BZD: '$',
  CAD: '$',
  CDF: '₣',
  CLP: '$',
  BYN: 'Br',
  BWP: 'P',
  BTN: '',
  BSD: '$',
  BOB: 'Bs.',
  BRL: 'R$',
  BIF: '₣',
  BND: '$',
  BMD: '$',
  BDT: '৳',
  BHD: 'ب.د',
  BGN: 'лв',
  BBD: '$',
  BAM: 'КМ',
  AZN: 'ман',
  AUD: '$',
  AWG: 'ƒ',
  ARS: '$',
  AMD: 'Դ',
  AOA: 'Kz',
  ALL: 'L',
  AFN: 'Af',
  AED: 'د.إ',
  USDT: '',
  ETH: '',
  DMT: '',
  BELT: '',
  NFT: '',
  BTO: '',
  CND: '',
  BCD: '',
  CLAM: '',
  BTC: '',
  COLX: '',
  FFT: '',
};
