import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery, useApolloClient } from '@apollo/react-hooks';
import { Select, message, Badge } from 'antd';
import coercedGet from 'utils/coercedGet';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { MemberTagConnectionsEdge } from 'types/graphqlTypes';
import { useDebounce } from 'hooks/useDebounce';
import { MEMBER_TAGS } from './queries';

const { Option } = Select;

const MEMBER_TAG = gql`
  query MemberTag($id: ID!) {
    memberTag(id: $id) {
      id
      name
      color
    }
  }
`;

type Props = {
  onChange: (e: Array<string> | string) => void;
  placeHolder?: string;
  value: Array<string> | string | undefined;
  disabled?: boolean;
  multiple?: boolean;
  labelAsValue?: boolean;
  queryFilter?: { [key: string]: any };
  isCustomFilter?: boolean;
};

const MemberTagSelect: React.FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
  labelAsValue,
  isCustomFilter = false,
}) => {
  const translate = useTranslate();

  const [options, setOptions] = useState([]);
  const [missingId, setMissingId] = useState({
    retrieved: false,
    loading: false,
  });
  const [searchInput, setSearchInput] = useState<string>('');
  const debouncedInput = useDebounce(searchInput, 500);
  const client = useApolloClient();

  const [loadMemberTags, { loading }] = useLazyQuery(MEMBER_TAGS, {
    context: {
      component: true,
    },
    variables: {
      first: 10,
      filter: {
        name: {
          contains: debouncedInput,
        },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const edges = coercedGet(data, 'memberTags.edges', []);

      setOptions(edges);
    },
    onError: () => {
      message.error({
        content: <span>{translate(messages.INTERNAL_SERVER_ERROR)}</span>,
      });
    },
  });

  const handleChange = (e: string | Array<string>) => {
    onChange(e);
  };

  React.useEffect(() => {
    if (isCustomFilter) loadMemberTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      !value ||
      value.length === 0 ||
      !options ||
      options?.length === 0 ||
      missingId.retrieved
    ) {
      return;
    }

    const init = async () => {
      const missingMemberTags: string[] =
        (!searchInput &&
          (value as string[]).filter(
            (x: string) =>
              !options.find(
                (memberTag: MemberTagConnectionsEdge) => memberTag.node.id === x
              )
          )) ||
        [];

      if (missingMemberTags.length > 0) {
        setMissingId((prev) => ({
          ...prev,
          loading: true,
        }));
        const memberTagsQueryPromises = missingMemberTags.map((memberTagId) =>
          client.query({
            query: MEMBER_TAG,
            variables: {
              id: memberTagId,
            },
          })
        );

        await Promise.all(memberTagsQueryPromises)
          .then((res) => {
            const memberTagsData: any = res.map(({ data }) => ({
              node: {
                id: data.memberTag.id,
                name: data.memberTag.name,
                color: data.memberTag.color,
              },
            }));

            setOptions(memberTagsData);
          })
          .finally(() =>
            setMissingId({
              retrieved: true,
              loading: false,
            })
          );
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, client, value]);

  return (
    <Select
      value={value || []}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      loading={loading || missingId.loading}
      onSearch={(text: string) => {
        setSearchInput(text);
      }}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
      onDropdownVisibleChange={(open) => {
        if (open && !options.length) loadMemberTags();
      }}
    >
      {options.map((edge: MemberTagConnectionsEdge) => (
        <Option
          key={edge.node?.id}
          value={labelAsValue ? edge.node?.name : edge.node?.id}
        >
          <Badge color={edge.node?.color || '#ffffff'} />
          <span>{edge.node?.name}</span>
        </Option>
      ))}
    </Select>
  );
};

export default MemberTagSelect;
