import { notification } from 'antd';

const copyToClipboard = (text: any, message?: string) => {
  const textField = document.createElement('textarea');
  textField.innerText = `${text}`;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();

  return notification.info({
    message: message || 'Copied To clipboard!',
    duration: 3,
  });
};

export default copyToClipboard;
