import { CheckCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import { Modal, Tooltip } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { Key } from 'antd/lib/table/interface';
import { showPermissionError } from 'components/Navbar/Navbar';
import { DATE_TIME_FORMAT } from 'constants/date';
import ALL_PERMISSIONS from 'constants/permissions3';
import { TabId } from 'constants/route';
import { StyledTable } from 'constants/styles';
import { GET_WITHDRAWAL_SERIAL_CODES } from 'graphql/queries/withdrawal.query';
import { useConfig } from 'hooks/useConfig';
import WithdrawalDetails from 'pages/components/MemberWithdrawals/components/WithdrawalDetails';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import commonMessages from 'messages';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import { usePermissions } from 'store/accountState';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { useScreenTabV2 } from 'store/screenTabState';
import coercedGet from 'utils/coercedGet';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import { columnAlign } from 'utils/tableAlignment';
import useTranslate from 'utils/useTranslate';
import isRow from 'utils/isRow';
import { OriginalColumn } from 'store/customColumnState/types';
import { MemberBalanceUnitRecord } from 'types/graphqlTypes-row';
import { cryptoCurrencies, currencies } from 'constants/currency';
import messages from '../../messages';
import WithdrawalCodes from './components/WithdrawalCodes';

const RedirectingDialog = () => (
  <Modal
    width={200}
    closable={false}
    maskClosable={false}
    footer={null}
    centered
    visible
  >
    <div style={{ textAlign: 'center' }}>
      <h5>Redirecting...</h5>
    </div>
  </Modal>
);

type BalanceServiceRecordsTableProps = {
  dataSource: Array<Record<string, any>>;
  loading: boolean;
  refetchVariables: Record<string, any>;
  handleSort: (order: 'ascend' | 'descend') => void;
};

const BalanceServiceRecordsTable = ({
  dataSource,
  loading,
  refetchVariables,
  handleSort,
}: BalanceServiceRecordsTableProps) => {
  const { addTab } = useScreenTabV2();
  const translate = useTranslate();
  const {
    currency: configCurrency,
    addCurrency,
    renderNumeral,
    addAdminCurrency,
  } = useConfig();

  const { role, permissions } = usePermissions();

  const { setTableColumns } = useSpreadsheetContext();

  const {
    ALLOWED_BALANCE_TRANSACTION_REPORTS,
    ALLOWED_MEMBER_BET_RECORDS,
  } = collectPermissions(
    role,
    permissions,
    ['BALANCE_TRANSACTION_REPORTS', 'MEMBER_BET_RECORDS'],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS
  );

  const { ALLOWED_WITHDRAWAL_REQUESTS } = collectPermissions(
    role,
    permissions,
    ['WITHDRAWAL_REQUESTS'],
    ALL_PERMISSIONS.ALL_WITHDRAWALS.WITHDRAWALS
  );

  const [state, setState] = useState({
    selectedRowKeys: [],
  });

  const [withdrawalModal, setWithdrawalModal] = useState<any>({
    visible: false,
    withdrawaId: null,
  });

  const rowSelection = {
    selectedRowKeys: state.selectedRowKeys,
    columnWidth: '32px',
    onChange: (e: any) => setState({ ...state, selectedRowKeys: e }),
  };

  const data = dataSource
    ? dataSource.map(({ node }) => ({ ...node, key: node.id }))
    : [];

  const openLink = (tab: string, refValues: Record<string, any> = {}) => {
    const {
      BALANCE_TRANSACTION_RECORDS,
      MEMBER_BET_RECORDS,
      WITHDRAWAL_REQUESTS,
    } = TabId;

    if (tab === MEMBER_BET_RECORDS) {
      if (!ALLOWED_MEMBER_BET_RECORDS) {
        showPermissionError('Member Bet Records - View');
        return;
      }
      addTab({
        id: MEMBER_BET_RECORDS as any,
        state: {
          balanceUnitSerialCodesRef: refValues.serialCode,
          memberIdRef: refetchVariables.filter.member,
        },
      });
    } else if (tab === BALANCE_TRANSACTION_RECORDS) {
      if (!ALLOWED_BALANCE_TRANSACTION_REPORTS) {
        showPermissionError('Balance Transaction Records - View');
        return;
      }
      addTab({
        id: BALANCE_TRANSACTION_RECORDS as any,
        state: {
          serialCodeRef: refValues.transactionSerialCode,
        },
      });
    } else if (tab === WITHDRAWAL_REQUESTS) {
      if (!ALLOWED_WITHDRAWAL_REQUESTS) {
        showPermissionError('Withdrawal Requests - View');
        return;
      }
      setWithdrawalModal({
        visible: true,
        withdrawaId: refValues.withdrawalId,
      });
    }
  };

  const [
    findWithdrawal,
    { loading: findingWithdrawal, variables },
  ] = useLazyQuery(GET_WITHDRAWAL_SERIAL_CODES, {
    fetchPolicy: 'network-only',
    onCompleted: (result) => {
      const edges = coercedGet(result, 'withdrawals.edges', []);
      if (edges.length) {
        const withdrawalId = coercedGet(edges, '[0]node.id', null);

        openLink(TabId.WITHDRAWAL_REQUESTS, {
          withdrawalId,
        });
      } else {
        const transactionSerialCode = coercedGet(
          variables,
          'filter.serialCode.eq',
          null
        );

        openLink(TabId.BALANCE_TRANSACTION_RECORDS, {
          transactionSerialCode,
        });
      }
    },
  });

  const handleWithdrawalRedirect = (code: string) => {
    findWithdrawal({
      variables: {
        filter: { serialCode: { eq: code } },
      },
    });
  };

  const columns = [
    {
      csvData: {
        label: translate(commonMessages.MANUAL_ADJUSTMENT),
        key: 'manualAdjustment',
        renderCell: (record: { transactionType: string }) =>
          record.transactionType === 'DEPOSIT' ? '✓' : '-',
      },
      key: 'manualAdjustment',
      align: columnAlign.onCenter,
      title: translate(commonMessages.MANUAL_ADJUSTMENT),
      dataIndex: 'manualAdjustment',
      width: 95,
      render: (manualAdjustment: boolean) =>
        manualAdjustment ? (
          <Tooltip
            arrowPointAtCenter
            title={translate(messages.MANUAL_ADJUSTMENT)}
          >
            <CheckCircleOutlined style={{ fontSize: '15px', color: 'green' }} />
          </Tooltip>
        ) : (
          '-'
        ),
    },
    {
      csvData: {
        label: translate(commonMessages.TRANSACTION_TYPE),
        key: 'transactionType',
        renderCell: (record: { transactionType: string }) =>
          record.transactionType
            ? translate(commonMessages[record.transactionType])
            : '-',
      },
      key: 'transactionType',
      align: columnAlign.onCenter,
      title: translate(commonMessages.TRANSACTION_TYPE),
      dataIndex: 'transactionType',
      width: 95,
      render: (transactionType: string) =>
        transactionType ? translate(commonMessages[transactionType]) : '-',
    },
    {
      csvData: {
        label: translate(commonMessages.REMARKS),
        key: 'remarks',
        renderCell: (record: { remarks: string }) => record.remarks,
      },
      key: 'remarks',
      align: columnAlign.onCenter,
      title: translate(commonMessages.REMARKS),
      dataIndex: 'remarks',
      width: 180,
      render: (remarks: string) => remarks || '-',
    },
    {
      csvData: {
        label: `${translate(messages.ORIGINAL_AMOUNT)}(${
          isRow ? 'Member' : configCurrency?.symbol
        })`,
        key: 'originalAmount',
        renderCell: (record: {
          originalAmount: number | string;
          currency: string;
        }) => {
          if (isRow) {
            const isCrypto = cryptoCurrencies.includes(record.currency);

            return renderNumeral(record.originalAmount, isCrypto);
          }

          return record.originalAmount;
        },
      },
      key: 'originalAmount',
      align: columnAlign.onCenter,
      title: `${translate(messages.ORIGINAL_AMOUNT)}(${
        isRow ? 'Member' : configCurrency?.symbol
      })`,
      dataIndex: 'originalAmount',
      width: isRow ? 150 : 85,
      render: (originalAmount: number, record: MemberBalanceUnitRecord) => {
        const isCrypto = cryptoCurrencies.includes(record.currency || '');
        return (
          <>{`${
            record.currency
              ? currencies[record.currency || '']
              : configCurrency.symbol
          } ${renderNumeral(originalAmount, isCrypto)}`}</>
        );
      },
    },
    {
      csvData: {
        label: `${translate(messages.CURRENT_AMOUNT)}(${
          isRow ? 'Member' : configCurrency?.symbol
        })`,
        key: 'currentAmount',
        renderCell: (record: { currentAmount: number; currency: string }) => {
          if (isRow) {
            const isCrypto = cryptoCurrencies.includes(record.currency);

            return renderNumeral(record.currentAmount, isCrypto);
          }

          return record.currentAmount;
        },
      },
      key: 'currentAmount',
      align: columnAlign.onCenter,
      title: `${translate(messages.CURRENT_AMOUNT)}(${
        isRow ? 'Member' : configCurrency?.symbol
      })`,
      dataIndex: 'currentAmount',
      width: isRow ? 150 : 85,
      render: (currentAmount: number, record: MemberBalanceUnitRecord) => {
        const isCrypto = cryptoCurrencies.includes(record.currency || '');
        return (
          <>{`${
            record.currency
              ? currencies[record.currency || '']
              : configCurrency.symbol
          } ${renderNumeral(currentAmount, isCrypto)}`}</>
        );
      },
    },

    {
      ...(isRow && {
        csvData: {
          label: 'Currency',
          key: 'currency',
          renderCell: (record: { currency: string }) => record.currency,
        },
        key: 'currency',
        align: columnAlign.onCenter,
        title: 'Currency',
        dataIndex: 'currency',
        width: 105,
        // eslint-disable-next-line no-shadow
        render: (currency: string) => currency || '-',
      }),
    },

    {
      ...(isRow && {
        csvData: {
          label: 'Amount(Base)',
          key: 'baseAmount',
          renderCell: (record: { baseAmount: number }) =>
            renderNumeral(record.baseAmount),
        },
        key: 'baseAmount',
        align: columnAlign.onCenter,
        title: 'Amount(Base)',
        dataIndex: 'baseAmount',
        width: 110,
        // eslint-disable-next-line no-shadow
        render: (baseAmount: number) => addAdminCurrency(baseAmount),
      }),
    },

    {
      ...(isRow && {
        csvData: {
          label: 'Exchange Rate(Base)',
          key: 'exchangeRate',
          renderCell: (record: { exchangeRate: number }) =>
            renderNumeral(record.exchangeRate),
        },
        key: 'exchangeRate',
        align: columnAlign.onCenter,
        title: 'Exchange Rate(Base)',
        dataIndex: 'exchangeRate',
        width: 105,
        // eslint-disable-next-line no-shadow
        render: (exchangeRate: number) => addAdminCurrency(exchangeRate),
      }),
    },

    {
      csvData: {
        label: translate(messages.TURNOVER_MULTIPLIER),
        key: 'turnoverRequirementMultiplier',
        renderCell: (record: { turnoverRequirementMultiplier: number }) =>
          `x${record.turnoverRequirementMultiplier}`,
      },
      key: 'turnoverRequirementMultiplier',
      align: columnAlign.onCenter,
      title: translate(messages.TURNOVER_MULTIPLIER),
      dataIndex: 'turnoverRequirementMultiplier',
      width: 85,
      render: (turnoverRequirementMultiplier: number) =>
        `x${turnoverRequirementMultiplier}`,
    },
    {
      csvData: {
        label: `${translate(messages.TURNOVER_REQUIREMENT)}(${
          configCurrency?.symbol
        })`,
        key: 'turnoverRequirement',
        renderCell: (record: { turnoverRequirement: number }) =>
          record.turnoverRequirement,
      },
      key: 'turnoverRequirement',
      align: columnAlign.onCenter,
      title: `${translate(messages.TURNOVER_REQUIREMENT)}(${
        configCurrency?.symbol
      })`,
      dataIndex: 'turnoverRequirement',
      width: 115,
      render: (turnoverRequirement: number) =>
        addCurrency(turnoverRequirement, 2),
    },
    {
      csvData: {
        label: `${translate(messages.TURNOVER_BALANCE)}(${
          configCurrency?.symbol
        })`,
        key: 'turnoverRequirementBalance',
        renderCell: (record: { turnoverRequirementBalance: number }) =>
          record.turnoverRequirementBalance.toFixed(2),
      },
      key: 'turnoverRequirementBalance',
      align: columnAlign.onCenter,
      title: `${translate(messages.TURNOVER_BALANCE)}(${
        configCurrency?.symbol
      })`,
      dataIndex: 'turnoverRequirementBalance',
      width: 105,
      render: (turnoverRequirementBalance: number) =>
        addCurrency(turnoverRequirementBalance, 2),
    },
    {
      csvData: {
        label: translate(commonMessages.CREATION_DATE),
        key: 'dateTimeCreated',
        renderCell: (record: { dateTimeCreated: string }) =>
          moment(record.dateTimeCreated).format(DATE_TIME_FORMAT),
      },
      key: 'dateTimeCreated',
      align: columnAlign.onCenter,
      title: translate(commonMessages.CREATION_DATE),
      dataIndex: 'dateTimeCreated',
      width: 105,
      defaultSortOrder: 'descend',
      sortDirections: ['descend', 'ascend', 'descend'],
      sorter: true,
      render: (dateTimeCreated: string) =>
        moment(dateTimeCreated).format(DATE_TIME_FORMAT),
    },
    {
      csvData: {
        label: translate(commonMessages.COMPLETION_DATE),
        key: 'dateTimeTurnoverCompleted',
        renderCell: (record: Record<string, any>) =>
          record.dateTimeTurnoverCompleted
            ? moment(record.dateTimeTurnoverCompleted).format(DATE_TIME_FORMAT)
            : '-',
      },
      key: 'dateTimeTurnoverCompleted',
      align: columnAlign.onCenter,
      title: translate(commonMessages.COMPLETION_DATE),
      dataIndex: 'dateTimeTurnoverCompleted',
      width: 105,
      render: (dateTimeTurnoverCompleted: Record<string, any>) =>
        dateTimeTurnoverCompleted
          ? moment(dateTimeTurnoverCompleted).format(DATE_TIME_FORMAT)
          : '-',
    },
    {
      csvData: {
        label: translate(messages.BETS),
        key: 'betCount',
        renderCell: (record: { betCount: number }) => record?.betCount,
      },
      key: 'betCount',
      title: translate(messages.BETS),
      width: 100,
      align: 'center',
      render: (record: { serialCode: string; betCount: number }) => (
        <ALink
          onClick={() =>
            openLink(TabId.MEMBER_BET_RECORDS, {
              serialCode: record.serialCode,
            })
          }
          disabled={!record.betCount}
        >
          {record.betCount || '-'}
        </ALink>
      ),
    },
    {
      csvData: {
        label: translate(messages.WITHDRAWAL_ID),
        key: 'withdrawals',
        renderCell: (record: { withdrawals: Array<any> }) =>
          record.withdrawals.map((item) => ` ${item}`),
      },
      key: 'withdrawals',
      align: columnAlign.onCenter,
      title: translate(messages.WITHDRAWAL_ID),
      dataIndex: 'withdrawals',
      width: 150,
      render: (withdrawals: Array<any>) =>
        withdrawals.length ? (
          <WithdrawalCodes
            withdrawalCodes={withdrawals}
            handleRedirect={handleWithdrawalRedirect}
          />
        ) : (
          '-'
        ),
    },
    {
      csvData: {
        label: translate(messages.BALANCE_SERVICE_CODE),
        key: 'serialCode',
        renderCell: (record: { serialCode: string }) => record.serialCode,
      },
      key: 'serialCode',
      align: columnAlign.onCenter,
      title: translate(messages.BALANCE_SERVICE_CODE),
      dataIndex: 'serialCode',
      width: 200,
    },
    {
      csvData: {
        label: translate(messages.TRANSACTION_CODE),
        key: 'transactionSerialCode',
        renderCell: (record: { transactionSerialCode: string }) =>
          record.transactionSerialCode,
      },
      key: 'transactionSerialCode',
      align: columnAlign.onCenter,
      title: translate(messages.TRANSACTION_CODE),
      dataIndex: 'transactionSerialCode',
      width: 120,
      render: (transactionSerialCode: string) => (
        <ALink
          onClick={() =>
            openLink(TabId.BALANCE_TRANSACTION_RECORDS, {
              transactionSerialCode,
            })
          }
        >
          {transactionSerialCode}
        </ALink>
      ),
    },
  ];

  const { filterColumns } = useCustomColumnsV2(
    TabId.BALANCE_SERVICE_RECORD_REPORT as any,
    columns as OriginalColumn[]
  );

  useEffect(() => {
    if (!loading) {
      setTableColumns(filterColumns(columns as OriginalColumn[]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      {findingWithdrawal && <RedirectingDialog />}
      {withdrawalModal.visible ? (
        <WithdrawalDetails
          hideActions
          id={withdrawalModal.withdrawaId}
          toggleModal={() => setWithdrawalModal({ visible: false, filter: {} })}
        />
      ) : null}
      <StyledTable
        onChange={(
          _pagination: TablePaginationConfig,
          _filters: Record<string, (Key | boolean)[] | null>,
          sorter: Record<string, any>
        ) => {
          handleSort(sorter.order);
        }}
        loading={loading}
        rowSelection={rowSelection}
        columns={filterColumns(columns as OriginalColumn[])}
        size="small"
        dataSource={data}
        pagination={false}
        scroll={{
          y: 'calc(100vh - 268px)',
          x: 1600,
        }}
        rowKey="serialCode"
      />
    </>
  );
};

export default BalanceServiceRecordsTable;
