import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Input, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { DynamicObj } from 'interfaces/user.interface';
import { useMutation } from '@apollo/react-hooks';
import {
  messages,
  StyledModal,
  StyledSelect,
  PasswordContainer,
} from 'SuperAdminMain/components/AccountManagementNew/utils';
import { useForm, Controller } from 'react-hook-form';
import useTranslate from 'utils/useTranslate';
import generatePassword from 'generate-password';
import gql from 'graphql-tag';

// ===[ MUTATION ] === //
export const CREATE_ADMIN_OPERATOR = gql`
  mutation createAdminOperator($input: CreateAdminOperatorInput!) {
    createAdminOperator(input: $input)
  }
`;

// ===[ MAIN ] === //
type Props = {
  account: DynamicObj;
};

export const AddOperatorAccount: React.FC<Props> = ({ account }) => {
  const [isShow, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createAdminOperator] = useMutation(CREATE_ADMIN_OPERATOR);
  const translate = useTranslate();
  const {
    register,
    setValue,
    errors,
    handleSubmit,
    reset,
    control,
  } = useForm();

  const handleShow = () => setShow(!isShow);

  const handleReset = () => {
    reset({
      date: undefined,
      time: undefined,
      select: [account.username],
    });

    handleShow();
  };

  const handleGeneratePassword = () => {
    setValue(
      'password',
      generatePassword.generate({
        length: 10,
        numbers: true,
      })
    );
  };

  const onSubmit = async (data: any) => {
    const { username, password } = data;
    setLoading(true);

    try {
      await createAdminOperator({
        variables: {
          input: { adminCode: account.adminCode, username, password },
        },
      });

      message.success(translate(messages['updated-successfully.text']));

      handleReset();
      // eslint-disable-next-line no-empty
    } finally {
    }

    setLoading(false);
  };

  useEffect(() => {
    register(
      { name: 'select' },
      { validate: { positive: (val) => val?.length > 0 } }
    );
    register('status');
    setValue('status', account.status);
  }, [account.status, register, setValue]);

  return (
    <>
      <Button type="link" block onClick={handleShow}>
        <FormattedMessage
          id="add-operator-account-status.text"
          defaultMessage="Add Operator Account"
        />
      </Button>

      <StyledModal
        title={
          <span className="fs-14">
            <FormattedMessage
              id="add-operator-account-status.text"
              defaultMessage="Add Operator Account"
            />
          </span>
        }
        visible={isShow}
        width={640}
        centered
        onCancel={handleReset}
        footer={[
          <Button key="back" onClick={handleReset}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id="update.text" defaultMessage="Update" />
          </Button>,
        ]}
      >
        <FormattedMessage
          id="members-involved.text"
          defaultMessage="Members Involved"
        />
        : 1 <FormattedMessage id="people.text" defaultMessage="People" />
        <StyledSelect
          mode="multiple"
          defaultValue={[account.username]}
          onChange={(e) => setValue('select', e)}
        >
          <StyledSelect.Option key={account.username} value={account.username}>
            {account.username}
          </StyledSelect.Option>
        </StyledSelect>
        {errors.select && (
          <span style={{ color: 'red' }}>
            <FormattedMessage
              id="must-select-1.text"
              defaultMessage="Must select at least 1."
            />
          </span>
        )}
        <br />
        <br />
        <Row style={{ marginBottom: '8px' }}>
          <Col span={8}>
            <StyledSpan style={{ transform: 'translateY(5px)' }}>
              <FormattedMessage
                id="operator-account-id.text"
                defaultMessage="Operator Account ID"
              />
            </StyledSpan>
          </Col>

          <Col span={12}>
            <Controller
              as={Input}
              control={control}
              name="username"
              placeholder={translate(messages['please-key-in.text'])}
              rules={{ required: true, minLength: 6 }}
            />
            {errors.username?.type === 'required' && (
              <span style={{ color: 'red' }}>
                <FormattedMessage
                  id="required.text"
                  defaultMessage="Required."
                />
              </span>
            )}
            {errors.username?.type === 'minLength' && (
              <span style={{ color: 'red' }}>
                <FormattedMessage
                  id="must-be-atleast-6-char.text"
                  defaultMessage="Must be at least 6 characters."
                />
              </span>
            )}
          </Col>
          <Col span={4} />
        </Row>
        {/* */}
        <Row style={{ marginBottom: '8px' }}>
          <Col span={8}>
            <StyledSpan style={{ transform: 'translateY(5px)' }}>
              <FormattedMessage id="password.text" defaultMessage="Password" />
            </StyledSpan>
          </Col>

          <Col span={12}>
            <PasswordContainer>
              <span className="password">
                <Controller
                  as={Input.Password}
                  control={control}
                  name="password"
                  placeholder={translate(messages['please-key-in.text'])}
                  rules={{ required: true, minLength: 6 }}
                />
              </span>

              <span className="generate">
                <Button type="primary" onClick={handleGeneratePassword}>
                  <FormattedMessage
                    id="generate.text"
                    defaultMessage="Generate"
                  />
                </Button>
              </span>
            </PasswordContainer>
            {errors.password?.type === 'required' && (
              <span style={{ color: 'red' }}>
                <FormattedMessage
                  id="password-is-required.text"
                  defaultMessage="Password is required."
                />
              </span>
            )}
            {errors.password?.type === 'minLength' && (
              <span style={{ color: 'red' }}>
                <FormattedMessage
                  id="must-be-atleast-6-char.text"
                  defaultMessage="Must be at least 6 characters."
                />
              </span>
            )}
          </Col>
          <Col span={4} />
        </Row>
        {/* */}
        <Row style={{ marginBottom: '60px' }}>
          <Col span={8}>
            <StyledSpan style={{ transform: 'translateY(5px)' }}>
              <FormattedMessage
                id="super-admin-password.text"
                defaultMessage="Super Admin Password"
              />
            </StyledSpan>
          </Col>

          <Col span={12}>
            <Controller
              as={Input.Password}
              control={control}
              name="superadminPassword"
              placeholder={translate(messages['please-key-in.text'])}
              rules={{ required: true, minLength: 6 }}
            />
            {errors.superadminPassword?.type === 'required' && (
              <span style={{ color: 'red' }}>
                <FormattedMessage
                  id="password-is-required.text"
                  defaultMessage="Password is required."
                />
              </span>
            )}
            {errors.superadminPassword?.type === 'minLength' && (
              <span style={{ color: 'red' }}>
                <FormattedMessage
                  id="must-be-atleast-6-char.text"
                  defaultMessage="Must be at least 6 characters."
                />
              </span>
            )}
          </Col>
          <Col span={4} />
        </Row>
      </StyledModal>
    </>
  );
};

// ===[ STYLE ] === //
const StyledSpan = styled.div`
  font-size: 15px;
  text-align: right;
  margin-right: 24px;
`;
