import styled from 'styled-components';
import { Layout, Table } from 'antd';

const { Content } = Layout;

export const StyledTableContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export const StyledTable = styled(Table)`
  .ant-table-fixed .ant-table-thead {
    background: #fafafa !important;
  }
  .ant-table-small {
    border-top: none !important;
    border-radius: 0;

    .ant-table-content {
      border-bottom: 1px solid #e8e8e8;
    }
  }

  .ant-table-small > .ant-table-content .ant-table-header {
    background-color: #fafafa !important;
  }

  .ant-table-custom-row > td {
    height: 60px;
  }

  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }

  .ant-table-fixed-right .ant-table-body-inner {
    overflow: hidden !important;
  }

  .action-icon {
    .b {
      fill: #1890ff;
    }
  }

  .action-icon-disabled {
    .b {
      fill: rgba(0, 0, 0, 0.38);
      cursor: not-allowed;
    }
  }
`;

export const StyledCenter = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLayout = styled(Layout)`
  height: 100%;
`;

export const StyledContent = styled(Content)`
  padding: 1rem !important;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: calc(100% - 232px);
`;

export const StyledTagContainer = styled.div`
  flex: 1;
`;

export default {};
