import React from 'react';
import { FormattedMessage } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import moment, { Moment } from 'moment';
import MemberProfile from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/MemberProfile';
import { DATE_TIME_FORMAT } from 'constants/date';
import { TranslateProps } from 'interfaces/translate.interface';
import { CurrencyMetadata } from 'types/graphqlTypes';
import messages from './messages';

type Rec = Record<string, any>;

export const DEFAULT_ROW_COUNT = 10;
export const rebateColumns = (
  translate: TranslateProps,
  currency: CurrencyMetadata
) => [
  {
    csvData: {
      label: translate(messages['date-time.text']),
      key: 'dateTimeCreated',
      renderCell: (rebate: Rec) => {
        const temp = coercedGet(rebate, 'dateTimeCreated', '-');
        return temp === '-' ? temp : moment(temp).format(DATE_TIME_FORMAT);
      },
    },
    title: translate(messages['date-time.text']),
    key: 'dateTimeCreated',
    dataIndex: 'dateTimeCreated',
    render: (date: string | Moment) => (
      <span data-testid="dateTimeCreated">
        {moment(date).format(DATE_TIME_FORMAT) || '-'}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['member-id.text']),
      key: 'member-id',
      renderCell: (rebate: Rec) => coercedGet(rebate, 'member.username', '-'),
    },
    title: <FormattedMessage id="member-id.text" defaultMessage="Member ID" />,
    key: 'member-id',
    render: (data: Rec) =>
      data.member ? (
        <MemberProfile member={data.member} />
      ) : (
        <FormattedMessage id="no-data.text" defaultMessage="No Data" />
      ),
  },
  {
    csvData: {
      label: translate(messages['member-affiliate.text']),
      key: 'member-affiliate',
      renderCell: () => translate(messages['not-applicable.text']),
    },
    key: 'member-affiliate',
    title: translate(messages['member-affiliate.text']),
    render: () => translate(messages['not-applicable.text']),
  },
  {
    csvData: {
      label: translate(messages['vip-level.text']),
      key: 'vip-level',
      renderCell: (rebate: Rec) =>
        [
          coercedGet(rebate, 'member.memberLoyaltyLevel.programme.name', ''),
          coercedGet(rebate, 'member.memberLoyaltyLevel.name', ''),
        ].join(' - '),
    },
    title: translate(messages['vip-level.text']),
    key: 'vip-level',
    render: (data: Rec) => (
      <span data-testid="memberLoyaltyLevel">
        {[
          coercedGet(data, 'member.memberLoyaltyLevel.programme.name', ''),
          coercedGet(data, 'member.memberLoyaltyLevel.name', ''),
        ].join(' - ')}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['rebate-programme-name.text']),
      key: 'rebateGroup',
      renderCell: (rebate: Rec) => coercedGet(rebate, 'rebateGroup.name', '-'),
    },
    title: translate(messages['rebate-programme-name.text']),
    key: 'rebateGroup',
    dataIndex: 'rebateGroup',
    render: (data: Rec) => (
      <span data-testid="rebateGroupName">{coercedGet(data, 'name', '')}</span>
    ),
  },
  {
    csvData: {
      label: translate(messages['rebate-programme-tier.text']),
      key: 'rebateProgrammeTier',
      renderCell: () => translate(messages['not-available.text']),
    },
    key: 'rebateProgrammeTier',
    title: translate(messages['rebate-programme-tier.text']),
    render: () => translate(messages['not-available.text']),
  },
  {
    csvData: {
      label: translate(messages['amount-accumulated.text']),
      key: 'totalAmountAccumulated',
      renderCell: (rebate: Rec) => {
        const temp = coercedGet(rebate, 'totalAmountAccumulated', 0);
        return `${currency?.symbol} ${+temp.toFixed(3)}`;
      },
    },
    title: translate(messages['amount-accumulated.text']),
    key: 'totalAmountAccumulated',
    dataIndex: 'totalAmountAccumulated',
    render: (value: number) => (
      <span data-testid="totalAmountAccumulated">
        {currency?.symbol} {+value.toFixed(3)}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['amount-payout.text']),
      key: 'totalAmountPayout',
      renderCell: (rebate: Rec) => {
        const temp = coercedGet(rebate, 'totalAmountPayout', 0);
        return `${currency?.symbol} ${+temp.toFixed(3)}`;
      },
    },
    title: translate(messages['amount-payout.text']),
    key: 'totalAmountPayout',
    dataIndex: 'totalAmountPayout',
    render: (value: number) => (
      <span data-testid="totalAmountPayout">
        {currency?.symbol} {+value.toFixed(3)}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['total-amount-to-be-payout.text']),
      key: 'totalAmountToBePayout',
      renderCell: () => translate(messages['not-available.text']),
    },
    title: translate(messages['total-amount-to-be-payout.text']),
    key: 'totalAmountToBePayout',
    dataIndex: 'totalAmountToBePayout',
    render: () => translate(messages['not-available.text']),
  },
  {
    csvData: {
      label: translate(messages['total-amount-claimed.text']),
      key: 'totalAmountClaimed',
      renderCell: (rebate: Rec) => {
        const temp = coercedGet(rebate, 'totalAmountClaimed', 0);
        return `${currency?.symbol} ${+temp.toFixed(3)}`;
      },
    },
    title: translate(messages['total-amount-claimed.text']),
    key: 'totalAmountClaimed',
    dataIndex: 'totalAmountClaimed',
    render: (value: Rec) => (
      <span data-testid="totalAmountClaimed">
        {currency?.symbol} {+value.toFixed(3)}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['amount-expired.text']),
      key: 'totalAmountExpired',
      renderCell: (promo: Rec) => {
        const temp = coercedGet(promo, 'totalAmountExpired', 0);
        return `${currency?.symbol} ${+temp.toFixed(3)}`;
      },
    },
    title: translate(messages['amount-expired.text']),
    key: 'totalAmountExpired',
    dataIndex: 'totalAmountExpired',
    render: (value: Rec) => (
      <span data-testid="totalAmountExpired">
        {currency?.symbol} {+value.toFixed(3)}
      </span>
    ),
  },
];

export const promoColumns = (
  translate: TranslateProps,
  currency: CurrencyMetadata
) => [
  {
    csvData: {
      label: translate(messages['date-time.text']),
      key: 'dateTimeCreated',
      renderCell: (promo: Rec) => {
        const temp = coercedGet(promo, 'dateTimeCreated', '-');
        return temp === '-' ? temp : moment(temp).format(DATE_TIME_FORMAT);
      },
    },
    title: translate(messages['date-time.text']),
    dataIndex: 'dateTimeCreated',
    key: 'dateTimeCreated',
    render: (date: string | Moment) => (
      <span data-testid="dateTimeCreated">
        {moment(date).format(DATE_TIME_FORMAT) || '-'}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['member-id.text']),
      key: 'member-id',
      renderCell: (promo: Rec) => {
        const temp = coercedGet(promo, 'member.username', '-');
        return temp;
      },
    },
    title: translate(messages['member-id.text']),
    key: 'member-id',
    render: (data: Rec) =>
      data.member ? (
        <MemberProfile member={data.member} />
      ) : (
        <FormattedMessage id="no-data.text" defaultMessage="No Data" />
      ),
  },
  {
    csvData: {
      label: translate(messages['member-affiliate.text']),
      key: 'member-affiliate',
      renderCell: () => translate(messages['not-applicable.text']),
    },
    key: 'member-affiliate',
    title: translate(messages['member-affiliate.text']),
    render: () => translate(messages['not-applicable.text']),
  },
  {
    csvData: {
      label: translate(messages['vip-level.text']),
      key: 'vip-level',
      renderCell: (promo: Rec) =>
        [
          coercedGet(promo, 'member.memberLoyaltyLevel.programme.name', ''),
          coercedGet(promo, 'member.memberLoyaltyLevel.name', ''),
        ].join(' - '),
    },
    title: translate(messages['vip-level.text']),
    key: 'vip-level',
    render: (data: Rec) => (
      <span data-testid="memberLoyaltyLevel">
        {[
          coercedGet(data, 'member.memberLoyaltyLevel.programme.name', ''),
          coercedGet(data, 'member.memberLoyaltyLevel.name', ''),
        ].join(' - ')}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['promo-name.text']),
      key: 'promo-name',
      renderCell: (promo: Rec) => {
        const temp = coercedGet(promo, 'promo.name', '-');
        return temp;
      },
    },
    title: translate(messages['promo-name.text']),
    key: 'promo-name',
    render: (_: any, data: Rec) => (
      <span data-testid="promo.name">{coercedGet(data, 'promo.name', '')}</span>
    ),
  },
  {
    csvData: {
      label: translate(messages['promo-tier.text']),
      key: 'promo-tier',
      renderCell: () => translate(messages['no-data.text']),
    },
    key: 'promo-tier',
    title: translate(messages['promo-tier.text']),
    render: () => translate(messages['no-data.text']),
  },
  {
    csvData: {
      label: translate(messages['amount-accumulated.text']),
      key: 'totalAmountAccumulated',
      renderCell: (promo: Rec) => {
        const temp = coercedGet(promo, 'totalAmountAccumulated', 0);
        return `${currency?.symbol} ${+temp.toFixed(3)}`;
      },
    },
    title: translate(messages['amount-accumulated.text']),
    key: 'totalAmountAccumulated',
    dataIndex: 'totalAmountAccumulated',
    render: (value: Rec) => (
      <span data-testid="totalAmountAccumulated">
        {currency?.symbol} {+value.toFixed(3)}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['amount-payout.text']),
      key: 'totalAmountPayout',
      renderCell: (promo: Rec) => {
        const temp = coercedGet(promo, 'totalAmountPayout', 0);
        return `${currency?.symbol} ${+temp.toFixed(3)}`;
      },
    },
    title: translate(messages['amount-payout.text']),
    key: 'totalAmountPayout',
    dataIndex: 'totalAmountPayout',
    render: (value: number) => (
      <span data-testid="totalAmountPayout">
        {currency?.symbol} {+value.toFixed(3)}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['total-amount-to-be-payout.text']),
      key: 'totalAmountToBePayout',
      renderCell: (promo: Rec) => {
        const temp = coercedGet(promo, 'totalAmountToBePayout', 0);
        return `${currency?.symbol} ${+temp.toFixed(3)}`;
      },
    },
    title: translate(messages['total-amount-to-be-payout.text']),
    key: 'totalAmountToBePayout',
    dataIndex: 'totalAmountToBePayout',
    render: (value: number) => (
      <span data-testid="totalAmountToBePayout">
        {currency?.symbol} {+value.toFixed(3)}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['total-amount-claimed.text']),
      key: 'totalAmountClaimed',
      renderCell: (promo: Rec) => {
        const temp = coercedGet(promo, 'totalAmountClaimed', 0);
        return `${currency?.symbol} ${+temp.toFixed(3)}`;
      },
    },
    title: translate(messages['total-amount-claimed.text']),
    key: 'totalAmountClaimed',
    dataIndex: 'totalAmountClaimed',
    render: (value: number) => (
      <span data-testid="totalAmountClaimed">
        {currency?.symbol} {+value.toFixed(3)}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['amount-expired.text']),
      key: 'totalAmountExpired',
      renderCell: (promo: Rec) => {
        const temp = coercedGet(promo, 'totalAmountExpired', 0);
        return `${currency?.symbol} ${+temp.toFixed(3)}`;
      },
    },
    title: translate(messages['amount-expired.text']),
    key: 'totalAmountExpired',
    dataIndex: 'totalAmountExpired',
    render: (value: number) => (
      <span data-testid="totalAmountExpired">
        {currency?.symbol} {+value.toFixed(3)}
      </span>
    ),
  },
  {
    csvData: {
      label: translate(messages['clawback.text']),
      key: 'clawback',
      renderCell: () => translate(messages['no-data.text']),
    },
    title: translate(messages['clawback.text']),
    key: 'clawback',
    render: () => translate(messages['no-data.text']),
  },
];
