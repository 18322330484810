import supportedTimezones from 'constants/supportedTimezones';
import uuid from 'uuid';
import * as moment from 'moment-timezone';
import { Locale } from 'store/accountState';

const GetSortedTimezones = (isShortVersion: boolean, locale: Locale = 'en') =>
  supportedTimezones[locale]
    .map(zoneName => {
      // when tzName is Asia/Beijing we use Asia/Shanghai offset at the same time retaining the Asia/Beijing Value to be sent to the server
      // this is necessary because moment doesnt support Asia/Beijing
      const isBeijing = zoneName === 'Asia/Beijing';
      const offsetArg = isBeijing ? 'Asia/Shanghai' : zoneName;

      const getParsedEstOffset = () => {
        const timeStr = moment
          .tz(offsetArg)
          .locale('zh-cn')
          .format('Z')
          .slice(0, 3);
        const timeInt = parseInt(timeStr, 10) - 4;

        const converToString = (myInt: number) =>
          Number(Math.abs(myInt)).toString();
        if (timeInt === 0) return '+00:00';
        if (timeInt > 0 && timeInt < 10)
          return `+0${converToString(timeInt)}:00`;
        if (timeInt >= 10) return `+${converToString(timeInt)}:00`;
        if (timeInt < 0 && Math.abs(timeInt) < 10)
          return `-0${converToString(timeInt)}:00`;
        if (timeInt < 0 && Math.abs(timeInt) >= 10)
          return `-${converToString(timeInt)}:00`;

        return `${timeInt}:00`;
      };

      const label = isShortVersion
        ? `(GMT${moment
            .tz(offsetArg)
            .locale('zh-cn')
            .format('Z')}) ${zoneName}`
        : `(GMT${moment
            .tz(offsetArg)
            .locale('zh-cn')
            .format('Z')}) (EST${getParsedEstOffset()}) ${zoneName}`;

      return {
        label,
        name: zoneName,
        id: uuid(),
      };
    })
    .sort((a, b) => {
      const re = /^\(GMT([+-]\d{1,2}):(\d{1,2})\).*$/;

      const aOffset = parseFloat(a.label.replace(re, '$1.$2'));
      const bOffset = parseFloat(b.label.replace(re, '$1.$2'));
      if (aOffset < bOffset) {
        return -1;
      }
      if (aOffset > bOffset) {
        return 1;
      }
      return 0;
    });

export default GetSortedTimezones;
