import styled from 'styled-components';
import { Card } from 'antd';
import Block from 'react-blocks';

export const StyledContent = styled.div`
  max-width: 1140px;
  margin: 0 auto;
`;

export const StyledBlock = styled(Block)`
  position: relative;
  flex-grow: 1 !important;
`;

export const StyledCard = styled(Card)`
  box-shadow: 0px 1px 1px 0.5px rgba(0, 0, 0, 0.2);

  & > .ant-card-body {
    display: flex;
    margin: 10px 0;
    padding-top: 0;
  }
`;

export const StyledBackground = styled.div`
  background: #f0f2f5;
  padding: 15px 0 15px 0;
  width: 100%;
`;

export const StyledArrow = styled.div`
  margin-top: 35px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 20px solid #f0f2f5;
  position: absolute;
  left: calc(50% - 13px);
`;

export default {};
