import esget from 'utils/esGet';
import { defineMessages } from 'react-intl';
import messages from 'pages/components/MemberWithdrawals/messages';
import { Withdrawal } from 'types/graphqlTypes';
import { subtract, sum, values, round } from 'lodash';
import globalMessages from 'messages';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/date';

const messagesLocal = defineMessages({
  'first-withdrawal.text': {
    id: 'first-withdrawal.text',
    defaultMessage: 'First Withdrawal',
  },
  'serial-code.text': {
    id: 'serial-code.text',
    defaultMessage: 'Serial Code',
  },
  'affiliate.text': {
    id: 'affiliate.text',
    defaultMessage: 'Affiliate',
  },
  'member-account.text': {
    id: 'member-account.text',
    defaultMessage: 'Member Account',
  },
  'vip-level.text': {
    id: 'vip-level.text',
    defaultMessage: 'VIP Level',
  },
  'manual-adjustment.text': {
    id: 'manual-adjustment.text',
    defaultMessage: 'Manual Adjustment',
  },
});

const isFailed = (value: string) => value === 'FAILED';

const computePayoutAmount = (withdrawal: Withdrawal) => {
  const {
    handlingFeeApplied,
    withdrawalTaxFeeApplied,
    promoDeductionFeeApplied,
    withdrawalLimitFeeApplied,
    amount,
  } = withdrawal;

  const newValue = values({
    handling: handlingFeeApplied || 0,
    tax: withdrawalTaxFeeApplied || 0,
    promoDeduction: promoDeductionFeeApplied || 0,
    limit: withdrawalLimitFeeApplied || 0,
  });
  const newPayoutAmount = subtract(amount, sum(newValue));
  const displayedPayoutAmount =
    newPayoutAmount < 0 ? 0 : round(newPayoutAmount, 2);

  return displayedPayoutAmount;
};

const getProcessingTime = (withdrawal: Withdrawal) => {
  const { dateTimeCreated, dateTimeProcessed, status } = withdrawal;

  if (status === 'APPROVED' || status === 'REJECTED')
    return moment(dateTimeProcessed).from(dateTimeCreated, true);
  if (status === 'FAILED') return '-';

  return moment(dateTimeCreated).fromNow();
};

const getCsvHeaders = (translate: any) => [
  {
    label: translate(messagesLocal['first-withdrawal.text']),
    key: 'firstWithdrawal',
    renderCell: (withdrawal: Withdrawal) =>
      esget(withdrawal?.firstWithdrawal, '-')
        ? translate(messages.yes)
        : translate(messages.no),
  },

  {
    label: translate(messagesLocal['serial-code.text']),
    key: 'serialCode',
    renderCell: (withdrawal: Withdrawal) => esget(withdrawal?.serialCode, '-'),
  },
  {
    label: translate(messagesLocal['affiliate.text']),
    key: 'affiliate',
    renderCell: (withdrawal: Withdrawal) =>
      esget(withdrawal?.account?.affiliate?.code, '-'),
  },
  {
    label: translate(messagesLocal['member-account.text']),
    key: 'username',
    renderCell: (withdrawal: Withdrawal) =>
      esget(withdrawal?.account?.username, '-'),
  },
  {
    label: 'Brand ID',
    key: 'brandId',
    renderCell: (withdrawal: Withdrawal) =>
      esget(withdrawal?.account?.brandId, '-'),
  },
  {
    label: 'Platform ID',
    key: 'platformId',
    renderCell: (withdrawal: Withdrawal) =>
      esget(withdrawal?.account?.platformId, '-'),
  },
  {
    label: translate(messages.VIP_TIER),
    key: 'vipLevel',
    renderCell: (withdrawal: Withdrawal) => {
      const memberLevels = esget(withdrawal?.account?.memberLoyaltyLevels, '-');
      return memberLevels?.map((item) => item.name);
    },
  },
  {
    label: translate(messages.memberMarker),
    key: 'memberMarker',
    renderCell: (withdrawal: Withdrawal) =>
      esget(withdrawal?.account?.memberLevel?.name, '-'),
  },
  {
    label: translate(messages.feeDeduction),
    key: 'feeDeduction',
    renderCell: (withdrawal: Withdrawal) => {
      const {
        handlingFeeApplied,
        withdrawalTaxFeeApplied,
        promoDeductionFeeApplied,
        withdrawalLimitFeeApplied,
        handlingFee,
        withdrawalTaxFee,
        promoDeductionFee,
        withdrawalLimitFee,
      } = withdrawal;

      const handling =
        handlingFeeApplied === null ? handlingFee : handlingFeeApplied;
      const tax =
        withdrawalTaxFeeApplied === null
          ? withdrawalTaxFee
          : withdrawalTaxFeeApplied;
      const promoDeduction =
        promoDeductionFeeApplied === null
          ? promoDeductionFee
          : promoDeductionFeeApplied;
      const limit =
        withdrawalLimitFeeApplied === null
          ? withdrawalLimitFee
          : withdrawalLimitFeeApplied;

      return `${translate(messages.handlingFee)}: ${handling ||
        0.0} | ${translate(messages.withdrawalTax)}: ${tax || 0} | ${translate(
        messages.promoDeduction
      )}: ${promoDeduction || 0.0} | ${translate(
        messages.withdrawalLimitFee
      )}: ${limit || 0.0}`;
    },
  },
  {
    label: translate(messages.complianceCheck),
    key: 'complianceCheck',
    renderCell: (withdrawal: Withdrawal) => {
      const {
        oneTimeTurnoverDetectionCheck,
        promoHunterCheck,
        contraBetCheck,
        ipCollisionOffenderCheck,
        martingaleBetOffenderCheck,
      } = withdrawal;
      const fails = [];

      if (isFailed(oneTimeTurnoverDetectionCheck))
        fails.push(translate(messages.oneTimeTurnoverDetection));
      if (isFailed(promoHunterCheck))
        fails.push(translate(messages.promoHunter));
      if (isFailed(contraBetCheck)) fails.push(translate(messages.contraBet));
      if (isFailed(ipCollisionOffenderCheck))
        fails.push(translate(messages.ipCollisionOffender));
      if (isFailed(martingaleBetOffenderCheck))
        fails.push(translate(messages.martingaleBetOffender));

      return fails;
    },
  },
  {
    label: translate(messages.withdrawalAmount),
    key: 'payoutAmount',
    renderCell: (withdrawal: Withdrawal) => computePayoutAmount(withdrawal),
  },
  {
    label: 'HexoPay Transaction UID',
    key: 'hexoPayUid',
    renderCell: (withdrawal: Withdrawal) => esget(withdrawal?.hexoPayUid, '-'),
  },
  {
    label: translate(globalMessages.PAYMENT_SOURCE),
    key: 'withdrawalMethod',
    renderCell: (withdrawal: Withdrawal) =>
      translate({
        id: `${esget(withdrawal?.status, '').toLowerCase()}.text`,
      }),
  },
  {
    label: translate(messages.status),
    key: 'status',
    renderCell: (withdrawal: Withdrawal) =>
      translate({
        id: `${esget(withdrawal?.status, '').toLowerCase()}.text`,
      }),
  },
  {
    label: translate(globalMessages.LABELS_REMARKS),
    key: 'remarks',
    renderCell: (withdrawal: Withdrawal) =>
      withdrawal?.externalRemarks || withdrawal?.remarks,
  },
  {
    label: translate(globalMessages['request-datetime.text']),
    key: 'requestDate',
    renderCell: (withdrawal: Withdrawal) =>
      `${moment(withdrawal?.dateTimeCreated).format(DATE_FORMAT)} - ${moment(
        withdrawal?.dateTimeCreated
      ).format('HH:mm:ss')}`,
  },
  {
    label: translate(globalMessages.ELAPSED_TIME),
    key: 'processingTime',
    renderCell: (withdrawal: Withdrawal) => getProcessingTime(withdrawal),
  },
];

export default getCsvHeaders;
