import { useQuery } from '@apollo/react-hooks';
import Layout from 'components/Layout';
import { useFilterValues } from 'contexts/Filters';
import { WITHDRAWAL_PROVIDERS } from 'graphql/queries/withdrawalProvider.query';
import { Page } from 'interfaces/user.interface';
import React, { useState } from 'react';
import { transformMovableDateFilter } from 'utils/dateUtils';
import esGet from 'utils/esGet';
import HeaderLeft from '../HeaderLeft';
import HeaderRight from '../HeaderRight';
import SideBar from '../SideBar';
import WithdrawalProvidersTable from '../WithdrawalProvidersTable';

const WithdrawalProvidersScreen = () => {
  const [page, setPage] = useState<Page>({
    first: 10,
    after: undefined,
    savedCursor: [''],
    currentPage: 0,
  });

  const { filters } = useFilterValues();

  const refetchVariables = {
    first: page.first,
    after: page.after,
    filter: transformMovableDateFilter(filters),
  };

  const { loading, data } = useQuery(WITHDRAWAL_PROVIDERS, {
    variables: refetchVariables,
  });

  const edges = esGet(data?.withdrawalProviders?.edges, []);
  const totalCount = esGet(data?.withdrawalProviders?.totalCount, []);
  const pageInfo = esGet(data?.withdrawalProviders?.pageInfo, []);
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  return (
    <Layout.Container>
      <>
        <Layout.Header leftNode={<HeaderLeft />} rightNode={<HeaderRight />} />
        <Layout.Content
          sideBar={<SideBar />}
          footer={
            <Layout.Footer
              leftNode={<div />}
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage as any}
              pageInfo={pageInfo}
            />
          }
        >
          <WithdrawalProvidersTable
            dataSource={edges}
            loading={loading}
            refetchVariables={refetchVariables}
          />
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

export default WithdrawalProvidersScreen;
