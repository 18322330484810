// import messages from 'messages';
import { PaymentMethod } from 'types/graphqlTypes';
import esget from 'utils/esGet';
import messagesLocal from 'pages/components/PaymentMethodListing/messages';
import { getPaymentMethodType } from 'pages/components/PaymentMethodListing/utils';
import { getBank, messages as bankMessages } from 'constants/banks';

const getCsvHeaders = (translate: any) => [
  {
    label: translate(messagesLocal.name),
    key: 'name',
    renderCell: (paymentMethod: PaymentMethod) => esget(paymentMethod.name, ''),
  },
  {
    label: translate(messagesLocal.paymentSource),
    key: 'paymentSource',
    renderCell: (paymentMethod: any) =>
      esget(paymentMethod?.depositProvider, '')
        ? translate(messagesLocal.thirdparty)
        : translate(messagesLocal.internal),
  },
  {
    label: translate(messagesLocal.provider),
    key: 'depositProvider',
    renderCell: (paymentMethod: any) =>
      esget(paymentMethod?.depositProvider?.name, ''),
  },
  {
    label: translate(messagesLocal.paymentmethodtype),
    key: 'paymentMethodType',
    renderCell: (paymentMethod: any) => getPaymentMethodType(paymentMethod),
  },
  {
    label: `${translate(messagesLocal.bankname)} / ${translate(
      messagesLocal.nickname
    )}`,
    key: 'bankName',

    renderCell: (paymentMethod: any) => {
      const { bank, nickname, blockchainAddress } = paymentMethod;

      if (bank) {
        const newBank = getBank(bank);
        return `${
          newBank && newBank.label !== 'OTHER' ? `[${newBank.label}] ` : ''
        } ${newBank && translate(bankMessages[newBank.label])}`;
      }
      if (nickname) return nickname;
      if (blockchainAddress) return blockchainAddress;

      return '-';
    },
  },
  {
    label: translate(messagesLocal.currentAccumulation),
    key: 'currentAccumulation',
    renderCell: (paymentMethod: any) =>
      esget(paymentMethod?.currentAccumulation, ''),
  },
  {
    label: translate(messagesLocal.dailyDepositLimit),
    key: 'dailyDepositLimit',
    renderCell: (paymentMethod: any) =>
      esget(paymentMethod?.maximumDepositAmountPerDay, 0) < 0
        ? '-'
        : esget(paymentMethod?.maximumDepositAmountPerDay, 0),
  },
  {
    label: translate(messagesLocal.status),
    key: 'status',
    renderCell: (paymentMethod: any) =>
      esget(paymentMethod?.enabled, '0')
        ? translate(messagesLocal.enable)
        : translate(messagesLocal.disable),
  },
  {
    label: translate(messagesLocal.remarks),
    key: 'remarks',
    renderCell: (paymentMethod: any) => esget(paymentMethod?.remarks, ''),
  },
];

export default getCsvHeaders;
