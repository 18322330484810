import styled from 'styled-components';

export const Wrapper = styled.div`
  .date-ranges {
    margin-left: auto;
  }

  .date-ranges a {
    color: rgba(0, 0, 0, 0.65);
  }

  .date-ranges a.active {
    color: #1890ff !important;
    text-decoration: none;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
  }

  .ant-picker-range {
    width: 250px;
    border: none;
  }

  .ant-picker-range-separator {
    transform: translateX(-8px);
  }

  .ant-picker-input > input {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export default {};
