import { useMutation } from '@apollo/react-hooks';
import { Button, message, Popconfirm } from 'antd';
import { APPROVE_WITHDRAWAL_NEXT } from 'graphql/mutations/withdrawal.mutation';
import messages from 'pages/components/MemberWithdrawals/messages';
import { defaultPaymentSource } from 'pages/components/MemberWithdrawals/utils';
import React from 'react';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';

type Props = {
  id: string;
  status: string;
  refetchQueries: any;
  setRemarksError: () => void;
  toggleModal: () => void;
  setWithdrawalMethodError: (data?: any) => void;
  withdrawalMethodId: string;
  paymentSourceType: string;
};

const ApproveWithdrawal = (props: Props) => {
  const {
    id,
    status,
    refetchQueries,
    setRemarksError,
    toggleModal,
    setWithdrawalMethodError,
    withdrawalMethodId,
    paymentSourceType,
  } = props;
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [approveWithdrawalNext, { loading }] = useMutation(
    APPROVE_WITHDRAWAL_NEXT,
    {
      onCompleted: () => {
        message.success(translate(messages.withdrawalApproved));
        setRemarksError();
        setWithdrawalMethodError();
        toggleModal();

        const storeDefaultValues = {
          withdrawalMethodId,
          paymentSourceType,
        };

        defaultPaymentSource.setValues(storeDefaultValues);
      },
      onError: () => {
        setRemarksError();
        setWithdrawalMethodError();
      },
      variables: { id },
      refetchQueries,
      context,
    }
  );

  return (
    <>
      {(status === 'PROCESSING' ||
        status === 'EXPIRED' ||
        status === 'ON_HOLD') && (
        <Popconfirm
          title={translate(messages.areYouSure)}
          onConfirm={() => {
            if (withdrawalMethodId) approveWithdrawalNext();
            else
              setWithdrawalMethodError(
                translate(messages.requiredApproveRemarkError)
              );
          }}
          onCancel={() => {}}
          okText={translate(messages.yes)}
          cancelText={translate(messages.no)}
        >
          <Button
            disabled={loading}
            loading={loading}
            style={{ color: '#52C41A', borderColor: '#52C41A' }}
          >
            {translate(messages.approve)}
          </Button>
        </Popconfirm>
      )}
    </>
  );
};

export default ApproveWithdrawal;
