import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Row, Col, Input, message } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useConfig } from 'hooks/useConfig';
import * as yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';

type FormValues = {
  xtpAppToken: string;
};

const noWhitespaceAndRequired = yup
  .string()
  .nullable()
  .required('Field is required')
  .matches(/^\S+$/, 'No whitespace allowed');

const validationSchema = yup.object().shape({
  xtpAppToken: noWhitespaceAndRequired,
});

const XtremePushConfig = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const { xtpAppToken, refetch } = useConfig();

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      xtpAppToken: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const [updateXtpAppToken, { loading }] = useMutation(UPDATE_CONFIG);

  const handleClose = () => {
    reset();
    setModalVisible((prev) => !prev);
  };

  const onUpdateAppToken = async (values: FormValues) => {
    try {
      await updateXtpAppToken({
        variables: {
          input: {
            xtpAppToken: values.xtpAppToken,
          },
        },
      });
      refetch();
      message.success('Xtreme Push App Token updated successfully.');
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    if (xtpAppToken) reset({ xtpAppToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xtpAppToken]);

  return (
    <div>
      <h3>XtremePush Config</h3>
      <Button onClick={() => setModalVisible(true)}>
        Configure XtremePush
      </Button>

      <Modal
        destroyOnClose
        title="Xtreme Push Config"
        centered
        closable={false}
        visible={modalVisible}
        onCancel={handleClose}
        okText="Update"
        onOk={handleSubmit(onUpdateAppToken)}
        okButtonProps={{ loading }}
      >
        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item
                label="Xtreme Push App Token"
                validateStatus={errors.xtpAppToken && 'error'}
                help={errors.xtpAppToken && errors.xtpAppToken.message}
              >
                <Controller
                  control={control}
                  name="xtpAppToken"
                  render={({ onChange, value }) => (
                    <Input type="url" onChange={onChange} value={value} />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default XtremePushConfig;
