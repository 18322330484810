import { useMutation } from '@apollo/react-hooks';
import { Button, message, Steps } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  CREATE_MEMBER_LOYALTY_PROGRAMME,
  SUBMIT_MEMBER_LOYALTY_PROGRAMME,
  UPDATE_MEMBER_LOYALTY_PROGRAMME,
} from 'graphql/mutations/vip.mutation';
import {
  MEMBER_LOYALTY_PROGRAMMES,
  QUALIFYING_MEMBER_LOYALTY_LEVELS,
} from 'graphql/queries/promo.query';
import { MEMBER_LOYALTY_PROGRAMS } from 'graphql/queries/vip.query';
import { get, isEmpty, omit, pick } from 'lodash';
import Drawer from 'pages/components/common/Drawer/Drawer';
import { useMemberLoyaltyProgrammeContext } from 'pages/components/MemberLoyaltyManagement/context';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import { usePermissions } from 'store/accountState';
import coercedGet from 'utils/coercedGet';
import getFieldsFromSchema from 'utils/getFieldsFromSchema';
import removeNull from 'utils/removeNull';
import removeTypename from 'utils/removeTypename';
import useTranslate from 'utils/useTranslate';
import AddTierForms from './components/AddTierForms';
import CancelConfirm from './components/CancelConfirm';
import NewProgramSettings from './components/NewProgramSettings';
import TierSettings from './components/TierSettings';
import {
  allTypes,
  CreateLoyaltyProgramScreens,
  getNewProgrammeSchema,
  getNewProgrammeSchemaObject,
  ProgrammeStatus,
  useCreateLoyaltyProgramContext,
  usePrimaryVipContext,
} from './context';
import messages from './messages';
import { StyledDrawerFooter } from './styles';

const { Step } = Steps;

type Props = {
  show: boolean;
  toggleOverlay: () => void;
};

export default function CreateProgramme({ show, toggleOverlay }: Props) {
  const translate = useTranslate();
  const { role, permissions } = usePermissions();
  const { ALLOWED_SUBMIT } = collectPermissions(
    role,
    permissions,
    ['SUBMIT'],
    ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  );
  const [saveOnlyOnce, setSaveOnlyOnce] = React.useState(false);

  const handleOverlay = React.useCallback(() => {
    setSaveOnlyOnce(false);
    toggleOverlay();
  }, [toggleOverlay, setSaveOnlyOnce]);

  const addTierFormsRef = React.useRef() as any;
  const NewProgramSettingsRef = React.useRef() as any;

  const [{ refetchVariables }] = useMemberLoyaltyProgrammeContext();

  const [
    contextState,
    dispatch,
    getValidationSchema,
  ] = useCreateLoyaltyProgramContext() as any;

  const { activeScreen } = contextState;

  const createLoyaltyScreensArray = Object.values(CreateLoyaltyProgramScreens);

  const activeScreenIndex =
    createLoyaltyScreensArray.findIndex((screen) => screen === activeScreen) ||
    0;

  const [createProgrammeMutation, { loading: createLoading }] = useMutation(
    CREATE_MEMBER_LOYALTY_PROGRAMME
  );
  const [updateProgrammeMutation, { loading: updateLoading }] = useMutation(
    UPDATE_MEMBER_LOYALTY_PROGRAMME
  );
  const [submitProgrammeMutation, { loading: submitLoading }] = useMutation(
    SUBMIT_MEMBER_LOYALTY_PROGRAMME
  ) as any;

  const mutateIsLoading = createLoading || updateLoading || submitLoading;

  const omitIsSingleObject = (criteria: Record<string, any>) => {
    if (
      criteria &&
      criteria.type === 'BASE' &&
      criteria.and &&
      criteria.and.length > 0
    ) {
      const newAnd = criteria.and.map((elem: Record<string, any>) => {
        if (elem.type === 'EFFECTIVE_BET') {
          return omit(elem, ['isSingleObject']);
        }
        return elem;
      });

      return {
        ...criteria,
        and: newAnd,
      };
    }
    return criteria;
  };

  const submitTypes = {
    SAVE_ONLY: 'SAVE_ONLY',
    SAVE_AND_EXIT: 'SAVE_AND_EXIT',
    PUBLISH_DRAFT: 'PUBLISH_DRAFT',
    UPDATE_EXISITING_PROGRAM: 'UPDATE_EXISITING_PROGRAM',
  };
  const isPrimaryVip = usePrimaryVipContext() as any;

  const handleSave = React.useCallback(
    (exit = true, submitType = 'UPDATE_EXISITING_PROGRAM') => {
      const includedFields = getFieldsFromSchema(
        getNewProgrammeSchemaObject(translate, isPrimaryVip)
      );

      const programmeId = get(contextState.activeProgramme, 'id');

      const status = get(contextState.activeProgramme, 'status');
      const mutate: any = !programmeId
        ? createProgrammeMutation
        : updateProgrammeMutation;

      const activeProgramIsNull = contextState.activeProgramme === null;

      const getRawInput = () => {
        if (activeProgramIsNull) {
          return pick(NewProgramSettingsRef.current.getFormValues(), [
            ...includedFields,
            'levels',
          ]);
        }

        if (!activeProgramIsNull) {
          if (activeScreen === CreateLoyaltyProgramScreens.PROGRAM_SETTINGS) {
            return pick(
              {
                ...contextState.activeProgramme,
                ...NewProgramSettingsRef.current.getFormValues(),
              },
              [...includedFields, 'levels']
            );
          }
        }
        return pick(contextState.activeProgramme, [
          ...includedFields,
          'levels',
        ]);
      };

      const rawInput = getRawInput();

      const currentDateSource = activeProgramIsNull
        ? NewProgramSettingsRef.current.getFormValues()
        : contextState.activeProgramme;

      // higher index is equivalent to a higher loyalty level
      const mutationInput =
        currentDateSource.validityStartDate && currentDateSource.validityEndDate
          ? rawInput
          : omit(rawInput, ['validityStartDate', 'validityEndDate']);

      const isSaveOnProgSettingsScreen =
        submitType === 'SAVE_ONLY' &&
        activeScreen === CreateLoyaltyProgramScreens.PROGRAM_SETTINGS;

      const progSettingsFormValue = coercedGet(
        NewProgramSettingsRef,
        'current.getFormValues',
        () => {}
      )();

      const schemaPromise = Promise.resolve(
        getNewProgrammeSchema(translate, isPrimaryVip)
      );

      const yieldedPromise = isSaveOnProgSettingsScreen
        ? schemaPromise.then((schema) => {
            NewProgramSettingsRef.current.validateForm().then((errors: any) => {
              if (!isEmpty(errors)) {
                NewProgramSettingsRef.current.handleSubmit();
              }
            });

            return schema.validate(progSettingsFormValue);
          })
        : getValidationSchema(translate, isPrimaryVip).validate({
            ...progSettingsFormValue,
            ...contextState.activeProgramme,
            levels: contextState.tiers,
          });

      yieldedPromise
        .then(async () => {
          const variables = removeTypename({
            ...(programmeId ? { id: programmeId } : {}),
            // reverse for proper ordering on BE
            input: {
              ...mutationInput,
              levels: [...contextState.tiers].reverse().map((item, index) => {
                const isNotLastorFirstIndex =
                  index !== coercedGet(contextState, 'tiers.length', 0) - 1 ||
                  index === 0;

                if (isNotLastorFirstIndex && item.inviteOnly) {
                  throw new Error(translate(messages.INVITE_ONLY_ERROR));
                }

                return removeNull({
                  ...item,
                  qualificationCriteria: omitIsSingleObject(
                    item.qualificationCriteria
                  ),
                });
              }),
            },
          });
          const publishedSuccessMessage = () =>
            message.success(
              translate(
                messages[
                  'member-loyalty.loyalty-programme-publish-success.text'
                ]
              )
            );
          try {
            if (saveOnlyOnce && submitType !== 'SAVE_ONLY') {
              await submitProgrammeMutation({
                variables: {
                  id: programmeId,
                },
                refetchQueries: [
                  {
                    query: MEMBER_LOYALTY_PROGRAMS,
                    fetchPolicy: 'network-only',
                    variables: refetchVariables,
                  },
                  {
                    query: QUALIFYING_MEMBER_LOYALTY_LEVELS,
                    fetchPolicy: 'network-only',
                  },
                  {
                    query: MEMBER_LOYALTY_PROGRAMMES,
                    fetchPolicy: 'network-only',
                  },
                ],
              });
              publishedSuccessMessage();
              return;
            }
            // console.log({ variables });
            const response = await mutate({
              variables,
              refetchQueries: [
                {
                  query: MEMBER_LOYALTY_PROGRAMS,
                  fetchPolicy: 'network-only',
                  variables: refetchVariables,
                },
              ],
            });

            if (!programmeId && submitType === 'SAVE_ONLY') {
              const newProgramId = get(
                response.data,
                'createMemberLoyaltyProgramme'
              );

              dispatch({
                type: allTypes.SET_CURRENT_PROGRAM_ID,
                payload: newProgramId,
              });

              dispatch({
                type: allTypes.SET_ACTIVE_PROGRAMME,
                payload: {
                  ...variables.input,
                  id: newProgramId,
                },
              });
              dispatch({
                type: allTypes.SET_TIERS,
                payload: [...variables.input.levels].reverse(),
              });
              message.success(
                <FormattedMessage
                  id="member-loyalty.details-saved.text"
                  defaultMessage="Details Saved."
                />
              );
              setSaveOnlyOnce(true);
              return;
            }

            // save and exit
            if (submitType === 'SAVE_AND_EXIT') {
              if (status !== ProgrammeStatus.DRAFT) {
                message.success(
                  translate(
                    messages['member-loyalty.programme-details-saved.text']
                  )
                );

                return;
              }

              message.success(
                translate(
                  messages['member-loyalty.programme-draft-created.text']
                )
              );

              return;
            }

            if (
              status === ProgrammeStatus.DRAFT &&
              programmeId &&
              submitType === 'PUBLISH_DRAFT'
            ) {
              // publishing draft w/ ID

              await submitProgrammeMutation({
                variables: {
                  id: programmeId,
                },
                refetchQueries: [
                  {
                    query: MEMBER_LOYALTY_PROGRAMS,
                    fetchPolicy: 'network-only',
                    variables: refetchVariables,
                  },
                  {
                    query: QUALIFYING_MEMBER_LOYALTY_LEVELS,
                    fetchPolicy: 'network-only',
                  },
                  {
                    query: MEMBER_LOYALTY_PROGRAMMES,
                    fetchPolicy: 'network-only',
                  },
                ],
              });

              publishedSuccessMessage();

              return;
            }

            if (!programmeId && submitType === 'PUBLISH_DRAFT') {
              // publishing DRAFT w/o ID, aka Fresh Publish

              const newProgramId = get(
                response.data,
                'createMemberLoyaltyProgramme'
              );
              await submitProgrammeMutation({
                variables: {
                  id: newProgramId,
                },
                refetchQueries: [
                  {
                    query: MEMBER_LOYALTY_PROGRAMS,
                    fetchPolicy: 'network-only',
                    variables: refetchVariables,
                  },
                  {
                    query: QUALIFYING_MEMBER_LOYALTY_LEVELS,
                    fetchPolicy: 'network-only',
                  },
                  {
                    query: MEMBER_LOYALTY_PROGRAMMES,
                    fetchPolicy: 'network-only',
                  },
                ],
              });

              publishedSuccessMessage();
              return;
            }

            // update program
            message.success(
              translate(messages['member-loyalty.programme-details-saved.text'])
            );
          } finally {
            if (exit) {
              handleOverlay();
            }
          }
        })
        .catch((err: any) => {
          const errMessage = err?.graphQLErrors[0]?.message || '';

          const noNameError =
            'GraphQL error: should NOT be shorter than 1 characters';

          const noTierError = 'Cannot update VIP Programme with empty tiers';
          if (err.message === noNameError) {
            message.error(
              translate(
                messages['member-loyalty.name-is-a-required-field.text']
              )
            );
            return;
          }

          if (err.message === noTierError) {
            message.warning(
              translate(
                messages[
                  'member-loyalty.programme-has-no-levels-will-set-as-draft'
                ]
              )
            );

            return;
          }
          message.error(errMessage);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      translate,
      isPrimaryVip,
      contextState,
      createProgrammeMutation,
      updateProgrammeMutation,
      activeScreen,
      getValidationSchema,
      saveOnlyOnce,
      refetchVariables,
      submitProgrammeMutation,
      dispatch,
      handleOverlay,
    ]
  );

  const handleDisplay = (actionType: string) => () => {
    if (actionType === 'PREV') {
      switch (activeScreen) {
        case createLoyaltyScreensArray[0]:
          return null;

        case createLoyaltyScreensArray[1]:
          return dispatch({
            type: allTypes.SET_ACTIVE_SCREEN,
            payload: createLoyaltyScreensArray[activeScreenIndex - 1],
          });

        case createLoyaltyScreensArray[2]:
          return dispatch({
            type: allTypes.SET_ACTIVE_SCREEN,
            payload: createLoyaltyScreensArray[activeScreenIndex - 1],
          });

        default:
          return null;
      }
    }

    return null;
  };

  const renderForms = () => {
    switch (activeScreen) {
      case CreateLoyaltyProgramScreens.PROGRAM_SETTINGS:
        return <NewProgramSettings ref={NewProgramSettingsRef} />;

      case CreateLoyaltyProgramScreens.TIER_SETTINGS:
        return <TierSettings resetSaveOnly={() => setSaveOnlyOnce(false)} />;

      case CreateLoyaltyProgramScreens.ADD_TIER_FORMS:
        return <AddTierForms ref={addTierFormsRef} />;

      default:
        return <NewProgramSettings ref={NewProgramSettingsRef} />;
    }
  };

  const renderPrevButtons = () => {
    switch (activeScreen) {
      case CreateLoyaltyProgramScreens.PROGRAM_SETTINGS:
        return null;
      case CreateLoyaltyProgramScreens.ADD_TIER_FORMS:
        return (
          <Button
            onClick={() => addTierFormsRef.current.handleTab('PREV')}
            size="large"
          >
            <FormattedMessage id="back.text" defaultMessage="Back" />
          </Button>
        );

      default:
        return (
          <Button onClick={handleDisplay('PREV')} size="large">
            <FormattedMessage id="previous.text" defaultMessage="Previous" />
          </Button>
        );
    }
  };

  // ===================

  const renderNextButtons = () => {
    switch (activeScreen) {
      case CreateLoyaltyProgramScreens.PROGRAM_SETTINGS:
        return (
          <Button
            onClick={async (e) => {
              e.preventDefault();
              NewProgramSettingsRef.current.handleSubmit();
            }}
            size="large"
            type="primary"
          >
            <FormattedMessage id="next.text" defaultMessage="Next" />
          </Button>
        );

      case CreateLoyaltyProgramScreens.TIER_SETTINGS:
        return (
          <Button
            size="large"
            type="primary"
            disabled={!ALLOWED_SUBMIT}
            loading={mutateIsLoading}
            onClick={async () => {
              if (
                get(contextState.activeProgramme, 'status') ===
                ProgrammeStatus.DRAFT
              ) {
                handleSave(true, submitTypes.PUBLISH_DRAFT);
                return;
              }

              handleSave();
            }}
          >
            {get(contextState.activeProgramme, 'status') ===
            ProgrammeStatus.DRAFT ? (
              <FormattedMessage id="publish.text" defaultMessage="Publish" />
            ) : (
              <FormattedMessage
                id="save-changes.text"
                defaultMessage="Save changes"
              />
            )}
          </Button>
        );

      case CreateLoyaltyProgramScreens.ADD_TIER_FORMS:
        return (
          <>
            <Button
              onClick={() => addTierFormsRef.current.handleTab('RESET')}
              size="large"
              className="mr-2"
            >
              <FormattedMessage id="reset.text" defaultMessage="Reset" />
            </Button>
            <Button
              onClick={() => addTierFormsRef.current.handleTab('NEXT')}
              size="large"
              type="primary"
            >
              <FormattedMessage id="continue.text" defaultMessage="Continue" />
            </Button>
          </>
        );

      default:
        return (
          <Button onClick={(e) => e} size="large" type="primary">
            <FormattedMessage id="next.text" defaultMessage="Next" />
          </Button>
        );
    }
  };

  return (
    <Drawer backdrop={false} open={show} onClose={handleOverlay}>
      <Drawer.Header
        title={translate(messages['member-loyalty.create-programme.text'])}
      >
        <div className="drawer-actions">
          <CancelConfirm ref={addTierFormsRef} handleOverlay={handleOverlay} />

          <ALink
            className="mr-4"
            onClick={() => handleSave(false, submitTypes.SAVE_ONLY)}
            disabled={
              activeScreen === CreateLoyaltyProgramScreens.ADD_TIER_FORMS ||
              mutateIsLoading
            }
          >
            <FormattedMessage id="save.text" defaultMessage="Save" />
          </ALink>

          <ALink
            onClick={() => handleSave(true, submitTypes.SAVE_AND_EXIT)}
            disabled={
              activeScreen === CreateLoyaltyProgramScreens.ADD_TIER_FORMS ||
              mutateIsLoading
            }
          >
            <FormattedMessage
              id="save-and-exit.text"
              defaultMessage="Save &amp; Exit"
            />
          </ALink>
        </div>
      </Drawer.Header>
      <Drawer.Content>
        <div className="agent-main">
          <div
            className="container"
            style={{
              maxWidth: '1124px',
            }}
          >
            {activeScreen !== CreateLoyaltyProgramScreens.ADD_TIER_FORMS && (
              <div className="steps-header" style={{ marginBottom: '6%' }}>
                <Steps current={activeScreenIndex}>
                  <Step
                    title={
                      <FormattedMessage
                        id="programme-settings.text"
                        defaultMessage="PROGRAMME SETTINGS"
                      />
                    }
                  />
                  <Step
                    title={
                      <FormattedMessage
                        id="tier-setting.text"
                        defaultMessage="TIER SETTING"
                      />
                    }
                  />
                </Steps>
              </div>
            )}

            {renderForms()}
          </div>
        </div>
      </Drawer.Content>
      <StyledDrawerFooter>
        <div className="container">
          <div className="actions">
            <div className="left">{renderPrevButtons()}</div>
            <div className="right">{renderNextButtons()}</div>
          </div>
        </div>
      </StyledDrawerFooter>
    </Drawer>
  );
}
