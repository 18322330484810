/* eslint-disable no-nested-ternary */
import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import copyToClipboard from 'utils/copyToClipboard';
import { get } from 'lodash';
import { ALink } from 'components/ALink/ALink';
import isRow from 'utils/isRow';

type Props = {
  withdrawal: Record<string, any>;
  readOnly: boolean;
};

const AccountInformation = (props: Props) => {
  const { withdrawal, readOnly } = props;
  const {
    bankAccount = {},
    account = {},
    hexoPayCreditCard,
    withdrawalMethod,
    transactionReference,
    transactionDetails,
  } = withdrawal;
  const { Text } = Typography;
  const { firstSixDigits, lastFourDigits, expirationMonth, expirationYear } =
    hexoPayCreditCard || {};

  return (
    <>
      <div className="menu-header py-2 px-3">
        <FormattedMessage
          id="account-info.text"
          defaultMessage="account info"
        />
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage id="real-name.text" defaultMessage="Real Name" />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {get(account, 'realName') || '-'}{' '}
          {get(account, 'realName', false) && (
            <ALink
              disabled={readOnly}
              onClick={() => copyToClipboard(account.realName)}
            >
              <CopyOutlined />
            </ALink>
          )}
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="phone-number.text"
              defaultMessage="Phone number"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {account.mobileNumber || '-'}{' '}
          {account.mobileNumber && (
            <ALink
              disabled={readOnly}
              onClick={() => copyToClipboard(account.mobileNumber)}
            >
              <CopyOutlined />
            </ALink>
          )}
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage id="bank.text" defaultMessage="Bank" />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {get(bankAccount, 'bank') || '-'}{' '}
          {get(bankAccount, 'bank', false) && (
            <ALink
              disabled={readOnly}
              onClick={() => copyToClipboard(bankAccount.bank)}
            >
              <CopyOutlined />
            </ALink>
          )}
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage id="BRANCH" defaultMessage="Branch" />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {get(bankAccount, 'branch') || '-'}{' '}
          {get(bankAccount, 'branch', false) && (
            <ALink
              disabled={readOnly}
              onClick={() => copyToClipboard(bankAccount.branch)}
            >
              <CopyOutlined />
            </ALink>
          )}
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage id="province.text" defaultMessage="Province" />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {get(bankAccount, 'province') || '-'}{' '}
          {get(bankAccount, 'province', false) && (
            <ALink
              disabled={readOnly}
              onClick={() => copyToClipboard(bankAccount.province)}
            >
              <CopyOutlined />
            </ALink>
          )}
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage id="city.text" defaultMessage="City" />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {get(bankAccount, 'city') || '-'}{' '}
          {get(bankAccount, 'city', false) && (
            <ALink
              disabled={readOnly}
              onClick={() => copyToClipboard(bankAccount.city)}
            >
              <CopyOutlined />
            </ALink>
          )}
        </div>
      </div>
      <div className="d-flex py-2 px-3 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="bank-account.text"
              defaultMessage="Bank account"
            />
          </Text>
        </div>
        <div className="flex-1 text-right">
          {get(bankAccount, 'accountNumber') || '-'}{' '}
          {get(bankAccount, 'accountNumber', false) && (
            <ALink
              disabled={readOnly}
              onClick={() => copyToClipboard(bankAccount.accountNumber)}
            >
              <CopyOutlined />
            </ALink>
          )}
        </div>
      </div>
      <div className="d-flex flex-column py-2 px-3 bb-1">
        <div>
          <Text type="secondary">
            <FormattedMessage
              id="payment-account-number.text"
              defaultMessage="Payment account number"
            />
          </Text>
        </div>
        <div className="flex-1 text-left">
          {hexoPayCreditCard
            ? `${firstSixDigits} *** *** ${lastFourDigits} ${expirationMonth}/${expirationYear}`
            : withdrawalMethod.name === 'Neteller' ||
              withdrawalMethod.name === 'Skrill'
            ? `${withdrawalMethod.name}: ${account.initialEmail}`
            : withdrawalMethod.__typename === 'CryptocurrencyWithdrawalMethod'
            ? transactionDetails
            : '-'}
        </div>
      </div>
      <div className="d-flex flex-column py-2 px-3 bb-1">
        <div>
          <Text type="secondary">{isRow ? '' : 'Hexopay'} Transaction UID</Text>
        </div>
        <div className="flex-1 text-left">{transactionReference || '-'}</div>
      </div>
    </>
  );
};

export default AccountInformation;
