// TODO: Remove this eslint disable once  the a tag has a valid href attr
/* eslint-disable jsx-a11y/anchor-is-valid */
import { PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import {
  DELETE_GAME_CATEGORY,
  UPDATE_GAME_CATEGORY,
} from 'graphql/mutations/vendorConfiguration.mutation';
import { GAME_CATEGORIES } from 'graphql/queries/vendorConfiguration.query';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import MessagePromptModal from 'components/MessagePromptModal';
import { usePermissions } from 'store/accountState';
import { GameCategory } from 'types/graphqlTypes';
import useTranslate from 'utils/useTranslate';
import { CustomDrawer } from './components/CustomDrawer/CustomDrawer';
import NewCategoryDrawer from './components/NewCategoryDrawer';
import MobileAppConfigTable from './MobileAppConfigTable';

const gameCategoriesLimit = 7;
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const MobileAppConfiguration = () => {
  const translate = useTranslate();
  const { locale } = useIntl();
  const { role, permissions } = usePermissions();
  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG}:CATEGORY`
  );

  const refetchVariables = {
    language: locale.toLocaleUpperCase(),
    filter: {
      platform: { eq: 'MOBILE' },
    },
  };

  const [drawerNewCategStatus, setNewCategDrawer] = useState(false);
  const [maxGameCategoriesState, setMaxGameCategoriesState] = useState(false);
  const [mainDrawer, setMainDrawer] = useState(false);
  const [
    selectedGameCategory,
    setSelectedGameCategory,
  ] = useState<GameCategory | null>(null);
  const [existingCategory, setExistingCategory] = useState<GameCategory | null>(
    null
  );

  const { loading, error, data, refetch: refetchCategories } = useQuery(
    GAME_CATEGORIES,
    {
      variables: refetchVariables,
      fetchPolicy: 'network-only',
    }
  );

  const [deleteGameCategory] = useMutation(DELETE_GAME_CATEGORY);
  const [updateGameCategory] = useMutation(UPDATE_GAME_CATEGORY, {});

  if (error) return <p>{translate(messages['error-text'])}</p>;

  const onEditGameCategory = (gameCateg: GameCategory) => {
    setExistingCategory(gameCateg);
    setNewCategDrawer(true);
  };

  const onAddVendor = (gameCateg: GameCategory) => {
    setSelectedGameCategory(gameCateg);
    setMainDrawer(true);
  };

  const resetDrawerStatus = () => {
    setMainDrawer(false);
  };

  const onDeleteGameCategory = async (gameCateg: GameCategory) => {
    try {
      await deleteGameCategory({
        variables: {
          id: gameCateg.id,
        },
        refetchQueries: [
          {
            query: GAME_CATEGORIES,
            variables: refetchVariables,
          },
        ],
      });
      message.success(translate(messages.SUCCESSFULLY_DELETED_GAME_CATEGORY));
    } catch (e) {
      message.error(translate(messages['error-occurred.text']));
    }
  };

  const closeDrawer = () => setNewCategDrawer(false);

  const openNewCategoryDrawer = () => {
    setExistingCategory(null);
    if (data?.gameCategories?.length >= gameCategoriesLimit) {
      setMaxGameCategoriesState(true);
    } else {
      setNewCategDrawer(true);
    }
  };

  return (
    <>
      <div
        className="bg-white"
        style={{ padding: '20px 10px', height: 'calc(100vh - 112px)' }}
      >
        <div className="d-flex justify-content-space-between">
          <h3 className="ml-2">
            <FormattedMessage
              id="category-vendor-config-portrait.text"
              defaultMessage="Category / Vendor Config (Portrait App)"
            />
          </h3>
          {ALLOWED_CREATE && (
            <Button type="primary" onClick={openNewCategoryDrawer}>
              <PlusOutlined className="mr-2" />
              <FormattedMessage
                id="new-category.text"
                defaultMessage="New Category"
              />
            </Button>
          )}
        </div>

        <MobileAppConfigTable
          gameCategories={data?.gameCategories}
          fetchingCategories={loading}
          refetchCategories={refetchCategories as any}
          updateGameCategory={updateGameCategory}
          onAddVendor={onAddVendor}
          onDeleteGameCategory={onDeleteGameCategory}
          onEditGameCategory={onEditGameCategory}
        />
        {drawerNewCategStatus && (
          <NewCategoryDrawer
            closeDrawer={closeDrawer}
            drawerStatus={drawerNewCategStatus}
            formItemLayout={formItemLayout}
            existingCategory={existingCategory}
            refetchCategories={refetchCategories}
          />
        )}

        {mainDrawer && (
          <CustomDrawer
            drawerStatus={mainDrawer}
            closeDrawer={resetDrawerStatus}
            selectedGameCategory={selectedGameCategory}
            formItemLayout={formItemLayout}
          />
        )}

        {maxGameCategoriesState && (
          <MessagePromptModal
            visible={maxGameCategoriesState}
            onCloseFn={() => setMaxGameCategoriesState(false)}
            okText={translate(messages.OK)}
            title={translate(messages.SYSTEM_PROMPT)}
          >
            <p>
              <FormattedMessage
                id="max-categories-error.text"
                defaultMessage="You have reached the maximum amount of categories ({limit}) allowed to be created."
                values={{
                  limit: gameCategoriesLimit,
                }}
              />
            </p>
          </MessagePromptModal>
        )}
      </div>
    </>
  );
};

export default MobileAppConfiguration;
