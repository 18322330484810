import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Spin, Tag, Tooltip } from 'antd';
import coercedGet from 'utils/coercedGet';
import { Admin } from 'types/graphqlTypes';

const ADMINS_COUNT = gql`
  query AdminsCount($filter: AdminsFilterInput) {
    admins(filter: $filter) {
      totalCount
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;
export const ClientNumber = ({
  id,
  refetchBool,
}: {
  id: string;
  refetchBool: boolean;
}) => {
  const { data, loading, refetch } = useQuery(ADMINS_COUNT, {
    context: { shouldBatch: true },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      filter: {
        vendorGroup: {
          eq: id,
        },
      },
    },
  });

  useEffect(() => {
    if (refetchBool) {
      refetch();
    }
  }, [refetch, refetchBool]);

  if (loading) {
    return <Spin />;
  }

  const count = coercedGet(data, 'admins.totalCount', 0);
  const clientsEdges = coercedGet(data, 'admins.edges', []);
  const clientsNodes = clientsEdges.map((edge: { node: Admin }) => edge.node);

  return (
    <Tooltip
      {...(count === 0 && {
        visible: false,
      })}
      title={clientsNodes.map((admin: Admin) => (
        <Tag className="mt-1">{admin.username}</Tag>
      ))}
    >
      <span style={{ cursor: 'default' }}>{count}</span>
    </Tooltip>
  );
};
