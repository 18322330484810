import React from 'react';
import { isEmpty } from 'lodash';

type Props = {
  cityStateLocation: {
    city: string;
    country: string;
    state: string;
  };
};

const cityStateEmpty = (cityStateLocation: Props['cityStateLocation']) => {
  if (!cityStateLocation || isEmpty(cityStateLocation)) {
    return true;
  }

  const { city, state, country } = cityStateLocation;

  return !city && !state && !country;
};

export const CityStateLocation = ({ cityStateLocation }: Props) => {
  const emptyLocation = cityStateEmpty(cityStateLocation);
  return !emptyLocation ? (
    <>
      | {cityStateLocation.city}
      {cityStateLocation.state ? `, ${cityStateLocation.state}` : ''}
    </>
  ) : (
    <></>
  );
};
