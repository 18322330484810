import React from 'react';
import styled from 'styled-components';
import { Control, Controller } from 'react-hook-form';
import CustomSwitch from 'components/CustomSwitch';
import { FormattedMessage } from 'react-intl';
import { Badge, Modal } from 'antd';
import messages from 'messages';
import { VendorsSelect } from './components/VendorsSelect';
import { CategorySelect } from './components/CategorySelect';
import useTranslate from '../../../utils/useTranslate';

export const DrawerContainer = styled.div`
  width: 90%;
`;

export const RadioSwitch = ({
  onChange,
  value,
}: {
  onChange: (e: boolean) => void;
  value: boolean;
}) => (
  <CustomSwitch
    onChange={(e: boolean) => onChange(e)}
    disabled={false}
    value={value}
    size="small"
    textStyle={{ fontSize: '15px', fontWeight: 400 }}
    textHelper={{
      true: <FormattedMessage id="on.text" defaultMessage="On" />,
      false: <FormattedMessage id="off.text" defaultMessage="Off" />,
    }}
    activeColor="#3ec988"
  />
);

export const RenderExtraByMethod = ({
  item,
  control,
  name,
  method,
}: {
  item: Record<string, any>;
  control: Control;
  name: (name: string) => string;
  method: string;
}) => (
  <>
    <div
      style={{ display: method === 'VENDOR_EFFECTIVE_BET' ? 'block' : 'none' }}
    >
      <Controller
        name={name('vendors')}
        control={control}
        defaultValue={item.vendors}
        render={({ value, onChange }) => (
          <VendorsSelect value={value} onChange={onChange} />
        )}
      />
    </div>

    <div
      style={{
        display: method === 'GAME_TYPE_EFFECTIVE_BET' ? 'block' : 'none',
      }}
    >
      <Controller
        name={name('gameTypes')}
        control={control}
        defaultValue={item.category}
        render={({ value, onChange }) => (
          <CategorySelect value={value} onChange={onChange} />
        )}
      />
    </div>
  </>
);

const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    width: 10px;
    height: 10px;
  }
`;

export const Status = ({ text }: { text: any }) => {
  const color = {
    ACTIVE: 'green',
    INACTIVE: 'red',
    DRAFT: '#FAAD14',
  };
  const translate = useTranslate();

  return (
    <>
      <StyledBadge color={color[text]} />
      {translate(messages[text])}
    </>
  );
};

export const PromptWarningModal = ({
  visible,
  onCloseFn,
  title,
  onOk,
}: {
  onOk: () => void;
  visible: boolean;
  onCloseFn: () => void;
  title: string;
}) => {
  const translate = useTranslate();

  return (
    <Modal
      visible={visible}
      title={title}
      okText={translate(messages.CONFIRM)}
      cancelText={translate(messages.CANCEL)}
      onCancel={onCloseFn}
      onOk={onOk}
    >
      <div className="ant-modal-confirm-body">
        <div className="ant-modal-confirm-content">
          <FormattedMessage
            id="ARE_YOU_SURE_YOU_WANT_TO_PROCEED_IRREVERSIBLE"
            defaultMessage="Are you sure you want to proceed? This action is irreversible"
          />
        </div>
      </div>
    </Modal>
  );
};
