import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { DUPLICATE_AFFILIATE_PROGRAMME } from 'graphql/mutations/affiliateAgent.mutation';
import { AFFILIATE_PROGRAMMES } from 'graphql/queries/affiliateAgent.query';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import useTranslate from 'utils/useTranslate';

type Props = {
  affiliate: Record<string, any>;
  refetch: () => void;
};

const CustomModal: any = Modal;

const DuplicateAffiliateProgramme = ({ affiliate, refetch }: Props) => {
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };
  const translate = useTranslate();
  const [duplicateAffiliateProgramme, { loading }] = useMutation(
    DUPLICATE_AFFILIATE_PROGRAMME,
    {
      onCompleted: () => {
        message.success({
          content: (
            <span data-testid="success">
              {translate(messages.AFFILIATE_PROGRAMME_DUPLICATE_SUCCESS)}
            </span>
          ),
        });
        refetch();
        handleShow();
      },
      onError: () =>
        message.error(
          <span data-testid="error">
            {translate(messages.AFFILIATE_PROGRAMME_DUPLICATE_ERROR)}
          </span>
        ),
    }
  );

  return (
    <>
      <ALink onClick={handleShow} data-testid="duplicate">
        <FormattedMessage id="duplicate.text" defaultMessage="Duplicate" />
      </ALink>
      {isShow && (
        <CustomModal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          cancelButtonProps={{ disabled: loading }}
          okButtonProps={{ loading, 'data-testid': 'confirm' }}
          onCancel={handleShow}
          onOk={() => {
            duplicateAffiliateProgramme({
              variables: {
                id: affiliate.id,
              },
              refetchQueries: [
                {
                  query: AFFILIATE_PROGRAMMES,
                },
              ],
            });
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-duplicate.text"
                defaultMessage="Confirm Duplicate"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default DuplicateAffiliateProgramme;
