import React from 'react';
import { Table } from 'antd';
import { MemberIpAddressReportItem } from 'types/graphqlTypes';
import { StyledTable, StyledTableContainer } from 'constants/styles';
import { ApolloError } from 'apollo-client';

type Props = {
  dataSource: MemberIpAddressReportItem[];
  loading: boolean;
  columns: any[];
  error: ApolloError | undefined;
};

const { Column } = Table;

const TableIndex = ({ dataSource, loading, columns }: Props) => (
  <StyledTableContainer>
    <StyledTable
      size="middle"
      rowKey="id"
      data-testid="container"
      dataSource={dataSource}
      loading={loading}
      pagination={false}
    >
      {columns?.map((column, idx) => (
        <Column
          title={column?.title}
          align={column?.align}
          width={column?.width}
          dataIndex={column?.dataIndex}
          key={column?.dataIndex || idx}
          render={column?.render}
        />
      ))}
    </StyledTable>
  </StyledTableContainer>
);

export default TableIndex;
