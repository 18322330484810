import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import { FormattedMessage } from 'react-intl';
import removeNull from 'utils/removeNull';
import isEmpty from 'lodash/isEmpty';
import useTranslate from 'utils/useTranslate';
import { ALink } from 'components/ALink/ALink';
import messages from 'messages';
import SearchForm from '../SearchForm';
import { StyledModal } from './styles';
import { SAVE_SEARCH } from './mutations';
import { CUSTOM_FILTERS } from '../SearchSettings/components/SearchSettingsList/queries';

const SaveSearch = ({ filters }: { filters: Record<string, any> }) => {
  const translate = useTranslate();
  const [isShow, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [saveSearch] = useMutation(SAVE_SEARCH);
  const handleShowModal = () => setShow(!isShow);
  const handleSubmit = async (input: Record<string, any>) => {
    try {
      setLoading(true);
      if (!isEmpty(removeNull(filters))) {
        await saveSearch({
          variables: {
            input: {
              name: input.name,
              context: 'member-game-reports',
              filter: removeNull(filters),
            },
          },
          refetchQueries: [
            {
              query: CUSTOM_FILTERS,
              variables: {
                filter: {
                  context: {
                    eq: 'member-game-reports',
                  },
                },
              },
            },
          ],
        });
        setLoading(false);
        message.success(`${translate(messages['search-saved.text'])}`);
        handleShowModal();
      } else {
        setLoading(false);
        message.error(
          `${translate(messages['cant-save-empty-filters-error.text'])}`
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ALink onClick={handleShowModal}>
        <FormattedMessage id="save-search.text" defaultMessage="Save Search" />
      </ALink>
      {isShow && (
        <StyledModal
          title={
            <FormattedMessage
              id="save-search.text"
              defaultMessage="Save Search"
            />
          }
          visible
          footer={false}
          onCancel={handleShowModal}
        >
          <SearchForm
            initialValues={{ name: null }}
            onSubmit={handleSubmit}
            onClose={handleShowModal}
            isLoading={isLoading}
          />
        </StyledModal>
      )}
    </>
  );
};

export default SaveSearch;
