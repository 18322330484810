import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import ALL_PERMISSIONS from 'constants/permissions3';
import FiltersProvider from 'contexts/Filters';
import {
  GET_PERMISSIONS_IDS,
  GET_PERMISSION_GROUPS,
} from 'graphql/queries/permissionGroup.query';
import { isEqual } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useMemo, useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import { QuickSearchIds } from 'components/QuickSearchFilter/QuickSearchFilter';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePermissions } from 'store/accountState';
import { StyledTagContainer } from 'styles';
import coercedGet from 'utils/coercedGet';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import { tablePaginationHandler } from 'utils/tablePagination';
import useTranslate from 'utils/useTranslate';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import FilterConditions from './components/FilterConditions';
import NewPermissionGroup from './components/NewPermissionGroup';
import PermissionGroups from './components/PermissionGroups';
import Sidebar from './components/Sidebar';
import SearchSettingsForm from './components/SearchSettingsForm';
import { Filters } from './types';

const pageInitState = {
  first: 10,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
};

const PermissionGroupContent = () => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_PERMISSION_GROUP
  );

  const [expandedTags, setExpandedTags] = useState(false);

  const [page, setPage] = useState(pageInitState);
  const defaultFilters: Filters = {
    name: null,
    serialCode: null,
    permissions: null,
    dateTimeUpdated: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const handleFilters = (e: any) => {
    setFilters(e);
  };

  const formattedFilters = useMemo(() => transformMovableDateFilter(filters), [
    filters,
  ]);
  const {
    loading,
    data = {} as any,
    refetchVariables,
    refetch,
  } = usePartialFiltersQuery(
    GET_PERMISSIONS_IDS,
    GET_PERMISSION_GROUPS,
    'permissionGroups.edges',
    formattedFilters,
    page,
    ['name', 'serialCode', 'permissions']
  );

  const totalCount = coercedGet(data, 'permissionGroups.totalCount', 0);
  const handleNext = (pageInfo: any) => () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage]! || undefined;
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };
  const { totalPage } = tablePaginationHandler(page, setPage, totalCount);

  const refetchQuery = () => {
    refetch(refetchVariables);
  };

  const initialFilters = {};

  const edges = coercedGet(data, 'permissionGroups.edges', []);
  const pageInfo = coercedGet(data, 'permissionGroups.pageInfo', {});
  const translate = useTranslate();

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <FiltersProvider
        initialValues={initialFilters}
        defaultValues={defaultFilters}
        tabId="permission-group"
      >
        <>
          <Layout.Header
            leftNode={
              <div className="d-flex align-items-start flex-g-1">
                <ToggleSidebarHead />
                <StyledTagContainer ref={ref} isExpanded={expandedTags}>
                  <span className="ml-1">
                    {translate(messages.FILTER_CONDITIONS)}:{' '}
                  </span>{' '}
                  <FilterConditions
                    filters={filters}
                    onFilterChange={handleFilters}
                    initialFilters={defaultFilters}
                  />
                </StyledTagContainer>
                <LegacyIconToggle
                  forwardedRef={ref}
                  filters={filters}
                  expandedTags={expandedTags}
                  toggleExpandedTags={setExpandedTags}
                />

                {!isEqual(defaultFilters, filters) && (
                  <ALink
                    onClick={() => handleFilters(defaultFilters)}
                    style={{ marginTop: '0.35rem' }}
                  >
                    {translate(messages.CLEAR_ALL)}
                  </ALink>
                )}
              </div>
            }
            rightNode={
              <>
                <div className="mr-2">
                  <ColumnsFilterNew iconOnly tabId="permission-group" />
                </div>
                <div className="mr-2">
                  <SpreadsheetDownload
                    iconOnly
                    filter={formattedFilters}
                    filename={translate(messages.PERMISSION_GROUPS)}
                    extension="csv"
                  />
                </div>
                {ALLOWED_CREATE && (
                  <NewPermissionGroup refetch={refetchQuery} />
                )}
              </>
            }
          />
          <Layout.Content
            sideBar={
              <Layout.Sidebar
                quickSearch={{
                  filters,
                  contextKey: 'permission-group',
                  onFilterChange: handleFilters,
                  searchSettingsForm: SearchSettingsForm,
                  quickSearchId: QuickSearchIds.PERMISSION_GROUP,
                  isNext: true,
                }}
              >
                <Sidebar
                  filters={filters}
                  onFilterChange={handleFilters}
                  initialFilters={defaultFilters}
                />
              </Layout.Sidebar>
            }
            footer={
              <Layout.Footer
                loading={loading}
                onNext={handleNext(pageInfo)}
                onPrev={handlePrev}
                page={page as any}
                resultsCount={totalCount}
                totalPage={totalPage}
                setPage={setPage as any}
                pageInfo={data && data.pageInfo}
              />
            }
          >
            <PermissionGroups
              loading={loading}
              data={edges}
              refetchVariables={refetchVariables}
              refetch={refetchQuery}
              formattedFilters={formattedFilters}
            />
          </Layout.Content>
        </>
      </FiltersProvider>
    </Layout.Container>
  );
};

const PermissionGroup = () => (
  <SpreadsheetProvider
    options={{
      query: GET_PERMISSION_GROUPS,
      edgesPath: 'permissionGroups.edges',
    }}
  >
    <PermissionGroupContent />
  </SpreadsheetProvider>
);

export default PermissionGroup;
