import React from 'react';
import { Table } from 'antd';
import { ApolloError } from 'apollo-client';
import { StyledTableContainer } from 'constants/styles';
import cx from 'classnames';
import { Key, TablePaginationConfig } from 'antd/lib/table/interface';

// const { Column } = Table;

type Props = {
  loading: boolean;
  error: ApolloError | undefined;
  data: any;
  columns: any[];
  handleSort: (order: 'ascend' | 'descend') => void;
};

const RebatePayoutRecordsTable = (props: Props) => {
  const { loading, error, data, columns, handleSort } = props;
  return (
    <StyledTableContainer
      data-testid={cx({ loading, error, container: !loading && !error })}
    >
      <Table
        bordered
        size="middle"
        dataSource={data}
        expandIconColumnIndex={3}
        loading={loading}
        pagination={false}
        onChange={(
          _pagination: TablePaginationConfig,
          _filters: Record<string, (Key | boolean)[] | null>,
          sorter: Record<string, any>
        ) => {
          handleSort(sorter.order);
        }}
        scroll={{ x: 2600 }}
        columns={columns?.filter(Boolean)}
      />
    </StyledTableContainer>
  );
};

export default RebatePayoutRecordsTable;
