export const TIER_REQUIREMENT = {
  ACTIVE_MEMBERS: 'ACTIVE_MEMBERS_COUNT',
  NET_PROFIT: 'NET_PROFIT',
  BOTH: 'BOTH',
  EITHER: 'EITHER',
};

export const getTierRequirement = (requirement: string) => ({
  forActiveMembers:
    requirement === TIER_REQUIREMENT.ACTIVE_MEMBERS ||
    requirement === TIER_REQUIREMENT.BOTH ||
    requirement === TIER_REQUIREMENT.EITHER,
  forNetProfit:
    requirement === TIER_REQUIREMENT.NET_PROFIT ||
    requirement === TIER_REQUIREMENT.BOTH ||
    requirement === TIER_REQUIREMENT.EITHER,
});
