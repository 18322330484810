import React, { useRef, useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';

const ChartDetailed = ({
  graph,
  color,
  chart,
}: {
  graph: any;
  color: string;
  chart?: number;
}) => {
  const { dataPoints } = graph;
  const translate = useTranslate();

  const [width, setWidth] = useState(0);
  const ref = useRef() as any;
  useEffect(() => {
    if (ref.current) setWidth(ref.current.clientWidth);
  }, [ref]);

  let c = 0;
  const data =
    dataPoints &&
    dataPoints.map((obj: { pv: number }) => {
      c += 1;
      const rObj: {
        name?: number;
        uv?: number;
        pv?: number;
        amount?: number;
      } = {};
      rObj.name = c;

      if (Math.sign(obj.pv) === -1) {
        rObj.uv = obj.pv;
      } else {
        rObj.amount = obj.pv;
      }

      return rObj;
    });

  const renamer = {
    name: translate(messages['name.text']),
    amount: translate(messages.amount_text),
    uv: translate(messages['uv.text']),
  };

  const modifiedArray =
    data &&
    data.map((item: any) =>
      Object.keys(item).reduce((acc, key) => {
        acc[renamer[key]] = item[key];
        return acc;
      }, {})
    );

  return (
    <div ref={ref}>
      <BarChart
        width={width}
        height={250}
        data={modifiedArray}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={renamer.name} interval={0} />
        <YAxis />
        <Tooltip />
        {chart && <Bar dataKey={renamer.uv} fill="#FF0062" />}
        <Bar dataKey={renamer.amount} fill={chart ? '#52C41A' : color} />
      </BarChart>
    </div>
  );
};

export default ChartDetailed;
