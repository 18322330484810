import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Switch } from 'antd';
import useTranslate from 'utils/useTranslate';
import { StyledRequestUpdate } from './styles';

const messages = defineMessages({
  requestUpdate: {
    id: 'request-update.text',
    defaultMessage: 'Request Update',
  },
});

const RequestUpdateToggle = ({
  onChange,
  checked,
}: {
  onChange: any;
  checked?: boolean;
}) => {
  const { locale } = useIntl();
  const translate = useTranslate();
  return (
    <StyledRequestUpdate locale={locale} className="mr-2">
      <Switch size="small" checked={checked || false} onChange={onChange} />
      <span>{translate(messages.requestUpdate)}</span>
    </StyledRequestUpdate>
  );
};

export default RequestUpdateToggle;
