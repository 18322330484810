import React from 'react';
import SharedStyledContent from 'styles/SharedStyledContent';
import { Members } from './components/Members/Members';
import { Sidebar } from './components/Sidebar/Sidebar';

type Props = {
  finalQueryFilter: Record<string, any>;
  setPage: Function;
  filters: Record<string, any>;
  page: Record<string, any>;
  handlePrev: Function;
  handleNext: Function;
  onFilterChange: (e: any) => any;
  members: Record<string, any>;
  memberLoading: boolean;
  error: any;
};

export const ContentMemberManagement = ({
  setPage,
  filters,
  page,
  handlePrev,
  handleNext,
  onFilterChange,
  members,
  memberLoading,
  error,
  finalQueryFilter,
}: Props) => (
  <div className="d-flex" style={{ height: 'calc(100vh - 157px)' }}>
    <Sidebar filters={filters} onFilterChange={onFilterChange} />

    <SharedStyledContent className="d-flex flex-column">
      <Members
        error={error}
        members={members}
        memberLoading={memberLoading}
        setPage={setPage}
        filters={filters}
        page={page}
        onPrev={handlePrev}
        onNext={handleNext}
        finalQueryFilter={finalQueryFilter}
      />
    </SharedStyledContent>
  </div>
);
