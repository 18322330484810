import React from 'react';
import { uniq } from 'lodash';

export const fixedScenarioTypes = {
  D_ONLY: 'DEPOSIT_ONLY',
  D_AND_T: 'DEPOSIT_AND_TURNOVER',
  D_AND_T_AND_T: 'DEPOSIT_AND_TURNOVER_AND_*TURNOVER',
};

export const allTypes = {
  ADD_SCENARIO: 'ADD_SCENARIO',
  REMOVE_SCENARIO: 'REMOVE_SCENARIO',
  REMOVE_ALL_SEQUENCED_SCENARIOS: 'REMOVE_ALL_SEQUENCED_SCENARIOS',
  ADD_ALL_SCENARIOS: 'ADD_ALL_SCENARIOS',
  ADD_SCENARIOS: 'ADD_SCENARIOS',
  ...fixedScenarioTypes,
};

export const QualificationCriteria = React.createContext<any>(null);

export function QualificationCriteriaProvider({ children }: any) {
  const initialState = {
    activeScenario: [],

    currentData: {},
  };

  const reducer = (state: Record<string, any>, action: Record<string, any>) => {
    switch (action.type) {
      case allTypes.ADD_ALL_SCENARIOS:
        return {
          ...state,
          activeScenario: Object.values(fixedScenarioTypes),
        };
      case allTypes.ADD_SCENARIOS:
        return {
          ...state,
          activeScenario: uniq([...state.activeScenario, ...action.payload]),
        };
      case allTypes.ADD_SCENARIO:
        return {
          ...state,
          activeScenario: uniq([...state.activeScenario, action.payload]),
        };
      case allTypes.REMOVE_SCENARIO:
        return {
          ...state,
          activeScenario: state.activeScenario.filter(
            (item: any) => item !== action.payload
          ),
        };
      case allTypes.REMOVE_ALL_SEQUENCED_SCENARIOS:
        return {
          ...state,
          activeScenario: state.activeScenario.filter((item: any) => {
            const scenariosArray = Object.values(fixedScenarioTypes);
            const itemIndex = scenariosArray.indexOf(action.payload);
            return scenariosArray.slice(0, itemIndex).includes(item);
          }),
        };
      default:
        return state;
    }
  };

  return (
    <QualificationCriteria.Provider
      value={React.useReducer(reducer, initialState)}
    >
      {children}
    </QualificationCriteria.Provider>
  );
}

export const useQualificationCriteriaContext = () =>
  React.useContext(QualificationCriteria);
