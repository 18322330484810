import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';

export const CostSettingsTypes = {
  SET_SORT_FILTER: 'SET_SORT_FILTER',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER',
  SET_SUBFILTER: 'SET_SUBFILTER',
  SET_SEARCH: 'SET_SEARCH',
  SET_COPIED: 'SET_COPIED',
  SET_ACTIVE_VENDOR: 'SET_ACTIVE_VENDOR',
  REMOVE_SUBFILTER: 'REMOVE_SUBFILTER',
};

export const CostSettingsFilters = {
  GAME_VENDOR: 'GAME_VENDOR',
  GAME_CATEGORY: 'GAME_CATEGORY',
  ALL: 'ALL',
};

export const CostSettingsContext = React.createContext({});

export const CostSettingsProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const initialState = {
    sortFilter: CostSettingsFilters.GAME_VENDOR,
    searchFilter: CostSettingsFilters.GAME_VENDOR,
    subFilters: [],
    search: '',
    activeVendor: {},
  };

  const reducer = (
    state: any,
    action: {
      type: string;
      payload: any;
      meta: DynamicObj;
      preventFillFor: any;
    }
  ) => {
    switch (action.type) {
      case CostSettingsTypes.SET_SORT_FILTER:
        return { ...state, sortFilter: action.payload };
      case CostSettingsTypes.SET_ACTIVE_VENDOR:
        return { ...state, activeVendor: action.payload };
      case CostSettingsTypes.SET_COPIED:
        return { ...state, copied: action.payload };
      case CostSettingsTypes.SET_SEARCH:
        return { ...state, search: action.payload };
      case CostSettingsTypes.SET_SEARCH_FILTER:
        return { ...state, searchFilter: action.payload };
      case CostSettingsTypes.SET_SUBFILTER:
        return {
          ...state,
          subFilters: [...state.subFilters, action.payload],
        };
      case CostSettingsTypes.REMOVE_SUBFILTER:
        return {
          ...state,
          subFilters: state.subFilters.filter(
            (filter: Record<string, any>) =>
              filter.value !== action.payload.value
          ),
        };
      default:
        return state;
    }
  };

  return (
    <CostSettingsContext.Provider
      value={React.useReducer(reducer, initialState)}
    >
      {children}
    </CostSettingsContext.Provider>
  );
};

export const useCostSettingsContext = () =>
  React.useContext(CostSettingsContext);
