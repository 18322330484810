import React, { useState } from 'react';
import { Button, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useTranslate from 'utils/useTranslate';
import StyledModal from 'SuperAdminMain/shared/styles/StyledModal';
import { FormattedMessage } from 'react-intl';
import { DynamicObj } from 'interfaces/user.interface';
import successMessage from 'SuperAdminMain/messages';
import messages from '../messages';
import { useVendorMaintenance } from '../../../context';

const KICK_PLAYERS = gql`
  mutation KICK_PLAYERS($id: ID!) {
    kickPlayers(vendor: $id)
  }
`;

interface Props {
  vendor: DynamicObj;
}

const ForceKick: React.FC<Props> = ({ vendor }) => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [kickPlayers] = useMutation(KICK_PLAYERS);
  const translate = useTranslate();
  const { refetch } = useVendorMaintenance();

  const handleShow = () => setVisible(!visible);

  const onAction = async () => {
    setLoading(true);

    try {
      await kickPlayers({
        variables: {
          id: vendor.id,
        },
      });

      refetch();
      handleShow();
      message.success(translate(successMessage['updated-successfully.text']));
      // eslint-disable-next-line no-empty
    } finally {
    }

    setLoading(false);
  };

  return (
    <>
      <Button type="link" block onClick={() => setVisible(true)}>
        {useTranslate()(messages.FORCE_KICK)}
      </Button>
      <StyledModal
        onOk={() => {
          message.warning('Functionality not yet available');
          setVisible(false);
        }}
        visible={visible}
        width={640}
        centered
        onCancel={handleShow}
        title={
          <FormattedMessage id="force-kick.text" defaultMessage="Force Kick" />
        }
        footer={[
          <Button key="back" onClick={handleShow}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={onAction}
          >
            <FormattedMessage id="update.text" defaultMessage="Update" />
          </Button>,
        ]}
      >
        <FormattedMessage
          id="force-kick-confirmation.text"
          defaultMessage="Are you sure you want to force kick this vendor?"
        />
      </StyledModal>
    </>
  );
};

export default ForceKick;
