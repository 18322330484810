import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { CloseOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Button,
  Col,
  Drawer,
  Input,
  message,
  Radio,
  Row,
  Select as AntdSelect,
} from 'antd';
import { FeatureFlags } from 'constants/featureFlags';
import { useFormik } from 'formik';
import {
  CREATE_BANNER,
  UPDATE_BANNER,
} from 'graphql/mutations/banner.mutation';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import { BANNERS, BANNER_LISTS } from 'graphql/queries/banner.query';
import { PROMO_NAMES } from 'graphql/queries/promo.query';
import useIsNext from 'hooks/useIsNext';
import { get } from 'lodash';
import messages from 'messages';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import FormItem from 'components/FormItem';
import { CustomUploadDragger } from 'components/Uploader/CustomUploadDragger';
import { UseFormikErrorMessage } from 'components/UseFormikErrorMessage/UseFormikErrorMessage';
import esGet from 'utils/esGet';
import { getBase64 } from 'utils/imageUtil';
import useTranslate from 'utils/useTranslate';
import { onSubmitOptions, validationSchema } from './utils';

type Rec = Record<string, any>;

const Select: any = AntdSelect;
const NewBannerDrawer = ({
  drawerState,
  setDrawerState,
  existingBanner,
  refetchVariables,
}: {
  drawerState: boolean;
  setDrawerState: (e: boolean) => void;
  existingBanner: Rec;
  refetchVariables: Rec;
}) => {
  const [initialValues, setInitialValues] = useState<Rec>({
    name: '',
    redirect: 'url',
    url: 'https://',
    promo: '',
    bannerDesktop: '',
    bannerMobile: '',
    bannerHorizontal: '',
  });

  const { data: tempData, refetch } = useQuery(BANNER_LISTS, {
    notifyOnNetworkStatusChange: true,
  });
  const bannersLists = esGet(tempData?.banners?.edges, []).map(
    ({ node }: { node: { name: string } }) => node?.name
  );

  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [createBanner] = useMutation(CREATE_BANNER);
  const [updateBanner] = useMutation(UPDATE_BANNER);
  const { data } = useQuery(PROMO_NAMES, {
    variables: {
      filter: {
        status: {
          in: ['ACTIVE', 'PUBLISHED'],
        },
      },
    },
  });
  const [fileListStateDesktop, setFileListDesktop] = useState(null);
  const [fileListStateMobile, setFileListMobile] = useState(null);
  const [fileListStateHorizontal, setFileListHorizontal] = useState(null);
  const [showExistingImageDesktop, setShowExistingImageDesktop] = useState(
    true
  );
  const [showExistingImageMobile, setShowExistingImageMobile] = useState(true);
  const [
    showExistingImageHorizontal,
    setShowExistingImageHorizontal,
  ] = useState(true);
  const desktopImgRef = useRef(null);
  const mobileImgRef = useRef(null);
  const horizontalImgRef = useRef(null);

  const acceptedFileTypes = ['image/jpg', 'image/png', 'image/jpeg'];

  const refetchQueries = [
    {
      query: BANNERS,
      variables: refetchVariables,
    },
  ];

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch]);

  useEffect(() => {
    if (existingBanner) {
      setInitialValues({
        name: existingBanner.name,
        redirect: existingBanner.link ? 'url' : 'promo',
        url: existingBanner.link || '',
        promo: get(existingBanner, 'promo.id', ''),
        bannerDesktop: get(existingBanner, 'imageDesktop.id'),
        bannerMobile: get(existingBanner, 'imageMobile.id'),
        bannerHorizontal: get(existingBanner, 'imageHorizontal.id'),
      });
    }
  }, [existingBanner]);

  const translate = useTranslate();

  const uploadImage = (
    type: string,
    file: Rec,
    setFieldValue: (e: string, f: any) => void
  ) => {
    uploadFile({
      name: '',
      redirect: 'url',
      url: '',
      promo: '',
      bannerDesktop: '',
      bannerMobile: '',
      bannerHorizontal: '',
      variables: {
        file,
      },
    } as any).then((resp) => {
      const respData = resp.data;
      if (respData.uploadFile && respData.uploadFile.id) {
        const imageId = respData.uploadFile.id;
        if (type === 'mobile') {
          setFieldValue('bannerMobile', imageId);
          setFileListMobile(file.name);
        }
        if (type === 'desktop') {
          setFieldValue('bannerDesktop', imageId);
          setFileListDesktop(file.name);
        }
        if (type === 'horizontal') {
          setFieldValue('bannerHorizontal', imageId);
          setFileListHorizontal(file.name);
        }
      }
    });
  };

  // Dont do any async/await here as it will resolve this beforeUpload
  // to prevent doing the built-in POST request based on action attribute
  const beforeUpload = (
    type: string,
    setFieldValue: (e: string, f: any) => void
  ) => (file: Rec) => {
    const validFileType = acceptedFileTypes.find(
      (acceptedFileType) => acceptedFileType === file.type
    );

    if (!validFileType) {
      message.error(translate(messages['wrong-file-type.text']));
      return false;
    }

    getBase64(file, (imgBase64: HTMLImageElement) => {
      const setImageRef = (
        imageRef: Rec,
        resolutionRestriction: any,
        label: string
      ) => {
        const imageRefValue = imageRef as Rec;
        imageRefValue.current.src = imgBase64;

        imageRefValue.current.onload = () => {
          const { naturalHeight, naturalWidth } = imageRef.current;
          const {
            height = 0,
            width = 0,
          }: {
            width?: number;
            height?: number;
          } = resolutionRestriction || {};

          if (naturalHeight < height || naturalWidth < width) {
            message.error(
              translate(messages[`${label}`], {
                size: `${width}px x ${height}px`,
              })
            );
          }
          if (file.size > 1000000) {
            message.error(translate(messages['file-size-too-large.text']));
          } else {
            uploadImage(type, file, setFieldValue);
          }
        };
      };

      if (type === 'mobile') {
        setImageRef(mobileImgRef, null, 'mobile-image-restriction-error.text');
      }
      if (type === 'desktop') {
        setImageRef(
          desktopImgRef,
          null,
          'desktop-image-restriction-error.text'
        );
      }
      if (type === 'horizontal') {
        setImageRef(
          horizontalImgRef,
          null,
          'horizontal-image-restriction-error.text'
        );
      }
    });

    // This is to prevent the <Upload> tag to send the built-in POST request based on the action attribute
    // We need to avoid to POST based on action attribute to reduce unnecessary call
    return false;
  };

  const onSubmit = async (values: Rec) => {
    if (!existingBanner && bannersLists.includes(values?.name || '')) {
      message.error(translate(messages.BANNER_OF_SAME_ERROR));
      return;
    }

    if (existingBanner) {
      const removedExistingBannersList = bannersLists.filter(
        (banner: string) => banner !== existingBanner?.name
      );
      const bannerNameExists = removedExistingBannersList.includes(
        values?.name
      );
      if (bannerNameExists) {
        message.error(translate(messages.BANNER_OF_SAME_ERROR));
        return;
      }
    }

    const options = (variables: Rec) => ({
      ...variables,
      refetchQueries,
    });
    if (existingBanner) {
      const variables = onSubmitOptions(values, existingBanner);
      await updateBanner(options(variables));
    } else {
      const variables = onSubmitOptions(values);
      await createBanner(options(variables));
    }

    setDrawerState(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(translate),
    onSubmit,
    enableReinitialize: true,
  });

  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
    handleSubmit,
  }: any = formik;

  const isNextTrue = useIsNext(FeatureFlags.horizontalImage);

  return (
    <Drawer
      width="100%"
      onClose={() => setDrawerState(false)}
      visible={drawerState}
      title={
        existingBanner ? (
          <FormattedMessage
            id="edit-banner.text"
            defaultMessage="Editing Banner"
          />
        ) : (
          <FormattedMessage
            id="new-banner-upload.text"
            defaultMessage="New Banner Upload"
          />
        )
      }
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button className="mr-2" onClick={() => setDrawerState(false)}>
            <FormattedMessage id="back.text" defaultMessage="Back" />
          </Button>
          <Button type="primary" onClick={handleSubmit} loading={isSubmitting}>
            <FormattedMessage id="upload.text" defaultMessage="Upload" />
          </Button>
        </div>
      }
    >
      <Row>
        <Col span={8} />
        <Col span={8}>
          <h4 className="text-bold">
            <FormattedMessage
              id="general-settings.text"
              defaultMessage="General Settings"
            />
          </h4>

          <div className="mt-3">
            <Form layout="horizontal">
              <FormItem
                label={
                  <FormattedMessage
                    id="banner-name.text"
                    defaultMessage="Banner Name"
                  />
                }
              >
                <Input
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <UseFormikErrorMessage formik={formik} name="name" />
              </FormItem>
            </Form>
          </div>

          <div className="mt-3">
            <Form layout="horizontal">
              <FormItem
                label={
                  <FormattedMessage
                    id="redirect.text"
                    defaultMessage="Redirect"
                  />
                }
              >
                <Radio.Group
                  value={values.redirect}
                  onChange={handleChange}
                  name="redirect"
                >
                  <Radio value="url">
                    <FormattedMessage
                      id="url-link-launch.text"
                      defaultMessage="URL for link to launch"
                    />
                  </Radio>
                  <Radio value="promo">
                    <FormattedMessage id="promo.text" defaultMessage="Promo" />
                  </Radio>
                </Radio.Group>
              </FormItem>

              {values.redirect === 'url' && (
                <FormItem
                  label={
                    <FormattedMessage
                      id="url-link-launch.text"
                      defaultMessage="URL for link to launch"
                    />
                  }
                >
                  <Input
                    name="url"
                    value={values.url}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={translate(messages['sample-full-url.text'])}
                  />
                  <UseFormikErrorMessage formik={formik} name="url" />
                </FormItem>
              )}

              {values.redirect === 'promo' && (
                <FormItem
                  label={
                    <FormattedMessage id="promo.text" defaultMessage="Promo" />
                  }
                >
                  <Select
                    name="promo"
                    value={values.promo}
                    onChange={(value: string) => setFieldValue('promo', value)}
                  >
                    {get(data, 'promos.edges', [])
                      .map((edge: { node: Rec }) => edge.node)
                      .map((promoData: { id: string; name: string }) => (
                        <Select.Option key={promoData.id}>
                          {promoData.name}
                        </Select.Option>
                      ))}
                  </Select>
                  <UseFormikErrorMessage formik={formik} name="promo" />
                </FormItem>
              )}
            </Form>
          </div>

          <h4 className="text-bold mt-3">
            <FormattedMessage
              id="banner-upload.text"
              defaultMessage="Banner Upload"
            />
          </h4>

          <div className="mt-3">
            <Form layout="horizontal">
              <FormItem
                label={
                  <FormattedMessage
                    id="desktop-version.text"
                    defaultMessage="Desktop Version"
                  />
                }
              >
                {get(existingBanner, 'imageDesktop.uri') &&
                  showExistingImageDesktop &&
                  !fileListStateDesktop && (
                    <div>
                      <a
                        href={existingBanner.imageDesktop.uri}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {existingBanner?.imageDesktop?.uri
                          ?.split('/')
                          [
                            existingBanner?.imageDesktop?.uri?.split('/')
                              .length - 1
                          ].replace(/%/g, ' ')}
                      </a>
                      <CloseOutlined
                        className="ml-2 text-danger cursor-pointer"
                        onClick={() => {
                          setInitialValues({
                            ...initialValues,
                            bannerDesktop: null,
                          });
                          setShowExistingImageDesktop(false);
                        }}
                      />
                    </div>
                  )}

                <CustomUploadDragger
                  fileList={fileListStateDesktop}
                  beforeUpload={beforeUpload('desktop', setFieldValue)}
                  acceptedFileTypes={acceptedFileTypes}
                  fileTypesText=".jpg .png"
                  imgRef={desktopImgRef}
                  onCloseFileList={() => {
                    setInitialValues(() => ({
                      ...formik.values,
                      bannerDesktop: null,
                    }));
                    setFileListDesktop(null);
                  }}
                />
              </FormItem>
              <FormItem
                label={
                  <FormattedMessage
                    id="portrait-version.text"
                    defaultMessage="Portrait Version"
                  />
                }
                className="mt-3"
              >
                {get(existingBanner, 'imageMobile.uri') &&
                  showExistingImageMobile &&
                  !fileListStateMobile && (
                    <div>
                      <a
                        href={existingBanner.imageMobile.uri}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {existingBanner?.imageMobile?.uri
                          ?.split('/')
                          [
                            existingBanner?.imageMobile?.uri?.split('/')
                              .length - 1
                          ].replace(/%/g, ' ')}
                      </a>
                      <CloseOutlined
                        className="ml-2 text-danger cursor-pointer"
                        onClick={() => {
                          setInitialValues({
                            ...initialValues,
                            bannerMobile: null,
                          });
                          setShowExistingImageMobile(false);
                        }}
                      />
                    </div>
                  )}
                <CustomUploadDragger
                  fileList={fileListStateMobile}
                  beforeUpload={beforeUpload('mobile', setFieldValue)}
                  acceptedFileTypes={acceptedFileTypes}
                  fileTypesText=".jpg .png"
                  imgRef={mobileImgRef}
                  onCloseFileList={() => {
                    setInitialValues(() => ({
                      ...formik.values,
                      bannerMobile: null,
                    }));
                    setFileListMobile(null);
                  }}
                />
              </FormItem>
              {/* //horizontal */}
              {isNextTrue && (
                <FormItem
                  label={
                    <FormattedMessage
                      id="landscape-version.text"
                      defaultMessage="Landscape Version"
                    />
                  }
                  className="mt-3"
                >
                  {get(existingBanner, 'imageHorizontal.uri') &&
                    showExistingImageHorizontal &&
                    !fileListStateHorizontal && (
                      <div>
                        <a
                          href={existingBanner.imageHorizontal.uri}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedMessage
                            id="existing-image.text"
                            defaultMessage="Existing Image"
                          />
                        </a>
                        <CloseOutlined
                          className="ml-2 text-danger cursor-pointer"
                          onClick={() => {
                            setInitialValues({
                              ...initialValues,
                              bannerHorizontal: null,
                            });
                            setShowExistingImageHorizontal(false);
                          }}
                        />
                      </div>
                    )}
                  <CustomUploadDragger
                    fileList={fileListStateHorizontal}
                    beforeUpload={beforeUpload('horizontal', setFieldValue)}
                    acceptedFileTypes={acceptedFileTypes}
                    fileTypesText=".jpg .png"
                    imgRef={horizontalImgRef}
                    onCloseFileList={() => {
                      setInitialValues(() => ({
                        ...formik.values,
                        bannerHorizontal: null,
                      }));
                      setFileListHorizontal(null);
                    }}
                  />
                </FormItem>
              )}
            </Form>
          </div>
        </Col>
        <Col span={8} />
      </Row>
    </Drawer>
  );
};

export default NewBannerDrawer;
