import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'bulk-update.text': {
    id: 'bulk-update.text',
    defaultMessage: 'Bulk Update',
  },
  'member-session-duration.text': {
    id: 'member-session-duration.text',
    defaultMessage: 'Member Session Duration',
  },
  'dashboard.text': {
    id: 'dashboard.text',
    defaultMessage: 'Dashboard',
  },
  'todays-instant-focus.text': {
    id: 'dashboard.text',
    defaultMessage: 'Dashboard',
  },
  'members.text': {
    id: 'members.text',
    defaultMessage: 'Members',
  },
  'member-management.text': {
    id: 'member-management.text',
    defaultMessage: 'Member Management',
  },
  'member-level-management.text': {
    id: 'member-level-management.text',
    defaultMessage: 'Member Marker Management',
  },
  'member-label-management.text': {
    id: 'member-label-management.text',
    defaultMessage: 'Label Management',
  },
  'members-online.text': {
    id: 'members-online.text',
    defaultMessage: 'Members Online',
  },
  'automation.text': {
    id: 'automation.text',
    defaultMessage: 'Automation',
  },
  'member-detail.text': {
    id: 'member-detail.text',
    defaultMessage: 'Member detail',
  },
  'operators.text': {
    id: 'operators.text',
    defaultMessage: 'Operators',
  },
  'permission-group.text': {
    id: 'permission-group.text',
    defaultMessage: 'Permission Group',
  },
  'permission-group-next.text': {
    id: 'permission-group-next.text',
    defaultMessage: 'Permission Group',
  },
  'deposits.text': {
    id: 'deposits.text',
    defaultMessage: 'Deposits',
  },
  'internal-member-deposits.text': {
    id: 'internal-member-deposits.text',
    defaultMessage: 'Internal Member Deposits',
  },
  'external-member-deposits.text': {
    id: 'external-member-deposits.text',
    defaultMessage: 'External Member Deposits',
  },
  'internal-deposit-requests.text': {
    id: 'internal-deposit-requests.text',
    defaultMessage: 'Deposit Requests (Internal)',
  },
  'external-deposit-requests.text': {
    id: 'external-deposit-requests.text',
    defaultMessage: 'Deposit Requests (3rd party)',
  },
  'payment-method-listing.text': {
    id: 'payment-method-listing.text',
    defaultMessage: 'Payment Method listing',
  },
  'deposit-providers-external.text': {
    id: 'deposit-providers-external.text',
    defaultMessage: '3rd Party Deposit Providers',
  },
  'third-party-deposit-providers.text': {
    id: 'third-party-deposit-providers.text',
    defaultMessage: 'Third party deposit providers',
  },
  'withdrawals.text': {
    id: 'withdrawals.text',
    defaultMessage: 'Withdrawals',
  },
  'withdrawal-requests.text': {
    id: 'withdrawal-requests.text',
    defaultMessage: 'Withdrawal Requests',
  },
  'withdrawal-methods.text': {
    id: 'withdrawal-methods.text',
    defaultMessage: 'Withdrawal methods',
  },
  'withdrawal-providers-external.text': {
    id: 'withdrawal-providers-external.text',
    defaultMessage: '3rd Party Withdrawal Providers',
  },
  'compliance-check.text': {
    id: 'compliance-check.text',
    defaultMessage: 'Compliance check',
  },
  'promo.text': {
    id: 'promo.text',
    defaultMessage: 'Promo',
  },
  'promo-listing.text': {
    id: 'promo-listing.text',
    defaultMessage: 'Promo Listing',
  },
  'promo-request.text': {
    id: 'promo-request.text',
    defaultMessage: 'Promo Request',
  },
  'promo-requests.text': {
    id: 'promo-requests.text',
    defaultMessage: 'Promo Requests',
  },
  'promo-labels.text': {
    id: 'promo-labels.text',
    defaultMessage: 'Promo Labels',
  },
  'promo-conflict.text': {
    id: 'promo-conflict.text',
    defaultMessage: 'Promo Conflict',
  },
  'promo-operating-log.text': {
    id: 'promo-operating-log.text',
    defaultMessage: 'Operating Log',
  },

  // new promo
  'promo-next.text': {
    id: 'promo-next.text',
    defaultMessage: 'Promo',
  },
  'create-promo.text': {
    id: 'create-promo.text',
    defaultMessage: 'Create Promo',
  },
  'edit-promo.text': {
    id: 'edit-promo.text',
    defaultMessage: 'Edit Promo',
  },
  'member-promo-history.text': {
    id: 'member-promo-history.text',
    defaultMessage: 'Member Promo History',
  },

  'manage-promo.text': {
    id: 'manage-promo.text',
    defaultMessage: 'Manage Promo',
  },

  'promo-payout-reports.text': {
    id: 'manage-payout-reports.text',
    defaultMessage: 'Promo Payout Records',
  },

  // 'account-management.text': {
  //   id: 'account-management.text',
  //   defaultMessage: 'Account Management',
  // },
  'vendor-management.text': {
    id: 'vendor-management.text',
    defaultMessage: 'Vendor Management',
  },
  'vendor-group.text': {
    id: 'vendor-group.text',
    defaultMessage: 'Vendor Group',
  },
  'vendors.text': {
    id: 'vendors.text',
    defaultMessage: 'Vendors',
  },
  'system-management.text': {
    id: 'system-management.text',
    defaultMessage: 'System Management',
  },
  'member-loyalty.text': {
    id: 'member-loyalty.text',
    defaultMessage: 'Member Loyalty',
  },
  'vip.text': {
    id: 'vip.text',
    defaultMessage: 'VIP',
  },
  'rebates.text': {
    id: 'rebates.text',
    defaultMessage: 'Rebates',
  },
  'reports.text': {
    id: 'reports.text',
    defaultMessage: 'Reports',
  },
  'daily-operating-report.text': {
    id: 'daily-operating-report.text',
    defaultMessage: 'Daily Operating Report',
  },
  'member-bet-records.text': {
    id: 'member-bet-records.text',
    defaultMessage: 'Member Bet Records',
  },
  'balance-transaction-records.text': {
    id: 'balance-transaction-records.text',
    defaultMessage: 'Balance Transaction Records',
  },
  'vendor-game-reports.text': {
    id: 'vendor-game-reports.text',
    defaultMessage: 'Vendor Game Reports',
  },
  'statistical-reports.text': {
    id: 'statistical-reports.text',
    defaultMessage: 'Statistical Reports',
  },
  'promo-payout-records.text': {
    id: 'promo-payout-records.text',
    defaultMessage: 'Promo Payout Records',
  },
  'analysis-reports.text': {
    id: 'analysis-reports.text',
    defaultMessage: 'Analysis Reports',
  },
  'rebates-reports.text': {
    id: 'rebates-reports.text',
    defaultMessage: 'Rebates Reports',
  },
  'total-number-of-participating-member.text': {
    id: 'total-number-of-participating-member.text',
    defaultMessage: 'Total Number of Participating Member',
  },
  'lottery-reports.text': {
    id: 'lottery-reports.text',
    defaultMessage: 'Lottery Reports',
  },
  'member-game-reports.text': {
    id: 'member-game-reports.text',
    defaultMessage: 'Member Game Reports',
  },
  'balance-service-record-report.text': {
    id: 'balance-service-record-report.text',
    defaultMessage: 'Balance Service Record Report',
  },
  'eu-reports.text': {
    id: 'eu-reports.text',
    defaultMessage: 'EU Reports',
  },
  'affiliates.text': {
    id: 'affiliates.text',
    defaultMessage: 'Affiliates',
  },
  'affiliate-programme.text': {
    id: 'affiliate-programme.text',
    defaultMessage: 'Affiliate Programme',
  },
  'agent-affiliate.text': {
    id: 'affiliate-programme.text',
    defaultMessage: 'Affiliate Programme',
  },
  'affiliate-criteria.text': {
    id: 'affiliate-criteria.text',
    defaultMessage: 'Affiliate Criteria',
  },
  'affiliate-request.text': {
    id: 'affiliate-request.text',
    defaultMessage: 'Affiliate Request',
  },
  'agent-request.text': {
    id: 'agent-request.text',
    defaultMessage: 'Agent Request',
  },
  'agent-payout-report.text': {
    id: 'agent-payout-report.text',
    defaultMessage: 'Payout Report',
  },
  'set-agency-commission.text': {
    id: 'set-agency-commission.text',
    defaultMessage: 'Set Agency Commission',
  },
  'site-configurations.text': {
    id: 'site-configurations.text',
    defaultMessage: 'Site Configurations',
  },
  'mobile-app-configuration.text': {
    id: 'mobile-app-configuration.text',
    defaultMessage: 'Mobile App Configuration',
  },
  'mobile-app-config-vendors.text': {
    id: 'mobile-app-config-vendors.text',
    defaultMessage: 'Mobile App Configuration Vendors',
  },
  'marquee-messages.text': {
    id: 'marquee-messages.text',
    defaultMessage: 'Marquee Messages',
  },
  'rotating-banner.text': {
    id: 'rotating-banner.text',
    defaultMessage: 'Rotating Banner',
  },
  'game-vendors.text': {
    id: 'game-vendors.text',
    defaultMessage: 'Game Vendors',
  },
  'category-games.text': {
    id: 'category-games.text',
    defaultMessage: '{category} - Games',
  },
  'category-vendor-games.text': {
    id: 'category-vendor-games.text',
    defaultMessage: '{category} / {vendorName} - Games',
  },
  'profile-setting.text': {
    id: 'profile-setting.text',
    defaultMessage: 'Profile Setting',
  },
  'alerts-and-notifications.text': {
    id: 'alerts-and-notifications.text',
    defaultMessage: 'Alerts and Notifications',
  },
  'specific-game-vendors.text': {
    id: 'specific-game-vendors.text',
    defaultMessage: '{category} Game Vendors',
  },
  'geo-fencing.text': {
    id: 'geo-fencing.text',
    defaultMessage: 'Geo-Fencing',
  },
  'system-message.text': {
    id: 'system-message.text',
    defaultMessage: 'System Message',
  },
  'manual-message.text': {
    id: 'manual-message.text',
    defaultMessage: 'Manual Message',
  },
  'automated-message.text': {
    id: 'automated-message.text',
    defaultMessage: 'Automated Message',
  },
  'deposit-requests-external.text': {
    id: 'deposit-requests-external.text',
    defaultMessage: 'Deposit Requests (3rd Party)',
  },
  'deposit-requests-internal.text': {
    id: 'deposit-requests-internal.text',
    defaultMessage: 'Deposit Requests (Internal)',
  },
  'new-withdrawal-requests.text': {
    id: 'new-withdrawal-requests.text',
    defaultMessage: 'New Withdrawal Requests',
  },

  // SuperAdmin
  'account-management.text': {
    id: 'account-management.text',
    defaultMessage: 'Account Management',
  },
  'report.text': {
    id: 'report.text',
    defaultMessage: 'Report',
  },
  'superadmin-all-client.text': {
    id: 'superadmin-all-client.text',
    defaultMessage: 'All Client',
  },
  'client-profile.text': {
    id: 'client-profile.text',
    defaultMessage: 'Client Profile',
  },
  'superadmin-geofencing.text': {
    id: 'superadmin-geofencing.text',
    defaultMessage: 'Geo-Fencing',
  },
  'superadmin-whitelist.text': {
    id: 'superadmin-whitelist.text',
    defaultMessage: 'Super Admin Whitelist',
  },
  'superadmin-vendor-maintenance.text': {
    id: 'superadmin-vendor-maintenance.text',
    defaultMessage: 'Vendor Maintenance',
  },
  'superadmin-vendor-group-management.text': {
    id: 'superadmin-vendor-group-management.text',
    defaultMessage: 'Vendor Group Management',
  },
  'superadmin-bet-history-report.text': {
    id: 'superadmin-bet-history-report.text',
    defaultMessage: 'Bet History Report',
  },
  'superadmin-accounts-consolidated-report.text': {
    id: 'superadmin-accounts-consolidated-report.text',
    defaultMessage: 'Consolidated Account Usage Report',
  },
  'superadmin-consolidated-vendor-usage-report.text': {
    id: 'superadmin-consolidated-vendor-usage-report.text',
    defaultMessage: 'Consolidated Vendor Usage Report',
  },
  'superadmin-custom-report.text': {
    id: 'superadmin-custom-report.text',
    defaultMessage: 'Custom Report',
  },
  'member/username.text': {
    id: 'member/username.text',
    defaultMessage: 'Member/{memberUsername}',
  },
  'account/username.text': {
    id: 'account/username.text',
    defaultMessage: 'Account / {memberUsername}',
  },
  'member-reports.text': {
    id: 'member-reports.text',
    defaultMessage: 'Member Reports',
  },
  'member-access-audit-history.text': {
    id: 'member-access-audit-history.text',
    defaultMessage: 'Member Access Audit History',
  },
  'member-ip-address-summary.text': {
    id: 'member-ip-address-summary.text',
    defaultMessage: 'Member IP Address Summary',
  },
  'ip-address-summary.text': {
    id: 'ip-address-summary.text',
    defaultMessage: 'IP Address Summary',
  },
  'member-access-summary-report.text': {
    id: 'member-access-summary-report.text',
    defaultMessage: 'Member Access Summary Report',
  },
  'member-interaction-log-report.text': {
    id: 'member-interaction-log-report.text',
    defaultMessage: 'Member Interaction Logs Report',
  },
  'missions.text': {
    id: 'missions.text',
    defaultMessage: 'Missions',
  },
  'force-profile-validation.text': {
    id: 'force-profile-validation.text',
    defaultMessage: 'Force Profile Validation',
  },
  'member-management-configuration.text': {
    id: 'member-management-configuration.text',
    defaultMessage: 'Member Management Config',
  },
  'system-management-general.text': {
    id: 'general-settings.text',
    defaultMessage: 'General Settings',
  },
  'mobile-portrait-configuration.text': {
    id: 'mobile-portrait-configuration.text',
    defaultMessage: 'Mobile Portrait Configuration',
  },
  'mobile-landscape-configuration.text': {
    id: 'mobile-landscape-configuration.text',
    defaultMessage: 'Mobile Landscape Configuration',
  },
  'mobile-landscape-vendor-configuration.text': {
    id: 'category-vendor-config-landscape.text',
    defaultMessage: 'Category / Vendor Config (Landscape App)',
  },
  'mobile-portrait-vendor-configuration.text': {
    id: 'category-vendor-config-portrait.text',
    defaultMessage: 'Category / Vendor Config (Mobile/H5)',
  },
  'desktop-configuration.text': {
    id: 'desktop-configuration.text',
    defaultMessage: 'Desktop Configuration',
  },
  'desktop-vendor-configuration.text': {
    id: 'desktop-vendor-configuration.text',
    defaultMessage: 'Category / Vendor Config (Desktop Website)',
  },
  'member-summary-report.text': {
    id: 'member-summary-report.text',
    defaultMessage: 'Member Summary Report',
  },
  'responsible-gaming.text': {
    id: 'responsible-gaming.text',
    defaultMessage: 'Responsible Gaming',
  },
  'common-configurations.text': {
    id: 'common-configurations.text',
    defaultMessage: 'Common Configurations',
  },
});

export default messages;
