import React from 'react';
import createContext from 'utils/createContext';

export const AffiliateTypes = {
  SET_ACTIVE_SCREEN: 'SET_ACTIVE_SCREEN',
  AGENT_AFFILIATE_LIST: 'AGENT_AFFILIATE_LIST',
  AGENT_AFFILIATE_DETAILS: 'AGENT_AFFILIATE_DETAILS',
  SHOW_CREATE_AFFILIATE_PROGRAMME: 'SHOW_CREATE_AFFILIATE_PROGRAMME',
  HIDE_CREATE_AFFILIATE_PROGRAMME: 'HIDE_CREATE_AFFILIATE_PROGRAMME',
  SET_DEFAULT_VENDORS: 'SET_DEFAULT_VENDORS',
  SET_DEFAULT_VENDOR_HANDLERS: 'SET_DEFAULT_VENDOR_HANDLERS',
  SET_ACTIVE_AFFILIATE_PROGRAMME: 'SET_ACTIVE_AFFILIATE_PROGRAMME',
  RESET_ACTIVE_AFFILIATE_PROGRAMME: 'RESET_ACTIVE_AFFILIATE_PROGRAMME',
  SET_SUMMARY: 'SET_SUMMARY',
  SET_REFETCH_VARIABLES: 'SET_REFETCH_VARIABLES',
};

type Action = {
  type: any;
  payload: any;
  meta: any;
};

export interface ContextProps {}

type Props = {
  children: React.ReactNode;
};

const initialState = {
  activeScreen: AffiliateTypes.AGENT_AFFILIATE_LIST,
  showCreateAffiliateProgramme: false,
  vendorHandlers: {},
  vendors: [],
  activeAffiliate: '',
  activeAffiliateData: {},
  recordSummary: '',
  refetchVariables: {},
};

type State = typeof initialState;

export const [useContext, ContextProvider] = createContext<ContextProps>();

export function AffiliateProvider(props: Props) {
  const { children } = props;

  const reducer = (state: State, { type, payload, meta }: Action) => {
    switch (type) {
      case AffiliateTypes.SET_ACTIVE_SCREEN:
        return {
          ...state,
          activeScreen: payload,
        };
      case AffiliateTypes.SET_REFETCH_VARIABLES:
        return {
          ...state,
          refetchVariables: payload,
        };
      case AffiliateTypes.SET_DEFAULT_VENDOR_HANDLERS:
        return {
          ...state,
          vendorHandlers: payload,
        };
      case AffiliateTypes.SET_DEFAULT_VENDORS:
        return {
          ...state,
          vendors: payload,
        };
      case AffiliateTypes.SHOW_CREATE_AFFILIATE_PROGRAMME:
        return {
          ...state,
          showCreateAffiliateProgramme: true,
        };
      case AffiliateTypes.HIDE_CREATE_AFFILIATE_PROGRAMME:
        return {
          ...state,
          showCreateAffiliateProgramme: false,
        };
      case AffiliateTypes.SET_ACTIVE_AFFILIATE_PROGRAMME:
        return {
          ...state,
          activeAffiliate: payload,
          activeAffiliateData: meta,
        };
      case AffiliateTypes.RESET_ACTIVE_AFFILIATE_PROGRAMME:
        return {
          ...state,
          activeAffiliate: '',
          activeAffiliateData: {},
        };
      case AffiliateTypes.SET_SUMMARY:
        return {
          ...state,
          recordSummary: payload,
        };
      default:
        return state;
    }
  };

  return (
    <ContextProvider value={React.useReducer(reducer, initialState)}>
      {children}
    </ContextProvider>
  );
}

export const useAffiliateState = () => useContext();
