import gql from 'graphql-tag';

export const START_PROCESS_DEPOSIT = gql`
  mutation StartProcessDeposit($id: ID!) {
    startProcessDeposit(id: $id)
  }
`;

export const STOP_PROCESS_DEPOSIT = gql`
  mutation StopProcessDeposit($id: ID!) {
    stopProcessDeposit(id: $id)
  }
`;

export const APPROVE_DEPOSIT = gql`
  mutation ApproveDeposit($id: ID!) {
    approveDeposit(id: $id)
  }
`;

export const REJECT_DEPOSIT = gql`
  mutation RejectDeposit($id: ID!) {
    rejectDeposit(id: $id)
  }
`;

export const UPDATE_DEPOSIT = gql`
  mutation UpdateDeposit($id: ID!, $remarks: String!) {
    updateDeposit(id: $id, remarks: $remarks)
  }
`;

export default {};
