import * as yup from 'yup';

export default yup.object().shape({
  successRateWeight: yup
    .number()
    .required()
    .typeError('Please enter a valid number')
    .min(0, 'Minimum atleast 0')
    .max(100, 'Allowed maximum is 100'),
  // .test("max", "Total should not exceed 100 %", function(value) {
  //   const { elapseMinuteWeight } = this.parent;
  //   return  value <= 100 - elapseMinuteWeight;
  // }),
  elapseMinuteWeight: yup
    .number()
    .required()
    .typeError('Please enter a valid number')
    .min(0, 'Minimum atleast 0')
    .max(100, 'Allowed maximum is 100'),
  // .test("max", "Total should not exceed 100 %", function(value) {
  //   const { successRateWeight = 0 } = this.parent;
  //   return  value <= 100 - successRateWeight;
  // })
});
