import React from 'react';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { DynamicObj } from 'interfaces/user.interface';

const ADMIN = gql`
  query Admin($id: ID!) {
    admin(id: $id) {
      id
      username
    }
  }
`;

export const ClientTag = ({ id }: { id: string }) => {
  const { filters, setFilters } = useFilterValues();
  const { data, loading, error } = useQuery(ADMIN, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const removeFilter = (idToBeRemoved: string, e: any) => {
    e.preventDefault();
    const newFilter = filters?.admins?.includesAny?.filter(
      (admin: string) => admin !== idToBeRemoved
    );

    if (newFilter.length === 0) {
      setFilters((prev: DynamicObj) => {
        const { admins, ...prevFilters } = prev;
        return {
          ...prevFilters,
        };
      });
    } else {
      setFilters((prev: DynamicObj) => ({
        ...prev,
        admins: { includesAny: newFilter },
      }));
    }
  };

  if (loading) {
    return (
      <Tag closable onClose={(e: any) => removeFilter(id, e)}>
        <LoadingOutlined />
      </Tag>
    );
  }

  if (error) {
    return (
      <Tag closable onClose={(e: any) => removeFilter(id, e)}>
        <FormattedMessage id="ERROR" defaultMessage="Error" />
      </Tag>
    );
  }

  return (
    <Tag closable onClose={(e: any) => removeFilter(id, e)}>
      {data?.admin?.username}
    </Tag>
  );
};
