import React, {
  useState,
  //  useEffect
} from 'react';
import { Divider, Typography, Button, message, Row, Col, Form } from 'antd';
import { useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { useConfig } from 'hooks/useConfig';
import ConfirmationModal from 'components/ConfirmationModal';
import {
  CreatePromoInput,
  Platform,
  PromoAwardPreRequisiteType,
  PromoAwardType,
  PromoClaimType,
  PromoEvent,
  PromoType,
} from 'types/graphqlTypes';
import { useMutation } from '@apollo/react-hooks';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { CREATE_PROMO_NEXT } from 'graphql/mutations/promo.mutation';
import removeNull from 'utils/removeNull';
import { CUSTOM_DATE_TIME_FORMAT } from 'constants/date';
import DepositPromoFields from './DepositPromoFields';
import SignUpPromoFields from './SignUpPromoFields';
import AdditionalConfig from './AdditionalConfig';
import PromoCommonFields from './PromoCommonFields';

const { Title } = Typography;

type FormValues = {
  type: PromoType | null;
  name: string;
  event: PromoEvent | null;
  validityDateTimeRange: Array<Moment | string> | null;
  platform: Platform | null;
  includedMemberTags: string[] | null;
  excludedMemberTags: string[] | null;
  claimType: PromoClaimType | string | null;
  awardPreRequisite: PromoAwardPreRequisiteType | null;
  amount: number | null;
  maxMemberCount: number | null;
  awardType: PromoAwardType | string | null;
  amountPercentage: number | null;
  awardCap: number | null;
  minAmount: number | null;
  maxAmount: number | null;
  claimCountLimit: number | null;
  paymentMethods: string[] | null;
  maxPayoutAmount: number | null;
  turnoverRequirementMultiplier: number | null;
  wagerContributions:
    | {
        rank: number | null;
        vendor: string | null;
        gameType: string | null;
        contribution: number | null;
      }[]
    | null;
  autoCancel: boolean;
  maintainingBalance: number | null;
};

const CreatePromo: React.FC = () => {
  const { context } = useOperatorHeader();

  const {
    currency: { symbol: currencySymbol },
  } = useConfig();

  const [promoTitleLength, setPromoTitleLength] = useState(0);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const defaultValues = {
    type: null,
    name: '',
    event: null,
    validityDateTimeRange: null,
    platform: Platform.Desktop,
    includedMemberTags: [],
    excludedMemberTags: [],
    claimType: PromoClaimType.Auto,
    awardPreRequisite: null,
    amount: null,
    maxMemberCount: null,
    awardType: 'FLAT',
    amountPercentage: null,
    awardCap: null,
    minAmount: null,
    maxAmount: null,
    paymentMethods: [],
    maxPayoutAmount: null,
    turnoverRequirementMultiplier: 0,
    wagerContributions: [
      {
        rank: null,
        vendor: null,
        gameType: null,
        contribution: null,
      },
    ],
    autoCancel: false,
    maintainingBalance: null,
  };

  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm<FormValues>({
    defaultValues,
    mode: 'all',
  });

  const {
    type: selectedPromoType,
    awardType: selectedPromoAwardType,
    autoCancel: enableKillSwitch,
    turnoverRequirementMultiplier: enableWagerTO,
  } = watch([
    'type',
    'awardType',
    'autoCancel',
    'turnoverRequirementMultiplier',
  ]);

  const isSignupPromo = selectedPromoType === 'SIGN_UP';
  const isDepositPromo = selectedPromoType === 'DEPOSIT';

  const [createPromo, { loading }] = useMutation<CreatePromoInput>(
    CREATE_PROMO_NEXT,
    {
      context,
    }
  );

  const onCreateNewPromo = async (values: FormValues) => {
    const newValues = removeNull(values);

    delete newValues.validityDateTimeRange;

    const wagerContributionRank = values?.wagerContributions?.map(
      (item, index) => ({
        ...item,
        rank: index + 1,
      })
    );

    try {
      await createPromo({
        variables: {
          input: {
            ...newValues,
            startDateTime: moment(values.validityDateTimeRange![0]).format(
              CUSTOM_DATE_TIME_FORMAT
            ),
            endDateTime: moment(values.validityDateTimeRange![1]).format(
              CUSTOM_DATE_TIME_FORMAT
            ),
            status: 'ACTIVE',
            wagerContributions: enableWagerTO ? wagerContributionRank : [],
            turnoverRequirementMultiplier: enableWagerTO
              ? values.turnoverRequirementMultiplier
              : 0,
          },
        },
      });
      message.success('Promo successfully created!');
      reset(defaultValues);
    } catch (err) {
      if (err) message.error('Something went wrong, Please try again.');
    } finally {
      setShowSubmitModal(false);
    }
  };

  const {
    type,
    name,
    event,
    platform,
    claimType,
    awardPreRequisite,
    amount,
    amountPercentage,
    maxMemberCount,
    awardType,
    awardCap,
    minAmount,
    maxAmount,
    claimCountLimit,
    paymentMethods,
    maxPayoutAmount,
    turnoverRequirementMultiplier,
    wagerContributions,
    validityDateTimeRange,
  } = errors;

  return (
    <div className="bg-white h-100 p-4">
      <ConfirmationModal
        title="Submit and Create Promo?"
        message="Are you sure?"
        visible={showSubmitModal}
        onCancel={() => setShowSubmitModal(false)}
        onOk={handleSubmit(onCreateNewPromo)}
        okButtonProps={loading}
      />
      <ConfirmationModal
        title="Cancel Promo Creation?"
        message="Are you sure?"
        visible={showCancelModal}
        onCancel={() => setShowCancelModal(false)}
        onOk={() => {
          reset();
          setPromoTitleLength(0);
          setShowCancelModal(false);
          message.success('Creation of Promo Cancelled.');
        }}
      />
      <div className="d-flex justify-content-between">
        <div>
          <Title level={3} type="secondary">
            Create New Promo
          </Title>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => setShowSubmitModal(true)}
            form="create-promo"
            className="mr-2"
          >
            Create New Promo
          </Button>
          <Button onClick={() => setShowCancelModal(true)}>Cancel</Button>
        </div>
      </div>
      <Divider />
      <Form
        wrapperCol={{
          span: 14,
        }}
        labelCol={{
          span: 8,
        }}
        layout="horizontal"
        id="create-promo"
      >
        <Row>
          <Col span={12}>
            <div className="d-flex flex-column">
              <PromoCommonFields
                control={control}
                selectedPromoType={selectedPromoType}
                setValue={setValue}
                promoTitleState={{
                  promoTitleLength,
                  setPromoTitle: setPromoTitleLength,
                }}
                fieldError={{
                  type,
                  name,
                  event,
                  platform,
                  claimType,
                  awardPreRequisite,
                  validityDateTimeRange,
                }}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className="d-flex flex-column">
              {isSignupPromo && (
                <SignUpPromoFields
                  control={control}
                  fieldError={{ amount, maxMemberCount }}
                  currencySymbol={currencySymbol}
                />
              )}
              {isDepositPromo && (
                <DepositPromoFields
                  selectedAwardType={selectedPromoAwardType}
                  control={control}
                  fieldError={{
                    awardType,
                    amount,
                    amountPercentage,
                    awardCap,
                    minAmount,
                    maxAmount,
                    claimCountLimit,
                    paymentMethods,
                    maxPayoutAmount,
                  }}
                  currencySymbol={currencySymbol}
                />
              )}
            </div>
          </Col>
        </Row>
        <Divider>
          <Title level={3} type="secondary">
            Additional Configuration
          </Title>
        </Divider>
        <AdditionalConfig
          clearErrors={clearErrors}
          control={control}
          setValue={setValue}
          fieldError={{
            turnoverRequirementMultiplier,
            wagerContributions,
          }}
          fieldToggles={{
            enableKillSwitch,
            enableWagerTO,
          }}
        />
      </Form>
    </div>
  );
};

export default CreatePromo;
