import gql from 'graphql-tag';

export const VENDORS = gql`
  query vendors($first: Int, $after: Binary, $filter: VendorsFilterInput) {
    vendors(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          name
          nameEn: name(language: EN)
          nameZh: name(language: ZH)
          type
          gameTypes
          # thumbnail
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
