// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const rowSource = (draggingIndex: number) => ({
  beginDrag({ index }: { index: number }) {
    // eslint-disable-next-line
    draggingIndex = index;
    return {
      index,
    };
  },
});

export const rowTarget = {
  drop(
    {
      index,
      moveRow,
    }: { index: number; moveRow: (drag: number, hover: number) => void },
    monitor: Record<string, any>
  ) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.

    // eslint-disable-next-line no-param-reassign
    monitor.getItem().index = hoverIndex;
  },
};
