import { defineMessages } from 'react-intl';

export const types = {
  ADMIN_MEMBER_DOCUMENT_CREATED: {
    module: 'ADMIN',
    action: 'ADMIN_MEMBER_DOCUMENT_CREATED',
  },
  MEMBER_REALITY_CHECK_ADMIN_UPDATED: {
    module: 'ADMIN',
    action: 'MEMBER_REALITY_CHECK_ADMIN_UPDATED',
  },
  MEMBER_VERIFIED: {
    module: 'MEMBER',
    action: 'MEMBER_VERIFIED',
  },
  ACCEPTED_TERMS_AND_CONDITIONS: {
    module: 'MEMBER',
    action: 'ACCEPTED_TERMS_AND_CONDITIONS',
  },
  MEMBER_COOLING_OFF: {
    module: 'MEMBER',
    action: 'MEMBER_COOLING_OFF',
  },
  MEMBER_DOCUMENT_CREATED: {
    module: 'MEMBER',
    action: 'MEMBER_DOCUMENT_CREATED',
  },
  MEMBER_DOCUMENT_APPROVED: {
    module: 'ADMIN',
    action: 'MEMBER_DOCUMENT_APPROVED',
  },
  MEMBER_DOCUMENT_REJECTED: {
    module: 'MEMBER',
    action: 'MEMBER_DOCUMENT_REJECTED',
  },
  MEMBER_TIME_OUT: {
    module: 'MEMBER',
    action: 'MEMBER_TIME_OUT',
  },
  MEMBER_CLOSED: {
    module: 'MEMBER',
    action: 'MEMBER_CLOSED',
  },
  MEMBER_LOCKED: {
    module: 'MEMBER',
    action: 'MEMBER_LOCKED',
  },
  ACCOUNT_PASSWORD_RESET_CREATED: {
    module: 'ACCOUNT',
    action: 'ACCOUNT_PASSWORD_RESET_CREATED',
  },
  ACCOUNT_PASSWORD_RESET_VERIFIED: {
    action: 'ACCOUNT_PASSWORD_RESET_VERIFIED',
    module: 'ACCOUNT',
  },
  ACCOUNT_PASSWORD_RESET_EXPIRED: {
    action: 'ACCOUNT_PASSWORD_RESET_EXPIRED',
    module: 'ACCOUNT',
  },
  MEMBER_SELF_EXCLUDED: {
    module: 'MEMBER',
    action: 'MEMBER_SELF_EXCLUDED',
  },
  MEMBER_GAMSTOP_SELF_EXCLUDED: {
    module: 'MEMBER',
    action: 'MEMBER_GAMSTOP_SELF_EXCLUDED',
  },
  MEMBER_ACTIVATED: {
    module: 'MEMBER',
    action: 'MEMBER_ACTIVATED',
  },
  MEMBER_REALITY_CHECK_CONTINUED: {
    module: 'MEMBER',
    action: 'MEMBER_REALITY_CHECK_CONTINUED',
  },
  MEMBER_REALITY_CHECK_DISCONTINUED: {
    module: 'MEMBER',
    action: 'MEMBER_REALITY_CHECK_DISCONTINUED',
  },
  MEMBER_LOGGED_IN: {
    module: 'MEMBER',
    action: 'MEMBER_LOGGED_IN',
  },
  MEMBER_LOGGED_OUT: {
    module: 'MEMBER',
    action: 'MEMBER_LOGGED_OUT',
  },
  SESSION_EXPIRED: {
    module: 'MEMBER',
    action: 'SESSION_EXPIRED',
  },
  MEMBER_LOYALTY_LEVEL_UPGRADED: {
    module: 'MEMBER_LOYALTY_LEVEL',
    action: 'MEMBER_LOYALTY_LEVEL_UPGRADED',
  },
  MEMBER_LOYALTY_LEVEL_DOWNGRADED: {
    module: 'MEMBER_LOYALTY_LEVEL',
    action: 'MEMBER_LOYALTY_LEVEL_DOWNGRADED',
  },
  DEPOSIT_REQUEST_CREATED: {
    module: 'DEPOSIT_REQUEST',
    action: 'DEPOSIT_REQUEST_CREATED',
  },
  DEPOSIT_REQUEST_APPROVED: {
    module: 'DEPOSIT_REQUEST',
    action: 'DEPOSIT_REQUEST_APPROVED',
  },
  DEPOSIT_REQUEST_REJECTED: {
    module: 'DEPOSIT_REQUEST',
    action: 'DEPOSIT_REQUEST_REJECTED',
  },
  WITHDRAWAL_REQUEST_CREATED: {
    module: 'WITHDRAWAL_REQUEST',
    action: 'WITHDRAWAL_REQUEST_CREATED',
  },
  WITHDRAWAL_REQUEST_APPROVED: {
    module: 'WITHDRAWAL_REQUEST',
    action: 'WITHDRAWAL_REQUEST_APPROVED',
  },
  WITHDRAWAL_REQUEST_REJECTED: {
    module: 'WITHDRAWAL_REQUEST',
    action: 'WITHDRAWAL_REQUEST_REJECTED',
  },
  VENDOR_BALANCE_CREDITED: {
    module: 'VENDOR',
    action: 'VENDOR_BALANCE_CREDITED',
  },
  VENDOR_BALANCE_DEBITED: {
    module: 'VENDOR',
    action: 'VENDOR_BALANCE_DEBITED',
  },
  VENDOR_GAME_LAUNCHED: {
    module: 'VENDOR',
    action: 'VENDOR_GAME_LAUNCHED',
  },
  MEMBER_COOLING_OFF_ENDED: {
    module: 'MEMBER',
    action: 'MEMBER_COOLING_OFF_ENDED',
  },
  MEMBER_FRAUD: {
    module: 'MEMBER',
    action: 'MEMBER_FRAUD',
  },
};

export const actors = ['OPERATOR', 'CHANNEL', 'SYSTEM'];

export const modules = [
  'MEMBER',
  'MEMBER_LOYALTY_LEVEL',
  'DEPOSIT_REQUEST',
  'WITHDRAWAL_REQUEST',
  'VENDOR',
];

export const messages = defineMessages({
  ADMIN_MEMBER_DOCUMENT_CREATED: {
    id: 'ADMIN_MEMBER_DOCUMENT_CREATED',
    defaultMessage: 'Member Document Created',
  },
  MEMBER_REALITY_CHECK_ADMIN_UPDATED: {
    id: 'MEMBER_REALITY_CHECK_ADMIN_UPDATED',
    defaultMessage: 'Reality Check Updated',
  },
  MEMBER_VERIFIED: {
    id: 'MEMBER_VERIFIED',
    defaultMessage: 'Member Verified',
  },
  ACCEPTED_TERMS_AND_CONDITIONS: {
    id: 'ACCEPTED_TERMS_AND_CONDITIONS',
    defaultMessage: 'Accepted Terms and Conditions',
  },
  MEMBER_FRAUD: {
    id: 'MEMBER_FRAUD',
    defaultMessage: 'Member Fraud',
  },
  MEMBER_COOLING_OFF_ENDED: {
    id: 'MEMBER_COOLING_OFF_ENDED',
    defaultMessage: 'Member Cooling-off ended',
  },
  MEMBER_COOLING_OFF: {
    id: 'MEMBER_COOLING_OFF',
    defaultMessage: 'Member Cooling-off',
  },
  MEMBER_TIME_OUT: {
    id: 'MEMBER_TIME_OUT',
    defaultMessage: 'Member Time-Out',
  },
  MEMBER_CLOSED: {
    id: 'MEMBER_CLOSED',
    defaultMessage: 'Member Closed',
  },
  MEMBER_LOCKED: {
    id: 'MEMBER_LOCKED',
    defaultMessage: 'Member Locked',
  },
  ACCOUNT_PASSWORD_RESET_CREATED: {
    id: 'ACCOUNT_PASSWORD_RESET_CREATED',
    defaultMessage: 'Account Password Reset Created',
  },
  ACCOUNT_PASSWORD_RESET_VERIFIED: {
    id: 'ACCOUNT_PASSWORD_RESET_VERIFIED',
    defaultMessage: 'Account Password Reset Verified',
  },
  ACCOUNT_PASSWORD_RESET_EXPIRED: {
    id: 'ACCOUNT_PASSWORD_RESET_EXPIRED',
    defaultMessage: 'Account Password Reset Expired',
  },
  MEMBER_ACTIVATED: {
    id: 'MEMBER_ACTIVATED',
    defaultMessage: 'Activated',
  },
  MEMBER_SELF_EXCLUDED: {
    id: 'MEMBER_SELF_EXCLUDED',
    defaultMessage: 'Self-Excluded',
  },
  MEMBER_GAMSTOP_SELF_EXCLUDED: {
    id: 'MEMBER_GAMSTOP_SELF_EXCLUDED',
    defaultMessage: 'Gamstop Self-Excluded',
  },
  MEMBER_REALITY_CHECK_CONTINUED: {
    id: 'MEMBER_REALITY_CHECK_CONTINUED',
    defaultMessage: 'Reality Check - Continued',
  },
  MEMBER_REALITY_CHECK_DISCONTINUED: {
    id: 'MEMBER_REALITY_CHECK_DISCONTINUED',
    defaultMessage: 'Reality Check - Discontinued',
  },
  MEMBER_DOCUMENT_CREATED: {
    id: 'MEMBER_DOCUMENT_CREATED',
    defaultMessage: 'Member Document Created',
  },
  MEMBER_DOCUMENT_APPROVED: {
    id: 'MEMBER_DOCUMENT_APPROVED',
    defaultMessage: 'Member Document Approved',
  },
  MEMBER_DOCUMENT_REJECTED: {
    id: 'MEMBER_DOCUMENT_REJECTED',
    defaultMessage: 'Member Document Rejected',
  },
  OPERATOR: {
    id: 'operator.text',
    defaultMessage: 'Operator',
  },
  CHANNEL: {
    id: 'member.text',
    defaultMessage: 'Member',
  },
  ACCOUNT: {
    id: 'ACCOUNT',
    defaultMessage: 'Account',
  },
  ADMIN: {
    id: 'admin.text',
    defaultMessage: 'Admin',
  },
  MEMBER: {
    id: 'member.text',
    defaultMessage: 'Member',
  },
  SYSTEM: {
    id: 'system.text',
    defaultMessage: 'System',
  },
  DEPOSIT: {
    id: 'deposit.text',
    defaultMessage: 'Deposit',
  },
  WITHDRAWAL: {
    id: 'withdrawal.text',
    defaultMessage: 'Withdrawal',
  },
  DEPOSIT_REQUEST: {
    id: 'deposit.text',
    defaultMessage: 'Deposit',
  },
  WITHDRAWAL_REQUEST: {
    id: 'withdrawal.text',
    defaultMessage: 'Withdrawal',
  },
  MEMBER_LOYALTY_LEVEL: {
    id: 'member-loyalty.text',
    defaultMessage: 'Member Loyalty',
  },
  CREATED: {
    id: 'created.text',
    defaultMessage: 'Created',
  },
  APPROVED: {
    id: 'approved.text',
    defaultMessage: 'Approved',
  },
  REJECTED: {
    id: 'rejected.text',
    defaultMessage: 'Rejected',
  },
  LOGGED_IN: {
    id: 'logged-in.text',
    defaultMessage: 'Logged In',
  },
  LOGGED_OUT: {
    id: 'logged-out.text',
    defaultMessage: 'Logged Out',
  },
  UPGRADED: {
    id: 'upgraded.text',
    defaultMessage: 'Upgraded',
  },
  DOWNGRADED: {
    id: 'downgraded.text',
    defaultMessage: 'Downgraded',
  },
  MEMBER_LOGGED_IN: {
    id: 'member-logged-in.text',
    defaultMessage: 'Member Logged In',
  },
  MEMBER_LOGGED_OUT: {
    id: 'member-logged-out.text',
    defaultMessage: 'Member Logged Out',
  },
  DEPOSIT_REQUEST_CREATED: {
    id: 'deposit-request-created.text',
    defaultMessage: 'Deposit Request Created',
  },
  DEPOSIT_REQUEST_APPROVED: {
    id: 'deposit-request-approved.text',
    defaultMessage: 'Deposit Request Approved',
  },
  DEPOSIT_REQUEST_REJECTED: {
    id: 'deposit-request-rejected.text',
    defaultMessage: 'Deposit Request Rejected',
  },
  WITHDRAWAL_REQUEST_CREATED: {
    id: 'withdrawal-request-created.text',
    defaultMessage: 'Withdrawal Request Created',
  },
  WITHDRAWAL_REQUEST_APPROVED: {
    id: 'withdrawal-request-approved.text',
    defaultMessage: 'Withdrawal Request Approved',
  },
  WITHDRAWAL_REQUEST_REJECTED: {
    id: 'withdrawal-request-rejected.text',
    defaultMessage: 'Withdrawal Request Rejected',
  },
  MEMBER_LOYALTY_LEVEL_UPGRADED: {
    id: 'member-loyalty-level-upgraded.text',
    defaultMessage: 'Member Loyalty Level Upgraded',
  },
  MEMBER_LOYALTY_LEVEL_DOWNGRADED: {
    id: 'member-loyalty-level-downgraded.text',
    defaultMessage: 'Member Loyalty Level Downgraded',
  },
  SESSION_EXPIRED: {
    id: 'session-expired.text',
    defaultMessage: 'Session Expired',
  },
  VENDOR_BALANCE_CREDITED: {
    id: 'vendor-balance-credited.text',
    defaultMessage: 'Vendor Balance Credited',
  },
  VENDOR_BALANCE_DEBITED: {
    id: 'vendor-balance-debited.text',
    defaultMessage: 'Vendor Balance Debited',
  },
  BALANCE_CREDITED: {
    id: 'balance-credited.text',
    defaultMessage: 'Balance Credited',
  },
  BALANCE_DEBITED: {
    id: 'balance-debited.text',
    defaultMessage: 'Balance Debited',
  },
  VENDOR_GAME_LAUNCHED: {
    id: 'vendor-game-launched.text',
    defaultMessage: 'Vendor Game Launched',
  },
  GAME_LAUNCHED: {
    id: 'game-launched.text',
    defaultMessage: 'Game Launched',
  },
  VENDOR: {
    id: 'vendor.text',
    defaultMessage: 'Vendor',
  },
});
