import * as Yup from 'yup';
import { get } from 'lodash';
import { ApolloClient } from 'apollo-client';
import messages from 'messages';
import { ISystemMessageNode } from '../../interfaces';
import { MULT_MEMBERS_QUERY } from './queries';
import { State } from './interface';

export const buildMessageType = {
  target: 'target',
  excluded: 'excluded',
};

export const getMultMembersId = (values: any[]) => {
  const keys = Object.keys(values);
  return keys.reduce((acc, key) => {
    acc[key] = values[key].in.map((val: any) => val.id);
    return acc;
  }, {});
};

export const buildCreateMessageFormat = (
  members: { __typename: string; id: string }[],
  type: string
) => ({
  [`${type}Members`]: members
    .filter(({ __typename }) => __typename === 'Member')
    .map((member) => member.id),
  [`${type}MemberLevels`]: members
    .filter(({ __typename }) => __typename === 'MemberLevel')
    .map((member) => member.id),
  [`${type}MemberLoyaltyLevels`]: members
    .filter(({ __typename }) => __typename === 'MemberLoyaltyLevel')
    .map((member) => member.id),
});

export const multiSelectType = {
  SIDEBAR: 'SIDEBAR_TYPE',
  FORM: 'FORM_TYPE',
};

export const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const validationSchema = (
  translate: any,
  maxTitle: number,
  maxContent: number
) =>
  Yup.object().shape({
    mailTitle: Yup.string()
      .nullable()
      .required(translate(messages['mail-title-field-required.text']))
      .max(
        maxTitle,
        translate(messages['maximum-number-of-characters.text'], {
          count: maxTitle,
        })
      ),
    mailContent: Yup.string()
      .nullable()
      .max(
        maxContent,
        translate(messages['maximum-number-of-characters.text'], {
          count: maxContent,
        })
      ),
    scheduleSend: Yup.bool(),
    date: Yup.string()
      .nullable()
      .when('scheduleSend', {
        is: (val) => val,
        then: Yup.string().required(
          translate(messages['date-field-required.text'])
        ),
      }),
    time: Yup.string()
      .nullable()
      .when('scheduleSend', {
        is: (val) => val,
        then: Yup.string().required(
          translate(messages['time-field-required.text'])
        ),
      }),
  });
export const messageType = {
  WHOLE_SITE_MEMBER: 'WHOLE_SITE_MEMBER',
  TARGET_MEMBERS: 'TARGET_MEMBERS',
};

export enum actions {
  SET_TARGET_MEMBERS,
  SET_EXCLUDED_MEMBERS,
  SET_IS_SUBMITTING,
  SET_INITIAL_VALUES,
  SET_QUALIFYING,
}

export const initialReducerState: State = {
  targetMembers: [],
  excludedMembers: [],
  isSubmitting: false,
  initialValues: {
    qualifying: messageType.WHOLE_SITE_MEMBER,
    mailTitle: '',
    mailContent: '',
    scheduleSend: false,
    date: undefined,
    time: undefined,
  },
};
export const reducer = (state: State, [type, payload]: [number, any]) => {
  switch (type) {
    case actions.SET_TARGET_MEMBERS:
      return {
        ...state,
        targetMembers: payload,
      };
    case actions.SET_EXCLUDED_MEMBERS:
      return {
        ...state,
        excludedMembers: payload,
      };
    case actions.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: payload,
      };
    case actions.SET_INITIAL_VALUES:
      return {
        ...state,
        initialValues: payload,
      };
    case actions.SET_QUALIFYING:
      return {
        ...state,
        initialValues: {
          ...state.initialValues,
          qualifying: payload,
        },
      };
    default:
      return state;
  }
};

export const preLoadMultMembers = async ({
  client,
  existingMessage,
  dispatch,
  type,
}: {
  client: ApolloClient<object>;
  existingMessage: ISystemMessageNode;
  dispatch: any;
  type: 'target' | 'excluded';
}) => {
  const typeMembers = `${type}Members`;
  const typeMemberLevels = `${type}MemberLevels`;
  const typeMemberLoyaltyLevels = `${type}MemberLoyaltyLevels`;
  const membersId = existingMessage[typeMembers].map(
    (x: { id: string }) => x.id
  );
  const memberLevelsId = existingMessage[typeMemberLevels].map(
    (x: { id: string }) => x.id
  );
  const memberLoyaltyLevelsId = existingMessage[typeMemberLoyaltyLevels].map(
    (x: { id: string }) => x.id
  );

  const response = await client.query({
    query: MULT_MEMBERS_QUERY,
    variables: {
      membersId,
      memberLevelsId,
      memberLoyaltyLevelsId,
    },
    fetchPolicy: 'network-only',
  });
  const members = get(response, 'data.members.edges', []).map(
    (edge: any) => edge.node
  );
  const memberLevels = get(response, 'data.memberLevels.edges', []).map(
    (edge: any) => edge.node
  );

  const memberLoyaltyLevels = get(
    response,
    'data.memberLoyaltyLevels.edges',
    []
  ).map((edge: any) => edge.node);

  const membersData = {
    [typeMembers]: { in: members },
    [typeMemberLevels]: { in: memberLevels },
    [typeMemberLoyaltyLevels]: { in: memberLoyaltyLevels },
  };
  dispatch([
    type === 'target'
      ? actions.SET_TARGET_MEMBERS
      : actions.SET_EXCLUDED_MEMBERS,
    membersData,
  ]);
};
