import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select, ConfigProvider, Empty } from 'antd';
import messages from 'pages/components/Reports/messages';
import useTranslate from 'utils/useTranslate';
import { useAccount } from 'store/accountState';
import { Admin } from 'types/graphqlTypes';
import { StyledLabel, SharedStyledSelect } from 'styles/SharedStyledSelect';
import { SharedStyledSpan } from 'styles/SharedStyledSpan';

const { Option } = Select;

type Props = {
  setState?: (value: any) => void;
  state: { [key: string]: Record<string, string> };
  rawHandleOnChange?: (value: string[]) => void;
  testId?: string;
};

const BrandFilter = ({ state, setState, rawHandleOnChange, testId }: Props) => {
  const translate = useTranslate();

  const {
    account: { account },
  } = useAccount();

  const brands = (account as Admin)?.linkedAdmins?.concat([
    { id: account?.id!, company: (account as Admin)?.company! },
  ]);

  const values = state?.brand?.in || [];

  const handleChange = (e: any) => {
    if (rawHandleOnChange) {
      rawHandleOnChange(e);
      return;
    }

    if (e.length) {
      setState?.((prev: Record<string, any>) => ({
        ...prev,
        brand: { in: e },
      }));
    } else {
      setState?.((prev: Record<string, any>) => ({
        ...prev,
        brand: null,
      }));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage id="brand.text" defaultMessage="Brand" />
          </StyledLabel>
        </div>
        <div>
          <SharedStyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </SharedStyledSpan>
        </div>
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={translate(messages['no-data.text'])}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        <SharedStyledSelect
          data-testid={testId}
          className="w-100"
          showSearch
          allowClear
          mode="multiple"
          placeholder={
            <FormattedMessage
              id="reports.search.text"
              defaultMessage="Search"
            />
          }
          value={values}
          onChange={handleChange}
          optionFilterProp="children"
          filterOption={(
            input: string,
            option: { props: { children: string } }
          ) =>
            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {brands?.map((brand: any) => (
            <Option key={brand.id} value={brand.company}>
              {brand.company}
            </Option>
          ))}
        </SharedStyledSelect>
      </ConfigProvider>
    </>
  );
};

export default BrandFilter;
