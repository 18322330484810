import { TabId } from 'constants/route';
import FiltersProvider from 'contexts/Filters';
import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import { TabId as TabIdType } from 'types/nav';
import WithdrawalProvidersScreen from './components/WithdrawalProvidersScreen';

const WithdrawalProviders = () => {
  const { getTab } = useScreenTabV2();
  const { filter = {} } =
    getTab(TabId.THIRD_PARTY_WITHDRAWAL_PROVIDERS as TabIdType) || {};
  const defaultFilters = {
    name: null,
    enabled: null,
    nickname: null,
    type: null,
    withdrawalProvider: null,
    bank: null,
  };

  const initialFilters = {
    ...defaultFilters,
    ...filter,
  };

  return (
    <FiltersProvider
      initialValues={initialFilters}
      defaultValues={defaultFilters}
      tabId={TabId.THIRD_PARTY_WITHDRAWAL_PROVIDERS as TabIdType}
    >
      <WithdrawalProvidersScreen />
    </FiltersProvider>
  );
};

export default WithdrawalProviders;
