import React from 'react';

const MarqueeContext = React.createContext<any>({});

type Props = {
  children?: React.ReactNode;
  setPage: (e: any) => void;
  page: Record<string, any>;
  refetchVariables: Record<string, any>;
};

export const MarqueeProvider = ({
  children,
  setPage,
  page,
  refetchVariables,
}: Props) => (
  <MarqueeContext.Provider value={{ setPage, page, refetchVariables }}>
    {children}
  </MarqueeContext.Provider>
);

export const useMarqueeValue = () => React.useContext(MarqueeContext);
