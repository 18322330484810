import * as Yup from 'yup';
import messages from 'messages';

export const validationSchema = (translate: any) =>
  Yup.object().shape({
    color: Yup.string()
      .nullable()
      .required(
        translate(messages['member-marker.this-field-is-required.text'])
      ),
    default: Yup.boolean()
      .nullable()
      .required(
        translate(messages['member-marker.this-field-is-required.text'])
      ),
    name: Yup.string()
      .nullable()
      .required(
        translate(messages['member-marker.this-field-is-required.text'])
      ),
    status: Yup.string()
      .nullable()
      .required(
        translate(messages['member-marker.this-field-is-required.text'])
      ),
  });
