import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { DELETE_WITHDRAWAL_METHOD } from 'graphql/mutations/withdrawalMethod.mutation';
import { WITHDRAWAL_METHODS } from 'graphql/queries/withdrawalMethod.query';
import messages from 'messages';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';

const DeleteWithdrawalMethod = (props: {
  id?: string;
  toggleModal: () => void;
  refetchVariables: Record<string, any>;
}) => {
  const translate = useTranslate();
  const { toggleModal, id, refetchVariables } = props;
  const { context } = useOperatorHeader();
  const [deleteWithdrawalMethod, { loading }] = useMutation(
    DELETE_WITHDRAWAL_METHOD,
    {
      onCompleted: () => {
        message.success(`${translate(messages.WITHDRAWAL_METHOD_DELETED)}`);
        toggleModal();
      },
      context,
    }
  );

  return (
    <Modal
      visible
      maskClosable={false}
      closable={false}
      width={416}
      okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
      cancelText={<FormattedMessage id="cancel.text" defaultMessage="Cancel" />}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={() => {
        deleteWithdrawalMethod({
          variables: {
            id,
          },
          refetchQueries: [
            {
              query: WITHDRAWAL_METHODS,
              fetchPolicy: 'network-only',
              variables: refetchVariables,
            },
          ],
        } as any);
      }}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">
          <FormattedMessage id="confirm.text" defaultMessage="Confirm" />{' '}
          <FormattedMessage id="delete.text" defaultMessage="Delete" />
        </span>
        <div className="ant-modal-confirm-content">
          <FormattedMessage
            id="are-you-sure.text"
            defaultMessage="Are you sure?"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteWithdrawalMethod;
