import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import {
  GET_MEMBER_IP_ADDRESS_SUMMARY,
  GET_MIAS_ID_BASED,
  GET_MIAS_STRING_BASED,
} from 'graphql/queries/memberIpAddressSummary.query';
import { Page } from 'interfaces/user.interface';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { useScreenTabV2 } from 'store/screenTabState';
import { StyledTagContainer } from 'styles';
import {
  MemberIpAddressReportItem,
  MemberIpAddressReportItemsFilterInput,
  Scalars,
} from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import compose from 'utils/compose';
import { createPartialUtil } from 'utils/partialUtils';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import SpreadsheetProvider, {
  useSpreadsheetContext,
} from 'contexts/Spreadsheet';
import FilterConditions from './components/FilterConditions';
import HeaderRight from './components/HeaderRight';
import Sidebar from './components/Sidebar';
import TableIndex from './components/TableIndex';
import getColumns, { OriginalColumn } from './components/TableIndex/columns';
import {
  MemberFromTab,
  QueryResult,
  StateFilter,
  UnifiedFilters,
} from './types';
import { collateMemberFilterFields } from './utils';

export type NullableType<T> = T | null;

const DEFAULT_ROW_COUNT = 10;

const pageInitState: Page = {
  after: undefined,
  savedCursor: [undefined] as any,
  currentPage: 0,
  first: DEFAULT_ROW_COUNT,
};

const initFilterState: StateFilter = {
  usernames: null,
  realnames: null,
  ipAddresses: null,
  brand: null,
};

const emptyMember: MemberFromTab = {
  id: null,
  username: null,
};

const isPropsEmpty = (obj: MemberFromTab) =>
  !Object.values(obj).every((prop) => !!prop);

const isPropsActive = (obj: UnifiedFilters) =>
  Object.values(obj).some((prop) => !!prop);

const MemberIpAddressSummary: React.FC = () => {
  const { setTableColumns } = useSpreadsheetContext();

  const { thisTab, updateTab, useUpdateTab, addTab } = useScreenTabV2(
    'member-ip-address-summary'
  );

  const filterFromTab = coercedGet(thisTab, 'filter', {});
  const ipAddressFromTab = coercedGet(thisTab, 'ipAddress', '');
  const memberFromTab: MemberFromTab = coercedGet(
    thisTab,
    'member',
    emptyMember
  );

  const [filter, setFilter] = useState<MemberIpAddressReportItemsFilterInput>(
    filterFromTab
  );

  const [unifiedFilters, setUnifiedFilters] = useState<UnifiedFilters>({});

  const [stateFilters, setStateFilters] = useState<StateFilter>(
    initFilterState
  );
  const [columns, setColumns] = useState<OriginalColumn[]>([]);
  const translate = useTranslate();

  const [page, setPage] = useState<Page>(pageInitState);

  const idQueries = {
    query: GET_MIAS_ID_BASED,
    sourceFiltFields: ['memberUserName', 'memberRealName'],
    targetStringFields: ['username', 'realName'],
    targetFilterFields: ['member', 'member'],
  };

  const filtProcessorFunc = compose(removeNull, collateMemberFilterFields);

  const usePartialFilterUtil = createPartialUtil('id', filtProcessorFunc);
  const { locale } = useIntl();
  const {
    loading,
    data,
    error,
    refetch,
    finalQueryFilter,
  } = usePartialFilterUtil(
    GET_MIAS_STRING_BASED,
    GET_MEMBER_IP_ADDRESS_SUMMARY,
    'memberIpAddressReport.edges',
    unifiedFilters,
    page,
    ['ipAddress'],
    idQueries,
    locale.toUpperCase()
  );

  const dataSource: MemberIpAddressReportItem[] = coercedGet(
    data as QueryResult,
    'memberIpAddressReport.edges',
    []
  ).map((edge: any) => edge!.node);

  const totalCount: number = coercedGet(
    data as QueryResult,
    'memberIpAddressReport.totalCount',
    0
  );

  const pageInfo = coercedGet(
    data as QueryResult,
    'memberIpAddressReport.pageInfo',
    {}
  );

  const totalPage: number = Math.ceil(totalCount / page.first) || 1;

  const { filterColumns } = useCustomColumnsV2(
    'member-ip-address-summary',
    columns
  );

  const handleStateFilters = (filterData: StateFilter): void => {
    setStateFilters((state) => ({ ...state, ...filterData }));

    const memberIds: Scalars['ID'][] = [
      ...coercedGet({ ...stateFilters, ...filterData }, 'usernames', []),
      ...coercedGet({ ...stateFilters, ...filterData }, 'realnames', []),
    ];

    const ipAddressValues: Scalars['IpAddress'] = [
      ...coercedGet({ ...stateFilters, ...filterData }, 'ipAddresses', []),
    ];

    const brandValues = [
      ...coercedGet({ ...stateFilters, ...filterData }, 'brand', []),
    ];

    updateTab({
      filter: {
        ...(memberIds.length && {
          member: {
            in: memberIds,
          },
        }),
        ...(ipAddressValues.length && {
          ipAddress: {
            in: ipAddressValues,
          },
        }),
        ...(brandValues.length && {
          brand: {
            in: brandValues,
          },
        }),
      },
    });
  };

  const resetStateFilters = () => {
    setStateFilters(initFilterState);
    updateTab({
      filter: {},
    });
  };

  const handleNext = (): void => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = (): void => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage] || undefined;
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const handleRedirectByFilter = ({
    id,
    ipAddress,
  }: {
    id: string;
    ipAddress: string;
  }): void => {
    addTab({
      id: 'member-access-audit-history',
      state: {
        memberId: id,
        ipAddress,
      },
    });
  };

  useEffect(() => {
    setUnifiedFilters({
      member: null,
      memberUserName: stateFilters?.usernames?.length
        ? { in: stateFilters.usernames }
        : null,
      memberRealName: stateFilters?.realnames?.length
        ? { in: stateFilters.realnames }
        : null,
      ipAddress: stateFilters?.ipAddresses?.length
        ? { in: stateFilters.ipAddresses }
        : null,
      brand: stateFilters?.brand?.length ? { in: stateFilters.brand } : null,
    });
  }, [stateFilters]);

  useEffect(() => {
    setTableColumns(getColumns(translate, handleRedirectByFilter));

    setColumns(getColumns(translate, handleRedirectByFilter));
    // eslint-disable-next-line
  }, [translate]);

  useEffect(() => {
    if (!isPropsEmpty(memberFromTab)) {
      const id = coercedGet(memberFromTab, 'id', '');
      setStateFilters((state) => ({
        ...state,
        usernames: [id],
      }));
      updateTab({
        filter: {
          member: {
            in: [id],
          },
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberFromTab]);

  useEffect(() => {
    if (ipAddressFromTab) {
      setStateFilters((state) => ({
        ...state,
        ipAddresses: [ipAddressFromTab],
      }));
      updateTab({
        filter: {
          ipAddress: {
            in: [ipAddressFromTab],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipAddressFromTab]);

  useUpdateTab(() => {
    setPage((prevData: Page) => {
      const { first, ...restOfPageInitState } = pageInitState;
      return {
        ...restOfPageInitState,
        first: prevData.first,
      };
    });
    setFilter(filterFromTab);
  });

  const [expandedTags, setExpandedTags] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <Layout.Header
        leftNode={
          <div className="d-flex align-items-center flex-g-1">
            <ToggleSidebarHead />
            <StyledTagContainer ref={ref} isExpanded={expandedTags}>
              <span className="ml-1">
                {translate(messages.FILTER_CONDITIONS)}:{' '}
              </span>
              <FilterConditions
                stateFilters={stateFilters}
                onFilterChange={handleStateFilters}
                isLoading={loading}
              />
            </StyledTagContainer>
            <LegacyIconToggle
              forwardedRef={ref}
              filters={filter}
              expandedTags={expandedTags}
              toggleExpandedTags={setExpandedTags}
            />
            {isPropsActive(unifiedFilters) && (
              <ALink onClick={resetStateFilters}>Clear All</ALink>
            )}
          </div>
        }
        rightNode={
          <HeaderRight
            refreshPage={refetch}
            withRealtime={false}
            filter={finalQueryFilter}
          />
        }
      />
      <Layout.Content
        sideBar={
          <Sidebar
            filter={filter}
            onFilterChange={handleStateFilters}
            isLoading={false}
            stateFilters={stateFilters}
            resetStateFilter={resetStateFilters}
          />
        }
        footer={
          <Layout.Footer
            loading={loading}
            onNext={handleNext}
            onPrev={handlePrev}
            page={page}
            resultsCount={totalCount}
            totalPage={totalPage}
            setPage={setPage}
            pageInfo={pageInfo}
          />
        }
      >
        <TableIndex
          loading={loading}
          dataSource={dataSource}
          error={error}
          columns={filterColumns(columns)}
        />
      </Layout.Content>
    </Layout.Container>
  );
};

const MemberIpAddressSummaryToggable: React.FC = () => (
  <SpreadsheetProvider
    options={{
      query: GET_MEMBER_IP_ADDRESS_SUMMARY,
      edgesPath: 'memberIpAddressReport.edges',
    }}
  >
    <MemberIpAddressSummary />
  </SpreadsheetProvider>
);

export default MemberIpAddressSummaryToggable;
