import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { StyledTableContainer } from 'constants/styles';
import FiltersProvider, { useFilterValues } from 'contexts/Filters';
import {
  GET_GAME_SESSIONS,
  GET_GAME_SESSIONS_IDS,
} from 'graphql/queries/memberSessionDuration.query';
import { useTablePagination } from 'hooks/usePaginate';
import { isEqual } from 'lodash';
import { columns } from 'pages/components/MemberSessionDuration/utils';
import messages from 'messages';
import React, { useMemo, useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { useAccount } from 'store/accountState';
import { StyledTable, StyledTagContainer } from 'styles';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import { FilterConditions } from './components/Header/FilterConditions';
import Sidebar from './components/Sidebar';

const MemberSessionDuration = () => {
  const translate = useTranslate();
  const [expandedTags, setExpandedTags] = useState(false);
  const { filters, initialValues, setFilters } = useFilterValues();
  const { page, setPage, handleNext, handlePrev } = useTablePagination({
    after: undefined,
    savedCursor: [undefined] as any,
    currentPage: 0,
    first: 10,
  });

  const {
    account: { account: user },
  } = useAccount();

  const attachAdminFilter = (filtersData: Record<string, any>) => ({
    ...filtersData,
    admin: {
      eq: user!.id,
    },
  });

  const memoizedFilter = useMemo(
    () => attachAdminFilter(removeNull(filters)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );
  const extraQuery = { admin: memoizedFilter.admin };

  const {
    loading,
    data,
  }: {
    loading: boolean;
    data: any;
  } = usePartialFiltersQuery(
    GET_GAME_SESSIONS_IDS,
    GET_GAME_SESSIONS,
    'gameSessions.edges',
    memoizedFilter,
    page,
    ['username', 'brandId', 'platformId'],
    'cache-and-network',
    'username',
    extraQuery
  );

  const rawGameSessions = data?.gameSessions?.edges || [];
  const totalCount = data?.gameSessions?.totalCount || 0;
  const pageInfo = data?.gameSessions?.pageInfo || 0;
  const gameSessions = rawGameSessions?.map(
    (rawGameSession: { node: Record<string, string> }) => rawGameSession.node
  );
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleFilters = (e: object) => {
    setFilters(e);
  };

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={
            <div className="d-flex align-items-start flex-g-1">
              <ToggleSidebarHead />
              <StyledTagContainer ref={ref} isExpanded={expandedTags}>
                <FilterConditions
                  filters={filters}
                  onFilterChange={handleFilters}
                  loading={loading}
                />
              </StyledTagContainer>
              <LegacyIconToggle
                forwardedRef={ref}
                filters={filters}
                expandedTags={expandedTags}
                toggleExpandedTags={setExpandedTags}
              />

              {!isEqual(initialValues, filters) && (
                <ALink
                  onClick={() => handleFilters(initialValues)}
                  style={{ marginTop: '0.35rem' }}
                >
                  {translate(messages.CLEAR_ALL)}
                </ALink>
              )}
            </div>
          }
          rightNode={null}
        />
        <Layout.Content
          height="calc(100vh - 80px)"
          sideBar={<Sidebar extraQuery={extraQuery} />}
          footer={
            <Layout.Footer
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={pageInfo}
            />
          }
        >
          <StyledTableContainer>
            <StyledTable
              columns={columns}
              loading={loading}
              dataSource={gameSessions}
              pagination={false}
            />
          </StyledTableContainer>
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

const MemberSessionDurationPage = () => (
  <FiltersProvider
    initialValues={{
      username: null,
      brand: null,
      platformId: null,
      brandId: null,
    }}
  >
    <MemberSessionDuration />
  </FiltersProvider>
);

export default MemberSessionDurationPage;
