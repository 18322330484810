import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useMutation } from '@apollo/react-hooks';
import { Button, message, Radio } from 'antd';
import MoreActionsMembersFilter from 'components/MoreActionsMembersFilter';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { StyledModal } from '../Content/components/Members/components/MembersTable/components/EditStatusMenu/styles';

type Props = {
  members: Array<{ key: string; label: string }>;
};

const BulkEditProfileValidation = ({ members }: Props) => {
  const [profile, setProfile] = useState(true);
  const [isShow, setShow] = useState(false);
  const [users, setUsers] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const handleShow = () => setShow(!isShow);
  const translate = useTranslate();
  const onSubmit = async () => {
    setLoading(true);
    const promises = users.map((user) =>
      updateMember({
        variables: {
          id: user.key,
          input: {
            forceVerification: profile,
          },
        },
      })
    );
    await Promise.all(promises);
    setLoading(false);
    setShow(false);
    message.success(translate(messages.MEMBER_UPDATED));
  };

  useEffect(() => {
    setUsers(members);
  }, [members]);
  return (
    <>
      <Button className="text-black" type="link" onClick={handleShow} block>
        <FormattedMessage
          id="EDIT_PROFILE_VALIDATION"
          defaultMessage="Edit Profile Validation"
        />
      </Button>
      <StyledModal
        title={
          <FormattedMessage
            id="EDIT_PROFILE_VALIDATION"
            defaultMessage="Edit Profile Validation"
          />
        }
        onCancel={handleShow}
        visible={isShow}
        footer={false}
      >
        <div className="p-3">
          <div className="mb-3">
            <MoreActionsMembersFilter
              value={users}
              placeholder={translate(messages.ENTER_USERNAME)}
              onChange={(e) => setUsers(e || [])}
            />
          </div>
          <Form.Item
            label={
              <FormattedMessage
                id="PROFILE_VALIDATION"
                defaultMessage="Profile Validation"
              />
            }
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 14 }}
          >
            <Radio.Group
              name="status"
              onChange={(val) => setProfile(val.target.value)}
              value={profile}
            >
              <Radio value>
                <FormattedMessage id="ON" defaultMessage="On" />
              </Radio>

              <Radio value={false}>
                <FormattedMessage id="OFF" defaultMessage="Off" />
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="bt-1 p-3 text-right">
          <Button className="mr-2" onClick={handleShow} loading={loading}>
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            role="button"
            loading={loading}
            onClick={onSubmit}
          >
            <FormattedMessage
              id="save-changes.text"
              defaultMessage="Save changes"
            />
          </Button>
        </div>
      </StyledModal>
    </>
  );
};

export default BulkEditProfileValidation;
