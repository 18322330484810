import React from 'react';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';

// custom hooks used with useFilterValues() context hook
const useExpandableFilterList = () => {
  const containerRef = React.useRef(null);
  const [isTagsHidden, setIsTagsHidden] = React.useState(true);
  const [showToggleArrow, setShowToggleArrow] = React.useState(false);

  const { filters } = useFilterValues();

  React.useEffect(() => {
    setTimeout(() => {
      if (containerRef.current === null) return;
      // offsetTop of index 0 will be the basis
      let offsetBasis = null;
      const tagsHtml = containerRef.current.querySelectorAll('.ant-tag');

      tagsHtml.forEach((elem, index) => {
        if (index === 0) {
          offsetBasis = elem.offsetTop;
        }
        return null;
      });

      tagsHtml.forEach((elem) => {
        if (elem.offsetTop > offsetBasis && isTagsHidden) {
          return elem.classList.add('display-none');
        }

        if (isTagsHidden === false) {
          return elem.classList.remove('display-none');
        }

        return null;
      });

      tagsHtml.forEach((elem) => {
        if (elem.classList.contains('display-none')) {
          setShowToggleArrow(true);
          return null;
        }
        if (!isTagsHidden) return null;

        setShowToggleArrow(false);
        return null;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isTagsHidden]);

  return [
    containerRef,
    { isTagsHidden, setIsTagsHidden },
    { showToggleArrow, setShowToggleArrow },
  ];
};

export default useExpandableFilterList;
