import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Button, Divider, message, Modal, Select, Upload } from 'antd';
import { UPLOAD_FILE } from 'graphql/mutations/uploadFile.mutation';
import {
  BULK_UPDATE_MEMBER_TAGS,
  MEMBER_BULK_TAGS_UPDATE_RECORD,
} from 'pages/components/BulkUpdate/utils';
import React from 'react';
import { ALink } from 'components/ALink/ALink';
import {
  BulkUpdateMemberTagsAction,
  MemberBulkUpdateJobRecord,
} from 'types/graphqlTypes';
import { capitalize, startCase } from 'lodash';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useInterval } from 'react-use';

const BulkUpdateMemberLabels = () => {
  const [uploadFile] = useMutation(UPLOAD_FILE);
  const [bulkUpdateMemberTags] = useMutation(BULK_UPDATE_MEMBER_TAGS);

  const [isIntervalRunning, setIsIntervalRunning] = React.useState(false);
  const [showResultModal, setShowResultModal] = React.useState(false);
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const [resultUri, setResultUri] = React.useState('');
  const [bulkUpdateResult, setBulkUpdateResult] = React.useState<{
    memberBulkTagsUpdateRecord: MemberBulkUpdateJobRecord;
  } | null>(null);

  const [action, setAction] = React.useState<
    BulkUpdateMemberTagsAction | undefined
  >(undefined);

  const { memberBulkTagsUpdateRecord: record } = bulkUpdateResult || {};

  const handleShowModal = () => setShowResultModal((prev) => !prev);

  const [loadRecord, { loading }] = useLazyQuery<{
    memberBulkTagsUpdateRecord: MemberBulkUpdateJobRecord;
  }>(MEMBER_BULK_TAGS_UPDATE_RECORD, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.memberBulkTagsUpdateRecord.status !== 'IN_PROGRESS') {
        setIsIntervalRunning(false);
        setBulkUpdateResult(data);
        setResultUri(data.memberBulkTagsUpdateRecord.result?.uri!);
        handleShowModal();
        setUploadingFile(false);
      }
    },
    onError: () => {
      setIsIntervalRunning(false);
      setUploadingFile(false);
    },
  });

  useInterval(
    () => {
      loadRecord();
    },
    isIntervalRunning ? 1500 : null
  );

  const uploadProps: any = {
    beforeUpload: (file: File) => {
      const validType = file.type === 'text/plain';

      if (!validType) {
        message.error('Only upload valid text file');
        return false;
      }

      setUploadingFile(true);

      uploadFile({
        variables: {
          file,
        },
      })
        .then(async ({ data }) => {
          await bulkUpdateMemberTags({
            variables: {
              file: data?.uploadFile?.id,
              action,
            },
          });
          setIsIntervalRunning(true);
        })
        .finally(() => loadRecord());

      return false;
    },
  };

  const handleActionChange = (value: BulkUpdateMemberTagsAction) =>
    setAction(value);

  return (
    <>
      <Modal
        visible={showResultModal}
        closable
        title="Bulk Update Labels Result"
        destroyOnClose
        onCancel={handleShowModal}
        centered
        footer={false}
        width="320px"
        maskClosable={false}
      >
        <div className="d-flex flex-column">
          <div className="fs-16 fw-500">
            <CheckCircleFilled className="text-success mr-2" />
            <FormattedMessage id="success.text" defaultMessage="Success" />:
            <span className="ml-1">
              {record?.processedRows}/{record?.totalRows}
            </span>
          </div>
          <div className="fs-16 fw-500">
            <CloseCircleFilled className="text-danger mr-2" />
            <FormattedMessage id="failed.text" defaultMessage="Failed" />:
            <span className="ml-1">
              {Number(record?.totalRows) - Number(record?.processedRows)}/
              {record?.totalRows}
            </span>
          </div>

          <div className="my-2">
            {record?.error ? (
              <small className="fs-12 text-danger">
                {record?.error?.message}
              </small>
            ) : (
              <small className="fs-12 text-success">
                Bulk update labels successful.
              </small>
            )}
          </div>
          {record?.error && (
            <>
              <Divider className="m-0" />

              <div
                style={{
                  background: '#f5f5f5',
                  maxHeight: '170px',
                  overflow: 'auto',
                }}
                className="mt-2 p-2"
              >
                {record?.error?.labels?.map((item, index) => (
                  <div key={index} className="fs-12 d-flex flex-column">
                    {item}
                  </div>
                ))}
              </div>
            </>
          )}
          <Divider className="m-0" />
          <div className="mt-1">
            <Button
              type="link"
              className="p-0"
              disabled={!resultUri}
              href={resultUri}
            >
              Download CSV
            </Button>
          </div>
        </div>
      </Modal>
      <div>
        <div className="d-flex align-items-center mb-3">
          <h3 className="mb-0">Bulk Update Labels &nbsp;</h3>
          <ALink
            href={`${process.env.PUBLIC_URL}/text/sample for bulk update labels.txt`}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            Sample Format
          </ALink>
        </div>

        <div className="mb-3">
          <Select
            placeholder="Select Action"
            value={action}
            onChange={(e) => handleActionChange(e)}
            style={{
              width: '130px',
            }}
          >
            {Object.values(BulkUpdateMemberTagsAction).map((item) => (
              <Select.Option value={item} key={item}>
                {startCase(capitalize(item))}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="d-flex">
          <Upload
            {...uploadProps}
            maxCount={1}
            accept={['.txt'].join(', ')}
            showUploadList={false}
          >
            <Button
              className="mr-3"
              // eslint-disable-next-line
              disabled={loading || uploadingFile || !action}
              loading={loading || uploadingFile}
            >
              {loading || uploadingFile ? 'Processing...' : 'Upload Text File'}
            </Button>
          </Upload>
        </div>
      </div>
    </>
  );
};

export default BulkUpdateMemberLabels;
