import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { LockOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ALink } from 'components/ALink/ALink';
import { FormattedMessage } from 'react-intl';
import { ChangeLoginPassword } from 'SuperAdminMain/components/AccountManagementNew/AMBody/Table/Actions/ChangeLoginPassword/ChangeLoginPassword';
import { NewClientDrawerContainer } from 'SuperAdminMain/shared/components/NewClientAccount/NewClientDrawerContainer/NewClientDrawerContainer';
import moment from 'moment';
import { useAccount360 } from '../AccountContext';
import coercedGet from '../../../utils/coercedGet';

export const AccountHeader: React.FC = () => {
  const { admin, refetch } = useAccount360();
  const [isShow, setShow] = useState(false);
  const handleShow = () => setShow(!isShow);
  const formatAttachmentsUri = useCallback(() => {
    if (coercedGet(admin, 'attachments', []).length > 0) {
      const splittedUris = admin?.attachments?.map(
        (attachment: { id: string; uri: string }) => ({
          id: attachment.id,
          name: attachment.uri.split('/'),
        })
      );
      return splittedUris!.map((uri: any) => ({
        ...uri,
        name: uri.name[uri.name.length - 1],
      }));
    }
    return undefined;
  }, [admin]);
  const userAccount = {
    ...admin,
    ...(admin.contractStartDateTime && {
      contractStartDateTime: moment(admin.contractStartDateTime),
    }),
    ...(admin.contractEndDateTime && {
      contractEndDateTime: moment(admin.contractEndDateTime),
    }),
    attachments: formatAttachmentsUri(),
  };
  const values = { ...userAccount };
  delete values.dateTimeCreated;

  return (
    <Container>
      <div className="header-content">
        <div className="client-name">{admin.username}</div>

        <div className="right-node">
          <ChangeLoginPassword account={admin}>
            <div className="header-lock">
              <LockOutlined style={{ fontSize: '20px', color: 'gray' }} />

              <ALink className="fs-8 fw-500" style={{ marginTop: '2px' }}>
                <FormattedMessage
                  id="edit-login-password.text"
                  defaultMessage="Edit Login Password"
                />
              </ALink>
            </div>
          </ChangeLoginPassword>

          <Button onClick={handleShow}>
            <FormattedMessage
              id="edit-details.text"
              defaultMessage="Edit Details"
            />
          </Button>

          <NewClientDrawerContainer
            existingValues={values}
            drawerState={isShow}
            closeDrawer={() => setShow(false)}
            refetchFunction={() => refetch()}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background: white;

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 944px;
    height: 56px;
    margin: 0 auto;

    .client-name {
      font-weight: 500;
      font-size: 14px;
      color: #000000a6;
    }

    .right-node {
      display: flex;
      justify-content: center;
      align-items: center;

      .header-lock {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50px;
        text-align: center;
        cursor: pointer;

        margin-right: 16px;

        a {
          font-size: 10px;
        }
      }
    }
  }
`;
