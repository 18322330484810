import gql from 'graphql-tag';

export const REBATE_GROUPS_RECORDS = gql`
  query rebateGroups(
    $first: Int
    $after: Binary
    $filter: RebateGroupsFilterInput
  ) {
    rebateGroups(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          type
          validityDateTimeRange {
            start
            end
          }
          participatingMembers {
            totalCount
          }
          qualifyingMemberLoyaltyLevels {
            id
          }
          qualifiedMembersCount
          dateTimeCreated
          automaticPayout
          totalPayout
          payoutClaimantsCount
          totalClaimedPayout
          totalExpiredPayout
          totalClawback
          ... on PeriodicRebateGroup {
            payoutSettlementTime
            payoutSettlementDayOfWeek
            payoutSettlementDayOfMonth
            payoutSettlementMonth
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export default {};
