import React from 'react';
import { Divider } from 'antd';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import { StyledHeaderContainer, StyledActionsContainer } from 'styles';
import { TabId } from 'constants/route';
import messages from 'messages';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import { usePermissions } from 'store/accountState';
import esGet from 'utils/esGet';
import CreateProgrammeButton from 'pages/components/MemberLoyaltyManagement/components/CreateProgrammeButton';
import useTranslate from 'utils/useTranslate';
import { useFilterValues } from 'contexts/Filters';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import removeNull from 'utils/removeNull';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import RefreshTable from './components/RefreshTable';
import FilterConditions from './components/FilterConditions';

type Props = {
  toggleOverlay: () => void;
  ref: any;
};

const Header: React.FC<Props> = React.forwardRef((props, ref: any) => {
  const { role, permissions } = usePermissions();
  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    ALL_PERMISSIONS.ALL_VIP.VIP_VIP
  );

  const { toggleOverlay } = props;

  const { filters } = useFilterValues();

  // const [expandedTags, setExpandedTags] = useState(false);
  const translate = useTranslate();
  return (
    <div className="d-flex my-3 px-3 align-items-center">
      <ToggleSidebarHead />
      <StyledHeaderContainer>
        <FilterConditions />
      </StyledHeaderContainer>

      <StyledActionsContainer>
        <RefreshTable
          onClick={() => esGet(ref?.current?.refetchTable, () => null)()}
        />
      </StyledActionsContainer>

      <StyledActionsContainer>
        <ColumnsFilterNew tabId={TabId.VIP as any} iconOnly />
      </StyledActionsContainer>

      <StyledActionsContainer className="mr-2">
        <SpreadsheetDownload
          iconOnly
          filter={removeNull(filters)}
          filename={translate(messages.VIP)}
          extension="csv"
        />
      </StyledActionsContainer>

      <StyledActionsContainer>
        <Divider type="vertical" className="ml-0 mr-2" />
      </StyledActionsContainer>

      {ALLOWED_CREATE && (
        <StyledActionsContainer>
          <CreateProgrammeButton toggleOverlay={toggleOverlay} />
        </StyledActionsContainer>
      )}
    </div>
  );
});

export default Header;
