import gql from 'graphql-tag';

export const CREATE_BANK_TRANSFER_PAYMENT_METHOD = gql`
  mutation CreateBankTransferPaymentMethod(
    $input: CreateBankTransferPaymentMethodInput!
  ) {
    createBankTransferPaymentMethod(input: $input)
  }
`;

export const CREATE_ALIPAY_PAYMENT_METHOD = gql`
  mutation CreateAlipayPaymentMethod($input: CreateAlipayPaymentMethodInput!) {
    createAlipayPaymentMethod(input: $input)
  }
`;

export const CREATE_WECHAT_PAYMENT_METHOD = gql`
  mutation CreateWechatPaymentMethod($input: CreateWechatPaymentMethodInput!) {
    createWechatPaymentMethod(input: $input)
  }
`;

export const CREATE_LUBANZHIFU_PAYMENT_METHOD = gql`
  mutation CreateLubanzhifuPaymentMethod(
    $input: CreateLubanzhifuPaymentMethodInput!
  ) {
    createLubanzhifuPaymentMethod(input: $input)
  }
`;

export const CREATE_PAYMENT_METHOD = gql`
  mutation createPaymentMethod(
    $input: CreatePaymentMethodInput!
    $type: PaymentMethodType!
  ) {
    createPaymentMethod(input: $input, type: $type)
  }
`;

export default {};
