import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { useFilterValues } from 'contexts/Filters';
import {
  GET_MEMBERS_ONLINE,
  GET_MEMBERS_ONLINE_IDS,
} from 'graphql/queries/membersOnline.query';
import { isEqual } from 'lodash';
import FilterConditions from 'pages/components/MembersOnlineManagement/components/FilterConditions';
import MembersOnlineTable from 'pages/components/MembersOnlineManagement/components/MembersOnline/components/MembersOnlineTable';
import { StyledTagContainer } from 'pages/components/MembersOnlineManagement/styles';
import messages from 'messages';
import React, { useMemo, useState } from 'react';
import { ALink } from 'components/ALink/ALink';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import RefreshButton from 'components/RefreshButton/RefreshButton';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import removeNull from 'utils/removeNull';
import useTranslate from 'utils/useTranslate';
import uuid from 'uuid';
import SearchSettingsForm from './SearchSettingsForm';
import FilterItems from './FilterItems';

const pageInitState = {
  first: 10,
  after: undefined,
  savedCursor: [undefined],
  currentPage: 0,
};

export const MembersOnlineScreen = ({
  setKey,
}: {
  setKey: (data: string) => void;
}) => {
  const [expandedTags, setExpandedTags] = useState(false);
  const translate = useTranslate();

  const { filters, setFilters, initialValues, resetFilter } = useFilterValues();

  const [page, setPage] = useState<any>(pageInitState);
  const [initialized, setInitialized] = useState(false);
  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }

    setPage(pageInitState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const memoizedFilter = useMemo(
    () => ({
      ...removeNull(filters),
      online: {
        eq: true,
      },
    }),
    [filters]
  );

  const {
    loading,
    data = {},
    refetchVariables,
    refetch,
  } = usePartialFiltersQuery(
    GET_MEMBERS_ONLINE_IDS,
    GET_MEMBERS_ONLINE,
    'members.edges',
    memoizedFilter,
    page,
    ['username']
  );

  const handleNext = (pageInfo: Record<string, any>) => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage] || undefined;
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const { members = {} } = data as any;
  const { totalCount, edges = [], pageInfo } = members;

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <Layout.Header
        leftNode={
          <div className="d-flex align-items-center flex-g-1">
            <ToggleSidebarHead />
            <StyledTagContainer ref={ref} isExpanded={expandedTags}>
              <span className="ml-1">
                {translate(messages.FILTER_CONDITIONS)}:{' '}
              </span>
              <FilterConditions isLoading={loading} />
            </StyledTagContainer>
            <LegacyIconToggle
              forwardedRef={ref}
              filters={filters}
              expandedTags={expandedTags}
              toggleExpandedTags={setExpandedTags}
            />
            {!isEqual(filters, initialValues) && (
              <ALink
                onClick={() => resetFilter()}
                style={{ marginTop: '0.35rem' }}
              >
                {translate(messages.CLEAR_ALL)}
              </ALink>
            )}
          </div>
        }
        rightNode={
          <>
            <RefreshButton
              onClick={() => {
                refetch(refetchVariables);
                setKey(uuid.v1());
              }}
            />
            <ColumnsFilterNew iconOnly tabId="members-online" />
          </>
        }
      />

      <Layout.Content
        sideBar={
          <Layout.Sidebar
            quickSearch={{
              filters,
              contextKey: 'members-online',
              isNext: true,
              searchSettingsForm: SearchSettingsForm,
              quickSearchId: QuickSearchIds.MEMBERS_ONLINE,
              onFilterChange: (e) =>
                setFilters((prev: Record<string, any>) => ({ ...prev, ...e })),
            }}
          >
            <FilterItems filters={filters} handleChange={setFilters} />
          </Layout.Sidebar>
        }
        footer={
          <Layout.Footer
            loading={loading}
            onNext={handleNext}
            onPrev={handlePrev}
            page={page}
            resultsCount={totalCount}
            totalPage={totalPage}
            setPage={setPage}
            pageInfo={pageInfo}
          />
        }
      >
        <MembersOnlineTable page={page} members={edges} loading={loading} />
      </Layout.Content>
    </Layout.Container>
  );
};
