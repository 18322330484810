import { defineMessages } from 'react-intl';

const messages = defineMessages({
  'please-enter.text': {
    id: 'please-enter.text',
    defaultMessage: 'Please enter',
  },
});

export default messages;
