import React from 'react';

const BatchSplitContext = React.createContext<any>({});

export const BatchSplitProvider = ({
  children,
  refetchListener,
}: {
  children: any;
  refetchListener: boolean;
}) => (
  <BatchSplitContext.Provider value={{ refetchListener }}>
    {children}
  </BatchSplitContext.Provider>
);

export const useBatchSplitValue = () => React.useContext(BatchSplitContext);
