import gql from 'graphql-tag';

export const MEMBER_LOYALTY_LEVELS = gql`
  query memberLoyaltyLevels(
    $first: Int
    $input: String
    $status: MemberLoyaltyProgrammeStatusQueryOperatorInput
  ) {
    memberLoyaltyLevels(
      first: $first
      filter: { name: { contains: $input }, status: $status }
    ) {
      edges {
        node {
          id
          name
          color
          rank
          programme {
            id
            name
          }
        }
      }
    }

    memberLoyaltyProgrammes(
      first: $first
      filter: { name: { contains: $input }, status: $status }
    ) {
      edges {
        node {
          id
          name
          levels {
            id
            name
            color
            rank
          }
        }
      }
    }
  }
`;

export default {};
