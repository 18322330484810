import { useQuery } from '@apollo/react-hooks';
import { DOMAINS } from 'graphql/queries/siteConfiguration.query';
import React, { useState } from 'react';
import { SharedStyledLayout } from 'styles/SharedStyledLayout';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import SiteConfigurationContent from './components/SiteConfigurationContent/SiteConfigurationContent';

const SiteConfigurations = () => {
  const [isSidebar, setSidebar] = useState(true);
  const handleSidebar = () => setSidebar(!isSidebar);

  const { data, refetch } = useQuery(DOMAINS);

  const domainsRaw = data?.domains?.edges || [];
  const domainsData = domainsRaw.map((x: Record<string, any>) => x.node);

  return (
    <div style={{ height: 'calc(100vh - 112px)' }}>
      <SharedStyledLayout>
        <LeftSidebar sideBarStatus={isSidebar} />

        <SiteConfigurationContent
          refetch={refetch}
          sidebarStatus={isSidebar}
          handleSidebar={handleSidebar}
          dataSource={domainsData}
        />

        <RightSidebar />
      </SharedStyledLayout>
    </div>
  );
};

export default SiteConfigurations;
