import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Progress } from 'antd';

export const ExcelProgress = ({
  processComplete,
  percents,
}: {
  processComplete: any;
  percents: number;
}) => {
  const [percent, setPercent] = React.useState(0);

  useEffect(() => {
    setPercent(percents);
  }, [percents]);

  useEffect(() => {
    if (percent >= 100) {
      setTimeout(() => {
        processComplete();
      }, 500);
    }
  }, [processComplete, percent]);

  return (
    <div className="p-3">
      <div className="d-flex justify-content-center mt-3">
        <Progress
          width={80}
          type="circle"
          percent={Number(percent.toFixed(1))}
        />
      </div>
      <div className="mt-1 d-flex justify-content-center">
        <p className="fs-16 fw-500">
          {percent < 100 ? (
            <>
              <FormattedMessage id="PROCESSING" defaultMessage="Processing" />
              ...
            </>
          ) : (
            <FormattedMessage id="DONE" defaultMessage="Done" />
          )}
        </p>
      </div>
      <div className="mt-2 text-center fs-14">
        <FormattedMessage
          id="PLEASE_WAIT_WHILE_SETUP_THINGS_FOR_YOU"
          defaultMessage="Please wait while we set things up for you"
        />
        !
      </div>
      <div className="text-center fs-14">
        <FormattedMessage
          id="DO_NOT_CLOSE_THIS_TAB"
          defaultMessage="Do not close this tab"
        />
        .
      </div>
    </div>
  );
};
