import React, { useState } from 'react';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { NewVendorDrawerContainer } from '../../../NewVendorDrawerContainer/NewVendorDrawerContainer';
import { useVendorGroupManagementValue } from '../../../../VendorGroupManagementContext';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const NewAccount: React.FC<Props> = () => {
  const data = useVendorGroupManagementValue();
  const [drawerState, setDrawerState] = useState<boolean>(false);
  return (
    <Container>
      <Button type="primary" onClick={() => setDrawerState(true)}>
        <PlusOutlined />
        <FormattedMessage id="new.text" defaultMessage="New" />
      </Button>

      {drawerState && (
        <NewVendorDrawerContainer
          refetchFn={data?.refetch}
          openDrawer={drawerState}
          closeDrawer={() => setDrawerState(false)}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-right: 24px;
`;
