import React, { Dispatch, useEffect, useState } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isEmpty, set, unset } from 'lodash';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { SharedStyledSelect } from 'styles/SharedStyledSelect';
import { SearchFilterInput } from 'components/SearchFilterInput/SearchFilterInput';
import { useDebounce } from '../../common/hooks/useDebounce';

export const Sidebar = ({
  page,
  setPage,
  getSystemNotifications,
  pageInitialState,
}: {
  page: Record<string, any>;
  setPage: Dispatch<any>;
  getSystemNotifications: () => void;
  pageInitialState: Record<string, any>;
}) => {
  const [titleInput, setTitleInput] = useState<string | null>(null);
  const [messageInput, setMessageInput] = useState<string | null>(null);
  const [priorityInput, setPriorityInput] = useState<string | null>('');

  const debouncedTitle = useDebounce(titleInput, 500);
  const debouncedMessage = useDebounce(messageInput, 500);

  const onChange = (type: string, value: string | null) => {
    switch (type) {
      case 'title':
        return setTitleInput(value);
      case 'message':
        return setMessageInput(value);
      case 'priority':
        return setPriorityInput(value);
      default:
        return null;
    }
  };

  useEffect(() => {
    setPage((prevState: any) => {
      const filterCopy = { ...prevState.filter };
      const pageOptions = {
        ...pageInitialState,
        filter: {
          ...filterCopy,
        },
        searchCriteria: {
          title: debouncedTitle,
          message: debouncedMessage,
          priority: priorityInput,
        },
      };

      if (debouncedTitle) {
        set(pageOptions, 'filter.title.contains', debouncedTitle);
      } else {
        unset(pageOptions, 'filter.title');
      }

      if (debouncedMessage) {
        set(pageOptions, 'filter.message.contains', debouncedMessage);
      } else {
        unset(pageOptions, 'filter.message');
      }

      if (priorityInput) {
        set(pageOptions, 'filter.priority.eq', priorityInput);
      } else {
        unset(pageOptions, 'filter.priority');
      }

      return pageOptions;
    });

    getSystemNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTitle, debouncedMessage, priorityInput]);

  useEffect(() => {
    if (!isEmpty(page.onTagClose)) {
      onChange(page.onTagClose, '');
    }
  }, [page.onTagClose]);

  return (
    <>
      <SearchFilterInput
        onChange={onChange}
        type="title"
        inputValueRef={titleInput}
      />

      <SearchFilterInput
        onChange={onChange}
        type="message"
        inputValueRef={messageInput}
      />

      <FilterItem
        label={
          <FormattedMessage
            id="priority-alert.text"
            defaultMessage="Priority Alert"
          />
        }
      >
        <SharedStyledSelect
          value={priorityInput as any}
          onChange={(val: any) => onChange('priority', `${val}`)}
        >
          <Select.Option value="">
            <FormattedMessage id="all.text" defaultMessage="All" />
          </Select.Option>
          <Select.Option value="HIGH">
            <FormattedMessage id="high.text" defaultMessage="High" />
          </Select.Option>
          <Select.Option value="MEDIUM">
            <FormattedMessage id="medium.text" defaultMessage="Medium" />
          </Select.Option>
          <Select.Option value="LOW">
            <FormattedMessage id="low.text" defaultMessage="Low" />
          </Select.Option>
        </SharedStyledSelect>
      </FilterItem>
    </>
  );
};
