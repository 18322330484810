import memberSummaryReport from './memberSummaryReport';
import depositRequestsInternal from './depositRequestsInternal';
import depositRequestsExternal from './depositRequestsExternal';
import paymentMethodListing from './paymentMethodListing';
import depositProviderExternal from './depositProviderExternal';
import withdrawalRequest from './withdrawalRequest';
import withdrawalMethods from './withdrawalMethods';
import withdrawalProviderExternal from './withdrawalProviderExternal';
import memberManagement from './memberManagement';
import permissionGroup from './permissionGroup';
import affiliateRequest from './affiliateRequest';

export default {
  'member-summary-report': memberSummaryReport,
  'deposit-requests-internal': depositRequestsInternal,
  'deposit-requests-external': depositRequestsExternal,
  'payment-method-listing': paymentMethodListing,
  'deposit-providers-external': depositProviderExternal,
  'withdrawal-requests': withdrawalRequest,
  'withdrawal-methods': withdrawalMethods,
  'withdrawal-providers-external': withdrawalProviderExternal,
  'member-management': memberManagement,
  'permission-group': permissionGroup,
  'affiliate-request': affiliateRequest,
};
