// import messages from 'messages';
import esget from 'utils/esGet';
import messages from 'pages/components/ThirdPartyDepositProvider/messages';

import thirdPartyProviders, {
  thirdPartyTypeNames,
} from 'constants/thirdPartyProviders';
import moment from 'moment';

const csvRenderKey = (provider: any) => {
  switch (provider.__typename) {
    case thirdPartyTypeNames.diorPay:
    case thirdPartyProviders.DIORPAY:
      return provider.key;

    case thirdPartyTypeNames.midPay:
    case thirdPartyTypeNames.midPayDirect:
    case thirdPartyProviders.MIDPAY:
    case thirdPartyProviders.MIDPAY_DIRECT:
    case thirdPartyProviders.MIDPAY_DIRECT_2:
      return provider.apiKey;

    case thirdPartyProviders.DIORPAY2:
    case thirdPartyTypeNames.diorPay2:
      return provider.encryptionKey;

    default:
      return '-';
  }
};

const getCsvHeaders = (translate: any) => [
  {
    label: translate(messages['provider-name.text']),
    key: 'name',
    renderCell: (paymentGateway: any) => esget(paymentGateway?.name, ''),
  },
  {
    label: translate(messages['type.text']),
    key: '__typename',
    renderCell: (paymentGateway: any) => esget(paymentGateway?.__typename, '-'),
  },
  {
    label: translate(messages.KEY_PREVIEW),
    key: 'apiKeyPreview',
    renderCell: (key: any) => csvRenderKey(key),
  },
  {
    label: translate(messages.THIRD_PARTY_GATEWAY),
    key: 'midPayDirectPaymentGateway',
    renderCell: (paymentGateway: any) => esget(paymentGateway?.id, '-'),
  },
  {
    label: translate(messages['date-created.text']),
    key: 'dateTimeCreated',
    renderCell: (paymentGateway: any) =>
      moment(paymentGateway.dateTimeCreated).format('MMM DD, YYYY hh:mm:ss A'),
  },
];

export default getCsvHeaders;
