import * as yup from 'yup';
import messages from 'messages';
import { MessageFormat } from 'types/react-intl';

export const validationSchema = (formatMessage: MessageFormat) =>
  yup.object().shape({
    username: yup
      .array()
      .nullable()
      .required(formatMessage(messages.USERNAME_REQUIRED)),
    userSelected: yup.array().when('type', {
      is: (val) => val !== 'ADD_REMOVE',
      then: yup
        .array()
        .required(formatMessage(messages['previous-tag-required.text'])),
    }),
    tags: yup.array().nullable(),
  });
