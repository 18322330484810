import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const Label = styled.span`
  font-size: 15px;
  color: #a0a0a0;
`;

const Value = styled.span`
  font-size: 15px;
`;

const InfoValues = ({
  label,
  value,
}: {
  label: string;
  value: string | number | Record<string, any>;
}) => (
  <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
    <Col span={11}>
      <Label>{label}</Label>
    </Col>
    <Col span={13}>
      <Value>{value}</Value>
    </Col>
  </Row>
);

export default InfoValues;
