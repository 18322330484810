import { withErrorHandler } from 'components/ErrorHandler';
import FiltersProvider from 'contexts/Filters';
import ReportsTimezoneProvider from 'contexts/ReportsTimezone';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import { GET_MAHR } from 'graphql/queries/memberAccessAuditHistory.query';
import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState/screenTabState';
import uuid from 'uuid';
import RecordsTable from './components/RecordsTable';
import { StyledLayout } from './styles';

const MemberAccessAuditHistory = () => {
  const [key, setKey] = React.useState('1');
  const tableRef = React.useRef(null);

  const { getTab } = useScreenTabV2('member-access-audit-history');

  const { memberId, ipAddress, filter = {} } = getTab(
    'member-access-audit-history'
  );

  const memberIdFilt = memberId ? { in: [memberId] } : null;

  const ipAdressFilt = ipAddress ? { in: [ipAddress] } : null;

  React.useEffect(() => {
    if (memberId || ipAddress) setKey(uuid.v1());
  }, [memberId, ipAddress]);

  return (
    <SpreadsheetProvider
      options={{
        query: GET_MAHR,
        edgesPath: 'memberAccessHistoryReport.edges',
      }}
    >
      <FiltersProvider
        key={key}
        tabId="member-access-audit-history"
        initialValues={{
          member: null,
          memberUserName: memberIdFilt,
          memberRealName: null,
          ipAddress: ipAdressFilt,
          // memberLoyaltyLevel: null,
          memberLevel: null,
          registration: null,
          dateTimeCreated: null,
          ...filter,
        }}
      >
        <ReportsTimezoneProvider>
          <StyledLayout className="d-flex">
            <RecordsTable ref={tableRef} />
          </StyledLayout>
        </ReportsTimezoneProvider>
      </FiltersProvider>
    </SpreadsheetProvider>
  );
};

export default withErrorHandler(MemberAccessAuditHistory);
