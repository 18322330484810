import React, { ReactElement } from 'react';

export const CreateAffiliateProgrammeScreens = {
  AGENT_AFFILIATE_PROGRAMME_SCREEN: 'AGENT_AFFILIATE_PROGRAMME_SCREEN',
  COST_SETTINGS_SCREEN: 'COST_SETTINGS_SCREEN',
  SETTLEMENT_PERIOD_SCREEN: 'SETTLEMENT_PERIOD_SCREEN',
  TIER_SETTINGS_SCREEN: 'TIER_SETTINGS_SCREEN',
};

export const CreateAffiliateProgrammeTypes = {
  SET_ACTIVE_SCREEN: 'SET_ACTIVE_SCREEN',
  SET_ACTIVE_VENDOR: 'SET_ACTIVE_VENDOR',
  ...CreateAffiliateProgrammeScreens,
};

export const CreateAffiliateProgramme = React.createContext<any>(null);

export function CreateAffiliateProgrammeProvider({
  children,
}: {
  children: ReactElement;
}) {
  const initialState = {
    activeScreen:
      CreateAffiliateProgrammeTypes.AGENT_AFFILIATE_PROGRAMME_SCREEN,
    activeVendor: {},
  };

  const reducer = (
    state: any,
    action: {
      type: string;
      payload?: any;
      id?: string;
    }
  ) => {
    switch (action.type) {
      case CreateAffiliateProgrammeTypes.SET_ACTIVE_SCREEN:
        return {
          ...state,
          activeScreen: action.payload,
        };
      case CreateAffiliateProgrammeTypes.SET_ACTIVE_VENDOR:
        return {
          ...state,
          activeVendor: action.payload,
        };

      default:
        return state;
    }
  };
  return (
    <CreateAffiliateProgramme.Provider
      value={React.useReducer(reducer, initialState)}
    >
      {children}
    </CreateAffiliateProgramme.Provider>
  );
}

export const useCreateAffiliateProgrammeState = () =>
  React.useContext(CreateAffiliateProgramme);
