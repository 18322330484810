// Get the user-agent string
const detectBrowser = (browser: string) =>
  navigator.userAgent.indexOf(browser) !== -1;

// Detect Firefox
export const FireFox = detectBrowser('Firefox');

// Detect Chrome
export const Chrome = detectBrowser('Chrome');

export const Chromium = detectBrowser('Chromium');

// Detect Safari
export const Safari = detectBrowser('Safari');

// Detect Opera
export const Opera = detectBrowser('OP');

// Detect Internet Explorer
export const InternetExplorer = detectBrowser('MSIE') || detectBrowser('rv:');

// Edge 20+
export const isEdge = !InternetExplorer && !!window.StyleMedia;
