import React from 'react';
import useTranslate from 'utils/useTranslate';
import { defineMessages } from 'react-intl';

const localMessages = defineMessages({
  LIVE: {
    id: 'rebates.live-rebate.text',
    defaultMessage: 'Live rebate',
  },
  PERIODIC: {
    id: 'periodic-rebate.text',
    defaultMessages: 'Periodic Rebate',
  },
});

const PayoutSettlementPeriodColumn = ({ value }: { value: string }) => {
  const translate = useTranslate();
  return (
    <span data-testid="payout-method.text">
      <> {value ? translate(localMessages[value]) : '-'}</>
    </span>
  );
};

export default PayoutSettlementPeriodColumn;
