import { useQuery } from '@apollo/react-hooks';
import { Button } from 'antd';
import cx from 'classnames';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import Layout from 'components/Layout';
import LegacyIconToggle from 'components/LegacyIconToggle';
import ALL_PERMISSIONS from 'constants/permissions3';
import { VENDORS } from 'graphql/queries/vendor.query';
import { Page, PageInfo } from 'interfaces/user.interface';
import { get } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import localMessages from 'pages/components/Rebates/messages';
import { REBATE_GROUPS } from 'pages/components/Rebates/query';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { usePermissions } from 'store/accountState';
import { StyledActionsContainer, StyledTagContainer } from 'styles';
import coercedGet from 'utils/coercedGet';
import { transformMovableDateFilter } from 'utils/dateUtils';
import useTranslate from 'utils/useTranslate';
import removeNull from 'utils/removeNull';
import SpreadsheetProvider from 'contexts/Spreadsheet';
import { QuickSearchIds } from 'components/QuickSearchFilter';
import SearchSettingsForm from './components/SearchSettingsForm';
import CreateRebateGroup from './components/CreateRebateGroup';
import FilterConditions from './components/FilterConditions';
import RebatesTable from './components/RebatesTable';
import Sidebar from './components/Sidebar';
import Summary from './components/Summary';
import {
  RebateGroupStatus,
  RebateGroupTypes,
  useRebateGroupState,
} from './context';

const JsCreateRebateGroup = CreateRebateGroup as any;

const pageInitState: Page = {
  after: undefined,
  savedCursor: [undefined] as any,
  currentPage: 0,
  first: 10,
};

const RebatesContent: React.FC = () => {
  const translate = useTranslate();

  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    ALL_PERMISSIONS.ALL_REBATES.REBATES_REBATES_REBATE_GROUP
  );

  const [rebateGroupState, dispatch] = useRebateGroupState() as any;
  const [state] = useState({
    reset: false,
    serialCode: undefined,
    dateCreated: null,
    memberAffiliates: [],
    members: [],
    types: [],
  });
  const [createRebateShown, setCreateRebateShown] = React.useState(false);
  const toggleCreateRebateVisibility = () =>
    setCreateRebateShown((prevState) => !prevState);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [expandedTags, setExpandedTags] = useState(false);
  const [pageInfo, setPageInfo] = useState<Partial<PageInfo>>({});

  const [page, setPage] = useState<Page>(pageInitState);

  const defaultFilters: any = {
    id: null,
    qualifyingMemberLoyaltyLevels: null,
    excludedMemberLevels: null,
    validityDateTimeRange: null,
    status: null,
  };
  const [filters, setFilters] = React.useState(defaultFilters);
  const handleFilters = (e: any) => {
    setFilters(e);
  };
  useEffect(() => {
    setPage(pageInitState);
  }, [filters]);
  const { initializedFilters } = rebateGroupState;
  const setInitializedFilters = (value: any) => {
    dispatch({
      type: RebateGroupTypes.SET_INITIALIZED_FILTERS,
      payload: value,
    });
  };

  useEffect(() => {
    setPage((prev: any) => ({
      ...pageInitState,
      first: prev.first,
    }));
  }, [setPage]);

  const newFilters = Object.entries(filters)
    .filter(
      (item) =>
        JSON.stringify(get(item[1], 'in', '')) !==
        JSON.stringify(Object.values(RebateGroupStatus))
    )
    .reduce(
      (acc, item) => ({
        ...acc,
        [item[0]]: item[1],
      }),
      {}
    );

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor!);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: transformMovableDateFilter(newFilters),
  };

  const setResultInfo = (data: { pageInfo: PageInfo; totalCount: number }) => {
    setTotalCount(data.totalCount);
    setPageInfo(data.pageInfo);
  };

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  useQuery(VENDORS, {
    onCompleted: (res: any) => {
      const vendors = coercedGet(res, 'vendors.edges', []);
      const vendorRebates = vendors.reduce((acc: any, { node }: any) => {
        const gameTypes = coercedGet(node, 'gameTypes', []);
        let newPercentages = {};
        gameTypes.forEach((gameType: any) => {
          newPercentages = {
            ...newPercentages,
            [`${gameType}_${node.id}`]: {
              vendor: node.id,
              gameType,
              percentage: 0,
            },
          };
        });
        return { ...acc, ...newPercentages };
      }, {});
      dispatch({
        type: RebateGroupTypes.SET_VENDORS,
        payload: vendors,
      });
      dispatch({
        type: RebateGroupTypes.SET_DEFAULT_VENDOR_REBATES,
        payload: vendorRebates,
      });
    },
  });

  const rebateGroupQuery = useQuery(REBATE_GROUPS, {
    variables: refetchVariables,
    fetchPolicy: 'network-only',
    onCompleted: (res: any) => {
      setResultInfo(res.rebateGroups);
    },
  });
  const edges = coercedGet(rebateGroupQuery.data, 'rebateGroups.edges', []).map(
    (item: any, key: number) => ({
      ...item.node,
      key: key + 1,
    })
  );

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <>
        <div
          className={cx({
            'd-none': rebateGroupState.summaryIsActive,
          })}
        >
          <Layout.Header
            leftNode={
              <div className="d-flex align-items-start flex-g-1">
                <ToggleSidebarHead />
                <StyledTagContainer ref={ref} isExpanded={expandedTags}>
                  <span className="ml-1">
                    {translate(messages.FILTER_CONDITIONS)}:{' '}
                  </span>{' '}
                  <FilterConditions
                    onFilterChange={handleFilters}
                    filters={filters}
                  />
                </StyledTagContainer>
                <LegacyIconToggle
                  forwardedRef={ref}
                  filters={filters}
                  expandedTags={expandedTags}
                  toggleExpandedTags={setExpandedTags}
                />
                <ClearAllFilterButton
                  initialFilters={defaultFilters}
                  currentFilters={filters}
                  onFilterChange={() => handleFilters(defaultFilters)}
                  style={{ marginTop: '0.35rem' }}
                />
              </div>
            }
            rightNode={
              <>
                <StyledActionsContainer className="mr-2">
                  <SpreadsheetDownload
                    iconOnly
                    filter={removeNull(filters)}
                    filename={translate(messages.REBATES)}
                    extension="csv"
                  />
                </StyledActionsContainer>

                {ALLOWED_CREATE && (
                  <Button type="primary" onClick={toggleCreateRebateVisibility}>
                    {translate(localMessages.ADD_NEW_REBATE_GROUP)}
                  </Button>
                )}
              </>
            }
          />
          <Layout.Content
            sideBar={
              <Layout.Sidebar
                quickSearch={{
                  filters,
                  contextKey: 'rebates',
                  onFilterChange: handleFilters,
                  searchSettingsForm: SearchSettingsForm,
                  quickSearchId: QuickSearchIds.REBATES,
                  isNext: true,
                }}
              >
                <Sidebar
                  filters={filters}
                  onFilterChange={setFilters}
                  initializedFilters={initializedFilters}
                  setInitializedFilters={setInitializedFilters}
                />
              </Layout.Sidebar>
            }
            footer={
              <Layout.Footer
                loading={false}
                onNext={handleNext}
                onPrev={handlePrev}
                page={page}
                resultsCount={totalCount}
                totalPage={totalPage}
                setPage={setPage}
                pageInfo={rebateGroupQuery.data?.pageInfo}
              />
            }
          >
            <RebatesTable
              data={edges}
              state={state}
              filters={filters}
              refetch={rebateGroupQuery.refetch}
              refetchVariables={refetchVariables}
              loading={rebateGroupQuery.loading}
              showDrawer={toggleCreateRebateVisibility}
            />
            <JsCreateRebateGroup
              drawerShown={createRebateShown}
              hideDrawer={toggleCreateRebateVisibility}
              refetch={rebateGroupQuery.refetch}
            />
          </Layout.Content>
        </div>
        <div
          className={cx({
            'd-none': !rebateGroupState.summaryIsActive,
          })}
        >
          <Summary showDrawer={toggleCreateRebateVisibility} />
        </div>
      </>
    </Layout.Container>
  );
};

const Rebates = () => (
  <SpreadsheetProvider
    options={{
      query: REBATE_GROUPS,
      edgesPath: 'rebateGroups.edges',
    }}
  >
    <RebatesContent />
  </SpreadsheetProvider>
);

export default Rebates;
