import gql from 'graphql-tag';

export const CREATE_MARQUEE_MESSAGE = gql`
  mutation CreateMarqueeMessage($input: CreateMarqueeMessageInput!) {
    createMarqueeMessage(input: $input)
  }
`;

export const DELETE_MARQUEE_MESSAGE = gql`
  mutation DeleteMarqueeMessage($id: ID!) {
    deleteMarqueeMessage(id: $id)
  }
`;

export const UPDATE_MARQUEE_SPEED = gql`
  mutation UpdateMarqueeSpeed($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

export const UPDATE_MARQUEE_MESSAGE = gql`
  mutation UpdateMarqueeMessage($id: ID!, $input: UpdateMarqueeMessageInput!) {
    updateMarqueeMessage(id: $id, input: $input)
  }
`;
