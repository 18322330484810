import React, { ReactElement } from 'react';
import cx from 'classnames';
import { Form as CompatibleForm } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Switch,
  Typography,
  Radio,
  Input,
  Select as AntdSelect,
  Row,
  Col,
  Checkbox,
  TimePicker as AntdTimePicker,
  DatePicker as AntdDatePicker,
} from 'antd';
import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { useIntl, FormattedMessage } from 'react-intl';
import { globalTranslate } from 'utils/globalMessages';
import useDate from 'utils/useDate';
import { formItemHorizontalLayout } from 'constants/form';
import { FormikProps } from 'interfaces/formikProps.interface';
import { RebateProps } from 'interfaces/formikValues/rebates.interface';
import useTranslate from 'utils/useTranslate';
import { useConfig } from 'hooks/useConfig';
import { StyledInputGroup } from './styles';
import messages from './messages';
import { CreateRebateGroupTypes, SettlementPeriod } from '../../constants';

const TimePicker: any = AntdTimePicker;
const DatePicker: any = AntdDatePicker;
const Select: any = AntdSelect;
const Form: any = CompatibleForm;

const FormWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

function PayoutSettings({
  formikProps,
}: {
  formikProps: FormikProps<RebateProps>;
}) {
  const { daysOfMonth } = useDate();
  const intl = useIntl();
  const { currency } = useConfig();
  const { handleChange, setFieldValue, errors, touched, values } = formikProps;

  const time = values.payoutSettlementTime;
  const translate = useTranslate();
  return (
    <FormWrapper className="mt-5">
      <Typography.Paragraph strong className="mb-4">
        <FormattedMessage
          id="rebates.payout-time.text"
          defaultMessage="Payout time"
        />
      </Typography.Paragraph>
      <Form.Item
        label={translate(messages['rebates.live-rebate.text'])}
        {...formItemHorizontalLayout}
      >
        <Switch
          checked={values.type === CreateRebateGroupTypes.LIVE}
          checkedChildren={translate(messages['common.on.text'])}
          unCheckedChildren={translate(messages['common.off.text'])}
          onChange={(value) => {
            setFieldValue(
              'type',
              value
                ? CreateRebateGroupTypes.LIVE
                : CreateRebateGroupTypes.PERIODIC
            );
            // setFieldValue('automaticPayout', false);
            // setFieldValue('noClaimOffsetDuration', false);
            // setFieldValue('noClaimExpiryDuration', false);
            // setFieldValue('noPayoutExpiryDuration', false);
          }}
        />
      </Form.Item>
      {values.type === CreateRebateGroupTypes.PERIODIC && (
        <>
          <Form.Item
            label={translate(messages['rebates.settlement-method.text'])}
            extra={
              values.payoutSettlementPeriod === SettlementPeriod.DAILY
                ? translate(
                    messages['rebates.settlement-method-daily-feedback.text']
                  )
                : ''
            }
            {...formItemHorizontalLayout}
          >
            <Select
              size="large"
              name="payoutSettlementPeriod"
              value={values.payoutSettlementPeriod}
              onChange={(value: any) => {
                setFieldValue('payoutSettlementPeriod', value);
                switch (values.payoutSettlementPeriod) {
                  case SettlementPeriod.DAILY: {
                    break;
                  }
                  case SettlementPeriod.WEEKLY:
                    setFieldValue(
                      'payoutSettlementDayOfWeek',
                      values.payoutSettlementDayOfWeek ||
                        moment.weekdays().indexOf('Sunday')
                    );
                    break;
                  case SettlementPeriod.MONTHLY:
                    setFieldValue(
                      'payoutSettlementDayOfMonth',
                      values.payoutSettlementDayOfMonth ||
                        daysOfMonth.indexOf('1st')
                    );
                    break;
                  default:
                    break;
                }
              }}
            >
              {Object.entries(SettlementPeriod).map(([key, value]) => (
                <Select.Option key={key} value={value}>
                  {globalTranslate(`${value.toLowerCase()}.text`, intl)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {values.payoutSettlementPeriod === SettlementPeriod.WEEKLY && (
            <Form.Item
              label={translate(messages['rebates.payout-day.text'])}
              {...formItemHorizontalLayout}
            >
              <Select
                size="large"
                value={
                  Math.max(0, values?.payoutSettlementDayOfWeek || 0) ||
                  Math.max(0, moment.weekdays().indexOf('Sunday'))
                }
                onChange={(value: number) =>
                  setFieldValue('payoutSettlementDayOfWeek', value)
                }
              >
                {moment.weekdays().map((value, idx) => (
                  <Select.Option key={idx} value={idx}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {values.payoutSettlementPeriod === SettlementPeriod.MONTHLY && (
            <Form.Item
              label={translate(messages['rebates.payout-date.text'])}
              {...formItemHorizontalLayout}
              help={
                errors.payoutSettlementDayOfMonth &&
                touched?.payoutSettlementDayOfMonth
              }
              validateStatus={
                errors.payoutSettlementDayOfMonth &&
                touched?.payoutSettlementDayOfMonth
                  ? 'error'
                  : ''
              }
            >
              <Select
                size="large"
                value={values.payoutSettlementDayOfMonth}
                onChange={(value: string) =>
                  setFieldValue('payoutSettlementDayOfMonth', value)
                }
              >
                {daysOfMonth.map((value, idx) => (
                  <Select.Option key={idx} value={idx}>
                    {globalTranslate(`${value.toLowerCase()}.text`, intl)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {values.payoutSettlementPeriod === SettlementPeriod.ANNUALLY && (
            <Form.Item
              label={translate(messages['rebates.payout-date-and-month.text'])}
              {...formItemHorizontalLayout}
              help={
                errors.payoutSettlementDateAndMonth &&
                touched?.payoutSettlementDateAndMonth
              }
              validateStatus={
                errors.payoutSettlementDateAndMonth &&
                touched?.payoutSettlementDateAndMonth
                  ? 'error'
                  : ''
              }
            >
              <DatePicker
                getPopupContainer={(trigger: { parentNode: ReactElement }) =>
                  trigger.parentNode
                }
                allowClear={false}
                format="MM/DD/"
                size="large"
                value={values.payoutSettlementDateAndMonth}
                onChange={(value?: string | Moment) =>
                  setFieldValue('payoutSettlementDateAndMonth', value)
                }
              />
            </Form.Item>
          )}
          {[
            SettlementPeriod.DAILY,
            SettlementPeriod.WEEKLY,
            SettlementPeriod.MONTHLY,
            SettlementPeriod.ANNUALLY,
          ].includes(values?.payoutSettlementPeriod || '') && (
            <Form.Item
              label={translate(messages['rebates.payout-time.text'])}
              extra={
                values.payoutSettlementPeriod === SettlementPeriod.DAILY
                  ? translate(messages['rebates.payout-time-feedback.text'])
                  : ''
              }
              {...formItemHorizontalLayout}
            >
              <TimePicker
                getPopupContainer={(trigger: { parentNode: ReactElement }) =>
                  trigger.parentNode
                }
                allowClear={false}
                size="large"
                value={
                  moment(
                    `${time?.hour() as any}:${time?.minutes()}`,
                    'HH:mm'
                  ) as any
                }
                format="HH:mm"
                onChange={(value: any) =>
                  setFieldValue('payoutSettlementTime', value)
                }
                style={{ width: '100%' }}
              />
            </Form.Item>
          )}
        </>
      )}
      <Form.Item
        label={translate(messages['rebates.payout-method.text'])}
        {...formItemHorizontalLayout}
      >
        <Radio.Group
          name="automaticPayout"
          value={values.automaticPayout}
          onChange={handleChange}
        >
          <Radio className="mr-4" value>
            <FormattedMessage
              id="rebates.automatic-payout.text"
              defaultMessage="Automatic payout"
            />
          </Radio>
          <Radio value={false}>
            <FormattedMessage
              id="manual-clain.text"
              defaultMessage="Manual Claim"
            />
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={translate(messages['rebates.turnover.text'])}
        {...formItemHorizontalLayout}
      >
        <Input
          size="large"
          suffix="x"
          min={0}
          name="turnoverRequirementMultiplier"
          type="number"
          onChange={handleChange}
          value={values.turnoverRequirementMultiplier.toString()}
        />
      </Form.Item>

      <Form.Item
        label={translate(messages['rebates.claim-offset-time.text'])}
        {...formItemHorizontalLayout}
        // extra={translate(
        //   messages['rebates.claim-offset-time-desc.text']
        // )}
      >
        <StyledInputGroup compact>
          <Input
            style={{ width: '40%' }}
            size="large"
            min={0}
            name="claimOffsetDuration"
            type="number"
            onChange={handleChange}
            value={values.claimOffsetDuration}
            disabled={values.noClaimOffsetDuration}
          />
          <Select
            getPopupContainer={(trigger: { parentNode: ReactElement }) =>
              trigger.parentNode
            }
            size="large"
            name="claimOffsetDurationPeriod"
            onChange={(value: string) =>
              setFieldValue('claimOffsetDurationPeriod', value)
            }
            value={values.claimOffsetDurationPeriod}
            style={{ width: '25%' }}
            disabled={values.noClaimOffsetDuration}
          >
            <Select.Option value="h">
              {translate(messages['common.hour.text'])}
            </Select.Option>
            <Select.Option value="d">
              {translate(messages['common.day.text'])}
            </Select.Option>
            <Select.Option value="m">
              {translate(messages['common.month.text'])}
            </Select.Option>
            <Select.Option value="y">
              {translate(messages['common.year.text'])}
            </Select.Option>
            {values.noClaimOffsetDuration && (
              <Select.Option value="-">-</Select.Option>
            )}
          </Select>
          <Checkbox
            style={{ width: '35%' }}
            name="noClaimOffsetDuration"
            checked={values.noClaimOffsetDuration}
            onChange={(e) => {
              if (handleChange) {
                handleChange(e);
              }
              if (e.target.checked) {
                setFieldValue('claimOffsetDurationPeriod', '-');
              } else {
                setFieldValue('claimOffsetDurationPeriod', 'd');
              }
            }}
          >
            {translate(messages['rebates.no-claim-offset-time.text'])}
          </Checkbox>
        </StyledInputGroup>
      </Form.Item>
      <Form.Item
        className={cx({ 'd-none': values.automaticPayout })}
        label={translate(messages['rebates.claim-expiry-time.text'])}
        {...formItemHorizontalLayout}
      >
        <StyledInputGroup compact>
          <Input
            style={{ width: '40%' }}
            size="large"
            min={0}
            name="claimExpiryDuration"
            type="number"
            onChange={handleChange}
            value={values.claimExpiryDuration}
            disabled={values.noClaimExpiryDuration}
          />
          <Select
            getPopupContainer={(trigger: { parentNode: ReactElement }) =>
              trigger.parentNode
            }
            size="large"
            name="claimExpiryDurationPeriod"
            onChange={(value: string) =>
              setFieldValue('claimExpiryDurationPeriod', value)
            }
            value={values.claimExpiryDurationPeriod}
            style={{ width: '25%' }}
            disabled={values.noClaimExpiryDuration}
          >
            <Select.Option value="h">
              {translate(messages['common.hour.text'])}
            </Select.Option>
            <Select.Option value="d">
              {translate(messages['common.day.text'])}
            </Select.Option>
            <Select.Option value="m">
              {translate(messages['common.month.text'])}
            </Select.Option>
            <Select.Option value="y">
              {translate(messages['common.year.text'])}
            </Select.Option>
            {values.noClaimExpiryDuration && (
              <Select.Option value="-">-</Select.Option>
            )}
          </Select>
          <Checkbox
            style={{ width: '35%' }}
            name="noClaimExpiryDuration"
            checked={values.noClaimExpiryDuration}
            onChange={(e) => {
              if (handleChange) {
                handleChange(e);
              }
              if (e.target.checked) {
                setFieldValue('claimExpiryDurationPeriod', '-');
              } else {
                setFieldValue('claimExpiryDurationPeriod', 'd');
              }
            }}
          >
            {translate(messages['rebates.no-claim-expiry-time.text'])}
          </Checkbox>
        </StyledInputGroup>
      </Form.Item>
      <Form.Item
        label={translate(messages['rebates.minimum-and-maximum.text'])}
        {...formItemHorizontalLayout}
      >
        <Row gutter={8} className="mb-2">
          <Col span={12}>
            <Form.Item
              validateStatus={errors.minimumPayout ? 'error' : null}
              help={errors.minimumPayout}
            >
              <Input
                size="large"
                name="minimumPayout"
                type="number"
                min={0}
                onChange={handleChange}
                value={values.minimumPayout}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <FormattedMessage
              id="rebates.minimum-amount.text"
              defaultMessage="Minimum amount"
            />
            ({currency?.symbol})
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              validateStatus={errors.maximumPayout ? 'error' : null}
              help={errors.maximumPayout}
            >
              <Input
                size="large"
                name="maximumPayout"
                type="number"
                min={0}
                onChange={handleChange}
                value={values.maximumPayout}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <FormattedMessage
              id="rebates.maximum-amount.text"
              defaultMessage="Maximum amount"
            />
            ({currency?.symbol})
          </Col>
        </Row>
      </Form.Item>
    </FormWrapper>
  );
}

export default PayoutSettings;
