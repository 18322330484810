import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import React, { FC } from 'react';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/date';
import { StyledLabel } from 'components/Layout/LeftSidebar/style';
import { FormattedMessage } from 'react-intl';
import { startCase } from 'lodash';
import { StyledSpan } from 'styles/SharedStyledSelectFilter';

const { RangePicker } = DatePicker;

type Props = {
  label: string;
  showTime?: boolean;
  isFilter?: boolean;
  value: [unknown, unknown] | null;
  onChange: (e: [string, string] | null) => void;
  testId?: string;
  showPreSets?: boolean;
  disableDateInput?: boolean;
  showLabel?: boolean;
};

const DateTimeRangePicker: FC<Props> = ({
  label,
  value,
  onChange,
  showTime = false,
  isFilter = true,
  testId,
  showPreSets = true,
  disableDateInput = false,
  showLabel = true,
}) => {
  const handleDateTimeChange: RangePickerProps['onChange'] | [] = (dates) => {
    const startDateFormat = 'YYYY-MM-DDT00:00:00.000Z';
    const endDateFormat = 'YYYY-MM-DDT23:59:59.999Z';

    if (dates) {
      const startDate = moment(dates[0]).format(startDateFormat);

      const endDate = moment(dates[1]).format(endDateFormat);

      onChange([startDate, endDate]);
    } else {
      onChange(null);
    }
  };

  const Label = () =>
    isFilter ? (
      <div>
        {showLabel && (
          <div className="d-flex justify-content-between">
            <StyledLabel>
              <FormattedMessage
                id={`${label}.text`}
                defaultMessage={startCase(label)}
              />
            </StyledLabel>
            <StyledSpan onClick={() => onChange(null)}>Clear</StyledSpan>
          </div>
        )}
      </div>
    ) : (
      <h4>{label}</h4>
    );

  return (
    <div className="mb-2 mt-2">
      {showLabel && <Label />}
      <RangePicker
        disabled={disableDateInput}
        data-testid={testId || 'date-range-picker'}
        {...(showPreSets
          ? {
              ranges: {
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, 'day'),
                  moment().subtract(1, 'day'),
                ],
                'Last 7 Days': [
                  moment()
                    .subtract(7, 'day')
                    .startOf('day'),
                  moment().subtract(1, 'day'),
                ],
                'Last Week': [
                  moment()
                    .subtract(1, 'weeks')
                    .startOf('isoWeek'),
                  moment()
                    .subtract(1, 'weeks')
                    .endOf('isoWeek'),
                ],
                'This Month': [
                  moment().startOf('month'),
                  moment().endOf('month'),
                ],
                'Last Month': [
                  moment()
                    .subtract(1, 'months')
                    .startOf('month'),
                  moment()
                    .subtract(1, 'months')
                    .endOf('month'),
                ],
              },
            }
          : {})}
        value={
          value?.length
            ? [moment(value?.[0] as string), moment(value?.[1] as string)]
            : null
        }
        showTime={showTime}
        onChange={handleDateTimeChange}
        format={!showTime ? DATE_FORMAT : DATE_TIME_FORMAT}
        placeholder={['Start Date', 'End Date']}
        allowClear
      />
    </div>
  );
};

export default DateTimeRangePicker;
