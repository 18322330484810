// removes object properties that has empty object values

const isEmpty = (obj: any): boolean =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export default (obj: any): any => {
  const myObj = { ...obj };
  Object.keys(myObj).forEach(key => isEmpty(myObj[key]) && delete myObj[key]);
  return myObj;
};
