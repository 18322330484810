import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  // STATUSES
  'received.text': {
    id: 'received.text',
    defaultMessage: 'Received',
  },
  'processing.text': {
    id: 'processing.text',
    defaultMessage: 'Processing',
  },
  'approved.text': {
    id: 'approved.text',
    defaultMessage: 'Approved',
  },
  'rejected.text': {
    id: 'rejected.text',
    defaultMessage: 'Rejected',
  },
  'expired.text': {
    id: 'expired.text',
    defaultMessage: 'Expired',
  },
  'disbursed.text': {
    id: 'disbursed.text',
    defaultMessage: 'Disbursed',
  },
  'claimable.text': {
    id: 'claimable.text',
    defaultMessage: 'Claimable',
  },
  // PROMOTION TYPES
  'manual-approval.text': {
    id: 'manual-approval.text',
    defaultMessage: 'Manual Approval',
  },
  'automatic-payout.text': {
    id: 'automatic-payout.text',
    defaultMessage: 'Automatic Payout',
  },
  'manual-claim.text': {
    id: 'manual-clain.text',
    defaultMessage: 'Manual Claim',
  },
  // VIP TYPES
  'tier-change.text': {
    id: 'tier-change.text',
    defaultMessage: 'Tier Change',
  },
  'auto-payout-on.text': {
    id: 'auto-payout-on.text',
    defaultMessage: 'Auto Payout - ON',
  },
  'auto-payout-off.text': {
    id: 'auto-payout-off.text',
    defaultMessage: 'Auto Payout - OFF',
  },
  // VIP STATUSES
  'upgraded.text': {
    id: 'upgraded.text',
    defaultMessage: 'Upgraded',
  },
  'downgraded.text': {
    id: 'downgraded.text',
    defaultMessage: 'Downgraded',
  },
  'upgrade-award.text': {
    id: 'upgrade-award.text',
    defaultMessage: 'Upgrade Award',
  },
  'birthday-award.text': {
    id: 'birthday-award.text',
    defaultMessage: 'Birthday Award',
  },
  'daily-award.text': {
    id: 'daily-award.text',
    defaultMessage: 'Daily Award',
  },
  'weekly-award.text': {
    id: 'weekly-award.text',
    defaultMessage: 'Weekly Award',
  },
  'monthly-award.text': {
    id: 'monthly-award.text',
    defaultMessage: 'Monthly Award',
  },
  'annual-award.text': {
    id: 'annual-award.text',
    defaultMessage: 'Annual Award',
  },
  // REBATES STATUSES
  'live-rebate.text': {
    id: 'live-rebate.text',
    defaultMessage: 'Live Rebate',
  },
  'periodic-rebate.text': {
    id: 'periodic-rebate.text',
    defaultMessage: 'Periodic Rebate',
  },
});
