import * as Yup from 'yup';
import messages from 'messages';
/*
 * Add the validation schemas here that will be shared
 * So we have one namespace
 * */

export class ValidationSchema {
  static sendMessage(
    translate: any,
    maxTitle: number,
    maxContent: number,
    isBulk: boolean
  ) {
    return Yup.object().shape({
      ...(isBulk && {
        userIds: Yup.array()
          .nullable()
          .required(translate(messages['users-field-required.text'])),
      }),
      title: Yup.string()
        .nullable()
        .required(translate(messages['title-field-required.text']))
        .max(
          maxTitle,
          translate(messages['maximum-number-of-characters.text'], {
            count: maxTitle,
          })
        ),
      body: Yup.string()
        .nullable()
        .required(translate(messages['body-field-required.text']))
        .max(
          maxContent,
          translate(messages['maximum-number-of-characters.text'], {
            count: maxContent,
          })
        ),
      scheduleSend: Yup.bool(),
      date: Yup.string()
        .nullable()
        .when('scheduleSend', {
          is: (val) => val,
          then: Yup.string().required(
            translate(messages['date-field-required.text'])
          ),
        }),
      time: Yup.string()
        .nullable()
        .when('scheduleSend', {
          is: (val) => val,
          then: Yup.string()
            .nullable()
            .required(translate(messages['time-field-required.text'])),
        }),
    });
  }
}
