import { defineMessages } from 'react-intl';

const messages = defineMessages({
  ACCOUNT_RESTRICTION: {
    id: 'ACCOUNT_RESTRICTION',
    defaultMessage: 'Account Restriction',
  },
  MEMBERS_MEMBER_MANAGEMENT_REPORT_GENERATE_CSV: {
    id: 'MEMBERS_MEMBER_MANAGEMENT_REPORT_GENERATE_CSV',
    defaultMessage: 'Generate CSV Report',
  },
  MEMBERS_MEMBER_MANAGEMENT_REPORT_CSV_DOWNLOAD_BUTTON: {
    id: 'MEMBERS_MEMBER_MANAGEMENT_REPORT_CSV_DOWNLOAD_BUTTON',
    defaultMessage: 'CSV Report Download Button',
  },
  MEMBER_INTERACTION_LOGS_REPORT: {
    id: 'MEMBER_INTERACTION_LOGS_REPORT',
    defaultMessage: 'Interaction Logs Report',
  },
  MEMBER_INTERACTION_LOGS_REPORT_LIST: {
    id: 'MEMBER_INTERACTION_LOGS_REPORT_LIST',
    defaultMessage: 'List',
  },
  DOCUMENT_MANAGEMENT: {
    id: 'DOCUMENT_MANAGEMENT',
    defaultMessage: 'Document Management',
  },
  DOCUMENT_MANAGEMENT_LIST: {
    id: 'DOCUMENT_MANAGEMENT_LIST',
    defaultMessage: 'List',
  },
  DOCUMENT_MANAGEMENT_UPLOAD: {
    id: 'DOCUMENT_MANAGEMENT_UPLOAD',
    defaultMessage: 'Upload',
  },
  DOCUMENT_MANAGEMENT_APPROVE: {
    id: 'DOCUMENT_MANAGEMENT_APPROVE',
    defaultMessage: 'Approve',
  },
  DOCUMENT_MANAGEMENT_REJECT: {
    id: 'DOCUMENT_MANAGEMENT_REJECT',
    defaultMessage: 'Reject',
  },
  DOCUMENT_MANAGEMENT_DELETE: {
    id: 'DOCUMENT_MANAGEMENT_DELETE',
    defaultMessage: 'Delete',
  },
  REALITY_CHECK: {
    id: 'REALITY_CHECK',
    defaultMessage: 'Reality Check',
  },
  REALITY_CHECK_LIST: {
    id: 'REALITY_CHECK_LIST',
    defaultMessage: 'List',
  },
  REALITY_CHECK_EDIT: {
    id: 'REALITY_CHECK_EDIT',
    defaultMessage: 'Edit',
  },
  DEPOSIT_LIMIT: {
    id: 'DEPOSIT_LIMIT',
    defaultMessage: 'Deposit Limit',
  },
  DEPOSIT_LIMIT_LIST: {
    id: 'DEPOSIT_LIMIT_LIST',
    defaultMessage: 'List',
  },
  DEPOSIT_LIMIT_EDIT: {
    id: 'DEPOSIT_LIMIT_EDIT',
    defaultMessage: 'Edit',
  },
  INTERNAL_SELF_EXCLUSION: {
    id: 'INTERNAL_SELF_EXCLUSION',
    defaultMessage: 'Self-Exclusion',
  },
  INTERNAL_SELF_EXCLUSION_LIST: {
    id: 'INTERNAL_SELF_EXCLUSION_LIST',
    defaultMessage: 'List',
  },
  INTERNAL_SELF_EXCLUSION_EDIT: {
    id: 'INTERNAL_SELF_EXCLUSION_EDIT',
    defaultMessage: 'Edit',
  },
  TIMEOUT: {
    id: 'TIMEOUT',
    defaultMessage: 'Timeout',
  },
  TIMEOUT_LIST: {
    id: 'TIMEOUT_LIST',
    defaultMessage: 'List',
  },
  TIMEOUT_EDIT: {
    id: 'TIMEOUT_EDIT',
    defaultMessage: 'Edit',
  },
  PROMO_LABELS: {
    id: 'PROMO_LABELS',
    defaultMessage: 'Promo Labels',
  },
  MANAGE_CURRENCY: {
    id: 'MANAGE_CURRENCY',
    defaultMessage: 'Manage Currency',
  },
  MANAGE_COUNTRY: {
    id: 'MANAGE_COUNTRY',
    defaultMessage: 'Manage Country',
  },
  MANAGE_COUNTRY_CODE: {
    id: 'MANAGE_COUNTRY_CODE',
    defaultMessage: 'Manage Country Code',
  },
  MANAGE_REALITY_CHECK: {
    id: 'MANAGE_REALITY_CHECK',
    defaultMessage: 'Manage Reality Check',
  },
  EMAIL_AUDIT_TRAIL: {
    id: 'EMAIL_AUDIT_TRAIL',
    defaultMessage: 'Email Audit Trail',
  },
  CHECK_FRAUD: {
    id: 'CHECK_FRAUD',
    defaultMessage: 'Check Fraud',
  },
  WITHDRAWAL_CANCELATION: {
    id: 'WITHDRAWAL_CANCELATION',
    defaultMessage: 'Withdrawal Cancelation',
  },
  SESSION_MANAGEMENT: {
    id: 'SESSION_MANAGEMENT',
    defaultMessage: 'Session Management',
  },
  HEXOPAY_SHOP_DETAILS: {
    id: 'HEXOPAY_SHOP_DETAILS',
    defaultMessage: 'Hexopay Shop Details',
  },

  NETELLER_SHOP_DETAILS: {
    id: 'NETELLER_SHOP_DETAILS',
    defaultMessage: 'Neteller Shop Details',
  },

  SKRILL_SHOP_DETAILS: {
    id: 'SKRILL_SHOP_DETAILS',
    defaultMessage: 'Skrill Shop Details',
  },
  WONKAPAY_SHOP_DETAILS: {
    id: 'WONKAPAY_SHOP_DETAILS',
    defaultMessage: 'WonkaPay Shop Details',
  },
  MANAGE_EMAIL_CONTENT: {
    id: 'MANAGE_EMAIL_CONTENT',
    defaultMessage: 'Manage Email Content',
  },
  MANAGE_SMTP_CONFIG: {
    id: 'MANAGE_SMTP_CONFIG',
    defaultMessage: 'Manage SMTP Config',
  },
  MANAGE_DYNAMIC_PAGES: {
    id: 'MANAGE_DYNAMIC_PAGES',
    defaultMessage: 'Manage Dynamic Pages',
  },
  LOQATE_INTEGRATION: {
    id: 'LOQATE_INTEGRATION',
    defaultMessage: 'Loqate Integration',
  },
  CHECK_DEPOSIT_LIMIT: {
    id: 'CHECK_DEPOSIT_LIMIT',
    defaultMessage: 'Deposit Limit',
  },
  SIGN_UP_CONFIG: {
    id: 'SIGN_UP_CONFIG',
    defaultMessage: 'Sign Up Config',
  },
  GBG_MANAGEMENT: {
    id: 'GBG_MANAGEMENT',
    defaultMessage: 'GBG Management',
  },
  CHECK_GAMSTOP: {
    id: 'CHECK_GAMSTOP',
    defaultMessage: 'GAMSTOP',
  },
  LIST: {
    id: 'view.text',
    defaultMessage: 'View',
  },
  MEMBERS_MANAGEMENT_CONFIG: {
    id: 'member-management-config.text',
    defaultMessage: 'Member Management Config',
  },
  MEMBERS_MANAGEMENT_CONFIG_VIEW: {
    id: 'member-management-config-view.text',
    defaultMessage: 'View',
  },
  MEMBERS_MANAGEMENT_CONFIG_EDIT: {
    id: 'member-management-config-edit.text',
    defaultMessage: 'Edit',
  },
  BANNER_SETTINGS_VIEW: {
    id: 'banner-settings-view.text',
    defaultMessage: 'View Banner Settings',
  },
  BANNER_SETTINGS_EDIT: {
    id: 'banner-settings-edit.text',
    defaultMessage: 'Edit Banner Settings',
  },
  SYSTEM_MANAGEMENT_COMMON_CONFIG: {
    id: 'SYSTEM_MANAGEMENT_COMMON_CONFIG',
    defaultMessage: 'Common Configuration',
  },
  VIEW: {
    id: 'view.text',
    defaultMessage: 'View',
  },
  VIEW_PAYMENT_SETTINGS: {
    id: 'view-payment-settings.text',
    defaultMessage: 'View Payment Settings',
  },
  VIEW_DETAILS: {
    id: 'view-details.text',
    defaultMessage: 'View Details',
  },
  UPDATE_REMARKS: {
    id: 'update-remarks.text',
    defaultMessage: 'Update Remarks',
  },
  ADD_VENDOR: {
    id: 'add-vendor.text',
    defaultMessage: 'Add Vendor',
  },
  TOGGLE_STATE: {
    id: 'on-or-off.text',
    defaultMessage: 'On / Off',
  },
  EDIT: {
    id: 'edit.text',
    defaultMessage: 'Edit',
  },
  EDIT_PAYMENT_SETTINGS: {
    id: 'edit-payment-settings.text',
    defaultMessage: 'Edit Payment Settings',
  },
  EDIT_BALANCE: {
    id: 'edit-balance.text',
    defaultMessage: 'Edit Balance',
  },
  ADD_BALANCE: {
    id: 'add-balance.text',
    defaultMessage: 'Add Balance',
  },
  ADD_BALANCE_PAYOUT: {
    id: 'add-balance-payout.text',
    defaultMessage: 'Payout',
  },
  ADD_BALANCE_DEPOSIT: {
    id: 'add-balance-deposit.text',
    defaultMessage: 'Deposit',
  },
  ADD_BALANCE_EXTERNAL_PROGRAMME: {
    id: 'add-balance-external-programme.text',
    defaultMessage: 'External Programme',
  },
  ADD_BALANCE_DEBIT_TRANSFER: {
    id: 'add-balance-debit-transfer.text',
    defaultMessage: 'Debit Transfer',
  },
  ADD_BALANCE_SYSTEM_CREDIT: {
    id: 'add-balance-system-credit.text',
    defaultMessage: 'System Credit',
  },
  REMOVE_BALANCE: {
    id: 'remove-balance.text',
    defaultMessage: 'Remove Balance',
  },
  REMOVE_BALANCE_WITHDRAWAL: {
    id: 'remove-balance-withdrawal.text',
    defaultMessage: 'Withdrawal',
  },
  REMOVE_BALANCE_MANUAL_WITHDRAWAL: {
    id: 'remove-balance-manual-withdrawal.text',
    defaultMessage: 'Manual Withdrawal',
  },
  REMOVE_BALANCE_CREDIT_TRANSFER: {
    id: 'remove-balance-credit-transfer.text',
    defaultMessage: 'Credit Transfer',
  },
  REMOVE_BALANCE_SYSTEM_DEBIT: {
    id: 'remove-balance-system-debit.text',
    defaultMessage: 'System Debit',
  },
  EDIT_LABEL: {
    id: 'edit-label.text',
    defaultMessage: 'Edit Label',
  },
  EDIT_STATUS: {
    id: 'edit-status.text',
    defaultMessage: 'Edit Status',
  },
  EDIT_MEMBER: {
    id: 'edit-member.text',
    defaultMessage: 'Edit Member',
  },
  EDIT_MEMBER_MARKER: {
    id: 'edit-member-marker.text',
    defaultMessage: 'Edit Member Marker',
  },
  EDIT_VIP: {
    id: 'edit-vip.text',
    defaultMessage: 'Edit VIP',
  },
  CHANGE_PASSWORD: {
    id: 'change-password.text',
    defaultMessage: 'Change Password',
  },
  CHANGE_WITHDRAWAL_PASSWORD: {
    id: 'change-withdrawal-password.text',
    defaultMessage: 'Change Withdrawal Password',
  },
  SEND_MESSAGE: {
    id: 'send-message.text',
    defaultMessage: 'Send Message',
  },
  EDIT_BRAND_LINKAGES: {
    id: 'edit-brand-linkages.text',
    defaultMessage: 'Edit Brand Linkages',
  },
  CREATE: {
    id: 'create.text',
    defaultMessage: 'Create',
  },
  DELETE: {
    id: 'delete.text',
    defaultMessage: 'Delete',
  },
  HEXOPAY_CREDIT_CARDS_TOGGLE_CLOSED_LOOP: {
    id: 'hexopay-credit-cards-toggle-closed-loop.text',
    defaultMessage: 'Toggle Closed Loop',
  },
  DUPLICATE: {
    id: 'duplicate.text',
    defaultMessage: 'Duplicate',
  },
  TOGGLE_ENABLE: {
    id: 'enable-or-disable.text',
    defaultMessage: 'Enable / Disable',
  },
  TOGGLE_ENABLED: {
    id: 'enable-or-disable.text',
    defaultMessage: 'Enable / Disable',
  },
  TOGGLE_PUBLISHED: {
    id: 'publish-or-unpublish.text',
    defaultMessage: 'Publish / Unpublish',
  },
  TOGGLE_ACTIVATION: {
    id: 'activate-or-deactivate.text',
    defaultMessage: 'Activate / Deactivate',
  },
  APPROVE: {
    id: 'approve.text',
    defaultMessage: 'Approve',
  },
  REJECT: {
    id: 'reject.text',
    defaultMessage: 'Reject',
  },
  PROCESS: {
    id: 'process.text',
    defaultMessage: 'Process',
  },
  ON_HOLD: {
    id: 'on-hold.text',
    defaultMessage: 'On Hold',
  },
  ACCOUNT_BALANCE: {
    id: 'account-balance.text',
    defaultMessage: 'Account Balance',
  },
  WALLET_BALANCE: {
    id: 'member-detail.wallet-balance.text',
    defaultMessage: 'Wallet Balance',
  },
  TO_BALANCE: {
    id: 't/o-balance.text',
    defaultMessage: 'T/O Balance',
  },
  LAST_DEPOSIT: {
    id: 'member-detail.last-deposit.text',
    defaultMessage: 'Last Deposit',
  },
  LAST_WITHDRAWAL: {
    id: 'member-detail.last-withdrawal.text',
    defaultMessage: 'Last Withdrawal',
  },
  LAST_PROMO_APPLIED: {
    id: 'member-detail.last-promo-applied.text',
    defaultMessage: 'Last Promo Applied',
  },
  LAST_PLAYED_GAMES: {
    id: 'member-detail.last-played-games.text',
    defaultMessage: 'Last Played Games',
  },
  MEMBER_NOTES: {
    id: 'member-details.member-notes.text',
    defaultMessage: 'Member Notes',
  },
  MEMBER_NOTES_LIST: {
    id: 'member-details.member-notes-list.text',
    defaultMessage: 'List',
  },
  MEMBER_NOTES_ADD: {
    id: 'member-details.member-notes-add.text',
    defaultMessage: 'Add',
  },
  MEMBER_NOTES_EDIT: {
    id: 'member-details.member-notes-edit.text',
    defaultMessage: 'Edit',
  },
  MEMBER_NOTES_DELETE: {
    id: 'member-details.member-notes-delete.text',
    defaultMessage: 'Delete',
  },
  PLAYER_PROFILE: {
    id: 'player-profile.text',
    defaultMessage: 'Player Profile Link',
  },
  PLAYER_PROFILE_EDIT: {
    id: 'player-profile-edit.text',
    defaultMessage: 'Edit',
  },
  PLAYER_PROFILE_VIEW: {
    id: 'player-profile-view.text',
    defaultMessage: 'View',
  },
  HEXOPAY_CREDIT_CARDS: {
    id: 'member-details.hexopay-credit-cards.text',
    defaultMessage: 'Hexopay Credit Cards',
  },
  HEXOPAY_CREDIT_CARDS_LIST: {
    id: 'member-details.hexopay-credit-cards-list.text',
    defaultMessage: 'List',
  },
  HEXOPAY_CREDIT_CARDS_DELETE: {
    id: 'member-details.hexopay-credit-cards-delete.text',
    defaultMessage: 'Delete',
  },
  HEXOPAY_CREDIT_CARDS_DISABLE: {
    id: 'member-details.hexopay-credit-cards-disable.text',
    defaultMessage: 'Disable',
  },
  AGENT_AFFILIATE: {
    id: 'agent-affiliate.text',
    defaultMessage: 'Agent Affiliate',
  },
  MEMBER_INFORMATION: {
    id: 'MEMBER_INFORMATION',
    defaultMessage: 'Member Information',
  },
  VIP_TIER_PROGRESS: {
    id: 'VIP_TIER_PROGRESS',
    defaultMessage: 'VIP Tier Progress',
  },
  MEMBER_LABEL: {
    id: 'MEMBER_LABEL',
    defaultMessage: 'Member Label',
  },
  USER_PROFILE: {
    id: 'USER_PROFILE',
    defaultMessage: 'User Profile',
  },
  LOGIN_DETAILS: {
    id: 'LOGIN_DETAILS',
    defaultMessage: 'Login Details',
  },
  CONTACT_DETAILS: {
    id: 'CONTACT_DETAILS',
    defaultMessage: 'Contact Details',
  },
  WITHDRAWAL_BANK_ACCOUNT: {
    id: 'WITHDRAWAL_BANK_ACCOUNT',
    defaultMessage: 'Withdrawal Bank Account',
  },
  REGISTRATION_INFORMATION: {
    id: 'REGISTRATION_INFORMATION',
    defaultMessage: 'Registration Information',
  },
  MESSAGES: {
    id: 'messages.text',
    defaultMessage: 'Messages',
  },
  DASHBOARD: {
    id: 'dashboard.text',
    defaultMessage: 'Dashboard',
  },
  DASHBOARDS: {
    id: 'dashboard.text',
    defaultMessage: 'Dashboard',
  },
  DASHBOARDS_INSTANT_FOCUS: {
    id: 'todays-instant-focus.text',
    defaultMessage: "Today's instant focus",
  },
  OPERATORS: {
    id: 'operators.text',
    defaultMessage: 'Operators',
  },
  OPERATORS_ACCOUNT_MANAGEMENT: {
    id: 'account-management.text',
    defaultMessage: 'Account Management',
  },
  OPERATORS_PERMISSION_GROUP: {
    id: 'permission-group.text',
    defaultMessage: 'Permission Group',
  },
  REPORTS_EU_REPORTS: {
    id: 'REPORTS_EU_REPORTS',
    defaultMessage: 'EU Reports',
  },
  DAILY_DEPOSIT_REPORT: {
    id: 'DAILY_DEPOSIT_REPORT',
    defaultMessage: 'Daily Deposit Report',
  },
  DAILY_PLAYER_ACTIVITIES_REPORT: {
    id: 'DAILY_PLAYER_ACTIVITIES_REPORT',
    defaultMessage: 'Daily Player Activities Report',
  },
  DAILY_SELF_EXCLUSION_REPORT: {
    id: 'DAILY_SELF_EXCLUSION_REPORT',
    defaultMessage: 'Daily Self-Exclusion Report',
  },
  DAILY_TIMEOUT_REPORT: {
    id: 'DAILY_TIMEOUT_REPORT',
    defaultMessage: 'Daily Timeout Report',
  },
  DAILY_TRANSACTION_REPORT: {
    id: 'DAILY_TRANSACTION_REPORT',
    defaultMessage: 'Daily Transaction Report',
  },
  DEPOSIT_LIMIT_REPORT: {
    id: 'DEPOSIT_LIMIT_REPORT',
    defaultMessage: 'Deposit Limit Report',
  },
  FULL_CUSTOMER_DUMPS: {
    id: 'FULL_CUSTOMER_DUMPS',
    defaultMessage: 'Full Customer Dumps',
  },
  NEWLY_REGISTERED_MEMBERS_REPORT: {
    id: 'NEWLY_REGISTERED_MEMBERS_REPORT',
    defaultMessage: 'Newly Registered Members Report',
  },
  PLAYER_STATUS_REPORT: {
    id: 'PLAYER_STATUS_REPORT',
    defaultMessage: 'Player Status Report',
  },
  MEMBERS: {
    id: 'members.text',
    defaultMessage: 'Members',
  },
  MEMBERS_MEMBER_MANAGEMENT: {
    id: 'member-management.text',
    defaultMessage: 'Member Management',
  },
  MEMBERS_MEMBER_MARKER_MANAGEMENT: {
    id: 'member-level-management.text',
    defaultMessage: 'Member Marker Management',
  },
  MEMBERS_LABEL_MANAGEMENT: {
    id: 'member.label-management.text',
    defaultMessage: 'Label management',
  },
  MEMBERS_LABEL_MANAGEMENT_VIEW_DETAILS: {
    id: 'member.label-management-view-details.text',
    defaultMessage: 'View Details',
  },
  MEMBERS_LABEL_MANAGEMENT_LIST: {
    id: 'member.label-management-list.text',
    defaultMessage: 'View',
  },
  MEMBERS_LABEL_MANAGEMENT_CREATE: {
    id: 'member.label-management-create.text',
    defaultMessage: 'Create',
  },
  MEMBERS_LABEL_MANAGEMENT_EDIT: {
    id: 'member.label-management-edit.text',
    defaultMessage: 'Edit',
  },
  MEMBERS_LABEL_MANAGEMENT_DELETE: {
    id: 'member.label-management-delete.text',
    defaultMessage: 'Delete',
  },
  MEMBERS_MEMBERS_ONLINE_ONLY: {
    id: 'members-online.text',
    defaultMessage: 'Members Online',
  },

  MEMBERS_MEMBER_BULK_UPDATE: {
    id: 'members-bulk-update.text',
    defaultMessage: 'Members Bulk Update',
  },

  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_STATUS: {
    id: 'bulk-change-status.text',
    defaultMessage: 'Bulk Change Status',
  },
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_GAME_ROUNDS: {
    id: 'Bulk_Update_Game_Round',
    defaultMessage: 'Bulk Update Game Round',
  },
  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_CREATE_NOTE: {
    id: 'bulk-add-notes.text',
    defaultMessage: 'Bulk Add Notes',
  },

  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_BALANCE: {
    id: 'bulk-edit-balance.text',
    defaultMessage: 'Bulk Edit Balance',
  },

  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_LABEL: {
    id: 'bulk-edit-labels.text',
    defaultMessage: 'Bulk Edit Labels',
  },

  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_MEMBER_MARKER: {
    id: 'bulk-edit-member-markers.text',
    defaultMessage: 'Bulk Edit Member Markers',
  },

  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_VIP: {
    id: 'bulk-edit-vip.text',
    defaultMessage: 'Bulk Edit VIP',
  },

  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_ACCOUNT_RESTRICTION: {
    id: 'bulk-account-restriction.text',
    defaultMessage: 'Bulk Account Restriction',
  },

  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_VERIFICATION_STATUS: {
    id: 'bulk-verification-status.text',
    defaultMessage: 'Bulk Verification Status',
  },

  MEMBERS_MEMBER_BULK_UPDATE_MEMBERS_BULK_UPDATE_ISSUE_BONUS: {
    id: 'bulk-issue-status.text',
    defaultMessage: 'Bulk Issue Bonus',
  },

  MEMBERS_MEMBERS_CONFIG: {
    id: 'members-config.text',
    defaultMessage: 'Members Management Config',
  },
  MEMBERS_AUTOMATION: {
    id: 'automation.text',
    defaultMessage: 'Automation',
  },
  MEMBERS_REPORTS: {
    id: 'members-reports.text',
    defaultMessage: 'Members Reports',
  },
  MEMBERS_REPORTS_IP_ADDRESS_SUMMARY_REPORT: {
    id: 'members-reports-member-ip-address-summary-report.text',
    defaultMessage: 'Member IP Address Summary',
  },
  MEMBERS_REPORTS_MEMBER_ACCESS_HISTORY_REPORT: {
    id: 'members-reports-member-access-history-report.text',
    defaultMessage: 'Member Access History Report',
  },
  MEMBERS_REPORTS_MEMBER_ACCESS_SUMMARY_REPORT: {
    id: 'members-reports-access-summary-report.text',
    defaultMessage: 'Member Access Summary Report',
  },
  MEMBERS_REPORTS_MEMBER_INTERACTION_LOGS_REPORT: {
    id: 'members-reports-member-interaction-logs-report.text',
    defaultMessage: 'Member Interaction Logs Report',
  },
  MEMBERS_REPORTS_MEMBER_IP_ADDRESS_REPORT: {
    id: 'members-reports-ip-address-summary-report.text',
    defaultMessage: 'IP Address Summary',
  },
  MEMBERS_REPORTS_MEMBER_SESSION_DURATION_REPORT: {
    id: 'members-reports-member-session-duration-report.text',
    defaultMessage: 'Member Session Duration',
  },
  AFFILIATES: {
    id: 'affiliates.text',
    defaultMessage: 'Affiliates',
  },
  AFFILIATES_AFFILIATE_PROGRAMME: {
    id: 'affiliate-programme.text',
    defaultMessage: 'Affiliate Programme',
  },
  AFFILIATES_AFFILIATE_CRITERIA: {
    id: 'agent-affiliate-criteria.text',
    defaultMessage: 'Affiliate Criteria',
  },
  AFFILIATES_AFFILIATE_REQUEST: {
    id: 'agent-affiliate-request.text',
    defaultMessage: 'Affiliate Request',
  },
  DEPOSITS: {
    id: 'deposits.text',
    defaultMessage: 'Deposits',
  },
  DEPOSITS_INTERNAL_DEPOSITS: {
    id: 'internal-deposit-requests.text',
    defaultMessage: 'Deposit Requests (Internal)',
  },
  DEPOSITS_EXTERNAL_DEPOSITS: {
    id: 'external-deposit-requests.text',
    defaultMessage: 'Deposit Requests (3rd party)',
  },
  DEPOSITS_PAYMENT_METHODS: {
    id: 'payment-method-listing.text',
    defaultMessage: 'Payment Method Listing',
  },
  DEPOSITS_THIRD_PARTY_DEPOSIT_PROVIDERS: {
    id: 'deposit-providers-external.text',
    defaultMessage: '3rd Party Deposit Providers',
  },
  WITHDRAWALS: {
    id: 'withdrawals.text',
    defaultMessage: 'Withdrawals',
  },
  WITHDRAWALS_WITHDRAWAL_REQUESTS: {
    id: 'withdrawal-requests.text',
    defaultMessage: 'Withdrawal Requests',
  },
  WITHDRAWALS_WITHDRAWAL_METHODS: {
    id: 'withdrawal-methods.text',
    defaultMessage: 'Withdrawal methods',
  },
  WITHDRAWALS_3RD_WITHDRAWAL_PROVIDERS: {
    id: '3rd-party-withdrawal-providers.text',
    defaultMessage: '3rd party withdrawal providers',
  },
  WITHDRAWALS_COMPLIANCE_CHECK: {
    id: 'compliance-check.text',
    defaultMessage: 'Compliance check',
  },
  CATEGORY: {
    id: 'category.text',
    defaultMessage: 'Category',
  },
  PROMOS: {
    id: 'promo.text',
    defaultMessage: 'Promo',
  },
  PROMOS_PROMO_LISTING: {
    id: 'promo-listing.text',
    defaultMessage: 'Promo Listing',
  },
  PROMOS_PROMO_LABELS: {
    id: 'PROMOS_PROMO_LABELS',
    defaultMessage: 'Promo Labels',
  },
  PROMOS_PROMO_REQUEST: {
    id: 'promo-request.text',
    defaultMessage: 'Promo Request',
  },
  VIP: {
    id: 'vip.text',
    defaultMessage: 'VIP',
  },
  VIP_VIP: {
    id: 'vip.text',
    defaultMessage: 'VIP',
  },
  REBATES: {
    id: 'rebates.text',
    defaultMessage: 'Rebates',
  },
  REPORTS: {
    id: 'reports.text',
    defaultMessage: 'Reports',
  },
  REPORTS_DAILY_OPERATING_REPORT: {
    id: 'daily-operating-report.text',
    defaultMessage: 'Daily Operating Report',
  },
  REPORTS_MEMBER_BET_RECORDS: {
    id: 'member-bet-records.text',
    defaultMessage: 'Member Bet Records',
  },
  REPORTS_VENDOR_GAME_REPORTS: {
    id: 'vendor-game-reports.text',
    defaultMessage: 'Vendor Game Reports',
  },
  REPORTS_MEMBER_PROMO_HISTORY_RECORDS: {
    id: 'member-promo-history.text',
    defaultMessage: 'Member Promo History',
  },
  REPORTS_BALANCE_TRANSACTION_REPORTS: {
    id: 'balance-transaction-records.text',
    defaultMessage: 'Balance Transaction Records',
  },
  REPORTS_PROMO_PAYOUT_RECORDS: {
    id: 'promo-payout-records.text',
    defaultMessage: 'Promo Payout Records',
  },
  REPORTS_STATISTICAL_REPORTS: {
    id: 'statistical-reports.text',
    defaultMessage: 'Statistical Reports',
  },
  REPORTS_ANALYSIS_REPORTS: {
    id: 'analysis-reports.text',
    defaultMessage: 'Analysis Reports',
  },
  REPORTS_REBATES_REPORTS: {
    id: 'rebates-reports.text',
    defaultMessage: 'Rebates Reports',
  },
  REPORTS_LOTTERY_REPORTS: {
    id: 'lottery-reports.text',
    defaultMessage: 'Lottery Reports',
  },
  REPORTS_MEMBER_GAME_REPORTS: {
    id: 'member-game-reports.text',
    defaultMessage: 'Member Game Reports',
  },
  REPORTS_BALANCE_SERVICE_RECORD_REPORTS: {
    id: 'balance-service-record-report.text',
    defaultMessage: 'Balance Service Record Report',
  },
  REPORTS_MEMBER_SUMMARY_REPORT: {
    id: 'member-summary-report.text',
    defaultMessage: 'Member Summary Report',
  },
  SYSTEM_MANAGEMENT: {
    id: 'system-management.text',
    defaultMessage: 'System Management',
  },
  SYSTEM_MANAGEMENT_SITE_CONFIG: {
    id: 'site-configurations.text',
    defaultMessage: 'Site Configurations',
  },
  SUBDOMAIN: {
    id: 'subdomain.text',
    defaultMessage: 'Subdomain',
  },
  SUBMIT: {
    id: 'submit.text',
    defaultMessage: 'Submit',
  },
  SYSTEM_MANAGEMENT_ALERTS_AND_NOTIFICATIONS: {
    id: 'alerts-and-notifications.text',
    defaultMessage: 'Alerts and Notifications',
  },
  SYSTEM_MANAGEMENT_DESKTOP_APP_CONFIG: {
    id: 'desktop-app-configuration.text',
    defaultMessage: 'Desktop App Configuration',
  },
  SYSTEM_MANAGEMENT_MOBILE_APP_CONFIG: {
    id: 'mobile-app-configuration.text',
    defaultMessage: 'Mobile App Configuration',
  },
  SYSTEM_MANAGEMENT_MARQUEE_MESSAGES: {
    id: 'marquee-messages.text',
    defaultMessage: 'Marquee Messages',
  },
  SYSTEM_MANAGEMENT_ROTATING_BANNER: {
    id: 'rotating-banner.text',
    defaultMessage: 'Rotating Banner',
  },
  SYSTEM_MANAGEMENT_VENDOR_MANAGEMENT: {
    id: 'vendor-management.text',
    defaultMessage: 'Vendor Management',
  },
  SYSTEM_MANAGEMENT_PROFILE_SETTING: {
    id: 'profile-setting.text',
    defaultMessage: 'Profile Setting',
  },
  SYSTEM_MANAGEMENT_GEO_FENCING: {
    id: 'geo-fencing.text',
    defaultMessage: 'Geo-Fencing',
  },
  SYSTEM_MANAGEMENT_SYSTEM_CONFIG: {
    id: 'force-profile-validation.text',
    defaultMessage: 'Force Profile Validation',
  },
  FORCE_VERIFICATION_VIEW: {
    id: 'force-profile-validation-view.text',
    defaultMessage: 'View',
  },
  FORCE_VERIFICATION_EDIT: {
    id: 'force-profile-validation-edit.text',
    defaultMessage: 'Edit',
  },
  SYSTEM_MESSAGES: {
    id: 'system-message.text',
    defaultMessage: 'System Message',
  },
  SYSTEM_MESSAGES_SYSTEM_MESSAGE: {
    id: 'system-message.text',
    defaultMessage: 'System Message',
  },
  SYSTEM_MESSAGES_MANUAL_MESSAGE: {
    id: 'permission.manual-message.text',
    defaultMessage: 'Manual Message',
  },
  SYSTEM_MESSAGES_AUTOMATIC_MESSAGE: {
    id: 'permission.automated-message.text',
    defaultMessage: 'Automated Message',
  },
  WATCHLIST: {
    id: 'watchlist.text',
    defaultMessage: 'Watchlist',
  },
  WATCHLIST_WATCHLIST: {
    id: 'watchlist.text',
    defaultMessage: 'Watchlist',
  },
  CSV_DOWNLOAD_BUTTON: {
    id: 'DOWNLOAD_CSV',
    defaultMessage: 'Download CSV',
  },
  // BULK_UPDATE: {
  //   id: 'BULK_UPDATE_GAME_ROUNDS',
  //   defaultMessage: 'Bulk Update Game Rounds',
  // },
  SYSTEM_MANAGEMENT_SITE_CONFIG_SUBDOMAIN: {
    id: 'subdomain.text',
    defaultMessage: 'Subdomain',
  },
  SUBDOMAIN_CREATE: {
    id: 'SUBDOMAIN_CREATE',
    defaultMessage: 'Create',
  },
  SUBDOMAIN_LIST: {
    id: 'SUBDOMAIN_LIST',
    defaultMessage: 'List',
  },
  SUBDOMAIN_EDIT: {
    id: 'SUBDOMAIN_EDIT',
    defaultMessage: 'Edit',
  },
  SUBDOMAIN_DELETE: {
    id: 'SUBDOMAIN_DELETE',
    defaultMessage: 'Delete',
  },
  SUBDOMAIN_DUPLICATE: {
    id: 'SUBDOMAIN_DUPLICATE',
    defaultMessage: 'Duplicate',
  },
  SUBDOMAIN_VIEW_DETAILS: {
    id: 'SUBDOMAIN_VIEW_DETAILS',
    defaultMessage: 'View Details',
  },
});

export default messages;
