import gql from 'graphql-tag';

export const MEMBER_LOYALTY_PROGRAMS_IDS = gql`
  query MemberLoyaltyProgramsIds(
    $filter: MemberLoyaltyProgrammesFilterInput
    $partialFilter: MemberLoyaltyProgrammesFilterInput
  ) {
    memberLoyaltyProgrammes(filter: $filter) {
      edges {
        cursor
        node {
          id
        }
      }
    }

    partial: memberLoyaltyProgrammes(filter: $partialFilter) {
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

export const MEMBER_LOYALTY_PROGRAMS = gql`
  query MemberLoyaltyProgrammes(
    $first: Int
    $after: Binary
    $filter: MemberLoyaltyProgrammesFilterInput
  ) {
    memberLoyaltyProgrammes: memberLoyaltyProgrammes(
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          validityStartDate
          validityEndDate
          id
          serialCode
          name
          status
          primary
          badgeSet
          membersCount
          levels {
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const MEMBER_LOYALTY_PROGRAMS_FULL_QUERY = gql`
  query MemberLoyaltyProgrammes(
    $first: Int
    $after: Binary
    $filter: MemberLoyaltyProgrammesFilterInput
  ) {
    memberLoyaltyProgrammes: memberLoyaltyProgrammes(
      first: $first
      after: $after
      filter: $filter
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          serialCode
          name
          description
          validityStartDate
          validityEndDate
          evaluationPeriod
          automaticMemberLoyaltyLevelUpgrade
          automaticMemberLoyaltyLevelDowngrade
          status
          membersCount
          primary
          dateTimeCreated
          dateTimeUpdated
          badgeSet

          levels {
            id
            name
            description
            inviteOnly
            color
            qualificationCriteria {
              type
              amount
              # level 1
              and {
                type
                amount
                gameTypes
                # level 2
                and {
                  # level 3
                  type
                  amount
                  gameTypes
                }
                or {
                  type
                  amount
                  gameTypes
                }
              }
            }

            ## previously benefits

            maximumWithdrawalAmountPerRequest
            maximumWithdrawalRequest
            withdrawalLimitRequestReset
            withdrawalLimitFee
            withdrawalLimitFeeType
            bankAccountLimits
            eWalletLimits

            ## previously payout
            autoPayout
            upgradePayout {
              limit
              amount
              claimExpiryAmount
              claimExpiryDuration
              claimOffsetAmount
              claimOffsetDuration
              payoutExpiryAmount
              payoutExpiryDuration
              turnover
            }
            dailyPayout {
              limit
              amount
              claimExpiryAmount
              claimExpiryDuration
              claimOffsetAmount
              claimOffsetDuration
              payoutExpiryAmount
              payoutExpiryDuration
              turnover
              timeOfDay
            }
            weeklyPayout {
              limit
              amount
              claimExpiryAmount
              claimExpiryDuration
              claimOffsetAmount
              claimOffsetDuration
              payoutExpiryAmount
              payoutExpiryDuration
              turnover
              timeOfDay
              dayOfWeek
            }
            monthlyPayout {
              limit
              amount
              claimExpiryAmount
              claimExpiryDuration
              claimOffsetAmount
              claimOffsetDuration
              payoutExpiryAmount
              payoutExpiryDuration
              turnover
              timeOfDay
              dayOfMonth
            }
            annualPayout {
              limit
              amount
              claimExpiryAmount
              claimExpiryDuration
              claimOffsetAmount
              claimOffsetDuration
              payoutExpiryAmount
              payoutExpiryDuration
              turnover
              timeOfDay
            }
            birthdayPayout {
              limit
              amount
              claimExpiryAmount
              claimExpiryDuration
              claimOffsetAmount
              claimOffsetDuration
              payoutExpiryAmount
              payoutExpiryDuration
              turnover
              timeOfDay
            }

            # payouts {
            # }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const LOYALTY_PROGRAMME_NAMES = gql`
  query FilterLoyaltyProgrammeNames(
    $first: Int
    $after: Binary
    $filter: MemberLoyaltyProgrammesFilterInput
  ) {
    memberLoyaltyProgrammes: memberLoyaltyProgrammes(
      first: $first
      after: $after
      filter: $filter
    ) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

export default {};
