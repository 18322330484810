import React from 'react';
import { Formik } from 'formik';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import * as yup from 'yup';
import styled from 'styled-components';

type Rec = Record<string, any>;

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

export const validationSchema = (formatMessage: (e: any) => string) =>
  yup.object().shape({
    name: yup
      .string()
      .nullable()
      .required(formatMessage(messages.required)),
  });

const { Item } = StyledForm;

const SearchForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: {
  initialValues: Rec;
  onSubmit: (e: any) => void;
  onClose: (e: any) => void;
  isLoading: boolean;
}) => {
  const intl = useIntl();
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema(intl.formatMessage)}
    >
      {({ handleSubmit, handleChange, errors, touched, values }) => (
        <div className="p-3">
          <StyledForm onSubmit={handleSubmit}>
            <Item
              label={<FormattedMessage id="name.text" defaultMessage="Name" />}
              validateStatus={errors.name && touched.name ? 'error' : undefined}
              help={touched.name ? errors.name : null}
            >
              <Input
                name="name"
                onChange={handleChange}
                value={values.name || ''}
                placeholder={intl.formatMessage(messages['please-enter.text'])}
              />
            </Item>
            <div className="text-right pt-1">
              <Button className="mr-2" onClick={onClose} loading={isLoading}>
                <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
              </Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                <FormattedMessage
                  id="save-changes.text"
                  defaultMessage="Save changes"
                />
              </Button>
            </div>
          </StyledForm>
        </div>
      )}
    </Formik>
  );
};

export default SearchForm;
