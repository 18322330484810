import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import { isEmpty } from 'lodash';
import { SearchFilterInput } from 'components/SearchFilterInput/SearchFilterInput';
import { StyledSelect } from 'pages/components/MarqueeMessages/components/SearchFilterSidebar/styles';
import { FilterItem } from 'components/FilterItem/FilterItem';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { useMarqueeValue } from '../../context';
import { useDebounce } from '../../../common/hooks/useDebounce';

const { Option } = Select;

const CustomOption: any = Option;
type FilterSelectProps = {
  statusInput: string;
  onChange: (key: string, value: any) => void;
};

export const StatusFilterSelect = ({
  statusInput,
  onChange,
}: FilterSelectProps) => (
  <FilterItem
    label={<FormattedMessage id="status.text" defaultMessage="Status" />}
  >
    <StyledSelect
      value={statusInput}
      onChange={(value: any) => onChange('status', value)}
      showSearch
      placeholder="Search"
    >
      <CustomOption value="">
        <FormattedMessage id="all.text" defaultMessage="All" />
      </CustomOption>
      <CustomOption value>
        <FormattedMessage id="active.text" defaultMessage="Active" />
      </CustomOption>
      <CustomOption value={false}>
        <FormattedMessage id="inactive.text" defaultMessage="Inactive" />
      </CustomOption>
    </StyledSelect>
  </FilterItem>
);

type SidebarProps = {
  page: Record<string, any>;
  getMarquee: () => void;
  pageInitialState: Record<string, any>;
};

const SearchFilterSidebar = ({
  page,
  getMarquee,
  pageInitialState,
}: SidebarProps) => {
  const translate = useTranslate();
  const [titleInput, setTitle] = useState('');
  const [bodyInput, setBody] = useState('');
  const [snippetInput, setSnippet] = useState('');
  const [statusInput, setStatus] = useState('');

  const debouncedInput = useDebounce(titleInput, 500);
  const debouncedBody = useDebounce(bodyInput, 500);
  const debouncedSnippet = useDebounce(snippetInput, 500);

  const { setPage } = useMarqueeValue()! as { setPage: (e: any) => void };

  const onChange = (type: string, value: string) => {
    if (type === 'title') {
      setTitle(value);
    } else if (type === 'body') {
      setBody(value);
    } else if (type === 'snippet') {
      setSnippet(value);
    } else {
      setStatus(value);
    }
  };

  const onFilter = () => {
    const enabled = {
      [statusInput === '' ? 'in' : 'eq']:
        statusInput === '' ? [true, false] : statusInput,
    };

    const searchCriteriaStatus = () => {
      if (!enabled.in) {
        return enabled.eq
          ? translate(messages['active.text'])
          : translate(messages['inactive.text']);
      }
      return null;
    };
    setPage(() => ({
      ...pageInitialState,
      filter: {
        ...(debouncedInput
          ? {
              title: {
                contains: debouncedInput,
              },
            }
          : {}),
        ...(debouncedBody
          ? {
              message: {
                contains: debouncedBody,
              },
            }
          : {}),
        ...(debouncedSnippet
          ? {
              snippet: {
                contains: debouncedSnippet,
              },
            }
          : {}),
        enabled,
      },
      searchCriteria: {
        title: debouncedInput,
        body: debouncedBody,
        snippet: debouncedSnippet,
        status: searchCriteriaStatus(),
      },
    }));

    getMarquee();
  };

  useEffect(() => {
    onFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedBody, debouncedInput, statusInput, debouncedSnippet]);

  useEffect(() => {
    if (!isEmpty(page.onTagClose)) {
      onChange(page.onTagClose, '');
    }
  }, [page.onTagClose]);

  return (
    <>
      <SearchFilterInput
        type="title"
        inputValueRef={titleInput}
        onChange={onChange}
      />

      <SearchFilterInput
        type="body"
        inputValueRef={bodyInput}
        onChange={onChange}
      />

      <SearchFilterInput
        type="snippet"
        inputValueRef={snippetInput}
        onChange={onChange}
      />

      <StatusFilterSelect statusInput={statusInput} onChange={onChange} />
    </>
  );
};

export default SearchFilterSidebar;
