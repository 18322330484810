import styled from 'styled-components';

export const StyledContainer = styled.div`
  .twitter-picker {
    border: unset !important;
    box-shadow: none !important;
    border-radius: unset !important;
    width: 100% !important;
    border-radius: 4px;

    ${(props) => `[title="${props.color}"]`} {
      background-image: url('/img/check-mark.png') !important;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      padding: 12px 12px 8px 12px !important;
      border: 1px solid #d9d9d9;
      > span {
        & > div {
          float: none !important;
          height: 12px !important;
          width: 12px !important;
          margin: 0px 8px 8px 0px !important;
        }
      }

      > div {
        display: none !important;
      }
    }
  }
`;

export const StyledColor = styled.span`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${(props: { value: string }) => props.value || '#efefef'};
  cursor: pointer;
`;

export const StyledColorSquare = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: ${(props) => props.color || '#efefef'};
  margin-right: 2px;
  vertical-align: middle;
`;

export default {};
