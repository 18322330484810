import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Select } from 'antd';
import { FormattedMessage, defineMessages } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { BankAccount, BankAccountsConnectionEdge } from 'types/graphqlTypes';
import { StyledLabel, StyledSelect } from '../../../../styles';

interface Props {
  onChange: (e: Record<string, any> | null) => void;
  value: Record<string, any>;
  disabled: boolean;
}

const messages = defineMessages({
  'enter-bank-account.text': {
    id: 'enter-bank-account.text',
    defaultMessage: 'Enter Bank Account',
  },
});

const BANK_ACCOUNTS = gql`
  query BankAccounts(
    $first: Int
    $after: Binary
    $filter: BankAccountsFilterInput
  ) {
    bankAccounts(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          accountNumber
        }
      }
    }
  }
`;

export const BankAccountFilter: React.FC<Props> = ({
  onChange,
  value,
  disabled,
}) => {
  const translate = useTranslate();
  const [input, setInput] = useState<string>('');
  const [options, setOptions] = useState([]);
  const debouncedInput = useDebounce(input, 500);

  const [loadBankAccounts, { loading }] = useLazyQuery(BANK_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    variables: {
      first: 10,
      filter: {
        accountNumber: {
          contains: debouncedInput,
        },
      },
    },
    onCompleted: (data) => {
      const {
        bankAccounts: { edges = [] },
      } = data;

      const nodes = edges.map(({ node }: BankAccountsConnectionEdge) => node);

      setOptions(nodes);
    },
  });

  const handleChange = (e: string[]) => {
    if (e.length) {
      onChange({
        includesAny: e,
      });
    } else {
      onChange(null);
    }

    setInput('');
  };

  const values = value?.bankAccounts?.includesAny ?? [];

  return (
    <div className="mt-1">
      <div>
        <StyledLabel>
          <FormattedMessage
            id="bank-account-number.text"
            defaultMessage="Bank Account Number"
          />
        </StyledLabel>
      </div>
      <StyledSelect
        data-testid="bank-account-filter"
        mode="multiple"
        value={values}
        showSearch
        allowClear
        loading={loading}
        placeholder={translate(messages['enter-bank-account.text'])}
        onChange={handleChange}
        filterOption={false}
        onSearch={(text: string) => {
          setInput(text);
        }}
        onFocus={() => setInput('')}
        onBlur={() => setInput('')}
        disabled={disabled}
        onDropdownVisibleChange={(open: boolean) => {
          if (open && !options.length) loadBankAccounts();
        }}
      >
        {options?.map((node: BankAccount) => (
          <Select.Option key={node.id} value={node.id!}>
            {node.accountNumber}
          </Select.Option>
        ))}
      </StyledSelect>
    </div>
  );
};
