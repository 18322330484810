import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import {
  START_PROCESS_WITHDRAWAL,
  STOP_PROCESS_WITHDRAWAL,
} from 'graphql/mutations/withdrawal.mutation';
import messages from 'pages/components/MemberWithdrawals/messages';
import React from 'react';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';

type Props = {
  id: string;
  status: string;
  refetchQueries: any;
  setRemarksError: () => void;
  toggleModal: () => void;
};

const ProcessWithdrawal = ({
  id,
  status,
  refetchQueries,
  setRemarksError,
  toggleModal,
}: Props) => {
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [startProcessWithdrawal, startProcessResult] = useMutation(
    START_PROCESS_WITHDRAWAL,
    {
      onCompleted: (data) => {
        if (data.startProcessWithdrawal) {
          message.success(translate(messages.processing));
        }
        setRemarksError();
      },
      variables: { id },
      refetchQueries,
      context,
    }
  );

  const [stopProcessWithdrawal, stopProcessResult] = useMutation(
    STOP_PROCESS_WITHDRAWAL,
    {
      onCompleted: (data) => {
        if (data.stopProcessWithdrawal) {
          message.success(translate(messages.withdrawalStopped));
        }
        setRemarksError();
        toggleModal();
      },
      variables: { id },
      refetchQueries,
    }
  );
  return (
    <>
      {status === 'PENDING' && (
        <Button
          disabled={startProcessResult.loading}
          loading={startProcessResult.loading}
          onClick={() => startProcessWithdrawal()}
        >
          {translate(
            messages[status === 'PENDING' ? 'startProcess' : 'reprocess']
          )}
        </Button>
      )}
      {status === 'PROCESSING' && (
        <Button
          disabled={stopProcessResult.loading}
          loading={stopProcessResult.loading}
          onClick={() => stopProcessWithdrawal()}
        >
          {translate(messages.stopProcess)}
        </Button>
      )}
    </>
  );
};

export default ProcessWithdrawal;
