import React from 'react';
import Layout from 'components/Layout';
import PromoNameSelectNext from 'components/PromoNameSelect';
import DateTimeRangePicker from 'components/DateTimeRangePicker';
import PromoTypeSelect from 'components/PromoTypeSelect';
import { useFilterValues } from 'contexts/Filters';
import { FilterItem } from 'components/FilterItem/FilterItem';

const Sidebar = () => {
  const { filters, setPersistedFilters } = useFilterValues();

  return (
    <Layout.Sidebar>
      <FilterItem
        label="Promo Type"
        onClear={() =>
          setPersistedFilters({
            ...filters,
            type: null,
          })
        }
      >
        <PromoTypeSelect
          isFilter
          value={filters?.type?.eq ?? null}
          onChange={(value: string) =>
            setPersistedFilters({
              ...filters,
              type: { eq: value },
            })
          }
        />
      </FilterItem>

      <FilterItem
        label="Promo Name"
        onClear={() =>
          setPersistedFilters({
            ...filters,
            name: null,
          })
        }
      >
        <PromoNameSelectNext value={filters} onChange={setPersistedFilters} />
      </FilterItem>

      <DateTimeRangePicker
        testId="promo-date-filter"
        label="Promo Start/End Date"
        value={
          filters.startDateTime && filters.endDateTime
            ? [filters.startDateTime.gte, filters.endDateTime.lte]
            : null
        }
        onChange={(e) =>
          setPersistedFilters({
            ...filters,
            ...(e?.length
              ? {
                  startDateTime: { gte: e[0] },
                  endDateTime: { lte: e[1] },
                }
              : {
                  startDateTime: null,
                  endDateTime: null,
                }),
          })
        }
      />
    </Layout.Sidebar>
  );
};

export default Sidebar;
