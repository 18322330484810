import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  message,
} from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'graphql/client/promoClient';
import { ENROLL_MEMBER_TO_PROMO } from 'graphql/mutations/member.mutation';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import { useMemberPromo } from 'hooks/useMemberPromo';
import { Member } from 'types/graphqlTypes';

const { Item } = Form;

const PROMOS = gql`
  query GetPromos($filter: PromoFilterInput) {
    promos(filter: $filter) {
      edges {
        node {
          ... on Promo {
            id
            name
          }
        }
      }
    }
  }
`;

type PromoData = {
  node: {
    name: string;
    id: string;
  };
};

type FormValues = {
  promo: string | null;
  bonusAmount: number | null;
  ringFenceAmount: number | null;
  memberId: string | null;
};

const EnrollPromo = () => {
  const [visible, setVisible] = useState(false);
  const [promoOptions, setPromoOptions] = useState<PromoData[]>([]);
  const [enableRingFence, setEnableRingFence] = useState(false);

  const { member } = useMember() as {
    member: Member;
  };

  const { loading: promosLoading } = useQuery(PROMOS, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        currency: {
          equal: member.currency,
        },
      },
    },
    skip: !visible,
    onCompleted: (data) => {
      setPromoOptions(data.promos.edges);
    },
  });

  const { refetch, refetchWalletBalances } = useMemberPromo();

  const defaultValues = {
    promo: null,
    bonusAmount: null,
    ringFenceAmount: null,
    memberId: null,
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
    reset,
  } = useForm<FormValues>({
    defaultValues,
    mode: 'all',
  });

  const handleModalVisibility = () => setVisible((prev) => !prev);

  const [enrollToPromo, { loading }] = useMutation(ENROLL_MEMBER_TO_PROMO);

  const onEnrollPromo = async (values: FormValues) => {
    await enrollToPromo({
      variables: {
        input: {
          legacyMemberId: member.id,
          promo: values.promo,
          bonusAmount: +values.bonusAmount!,
          ringFenceAmount: +values.ringFenceAmount! ?? 0,
        },
      },
    })
      .then(() => {
        message.success('Member successfully enrolled in the promo.');
        refetch();
        refetchWalletBalances();
      })
      .catch((err) => {
        if (err) message.error(`Unable to enroll to promo, ${err.message}`);
      })
      .finally(() => handleModalVisibility());
  };

  const Footer = () => (
    <>
      <Button
        loading={loading}
        onClick={() => {
          reset();
          handleModalVisibility();
        }}
      >
        Cancel
      </Button>
      <Popconfirm
        title="Are you sure you want to enroll the member in the promotion?"
        onConfirm={handleSubmit(onEnrollPromo)}
      >
        <Button loading={loading} type="primary">
          Save Changes
        </Button>
      </Popconfirm>
    </>
  );

  return (
    <>
      <Modal
        title="Add Promo"
        width={400}
        visible={visible}
        centered
        destroyOnClose
        closable
        onCancel={handleModalVisibility}
        footer={<Footer />}
      >
        <Form layout="vertical">
          <Item
            label="Promo Name"
            validateStatus={errors.promo && 'error'}
            help={errors.promo && errors.promo.message}
          >
            <Controller
              control={control}
              name="promo"
              rules={{
                required: {
                  value: true,
                  message: 'Promo is required.',
                },
              }}
              render={({ value, onChange }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  placeholder="Select a Promo"
                  loading={promosLoading}
                >
                  {promoOptions?.map((option) => (
                    <Select.Option key={option.node.id} value={option.node.id}>
                      {option.node.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </Item>
          <Item
            label="Amount"
            validateStatus={errors.bonusAmount && 'error'}
            help={errors.bonusAmount && errors.bonusAmount.message}
          >
            <Controller
              control={control}
              name="bonusAmount"
              rules={{
                required: {
                  value: true,
                  message: 'Amount is required.',
                },
                min: {
                  value: 1,
                  message: 'Amount must be greater than 0',
                },
              }}
              render={({ value, onChange }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder="Enter an amount"
                  type="number"
                  min={0}
                />
              )}
            />
          </Item>
          <>
            <Checkbox
              className="mt-3 mb-1"
              value={enableRingFence}
              onChange={() => {
                setEnableRingFence((prev) => !prev);

                setValue('ringFenceAmount', null);
                clearErrors('ringFenceAmount');
              }}
            >
              Ring-fence Cash
            </Checkbox>
            <Item
              label="Amount to ring-fence"
              validateStatus={errors.ringFenceAmount && 'error'}
              help={errors.ringFenceAmount && errors.ringFenceAmount.message}
            >
              <Controller
                control={control}
                name="ringFenceAmount"
                rules={{
                  required: {
                    value: enableRingFence,
                    message: 'Ring-fence cash amount is required.',
                  },
                }}
                render={({ value, onChange }) => (
                  <Input
                    value={value}
                    onChange={onChange}
                    placeholder="Enter an amount"
                    type="number"
                    min={0}
                    disabled={!enableRingFence}
                  />
                )}
              />
            </Item>
          </>
        </Form>
      </Modal>

      <Button
        type="primary"
        style={{
          padding: '0 17px 0 17px',
        }}
        onClick={handleModalVisibility}
      >
        Add Promo
      </Button>
    </>
  );
};

export default EnrollPromo;
