import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import messages from 'pages/components/PermissionGroup/messages';
import globalMessages from 'messages';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import { DELETE_PERMISSION_GROUP } from 'graphql/mutations/permissionGroup.mutation';

type Props = {
  toggleModal: () => void;
  id: string;
  refetch: () => void;
};

const DeletePermissionGroup = (props: Props) => {
  const { toggleModal, id, refetch } = props;
  const translate = useTranslate();
  const { context } = useOperatorHeader();
  const [deletePermissionGroup, { loading }] = useMutation(
    DELETE_PERMISSION_GROUP,
    {
      context,
      onCompleted: () => {
        refetch();
        message.success(translate(messages.PERMISSION_GROUP_DELETE_SUCCESS));
        toggleModal();
      },
      onError: () =>
        message.error(translate(messages.PERMISSION_GROUP_DELETE_ERROR)),
    }
  );

  return (
    <Modal
      visible
      maskClosable={false}
      closable={false}
      width={416}
      okText={<FormattedMessage id="confirm.text" defaultMessage="Confirm" />}
      cancelText={<FormattedMessage id="cancel.text" defaultMessage="Cancel" />}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ loading }}
      onCancel={toggleModal}
      onOk={() => {
        deletePermissionGroup({
          variables: {
            id,
          },
        });
      }}
    >
      <div className="ant-modal-confirm-body">
        <QuestionCircleOutlined style={{ color: '#faad14' }} />
        <span className="ant-modal-confirm-title">
          <FormattedMessage id="confirm.text" defaultMessage="Confirm" />{' '}
          <FormattedMessage id="delete.text" defaultMessage="Delete" />
        </span>
        <div className="ant-modal-confirm-content">
          {translate(globalMessages.ARE_YOU_SURE)}
        </div>
      </div>
    </Modal>
  );
};

export default DeletePermissionGroup;
