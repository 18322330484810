import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Empty, message, Modal, Select, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import gql from 'graphql-tag';
import { capitalize, startCase } from 'lodash';
import { Document } from 'pages/components/NewMemberDetail/components/DocumentManagement/Document';
import { MEMBER_DOCUMENTS } from 'pages/components/NewMemberDetail/components/DocumentManagement/utils';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PermissionError from 'components/PermissionError';
import styled from 'styled-components';
import {
  Member,
  MemberDocumentsConnection,
  MemberDocumentType,
  MutationCreateMemberDocumentArgs,
  QueryMemberDocumentsArgs,
} from 'types/graphqlTypes';
import { useOperatorHeader } from 'utils/useOperatorHeader';

const ContainerHeader = styled.div`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: #f0f0f0;
  height: 36px;
  padding-left: 16px;
  display: flex;
  align-items: center;
`;

const ContainerBody = styled.div`
  background: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

const ContainerInner = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CREATE_MEMBER_DOCUMENT = gql`
  mutation CreateMemberDocument($id: ID!, $input: MemberDocumentInput!) {
    createMemberDocument(id: $id, input: $input)
  }
`;

const documentTypes = [
  MemberDocumentType.IdDocumentBack,
  MemberDocumentType.IdDocumentFront,
  MemberDocumentType.ProofOfAddressDocument,
  MemberDocumentType.BankStatement,
  MemberDocumentType.BirthCertificate,
  MemberDocumentType.CreditDebitCardBack,
  MemberDocumentType.CreditDebitCardFront,
  MemberDocumentType.DrivingLicenseBack,
  MemberDocumentType.DrivingLicenseFront,
  MemberDocumentType.NotarizedId,
  MemberDocumentType.Passport,
  MemberDocumentType.SelfCertified,
  MemberDocumentType.Other,
];

const DocumentManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [file, setFile] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<MemberDocumentType>(
    MemberDocumentType.Passport
  );

  const { member } = useMember() as { member: Member };
  const { context } = useOperatorHeader();

  const {
    MEMBER_DOCUMENT_MANAGEMENT_PERMISSION,
  } = useNewMemberDetailPermissions();
  const [createMemberDocument] = useMutation<
    boolean,
    MutationCreateMemberDocumentArgs
  >(CREATE_MEMBER_DOCUMENT);
  const { data, refetch } = useQuery<
    { memberDocuments: MemberDocumentsConnection },
    QueryMemberDocumentsArgs
  >(MEMBER_DOCUMENTS, {
    variables: {
      first: 10,
      filter: {
        member: {
          eq: member?.id,
        },
      },
    },
    fetchPolicy: 'network-only',
  });

  const memberDocumentsEdges = data?.memberDocuments?.edges || [];
  const memberDocuments = memberDocumentsEdges.map(
    (memberDocument) => memberDocument!.node
  );

  const onAttachFile = async ({
    onSuccess,
    file: locFile,
  }: Record<string, any>) => {
    setFile([locFile]);
    onSuccess();
  };

  const onRemoveFile = () => {
    setFile([]);
  };

  const onSubmitDocument = () => {
    if (file.length === 0) {
      message.error('Please upload your document');
      return;
    }

    setLoading(true);
    createMemberDocument({
      variables: {
        id: member.id,
        input: {
          document: file[0],
          type,
        },
      },
      context,
    })
      .then(() => {
        message.success('Document uploaded');
      })
      .finally(() => {
        refetch();
        setIsModalVisible(false);
        setLoading(false);
        setFile([]);
        setType(MemberDocumentType.Passport);
      });
  };

  return (
    <div className="mt-3">
      <ContainerHeader className="justify-content-space-between">
        <span className="fw-500">
          <FormattedMessage
            id="DOCUMENT_MANAGEMENT"
            defaultMessage="Document Management"
          />
        </span>
        {MEMBER_DOCUMENT_MANAGEMENT_PERMISSION.ALLOWED_UPLOAD && (
          <Button
            onClick={() => setIsModalVisible(true)}
            size="small"
            className="mr-3"
          >
            Upload
          </Button>
        )}
      </ContainerHeader>
      <ContainerBody>
        <ContainerInner>
          {memberDocuments.length === 0 &&
            MEMBER_DOCUMENT_MANAGEMENT_PERMISSION.ALLOWED_LIST && <Empty />}
          <PermissionError
            message="Document Management"
            withPermission={MEMBER_DOCUMENT_MANAGEMENT_PERMISSION.ALLOWED_LIST}
          >
            <>
              {memberDocuments.map((memberDocument) => (
                <Document
                  refetch={refetch}
                  memberDocument={memberDocument}
                  key={memberDocument?.id}
                />
              ))}
            </>
          </PermissionError>
        </ContainerInner>
      </ContainerBody>

      <Modal
        title="Upload Document"
        visible={isModalVisible}
        onOk={onSubmitDocument}
        confirmLoading={loading}
        okText="Submit"
        onCancel={() => setIsModalVisible(false)}
      >
        <Upload
          fileList={file as any}
          accept=".png, .jpg, .jpeg, .pdf"
          customRequest={onAttachFile}
          onRemove={onRemoveFile}
        >
          <Button icon={<UploadOutlined />}>Upload document</Button>
        </Upload>

        <p className="mt-3 mb-0">Document Type</p>
        <Select
          value={type}
          style={{ width: '200px' }}
          onChange={(value: MemberDocumentType) => setType(value)}
        >
          {documentTypes.map((docType) => (
            <Select.Option value={docType} key={docType}>
              {capitalize(startCase(docType))}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </div>
  );
};

export default DocumentManagement;
