import gql from 'graphql-tag';

export const CREATE_DEPOSIT_SOURCE = gql`
  mutation CreateDepositSource($input: CreateDepositSourceInput!) {
    createDepositSource(input: $input)
  }
`;

export const UPDATE_DEPOSIT_SOURCE = gql`
  mutation UpdateDepositSource($id: ID!, $input: UpdateDepositSourceInput!) {
    updateDepositSource(id: $id, input: $input)
  }
`;

export const DELETE_DEPOSIT_SOURCE = gql`
  mutation DeleteDepositSource($id: ID!) {
    deleteDepositSource(id: $id)
  }
`;
