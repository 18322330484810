import { AdminIdProvider } from 'contexts/AdminId';
import React from 'react';
import FilterConditions from './components/FilterConditions';
import VendorManagementTable from './components/Vendors/components/VendorManagementTable';
import { StyledContent, StyledLayout, StyledTagContainer } from './styles';

const GameVendorManagement = () => (
  <StyledLayout>
    <AdminIdProvider>
      <>
        <StyledContent className="p-4">
          <div className="d-flex mb-6">
            <StyledTagContainer>
              <FilterConditions />
            </StyledTagContainer>
          </div>
          <div>
            <VendorManagementTable />
          </div>
        </StyledContent>
      </>
    </AdminIdProvider>
  </StyledLayout>
);

export default GameVendorManagement;
