import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Row } from 'antd';
import { useFormik } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import FilterItems from '../FilterItems';

export const StyledForm = styled(Form)`
  .ant-legacy-form-item {
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    label {
      color: rgba(0, 0, 0, 0.35);
    }
  }
`;

interface ISearchSettingsForm {
  onClose: Function;
  initialValues: any;
  onSubmit: (fields: any) => void;
  isLoading: boolean;
}

const SearchSettingsForm = ({
  onClose,
  initialValues,
  onSubmit,
  isLoading,
}: ISearchSettingsForm) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  const { handleSubmit, setFieldValue, values } = formik;

  const onFilterChange = (e: Record<string, string[]>) =>
    Object.entries(e).map(([key, value]) => setFieldValue(key, value));

  return (
    <StyledForm>
      <Row className="mb-3">
        <Col span={12}>
          <FilterItems
            onFilterChange={onFilterChange}
            filters={values}
            isCustomFilter
            stateFilters={values}
            onRawFilterChange={() => {}}
          />
        </Col>
      </Row>
      <div className="bt-1 py-4 text-right">
        <Button className="mr-2" onClick={onClose as any} loading={isLoading}>
          <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
        </Button>{' '}
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={handleSubmit as any}
        >
          <FormattedMessage
            id="save-changes.text"
            defaultMessage="Save changes"
          />
        </Button>
      </div>
    </StyledForm>
  );
};

export default SearchSettingsForm;
