import React, { useReducer } from 'react';

const BalancesContext = React.createContext<any>({});

interface IReducerState {
  vendors: any[];
  balances: object;
  onSearchWalletStatus: boolean;
  searchedVendorsSelected: string[];
  tabState: '1' | '2' | '3';
}

const reducerState: IReducerState = {
  vendors: [],
  balances: {},
  onSearchWalletStatus: false,
  searchedVendorsSelected: [],
  tabState: '1',
};

export enum actions {
  SET_VENDORS,
  SET_BALANCES,
  SET_ON_SEARCH_WALLET_STATUS,
  SET_SEARCH_VENDORS_SELECTED,
  SET_TAB_STATE,
}

const reducer = (state: IReducerState, [type, payload]: any) => {
  switch (type) {
    case actions.SET_VENDORS:
      return {
        ...state,
        vendors: payload,
      };

    case actions.SET_BALANCES:
      return {
        ...state,
        balances: {
          ...state.balances,
          ...payload,
        },
      };

    case actions.SET_ON_SEARCH_WALLET_STATUS:
      return {
        ...state,
        onSearchWalletStatus: payload,
      };

    case actions.SET_SEARCH_VENDORS_SELECTED:
      return {
        ...state,
        searchedVendorsSelected: payload,
      };

    case actions.SET_TAB_STATE:
      return {
        ...state,
        tabState: payload,
      };
    default:
      return state;
  }
};

export const BalancesProvider = ({ children }: { children: any }) => {
  const [
    {
      vendors,
      balances,
      onSearchWalletStatus,
      searchedVendorsSelected,
      tabState,
    },
    dispatch,
  ] = useReducer(reducer, reducerState);

  return (
    <BalancesContext.Provider
      value={{
        vendors,
        balances,
        onSearchWalletStatus,
        searchedVendorsSelected,
        tabState,
        dispatch,
      }}
    >
      {children}
    </BalancesContext.Provider>
  );
};

export const useBalancesContext = () => React.useContext(BalancesContext);
