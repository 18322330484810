import React, { useState, useEffect } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Radio, Select, message } from 'antd';
import { useFormik } from 'formik';
import { useLazyQuery } from '@apollo/react-hooks';
import thirdPartyProviders from 'constants/thirdPartyProviders'; // thirdPartyTypeNames,
import messages from 'pages/components/WithdrawalProviders/messages';
import styled from 'styled-components';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import { withdrawalProvidersTestId } from 'components/data-testid/WithdrawalProviders';
import globalMessages from 'messages';
import { validationSchema } from './validationSchema';
import { PAYMENT_GATEWAYS } from './queries';

const StyledButton = styled(Button)`
  text-transform: uppercase;
`;
const { Option } = Select;

const { DIORPAY, MIDPAY, MIDPAY_DIRECT } = thirdPartyProviders;
const formitemlayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

const ProviderForm = (props: Record<string, any>) => {
  const {
    submitWithdrawalProvider,
    onClose,
    withdrawalProvider,
    editable,
    loading,
  } = props;
  const translate = useTranslate();

  const initCredentialFields = coercedGet(
    withdrawalProvider,
    'midPayDirectPaymentGateway.credentialFields',
    []
  );

  const [credentialFields, setCredentialFields] = useState(
    initCredentialFields
  );

  const getInitialValues = (withrdawalProviderInfo: any) => {
    type intialValueType = {
      type: string;
      midpayDirectApiKey: any;
      paymentGateway: any;
      credentials: any;
      key: any;
      merchantCode: any;
      name: any;
      apiKey: any;
      midPayDirectPaymentGateway: any;
      credentialValues: any;
    };
    let initialValues = {
      name: withrdawalProviderInfo?.name,
    } as intialValueType;

    if (withrdawalProviderInfo?.type === DIORPAY) {
      initialValues = {
        ...initialValues,
        type: DIORPAY,
        key: withrdawalProviderInfo[editable ? 'key' : 'keyPreview'],
        merchantCode: withrdawalProviderInfo?.merchantCode,
      };
    } else if (withrdawalProviderInfo?.type === MIDPAY) {
      initialValues = {
        ...initialValues,
        type: MIDPAY,
        key: withrdawalProviderInfo[editable ? 'apiKey' : 'apiKeyPreview'],
      };
    } else if (withrdawalProviderInfo?.type === MIDPAY_DIRECT) {
      initialValues = {
        ...initialValues,
        type: MIDPAY_DIRECT,
        midpayDirectApiKey:
          withrdawalProviderInfo[editable ? 'apiKey' : 'apiKeyPreview'],
        paymentGateway:
          withrdawalProviderInfo?.midPayDirectPaymentGateway?.[
            editable ? 'id' : 'name'
          ],
        credentials: withrdawalProviderInfo?.credentialValues,
      };
    }

    return initialValues;
  };

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    setValues,
    isValid,
  } = useFormik({
    initialValues: getInitialValues(withdrawalProvider),
    validationSchema: validationSchema(translate),
    onSubmit: async (submitValues, { resetForm }) => {
      const variables = {
        id: submitValues.apiKey,
        input: {
          name: submitValues.name,
          type: submitValues.type,
          key: submitValues.key,
          merchantCode: submitValues.merchantCode,
          apiKey: submitValues.apiKey,
          midpayDirectApiKey: submitValues.midpayDirectApiKey,
          midPayDirectPaymentGateway: submitValues.midPayDirectPaymentGateway,
          credentialValues: submitValues.credentialValues,
        },
      };

      if (values.type === DIORPAY) {
        variables.input = {
          ...variables.input,
          key: values.key,
          merchantCode: values.merchantCode,
        };
      } else if (values.type === MIDPAY) {
        variables.input = {
          ...variables.input,
          apiKey: values.key,
        };
      } else if (values.type === MIDPAY_DIRECT) {
        variables.input = {
          ...variables.input,
          apiKey: values.midpayDirectApiKey,
          midPayDirectPaymentGateway: values.paymentGateway,
          credentialValues: values.credentials,
        };
      }

      if (withdrawalProvider?.id) {
        variables.input.type = '';
        variables.id = withdrawalProvider?.id;
      }

      const response = await submitWithdrawalProvider({
        variables,
      });

      if (response && response.data) {
        // Resets the dynamic inputs after creating/editing a provider
        setCredentialFields([]);
        resetForm();
      }
    },
    onReset: () => onClose(),
  });

  const [
    getGateways,
    { loading: loadingGateways, data: dataGateways },
  ] = useLazyQuery(PAYMENT_GATEWAYS, {
    fetchPolicy: 'network-only',
    onError: (error) => {
      const errorCode = coercedGet(error, 'graphQLErrors[0].original.code', '');
      if (errorCode === 'INVALID_PAYMENT_GATEWAY_CREDENTIALS') {
        message.error(translate(messages.INVALID_API_CREDENTIALS));
      } else {
        message.error(translate(globalMessages.INTERNAL_SERVER_ERROR));
      }
    },
  });

  const gatewayOptions = coercedGet(dataGateways, 'paymentGateways', []);

  const onGetGateways = () => {
    getGateways({
      variables: {
        input: {
          apiKey: values.midpayDirectApiKey,
          integrationType: 'MIDPAY_DIRECT',
          methodType: 'WITHDRAW',
        },
      },
    });
  };

  useEffect(() => {
    if (editable && withdrawalProvider?.id) {
      onGetGateways();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable, withdrawalProvider]);

  const onSetGateway = (value: string) => {
    const gateWayInfo = gatewayOptions.find(
      (gatewayOption: Record<string, any>) => gatewayOption.id === value
    );
    const credValues = new Array(gateWayInfo.credentialFields.length).fill('');

    setValues({
      ...values,
      paymentGateway: value,
      credentials: credValues,
    });
    setCredentialFields(gateWayInfo.credentialFields);
  };

  const onSetType = ({ target }: Record<string, any>) => {
    setValues({
      name: values.name,
      type: target.value,
    } as any);
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        layout="horizontal"
        labelAlign="right"
        data-testid={withdrawalProvidersTestId.modal}
      >
        <div className="p-6">
          <Form.Item className={editable ? 'ml-3' : 'mb-1'}>
            <div className="ant-col ant-col-7 ant-form-item-label">
              <b className="mr-5">
                {translate(messages['withdrawal-provider-details.text'])}
              </b>
            </div>
          </Form.Item>
          <Form.Item
            htmlFor="name"
            label={translate(messages['provider-name.text'])}
            {...formitemlayout}
            validateStatus={errors.name ? 'error' : undefined}
            help={errors.name || null}
            className={editable ? 'mt-4' : 'mb-0'}
            data-testid={withdrawalProvidersTestId.providerNameFormItem}
          >
            {editable ? (
              <Input
                id="name"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                placeholder={translate(messages['provider-name.text'])}
                data-testid={withdrawalProvidersTestId.providerNameInput}
              />
            ) : (
              values.name
            )}
          </Form.Item>

          <Form.Item
            data-testid={withdrawalProvidersTestId.providerTypeFormItem}
            label={translate(messages['type.text'])}
            {...formitemlayout}
            validateStatus={errors.type ? 'error' : undefined}
            help={errors.type || null}
            className={editable ? 'mt-4' : 'mb-0'}
          >
            {editable ? (
              <Radio.Group
                disabled={Boolean(withdrawalProvider?.id)}
                name="type"
                value={values.type}
                onChange={onSetType}
              >
                {/* <Radio
                  // disabled
                  data-testid={withdrawalProvidersTestId.midpayRadioBtn}
                  value={MIDPAY}
                >
                  {translate(globalMessages.MIDPAY)}
                </Radio>
                <Radio
                  // disabled
                  data-testid={withdrawalProvidersTestId.diorpayRadioBtn}
                  value={DIORPAY}
                >
                  {translate(globalMessages.DIORPAY)}
                </Radio> */}
                <Radio
                  value={MIDPAY_DIRECT}
                  data-testid="midpay-direct-radioBtn"
                >
                  {' '}
                  {translate(globalMessages.MIDPAY_DIRECT)}
                </Radio>
              </Radio.Group>
            ) : (
              values.type
            )}
          </Form.Item>

          {values.type === DIORPAY || values.type === MIDPAY ? (
            <Form.Item
              htmlFor="key"
              data-testid={withdrawalProvidersTestId.credentialsFormItem}
              label={`${
                editable ? translate(messages['primary-credential.text']) : ''
              }
                  ${editable ? '(' : ''}${translate(
                messages[values.type === MIDPAY ? 'api-key.text' : 'key.text']
              )}${editable ? ')' : ''}`}
              {...formitemlayout}
              validateStatus={errors.key ? 'error' : undefined}
              help={errors.key || null}
              className={editable ? 'mt-4' : 'mb-0'}
            >
              {editable ? (
                <Input
                  id="key"
                  name="key"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.key}
                  placeholder={translate(
                    messages[
                      values.type === MIDPAY ? 'api-key.text' : 'key.text'
                    ]
                  )}
                  data-testid={withdrawalProvidersTestId.credentialsInput}
                />
              ) : (
                <span data-testid="u-primarycred">{values.key}</span>
              )}
            </Form.Item>
          ) : null}

          {values.type === DIORPAY ? (
            <Form.Item
              {...formitemlayout}
              label={translate(messages['merchant-code.text'])}
              htmlFor="merchantCode"
              validateStatus={errors.merchantCode ? 'error' : undefined}
              help={errors.merchantCode}
              className={editable ? 'mt-4' : 'mb-0'}
              data-testid={withdrawalProvidersTestId.merchantCodeFormItem}
            >
              {editable ? (
                <Input
                  name="merchantCode"
                  id="merchantCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.merchantCode}
                  placeholder={translate(messages['merchant-code.text'])}
                  data-testid={withdrawalProvidersTestId.merchantCodeInput}
                />
              ) : (
                <span data-testid="u-merchantcode">{values.merchantCode}</span>
              )}
            </Form.Item>
          ) : null}

          {values.type === MIDPAY_DIRECT ? (
            <>
              <Form.Item
                htmlFor="midpayDirectApiKey"
                {...formitemlayout}
                label={translate(messages.MIDPAY_DIRECT_CREDS)}
                validateStatus={errors.midpayDirectApiKey ? 'error' : undefined}
                help={errors.midpayDirectApiKey || null}
                className={editable ? 'mt-1' : 'mb-0'}
              >
                {editable ? (
                  <div className="d-flex">
                    <Input
                      className="col-9 mr-1"
                      name="midpayDirectApiKey"
                      id="midpayDirectApiKey"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.midpayDirectApiKey}
                      placeholder={translate(messages['api-key.text'])}
                      disabled={loadingGateways}
                    />
                    <Button
                      className="col-3"
                      type="primary"
                      loading={loadingGateways}
                      onClick={onGetGateways}
                      disabled={!values.midpayDirectApiKey}
                      data-testid={
                        withdrawalProvidersTestId.apiKeyConfirmButton
                      }
                    >
                      {translate(globalMessages.CONFIRM)}
                    </Button>
                  </div>
                ) : (
                  <span>{values.midpayDirectApiKey}</span>
                )}
              </Form.Item>
              {values.midpayDirectApiKey && gatewayOptions.length ? (
                <>
                  <Form.Item
                    {...formitemlayout}
                    label={translate(messages.THIRD_PARTY_GATEWAY)}
                    validateStatus={errors.paymentGateway ? 'error' : undefined}
                    help={errors.paymentGateway || null}
                    className={editable ? 'mt-1' : 'mb-0'}
                  >
                    {editable ? (
                      <Select
                        data-testid="payment-gateway-select"
                        onChange={onSetGateway}
                        value={values.paymentGateway}
                      >
                        {gatewayOptions.map(
                          (
                            gatewayOption: Record<string, any>,
                            index: number
                          ) => (
                            <Option
                              key={gatewayOption.id}
                              value={gatewayOption.id}
                              data-testid={`${withdrawalProvidersTestId.gatewayOption}${index}`}
                            >
                              {translate(globalMessages[gatewayOption.id])}
                            </Option>
                          )
                        )}
                      </Select>
                    ) : (
                      values.paymentGateway
                    )}
                  </Form.Item>

                  {credentialFields.length
                    ? credentialFields.map((field: any, index: number) => (
                        <Form.Item
                          {...formitemlayout}
                          key={`${values.paymentGateway}-${index}`}
                          label={translate(messages[field])}
                          validateStatus={
                            coercedGet(errors, `credentials.[${index}]`, '')
                              ? 'error'
                              : undefined
                          }
                          help={coercedGet(
                            errors,
                            `credentials.[${index}]`,
                            null
                          )}
                          className={editable ? 'mt-1' : 'mb-0'}
                        >
                          {editable ? (
                            <Input
                              data-testid={`${withdrawalProvidersTestId.credentialInput}${index}`}
                              name={`credentials.${index}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.credentials[index]}
                            />
                          ) : (
                            values.credentials[index]
                          )}
                        </Form.Item>
                      ))
                    : null}
                </>
              ) : null}
            </>
          ) : null}
        </div>

        <div className="bt-1 p-4 text-right">
          {editable ? (
            <>
              <Button className="mr-2" onClick={onClose} disabled={loading}>
                {translate(messages['cancel.text'])}
              </Button>
              <Button
                disabled={!isValid}
                type="primary"
                htmlType="submit"
                loading={loading}
                data-testid="submit-button"
              >
                {translate(
                  messages[!withdrawalProvider?.id ? 'add.text' : 'save.text']
                )}
              </Button>
            </>
          ) : (
            <StyledButton type="primary" onClick={onClose}>
              {translate(messages['ok.text'])}
            </StyledButton>
          )}
        </div>
      </Form>
    </>
  );
};

export default ProviderForm;
