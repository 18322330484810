import React from 'react';
import { Spin } from 'antd';
import { StyledCenter } from 'styles';

function PageLoader() {
  return (
    <StyledCenter>
      <Spin />
    </StyledCenter>
  );
}

export default PageLoader;
