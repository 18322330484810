import React, { cloneElement, ReactElement } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Spin } from 'antd';
import get from 'utils/coercedGet';
import { isObjectPropsEmpty as isEmpty } from 'utils/isObjectPropsEmpty/isObjectPropsEmpty';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';
import { DocumentNode } from 'graphql';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  gqlTag: DocumentNode;
  render: string;
  component?: ReactElement;
  id?: string;
  restProps?: any;
  dynamicProps?: any;
  defaultValue?: number | string | ReactElement;
  withEdges?: boolean;
  customFilter?: any;
  customVariables?: any;
  manipulate?: Function;
};

const BatchSplit = ({
  component,
  gqlTag,
  id,
  render,
  restProps = {},
  dynamicProps = {},
  defaultValue = '-',
  withEdges = true,
  customFilter = {},
  customVariables = {},
  manipulate,
}: Props) => {
  const tempDynamicProps: any = {};
  let filter = {};

  if (id) {
    filter = {
      filter: { id: { eq: id } },
    };
  }
  const { data, loading, error } = useQuery(gqlTag, {
    context: { shouldBatch: true },
    fetchPolicy: 'cache-and-network',
    variables: {
      ...filter,
      ...customFilter,
      ...customVariables,
    },
  });

  const loadingIcon = (
    <LoadingOutlined
      style={{
        fontSize: 16,
      }}
      spin
    />
  );

  if (loading) {
    return <Spin indicator={loadingIcon} spinning={loading} />;
  }

  if (error as any) {
    return (
      <TableErrorCell errorMessage={error?.message || 'API did not resolve'} />
    );
  }

  let temp = get(
    data,
    withEdges
      ? `${data ? Object.keys(data)[0] : ''}.edges`
      : `${data ? Object.keys(data) : ''}`,
    withEdges ? [] : {}
  );

  if (withEdges) {
    temp = temp.map(({ node }: any) => node);
  }

  if (!isEmpty(dynamicProps)) {
    Object.keys(dynamicProps).map((q) => {
      if (withEdges) {
        tempDynamicProps[q] =
          temp.length === 1 ? get(temp[0], dynamicProps[q], '') : '-';
        return null;
      }
      tempDynamicProps[q] = !isEmpty(temp)
        ? get(temp, dynamicProps[q], '-')
        : '-';
      return null;
    });
  }

  const children = () => {
    if (withEdges) {
      return temp.length === 1
        ? get(temp[0], render, defaultValue)
        : defaultValue;
    }

    return !isEmpty(temp) ? get(temp, render, defaultValue) : defaultValue;
  };

  return cloneElement(
    component || <></>,
    { ...restProps, ...tempDynamicProps },
    manipulate ? manipulate(children()) : children()
  );
};

export default BatchSplit;
