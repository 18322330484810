import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { get } from 'lodash';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import Icon from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { ViewMessage } from 'pages/components/SystemMessage/ManualMessage/components/ViewMessage/ViewMessage';
import { formatDate } from 'utils/dateUtils';
import { columnAlign } from 'utils/tableAlignment';
import { SharedStyledTable } from 'styles/SharedStyledTable';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import { usePermissions } from 'store/accountState';
import { DATE_FORMAT } from 'constants/date';
import { ReactComponent as ScheduleSendIcon } from '../../assets/schedule-send.svg';
import { MessageRowRenderer } from './components/MessageRowRenderer/MessageRowRenderer';
import { IMessagesData, ISystemMessageNode } from '../../interfaces';

interface ISystemMessageTable {
  data: IMessagesData;
  loading: boolean;
  getMessages: any;
}

const customExpandIcon = (_props: any) => {
  const { onExpand, record, expanded } = _props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={onExpand} className="cursor-pointer">
      <Button type="link" className="pr-0">
        {record.totalRecipients}
      </Button>
      <LegacyIcon type={expanded ? 'up' : 'down'} />
    </div>
  );
};

export const SystemMessageTable = ({
  data,
  loading,
  getMessages,
}: ISystemMessageTable) => {
  const { role, permissions } = usePermissions();
  const [selectedMessage, setSelectedMessage] = useState<any>(null);
  const { ALLOWED_VIEW_DETAILS } = collectPermissions(
    role,
    permissions,
    ['VIEW_DETAILS'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MESSAGES.SYSTEM_MESSAGES}:MANUAL_MESSAGE`
  );

  const systemMessageNode: ISystemMessageNode[] = get(
    data,
    'messages.edges',
    []
  ).map((edge: any) => edge.node);
  const columns = [
    {
      title: <FormattedMessage id="title.text" defaultMessage="Title" />,
      dataIndex: 'title',
      align: columnAlign.onCenter,
      key: 'title',
      render: (title: string, allData: any) => (
        <Button
          type="link"
          onClick={() => {
            if (ALLOWED_VIEW_DETAILS) {
              setSelectedMessage(allData);
            }
          }}
        >
          {!allData.submitted && (
            <Icon component={ScheduleSendIcon} className="mr-1" />
          )}
          {title}
        </Button>
      ),
    },
    {
      title: (
        <FormattedMessage id="created-by.text" defaultMessage="Created by" />
      ),
      dataIndex: 'title',
      key: 'title',
      align: columnAlign.onCenter,
      render: (_e: any, allData: any) => allData.creator.username,
    },
    {
      title: (
        <FormattedMessage
          id="sending-date.text"
          defaultMessage="Sending Date"
        />
      ),
      dataIndex: 'schedule',
      key: 'schedule',
      align: columnAlign.onCenter,
      render: (schedule: string, allData: any) => {
        const { dateTimeSubmitted, submitted } = allData;
        const date = dateTimeSubmitted || schedule;
        const formattedSchedule = formatDate(
          date,
          `${DATE_FORMAT} (dd) HH:mm:ss`
        );
        const timeAgo = submitted && <>- {moment(date).fromNow()}</>;

        return (
          <span>
            {formattedSchedule} {timeAgo}
          </span>
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="read-messages.text"
          defaultMessage="Read Messages"
        />
      ),
      dataIndex: 'totalReads',
      key: 'totalReads',
      align: columnAlign.onCenter,
      render: (readMessages: string) => (
        <>
          <Button type="link" className="pr-0">
            {readMessages}
          </Button>{' '}
        </>
      ),
    },
    {
      title: (
        <FormattedMessage
          id="total-members.text"
          defaultMessage="Total Members"
        />
      ),
      dataIndex: 'totalRecipients',
      key: 'totalRecipients',
      align: columnAlign.onCenter,
      render: () => <></>,
    },
  ];

  return (
    <>
      <Spin spinning={loading}>
        <SharedStyledTable
          expandedRowRender={MessageRowRenderer}
          expandIconColumnIndex={4}
          expandIcon={customExpandIcon}
          pagination={false}
          size="middle"
          columns={columns}
          dataSource={systemMessageNode}
        />
      </Spin>

      <ViewMessage
        getMessages={getMessages}
        message={selectedMessage}
        onCancelFn={() => setSelectedMessage(null)}
      />
    </>
  );
};
