import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import SelectFilterField from 'components/SelectFilterField';
import {
  LAST_LOGIN_IP,
  PHONE_NUMBERS,
  REGISTRATION_IP,
} from 'graphql/queries/member.query';
import { blockAlphabets } from 'utils/blockAlphabets';
import { BankAccountFilter } from './components/BankAccountFilter/BankAccountFilter';
import { BirthDateFilter } from './components/BirthDateFilter/BirthDateFilter';
import ProfileValidationFilter from './components/ProfileValidationFilter';

interface Props {
  filters: any;
  onFilterChange?: (e: any) => any;
  handleChange: (e: any) => any;
  isLoading: boolean;
}

export const AdvancedFilter: React.FC<Props> = ({
  filters,
  // onFilterChange,
  handleChange,
  isLoading,
}) => {
  const [isShowAdvancedFilter, setShowAdvancedFilter] = useState<boolean>(
    false
  );

  return (
    <>
      <div
        className="d-flex justify-content-between align-content-center mt-4"
        onClick={() => setShowAdvancedFilter((val) => !val)}
        role="button"
        tabIndex={0}
        onKeyDown={() => null}
        style={{ outline: 'none', cursor: 'pointer' }}
      >
        <div>
          <ALink>
            <FormattedMessage
              id="member-management.advanced-filter.text"
              defaultMessage="Advanced Filter"
            />
          </ALink>
        </div>

        <div>
          <LegacyIcon
            className="ml-1 bg-light rounded-circle p-1"
            type={isShowAdvancedFilter ? 'up' : 'down'}
            style={{ transform: 'translateY(4px)' }}
          />
        </div>
      </div>

      <div style={{ display: `${isShowAdvancedFilter ? 'block' : 'none'}` }}>
        <SelectFilterField
          testId="member-mgmt-registrationIp-filter"
          label="Registration IP"
          query={REGISTRATION_IP}
          queryConnection="members"
          filterFieldName="registrationIpAddress"
          filters={filters}
          onChange={(e) =>
            handleChange({
              name: 'registrationIpAddress',
              value: e?.length ? { in: e } : null,
            })
          }
          onKeyDown={blockAlphabets}
        />

        <SelectFilterField
          testId="member-mgmt-lastLoginIp-filter"
          label="Last Login IP"
          query={LAST_LOGIN_IP}
          queryConnection="members"
          filterFieldName="lastLoginIpAddress"
          filters={filters}
          onChange={(e) =>
            handleChange({
              name: 'lastLoginIpAddress',
              value: e?.length ? { in: e } : null,
            })
          }
          onKeyDown={blockAlphabets}
        />

        <BirthDateFilter
          value={filters.dateOfBirth}
          onChange={(e) => handleChange({ name: 'dateOfBirth', value: e })}
          disabled={isLoading}
        />

        <BankAccountFilter
          value={filters}
          onChange={(e) => handleChange({ name: 'bankAccounts', value: e })}
          disabled={isLoading}
        />

        <SelectFilterField
          testId="member-mgmt-mobilePhoneNumber-filter"
          label="Mobile Phone Number"
          query={PHONE_NUMBERS}
          queryConnection="members"
          filterFieldName="mobileNumber"
          filters={filters}
          onChange={(e) =>
            handleChange({
              name: 'mobileNumber',
              value: e?.length ? { in: e } : null,
            })
          }
        />

        <ProfileValidationFilter
          value={filters.forceVerification}
          onChange={(e) =>
            handleChange({ name: 'forceVerification', value: e })
          }
          disabled={isLoading}
        />
      </div>
    </>
  );
};
