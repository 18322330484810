import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { defineMessages } from 'react-intl';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import { Admin } from 'types/graphqlTypes';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { DynamicObj } from 'interfaces/user.interface';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';

const messagesLocal = defineMessages({
  SELECT_CLIENTS: {
    id: 'SELECT_CLIENTS',
    defaultMessage: 'Select client',
  },
});

const ADMINS = gql`
  query Admins($filter: AdminsFilterInput, $first: Int) {
    admins(filter: $filter, first: $first) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export const ClientAccountFilter = () => {
  const [filter, setFilter] = useState({});
  const { filters, setFilters } = useFilterValues();
  const translate = useTranslate();
  const { loading, data } = useQuery(ADMINS, {
    variables: {
      first: 10,
      filter,
    },
    fetchPolicy: 'cache-and-network',
  });

  const onSearch = (searchValue: string) => {
    setFilter({
      username: {
        contains: searchValue,
      },
    });
  };

  const handleFilters = (selectedValue: string[]) => {
    if (!selectedValue || selectedValue.length === 0) {
      setFilters((prev: DynamicObj) => {
        const { admins, ...restFilter } = prev;
        return restFilter;
      });
    } else {
      setFilters((prev: DynamicObj) => ({
        ...prev,
        admins: { includesAny: selectedValue },
      }));
    }
  };

  const adminsEdges = coercedGet(data, 'admins.edges', []);
  const adminsData: Admin[] = adminsEdges.map(
    (admins: { node: Admin }) => admins.node
  );

  return (
    <Spin spinning={loading}>
      <Select
        placeholder={translate(messagesLocal.SELECT_CLIENTS)}
        value={filters?.admins?.includesAny}
        onBlur={() => setFilter({})}
        onChange={handleFilters}
        filterOption={false}
        className="w-100"
        mode="multiple"
        notFoundContent={null}
        onSearch={debounce(onSearch, 500)}
      >
        {adminsData.map(admin => (
          <Select.Option key={admin.id} value={admin.id}>
            {admin.username}
          </Select.Option>
        ))}
      </Select>
    </Spin>
  );
};
