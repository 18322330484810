import FiltersProvider from 'contexts/Filters';
import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import uuid from 'uuid';
import PaymentMethodsScreen from './components/PaymentMethodsScreen';

const PaymentMethodListing = () => {
  const [key, setKey] = useState('');
  const { getTab } = useScreenTabV2();
  const { filter = {} } = getTab('payment-method-listing') || {};
  const refreshPage = () => setKey(uuid.v1());

  const defaultFilters = {
    name: null,
    bank: null,
    nickname: null,
    currentAccumulation: null,
    excludedMemberLevel: null,
    paymentMethodType: null,
    enabled: null,
    minimumDepositAmount: null,
    maximumDepositAmount: null,
    minimumTotalDeposit: null,
    maximumTotalDeposit: null,
    depositProvider: null,
  };

  const initialFilters = {
    ...defaultFilters,
    ...filter,
  };

  return (
    <FiltersProvider
      initialValues={initialFilters}
      defaultValues={defaultFilters}
      tabId="payment-method-listing"
    >
      <PaymentMethodsScreen key={key} refreshPage={refreshPage} />
    </FiltersProvider>
  );
};

export default PaymentMethodListing;
