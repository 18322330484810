import React from 'react';
import styled from 'styled-components';
import { EllipsisOutlined } from '@ant-design/icons';
import { Table as AntTable, Tooltip, Tag, Dropdown, Menu, Spin } from 'antd';
import { useCustomColumnsV2 } from 'SuperAdminMain/store/SuperAdminCustomColumnState/customColumnState';
import { TabIdSA, TabIdSA as TabId } from 'SuperAdminMain/constants/route';
import useTranslate from 'utils/useTranslate';
import { useVendorGroupManagementValue } from 'SuperAdminMain/components/VendorManagement/VendorGroupManagement/VendorGroupManagementContext';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import { Vendor, VendorGroup } from 'types/graphqlTypes';
import { useScreenTabSA } from 'SuperAdminMain/store/SuperAdminScreenTabState/screenTabState';
import { Status } from './Status/Status';
import { Footer } from './Footer/Footer';
import messages from './messages';
import { ClientNumber } from './ClientNumber/ClientNumber';
import { EditVendorGroupInformation } from './EditVendorGroupInformation/EditVendorGroupInformation';
import { DeleteVendorGroup } from './DeleteVendorGroup/DeleteVendorGroup';
import { DuplicateVendorGroup } from './DuplicateVendorGroup/DuplicateVendorGroup';

interface Props {}

const StyledMenu = styled(Menu)`
  li {
    padding: 0;
  }

  button {
    text-align: left;
    color: #000000a6;
  }
`;

const renderVendorsOnTooltip = (vendors: Vendor[]) =>
  vendors.map((vendor) => (
    <Tag className="mt-1" key={vendor.id}>
      {vendor.name}
    </Tag>
  ));

// eslint-disable-next-line arrow-body-style
export const Table: React.FC<Props> = () => {
  const { data, loading, refetch } = useVendorGroupManagementValue();
  const { isTabActive } = useScreenTabSA(TabIdSA.VENDOR_GROUP_MANAGEMENT);
  const isActive = isTabActive(TabIdSA.VENDOR_GROUP_MANAGEMENT);
  const translate = useTranslate();
  const columns = [
    {
      title: translate(messages.VENDOR_GROUP_NAME),
      dataIndex: 'name',
      key: 'name',
      width: 232,
      render: (vendor: string) => vendor || '-',
    },
    {
      title: translate(messages.STATUS),
      dataIndex: 'status',
      key: 'status',
      width: 192,
      render: (status: string) => <Status status={status} />,
    },
    {
      title: translate(messages.NUMBER_OF_VENDOR),
      dataIndex: 'vendors',
      key: 'vendors',
      width: 216,
      render: (vendor: Vendor[]) =>
        vendor.length > 0 ? (
          <Tooltip title={renderVendorsOnTooltip(vendor)}>
            <span style={{ cursor: 'default' }}>{vendor.length}</span>
          </Tooltip>
        ) : (
          <span style={{ cursor: 'default' }}>0</span>
        ),
    },

    {
      title: translate(messages.NUMBER_OF_CLIENT),
      key: 'clientNumber',
      render: ({ id }: { id: string }) => (
        <ClientNumber id={id} refetchBool={isActive} />
      ),
    },
    {
      title: '',
      width: 50,
      key: 'id',
      render: (vendor: VendorGroup) => (
        <Dropdown
          overlay={
            <StyledMenu>
              <StyledMenu.Item key={1}>
                <EditVendorGroupInformation vendorGroup={vendor} />
              </StyledMenu.Item>
              <StyledMenu.Item key={2}>
                <DuplicateVendorGroup id={vendor.id} refetchFn={refetch} />
              </StyledMenu.Item>
              <StyledMenu.Item key={3}>
                <DeleteVendorGroup vendor={vendor} refetchFn={refetch} />
              </StyledMenu.Item>
            </StyledMenu>
          }
          placement="bottomRight"
        >
          <EllipsisOutlined
            className="cursor-pointer font-weight-bold"
            style={{ fontSize: '25px' }}
          />
        </Dropdown>
      ),
    },
  ];

  const { loading: loadingCC, filterColumns } = useCustomColumnsV2(
    TabId.VENDOR_GROUP_MANAGEMENT,
    columns
  );

  if (loadingCC) {
    return <Spin />;
  }

  const totalCount = coercedGet(data, 'vendorGroups.totalCount', 0);
  const vendorGroupEdges = coercedGet(data, 'vendorGroups.edges', []);
  const vendorGroupNodes = vendorGroupEdges.map(
    (vendorGroup: { node: VendorGroup }) => vendorGroup.node
  );

  return (
    <Container>
      <StyledTable
        rowKey={(x: any) => x.id}
        data-testid="vgm-table"
        loading={loadingCC || loading}
        dataSource={vendorGroupNodes}
        columns={filterColumns(columns)}
        pagination={false}
      />

      <Footer totalCount={totalCount} />
    </Container>
  );
};

const Container = styled.div`
  height: auto;
  width: calc(100% - 232px);

  .scheduled-maintenance {
    position: absolute;
    transform: translateY(-50%);
  }
`;

const StyledTable = styled(AntTable)`
  height: calc(100% - 48px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;

  tbody tr {
    max-height: 54px !important;
  }

  table tr:nth-child(2n) td {
    background-color: #fcfcfc;
  }
`;
