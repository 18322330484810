import React from 'react';
import { get } from 'lodash';
import { useScreenTabV2 } from 'store/screenTabState';
import { TabId } from 'constants/route';
import { ALink } from 'components/ALink/ALink';

const GamesScreenTab = ({
  games,
  gameCategory,
  cb,
  allData,
  vendor,
}: {
  games: number;
  gameCategory: string;
  cb?: () => void;
  allData?: Record<string, any>;
  vendor?: Record<string, any>;
}) => {
  const { addTab } = useScreenTabV2();

  const { CATEGORY_VENDOR_GAMES, CATEGORY_GAMES } = TabId;

  const handleClick = () => {
    addTab({
      id: vendor ? CATEGORY_VENDOR_GAMES : CATEGORY_GAMES,
      state: {
        games,
        resolvedGameCategory: gameCategory,
        vendor,
        translateOpts: {
          vendorName: get(vendor, 'name'),
          category: gameCategory,
        },
      },
    } as any);

    if (cb) {
      cb();
    }
  };

  return (
    <ALink onClick={handleClick}>
      {allData ? `${games} / ${allData.games}` : games}
    </ALink>
  );
};

export default GamesScreenTab;
