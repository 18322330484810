import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input } from 'antd';
import SingleFileUploader from 'components/Uploader/SingleFileUploader';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import messages from '../../../../messages';

type Props = {
  values: Record<string, any>;
  handleChange: any;
  handleBlur: any;
  errors: any;
  formItemLayout: Record<string, any>;
  setFieldValue: (key: string, value: any) => void;
  disabled?: boolean;
};

const WechatDetails = (props: Props) => {
  const {
    formItemLayout,
    values,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    disabled,
  } = props;

  const onCompleteUpload = (field: string, file: Record<string, any>) =>
    setFieldValue(field, file ? file.id : '');
  const translate = useTranslate();
  return (
    <>
      <Form.Item
        label={translate(messages.nickname)}
        {...formItemLayout}
        validateStatus={errors.nickname ? 'error' : ''}
        help={errors.nickname || null}
        htmlFor="nickname"
      >
        <Input
          name="nickname"
          id="nickname"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.nickname}
          placeholder={translate(messages.nickname)}
          disabled={disabled}
        />
      </Form.Item>
      <Form.Item
        label={translate(messages.qrcode)}
        {...formItemLayout}
        validateStatus={errors.image ? 'error' : ''}
        help={errors.image || null}
        htmlFor="image"
      >
        <SingleFileUploader
          name="image"
          accept=".png, .jpg, .jpeg"
          defaultFiles={coercedGet(values, 'image', '')}
          data={{
            onCompleteUpload,
          }}
          previewVisible
          showUploadList={{
            showDownloadIcon: false,
          }}
          imageValue={coercedGet(values, 'image', '')}
          setFieldValue={setFieldValue}
        />
      </Form.Item>
    </>
  );
};

export default WechatDetails;
