/* eslint-disable no-param-reassign */
import { useCallback, useMemo } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export type SidebarState = {
  sidebarKey: string;
};

const initialState = {
  sidebarKey: uuidv4(),
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    resetPolling: (state: SidebarState) => {
      state.sidebarKey = uuidv4();
    },
  },
});

export const sidebarReducer = sidebarSlice.reducer;

export const useSidebar = () => {
  const { actions } = sidebarSlice;
  const dispatch = useDispatch();

  const resetPolling = useCallback(() => {
    dispatch(actions.resetPolling());
  }, [actions, dispatch]);

  const sidebarKey = useSelector(
    ({ sidebar }: { sidebar: SidebarState }) => sidebar.sidebarKey
  );

  return useMemo(
    () => ({
      resetPolling,
      sidebarKey,
    }),
    [resetPolling, sidebarKey]
  );
};
