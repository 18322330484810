import gql from 'graphql-tag';

export const VENDOR_SETTING = gql`
  query VendorSettings(
    $vendor: VendorCode!
    $admin: ID
    $context: VendorSettingsContext!
  ) {
    vendorSettings(vendor: $vendor, admin: $admin, context: $context) {
      vendor
      gameTypeSettings {
        SLOT {
          disabled
          hidden
        }
        LIVE {
          disabled
          hidden
        }
        POKER {
          disabled
          hidden
        }
        FISH {
          disabled
          hidden
        }
        SPORTSBOOK {
          disabled
          hidden
        }
        LOTTERY {
          disabled
          hidden
        }
      }
      details
    }
  }
`;

export default {};
