import { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Form, Input, message, Modal, Table, Tooltip } from 'antd';
import ALL_PERMISSIONS from 'constants/permissions3';
import { useFormik } from 'formik';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';
import { has, isEmpty } from 'lodash';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useUpdateEffect } from 'react-use';
import { UseFormikErrorMessage } from 'components/UseFormikErrorMessage/UseFormikErrorMessage';
import { usePermissions } from 'store/accountState';
import useTranslate from 'utils/useTranslate';
import { useScreenTabV2 } from '../../../store/screenTabState';
import esGet from '../../../utils/esGet';
import {
  CONFIG,
  ProfileSwitch,
  validationSchema,
  verificationDict,
} from './utils';

const ForceProfileValidation = () => {
  const translate = useTranslate();
  const [modalVisible, setModalVisible] = useState(false);
  const { data, loading, refetch } = useQuery(CONFIG, {
    fetchPolicy: 'network-only',
  });
  const { isTabActive } = useScreenTabV2();
  const tab = isTabActive('force-profile-validation');
  const [updateConfig] = useMutation(UPDATE_CONFIG);
  const memberFormFields = esGet(data?.config?.memberFormFields, []);
  const dataDict = verificationDict(memberFormFields);
  const defaultValue = {
    ...data?.config?.memberForceVerificationSettings,
    bankAccount:
      data?.config?.memberForceVerificationSettings?.bankAccountNumber,
  };

  useUpdateEffect(() => {
    if (tab && refetch) {
      refetch();
    }
  }, [tab]);

  const formik = useFormik({
    onSubmit: (e) => {
      const { bankAccount, __typename, ...restValue } = e;
      updateConfig({
        variables: {
          input: {
            memberForceVerificationSettings: {
              ...restValue,
              bankAccountNumberLastDigitsCount: +restValue.bankAccountNumberLastDigitsCount,
            },
          },
        },
      }).then(() => {
        message.success(translate(messages['successfully-saved-changes.text']));
        setModalVisible(false);
      });
    },
    validationSchema: validationSchema(translate),
    initialValues: data?.config?.memberForceVerificationSettings
      ? defaultValue
      : {
          realName: false,
          gender: false,
          dateOfBirth: false,
          bankAccount: true,
          bankAccountName: false,
          bankAccountNumber: true,
          bankAccountNumberLastDigitsCount: 8,
          mobileNumber: true,
          email: true,
        },
    enableReinitialize: true,
  });
  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    handleBlur,
  } = formik;
  const columns = [
    {
      title: translate(messages.FIELD_NAME),
      dataIndex: 'name',
      key: 'name',
      width: 250,
    },
    {
      title: translate(messages.MEMBER_VALIDATE),
      dataIndex: 'validate',
      key: 'validate',
      width: 150,
      render: (e: boolean, allData: Record<string, any>) => (
        <ProfileSwitch
          disabled={
            (allData.key === 'mobileNumber' &&
              !has(dataDict, 'MOBILE_NUMBER')) ||
            (allData.key === 'email' && !has(dataDict, 'EMAIL'))
          }
          value={e}
          onChange={(val: boolean) => setFieldValue(allData.key, val)}
        />
      ),
    },
    {
      title: 'Others',
      dataIndex: 'render',
      key: 'render',
      render: (e: any) => {
        if (e) {
          return (
            <div className="d-flex">
              <div className="mr-3 d-flex align-items-center">
                <div className="mr-2">
                  <FormattedMessage
                    id="BANK_ACCOUNT_NAME"
                    defaultMessage="Bank Account Name"
                  />
                </div>
                <ProfileSwitch
                  disabled={!values.bankAccount}
                  value={values.bankAccountName}
                  onChange={(val: boolean) =>
                    setFieldValue('bankAccountName', val)
                  }
                />
              </div>
              <div className="mr-3 d-flex align-items-center">
                <div className="mr-2">
                  <FormattedMessage
                    id="BANK_ACCOUNT_NUMBER"
                    defaultMessage="Bank Account Number"
                  />
                </div>
                <ProfileSwitch
                  disabled
                  value={values.bankAccountNumber}
                  onChange={(val: boolean) =>
                    setFieldValue('bankAccountNumber', val)
                  }
                />
              </div>
              <div className="mr-3 d-flex align-items-center">
                <div className="mr-2">
                  <FormattedMessage
                    id="VERIFICATION_DIGITS"
                    defaultMessage="Verification Digits"
                  />
                </div>
                <div>
                  <Input
                    disabled={!values.bankAccountNumber || !values.bankAccount}
                    value={values.bankAccountNumberLastDigitsCount}
                    name="bankAccountNumberLastDigitsCount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ width: '25%' }}
                  />

                  <div style={{ fontSize: '12px' }}>
                    <UseFormikErrorMessage
                      formik={formik}
                      name="bankAccountNumberLastDigitsCount"
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }
        return <></>;
      },
    },
  ];
  const mock = [
    {
      name: 'Real Name',
      validate: values.realName,
      render: null,
      key: 'realName',
    },
    {
      name: 'Gender',
      validate: values.gender,
      render: null,
      key: 'gender',
    },
    {
      name: 'Date of Birth',
      validate: values.dateOfBirth,
      render: null,
      key: 'dateOfBirth',
    },
    {
      name: 'Bank Account',
      validate: values.bankAccount,
      render: 'hey',
      key: 'bankAccount',
    },
  ];
  const mock2 = [
    {
      name: 'Update Mobile Number',
      validate: values.mobileNumber,
      key: 'mobileNumber',
    },
    {
      name: 'Update Email',
      validate: values.email,
      key: 'email',
    },
  ];

  useEffect(() => {
    if (values.bankAccount) {
      setFieldValue('bankAccountNumber', true);
    } else {
      setFieldValue('bankAccountNumber', false);
      setFieldValue('bankAccountName', false);
      setFieldValue('bankAccountNumberLastDigitsCount', 8);
    }
  }, [setFieldValue, values.bankAccount]);

  const { role, permissions } = usePermissions();
  const { ALLOWED_FORCE_VERIFICATION: ALLOWED_EDIT } = collectPermissions(
    role,
    permissions,
    ['FORCE_VERIFICATION'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_SYSTEM_CONFIG}:EDIT`
  );

  return (
    <Form style={{ height: 'calc(100vh - 112px)' }}>
      <div className="py-4 px-3" style={{ background: 'white' }}>
        <div className="d-flex justify-content-between">
          <p className="fs-14 fw-600">
            <FormattedMessage
              id="FORCE_PROFILE_VALIDATION_SETTINGS"
              defaultMessage="Force Profile Validation Setting"
            />
          </p>
          <Tooltip
            placement="left"
            title={
              !ALLOWED_EDIT &&
              'Need Permission in Force Profile Verification (Edit)'
            }
          >
            <Button
              disabled={!isEmpty(formik.errors) || !ALLOWED_EDIT}
              onClick={() => setModalVisible(true)}
            >
              <FormattedMessage id="save.text" defaultMessage="Save" />
            </Button>
          </Tooltip>
        </div>

        <div className="mt-3">
          <Table
            loading={loading}
            columns={columns}
            dataSource={mock}
            pagination={false}
          />
        </div>

        <div className="mt-5">
          <p className="fs-14 fw-600">
            <FormattedMessage
              id="CONTACT_INFORMATION_UPDATE"
              defaultMessage="Contact Information Update"
            />
          </p>
          <Table
            loading={loading}
            columns={columns}
            dataSource={mock2}
            pagination={false}
            showHeader={false}
          />
        </div>
      </div>

      <Modal
        visible={modalVisible}
        onOk={() => handleSubmit()}
        onCancel={() => setModalVisible(false)}
        bodyStyle={{
          padding: '32px 32px 24px 32px',
        }}
        footer={null}
        closable={false}
      >
        <div className="modal-content d-flex flex-row">
          <div style={{ width: 38 }}>
            <InfoCircleOutlined
              style={{ color: 'rgb(251,193,78)', fontSize: '22px' }}
            />
          </div>

          <div style={{ position: 'relative', width: '100%' }}>
            <div
              className="modal-title"
              style={{
                fontSize: 16,
                color: 'rgba(0,0,0,0.85',
              }}
            >
              <div>{translate(messages.DO_YOU_WISH_TO_PROCEED)}</div>
            </div>

            <div className="buttons d-flex justify-content-end">
              <Button onClick={() => setModalVisible(false)}>
                {translate(messages['cancel.text'])}
              </Button>

              <Button
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginLeft: 8 }}
              >
                {translate(messages['save.text'])}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Form>
  );
};

export default ForceProfileValidation;
