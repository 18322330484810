import { useMutation } from '@apollo/react-hooks';
import { Button, message, Select } from 'antd';
import { UPDATE_MEMBER } from 'graphql/mutations/member.mutation';
import MemberDropdown from 'pages/components/NewMemberDetail/components/MemberDropdown';
import { useNewMemberDetailPermissions } from 'pages/components/NewMemberDetail/constants';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import {
  ContainerBody,
  ContainerHeader,
} from 'pages/components/NewMemberDetail/styles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PermissionError from 'components/PermissionError';
import { Flex } from 'SuperAdminMain/shared/styles/Flex';
import { Member, MutationUpdateMemberArgs } from 'types/graphqlTypes';

const realityCheckOpts = [
  {
    label: '15 mins',
    value: 900_000,
  },
  {
    label: '30 mins',
    value: 1_800_000,
  },
  {
    label: '45 mins',
    value: 2_700_000,
  },
  {
    label: '60 mins',
    value: 3_600_000,
  },
];

const MemberRealityCheck = () => {
  const [showTable, setShowTable] = useState(true);
  const [selectedValue, setSelectedValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const { MEMBER_REALITY_CHECK_PERMISSION } = useNewMemberDetailPermissions();

  const [updateMember] = useMutation<boolean, MutationUpdateMemberArgs>(
    UPDATE_MEMBER
  );
  const { member, refetch } = useMember() as {
    member: Member;
    refetch: Function;
  };

  const onUpdateRealityCheck = () => {
    setLoading(true);
    updateMember({
      variables: {
        id: member?.id,
        input: {
          realityCheckDuration: selectedValue,
        },
      },
    })
      .then(() => {
        message.success('Reality check updated');
        refetch();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const realityCheckValue = () => {
    if (!member?.realityCheckDuration) {
      return '';
    }

    const foundValue = realityCheckOpts.find(
      (opt) => opt.value === member.realityCheckDuration
    );
    return foundValue!.label;
  };

  return (
    <div className="mt-3">
      <ContainerHeader>
        <span className="fw-500">
          <FormattedMessage id="REALITY_CHECK" defaultMessage="Reality Check" />
        </span>
        <MemberDropdown showTable={showTable} setShowTable={setShowTable} />
      </ContainerHeader>
      <ContainerBody
        className="p-3"
        style={{ display: showTable ? 'block' : 'none' }}
      >
        <PermissionError
          message="Member Reality Check"
          withPermission={MEMBER_REALITY_CHECK_PERMISSION.ALLOWED_LIST}
        >
          <>
            <p className="mb-0">Duration: {realityCheckValue()}</p>

            <Flex alignItems="center" className="mt-3">
              <Select
                className="mr-3"
                style={{ width: '150px' }}
                value={selectedValue}
                onSelect={(e) => setSelectedValue(e)}
              >
                <Select.Option value="" disabled>
                  Select Duration
                </Select.Option>
                {realityCheckOpts.map((opts) => (
                  <Select.Option value={opts.value}>{opts.label}</Select.Option>
                ))}
              </Select>
              <Button
                disabled={
                  loading ||
                  !selectedValue ||
                  !MEMBER_REALITY_CHECK_PERMISSION.ALLOWED_EDIT
                }
                loading={loading}
                onClick={onUpdateRealityCheck}
              >
                Set Reality Check
              </Button>
            </Flex>
          </>
        </PermissionError>
      </ContainerBody>
    </div>
  );
};

export default MemberRealityCheck;
