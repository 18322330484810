import React, { useCallback, useEffect, useState } from 'react';
import Drawer from 'pages/components/common/Drawer/Drawer';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { isEmpty, flatten } from 'lodash';
import { FormattedMessage, defineMessages } from 'react-intl';
import DrawerFooter from 'components/DrawerFooter';
import * as Yup from 'yup';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { findDuplicates } from 'utils/findDuplicates';
import { getAllIndexes } from 'utils/getAllIndexes';
import messages from 'messages';
import { CustomRegex } from 'utils/regex';
import useTranslate from 'utils/useTranslate';
import { useMissionsValue } from '../../context';
import { MilestoneCard } from './MilestoneCard';
import { RadioSwitch } from '../../utils';

const appendDefaultValues = {
  requirement: null,
  rewardType: 'MONEY',
  rewardAmount: '',
  rewardMultiplier: true,
  turnoverRequirementMultiplier: '',
};

const validationSchema = (translate: any, settings: boolean) =>
  settings
    ? Yup.object().shape({
        milestones: Yup.array().of(
          Yup.object().shape({
            requirement: Yup.number()
              .nullable()
              .required(translate(messages.REQUIRED))
              .min(1, translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 })),
            rewardAmount: Yup.string()
              .required(translate(messages.REQUIRED))
              .matches(
                CustomRegex.onlyNumbers(),
                translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
              )
              .matches(
                CustomRegex.twoDecimals(),
                translate(messages['maximum-count-decimal-places.text'], {
                  count: 2,
                })
              ),
            turnoverRequirementMultiplier: Yup.string()
              .required(translate(messages.REQUIRED))
              .matches(
                CustomRegex.onlyNumbers(),
                translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
              )
              .matches(
                CustomRegex.twoDecimals(),
                translate(messages['maximum-count-decimal-places.text'], {
                  count: 2,
                })
              ),
          })
        ),
      })
    : Yup.object().shape({});

const localMessages = defineMessages({
  'MISSIONS.TIMES_DAYS_DUPLICATE_ERROR': {
    id: 'MISSIONS.TIMES_DAYS_DUPLICATE_ERROR',
    defaultMessage: 'Times/Days cannot be repeated!',
  },
});

const MilestoneSettings = () => {
  const {
    onPrevStep,
    setMilestonesValue,
    milestonesValue,
    onNextStep,
  } = useMissionsValue();
  const [settings, setSettings] = useState(milestonesValue?.milestonesEnabled);
  const [amounts, setAmounts] = useState({});
  const translate = useTranslate();
  const {
    control,
    handleSubmit,
    getValues,
    errors,
    clearErrors,
    setError,
    formState,
  } = useForm({
    defaultValues: {
      milestones: milestonesValue?.milestones || [],
    },
    resolver: yupResolver(validationSchema(translate, settings)),
  });
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'milestones',
  });

  const validateTimesDays = useCallback(() => {
    if (!isEmpty(amounts)) {
      const keys = Object.keys(amounts).map((amount) => +amount);
      const values: number[] = Object.values(amounts);
      const duplicates = findDuplicates(values).filter((dup) => dup > 0);
      const indexWithErrors = flatten(
        duplicates.map((dup: number) => getAllIndexes(values, dup))
      );
      const amountsIndexWithEmpty = values.reduce<number[]>((acc, cur, idx) => {
        if (!cur) {
          acc.push(+idx);
        }

        return acc;
      }, []);
      const keysWithAmountOnly = keys.filter(
        (key) => !amountsIndexWithEmpty.includes(key)
      );
      keysWithAmountOnly.forEach((key) =>
        clearErrors(`milestones[${key}].requirement`)
      );
      if (indexWithErrors.length > 0) {
        setTimeout(() => {
          indexWithErrors.forEach((index) => {
            setError(`milestones[${index}].requirement`, {
              type: 'manual',
              message: translate(
                localMessages['MISSIONS.TIMES_DAYS_DUPLICATE_ERROR']
              ),
            });
          });
        });
      }

      return indexWithErrors.length > 0;
    }

    return false;
  }, [amounts, clearErrors, setError, translate]);

  const onSubmit = () => {
    if (settings) {
      const hasErrors = validateTimesDays();
      if (hasErrors) {
        return;
      }
    }

    setMilestonesValue({
      milestonesEnabled: settings,
      milestones: getValues()?.milestones,
    });
    onNextStep();
  };

  useEffect(() => {
    if (settings) {
      validateTimesDays();
    }
  }, [validateTimesDays, formState.submitCount, settings]);

  useEffect(() => {
    if (!settings) {
      clearErrors();
    }
  }, [clearErrors, settings]);

  const watchedMilestones = useWatch({
    control,
    name: 'milestones',
  });
  return (
    <>
      <Drawer.Content style={{ marginLeft: '5%', marginRight: '5%' }}>
        <div className="my-2 d-flex justify-content-between">
          <div>
            <div className="fs-11">
              <FormattedMessage
                id="MILESTONES_SETTING"
                defaultMessage="Milestones Setting"
              />
            </div>
            <RadioSwitch onChange={(e) => setSettings(e)} value={settings} />
          </div>
          <Button
            disabled={!settings}
            type="primary"
            ghost
            onClick={() => append(appendDefaultValues)}
          >
            <PlusOutlined />{' '}
            <FormattedMessage
              id="ADD_MILESTONE"
              defaultMessage="Add Milestone"
            />
          </Button>
        </div>

        <div className="my-3">
          {fields.map((item, idx) => (
            <MilestoneCard
              duplicate={() =>
                insert(
                  idx + 1,
                  (watchedMilestones as Record<string, any>[])[idx]
                )
              }
              setAmounts={setAmounts}
              settings={settings}
              errors={errors}
              item={item}
              control={control}
              key={item.id}
              index={idx}
              remove={remove}
            />
          ))}
        </div>
      </Drawer.Content>
      <DrawerFooter>
        <>
          <Button onClick={() => onPrevStep()}>
            <FormattedMessage id="BACK" defaultMessage="Back" />
          </Button>
          <Button onClick={handleSubmit(onSubmit)} type="primary">
            <FormattedMessage id="CONTINUE" defaultMessage="Continue" />
          </Button>
        </>
      </DrawerFooter>
    </>
  );
};

export default MilestoneSettings;
