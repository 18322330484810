import { isEmpty, replace } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button, DatePicker, message, Select } from 'antd';
import { useInterval, useUpdateEffect } from 'react-use';
import BrandSelect from 'components/BrandSelect';
import gql from 'graphql-tag';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { usePermissions } from 'store/accountState';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import ALL_PERMISSIONS from 'constants/permissions3';
import { DATE_FORMAT } from 'constants/date';
import {
  euReportsOptions,
  Reports,
  reportType,
  useReportsMutation,
  ReportData,
  queriesKeyAndNode,
} from './utils';

const Container = styled.div`
  background: white;
  padding: 20px;
  height: calc(100vh - 112px);
`;

const ME = gql`
  query {
    me {
      ... on Operator {
        id
        username
        admin
        company
        linkedAdmins {
          id
          company
        }
      }
      ... on Admin {
        id
        username
        company
        linkedAdmins {
          id
          company
        }
      }
    }
  }
`;

const maxTries = 100_000_000;

const EUReports = () => {
  const dateFormat = DATE_FORMAT;
  const customDateFormat = 'YYYY-MM-DDTHH:mm:ss.000Z';
  const [report, setReport] = useState<Reports | ''>('');
  const [dateTime, setDateTime] = useState<[moment.Moment, moment.Moment]>([
    moment.utc(new Date(), dateFormat).startOf('day'),
    moment.utc(new Date(), dateFormat).startOf('day'),
  ]);
  const [downloadLink, setDownloadLink] = React.useState('');
  const [isLoading, toggleIsLoading] = React.useState(false);
  const [isRunning, toggleIsRunning] = React.useState(false);
  const [downloadTryCount, setDownloadTryCount] = React.useState(0);
  const [selectedBrands, setSelectedBrands] = React.useState<string[]>([]);
  const [brands, setBrands] = React.useState([]);

  const [data, setData] = useState<Record<string, any>>({});

  const { callMutation } = useReportsMutation(report);
  const { role, permissions } = usePermissions();
  const {
    ALLOWED_CSV_DOWNLOAD_BUTTON,
    ...reportPermissions
  } = collectPermissions(
    role,
    permissions,
    [
      'CSV_DOWNLOAD_BUTTON',
      'DAILY_DEPOSIT_REPORT',
      'DAILY_PLAYER_ACTIVITIES_REPORT',
      'DAILY_SELF_EXCLUSION_REPORT',
      'DAILY_TIMEOUT_REPORT',
      'DAILY_TRANSACTION_REPORT',
      'DEPOSIT_LIMIT_REPORT',
      'FULL_CUSTOMER_DUMPS',
      'NEWLY_REGISTERED_MEMBERS_REPORT',
      'PLAYER_STATUS_REPORT',
    ],
    ALL_PERMISSIONS.ALL_REPORTS.REPORTS_EU_REPORTS
  );

  const applyPermission = (reports: ReportData[]) => {
    const permittedReports = Object.keys(
      reportPermissions
    ).filter((permissionKey) => Boolean(reportPermissions[permissionKey]));
    const transformedPermittedReports = permittedReports.map((permission) =>
      replace(permission, 'ALLOWED_', '')
    );
    return reports.filter((reportData: ReportData) =>
      transformedPermittedReports.find(
        (keyword) => keyword === reportData.value
      )
    );
  };

  const client = useApolloClient();

  useInterval(
    async () => {
      const { data: resData } = await client.query({
        query: queriesKeyAndNode[report],
        fetchPolicy: 'network-only',
        variables: {
          first: 1,
        },
      });

      setData(resData);
    },

    isRunning ? 1000 : null
  );

  useUpdateEffect(() => {
    if (downloadTryCount === maxTries) {
      toggleIsRunning(false);
      toggleIsLoading(false);
      setDownloadTryCount(0);
      message.error('No report generated');
      return;
    }

    if (!isEmpty(data)) {
      const type = reportType[report];
      const reportData = data[type]?.edges[0]?.node?.report?.uri;

      if (reportData) {
        message.info('Report generated');
        setDownloadLink(reportData);
        toggleIsRunning(false);
        toggleIsLoading(false);
      } else {
        setDownloadTryCount((prev) => prev + 1);
      }
    } else {
      setDownloadLink('');
    }
  }, [data]);

  const onGenerate = () => {
    if (selectedBrands?.length === 0) {
      message.warning('Please select at least 1 brand');
      return;
    }
    setDownloadLink('');
    toggleIsLoading(true);
    callMutation({
      variables: {
        input: {
          filter: {
            startDateTime: moment(dateTime![0]).format(customDateFormat),
            endDateTime: moment(dateTime![1]).format(customDateFormat),
            admins: selectedBrands,
          },
        },
      },
    }).then(() => {
      toggleIsRunning(true);
    });
  };

  const [loadMeData] = useLazyQuery(ME, {
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      const { me } = _data || {};
      const account = me?.linkedAdmins?.concat([
        ...(me?.__typename !== 'Operator'
          ? [{ id: me.id!, company: me.company! }]
          : [{ id: me.admin!, company: me.company! }]),
      ]);
      setBrands(account);
    },
  });

  React.useEffect(() => {
    loadMeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <div className="mb-3">Custom Reports</div>
      <div
        style={{
          display: 'flex',
          gap: '15px',
        }}
      >
        <div style={{ width: '300px' }}>
          <h4>Brands</h4>
          <BrandSelect
            brands={brands || []}
            onSelect={(e) => setSelectedBrands(e)}
          />
        </div>

        <div>
          <h4>Report</h4>
          <Select
            style={{ width: '300px' }}
            placeholder="Report Name"
            onChange={(e: Reports) => {
              setData({});
              setReport(e);
            }}
          >
            {applyPermission(euReportsOptions).map((dataReport: ReportData) => (
              <Select.Option value={dataReport.value}>
                {dataReport.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div>
          <h4>Date/Time Range</h4>
          <DatePicker.RangePicker
            showTime
            value={dateTime}
            onChange={(e) => {
              setData({});
              setDateTime(
                (e as unknown) as React.SetStateAction<
                  [moment.Moment, moment.Moment]
                >
              );
            }}
            format={`${dateFormat}, HH:mm:ss`}
          />
        </div>

        {ALLOWED_CSV_DOWNLOAD_BUTTON && (
          <>
            <div>
              <h4 style={{ opacity: '0' }}>.</h4>
              <Button
                type="default"
                onClick={onGenerate}
                disabled={!report || isLoading}
                loading={isLoading}
              >
                {isLoading ? 'Processing...' : 'Generate'}
              </Button>
            </div>

            <div>
              <h4 style={{ opacity: '0' }}>.</h4>
              <Button
                href={downloadLink}
                disabled={!downloadLink || selectedBrands.length === 0}
                type="primary"
              >
                Download
              </Button>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default EUReports;
