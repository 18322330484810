import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import Numeral from 'numeral';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Input, Select, message } from 'antd';
import { StyledFormItem1 } from './styles';

const { Item } = Form;
const { Option } = Select;

const colStyles = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const relativeFeeLimitSchema = Yup.number()
  .min(0)
  .max(100)
  .nullable();

type Props = {
  formik?: Record<string, any>;
};

const CustomSelect: any = Select;

export default function Benefits({ formik = {} }: Props) {
  const { handleChange, setFieldValue, values } = formik;

  const {
    maximumWithdrawalAmountPerRequest,
    maximumWithdrawalRequest,
    withdrawalLimitRequestReset,
    withdrawalLimitFeeType,
    withdrawalLimitFee,
    bankAccountLimits,
    eWalletLimits,
  } = values;

  const feeTypes = {
    RELATIVE: 'RELATIVE',
    ABSOLUTE: 'ABSOLUTE',
  };

  const getLimitFeeValue = () => {
    if (withdrawalLimitFeeType === feeTypes.RELATIVE) {
      return !withdrawalLimitFee
        ? 0
        : Numeral(withdrawalLimitFee * 100).format('0,0');
    }
    return withdrawalLimitFee;
  };

  return (
    <div>
      <Item
        colon={false}
        style={{ color: 'rgba(0, 0, 0, 0.85)' }}
        label={
          <strong>
            <FormattedMessage
              id="values-settings.text"
              defaultMessage="Benefits Settings"
            />
          </strong>
        }
        {...colStyles}
      >
        &nbsp;
      </Item>

      <Item
        label={
          <span>
            <FormattedMessage
              id="maximum-withdrawal-amount-per-request.text"
              defaultMessage="Maximum Withdrawal Amount (Per Request)"
            />
          </span>
        }
        {...colStyles}
      >
        <Input
          name="maximumWithdrawalAmountPerRequest"
          value={maximumWithdrawalAmountPerRequest}
          onChange={handleChange}
          type="number"
          size="large"
          placeholder="2000"
          style={{ width: '60%' }}
        />
      </Item>

      <StyledFormItem1
        label={
          <FormattedMessage
            id="maximum-withdrawal-request.text"
            defaultMessage="Maximum Withdrawal Request"
          />
        }
        {...colStyles}
      >
        <Input
          name="maximumWithdrawalRequest"
          value={maximumWithdrawalRequest}
          onChange={handleChange}
          type="number"
          size="large"
          placeholder="18"
          style={{ width: '60%' }}
          addonAfter={
            <CustomSelect
              name="withdrawalLimitRequestReset"
              value={withdrawalLimitRequestReset}
              onChange={(period: any) => {
                setFieldValue('withdrawalLimitRequestReset', period);
              }}
              defaultValue="DAILY"
              style={{ width: 128 }}
            >
              <Option key="1" value="DAILY">
                <FormattedMessage
                  id="member-loyalty.per-day.text"
                  defaultMessage="Per Day"
                />
              </Option>
              <Option key="2" value="WEEKLY">
                <FormattedMessage
                  id="member-loyalty.per-week.text"
                  defaultMessage="Per Week"
                />
              </Option>
              <Option key="3" value="MONTHLY">
                <FormattedMessage
                  id="member-loyalty.per-month.text"
                  defaultMessage="Per Month"
                />
              </Option>
            </CustomSelect>
          }
        />
      </StyledFormItem1>

      <StyledFormItem1
        label={
          <FormattedMessage
            id="widthdrawal-limit-fee.text"
            defaultMessage="Withdrawal Limit Fee"
          />
        }
        {...colStyles}
      >
        <Input
          name="withdrawalLimitFee"
          value={getLimitFeeValue()}
          onChange={(e) => {
            if (withdrawalLimitFeeType === feeTypes.RELATIVE) {
              const value = !parseInt(e.target.value, 10)
                ? 0
                : parseInt(e.target.value, 10);

              relativeFeeLimitSchema
                .validate(value)
                .then(() => {
                  const decimalPercent = value / 100;

                  setFieldValue('withdrawalLimitFee', decimalPercent);
                })
                .catch((err) => {
                  message.error(err.message);
                });
              return;
            }

            setFieldValue('withdrawalLimitFee', parseInt(e.target.value, 10));
          }}
          type="number"
          size="large"
          placeholder="18"
          style={{ width: '60%' }}
          addonAfter={
            <CustomSelect
              name="withdrawalLimitFeeType"
              value={withdrawalLimitFeeType}
              onChange={(feeType: any) => {
                setFieldValue('withdrawalLimitFeeType', feeType);
              }}
              defaultValue="RELATIVE"
              style={{ width: 128 }}
            >
              <Option key="1" value="RELATIVE">
                %
              </Option>
              <Option key="2" value="ABSOLUTE">
                <FormattedMessage
                  id="member-loyalty.fixed.text"
                  defaultMessage="Fixed"
                />
              </Option>
            </CustomSelect>
          }
        />
      </StyledFormItem1>

      <Item
        label={
          <FormattedMessage
            id="bank-account-limits.text"
            defaultMessage="Bank Account Limits"
          />
        }
        {...colStyles}
      >
        <Input
          name="bankAccountLimits"
          value={bankAccountLimits}
          onChange={handleChange}
          type="number"
          size="large"
          style={{ width: '60%' }}
        />
      </Item>

      <Item
        label={
          <FormattedMessage
            id="e-wallet-limits.text"
            defaultMessage="E-Wallet Limits"
          />
        }
        {...colStyles}
      >
        <Input
          name="eWalletLimits"
          value={eWalletLimits}
          onChange={handleChange}
          type="number"
          size="large"
          style={{ width: '60%' }}
        />
      </Item>
    </div>
  );
}
