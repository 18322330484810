import { useQuery } from '@apollo/react-hooks';
import { ErrorHandler } from 'components/ErrorHandler';
import Layout from 'components/Layout';
import { TabId } from 'constants/route';
import { useFilterValues } from 'contexts/Filters';
import { WITHDRAWAL_METHODS } from 'graphql/queries/withdrawalMethod.query';
import React, { useState } from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import { transformMovableDateFilter } from 'utils/dateUtils';
import { getPollInterval } from 'utils/pollInterval';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import HeaderLeft from '../HeaderLeft';
import HeaderRight from '../HeaderRight';
import SideBar from '../SideBar';
import WithdrawalMethodsTable from '../WithdrawalMethodsTable';

const WithdrawalsScreen = () => {
  const { isTabActive } = useScreenTabV2();
  const { context, setOnPoll } = useOperatorHeader();
  const [page, setPage] = useState<any>({
    first: 10,
    after: undefined,
    savedCursor: [''],
    currentPage: 0,
  });
  const { filters } = useFilterValues();

  const refetchVariables = {
    first: page.first,
    after: page.after || undefined,
    filter: transformMovableDateFilter(filters),
  };

  const { loading, data = {} } = useQuery(WITHDRAWAL_METHODS, {
    variables: refetchVariables,
    pollInterval: getPollInterval({
      isActive: isTabActive(TabId.WITHDRAWAL_METHODS as any),
      isRealtime: true,
    }),
    context,
    onCompleted: () => {
      setOnPoll(true);
    },
  });

  const { withdrawalMethods = {} } = data as any;
  const { totalCount, pageInfo, edges = [] } = withdrawalMethods;
  const totalPage = Math.ceil(totalCount / page.first) || 1;

  const handleNext = () => {
    const { savedCursor, currentPage } = page;
    savedCursor.push(pageInfo.endCursor);
    setPage({
      ...page,
      after: pageInfo.endCursor,
      currentPage: currentPage + 1,
      savedCursor,
    });
  };

  const handlePrev = () => {
    const { currentPage, savedCursor } = page;
    const prevPage = currentPage - 1;
    const after = savedCursor[prevPage];
    setPage({
      ...page,
      after,
      currentPage: prevPage,
    });
  };

  return (
    <Layout.Container>
      <>
        <Layout.Header leftNode={<HeaderLeft />} rightNode={<HeaderRight />} />
        <Layout.Content
          sideBar={<SideBar />}
          footer={
            <Layout.Footer
              leftNode={<div />}
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={pageInfo}
            />
          }
        >
          <ErrorHandler>
            <WithdrawalMethodsTable
              dataSource={edges}
              loading={loading}
              refetchVariables={refetchVariables}
            />
          </ErrorHandler>
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

export default WithdrawalsScreen;
