import React from 'react';
import styled from 'styled-components';

const SmallBlock = styled.div`
  border-radius: 10px;
  height: 50px;
  width: 100%;
  background: #e6e6e6;
  margin-bottom: 5px;
`;

type BannerProps = {
  desktopPreviewState: boolean;
  setDesktopPreviewState: (item: boolean) => void;
  selectedBanner: {
    imageMobile: {
      uri: string;
    };
    name: string;
  };
};

export const MobilePreviewBanner = ({ selectedBanner }: BannerProps) => (
  <div>
    <div
      style={{
        height: '30px',
        width: '100%',
        background: '#e6e6e6',
      }}
    />

    <div className="d-flex justify-content-space-between mt-2 align-items-center">
      <div
        style={{
          borderRadius: '0 10px 10px 0',
          height: '135px',
          width: '50px',
          background: '#e6e6e6',
        }}
      />
      <div className="p-2">
        <img
          src={selectedBanner.imageMobile.uri}
          alt={selectedBanner.name}
          style={{
            width: '100%',
            borderRadius: '10px',
          }}
          height={150}
        />
      </div>
      <div
        style={{
          borderRadius: '10px 0 0 10px',
          height: '135px',
          width: '50px',
          background: '#e6e6e6',
        }}
      />
    </div>

    <div className="d-flex mt-1 justify-content-center px-3">
      <div
        className="mx-1"
        style={{
          borderRadius: '10px',
          height: '25px',
          width: '85%',
          background: '#e6e6e6',
        }}
      />
      <div
        className="mx-1"
        style={{
          borderRadius: '10px',
          height: '25px',
          width: '15%',
          background: '#e6e6e6',
        }}
      />
    </div>

    <div className="d-flex mt-1 justify-content-center px-3">
      <div
        className="mx-1"
        style={{
          borderRadius: '10px',
          height: '100px',
          width: '100%',
          background: '#e6e6e6',
        }}
      />
    </div>

    <div className="mt-1 px-3">
      <div className="mx-1 d-flex justify-content-space-between">
        <div style={{ width: '28%' }}>
          <SmallBlock />
          <SmallBlock />
          <SmallBlock />
          <SmallBlock />
          <SmallBlock />
        </div>
        <div style={{ width: '70%' }}>
          <div
            style={{
              borderRadius: '10px',
              height: '270px',
              width: '100%',
              background: '#e6e6e6',
            }}
          />
        </div>
      </div>
    </div>

    <div className="mt-1">
      <div
        style={{
          height: '50px',
          width: '100%',
          background: '#e6e6e6',
        }}
      />
    </div>
  </div>
);
