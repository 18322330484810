import React from 'react';
import moment from 'moment';
import { Tooltip } from 'antd';
import coercedGet from 'utils/coercedGet';
import { columnAlign } from 'utils/tableAlignment';
import MemberProfile from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/MemberProfile';
import { ReactComponent as IconAgent } from 'components/assets/icon-agent.svg';
import IpAddressLocationTableCell from 'components/IpAddressLocationTableCell';
import { ALink } from 'components/ALink/ALink';
import {
  IpAddressLocation,
  MemberIpAddressReportItem,
} from 'types/graphqlTypes';
import { DATE_TIME_FORMAT } from 'constants/date';
import messages from '../../messages';

export type OriginalColumn = {
  key: string;
  title: string;
  customTitle?: string;
  [x: string]: any;
};

type Translator = (messageArgs: {
  id: string;
  defaultMessage: string;
}) => string;

type RedirectByFilter = ({
  id,
  ipAddress,
}: {
  id: string;
  ipAddress: string;
}) => void;

const getColumns = (
  translate: Translator,
  handleRedirectByFilter: RedirectByFilter
): OriginalColumn[] => [
  {
    csvData: {
      label: translate(messages['username.text']),
      key: 'username',
      renderCell: ({ member }: MemberIpAddressReportItem) =>
        coercedGet(member, 'username', ''),
    },
    disabled: true,
    title: translate(messages['username.text']),
    key: 'username',
    align: columnAlign.onCenter,
    width: 100,
    render: ({ member }: MemberIpAddressReportItem) => (
      <MemberProfile member={member} />
    ),
  },
  {
    csvData: {
      label: translate(messages['affiliate.text']),
      key: 'affiliate',
      renderCell: ({ member }: MemberIpAddressReportItem) =>
        coercedGet(member, 'affiliate.code', '-'),
    },
    title: translate(messages['affiliate.text']),
    key: 'affiliate',
    align: columnAlign.onCenter,
    width: 100,
    render: ({ member }: MemberIpAddressReportItem) => {
      const code = coercedGet(member, 'affiliate.code', '-');

      return code === '-' ? (
        code
      ) : (
        <Tooltip title={code}>
          <IconAgent />
        </Tooltip>
      );
    },
  },
  {
    csvData: {
      label: translate(messages['real-name.text']),
      key: 'realName',
      renderCell: ({ member }: MemberIpAddressReportItem) =>
        coercedGet(member, 'realName', '-'),
    },
    title: translate(messages['real-name.text']),
    key: 'realName',
    align: columnAlign.onCenter,
    width: 200,
    render: ({ member }: MemberIpAddressReportItem) =>
      coercedGet(member, 'realName', '-'),
  },
  {
    csvData: {
      label: 'Brand ID',
      key: 'brandId',
      renderCell: ({ member }: MemberIpAddressReportItem) =>
        coercedGet(member, 'brandId', '-'),
    },
    title: 'Brand ID',
    key: 'brandId',
    align: columnAlign.onCenter,
    width: 200,
    render: ({ member }: MemberIpAddressReportItem) =>
      coercedGet(member, 'brandId', '-'),
  },
  {
    csvData: {
      label: 'Platform ID',
      key: 'platformId',
      renderCell: ({ member }: MemberIpAddressReportItem) =>
        coercedGet(member, 'platformId', '-'),
    },
    title: 'Platform ID',
    key: 'platformId',
    align: columnAlign.onCenter,
    width: 200,
    render: ({ member }: MemberIpAddressReportItem) =>
      coercedGet(member, 'platformId', '-'),
  },
  {
    csvData: {
      label: translate(messages['ip-address.text']),
      key: 'ipAddress',
      renderCell: ({ ipAddress }: MemberIpAddressReportItem) => ipAddress,
    },
    title: translate(messages['ip-address.text']),
    dataIndex: 'ipAddress',
    key: 'ipAddress',
    align: columnAlign.onCenter,
    width: 100,
    render: (e: string) => e,
  },
  {
    csvData: {
      label: translate(messages['ip-address-location.text']),
      key: 'ipAddressLocation',
      renderCell: ({ ipAddressLocation }: MemberIpAddressReportItem) =>
        `${coercedGet(ipAddressLocation, 'city', '')}${
          coercedGet(ipAddressLocation, 'city', '').length ? ',' : ''
        }${coercedGet(ipAddressLocation, 'country', '')}${
          coercedGet(ipAddressLocation, 'country', '').length ? ',' : ''
        }${coercedGet(ipAddressLocation, 'state', '')}`,
    },
    title: translate(messages['ip-address-location.text']),
    dataIndex: 'ipAddressLocation',
    key: 'ipAddressLocation',
    align: columnAlign.onCenter,
    width: 300,
    render: (location: IpAddressLocation) => (
      <IpAddressLocationTableCell location={location} />
    ),
  },
  {
    csvData: {
      label: translate(messages['last-datetime-ip-address-used.text']),
      key: 'lastUsedDateTime',
      renderCell: ({ lastUsedDateTime }: MemberIpAddressReportItem) =>
        moment(lastUsedDateTime).format(`${DATE_TIME_FORMAT} A`),
    },
    title: translate(messages['last-datetime-ip-address-used.text']),
    dataIndex: 'lastUsedDateTime',
    key: 'lastUsedDateTime',
    align: columnAlign.onCenter,
    width: 200,
    render: (e: string) => moment(e).format(`${DATE_TIME_FORMAT} A`),
  },
  {
    csvData: {
      label: translate(messages['total-count-ip-address-used.text']),
      key: 'usageCount',
      renderCell: ({ usageCount }: MemberIpAddressReportItem) => usageCount,
    },
    title: translate(messages['total-count-ip-address-used.text']),
    key: 'usageCount',
    align: columnAlign.onCenter,
    width: 100,
    render: ({
      usageCount,
      member: { id },
      ipAddress,
    }: MemberIpAddressReportItem) => (
      <ALink onClick={() => handleRedirectByFilter({ id, ipAddress })}>
        {usageCount}
      </ALink>
    ),
  },
];

export default getColumns;
