import gql from 'graphql-tag';

export const CREATE_WITHDRAWAL_PROVIDER = gql`
  mutation createWithdrawalProvider($input: CreateWithdrawalProviderInput!) {
    createWithdrawalProvider(input: $input)
  }
`;

export const DELETE_WITHDRAWAL_PROVIDER = gql`
  mutation deleteWithdrawalProvider($id: ID!) {
    deleteWithdrawalProvider(id: $id)
  }
`;

export const UPDATE_WITHDRAWAL_PROVIDER = gql`
  mutation updateWithdrawalProvider(
    $id: ID!
    $input: UpdateWithdrawalProviderInput!
  ) {
    updateWithdrawalProvider(id: $id, input: $input)
  }
`;

export default {};
