import gql from 'graphql-tag';

export const AFFILIATE_PROGRAMMES = gql`
  query AffiliateProgrammes($filter: AffiliateProgrammesFilterInput) {
    affiliateProgrammes(filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
