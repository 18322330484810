export const statusOptions = [
  {
    value: 'UNSETTLED',
    label: 'Unsettled',
  },
  {
    value: 'SETTLED',
    label: 'Settled',
  },
  {
    value: 'WIN',
    label: 'Win',
  },
  {
    value: 'LOSS',
    label: 'Loss',
  },
  {
    value: 'DRAW',
    label: 'Draw',
  },
  {
    value: 'CANCELED',
    label: 'Cancelled',
  },
  {
    value: 'CASHOUT',
    label: 'Cashout',
  },
  {
    value: 'REFUNDED',
    label: 'Refunded',
  },
];
