import React from 'react';
import { Tabs } from 'antd';
import useTranslate from 'utils/useTranslate';
import { tabsMsgsNext } from '../../../../messages';
import PermissionTree from './components/PermissionTree';

const { TabPane } = Tabs;

const Permissions = () => {
  const activeTabMsgs = tabsMsgsNext;
  const translate = useTranslate();
  return (
    <div className="mt-6">
      <Tabs defaultActiveKey="1" onChange={(e) => e}>
        {Object.keys(activeTabMsgs).map((key, index) => (
          <TabPane
            tab={translate(activeTabMsgs[key])}
            key={(index + 1).toString()}
          >
            <PermissionTree permission={key} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Permissions;
