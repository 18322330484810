import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import {
  CREATE_DAILY_DEPOSIT_REPORT,
  CREATE_DAILY_PLAYER_ACTIVITY_REPORT,
  CREATE_DAILY_SELF_EXCLUDED_REPORT,
  CREATE_DAILY_TIMEOUT_REPORT,
  CREATE_DAILY_TRANSACTION_REPORT,
  CREATE_DEPOSIT_LIMIT_REPORT,
  CREATE_FULL_CUSTOMER_DUMP,
  CREATE_NEWLY_REGISTERED_REPORT,
  CREATE_PLAYER_STATUS_REPORT,
} from 'graphql/mutations/euReports.mutation';
import {
  DAILY_DEPOSIT_REPORTS,
  DAILY_PLAYER_ACTIVITIES_REPORTS,
  DAILY_SELF_EXCLUDED_REPORT,
  DAILY_TIMEOUT_REPORTS,
  DAILY_TRANSACTION_REPORTS,
  DEPOSIT_LIMIT_REPORTS,
  FULL_CUSTOMER_DUMPS_REPORTS,
  NEWLY_REGISTERED_MEMBERS_REPORTS,
  PLAYER_STATUS_REPORTS,
} from 'graphql/queries/euReports.query';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import { useState } from 'react';

export type Reports =
  | 'DAILY_SELF_EXCLUSION_REPORT'
  | 'DAILY_TIMEOUT_REPORT'
  | 'NEWLY_REGISTERED_MEMBERS_REPORT'
  | 'DAILY_TRANSACTION_REPORT'
  | 'DAILY_DEPOSIT_REPORT'
  | 'FULL_CUSTOMER_DUMPS'
  | 'DEPOSIT_LIMIT_REPORT'
  | 'DAILY_PLAYER_ACTIVITIES_REPORT'
  | 'PLAYER_STATUS_REPORT';

export type ReportData = {
  value: Reports;
  label: string;
};

const mutationsKeyAndNode: Record<Reports, DocumentNode> = {
  DAILY_SELF_EXCLUSION_REPORT: CREATE_DAILY_SELF_EXCLUDED_REPORT,
  DAILY_TIMEOUT_REPORT: CREATE_DAILY_TIMEOUT_REPORT,
  NEWLY_REGISTERED_MEMBERS_REPORT: CREATE_NEWLY_REGISTERED_REPORT,
  DAILY_TRANSACTION_REPORT: CREATE_DAILY_TRANSACTION_REPORT,
  DAILY_DEPOSIT_REPORT: CREATE_DAILY_DEPOSIT_REPORT,
  FULL_CUSTOMER_DUMPS: CREATE_FULL_CUSTOMER_DUMP,
  DEPOSIT_LIMIT_REPORT: CREATE_DEPOSIT_LIMIT_REPORT,
  DAILY_PLAYER_ACTIVITIES_REPORT: CREATE_DAILY_PLAYER_ACTIVITY_REPORT,
  PLAYER_STATUS_REPORT: CREATE_PLAYER_STATUS_REPORT,
};

export const queriesKeyAndNode: Record<Reports, DocumentNode> = {
  DAILY_SELF_EXCLUSION_REPORT: DAILY_SELF_EXCLUDED_REPORT,
  DAILY_TIMEOUT_REPORT: DAILY_TIMEOUT_REPORTS,
  NEWLY_REGISTERED_MEMBERS_REPORT: NEWLY_REGISTERED_MEMBERS_REPORTS,
  DAILY_TRANSACTION_REPORT: DAILY_TRANSACTION_REPORTS,
  DAILY_DEPOSIT_REPORT: DAILY_DEPOSIT_REPORTS,
  FULL_CUSTOMER_DUMPS: FULL_CUSTOMER_DUMPS_REPORTS,
  DEPOSIT_LIMIT_REPORT: DEPOSIT_LIMIT_REPORTS,
  DAILY_PLAYER_ACTIVITIES_REPORT: DAILY_PLAYER_ACTIVITIES_REPORTS,
  PLAYER_STATUS_REPORT: PLAYER_STATUS_REPORTS,
};

export const useReportsMutation = (report: Reports | '') => {
  const { context } = useOperatorHeader();

  const [onReportMutation, { loading }] = useMutation(
    mutationsKeyAndNode[report] || CREATE_DAILY_SELF_EXCLUDED_REPORT,
    {
      context,
    }
  );

  return { callMutation: onReportMutation, loading };
};

export const useSelect = (initialValue: Reports | '') => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    bind: {
      onChange: (e: Reports | '') => setValue(e),
    },
  };
};

export const useReportsLazyQuery = (report: Reports | '') => {
  const [callQuery, { loading, data }] = useLazyQuery(
    queriesKeyAndNode[report] || DAILY_SELF_EXCLUDED_REPORT,
    {
      fetchPolicy: 'network-only',
    }
  );

  return { callQuery, loading, data };
};

export const reportType: Record<Reports, string> = {
  DAILY_SELF_EXCLUSION_REPORT: 'dailySelfExcludedReports',
  DAILY_TIMEOUT_REPORT: 'dailyTimeOutReports',
  NEWLY_REGISTERED_MEMBERS_REPORT: 'newlyRegisteredMembersReports',
  DAILY_TRANSACTION_REPORT: 'dailyTransactionReports',
  DAILY_DEPOSIT_REPORT: 'dailyDepositReports',
  FULL_CUSTOMER_DUMPS: 'fullCustomerDumps',
  DEPOSIT_LIMIT_REPORT: 'depositLimitReports',
  DAILY_PLAYER_ACTIVITIES_REPORT: 'dailyPlayerActivities',
  PLAYER_STATUS_REPORT: 'playerStatusReports',
};

export const euReportsOptions: ReportData[] = [
  {
    value: 'DAILY_SELF_EXCLUSION_REPORT',
    label: 'Daily Self-Exclusion Report',
  },
  { value: 'DAILY_TIMEOUT_REPORT', label: 'Daily Time-out Report' },
  {
    value: 'NEWLY_REGISTERED_MEMBERS_REPORT',
    label: 'Newly Registered Members Report',
  },
  { value: 'DAILY_TRANSACTION_REPORT', label: 'Daily Transaction Report' },
  { value: 'DAILY_DEPOSIT_REPORT', label: 'Daily Deposit Report' },
  { value: 'FULL_CUSTOMER_DUMPS', label: 'Full Customer Dumps' },
  { value: 'DEPOSIT_LIMIT_REPORT', label: 'Deposit Limit Report' },
  {
    value: 'DAILY_PLAYER_ACTIVITIES_REPORT',
    label: 'Daily Player Activities Report',
  },
  {
    value: 'PLAYER_STATUS_REPORT',
    label: 'Player Status Report',
  },
];
