import React from 'react';
import { Select } from 'antd';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { GameTypes } from 'constants/gameTypes';

const { Option } = Select;

type Props = {
  onChange: (e: Array<string> | string) => void;
  value: Array<string> | string;
  placeHolder?: string;
  disabled?: boolean;
  multiple?: boolean;
};

const GameTypesSelect: React.FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled,
  multiple,
}) => {
  const translate = useTranslate();

  const handleChange = (e: string | Array<string>) => {
    onChange(e);
  };

  return (
    <Select
      value={value || undefined}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      placeholder={placeHolder || ''}
      onChange={handleChange}
      filterOption={false}
      disabled={disabled || false}
      allowClear
      style={{ width: '100%' }}
    >
      {// eslint-disable-next-line no-shadow
      Object.keys(GameTypes).map((gameType: string, key: number) => (
        <Option key={key} value={gameType}>
          {translate(messages[`${gameType}`])}
        </Option>
      ))}
    </Select>
  );
};

export default GameTypesSelect;
