import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import { TabId } from 'constants/route';
import coercedGet from 'utils/coercedGet';
import RebateMemberPayoutRecords from './RebateMemberPayoutRecords';
import PromoMemberPayoutRecords from './PromoMemberPayoutRecords';

const MemberPayoutRecords = () => {
  const { getTab, isTabActive } = useScreenTabV2() as any;

  const isActiveTab = isTabActive(TabId.TOTAL_NUMBER_OF_PARTICIPATING_MEMBER);
  const tab = getTab(TabId.TOTAL_NUMBER_OF_PARTICIPATING_MEMBER);

  const rebateGroup = coercedGet(tab, 'data.rebateGroupId', '');
  const promoGroup = coercedGet(tab, 'data.promoGroupId', '');

  return rebateGroup !== '' ? (
    <RebateMemberPayoutRecords id={rebateGroup} isActiveTab={isActiveTab} />
  ) : (
    <PromoMemberPayoutRecords id={promoGroup} isActiveTab={isActiveTab} />
  );
};

export default MemberPayoutRecords;
