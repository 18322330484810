import React, { useState } from 'react';
import { Select } from 'antd';
import { StyledSelect } from 'styles';
import { MessagesConnectionEdge } from 'types/graphqlTypes';
import { useLazyQuery } from '@apollo/react-hooks';
import { sortedUniq } from 'lodash';
import { FilterItem } from 'components/FilterItem/FilterItem';
import { MESSAGE_CREATORS } from './queries';

const { Option } = Select;

interface IOperatorFilter {
  values: string[];
  setFilters: (e: { in: string[] } | null) => any;
}

interface IOptions {
  id: string;
  role: string;
  username: string;
}

export const OperatorFilter = ({ values, setFilters }: IOperatorFilter) => {
  const [options, setOptions] = useState<IOptions[]>([]);

  const [loadMessageCreators, { called, loading }] = useLazyQuery(
    MESSAGE_CREATORS,
    {
      fetchPolicy: 'network-only',
      variables: {
        first: 1000,
      },
      onCompleted: (data) => {
        const {
          messages: { edges = [] },
        } = data;

        const messageCreators = edges.map(
          ({ node }: MessagesConnectionEdge) => node.creator
        );

        setOptions(messageCreators);
      },
    }
  );

  const handleChange = (e: string[]) => {
    if (e.length) {
      setFilters({ in: e });
    } else {
      setFilters(null);
    }
  };

  if (!called) loadMessageCreators();

  return (
    <FilterItem label="Created By" onClear={() => handleChange([])}>
      <StyledSelect
        mode="multiple"
        showSearch
        placeholder="Enter Creator"
        value={values}
        onChange={handleChange}
        allowClear
        loading={loading}
      >
        {sortedUniq(options).map(({ id, username }) => (
          <Option key={id} value={id}>
            {username}
          </Option>
        ))}
      </StyledSelect>
    </FilterItem>
  );
};

export default OperatorFilter;
