import React from 'react';
import * as moment from 'moment-timezone';
import { useReportsTimezone } from 'contexts/ReportsTimezone';
import { FilterOutlined } from '@ant-design/icons';
// import messages from 'messages';
import { Tooltip } from 'antd';
import useTranslate from 'utils/useTranslate';
import coercedGet from 'utils/coercedGet';
import localMessages from './messages';
import { StyledContent, StyledRow, StyledCol, StyledFilter } from './styles';
import VendorNames from '../VendorNames';
import ClientFilterNames from '../ClientFilterNames';

type Props = {
  node: Record<string, any>;
};

const FilterStatus: React.FC<Props> = ({ node }) => {
  const translate = useTranslate();
  const filter = coercedGet(node, 'filter', {});

  const { currentTimezone }: any = useReportsTimezone();
  const dateTimeProcGte = coercedGet(filter, 'dateTimeProcessed.gte', null);
  const dateTimeProcLte = coercedGet(filter, 'dateTimeProcessed.lte', null);

  const vendorFilts = coercedGet(filter, 'vendor.in', []);
  const clientFilts = coercedGet(filter, 'admin.in', []);

  const getToolTipComponent = () => (
    <StyledContent>
      <StyledRow>
        <StyledFilter>{translate(localMessages.FILTERS)}</StyledFilter>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          <span>{translate(localMessages.PERIOD)}</span>
        </StyledCol>
        <StyledCol>
          {!dateTimeProcGte && !dateTimeProcLte ? (
            translate(localMessages.NO_DATE_GIVEN)
          ) : (
            <span>
              {moment
                .tz(dateTimeProcGte, currentTimezone)
                .format('YYYY/MM/DD HH:mm')}{' '}
              - <br />
              {moment
                .tz(dateTimeProcLte, currentTimezone)
                .format('YYYY/MM/DD HH:mm')}
            </span>
          )}
        </StyledCol>
      </StyledRow>

      <StyledRow>
        <StyledCol>{translate(localMessages.CLIENT_ACCOUNTS)}</StyledCol>
        <StyledCol>
          <ClientFilterNames adminIds={clientFilts} />
        </StyledCol>
      </StyledRow>
      {/*  CLIENT CATEGORY HERE */}

      <StyledRow>
        <StyledCol>{translate(localMessages.TIMEZONE_SELECTION)}</StyledCol>
        <StyledCol>{coercedGet(node, 'timezone', '-')}</StyledCol>
      </StyledRow>

      <StyledRow>
        <StyledCol>{translate(localMessages.MEMBER_ID)}</StyledCol>
        <StyledCol>-</StyledCol>
      </StyledRow>

      <StyledRow>
        <StyledCol>{translate(localMessages.VENDOR)}</StyledCol>
        <StyledCol>
          {' '}
          <VendorNames vendorIds={vendorFilts} />
        </StyledCol>
      </StyledRow>
    </StyledContent>
  );

  return (
    <Tooltip placement="bottom" title={getToolTipComponent()}>
      <FilterOutlined className="ml-2" />
    </Tooltip>
  );
};

export default FilterStatus;
