import React, { useEffect, useState } from 'react';
import navMessages from 'constants/navMessages';
import {
  StyledCardContainer,
  StyledTabPane,
} from 'pages/components/ScreenTab/styles';
import useEventListener from '@use-it/event-listener';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
// import { useClearCacheCtx } from 'react-clear-cache';

import cx from 'classnames';
import { TabIdSA } from 'SuperAdminMain/constants/route';
import { customFormatMessage } from 'utils/customFormatMessage';
import { ErrorHandler } from 'components/ErrorHandler';
import {
  Tab,
  ScreenTabState,
  setActiveTab,
  removeTab,
} from '../../store/SuperAdminScreenTabState/screenTabState';
import { TabComponent } from './components/SuperAdminTabComponent';
import DraggableTabs from './components/DraggableTabs';

export const SuperAdminScreenTabs: React.FC = () => {
  // const { isLatestVersion } = useClearCacheCtx();
  const [tabsSameSize, setTabsSameSize] = useState(false);

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { tabs, activeKey } = useSelector(
    ({ superAdminScreenTab }: { superAdminScreenTab: ScreenTabState }) =>
      superAdminScreenTab
  );

  useEventListener('resize', () => {
    const tabContainer = document.querySelector(
      '.screen-tab .ant-tabs-nav-wrap'
    );
    const tabWrapper = document.querySelector('.screen-tab .ant-tabs-nav');
    if (!tabContainer || !tabWrapper) return;
    if (
      tabWrapper.clientWidth >= tabContainer.clientWidth &&
      tabs.length <= 6
    ) {
      setTabsSameSize(true);
    } else {
      setTabsSameSize(false);
    }
  });

  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [tabs]);

  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);

  // const getTabName = (tab: Tab) =>
  //   translate(
  //     navMessages[`${tab.state.translateKeyId || tab.id}.text`],
  //     tab.state.translateOpts
  //   );

  const getTabName = (tab: Tab) =>
    tab.memberId && !tab.state.translateOpts
      ? tab.state.username
      : translate(
          navMessages[`${tab.state.translateKeyId || tab.id}.text`],
          tab.state.translateOpts
        );

  const handleChangeTab = (key: string) => {
    dispatch(setActiveTab(key));
  };

  const handleRemoveTab = (
    key: string | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    dispatch(removeTab(key.toString()));
  };

  return tabs.length ? (
    <StyledCardContainer
      className="screen-tab"
      tabsSameSize={tabsSameSize}
      updateAvailable={false}
    >
      <DraggableTabs
        type={tabs.length > 1 ? 'editable-card' : 'card'}
        activeKey={activeKey}
        onChange={handleChangeTab}
        onEdit={handleRemoveTab}
        hideAdd
      >
        {tabs.map((tab: Tab) => (
          <StyledTabPane
            tab={
              <div className={cx('d-inline-block', { 'd-flex': tabsSameSize })}>
                <div className={cx({ truncate: tabsSameSize })}>
                  {getTabName(tab)}
                </div>
              </div>
            }
            key={tab.key}
            forceRender={tab.id !== TabIdSA.CLIENT_PROFILE}
          >
            <ErrorHandler>
              <TabComponent
                id={tab.id}
                {...(tab?.memberId && { accountId: tab?.memberId })}
              />
            </ErrorHandler>
          </StyledTabPane>
        ))}
      </DraggableTabs>
    </StyledCardContainer>
  ) : null;
};
