import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import translations from 'translations';

export default connect((state: any) => {
  const { locale } = state.account;
  return {
    locale,
    messages: translations[locale],
  };
})(IntlProvider) as any;
