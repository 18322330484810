import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import Layout from 'components/Layout';
import { ScrollableFilter } from 'components/Layout/LeftSidebar/style';
import { useFilterValues } from 'contexts/Filters';
import React from 'react';
import { QuickSearchIds } from 'components/QuickSearchFilter/QuickSearchFilter';
import SearchSettingsForm from '../SearchSettingsForm';
import FilterItems from '../FilterItems';

const Sidebar = () => {
  const {
    filters,
    setPersistedFilters,
    handleFilterChange,
  } = useFilterValues() as any;

  return (
    <Layout.Sidebar
      quickSearch={{
        filters,
        onFilterChange: setPersistedFilters,
        contextKey: 'vip',
        isNext: true,
        quickSearchId: QuickSearchIds.MEMBER_LOYALTY_MANAGEMENT,
        searchSettingsForm: SearchSettingsForm,
      }}
    >
      <ScrollableFilter>
        <Form layout="vertical">
          <FilterItems
            filters={filters}
            onRawFilterChange={handleFilterChange}
          />
        </Form>
      </ScrollableFilter>
    </Layout.Sidebar>
  );
};

export default Sidebar;
