import React from 'react';
import { useScreenTabV2 } from 'store/screenTabState';
import { Dropdown, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useMember } from 'pages/components/NewMemberDetail/memberContext';
import { ALink } from 'components/ALink/ALink';

export const Messages = ({ value }: { value: number }) => {
  const { addTab } = useScreenTabV2();

  const { member } = useMember() as Record<string, any>;
  const { id, username } = member;

  const handleClick = () => {
    addTab({
      id: 'manual-message',
      state: {
        memberFilter: {
          targetMembers: { in: [{ id, username, __typename: 'Member' }] },
        },
      },
    });
  };

  return (
    <span>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="1">
              <FormattedMessage
                id="open-in-new-browser-tab.text"
                defaultMessage="Open in new browser tab"
              />
            </Menu.Item>
          </Menu>
        }
        trigger={['contextMenu']}
      >
        <ALink className="fs-12" onClick={() => handleClick()}>
          {value || 0}{' '}
          <FormattedMessage
            id="member.messages.text"
            defaultMessage="Messages"
          />
        </ALink>
      </Dropdown>
    </span>
  );
};
