import React, { useEffect } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import moment from 'moment';
import { useCustomColumnsV2 } from 'store/customColumnState/customColumnState';
import { ALink } from 'components/ALink/ALink';
import { StyledTable, StyledTableContainer } from 'constants/styles';
import { useSpreadsheetContext } from 'contexts/Spreadsheet';
import { usePermissions } from 'store/accountState';
import ALL_PERMISSIONS from 'constants/permissions3';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import coercedGet from 'utils/coercedGet';
import useTranslate from 'utils/useTranslate';
import { columnAlign } from 'utils/tableAlignment';
import { split, startCase } from 'lodash';
import { showPermissionError } from 'components/Navbar/Navbar';
import { DATE_TIME_FORMAT } from 'constants/date';
import columns from './columns';
import messages from '../../../../messages';

type Props = {
  permissionGroups: any[];
  toggleEdit: (id: string) => void;
  toggleDuplicate: (id: string) => void;
  toggleDelete: (id: string) => void;
  loading: boolean;
};

const PermissionGroupsTable = ({
  permissionGroups,
  toggleEdit,
  toggleDuplicate,
  toggleDelete,
  loading,
}: Props) => {
  const { role, permissions } = usePermissions();

  const {
    ALLOWED_LIST,
    ALLOWED_EDIT,
    ALLOWED_DELETE,
    ALLOWED_DUPLICATE,
  } = collectPermissions(
    role,
    permissions,
    ['LIST', 'EDIT', 'DELETE', 'DUPLICATE'],
    ALL_PERMISSIONS.ALL_OPERATORS.OPERATORS_PERMISSION_GROUP
  );

  const data = permissionGroups
    ? permissionGroups.map(({ node }) => ({ ...node, key: node.id }))
    : [];
  const translate = useTranslate();

  const { setTableColumns } = useSpreadsheetContext();

  const formatPermissionMsg = (text: string) => {
    const permissionArr = split(text, ':');
    const slicedPermissionArr =
      permissionArr.length > 1 ? permissionArr.slice(-2) : permissionArr;

    const newPermissionString = slicedPermissionArr.join('_');

    return startCase(newPermissionString);
  };

  const tableData = data.map((permissionGroup) => ({
    ...permissionGroup,
    permissions: permissionGroup.permissions.map((permission: string) =>
      formatPermissionMsg(permission)
    ),
  }));

  const newColumns = [
    {
      csvData: {
        label: translate(messages.serialcode),
        key: 'serialCode',
        renderCell: (permissionGroup: Record<string, any>) =>
          coercedGet(permissionGroup, 'serialCode', ''),
      },
      disabled: true,
      key: 'serialCode',
      title: translate(messages.serialcode),
      width: 50,
      align: columnAlign.onCenter,
      render: (permissionGroup: Record<string, any>) => (
        <ALink
          onClick={() => {
            if (!ALLOWED_EDIT) {
              showPermissionError('Permission Group - Edit');
              return;
            }
            toggleEdit(permissionGroup.id);
          }}
        >
          {permissionGroup.serialCode}
        </ALink>
      ),
    },
    {
      csvData: {
        label: translate(messages.datetimeupdated),
        key: 'dateTimeUpdated',
        renderCell: ({ dateTimeUpdated }: { dateTimeUpdated: string }) =>
          moment(dateTimeUpdated).format(`${DATE_TIME_FORMAT} A`),
      },
      key: 'dateTimeUpdated',
      title: translate(messages.datetimeupdated),
      width: 100,
      align: columnAlign.onCenter,
      dataIndex: 'dateTimeUpdated',
      render: (dateTimeUpdated: string) =>
        moment(dateTimeUpdated).format(`${DATE_TIME_FORMAT} A`),
    },
    {
      csvData: {
        label: translate(messages.name),
        key: 'name',
        renderCell: (permissionGroup: Record<string, any>) =>
          coercedGet(permissionGroup, 'name', ''),
      },
      key: 'name',
      title: translate(messages.name),
      width: 100,
      align: columnAlign.onCenter,
      render: (permissionGroup: Record<string, any>) => (
        <ALink
          onClick={() => {
            if (!ALLOWED_EDIT) {
              showPermissionError('Permission Group - Edit');
              return;
            }
            toggleEdit(permissionGroup.id);
          }}
        >
          {permissionGroup.name}
        </ALink>
      ),
    },
    ...columns(),
    {
      key: 'actions',
      title: translate(messages.action),
      dataIndex: 'id',
      width: 50,
      align: columnAlign.onCenter,
      render: (id: string) =>
        (ALLOWED_EDIT || ALLOWED_DUPLICATE || ALLOWED_DELETE) && (
          <Dropdown
            overlay={
              <Menu>
                {ALLOWED_EDIT && (
                  <Menu.Item
                    onClick={() => {
                      toggleEdit(id);
                    }}
                  >
                    {translate(messages.edit)}
                  </Menu.Item>
                )}
                {ALLOWED_DUPLICATE && (
                  <Menu.Item
                    disabled={false}
                    onClick={() => {
                      toggleDuplicate(id);
                    }}
                  >
                    {translate(messages.duplicate)}
                  </Menu.Item>
                )}
                {ALLOWED_DELETE && (
                  <Menu.Item
                    disabled={false}
                    onClick={() => {
                      toggleDelete(id);
                    }}
                  >
                    {translate(messages.delete)}
                  </Menu.Item>
                )}
              </Menu>
            }
            placement="bottomRight"
          >
            <Button type="link" icon={<EllipsisOutlined />} />
          </Dropdown>
        ),
    },
  ];

  const { filterColumns } = useCustomColumnsV2('permission-group', newColumns);

  useEffect(() => {
    setTableColumns(newColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    ALLOWED_LIST && (
      <StyledTableContainer>
        <StyledTable
          columns={filterColumns(newColumns)}
          size="middle"
          dataSource={tableData}
          pagination={false}
          loading={loading}
        />
      </StyledTableContainer>
    )
  );
};

export default PermissionGroupsTable;
