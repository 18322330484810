import React from 'react';
import coercedGet from 'utils/coercedGet';
import { Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { TableErrorCell } from 'components/TableErrorCell/TableErrorCell';

import { CLIENT_NAME } from './query';

type Props = {
  adminIds: string[];
};

const ClientFilterNames: React.FC<Props> = ({ adminIds }) => {
  const id = adminIds[0];

  const { data, loading, error } = useQuery(CLIENT_NAME, {
    variables: {
      id,
    },
    context: { shouldBatch: true },
  });

  if (loading)
    return (
      <div className="styled-center">
        <Spin />
      </div>
    );
  if (error) {
    return <TableErrorCell errorMessage={error.message} />;
  }

  return <span>{coercedGet(data, 'admin.username', null) || ''}</span>;
};

export default ClientFilterNames;
