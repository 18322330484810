import { defineMessages } from 'react-intl';

export default defineMessages({
  'rebate-programme-name.text': {
    id: 'rebate-programme-name.text',
    defaultMessage: 'Rebate Programme Name',
  },
  'payout-time-set.text': {
    id: 'payout-time-set.text',
    defaultMessage: 'Payout Time Set',
  },
  'settlement-method.text': {
    id: 'settlement-method.text',
    defaultMessage: 'Settlement Method',
  },
  'qualifying-vip-level.text': {
    id: 'qualifying-vip-level.text',
    defaultMessage: 'Qualifying VIP Level',
  },
  'rebate-programme-period.text': {
    id: 'rebate-programme-period.text',
    defaultMessage: 'Rebate Programme Period',
  },
  'total-number-of-participating-member.text': {
    id: 'total-number-of-participating-member.text',
    defaultMessage: 'Total Number of Participating Member',
  },
  'total-number-of-qualifying-member.text': {
    id: 'total-number-of-qualifying-member.text',
    defaultMessage: 'Total Number of Qualifying Members',
  },
  'auto-payout.text': {
    id: 'auto-payout.text',
    defaultMessage: 'Auto Payout',
  },
  'total-amount-payout.text': {
    id: 'total-amount-payout.text',
    defaultMessage: 'Total Amount Payout',
  },
  'total-amount-to-be-payout.text': {
    id: 'total-amount-to-be-payout.text',
    defaultMessage: 'Total Amount to be Payout',
  },
  'total-number-of-user-claimed.text': {
    id: 'total-number-of-user-claimed.text',
    defaultMessage: 'Total Number of User Claimed',
  },
  'total-amount-claimed.text': {
    id: 'total-amount-claimed.text',
    defaultMessage: 'Total Amount Claimed',
  },
  'amount-expired.text': {
    id: 'amount-expired.text',
    defaultMessage: 'Amount Expired',
  },
  'clawback.text': {
    id: 'clawback.text',
    defaultMessage: 'Clawback',
  },
  'not-available.text': {
    id: 'not-available.text',
    defaultMessage: 'Not Available',
  },
  LIVE: {
    id: 'rebates.live-rebate.text',
    defaultMessage: 'Live rebate',
  },
  PERIODIC: {
    id: 'periodic-rebate.text',
    defaultMessages: 'Periodic Rebate',
  },
  YES: {
    id: 'YES',
    defaultMessage: 'Yes',
  },
  NO: {
    id: 'NO',
    defaultMessage: 'No',
  },
  'not-set.text': {
    id: 'not-set.text',
    defaultMessage: 'Not Set',
  },
});
