import React from 'react';
import { Table } from 'antd';
import { ApolloError } from 'apollo-client';
import { MemberAccessSummaryReportItem } from 'types/graphqlTypes';
import { StyledTable, StyledTableContainer } from 'constants/styles';

type Props = {
  members: MemberAccessSummaryReportItem[];
  loading: boolean;
  columns: any[];
  error: ApolloError | undefined;
};

const MemberAccessSummaryTable = ({ members, loading, columns }: Props) => {
  const { Column } = Table;
  return (
    <StyledTableContainer>
      <StyledTable
        size="middle"
        rowKey="id"
        data-testid="container"
        dataSource={members}
        loading={loading}
        pagination={false}
      >
        {columns?.map((column, idx) => (
          <Column
            title={column?.title}
            dataIndex={column?.dataIndex}
            align={column?.align}
            width={column?.width}
            key={idx}
            render={column?.render}
          />
        ))}
      </StyledTable>
    </StyledTableContainer>
  );
};

export default MemberAccessSummaryTable;
