import { allGameTypes } from 'constants/gameTypes';

export const initQualificationCriteria = {
  type: 'BASE',
  and: [
    {
      type: 'DEPOSIT',
      amount: 0,
    },
    {
      type: 'EFFECTIVE_BET',
      amount: 0,
      gameTypes: [...allGameTypes],
      isSingleObject: true,
    },
  ],
};
