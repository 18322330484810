import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spin, Typography } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import esGet from 'utils/esGet';
import useWindowWidth from 'hooks/useWindowWidth';
import { useConfig } from 'hooks/useConfig';
import { GET_MBR_SUMMARY } from 'graphql/queries/memberBetRecords.query';
import { useDatePresets } from 'hooks/useDatePresets';
import { MemberBetRecordSummary } from 'types/graphqlTypes-row';
import isRow from 'utils/isRow';
import { currencies } from 'constants/currency';

const TotalValue = ({
  totalKey,
  value,
  isBankNote = false,
  currency,
}: {
  totalKey: ReactElement;
  value: number;
  isBankNote?: boolean;
  currency?: string | null;
}) => {
  const IsSmallScreenSize = useWindowWidth() <= 1300;
  const { addCurrency, renderNumeral } = useConfig();
  return (
    <div className="mr-3">
      <Typography.Text
        className="d-block"
        style={{ fontSize: IsSmallScreenSize ? '10px' : '13px' }}
      >
        {totalKey}{' '}
      </Typography.Text>

      {!isRow ? (
        <Typography.Text
          className=" d-block"
          strong
          style={{ fontSize: IsSmallScreenSize ? '10px' : '13px' }}
        >
          {isBankNote ? addCurrency(value, 2) : value}
        </Typography.Text>
      ) : (
        <Typography.Text
          className=" d-block"
          strong
          style={{ fontSize: IsSmallScreenSize ? '10px' : '13px' }}
        >
          {isBankNote
            ? `${currencies[currency!]} ${renderNumeral(value)}`
            : value}
        </Typography.Text>
      )}
    </div>
  );
};

const FooterTotalValues = ({ filter }: { filter: Record<string, any> }) => {
  const { preSetValue } = useDatePresets();
  const { baseCurrency } = useConfig();

  const { data, loading } = useQuery<{
    memberBetRecordSummary: MemberBetRecordSummary;
  }>(GET_MBR_SUMMARY, {
    variables: {
      filter,
    },
    fetchPolicy: 'network-only',
    skip: !preSetValue,
  });
  if (loading) {
    return <Spin />;
  }
  const totalBetAmount = esGet(data?.memberBetRecordSummary.totalBetAmount, 0);
  const totalWinloss = esGet(data?.memberBetRecordSummary.totalWinloss, 0);
  const totalEffectiveBet = esGet(
    data?.memberBetRecordSummary.totalEffectiveBet,
    0
  );
  const totalBaseBetAmount = esGet(
    data?.memberBetRecordSummary.totalBaseBetAmount,
    0
  );
  const totalBaseWinloss = esGet(
    data?.memberBetRecordSummary.totalBaseWinloss,
    0
  );
  const totalBaseEffectiveBet = esGet(
    data?.memberBetRecordSummary.totalBaseEffectiveBet,
    0
  );

  const totalBetsCount = esGet(data?.memberBetRecordSummary.totalBetsCount, 0);
  const totalSettledBetsCount = esGet(
    data?.memberBetRecordSummary.totalSettledBetsCount,
    0
  );

  return (
    <div className="d-flex">
      {isRow ? (
        <>
          <TotalValue
            isBankNote
            currency={baseCurrency}
            totalKey={
              <FormattedMessage
                id="TOTAL_BET_AMOUNT_BASE"
                defaultMessage="Total Bet Amount (Base)"
              />
            }
            value={totalBaseBetAmount}
          />
          <TotalValue
            isBankNote
            currency={baseCurrency}
            totalKey={
              <FormattedMessage
                id="TOTAL_EFFECTIVE_BET_BASE"
                defaultMessage="Total Effective Bet (Base)"
              />
            }
            value={totalBaseEffectiveBet}
          />
          <TotalValue
            isBankNote
            currency={baseCurrency}
            totalKey={
              <FormattedMessage
                id="TOTAL_WIN_LOSS_BASE"
                defaultMessage="Total Win/Loss (Base)"
              />
            }
            value={totalBaseWinloss}
          />
        </>
      ) : (
        <>
          <TotalValue
            isBankNote
            totalKey={
              <FormattedMessage
                id="TOTAL_BET_AMOUNT"
                defaultMessage="Total Bet Amount"
              />
            }
            value={totalBetAmount}
          />
          <TotalValue
            isBankNote
            totalKey={
              <FormattedMessage
                id="TOTAL_EFFECTIVE_BET"
                defaultMessage="Total Effective Bet"
              />
            }
            value={totalEffectiveBet}
          />
          <TotalValue
            isBankNote
            totalKey={
              <FormattedMessage
                id="TOTAL_WIN_LOSS"
                defaultMessage="Total Win/Loss"
              />
            }
            value={totalWinloss}
          />
        </>
      )}

      <TotalValue
        totalKey={
          <FormattedMessage
            id="TOTAL_NO_OF_BETS"
            defaultMessage="Total No. of Bets"
          />
        }
        value={totalBetsCount!}
      />
      <TotalValue
        totalKey={
          <FormattedMessage
            id="TOTAL_NO_OF_BETS_SETTLED"
            defaultMessage="Total No. of Bets Settled"
          />
        }
        value={totalSettledBetsCount!}
      />
    </div>
  );
};

export default FooterTotalValues;
