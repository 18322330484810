import * as Yup from 'yup';
import { set, get } from 'lodash';
import { urlRegex } from 'utils/regex';
import messages from 'messages';
import { TranslateProps } from 'interfaces/translate.interface';

type Rec = Record<string, any>;

export const validationSchema = (translate: TranslateProps) =>
  Yup.object().shape({
    name: Yup.string().required(
      translate(messages['banner-name-required.text'])
    ),
    redirect: Yup.string(),
    url: Yup.string().when('redirect', {
      is: (redirect) => redirect === 'url',
      then: Yup.string()
        .required(translate(messages['url-required.text']))
        .matches(urlRegex, translate(messages['url-validator-field.text'])),
    }),
    promo: Yup.string().when('redirect', {
      is: (redirect) => redirect === 'promo',
      then: Yup.string().required(
        translate(messages['promo-name-required.text'])
      ),
    }),
  });

export const onSubmitOptions = (
  values: Rec,
  existingBanner: Rec | null = null
) => {
  const variables = {
    variables: {
      input: {
        name: values.name,
      },
    },
  };

  if (existingBanner) {
    set(variables, 'variables.id', existingBanner.id);
  }

  if (values.redirect === 'url') {
    set(variables, 'variables.input.link', values.url);
  }

  if (values.redirect === 'promo') {
    set(variables, 'variables.input.promo', values.promo);
  }

  if (values.bannerDesktop || get(existingBanner, 'imageDesktop')) {
    set(variables, 'variables.input.imageDesktop', values.bannerDesktop);
    set(variables, 'variables.input.enabledDesktop', true);
  }

  if (values.bannerMobile || get(existingBanner, 'imageMobile')) {
    set(variables, 'variables.input.imageMobile', values.bannerMobile);
    set(variables, 'variables.input.enabledMobile', true);
  }

  if (values.bannerHorizontal || get(existingBanner, 'imageHorizontal')) {
    set(variables, 'variables.input.imageHorizontal', values.bannerHorizontal);
    set(variables, 'variables.input.enabledHorizontal', true);
  }

  return variables;
};
