import { useCallback } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

type PromoProps = {
  currentActivePromo: string;
};

type PromoState = {
  promos: PromoProps;
};

export const promosSlice = createSlice({
  name: 'promos',
  initialState: {
    currentActivePromo: '',
  },
  reducers: {
    setActivePromo: (state, { payload }: { payload: string }) => ({
      ...state,
      currentActivePromo: payload,
    }),
  },
});

export const usePromo = () => {
  const { actions } = promosSlice;
  const dispatch = useDispatch();
  const promos = useSelector((state: PromoState) => state.promos);
  return {
    setActivePromo: useCallback(
      (payload: string) => dispatch(actions.setActivePromo(payload)),
      [actions, dispatch]
    ),
    promos,
  };
};

export const promoReducer = promosSlice.reducer;
