import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import cx from 'classnames';
import { useDebounce } from 'pages/components/common/hooks/useDebounce';
import { IMemberLevel } from 'pages/components/SystemMessage/ManualMessage/interfaces';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import { MEMBER_LOYALTY_LEVELS } from './queries';
import { StyledOption, StyledSelect } from './styles';
import {
  createLevelObject,
  ILevelObject,
  ILevels,
  IPrograms,
  ISelected,
  Props,
} from './utils';

export const VipMultiSelect: React.FC<Props> = ({
  onChange,
  value,
  disabled,
  truncate = true,
}) => {
  const [opts, setOpts] = useState<ILevelObject[]>();
  const [input, setInput] = useState<string>('');
  const [selected, setSelected] = useState<ISelected>({
    activeItem: [],
    stringValue: [],
  });

  const debouncedInput = useDebounce(input, 500);
  const translate = useTranslate();

  const [loadVIP, { loading, error, data = {} }] = useLazyQuery<any>(
    MEMBER_LOYALTY_LEVELS,
    {
      variables: {
        first: 10,
        input: debouncedInput,
      },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        const levelEdges = get(data, 'memberLoyaltyLevels.edges', []) || [];
        const programEdges =
          get(data, 'memberLoyaltyProgrammes.edges', []) || [];

        const programmes: ILevelObject[] = programEdges
          .map(({ node: { name, levels: localLevels } }: { node: IPrograms }) =>
            localLevels.map(
              (level: IMemberLevel): ILevelObject =>
                createLevelObject(name, level, truncate)
            )
          )
          .reduce((acc: any, cur: any) => [...acc, ...cur], []);

        const levels: ILevelObject[] = (levelEdges || []).map(
          ({ node }: { node: ILevels }): ILevelObject =>
            createLevelObject(node.programme.name, node)
        );

        const combination: ILevelObject[] = [...programmes, ...levels].filter(
          (levelA, index, self) =>
            index === self.findIndex((levelB) => levelA.id === levelB.id)
        );

        setOpts(combination);
      },
    }
  );

  const handleSetActive = (e: any) => {
    const vals = value || [];

    if (e.length > vals.length) {
      const newItem = JSON.parse(e.slice(-1)[0]);
      onChange([...vals, newItem]);
    } else {
      const items = e.map((item: string) => JSON.parse(item).id);
      const currentItems = vals.filter((item: ILevelObject) =>
        items.includes(item.id)
      );
      onChange(currentItems);
    }
  };

  useEffect(() => {
    loadVIP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadVIP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  useEffect(() => {
    setSelected({
      activeItem:
        value?.map((item: ILevelObject, key: number) => (
          <StyledOption
            key={key}
            title={item.fullName}
            value={JSON.stringify(item)}
            style={{ display: 'none' }}
          >
            {item.shortName}
          </StyledOption>
        )) || [],
      stringValue:
        value?.map((item: ILevelObject) => JSON.stringify(item)) || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <StyledSelect
      data-testid={cx({ loading, error, container: !loading && !error })}
      mode="multiple"
      onSearch={(e) => setInput(e)}
      onChange={handleSetActive}
      loading={loading}
      onDropdownVisibleChange={(open) => open && loadVIP()}
      disabled={disabled}
      placeholder={translate(messages['enter-vip-level.text'])}
      filterOption={false}
      onBlur={() => setInput('')}
      allowClear
      showSearch
      value={selected.stringValue}
      activeItems={value || []}
    >
      {opts?.map((item, key) => (
        <StyledOption
          key={key}
          value={JSON.stringify(item)}
          color={item.color}
          title={item.fullName}
        >
          {item.shortName}
        </StyledOption>
      ))}

      {selected.activeItem}
    </StyledSelect>
  );
};
