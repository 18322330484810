import React from 'react';
import styled from 'styled-components';
import { SearchOutlined, SettingOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';

interface Props {}

// eslint-disable-next-line arrow-body-style
export const Search: React.FC<Props> = () => {
  return (
    <Container>
      <div className="search">
        <SearchOutlined className="search-icon" />
        <FormattedMessage id="search.text" defaultMessage="Search" />
      </div>

      <div className="save">
        <ALink className="ml-1" onClick={() => null}>
          <FormattedMessage
            id="save-search.text"
            defaultMessage="Save Search"
          />
        </ALink>
        &nbsp;|&nbsp;
        <ALink onClick={() => null}>
          <SettingOutlined className="setting-icon" />
        </ALink>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;

  .search {
    font-weight: bold;
    color: #000000d9;
    display: flex;

    .search-icon {
      margin-right: 4px;
      margin-top: 3px;
    }
  }

  .setting-icon {
    transform: translateY(1px);
  }
`;
