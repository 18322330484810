import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { translateDefaultMarker } from 'utils/translateDefaultMarker';
import { truncateMemberMarker } from './utils';
import { StyledButtonContainer, StyledButton } from './styles';

const MemberMarkerButton = ({
  color,
  memberMarker,
  onClick,
  isHighlight,
}: {
  color: string;
  memberMarker: string;
  onClick?: (e: any) => void;
  isHighlight?: boolean;
}) => {
  const intl = useIntl();
  const translate = useTranslate();
  const { text } = truncateMemberMarker(
    translateDefaultMarker({ text: memberMarker, intl, translate })
  );
  return (
    <StyledButtonContainer>
      <Tooltip placement="top" title={memberMarker}>
        <StyledButton color={color} onClick={onClick}>
          {text}
        </StyledButton>
      </Tooltip>
      {isHighlight && (
        <CheckCircleFilled
          style={{
            color: 'limegreen',
            right: '-6px',
            top: '-5px',
          }}
          className="position-absolute"
        />
      )}
    </StyledButtonContainer>
  );
};

export default MemberMarkerButton;
