import React from 'react';
import { Empty, Progress } from 'antd';
import { ErrorHandler } from 'components/ErrorHandler';
import { useCsvValues } from 'contexts/Csv';
import { CSVLink } from 'react-csv';
import { StyledCenter, StyledButton } from './styles';

type Props = any;

const ModalBody: React.FC<Props> = ({ fileName, handleQuery, ...props }) => {
  const {
    tableData,
    csvHeader,
    ratioString,
    progPercent,
    isNotStarted,
    emptyServerData,
    loading,
    error,
  } = useCsvValues();

  const generateComplete = progPercent === '100';

  return (
    <ErrorHandler>
      <StyledCenter>
        <div className="styled-center flex flex-direction-column">
          {(emptyServerData || isNotStarted) && !loading ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <>
              <Progress
                className="my-3"
                type="circle"
                percent={Number(progPercent)}
                width={80}
                status={error ? 'exception' : undefined}
              />
              <span className="mb-3">{ratioString}</span>
            </>
          )}

          {generateComplete && (
            <StyledButton disabled={loading}>
              <CSVLink
                {...props}
                data={tableData}
                headers={csvHeader}
                filename={`${fileName}.csv`}
                target="_blank"
              >
                Download CSV
              </CSVLink>
            </StyledButton>
          )}

          {error && (
            <div className="text-danger">
              An error occurred. Please try again.
            </div>
          )}

          <StyledButton
            type="primary"
            className="mt-1"
            loading={loading}
            onClick={handleQuery}
          >
            {error ? 'Retry' : 'Generate New'}
          </StyledButton>
        </div>
      </StyledCenter>
    </ErrorHandler>
  );
};

export default ModalBody;
