/* eslint-disable no-bitwise */
import React from 'react';
import moment from 'moment';
import Numeral from 'numeral';
import { Typography } from 'antd';
import gameTypes from 'constants/gameTypes';
import mergeObjectArrayValues from 'utils/mergeObjectArrayValues';
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const { Text } = Typography;

const AreaChartIndex = ({
  data = {},
  metric,
}: {
  data: Record<string, any>;
  metric: string;
}) => {
  const dataKeys = Object.keys(data);
  const remappedValues = {};
  dataKeys.forEach((dk) => {
    remappedValues[dk] = data[dk].dataPoints;
  });
  const dataSource = mergeObjectArrayValues(remappedValues);
  return (
    <ResponsiveContainer height={300} width="100%">
      <AreaChart
        data={dataSource}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="label"
          tickFormatter={(text: string) => moment(text).format('MMM DD')}
        />
        <YAxis />
        <Tooltip
          formatter={(value: any) => Numeral(value).format('0.00', (n) => ~~n)}
          labelFormatter={(text: any) => (
            <Text strong>
              {text} ({metric})
            </Text>
          )}
        />
        <Legend
          wrapperStyle={{
            paddingTop: '10px',
          }}
        />
        {dataKeys.map((dk, idx) => (
          <Area
            key={idx}
            strokeWidth={2}
            type="monotone"
            dataKey={dk}
            stroke={
              (gameTypes?.find((gt: { type: string }) => gt.type === dk) as any)
                .color
            }
            fillOpacity={0}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartIndex;
