import React from 'react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { useAccount } from 'store/accountState';
import CkEditor from '@ckeditor/ckeditor5-react';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/zh-cn';

type Props = {
  name: string;
  defaultValue?: string;
  onChange?: (key: string, value: string, shouldValidate?: boolean) => void;
};

function RichTextField(props: Props) {
  const { name, defaultValue, onChange } = props;
  const [value, setValue] = React.useState(defaultValue || '');
  const {
    account: { locale },
  } = useAccount();

  const handleChange = (val: string) => {
    if (onChange) {
      onChange(name, val, false);
    }
    setValue(val);
  };

  return (
    <div className="rtf-editor">
      <CkEditor
        editor={DecoupledEditor}
        data={value}
        config={{
          language: {
            ui: locale === 'zh' ? 'zh-cn' : 'en',
          },
          removePlugins: [
            'CKFinder',
            'EasyImage',
            'Image',
            'ImageCaption',
            'ImageStyle',
            'ImageToolbar',
            'ImageUpload',
            'MediaEmbed',
          ],
          link: {
            decorators: {
              openInNewTab: {
                mode: 'manual',
                label: 'Open in a new tab',
                attributes: {
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
              },
            },
          },
        }}
        onInit={(editor: any) => {
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );
        }}
        onChange={(_: any, editor: CKEDITOR.editor) => {
          const data = editor.getData();
          handleChange(data);
        }}
      />
    </div>
  );
}

export default React.memo(RichTextField);
