import gql from 'graphql-tag';

export const CREATE_REBATE_GROUP = gql`
  mutation createRebateGroup($input: CreateRebateGroupInput!) {
    createRebateGroup(input: $input)
  }
`;

export const UPDATE_REBATE_GROUP = gql`
  mutation updateRebateGroup($id: ID!, $input: UpdateRebateGroupInput!) {
    updateRebateGroup(id: $id, input: $input)
  }
`;

export const DUPLICATE_REBATE_GROUP = gql`
  mutation($id: ID!) {
    duplicateRebateGroup(id: $id)
  }
`;

export const DELETE_REBATE_GROUP = gql`
  mutation deleteRebateGroup($id: ID!) {
    deleteRebateGroup(id: $id)
  }
`;

export const SUBMIT_REBATE_GROUP = gql`
  mutation submitRebateGroup($id: ID!) {
    submitRebateGroup(id: $id)
  }
`;

export default {};
