import React from 'react';
import { DynamicObj } from 'interfaces/user.interface';
import useTranslate from 'utils/useTranslate';
import { FilterItem } from 'components/FilterItem/FilterItem';
import WithdrawalMethodsSelect from 'components/WithdrawalMethodsSelect';
import IdBasedSelectFilterField from 'components/IdBasedSelectFilterField';
import coercedGet from 'utils/coercedGet';
import { WITHDRAWAL_PROVIDERS } from 'graphql/queries/withdrawalMethod.query';
import BanksSelect from 'components/BanksSelect';
import globalMessages from 'messages';
import messages from '../../messages';
import PaymentMethodTypeFilter from '../SideBar/components/PaymentMethodTypeFilter';
import NicknameFilter from '../SideBar/components/NicknameFilter';
import StatusFilter from '../SideBar/components/StatusFilter';

type Props = {
  filters: Record<string, any>;
  onRawFilterChange: ({
    name,
    value,
  }: {
    name: string;
    value: DynamicObj | null;
  }) => void;
  isCustomFilter?: boolean;
};

export default ({
  filters,
  onRawFilterChange: handleChange,
  isCustomFilter = false,
}: Props) => {
  const translate = useTranslate();

  return (
    <>
      <FilterItem
        label={translate(messages.withdrawalmethod)}
        onClear={() =>
          handleChange({
            name: 'name',
            value: null,
          })
        }
      >
        <WithdrawalMethodsSelect
          value={filters.name ? filters.name.in : []}
          placeHolder={translate(messages.withdrawalmethodplaceholder)}
          multiple
          labelAsValue
          onChange={(e) =>
            handleChange({
              name: 'name',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>

      <PaymentMethodTypeFilter
        value={filters.type ? filters.type.in || [] : []}
        onChange={(e) =>
          handleChange({
            name: 'type',
            value: e.length ? { in: e } : null,
          })
        }
      />

      <IdBasedSelectFilterField
        testId="withdrawal-method-providers-filter"
        label="Provider"
        query={WITHDRAWAL_PROVIDERS}
        queryConnection="withdrawalProviders"
        filterFieldName="name"
        filters={filters}
        rawFilterValues={coercedGet(filters, 'withdrawalProvider.in', [])}
        isCustomFilter={isCustomFilter}
        onChange={(e) =>
          handleChange({
            name: 'withdrawalProvider',
            value: e?.length ? { in: e } : null,
          })
        }
      />

      <FilterItem
        label={translate(messages.bank)}
        onClear={() =>
          handleChange({
            name: 'bank',
            value: null,
          })
        }
      >
        <BanksSelect
          value={filters.bank ? filters.bank.in || [] : []}
          placeHolder={translate(messages.enterBankName)}
          multiple
          onChange={(e) =>
            handleChange({
              name: 'bank',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>

      <FilterItem
        label={translate(messages.nickname)}
        onClear={() =>
          handleChange({
            name: 'nickname',
            value: null,
          })
        }
      >
        <NicknameFilter
          value={filters.nickname ? filters.nickname.in : []}
          placeHolder={translate(messages.nicknameplaceholder)}
          multiple
          labelAsValue
          onChange={(e) =>
            handleChange({
              name: 'nickname',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>

      <FilterItem label={translate(globalMessages.status)}>
        <StatusFilter
          value={filters.enabled ? filters.enabled.in || [] : []}
          onChange={(e) =>
            handleChange({
              name: 'enabled',
              value: e.length ? { in: e } : null,
            })
          }
        />
      </FilterItem>
    </>
  );
};
