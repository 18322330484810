import { ROLES } from 'constants/permissions3';
import { MessageFormat } from 'types/react-intl';
import { customFormatMessage } from 'utils/customFormatMessage';
import { uniq, flatMapDeep } from 'lodash';
import messages from './messages';

export const allColumns = (formatMessage: MessageFormat) => {
  const translate = (messageVal: any, opts: any = null) =>
    customFormatMessage(formatMessage, messageVal, opts);
  return [
    {
      name: 'serialCode',
      label: translate(messages.serialcode),
    },
    {
      name: 'dateTimeUpdated',
      label: translate(messages.datetimeupdated),
    },
    {
      name: 'name',
      label: translate(messages.name),
    },
    {
      name: 'permissions',
      label: translate(messages.permissions),
    },
    {
      name: 'actions',
      label: translate(messages.action),
    },
  ];
};

export const checkPermission = (
  role: any,
  permissions: any,
  targetPermission: any
) => {
  if (role === ROLES.OPERATOR) {
    return permissions.find((p: any) => p.includes(targetPermission));
  }
  return true;
};

export const collectPermissions = (
  role: any,
  permissions: any,
  collections: any,
  hintPermission: any
) =>
  collections.reduce(
    (acc: any, curr: any) => ({
      ...acc,
      [`ALLOWED_${curr}`]:
        role === ROLES.OPERATOR
          ? !!permissions.find((p: any) => p === `${hintPermission}:${curr}`)
          : true,
    }),
    {}
  );

export const getParentPermission = (data: Array<string[]>) => {
  const rawResult = data?.map((item) => item.filter((i) => !i.includes(':')));

  const result = uniq(flatMapDeep(rawResult));

  return result;
};
