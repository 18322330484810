import styled from 'styled-components';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message } from 'antd';
import { isString } from 'lodash';
import randomstring from 'randomstring';

export const formItemHorizontalLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const formItemVerticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const SelectMultiplePrimary = styled(Form.Item)`
  .ant-select-selection__choice {
    background: #1890ff !important;
    color: white !important;
    border-radius: 16px !important;
    height: 24px;
    font-size: 10px;
  }

  .ant-select-selection__choice__remove {
    color: white !important;
  }
`;

export const SelectMultipleDangerOutline = styled(Form.Item)`
  .ant-select-selection__choice {
    background: #e500000d !important;
    border-radius: 16px !important;
    border: 1px solid #e50000 !important;
    width: 64px;
    height: 24px;
    font-size: 10px;
  }
`;

export const uploadProps = {
  name: 'file',
  multiple: true,
  action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  onChange(info: {
    file: {
      name?: string;
      status?: string;
    };
  }) {
    const { status } = info.file;
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

export const getFileNameFromURL = (url: string) =>
  decodeURIComponent(url).replace(/^.*[\\/]/, '');

export const setDefaultUploadList = (files: Array<any>) =>
  files.map((file: any) => ({
    uid: isString(file) ? randomstring.generate({ length: 3 }) : file.id,
    name: getFileNameFromURL(isString(file) ? file : file.uri),
    status: 'done',
    url: isString(file) ? file : file.uri,
  }));
