import React, { useState } from 'react';
import { useFilterValues } from 'contexts/Filters';
import messages from 'messages';
import { isEqual } from 'lodash';
import { StyledTagContainer } from 'styles';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import useTranslate from 'utils/useTranslate';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { ALink } from 'components/ALink/ALink';
import { useScreenTabV2 } from 'store/screenTabState';
import FilterConditions from './FilterConditions';

const HeaderLeft = () => {
  const [expandedTags, toggleExpandedTags] = useState(false);
  const { defaultValues, resetFilterToDefault, filters } = useFilterValues();
  const translate = useTranslate();

  const ref = React.useRef<HTMLDivElement>(null);

  const { clearTabState } = useScreenTabV2('withdrawal-methods');

  return (
    <div className="d-flex align-items-start flex-g-1">
      <ToggleSidebarHead />
      <StyledTagContainer ref={ref} isExpanded={expandedTags}>
        <span className="ml-1">{translate(messages.FILTER_CONDITIONS)}: </span>
        <FilterConditions />
      </StyledTagContainer>
      <LegacyIconToggle
        forwardedRef={ref}
        filters={filters}
        expandedTags={expandedTags}
        toggleExpandedTags={toggleExpandedTags}
      />
      {!isEqual(filters, defaultValues) && (
        <ALink
          onClick={() => {
            resetFilterToDefault();
            clearTabState();
          }}
          style={{ marginTop: '0.35rem' }}
        >
          {translate(messages.CLEAR_ALL)}
        </ALink>
      )}
    </div>
  );
};

export default HeaderLeft;
