import styled from 'styled-components';

export const Root = styled.div`
  .footer-actions {
    display: flex;
  }
  .form-content {
    max-width: 620px;
    margin: 0 auto;
  }
`;

export default {};
