import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { useMutation } from '@apollo/react-hooks';
import { Button, Divider, Input, message, Radio, Row } from 'antd';
import { useFormik } from 'formik';
import { UPDATE_SITE_CONFIGURATION } from 'graphql/mutations/siteConfiguration.mutation';
import { GET_SITE_CONFIGURATION } from 'graphql/queries/siteConfiguration.query';
import { useConfig } from 'hooks/useConfig';
import TimezoneSelect from 'pages/components/SiteConfigurations/components/TimezoneSelect';
import globalMessages from 'messages';
import React from 'react';
import CustomSwitch from 'components/CustomSwitch';
import SingleFileUploader from 'components/Uploader/SingleFileUploader';
import { useAccount } from 'store/accountState';
import styled from 'styled-components';
import removeNull from 'utils/removeNull';
import removeTypename from 'utils/removeTypename';
import useTranslate from 'utils/useTranslate';
import messages from './messages';
import validationSchema from './validationSchema';

const { TextArea } = Input;
const Spacer = styled(Divider)`
  margin: 12px 0;
`;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12, offset: 1 },
  colon: false,
};

const BoldFormItemLabel = styled.label`
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65);
`;

const Label = styled.span`
  font-size: 14px;
`;

export const handlingFeeTypes = {
  always: 'ALWAYS',
  unmet: 'UNMET',
};

const EditSiteConfiguration = ({
  onSuccess,
  config,
}: {
  onSuccess: () => void;
  config: Record<string, any>;
}) => {
  const translate = useTranslate();
  const {
    account: { locale },
  } = useAccount();
  const { currency } = useConfig();

  const [updateConfig, { loading }] = useMutation(UPDATE_SITE_CONFIGURATION, {
    onCompleted: () => {
      message.success(translate(globalMessages.SITE_CONFIGURATION_UPDATED));
      onSuccess();
    },
    refetchQueries: [
      {
        query: GET_SITE_CONFIGURATION,
      },
    ],
  });

  let timezoneConfig;

  switch (locale) {
    case 'en':
      timezoneConfig = {
        timezone: config?.timezone,
      };
      break;
    case 'zh':
      timezoneConfig = {
        timezone: '亚洲/北京',
      };
      break;
    default:
      timezoneConfig = {};
  }
  const initialValues = {
    ...config,
    ...timezoneConfig,
    withdrawalTaxValue:
      // This will convert to the equivalence of percentage from 0 to 100.
      config.withdrawalTaxType === 'ABSOLUTE'
        ? config.withdrawalTaxValue
        : (config.withdrawalTaxValue || 0) * 100,
  };
  const formikProps = useFormik({
    initialValues,
    onSubmit: (formValues: Record<string, any>, { resetForm }) => {
      const newFormValues = { ...formValues };
      if (formValues.withdrawalTaxType === 'RELATIVE') {
        newFormValues.withdrawalTaxValue =
          (newFormValues.withdrawalTaxValue || 0) / 100;
      }
      const valueProcessed = removeNull(removeTypename(newFormValues));
      delete valueProcessed.cname;

      updateConfig({
        variables: {
          input: valueProcessed,
        },
      }).then(() => resetForm());
    },
    validationSchema: validationSchema(translate),
    enableReinitialize: true,
  });
  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    handleBlur,
    errors,
    isValid,
  } = formikProps;

  const onCompleteUpload = (field: string, file: { id: string }) =>
    setFieldValue(field, file.id);

  return (
    <Form layout="horizontal" onSubmit={handleSubmit}>
      <Form.Item
        {...formItemLayout}
        label={translate(messages.domainname)}
        validateStatus={errors.domainName && 'error'}
        help={errors.domainName || null}
      >
        <Input
          name="domainName"
          value={values.domainName}
          onChange={handleChange}
          placeholder="example.com"
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label={translate(messages.cname)}>
        {config.cname}
      </Form.Item>

      <Form.Item {...formItemLayout} label={translate(messages.description)}>
        <TextArea
          rows={3}
          name="domainDescription"
          value={values.domainDescription}
          onChange={handleChange}
        />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={translate(messages.crtfile)}
        extra={translate(messages.crtfilehelp)}
      >
        <SingleFileUploader
          name="crtFile"
          accept=".crt"
          defaultFiles={values.crtFile || {}}
          data={{
            onCompleteUpload,
          }}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label={translate(messages.privatekey)}>
        <SingleFileUploader
          name="privateKey"
          defaultFiles={values.privateKey || {}}
          data={{
            onCompleteUpload,
          }}
        />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={translate(messages.intermediarycrtfile)}
      >
        <SingleFileUploader
          name="intermediaryCert"
          accept=".crt"
          defaultFiles={values.intermediaryCert || {}}
          data={{
            onCompleteUpload,
          }}
        />
      </Form.Item>

      <Spacer dashed />

      <Form.Item
        {...formItemLayout}
        label={
          <BoldFormItemLabel>{translate(messages.captcha)}</BoldFormItemLabel>
        }
      />

      <Form.Item {...formItemLayout} label={translate(messages.frontend)}>
        <CustomSwitch
          name="captchaEnabledFrontend"
          value={values.captchaEnabledFrontend}
          onChange={(value: boolean) =>
            setFieldValue('captchaEnabledFrontend', value)
          }
          textHelper={{
            true: translate(globalMessages['on.text']),
            false: translate(globalMessages['off.text']),
          }}
          activeColor={null}
          disabled={false}
          textStyle={{}}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label={translate(messages.backend)}>
        <CustomSwitch
          name="captchaEnabledBackend"
          value={values.captchaEnabledBackend}
          onChange={(value: boolean) =>
            setFieldValue('captchaEnabledBackend', value)
          }
          textHelper={{
            true: translate(globalMessages['on.text']),
            false: translate(globalMessages['off.text']),
          }}
          activeColor={null}
          disabled={false}
          textStyle={{}}
        />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={
          <BoldFormItemLabel>
            {translate(messages.googleauth)}
          </BoldFormItemLabel>
        }
      />

      <Form.Item {...formItemLayout} label={translate(messages.backend)}>
        <CustomSwitch
          name="googleAuthEnabledBackend"
          value={values.googleAuthEnabledBackend}
          onChange={(value: boolean) =>
            setFieldValue('googleAuthEnabledBackend', value)
          }
          textHelper={{
            true: translate(globalMessages['on.text']),
            false: translate(globalMessages['off.text']),
          }}
          activeColor={null}
          disabled={false}
          textStyle={{}}
        />
      </Form.Item>

      <Spacer dashed />

      <Form.Item
        {...formItemLayout}
        label={
          <BoldFormItemLabel>
            {translate(messages.walletconfig)}
          </BoldFormItemLabel>
        }
      />

      <Form.Item {...formItemLayout} label={translate(messages.transfer)}>
        <CustomSwitch
          name="transferVendorsEnabled"
          value={values.transferVendorsEnabled}
          onChange={(value: boolean) =>
            setFieldValue('transferVendorsEnabled', value)
          }
          textHelper={{
            true: translate(globalMessages['on.text']),
            false: translate(globalMessages['off.text']),
          }}
          activeColor={null}
          disabled={false}
          textStyle={{}}
        />
      </Form.Item>

      <Form.Item {...formItemLayout} label={translate(messages.seamless)}>
        <CustomSwitch
          name="seamlessVendorsEnabled"
          value={values.seamlessVendorsEnabled}
          onChange={(value: boolean) =>
            setFieldValue('seamlessVendorsEnabled', value)
          }
          textHelper={{
            true: translate(globalMessages['on.text']),
            false: translate(globalMessages['off.text']),
          }}
          activeColor={null}
          disabled={false}
          textStyle={{}}
        />
      </Form.Item>

      <Spacer dashed />
      <Form.Item
        {...formItemLayout}
        labelCol={{ span: 12 }}
        label={
          <BoldFormItemLabel>
            {translate(messages.paymentmethodratecalc)}
          </BoldFormItemLabel>
        }
      />

      <Form.Item
        {...formItemLayout}
        label={translate(messages.paymentmethodtransactions)}
        validateStatus={
          errors.paymentMethodSuccessRateCalculationLastTransactionsCount &&
          'error'
        }
        help={
          errors.paymentMethodSuccessRateCalculationLastTransactionsCount ||
          null
        }
      >
        <Input
          name="paymentMethodSuccessRateCalculationLastTransactionsCount"
          placeholder="10"
          value={
            values.paymentMethodSuccessRateCalculationLastTransactionsCount
          }
          onChange={handleChange}
          type="number"
        />
      </Form.Item>
      <Spacer dashed />

      <Form.Item
        {...formItemLayout}
        label={
          <BoldFormItemLabel>
            {translate(messages.withdrawalcharges)}
          </BoldFormItemLabel>
        }
      />

      <Form.Item
        {...formItemLayout}
        label={translate(messages.turnoverwaiver)}
        className={values.turnoverRequirementWaiverEnabled ? 'mb-0' : ''}
      >
        <CustomSwitch
          name="turnoverRequirementWaiverEnabled"
          value={values.turnoverRequirementWaiverEnabled}
          onChange={(value: boolean) => {
            setFieldValue('turnoverRequirementWaiverEnabled', value);
            setFieldValue('turnoverRequirementWaiverThreshold', 0);
          }}
          textHelper={{
            true: translate(globalMessages['on.text']),
            false: translate(globalMessages['off.text']),
          }}
          activeColor={null}
          disabled={false}
          textStyle={{}}
        />
      </Form.Item>

      {values.turnoverRequirementWaiverEnabled && (
        <Form.Item
          {...formItemLayout}
          label=" "
          validateStatus={errors.turnoverRequirementWaiverThreshold && 'error'}
          help={errors.turnoverRequirementWaiverThreshold || null}
        >
          <Label>{translate(messages.turnoverwaiverhelp)}</Label>
          <Input
            name="turnoverRequirementWaiverThreshold"
            value={values.turnoverRequirementWaiverThreshold}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
            suffix={currency?.symbol}
          />
        </Form.Item>
      )}

      <Form.Item
        {...formItemLayout}
        label={translate(messages.withdrawalfee)}
        validateStatus={errors.withdrawalHandlingFee && 'error'}
        help={errors.withdrawalHandlingFee || null}
      >
        <Input
          name="withdrawalHandlingFee"
          value={values.withdrawalHandlingFee}
          onChange={handleChange}
          onBlur={handleBlur}
          suffix={currency?.symbol}
          type="number"
        />
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={translate(messages.HANDLING_FEE_CHARGING)}
        validateStatus={errors.withdrawalHandlingFee && 'error'}
        help={errors.withdrawalHandlingFee || null}
      >
        <Radio.Group
          className="d-flex mt-1"
          name="withdrawalHandlingFeeType"
          value={values.withdrawalHandlingFeeType}
          onChange={handleChange}
        >
          <Radio value={handlingFeeTypes.always}>
            {translate(messages[handlingFeeTypes.always])}
          </Radio>
          <Radio value={handlingFeeTypes.unmet}>
            {translate(messages[handlingFeeTypes.unmet])}
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        label={translate(messages.withdrawaltax)}
        extra={translate(messages.withdrawaltaxhelp)}
        validateStatus={errors.withdrawalTaxValue && 'error'}
        help={errors.withdrawalTaxValue || null}
      >
        <Radio.Group
          name="withdrawalTaxType"
          value={values.withdrawalTaxType}
          onChange={handleChange}
        >
          <Radio value="ABSOLUTE">{translate(messages.absolute)}</Radio>
          <Radio value="RELATIVE">{translate(messages.relative)}</Radio>
        </Radio.Group>
        <Row>
          <Input
            suffix={
              values.withdrawalTaxType === 'ABSOLUTE'
                ? `${currency?.symbol}`
                : '%'
            }
            name="withdrawalTaxValue"
            value={values.withdrawalTaxValue}
            onChange={handleChange}
            onBlur={handleBlur}
            type="number"
          />
        </Row>
      </Form.Item>

      <Spacer dashed />

      <Form.Item
        {...formItemLayout}
        label={
          <BoldFormItemLabel>{translate(messages.timezone)}</BoldFormItemLabel>
        }
        // colon={false}
        className="mb-0"
      />
      <Form.Item {...formItemLayout} label={translate(messages.timezone)}>
        <TimezoneSelect formik={formikProps} />
      </Form.Item>

      <div className="ant-modal-footer">
        <div>
          <Button
            type="primary"
            loading={loading}
            onClick={handleSubmit as any}
            disabled={!isValid}
          >
            {translate(messages.save)}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default EditSiteConfiguration;
