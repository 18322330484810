import React from 'react';
import { Resizable } from 'react-resizable';

type ResizeableType = {
  onResize: () => void;
  width: number;
  minWidth: number;
};

const ResizeableTitle = (props: ResizeableType) => {
  const { onResize, width, minWidth, ...restProps } = props;

  // console.log(minWidth);
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      minConstraints={[minWidth, minWidth]}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizeableTitle;
