import React from 'react';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import messages from 'messages';
import { useIntl } from 'react-intl';
import { ReactComponent as RefreshIcon } from 'components/assets/refresh-table.svg';

const RefreshTable = ({ onClick, ...rest }: { onClick: () => void }) => {
  const intl = useIntl();
  const { formatMessage } = intl;

  return (
    <Tooltip title={formatMessage(messages['refres-table.text'])}>
      <Icon
        className="mr-2"
        component={RefreshIcon}
        style={{ fontSize: '22px' }}
        onClick={onClick}
        {...rest}
      />
    </Tooltip>
  );
};

export default RefreshTable;
