import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';

type LegacyIconType = {
  correct: boolean | null;
};
const LegacyIconMbr = ({ correct }: LegacyIconType) => (
  <div>
    <LegacyIcon
      type={correct ? 'check' : 'close'}
      style={{
        fontSize: '15px',
        color: correct ? 'green' : 'red',
        top: '7px',
        zIndex: 2,
        right: '5px',
      }}
      className="position-absolute"
    />
  </div>
);

export default LegacyIconMbr;
