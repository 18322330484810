import { useMutation } from '@apollo/react-hooks';
import { Button, Col, Modal, Radio, Row } from 'antd';
import { UPDATE_MARQUEE_SPEED } from 'graphql/mutations/marqueeMessage.mutation';
import { get } from 'lodash';
import messages from 'messages';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAccount } from 'store/accountState';
import useTranslate from 'utils/useTranslate';

type Props = {
  modalStatus: boolean;
  setModalStatus: (e: boolean) => void;
};

const MarqueeMessagesSettingsModal = ({
  modalStatus,
  setModalStatus,
}: Props) => {
  const translate = useTranslate();
  const onCloseModal = () => setModalStatus(false);
  const {
    account: { config },
  } = useAccount();
  const [updateMarqueeSpeed] = useMutation(UPDATE_MARQUEE_SPEED);
  const [speed, setSpeed] = useState('SLOW');

  const onSave = () => {
    updateMarqueeSpeed({
      variables: {
        input: {
          marqueeMessageSpeed: speed,
        },
      },
    }).then(() => onCloseModal());
  };
  const ModalFooter = [
    <Button type="primary" key={1} onClick={onSave}>
      <FormattedMessage id="save.text" defaultMessage="Save" />
    </Button>,
  ];

  useEffect(() => {
    if (config) {
      const marqueeSpeed = get(config, 'marqueeMessageSpeed', null);
      if (marqueeSpeed) {
        setSpeed(marqueeSpeed);
      }
    }
  }, [config]);

  return modalStatus ? (
    <Modal
      title={translate(messages['marquee-message-setting.text'])}
      visible={modalStatus}
      footer={ModalFooter}
      onCancel={onCloseModal}
      width={900}
    >
      <div className="p-1">
        <Row className="d-flex align-items-center">
          <Col span={5} style={{ textAlign: 'right', paddingRight: '20px' }}>
            <FormattedMessage
              id="scrolling-speed.text"
              defaultMessage="Scrolling Speed"
            />
          </Col>
          <Col span={19}>
            <Radio.Group
              value={speed}
              onChange={({ target: { value } }) => setSpeed(value)}
            >
              <Radio.Button value="SLOW">
                <FormattedMessage id="slow.text" defaultMessage="Slow" />
              </Radio.Button>
              <Radio.Button value="MEDIUM">
                <FormattedMessage id="medium.text" defaultMessage="Medium" />
              </Radio.Button>
              <Radio.Button value="FAST">
                <FormattedMessage id="fast.text" defaultMessage="Fast" />
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col span={5} />
          <Col span={19}>
            <div>
              <i>
                <FormattedMessage
                  id="speed-reference-marquee-message.text"
                  defaultMessage="Speed reference"
                />
                - https://www.quackit.com/html/codes/html_marquee_code.cfm
              </i>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

export default MarqueeMessagesSettingsModal;
