import gql from 'graphql-tag';

export const PAYMENT_METHODS = gql`
  query PaymentMethods(
    $first: Int
    $after: Binary
    $filter: PaymentMethodsFilterInput
  ) {
    paymentMethods(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          ... on OfflineBankTransferPaymentMethod {
            id
            name
            remarks
            enabled
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const PROVIDER_INFO = gql`
  query withdrawalProvider($id: ID!) {
    withdrawalProvider(id: $id) {
      id
      name
      type
      ... on MidpayDirectWithdrawalProvider {
        id
        apiKey
        apiKeyPreview
        midPayDirectPaymentGateway {
          id
          name
          credentialFields
          # payment channelds couldn't be fetched from this query and can be only get from query.paymentGateways
        }
        credentialValues
      }
      # TEMPORARILY removed since paymentChannels are available on MidPayDirectDepositProvider only
      # ... on MidpayWithdrawalProvider {
      #   apiKey
      #   apiKeyPreview
      # }
      # ... on DiorpayWithdrawalProvider {
      #   key
      #   keyPreview
      #   merchantCode
      # }
    }
  }
`;

// Payment channels are also known as Payment gateways
export const PAYMENT_CHANNELS = gql`
  query PaymentGateways($input: PaymentGatewaysInput!) {
    paymentGateways(input: $input) {
      ... on PaymentGateway {
        id
        name
      }
      ... on MidPayDirectPaymentGateway {
        id
        name
        credentialFields
        paymentChannels
      }
      ... on DiorPay2PaymentGateway {
        id
        name
        gateway
        paymentChannels
      }
      __typename
    }
  }
`;

export default {};
