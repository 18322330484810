import gql from 'graphql-tag';

export const MEMBER_LEVEL = gql`
  query MemberLevel($id: ID!) {
    member(id: $id) {
      id
      memberLevel {
        id
        name
        default
        color
        status
      }
    }
  }
`;
