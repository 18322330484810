import React from 'react';
import { Button, Row, Col } from 'antd';
import { Formik } from 'formik';
import moment from 'moment';

import { FormattedMessage } from 'react-intl';
import { StyledForm } from './styles';
import validationSchema from './validationSchema';

import Username from '../../../../../Username';
import DateRangeFilter from '../../../../../DateRangeFilter';
import Status from '../../../../../Status';

type Props = {
  initialValues: Record<string, any>;
  onSubmit: (data: Record<string, any>) => void;
  onClose: () => void;
  isLoading: boolean;
};

const SearchSettingsForm = ({
  initialValues,
  onSubmit,
  onClose,
  isLoading,
}: Props) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {({ handleSubmit, setFieldValue, values }) => {
      const derivedValue = {
        ...values,
        ...(values.dateRange.length && {
          dateRange: [moment(values.dateRange[0]), moment(values.dateRange[1])],
        }),
      };
      return (
        <StyledForm onSubmit={handleSubmit}>
          <Row>
            <Col span={12}>
              <Username
                state={derivedValue}
                onChange={(e) => setFieldValue('usernames', e)}
              />
              <DateRangeFilter
                state={derivedValue}
                onChange={(e) => setFieldValue('dateRange', e)}
              />
              <Status
                state={derivedValue}
                onChange={(e) => setFieldValue('status', e)}
              />
            </Col>
          </Row>
          <div className="bt-1 py-4 text-right">
            <Button className="mr-2" onClick={onClose} loading={isLoading}>
              <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
            </Button>{' '}
            <Button type="primary" htmlType="submit" loading={isLoading}>
              <FormattedMessage
                id="save-changes.text"
                defaultMessage="Save changes"
              />
            </Button>
          </div>
        </StyledForm>
      );
    }}
  </Formik>
);

export default SearchSettingsForm;
