import gql from 'graphql-tag';

export const DEPOSIT_REQUESTS = gql`
  query Deposits($first: Int, $after: Binary, $filter: DepositsFilterInput) {
    deposits(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          serialCode
          amount
          account {
            id
            username
            __typename
            platformId
            brandId
            currency
            dateTimeCreated
            affiliate {
              id
              code
            }
            email
            initialEmail
            memberLoyaltyLevels {
              id
              name
              description
              color
              programme {
                id
                name
              }
            }
            memberLevel {
              id
              name
              default
              color
              status
              membersCount
            }
          }
          transactionReference
          manualAdjustment
          processingTime
          status
          currency
          exchangeRate
          baseCurrency
          isCrypto
          amountInBaseCurrency
          processor {
            id
            username
            __typename
          }
          firstDeposit
          dateTimeCreated
          dateTimeProcessed
          remarks

          ... on OfflineBankTransferDeposit {
            transactionReference
          }
          ... on HexoPayDeposit {
            id
            paymentMethod {
              id
              name
            }
            hexoPayUid
            transactionReference
            hexoPayCreditCard {
              firstDigit
              firstSixDigits
              lastFourDigits
              expirationYear
              expirationMonth
            }
          }
          ... on CryptocurrencyDeposit {
            id
            depositorBlockchainAddress
            paymentMethod {
              id
              name
            }
          }
          ... on AstroPayWalletDeposit {
            id
            transactionReference
            paymentMethod {
              id
              name
            }
          }
          ... on PayByAstroPayDeposit {
            id
            transactionReference
            paymentMethod {
              id
              name
            }
          }
          ... on NetellerDeposit {
            id
            transactionReference
            paymentMethod {
              id
              name
            }
            account {
              id
              username
              email
            }
          }
          ... on SkrillDeposit {
            id
            transactionReference
            paymentMethod {
              id
              name
            }
            account {
              id
              username
              email
            }
          }
          __typename
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const DEPOSIT_REQUESTS_CSV_DATA = gql`
  query DepositsCsv($first: Int, $after: Binary, $filter: DepositsFilterInput) {
    deposits(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          serialCode
          firstDeposit
          amount
          status
          remarks
          processingTime
          dateTimeCreated
          dateTimeProcessed
          isCrypto
          account {
            id
            username
            platformId
            brandId
            dateTimeCreated
            email
            initialEmail
            memberLevel {
              id
              name
            }
            memberLoyaltyLevels {
              id
              name
              programme {
                id
                name
              }
            }
            affiliate {
              id
              code
            }
          }
          paymentMethod {
            id
            name
          }
          ... on HexoPayDeposit {
            id
            hexoPayUid
            paymentMethod {
              id
              name
            }
            hexoPayCreditCard {
              firstDigit
              lastFourDigits
              expirationYear
              expirationMonth
            }
          }
          ... on CryptocurrencyDeposit {
            id
            depositorBlockchainAddress
            paymentMethod {
              id
              name
            }
          }
          ... on NetellerDeposit {
            id
            transactionReference
            paymentMethod {
              id
              name
            }
            account {
              id
              username
              email
            }
          }
          ... on SkrillDeposit {
            id
            transactionReference
            paymentMethod {
              id
              name
            }
            account {
              id
              username
              email
            }
          }
        }
      }
    }
  }
`;

export const DEPOSIT = gql`
  query Deposit($id: ID!, $language: Language) {
    deposit(id: $id) {
      id
      serialCode
      amount
      account {
        id
        username
        realName
        email
        initialEmail
        memberLevel {
          id
          name
          color
        }
        tags {
          id
          name
          color
        }
        notes {
          edges {
            node {
              id
              header
              note
            }
          }
        }
        memberLoyaltyLevels {
          id
          name
          color
          programme {
            id
            name
          }
        }
      }
      transactionReference
      manualAdjustment
      processingTime
      paymentMethod {
        id
        name
        remarks
        excludedMemberLevel {
          id
          name
          color
        }
        ... on OfflineBankTransferPaymentMethod {
          bank
          accountNumber
          accountName
          branch
          country
          province
          city
        }
        ... on AlipayPaymentMethod {
          nickname
        }
        ... on WechatPaymentMethod {
          nickname
        }
      }
      status
      processor {
        id
        username
      }
      dateTimeCreated
      dateTimeProcessed
      remarks
      __typename
      ... on HexoPayDeposit {
        id
        hexoPayUid
        paymentMethod {
          id
          name
        }
        hexoPayCreditCard {
          firstDigit
          firstSixDigits
          lastFourDigits
          expirationYear
          expirationMonth
        }
      }
      ... on NetellerDeposit {
        id
        transactionReference
        paymentMethod {
          id
          name
        }
        account {
          id
          username
          email
        }
      }
      ... on SkrillDeposit {
        id
        transactionReference
        paymentMethod {
          id
          name
        }
        account {
          id
          username
          email
        }
      }
      ... on OfflineBankTransferDeposit {
        transactionReference
        depositorBank
        depositorName
        sourceBank
        depositSource {
          id
          logo {
            id
            uri
          }
          displayName(language: $language)
          __typename
        }
      }
      ... on CryptocurrencyDeposit {
        depositorBlockchainAddress
        transactionReference
        cryptoExchangeRate
        amount
        currency
        isCrypto
      }
      ... on AlipayDeposit {
        depositorName
      }
      ... on WechatDeposit {
        depositorName
      }
      __typename
    }
  }
`;

export const MEMBER_NOTES = gql`
  query MemberNotes(
    $first: Int
    $after: Binary
    $filter: MemberNotesFilterInput
  ) {
    memberNotes(first: $first, after: $after, filter: $filter) {
      totalCount
      edges {
        cursor
        node {
          id
          header
          note
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

export const GET_DEPOSIT_SERIAL_CODES = gql`
  query FilterDepositSerialCodes(
    $first: Int
    $after: Binary
    $filter: DepositsFilterInput!
  ) {
    deposits(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          serialCode
        }
      }
    }
  }
`;

export const GET_DEPOSIT_BRAND_IDS = gql`
  query FilterDepositBrandIds(
    $first: Int
    $after: Binary
    $filter: DepositsFilterInput!
  ) {
    deposits(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          account {
            id
            brandId
          }
        }
      }
    }
  }
`;

export const GET_DEPOSIT_PLATFORM_IDS = gql`
  query FilterDepositPlatformIds(
    $first: Int
    $after: Binary
    $filter: DepositsFilterInput!
  ) {
    deposits(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          account {
            id
            platformId
          }
        }
      }
    }
  }
`;

export const GET_DEPOSIT_ACCOUNTS = gql`
  query FilterDepositAccounts($first: Int, $filter: MembersFilterInput) {
    members(first: $first, filter: $filter) {
      edges {
        node {
          id
          username
        }
      }
    }
  }
`;

export default {};
