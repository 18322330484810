import coercedGet from 'utils/coercedGet';
import { omit } from 'lodash';
import { PERIOD_TIME_PATTERN } from 'constants/regex';
import moment from 'moment';
import { CreateAffiliateProgrammeScreens } from './context';
import { getTierRequirement } from '../../constants';

export const AffiliateProgrammeSettlementPeriod = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  BIWEEKLY: 'BIWEEKLY',
};

export const getPrevScreen = (currScreen: string) => {
  const screens = Object.values(CreateAffiliateProgrammeScreens);
  const currScreenIdx = screens.findIndex((screen) => screen === currScreen);
  return screens[currScreenIdx > 0 ? currScreenIdx - 1 : currScreenIdx];
};

export const getNextScreen = (currScreen: string) => {
  const screens = Object.values(CreateAffiliateProgrammeScreens);
  const currScreenIdx = screens.findIndex((screen) => screen === currScreen);
  return screens[
    currScreenIdx < screens.length - 1 ? currScreenIdx + 1 : currScreenIdx
  ];
};

const transformPlatformFeesToHandlers = (
  vendorPlatformFees: Record<string, any>
) =>
  vendorPlatformFees.length
    ? vendorPlatformFees.reduce(
        (acc: Record<string, any>, curr: Record<string, any>) => ({
          ...acc,
          [`${curr.gameType}_${curr.vendor.id}`]: {
            gameType: curr.gameType,
            vendor: curr.vendor.id,
            minimumCharge: curr.minimumCharge,
            maximumCharge: curr.maximumCharge,
            chargeLevels: curr.chargeLevels.map((l: Record<string, any>) =>
              omit(
                {
                  ...l,
                },
                ['__typename']
              )
            ),
            ggrChargeCostSharing:
              !!(
                coercedGet(curr, 'minimumCharge', 0) ||
                coercedGet(curr, 'maximumCharge', 0) ||
                coercedGet(curr, 'chargeLevels', []).length
              ) || false,
            noMinimumCharge: curr.minimumCharge === 0,
            noMaximumCharge: curr.maximumCharge === 0,
          },
        }),
        {}
      )
    : {};

export const transformAffiliateData = (data: Record<string, any>) => {
  if (data.id) {
    const lookup = coercedGet(data, 'levelsRequirement', '');
    const { forActiveMembers, forNetProfit } = getTierRequirement(lookup);

    return omit(
      {
        ...data,
        positiveCarryOverEnabled: !!coercedGet(
          data,
          'positiveCarryOverEnabled',
          false
        ),
        depositTransactionCost:
          coercedGet(data, 'depositTransactionCost', 0) * 100,
        thirdPartyDepositTransactionCost:
          coercedGet(data, 'thirdPartyDepositTransactionCost', 0) * 100,
        withdrawalTransactionCost:
          coercedGet(data, 'withdrawalTransactionCost', 0) * 100,
        thirdPartyWithdrawalTransactionCost:
          coercedGet(data, 'thirdPartyWithdrawalTransactionCost', 0) * 100,
        promoCost: coercedGet(data, 'promoCost', 0) * 100,
        rebateCost: coercedGet(data, 'rebateCost', 0) * 100,
        interestAccountCost: coercedGet(data, 'interestAccountCost', 0) * 100,
        minimumPayoutAccumulationAmount: coercedGet(
          data,
          'minimumPayoutAccumulationAmount',
          0
        ),
        payoutClaimOffsetDuration: coercedGet(
          data,
          'payoutClaimOffsetDuration',
          '0'
        ).replace(/\D/g, ''),
        payoutClaimExpiryDuration: coercedGet(
          data,
          'payoutClaimExpiryDuration',
          '0'
        ).replace(/\D/g, ''),
        payoutExpiryDuration: coercedGet(
          data,
          'payoutExpiryDuration',
          '0'
        ).replace(/\D/g, ''),
        payoutClaimOffsetSelected:
          data.payoutClaimOffsetDuration === '0ms'
            ? '-'
            : coercedGet(data, 'payoutExpiryDuration', 'm').match(
                PERIOD_TIME_PATTERN
              )[0],
        payoutClaimExpirySelected:
          data.payoutClaimExpiryDuration === '0ms'
            ? '-'
            : coercedGet(data, 'payoutExpiryDuration', 'm').match(
                PERIOD_TIME_PATTERN
              )[0],
        payoutExpirySelected: coercedGet(
          data,
          'payoutExpiryDuration',
          'm'
        ).match(PERIOD_TIME_PATTERN)[0],
        noPayoutClaimOffsetDuration: data.payoutClaimOffsetDuration === '0ms',
        noPayoutClaimExpiryDuration: data.payoutClaimExpiryDuration === '0ms',
        noPayoutExpiryDuration: data.payoutExpiryDuration === '0ms',
        tierRequirement:
          data.levelsRequirement !== 'EITHER' &&
          data.levelsRequirement !== 'BOTH'
            ? [`${data.levelsRequirement}`]
            : ['ACTIVE_MEMBERS_COUNT', 'NET_PROFIT'],
        tierRequirementOperator:
          data.levelsRequirement !== 'EITHER' &&
          data.levelsRequirement !== 'BOTH'
            ? 'BOTH'
            : coercedGet(data, 'levelsRequirement', 'BOTH'),
        vendorHandlers: transformPlatformFeesToHandlers(
          data.vendorPlatformFees
        ),
        settlementTime: moment(
          `${new Date()
            .toISOString()
            .split('T')
            .shift()} ${data.settlementTime}`
        ),
        levels: coercedGet(data, 'levels', []).length
          ? data.levels.map((l: Record<string, any>, idx: number) => ({
              ...l,
              maximumActiveMembers:
                forActiveMembers && idx !== data.levels.length - 1
                  ? data.levels[idx + 1].minimumActiveMembersCount
                  : '-',
              maximumNetProfit:
                forNetProfit && idx !== data.levels.length - 1
                  ? data.levels[idx + 1].minimumNetProfit
                  : '-',
              complete: true,
              completedAt: lookup,
              percentage: coercedGet(l, 'percentage', 0) * 100,
            }))
          : [],
        id: data.id,
      },
      ['key', '__typename']
    );
  }
  return {};
};
