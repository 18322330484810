import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { UPDATE_AFFILIATE_PROGRAMME } from 'graphql/mutations/affiliateAgent.mutation';
import { AFFILIATE_PROGRAMMES } from 'graphql/queries/affiliateAgent.query';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ALink } from 'components/ALink/ALink';
import noOp from 'utils/noOp';
import useTranslate from 'utils/useTranslate';
import { AffiliateProgrammeTypes } from '../../utils';

const CustomModal: any = Modal;

const ActivateAffiliateProgramme = ({
  affiliate,
}: {
  affiliate: Record<string, any>;
}) => {
  const [isShow, setShow] = useState(false);
  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    setShow(!isShow);
  };

  const translate = useTranslate();

  const [updateAffiliateProgramme, { loading }] = useMutation(
    UPDATE_AFFILIATE_PROGRAMME,
    {
      onCompleted: () => {
        message.success(
          <span data-testid="success">
            {translate(messages.AFFILIATE_PROGRAMME_ACTIVATE_SUCCESS)}
          </span>
        );
        handleShow();
      },
      onError: () =>
        message.error(
          <span data-testid="error">
            {translate(messages.AFFILIATE_PROGRAMME_ACTIVATE_ERROR)}
          </span>
        ),
    }
  );

  return (
    <>
      <ALink
        onClick={
          affiliate.status === AffiliateProgrammeTypes.INACTIVE
            ? handleShow
            : noOp
        }
        data-testid="activate"
      >
        <FormattedMessage id="activate.text" defaultMessage="Activate" />
      </ALink>
      {isShow && (
        <CustomModal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          cancelButtonProps={{ disabled: loading }}
          okButtonProps={{ loading, 'data-testid': 'confirm' }}
          onCancel={handleShow}
          onOk={() => {
            updateAffiliateProgramme({
              variables: {
                id: affiliate.id,
                input: {
                  enabled: true,
                },
              },
              refetchQueries: [
                {
                  query: AFFILIATE_PROGRAMMES,
                },
              ],
            });
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-activate.text"
                defaultMessage="Confirm Activate"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default ActivateAffiliateProgramme;
