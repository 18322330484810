import React from 'react';
import { Empty } from 'antd';
import { isEmpty } from 'lodash';
import { MemberInfo } from 'store/accountState/utils';
import StarMember from '../StarMember';

export const starredMembersSortBy = {
  dateRegistered: 'dateRegistered',
  memberLevel: 'memberLevel',
  lastAdded: 'lastAdded',
};

type Props = {
  starredMembers: Array<MemberInfo>;
};

const StarredMembers = ({ starredMembers }: Props) => {
  const empty = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <div>
      {!isEmpty(starredMembers)
        ? starredMembers.map((user, index) => (
            <StarMember key={index} user={user} />
          ))
        : empty}
    </div>
  );
};

export default StarredMembers;
