import React, { MutableRefObject } from 'react';
import ColumnsFilterNew from 'components/ColumnsFilter/ColumnsFilterNew';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import TimezoneSelect from 'components/TimezoneSelect';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import SpreadsheetDownload from 'components/SpreadsheetDownload';
import { DynamicObj } from 'interfaces/user.interface';
import RefreshTable from './components/RefreshTable';
import HorizontalRule from './components/HorizontalRule';
import FilterConditions from '../FilterConditions';
import { StyledTagContainer, StyledActionsContainer } from './styles';

type Props = {
  forwardRef:
    | {
        current: {
          refetchTable: () => void;
        };
      }
    | MutableRefObject<null>;

  finalQueryFilter: DynamicObj;
};

const TableHeader = React.forwardRef(
  ({ forwardRef, finalQueryFilter }: Props) => {
    const translate = useTranslate();

    return (
      <div className="d-flex px-3 align-items-center my-3">
        <ToggleSidebarHead />
        <StyledTagContainer>
          <FilterConditions />
        </StyledTagContainer>
        <StyledActionsContainer>
          <TimezoneSelect />
        </StyledActionsContainer>

        <StyledActionsContainer>
          <RefreshTable onClick={() => forwardRef.current?.refetchTable()} />
        </StyledActionsContainer>
        <StyledActionsContainer>
          <HorizontalRule className="mr-2" />
        </StyledActionsContainer>

        <StyledActionsContainer>
          <ColumnsFilterNew
            tabId="member-access-audit-history"
            iconOnly
            checkAll
          />
        </StyledActionsContainer>
        <StyledActionsContainer>
          <SpreadsheetDownload
            iconOnly
            filter={finalQueryFilter}
            filename={translate(messages.MEMBER_ACCESS_AUDIT_HISTORY)}
            extension="csv"
          />
        </StyledActionsContainer>
      </div>
    );
  }
);

export default TableHeader;
