import React from 'react';
import { Tag } from 'antd';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';

const FilterConditions = ({
  filters,
  onFilterChange,
}: {
  filters: Record<string, any>;
  onFilterChange: (e: Record<string, any>) => void;
}) => {
  const translate = useTranslate();

  const handleRemoveName = (
    user: string,
    e: { preventDefault: () => void }
  ) => {
    e.preventDefault();
    const { name } = filters;
    const data = name.in.filter((item: string) => item !== user);
    const newName = {
      in: data,
    };
    onFilterChange({
      ...filters,
      name: data.length ? newName : null,
    });
  };

  const handleRemoveType = (
    typeName: string,
    e: { preventDefault: () => void }
  ) => {
    e.preventDefault();
    const { depositProviderType } = filters;
    const data = depositProviderType.in.filter(
      (item: string) => item !== typeName
    );
    const newFilterProviderType = {
      in: data,
    };

    onFilterChange({
      ...filters,
      depositProviderType: data.length ? newFilterProviderType : null,
    });
  };

  return (
    <>
      {filters.name
        ? filters.name.in.map((item: string, key: number) => (
            <Tag key={key} closable onClose={(e) => handleRemoveName(item, e)}>
              {item}
            </Tag>
          ))
        : null}
      {filters.depositProviderType
        ? filters.depositProviderType.in.map((item: string, key: number) => (
            <Tag key={key} closable onClose={(e) => handleRemoveType(item, e)}>
              {translate(messages[item])}
            </Tag>
          ))
        : null}
    </>
  );
};

export default FilterConditions;
