import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import DateRangeFilter from './components/DateRangeFilter';

const Sidebar = ({
  state,
  setState,
}: {
  state: Record<string, any>;
  setState: (e: any) => void;
}) => (
  <>
    <DateRangeFilter />
    <Button
      className="mt-4"
      block
      onClick={() =>
        setState({
          ...state,
          dateRange: [],
        })
      }
    >
      <FormattedMessage id="reports.clear.text" defaultMessage="Clear" />
    </Button>
  </>
);

export default Sidebar;
