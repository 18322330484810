import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'members-online.number.text': {
    id: 'members-online.number.text',
    defaultMessage: 'No.',
  },
  'members-online.member-account.text': {
    id: 'members-online.member-account.text',
    defaultMessage: 'Member Account',
  },
  'members-online.agent-account.text': {
    id: 'members-online.agent-account.text',
    defaultMessage: 'Agent Account',
  },
  'members-online.last-seen.text': {
    id: 'members-online.last-seen.text',
    defaultMessage: 'Last Seen',
  },
  'members-online.account-balance.text': {
    id: 'members-online.account-balance.text',
    defaultMessage: 'Account Balance',
  },
  'members-online.win-loss.text': {
    id: 'members-online.win-loss.text',
    defaultMessage: 'Win Loss',
  },
  'members-online.member-levels.text': {
    id: 'members-online.member-levels.text',
    defaultMessage: 'Member Levels',
  },
});
