import React, { useEffect, useState } from 'react';
import { Button, message, Modal, Spin, Table } from 'antd';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { orderBy } from 'lodash';
import esGet from 'utils/esGet';
import { CurrencyMetadata } from 'types/graphqlTypes';
import { useConfig } from 'hooks/useConfig';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import {
  columns,
  CURRENCIES,
  DEFAULT_CURRENCY,
  UPDATE_CURRENCY,
} from './utils';

const ManageCurrency = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currencySelected, setCurrencySelected] = useState<React.Key[]>(['']);
  const { data } = useQuery(CURRENCIES);
  const [getDefaultCurrency] = useLazyQuery(DEFAULT_CURRENCY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (dataResp: Record<string, any>) => {
      setLoading(false);
      const defaultCurrency: CurrencyMetadata = esGet(
        dataResp?.config?.defaultCurrency,
        {}
      );
      setCurrencySelected([defaultCurrency?.id!]);
    },
  });
  const { refetch } = useConfig();
  const { context } = useOperatorHeader();
  const [updateCurrency] = useMutation(UPDATE_CURRENCY, {
    context,
  });

  useEffect(() => {
    if (isModalVisible) {
      setLoading(true);
      getDefaultCurrency();
    }
  }, [getDefaultCurrency, isModalVisible]);

  const onUpdateCurrency = async () => {
    setLoading(true);
    await updateCurrency({
      variables: {
        input: {
          defaultCurrency: currencySelected[0],
        },
      },
    }).finally(() => {
      message.success('Currency updated');
      setLoading(false);
      if (refetch) {
        refetch();
      }
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await onUpdateCurrency();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setCurrencySelected(selectedRowKeys);
    },
    selectedRowKeys: currencySelected,
  };

  const currencies = esGet(data?.currencies?.edges, []);
  const nodeCurrencies = currencies.map(
    (localData: { node: CurrencyMetadata[] }) => localData.node
  );
  const transformedCurrencies = orderBy(
    nodeCurrencies.map((curr: CurrencyMetadata) => ({
      ...curr,
      key: curr.id,
    })),
    ['name'],
    ['asc']
  );

  return (
    <div>
      <h3>Manage Currency</h3>
      <Button style={{ marginTop: '12px' }} onClick={showModal}>
        View/Select Default Currency
      </Button>

      <Modal
        width="900px"
        title="View & Select your Default Currency"
        visible={isModalVisible}
        onOk={handleOk}
        okText="Set Default"
        onCancel={handleCancel}
      >
        {loading ? (
          <Spin />
        ) : (
          <Spin spinning={loading}>
            <Table
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              dataSource={transformedCurrencies}
              columns={columns}
            />
          </Spin>
        )}
      </Modal>
    </div>
  );
};

export default ManageCurrency;
