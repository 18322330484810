import { FormattedMessage } from 'react-intl';
import { EllipsisOutlined, MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import { columnAlign } from 'utils/tableAlignment';
import React from 'react';
import CS from 'components/CustomSwitch';

const CustomSwitch: any = CS;

export const columns = (
  isNextTrue: boolean,
  toggleDesktopOrMobile: (e: string, f: any) => void,
  setForEditBanner: (e: Record<string, any>) => void,
  onDuplicateBanner: (e: string) => void,
  onDeleteBanner: (e: Record<string, any>) => void,
  {
    ALLOWED_EDIT,
    ALLOWED_DUPLICATE,
    ALLOWED_DELETE,
    ALLOWED_TOGGLE_ENABLE,
  }: {
    [key: string]: boolean;
  }
) => [
  {
    title: <></>,
    width: 50,
    align: columnAlign.onRight,
    render: () => <MenuOutlined />,
  },
  {
    title: (
      <FormattedMessage id="banner-name.text" defaultMessage="Banner Name" />
    ),
    dataIndex: 'name',
    key: 'name',
    align: columnAlign.onLeft,
    render: (text: string) => text,
  },
  {
    title: <FormattedMessage id="MOBILE_H5" defaultMessage="Mobile/H5" />,
    align: columnAlign.onCenter,
    dataIndex: 'enabledMobile',
    key: 'enabledMobile',
    render: (text: string, allData: Record<string, any>) =>
      ALLOWED_TOGGLE_ENABLE && (
        <CustomSwitch
          disabled={!allData.imageMobile}
          value={text}
          onChange={() => toggleDesktopOrMobile('mobile', allData)}
          textHelper={{
            true: <FormattedMessage id="on.text" defaultMessage="On" />,
            false: <FormattedMessage id="off.text" defaultMessage="Off" />,
          }}
        />
      ),
  },
  {
    title: isNextTrue ? (
      <FormattedMessage id="landscape.text" defaultMessage="Landscape" />
    ) : (
      ''
    ),
    align: columnAlign.onCenter,
    dataIndex: 'enabledHorizontal',
    key: 'enabledHorizontal',
    render: (text: boolean, allData: Record<string, any>) =>
      ALLOWED_TOGGLE_ENABLE &&
      isNextTrue && (
        <CustomSwitch
          disabled={!allData.imageHorizontal}
          value={text}
          onChange={() => toggleDesktopOrMobile('horizontal', allData)}
          textHelper={{
            true: <FormattedMessage id="on.text" defaultMessage="On" />,
            false: <FormattedMessage id="off.text" defaultMessage="Off" />,
          }}
        />
      ),
  },
  {
    title: <FormattedMessage id="desktop.text" defaultMessage="Desktop" />,
    align: columnAlign.onCenter,
    dataIndex: 'enabledDesktop',
    key: 'enabledDesktop',
    render: (text: boolean, allData: Record<string, any>) =>
      ALLOWED_TOGGLE_ENABLE && (
        <CustomSwitch
          disabled={!allData.imageDesktop}
          value={text}
          onChange={() => toggleDesktopOrMobile('desktop', allData)}
          textHelper={{
            true: <FormattedMessage id="on.text" defaultMessage="On" />,
            false: <FormattedMessage id="off.text" defaultMessage="Off" />,
          }}
        />
      ),
  },
  {
    title: <FormattedMessage id="action.text" defaultMessage="Action" />,
    dataIndex: 'actions',
    align: columnAlign.onCenter,
    key: 'actions',
    render: (_: any, allData: Record<string, any>) =>
      (ALLOWED_EDIT || ALLOWED_DUPLICATE || ALLOWED_DELETE) && (
        <Dropdown
          overlay={
            <Menu>
              {ALLOWED_EDIT && (
                <Menu.Item onClick={() => setForEditBanner(allData)}>
                  <FormattedMessage id="edit.text" defaultMessage="Edit" />
                </Menu.Item>
              )}

              {ALLOWED_DUPLICATE && (
                <Menu.Item onClick={() => onDuplicateBanner(allData.id)}>
                  <FormattedMessage
                    id="duplicate.text"
                    defaultMessage="Duplicate"
                  />
                </Menu.Item>
              )}

              {!allData.enabledDesktop &&
                !allData.enabledMobile &&
                ALLOWED_DELETE && (
                  <Menu.Item onClick={() => onDeleteBanner(allData)}>
                    <FormattedMessage
                      id="delete.text"
                      defaultMessage="Delete"
                    />
                  </Menu.Item>
                )}
            </Menu>
          }
          placement="bottomRight"
        >
          <EllipsisOutlined
            className="cursor-pointer font-weight-bold"
            style={{ fontSize: '25px' }}
          />
        </Dropdown>
      ),
  },
];

export const BodyRow = (
  setSelectedBanner: (e: any) => void,
  banners: any,
  draggingIndex: number
) => (props: Record<string, any>) => {
  const {
    isOver,
    connectDragSource,
    connectDropTarget,
    moveRow,
    ...restProps
  } = props;
  const style = { ...restProps.style, cursor: 'move' };
  let { className } = restProps;
  if (isOver) {
    if (restProps.index > draggingIndex) {
      className += ' drop-over-downward';
    }
    if (restProps.index < draggingIndex) {
      className += ' drop-over-upward';
    }
  }

  return connectDragSource(
    connectDropTarget(
      <tr
        {...restProps}
        className={className}
        style={style}
        onClick={() => setSelectedBanner(banners[restProps.index])}
      />
    )
  );
};
