import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers';
import { Button, Input, message, Modal } from 'antd';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Config } from 'types/graphqlTypes-row';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import * as yup from 'yup';

const WONKAPAY_SHOP_DETAILS = gql`
  query WonkaPayShopDetails {
    config {
      wonkaPayKey
      wonkaPaySecret
    }
  }
`;

const UPDATE_WONKAPAY_DETAILS = gql`
  mutation UpdateWonkaPayShopDetails($input: UpdateConfigInput!) {
    updateConfig(input: $input)
  }
`;

const noWhitespaceAndRequired = yup
  .string()
  .nullable()
  .required('Field is required')
  .matches(/^\S+$/, 'No whitespace allowed');

const validationSchema = yup.object().shape({
  wonkaPayKey: noWhitespaceAndRequired,
  wonkaPaySecret: noWhitespaceAndRequired,
});

const WonkaPayShopDetails = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { control, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      wonkaPayKey: '',
      wonkaPaySecret: '',
    },
    resolver: yupResolver(validationSchema),
  });
  const { context } = useOperatorHeader();
  const [updateWonkaPay, { loading: mutationLoading }] = useMutation(
    UPDATE_WONKAPAY_DETAILS,
    {
      context,
    }
  );

  const { loading, refetch } = useQuery<{ config: Config }>(
    WONKAPAY_SHOP_DETAILS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onCompleted: ({ config }) => {
        reset({
          wonkaPayKey: config.wonkaPayKey!,
          wonkaPaySecret: config.wonkaPaySecret!,
        });
      },
    }
  );

  const handleOnOk = (values: Record<string, any>) => {
    updateWonkaPay({
      variables: {
        input: {
          ...values,
        },
      },
    })
      .then(() => {
        message.success('WonkaPay Shop Details updated!');
      })
      .finally(() => {
        setIsModalVisible(false);
        refetch();
      });
  };

  return (
    <div>
      <h3>Manage WonkaPay Shop Details</h3>
      <Button onClick={() => setIsModalVisible(true)}>
        Configure WonkaPay Shop Details
      </Button>

      <Modal
        destroyOnClose
        title="Configure WonkaPay Shop Details"
        visible={isModalVisible}
        onOk={handleSubmit(handleOnOk)}
        onCancel={() => setIsModalVisible(false)}
        okButtonProps={{ loading: loading || mutationLoading }}
        cancelButtonProps={{ loading: loading || mutationLoading }}
      >
        <div className="mb-3">
          <p className="mb-1 fw-500">WonkaPay Key</p>
          <Controller as={Input} control={control} name="wonkaPayKey" />
          <span className="text-11 text-danger">
            {errors?.wonkaPayKey?.message}
          </span>
        </div>
        <div className="mb-3">
          <p className="mb-1 fw-500">WonkaPay Secret Key:</p>
          <Controller as={Input} control={control} name="wonkaPaySecret" />
          <span className="text-11 text-danger">
            {errors?.wonkaPaySecret?.message}
          </span>
        </div>
      </Modal>
    </div>
  );
};

export default WonkaPayShopDetails;
