import React, { useState } from 'react';
import Layout from 'components/Layout';
import EditSearchSettings from 'pages/components/Reports/DailyOperatingReport/components/SearchSettings/components/EditSearchSettings';
import { useFilterValues } from 'contexts/Filters';
import { FilterItem } from 'components/FilterItem/FilterItem';
import SavedFilterSelect from 'components/SavedFilterSelect';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';
import FilterItems from '../FilterItems';

type Props = {};

const Sidebar: React.FC<Props> = () => {
  const {
    filters,
    handleFilterChange,
    onFilterChange,
    savedFilterId,
    handleSavedFilterIdChange,
  } = useFilterValues();
  const [key, setKey] = useState(0);

  /**
   * Re-renders the <SavedFilterSelect/> to update the list of filters
   */
  const handleUpdateKey = () => {
    setKey((prev) => prev + 1);
  };

  const translate = useTranslate();

  return (
    <Layout.Sidebar
      quickSearch={{
        filters,
        onFilterChange,
        onFiltersUpdate: () => handleUpdateKey(),
        contextKey: 'daily-operating-report',
        editSearchSettings: EditSearchSettings,
        hideQuickSearchFilter: true,
      }}
    >
      <FilterItem label={translate(messages.QUICK_FILTER)}>
        <SavedFilterSelect
          placeHolder={translate(messages.SELECT_SAVED_CUSTOM_FILTER)}
          value={savedFilterId as string}
          onChange={(val: string) => handleSavedFilterIdChange(val)}
          context="daily-operating-report"
          key={key}
        />
      </FilterItem>

      <FilterItems filters={filters} handleFilterChange={handleFilterChange} />
    </Layout.Sidebar>
  );
};

export default Sidebar;
