import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'permission-groups.text': {
    id: 'permission-groups.text',
    defaultMessage: 'Permission Groups',
  },
  'last-login-date.text': {
    id: 'last-login-date.text',
    defaultMessage: 'Last login date',
  },
  'registration-date.text': {
    id: 'registration-date.text',
    defaultMessage: 'Registration Date',
  },
  'status.text': {
    id: 'status.text',
    defaultMessage: 'Status',
  },
});
