import styled from 'styled-components';
import { Card, Row } from 'antd';

export const StyledCard = styled(Card)`
  box-shadow: 0px 1px 1px 0.5px rgba(0, 0, 0, 0.2);
  max-width: 216px;
  max-height: 184px;
  /* Firefox */
  @-moz-document url-prefix() {
    & {
      max-height: 200px;
    }
  }
  border-radius: 8px !important;

  .ant-card-body {
    padding: 16px;
  }

  .ant-card-body .mb-2 {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.06;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.45);
  }

  .ant-card-body h3 {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-card-body .ant-row {
    margin-top: -12px;
  }
`;

export const StyledFooter = styled(Row)`
  font-size: 12px;
`;

export default {};
