/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLazyQuery } from '@apollo/react-hooks';
import { Select, ConfigProvider, Empty } from 'antd';
import { debounce, get, uniq } from 'lodash';
import useTranslate from 'utils/useTranslate';

import messages from 'pages/components/Reports/messages';
import { GamesConnectionEdge, Game } from 'types/graphqlTypes';
import { GAME_TYPES } from './queries';
import { StyledLabel, StyledSelect, StyledSpan } from '../styles';

const { Option } = Select;

type GameCategoryType = {
  setState: (value: any) => void;
  state: { [key: string]: any };
};

const GameType = ({ state, setState }: GameCategoryType) => {
  const [loadSearch, { data, called }] = useLazyQuery(GAME_TYPES, {
    variables: { first: 10, filter: {} },
  });

  if (!called) loadSearch();

  const edges = get(data, 'games.edges', []);
  const node = edges.map((edge: GamesConnectionEdge) => edge.node);
  const gameTypes = node.map((q: Game) => q.type);
  const uniqueGameTypes: string[] = uniq(gameTypes);

  const handleChange = (e: any) => {
    if (e.length) {
      setState((prev: Record<string, any>) => ({
        ...prev,
        gameType: { in: e },
      }));
    } else {
      setState((prev: Record<string, any>) => ({
        ...prev,
        gameType: null,
      }));
    }
  };

  const debouncedRefetch = debounce((textInput) => {
    if (textInput.length === 0) {
      loadSearch({
        variables: {
          first: 10,
          filter: {},
        },
      });
    }

    if (textInput.length > 1) {
      loadSearch({
        variables: {
          first: 10,
          filter: {
            type: {
              contains: textInput,
            },
          },
        },
      });
    }
  }, 500);

  const translate = useTranslate();

  return (
    <>
      <div className="d-flex justify-content-between mb-1">
        <div>
          <StyledLabel>
            <FormattedMessage
              id="reports.game-type.text"
              defaultMessage="Game Type"
            />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={translate(messages['no-data.text'])}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        <StyledSelect
          showSearch
          allowClear
          mode="multiple"
          placeholder={
            <FormattedMessage
              id="reports.search.text"
              defaultMessage="Search"
            />
          }
          style={{ width: '100%' }}
          value={state.gameType ? (uniq(state.gameType.in) as any) : []}
          onChange={handleChange}
          onSearch={(text) => {
            // setPartial(text);
            debouncedRefetch(text);
          }}
          optionFilterProp="label"
        >
          {/* {!hasPartial && partial.length && (
            <Option
              key={11}
              value={`Partial: ${partial}`}
              label={`Partial: ${partial}`}
            >
              <FormattedMessage id="partial.text" defaultMessage="Partial" />:{' '}
              {partial}
            </Option>
          )} */}

          {uniqueGameTypes.map((gameType: string) => (
            <Option key={gameType} value={gameType} label={gameType}>
              {gameType}
            </Option>
          ))}
        </StyledSelect>
      </ConfigProvider>
    </>
  );
};

export default GameType;
