import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { CREATE_DEPOSIT_PROVIDER } from 'graphql/mutations/depositProvider.mutation';
import { DEPOSIT_PROVIDERS } from 'graphql/queries/depositProvider.query';
import globalMsgs from 'messages';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import ProviderForm from '../ProviderForm';
import { StyledModal } from './styles';

const NewProvider = () => {
  const translate = useTranslate();
  const [isShow, setIsShow] = useState(false);

  const handleModal = () => setIsShow((prevState) => !prevState);

  const messages = defineMessages({
    'deposit-provider-created.text': {
      id: 'deposit-provider-created.text',
      defaultMessage: 'Deposit provider created',
    },
  });

  const refetchQueries = [
    {
      query: DEPOSIT_PROVIDERS,
      variables: { first: 10, filter: {} },
    },
  ];

  const [createDepositProvider, { data: ProviderData, loading }] = useMutation(
    CREATE_DEPOSIT_PROVIDER,
    {
      onCompleted: (data) => {
        if (!data) return;
        message.success({
          content: (
            <span data-testid={data.createDepositProvider}>
              {translate(messages['deposit-provider-created.text'])}
            </span>
          ),
        });
      },
      onError: () => {
        message.error({
          content: (
            <span data-testid="error">
              {translate(globalMsgs.INTERNAL_SERVER_ERROR)}
            </span>
          ),
        });
      },
      refetchQueries,
    }
  );

  return (
    <>
      <Button
        data-testid="create"
        onClick={handleModal}
        style={{ marginTop: 'auto' }}
        type="primary"
      >
        <PlusOutlined />{' '}
        <FormattedMessage
          id="add-deposit-provider.text"
          defaultMessage="Add deposit provider"
        />
      </Button>
      <StyledModal
        title={
          <FormattedMessage
            id="add-deposit-provider.text"
            defaultMessage="Add deposit provider"
          />
        }
        visible={isShow}
        width={800}
        onOk={handleModal}
        onCancel={handleModal}
        footer={false}
      >
        <div data-testid="modal">
          <ProviderForm
            depositProvider={ProviderData}
            onClose={handleModal}
            submitDepositProvider={createDepositProvider}
            loading={loading}
          />
        </div>
      </StyledModal>
    </>
  );
};

export default NewProvider;
