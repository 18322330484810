import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Select, Spin, Tooltip } from 'antd';
import esGet from 'utils/esGet';
import { orderBy } from 'lodash';

const COUNTRY_CODES = gql`
  query CountryCodes {
    countryCodes {
      country
      countryCode
    }
  }
`;

type CountryCodeType = {
  country: string;
  countryCode: string;
};

const CountryCodesSelect = ({
  onSelected,
  value = '',
}: {
  value?: string;
  onSelected: (countryCode: string) => void;
}) => {
  const { data, loading } = useQuery(COUNTRY_CODES);

  if (loading) {
    return <Spin />;
  }

  const rawCountryCodes: CountryCodeType[] = esGet(data?.countryCodes, []);
  const transform = rawCountryCodes.map((rawCountryCode) => ({
    ...rawCountryCode,
    countryCode: Number(rawCountryCode.countryCode.split('+')[1]),
  }));
  const countryCodes = orderBy(transform, 'countryCode').map(
    (sortedCountryCodes) => ({
      ...sortedCountryCodes,
      countryCode: `+${sortedCountryCodes.countryCode}`,
    })
  );

  /**
   * @description This function will group countries by country code this will also handle the case where multiple countries have the same country code `e.g. +61 (Australia, Christmas Island, Cocos (Keeling) Islands)`
   * @param countries List of countries
   * @returns Array of countries grouped by country code
   */
  const groupCountriesByCode = (countries: CountryCodeType[]) => {
    const countryGroups: Record<string, string[]> = {};

    countries.forEach(({ country, countryCode }) => {
      if (!countryGroups[countryCode]) {
        countryGroups[countryCode] = [];
      }
      countryGroups[countryCode].push(country);
    });
    return countryGroups;
  };

  /**
   * @description This function will format the grouped countries into an array of `CountryCodeType`
   * @param countryGroups The list of countries grouped by country code
   * @returns an array of `CountryCodeType`
   */
  const formatGroupedCountries = (
    countryGroups: Record<string, string[]>
  ): CountryCodeType[] =>
    Object.entries(countryGroups).map(([code, countries]) => ({
      countryCode: code,
      country: countries.join(', '),
    }));

  const newCountryCode = () => {
    const countryGroups = groupCountriesByCode(countryCodes);
    return formatGroupedCountries(countryGroups);
  };

  return (
    <Select
      placeholder="Code"
      defaultValue={value || undefined}
      value={value || undefined}
      onChange={(e: string) => {
        onSelected(e);
      }}
      style={{ width: '70%', maxWidth: '150px' }}
    >
      {newCountryCode().map((code) => (
        <Select.Option value={`${code.countryCode}`} key={code.country}>
          <Tooltip
            title={`(${code.countryCode}) ${code.country}`}
            placement="left"
          >
            <span className="fs-12">({code.countryCode})</span> {code.country}
          </Tooltip>
        </Select.Option>
      ))}
    </Select>
  );
};

export default CountryCodesSelect;
