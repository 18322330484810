import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  FC,
} from 'react';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import { get, isEmpty } from 'lodash';
import { Page } from 'interfaces/user.interface';
import { formatDate, transformMovableDateFilter } from 'utils/dateUtils';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { ClearAllFilterButton } from 'components/ClearAllFilterButton/ClearAllFilterButton';
import { DATE_FORMAT } from 'constants/date';
import { CreatorTag } from '../CreatorTags';
import { MemberTags } from '../MemberTags';
import { buildMessageType } from '../../../CreateNewMail/util';
import { MemberLevelsTags } from '../MemberLevelsTags';
import { MemberLoyaltyLevelsTags } from '../MemberLoyaltyLevelsTags';
import {
  ISystemMessageFilter,
  IMember,
  IMemberLevel,
  IMemberLoyaltyLevel,
} from '../../../../interfaces';

interface Props {
  page: Page<ISystemMessageFilter>;
  setPage: Dispatch<SetStateAction<Page<ISystemMessageFilter>>>;
}

export const FilterConditions: FC<Props> = ({ page, setPage }) => {
  const [localStateFilter, setLocalStateFilter] = useState({});
  const [initialFilter] = useState(page.filter as Object);

  const filters = page?.filter as Record<string, any>;

  useEffect(() => {
    const filterToTransform = () => {
      const movableDate = transformMovableDateFilter({
        dateTimeSubmitted: {
          gte: get(page, 'filter.dateTimeSubmitted.gte'),
          lte: get(page, 'filter.dateTimeSubmitted.lte'),
        },
      });

      const hasDate = !!get(movableDate, 'filter.dateTimeSubmitted.gte');
      return hasDate
        ? {
            gte: get(movableDate, 'dateTimeSubmitted.gte'),
            lte: get(movableDate, 'dateTimeSubmitted.lte'),
          }
        : undefined;
    };
    const transformedDateFilters = transformMovableDateFilter({
      ...page.filter,
      ...filterToTransform(),
    });
    setLocalStateFilter(transformedDateFilters || {});
  }, [page]);

  const setFilters = (filter: any) => {
    setPage((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        ...filter,
      },
    }));
  };

  const onCleanFilter = (filter: Object) => {
    setPage((prev) => ({
      ...prev,
      filter,
    }));
  };

  const onRemoveFilter = (key: string, value: any) => () => {
    setFilters({ [key]: value });
  };

  const onRemoveMemberFilter = (key: string) => (member: { id: string }) => {
    const members = filters[key]?.in || [];
    const newFilter = members.filter(
      (item: { id: string }) => item.id !== member.id
    );

    const payload = !isEmpty(newFilter) ? { in: newFilter } : null;
    onRemoveFilter(key, payload)();
  };

  return (
    <>
      <div>
        <ToggleSidebarHead />
        <FormattedMessage
          id="filter-conditions.text"
          defaultMessage="Filter conditions"
        />
        :
        <span className="mr-4" />
        {filters?.creator?.in && (
          <CreatorTag
            onClose={onRemoveMemberFilter('creator')}
            ids={get(page, 'filter.creator.in')}
          />
        )}
        {filters?.title?.in && (
          <Tag closable onClose={onRemoveFilter('title', null)}>
            <strong>
              <FormattedMessage id="title.text" defaultMessage="Title" />
            </strong>
            : {get(page, 'filter.title.in')}
          </Tag>
        )}
        {filters?.dateTimeSubmitted?.gte && (
          <Tag closable onClose={onRemoveFilter('dateTimeSubmitted', null)}>
            <strong>
              <FormattedMessage
                id="sending-date.text"
                defaultMessage="Sending Date"
              />
            </strong>
            :{' '}
            {formatDate(
              get(localStateFilter, 'dateTimeSubmitted.gte'),
              DATE_FORMAT
            )}{' '}
            ~{' '}
            {formatDate(
              get(localStateFilter, 'dateTimeSubmitted.lte'),
              DATE_FORMAT
            )}
          </Tag>
        )}
        {filters?.targetMembers?.in?.map((x: IMember) => (
          <MemberTags
            key={x.id}
            id={x.id}
            type={buildMessageType.target}
            onClose={onRemoveMemberFilter(`${buildMessageType.target}Members`)}
          />
        ))}
        {filters?.excludedMembers?.in?.map((x: IMember) => (
          <MemberTags
            key={x.id}
            id={x.id}
            type={buildMessageType.excluded}
            onClose={onRemoveMemberFilter(
              `${buildMessageType.excluded}Members`
            )}
          />
        ))}
        {filters?.targetMemberLevels?.in?.map((x: IMemberLevel) => (
          <MemberLevelsTags
            key={x.id}
            id={x.id}
            type={buildMessageType.target}
            onClose={onRemoveMemberFilter(
              `${buildMessageType.target}MemberLevels`
            )}
          />
        ))}
        {filters?.excludedMemberLevels?.in?.map((x: IMemberLevel) => (
          <MemberLevelsTags
            key={x.id}
            id={x.id}
            type={buildMessageType.excluded}
            onClose={onRemoveMemberFilter(
              `${buildMessageType.excluded}MemberLevels`
            )}
          />
        ))}
        {filters?.targetMemberLoyaltyLevels?.in?.map(
          (x: IMemberLoyaltyLevel) => (
            <MemberLoyaltyLevelsTags
              key={x.id}
              id={x.id}
              type={buildMessageType.target}
              onClose={onRemoveMemberFilter(
                `${buildMessageType.target}MemberLoyaltyLevels`
              )}
            />
          )
        )}
        {filters?.excludedMemberLoyaltyLevels?.in?.map(
          (x: IMemberLoyaltyLevel) => (
            <MemberLoyaltyLevelsTags
              key={x.id}
              id={x.id}
              type={buildMessageType.excluded}
              onClose={onRemoveMemberFilter(
                `${buildMessageType.excluded}MemberLoyaltyLevels`
              )}
            />
          )
        )}
      </div>
      <div className="ml-auto flex-shrink-0">
        <ClearAllFilterButton
          initialFilters={initialFilter}
          currentFilters={filters}
          onFilterChange={onCleanFilter}
        />
      </div>
    </>
  );
};
