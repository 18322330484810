import { useMutation } from '@apollo/react-hooks';
import { Button as AntdButton, message, Popconfirm } from 'antd';
import { PureQueryOptions } from 'apollo-client';
import { REJECT_DEPOSIT } from 'graphql/mutations/deposit.mutation';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';

const Button: any = AntdButton;
const RejectDeposit = (props: {
  id: string;
  refetchQueries: (string | PureQueryOptions)[] | undefined;
  remarks: string;
  setRemarksError: Function;
  toggleModal: Function;
}) => {
  const translate = useTranslate();
  const { id, refetchQueries, remarks, setRemarksError, toggleModal } = props;

  const messages = defineMessages({
    rejectDepositProcess: {
      id: 'deposits.deposit-request-rejected.text',
      defaultMessage: 'Deposit request rejected',
    },
  });

  const [rejectDeposit, { loading }] = useMutation(REJECT_DEPOSIT, {
    onCompleted: (data) => {
      if (data.rejectDeposit) {
        message.success(translate(messages.rejectDepositProcess));
      }
      setRemarksError();
      toggleModal();
    },
    onError: () => {
      setRemarksError();
    },
    variables: { id },
    refetchQueries,
  });

  return (
    <>
      <Popconfirm
        title={
          <FormattedMessage
            id="are-you-sure?.text"
            defaultMessage="Are you sure?"
          />
        }
        onConfirm={() => {
          if (remarks) rejectDeposit();
          else
            setRemarksError(
              <FormattedMessage
                id="deposits.required-for-rejecting-deposit-request.text"
                defaultMessage="Required for rejecting deposit request"
              />
            );
        }}
        onCancel={() => {}}
        okText={<FormattedMessage id="yes.text" defaultMessage="Yes" />}
        cancelText={<FormattedMessage id="no.text" defaultMessage="No" />}
      >
        <Button type="danger" ghost disabled={loading} loading={loading}>
          <FormattedMessage id="reject.text" defaultMessage="Reject" />
        </Button>
      </Popconfirm>
    </>
  );
};

export default RejectDeposit;
