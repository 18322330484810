import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import FiltersProvider, { useFilterValues } from 'contexts/Filters';
import { useTablePagination } from 'hooks/usePaginate';
import { PromoPayout, PromoPayoutsConnectionEdge } from 'types/graphqlTypes';
import {
  GET_PROMO_PAYOUTS,
  GET_PROMO_PAYOUT_NAMES,
} from 'graphql/queries/promo.query';
import DownloadCsvButtonNext from 'components/DownloadCsvButtonNext';
import { reports } from 'components/DownloadCsvButtonNext/reportsValue';
import coercedGet from 'utils/coercedGet';
import { Tabs } from 'antd';
import styled from 'styled-components';
import removeNull from 'utils/removeNull';
import { usePartialFiltersQuery } from 'utils/partialUtils';
import LegacyIconToggle from 'components/LegacyIconToggle';
import { ALink } from 'components/ALink/ALink';
import { StyledTagContainer } from 'styles';
import { ToggleSidebarHead } from 'components/ToggleSidebarHead/ToggleSidebarHead';
import { isEqual } from 'lodash';
import TimezoneSelect from 'components/TimezoneSelect';
import ReportsTimezoneProvider, {
  useReportsTimezone,
} from 'contexts/ReportsTimezone';
import { FilterConditions } from './FilterConditions';
import { getPayoutTabPanes } from './utils';
import Sidebar from './Sidebar';

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-wrap {
    background-color: #fff;
  }
  .ant-tabs-content-holder {
    overflow: auto;
  }
`;

const PromoPayoutReportsContent = () => {
  const { bypassedCurrentTimezone } = useReportsTimezone();

  const [expandedTags, setExpandedTags] = useState(false);
  const [payoutTabState, setPayoutTabState] = useState({
    activeKey: 'SIGN_UP',
  });

  const { filters, initialValues, setFilters, resetFilter } = useFilterValues();
  const { page, setPage, handleNext, handlePrev } = useTablePagination({
    after: undefined,
    savedCursor: [undefined],
    currentPage: 0,
    first: 10,
  });

  const handleFilterChange = (e: object) => {
    setFilters(e);
  };

  const memoizedFilter = React.useMemo(
    () => ({
      ...removeNull(filters),
      type: {
        eq: payoutTabState.activeKey,
      },
    }),
    [filters, payoutTabState.activeKey]
  );

  const { loading, data } = usePartialFiltersQuery(
    GET_PROMO_PAYOUT_NAMES,
    GET_PROMO_PAYOUTS,
    'promoPayouts.edges',
    memoizedFilter,
    page,
    ['name'],
    'cache-and-network',
    'name'
  );

  const promoPayoutsEdges = coercedGet(data!, 'promoPayouts.edges', []);
  const promoPayouts: PromoPayout[] = promoPayoutsEdges.map(
    (promoPayoutEdge: PromoPayoutsConnectionEdge) => promoPayoutEdge.node
  );

  const totalCount = coercedGet(data!, 'promoPayouts.totalCount', 0);
  const pageInfo = coercedGet(data!, 'promoPayouts.pageInfo', 0);

  const totalPage = Math.ceil(totalCount / page.first) || 1;

  useEffect(() => {
    resetFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payoutTabState.activeKey]);

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <Layout.Container>
      <>
        <Layout.Header
          leftNode={
            <div className="d-flex align-items-center flex-g-1">
              <ToggleSidebarHead />
              <StyledTagContainer ref={ref} isExpanded={expandedTags}>
                <FilterConditions
                  filters={filters}
                  onFilterChange={handleFilterChange}
                  loading={loading}
                />
              </StyledTagContainer>
              <LegacyIconToggle
                forwardedRef={ref}
                filters={filters}
                expandedTags={expandedTags}
                toggleExpandedTags={setExpandedTags}
              />
              {!isEqual(initialValues, filters) && (
                <ALink
                  onClick={() => resetFilter()}
                  style={{ marginTop: '0.35rem' }}
                >
                  Clear All
                </ALink>
              )}
            </div>
          }
          rightNode={
            <div className="d-flex align-items-center">
              <TimezoneSelect />
              <DownloadCsvButtonNext
                filter={{
                  ...filters,
                  type: {
                    eq: payoutTabState.activeKey,
                  },
                }}
                iconOnly
                module={reports.PROMO_PAYOUT_RECORDS}
                allowedDownloadCSV
                allowedGenerateCSV
              />
            </div>
          }
        />
        <Layout.Content
          sideBar={<Sidebar />}
          footer={
            <Layout.Footer
              loading={loading}
              onNext={handleNext}
              onPrev={handlePrev}
              page={page}
              resultsCount={totalCount}
              totalPage={totalPage}
              setPage={setPage}
              pageInfo={pageInfo}
            />
          }
        >
          <StyledTabs
            style={{
              marginLeft: 24,
            }}
            type="card"
            activeKey={payoutTabState.activeKey}
            onChange={(key) =>
              setPayoutTabState((prev) => ({
                ...prev,
                activeKey: key,
              }))
            }
          >
            {getPayoutTabPanes({
              dataSource: promoPayouts,
              loading,
              reportTimezone: bypassedCurrentTimezone,
            }).map((pane) => (
              <Tabs.TabPane key={pane.key} tab={pane.title}>
                {pane.content}
              </Tabs.TabPane>
            ))}
          </StyledTabs>
        </Layout.Content>
      </>
    </Layout.Container>
  );
};

const PromoPayoutReports = () => (
  <ReportsTimezoneProvider>
    <FiltersProvider
      initialValues={{
        name: null,
        startDateTime: null,
        endDateTime: null,
      }}
    >
      <PromoPayoutReportsContent />
    </FiltersProvider>
    )
  </ReportsTimezoneProvider>
);

export default PromoPayoutReports;
