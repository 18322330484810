import React from 'react';
import coercedGet from 'utils/coercedGet';
import { Select, Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
// eslint-disable-next-line @typescript-eslint/camelcase
import { CountryConnection, QueryCountriesArgs } from 'types/graphqlTypes';
import { StyledSelect } from './styles';

type Props = {
  onChange: (e: any) => void;
  placeHolder?: string;
  value: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
  multiple?: boolean;
  style?: { [key: string]: any };
  isFilter?: boolean;
  className?: string;
  labelAsValue?: boolean;
  isUkOnly?: boolean;
  valueKey?: string;
};

const COUNTRIES = gql`
  query Countries($first: Int) {
    countries(first: $first) {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;

const CountriesSelect: React.FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled,
  label,
  multiple,
  style,
  className,
  isFilter,
  valueKey = 'name',
}) => {
  const { Option } = Select;

  const handleChange = (e: any) => {
    onChange(e);
  };

  const { data, loading } = useQuery<
    { countries: CountryConnection },
    // eslint-disable-next-line @typescript-eslint/camelcase
    QueryCountriesArgs
  >(COUNTRIES, {
    variables: {
      first: 300,
    },
    fetchPolicy: 'network-only',
  });

  const countryList = data?.countries?.edges || [];

  if (loading) return <Spin />;

  return (
    <div className={className || ''}>
      <div>{label}</div>
      <StyledSelect
        mode={multiple ? 'multiple' : undefined}
        value={value || (multiple ? [] : undefined)}
        showSearch
        placeholder={placeHolder || ''}
        onChange={handleChange}
        optionFilterProp="children"
        filterOption={(input: string, option: any) => {
          const target = coercedGet(option as {}, 'props.children', null);
          if (target) {
            return (
              target
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            );
          }
          return false;
        }}
        disabled={disabled}
        style={style || { width: '100%' }}
        isFilter={isFilter}
      >
        {countryList?.map((item) => (
          <Option key={item?.node?.id} value={item?.node[valueKey]}>
            {item?.node?.name}
          </Option>
        ))}
      </StyledSelect>
    </div>
  );
};

export default CountriesSelect;
