import styled from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const StyledLayout = styled(Layout)`
  height: 100%;
`;

export const StyledContent = styled(Content)`
  background: #fff;
`;

export const StyledTagContainer = styled.div`
  flex: 1;
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default {};
