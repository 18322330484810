const transactionTypes: Array<string> = [
  'DEPOSIT',
  'AFFILIATE_COMMISSION',
  'REBATE_PAYOUT',
  'PROMO_PAYOUT',
  'MEMBER_LOYALTY_PAYOUT',
  'REFUND_BET',
  'DEBIT_VENDOR_TRANSFER',
  'SYSTEM_CREDIT',
  'EXTERNAL_PROGRAMME',
];

export const getTransactionLabel = (type: string) =>
  transactionTypes.find((value) => value === type);

export default transactionTypes;
