import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, Checkbox } from 'antd';
import { formItemHorizontalLayout } from 'constants/form';
import messages from 'messages';
import useTranslate from 'utils/useTranslate';

const { Option } = Select;

type Props = {
  setFieldValue: (key: string, value: any) => void;
  values: Record<string, any>;
  label: string;
  id: string;
  onChange?: () => void;
};

function Claims({ setFieldValue, values, label, id, onChange }: Props) {
  const translate = useTranslate();
  return (
    <Form.Item label={label} {...formItemHorizontalLayout}>
      <Input.Group compact>
        <Input
          disabled={values[`noPayoutClaim${id}Duration`]}
          style={{ width: '35%' }}
          size="large"
          value={values[`payoutClaim${id}Duration`]}
          onChange={(e) =>
            setFieldValue(`payoutClaim${id}Duration`, e.target.value)
          }
        />
        <Select
          disabled={values[`noPayoutClaim${id}Duration`]}
          size="large"
          value={values[`payoutClaim${id}Selected`] || 'd'}
          onChange={(e) => setFieldValue(`payoutClaim${id}Selected`, e)}
          style={{ width: 110 }}
        >
          <Option value="d">{translate(messages.DAYS)}</Option>
          <Option value="m">{translate(messages.MONTHS)}</Option>
        </Select>
        <div style={{ width: '35%', paddingTop: 8, paddingLeft: 24 }}>
          <Checkbox
            name={`noPayoutClaim${id}Duration`}
            checked={values[`noPayoutClaim${id}Duration`]}
            onChange={onChange}
          >
            {translate(
              id.toLowerCase() === 'expiry'
                ? messages.NO_CLAIM_EXPIRY_TIME
                : messages.NO_CLAIM_OFFSET_TIME
            )}
          </Checkbox>
        </div>
      </Input.Group>
    </Form.Item>
  );
}

export default Claims;
