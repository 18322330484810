import { startCase } from 'lodash';
import React, { FC } from 'react';
import { EmailAuditTrail } from 'types/graphqlTypes';
import esGet from 'utils/esGet';

type Props = {
  record: EmailAuditTrail;
};

const TriggerEventName: FC<Props> = ({ record }) => {
  const getEventName = () => {
    const eventName = esGet(record?.triggerEventName, null);
    const triggerName = esGet(record?.triggerName, null);

    if (eventName === 'TRIGGERS') return triggerName || 'Triggers';

    return startCase(eventName.toLowerCase()) || '-';
  };

  return (
    <p>
      {getEventName() === 'TIME_OUT_FACILITY'
        ? 'Time-out Facility'
        : getEventName()}
    </p>
  );
};

export default TriggerEventName;
