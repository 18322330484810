import Dexie from 'dexie';

const DB_NAME = 'onewallet-idb';
const DB_VERSION = 1;

const db = new Dexie(DB_NAME);
db.version(DB_VERSION).stores({
  downloadCsv: 'id',
});

export default db;
