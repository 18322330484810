import {
  CloseSquareOutlined,
  LayoutOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button } from 'antd';
import Layout from 'components/Layout';
import ALL_PERMISSIONS from 'constants/permissions3';
import { MARQUEE_MESSAGES } from 'graphql/queries/marqueeMessage.query';
import MarqueeMessagesSettingsModal from 'pages/components/MarqueeMessages/components/MarqueeContent/MarqueeMessagesSettingsModal';
import MarqueeTable from 'pages/components/MarqueeMessages/components/MarqueeContent/MarqueeTable';
import NewMarqueeMessageModal from 'pages/components/MarqueeMessages/components/MarqueeContent/NewMarqueeMessageModal';
import SearchFilterSidebar from 'pages/components/MarqueeMessages/components/SearchFilterSidebar';
import { collectPermissions } from 'pages/components/PermissionGroup/utils';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SearchCriteriaTag } from 'components/SearchCriteriaTag/SearchCriteriaTag';
import { usePermissions } from 'store/accountState';
import coercedGet from 'utils/coercedGet';
import removeNull from 'utils/removeNull';
import { tablePaginationHandler } from 'utils/tablePagination';
import { MarqueeProvider } from './context';

const pageInitialState = {
  first: 10,
  after: null,
  cursor: [null],
  currentPage: 0,
  filter: {},

  searchCriteria: {},
  onTagClose: '',
};

const MarqueeMessages = () => {
  const { role, permissions } = usePermissions();

  const { ALLOWED_CREATE } = collectPermissions(
    role,
    permissions,
    ['CREATE'],
    `${ALL_PERMISSIONS.ALL_SYSTEM_MANAGEMENT.SYSTEM_MANAGEMENT_MARQUEE_MESSAGES}`
  );

  const [isSidebar, setSidebar] = useState(true);
  const [modalStateSetting, setModalStateSetting] = useState(false);
  const [modalStateNewMessage, setModalStateNewMessage] = useState(false);
  const [existingMarquee, setExistingMarquee] = useState<Record<
    string,
    any
  > | null>(null);
  const [page, setPage] = useState(pageInitialState);
  const handleSidebar = () => setSidebar(!isSidebar);

  const refetchVariables = {
    first: page.first,
    after: page.after,
    filter: removeNull(page.filter),
  };

  const [getMarquee, { loading, error, data }] = useLazyQuery(
    MARQUEE_MESSAGES,
    {
      variables: refetchVariables,
    }
  );

  useEffect(() => {
    getMarquee();
  }, [getMarquee]);

  if (error) {
    return (
      <p>
        <FormattedMessage id="error.text" defaultMessage="Error" />
      </p>
    );
  }

  const onNewMarqueeMessage = () => {
    setExistingMarquee(null);
    setModalStateNewMessage(true);
  };

  const refetchQueries = [
    {
      query: MARQUEE_MESSAGES,
      variables: refetchVariables,
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const onEditMarqueeMessage = (marqueeToEdit: Record<string, any>) => {
    setExistingMarquee(marqueeToEdit);
    setModalStateNewMessage(true);
  };

  const totalCount = coercedGet(data, 'marqueeMessages.totalCount', 0);
  const pageInfo = coercedGet(data, 'marqueeMessages.pageInfo', {});

  const { handleNext, handlePrev, totalPage } = tablePaginationHandler(
    page,
    setPage,
    totalCount
  );

  return (
    <MarqueeProvider
      setPage={setPage}
      page={page}
      refetchVariables={refetchVariables}
    >
      <Layout.Container>
        <>
          <Layout.Header
            leftNode={
              <div>
                <Button onClick={handleSidebar} size="small" className="mr-3">
                  {isSidebar ? <CloseSquareOutlined /> : <LayoutOutlined />}
                </Button>
                <FormattedMessage
                  id="filter-conditions.text"
                  defaultMessage="Filter conditions"
                />
                :
                <SearchCriteriaTag setPage={setPage} page={page} />
              </div>
            }
            rightNode={
              <>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span
                  className="d-flex align-items-center mr-2"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setModalStateSetting(true)}
                >
                  <SettingOutlined
                    className="mr-2"
                    style={{ fontSize: '20px', color: '#a0a0a0' }}
                  />
                </span>

                {ALLOWED_CREATE && (
                  <Button type="primary" onClick={() => onNewMarqueeMessage()}>
                    <PlusOutlined className="mr-2" />
                    <FormattedMessage
                      id="new-marquee-message.text"
                      defaultMessage="New Marquee Message"
                    />
                  </Button>
                )}
              </>
            }
          />
          <Layout.Content
            sideBar={
              <Layout.Sidebar sidebarStatus={isSidebar} withSearchIcon>
                <SearchFilterSidebar
                  page={page}
                  getMarquee={getMarquee}
                  pageInitialState={pageInitialState}
                />
              </Layout.Sidebar>
            }
            footer={
              <Layout.Footer
                loading={loading}
                onNext={handleNext}
                onPrev={handlePrev}
                page={page as any}
                resultsCount={totalCount}
                totalPage={totalPage}
                setPage={setPage as any}
                pageInfo={pageInfo}
              />
            }
          >
            <MarqueeTable
              loading={loading}
              setExistingMarquee={onEditMarqueeMessage}
              marqueeData={data}
              refetchQueries={refetchQueries}
            />
          </Layout.Content>
        </>
      </Layout.Container>

      <MarqueeMessagesSettingsModal
        setModalStatus={setModalStateSetting}
        modalStatus={modalStateSetting}
      />

      {modalStateNewMessage && (
        <NewMarqueeMessageModal
          modalState={modalStateNewMessage}
          setModalState={setModalStateNewMessage}
          existingMarquee={existingMarquee!}
          refetchQueries={refetchQueries}
        />
      )}
    </MarqueeProvider>
  );
};

export default MarqueeMessages;
