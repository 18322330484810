import gql from 'graphql-tag';
import * as yup from 'yup';

export const DYNAMIC_PAGES = gql`
  query DynamicPages {
    faq: dynamicPages(
      filter: { type: { eq: FREQUENTLY_ASKED_QUESTIONS } }
      first: 1
    ) {
      edges {
        node {
          id
          type
        }
      }
    }

    privacyPolicy: dynamicPages(
      filter: { type: { eq: PRIVACY_POLICY } }
      first: 1
    ) {
      edges {
        node {
          id
          type
        }
      }
    }

    tnc: dynamicPages(
      filter: { type: { eq: TERMS_AND_CONDITIONS } }
      first: 1
    ) {
      edges {
        node {
          id
          type
        }
      }
    }
  }
`;
export const DYNAMIC_PAGE = gql`
  query DynamicPage($id: ID!) {
    dynamicPage(id: $id) {
      id
      type
      pages {
        title
        pageNumber
        content
      }
      version
      dateTimeCreated
      customDateTimeCreated
    }
  }
`;
export const CREATE_DYNAMIC_PAGES = gql`
  mutation CreateDynamicPage($input: CreateDynamicPageInput!) {
    createDynamicPage(input: $input)
  }
`;
export const validationSchema = yup.object().shape({
  version: yup
    .string()
    .nullable()
    .required('This field is required'),
  pages: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .nullable()
        .required('This field is required'),
      content: yup
        .string()
        .nullable()
        .required('This field is required'),
    })
  ),
});
export const isAscending = (val: number[]) =>
  val
    .slice(1)
    .map((value, idx) => value > val[idx])
    .every((x) => x);
