import React from 'react';
import { Tag } from 'antd';
import cx from 'classnames';
import { ErrorHandler } from 'components/ErrorHandler';

type Props = {
  className?: string;
  isHide?: boolean;
  closable?: boolean;
  onClose: any;
};

const ModifiedTag: React.FC<Props> = ({
  children,
  className,
  isHide,
  ...rest
}) => (
  <ErrorHandler>
    <Tag
      className={`mt-1 ${cx({ 'display-none': isHide })} ${className || ''}`}
      {...rest}
    >
      {children}
    </Tag>
  </ErrorHandler>
);

export default ModifiedTag;
