/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TwitterPicker } from 'react-color';
import { ALink } from 'components/ALink/ALink';
import { StyledContainer, StyledColor } from './styles';

const colorsArray = [
  '#d3d3d3',
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4caf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#607d8b',
  '#823d4d',
  '#df40b6',
  '#b45f7a',
  '#DD902F',
  '#E099C2',
  '#96CCF5',
  '#B99FCE',
  '#FF006C',
];

const ColorPicker = ({
  onChange,
  value,
  onCreate,
  name,
}: {
  onChange: (e: any) => void;
  onCreate: (e: any) => void;
  value: string;
  name: string;
}) => {
  const [colorValue, setColorValue] = useState(value);

  const handleChangeComplete = (color: { hex: string }) => {
    setColorValue(color.hex);
    onChange(color.hex);
  };

  return (
    <StyledContainer>
      <div className="mt-2 pt-2 bt-1">
        <ALink className="ml-2" onClick={onCreate}>
          <FormattedMessage id="create.text" defaultMessage="Create" />
        </ALink>
        <StyledColor className="mx-2" value={colorValue} />
        <span>{name}</span>
      </div>
      <TwitterPicker
        triangle="hide"
        colors={colorsArray}
        onChange={handleChangeComplete}
      />
    </StyledContainer>
  );
};

export default ColorPicker;
