import React from 'react';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import useTranslate from 'utils/useTranslate';
import { messages } from 'SuperAdminMain/components/AccountManagementNew/utils';
import { StyledLabel, StyledSpan, StyledSelect } from '../utils';

export const CategoryFilter: React.FC = () => {
  const { filters, setFilters } = useFilterValues();
  const translate = useTranslate();

  const handleChange = (e: Array<string>) => {
    const bool = e.map((val: string) => JSON.parse(val));

    if (e.length) {
      setFilters((prev: any) => ({
        ...prev,
        trial: { in: bool },
      }));
    } else {
      setFilters((prev: any) => ({
        ...prev,
        trial: null,
      }));
    }
  };

  return (
    <div className="filter">
      <div className="label-clear">
        <div>
          <StyledLabel>
            <FormattedMessage id="category.text" defaultMessage="Category" />
          </StyledLabel>
        </div>

        <div>
          <StyledSpan onClick={() => handleChange([])}>
            <FormattedMessage id="clear.text" defaultMessage="Clear" />
          </StyledSpan>
        </div>
      </div>

      <StyledSelect
        mode="multiple"
        value={
          filters.trial ? filters.trial.in.map((q: boolean) => `${q}`) : []
        }
        placeholder={translate(messages['please-enter.text'])}
        onChange={handleChange}
        filterOption={false}
      >
        <Select.Option key="trial" value="true" label="Trial">
          <FormattedMessage id="trial.text" defaultMessage="Trial" />
        </Select.Option>

        <Select.Option key="production" value="false" label="Production">
          <FormattedMessage id="production.text" defaultMessage="Production" />
        </Select.Option>
      </StyledSelect>
    </div>
  );
};
