import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import useTranslate from 'utils/useTranslate';
import messages from './messages';
import {
  TIER_REQUIREMENT,
  getTierRequirement,
} from '../../../../../../../../constants';

const StyledInput = styled(Input)<any>`
  width: ${(props) => (props.name !== 'name' ? '100px' : '120px')};
`;

export const isNotEmpty = (nodeObj: Record<string, any>, lookup: string) => {
  let keys = ['name', 'percentage'];
  const memberKeys = ['minimumActiveMembersCount'];
  const netProfitKeys = ['minimumNetProfit'];
  switch (lookup) {
    case TIER_REQUIREMENT.ACTIVE_MEMBERS:
      keys = [...keys, ...memberKeys];
      break;
    case TIER_REQUIREMENT.NET_PROFIT:
      keys = [...keys, ...netProfitKeys];
      break;
    case TIER_REQUIREMENT.BOTH:
    case TIER_REQUIREMENT.EITHER:
      keys = [...keys, ...memberKeys, ...netProfitKeys];
      break;
    default:
      break;
  }
  return keys.every((key) => nodeObj[key] !== '');
};

const ConditionalInput = ({
  node,
  name,
  disableEdit,
  index,
}: {
  node: Record<string, any>;
  name: string;
  disableEdit: boolean;
  index: number;
}) => {
  const { setFieldValue, values } = useFormikContext() as any;
  const translate = useTranslate();

  const lookup =
    values.tierRequirement.length === 2
      ? values.tierRequirementOperator
      : values.tierRequirement[0];

  if (node.complete || disableEdit) {
    return (
      <strong>
        {node[name] || '-'}
        {name === 'percentage' && '%'}
      </strong>
    );
  }

  const additionalProps = {
    minimumActiveMembersCount: { min: 0, max: +node.maximumActiveMembers },
    maximumActiveMembers: { min: +node.minimumActiveMembersCount },
    minimumNetProfit: { min: 0, max: +node.maximumNetProfit },
    maximumNetProfit: { max: +node.minimumNetProfit },
    percentage: { min: 0, max: 100 },
  };

  const validate = (value: number) => {
    switch (name) {
      case 'minimumActiveMembersCount':
        return value <= +node.maximumActiveMembers && value >= 0
          ? value
          : node.maximumActiveMembers;
      case 'maximumActiveMembers':
        return value >= +node.minimumActiveMembersCount && value >= 0
          ? value
          : node.minimumActiveMembersCount;
      case 'minimumNetProfit':
        return value <= +node.maximumNetProfit && value >= 0
          ? value
          : node.maximumNetProfit;
      case 'maximumNetProfit':
        return value >= +node.minimumNetProfit && value >= 0
          ? value
          : node.minimumNetProfit;
      case 'percentage':
        return value <= 100 && value >= 0 ? value : 50;
      default:
        return value;
    }
  };
  return (
    <div className="d-flex">
      <StyledInput
        {...additionalProps[name]}
        name={name}
        type={name === 'name' ? 'text' : 'number'}
        value={node[name]}
        disabled={disableEdit}
        onChange={(e: Record<string, any>) => {
          const { value } = e.target;

          const { forActiveMembers, forNetProfit } = getTierRequirement(lookup);

          const newLevels = values.levels.map(
            (level: Record<string, any>, idx: number) => {
              if (idx === index) {
                return {
                  ...level,
                  [name]: value,
                };
              }
              return level;
            }
          );

          if (newLevels.length > 1) {
            if (name.includes('minimumActiveMembersCount')) {
              newLevels[index - 1] = {
                ...newLevels[index - 1],
                ...(forActiveMembers && { maximumActiveMembers: +value }),
              };
            }
            if (name.includes('minimumNetProfit')) {
              newLevels[index - 1] = {
                ...newLevels[index - 1],
                ...(forNetProfit && { maximumNetProfit: +value }),
              };
            }
          }

          setFieldValue('levels', newLevels);
        }}
        onBlur={(e: Record<string, any>) => {
          const { value } = e.target;
          const newValue = validate(value);

          setFieldValue(
            'levels',
            values.levels.map((level: Record<string, any>) => {
              if (level.key === node.key) {
                return {
                  ...level,
                  [name]: newValue || value,
                  complete: isNotEmpty(node, lookup),
                  completedAt: isNotEmpty(node, lookup) ? lookup : '',
                };
              }
              return level;
            })
          );
        }}
        style={{ border: '0px' }}
        size="small"
        placeholder={
          name === 'name' ? translate(messages['common.edit.text']) : '-'
        }
      />
      {name === 'percentage' && <span>%</span>}
    </div>
  );
};

export default ConditionalInput;
