import React, { useState } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Input, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Control, Controller } from 'react-hook-form';
import { VipMultiSelect } from 'components/VIPMultiSelect/VipMultiSelect';
import useTranslate from 'utils/useTranslate';
import messages from 'messages';
import { MemberMarkerMultiSelect } from 'components/MemberMarkerMultiSelect/MemberMarkerMultiSelect';
import MemberTagsSelect from '../MemberTagsSelect';
import { PromptWarningModal } from '../../utils';

type Props = {
  index: number;
  item: Record<string, any>;
  control: Control;
  errors: Record<string, any>;
  remove: (idx: number) => void;
  disableDelete: boolean;
  milestoneHide: boolean;
  setNames: (data: any) => void;
  names: string[];
};

const ErrorMessage = ({
  errors,
  name,
  index,
}: {
  errors: Record<string, any>;
  name: string;
  index: number;
}) =>
  errors?.memberLoyaltyGroups?.[index]?.[name]?.message ? (
    <small className="text-danger">
      {errors?.memberLoyaltyGroups?.[index]?.[name]?.message}
    </small>
  ) : (
    <></>
  );

export const VIPGroupCard = ({
  index,
  item,
  control,
  errors,
  remove,
  disableDelete,
  milestoneHide,
  setNames,
  names,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const name = (val: string) => `memberLoyaltyGroups[${index}].${val}`;
  const translate = useTranslate();

  return (
    <div
      className="border-top border-bottom px-3 d-flex align-items-center justify-content-between py-2"
      style={{ borderRadius: '4px', minHeight: '88px', overflowX: 'auto' }}
    >
      <div className="d-flex">
        <div className="mr-5 flex-shrink-0" style={{ width: '150px' }}>
          <p className="fw-600 fs-13">
            <FormattedMessage id="GROUP_NAME" defaultMessage="Group Name" />
          </p>
          <Controller
            name={name('name')}
            defaultValue={item.name}
            control={control}
            render={({ value, onChange }) => (
              <Input
                style={{ width: '100%' }}
                value={value}
                onChange={(e) => {
                  const localNames = [...names];
                  localNames[index] = e.target.value;
                  setNames(localNames);
                  onChange(e.target.value);
                }}
              />
            )}
          />
          <ErrorMessage errors={errors} name="name" index={index} />
        </div>

        <div className="mr-3 flex-shrink-0" style={{ width: '300px' }}>
          <p className="fw-600 fs-13">
            <FormattedMessage
              id="TARGET_GROUP_VIP_TIER"
              defaultMessage="Target Group VIP Tier"
            />
          </p>
          <Controller
            name={name('qualifiedMemberLoyaltyLevels')}
            defaultValue={item.qualifiedMemberLoyaltyLevels}
            control={control}
            render={({ value, onChange }) => (
              <VipMultiSelect
                value={value || undefined}
                onChange={(e) => onChange(e)}
                disabled={false}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="qualifiedMemberLoyaltyLevels"
            index={index}
          />
        </div>

        <div className="mr-3 flex-shrink-0" style={{ width: '200px' }}>
          <p className="fw-600 fs-13">
            <FormattedMessage
              id="EXCLUDED_MEMBER_MARKER"
              defaultMessage="Excluded Member Marker"
            />
          </p>
          <Controller
            name={name('excludedMemberLevels')}
            defaultValue={item.excludedMemberLevels}
            control={control}
            render={({ value, onChange }) => (
              <MemberMarkerMultiSelect
                onChange={(e) => onChange(e)}
                value={value}
                disabled={false}
              />
            )}
          />
        </div>

        <div className="mr-3 flex-shrink-0" style={{ width: '160px' }}>
          <p className="fw-600 fs-13">
            <FormattedMessage
              id="EXCLUDED_MEMBER_LABEL"
              defaultMessage="Excluded Member Label"
            />
          </p>
          <Controller
            name={name('excludedMemberTags')}
            defaultValue={item.excludedMemberTags}
            control={control}
            render={({ value, onChange }) => (
              <MemberTagsSelect value={value} onChange={(e) => onChange(e)} />
            )}
          />
        </div>

        <div className="mr-3 flex-shrink-0" style={{ width: '160px' }}>
          <p className="fw-600 fs-13">
            <FormattedMessage
              id="MILESTONE_MULTIPLIER"
              defaultMessage="Milestone Multiplier"
            />{' '}
            (x)
          </p>
          <Controller
            name={name('milestoneRewardMultiplier')}
            control={control}
            defaultValue={item.milestoneRewardMultiplier}
            as={<Input disabled={milestoneHide} style={{ width: '100%' }} />}
          />
          <ErrorMessage
            errors={errors}
            name="milestoneRewardMultiplier"
            index={index}
          />
        </div>

        <div className="mr-3 flex-shrink-0" style={{ width: '160px' }}>
          <p className="fw-600 fs-13">
            <FormattedMessage
              id="TASK_MULTIPLIER"
              defaultMessage="Task Multiplier"
            />{' '}
            (x)
          </p>
          <Controller
            name={name('taskMultiplier')}
            defaultValue={item.taskMultiplier}
            control={control}
            as={<Input style={{ width: '100%' }} />}
          />
          <ErrorMessage errors={errors} name="taskMultiplier" index={index} />
        </div>

        <div className="mr-3 flex-shrink-0" style={{ width: '160px' }}>
          <p className="fw-600 fs-13">
            <FormattedMessage
              id="REWARD_MULTIPLIER"
              defaultMessage="Reward Multiplier"
            />{' '}
            (x)
          </p>
          <Controller
            name={name('taskRewardMultiplier')}
            defaultValue={item.taskRewardMultiplier}
            control={control}
            as={<Input style={{ width: '100%' }} />}
          />
          <ErrorMessage
            errors={errors}
            name="taskRewardMultiplier"
            index={index}
          />
        </div>

        <div className="flex-shrink-0 pr-5">
          <p className="fw-600 fs-13" style={{ opacity: 0 }}>
            actions
          </p>
          <Dropdown
            overlay={() => (
              <Menu>
                <Menu.Item
                  disabled={disableDelete}
                  onClick={() => setShowModal(true)}
                >
                  <FormattedMessage
                    id="DELETE_VIP_GROUP"
                    defaultMessage="Delete VIP Group"
                  />
                </Menu.Item>
              </Menu>
            )}
          >
            <EllipsisOutlined
              className="cursor-pointer"
              style={{ fontSize: '25px' }}
            />
          </Dropdown>
        </div>
      </div>

      <PromptWarningModal
        onOk={() => remove(index)}
        visible={showModal}
        onCloseFn={() => setShowModal(false)}
        title={translate(messages.DELETE_VIP_GROUP)}
      />
    </div>
  );
};
