import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable: any = styled(Table)`
  .ant-table-expanded-row {
    td {
      padding: 0 !important;
    }
  }
`;

export const StyledCenter = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {};
