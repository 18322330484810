import { useMutation } from '@apollo/react-hooks';
import { message, Switch } from 'antd';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';
import React, { FC, useState } from 'react';
import { MutationUpdateConfigArgs } from 'types/graphqlTypes';
import { useOperatorHeader } from 'utils/useOperatorHeader';

interface Props {
  label: string;
  testId: string;
  config?: boolean | undefined | null;
  successMsg?: string;
  updateInput: string;
}

const FeatureToggleSwitch: FC<Props> = ({
  label,
  testId,
  config,
  successMsg,
  updateInput,
}) => {
  const [checked, setChecked] = useState(config);
  const [visible, setVisible] = useState(false);
  const { context } = useOperatorHeader();
  const [mutate, { loading }] = useMutation<boolean, MutationUpdateConfigArgs>(
    UPDATE_CONFIG,
    {
      context,
    }
  );

  const onChangeEnable = (checkValue: boolean) => {
    setChecked(checkValue);
    if (updateInput !== 'enableLoqateIntegration') {
      mutate({
        variables: {
          input: {
            [updateInput]: checkValue,
          },
        },
      }).then(() => {
        message.success(successMsg);
      });
    } else {
      setVisible(true);
    }
  };

  const onConfirm = async (): Promise<void> => {
    await mutate({
      variables: {
        input: {
          enableLoqateIntegration: checked,
        },
      },
    }).then(() => {
      message.success('Loqate feature updated');
      setVisible(false);
    });
  };

  const onCancel = () => {
    setVisible(false);
    setChecked(!checked);
  };

  return (
    <div>
      <h3>{label}</h3>
      <Switch
        onChange={(e) => onChangeEnable(e)}
        checked={checked || undefined}
        checkedChildren="On"
        unCheckedChildren="Off"
        data-testid={testId}
      />

      {updateInput === 'enableLoqateIntegration' && (
        <ConfirmationModal
          title="Are You Sure?"
          message="Changing Feature Flag status will have an impact on address completion on Web Sites and Back Office."
          visible={visible}
          onOk={onConfirm}
          onCancel={onCancel}
          okButtonProps={loading}
        />
      )}
    </div>
  );
};

export default FeatureToggleSwitch;
