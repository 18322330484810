import React from 'react';
import { FormItem } from 'pages/components/NewMemberManagement/components/Content/components/Members/components/MembersTable/components/BalanceForm/components/utils';
import { Select, Input } from 'antd';
import { capitalize } from 'lodash';
import gql from 'graphql-tag';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { Member, WithdrawalMethodsConnection } from 'types/graphqlTypes';

const WITHDRAWAL_METHODS = gql`
  query WithdrawalMethods(
    $first: Int
    $after: Binary
    $filter: WithdrawalMethodFilterInput
  ) {
    withdrawalMethods(first: $first, after: $after, filter: $filter) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const GET_MEMBER_INFO = gql`
  query MemberEmail($id: ID) {
    member(id: $id) {
      id
      email
    }
  }
`;

export const GET_MEMBER_DEPOSITS_INFO = gql`
  query MemberDeposit(
    $first: Int
    $after: Binary
    $filter: DepositsFilterInput
  ) {
    deposits(first: $first, after: $after, filter: $filter) {
      totalCount
    }
  }
`;

type Props = {
  errors: Record<string, string>;
  touched: Record<string, string>;
  setFieldValue: (key: string, data: string) => void;
  values: Record<string, string>;
  memberId: string;
};

const ManualWithdrawalFields: React.FC<Props> = ({
  errors,
  touched,
  values,
  setFieldValue,
  memberId,
}) => {
  const [allowManualWithdrawal, setAllowManualWithdrawal] = React.useState({
    skrillWithdrawal: false,
    netellerWithdrawal: false,
  });

  const { skrillWithdrawal, netellerWithdrawal } = allowManualWithdrawal;

  const client = useApolloClient();

  const { data, loading } = useQuery<{
    withdrawalMethods: WithdrawalMethodsConnection;
  }>(WITHDRAWAL_METHODS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        name: {
          in: ['Skrill', 'Neteller'],
        },
      },
    },
  });

  useQuery<{ member: Member }>(GET_MEMBER_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: memberId,
    },
    onCompleted: (res) => setFieldValue('email', res.member.email!),
  });

  const withdrawalMethods = data?.withdrawalMethods.edges;

  const memberDepositPromise = async () => {
    const depositQueryPromises = ['SKRILL', 'NETELLER'].map((item: string) =>
      client.query({
        query: GET_MEMBER_DEPOSITS_INFO,
        variables: {
          filter: {
            account: {
              eq: memberId,
            },
            paymentMethodType: {
              eq: item,
            },
          },
        },
      })
    );

    const memberDepositRawData = await Promise.all(depositQueryPromises);

    const depositsCount = memberDepositRawData.map(
      (rawData) => rawData.data.deposits.totalCount
    );

    setAllowManualWithdrawal({
      skrillWithdrawal: Boolean(depositsCount[0]),
      netellerWithdrawal: Boolean(depositsCount[1]),
    });
  };

  React.useEffect(() => {
    if (memberId) memberDepositPromise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  return (
    <>
      <FormItem
        label="Choose Withdrawal Method"
        errors={errors}
        touched={touched}
        keyType="withdrawalMethod"
      >
        <Select
          value={values.withdrawalMethod || undefined}
          onChange={(e) => setFieldValue('withdrawalMethod', e)}
          placeholder="Please select a withdrawal method"
          loading={loading}
          disabled={!netellerWithdrawal && !skrillWithdrawal}
        >
          {withdrawalMethods?.map((withdrawalMethod) => {
            const { id, name } = withdrawalMethod!.node;

            let isDisabled = false;

            isDisabled =
              (name === 'Neteller' && !netellerWithdrawal) ||
              (name === 'Skrill' && !skrillWithdrawal);

            return (
              <Select.Option disabled={isDisabled} value={id} key={id}>
                {capitalize(name)}
              </Select.Option>
            );
          })}
        </Select>
      </FormItem>

      <FormItem label="Email" touched={touched} errors={errors} keyType="email">
        <Input
          aria-label="email"
          name="email"
          value={
            (netellerWithdrawal || skrillWithdrawal) && values.withdrawalMethod
              ? values.email
              : ''
          }
          disabled
        />
      </FormItem>
    </>
  );
};

export default ManualWithdrawalFields;
