import gql from 'graphql-tag';

export const ACCOUNT_USAGE_REPORTS = gql`
  query AUR_MAIN_QUERY(
    $first: Int
    $after: Binary
    $filter: AccountUsageReportFilterInput
  ) {
    accountUsageReports(first: $first, after: $after, filter: $filter) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          timezone
          creator {
            id
            username
            __typename
          }
          dateTimeCreated
          filter {
            dateTimeProcessed {
              gte
              lte
            }
            vendor {
              in
            }
            # game {
            #   in
            # }
            admin {
              in
            }
            # status {
            #   in
            # }
          }
          report {
            id
            uri
          }
          scheduledDateTime
          status
        }
      }
    }
  }
`;

export default {};
