import React from 'react';
import { Select, Spin } from 'antd';
import gql from 'graphql-tag';
import { defineMessages } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import coercedGet from 'SuperAdminMain/utils/coercedGet';
import { Vendor } from 'types/graphqlTypes';
import { useFilterValues } from 'SuperAdminMain/contexts/Filters';
import { DynamicObj } from 'interfaces/user.interface';
import useTranslate from 'SuperAdminMain/hooks/useTranslate';

const VENDORS = gql`
  query {
    vendors {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const messagesLocal = defineMessages({
  SELECT_VENDOR: {
    id: 'SELECT_VENDOR',
    defaultMessage: 'Select vendor',
  },
});

export const VendorFilter = () => {
  const { filters, setFilters } = useFilterValues();
  const { data, loading } = useQuery(VENDORS);
  const value = filters?.vendors;
  const translate = useTranslate();

  const handleFilters = (selectedValue: string[]) => {
    if (!selectedValue || selectedValue.length === 0) {
      setFilters((prev: DynamicObj) => {
        const { vendors, ...restFilter } = prev;
        return restFilter;
      });
    } else {
      setFilters((prev: DynamicObj) => ({
        ...prev,
        vendors: { includesAny: selectedValue },
      }));
    }
  };

  const vendorEdges = coercedGet(data, 'vendors.edges', []);
  const vendors = vendorEdges.map((vendor: { node: Vendor }) => vendor.node);
  return (
    <Spin spinning={loading}>
      <StyledSelect
        value={value?.includesAny}
        mode="multiple"
        placeholder={translate(messagesLocal.SELECT_VENDOR)}
        onChange={(valueSelect: any) => handleFilters(valueSelect)}
        optionFilterProp="children"
        filterOption={(input: any, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {vendors.map((vendor: Vendor) => (
          <Select.Option key={vendor.id} value={vendor.id}>
            {vendor.name}
          </Select.Option>
        ))}
      </StyledSelect>
    </Spin>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;

  .ant-select-selection {
    padding: 4px;
    padding-top: 0;
    border-radius: 4px;
  }

  .ant-select-selection__rendered {
    margin-left: 0 !important;
  }

  .ant-select-selection li.ant-select-selection__choice {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
`;
