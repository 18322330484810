import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/react-hooks';
import { message, Modal } from 'antd';
import { DELETE_MEMBER_LEVEL } from 'graphql/mutations/memberLevel.mutation';
import { GET_MEMBER_LEVELS } from 'graphql/queries/memberLevel.query';
import messages from 'messages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useOperatorHeader } from 'utils/useOperatorHeader';
import useTranslate from 'utils/useTranslate';
import { useMemberLevel } from '../../context';

type Props = {
  memberLevel: Record<string, any>;
  disabled: boolean;
};

const DeleteMemberLevel = ({ memberLevel, disabled }: Props) => {
  const translate = useTranslate();
  const [isShow, setShow] = useState(false);

  const handleShow = (e?: Record<string, any>) => {
    if (e) e.preventDefault();
    if (disabled) {
      message.warn(
        <FormattedMessage
          id="member-level-management.must-deactivate-first.text"
          defaultMessage="Must deactivate first"
        />
      );
      return;
    }
    setShow(!isShow);
  };
  const { refetchParams } = useMemberLevel()!;
  const { context } = useOperatorHeader();
  const [deleteMemberLevel, { loading }] = useMutation(DELETE_MEMBER_LEVEL, {
    onCompleted: (data) => {
      if (data.deleteMemberLevel) {
        message.success(translate(messages.MEMBER_LEVEL_DELETED));
      }
      handleShow();
    },
    context,
  });

  return (
    <>
      <a href="/#" rel="noopener noreferrer" onClick={handleShow}>
        {translate(messages.DELETE)}
      </a>
      {isShow && (
        <Modal
          visible
          maskClosable={false}
          closable={false}
          width={416}
          okText={
            <FormattedMessage id="confirm.text" defaultMessage="Confirm" />
          }
          cancelText={
            <FormattedMessage id="cancel.text" defaultMessage="Cancel" />
          }
          cancelButtonProps={{ disabled: loading }}
          okButtonProps={{ loading }}
          onCancel={handleShow}
          onOk={() => {
            deleteMemberLevel({
              variables: {
                id: memberLevel.id,
              },
              refetchQueries: [
                {
                  query: GET_MEMBER_LEVELS,
                  variables: refetchParams,
                },
              ],
            });
          }}
        >
          <div className="ant-modal-confirm-body">
            <QuestionCircleOutlined style={{ color: '#faad14' }} />
            <span className="ant-modal-confirm-title">
              <FormattedMessage
                id="confirm-delete.text"
                defaultMessage="Confirm Delete"
              />
            </span>
            <div className="ant-modal-confirm-content">
              <FormattedMessage
                id="are-you-sure?.text"
                defaultMessage="Are you sure?"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default DeleteMemberLevel;
