export const costSettings = [
  {
    key: 'depositTransactionCost',
    id: 'agent.deposit-transaction-cost.text',
    text: 'Deposit Transaction Cost',
  },
  {
    key: 'thirdPartyDepositTransactionCost',
    id: 'agent.3rd-party-payment-gateway-deposit-transaction-cost.text',
    text: '3rd Party Payment Gateway Deposit Transaction Cost',
  },
  {
    key: 'withdrawalTransactionCost',
    id: 'agent.withdrawal-transaction-cost.text',
    text: 'Withdrawal Transaction Cost',
  },
  {
    key: 'thirdPartyWithdrawalTransactionCost',
    id: 'agent.3rd-party-payment-gateway-withdrawal-transaction-cost.text',
    text: '3rd Party Payment Gateway Withdrawal Transaction Cost',
  },
  {
    key: 'promoCost',
    id: 'agent.promotion-cost.text',
    text: 'Promotion Cost',
  },
  {
    key: 'rebateCost',
    id: 'agent.rebate-cost.text',
    text: 'Rebate Cost',
  },
  {
    key: 'interestAccountCost',
    id: 'agent.interest-account-cost.text',
    text: 'Interest Account Cost',
  },
];

export default {};
