import React from 'react';
import { FormattedMessage } from 'react-intl';
import coercedGet from 'utils/coercedGet';
import { Tag } from 'antd';
import { get } from 'lodash';

type Props = {
  filters: object;
  loading: boolean;
  onFilterChange: Function;
};

export const FilterConditions = ({
  filters,
  loading,
  onFilterChange,
}: Props) => {
  const handleRemove = ({
    event,
    dataToRemove,
    filterKey,
  }: {
    event: React.MouseEvent<HTMLElement>;
    dataToRemove: string;
    filterKey: string;
  }) => {
    event.preventDefault();
    if (!loading) {
      const data = get(filters[filterKey], 'in', []).filter(
        (item: string) => item !== dataToRemove
      );
      const newFilter = {
        in: data,
      };
      onFilterChange({
        ...filters,
        [filterKey]: data.length ? newFilter : null,
      });
    }
  };

  const FilterTag = ({ filterKey }: { filterKey: string }) =>
    coercedGet(filters, `${filterKey}.in`, []).map((item: string) => (
      <Tag
        style={{ margin: '4px' }}
        key={item}
        closable
        onClose={(e) =>
          handleRemove({
            event: e,
            dataToRemove: item,
            filterKey,
          })
        }
      >
        {item}
      </Tag>
    ));

  return (
    <>
      <FormattedMessage
        id="filter-conditions.text"
        defaultMessage="Filter Conditions"
      />
      :
      <FilterTag filterKey="username" />
      <FilterTag filterKey="brand" />
      <FilterTag filterKey="brandId" />
      <FilterTag filterKey="platformId" />
    </>
  );
};
