import React from 'react';
import gql from 'graphql-tag';
import { Config } from 'types/graphqlTypes';
import { useQuery } from '@apollo/react-hooks';
import { useAccount } from 'store/accountState';
import esGet from 'utils/esGet';

const TIMEZONE_CONFIG = gql`
  query Timezone {
    config {
      timezone
    }
  }
`;

export const ReportsTimezoneContext = React.createContext(null);

const ReportsTimezoneProvider: React.FC = ({ children }) => {
  const { account } = useAccount();
  const { config: accountConfig } = account;
  const globalDefaultTimezone = esGet(accountConfig?.timezone, 'Europe/London');

  const [currentTimezone, setCurrentTimezone] = React.useState<string>(
    globalDefaultTimezone
  );

  useQuery<{ config: Config }>(TIMEZONE_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: ({ config }) => {
      setCurrentTimezone(config.timezone);
    },
  });

  const bypassBeijingOffset = React.useCallback((currTimezone: string) => {
    if (currTimezone === 'Asia/Beijing') return 'Asia/Shanghai';
    return currTimezone;
  }, []);

  // bypassedCurrentTimezone for table columns rendering of timezones
  // bypassedCurrentTimezone is only for displaying of timezone value that is intended only to be shown to the User
  // currentTimezone  its used for all areas of the app where we need to send a timezone value to the BE, such as Forms and Select Components(filters)

  const tzContext = {
    bypassedCurrentTimezone: bypassBeijingOffset(currentTimezone),
    currentTimezone,
    setCurrentTimezone,
    globalDefaultTimezone,
  };

  return (
    <ReportsTimezoneContext.Provider value={tzContext as any}>
      {children}
    </ReportsTimezoneContext.Provider>
  );
};

export default ReportsTimezoneProvider;

export const useReportsTimezone = (): any =>
  React.useContext(ReportsTimezoneContext);
