import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Select } from 'antd';
import vendors from 'constants/vendors';

const { Option } = Select;

const Vendor = ({
  state,
  setState,
}: {
  state: {
    vendors: any[];
  };
  setState: (e: Record<string, any>) => void;
}) => (
  <div className="my-2">
    <div className="mb-1">
      <FormattedMessage id="reports.vendor.text" defaultMessage="Vendor" />
    </div>
    <Select
      placeholder={
        <FormattedMessage id="reports.select.text" defaultMessage="Select" />
      }
      mode="multiple"
      style={{ width: '100%' }}
      value={state.vendors}
      onChange={(values) =>
        setState(
          (prevState: { reset: boolean }) =>
            ({
              ...state,
              vendors: values,
              ...(!values.length && { reset: !prevState.reset }),
            } as Record<string, any>)
        )
      }
    >
      {vendors.map((option) => (
        <Option key={option.code} value={option.code}>
          {option.label}
        </Option>
      ))}
    </Select>
  </div>
);

export default Vendor;
