import { defineMessages } from 'react-intl';
import React from 'react';
import * as Yup from 'yup';
import messages from 'messages';
import { CustomRegex } from 'utils/regex';
import { toFinite } from 'lodash';

export const validationSchema = (translate: any) =>
  Yup.object().shape({
    cyclesCount: Yup.number()
      .min(1, translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 }))
      .required(translate(messages.REQUIRED)),
    dayRewardPointsRequirement: Yup.number()
      .required(translate(messages.REQUIRED))
      .min(1, translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 })),
    dayRewardAmount: Yup.string()
      .required(translate(messages.REQUIRED))
      .matches(
        CustomRegex.onlyNumbers(),
        translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
      )
      .matches(
        CustomRegex.twoDecimals(),
        translate(messages['maximum-count-decimal-places.text'], { count: 2 })
      ),
    dayRewardTurnoverRequirementMultiplier: Yup.string()
      .required(translate(messages.REQUIRED))
      .matches(
        CustomRegex.onlyNumbers(),
        translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
      )
      .matches(
        CustomRegex.twoDecimals(),
        translate(messages['maximum-count-decimal-places.text'], { count: 2 })
      ),

    days: Yup.array().of(
      Yup.object().shape({
        tasks: Yup.array().of(
          Yup.object().shape({
            type: Yup.string(),
            amount: Yup.string().when('type', {
              is: (val: string) => val === 'EFFECTIVE_BET',
              then: () =>
                Yup.string()
                  .required(translate(messages.REQUIRED))
                  .matches(
                    CustomRegex.onlyNumbers(),
                    translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
                  )
                  .test(
                    'amount',
                    translate(messages.AMOUNT_MIN_MORE_THAN, { count: 0 }),
                    (value) => {
                      const valueTransform = toFinite(value);
                      return valueTransform > 0;
                    }
                  )
                  .matches(
                    CustomRegex.twoDecimals(),
                    translate(messages['maximum-count-decimal-places.text'], {
                      count: 2,
                    })
                  ),
            }),
            rewardAmount: Yup.string()
              .required(translate(messages.REQUIRED))
              .matches(
                CustomRegex.onlyNumbers(),
                translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
              )
              .matches(
                CustomRegex.twoDecimals(),
                translate(messages['maximum-count-decimal-places.text'], {
                  count: 2,
                })
              ),
            turnoverRequirementMultiplier: Yup.string()
              .required(translate(messages.REQUIRED))
              .matches(
                CustomRegex.onlyNumbers(),
                translate(messages.AMOUNT_INVALID_KEYED_NUMERICS)
              )
              .matches(
                CustomRegex.twoDecimals(),
                translate(messages['maximum-count-decimal-places.text'], {
                  count: 2,
                })
              ),
          })
        ),
      })
    ),
  });
export const localMessages = defineMessages({
  MISSION_POINTS_INFO: {
    id: 'MISSION_POINTS_INFO',
    defaultMessage: 'Points needed to clear milestone requirement for each day',
  },
});
export const ErrorMessage = ({
  errors,
  name,
}: {
  errors: Record<string, any>;
  name: string;
}) =>
  errors[name]?.message ? (
    <small className="text-danger">{errors[name].message}</small>
  ) : (
    <></>
  );
