import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Spin } from 'antd';
import coercedGet from 'utils/coercedGet';

import { MemberTag } from 'types/graphqlTypes';
import MemberTagsSelect from './components/MemberTagsSelect';
import { MEMBER_TAGS } from './queries';

const MemberTags = ({
  memberId,
  memberTags,
  isMemberDetail,
}: {
  memberId: string;
  memberTags?: MemberTag[];
  isMemberDetail?: boolean;
}) => {
  const [tags, setTags] = useState<Record<string, any>[]>([]);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const loadInitialTags = useCallback(async () => {
    setLoading(true);
    const { data } = await client.query({
      context: { shouldBatch: true },
      fetchPolicy: 'network-only',
      query: MEMBER_TAGS,
      variables: {
        id: memberId,
      },
    });
    const tagsData = coercedGet(data, 'member.tags', []);
    const filteredTags = tagsData.filter(Boolean);
    setTags(filteredTags);
    setLoading(false);
  }, [client, memberId]);

  useEffect(() => {
    if (isMemberDetail) loadInitialTags();
  }, [loadInitialTags, isMemberDetail]);

  if (loading) {
    return <Spin spinning />;
  }

  return tags ? (
    <MemberTagsSelect
      value={isMemberDetail ? memberTags! : tags}
      disableClosable
    />
  ) : (
    <></>
  );
};

export default MemberTags;
