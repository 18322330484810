import { CloseOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Drawer, message, Modal, Popconfirm, Radio } from 'antd';
import { UPDATE_CONFIG } from 'graphql/mutations/updateConfig.mutation';
import messages from 'messages';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { DepositSource } from 'types/graphqlTypes';
import coercedGet from 'utils/coercedGet';
import { mapOrder } from 'utils/mapOrder';
import useTranslate from 'utils/useTranslate';
import DepositSourceForm from './components/DepositSourceForm';
import DepositSourceList from './components/DepositSourceList';
import {
  CREATE_DEPOSIT_SOURCE,
  DELETE_DEPOSIT_SOURCE,
  UPDATE_DEPOSIT_SOURCE,
} from './mutations';
import { DEPOSIT_SOURCES_AND_CONFIG } from './queries';

const NoBorderButton = styled(Button)`
  border: none;
  box-shadow: none;
  padding: 0;
`;

const StyledItem = styled.div`
  height: 100%;
  width: 100%;
  padding: 5px 12px;
`;

const StyledWrapper = styled.div`
  min-height: 400px;
`;

const depositSourceDefault = {
  id: '',
  logo: {
    id: '',
    uri: '',
  },
  displayName: {
    EN: '',
    ZH: '',
  },
  visible: false,
  loadBankSource: false,
  order: 0,
};

type PopoverTitleProps = {
  setShow: (value: boolean) => void;
  title: string;
};

const PopoverTitle: React.FC<PopoverTitleProps> = ({ setShow, title }) => (
  <div className="d-flex align-items-center justify-content-space-between">
    <h4>{title}</h4>
    <NoBorderButton size="small" onClick={() => setShow(false)}>
      <CloseOutlined />
    </NoBorderButton>
  </div>
);

const PopoverContent = forwardRef((_props, ref) => {
  const translate = useTranslate();
  const configOptions = [
    { value: 'MANDATORY', label: translate(messages.MANDATORY) },
    { value: 'OPTIONAL', label: translate(messages.OPTIONAL) },
    { value: 'HIDDEN', label: translate(messages.HIDDEN) },
  ];

  const [updateConfig] = useMutation(UPDATE_CONFIG);
  const [createDepositSource] = useMutation(CREATE_DEPOSIT_SOURCE);
  const [updateDepositSource] = useMutation(UPDATE_DEPOSIT_SOURCE);
  const [deleteDepositSource] = useMutation(DELETE_DEPOSIT_SOURCE);

  const [state, setState] = useState({
    drawer: false,
    defaultFormValues: depositSourceDefault,
    editIndex: 0,
    deleteList: [],
    saving: false,
  });

  const { reset, control, setValue, formState, getValues } = useForm({
    defaultValues: {
      depositSources: [],
      depositSourcesConfig: 'MANDATORY',
    },
  });
  const { isDirty } = formState;
  const fieldArray = useFieldArray({
    control,
    name: 'depositSources',
    keyName: 'id',
  });
  const { fields, append, remove } = fieldArray;
  const { loading } = useQuery(DEPOSIT_SOURCES_AND_CONFIG, {
    fetchPolicy: 'network-only',
    onError: () => {
      message.error(translate(messages.FATAL_ERROR));
    },
    onCompleted: (data) => {
      const newDataSource = coercedGet(data, 'depositSources.edges', []).map(
        (edge: object) => {
          const node = coercedGet(edge, 'node', {});
          const newNode = {
            ...node,
            displayName: {
              EN: node.enDisplayName,
              ZH: node.zhDisplayName,
            },
          };
          return newNode;
        }
      );
      const order = Array.from(Array(newDataSource.length).keys());
      const config = coercedGet(
        data,
        'config.depositSourceFieldStateInputType',
        'MANDATORY'
      );

      if (newDataSource.length) {
        reset({
          depositSources: mapOrder(newDataSource, order, 'order'),
          depositSourcesConfig: config,
        } as Record<string, any>);
      }
    },
  });

  const toggleDrawer = () => {
    setState((prev) => ({ ...prev, drawer: !prev.drawer }));
  };

  const handleDrawerAdd = () => {
    setState((prev) => ({
      ...prev,
      drawer: true,
      defaultFormValues: depositSourceDefault,
    }));
  };

  const handleDrawerEdit = (
    index: number,
    value: Partial<DepositSource> | any
  ) => {
    setState((prev) => ({
      ...prev,
      drawer: true,
      defaultFormValues: value,
      editIndex: index,
    }));
  };

  const addDepositSource = (value: Partial<DepositSource>) => {
    append(value);
    setState((prev) => ({
      ...prev,
      drawer: false,
    }));
  };

  const editDepositSource = (value: Partial<DepositSource>) => {
    const newFields = [...fields];
    newFields[state.editIndex] = value;
    setValue('depositSources', [...newFields] as any);
    setState((prev) => ({
      ...prev,
      drawer: false,
    }));
  };

  const updateVisibility = (index: number, value: boolean) => {
    const newFields = [...fields];
    newFields[index].visible = value;
    setValue('depositSources', [...newFields] as any);
    setState((prev) => ({
      ...prev,
      drawer: false,
    }));
  };

  const removeDepositSource = (
    index: number,
    value: Partial<DepositSource>
  ) => {
    const { id } = value;
    remove(index);

    if (id !== 'NEW') {
      setState((prev) => ({
        ...prev,
        deleteList: [...prev.deleteList, id] as any,
      }));
    }
  };

  const onSubmit = async () => {
    const newConfig: any = getValues('depositSourcesConfig');

    const updateDepositSourcesConfig = updateConfig({
      variables: {
        input: {
          depositSourceFieldStateInputType: newConfig,
        },
      },
    });

    const deleteDepositSourcePromises = state.deleteList.map((id) =>
      deleteDepositSource({
        variables: {
          id,
        },
      })
    );

    const addEditDepositSourcePromises = fields.map((field, index) => {
      const { id, displayName, loadBankSource, logo, visible } = field;

      if (id === 'NEW') {
        const input = {
          logo: logo.id,
          order: index,
          shortName: {
            EN: '',
            ZH: '',
          },
          displayName,
          visible,
          loadBankSource,
        };
        return createDepositSource({
          variables: {
            input,
          },
        });
      }

      const input = {
        logo: logo.id,
        order: index,
        displayName,
        visible,
        loadBankSource,
      };
      return updateDepositSource({
        variables: {
          id,
          input,
        },
      });
    });

    setState((prev) => ({ ...prev, saving: true }));

    try {
      await Promise.all([
        updateDepositSourcesConfig,
        ...addEditDepositSourcePromises,
        ...deleteDepositSourcePromises,
      ]);

      reset({
        depositSources: fields as any,
        depositSourcesConfig: newConfig,
      });
      setState((prev) => ({ ...prev, saving: false }));
      message.success(translate(messages['successfully-saved-changes.text']));
    } catch {
      setState((prev) => ({ ...prev, saving: false }));
      message.error(translate(messages.UNKNOWN_ERROR));
    }
  };

  useImperativeHandle(ref, () => ({
    saveConfig: onSubmit,
    saving: state.saving,
    isDirty,
  }));

  return (
    <div>
      <StyledWrapper>
        <h4>{translate(messages.DEPOSIT_SOURCE_CONFIG_OFFLINE_BANK_ONLY)}</h4>
        <div className="py-2 bb-1">
          <Controller
            control={control}
            name="depositSourcesConfig"
            render={({ onChange, value }) => (
              <Radio.Group
                disabled={loading}
                options={configOptions}
                onChange={(e) => onChange(e.target.value)}
                value={value}
              />
            )}
          />
        </div>
        <div>
          <div className="py-2 d-flex justify-content-space-between align-items-center">
            <h4 className="m-0">
              {translate(messages.DEPOSIT_SOURCE_LISTING)}
            </h4>
            <Button type="link" onClick={() => handleDrawerAdd()}>
              <PlusOutlined /> {translate(messages.ADD_NEW)}
            </Button>
          </div>
          <DepositSourceList
            loading={loading}
            handleDrawerEdit={handleDrawerEdit}
            removeDepositSource={removeDepositSource}
            updateVisibility={updateVisibility}
            {...fieldArray}
          />
        </div>
      </StyledWrapper>
      <div className="pt-3 text-right bt-1">
        <Button
          loading={state.saving}
          type="primary"
          onClick={() => onSubmit()}
        >
          {translate(messages['save.text'])}
        </Button>
      </div>
      <Drawer
        title={
          <div>
            <NoBorderButton className="pr-2" onClick={() => toggleDrawer()}>
              <LeftOutlined />
            </NoBorderButton>
            <span>{translate(messages.ADD_NEW_DEPOSIT_SOURCE)}</span>
          </div>
        }
        width="100%"
        placement="right"
        closable={false}
        onClose={() => toggleDrawer()}
        visible={state.drawer}
        getContainer={false}
        style={{ position: 'absolute' }}
        headerStyle={{ padding: '0 0 10px 0' }}
        bodyStyle={{ padding: '12px 0' }}
        destroyOnClose
      >
        <DepositSourceForm
          append={addDepositSource}
          edit={editDepositSource}
          defaultValues={state.defaultFormValues}
        />
      </Drawer>
    </div>
  );
});

type Props = {
  setPopOver: (val: boolean) => void;
};

const DepositSourceConfig: React.FC<Props> = ({ setPopOver }) => {
  const translate = useTranslate();
  const childRef = useRef() as any;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [key, setKey] = useState(0);

  const handleVisibleChange = (value: boolean) => {
    if (childRef.current?.saving) return;

    if (childRef.current?.isDirty) {
      window.scrollTo(0, 0);
      setShowWarning(!value);
    } else {
      setPopOver(value);
      setIsModalVisible(value);
    }
  };

  const handleCancel = () => {
    setPopOver(false);
    setIsModalVisible(false);
    setShowWarning(false);
    setKey(key + 1);
  };

  const handleConfirm = async () => {
    setShowWarning(false);
    await childRef.current?.saveConfig();
    setPopOver(false);
    setIsModalVisible(false);
  };

  return (
    <>
      <StyledItem
        className="text-left"
        onClick={() => {
          setIsModalVisible(true);
          setPopOver(false);
        }}
      >
        {translate(messages.DEPOSIT_SOURCE_CONFIG)}
      </StyledItem>
      <Modal
        title={
          <PopoverTitle
            title={translate(messages.DEPOSIT_SOURCE_CONFIG_OFFLINE)}
            setShow={handleVisibleChange}
          />
        }
        visible={isModalVisible}
        footer={null}
        closable={false}
      >
        <PopoverContent key={key} ref={childRef} />
        <Popconfirm
          title={translate(messages.CONFIRM_SAVE_DEPOSIT_CONFIG)}
          onConfirm={() => handleConfirm()}
          onCancel={() => handleCancel()}
          placement="right"
          visible={showWarning}
        />
      </Modal>
    </>
  );
};

export default DepositSourceConfig;
